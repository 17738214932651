import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Grid, Table } from 'semantic-ui-react';
import * as global_constants from '../../global_constants';
import DatePicker from '../../shared/component/calendar_component';
import { add_doctors_order } from './../../case/action/subscriber_action';
import { custom_date_format, date_format_with_no_time } from './../../shared/utility';
import { enum_case_page_state, enum_type_of_insurance } from './../case_constants';
import CheckboxComponent from "./../../shared/component/checkbox_component"
// Add Edit and view Insurance Doctors Order details
//Define the state with their data types
interface IProps {
    insurance_type: string
    form_submitted: boolean,
    case_id: number,
    subscriber_data: any,
    user_login_details: any,
    page_state: any,
    onRef?: any,
    doctors_fetch_time?: any
}
interface IState {
    show_inactive_doctors_orders: boolean,
    subscriber: any,
    doctors_order_list: any,
    page_state: enum_case_page_state,
    doctors_order: {
        comments: string
        from_date?: any
        formatted_from_date?: Date
        id?: number
        is_active?: boolean
        is_shown_active?: boolean
        prescribed_visits?: number
        subscriber_id?: number
        thru_date?: any
        formatted_thru_date?: Date
        visits_used?: number,
        case_id?: number,
        show_from_date_error?: boolean
    },
    insurance_type: string
    form_submitted?: boolean,
    case_id: number,
}

export class InsuranceDoctorsOrdersComponent extends React.Component<IProps, IState> {
    _is_mounted = false;
    constructor(props) {
        super(props);        

        this.state = {
            show_inactive_doctors_orders: false,
            subscriber: this.props.subscriber_data,
            doctors_order_list: this.props.subscriber_data.subscriber_doctors_orders,
            insurance_type: this.props.insurance_type,
            form_submitted: this.props.form_submitted,
            case_id: this.props.case_id,
            page_state: this.props.page_state,
            doctors_order: {
                comments: null,
                from_date: null,
                formatted_from_date: null,
                id: null,
                is_active: null,
                is_shown_active: null,
                prescribed_visits: null,
                subscriber_id: this.props.subscriber_data.id,
                thru_date: null,
                formatted_thru_date: null,
                visits_used: null,
                case_id: this.props.subscriber_data.case_id,
                show_from_date_error: false
            }
        }


        this.handleChange = this.handleChange.bind(this)
    }
    componentDidMount() {
        this._is_mounted = true;
        if (this.props.onRef) {
            this.props.onRef(this);
        }

        this.get_doctors_orders_data_from_props();
        /*
        // if the doctors order list is empty then we are pushing one blank row into it.
        if (this.state.doctors_order_list.length == 0) {
            this.add_doctors_order()
        }
        */
    }
    componentWillUnmount() {
        this._is_mounted = false;
    }
    UNSAFE_componentWillMount() {

        if (this.props.onRef) {
            this.props.onRef(this);
        }

    }
   

    componentDidUpdate(previousProps, previousState) {

        if (previousProps.subscriber_data.id !== this.props.subscriber_data.id) {
            this.get_doctors_orders_data_from_props();
        } else if (previousProps.page_state != this.props.page_state) {
            this.get_doctors_orders_data_from_props();
        }

        else if (previousProps.doctors_fetch_time !== this.props.doctors_fetch_time) {
            this.get_doctors_orders_data_from_props();
        }
        
    }


    get_doctors_orders_data_from_props = () => {
        var subscriber_data = Object.assign({}, this.props.subscriber_data);
        var doctors_orders_data = Object.assign([], subscriber_data.subscriber_doctors_orders);

        //if (doctors_orders_data.length >= 0) {
        //    var active_row = this.check_active_doctors_orders(doctors_orders_data);
        //    if (active_row.length == 0) {
        //        var new_doctors_order = Object.assign({}, this.state.doctors_order);
        //        new_doctors_order.is_active = true;
        //        new_doctors_order.id = 0;
        //        doctors_orders_data.push(new_doctors_order);
        //    }
        //} else {
        //    var new_doctors_order = Object.assign({}, this.state.doctors_order);
        //    new_doctors_order.is_active = true;
        //    new_doctors_order.id = 0;
        //    doctors_orders_data.push(new_doctors_order);
        //}
       
        for (let item of doctors_orders_data) {
            if (!(item.from_date == '' || item.from_date == null || item.from_date == undefined)) {
                item.formatted_from_date = moment(item.from_date);
            }
            if (!(item.thru_date == '' || item.thru_date == null || item.thru_date == undefined)) {
                item.formatted_thru_date = moment(item.thru_date);
            }
            item.is_shown_active = item.is_active
        }
        var show_inactive_doctors_orders = false;
        if (this.props.page_state === enum_case_page_state.add) {
            show_inactive_doctors_orders = false;
        } else {
            show_inactive_doctors_orders = this.state.show_inactive_doctors_orders;
        }

        if (this._is_mounted) {
            this.setState({
                subscriber: subscriber_data,
                doctors_order_list: doctors_orders_data,
                form_submitted: this.props.form_submitted,
                page_state: this.props.page_state,
                show_inactive_doctors_orders: show_inactive_doctors_orders,
            });
        }
    }

    check_active_doctors_orders = (data) => {
        return data.filter(function (row, index) {
            return (row.is_active == true);
        });
    }

    check_doctors_order_row = (e, checked, rowData, index) => {
        var data = Object.assign([], this.state.doctors_order_list);
        data[index].is_selected = checked;
        var allrowChecked = data.filter(function (row, index) {
            return (row.is_selected == false || !row.is_selected);
        });
        var allChecked = false;
        if (allrowChecked.length == 0) {
            allChecked = true;
        } else {
            allChecked = false;
        }
        if (this._is_mounted) {
            this.setState({
                doctors_order_list: data
            });
        }
    }

    show_inactive_doctors_order_change = (e, checked) => {

        //var subscriber_data = Object.assign({}, this.props.subscriber_data);
        //var doctors_orders_data = Object.assign([], subscriber_data.subscriber_doctors_orders);
        //if (!data.checked) {
        //    var active_row = this.check_active_doctors_orders(doctors_orders_data);
        //    if (active_row.length == 0) {
        //        var new_doctors_order = Object.assign({}, this.state.doctors_order);
        //        new_doctors_order.is_active = true;
        //        new_doctors_order.id = 0;
        //        doctors_orders_data.push(new_doctors_order);
        //    }
        //}
        if (this._is_mounted) {
            this.setState({
                show_inactive_doctors_orders: checked,

            });
        }
    }

    add_doctors_order = () => {
        var new_doctors_order = Object.assign({}, this.state.doctors_order);
        var doctors_orders_data = Object.assign([], this.state.doctors_order_list);
        new_doctors_order.is_active = true;
        new_doctors_order.is_shown_active = true;
        new_doctors_order.id = 0;
        new_doctors_order.case_id = this.props.subscriber_data && this.props.subscriber_data.case_id ? this.props.subscriber_data.case_id: 0;
        new_doctors_order.subscriber_id = this.props.subscriber_data && this.props.subscriber_data.id ? this.props.subscriber_data.id : 0;
        new_doctors_order.from_date = date_format_with_no_time(new_doctors_order.from_date); 
        new_doctors_order.thru_date = date_format_with_no_time(new_doctors_order.thru_date);

        doctors_orders_data.push(new_doctors_order);
        if (this._is_mounted) {
            this.setState({
                doctors_order_list: doctors_orders_data
            });
        }
    }

    apply_validations = () => {
        var data = Object.assign([], this.state.doctors_order_list);

        var selected_date_for_validation = data.filter(function (row, index) {
            return row.formatted_from_date == null
        })[0];

        if (selected_date_for_validation) {
            
            for (let row of data) {
                if (row.formatted_from_date == null) {
                    row.show_from_date_error = true
                    row.show_date_error = true
                }
            }

           
        }
        if (this._is_mounted) {
            this.setState({
                doctors_order_list: data
            });
        }
    }
    
    delete_doctors_order = () => {
        var data = Object.assign([], this.state.doctors_order_list);

        var show_dialogue = data.filter(function (row, index) {
            return (row.is_selected == true)
        })[0];

        var new_data = data.filter(function (row, index) {
            return (row.is_selected != true) 
        });

        if (show_dialogue == undefined || show_dialogue.length == 0) {
            /**
            for (let row of data) {
                if (row.is_selected == true) {
                    row.is_active = false
                    row.is_shown_active = false
                }
            }
            */

            toastr.warning('', "Please select a doctor order");
        }

        if (new_data && this._is_mounted) {
            this.setState({
                doctors_order_list: new_data
            });

        }

    }

    

    get_date = (date, pattern) => {
        if (date) {
            return moment(date).format(pattern);
        }
        return '';
    }

    check_invalid_data = () => {

        let doctors_order_list = this.state.doctors_order_list;

        var invalid_data = doctors_order_list.filter(function (row, index) {
            return ((row.comments != null || row.prescribed_visits != null || row.formatted_thru_date != null) && row.formatted_from_date == null);
        });

        if (invalid_data.length > 0) {
            return true;
        }

        return false;
    }

    is_list_empty = () => {

        let doctors_order_list = this.state.doctors_order_list;

        var data = doctors_order_list.filter(function (row, index) {
            return (row.comments != null || row.prescribed_visits != null || row.formatted_thru_date != null || row.formatted_from_date != null);
        });

        if (data.length > 0) {
            return false;
        }

        return true;
    }

    handleChange = (index, row) => {

        var data = [...this.state.doctors_order_list];
        if (typeof index != 'undefined' && typeof row != 'undefined') {

            data[index] = row;
            if (this._is_mounted) {
                this.setState({ doctors_order_list: data });
            }
        }
    }

    on_from_date_change = (deposit_date, index, row) => {
        //const deposit_date = this.get_date(value, 'MM/DD/YYYY');
        var data = [...this.state.doctors_order_list];
        if (typeof index != 'undefined' && typeof row != 'undefined') {

            data[index].formatted_from_date = deposit_date;

            if (deposit_date == null && data[index].show_date_error) {
                data[index].show_from_date_error = true;
            } else {
                data[index].show_from_date_error = false;
            }
            
            if (this._is_mounted) {
                this.setState({ doctors_order_list: data });
            }
        }
    }

    on_thru_date_change = (deposit_date, index, row) => {
        var data = [...this.state.doctors_order_list];
        if (typeof index != 'undefined' && typeof row != 'undefined') {

            data[index].formatted_thru_date = deposit_date;
            if (this._is_mounted) {
                this.setState({ doctors_order_list: data });
            }
        }
    }

    get_valid_doctors_order_list = () => {

        let doctors_order_list = this.state.doctors_order_list;

        var valid_doctors_order_list = doctors_order_list.filter((row) => {
            row.is_shown_active = null;
            row.from_date = row.formatted_from_date == null ? null : date_format_with_no_time(row.formatted_from_date);
            row.thru_date = row.formatted_thru_date == null ? null : date_format_with_no_time(row.formatted_thru_date);
            row.pst_coverage = this.state.insurance_type === enum_type_of_insurance.primary_insurance ? "P" : (this.state.insurance_type === enum_type_of_insurance.secondary_insurance ? "S" : "T");
            return (row.from_date != null);
        });
        if (this._is_mounted) {
            this.setState({
                doctors_order_list: valid_doctors_order_list,
                show_inactive_doctors_orders: false
            });
        }
        return valid_doctors_order_list
    }


    on_save_doctors_order = (case_id, subscriber_id, doctors_order_list) => {

        return add_doctors_order(this.props.user_login_details.user_data.data.token_details.access_token, case_id, subscriber_id, doctors_order_list);
    }

//    reset_fields_with_view_mode

get_doctors_orders_table_row = () => {
    var _this = this;
    return this.state.doctors_order_list.map(function(row, index) {
      if (
        (row.id == 0 && (row.is_active || row.is_shown_active)) ||
        (row.id > 0 && row.is_shown_active) ||
        (_this.state.show_inactive_doctors_orders && row.id > 0 && !row.is_shown_active)
      ) {
        if (row.is_active && row == 0) {
          row.is_shown_active = row.is_active;
        }
        let isCheckBoxDisabled = row.id != 0 ? true : _this.state.page_state === enum_case_page_state.edit;
        let visit_used = !row.visits_used && row.id != 0 ? 0 : row.visits_used;
        return (
          <Table.Row key={index}>
            <Table.Cell textAlign='center'>
              <CheckboxComponent
                id={_this.state.insurance_type + '_doctor_order_row_' + index}
                disabled={isCheckBoxDisabled}
                checked={row.is_selected}
                onChange={(e, checked) => _this.check_doctors_order_row(e, checked, row, index)}
              />
            </Table.Cell>
            <Table.Cell className={`custom-datepicker-for-table datepicker-keybowrd-${index}`}>
              <div className={row.show_from_date_error ? `error requiredWithBgColor` : ``}>
                <DatePicker
                  id={_this.state.insurance_type + '_doctor_order_from_date_' + index}
                  class_name={`datepicker-keybowrd-${index}`}
                  date_update={(date) => {
                    _this.on_from_date_change(date, index, row);
                  }}
                  is_required={true}
                  is_disabled={_this.state.page_state === enum_case_page_state.edit}
                  date={row.formatted_from_date}
                />
              </div>
            </Table.Cell>
            <Table.Cell className={`custom-datepicker-for-table datepicker-keybowrd-thru-date-${index}`}>
              <DatePicker
                id={_this.state.insurance_type + '_doctor_order_thru_date_' + index}
                class_name={`datepicker-keybowrd-thru-date-${index}`}
                date_update={(date) => {
                  _this.on_thru_date_change(date, index, row);
                }}
                disabled={_this.state.page_state === enum_case_page_state.edit}
                is_disabled={_this.state.page_state === enum_case_page_state.edit}
                date={row.formatted_thru_date}
              />
            </Table.Cell>
            <Table.Cell>
              <input
                disabled={_this.state.page_state === enum_case_page_state.edit}
                type='text'
                autoComplete='new-password'
                pattern='[0-9]*'
                value={row.prescribed_visits || ''}
                id={_this.state.insurance_type + '_doctor_order_prescribed_visits_' + index}
                maxLength={9}
                onChange={(e) => {
                  let prescribed_visits;
                  prescribed_visits = e.target.validity.valid
                    ? Number(e.target.value) <= 2147483647
                      ? e.target.value
                      : row.prescribed_visits
                    : row.prescribed_visits;
                  row.prescribed_visits = prescribed_visits;
                  _this.handleChange(index, row);
                }}
              />
            </Table.Cell>
            <Table.Cell>{visit_used}</Table.Cell>
            <Table.Cell>
              <input
                disabled={_this.state.page_state === enum_case_page_state.edit}
                autoComplete='new-password'
                maxLength={50}
                id={_this.state.insurance_type + '_doctor_order_comments_' + index}
                value={row.comments || ''}
                onChange={(e) => {
                  row.comments = e.target.value;
                  _this.handleChange(index, row);
                }}
              />
            </Table.Cell>
            <Table.Cell textAlign='center'>
              <CheckboxComponent
                id={_this.state.insurance_type + '_doctor_order_is_active_' + index}
                disabled={_this.state.page_state === enum_case_page_state.edit}
                checked={row.is_active}
                onChange={(e, checked) => {
                  row.is_active = checked;
                  _this.handleChange(index, row);
                }}
              />
            </Table.Cell>
          </Table.Row>
        );
      }
    });
}
    render() {
        var _this = this;
        var ins_type_id = this.state.insurance_type === enum_type_of_insurance.primary_insurance ? "primary_insurance" : (this.state.insurance_type === enum_type_of_insurance.secondary_insurance ? "secondary_insurance" : "tertiary_insurance");
        return (
          <Grid>
            <Grid.Column>
              <Grid className='padd-15'>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <p className='sub-heading-blue mar-r-15 float-left mar-b-0'> Doctor's Orders</p>
                  {
                    <CheckboxComponent
                      label='Show Inactive'
                      id={ins_type_id + 'show_inactive_doctors_order'}
                      checked={this.state.show_inactive_doctors_orders}
                      onChange={(e, checked) => {
                        this.show_inactive_doctors_order_change(e, checked);
                      }}
                    />
                  }
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <div className='customtable'>
                    <div className='custom-table-responsive table-elements-disabled'>
                      <Table>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell style={{ width: '35px', minWidth: '35px' }}></Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '170px', minWidth: '170px' }}>From Date</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '170px', minWidth: '170px' }}>Through Date</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '160px', minWidth: '160px' }}>Prescribed Visits</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '110px', minWidth: '110px' }}>Visits Used</Table.HeaderCell>
                            <Table.HeaderCell style={{ minWidth: '200px' }}>Comments</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '70px', minWidth: '70px' }} textAlign='center'>
                              Active
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        {this.state.doctors_order_list.length > 0 && <Table.Body>{this.get_doctors_orders_table_row()}</Table.Body>}
                      </Table>
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={16} textAlign='right' className='mar-t-15'>
                  <Button
                    type='button'
                    id={_this.state.insurance_type + 'delete_doctors_order'}
                    basic
                    disabled={_this.state.page_state === enum_case_page_state.edit}
                    onClick={this.delete_doctors_order}
                  >
                    Delete
                  </Button>
                  <Button
                    type='button'
                    primary
                    id={ins_type_id + '_add_doctors_order'}
                    disabled={_this.state.page_state === enum_case_page_state.edit}
                    onClick={this.add_doctors_order}
                  >
                    Add Doctor's Orders
                  </Button>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        patient_details: state.patient_details
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InsuranceDoctorsOrdersComponent))