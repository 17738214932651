import { Popup } from "semantic-ui-react";
import * as React from "react";

const GridCellButtonTooltip = (props) => {
    var content = '';
    switch (props.colDef.field) {
        case 'qualifying_criteria_payer':
            content = props.data.qualifying_criteria_payer_content;
            break;
        case 'qualifying_criteria_insurance_class':
            content = props.data.qualifying_criteria_insurance_class_content;
            break;
        default:
            break;
    }
    if (content.trim() == '') {
        return (<span className="grid_cell_button_tooltip">{props.value}</span>);
    } else {
        return (
            <Popup
                trigger={<span className="grid_cell_button_tooltip">{props.value}</span>}
                content={content}
                on={"hover"} 
                /*size='mini' //font size*/
                flowing
                hoverable
                hideOnScroll
            />
        );
    }
};

export default GridCellButtonTooltip;
