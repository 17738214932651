import * as React from 'react';
import Select from 'react-select';

interface IProps {
    id?: string,
    name?: string,
    style?: object,
    options: any[],
    onChange: Function,
    defaultValue?: React.ReactText,
    placeHolder?: string,
    noOptionsMessage?: string,
    maxMenuHeight?: number,
    isRequired?: boolean,
    disabled?: boolean,
    autoFocus?: boolean,
    openMenuOnFocus?: boolean
}


/** 
* Dropdown search UI component
*/
class SelectionSearch extends React.PureComponent<IProps, any> {

    // Get innitalise data when component created
    constructor(props) {
        super(props);
        this.state = {
            placeHolder: this.props.placeHolder,
            defaultValue: this.props.defaultValue,
            selected_option: this.get_selected_option()
        }
    }

    componentDidUpdate(previousProps, previousState) {
        if ((previousProps.defaultValue !== this.props.defaultValue
            && previousState.defaultValue !== this.props.defaultValue) || (previousProps.options !== this.props.options)) {
            this.setState({
                placeHolder: this.props.placeHolder,
                defaultValue: this.props.defaultValue,
                selected_option: this.get_selected_option()
            })
        }
    }

    /**
     * Selection change
     * @param item 
     */
    handle_change = (item) => {
        this.setState({ selected_option: item },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(item);
                }
            });
    };


    /**
     * Find selected option
     */
    get_selected_option = () => {
        let selected = null;
        if (this.props.options && this.props.options.length > 0) {
            selected = this.props.options.find(obj => obj.value === this.props.defaultValue);
        }
        return selected || null;
    }

    /**
     * Rquired css
     * Apply required error validation on dropdown controll
     */
    is_required = () => {
        if (this.props.isRequired) {
            return {
                border: 'solid 1px #ed1c24',
                backgroundColor: '#feedeb'
            }
        }

        return null;
    }

    /**
     * UI controll css modified
     */
    select_styles = {
        indicatorSeparator: () => ({ display: 'none' }),
        dropdownIndicator: (provided, state) => ({
            ...provided, padding: '5px'
        }),
        clearIndicator: (provided, state) => ({
            ...provided, padding: '5px'
        }),
        control: (provided, state) => ({
            ...provided,
            border: state.isFocused ? '1px solid #85b7d9' : null,
            boxShadow: state.isFocused ? '1px solid #85b7d9' : null,
            ...this.is_required()
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        menuList: (provided, state) => ({
            ...provided, overflowX: "auto"
        }),
    }

    no_options_message = () => <div className="no-data-found">{this.props.noOptionsMessage || ''}</div>

    /**
     * UI render function
     */
    render() {
        return (<Select
            id={this.props.id || 'selection_search'}
            name="Case Select"
            className="basic-single"
            classNamePrefix="select"
            styles={this.select_styles}
            autoFocus={this.props.autoFocus || false}
            isClearable={false}
            isSearchable={true}
            placeholder={this.props.placeHolder || 'Select'}
            noOptionsMessage={this.no_options_message}
            value={this.state.selected_option || null}
            options={this.props.options || []}
            onChange={this.handle_change}
            openMenuOnFocus={this.props.openMenuOnFocus || false}
            maxMenuHeight={this.props.maxMenuHeight || 200}
            menuPortalTarget={document.body}
            menuPosition={'absolute'}
            menuPlacement={'auto'}
            menuShouldScrollIntoView={false}
        />);
    }
};

export default SelectionSearch;