import * as React from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import SelectionComponent from '../../../shared/component/selection_component';

/**
 * Pagination component
 * This is custome pagination and has developed to manage page information, total records
 * Also it's handling page navigation- frist page, last page, previous page, next page
 */

interface IProps {
	fromPage?: number,
	toPage?: number,
	totalRecords?: number,
	isFirstPage?: boolean,
	isLastPage?: boolean,
	onFirst?: Function,
	onPrevious?: Function,
	onNext?: Function,
	onLast?: Function,
	onSpecificPage?: Function,
	totalPages?: number,
	pager: any,
	showPager?: boolean,
	itemsPerPageOptions: number[],
	onPageSizeSelected?: Function,
	message?: string,
	excludePagerSizeSelect?: boolean,
}

export default class NumericPager extends React.Component<IProps, any> {
	eclipse = 5;

	constructor(props) {
		super(props);
	}

    on_first = () => {
        this.props.onFirst();
    }
    on_previous = () => {
        this.props.onPrevious();
    }
    on_next = () => {
        this.props.onNext();
    }
    on_last = () => {
        this.props.onLast();
    }

    on_specific_page = (page) => {
        this.props.pager.curr_page = page;
        this.props.onSpecificPage(page);
    }

    render() {
        return (
            <React.Fragment>
                <div className="grid-wrapper">
                    <div> 
                        <Grid columns={16} className="numberpagination-top" style={{ borderTop: '1px solid #a1aebb', marginBottom: '1px !important'}}>
                            <Grid.Column tablet={16} computer={this.props.excludePagerSizeSelect ? 6 : 4} className="numeric-pager-column">
                                <div style={{ display: 'flex', marginLeft: 7 }}>
                                <div style={{ marginTop: 10 }}>{this.props.message}</div>
                            </div>
                        </Grid.Column>
                        {this.props.excludePagerSizeSelect ?
                            <>
                            </>
                            :
                            <>
                                <Grid.Column tablet={16} computer={4} className="numeric-pager-column">
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ marginTop: 10 }}>View &nbsp;</div>
                                        <SelectionComponent
                                            id='id_items_per_page'
                                            name='items_per_page'
                                            options={this.props.itemsPerPageOptions.map((itemsPerPage, index) => {
                                                return { value: itemsPerPage, text: itemsPerPage }
                                            })}
                                            onChange={this.props.onPageSizeSelected}
                                            defaultValue={15} />
                                        <div style={{ marginTop: 10 }}>&nbsp; items per page</div>
                                    </div>
                                </Grid.Column>
                            </>
                        }
                        <Grid.Column tablet={16} computer={10} className="numeric-pager-column">
                            <div style={{ display: 'flex' }}>
                                <div style={{ paddingTop: 5 }}>
                                    <ul className="numberpagination PageButton" style={{ textAlign: 'center' }}>
                                        {
                                            this.props.pager
                                            && this.props.pager.start_range
                                            && this.props.pager.total_pages > 0
                                            &&
                                            <li>
                                                <button disabled={this.props.isFirstPage} onClick={() => this.on_previous()} className="button-page"><strong>Previous</strong></button>
                                            </li>
                                        }
                                        {
                                                this.props.showPager
                                                    && this.props.pager
                                                    && this.props.pager.start_range
                                                    && this.props.pager.start_page <= 3
                                                    ? this.props.pager.start_range.map((page, index) =>
                                                        <li key={index} className={this.props.pager.curr_page === page ? 'active' : ''}>
                                                            <a onClick={() => this.on_specific_page(page)}>{page}</a>
                                                        </li>
                                                    )
                                                    :
                                                    <>
                                                        <li>
                                                            <button disabled={this.props.isFirstPage} onClick={() => this.on_first()} className="button-page">
                                                                1
                                        </button>
                                                        </li>
                                                        <li>
                                                            <button disabled={this.props.isFirstPage} onClick={() => this.on_specific_page(this.props.pager.curr_page - this.eclipse)} className="button-page">
                                                                ...
                                            </button>
                                                        </li>
                                                    </>
                                        }
                                        {
                                            this.props.showPager
                                            && this.props.pager
                                            && this.props.pager.mid_range
                                            && this.props.pager.mid_range.map((page, index) =>
                                                <li key={index} className={this.props.pager.curr_page === page ? 'active' : ''}>
                                                    <a onClick={() => this.on_specific_page(page)}>{page}</a>
                                                </li>
                                            )
                                        }
                                        {
                                            this.props.showPager
                                                && this.props.pager
                                                && this.props.pager.end_range
                                                && this.props.pager.end_page >= this.props.pager.total_pages - 2
                                                ? this.props.pager.end_range.map((page, index) =>
                                                    <li key={index} className={this.props.pager.curr_page === page ? 'active' : ''}>
                                                        <a onClick={() => this.on_specific_page(page)}>{page}</a>
                                                    </li>
                                                )
                                                :
                                                <>
                                                    <li>
                                                        <button disabled={this.props.isLastPage} onClick={() => this.on_specific_page(this.props.pager.curr_page + this.eclipse)} className="button-page">
                                                            ...
                                            </button>
                                                    </li>
                                                    <li>
                                                        <button disabled={this.props.isLastPage} onClick={() => this.on_last()} className="page-arrow-right button-page">
                                                            {this.props.pager.total_pages}
                                                        </button>
                                                    </li>
                                                </>
                                        }
                                        {
                                            this.props.pager
                                            && this.props.pager.start_range
                                            && this.props.pager.total_pages > 0
                                            &&
                                            <li>
                                                <button disabled={this.props.isLastPage} onClick={() => this.on_next()} className="button-page"><strong>Next</strong></button>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </Grid.Column>
                        </Grid>
                    </div>
                </div>
            </React.Fragment>

        );
    }
}