import * as React from 'react';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import * as draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import RichTextEditorHelper, { IRichTextEditorProps } from './rich_text_editor_helper';
import CustomControls from './custom_controls';


// #region State Definition
interface IRichTextEditorState {
    editorState: EditorState,
    is_editor_readonly: boolean,
    hide_toolbar: boolean
}
// #endregion

class RichTextEditor extends React.Component<IRichTextEditorProps, any> {

    // #region Class Properties
    state: IRichTextEditorState;
    editor_style_config: any; // Editor Style Config
    tool_bar_config: any // Toolbar config definition object
    tool_bar_class_name: string // Tool bar css class name
    custom_menu_toolbar: Array<any>; //Array of custom menu component
    // #endregion Class Properties

    // #region Constructor
    constructor(props) {
        super(props);

        // #region Data Conversion from HTML
        const blocks_from_html = htmlToDraft(this.props.editorContent);

        const content_state = ContentState.createFromBlockArray(
            blocks_from_html.contentBlocks,
            blocks_from_html.entityMap,
        );
        // #endregion Data Conversion from HTML

        // #region Intialize the config for toolbar
        this.initialize_custom_config();
        // #endregion Intialize  the config for toolbar

        // #region Initialize State
        this.state = {
            editorState: EditorState.createWithContent(content_state),
            is_editor_readonly: this.props.readonly ? this.props.readonly : false,
            hide_toolbar: this.props.hideToolbar ? this.props.hideToolbar : false
        };
        // #endregion Initialize State
    }
    // #endregion Constructor

    // #region Initialize tool config
    initialize_custom_config() {
        this.editor_style_config = RichTextEditorHelper.get_editor_style_for_react_draft(this.props.editorStyle, this.props.readonly);
        this.tool_bar_config = RichTextEditorHelper.get_react_draft_toolbar_object(this.props.toolbarConfig);
        this.tool_bar_class_name = this.props.toolbarClassName ? this.props.toolbarClassName : "toolbar-class";
        this.custom_menu_toolbar = this.get_tool_bar_custom_buttons();
    }
    // #endregion  Initialize tool config

    // #region Event Handling
    onChange = (editorState: EditorState) => {
        if (this.is_props_defined(this.props.onChange)) {
            let letter_content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            letter_content = letter_content.split('<p></p>').join('<p>&nbsp;</p>');
            this.props.onChange(letter_content);
        }
        this.setState({ editorState });
    }
    // #endregion Event Handling

    // #region Helper methods

    is_props_defined(callback): boolean {

        let result: boolean = true;

        if (callback == null || callback == undefined)
            result = false;

        return result;
    }
    // #endregion Helper Methods

    // #region Custom Menu Options
    get_tool_bar_custom_buttons = () => {
        let listItem: Array<any> = [];

        if (!this.is_props_defined(this.props.toolbarCustomMenuConfig)) return [];
        let CustomMenuControl;
        this.props.toolbarCustomMenuConfig.map((config) => {
            
            CustomMenuControl = CustomControls.get_custom_menu_component(config.controlType);

            if (CustomMenuControl) listItem.push(<CustomMenuControl onChange={this.onChange} config={config} />);

        });

        return listItem;
    }
    // #endregion Custom Menu Options

    render() {
        return (
            <Editor
                editorState={this.state.editorState}
                editorStyle={this.editor_style_config}
                toolbarHidden={this.state.hide_toolbar}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName={this.tool_bar_class_name}
                onEditorStateChange={this.onChange}
                readOnly={this.state.is_editor_readonly}
                toolbar={this.tool_bar_config}
                toolbarCustomButtons={this.custom_menu_toolbar}
            />
        );
    }
}

export default RichTextEditor;