import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastr as toaster } from "react-redux-toastr";
import { ftp_scheduler_configuration } from '../../dashboard/dashboard_constants';
import ReportHeader from '../../../reports/component/report_header';
import { bindActionCreators } from 'redux';
import * as global_constants from '../../../global_constants';
import { dateTimeFormatter, messages } from '../../../reports/report_constants';
import { custom_date_format, get_all_error, get_columns, show_html_content_toaster, strip } from '../../../shared/utility';
import { get_schedules } from '../action/ftp_schedule_actions';
import { log_error } from '../../../shared/action/shared_action';
import { isNullOrUndefined } from 'util';
import { Dimmer, Loader } from 'semantic-ui-react';
import GridView from '../../../shared/component/grid';
import * as moment from 'moment';

export class FtpSchedulerComponent extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    is_mounted: boolean;
    current_date: string = "";
    title: string = "";
    token: string = "";
    grid_header_height: number = 0;
    columns_definition: any[] = [];
    page_settings = ftp_scheduler_configuration(this.props);
    report_data: any = { rows: [] };

    componentDidMount = async () => {
        this.is_mounted = true;
        document.body.classList.add('admin-framework');
        this.columns_definition = this.page_settings.columns_definition;

        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.current_date = custom_date_format(new Date(), global_constants.date_format["mm/dd/yyyy h:MM:ss TT"]);
        
        this.setState({
            loading: true,
        });

        try {
            let response = await get_schedules(this.token);

            if (response.data) {
                response.data = this.row_data_formatter(response.data, this.columns_definition);
                this.report_data["rows"] = response.data || [];
            } else {
                log_error(response.data.messages[0].message.indexOf("<br") > -1);
                if (response.data.messages[0].message.indexOf("<br") > -1) {
                    const toastr_options = show_html_content_toaster(get_all_error(response.data.messages[0].message));
                    toaster.error("", toastr_options);
                }
            }

            if (this.is_mounted) {
                this.setState({
                    loading: false,
                });
            }
        } catch (error) {
            if (this.is_mounted) {
                this.setState({
                    loading: false,
                });
            }
            log_error(error);
            if (error.response.data) {
                const toastr_options = show_html_content_toaster(get_all_error(error.response.data));
                toaster.error("", toastr_options);
            }
        }
    };

    componentWillUnmount = () => {
        this.is_mounted = false;
        document.body.classList.remove('admin-framework');
        this.report_data = {};
    };

    row_data_formatter = (row_data, column_def) => {
        row_data.map((row) => {
            column_def.map((value, index) => {
                switch (value.type) {
                    case "boolean":
                        if (value.field == "isDisabled") {
                            row[value.field] = row[value.field] == true ? "Inactive" : "Active";
                        }
                        if (value.field == "lastRunStatus") {
                            // In DB it could be 1, 0 or null, we need to consider all the cases
                            row[value.field] = row[value.field] == true
                                ? "Success"
                                : row[value.field] == false
                                    ? "Fail"
                                    : "";
                        }
                        if (value.field == "isRunning") {
                            row[value.field] = row[value.field] == true ? "Processing" : "";
                        }
                        break;
                    case "string":
                            if (value.field == "scheduleTime") {
                                let scheduleTime = moment.duration(row[value.field]);
                                row[value.field] = moment.utc(scheduleTime.asMilliseconds()).format("HH:mm");
                            }
                            break;
                    case "datetime":
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined ? dateTimeFormatter(row[value.field]) : "";
                        break;
                    default:
                        row[value.field] = !isNullOrUndefined(row[value.field]) ? row[value.field] : "";
                }
            });

            return row;
        });
        return row_data;
    };

    get_grid_ref = (grid_params) => {
        this.setState({
            grid_params,
            report_rows_count: grid_params.api.getModel().getRowCount()
        });
    };

    render() {
        return (
            <div className={"common-forms-add"}>
                <Dimmer active={this.state.loading}>
                    <Loader size="massive">Loading</Loader>
                </Dimmer>
                <div className={"common-forms-search report-framework batch-tasks-program"} style={{ paddingRight: 0 }}>
                    <div style={{display:'flex', alignItems:'center', marginBottom: '5px'}}>
                        <h3 style={{ marginBottom: '0px', fontWeight: 'bold', fontSize: '22px', padding: '5px 5px 10px 5px' }}>{this.page_settings.title}</h3>
                        <div style={{flex:'1'}}></div>
                        <div style={{display:'flex', paddingRight: '20px', cursor: 'pointer'}} onClick={()=>{this.props.history.push(`/admin/dashboard/ftp_scheduler/scheduled_run_details`);}}>
                            <svg style={{marginRight: '10px', fill:'#00A7E1'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <path className="a" d="M11-15H9v4H5v2H9v4h2V-9h4v-2H11Zm-1-5A10,10,0,0,0,0-10,10,10,0,0,0,10,0,10,10,0,0,0,20-10,10,10,0,0,0,10-20ZM10-2a8.011,8.011,0,0,1-8-8,8.011,8.011,0,0,1,8-8,8.011,8.011,0,0,1,8,8,8.011,8.011,0,0,1-8,8Z" transform="translate(0 20)"/>
                            </svg>
                            <span style={{fontWeight:'bold', color: '#00A7E1'}}>Add Scheduled Run</span>
                        </div>
                    </div>
                    <div className="report-wrapper report_with_scrolling_and_vp_height">
                        <React.Fragment>
                            <div className={"report_wrapper_with_min_height"}>
                                <GridView
                                    id={this.page_settings.report_grid_id}
                                    row={this.report_data.rows}
                                    headerHeight={this.grid_header_height}
                                    get_grid_ref={this.get_grid_ref}
                                    column={this.columns_definition}
                                    enableColResize={true}
                                    emptyMessage={messages.no_records_found}
                                    headerIdForTabNavigation={this.page_settings.report_grid_id}
                                    suppressSizeToFit={true}
                                    gridAutoHeight={true}
                                    paginationPageSize={this.page_settings.default_page_size}
                                    isPagination={true}
                                    paginationMessage={this.current_date}
                                    totalInBetween = {true}
                                    wrapperClass={'ftpSchedulerGridWrapperClass'}
                                    wrapperStyle={{width: '100%'}}
                                />
                            </div>
                        </React.Fragment>
                    </div>
                </div>
            </div>);
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
        },
        dispatch
    );
};

const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FtpSchedulerComponent));
