import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Dimmer, Form, Grid, Header, Loader, Modal } from "semantic-ui-react";
import * as global_constants from '../../global_constants';
import * as session_storage from '../../shared/session_storage_utility';
import { custom_date_format, get_all_error } from './../../shared/utility';
import { charge_review_summary_id, currencyFormatter, charge_messages, headerNoFilterIconTemplate, amount_headerTemplate } from './../charge_constant';
import { set_focus_to_app_header, set_focus_on_element_with_id } from './../../shared/tab_navigation_utility';
import GridView from '../../shared/component/grid';
import { get_charge_review_summary, release_charge } from './../action/charge_action';
import { currency_comparator, number_comparator, text_comparator, mm_dd_yyyy_comparator } from './../../shared/component/grid/comparators';
import ConfirmationComponent from '../../shared/component/confirmation_component';

export class ChargeReviewSummaryComponent extends React.Component<any, any> {
    [x: string]: any;
    _is_mounted = false;
    total_charges = 0;
    total_charges_amount = 0;

    charge_review_summary = {

        charge_review_summary_def: [
            {
                headerName: "",
                headerComponentParams: headerNoFilterIconTemplate,
                field: "location.id",
                headerCheckboxSelection: true,
                checkboxSelection: true,
                width: 50,
                suppressSorting: true,
                suppressFilter: true,
                suppressSizeToFit: true,
                cellRenderer: params => this.charge_review_cell_renderer(params, 'location_id'),
                valueGetter: function chainValueGetter(params) {
                    return '';
                }
            },
            {
                headerName: 'Location',
                headerComponentParams: headerNoFilterIconTemplate,
                field: 'location.name',
                filter: 'textFilter',
                type: 'string',
                minWidth: 270,
                //width: 200,
                unSortIcon: true,
                suppressFilter: true,
                comparator: text_comparator,
                //cellRenderer:'LinkRendererComponent'
                cellRenderer: params => this.charge_review_cell_renderer(params, 'location_name'),
            },
            {
                headerName: 'Charges for Review',
                headerComponentParams: amount_headerTemplate,
                field: 'audit_charges_count',
                filter: 'textFilter',
                type: 'number',
                minWidth: 200,
                //width: 200,
                unSortIcon: true,
                suppressFilter: true,
                comparator: number_comparator,
                cellStyle: { textAlign: 'right' },
                //cellClass: 'href_link',
                // cellRenderer: 'LinkRendererComponent',
                cellRenderer: params => this.charge_review_cell_renderer(params, 'audit_charges'),
            },
            {
                headerName: 'Audit Charge Amount',
                headerComponentParams: amount_headerTemplate,
                field: 'audit_charges_amount',
                filter: 'textFilter',
                type: 'currency',
                minWidth: 200,
                //width: 180,
                unSortIcon: true,
                suppressFilter: true,
                comparator: currency_comparator,
                cellStyle: { textAlign: 'right' },
            },
            {
                headerName: 'Posting Date',
                headerComponentParams: headerNoFilterIconTemplate,
                field: 'posting_date',
                filter: 'dateFilter',
                type: 'date',
                minWidth: 180,
                //width: 40,
                unSortIcon: true,
                suppressFilter: true,
                comparator: mm_dd_yyyy_comparator
            },

        ],
        page_size: 5000
    }
    constructor(props) {
        super(props);
        this.state = {
            show_grid: false,
            total_charges: 0,
            total_charges_amount: 0,
            charge_audit_list: [],
            footer_row: [],
            loading: false,
            selected_charge_list: [],
            disable_charge_release_button: false,
            show_bummepd_charge_message_popup:false
        }
    }
    charge_review_cell_renderer = (params, col_name) => {
        var eDiv = document.createElement('div');
        if (col_name == 'location_id') {
            var icon = '';
            if (params.data.location.id == "Total" || !params.data.location.id) {
                if (params.data.location.id == "Total") {
                    if (params.eGridCell && params.eGridCell.querySelector('.ag-selection-checkbox')) {
                        params.eGridCell.querySelector('.ag-selection-checkbox').remove()
                    }
                    icon = 'Total'
                }
                eDiv.innerHTML = `<span>${icon}</span>`;
            }
        }
        else if (col_name == 'audit_charges') {
            if (params.data.location.id == "Total") {
                eDiv.innerHTML = '<span id=' + params.data.location.id + ' data-attr=' + params.data.location.id + '>' + params.data.audit_charges_count + '</span>'
            }
            else {
                //<Link to={`/?info=${params.data.Id}`}>"+{params.value}+"</Link>
                //eDiv.innerHTML = `<span>` + params.data.audit_charges_count + `</span>`;
                eDiv.innerHTML = '<span id=' + params.data.location.id + ' class="href_link" data-attr=' + params.data.location.id + '>' + params.data.audit_charges_count + '</span>'
                eDiv.addEventListener('click', (para) => {
                    this.props.history.push({
                        pathname: '/charge/charge_review_detail/' + params.data.location.id,
                        search: '',
                        state: { charge_visit: params.data.location }
                    })
                });
            }
        }
        else if (col_name == 'location_name') {
            if (params.data.location.id != "Total") {
                eDiv.innerHTML = '<span>' + params.data.location.name + ' (' + params.data.location.code + ') ' + '</span>';
            }
        }
        return eDiv;
    }
    patient_id = session_storage.get('active_patient');
    componentDidMount = () => {
        this._is_mounted = true;
        this.patient_id = session_storage.get('active_patient');
        document.body.classList.add('charge-review');
        this.get_charge_review_summary();
    };
    get_charge_review_summary = () => {
        if (this._is_mounted) {
            this.setState({ loading: true });
        }
        get_charge_review_summary(this.props.user_login_details.user_data.data.token_details.access_token).then(res => {
            if (res.data.data) {
                if (res.data.data.length > 0) {
                    let charge_review_data = this.format_data(res.data.data);
                    var footer_obj = {};
                    this.charge_review_summary.charge_review_summary_def.map((value, index) => {

                        if (value.type == 'currency' || value.type == 'number') {
                            footer_obj[value.field] = 0;
                        } else {
                            footer_obj[value.field] = '';
                        }
                    });
                    footer_obj['location'] = { id: 'Total' };
                    footer_obj['audit_charges_count'] = this.total_charges;
                    footer_obj['audit_charges_amount'] = currencyFormatter(this.total_charges_amount);
                    if (this._is_mounted) {
                        this.setState({
                            charge_audit_list: charge_review_data,
                            footer_row: [footer_obj],
                            loading: false,
                            show_grid: true
                        })
                    }
                }
                else {
                    if (this._is_mounted) {
                        this.setState({
                            charge_audit_list: [],
                            footer_row: [],
                            loading: false,
                            show_grid: false
                        })
                    }
                }

            } else {
                if (this._is_mounted) {
                    this.setState({
                        charge_audit_list: [],
                        footer_row: [],
                        loading: false,
                        show_grid: false
                    })
                }
            }
        })


    }
    format_data = (summary_data) => {
        let formatted_data = [];
        let total_charges = 0;
        let total_charges_amount = 0;
        for (let row = 0; row < summary_data.length; row++) {
            total_charges += summary_data[row].audit_charges_count;
            total_charges_amount += summary_data[row].audit_charges_amount;
            summary_data[row].posting_date = custom_date_format(summary_data[row].posting_date, global_constants.date_format["mm/dd/yyyy"])
            summary_data[row].audit_charges_amount = currencyFormatter(summary_data[row].audit_charges_amount);
            formatted_data.push(summary_data[row]);
        }
        this.total_charges = total_charges;
        this.total_charges_amount = total_charges_amount;
        return formatted_data;
    }
    
    componentWillUnmount = () => {
        this._is_mounted = false;
        document.body.classList.remove('charge-review');
    }

    on_document_check_changed = items => {
        if (this._is_mounted) {
            this.setState({
                selected_charge_list: [...items]
            });
        }
    }
    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }

    release_charge = () => {
        let release_charges = this.state.selected_charge_list.map((item, index) => {
            return { charge_id: 0, pending_reason_id: 0, location_id: item.location.id, charge_open_time: item.charge_open_time }
        });
        if (release_charges.length > 0) {
            if (this._is_mounted) {
                this.setState({ loading: true, disable_charge_release_button: true });
            }
            release_charge(this.props.user_login_details.user_data.data.token_details.access_token, release_charges, this.props.user_login_details.user_data.data.company_id).then(res => {
                if (res.data.status===1) {
                    toastr.success('', charge_messages.charge_released);
                    if (this._is_mounted) {
                        this.setState({ loading: false, disable_charge_release_button: false, selected_charge_list: [] });
                    }
                    this.get_charge_review_summary();

                } else {
                    if (this._is_mounted) {
                        this.setState({ loading: false });
                    }
                    if (res.data.messages.length > 0) {
                        const toastr_options = this.show_html_content_toaster(get_all_error(res.data));
                        toastr.error('', toastr_options);
                    }
                }
            }, (error) => {
                if (error && error.response && error.response.data && error.response.data.messages && error.response.data.messages) {
                    let charge_exception = error.response.data.messages.filter((item) => {
                        return item.code === global_constants.charge_exception.code
                    })
                    if (charge_exception && charge_exception.length > 0) {
                        if (this._is_mounted) {
                            this.setState({
                                loading: false,
                                show_bummepd_charge_message_popup: true,
                                disable_charge_release_button: false
                            })
                        }
                    } else {
                        if (this._is_mounted) {
                            this.setState({ loading: false, disable_charge_release_button: false });
                        }
                    }
                } else {
                    if (this._is_mounted) {
                        this.setState({ loading: false, disable_charge_release_button: false });
                    }
                }
            });
        }
        else {
            toastr.warning('', charge_messages.no_audit_selected);
        }
    }
    handle_after_grid_is_ready = () => {
        // when thr grid is ready setting the focus to the grid header
        this.set_focus_to_first_cell_of_the_header();
    }

    throw_me_out_from_the_grid = () => {
        set_focus_on_element_with_id("charge_release_btn")
    }

    set_focus_to_first_cell_of_the_header = () => {
        let firstColumnsOfGrid: NodeListOf<HTMLElement> = document.getElementById("view_charge_grid").querySelectorAll('.ag-header-cell:not(.ag-header-cell-sortable)') as NodeListOf<HTMLElement>;
        let firstColumnOfGrid = firstColumnsOfGrid[0]
        firstColumnOfGrid.setAttribute("tabindex", "0");
        firstColumnOfGrid.focus();
    }

    refresh_bummepd_charge_modal = () => {
        if (this._is_mounted) {
            this.setState({
                show_bummepd_charge_message_popup: false
            }, () => {
                this.get_patient_charge_list();
            });
        }
    }
    on_close_bummepd_charge_popup = () => {
        if (this._is_mounted) {
            this.setState({
                show_bummepd_charge_message_popup: false
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <Dimmer active={this.state.loading}>
                    <Loader size="massive">Loading</Loader>
                </Dimmer>
                <div className="common-forms-add padd-b-0">

                    <Header as='h3' textAlign="left" id="applicationPageTitleSticky">
                        Charge Review Summary
                        </Header>
                    {
                        <div className='auto-height-patient-scroll' id="scrollable-content-area">
                            <Form autoComplete="off" className="patient-add-form padd-b-0" style={{ height: '100%' }}>
                                <div className="common-forms-add charge_review_summry_container" style={{ padding: "0", height: '100%' }}>
                                    <GridView
                                        id={'view_charge_grid'}
                                        onRef={instance => { this.grid_reference = instance; }}
                                        style={{ height: '100%' }}
                                        wrapperStyle={{ width: '100%', height: '100%', display: 'flex', flex: '1 1 0%' }}
                                        column={this.charge_review_summary.charge_review_summary_def}
                                        row={this.state.charge_audit_list}
                                        checkboxSelection={true}
                                        onRowSelection={this.on_document_check_changed}
                                        pinnedBottomRowData={this.state.footer_row}
                                        get_grid_ref={this.handle_after_grid_is_ready}
                                        onGridOut={this.throw_me_out_from_the_grid}
                                        handleCheckboxNavigation={true}
                                    />
                                </div>
                            </Form>
                        </div>
                    }
                    {this.state.show_grid &&
                        (this.state.charge_audit_list.length > 0 &&
                            <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area" id="applicationFooterSticky">
                                <Button id="charge_release_btn" disabled={this.state.disable_charge_release_button} onKeyDown={set_focus_to_app_header} type='button' primary onClick={() => this.release_charge()}>Release Charges</Button>
                            </div>
                        )
                    }
                </div>
                {this.state.show_bummepd_charge_message_popup ?
                    <ConfirmationComponent message={global_constants.charge_exception.message}
                        title='Confirm' show_modal={this.state.show_bummepd_charge_message_popup}
                        onCloseModal={this.on_close_bummepd_charge_popup}
                        save_button_text='Refresh'
                        on_force_save_click={(e) => { this.refresh_bummepd_charge_modal() }} />
                    : ''}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        patient_id: state.patient_details && state.patient_details.patient_header && state.patient_details.patient_header.data && state.patient_details.patient_header.data.id || ''
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        //update_patient_header_info: update_patient_header_info,
    }, dispatch)
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChargeReviewSummaryComponent));