import { AgGridReact } from 'ag-grid-react';
import 'ag-grid/dist/styles/ag-grid.css';
import 'ag-grid/dist/styles/ag-theme-balham.css';
import * as React from 'react';
import { clearTimeout, setTimeout } from 'timers';
import LinkRendererComponent from '../../../reports/util/link_renderer_component';
import PopUpLinkRenderer from '../popup_link_renderer_component';
import GroupPanelComponent from './group_panel_component';
import { DIMEN, GRID_AUTO_HEIGHT_STYLE, GRID_BASE_STYLE, GRID_FILTER_COMPONENTS, ICONS, TEMPLATE_LOADING, TEMPLATE_NO_RECORD } from './constants';
import { Pagination } from './pagination_component';
import NumericPager from './numeric_pager';
import { clear_all_filter, clear_filter, filterSelectedDisplay, getRowHeight, isTouchDevice, copy_cell_text, group_row_by_column, groupRowByColumnWithRowsCount, filterRowDataForGrouped } from './utils';
import GridCellTooltip from './grid_tooltip_component';
import GridCellButtonTooltip from './grid_cell_button_tooltip_component';

interface IProps {
	id?: string,
	name?: string,
	column: any[],
	row: any[],
	onRowSelection?: Function,
	onRowDoubleClicked?: Function,
	selectionType?: string,
	paginationPageSize?: number,
	isPagination?: boolean,
	paginationMessage?: string,
	emptyMessage?: string,
	loadingMessage?: string,
	headerHeight?: number,
	style?: any,
	wrapperStyle?: object,
	wrapperClass?: string,
	suppressMovableColumns?: boolean,
	suppressColumnVirtualisation?: boolean,
	enableColResize?: boolean,
	gridAutoHeight?: boolean,
	gridFullHeight?: boolean,
	gridAutoRowHeight?: boolean,
	checkboxSelection?: boolean,
	radioSelection?: boolean,
	rowGroupPanelShow?: boolean,
	get_grid_ref?: Function,
	isTotal?: boolean,
	pinnedBottomRowData?: any[],
	on_filter_button_click?: Function,
	onCellClicked?: Function,
	getRowHeight?: Function,
	isRowSelectable?: Function,
	onGroupByChange?: Function,
	onGridOut?: Function,
	onForceGridOut?: Function,
	suppressSizeToFit?: boolean,
	onRef?: any,
	headerIdForTabNavigation?: string,
	handleCheckboxNavigation?: boolean, // this will make control smart enough to add navigation for checkbox grids
	enableEnterOnNavigation?: boolean,  // props enable enter on row if it's click is available
	onPaginationChange?: Function,
	displayGroupRowCount?: boolean,
	rowClassRules?: object,
	onPaginationChangeEvent?: Function,
	suppressHorizontalScroll?: boolean,
	renderTopPager?: boolean,
	renderBottomNumericPager?: boolean,
	itemsPerPageOptions?: number[],
	onPageSizeChange?: Function,
	numericPagerMessage?: string,
    excludePagerSizeSelect?: boolean,
    onCellHoverTooltipColumn?: string,
    onCellHoverTooltipSource?: string,
    onCellMouseOverFunc?: Function,
	onCellMouseOutFunc?: Function,
	getRowStyle?: Function
	expandGroupByDefault?: boolean,
	customColumnToTotal?: [],
	totalInBetween?: boolean,
}

/**
 * Grid view component use to display data
 */
class GridView extends React.Component<IProps, any> {
	_is_mounted = false;
	gridApi: any;
	gridColumnApi: any;
	focused_cell_column: any;
	focused_cell_index: any;
	container_grid: HTMLElement;
	ref_group_panel: any;

	// Get initialize data when component created
	constructor(props) {
		super(props);
		const pageSize = this.props.paginationPageSize || DIMEN.PAGINATION_SIZE;
		this.state = {
			frameworkComponents: { ...GRID_FILTER_COMPONENTS, LinkRendererComponent: LinkRendererComponent, PopUpLinkRenderer: PopUpLinkRenderer, GridCellTooltip: GridCellTooltip, GridCellButtonTooltip: GridCellButtonTooltip },
			defaultColDef: { suppressKeyboardEvent: this.keyboardEvent.bind(this) },
			getRowHeight: getRowHeight(this.props.name),
			current_page: 1,
			from_page: 1,
			to_page: this.props.row ? pageSize > this.props.row.length ? this.props.row.length : pageSize : 0,
			total_record: this.props.row ? this.props.row.length : 0,
			total_page: this.props.row ? ((this.props.row.length % pageSize) > 0 ? parseInt((this.props.row.length / pageSize).toString()) + 1 : parseInt((this.props.row.length / pageSize).toString())) : 0,
			is_first_page: true,
			is_last_page: true,
			is_show_number_pager: false,
			is_google_paging: false,
			pager: {},
			grouped_rows: [],
			grouped_fields: [],
			onPaginationChangeEvent: null
		};

		this.set_focus_on_first_cell_of_first_column = this.set_focus_on_first_cell_of_first_column.bind(this);
	}

	// F=grid function callback on ready state
	onGridReady(params) {
		if (this._is_mounted) {
			this.gridApi = params.api;
			this.gridColumnApi = params.columnApi;
			// navigate_tab_grid_first_cell(params, this.props.id || "myGrid");
			params.api.sizeColumnsToFit();
			if (isTouchDevice()) {
				params.api.addEventListener('rowClicked', this.onRowClick.bind(this));
			} else {
				params.api.addEventListener('rowDoubleClicked', this.onRowDoubleClick.bind(this));
			}
			if (this.props.selectionType || this.props.checkboxSelection || this.props.radioSelection) {
				params.api.addEventListener('rowSelected', this.onSelectionChanged.bind(this));
			}
			if (this.props.gridAutoHeight) {
				params.api.addEventListener('columnResized', this.onColumnResized.bind(this));
				if (this._is_mounted) {
					this.setState({
						timeout: setTimeout(() => {
							params.api.resetRowHeights();
						}, 500)
					});
				}
			}

			if (!this.props.suppressSizeToFit) {
				params.api.addEventListener('gridSizeChanged', this.onGridResize.bind(this));
			}

			if (this.props.get_grid_ref) {
				this.props.get_grid_ref(params);
			}

			this.set_pager(1);
			this.handle_first_tab();
			this.container_grid = document.querySelector(`#${this.props.id}`);
			if (this.container_grid) {
				this.container_grid.addEventListener('mouseleave', this.handle_mouse_leave);
				if (this.props.rowGroupPanelShow) {
					const grid_column_nodes: NodeListOf<HTMLElement> = document.getElementById(this.props.id).querySelectorAll('.ag-cell-label-container .ag-header-cell-label') as NodeListOf<HTMLElement>;
					if (grid_column_nodes && grid_column_nodes.length > 0) {
						for (var i = 0; i < grid_column_nodes.length; i++) {
							let node = grid_column_nodes[i];
							if (node.draggable) {
								node.ondragstart = this.onDragStart;
								node.onclick = this.handle_header_click;
							}
						}
					}
				}
			}
			this.setGridOverflow();

		}
	}

	onDragStart = (dragEvent) => {
		var userAgent = window.navigator.userAgent;
		var isIE = userAgent.indexOf('Trident/') >= 0;
		let column_value = dragEvent.target.dataset;
		if (column_value) {
			dragEvent.dataTransfer.setData(isIE ? 'text' : 'text/plain', JSON.stringify(column_value));
		}
	};

	handle_header_click = (e) => {
		let items = this.gridApi.getSortModel();
		if (this.ref_group_panel) {
			this.ref_group_panel.set_sort_model(items);
		}
	}

	handle_mouse_leave = event => {
		this.gridApi.clearFocusedCell();
	}

    /**
     * Handle the tab event for the first time
     */
	handle_first_tab = () => {
		if (this.props.headerIdForTabNavigation) {
			let self = this;
			let header: HTMLElement = document.getElementById(this.props.headerIdForTabNavigation) as HTMLElement;
			if (header) {
				// remove if already exist
				header.removeEventListener("keydown", this.event_listener_to_handle_header_tab, true);
				// Add listener
				header.addEventListener("keydown", this.event_listener_to_handle_header_tab);
			}
		} else if (this.props.handleCheckboxNavigation && this.props.id) {
			// here i want to apply tab index on checkbox header created
			const gridCells: NodeListOf<HTMLElement> = document.getElementById(this.props.id).querySelectorAll('.ag-header-cell:not(.ag-header-cell-sortable)') as NodeListOf<HTMLElement>;
			gridCells[0].setAttribute("tabindex", "0");
			gridCells[0].removeEventListener("keydown", this.event_listener_to_handle_checkbox_header_tab, true);
			gridCells[0].addEventListener("keydown", this.event_listener_to_handle_checkbox_header_tab);
		}
	}

    /**
     * Handle checkbox based grid navigation.
     * */
	event_listener_to_handle_checkbox_header_tab = (event) => {
		// 32 fpr spacer
		if (event.which == 32) {
			event.target.querySelector('span').click();
		}

		if (!event.shiftKey && event.keyCode == 9 && this.props.row.length > 0) {
			event.preventDefault();
			this.set_focus_on_first_cell_of_first_column();
		}
	}

    /*
     * Handle navigation for Ist header of the grid
     * */
	event_listener_to_handle_header_tab = (event) => {
		if (!event.shiftKey && event.keyCode == 9 && this.props.row.length > 0) {
			event.preventDefault();
			this.set_focus_on_first_cell_of_first_column();
		}
	}

    /**
     * Set focus to the first cell of first display row.
     * This is done to handle the tabbing when we traverse the grid again and again
     * */
	set_focus_on_first_cell_of_first_column = () => {
		if (this.gridApi && this.gridColumnApi) {
			// scrolls to the first row
			this.gridApi.ensureIndexVisible(0);

			// scrolls to the first column
			var firstCol = this.gridColumnApi.getAllDisplayedColumns()[0];
			this.gridApi.ensureColumnVisible(firstCol);

			let topRowIndex = 0;
			if (this.props.paginationPageSize) {
				topRowIndex = (this.state.current_page - 1) * this.props.paginationPageSize;
				// sets focus into the first grid cell
				this.gridApi.setFocusedCell(topRowIndex, firstCol);
			} else {
				// sets focus into the first grid cell
				this.gridApi.setFocusedCell(0, firstCol);
			}

			if ((!this.props.checkboxSelection) && (!this.props.radioSelection)) {
				this.gridApi.forEachNode((node) => {
					if (node.rowIndex == topRowIndex) {
						node.setSelected(true)
					}
				});
			}
		}
	}

	// Keyboard enter pressed event
	keyboardEvent({ event }) {
		copy_cell_text(event, this.gridApi);// Mac safari copy
		if (this.props.selectionType || this.props.enableEnterOnNavigation) {
			// handling grid behavior on enter
			if (event.which === 13) {
				if (this.props.enableEnterOnNavigation) {
					let focussedCell = this.gridApi.getFocusedCell();
					if (focussedCell && this.props.onRowDoubleClicked) {
						this.gridApi.clearFocusedCell();
						this.props.onRowDoubleClicked(this.props.row[focussedCell.rowIndex]);
					}
				} else {
					const row_node = this.gridApi.rowModel.rowsToDisplay[this.focused_cell_index];
					if (row_node && row_node.data) {
						this.gridApi.clearFocusedCell();
						this.props.onRowDoubleClicked && this.props.onRowDoubleClicked(row_node.data);
					}
				}
			}

			if (event.which == 32) {
				if (this.props.enableEnterOnNavigation && this.props.radioSelection) {
					let firstColumn = this.gridColumnApi.getAllColumns()[0].colId;

					// if the row is focussed
					if (this.gridApi.getFocusedCell() && this.gridApi.getFocusedCell().column && this.gridApi.getFocusedCell().column.colId) {
						let currentColumnField = this.gridApi.getFocusedCell().column.colId;

						if (firstColumn == currentColumnField) {
							event.target.querySelector('input').click();
						}
					}
				}
			}

			// handling navigation for tab and shift tab
			if (event.keyCode == '9') {

				if (!this.props.enableEnterOnNavigation) {
					let lastColumn = this.gridColumnApi.getAllColumns()[this.gridColumnApi.getAllColumns().length - 1].colId;
					let firstColumn = this.gridColumnApi.getAllColumns()[0].colId;
					let currentColumnField = this.gridApi.getFocusedCell().column.colId;

					if (!event.shiftKey) {
						if (lastColumn == currentColumnField) {
							if (this.state.total_record == this.gridApi.getFocusedCell().rowIndex + 1) {
								// sets focus into the first grid cell
								this.gridApi.ensureColumnVisible(firstColumn);
								this.gridApi.clearFocusedCell();
								if (this.props.onGridOut) {
									event.preventDefault();
									this.props.onGridOut();
								}
							} else {
								// selecting the next row
								this.keyRowSelection(this.gridApi.getFocusedCell().rowIndex + 1);
							}

							// handling pagination on tab keyboard
							if (this.gridApi.getFocusedCell()) {
								if (((this.gridApi.getFocusedCell().rowIndex + 1) % this.props.paginationPageSize == 0)) {
									event.preventDefault();
									this.on_move_next();
								}
							}
						}
					}

					// handling pagination on shift+tab keyboard
					if (event.shiftKey) {
						if (firstColumn == currentColumnField) {
							if (this.gridApi.getFocusedCell().rowIndex != 0) {
								// selecting the previous row
								this.keyRowSelection(this.gridApi.getFocusedCell().rowIndex - 1);
								if (this.gridApi.getFocusedCell().rowIndex % this.props.paginationPageSize == 0) {
									event.preventDefault();
									this.on_move_previous();
								}
							}
						}
					}
				} else {
					this.gridApi.clearFocusedCell();
				}
			}

			// break out from the grid on alt + shift
			if (event.altKey && event.shiftKey) {
				if (this.props.onGridOut) {
					event.preventDefault();
					// scrolling left before breaking the grid
					this.gridApi.ensureColumnVisible(this.gridColumnApi.getAllColumns()[0].colId);
					this.gridApi.clearFocusedCell();
					this.props.onGridOut();
				}

				if (this.props.onForceGridOut) {
					event.preventDefault();
					// scrolling left before breaking the grid
					this.gridApi.ensureColumnVisible(this.gridColumnApi.getAllColumns()[0].colId);
					this.gridApi.clearFocusedCell();
					this.props.onForceGridOut();
				}
			}
		} else {
			if (event.which === 13) {
				event.preventDefault();
				const row_node = this.gridApi.rowModel.rowsToDisplay[this.focused_cell_index];
				if (row_node && row_node.data) {
					this.gridApi.clearFocusedCell();
					this.props.onRowDoubleClicked && this.props.onRowDoubleClicked(row_node.data);
				}
			}
		}
	}

	ensureScrollIsLeft = () => {
		this.gridApi.ensureColumnVisible(this.gridColumnApi.getAllColumns()[0].colId);
	}

	// grid row selection
	keyRowSelection = (index) => {
		if (this.props.selectionType) {
			this.gridApi.forEachNode((node) => {
				if (index === node.rowIndex) {
					node.setSelected(true);
				}
			});
		}
	}


	// grid row navigation by keyboard arrow up/ arrow down 
	navigateToNextCell(params) {
		var previousCell = params.previousCellDef;
		var suggestedNextCell = params.nextCellDef;
		var KEY_UP = 38;
		var KEY_DOWN = 40;
		var KEY_LEFT = 37;
		var KEY_RIGHT = 39;
		const { from_page, to_page } = this.state;

		switch (params.key) {
			case KEY_DOWN:
				previousCell = params.previousCellDef;
				let nextIndex = previousCell.rowIndex + 1;
				if (this.props.isPagination && this.props.isPagination === true) {
					nextIndex = (previousCell.rowIndex) === (to_page - 1) ? (from_page - 1) : previousCell.rowIndex + 1;
				} else if (nextIndex > this.gridApi.rowRenderer.lastRenderedRow) {
					nextIndex = this.gridApi.rowRenderer.lastRenderedRow;
				}

				this.gridApi.setFocusedCell(nextIndex, previousCell.column.colId);
				// set selected cell on current cell + 1
				return {
					rowIndex: nextIndex,
					column: previousCell.column,
					floating: previousCell.floating
				};
			// return suggestedNextCell;
			case KEY_UP:
				previousCell = params.previousCellDef;
				// set selected cell on current cell - 1
				let previous_index = previousCell.rowIndex !== 0 ? previousCell.rowIndex - 1 : previousCell.rowIndex;
				this.gridApi.setFocusedCell(previous_index, previousCell.column.colId);
				// this.keyRowSelection(previousCell.rowIndex - 1);
				return suggestedNextCell;
			case KEY_LEFT:
			case KEY_RIGHT:
				return suggestedNextCell;
			default:
				throw "this will never happen, navigation is always on of the 4 keys above";
		}
	}

	// grid function callback on ready state
	onGridResize() {
		this.gridApi.sizeColumnsToFit();
	}

	// Set Grid no record height 
	setGridOverflow = () => {
		if (this.get_rows() && this.get_rows().length === 0) {
			let grid_id: any = document.getElementById(this.props.id || 'myGrid');
			if (grid_id) {
				let ag_header = grid_id.querySelector('.ag-header').offsetHeight;
				let ag_body = grid_id.querySelector('.ag-body').offsetHeight;
				let ag_overlay: any = grid_id.querySelector('.ag-overlay');
				ag_overlay.style.marginTop = `${ag_header}px`;
				ag_overlay.style.height = `${ag_body}px`;
				ag_overlay.style.minHeight = `30px`;
			}
		}
	}

	// Grid data wrap
	onColumnResized(event) {
		if (event.finished) {
			if (this._is_mounted) {
				this.setState({
					timeout: setTimeout(() => { this.gridApi.resetRowHeights(); }, 200)
				});
			}
		}
	}

	// grid row click function call
	onSelectionChanged() {
		if (this.props.radioSelection && this.props.radioSelection === true) {
			var selectedRows = this.gridApi.getSelectedRows();
			this.props.onRowSelection && this.props.onRowSelection(selectedRows);
		} else if (this.props.onRowSelection) {
			const selectedRows = filterSelectedDisplay(this.gridApi);
			this.props.onRowSelection(selectedRows);
		}
	}

	// grid row double click function call
	onRowDoubleClick({ data, rowIndex }) {
		this.props.onRowDoubleClicked && this.props.onRowDoubleClicked(data);
	}

	// grid row single click function call
	onRowClick({ data, rowIndex }) {
		this.props.onRowDoubleClicked && this.props.onRowDoubleClicked(data);
	}

	// grid row cell click function call
	onCellClicked(params) {
		this.props.onCellClicked && this.props.onCellClicked({ data: params.data, column: params.column });
	}

	// grid row cell click function call
	onCellFocused(params) {
		this.focused_cell_column = params.column;
		this.focused_cell_index = params.rowIndex;
	}

	onCellMouseOver(params) {
		if (params.rowIndex !== this.focused_cell_index && !params.rowPinned) {
			this.gridApi.setFocusedCell(params.rowIndex, params.column.colId);
        }
        //if this is the failed charges report
        if (this.props.onCellHoverTooltipColumn && this.props.onCellHoverTooltipColumn == params.column.colId) {
            if (this.props.onCellHoverTooltipSource) {
                this.props.onCellMouseOverFunc(params.data[this.props.onCellHoverTooltipSource], params.event.x, params.event.y);
            } else {
                this.props.onCellMouseOverFunc(params.value, params.event.x, params.event.y);
            }
        }
	}

    onCellMouseOut(params) {
        if (this.props.onCellHoverTooltipColumn && this.props.onCellMouseOutFunc) {
            this.props.onCellMouseOutFunc();
        }
	}

	// grid Pagination Changed callback
	onPaginationChanged({ api }) {
		if (this.props.onPaginationChangeEvent) {
			this.props.onPaginationChangeEvent(api)
		}

		if (this._is_mounted) {
			this.setState({
				is_first_page: this.state.current_page == 1,
				is_last_page: this.state.current_page == this.state.total_page
			});
		}

		if (this.state.total_page > 0 && this.state.pager.end_page === 0) {
			this.set_pager(1);
		}
	}

	// grid Pagination Changed callback
	onFilterChanged({ api }) {
		const row_count = api.rowModel.rowsToDisplay.length;
		const pageSize = this.props.paginationPageSize || DIMEN.PAGINATION_SIZE;
		if (this.state.grouped_fields.length > 0) {
			this.on_group_change(this.state.grouped_fields);
		}

		if (this._is_mounted) {
			this.setState({
				re_render: false,
				current_page: 1,
				from_page: 1,
				to_page: pageSize > row_count ? row_count : pageSize,
				total_record: row_count,
				total_page:
					row_count % pageSize > 0
						? parseInt((row_count / pageSize).toString()) + 1
						: parseInt((row_count / pageSize).toString()),
				is_first_page: true,
				is_last_page: true
			});
		}
		this.gridApi.paginationGoToFirstPage();
		if (this.props.on_filter_button_click) {
			this.props.on_filter_button_click();
		}
		if (this.props.onRowSelection) {
			const selectedRows = filterSelectedDisplay(api);
			this.props.onRowSelection(selectedRows);
		}
		clear_filter(api);
	}

	unGroupRow = (row) => {
		return row.data.participants
	}

	on_move_first = () => {
		this.gridApi.paginationGoToFirstPage();
		const pageSize = this.props.paginationPageSize || DIMEN.PAGINATION_SIZE;
		if (this._is_mounted) {
			this.setState({ current_page: 1, from_page: 1, to_page: pageSize > this.props.row.length ? this.props.row.length : pageSize }, () => {
				this.props.onPaginationChange && this.props.onPaginationChange(this.state.from_page, this.state.to_page);
			});
		}
		this.set_pager(1);
	}

	on_move_last = () => {
		this.gridApi.paginationGoToLastPage();
		const pageSize = this.props.paginationPageSize || DIMEN.PAGINATION_SIZE;
		if (this._is_mounted) {
			this.setState({
				current_page: this.state.total_page, to_page: this.state.total_record, from_page: ((this.state.total_page - 1) * pageSize) + 1
			}, () => { this.props.onPaginationChange && this.props.onPaginationChange(this.state.from_page, this.state.to_page); });
		}
		this.set_pager(this.state.total_page);
	}

	on_move_next = () => {
		this.gridApi.paginationGoToNextPage();
		const pageSize = this.props.paginationPageSize || DIMEN.PAGINATION_SIZE;
		const curr_page = this.state.current_page < this.state.total_page ? (this.state.current_page + 1) : this.state.current_page;
		this.set_pager(curr_page);
		if (curr_page <= this.state.total_page) {
			if (this._is_mounted) {
				this.setState({
					current_page: curr_page,
					to_page: (curr_page * pageSize) > this.state.total_record ? this.state.total_record : (curr_page * pageSize), from_page: ((curr_page - 1) * pageSize) + 1
				}, () => { this.props.onPaginationChange && this.props.onPaginationChange(this.state.from_page, this.state.to_page); });
			}
		}
	}

    on_move_previous = () => {
		this.gridApi.paginationGoToPreviousPage();
		const pageSize = this.props.paginationPageSize || DIMEN.PAGINATION_SIZE;
		const curr_page = this.state.current_page > 1 ? (this.state.current_page - 1) : this.state.current_page;
		this.set_pager(curr_page);
		if (this._is_mounted) {
			this.setState({
				current_page: curr_page,
				to_page: (curr_page * pageSize) > this.state.total_record ? this.state.total_record : (curr_page * pageSize), from_page: ((curr_page - 1) * pageSize) + 1
			}, () => { this.props.onPaginationChange && this.props.onPaginationChange(this.state.from_page, this.state.to_page); });
		}
	}

    on_move_to_page = (page) => {
		if (this.gridApi) {
            this.gridApi.paginationGoToPage(page-1); //index not page
			const pageSize = this.props.paginationPageSize || DIMEN.PAGINATION_SIZE;
			const curr_page = page;
            this.set_pager(curr_page);
			if (curr_page <= this.state.total_page) {
				if (this._is_mounted) {
					this.setState({
						current_page: curr_page,
						to_page: (curr_page * pageSize) > this.state.total_record ? this.state.total_record : (curr_page * pageSize), from_page: ((curr_page - 1) * pageSize) + 1
					}, () => { this.props.onPaginationChange && this.props.onPaginationChange(this.state.from_page, this.state.to_page); });
				}
			}
		}
	}

	set_pager = (page) => {
		var pager = this.state.pager;
		if (page < 1 || page > pager.totalPages) {
			return;
		}
		var page_size = this.props.paginationPageSize || DIMEN.PAGINATION_SIZE;
		pager = this.get_pager(page, page_size);
		if (this._is_mounted) {
			// update state
			this.setState({ pager: pager });
		}
	}

	get_pager = (curr_page, page_size) => {
		// default to first page
		curr_page = curr_page || 1;

		// default page size is 10
		page_size = page_size || 5;

		// calculate total pages
		var total_pages = this.state.total_page;
		var start_page, end_page;
		if (this.state.total_page <= 6) {
			// less than 10 total pages so show all
			start_page = 1;
			end_page = this.state.total_page;
		} else {
			if (this.state.is_google_paging) {
				// more than 10 total pages so calculate start and end pages
				if (curr_page <= 3) {
					start_page = 1;
					end_page = 6;
				} else
					if (Number(curr_page) + 2 >= this.state.total_page) {
						start_page = Number(this.state.total_page) - 4;
						end_page = this.state.total_page;
					} else {
						start_page = Number(curr_page) - 2;
						end_page = Number(curr_page) + 2;
					}
			}
			else {
				if (Number(curr_page) + 5 >= this.state.total_page) {
					start_page = Number(this.state.total_page) - 5;
					end_page = this.state.total_page;
				}
				else {
					start_page = curr_page;
					end_page = curr_page + 5;
				}
			}
		}
		// create an array of pages to ng-repeat in the pager control
		var pages = (end_page + 1) - start_page;
		var arr = [];
		for (var index = 0; index < pages; index++) {
			arr.push(start_page + index);
		}
		// pages = [Array((end_page + 1) - start_page).map(i => start_page + i)];

		// calculate start and end item indexes
		var start_index = (curr_page - 1) * page_size;

		var start_pager = [];
		for (var index = 1; index < start_page; index++) {
			start_pager.push(index);
		}
		var mid_pager = [];
		for (var indx = start_page; indx <= end_page; indx++) {
			mid_pager.push(indx);
		}
		var end_pager = [];
		for (var indx = end_page + 1; indx <= total_pages; indx++) {
			end_pager.push(indx);
		}

		// return object with all pager properties required by the view
		return {
			curr_page: curr_page,
			page_size: page_size,
			total_pages: total_pages,
			start_page: start_page,
			end_page: end_page,
			start_index: start_index,
			pages: arr,
			start_range: start_pager,
			mid_range: mid_pager,
			end_range: end_pager
		};
	}

	componentDidMount() {
		this._is_mounted = true;
		if (this.props.onRef) {
			this.props.onRef(this);
		}
	}

	componentDidUpdate(previousProps, previousState) {
		if (previousProps.row !== this.props.row || previousProps.paginationPageSize !== this.props.paginationPageSize) {
			if (this.gridApi) {
				const pageSize = this.props.paginationPageSize || DIMEN.PAGINATION_SIZE;
				const total_page = this.props.row ? ((this.props.row.length % pageSize) > 0 ? parseInt((this.props.row.length / pageSize).toString()) + 1 : parseInt((this.props.row.length / pageSize).toString())) : 0;

				if (this._is_mounted) {
					this.setState({
						current_page: 1,
						from_page: 1,
						to_page: this.props.row ? pageSize > this.props.row.length ? this.props.row.length : pageSize : 0,
						total_record: this.props.row ? this.props.row.length : 0,
						total_page: total_page,
						is_first_page: true,
						is_last_page: total_page > 1 ? false : true,
					});
				}


				this.gridApi.paginationSetPageSize(pageSize);
				this.gridApi.paginationGoToFirstPage();
				clear_all_filter(this.gridApi);
			}
		}

		if (previousState.total_page !== this.state.total_page) {
			this.set_pager(1);
		}
	}

	componentWillUnmount() {
		this._is_mounted = false;
		if (this.state.timeout) {
			clearTimeout(this.state.timeout);
		}

		if (this.props.onRef) {
			this.props.onRef(this);
		}
		if (this.container_grid) {
			this.container_grid.removeEventListener('mouseleave', this.handle_mouse_leave);
		}
	}

	getOptions = () => {
		let attributes = {};
		if (this.props.checkboxSelection && this.props.checkboxSelection === true) {
			attributes = { ...attributes, ...{ rowSelection: 'multiple', suppressRowClickSelection: true } };
		} else if (this.props.radioSelection) {
			attributes = { ...attributes, ...{ rowSelection: 'single', suppressRowClickSelection: true } };
		} else if (this.props.selectionType) {
			attributes = { ...attributes, ...{ rowSelection: this.props.selectionType } };
		}
		if (this.props.isPagination) {
			attributes = { ...attributes, ...{ pagination: this.props.isPagination } };
		}
		if (this.props.gridAutoHeight) {
			attributes = { ...attributes, ...{ gridAutoHeight: this.props.gridAutoHeight } };
		}
		if (this.props.isTotal && this.props.isTotal === true) {
			attributes = { ...attributes, ...{ getRowStyle: this.getRowStyleTotal.bind(this) } };
		}
		if (this.props.onCellClicked) {
			attributes = { ...attributes, ...{ onCellClicked: this.props.onCellClicked } };
		}
		if (this.props.name) {
			attributes = { ...attributes, ...this.state.getRowHeight };
		}
		if (this.props.isRowSelectable) {
			attributes['isRowSelectable'] = this.props.isRowSelectable;
		}
		if (this.props.suppressColumnVirtualisation) {
			attributes['suppressColumnVirtualisation'] = true;
		}
		if (this.props.rowClassRules) {
			attributes = { ...attributes, ...{ rowClassRules: this.props.rowClassRules } };
		}
		if (this.props.rowGroupPanelShow) {
			// attributes['groupUseEntireRow'] = true;
			// attributes['groupIncludeFooter']= true;
			// attributes['rememberGroupStateWhenNewData']= true;
			// attributes['groupRemoveSingleChildren']= true;
			attributes['getNodeChildDetails'] = this.getNodeChildDetails.bind(this);
		}
		if (this.props.suppressHorizontalScroll) {
			attributes['suppressHorizontalScroll'] = true;
		}
		return attributes;
	}

	getRowStyleTotal(params) {
		if (params && (params.node.rowIndex == params.api.rowModel.rowsToDisplay.length - 1)) {
			return { fontWeight: 600 }
		}
		return null;
	}

	grid_full_height = () => {
		if (this.props.gridFullHeight) {
			const header = this.props.headerHeight || DIMEN.HEIGHT_HEADER;
			const body = this.state.total_record || 1;
			const extra_height = 20;
			const row_height = 28;
			return { height: `${header + extra_height + body * row_height}px` };
		}
		return null;
	}

	gridStyle = () => {
		if (this.props.gridAutoHeight && this.props.gridAutoHeight === true) {
			return { ...GRID_AUTO_HEIGHT_STYLE };
		} else {
			return { ...GRID_BASE_STYLE, ...this.props.style, ...this.grid_full_height() };
		}
	}

	getNodeChildDetails(rowItem) {
		if (rowItem.participants) {
			return {
				group: true,
				expanded: this.props.expandGroupByDefault,
				children: rowItem.participants,
				key: rowItem.group
			};
		} else {
			return null;
		}
	}

	renderPagination = () => {
		if (this.props.isPagination
			&& this.props.renderTopPager !== false
			&& this.props.isPagination === true
			&& this.state.total_record
			&& this.state.total_record > 0) {
			return (<Pagination
				fromPage={this.state.from_page}
				toPage={this.state.to_page}
				totalRecords={this.state.total_record}
				isFirstPage={this.state.is_first_page}
				isLastPage={this.state.is_last_page}
				onFirst={this.on_move_first}
				onPrevious={this.on_move_previous}
				onNext={this.on_move_next}
				onLast={this.on_move_last}
				onSpecificPage={this.on_move_to_page}
				totalPage={this.state.total_page}
				pager={this.state.pager}
				showPager={this.state.is_show_number_pager}
				message={this.props.paginationMessage}
				totalInBetween={this.props.totalInBetween}
			/>);
		}
		return null;
	}

	renderBottomNumericPagination = () => {
		if (this.props.isPagination
			&& this.props.renderBottomNumericPager
			&& this.props.isPagination === true
			&& this.state.total_record
			&& this.state.total_record > 0) {
			return (
				<NumericPager
					key={this.state.pager}
					fromPage={this.state.from_page}
					toPage={this.state.to_page}
					totalRecords={this.state.total_record}
					isFirstPage={this.state.is_first_page}
					isLastPage={this.state.is_last_page}
					onFirst={this.on_move_first}
					onPrevious={this.on_move_previous}
					onNext={this.on_move_next}
					onLast={this.on_move_last}
					onSpecificPage={this.on_move_to_page}
					totalPages={this.state.total_page}
					pager={this.state.pager}
					showPager={true}
					onPageSizeSelected={this.props.onPageSizeChange}
					itemsPerPageOptions={this.props.itemsPerPageOptions}
					message={this.props.numericPagerMessage}
					excludePagerSizeSelect={this.props.excludePagerSizeSelect}
				/>);
		}
		return null;
	}

	// Render group by panel
	renderGroupPanel = () => {
		if (this.props.rowGroupPanelShow) {
			return (<GroupPanelComponent
				onRef={ref => (this.ref_group_panel = ref)}
				onChange={this.on_group_change}
				onSort={this.on_sort_change}
				onClick={this.handle_group_tag_click}
			/>)
		}
		return null;
	}

	// Group tag click event handler
	handle_group_tag_click = (data, e) => {
		let items = this.gridApi.getSortModel();
		if (items && items.length > 0) {
			let is_exist = false;
			items = items.map((value) => {
				if (value.colId == data.field) {
					is_exist = true;
					value.sort = value.sort ? value.sort == 'asc' ? 'desc' : null : 'asc';
				}
				return value;
			})
			if (!is_exist) {
				items.push({ colId: data.field, sort: 'asc' });
			}
		} else {
			items = [{ colId: data.field, sort: 'asc' }];
		}
		this.gridApi.setSortModel(items);
		if (this.ref_group_panel) {
			this.ref_group_panel.set_sort_model(items);
		}
	}

	// Sort callback from group by tag click
	on_sort_change = (sortModel) => {
		this.gridApi.setSortModel(sortModel);
	}

	on_group_change = (fields) => {
		let row = this.props.row;
		if (this.gridApi.isAnyFilterPresent()) {
			const filteredField = this.gridApi.getFilterModel();
			row = filterRowDataForGrouped(row, filteredField);
		}
		let groupedRows = group_row_by_column(row, fields, this.props);
		//if (this.props.displayGroupRowCount) {
		//	groupedRows = groupRowByColumnWithRowsCount(row, fields, this.props);
		//}
		if (row && row.length > 0) {
			this.gridColumnApi.setColumnVisible('group', fields.length > 0);
			this.setState(
				{
					grouped_fields: fields,
					grouped_rows: fields.length > 0 ? groupedRows : []
				},
				() => {
					this.props.onGroupByChange && this.props.onGroupByChange(fields);
				}
			);
		}
	}

	get_rows = () => {
		if (this.props.rowGroupPanelShow && this.state.grouped_rows.length > 0) {
			return this.state.grouped_rows;
		}
		return this.props.row;
	}

	// Render function
	render() {
		return (
			<div
				style={this.props.wrapperStyle || { width: '100%', height: '100%' }}
				className={`main-grid-wrapper ${this.props.wrapperClass || 'grid_wrapper'}`}
			>
				<div id={this.props.id || 'myGrid'} style={this.gridStyle()} className='ag-theme-balham custom-multiselect'>
					{this.renderPagination()}
					{this.renderGroupPanel()}
					<AgGridReact
						defaultColDef={this.state.defaultColDef}
						columnDefs={this.props.column}
						rowData={this.get_rows()}
						icons={ICONS}
						gridOptions={this.getOptions()}
						enableFilter={true}
						enableSorting={true}
						suppressMenuHide={true}
						suppressPaginationPanel={true}
						suppressMovableColumns={
							this.props.suppressMovableColumns || this.props.suppressMovableColumns == undefined ? true : false
						}
						suppressDragLeaveHidesColumns={true}
						headerHeight={this.props.headerHeight || DIMEN.HEIGHT_HEADER}
						paginationPageSize={this.props.paginationPageSize || DIMEN.PAGINATION_SIZE}
						frameworkComponents={this.state.frameworkComponents}
						overlayLoadingTemplate={this.props.loadingMessage || TEMPLATE_LOADING}
						overlayNoRowsTemplate={this.props.emptyMessage || TEMPLATE_NO_RECORD}
						onPaginationChanged={this.onPaginationChanged.bind(this)}
						navigateToNextCell={this.navigateToNextCell.bind(this)}
						onCellMouseOver={this.onCellMouseOver.bind(this)}
						onCellMouseOut={this.onCellMouseOut.bind(this)}
						onCellFocused={this.onCellFocused.bind(this)}
						onFilterChanged={this.onFilterChanged.bind(this)}
						onGridReady={this.onGridReady.bind(this)}
						enableColResize={this.props.enableColResize ? this.props.enableColResize : false}
						onColumnResized={this.onColumnResized.bind(this)}
						pinnedBottomRowData={this.props.pinnedBottomRowData || null}
						getRowHeight={this.props.getRowHeight || null}
						getRowStyle={this.props.getRowStyle}
					/>
					{this.renderBottomNumericPagination()}
				</div>
			</div>
		);
	}
}

export default GridView;