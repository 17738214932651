import WebptSso, { Props as WebPtSsoProps, StepsEnum, ThemeEnum } from '@webpt/sso-ui';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import { sso_user_authentication } from '../../sso/action/sso_action';
import CryptoService from './../../shared/crypto_service/crypto_service';
import SsoSuccessMessage from './sso_success_message_component';
import { get_lauch_darkley_key_value } from '../../shared/utility';

export class SSOLoginComponent extends React.Component<any, any> {
    [x: string]: any;

    constructor(props) {
        super(props);

        // Initialize state property here
        this.state = {
            loading: false
        };
    }     

    componentDidMount() {
        // getting encryption passphrase for the first time
        CryptoService.get_encryption_key();
    }
    encrypt_password = async (value) => {
        let encryptedValue = CryptoService.encrypt_password(value);
        return encryptedValue;
    }

    // method will execute when user will click on Sign-In button on login window
    on_sign_in = async (username, password) => {

        this.setState({
            loading: true
        });
        await CryptoService.get_encryption_key();

        // User's Password must be encrypted before sending it to server
        var data = await this.encrypt_password(password);
        let thiz = this;
        
        //Authenticate the user credentials...
        await sso_user_authentication(username.trim(), data).then(async (response) => {            

            thiz.setState({
                loading: false
            });
            
             if (response.data.status==1) {               
                return true;
            } else {
                var errormessage = '';
                if (response.data && response.data.messages.length>0) {
                    errormessage = response.data.messages[0].message
                }
                throw errormessage
            } 
        });
    }
    public options: WebPtSsoProps = {
        ssoPartyName: 'Billing',
        ssoPartyLogo: 'src/assets/images/emr.png',
        applicationTheme: {
            color: ThemeEnum.DENIM,
            logo: 'src/assets/images/billing.png'
        },
        activeStep: StepsEnum.SSOAUTH,
        onSubmit: (username, password) => {
            return this.on_sign_in(username, password)
        },
        successMessage: <SsoSuccessMessage/>
    };

     render() {
         
        return (
            <div id="ssoUi" className='login-wrapperBlue'>
                    <Dimmer active={this.state.loading}>
                        <Loader size='massive'>Loading</Loader>
                    </Dimmer>
                    <WebptSso {... this.options} /> 
                </div>
        );
    }
}

export default SSOLoginComponent;
