import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Grid, Table} from "semantic-ui-react";
import * as global_constants from '../../global_constants';
import Selection from '../../shared/component/selection_component';
import AddNewNoteComponent from '../../patient/component/account_notes_add_component';
import * as  charge_constants from './../charge_constant';
import * as local_storage from '../../shared/local_storage_utility';
import * as session_storage from '../../shared/session_storage_utility';
import { set_active_patient, get_master_data, get_master_data_by_type, refresh_token } from '../../login/action/login_action';
import  ChargeDetailRowComponent  from './charge_details_row_component';
import { get_company_detail_data, set_prevent_company_model_status } from '../../shared/action/shared_action';
import { detect_ie, update_company_details , get_lauch_darkley_key_value, clear_sotrage_after_company_change_on_main_page } from '../../shared/utility';
import { MasterData } from '../../login/login_constants';
import LaunchDarkley from '../../shared/launch_darkley/launch_darkley';
import { AlertConfirm } from '../../shared/component/alert_confirm_component';

export class ChargeReviewDetailRowComponent extends React.Component<any, any> {
    [x: string]: any;
    _is_mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            select_all: false,
            clear_charge: false,
            update_child_force: false,
            //////
            charge_audit_row_item: this.props.data,
            pending_reasion_list: this.props.pending_reasion_list || [],
            charge_audit_action_list: [],
            select_all_charge_visit_checkbox: false,
            show_save_alert: false
        }
        this.charge_row_nodes = [];
    }
    componentDidMount = () => {
        this.props.onRef(this);
        this._is_mounted = true;
        var action_list = this.update_data(charge_constants.action_list);
        
        if (this._is_mounted) {
            this.setState({
                charge_audit_action_list: action_list
            });
        }
    };

    componentWillUnmount = () => {
        this.props.onRef(undefined);
        this._is_mounted = false;
    }
    get_all_charge_data = () => {
        let row_items = [];
        this.charge_row_nodes.map((node) => {
            if (node) {
                var item = node.get_updated_data();
                row_items = [...row_items, item];
            }
        })
        return row_items;
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.pending_reasion_list !== this.props.pending_reasion_list) {
            if (this._is_mounted) {
                this.setState({
                    pending_reasion_list: this.props.pending_reasion_list
                })
            }
        }

        if (prevProps.charge_audit_list !== this.props.charge_audit_list) {
            if (this._is_mounted) {
                this.setState({
                    charge_audit_row_item: this.props.data,
                })
            }
        }
        if (prevProps.data !== this.props.data) {
            if (this._is_mounted) {
                this.setState({
                    charge_audit_row_item: this.props.data,
                })
            }
        }
    }
    update_data = (source) => {
        var target = [{ "text": 'Release or Pend', "value": global_constants.constants.dropdown_default_info.value_string, "key": "Release or Pend" }];
        var source_length = source.length;
        if (source && source_length > 0) {
            for (let y = 0; y < source_length; y++) {
                let item = source[y];
                target.push({
                    "text": item.action_desc,
                    "value": item.action_id,
                    "key": item.action_id
                })
            }
        }
        return target;

    }

    select_charge_for_edit = (e, value, index) => {
        var charge_audit_row_item = { ...this.state.charge_audit_row_item };
        charge_audit_row_item.charges[index].selected = value.checked

        var un_selected_charge = charge_audit_row_item.charges.filter((charge) => {
            if (charge.allow_edit && !charge.selected) {
                return charge;
            }            
        })
        var select_all_charge = false;
        if (un_selected_charge.length==0) {
            select_all_charge = true;
        }
        if (this._is_mounted) {
            this.setState({
                charge_audit_row_item: charge_audit_row_item,
                select_all_charge_visit_checkbox: select_all_charge
            });
        }
    }

    select_all_charge_visit_for_edit = (e, value, index) => {
        var charge_audit_row_item = { ...this.state.charge_audit_row_item };

        var updated_charge_item = charge_audit_row_item.charges.map((charge) => {
           if (charge.allow_edit) {
               charge.selected = value.checked
            }
            return charge;
        })
        charge_audit_row_item.charges = updated_charge_item;
        //charge_audit_row_item.charges[index].selected = value.checked
        if (this._is_mounted) {
            this.setState({
                charge_audit_row_item: charge_audit_row_item,
                select_all_charge_visit_checkbox: value.checked
            })
        }
    }
    edit_visit = async () => {
        var selected_charge = [];
        this.state.charge_audit_row_item.charges.map(function (row, index) {
            if (row.allow_edit) {
                selected_charge.push(row.charge_id);
            }
        });
        if (selected_charge.length > 0) {
            let auth_data = local_storage.get('auth_data');
            let current_company_id = auth_data && auth_data.company_id;
            if (current_company_id != this.props.search_creteria.company_id) {
                if(detect_ie()){
                    let count = parseInt(local_storage.get("open_tab_count"));
                    if(count > 1) {
                        this.props.set_prevent_company_model_status(true);
                        return false;
                    }
                }
                await get_company_detail_data(this.props.search_creteria.company_id, this.props.user_login_details.user_data.data.token_details.access_token).then(async response => {
                    if (response && response.data) {
                        local_storage.set('report_navigation_active_patient', this.state.charge_audit_row_item.patient_id);
                        local_storage.set('company_change_on_same_tab', true);

                        local_storage.set('report_navigation', {
                            pathname: '/charge/edit_charge/' + selected_charge[0],
                            search: '',
                            state: {
                                charge_visit: true,
                                from_charge_review: true,
                                search_creteria: this.props.search_creteria,
                                location_search_text: this.props.location_search_text,
                                payment_associated_with_visit:this.state.charge_audit_row_item.payment_associated_with_visit
                            }
                        });
                        await this.change_company(response.data);
                        session_storage.set('active_patient', this.state.charge_audit_row_item.patient_id);
                        this.props.set_active_patient(this.state.charge_audit_row_item.patient_id);
                        this.props.history.push({
                            pathname: '/charge/edit_charge/' + selected_charge[0],
                            search: '',
                            state: {
                                charge_visit: true,
                                from_charge_review: true,
                                search_creteria: this.props.search_creteria,
                                location_search_text: this.props.location_search_text,
                                payment_associated_with_visit:this.state.charge_audit_row_item.payment_associated_with_visit
                            }
                        })
                    }
                }).catch(error => { });
            }
            else {

                session_storage.set('active_patient', this.state.charge_audit_row_item.patient_id);
                this.props.set_active_patient(this.state.charge_audit_row_item.patient_id);

                this.props.history.push({
                    pathname: '/charge/edit_charge/' + selected_charge[0],
                    search: '',
                    state: {
                        charge_visit: true,
                        from_charge_review: true,
                        search_creteria: this.props.search_creteria,
                        location_search_text: this.props.location_search_text,
                        payment_associated_with_visit:this.state.charge_audit_row_item.payment_associated_with_visit
                    }
                })
            }
        }
        else {
            toastr.warning('', charge_constants.charge_messages.no_charge_exists_for_edit_visit);
        }

    }
    change_company = async (company_data) => {
        var user_data = this.props.user_login_details.user_data.data;
        user_data.company_id = company_data.id;
        user_data.company_name = company_data.name;
        user_data.gpms_code = company_data.gpms_code;

        // Set auth data in local storage on company changed 
        update_company_details(company_data);

        await this.props.refresh_token(user_data, true);
        console.log("End Charge Audit, refreshing token, user data =>", this.props.user_login_details.user_data.data);

        // get master data when user change company
        this.props.get_master_data(MasterData.all, this.props.user_login_details.user_data.data.user_id, this.props.user_login_details.user_data.data.token_details.access_token, true);
        this.props.get_master_data_by_type(MasterData.all, this.props.user_login_details.user_data.data.user_id, this.props.user_login_details.user_data.data.token_details.access_token, true);
        LaunchDarkley.update();
        clear_sotrage_after_company_change_on_main_page();
    }
    edit_charge = (for_visit) => {
        var selected_charge = [];
        this.state.charge_audit_row_item.charges.map(function (row, index) {
            if (row.allow_edit && row.selected) {
                selected_charge.push(row.charge_id);
            }
        });

        if (selected_charge.length == 0) {
            toastr.warning('', charge_constants.charge_messages.no_charge_selected);
        } else if (!this.state.select_all_charge_visit_checkbox && selected_charge.length > 1) {
            toastr.warning('', charge_constants.charge_messages.select_single_or_all_charge);
        } else {
            session_storage.set('active_patient', this.state.charge_audit_row_item.patient_id);
            this.props.set_active_patient(this.state.charge_audit_row_item.patient_id);
            
            this.props.history.push({
                pathname: '/charge/edit_charge/' + selected_charge[0],
                search: '',
                state: {
                    charge_visit: this.state.select_all_charge_visit_checkbox,
                    from_charge_review: true,
                    search_creteria: this.props.search_creteria,
                    location_search_text: this.props.location_search_text
                }
            })
        }
    }
    action_on_change = (value) => {
        var data = { ...this.state.charge_audit_row_item }

        //B-35877 check charge statuses for (R or 0) and T
        var statusR0 = false;
        var statusT = false;
        let show_save_alert = false;
        data.charges.forEach((charge_obj) => {
            if (charge_obj.charge_status == 'R' || charge_obj.charge_status == '0') {
                statusR0 = true;
            }
            if (charge_obj.charge_status == 'T') {
                statusT = true;
            }
        });

        if (value == "1" && statusR0 && statusT) {
            show_save_alert = true;
        }

        data.pending_reason_id = 0;
        data.action_id = value;
        if (this._is_mounted) {
            this.setState({
                charge_audit_row_item: data,
                show_save_alert: show_save_alert
            })
        }
    }

    pending_reasion_on_change = (value) => {
        var data = { ...this.state.charge_audit_row_item }
        data.pending_reason_id = value;
        data.action_id = 2;
        if (this._is_mounted) {
            this.setState({
                charge_audit_row_item: data
            })
        }
    }

    get_updated_data = () => {
        var charge_list = this.get_all_charge_data()
        var charge_audit_row = this.state.charge_audit_row_item;
        charge_audit_row.charges = charge_list;
        return charge_audit_row;
    }

    on_close_alert() {
        if (this._is_mounted) {
            this.setState({ show_save_alert: false });
        }
    }

    render() {
        console.log('CRDR_Render', new Date());
        let provider = this.state.charge_audit_row_item.billing_provider_name ? this.state.charge_audit_row_item.billing_provider_name + ' (' + this.state.charge_audit_row_item.billing_provider_code + ')' : '';
        let location = this.state.charge_audit_row_item.place_of_service_name ? this.state.charge_audit_row_item.place_of_service_name + ' (' + this.state.charge_audit_row_item.place_of_service_code + ')' : '';

        return (
            <React.Fragment>

                <AlertConfirm
                    open={this.state.show_save_alert}
                    close={() => this.on_close_alert()}
                    confirm={() => this.on_close_alert()}
                    message={charge_constants.charge_messages.charge_status_warning}
                    hide_cancel_button={true}
                    title={charge_constants.charge_messages.charge_status_warning_title}
                    labelYes="OK"
                />

                <Grid>
                    <Grid.Column computer={16} className="padd-r-0" style={{ marginRight: 10 }}>
                        <Grid className="form patient-search-form charge_review_row_details mar-t-5 ">

                            {
                                <React.Fragment>
                                    <Grid.Column computer={16} mobile={16} tablet={16} >
                                        <Grid>
                                            <Grid.Column computer={16} mobile={16} tablet={16} className="review-charge-card">
                                                <strong>
                                                    {this.state.charge_audit_row_item.patient_full_name} #{this.state.charge_audit_row_item.patient_id}</strong>
                                            </Grid.Column>
                                        </Grid >
                                        <Grid>
                                            <Grid.Column computer={5} mobile={16} tablet={16} className="review-charge-card patient-name-info" style={{ paddingBottom: '0px !important' }} >
                                                Date of Birth: {this.state.charge_audit_row_item.dob} ({this.state.charge_audit_row_item.age})
                                        </Grid.Column>
                                            <Grid.Column computer={5} mobile={16} tablet={16} className="review-charge-card patient-name-info" style={{ paddingBottom: '0px !important' }} >
                                                Primary Insurance: {this.state.charge_audit_row_item.primary_insurance_code}
                                            </Grid.Column>
                                            <Grid.Column computer={6} mobile={16} tablet={16} className="review-charge-card patient-name-info" style={{ paddingBottom: '0px !important' }} >
                                                Referring Physician: {this.state.charge_audit_row_item.ref_physician}
                                            </Grid.Column>
                                        </Grid >
                                        <Grid>
                                            <Grid.Column computer={5} mobile={16} tablet={16} className="review-charge-card patient-name-info" style={{ paddingBottom: '0px !important' }} >
                                                Date of Service: {this.state.charge_audit_row_item.date_of_service} {this.props.rsiB35911ErrorCorrectionsPreventUserFromAddingNcModifierToChargeWithActivePayments && this.state.charge_audit_row_item.payment_associated_with_visit && <i className="dollar-icon"/>}
                                            </Grid.Column>
                                            <Grid.Column computer={5} mobile={16} tablet={16} className="review-charge-card patient-name-info" style={{ paddingBottom: '0px !important' }} >
                                                Provider: {provider}
                                            </Grid.Column>
                                            <Grid.Column computer={6} mobile={16} tablet={16} className="review-charge-card patient-name-info" style={{ paddingBottom: '0px !important' }} >
                                                Case: {this.state.charge_audit_row_item.case_name}
                                            </Grid.Column>
                                        </Grid >
                                        <Grid>
                                            <Grid.Column computer={5} mobile={16} tablet={16} className="review-charge-card patient-name-info" style={{ paddingBottom: '0px !important' }} >
                                                Posted: {this.state.charge_audit_row_item.posted_date}
                                            </Grid.Column>
                                            <Grid.Column computer={5} mobile={16} tablet={16} className="review-charge-card patient-name-info" style={{ paddingBottom: '0px !important' }} >
                                                Location: {location}
                                            </Grid.Column>
                                            <Grid.Column computer={6} mobile={16} tablet={16} className="review-charge-card patient-name-info" style={{ paddingBottom: '0px !important' }} >
                                                Date of injury: {this.state.charge_audit_row_item.date_of_injury}
                                            </Grid.Column>
                                        </Grid >
                                        <Grid>
                                            <Grid.Column computer={5} mobile={16} tablet={16} className="review-charge-card patient-name-info" style={{ paddingBottom: '0px !important' }} >
                                                <Selection
                                                    id={'select_pend_reason_' + this.props.row_count}
                                                    defaultValue={this.state.charge_audit_row_item.action_id || ''}
                                                    options={this.state.charge_audit_action_list}
                                                    onChange={this.action_on_change}
                                                    disabled={false}
                                                />
                                            </Grid.Column>
                                            <Grid.Column computer={5} mobile={16} tablet={16} className="review-charge-card patient-name-info" style={{ paddingBottom: '0px !important' }} >
                                                <Selection
                                                    id={'pending_reasion_' + this.props.row_count}
                                                    defaultValue={this.state.charge_audit_row_item.pending_reason_id || ''}
                                                    options={this.state.pending_reasion_list}
                                                    onChange={this.pending_reasion_on_change}
                                                    disabled={false}
                                                />
                                            </Grid.Column>
                                            <Grid.Column computer={6} mobile={16} tablet={16} className="review-charge-card patient-name-info" style={{ paddingBottom: '0px !important' }} >
                                                <div className="charge_info" style={{ float: 'right', marginTop: '0px' }}>
                                                    <AddNewNoteComponent company_id={this.props.search_creteria.company_id} is_patient_header={false} onCloseModal={this.closeAddNewNoteModal} patient_id={this.state.charge_audit_row_item.patient_id}
                                                        is_primary={false} get_data={false} id={"charge_review_add_account_note_" + this.props.row_count} add_label={'Add Account Note'} />
                                                    <Button type="button" id={"charge_review_editcharge_" + this.props.row_count} primary onClick={this.edit_visit} >Edit Visit</Button>
                                                </div>
                                            </Grid.Column>
                                        </Grid>
                                    </Grid.Column>
                                </React.Fragment>
                            }
                            <Grid.Column computer={16} className="mar-t-5">
                                <div className="customtable">
                                    <div className="table-responsive" id={'charge_details_proc_table' + this.props.row_count}>
                                        <Table id={"charge_review_charge_info_table_" + this.props.row_count} className="charge_review_charge_info">
                                            <Table.Header>
                                                <Table.Row key={'charge_details_header_' + this.props.row_count}>
                                                    <Table.HeaderCell style={{ width: '250px', minWidth: '250px' }}>CPT Code</Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '80px', minWidth: '80px' }}>Units</Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '250px', minWidth: '250px' }}>Modifier 1</Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '250px', minWidth: '250px' }}>Modifier 2 </Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '250px', minWidth: '250px' }}>Modifier 3 </Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '200px', minWidth: '200px' }}>Diagnosis Codes</Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '125px', minWidth: '125px' }}>POS Indicator</Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '300px', minWidth: '300px' }}>Insurance Preauthorization</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body key={'charge_details_body' + this.props.row_count}>
                                                {
                                                    this.state.charge_audit_row_item.charges.map((charge, index) => {
                                                        return (
                                                            <ChargeDetailRowComponent key={index}
                                                                row_count={index}
                                                                data={charge}
                                                                isClear={this.state.clear_charge}
                                                                update_child_force={this.state.update_child_force}
                                                                reverse_select_all={this.reverse_select_all}
                                                                onRef={ref => (this.charge_row_nodes[index] = ref)}
                                                                modifier1_data={this.props.modifier1_data}
                                                                modifier2_data={this.props.modifier2_data}
                                                                modifier3_data={this.props.modifier3_data}
                                                                page_name={charge_constants.enum_page_name.review}
                                                                parent_row_count={this.props.row_count}
                                                                company_id={this.props.search_creteria.company_id}
                                                            />
                                                        )
                                                    })
                                                }
                                            </Table.Body>
                                        </Table>
                                    </div>
                                </div>
                            </Grid.Column>

                        </Grid>
                    </Grid.Column>
                </Grid>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        set_active_patient: set_active_patient,
        refresh_token: refresh_token,
        get_master_data: get_master_data,
        get_master_data_by_type: get_master_data_by_type,
        set_prevent_company_model_status,
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        rsiB35911ErrorCorrectionsPreventUserFromAddingNcModifierToChargeWithActivePayments: get_lauch_darkley_key_value(state.launch_darkly, "rsiB35911ErrorCorrectionsPreventUserFromAddingNcModifierToChargeWithActivePayments")
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChargeReviewDetailRowComponent));