import * as moment from 'moment';

export const FORMAT_DDDD_DD_MMMM = 'dddd, MMMM DD';
export const FORMAT_DDDD_DD_MMMM_YYYY = 'dddd, MMMM DD, YYYY';
export const FORMAT_YYYY_MM_DD_HH_MM_SS = 'YYYY-MM-DD HH:mm:ss';

/**
 * Date time formatter function
 * @param {*} dateTime pass value tobe format
 * @param {*} format pattern
 */
export const calendarFormat = (dateTime, format) => (moment(dateTime).local().format(format));

/**
 * Current date time
 * It will used to pass modified date.
 * Date time
 */
export const currentDateTime = () => moment().format(FORMAT_YYYY_MM_DD_HH_MM_SS);

/**
 * Current date time
 * It will used to get current date with specific format.
 */
export const currentDate = datePattern => moment().format(datePattern);

/**
 * Get Timezone UTC Offset
 */
export const getTimeOffset = () => {
    const time = moment();
    return time.utcOffset();
};

export const get_date = (date, pattern) => {
    if (date) {
        return moment(date).format(pattern);
    }
    return '';
}