import * as moment from 'moment';
import * as React from 'react';
import * as NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import MaskedInput from 'react-text-mask';
import TextareaAutosize from 'react-textarea-autosize';
import { bindActionCreators } from 'redux';
import { Button, Dimmer, Form, Grid, Header, Loader } from 'semantic-ui-react';
import { setTimeout } from 'timers';
import * as global_constants from '../../global_constants';
import { update_patient_header_info } from '../../patient/action/patient_header_action';
import DatePicker from '../../shared/component/date_picker_component';
import Selection from '../../shared/component/selection_component';
import * as session_storage from '../../shared/session_storage_utility';
import { get_patient_details } from '../action/patient_action';
import { patient_messages, addPatientInitialConfiguration, patientInvalidFieldMessage } from '../patient_constants';
import { handle_click_on_enter, set_focus_to_app_header } from './../../shared/tab_navigation_utility';
import { custom_date_format, get_all_error, is_all_numbers, is_all_letters, unformat_zip_code, unmask, zip_code_formation, trim_nested_json_obj_values} from './../../shared/utility';
import { add_patient, edit_patient } from './../action/patient_action';
import DuplicatePatientComponent from './duplicate_patient_component';
import MedicareCapComponent from './medicare_cap_component';
import * as local_storage from '../../shared/local_storage_utility';
import { quick_search_all_provider, search_all_provider } from './../../charge/action/charge_action';
import { quick_search_location, search_place  } from './../../shared/action/autosearch_action';
import AdvancedControl from './../../shared/component/advanced_control';
import AutoSearchComponent from './../../shared/component/auto_search_component';
import * as shared_constants from './../../shared/shared_constants';
import { set_active_patient } from '../../login/action/login_action';
import { ComposeNameFieldValidator } from '../validator/compose_name_field_validator';

class PatientAddComponent extends React.Component<any, any> {
    [x: string]: any;
    _is_mounted = false;
    _patient_first_name_error_message = '';
    _patient_last_name_error_message = '';
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            is_save_button_disabled:false,
            email_required: local_storage.get("auth_data") ? local_storage.get("auth_data").email_required || false : false,
            patient_master_data: this.props.user_login_details.formated_master_data,
            patient_id: props.match.params.patient_id,
            is_edit_mode: props.location.pathname == '/patient_registration' ? false : true,
            patient_form_invalid: {
                patient: {
                    name: {
                        middle_initial: false,
                        suffix: false,
                        first_name: false,
                        last_name: false,
                        preferred_name: false
                    },
                    place_of_service: false,
                    date_of_birth: false,
                    gender: false,
                    address: {
                        address1: false,
                        address2: false,
                        city: false,
                        province: false,
                        zip: false,
                        postal_code: false,
                        state_id: false,
                        country_id: false
                    },
                    phone: {
                        home: false,
                        work: false,
                        cell: false,
                        primary: false
                    },
                    reminder_type: false,
                    email: false,
                    driver_license: false,
                    ssn: false,
                    patient_notes: false,
                    responsible_party: {
                        name: {
                            middle_initial: false,
                            suffix: false,
                            first_name: false,
                            last_name: false,
                            preferred_name: false
                        },
                        address: {
                            address1: false,
                            address2: false,
                            city: false,
                            province: false,
                            zip: false,
                            postal_code: false,
                            state_id: false,
                            country_id: false
                        },
                        phone: {
                            home: false,
                            work: false,
                            cell: false,
                            primary: false
                        }
                    },
                    emergency_contact: {
                        name: {
                            middle_initial: false,
                            suffix: false,
                            first_name: false,
                            last_name: false,
                            preferred_name: false
                        },
                        phone: {
                            home: false,
                            work: false,
                            cell: false,
                            primary: false
                        }
                    }

                }
            },
            is_us_selected: true,
            is_rp_us_selected: true,
            patient: {
                id: 0,
                company_id: 0,
                name: {
                    middle_initial: '',
                    suffix: '',
                    first_name: '',
                    last_name: '',
                    is_name_requried: false,
                    preferred_name: ''
                },
                place_of_service_id: '-999',
                date_of_birth: null,
                formatted_date_of_birth: null, //this.state.patient.date_of_birth == null ? null : custom_date_format(this.state.patient.date_of_birth, date_format["mm/dd/yyyy"]),
                gender: '',
                patient_notes: '',
                address: {
                    address1: '',
                    address2: '',
                    city: '',
                    province: '',
                    zip: '',
                    postal_code: '',
                    state_id: '',
                    country_id: 1,
                    is_required: false,
                    unformat_zip: ''
                },
                phone: {
                    home: '',
                    work: '',
                    cell: '',
                    primary: '1'
                },
                is_active: true,
                collection_flag: false,
                payment_plan: false,
                marital_status: '',
                email: '',
                ssn: '',
                driver_license: '',
                spoken_language: 'EN',
                reminder_type: '1',
                alt_record_id: '',
                alt_record_id2: '',
                alt_record_id3: '',
                is_force_save: false,
                is_patient_statement: true,
                case_name: '',
                note_name: '',
                note_code: '',
                total_insurance_pay_amount: '',
                total_ansurance_adj_amount: '',
                total_insurance_balance: '',
                total_patient_pay_amount: '',
                total_patient_adj_amount: '',
                total_patient_balance: '',
                location_id: '',
                billing_provider_id: '',
                ts_discipline: '',
                ts_location_id: '',
                case_effective_date: moment(new Date()),
                responsible_party: {
                    name: {
                        middle_initial: '',
                        Suffix: '',
                        First_name: '',
                        Last_name: '',
                        Is_name_requried: false,
                        Preferred_name: ''
                    },
                    address: {
                        address1: '',
                        address2: '',
                        city: '',
                        province: '',
                        zip: '',
                        postal_code: '',
                        state_id: '',
                        country_id: 1,
                        is_required: false,
                        unformat_zip: ''
                    },
                    phone: {
                        home: '',
                        work: '',
                        cell: '',
                        primary: ''
                    },
                    date_of_birth: null,
                    formatted_date_of_birth: null,
                    ssn: '',
                    gender: '',
                    email: '',
                    driver_license: '',
                    is_received_90: false,
                    apply_dunning_code: false,
                    marital_status: '',
                    //dunning_code: '',
                    same_as_patient: false,
                    //  number_of_statements_received: '',
                    hsa: ''
                },
                emergency_contact: {
                    name: {
                        middle_initial: '',
                        suffix: '',
                        first_name: '',
                        last_name: '',
                        is_name_requried: false,
                        preferred_name: ''
                    },
                    phone: {
                        home: '',
                        work: '',
                        cell: '',
                        primary: ''
                    }
                },
                request_provider_id: ''
            },
            form_submited: false,
            is_duplicate_patient_exist: false,
            duplicate_patients: [],
            grid_conf: {
                isPagination: true,
                gridAutoHeight: true,
                selectionType: 'single',
                paginationPageSize: 20,
            },
            provider_quick_search_format:
            {
                "title": '',
                "label": '',
                "code": '',
                "name": ''
            },
            pos_quick_search_format:
            {
                "title": '',
                "label": '',
                "code": '',
                "name": ''
            },
            provider_search_data: {},
            pos_search_data: {}
        };
        this.medicare_child = React.createRef();
        this.handleCalendarChange = this.handleCalendarChange.bind(this);
        this.nameFieldValidator = new ComposeNameFieldValidator();

    }
    show_html_content_toaster = msg => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ),
            timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        };
    };
    componentDidMount() {
        // For EDIT
        // Check if patient data is present in store
        // If data is present in store update the patient object
        // else get data and update the patient object.
        this._is_mounted = true;
        document.body.classList.add('patient-add-edit');
        if (this.state.is_edit_mode) {
            if (this.props.patient_details.selected_patient) {
                this.format_edit_patient_details();
            }
            this.get_patient_details(this.state.patient_id);
        }
    }

    componentDidUpdate(previousProps, previousState) {
        if (this.props.match.params.patient_id &&
            session_storage.get('active_patient') != this.props.match.params.patient_id
            ) {
            session_storage.set('active_patient', this.props.match.params.patient_id);
            this.props.set_active_patient(this.props.match.params.patient_id);

        }

        // Update state if the user changes url from add->update and vice versa
        if (previousProps.location.pathname != this.props.location.pathname) {
            if (this._is_mounted) {
                this.setState({
                    loading: false,
                    is_save_button_disabled:false,
                    patient_master_data: this.props.user_login_details.formated_master_data,
                    patient_id: this.props.match.params.patient_id,
                    is_edit_mode: this.props.location.pathname == '/patient_registration' ? false : true,
                    patient_form_invalid: {
                        patient: {
                            name: {
                                middle_initial: false,
                                suffix: false,
                                first_name: false,
                                last_name: false,
                                preferred_name: false
                            },
                            date_of_birth: false,
                            place_of_service: false,
                            gender: false,
                            address: {
                                address1: false,
                                address2: false,
                                city: false,
                                province: false,
                                zip: false,
                                postal_code: false,
                                state_id: false,
                                country_id: false
                            },
                            phone: {
                                home: false,
                                work: false,
                                cell: false,
                                primary: false
                            },
                            reminder_type: false,
                            email: false,
                            driver_license: false,
                            ssn: false,
                            patient_notes: false,
                            responsible_party: {
                                name: {
                                    middle_initial: false,
                                    suffix: false,
                                    first_name: false,
                                    last_name: false,
                                    preferred_name: false
                                },
                                address: {
                                    address1: false,
                                    address2: false,
                                    city: false,
                                    province: false,
                                    zip: false,
                                    postal_code: false,
                                    state_id: false,
                                    country_id: false
                                },
                                phone: {
                                    home: false,
                                    work: false,
                                    cell: false,
                                    primary: false
                                }
                            },
                            emergency_contact: {
                                name: {
                                    middle_initial: false,
                                    suffix: false,
                                    first_name: false,
                                    last_name: false,
                                    preferred_name: false
                                },
                                phone: {
                                    home: false,
                                    work: false,
                                    cell: false,
                                    primary: false
                                }
                            }
                        }
                    },
                    patient: {
                        id: 0,
                        company_id: 0,
                        name: {
                            middle_initial: '',
                            suffix: '',
                            first_name: '',
                            last_name: '',
                            is_name_requried: false,
                            preferred_name: ''
                        },
                        place_of_service_id:'-999',
                        formatted_date_of_birth: null,
                        date_of_birth: null, //this.state.patient.date_of_birth == null ? null : custom_date_format(this.state.patient.date_of_birth, date_format["mm/dd/yyyy"]),
                        gender: '',
                        patient_notes: '',
                        address: {
                            address1: '',
                            address2: '',
                            city: '',
                            province: '',
                            zip: '',
                            postal_code: '',
                            state_id: '',
                            country_id: 1,
                            is_required: false,
                            unformat_zip: ''
                        },
                        phone: {
                            home: '',
                            work: '',
                            cell: '',
                            primary: '1'
                        },
                        is_active: true,
                        collection_flag: false,
                        payment_plan: false,
                        marital_status: '',
                        email: '',
                        ssn: '',
                        driver_license: '',
                        spoken_language: 'EN',
                        reminder_type: '1',
                        alt_record_id: '',
                        alt_record_id2: '',
                        alt_record_id3: '',
                        is_force_save: false,
                        is_patient_statement: true,
                        case_name: '',
                        note_name: '',
                        note_code: '',
                        total_insurance_pay_amount: '',
                        total_ansurance_adj_amount: '',
                        total_insurance_balance: '',
                        total_patient_pay_amount: '',
                        total_patient_adj_amount: '',
                        total_patient_balance: '',
                        location_id: '',
                        billing_provider_id: '',
                        ts_discipline: '',
                        ts_location_id: '',
                        case_effective_date: moment(new Date()),
                        responsible_party: {
                            name: {
                                middle_initial: '',
                                Suffix: '',
                                First_name: '',
                                Last_name: '',
                                Is_name_requried: false,
                                Preferred_name: ''
                            },
                            address: {
                                address1: '',
                                address2: '',
                                city: '',
                                province: '',
                                zip: '',
                                postal_code: '',
                                state_id: '',
                                country_id: 1,
                                is_required: false,
                                unformat_zip: ''
                            },
                            phone: {
                                home: '',
                                work: '',
                                cell: '',
                                primary: ''
                            },
                            formatted_date_of_birth: null,
                            date_of_birth: null,
                            ssn: '',
                            gender: '',
                            email: '',
                            driver_license: '',
                            is_received_90: false,
                            apply_dunning_code: false,
                            marital_status: '',
                            //dunning_code: '',
                            same_as_patient: false,
                            //  number_of_statements_received: '',
                            hsa: ''
                        },
                        emergency_contact: {
                            name: {
                                middle_initial: '',
                                suffix: '',
                                first_name: '',
                                last_name: '',
                                is_name_requried: false,
                                preferred_name: ''
                            },
                            phone: {
                                home: '',
                                work: '',
                                cell: '',
                                primary: ''
                            }
                        }
                    },
                    form_submited: false,
                    is_duplicate_patient_exist: false,
                    duplicate_patients: [],
                    provider_quick_search_format:
                    {
                        "title": '',
                        "label": '',
                        "code": '',
                        "name": ''
                    },
                    pos_quick_search_format:
                    {
                        "title": '',
                        "label": '',
                        "code": '',
                        "name": ''
                    },
                });
            }
            this.handleCalendarChange = this.handleCalendarChange.bind(this);
            if (this.props.location.pathname != '/patient_registration') {
                if (this.props.patient_details.selected_patient) {
                    this.format_edit_patient_details();
                }
                this.get_patient_details(this.state.patient_id);
            }
        }
    }

    add_patient = () => {
        var _this = this;               
        add_patient(this.props.user_login_details.user_data.data.token_details.access_token, trim_nested_json_obj_values(this.state.patient)).then(
            async response => {
                if (response.data.data.id && response.data.data.id > 0) {
                    session_storage.set('active_patient', response.data.data.id);
                    this.props.set_active_patient(response.data.data.id);

                    var saved = await this.medicare_child.add_update_medicare_cap(response.data.data.id);
                    if (this._is_mounted) {
                        this.setState({ loading: false, is_save_button_disabled: false });
                    }
                    if (!saved) {
                        toastr.success('', patient_messages.added_successfully);
                        this.props.history.push(`/patient/${response.data.data.id}`);
                    } else {
                        if (this.props.patient_details.selected_patient_medicare_cap_updated_data.status == 1) {
                            toastr.success('', patient_messages.added_successfully);
                            this.props.history.push(`/patient/${response.data.data.id}`);
                        } else {
                            toastr.error('', this.props.patient_details.selected_patient_medicare_cap_updated_data.messages[0].message);
                        }
                    }
                } else {
                    if (response.data.data.duplicate_patients && response.data.data.duplicate_patients.length > 0) {
                        var duplicate_patient_list = [];
                        var duplicate_patient_length = response.data.data.duplicate_patients.length;

                        if (duplicate_patient_length > 0) {
                            for (let k = 0; k < duplicate_patient_length; k++) {
                                let item = response.data.data.duplicate_patients[k];

                                duplicate_patient_list.push({
                                    id: `${item.id}`,
                                    name: item.name.first_name + ' ' + item.name.last_name,
                                    address: item.address.address1,
                                    phone: item.phone.home
                                });
                            }
                        }
                        if (this._is_mounted) {
                            this.setState({
                                is_duplicate_patient_exist: response.data.data.duplicate_patients.length > 0,
                                duplicate_patients: duplicate_patient_list,
                                loading: false,
                                is_save_button_disabled: false
                            });
                        }
                    } else {
                        if (this._is_mounted) {
                            this.setState({ loading: false, is_save_button_disabled: false });
                        }
                        toastr.error('', response.data.messages[0].message);
                    }
                }
            },
            error => {
                //convert formatted zip code to unformatted one
                var state_patient = this.state.patient;
                state_patient.address.zip = zip_code_formation(state_patient.address.zip);
                state_patient.responsible_party.address.zip = zip_code_formation(state_patient.responsible_party.address.zip);
               
                if (_this._is_mounted) {
                    _this.setState({ loading: false, patient: state_patient, is_save_button_disabled: false });
                }
                if (error.response && error.response.data && error.response.data.messages) {
                    const toastr_options = _this.show_html_content_toaster(get_all_error(error.response.data));
                    toastr.error('', toastr_options);
                }
            }
        );
    };

    // API call to update patient details
    save_updated_patient_details = async () => {
        var _this = this;
        await edit_patient(this.props.user_login_details.user_data.data.token_details.access_token, trim_nested_json_obj_values(this.state.patient)).then(
            async response => {
                if (response.data.data == true) {
                    session_storage.set('active_patient', this.state.patient_id);
                    this.props.set_active_patient(this.state.patient_id);

                    //await this.medicare_child.add_update_medicare_cap(this.state.patient_id);
                    //this.setState({ loading: false });
                    //if (this.props.patient_details.selected_patient_medicare_cap_updated_data.status == 1) {
                    //    toastr.success('', patient_messages.updated_successfully);
                    //    this.props.history.push(`/patient/${this.state.patient_id}`);
                    //} else {
                    //    toastr.success('', this.props.patient_details.selected_patient_medicare_cap_updated_data.messages[0].message);
                    //}
                    var saved = await this.medicare_child.add_update_medicare_cap(this.state.patient_id);
                    if (this._is_mounted) {
                        this.setState({ loading: false, is_save_button_disabled: false });
                    }
                    if (!saved) {
                        toastr.success('', patient_messages.updated_successfully);
                        this.props.history.push(`/patient/${this.state.patient_id}`);
                    } else {
                        if (this.props.patient_details.selected_patient_medicare_cap_updated_data.status == 1) {
                            toastr.success('', patient_messages.updated_successfully);
                            this.props.history.push(`/patient/${this.state.patient_id}`);
                        } else {
                            toastr.error('', this.props.patient_details.selected_patient_medicare_cap_updated_data.messages[0].message);
                        }
                    }
                    //update patient header while update patient info
                    this.props.update_patient_header_info(true, '', false);
                } else {
                    if (this._is_mounted) {
                        this.setState({ loading: false, is_save_button_disabled: false });
                    }
                    toastr.error('', response.data.messages[0].message);
                }
            },
            error => {
                //convert formatted zip code to unformatted one
                var state_patient = this.state.patient;
                state_patient.address.zip = zip_code_formation(state_patient.address.zip);
                state_patient.responsible_party.address.zip = zip_code_formation(state_patient.responsible_party.address.zip);

                if (_this._is_mounted) {
                    _this.setState({ loading: false, patient: state_patient, is_save_button_disabled: false });
                }
                if (error.response && error.response.data && error.response.data.messages) {
                    const toastr_options = _this.show_html_content_toaster(get_all_error(error.response.data));
                    toastr.error('', toastr_options);
                }
            }
        );
    };

    // Get patient patient data for EDIT
    get_patient_details = async patient_id => {
        await this.props.get_patient_details(this.props.user_login_details.user_data.data.token_details.access_token, patient_id);
        this.format_edit_patient_details();
    };

    format_edit_patient_details = () => {
        if (this.props.patient_details.selected_patient) {
            var state = { ...this.state };
            state.patient = this.props.patient_details.selected_patient.data;

            // Set date object
            if (!(state.patient.date_of_birth == '' || state.patient.date_of_birth == null || state.patient.date_of_birth == undefined)) {
                state.patient.formatted_date_of_birth = moment(this.props.patient_details.selected_patient.data.date_of_birth);
            }
            if (
                !(
                    state.patient.responsible_party.date_of_birth == '' ||
                    state.patient.responsible_party.date_of_birth == null ||
                    state.patient.responsible_party.date_of_birth == undefined
                )
            ) {
                state.patient.responsible_party.formatted_date_of_birth = moment(this.props.patient_details.selected_patient.data.responsible_party.date_of_birth);
            }
            // if country of patient is null then convert it to US as selected
            state.patient.address.country_id = state.patient.address.country_id ? state.patient.address.country_id : 1;
            state.patient.responsible_party.address.country_id = state.patient.responsible_party.address.country_id ? state.patient.responsible_party.address.country_id : 1;
            state.patient.phone.primary = state.patient.phone.primary ? state.patient.phone.primary : '1';
            state.patient.address.state_id = state.patient.address.state_id ? state.patient.address.state_id.toString().trim() : state.patient.address.state_id;
            ///
            state.patient.address.unformat_zip = unformat_zip_code(state.patient.address.zip);
            state.patient.address.zip = zip_code_formation(state.patient.address.zip);
            state.patient.responsible_party.address.unformat_zip = unformat_zip_code(state.patient.responsible_party.address.zip);
            state.patient.responsible_party.address.zip = zip_code_formation(state.patient.responsible_party.address.zip);
            state.patient.responsible_party.address.state_id = state.patient.responsible_party.address.state_id
                ? state.patient.responsible_party.address.state_id.toString().trim()
                : state.patient.responsible_party.address.state_id;

            var is_same_as_Patient = this.check_same_patient_details(state.patient);
            state.patient.responsible_party.same_as_patient = is_same_as_Patient;
            var provider_format = { "title": '', "label": '', "code": '', "name": '' };
            if (state.patient.requested_provider_id > 0) {
                provider_format = {
                    "title": state.patient.requested_provider_id.toString(),
                    "code": state.patient.provider_code,
                    "label": (state.patient.provider_code) + " - " + (state.patient.provider_desc),
                    "name": ''

                };
            }
            if (this._is_mounted) {
                this.setState({
                    provider_quick_search_format: provider_format,
                    is_us_selected: state.patient.address.country_id == 1,
                    is_rp_us_selected: state.patient.responsible_party.address.country_id == 1,
                    patient: state.patient
                });
            }
        }
    };

    //Change event for input :- first name, last name ...
    on_patient_form_change = (e, _obj_hirerachy, is_emergency_contact, is_responsibility_party, dropdown_name, dropdown_value) => {
        const field_name = e.target.name != undefined ? e.target.name.replace('txt_', '') : e.target.name;
        var field_value = e.target.value != undefined ? e.target.value.trimStart() : e.target.value;
        const state = { ...this.state };
        const state_patient = state.patient;
        const patient_invalid = state.patient_form_invalid.patient;
        const _obj_name = is_responsibility_party ? state_patient.responsible_party : is_emergency_contact ? state_patient.emergency_contact : state_patient;
        const _obj_name_invalid = is_responsibility_party ? patient_invalid.responsible_party : is_emergency_contact ? patient_invalid.emergency_contact : patient_invalid;

        var is_valid = false;
        if (field_name == 'ssn' || field_name == 'home' || field_name == 'cell' || field_name == 'work') {
            field_value = unmask(field_value);
        }
        //For HSA Numeric field validation
        if (field_name == 'hsa') {
            if (_obj_hirerachy) {
                field_value = e.target.validity.valid
                    ? Number(e.target.value) <= 2147483647
                        ? e.target.value
                        : _obj_name[_obj_hirerachy][field_name]
                    : _obj_name[_obj_hirerachy][field_name];
            } else {
                field_value = e.target.validity.valid ? (Number(e.target.value) <= 2147483647 ? e.target.value : _obj_name[field_name]) : _obj_name[field_name];
            }
        }
        if (field_name == 'zip') {
            field_value = unformat_zip_code(field_value);
            if (_obj_hirerachy) {
                field_value = is_all_numbers(field_value)
                    ? Number(field_value) <= 2147483647
                        ? field_value
                        : _obj_name[_obj_hirerachy]['unformat_' + field_name]
                    : _obj_name[_obj_hirerachy]['unformat_' + field_name];
                _obj_name[_obj_hirerachy]['unformat_' + field_name] = unformat_zip_code(field_value);
            } else {
                field_value = is_all_numbers(field_value) ? (Number(field_value) <= 2147483647 ? field_value : _obj_name['unformat_' + field_name]) : _obj_name['unformat_' + field_name];
                _obj_name['unformat_' + field_name] = unformat_zip_code(field_value);
            }
            if (field_value.length > 5 && field_value.length <= 9) {
                field_value = zip_code_formation(field_value);
            }
        }
        if (field_name == 'province' || field_name == 'postal_code') {
            var valid = this.check_allowed_special_chars(field_value);
            if (_obj_hirerachy) {
                field_value = valid ? _obj_name[_obj_hirerachy][field_name] : e.target.value;
            } else {
                field_value = valid ? _obj_name[field_name] : e.target.value;
            }
        }
        if (!dropdown_value) {
            if (e.target.name == 'phone') {
                _obj_name['masked_phone'] = field_value;
                //state.patient['unmasked_phone'] = unmask(field_value);
                field_value = _obj_name['unmasked_phone'];
            } else {
                if (_obj_hirerachy) {
                    _obj_name[_obj_hirerachy][field_name] = field_value;
                } else {
                    _obj_name[field_name] = field_value;
                }
            }
        } else {
            if (_obj_hirerachy) {
                _obj_name[_obj_hirerachy][dropdown_name] = dropdown_value;
            } else {
                _obj_name[dropdown_name] = dropdown_value;
            }
        }
        var _is_us_selected = true;
        var _is_rp_us_selected = true;
        var is_need_update_patient = false;
        var is_need_update_rp = false;
        if (!is_responsibility_party && dropdown_name == 'country_id') {
            _is_us_selected = dropdown_value == 1;
            is_need_update_patient = true;
        } else if (is_responsibility_party && dropdown_name == 'country_id') {
            _is_rp_us_selected = dropdown_value == 1;
            is_need_update_rp = true;
        } else {
            is_need_update_patient = false;
            is_need_update_rp = false;
        }

        var is_form_invalid = this.validate_form_feild_data(state_patient, { patient: patient_invalid });

        var is_same_as_Patient = this.check_same_patient_details(state_patient);

        state_patient.responsible_party.same_as_patient = is_same_as_Patient;

        if (is_need_update_patient) {
            if (this._is_mounted) {
                this.setState({
                    is_us_selected: _is_us_selected,
                    patient: state_patient,
                    patient_form_invalid: is_form_invalid.patient_data
                });
            }
        } else if (is_need_update_rp) {
            if (this._is_mounted) {
                this.setState({
                    is_rp_us_selected: _is_rp_us_selected,
                    patient: state_patient,
                    patient_form_invalid: is_form_invalid.patient_data
                });
            }
        } else {
            if (this._is_mounted) {
                this.setState({
                    patient: state_patient,
                    patient_form_invalid: is_form_invalid.patient_data
                });
            }
        }
    };
    //ON HSA CHANGE Currency Format
    //
    on_hsa_change = e => {
        var data = this.state.patient;
        var amount_data = Number(e.formattedValue.replace('$', '').replace(/,/g, ''));
        amount_data = Math.floor(amount_data).toString().length > 5 ? data.responsible_party.hsa : amount_data;
        data.responsible_party.hsa = amount_data > 0 && Number(amount_data) <= 2147483647 ? amount_data : '';
        if (this._is_mounted) {
            this.setState({
                patient: data
            });
        }
    };
    //
    // validate each input box on change event
    validate_value(field_name, field_value, is_emergency_contact) {
        // Email is not a required field.
        if (field_name != 'email' && !is_emergency_contact && !field_value) {
            return true;
        }

        switch (field_name) {
            case 'email':
                var email_invalid = field_value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? false : true;
                return email_invalid;
                break;
            //case 'phone':
            //    return (!field_value || (field_value && field_value.length > 0 && field_value.length != 10));
            //    break;
            case 'ssn':
                return !field_value || (field_value && field_value.length > 0 && field_value.length != 9);
                break;
            case 'zip':
                var valid_zip = false;
                if ((field_value.length > 0 && field_value.length < 5) || (field_value.length > 5 && field_value.length < 9)) {
                    valid_zip = true;
                }
                return valid_zip;
                break;
            case 'date_of_birth':
                return !field_value || (field_value && field_value.length > 0 && field_value.length != 9);
                break;
            case 'default':
                return false;
        }
    }

    on_patient_cancel_click = () => {
        if (this.state.is_edit_mode) {
            this.props.history.push(`/patient/${this.state.patient_id}`);
        } else {
            this.props.history.push('/patient_search');
        }
    };

    validate_form_feild_data = (patientData, validate_field) => {
        var in_valid_form_data = false;
        var patientData = patientData; // this.state.patient;
        var valid = validate_field.patient; // this.state.patient_form_invalid.patient;
        //patient First Name
        if (patientData.name.first_name == null || patientData.name.first_name == undefined || patientData.name.first_name == '') {
            valid.name.first_name = true;
            in_valid_form_data = true;
        } else {
            valid.name.first_name = false;
        }
        //patient last Name
        if (patientData.name.last_name == null || patientData.name.last_name == undefined || patientData.name.last_name == '') {
            valid.name.last_name = true;
            in_valid_form_data = true;
        } else {
            valid.name.last_name = false;
        }
        //patient location
        if (!this.state.is_edit_mode && (patientData.place_of_service_id == null || patientData.place_of_service_id == undefined || patientData.place_of_service_id == '' || patientData.place_of_service_id < 0)) {
            valid.place_of_service = true;
            in_valid_form_data = true;
        } else {
            valid.place_of_service = false;
        }
        //patient address1
        if (patientData.address.address1 == null || patientData.address.address1 == undefined || patientData.address.address1 == '') {
            valid.address.address1 = true;
        } else {
            valid.address.address1 = false;
        }
        //patient city
        if (patientData.address.city == null || patientData.address.city == undefined || patientData.address.city == '') {
            valid.address.city = true;
        } else {
            valid.address.city = false;
        }


        //patient conutry
        if (patientData.address.country_id == null || patientData.address.country_id == undefined || patientData.address.country_id == '') {
            valid.address.country_id = true;
        } else {
            valid.address.country_id = false;
            if (this.state.is_us_selected) {
                //patient state
                if (patientData.address.state_id == null || patientData.address.state_id == undefined || patientData.address.state_id.trim() == '') {
                    valid.address.state_id = true;
                } else {
                    valid.address.state_id = false;
                }
                //patient zip
                if (patientData.address.zip == null || patientData.address.zip == undefined || patientData.address.zip == '') {
                    valid.address.zip = true;
                } else {
                    var zip = unformat_zip_code(patientData.address.zip);
                    if ((zip.length > 0 && zip.length < 5) || (zip.length > 5 && zip.length < 9)) {
                        valid.address.zip = true;
                        in_valid_form_data = true;
                    } else {
                        valid.address.zip = false;
                    }
                }
            } else {
                valid.address.state_id = false;
                valid.address.zip = false;
            }
        }

        //patient Phone
        //TODO:only check empty feild
        if (patientData.phone.primary == null || patientData.phone.primary == undefined || patientData.phone.primary == '') {
            valid.phone.phone = true;
            in_valid_form_data = true;
        } else {
            if (patientData.phone.primary == '1') {
                if (patientData.phone.home == null || patientData.phone.home == undefined || patientData.phone.home == '') {
                    valid.phone.work = false;
                    valid.phone.cell = false;
                    valid.phone.home = true;
                    in_valid_form_data = true;
                } else {
                    if (patientData.phone.home.length > 0 && patientData.phone.home.length != 10) {
                        valid.phone.work = false;
                        valid.phone.cell = false;
                        valid.phone.home = true;
                    } else {
                        valid.phone.work = false;
                        valid.phone.cell = false;
                        valid.phone.home = false;
                    }
                }
            } else if (patientData.phone.primary == '2') {
                if (patientData.phone.cell == null || patientData.phone.cell == undefined || patientData.phone.cell == '') {
                    valid.phone.cell = true;
                    valid.phone.home = false;
                    valid.phone.work = false;
                    in_valid_form_data = true;
                } else {
                    if (patientData.phone.cell.length > 0 && patientData.phone.cell.length != 10) {
                        valid.phone.cell = true;
                        valid.phone.home = false;
                        valid.phone.work = false;
                    } else {
                        valid.phone.work = false;
                        valid.phone.cell = false;
                        valid.phone.home = false;
                    }
                }
            } else if (patientData.phone.primary == '3') {
                if (patientData.phone.work == null || patientData.phone.work == undefined || patientData.phone.work == '') {
                    valid.phone.work = true;
                    valid.phone.cell = false;
                    valid.phone.home = false;
                    in_valid_form_data = true;
                } else {
                    if (patientData.phone.work.length > 0 && patientData.phone.work.length != 10) {
                        valid.phone.work = true;
                        valid.phone.cell = false;
                        valid.phone.home = false;
                    } else {
                        valid.phone.work = false;
                        valid.phone.cell = false;
                        valid.phone.home = false;
                    }
                }
            }
        }
        // If any of phone number is not empty and length not equal to 10.
        if (patientData.phone.home && patientData.phone.home.length > 0 && patientData.phone.home.length != 10) {
            valid.phone.home = true;
            in_valid_form_data = true;
        }
        if (patientData.phone.cell && patientData.phone.cell.length > 0 && patientData.phone.cell.length != 10) {
            valid.phone.cell = true;
            in_valid_form_data = true;
        }
        if (patientData.phone.work && patientData.phone.work.length > 0 && patientData.phone.work.length != 10) {
            valid.phone.work = true;
            in_valid_form_data = true;
        }
        if (patientData.name.middle_initial)
        {
            let middleInitial = patientData.name.middle_initial;
            let isMiddleInitialInValid = !is_all_letters(middleInitial) || this.check_allowed_special_chars(middleInitial);
            valid.name.middle_initial = isMiddleInitialInValid;
            in_valid_form_data = isMiddleInitialInValid;
        } else {
            valid.name.middle_initial = false;
        }

        if (!valid.name.first_name && this.nameFieldValidator.isValid(patientData.name.first_name)) {
            valid.name.first_name = false;
        } else {
            valid.name.first_name = true;
            in_valid_form_data = true;
            this._patient_first_name_error_message = this.nameFieldValidator.getErrorMessages()[0];
        }

        if (!valid.name.last_name && this.nameFieldValidator.isValid(patientData.name.last_name)) {
            valid.name.last_name = false;
        } else {
            valid.name.last_name = true;
            in_valid_form_data = true;
            this._patient_last_name_error_message = this.nameFieldValidator.getErrorMessages()[0];
        }

        //patient Email
        //TODO: only check empty feild
        if (patientData.email && (patientData.email.trim().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? false : true)) {
            valid.email = true;
            in_valid_form_data = true;
        } else {
            //patient email
            if (this.state.email_required) {
                if (patientData.email == null || patientData.email == undefined || patientData.email == '') {
                    in_valid_form_data = true;
                    valid.email = true;
                } else {
                    valid.email = false;
                }
            }
            else {
                valid.email = false;
            }
        }
        //patient Date of Birth
        //only check empty feild
        if (patientData.formatted_date_of_birth == null || patientData.formatted_date_of_birth == undefined || patientData.formatted_date_of_birth == '') {
            valid.date_of_birth = true;
            in_valid_form_data = true;
        } else {
            valid.date_of_birth = false;
        }
        //patient Gender
        if (patientData.gender == null || patientData.gender == undefined || patientData.gender == '') {
            valid.gender = true;
            in_valid_form_data = true;
        } else {
            valid.gender = false;
        }
        //patient SSN
        if (patientData.ssn == null || patientData.ssn == undefined || patientData.ssn == '') {
            valid.ssn = true;
        } else {
            if (patientData.ssn.length > 0 && patientData.ssn.length != 9) {
                valid.ssn = true;
                in_valid_form_data = true;
            } else {
                valid.ssn = false;
            }
        }
        //Emergency Contact
        //TODO:only check empty feild
        // If any of phone number is not empty and length not equal to 10.
        if (patientData.emergency_contact.phone.home && patientData.emergency_contact.phone.home.length > 0 && patientData.emergency_contact.phone.home.length != 10) {
            valid.emergency_contact.phone.home = true;
            in_valid_form_data = true;
            valid.emergency_contact.phone.home = true;
        } else {
            valid.emergency_contact.phone.home = false;
        }
        if (patientData.emergency_contact.phone.cell && patientData.emergency_contact.phone.cell.length > 0 && patientData.emergency_contact.phone.cell.length != 10) {
            valid.emergency_contact.phone.cell = true;
            in_valid_form_data = true;
            valid.emergency_contact.phone.cell = true;
        } else {
            valid.emergency_contact.phone.cell = false;
        }
        if (patientData.emergency_contact.phone.work && patientData.emergency_contact.phone.work.length > 0 && patientData.emergency_contact.phone.work.length != 10) {
            valid.emergency_contact.phone.work = true;
            in_valid_form_data = true;
        } else {
            valid.emergency_contact.phone.work = false;
        }
        ////////
        //Responsible Party First Name
        if (
            patientData.responsible_party.name.first_name == null ||
            patientData.responsible_party.name.first_name == undefined ||
            patientData.responsible_party.name.first_name == ''
        ) {
            valid.responsible_party.name.first_name = true;
        } else {
            valid.responsible_party.name.first_name = false;
        }
        //Responsible Party last Name
        if (patientData.responsible_party.name.last_name == null || patientData.responsible_party.name.last_name == undefined || patientData.responsible_party.name.last_name == '') {
            valid.responsible_party.name.last_name = true;
        } else {
            valid.responsible_party.name.last_name = false;
        }
        //Responsible Party address1
        if (
            patientData.responsible_party.address.address1 == null ||
            patientData.responsible_party.address.address1 == undefined ||
            patientData.responsible_party.address.address1 == ''
        ) {
            valid.responsible_party.address.address1 = true;
        } else {
            valid.responsible_party.address.address1 = false;
        }
        //Responsible Party city
        if (patientData.responsible_party.address.city == null || patientData.responsible_party.address.city == undefined || patientData.responsible_party.address.city == '') {
            valid.responsible_party.address.city = true;
        } else {
            valid.responsible_party.address.city = false;
        }
        //Responsible Party conutry
        if (
            patientData.responsible_party.address.country_id == null ||
            patientData.responsible_party.address.country_id == undefined ||
            patientData.responsible_party.address.country_id == ''
        ) {
            valid.responsible_party.address.country_id = true;
        } else {
            valid.responsible_party.address.country_id = false;
            if (this.state.is_rp_us_selected) {
                //Responsible Party state
                if (
                    patientData.responsible_party.address.state_id == null ||
                    patientData.responsible_party.address.state_id == undefined ||
                    patientData.responsible_party.address.state_id.trim() == ''
                ) {
                    valid.responsible_party.address.state_id = true;
                } else {
                    valid.responsible_party.address.state_id = false;
                }
                //Responsible Party zip
                if (patientData.responsible_party.address.zip == null || patientData.responsible_party.address.zip == undefined || patientData.responsible_party.address.zip == '') {
                    valid.responsible_party.address.zip = true;
                } else {
                    var zip = unformat_zip_code(patientData.responsible_party.address.zip);
                    if ((zip.length > 0 && zip.length < 5) || (zip.length > 5 && zip.length < 9)) {
                        valid.responsible_party.address.zip = true;
                        in_valid_form_data = true;
                    } else {
                        valid.responsible_party.address.zip = false;
                    }
                }
            } else {
                valid.responsible_party.address.zip = false;
                valid.responsible_party.address.state_id = false;
            }
        }

        //Responsible Party Phone
        //TODO:
        if (patientData.responsible_party.phone.cell == null || patientData.responsible_party.phone.cell == undefined || patientData.responsible_party.phone.cell == '') {
            valid.responsible_party.phone.cell = true;
        } else {
            if (patientData.responsible_party.phone.cell.length > 0 && patientData.responsible_party.phone.cell.length != 10) {
                valid.responsible_party.phone.cell = true;
                in_valid_form_data = true;
            } else {
                valid.responsible_party.phone.cell = false;
            }
        }

        if (patientData.responsible_party.ssn && (patientData.responsible_party.ssn.length > 0 && patientData.responsible_party.ssn.length != 9)) {
            valid.responsible_party.ssn = true;
            in_valid_form_data = true;
        } else {
            valid.responsible_party.ssn = false;
        }

        if (patientData.responsible_party.email && (patientData.responsible_party.email.trim().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? false : true)) {
            valid.responsible_party.email = true;
            in_valid_form_data = true;
        } else {
            valid.responsible_party.email = false;
        }

        var validate_form = {
            in_valid_form_data: in_valid_form_data,
            patient_data: { patient: valid }
        };

        return validate_form;
    };
    // check patient details is same as responsibility party details
    check_same_patient_details = state_patient => {
        // var moment = moment();
        var _same_as_patient = false;
        if (
            JSON.stringify(state_patient.name, function (key, val) {
                if (key !== 'preferred_name') {
                    return val;
                }
            }) ===
            JSON.stringify(state_patient.responsible_party.name, function (key, val) {
                if (key !== 'preferred_name') {
                    return val;
                }
            }) &&
            JSON.stringify(state_patient.responsible_party.address, function (key, val) {
                if (val == null || val == '' || val == undefined) {
                    return '';
                }
                return val;
            }) ===
            JSON.stringify(state_patient.address, function (key, val) {
                if (val == null || val == '' || val == undefined) {
                    return '';
                }
                return val;
            }) &&
            // JSON.stringify(state_patient.responsible_party.address) === JSON.stringify(state_patient.address) &&
            state_patient.responsible_party.email == state_patient.email &&
            (state_patient.responsible_party.formatted_date_of_birth
                ? state_patient.formatted_date_of_birth
                    ? state_patient.responsible_party.formatted_date_of_birth.format('LL') == state_patient.formatted_date_of_birth.format('LL')
                    : false
                : state_patient.formatted_date_of_birth
                    ? false
                    : true) &&
            //state_patient.responsible_party.date_of_birth.format('LL') == state_patient.date_of_birth.format('LL') &&
            state_patient.responsible_party.gender == state_patient.gender &&
            state_patient.responsible_party.ssn == state_patient.ssn &&
            state_patient.responsible_party.marital_status == state_patient.marital_status &&
            state_patient.responsible_party.driver_license == state_patient.driver_license &&
            (state_patient.phone.primary == '1'
                ? state_patient.responsible_party.phone.cell == state_patient.phone.home
                : state_patient.phone.primary == '2'
                    ? state_patient.responsible_party.phone.cell == state_patient.phone.cell
                    : state_patient.responsible_party.phone.cell == state_patient.phone.work)
            //(state_patient.responsible_party.phone.cell == state_patient.phone.cell ||
            //    state_patient.responsible_party.phone.cell == state_patient.phone.work ||
            //    state_patient.responsible_party.phone.cell == state_patient.phone.home)
        ) {
            _same_as_patient = true;
        }

        return _same_as_patient;
    };
    on_change_active_patient = e => {
        if (this._is_mounted) {
            this.setState({
                patient: {
                    ...this.state.patient,
                    is_active: e.target.checked
                }
            });
        }
    };
    on_change_send_statement_patient = e => {
        if (this._is_mounted) {
            this.setState({
                patient: {
                    ...this.state.patient,
                    is_patient_statement: !e.target.checked
                }
            });
        }
    };
    // Copy patient data to responsible party
    copy_data = e => {
        var _patient = Object.assign({}, this.state.patient);
        var responsible_party = Object.assign({}, this.state.patient.responsible_party);
        var _phone = Object.assign({}, this.state.patient.responsible_party.phone);
        var _is_us_selected = _patient.address.country_id == 1;

        var patient_validation = Object.assign({}, this.state.patient_form_invalid);

        if (e.target.checked) {
            var updated_patient_data = {
                ...this.state.patient,
                responsible_party: {
                    ...this.state.patient.responsible_party,
                    name: {
                        middle_initial: _patient.name.middle_initial,
                        suffix: _patient.name.suffix,
                        first_name: _patient.name.first_name,
                        last_name: _patient.name.last_name,
                        preferred_name: _patient.name.preferred_name,
                        is_name_requried: false
                    },
                    address: {
                        address1: _patient.address.address1,
                        address2: _patient.address.address2,
                        city: _patient.address.city,
                        province: _patient.address.province,
                        //province: _is_us_selected ? '' : _patient.address.province,
                        zip: _patient.address.zip,

                        postal_code: _patient.address.postal_code,
                        //postal_code: _is_us_selected ? '' : _patient.address.postal_code,
                        state_id: _patient.address.state_id,
                        country_id: _patient.address.country_id,
                        is_required: _patient.address.is_required,
                        unformat_zip: _patient.address.unformat_zip, //false
                    },
                    phone: {
                        home: '',
                        work: '',
                        cell: _patient.phone.primary == '1' ? _patient.phone.home : _patient.phone.primary == '2' ? _patient.phone.cell : _patient.phone.work,
                        primary: ''
                    },
                    email: _patient.email,
                    formatted_date_of_birth: this.state.patient_form_invalid.patient.date_of_birth && this.state.form_submited ? null : _patient.formatted_date_of_birth,
                    gender: _patient.gender,
                    ssn: _patient.ssn,
                    marital_status: _patient.marital_status,
                    driver_license: _patient.driver_license,
                    same_as_patient: e.target.checked
                }
            };

            var updated_validation = this.validate_form_feild_data(updated_patient_data, patient_validation);
            if (this._is_mounted) {
                this.setState({
                    is_us_selected: _is_us_selected,
                    is_rp_us_selected: _is_us_selected,
                    patient: updated_patient_data,
                    patient_form_invalid: updated_validation.patient_data
                });
            }
        } else {
            var updated_patient_data = {
                ...this.state.patient,
                responsible_party: {
                    ...this.state.patient.responsible_party,
                    same_as_patient: e.target.checked
                }
            };

            var updated_validation = this.validate_form_feild_data(updated_patient_data, patient_validation);
            if (this._is_mounted) {
                this.setState({
                    patient: updated_patient_data,
                    patient_form_invalid: updated_validation.patient_data
                });
            }
        }

        //window.setTimeout(function () {
        //    this.validate_form_feild_data();
        //}, 500);
    };

    on_patient_save_click = () => {
        if (this._is_mounted) {
            this.setState({ is_save_button_disabled: true });
        }
        var patientData = this.state.patient;
        //handle trim with email on save
        patientData.email = patientData.email ? patientData.email.trim() : patientData.email;
        patientData.name.middle_initial = patientData.name.middle_initial ? patientData.name.middle_initial.toString().toUpperCase() : patientData.name.middle_initial;
        patientData.responsible_party.email = patientData.responsible_party.email ? patientData.responsible_party.email.trim() : patientData.responsible_party.email;
        var validate_field = this.state.patient_form_invalid;
        var is_form_invalid = this.validate_form_feild_data(patientData, validate_field);
        var stop_submit = false;

        if (is_form_invalid.in_valid_form_data) {
            toastr.error('', patient_messages.required_fields);
            stop_submit = true;
        } else {
            if (this.check_allowed_special_chars(patientData.responsible_party.name.first_name)) {
                validate_field.patient.responsible_party.name.first_name = true;
                toastr.error('', patient_messages.special_characters_validation);
                stop_submit = true;
            } else if (this.check_allowed_special_chars(patientData.responsible_party.name.last_name)) {
                validate_field.patient.responsible_party.name.last_name = true;
                toastr.error('', patient_messages.special_characters_validation);
                stop_submit = true;
            } else if (!this.validate_date(patientData.formatted_date_of_birth)) {
                patientData.formatted_date_of_birth = null;
                validate_field.patient.date_of_birth = true;
                toastr.error('', patient_messages.date_of_birth_validation);
                stop_submit = true;
            } else if (!this.validate_date(patientData.responsible_party.formatted_date_of_birth)) {
                patientData.responsible_party.formatted_date_of_birth = null;
                validate_field.patient.responsible_party.date_of_birth = true;
                toastr.error('', patient_messages.date_of_birth_responsibility_validation);
                stop_submit = true;
            }
        }

        if (this.medicare_child.check_invalid_data()) {
            if (!stop_submit) {
                toastr.error('', patient_messages.required_fields);
                stop_submit = true;
            }
        }

        if (this.medicare_child.check_duplicate_data() > 0) {
            if (!stop_submit) {
                toastr.error('', patient_messages.duplicate_speciality);
                stop_submit = true;
            }
        }

        //convert to string fomat from moment object for saving
        patientData.date_of_birth =
            patientData.formatted_date_of_birth == null ? null : patientData.formatted_date_of_birth;
        patientData.responsible_party.date_of_birth =
            patientData.responsible_party.formatted_date_of_birth == null
                ? null
                : patientData.responsible_party.formatted_date_of_birth;
        //clear the zip code and state when country other than us is selected
        if (patientData.address.country_id && patientData.address.country_id == 1) {
            patientData.address.province = null;
            patientData.address.postal_code = null;
        } else {
            patientData.address.zip = null;
            patientData.address.unformat_zip = '';
            patientData.address.state_id = null;
        }

        if (patientData.responsible_party.address.country_id && patientData.responsible_party.address.country_id == 1) {
            patientData.responsible_party.address.province = null;
            patientData.responsible_party.address.postal_code = null;
        } else {
            patientData.responsible_party.address.zip = null;
            patientData.responsible_party.address.unformat_zip = '';
            patientData.responsible_party.address.state_id = null;
        }
        // set integer inputs to zero if not entered
        if (patientData.total_ansurance_adj_amount == '') {
            patientData.total_ansurance_adj_amount = 0;
        }
        if (patientData.total_insurance_balance == '') {
            patientData.total_insurance_balance = 0;
        }
        if (patientData.total_insurance_pay_amount == '') {
            patientData.total_insurance_pay_amount = 0;
        }
        if (patientData.total_patient_adj_amount == '') {
            patientData.total_patient_adj_amount = 0;
        }
        if (patientData.total_patient_balance == '') {
            patientData.total_patient_balance = 0;
        }
        if (patientData.total_patient_pay_amount == '') {
            patientData.total_patient_pay_amount = 0;
        }
        if (patientData.ts_location_id == '') {
            patientData.ts_location_id = 0;
        }
        if (patientData.responsible_party.hsa == '') {
            patientData.responsible_party.hsa = 0;
        }

        if (this._is_mounted) {
            this.setState({
                patient: patientData,
                form_submited: true,
                patient_form_invalid: is_form_invalid.patient_data
            });
        }
        this.render();

        if (!stop_submit) {
            //convert formatted zip code to unformatted one
            patientData.address.zip = unformat_zip_code(patientData.address.zip);
            patientData.responsible_party.address.zip = unformat_zip_code(patientData.responsible_party.address.zip);

            if (this._is_mounted) {
                this.setState({ loading: true, patient: patientData });
            }
            // Add/Update data
            if (this.state.is_edit_mode) {
                this.save_updated_patient_details();
            } else {
                this.add_patient();
            }
        }
        else {
            if (this._is_mounted) {
                this.setState({ is_save_button_disabled: false });
            }
        }
    };
    validate_date = date => {
        let m = moment(date);
        var valid = true;
        if (m.isValid() && m.diff(moment(), 'days') > 0) {
            valid = false;
        }
        return valid;
    };
    check_allowed_special_chars = value => {
        if (value != undefined || value != null) {
            var notAllowedSplChars = '~!@#$%^&*()_+`={}|[]\\:";<>?,./';
            for (var i = 0; i < value.length; i++) {
                if (notAllowedSplChars.indexOf(value.charAt(i)) != -1) {
                    return true;
                }
            }
        }

        return false;
    };

    // Will handle date selection from calendar
    handleCalendarChange = dt => {
        let m = moment(dt);
        var state = { ...this.state };
        var patient_info = state.patient;
        var patient_invalid = state.patient_form_invalid;
        if (dt != null && m.isValid()) {
            patient_invalid.patient.date_of_birth = false;
        } else {
            patient_invalid.patient.date_of_birth = true;
        }
        patient_info.formatted_date_of_birth = dt;
        patient_info.responsible_party.same_as_patient = this.check_same_patient_details(patient_info);
        if (this._is_mounted) {
            this.setState({
                patient: patient_info,
                patient_form_invalid: patient_invalid
            });
        }
    };

    handleCalendar_responsible_partyChange = dt => {
        let m = moment(dt);
        var state = { ...this.state };
        var patient_info = state.patient;
        var patient_invalid = state.patient_form_invalid;
        if (m.isValid()) {
            patient_invalid.patient.responsible_party.date_of_birth = false;
        }
        patient_info.responsible_party.formatted_date_of_birth = dt;
        patient_info.responsible_party.same_as_patient = this.check_same_patient_details(patient_info);
        if (this._is_mounted) {
            this.setState({
                patient: patient_info,
                patient_form_invalid: patient_invalid
            });
        }
    };
    //Duplicate patient
    on_duplicate_patient_close = () => {
        //ToDO
        if (this._is_mounted) {
            this.setState({
                is_duplicate_patient_exist: false
            });
        }
    };
    on_duplicate_patient_force_save = () => {
        //ToDO
        var state = { ...this.state };
        var patient_info = state.patient;
        patient_info.is_force_save = true;
        if (this._is_mounted) {
            this.setState({
                patient: patient_info
            });
        }
        this.on_duplicate_patient_close();
        this.on_patient_save_click();
    };
    on_duplicate_patient_row_selection = row => {
        this.on_duplicate_patient_close();
        session_storage.set('active_patient', row.id);
        this.props.set_active_patient(row.id);
        this.props.history.push(`/patient/${row.id}`);
        //this.get_patient_details(row.id)
    };

    //Date required validation background color
    required_date = () => {
        let value = '';
        if (this.state.patient_form_invalid.patient.date_of_birth && this.state.form_submited) {
            value = 'requiredWithBgColor';
        }
        return value;
    };

    componentWillUnmount() {
        this._is_mounted = false;
        document.body.classList.remove('patient-add-edit');
    }
    //Requested providers
    on_provider_grid_row_selection = (item) => {

        let formatted_provider = {
            "title": `${item.id}`,
            "code": item.code,
            "description": item.description,
            "id": item.id,
            "label": (item.code) + " - " + (item.description),

        };
        var patient_data = this.state.patient;
        patient_data.requested_provider_id = formatted_provider.title;
        if (this._is_mounted) {
            this.setState({
                provider_quick_search_format: formatted_provider,
                patient: patient_data

            })
        }
    }
    //Requested providers
    on_place_of_service_grid_row_selection = (item) => {
        let formatted_pos = {
            "title": `${item.id}`,
            "code": item.code,
            "description": item.name,
            "id": item.id,
            "label": (item.code) + " - " + (item.name),

        };
        var patient_invalid = this.state.patient_form_invalid;       
        patient_invalid.patient.place_of_service = false;
        var patient_data = this.state.patient;
        patient_data.place_of_service_id = formatted_pos.title;
        if (this._is_mounted) {
            this.setState({
                pos_quick_search_format: formatted_pos,
                patient: patient_data,
                patient_form_invalid: patient_invalid
            })
        }
    }
    on_provider_search = async (params) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        var grid_data = [];
        
        params = params ? params : {};
        params.id = -1;
        params.apply_pos_code = false;
        const search_data_provider = await search_all_provider(params, token).then(res => res.data);
        const search_provider_result = search_data_provider.data !== null ? search_data_provider.data.result : [];
        grid_data = { ...this.state.grid_conf, rows: search_provider_result, column: shared_constants.search_provider_configuration('provider').column_defs, messages: search_data_provider.messages };
        if (this._is_mounted) {
            this.setState({ provider_search_data: grid_data });
        }
    }
    on_pos_search = async (params) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        var grid_data = [];

        params = params ? params : {};
        params.id = -1;
        const search_data_location = await search_place(params, token).then(res => res.data);
        const search_location_result = search_data_location.data !== null ? search_data_location.data.result : [];
        grid_data = { ...this.state.grid_conf, rows: search_location_result, column: shared_constants.search_location_configuration('location').column_defs, messages: search_data_location.messages };
        if (this._is_mounted) {
            this.setState({ pos_search_data: grid_data });
        }
    }
    on_blur_auto_search = (e) => { }

    setup_medicare_child_reference = (instance) => {
        this.medicare_child = instance;        
     }
    get_input_value = (input) => {

    }
    // get the quick search provider
    get_provider_quick_search_data_list = async (search_keyword) => {
       // var params = { key: search_keyword, locationId: -1 };
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await quick_search_all_provider(search_keyword, token);
    }
    // get the quick search location
    get_location_quick_search_data_list = async (search_keyword) => {
        // var params = { key: search_keyword, locationId: -1 };
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await quick_search_location(search_keyword, token);
    }
    prepare_suggestion_provider = (data) => {
       return this.prepare_suggestion(data);
    }
    prepare_suggestion_location = (data) => {
       return this.prepare_suggestion(data,true);
    }
    prepare_suggestion = (_data,is_for_location=false) => {
        var formattedList = [];
        var data_length = 0;
        if (_data.data) {
            data_length = _data.data.length;
            _data = _data.data;
        }
        else {
            data_length = _data.length;
        }
        if (_data && data_length) {
            for (var i = 0; i < data_length; i++) {
                var item = _data[i];
                formattedList.push({
                    "title": `${item.id}`,
                    "code": item.code,
                    "name": is_for_location? item.name:item.description,

                    "label": (item.code) + " - " + (is_for_location ? item.name : item.description),

                });
            }
        }
        return formattedList;
    }
    on_item_selection_provider = (data) => {
        this.on_item_selection(data);
    }
    on_item_selection_location = (data) => {
        this.on_item_selection(data, true);
    }
    on_item_selection = (item,is_for_location=false) => {
        var patient_data = this.state.patient;
        if (is_for_location) {
            patient_data.place_of_service_id = item.title;}
        else {
            patient_data.requested_provider_id = item.title;
        }
        if (this._is_mounted) {
            this.setState({
                patient: patient_data
            })
        }
    }
    clear_quick_search = () => {
        var patient_data = this.state.patient;
        patient_data.requested_provider_id = '';
        if (this._is_mounted) {
            this.setState({
                provider_quick_search_format: {
                    "title": '',
                    "label": '',
                    "code": '',
                    "name": ''
                },
                patient: patient_data
            });
        }
    }
    clear_quick_search_location = () => {
        var patient_invalid = this.state.patient_form_invalid; 
        var patient_data = this.state.patient;
            patient_invalid.patient.place_of_service = true;
       
        patient_data.place_of_service_id = '';
        if (this._is_mounted) {
            this.setState({
                pos_quick_search_format: {
                    "title": '',
                    "label": '',
                    "code": '',
                    "name": ''
                },
                patient: patient_data,
                patient_form_invalid: patient_invalid
            });
        }
    }
    render_suggestion_result = (props) => {
        return (
            <div key={props.title} tabIndex={0} className="item_auto_search">
                <div key={props.title} className="fs_13">
                    <span>{props.label}</span>
                </div>
            </div>

        )
    }

    on_patient_form_change_for_country = (value, e) => {
    this.on_patient_form_change(e, 'address', false, false, 'country_id', value)
}
on_patient_form_change_for_responsible_party_country = (value, e) => {
    this.on_patient_form_change(e, 'address', false, true, 'country_id', value);
}
on_patient_form_change_for_responsible_party_states = (value, e) => {
    this.on_patient_form_change(e, 'address', false, true, 'state_id', value);
}
on_patient_form_change_for_states = (value, e) => {
    this.on_patient_form_change(e, 'address', false, false, 'state_id', value);
}
on_patient_form_change_for_gender = (value, e) => {
    this.on_patient_form_change(e, null, false, false, 'gender', value);
}
on_patient_form_change_for_marital_status = (value, e) => {
    this.on_patient_form_change(e, null, false, false, 'marital_status', value);
}
on_patient_form_change_for_responsible_party_gender = (value, e) => {
    this.on_patient_form_change(e, null, false, true, 'gender', value);
}
on_patient_form_change_primary_phone = (value, e) => {
    this.on_patient_form_change(e, 'phone', false, false, 'primary', value);
}
on_patient_form_change_for_responsible_party_marital_status = (value, e) => {
    this.on_patient_form_change(e, null, false, true, 'marital_status', value);
}
on_patient_form_change_for_reminder_type = (value, e) => {
    this.on_patient_form_change(e, null, false, false, 'reminder_type', value);
}
on_patient_form_change_for_languages = (value, e) => {
    this.on_patient_form_change(e, null, false, false, 'spoken_language', value);
}
on_patient_form_change_for_phone = (e) => {
    this.on_patient_form_change(e, 'phone', false, false, null, null)
}
on_patient_form_change_for_responsible_party_phone = (e) => {
    this.on_patient_form_change(e, 'phone', false, true, null, null)
}
on_patient_form_change_for_home_phone = (e) => {
    this.on_patient_form_change(e, 'phone', true, false, null, null)
}
on_patient_form_change_for_home_ssn = (e) => {
    this.on_patient_form_change(e, null, false, true, null, null)
}
on_patient_form_change_for_email = (e) => {
    this.on_patient_form_change(e, null, false, false, null, null)
}
 on_blur_auto_search_location = () => {
        var patient_invalid = this.state.patient_form_invalid;
        var elem_location_value = document.getElementById('auto_location')['value'];
       
            if (elem_location_value == "") {
                patient_invalid.patient.place_of_service = true;
            }
            else {
                patient_invalid.patient.place_of_service = false;
            }
            if (this._is_mounted) {
                this.setState({
                    patient_form_invalid: patient_invalid
                });
            }
        
    }

    //
    render() {
        //const email_required = local_storage.get("auth_data").email_required || false;
        return (
            <React.Fragment>
                <Dimmer active={this.state.loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <div className='common-forms-add  common-forms-add-flex'>
                    <Header as='h3' dividing textAlign='left' id='applicationPageTitleSticky'>
                        {this.state.is_edit_mode ? 'Edit Patient' : 'Add Patient'}
                    </Header>
                    <div className={this.state.is_edit_mode ? 'auto-height-patient-scroll' : 'auto-height-scroll'} id='scrollable-content-area'>
                        <Form className='patient-add-form' autoComplete='off'>
                            <Grid>
                                <Grid.Column className='mar-b-20' mobile={16} tablet={16} computer={8}>
                                    <Header as='h4' className='mar-t-15'>
                                        Patient Information
                  </Header>
                                    <Grid>
                                        <Grid.Column mobile={8} tablet={8} computer={7}>
                                            <Form.Field>
                                                <label>
                                                    First Name <span className={this.state.patient_form_invalid.patient.name.first_name && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                </label>
                                                <Form.Input
                                                    fluid
                                                    autoFocus
                                                    autoComplete='new-password'
                                                    maxLength={addPatientInitialConfiguration.nameMaxLength}
                                                    name='txt_first_name'
                                                    value={this.state.patient.name.first_name || ''}
                                                    onChange={e => this.on_patient_form_change(e, 'name', false, false, null, null)}
                                                    className={this.state.patient_form_invalid.patient.name.first_name && this.state.form_submited ? 'req-border-inp' : ''}
                                                    error={this.state.patient_form_invalid.patient.name.first_name && this.state.form_submited}
                                                />
                                                <div id='patient_first_name_invalid_text' className='requiredText'>
                                                    {this.state.patient_form_invalid.patient.name.first_name && this.state.patient.name.first_name && this.state.form_submited ? this._patient_first_name_error_message : ''}
                                                </div>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={2}>
                                            <Form.Field>
                                                <label>MI</label>
                                                <Form.Input
                                                    fluid
                                                    autoComplete='new-password'
                                                    maxLength={1}
                                                    name='txt_middle_initial'
                                                    value={this.state.patient.name.middle_initial ? this.state.patient.name.middle_initial.trim() : ''}
                                                    onChange={e => this.on_patient_form_change(e, 'name', false, false, null, null)}
                                                    className={this.state.patient_form_invalid.patient.name.middle_initial && this.state.patient.name.middle_initial && this.state.form_submited ? 'req-border-inp' : ''}
                                                    error={this.state.patient_form_invalid.patient.name.middle_initial && this.state.form_submited}
                                                />
                                                <div id='patient_middle_initial_invalid_text' className='requiredText'>
                                                    {this.state.patient_form_invalid.patient.name.middle_initial && this.state.patient.name.middle_initial && this.state.form_submited ? global_constants.constants.middle_initial_invalid_message : ''}
                                                </div>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={7}>
                                            <Form.Field>
                                                <label>
                                                    Last Name <span className={this.state.patient_form_invalid.patient.name.last_name && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                </label>
                                                <Form.Input
                                                    fluid
                                                    autoComplete='new-password'
                                                    maxLength={addPatientInitialConfiguration.nameMaxLength}
                                                    name='txt_last_name'
                                                    value={this.state.patient.name.last_name || ''}
                                                    onChange={e => this.on_patient_form_change(e, 'name', false, false, null, null)}
                                                    className={this.state.patient_form_invalid.patient.name.last_name && this.state.form_submited ? 'req-border-inp' : ''}
                                                    error={this.state.patient_form_invalid.patient.name.last_name && this.state.form_submited}
                                                />
                                                <div id='patient_last_name_invalid_text' className='requiredText'>
                                                    {this.state.patient_form_invalid.patient.name.last_name && this.state.patient.name.last_name && this.state.form_submited ? this._patient_last_name_error_message : ''}
                                                </div>
                                            </Form.Field>
                                        </Grid.Column>

                                    </Grid>
                                    <Grid>
                                        <Grid.Column mobile={8} tablet={8} computer={3}>
                                            <Form.Field>
                                                <label>Suffix</label>
                                                <Form.Input
                                                    fluid
                                                    autoComplete='new-password'
                                                    maxLength={3}
                                                    name='txt_suffix'
                                                    value={this.state.patient.name.suffix || ''}
                                                    onChange={e => this.on_patient_form_change(e, 'name', false, false, null, null)}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={6}>
                                            <Form.Field>
                                                <label>Preferred Name</label>
                                                <Form.Input
                                                    autoComplete='new-password'
                                                    maxLength={20}
                                                    name='txt_preferred_name'
                                                    value={this.state.patient.name.preferred_name || ''}
                                                    onChange={e => this.on_patient_form_change(e, 'name', false, false, null, null)}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        {
                                            !this.state.is_edit_mode &&
                                            <Grid.Column mobile={8} tablet={8} computer={7}>
                                                <Form.Field className={this.state.patient_form_invalid.patient.place_of_service && this.state.form_submited ? "advance-quick-search case-reg-form requiredWithBgColor" : ' advance-quick-search case-reg-form'}>
                                                    <label>Location<span className={this.state.patient_form_invalid.patient.place_of_service && this.state.form_submited ? "req-alert" : 'req-alert_normal'}> (required)</span> </label>
                                                    <AdvancedControl
                                                        disabled_element={false}
                                                        onGridRowSelection={this.on_place_of_service_grid_row_selection}
                                                        gridConfig={this.state.pos_search_data}
                                                        controlId={global_constants.constants.advanced_control_type.location}
                                                        onSearch={this.on_pos_search}
                                                        search_type={'LOCATION'}
                                                        headerIdForGridTabNavigation={'location'}
                                                    />
                                                    <AutoSearchComponent
                                                        default_value={this.state.pos_quick_search_format}
                                                        on_blur={ this.on_blur_auto_search_location}
                                                        errorMessage={'No Location Found !'}
                                                        prepareRenderList={this.render_suggestion_result}
                                                        getList={this.get_location_quick_search_data_list}
                                                        prepareDataList={this.prepare_suggestion_location}
                                                        selectresult={this.on_item_selection_location} is_focus={false}
                                                        is_disabled={false} show_clear_search={true} clear_search={this.clear_quick_search_location}
                                                        errorClass={this.state.patient_form_invalid.patient.place_of_service && this.state.form_submited ? 'req-background-inp' : '' }
                                                        control_id={'auto_location'}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        }
                                    </Grid>
                                    <Grid>
                                        <Grid.Column>
                                            <Form.Field className='required-for-billing-span'>
                                                <label>
                                                    Address 1 <span className={this.state.patient_form_invalid.patient.address.address1 && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                </label>
                                                <Form.Input
                                                    autoComplete='new-password'
                                                    maxLength={50}
                                                    name='txt_address1'
                                                    value={this.state.patient.address.address1 || ''}
                                                    onChange={e => this.on_patient_form_change(e, 'address', false, false, null, null)}
                                                    error={this.state.patient_form_invalid.patient.address.address1 && this.state.form_submited}
                                                />

                                            </Form.Field>
                                            <p className={this.state.patient_form_invalid.patient.address.address1 && this.state.form_submited ? "required-place-dropdown req-alert" : 'required-place-dropdown'}><i>required for billing</i></p>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid>
                                        <Grid.Column>
                                            <Form.Field>
                                                <label>Address 2</label>
                                                <Form.Input
                                                    autoComplete='new-password'
                                                    maxLength={50}
                                                    name='txt_address2'
                                                    value={this.state.patient.address.address2 || ''}
                                                    onChange={e => this.on_patient_form_change(e, 'address', false, false, null, null)}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid>
                                        <Grid.Column mobile={8} tablet={8} computer={8}>
                                            <Form.Field className='required-for-billing-span'>
                                                <label>
                                                    City <span className={this.state.patient_form_invalid.patient.address.city && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                </label>
                                                <Form.Input
                                                    autoComplete='new-password'
                                                    maxLength={30}
                                                    name='txt_city'
                                                    value={this.state.patient.address.city || ''}
                                                    onChange={e => this.on_patient_form_change(e, 'address', false, false, null, null)}
                                                    error={this.state.patient_form_invalid.patient.address.city && this.state.form_submited}
                                                />
                                            </Form.Field>
                                            <p className={this.state.patient_form_invalid.patient.address.city && this.state.form_submited ? "required-place-dropdown req-alert" : 'required-place-dropdown'}><i>required for billing</i></p>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>
                                                    Country <span className={this.state.patient_form_invalid.patient.address.country_id && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                </label>
                                                {
                                                    <Selection
                                                        name='country'
                                                        id='patient_country'
                                                        //placeHolder='Select'
                                                        options={this.state.patient_master_data.country}
                                                        onChange={this.on_patient_form_change_for_country}
                                                        defaultValue={this.state.patient.address.country_id || ''}
                                                        style={this.state.patient_form_invalid.patient.address.country_id && this.state.form_submited ? 'red-error-thin' : ''}
                                                    //isRequired={this.state.error.state ? true : false}
                                                    //disabled={this.state.page_state === enum_case_page_state.edit}
                                                    />

                                                    //<Dropdown placeholder='Select' fluid selection options={this.state.patient_master_data.country} value={this.state.patient.address.country_id || ''}
                                                    //    onChange={(e, { value }) => { this.on_patient_form_change(e, 'address', false, false, 'country_id', value) }}
                                                    //    className={this.state.patient_form_invalid.patient.address.country_id && this.state.form_submited ? "red-error-thin" : ''}
                                                    ///>
                                                }
                                                <p className={this.state.patient_form_invalid.patient.address.country_id && this.state.form_submited ? "required-for-billing-place-dropdown req-alert" : 'required-for-billing-place-dropdown'}><i>required for billing</i></p>
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid>
                                        <Grid.Column style={this.state.is_us_selected ? { display: 'none' } : { display: 'block' }} mobile={8} tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>Province </label>
                                                <Form.Input
                                                    autoComplete='new-password'
                                                    pattern='/^[a-zA-Z0-9 ]/'
                                                    maxLength={20}
                                                    name='txt_province'
                                                    value={this.state.patient.address.province || ''}
                                                    onChange={e => this.on_patient_form_change(e, 'address', false, false, null, null)}
                                                    error={this.state.patient_form_invalid.patient.address.province && this.state.form_submited}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column style={this.state.is_us_selected ? { display: 'none' } : { display: 'block' }} mobile={8} tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>Postal Code </label>
                                                <Form.Input
                                                    autoComplete='new-password'
                                                    pattern='/^[a-zA-Z0-9 ]/'
                                                    maxLength={20}
                                                    name='txt_postal_code'
                                                    value={this.state.patient.address.postal_code || ''}
                                                    onChange={e => this.on_patient_form_change(e, 'address', false, false, null, null)}
                                                    error={this.state.patient_form_invalid.patient.address.postal_code && this.state.form_submited}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid>
                                        <Grid.Column style={this.state.is_us_selected ? { display: 'block' } : { display: 'none' }} mobile={8} tablet={8} computer={8}>
                                            <Form.Field className="required-for-billing-span">
                                                <label>
                                                    State <span className={this.state.patient_form_invalid.patient.address.state_id && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                </label>
                                                {
                                                    <Selection
                                                        name='states'
                                                        id='patient_states'
                                                        placeHolder='Select'
                                                        options={this.state.patient_master_data.states}
                                                        onChange={this.on_patient_form_change_for_states}
                                                        defaultValue={this.state.patient.address.state_id || ''}
                                                        style={this.state.patient_form_invalid.patient.address.state_id && this.state.form_submited ? 'red-error-thin' : ''}
                                                        hidden={true}
                                                    //isRequired={this.state.error.state ? true : false}
                                                    //disabled={this.state.page_state === enum_case_page_state.edit}
                                                    />
                                                    //<Dropdown placeholder='Select' fluid selection options={this.state.patient_master_data.states} value={this.state.patient.address.state_id}
                                                    //    onChange={(e, { value }) => { this.on_patient_form_change(e, 'address', false, false, 'state_id', value) }} className={this.state.patient_form_invalid.patient.address.state_id && this.state.form_submited ? 'red-error-thin' : ''} />
                                                }
                                                <p className={this.state.patient_form_invalid.patient.address.state_id && this.state.form_submited ? "required-for-billing-place-dropdown req-alert" : 'required-for-billing-place-dropdown'}><i>required for billing</i></p>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column style={this.state.is_us_selected ? { display: 'block' } : { display: 'none' }} mobile={8} tablet={8} computer={8}>
                                            <Form.Field className="required-for-billing-span">
                                                <label>
                                                    ZIP Code <span className={this.state.patient_form_invalid.patient.address.zip && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                </label>
                                                <Form.Input
                                                    autoComplete='new-password'
                                                    maxLength={10}
                                                    pattern='[0-9]*'
                                                    name='txt_zip'
                                                    value={zip_code_formation(this.state.patient.address.unformat_zip) || ''}
                                                    onChange={e => this.on_patient_form_change(e, 'address', false, false, null, null)}
                                                    error={this.state.patient_form_invalid.patient.address.zip && this.state.form_submited}
                                                />
                                                <p className={this.state.patient_form_invalid.patient.address.zip && this.state.form_submited ? "required-place-bottom req-alert" : 'required-place-bottom'}><i>{this.state.patient_form_invalid.patient.address.zip && this.state.form_submited
                                                    && this.state.patient.address.unformat_zip && this.state.patient.address.unformat_zip != null && this.state.patient.address.unformat_zip.length > 0
                                                    && (this.state.patient.address.unformat_zip.length != 5 || this.state.patient.address.unformat_zip.length != 9)
                                                    ? global_constants.constants.zip_compulsory : 'required for billing'}
                                                </i></p>
                                                {
                                                    //<div className='requiredText'>
                                                    //    {this.state.patient_form_invalid.patient.address.zip &&
                                                    //        this.state.form_submited &&
                                                    //        this.state.patient.address.unformat_zip &&
                                                    //        this.state.patient.address.unformat_zip.length > 0 &&
                                                    //        (this.state.patient.address.unformat_zip.length != 5 || this.state.patient.address.unformat_zip.length != 9)
                                                    //        ? global_constants.constants.zip_compulsory
                                                    //        : ''}
                                                    //</div>
                                                }
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Header as='h5' className='small form_section_heading'>
                                        Phone{' '}
                                        <i className='italic-gray'>
                                            <p>(entry of at least one phone number required)</p>
                                        </i>
                                    </Header>
                                    <Grid>
                                        <Grid.Column mobile={8} tablet={8} computer={5}>
                                            <Form.Field>
                                                <label>Home</label>
                                                <MaskedInput
                                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    className={this.state.patient_form_invalid.patient.phone.home && this.state.form_submited ? 'req-background-inp' : ''}
                                                    placeholder='(___) ___-____'
                                                    guide={true}
                                                    id='patient-home-phone'
                                                    name='home'
                                                    autoComplete='new-password'
                                                    showMask={true}
                                                    onChange={this.on_patient_form_change_for_phone}
                                                    value={this.state.patient.phone.home || ''}
                                                />
                                                <div className='requiredText'>
                                                    {this.state.patient_form_invalid.patient.phone.home &&
                                                        this.state.form_submited &&
                                                        this.state.patient.phone.home &&
                                                        this.state.patient.phone.home.length != 10 &&
                                                        this.state.patient.phone.home.length > 0
                                                        ? global_constants.constants.ten_digits_compulsory
                                                        : ''}
                                                </div>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={6}>
                                            <Form.Field>
                                                <label>Cell</label>
                                                <MaskedInput
                                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    className={this.state.patient_form_invalid.patient.phone.cell && this.state.form_submited ? 'req-background-inp' : ''}
                                                    placeholder='(___) ___-____'
                                                    guide={true}
                                                    name='cell'
                                                    id='patient-cell-phone'
                                                    onChange={this.on_patient_form_change_for_phone}
                                                    autoComplete='new-password'
                                                    value={this.state.patient.phone.cell || ''}
                                                    showMask={true}
                                                />
                                                <div className='requiredText'>
                                                    {this.state.patient_form_invalid.patient.phone.cell &&
                                                        this.state.form_submited &&
                                                        this.state.patient.phone.cell &&
                                                        this.state.patient.phone.cell.length != 10 &&
                                                        this.state.patient.phone.cell.length > 0
                                                        ? global_constants.constants.ten_digits_compulsory
                                                        : ''}
                                                </div>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={5}>
                                            <Form.Field>
                                                <label>Work</label>
                                                <MaskedInput
                                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    className={this.state.patient_form_invalid.patient.phone.work && this.state.form_submited ? 'req-background-inp' : ''}
                                                    placeholder='(___) ___-____'
                                                    guide={true}
                                                    name='work'
                                                    id='patient-work-phone'
                                                    onChange={this.on_patient_form_change_for_phone}
                                                    autoComplete='new-password'
                                                    value={this.state.patient.phone.work || ''}
                                                    showMask={true}
                                                />
                                                <div className='requiredText'>
                                                    {this.state.patient_form_invalid.patient.phone.work &&
                                                        this.state.form_submited &&
                                                        this.state.patient.phone.work &&
                                                        this.state.patient.phone.work.length != 10 &&
                                                        this.state.patient.phone.work.length > 0
                                                        ? global_constants.constants.ten_digits_compulsory
                                                        : ''}
                                                </div>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={5}>
                                            <Form.Field>
                                                <label>Primary Phone</label>
                                                {
                                                    <Selection
                                                        name='primary_phone'
                                                        id='patient_primary_phone'
                                                        //placeHolder='Select'
                                                        options={this.state.patient_master_data.primary_phone}
                                                        onChange={this.on_patient_form_change_primary_phone}
                                                        defaultValue={this.state.patient.phone.primary || ''}
                                                    //style={this.state.patient_form_invalid.patient.address.state_id && this.state.form_submited ? 'red-error-thin' : ''}
                                                    //isRequired={this.state.error.state ? true : false}
                                                    //disabled={this.state.page_state === enum_case_page_state.edit}
                                                    />
                                                    //<Dropdown placeholder='Select' fluid selection options={this.state.patient_master_data.primary_phone} value={this.state.patient.phone.primary}
                                                    //    onChange={(e, { value }) => { this.on_patient_form_change(e, 'phone', false, false, 'primary', value) }} />
                                                }
                                                {/* <Form.Input autoComplete="new-password"  name='txt_primary' value={this.state.patient.phone.primary} onChange={(e) => this.on_patient_form_change(e, 'phone', false, false)} error={this.state.patient_form_invalid.patient.phone.primary} /> */}
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Header as='h5' className='small form_section_heading'>
                                        Other Contact Information
                  </Header>
                                    <Grid>
                                        <Grid.Column mobile={8} tablet={8} computer={10}>
                                            <Form.Field>
                                                <label>Email {this.state.email_required ? <span className={this.state.patient_form_invalid.patient.email && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span> : ''}</label>
                                                <Form.Input
                                                    maxLength={50}
                                                    autoComplete='new-password'
                                                    name='txt_email'
                                                    value={this.state.patient.email || ''}
                                                    onChange={this.on_patient_form_change_for_email}
                                                    className={this.state.email_required && this.state.patient_form_invalid.patient.email && this.state.form_submited ? 'req-border-inp' : ''}
                                                    error={this.state.patient_form_invalid.patient.email && this.state.form_submited}
                                                />
                                                <div className='requiredText'>
                                                    {this.state.patient_form_invalid.patient.email && this.state.patient.email && this.state.form_submited ? global_constants.constants.valid_email : ''}
                                                </div>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={6}>
                                            <Form.Field>
                                                <label>Reminder Type</label>
                                                {
                                                    <Selection
                                                        name='reminder_type'
                                                        id='patient_reminder_type'
                                                        //placeHolder='Select'
                                                        options={this.state.patient_master_data.reminder_type}
                                                        onChange={this.on_patient_form_change_for_reminder_type}
                                                        defaultValue={this.state.patient.reminder_type || ''}
                                                    //style={this.state.patient_form_invalid.patient.address.state_id && this.state.form_submited ? 'red-error-thin' : ''}
                                                    //isRequired={this.state.error.state ? true : false}
                                                    //disabled={this.state.page_state === enum_case_page_state.edit}
                                                    />
                                                    //<Dropdown placeholder='Select' fluid selection options={this.state.patient_master_data.reminder_type} value={this.state.patient.reminder_type}
                                                    //    onChange={(e, { value }) => { this.on_patient_form_change(e, null, false, false, 'reminder_type', value) }} />
                                                }
                                                {/* <Form.Input autoComplete="new-password"  name='txt_reminder_type' value={this.state.patient.reminder_type} onChange={(e) => this.on_patient_form_change(e, null, false, false)} error={this.state.patient_form_invalid.patient.reminder_type} /> */}
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Header as='h5' className='small form_section_heading'>
                                        Other Patient Information
                  </Header>
                                    <Grid>
                                        <Grid.Column mobile={8} tablet={8} computer={8}>
                                            <div>
                                                <Form.Field className={`${this.required_date()}`}>
                                                    <label>
                                                        Date of Birth <span className={this.state.patient_form_invalid.patient.date_of_birth && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                    </label>

                                                    <DatePicker
                                                        maxDate={moment().format('MM/DD/YYYY')}
                                                        date_update={this.handleCalendarChange}
                                                        date={this.state.patient.formatted_date_of_birth}
                                                        is_required={this.state.patient_form_invalid.patient.date_of_birth && this.state.form_submited}
                                                    />
                                                </Form.Field>
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>
                                                    Gender <span className={this.state.patient_form_invalid.patient.gender && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                </label>
                                                {
                                                    <Selection
                                                        name='gender'
                                                        id='patient_gender'
                                                        placeHolder='Select'
                                                        options={this.state.patient_master_data.gender}
                                                        onChange={this.on_patient_form_change_for_gender}
                                                        defaultValue={this.state.patient.gender || ''}
                                                        style={this.state.patient_form_invalid.patient.gender && this.state.form_submited ? 'req-background-inp' : ''}
                                                        hidden={true}
                                                    />
                                                }
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>
                                                    SSN <span className={this.state.patient_form_invalid.patient.ssn && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                </label>
                                                <MaskedInput
                                                    mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    className={this.state.patient_form_invalid.patient.ssn && this.state.form_submited ? 'red-error-thin' : ''}
                                                    placeholder='___-__-____'
                                                    guide={true}
                                                    id='patient-ssn'
                                                    autoComplete='new-password'
                                                    name='ssn'
                                                    onChange={this.on_patient_form_change_for_email}
                                                    value={this.state.patient.ssn || ''}
                                                    showMask={true}
                                                />

                                                <p className={this.state.patient_form_invalid.patient.ssn && this.state.form_submited ? "required-for-billing-ssn-place-bottom req-alert" : 'required-for-billing-ssn-place-bottom'}><i>{this.state.patient_form_invalid.patient.ssn && this.state.form_submited
                                                    && this.state.patient.ssn && this.state.patient.ssn.length != 9 && this.state.patient.ssn.length > 0
                                                    ? global_constants.constants.ssn_compulsory : 'required for billing'}
                                                </i></p>
                                                {
                                                    //<div className='requiredText'>
                                                    //    {this.state.patient_form_invalid.patient.ssn &&
                                                    //        this.state.form_submited &&
                                                    //        this.state.patient.ssn &&
                                                    //        this.state.patient.ssn.length != 9 &&
                                                    //        this.state.patient.ssn.length > 0
                                                    //        ? global_constants.constants.ssn_compulsory
                                                    //        : ''}
                                                    //</div>
                                                }
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column mobile={8} tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>Marital Status</label>
                                                {
                                                    <Selection
                                                        name='marital_status'
                                                        id='patient_marital_status'
                                                        placeHolder='Select'
                                                        options={this.state.patient_master_data.marital_status}
                                                        onChange={this.on_patient_form_change_for_marital_status}
                                                        defaultValue={this.state.patient.marital_status || ''}
                                                        hidden={true}
                                                    />
                                                }
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>Driver's License</label>
                                                <Form.Input
                                                    autoComplete='new-password'
                                                    maxLength={50}
                                                    name='txt_driver_license'
                                                    value={this.state.patient.driver_license || ''}
                                                    onChange={e => this.on_patient_form_change(e, null, false, false, null, null)}
                                                    error={this.state.patient_form_invalid.patient.driver_license}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>Spoken Language</label>
                                                {
                                                    <Selection
                                                        name='languages'
                                                        id='patient_languages'
                                                        placeHolder='Select'
                                                        options={this.state.patient_master_data.languages}
                                                        onChange={this.on_patient_form_change_for_languages}
                                                        defaultValue={this.state.patient.spoken_language || ''}
                                                        hidden={true}
                                                    />
                                                }
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={8}>
                                            <Form.Field className="advance-quick-search case-reg-form billing-provider">
                                                <label>Requested Provider</label>
                                                <AdvancedControl
                                                    disabled_element={false}
                                                    onGridRowSelection={this.on_provider_grid_row_selection}
                                                    gridConfig={this.state.provider_search_data}
                                                    controlId={global_constants.constants.advanced_control_type.provider}
                                                    onSearch={this.on_provider_search}
                                                    search_type={'BILLING_PROVIDER'}
                                                    headerIdForGridTabNavigation={'provider'}
                                                />
                                                <AutoSearchComponent
                                                    default_value={this.state.provider_quick_search_format}
                                                    errorMessage={'No Requested Provider Found !'}
                                                    prepareRenderList={this.render_suggestion_result}
                                                    getList={this.get_provider_quick_search_data_list}
                                                    prepareDataList={this.prepare_suggestion_provider}
                                                    selectresult={this.on_item_selection_provider}
                                                    is_focus={false}
                                                    is_disabled={false}
                                                    show_clear_search={true} clear_search={this.clear_quick_search}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Header as='h5' className='small form_section_heading'>
                                        Internal Information
                  </Header>
                                    <Grid>
                                        <Grid.Column computer={16}>
                                            <Form.Field>
                                                <label>Comment</label>
                                                <TextareaAutosize
                                                    style={{ height: 60 }}
                                                    name='txt_patient_notes'
                                                    autoComplete='new-password'
                                                    maxLength={250}
                                                    value={this.state.patient.patient_notes || ''}
                                                    onChange={e => this.on_patient_form_change(e, null, false, false, null, null)}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid>
                                        <Grid.Column computer={16}>
                                            <Form.Field>
                                                <label>Alternate Patient ID</label>
                                                <Form.Input
                                                    autoComplete='new-password'
                                                    maxLength={50}
                                                    name='txt_alt_record_id'
                                                    value={this.state.patient.alt_record_id || ''}
                                                    onChange={e => this.on_patient_form_change(e, null, false, false, null, null)}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                                <Grid.Column className='mar-b-20' mobile={16} tablet={16} computer={8}>
                                    <Header as='h4' className='mar-t-15'>
                                        Emergency Contact
                                    </Header>
                                    <Grid>
                                        <Grid.Column mobile={8} tablet={8} computer={7}>
                                            <Form.Field>
                                                <label>First Name</label>
                                                <Form.Input
                                                    autoComplete='new-password'
                                                    maxLength={50}
                                                    name='txt_first_name'
                                                    value={this.state.patient.emergency_contact.name.first_name || ''}
                                                    onChange={e => this.on_patient_form_change(e, 'name', true, false, null, null)}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={2}>
                                            <Form.Field>
                                                <label>MI</label>
                                                <Form.Input
                                                    fluid
                                                    autoComplete='new-password'
                                                    maxLength={1}
                                                    name='txt_middle_initial'
                                                    value={this.state.patient.emergency_contact.name.middle_initial ? this.state.patient.emergency_contact.name.middle_initial.trim() : ''}
                                                    onChange={e => this.on_patient_form_change(e, 'name', true, false, null, null)}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={7}>
                                            <Form.Field>
                                                <label>Last Name</label>
                                                <Form.Input
                                                    autoComplete='new-password'
                                                    maxLength={50}
                                                    name='txt_last_name'
                                                    value={this.state.patient.emergency_contact.name.last_name || ''}
                                                    onChange={e => this.on_patient_form_change(e, 'name', true, false, null, null)}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Header as='h5' className='small form_section_heading'>
                                        Phone
                                    </Header>
                                    <Grid>
                                        <Grid.Column mobile={8} tablet={8} computer={6}>
                                            <Form.Field>
                                                <label>Home</label>
                                                <MaskedInput
                                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    className={this.state.patient_form_invalid.patient.emergency_contact.phone.home && this.state.form_submited ? 'red-error-thin' : ''}
                                                    placeholder='(___) ___-____'
                                                    guide={true}
                                                    id='emergency_contact-home-phone'
                                                    name='home'
                                                    autoComplete='new-password'
                                                    onChange={this.on_patient_form_change_for_home_phone}
                                                    value={this.state.patient.emergency_contact.phone.home || ''}
                                                    showMask={true}
                                                />
                                                <div className='requiredText'>
                                                    {this.state.patient_form_invalid.patient.emergency_contact.phone.home &&
                                                        this.state.form_submited &&
                                                        this.state.patient.emergency_contact.phone.home &&
                                                        this.state.patient.emergency_contact.phone.home.length != 10 &&
                                                        this.state.patient.emergency_contact.phone.home.length > 0
                                                        ? global_constants.constants.ten_digits_compulsory
                                                        : ''}
                                                </div>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Cell</label>
                                                <MaskedInput
                                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    className={this.state.patient_form_invalid.patient.emergency_contact.phone.cell && this.state.form_submited ? 'red-error-thin' : ''}
                                                    placeholder='(___) ___-____'
                                                    guide={true}
                                                    name='cell'
                                                    id='emergency_contact-cell-phone'
                                                    onChange={this.on_patient_form_change_for_home_phone}
                                                    autoComplete='new-password'
                                                    value={this.state.patient.emergency_contact.phone.cell || ''}
                                                    showMask={true}
                                                />

                                                <div className='requiredText'>
                                                    {this.state.patient_form_invalid.patient.emergency_contact.phone.cell &&
                                                        this.state.form_submited &&
                                                        this.state.patient.emergency_contact.phone.cell &&
                                                        this.state.patient.emergency_contact.phone.cell.length != 10 &&
                                                        this.state.patient.emergency_contact.phone.cell.length > 0
                                                        ? global_constants.constants.ten_digits_compulsory
                                                        : ''}
                                                </div>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={6}>
                                            <Form.Field>
                                                <label>Work</label>
                                                <MaskedInput
                                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    className={this.state.patient_form_invalid.patient.emergency_contact.phone.work && this.state.form_submited ? 'red-error-thin' : ''}
                                                    placeholder='(___) ___-____'
                                                    guide={true}
                                                    name='work'
                                                    id='emergency_contact-work-phone'
                                                    onChange={this.on_patient_form_change_for_home_phone}
                                                    autoComplete='new-password'
                                                    value={this.state.patient.emergency_contact.phone.work || ''}
                                                    showMask={true}
                                                />

                                                <div className='requiredText'>
                                                    {this.state.patient_form_invalid.patient.emergency_contact.phone.work &&
                                                        this.state.form_submited &&
                                                        this.state.patient.emergency_contact.phone.work &&
                                                        this.state.patient.emergency_contact.phone.work.length != 10 &&
                                                        this.state.patient.emergency_contact.phone.work.length > 0
                                                        ? global_constants.constants.ten_digits_compulsory
                                                        : ''}
                                                </div>
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Header as='h4' className='mar-t-15'>
                                        Responsible Party
                                    </Header>
                                    <div className='field'>
                                        <div className={this.state.patient.responsible_party.same_as_patient ? 'ui checked checkbox ' : 'ui checkbox '}>
                                            <input
                                                className='hidden'
                                                id='txt_same_as_patient'
                                                tabIndex={0}
                                                onKeyDown={handle_click_on_enter}
                                                type='checkbox'
                                                checked={this.state.patient.responsible_party.same_as_patient}
                                                onChange={e => this.copy_data(e)}
                                            />
                                            <label htmlFor='txt_same_as_patient'>Same as Patient </label>
                                        </div>
                                    </div>
                                    <Grid>
                                        <Grid.Column mobile={8} tablet={8} computer={7}>
                                            <Form.Field className='required-for-billing-span'>
                                                <label>
                                                    First Name <span className={this.state.patient_form_invalid.patient.responsible_party.name.first_name && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                </label>
                                                <Form.Input
                                                    autoComplete='new-password'
                                                    maxLength={20}
                                                    name='txt_first_name'
                                                    value={this.state.patient.responsible_party.name.first_name || ''}
                                                    onChange={e => this.on_patient_form_change(e, 'name', false, true, null, null)}
                                                    error={this.state.patient_form_invalid.patient.responsible_party.name.first_name && this.state.form_submited}
                                                />

                                            </Form.Field>
                                            <p className={this.state.patient_form_invalid.patient.responsible_party.name.first_name && this.state.form_submited ? "required-place-dropdown req-alert" : 'required-place-dropdown'}><i>required for billing</i></p>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={2}>
                                            <Form.Field>
                                                <label>MI</label>
                                                <Form.Input
                                                    fluid
                                                    autoComplete='new-password'
                                                    maxLength={1}
                                                    name='txt_middle_initial'
                                                    value={this.state.patient.responsible_party.name.middle_initial ? this.state.patient.responsible_party.name.middle_initial.trim() : ''}
                                                    onChange={e => this.on_patient_form_change(e, 'name', false, true, null, null)}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={7}>
                                            <Form.Field className='required-for-billing-span'>
                                                <label>
                                                    Last Name <span className={this.state.patient_form_invalid.patient.responsible_party.name.last_name && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                </label>
                                                <Form.Input
                                                    autoComplete='new-password'
                                                    maxLength={20}
                                                    name='txt_last_name'
                                                    value={this.state.patient.responsible_party.name.last_name || ''}
                                                    onChange={e => this.on_patient_form_change(e, 'name', false, true, null, null)}
                                                    error={this.state.patient_form_invalid.patient.responsible_party.name.last_name && this.state.form_submited}
                                                />
                                            </Form.Field>
                                            <p className={this.state.patient_form_invalid.patient.responsible_party.name.last_name && this.state.form_submited ? "required-place-dropdown req-alert" : 'required-place-dropdown'}><i>required for billing</i></p>
                                        </Grid.Column>

                                        <Grid.Column mobile={8} tablet={8} computer={3}>
                                            <Form.Field>
                                                <label>Suffix</label>
                                                <Form.Input
                                                    autoComplete='new-password'
                                                    maxLength={3}
                                                    name='txt_suffix'
                                                    value={this.state.patient.responsible_party.name.suffix || ''}
                                                    onChange={e => this.on_patient_form_change(e, 'name', false, true, null, null)}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid>
                                        <Grid.Column>
                                            <Form.Field className='required-for-billing-span'>
                                                <label>
                                                    Address 1 <span className={this.state.patient_form_invalid.patient.responsible_party.address.address1 && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                </label>
                                                <Form.Input
                                                    autoComplete='new-password'
                                                    maxLength={50}
                                                    name='txt_address1'
                                                    value={this.state.patient.responsible_party.address.address1 || ''}
                                                    onChange={e => this.on_patient_form_change(e, 'address', false, true, null, null)}
                                                    error={this.state.patient_form_invalid.patient.responsible_party.address.address1 && this.state.form_submited}
                                                />
                                            </Form.Field>
                                            <p className={this.state.patient_form_invalid.patient.responsible_party.address.address1 && this.state.form_submited ? "required-place-dropdown req-alert" : 'required-place-dropdown'}><i>required for billing</i></p>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid>
                                        <Grid.Column>
                                            <Form.Field>
                                                <label>Address 2</label>
                                                <Form.Input
                                                    autoComplete='new-password'
                                                    maxLength={50}
                                                    name='txt_address2'
                                                    value={this.state.patient.responsible_party.address.address2 || ''}
                                                    onChange={e => this.on_patient_form_change(e, 'address', false, true, null, null)}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid>
                                        <Grid.Column mobile={8} tablet={8} computer={8}>
                                            <Form.Field className='required-for-billing-span'>
                                                <label>
                                                    City <span className={this.state.patient_form_invalid.patient.responsible_party.address.city && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                </label>
                                                <Form.Input
                                                    autoComplete='new-password'
                                                    maxLength={30}
                                                    name='txt_city'
                                                    value={this.state.patient.responsible_party.address.city || ''}
                                                    onChange={e => this.on_patient_form_change(e, 'address', false, true, null, null)}
                                                    error={this.state.patient_form_invalid.patient.responsible_party.address.city && this.state.form_submited}
                                                />
                                            </Form.Field>
                                            <p className={this.state.patient_form_invalid.patient.responsible_party.address.city && this.state.form_submited ? "required-place-dropdown req-alert" : 'required-place-dropdown'}><i>required for billing</i></p>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>
                                                    Country <span className={this.state.patient_form_invalid.patient.responsible_party.address.country_id && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                </label>
                                                {
                                                    <Selection
                                                        name='responsible_party_country'
                                                        id='patient_responsible_party_country'
                                                        //placeHolder='Select'
                                                        options={this.state.patient_master_data.country}
                                                        onChange={this.on_patient_form_change_for_responsible_party_country}
                                                        defaultValue={this.state.patient.responsible_party.address.country_id || ''}
                                                        style={this.state.patient_form_invalid.patient.responsible_party.address.country_id && this.state.form_submited ? 'red-error-thin' : ''}
                                                    //isRequired={this.state.error.state ? true : false}
                                                    //disabled={this.state.page_state === enum_case_page_state.edit}
                                                    />
                                                    //<Dropdown fluid placeholder='Select' selection options={this.state.patient_master_data.country} value={this.state.patient.responsible_party.address.country_id}
                                                    //    onChange={(e, { value }) => { this.on_patient_form_change(e, 'address', false, true, 'country_id', value) }}
                                                    //    className={this.state.patient_form_invalid.patient.responsible_party.address.country_id && this.state.form_submited ? "red-error-thin" : ''}
                                                    ///>
                                                }
                                                <p className={this.state.patient_form_invalid.patient.responsible_party.address.country_id && this.state.form_submited ? "required-for-billing-place-dropdown req-alert" : 'required-for-billing-place-dropdown'}><i>required for billing</i></p>
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid>
                                        <Grid.Column style={this.state.is_rp_us_selected ? { display: 'block' } : { display: 'none' }} mobile={8} tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>
                                                    State <span className={this.state.patient_form_invalid.patient.responsible_party.address.state_id && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                </label>
                                                {
                                                    <Selection
                                                        name='responsible_party_states'
                                                        id='patient_responsible_party_states'
                                                        placeHolder='Select'
                                                        options={this.state.patient_master_data.states}
                                                        onChange={this.on_patient_form_change_for_responsible_party_states}
                                                        defaultValue={this.state.patient.responsible_party.address.state_id || ''}
                                                        style={this.state.patient_form_invalid.patient.responsible_party.address.state_id && this.state.form_submited ? 'red-error-thin' : ''}
                                                        hidden={true}
                                                    //isRequired={this.state.error.state ? true : false}
                                                    //disabled={this.state.page_state === enum_case_page_state.edit}
                                                    />
                                                    //<Dropdown placeholder='Select' fluid selection options={this.state.patient_master_data.states} value={this.state.patient.responsible_party.address.state_id}
                                                    //    onChange={(e, { value }) => { this.on_patient_form_change(e, 'address', false, true, 'state_id', value) }} className={this.state.patient_form_invalid.patient.responsible_party.address.state_id && this.state.form_submited ? 'red-error-thin' : ''} />
                                                }
                                                <p className={this.state.patient_form_invalid.patient.responsible_party.address.state_id && this.state.form_submited ? "required-for-billing-place-dropdown req-alert" : 'required-for-billing-place-dropdown'}><i>required for billing</i></p>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column style={this.state.is_rp_us_selected ? { display: 'block' } : { display: 'none' }} mobile={8} tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>
                                                    ZIP Code <span className={this.state.patient_form_invalid.patient.responsible_party.address.zip && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                </label>
                                                <Form.Input
                                                    autoComplete='new-password'
                                                    pattern='[0-9]*'
                                                    maxLength={10}
                                                    name='txt_zip'
                                                    value={zip_code_formation(this.state.patient.responsible_party.address.unformat_zip) || ''}
                                                    onChange={e => this.on_patient_form_change(e, 'address', false, true, null, null)}
                                                    error={this.state.patient_form_invalid.patient.responsible_party.address.zip && this.state.form_submited}
                                                />
                                                <p className={this.state.patient_form_invalid.patient.responsible_party.address.zip && this.state.form_submited ? "required-place-bottom req-alert" : 'required-place-bottom'}><i>{this.state.patient_form_invalid.patient.responsible_party.address.zip && this.state.form_submited
                                                    && this.state.patient.responsible_party.address.unformat_zip &&
                                                    this.state.patient.responsible_party.address.unformat_zip.length > 0 &&
                                                    (this.state.patient.responsible_party.address.unformat_zip.length != 5 || this.state.patient.responsible_party.address.unformat_zip.length != 9)
                                                    ? global_constants.constants.zip_compulsory : 'required for billing'}
                                                </i></p>
                                                {
                                                    //<div className='requiredText'>
                                                    //    {this.state.patient_form_invalid.patient.responsible_party.address.zip &&
                                                    //        this.state.form_submited &&
                                                    //        this.state.patient.responsible_party.address.unformat_zip &&
                                                    //        this.state.patient.responsible_party.address.unformat_zip.length > 0 &&
                                                    //        (this.state.patient.responsible_party.address.unformat_zip.length != 5 || this.state.patient.responsible_party.address.unformat_zip.length != 9)
                                                    //        ? global_constants.constants.zip_compulsory
                                                    //        : ''}
                                                    //</div>
                                                }
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid>
                                        <Grid.Column style={this.state.is_rp_us_selected ? { display: 'none' } : { display: 'block' }} mobile={8} tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>Province </label>
                                                <Form.Input
                                                    autoComplete='new-password'
                                                    maxLength={20}
                                                    pattern='/^[a-zA-Z0-9 ]/'
                                                    name='txt_province'
                                                    value={this.state.patient.responsible_party.address.province || ''}
                                                    onChange={e => this.on_patient_form_change(e, 'address', false, true, null, null)}
                                                    error={this.state.patient_form_invalid.patient.responsible_party.address.province && this.state.form_submited}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column style={this.state.is_rp_us_selected ? { display: 'none' } : { display: 'block' }} mobile={8} tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>Postal Code </label>
                                                <Form.Input
                                                    autoComplete='new-password'
                                                    pattern='/^[a-zA-Z0-9 ]/'
                                                    maxLength={20}
                                                    name='txt_postal_code'
                                                    value={this.state.patient.responsible_party.address.postal_code || ''}
                                                    onChange={e => this.on_patient_form_change(e, 'address', false, true, null, null)}
                                                    error={this.state.patient_form_invalid.patient.responsible_party.address.postal_code && this.state.form_submited}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid>
                                        <Grid.Column mobile={8} tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>
                                                    Phone <span className={this.state.patient_form_invalid.patient.responsible_party.phone.cell && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                </label>
                                                <MaskedInput
                                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    className={this.state.patient_form_invalid.patient.responsible_party.phone.cell && this.state.form_submited ? 'red-error-thin' : ''}
                                                    placeholder='(___) ___-____'
                                                    guide={true}
                                                    name='cell'
                                                    id='responsible_party-cell-phone'
                                                    onChange={this.on_patient_form_change_for_responsible_party_phone}
                                                    autoComplete='new-password'
                                                    value={this.state.patient.responsible_party.phone.cell}
                                                    showMask={true}
                                                />
                                                <p className={this.state.patient_form_invalid.patient.responsible_party.phone.cell && this.state.form_submited ? "required-for-billing-ssn-place-bottom req-alert" : 'required-for-billing-ssn-place-bottom'}><i>{this.state.patient_form_invalid.patient.responsible_party.phone.cell && this.state.form_submited
                                                    && this.state.patient.responsible_party.phone.cell &&
                                                    this.state.patient.responsible_party.phone.cell.length != 10 &&
                                                    this.state.patient.responsible_party.phone.cell.length > 0
                                                    ? global_constants.constants.ten_digits_compulsory : 'required for billing'}
                                                </i></p>
                                                {
                                                    //<div className='requiredText'>
                                                    //    {this.state.patient_form_invalid.patient.responsible_party.phone.cell &&
                                                    //        this.state.form_submited &&
                                                    //        this.state.patient.responsible_party.phone.cell &&
                                                    //        this.state.patient.responsible_party.phone.cell.length != 10 &&
                                                    //        this.state.patient.responsible_party.phone.cell.length > 0
                                                    //        ? global_constants.constants.ten_digits_compulsory
                                                    //        : ''}
                                                    //</div>
                                                }
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>Email</label>
                                                <Form.Input
                                                    autoComplete='new-password'
                                                    maxLength={50}
                                                    name='txt_email'
                                                    value={this.state.patient.responsible_party.email || ''}
                                                    onChange={e => this.on_patient_form_change(e, null, false, true, null, null)}
                                                    error={this.state.patient_form_invalid.patient.responsible_party.email && this.state.form_submited}
                                                />
                                                <div className='requiredText'>
                                                    {this.state.patient_form_invalid.patient.responsible_party.email && this.state.form_submited ? global_constants.constants.valid_email : ''}
                                                </div>
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid>
                                        <Grid.Column mobile={8} tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>Date of Birth</label>
                                                <DatePicker
                                                    maxDate={moment().format('MM/DD/YYYY')}
                                                    date_update={this.handleCalendar_responsible_partyChange}
                                                    date={this.state.patient.responsible_party.formatted_date_of_birth}
                                                    is_required={this.state.patient_form_invalid.patient.responsible_party.date_of_birth && this.state.form_submited}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>Gender</label>
                                                {
                                                    <Selection
                                                        name='responsible_party_gender'
                                                        id='patient_responsible_party_gender'
                                                        placeHolder='Select'
                                                        options={this.state.patient_master_data.gender}
                                                        onChange={this.on_patient_form_change_for_responsible_party_gender}
                                                        defaultValue={this.state.patient.responsible_party.gender || ''}
                                                        hidden={true}
                                                    //style={this.state.patient_form_invalid.patient.gender && this.state.form_submited ? "req-background-inp" : ''}
                                                    //isRequired={this.state.error.state ? true : false}
                                                    //disabled={this.state.page_state === enum_case_page_state.edit}
                                                    />
                                                    //<Dropdown placeholder='Select' fluid selection options={this.state.patient_master_data.gender} value={this.state.patient.responsible_party.gender}
                                                    //    onChange={(e, { value }) => { this.on_patient_form_change(e, null, false, true, 'gender', value) }} />
                                                }
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid>
                                        <Grid.Column mobile={8} tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>SSN</label>

                                                <MaskedInput
                                                    mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    className={this.state.patient_form_invalid.patient.responsible_party.ssn && this.state.form_submited ? 'red-error-thin' : ''}
                                                    placeholder='___-__-____'
                                                    guide={true}
                                                    id='patient-ssn'
                                                    autoComplete='new-password'
                                                    name='ssn'
                                                    onChange={this.on_patient_form_change_for_home_ssn}
                                                    value={this.state.patient.responsible_party.ssn || ''}
                                                    showMask={true}
                                                />
                                                <div className='requiredText'>
                                                    {this.state.patient_form_invalid.patient.responsible_party.ssn &&
                                                        this.state.form_submited &&
                                                        this.state.patient.responsible_party.ssn &&
                                                        this.state.patient.responsible_party.ssn.length != 9 &&
                                                        this.state.patient.responsible_party.ssn.length > 0
                                                        ? global_constants.constants.ssn_compulsory
                                                        : ''}
                                                </div>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>Marital Status</label>
                                                {
                                                    <Selection
                                                        name='responsible_party_marital_status'
                                                        id='patient_responsible_party_marital_status'
                                                        placeHolder='Select'
                                                        options={this.state.patient_master_data.marital_status}
                                                        onChange={this.on_patient_form_change_for_responsible_party_marital_status}
                                                        defaultValue={this.state.patient.responsible_party.marital_status || ''}
                                                        hidden={true}
                                                    //style={this.state.patient_form_invalid.patient.gender && this.state.form_submited ? "req-background-inp" : ''}
                                                    //isRequired={this.state.error.state ? true : false}
                                                    //disabled={this.state.page_state === enum_case_page_state.edit}
                                                    />
                                                    //<Dropdown placeholder='Select' fluid selection options={this.state.patient_master_data.marital_status} value={parseInt(this.state.patient.responsible_party.marital_status)}
                                                    //    onChange={(e, { value }) => { this.on_patient_form_change(e, null, false, true, 'marital_status', value) }} />
                                                }
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid>
                                        <Grid.Column mobile={8} tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>Driver's License</label>
                                                <Form.Input
                                                    autoComplete='new-password'
                                                    name='txt_driver_license'
                                                    maxLength={50}
                                                    value={this.state.patient.responsible_party.driver_license || ''}
                                                    onChange={e => this.on_patient_form_change(e, null, false, true, null, null)}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>Health Savings Account</label>
                                                <NumberFormat
                                                    maxLength={15}
                                                    value={this.state.patient.responsible_party.hsa || ''}
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}
                                                    displayType='input'
                                                    onValueChange={this.on_hsa_change}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    renderText={value => <div>{value}</div>}
                                                />
                                                {/* <Form.Input autoComplete="new-password" pattern="[0-9]*" name='txt_hsa' maxLength={5} value={this.state.patient.responsible_party.hsa || ''} onChange={(e) => this.on_patient_form_change(e, null, false, true, null, null)} /> */}
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>

                                    <Header className='form_section_heading' as='h5' dividing>
                                        Billing Information
                  </Header>
                                    <div className='field'>
                                        <div className={this.state.patient.is_active ? 'ui checked checkbox' : 'ui checkbox'}>
                                            <input
                                                className='hidden'
                                                id='is_patient_statement'
                                                tabIndex={0}
                                                type='checkbox'
                                                onKeyDown={handle_click_on_enter}
                                                checked={this.state.patient.is_patient_statement ? false : true}
                                                onChange={e => this.on_change_send_statement_patient(e)}
                                            />
                                            <label htmlFor='is_patient_statement'>Do not send batch statement to patient</label>
                                        </div>
                                    </div>
                                </Grid.Column>
                            </Grid>

                            <Grid>
                                <Grid.Column>
                                    <p className='instructinfo'>
                                        Fields marked with <strong className={'font_bold'}>"required"</strong> must be completed before you can save. Fields marked with{' '}
                                        <strong className={'font_bold'}>"required for billing"</strong> do not need to be completed in order to save. However, you cannot bill charges for this patient
                                        until these fields are completed.
                  </p>
                                </Grid.Column>
                            </Grid>
                            <br />
                            <Grid>
                                <Grid.Column style={{ marginTop: '16px' }}>
                                    <div className='field'>
                                        <div className={this.state.patient.is_active ? 'ui checked checkbox' : 'ui checkbox'}>
                                            <input
                                                className='hidden'
                                                id='patient_active'
                                                tabIndex={0}
                                                type='checkbox'
                                                onKeyDown={handle_click_on_enter}
                                                checked={this.state.patient.is_active}
                                                onChange={e => this.on_change_active_patient(e)}
                                            />
                                            <label htmlFor='patient_active'>Patient Active</label>
                                        </div>
                                    </div>
                                </Grid.Column>
                            </Grid>

                            <div style={{ marginTop: '25px' }}>
                                <MedicareCapComponent
                                    is_view={false}
                                    patient_id={this.state.patient_id}
                                    onRef={this.setup_medicare_child_reference}
                                    form_submitted={this.state.form_submited}
                                />
                            </div>
                        </Form>
                    </div>
                    <div className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area' id='applicationFooterSticky'>
                        <Button id='patient_cancel_btn' basic onClick={this.on_patient_cancel_click}>
                            Cancel
            </Button>
                        <Button id='patient_save_btn' onKeyDown={set_focus_to_app_header} type='submit' disabled={this.state.is_save_button_disabled} primary onClick={this.on_patient_save_click}>
                            Save
            </Button>
                    </div>
                </div>
                {this.state.is_duplicate_patient_exist && (
                    <DuplicatePatientComponent
                        onGridRowSelection={this.on_duplicate_patient_row_selection}
                        showModal={this.state.is_duplicate_patient_exist}
                        onCloseModal={this.on_duplicate_patient_close}
                        on_force_save_click={this.on_duplicate_patient_force_save}
                        duplicate_patient_data={this.state.duplicate_patients}
                    />
                )}
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            get_patient_details: get_patient_details,
            update_patient_header_info: update_patient_header_info,
            set_active_patient: set_active_patient
        },
        dispatch
    );
};

const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
        patient_details: state.patient_details
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PatientAddComponent);