import { IProviderBillingStrategy } from './provider_billing_validator_strategy';

export class ConcreteStrategySettingNameUnique implements IProviderBillingStrategy {
    private hasSettingName(dataToCheck: any, dataCurrentIndex: any, dataInGrid: any) {
        let matchedGridData: string[] = dataInGrid.filter((element: any, elementIndex: number) => {
            if (element.status && dataToCheck.status && dataCurrentIndex != elementIndex
                && element.setting_name.toUpperCase() == dataToCheck.setting_name.toUpperCase()) {
                return element;
            }
        });

        return matchedGridData.length > 0;
    }

    validate(data: any[]): boolean {
        return !this.hasSettingName(data[0], data[1], data[2]);
    }
}

export class ConcreteStrategyClaimHoldNameUnique implements IProviderBillingStrategy {
    private hasClaimHoldName(dataToCheck: any, dataCurrentIndex: any, dataInGrid: any) {
        let matchedGridData: string[] = dataInGrid.filter((element: any, elementIndex: number) => {
            if (element.status && dataToCheck.status && dataCurrentIndex != elementIndex
                && element.claim_hold_name.toUpperCase() == dataToCheck.claim_hold_name.toUpperCase()) {
                return element;
            }
        });

        return matchedGridData.length > 0;
    }

    validate(data: any[]): boolean {
        return !this.hasClaimHoldName(data[0], data[1], data[2]);
    }
}