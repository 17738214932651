import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Dimmer, Form, Grid, Loader, Input } from "semantic-ui-react";
import { set_focus_on_element_with_id } from '../../shared/tab_navigation_utility'
import GridView from '../../shared/component/grid';
import { toastr } from 'react-redux-toastr';
import { get_all_error, get_columns } from '../../shared/utility';
import { toastr_options } from '../../global_constants';
import * as constants from '../charge_constant';
import * as session_storage from '../../shared/session_storage_utility';
import { get_procedure_code_list, procedure_code_update } from '../action/charge_action';

export class ProcedureCodeStatusComponent extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            show_grid: false,
            is_search_disabled: false,
            is_update_disabled: false,
            grid_params: [],
            title: 'Procedure Code Status',
            company_name: props.user_login_details.user_data.data.gpms_code + " - " + props.user_login_details.user_data.data.company_name,
            is_error: false,
            grid_rows_count: 0,
            procedure_code_pending: true,
            search_criteria: {
                procedure_code: ""
            },
            incomplete_proc_code_ids: []
        };
        this.handle_clear = this.handle_clear.bind(this);
    }
    total_column_width: number = 0;
    grid_header_height: number = 0;
    width_padding: number = 100;
    grid_data: any = {
        rows: [],
        grid_conf: constants.procedure_code_configuration.column_defs,
    };

    componentDidMount = () => {
        document.body.classList.add('admin-framework');
        let procedure_code_search = session_storage.get(`procedure_code_criteria`);

        if (procedure_code_search && procedure_code_search.search_criteria) {
            let updatedCriteria = { ...procedure_code_search.search_criteria, procedure_code: procedure_code_search.search_criteria.procedure_code ? procedure_code_search.search_criteria.procedure_code : "" }
            this.setState({ search_criteria: updatedCriteria }, () => {
                this.handle_search();
            })
        }
    }

    componentWillUnmount = () => {
        document.body.classList.remove('admin-framework');
        if (this.props.history.location) {
            let route_array = this.props.history.location.pathname.split('/');
            if (route_array && route_array[2] !== ('procedure_code' || 'procedure_code_status')) {
              session_storage.remove('procedure_code_criteria');
            }
        }
    }

    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }

    update_report_rows_count = () => {
        this.setState({
            grid_rows_count: this.state.grid_params.api.getDisplayedRowCount()
        });

        var grid_width = document.getElementsByClassName('admin-wrapper')[0].clientWidth;

        if (this.total_column_width > (grid_width - this.width_padding)) {
            this.grid_data.grid_conf.filter(item => {
                this.state.grid_params.columnApi.setColumnWidth(item.field, item.width, false);
            });
        }
    };

    get_grid_ref = grid_params => {
        this.setState({
            grid_params,
            grid_rows_count: grid_params.api.getModel().getRowCount()
        });
        var grid_width = document.getElementsByClassName('admin-wrapper')[0].clientWidth;
        if (this.total_column_width > (grid_width - this.width_padding)) {
            this.grid_data.grid_conf.filter(item => {
                grid_params.columnApi.setColumnWidth(item.field, item.width, false);
            });
        }
    };

    on_grid_out = () => {
        set_focus_on_element_with_id('app_header_dropdown');
    };

    handle_change = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            search_criteria: { ...prevState.search_criteria, [name]: value },
            is_error: value ? false : true
        }));
    };

    handle_clear = () => {
        this.setState({
            show_grid: false,
            is_search_disabled: false,
            is_update_disabled: false,
            search_criteria: {
                procedure_code: ""
            },
            grid_rows_count: 0,
            loading: false,
            is_error: false,
            procedure_code_pending: true,
            incomplete_proc_code_ids: []
        });
        this.grid_data = {
            rows: [],
            grid_conf: constants.procedure_code_configuration.column_defs
        }

        session_storage.remove(`procedure_code_criteria`);
    }

    handle_cancel = () => {
        this.setState({
            show_grid: false,
            is_search_disabled: false,
            is_update_disabled: false,
            grid_rows_count: 0,
            loading: false,
            is_error: false,
            procedure_code_pending: true,
            incomplete_proc_code_ids: []
        });
        this.grid_data = {
            rows: [],
            grid_conf: constants.procedure_code_configuration.column_defs
        }
    }

    row_data_formatter = (data) => {
        data.map(row => {
            this.grid_data.grid_conf.map((value) => {
                switch (value.type) {
                    case 'boolean': 
                        row[value.field] = row[value.field] !== false ? false : true;
                        break;
                    case 'status':
                        row[value.field] = row[value.field] ? "Active" : "Inactive";
                        break;
                    default:
                        row[value.field] = row[value.field] ? row[value.field].toString().trim() : "";
                }
            });
            return row;
        });

        return data;
    }

    handle_search = async (event = null) => {
        if (!this.state.search_criteria.procedure_code) {
            this.setState({ is_error: true });
            toastr.error('', 'The Procedure Code is invalid.');
            return;
        }

        this.setState({
            is_search_disabled: true,
            is_update_disabled: true,
            loading: true,
            show_grid: false,
            procedure_code_pending: true,
            incomplete_proc_code_ids: []
        });

        this.grid_data = {
            rows: [],
            grid_conf: constants.procedure_code_configuration.column_defs,
        }

        if (this.state.is_error && event) {
            event.preventDefault();
        } else {
            var token = this.props.user_login_details.user_data.data.token_details.access_token;
            let criteria = this.state.search_criteria
            let response = await get_procedure_code_list(token, criteria.procedure_code);
            
            if (response && response.data.status == 1) {
                if (response.data && response.data.data && response.data.data.length > 0) {
                    this.grid_data.rows = this.row_data_formatter(response.data.data);
                    session_storage.set(`procedure_code_criteria`, {
                        search_criteria: this.state.search_criteria
                    });
                    const grid_height = get_columns(this.grid_data.rows, this.grid_data.grid_conf);
                    this.total_column_width = grid_height.total_column_width;
                    this.grid_header_height = grid_height.header_height;
                    this.setState({
                        is_search_disabled: false,
                        is_update_disabled: false,
                        loading: false,
                        show_grid: true,
                        grid_rows_count: this.grid_data.rows.length,
                        procedure_code_pending: true
                    });
                }
                if (response.data && response.data.data && response.data.data.length == 0) {
                    this.handle_cancel()
                    toastr.error("", 'The Procedure Code is invalid.');
                }
            } else {
                const toastr_options = this.show_html_content_toaster(get_all_error(response.data.data));
                toastr.error('', toastr_options);
                this.setState({
                    is_search_disabled: false,
                    is_update_disabled: false,
                    loading: false,
                    show_grid: true,
                    grid_rows_count: this.grid_data.rows.length
                });
            }
        }
    }

    on_check_box_selection = (data) => {
        this.setState({
            [`incomplete_proc_code_ids`]: data
        });
    }

    is_row_selectable = (params) => {
        return (params.data.is_incomplete === false) ? true : false;
    }

    handle_update_procedure_codes = async () => {
        if (this.state[`incomplete_proc_code_ids`].length == 0) {
            toastr.error('', 'No procedure code selected.');
            return;
        }
        if (this.state[`incomplete_proc_code_ids`].length > 0) {
            // Create Query Param from checked items
            const incomplete_proc_code_id_array = this.state[`incomplete_proc_code_ids`].map((value) => {
                return value.procedure_code_id;
            });
            this.setState({
                loading: true,
                is_search_disabled: true,
                is_update_disabled: true
            });

            var token = this.props.user_login_details.user_data.data.token_details.access_token;
            await procedure_code_update(token, incomplete_proc_code_id_array).then(
                (response) => {
                    if (response.data && response.data.status === 1) {
                        let toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                        toastr.success("", toastr_options);
                        this.handle_search();
                    } else {
                        if (response.data.messages && response.data.messages[0].message.indexOf("<br") > -1) {
                            let toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                            toastr.error("", toastr_options);
                        }
                    }
                    this.setState({
                        loading: false,
                        is_search_disabled: false,
                        is_update_disabled: false,
                        procedure_code_pending: false,
                        incomplete_proc_code_ids: []
                    });
                },
                (error) => {
                    this.setState({
                        loading: false,
                        is_search_disabled: false,
                        is_update_disabled: false,
                        procedure_code_pending: true
                    });
                    if (error.response.data) {
                        const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                        toastr.error("", toastr_options);
                    }
                }
            );
        }
    };

    // On double click of grid, it redirects user to view page.
    on_row_double_clicked = (selected_row_data: { procedure_code_id: any; }) => {
        let code_id = selected_row_data.procedure_code_id;
        this.props.history.push(`/charge/procedure_code/${code_id}`);
    };

    render() {
        const { loading, show_grid, search_criteria, is_search_disabled, is_update_disabled, grid_rows_count, is_error, company_name, procedure_code_pending } = this.state
        return (
            <React.Fragment>
                <Dimmer active={loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <div className='admin-wrapper' style={this.grid_data.rows.length != 0 ? { paddingBottom: 20 } : {}}>
                    <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                        <Grid.Column computer={16}>
                            <h3 className='ui header left aligned'>Procedure Code Status</h3>
                        </Grid.Column>
                    </Grid>
                    <div id='admin-scrollable-area' className='wrapper' style={show_grid ? { flex: 1 } : {}}>
                        <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container'>
                            <Form id="procedure_code_container" autoComplete='off' onSubmit={e => this.handle_search(e)}>
                                <Grid style={{ marginBottom: 0 }}>
                                    <Grid.Column computer={16} >
                                        <Grid>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field >
                                                    <label>Company</label>
                                                    <span style={{ fontSize: 16 }}>{company_name}</span>
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field >
                                                    <label>Procedure Code<span className={is_error ? "req-alert" : 'req-alert_normal'}> (required)</span></label>
                                                    <Input fluid autoFocus
                                                        id='procedure_code'
                                                        name='procedure_code'
                                                        value={search_criteria.procedure_code || ""}
                                                        onChange={(e) => this.handle_change(e)}
                                                        className={is_error ? 'req-border-inp' : ""}
                                                        error={is_error}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid>
                                    </Grid.Column>
                                </Grid>
                                <Grid style={{ margin: '0 -17px' }}>
                                    <Grid.Column computer={16} textAlign='right'>
                                        <Button type="button" id="clear_form" onClick={this.handle_clear} basic >Clear</Button>
                                        <Button id="procedure_code_search" disabled={is_search_disabled} type='submit' primary>Search</Button>
                                    </Grid.Column>
                                </Grid>
                                {show_grid && (
                                    <Grid style={{ marginTop: '-1rem', marginBottom: 0 }}>
                                        <Grid.Column tablet={5} computer={4} textAlign='left'>
                                            <p style={{ fontSize: '16px' }}>Search Results</p>
                                        </Grid.Column>
                                        <Grid.Column tablet={5} computer={8} textAlign='center'>
                                            {
                                                <p style={{ fontSize: '16px', minHeight: 22 }}>
                                                    {`${grid_rows_count} ${grid_rows_count == 1 ? ' record shown' : ' records shown'
                                                        }`}
                                                </p>
                                            }
                                        </Grid.Column>
                                        <Grid.Column tablet={2} computer={4} textAlign='right' />
                                    </Grid>
                                )}
                            </Form>
                        </div>
                        {show_grid && (
                            <GridView
                                id="procedure_code"
                                row={this.grid_data.rows}
                                column={this.grid_data.grid_conf}
                                style={{ height: '100%' }}
                                wrapperStyle={{ width: '100%', height: '100%', display: 'flex' }}
                                suppressMovableColumns={false}
                                enableColResize={true}
                                selectionType={'single'}
                                checkboxSelection={true}
                                gridAutoRowHeight={true}
                                onRowSelection={(item) => this.on_check_box_selection(item)}
                                onRowDoubleClicked={this.on_row_double_clicked}
                                get_grid_ref={this.get_grid_ref}
                                suppressSizeToFit={true}
                                headerHeight={this.grid_header_height}
                                headerIdForTabNavigation={constants.search_grid_id}
                                onForceGridOut={this.on_grid_out}
                                on_filter_button_click={() => this.update_report_rows_count()}
                                isRowSelectable={this.is_row_selectable}
                            />
                        )}
                    </div>
                    {show_grid > 0 && procedure_code_pending && (
                        <div className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area' id='applicationFooterSticky' style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <Grid.Column computer={16} textAlign='right'>
                                <Button type="button" id="cancel_data" onClick={this.handle_cancel} basic >Cancel</Button>
                                <Button primary id='update_procedure_code_button' disabled={is_update_disabled} type='button' style={{ marginRight: 0 }} onClick={this.handle_update_procedure_codes}>
                                    Update
                                </Button>
                            </Grid.Column>
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({

    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProcedureCodeStatusComponent));