import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Dimmer, Grid, Loader } from 'semantic-ui-react';
import { clearTimeout, setTimeout } from 'timers';
import GridView from '../../shared/component/grid';
import { GRID_NAME } from '../../shared/component/grid/constants';
//Sub-Layouts
import { get_patient_account_notes, get_patient_account_notes_list, get_patient_account_notes_status_action, get_patient_account_tasks, remove_patient_account_notes, set_patient_account_notes_and_task } from '../action/patient_action';
import * as patient_constants from '../patient_constants';
import AddNewNoteComponent from './account_notes_add_component';
import AccountNotesPrintNotesComponent from './account_notes_print_notes_component';

import { set_focus_on_element_with_id } from './../../shared/tab_navigation_utility';

/*
1) Display the current patient's Account notes
2) Add New Account notes component used
3) Delete Existing Account notes implemented on the same page
*/
export class AccountNotesComponent extends React.Component<any, any> {
    [x: string]: any;
    _is_mounted = false;
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            shown: false,
            print_notes_shown: false,
            show_new_note_modal: false,
            patient_id: props.match.params.patient_id ? props.match.params.patient_id : this.props.patient_id,
            selected_account_notes: [],
            db_click: false,
            double_click_select: [],
            account_notes_grid_ref: {},
            patient_account_task_notes: [],
            note_fatch_time: ''
        }

        this.grid_reference = React.createRef();
    }


    UNSAFE_componentWillMount() {

        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    componentWillUnmount() {
        this._is_mounted = false;
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
        }
        if (this.props.onRef) {
            this.props.onRef(undefined)
        }
    }
    
    componentDidMount() {
        this._is_mounted = true;
        this.get_account_details(this.state.patient_id);
       
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.match.params.patient_id != this.props.match.params.patient_id) {
            if (this._is_mounted) {
                this.setState({
                    patient_id: this.props.match.params.patient_id,
                    selected_account_notes: []
                });
            }
            this.get_account_details(this.props.match.params.patient_id);
            return;
        }
        if (previousProps.account_shown != this.props.account_shown) {
            if (this._is_mounted) {
                this.setState({ shown: !this.state.shown }); // show on change props
            }
        }

        //account notes clear selection
        if (previousProps.note_fatch_time != this.props.note_fatch_time) {
            if (this._is_mounted) {
                this.setState({
                    timeout: setTimeout(() => {
                        if (this.state.account_notes_grid_ref.api) {
                            this.state.account_notes_grid_ref.api.forEachNode((node) => {
                                node.setSelected(false);
                            });
                        }
                    }, 200)
                });
            }
            //setTimeout(() => {
            //    if (this.state.account_notes_grid_ref.api) {
            //        this.state.account_notes_grid_ref.api.forEachNode((node) => {
            //            node.setSelected(false);
            //        });
            //    }
            //}, 200);
        }
    }

    get_account_details = (patient_id) => {
      this.setState(
        {
          selected_account_notes: []
        },
        () =>
        this.props.get_patient_account_notes_list(patient_id, this.props.user_login_details.user_data.data.token_details.access_token, this.props.data_model_key)
        );
    }


    //get_account_details = async (patient_id) => {
    //    this.setState({
    //        loading: true,
    //        patient_account_task_notes:null
    //    })
    //    //Account 
    //    //await this.props.get_patient_account_notes_and_task(patient_id, this.props.user_login_details.user_data.data.token_details.access_token);
    //    await this.props.get_patient_account_notes(patient_id, this.props.user_login_details.user_data.data.token_details.access_token);
    //    await this.props.get_patient_account_tasks(patient_id, this.props.user_login_details.user_data.data.token_details.access_token);
    //    await this.props.set_patient_account_notes_and_task(this.props.patient_details.patient_task, this.props.patient_details.patient_account_notes);
    //    this.setState({
    //        loading: false,
    //        selected_account_notes: [],
    //        patient_account_task_notes: this.props.patient_details.selected_patient_task_and_account_notes
    //    });
    //    if (this.state.account_notes_grid_ref.api) {
    //        this.state.account_notes_grid_ref.api.forEachNode((node) => {
    //            node.setSelected(false);
    //        });
    //    }
    //}

    // toggle to show and hide Account Notes Component
    private toggle = () => {
        if (this._is_mounted) {
            this.setState({
                shown: !this.state.shown
            });
        }
    }

    // Print Button Functionality 
    on_print_click(selected_account_notes): any {
        if (selected_account_notes.length === 0) {
            toastr.warning('', patient_constants.patient_messages.select_record);
        } else {
            if (this._is_mounted) {
                this.setState({
                    print_notes_shown: true
                });
            }
        }
    }

    // Remove Button Functionality
    async on_remove_sticky_click(): Promise<any> {
        var selected_account_notes_length = this.state.selected_account_notes.length;
        var remove_note_list = [];
        var hasNote = false;

        // If record selected
        if (selected_account_notes_length > 0) {
            for (let k = 0; k < selected_account_notes_length; k++) {
                var item = this.state.selected_account_notes[k];

                if (!item.isTask) {
                    hasNote = true;
                }
                if (item.stick_on != null && item.stick_on != '' && item.stick_on != '-- Not A Sticky --') {
                    remove_note_list.push(item.id);
                }
            }

            if (remove_note_list.length == 0) {
                toastr.error('', patient_constants.patient_messages.note_already_removed);
                return
            }
            if (this._is_mounted) {
                this.setState({
                    loading: true
                });
            }

            // API call to remove data
            await this.props.remove_patient_account_notes(this.props.user_login_details.user_data.data.token_details.access_token, this.state.patient_id, remove_note_list);
            if (this.props.patient_details.selected_patient_remove_account_notes && this.props.patient_details.selected_patient_remove_account_notes.data) {

                var errorMessage = hasNote ? patient_constants.patient_messages.note_removed : patient_constants.patient_messages.note_already_removed;
                toastr.success('', errorMessage);

                // Get New Data                    
                this.get_account_details(this.state.patient_id);
                this.props.get_patient_account_notes_status({
                    updated: true,
                    addedNoteType: "Payment" //this.state.patient.stick_on
                });
                this.props.get_patient_account_notes_status({
                    updated: true,
                    addedNoteType: "Charge" //this.state.patient.stick_on
                });
            }
            if (this._is_mounted) {
                this.setState({
                    loading: false,
                    selected_account_notes: []
                });
            }
        }
        else {
            toastr.warning('', patient_constants.patient_messages.no_records_selected);
        }
    }

    // Double click on account notes row
    // Open modal popup for viewing data
    on_view_account_notes = (selectedRows) => {
        if (this._is_mounted) {
            this.setState({
                db_click: true
            });
        }
        var selected_row_arr = [];
        selected_row_arr[0] = selectedRows;
        this.double_click_selection(selected_row_arr);
        this.on_print_click(selectedRows);
    }

    // Click functin of checkbox
    double_click_selection = items => {
        if (this._is_mounted) {
            this.setState({
                double_click_select: items
            });
        }
    }



    //Cancel button Click
    //Cancel button is only visible on patient header (Account note icon)
    on_cancel_click = () => {
        this.props.close_icon()
    }

    // Click functin of checkbox
    on_check_box_selection = items => {
        if (this._is_mounted) {
            this.setState({
                selected_account_notes: items
            });
        }
    }

    // Close Print Modal 
    close_print_modal = () => {
        if (this._is_mounted) {
            this.setState({
                print_notes_shown: false,
            });
        }
        if (this.state.db_click === true) {
            if (this._is_mounted) {
                this.setState({
                    double_click_select: [],
                    db_click: false
                });
            }
        }
    }

    get_account_notes_grid_ref = (grid_params) => {
        if (this._is_mounted) {
            this.setState({ account_notes_grid_ref: grid_params });
        }
    }

    get_rows = () => {
        if (this.props.data_model_key === 'dialog_account_notes') {
            return this.props.patient_details && this.props.patient_details.dialog_account_notes || {};
        }
        return this.props.patient_details && this.props.patient_details.patient_view_account_notes || {};
    }

    handle_on_grid_out = () => {
        if (this.props.is_patient_header) {
            set_focus_on_element_with_id("patient_header_close_button");
        } else {
            set_focus_on_element_with_id("account_notes_remove_sticky_button");
        }
    }

    render() {

        var hidden = { display: !this.state.shown ? "block" : "none" }
        const data_model = this.get_rows();
        let is_loading = data_model.task_notes_loading;
        if (this.state.loading == true) {
            is_loading = this.state.loading;
        }

        return (
            <div className="content active" style={hidden} id="account_notes">
                <Dimmer active={is_loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <Grid.Column>
                    <GridView
                        id={this.props.is_patient_header ? "account_notes_patient_header_grid_id" : "account_notes_grid_id"}
                        row={data_model.account_task_notes || []}
                        column={patient_constants.account_notes_configuration.column_defs}
                        gridAutoHeight={this.props.is_patient_header ? false : true}
                        checkboxSelection={true}
                        onRowDoubleClicked={this.on_view_account_notes}
                        onRowSelection={this.on_check_box_selection}
                        style={{ height: "480px" }}
                        get_grid_ref={this.get_account_notes_grid_ref}
                        name={GRID_NAME.ACCOUNT_NOTES}
                        onGridOut={this.handle_on_grid_out}
                        enableEnterOnNavigation={true}
                        onRef={instance => { this.grid_reference = instance; }}
                        handleCheckboxNavigation={true}
                    />
                </Grid.Column>

                <Grid.Column computer={16} style={{ textAlign: 'right' }} className="mar-t-15">
                    {this.props.is_patient_header ? <Button basic id="patient_header_close_button" onClick={() => this.on_cancel_click()}>Cancel </Button> : ''}
                    <Button basic id="account_notes_remove_sticky_button" onClick={() => this.on_remove_sticky_click()}>Remove Sticky</Button>
                    <Button basic id={this.props.is_patient_header ? "print_selected_notes_button_patient_header" : "print_selected_notes_button"} onClick={() => this.on_print_click(this.state.selected_account_notes)}>Print Selected Notes</Button>
                    <AddNewNoteComponent is_patient_header={this.props.is_patient_header} onCloseModal={this.closeAddNewNoteModal} get_account_details={this.get_account_details} patient_id={this.props.patient_id}
                        get_data={true} add_label={'Add New Note'} id={this.props.is_patient_header ? 'trigger_add_new_notes_button_patient_header' : 'trigger_add_new_notes_button' } />
                    {(this.state.print_notes_shown === true) ? <AccountNotesPrintNotesComponent is_patient_header={this.props.is_patient_header} head={this.props.patient_data} open={this.state.print_notes_shown} note_info={(this.state.double_click_select.length > 0) ? this.state.double_click_select : this.state.selected_account_notes} onCloseModal={this.close_print_modal} /> : ''}
                </Grid.Column>

            </div>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
       // get_patient_account_notes_and_task: get_patient_account_notes_and_task,
        remove_patient_account_notes: remove_patient_account_notes,
        get_patient_account_notes_status: get_patient_account_notes_status_action,
        get_patient_account_notes: get_patient_account_notes,
        get_patient_account_tasks: get_patient_account_tasks,
        set_patient_account_notes_and_task: set_patient_account_notes_and_task,
        get_patient_account_notes_list: get_patient_account_notes_list
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        user_details: state.user_details,
        shared_details: state.shared_details,
        patient_details: state.patient_details
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountNotesComponent))