import { IProviderBillingStrategy } from './provider_billing_validator_strategy';
import * as billingPaneConstants from '../../provider_billing_pane_constants';

export interface ISelectionGroups {
    location_ids: number[],
    insurance_class_ids: number[],
    insurance_code_ids: number[]
}

export class ConcreteCombinationsLimit implements IProviderBillingStrategy {
    private isValidPermutation(dataIncoming: any[]): boolean {
        const permutationsLimit = billingPaneConstants.initialSettings.permutationsLimit;
        let formFields: ISelectionGroups = dataIncoming[0];
        let nameElementToUpdate: string = (dataIncoming[1]) ? Object.keys(dataIncoming[1])[0] : null;

        if (nameElementToUpdate) {
            let newValuesToBeUpdated: string[] = dataIncoming[1][nameElementToUpdate];
            let element: any = undefined;

            for (element in formFields) {
                if (element == nameElementToUpdate) {
                    formFields[element] = newValuesToBeUpdated;
                    break;
                }
            }
        }

        let currentPermutationsLimit = 1;

        if (formFields.location_ids && formFields.location_ids.length > 0) {
            currentPermutationsLimit *= formFields.location_ids.length;
        }

        if (formFields.insurance_class_ids && formFields.insurance_class_ids.length > 0) {
            currentPermutationsLimit *= formFields.insurance_class_ids.length;
        }

        if (formFields.insurance_code_ids && formFields.insurance_code_ids.length > 0) {
            currentPermutationsLimit *= formFields.insurance_code_ids.length;
        }

        return currentPermutationsLimit <= permutationsLimit;
    }

    validate(data: any[]): boolean {
        return this.isValidPermutation(data);
    }
}