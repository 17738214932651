import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Dimmer, Form, Grid, Icon, Loader, Modal } from 'semantic-ui-react';
import { clearTimeout, setTimeout } from 'timers';
import * as global_constants from '../../global_constants';
import { get_control_list } from '../../shared/action/advanced_control_action';
import { get_columns } from './../../shared/utility'
import { handle_focus_on_tab, set_focus_on_element_with_id } from './../../shared/tab_navigation_utility';
import GridView from './grid';
interface IProps {
    controlId: string,
    onGridRowSelection: Function,
    onSearch: Function,
    onCloseModal: Function,
    gridConfig: { rows?: any; paginationPageSize?: any; column?: any; },
    showModal?: boolean,
    get_control_list?: Function,
    advance_control_state?: object,
    user_login_details?: { user_data: { data: { token_details: { access_token: any; }; }; }; },
    addClicks: Function,
    keyPress: Function,
    disabled_element?: string,
    set_parent_show_modal_state?: Function,
    get_grid_ref?: Function,
    className?: string,
    search_type?: string,
    headerIdForGridTabNavigation?: string,
    autoFocusForSearchIcon?: boolean
}

class AdvancedControl extends React.Component<IProps, any> {
    [x: string]: any;
    _is_mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            control_data: null,
            loading: false,
            form_data: {},
            control_id: this.props.controlId,
            grid_config: {},
            page_size: global_constants.constants.PAGE_SIZE,
            error_message: '',
            disabled_element: this.props.disabled_element ? this.props.disabled_element : false
        };

        this.grid_reference = React.createRef();
    }
    total_column_width: number = 0;
    grid_header_height: number = 0;
    controlHandle = e => {
        if (this.state.disabled_element) {
            return;
        }
        e.preventDefault();
        if (this._is_mounted) {
            if (this.props.search_type) {
                this.handle_close_tab();
            }
            this.setState({ showModal: true });
        }
        if (typeof this.props.set_parent_show_modal_state != 'undefined') {
            this.props.set_parent_show_modal_state(true, this.props.controlId);
        }
        if (this._is_mounted) {
            this.setState({
                timeout: setTimeout(function () {
                    if(! document) return; // if no DOM is there no need to go furthur.

                    var modelList: NodeListOf<HTMLElement> = document.querySelectorAll('#advance_seach_model_pop') as NodeListOf<HTMLElement>;

                    var model = modelList[modelList.length - 1];

                    if (model) {
                        var fist_input = model.getElementsByTagName('input');
                        if (fist_input && fist_input.length > 0 && fist_input[0]) {
                            fist_input[0].focus();
                        }
                    }
                }, 500)
            });
        }
    };
    componentWillUnmount() {
        this._is_mounted = false;
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
        }
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousState.showModal != this.state.showModal) {
            if (this._is_mounted) {
                this.setState({
                    showModal: this.state.showModal
                });
            }
            if (this.state.showModal) {
                var token = this.props.user_login_details.user_data.data.token_details.access_token;
                this.props.get_control_list(this.state.control_id, token);
            }
        }
        if (previousProps.showModal !== this.props.showModal) {
            if (this._is_mounted) {
                this.setState({
                    showModal: this.props.showModal
                });
            }
        }

        if (previousProps.advance_control_state !== this.props.advance_control_state) {
            const control_id = this.state.control_id.toLowerCase();
            if (this._is_mounted) {
                this.setState({
                    control_data: this.props.advance_control_state[control_id]
                });
            }
        }
        if (
            previousProps.gridConfig != this.props.gridConfig &&
            typeof this.props.gridConfig.rows != 'undefined' &&
            Object.keys(this.props.gridConfig).length > 0
        ) {
            const messages = 'No Record Available'; // typeof this.props.gridConfig.messages != 'undefined' && this.props.gridConfig.messages !== null ? this.props.gridConfig.messages.map(msg => msg.message).join('.') : '';
            if (this.props.gridConfig.rows && this.props.gridConfig.rows.length > 0) {
                //this.total_column_width = set_column_data_width(this.props.gridConfig.rows.slice(0, this.props.gridConfig.paginationPageSize), this.props.gridConfig.column);
                const grid_height = get_columns(this.props.gridConfig.rows.slice(0, this.props.gridConfig.paginationPageSize), this.props.gridConfig.column);
                this.total_column_width = grid_height.total_column_width;
                this.grid_header_height = grid_height.header_height;
            }
            if (this._is_mounted) {
                this.setState({
                    grid_config: this.props.gridConfig,
                    error_message: messages,
                    loading: false
                });
            }
        }
        if (previousProps.disabled_element != this.props.disabled_element) {
            if (this._is_mounted) {
                this.setState({
                    disabled_element: this.props.disabled_element
                });
            }
        }
    }

    /* getHandler method provide handles on bases of provided lable button */

    getHandler = (action, e) => {
        switch (action) {
            case 'Search':
                var data = this.state.form_data; // add page size property
                Object.keys(data).forEach(function (key) {
                    data[key] = data[key] ? data[key].toString().trim() : data[key];
                });
                data['page_size'] = global_constants.constants.PAGE_SIZE;
                if (this._is_mounted) {
                    this.setState({
                        loading: true
                    });
                }
                this.props.onSearch(data); // fetch data from search apis on click search button
                break;
            case 'Clear':
                this.clearFormHandler(e);
                break;
            case 'Cancel':
                this.closeModal();
                break;
            case 'Add Referring Physician':
                this.props.addClicks();
                break;
            case 'Add New':
                this.props.addClicks();
                break;
            case 'Add':
                this.props.addClicks();
                break;
            case 'New':
                this.props.addClicks();
                break;
            case 'Add New':
                this.props.addClicks();
                break;
            default:
                //this.props.onAddClick();
                break;
        }
    };

    closeModal = () => {
        if (this._is_mounted) {
            this.setState({ showModal: false, grid_config: {}, form_data: {}, error_message: '' });
        }
        if (typeof this.props.onCloseModal !== 'undefined') {
            this.props.onCloseModal();
        }
        if (typeof this.props.set_parent_show_modal_state != 'undefined') {
            this.props.set_parent_show_modal_state(false, this.props.controlId);
        }

        // when modal is closing and focus should move to search magnifying glass
        setTimeout(() => {
            if (this.props.search_type) {
                set_focus_on_element_with_id('advance-control-' + this.props.search_type);
            }
        }, 100);
    };

    clearFormHandler = e => {
        if (this._is_mounted) {
            this.setState({ form_data: {}, grid_config: {}, error_message: '' });
        }
    };

    handleChange = e => {
        const name = e.target.name;
        let input_val = e.target.type === 'checkbox' ? e.target.checked: e.target.value;
        const form_updated_data = Object.assign({}, this.state.form_data, { [name]: input_val });
        if (this._is_mounted) {
            this.setState({ form_data: form_updated_data });
        }
    };

    onSelection = selectedRows => {
        //this.props.onGridRowSelection(selectedRows);
        //this.closeModal();
    };

    onDoubleClick = (selectedRows) => {
        this.closeModal();
        this.props.onGridRowSelection(selectedRows);
    }

    input_key_press = (event) => {
        if (event.which == 13) {
            if (this.props.keyPress) {
                var data = this.state.form_data; // add page size property
                data['page_size'] = global_constants.constants.PAGE_SIZE;
                this.props.keyPress(data, event);
            }
        }
    };

    key_tab_press = (event, type) => {
        if (event.keyCode === 13) {  // on enter event
            event.preventDefault();

            if (type == 'open') {
                this.controlHandle(event);
                if (typeof this.props.set_parent_show_modal_state != 'undefined') {
                    this.props.set_parent_show_modal_state(true, this.props.controlId);
                }
            } else if (type == 'Search') {
                this.getHandler(type, event);
            }
        }

    }


    componentDidMount() {
        this._is_mounted = true;

    }

    /**
     * Making Generic
     * */
    handle_close_tab = () => {
        setTimeout(() => {
            if(! document) return; // if no DOM is there no need to go furthur.
            let closeIcons: NodeListOf<HTMLElement> = document.querySelectorAll('i.close.icon:not(.auto-search-icon)') as NodeListOf<HTMLElement>;
            let closeIcon = closeIcons[closeIcons.length - 1]
            if (closeIcon) {
                closeIcon.tabIndex = 0;
                closeIcon.id = "advance_control_close_icon" + this.props.search_type;
                let search_type = this.props.search_type;
                closeIcon.addEventListener("keydown", function (event) {
                    if (event.shiftKey && event.keyCode == 9) {
                        event.preventDefault();
                        let search_button = document.getElementById("advance_control_search_button" + search_type);
                        search_button.focus();
                    }

                    if (event.keyCode == 13) {
                        event.preventDefault();
                        var el = event.target as HTMLElement
                        el.click();
                    }
                });
            }
        }, 500)
    }
    handle_search_tab = (event) => {

        // if grid is not empty then focus should move on cross icon.
        if (!this.state.grid_config.rows || (this.state.grid_config.rows.length == 0)) {

            if (this.props.search_type) {
                //making generic
                handle_focus_on_tab(event, "advance_control_close_icon" + this.props.search_type)
            }
        } else {
            // scrolling grid column to the left
            this.grid_reference.ensureScrollIsLeft();
            this.grid_reference.handle_first_tab();
        }
    }

    on_grid_out = () => {
        if (this.props.search_type) {
            set_focus_on_element_with_id("advance_control_close_icon" + this.props.search_type);
        }
    }

    get_grid_ref = grid_params => {
        var grid_width = document.getElementById('advanced_control_content').clientWidth;
        if (this.total_column_width > grid_width) {
            this.props.gridConfig.column.filter(item => {
                grid_params.columnApi.setColumnWidth(item.field, item.width, false);
            });
        }
    }
    render() {
        const form_fields = this.state.control_data ? this.state.control_data.inputFields : null;

        // const { rows } = this.props.gridConfig;
        const { rows } = this.state.grid_config;

        // const grid_config = { ... this.props.gridConfig };
        const grid_config = { ... this.state.grid_config };
        const that = this;
        let button_controls = [];
        let input_controls = [];
        if (form_fields != null) {
            button_controls = form_fields.filter((element) => element.type == 'Button' && element).reverse(); // filter button controls
            input_controls = form_fields.filter((element) => element.type != 'Button' && element);
        }

        return (
            <React.Fragment>
                <button
                    type="button"
                    className="searchIcon"
                    disabled={this.state.disabled_element}
                    onClick={this.controlHandle}
                    autoFocus={this.props.autoFocusForSearchIcon ? true : false}
                    id={this.props.search_type ? 'advance-control-' + this.props.search_type : ''}
                >
                    <Icon aria-hidden="true" name='search' />
                </button>
                {form_fields != null && (
                    <Modal
                        onClose={this.closeModal}
                        centered={false}
                        className={`modal_container default-modal ${this.props.className}`}
                        open={this.state.showModal}
                        closeIcon={true}
                        id='advance_seach_model_pop'
                        closeOnDimmerClick={false}
                    >
                        <Dimmer active={this.state.loading}>
                            <Loader size='huge'>Loading</Loader>
                        </Dimmer>
                        <Modal.Header>
                            <div>
                                <h3>{this.state.control_data.title}</h3>
                            </div>
                        </Modal.Header>
                        <Modal.Content id='advanced_control_content'>
                            <Modal.Description>
                                <div className='common-forms' style={{ padding: '0' }}>
                                    <Form>
                                        <Grid>
                                            {input_controls.map((field, index) => {
                                                let fieldAttibute: any = {};
                                                fieldAttibute = {
                                                    name: field.name,
                                                    type: field.type == 'TextBox' ? 'text' : field.type,
                                                    value: this.state.form_data.hasOwnProperty(field.name)
                                                        ? this.state.form_data[field.name]
                                                        : '',
                                                    onChange: that.handleChange,
                                                    onKeyUp: e => {
                                                        this.key_tab_press(e, 'Search');
                                                    },
                                                    onKeyPress: that.input_key_press
                                                };
                                                if (field.maxLength != null && field.type == 'TextBox') {
                                                    fieldAttibute.maxLength = field.maxLength;
                                                }

                                                if (field.type == 'Checkbox') {
                                                    return (<Grid.Column tablet={8} computer={8} key={index}>
                                                        <Form.Field>
                                                            <label>&nbsp;</label>
                                                            <div className="ui checkbox">
                                                                <input {...fieldAttibute} />
                                                                <label>{field.labelName}</label>
                                                            </div>
                                                        </Form.Field>
                                                    </Grid.Column>);
                                                }

                                                return (<Grid.Column tablet={8} computer={8} key={index}>
                                                        <Form.Field>
                                                            <label>{field.labelName}</label>
                                                            <input {...fieldAttibute} />
                                                        </Form.Field>
                                                    </Grid.Column>);
                                            })}
                                        </Grid>
                                        <Grid>
                                            <Grid.Column tablet={16} computer={16} textAlign='right' className='mar-t-10'>
                                                {button_controls.map((field, index) => {
                                                    if (field.action == 'Search') {
                                                        return (<Button
                                                            key={index}
                                                            name={field.name}
                                                            className={'ui primary button'}
                                                            type='button'
                                                            onKeyDown={this.handle_search_tab}
                                                            onClick={e => that.getHandler(field.action, e)}
                                                            id={this.props.search_type ? 'advance_control_search_button' + this.props.search_type : 'advance_control_search_button'}
                                                        >
                                                            {field.action}
                                                        </Button>);
                                                    }
                                                    else if (field.action == 'Add Referring Physician') {
                                                        return (<Button
                                                            key={index}
                                                            name={field.name}
                                                            className={'basic'}
                                                            type='button'
                                                            onClick={e => that.getHandler(field.action, e)}
                                                            id='add_referring_physican_add_button'
                                                        >
                                                            {field.action}
                                                        </Button>);
                                                    }
                                                    return (<Button
                                                        key={index}
                                                        name={field.name}
                                                        className={'basic'}
                                                        type='button'
                                                        onClick={e => that.getHandler(field.action, e)}
                                                        id='bg_fff2'
                                                    >
                                                        {field.action}
                                                    </Button>);
                                                })}
                                            </Grid.Column>
                                        </Grid>
                                    </Form>
                                    <div className='mar-t-15'>
                                        {(rows && !this.state.loading) && (
                                            <GridView
                                                row={rows}
                                                onRef={instance => { this.grid_reference = instance; }}
                                                {...grid_config}
                                                isPagination={true}
                                                gridAutoHeight={true}
                                                selectionType={'single'}
                                                onRowDoubleClicked={this.onDoubleClick}
                                                enableColResize={true}
                                                suppressSizeToFit={true}
                                                headerHeight={this.grid_header_height}
                                                get_grid_ref={typeof this.props.get_grid_ref !== 'undefined' ? this.props.get_grid_ref : this.get_grid_ref}
                                                onGridOut={this.on_grid_out}
                                                headerIdForTabNavigation={this.props.headerIdForGridTabNavigation}
                                            />
                                        )}
                                    </div>
                                </div>
                            </Modal.Description>
                        </Modal.Content>
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return { get_control_list: (control_id, token) => dispatch(get_control_list(control_id, token)) };
};

const mapSTateToProps = state => {
    return {
        advance_control_state: state.advanced_control_details,
        user_login_details: state.user_login_details
    };
};

export default connect(
    mapSTateToProps,
    mapDispatchToProps
)(AdvancedControl);
