import * as React from "react";
import { connect } from "react-redux";
import { toastr as toaster } from "react-redux-toastr";
import { withRouter } from 'react-router-dom';
import * as login_constants from "../../login/login_constants";
import { bindActionCreators } from "redux";
import { Button, Form, Grid, Input } from "semantic-ui-react";
import * as global_constant from '../../global_constants';
import { Dimmer, Loader } from 'semantic-ui-react';
import CryptoService from './../../shared/crypto_service/crypto_service';
import { sso_user_authentication } from '../../iam/action/setup_login_action';

const WAITING_TIMEOUT = 2000;
const STATUS_SUCCESS = 1;
export class IamSetupLoginComponent extends React.Component<any, any> {
    MESSAGE_SUCCESSFUL = "Success! You will be directed to WebPT Billing.";
    MESSAGE_USER_PASSWORD_NOT_MATCH = "Either Username or Password is missing.";
    MISSING_UNIVERSAL_LOGIN_PROFILE = "Missing the Universal Login information";
    ERROR_MESSAGE = "Something went wrong, please try again";
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            universalName: "",
            universalEmail: "",
            webptId: "",
            username: "",
            password: "",
            message: "",
            loading: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
        if (sessionStorage[global_constant.universal_profile_session] != null && sessionStorage[global_constant.universal_profile_session] != undefined && sessionStorage[global_constant.universal_profile_session] != "") {
            const { name, email, webptId } = JSON.parse(sessionStorage[global_constant.universal_profile_session]);
            this.setState({ universalName: name, universalEmail: email, webptId: webptId });
        }
        else {
            this.setState({ message: this.MISSING_UNIVERSAL_LOGIN_PROFILE });
            window.open(global_constant.sso_url.webpt_emr_authorize, '_self');
        }
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    show_html_content_toaster = (messages: any) => {
        const toastr_option = {
            preventDuplicates: true,
            position: "bottom-center",
            timeOut: 10000,
            component: () => (
                <div>
                    <span>{messages}</span>
                </div>
            ),
        };
        return toastr_option;
    };

    /**
     * Method to encrypt password
     * @param value Password 
     * @returns Returns the password encrypt
     */
    encrypt_password = async (value) => {
        let encryptedValue = CryptoService.encrypt_password(value);
        return encryptedValue;
    }

    /**
     * Method for logging in with billing user credentials
     */
    on_sign_in = async () => {
        this.setState({ message: "" });

        if (this.state.username.trim() == "" || this.state.password.trim() == "") {
            this.setState({ message: this.MESSAGE_USER_PASSWORD_NOT_MATCH });
        } else if (!this.state.webptId) {
            this.setState({ message: this.MISSING_UNIVERSAL_LOGIN_PROFILE });
        } else {
            this.setState({
                loading: true
            });
            await CryptoService.get_encryption_key();

            var data = await this.encrypt_password(this.state.password.trim());

            await sso_user_authentication(this.state.username.trim(), data, this.state.webptId).then(async (response) => {

                this.setState({
                    loading: false
                });

                const { status, messages } = response.data;

                if (status === STATUS_SUCCESS) {
                    this.setState({ message: "" });
                    setTimeout(() => {
                        toaster.success(
                            "",
                            this.show_html_content_toaster(this.MESSAGE_SUCCESSFUL)
                        );
                    }, WAITING_TIMEOUT);

                    window.open(global_constant.sso_url.webpt_emr_authorize, '_self');

                    return true;
                }

                const errormessage = messages && messages.length > 0 ? messages[0].message : this.ERROR_MESSAGE;
                this.setState({ message: errormessage });
            });
        }
    };

    /**
     * Pressing the cancel button clears the fields
     */
    on_cancel = async () => {
        this.setState({
            message: "",
            username: "",
            password: ""
        });
    };

    handle_change_username = (e) => {
        this.setState({ username: e.target.value });
    };

    handle_change_password = (e) => {
        this.setState({ password: e.target.value });
    };

    render() {
        return (
            <>
                <Dimmer active={this.state.loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <React.Fragment>
                    <Grid.Row>
                        <Grid.Column computer={8}>
                            <div className="center-screen-iam-setup">
                                <div>
                                    <img
                                        src={
                                            login_constants.IMAGES_PATH +
                                            login_constants.WEB_PT_COLOR_LOGO
                                        }
                                        className="logo-webPT-iam-setup"
                                        alt="logo"
                                    />
                                </div>
                                <div className="text-bold-iam-setup">Connect Your Accounts</div>
                                <div className="text-simple-iam-setup">
                                    <p>
                                        Your universal account is not connected to WebPT Billing.
                                    </p>
                                </div>
                                <div className="text-simple-iam-setup">
                                    <p>
                                        Login with your WebPT Billing credentials to complete account
                                        linking.
                                    </p>
                                </div>
                                <div className="same-line-iam-setup">
                                    <div className="box-iam-setup item-box-iam-setup">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th colSpan={2}>
                                                        <h2>
                                                            Universal Login
                                                        </h2>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td align="left">Name:</td>
                                                    <td align="left">{this.state.universalName}</td>
                                                </tr>
                                                <tr>
                                                    <td align="left">Email:</td>
                                                    <td align="left">{this.state.universalEmail}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="box-iam-setup item-box-iam-setup">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th colSpan={2}>
                                                        <h2>
                                                            Billing Login
                                                        </h2>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td align="left">Username:</td>
                                                    <td>
                                                        <Form.Field>
                                                            <Input
                                                                type="text"
                                                                id="username"
                                                                name="username"
                                                                value={this.state.username || ""}
                                                                onChange={(e) => this.handle_change_username(e)}
                                                                className={""}
                                                                error={false}
                                                            />
                                                        </Form.Field>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td align="left">Password:</td>
                                                    <td>
                                                        <Form.Field>
                                                            <Input
                                                                type="password"
                                                                id="password"
                                                                name="password"
                                                                value={this.state.password || ""}
                                                                onChange={(e) => this.handle_change_password(e)}
                                                                className={""}
                                                                error={false}
                                                            />
                                                        </Form.Field>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="error-message-iam-setup">{this.state.message}</div>
                                <div className="same-line-iam-setup">
                                    <div className="item-box-iam-setup">
                                        <Button variant="primary" id="cancel" type="button"
                                            onClick={() => this.on_cancel()}>
                                            Cancel
                                        </Button>
                                    </div>
                                    <div className="item-box-iam-setup">
                                        <Button
                                            primary
                                            id="connect"
                                            type="button"
                                            onClick={() => this.on_sign_in()}
                                        >
                                            Connect
                                        </Button>
                                    </div>
                                </div>
                                <div className="textFooter">
                                    Don´t have a WebPT Login? Contact your company administrator.
                                </div>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </React.Fragment>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};

const mapStateToProps = (state) => {
    return {};
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(IamSetupLoginComponent));
