import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { bindActionCreators } from 'redux';
import { Button, Dimmer, Form, Grid, Loader, Modal } from 'semantic-ui-react';
import { constants } from '../../global_constants';
import AdvancedControl from "../../shared/component/advanced_control";
import Selection from '../../shared/component/selection_component';
import SelectionSearch from '../../shared/component/selection_search_component';
import { search_note_code_configuration } from '../../shared/shared_constants';
import { get_lauch_darkley_key_value } from '../../shared/utility';
import { add_patient_account_notes, get_patient_account_notes_status_action, get_patient_account_note_code } from '../action/patient_action';
import { patient_messages, sticky_code_list } from '../patient_constants';
import { enum_type_of_search } from './../../case/case_constants';
import { quick_search_note_code, search_note_code } from './../../shared/action/autosearch_action';
import AutoSearchComponent from './../../shared/component/auto_search_component';
import { handle_focus_on_tab, set_focus_on_element_with_id } from './../../shared/tab_navigation_utility';

export class AddNewNoteComponent extends React.Component<any, any> {
    _is_mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            show_new_note_modal: this.props.show_new_note_modal,
            new_note_data: {},
            show_modal: false,
            patient_id: props.match.params.patient_id ? props.match.params.patient_id : props.patient_id,
            patient: {
                patient_id: props.match.params.patient_id ? props.match.params.patient_id : props.patient_id,
                note_code_id: 0,
                note_code: '',
                note_text: '',
                show_on_patient: false,
                show_on_charge: false,
                show_on_payment: false,
                show_on_case: false,
                stick_on: "-- Not A Sticky --",
                loading: false,
                company_id: this.props.company_id || this.props.user_login_details.user_data.data.company_id
            },
            is_submitted: false,
            note_code_search_data: {},
            note_code_quick_search_format:
            {
                "title": '',
                "label": '',
                "note_code": ''
            },
            note_code_options: [],
            invalid_code: false
        }
    }

    // function call on componentDidMount for getting account note code for selected patient
    componentDidMount() {
        this._is_mounted = true;
        this.get_patient_account_note_code(this.state.patient_id);
        if (this.props.rsiB34675AccountNoteAccountCodeAssignmentEfficiency) {
            this.on_note_code_search({});
        }
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.patient_id != this.props.patient_id || previousProps.match.params.patient_id != this.props.match.params.patient_id) {
            if (this._is_mounted) {
                this.setState({
                    patient_id: this.props.match.params.patient_id ? this.props.match.params.patient_id : this.props.patient_id
                });
            }
        }
    }

    componentWillUnmount() {
        this._is_mounted = false;

    }

    // get formatted patient account note code from the patient id
    get_patient_account_note_code = async (patient_id) => {
        let is_from_patient = true;
        if (this.props.get_data == false) {
            is_from_patient = false;
        }
        await this.props.get_patient_account_note_code(patient_id, is_from_patient, this.props.user_login_details.user_data.data.token_details.access_token);
        const formatted_patient_account_note_codes = [];
        if (this.props.patient_details.selected_patient_account_notes_codes && this.props.patient_details.selected_patient_account_notes_codes.data) {
            const code_length = this.props.patient_details.selected_patient_account_notes_codes.data.length;
            formatted_patient_account_note_codes.push({ key: 0, text: 'Select', value: 0 })
            for (let i = 0; i < code_length; i++) {
                const code = this.props.patient_details.selected_patient_account_notes_codes.data[i];
                formatted_patient_account_note_codes.push({ key: code.note_code_id, text: code.note_code, value: code.note_code_id })
            }
            if (this._is_mounted) {
                this.setState({
                    formatted_patient_account_note_codes: formatted_patient_account_note_codes
                });
            }
        }

    }
    
    // close the modal clicking the cancel button
    toggle_modal_state = () => {
        if (this._is_mounted) {
            // if modal is going to close then set the focus to parent element
            if (this.state.show_modal) {
                set_focus_on_element_with_id('trigger_add_new_notes_button_patient_header');
            }

            this.setState({
                show_modal: !this.state.show_modal,
                patient: {
                    patient_id: this.props.match.params.patient_id ? this.props.match.params.patient_id : this.props.patient_id,
                    note_code_id: 0,
                    note_code: "",
                    note_text: "",
                    show_on_patient: false,
                    show_on_charge: false,
                    show_on_payment: false,
                    show_on_case: false,
                    stick_on: "-- Not A Sticky --",
                    company_id: this.props.company_id || this.props.user_login_details.user_data.data.company_id
                },
                is_submitted: false,
                loading: false,
                note_code_quick_search_format: {
                    "title": '',
                    "label": '',
                    "note_code": ''
                }
            });
        }
    };

    // save new note data
    save_add_new_note_data = async () => {
        let is_submitted = true;
        const invalid_code = this.state.patient.note_code_id == 0;
        if (
          !this.state.patient.note_text ||
          !this.state.patient.note_text.replace(/\s/g, '') ||
          this.state.patient.note_code_id == 0
        ) {
          toastr.error('', patient_messages.required_fields);
          if (this._is_mounted) this.setState({ loading: false, is_submitted, invalid_code });
          return;
        }
        if (this._is_mounted) {
          this.setState({ loading: true, is_submitted, invalid_code });
        }

        await this.props.add_patient_account_notes(
          this.state.patient_id,
          this.props.user_login_details.user_data.data.token_details.access_token,
          this.state.patient
        );
        this.props.get_patient_account_notes_status({
          updated: true,
          addedNoteType: this.state.patient.stick_on
        });

        if (
          this.props.patient_details.selected_patient_add_account_notes &&
          this.props.patient_details.selected_patient_add_account_notes.data
        ) {
          toastr.success('', patient_messages.note_added);
          this.toggle_modal_state();
          if (this.props.get_account_details) {
            this.props.get_account_details(this.state.patient_id);
          }
        }
    }

    handle_on_open_modal_window = () => {
        setTimeout(() => {
            let closeIcons: NodeListOf<HTMLElement> = document.querySelectorAll('i.close.icon') as NodeListOf<HTMLElement>;
            let closeIcon = closeIcons[closeIcons.length - 1]
            closeIcon.tabIndex = 0;
            closeIcon.id = "add_new_account_notes_model_close_button";
            closeIcon.addEventListener("keydown", function (event) {
                if (event.shiftKey && event.keyCode == 9) {
                    event.preventDefault();
                    set_focus_on_element_with_id("save_button_add_account_note");
                }

                if (event.keyCode == 13) {
                    event.preventDefault();
                    const el = event.target as HTMLElement
                    el.click();
                }
            });
        }, 200)
    }

    on_note_code_grid_row_selection = (selected_row) => {
        let id = '';
        let name = '';
        let codeVal = '';
        let selected_data = '';
        const patient_state = this.state.patient;
        let nc_invalid = this.state.invalid_code;
        if (selected_row) {
            id = selected_row.id;
            codeVal = (selected_row.code == null && selected_row.code == undefined) ? '' : selected_row.code;
            name = (selected_row.description == null && selected_row.description == undefined) ? '' : selected_row.description;
        }
        selected_data = codeVal + " - " + name;
        let format_nc = { "label": '', "title": '', "note_code": '' };
        if (parseInt(id) > 0) {
            // Set Auto search control for action code
            format_nc = {
                "title": id.toString(),
                "note_code": codeVal,
                "label": selected_data
            };
        }
        patient_state.note_code_id = id;
        nc_invalid = false;
        if (this._is_mounted) {
            this.setState({
                note_code_quick_search_format: format_nc,
                patient: patient_state,
                invalid_code : nc_invalid
            });
        }

    }

    //Auto search for Note Code
    render_suggestion_result = (props) => {
        return (
            <div key={props.title} tabIndex={0} className="item_auto_search">
                <div key={props.title} className="fs_13">
                    <span>{props.label}</span>
                </div>
            </div>

        )
    }

    // function/method to handle the when searching of note code occurs
    on_note_code_search = async (params) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        params = params ? params : {};
        params.is_from_patient = true;
        const search_data_note_code = await search_note_code(params, token).then((res) => res.data);
        const search_note_code_result = search_data_note_code.data !== null ? search_data_note_code.data.result : [];
        if (this.props.rsiB34675AccountNoteAccountCodeAssignmentEfficiency) {
          let formatted_options = search_note_code_result.map((item) => {
            item = {
              label: `${item['description']} - ${item['code']}`,
              text: `${item['description']} - ${item['code']}`,
              value: item.id,
              key: item.id
            };
            return item;
          });
          if (this._is_mounted) {
            this.setState({
              note_code_options: formatted_options
            });
          }
        } else {
          let grid_data = {};
          grid_data = {
            ...this.state.grid_conf,
            rows: search_note_code_result,
            column: search_note_code_configuration(enum_type_of_search.note_code + 'grid_header_id')
              .column_defs,
            messages: search_data_note_code.messages
          };
          if (this._is_mounted) {
            this.setState({ note_code_search_data: grid_data });
          }
        }
    }

    // get the quick search note code
    get_note_code_quick_search_data_list = async (search_keyword) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await quick_search_note_code(search_keyword, token);
    }

    // select the item on click suggested items
    on_item_selection = (item) => {
        const state = { ...this.state }
        state.invalid_code = false;
        state.patient.note_code_id = item.title;
        if (this._is_mounted) {
            this.setState({
                patient: state.patient
            });
        }
    }

    // prepare the suggestion list with search result for note code
    prepare_suggestion = (_data) => {
        let formattedList = [];
        let data_length = 0;
        if (_data.data) {
            data_length = _data.data.length;
            _data = _data.data;
        }
        else {
            data_length = _data.length;
        }
        if (_data && data_length) {
            for (let i = 0; i < data_length; i++) {
                const item = _data[i];
                formattedList.push({
                    "title": `${item.id}`,
                    "code": item.code,
                    "name": item.description,

                    "label": (item.code) + " - " + (item.description),
                })
            }
        }
        return formattedList;
    }

    clear_quick_search = () => {
        const state = { ...this.state }
        state.patient.note_code_id = 0;
        state.patient.note_code = "";
        state.patient.note_text = "";
        state.invalid_code = true;
        if (this._is_mounted) {
            this.setState({
                note_code_quick_search_format: {
                    "title": '',
                    "label": '',
                    "note_code": ''
                },
             });
        }
    }

    // multi select change handler
    multi_select_handler = (item) => {
      if (this._is_mounted) {
        this.setState((prev_state) => ({
          invalid_code: item.value === 0 ? true : false,
          patient: {
            ...prev_state.patient,
            note_code_id: item ? item.value : 0
          }
        }));
      }
    };

    render() {
        let is_primary_btn = this.props.is_primary == null || this.props.is_primary == undefined ? true : this.props.is_primary;
        return (
            <Modal
                open={this.state.show_modal}
                onClose={this.toggle_modal_state}
                centered={false}
                onOpen={this.handle_on_open_modal_window}
                className="add-new-note"
                closeIcon
                trigger={<Button id={this.props.id} onKeyDown={(event) => {
                    if (this.props.is_patient_header) {
                        handle_focus_on_tab(event, "account_notes_model_close_button")
                    }
                }
                } onClick={this.toggle_modal_state} type='button' primary={is_primary_btn} basic={!is_primary_btn} >{this.props.add_label}</Button>}
                closeOnDimmerClick={false}
            >
                <Dimmer active={this.state.loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <Modal.Header>New Note</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <div className="App">
                            <div className="common-forms" style={{ padding: '0' }}>
                                <Form>
                                    <Grid>
                                        <Grid.Column computer={16}>
                                            <Form.Field className={this.state.invalid_code && this.state.is_submited ? "advance-quick-search case-reg-form requiredWithBgColor" : ' advance-quick-search case-reg-form'}>
                                                <label>Note <span className={this.state.is_submitted && (!this.state.patient.note_text || !this.state.patient.note_text.replace(/\s/g, '')) ? 'req-alert' : 'req-alert_normal'}>(required)</span></label>
                                                <TextareaAutosize
                                                    name="note_value"
                                                    maxLength={2000}
                                                    autoFocus
                                                    autoComplete="new-note-value"
                                                    className={this.state.is_submitted && (!this.state.patient.note_text || !this.state.patient.note_text.replace(/\s/g, '')) ? 'red-error-thin requiredWithBgColor' : ''}
                                                    onChange={e => {
                                                        const patient_data = this.state.patient;
                                                        patient_data.note_text = e.target.value;
                                                        if (this._is_mounted) {
                                                            this.setState({ patient: patient_data });
                                                        }
                                                    }}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid>
                                        <Grid.Column tablet={8} computer={8}>
                                            <Form.Field className={this.state.invalid_code && this.state.is_submitted ? "advance-quick-search case-reg-form requiredWithBgColor" : ' advance-quick-search case-reg-form'}>
                                                <label>Code <span className={this.state.invalid_code && this.state.is_submitted ? 'req-alert' : 'req-alert_normal'}>(required)</span></label>
                                                {this.props.rsiB34675AccountNoteAccountCodeAssignmentEfficiency ? (
                                                    <SelectionSearch
                                                        id='note_code'
                                                        name='note_code'
                                                        noOptionsMessage={'No Code Found !'}
                                                        defaultValue={this.state.patient.note_code_id}
                                                        isRequired={this.state.invalid_code && this.state.is_submitted}
                                                        options={this.state.note_code_options}
                                                        onChange={this.multi_select_handler}
                                                        autoFocus={false}
                                                        openMenuOnFocus={true}
                                                    />
                                                    ) : (
                                                    <React.Fragment>
                                                        <AdvancedControl
                                                            onGridRowSelection={this.on_note_code_grid_row_selection}
                                                            gridConfig={this.state.note_code_search_data}
                                                            controlId={constants.advanced_control_type.note_code}
                                                            onSearch={this.on_note_code_search}
                                                            search_type={enum_type_of_search.note_code}
                                                            headerIdForGridTabNavigation={enum_type_of_search.note_code + 'grid_header_id'}
                                                        />
                                                        <AutoSearchComponent
                                                            control_id={constants.advanced_control_type.note_code}
                                                            default_value={this.state.note_code_quick_search_format}
                                                            errorMessage={'No Note Code Found !'}
                                                            prepareRenderList={this.render_suggestion_result}
                                                            getList={this.get_note_code_quick_search_data_list}
                                                            prepareDataList={(data) => this.prepare_suggestion(data)}
                                                            selectresult={(item) => this.on_item_selection(item)}
                                                            is_focus={false}
                                                            show_clear_search={true}
                                                            clear_search={() => this.clear_quick_search()}
                                                            errorClass={
                                                                this.state.invalid_code && this.state.is_submitted ? 'req-background-inp' : ''
                                                            }
                                                        />
                                                    </React.Fragment>
                                                )}
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>Stick on</label>
                                                <Selection
                                                    id={'id_stick_on'}
                                                    defaultValue={this.state.patient.stick_on}
                                                    options={sticky_code_list}
                                                    onChange={(value) => {
                                                        const state = { ...this.state }
                                                        state.patient.stick_on = value;
                                                        state.patient.show_on_case = value == 'Case' ? true : false;
                                                        state.patient.show_on_charge = value == 'Charge' ? true : false;
                                                        state.patient.show_on_patient = value == 'Patient' ? true : false;
                                                        state.patient.show_on_payment = value == 'Payment' ? true : false;
                                                        if (this._is_mounted) {
                                                            this.setState({
                                                                patient: state.patient
                                                            });
                                                        }
                                                    }}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                </Form>
                                <Grid style={{ marginTop: '20px' }}>
                                    <Grid.Column computer={16} textAlign="right">
                                        <Button basic onClick={this.toggle_modal_state}>Cancel</Button>
                                        <Button type='submit' id='save_button_add_account_note'
                                            onKeyDown={(event) => handle_focus_on_tab(event, "add_new_account_notes_model_close_button")} primary onClick={this.save_add_new_note_data}>Save</Button>
                                    </Grid.Column>
                                </Grid>
                            </div>
                        </div>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        );
    }
}

// Function used for getting data from actions
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        add_patient_account_notes: add_patient_account_notes,
        get_patient_account_note_code: get_patient_account_note_code,
        get_patient_account_notes_status: get_patient_account_notes_status_action
    }, dispatch)
}

// Function used for getting data from updated states
const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        patient_details: state.patient_details,
        rsiB34675AccountNoteAccountCodeAssignmentEfficiency: get_lauch_darkley_key_value(state.launch_darkly, "rsiB34675AccountNoteAccountCodeAssignmentEfficiency")
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddNewNoteComponent))