import * as global_constants from '../../global_constants';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request';

export const get_company_list = (token, company_name, company_code) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.company_urls.company_search}`;   

    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            name: company_name,
            code: company_code
        }
    })
    return request.make(request_config)
}