import { header_template } from '../../admin/constants/constants';
import { text_comparator, mm_dd_yyyy_comparator, currency_comparator } from '../../shared/component/grid/comparators';

export const constant = {
  adjustment_search: '/constant/eob_claim_adj_code/get_eob_claim_code'
}

export const messages = {
  date_validation: '"Date of Service Through" must be on or after the "Date of Service From".',
  transfer_insurance_not_fill: 'Please select an insurance to transfer to.',
  less_payment_amount: 'Payment Amount is not equal to the Posted Amount.',
  close_open_month: 'Month already closed. Please use a current date.',
  required_field: 'Please fill all the mandatory fields or check highlighted field values.',
};

export const bulk_payment_updates = (props) => {
  return {
    title: 'Bulk Payment Updates',
    title_search_criteria: {
      selected_company: {
        title: props.user_login_details.user_data.data.company_id,
        code: props.user_login_details.user_data.data.gpms_code,
        name: props.user_login_details.user_data.data.company_name,
        label: `${props.user_login_details.user_data.data.gpms_code} - ${props.user_login_details.user_data.data.company_name}`
      },
      selected_patient: {
        label: '',
        title: ''
      },
      date_of_service_from: null,
      date_of_service_end: null,
      // dropdown
      place_of_service: 'All',
      payment_type: 'All',
      // Multi Select
      insurance: '0 Selected',
    },
    initial_search_criteria: {
      company_id: props.user_login_details.user_data.data.company_id,
      patient_id: '',
      date_of_service_from: null,
      date_of_service_end: null,
      payment_type: "0",
      // Multi Select
      place_of_service: [],
      insurance: [],
      selected_company: {
        title: `${props.user_login_details.user_data.data.company_id}`,
        name: props.user_login_details.user_data.data.company_name,
        label: `${props.user_login_details.user_data.data.gpms_code} - ${props.user_login_details.user_data.data.company_name}`
      },
      selected_patient: {
        label: '',
        title: ''
      }
    },
    initial_update_criteria: {
      company_id: props.user_login_details.user_data.data.company_id,
      check_card_auth_number_update: null,
      batch_update: null,
      adjustment_code: null,
      payment_code: null,
      deposit_date: null,
    },
    messages: {
      update_action_success: 'Changes saved successfully.',
      empty_error: 'You must select at least one record to',
      empty_update_error: 'You must select at least one record to update.',
      mismatch_update_error: 'At least one charge selection is invalid. Only valid charges are being processed.',
      update_msg: 'You must select at least one record to update.',
      valid_update_msg: 'You must select at least one valid record.',
      r2_valid_update_msg: '0 Charge(s) were updated, Total Charge Amount: $0.00.',
      numeric_allow: 'Only Numeric characters are allowed.',
      mandatory_fields: "Either 'Check/Credit Card Auth Number', 'Deposit Date Range', 'Batch Number' or 'Patiend ID' must be entered.",
      date_range_exceed: 'Deposit Date range cannot exceed',
      date_range_invalid: '"Through Date" must be on or after the "From Date".',
      date_range_mandatory_fields: 'Please fill all the mandatory fields or check highlighted field values.',
    },
    column_defs: [
      {
        headerName: "Select/Deselect All",
        headerComponentParams: header_template,
        field: "checkbox",
        headerCheckboxSelection: true,
        checkboxSelection: true,
        suppressFilter: true,
        suppressSorting: true,
        suppressSizeToFit: true,
        resizable: false,
        suppressMovable: true,
        minWidth: 40,
        maxWidth: 40,
        width: 40,
      },
      {
        headerName: "Check/Credit Card Auth Number",
        headerComponentParams: header_template,
        field: "credit_Card_Auth_Number",
        type: "string",
        unSortIcon: true,
        filter: "textFilter",
        comparator: text_comparator
      },
      {
        headerName: "Deposit Date",
        headerComponentParams: header_template,
        field: "deposit_Date",
        type: "date",
        filter: 'dateFilter',
        unSortIcon: true,
        comparator: mm_dd_yyyy_comparator,
        cellRenderer: (params) => deposit_date_cell_renderer(params, props),
      },
      {
        headerName: "Batch Number",
        headerComponentParams: header_template,
        field: "batch_Number",
        type: "string",
        filter: "textFilter",
        unSortIcon: true,
        comparator: text_comparator
      },
      {
        headerName: "Patient ID",
        headerComponentParams: header_template,
        field: "patient_Id",
        type: "string",
        filter: "textFilter",
        unSortIcon: true,
        tooltipField: "patient_Id",
        comparator: text_comparator
      },
      {
        headerName: "Last Name",
        headerComponentParams: header_template,
        field: "last_Name",
        type: "string",
        tooltipField: "last_Name",
        filter: "textFilter",
        unSortIcon: true,
        comparator: text_comparator
      },
      {
        headerName: "First Name",
        headerComponentParams: header_template,
        field: "name",
        type: "string",
        filter: "textFilter",
        tooltipField: "name",
        unSortIcon: true,
        comparator: text_comparator
      },
      {
        headerName: "Location",
        headerComponentParams: header_template,
        field: "location",
        tooltipField: "location",
        type: "string",
        filter: "textFilter",
        unSortIcon: true,
        resizable:true, 
        comparator: text_comparator
      },
      {
        headerName: "Provider",
        headerComponentParams: header_template,
        field: "provider",
        tooltipField: "provider",
        type: "string",
        filter: "textFilter",
        unSortIcon: true,
        comparator: text_comparator
      },
      {
        headerName: "Payment Method",
        headerComponentParams: header_template,
        field: "payment_Type",
        tooltipField: "payment_Type",
        type: "string",
        filter: "textFilter",
        unSortIcon: true,
        comparator: text_comparator
      },
      {
        headerName: "Transaction Type",
        headerComponentParams: header_template,
        field: "transaction_Type",
        tooltipField: "transaction_Type",
        type: "string",
        filter: "textFilter",
        unSortIcon: true,
        comparator: text_comparator
      },
      {
        headerName: "Payment Code",
        headerComponentParams: header_template,
        field: "payment_Code",
        tooltipField: "payment_Code",
        type: "string",
        filter: "textFilter",
        unSortIcon: true,
        comparator: text_comparator
      },
      {
        headerName: "Total Payment Amount",
        headerComponentParams: header_template,
        field: "total_Payment",
        tooltipField: "total_Payment",
        type: "currency",
        filter: "currencyFilter",
        unSortIcon: true,
        comparator: currency_comparator
      }]
  };
};

const deposit_date_cell_renderer = (params, props) => {
  let eDiv = document.createElement("span");

  eDiv.setAttribute('id', `${params.colDef.field}_${params.value}`);
  eDiv.innerHTML = params.value;

  if (params.data.is_Closed_Month == 1) {
    eDiv.style.fontWeight  = 'bold';
    eDiv.title = 'This Deposit Date is in a closed month.';
  }

  return eDiv;
};
