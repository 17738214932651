import * as React from 'react';
import { connect } from 'react-redux';
import { toastr as toaster } from 'react-redux-toastr';
import TextareaAutosize from 'react-textarea-autosize';
import { Button, Dimmer, Form, Grid, Input, Checkbox, Loader } from 'semantic-ui-react';
import * as global_constants from '../../../global_constants';
import PromptNavigation from '../../../shared/component/prompt_navigation_component';
import AdvanceQuickSearch from "../../constants/component/claim_adjustment_code_advanced_search";
import * as session_storage from '../../../shared/session_storage_utility';
import { set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import * as constants_actions from '../action/constants_action';
import * as constants from '../constants';
import { LoaderComponent } from '../../../shared/component/loading_component'
import {
    advanced_search_payer,
    advanced_search_rejection_group,
    quick_search_payer,
    quick_search_rejection_group
} from '../../../shared/action/shared_action';

class StatusCodesAddEditComponent extends React.Component<any, any> {

    _is_mounted = false;

    constructor(props) {
        super(props);
        // Define initial state value.
        this.state = {
            show_render: true,
            form_object: {},
            is_loading: false,
            is_submitted: false,
            required_error: {},
            required_filed: {},
            by_pass_prompt: false,
            is_save_button_disabled: false,
            group_code: null,
            payer: null,
            payer_search_data: null,
            group_code_search_data: null,
            group_code_selected_row: {},
            payerid_selected_row: {}
        };
    }

    // Define initial properties and its value.
    form_object: any = {
        id: 0,
        code: '',
        description: '',
        group_id: 0,
        payer_id: 0,
        is_active: true
    }

    required_filed: any = {
        code: true,
    };

    page_name: string = 'status_code';
    disable_fields = false;
    page_metadata: any;
    token: string = '';
    status_code_id: number = 0;
    is_grid_visible = false;
    go_next: boolean = false;
    is_cancel: boolean = false;
    initial_form_object: any = {};
    search_criteria: any = {
        code: '',
        description: '',
        group_id: '',
        payer_id: '',
        include_inactive: false
    };

    search_enum = {
        payer: 'payer',
        group_code: 'group_code'
    };

    // Fetching the page_name and state.
    UNSAFE_componentWillMount = () => {
        const { user_data } = this.props.user_login_details;
        this.token = user_data.data.token_details.access_token;
        this.status_code_id = this.props.match.params && this.props.match.params.id ? this.props.match.params.id : 0;
        //Verifying page_name is correct or not.
        this.initial_form_object = this.init_fields(this.form_object);
        this.page_metadata = constants.status_code;
    };

    componentDidMount = () => {
        this._is_mounted = true;
        document.body.classList.add('admin-framework');
        let elem = document.getElementById('search-criteria-container');
        let all_form_elem = elem.getElementsByTagName('input');
        all_form_elem[0].focus();
        this.setState({
            form_object: this.form_object,
            group_code: this.page_metadata.group_code,
            payer: this.page_metadata.payer
        });

        if (this.status_code_id) {
            this.get_data(this.status_code_id);
        }
    };

    componentWillUnmount = () => {
        document.body.classList.remove('admin-framework');
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[2] !== this.page_name && session_storage.remove(`constant_${this.page_name}_criteria`);
        }
    };

    init_fields = (value) => {
        return {
            id: value.id || 0,
            code: value.code || '',
            description: value.description || '',
            group_id: value.group_id || 0,
            payer_id: value.payer_id || 0,
            is_active: value.is_active || false
        };
    }

    // Get the data on the basis of ID.
    get_data = async (id) => {
        let url = this.page_metadata.api.get.url;
        url = url.replace('id_value', id);
        this.setState({
            is_loading: true
        });
        constants_actions.get_data(this.token, url).then(
            response => {
                if (response.data && response.data.data) {
                    this.form_object = this.init_fields(response.data.data);
                    this.initial_form_object = { ...this.form_object };
                    let payer = {
                        payer_key_id: response.data.data.payer_id,
                        name: response.data.data.payer_name,
                        description: response.data.data.payer_description
                    }

                    let group_code = {
                        e277_status_cd_grp_id: response.data.data.group_id,
                        name: response.data.data.group_name,
                        description: response.data.data.group_description
                    }

                    this.setState({
                        form_object: this.form_object,
                        is_loading: false,
                        payerid_selected_row: payer,
                        group_code_selected_row: group_code
                    });
                }
            },
            error => {
                this.setState({
                    is_loading: false
                });
            }
        );
    };

    //Input change handler.
    input_change_handler = e => {
        const { value, checked, type, name } = e.target;
        if (type == 'checkbox') {
            this.form_object[name] = checked;
        } else {
            this.form_object[name] = value;
        }

        this.setState(prev_state => ({
            required_error: {
                ...prev_state.required_error,
                [name]: this.is_error(value, name)
            },
            form_object: this.form_object
        }));
    };

    // validate each input box on change event
    is_error = (value, name) => {
        if (this.required_filed[name] && !value) {
            return true;
        }
        return false;
    };

    is_valid = () => {
        let is_error = false;
        let required_error = { ...this.state.required_error };

        for (let name in this.form_object) {
            if (name !== 'is_active' && this.form_object[name]) {
                this.form_object[name] = this.form_object[name].toString().trim();
            }

            if (this.required_filed[name] && !this.form_object[name]) {
                required_error[name] = true;
                is_error = true;
            }
        }

        if (is_error) {
            this.setState({
                required_error,
            });
            toaster.error('', constants.mandatory_fields);
        }
        return is_error;
    }

    //Save the form object and redirect it to search
    save_handler = (e, from_pop_up) => {
        this.setState({ is_submitted: true });
        this.go_next = false;
        //Check validation
        if (this.is_valid()) {
            return;
        }

        //Start loader
        this.setState({
            is_loading: true,
            is_save_button_disabled: true
        });
        session_storage.set('status_code_search_criteria', {});
        if (this.status_code_id) {
            //Update
            let url = this.page_metadata.api.update.url;
            url = url.replace('id_value', this.status_code_id);
            constants_actions.update_data(this.token, url, this.form_object).then(
                response => {
                    if (response.data && response.data.data) {
                        this.initial_form_object = { ...this.form_object };
                        this.go_next = true;
                        toaster.success('', this.page_metadata.validation_message.update);
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false
                        }, () => {
                            if (!from_pop_up && this.go_next) {
                                this.go_next = false;
                                this.props.history.goBack();
                            }
                        });
                    } else {
                        if (response.data.messages[0].message.indexOf('<br') > -1) {
                            this.show_html_content_toaster(response.data.messages[0].message);
                        } else {
                            toaster.error('', response.data.messages[0].message);
                        }
                        this.go_next = false;
                        if (this._is_mounted) {
                            this.setState({
                                is_loading: false,
                                is_save_button_disabled: false
                            });
                        }
                    }
                },
                error => {
                    if (this._is_mounted) {
                        this.go_next = false;
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false
                        });
                    }
                    if (error.response.data) toaster.error('', error.response.data.messages[0].message);
                    toaster.error('', this.page_metadata.validation_message.failure);
                });
        } else {
            //Add
            let url = this.page_metadata.api.add.url;
            constants_actions.add_data(this.token, url, this.form_object).then(
                response => {
                    if (response.data && response.data.data) {
                        this.initial_form_object = { ...this.form_object };
                        this.go_next = true;
                        toaster.success('', this.page_metadata.validation_message.save);
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false
                        }, () => {
                            if (!from_pop_up && this.go_next) {
                                this.go_next = false;
                                this.props.history.goBack();
                            }
                        });
                    } else {
                        if (response.data.messages[0].message.indexOf('<br') > -1) {
                            this.show_html_content_toaster(response.data.messages[0].message);
                        } else {
                            toaster.error('', response.data.messages[0].message);
                        }
                        this.go_next = false;
                        if (this._is_mounted) {
                            this.setState({
                                is_loading: false,
                                is_save_button_disabled: false
                            });
                        }
                    }
                },
                error => {
                    if (this._is_mounted) {
                        this.go_next = false;
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false
                        });
                    }
                    if (error.response.data) {
                        toaster.error('', error.response.data.messages[0].message);
                    }
                    toaster.error('', this.page_metadata.validation_message.failure);
                });
        }
    };

    //Function used to Run Report based on controllers values.
    cancel_handler = () => {
        this.initial_form_object = { ...this.form_object };
        this.setState({ by_pass_prompt: true }, () => this.props.history.goBack());
    };

    // Show multiple messages
    show_html_content_toaster = msg => {
        return {
            component: () => (<div><div dangerouslySetInnerHTML={{ __html: msg }} /></div>),
            timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        };
    };

    is_data_unsaved = () => {
        if (JSON.stringify(this.initial_form_object) !== JSON.stringify(this.form_object)) {
            return true;
        }
        return false;
    }



    update_data = (data, type) => {
        if (type == this.search_enum.group_code) {
            this.form_object.group_id = data ? data.e277_status_cd_grp_id : '';
            this.setState({
                form_object: this.form_object,
                group_code_selected_row: data
            });
        } else {
            this.form_object.payer_id = data ? data.payer_key_id : '';
            this.setState({
                form_object: this.form_object,
                payerid_selected_row: data
            });
        }
    }

    on_quick_search = async (search_keyword: any, type: string) => {
        let result: any;
        if (type == this.search_enum.payer) {
            result = await quick_search_payer({ keyword: search_keyword }, this.token);
        } else {
            result = await quick_search_rejection_group({ keyword: search_keyword }, this.token);
        }
        result.data.data = result.data && result.data.data ? result.data.data.sort((a, b) => a.name.localeCompare(b.name)) : result;
        return result;
    };

    on_advanced_search = async (params, type: string) => {
        if (type == this.search_enum.payer) {
            params.Description = params.Description || ""
            params.Outbound_id = params.Outbound_id || ""
            params.name = params.name || ""
            params.Inbound_id = params.Inbound_id || ""
            advanced_search_payer(params, this.token).then((res) => {
                if (this._is_mounted) {
                    this.setState({
                        payer_search_data: {
                            rows: res.data.data,
                            column: constants.insurance.payerid_column_defs
                        }
                    })
                }
            }, error => {
                if (error.response && error.response.data && error.response.data.messages) {
                    toaster.error('', error.response.data.messages[0].message);
                }
            });

        } else {
            params.Description = params.Description || "";
            params.Name = params.Name || "";
            params.show_inactive = params.show_inactive || false;
            advanced_search_rejection_group(params, this.token).then((res) => {
                if (this._is_mounted) {
                    this.setState({
                        group_code_search_data: {
                            rows: res.data.data,
                            column: constants.status_code.group_code_column_defs
                        }
                    });
                }
            }, error => {
                if (error.response && error.response.data && error.response.data.messages) {
                    toaster.error('', error.response.data.messages[0].message);
                }
            });
        }
    };

    //It renders report grid and its search controls.
    render() {
        const { form_object, required_error, is_submitted } = this.state;
        return (
            this.state.show_render && (
                <React.Fragment>
                    {!this.state.by_pass_prompt &&
                        (<PromptNavigation
                            is_data_changed={this.is_data_unsaved()}
                            save={e => this.save_handler(e, true)}
                            go_next_location={this.go_next}
                            history={this.props.history}
                        />)}
                    <LoaderComponent loading={this.state.is_loading}>
                        <div className={'admin-wrapper view-edit Locations ' + this.page_metadata.id} style={loaction_css.locations_id}>
                            <Grid className='headerGrid' style={loaction_css.headerGrid}>
                                <Grid.Column computer={16}>
                                    <h3 className='ui header left aligned'>{this.page_metadata.add_header}</h3>
                                </Grid.Column>
                            </Grid>
                            <div className='wrapper' style={loaction_css.wrapper}>
                                <Form autoComplete='off' name='view_edit_form' className='common-forms' style={loaction_css.common_forms}>
                                    <div id='admin-scrollable-area' style={loaction_css.scrollbar_area}>
                                        <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container' style={loaction_css.patient_search_form}>
                                            <Grid>
                                                <Grid.Column tablet={8} computer={4}>
                                                    <Form.Field>
                                                        <label>
                                                            Code{' '}
                                                            {this.required_filed.code && (
                                                                <span className={!form_object.code && this.required_filed.code && is_submitted && required_error.code ? 'req-alert' : 'req-alert_normal'}>
                                                                    (required)
                                                                 </span>
                                                            )}
                                                        </label>
                                                        <Input
                                                            autoComplete='off'
                                                            id='code'
                                                            name='code'
                                                            type='text'
                                                            maxLength={50}
                                                            onChange={e => this.input_change_handler(e)}
                                                            value={this.form_object['code'] ? this.form_object['code'] : ''}
                                                            className={is_submitted && (this.state.required_error.code || (!form_object.code && this.required_filed.code)) ? 'req-border-inp' : ''}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column tablet={8} computer={8}>
                                                    <Form.Field>
                                                        <label>Description</label>
                                                        <TextareaAutosize
                                                            autoComplete='off'
                                                            id='description'
                                                            name='description'
                                                            maxLength={255}
                                                            type='text'
                                                            value={this.form_object['description'] ? this.form_object['description'] : ''}
                                                            onChange={e => this.input_change_handler(e)}
                                                            onKeyDown={(e) => {
                                                                if (e.keyCode == 13 || e.which == 13) {
                                                                    e.preventDefault();
                                                                    return false;
                                                                }
                                                            }}
                                                            onFocus={(e) => { e.currentTarget? e.currentTarget.select():''}}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>

                                                <Grid.Column tablet={8} computer={4}>
                                                    <Form.Field className="advance-quick-search">
                                                        <label>Group Code</label>
                                                        <AdvanceQuickSearch
                                                            control={this.state.group_code ? this.state.group_code : null}
                                                            update_data={this.update_data}
                                                            search_result={this.state.group_code_search_data}
                                                            on_quick_search={this.on_quick_search}
                                                            on_advanced_search={this.on_advanced_search}
                                                            selected_row={this.state.group_code_selected_row}
                                                            headerIdForGridTabNavigation={constants.status_code.group_code_header_id}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column tablet={8} computer={4}>
                                                    <Form.Field className="advance-quick-search">
                                                        <label>Payer</label>
                                                        <AdvanceQuickSearch
                                                            control={this.state.payer ? this.state.payer : null}
                                                            update_data={this.update_data}
                                                            search_result={this.state.payer_search_data}
                                                            on_quick_search={this.on_quick_search}
                                                            on_advanced_search={this.on_advanced_search}
                                                            selected_row={this.state.payerid_selected_row}
                                                            headerIdForGridTabNavigation={constants.insurance.payerid_header_id}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column tablet={8} computer={4}>
                                                    <Form.Field>
                                                        <label>&nbsp;</label>
                                                        <Checkbox
                                                            name='is_active'
                                                            id='is_active'
                                                            onChange={e => this.input_change_handler(e)}
                                                            checked={this.form_object['is_active'] ? this.form_object['is_active'] : false}
                                                            label={'Is Active'}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid>
                                        </div>
                                    </div>
                                    <div className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area' id='applicationFooterSticky' style={loaction_css.footerArea}>
                                        <Button
                                            id='cancel_button_id'
                                            type='button' onClick={this.cancel_handler}
                                            basic>Cancel</Button>
                                        <Button
                                            id='save_button_id'
                                            type='button'
                                            disabled={this.state.is_save_button_disabled}
                                            onKeyDown={set_focus_to_app_header}
                                            onClick={e => this.save_handler(e, false)}
                                            primary style={loaction_css.save_button}>Save</Button>
                                    </div>
                                </Form>
                            </div>
                            </div>
                    </LoaderComponent>
                </React.Fragment>
            )
        );
    }
}
// Css Style
const loaction_css = {
    locations_id: {
        paddingBottom: 0
    },
    headerGrid: {
        marginTop: 0,
        marginBottom: 0,
        paddingRight: 15
    },
    wrapper: {
        borderTop: 0,
        marginBottom: 0
    },
    common_forms: {
        paddingTop: 0,
        height: '100%',
        paddingBottom: 0,
        display: 'flex',
        flexDirection: 'column'
    } as React.CSSProperties,

    patient_search_form: {
        paddingBottom: 0,
        flexBasis: 'auto',
        borderColor: '#cccccc',
        borderStyle: 'solid',
        borderWidth: '1px 0px 0px',
        margin: '0 0 15px 0px',
        padding: '25px 15px 0 0'
    },
    footerArea: {
        paddingLeft: 0,
        paddingBottom: 0,
        marginBottom: 12,
        paddingRight: 25,
        paddingTop: 20
    },
    scrollbar_area: {
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column'
    } as React.CSSProperties,
    save_button: {
        marginRight: 0
    }
};
//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
        shared_details: state.shared_details,
    };
};

export default connect(mapStateToProps)(StatusCodesAddEditComponent);