import * as React from 'react';
import { Button, Form, Grid } from 'semantic-ui-react';
import { handle_focus_on_tab, handle_focus_on_shift_tab } from '../tab_navigation_utility';

class SearchCriteriaComponent extends React.Component<any, any> {
    [x: string]: any;

    constructor(props) {
        super(props);
        this.state = {
            company_name: '',
            company_code: ''
        }
    }

    onClick = () => {
        const objName = {
            type: "startsWith",
            filter: this.state.company_name
        };
        const objCode = {
            type: "startsWith",
            filter: this.state.company_code
        };
        this.props.onSearch({ ...this.state });
    }

    clearFilter = () => {
        this.setState({ company_name: '', company_code: '' })
        this.props.onClear();
    }
    onCancelClick = () => {
        this.props.onCancel();
    }

    render() {
        return (
            <div className="App">
                <div className="common-forms" style={{ padding: '0' }}>
                    <Form>
                <Grid>
                    <Grid.Column>
                           
                            <Grid>
                                <Grid.Column tablet={8} computer={8}>
                                    <Form.Field>
                                        <label>Company Name</label>
                                            <input value={this.state.company_name}
                                                className="prompt" autoFocus onChange={e => this.setState({ company_name: e.target.value })} />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column tablet={8} computer={8}>
                                    <Form.Field>
                                            <label>Company Code</label>
                                            <input onKeyDown={(event) => { handle_focus_on_tab(event, "search_criteria_button_cancel") } } id="search_criteria_input_company_code" value={this.state.company_code}
                                            className="prompt" onChange={e => this.setState({ company_code: e.target.value })} />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid>
                       
                    </Grid.Column>
                </Grid>
                <Grid>
                        <Grid.Column computer={16} textAlign="right">                           
                                <Button id="search_criteria_button_search" onKeyDown={(event) => {
                                    this.props.onSearchTab(event);
                                    handle_focus_on_shift_tab(event, "search_criteria_button_clear");
                      }} type='submit' primary onClick={this.onClick}>Search</Button>
                    </Grid.Column>
                    </Grid>
                    </Form>
                    <Grid style={{ marginTop: '-58px',  paddingRight:'85px'}}>
                        <Grid.Column computer={16} textAlign="right">
                            <Button tabIndex={-1} onKeyDown={(event) => {
                                handle_focus_on_tab(event, "search_criteria_button_clear");
                                handle_focus_on_shift_tab(event, "search_criteria_input_company_code");
                            }} basic id="search_criteria_button_cancel" onClick={this.onCancelClick}>Cancel</Button>
                            <Button tabIndex={-1} onKeyDown={(event) => {
                                handle_focus_on_tab(event, "search_criteria_button_search");
                                handle_focus_on_shift_tab(event, "search_criteria_button_cancel");
                            }} id="search_criteria_button_clear" type='reset' basic onClick={this.clearFilter}>Clear</Button>

                        </Grid.Column>
                    </Grid>

                </div>
            </div>



        );
    }
}

export default SearchCriteriaComponent;