import * as React from 'react';
import { Accordion, Button, Form, Grid, Icon } from 'semantic-ui-react';
import { handle_click_on_enter } from './../../shared/tab_navigation_utility';

interface IProps {
  run_report_handler: React.FormEventHandler;
  input_fields: any[];
  controls_is_expended: boolean;
  controls_toggle: React.MouseEventHandler;
  set_control_type: Function;
  show_parm_level_2: any;
  handle_focus_for_grid: React.KeyboardEventHandler;
  is_search_button_disabled: boolean;
  clear_handler: React.MouseEventHandler;
}

const ReportController = (props: IProps) => {
  let isDefaultFocus = true;
  let is_button_visible = false;
  return (
    <Form onSubmit={props.run_report_handler} autoComplete={'off'}>
      {props.input_fields && (
        <Grid>
          <Grid.Column computer={16} tablet={16} textAlign='left' className='accordionColumn'>
            <Accordion fluid styled>
              <Accordion.Title active={props.controls_is_expended} index={0} onClick={props.controls_toggle}>
                <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name='angle right' />
                Search Criteria
              </Accordion.Title>
              <Accordion.Content active={props.controls_is_expended}>
                <Grid>
                  {props.input_fields.map(
                    (item: { invisible: any; control_type: string; label_name: string; sequence: string }) => {
                      let updateDefaultFocus = false;
                      if (isDefaultFocus && !item.invisible && item.control_type.toLowerCase() != 'blank') {
                        updateDefaultFocus = true;
                        isDefaultFocus = false;
                      }
                      return (
                        !item.invisible &&
                        item.control_type.toLowerCase() != 'blank' &&
                        (is_button_visible = true) && (
                          <Grid.Column tablet={7} computer={4} key={item.label_name + '_' + item.sequence}>
                            {props.set_control_type(item.control_type, item, updateDefaultFocus)}
                          </Grid.Column>
                        )
                      );
                    }
                  )}
                </Grid>
              </Accordion.Content>
            </Accordion>
          </Grid.Column>
        </Grid>
      )}
      {props.show_parm_level_2 ? (
        <Grid>
          <Grid.Column tablet={16} computer={16} textAlign='right' className='button_area'>
            <Button
              id='create_report_button'
              className='primary'
              onClick={props.run_report_handler}
              type='submit'
              onKeyDown={props.handle_focus_for_grid}
              style={{ marginRight: '0' }}
              disabled={props.is_search_button_disabled}
              content={'Continue'}
            />
          </Grid.Column>
        </Grid>
      ) : (
        is_button_visible && (
          <Grid>
            <Grid.Column tablet={16} computer={16} textAlign='right' className='button_area'>
              <Button type='reset' id='clear_report_button' basic onClick={props.clear_handler} content={'Clear'} />
              <Button
                id='create_report_button'
                className='primary'
                onClick={props.run_report_handler}
                type='submit'
                onKeyDown={props.handle_focus_for_grid}
                style={{ marginRight: '0' }}
                disabled={props.is_search_button_disabled}
                content={'Search'}
              />
            </Grid.Column>
          </Grid>
        )
      )}
    </Form>
  );
};

export default ReportController;
