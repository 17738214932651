import * as React from 'react';
import { connect } from "react-redux";
import { Checkbox, Form, Input } from 'semantic-ui-react';
import Selection from '../../shared/component/selection_component';
import * as billing_constant from '../billing_constants';
import TextareaAutosize from 'react-textarea-autosize';


class ClaimIndicatorRowComponent extends React.Component<any, any> {
    [x: string]: any;
    _is_mount = false;
    initial_form_object: any = {};


    // Get innitalise data when component created
    constructor(props) {
        super(props);
        this.state = {
            form_object: this.props.data,
            // status_disabled: this.props.data && this.props.data.not_used_for_billing || false,         

        };
    }

    UNSAFE_componentWillMount() {
        this.initial_form_object = { ...this.state.form_object };
    }

    componentDidMount() {
        this._is_mount = true;
        if (this.props.onRef) {
            this.props.onRef(this);
        }
        if (this.state.form_object && this.state.form_object.insurance_code) {
            if (this._is_mount) {

            }
        }
    }

    //Component update function
    componentDidUpdate(previousProps, previousState) {
        if ((previousState.form_object.provider_billing_id === this.state.form_object.provider_billing_id) &&
            JSON.stringify(previousState.form_object) !== JSON.stringify(this.state.form_object)) {
            // this.props.on_unsave_billing(this.props.row_count, JSON.stringify(this.initial_form_object) !== JSON.stringify(this.state.form_object))
        } else if (this.props.data != previousProps.data) {
            let data = { ...this.props.data };

            this.setState({
                form_object: data,

            });
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(undefined);
        }
        this._is_mount = false;
    }

    //Get row number
    get_row_num = () => this.props.row_num;
    //Get data retruns updated data item.
    get_updated_data = () => ({ ...this.state.form_object });
    //Get data retruns data item.
    get_data = () => ({ ...this.props.data });


    //checkbox change for - active status
    handle_status_change = e => {
        const { checked, name } = e.target;
        var data = { ...this.state.form_object }
        data[name] = checked;
        this.setState(prev_state => ({
            form_object: data,
        }));
    }

    //Dropdown change handler.
    dropdown_change_handler = (data, e) => {
        const { value, name } = e.target;
        var data = { ...this.state.form_object };
        data[name] = value == ' ' ? '' : value;

        this.setState(prev_state => ({
            form_object: data
        }));
    };

    //Insurance class dropdown change
    on_change_dropdown_id_handler = (data, e) => {
        const { value, name } = e.target;
        var data = { ...this.state.form_object };
        if (name == 'late_reason' && value != '-999') {
            e.target.className = '';
           // data['claim_action'] = 'Late';
        }
        data[name] = value == ' ' ? null : Number(value);

        this.setState(prev_state => ({
            form_object: data
        }));
    };
    on_change_dropdown_string_handler = (data, e) => {
        const { value, name } = e.target;
        e.target.className = '';
        var data = { ...this.state.form_object };
        data[name] = value == ' ' ? null : value;
        data[name+'_for_sorting'] = value == ' ' ? 'Select' : value;

        this.setState(prev_state => ({
            form_object: data
        }));
    };
    //Input change handler.
    input_change_handler = e => {
        const { value, name } = e.target;
        var data = { ...this.state.form_object };
        data[name] = value
        this.setState({
            form_object: data,
        });
    };

    //Render function
    render() {
        //    console.log(this.props.column_fields);
        return (<tr>{this.props.column_fields.map((cal, index) => (this[cal.renderer](`${cal.field}_renderer`)))}</tr>);
    }

    /**
     * Column cell renderers
     */
    //charge_renderer
    charge_renderer = (key_id) => (
        <td data-label="charge_id" key={key_id}>
            {this.state.form_object.charge_id || ''}
        </td>
    );
    //date_of_service_renderer
    date_of_service_renderer = (key_id) => (
        <td data-label="date_of_service" key={key_id}>
            {this.state.form_object.date_of_service || ''}
        </td>
    );
    //proc_code_renderer
    proc_code_renderer = (key_id) => (
        <td data-label="proc_code" key={key_id}>
            {this.state.form_object.proc_code || ''}
        </td>
    );
    //total_chg_amt_renderer
    total_chg_amt_renderer = (key_id) => (
        <td data-label="total_chg_amt" key={key_id}>
            {this.state.form_object.total_chg_amt || ''}
        </td>
    );
    //owed_amt_renderer
    owed_amt_renderer = (key_id) => (
        <td data-label="owed_amt" key={key_id}>
            {this.state.form_object.owed_amt || ''}
        </td>
    );
    //last_billed_date_renderer
    last_billed_date_renderer = (key_id) => (
        <td data-label="last_billed_date" key={key_id}>
            {this.state.form_object.last_billed_date || ''}
        </td>
    );
    //Action column cell renderer
    action_renderer = (key_id) => (
        <td data-label="action_id" key={key_id}>
            <Selection
                id={'id_action_' + this.state.form_object.charge_id}
                name='claim_action'
                //placeHolder={'Select'}
                hidden={false}
                defaultValue={this.state.form_object.claim_action || ''}
                options={billing_constant.claim_action}
                onChange={(value, e) => {
                    this.on_change_dropdown_string_handler(value, e);
                }}
            />
        </td>
    );
    //late_reason column cell renderer
    late_reason_renderer = (key_id) => (
        <td data-label="late_reason_id" key={key_id}>
            <Selection
                id={'id_late_reason_' + this.state.form_object.charge_id}
                name='late_reason'
                //placeHolder={'Select'}
                hidden={true}
                defaultValue={this.state.form_object.late_reason || ''}
                options={billing_constant.claim_reason}
                onChange={(value, e) => {
                    this.on_change_dropdown_id_handler(value, e);
                }}
            />
        </td>
    );
    //claim code renderer
    claim_code_renderer = (key_id) => (
        <td data-label="claim_code" key={key_id}>
            <Form.Field>
                <Input
                    name='claim_code'
                    autoComplete='off'
                    onChange={e => this.input_change_handler(e)}
                    id={'claim_code_' + this.props.row_count}
                    type='text'
                    value={this.state.form_object.claim_code || ''}
                    maxLength={50}
                />
            </Form.Field>
        </td>
    );
    //condition_code_renderer
    condition_code_renderer = (key_id) => (
        <td data-label="condition_code" key={key_id}>
            <Form.Field>
                {
                    //<Input
                    //    name='condition_code'
                    //    autoComplete='off'
                    //    onChange={e => this.input_change_handler(e)}
                    //    id={'condition_code_' + this.props.row_count}
                    //    type='text'
                    //    value={this.state.form_object.condition_code || ''}
                    //    maxLength={100}
                    ///>
                }
                <Selection
                    id={'condition_code' + this.props.row_count}
                    name='condition_code_id'
                    //placeHolder={'Select'}
                    hidden={true}
                    defaultValue={this.state.form_object.condition_code_id || ''}
                    options={this.props.condition_code}
                    onChange={(value, e) => {
                        this.on_change_dropdown_id_handler(value, e);
                    }}
                />
            </Form.Field>
        </td>
    );
    //note_renderer

    note_renderer = (key_id) => (
        <td data-label="note" key={key_id}>
            <Form.Field>
                <TextareaAutosize
                    style={{ height: 60 }}
                    name='note'
                    autoComplete='off'
                    onChange={e => this.input_change_handler(e)}
                    id={'note_' + this.props.row_count}
                    //type='text'
                    value={this.state.form_object.note || ''}
                    maxLength={250}
                />
            </Form.Field>
        </td>
    );

    //Rebill column cell renderer
    status_renderer = (key_id) => (
        <td data-label="status" key={key_id}>
            <Form.Field className='providers-ui-cell_center'>
                <Checkbox
                    id={'id_status_cell_' + this.props.row_count}
                    name='rebill_status'
                    checked={this.state.form_object['rebill_status'] || false}
                    onChange={e => this.handle_status_change(e)}
                />
            </Form.Field>
        </td>
    );
};

const mapStateToProps = ({ user_login_details }) => {
    return {
        user_login_details
    };
}
const mapDispatchToProps = dispatch => {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ClaimIndicatorRowComponent);