import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import { user_logout } from '../../login/action/login_action';
import * as local_storage from '../../shared/local_storage_utility';
import * as session_storage from '../../shared/session_storage_utility';
import { detect_ie_11, get_sso_token_value_from_url } from '../../shared/utility';
import { sso_logout } from '../../sso/action/sso_action';
import * as global_constant from './../../global_constants';
import { prompt_for_logout } from './../../shared/action/shared_action';

export class LogoutComponent extends React.Component<any, any> {
    [x: string]: any;
    _is_mounted = false;
    _force_logout = (this.props.location && this.props.location.state ? this.props.location.state.force_logout : false);

    constructor(props) {
        super(props);
        this.props.prompt_for_logout(global_constant.prompt_for_logout.NOT_REQUIRED);
        // Initialize state property here
        this.state = {
            loading: true
        };
    }

    UNSAFE_componentWillMount() {
        //get wpt_sso_token if coming from SSO/EMR page 
        let token = get_sso_token_value_from_url(window.location.href, global_constant.sso_config_data.sso_login_token_key);
        if (!token) {
            this.on_logout_page_load();
        }
    }
    componentDidMount() {
        //get wpt_sso_token if coming from SSO/EMR page 
        let token = get_sso_token_value_from_url(window.location.href, global_constant.sso_config_data.sso_login_token_key);
        if (token) {
            this.logut_from_emr();
        }
    }

    logut_from_emr = async () => {
        //get wpt_sso_token if coming from SSO/EMR page 
        let token = get_sso_token_value_from_url(window.location.href, global_constant.sso_config_data.sso_login_token_key);
        if (token) {
            await sso_logout(token).then((res) => {
                this.force_logout();
            }, (error) => {
                this.force_logout();
            });
        }
    }

    on_logout_page_load =  () => {  
        if (this._force_logout) {
            this.force_logout();
        } else {
            this.on_log_out();
        }
    }

    navigate_to_emr_login = (call_from_emr = false) => {
        let window_obj = window.open(global_constant.sso_config_data.sso_config.webpt_logout_url, global_constant.sso_config_data.emr_win_name);
        if(window_obj) {
            window_obj.close();
        }
        sessionStorage.removeItem('sso_user_login');        
        window.location.href = global_constant.sso_config_data.sso_config.webpt_logout_url;
    }

    navigate_to_R6_login = () => {
        sessionStorage.removeItem('sso_user_login');
        this.props.history.push(`/login`);
    }

    //  API call on logout
    on_log_out = async () => {
        // if prompt for logout is required then invoke props.push
        // which will trigger handleBlockedNavigation inside prompt_navigation_component
        if (this.props.check_prompt_for_logout == global_constant.prompt_for_logout.PROMPT_REQUIRED) {

            await this.props.prompt_for_logout(global_constant.prompt_for_logout.REQUIRED);
            if (sessionStorage['sso_user_login']) {
                this.navigate_to_emr_login();
            } else {
                this.navigate_to_R6_login();
            }

        }
        // case where there is no page which requires prompt for unsaved page
        // in this case we will logout the user forcefully
        if (this.props.check_prompt_for_logout == global_constant.prompt_for_logout.NOT_REQUIRED) {
            this.force_logout();
        } else {
            if (sessionStorage['sso_user_login']) {
                this.navigate_to_emr_login();
            } else {
                this.navigate_to_R6_login();
            }
        }
    }

    force_logout = async () => {
        // clear redux-store
        if (this.props.user_login_details && this.props.user_login_details.user_data && this.props.user_login_details.user_data.data) {
            const logout = await this.props.logout(this.props.user_login_details.user_data.data.user_id, this.props.user_login_details.user_data.data.token_details.access_token);
        }
        /**
         * Clearing local storage after logout
         */

        let is_sso_user = false;
        if (sessionStorage['sso_user_login']) {
            is_sso_user = true;
        }

        local_storage.clear_all();
        session_storage.clear_all();

        // let other process to get completed
        setTimeout(() => {
            // localStorage.clear();
            // local_storage.set("version", build_version);
            // local_storage.set("persist:root", persist_root);
            local_storage.deleteAllCookiesWithPath();

            if (detect_ie_11()) {
                local_storage.deleteAllCookies();
            }
            if (is_sso_user) {
                this.navigate_to_emr_login();
            } else {
                this.navigate_to_R6_login();
            }
        }, 100);

        // close other tabs as well
        if (detect_ie_11()) {
            localStorage.setItem('closeOtherWindow' + sessionStorage['sessionId'], sessionStorage['tabId']);
            // in the case of IE we are deleting  the key when listening in the on storage channel
            // localStorage.removeItem('closeOtherWindow' + sessionStorage['sessionId']);
        }
    }
    
    render() {
        return (
            <>
                <Dimmer active={this.state.loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        logout: user_logout,
        prompt_for_logout: prompt_for_logout
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        check_prompt_for_logout: state.shared_details.prompt_for_logout,
        user_login_details: state.user_login_details,
    };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogoutComponent))