import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Dimmer, Form, Icon, Loader } from 'semantic-ui-react';
import { toastr } from 'react-redux-toastr';
import * as _ from 'lodash';
import { add_payer, edit_payer, get_payer_by_id } from '../action/manage_payers_actions';
import { connectionDetailMessages } from '../dashboard_constants';
import { log_error } from '../../../shared/action/shared_action';
import { Tooltip } from '@blueprintjs/core';

class ConnectionDetailComponent extends React.Component<any, any> {

    token: string = "";
    constructor(props: any) {
        super(props);

        let initialValue = {
            payerName: '',
            hostName: '',
            userName: '',
            password: '',
            sshHostKey: '',
            port: '',
            pastDaysToCheckFiles: '',
            locationInboundRemote: '',
            locationOutboundRemote: '',
            locationInboundLocal: '',
            locationOutboundLocal: '',
            locationSentFolder: '',
            isActive: true
        }

        this.state = {
            loading: false,
            connection: initialValue,
            initialValue,
            formSubmited: false
        };
    }

    componentDidMount(): void {
        this.token = this.props.userLoginDetails.user_data.data.token_details.access_token;
        if (this.props.match.params.id) {
            this.loadConnectionDetail(this.props.match.params.id)
        }
    }

    loadConnectionDetail = async (id: number) => {
        this.setState({
            loading: true
        });
        let response = await get_payer_by_id(this.token, id)
        if (response.data && response.data.data) {
            this.setState({
                loading: false,
                connection: response.data.data,
                initialValue: response.data.data
            });
        } else {
            console.error('Connection not found')
            this.setState({
                loading: false
            });
        }
    }

    onInputChange = (e: any) => {
        const { value, checked, type, name } = e.target;
        this.setState({
            connection: {
                ...this.state.connection,
                [name]: type == 'checkbox' ? checked : value
            }
        });
    }

    validateFields = () => {
        const requiredFields = [
            'payerName',
            'hostName',
            'userName',
            'password',
            'sshHostKey',
            'port',
            'pastDaysToCheckFiles',
            'locationInboundRemote',
            'locationOutboundRemote',
            'locationInboundLocal',
            'locationOutboundLocal',
            'locationSentFolder'
        ];

        return _.every(requiredFields, (field) => !!this.state.connection[field]);
    }

    onCancel = () => {
        this.props.history.goBack()
    }

    onSubmit = async () => {
        this.setState({
            loading: true,
            formSubmited: true
        })
        if (this.validateFields()) {
            if (!this.state.connection.autoFtpPayerId) {
                try {
                    await add_payer(this.token, this.state.connection)
                    toastr.success('', connectionDetailMessages.connectionSaved);
                } catch (error) {
                    log_error(error)
                }
            } else {
                try {
                    await edit_payer(this.token,this.state.connection.autoFtpPayerId, this.state.connection)
                    toastr.success('', connectionDetailMessages.connectionUpdated);
                } catch (error) {
                    log_error(error)
                }
            }
            this.setState({
                loading: false
            })
            this.props.history.goBack()
        } else {
            toastr.error('', connectionDetailMessages.requiredFields);
            this.setState({
                loading: false
            })
        }
    }

    render() {
        return (
            <>
                <Dimmer active={this.state.loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <Form onSubmit={this.onSubmit} style={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '100%', height: '100%' }}>
                    <div style={{ position: 'relative', flexGrow: 1 }}>
                        <div style={{ position: 'absolute', display: 'flex', flexDirection: 'column', width: '100%', height: '100%', overflow: 'auto' }}>
                            <div style={{ position: 'relative' }}>
                                <h3 style={{ marginBottom: '0px', fontWeight: 'bold', fontSize: '22px', padding: '5px 5px 10px 5px' }}>FTP Manager</h3>
                                <div style={{ border: '1px solid rgb(204, 204, 204)', background: 'rgb(255, 255, 255)', padding: '10px', marginRight: '15px' }}>
                                    <h3 className='card-title' style={{ marginBottom: '0px', paddingLeft: '10px' }}>Connection Detail</h3>
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <div style={{ flex: 1, padding: '10px' }}>
                                            <Form.Field className={`form-field-input ${this.state.formSubmited && !this.state.connection.payerName ? 'required-field-bg' : ''}`}>
                                                <label>
                                                    Connection name <span className={this.state.formSubmited && !this.state.connection.payerName ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                </label>
                                                <Form.Input
                                                    name='payerName'
                                                    style={{ width: '100%' }}
                                                    value={this.state.connection.payerName || ''}
                                                    onChange={e => this.onInputChange(e)}
                                                />
                                            </Form.Field>
                                        </div>
                                        <div style={{ flex: 1, display: 'flex', padding: '10px' }}>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <div style={{ flex: 1, padding: '10px' }}>
                                            <Form.Field className={`form-field-input ${this.state.formSubmited && !this.state.connection.hostName ? 'required-field-bg' : ''}`}>
                                                <label>
                                                    FTP Host <span className={this.state.formSubmited && !this.state.connection.hostName ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                </label>
                                                <Form.Input
                                                    name='hostName'
                                                    style={{ width: '100%' }}
                                                    value={this.state.connection.hostName || ''}
                                                    onChange={e => this.onInputChange(e)}
                                                />
                                            </Form.Field>
                                        </div>
                                        <div style={{ flex: 1, display: 'flex', padding: '10px' }}>
                                            <div style={{ flex: 1, paddingRight: '10px' }}>
                                                <Form.Field className={`form-field-input ${this.state.formSubmited && !this.state.connection.userName ? 'required-field-bg' : ''}`}>
                                                    <label>
                                                        User name <span className={this.state.formSubmited && !this.state.connection.userName ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                    </label>
                                                    <Form.Input
                                                        name='userName'
                                                        style={{ width: '100%' }}
                                                        value={this.state.connection.userName || ''}
                                                        onChange={e => this.onInputChange(e)}
                                                    />
                                                </Form.Field>
                                            </div>
                                            <div style={{ flex: 1, paddingLeft: '10px' }}>
                                                <Form.Field className={`form-field-input ${this.state.formSubmited && !this.state.connection.password ? 'required-field-bg' : ''}`}>
                                                    <label>
                                                        Password <span className={this.state.formSubmited && !this.state.connection.password ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                    </label>
                                                    <Form.Input
                                                        name='password'
                                                        style={{ width: '100%' }}
                                                        value={this.state.connection.password || ''}
                                                        onChange={e => this.onInputChange(e)}
                                                    />
                                                </Form.Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <div style={{ flex: 1, padding: '10px' }}>
                                            <Form.Field className={`form-field-input ${this.state.formSubmited && !this.state.connection.sshHostKey ? 'required-field-bg' : ''}`}>
                                                <label>
                                                    SSH Key <span className={this.state.formSubmited && !this.state.connection.sshHostKey ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                </label>
                                                <Form.Input
                                                    name='sshHostKey'
                                                    style={{ width: '100%' }}
                                                    value={this.state.connection.sshHostKey || ''}
                                                    onChange={e => this.onInputChange(e)}
                                                />
                                            </Form.Field>
                                        </div>
                                        <div style={{ flex: 1, display: 'flex', padding: '10px' }}>
                                            <div style={{ flex: 1, paddingRight: '10px' }}>
                                                <Form.Field className={`form-field-input ${this.state.formSubmited && !this.state.connection.port ? 'required-field-bg' : ''}`}>
                                                    <label>
                                                        Port <span className={this.state.formSubmited && !this.state.connection.port ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                    </label>
                                                    <Form.Input
                                                        name='port'
                                                        type='number'
                                                        className='form-field-number'
                                                        style={{ width: '100%' }}
                                                        value={this.state.connection.port || ''}
                                                        onChange={e => this.onInputChange(e)}
                                                    />
                                                </Form.Field>
                                            </div>
                                            <div style={{ flex: 1, paddingLeft: '10px' }}>
                                                <Form.Field className={`form-field-input ${this.state.formSubmited && !this.state.connection.pastDaysToCheckFiles ? 'required-field-bg' : ''}`}>
                                                    <label>
                                                        Past Days To Check File(s) <span className={this.state.formSubmited && !this.state.connection.pastDaysToCheckFiles ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                    </label>
                                                    <Form.Input
                                                        name='pastDaysToCheckFiles'
                                                        type='number'
                                                        className='form-field-number'
                                                        style={{ width: '100%' }}
                                                        value={this.state.connection.pastDaysToCheckFiles || ''}
                                                        onChange={e => this.onInputChange(e)}
                                                    />
                                                </Form.Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <div style={{ flex: 1, padding: '10px' }}>
                                            <Form.Field className={`form-field-input ${this.state.formSubmited && !this.state.connection.locationInboundRemote ? 'required-field-bg' : ''}`}>
                                                <label>
                                                    Remote Inbound Location <span className={this.state.formSubmited && !this.state.connection.locationInboundRemote ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                </label>
                                                <Form.Input
                                                    name='locationInboundRemote'
                                                    style={{ width: '100%' }}
                                                    value={this.state.connection.locationInboundRemote || ''}
                                                    onChange={e => this.onInputChange(e)}
                                                />
                                            </Form.Field>
                                        </div>
                                        <div style={{ flex: 1, padding: '10px' }}>
                                            <Form.Field className={`form-field-input ${this.state.formSubmited && !this.state.connection.locationOutboundRemote ? 'required-field-bg' : ''}`}>
                                                <label>
                                                    Remote Outbound Location <span className={this.state.formSubmited && !this.state.connection.locationOutboundRemote ? "req-alert" : 'req-alert_normal'}>(required)</span>

                                                    <Tooltip
                                                        content={
                                                            <div>
                                                                Separate multiple paths with ';'
                                                            </div>
                                                        }
                                                        position={'right'}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 20 20" style={{width: '30px'}}><path style={{ fill: '#7F8FA4' }} d="M9-15h2v2H9Zm0,4h2v6H9Zm1-9A10,10,0,0,0,0-10,10,10,0,0,0,10,0,10,10,0,0,0,20-10,10,10,0,0,0,10-20ZM10-2a8.011,8.011,0,0,1-8-8,8.011,8.011,0,0,1,8-8,8.011,8.011,0,0,1,8,8A8.011,8.011,0,0,1,10-2Z" transform="translate(20) rotate(180)" /></svg>
                                                    </Tooltip>
                                                </label>
                                                <Form.Input
                                                    name='locationOutboundRemote'
                                                    style={{ width: '100%' }}
                                                    value={this.state.connection.locationOutboundRemote || ''}
                                                    onChange={e => this.onInputChange(e)}
                                                />
                                            </Form.Field>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <div style={{ flex: 1, padding: '10px' }}>
                                            <Form.Field className={`form-field-input ${this.state.formSubmited && !this.state.connection.locationInboundLocal ? 'required-field-bg' : ''}`}>
                                                <label>
                                                    Local Inbound Location <span className={this.state.formSubmited && !this.state.connection.locationInboundLocal ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                </label>
                                                <Form.Input
                                                    name='locationInboundLocal'
                                                    style={{ width: '100%' }}
                                                    value={this.state.connection.locationInboundLocal || ''}
                                                    onChange={e => this.onInputChange(e)}
                                                />
                                            </Form.Field>
                                        </div>
                                        <div style={{ flex: 1, padding: '10px' }}>
                                            <Form.Field className={`form-field-input ${this.state.formSubmited && !this.state.connection.locationOutboundLocal ? 'required-field-bg' : ''}`}>
                                                <label>
                                                    Local Outbound Location <span className={this.state.formSubmited && !this.state.connection.locationOutboundLocal ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                </label>
                                                <Form.Input
                                                    name='locationOutboundLocal'
                                                    style={{ width: '100%' }}
                                                    value={this.state.connection.locationOutboundLocal || ''}
                                                    onChange={e => this.onInputChange(e)}
                                                />
                                            </Form.Field>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <div style={{ flex: 1, padding: '10px' }}>
                                            <Form.Field className={`form-field-input ${this.state.formSubmited && !this.state.connection.locationSentFolder ? 'required-field-bg' : ''}`}>
                                                <label>
                                                    Select Folder Location <span className={this.state.formSubmited && !this.state.connection.locationSentFolder ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                </label>
                                                <Form.Input
                                                    name='locationSentFolder'
                                                    style={{ width: '100%' }}
                                                    value={this.state.connection.locationSentFolder || ''}
                                                    onChange={e => this.onInputChange(e)}
                                                />
                                            </Form.Field>
                                        </div>
                                        <div style={{ flex: 1, padding: '10px' }}>
                                            <Form.Field>
                                                <label>&nbsp;</label>
                                                <Checkbox
                                                    name='isActive'
                                                    id='isActive'
                                                    className='form-field-checkbox checkbox-label-center'
                                                    onChange={e => this.onInputChange(e)}
                                                    checked={this.state.connection.isActive ? this.state.connection.isActive : false}
                                                    label={'Is Active'}
                                                />
                                            </Form.Field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', padding: '10px' }}>
                        <Button name='cancelButton' type='button' basic onClick={this.onCancel}>
                            Cancel
                        </Button>
                        <Button name='saveButton' type='submit' primary>
                            Save
                        </Button>
                    </div>
                </Form>
            </>
        );
    }
}

const mapStateToProps = ({ user_login_details }) => {
    return {
        userLoginDetails: user_login_details
    };
}
const mapDispatchToProps = (dispatch: any) => {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ConnectionDetailComponent);