import * as moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { toastr as toaster } from "react-redux-toastr";
import { bindActionCreators } from "redux";
import { Accordion, Button, Dimmer, Form, Grid, Icon, Input, Loader } from "semantic-ui-react";
import { toastr_options } from "../../global_constants";
import ReportFooter from "../../reports/component/report_footer";
import ReportHeader from "../../reports/component/report_header";
import {
  currencyFormatter,
  dateFormatter,
  messages,
  report_ledger_full_configuration_header_id,
  IPayloadForBillingAudit,
  ReportEntityTypeId,
  ReportEventAction,
  saveAuditPrintExport
} from "../../reports/report_constants";
import { multi_grid_export, multi_grid_print, generateAuditDataEntryPayload } from "../../reports/util/export_print_utility";
import { log_error } from "../../shared/action/shared_action";
import AdvancedControl from "../../shared/component/advanced_control";
import AutoSearchComponent from "../../shared/component/auto_search_component";
import DatePicker from "../../shared/component/date_picker_component";
import DateRangePickerComponent from "../../shared/component/date_range_component";
import GridView from "../../shared/component/grid/index";
import SelectionComponent from "../../shared/component/selection_component";
import * as local_storage from "../../shared/local_storage_utility";
import * as session_storage from "../../shared/session_storage_utility";
import { get_all_error, get_columns, strip, get_lauch_darkley_key_value, date_format_with_no_time_return_string_or_null, date_format_with_no_time } from "../../shared/utility";
import { user_companies } from "../../user/action/user_action";
import {
  advanced_search_eob_payor as advanced_search_eob_payors,
  get_eob_835s,
  quick_search_eob_payor as quick_search_eob_payors,
  release_eob_835s,
  search_eob_835s,
  update_eob_835s
} from "../action/payment_action";
import { release835s_configuration, release835sDetail_configuration, release835Status, payerRulesPageName } from "../payment_constants";
import { handle_click_on_enter, set_focus_to_app_header } from "./../../shared/tab_navigation_utility";
import { handle_header_checkbox } from "../../billing/utility";
import DrawerComponent from "../../shared/component/drawer_component";
import { Drawer, Position } from "@blueprintjs/core";
import PayerRulesAddEditDrawerComponent from "../../admin/constants/component/payer_rules_add_edit_drawer_component";
import { payer_rule_summary } from '../../admin/constants/constants';
import { add_data } from '../../admin/constants/action/constants_action';
import { sumaj_bill_636_enhanced_payer_rules, argos_bill_1162_editable_835s } from "../../feature_flip_constant";
import * as _ from 'lodash';

declare global {
  interface Window {
      handleDrawerOpen: any;
  }
}
export class Release835sComponent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      controls_is_expended: true,
      is_submitted: false,
      is_searched: false,
      is_grid_visible: false,
      is_release_grid_visible: false,
      is_show_pop_up: false,
      loading: true,
      show_date: false,
      is_error: false,
      is_print_disabled: true,
      is_export_disabled: true,
      is_process_disabled: true,
      is_close_disabled: true,
      is_save_button_disabled: false,
      is_clear_button_disabled: false,
      report_data: [],
      current_date: new Date(),
      row_params: {},
      charge_audit_footer_obj: {},
      report_rows_count: 0,
      grid_params: null,
      grid_conf: {
        isPagination: true,
        gridAutoHeight: true,
        selectionType: "single",
        paginationPageSize: 20
      },
      selected_835s: [],
      payer_search_data: {
        title: "",
        label: "",
        code: "",
        name: ""
      },
      isDrawerOpen: false,
      isPayerRuleDataUpdated: false
    };
    
    window.handleDrawerOpen = this.handleDrawerOpen.bind(this);
  }

  // Define class level variables.
  company_options: any = [];
  reportId: string = "0";
  report_data: any = {
    rows: [],
    release_rows: []
  };
  search_criteria: any = {
    company_id: "",
    eob_date_from: null,
    eob_date_to: null,
    check_eft_num: "",
    payer: "",
    payer_id: "",
    load_date: ""
  };
  initial_search_criteria: any = {
    company_id: this.props.user_login_details.user_data.data.company_id,
    eob_date_from: null,
    eob_date_to: null,
    check_eft_num: "",
    payer: "",
    payer_id: "",
    load_date: ""
  };
  title_search_criteria: any = {
    company_id: "",
    eob_date_from: "",
    eob_date_to: "",
    check_eft_num: "",
    payer: "",
    payer_id: "",
    load_date: ""
  };
  payer_item = [];
  release_criteria: any = {
    deposit_date: "",
    batch_number: ""
  };
  initial_release_criteria: any = {
    deposit_date: "",
    batch_number: ""
  };
  R4ToR6Route: any = [
    {
      has_parameter: false,
      parameters: null,
      r4_route: "ReportSelection.aspx",
      r6_route: "/report/sub_report_data?param"
    }
  ];
  report_container: any;
  run_report_date = moment().format("MM/DD/YYYY") + " " + moment().format("hh:mm:ss A");
  release835s = release835s_configuration(this.R4ToR6Route);
  release835sDetail = release835sDetail_configuration;
  release_835s_column_def: any = [];
  release_column_def: any = [];
  current_date: string;
  title: string;
  total_column_width: number = 0;
  grid_header_height: number = 0;
  total_column_width_release: number = 0;
  grid_header_height_release: number = 0;
  payer_search_enum = "payer";
  is_initial_result = false;
  last_col_width = 0;
  grid_width = 0;
  grid_width_release = 0;
  token: any = "";
  is_mounted = false;
  grid_release_data_length: number = 0;

  // Fetching the report id and params.
  UNSAFE_componentWillMount = () => {
    this.token = this.props.user_login_details.user_data.data.token_details.access_token;
    this.release_835s_column_def = this.release835s.column_defs;
    
    if (this.props.features.sumajBill636EnhancedPayerRules) {
      this.release_835s_column_def[2].cellRenderer = (params: any) => {
        if(params.value == release835Status.noPayerRuleEstablished && this.props.hasAccessToPayerRulesPage){
            var eDiv = document.createElement('div');
            eDiv.innerHTML = `<span class='href_link' onclick="handleDrawerOpen('${params.data.payer_id}','${params.data.company_id}')">${params.value}</span>`;
            return eDiv;
        }else{
            return params.value
        }
      }
    }
    this.release_column_def = this.release835s.release_column_def;
    this.search_criteria = {
      ...this.search_criteria,
      company_id: this.props.user_login_details.user_data.data.company_id
    };
    this.title_search_criteria = {
      ...this.search_criteria,
      company_id: this.props.user_login_details.user_data.data.company_name
    };

    if (session_storage.get("release_835_search_criteria")) {
      this.is_initial_result = true;
      this.search_criteria = {
        ...session_storage.get("release_835_search_criteria")
      };
      this.search_criteria = {
        ...this.search_criteria,
        eob_date_from: this.search_criteria.eob_date_from
          ? moment(date_format_with_no_time(this.search_criteria.eob_date_from)).format("MM/DD/YYYY")
          : null,
        eob_date_to: this.search_criteria.eob_date_to
          ? moment(date_format_with_no_time(this.search_criteria.eob_date_to)).format("MM/DD/YYYY")
          : null
      };
      this.title_search_criteria = {
        ...this.search_criteria
      };
    }
  };

  componentDidMount = async () => {
    this.is_mounted = true;
    await this.props.user_companies(false, this.token);
    let response = this.props.user_companies_response;
    if (response && response.status == 1) {
      this.get_dropdown_master_data(response.data);
    } else {
      const toastr_options = this.show_html_content_toaster(get_all_error(response.data));
      toaster.error("", toastr_options);
    }
    document.body.classList.add("reports");
    if (this.is_initial_result) {
      this.get_all_grid_data();
    } else {
      this.get_eob_835s();
    }
  };

  componentWillUnmount = () => {
    document.body.classList.remove("reports");
    this.is_mounted = false;
    this.search_criteria = {
      ...this.initial_search_criteria
    };
    this.report_data = {
      release_rows: [],
      rows: []
    };
    session_storage.remove("release_835_search_criteria");
  };

  get_dropdown_master_data = (data: any[]) => {
    this.company_options = data.map((val) => {
      return { key: val.company_id, value: val.company_id, text: val.gpms_code + " - " + val.company_name };
    });
    this.search_criteria = {
      ...this.search_criteria
    };
    if (this.is_mounted) {
      this.setState({
        re_render: false
      });
    }
  };

  // Get All Grid Data
  get_all_grid_data = async () => {
    let arrData = [];
    let search_criteria = {
      ...this.search_criteria,
      eob_date_from: this.search_criteria.eob_date_from
        ? moment(this.search_criteria.eob_date_from).format("MM/DD/YYYY")
        : "",
      eob_date_to: this.search_criteria.eob_date_to ? moment(this.search_criteria.eob_date_to).format("MM/DD/YYYY") : ""
    };
    this.title_search_criteria = {
      ...search_criteria,
      company_id: this.get_dropdown_text()
    };
      this.grid_width = document.getElementsByClassName("report-wrapper")[0] != null ? document.getElementsByClassName("report-wrapper")[0].clientWidth : 0;
      this.grid_width_release = document.getElementsByClassName("report-wrapper")[0] != null ? document.getElementsByClassName("report-wrapper")[0].clientWidth : 0;
    let param = Object.keys(search_criteria)
      .map((key) => key + "=" + search_criteria[key])
      .join("&");

    if (this.is_mounted) {
      this.setState((prev_state) => ({
        loading: true,
        is_print_disabled: true,
        is_export_disabled: true,
        is_process_disabled: true,
        is_close_disabled: true,
        is_grid_visible: false,
        is_save_button_disabled: true,
        is_release_grid_visible: false,
        controls_is_expended: false,
        selected_payer:
          this.is_initial_result && this.search_criteria.payer_id && this.search_criteria.payer
            ? {
                title: this.search_criteria.payer_id + " - " + this.search_criteria.payer,
                code: this.search_criteria.payer_id,
                description: this.search_criteria.payer,
                id: this.search_criteria.payer_id,
                label: this.search_criteria.payer_id + " - " + this.search_criteria.payer
              }
            : prev_state.selected_payer
      }));
    }
    arrData.push(get_eob_835s(this.token));
    arrData.push(search_eob_835s(param, this.token));
    await Promise.all(arrData)
      .then(
        (response) => {
          if (response[0].data && response[0].data.data && response[1].data && response[1].data.data) {
            response[0].data.data = this.row_data_formatter(response[0].data.data, this.release_column_def);
            this.report_data["release_rows"] = response[0].data.data || [];
            this.total_column_width_release = 0;
            const grid_height_release = get_columns(this.report_data["release_rows"], this.release_column_def);
            this.grid_header_height_release = grid_height_release.header_height + 10;
            this.total_column_width_release = grid_height_release.total_column_width;
            response[1].data.data = this.row_data_formatter(response[1].data.data, this.release_835s_column_def, true);
            this.report_data["rows"] = response[1].data.data || [];
            this.total_column_width = 0;
            const grid_height = get_columns(this.report_data["rows"], this.release_835s_column_def);
            this.grid_header_height = grid_height.header_height + 10;
            this.total_column_width = grid_height.total_column_width;

            if (this.is_mounted) {
              this.setState({
                loading: false,
                is_process_disabled: false,
                is_close_disabled: false,
                is_release_grid_visible: true,
                is_export_disabled: false,
                is_print_disabled: false,
                is_grid_visible: true,
                show_date: true,
                report_rows_count: this.report_data["rows"] && this.report_data["rows"].length,
                is_save_button_disabled: false,
                is_submitted: false
              });
            }
          }
        },
        (error) => {
          if (this.is_mounted) {
            this.setState({
              loading: false,
              is_process_disabled: true,
              is_close_disabled: true,
              is_release_grid_visible: true,
              is_save_button_disabled: false,
              controls_is_expended: true
            });
          }
          log_error(error);
          if (error.response.data) {
            const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
            toaster.error("", toastr_options);
          }
        }
      )
      .catch((error) => {
        if (this.is_mounted) {
          this.setState({
            loading: false,
            is_process_disabled: true,
            is_close_disabled: true,
            is_release_grid_visible: true,
            is_save_button_disabled: false,
            controls_is_expended: true
          });
        }
        log_error(error);
        if (error.data.messages[0].message.indexOf("<br") > -1) {
          this.show_html_content_toaster(error.response.data.messages[0].message);
        } else {
          toaster.error("", error.response.data.messages[0].message);
        }
      });
  };

  // Get Eob Release data
    get_eob_835s = async () => {
        this.grid_width_release = document.getElementsByClassName("report-wrapper")[0] != null ? document.getElementsByClassName("report-wrapper")[0].clientWidth : 0;
    if (this.is_mounted) {
      this.setState((prev_state) => ({
        loading: true,
        is_release_grid_visible: false,
        is_process_disabled: true,
        is_close_disabled: true,
        selected_payer:
          this.is_initial_result && this.search_criteria.payer_id && this.search_criteria.payer
            ? {
                title: this.search_criteria.payer_id + " - " + this.search_criteria.payer,
                code: this.search_criteria.payer_id,
                description: this.search_criteria.payer,
                id: this.search_criteria.payer_id,
                label: this.search_criteria.payer_id + " - " + this.search_criteria.payer
              }
            : prev_state.selected_payer
      }));
    }
    await get_eob_835s(this.token).then(
      (response) => {
        if (response.data && response.data.data) {
          response.data.data = this.row_data_formatter(response.data.data, this.release_column_def);
          this.report_data["release_rows"] = response.data.data || [];
          this.total_column_width_release = 0;
          const grid_height = get_columns(this.report_data["release_rows"], this.release_column_def);
          this.grid_header_height_release = grid_height.header_height + 10;
          this.total_column_width_release = grid_height.total_column_width;
          if (this.is_mounted) {
            this.setState({
              loading: false,
              report_data: this.report_data,
              is_process_disabled: false,
              is_close_disabled: false,
              is_release_grid_visible: true,
              is_export_disabled: false,
              is_print_disabled: false
            });
          }
        } else {
          log_error(response.data.messages[0].message.indexOf("<br") > -1);
          if (response.data.messages[0].message.indexOf("<br") > -1) {
            const toastr_options = this.show_html_content_toaster(get_all_error(response.data.messages[0].message));
            toaster.error("", toastr_options);
          }
          if (this.is_mounted) {
            this.setState({
              loading: false,
              is_process_disabled: true,
              is_close_disabled: true,
              is_release_grid_visible: true
            });
          }
        }
      },
      (error) => {
        if (this.is_mounted) {
          this.setState({
            loading: false,
            is_process_disabled: true,
            is_close_disabled: true,
            is_release_grid_visible: true
          });
        }
        log_error(error);
        if (error.response.data) {
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error("", toastr_options);
        }
      }
    );
  };

  // Get Report Data.
  get_report_data = async () => {
    let search_criteria = {
      ...this.search_criteria,
      eob_date_from: this.search_criteria.eob_date_from
        ? moment(this.search_criteria.eob_date_from).format("MM/DD/YYYY")
        : "",
      eob_date_to: this.search_criteria.eob_date_to ? moment(this.search_criteria.eob_date_to).format("MM/DD/YYYY") : ""
    };
    this.title_search_criteria = {
      ...search_criteria,
      company_id: this.get_dropdown_text()
    };
      this.grid_width = document.getElementsByClassName("report-wrapper")[0] != null ?  document.getElementsByClassName("report-wrapper")[0].clientWidth : 0;
    let param = Object.keys(search_criteria)
      .map((key) => key + "=" + search_criteria[key])
      .join("&");

    if (this.is_mounted) {
      this.setState({
        loading: true,
        is_grid_visible: false,
        is_save_button_disabled: true
      });
    }
    await search_eob_835s(param, this.token).then(
      (response) => {
        if (response.data && response.data.data) {
          response.data.data = this.row_data_formatter(response.data.data, this.release_835s_column_def, true);
          this.report_data["rows"] = response.data.data || [];
          this.total_column_width = 0;
          const grid_height = get_columns(this.report_data["rows"], this.release_835s_column_def);
          this.grid_header_height = grid_height.header_height + 10;
          this.total_column_width = grid_height.total_column_width;
          if (this.is_mounted) {
            this.setState({
              loading: false,
              report_data: this.report_data,
              is_grid_visible: true,
              show_date: true,
              report_rows_count: this.report_data["rows"] && this.report_data["rows"].length,
              is_save_button_disabled: false,
              is_submitted: false
            });
          }
        } else {
          log_error(response.data.messages[0].message.indexOf("<br") > -1);
          if (response.data.messages[0].message.indexOf("<br") > -1) {
            const toastr_options = this.show_html_content_toaster(get_all_error(response.data.messages[0].message));
            toaster.error("", toastr_options);
          }
          if (this.is_mounted) {
            this.setState({
              loading: false,
              is_save_button_disabled: false
            });
          }
        }
      },
      (error: { response: { data: any } }) => {
        if (this.is_mounted) {
          this.setState({
            loading: false,
            is_save_button_disabled: false
          });
        }
        log_error(error);
        if (error.response.data) {
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error("", toastr_options);
        }
      }
    );
  };

  // Calender change handler.
  range_date_change_handler = (eob_date_from: moment.MomentInput, end_date: moment.MomentInput) => {
    let is_error = false;
    this.search_criteria = {
      ...this.search_criteria,
      eob_date_from: eob_date_from || null,
      eob_date_to: end_date || null
    };

    if (this.search_criteria.check_eft_num) {
      is_error = false;
    } else {
      is_error = (moment.isMoment(eob_date_from) ? false : true) || (moment.isMoment(end_date) ? false : true);
    }

    if (!moment.isMoment(eob_date_from) && !moment.isMoment(end_date)) {
      is_error = false;
    }
    if (this.is_mounted) {
      this.setState({
        is_error: is_error
      });
    }
  };

  // Calender change handler.
  calendar_change_handler = (name: string | number, date_object: moment.MomentInput) => {
    if (name === "deposit_date") {
      this.release_criteria[name] = date_object ? moment(date_object).format("MM/DD/YYYY") : "";
    } else {
      this.search_criteria[name] = date_object ? moment(date_object).format("MM/DD/YYYY") : "";
    }
    if (this.is_mounted) {
      this.setState((prev_state) => ({
        search_criteria: this.search_criteria
      }));
    }
  };

  // Dropdown change handler.
  dropdown_change_handler = (event: {
    target: { selectedOptions?: any; options?: any; selectedIndex?: any; id?: any; value?: any };
  }) => {
    const { id, value } = event.target;
    this.search_criteria[id] = value;
    if (this.is_mounted) {
      this.clear_quick_search();
    }
  };

  // Input change handler.
  input_change_handler = (e) => {
    const { value, name } = e.target;
    if (name === "batch_number") {
      this.release_criteria[name] = value;
    } else {
      this.search_criteria[name] = value && value.trim();
    }
    if (this.is_mounted) {
      this.setState((prev_state) => ({
        required_error: {
          ...prev_state.required_error,
          [name]: !!value
        },
        form_object: this.search_criteria
      }));
    }
  };

  // Required Field Validator.
  required_field_validator = () => {
    let is_error = true;
    const { eob_date_from, eob_date_to, check_eft_num } = this.search_criteria;
    if (moment.isMoment(eob_date_from) && moment.isMoment(eob_date_to)) {
      is_error = moment(eob_date_to).isSameOrAfter(eob_date_from) ? false : true;
    } else if (check_eft_num) {
      is_error = false;
    } else {
      is_error = true;
    }
    is_error && toaster.error("", this.release835s.msg);
    return is_error;
  };

  // Function used to Run Report based on controllers values.
  run_report_handler = (e) => {
    let is_error = false;
    // Validate Required Field
    is_error = this.required_field_validator();
    if (is_error) {
      return;
    }
    if (this.is_mounted) {
      this.setState({
        is_submitted: true,
        is_searched: true,
        controls_is_expended: false
      });
    }
    this.get_all_grid_data();
    session_storage.set("release_835_search_criteria", this.search_criteria);
    this.run_report_date = moment(new Date()).format("MM/DD/YYYY") + " " + moment(new Date()).format("hh:mm:ss A");
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  // FUnction use to format the grid data on load.
  row_data_formatter = (row_data, column_def, search = false) => {
    let row_index = 0;
    row_data.map((row) => {
      if (search) {
        let disabled = row.status === (this.props.features.sumajBill636EnhancedPayerRules?release835Status.noPayerRuleEstablished:release835Status.payerIdNotFound) ? true : false;
        row["disabled_is_release"] = disabled;
        row["disabled_is_close"] = false;
        row["is_close"] = false;
        row["is_release"] = false;
        row["row_index"] = row_index++;
      }
      column_def.map((value) => {
        switch (value.type) {
          case "date":
            row[value.field] =
              row[value.field] != null || row[value.field] != undefined ? dateFormatter(row[value.field]) : "";
            break;
          case "currency":
            row[value.field] =
              row[value.field] != null || row[value.field] != undefined
                ? currencyFormatter(row[value.field])
                : currencyFormatter(0);
            break;
          case "number":
            row[value.field] =
              row[value.field] != null || row[value.field] != undefined ? parseInt(row[value.field]) : 0;
            break;
          case "hyper_link":
            row[`link_${value.field}`] = strip(row[value.field]);
            break;
          default:
            row[value.field] = row[value.field] != null || row[value.field] != undefined ? row[value.field] : "";
        }
      });
      return row;
    });
    return row_data;
  };

  // Function calls on click of Clear button and clear all the searched result as well as criteria.
  clear_handler = async () => {
    if (this.is_mounted) {
      this.setState({
        loading: true,
        is_grid_visible: false,
        is_release_grid_visible: false,
        is_export_disabled: true,
        is_print_disabled: true,
        is_searched: false
      });
    }
    this.search_criteria = { ...this.initial_search_criteria };
    this.release_criteria = { ...this.initial_release_criteria };
    this.report_data = {
      release_rows: [],
      rows: []
    };
    session_storage.remove("release_835_search_criteria");
    await this.get_eob_835s();
    this.title_search_criteria = {
      company_id: this.props.user_login_details.user_data.data.company_name,
      check_eft_num: "",
      payer: "",
      payer_id: "",
      load_date: "",
      eob_date_from: "",
      eob_date_to: ""
    };
    if (this.is_mounted) {
      this.setState({
        report_data: [],
        is_grid_visible: false,
        is_export_disabled: false,
        is_print_disabled: false,
        show_date: false,
        loading: false,
        is_start_date: false,
        is_end_date: false,
        is_error: false,
        selected_payer: {},
        is_submitted: false
      });
    }
  };

  // Show multiple messages
  show_html_content_toaster = (msg) => {
    return {
      component: () => (
        <div>
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        </div>
      ),
      timeOut: toastr_options.toastr_time_out,
      preventDuplicates: true
    };
  };

  // Function calls on initialization of Ag-Grid and catch its reference.
  get_grid_ref = (grid_params) => {
    if (this.is_mounted) {
      this.setState({
        grid_params,
        report_rows_count: grid_params.api.getModel().getRowCount()
      });
    }
    if (this.total_column_width > this.grid_width) {
      this.release_835s_column_def.filter((item, index) => {
        grid_params.columnApi.setColumnWidth(item.field, item["width"], false);
      });
    }
      handle_header_checkbox(grid_params, "is_release", "is_close", "status", this.props.features.sumajBill636EnhancedPayerRules?release835Status.noPayerRuleEstablished:release835Status.payerIdNotFound, false, false);
      handle_header_checkbox(grid_params, "is_close", "is_release", "status", this.props.features.sumajBill636EnhancedPayerRules?release835Status.noPayerRuleEstablished:release835Status.payerIdNotFound,false,true);
  };

  get_grid_ref_release = (grid_params) => {
    if (this.is_mounted) {
      this.setState({
        grid_params_release: grid_params
      });
    }
    if (this.total_column_width_release > this.grid_width_release) {
      this.release_column_def.filter((item, index) => {
        grid_params.columnApi.setColumnWidth(item.field, item["width"], false);
      });
    }
  };

  //Function calls after click of filter button and recalculate the footer sum.
  on_filter_button_click = () => {
    // Update row count
    this.setState({
      report_rows_count: this.state.grid_params.api.getModel().getRowCount() //this.state.grid_params.api.getModel().getRowCount()
    });
  };

  // ################## Payer Search ##################
  get_payer_list = async (search_keyword) => {
    let result = await quick_search_eob_payors(this.search_criteria.company_id, { keyword: search_keyword }, this.token);
    result.data.data =
      result.data && result.data.data ? result.data.data.sort((a, b) => a.name.localeCompare(b.name)) : result;
    return result;
  };

  //Requested providers
  on_advanced_grid_row_select = (item) => {
    let formatted_payors = {
      title: item.payer_id + " - " + item.name,
      code: item.payer_id,
      description: item.name,
      id: item.payer_id,
      label: item.payer_id + " - " + item.name
    };
    if (this.is_mounted) {
      this.search_criteria = {
        ...this.search_criteria,
        payer: formatted_payors.description,
        payer_id: formatted_payors.code
      };
      this.setState({
        selected_payer: formatted_payors
      });
    }
  };

  on_payer_search = async (params) => {
    const token = this.props.user_login_details.user_data.data.token_details.access_token;
    let grid_data = [];
    params = params ? params : {};
    params.company_id = this.search_criteria.company_id;
    const search_data_payer = await advanced_search_eob_payors(params, token).then((res) => res.data);
    grid_data = {
      ...this.state.grid_conf,
      rows: search_data_payer.data !== null ? search_data_payer.data || search_data_payer.data.result : [],
      column: this.release835s.eob_payer_column_defs,
      messages: search_data_payer.messages
    };
    if (this.is_mounted) {
      this.setState({ payer_search_data: grid_data });
    }
  };

  // clear data on clear icon
  clear_quick_search = () => {
    if (this.is_mounted) {
      this.search_criteria = {
        ...this.search_criteria,
        payer: "",
        payer_id: ""
      };
      this.setState({
        selected_payer: {}
      });
    }
  };

  // get the searched value which is typed for searching location or providers
  get_input_value = (input) => {
    if (input.trim().length >= 3) {
      this.get_payer_list(input);
    }
    if (this.is_mounted) {
      const { is_submitted, error } = this.state;
      if (is_submitted) {
        this.setState({ form: { ...this.state.form, insurance_payer: null } });
      } else {
        this.setState({ form: { ...this.state.form, insurance_payer: null } });
      }
    }
  };

  //Auto search for class
  renderResult = (props) => {
    return (
      <div key={props.title} tabIndex={0} className="item_auto_search">
        <div key={props.title} className="fs_13">
          <span>{props.label}</span>
        </div>
      </div>
    );
  };

  // prepare the suggestion list with search result
  prepare_suggestion = (data) => {
    let formattedList = [];
    let data_length = data.data.length;
    if (data && data_length) {
      for (let i = 0; i < data_length; i++) {
        let item = data.data[i];
        formattedList.push({
          title: item.payer_id + (item.name ? `- ${item.name}` : ""),
          code: item.payer_id,
          description: item.name,
          id: item.payer_id,
          label: item.payer_id + (item.name ? `- ${item.name}` : "")
        });
      }
    }
    return formattedList;
  };

  on_item_selection = (item) => {
    if (this.is_mounted) {
      this.search_criteria = {
        ...this.search_criteria,
        payer: item.description,
        payer_id: item.code
      };
      this.setState({
        selected_payer: item
      });
    }
  };
  // ################## Payer Search ##################

  // Used to create the title for export and print.
  set_title = (separator) => {
    this.current_date = moment().format("MM/DD/YYYY") + " " + moment().format("hh:mm:ss A");
    const { company_id, check_eft_num, payer, payer_id, load_date, eob_date_from, eob_date_to } = this.title_search_criteria;
    this.title =
      "Release 835s" +
      separator +
      "Company: " +
      company_id +
      separator +
      "835 Date Range: " +
      (eob_date_from && eob_date_to ? eob_date_from + " - " + eob_date_to : "") +
      separator +
      "Check Number: " +
      check_eft_num +
      separator +
      "Inbound Payer: " +
      payer_id +
      (payer ? ` - ${payer}` : "") +
      separator +
      "Load Date: " +
      load_date +
      separator +
      this.current_date +
      separator +
      (this.state.report_rows_count == 1
        ? this.state.report_rows_count + " record"
        : this.state.report_rows_count + " records") +
      separator +
      separator;
    return this.title;
  };

  get_dropdown_text = () => {
    let option = this.company_options.filter((item) => {
      if (item.value == this.search_criteria.company_id) {
        return item.text;
      }
    });
    return option && option[0] ? option[0].text : '';
  };
  // Function calls on initialization of export report data
  on_export_button = () => {
    this.title = this.set_title("\n");
    let title = [this.title, "\n\nCurrent Release Status\n"];
    let grid_params = [this.state.grid_params, this.state.grid_params_release];
    multi_grid_export(grid_params, title, "Release 835s", ["is_release", "is_close"]);
    grid_params = [];
    this.saveReportEventAction(ReportEventAction.Export);
  };

  // Function calls on initialization of Print report data
  on_print_button = () => {
    this.title = this.set_title("<br/>");
    let title = [this.title, "<br/><br/>Current Release Status <br/><br/>"];
    let grid_params = [this.state.grid_params, this.state.grid_params_release];
    multi_grid_print(grid_params, title, "Release 835s", ["is_release", "is_close"]);
    grid_params = [];
    this.saveReportEventAction(ReportEventAction.Print);
  };

  // Function calls save billing audit on Export or Print
  private saveReportEventAction = (eventActionId: number) => {
      const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
      const { user_id, company_id } = this.props.user_login_details.user_data.data;

      const payload: IPayloadForBillingAudit = {
          reportId: this.reportId,
          contextTitle: `${this.release835s.title} - ${ReportEventAction[eventActionId]}`,
          eventActionId: eventActionId,
          userId: Number(user_id),
          companyId: Number(company_id),
          entityTypeId: ReportEntityTypeId.EOB_RELEASE,
          data: { Release: this.report_data.rows.length, Released: this.report_data.release_rows.length }
      }

      const reqBody = generateAuditDataEntryPayload(payload);

      saveAuditPrintExport(reqBody, accessToken);
  };

  handle_navigation_after_last_element = (event) => {
    // apply a check where grid is empty
    if (
      !event.shiftKey &&
      event.keyCode == "9" &&
      (this.report_data == null || this.report_data.length == 0)
    ) {
      set_focus_to_app_header(event);
    }
  };

  // Select 835 Key on checkbox
  on_check_box_selection = (items) => {
    if (this.state.selected_835s.length !== items.length) {
      if (this.is_mounted) {
        this.setState({
          selected_835s: items
        });
      }
    }
  };

  update_eob_835s = async () => {
    if (this.state.selected_835s && this.state.selected_835s.length <= 0) {
      toaster.error("", this.release835s.closed_msg.error);
      return;
    }

    if (this.state.selected_835s && this.state.selected_835s.length > 0) {
      // Create Query Param from checked items
      const params = this.state.selected_835s.map((value) => {
        return { eob_key: value.eob_key, release_type: "U" };
      });
      if (this.is_mounted) {
        this.setState({
          loading: true,
          is_save_button_disabled: true
        });
      }
      await update_eob_835s(params, this.token).then(
        (response) => {
          if (response.data && response.data.status === 1) {
            const eob_success = response.data.data.filter((value) => {
              return value.release_status === "SUCCESS";
            });
            if (params.length !== eob_success.length) {
              toaster.error("", this.release835s.invalid_835_msg);
            } else {
              toaster.success("", this.release835s.closed_msg.success);
            }
            if (this.is_mounted) {
              this.setState({
                selected_835s: [],
                loading: false,
                is_save_button_disabled: false,
                is_cancel_disabled: false
              });
              this.get_eob_835s();
            }
          } else {
            response.data.messages && log_error(response.data.messages[0].message.indexOf("<br") > -1);
            if (response.data.messages && response.data.messages[0].message.indexOf("<br") > -1) {
              const toastr_options = this.show_html_content_toaster(get_all_error(response.data.messages[0].message));
              toaster.error("", toastr_options);
            }
            if (this.is_mounted) {
              this.setState({
                selected_835s: [],
                loading: false,
                is_save_button_disabled: false,
                is_cancel_disabled: false
              });
            }
          }
        },
        (error) => {
          if (this.is_mounted) {
            this.setState({
              loading: false,
              is_save_button_disabled: false,
              is_cancel_disabled: false
            });
          }
          log_error(error);
          if (error.response.data) {
            const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
            toaster.error("", toastr_options);
          }
        }
      );
    }
  };

  release_eob_835s = async () => {
    let param = [];
    let is_required = false;
    const accrual_flag: any = local_storage.get("auth_data").accrual_accounting || false;
    const open_month: any = local_storage.get("auth_data").open_month || null;
    let minDate = null;
    if (accrual_flag) {
      minDate = new Date(open_month);
    }
    this.state.grid_params.api.forEachNode((node) => {
      let item = node.data;
      if (!is_required) {
        is_required = item.is_release;
      }
      if ((item.status !== (this.props.features.sumajBill636EnhancedPayerRules?release835Status.noPayerRuleEstablished:release835Status.payerIdNotFound) && item.is_release) || item.is_close) {
        param.push({
          eob_key: item.eob_key,
          release_type: item.is_release ? "R" : item.is_close ? "C" : ""
        });
      }
    });

      this.release_criteria.deposit_date = date_format_with_no_time_return_string_or_null(this.release_criteria.deposit_date);
    if (param && param.length <= 0) {
      toaster.error("", this.release835s.process_msg.empty_error);
      return;
    }
    if (is_required && !this.release_criteria.deposit_date) {
      toaster.error("", this.release835s.process_msg.deposit_error);
      return;
    }
    if (moment(this.release_criteria.deposit_date) < moment(minDate)) {
      toaster.error("", this.release835s.process_msg.close_month_error);
      return;
    }
    //to check required validation
    if (param && param.length > 0) {
      if (this.is_mounted) {
        this.setState({
          loading: true,
          is_save_button_disabled: true
        });
      }
      let params = {
          company_id: this.search_criteria.company_id,
          deposit_date: this.release_criteria.deposit_date,
        batch_number: this.release_criteria.batch_number
      };
      await release_eob_835s(param, params, this.token).then(
        (response) => {
          if (response.data && response.data.status === 1) {
            const eob_success = response.data.data.filter((value) => {
              return value.release_status === "SUCCESS";
            });
            if (param.length !== eob_success.length) {
              toaster.error("", this.release835s.invalid_835_msg);
            } else {
              toaster.success("", this.release835s.process_msg.success);
            }
            if (this.is_mounted) {
              this.get_all_grid_data();
            }
          } else {
            response.data.messages && log_error(response.data.messages[0].message.indexOf("<br") > -1);
            if (response.data.messages) {
              const toastr_options = this.show_html_content_toaster(get_all_error(response.data.messages));
              toaster.error("", toastr_options);
            }
            if (this.is_mounted) {
              this.setState({
                selected_eobs: [],
                loading: false,
                is_save_button_disabled: false,
                is_cancel_disabled: false
              });
            }
          }
        },
        (error) => {
          if (this.is_mounted) {
            this.setState({
              loading: false,
              is_save_button_disabled: false,
              is_cancel_disabled: false
            });
          }
          log_error(error);
          if (error.response.data) {
            const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
            toaster.error("", toastr_options);
          }
        }
      );
    }
  };

    // Search Criteria Toggler
  controls_toggle = () => {
    if (this.is_mounted) {
      this.setState({
        controls_is_expended: !this.state.controls_is_expended
      });
    }
  }

  handleDrawerOpen = (inboundId:any,companyId:any) => {
    this.setState({
      payerRule: {
        originalRowData: {},
        originalRowIndex: {}
      },
      openPanelMode: {
          isUpdateRow: false,
          isCreateRow: true,
          labelSave: 'Save',
          inboundId,
          companyId
      }
    });
    this.setState({ isDrawerOpen: true });
  }

  handleDrawerClose = () =>{
    this.setState({
      isDrawerOpen: false
    });
  }

  getPayerRulesAddComponent = () => {
    return (
        <PayerRulesAddEditDrawerComponent
            saveHandlerCallback={this.payerRulesSaveCallback}
            updateHandlerCallback={this.is_payer_rules_updated_callback}
            {...this.state} />
    );
  };

  payerRulesSaveCallback = async (incomingPayload:any) => {
    let dto = Object.assign({}, incomingPayload);
    this.setState({
      loading: true
    });
    dto.inboundId = dto.inboundId.split('-')[0].trim();
    dto.companyId = this.state.openPanelMode.companyId;
    try {
      let response:any = await add_data(this.token, payer_rule_summary.api.save_payer_rule.url, dto);
      if (response.data && response.data.data !== -1) {
        toaster.success('', payer_rule_summary.successfully_saved_message);
        this.handleDrawerClose();
        this.run_report_handler(null);
      }else{
        if (response.data.messages[0].message.indexOf('<br') > -1) {
            this.show_html_content_toaster(response.data.messages[0].message);
        } else {
            toaster.error('', response.data.messages[0].message);
        }
      }
    } catch (error) {
      toaster.error('', payer_rule_summary.validation_message.failure);
    }
    this.setState({
      loading: false
    });
  }
  is_payer_rules_updated_callback = (is_updated: boolean) => { this.setState({ isPayerRuleDataUpdated: is_updated }) }
  render() {
    const is_error = this.state.is_submitted && this.state.is_error;
    const calculatedHeight = this.report_data.rows.length * 28 + 75;
    const gridHeight = calculatedHeight <= 480 ? calculatedHeight : 480;
    return (
      <>
      <Dimmer active={this.state.loading}>
        <Loader size="massive">Loading</Loader>
      </Dimmer>
      <div className={"common-forms-add"}>
        <div className={"common-forms-search report-framework"} style={{ paddingRight: 0 }}>
          <ReportHeader title={this.release835s.title} />
          <div style={{ overflow: "auto", paddingRight: 15, flex: "1 1 0" }}>
            <div
              id="report-scrollable-area"
              className="report-wrapper"
              style={this.state.is_grid_visible ? { flex: 1 } : {}}
            >
              <div className="patient-search-form  patient_search_bottom_padding" id="report-criteria-container">
                <Form autoComplete="off" onSubmit={this.run_report_handler}>
                  <Grid>
                    <Grid.Column computer={16} tablet={16} textAlign='left' className='accordionColumn'>
                      <Accordion fluid styled>
                        <Accordion.Title active={this.state.controls_is_expended} index={0} onClick={this.controls_toggle}>
                          <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name='angle right' />
                          Search Criteria
                        </Accordion.Title>
                        <Accordion.Content active={this.state.controls_is_expended}>
                          <Grid>
                            <Grid.Column tablet={8} computer={4}>
                              <Form.Field>
                                <label>
                                  Company
                                  <span
                                    className={
                                      this.state.is_submitted && !this.search_criteria.company_id
                                        ? "req-alert"
                                        : "req-alert_normal"
                                    }
                                    dangerouslySetInnerHTML={{ __html: " (required)" }}
                                  />
                                </label>
                                <SelectionComponent
                                  id="company_id"
                                  name="company_id"
                                  options={this.company_options}
                                  onChange={(value, event) => {
                                    this.dropdown_change_handler(event);
                                  }}
                                  defaultValue={this.search_criteria.company_id}
                                  style={"dropdown-options-wrap"}
                                />
                              </Form.Field>
                            </Grid.Column>
                            <Grid.Column tablet={8} computer={4}>
                              <Form.Field>
                                <label>835 Date Range</label>
                                <DateRangePickerComponent
                                  updateDatesChange={this.range_date_change_handler}
                                  startDate={this.search_criteria.eob_date_from}
                                  endDate={this.search_criteria.eob_date_to}
                                  error={is_error}
                                  maxDate={moment()}
                                />
                              </Form.Field>
                            </Grid.Column>
                            <Grid.Column tablet={8} computer={4}>
                              <Form.Field>
                                <label>Check Number</label>
                                <Input
                                  autoComplete="off"
                                  name="check_eft_num"
                                  id="check_eft_num"
                                  onChange={this.input_change_handler}
                                  value={this.search_criteria.check_eft_num ? this.search_criteria.check_eft_num : ""}
                                  type="text"
                                  maxLength="50"
                                />
                              </Form.Field>
                            </Grid.Column>
                            <Grid.Column tablet={8} computer={4}>
                              <Form.Field className="advance-quick-search">
                                <label>Inbound Payer</label>
                                <AdvancedControl
                                  disabled_element={false}
                                  onGridRowSelection={this.on_advanced_grid_row_select}
                                  gridConfig={this.state.payer_search_data}
                                  controlId={"EOB_PAYER"}
                                  onSearch={this.on_payer_search}
                                  search_type={"EOB_PAYER"}
                                  headerIdForGridTabNavigation={"payer"}
                                />
                                <AutoSearchComponent
                                  errorClass={""}
                                  getInputValue={this.get_input_value}
                                  default_value={this.state.selected_payer}
                                  errorMessage={"No Record Found !"}
                                  prepareRenderList={this.renderResult}
                                  getList={this.get_payer_list}
                                  prepareDataList={(data) => this.prepare_suggestion(data)}
                                  selectresult={(item) => this.on_item_selection(item)}
                                  show_clear_search={true}
                                  clear_search={this.clear_quick_search}
                                />
                              </Form.Field>
                            </Grid.Column>
                          </Grid>
                          <Grid>
                            <Grid.Column tablet={8} computer={4}>
                              <Form.Field>
                                <label>Load Date</label>
                                <DatePicker
                                  id={"load_date"}
                                  name={"load_date"}
                                  date_update={(date_object) => this.calendar_change_handler("load_date", date_object)}
                                  is_required={false}
                                  date={
                                    this.search_criteria.load_date && moment(this.search_criteria.load_date)
                                      ? moment(this.search_criteria.load_date)
                                      : null
                                  }
                                  autoFocus={true}
                                  maxDate={moment().format("MM/DD/YYYY")}
                                />
                              </Form.Field>
                            </Grid.Column>
                          </Grid>
                        </Accordion.Content>
                      </Accordion>
                    </Grid.Column>
                  </Grid>
                  <Grid>
                    <Grid.Column tablet={16} computer={16} textAlign="right" className="button_area">
                      <Button
                        type="reset"
                        id="clear_report_button"
                        basic
                        onClick={this.clear_handler}
                        disabled={this.state.is_clear_button_disabled}
                        content={"Clear"}
                      />
                      <Button
                        id="create_report_button"
                        className="primary"
                        type="submit"
                        onKeyDown={this.handle_navigation_after_last_element}
                        style={{ marginRight: "0" }}
                        disabled={this.state.is_save_button_disabled}
                        content={"Search"}
                      />
                    </Grid.Column>
                  </Grid>
                </Form>
              </div>
              {this.state.is_grid_visible && (
                <React.Fragment>
                  <GridView
                    id={this.release835s.report_grid_id}
                    row={this.report_data.rows}
                    column={this.release_835s_column_def}
                    headerHeight={this.grid_header_height}
                    enableColResize={true}
                    emptyMessage={messages.no_records_found}
                    get_grid_ref={this.get_grid_ref}
                    headerIdForTabNavigation={report_ledger_full_configuration_header_id}
                    suppressSizeToFit={true}
                    on_filter_button_click={() => {
                      this.on_filter_button_click();
                    }}
                    style={{ height: gridHeight }}
                  />
                  <div
                    className="patient-search-form  patient_search_bottom_padding"
                    style={{ border: 0, background: "transparent" }}
                  >
                    <Form>
                      <Grid>
                        <Grid.Column tablet={8} computer={4}>
                          <Form.Field>
                            <label>Deposit Date</label>
                            <DatePicker
                              id={"deposit_date"}
                              name={"deposit_date"}
                              date_update={(date_object) => this.calendar_change_handler("deposit_date", date_object)}
                              is_required={false}
                              date={
                                this.release_criteria.deposit_date && moment(this.release_criteria.deposit_date)
                                  ? moment(this.release_criteria.deposit_date)
                                  : null
                              }
                              autoFocus={true}
                              maxDate={moment().format("MM/DD/YYYY")}
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column tablet={8} computer={4}>
                          <Form.Field>
                            <label>Batch Number</label>
                            <Input
                              autoComplete="off"
                              name="batch_number"
                              id="batch_number"
                              onChange={this.input_change_handler}
                              value={this.release_criteria.batch_number ? this.release_criteria.batch_number : ""}
                              type="text"
                              maxLength="40"
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column
                          tablet={16}
                          computer={8}
                          style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}
                        >
                          <Button
                            style={{ marginRight: "0" }}
                            id="is_process_disabled"
                            onClick={this.release_eob_835s}
                            type="submit"
                            disabled={this.state.is_process_disabled}
                            content={"Process"}
                            primary
                          />
                        </Grid.Column>
                      </Grid>
                    </Form>
                  </div>
                </React.Fragment>
              )}
            </div>
            {this.state.is_release_grid_visible && (
              <React.Fragment>
                <div style={{ border: "1px solid #cccccc", background: " #fff", marginTop: 20 }}>
                  <div className="ui grid headerGrid" style={{ margin: 0, paddingTop: 5 }}>
                    <div className="sixteen wide computer column">
                      <h3 className="ui header left aligned release835s">Current Release Status</h3>
                    </div>
                  </div>
                  <GridView
                    id={this.release835s.release_grid_id}
                    row={this.report_data.release_rows}
                    column={this.release_column_def}
                    headerHeight={this.grid_header_height_release}
                    enableColResize={true}
                    emptyMessage={messages.no_records_found}
                    get_grid_ref={this.get_grid_ref_release}
                    headerIdForTabNavigation={report_ledger_full_configuration_header_id}
                    onRowSelection={(item) => this.on_check_box_selection(item)}
                    suppressSizeToFit={true}
                    gridAutoHeight={true}
                    checkboxSelection={true}
                    handleCheckboxNavigation={true}
                  />
                </div>
                <Grid style={{ marginTop: 0, marginBottom: 0 }}>
                  <Grid.Column tablet={16} computer={16} textAlign="right">
                    <Button
                      id="is_close_disabled"
                      className="primary"
                      onClick={this.update_eob_835s}
                      type="button"
                      onKeyDown={this.handle_navigation_after_last_element}
                      style={{ marginRight: "0" }}
                      disabled={this.state.is_close_disabled}
                      content={"Close"}
                    />
                  </Grid.Column>
                </Grid>
              </React.Fragment>
            )}
          </div>
          <div
            className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area"
            id="applicationFooterSticky"
            style={{ paddingRight: 25 }}
          >
            <ReportFooter
              on_print_button={this.on_print_button}
              on_export_button={this.on_export_button}
              export_disabled={this.state.is_export_disabled}
              print_disabled={this.state.is_print_disabled}
            />
          </div>
        </div>
      </div>
      {this.state.isDrawerOpen && <DrawerComponent
        key={this.props.key_id}
        canOutsideClickClose={false}
        canEscapeKeyClose={false}
        isOpen={this.state.isDrawerOpen}
        className="ext-bp-drawer provider-drawer billing-provider-drawer"
        handleDrawerClose={this.handleDrawerClose}
        position={Position.RIGHT}
        title=""
        isCloseButtonShown={true}
        innerContent={this.getPayerRulesAddComponent()}
        size={Drawer.SIZE_STANDARD}
        inbounds={[]}
        payer_codes={[]}
      />}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      user_companies: user_companies
    },
    dispatch
  );
};

// Get user and login details from store.
const mapStateToProps = (state) => {
  return {
    user_login_details: state.user_login_details,
    hasAccessToPayerRulesPage: _.some(state.shared_details.left_menu_data.data, { name: payerRulesPageName, 'view': true }),
    selected_patient: state.patient_details.patient_header,
    is_expand: state.shared_details.is_expand,
    user_companies_response: state.user_details.user_companies_response,
    features: {
        sumajBill636EnhancedPayerRules: get_lauch_darkley_key_value(state.launch_darkly, sumaj_bill_636_enhanced_payer_rules),
        argosBill1162Editable835S: get_lauch_darkley_key_value(state.launch_darkly, argos_bill_1162_editable_835s)
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Release835sComponent);
