import * as React from "react";
import { connect } from "react-redux";
import { Button, Dimmer, Grid, Header, Loader } from "semantic-ui-react";
import { clearTimeout, setTimeout } from 'timers';
import * as session_storage from '../../shared/session_storage_utility';
import { format_patient_name, execute_print } from "../../shared/utility";
import * as report_service from "../action/report_action";
import * as report_constants from "../report_constants";
import * as report_data_formatter from '../util/report_data_formatter';
import GridView from "./../../shared/component/grid";
import { saveAs } from './../../shared/file-saver.min';
import { SubReportHeader } from './report_header';
import { set_focus_on_element_with_id, set_focus_to_app_header } from './../../shared/tab_navigation_utility';
import { get_columns } from '../../shared/utility';

class LedgerVisitClaimAdjustmentComponent extends React.Component<any, any> {
    _is_mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            is_print_disabled: true,
            patient_adjustments_grid_ref: {},
            claims_grid_ref: {},
            isVisible: false,
            is_back_button: false
        };
    }
    MIN_WIDTH = 100;
    company_name = '';
    patient_name = '';
    account_no = null;
    current_date = '';
    title = '';
    report_data = {
        payment_adjustment: [],
        claims: [],
        title: ""
    };

    payment_adjustment_columns = report_constants.report_payment_adjustment_configuration.column_defs;
    payment_adjustment_total_column_width: any;
    payment_adjustment_max_header_height: any;
    report_claims_columns = report_constants.report_claims_configuration.column_defs;
    report_claims_total_column_width: any;
    report_claims_max_header_height: any;

    UNSAFE_componentWillMount() {
        this.set_url_history();
        var report_params = decodeURIComponent(this.props.location.search);
        report_params = report_params.replace('?', '');
        var charge_id = parseInt(report_params);
        var token = this.props.user_login_details.user_data.data.token_details.access_token;
        if (charge_id) {
            report_service.get_patient_payment_adjustments(token, charge_id).then(response => {
                if (response) {
                    this.report_data.payment_adjustment = this.row_data_formatter(
                        response.data.data,
                        report_constants.report_payment_adjustment_configuration.column_defs
                    );
                    const payment_adjustment = get_columns(response.data.data, this.payment_adjustment_columns);
                    this.payment_adjustment_total_column_width = payment_adjustment.total_column_width;
                    this.payment_adjustment_max_header_height = payment_adjustment.header_height
                }
                report_service.get_patient_claim_data(token, charge_id).then(response => {
                    if (response) {
                        this.report_data.claims = this.row_data_formatter(response.data.data, report_constants.report_claims_configuration.column_defs);
                        const report_claims = get_columns(response.data.data, this.report_claims_columns);
                        this.report_claims_total_column_width = report_claims.total_column_width;
                        this.report_claims_max_header_height = report_claims.header_height;
                    }
                    report_service.get_charge_report_title(token, charge_id).then(response => {
                        if (response) {
                            this.report_data.title = response.data.data;
                            this.setState({
                                loading: false,
                                is_print_disabled: false,
                                isVisible: true
                            });
                        }
                    });
                });
            });
        }
    }
    componentWillUnmount = () => {
        this._is_mounted = false;
        if (this.state.timeout) {
            clearTimeout(this.state.timeout)
        }
        if (this.state.timeout_adjustment) {
            clearTimeout(this.state.timeout_adjustment)
        }
        document.body.classList.remove('reports', 'patient-header', 'patient-subHeader');
    }
    componentDidMount() {
        this._is_mounted = true;
        document.body.classList.add('reports');
    }

    set_url_history = () => {
        var drill_down_count = session_storage.get("drill_down_count");
        var url_history = session_storage.get("url_history");
        if (url_history.length == 2) {
            url_history.push(this.props.match.url);
            drill_down_count++;
        }
        session_storage.set("drill_down_count", drill_down_count);
        session_storage.set("url_history", url_history);
    };

    //Function use to format the grid data on load.
    row_data_formatter = (data, column_def) => {
        data.map(row => {
            column_def.map(value => {
                switch (value.type) {
                    case "date":
                        row[value.field] = row[value.field] != null || row[value.field] != undefined ? report_constants.dateFormatter(row[value.field]) : "";
                        break;
                    case "currency":
                        row[value.field] = row[value.field] != null || row[value.field] != undefined ? report_constants.currencyFormatter(row[value.field]) : report_constants.currencyFormatter(0);
                        break;
                    case "number":
                        row[value.field] = row[value.field] != null || row[value.field] != undefined ? parseInt(row[value.field]) : 0;
                        break;
                    default:
                        row[value.field] = row[value.field] != null || row[value.field] != undefined ? row[value.field].toString().trim() : "";
                    // value.minWidth = value.minWidth < row[value.field].length * 7 + 50 ? row[value.field].length * 7 + 50 : value.minWidth;
                }
            });
            return row;
        });

        return data;
    };

    //Function calls on intialization of Ag-Grid and catch its reference.
    get_claims_grid_ref = grid_params => {
        this.setState({
            claims_grid_ref: grid_params,
            timeout: setTimeout(() => {
                grid_params.columnApi.autoSizeAllColumns();
            }, 100)
        });
        setTimeout(() => {
            grid_params.columnApi.autoSizeAllColumns();
        }, 100)
    };

    //Function calls on intialization of Ag-Grid and catch its reference.
    get_patient_adjustments_grid_ref = grid_params => {
        this.setState({
            patient_adjustments_grid_ref: grid_params,
            timeout_adjustment: setTimeout(() => {
                grid_params.columnApi.autoSizeAllColumns();
            }, 100)
        });
        // setting up the default focus
        set_focus_on_element_with_id(report_constants.report_payment_adjustment_header_id)
    };

    //Function calls on initialization of export report data
    on_export_button = () => {
        this.title = this.set_title('\n');
        var field_type;
        var report_name = 'Payment Adjustments and Billing Log Detail';
        var csv_data: any;
        var params = {
            processCellCallback: param => {
                field_type = param.column.colDef.type;
                if (field_type) {
                    if (field_type == 'hyper_link' && param.value != 'Total') {
                        param.value = report_data_formatter.get_link_value(param.value);
                    }
                }
                return param.value;
            },
            fileName: report_name
        };
        csv_data =
            this.title +
            'Payment and Adjustments' +
            '\n' +
            this.state.patient_adjustments_grid_ref.api.getDataAsCsv(params) +
            '\n' +
            '\n' +
            '\n' +
            '\n' +
            'Claims' +
            '\n' +
            this.state.claims_grid_ref.api.getDataAsCsv(params)
        var blob = new Blob([csv_data], {
            type: 'text/csv;charset=utf-8'
        });
        //Blob.js
        saveAs(blob, report_name + '.csv');
    };

    set_title = (separter) => {
        this.company_name = this.props.user_login_details.user_data.data.gpms_code + "-" + this.props.user_login_details.user_data.data.company_name;
        this.patient_name = format_patient_name(this.props.selected_patient.data.name);
        this.account_no = 'Account#:' + session_storage.get("active_patient");
        this.current_date = report_constants.dateFormatter(this.state.current_date);
        return this.title =
            'Payment Adjustments and Billing Log Detail' +
            separter +
            this.company_name +
            separter +
            this.patient_name +
            separter +
            this.account_no +
            separter +
            this.current_date +
            separter +
            separter;
    }

    //Function calls on click of print button.
    on_print_button = () => {
        var printElement: any;
        var print_report_button = document.getElementById('print_report_button');
        var ua = window.navigator.userAgent;
        if (document.getElementById("printElement")) {
            document.getElementById("printElement").remove();
            document.body.style.overflow = null
        }
        printElement = document.createElement("div");
        printElement.setAttribute("id", "printElement");
        var printContent = "";
        //var company_name = this.props.user_login_details.user_data.data.gpms_code + "-" + this.props.user_login_details.user_data.data.company_name;
        //var patient_name = format_patient_name(this.props.selected_patient.data.name);
        //var account_no = 'Account#:' + session_storage.get("active_patient");
        //var current_date = report_constants.dateFormatter(this.state.current_date);
        //var title = company_name + "<br>" + 'Charge Details <br>' + patient_name + "<br>" + account_no + "<br>" + current_date;
        printContent += '<div class="reportPrint">';
        this.title = this.set_title('<br>');
        printContent += this.title;
        printContent += "<div style='font-weight: bold;font-size:16px'>Payment and Adjustments</div><br>";
        printContent += '<div class="reportPrint"><table><thead><tr>';
        var row_api = this.state.patient_adjustments_grid_ref.api;
        var col_api = this.state.patient_adjustments_grid_ref.columnApi;
        var header_data = col_api.getAllColumns();

        header_data.forEach(function (value, index) {
            printContent += "<th>" + value.colDef.headerName + "</th>";
        });
        printContent += "</tr></thead>";

        if (row_api.getModel().getRowCount() > 0) {
            row_api.forEachNodeAfterFilterAndSort(function (data, index) {
                printContent += "<tr>";
                header_data.forEach(function (colData, index) {
                    printContent += "<td>" + data.data[colData.colDef.field] + "</td>";
                });
                printContent += "</tr>";
            });
        } else {
            printContent += '<tr><td colspan="100%" class="norecords">' + report_constants.messages.no_records_found + "</td></tr>";
        }
        printContent += "</table></div><br>";

        printContent += "<div style='font-weight: bold;font-size:16px'>Claim Log</div><br/>";
        printContent += '<div class="reportPrint"><table><thead><tr>';
        row_api = this.state.claims_grid_ref.api;
        col_api = this.state.claims_grid_ref.columnApi;
        header_data = col_api.getAllColumns();

        header_data.forEach(function (value, index) {
            printContent += "<th>" + value.colDef.headerName + "</th>";
        });
        printContent += "</tr></thead>";
        if (row_api.getModel().getRowCount() > 0) {
            row_api.forEachNodeAfterFilterAndSort(function (data, index) {
                printContent += "<tr>";
                header_data.forEach(function (colData, index) {
                    printContent += "<td>" + data.data[colData.colDef.field] + "</td>";
                });
                printContent += "</tr>";
            });
        } else {
            printContent += '<tr><td colspan="100%" class="norecords">' + report_constants.messages.no_records_found + "</td></tr>";
        }
        printContent += "</table></div>";
        printContent += "</div>";
        printElement.innerHTML = printContent;
        document.body.setAttribute('style', 'overflow:visible!important');
        document.body.appendChild(printElement);

        if (window.print) {
            if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
                setTimeout(function () {
                    execute_print();
                }, 100)

                setTimeout(function () {
                    document.body.style.overflow = null;
                    printElement.remove();
                    print_report_button.blur();
                }, 5000)

            }
            else {
                if (window.matchMedia) {
                    var mediaQueryList = window.matchMedia('print');
                    mediaQueryList.addListener(function(mql) {
                        if (mql.matches) {
                        } else {
                            afterPrint();
                        }
                    });
                }
                var afterPrint = function () {
                    if (ua.indexOf("Trident/7.0") > -1) {
                        document.body.removeChild(printElement);
                    } else {
                        printElement.remove();
                    }
                    document.body.style.overflow = null;
                    print_report_button.blur();
                };
                // These are for Firefox
                window.onafterprint = afterPrint;
                execute_print();
            }
        }
    };
   
    on_back_button = () => {
        var drill_down_count = session_storage.get("drill_down_count");
        var url_history = session_storage.get("url_history");
        url_history.pop();
        drill_down_count--;
        session_storage.set("drill_down_count", drill_down_count);
        session_storage.set("url_history", url_history);
        this.props.history.replace(url_history[url_history.length - 1]);
        window.location.reload();
    };

    on_initial_result_button = () => {
        var drill_down_count = session_storage.get("drill_down_count");
        var url_history = session_storage.get("url_history");
        var new_url_history = [];
        new_url_history.push(url_history[0]);
        drill_down_count = 1;
        session_storage.set("drill_down_count", drill_down_count);
        session_storage.set("url_history", new_url_history);
        this.props.history.replace(url_history[0]);
        window.location.reload();
    };

    render() {
        return (
            <div className={'common-forms-add financial'}>
                <Dimmer active={this.state.loading}>
                    <Loader size="massive">Loading</Loader>
                </Dimmer>
                <div className={'common-forms-search report-framework subReport'}>
                    <SubReportHeader title={"Payment Adjustments and Billing Log Detail"} showDate={false} />
                    {this.state.isVisible &&
                        <React.Fragment>
                            <div id='report-scrollable-area' className='report-wrapper multiple'>
                                <Header as="h3">Payment and Adjustments</Header>
                                <GridView
                                    id={report_constants.report_ledger_full_configuration.patient_report_grid_id}
                                    style={{ height: "100%" }}
                                    row={this.report_data.payment_adjustment}
                                    column={this.payment_adjustment_columns}
                                    suppressMovableColumns={false}
                                    suppressColumnVirtualisation={true}
                                    enableColResize={true}
                                    emptyMessage={report_constants.messages.no_records_found}
                                    get_grid_ref={this.get_patient_adjustments_grid_ref}
                                    headerHeight={this.payment_adjustment_max_header_height}
                                    headerIdForTabNavigation={report_constants.report_payment_adjustment_header_id}
                                />
                                <Header as="h3">Claim Log</Header>
                                <GridView
                                    id={report_constants.report_ledger_full_configuration.patient_report_grid_id}
                                    style={{ height: "100%" }}
                                    row={this.report_data.claims}
                                    column={this.report_claims_columns}
                                    suppressColumnVirtualisation={true}
                                    suppressMovableColumns={false}
                                    enableColResize={true}
                                    emptyMessage={report_constants.messages.no_records_found}
                                    get_grid_ref={this.get_claims_grid_ref}
                                    headerHeight={this.report_claims_max_header_height}
                                    headerIdForTabNavigation={report_constants.report_claims_header_id}
                                />
                            </div>
                            <div className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area' id='applicationFooterSticky'>
                                <Grid.Column computer={16} textAlign="right">
                                    <Button type='reset' id="export_report_button" onClick={this.on_export_button} basic disabled={this.state.export_disabled}>Export</Button>
                                    <Button onKeyDown={(event) => {
                                        set_focus_to_app_header(event)
                                    }} type="submit" id="print_report_button" onClick={this.on_print_button} primary disabled={this.state.is_print_disabled}>Print</Button>
                                </Grid.Column>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
        selected_patient: state.patient_details.patient_header,
    };
};

export default connect(mapStateToProps)(LedgerVisitClaimAdjustmentComponent);