export const end_points = {
    search: '/charge/reactivate_search',
    initial_load: '/charge/reactivate_initial_load',
    reactivate: '/charge/reactivate_charge/reactivate',
};

export const metadata = {
    title: "Reactivate Charge",
};

export const messages = {
    required_charge_id: "The Charge ID is required.",
    invalid_charge_id: "The Charge ID entered is invalid.",
};
