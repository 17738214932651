// Utility functions
export const DX_API_PAGE_SIZE = 100;
export const dx_keys = {
    dx1: null,
    dx2: null,
    dx3: null,
    dx4: null,
    dx5: null,
    dx6: null,
    dx7: null,
    dx8: null
};

/**
 * Diagnosis multi selection value assigning from starting
 * No order selection
 * @param data
 */
export const diagnosis_values = (data) => {
    let dx = { ...dx_keys };

    //Format dx value as per multi select list
    let dxi = 1;
    for (let i = 0; i < 8; i++) {
        const key = `dx${i + 1}`;
        if (data[key]) {
            dx[`dx${dxi}`] = data[key];
            dx[`dx${dxi}_desc`] = data[`dx${i + 1}_desc`];
            dx[`dx${dxi}_icd_type`] = data[`dx${i + 1}_icd_type`];
            dxi++;
        }
    }
    return dx;
}

export const mod_keys = {
    proc_mod1_id: 0,
    proc_mod2_id: 0,
    proc_mod3_id: 0
};

/**
 * Modifires multi selection value assigning from starting
 * No order selection
 * @param data
 */
export const modifire_values = (data) => {
    let mod = { ...mod_keys };

    //Format modifire value as per multi select list
    let modi = 1;
    for (let i = 0; i < 3; i++) {
        const key = `proc_mod${i + 1}_id`;
        if (data[key]) {
            mod[`proc_mod${modi}_id`] = data[key];
            mod[`proc_mod${modi}`] = data[`proc_mod${i + 1}`];
            mod[`proc_mod${modi}_desc`] = data[`proc_mod${i + 1}_desc`];
            modi++;
        }
    }
    return mod;
}

export const format_multi_select_data = (source, type) => {
    let target = [];
    let screenWidth = window.screen.width;
    let subVal = 8;
    if (screenWidth > 1364) {
        subVal = 12;
    }
    if (source && source.length > 0) {
        for (let y = 0; y < source.length; y++) {
            let item = source[y];
            let text_val = (type == 'modifiers') ? (item.procedure_modifier_code ? item.procedure_modifier_code : '') + ' - ' + (item.procedure_modifier_desc ? item.procedure_modifier_desc : '') : (item.code ? item.code : '') + ' - ' + (item.description ? item.description : '');

            target.push({
                disabled: false,
                is_checked: false,
                name: text_val,
                ...item,
                value: (type == 'modifiers') ? item.id : item.code,
                label_text: text_val.substring(0, subVal)
            })
        }
    }
    return target;
}
