import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Dimmer, Form, Grid, Loader, Input } from "semantic-ui-react";
import { set_focus_on_element_with_id } from '../../../shared/tab_navigation_utility' 
import GridView from '../../../shared/component/grid';
import { toastr } from 'react-redux-toastr';
import { get_all_error, get_columns} from '../../../shared/utility';
import { toastr_options } from '../../../global_constants';
import * as constants from '../constants';
import * as session_storage from '../../../shared/session_storage_utility';
import { get_patientList, account_rollup } from '../../../patient/action/patient_action';

export class AccountRollUpComponent extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            show_grid: false,
            is_search_disabled: false,
            is_rollup_disabled: false,
            grid_params: [],
            title: 'Account RollUp',
            company_name: props.user_login_details.user_data.data.gpms_code + " - " + props.user_login_details.user_data.data.company_name,
            is_error: false,
            grid_rows_count: 0,
            rollup_pending: true,
            search_criteria: {
                patient_id: ""
            },
            selected_patient: []
        };
        this.handle_clear = this.handle_clear.bind(this);
    }
    total_column_width: number = 0;
    grid_header_height: number = 0;
    width_padding: number = 100;
    grid_data: any = {
        rows: [],
        grid_conf: constants.account_rollup_configuration.column_defs,
    };

    componentDidMount = () => {
        document.body.classList.add('admin-framework');
        let account_rollup_search = session_storage.get(`account_rollup_criteria`);

        if (account_rollup_search && account_rollup_search.search_criteria) {
            let updatedCriteria = { ...account_rollup_search.search_criteria, patient_id: account_rollup_search.search_criteria.patient_id ? account_rollup_search.search_criteria.patient_id : "" }
            this.setState({ search_criteria: updatedCriteria }, () => {
                this.handle_search();
            })
        }
    }

    componentWillUnmount = () => {
        document.body.classList.remove('admin-framework');
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[2] !== 'account_rollup' && session_storage.remove('account_rollup_criteria');
        }
    }

    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }

    update_report_rows_count = () => {
        this.setState({
            grid_rows_count: this.state.grid_params.api.getDisplayedRowCount()
        });

        var grid_width = document.getElementsByClassName('admin-wrapper')[0].clientWidth;

        if (this.total_column_width > (grid_width - this.width_padding)) {
            this.grid_data.grid_conf.filter(item => {
                this.grid_data.grid_conf.setColumnWidth(item.field, item.width, false);
            });
        }
    };

    get_grid_ref = grid_params => {
        this.setState({
            grid_params,
            grid_rows_count: grid_params.api.getModel().getRowCount()
        });
        var grid_width = document.getElementsByClassName('admin-wrapper')[0].clientWidth;
        if (this.total_column_width > (grid_width - this.width_padding)) {
            this.grid_data.grid_conf.filter(item => {
                 grid_params.columnApi.setColumnWidth(item.field, item.width, false);
            });
        }
    };

    on_grid_out = () => {
        set_focus_on_element_with_id('app_header_dropdown');
    };

    max_value_validation = (input) => {
        if (Number(input) > 2147483647) {
            return this.max_value_validation(input.substring(0, input.length - 1));
        }
        else {
            return Number(input);
        }
    }

    handle_change = (e) => {
        const { name, value } = e.target;

        let newValue = (e.target.validity.valid) ? 
                            Number(value) <= 2147483647 ? 
                                value : 
                                this.max_value_validation(value) :
                            this.state.search_criteria.patient_id;
        this.setState(prevState => ({
            search_criteria: { ...prevState.search_criteria, [name]: newValue },
            is_error: value ? false : true
        }));
    };

    handle_clear = () => {
        this.setState({
            show_grid: false,
            is_search_disabled: false,
            is_rollup_disabled: false,
            search_criteria: {
                patient_id: ""
            },
            grid_rows_count: 0,
            loading: false,
            is_error: false,
            rollup_pending: true, 
            selected_patient: []
        });
        this.grid_data = {
            rows: [],
            grid_conf: constants.account_rollup_configuration.column_defs
        }
       
        session_storage.remove(`account_rollup_criteria`);
    }

    handle_cancel = () => {
        this.setState({
            show_grid: false,
            is_search_disabled: false,
            is_rollup_disabled: false,
            grid_rows_count: 0,
            loading: false,
            is_error: false,
            rollup_pending: true,
            selected_patient:[]
        });
        this.grid_data = {
            rows: [],
            grid_conf: constants.account_rollup_configuration.column_defs
        }
    }

    get_full_name = (row) => {
        let name = null;
        if(row.first_name) {
            name = row.first_name; 
        }
        if(row.middle_name) {
            name += " "+row.middle_name; 
        }
        if(row.last_name) {
            name += " "+row.last_name; 
        }

        return name;
    }

    row_data_formatter = (data, internal_data) => {
        data.map(row => {
            this.grid_data.grid_conf.map((value) => {
                switch (value.type) {
                    case 'company_name':
                        row[value.field] = internal_data.company_name;
                        break;
                    case 'company_id':
                        row[value.field] = internal_data.company_id;
                        break;        
                    case 'name':
                        row[value.field] = this.get_full_name(row);
                        break;        
                    default:
                        row[value.field] = row[value.field] ? row[value.field].toString().trim() : "";
                }
            });
            return row;
        });

        return data;
    }

    handle_search = async (event = null) => {
        if (!this.state.search_criteria.patient_id) {
            this.setState({ is_error: true });
            toastr.error('', 'The Patient ID is invalid.');
            return;
        }

        this.setState({
            is_search_disabled: true,
            is_rollup_disabled: true,
            loading: true,
            show_grid: false,
            rollup_pending: true,
            selected_patient:[]
        });

        this.grid_data = {
            rows: [],
            grid_conf: constants.account_rollup_configuration.column_defs,
        }

        if (this.state.is_error && event) {
            event.preventDefault();
        } else {
            var token = this.props.user_login_details.user_data.data.token_details.access_token;
            let criteria = this.state.search_criteria
            let company_id = this.props.user_login_details.user_data.data.company_id;

            let response = await get_patientList(token, company_id, criteria.patient_id, 100, true);
            if (response && response.data.status == 1) {
                if (response.data && response.data.data && response.data.data.length > 0) {
                    this.grid_data.rows = this.row_data_formatter(response.data.data, this.props.user_login_details.user_data.data);
                    session_storage.set(`account_rollup_criteria`, {
                        search_criteria: this.state.search_criteria
                    });
                    const grid_height = get_columns(this.grid_data.rows, this.grid_data.grid_conf);
                    this.total_column_width = grid_height.total_column_width;
                    this.grid_header_height = grid_height.header_height;
                    this.setState({
                        is_search_disabled: false,
                        is_rollup_disabled: false,
                        loading: false,
                        show_grid: true,
                        grid_rows_count: this.grid_data.rows.length,
                        rollup_pending: true
                    });
                }
                if (response.data && response.data.data && response.data.data.length == 0) {
                    this.handle_cancel()
                    toastr.error("", "The Patient ID is invalid.");
                }
            } else {
                const toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                toastr.error('', toastr_options);
                this.setState({
                    is_search_disabled: false,
                    is_rollup_disabled: false,
                    loading: false,
                    show_grid: true,
                    grid_rows_count: this.grid_data.rows.length
                });
            }
        }
    }

    on_check_box_selection = (data) => {
        this.setState({
            [`selected_patient`]: data
        });
    }

    handle_rollup_patients = async () => {
        if (this.state[`selected_patient`].length == 0) {
            toastr.error('', 'No patient selected.');
            return;
        }
        if (this.state[`selected_patient`].length > 0) {
            // Create Query Param from checked items
            const params = this.state[`selected_patient`].map((value) => {
                return { patient_id: value.id };
            });
            this.setState({
                loading: true,
                is_search_disabled: true,
                is_rollup_disabled: true
            });

            var token = this.props.user_login_details.user_data.data.token_details.access_token;
            await account_rollup(token, params[0].patient_id).then(
                (response) => {
                    if (response.data && response.data.status === 1) {
                        toastr.success("", "Account Rollup Complete.");
                        this.setState({
                            loading: false,
                            is_search_disabled: false,
                            is_rollup_disabled: false,
                            rollup_pending: false,
                            selected_patient: []
                        });
                    } else {
                        if (response.data.messages && response.data.messages[0].message.indexOf("<br") > -1) {
                            let toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                            toastr.error("", toastr_options);
                        }
                        this.setState({
                            loading: false,
                            is_search_disabled: false,
                            is_rollup_disabled: false,
                            rollup_pending: true
                        });
                    }
                },
                (error) => {
                    this.setState({
                        loading: false,
                        is_search_disabled: false,
                        is_rollup_disabled: false,
                        rollup_pending: true
                    });
                    if (error.response.data) {
                        const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                        toastr.error("", toastr_options);
                    }
                }
            );
        }
    };

    render() {
        const { loading, show_grid, search_criteria, is_search_disabled, is_rollup_disabled, grid_rows_count, is_error, company_name, rollup_pending  } = this.state
        return (
            <React.Fragment>
                <Dimmer active={loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <div className='admin-wrapper' style={this.grid_data.rows.length != 0 ? { paddingBottom: 20 } : {}}>
                    <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                        <Grid.Column computer={16}>
                            <h3 className='ui header left aligned'>Account Rollup</h3>
                        </Grid.Column>
                    </Grid>
                    <div id='admin-scrollable-area' className='wrapper' style={show_grid ? { flex: 1 } : {}}>
                        <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container'>
                            <Form id="account_rollup_container" autoComplete='off' onSubmit={e => this.handle_search(e)}>
                                <Grid style={{ marginBottom: 0 }}>
                                    <Grid.Column computer={16} >
                                        <Grid>
                                            <Grid.Column tablet={8} computer={4}>
                                               <Form.Field >                                                
                                                  <label>Company</label>
                                                  <span style={{ fontSize: 16 }}>{company_name}</span>
                                               </Form.Field>
                                           </Grid.Column>
                                           <Grid.Column tablet={8} computer={4}>
                                                <Form.Field >
                                                    <label>Enter Patient ID <span className={is_error ? "req-alert" : 'req-alert_normal'}>(required)</span></label>
                                                    <Input fluid autoFocus
                                                        id='patient_id'
                                                        maxLength={10}
                                                        name='patient_id'
                                                        pattern='[0-9]*'
                                                        value={search_criteria.patient_id || ""}
                                                        onChange={(e) => this.handle_change(e)}
                                                        className={is_error ? 'req-border-inp': "" }
                                                        error={is_error}
                                                    />
                                                </Form.Field>
                                           </Grid.Column>
                                        </Grid>
                                    </Grid.Column>
                                </Grid>
                                <Grid style={{ margin: '0 -17px' }}>
                                    <Grid.Column computer={16} textAlign='right'>
                                        <Button type="button" id="clear_form" onClick={this.handle_clear} basic >Clear</Button>
                                        <Button id="account_rollup_search" disabled={is_search_disabled} type='submit' primary>Search</Button>
                                    </Grid.Column>
                                </Grid>
                                {show_grid && (
                                    <Grid style={{ marginTop: '-1rem', marginBottom: 0 }}>
                                        <Grid.Column tablet={5} computer={4} textAlign='left'>
                                            <p style={{ fontSize: '16px' }}>Search Results</p>
                                        </Grid.Column>
                                        <Grid.Column tablet={5} computer={8} textAlign='center'>
                                            {
                                                <p style={{ fontSize: '16px', minHeight: 22 }}>
                                                    {`${grid_rows_count} ${grid_rows_count == 1 ? ' record shown' : ' records shown'
                                                        }`}
                                                </p>
                                            }
                                        </Grid.Column>
                                        <Grid.Column tablet={2} computer={4} textAlign='right' />
                                    </Grid>
                                )}
                            </Form>
                        </div>
                        {show_grid && (
                            <GridView
                                id="account_rollup"
                                row={this.grid_data.rows}
                                column={this.grid_data.grid_conf}
                                style={{ height: '100%' }}
                                wrapperStyle={{ width: '100%', height: '100%', display: 'flex' }}
                                suppressMovableColumns={false}
                                enableColResize={true}
                                selectionType={'single'}
                                checkboxSelection={true}
                                gridAutoRowHeight={true}
                                onRowSelection={(item) => this.on_check_box_selection(item)}
                                get_grid_ref={this.get_grid_ref}
                                suppressSizeToFit={true}
                                headerHeight={this.grid_header_height}
                                headerIdForTabNavigation={constants.search_grid_id}
                                onForceGridOut={this.on_grid_out}
                            />
                        )}
                    </div>
                    {show_grid > 0 && rollup_pending && (
                        <div className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area' id='applicationFooterSticky' style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <Grid.Column computer={16} textAlign='right'>
                                <Button type="button" id="cancel_data" onClick={this.handle_cancel} basic >Cancel</Button>
                                <Button primary id='account_rollup_button' disabled={is_rollup_disabled} type='button' style={{ marginRight: 0 }} onClick={this.handle_rollup_patients}>
                                    Rollup
                                </Button>
                            </Grid.Column>
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountRollUpComponent));