import { IPrinExportDto } from "../audit/print_export_constants";
import { RequestConfig } from "../network/config";
import * as global_constants from '../../global_constants';
import * as ReportConstants from '../../reports/report_constants';
import { Request } from './../../shared/network/request';

export const save_print_export_audit = (reqBody: IPrinExportDto, access_token: any, eventAction: number) => {
    let url = "";
    if (eventAction === ReportConstants.ReportEventAction.Print)
        url = `${global_constants.base_end_points}/print_export_audit/print`;
    else
        url = `${global_constants.base_end_points}/print_export_audit/export`;

    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + access_token
    };
    const requestConfig = new RequestConfig({
        url: url,
        headers: headers,
        method: 'POST',
        data: reqBody
    });
    return new Request().make(requestConfig);
};
