import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { toastr as toaster } from 'react-redux-toastr';
import { Accordion, Button, Dimmer, Form, Grid, Icon, Input, Loader, Modal } from 'semantic-ui-react';
import { option_formater, payer_groups } from '../../admin/constants/constants';
import { get_company_reference } from '../../company/action/company_action';
import { toastr_options } from '../../global_constants';
import ReportHeader from '../../reports/component/report_header';
import {
  currencyFormatter,
  dateFormatter,
  IPayloadForBillingAudit,
  messages,
  report_ledger_full_configuration_header_id
} from '../../reports/report_constants';
import {
  export_grid_pagination_data,
  generateAuditDataEntryPayload,
  multi_grid_export,
  multi_grid_print,
  print_grid_pagination_data
} from '../../reports/util/export_print_utility';
import {
  advanced_search_payer,
  advanced_search_rejection_group,
  log_error,
  quick_search_payer,
  quick_search_rejection_group
} from '../../shared/action/shared_action';
import AdvancedControl from '../../shared/component/advanced_control';
import AutoSearchComponent from '../../shared/component/auto_search_component';
import DateRangePickerComponent from '../../shared/component/date_range_component';
import GridView from '../../shared/component/grid/index';
import SelectionComponent from '../../shared/component/selection_component';
import * as session_storage from '../../shared/session_storage_utility';
import * as local_storage from '../../shared/local_storage_utility';
import { handle_click_on_enter, set_focus_to_app_header } from '../../shared/tab_navigation_utility';
import { get_all_error, get_columns, strip, addClass, removeClass,  } from '../../shared/utility';
import {
  close_e277_claims,
  get_companies_list_by_parent,
  get_e277_claims_search,
  get_parent_companies_by_inv_serv_type
} from '../action/billing_actions';
import { report277_configuration } from '../billing_constants';
import { capitalize, clear_checbox_selection_on_pagination, handle_header_checkbox } from '../utility';
import * as ReportConstants from '../../reports/report_constants';
import { providerBillingSettingsFeatures,  } from '../../admin/constants/constants';

const DropdownInput = ({ label, id, options, change_handler, defaultValue }) => {
  return (
    <Grid.Column tablet={8} computer={4}>
      <Form.Field>
        <label dangerouslySetInnerHTML={{ __html: label }} />
        <SelectionComponent
          id={id}
          name={id}
          options={options}
          onChange={change_handler}
          defaultValue={defaultValue}
          style={'dropdown-options-wrap'}
        />
      </Form.Field>
    </Grid.Column>
  );
};

const Advanced_control = (props) => {
  return (
    <Grid.Column mobile={16} tablet={8} computer={4}>
      <Form.Field className='advance-quick-search'>
        <label>{props.label}</label>
        <AdvancedControl
          onGridRowSelection={props.onGridRowSelection}
          gridConfig={props.gridConfig}
          controlId={props.controlId}
          onSearch={props.onSearch}
          search_type={props.search_type}
          headerIdForGridTabNavigation={props.headerIdForGridTabNavigation}
        />
        <AutoSearchComponent
          getInputValue={props.getInputValue}
          default_value={props.default_value}
          errorMessage={'No Record Found !'}
          prepareRenderList={props.prepareRenderList}
          getList={props.getList}
          prepareDataList={props.prepareDataList}
          selectresult={props.selectresult}
          show_clear_search={true}
          clear_search={props.clear_search}
        />
      </Form.Field>
    </Grid.Column>
  );
};

const Confirmation_export_print = (props) => {
  return (
    <Modal
      onClose={props.closeModal}
      centered={false}
      className='searchCompany'
      open={props.show_modal}
      id={'confirmationReport277Popup'}
      closeIcon
      closeOnDimmerClick={false}
    >
      <Modal.Header>{props.title}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Grid>
            <Grid.Column>
              <Grid>
                <Grid.Column tablet={16} computer={16}>
                  <Form.Field>
                    <label>{props.message}</label>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column tablet={16} computer={16} align='right'>
                  <Button type='cancel' id='btn_cancel' basic onClick={props.on_cancel_click}>
                    Cancel
                  </Button>
                  <Button type='cancel' id='btn_all_results' basic onClick={props.all_results}>
                    All Results
                  </Button>
                  <Button type='submit' id='btn_displayed_results' primary onClick={props.displayed_results}>
                    Displayed Results
                  </Button>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export class Report277Component extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      controls_is_expended: true,
      is_submitted: false,
      is_grid_visible: false,
      is_show_pop_up: false,
      loading: true,
      is_error_dos: false,
      is_error_file: false,
      is_print_disabled: true,
      is_export_disabled: true,
      is_process_disabled: true,
      is_updated_disabled: true,
      is_search_disabled: false,
      is_clear_button_disabled: false,
      report_data: [],
      row_params: {},
      report_rows_count: 0,
      grid_params: null,
      grid_conf: {
        isPagination: true,
        gridAutoHeight: true,
        selectionType: 'single',
        paginationPageSize: 20
      },
      selected_payer: {},
      selected_rejection_group: {},
      selected_835s: [],
      apply_closed_note: false,
      confirm_box_open: false,
      closed_note_selected: ''
    };
  }

  // Define class level variables.
  R4ToR6Route: any = [
    {
      has_parameter: false,
      parameters: null,
      r4_route: 'ReportSelection.aspx',
      r6_route: '/report/sub_report_data?param'
    }
  ];
  report277 = report277_configuration(this.R4ToR6Route);
  report_277_column_def: any = [];
  options_data: any = {
    ...this.report277.options_data
  };
  report_data: any = {
    rows: []
  };
  search_criteria: any = {
    ...this.report277.search_criteria
  };
  title_search_criteria: any = {
    ...this.report277.title_search_criteria
  };
  payer_item = [];
  report_container: any;
  run_report_date = moment().format('MM/DD/YYYY') + ' ' + moment().format('hh:mm:ss A');
  title: string;
  total_column_width: number = 0;
  grid_header_height: number = 0;
  search_enum = {
    payer: 'payer',
    rejection_group: 'rejection_group'
  };
  is_initial_result = false;
  last_col_width = 0;
  grid_width = 0;
  token: any = '';
  is_mounted = false;
  grid_release_data_length: number = 0;
  software_only_company = false;
  reportId: string = '0';
  // Fetching the report id and params.
  UNSAFE_componentWillMount = () => {
    this.token = this.props.user_login_details.user_data.data.token_details.access_token;
    this.report_277_column_def = this.report277.column_defs;
    this.software_only_company = local_storage.get('auth_data').company_service_type == "3";
    if (session_storage.get('report_277_search_criteria')) {
      this.is_initial_result = true;
      this.search_criteria = {
        ...session_storage.get('report_277_search_criteria')
      };
      this.search_criteria = {
        ...this.search_criteria,
        dos_date_from: this.handle_date('dos_date_from'),
        dos_date_to: this.handle_date('dos_date_to'),
        file_date_from: this.handle_date('file_date_from'),
        file_date_to: this.handle_date('file_date_to')
      };
      this.title_search_criteria = {
        ...this.search_criteria
      };
    }
    this.search_criteria = {
      ...this.search_criteria,
      service_type: this.software_only_company ? '3' : this.search_criteria.service_type
    };
  };

  componentDidMount = () => {
    this.is_mounted = true;
    this.get_dropdowns_data();
    document.body.classList.add('reports');
  };

  componentWillUnmount = () => {
    document.body.classList.remove('reports');
    this.is_mounted = false;
    this.search_criteria = {
      ...this.report277.search_criteria
    };
    this.report_data = [];
    session_storage.remove('report_277_search_criteria');
  };

  get_dropdowns_data = async () => {
    let arrData = [];
    if (this.is_mounted) {
      this.setState({
        re_render: true,
        loading: true,
        selected_payer:
          this.is_initial_result && this.search_criteria.payer_code && this.search_criteria.payer
            ? {
                title: this.search_criteria.payer_code + ' - ' + this.search_criteria.payer,
                code: this.search_criteria.payer_code,
                description: this.search_criteria.payer,
                id: this.search_criteria.payer_id,
                label: this.search_criteria.payer_code + ' - ' + this.search_criteria.payer
              }
            : {},
        selected_rejection_group:
          this.is_initial_result && this.search_criteria.rejection_group_code && this.search_criteria.rejection_group
            ? {
                title: this.search_criteria.rejection_group_code + ' - ' + this.search_criteria.rejection_group,
                code: this.search_criteria.rejection_group_code,
                description: this.search_criteria.rejection_group,
                id: this.search_criteria.rejection_group_code,
                label: this.search_criteria.rejection_group_code + ' - ' + this.search_criteria.rejection_group
              }
            : {}
      });
    }
    arrData.push(get_company_reference(this.token, ''));
    arrData.push(
      get_parent_companies_by_inv_serv_type(this.token, { inv_serv_type_id: this.search_criteria.service_type })
    );
    arrData.push(
      get_companies_list_by_parent(this.token, {
        parent_company_id: this.search_criteria.parent_company_id,
        inv_serv_type_id: this.search_criteria.service_type
      })
    );

    await Promise.all(arrData)
      .then((response) => {
        if (response[0].data && response[0].data.data) {
          response[0].data.data.filter((item) => {
            if (item.refrence_type === 'service_type') {
              this.options_data.service_type = option_formater(
                item.refrences,
                'title',
                'value',
                true, // to add blank object
                false, // to sorted object,
                '--ALL--',
                '0'
              );
              return;
            }
          });
          if (!this.is_initial_result && !this.software_only_company) {
            this.search_criteria = {
              ...this.search_criteria,
              service_type: this.options_data.service_type.length > 0 ? this.options_data.service_type[0].value : '-1'
            };
          }
        }
        if (response[1].data && response[1].data.data) {
          this.options_data.parent_company_id = option_formater(
            response[1].data.data,
            'company_name',
            'company_id',
            false, // to add blank object
            false // to sorted object,
          );
          if (!this.is_initial_result) {
            this.search_criteria = {
              ...this.search_criteria,
              parent_company_id:
                this.options_data.parent_company_id.length > 0 ? this.options_data.parent_company_id[0].value : '-1'
            };
          }
        }
        if (response[2].data && response[2].data.data) {
          this.options_data.company_id = option_formater(
            response[2].data.data,
            'company_name',
            'company_id',
            false, // to add blank object
            false // to sorted object,
          );
          if (!this.is_initial_result) {
            this.search_criteria = {
              ...this.search_criteria,
              company_id: this.options_data.company_id.length > 0 ? this.options_data.company_id[0].value : '-1'
            };
          }
        }
        this.setState(
          {
            re_render: false,
            loading: false
          },
          () => {
            this.is_initial_result && this.get_report_data();
          }
        );
      })
      .catch((error) => {
        if (this.is_mounted) {
          this.setState({
            is_show_pop_up: false,
            loading: false
          });
        }
        if (error.response) {
          error.response.map((item) => {
            if (item.error.response && item.error.response.data && item.error.response.data.messages) {
              log_error(item.error);
              const toastr_options = this.show_html_content_toaster(get_all_error(item.error.response.data));
              toaster.error('', toastr_options);
            }
            return;
          });
        }
      });
  };

  update_companies_dropdowns_data = async () => {
    let arrData = [];
    if (this.is_mounted) {
      this.setState({
        re_render: true
      });
    }
    arrData.push(
      get_parent_companies_by_inv_serv_type(this.token, { inv_serv_type_id: this.search_criteria.service_type })
    );
    arrData.push(
      get_companies_list_by_parent(this.token, {
        parent_company_id: '-1',
        inv_serv_type_id: this.search_criteria.service_type
      })
    );
    await Promise.all(arrData)
      .then((response) => {
        if (response[0].data && response[0].data.status === 1) {
          this.options_data.parent_company_id = option_formater(
            response[0].data.data,
            'company_name',
            'company_id',
            false, // to add blank object
            false // to sorted object,
          );
          this.search_criteria = {
            ...this.search_criteria,
            parent_company_id:
              this.options_data.parent_company_id.length > 0 ? this.options_data.parent_company_id[0].value : '-1'
          };
        }
        if (response[1].data && response[1].data.data) {
          this.options_data.company_id = option_formater(
            response[1].data.data,
            'company_name',
            'company_id',
            false, // to add blank object
            false // to sorted object,
          );
          this.search_criteria = {
            ...this.search_criteria,
            company_id: this.options_data.company_id.length > 0 ? this.options_data.company_id[0].value : '-1'
          };
        }
        this.setState({
          re_render: false
        });
      })
      .catch((error) => {
        if (this.is_mounted) {
          this.setState({
            is_show_pop_up: false,
            loading: false
          });
        }
        if (error.response) {
          error.response.map((item) => {
            if (item.error.response && item.error.response.data && item.error.response.data.messages) {
              log_error(item.error);
              const toastr_options = this.show_html_content_toaster(get_all_error(item.error.response.data));
              toaster.error('', toastr_options);
            }
            return;
          });
        }
      });
  };

  update_companies_list_by_parent = async () => {
    this.setState({
      re_render: true
    });
    await get_companies_list_by_parent(this.token, {
      parent_company_id: this.search_criteria.parent_company_id,
      inv_serv_type_id: this.search_criteria.service_type
    }).then(
      (response) => {
        if (response.data && response.data.data) {
          this.options_data.company_id = option_formater(
            response.data.data,
            'company_name',
            'company_id',
            false, // to add blank object
            false // to sorted object,
          );
          this.search_criteria = {
            ...this.search_criteria,
            company_id: this.options_data.company_id.length > 0 ? this.options_data.company_id[0].value : '-1'
          };
          if (this.is_mounted) {
            this.setState({
              re_render: false
            });
          }
        } else {
          if (response.data.messages) {
            log_error(response.data.messages);
            const toastr_options = this.show_html_content_toaster(get_all_error(response.data.messages));
            toaster.error('', toastr_options);
          }
          if (this.is_mounted) {
            this.setState({
              loading: false,
              is_search_disabled: false
            });
          }
        }
      },
      (error: { response: { data: any } }) => {
        if (this.is_mounted) {
          this.setState({
            loading: false,
            is_search_disabled: false
          });
        }
        if (error.response.data) {
          log_error(error);
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error('', toastr_options);
        }
      }
    );
  };

  // Get Report Data.
  get_report_data = async () => {
    let params: any = {
      dos_date_from: this.handle_date('dos_date_from', true),
      dos_date_to: this.handle_date('dos_date_to', true),
      file_date_from: this.handle_date('file_date_from', true),
      file_date_to: this.handle_date('file_date_to', true)
    };
    this.title_search_criteria = {
      ...this.search_criteria,
      ...params,
      service_type: this.get_dropdwon_text('service_type'),
      parent_company_id: this.get_dropdwon_text('parent_company_id'),
      company_id: this.get_dropdwon_text('company_id'),
      close_status: this.get_dropdwon_text('close_status'),
      received_status: this.get_dropdwon_text('received_status'),
      accepted_status: this.get_dropdwon_text('accepted_status')
    };
    params = {
      ...this.search_criteria,
      ...params,
      page_size: 5000
    };
    delete params.payer_code;
    delete params.rejection_group_code;
    this.grid_width = document.getElementsByClassName('report-wrapper')[0].clientWidth;
    if (this.is_mounted) {
      this.setState({
        loading: true,
        is_grid_visible: false,
        is_search_disabled: true,
        is_export_disabled: true,
        is_print_disabled: true,
        is_updated_disabled: true,
        apply_closed_note: false,
        closed_note_selected: ''
      });
    }
    await get_e277_claims_search(this.token, params).then(
      (response) => {
        if (response.data && response.data.data) {
          response.data.data = this.row_data_formatter(response.data.data, this.report_277_column_def, true);
          this.report_data['rows'] = response.data.data || [];
          this.total_column_width = 0;
          const grid_height = get_columns(this.report_data['rows'], this.report_277_column_def);
          this.grid_header_height = grid_height.header_height + 35;
          this.total_column_width = grid_height.total_column_width;
          if (this.is_mounted) {
            this.setState({
              loading: false,
              report_data: this.report_data,
              is_grid_visible: true,
              report_rows_count: this.report_data['rows'] && this.report_data['rows'].length,
              is_search_disabled: false,
              is_export_disabled: false,
              is_print_disabled: false,
              is_updated_disabled: false
            });
          }
        } else {
          if (response.data.messages) {
            log_error(response.data.messages);
            const toastr_options = this.show_html_content_toaster(get_all_error(response.data.messages));
            toaster.error('', toastr_options);
          }
          if (this.is_mounted) {
            this.setState({
              loading: false,
              is_search_disabled: false,
              controls_is_expended: true
            });
          }
        }
      },
      (error: { response: { data: any } }) => {
        if (this.is_mounted) {
          this.setState({
            loading: false,
            is_search_disabled: false,
            controls_is_expended: true
          });
        }
        if (error.response && error.response.data) {
          log_error(error);
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error('', toastr_options);
        }
      }
    );
  };

  // Calender change handler.
  range_date_change_handler = (from_date: any, end_date: any, type: any) => {
    let is_error = false;
    this.search_criteria = {
      ...this.search_criteria,
      [`${type}_date_from`]: from_date || null,
      [`${type}_date_to`]: end_date || null
    };
    if (this.is_mounted) {
      this.setState({
        [`is_error_${type}`]: from_date && moment.isMoment(from_date) ? (end_date && moment.isMoment(end_date) ? false : true) : true
      });
    }
  };

  // Dropdown change handler.
  dropdown_change_handler = (event: {
    target: { selectedOptions?: any; options?: any; selectedIndex?: any; id?: any; value?: any };
  }) => {
    const { id, value } = event.target;
    this.search_criteria[id] = value;
    if (id === 'service_type') {
      this.update_companies_dropdowns_data();
    } else if (id === 'parent_company_id') {
      this.update_companies_list_by_parent();
    }
    if (this.is_mounted) {
      this.setState({
        form_object: this.search_criteria
      });
    }
  };

  // Input change handler.
  input_change_handler = (e) => {
    const { value, name, type, checked } = e.target;
    let closed_note_selected = {};
    if (name === 'apply_closed_note' || name === 'closed_note_selected') {
      if (type === 'checkbox') {
        closed_note_selected[name] = checked;
      } else {
        closed_note_selected[name] = value;
      }
    } else {
      if (type === 'checkbox') {
        this.search_criteria[name] = checked;
      } else {
        if (name === 'patient_id' || name === 'run_id') {
          var txt_pid = e.target.value.trim();
          this.search_criteria[name] = e.target.validity.valid
            ? Number(txt_pid) <= 2147483647
              ? txt_pid
              : this.max_value_validation(txt_pid)
            : this.search_criteria[name];
        } else {
          this.search_criteria[name] = value;
        }
      }
    }

    if (name === 'apply_closed_note' && checked) {
      this.state.grid_params.api.forEachNodeAfterFilterAndSort((node) => {
        if (!node.data['closed']) {
          node.setDataValue('closed_notes', '');
          let txt_claim_notes: any = document.querySelector(`[col-id=closed_notes] #notes_field_${node.rowIndex}`);
          if (txt_claim_notes) {
            addClass(txt_claim_notes, 'disabled');
            txt_claim_notes.querySelector('input').disabled = checked;
          }
        }
      });
    } else if (name === 'apply_closed_note' && !checked) {
      this.state.grid_params.api.forEachNodeAfterFilterAndSort((node) => {
        if (!node.data['closed']) {
          node.setDataValue('closed_notes', '');
          let txt_claim_notes: any = document.querySelector(`[col-id=closed_notes] #notes_field_${node.rowIndex}`);
          if (txt_claim_notes) {
            removeClass(txt_claim_notes, 'disabled');
            txt_claim_notes.querySelector('input').disabled = checked;
          }
        }
      });
    }

    if (this.is_mounted) {
      this.setState((prev_state) => ({
        required_error: {
          ...prev_state.required_error,
          [name]: value ? false : true
        },
        form_object: this.search_criteria,
        ...closed_note_selected
      }));
    }
  };

  max_value_validation = (input) => {
    if (Number(input) > 2147483647) {
      return this.max_value_validation(input.substring(0, input.length - 1));
    } else {
      return Number(input);
    }
  };
  // ################## Advanced Controll ##################
  get_quick_search_list = async (search_keyword: any, type: string) => {
    let result: any;
    if (type == this.search_enum.payer) {
      result = await quick_search_payer({ keyword: search_keyword }, this.token);
    } else {
      result = await quick_search_rejection_group({ keyword: search_keyword }, this.token);
    }
    result.data.data =
      result.data && result.data.data ? result.data.data.sort((a, b) => a.name.localeCompare(b.name)) : result;
    return result;
  };

  // on provider search item selection
  on_advanced_search_grid_row_election = (selectedRow, type: any) => {
    this.search_criteria = {
      ...this.search_criteria,
      [`${type}_id`]: selectedRow.id,
      [`${type}_code`]: selectedRow.code,
      [`${type}`]: selectedRow.description
    };
    if (this.is_mounted) {
      this.setState({
        [`selected_${type}`]: selectedRow
      });
    }
  };

  // clear data on clear icon
  clear_quick_search = (type: any) => {
    this.search_criteria = {
      ...this.search_criteria,
      [`${type}_id`]: '',
      [`${type}_code`]: '',
      [`${type}`]: ''
    };
    if (this.is_mounted) {
      this.setState({
        [`selected_${type}`]: {}
      });
    }
  };

  // get the searched value which is typed for searching location or providers
  get_input_value = (input: { trim: () => { (): any; new (): any; length: number } }, type: string) => {
    if (input.trim().length >= 3) {
      this.get_quick_search_list(input, type);
    }
    if (this.is_mounted) {
      this.setState({
        form: { ...this.state.form, insurance_payer: null }
      });
    }
  };

  //Auto search for class
  renderResult = (props: { title: string | number; label: React.ReactNode }) => {
    return (
      <div key={props.title + '_main'} tabIndex={0} className='item_auto_search'>
        <div key={props.title} className='fs_13'>
          <span>{props.label}</span>
        </div>
      </div>
    );
  };

  row_selection_format = (item, type: string) => {
    let formatted = null;
    if (type == this.search_enum.payer) {
      formatted = {
        title: `${item.payer_key_id}`,
        code: item.name,
        description: item.description,
        id: item.payer_key_id,
        label: item.name + ' - ' + item.description
      };
    } else {
      formatted = {
        title: `${item.e277_status_cd_grp_id}`,
        code: item.name,
        description: item.description,
        id: item.e277_status_cd_grp_id,
        label: item.name + (item.description ? `- ${item.description}` : '')
      };
    }
    return formatted;
  };

  // preapare the suggestion list with search result
  prepare_suggestion = (data: { data: string | any[] }, type: string) => {
    let formattedList = [];
    let data_length = data.data.length;
    if (data && data_length) {
      for (let i = 0; i < data_length; i++) {
        let item = data.data[i];
        if (type == this.search_enum.payer) {
          formattedList.push({
            title: `${item.payer_key_id}`,
            code: item.name,
            description: item.description,
            id: item.payer_key_id,
            label: item.name + (item.description ? `- ${item.description}` : '')
          });
        } else {
          formattedList.push({
            title: `${item.e277_status_cd_grp_id}`,
            code: item.name,
            description: item.description,
            id: item.e277_status_cd_grp_id,
            label: item.name + (item.description ? `- ${item.description}` : '')
          });
        }
      }
    }
    return formattedList;
  };

  on_item_selection = (item: { description: any; code: any }, type: any) => {
    this.on_advanced_search_grid_row_election(item, type);
  };

  on_advanced_grid_row_select = (item: { payer_key_id: any; name: string; description: string }, type: string) => {
    const prepared_result = this.row_selection_format(item, type);
    this.on_advanced_search_grid_row_election(prepared_result, type);
  };

  // search location by typing name or code
  on_advance_search = async (params, type: string) => {
    let search_data = null;
    let config_column = null;
    if (type == this.search_enum.payer) {
      params.Description = typeof params.Description == 'undefined' ? '' : params.Description;
      params.Outbound_id = typeof params.Outbound_id == 'undefined' ? '' : params.Outbound_id;
      params.Inbound_id = typeof params.Inbound_id == 'undefined' ? '' : params.Inbound_id;
      params.name = typeof params.name == 'undefined' ? '' : params.name;
      search_data = await advanced_search_payer(params, this.token).then((res) => res.data);
      config_column = payer_groups.payer_column_defs;
    } else {
      params.Description = typeof params.description == 'undefined' ? '' : params.description;
      params.name = typeof params.name == 'undefined' ? '' : params.name;
      params.show_inactive = false;
      search_data = await advanced_search_rejection_group(params, this.token).then((res) => res.data);
      config_column = this.report277.rejection_group_column_defs;
    }

    const search_result = search_data && search_data.data !== null ? search_data.data : [];
    const grid_data = {
      ...this.state.grid_conf,
      rows: search_result,
      column: config_column,
      messages: search_data.messages,
      paginationPageSize: 20
    };
    if (this.is_mounted) {
      this.setState({ [`${type}_grid_data`]: grid_data });
    }
  };
  // ################## Advanced Controll ##################

  //Required Field Validator.
  required_field_validator = () => {
    let is_error = false;
    let from_date: any = this.search_criteria.file_date_from;
    let to_date: any = this.search_criteria.file_date_to;
    if (from_date && to_date) {
      if (from_date <= to_date) {
        if ((to_date - from_date) / 86400000 >= 31) {
          is_error = true;
          toaster.error('', messages.date_rage_validation);
        }
      } else {
        is_error = true;
        toaster.error('', messages.date_validation);
      }
    } else {
      is_error = true;
      toaster.error('', messages.mandatory_fields);
    }
    this.setState({
      is_error_file: is_error
    });
    return is_error;
  };

  // Function used to Run Report based on controllers values.
  run_report_handler = (e: { preventDefault: () => void; stopPropagation: () => void }) => {
    let is_error = false;
    if (this.is_mounted) {
      this.setState({ is_submitted: true });
    }
    // Validate Required Field
    is_error = this.required_field_validator();
    if (is_error) {
      return;
    }
    this.get_report_data();
    session_storage.set('report_277_search_criteria', this.search_criteria);
    this.run_report_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  // FUnction use to format the grid data on load.
  row_data_formatter = (row_data: any[], column_def: any[], search = false) => {
    let row_index = 0;
    row_data.map((row) => {
      if (search) {
        let disabled = row.closed ? true : false;
        row['disabled_is_note'] = disabled;
        row['disabled_is_close'] = disabled;
        row['is_close'] = false;
        row['is_note'] = false;
        row['row_index'] = row_index++;
      }
      column_def.map((value) => {
        switch (value.type) {
          case 'date':
            row[value.field] =
              row[value.field] != null || row[value.field] != undefined ? dateFormatter(row[value.field]) : '';
            break;
          case 'currency':
            row[value.field] =
              row[value.field] != null || row[value.field] != undefined
                ? currencyFormatter(row[value.field])
                : currencyFormatter(0);
            break;
          case 'number':
            row[value.field] =
              row[value.field] != null || row[value.field] != undefined ? parseInt(row[value.field]) : 0;
            break;
          case 'hyper_link':
            row[`link_${value.field}`] = strip(row[value.field]);
            break;
          case 'boolean':
            row[value.field] = JSON.parse(row[value.field]) ? 'Yes' : 'No';
          default:
            row[value.field] = row[value.field] != null || row[value.field] != undefined ? row[value.field] : '';
        }
      });
      return row;
    });
    return row_data;
  };

  // Function calls on click of Clear button and clear all the searched result as well as criteria.
  clear_handler = () => {
    if (this.is_mounted) {
      this.is_initial_result = false;
      this.setState(
        {
          is_grid_visible: false,
          is_export_disabled: true,
          is_print_disabled: true,
          is_updated_disabled: true,
          apply_closed_note: false,
          closed_note_selected: '',
          is_error: false,
          selected_payer: {},
          selected_rejection_group: {},
          is_submitted: false,
          report_data: [],
          loading: true,
          controls_is_expended: true
        },
        () => {
          this.search_criteria = { ...this.report277.search_criteria };
          this.report_data = {};
          session_storage.remove('report_277_search_criteria');
          this.title_search_criteria = {
            ...this.report277.title_search_criteria
          };
          this.get_dropdowns_data();
        }
      );
    }
  };

  // Show multiple messages
  show_html_content_toaster = (msg: any) => {
    return {
      component: () => (
        <div>
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        </div>
      ),
      timeOut: toastr_options.toastr_time_out,
      preventDuplicates: true
    };
  };

  // Search Criteria Toggler
  controls_toggle = () => {
    if (this.is_mounted) {
      this.setState({
        controls_is_expended: !this.state.controls_is_expended
      });
    }
  };

  // Function calls on intialization of Ag-Grid and catch its reference.
  get_grid_ref = (grid_params: {
    api: { getModel: () => { (): any; new (): any; getRowCount: { (): any; new (): any } } };
    columnApi: { setColumnWidth: (arg0: any, arg1: any, arg2: boolean) => void };
  }) => {
    if (this.is_mounted) {
      this.setState({
        grid_params,
        report_rows_count: grid_params.api.getModel().getRowCount()
      });
    }
    if (this.total_column_width > this.grid_width) {
      this.report_277_column_def.filter((item: { [x: string]: any; field: any }) => {
        grid_params.columnApi.setColumnWidth(item.field, item['width'], false);
      });
    }
    grid_params.columnApi.setColumnWidth('closed_notes', 300, false);
    handle_header_checkbox(grid_params, 'is_close', 'is_note', 'closed', true, true,false);
    handle_header_checkbox(grid_params, 'is_note', 'is_close', 'closed', true, true,false);
  };

  //Function calls after click of filter button and recalculate the footer sum.
  on_filter_button_click = () => {
    // Update row count
    this.setState({
      report_rows_count: this.state.grid_params.api.getModel().getRowCount() //this.state.grid_params.api.getModel().getRowCount()
    });
  };

  get_dropdwon_text = (field) => {
    let option = this.options_data[field].filter((item) => {
      if (item.value == this.search_criteria[field]) {
        return item.text;
      }
    });
    return option && option[0] ? option[0].text : '';
  };

  // Used to create the title for export and print.
  set_title = (separter: string, only_current_page) => {
    let title_search_criteria: any = {};
    let title = '';

    let rows_count;
    if (only_current_page) {
      let grid_params = this.state.grid_params.api;
      let no_of_record =
        grid_params.paginationProxy.bottomRowIndex - this.state.grid_params.api.paginationProxy.topRowIndex + 1;
      rows_count = no_of_record == 1 ? no_of_record + ' record' : no_of_record + ' records';
    } else {
      rows_count =
        this.state.report_rows_count == 1
          ? this.state.report_rows_count + ' record'
          : this.state.report_rows_count + ' records';
    }
    title_search_criteria = {
      title: '277 Report',
      service_Type: this.title_search_criteria.service_type,
      parent_Company: this.title_search_criteria.parent_company_id,
      company: this.title_search_criteria.company_id,
      patient_ID: this.title_search_criteria.patient_id,
      payer:
        this.title_search_criteria.payer_code +
        (this.title_search_criteria.payer ? ` - ${this.title_search_criteria.payer}` : ''),
      date_of_Service:
        this.title_search_criteria.dos_date_from && this.title_search_criteria.dos_date_to
          ? this.title_search_criteria.dos_date_from + ' - ' + this.title_search_criteria.dos_date_to
          : '',
      file_Date:
        this.title_search_criteria.file_date_from && this.title_search_criteria.file_date_to
          ? this.title_search_criteria.file_date_from + ' - ' + this.title_search_criteria.file_date_to
          : '',
      days_to_277_Has_Passed: this.title_search_criteria.days_elapsed ? 'Yes' : 'No',
      rejection_Group:
        this.title_search_criteria.rejection_group_code +
        (this.title_search_criteria.rejection_group ? ` - ${this.title_search_criteria.rejection_group}` : ''),
      close_Status: this.title_search_criteria.close_status,
      received_Status: this.title_search_criteria.received_status,
      accepted_Status: this.title_search_criteria.accepted_status,
      run_ID: this.title_search_criteria.run_id,
      current_date: moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A'),
      rows_count
    };
    Object.keys(title_search_criteria).forEach((key) => {
      if (['current_date', 'title', 'rows_count'].indexOf(key) > -1) {
        title += title_search_criteria[key] + separter;
      } else {
        title += capitalize(key.replace(/_/g, ' ')) + ': ' + title_search_criteria[key] + separter;
      }
    });
    return title + separter + separter;
  };

  // Function calls on initialization of export report data
  on_export_button = (only_current_page = false) => {
    this.setState({ confirm_box_open: false }, () => {
      let title = this.set_title('\n', only_current_page);
      let grid_params = [this.state.grid_params];
      if (only_current_page) {
        export_grid_pagination_data(this.state.grid_params, title, '277 Report', ['is_note', 'is_close']);
      } else {
        multi_grid_export(grid_params, [title], '277 Report', ['is_note', 'is_close']);
      }
      grid_params = [];
      title = '';
      this.saveReportEventAction(ReportConstants.ReportEventAction.Export);
    });
  };

  // Function calls on initialization of Print report data
  on_print_button = (only_current_page = false) => {
    this.setState({ confirm_box_open: false }, () => {
      let title = this.set_title('<br/>', only_current_page);
      let grid_params = [this.state.grid_params];
      if (only_current_page) {
        print_grid_pagination_data(this.state.grid_params, title, '277 Report', 'print_report_button', [
          'is_note',
          'is_close'
        ]);
      } else {
        multi_grid_print(grid_params, [title], '277 Report', ['is_note', 'is_close']);
      }
      grid_params = [];
      title = '';
      this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
    });
  };

    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;
        const payload: IPayloadForBillingAudit = {
            reportId: this.reportId,
            contextTitle: "277 Report" + " - " + ReportConstants.ReportEventAction[eventActionId],
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: ReportConstants.ReportEntityTypeId.D_CLAIM,
            data: { Records: this.state.report_rows_count }
        };
        const reqBody = generateAuditDataEntryPayload(payload);
        ReportConstants.saveAuditPrintExport(reqBody, accessToken);
    };

  handle_navigation_after_last_element = (event) => {
    // apply a check where grid is empty
    if (
      !event.shiftKey &&
      event.keyCode == '9' &&
      (this.state.report_data == null || this.state.report_data.length == 0)
    ) {
      set_focus_to_app_header(event);
    }
  };

  prepare_request_data = (closed_notes) => {
    let params = [];
    this.state.grid_params.api.forEachNode((node) => {
      let item = node.data;
      if (!item.closed && (item.is_note || item.is_close)) {
        params.push({
          claim_hdr_id: item.claim_hdr_id,
          closed_notes: this.state.apply_closed_note ? closed_notes : item.closed_notes,
          create_note: item.is_note ? false : true
        });
      }
    });
    return params;
  };

  update_277_report = async () => {
    let params = this.prepare_request_data(this.state.closed_note_selected);
    if (params && params.length <= 0) {
      toaster.error('', this.report277.update_msg.empty_error);
      return;
    }
    if (this.state.apply_closed_note) {
      if (!this.state.closed_note_selected.toLowerCase().trim()) {
        toaster.error('', this.report277.update_msg.closed_note_selected);
        return;
      }
    }
    if (this.is_mounted) {
      this.setState({
        loading: true,
        is_search_disabled: true
      });
    }
    await close_e277_claims(this.token, params).then(
      (response) => {
        if (response.data && response.data.status === 1) {
          toaster.success('', response.data.messages[0].message);
          if (this.is_mounted) {
            this.setState(
              {
                selected_835s: [],
                loading: false,
                is_search_disabled: false
              },
              () => this.get_report_data()
            );
          }
        } else {
          if (response.data.messages) {
            const toastr_options = this.show_html_content_toaster(get_all_error(response.data.messages));
            toaster.error('', toastr_options);
          }
          if (this.is_mounted) {
            this.setState({
              selected_835s: [],
              loading: false,
              is_search_disabled: false
            });
          }
        }
      },
      (error) => {
        if (this.is_mounted) {
          this.setState({
            loading: false,
            is_search_disabled: false
          });
        }
        if (error.response.data) {
          log_error(error);
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error('', toastr_options);
        }
      }
    );
  };

  grid_height = (params) => {
    if (!params.node.isRowPinned()) {
      if (params.data.closed) {
        return this.get_closed_notes_cell_height(params);
      }
      return 50;
    } else {
      return 38;
    }
  };

  get_closed_notes_cell_height = (params) => {
    let single_row_height = 32;
    const value = params.data.closed_notes;
    if (value != null && value != undefined && value.length > 40) {
      single_row_height = single_row_height * (Math.floor(value.length / 40) + 1);
    }
    return single_row_height;
  };

  handle_date = (name: React.ReactText, title = false) => {
    if (title) {
      return this.search_criteria[name] ? moment(this.search_criteria[name]).format('MM/DD/YYYY') : '';
    } else {
      return this.search_criteria[name] ? moment(moment(this.search_criteria[name]).format('MM/DD/YYYY')) : null;
    }
  };

  onPaginationChange = (from_page, to_page) => {
    clear_checbox_selection_on_pagination(
      this.state.grid_params,
      'is_close',
      'is_note',
      'closed',
      true,
      this.state.apply_closed_note
    );
  };

  // close popup
  handle_close = () => {
    if (this.is_mounted) {
      this.setState({ confirm_box_open: false });
    }
  };

  // confirmation popup
  handle_confirmation = (type) => {
    if (this.is_mounted) {
      if (this.state.grid_params.api.paginationProxy.totalPages > 1) {
        this.setState({
          confirm_box_open: true,
          confirm_title: `Confirm`,
          message: `What do you want to ${type}?`,
          confirm_type: type
        });
      } else {
        if (type === 'print') {
          this.on_print_button(true);
        } else {
          this.on_export_button(true);
        }
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className={'common-forms-add report_277'}>
          <Dimmer active={this.state.loading}>
            <Loader size='massive'>Loading</Loader>
          </Dimmer>
          <div className={'common-forms-search report-framework'} style={{ paddingRight: 0 }}>
            <ReportHeader title={this.report277.title} />
            <div id='report-scrollable-area' className='report-wrapper' style={{ flex: '1 1 0px' }}>
              <div className='inner_wrap'>
                <div className='patient-search-form  patient_search_bottom_padding' id='report-criteria-container'>
                  <Form autoComplete='off' onSubmit={this.run_report_handler}>
                    <Grid>
                      <Grid.Column computer={16} tablet={16} textAlign='left' className='accordionColumn'>
                        <Accordion fluid styled>
                          <Accordion.Title
                            active={this.state.controls_is_expended}
                            index={0}
                            onClick={this.controls_toggle}
                          >
                            <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name='angle right' />
                            Search Criteria
                          </Accordion.Title>
                          <Accordion.Content active={this.state.controls_is_expended}>
                            <Grid>
                              {!this.software_only_company && (
                                <DropdownInput
                                  label='Service Type'
                                  id='service_type'
                                  change_handler={(value, event) => {
                                    this.dropdown_change_handler(event);
                                  }}
                                  defaultValue={this.search_criteria.service_type}
                                  options={this.options_data.service_type}
                                />
                              )}
                              <DropdownInput
                                label='Parent Company'
                                id='parent_company_id'
                                change_handler={(value, event) => {
                                  this.dropdown_change_handler(event);
                                }}
                                defaultValue={this.search_criteria.parent_company_id}
                                options={this.options_data.parent_company_id}
                              />
                              <DropdownInput
                                label='Company'
                                id='company_id'
                                change_handler={(value, event) => {
                                  this.dropdown_change_handler(event);
                                }}
                                defaultValue={this.search_criteria.company_id}
                                options={this.options_data.company_id}
                              />
                              <Grid.Column tablet={8} computer={4}>
                                <Form.Field>
                                  <label>Patient ID</label>
                                  <Input
                                    autoComplete='off'
                                    name='patient_id'
                                    id='patient_id'
                                    onChange={this.input_change_handler}
                                    value={this.search_criteria.patient_id ? this.search_criteria.patient_id : ''}
                                    type='text'
                                    pattern='[0-9]*'
                                    maxLength={10}
                                  />
                                </Form.Field>
                              </Grid.Column>
                            </Grid>
                            <Grid>
                              <Advanced_control
                                // quick search
                                label={'Payer'}
                                onGridRowSelection={(row) =>
                                  this.on_advanced_grid_row_select(row, this.search_enum.payer)
                                }
                                gridConfig={this.state.payer_grid_data}
                                controlId={'PAYER_DESCRIPTION'}
                                onSearch={(param) => this.on_advance_search(param, this.search_enum.payer)}
                                search_type={'PAYER_DESCRIPTION'}
                                headerIdForGridTabNavigation={payer_groups.payer_header_id}
                                // quick search
                                getInputValue={(e) => this.get_input_value(e, this.search_enum.payer)}
                                default_value={this.state.selected_payer}
                                prepareRenderList={this.renderResult}
                                getList={(e) => this.get_quick_search_list(e, this.search_enum.payer)}
                                prepareDataList={(data) => this.prepare_suggestion(data, this.search_enum.payer)}
                                selectresult={(item) => this.on_item_selection(item, this.search_enum.payer)}
                                clear_search={() => this.clear_quick_search(this.search_enum.payer)}
                              />
                              <Grid.Column tablet={8} computer={4}>
                                <Form.Field>
                                  <label>Date of Service</label>
                                  <DateRangePickerComponent
                                    updateDatesChange={(from_date, end_date) =>
                                      this.range_date_change_handler(from_date, end_date, 'dos')
                                    }
                                    startDate={this.search_criteria.dos_date_from}
                                    endDate={this.search_criteria.dos_date_to}
                                    error={false}
                                    id={'dos'}
                                    maxDate={moment()}
                                  />
                                </Form.Field>
                              </Grid.Column>
                              <Grid.Column tablet={8} computer={4}>
                                <Form.Field>
                                  <label>
                                    File Date{' '}
                                    <span
                                      className={
                                        this.state.is_submitted && this.state.is_error_file
                                          ? 'req-alert'
                                          : 'req-alert_normal'
                                      }
                                    >
                                      (required)
                                    </span>
                                  </label>
                                  <DateRangePickerComponent
                                    updateDatesChange={(from_date, end_date) =>
                                      this.range_date_change_handler(from_date, end_date, 'file')
                                    }
                                    startDate={this.search_criteria.file_date_from}
                                    endDate={this.search_criteria.file_date_to}
                                    error={this.state.is_submitted && this.state.is_error_file}
                                    id={'file_date'}
                                    maxDate={moment()}
                                  />
                                </Form.Field>
                              </Grid.Column>
                              <Grid.Column tablet={8} computer={4}>
                                <Form.Field className='ui checkbox'>
                                  <div style={{ marginTop: 32 }}>
                                    <input
                                      type='checkbox'
                                      name={'days_elapsed'}
                                      id={'days_elapsed'}
                                      checked={
                                        this.search_criteria['days_elapsed']
                                          ? this.search_criteria['days_elapsed']
                                          : false
                                      }
                                      onChange={this.input_change_handler}
                                    />
                                    <label className='chkbx_label' htmlFor={'days_elapsed'}>
                                      Days to 277 Has Passed
                                    </label>
                                  </div>
                                </Form.Field>
                              </Grid.Column>
                            </Grid>
                            <Grid>
                              <Advanced_control
                                // quick search
                                label={'Rejection Group'}
                                onGridRowSelection={(row) =>
                                  this.on_advanced_grid_row_select(row, this.search_enum.rejection_group)
                                }
                                gridConfig={this.state.rejection_group_grid_data}
                                controlId={'REJECTION_GROUP'}
                                onSearch={(param) => this.on_advance_search(param, this.search_enum.rejection_group)}
                                search_type={'REJECTION_GROUP'}
                                headerIdForGridTabNavigation={payer_groups.payer_header_id}
                                // quick search
                                getInputValue={(e) => this.get_input_value(e, this.search_enum.rejection_group)}
                                default_value={this.state.selected_rejection_group}
                                prepareRenderList={this.renderResult}
                                getList={(e) => this.get_quick_search_list(e, this.search_enum.rejection_group)}
                                prepareDataList={(data) =>
                                  this.prepare_suggestion(data, this.search_enum.rejection_group)
                                }
                                selectresult={(item) => this.on_item_selection(item, this.search_enum.rejection_group)}
                                clear_search={() => this.clear_quick_search(this.search_enum.rejection_group)}
                              />
                              <DropdownInput
                                label='Close Status'
                                id='close_status'
                                change_handler={(value, event) => {
                                  this.dropdown_change_handler(event);
                                }}
                                defaultValue={this.search_criteria.close_status}
                                options={this.options_data.close_status}
                              />
                              <DropdownInput
                                label='Received Status'
                                id='received_status'
                                change_handler={(value, event) => {
                                  this.dropdown_change_handler(event);
                                }}
                                defaultValue={this.search_criteria.received_status}
                                options={this.options_data.received_status}
                              />
                              <DropdownInput
                                label='Accepted Status'
                                id='accepted_status'
                                change_handler={(value, event) => {
                                  this.dropdown_change_handler(event);
                                }}
                                defaultValue={this.search_criteria.accepted_status}
                                options={this.options_data.accepted_status}
                              />
                            </Grid>
                            <Grid>
                              <Grid.Column tablet={8} computer={4}>
                                <Form.Field>
                                  <label>Run ID</label>
                                  <Input
                                    autoComplete='off'
                                    name='run_id'
                                    id='run_id'
                                    onChange={this.input_change_handler}
                                    value={this.search_criteria.run_id ? this.search_criteria.run_id : ''}
                                    type='text'
                                    maxLength={10}
                                    pattern='[0-9]*'
                                  />
                                </Form.Field>
                              </Grid.Column>
                            </Grid>
                          </Accordion.Content>
                        </Accordion>
                      </Grid.Column>
                    </Grid>
                    <Grid>
                      <Grid.Column tablet={16} computer={16} textAlign='right'>
                        <Button
                          type='reset'
                          id='clear_report_button'
                          basic
                          onClick={this.clear_handler}
                          disabled={this.state.is_clear_button_disabled}
                          content={'Clear'}
                        />
                        <Button
                          id='create_report_button'
                          className='primary'
                          type='submit'
                          onKeyDown={this.handle_navigation_after_last_element}
                          style={{ marginRight: '0' }}
                          disabled={this.state.is_search_disabled}
                          content={'Search'}
                        />
                      </Grid.Column>
                    </Grid>
                  </Form>
                </div>
                {this.state.is_grid_visible && (
                  <GridView
                    id={this.report277.report_grid_id}
                    row={this.report_data.rows}
                    column={this.report_277_column_def}
                    headerHeight={this.grid_header_height}
                    enableColResize={true}
                    emptyMessage={messages.no_records_found}
                    get_grid_ref={this.get_grid_ref}
                    headerIdForTabNavigation={report_ledger_full_configuration_header_id}
                    suppressSizeToFit={true}
                    gridAutoHeight={true}
                    on_filter_button_click={() => {
                      this.on_filter_button_click();
                    }}
                    isPagination={true}
                    paginationPageSize={20}
                    wrapperClass={'grid_wrapper paginated_grid'}
                    getRowHeight={this.grid_height}
                    onPaginationChange={this.onPaginationChange}
                  />
                )}
              </div>
            </div>
            <Form style={{ margin: '15px 20px 0 0' }} onSubmit={this.update_277_report}>
              <Form.Group inline style={{ justifyContent: 'flex-end', marginBottom: 0, marginRight: -5 }}>
                <Form.Checkbox
                  label='Apply Closed Note to Selected'
                  type='checkbox'
                  name={'apply_closed_note'}
                  id={'apply_closed_note'}
                  checked={this.state.apply_closed_note ? this.state.apply_closed_note : false}
                  onChange={this.input_change_handler}
                  disabled={!this.state.is_grid_visible}
                />
                <Form.Field style={{ width: '33.33%' }}>
                  <Input
                    autoComplete='off'
                    name='closed_note_selected'
                    id='closed_note_selected'
                    onChange={this.input_change_handler}
                    disabled={!this.state.is_grid_visible}
                    value={this.state.closed_note_selected ? this.state.closed_note_selected : ''}
                    style={{ width: '100%' }}
                    maxLength={500}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
            <div
              className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
              id='applicationFooterSticky'
              style={{ paddingRight: 25 }}
            >
              <Grid style={{ marginTop: 0, marginBottom: 0 }}>
                <Grid.Column computer={16} tablet={16} textAlign='right'>
                  <Button
                    id='export_report_button'
                    type='submit'
                    onClick={() => this.handle_confirmation('export')}
                    basic
                    disabled={this.state.is_export_disabled}
                    content={'Export'}
                  />
                  <Button
                    id='print_report_button'
                    type='submit'
                    onClick={() => this.handle_confirmation('print')}
                    disabled={this.state.is_print_disabled}
                    basic
                    content={'Print'}
                  />
                  <Button
                    id='is_updated_disabled'
                    className='primary'
                    onClick={this.update_277_report}
                    type='button'
                    onKeyDown={set_focus_to_app_header}
                    style={{ marginRight: '0' }}
                    disabled={this.state.is_updated_disabled}
                    content={'Update'}
                  />
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </div>
        {this.state.confirm_box_open && (
          <Confirmation_export_print
            closeModal={this.handle_close}
            show_modal={this.state.confirm_box_open}
            title={this.state.confirm_title}
            message={this.state.message}
            displayed_results={
              this.state.confirm_type === 'print' ? () => this.on_print_button(true) : () => this.on_export_button(true)
            }
            all_results={
              this.state.confirm_type === 'print' ? () => this.on_print_button() : () => this.on_export_button()
            }
            on_cancel_click={this.handle_close}
          />
        )}
      </React.Fragment>
    );
  }
}

// Get user and login details from store.
const mapStateToProps = (state) => {
  return {
    user_login_details: state.user_login_details,
    selected_patient: state.patient_details.patient_header,
    is_expand: state.shared_details.is_expand,
  };
};

export default connect(mapStateToProps)(Report277Component);
