import { IValidator } from "./IValidator";
import { MinLengthValidator } from "./min_length_validator";
import { FirstCharacterValidator } from "./first_character_validator";
import { AllowedCharactersValidator } from "./allowed_characters_validator";
import { MaxLengthValidator } from "./max_length_validator";

export class ComposeNameFieldValidator implements IValidator {
    private validators: Array<IValidator>;
    private errorMessages = new Array<string>();

    constructor() {
        this.validators = [
            new MinLengthValidator(),
            new FirstCharacterValidator(),
            new AllowedCharactersValidator(),
            new MaxLengthValidator()
        ];
    }

    isValid(data: any): boolean {
        let isValid = true;

        this.errorMessages = [];
        for (let validator of this.validators) {
            if (!validator.isValid(data)) {
                isValid = false;
                this.errorMessages = this.errorMessages.concat(validator.getErrorMessages());
                break;
            }
        }

        return isValid;
    }

    getErrorMessages(): string[] {
        return this.errorMessages;
    }
}
