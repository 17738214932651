import * as React from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import { isNullOrUndefined } from 'util';

/**
 * Pagination component
 * This is custome pagination and has developed to manage page information, total records
 * Also it's handling page navigation- frist page, last page, previous page, next page
 */
const Pagination = (props) => {
    const { fromPage, toPage, totalRecords, isFirstPage, isLastPage, onFirst, onPrevious, onNext, onLast, onSpecificPage, totalPage, pager, showPager, totalInBetween } = props;  
    //if (!pager.pages || pager.pages.length <= 1) {
    //    // don't display pager if there is only 1 page
    //    return null;
    //}
    if (!isNullOrUndefined(totalInBetween) && totalInBetween == true) {
        return (<Grid columns={3} className="pagination-top">
            <Grid.Column tablet={3}>
                <h4 className="mainheadingForm">{props.message || 'Search Results'}</h4>
            </Grid.Column>
            <Grid.Column tablet={8} className="records-number">
                <div className="pull-right">
                    <ul className="pagination PageButton">
                        <li>
                            <button disabled={isFirstPage} onClick={onFirst} className="button-page">
                                <Icon disabled={isFirstPage} name="angle double left" />
                            </button>
                        </li>
                        <li>
                            <button disabled={isFirstPage} onClick={onPrevious} className="button-page">Previous</button>
                        </li>
                        {
                            showPager && pager && pager.pages ? 
                                pager.pages.map((page, index) =>
                                    <li key={index} className={pager.curr_page === page ? 'active' : ''}>
                                        <a onClick={() => onSpecificPage(page)}>{page}</a>
                                    </li>
                                )
                            
                                : `${fromPage}-${toPage} of ${totalRecords} shown`
                        }

                        <li>
                            <button disabled={isLastPage} onClick={onNext} className="button-page">Next</button>
                        </li>
                        <li>
                            <button disabled={isLastPage} onClick={onLast} className="page-arrow-right button-page">
                                <Icon disabled={isLastPage} name="angle double right" />
                            </button>
                        </li>
                    </ul>
                </div>
                
            </Grid.Column>
            <Grid.Column tablet={5}>
                
            </Grid.Column>
        </Grid>);
    }

    return (<Grid columns={3} className="pagination-top">
        <Grid.Column tablet={3}>
            <h4 className="mainheadingForm">{props.message || 'Search Results'}</h4>
        </Grid.Column>
        <Grid.Column tablet={8} className="records-number">
            {`${fromPage} to ${toPage} of ${totalRecords} ${totalRecords == 1 ? 'record' : 'records'} shown`}
        </Grid.Column>
        <Grid.Column tablet={5}>
            <div className="pull-right">
                <ul className="pagination PageButton">
                    <li>
                        <button disabled={isFirstPage} onClick={onFirst} className="button-page">
                            <Icon disabled={isFirstPage} name="angle double left" />
                        </button>
                    </li>
                    <li>
                        <button disabled={isFirstPage} onClick={onPrevious} className="button-page">Previous</button>
                    </li>
                    {
                        showPager && pager && pager.pages ? 
                            pager.pages.map((page, index) =>
                                <li key={index} className={pager.curr_page === page ? 'active' : ''}>
                                    <a onClick={() => onSpecificPage(page)}>{page}</a>
                                </li>
                            )
                        
                            : ''
                    }

                    <li>
                        <button disabled={isLastPage} onClick={onNext} className="button-page">Next</button>
                    </li>
                    <li>
                        <button disabled={isLastPage} onClick={onLast} className="page-arrow-right button-page">
                            <Icon disabled={isLastPage} name="angle double right" />
                        </button>
                    </li>

                </ul>
            </div>
        </Grid.Column>
    </Grid>);
};

export { Pagination };

