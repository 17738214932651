import * as global_constants from '../../global_constants';
import { json } from '../../shared/crypto_service/sjcl';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request';

// Release the Integration Payment only for Ready status
export const release_integration_payment = (token, params) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.integration_payment.release_payment_integration}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': params
    })

   return request.make(request_config);
};

// Close the Integration Payment by User
export const close_integration_payment =  (token, params) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.integration_payment.close_payment_integration}`;
  let request = new Request();
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token
  };

  let request_config = new RequestConfig({
    url: url,
    headers: headers,
    method: 'put',
    data: params
  });
  
  return request.make(request_config);
};

// Get the Integration Payment to be Process
export const search_intergration_payment = (token, params) => {    
    const url = `${global_constants.base_end_points}${global_constants.end_points.integration_payment.search_payment_integration}`;

    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    };
  
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': json.encode(params)
    });
    return request.make(request_config);
  };