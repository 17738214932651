import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Dimmer, Grid, Loader, Header, Form, Checkbox } from 'semantic-ui-react';
import { ExpectedRateMultipleFeeSchedule, expected_rate } from '../../constants';
import DefinitionComponent from './definition_component';
import { get_expected_rates,
    save_expected_rates, validate_expected_rates,
    get_insurance_codes, expected_rate_fee_rate_type,
    get_locations, get_providers, get_procedure_codes
} from '../../action/constants_action';
import { toastr } from 'react-redux-toastr';
import { AlertConfirm } from '../../../../shared/component/alert_confirm_component';
import PromptNavigation from '../../../../shared/component/prompt_navigation_component';
import  AddInsuranceDrawerComponent from './add_insurance_drawer_component'
import { toastr_options } from '../../../../global_constants';
import { get_lauch_darkley_key_value, nullifyString } from '../../../../shared/utility';
import { advanced_search_class } from "../../../../shared/action/shared_action";
import * as global_constants from "../../../../global_constants";
import { argos_bill_1627_expected_rates_improvements } from './../../../../feature_flip_constant';
import { ResizableDraggableModal } from '../../../../shared/resizable-draggable-modal';

class AddEditDeleteComponent extends React.Component<any, any> {
    _is_mounted = false;
    _insurance_code_list: any = [];
    _insurance_class_list: any = [];
    _locations_list: any = [];
    _providers_list: any = [];
    _fee_rate_type_list: any = [];
    _procedure_code_list: any = [];
    _expected_rate_list: any = [];
    _open_expected_rate_fee_id = {
        name: '',
        expanded_fee_ids: [],
        all_fee_ids: []
    };
    _expected_rate_id_marked_for_copy = -1;
    _expected_rate_id_to_delete = -1;
    _expected_rate_name_to_delete = '';
    _expected_rate_fee_marked_for_delete = {
        "expected_rate_id": null,
        "expected_rate_fee_id": null
    }
    token: string = "";

    // #region life cycle methods
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            list_expected_rate_table: [],
            alert: {
                display: false,
                message: '',
                action: '',
                title: '',
                label_yes: ''
            },
            is_save_in_progress: false,
            lst_validations: [],
            show_drawer: false
        };
    }

    componentDidMount = () => {
        this._is_mounted = true;
        document.body.classList.add('admin-framework');
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.get_all_data()
    }

    componentWillUnmount = () => {
        this._is_mounted = false;
        document.body.classList.remove('admin-framework');
        delete this._insurance_code_list;
        delete this._insurance_class_list;
        delete this._providers_list;
        delete this._locations_list;
        delete this._fee_rate_type_list;
        delete this._procedure_code_list;
        delete this._expected_rate_list;
    }

    get_all_data = async () => {
        if (this._is_mounted) {
            this.setState({ loading: true });
        }
        const params = { page_size: global_constants.constants.PAGE_SIZE };
        const promise_array = [
            expected_rate_fee_rate_type(this.token),
            get_locations(this.token),
            get_providers(this.token),
            get_procedure_codes(this.token),
            get_insurance_codes(this.token),
            get_expected_rates(this.token),
            advanced_search_class(params, this.token)
        ];
        await Promise.all(promise_array)
            .then(
                (res) => {
                    if (res[0].data && res[0].data.data && res[0].data.data.length > 0) {
                        if (this.props.features.argosBill1627ExpectedRatesImprovements) {
                            this._fee_rate_type_list = res[0].data.data;
                        }
                        else {
                            this._fee_rate_type_list = this.remove_cascade_fee_type(res[0].data.data);
                        }
                    }

                    if (res[1].data && res[1].data.data && res[1].data.data.length > 0) {
                        this._locations_list = res[1].data.data;
                    }

                    if (res[2].data && res[2].data.data && res[2].data.data.length > 0) {
                        this._providers_list = res[2].data.data;
                    }

                    if (res[3].data && res[3].data.data && res[3].data.data.length > 0) {
                        this._procedure_code_list = res[3].data.data;
                    }

                    if (res[4].data && res[4].data.data && res[4].data.data.length > 0) {
                        this._insurance_code_list = res[4].data.data;
                    }
                    if (res[5].data && res[5].data.data && res[5].data.data.length > 0) {
                        this._expected_rate_list = this.get_expected_rates_list(res[5].data.data, this._open_expected_rate_fee_id);
                    }
                    if (res[6].data && res[6].data.data && res[6].data.data.length > 0) {
                        this._insurance_class_list = res[6].data.data;
                    }

                    if (this._is_mounted) {
                        this.setState({ loading: false, list_expected_rate_table: this._expected_rate_list });
                    }
                },
                (error) => {
                    if (this._is_mounted) {
                        this.setState({ loading: false, list_expected_rate_table: [] });
                    }
                }
            )
            .catch((error) => {
                if (this._is_mounted) {
                    this.setState({ loading: false, list_expected_rate_table: [] });
                }
            });
    };

    remove_cascade_fee_type = (response) => {
        return response.filter((fee_rate_type) => fee_rate_type.expected_rate_fee_rate_type_name !== "Reduction Percent");
    }
    // #endregion 

    // #region Load Data Methods.
    get_expected_rates_list = (expected_rate_data, open_expected_rate) => {
        var new_expanded = false;
        var list_expected_rate_table = [];
        if (expected_rate_data) {
            var today = new Date();
            today.setHours(0, 0, 0, 0);
            list_expected_rate_table = expected_rate_data.map((data_item) => {
                data_item.is_expanded = data_item.name == open_expected_rate["name"];
                data_item.include_cpt_with_no_value = false;
                data_item.is_modified = false;
                data_item.is_expired = data_item.end_date && new Date(data_item.end_date) < today;
                data_item.assigned_insurances_ids = data_item.assigned_insurance_class_ids && data_item.assigned_insurance_class_ids.length > 0 ? [] : data_item.assigned_insurances_ids;
                data_item.expected_rate_fees = data_item.expected_rate_fees.map((fee) => {
                    fee.is_modified = false;
                    fee.is_expanded = false;
                    if (data_item.is_expanded) {
                        if (open_expected_rate["expanded_fee_ids"].indexOf(fee.expected_rate_fee_id) >= 0) {
                            fee.is_expanded = true;
                        }
                        else {
                            if (open_expected_rate["all_fee_ids"].indexOf(fee.expected_rate_fee_id) < 0) {
                                if (!new_expanded && open_expected_rate["expanded_fee_ids"].filter(x => x < 0).length == 1) {
                                    fee.is_expanded = true;
                                    new_expanded = true;
                                }
                            }
                        }
                    }
                    var fee_proc_codes = fee.expected_rate_fee_procedure_codes.map((proc_code) => {
                        proc_code.is_modified = false;
                        return proc_code;

                    });
                    fee.expected_rate_fee_procedure_codes = fee_proc_codes;
                    return fee;
                });
                return data_item;
            });
            return list_expected_rate_table;
        }
    }

    //#region Validation Functionality
    submit_save_request = () => {
        if (this._is_mounted) {
            this.setState({ is_save_in_progress: true });
            let local_copy = [...this.state.list_expected_rate_table]
            var lst_edited_expected_rate = [];
            local_copy.map((expected_rate_obj) => {
                let local_expected_rate_obj = Object.assign({}, expected_rate_obj);
                if (local_expected_rate_obj.is_modified) {
                    local_expected_rate_obj.expected_rate_fees = [...local_expected_rate_obj.expected_rate_fees];
                    let modified_expected_rate_fee = [];
                    local_expected_rate_obj.expected_rate_fees.map((rate_fee_definition) => {
                        let local_rate_fee_definition = Object.assign({}, rate_fee_definition);
                        if (local_expected_rate_obj.is_active == false) {
                            this.set_nullable_properties_on_deleted_expected_rates(local_expected_rate_obj)
                        }
                        if (local_rate_fee_definition.is_modified == true) {

                            let updated_proc_code = [];
                            local_rate_fee_definition.expected_rate_fee_procedure_codes = [...local_rate_fee_definition.expected_rate_fee_procedure_codes];
                            local_rate_fee_definition.expected_rate_fee_procedure_codes.map((proc_code) => {
                                if (proc_code.is_modified == true && (proc_code.expected_rate_fee_procedure_code_id > 0 || (proc_code.expected_reimbursement_unit != null || proc_code.is_carve_out == true || (this.props.features.ff_sumaj_B30566_expected_rate_multiple_fee_schedules && proc_code.updated_billed_amount != null)))) {
                                    if (proc_code.expected_reimbursement_unit === null) {
                                        proc_code.is_carve_out = false;
                                    }
                                    updated_proc_code.push(proc_code);
                                }
                            });

                            local_rate_fee_definition.expected_rate_fee_procedure_codes = updated_proc_code;
                            modified_expected_rate_fee.push(local_rate_fee_definition);
                        }
                    });
                    local_expected_rate_obj.expected_rate_fees = modified_expected_rate_fee;
                    lst_edited_expected_rate.push(local_expected_rate_obj);
                }
            });

            var ui_validation = this.ui_expected_rate_validate(this.state.list_expected_rate_table);

            if (ui_validation.is_validation_successful && ui_validation.is_insurance_available) {
                let donotshowagain: boolean = ((JSON.parse(localStorage.getItem(expected_rate.warningModal.localStorageKey)) || {})[this.props.user_login_details.user_data.data.user_id] ? (JSON.parse(localStorage.getItem(expected_rate.warningModal.localStorageKey)) || {})[this.props.user_login_details.user_data.data.user_id] : false)
                let expectedRatesList: any = local_copy.filter((expectedRateObj: any) => {
                    if (expectedRateObj.is_modified
                        && expectedRateObj.expected_rate_fees.length > 0
                        && (expectedRateObj.expected_rate_fees.filter((feeObj: any) => feeObj.fee_rate_type_id == expected_rate.warningModal.flatRateId && feeObj.expected_rate_fee_procedure_codes.length == 0).length > 0 ||
                            expectedRateObj.expected_rate_fees.filter((feeObj: any) => feeObj.fee_rate_type_id == expected_rate.warningModal.flatRateId && feeObj.expected_rate_fee_procedure_codes.length > 0
                                && feeObj.expected_rate_fee_procedure_codes.filter((procCodeObj: any) => procCodeObj.expected_reimbursement_unit != null) == 0).length > 0)) {
                        return true;
                    }
                });

                if (expectedRatesList.length > 0 && !donotshowagain) {
                    let renderWarningModal = {
                        title: expected_rate.warningModal.title,
                        message: expected_rate.warningModal.missingExpectedReimbursementsRates(expectedRatesList),
                        lst_edited_expected_rate: lst_edited_expected_rate
                    };
                    this.setState({ renderWarningModal });
                } else {
                    this.service_validate_expected_rates(lst_edited_expected_rate);
                }
            }
            else {
                this.setState({
                    lst_validations: ui_validation.validations_list
                });
                if (!ui_validation.is_validation_successful) {
                    toastr.error('', expected_rate.validation_message.toastr);
                }else if(!ui_validation.is_insurance_available){
                    toastr.error('', expected_rate.validation_message.insurance_validation_ms);
                }
            }
        }
    }

    ui_expected_rate_validate = (modified_expected_rates_list) => {
        var response = {
            validations_list: [],
            is_validation_successful: true,
            is_insurance_available : true
        };

        modified_expected_rates_list.map((expected_rate_obj) => {
            var validation;
            const in_valid_pattern = /[^A-Za-z0-9\s]/g
            if (expected_rate_obj.is_active == false) return;

            if (!expected_rate_obj.name && expected_rate_obj.name.trim().length == 0) {
                response.is_validation_successful = false;
                if (!validation) validation = {};
                validation[expected_rate.validation_obj_structure.id] = expected_rate_obj.expected_rate_id;
                validation[expected_rate.validation_obj_structure.has_required_validation] = true;
            }

            //Name with special characters
            var special_characters_match = expected_rate_obj.name.match(in_valid_pattern);

            if (special_characters_match && special_characters_match.length > 0) {
                response.is_validation_successful = false;
                if (!validation) validation = {};
                validation[expected_rate.validation_obj_structure.id] = expected_rate_obj.expected_rate_id;
                validation[expected_rate.validation_obj_structure.name] = expected_rate.validation_message.name;
            }

            //Start Date not selected by the user.
            if (!expected_rate_obj.start_date) {
                response.is_validation_successful = false;
                if (!validation) validation = {};
                validation[expected_rate.validation_obj_structure.id] = expected_rate_obj.expected_rate_id;
                validation[expected_rate.validation_obj_structure.has_required_validation] = true;
            }

            //Check for valid end date if exist
            if (expected_rate_obj.end_date && new Date(expected_rate_obj.end_date).getTime() == NaN) {
                if (!validation) validation = {};
                response.is_validation_successful = false;
                validation[expected_rate.validation_obj_structure.id] = expected_rate_obj.expected_rate_id;
            }
            //Check end date greater than start date
            if (expected_rate_obj.start_date && expected_rate_obj.end_date) {
                if (new Date(expected_rate_obj.end_date) < new Date(expected_rate_obj.start_date)) {
                    if (!validation) validation = {};
                    response.is_validation_successful = false;
                    validation[expected_rate.validation_obj_structure.id] = expected_rate_obj.expected_rate_id;
                    validation[expected_rate.validation_obj_structure.end_date] = expected_rate.validation_message.end_date;
                }
            }

            //Insurance Code Validation
            if ((!expected_rate_obj.assigned_insurances_ids || expected_rate_obj.assigned_insurances_ids.length == 0) && (!expected_rate_obj.assigned_insurance_class_ids || expected_rate_obj.assigned_insurance_class_ids.length == 0) ) {
                response.is_insurance_available = false;
                if (!validation) validation = {};
                validation[expected_rate.validation_obj_structure.id] = expected_rate_obj.expected_rate_id;
                validation[expected_rate.validation_obj_structure.has_required_validation] = true;
                validation[expected_rate.validation_obj_structure.assigned_insurances_ids] = expected_rate.validation_message.assigned_insurances_ids.required;
            }

            //validate atleast one rate fee definition

            if (!expected_rate_obj.expected_rate_fees || expected_rate_obj.expected_rate_fees.length == 0 || this.get_active_expected_rate_fees(expected_rate_obj.expected_rate_fees).length == 0) {
                response.is_validation_successful = false;
                if (!validation) validation = {};
                validation[expected_rate.validation_obj_structure.id] = expected_rate_obj.expected_rate_id;
                validation[expected_rate.validation_obj_structure.has_required_validation] = true;
                validation[expected_rate.validation_obj_structure.expected_rate_fees] = expected_rate.validation_message.expected_rate_fees_required;
            }

            //Validate expected rate fee
            if (!validation) validation = {};
            var fee_validations = [];
            expected_rate_obj.expected_rate_fees.map((fee) => {

                if (fee.is_active == false) return;

                var validation_obj = {};
                var is_validation_successful = true;

                if (fee.assigned_provider_ids.length == 0 && !fee.include_future_providers) {
                    is_validation_successful = false;
                    validation_obj[expected_rate.expected_rate_fee_validation.id] = fee.expected_rate_fee_id;
                    validation_obj[expected_rate.expected_rate_fee_validation.has_required_validation] = true;
                }

                if (fee.assigned_location_ids.length == 0) {
                    is_validation_successful = false;
                    validation_obj[expected_rate.expected_rate_fee_validation.id] = fee.expected_rate_fee_id;
                    validation_obj[expected_rate.expected_rate_fee_validation.has_required_validation] = true;
                }

                if (!fee.fee_rate_type_id || fee.fee_rate_type_id == "" || fee.fee_rate_type_id == '0' || fee.fee_rate_type_id == 'NA') {
                    is_validation_successful = false;
                    validation_obj[expected_rate.expected_rate_fee_validation.id] = fee.expected_rate_fee_id;
                    validation_obj[expected_rate.expected_rate_fee_validation.has_required_validation] = true;
                }

                if (fee.fee_rate_type_id == expected_rate.fee_type.reduction_percent) {
                    if (!fee.percentage || fee.percentage <= 0) {
                        is_validation_successful = false;
                        validation_obj[expected_rate.expected_rate_fee_validation.id] = fee.expected_rate_fee_id;
                        validation_obj[expected_rate.expected_rate_fee_validation.has_required_validation] = true;
                    } else if (fee.percentage > 100) {
                        is_validation_successful = false;
                        validation_obj[expected_rate.expected_rate_fee_validation.id] = fee.expected_rate_fee_id;
                        validation_obj[expected_rate.expected_rate_fee_validation.percentage] = expected_rate.validation_message.expected_rate_fee_percentage;
                    }
                }

                if ((fee.fee_rate_type_id == expected_rate.fee_type.fee_for_service) && fee.assistant_modifier_reduction) {
                    if (!fee.modifier_percentage_reduction || fee.modifier_percentage_reduction <= 0) {
                        is_validation_successful = false;
                        validation_obj[expected_rate.expected_rate_fee_validation.id] = fee.expected_rate_fee_id;
                        validation_obj[expected_rate.expected_rate_fee_validation.has_required_validation] = true;
                    } else if (fee.modifier_percentage_reduction > 99.99) {
                        is_validation_successful = false;
                        validation_obj[expected_rate.expected_rate_fee_validation.id] = fee.expected_rate_fee_id;
                        validation_obj[expected_rate.expected_rate_fee_validation.modifier_percentage_reduction] = expected_rate.validation_message.modifier_percentage_reduction;
                    }
                }

                if (this.props.features.argosBill1627ExpectedRatesImprovements && fee.fee_rate_type_id == expected_rate.fee_type.fee_for_service && fee.max_number_of_units) {
                    if (!fee.units || fee.units.floatValue <= 0) {
                        is_validation_successful = false;
                        validation_obj[expected_rate.expected_rate_fee_validation.id] = fee.expected_rate_fee_id;
                        validation_obj[expected_rate.expected_rate_fee_validation.has_required_validation] = true;
                    }
                }

                if (fee.fee_rate_type_id == expected_rate.fee_type.flat_rate) {
                    if (!fee.per_visit || fee.per_visit <= 0) {
                        is_validation_successful = false;
                        validation_obj[expected_rate.expected_rate_fee_validation.id] = fee.expected_rate_fee_id;
                        validation_obj[expected_rate.expected_rate_fee_validation.has_required_validation] = true;
                    }
                }

                if (fee.mppr) {
                    //Validate expected rate procedure codes
                    if (!validation) validation = {};
                    var proc_validations = [];

                    fee.expected_rate_fee_procedure_codes.map((proc_code) => {
                        var proc_validation_obj = {};
                        var is_proc_validation_successful = true;

                        if (proc_code.mppr_rate && (proc_code.expected_reimbursement_unit == null || proc_code.expected_reimbursement_unit === '')) {
                            is_proc_validation_successful = false;
                            proc_validation_obj[expected_rate.expected_rate_fee_validation.expected_rate_fee_procedure_codes.id] = proc_code.procedure_code_id;
                            proc_validation_obj[expected_rate.expected_rate_fee_validation.has_required_validation] = true;
                        }

                        if (!is_proc_validation_successful) {
                            proc_validations.push(proc_validation_obj);
                        }
                    })

                    if (proc_validations.length > 0) {
                        response.is_validation_successful = false;
                        if (!validation) validation = {};
                        validation[expected_rate.validation_obj_structure.id] = expected_rate_obj.expected_rate_id;
                        validation[expected_rate.validation_obj_structure.expected_rate_fee_procedure_codes_validations] = proc_validations;
                    }
                }
               
                if (!is_validation_successful) {
                    fee_validations.push(validation_obj);
                }
                
            });

            if (fee_validations.length > 0) {
                response.is_validation_successful = false;
                if (!validation) validation = {};
                validation[expected_rate.validation_obj_structure.id] = expected_rate_obj.expected_rate_id;
                validation[expected_rate.validation_obj_structure.expected_rate_fees_validations] = fee_validations;
            }

            if (validation) response.validations_list.push(validation);

        });
        return response;
    }

    service_validate_expected_rates = async (modified_expected_rates_list) => {
        var lst_validations = [];
        var toastr_message_display_lst = []
        if (this._is_mounted) {
            modified_expected_rates_list.forEach(expectedRate => {
                if (expectedRate && expectedRate.expected_rate_fees && expectedRate.expected_rate_fees.length > 0) {
                    expectedRate.expected_rate_fees.forEach(expectedRateFee => {
                        if (expectedRateFee && expectedRateFee.expected_rate_fee_procedure_codes && expectedRateFee.expected_rate_fee_procedure_codes.length > 0) {
                            expectedRateFee.expected_rate_fee_procedure_codes.forEach(expectedRateProcedureCode => {
                                if (expectedRateProcedureCode) {
                                    expectedRateProcedureCode.mppr_rate = nullifyString(expectedRateProcedureCode.mppr_rate);
                                }
                            });
                        }
                    });
                }
            });
            await validate_expected_rates(this.props.user_login_details.user_data.data.token_details.access_token, modified_expected_rates_list)
                .then(response => {
                    if (response.data.data) {
                        response.data.data.filter((expected_rate_obj) => {
                            let display_message = '';
                            if (expected_rate_obj.validation_results && expected_rate_obj.validation_results.length > 0) {
                                var er_validation_msg = {};
                                var assigned_insurances_ids_msg_lst = [];
                                var assigned_insurance_class_ids_msg_lst = [];
                                er_validation_msg[expected_rate.validation_obj_structure.has_required_validation] = false;
                                er_validation_msg[expected_rate.validation_obj_structure.id] = expected_rate_obj.expected_rate_id;
                                //expected rate definition validations.
                                expected_rate_obj.validation_results.filter((validation) => {
                                    if (validation.message) {
                                        if (validation.field_name == expected_rate.validation_obj_structure.assigned_insurances_ids) {
                                            assigned_insurances_ids_msg_lst.push({ 'insurance_code_id': validation.field_id, 'message': validation.message });
                                            display_message = display_message + '</br>' + validation.message;
                                        }
                                        else if (validation.field_name == expected_rate.validation_obj_structure.assigned_insurance_class_ids) {
                                            assigned_insurance_class_ids_msg_lst.push({ 'insurance_class_id': validation.field_id, 'message': validation.message });
                                            display_message = display_message + '</br>' + validation.message;
                                        }
                                        else if (validation.field_name == expected_rate.validation_obj_structure.expected_rate_fees) {
                                            if (er_validation_msg[`${validation.field_name}`])
                                                er_validation_msg[`${validation.field_name}`] = validation.message + ' ' + er_validation_msg[`${validation.field_name}`]
                                            else
                                                er_validation_msg[`${validation.field_name}`] = validation.message;

                                            display_message = display_message + '</br>' + validation.message;
                                        }
                                        else {
                                            er_validation_msg[`${validation.field_name}`] = validation.message
                                        }
                                    }
                                });

                                //assign the lst of insurance validations if they exsis.
                                if (assigned_insurances_ids_msg_lst && assigned_insurances_ids_msg_lst.length > 0) {
                                    er_validation_msg[expected_rate.validation_obj_structure.assigned_insurances_ids] = assigned_insurances_ids_msg_lst;
                                }

                                if (assigned_insurance_class_ids_msg_lst && assigned_insurance_class_ids_msg_lst.length > 0) {
                                    er_validation_msg[expected_rate.validation_obj_structure.assigned_insurance_class_ids] = assigned_insurance_class_ids_msg_lst;
                                }

                                if (display_message.trim().length > 0) toastr_message_display_lst.push(display_message.toString() + '</br>');
                            }

                            //expected rate definitions.
                            let expected_rate_fees_validation_lst = null;
                            expected_rate_obj.expected_rate_fees.map((expected_rate_fees_obj) => {
                                let expected_rate_fee_validation = null;
                                if (expected_rate_fees_obj.validation_results && expected_rate_fees_obj.validation_results.length > 0) {
                                    expected_rate_fees_obj.validation_results.map((expected_rate_fee_validation_obj) => {
                                        if (expected_rate_fee_validation_obj.message) {
                                            if (!expected_rate_fees_validation_lst) expected_rate_fees_validation_lst = [];
                                            if (!expected_rate_fee_validation) expected_rate_fee_validation = {};
                                            expected_rate_fee_validation[`${expected_rate.expected_rate_fee_validation.has_required_validation}`] = false;
                                            expected_rate_fee_validation[`${expected_rate.expected_rate_fee_validation.id}`] = expected_rate_fees_obj.expected_rate_fee_id;
                                            if (expected_rate_fee_validation_obj.field_name == expected_rate.expected_rate_fee_validation.assigned_provider_ids ||
                                                expected_rate_fee_validation_obj.field_name == expected_rate.expected_rate_fee_validation.assigned_location_ids) {
                                                if (expected_rate_fee_validation[`${expected_rate_fee_validation_obj.field_name}`]) {
                                                    expected_rate_fee_validation[`${expected_rate_fee_validation_obj.field_name}`] =
                                                        expected_rate_fee_validation_obj.message + ' ' + expected_rate_fee_validation[`${expected_rate_fee_validation_obj.field_name}`]
                                                }
                                                else {
                                                    expected_rate_fee_validation[`${expected_rate_fee_validation_obj.field_name}`] =
                                                        expected_rate_fee_validation_obj.message
                                                }

                                            }
                                            else {
                                                expected_rate_fee_validation[`${expected_rate_fee_validation_obj.field_name}`] = expected_rate_fee_validation_obj.message
                                            }
                                        }
                                    });
                                }
                                //Validate proc code
                                let proc_code_validation_lst = [];
                                expected_rate_fees_obj.expected_rate_fee_procedure_codes.map((proc_code) => {
                                    let proc_code_validation_obj = null;
                                    if (expected_rate_fees_obj.validation_results && expected_rate_fees_obj.validation_results.length > 0) {
                                        expected_rate_fees_obj.validation_results.map((proc_code_validation) => {
                                            if (proc_code_validation.message) {
                                                if (!proc_code_validation_obj) proc_code_validation_obj = {};
                                                proc_code_validation_obj[`${expected_rate.expected_rate_fee_validation.expected_rate_fee_procedure_codes.id}`] = proc_code.procedure_code_id;
                                                if (proc_code.field_name == expected_rate.expected_rate_fee_validation.expected_rate_fee_procedure_codes.expected_reimbursement_unit) {
                                                    if (proc_code_validation_obj[`${proc_code_validation.field_name}`]) {
                                                        proc_code_validation_obj[`${proc_code_validation.field_name}`] =
                                                            proc_code_validation_obj.message + ' ' + proc_code_validation_obj[`${proc_code_validation_obj.field_name}`]
                                                    }
                                                    else {
                                                        proc_code_validation_obj[`${proc_code_validation_obj.field_name}`] =
                                                            proc_code_validation_obj.message
                                                    }

                                                }
                                            }
                                        })
                                    }
                                    if (proc_code_validation_obj) {
                                        proc_code_validation_lst.push(proc_code_validation_obj);
                                    }
                                });

                                if (!expected_rate_fee_validation && proc_code_validation_lst.length > 0) {
                                    expected_rate_fee_validation = {};
                                    expected_rate_fee_validation[`${expected_rate.expected_rate_fee_validation.has_required_validation}`] = false;
                                    expected_rate_fee_validation[`${expected_rate.expected_rate_fee_validation.id}`] = expected_rate_fees_obj.expected_rate_fee_id;
                                }

                                if (proc_code_validation_lst.length > 0)
                                    expected_rate_fee_validation[`${expected_rate.expected_rate_fee_validation.proc_codes_validation_list}`] = proc_code_validation_lst;

                                if (expected_rate_fee_validation) {
                                    expected_rate_fees_validation_lst.push(expected_rate_fee_validation);
                                    display_message = expected_rate_fee_validation.expected_rate_fee_procedure_codes;
                                    if (this.props.features.argosBill1627ExpectedRatesImprovements && expected_rate_fee_validation.units) {
                                        display_message = expected_rate_fee_validation.units;
                                    }
                                }

                                if (display_message && display_message.trim().length > 0 && toastr_message_display_lst.indexOf(display_message.toString() + '</br>') === -1) toastr_message_display_lst.push(display_message.toString() + '</br>');
                            });

                            if (expected_rate_fees_validation_lst && expected_rate_fees_validation_lst.length > 0) {
                                if (!er_validation_msg) {
                                    er_validation_msg = {};
                                    er_validation_msg[expected_rate.validation_obj_structure.has_required_validation] = false;
                                    er_validation_msg[expected_rate.validation_obj_structure.id] = expected_rate_obj.expected_rate_id;
                                }
                                er_validation_msg[`${expected_rate.validation_obj_structure.expected_rate_fees_validations}`] = expected_rate_fees_validation_lst;
                            }

                            if (er_validation_msg) lst_validations.push(er_validation_msg);
                        });
                        this.setState({ lst_validations });

                        if (lst_validations.length == 0) {
                            this.save_expected_rates(modified_expected_rates_list);
                        }
                        else if (toastr_message_display_lst && toastr_message_display_lst.length > 0) {
                            let final_msg = toastr_message_display_lst.join('</br>');
                            const toastr_options = this.show_html_content_toaster(final_msg);
                            toastr.error('', toastr_options);
                        }
                    }
                });
        }
    }
    //#endregion

    // #region Save 
    save_expected_rates = async (modified_expected_rates_list) => {
        if (this._is_mounted) {
            var today = new Date();
            var open_expected_rate = this._open_expected_rate_fee_id;
            var list_expected_rate_table = this.state.list_expected_rate_table
            await save_expected_rates(this.props.user_login_details.user_data.data.token_details.access_token, modified_expected_rates_list)
                .then(response => {
                    if (response.data.data && response.data.data == true) {

                        list_expected_rate_table = list_expected_rate_table.map((expected_rate) => {
                            if (expected_rate.is_expanded == true) {
                                open_expected_rate['name'] = expected_rate.name;
                                var expanded_fee_ids = [];
                                var all_fee_ids = [];
                                expected_rate.expected_rate_fees.map((fee) => {
                                    if (fee.is_expanded)
                                        expanded_fee_ids.push(fee.expected_rate_fee_id);
                                    all_fee_ids.push(fee.expected_rate_fee_id);
                                });
                                open_expected_rate['expanded_fee_ids'] = expanded_fee_ids;
                                open_expected_rate['all_fee_ids'] = all_fee_ids;
                            }
                        });
                        this.get_new_expected_rates_after_save(open_expected_rate);
                        toastr.success('', expected_rate.save_success_msg);
                    }
                    else {
                        toastr.error('', expected_rate.save_error_msg);
                    }
                }, (error) => {
                    toastr.error('', expected_rate.save_error_msg);
                });
        }
    }

    get_new_expected_rates_after_save = async (open_expected_rate) => {
        if (this._is_mounted) {
            await get_expected_rates(this.props.user_login_details.user_data.data.token_details.access_token)
                .then(res => {
                    if (res.data && res.data.data && res.data.data.length > 0) {
                        this._expected_rate_list = this.get_expected_rates_list(res.data.data, open_expected_rate);
                    }
                    else {
                        this._expected_rate_list = []
                    }
                    this.setState({ is_save_in_progress: false, list_expected_rate_table: this._expected_rate_list });
                }
                    , (error) => {
                        this._expected_rate_list = []
                        this.setState({ is_save_in_progress: false, list_expected_rate_table: this._expected_rate_list });
                    });
        }
    }
    // #endregion

    // #region AlertBox
    alert_box_helper = (show_alert_box, alert_msg, action, title, label_yes) => {
        this.setState({
            alert: {
                display: show_alert_box,
                message: alert_msg,
                action: action,
                title: title,
                label_yes: label_yes
            }
        })
    }
    // #endregion

    set_nullable_properties_on_deleted_expected_rates = (local_expected_rate_obj) => {
        if (!local_expected_rate_obj.expected_rate_id) {
            return;
        }
        else {
            if (!local_expected_rate_obj.start_date)
                local_expected_rate_obj.start_date = new Date();
        }
    }

    // #region Add New Expected Rate Button Functionality
    add_new_expected_rate = () => {
        if (!this.is_data_updated()) {
            this.add_default_item_in_expected_rate_list();
        }
        else {
            this.alert_box_helper(true, expected_rate.prompt_message.add_new, expected_rate.alert_action.add_new_expected_rate,
                expected_rate.prompt_title.unsaved_changes, expected_rate.prompt_button_label.continue);
        }
    }

    add_default_item_in_expected_rate_list = () => {
        var expected_rate_list = this.state.list_expected_rate_table;
        var expected_rate_item =
        {
            expected_rate_id: 0,
            name: '',
            start_date: '',
            end_date: '',
            is_expanded: true,
            is_modified: true,
            is_expired: false,
            is_active: true,
            include_cpt_with_no_value: true,
            assigned_insurances_ids: [],
            assigned_insurance_class_ids: [],
            expected_rate_fees: [{
                expected_rate_fee_id: -1,
                fee_rate_type_id: null,
                percentage: null,
                assistant_modifier_reduction: false,
                modifier_percentage_reduction: null,
                mppr: false,
                per_visit: null,
                per_evaluation: null,
                is_active: true,
                assigned_provider_ids: [],
                assigned_location_ids: [],
                include_future_providers: false,
                validation_results: null,
                is_modified: true,
                is_expanded: false,
                expected_rate_fee_procedure_codes: [],
                max_number_of_units: false,
                units: null
            }],
        };

        expected_rate_list = expected_rate_list.map((data_item) => {
            data_item.is_expanded = false;
            return data_item;
        });

        expected_rate_list.splice(0, 0, expected_rate_item);
        if (this._is_mounted) {
            this.setState({
                list_expected_rate_table: expected_rate_list
            });

        }
    }
    // #endregion

    // #region Call-back methods
    on_toggle_expected_rate_definition = async (data) => {
        if (this._is_mounted) {
            let list_expected_rate_table = this.state.list_expected_rate_table;
            var er_list =
                list_expected_rate_table.map((item) => {
                    if (item.expected_rate_id == data) {
                        item.is_expanded = !item.is_expanded;
                    }
                    else
                        item.is_expanded = false;
                    return item;
                });
            this.setState({
                list_expected_rate_table: er_list
            })
        }
    }

    update_expected_rate_definition = async (data, identifier, value) => {
        if (this._is_mounted) {
            var validation;
            var has_required_validations = false;
            var list_expected_rate_table = this.state.list_expected_rate_table;
            list_expected_rate_table =
                list_expected_rate_table.map((item) => {
                    if (item.expected_rate_id == data) {
                        validation = this.getExpectedRateValidation(data);
                        if (identifier == expected_rate.identifier.name) {
                            item.name = value;
                            if (validation && validation.name) {
                                validation.name = "";
                            }
                        }
                        else if (identifier == expected_rate.identifier.dos_range) {
                            item.start_date = value.start_date;
                            item.end_date = value.end_date;
                            if (validation && validation.start_date) validation.start_date = "";
                            if (validation && validation.end_date) validation.end_date = "";
                        }
                        else if (identifier == expected_rate.identifier.is_active) {
                            item.is_active = value;
                            item.is_modified = true
                        }
                        else if (identifier == expected_rate.identifier.include_cpt_with_no_value) {
                            item.include_cpt_with_no_value = value;
                        }
                        else if (identifier == expected_rate.identifier.expected_rate_fee_definition) //update fee definition
                        {
                            item = this.update_expected_rate_fee_definition(item, value);
                        }
                        else if (identifier == expected_rate.identifier.expected_rate_fee_definition_procedure_code) //update expected rate fee definition procedure code
                        {
                            var expected_rate_fees_list = item.expected_rate_fees.map((fee) => {
                                if (fee.expected_rate_fee_id == value.expected_rate_fee_id) {

                                    //If proc code not exists, add new record
                                    if (fee.expected_rate_fee_procedure_codes.filter(x => x.procedure_code_id == value.procedure_code_id).length == 0) {
                                        var new_proc_code: any = {
                                            expected_rate_fee_procedure_code_id: 0,
                                            procedure_code_id: value.procedure_code_id,
                                            expected_reimbursement_unit: null,
                                            is_carve_out: false,
                                            is_modified: true,
                                            mppr_rate: ''
                                        }
                                        if (this.props.features.ff_sumaj_B30566_expected_rate_multiple_fee_schedules) new_proc_code.updated_billed_amount = null;
                                        fee.expected_rate_fee_procedure_codes.push(new_proc_code);
                                    }

                                    var fee_proc_codes = fee.expected_rate_fee_procedure_codes.map((proc_code) => {
                                        if (proc_code.procedure_code_id == value.procedure_code_id) {
                                            proc_code.is_modified = true;
                                            if (value.identifier == expected_rate.identifier.is_carve_out) {
                                                proc_code.is_carve_out = value.value;
                                            }
                                            else if (value.identifier == expected_rate.identifier.expected_reimbursement_unit) {
                                                proc_code.expected_reimbursement_unit = value.value ? value.value : null;
                                            }
                                            else if (value.identifier == expected_rate.identifier.updated_billed_amount) {
                                                proc_code.updated_billed_amount = value.value ? value.value : null;
                                            }
                                            else if (value.identifier == expected_rate.identifier.mppr_rate) {
                                                proc_code.mppr_rate = value.value ? value.value : '';
                                            }
                                        }
                                        return proc_code;
                                    });
                                    fee.is_modified = true;
                                    fee.expected_rate_fee_procedure_codes = fee_proc_codes;
                                }
                                return fee;
                            });
                            item.expected_rate_fees = expected_rate_fees_list;

                        }

                        has_required_validations = this.ui_has_required_validation(item);
                        if (value.identifier != expected_rate.identifier.is_expanded && identifier != expected_rate.identifier.include_cpt_with_no_value) {
                            item.is_modified = true;
                        }
                    }
                    return item;
                });

            //Re-evaluate the insurance validation if date is updated.
            if (identifier == expected_rate.identifier.dos_range && validation) {
                //Reset over lap validation
                if (validation[expected_rate.validation_obj_structure.assigned_insurances_ids] &&
                    !this.insurance_code_list_has_overlap_validation(data, null, list_expected_rate_table)) {
                    validation[expected_rate.validation_obj_structure.assigned_insurances_ids] = '';
                }
            }

            let lst_validations = this.update_validation_object(data, has_required_validations, validation);
            this.setState({
                list_expected_rate_table,
                lst_validations
            })
        }
    }

    update_expected_rate_fee_definition = (expected_rate_obj, value) => {

        let filtered_expected_rate_fee = expected_rate_obj.expected_rate_fees.filter((expected_rate_fee_obj) => {
            if (expected_rate_fee_obj.expected_rate_fee_id == value.expected_rate_fee_id) return true;
        });

        if (filtered_expected_rate_fee.length == 0 || filtered_expected_rate_fee.length > 1) return expected_rate_obj;

        let expected_rate_fee_to_update = filtered_expected_rate_fee[0];

        expected_rate_fee_to_update.is_modified = true;

        if (value.identifier == expected_rate.identifier.location) {
            expected_rate_fee_to_update.assigned_location_ids = value.value;
        }
        else if (value.identifier == expected_rate.identifier.provider) {
            expected_rate_fee_to_update.assigned_provider_ids = value.value;
            if (value.future != null) {
                expected_rate_fee_to_update.include_future_providers = value.future;
            }
        }
        else if (value.identifier == expected_rate.identifier.fee_rate_type) {
            if (expected_rate_fee_to_update.expected_rate_fee_id == value.expected_rate_fee_id) {
                expected_rate_fee_to_update.fee_rate_type_id = value.value;
                if (value.value == expected_rate.fee_type.flat_rate) {
                    expected_rate_fee_to_update.percentage = null;
                    expected_rate_fee_to_update.modifier_percentage_reduction = null;
                    expected_rate_fee_to_update.assistant_modifier_reduction = null;
                    expected_rate_fee_to_update.mppr = null;
                    expected_rate_fee_to_update.expected_rate_fee_procedure_codes.map((proc_code) => {
                        proc_code.mppr_rate = '';
                        proc_code.is_modified = true;
                    });
                    expected_rate_fee_to_update.max_number_of_units = null;
                    expected_rate_fee_to_update.units = null;
                }
                else if (value.value == expected_rate.fee_type.fee_for_service) {
                    expected_rate_fee_to_update.per_visit = null;
                    expected_rate_fee_to_update.per_evaluation = null;
                    !this.props.features.argosBill1627ExpectedRatesImprovements ?
                    expected_rate_fee_to_update.expected_rate_fee_procedure_codes.map((proc_code) => {
                        proc_code.is_carve_out = false;
                    }) : null;
                }
                else {
                    expected_rate_fee_to_update.per_visit = null;
                    expected_rate_fee_to_update.per_evaluation = null;
                    expected_rate_fee_to_update.percentage = null;
                    expected_rate_fee_to_update.modifier_percentage_reduction = null;
                    expected_rate_fee_to_update.assistant_modifier_reduction = null;
                    expected_rate_fee_to_update.mppr = null;
                    expected_rate_fee_to_update.max_number_of_units = null;
                    expected_rate_fee_to_update.units = null;

                    expected_rate_fee_to_update.expected_rate_fee_procedure_codes.map((proc_code) => {
                        proc_code.is_carve_out = false;
                        proc_code.mppr_rate = '';
                        proc_code.is_modified = true;
                    });
                }
            }
        }
        else if (value.identifier == expected_rate.identifier.percentage) {
            expected_rate_fee_to_update.percentage = value.value ? parseInt(value.value) : null;
        }
        else if (value.identifier == expected_rate.identifier.per_visit) {
            expected_rate_fee_to_update.per_visit = value.value;
        }
        else if (value.identifier == expected_rate.identifier.per_evaluation) {
            expected_rate_fee_to_update.per_evaluation = value.value;
        }
        else if (value.identifier == expected_rate.identifier.is_expanded) {
            expected_rate_fee_to_update.is_expanded = value.value;
        }
        else if (value.identifier == expected_rate.identifier.is_active) {
            expected_rate_fee_to_update.is_active = false;
        }
        else if (value.identifier == expected_rate.identifier.assistant_modifier_reduction) {
            expected_rate_fee_to_update.assistant_modifier_reduction = value.value || null;
            if (!expected_rate_fee_to_update.assistant_modifier_reduction) {
                expected_rate_fee_to_update.modifier_percentage_reduction = null;
            }
        }
        else if (value.identifier == expected_rate.identifier.mppr) {
            expected_rate_fee_to_update.mppr = value.value || null;
            if (!expected_rate_fee_to_update.mppr) {
                expected_rate_fee_to_update.expected_rate_fee_procedure_codes.map((proc_code) => {
                    proc_code.mppr_rate = '';
                    proc_code.is_modified = true;
                });
            }
        }
        else if (value.identifier == expected_rate.identifier.modifier_percentage_reduction) {
            expected_rate_fee_to_update.modifier_percentage_reduction = value.value || null;
        }
        else if (this.props.features.argosBill1627ExpectedRatesImprovements && value.identifier == expected_rate.identifier.max_number_of_units) {
            expected_rate_fee_to_update.max_number_of_units = value.value;
            if (!expected_rate_fee_to_update.max_number_of_units) {
                expected_rate_fee_to_update.units = null;
                expected_rate_fee_to_update.expected_rate_fee_procedure_codes.map((proc_code) => {
                    proc_code.is_carve_out = false;
                    proc_code.is_modified = true;
                });
            }
        }
        else if (this.props.features.argosBill1627ExpectedRatesImprovements && value.identifier == expected_rate.identifier.units) {
            expected_rate_fee_to_update.units = value.value.floatValue >= 0 ? value.value.floatValue : null;
        }
        return expected_rate_obj;
    }

    open_insurance_codes_drawer = () => {
        this.setState({
            show_drawer: true
        })
    }

    on_insurance_drawer_close = (expected_rate_id, insurance_id_lst, insurance_class_list) => {
        let lst_validations = this.state.lst_validations;
        if (!insurance_id_lst) insurance_id_lst = [];
        if (!insurance_class_list) insurance_class_list = [];
        let current_expected_rate = this.get_expected_rate(expected_rate_id);
        let list_expected_rate_table = this.state.list_expected_rate_table;
        if (JSON.stringify(current_expected_rate.assigned_insurances_ids) != JSON.stringify(insurance_id_lst)) {
            current_expected_rate.assigned_insurances_ids = insurance_id_lst;
            current_expected_rate.is_modified = true;
        }
        if (JSON.stringify(current_expected_rate.assigned_insurance_class_ids) != JSON.stringify(insurance_class_list)) {
            current_expected_rate.assigned_insurance_class_ids = insurance_class_list;
            current_expected_rate.is_modified = true;
        }
        list_expected_rate_table = list_expected_rate_table.filter((expected_rate_obj) => {
            if (expected_rate_obj.expected_rate_id == expected_rate_id) expected_rate_obj = current_expected_rate;
            return expected_rate_obj;
        });

        //Refresh the Validation if required
        let expected_rate_validation = this.getExpectedRateValidation(expected_rate_id);
        if (expected_rate_validation) {
            //check for required vaidation              
            let has_required_validations = this.ui_has_required_validation(current_expected_rate)

            //Reset over lap validation
            if (expected_rate_validation[expected_rate.validation_obj_structure.assigned_insurances_ids] &&
                current_expected_rate.assigned_insurances_ids.length > 0 &&
                !this.insurance_code_list_has_overlap_validation(expected_rate_id, current_expected_rate, list_expected_rate_table)) {
                expected_rate_validation[expected_rate.validation_obj_structure.assigned_insurances_ids] = '';
            }
            lst_validations = this.update_validation_object(expected_rate_id, has_required_validations, expected_rate_validation);
        }

        this.setState({ list_expected_rate_table, lst_validations, show_drawer: false });
    }

    copy_expected_rate_definition_prompt = (expected_rate_id) => {
        this._expected_rate_id_marked_for_copy = expected_rate_id
        if (this.is_data_updated()) {
            this.alert_box_helper(true, expected_rate.prompt_message.add_new, expected_rate.alert_action.copy_expected_rate,
                expected_rate.prompt_title.unsaved_changes, expected_rate.prompt_button_label.continue);
        }
        else {
            this.copy_expected_rate_fee(this._expected_rate_id_marked_for_copy);
        }
    }

    set_delete_msg = (premsg, boldmsg, postmsg) => {
        const val = premsg + ' <b>' + boldmsg + '</b>. ' + postmsg;
        return (
            <span dangerouslySetInnerHTML={{ __html: val }} />
        );
    }

    delete_expected_rate_definition_prompt = (expected_rate_id, expected_rate_name) => {
        let delete_prompt_message = this.set_delete_msg(expected_rate.prompt_message.to_delete, expected_rate_name, expected_rate.prompt_message.to_continue);
        this._expected_rate_id_to_delete = expected_rate_id
        this._expected_rate_name_to_delete = expected_rate_name
        this.alert_box_helper(true, delete_prompt_message, expected_rate.alert_action.delete_expected_rate,
            expected_rate.prompt_title.delete, expected_rate.prompt_button_label.delete);
    }

    copy_expected_rate_fee_definition = (expected_rate_id, expected_rate_fee_id) => {
        this.add_new_fee_item(expected_rate_id, expected_rate_fee_id);
    }

    delete_expected_rate_fee_definition_prompt = (expected_rate_id, expected_rate_fee_id) => {
        this._expected_rate_fee_marked_for_delete = {
            "expected_rate_id": expected_rate_id,
            "expected_rate_fee_id": expected_rate_fee_id
        };
        this.alert_box_helper(true, expected_rate.prompt_message.delete_fee_definition, expected_rate.alert_action.delete_expected_rate_fee,
            expected_rate.prompt_title.confirm, expected_rate.prompt_button_label.ok);
    }
    // #endregion

    // #region Handle Confirm
    handle_confirm = (e) => {

        e.preventDefault();
        let action = this.state.alert.action;
        this.setState({
            alert: {
                display: false,
                message: '',
                action: '',
                title: '',
                label_yes: ''
            },
            is_save_in_progress: false
        });

        switch (action) {
            case expected_rate.alert_action.add_new_expected_rate:
                this.get_new_expected_rates_after_save(this._open_expected_rate_fee_id).then(() => {
                    this.add_default_item_in_expected_rate_list();
                })
                break;
            case expected_rate.alert_action.copy_expected_rate:
                this.get_new_expected_rates_after_save(this._open_expected_rate_fee_id).then(() => {
                    this.copy_expected_rate_fee(this._expected_rate_id_marked_for_copy);
                })
                break;
            case expected_rate.alert_action.delete_expected_rate:

                if (this._expected_rate_id_to_delete > 0) {
                    this.update_expected_rate_definition(this._expected_rate_id_to_delete, expected_rate.identifier.is_active, false);
                }
                else {

                    let filtered_tables = this.state.list_expected_rate_table.filter((table) => {
                        if (table.expected_rate_id != this._expected_rate_id_to_delete) return true;
                    });

                    this.setState({ list_expected_rate_table: filtered_tables });

                }
                let delete_expected_rate_success_msg = this._expected_rate_name_to_delete + ' ' + expected_rate.prompt_message.has_deleted
                toastr.success('', delete_expected_rate_success_msg);
                break;
            case expected_rate.alert_action.delete_expected_rate_fee:
                this.delete_expected_rate_fee_definition(this._expected_rate_fee_marked_for_delete.expected_rate_id, this._expected_rate_fee_marked_for_delete.expected_rate_fee_id)
                this._expected_rate_fee_marked_for_delete = { expected_rate_id: null, expected_rate_fee_id: null };
                break;
        }

    }
    // #endregion

    // #region Helper Methods
    getActiveTable = () => {
        return this.state.list_expected_rate_table.filter((table) => {
            if (!this.props.features.argosBill1627ExpectedRatesImprovements) {
                if (table.expected_rate_fees && table.expected_rate_fees.length == 1 && table.expected_rate_fees[0].fee_rate_type_id == expected_rate.fee_type.reduction_percent) return;
            }
            if (!table.is_expired && table.is_active)
                return true;
        });
    }
    getExpiredTable = () => {
        return this.state.list_expected_rate_table.filter((table) => {
            if (!this.props.features.argosBill1627ExpectedRatesImprovements) {
                if (table.expected_rate_fees && table.expected_rate_fees.length == 1 && table.expected_rate_fees[0].fee_rate_type_id == expected_rate.fee_type.reduction_percent) return;
            }
            if (table.is_expired && table.is_active)
                return true;
        });
    }

    hasActiveTable = () => {
        return this.getActiveTable().length > 0;
    }

    hasExpiredTable = () => {
        return this.getExpiredTable().length > 0;
    }

    is_data_updated = () => {
        var modified_list =
            this.state.list_expected_rate_table.filter((table) => {
                if (table.is_modified == true) {
                    return true;
                }
            });

        return modified_list.length > 0;
    }

    is_save_button_disabled = () => {
        return this.state.lst_validations.length == 0 && (this.state.is_save_in_progress == true || !this.is_data_updated());
    }

    get_expected_rate = (expected_rate_id) => {
        let search_obj;
        let searched_obj_lst = this.state.list_expected_rate_table.filter((expected_rate_obj) => {
            if (expected_rate_obj.expected_rate_id == expected_rate_id) return expected_rate_obj;
        });
        if (searched_obj_lst && searched_obj_lst.length == 1) search_obj = searched_obj_lst[0];
        return search_obj;
    }

    get_current_expected_rate = () => {
        let current_open_obj;
        let searched_obj_lst = this.state.list_expected_rate_table.filter((expected_rate_obj) => {
            if (expected_rate_obj.is_expanded == true) return expected_rate_obj;
        });
        if (searched_obj_lst && searched_obj_lst.length == 1) current_open_obj = searched_obj_lst[0];
        return current_open_obj;

    }

    ui_has_required_validation = (expected_rate_obj) => {
        return (
            !(expected_rate_obj.name.trim() &&
                expected_rate_obj.start_date &&
                (expected_rate_obj.assigned_insurances_ids || expected_rate_obj.assigned_insurances_ids.length > 0)
            )
        );
    }

    getExpectedRateValidation = (expected_rate_id) => {
        let search_result;
        let validation = {}

        if (this.state.lst_validations &&
            this.state.lst_validations.length > 0) {
            search_result = this.state.lst_validations.filter((validation) => {
                if (validation && expected_rate_id == validation.expected_rate_id) return true
            });
        }
        if (search_result && search_result.length == 1) validation = search_result[0];
        return validation
    }

    get_formatted_insurance_list = (expected_rate_id, expected_rate_list, type = 'insurance_code') => {
        var availability_info;
        let formatted_insurance_display = []
        let current_expected_rate = this.get_expected_rate(expected_rate_id);

        let insurance_list = type == 'insurance_code' ?  this._insurance_code_list : this._insurance_class_list ;
        let validation_obj;
        let dtls_type = type == 'insurance_code' ? 'insurance_code_id' : 'id';
        insurance_list.map((insurance_dtls) => {
            availability_info = this.is_insurance_available(expected_rate_id,
                insurance_dtls[dtls_type], current_expected_rate.start_date,
                current_expected_rate.end_date, expected_rate_list, type);

            validation_obj = this.getExpectedRateValidation(expected_rate_id);
            let final_server_validation_msg = '';
            let val_type = type == 'insurance_code' ? 'assigned_insurances_ids' : 'assigned_insurance_class_ids';
            if (validation_obj && validation_obj[val_type] && Array.isArray(validation_obj[val_type])) {
                validation_obj[val_type].filter((obj) => {
                    if (obj[dtls_type] == insurance_dtls[dtls_type]) {
                        final_server_validation_msg = final_server_validation_msg + ' ' + obj.message;
                    }
                });
                if (final_server_validation_msg) availability_info.on_hover_msg = final_server_validation_msg;
            }

            formatted_insurance_display.push({
                'value': insurance_dtls[dtls_type],
                'name': type == 'insurance_code' ? insurance_dtls.insurance_display_name : insurance_dtls.insurance_class_code + '-' + insurance_dtls.insurance_class_description,
                'is_disabled': !availability_info.is_available,
                'on_hover_msg': availability_info.on_hover_msg
            });
        });

        return formatted_insurance_display;
    }

    is_insurance_available = (expected_rate_id, insurance_id, start_date, end_date, expected_rate_list_lookup, type) => {
        var response = { is_available: true, on_hover_msg: '' }

        if (!start_date && !end_date) return response
        let val_type = type == 'insurance_code' ? 'assigned_insurances_ids' : 'assigned_insurance_class_ids';
        //Check for expected rate that have that uses the insurance
        let expected_rate_list = expected_rate_list_lookup.filter((expected_rate_obj) => {
            if (expected_rate_id != expected_rate_obj.expected_rate_id &&
                expected_rate_obj.is_active == true &&
                expected_rate_obj[val_type] &&
                expected_rate_obj[val_type].includes(insurance_id)) {
                return expected_rate_obj;
            }
        });

        if (!expected_rate_list || expected_rate_list.length == 0) return response

        //Check for date range over lap
        let expected_rate_start_date, expected_rate_end_date;
        var req_date_range = { start_date: null, end_date: null };
        if (start_date) req_date_range.start_date = new Date(start_date);
        if (end_date) req_date_range.end_date = new Date(end_date);
        let current_code_is_available = false;
        let on_hover_msg_lst = [];

        expected_rate_list.filter((expected_rate_obj) => {
            if (expected_rate_obj.start_date) expected_rate_start_date = new Date(expected_rate_obj.start_date);
            if (expected_rate_obj.end_date) expected_rate_end_date = new Date(expected_rate_obj.end_date);

            if (req_date_range.start_date && req_date_range.end_date) {

                let before_current_date_range = expected_rate_start_date &&
                    req_date_range.start_date < expected_rate_start_date &&
                    req_date_range.end_date < expected_rate_start_date;

                let after_current_date_range = expected_rate_start_date &&
                    req_date_range.start_date > expected_rate_start_date &&
                    req_date_range.end_date > expected_rate_start_date &&
                    expected_rate_end_date &&
                    req_date_range.start_date > expected_rate_end_date &&
                    req_date_range.end_date > expected_rate_end_date;

                current_code_is_available = before_current_date_range || after_current_date_range
            }
            else if (req_date_range.start_date && !req_date_range.end_date) {
                //after current er date range
                current_code_is_available = expected_rate_start_date &&
                    req_date_range.start_date > expected_rate_start_date &&
                    expected_rate_end_date &&
                    req_date_range.start_date > expected_rate_end_date;
            }
            else if (!req_date_range.start_date && req_date_range.end_date) {
                current_code_is_available = false;
            }

            if (!current_code_is_available) {
                on_hover_msg_lst.push(expected_rate_obj.name || expected_rate.expected_rate_untitled);
            }
            //If save in in progress see if the validation msg list is the source object to enable it.
            response.is_available = this.state.is_save_in_progress || (response.is_available && current_code_is_available);
            expected_rate_start_date = null;
            expected_rate_end_date = null;
        });
        response.on_hover_msg = on_hover_msg_lst.length > 0 ? (expected_rate.validation_message.assigned_insurances_ids.hover + on_hover_msg_lst.join(', ')) : '';

        return response;
    }

    get_insurance_codes_or_class_selected_list = (expected_rate_id, return_insurance_class_data = false) => {
        let expected_rate = this.state.list_expected_rate_table.filter(er => er.expected_rate_id == expected_rate_id);
        if (expected_rate.length > 0)
            return return_insurance_class_data ? expected_rate[0].assigned_insurance_class_ids : expected_rate[0].assigned_insurances_ids;
        else
            return [];
    }

    insurance_code_list_has_overlap_validation = (expected_rate_id, expected_rate_obj, expected_rate_list) => {
        let lst_insurance_validations;
        if (!expected_rate_obj)
            expected_rate_obj = expected_rate_list.filter((item) => {
                if (item.expected_rate_id == expected_rate_id) return true;
            });

        if (expected_rate_obj.assigned_insurances_ids && expected_rate_obj.assigned_insurances_ids.length > 0) {
            let formatted_insurance_list = this.get_formatted_insurance_list(expected_rate_obj.expected_rate_id, expected_rate_list, 'insurance_code');
            lst_insurance_validations = formatted_insurance_list.filter((insurance_code_obj) => {
                if (insurance_code_obj.on_hover_msg) return true;
            });

            if (lst_insurance_validations && lst_insurance_validations.length > 0) {
                lst_insurance_validations = lst_insurance_validations.filter((item) => {
                    if (expected_rate_obj.assigned_insurances_ids.includes(item.value)) return true;
                });
            }
        }

        return (lst_insurance_validations && lst_insurance_validations.length > 0);
    }

    update_validation_object = (expected_rate_id, has_required_validations, validation_obj) => {
        let lst_validations = this.state.lst_validations;
        if (validation_obj) {
            validation_obj.has_required_validation = has_required_validations;
            lst_validations = lst_validations.filter((validation_item) => {
                if (validation_item.expected_rate_id == expected_rate_id) validation_item = validation_obj;
                return true;
            });
        }
        return lst_validations;
    }

    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }

    get_active_expected_rate_fees = (lst_expected_rate_fee) => {
        let filtered_expected_rate_fee = [];
        lst_expected_rate_fee.map((rate_fee_obj) => {
            if (rate_fee_obj.is_active == true) filtered_expected_rate_fee.push(rate_fee_obj);
        });
        return filtered_expected_rate_fee;
    }
    // #endregion

    add_new_fee_item = (expected_rate_id, copy_from_fee_id) => {
        var expected_rate_fee_id = 0;
        var expected_rate = this.state.list_expected_rate_table.filter(item => item.expected_rate_id == expected_rate_id);
        if (expected_rate.length > 0) {
            expected_rate_fee_id = (-1 - expected_rate[0].expected_rate_fees.filter(x => x.expected_rate_fee_id <= 0).length);
        }

        var list_expected_rate_table = this.state.list_expected_rate_table.map((data_item) => {
            if (data_item.expected_rate_id == expected_rate_id) {
                data_item.is_modified = true;
                var expected_rate_fees_list = data_item.expected_rate_fees;
                var copy_from_fee_obj = null;
                if (copy_from_fee_id != 0) {
                    var fee_obj = expected_rate_fees_list.filter(fee => fee.expected_rate_fee_id == copy_from_fee_id);
                    if (fee_obj.length > 0)
                        copy_from_fee_obj = fee_obj[0];
                }
                else {
                    data_item.include_cpt_with_no_value = true;
                }

                var new_item =
                {
                    expected_rate_fee_id: expected_rate_fee_id,
                    fee_rate_type_id: null,
                    percentage: null,
                    per_visit: null,
                    per_evaluation: null,
                    is_active: true,
                    assigned_provider_ids: [],
                    assigned_location_ids: [],
                    include_future_providers: false,
                    validation_results: null,
                    is_modified: true,
                    is_expanded: false,
                    assistant_modifier_reduction: false,
                    modifier_percentage_reduction: null,
                    mppr: false,
                    expected_rate_fee_procedure_codes: []
                };

                if (copy_from_fee_obj != null) {
                    new_item.fee_rate_type_id = copy_from_fee_obj.fee_rate_type_id;
                    new_item.percentage = copy_from_fee_obj.percentage;
                    new_item.per_visit = copy_from_fee_obj.per_visit;
                    new_item.per_evaluation = copy_from_fee_obj.per_evaluation;
                    new_item.fee_rate_type_id = copy_from_fee_obj.fee_rate_type_id;
                    new_item.is_expanded = copy_from_fee_obj.is_expanded;
                    new_item.assistant_modifier_reduction = copy_from_fee_obj.assistant_modifier_reduction;
                    new_item.mppr = copy_from_fee_obj.mppr;
                    new_item.modifier_percentage_reduction = copy_from_fee_obj.modifier_percentage_reduction;
                    var proc_codes_list = [];

                    var local_copy_fee_procedure_codes = [...copy_from_fee_obj.expected_rate_fee_procedure_codes]
                    local_copy_fee_procedure_codes.map((proc_code) => {
                        let local_proc_code_obj = Object.assign({}, proc_code);
                        local_proc_code_obj.is_modified = true;
                        local_proc_code_obj.last_updated = null;
                        proc_codes_list.push(local_proc_code_obj)
                    });
                    new_item.expected_rate_fee_procedure_codes = proc_codes_list;
                }

                expected_rate_fees_list.push(new_item);
                data_item.expected_rate_fees = expected_rate_fees_list;
            }
            return data_item;
        });

        if (this._is_mounted) {
            this.setState({
                list_expected_rate_table
            });
        }
    }

    // #region generate render content

    get_expected_rate_component = (expected_rate_table_items) => {
        let expected_rate_definitions;
        let expected_rate;
        expected_rate_definitions =
            expected_rate_table_items.map((item) => {
                expected_rate = Object.assign({}, item);
                expected_rate.expected_rate_fees = [...item.expected_rate_fees]
                let fileted_rate_fees = this.get_active_expected_rate_fees(expected_rate.expected_rate_fees)
                expected_rate.expected_rate_fees = fileted_rate_fees.map((expected_rate_fee_state_obj) => {
                    let local_copy_expected_rate_fee = Object.assign({}, expected_rate_fee_state_obj);
                    local_copy_expected_rate_fee.expected_rate_fee_procedure_codes = [...expected_rate_fee_state_obj.expected_rate_fee_procedure_codes];

                    local_copy_expected_rate_fee.expected_rate_fee_procedure_codes =
                        this.get_cpt_code_list(expected_rate_fee_state_obj.expected_rate_fee_procedure_codes, expected_rate.include_cpt_with_no_value);

                    return local_copy_expected_rate_fee;
                });

                return (
                    <DefinitionComponent
                        key={expected_rate.expected_rate_id}
                        row_id={expected_rate.expected_rate_id}
                        data={expected_rate}
                        onToggleExpectedRateDefinition={this.on_toggle_expected_rate_definition}
                        updateExpectedRateDefinition={this.update_expected_rate_definition}
                        is_save_in_progress={this.state.is_save_in_progress}
                        validation={this.getExpectedRateValidation(item.expected_rate_id)}
                        openInsuranceCodesDrawer={this.open_insurance_codes_drawer}
                        addNewFeeItem={this.add_new_fee_item}
                        location_list={this._locations_list}
                        provider_list={this._providers_list}
                        fee_rate_type_list={this._fee_rate_type_list}
                        copy_expected_rate_definition_prompt={this.copy_expected_rate_definition_prompt}
                        delete_expected_rate_definition_prompt={this.delete_expected_rate_definition_prompt}
                        copyExpectedRateFeeDefinition={this.copy_expected_rate_fee_definition}
                        deleteExpectedRateFeeDefinitionPrompt={this.delete_expected_rate_fee_definition_prompt}
                    />
                )
            })
        return expected_rate_definitions;
    }

    get_insurance_drawer_component = () => {
        let open_expected_rate_obj = this.get_current_expected_rate()
        let expected_rate_id = open_expected_rate_obj ? open_expected_rate_obj.expected_rate_id : -1;
        let selected_list = [...this.get_insurance_codes_or_class_selected_list(expected_rate_id)];
        let selected_class_list = [...this.get_insurance_codes_or_class_selected_list(expected_rate_id, true)];
        return (
            <AddInsuranceDrawerComponent
                key={expected_rate_id}
                key_id={expected_rate_id}
                isOpen={this.state.show_drawer}
                onInsuranceDrawerClose={this.on_insurance_drawer_close}
                insurance_list={this.get_formatted_insurance_list(expected_rate_id, this.state.list_expected_rate_table, 'insurance_code')}
                insurance_class_list={this.get_formatted_insurance_list(expected_rate_id, this.state.list_expected_rate_table, 'insurance_class')}
                selected_list={selected_list}
                selected_insurance_class_list={selected_class_list}
                expected_rate_id={expected_rate_id}
                is_save_in_progress={this.state.is_save_in_progress}
            />
        );
    }

    get_render_contents = () => {
        switch (true) {
            case this.state.list_expected_rate_table.length == 0:
                return (
                    <React.Fragment>
                        <Grid style={{ marginTop: 0, marginBottom: 0, paddingTop: 30 }}>
                            <Grid.Column computer={16} align='center'>
                                <Header id='no_expected_rates_msg' as='h4' className='small form_section_heading'>
                                    {expected_rate.no_data_display_text}
                                </Header>
                            </Grid.Column>
                        </Grid>
                    </React.Fragment>
                );
            case this.hasActiveTable() && this.hasExpiredTable():
                return (
                    <React.Fragment>
                        {/*marginBottom: 10 will be replaced with marginBottom: 0 when Table Data comes in*/}
                        <Grid style={{ marginTop: 0, marginBottom: 0 }}>
                            <Grid.Column computer={16}>
                                <Header
                                    id='active_expected_rates_hdr_id'
                                    as='h4'
                                    className='small form_section_heading'
                                >
                                    {expected_rate.active_header_text}
                                </Header>
                            </Grid.Column>
                        </Grid>
                        <div id='expected_rate_all_active_definitions'>
                            {this.get_expected_rate_component(this.getActiveTable())}
                        </div>
                        <Grid style={{ marginTop: 0, marginBottom: 0 }}>
                            <Grid.Column computer={16}>
                                <Header
                                    id='expired_expected_rates_hdr_id'
                                    as='h4'
                                    className='small form_section_heading'
                                >
                                    {expected_rate.expired_header_text}
                                </Header>
                            </Grid.Column>
                        </Grid>
                        <div id='expected_rate_all_expired_definitions'>
                            {this.get_expected_rate_component(this.getExpiredTable())}
                        </div>
                    </React.Fragment>
                );
            case this.hasExpiredTable() && !this.hasActiveTable():
                return (
                    <React.Fragment>
                        <Grid style={{ marginTop: 0, marginBottom: 0 }}>
                            <Grid.Column computer={16}>
                                <Header
                                    id='expired_expected_rates_hdr_id'
                                    as='h4'
                                    className='small form_section_heading'
                                >
                                    {expected_rate.expired_header_text}
                                </Header>
                            </Grid.Column>
                        </Grid>
                        <div id='expected_rate_all_expired_definitions'>
                            {this.get_expected_rate_component(this.getExpiredTable())}
                        </div>
                    </React.Fragment>
                );
            default:
                return (
                    <div id='expected_rate_all_active_definitions'>
                        {this.get_expected_rate_component(this.getActiveTable())}
                    </div>
                );
        }
    }
    //#endregion

    // #region Copy Functionality
    copy_expected_rate_fee = (expected_rate_id) => {
        let lst_current_expected_rates = this.state.list_expected_rate_table;
        let copy_from = this.get_expected_rate(expected_rate_id);
        let id_counter = 0
        if (copy_from) {
            let latest_updated_date = new Date();
            let copy_to = Object.assign({}, copy_from);
            //Reset data on copy_to
            copy_to.expected_rate_id = 0;
            copy_to.name = copy_to.name + ' Copy';
            copy_to.start_date = null;
            copy_to.end_date = null;
            copy_to.is_expanded = true;
            copy_to.is_modified = true;
            copy_to.is_expired = false;
            copy_to.expected_rate_fees = [...copy_from.expected_rate_fees]
            let copied_expected_rate_fees = [];
            copy_to.expected_rate_fees = copy_to.expected_rate_fees.map((fee) => {
                let new_copy_fee = Object.assign({}, fee);
                new_copy_fee.expected_rate_fee_id = id_counter
                new_copy_fee.is_modified = true;
                new_copy_fee.is_expanded = false;
                new_copy_fee.is_active = true;
                id_counter = id_counter - 1;
                //#region Copy CPT Code
                new_copy_fee.expected_rate_fee_procedure_codes = [...fee.expected_rate_fee_procedure_codes];
                let copied_fee_procedure_codes = [];
                new_copy_fee.expected_rate_fee_procedure_codes.map((fee_procedure_code) => {
                    if(this._procedure_code_list.filter(p => p.procedure_code_id === fee_procedure_code.procedure_code_id).length > 0){
                    let new_fee_procedure_code = Object.assign({}, fee_procedure_code);
                    new_fee_procedure_code.expected_rate_fee_procedure_code_id = 0;
                    new_fee_procedure_code.is_modified = true;
                    new_fee_procedure_code.last_updated = latest_updated_date;
                    copied_fee_procedure_codes.push(new_fee_procedure_code);
                    }
                });
                new_copy_fee.expected_rate_fee_procedure_codes = copied_fee_procedure_codes;
                //#end region
                copied_expected_rate_fees.push(new_copy_fee);
            });
            copy_to.expected_rate_fees = copied_expected_rate_fees;
            //close any open expected rate objects and refesh the copu flap

            lst_current_expected_rates.map((expected_rate_obj) => {
                expected_rate_obj.is_expanded = false;
                expected_rate_obj.expected_rate_fees.map((fee) => {
                    fee.is_expanded = false;
                });
            });

            lst_current_expected_rates.splice(0, 0, copy_to);
            this._expected_rate_id_marked_for_copy = -1;
            this.setState({ list_expected_rate_table: lst_current_expected_rates });
        }
    }
    // #endregion

    // #region Get CPT codes list
    get_cpt_code_list = (selected_proc_codes, include_cpt_with_no_value) => {
        var cpt_code_list = [];
        let master_proc_code_lst = this._procedure_code_list;
        master_proc_code_lst.map((read_proc_code_obj) => {
            var proc_code_to_be_added = {};
            proc_code_to_be_added[`${expected_rate.proc_code_obj.id}`] = 0;
            proc_code_to_be_added[`${expected_rate.proc_code_obj.procedure_code_id}`] = read_proc_code_obj.procedure_code_id;
            proc_code_to_be_added[`${expected_rate.proc_code_obj.proc_code}`] = read_proc_code_obj.proc_code;
            proc_code_to_be_added[`${expected_rate.proc_code_obj.reimbursement_unit}`] = '';
            proc_code_to_be_added[`${expected_rate.proc_code_obj.updated_billed_amount}`] = '';
            proc_code_to_be_added[`${expected_rate.proc_code_obj.std_chg_amt}`] = read_proc_code_obj.std_chg_amt;
            proc_code_to_be_added[`${expected_rate.proc_code_obj.carve_out}`] = false;
            proc_code_to_be_added[`${expected_rate.proc_code_obj.last_updated}`] = null;
            proc_code_to_be_added[`${expected_rate.proc_code_obj.is_modified}`] = false;
            proc_code_to_be_added[`${expected_rate.proc_code_obj.mppr_rate}`] = '';

            let existing_proc_code = selected_proc_codes.filter(x => x.procedure_code_id == read_proc_code_obj.procedure_code_id);
            if (existing_proc_code.length > 0) {
                proc_code_to_be_added[`${expected_rate.proc_code_obj.id}`] = existing_proc_code[0][`${expected_rate.proc_code_obj.id}`];
                proc_code_to_be_added[`${expected_rate.proc_code_obj.reimbursement_unit}`] = existing_proc_code[0][`${expected_rate.proc_code_obj.reimbursement_unit}`] != null ? existing_proc_code[0][`${expected_rate.proc_code_obj.reimbursement_unit}`] : '';
                proc_code_to_be_added[`${expected_rate.proc_code_obj.updated_billed_amount}`] = existing_proc_code[0][`${expected_rate.proc_code_obj.updated_billed_amount}`] != null ? existing_proc_code[0][`${expected_rate.proc_code_obj.updated_billed_amount}`] : '';
                proc_code_to_be_added[`${expected_rate.proc_code_obj.carve_out}`] = existing_proc_code[0][`${expected_rate.proc_code_obj.carve_out}`];
                proc_code_to_be_added[`${expected_rate.proc_code_obj.last_updated}`] = existing_proc_code[0][`${expected_rate.proc_code_obj.last_updated}`];
                proc_code_to_be_added[`${expected_rate.proc_code_obj.is_modified}`] = existing_proc_code[0][`${expected_rate.proc_code_obj.is_modified}`];
                proc_code_to_be_added[`${expected_rate.proc_code_obj.mppr_rate}`] = existing_proc_code[0][`${expected_rate.proc_code_obj.mppr_rate}`] != null ? existing_proc_code[0][`${expected_rate.proc_code_obj.mppr_rate}`] : '';
            }
            if (existing_proc_code.length > 0 || include_cpt_with_no_value == true) {
                cpt_code_list.push(proc_code_to_be_added);
            }
        });
        return cpt_code_list;
    }

    // #endregion

    // #region Delete Functionality
    delete_expected_rate_fee_definition = (expected_rate_id, expected_rate_fee_id) => {
        if (expected_rate_fee_id > 0) {
            var data = {
                expected_rate_id: expected_rate_id,
                expected_rate_fee_id: expected_rate_fee_id,
                identifier: expected_rate.identifier.is_active,
                value: false
            };
            this.update_expected_rate_definition(expected_rate_id, expected_rate.identifier.expected_rate_fee_definition, data);
        }
        else {
            let expected_rate_obj_local = this.get_expected_rate(expected_rate_id);
            expected_rate_obj_local.expected_rate_fees = [...expected_rate_obj_local.expected_rate_fees];

            expected_rate_obj_local.expected_rate_fees = expected_rate_obj_local.expected_rate_fees.filter((rate_fee_obj) => {
                if (rate_fee_obj.expected_rate_fee_id != expected_rate_fee_id)
                    return true;
            });

            let list_expected_rate_table = this.state.list_expected_rate_table;
            list_expected_rate_table.map((expected_rate_obj) => {
                if (expected_rate_obj.expected_rate_id == expected_rate_id)
                    expected_rate_obj = expected_rate_obj_local;
            });

            this.setState({ list_expected_rate_table })
        }
    }

    submitContractManagement = (lst_edited_expected_rate: any) => {
        let store = JSON.parse(localStorage.getItem(expected_rate.warningModal.localStorageKey)) || {}
        store[this.props.user_login_details.user_data.data.user_id] = this.state.renderWarningModal.donotshowagain  || false
        localStorage.setItem(expected_rate.warningModal.localStorageKey, JSON.stringify(store));
        this.setState({ renderWarningModal: undefined });
        this.service_validate_expected_rates(lst_edited_expected_rate);
    }

    renderWarningModal = () => {
        let { title, message } = this.state.renderWarningModal;
        return (
            <ResizableDraggableModal
                showModal={this.state.renderWarningModal}
                dimmer={true}
                boundWith={"window"}
                dragHandleClassName={"daragble-header"}
                autoResizeOnload={false}
                popUpPosition={{
                    width: 420,
                    height: 317,
                    minWidth: 420,
                    minHeight: 317,
                    maxWidth: 420,
                    maxHeight: 317
                }}
                enable={{
                    bottomRight: true
                }}
                className={'modal_container'}
                disableDragging={true}
            >
                <ResizableDraggableModal.Header
                    onRequestClose={() => this.setState({ renderWarningModal: undefined, is_save_in_progress: false })}
                >{title}</ResizableDraggableModal.Header>
                <ResizableDraggableModal.Content className={"daragble-content scrolling"}>
                    <ResizableDraggableModal.Description className={"daragble-description descriptionWarningModal"}>
                        {message}
                    </ResizableDraggableModal.Description>
                </ResizableDraggableModal.Content>
                <ResizableDraggableModal.Footer>
                    <Form autoComplete="off" onSubmit={() => { this.submitContractManagement(this.state.renderWarningModal.lst_edited_expected_rate) }} className='footerWarningModal'>
                        <Form.Field className='footerCheckbox'>
                            <Checkbox
                                id='donotshowagain'
                                name='donotshowagain'
                                checked={this.state.renderWarningModal.donotshowagain || false}
                                label='Do not show this again'
                                onChange={(e: any) => {
                                    const { checked } = e.target;
                                    this.setState((prevState: any) => ({ renderWarningModal: { ...prevState.renderWarningModal, donotshowagain: checked } }))
                                }}
                            />
                        </Form.Field>
                        <Button
                            type="button"
                            id="cancelButtonModal"
                            basic
                            onClick={() => this.setState({ renderWarningModal: undefined, is_save_in_progress: false })}
                            content={"Cancel"}
                        />
                        <Button
                            id="saveButtonModal"
                            className="primary"
                            type="submit"
                            content={"Continue"}
                        />
                    </Form>
                </ResizableDraggableModal.Footer>
            </ResizableDraggableModal>
        );
    }
    // #endregion

    render() {
        const { loading } = this.state;
        return (
            <div className='admin-wrapper view-edit' style={{paddingBottom: 0, paddingTop: 0}}>
                <PromptNavigation
                    is_data_changed={this.is_data_updated()}
                    go_next_location={this.is_data_updated()}
                    prompt_message={expected_rate.prompt_message.add_new}
                    hide_yes_button={true}
                    no_button_text="Continue"
                    no_button_class_name="primary"
                />

                <AlertConfirm
                    open={this.state.alert.display}
                    close={() => this.alert_box_helper(false, '', '', '', '')}
                    cancel={() => this.alert_box_helper(false, '', '', '', '')}
                    confirm={this.handle_confirm}
                    message={this.state.alert.message}
                    title={this.state.alert.title}
                    labelYes={this.state.alert.label_yes}
                />

                {this.state.show_drawer && this.get_insurance_drawer_component()}

                <Dimmer active={loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>

                <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 10, marginRight: 2 }}>
                    <Grid.Column tablet={8} computer={8}>
                        <Header id='expected_rates_hdr_id' as='h3' textAlign="left">{expected_rate.component_display_title}</Header>
                    </Grid.Column>
                    <Grid.Column tablet={8} computer={8} textAlign='right'>
                        <Button id='create_expected_rates_button_id' onClick={this.add_new_expected_rate} primary type='button'>{expected_rate.create_expected_rate_button_text}</Button>
                    </Grid.Column>
                </Grid>
                <div id='admin-scrollable-area' className='wrapper'>
                    <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container'>
                        {(!loading) && this.get_render_contents()}
                    </div>
                </div>
                {
                    (this.state.list_expected_rate_table.length > 0) &&
                    (<div className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area' id='applicationFooterSticky'>
                        <Grid textAlign='right' style={{ marginTop: 0.5, marginBottom: 0.5 }}>
                            <Grid.Column computer={16} textAlign='right' style={{paddingTop:0,paddingBottom:0}}>
                                <Button id='expected_rates_save_button_id' type='submit' primary onClick={this.submit_save_request}
                                    disabled={this.is_save_button_disabled()}
                                >
                                    {expected_rate.save_button_text}
                                </Button>
                            </Grid.Column>
                        </Grid>
                    </div>)
                }
                {this.state.renderWarningModal && this.renderWarningModal()}
            </div>
        );
    }
}

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
        features: {
            ff_sumaj_B30566_expected_rate_multiple_fee_schedules: get_lauch_darkley_key_value(state.launch_darkly, ExpectedRateMultipleFeeSchedule.sumajB30566ExpectedRateMultipleFeeSchedules),
            argosBill1627ExpectedRatesImprovements: get_lauch_darkley_key_value(state.launch_darkly, argos_bill_1627_expected_rates_improvements)
        }
    };
};
export default connect(mapStateToProps)(AddEditDeleteComponent);