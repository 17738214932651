import * as React from 'react';
import * as IdleTimer from 'react-idle-timer';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Redirect, Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { setTimeout } from 'timers';
import { decompressFromUTF16 } from '../lz-string';
import { get_lauch_darkley_key_value as get_launch_darkly_key_value } from '../utility';
import AddEditDeleteComponent from '../../admin/constants/component/expected_rate/add_edit_delete_component';
import ClaimAdjCodeCompany from '../../admin/constants/component/claim_adj_code_comp_component';
import ClaimAdjustmentReasonCodeWithCompanyOvComponent from '../../admin/constants/component/claim_adj_reason_code_with_comp_ov_component';
import ClaimAdjCodePayerOverride from '../../admin/constants/component/claim_adj_code_payer_override_component';
import ClaimAdjCodePayerOverrideSearch from '../../admin/constants/component/claim_adj_code_payer_override_search_component';
import ClaimAdjViewEditComponent from '../../admin/constants/component/claim_adj_company_view_edit_component';
import ClaimsScrubbingProcedureReplacementComponent from '../../charge/component/claims_scrubbing_procedure_replacement';
import ConstantSearchComponent from '../../admin/constants/component/constant_search_component';
import ConstantEditComponent from '../../admin/constants/component/constant_view_edit_component';
import InsuranceAddEditComponent from '../../admin/constants/component/insurances_add_edit_component';
import InsuranceSearchComponent from '../../admin/constants/component/insurances_search_component';
import LocationSearchComponent from '../../admin/constants/component/location_search_component';
import LocationAddEditComponent from '../../admin/constants/component/location_view_edit_component';
import PayerAddEditComponent from '../../admin/constants/component/payer_add_edit_component';
import PayerAlertAddEdit from '../../admin/constants/component/payer_alert_add_edit_component';
import PayerAlertSearch from '../../admin/constants/component/payer_alert_search_component';
import PayerGroupAddEditComponent from "../../admin/constants/component/payer_groups_add_edit_component";
import PayerGroupSearchComponent from "../../admin/constants/component/payer_groups_search_component";
import PayerSearchComponent from '../../admin/constants/component/payer_search_component';
import PracticesSearchComponent from '../../admin/constants/component/practices_search_component';
import PracticesViewEditComponent from '../../admin/constants/component/practices_view_edit_component';
import ProviderAddEditComponent from '../../admin/constants/component/provider_add_edit_component';
import InsuranceClassesAddEditComponent from '../../admin/constants/component/insurance_classes_add_edit_component';
import FailedChargeRuleAddEditComponent from "../../admin/constants/component/failed_charge_rule_add_edit_component";
import UserAddEditComponent from '../../user/component/user_add_edit_component';
import ProviderSearchComponent from '../../admin/constants/component/provider_search_component';
import ClaimholdsSearchComponent from '../../admin/constants/component/claimholds_search_component';
import ClaimHoldsAddEditComponent from '../../admin/constants/component/claimholds_add_edit_component';
import ChargeComponent from '../../charge/component/charge_component';
import ChargeReviewDetailComponent from '../../charge/component/charge_review_detail_component';
import ChargeReviewSummaryComponent from '../../charge/component/charge_review_summary_component';
import ProcedureCodeStatusComponent from '../../charge/component/procedure_code_status_component';
import ClaimHoldsSummaryComponent from '../../charge/component/claim_holds_summary_component';
import ReleaseClaimDelay from '../../charge/component/release_claim_delay_component';
import ViewChargeComponent from '../../charge/component/view_charge_component';
import ChargeCaseTransferComponent from '../../charge/component/charge_case_transfer_component';
import * as global_constants from '../../global_constants';
import { refresh_token, user_authentication, set_active_patient, get_master_data_by_type, get_master_data } from '../../login/action/login_action';
import PatientAddComponent from '../../patient/component/patient_add_component';
import PatientSearchComponent from '../../patient/component/patient_search_component';
import ViewPatientComponent from '../../patient/component/view_patient_component';
import PatientMergeAccountsComponent from '../../patient/component/patient_merge_accounts_component';
import PatientLetterPreviewComponent from '../../patient/component/patient_letter_preview_component';
import NoPatientSelectedComponent from '../../payment/component/no_patient_selected_payment_component';
import UnassignedPayments from '../../payment/component/unassigned_payments_component';
import EditPayments from '../../payment/component/edit_payments_component';
import DemandStatementReport from '../../reports/component/demand_statement_report_component';
import LedgerVisitClaimAdjustmentComponent from '../../reports/component/ledger_visit_claim_adjustment_component';
import PatientLedgerFullReportComponent from '../../reports/component/patient_ledger_full_report_component';
import ReportComponent from '../../reports/component/report_component';
import ChargeAuditSummaryComponent from '../../reports/component/charge_audit_summary_component';
import SummaryAndForecastReportComponent from '../../reports/component/summary_and_forecast_report_component';
import ManuallyPostedEobComponent from '../../reports/component/manually_posted_eob_component';
import UnrecognizedEobComponent from '../../reports/component/unrecognized_eob_component';
import ReportInterceptorComponent from '../../reports/component/report_interceptor_component';
import OpenZeroPaymentsDetailsComponent from '../../reports/component/open_zero_payments_details_component';
import { log_error } from '../../shared/action/shared_action';
import * as local_storage from '../../shared/local_storage_utility';
import ManageMyAccountComponent from '../../user/component/manage_my_account_component';
import { left_menu_permission } from '../action/shared_action';
import AppHeaderComponent from '../component/app_header_component';
import ComingSoonComponent from '../component/coming_soon_component';
import IdleTokenHandleComponent from '../component/idle_token_handle_component';
// Sub Layouts
import LeftMenuComponent from '../component/left_menu_component';
import R2EmbeddedPageComponent from '../component/r2_embedded_page_component';
import * as constants from './../../admin/constants/constants';
import CaseRegistrationComponent from './../../case/component/case_registration_component';
import { handle_event_on_closing_tab, setup_tabId_in_localStorage, detect_ie, detect_ie_11, remove_tab_id, delete_cookie, close_chat } from './../../shared/utility';
import CryptoService from './../../shared/crypto_service/crypto_service';
import * as session_storage from '../../shared/session_storage_utility';
import CompanyComponent from '../../company/component/company_component';
import ScheduleMonthEndSearchComponent from '../../admin/constants/component/schedule_month_end_search_component';
import ScheduleNewJob from '../../admin/constants/component/schedule_new_job_component';
import AccountRollUpComponent from '../../admin/constants/component/account_rollup_component';
import UserSearchComponent from '../../admin/constants/component/users_search_component';
import UpdatedChargesComponent from '../../charge/component/updated_charges_component';
import BatchPayment from './../../payment/batch_payment/component/batch_payment_component';
import ChargeAuditComponent from '../../charge/component/charge_audit_component';
import ChargeAuditDetailComponent from '../../charge/component/charge_audit_detail_component';
import ClaimIndicatorComponent from '../../billing/component/claim_indicator_component';
import OpenClaimsComponent from '../../billing/component/open_claims_component';
import FailedChargeRulesComponent from '../../billing/component/failed_charge_rules_component';
import ExpectedRatesReRouteComponent from '../../admin/constants/component/expected_rate/re_route_component';
import Reset835EobComponent from '../../payment/component/reset_835_eob_component';
import ErrorCorrectionReRouteComponent from '../../payment/component/error_corrections_re_route_component';
import { throttle, ThrottleSettings } from 'lodash';
import { keep_alive, token_verify_post } from '../../sso/action/sso_action';
import LogoutComponent from '../../login/component/logout_component';
import Release835sComponent from '../../payment/component/release_835s_component';
import BatchTasksComponent from '../../admin/batch_tasks/component/batch_tasks_component';
import BatchTaskDetailComponent from '../../admin/batch_tasks/component/batch_task_detail_component';
import ProgramDetailComponent from '../../admin/batch_tasks/component/program_details_component';
import BatchTaskRunDetailComponent from '../../admin/batch_tasks/component/batch_task_run_detail_component';
import BatchTaskLogDetailComponent from '../../admin/batch_tasks/component/batch_log_details_component';
import PatientStatementsComponent from '../../billing/component/patient_statements_component';
import Report277Component from '../../billing/component/e_277_report_component';
import BatchBillingComponent from '../../billing/component/batch_billing/batch_billing_component';
import BatchBillingClaimDetailComponent from '../../billing/component/batch_billing/claim_detail_component';
import BatchBillingClaimsComponent from '../../billing/component/batch_billing/batch_billing_claims_component';
import ReferringPhysicianStatusComponent from '../../admin/constants/component/referring_physician_status_component';
import ManageConstants from '../../admin/constants/component/manage_constants_component';
import DemandClaimsComponent from '../../billing/component/demand_claims_component';
import BatchUpdatesComponent from '../../billing/component/batch_updates/batch_updates_component';
import StatusCodeSearchComponent from '../../admin/constants/component/status_code_search_component';
import StatusCodeAddEditComponent from '../../admin/constants/component/status_code_add_edit_component';
import PayerRulesSearchComponent from '../../admin/constants/component/payer_rules_search_component';
import PayerRulesReRouteComponent from '../../admin/constants/component/payer_rules_re_route_component';
import PayerRulesAddEditComponent from '../../admin/constants/component/payer_rules_add_edit_component';
import NotFoundPageComponent from '../component/404_page_component';
import DashboardComponent from '../../dashboard/component/dashboard_component';
import BatchBillingCreateClaim from '../../billing/component/batch_billing/create_claim_component';
import ChargeEditRuleComponent from '../../charge/component/charge_edit_rules_components';
import BulkPaymentUpdateComponent from '../../payment/bulk_payment_update/component/bulk_payment_update_component';
import OverridesComponent from '../../admin/constants/component/claim_override/overrides_component';
import ErrorExclusions835Component from '../../admin/constants/component/835_error_exclusions_component';
import ReactivateComponent from '../../charge/component/reactivate_component';


import LaunchDarkley from '../launch_darkley/launch_darkley';
import ErrorCorrectionDetailsComponent from '../../payment/component/error_correction_details_component';
import RolesAndPermissionsComponent from '../../admin/constants/component/roles_permissions';
import UserBulkUploadComponent from '../../admin/constants/component/user_bulk_upload_component';
import PaymentAdjustmentSummaryComponent from '../../reports/component/payment_adjustment_summary_component';
import PaymentAdjustmentSummaryByPosComponent from '../../reports/component/pos_payment_adjustment_summary_component';
import FormLettersSearchComponent from '../../admin/constants/component/form_letters_search_component';
import ReleasePatientPayment from '../../payment/component/release_patient_payment_component';
import { MasterData } from '../../login/login_constants';
import ExpectedRatesReport from '../../reports/component/expected_rates_report';

import Release835sDetailComponent  from '../../payment/component/release_835s_detail_component';
import Release835sReRouteComponent from '../../payment/component/release_835s_reroute_component';
import Upload835Component from '../../payment/component/upload_835_component';
import FtpManagerComponent from '../../admin/dashboard/component/ftp_manager_component';
import FtpSchedulerComponent from '../../admin/dashboard/component/ftp_scheduler_component';
import DeleteAccountNotesComponent from '../../admin/dashboard/component/delete_account_notes_component';
import { magnus_plat_5681_isolate_auth0 } from '../../feature_flip_constant';
import ConnectionDetailComponent from '../../admin/dashboard/component/connection_detail_component';
import ScheduledRunDetailComponent from '../../admin/dashboard/component/scheduled_run_detail_component';
import RemoveMove835sComponent from '../../admin/dashboard/component/remove_move_835s';

/* 1) Create the main page of the App. 
   2) AppHeaderComponent is the application header
   3) LeftMenuComponent is the static component. Which will be present through the application
   4) Main has all the dynamically loaded components
   5) Check if the application is in idle/active mode
  */

class PrimaryLayoutComponent extends React.Component<any, any> {
    [x: string]: any;
    _is_mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            menuToggle: true,
            show_token_popup: false
        };
        this.idleTimer = null;
        this.onActive = this._onActive.bind(this);
        this.onIdle = this._onIdle.bind(this);
    }

    async UNSAFE_componentWillMount() {
        //Clear local storage when new build published
        if (local_storage.get('version') == undefined || local_storage.get('version') != global_constants.build_version) {
            local_storage.clear_all();
        }

        setup_tabId_in_localStorage();

        if (this.check_auth_data() || (this.props.location && this.props.location.pathname == '/')) {
            this.navigate_to_logout();
        } else {
            await CryptoService.get_encryption_key();

            // setTimeout is used to update the token after refresh_time expires
            this.interval = window.setTimeout(() => {
                this.refresh_token();
            }, global_constants.constants.check_token_expire_interval);

            // sso verify token
            //TODO: When Feature Flip Deprecation
            //The logic here is if the FF is on pass the condition (do nothing)
            //but when FF is off then do the logic.
            if (!this.props.magnusPlat5681IsolateAuth0) {
                if (sessionStorage['sso_user_login']) {
                    this.sso_interval = window.setTimeout(() => {
                        this.sso_verify_token();
                    }, global_constants.constants.check_sso_token_verify_time);
                }
            }

            // Get left menu data
            if (this.props.shared_details.left_menu_data == undefined) {
                this.get_menu_data();
            }
        }

        // to communicate between multiple tabs this is used
        this.listen_event_across_tabs();

        // listen if tab is closed
        handle_event_on_closing_tab();
        const wait_for_master_data_update = local_storage.get("wait_for_master_data_update");
        if (wait_for_master_data_update && (wait_for_master_data_update == true || wait_for_master_data_update == "true")) {
            await this.get_master_Data();
        }
        if (window.location.pathname == '/patient_search' || window.location.pathname == '/dashboard') {
            LaunchDarkley.update(
                () => {
                    const report_drillDown = local_storage.get('report_navigation');
                    if (report_drillDown) {
                        this.open_report_drillDown_after_company_change(true);
                    } else {
                        let navigate_to = '/patient_search';
                        if (this.props.rsiB29536Dashboard) {
                            navigate_to = '/dashboard';
                        }
                        this.props.history.push(`${navigate_to}`);
                    }
                }
            );
        }
    }
    /**
     * Purpose of this function is to open a broadcast channel
     * between multiple tabs of same application, whenever one tab want to talk to other
     * this could be acheved by this function.
     * */
    listen_event_across_tabs = () => {
        let _this = this;
        window.onstorage = (e: any) => {
            // listening event => closeOtherWindow
            // to logout the tab
            if ((e.key == ('closeOtherWindow' + session_storage.get_raw("sessionId"))) ||
                (e.key == ('auth_data' + session_storage.get_raw("sessionId"))) ||
                (e.key == ('prompt_for_idle_timeout' + session_storage.get_raw("sessionId")))
            ) {

                let isInternetExplorer11 = detect_ie_11();
                let isInternetExplorer = detect_ie();
                let is_company_changed = false;
                let auth_data = local_storage.get('auth_data');
                let prompt_for_idle_timeout = local_storage.get('prompt_for_idle_timeout');
                const isIPad = navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i);

                if (e.key == ('auth_data' + session_storage.get_raw("sessionId"))) {
                    if (e.oldValue != "") {
                        const old_auth_data = JSON.parse(decompressFromUTF16(e.oldValue));
                        /*
                         * Checking if the company is changed or not
                         * */
                        if (auth_data && old_auth_data != null && auth_data.change_company && (auth_data.change_company == true || auth_data.change_company == "true") &&
                            auth_data.token_details.access_token != old_auth_data.token_details.access_token) {
                            // will perfrom action on the basis of this flag
                            is_company_changed = true;

                            this.handleBehaviourOnChangeCompany();
                        }
                    }

                    /**
                     * This is invoked in the case of logout when we
                     * clear the auth data from the local storage
                     * */
                    if ((auth_data == null || auth_data == undefined) && !isInternetExplorer11) {
                        // first go the login page
                        // window.open('login', '_self', 'main');
                        const isMainWindow = sessionStorage["is_main_window"];
                        const sessionId = sessionStorage["sessionId"];
                        const tabId = sessionStorage["tabId"];
                        let sso_user = sessionStorage['sso_user_login'];
                        window.sessionStorage.clear();
                        if (sso_user) {
                            sessionStorage['sso_user_login'] = true;
                        }
                        sessionStorage["is_main_window"] = isMainWindow;
                        sessionStorage["sessionId"] = sessionId;
                        sessionStorage["tabId"] = tabId;

                        // the special case of IE > 12
                        // where event doesn't call self tab
                        // in the case of logout, which is done from the caller, 
                        // makes its sibling browsers close
                        if (isInternetExplorer) {
                            remove_tab_id();
                            setTimeout(function () {
                                window.close();
                            }, 10);
                            return;
                        } else {
                            // catch event for browser - chrome/firefox/safari + also mac + ipad
                            // if it is parent window then close all the sibling tabs
                            if (sessionStorage["is_main_window"] == true || sessionStorage["is_main_window"] == "true") {
                                localStorage.removeItem('closeOtherWindow' + session_storage.get_raw("sessionId"));
                                localStorage.setItem('closeOtherWindow' + session_storage.get_raw("sessionId"), session_storage.get_raw("tabId"));
                            } else {
                                if (local_storage.keyCountUsingKeyNameMatch("tabId") > 1) {
                                    // handle a different behaviour of ipad
                                    if (isIPad) {
                                        remove_tab_id();
                                    }
                                    // close the window
                                    window.close();
                                }
                            }
                        }
                    }
                }

                /*
                 * This closes all the windows 
                 * */
                if ((e.key == ('closeOtherWindow' + session_storage.get_raw("sessionId"))) && !isInternetExplorer11) {
                    // handle a different behaviour of ipad 
                    if (isIPad) {
                        remove_tab_id();
                    }
                    if (!isInternetExplorer) {
                        // close the tab
                        window.close();
                    }
                }

                /*
               * Idle Tab Listen
               * */
                if ((e.key == ('prompt_for_idle_timeout' + session_storage.get_raw("sessionId")))) {
                    if (prompt_for_idle_timeout == "true" || prompt_for_idle_timeout == true) {
                        if (_this._is_mounted) {
                            // open the popup
                            _this.setState({
                                show_token_popup: true
                            });
                        }
                    } else if (prompt_for_idle_timeout == "false" || prompt_for_idle_timeout == false) {
                        if (_this._is_mounted) {
                            // close the popup
                            _this.setState({
                                show_token_popup: false
                            });
                        }
                        if (_this.idleTimer) {
                            _this.idleTimer.reset();
                        }
                    }
                }

                /*
                 * Close the sibling windows in case of IE 
                 * and ignoring self
                 * */
                if ((e.key == ('closeOtherWindow' + session_storage.get_raw("sessionId"))) &&
                    isInternetExplorer11 &&
                    //localStorage['closeOtherWindow' + session_storage.get_raw("sessionId")]
                    e.newValue != session_storage.get_raw("tabId") &&
                    localStorage['closeOtherWindow' + session_storage.get_raw("sessionId")] != session_storage.get_raw("tabId")
                ) {
                    remove_tab_id();
                    // close the tab
                    window.close();
                }

                /*
                 * If the company is changed then we will close all the windows except parent window
                 * */
                if (is_company_changed && !isInternetExplorer11) {
                    // the special case of IE > 12
                    // where event doesn't call itself
                    // in the case of change company, which is done from the caller, 
                    // makes its sibling browsers close
                    if (isInternetExplorer) {
                        remove_tab_id();
                        setTimeout(function () {
                            window.close();
                        }, 10);
                        return;
                    } else {
                        // for browsers safari/chrome/firefox
                        //if this is main window then close all the remaining child's
                        if (sessionStorage["is_main_window"] == true || sessionStorage["is_main_window"] == "true") {
                            localStorage.removeItem('closeOtherWindow' + session_storage.get_raw("sessionId"));
                            localStorage.setItem('closeOtherWindow' + session_storage.get_raw("sessionId"), session_storage.get_raw("tabId"));
                        } else {
                            // handle a different behaviour of ipad
                            // or IE >=12 
                            if (isIPad) {
                                remove_tab_id();
                            }
                            // closing all the sibling windows
                            window.close();
                        }
                    }

                    session_storage.set('patient_criteria', '');
                    let patient_id=local_storage.get('report_navigation_active_patient');
                    const report_search_criteria = local_storage.get('report_search_criteria')
                    if(report_search_criteria){
                        session_storage.set('report_search_criteria', report_search_criteria);
                        local_storage.remove('report_search_criteria');
                    }
                    if (patient_id) {
                        session_storage.set('active_patient', patient_id);
                        this.props.set_active_patient(patient_id)
                    } else {
                        session_storage.set('active_patient', '');
                    }
                    const report_navigation = local_storage.get('report_navigation');
                    if (report_navigation) {
                        this.props.history.push(report_navigation);
                    } else if (this.props.rsiB29536Dashboard) {
                        this.props.history.push('/dashboard');
                    } else {
                        this.props.history.push('/patient_search');
                    }
                    window.location.reload();
                }
                /*
               * If the company is changed in the case of IE 11
               * */
                if (is_company_changed && isInternetExplorer11) {
                    if (session_storage.get_raw("company_changed") && session_storage.get_raw("company_changed") == "true") {
                        sessionStorage.removeItem("company_changed")
                    } else {
                        // deleting tabId
                        const tabId = session_storage.get_raw("tabId");
                        const name = 'tabId' + tabId;
                        console.log(name);
                        delete_cookie(name);
                        console.log(document.cookie);
                        //document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                        if (sessionStorage["is_main_window"] == true || sessionStorage["is_main_window"] == "true") {
                            // because in ie11 browsers throws a prompt and ask if you want to delete or not
                        } else {
                            //removing values of tab id from session and localStorage
                            remove_tab_id();
                        }
                        const report_search_criteria = local_storage.get('report_search_criteria')
                        if(report_search_criteria){
                            session_storage.set('report_search_criteria', report_search_criteria);
                            local_storage.remove('report_search_criteria');
                        }
                        let patient_id = local_storage.get('report_navigation_active_patient');
                        session_storage.set('patient_criteria', '');
                        if (patient_id) {
                            session_storage.set('active_patient', patient_id);
                            this.props.set_active_patient(patient_id)
                        } else {
                            session_storage.set('active_patient', '');
                        }
                        setTimeout(function () {
                            // closing all the sibling windows
                            window.close();
                            const report_navigation = local_storage.get('report_navigation');
                            if (report_navigation) {
                                this.props.history.push(report_navigation);
                            } else if (this.props.rsiB29536Dashboard) {
                                this.props.history.push('/dashboard');
                            } else {
                                this.props.history.push('/patient_search');
                            }
                            window.location.reload();
                        }, 100);
                        return;

                    }
                }
            }
        };

        // main visibility API function 
        // check if current tab is active or not
        const vis = (() => {
            let stateKey,
                eventKey,
                keys = {
                    hidden: "visibilitychange",
                    webkitHidden: "webkitvisibilitychange",
                    mozHidden: "mozvisibilitychange",
                    msHidden: "msvisibilitychange"
                };
            for (stateKey in keys) {
                if (stateKey in document) {
                    eventKey = keys[stateKey];
                    break;
                }
            }
            return (c) => {
                if (c) document.addEventListener(eventKey, c);
                return !document[stateKey];
            }
        })();

        // check if current tab is active or not
        vis(() => {
            if (vis(undefined)) {
                // the setTimeout() is used due to a delay 
                // before the tab gains focus again, very important!
                this.check_token_interval = setTimeout(() => {
                    console.log('the tab gains focus', new Date());
                    this.sso_verify_token();
                    this.check_token_session_expire();
                }, global_constants.constants.window_tab_focus_timeout);
            }
            else {
                console.log('when tab looses focus or inactive', new Date());
            }
        });

        // check if browser window has focus		
        const isIE = detect_ie(),// (document.documentMode === undefined),
            isChromium = window['chrome'];
        if (!isIE && !isChromium) {
            // checks for Firefox and other  NON IE Chrome versions
            window.addEventListener("focusin", function (event) {
                setTimeout(function () {
                }, global_constants.constants.window_tab_focus_timeout);
            });
        } else {
            // checks for IE and Chromium versions
            if (window.addEventListener) {
                // bind focus event
                window.addEventListener("focus", function (event) {
                    setTimeout(function () {
                    }, global_constants.constants.window_tab_focus_timeout);
                }, false);
            }
        }
    };

    handleBehaviourOnChangeCompany = () => {
        // Removing Session Search Criteria
        session_storage.remove(`batch_update_criteria`);
        session_storage.set('patient_criteria', '');
        local_storage.remove('company_change_on_same_tab');
    }

    get_master_Data = async () => {
        local_storage.set('should_queue_request', 'true');
        // API call for refresh token

        const old_user_data = local_storage.get("auth_data");
        console.log("get_master_Data in primary, refreshing token,==auth data =>", old_user_data);
        var user_data = {... this.props.user_login_details.user_data.data };
        if (old_user_data) {
            let auth_data = { ...old_user_data };
            user_data.company_id = auth_data.company_id;
            user_data.company_name = auth_data.company_name;
            user_data.gpms_code = auth_data.gpms_code;
            user_data.session_details.session_id = auth_data.session_details.session_id;
            user_data.token_details.access_token = auth_data.token_details.access_token
        }
        await this.props.refresh_token(user_data,true);
        await this.props.get_master_data(MasterData.all, this.props.user_login_details.user_data.data.user_id, this.props.user_login_details.user_data.data.token_details.access_token, true);
        await this.props.get_master_data_by_type(MasterData.all, this.props.user_login_details.user_data.data.user_id, this.props.user_login_details.user_data.data.token_details.access_token, true);
        local_storage.remove("wait_for_master_data_update");
        this.open_report_drillDown_after_company_change()
    }

    open_report_drillDown_after_company_change = async (from_page_mount = false) => {
        const report_navigation = local_storage.get('report_navigation');
        if (report_navigation) {
            let patient_id = local_storage.get('report_navigation_active_patient');
            const report_search_criteria = local_storage.get('report_search_criteria')
            if(report_search_criteria){
                session_storage.set('report_search_criteria', report_search_criteria);
                local_storage.remove('report_search_criteria');
            }
            if (patient_id) {
                session_storage.set('active_patient', patient_id);
                this.props.set_active_patient(patient_id)
                local_storage.remove('report_navigation_active_patient')
            }
            session_storage.set('patient_criteria', '');
            local_storage.remove('report_navigation');
        }
    }

    check_token_session_expire = () => {
        let auth_data = local_storage.get('auth_data');
        if (auth_data && auth_data.token_details && auth_data.token_details.expires_time) {
            let express_time = local_storage.get('auth_data').token_details.expires_time;
            const token_expires_time = new Date(express_time).getTime();
            const current_time = new Date().getTime();
            //calculate the difference between expiration date time and current date time
            const remaining_time = token_expires_time - current_time;

            // if token expire remaining time is less then equal to 0 
            //than navigate to login while window is active after inactive

            if (remaining_time <= 0 && local_storage.get('should_queue_request') != 'true') {
                this.clear_refresh_token_interval();
                this.navigate_to_logout();
            } else {
                window.clearTimeout(this.interval);
                this.refresh_token();
            }
        } else {
            this.clear_refresh_token_interval();
            this.navigate_to_logout();               
        }
    }

    navigate_to_logout = () => {
        // logging out the user if access token is not available in the local storage.
        if (window.location.pathname != '/login') {
            close_chat();
            setTimeout(() => {
                if (this.props.magnusPlat5681IsolateAuth0) {
                    if (sessionStorage['sso_user_login']) {
                        this.props.history.push('/v2/logout');
                    } else {
                        this.props.history.push('/logout');
                    }
                } else {
                    this.props.history.push('/logout');
                }
            }, 0);
        } else {
            if (!local_storage.get("version")) {
                local_storage.set("version", global_constants.build_version);
            }
        }
    }

    sso_verify_token = async () => {
        if (sessionStorage['sso_user_login']) {
            if (this.props.magnusPlat5681IsolateAuth0) {
                if (this.idleTimer) {
                    this.idleTimer.reset();
                }
            }
            else {
                token_verify_post().then((response) => {
                    if (response && response.data && response.data.error) {
                        toastr.error('', 'Your Session is expired. You are not Authorized to access this functionality. Please re-login.');
                        this.navigate_to_logout();
                    } else {
                        if (this.idleTimer) {
                            this.idleTimer.reset();
                        }
                    }
                }).catch((error) => {
                    toastr.error('', 'Your Session is expired. You are not Authorized to access this functionality. Please re-login.');
                    this.navigate_to_logout();
                    console.log(error);
                })
            }
            // place keep alive in event listener
            keep_alive().then((response) => {
                if (response && response.data && response.data.status == 0) {
                    console.log("Fail sso  Keep Alive here verified.");
                    toastr.error('', 'Your Session is expired. You are not Authorized to access this functionality. Please re-login.');
                    this.navigate_to_logout();
                } else {
                    console.log("Success sso Keep Alive here verified.");
                }
            }).catch((error) => {
                toastr.error('', 'Your Session is expired. You are not Authorized to access this functionality. Please re-login.');
                this.navigate_to_logout();
                console.log(error);
            })
            this.sso_interval = window.setTimeout(() => {
                this.sso_verify_token();
            }, global_constants.constants.check_sso_token_verify_time);
        }
    };

    refresh_token = async () => {
        if (!local_storage.get('auth_data')) {
            this.clear_refresh_token_interval();
            this.navigate_to_logout();
            return;
        }
        let express_time = local_storage.get('auth_data').token_details.expires_time;
        if (local_storage.get("idle_tab_count") == local_storage.keyCountUsingKeyNameMatch("tabId")) {
            local_storage.set("prompt_for_idle_timeout", true);
            if (this._is_mounted) {
                this.setState({
                    show_token_popup: true
                });
            }
        }

        const token_expires_time = new Date(express_time).getTime();
        const current_time = new Date().getTime();
        //calculate the difference between expiration date time and current date time
        const remaining_time = token_expires_time - current_time;

        // Check if idle time popup is open or close AND if remaining time is less that refresh_token_left_time
        // If both the conditions are true, get new token
        if (
            !this.state.show_token_popup &&
            (remaining_time <= global_constants.constants.refresh_token_left_time && remaining_time > 0 && local_storage.get('should_queue_request') != 'true')
        ) {
            local_storage.set('should_queue_request', 'true');
            console.log("Start Primary Layout, refreshing token, user data =>", this.props.user_login_details.user_data.data, "==auth data =>", local_storage.get("auth_data"));
            // API call for refresh token
            await this.props.refresh_token(this.props.user_login_details.user_data.data);
            console.log("End Primary Layout, refreshing token, user data =>", this.props.user_login_details.user_data.data);

            if (local_storage.get('auth_data').token_details == null) {
                toastr.error('', 'Authorization Server is down. Please Re-Login to be continue.');
                this.navigate_to_logout();
                return;
            }
        }
        this.interval = window.setTimeout(() => {
            this.refresh_token();
        }, global_constants.constants.check_token_expire_interval);
    };

    // Clear Interval time on Unmount
    componentWillUnmount() {
        if(detect_ie()){
            var count = parseInt(local_storage.get("open_tab_count")) - 1;
            local_storage.set("open_tab_count", count);
        }
    
        this.clear_refresh_token_interval();
        this.clear_sso_token_interval();
        this._is_mounted = false;
    }

    clear_refresh_token_interval = () => {
        window.clearTimeout(this.interval);
        window.clearTimeout(this.check_token_interval);
    }

    clear_sso_token_interval = () => {
        window.clearTimeout(this.sso_interval);
    }

    check_auth_data = () => {
        let log_out = false;
        const auth_data = local_storage.get('auth_data');
        if (auth_data && auth_data.token_details) {
            const token = auth_data.token_details.access_token;
            if (!token) {
                log_out = true;
            }
        } else {
            log_out = true;
        }
        return log_out;
    };

    componentDidMount() {
        if(detect_ie()){
            const count = parseInt(local_storage.get("open_tab_count") || 0) + 1;
            local_storage.set("open_tab_count", count);
        }
        this._is_mounted = true;
    }

    // Error Handling in Component. API Should call
    componentDidCatch(error, info) {
        log_error(error)
    }

    //Left menu data
    get_menu_data = () => {
        this.props.menu_permission(
            this.props.user_details.user_data.data.user_id,
            this.props.user_details.user_data.data.company_id,
            this.props.user_details.user_data.data.token_details.access_token
        );
    };

    // Open/close left menu
    menuToggle = toggle => {
        if (this._is_mounted) {
            this.setState({
                menuToggle: toggle
            });
        }
    };

    update_show_token_popup = flag => {
        if (this._is_mounted) {
            this.setState({
                show_token_popup: flag
            });
        }
    };

    _onActive(e) {
        this.sso_verify_token()
        console.log('Idle Start Start Time = ' + new Date());
        console.log('time remaining', this.idleTimer ? new Date(this.idleTimer.getRemainingTime()) : '');
        local_storage.set("idle_tab_count", local_storage.get("idle_tab_count") - 1);
    }

    _onIdle(e) {
        window.clearTimeout(this.sso_interval);
        console.log('user is idle');
        console.log('last active', this.idleTimer ? new Date(this.idleTimer.getLastActiveTime()) : '');
        let count = local_storage.get("idle_tab_count");
        if (count === undefined) {
            count = 1;
        } else {
            ++count;
        }
        local_storage.set("idle_tab_count", count);
    }

    get_routes = (page_type) => {
        switch (page_type) {
            case 'CONST-SEARCH-ADD-EDIT':
                return (<Switch>
                    <Route path={`${this.props.match.path}admin/:page_name/search`} exact component={ConstantSearchComponent} />
                    <Route path={`${this.props.match.path}admin/:page_name`} exact component={ConstantEditComponent} />
                    <Route path={`${this.props.match.path}admin/:page_name/:id`} exact component={ConstantEditComponent} />
                    <Route path={`${this.props.match.path}billing/:page_name/search`} exact component={ConstantSearchComponent} />
                    <Route path={`${this.props.match.path}billing/:page_name`} exact component={ConstantEditComponent} />
                    <Route path={`${this.props.match.path}billing/:page_name/:id`} exact component={ConstantEditComponent} />
                    <Route path={`${this.props.match.path}charge/:page_name/search`} exact component={ConstantSearchComponent} />
                    <Route path={`${this.props.match.path}charge/:page_name`} exact component={ConstantEditComponent} />
                    <Route path={`${this.props.match.path}charge/:page_name/:id`} exact component={ConstantEditComponent} />
                    <Route path={`${this.props.match.path}payments/:page_name/search`} exact component={ConstantSearchComponent} />
                    <Route path={`${this.props.match.path}payments/:page_name`} exact component={ConstantEditComponent} />
                    <Route path={`${this.props.match.path}payments/:page_name/:id`} exact component={ConstantEditComponent} />
                </Switch>)
                break;
            case 'CONST-SEARCH':
                return (<Switch>
                    <Route path={`${this.props.match.path}admin/:page_name/search`} exact component={ConstantSearchComponent} />
                    <Route path={`${this.props.match.path}billing/:page_name/search`} exact component={ConstantSearchComponent} />
                    <Route path={`${this.props.match.path}charge/:page_name/search`} exact component={ConstantSearchComponent} />
                    <Route path={`${this.props.match.path}payments/:page_name/search`} exact component={ConstantSearchComponent} />
                </Switch>)
                break;
            default:
                return (<Switch>
                    {/* Patient Routes */}
                    <Route path={`${this.props.match.path}patient_search`} exact component={PatientSearchComponent} />
                    <Route path={`${this.props.match.path}patient_registration`} exact component={PatientAddComponent} />
                    <Route path={`${this.props.match.path}patient_merge_accounts`} exact component={PatientMergeAccountsComponent} />
                    <Route path={`${this.props.match.path}patient/:patient_id`} exact component={ViewPatientComponent} />
                    <Route path={`${this.props.match.path}edit_patient/:patient_id`} exact component={PatientAddComponent} />
                    <Route path={`${this.props.match.path}patient_letters/preview`} exact component={PatientLetterPreviewComponent} />
                    <Route path={`${this.props.match.path}no_patient_selected`} exact component={NoPatientSelectedComponent} />
                    {/* Patient Routes End */}
                    {/* Report Routes */}
                    <Route path={`${this.props.match.path}patient/report/ledger_full`} exact component={PatientLedgerFullReportComponent} />
                    <Route path={`${this.props.match.path}patient/report/demand_statement`} exact component={DemandStatementReport} />
                    <Route path={`${this.props.match.path}patient/report/report_interceptor`} exact component={ReportInterceptorComponent} />
                    <Route path={`${this.props.match.path}report/report_interceptor`} exact component={ReportInterceptorComponent} />
                    <Route path={`${this.props.match.path}patient/report/report_data`} exact component={ReportComponent} />
                    <Route path={`${this.props.match.path}patient/report/sub_report_data`} exact component={ReportComponent} />
                    <Route path={`${this.props.match.path}report/report_data`} exact component={ReportComponent} />
                    <Route path={`${this.props.match.path}report/sub_report_data`} exact component={ReportComponent} />
                    <Route path={`${this.props.match.path}patient/report/claim_adjustment_component`} exact component={LedgerVisitClaimAdjustmentComponent} />
                    <Route path={`${this.props.match.path}report/report_data`} exact component={ReportComponent} />
                    <Route path={`${this.props.match.path}report/sub_report_data`} exact component={ReportComponent} />
                    <Route path={`${this.props.match.path}report/report_interceptor`} exact component={ReportInterceptorComponent} />
                    <Route path={`${this.props.match.path}report/charge_audit_summary`} exact component={ChargeAuditSummaryComponent} />
                    <Route path={`${this.props.match.path}report/summary_and_forecast_report`} exact component= {SummaryAndForecastReportComponent}/>
                    <Route path={`${this.props.match.path}report/manually_posted_eob`} exact component={ManuallyPostedEobComponent} />
                    <Route path={`${this.props.match.path}report/unrecognized_eob`} exact component={UnrecognizedEobComponent} />
                    <Route path={`${this.props.match.path}denial_management/open_zero_payments/:eob_key`} exact component={OpenZeroPaymentsDetailsComponent} />
                    <Route path={`${this.props.match.path}report/payment_adjustment_summary`} exact component={PaymentAdjustmentSummaryComponent} />
                    <Route path={`${this.props.match.path}report/payment_adjustment_summary_by_pos`} exact component={PaymentAdjustmentSummaryByPosComponent} />
                    <Route path={`${this.props.match.path}report/contract_management_report`} exact component={ExpectedRatesReport} />
                    {/* Report Routes End */}
                    {/* Constant Routes */}
                    <Route path={`${this.props.match.path}admin/payer_alert/search`} exact component={PayerAlertSearch} />
                    <Route path={`${this.props.match.path}admin/payer_alert/:id`} exact component={PayerAlertAddEdit} />
                    <Route path={`${this.props.match.path}admin/payer_alert`} exact component={PayerAlertAddEdit} />
                    <Route path={`${this.props.match.path}admin/insurance/search`} exact component={InsuranceSearchComponent} />
                    <Route path={`${this.props.match.path}admin/insurance`} exact component={InsuranceAddEditComponent} />
                    <Route path={`${this.props.match.path}admin/insurance/:id`} exact component={InsuranceAddEditComponent} />
                    <Route path={`${this.props.match.path}admin/practice/search`} exact component={PracticesSearchComponent} />
                    <Route path={`${this.props.match.path}admin/practice`} exact component={PracticesViewEditComponent} />
                    <Route path={`${this.props.match.path}admin/practice/:id`} exact component={PracticesViewEditComponent} />
                    <Route path={`${this.props.match.path}admin/locations/search`} exact component={LocationSearchComponent} />
                    <Route path={`${this.props.match.path}admin/locations`} exact component={LocationAddEditComponent} />
					<Route path={`${this.props.match.path}admin/locations/:id`} exact component={LocationAddEditComponent} />
					<Route path={`${this.props.match.path}admin/contract_management`} exact component={AddEditDeleteComponent} />
                    <Route path={`${this.props.match.path}charge/procedure_code_replacements`} exact component={ClaimsScrubbingProcedureReplacementComponent} />
                    <Route path={`${this.props.match.path}payments/claim_adj_code_company/search`} exact component={ClaimAdjCodeCompany} />
                    <Route path={`${this.props.match.path}payments/claim_adj_code_company/:id`} exact component={ClaimAdjViewEditComponent} />
                    <Route path={`${this.props.match.path}payments/claim_adj_code_company`} exact component={ClaimAdjViewEditComponent} />

                    <Route path={`${this.props.match.path}payments/claim_adj_reason_code_with_comp_ov_component/:id`} exact component={ClaimAdjustmentReasonCodeWithCompanyOvComponent} />
                    <Route path={`${this.props.match.path}payments/claim_adj_reason_code_with_comp_ov_component`} exact component={ClaimAdjustmentReasonCodeWithCompanyOvComponent} />


                    <Route path={`${this.props.match.path}payments/claim_adj_code_pay_ov/search`} exact component={ClaimAdjCodePayerOverrideSearch} />
                    <Route path={`${this.props.match.path}payments/claim_adj_code_pay_ov`} exact component={ClaimAdjCodePayerOverride} />
                    <Route path={`${this.props.match.path}payments/claim_adj_code_pay_ov/:id`} exact component={ClaimAdjCodePayerOverride} />
                    <Route path={`${this.props.match.path}admin/provider/search`} exact component={ProviderSearchComponent} />
                    <Route path={`${this.props.match.path}admin/claim_holds/search`} exact component={ClaimholdsSearchComponent} />
                    <Route path={`${this.props.match.path}admin/provider`} exact component={ProviderAddEditComponent} />
                    <Route path={`${this.props.match.path}admin/provider/:id`} exact component={ProviderAddEditComponent} />
                    <Route path={`${this.props.match.path}billing/provider/search`} exact component={ProviderSearchComponent} />
                    <Route path={`${this.props.match.path}billing/claim_holds/search`} exact component={ClaimholdsSearchComponent} />
                    <Route path={`${this.props.match.path}billing/location_claim_holds/:id`} exact component={ClaimHoldsAddEditComponent} />
                    <Route path={`${this.props.match.path}billing/provider`} exact component={ProviderAddEditComponent} />
                    <Route path={`${this.props.match.path}billing/provider/:id`} exact component={ProviderAddEditComponent} />
                    <Route path={`${this.props.match.path}admin/insurance_class`} exact component={InsuranceClassesAddEditComponent} />
                    <Route path={`${this.props.match.path}admin/insurance_class/:id`} exact component={InsuranceClassesAddEditComponent} />
                    <Route path={`${this.props.match.path}billing/payer_groups/search`} exact component={PayerGroupSearchComponent} />
                    <Route path={`${this.props.match.path}billing/payer_groups`} exact component={PayerGroupAddEditComponent} />
                    <Route path={`${this.props.match.path}billing/payer_groups/:id`} exact component={PayerGroupAddEditComponent} />
                    <Route path={`${this.props.match.path}billing/payer/search`} exact component={PayerSearchComponent} />
                    <Route path={`${this.props.match.path}billing/payer`} exact component={PayerAddEditComponent} />
                    <Route path={`${this.props.match.path}billing/payer/:id`} exact component={PayerAddEditComponent} />
                    <Route path={`${this.props.match.path}billing/patient_statements`} exact component={PatientStatementsComponent} />
                    <Route path={`${this.props.match.path}billing/failed_charge_rules/search`} exact component={FailedChargeRulesComponent} />
                    <Route path={`${this.props.match.path}billing/failed_charge_rules`} exact component={FailedChargeRuleAddEditComponent} />
                    <Route path={`${this.props.match.path}billing/failed_charge_rules/:id`} exact component={FailedChargeRuleAddEditComponent} />
                    <Route path={`${this.props.match.path}billing/status_code/search`} exact component={StatusCodeSearchComponent} />
                    <Route path={`${this.props.match.path}billing/status_code`} exact component={StatusCodeAddEditComponent} />
                    <Route path={`${this.props.match.path}billing/status_code/:id`} exact component={StatusCodeAddEditComponent} />
                    <Route path={`${this.props.match.path}billing/batch_billing/search`} exact component={BatchBillingComponent} />
                    <Route path={`${this.props.match.path}billing/batch_billing`} exact component={BatchBillingCreateClaim} />
                    <Route path={`${this.props.match.path}billing/batch_billing/:id`} exact component={BatchBillingClaimDetailComponent} />                    
                    <Route path={`${this.props.match.path}billing/batch_billing/:id/claims`} exact component={BatchBillingClaimsComponent} />
                    <Route path={`${this.props.match.path}billing/charge_edit_rules`} exact component={ChargeEditRuleComponent} />
					<Route path={`${this.props.match.path}admin/form_letters/search`} exact component={FormLettersSearchComponent} />
					<Route path={`${this.props.match.path}admin/835_error_exclusions`} exact component={ErrorExclusions835Component} />
                    {/* Constant Routes End */}
                    {/* Admin Dashboard Routes */}
                    <Route path={`${this.props.match.path}admin/dashboard/ftp_manager`} exact component={FtpManagerComponent} />
                    <Route path={`${this.props.match.path}admin/dashboard/ftp_scheduler`} exact component={FtpSchedulerComponent} />
                    <Route path={`${this.props.match.path}admin/dashboard/ftp_scheduler/scheduled_run_details`} exact component={ScheduledRunDetailComponent} />
                    <Route path={`${this.props.match.path}admin/dashboard/ftp_scheduler/scheduled_run_details/:id`} exact component={ScheduledRunDetailComponent} />
                    <Route path={`${this.props.match.path}admin/dashboard/delete_account_notes`} exact component={DeleteAccountNotesComponent} />
                    <Route path={`${this.props.match.path}admin/dashboard/remove_move_835s`} exact component={RemoveMove835sComponent} />
                    {/* FTP Manager */}
                    <Route path={`${this.props.match.path}admin/dashboard/ftp_manager/connection_detail`} exact component={ConnectionDetailComponent} />
                    <Route path={`${this.props.match.path}admin/dashboard/ftp_manager/connection_detail/:id`} exact component={ConnectionDetailComponent} />
                    {/* FTP Manager */}
                    {/* Admin Dashboard Routes End */}
                    {/* Charge Routes */}
                    <Route path={`${this.props.match.path}charge/add_charge`} exact component={ChargeComponent} />
                    <Route path={`${this.props.match.path}charge/edit_charge/:charge_id`} exact component={ChargeComponent} />
                    <Route path={`${this.props.match.path}charge/view_charge`} exact component={ViewChargeComponent} />
                    <Route path={`${this.props.match.path}charge/release_claim_delay`} exact component={ReleaseClaimDelay} />
                    <Route path={`${this.props.match.path}charge/charge_review`} exact component={ChargeReviewSummaryComponent} />
                    <Route path={`${this.props.match.path}charge/charge_review_detail/:id`} exact component={ChargeReviewDetailComponent} />
                    <Route path={`${this.props.match.path}charge/charge_case_transfer`} exact component={ChargeCaseTransferComponent} />
                    <Route path={`${this.props.match.path}charge/updated_charges`} exact component={UpdatedChargesComponent} />
                    <Route path={`${this.props.match.path}charge/charge_audit`} exact component={ChargeAuditComponent} />
                    <Route path={`${this.props.match.path}charge/charge_audit_detail`} exact component={ChargeAuditDetailComponent} />
                    <Route path={`${this.props.match.path}charge/procedure_code_status`} exact component={ProcedureCodeStatusComponent} />
					<Route path={`${this.props.match.path}charge/claim_holds_summary`} exact component={ClaimHoldsSummaryComponent} />
					<Route path={`${this.props.match.path}charge/reactivate`} exact component={ReactivateComponent} />
                    {/* Charge Routes End */}
                    {/* Case Routes End */}
                    <Route path={`${this.props.match.path}case`} exact component={CaseRegistrationComponent} />
                    <Route path={`${this.props.match.path}case/:case_id`} exact component={CaseRegistrationComponent} />
                    {/* Case Routes */}
                    <Route path={`${this.props.match.path}coming_soon`} exact component={ComingSoonComponent} />
                    <Route path={`${this.props.match.path}manage_account`} exact component={ManageMyAccountComponent} />
                    <Route path={`${this.props.match.path}R2`} component={R2EmbeddedPageComponent} />
                    <Route path={`${this.props.match.path}payments/unassigned_payments`} exact component={UnassignedPayments} />
                    <Route path={`${this.props.match.path}payments/edit_payments`} exact component={EditPayments} />
                    <Route path={`${this.props.match.path}admin/company`} exact component={CompanyComponent} />
                    <Route path={`${this.props.match.path}admin/company/:company_id`} exact component={CompanyComponent} />
                    <Route path={`${this.props.match.path}admin/users/search`} exact component={UserSearchComponent} />
                    <Route path={`${this.props.match.path}admin/users`} exact component={UserAddEditComponent} />
                    <Route path={`${this.props.match.path}admin/users/:id`} exact component={UserAddEditComponent} />
                    <Route path={`${this.props.match.path}payments/batch_payments`} exact component={BatchPayment} />
                    <Route path={`${this.props.match.path}payments/batch_adjustments`} exact component={BatchPayment} />
                    <Route path={`${this.props.match.path}payments/error_corrections`} exact render={(props) => (<ErrorCorrectionReRouteComponent errorCorrectionsFF={this.props.rsiB32115ErrorCorrectionsMainPageUpdate} {...props}/>)} />
                    <Route path={`${this.props.match.path}payments/error_corrections_details`} exact component={ErrorCorrectionDetailsComponent} />
                    <Route path={`${this.props.match.path}billing/claim_indicator`} exact component={ClaimIndicatorComponent} />
                    <Route path={`${this.props.match.path}billing/open_claims`} exact component={OpenClaimsComponent} />
                    <Route path={`${this.props.match.path}billing/277_report`} exact component={Report277Component} />
                    <Route path={`${this.props.match.path}logout`} exact component={LogoutComponent} />
                    {/* Schedule Month End Routes */}
                    <Route path={`${this.props.match.path}admin/month_end`} exact component={ScheduleMonthEndSearchComponent} />
                    <Route path={`${this.props.match.path}admin/schedule_new_job`} exact component={ScheduleNewJob} />
                    <Route path={`${this.props.match.path}admin/account_rollup`} exact component={AccountRollUpComponent} />
                    <Route path={`${this.props.match.path}admin/batch_tasks`} exact component={BatchTasksComponent} />
                    <Route path={`${this.props.match.path}admin/batch_tasks/:batch_task_id`} exact component={BatchTaskDetailComponent} />
                    <Route path={`${this.props.match.path}admin/batch_tasks/runs/:batch_run_id`} exact component={BatchTaskRunDetailComponent} />
                    <Route path={`${this.props.match.path}admin/batch_tasks/programs/:program_id`} exact component={ProgramDetailComponent} />
                    <Route path={`${this.props.match.path}admin/batch_tasks/logs/:log_id`} exact component={BatchTaskLogDetailComponent} />
                    <Route path={`${this.props.match.path}admin/referring_physician_status`} exact component={ReferringPhysicianStatusComponent} />
                    <Route path={`${this.props.match.path}admin/roles_permissions`} exact component={RolesAndPermissionsComponent} />
                    <Route path={`${this.props.match.path}admin/user_bulk_upload`} exact component={UserBulkUploadComponent} />
                    <Route path={`${this.props.match.path}admin/overrides`} exact component={OverridesComponent} />

                    {/* Demand Claims Routes */}
                    <Route path={`${this.props.match.path}billing/demand_claims`} exact component={DemandClaimsComponent} />
                    <Route path={`${this.props.match.path}billing/batch_updates`} exact component={BatchUpdatesComponent} />

                    {/* Schedule Month End Routes End*/}
                    {/* Payments Routes */}
                    <Route path={`${this.props.match.path}payments/reset_835`} exact component={Reset835EobComponent} />
					<Route path={`${this.props.match.path}payments/release_835s`} exact render={(props) => (<Release835sReRouteComponent release835sFF={this.props.argos_bill_1162_editable_835s} {...props}/>)} />
                    <Route path={`${this.props.match.path}payments/release_835s_detail`} exact component={Release835sDetailComponent} />
                    <Route path={`${this.props.match.path}payments/add_edit_payer_rules/search`} exact render={(props) => (<PayerRulesReRouteComponent enhancedPayerRulesFF={this.props.sumajBill636EnhancedPayerRules} {...props} />)} />
                    <Route path={`${this.props.match.path}payments/add_edit_payer_rules/`} exact render={(props) => (<PayerRulesReRouteComponent enhancedPayerRulesFF={this.props.sumajBill636EnhancedPayerRules} {...props}/>)} />
                    <Route path={`${this.props.match.path}payments/add_edit_payer_rules/add`} exact component={PayerRulesAddEditComponent} />
                    <Route path={`${this.props.match.path}payments/add_edit_payer_rules/:id`} exact component={PayerRulesAddEditComponent} />
                    <Route path={`${this.props.match.path}payments/bulk_payment_updates`} exact component={BulkPaymentUpdateComponent} />
                    <Route path={`${this.props.match.path}payments/releasePatientPayments`} exact component={ReleasePatientPayment} />
                    <Route path={`${this.props.match.path}payments/upload_835`} exact component={Upload835Component} />
                    {/* Payments Routes End*/}
                    {/* Manage Constants Routes End*/}
                    <Route path={`${this.props.match.path}admin/manage_constants`} exact component={ManageConstants} />
                    {/* Manage Constants Routes End*/}
                    {/* Dashboard Routes */}
                    <Route path={`${this.props.match.path}dashboard`} exact component={DashboardComponent} />

                    {/* Dashboard Routes End*/}
                    <Route path={`${this.props.match.path}404_page`} exact component={NotFoundPageComponent} />

                    <Redirect to='/404_page' />
                    {/* <Redirect to='/login' /> */}
                    
                    
                </Switch>)
        }
    }

    render() {
        //check if menu data is available. If so then form the page.
        if (this.props.shared_details.left_menu_data) {
            let page_list = constants.page_lists.filter((item) => {
                return (['admin', 'charge', 'payments', 'billing'].indexOf(this.props.location.pathname.split('/')[1]) > -1 && item.name == this.props.location.pathname.split('/')[2]);
            })
            let page_type = page_list.length > 0 ? page_list[0].is_page_add_edit ? 'CONST-SEARCH-ADD-EDIT' : (this.props.location.pathname.split('/')[3] == 'search' ? 'CONST-SEARCH' : '') : ''
            return (
                <div className='primary-layout'>
                    <IdleTokenHandleComponent update_show_token_popup={this.update_show_token_popup} show_token_popup={this.state.show_token_popup} />
                    <IdleTimer
                        ref={ref => {
                            this.idleTimer = ref;
                        }}
                        element={document}
                        onActive={this.onActive}
                        onIdle={this.onIdle}
                        timeout={global_constants.constants.idle_time}
                    >
                        <AppHeaderComponent />
                        <div className='main-wrapper'>
                            <LeftMenuComponent menuToggle={this.menuToggle} />
                            <main className={this.state.menuToggle === false ? 'body-wrapper toggled' : 'body-wrapper'}>
                                {this.get_routes(page_type)}
                            </main>
                        </div>
                    </IdleTimer>
                </div>
            );
        } else if (this.props.shared_details.left_menu_data == undefined) {
            return <div />;
        } else {
            return <Redirect to='/login' />;
        }
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            authentication: user_authentication,
            menu_permission: left_menu_permission,
            refresh_token: refresh_token,
            set_active_patient: set_active_patient,
            get_master_data: get_master_data,
            get_master_data_by_type: get_master_data_by_type
        },
        dispatch
    );
};

const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
        user_details: state.user_login_details,
        shared_details: state.shared_details,
        rsiB29536Dashboard: get_launch_darkly_key_value(state.launch_darkly, "rsiB29536Dashboard"),
        rsiB32115ErrorCorrectionsMainPageUpdate: get_launch_darkly_key_value(state.launch_darkly, "rsiB32115ErrorCorrectionsMainPageUpdate"),
		sumajBill636EnhancedPayerRules: get_launch_darkly_key_value(state.launch_darkly, "sumajBill636EnhancedPayerRules"),
        argos_bill_1162_editable_835s: get_launch_darkly_key_value(state.launch_darkly, "argosBill1162Editable835S"),
        magnusPlat5681IsolateAuth0: get_launch_darkly_key_value(state.launch_darkly, magnus_plat_5681_isolate_auth0)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrimaryLayoutComponent);