import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Checkbox, Form, Grid, GridColumn, Header, Table } from 'semantic-ui-react';
import * as case_constant from '../../case/case_constants';
import * as global_constants from '../../global_constants';
import { arrayMove } from '../../shared/dnd_service';
import SortableContainer from '../../shared/dnd_service/sortable_container';
import SortableElement from '../../shared/dnd_service/sortable_element';
import SortableHandle from '../../shared/dnd_service/sortable_handle';
import { add_update_case_diagnosis_code, diagnosis_code_quick_search_data, get_advance_search, get_case_diagnosis_code } from '../action/diagnosis_action';
import * as diagnosis_constant from '../diagnosis_constant';
import AutoSearchComponent from './../../shared/component/auto_search_component';
import DiagnosisAdvancedSeach from './diagnosis_advance_search_component';

export class DiagnosisComponent extends React.Component<any, any> {
    [x: string]: any;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            is_view_mode: (props.page_state == case_constant.enum_case_page_state.edit) ? true : false,
            is_dragging: props.is_dragging,
            advanc_search_data: {},
            grid_conf: {
                isPagination: true,
                gridAutoHeight: true,
                selectionType: 'single',
                paginationPageSize: diagnosis_constant.search_diagnosis_code_configuration(diagnosis_constant.diagnosis_search_header_id).pagination_size,
            },
            selected_diagnosis_code: {
                "title": '',
                "label": '',
            },
            case_diagnosis_list: [],
            check_all_dx_code: false,
            max_sequence: 0,
            is_diagnosis_update: false,
            form_submited: false,
            required_dx: false,
        }
    }


    UNSAFE_componentWillMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }

    }
    componentDidMount() {
        this._isMounted = true;
        this.get_case_diagnosis_code(this.props.case_id);
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        if (this.props.onRef) {
            this.props.onRef(undefined)
        }
    }
    componentDidUpdate(previousProps, previousState) {
        if (previousProps.page_state !== this.props.page_state) {
            if (this._isMounted) {
                this.setState({
                    is_view_mode: (this.props.page_state == case_constant.enum_case_page_state.edit) ? true : false
                });
            }
        }
        if (previousProps.is_dragging !== this.props.is_dragging) {
            if (this._isMounted) {
                this.setState({
                    is_dragging: this.props.is_dragging
                });
            }
        }
        if (previousProps.case_id !== this.props.case_id) {
            this.get_case_diagnosis_code(this.props.case_id);
        }
    }

    get_case_diagnosis_code = async (case_id) => {
        if (!(isNaN(Number(case_id)) || Number(case_id) >= global_constants.maximum_value.integer)) {
            if (case_id && case_id > 0) {
                var case_diagnosis = await get_case_diagnosis_code(case_id, this.props.user_login_details.user_data.data.token_details.access_token);
                var data = (case_diagnosis.data == null || case_diagnosis.data.data == null) ? [] : case_diagnosis.data.data;

                var max_dx_sequence = 0;
                if (data.length > 0) {
                    var active_dx = data.filter(function (row, index) {
                        return (row.is_active == true);
                    });
                    if (active_dx.length > 0) {
                        ////check if any duplicate sequence update sequence no
                        //var valueArr = active_dx.map(function (item) { return item.sequence });
                        //var isDuplicate = valueArr.some(function (item, idx) {
                        //    return valueArr.indexOf(item) != idx
                        //});
                        //if (isDuplicate) {
                        //    active_dx.map((item, index) => {
                        //        item.sequence = index + 1;
                        //    });
                        //}
                        active_dx.map((item, index) => {
                            item.sequence = index + 1;
                        });

                        var last_element = active_dx.length - 1;
                        max_dx_sequence = active_dx[last_element].sequence;
                    }
                }
                if (this._isMounted) {
                    this.setState({
                        case_diagnosis_list: data,
                        max_sequence: max_dx_sequence,
                        is_diagnosis_update: false,
                        required_dx: false,
                        check_all_dx_code: false,
                        selected_diagnosis_code: {
                            "title": '',
                            "label": '',
                        }
                    }, () => {
                        if (this.props.update_dx_List) {
                            this.props.update_dx_List();
                        }
                        });
                   
                }
            }
        }
    }

    on_diagnosis_advance_search_grid_row_selection = (item) => {
        var duplicate_count = this.check_if_diagnosis_code_is_unique(this.state.case_diagnosis_list, item);
        if (duplicate_count > 0) {
            toastr.error('', diagnosis_constant.error_messages.dulicate_dx_code);
            return true;
        }

        this.add_dx_code(item);
    }

    on_diagnosis_code_advance_search = async (params) => {

        params = params ? params : {};
        params["Page_size"] = global_constants.constants.PAGE_SIZE;

        const search_data = await get_advance_search(params, this.props.user_login_details.user_data.data.token_details.access_token).then(res => res.data);
        const search_result = (search_data.data !== null && search_data.data.result !== null) ? search_data.data.result : [];
        var grid_data = {
            ...this.state.grid_conf,
            rows: search_result,
            column: diagnosis_constant.search_diagnosis_code_configuration(diagnosis_constant.diagnosis_search_header_id).column_defs,
            paginationPageSize: diagnosis_constant.search_diagnosis_code_configuration(diagnosis_constant.diagnosis_search_header_id).pagination_size,
        };
        if (this._isMounted) {
            this.setState({
                advanc_search_data: grid_data
            });
        }
    }

    // get the quick search location apis data
    get_diagnosis_code_quick_search_data_list = async (search_keyword) => {
        return await diagnosis_code_quick_search_data(search_keyword, this.props.user_login_details.user_data.data.token_details.access_token);
    }

    // preapare the suggestion list with search result
    prepare_suggestion = (data) => {
        let formattedList = [];
        var data_length = data.data.length;
        if (data && data_length) {
            for (let i = 0; i < data_length; i++) {
                //TODO:
                let item = data.data[i];
                let name = item.code + ' - ' + item.description

                item = { ...item, title: name, label: name, is_active: "true" };
                formattedList.push(item);
            }
        }
        return formattedList;
    }


    add_dx_code = (item) => {
        if (item && (item.title != "" || item.label != "")) {
            var data = Object.assign([], this.state.case_diagnosis_list);
            item = {
                ...item,
                case_id: this.props.case_id,
                sequence: this.state.max_sequence + 1,
                source_type: this.props.source_type,
                is_active: true,
                is_selected: false
            };
            var insert_dx = true;
            for (let row of data) {
                row.is_selected = false;
                if (row.code == item.code && row.diagnosis_code_id == item.diagnosis_code_id) {
                    row.is_active = true;
                    insert_dx = false;
                }
            }
            if (insert_dx) {
                data.push(item);
            }

            var max_dx_sequence = 0;
            if (data.length > 0) {
                var active_dx = data.filter(function (row, index) {
                    return (row.is_active == true);
                });
                if (active_dx.length > 0) {
                    var last_element = active_dx.length - 1;
                    max_dx_sequence = active_dx[last_element].sequence;
                }

            }
            if (this._isMounted) {
                this.setState({
                    case_diagnosis_list: data,
                    max_sequence: max_dx_sequence,
                    check_all_dx_code: false,
                    is_diagnosis_update: true,
                    required_dx: false,
                });
            }
            let _self = this;
            setTimeout(function () {
                _self.clear_dx_code_quick_search();
                if (_self.props.update_dx_List) {
                    _self.props.update_dx_List();
                }
            }, 0);
        }
    }

    // select the item on click suggested items
    on_item_selection = (item) => {
        var duplicate_count = this.check_if_diagnosis_code_is_unique(this.state.case_diagnosis_list, item);
        if (duplicate_count > 0) {
            //this.setState({
            //    selected_diagnosis_code: {
            //        "title": '',
            //        "label": '',
            //    },
            //});
            let _self = this;
            setTimeout(function () { _self.clear_dx_code_quick_search() }, 0);
            toastr.error('', diagnosis_constant.error_messages.dulicate_dx_code);
            return;
        }

        this.add_dx_code(item);
    }

    // check if selected dx already in list
    check_if_diagnosis_code_is_unique = (dx_list, dx_row) => {
        var duplicate_count = 0;
        if (dx_row) {
            for (var i = 0; i < dx_list.length; i++) {
                if (dx_list[i].code == dx_row.code) {
                    if (dx_list[i].is_active == true) {
                        duplicate_count++;
                    } else {
                        if (dx_list[i].isActive == false && (dx_list[i].case_dx_id == undefined || dx_list[i].case_dx_id == null || dx_list[i].case_dx_id == 0)) {
                            dx_list.splice(i, 1);
                        }
                    }
                }
            }
        }
        return duplicate_count;
    }

    //Auto search for dx code
    render_suggestion_result = (props) => {
        return (
            <div key={props.title} tabIndex={0} className="item_auto_search">
                <div key={props.title} className="fs_13">
                    <span>{props.label}</span>
                </div>
            </div>

        )
    }


    check_all_dx_code_change = (e, value) => {
        var data = Object.assign([], this.state.case_diagnosis_list);
        var data_length = data.length;
        if (data_length > 0) {
            for (let i = 0; i < data_length; i++) {
                var row = data[i];
                row.is_selected = value.checked
            }
            if (this._isMounted) {
                this.setState({
                    case_diagnosis_list: data,
                    check_all_dx_code: value.checked
                });
            }
        }
    }

    check_dx_code_row = (e, value, rowData, index) => {
        var data = Object.assign([], this.state.case_diagnosis_list);
        data[index].is_selected = value.checked;
        var allrowChecked = data.filter(function (row, index) {
            return (row.is_selected == false || !row.is_selected);
        });
        var allChecked = false;
        if (allrowChecked.length == 0) {
            allChecked = true;
        } else {
            allChecked = false;
        }
        if (this._isMounted) {
            this.setState({
                case_diagnosis_list: data,
                check_all_dx_code: allChecked
            });
        }
    }

    clear_dx_code_quick_search = () => {
        if (this._isMounted) {
            this.setState({
                selected_diagnosis_code: {
                    "title": '',
                    "label": '',
                },
            });
        }
    }

    remove_dx_code = () => {
        var data = Object.assign([], this.state.case_diagnosis_list);
        var selected_date_for_remove = data.filter(function (row, index) {
            return (row.is_selected == true && row.is_active == true)
        })[0];
        if (selected_date_for_remove) {
            for (let row of data) {
                if (row.is_selected == true) { row.is_active = false }
            }
            //upating all dx sequence on deleting any dx code
            var counter = 1;
            for (let dx of data) {
                if (dx.is_active == true) {
                    dx.sequence = counter;
                    counter++;
                }
            }
            if (this._isMounted) {
                this.setState({
                    case_diagnosis_list: data,
                    check_all_dx_code: false,
                    max_sequence: counter - 1,
                    is_diagnosis_update: true
                });
            }
            setTimeout(() => {
                if (this.props.update_dx_List) {
                    this.props.update_dx_List();
                }
            }, 0)
            //if (this.props.case_id > 0) {
            //    this.validate_dx_code(true);
            //}
        }
        else {
            toastr.warning('', diagnosis_constant.error_messages.dc_code_checkbox_select);
        }
    }

    add_update_diagnosis_code = async (case_id) => {
        await add_update_case_diagnosis_code(this.props.user_login_details.user_data.data.token_details.access_token, this.state.case_diagnosis_list, case_id)
            .then(function (response) {

            },
                function (error) {
                })
    }

    get_update_diagnosis_code_list = () => {
        return {
            diagnosis_list: this.state.case_diagnosis_list,
            is_diagnosis_update: this.state.is_diagnosis_update

        };
    }

    //show_delete_button = () => {
    //    var show_delete = false;
    //    var active_data = this.state.case_diagnosis_list.filter(function (row, index) {
    //        return row.is_active == true
    //    });
    //    if (active_data && active_data.length>1) {
    //        show_delete = true;
    //    }
    //    return show_delete;        
    //}

    validate_dx_code = (form_submited) => {
        var active_data = this.state.case_diagnosis_list.filter(function (row, index) {
            return row.is_active == true
        });
        if (!(this.props.case_data.is_incomplete_rf3)) {
            if (active_data.length == 0) {
                if (this._isMounted) {
                    this.setState({
                        form_submited: form_submited,
                        required_dx: true,
                    });
                }
                return true;
            }
        }
        if (this._isMounted) {
            this.setState({
                form_submited: false,
                required_dx: false,
            });
        }
        return false;
    }

    render() {

        /**
         * This High Order Component makes table cell element to be draggable.
         * */
        const RowDragHandle = SortableHandle(({ row, is_view }: { row: any, is_view: boolean }) => {
            return (
                < Table.Cell className={is_view ? '' : 'grabbable'}>
                    {row.code}
                </Table.Cell >

            )
        });

        const SequenceDragHandle = SortableHandle(({ row, is_view, idx }: { row: any, is_view: boolean, idx: number, }) => {
            return (
                < Table.Cell className={is_view ? '' : 'grabbable'}>
                    {row.sequence}
                </Table.Cell >

            )
        });
        /**
        * This High Order Component makes table cell element to be draggable.
        * */
        const DescriptionDragHandle = SortableHandle(({ row, is_view }: { row: any, is_view: boolean }) => {
            return (
                <Table.Cell className={is_view ? '' : 'grabbable'}>
                    {row.description}
                </Table.Cell>
            )
        });

        /**
        * This  High Order Component will move when dragged by handler .
        * */
        var CaseSortableRow = SortableElement(({ row, idx, is_view, is_dragging, show_sequence }: { row: any, idx: number, is_view: boolean, is_dragging: boolean, show_sequence: boolean }) => {
            return (

                <Table.Row key={idx}>
                    <Table.Cell className={is_view ? 'cursor-not-allowed' : ''}><Checkbox disabled={is_view} checked={row.is_selected} onChange={(e, value) => this.check_dx_code_row(e, value, row, idx)} /></Table.Cell>
                    {show_sequence && <SequenceDragHandle row={row} idx={idx} is_view={!is_dragging} />}
                    <RowDragHandle row={row} is_view={!is_dragging} />

                    <DescriptionDragHandle row={row} is_view={!is_dragging} />
                </Table.Row>
            )
        });

        /**
        * This  High Order Component is the dropzone of drag and drop.
        * */
        var CasesSortableTable = SortableContainer(({ case_diagnosis_list, is_view, is_dragging, show_sequence }: { case_diagnosis_list: string[], is_view: boolean, is_dragging: boolean, show_sequence: boolean }) => {
            if (case_diagnosis_list.length > 0) {

                return (
                    <Table.Body>
                        {
                            case_diagnosis_list.map(function (row: any, index: number) {
                                if (row.is_active) {
                                    return (
                                        <CaseSortableRow show_sequence={show_sequence} is_dragging={is_dragging} disabled={!is_dragging} key={index} row={row} index={index} idx={index} is_view={is_view} />
                                    )
                                }
                            })
                        }
                    </Table.Body>
                );
            }
        });

        /**
        * This function is called when drag element is dropped 
        * */
        var onSortEnd = ({ oldIndex, newIndex }) => {

            var sorted_data: any = arrayMove(this.state.case_diagnosis_list, oldIndex, newIndex)

            // reordering the sequence
            var counter = 1;
            for (let dx of sorted_data) {
                if (dx.is_active == true) {
                    dx.sequence = counter;
                    counter++;
                }
            }
            if (this._isMounted) {
                this.setState({
                    case_diagnosis_list: sorted_data,
                    is_diagnosis_update: true,
                });
            }
        };

        var format_dx_row = () => {
            if (this.state.case_diagnosis_list.length > 0) {
                var is_view = this.state.is_view_mode;
                return (
                    <CasesSortableTable lockAxis={'y'} lockToContainerEdges={true} case_diagnosis_list={this.state.case_diagnosis_list} is_view={is_view} is_dragging={this.state.is_dragging} show_sequence={this.props.show_sequence} onSortEnd={onSortEnd} useDragHandle={true} helperClass={'dragged_element'} translatableSelector="td" />
                )
            }
        }

        return (
            <div id="case_diagnosis_section">
                <br></br>
                <Header as='h4' className="small">
                    Diagnosis
                         </Header>
                <Grid>
                    <GridColumn tablet={16} computer={16} className="padd-r-0">
                        <Form.Field className="advance-quick-search">
                            <label><span className="req-alert_normal">At least one ICD-10 code is required.</span></label>
                            <DiagnosisAdvancedSeach onGridRowSelection={this.on_diagnosis_advance_search_grid_row_selection} gridConfig={this.state.advanc_search_data}
                                controlId={global_constants.constants.advanced_control_type.diagnosiscode_search} onSearch={this.on_diagnosis_code_advance_search}
                                disabled_element={this.state.is_view_mode} keyPress={this.on_diagnosis_code_advance_search} clear_dx_code_quick_search={this.clear_dx_code_quick_search} />
                            <AutoSearchComponent
                                default_value={this.state.selected_diagnosis_code}
                                errorMessage={"No Record Found !"} prepareRenderList={this.render_suggestion_result} getList={this.get_diagnosis_code_quick_search_data_list}
                                prepareDataList={(data) => this.prepare_suggestion(data)} selectresult={(item) => this.on_item_selection(item)} is_focus={false}
                                is_disabled={this.state.is_view_mode} clear_search={() => this.clear_dx_code_quick_search()}
                                errorClass={this.state.required_dx && this.state.form_submited ? 'search-error-thin' : ''}
                            />
                        </Form.Field>
                    </GridColumn>
                   
                    {
                        //!this.props.show_sequence &&
                        //<Grid.Column tablet={16} computer={8} textAlign="right" className="mar-t-15 padd-r-0">
                            
                        //    <Button disabled={this.state.is_view_mode} type="button" basic onClick={this.remove_dx_code}>Delete</Button>
                                
                        //</Grid.Column>
                    }
                    <GridColumn computer={16} className="padd-r-0">
                        <div className="customtable">
                            <div>
                                <Table fixed id="patient_medicare_cap_table">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell className={this.state.is_view_mode ? 'cursor-not-allowed' : ''}>
                                                <Checkbox checked={this.state.check_all_dx_code} onChange={this.check_all_dx_code_change} disabled={this.state.is_view_mode} />
                                            </Table.HeaderCell>
                                            {this.props.show_sequence && <Table.HeaderCell width='one' className="width-125" >No.</Table.HeaderCell>}
                                            <Table.HeaderCell width='three' className="width-125" >ICD-10 Code</Table.HeaderCell>
                                            <Table.HeaderCell>Description</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    {format_dx_row()}

                                </Table>
                            </div>
                        </div>
                    </GridColumn>
                    {
                        <Grid.Column tablet={16} computer={16} textAlign="right" className="padd-r-0">

                            <Button disabled={this.state.is_view_mode} type="button" basic className="mar-r-0" onClick={this.remove_dx_code}>Delete</Button>

                        </Grid.Column>
                    }
                </Grid>


            </div>
        )
    }
}



const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DiagnosisComponent))