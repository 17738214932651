import * as global_constants from './../../global_constants';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request';
//To get the subscriber information

export const get_subscriber = (insurance_request, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.subscriber_url.subscriber}${global_constants.end_points.subscriber_url.subscriber_info}`;

    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(insurance_request)
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(requestConfig)
}

//To add a new subscriber
export const add_subscriber = (token,  subscriber_obj) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.subscriber_url.subscriber}${global_constants.end_points.subscriber_url.subscriber_details}/add`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': subscriber_obj
    })

    return request.make(requestConfig)

   

}

//To add a doctors order
export const add_doctors_order = (token, case_id, subscriber_id, doctors_order_list) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.subscriber_url.subscriber}${global_constants.end_points.subscriber_url.doctors_orders}/add`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': doctors_order_list,
        'params': {
            case_id: case_id,
            subscriber_id: subscriber_id
        }
    })

    return request.make(requestConfig)

}


//To add a preauth
export const add_preauth = (token, case_id, subscriber_id, preauth_list) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.subscriber_url.subscriber}${global_constants.end_points.subscriber_url.preauth}/add`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': preauth_list,
        'params': {
            case_id: case_id,
            subscriber_id: subscriber_id
        }
    })

    return request.make(requestConfig)

}

export const add_eligibility = (token, case_id, subscriber_id, eligibility_list)=>{
    const url = `${global_constants.base_end_points}${global_constants.end_points.subscriber_url.subscriber}${global_constants.end_points.subscriber_url.eligibility}/add`;
    let request = new Request();
     let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': eligibility_list,
        'params': {
            case_id: case_id,
            subscriber_id: subscriber_id
        }
    }) 

    return request.make(requestConfig)
}