import * as global_constants from '../../global_constants';
import * as login_constants from '../login_constants';

function update_master_data(source, is_select_default: boolean = false, is_data_type_int: boolean = false, is_msp_type: boolean = false) {
    var target = is_select_default ? [{ "text": global_constants.constants.dropdown_default_info.text, "value": is_data_type_int ? global_constants.constants.dropdown_default_info.value_int : global_constants.constants.dropdown_default_info.value_string, "key": "Select" }] : [];
    var source_length = source.length;

    if (source && source_length > 0) {
        for (let y = 0; y < source_length; y++) {
            let item = source[y];

            target.push({
                "text": is_msp_type ? item.type_code + ' - ' + item.name : item.name || item.text,
                "value": is_msp_type ? item.type_code : item.id || item.value,
                "key": item.name
            })
        }
    }

    return target;

}

export default function (state = [], { type, payload, error }) {
    switch (type) {

        case login_constants.USER_AUTHENTICATION:
            if (payload) {
                return { ...state, ["user_data"]: payload.data, ["is_logged_in"]: payload.data.status == 1 ? true : false };
            }
            else if (error) {
                // TODO : do something here
                //console.log(error)
            }
            else {
                return state
            }

        case (login_constants.USER_LOGOUT):
            return { ...state, ["user_data"]: null, ["is_logged_in"]: false };
            
        case (global_constants.constants.CLEAR_APPLICATION_CACHE):
            return { ...state, ["user_data"]: null, ["is_logged_in"]: false, ["is_password_expired"]: false, ["forgot_password"]: null, ["master_data"]: null, ["formated_master_data"]: null, ["master_data_by_type"]:null };

        case (login_constants.FORGOT_PASSWORD):
            return { ...state, ["user_data"]: null, ["is_logged_in"]: false, ["forgot_password"]: payload.data.messages };

        case (global_constants.constants.IS_PASSWORD_EXPIRED):
            return { ...state, ["is_password_expired"]: payload.is_password_expired };

        case (login_constants.FORGOT_PASSWORD_TOKEN_VALIDATION):
            return { ...state, ["is_password_expired"]: false, ["is_logged_in"]: false, ["forgot_password_token_validation"]: payload.data };

        case (login_constants.RESET_PASSWORD):
            return { ...state, ["is_password_expired"]: false, ["is_logged_in"]: false, ["user_reset_password"]: payload.data };

        case (login_constants.RE_SEND_IP_REGISTRATION_LINK):
            return { ...state, ["is_password_expired"]: false, ["is_logged_in"]: false, ["re_send_ip_registration_data"]: payload.data };

        case (login_constants.MASTER_DATA):

            var data = Object.assign({}, payload.data.data);
            data.country = update_master_data(data.country);
            data.states = update_master_data(data.states);
            data.gender = update_master_data(data.gender);
            data.marital_status = update_master_data(data.marital_status);
            data.primary_phone = update_master_data(data.primary_phone);
            data.reminder_type = update_master_data(data.reminder_type);
            data.languages = update_master_data(data.languages);
            data.med_cap_specialty = update_master_data(data.med_cap_specialty);
            data.disciplines = update_master_data(data.disciplines, true, false);
            data.injury_type = update_master_data(data.injury_tpes);
            data.patient_contract = update_master_data(data.note_code, true, false);
            data.referral_status = update_master_data(data.referral_status);
            data.accident_location = update_master_data(data.states, true, false);
            data.payment_code = update_master_data(data.payment_code);
            data.subscriber_relationship = update_master_data(data.subscriber_relationship);
            data.subscriber_msp_type = update_master_data(data.subscriber_msp_type, false, false, true);

            return { ...state, ["master_data"]: payload.data, ["formated_master_data"]: data, };

        // Update Token value
        case (global_constants.constants.REFRESH_TOKEN):
            return { ...state, ["user_data"]: payload };
        case (login_constants.MASTER_DATA_BY_TYPE):
            //var data = { ...payload.data.data };
            //const final_data = data.credit_types;
            //const updated_state = {
            //    ...state, ["master_data"]: { ...state['master_data'], data: { ...state['master_data'].data, credit_types: final_data } }
            //};
            //return updated_state;
            return { ...state, ["master_data_by_type"]: payload.data };

        case (global_constants.constants.UPDATE_PAYMENT_METHOD):
           // update payment methods based on FF flag
            var payment_method = [{ value: 1, text: 'Cash' }, { value: 2, text: 'Check' }, { value: 3, text: 'Credit Card' }];
                payment_method.push({ value: 4, text: 'Debit Card' });
                payment_method.push({ value: 5, text: 'HSA' });
            return { ...state, ["payment_methods"]: payment_method };
        case (global_constants.constants.AUTO_LOGOUT):
            return { ...state, ["auto_logout"]: payload.auto_logout};
        case global_constants.constants.ACTIVE_PATIENT_ID:
        /**
         * return Active patient id 
         * */
        return { ...state, ["active_patient_id"]: payload.active_patient_id };            
        case global_constants.constants.DISPLAY_404_PAGE:
        /**
         * if returns true display 404 page.
         * */
        return { ...state, ["display_404_page"]: payload };            
        case global_constants.constants.CUSTOM_PROMPT:
        /**
         * return Active patient id 
         * */
        return { ...state, ["custom_prompt"]: payload.option }; 

        case global_constants.constants.UNSAVED_FORM_DATA_FOR_PROMPT:
        /**
         * return Active patient id 
         * */
        return { ...state, ["unsaved_form_data_for_prompt"]: payload };            
        default:
            return state
    }

}
