import * as moment from 'moment';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Date_Picker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Icon } from 'semantic-ui-react';
import autoDatePicker, { is_selected_date_in_range } from "./date_picker_utility";

class Calendar extends React.Component<any, any> {
    [x: string]: any;
    constructor(props) {
        super(props);
        this.enable_native_keyboard_navigation = true;
        this.popper = {};
        this.state = {
            date_value: null,
            enable_native_keyboard_navigation: true,
            is_required: false,
            is_focused: false,
            is_disabled: this.props.is_disabled,
            class_name: this.props.class_name || 'datepicker-keybowrd'
        };
        this.handleCalendarChange = this.handleCalendarChange.bind(this);
        this.onBlurCalendarChange = this.onBlurCalendarChange.bind(this);
    }

    componentDidUpdate(previousProps, previousState) {

        var dateData = this.props.date ? this.props.date : null;
        var requiredData = this.props.is_required ? this.props.is_required : false;
        var needUpdate = false;
        var is_focused = false;
        var disableData = this.props.is_disabled ? this.props.is_disabled : false;
        if (this.props.date != previousProps.date) {
            dateData = this.props.date;
            needUpdate = true;
        }
        else if (this.props.is_required != previousProps.is_required) {
            requiredData = this.props.is_required;
            needUpdate = true;

        }
        else if (this.props.is_disabled != previousProps.is_disabled) {
            disableData = this.props.is_disabled;
            needUpdate = true;

        }

        if (needUpdate) {
            this.enable_native_keyboard_navigation = true;
            this.setState({
                enable_native_keyboard_navigation: true,
                date_value: dateData,
                is_required: requiredData,
                is_disabled: disableData,
                is_focused: is_focused
            });
        }
    }

    componentDidMount() {
        this.setState({
            date_value: this.props.date,
            is_required: this.props.is_required,
            is_disabled: this.props.is_disabled
        });
        var input = document.querySelectorAll(`.${this.state.class_name} input`);
        if (input != undefined && input.length > 0) {
            for (let x = 0; x < input.length; x++) {
                input[x].setAttribute("maxLength", "10")
            }
        }
        if (this.props.id_popper) {
            this.popper.popperContainer = this.popper_ontainer;
        }
        //const element = document.querySelector(`.${this.state.class_name} input`);
        //if (element != undefined) {
        //    element.addEventListener('keyup', this.click_handler);
        //}
    }

    click_handler = (e) => {

        if (e.target.onblur == null && e.target.onchange == null && e.keyCode == 13) {
            let value = e.target.value;

            this.onBlurCalendarChange(value);
         //   e.target.blur();
        }
    }

    // Will handle date selection from calendar when focus out 
    onBlurCalendarChange(d) {
        let prevSelectedDate = this.props.date ? this.props.date : null;
        let suggested_date = autoDatePicker(d, prevSelectedDate, this.props.maxDate);  // pickup correct date


        let m = moment(suggested_date);
        var newDate = null;
        let minDate = (this.props.minDate) ? moment(this.props.minDate) : null;
        var allow_future = true;
        var allow_previous = true;

        if (this.props.maxDate && m.isValid()) {
            if (new Date(m.format("MM/DD/YYYY")) > new Date(this.props.maxDate)) {
                allow_future = false;
            }
        }
        if (this.props.minDate && m.isValid()) {
            if (new Date(m.format("MM/DD/YYYY")) < new Date(this.props.minDate.toLocaleDateString() )) {
                allow_previous = false;
            }
        }
        if (m.isValid() && allow_future && allow_previous) {
            var s = m.format("MM/DD/YYYY");
            newDate = moment(s);

            let is_valid_date = d == "" ? true : this.is_valid_date(d);

            this.enable_native_keyboard_navigation = is_valid_date;

            this.setState({
                date_value: newDate,
                enable_native_keyboard_navigation: is_valid_date
            });

            // checking the selected date to be in range
            if( ! is_selected_date_in_range(this.props.minDate, this.props.maxDate, newDate)) return false;

            this.props.date_update(newDate);
        } else {
            let is_valid_date = d == "" ? true : this.is_valid_date(d);
            this.enable_native_keyboard_navigation = is_valid_date

            this.setState({
                date_value: null,
                enable_native_keyboard_navigation: is_valid_date
            }, () => { this.forceUpdate(); });

            this.props.date_update(null);
        }
    }

    // Will handle date selection from calendar  
    handleCalendarChange(e, dt) {

        if (((e ? e.keyCode != 13 : false) || dt._isAMomentObject) && this.enable_native_keyboard_navigation) {

            // checking the selected date to be in range        
            if( ! is_selected_date_in_range(this.props.minDate, this.props.maxDate, dt)) return false;
            
            this.setState({
                date_value: dt
            });

            // this is done to avoid change of state on navigation  
            if (e) {
                this.props.date_update(dt);
            }
        } else {
            if(e && e.target &&e.target.closest) {
                let closest_input = e.target.closest(".custom-datepicker-for-table input");
                let date_str = null;

                // edge case handled for a particular scenerio for the first time of date selection
                // steps => dummy value is entered -> tab is pressed -> shift tab is pressed and date is picked by click
                // in this case handle change event throws the date clicked by the user from the calendar
                // rather than event object
                if(closest_input) {
                    date_str = closest_input.value;
                    date_str = date_str == '' ? moment().format("MM/DD/YYYY") : date_str;
                } else {
                    date_str = (dt && dt.format) ? dt.format("MM/DD/YYYY") : moment().format("MM/DD/YYYY");
                }

                if( ! is_selected_date_in_range(this.props.minDate, this.props.maxDate, date_str)) return false;

                this.onBlurCalendarChange(date_str);
                e.target.blur();
            }
        }
    }
    is_valid_date = (date) => {
        var date_regex = /((^(10|12|0?[13578])([/])(3[01]|[12][0-9]|0?[1-9])([/])((1[8-9]\d{2})|([2-9]\d{3}))$)|(^(11|0?[469])([/])(30|[12][0-9]|0?[1-9])([/])((1[8-9]\d{2})|([2-9]\d{3}))$)|(^(0?2)([/])(2[0-8]|1[0-9]|0?[1-9])([/])((1[8-9]\d{2})|([2-9]\d{3}))$)|(^(0?2)([/])(29)([/])([2468][048]00)$)|(^(0?2)([/])(29)([/])([3579][26]00)$)|(^(0?2)([/])(29)([/])(1[89][0][48])$)|(^(0?2)([/])(29)([/])([2-9][0-9][0][48])$)|(^(0?2)([/])(29)([/])(1[89][2468][048])$)|(^(0?2)([/])(29)([/])([2-9][0-9][2468][048])$)|(^(0?2)([/])(29)([/])(1[89][13579][26])$)|(^(0?2)([/])(29)([/])([2-9][0-9][13579][26])$))/;
        return date_regex.test(date);
    }


    handle_month_change = (dt) => {

        // checking the selected date to be in range
        if (dt._isAMomentObject && is_selected_date_in_range(this.props.minDate, this.props.maxDate, dt)) {
            this.setState({
                date_value: dt
            });

            this.props.date_update(dt);
        }
    }

    handle_year_change = (dt) => {

        // checking the selected date to be in range
        if (dt._isAMomentObject && is_selected_date_in_range(this.props.minDate, this.props.maxDate, dt)) {
            this.setState({
                date_value: dt
            });

            this.props.date_update(dt);
        }
    }

    popper_ontainer = ({ children }) => {
        const domNode = document.getElementById(this.props.id_popper)
        if (domNode) {
            return ReactDOM.createPortal(children, domNode);
        }
        return null;
    }

    render() {
        var maxDate = null;
        var minDate = null;
        if (this.props.maxDate) { maxDate = moment(this.props.maxDate); }
        if (this.props.minDate) { minDate = moment(this.props.minDate); }

        //popperContainer={CalendarContainer}
        return (
            <Date_Picker placeholderText="MM/DD/YYYY"
                selected={this.state.date_value}
                onBlur={e => this.onBlurCalendarChange(e.target.value)}
                maxDate={maxDate}
                minDate={minDate}
                maxLength={10}
                onKeyDown={this.click_handler}
                disabled={this.state.is_disabled}
                showYearDropdown
                onMonthChange={this.handle_month_change}
                onYearChange={this.handle_year_change}
                showMonthDropdown
                adjustDateOnChange
                dropdownMode="select"
                enableNativeKeyboardNavigation={this.state.enable_native_keyboard_navigation}
                onChange={(e, d) => this.handleCalendarChange(d, e)}
                id={this.props.id ? this.props.id : null}
                {...this.popper}
            />
        );
    }
}

export default Calendar;