import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get_updated_charges, user_companies, deactivate_charge_audit, deactivate_bulk_charge_audit } from '../action/charge_action';
import { constants, toastr_options } from '../../global_constants';
import { bindActionCreators } from 'redux';
import { Button, Dimmer, Form, Grid, Loader, Header, Modal } from 'semantic-ui-react'
import Selection from '../../shared/component/selection_component';
import GridView from '../../shared/component/grid/grid_child_ui_component';
import { updated_charges_summary } from '../charge_constant';
import { get_columns, strip, update_company_details, get_all_error, detect_ie, clear_sotrage_after_company_change_on_main_page } from '../../shared/utility';
import * as session_storage from '../../shared/session_storage_utility';
import * as local_storage from '../../shared/local_storage_utility';
import { get_patient_company_info, set_prevent_company_model_status } from '../../shared/action/shared_action';
import { refresh_token, get_master_data_by_type, get_master_data } from '../../login/action/login_action';
import { hierarchy_grid_print_data, export_updated_charge_summary } from '../utility';
import { add_patient_account_notes_return_response } from '../../patient/action/patient_action'
import RndModal from "../../shared/component/rnd_modal";
import TextareaAutosize from 'react-textarea-autosize';
import { toastr } from 'react-redux-toastr';
import { handle_click_on_enter } from '../../shared/tab_navigation_utility';
import ConfirmationComponent from '../../shared/component/confirmation_component';
import * as ReportConstants from '../../reports/report_constants';
import LaunchDarkley from '../../shared/launch_darkley/launch_darkley';
import { MasterData } from '../../login/login_constants';
import { generateAuditDataEntryPayload } from '../../reports/util/export_print_utility';

export class UpdatedChargesComponent extends React.Component<any, any> {

    _is_mounted = false;
    _parent_company_list = [];
    _user_master_company_list = [];
    _grid_ref = { api: null };
    _rnd: any;
    _is_resized: boolean = false;
    _default_popup_height = 150;

    constructor(props) {
        super(props);
        this.state = {
            search_criteria: {
                Parent_company_id: -1,
                Company_id: -1
            },
            updated_charges: {
                rows: [],
                updated_charge_summary_column_def: updated_charges_summary.column_defs,
                grid_header_height: 0,
                total_column_width: 0,
                max_inner_width: 0,
                grid_width: 0
            },
            parent_company_options: [],
            user_company_options: [],
            show_grid: false,
            loading: false,
            show_note_popup: false,
            note_text: '',
            note_submitted: false,
            popUpPosition: {
                x: 300,
                y: 65,
                width: 699,
                height: this._default_popup_height,
                minHeight: 150,
                minWidth: 699,
                maxWidth: 1600,
                maxHeight: 900
            },
            refresh_component: this.props.location.state.refresh,
            show_remove_modal: false
        }
        this.handle_clear = this.handle_clear.bind(this);
        this.on_save_note_modal = this.on_save_note_modal.bind(this);
    }

    reportId: string ="0";

    selected_updated_charge: {
        list_intaudit_ids: [],
        patient_id: any,
        date_of_service: '',
        info_note_code_id: any,
        company_id: any
    };
    selected_bulk_update_charge: [
        { int_audit_ids: Array<number>, company_id: any, patient_id: any }
    ];

    note_model: {
        patient_id: 0,
        note_code_id: any,
        note_code: any,
        note_text: any,
        show_on_patient: false,
        show_on_charge: false,
        show_on_payment: false,
        show_on_case: false,
        stick_on: "",
        loading: false
    };


    componentDidMount = () => {
        this._is_mounted = true;
        //Load the DropDown
        this.get_parent_n_user_company(true);
        document.body.classList.add('reports');

        let search = this.loadSeachCriteriaFromSession();
        if (search) {
            this.setState({
                search_criteria: {
                    Parent_company_id: search.Parent_company_id,
                    Company_id: search.Company_id
                }
            });
            this.get_updated_charges(true);
        }
    }

    loadSeachCriteriaFromSession() {
        let search = null;
        let updatedcharge_page_search = session_storage.get(updated_charges_summary.session_key);
        if (updatedcharge_page_search && updatedcharge_page_search.search_criteria) {
            return updatedcharge_page_search.search_criteria;
        }
        return search;
    }


    componentWillUnmount = () => {
        this._is_mounted = false;
        document.body.classList.remove('reports');
        delete this._parent_company_list;
        delete this._user_master_company_list;
        //setState should not be called on componentWillUnmount 
        //as the component is about to be destroyed and never remounted.
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.state.refresh != this.props.location.state.refresh) {
            this.handle_clear();
        }

    }


    get_parent_n_user_company = async (include_all_companies_options) => {

        if (this._is_mounted) {
            //console.log('Updated Charges:: get_parent_n_user_company');
            await user_companies(this.props.user_login_details.user_data.data.token_details.access_token, include_all_companies_options).then(response => {
                if (response.data.data) {
                    this._parent_company_list = response.data.data.parent_companies;
                    this._user_master_company_list = response.data.data.companies;

                    let patent_company_dd_options = this.get_dd_options(response.data.data.parent_companies);
                    let company_dd_options = this.get_dd_options(response.data.data.companies);
                    this.setState({
                        loading: false,
                        parent_company_options: patent_company_dd_options,
                        user_company_options: company_dd_options,
                    });
                }
                else {
                    this._parent_company_list = [];
                    this._user_master_company_list = [];
                    this.setState({
                        loading: false,
                        parent_company_options: [],
                        user_company_options: []
                    });
                }

            });
        }

    }


    get_dd_options = (obj_list) => {
        //console.log('Updated Charges:: get_dd_options');
        if (!obj_list) return [];
        let options = [];
        obj_list.forEach((obj) => { options.push({ key: obj.company_id, value: obj.company_id, text: obj.company_name }) });
        return options;

    }


    select_user_company = (parent_company_id) => {
        let user_companies = [];
        // ALL PARENT COMAPNIES SELECTED.
        if (parent_company_id == -1) {
            user_companies = this._user_master_company_list;
        }
        else {
            user_companies = this._user_master_company_list.filter(item => (item.parent_company_id == parent_company_id || item.parent_company_id == -1));
        }
        return user_companies
    }


    on_change_parent_company_handler = (value, event) => {
        if (this._is_mounted) {
            let i_value = parseInt(value);
            //console.log('Updated Charges:: on_change_parent_company_handler, value = ' + i_value);
            let user_company = this.select_user_company(i_value);
            let company_dd_options = this.get_dd_options(user_company)
            let first_user_company = -99;
            if (!company_dd_options && company_dd_options.length == 0) {
                // This condition should never happen as we receive parent companies that have child companies associated always. 
                company_dd_options.push({ key: -99, value: -99, text: ' - NO COMPANIES - ' });
            }
            else {
                first_user_company = company_dd_options[0].value;
            }

            this.setState({
                user_company_options: company_dd_options,
                search_criteria: {
                    Parent_company_id: i_value,
                    Company_id: first_user_company
                }
            });
        }

    }


    on_change_user_company_handler = (value, event) => {
        if (this._is_mounted) {
            //console.log('Updated Charges:: on_change_user_company_handler, value = ' + value);
            let i_value = parseInt(value);
            //If a child company is selected populate the appropriate parent company.
            let user_selection_parent_company = null;
            if (i_value != -1) {
                let user_company = this._user_master_company_list.filter(item => (item.company_id == i_value));
                if (user_company && user_company.length > 0) user_selection_parent_company = user_company[0].parent_company_id;
            }
            //console.log('Updated Charges:: on_change_user_company_handler, user_selection_parent_company = ' + user_selection_parent_company);
            this.setState({
                search_criteria: {
                    Parent_company_id: user_selection_parent_company == null ? this.state.search_criteria.Parent_company_id : user_selection_parent_company,
                    Company_id: i_value
                }
            });
        }

    }


    get_updated_charges = async (Load_from_session = false) => {
        if (this._is_mounted) {
            this.setState({ loading: true });

            //Seach Criteria to load the data.
            let search_parameters = this.state.search_criteria
            if (Load_from_session == true) {
                let search = this.loadSeachCriteriaFromSession();
                if (search) search_parameters = { Parent_company_id: search.Parent_company_id, Company_id: search.Company_id }
            }
            //console.log("Parent Company Id = " + search_parameters.Parent_company_id + ", Company Id = " + search_parameters.Company_id);
            await get_updated_charges(this.props.user_login_details.user_data.data.token_details.access_token, search_parameters).then(async response => {
                if (response.data.data && response.data.data.length > 0) {
                    let formated_table = await this.format_data(response);
                    session_storage.set(updated_charges_summary.session_key, {
                        search_criteria: this.state.search_criteria
                    });
                    this.setState({
                        loading: false,
                        updated_charges: formated_table,
                        show_grid: true
                    });
                }
                else {
                    this.setState({
                        loading: false,
                        updated_charges: {
                            rows: [],
                            updated_charge_summary_column_def: updated_charges_summary.column_defs,
                            grid_header_height: 0,
                            total_column_width: 0,
                            max_inner_width: 0,
                            grid_width: 0
                        },
                        show_grid: true
                    });
                }
            });
        }
    }


    format_data = async (response) => {
        let formated_table_object = {
            rows: [],
            updated_charge_summary_column_def: updated_charges_summary.column_defs,
            grid_header_height: 0,
            total_column_width: 0,
            max_inner_width: 0,
            grid_width: 0
        };
        formated_table_object.rows = response.data.data
            ? response.data.data.map(item => {
                const inner_cols = updated_charges_summary.inner_cols.map(value => ({ ...value }));
                item = {
                    ...item,
                    participants: [
                        {
                            ...item,
                            inner_cols
                        }
                    ],
                    group: ''
                };
                let propertyName = 'updated_charge_details';
                let x = item[propertyName];
                delete item['updated_charge_details'];
                return item;
            })
            : [];
        formated_table_object.total_column_width = 0;
        const grid_height = get_columns(
            formated_table_object.rows,
            formated_table_object.updated_charge_summary_column_def
        );

        formated_table_object.grid_header_height = grid_height.header_height;
        formated_table_object.total_column_width = grid_height.total_column_width;
        formated_table_object.grid_width = document.getElementsByClassName('report-wrapper')[0].clientWidth;
        return formated_table_object;
    }


    handle_clear = () => {
        if (this._is_mounted) {
            let reset_popup_obj = this.reset_pop_up();
            let company_dd_options = this.get_dd_options(this._user_master_company_list);
            session_storage.remove(updated_charges_summary.session_key);
            this.setState({
                search_criteria: {
                    Parent_company_id: -1,
                    Company_id: -1
                },
                show_grid: false,
                user_company_options: company_dd_options,
                updated_charges: {
                    rows: [],
                    updated_charge_summary_column_def: updated_charges_summary.column_defs,
                    grid_header_height: 0,
                    total_column_width: 0,
                    max_inner_width: 0,
                    grid_width: 0
                },
                show_note_popup: false,
                note_text: '',
                note_submitted: false,
                popUpPosition: reset_popup_obj
            });
        }
    }


    on_click_export_button = () => {
        export_updated_charge_summary(this._grid_ref, updated_charges_summary.page_name);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Export);
    }


    on_click_print_button = () => {
        hierarchy_grid_print_data(this._grid_ref, updated_charges_summary.page_name);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
    }

    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;

        const payload: ReportConstants.IPayloadForBillingAudit = {
            reportId: this.reportId,
            contextTitle: `${updated_charges_summary.page_name} - ${ReportConstants.ReportEventAction[eventActionId]}`,
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: ReportConstants.ReportEntityTypeId.D_Charge,
            data: { Records: this.state.updated_charges.rows.length }
        }

        const reqBody = generateAuditDataEntryPayload(payload);

        ReportConstants.saveAuditPrintExport(reqBody, accessToken);
    };

    get_grid_ref = grid_ref => {
        this._grid_ref = grid_ref;
       
    };


    is_button_disabled() {
        let disable_button = false;
        if (!this.state.updated_charges || (this.state.updated_charges.rows.length == 0)) {
            disable_button = true;
        }
        return disable_button;
    }


    change_company = async (company_data) => {
        var user_data = this.props.user_login_details.user_data.data;
        user_data.company_id = company_data.id;
        user_data.company_name = company_data.name;
        user_data.gpms_code = company_data.gpms_code;

        // Set auth data in local storage on company changed 
        update_company_details(company_data);

        await this.props.refresh_token(user_data, true);
        console.log("End Updated Charges, refreshing token, user data =>", this.props.user_login_details.user_data.data);
        // get master data when user change company
        this.props.get_master_data(MasterData.all, this.props.user_login_details.user_data.data.user_id, this.props.user_login_details.user_data.data.token_details.access_token, true);
        this.props.get_master_data_by_type(MasterData.all, this.props.user_login_details.user_data.data.user_id, this.props.user_login_details.user_data.data.token_details.access_token, true);
        LaunchDarkley.update();
        clear_sotrage_after_company_change_on_main_page();
    }


    on_cell_Click = async ({ data, column }) => {
        if ((column.colId == 'update_action')) {
            // Open Note Here     
            this.setState({ show_note_popup: true });
            this.selected_updated_charge = {
                patient_id: data.patient_id,
                list_intaudit_ids: data.int_audit_ids,
                date_of_service: data.date_of_service,
                info_note_code_id: data.info_note_code_id,
                company_id: data.company_id
            };
        }
        if (column.colId == 'patient_id') {
            var patient_id = data.patient_id;
            await this.update_token(data.company_id, patient_id);
        }
    }


    save_note = async () => {
        if (this._is_mounted) {
            //Add patient note
            this.note_model = {
                patient_id: this.selected_updated_charge.patient_id,
                note_code_id: this.selected_updated_charge.info_note_code_id,
                note_code: "INFO",
                note_text: (this.selected_updated_charge.date_of_service + ' - ' + this.state.note_text),
                show_on_patient: false,
                show_on_charge: false,
                show_on_payment: false,
                show_on_case: false,
                stick_on: "",
                loading: false
            };

            await this.update_token(this.selected_updated_charge.company_id, this.selected_updated_charge.patient_id);
            await add_patient_account_notes_return_response(this.selected_updated_charge.patient_id,
                this.props.user_login_details.user_data.data.token_details.access_token, this.note_model).then(response => {
                    if (response.data.messages && response.data.messages.length > 0) {
                        toastr.error('', response.data.messages[0].message);
                    }
                    else {
                        deactivate_charge_audit(this.props.user_login_details.user_data.data.token_details.access_token,  this.selected_updated_charge.list_intaudit_ids ).
                            then(async response => {
                                this.selected_updated_charge = { patient_id: 0, list_intaudit_ids: [], date_of_service: "", info_note_code_id: -1, company_id: -1 };
                                this.get_updated_charges();
                            });
                    }
                },
                    error => {
                        if (error.response.data && error.response.data.messages && error.response.data.messages.length > 0)
                            toastr.error('', error.response.data.messages[0].message);
                    });
            this.setState({ note_text: '', note_submitted: false });
        }

    }

    update_token = async (company_id, patient_id) => {
        session_storage.set("is_navigate", true);
        let auth_data = local_storage.get('auth_data');
        let current_Company_Id = auth_data && auth_data.company_id;
        const navigate_to = `/patient/${patient_id}`;
        if (current_Company_Id != company_id) {
            if(detect_ie()){
                let count = parseInt(local_storage.get("open_tab_count"));
                if(count > 1) {
                    this.props.set_prevent_company_model_status(true);
                    return false;
                }
            }
            let token = auth_data && auth_data.token_details && auth_data.token_details.access_token;
            await get_patient_company_info(patient_id, token).then(async response => {
                if (response && response.data && response.data.data) {
                    local_storage.set('report_navigation', {
                        pathname: navigate_to,
                    });
                    local_storage.set('company_change_on_same_tab', true);
                    local_storage.set('last_report_navigation', window.location)
                    await this.change_company(response.data.data);
                }
            }).catch(error => {
            })
        }
        this.props.history.push(`${navigate_to}`);
    }



    on_close_note_modal = () => {
        if (this._is_mounted) {
            this.setState({
                show_note_popup: false,
                note_submitted: false,
                note_text: ''
            });
        }
    }


    on_save_note_modal = () => {
        if (this._is_mounted) {
            let note_valid = this.is_note_valid();
            this.setState({
                note_submitted: true,
                show_note_popup: !note_valid
            });
            if (note_valid) this.save_note();
        }
    }


    update_popup_height(height_update) {
        let updated_parent_height = this._default_popup_height + parseInt(height_update);
        if (this._is_mounted) {
            this.setState({
                popUpPosition: {
                    ...this.state.popUpPosition,
                    height: updated_parent_height,
                    minHeight: 150
                },
            })
        }

        this._rnd &&
            this._rnd.updateSize({
                width: this.state.popUpPosition.width,
                height: updated_parent_height,
                minHeight: this.state.popUpPosition.minHeight,
                minWidth: 628
            });
    }


    reset_pop_up() {
        return (
            {
                x: 300,
                y: 65,
                width: 699,
                height: this._default_popup_height,
                minHeight: 150,
                minWidth: 699,
                maxWidth: 1600,
                maxHeight: 900
            }
        );
    }


    is_note_valid() {
        let valid_note = (this.state.note_text && (this.state.note_text.trim().length > 0))
        return valid_note;
    }

    // Select case_id on checkbox
    on_check_box_selection = (items) => {
        this.selected_bulk_update_charge = items;       
    };

    is_row_selectable = (params) => {
        if (params.data && params.data.participants) return true;

        return false;
    }
    on_click_update_button = () => {
        if (this.selected_bulk_update_charge && this.selected_bulk_update_charge.length > 0) {
            if (this._is_mounted) {
                this.setState({ show_remove_modal: true });
            }
        }
        else {
            toastr.error("", updated_charges_summary.empty_error);
        }

    }
    on_close_remove_confirmation = () => {
        if (this._is_mounted) {
            this.setState({ show_remove_modal: false });
        }
    }
    deactivate_charge_audit = async (remove_audit_list: Array<any>, list_intaudit_ids:Array<number>) => {
        if (remove_audit_list && remove_audit_list.length > 0) {
            var remove_audit_success: Array<number> = [];
            var remove_audit_failure: Array<number> = [];
            if (this.selected_bulk_update_charge && this.selected_bulk_update_charge.length > 0) {
                //await this.update_token(this.selected_bulk_update_charge[0].company_id, this.selected_bulk_update_charge[0].patient_id);
                deactivate_bulk_charge_audit(this.props.user_login_details.user_data.data.token_details.access_token, remove_audit_list).
                    then(async response => {
                        if (response.data && response.data.status === 1) {
                            await this.get_updated_charges();
                            this.selected_bulk_update_charge = [{ int_audit_ids: [], company_id: -1, patient_id: 0 }];
                            this.selected_bulk_update_charge.splice(0, 1);
                            for (var indx = 0; indx < list_intaudit_ids.length; indx++) {
                                if (response.data.data[list_intaudit_ids[indx]]) {
                                    remove_audit_success.push(list_intaudit_ids[indx]);
                                }
                                else {
                                    remove_audit_failure.push(list_intaudit_ids[indx]);
                                    this._grid_ref.api.forEachNodeAfterFilter((node) => {
                                        // select the node
                                        if (node.data.int_audit_ids.indexOf(list_intaudit_ids[indx]) != -1) {
                                            //this.selected_bulk_update_charge.push(node.data);
                                            node.setSelected(true);
                                        }

                                    });
                                }
                            }

                            if (list_intaudit_ids.length !== remove_audit_success.length) {
                                toastr.error("", updated_charges_summary.remove_error_text);
                            } else {
                                toastr.success("", updated_charges_summary.remove_successful_text);
                            }

                        }
                        else {
                            if (this._is_mounted) {
                                this.setState({
                                    loading: false
                                });
                            }
                            if (response.data && response.data.messages && response.data.messages.length>0) {
                                const toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                                toastr.error('', toastr_options);
                            }
                        }

                    }, (error) => {
                        if (this._is_mounted) {
                            this.setState({
                                loading:false
                            });
                        }
                        if (error.response && error.response.data) {
                            const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                            toastr.error('', toastr_options);
                        }

                    }).catch(error => {
                        if (this._is_mounted) {
                            this.setState({
                                loading: false
                            });
                        }
                        if (error.response && error.response.data) {
                            const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                            toastr.error('', toastr_options);
                        }
                    });
            }
        }
    }
    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }
    on_remove_charge = () => {
        this.on_close_remove_confirmation();
        let remove_audit_list = [];
        var list_intaudit_ids = [];
        if (this.selected_bulk_update_charge.length > 0) {

            for (var indx = 0; indx < this.selected_bulk_update_charge.length; indx++) {    
                remove_audit_list.push({
                    company_id: this.selected_bulk_update_charge[indx].company_id,
                    int_audit_ids: this.selected_bulk_update_charge[indx].int_audit_ids
                });
                list_intaudit_ids = list_intaudit_ids.concat(this.selected_bulk_update_charge[indx].int_audit_ids);
            }
            this.deactivate_charge_audit(remove_audit_list, list_intaudit_ids);           
        }
        else {
            toastr.error("", updated_charges_summary.empty_error);
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className={'common-forms-add'}>
                    <Dimmer active={this.state.loading}>
                        <Loader size='massive'>Loading</Loader>
                    </Dimmer>
                    <div className='common-forms-search report-framework'>
                        <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                            <Grid.Column computer={16}>
                                <Header as='h3' textAlign="left">{updated_charges_summary.page_name}</Header>
                            </Grid.Column>
                        </Grid>
                        <div id='updated-charge-summary-scrollable-area' className='report-wrapper' style={this.state.show_grid ? { flex: 1 } : {}}>
                            <div className='patient-search-form  patient_search_bottom_padding' id='report-criteria-container'>
                                <Form autoComplete="off" onReset={this.handle_clear.bind(this)}>
                                    <Grid>
                                        <Grid.Column>
                                            <Grid>
                                                <Grid.Column mobile={16} tablet={8} computer={4}>
                                                    <Form.Field>
                                                        <label>{updated_charges_summary.parent_company_dd_name} <span className="req-alert_normal">{constants.required_text}</span></label>
                                                        <Selection
                                                            id='parent_company'
                                                            name='parent_company'
                                                            options={this.state.parent_company_options}
                                                            onChange={(value, e) => {
                                                                this.on_change_parent_company_handler(value, e);
                                                            }}
                                                            defaultValue={this.state.search_criteria.Parent_company_id}
                                                            style={'dropdown-options-wrap'}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column mobile={16} tablet={8} computer={4}>
                                                    <Form.Field>
                                                        <label>{updated_charges_summary.user_company_dd_name} <span className="req-alert_normal">{constants.required_text}</span></label>
                                                        <Selection
                                                            id='user_company'
                                                            name='user_company'
                                                            options={this.state.user_company_options}
                                                            onChange={(value, e) => {
                                                                this.on_change_user_company_handler(value, e);
                                                            }}
                                                            defaultValue={this.state.search_criteria.Company_id}
                                                            style={'dropdown-options-wrap'}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid>
                                            <Grid style={{ margin: '0 -17px' }}>
                                                <Grid.Column computer={16} textAlign="right">
                                                    <Button id='updated_charge_clear_button' type="reset" basic >{updated_charges_summary.clear_button_text}</Button>
                                                    <Button id="updated_charge_search_button" type='submit' onClick={this.get_updated_charges.bind(this)} primary>{updated_charges_summary.search_button_text}</Button>
                                                </Grid.Column>
                                            </Grid>
                                        </Grid.Column>
                                    </Grid>
                                </Form>
                            </div>
                            {
                                this.state.show_grid && (
                                    <GridView
                                        id={updated_charges_summary.report_grid_id}
                                        row={this.state.updated_charges.rows}
                                        column={this.state.updated_charges.updated_charge_summary_column_def}
                                        headerHeight={this.state.updated_charges.grid_header_height}
                                        style={{ height: '100%' }}
                                        wrapperStyle={{ width: '100%', height: '100%', display: 'flex', flex: 1 }}
                                        wrapperClass={'grid_wrapper accordian_grid'}
                                        enableColResize={true}
                                        emptyMessage={constants.no_records_found_message}
                                        headerIdForTabNavigation={updated_charges_summary.updated_charge_summary_configuration_header_id}
                                        onCellClicked={this.on_cell_Click}
                                        detailsName='updated_charge_details'
                                        get_grid_ref={this.get_grid_ref}
                                        checkboxSelection={true}
                                        onRowSelection={this.on_check_box_selection}
                                        suppressSizeToFit={true}
                                        isRowSelectable={this.is_row_selectable}
                                    />
                                )
                            }
                        </div>
                        <div className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area' id='applicationFooterSticky'>
                            <Grid textAlign='right' style={{ marginTop: 0.5, marginBottom: 0.5 }}>
                                <Grid.Column computer={16} textAlign='right'>
                                    <Button id='export_summary_button' type='submit' disabled={this.is_button_disabled()} onClick={this.on_click_export_button} basic> {updated_charges_summary.export_button_text} </Button>
                                    <Button id='print_summary_button' type='submit' disabled={this.is_button_disabled()} onClick={this.on_click_print_button} basic> {updated_charges_summary.print_button_text} </Button>
                                    <Button id='update_charge_button' type='submit' disabled={this.is_button_disabled()} onClick={this.on_click_update_button} primary> {updated_charges_summary.update_button_text} </Button>
                                </Grid.Column>
                            </Grid>
                        </div>
                    </div>
                </div>
                {
                    this.state.show_grid && this.state.show_note_popup && (
                        <RndModal
                            isOpen={this.state.show_note_popup}
                            onRequestClose={this.on_close_note_modal}
                            ariaHideApp={false}
                            // Rnd properties
                            className={"default-modal"}
                            default={this.state.popUpPosition}
                            minWidth={this.state.popUpPosition.minWidth}
                            minHeight={this.state.popUpPosition.minHeight}
                            maxWidth={this.state.popUpPosition.maxWidth}
                            maxHeight={this.state.popUpPosition.maxHeight}
                            enableResizing={{ bottomRight: true }}
                            resizeHandleClasses={{
                                bottomRight: "resize_bottom_right"
                            }}
                            onResize={(e, direction, ref, delta, position) => {
                                this._is_resized = true;
                                if (this._is_mounted) {
                                    this.setState({
                                        popUpPosition: {
                                            ...this.state.popUpPosition,
                                            width: ref.style.width,
                                            height: ref.style.height,
                                        },
                                    });
                                }
                            }}
                            size={{ width: this.state.popUpPosition.width, height: this.state.popUpPosition.height }}
                            bounds="window"
                            dragHandleClassName="daragble-header"
                            style={{ overflow: "hidden" }}
                            reference={upDate => {
                                this._rnd = upDate;
                            }}
                        >
                            <div className="header daragble-header" style={{ cursor: "all-scroll", borderRadius: '.28571429rem .28571429rem 0 0' }} id="daragble-header">
                                <h3>Note</h3>
                                <i tabIndex={0} onKeyDown={handle_click_on_enter} aria-hidden="true" className="close icon" onClick={this.on_close_note_modal} />
                            </div>
                            <div className="content">
                                <div className="description">
                                    <div className="daragble-App" id="daragble-App">
                                        <div className="common-forms" style={{ padding: "0" }}>
                                            <Grid>
                                                <Grid.Column>
                                                    <label><span className={(this.state.note_submitted && !this.is_note_valid()) ? 'req-alert' : 'req-alert_normal'}>{constants.required_text}</span></label>
                                                    <TextareaAutosize
                                                        id='note_text'
                                                        name="note_text"
                                                        value={this.state.note_text}
                                                        type="text"
                                                        className={(this.state.note_submitted && !this.is_note_valid()) ? 'red-error-thin requiredWithBgColor' : ''}
                                                        maxLength={2000}
                                                        autoFocus
                                                        onChange={e => {
                                                            this.setState({ note_text: e.target.value })
                                                        }}
                                                        style={{ width: "100%" }}
                                                        onHeightChange={(height, instance) => this.update_popup_height(height)} // just updating parent height
                                                    />
                                                </Grid.Column>
                                            </Grid>
                                        </div>
                                        <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area padd-r-0">
                                            <Grid textAlign='right'>
                                                <Grid.Column computer={16} textAlign='right'>
                                                    <Button id="btnCancleNote" type="button" basic onClick={() => this.on_close_note_modal()}> Cancel </Button>
                                                    <Button id="btnSaveNote" type="button" primary onClick={() => this.on_save_note_modal()}> Save </Button>
                                                </Grid.Column>
                                            </Grid>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </RndModal>
                    )
                }
                {
                    this.state.show_remove_modal && (
                        <ConfirmationComponent title={updated_charges_summary.remove_modal_title}
                            message={updated_charges_summary.remove_modal_message}
                            save_button_text={updated_charges_summary.remove_modal_ok_text}
                            cancel_button_text={updated_charges_summary.remove_modal_cancel_text}
                            show_modal={this.state.show_remove_modal}
                            onCloseModal={this.on_close_remove_confirmation}
                            on_force_save_click={this.on_remove_charge}
                            custom_id={"remove_modal_confim_popup"}
                        >
                        </ConfirmationComponent>)
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        refresh_token: refresh_token,
        set_prevent_company_model_status,
        get_master_data: get_master_data,
        get_master_data_by_type: get_master_data_by_type,
    }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdatedChargesComponent));