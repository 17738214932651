import * as React from 'react';
import * as constants from '../constants';
import * as FF from '../../../feature_flip_constant';
import * as global_constants from '../../../global_constants';
import * as constants_actions from '../action/constants_action';
import * as local_storage from './../../../shared/local_storage_utility';
import * as session_storage from '../../../shared/session_storage_utility';
import * as billingPaneConstants from '../provider_billing_pane_constants';

import { connect } from 'react-redux';
import { Drawer, Position } from '@blueprintjs/core';
import { toastr as toaster } from 'react-redux-toastr';
import { get_insurance_codes } from '../action/constants_action';
import { option_formater as option_formatter } from '../constants';
import { advanced_search_class } from '../../../shared/action/shared_action';
import { ProviderBillingValidator } from './providerBilling/provider_billing_validator';
import { get_lauch_darkley_key_value as get_launch_darkly_key_value } from '../../../shared/utility';
import { Accordion, Button, Checkbox, Dimmer, Form, Grid, Header, Icon, Loader, Modal } from 'semantic-ui-react';
import { ConcreteStrategyClaimHoldNameUnique } from './providerBilling/provider_billing_concrete_setting_name_unique';
import { handle_click_on_enter, set_focus_on_element_with_id, set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import { check_valid_npi, get_all_error, is_null_or_white_space, is_user_partner_customer_and_company_revEquip_revServe, unmask, zip_code_formation } from '../../../shared/utility';
import { ConcreteStrategyOnlyProviderInGroups, ConcreteStrategySingleDataInGroupsLocationClaimHold } from './providerBilling/provider_billing_concrete_data_in_groups';

import MaskedInput from 'react-text-mask';
import TextareaAutosize from 'react-textarea-autosize';
import Selection from '../../../shared/component/selection_component';
import DrawerComponent from '../../../shared/component/drawer_component';
import ProviderBillingHeaderComponent from './provider_billing_header_component';
import PromptNavigation from '../../../shared/component/prompt_navigation_component';
import ClaimHoldsAddEditDrawerComponent from './claimholds_add_edit_drawer_component';
import ProviderBillingDisplayRowComponent from './provider_billing_display_row_component';


class LocationViewEditComponent extends React.Component<any, any> {
    go_next: boolean = false;
    _is_mounted: boolean = false;
    disable_fields: boolean = false;
    by_pass_prompt: boolean = false;
    is_grid_visible: boolean = false;
    is_image_updated: boolean = false;
    is_new_claim_button_disable: boolean = false;
    required_statement_header_company_details: boolean = false;
    disabled_statement_header_company_details: boolean = false;

    token: string = '';
    image_preview: string = '';
    page_name: string = 'locations';
    image_formate: string = 'data:image/bmp;base64';

    return_same_as_location = ['stmt_rtn_name', 'stmt_rtn_address', 'stmt_rtn_city', 'stmt_rtn_state', 'stmt_rtn_zip'];

    code_id: Number = 0;
    company_id: Number = 0;

    page_metadata: any;
    incomingPayload: any;
    rowNodesForClaims: any;
    current_user_type: any;
    company_service_type: any;
    billing_selectedItems: any;
    claim_hold_selectedItems: any;

    company_details: any = {};
    initial_form_object: any = {};
    company_initial_details: any = {};

    menu_permission_object: Array<any> = [{ update: true }];

    getLocationDataPromise: Promise<string>;
    getLocationCodePromise: Promise<string>;
    apiPromisesCollector: Promise<string>[] = [];
    getLocationClaimsListPromise: Promise<string>;
    retrievePendingReasonPromise: Promise<string>;
    retrieveProvidersAndInsurancesPromise: Promise<string>;

    retrievePendingReasonPromiseReject: any;
    getProviderClaimsListPromiseReject: any;
    retrievePendingReasonPromiseResolve: any;
    getLocationClaimsListPromiseResolve: any;
    getLocationsClaimsListPromiseResolve: any;
    retrieveProvidersAndInsurancesPromiseReject: any;
    retrieveProvidersAndInsurancesPromiseResolve: any;

    validator: any = new ProviderBillingValidator();

    locations_search_criteria: any = {
        location_code: '',
        location_name: '',
        show_inactive: false
    };

    STATEMENT_HEADER_INFORMATION = {
        USE_COMPANY: "1",
        USE_LOCATION: "2"
    }

    form_object: any = {
        address1: '',
        address2: '',
        city: '',
        apply_tax_billing: false,
        chg_entry_supply_only: false,
        claim_code: '',
        default_prov_grp_num: '',
        dont_show_in_lookups: false,
        fax: '',
        image_base64: null,
        image_name: null,
        is_active: true,
        is_incomplete_rf3: true,
        logo_image_index_id: 0,
        medicare_type: '',
        npi: '',
        phone: '',
        place_of_service_id: 0,
        pos_identifier: '',
        pos_code: '',
        pos_name: '',
        practice_id: 0,
        practice_name: '',
        state: '',
        statement_address: '',
        statement_city: '',
        statement_name: '',
        statement_note: '',
        statement_phone: '',
        statement_phone_ext: '',
        statement_same_as_clinic: false,
        statement_state: '',
        statement_zip: '',
        stmt_rtn_address: '',
        stmt_rtn_city: '',
        stmt_rtn_name: '',
        stmt_rtn_same_as_clinic: false,
        stmt_rtn_state: '',
        stmt_rtn_zip: '',
        submitter_id: '',
        tax_id: '',
        zip: ''
    };

    same_as_location = [
        'statement_address',
        'statement_city',
        'statement_name',
        'statement_note',
        'statement_phone',
        'statement_phone_ext',
        'statement_state',
        'statement_zip'
    ];

    required_field: any = {
        pos_code: true,
        pos_name: true,
        address1: true,
        city: true,
        state: true,
        zip: true,
        practice_id: true,
        npi: true,
        pos_identifier: true,
        claim_code: true
    };

    claim_model: any = {
        claim_hold_name: '',
        claim_delay_reason_id: '31',
        start_date: null,
        end_date: null,
        claim_hold_id: 0,
        location_id: 0,
        location_ids: [],
        provider_ids: [],
        insurance_class: '',
        insurance_class_ids: [],
        insurance_code: '',
        insurance_code_ids: [],
        not_used_for_billing: false,
        status: true,
        hasChanges: false
    };

    system_setting_required_field: any = {
        statement_name: true,
        statement_address: true,
        statement_city: true,
        statement_state: true,
        statement_zip: true,
        statement_phone: true,
        stmt_rtn_name: true,
        stmt_rtn_address: true,
        stmt_rtn_city: true,
        stmt_rtn_state: true,
        stmt_rtn_zip: true,
    };


    constructor(props) {
        super(props);
        // Define initial state value.
        this.state = {
            patient_master_data: this.props.user_login_details.formated_master_data,

            show_render: true,
            is_loading: false,
            is_submitted: false,
            logo_loading: false,
            by_pass_prompt: false,
            isSaveInProgress: false,
            isClaimDrawerOpen: false,
            show_inactive_claim: false,
            is_save_button_disabled: false,

            openPanelMode: {
                isModeEditable: false,
                isModeCreate: false
            },
            controls_is_expended: {
                location: true,
                claim: true,
                billing: true,
                statement: true
            },

            claimTabId: '',

            practice: [],
            providers: [],
            insuranceCodes: [],
            claim_hold_rows: [],
            provider_search: [],
            insuranceClasses: [],
            claim_delay_reason: [],
            unsaved_claim_rows: [],
            claim_hold_rows_original: [],

            error: {},
            required: {},
            form_object: {},
            required_field: {},
            required_error: {},
            system_setting_required: {},

            locationClaimHold: {
                originalRowData: {},
                originalRowIndex: {}
            },

            claim_hold_col_fields: this.retrieveProviderClaimHoldsColumns(),
        };

        this.billing_selectedItems = [];
        this.claim_hold_selectedItems = [];
        this.rowNodesForClaims = [];
    }


    // Fetching the page_name and state.
    UNSAFE_componentWillMount = () => {
        this._is_mounted = true;
        let auth_data = local_storage.get("auth_data");
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.code_id = this.props.match.params && this.props.match.params.id ? this.props.match.params.id : 0;
        this.disabled_statement_header_company_details = this.is_disabled_statement_header_company_details(auth_data);
        this.required_statement_header_company_details = this.is_required_statement_header_company_details(auth_data);

        //Verifying page_name is correct or not.
        this.page_metadata = constants.locations;
        this.initial_form_object = { ...this.form_object };
        if (this.code_id) {
            this.checkUpdatePermission();
            this.get_data(this.code_id);
        } else {
            if (this._is_mounted) {
                this.setState({
                    form_object: this.form_object
                });
            }
        }
        this.generateFirstCallsPromises();

        if (auth_data && auth_data.user_type_r3_id) {
            this.current_user_type = auth_data.user_type_r3_id;
            this.company_service_type = auth_data.company_service_type;
        }
        if (
            is_user_partner_customer_and_company_revEquip_revServe(this.company_service_type, this.current_user_type)
        ) {
            this.is_new_claim_button_disable = true;
        }

        this.setState({
            is_loading: true
        });
    };

    checkUpdatePermission = () => {
        this.menu_permission_object = this.props.shared_details.left_menu_data.data.filter((menu) => {
            return menu.name == 'Edit Locations';
        });

        if (this.menu_permission_object.length == 0 || !this.menu_permission_object[0].view) {
            this.disable_fields = true;
            this.setState({ is_save_button_disabled: true });
        }
    }

    componentDidMount = () => {
        this._is_mounted = true;
        document.body.classList.add('admin-framework');
        let elem = document.getElementById('search-criteria-container');
        let all_form_elem = elem.getElementsByTagName('input');
        all_form_elem[0].focus();
        this.get_practices();
        this.get_company_details();

        this.retrievePendingReasons();

        this.get_location_card_practice_select_data();

        if (this.code_id) {
            this.get_location_card_data(this.code_id);
        } else {
            if (this._is_mounted) {
                this.setState({
                    form_object: this.form_object
                });
            }
        }

        if (this.code_id > 0) {
            this.get_location_claim_list(this.code_id);
        }
        this.retrieveProvidersAndInsurancesForMultiTab();

        Promise.all(this.apiPromisesCollector).then(() => {
            this.setState({
                is_loading: false
            });
        });
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.user_login_details != this.props.user_login_details) {
            if (this.props.user_login_details.user_data.data.company_id != this.company_details.company_id) {
                this.get_practices();
                this.get_company_details();
                this.form_object.statement_same_as_clinic = this.form_object.stmt_rtn_same_as_clinic = false;
                if (this._is_mounted) {
                    this.setState({
                        form_object: { ...this.form_object }
                    });
                }
            }
        }
    };

    componentWillUnmount = () => {
        this._is_mounted = false;
        document.body.classList.remove('admin-framework');
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[2] !== 'locations' && session_storage.remove('locations_search_criteria');
        }
        session_storage.remove('location_claim_data_loaded');
    };

    for_revequip_or_software_only_service_type = (data) => {
        return data.company_service_type == 1 || data.company_service_type == 3;
    }

    is_required_statement_header_company_details = (data) => {
        return data.statement_header_info == this.STATEMENT_HEADER_INFORMATION.USE_LOCATION
            && this.for_revequip_or_software_only_service_type(data);
    }

    is_disabled_statement_header_company_details = (data) => {
        return data.statement_header_info == this.STATEMENT_HEADER_INFORMATION.USE_COMPANY
            && this.for_revequip_or_software_only_service_type(data);
    }

    // Get the data on the basis of ID.
    get_data = code_id => {
        let url = this.page_metadata.api.get.url;
        url = url.replace('id_value', code_id);
        if (this._is_mounted) {
            this.setState({
                is_loading: true
            });
        }
        constants_actions.get_data(this.token, url).then(
            async (response) => {
                if (response.data && response.data.data) {
                    this.form_object = { ...response.data.data };
                    this.initial_form_object = { ...this.form_object };
                    this.update_required_field();
                    if (this.form_object.logo_image_index_id > 0) {
                        await this.get_logo_image(this.form_object.logo_image_index_id);
                    }
                    if (this._is_mounted) {
                        this.setState({
                            form_object: this.form_object,
                            is_loading: false
                        });
                    }
                }
            },
            (error) => {
                if (this._is_mounted) {
                    this.setState({
                        is_loading: false
                    });
                }
                if (error.response && error.response.data && error.response.data.messages) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error('', toastr_options);
                }
            }
        );
    };

    // Get the practices data on the basis of ID.
    get_practices = () => {
        constants_actions.get_search_data(this.token, `${this.page_metadata.api.practice.url}`).then(
            res => {
                if (res.data) {
                    if (this._is_mounted) {
                        this.setState({
                            practices: option_formatter(
                                res.data.data,
                                'practice_name',
                                'practice_id',
                                false, // to add blank object
                                false // to shorted object
                            ),
                            is_loading: false
                        });
                    }
                }
            },
            error => {
                if (this._is_mounted) {
                    this.setState({
                        is_loading: false
                    });
                }
                if (error.response && error.response.data && error.response.data.messages) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error('', toastr_options);
                }
            }
        );
    };

    // Get the company details data on the basis of ID.
    get_company_details = () => {
        constants_actions.get_company_details(this.token, this.props.user_login_details.user_data.data.company_id).then(
            res => {
                if (res.data) {
                    this.company_details = { ...res.data };
                    this.company_initial_details = Object.keys(res.data).reduce((object, key) => {
                        if (key !== 'company_id') {
                            object[key] = res.data[key];
                        }
                        return object;
                    }, {});
                    if (this._is_mounted) {
                        this.setState({
                            is_loading: false
                        });
                    }
                }
            },
            error => {
                if (this._is_mounted) {
                    this.setState({
                        is_loading: false
                    });
                }
                if (error.response && error.response.data && error.response.data.messages) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error('', toastr_options);
                }
            }
        );
    };

    //////////////////////////////Image Related//////////////////////////////

    get_logo_image = async logo_image_index_id => {
        await constants_actions
            .open_image(this.token, `${this.page_metadata.api.logo.url}/${logo_image_index_id}`)
            .then(
                (res) => {
                    if (res.data && res.data) {
                        this.image_formate = 'data:image/bmp;base64';
                        this.image_preview = this.arrayBufferToBase64(res.data.data);
                        this.initial_form_object = { ...this.form_object };
                    } else {
                        if (this._is_mounted) {
                            this.setState({
                                logo_loading: false
                            });
                        }
                    }
                },
                (error) => {
                    if (error.response && error.response.data && error.response.data.messages) {
                        const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                        toaster.error('', toastr_options);
                    }
                }
            )
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.messages) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error('', toastr_options);
                }
            });
    };

    // Image upload handler.
    upload_logo = () => {
        let input: any = this.refs.input_reader;
        input.click();
    };

    // Image remove handler.
    remove_logo = () => {
        this.form_object.image_base64 = null;
        this.form_object.logo_image_index_id = null;
        this.image_preview = null;
        if (this._is_mounted) {
            this.setState(prev_state => ({
                form_object: this.form_object
            }));
        }
        document.getElementById('remove_logo_id').blur();
    };

    // Convert array buffer to base64
    arrayBufferToBase64(arrayBuffer) {
        return btoa(new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    }

    // Input File handler.
    file_handler = e => {
        const { name, files } = e.target;
        var is_valid_mime;
        if (this._is_mounted) {
            this.setState({
                logo_loading: true
            });
        }
        constants.loadMime(files[0], (mime, is_valid, mime_type, file) => {
            //print the output to the screen
            is_valid_mime = is_valid;
            if (is_valid_mime) {
                this.form_object.logo_image_index_id = this.initial_form_object.logo_image_index_id;
                let reader = new FileReader();
                let self = this;
                this.form_object.image_name = file.name;
                this.form_object.logo_image_index_id = null;
                reader.onload = (r: any) => {
                    this.image_preview = this.form_object[name] = r.target.result.split(',')[1];
                    this.image_formate = mime_type;
                    if (this._is_mounted) {
                        self.setState(prev_state => ({
                            form_object: this.form_object,
                            logo_loading: false
                        }));
                    }
                };
                reader.readAsDataURL(file);
                document.getElementById('upload_logo_id').blur();
                this.is_image_updated = true;
            } else {
                toaster.error('', 'Only gif, jpg and png files can be uploaded');
            }
        });
    };

    //////////////////////////////Image Related End//////////////////////////////

    // Input change handler.
    input_change_handler = e => {
        const { value, name } = e.target;
        let error = this.state.error;
        let required = this.state.required;
        let system_setting_required = this.state.system_setting_required;
        if (name.indexOf('zip') > -1) {
            let field_value = value && value.length > 0 && value.replace(/[^0-9]/g, '');
            required[name] = !value;
            system_setting_required[name] = !value;
            if (!isNaN(field_value)) {
                error[name] = this.validate_value(field_value, name);
                if (error[name]) {
                    required[name] = this.required_field[name] && true;
                    system_setting_required[name] = this.system_setting_required_field[name] && true;
                }
                if (this.form_object['pos_identifier'] == '12') {
                    required['zip'] = false;
                } else {
                    required['zip'] = !this.form_object['zip'];
                }
            }
        } else if (e.target.validity.valid) {
            required[name] = !value;
            let new_field_value = value && value.length > 0 && value.replace(/[^0-9]/g, '');
            if (name == "statement_phone" && new_field_value.length == 0) {
                system_setting_required[name] = true;
            } else {
                system_setting_required[name] = !value;
            }
            error[name] = this.validate_value(value, name);
            if (error[name]) {
                required[name] = this.required_field[name] && true;
                system_setting_required[name] = this.system_setting_required_field[name] && true;
            }
        }
        if (this.same_as_location.indexOf(name) > -1) {
            this.form_object.statement_same_as_clinic = false;
        } else if (this.return_same_as_location.indexOf(name) > -1) {
            this.form_object.stmt_rtn_same_as_clinic = false;
        }
        if (this._is_mounted) {
            this.setState({
                form_object: this.form_object,
                error: error,
                required: required,
                system_setting_required: system_setting_required
            });
        }
    };

    // Checkbox checkbox handler.
    checkbox_handler = e => {
        const { checked, name } = e.target;
        const {
            statement_company_name,
            address1,
            city,
            zip,
            state,
            statement_Phone,
            stmt_phone_ext
        } = this.company_details;

        let location_details =
        {
            statement_company_name: this.form_object["pos_name"],
            address1: this.form_object["address1"],
            city: this.form_object["city"],
            zip: this.form_object["zip"],
            state: this.form_object["state"],
            phone: this.form_object["phone"],
        };

        const { system_setting_required } = this.state;

        this.form_object[name] = JSON.parse(checked);
        if ((name === 'statement_same_as_clinic' || name === 'stmt_rtn_same_as_clinic') && checked === true) {
            let error = this.state.error;
            for (let name in this.form_object) {
                if (this.required_field[name] && !this.form_object[name]) {
                }
                error[name] = this.validate_value(this.form_object[name], name);
            }

            if (name === 'statement_same_as_clinic') {
                this.form_object = {
                    ...this.form_object,
                    statement_name: location_details.statement_company_name,
                    statement_address: location_details.address1,
                    statement_city: location_details.city,
                    statement_state: location_details.state,
                    statement_zip: location_details.zip,
                    statement_phone: location_details.phone
                };

                // removing validations on statement detail fields if correct value is copied
                system_setting_required["statement_name"] = location_details.statement_company_name && location_details.statement_company_name.length > 0 ? false : system_setting_required["statement_name"];
                system_setting_required["statement_address"] = location_details.address1 && location_details.address1.length > 0 ? false : system_setting_required["statement_address"];
                system_setting_required["statement_city"] = location_details.city && location_details.city.length > 0 ? false : system_setting_required["statement_city"];
                system_setting_required["statement_state"] = location_details.state && location_details.state.length > 0 ? false : system_setting_required["statement_state"];
                system_setting_required["statement_zip"] = location_details.zip && location_details.zip.length > 0 ? false : system_setting_required["statement_zip"];
                system_setting_required["statement_phone"] = location_details.phone && location_details.phone.length > 0 ? false : system_setting_required["statement_phone"];

            } else if (name === 'stmt_rtn_same_as_clinic') {
                this.form_object = {
                    ...this.form_object,
                    stmt_rtn_name: statement_company_name,
                    stmt_rtn_address: address1,
                    stmt_rtn_city: city,
                    stmt_rtn_state: state,
                    stmt_rtn_zip: zip
                };

                // removing validations on statement detail fields if correct value is copied
                system_setting_required["stmt_rtn_name"] = statement_company_name && statement_company_name.length > 0 ? false : system_setting_required["stmt_rtn_name"];
                system_setting_required["stmt_rtn_address"] = address1 && address1.length > 0 ? false : system_setting_required["stmt_rtn_address"];
                system_setting_required["stmt_rtn_city"] = city && city.length > 0 ? false : system_setting_required["stmt_rtn_city"];
                system_setting_required["stmt_rtn_state"] = state && state.length > 0 ? false : system_setting_required["stmt_rtn_state"];
                system_setting_required["stmt_rtn_zip"] = zip && zip.length > 0 ? false : system_setting_required["stmt_rtn_zip"];
            }
        }
        if (this._is_mounted) {
            this.setState(prev_state => ({
                form_object: this.form_object,
                system_setting_required: system_setting_required
            }));
        }
    };

    // Dropdown change handler.
    dropdown_change_handler = e => {
        const { value, name } = e.target;
        let required = this.state.required;
        let system_setting_required = this.state.system_setting_required;

        if (name == "statement_state" || name == "stmt_rtn_state") {
            system_setting_required[name] = value.trim() == "" ? true : !value;
        } else {
            system_setting_required[name] = !value;
        }
        required[name] = !value;
        this.form_object[name] = value == ' ' ? null : value;
        if (name == 'pos_identifier') {
            const address_required = this.update_required_field();
            required = {
                ...required,
                address1: address_required && !this.state.form_object['address1'] ? true : false,
                city: address_required && !this.state.form_object['city'] ? true : false,
                state: address_required && !this.state.form_object['state'] ? true : false,
                zip: address_required && !this.state.form_object['zip'] ? true : false
            };
        } else if (this.same_as_location.indexOf(name) > -1) {
            this.form_object.statement_same_as_clinic = false;
        } else if (this.return_same_as_location.indexOf(name) > -1) {
            this.form_object.stmt_rtn_same_as_clinic = false;
        } else if (name == "statement_state") {
            system_setting_required.statement_state = false;
        } else if (name == "stmt_rtn_state") {
            system_setting_required.stmt_rtn_state = false;
        }
        if (this._is_mounted) {
            this.setState({
                form_object: {
                    ...this.form_object
                },
                required: required,
                system_setting_required: system_setting_required
            });
        }
    };

    // validate each input box on change event
    validate_value = (value, name) => {
        var error = false;
        switch (name) {
            case 'phone':
            case 'statement_phone':
            case 'fax':
                value = value && value.length > 0 && value.replace(/[^0-9]/g, '');
                error = !!value && value.length > 0 && value.length != 10;
                this.form_object[name] = value && value.length > 0 && unmask(value);
                break;
            case 'zip':
            case 'statement_zip':
            case 'stmt_rtn_zip':
                value = value && value.length > 0 && value.replace(/[^0-9]/g, '');
                error = !!value && ((value.length > 0 && value.length < 5) || (value.length > 5 && value.length < 9));
                this.form_object[name] = value;
                break;
            case 'pos_code':
                value = value && value.trim();
                error = value.length > 0 && value.length < 3;
                this.form_object[name] = value;
                break;
            case 'npi':
                if (!isNaN(value)) {
                    value = value && value.trim();
                    error = value && value.length > 0 ? !check_valid_npi(value) : false;
                    this.form_object[name] = value;
                } else {
                    error = true;
                }
                break;
            //  Non Required Field
            case 'tax_id':
                if (!isNaN(value)) {
                    value = value && value.trim();
                    error = !!value && value.length > 0 && value.length < 9;
                    this.form_object[name] = value && value.trim();
                } else {
                    error = true;
                }
                break;
            default:
                this.form_object[name] = value;
                return false;
        }
        return error;
    };

    accordion_toggle = (type: React.ReactText) => {
        if (this._is_mounted) {
            this.setState((prev_state) => ({
                controls_is_expended: {
                    ...prev_state.controls_is_expended,
                    [type]: !prev_state.controls_is_expended[type]
                }
            }));
        }
    };

    on_unsaved_claims = (row_num, is_unsave) => {
        if (is_unsave && this.state.unsaved_claim_rows.indexOf(row_num) == -1) {
            this.setState(state => ({
                unsaved_claim_rows: [...state.unsaved_claim_rows, row_num]
            }))
        } else if (!is_unsave && this.state.unsaved_claim_rows.indexOf(row_num) != -1) {
            this.setState(state => ({
                unsaved_claim_rows: state.unsaved_claim_rows.filter(val => val !== row_num)
            }))
        }
    }

    render_claim_hold_rows = () => {
        if (this.state.claim_hold_rows && this.state.claim_hold_rows.length > 0) {
            return this.state.claim_hold_rows.map((item, index) => {
                if (this.claim_hold_selectedItems[index] == undefined) {
                    this.claim_hold_selectedItems[index] = {
                        providers: item.provider_ids,
                        insuranceClass: item.insurance_class_ids,
                        insuranceCode: item.insurance_code_ids
                    };
                }
                return this.state.show_inactive_claim ? (
                    <ProviderBillingDisplayRowComponent
                        key={index}
                        data={item}
                        onClickCallbackHandler={this.handleLocationClaimRowClick}
                        onClickBadgeItemHandler={this.onClickClaimBadgeItemHandler}
                        column_fields={this.state.claim_hold_col_fields}
                        row_index={index}
                        providers={this.claim_hold_selectedItems[index].providers}
                        insuranceClass={this.claim_hold_selectedItems[index].insuranceClass}
                        insuranceCode={this.claim_hold_selectedItems[index].insuranceCode}
                        onRef={(ref) => (this.rowNodesForClaims[index] = ref)}
                        on_unsave_billing={this.on_unsaved_claims}
                        disable_elements={this.disable_fields}
                        claim_delay_reason={this.state.claim_delay_reason}
                        row_type={"claim"}
                    />
                ) : (
                    item.status && (
                        <ProviderBillingDisplayRowComponent
                            key={index}
                            data={item}
                            onClickCallbackHandler={this.handleLocationClaimRowClick}
                            onClickBadgeItemHandler={this.onClickClaimBadgeItemHandler}
                            column_fields={this.state.claim_hold_col_fields}
                            row_index={index}
                            providers={this.claim_hold_selectedItems[index].providers}
                            insuranceClass={this.claim_hold_selectedItems[index].insuranceClass}
                            insuranceCode={this.claim_hold_selectedItems[index].insuranceCode}
                            onRef={(ref) => (this.rowNodesForClaims[index] = ref)}
                            on_unsave_billing={this.on_unsaved_claims}
                            disable_elements={this.disable_fields}
                            claim_delay_reason={this.state.claim_delay_reason}
                            row_type={"claim"}
                        />
                    )
                );
            });
        }
        return this.retrieveDefaultProviderClaimHoldRow();
    }

    retrieveDefaultProviderClaimHoldRow = () => {
        const columnsCount = this.retrieveProviderClaimHoldsColumns().length;
        return (
            <tr>
                <td colSpan={columnsCount}>
                    <p className='ui-grid-label-empty'>TEXT</p>
                </td>
            </tr>
        );
    };

    retrieveProviderClaimHoldsColumns = () => {
        return billingPaneConstants.location_claim_holds_columns;
    }

    // Update required field based on claim code and Place of Service Identifier
    update_required_field = () => {
        let address_required = true;
        if (this.form_object['pos_identifier'] == '12') {
            address_required = false;
        }
        this.required_field = {
            ...this.required_field,
            address1: address_required,
            city: address_required,
            state: address_required,
            zip: address_required
        };

        return address_required;
    };

    // Save the form object and redirect it to search
    save_handler = (e, from_pop_up) => {
        this.setState({
            is_submitted: true,
            is_loading: true,
            is_save_button_disabled: true,
            isSaveInProgress: true
        });
        let is_error = false;
        this.go_next = false;
        let error = this.state.error;
        let required = this.state.required;
        let system_setting_required = this.state.system_setting_required;

        let location_claim_data = this.get_all_updated_claim_data();

        for (let name in this.form_object) {
            this.form_object[name] =
                this.form_object[name] && typeof this.form_object[name] !== 'boolean'
                    ? this.form_object[name].toString().trim()
                    : this.form_object[name];

            if (this.required_field[name] && !this.form_object[name]) {
                required[name] = true;
                is_error = true;
            }

            if (this.required_statement_header_company_details && this.system_setting_required_field[name] && !this.form_object[name]) {
                system_setting_required[name] = true;
                is_error = true;
            }

            error[name] = this.validate_value(this.form_object[name], name);

            if (error[name]) {
                required[name] = this.required_field[name] && true;
                is_error = true;
            }
        }

        if (this._is_mounted) {
            this.setState({
                required: required,
                system_setting_required: system_setting_required,
                error: error
            });
        }

        if (is_error) {
            toaster.error('', constants.mandatory_fields);
            this.setState({
                is_loading: false,
                by_pass_prompt: !from_pop_up ? true : false,
                is_save_button_disabled: false
            });
            return (is_error = true);
        }

        // Check Field Validation
        for (let key in this.state.error) {
            if (this.state.error[key]) {
                toaster.error('', constants.mandatory_fields);
                is_error = true;
            }
        }
        if (is_error) {
            this.setState({
                is_loading: false,
                by_pass_prompt: !from_pop_up ? true : false,
                is_save_button_disabled: false
            });
            return;
        } else {
            this.form_object['is_incomplete_rf3'] = false;
        }
        if (this._is_mounted) {
            this.setState({
                is_loading: true,
                is_save_button_disabled: true
            });
        }

        session_storage.set('locations_search_criteria', this.locations_search_criteria);
        this.form_object.statement_phone_ext = !is_null_or_white_space(this.form_object.statement_phone_ext) ? this.form_object.statement_phone_ext : null;
        if (this.code_id) {
            //Update
            let url = this.page_metadata.api.update.url;
            url = url.replace('id_value', this.code_id);
            if (!this.is_image_updated) {
                this.form_object.image_base64 = null;
            } else {
                this.form_object.logo_image_index_id = null;
            }
            constants_actions.update_data(this.token, url, this.form_object).then(
                response => {
                    if (response.data && response.data.data) {

                        if (this.props.features && this.props.features.LocationClaimHolds) {
                            // update location claim holds
                            constants_actions.add_location_claim_hold(this.token, this.code_id, location_claim_data).then(
                                response => {
                                    if (!response.data) {
                                        if (response.data.messages[0].message.indexOf('<br') > -1) {
                                            this.show_html_content_toaster(response.data.messages[0].message);
                                        } else {
                                            toaster.error('', response.data.messages[0].message);
                                        }
                                    }
                                },
                                error => {
                                    if (error.response && error.response.data && error.response.data.messages) {
                                        const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                                        toaster.error('', toastr_options);
                                    }
                                }
                            );
                        }
                        this.go_next = true;
                        toaster.success('', this.page_metadata.validation_message.update);
                        this.initial_form_object = { ...this.form_object };
                        if (this._is_mounted) {
                            this.setState({
                                is_loading: false,
                                by_pass_prompt: !from_pop_up ? true : false,
                                is_save_button_disabled: false
                            });
                        }

                        if (!from_pop_up && this.go_next) {
                            this.go_next = false;
                            let last_report_navigation = local_storage.get('last_report_navigation');
                            local_storage.remove('last_report_navigation');
                            if (!last_report_navigation) {
                                this.props.history.goBack()
                            } else {
                                this.props.history.push(last_report_navigation);
                            }
                        }
                    } else {
                        if (response.data.messages[0].message.indexOf('<br') > -1) {
                            this.show_html_content_toaster(response.data.messages[0].message);
                        } else {
                            toaster.error('', response.data.messages[0].message);
                        }
                        this.go_next = false;
                        if (this._is_mounted) {
                            this.setState({
                                is_loading: false,
                                is_save_button_disabled: false
                            });
                        }
                    }
                },
                error => {
                    if (this._is_mounted) {
                        this.go_next = false;
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false
                        });
                    }
                    if (error.response && error.response.data && error.response.data.messages) {
                        const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                        toaster.error('', toastr_options);
                    }
                }
            );
        } else {
            //Add
            let url = this.page_metadata.api.add.url;
            constants_actions.add_data(this.token, url, this.form_object).then(
                response => {
                    if (response.data && response.data.data) {
                        this.go_next = true;
                        toaster.success('', this.page_metadata.validation_message.save);
                        this.initial_form_object = { ...this.form_object };
                        if (this._is_mounted) {
                            this.setState({
                                is_loading: false,
                                by_pass_prompt: !from_pop_up ? true : false,
                                is_save_button_disabled: false
                            });
                        }
                        if (!from_pop_up && this.go_next) {
                            this.go_next = false;
                            let last_report_navigation = local_storage.get('last_report_navigation');
                            local_storage.remove('last_report_navigation');
                            if (!last_report_navigation) {
                                this.props.history.goBack()
                            } else {
                                this.props.history.push(last_report_navigation);
                            }
                        }
                    } else {
                        if (response.data.messages[0].message.indexOf('<br') > -1) {
                            this.show_html_content_toaster(response.data.messages[0].message);
                        } else {
                            toaster.error('', response.data.messages[0].message);
                        }
                        this.go_next = false;
                        if (this._is_mounted) {
                            this.setState({
                                is_loading: false,
                                is_save_button_disabled: false
                            });
                        }
                    }
                },
                error => {
                    if (this._is_mounted) {
                        this.go_next = false;
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false
                        });
                    }
                    if (error.response && error.response.data && error.response.data.messages) {
                        const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                        toaster.error('', toastr_options);
                    }
                }
            );
        }
    };

    // Function used to Run Report based on controllers values.
    cancel_handler = () => {
        this.by_pass_prompt = true;
        this.setState(
            {
                by_pass_prompt: true
            },
            () => {
                let last_report_navigation = local_storage.get('last_report_navigation');
                local_storage.remove('last_report_navigation');
                if (!last_report_navigation) {
                    this.props.history.goBack()
                } else {
                    this.props.history.push(last_report_navigation);
                }
            }
        );
    };

    // Show multiple messages
    show_html_content_toaster = msg => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ),
            timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        };
    };

    claim_hold_from_drawer_callback = (incomingPayload) => {
        this.setState({
            isClaimDrawerOpen: false
        });
        let old_data = this.get_all_updated_claim_data();

        if (this.state.openPanelMode.isModeEditable) {
            incomingPayload.hasChanges = this.state.openPanelMode.isModeEditable;
            old_data[this.state.locationClaimHold.originalRowIndex] = incomingPayload;
            this.claim_hold_selectedItems[this.state.locationClaimHold.originalRowIndex] = {
                providers: incomingPayload.provider_ids,
                insuranceClass: incomingPayload.insurance_class_ids,
                insuranceCode: incomingPayload.insurance_code_ids
            };
        }
        if (this.state.openPanelMode.isModeCreate) {
            old_data.push({ ...incomingPayload });
            this.claim_hold_selectedItems[old_data.length - 1] = {
                providers: incomingPayload.provider_ids,
                insuranceClass: incomingPayload.insurance_class_ids,
                insuranceCode: incomingPayload.insurance_code_ids
            };
            this.setState((state) => ({
                unsaved_claim_rows: [...old_data, old_data.length - 1]
            }));
        }

        this.setState((state) => ({
            claim_hold_rows: old_data,
            claim_hold_rows_original: [...old_data]
        }));
        session_storage.set('location_claim_data_loaded', old_data);
    }

    generateFirstCallsPromises = () => {
        this.getLocationClaimsListPromise = new Promise((resolve, reject) => {
            this.getLocationsClaimsListPromiseResolve = resolve;
            this.getProviderClaimsListPromiseReject = reject;
        });
        this.retrieveProvidersAndInsurancesPromise = new Promise((resolve, reject) => {
            this.retrieveProvidersAndInsurancesPromiseResolve = resolve;
            this.retrieveProvidersAndInsurancesPromiseReject = reject;
        });
        this.retrievePendingReasonPromise = new Promise((resolve, reject) => {
            this.retrievePendingReasonPromiseResolve = resolve;
            this.retrievePendingReasonPromiseReject = reject;
        });
    };

    get_all_updated_data = () => {
        return this.state.billing_rows;
    }

    get_all_updated_claim_data = () => {
        return this.state.claim_hold_rows;
    }

    get_location_card_data = code_id => {
        let url = this.page_metadata.api.get.url;
        url = url.replace('id_value', code_id);
        if (this._is_mounted) {
            this.setState({
                is_loading: true
            });
        }
        constants_actions.get_data(this.token, url).then(
            async (response) => {
                if (response.data && response.data.data) {
                    this.form_object = { ...response.data.data };
                    this.initial_form_object = { ...this.form_object };
                    if (this._is_mounted) {
                        this.setState({
                            form_object: this.form_object,
                        });
                    }
                }
            },
            (error) => {
                if (this._is_mounted) {
                    this.setState({
                        is_loading: false
                    });
                }
                if (error.response && error.response.data && error.response.data.messages) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error('', toastr_options);
                }
            }
        );
    };

    get_location_card_practice_select_data = () => {
        if (this._is_mounted) {
            this.setState({
                is_loading: true
            });
        }
        constants_actions.get_search_data(this.token, `${this.page_metadata.api.practice.url}`).then(
            res => {
                if (res.data) {
                    if (this._is_mounted) {
                        this.setState({
                            practices: option_formatter(
                                res.data.data,
                                'practice_name',
                                'practice_id',
                                false, // to add blank object
                                false // to shorted object
                            )
                        });
                    }
                }
            },
            error => {
                if (this._is_mounted) {
                    this.setState({
                        is_loading: false
                    });
                }
                if (error.response && error.response.data && error.response.data.messages) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error('', toastr_options);
                }
            }
        );
    };

    get_claimhold_reason = () => {
        let claim_delay_reason = [{
            "key": 0,
            "value": 31,
            "text": "Pending Location Credentialing"
        }];

        this.setState({
            claim_delay_reason: claim_delay_reason
        });
    }

    get_location_claim_list = (code_id: Number) => {
        this.apiPromisesCollector.push(this.getLocationClaimsListPromise);

        constants_actions.get_location_claim_hold(this.token, this.code_id).then(
            (response) => {
                this.setState({
                    claim_hold_rows: Array.isArray(response.data.data) ? response.data.data : [],
                    claim_hold_rows_original: Array.isArray(response.data.data) ? [...response.data.data] : []
                });
                this.getLocationsClaimsListPromiseResolve('ok');
            },
            (error) => {
                this.getLocationsClaimsListPromiseResolve('error');
            });
    };

    on_column_moved = (id, cols) => {
        this.setState({
            [`${id}_col_fields`]: cols
        });
    }

    on_sort_changed = (id, rows) => {
        this.setState({
            [`${id}_rows`]: rows
        });
        this[`${id}_selectedItems`] = [];
    }

    confirm_close_handler = () => {
        if (this._is_mounted) {
            set_focus_on_element_with_id("save_location_claim_btn");
            this.setState({
                confirm_box_open: false
            });
        }
    };

    confirm_handler = e => {
        this.claim_hold_from_drawer_callback(this.incomingPayload)
        if (this._is_mounted) {
            this.setState({
                confirm_box_open: false
            });
        }
    };

    new_claim_handler = () => {
        this.setState({
            openPanelMode: {
                isModeEditable: false,
                isModeCreate: true
            }
        });
        this.unbindClaimRowData();
        this.handleDrawerClaimOpen();
    };

    show_inactive = (e) => {
        const { name, checked } = e.target;
        this.setState({
            [name]: checked
        });
    };



    private bindClaimRowData = (selectedRowData, selectedRowIndex) => {
        this.setState({
            locationClaimHold: {
                originalRowData: selectedRowData,
                originalRowIndex: selectedRowIndex
            }
        });
    };

    private getClaimHoldDrawerComponent = () => {
        return (
            <ClaimHoldsAddEditDrawerComponent
                save_disable={this.is_new_claim_button_disable}
                saveHandlerCallback={this.locationClaimSaveCallback}
                {...this.state} />
        );
    };

    private handleDrawerClaimClose = () => this.setState({ isClaimDrawerOpen: false });
    private handleDrawerClaimOpen = () => this.setState({ isClaimDrawerOpen: true });

    private handleLocationClaimRowClick = (e, selectedRowData, selectedRowIndex) => {
        this.setState({
            openPanelMode: {
                isModeEditable: true,
                isModeCreate: false
            }
        });
        this.bindClaimRowData(selectedRowData, selectedRowIndex);
        this.handleDrawerClaimOpen();
    }

    private onClickClaimBadgeItemHandler = (itemId) => {
        this.setState({
            claimTabId: itemId
        });
    }

    private locationClaimSaveCallback = (incomingPayload) => {
        this.validator.setStrategy(new ConcreteStrategyClaimHoldNameUnique());
        if (
            !this.validator.isValid([
                incomingPayload,
                this.state.locationClaimHold.originalRowIndex,
                this.state.claim_hold_rows
            ])
        ) {
            toaster.error('', billingPaneConstants.settingNameUniqueValidationLocationClaimHoldError);
            return;
        }

        this.validator.setStrategy(new ConcreteStrategyOnlyProviderInGroups());
        if (this.validator.isValid(incomingPayload)) {
            this.incomingPayload = incomingPayload;
            this.setState({
                confirm_box_open: true
            });
            return;
        }

        this.validator.setStrategy(new ConcreteStrategySingleDataInGroupsLocationClaimHold());
        if (!this.validator.isValid(incomingPayload)) {
            toaster.error('', billingPaneConstants.dataInGroupsValidationErrorLocationClaimHold);
            return;
        }


        this.claim_hold_from_drawer_callback(incomingPayload);
    }

    private retrievePendingReasons = async () => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;

        let claim_delay_reason = [];

        claim_delay_reason = await constants_actions.get_provider_pending_reason(token).then(res => {
            if (res.data.data != null && res.data.data.length > 0) {
                return res.data.data.map((val, index) => {
                    return { key: index, value: val.pending_reason_id, text: val.pending_reason_desc }
                });
            }
        }, (error) => {
            this.retrievePendingReasonPromiseReject('error');
            return;
        });
        claim_delay_reason.reverse();
        this.setState({
            claim_delay_reason
        }, () => {
            this.retrievePendingReasonPromiseResolve('ok');
        })
    }

    private retrieveProvidersAndInsurancesForMultiTab = async () => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        const params = { page_size: global_constants.constants.PAGE_SIZE };

        this.apiPromisesCollector.push(this.retrieveProvidersAndInsurancesPromise);
        await get_insurance_codes(token)
            .then(response => {
                this.setState({
                    insuranceCodes: response.data.data
                });
            }, (error) => {
                this.retrieveProvidersAndInsurancesPromiseReject('error');
            })
            .then(async () => {
                var url = '/constant/providers/search?provider_code=&first_name=&last_name=&show_inactive=false&page_Size=5000';
                await constants_actions.get_search_data(this.token, url).then(
                    response => {
                        if (response.data) {
                            let mapped_provider_code_data = response.data.data.map((item, index) => {
                                return {
                                    provider_id: item.provider_id,
                                    provider_code: Number(item.provider_code),
                                    description: item.description
                                };
                            });
                            this.setState({
                                providers: mapped_provider_code_data
                            });
                        }
                    })
            }, (error) => {
                this.retrieveProvidersAndInsurancesPromiseReject('error');
            })
            .then(async () => {
                await advanced_search_class(params, token)
                    .then(response => {
                        this.setState({
                            insuranceClasses: response.data.data
                        });
                        this.retrieveProvidersAndInsurancesPromiseResolve('ok');
                    });
            }, (error) => {
                this.retrieveProvidersAndInsurancesPromiseReject('error');
            });
    }

    private unbindClaimRowData = () => {
        this.setState({
            locationClaimHold: {
                originalRowData: this.claim_model
            },
            claimTabId: ''
        });
        this.setState({
            providerClaimHold: {
                originalRowData: this.claim_model
            },
            claimTabId: ''
        });
    }

    // It renders report grid and its search controls.
    render() {
        const { is_submitted, error, required, system_setting_required, controls_is_expended } = this.state;
        return this.state.show_render && (
            <React.Fragment>
                <Modal
                    closeIcon
                    centered={false}
                    closeOnDimmerClick={false}
                    open={this.state.confirm_box_open}
                    onClose={this.confirm_close_handler}
                    className='confirm_box default-modal'
                    id={'confirm_box'}
                >
                    <Modal.Header>Confirm</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Grid>
                                <Grid.Column>
                                    <p>{billingPaneConstants.onlyProviderInGroupsValidationErrorClaimHold}</p>
                                </Grid.Column>
                            </Grid>
                            <Grid>
                                <Grid.Column computer={16} textAlign='right'>
                                    <Button type='cancel' basic onClick={this.confirm_close_handler}>
                                        Cancel
                                    </Button>
                                    <Button type='button' id="confirm_button_confirm_box_window" onKeyDown={(e) => {
                                    }} onClick={this.confirm_handler} primary>
                                        Continue
                                    </Button>
                                </Grid.Column>
                            </Grid>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
                {!this.state.by_pass_prompt && (
                    <PromptNavigation
                        is_data_changed={JSON.stringify(this.initial_form_object) !== JSON.stringify(this.form_object)}
                        save={e => this.save_handler(e, true)}
                        go_next_location={this.go_next}
                        history={this.props.history}
                    />
                )}
                <Dimmer active={this.state.is_loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <div className={'admin-wrapper view-edit Locations ' + this.page_metadata.id} style={location_css.locations_id}>
                    <Grid className='headerGrid' style={location_css.headerGrid}>
                        <Grid.Column computer={16}>
                            <h3 className='ui header left aligned'>{this.page_metadata.add_header}</h3>
                        </Grid.Column>
                    </Grid>
                    <div className='wrapper' style={location_css.wrapper}>
                        <Form autoComplete='off' name='view_edit_form' className='common-forms' style={location_css.common_forms}>
                            <div id='admin-scrollable-area' style={location_css.scrollbar_area}>
                                {/* Location Information */}
                                <Grid className='common-accordion'>
                                    <Grid.Column
                                        computer={16}
                                        tablet={16}
                                        textAlign='left'
                                        className='accordionColumn'
                                        id='accordion-column'
                                    >
                                        <Accordion fluid styled>
                                            <Accordion.Title active={controls_is_expended.location} index={0} onClick={() => this.accordion_toggle('location')}>
                                                <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name='angle right' />
                                                Location Information
                                            </Accordion.Title>
                                            <Accordion.Content active={controls_is_expended.location} style={location_css.accordion_content}>
                                                <div id='search-criteria-container'>
                                                    <Grid>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field className={is_submitted && required.pos_code ? 'requiredWithBgColor error' : ''}>
                                                                <label>
                                                                    Location Code{' '}
                                                                    <span className={is_submitted && required.pos_code ? 'req-alert' : 'req-alert_normal'}>
                                                                        (required)
                                                                    </span>
                                                                </label>
                                                                <input
                                                                    autoComplete='new-password'
                                                                    name='pos_code'
                                                                    id='pos_code'
                                                                    onChange={e => this.input_change_handler(e)}
                                                                    value={this.form_object.pos_code ? this.form_object.pos_code : ''}
                                                                    type='text'
                                                                    maxLength={5}
                                                                    pattern='[0-9a-zA-Z]*'
                                                                    disabled={this.disable_fields}
                                                                />
                                                                {is_submitted && error.pos_code && <div className='requiredText'>Alphanumeric, 3 to 5.</div>}
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field
                                                                className={
                                                                    this.required_field.pos_name && is_submitted && required.pos_name
                                                                        ? 'requiredWithBgColor error'
                                                                        : ''
                                                                }
                                                            >
                                                                <label>
                                                                    Location Name{' '}
                                                                    <span
                                                                        className={
                                                                            this.required_field.pos_name && is_submitted && required.pos_name
                                                                                ? 'req-alert'
                                                                                : 'req-alert_normal'
                                                                        }
                                                                    >
                                                                        (required)
                                                                    </span>
                                                                </label>
                                                                <input
                                                                    autoComplete='new-password'
                                                                    name='pos_name'
                                                                    id='pos_name'
                                                                    onChange={e => this.input_change_handler(e)}
                                                                    value={this.form_object.pos_name ? this.form_object.pos_name : ''}
                                                                    type='text'
                                                                    maxLength={50}
                                                                    disabled={this.disable_fields}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field
                                                                className={
                                                                    this.required_field.address1 && is_submitted && required.address1
                                                                        ? 'requiredWithBgColor error'
                                                                        : ''
                                                                }
                                                            >
                                                                <label>
                                                                    Address{' '}
                                                                    {this.required_field.address1 && (
                                                                        <span
                                                                            className={
                                                                                this.required_field.address1 && is_submitted && required.address1
                                                                                    ? 'req-alert'
                                                                                    : 'req-alert_normal'
                                                                            }
                                                                        >
                                                                            (required)
                                                                        </span>
                                                                    )}
                                                                </label>
                                                                <input
                                                                    autoComplete='new-password'
                                                                    name='address1'
                                                                    id='address1'
                                                                    onChange={e => this.input_change_handler(e)}
                                                                    value={this.form_object.address1 ? this.form_object.address1 : ''}
                                                                    type='text'
                                                                    maxLength={50}
                                                                    disabled={this.disable_fields}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field
                                                                className={
                                                                    this.required_field.city && is_submitted && required.city ? 'requiredWithBgColor error' : ''
                                                                }
                                                            >
                                                                <label>
                                                                    City{' '}
                                                                    {this.required_field.city && (
                                                                        <span
                                                                            className={
                                                                                this.required_field.city && is_submitted && required.city
                                                                                    ? 'req-alert'
                                                                                    : 'req-alert_normal'
                                                                            }
                                                                        >
                                                                            (required)
                                                                        </span>
                                                                    )}
                                                                </label>
                                                                <input
                                                                    autoComplete='new-password'
                                                                    name='city'
                                                                    id='city'
                                                                    onChange={e => this.input_change_handler(e)}
                                                                    value={this.form_object.city ? this.form_object.city : ''}
                                                                    type='text'
                                                                    maxLength={50}
                                                                    disabled={this.disable_fields}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid>
                                                    <Grid>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field>
                                                                <label>
                                                                    State{' '}
                                                                    {this.required_field.state && (
                                                                        <span
                                                                            className={
                                                                                this.required_field.state && is_submitted && required.state
                                                                                    ? 'req-alert'
                                                                                    : 'req-alert_normal'
                                                                            }
                                                                        >
                                                                            (required)
                                                                        </span>
                                                                    )}
                                                                </label>
                                                                <Selection
                                                                    id='state'
                                                                    name='state'
                                                                    options={option_formatter(
                                                                        this.state.patient_master_data.states,
                                                                        'text',
                                                                        'value',
                                                                        true, // to add blank object
                                                                        false, // to sorted object,
                                                                        'Select'
                                                                    )}
                                                                    onChange={(value, e) => {
                                                                        this.dropdown_change_handler(e);
                                                                    }}
                                                                    defaultValue={this.form_object.state ? this.form_object.state : ''}
                                                                    style={
                                                                        this.required_field.state && is_submitted && required.state
                                                                            ? 'dropdown-options-wrap req-background-inp'
                                                                            : 'dropdown-options-wrap'
                                                                    }
                                                                    disabled={this.disable_fields}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field
                                                                className={
                                                                    is_submitted && required.zip
                                                                        ? 'requiredWithBgColor error' :
                                                                        is_submitted && error.zip
                                                                            ? 'error'
                                                                            : ''
                                                                }
                                                            >
                                                                <label>
                                                                    Zip Code{' '}
                                                                    {this.required_field.zip && (
                                                                        <span className={is_submitted && required.zip ? 'req-alert' : 'req-alert_normal'}>
                                                                            (required)
                                                                        </span>
                                                                    )}
                                                                </label>
                                                                <input
                                                                    autoComplete='new-password'
                                                                    name='zip'
                                                                    value={this.form_object.zip ? zip_code_formation(this.form_object.zip) : ''}
                                                                    onChange={e => this.input_change_handler(e)}
                                                                    id='zip'
                                                                    maxLength={10}
                                                                    pattern='[0-9]*'
                                                                    disabled={this.disable_fields}
                                                                />
                                                                {is_submitted && error.zip && <div className='requiredText'>Zip can be 5 or 9 digits.</div>}
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field className={is_submitted && error.phone ? 'error' : ''}>
                                                                <label>Phone</label>
                                                                <MaskedInput
                                                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                                    className={'form-control'}
                                                                    placeholder='(___) ___-____'
                                                                    guide={true}
                                                                    id='phone'
                                                                    name='phone'
                                                                    autoComplete='new-password'
                                                                    showMask={true}
                                                                    onChange={e => this.input_change_handler(e)}
                                                                    value={this.form_object.phone ? this.form_object.phone : ''}
                                                                    disabled={this.disable_fields}
                                                                />
                                                                {is_submitted && error.phone && <div className='requiredText'>Please enter 10 digits</div>}
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field className={is_submitted && error.fax ? 'error' : ''}>
                                                                <label>Fax</label>
                                                                <MaskedInput
                                                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                                    className={'form-control'}
                                                                    placeholder='(___) ___-____'
                                                                    guide={true}
                                                                    id='fax'
                                                                    name='fax'
                                                                    autoComplete='new-password'
                                                                    showMask={true}
                                                                    onChange={e => this.input_change_handler(e)}
                                                                    value={this.form_object.fax}
                                                                    disabled={this.disable_fields}
                                                                />
                                                                {is_submitted && error.fax && <div className='requiredText'>Please enter 10 digits</div>}
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid>
                                                    <Grid>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field>
                                                                <label>
                                                                    Practice{' '}
                                                                    <span
                                                                        className={
                                                                            this.required_field.practice_id && is_submitted && required.practice_id
                                                                                ? 'req-alert'
                                                                                : 'req-alert_normal'
                                                                        }
                                                                    >
                                                                        (required)
                                                                    </span>
                                                                </label>
                                                                <Selection
                                                                    id='practice_id'
                                                                    name='practice_id'
                                                                    placeHolder={'Select'}
                                                                    hidden={true}
                                                                    options={this.state.practices}
                                                                    onChange={(value, e) => {
                                                                        this.dropdown_change_handler(e);
                                                                    }}
                                                                    defaultValue={this.form_object.practice_id ? this.form_object.practice_id : ''}
                                                                    style={
                                                                        this.required_field.practice_id && is_submitted && required.practice_id
                                                                            ? 'dropdown-options-wrap req-background-inp'
                                                                            : 'dropdown-options-wrap'
                                                                    }
                                                                    disabled={this.disable_fields}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field style={{ marginTop: 32 }}>
                                                                <Checkbox
                                                                    name='dont_show_in_lookups'
                                                                    id='dont_show_in_lookups'
                                                                    onChange={e => this.checkbox_handler(e)}
                                                                    checked={this.form_object.dont_show_in_lookups ? true : false}
                                                                    label={'Exclude From Lookups'}
                                                                    disabled={this.disable_fields}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field style={{ marginTop: 32 }}>
                                                                <Checkbox
                                                                    name='is_active'
                                                                    id='is_active'
                                                                    onChange={e => this.checkbox_handler(e)}
                                                                    checked={this.form_object.is_active ? true : false}
                                                                    label={'Is Active'}
                                                                    disabled={this.disable_fields}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid>
                                                    <Grid>
                                                        <Grid.Column tablet={16} computer={16}>
                                                            <div style={{ display: 'inline-block', textAlign: 'center' }}>
                                                                <Button
                                                                    type='button'
                                                                    id='remove_logo_id'
                                                                    onClick={() => this.remove_logo()}
                                                                    disabled={(this.image_preview ? false : true) || this.disable_fields}
                                                                    basic
                                                                >
                                                                    Remove Logo
                                                                </Button>
                                                                <Button
                                                                    type='button'
                                                                    id='upload_logo_id'
                                                                    onClick={this.upload_logo}
                                                                    basic
                                                                    disabled={this.disable_fields}
                                                                >
                                                                    Upload Logo
                                                                </Button>
                                                                <input
                                                                    type='file'
                                                                    ref='input_reader'
                                                                    accept='image/png,image/gif,image/jpeg,image/jpg'
                                                                    style={{ display: 'none' }}
                                                                    value=''
                                                                    onChange={e => this.file_handler(e)}
                                                                    name='image_base64'
                                                                    id='image_base64'
                                                                    disabled={this.disable_fields}
                                                                />
                                                            </div>
                                                        </Grid.Column>
                                                    </Grid>
                                                    {this.image_preview && (
                                                        <Grid>
                                                            <Grid.Column tablet={16} computer={16} style={{ overflowX: 'auto', overflowY: 'hidden', marginLeft: 0 }}>
                                                                <img
                                                                    src={`${this.image_formate},${this.image_preview}`}
                                                                    alt='practice image'
                                                                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                                                                />
                                                            </Grid.Column>
                                                        </Grid>
                                                    )}
                                                </div>
                                            </Accordion.Content>
                                        </Accordion>
                                    </Grid.Column>
                                </Grid>

                                {/* Location Claim Holds */}
                                {this.props.features && this.props.features.LocationClaimHolds &&
                                    <Grid className='common-accordion'>
                                        <Grid.Column
                                            computer={16}
                                            tablet={16}
                                            textAlign='left'
                                            className='accordionColumn'
                                            id='accordion-column'
                                        >
                                            <Accordion fluid styled>
                                                <Accordion.Title active={controls_is_expended.claim} index={0} onClick={() => this.accordion_toggle('claim')}>
                                                    <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name='angle right' />
                                                    Location Claim Holds
                                                </Accordion.Title>
                                                <Accordion.Content active={controls_is_expended.claim} style={location_css.accordion_content}>
                                                    <Grid style={{ marginTop: '-0.5rem', marginBottom: '-2rem' }}>
                                                        <Grid.Column computer={16} textAlign='left' style={location_css.inactive_column}>
                                                            <Checkbox
                                                                name='show_inactive_claim'
                                                                id='show_inactive_claim'
                                                                onChange={(e) => this.show_inactive(e)}
                                                                checked={this.state.show_inactive_claim}
                                                                label={'Show Inactive'}
                                                                disabled={this.state.claim_hold_rows_original.length <= 0}
                                                            />
                                                        </Grid.Column>
                                                    </Grid>
                                                    <Grid>
                                                        <Grid.Column computer={16}>
                                                            <div className="customtable" id="id_billing_container">
                                                                <div className="table-responsive" id="id_billing_settings_table">
                                                                    <table className="ui table" id="id-table-billing-settings-form">
                                                                        <thead>
                                                                            <ProviderBillingHeaderComponent
                                                                                columns={this.state.claim_hold_col_fields}
                                                                                rows={this.state.claim_hold_rows_original}
                                                                                onSortChanged={this.on_sort_changed}
                                                                                onColumnMoved={this.on_column_moved}
                                                                                id={"claim_hold"}
                                                                            />
                                                                        </thead>
                                                                        <tbody>
                                                                            {this.render_claim_hold_rows()}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </Grid.Column>
                                                    </Grid>
                                                    <Grid>
                                                        <Grid.Column computer={16} textAlign="right" style={location_css.new_button_section}>
                                                            <Button
                                                                id='id_btn_new_row'
                                                                type='button'
                                                                onClick={this.new_claim_handler}
                                                                primary
                                                                style={location_css.new_button}
                                                                content='New'
                                                            />
                                                        </Grid.Column>
                                                    </Grid>
                                                </Accordion.Content>
                                            </Accordion>
                                        </Grid.Column>
                                    </Grid>
                                }
                                {/* Billing Settings */}
                                <Grid className='common-accordion' style={location_css.location_top}>
                                    <Grid.Column
                                        computer={16}
                                        tablet={16}
                                        textAlign='left'
                                        className='accordionColumn'
                                        id='accordion-column'
                                    >
                                        <Accordion fluid styled>
                                            <Accordion.Title active={controls_is_expended.billing} index={0} onClick={() => this.accordion_toggle('billing')}>
                                                <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name='angle right' />
                                                Billing Settings
                                            </Accordion.Title>
                                            <Accordion.Content active={controls_is_expended.billing} style={location_css.accordion_content}>
                                                <Grid>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field className={is_submitted && error.tax_id ? 'error' : ''}>
                                                            <label>Tax Identification Number</label>
                                                            <input
                                                                autoComplete='new-password'
                                                                name='tax_id'
                                                                id='tax_id'
                                                                onChange={e => this.input_change_handler(e)}
                                                                value={this.form_object.tax_id ? this.form_object.tax_id : ''}
                                                                pattern='[0-9]*'
                                                                maxLength={9}
                                                                disabled={this.disable_fields}
                                                            />
                                                            {is_submitted && error.tax_id && <div className='requiredText'>Please enter 9 digits </div>}
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field
                                                            className={
                                                                this.required_field.npi && is_submitted && required.npi ? 'requiredWithBgColor error' : ''
                                                            }
                                                        >
                                                            <label>
                                                                National Provider Identifier{' '}
                                                                <span
                                                                    className={
                                                                        this.required_field.npi && is_submitted && required.npi
                                                                            ? 'req-alert'
                                                                            : 'req-alert_normal'
                                                                    }
                                                                >
                                                                    (required)
                                                                </span>
                                                            </label>
                                                            <input
                                                                autoComplete='new-password'
                                                                name='npi'
                                                                value={this.form_object.npi ? this.form_object.npi : ''}
                                                                onChange={e => this.input_change_handler(e)}
                                                                id='npi'
                                                                pattern='[0-9]*'
                                                                maxLength={10}
                                                                disabled={this.disable_fields}
                                                            />
                                                            {is_submitted && error.npi && (
                                                                <div className='requiredText'>Invalid NPI (Example 1234567893)</div>
                                                            )}
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <label>
                                                                Place of Service Identifier{' '}
                                                                <span
                                                                    className={
                                                                        this.required_field.pos_identifier && is_submitted && required.pos_identifier
                                                                            ? 'req-alert'
                                                                            : 'req-alert_normal'
                                                                    }
                                                                >
                                                                    (required)
                                                                </span>
                                                            </label>
                                                            <Selection
                                                                id='pos_identifier'
                                                                name='pos_identifier'
                                                                placeHolder={'Select'}
                                                                hidden={true}
                                                                options={constants.option_formatter_order_by_sequence(
                                                                    this.state.patient_master_data.pos_ident,
                                                                    'name',
                                                                    'id'
                                                                )}
                                                                onChange={(value, e) => {
                                                                    this.dropdown_change_handler(e);
                                                                }}
                                                                defaultValue={this.form_object.pos_identifier ? this.form_object.pos_identifier : ''}
                                                                style={
                                                                    this.required_field.pos_identifier && is_submitted && required.pos_identifier
                                                                        ? 'dropdown-options-wrap req-background-inp'
                                                                        : 'dropdown-options-wrap'
                                                                }
                                                                disabled={this.disable_fields}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <label>
                                                                Claim Code{' '}
                                                                <span
                                                                    className={
                                                                        this.required_field.claim_code && is_submitted && required.claim_code
                                                                            ? 'req-alert'
                                                                            : 'req-alert_normal'
                                                                    }
                                                                >
                                                                    (required)
                                                                </span>
                                                            </label>
                                                            <Selection
                                                                id='claim_code'
                                                                name='claim_code'
                                                                placeHolder={'Select'}
                                                                hidden={true}
                                                                options={option_formatter(
                                                                    this.state.patient_master_data.claim_code,
                                                                    'name',
                                                                    'id',
                                                                    false, // to add blank object
                                                                    false // to shorted object
                                                                )}
                                                                onChange={(value, e) => {
                                                                    this.dropdown_change_handler(e);
                                                                }}
                                                                defaultValue={this.form_object.claim_code ? this.form_object.claim_code : ''}
                                                                style={
                                                                    this.required_field.claim_code && is_submitted && required.claim_code
                                                                        ? 'dropdown-options-wrap req-background-inp'
                                                                        : 'dropdown-options-wrap'
                                                                }
                                                                disabled={this.disable_fields}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid>
                                                <Grid>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <label>Medicare Type</label>
                                                            <Selection
                                                                id='medicare_type'
                                                                name='medicare_type'
                                                                placeHolder={'Select'}
                                                                hidden={true}
                                                                options={option_formatter(
                                                                    this.state.patient_master_data.medicare_type,
                                                                    'id',
                                                                    'name',
                                                                    true, // to add blank object
                                                                    false // to shorted object
                                                                )}
                                                                onChange={(value, e) => {
                                                                    this.dropdown_change_handler(e);
                                                                }}
                                                                defaultValue={this.form_object.medicare_type ? this.form_object.medicare_type : ''}
                                                                style={'dropdown-options-wrap'}
                                                                disabled={this.disable_fields}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <label>Default Provider Group Number</label>
                                                            <input
                                                                autoComplete='new-password'
                                                                name='default_prov_grp_num'
                                                                id='default_prov_grp_num'
                                                                onChange={e => this.input_change_handler(e)}
                                                                value={this.form_object.default_prov_grp_num ? this.form_object.default_prov_grp_num : ''}
                                                                type='text'
                                                                maxLength={50}
                                                                disabled={this.disable_fields}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <label>Submitter ID</label>
                                                            <input
                                                                autoComplete='new-password'
                                                                name='submitter_id'
                                                                id='submitter_id'
                                                                onChange={e => this.input_change_handler(e)}
                                                                value={this.form_object.submitter_id ? this.form_object.submitter_id : ''}
                                                                type='text'
                                                                maxLength={250}
                                                                disabled={this.disable_fields}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field style={{ marginTop: 32 }}>
                                                            <Checkbox
                                                                name='chg_entry_supply_only'
                                                                id='chg_entry_supply_only'
                                                                onChange={e => this.checkbox_handler(e)}
                                                                checked={this.form_object.chg_entry_supply_only ? true : false}
                                                                label={'Supply Only Charge Entry'}
                                                                disabled={this.disable_fields}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid>
                                                <Grid>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <Checkbox
                                                                name='apply_tax_billing'
                                                                id='apply_tax_billing'
                                                                onChange={e => this.checkbox_handler(e)}
                                                                checked={this.form_object.apply_tax_billing ? true : false}
                                                                label={'Apply Tax'}
                                                                disabled={this.disable_fields}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid>
                                            </Accordion.Content>
                                        </Accordion>
                                    </Grid.Column>
                                </Grid>

                                {/* Statement Settings */}
                                <Grid className='common-accordion' style={location_css.location_top}>
                                    <Grid.Column
                                        computer={16}
                                        tablet={16}
                                        textAlign='left'
                                        className='accordionColumn'
                                        id='accordion-column'
                                    >
                                        <Accordion fluid styled>
                                            <Accordion.Title active={controls_is_expended.statement} index={0} onClick={() => this.accordion_toggle('statement')}>
                                                <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name='angle right' />
                                                Statement Settings
                                            </Accordion.Title>
                                            <Accordion.Content active={controls_is_expended.statement} style={location_css.accordion_content}>
                                                <Grid>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field style={{ marginTop: 32 }}>
                                                            <Checkbox
                                                                name='statement_same_as_clinic'
                                                                id='statement_same_as_clinic'
                                                                onChange={e => this.checkbox_handler(e)}
                                                                checked={this.form_object.statement_same_as_clinic ? true : false}
                                                                label={'Same as Location'}
                                                                disabled={this.disabled_statement_header_company_details || this.disable_fields}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field className={this.required_statement_header_company_details && is_submitted && system_setting_required.statement_name ?
                                                            'requiredWithBgColor error' : ''}>
                                                            <label>Name{this.required_statement_header_company_details ?
                                                                <span className={system_setting_required.statement_name &&
                                                                    is_submitted ? "req-alert" : 'req-alert_normal'}> (required)
                                                                </span> : ""
                                                            }</label>
                                                            <input
                                                                autoComplete='new-password'
                                                                name='statement_name'
                                                                id='statement_name'
                                                                onChange={e => this.input_change_handler(e)}
                                                                value={this.form_object.statement_name ? this.form_object.statement_name : ''}
                                                                type='text'
                                                                maxLength={40}
                                                                disabled={this.disabled_statement_header_company_details || this.disable_fields}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field className={this.required_statement_header_company_details && is_submitted && system_setting_required.statement_address ?
                                                            'requiredWithBgColor error' : ''}>
                                                            <label>Address{this.required_statement_header_company_details ?
                                                                <span className={system_setting_required.statement_address &&
                                                                    is_submitted ? "req-alert" : 'req-alert_normal'}> (required)
                                                                </span> : ""
                                                            }</label>
                                                            <input
                                                                autoComplete='new-password'
                                                                name='statement_address'
                                                                id='statement_address'
                                                                onChange={e => this.input_change_handler(e)}
                                                                value={this.form_object.statement_address ? this.form_object.statement_address : ''}
                                                                type='text'
                                                                maxLength={45}
                                                                disabled={this.disabled_statement_header_company_details || this.disable_fields}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field className={this.required_statement_header_company_details && is_submitted && system_setting_required.statement_city ?
                                                            'requiredWithBgColor error' : ''}>
                                                            <label>City{this.required_statement_header_company_details ?
                                                                <span className={system_setting_required.statement_city &&
                                                                    is_submitted ? "req-alert" : 'req-alert_normal'}> (required)
                                                                </span> : ""
                                                            }</label>
                                                            <input
                                                                autoComplete='new-password'
                                                                name='statement_city'
                                                                id='statement_city'
                                                                onChange={e => this.input_change_handler(e)}
                                                                value={this.form_object.statement_city ? this.form_object.statement_city : ''}
                                                                type='text'
                                                                maxLength={35}
                                                                disabled={this.disabled_statement_header_company_details || this.disable_fields}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid>
                                                <Grid>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <label>State{this.required_statement_header_company_details ?
                                                                <span className={system_setting_required.statement_state &&
                                                                    is_submitted ? "req-alert" : 'req-alert_normal'}> (required)
                                                                </span> : ""
                                                            }</label>
                                                            <Selection
                                                                id='statement_state'
                                                                name='statement_state'
                                                                options={option_formatter(
                                                                    this.state.patient_master_data.states,
                                                                    'text',
                                                                    'value',
                                                                    true, // to add blank object
                                                                    false, // to sorted object,
                                                                    'Select'
                                                                )}
                                                                onChange={(value, e) => {
                                                                    this.dropdown_change_handler(e);
                                                                }}
                                                                defaultValue={this.form_object.statement_state ? this.form_object.statement_state : ''}
                                                                style={
                                                                    this.required_statement_header_company_details && is_submitted && system_setting_required.statement_state
                                                                        ? 'dropdown-options-wrap req-background-inp'
                                                                        : 'dropdown-options-wrap'
                                                                }
                                                                disabled={this.disabled_statement_header_company_details || this.disable_fields}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field className={is_submitted ?
                                                            this.required_statement_header_company_details &&
                                                                (system_setting_required.statement_zip || error.statement_zip) ?
                                                                'requiredWithBgColor error' :
                                                                error.statement_zip ?
                                                                    'error' :
                                                                    '' : ''}>
                                                            <label>ZIP Code{this.required_statement_header_company_details ?
                                                                <span className={(system_setting_required.statement_zip || error.statement_zip) &&
                                                                    is_submitted ? "req-alert" : 'req-alert_normal'}> (required)
                                                                </span> : ""
                                                            }</label>
                                                            <input
                                                                autoComplete='new-password'
                                                                name='statement_zip'
                                                                value={
                                                                    this.form_object.statement_zip ? zip_code_formation(this.form_object.statement_zip) : ''
                                                                }
                                                                onChange={e => this.input_change_handler(e)}
                                                                id='statement_zip'
                                                                maxLength={10}
                                                                pattern='[0-9]*'
                                                                disabled={this.disabled_statement_header_company_details || this.disable_fields}
                                                            />
                                                            {is_submitted && (error.statement_zip || system_setting_required.statement_zip) && this.form_object.statement_zip &&
                                                                this.form_object.statement_zip.length > 0 && (
                                                                    <div className='requiredText'>Zip can be 5 or 9 digits.</div>
                                                                )}
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field className={this.required_statement_header_company_details && is_submitted &&
                                                            (system_setting_required.statement_phone || error.statement_phone) ? "requiredWithBgColor error" : ''}>
                                                            <label>Phone{this.required_statement_header_company_details ?
                                                                <span className={system_setting_required.statement_phone &&
                                                                    is_submitted ? "req-alert" : 'req-alert_normal'}> (required)
                                                                </span> : ""
                                                            }</label>
                                                            <MaskedInput
                                                                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                                className={this.required_statement_header_company_details && system_setting_required.statement_phone && is_submitted ? 'red-error-thin form-control' : 'form-control'}
                                                                placeholder='(___) ___-____'
                                                                guide={true}
                                                                id='statement_phone'
                                                                name='statement_phone'
                                                                autoComplete='new-password'
                                                                showMask={true}
                                                                onChange={e => this.input_change_handler(e)}
                                                                value={this.form_object.statement_phone ? this.form_object.statement_phone : ''}
                                                                disabled={this.disabled_statement_header_company_details || this.disable_fields}
                                                            />
                                                            {is_submitted && (system_setting_required.statement_phone || error.statement_phone) &&
                                                                this.form_object.statement_phone &&
                                                                this.form_object.statement_phone.length > 0
                                                                && (
                                                                    <div className='requiredText'>Please enter 10 digits</div>
                                                                )}
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <label>Phone Extension</label>
                                                            <input
                                                                autoComplete='new-password'
                                                                name='statement_phone_ext'
                                                                id='statement_phone_ext'
                                                                onChange={e => this.input_change_handler(e)}
                                                                value={this.form_object.statement_phone_ext ? this.form_object.statement_phone_ext : ''}
                                                                type='text'
                                                                maxLength={4}
                                                                disabled={this.disabled_statement_header_company_details || this.disable_fields}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid>
                                                <Grid>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field style={{ marginTop: 32 }}>
                                                            <Checkbox
                                                                name='stmt_rtn_same_as_clinic'
                                                                id='stmt_rtn_same_as_clinic'
                                                                onChange={e => this.checkbox_handler(e)}
                                                                checked={this.form_object.stmt_rtn_same_as_clinic ? true : false}
                                                                label={'Return Address Same as Location'}
                                                                disabled={this.disabled_statement_header_company_details || this.disable_fields}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field className={this.required_statement_header_company_details && is_submitted && system_setting_required.stmt_rtn_name ? 'requiredWithBgColor error' : ''}>
                                                            <label>Return Address Name{this.required_statement_header_company_details && <span className={is_submitted && system_setting_required.stmt_rtn_name ? 'req-alert' : 'req-alert_normal'}>
                                                                {' '}(required)
                                                            </span>}</label>
                                                            <input
                                                                autoComplete='new-password'
                                                                name='stmt_rtn_name'
                                                                id='stmt_rtn_name'
                                                                onChange={e => this.input_change_handler(e)}
                                                                value={this.form_object.stmt_rtn_name ? this.form_object.stmt_rtn_name : ''}
                                                                type='text'
                                                                maxLength={50}
                                                                disabled={this.disabled_statement_header_company_details || this.disable_fields}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field className={this.required_statement_header_company_details && is_submitted && system_setting_required.stmt_rtn_address ? 'requiredWithBgColor error' : ''}>
                                                            <label>Return Address{this.required_statement_header_company_details && <span className={is_submitted && system_setting_required.stmt_rtn_address ? 'req-alert' : 'req-alert_normal'}>
                                                                {' '}(required)
                                                            </span>}</label>
                                                            <input
                                                                autoComplete='new-password'
                                                                name='stmt_rtn_address'
                                                                id='stmt_rtn_address'
                                                                onChange={e => this.input_change_handler(e)}
                                                                value={this.form_object.stmt_rtn_address ? this.form_object.stmt_rtn_address : ''}
                                                                type='text'
                                                                maxLength={50}
                                                                disabled={this.disabled_statement_header_company_details || this.disable_fields}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field className={this.required_statement_header_company_details && is_submitted && system_setting_required.stmt_rtn_city ? 'requiredWithBgColor error' : ''}>
                                                            <label>Return Address City {this.required_statement_header_company_details && <span className={is_submitted && system_setting_required.stmt_rtn_city ? 'req-alert' : 'req-alert_normal'}>
                                                                (required)
                                                            </span>}</label>
                                                            <input
                                                                autoComplete='new-password'
                                                                name='stmt_rtn_city'
                                                                id='stmt_rtn_city'
                                                                onChange={e => this.input_change_handler(e)}
                                                                value={this.form_object.stmt_rtn_city ? this.form_object.stmt_rtn_city : ''}
                                                                type='text'
                                                                maxLength={50}
                                                                disabled={this.disabled_statement_header_company_details || this.disable_fields}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid>
                                                <Grid>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <label>Return Address State {this.required_statement_header_company_details &&
                                                                <span className={this.required_statement_header_company_details && is_submitted && system_setting_required.stmt_rtn_state ? 'req-alert' : 'req-alert_normal'}>
                                                                    {' '}(required)
                                                                </span>}
                                                            </label>
                                                            <Selection
                                                                id='stmt_rtn_state'
                                                                name='stmt_rtn_state'
                                                                options={option_formatter(
                                                                    this.state.patient_master_data.states,
                                                                    'text',
                                                                    'value',
                                                                    true, // to add blank object
                                                                    false, // to sorted object,
                                                                    'Select'
                                                                )}
                                                                onChange={(value, e) => {
                                                                    this.dropdown_change_handler(e);
                                                                }}
                                                                defaultValue={this.form_object.stmt_rtn_state ? this.form_object.stmt_rtn_state : ''}
                                                                style={
                                                                    this.required_statement_header_company_details && is_submitted && system_setting_required.stmt_rtn_state
                                                                        ? 'dropdown-options-wrap req-background-inp'
                                                                        : 'dropdown-options-wrap'
                                                                }
                                                                disabled={this.disabled_statement_header_company_details || this.disable_fields}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field className={is_submitted ?
                                                            this.required_statement_header_company_details &&
                                                                (system_setting_required.stmt_rtn_zip || error.stmt_rtn_zip) ?
                                                                'requiredWithBgColor error' :
                                                                error.stmt_rtn_zip ?
                                                                    'error' :
                                                                    '' : ''}>
                                                            <label>Return Address ZIP Code{this.required_statement_header_company_details && <span className={this.required_statement_header_company_details && is_submitted && system_setting_required.stmt_rtn_zip ? 'req-alert' : 'req-alert_normal'}>
                                                                {' '}(required)
                                                            </span>}</label>
                                                            <input
                                                                autoComplete='new-password'
                                                                name='stmt_rtn_zip'
                                                                value={
                                                                    this.form_object.stmt_rtn_zip ? zip_code_formation(this.form_object.stmt_rtn_zip) : ''
                                                                }
                                                                onChange={e => this.input_change_handler(e)}
                                                                id='stmt_rtn_zip'
                                                                maxLength={10}
                                                                pattern='[0-9]*'
                                                                disabled={this.disabled_statement_header_company_details || this.disable_fields}
                                                            />
                                                            {is_submitted && (error.stmt_rtn_zip || system_setting_required.stmt_rtn_zip) &&
                                                                this.form_object.stmt_rtn_zip &&
                                                                this.form_object.stmt_rtn_zip.length > 0 && (
                                                                    <div className='requiredText'>Zip can be 5 or 9 digits.</div>
                                                                )}
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={8}>
                                                        <Form.Field>
                                                            <label>Statement Note</label>
                                                            <TextareaAutosize
                                                                style={{ height: 60 }}
                                                                name='statement_note'
                                                                id='statement_note'
                                                                autoComplete='new-password'
                                                                maxLength={250}
                                                                value={this.form_object.statement_note ? this.form_object.statement_note : ''}
                                                                onChange={e => this.input_change_handler(e)}
                                                                disabled={this.disabled_statement_header_company_details || this.disable_fields}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid>
                                            </Accordion.Content>
                                        </Accordion>
                                    </Grid.Column>
                                </Grid>
                            </div>
                        </Form>
                    </div>
                    <div
                        className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                        id='applicationFooterSticky'
                        style={location_css.footerArea}
                    >
                        <Button id='cancel_button_id' type='button' onClick={() => this.cancel_handler()} basic>
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            onKeyDown={set_focus_to_app_header}
                            onClick={e => this.save_handler(e, false)}
                            id='save_button_id'
                            primary
                            style={location_css.save_button}
                            disabled={this.state.is_save_button_disabled}
                        >
                            Save
                        </Button>
                    </div>
                </div>
                {this.state.isClaimDrawerOpen && <DrawerComponent
                    key={this.props.key_id}
                    canOutsideClickClose={false}
                    canEscapeKeyClose={false}
                    isOpen={this.state.isClaimDrawerOpen}
                    className="ext-bp-drawer provider-drawer billing-provider-drawer claim-provider-drawer"
                    handleDrawerClose={this.handleDrawerClaimClose}
                    position={Position.RIGHT}
                    title=""
                    isCloseButtonShown={true}
                    innerContent={this.getClaimHoldDrawerComponent()}
                    size={Drawer.SIZE_STANDARD}
                />}
            </React.Fragment>
        );
    }
}

// Css Style
const location_css = {
    locations_id: {
        paddingBottom: 0
    },
    headerGrid: {
        marginTop: 0,
        marginBottom: 0,
        paddingRight: 15
    },
    wrapper: {
        borderTop: 0,
        marginBottom: 0
    },
    common_forms: {
        paddingTop: 0,
        height: '100%',
        paddingBottom: 0,
        display: 'flex',
        flexDirection: 'column'
    } as React.CSSProperties,

    patient_search_form: {
        paddingBottom: 0,
        flexBasis: 'auto',
        borderColor: '#cccccc',
        borderStyle: 'solid',
        borderWidth: '2px 1px 1px',
        margin: '0 0 15px 0px',
        background: '#fff',
        padding: '25px 15px'
    },
    footerArea: {
        paddingLeft: 0,
        paddingBottom: 0,
        marginBottom: 12,
        paddingRight: 25,
        paddingTop: 20
    },
    scrollbar_area: {
        height: '100%',
        // display: 'flex',
        // flexDirection: 'column',
        paddingRight: 15
    } as React.CSSProperties,
    save_button: {
        marginRight: 0
    },
    location_top: {
        marginTop: 5
    },
    accordion_content: {
        padding: 0
    },
    inactive_column: {
        marginBottom: '-1rem'
    },
    new_button: {
        marginRight: 0
    },
    new_button_section: {
        marginTop: -10
    },
};

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
        shared_details: state.shared_details,
        features: {
            LocationClaimHolds: get_launch_darkly_key_value(state.launch_darkly, FF.leviathan_bill_3434_location_credential_claim_holds_r6)
        }
    };
};

export default connect(mapStateToProps)(LocationViewEditComponent);
