import * as React from 'react';
import { toastr } from 'react-redux-toastr';
import { Button, Dimmer, Checkbox, Form, Grid, Header, Loader, Modal, Table, TableBody } from "semantic-ui-react";

class IPPermissionComponent extends React.PureComponent<any, any> {
    [x: string]: any;
    _is_mounted = false;
    ip_access = {
        ip_address_id: 0,
        ip_address: "",
        is_revoking: false,
        is_enabled: this.props.default_enabled|| false,
        is_selected: false,
        is_active: true,
        is_invalid:false
    }
    //Get innitalise data when component created
    constructor(props) {
        super(props);
        this.state = {
            ip_address_list: this.props.ip_list ||[],
            select_all_ip_address: false,
            show_delete_confirm_modal_popup:false
        }
    }

    componentDidMount() {
        this._is_mounted = true;
        if (this.props.onRef) {
            this.props.onRef(this);
        }
        
    }
    componentWillUnmount() {
        this._is_mounted = false;
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.get_parent_ip_list && this.props.ip_list && this.props.ip_list.length>0) {
            if (this._is_mounted) {
                this.setState({
                    ip_address_list: this.props.ip_list
                })
            }
        }
    }

    // Checkbox check change function
    change_ip_permission = (e, value, index) => {

        let data = [...this.state.ip_address_list];
        data[index].is_enabled = value.checked;
        if (this._is_mounted) {
            this.setState({
                ip_address_list: data
            })
        }
    }

    change_revoking_permission = (e, value, index) => {

        let data = [...this.state.ip_address_list];
        data[index].is_revoking = value.checked;
        if (this._is_mounted) {
            this.setState({
                ip_address_list: data
            })
        }
    }

    change_ip_address = (e, value, index) => {
        let data = [...this.state.ip_address_list];
        data[index].ip_address = value.value;
        data[index]['is_invalid'] = false;

        if (data[index].ip_address == "::1") {
            data[index]['is_invalid'] = false;
        } else {
            if (data[index].ip_address == '' && data[index].ip_address_id>0) {
                var valid = /^(\d{1,3}|\*)\.(\d{1,3}|\*)\.(\d{1,3}|\*)\.(\d{1,3}|\*)$/.test(data[index].ip_address);
                if (!valid) {
                    data[index]['is_invalid'] = true;
                } else {
                    data[index]['is_invalid'] = false;
                }
            } else if (data[index].ip_address != '' && (data[index].ip_address_id >0)) {
                var valid = /^(\d{1,3}|\*)\.(\d{1,3}|\*)\.(\d{1,3}|\*)\.(\d{1,3}|\*)$/.test(data[index].ip_address);
                if (!valid) {
                    data[index]['is_invalid'] = true;
                } else {
                    data[index]['is_invalid'] = false;
                }
            }
            else if (data[index].ip_address != '' && (data[index].ip_address_id == 0 || data[index].ip_address_id =='')) {
                var valid = /^(\d{1,3}|\*)\.(\d{1,3}|\*)\.(\d{1,3}|\*)\.(\d{1,3}|\*)$/.test(data[index].ip_address);
                if (!valid) {
                    data[index]['is_invalid'] = true;
                } else {
                    data[index]['is_invalid'] = false;
                }
            }
            
        }
        
        if (this._is_mounted) {
            this.setState({
                ip_address_list: data
            })
        }
    }

    add_ip_row = () => {
        var old_ip_list = [...this.state.ip_address_list];
        //var is_valid_ip_list = true;
        //var all_row = old_ip_list.filter((item, index) => {
        //    if (item.is_active && !item.ip_address) {
        //        if (!item.ip_address) {
        //            item['is_invalid'] = true;
        //        } else {
        //            if (item.ip_address=="::1") {
        //                item['is_invalid'] = false;
        //            } else {
        //                var valid = /^(\d{1,3}|\*)\.(\d{1,3}|\*)\.(\d{1,3}|\*)\.(\d{1,3}|\*)$/.test(item.ip_address);
        //                if (!valid) {
        //                    item['is_invalid'] = true;
        //                } else {
        //                    item['is_invalid'] = false;
        //                }
        //            }
        //        }
        //    }
        //    return item;
        //});

        //var invalid_row = all_row.filter((item) => {
        //    if (item.is_active && item['is_invalid'] == true) {
        //        return item;
        //    }
        //})

        //if (invalid_row.length == 0) {
        //    is_valid_ip_list= true;
        //} else {
        //    console.log('invalid row');
        //    is_valid_ip_list= false;
        //}

        //if (is_valid_ip_list) {
        //    old_ip_list.push({...this.ip_access });            
        //} 

        old_ip_list.push({ ...this.ip_access });
        var show_all_chb = this.state.select_all_ip_address;
        if (this._is_mounted) {
            this.setState({
                ip_address_list: old_ip_list,
                select_all_ip_address: false
            })
        }
    }

    show_remove_ip_confirm_popup = () => {
        var data = [...this.state.ip_address_list];
        var selected_data = data.filter((item, index) => {
            if (item.is_active && item["is_selected"]) {
                return item;
            }            
        });
        if (selected_data.length>0) {
            if (this._is_mounted) {
                this.setState({
                    show_delete_confirm_modal_popup: true,
                }, () => {
                    let el: HTMLElement = document.querySelector('#invalidIPErrorPopup .close');
                    if (el) {
                        el.setAttribute('tabIndex', '0');
                        el.addEventListener("keydown", function (event) {
                            if (!event.shiftKey && (event.keyCode == 9 ||  event.which == 9)) {
                                event.preventDefault();
                                let search_button = document.getElementById("btnInvalidipErrorCancel");
                                search_button.focus();
                            }
                            else if (event.shiftKey && (event.keyCode == 9 || event.which == 9)) {
                                event.preventDefault();
                                let search_button = document.getElementById("btnInvalidipErrorOK");
                                search_button.focus();
                            }
                            else if (event.keyCode == 13 || event.which == 9) {
                                event.preventDefault();
                                var el = event.target as HTMLElement
                                el.click();
                            }
                        })
                    }
                    });
            }
        } else {
            toastr.error('', 'Please select an IP Address.');
        }        
    }

    remove_ip_row = () => {
        var data = [...this.state.ip_address_list];
        var active_data = data.map((item, index) => {
            if (item.is_active && item["is_selected"]) {
                item.is_active = false;
            }
            return item;
        });
        if (this._is_mounted) {
            this.setState({
                ip_address_list: active_data,
                select_all_ip_address: false
            }, () => {
                let el: HTMLElement = document.querySelector('#remove_ip_btn');
                if (el) {
                    el.focus();
                }
            })
        }
    }

    select_ip_address_row = (e, value, index) => {
        var data = [...this.state.ip_address_list];
        data[index]["is_selected"] = value.checked;
        var select_all = false;
        var not_selected_Data = data.filter((item, index) => {
           if (item.is_active && !item["is_selected"]) {
               return item;
            }
        });
        if (not_selected_Data.length==0) {
            select_all = true;
        }

        if (this._is_mounted) {
            this.setState({
                ip_address_list: data,
                select_all_ip_address: select_all
            })
        }
    }

    select_all_ip_address_row = (e, value) => {
        var data = [...this.state.ip_address_list];
        if (data.length > 0) {
            data= data.map((item, index) => {
                item["is_selected"] = value.checked;
                return item;
            });

        }

        if (this._is_mounted) {
            this.setState({
                ip_address_list: data,
                select_all_ip_address: value.checked
            })
        }
    }

    validate_ip_address_list = () => {
        var old_ip_list = [...this.state.ip_address_list];
        var all_row = old_ip_list.map((item, index) => {
            if (item.is_active) {
                item['is_invalid'] = false;

                if (!item.ip_address && item.ip_address_id >0) {
                    item['is_invalid'] = true;
                    
                } else if (item.ip_address && item.ip_address_id > 0 && item.ip_address != "::1") {
                    var valid = /^(\d{1,3}|\*)\.(\d{1,3}|\*)\.(\d{1,3}|\*)\.(\d{1,3}|\*)$/.test(item.ip_address);
                    if (!valid) {
                        item['is_invalid'] = true;

                    }

                } else {
                    if (item.ip_address != "::1") {
                        if (!item.ip_address && item.ip_address_id == 0) {
                            item['is_invalid'] = false;
                        }
                        else if (item.ip_address && item.ip_address_id == 0) {
                            var valid = /^(\d{1,3}|\*)\.(\d{1,3}|\*)\.(\d{1,3}|\*)\.(\d{1,3}|\*)$/.test(item.ip_address);
                            if (!valid) {
                                item['is_invalid'] = true;

                            }
                        } 
                    }                                        
                }                
            }
            return item;
        });
        if (this._is_mounted) {
            this.setState({
                ip_address_list: all_row
            })
        }
        var invalid_row = all_row.filter((item) => {
            if ((item.ip_address_id > 0 && item['is_invalid'] == true)
                || (item.is_active && item['is_invalid'] == true && item.ip_address !='' &&  (item.ip_address_id == 0 || item.ip_address_id == '' ))) {
                return item;
            }            
        })

        if (invalid_row.length == 0) {
            return true;
        } else {
            console.log('invalid row');
            return false;
        }
    }

    get_update_ip_address_list = () => {
        var update_list = this.state.ip_address_list.filter((item) => {
            if (item.ip_address_id > 0 || (item.is_active && item.ip_address != '' && (item.ip_address_id == 0 || item.ip_address_id == '') ) ) {
                return item;
            }            
        })
        return update_list;
    }

    close_delete_confirm_modal = () => {
        if (this._is_mounted) {
            this.setState({
                show_delete_confirm_modal_popup: false,
            });
        }
    }

    ok_delete_confirm_modal = () => {
        if (this._is_mounted) {
            this.setState({
                show_delete_confirm_modal_popup: false,
            }, (() => {
                    this.remove_ip_row();
                //this.reset_after_save(this.state.charge.case_id)
            }));
        }
    }

    cancel_delete_confirm_modal = () => {
        this.close_delete_confirm_modal();
    }

    ok_tab_focus_event = (e,id = '') => {        
        if (!e.shiftKey && (e.keyCode == 9 || e.which == 9)) {
            let el: HTMLElement = document.querySelector('#invalidIPErrorPopup .close');
            if (el) {
                el.focus();
                e.preventDefault();
            }
        }
        else if (e.shiftKey && (e.keyCode == 9 || e.which == 9)) {
            let el: HTMLElement = document.querySelector('#btnInvalidipErrorCancel');
            if (el) {
                el.focus();
                e.preventDefault();
            }
        }
    }

    cancel_tab_focus_event = (e, id = '') => {
        if (e.shiftKey && (e.keyCode == 9 || e.which == 9)) {
            let el: HTMLElement = document.querySelector('#invalidIPErrorPopup .close');
            if (el) {
                el.focus();
                e.preventDefault();
            }
        }
    }

    //Render function
    render() {
        return (
            <React.Fragment >
                <Grid className="form patient-search-form comapny_details_sub_section mar-t-5 "
                    style={this.props.additional_style ? this.props.additional_style : {}}>
                <Grid.Column id="ip_access_section" computer={16} className="">
                    <Header as='h2' textAlign="left" className="section-heading-mini_gray">
                        {this.props.title}
                            </Header>
                    <Grid>
                        <Grid.Column tablet={16} computer={16} className="ip_access_table_container">
                            <div className="customtable">
                                <div className='table-responsive'>
                                    <Table id={"ip_access_section_table"} className="charge_review_patient_info">
                                        <Table.Header>
                                            <Table.Row key={'ip_access_table_header_row'}>
                                                    <Table.HeaderCell style={{ width: '40px', minWidth: '40px', minHeight: '42px' }}>
                                                    <Checkbox 
                                                        id='check_all_ip'
                                                        checked={this.state.select_all_ip_address}
                                                        onChange={(e, value) => this.select_all_ip_address_row(e, value)}
                                                    />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell style={{ width: '40%', minWidth: '40%' }}>IP Address</Table.HeaderCell>
                                                <Table.HeaderCell className="text-center">Is Revoked</Table.HeaderCell>
                                                <Table.HeaderCell className="text-center">Is Enabled</Table.HeaderCell>
                                            </Table.Row>

                                        </Table.Header>
                                        {this.state.ip_address_list.length>0 && <TableBody>
                                            {
                                                this.state.ip_address_list.map((item, index) => {
                                                    if (item.is_active) {
                                                        return (
                                                            <Table.Row key={'ip_' + index}>
                                                                <Table.Cell>
                                                                    <Checkbox
                                                                        id={'check_ip_' + index}
                                                                        checked={item.is_selected ||false}
                                                                        onChange={(e, value) => this.select_ip_address_row(e, value, index)}
                                                                    />
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <Form.Input fluid
                                                                        id={'ip_address' + index}
                                                                        error={item.is_invalid && (this.props.form_submited ||false)}
                                                                        value={item.ip_address}
                                                                        autoComplete='off'
                                                                        onChange={(e, value) => this.change_ip_address(e, value, index)}
                                                                    />
                                                                </Table.Cell>
                                                                <Table.Cell className="text-center">
                                                                    <Checkbox toggle
                                                                        checked={item.is_revoking}
                                                                        id={'is_revoking_' + index}
                                                                        onChange={(e, value) => this.change_revoking_permission(e, value, index)}
                                                                    />
                                                                </Table.Cell>
                                                                <Table.Cell className="text-center">
                                                                    <Checkbox toggle
                                                                        id={'is_enabled_' + index}
                                                                        checked={item.is_enabled}
                                                                        onChange={(e, value) => this.change_ip_permission(e, value, index)}
                                                                    />
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        )
                                                    }
                                                })
                                            }
                                        </TableBody>}
                                    </Table>
                                </div>
                            </div>
                        </Grid.Column>
                        <Grid.Column tablet={16} computer={16} textAlign="right">
                                <Button id="remove_ip_btn" type='button' basic onClick={this.show_remove_ip_confirm_popup} >Delete</Button>
                            <Button id="add_ip_btn" type='button' primary onClick={this.add_ip_row} >Add</Button>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>
            <Modal
              onClose={this.close_delete_confirm_modal}
              centered={false}
              className=' default-modal'
              open={this.state.show_delete_confirm_modal_popup}
              closeIcon={true}
              id='invalidIPErrorPopup'
              closeOnDimmerClick={false}
            >
              <Modal.Header>
                <h3>Confirm</h3>
              </Modal.Header>
              <Modal.Content>
                <Modal.Description>
                  <div className='common-forms' style={{ padding: '0' }}>
                  <p>Are you sure you want to delete selected IP Address?</p>
                  </div>
                  <div className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area padd-r-0'>
                    <Button
                        id='btnInvalidipErrorCancel'
                        type='button'   
                        onKeyDown={this.cancel_tab_focus_event}
                        onClick={() => this.cancel_delete_confirm_modal()}>
                        Cancel
                    </Button>
                        <Button
                      id='btnInvalidipErrorOK'
                      type='button'
                      primary autoFocus={true}
                      onKeyDown ={this.ok_tab_focus_event}
                      onClick={() => this.ok_delete_confirm_modal()}
                    >
                      OK
                    </Button>
                    
                  </div>
                </Modal.Description>
              </Modal.Content>
            </Modal>
          </React.Fragment>
        );
    }
};

export default IPPermissionComponent;