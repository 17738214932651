import * as global_constants from '../../../global_constants';
import { RequestConfig } from '../../../shared/network/config';
import { Request } from '../../../shared/network/request';

export const get_schedules = (token) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.ftp_management.get_schedules}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

export const get_ftp_schedule_by_id = (token, ftp_schedule_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.ftp_management.get_schedules}/${ftp_schedule_id}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

export const add_ftp_schedule = (token, auto_ftp_schedule) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.ftp_management.get_schedules}`;

    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': auto_ftp_schedule
    })

    return request.make(request_config);
}

export const edit_ftp_schedule = (token, ftp_schedule_id, auto_ftp_schedule) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.ftp_management.get_schedules}/${ftp_schedule_id}`;

    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'put',
        'data': auto_ftp_schedule
    })
    return request.make(request_config);
}
