import * as global_constants from './../../global_constants';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request';

//To add a new case
export const add_claim = (token, patient_id, case_obj) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${global_constants.end_points.case_url.case}/add`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': case_obj
    })

    return request.make(requestConfig)


}
export const get_patient_insurance = (patient_id, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.claim_indicator.get_patient_insurance}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            patient_id: patient_id,
        }
    })

    return request.make(request_config);
}

export const get_patient_charges = (criteria, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.claim_indicator.get_charges}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            patient_id: criteria.patient_id,
            ins_code: criteria.ins_code,
            from_date: criteria.from_date,
            to_date: criteria.to_date,
        }
    })

    return request.make(request_config);
}
export const save_charge = (token, charges) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.claim_indicator.save_charge}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': charges
    })

    return request.make(requestConfig);

}
export const get_payer_group = (ins_code, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.claim_indicator.get_payer_group}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            ins_code_id: ins_code,
        }
    })

    return request.make(request_config);
}
export const get_condition_code = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.claim_indicator.get_condition_code}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',       
    })

    return request.make(request_config);
}