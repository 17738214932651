import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import TextareaAutosize from 'react-textarea-autosize';
import { Button, Checkbox, Dimmer, Form, Grid, Loader } from 'semantic-ui-react';
import { export_grid_data, print_grid_data, generateAuditDataEntryPayload } from '../../../reports/util/export_print_utility';
import { insurance_advance_search, insurance_quick_search } from '../../../shared/action/autosearch_action';
import {
  advanced_search_class,
  get_contracts,
  get_insurance_types,
  quick_search_class
} from '../../../shared/action/shared_action';
import AddPrimaryInsurance from '../../../shared/component/add_primary_insurance_component';
import GridView from '../../../shared/component/grid';
import * as local_storage from '../../../shared/local_storage_utility';
import * as session_storage from '../../../shared/session_storage_utility';
import { set_focus_on_element_with_id, set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import ClaimAdjCodeSearch from '../../constants/component/claim_adjustment_code_advanced_search';
import { get_search_data } from '../action/constants_action';
import * as admin_payment_const from '../constants';
import { get_columns } from '../../../shared/utility';
import * as ReportConstants from '../../../reports/report_constants';
import moment = require('moment');

class PayerAlertSearch extends React.Component<any, any> {
  _is_mount = false;
  payer_alert_search: any;
  constructor(props) {
    super(props);
    this.state = {
      patient_id: session_storage.get('active_patient'),
      loading: false,
      control: null,
      search_form: {
        insurance_class_id: '',
        insurance_code_id: '',
        message: '',
        show_inactive: false
      },
      ins_class_search_data: null,
      ins_code_search_data: null,
      ins_code_selected_row: null,
      ins_class_selected_row: null,
      is_focused: true,
      add_primary_insurance_modal: false,
      insurance_types: null,
      insurance_contract: null,
      ins_code_modal: false,
      grid_rows_count: 0,
      is_search_button_disabled: false
    };
    this.ins_class = React.createRef();
    this.ins_code = React.createRef();
  }
  ins_class: React.RefObject<unknown>;
  ins_code: React.RefObject<unknown>;
  ins_code_search_data: any;
  company_name: any;
  current_date: string;
  title: string;
  [x: string]: any;
  empty_search_form = null;
  ins_code_enum = 'ins_code';
  ins_class_enum = 'ins_class';
  page_name: string = '';
  id = 0;
  search = 'search';
  token = '';
  grid_ref = { api: null };
  is_grid_visible = false;
  is_search_ui: any = false;
  search_criteria = {
    insurance_class_id: '',
    insurance_code_id: '',
    message: '',
    show_inactive: false
  };
  is_initial_result = false;
  ins_code_selected_row = {};
  ins_class_selected_row = {};
    total_column_width: number = 0;
    grid_header_height: number = 0;
    reportId: string = "0";
  // Fetching the page_name and state.

  componentDidMount = () => {
    this._is_mount = true;
    this.empty_search_form = this.state.search_form;
    this.get_insurance_master_info();
    this.token = this.props.user_login_details.user_data.data.token_details.access_token;
    document.body.classList.add('admin-framework');
    let elem = document.getElementById('patient-search-criteria-container');
    let all_form_elem = elem.getElementsByTagName('input');
    all_form_elem[0].focus();
    this.handle_search_button_tab();
    let controls = {
      insurance_class: {
        type: 'ins_class',
        grid_config: {
          rows: null,
          column: admin_payment_const.search_class_configuration.column_defs
        },
        control_id: 'INSURANCE_CLASS',
        selected_row: null,
        error_message: 'No Record Found !',
        label: ['insurance_class_code', 'insurance_class_description']
      },
      insurance_code: {
        type: 'ins_code',
        grid_config: {
          rows: null,
          column: admin_payment_const.search_insurance_configuration.column_defs
        },
        control_id: 'TERTIARY_INSURANCE',
        error_message: 'No Record Found !',
        label: ['insurance_code', 'insurance_name', 'address1', 'city', 'state', 'zip'],
        selected_row: null
      }
    };
    if (this._is_mount) {
      this.setState(
        {
          control: controls
        },
        () => this.get_page_metadata()
      );
    }
    this.page_name = this.props.history.location.pathname;
  };

  componentWillUnmount = () => {
    this._is_mount = false;
    document.body.classList.remove('admin-framework');
    if (this.props.history.location) {
      var route_array = this.props.history.location.pathname.split('/');
      route_array && route_array[2] !== 'payer_alert' && session_storage.remove('payer_alert_search');
    }
  };

  //Use to fetch params and page metadata
  get_page_metadata = () => {
    this.token = this.props.user_login_details.user_data.data.token_details.access_token;
    let payer_alert_search = session_storage.get('payer_alert_search');
    this.search_criteria = (payer_alert_search && payer_alert_search.search_criteria) || this.search_criteria;
    this.ins_code_selected_row =
      payer_alert_search && payer_alert_search.ins_code_selected_row
        ? payer_alert_search.ins_code_selected_row
        : this.ins_code_selected_row;

    this.ins_class_selected_row =
      payer_alert_search && payer_alert_search.ins_class_selected_row
        ? payer_alert_search.ins_class_selected_row
        : this.ins_class_selected_row;

    if (payer_alert_search && payer_alert_search.search_criteria) {
      this.setState(
        {
          search_form: { ...this.search_criteria },
          show_render: true
        },
        () => this.search_handler(this)
      );
    }
  };

  on_quick_search = async (params, type) => {
    if (type == this.ins_code_enum) {
      return await insurance_quick_search(params, this.token);
    } else if (type == this.ins_class_enum) {
      let param = { keyword: params };
      return await quick_search_class(param, this.token);
    }
  };

  setFocusToAppHeader = event => {
    // apply a check where grid is empty
    if (
      !event.shiftKey &&
      event.keyCode == '9' &&
      (this.state.grid_data == null || this.state.grid_data.rows.length == 0)
    ) {
      set_focus_to_app_header(event);
    }
  };

  on_advanced_search = async (params, type) => {
    if (this.ins_class_enum == type) {
      await advanced_search_class(params, this.token).then(
        res => {
          let result = res.data ? res.data.data : [];
          if (this._is_mount) {
            this.setState({
              ins_class_search_data: {
                rows: result,
                column: admin_payment_const.search_class_configuration.column_defs
              }
            });
          }
        },
        error => {
          if (error.response && error.response.data && error.response.data.messages) {
            toastr.error('', error.response.data.messages[0].message);
          }
        }
      );
    } else if (this.ins_code_enum == type) {
      params.records = 100;
      await insurance_advance_search(params, this.token).then(
        res => {
          let result = res.data ? res.data.data : [];
          if (this._is_mount) {
            this.ins_code_search_data = {
              rows: result,
              column: admin_payment_const.search_insurance_configuration.column_defs
            };
            this.setState({
              ins_code_search_data: {
                rows: result,
                column: admin_payment_const.search_insurance_configuration.column_defs
              }
            });
          }
        },
        error => {
          if (error.response && error.response.data && error.response.data.messages) {
            toastr.error('', error.response.data.messages[0].message);
          }
        }
      );
    }
  };

  update_data = (data, type) => {
    if (type == this.ins_class_enum) {
      if (this._is_mount) {
        this.ins_class_selected_row = data;
        this.setState({
          search_form: {
            ...this.state.search_form,
            insurance_class_id: data ? data.id : ''
          },
          ins_class_selected_row: data,
          is_focused: false
        });
      }
    } else {
      if (this._is_mount) {
        this.ins_code_selected_row = data;
        this.setState({
          search_form: {
            ...this.state.search_form,
            insurance_code_id: data ? data.id : ''
          },
          ins_code_selected_row: data,
          is_focused: false
        });
      }
    }
  };

  set_title = separter => {
    this.company_name = this.props.user_login_details.user_data.data.company_name;
    this.current_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
    this.title = admin_payment_const.payer_alert_search.report_title;
    return (this.title =
      this.title.replace(/<br>/g, separter) +
      separter +
      this.company_name +
      separter +
      this.current_date +
      separter +
      (this.state.grid_rows_count == 1
        ? this.state.grid_rows_count + ' record'
        : this.state.grid_rows_count + ' records') +
      separter +
      separter);
  };

  on_export = () => {
    this.title = this.set_title('\n');
      export_grid_data(this.grid_ref, this.title, admin_payment_const.payer_alert_search.report_name);
      this.saveReportEventAction(ReportConstants.ReportEventAction.Export);
  };

  on_print = () => {
    this.title = this.set_title('<br>');
      print_grid_data(this.grid_ref, this.title, admin_payment_const.payer_alert_search.report_name);
      this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
  };

  private saveReportEventAction = (eventActionId: number) => {
    const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
    const { user_id, company_id } = this.props.user_login_details.user_data.data;

    const payload: ReportConstants.IPayloadForBillingAudit = {
      reportId: this.reportId,
      contextTitle: `Payer Alerts - ${ReportConstants.ReportEventAction[eventActionId]}`,
      eventActionId: eventActionId,
      userId: Number(user_id),
      companyId: Number(company_id),
      entityTypeId: ReportConstants.ReportEntityTypeId.C_INSURANCE_CLASS,
      data: { Records: this.state.grid_params.api.rowModel.rowsToDisplay.length }
    };

    const reqBody = generateAuditDataEntryPayload(payload);

    ReportConstants.saveAuditPrintExport(reqBody, accessToken);
  };

  search_handler = e => {
    this.setState({ loading: true, grid_data: null, is_search_button_disabled: true });
    const token = local_storage.get('auth_data').token_details.access_token;
    this.search_criteria = { ...this.state.search_form };
    let queryString = Object.keys(this.search_criteria)
      .map(key => key + '=' + this.search_criteria[key].toString().trim())
      .join('&');
    let api = admin_payment_const.payer_alert_search.api.payer_alert_search + '?' + queryString + '&page_size=5000';
    get_search_data(token, api).then(
      res => {
        var result = res.data.data ? res.data.data : [];
        result = result.map((val, index) => {
          val.status = val.status ? 'Active' : 'Inactive';
          return val;
        });
          const grid_height = get_columns(result, admin_payment_const.payer_alert_search.column_def);
          this.total_column_width = grid_height.total_column_width;
          this.grid_header_height = grid_height.header_height;
        var grid_data = {
          rows: result,
          grid_conf: admin_payment_const.payer_alert_search.column_def,
          page_size: admin_payment_const.payer_alert_search.page_size
        };
        if (this._is_mount) {
          this.is_search_ui = true;
          this.setState({
            show_grid: true,
            grid_data: grid_data,
            loading: false,
            is_focused: false,
            grid_rows_count: result,
            is_search_button_disabled: false
          });
          this.is_grid_visible = this.state.show_grid;
        }
        session_storage.set('payer_alert_search', {
          search_criteria: this.search_criteria,
          ins_code_selected_row: this.ins_code_selected_row,
          ins_class_selected_row: this.ins_class_selected_row
        });
      },
      error => {
        if (this._is_mount) {
          this.setState({ 
            loading: false,
            is_search_button_disabled: false
          });
        }
        if (error.response && error.response.data && error.response.data.messages) {
          toastr.error('', error.response.data.messages[0].message);
        }
      }
    );
  };

  //On double click of grid, it redirects user to view page.
  on_row_double_clicked = selected_row_data => {
    this.id = selected_row_data['payer_alert_id'];
    this.props.history.push(`/admin/payer_alert/${this.id}`);
  };

  add_handler = e => {
    e.preventDefault();
    this.props.history.push(`/admin/payer_alert`);
  };

  set_primary_insurance_details = data => {
    if (this._is_mount && data) {
      let row = {};
      row['insurance_code'] = data.insurance_code;
      row['insurance_name'] = data.name;
      row['address1'] = data.address ? data.address.address1 : '';
      row['city'] = data.address ? data.address.city : '';
      row['state'] = data.address ? data.address.state : '';
      row['zip'] = data.address ? data.address.zip : '';
      this.setState({
        ins_code_selected_row: row,
        ins_code_modal: !this.state.ins_code_modal,
        search_form: {
          ...this.state.search_form,
          insurance_code_id: data.id
        }
      });
      this.ins_code_selected_row = row;
    }
  };

  get_insurance_master_info = async () => {
    var ins_type = await get_insurance_types(this.token).then(res => {
      if (res.data.data != null && res.data.data.length > 0) {
        return res.data.data.map((val, index) => {
          return { key: index, value: val.id, text: val.name };
        });
      }
    });

    // Get All insurance types from api
    const available_contracts = await get_contracts(this.token).then(res => {
      if (res.data.data != null && res.data.data.length > 0) {
        return res.data.data;
      }
    });

    if (this._is_mount) {
      this.setState({
        insurance_types: ins_type,
        insurance_contract: available_contracts
      });
    }
  };

  get_grid_ref = grid_params => {
    this.grid_ref = grid_params;
    this.setState({
      grid_params,
      grid_rows_count: grid_params.api.getModel().getRowCount()
    });
    var grid_width = document.getElementsByClassName('admin-wrapper')[0].clientWidth;
    if (this.total_column_width > grid_width) {
        admin_payment_const.payer_alert_search.column_def.filter(item => {
            grid_params.columnApi.setColumnWidth(item.field, item.width, false);
        });
    }
  };

  clear_handle = () => {
    let initial_form = { ...this.empty_search_form };
    if (this._is_mount) {
      this.setState({
        search_form: initial_form,
        grid_data: null,
        show_grid: false,
        ins_code_selected_row: {},
        ins_class_selected_row: {},
        grid_rows_count: 0
      });
      this.is_grid_visible = false;
      session_storage.remove('payer_alert_search');
    }
    this.is_initial_result = false;
    this.ins_code_selected_row = {};
    this.ins_class_selected_row = {};
    this.is_search_ui = false;
    this.is_grid_visible = false;
  };

  on_change_handle = e => {
    let value = e.target.value;
    this.setState(prevState => ({
      search_form: {
        ...prevState.search_form,
        message: value
      }
    }));
  };

  add_primary_insurance = () => {
    if (this._is_mount) {
      this.setState({ add_primary_insurance_modal: !this.state.add_primary_insurance_modal });
    }
  };

  on_grid_out = () => {
    set_focus_on_element_with_id('export_report_button');
  };

  handle_search_button_tab = () => {
    setTimeout(() => {
      let searchButton = document.getElementById('search_button_id');
      let self = this;
      searchButton.addEventListener('keydown', function(event) {
        // apply a check where grid is empty
        if (
          !event.shiftKey &&
          event.keyCode == 9 &&
          (self.state.grid_data == null || (self.state.grid_data.length == 0 && !self.state.show_grid))
        ) {
          set_focus_to_app_header(event);
        }
      });
    }, 200);
  };

  on_close_primary_insurance_modal = () => {
    // setting the focus on Add New Button
    let modalButtons: any = document.querySelectorAll('#bg_fff2');
    if (modalButtons.length > 0) {
      modalButtons[modalButtons.length - 1].focus();
    }
  };

  update_report_rows_count = () => {
    this.setState({
      grid_rows_count: this.state.grid_params.api.getModel().getRowCount()
    });
  };
  render() {
    let rows_count = this.state.grid_rows_count;
    if (typeof this.state.grid_rows_count == 'object') {
      rows_count = this.state.grid_rows_count.length;
    }
    return (
      <React.Fragment>
        <Dimmer active={this.state.loading}>
          <Loader size='massive'>Loading</Loader>
        </Dimmer>
        <AddPrimaryInsurance
          insurance_types={this.state.insurance_types}
          insurance_contract={this.state.insurance_contract}
          set_primary_insurance_details={this.set_primary_insurance_details}
          add_primary_insurance={this.state.add_primary_insurance_modal}
          on_close_modal={this.on_close_primary_insurance_modal}
        />
        <div className='admin-wrapper payer-alert' style={!this.state.show_grid ? { paddingBottom: 20 } : {}}>
          <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
            <Grid.Column computer={16}>
              <h3 className='ui header left aligned' dangerouslySetInnerHTML={{ __html: 'Payer Alerts' }} />
            </Grid.Column>
          </Grid>
          <div id='admin-scrollable-area' className='wrapper' style={this.state.show_grid ? { flex: 1 } : {}}>
            <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container'>
              <Form id='patient-search-criteria-container' autoComplete='off' onSubmit={e => this.search_handler(e)}>
                <Grid>
                  <Grid.Column mobile={8} tablet={8} computer={4}>
                    <Form.Field className='advance-quick-search'>
                      <label>Insurance Class</label>
                      <ClaimAdjCodeSearch
                        control={this.state.control ? this.state.control.insurance_class : null}
                        update_data={this.update_data}
                        search_result={this.state.ins_class_search_data}
                        on_quick_search={this.on_quick_search}
                        on_advanced_search={this.on_advanced_search}
                        selected_row={this.ins_class_selected_row}
                        is_focused={true}
                        headerIdForGridTabNavigation={admin_payment_const.payer_alert_search_insurance_class_id}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column mobile={8} tablet={8} computer={4}>
                    <Form.Field className='advance-quick-search'>
                      <label>Insurance Code</label>
                      <ClaimAdjCodeSearch
                        on_quick_search={this.on_quick_search}
                        on_advanced_search={this.on_advanced_search}
                        search_result={this.ins_code_search_data}
                        control={this.state.control ? this.state.control.insurance_code : null}
                        addClicks={this.add_primary_insurance}
                        update_data={this.update_data}
                        hide_modal={this.state.ins_code_modal}
                        selected_row={this.ins_code_selected_row}
                        headerIdForGridTabNavigation={admin_payment_const.payer_alert_search_insurance_code_id}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                      <label>Alert Message </label>
                      <TextareaAutosize
                        maxLength={200}
                        rows={1}
                        className='textarea-single-row'
                        onChange={this.on_change_handle}
                        value={this.state.search_form.message}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} computer={4} verticalAlign={'bottom'} textAlign={'left'}>
                    <Form.Field className=''>
                      <label />
                      <Checkbox
                        label='Include Inactive'
                        checked={this.state.search_form.show_inactive}
                        onChange={() =>
                          this.setState(prev => ({
                            search_form: {
                              ...prev.search_form,
                              show_inactive: !this.state.search_form.show_inactive
                            }
                          }))
                        }
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid>
                <Grid style={{ margin: '0 -17px' }}>
                  <Grid.Column computer={16} textAlign='right'>
                    <Button type='reset' id='clear_button_id' onClick={this.clear_handle} basic>
                      Clear
                    </Button>
                    <Button type='button' id='new_button_id' onClick={this.add_handler} basic>
                      New
                    </Button>
                    <Button onKeyDown={this.setFocusToAppHeader} type='submit' id='search_button_id' primary disabled={this.state.is_search_button_disabled}>
                      Search
                    </Button>
                  </Grid.Column>
                </Grid>
                {this.state.grid_data && (
                  <Grid style={{ marginTop: '-1rem', marginBottom: 0 }}>
                    <Grid.Column tablet={5} computer={4} textAlign='left'>
                      <p style={{ fontSize: '16px' }}>Search Results</p>
                    </Grid.Column>
                    <Grid.Column tablet={5} computer={8} textAlign='center'>
                      {
                        <p style={{ fontSize: '16px', minHeight: 22 }}>
                          {`${rows_count} ${rows_count == 1 ? ' record shown' : ' records shown'}`}
                        </p>
                      }
                    </Grid.Column>
                    <Grid.Column tablet={2} computer={4} textAlign='right' />
                  </Grid>
                )}
              </Form>
            </div>
             {this.state.grid_data && !this.state.loading && (
              <GridView
                id={`id_grid_payer_alert`}
                row={this.state.grid_data.rows}
                column={this.state.grid_data.grid_conf}
                selectionType={'single'}
                style={{ height: '100%' }}
                wrapperStyle={{ width: '100%', height: 0, display: 'flex', flex: '1 1 auto' }}
                onRowDoubleClicked={this.on_row_double_clicked}
                get_grid_ref={this.get_grid_ref}
                suppressMovableColumns={false}
                enableColResize={true}
                headerHeight={this.grid_header_height}
                suppressSizeToFit={true}
                headerIdForTabNavigation={admin_payment_const.payer_alert_search_grid_id}
                onGridOut={this.on_grid_out}
                on_filter_button_click={() => this.update_report_rows_count()}
              />
            )}
          </div>
          {this.state.show_grid && (
            <div
              className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
              id='applicationFooterSticky'
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <Grid.Column computer={16} textAlign='right'>
                <Button id='export_report_button' type='submit' onClick={this.on_export} basic>
                  Export
                </Button>
                <Button
                  onKeyDown={set_focus_to_app_header}
                  id='print_report_button'
                  type='submit'
                  onClick={this.on_print}
                  primary
                >
                  Print
                </Button>
              </Grid.Column>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

//Get user and login details from store.
const mapStateToProps = state => {
  return {
      user_login_details: state.user_login_details
  };
};
export default connect(
  mapStateToProps,
  null
)(PayerAlertSearch);
