import * as React from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { Table } from 'semantic-ui-react';
import { toastr as toaster } from 'react-redux-toastr';
import { currency_formatter, date_format_with_no_time, date_format_with_no_time_return_string_or_null } from '../../shared/utility';
import ErrorCorrectionEditFormComponent from './error_correction_edit_form_compoent';
import { ErrorCorrectionEditPaymentFormComponent } from './error_correction_edit_payment_form_component';
import * as global_constants from '../../global_constants';
import { format_amount, get_check_credit_num } from '../utility';
import { diagnosis_values, modifire_values } from '../edit_charge_util';
import { charge_messages } from '../../charge/charge_constant';
import { get_lauch_darkley_key_value as isFFEnabled } from '../../shared/utility';
import { ErrorCorrectionsEnhancementsProject } from '../payment_constants';

class ErrorCorrectionEditableRowComponent extends React.Component<any, any> {

    _is_mounted = false;
    initial_data: any = {};
    update_data: any = {};

    initial_payments: any = [];
    update_payments: any = [];

    ui_form_nodes: any;
    ui_payment_form_nodes: any;
    empty_option = { text: ' ', value: ' ' };

    // Get initialize data when component created
    constructor(props) {
        super(props);
        this.ui_form_nodes = null;
        this.ui_payment_form_nodes = [];
        this.state = {
            data: this.init_data(this.props.data),
            minDate: null,
            is_fetched: false,
            open_confirm_alert: false,
            allow_charge_delete: this.props.data.allow_delete,
            to_insurance: false,
            to_patient: false,
            delete: false,
            edit: false,
            expand: this.props.IsErrorCorrectionsEnhancementsEnabled ? true : false,
            is_viewed:false,
            insurance_code: [],
            tranfer_insurance_code: [],
            preauth_list: [],

            error_charge: {
                date_of_service: false,
                provider_id: false,
                pos_Id: false,
                proc_id: false
            },
            err_adjustment_qualifier: false
        };

        this.init_charge(this.props.data);
    }

    componentDidMount() {
        this._is_mounted = true;
        this.props.onRef(this);
        let date = null;
        if (!this.props.data.deposit_date_disabled && this.props.accrualFlag) {
            date = new Date(this.props.openMonth);
        }

        this.setState({
            minDate: date
        });
        const state_value = {};
        let is_update = false;
        if (this.props.edit_all) {
            state_value['edit'] = this.props.edit_all;
            state_value['expand'] = this.props.edit_all;
            state_value['is_viewed'] = true;
            is_update = true;//state update available 
        }

        if (is_update) {
            this.setState(state_value);
        }

        this.get_insurance_by_charge();
        this.get_preauth_data();
    }

    componentWillUnmount() {
        this._is_mounted = false;
        this.props.onRef(undefined)
    }

    //Component update function
    componentDidUpdate(previousProps, previousState) {
        const { shouldUpdate, checked } = this.props;
        const state_value = {};
        let is_update = false;
        if (shouldUpdate === true && previousProps.checked !== checked) {
            state_value['checked'] = checked;
            is_update = true;//state update available 
        } else if (this.state.checked === true && this.props.isClear === true) {
            state_value['checked'] = false;
            is_update = true;//state update available 
        }

        if (this.props.clear_data !== previousProps.clear_data) {
            state_value['err_amount'] = false;
            state_value['err_payment_code'] = false;
            state_value['err_insurance'] = false;
            state_value['disabled_date_of_service'] = false;
            is_update = true;//state update available 
        }
        if (this.props.expand !== previousProps.expand) {
            state_value['expand'] = this.props.expand;
            is_update = true;//state update available 
        }

        if (this.props.edit_all !== previousProps.edit_all &&
            this.props.edit_all !== this.state.edit) {
            state_value['edit'] = this.props.edit_all;
            state_value['expand'] = this.props.edit_all;
            if(this.props.edit_all===true){
                state_value['is_viewed'] = true
            }
            is_update = true;//state update available 
        }

        if (this.props.data != previousProps.data) {
            state_value['data'] = this.init_data(this.props.data);
            state_value['allow_charge_delete'] = state_value['data'].allow_delete;
            state_value['is_fetched'] = true;
            state_value['checked'] = checked;
            is_update = true;//state update available 
            this.init_charge(this.props.data);
        }

        if (is_update) {
            this.setState(state_value);
        }
    }

    init_data = (data) => {
        const form_data = { ...data };
        form_data['fromInsurance'] = data.fromInsurance || [];
        form_data['preAuthorization'] = data.preAuthorization || [];
        return form_data;
    }

    init_charge = (data) => {
        const form_data = {
            ...data,
            ...diagnosis_values(data),
            ...modifire_values(data)
        };
        form_data['patient_id'] = this.props.patientId;
        form_data['date_of_service'] = this.get_date(date_format_with_no_time_return_string_or_null(data.date_of_service), 'MM/DD/YYYY');
        form_data['start_of_care_date'] = date_format_with_no_time(data.start_of_care_date);
        form_data['plan_of_care_date'] = date_format_with_no_time(data.plan_of_care_date);
        form_data['updated_date'] = data.updated_date ? this.get_date(data.updated_date, 'MM/DD/YYYY') : null;
        form_data['modified_by'] = data.modified_by;
        form_data['provider_id'] = data.provider_id;
        form_data['provider_code'] = data.provider_code;
        form_data['provider_desc'] = data.provider_desc;
        form_data['pos_Id'] = data.pos_Id;
        form_data['pos_code'] = data.pos_code;
        form_data['pos_name'] = data.pos_name;
        form_data['charge_id'] = data.charge_id;
        form_data['referring_physician_id'] = data.referring_physician_id;
        form_data['referring_first_name'] = data.referring_first_name;
        form_data['referring_last_name'] = data.referring_last_name;
        form_data['referring_physician_code'] = data.referring_physician_code;
        form_data['referring_address'] = data.v;
        form_data['proc_id'] = data.proc_id;
        form_data['proc_code'] = data.proc_code;
        form_data['proc_desc'] = data.proc_desc;
        form_data['proc_amount'] = data.proc_amount;
        form_data['total_charge_amount'] = data.total_charge_amount;
        form_data['amount'] = data.amount;
        form_data['payment'] = data.payment;
        form_data['adjustment'] = data.adjustment;
        form_data['balance'] = data.balance;
        form_data['insurance_balance'] = data.insurance_balance;
        form_data['patient_balance'] = data.patient_balance;
        form_data['units'] = data.units;
        form_data['preauth_id'] = data.preauth_id;
        form_data['charge_open_time'] = data.charge_open_time;
        form_data['rebill'] = data.rebill || false;
        form_data['delete'] = data.delete || false;
        form_data['allow_delete'] = data.allow_delete || false;
        this.initial_data = { ...form_data };
        this.update_data = { ...form_data };
        this.initial_payments = this.init_payment_data(data);
        this.update_payments = this.init_payment_data(data);
        return form_data;
    }


    init_payment_data = (data) => {
        if (data.payments && data.payments.length > 0) {
            return data.payments.map((data) => {
                const form_data = { ...data };
                form_data['deposit_date'] = this.get_date(form_data.deposit_date, 'MM/DD/YYYY');
                form_data['adjustment_amount'] = format_amount(data.adjustment_amount || 0);
                form_data['payment_amount'] = format_amount(data.payment_amount || 0);
                form_data['transfer_detail_amount'] = format_amount(data.transfer_detail_amount || 0);
                form_data['from_ins_code'] = data.from_ins_code == 0 ? '0' : `${data.from_ins_code}`;
                form_data['xfer_ins_code'] = data.xfer_ins_code == 0 ? '0' : `${data.xfer_ins_code}`;
                form_data['batch_num'] = data.batch_num || '';
                form_data['adjustment_qualifier'] = data.adjustment_qualifier || '';
                form_data['adjustment_reason'] = data.adjustment_reason || '';
                form_data['delete'] = false;
                form_data['to_insurance'] = false;
                form_data['to_patient'] = false;
                form_data['to_charge_id'] = 0;
                return form_data;
            });
        }

        return [];
    }


    insurance_by_charge = (response) => {
        if (response && response.length > 0) {
            return response.map((val, index) => {
                return { key: index, value: val.insurance_code_id, text: val.insurance_name, data: val }
            });
        }
        return [];
    }

    /*Dropdown API call*/
    get_insurance_by_charge = () => {
        const dropdown_insurance_data = {};
        dropdown_insurance_data['is_loading_dropdown'] = false;

        let insurance_code = this.state.data && this.state.data.fromInsurance ? this.insurance_by_charge(this.state.data.fromInsurance) : [];
        dropdown_insurance_data['insurance_code'] = insurance_code;
        let tranfer_insurance_code = [this.empty_option, ...insurance_code];
        dropdown_insurance_data['tranfer_insurance_code'] = tranfer_insurance_code;

        if (this._is_mounted) {
            this.setState(dropdown_insurance_data);
        }
    }

    get_preauth_data = () => {
        let preauth_list = this.state.data && this.state.data.preAuthorization ? this.update_preauth_data(this.state.data.preAuthorization) : [];
        if (this._is_mounted) {
            this.setState({ preauth_list });
        }
    }

    update_preauth_data = (source) => {
        let target = [{ "text": '-- Please Select a Preauthorization --', "value": global_constants.constants.dropdown_default_info.value_string, "key": "-- Please Select a Preauthorization --" }];
        var source_length = source.length;

        if (source && source_length > 0) {
            for (let y = 0; y < source_length; y++) {
                let item = source[y];
                target.push({
                    "text": item.detail,
                    "value": item.id,
                    "key": item.id
                })
            }
        }

        return target;
    }

    //Get row expand status
    is_expand = () => this.state.expand;

    //isChecked returns selection status.
    is_checked = () => this.state.checked;

    //Get row number
    get_row_num = () => this.props.rowNum;

    is_valid_charge = () => {

        let has_error = false;
        let error = {
            date_of_service: false,
            provider_id: false,
            pos_Id: false,
            proc_id: false
        };

        //if (!this.update_data.plan_of_care_date) {
        //    has_error = true;
        //    error.plan_of_care_date = true;
        //}
        if (!this.update_data.proc_id) {
            has_error = true;
            error.proc_id = true;
        }
        if (!this.update_data.pos_Id || this.update_data.pos_Id == '-999') {
            has_error = true;
            error.pos_Id = true;
        }
        if (!this.update_data.provider_id || this.update_data.provider_id == '-999') {
            has_error = true;
            error.provider_id = true;
        }
        if (!this.update_data.date_of_service) {
            has_error = true;
            error.date_of_service = true;
        }

        if (has_error) {
            toaster.error('', global_constants.constants.mandatory_fields);
            if (this._is_mounted) {
                this.setState({
                    error_charge: error
                });
            }

            return false;
        }

        if (!this.update_data.dx1 && !this.update_data.dx2
            && !this.update_data.dx3 && !this.update_data.dx4
            && !this.update_data.dx5 && !this.update_data.dx6
            && !this.update_data.dx7 && !this.update_data.dx8) {
            toaster.error('', charge_messages.req_atleast_1dx_for_charge);
            if (this._is_mounted) {
                this.setState({
                    error_charge: error
                });
            }
            return false;
        }
        return true
    }

    //Check form validation
    is_valid_payment = () => {
        let flag_value = true;
        let update_value = {};
        if (this.update_payments && this.update_payments.length > 0) {
            this.update_payments.map((item) => {
                if (this.required_adjustment_qualifier(item.adjustment_qualifier, item)) {
                    update_value['err_adjustment_qualifier'] = true;
                    flag_value = false;
                }
            })
        }

        if (!flag_value) {
            this.setState({ ...update_value })
        }

        return flag_value;
    }

    force_error_edit = () => {
        let update_value = {};
        update_value['edit'] = true;
        update_value['expand'] = true;
        update_value['err_adjustment_qualifier'] = true;
        this.setState({ ...update_value })
    }

    required_adjustment_qualifier = (dropdown_value, form_data) => {
        const {
            payment_amount,
            adjustment_amount,
            transfer_detail_amount
        } = form_data;
        let adj_qual = dropdown_value || '';
        let original_payment = this.props.data.payments.find(payment => { return payment.payment_adjustment_id == form_data.payment_adjustment_id });
        if ((adj_qual == 'PR' || adj_qual == 'CO') && original_payment) {
            if (transfer_detail_amount != 0 && adjustment_amount != 0) {
                toaster.error('', 'Can not enter value in both Adjustment Amount and Transfer Detail Amount for qualifier PR and CO.');
                return true;
            } else if (adj_qual == "PR" && transfer_detail_amount == 0) {
                toaster.error('', 'For PR qualifier, enter value in Transfer Detail Amount.');
                return true;
            } else if (adj_qual == "CO" && (adjustment_amount == 0 && payment_amount == 0)) {
                toaster.error('', 'For CO qualifier, enter value in Adjustment Amount and/or Payment Amount.');
                return true;
            }
        }
        return false;
    }


    //Get data returns updated data item.
    get_updated_data = () => {
        let charge = null;
        if (this.is_charge_unsaved()) {
            charge = { ...this.update_data, has_charge_change: true };
        } else {
            charge = { ...this.initial_data, has_charge_change: false };
        }

        const p_rows = this.get_payment_unsaved();
        if (p_rows && p_rows.length > 0) {
            charge['has_payment_change'] = true;
            charge['payments'] = p_rows;
        } else {
            charge['has_payment_change'] = false;
            charge['payments'] = [];
        }

        return charge;
    };

    //Check unsaved payment data change
    get_payment_unsaved = () => {
        let unsaved_data = [];
        this.update_payments.map((item, index) => {
            if (JSON.stringify(this.initial_payments[index]) !== JSON.stringify(item)) {
                unsaved_data.push(item);
            }
        })
        return unsaved_data
    }

    //Get data returns updated data item.
    get_data = () => {
        return {
            ...this.update_data,
            payments: [...this.update_payments]
        }
    };

    //Check unsaved charge data change
    is_charge_unsaved = () => {
        if (JSON.stringify(this.initial_data) !== JSON.stringify(this.update_data)) {
            return true;
        }
        return false;
    }

    //Check unsaved payment data change
    is_payment_unsaved = () => {
        if (JSON.stringify(this.initial_payments) !== JSON.stringify(this.update_payments)) {
            return true;
        }
        return false;
    }

    on_charge_change = (key, value) => {
        this.update_data[key] = value;
    }
    on_charge_object_change = (params) => {
        this.update_data = { ...this.update_data, ...params };
    }

    on_payment_change = (key, value, index) => {
        if (this.update_payments && this.update_payments.length > index) {
            this.update_payments[index][key] = value;
        }
    }

    on_payment_object_change = (params, index) => {
        if (this.update_payments && this.update_payments.length > index) {
            this.update_payments[index] = { ...this.update_payments[index], ...params };
            let list = this.update_payments;
            if (params.hasOwnProperty('delete') || params.hasOwnProperty('to_insurance')) {
                let allow_charge_delete = this.update_payments.every(item => item.delete || item.to_insurance || (item.hide_row && !item.delete));
                if (this.state.data.allow_delete != allow_charge_delete) {
                    if (!allow_charge_delete) {
                        // Unselect charge delete when a payment delete unselected
                        this.update_data['delete'] = allow_charge_delete;
                    }
                    this.setState({
                        data: { ...this.get_data(), allow_delete: allow_charge_delete },
                        allow_charge_delete
                    });
                }
            }
        }
    }


    get_date = (date, pattern) => {
        if (date) {
            return moment(date).format(pattern);
        }
        return '';
    }

    //Get default date selection
    default_date = (date, pattern) => {
        if (date) {
            const dt = moment(date).format(pattern);
            return moment(dt);
        }
        return null;
    }

    format_value = (value, type) => {
        if (type == 'date') {
            return this.get_date(value, 'MM/DD/YYYY');
        } else if (type === 'currency') {
            return currency_formatter(value || 0);
        }
        return value;
    }

    handle_group_click = (e) => {
        const data = this.get_data();
        this.setState(prevState => {
            let state = { expand: !prevState.expand };
            if (prevState.expand) {
                state['edit'] = false;
                state['data'] = { ...this.get_data() };
            }
            return state;
        }, () => {
            if (this.props.edit_all && this.props.total_length == 1) {
                this.props.edit_all_toggle();
            }

            this.props.onEditToggle(false, data, this.props.rowNum)
        });
    }

    //Render expand collapse icon
    render_angle_icon = () => {
        if ((this.state.edit || (this.state.data.payments && this.state.data.payments.length > 0))) {
            return (<span className="angle-group-expand-collapse" onClick={this.handle_group_click}>
                <i aria-hidden="true" className={`angle-color angle ${this.state.expand ? 'up' : 'down'} icon`}></i>
            </span>);
        }
        return null;
    }

    render_inner_header = () => {
        if (this.props.innerColumn && this.props.innerColumn.length > 0) {
            return this.props.innerColumn.map((col, index) => {
                return (
                    <Table.HeaderCell
                        key={`level1-h${index}`}
                        content={col.headerName}
                        style={{ width: col.width, minWidth: col.minWidth, ...col.cellStyle }}
                    />
                );
            });
        }
    }

    render_inner_rows = () => {
        if (this.state.data.payments && this.state.data.payments.length > 0) {
            return this.state.data.payments.map((item, index) => {
                item ={
                    ...item,
                    check_credit_num: get_check_credit_num(item)
                }
                return (
                    <Table.Row key={`level1-r${index}`} className={item.is_active ? '' : 'multi-select-dropdown-inactive-pay-row'} id={"error-correction-inner-rows"}>
                        {this.props.innerColumn.map((col, cellIndex) => {
                            return (
                                <Table.Cell
                                    key={`level1-r${index}-c${cellIndex}`}
                                    style={{ ...col.cellStyle }}
                                    content={this.format_value(item[col.field], col.type)}
                                />
                            );
                        })}
                    </Table.Row>
                );
            });
        }
    }

    render_inner_grid = () => {
        if (this.state.expand && !this.state.edit && this.state.data.payments && this.state.data.payments.length > 0) {
            return (
                <Table.Row key={`level1-${this.props.rowNum}`}>
                    <Table.Cell colSpan={12} className={'error-corrections-table-cell'}>
                        <Table className={'error-corrections-inner-table'} style={{tableLayout: 'fixed'}}>
                            <Table.Header>
                                <Table.Row>
                                    {this.render_inner_header()}
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>{this.render_inner_rows()}</Table.Body>
                        </Table>
                    </Table.Cell>
                </Table.Row>
            );
        }
        return null;
    }

    click_to_open = (data) => {
        this.setState({
            edit: true,
            expand: true,
            is_viewed:true,
        }, () => {
            if (!this.props.edit_all && this.props.total_length == 1) {
                this.props.edit_all_toggle();
            }
            this.props.onEditToggle(true, data, this.props.rowNum)
        })
    }

    get_class_based_on_charge_viewed = () => {
        return this.state.is_viewed ? "charge_is_viewed" : "href_link";
    }
    render_read_row = (data) => {
        return (
            <React.Fragment>
                <Table.Row key={`level0-${this.props.rowNum}`} className={data.is_active ? '' : 'multi-select-dropdown-inactive-row'} >
                    <Table.Cell content={this.render_angle_icon()} />
                    <Table.Cell>
                        <span
                            className={this.get_class_based_on_charge_viewed()}
                            onClick={() => this.click_to_open(data)}
                            dangerouslySetInnerHTML={{ __html: data.charge_id }}
                            id={`charge_id_${data.charge_id}`}
                        />
                    </Table.Cell>
                    <Table.Cell>{this.get_date(data.date_of_service, 'MM/DD/YYYY')}</Table.Cell>
                    <Table.Cell>{data.proc_code}</Table.Cell>
                    <Table.Cell>{data.units}</Table.Cell>
                    <Table.Cell style={{ textAlign: 'right' }} content={currency_formatter(data.amount)} />
                    <Table.Cell style={{ textAlign: 'right' }} content={currency_formatter(data.total_charge_amount)} />
                    <Table.Cell style={{ textAlign: 'right' }} content={currency_formatter(data.payment)} />
                    <Table.Cell style={{ textAlign: 'right' }} content={currency_formatter(data.adjustment)} />
                    <Table.Cell style={{ textAlign: 'right' }} content={currency_formatter(data.balance)} />
                    <Table.Cell style={{ textAlign: 'right' }} content={currency_formatter(data.insurance_balance)} />
                    <Table.Cell style={{ textAlign: 'right' }} content={currency_formatter(data.patient_balance)} />
                </Table.Row>
                {this.render_inner_grid()}
            </React.Fragment>
        );
    }

    transaction_list = (props_data, insurance_code, tranfer_insurance_code) => {
        return (this.update_payments.map((transaction, index) => {
            if (transaction.hide_row) {
                return null;
            }
            let required_check = this.required_adjustment_qualifier(transaction.adjustment_qualifier, transaction);
            return <ErrorCorrectionEditPaymentFormComponent
                onRef={ref => (this.ui_payment_form_nodes[index] = ref)}
                key={index}
                row_id={index}
                rowNum={`payment-${this.props.rowNum}-${index}`}
                data={transaction}
                isSubmitted={this.props.isSubmitted}
                err_adjustment_qualifier={required_check}
                onDataChange={this.on_payment_change}
                onObjectChange={this.on_payment_object_change}
                paymentCode={props_data.paymentCode || []}
                adjustmentCode={props_data.adjustmentCode || []}
                paymentType={props_data.paymentType || []}
                adjustmentQualifier={props_data.adjustmentQualifier || []}
                adjustmentReason={props_data.adjustmentReason || []}
                insuranceCode={insurance_code || []}
                tranferInsuranceCode={tranfer_insurance_code || []}
                isDeletePaymentAllowed={props_data.isDeletePaymentAllowed}
                IsErrorCorrectionsEnhancementsEnabled = {this.props.IsErrorCorrectionsEnhancementsEnabled}
            />
        })
        );
    }

    //Render function
    render() {
        if (this.state.expand && this.state.edit) {
            return (
                <React.Fragment>
                    <Table.Row key={`level0-${this.props.rowNum}`} className='active'>
                        <Table.Cell verticalAlign={'top'}>{this.render_angle_icon()}</Table.Cell>
                        <Table.Cell colSpan={11} className={'error-corrections-table-cell-no-bg'}>
                            <div className="wrapIn" >
                                <ErrorCorrectionEditFormComponent
                                    onRef={ref => (this.ui_form_nodes = ref)}
                                    token={this.props.token}
                                    data={this.update_data}
                                    initial_data={this.props.data}
                                    rowIndex={this.props.rowNum}
                                    totalRows={this.props.totalRows}
                                    rowNum={`level0-${this.props.rowNum}`}
                                    patientId={this.props.patientId}
                                    allowChargeDelete={this.state.allow_charge_delete}
                                    error={this.state.error_charge}
                                    isSubmitted={this.props.isSubmitted}
                                    onDataChange={this.on_charge_change}
                                    onObjectChange={this.on_charge_object_change}
                                    getDxData={this.props.getDxData}
                                    dropdown_data={this.props.dropdown_data}
                                    preauth_list={this.state.preauth_list}
                                    medical_group={this.props.medical_group}
                                    specility={this.props.specility}
                                    medical_group_unfiltered={this.props.medical_group_unfiltered}
                                    primary_spaciality_unfiltered={this.props.primary_spaciality_unfiltered}
                                    paymentCode={this.props.paymentCode || []}
                                    adjustmentCode={this.props.adjustmentCode || []}
                                    paymentType={this.props.paymentType || []}
                                    adjustmentQualifier={this.props.adjustmentQualifier || []}
                                    adjustmentReason={this.props.adjustmentReason || []}
                                    insuranceCode={this.state.insurance_code || []}
                                    tranferInsuranceCode={this.state.tranfer_insurance_code || []}
                                />
                                {
                                    this.props.data && this.props.data.charge_id && this.props.data.payments
                                        && this.props.data.payments.length > 0 ?
                                        this.transaction_list(this.props, this.state.insurance_code, this.state.tranfer_insurance_code) : ""
                                }
                            </div>
                        </Table.Cell>
                    </Table.Row>
                </React.Fragment>
            );
        }
        return this.render_read_row(this.state.data);
    }
};

const mapStateToProps = (state) => {
    return {
        IsErrorCorrectionsEnhancementsEnabled: isFFEnabled(state.launch_darkly, ErrorCorrectionsEnhancementsProject.projectFlag)
    }
}

export default connect(mapStateToProps)(ErrorCorrectionEditableRowComponent);
