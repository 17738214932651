import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import PopUpReportsComponent from "./popup_report_component";
import { show_hide_model_popup_report } from "../action/shared_action";
import { Button, Grid, Modal, Popup } from 'semantic-ui-react';
import ReportComponent from '../../reports/component/report_component';

export class PopUpLinkRenderer extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      is_show_pop_up: false,
      charge_id: 0,
      show_print_prompt: false,
      is_tooltip: this.props.is_tooltip || false
    };
  }

  // Declare Class level variable
  linkParam = {
    pathname: "/report/sub_report_data",
    search: "",
    state: {
      is_sub_report_controls: false,
      report_id: 0
    },
    link_value: ""
  };
  show_prompt = false;
  is_clicked = false;
  is_link = false;

  UNSAFE_componentWillMount = () => {
      this.check_is_link();
      if (this.is_link) {
          this.set_hyperlink_value(this.props.value, this.props.r4_to_r6_routes);
      } else {
          this.set_value(this.props.value)
      }
  };

  check_is_link = () => {
    this.is_link = this.props.value && this.props.value.includes("</a>");
  };

  // Use to get sub-report id from query string
  get_report_id = (param) => {
    let report_id;
    report_id = param.split("&", 1);
    report_id = parseInt(report_id[0].split("rid=")[1]);
    this.linkParam = {
      ...this.linkParam,
      state: {
        is_sub_report_controls: false,
        report_id: report_id
      }
    };
  };

  // Function used to get link value from hyperlink.
  get_link_value = (row_value) => {
    if (!row_value) {
      return "";
    }
    row_value = row_value.replace("<b>", "").replace("</b>", "");
    let start = row_value.indexOf(">") + 1;
    let end = row_value.indexOf("</");
    return row_value.substring(start, end).trim();
  };

  // Function used to get parameter value from hyperlink.
  get_params = (query_param) => {
    let query_param_array;
    if (!query_param) {
      return "";
    }
    query_param_array = query_param.split(">");
    query_param_array = query_param_array[0]
      .replace("<a ", "")
      .replace("</a>", "")
      .replace(">", "")
      .replace(/["]/g, "");
    query_param_array = query_param_array.split("?");
    query_param_array = query_param_array[1]
      .replace("<a ", "")
      .replace("</a>", "")
      .replace(">", "")
      .replace(/["]/g, "");
    return query_param_array;
  };

    set_value = (row_value) => {
        this.linkParam = {
            ...this.linkParam,
            pathname: '',
            link_value: row_value
        };
    }

  // Function used to set hyperlink parameter.
  set_hyperlink_value = (row_value, r4_to_r6_routes) => {
    if (!row_value) {
      return "";
    }
    let link_value = "";
    let query_param = "";
    row_value = row_value
      .toString()
      .replace(/%a0/g, "")
      .replace(/%d0/g, "")
      .replace(/%2c/g, ",")
      .replace(/%20/g, " ")
      .replace(/%23/g, "#");
    try {
      row_value = decodeURIComponent(row_value);
    } catch (e) {
      row_value = row_value;
    }

    row_value = row_value.replace("<b>", "").replace("</b>", "");
    link_value = this.get_link_value(row_value);
    query_param = link_value && this.get_params(row_value);
    query_param = link_value && query_param.toLowerCase().trim();
    this.linkParam.search = `?${query_param}`;
    row_value = row_value.toLowerCase().trim();
    r4_to_r6_routes.filter((route) => {
      let r4_route = route.r4_route.toLowerCase().trim();
      let r6_route = route.r6_route.toLowerCase().trim();

      if (row_value.indexOf(r4_route) > -1) {
        r6_route = r6_route.replace("param", encodeURIComponent(query_param));
        this.linkParam = {
          ...this.linkParam,
          link_value: link_value
        };
      }
    });
    this.get_report_id(this.props.value);
  };

  close_popup = () => {
    this.setState(
      {
        show_model_popup_report: false
      },
      () => {
        this.props.show_hide_model_popup_report(null);
      }
    );
  };

  click_to_navigate = () => {
    if (this.props.custom_print) {
      this.setState({
        show_print_prompt: true
      });
    } else {
      if (this.props.show_model_popup_report) {
        this.props.show_model_popup_report.close_popup();
      }
      this.setState(
        {
          show_model_popup_report: true
        },
        () => {
          this.props.show_hide_model_popup_report(this);
        }
      );
    }
  };

  after_download_pdf = (status: boolean) => {
    this.setState({
      show_model_popup_report: status
    });
  };

  // Close print prompt
  close_print_prompt = () => {
    this.setState({
      show_print_prompt: false
    });
  };

  // custom print pop-up handler
  custom_print = (type) => {
    let link_param = this.linkParam.search.split('&');
    let report_type_index = -1;
    link_param.map((item, index) => {
      if (item.indexOf('reportType') > -1) {
        report_type_index = index;
      }
    });
    if (report_type_index > -1) {
      link_param[report_type_index] = `reportType=${type}`;
      this.linkParam = {
        ...this.linkParam,
        search: link_param.join('&')
      }
    } else {
      this.linkParam = {
        ...this.linkParam,
        search: `${this.linkParam.search}&reportType=${type}`
      }
    }

    this.setState(
      {
        show_print_prompt: false,
        show_model_popup_report: true
      },
      () => {
        this.props.show_hide_model_popup_report(this);
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.linkParam.link_value === "Total" ? (
          this.linkParam.link_value
            ) : this.state.is_tooltip ? this.props.data[this.props.tooltip_feild] ? (
          <React.Fragment>
            <Popup
              trigger={
                    <span
                         {...(this.is_link ? { className: "href_link", onClick: this.click_to_navigate } : {})}
                        dangerouslySetInnerHTML={{ __html: this.linkParam.link_value }}
                    />
              }
              content={this.props.data[this.props.tooltip_feild]}
              on={"hover"}
              wide={"very"}
              flowing
              hoverable
              hideOnScroll
            />
          </React.Fragment>
                ) : (<span
                        {...(this.is_link ? { className: "href_link", onClick: this.click_to_navigate } : {})}
                        dangerouslySetInnerHTML={{ __html: this.linkParam.link_value }}
                />): (
          <span
            {...(this.is_link ? { className: "href_link", onClick: this.click_to_navigate } : {})}
            dangerouslySetInnerHTML={{ __html: this.linkParam.link_value }}
          />
        )}
        {this.state.show_model_popup_report && (
          this.props.downloadPdf ? (
            <div style={{ height: 0, visibility: 'hidden' }}>
              <ReportComponent
                set_url_history={true}
                location={this.linkParam}
                is_print_disabled={this.props.is_print_disabled || false}
                is_export_disabled={this.props.is_export_disabled || false}
                is_close_button={true}
                on_close={this.props.on_close}
                from_popup={true}
                auto_print={this.props.auto_print || false}
                downloadPdf={this.props.downloadPdf || false}
                after_download_pdf={this.after_download_pdf}
              />
            </div>
          ) : (
          <PopUpReportsComponent
            on_close={this.close_popup}
            location={this.linkParam}
            is_print_disabled={this.props.is_print_disabled || false}
            is_export_disabled={this.props.is_export_disabled || false}
            auto_print={this.props.auto_print || false}
          />)
        )}
        {this.props.custom_print && (
          <Modal
            closeIcon
            onClose={this.close_print_prompt}
            centered={false}
            className='default-modal'
            open={this.state.show_print_prompt}
            closeOnDimmerClick={false}
            size={"small"}
          >
            <Modal.Header>835 View Types</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <Grid>
                  <Grid.Column>
                    <p>{'How would you like to view the selected 835?'}</p>
                  </Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column computer={16} textAlign='right'>
                    <Button
                      basic
                      id='id_btn_cancel'
                      type='button'
                      onClick={(e) => this.custom_print(2)}
                      content={'Visit Only'}
                    />
                    <Button
                      basic
                      id='id_btn_no'
                      type='button'
                      onClick={(e) => this.custom_print(1)}
                      content={'Patient Only'}
                    />
                    <Button
                      primary
                      id='id_btn_yes'
                      type='button'
                      onClick={(e) => this.custom_print(0)}
                      content={'Full 835'}
                    />
                  </Grid.Column>
                </Grid>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    show_model_popup_report: state.shared_details.show_model_popup_report
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      show_hide_model_popup_report: show_hide_model_popup_report
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PopUpLinkRenderer));
