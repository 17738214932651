import * as React from 'react';
import { connect } from 'react-redux';
import { toastr as toaster } from 'react-redux-toastr';
import Selection from '../../../shared/component/selection_component';
import MultiSelectPanelComponent from '../../../shared/component/multi_select_panel_component';
import { Button, Grid, Input, Radio, Segment } from 'semantic-ui-react';
import { Tab, Tabs } from '@blueprintjs/core';
import { location_name, insurance_name, get_insurance_code } from '../utility';
import { statusSelectOptions } from '../provider_billing_pane_constants';
import * as billingPaneConstants from '../provider_billing_pane_constants';
import { ProviderBillingService } from './providerBilling/provider_billing_service';
import { ProviderBillingValidator } from './providerBilling/provider_billing_validator';
import { ConcreteCombinationsLimit } from './providerBilling/provider_billing_concrete_combinations_limit';

interface IFormFields {
    provider_id: number,
    provider_billing_id: number,
    setting_id: number,
    setting_name: string,
    status: React.ReactText,
    tax_identification_number: string,
    ssn_tin_flag: boolean,
    individual_number: string,
    individual_qualifier: string,
    group_number: string,
    group_qualifier: string,
    location_ids: number[],
    insurance_class_ids: number[],
    insurance_code_ids: number[]
}

/**
 * Responsible to retrieve A Provider Billing Component
 **/
class ProviderBillingAddEditComponent extends React.Component<any, any> {
    _isMount = false;
    private readonly statusSelectOptions = statusSelectOptions;
    private readonly tabLocationsData = [];
    private readonly tabInsuranceClassesData = [];
    private readonly tabInsuranceCodesData = [];
    private validator: any = new ProviderBillingValidator();
    private providerBillingService = new ProviderBillingService();

    constructor(props) {
        super(props);
        this.state = {
            formFields: this.props.providerBilling.originalRowData,
            isSubmitted: false,
            externalErrors: {},
            formErrors: {}
        };

        this.currentFormFields = { ...this.props.providerBilling.originalRowData };
        this.tabLocationsData = this.props.locations.map((val, index) => { return { value: val.id, name: location_name(val) } });
        this.tabInsuranceClassesData = this.props.insuranceClasses.map((val, index) => { return { value: val.id, name: insurance_name(val) } });
        this.tabInsuranceCodesData = this.props.insuranceCodes.map((val, index) => { return { value: val.insurance_code_id, name: val.insurance_display_name } });
    }

    private currentFormFields: IFormFields = {
        provider_id: null,
        provider_billing_id: null,
        setting_id:0,
        setting_name: '',
        status: 'true',
        tax_identification_number: '',
        ssn_tin_flag: false,
        individual_number: '',
        individual_qualifier: '',
        group_number: '',
        group_qualifier: '',
        location_ids: [],
        insurance_class_ids: [],
        insurance_code_ids: []
    }

    componentDidMount() {
        this._isMount = true;
    }

    required_filed: any = {
        setting_name: true
    };

    validationsPassed() {
        let formIsValid = true;
        let errors = {};
        this.currentFormFields.setting_name = this.currentFormFields.setting_name.toString().trim();

        if (this.required_filed.setting_name && !this.currentFormFields.setting_name) {
            errors['setting_name'] = true;
            formIsValid = false;
        }
        if(this._isMount) {
            this.setState({
                formErrors: errors
            });
        }

        return formIsValid;
    }

    saveHandler = (ev, saveFromPopup) => {
        ev.preventDefault();
        if(this._isMount) {
            this.setState({
                isSubmitted: true
            });
        }
        const formFieldsValidationPassed: boolean = this.validationsPassed();

        if (formFieldsValidationPassed && this.is_permutations_limit_acceptable(null)) {
            let billingSetting = this.currentFormFields;
            this.props.saveHandlerCallback(billingSetting);
        }
        if (!formFieldsValidationPassed) {
            toaster.error('', billingPaneConstants.generalValidationError);
        }
    }

    onChangeDropdownHandler = e => {
        const { value, name } = e.target;

        if (name == 'status') {
            this.currentFormFields[name] = (value == 'true');
        } else {
            this.currentFormFields[name] = value;
        }
        if(this._isMount) {
            this.setState({
                formFields: this.currentFormFields
            });
        }
    };

    onChangeInputsHandler = e => {
        const input_field = e.target.name;
        let input_value = e.target.value;
        this.currentFormFields[input_field] = input_value;
        if(this._isMount) {
            this.setState({
                formFields: this.currentFormFields
            });
        }
        this.validationsPassed();
    }

    onChangeSsnEinHandler = (e, { value }) => {
        if (value === 'ssn') {
            this.currentFormFields['ssn_tin_flag'] = false;
        } else {
            this.currentFormFields['ssn_tin_flag'] = true;
        }
        if(this._isMount) {
            this.setState({
                formFields: this.currentFormFields
            });
        }
    }

    handle_locations_on_change_selected = (selected_list: number[]): void => {
        this.currentFormFields.location_ids = selected_list;
    }

    handle_insurance_class_on_change_selected = (selected_list: number[]): void => {
        this.currentFormFields.insurance_class_ids = selected_list;
    }

    handle_insurance_code_on_change_selected = (selected_list: number[]): void => {
        this.currentFormFields.insurance_code_ids = selected_list;
    }

    is_permutations_limit_acceptable = (newSelectedValues: any): boolean => {
        this.validator.setStrategy(new ConcreteCombinationsLimit());
        const hasValueLessThanLimit = this.validator.isValid([{ ...this.currentFormFields }, newSelectedValues]);
        const instancesReference: any = this.refs;

        if (!hasValueLessThanLimit) {
            toaster.error('', billingPaneConstants.maximumCombinationsExceeded);
            this.providerBillingService.rollbackToPreviousSelectedItems([instancesReference, { ...this.currentFormFields }, newSelectedValues]);
        }

        return hasValueLessThanLimit;
    }
    componentWillUnmount = () => {
        this._isMount = false;
    };

    render() {
        const { externalErrors, formErrors, isSubmitted} = this.state;
        return (
            <React.Fragment>
                <div className='bp3-dialog-save-button'>
                    <Button
                        id='save_provider_billing_btn'
                        className="primary"
                        type='button'
                        onClick={e => this.saveHandler(e, false)}>{'Apply'}</Button>
                </div>
                <Grid columns='equal'>
                    <Grid.Row>
                        <Grid.Column>
                            <label>
                                Setting Name{' '}
                                <span
                                    className={isSubmitted && (externalErrors.setting_name || formErrors.setting_name) ? 'req-alert' : 'req-alert_normal'}>
                                    (required)
                            </span>
                            </label>
                            <Input
                                autoComplete='off'
                                name='setting_name'
                                id='setting_name'
                                type='text'
                                defaultValue={this.currentFormFields.setting_name || ''}
                                onChange={e => this.onChangeInputsHandler(e)}
                                maxLength={50}
                                className={isSubmitted && (externalErrors.setting_name || formErrors.setting_name) ? 'req-border-inp' : ''}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <label>
                                <span>
                                    Status{' '}
                                </span>
                            </label>
                            <Selection
                                id='status'
                                name='status'
                                options={this.statusSelectOptions}
                                onChange={(value, e) => {
                                    this.onChangeDropdownHandler(e);
                                }}
                                defaultValue={this.currentFormFields.status}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <label>
                                Tax ID Number{' '}
                                <span className={"req-alert_normal"}>                                 
                                </span>
                            </label>
                            <Input
                                autoComplete='off'
                                name='tax_identification_number'
                                id='tax_identification_number'
                                type='text'
                                maxLength={9}
                                pattern='[0-9]*'
                                onChange={e => this.onChangeInputsHandler(e)}
                                defaultValue={this.currentFormFields.tax_identification_number || ''}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Radio
                                className='providers-ui-radio-ssn'
                                label='SSN'
                                value='ssn'
                                name='ssn_tin_flag_group'
                                checked={!this.currentFormFields.ssn_tin_flag}
                                onChange={this.onChangeSsnEinHandler}
                            />
                            <Radio
                                className='providers-ui-radio-tin'
                                label='EIN'
                                value='tin'
                                name="ssn_tin_flag_group"
                                checked={this.currentFormFields.ssn_tin_flag}
                                onChange={this.onChangeSsnEinHandler}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <label>
                                Individual Number{' '}
                                <span className={"req-alert_normal"}>
                                </span>
                            </label>
                            <Input
                                autoComplete='off'
                                name='individual_number'
                                id='individual_number_id'
                                type='text'
                                defaultValue={this.currentFormFields.individual_number || ''}
                                onChange={e => this.onChangeInputsHandler(e)}
                                maxLength={50}
                            />
                        </Grid.Column>
                        <Grid.Column>                            
                            <label>
                                Individual Qualifier{' '}
                                <span className={"req-alert_normal"}>
                                </span>
                            </label>
                            <Selection
                                id='individual_qualifier_id'
                                name='individual_qualifier'
                                defaultValue={this.currentFormFields.individual_qualifier}
                                options={this.props.provider_qualifier}
                                onChange={(value, e) => {
                                    this.onChangeDropdownHandler(e);
                                }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <label>
                                Group Number{' '}
                                <span className={"req-alert_normal"}>
                                </span>
                            </label>
                            <Input
                                autoComplete='off'
                                name='group_number'
                                id='group_number_id'
                                type='text'
                                defaultValue={this.currentFormFields.group_number || ''}
                                onChange={e => this.onChangeInputsHandler(e)}
                                maxLength={50}
                            />
                        </Grid.Column>
                        <Grid.Column>                            
                            <label>
                                Group Qualifier{' '}
                                <span className={"req-alert_normal"}>
                                </span>
                            </label>
                            <Selection
                                id='individual_qualifier_id'
                                name='group_qualifier'
                                defaultValue={this.currentFormFields.group_qualifier}
                                options={this.props.provider_qualifier}
                                onChange={(value, e) => {
                                    this.onChangeDropdownHandler(e);
                                }}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="last-row">
                        <Segment>
                            <Tabs
                                animate={true}
                                id="billing-tab-group"
                                defaultSelectedTabId={this.props.billingTabId || "location_id"}>
                                <Tab id="location_id" title="Location" panel={this.renderLocationsPanel()} />
                                <Tab id="insurance_class_id" title="Insurance Class" panel={this.renderInsuranceClassesPanel()} />
                                <Tab id="insurance_code_id" title="Insurance Code" panel={this.renderInsuranceCodePanel()} />
                                <Tabs.Expander />
                            </Tabs>
                        </Segment>
                    </Grid.Row>
                </Grid>
            </React.Fragment>
        );
    }

    private renderLocationsPanel = () => { return this.getLocationPanel(); }
    private renderInsuranceClassesPanel = () => { return this.getInsuranceClassPanel(); }
    private renderInsuranceCodePanel = () => { return this.getInsuranceCodePanel(); }
    private getLocationPanel = () => {
        const selectedLocationsItems = [...this.currentFormFields.location_ids];

        return (
            <div>
                <MultiSelectPanelComponent
                    id='location-multi-select'
                    data={this.tabLocationsData}
                    selected_list={selectedLocationsItems}
                    onChangeSelected={selectedItems => { if (this.is_permutations_limit_acceptable({ location_ids: [...selectedItems] })) { this.handle_locations_on_change_selected([...selectedItems]) } }}
                    ref="location_ids"
                />
            </div>);
    }
    private getInsuranceClassPanel = () => {
        const selectedInsuranceClassItems = [...this.currentFormFields.insurance_class_ids];

        return (
            <div>
                <MultiSelectPanelComponent
                    id='insurance-class-multi-select'
                    data={this.tabInsuranceClassesData}
                    selected_list={selectedInsuranceClassItems}
                    onChangeSelected={selectedItems => { if (this.is_permutations_limit_acceptable({ insurance_class_ids: [...selectedItems] })) { this.handle_insurance_class_on_change_selected([...selectedItems]) } }}
                    ref="insurance_class_ids"
                />
            </div>);
    }
    private getInsuranceCodePanel = () => {
        const selectedInsuranceCodeItems = [...this.currentFormFields.insurance_code_ids];

        return (
            <div>
                <MultiSelectPanelComponent
                    id='insurance-code-multi-select'
                    data={this.tabInsuranceCodesData}
                    selected_list={selectedInsuranceCodeItems}
                    onChangeSelected={selectedItems => { if (this.is_permutations_limit_acceptable({ insurance_code_ids: [...selectedItems] })) { this.handle_insurance_code_on_change_selected([...selectedItems]) } }}
                    ref="insurance_code_ids"
                />
            </div>);
    }
}

const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details
    };
}

export default connect(mapStateToProps)(ProviderBillingAddEditComponent);
