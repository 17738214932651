// Add/Edit Payer Rules Search Component
import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Grid, Input } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import * as export_print_utility from '../../../reports/util/export_print_utility';
import GridView from '../../../shared/component/grid';
import * as session_storage from '../../../shared/session_storage_utility';
import { set_focus_on_element_with_id, set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import * as constants_actions from '../action/constants_action';
import { get_columns, } from "./../../../shared/utility"
import * as constants from '../constants';
import * as local_storage from '../../../shared/local_storage_utility';
import * as global_constants from '../../../global_constants';
import Selection from '../../../shared/component/selection_component';
import { LoaderComponent } from '../../../shared/component/loading_component';
import { advanced_search_class } from '../../../shared/action/shared_action';
import { insurance_name } from '../utility';
import { get_adjustment_code } from '../../../payment/batch_payment/action/batch_payment_action';
import { save_billing_audit } from "../../../reports/action/report_action";
import * as ReportConstants from '../../../reports/report_constants';

export class PayerRulesSearchComponent extends React.Component<any, any> {

    constructor(props) {
        super(props);
        // Define initial state value.
        this.state = {
            show_grid: false,
            show_render: true,
            payerrules_search_criteria: [],
            is_loading: false,
            is_search_button_disabled: false,
            shown: false,
            grid_rows_count: 0,

            payers: [],
            insurance_class: [],
            payment_codes: [],
            adjustment_codes: [],
            is_loading_dropdown: true
        };
    }

    // Define initial properties and its value.
    _is_mounted = false;
    page_name: string = 'add_edit_payer_rules';
    page_metadata: any;
    width_padding: number = 100;
    payerrules_search_criteria: any = {
        payer_id: '',
        description: '',
        insurance_class_id: '',
        payment_code_id: '',
        adjustment_code_id: '',
    };
    initial_payerrules_search_criteria: any = {
        payer_id: '',
        description: '',
        insurance_class_id: '',
        payment_code_id: '',
        adjustment_code_id: '',
    };
    token: string = '';
    row_data = [];
    is_search_ui: any = false;
    code_id: number = 0;
    is_grid_visible = false;
    title = '';
    current_date: any;
    company_name: any;
    total_column_width: number = 0;
    grid_header_height: number = 0;
    current_user_type: global_constants.enum_user_type_r3_id = 1;
    company_service_type = 0;
    select_option = { text: 'Select', value: ' ', key: 'Select' };

    // Fetching the page_name and state.
    UNSAFE_componentWillMount = () => {
        this.get_page_metadata();
        var auth_data = local_storage.get("auth_data");
        if (auth_data) {
            this.current_user_type = auth_data.user_type_r3_id;
            this.company_service_type = auth_data.company_service_type;
        }
    };

    componentDidMount = () => {
        document.body.classList.add('admin-framework');
        let elem = document.getElementById('search-criteria-container');
        let all_form_elem = elem.getElementsByTagName('input');
        all_form_elem[0].focus();
        this.handle_search_button_tab();
        this.get_api_data();
    };

    componentWillUnmount = () => {
        document.body.classList.remove('admin-framework');
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[2] !== 'add_edit_payer_rules' && session_storage.remove('payerrules_search_criteria');
        }
    };

    //Use to fetch params and page metadata
    get_page_metadata = () => {
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        //Verifying page_name is correct or not.
        this.page_metadata = constants.add_edit_payer_rules;
        this.payerrules_search_criteria = (session_storage.get('payerrules_search_criteria')) || this.payerrules_search_criteria;

        if (session_storage.get('payerrules_search_criteria')) {
            this.search_handler(this);
        }
        this.setState({
            show_render: true
        });
    };

    // Data formatter
    format_data = (rows, columns) => {
        let formatted_data = rows.map(row_data => {
            columns.filter(col_data => {
                switch (col_data.type) {
                    case 'currency':
                        row_data[col_data.field] =
                            row_data[col_data.field] != null || row_data[col_data.field] != undefined ? constants.currencyFormatter(row_data[col_data.field]) : constants.currencyFormatter(0);
                        break;
                    case 'boolean':
                        row_data[col_data.field] = row_data[col_data.field] ? true : false;
                        break;
                    case 'number':
                        row_data[col_data.field] = row_data[col_data.field];
                        break;
                    case 'string':
                        row_data[col_data.field] = row_data[col_data.field];
                        break;
                    default:
                        row_data[col_data.field] = row_data[col_data.field];
                }
            });
            return row_data;
        });
        return formatted_data;
    };

    /*Dropdown API call*/
    get_api_data = async () => {
        const dropdown_data = {};
        dropdown_data['payers'] = await this.get_payers();
        dropdown_data['insurance_class'] = await this.get_insurance_class();
        dropdown_data['payment_codes'] = await this.get_payment_codes();
        dropdown_data['adjustment_codes'] = await this.get_adjustment_codes();
        dropdown_data['is_loading_dropdown'] = false;
        this.setState(dropdown_data);
    }

    get_payers = () => {
        const url = this.page_metadata.api.payers.url;
        return constants_actions.get_data(this.token, url).then(
            response => {
                if (response.data && response.data.data) {
                    return response.data.data.map((val, index) => { return { key: index, value: val.payer_id, text: val.payer_name, data: val } });
                } else {
                    return [];
                }
            },
            error => {
                return [];
            }
        );
    }

    get_insurance_class = () => {
        const params = { page_size: global_constants.constants.PAGE_SIZE };
        return advanced_search_class(params, this.token).then(
            (response) => {
                if (response.data && response.data.data) {
                    let data = response.data.data.sort((obj1, obj2) => {
                        let code1 = obj1.insurance_class_code || '';
                        let code2 = obj2.insurance_class_code || '';
                        const value1 = code1.toString().toLowerCase();
                        const value2 = code2.toString().toLowerCase();
                        if (value1 < value2) {
                            return -1;
                        }
                        if (value1 > value2) {
                            return 1;
                        }
                        return 0;
                    });
                    return data.map((val, index) => { return { key: index, value: val.id, text: insurance_name(val), data: val } })
                } else {
                    return [];
                }
            },
            error => {
                return [];
            });
    }

    get_payment_codes = () => {
        if (this.props.user_login_details.master_data &&
            this.props.user_login_details.master_data.data &&
            this.props.user_login_details.master_data.data.payment_code) {
            let payment_code_data = this.props.user_login_details.master_data.data.payment_code.filter(code => code.is_active == true);
            let data = payment_code_data.sort((obj1, obj2) => {
                let desc1 = obj1.payment_desc || '';
                let desc2 = obj2.payment_desc || '';
                const value1 = desc1.toString().toLowerCase();
                const value2 = desc2.toString().toLowerCase();
                if (value1 < value2) {
                    return -1;
                }
                if (value1 > value2) {
                    return 1;
                }
                return 0;
            });
            return data.map((val, index) => {
                return {
                    key: index,
                    value: val.payment_id,
                    text: `${val.payment_code} - ${val.payment_desc}`,
                    data: val
                }
            });
        } else {
            return [];
        }
    }

    get_adjustment_codes = () => {
        return get_adjustment_code(this.token).then(
            response => {
                if (response.data && response.data.data) {
                    let data = response.data.data.sort((obj1, obj2) => {
                        let desc1 = obj1.adjustment_desc || '';
                        let desc2 = obj2.adjustment_desc || '';
                        const value1 = desc1.toString().toLowerCase();
                        const value2 = desc2.toString().toLowerCase();
                        if (value1 < value2) {
                            return -1;
                        }
                        if (value1 > value2) {
                            return 1;
                        }
                        return 0;
                    });
                    return data.map((val, index) => {
                        return {
                            key: index,
                            value: val.adjustment_code_id,
                            text: `${val.adjustment_code} - ${val.adjustment_desc}`,
                            data: val
                        }
                    })
                } else {
                    return [];
                }
            },
            error => {
                return [];
            }
        );
    }

    //On double click of grid, it redirects user to view page.
    on_row_double_clicked = row_data => {
        this.code_id = row_data['payer_rule_id'];
        let is_id_exist = this.state.payers.findIndex(item => item.value === row_data.payer_id);
        let payer_options = [...this.state.payers];
        if (is_id_exist == -1) {
            payer_options.push({
                key: 0,
                text: row_data.payer_id,
                value: row_data.payer_id
            })
        }
        let data = {
            payers: payer_options,
            insurance_class: this.state.insurance_class,
            payment_codes: this.state.payment_codes,
            adjustment_codes: this.state.adjustment_codes
        };
        this.props.history.push(`${this.page_metadata.base_url}/${this.page_name}/${this.code_id}`, data);
    };

    onChangeDropdownHandler = e => {
        const { value, name } = e.target;
        this.payerrules_search_criteria[name] = value;
        this.setState({
            payerrules_search_criteria: this.payerrules_search_criteria
        });
    };

    //Input change handler.
    input_change_handler = e => {
        const { value, checked, type, name } = e.target;
        if (type == 'checkbox') {
            this.payerrules_search_criteria[name] = checked;
        } else {
            this.payerrules_search_criteria[name] = value;
        }
        this.setState({
            payerrules_search_criteria: this.payerrules_search_criteria
        });
    };

    //Search button handler and used to search the data on the basis of search criteria.
    search_handler = e => {
        var url = this.page_metadata.api.search.url;
        for (let key in this.payerrules_search_criteria) {
            url = url.replace(key + '_value', encodeURIComponent(this.payerrules_search_criteria[key].toString().trim()));
        }
        this.setState({
            is_loading: true,
            is_search_button_disabled: true
        });
        constants_actions.get_search_data(this.token, url).then(
            response => {
                if (response.data) {
                    this.row_data = response.data.data ? response.data.data : [];
                    this.row_data = this.format_data(this.row_data, this.page_metadata.column_def);
                    const grid_height = get_columns(this.row_data, this.page_metadata.column_def);
                    this.total_column_width = grid_height.total_column_width;
                    this.grid_header_height = grid_height.header_height;
                    this.is_search_ui = true;
                    this.setState({
                        show_grid: true,
                        is_loading: false,
                        is_search_button_disabled: false,
                        payerrules_search_criteria: this.payerrules_search_criteria,
                        grid_rows_count: this.row_data && this.row_data.length
                    });
                    session_storage.set('payerrules_search_criteria', this.payerrules_search_criteria);
                }
            },
            error => {
                this.setState({
                    is_loading: false,
                    is_search_button_disabled: false
                });
                // TODO : do something here
                //                if (error.response.data) console.log(error.response.data.messages[0].message);
            }
        );
    };

    //Reset button the search control.
    clear_handler = e => {
        this.payerrules_search_criteria = { ...this.initial_payerrules_search_criteria };
        this.setState({
            show_grid: false,
            shown: false,
            payerrules_search_criteria: this.initial_payerrules_search_criteria,
            grid_rows_count: 0
        });
        this.is_search_ui = false;
        this.is_grid_visible = false;
        session_storage.remove('payerrules_search_criteria');
    };

    //New button handler.
    new_handler = e => {
        let data = {
            payers: this.state.payers,
            insurance_class: this.state.insurance_class,
            payment_codes: this.state.payment_codes,
            adjustment_codes: this.state.adjustment_codes
        };
        this.props.history.push(`${this.page_metadata.base_url}/${this.page_name}/add`, data);
    };

    //Function calls on initialization of Ag-Grid and catch its reference.
    get_grid_ref = grid_params => {
        this.setState({
            grid_params,
            grid_rows_count: grid_params.api.getModel().getRowCount()
        });
        var grid_width = document.getElementsByClassName('admin-wrapper')[0].clientWidth;
        if (this.total_column_width > (grid_width - this.width_padding)) {
            this.page_metadata.column_def.filter(item => {
                grid_params.columnApi.setColumnWidth(item.field, item.width, false);
            });
        }
    };

    //Used to create the title for export and print.
    set_title = separter => {
        this.company_name = this.props.user_login_details.user_data.data.company_name;
        this.current_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
        this.title = this.page_metadata.search_header;
        return this.title =
            this.title.replace(/<br>/g, separter) +
            separter +
            this.company_name +
            separter +
            this.current_date +
            separter +
            (this.state.grid_rows_count == 1
                ? this.state.grid_rows_count + ' record'
                : this.state.grid_rows_count + ' records') +
            separter +
            separter;
    };

    //Function calls on initialization of export report data
    on_export_button = () => {
        this.title = this.set_title('\n');
        this.page_name = this.page_name.replace(/<br>/g, '');
        export_print_utility.export_grid_data(this.state.grid_params, this.title, this.page_name);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Export);
    };

    //Function calls on initialization of Print report data
    on_print_button = () => {
        this.title = this.set_title('<br>');
        export_print_utility.print_grid_data(this.state.grid_params, this.title, this.page_name);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
    };

    private saveReportEventAction = (eventActionId: number) => {
        let reqBody = { reportId: "0", reportEventActionId: eventActionId };

        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        save_billing_audit(reqBody, accessToken);
    };
    on_grid_out = () => {
        set_focus_on_element_with_id('app_header_dropdown');
    };

    handle_search_button_tab = () => {
        setTimeout(() => {
            let searchButton = document.getElementById('search_button_id');
            let self = this;
            searchButton.addEventListener('keydown', function (event) {
                // apply a check where grid is empty
                if (!event.shiftKey && (self.row_data == null || (self.row_data.length == 0 && !self.state.show_grid))) {
                    set_focus_to_app_header(event);
                }
            });
        }, 200);
    };

    update_report_rows_count = () => {
        this.setState({
            grid_rows_count: this.state.grid_params.api.getModel().getRowCount()
        });
    };
    //It renders report grid and its search controls.
    render() {
        const payers = [this.select_option, ...this.state.payers];
        const insurance_class = [this.select_option, ...this.state.insurance_class];
        const payment_codes = [this.select_option, ...this.state.payment_codes];
        const adjustment_codes = [this.select_option, ...this.state.adjustment_codes];

        return (
            this.state.show_render &&
            (<LoaderComponent loading={this.state.is_loading || this.state.is_loading_dropdown}>
                <div className={'admin-wrapper ' + this.page_metadata.id} style={!this.state.show_grid ? { paddingBottom: 20 } : {}}>
                    <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                        <Grid.Column computer={16}>
                            <h3 className='ui header left aligned' dangerouslySetInnerHTML={{ __html: this.page_metadata.search_header }} />
                        </Grid.Column>
                    </Grid>
                    <div id='admin-scrollable-area' className='wrapper' style={this.state.show_grid ? { flex: 1 } : {}}>
                        <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container'>
                            <Form autoComplete='off' onSubmit={e => this.search_handler(e)}>
                                <Grid>
                                    <Grid.Column tablet={8} computer={4}>
                                        <Form.Field>
                                            <label>Payer</label>
                                            <Selection
                                                id='payer_id'
                                                name='payer_id'
                                                defaultValue={this.payerrules_search_criteria.payer_id}
                                                options={payers}
                                                onChange={(value, e) => {
                                                    this.onChangeDropdownHandler(e);
                                                }}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column tablet={8} computer={4}>
                                        <Form.Field>
                                            <label>Description</label>
                                            <Input
                                                autoComplete='off'
                                                id='description'
                                                name='description'
                                                maxLength={250}
                                                onChange={e => this.input_change_handler(e)}
                                                value={this.payerrules_search_criteria['description'] ? this.payerrules_search_criteria['description'] : ''}
                                                type='text'
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column tablet={8} computer={4}>
                                        <Form.Field>
                                            <label>Insurance Class</label>
                                            <Selection
                                                id='insurance_class_id'
                                                name='insurance_class_id'
                                                defaultValue={this.payerrules_search_criteria.insurance_class_id}
                                                options={insurance_class}
                                                onChange={(value, e) => {
                                                    this.onChangeDropdownHandler(e);
                                                }}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column tablet={8} computer={4}>
                                        <Form.Field>
                                            <label>Payment Code</label>
                                            <Selection
                                                id='payment_code_id'
                                                name='payment_code_id'
                                                defaultValue={this.payerrules_search_criteria.payment_code_id}
                                                options={payment_codes}
                                                onChange={(value, e) => {
                                                    this.onChangeDropdownHandler(e);
                                                }}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid>
                                <Grid>
                                    <Grid.Column tablet={8} computer={4}>
                                        <Form.Field>
                                            <label>Adjustment Code</label>
                                            <Selection
                                                id='adjustment_code_id'
                                                name='adjustment_code_id'
                                                defaultValue={this.payerrules_search_criteria.adjustment_code_id}
                                                options={adjustment_codes}
                                                onChange={(value, e) => {
                                                    this.onChangeDropdownHandler(e);
                                                }}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid>
                                <Grid style={{ margin: '0 -17px' }}>
                                    <Grid.Column computer={16} textAlign='right'>
                                        <Button id='clear_button_id' type='button' onClick={e => this.clear_handler(e)} basic content="Clear" />
                                        <Button id='new_button_id' type='button' onClick={e => this.new_handler(e)} basic content="New" />
                                        <Button id='search_button_id' type='submit' primary content="Search" />
                                    </Grid.Column>
                                </Grid>
                                {this.state.show_grid && (
                                    <Grid style={{ marginTop: '-1rem', marginBottom: 0 }}>
                                        <Grid.Column tablet={5} computer={4} textAlign='left'>
                                            <p style={{ fontSize: '16px' }}>Search Results</p>
                                        </Grid.Column>
                                        <Grid.Column tablet={5} computer={8} textAlign='center'>
                                            {
                                                <p style={{ fontSize: '16px', minHeight: 22 }}>
                                                    {`${this.state.grid_rows_count} ${
                                                        this.state.grid_rows_count == 1 ? ' record shown' : ' records shown'
                                                        }`}
                                                </p>
                                            }
                                        </Grid.Column>
                                        <Grid.Column tablet={2} computer={4} textAlign='right' />
                                    </Grid>
                                )}
                            </Form>
                        </div>
                        {this.state.show_grid && !this.state.is_loading && (
                            <GridView
                                id={`id_grid_${this.page_name}`}
                                row={this.row_data}
                                column={this.page_metadata.column_def}
                                style={{ height: '100%' }}
                                wrapperStyle={{ width: '100%', height: '100%', display: 'flex' }}
                                suppressMovableColumns={false}
                                enableColResize={true}
                                onRowDoubleClicked={this.on_row_double_clicked}
                                selectionType={'single'}
                                get_grid_ref={this.get_grid_ref}
                                suppressSizeToFit={true}
                                headerHeight={this.grid_header_height}
                                headerIdForTabNavigation={constants.search_grid_id}
                                onForceGridOut={this.on_grid_out}
                                on_filter_button_click={() => this.update_report_rows_count()}
                            />
                        )}
                    </div>
                    {this.state.show_grid && (
                        <div
                            className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                            id='applicationFooterSticky'
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                            <Grid.Column computer={16} textAlign='right'>
                                <Button
                                    id='export_report_button'
                                    type='submit'
                                    onClick={this.on_export_button}
                                    basic>Export</Button>
                                <Button
                                    onKeyDown={set_focus_to_app_header}
                                    id='print_report_button'
                                    type='submit'
                                    onClick={this.on_print_button}
                                    disabled={this.state.is_search_button_disabled}
                                    primary>Print</Button>
                            </Grid.Column>
                        </div>
                    )}
                </div>
            </LoaderComponent>)
        );
    }
}

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        selected_patient: state.patient_details.patient_header,
        user_login_details: state.user_login_details        
    };
};

export default withRouter(connect(mapStateToProps)(PayerRulesSearchComponent));