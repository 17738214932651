import * as user_constants from '../user_constants';

export default function (state = [], { type, payload }) {

    switch (type) {

        case user_constants.GET_USER_DETAILS:
            return { ...state, ["user_data"]: payload.data };

        case user_constants.UPDATE_USER_DETAILS:

            var state_object = Object.assign({}, state);
            state_object["user_data"]["data"] = payload.data.user_data;
            state_object["is_user_data_upated"] = payload.data;
            state = state_object;

            return { ...state };

        case user_constants.USER_IP_REGISTER:
            return { ...state, ["ip_registartion_response"]: payload.data };

        case user_constants.UPDATE_USER_PASSWORD:
            return { ...state, ["user_password"]: payload.data };

        case user_constants.USER_COMPANIES:
            return { ...state, ["user_companies_response"]: payload.data };

        case user_constants.USER_PARENT_COMPANIES:
            return { ...state, ["user_parent_companies_response"]: payload.data };

        default:
            return state
    }

}
