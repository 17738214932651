import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import { sso_login_redirect } from '../action/sso_action';
import { get_lauch_darkley_key_value, get_sso_token_value_from_url } from '../../shared/utility';
import { sso_config_data } from '../../global_constants'


export class SSORedirectComponent extends React.Component<any, any> {
    [x: string]: any;

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }
    componentDidMount() {
        this.sso_redirect();
    }

    sso_redirect = () => {       
        let token = get_sso_token_value_from_url(window.location.href,sso_config_data.sso_setup_token_key);

        if (token) {
            this.setState({
                loading: true
            })
            sso_login_redirect(token).then((response) => {
                window.location.href = "/sso/login";                
            })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            //if token not found
            //TODO: alert should be display or not
            window.location.href = "/login"
        }
    }

    render() {
        return (
            <>
                <Dimmer active={this.state.loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                {
                    // this.state.redirect ? <Redirect to="/sso/login" /> : ""
                }
            </>
        );
    }
}

export default SSORedirectComponent
