import * as global_constants from '../../global_constants';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request';
import * as login_constants from '../../login/login_constants';
import * as local_storage from '../../shared/local_storage_utility';
import { log_error } from '../../shared/action/shared_action';
/**
 * Verifies user at the time of login
 * @param Username 
 * @param Password 
 */
export const sso_user_authentication = (Username, Password) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.login_urls.sso_authenticate}`;
    var data = "user_name=" + Username + "&password=" + encodeURIComponent(Password);

    let request = new Request();
    let headers = {        
        'Content-Type': 'application/x-www-form-urlencoded'
    };

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': data
    });

    return request.make(request_config);
}

export const sso_user_verification_post = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.login_urls.sso_verification_post}`;
    var data = "token=" + token;
   
    let request = new Request();
    let headers = {        
        'Content-Type': 'application/x-www-form-urlencoded'
    }
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': data
    })
    
    return dispatch => request.make(request_config).then(
        response => {
            // Set Token Expiration Time
            if (response.data.data && response.data.data.token_details) {
                var expiresTime = new Date();
                expiresTime = new Date(expiresTime.setSeconds(expiresTime.getSeconds() + parseInt(response.data.data.token_details.expires_in)));

                response.data.data.token_details['expires_time'] = expiresTime;
                // set auth data in local storage
                local_storage.set("auth_data", response.data.data);
            }
            dispatch({
                type: login_constants.USER_AUTHENTICATION,
                payload: response
            })
        },
        error => {
            log_error(error)
        }
    );
}
/**
 * keeping my user alive
 * @param token 
 */
export const keep_alive = () => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.login_urls.keep_alive}`;
    var data = "";

    let request = new Request();
    let headers = {        
        'Content-Type': 'application/x-www-form-urlencoded'
    }
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': data
    })

   return request.make(request_config)
}

export const token_verify_post = () => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.login_urls.token_verify_post}`;
    let headers = {        
        'Content-Type': 'application/x-www-form-urlencoded'
    }
    var data = "";
    let request = new Request();
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': data
    })

   return request.make(request_config);
}
export const sso_login_redirect = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.login_urls.sso_login_redirect}?${global_constants.sso_config_data.sso_setup_token_key}=` + token;
    let headers = {        
    }
    let request = new Request();
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

   return request.make(request_config);
}

export const sso_login_reset = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.login_urls.sso_login_reset}?${global_constants.sso_config_data.sso_login_token_key}=${token}&application_id=14`;
    let headers = {
    }
    let request = new Request();
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'put'
    })

    return request.make(request_config);
}
// sso logout
export const sso_logout = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.login_urls.sso_logout}?${global_constants.sso_config_data.sso_login_token_key}=` + token;
    let headers = {
    }
    let request = new Request();
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'put'
    })

    return request.make(request_config);
}
//check if SSO mapping exist for logged in user, if they logged in by SSO
export const check_sso_user_mapping = (user_id) => {
    const url = `${global_constants.base_end_points}/sso/check_user_mapping?user_id=${user_id}&ref=${new Date().getTime()}`// `${global_constants.base_end_points}${global_constants.end_points.login_urls.sso_login_redirect}?${global_constants.sso_config_data.sso_setup_token_key}=` + token;
   
    let request = new Request();
    let request_config = new RequestConfig({
        'url': url,
        'method': 'get'
    })

    return request.make(request_config);
}


export const get_user_emr_facility = (user_id) => {
    const url = `${global_constants.base_end_points}/sso/user_emr_facility?user_id=${user_id}&ref=${new Date().getTime()}`// `${global_constants.base_end_points}${global_constants.end_points.login_urls.sso_login_redirect}?${global_constants.sso_config_data.sso_setup_token_key}=` + token;

    let request = new Request();
    let request_config = new RequestConfig({
        'url': url,
        'method': 'get'
    })

    return request.make(request_config);
}

export const sso_identity_application = () => {
    const url = `${global_constants.base_end_points_v2}${global_constants.end_points.oauth_urls.sso_identity_application}`;

    let request = new Request();
    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    });

    return request.make(request_config);
}

