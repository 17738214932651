import * as React from 'react'
import { connect } from 'react-redux';
import { Grid, GridColumn, Table, Button } from 'semantic-ui-react';
import  FeeDefinitionComponent  from './fee_definition_component';
import { expected_rate } from '../../constants';
import { argos_bill_1627_expected_rates_improvements } from "../../../../feature_flip_constant";
import { get_lauch_darkley_key_value } from '../../../../shared/utility';

export class FeeComponent extends React.Component<any, any>{
    _is_mounted = false;
   
    constructor(props) {
        super(props);
    }
    componentDidMount = () => {
        this._is_mounted = true;
    }

    shouldComponentUpdate(nextProps, nextState){
        let data = this.props;
        let new_data = nextProps;
        if (data.expected_rate_fees.length != new_data.expected_rate_fees.length) return true;
        if (JSON.stringify(data.expected_rate_fees) != JSON.stringify(new_data.expected_rate_fees)) return true;
        if (JSON.stringify(data.validation) !== JSON.stringify(nextProps.validation)) return true;
        if (JSON.stringify(data.proc_code_validation) != JSON.stringify(nextProps.proc_code_validation)) return true;
        return false
    }

    componentWillUnmount = () => {
        this._is_mounted = false;
    }

    add_new_fee_item = () => {
        this.props.addNewFeeItem && this.props.addNewFeeItem(this.props.expected_rate_id);
    }

    update_expected_rate_fee_definition_include_future_providers = (expected_rate_fee_id, identifier, value, future) => {
        this.props.updateExpectedRatFeeDefinitionIncludeFutureProviders && this.props.updateExpectedRatFeeDefinitionIncludeFutureProviders(expected_rate_fee_id, identifier, value, future);
    }

    update_expected_rate_fee_definition = (expected_rate_fee_id, identifier, value) => {
        this.props.updateExpectedRatFeeDefinition && this.props.updateExpectedRatFeeDefinition(expected_rate_fee_id, identifier, value);
    }

    update_expected_rate_fee_definition_procedure_code = (expected_rate_fee_id, procedure_code_id, identifier, value) => {
        this.props.updateExpectedRatFeeDefinitionProcedureCode && this.props.updateExpectedRatFeeDefinitionProcedureCode(expected_rate_fee_id, procedure_code_id, identifier, value);
    }

    copy_expected_rate_fee_definition = (expected_rate_fee_id) => {
        this.props.copyExpectedRateFeeDefinition && this.props.copyExpectedRateFeeDefinition(expected_rate_fee_id);
    }

    delete_expected_rate_fee_definition_prompt = (expected_rate_fee_id) => {
        this.props.deleteExpectedRateFeeDefinitionPrompt && this.props.deleteExpectedRateFeeDefinitionPrompt(expected_rate_fee_id);
    }
    
    get_fee_type_list = () => {
        let formatted_fee_type = [];
        this.props.fee_rate_type_list.map((fee_type) => {
            formatted_fee_type.push({
                'value': fee_type.expected_rate_fee_rate_type_id,
                'text': fee_type.expected_rate_fee_rate_type_name
            });
        })
        return formatted_fee_type;
    }

    get_formatted_location_list = (expected_rate_fee_id) => {
        var locations_list = [];
        var obj;
        locations_list = this.props.location_list.map((location) => {
            obj = {};
            obj['value'] = location.place_of_service_id;
            obj['name'] = location.display_name;
            obj['is_disabled'] =
                (this.get_location_usage_across_expected_rate_fees(expected_rate_fee_id, location.place_of_service_id).length == this.props.provider_list.length) ||
                this.location_provider_combination_exsist(expected_rate_fee_id, location.place_of_service_id);
            return obj;
        });
        return locations_list;
    }

    get_all_providers_across_expected_rate_fees = (expected_rate_fee_id, provider_id) => {
        let all_taken_location_ids = [];
        this.props.expected_rate_fees.map((expected_rate_fee_obj) => {
            if (expected_rate_fee_obj.is_active == true && expected_rate_fee_obj.expected_rate_fee_id != expected_rate_fee_id && expected_rate_fee_obj.assigned_provider_ids.includes(provider_id)) {
                expected_rate_fee_obj.assigned_location_ids.map((location_id) => {
                    if (all_taken_location_ids.indexOf(location_id) == -1)
                        all_taken_location_ids.push(location_id);
                });
            }
        });
        return all_taken_location_ids;
    }

    get_location_usage_across_expected_rate_fees = (expected_rate_fee_id, location_id) => {
        let all_taken_provider_ids = [];
        this.props.expected_rate_fees.map((expected_rate_fee_obj) => {
            if (expected_rate_fee_obj.is_active == true && expected_rate_fee_obj.expected_rate_fee_id != expected_rate_fee_id && expected_rate_fee_obj.assigned_location_ids.includes(location_id)) {
                expected_rate_fee_obj.assigned_provider_ids.map((provider_id) => {
                    if (all_taken_provider_ids.indexOf(provider_id)== -1)
                        all_taken_provider_ids.push(provider_id);
                });
            }
        });
        return all_taken_provider_ids;
    }

    get_expected_rate_fee_providers_list = (expected_rate_fee_id) => {
        let providers = []
        this.props.expected_rate_fees.map((fee) => {
            if (fee.is_active == true && fee.expected_rate_fee_id == expected_rate_fee_id) {
                providers = fee.assigned_provider_ids;
            }
        });
        return providers;
    }

    location_provider_combination_exsist = (expected_rate_fee_id, location_id) => {
        let provider_lst = this.get_expected_rate_fee_providers_list(expected_rate_fee_id);
        let current_combination_exsist = [];
        let exists = false;

        provider_lst.map((provider_id) => {
            current_combination_exsist = this.props.expected_rate_fees.filter((expected_rate_fee_obj) => {
                if (expected_rate_fee_obj.is_active == true && expected_rate_fee_obj.expected_rate_fee_id != expected_rate_fee_id) {
                    if (expected_rate_fee_obj.assigned_location_ids.includes(location_id) && expected_rate_fee_obj.assigned_provider_ids.includes(provider_id)) {
                        exists = true;
                        return true;
                    }
                }
            });
        });
        let result = (current_combination_exsist && current_combination_exsist.length > 0);
        //return result;
        return exists;
    }

    get_expected_rate_fee_locations_list = (expected_rate_fee_id) => {
        let locations = []
        this.props.expected_rate_fees.map((fee) => {
            if (fee.is_active == true && fee.expected_rate_fee_id == expected_rate_fee_id) {
                locations = fee.assigned_location_ids;
            }
        });
        return locations;
    }

    provider_location_combination_exsist = (expected_rate_fee_id, provider_id) => {
        let location_lst = this.get_expected_rate_fee_locations_list(expected_rate_fee_id);
        let current_combination_exsist = [];
        let exists = false;

        location_lst.map((location_id) => {
            current_combination_exsist = this.props.expected_rate_fees.filter((expected_rate_fee_obj) => {
                if (expected_rate_fee_obj.is_active == true && expected_rate_fee_obj.expected_rate_fee_id != expected_rate_fee_id) {
                    if (expected_rate_fee_obj.assigned_location_ids.includes(location_id) && expected_rate_fee_obj.assigned_provider_ids.includes(provider_id)) {
                        exists = true;
                        return true;
                    }
                }
            });
        });

        let result = (current_combination_exsist && current_combination_exsist.length > 0);
        //return result;
        return exists;
    }

    get_formatted_provider_list = (expected_rate_fee_id) => {
        var provider_list = [];
        var obj;
        provider_list = this.props.provider_list.map((provider) => {
            obj = {};
            obj['value'] = provider.provider_id;
            obj['name'] = provider.display_name;
            obj['is_disabled'] =
                (this.get_all_providers_across_expected_rate_fees(expected_rate_fee_id, provider.provider_id).length == this.props.location_list.length) ||
                this.provider_location_combination_exsist(expected_rate_fee_id, provider.provider_id);
            return obj;
        });
        return provider_list;
    }

    get_expected_rate_fee_validation = (expected_rate_fee_id) => {
        let rate_fee_validation = null;

        if (this.props.validation) {
            rate_fee_validation = this.props.validation.filter((rate_fee_validation_obj) => {
                if (rate_fee_validation_obj.expected_rate_fee_id == expected_rate_fee_id) return true;
            });
        }

        return rate_fee_validation && rate_fee_validation.length == 1 ? rate_fee_validation[0] : null;
    }

    render() {
        return (
            <React.Fragment>
                <Grid>
                    <GridColumn computer={16}>
                        <Grid>
                            <Grid.Column computer={16}>
                                <div className='customtable expected_rate_fee_table'>
                                        <Table>
                                            <Table.Header>      
                                                <Table.Row>
                                                <Table.HeaderCell style={{ width: '3%' }}></Table.HeaderCell>
                                                <Table.HeaderCell style={{ width: '12%' }}>Locations</Table.HeaderCell>
                                                <Table.HeaderCell style={{ width: '12%' }}>Providers</Table.HeaderCell>
                                                <Table.HeaderCell style={{ width: '12%' }}>Fee/Rate</Table.HeaderCell>
                                                <Table.HeaderCell style={{ width: '7%' }}> </Table.HeaderCell>
                                                <Table.HeaderCell style={{ width: '6%' }}> </Table.HeaderCell>
                                                {this.props.features.argosBill1627ExpectedRatesImprovements && <Table.HeaderCell style={{ width: '7%' }}> </Table.HeaderCell>}
                                                <Table.HeaderCell style={{ width: '8%' }}>Percentage</Table.HeaderCell>
                                                <Table.HeaderCell style={{ width: '8%' }}>Per Visit</Table.HeaderCell>
                                                <Table.HeaderCell style={{ width: '8%' }}>Per Eval</Table.HeaderCell>
                                                {this.props.features.argosBill1627ExpectedRatesImprovements && <Table.HeaderCell style={{ width: '8%' }}>Units</Table.HeaderCell>}
                                                <Table.HeaderCell textAlign='right' style={{ width: '12%' }}>
                                                    <Button
                                                        id={'add_new_button_' + this.props.row_id}
                                                        className={'add-fee'}
                                                        type='button'
                                                        onClick={this.add_new_fee_item}
                                                    >
                                                        + Add New
                                                    </Button>
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        {this.props.expected_rate_fees && this.props.expected_rate_fees.length > 0 && (
                                                <Table.Body>
                                                {
                                                    this.props.expected_rate_fees.map((item) => {
                                                        if (!this.props.features.argosBill1627ExpectedRatesImprovements) {
                                                            if (item.fee_rate_type_id == expected_rate.fee_type.reduction_percent) return;
                                                        }
                                                        return (
                                                            <FeeDefinitionComponent
                                                                key={item.expected_rate_fee_id}
                                                                data={Object.assign({}, item)}
                                                                key_id={item.expected_rate_fee_id}
                                                                expected_rate_id={this.props.expected_rate_id}
                                                                fee_type_list={[...this.get_fee_type_list()]}
                                                                updateExpectedRatFeeDefinition={this.update_expected_rate_fee_definition}
                                                                updateExpectedRatFeeDefinitionIncludeFutureProviders={this.update_expected_rate_fee_definition_include_future_providers}
                                                                onProviderOpen={this.get_formatted_provider_list}
                                                                onLocationOpen={this.get_formatted_location_list}
                                                                is_save_in_progress={this.props.is_save_in_progress}
                                                                validation={Object.assign({}, this.get_expected_rate_fee_validation(item.expected_rate_fee_id))}
                                                                updateExpectedRatFeeDefinitionProcedureCode={this.update_expected_rate_fee_definition_procedure_code}
                                                                copyExpectedRateFeeDefinition={this.copy_expected_rate_fee_definition}
                                                                deleteExpectedRateFeeDefinitionPrompt={this.delete_expected_rate_fee_definition_prompt}
                                                                proc_code_validation={this.props.proc_code_validation}
                                                            />
                                                        );
                                                    })
                                                    }
                                                </Table.Body>
                                            )}
                                        </Table>
                                </div>
                            </Grid.Column>
                        </Grid>
                    </GridColumn>
                </Grid>
            </React.Fragment>
        )
    }
}

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        features: {
            argosBill1627ExpectedRatesImprovements: get_lauch_darkley_key_value(state.launch_darkly, argos_bill_1627_expected_rates_improvements),
        }
    };
};
export default connect(mapStateToProps)(FeeComponent);