import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Dimmer, Grid, Loader, Modal } from 'semantic-ui-react';
// Sub Layout
import GridView from '../../shared/component/grid';
import GridViewGroup from '../../shared/component/grid/grid_group_component';
import { get_output_reports, get_PDF_by_guid, get_scanned_documents, open_scanned_document, print_output_report, print_scanned_documents } from '../action/note_action';
import * as patient_constants from '../patient_constants';
import { set_focus_on_element_with_id } from '../../shared/tab_navigation_utility';
import { GRID_NAME } from '../../shared/component/grid/constants';
import * as global_constants from '../../../app/global_constants';

/* Implemented view patient's Medical record 
   -OUTPUT REPORT
      1) Output reports grid
      2) Output report print
   - SCANNED DOCUMENT
      1) Scanned document grid
      2) Scanned document print
    */

let report_items = [];
let document_items = [];
export class MedicalRecordComponent extends React.Component<any, any> {
    [x: string]: any;
    _is_mounted = false;
    constructor(props) {
        super(props);

        this.state = {
            shown: false,
            patient_id: props.match.params.patient_id ? props.match.params.patient_id : this.props.patient_details.patient_header.data.id,
            document_items: [],
            report_items: [],
            loading: false,
            scan_loading: false,
            pdf_content: '',
            encoded_image: '',
            show_print_medical_record_popup: false,
            show_print_scanned_document_popup: false,
            is_scanned_doc_image: false,
            double_click_selected_scanned_document: {}
        }
    }
  doc_formate: string = 'data:application/pdf;base64';
  doc_preview: string = '';
  
    componentDidMount() {
        this._is_mounted = true;
        this.medical_record_api_call(this.state.patient_id);
    }
    componentWillUnmount() {
        this._is_mounted = false;

    }
    componentDidUpdate(previousProps, previousState) {
        if (previousProps.match.params.patient_id != this.props.match.params.patient_id) {
            this.medical_record_api_call(this.props.match.params.patient_id);
        }
        if (previousProps.show_medical_record != this.props.show_medical_record) {
            if (this._is_mounted) {
                this.setState({ shown: !this.state.shown }); // show on change props
            }
        }
    }

    medical_record_api_call = (patient_id) => {
        //this.setState({
        //    loading: true
        //});
        //await this.props.get_output_reports(patient_id, this.props.user_login_details.user_data.data.token_details.access_token);
        //await this.props.get_scanned_documents(patient_id, this.props.user_login_details.user_data.data.token_details.access_token);
        //this.setState({
        //    loading: false
        //});
        this.get_scan_documents(patient_id);
        this.get_output_report_documents(patient_id)
    }

    get_scan_documents = async (patient_id) => {
        if (this._is_mounted) {
            this.setState({
                scan_loading: true
            });
        }
        await this.props.get_scanned_documents(patient_id, this.props.user_login_details.user_data.data.token_details.access_token);
        if (this._is_mounted) {
            this.setState({
                scan_loading: false
            });
        }
    }
    get_output_report_documents = async (patient_id) => {
        if (this._is_mounted) {
            this.setState({
                loading: true
            });
        }
        await this.props.get_output_reports(patient_id, this.props.user_login_details.user_data.data.token_details.access_token);
        if (this._is_mounted) {
            this.setState({
                loading: false
            });
        }
    }

    // toggle to show and hide Medical Record Component
    private toggle = () => {
        if (this._is_mounted) {
            this.setState({
                shown: !this.state.shown
            });
        }
    }

    generate_pdf = (data, type, is_report_double_click) => {
      const pdf_file = new Blob([data], {
        type: 'application/pdf'
      });
      if (pdf_file.size <= 0) {
        if (this._is_mounted) {
          this.setState({ loading: false });
        }
        if (type == "scanned_documents") {
          toastr.error(
            "",
            patient_constants.patient_messages.pdf_generation_error
          );
        } else {
          toastr.error("", patient_constants.patient_messages.report_not_found);
        }
        return false;
      } else {
        // For IE
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          if (this._is_mounted) {
            this.setState({
              loading: false
            });
          }
          window.navigator.msSaveOrOpenBlob(pdf_file);
        } else {
          if (!is_report_double_click) {
            const pdf_content = window.URL.createObjectURL(pdf_file);
            var win = open(pdf_content, 'medical_record', '');
            if (!win) {
              toastr.error('', global_constants.global_messages.popup_blocked);
            } else {
              win.focus();
              win.onload = () => {
                win.print();
                window.URL.revokeObjectURL(this.state.pdf_content);
              };
            }
            if (this._is_mounted) {
              this.setState({
                loading: false
              });
            }
          } else {
            this.doc_preview = this.arrayBufferToBase64(data);
            if (this._is_mounted) {
              this.setState({
                pdf_content: `${this.doc_formate},${this.doc_preview}`,
                isScannedDocImage: true,
                loading: false,
                show_print_medical_record_popup: true
              });
            }
          }
        }
      }
    };

    // Conver array buffer to base64
    arrayBufferToBase64(arrayBuffer) {
      return btoa(new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    }

    // **********************************Output Reports Print starts ***************************************//

    // Output Report print button function
    on_print_reports_click = (is_report_double_click, report_items) => {

        if (report_items.length > 0) {

            let is_print_report = true;
            let output_report_checked_ids = []
            for (let value of report_items) {
                if (value.status == 'Final') {
                    let report_object = {
                        note_id: 0,
                        report_id: 0
                    }
                    report_object.note_id = Number(value.note_info.id);
                    report_object.report_id = Number(value.id);
                    output_report_checked_ids.push(report_object);
                }
                else {
                    is_print_report = false;
                }
            }

            if (!is_print_report) {
                toastr.warning('', patient_constants.patient_messages.finalized_report_only_print);
                return;
            }
            if (output_report_checked_ids.length == 0) {
                toastr.warning('', patient_constants.patient_messages.output_report_only_print);
                return;
            }

            // get print data 
            this.get_report_print_data(output_report_checked_ids, is_report_double_click)
        }
        else {
            toastr.warning('', patient_constants.patient_messages.select_finalized_reports);
        }

    }

    //Check for touch device
    checkTouchDevice = () => {
        return true == ("ontouchstart" in window || (window as any).DocumentTouch);
    }

    // API call to get print data for Output reports
    get_report_print_data = (output_report_checked_ids, is_report_double_click) => {
        if (this._is_mounted) {
            this.setState({
                loading: true
            });
        }
        var _this = this
        print_output_report(this.props.patient_details.patient_header.data.id, output_report_checked_ids, this.props.user_login_details.user_data.data.token_details.access_token).then(async response => {

            this.generate_pdf(response.data.data, 'output_report', is_report_double_click);

        },
            function (error) {
              if (_this._is_mounted) {
                    _this.setState({ loading: false });
                }
                if (error.response && error.response.data && error.response.data.messages) {

                    toastr.error('', error.response.data.messages[0].message);
                }
            })
    }

    // open close modal popup. (toggle state) - Print Output Reports 
    toggle_print_output_report_modal_state = () => {
        if (this._is_mounted) {
            this.setState({
                show_print_medical_record_popup: !this.state.show_print_medical_record_popup
            });
        }
    };

    // Double click of output report- generate print
    on_report_double_click = (selectedRows) => {
        if (selectedRows.id) {
            var arr = [selectedRows]
            this.on_print_reports_click(true, arr)
        }
    }

    // **********************************Output Reports Print ends *****************************************//

    // **********************************Scanned Documents Print starts *************************************//


    // open close modal popup. (toggle state) - Print Output Reports 
    toggle_print_scanned_document_modal_state = () => {
        if (this._is_mounted) {
            this.setState({
                show_print_scanned_document_popup: !this.state.show_print_scanned_document_popup
            });
        }
    };

    // Double click of scanned document- generate print
    on_scanned_document_double_click = (selectedRow) => {
        var arr = [selectedRow];
        if (this._is_mounted) {
            this.setState({
                loading: true,
                double_click_selected_scanned_document: arr
            });
        }
        if (this.checkTouchDevice()) { }
        else {

            const encoded_mage = "";

            let _this = this;

            open_scanned_document(this.props.patient_details.patient_header.data.id, selectedRow.id, this.props.user_login_details.user_data.data.token_details.access_token).then(async response => {

                if (response.data.data) {
                    if (response.data.data.image_index_id > 0) {
                        var arr = [response.data.data.image_index_id]
                        this.get_scanned_documents_for_print(arr)
                    }
                    else {
                        const document_ids = [];
                        document_ids.push(selectedRow.id);
                        const encoded_image = response.data.data.encoded_image;
                        if (this._is_mounted) {
                            _this.setState({
                                loading: false,
                                is_scanned_doc_image: true,
                                show_print_scanned_document_popup: true,
                                encoded_image: encoded_image
                            });
                        }
                    }
                }



            },
                (error) => {
                    if (this._is_mounted) {
                        _this.setState({ loading: false });
                    }
                    if (error.response && error.response.data && error.response.data.messages) {

                        toastr.error('', error.response.data.messages[0].message);
                    }
                })
        }
    }

    // Scanned Documents print button function
    on_print_documents_click = (document_items) => {

        var document_length = document_items.length;
        if (document_length > 0) {
            if (this._is_mounted) {
                this.setState({ loading: true });
            }
            let document_ids = [];
            for (let value of document_items) {
                document_ids.push(value.id);
            }

            this.get_scanned_documents_for_print(document_ids)
        }
        else {

            toastr.warning('', patient_constants.patient_messages.select_scanned_doc);
        }
    }

    get_scanned_documents_for_print = (document_ids) => {

        var _this = this;
        print_scanned_documents(this.props.patient_details.patient_header.data.id, document_ids, this.props.user_login_details.user_data.data.token_details.access_token).then(async response => {

            if (response.data && response.data.data != null) {
                this.open_PDF_by_guid(response.data.data.key_guid);
            }
            else {
                if (this._is_mounted) {
                    _this.setState({ loading: false });
                }
            }

        },
            (error) => {
                if (_this._is_mounted) {
                    _this.setState({ loading: false });
                }
                if (error.response && error.response.data && error.response.data.messages) {

                    toastr.error('', error.response.data.messages[0].message);
                }
            })

    }

    open_PDF_by_guid = (key_guid) => {
        var _this = this
        get_PDF_by_guid(this.props.patient_details.patient_header.data.id, key_guid, this.props.user_login_details.user_data.data.token_details.access_token).then(async response => {

            this.generate_pdf(response.data.data, 'scanned_documents', false);

        },
             (error)=> {
                 if (_this._is_mounted) {
                    _this.setState({ loading: false });
                }
                if (error.response && error.response.data && error.response.data.messages) {

                    toastr.error('', error.response.data.messages[0].message);
                }
            })

    }

    // **********************************Scanned Documents Print ends *************************************//
    // /Output Reports selection callback
    on_report_check_changed = items => {
        if (this._is_mounted) {
            this.setState({
                report_items: [...items]
            });
        }
    }

    // Scanned Document selection callback
    on_document_check_changed = items => {
        if (this._is_mounted) {
            this.setState({
                document_items: [...items]
            });
        }
    }

    renderReports = () => {
        if (this.props.medical_output_reports) {
            return (<Grid.Column>
                <GridViewGroup
                    column={patient_constants.output_reports_configuration.column_defs}
                    row={this.props.medical_output_reports}
                    gridAutoHeight={this.props.is_patient_header ? false : true}
                    checkboxSelection={true}
                    onRowSelection={this.on_report_check_changed}
                    onRowDoubleClicked={this.on_report_double_click}
                    name={GRID_NAME.MEDICAL_RECORD}
                    headerIdForTabNavigation={patient_constants.medical_reord_output_reports_grid_header_id}
                />

            </Grid.Column>);
        }

        return (<Grid.Column style={{ textAlign: 'center' }}>Loading...</Grid.Column>);
    }

    renderDocuments = () => {
        if (this.props.medical_scanned_documents) {
            return (<Grid.Column>
                <GridView
                    column={patient_constants.scanned_documents_configuration.column_defs}
                    row={this.props.medical_scanned_documents}
                    gridAutoHeight={true}
                    checkboxSelection={true}
                    onRowSelection={this.on_document_check_changed}
                    onRowDoubleClicked={this.on_scanned_document_double_click}
                    handleCheckboxNavigation={true}
                    id={patient_constants.medical_record_scanned_documents_grid_header_id}
                    onGridOut={this.send_me_out_of_scanned_document_grid}
                    enableEnterOnNavigation={true}
                />
            </Grid.Column>)
        }

        return (<Grid.Column style={{ textAlign: 'center' }}>Loading...</Grid.Column>)
    }

    send_me_out_of_scanned_document_grid = () => {
        set_focus_on_element_with_id("print_document_report_button");
    }


    render() {
        var hidden = {
            display: this.props.shown ? "block" : "none"
        }
        return (
            <div className="common-forms-view" >


                <div className="content active">
                    <label>Output Reports</label>
                    <Dimmer active={this.state.loading}>
                        <Loader size='massive'>Loading</Loader>
                    </Dimmer>
                    {this.renderReports()}
                    {this.props.medical_output_reports && this.props.medical_output_reports.length > 0 &&
                        <Grid.Column computer={16} style={{ textAlign: 'right' }}>
                            <Button type='button' onClick={() => this.on_print_reports_click(false, this.state.report_items)} primary>Print</Button>
                        </Grid.Column>}

                    <label>Scanned Documents</label>
                    <Dimmer active={this.state.scan_loading}>
                        <Loader size='massive'>Loading</Loader>
                    </Dimmer>
                    {this.renderDocuments()}
                    {this.props.medical_scanned_documents && this.props.medical_scanned_documents.length > 0 &&
                        <Grid.Column computer={16} style={{ textAlign: 'right' }}>
                            <Button id="print_document_report_button" type='button' onClick={() => this.on_print_documents_click(this.state.document_items)} primary>Print</Button>
                        </Grid.Column>}
                </div>

                {this.state.pdf_content &&
                    <Modal
                        onClose={this.toggle_print_output_report_modal_state}
                        centered={false}
                        className="ledger-report-popup"
                        open={this.state.show_print_medical_record_popup}
                        closeIcon
                        closeOnDimmerClick={false}
                        id="output_report_print"
                    >
                        <Modal.Header>Print Preview</Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                <Grid>
                                    <Grid.Column mobile={16} tablet={16} computer={16}>
                                      <embed type='application/pdf' width='100%' height='700' src={this.state.pdf_content} />
                                    </Grid.Column>
                                </Grid>
                            </Modal.Description>
                        </Modal.Content>
                    </Modal>
                }

                <Modal
                    onClose={this.toggle_print_scanned_document_modal_state}
                    centered={false}
                    className="ledger-report-popup"
                    open={this.state.show_print_scanned_document_popup}
                    closeIcon
                    closeOnDimmerClick={false}
                    id="scanned_document_print"
                >
                    <Modal.Header>Print Preview</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Grid>
                                <Grid.Column mobile={16} tablet={16} computer={16}>
                                    <img width="100%" height="700" src={`data:image/JPEG;base64,${this.state.encoded_image}`}></img>

                                </Grid.Column>

                                <Grid.Column computer={16} style={{ textAlign: 'right' }} className="mar-t-15">
                                    <Button basic onClick={() => this.toggle_print_scanned_document_modal_state()}>Close</Button>
                                    <Button primary onClick={() => this.on_print_documents_click(this.state.double_click_selected_scanned_document)}>Print</Button>

                                </Grid.Column>

                            </Grid>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </div>

        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        get_output_reports: get_output_reports,
        get_scanned_documents: get_scanned_documents
    }, dispatch)
}

const mapStateToProps = ({ user_login_details, user_details, shared_details, patient_details }) => {
    return {
        user_login_details: user_login_details,
        user_details: user_details,
        shared_details: shared_details,
        patient_details: patient_details,
        medical_output_reports: patient_details.medical_output_reports,
        medical_scanned_documents: patient_details.medical_scanned_documents
    };
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MedicalRecordComponent));