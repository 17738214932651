// Providers search component
import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Dimmer, Form, Grid, Input, Loader } from 'semantic-ui-react';
import * as export_print_utility from '../../../reports/util/export_print_utility';
import GridView from '../../../shared/component/grid';
import * as session_storage from '../../../shared/session_storage_utility';
import { set_focus_on_element_with_id, set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import * as constants_actionss from '../action/constants_action';
import { get_columns, is_user_partner_customer_and_company_revEquip_revServe, get_lauch_darkley_key_value } from "./../../../shared/utility"
import * as constants from '../constants';
import * as local_storage from '../../../shared/local_storage_utility';
import * as global_constants from '../../../global_constants';
import * as ReportConstants from '../../../reports/report_constants';
import { BillingAuditLogPagesFeature, providerBillingSettingsFeatures } from '../constants';
import { withRouter } from 'react-router-dom'


class ProviderSearchComponent extends React.Component<any, any> {

    constructor(props) {
        super(props);
        // Define initial state value.
        this.state = {
            show_grid: false,
            show_render: true,
            provider_search_criteria: [],
            is_loading: false,
            is_search_button_disabled: false,
            shown: false,
            grid_rows_count: 0
        };
    }

    // Define initial properties and its value.
    _is_mounted = false;
    page_name: string = 'provider';
    page_route: string = '';
    unlisten: any;
    page_metadata: any;
    width_padding: number = 100;
    provider_search_criteria: any = {
        provider_code: '',
        first_name: '',
        last_name: '',
        show_inactive: false
    };
    initial_provider_search_criteria: any = {
        provider_code: '',
        first_name: '',
        last_name: '',
        show_inactive: false
    };
    token: string = '';
    row_data = [];
    is_search_ui: any = false;
    code_id: number = 0;
    is_grid_visible = false;
    title = '';
    current_date: any;
    company_name: any;
    total_column_width: number = 0;
    grid_header_height: number = 0;
    current_user_type: global_constants.enum_user_type_r3_id = 1;
    company_service_type = 0;
    reportId: string = "0";

    // Fetching the page_name and state.
    UNSAFE_componentWillMount = () => {
        this.get_page_metadata(this.props.location, null);
        var auth_data = local_storage.get("auth_data");
        if (auth_data) {
            this.current_user_type = auth_data.user_type_r3_id;
            this.company_service_type = auth_data.company_service_type;
        }
        this.unlisten = this.props.history.listen((location, action) => {
            this.get_page_metadata(location, action);
        });
    };

    componentDidMount = () => {
        document.body.classList.add('admin-framework');
        let elem = document.getElementById('search-criteria-container');
        let all_form_elem = elem.getElementsByTagName('input');
        all_form_elem[0].focus();
        this.handle_search_button_tab();
    };

    componentWillUnmount = () => {
        this.unlisten();
        document.body.classList.remove('admin-framework');
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[1] !== this.page_metadata.route && session_storage.remove(this.page_metadata.storage_key);
        }
    };

    //Use to fetch params and page metadata
    get_page_metadata = (location, action) => {
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;

        let parts = location.pathname.split('/');
        this.page_route = parts[1];
        switch (this.page_route) {
            case 'admin':
                this.page_metadata = constants.admin_provider;
                break;
            case 'billing':
                this.page_metadata = constants.billing_provider;
                break;
        }

        // filter out column for feature flip
        this.page_metadata.column_def = this.props.features.isProviderClaimHoldEnabled ? this.page_metadata.column_def : this.page_metadata.column_def.filter(c => c.field !== "claim_holds");

        this.provider_search_criteria = (session_storage.get(this.page_metadata.storage_key));
        if (!this.provider_search_criteria) {
            this.clear_handler(null);
        }

        if (session_storage.get(this.page_metadata.storage_key)) {
            this.search_handler(this);
        }
        this.setState({
            show_render: true
        });
    };

    // Data formatter
    format_data = (rows, columns) => {
        let formatted_data = rows.map(row_data => {
            columns.filter(col_data => {
                switch (col_data.type) {
                    case 'currency':
                        row_data[col_data.field] =
                            row_data[col_data.field] != null || row_data[col_data.field] != undefined ? constants.currencyFormatter(row_data[col_data.field]) : constants.currencyFormatter(0);
                        break;
                    case 'boolean':
                        row_data[col_data.field] = row_data[col_data.field] ? 'Active' : 'Inactive';
                        break;
                    case 'number':
                        row_data[col_data.field] = row_data[col_data.field];
                        break;
                    case 'string':
                        row_data[col_data.field] = row_data[col_data.field];
                        break;
                    default:
                        row_data[col_data.field] = row_data[col_data.field];
                }
            });
            return row_data;
        });
        return formatted_data;
    };

    //On double click of grid, it redirects user to view page.
    on_row_double_clicked = selected_row_data => {
        this.code_id = selected_row_data['provider_id'];
        this.props.history.push(`${this.page_metadata.base_url}/${this.page_name}/${this.code_id}`);
    };

    //Input change handler.
    input_change_handler = e => {
        const { value, checked, type, name } = e.target;
        if (type == 'checkbox') {
            this.provider_search_criteria[name] = checked;
        } else {
            this.provider_search_criteria[name] = value;
        }
        this.setState({
            provider_search_criteria: this.provider_search_criteria
        });
    };

    //Search button handler and used to search the data on the basis of search criteria.
    search_handler = e => {
        var url = this.page_metadata.api.search.url;
        for (let key in this.provider_search_criteria) {
            url = url.replace(key + '_value', encodeURIComponent(this.provider_search_criteria[key].toString().trim()));
        }
        this.setState({
            is_loading: true,
            is_search_button_disabled: true
        });
        constants_actionss.get_search_data(this.token, url).then(
            response => {
                if (response.data) {
                    this.row_data = response.data.data ? response.data.data : [];
                    this.row_data = this.format_data(this.row_data, this.page_metadata.column_def);
                    const grid_height = get_columns(this.row_data, this.page_metadata.column_def);
                    this.total_column_width = grid_height.total_column_width;
                    this.grid_header_height = grid_height.header_height;
                    this.is_search_ui = true;
                    this.setState({
                        show_grid: true,
                        is_loading: false,
                        is_search_button_disabled: false,
                        provider_search_criteria: this.provider_search_criteria,
                        grid_rows_count: this.row_data && this.row_data.length
                    });
                    session_storage.set(this.page_metadata.storage_key, this.provider_search_criteria);
                }
            },
            error => {
                this.setState({
                    is_loading: false,
                    is_search_button_disabled: false
                });
                // TODO : do something here
                //                if (error.response.data) console.log(error.response.data.messages[0].message);
            }
        );
    };

    //Reset button the search control.
    clear_handler = e => {
        this.provider_search_criteria = { ...this.initial_provider_search_criteria };
        this.setState({
            show_grid: false,
            shown: false,
            provider_search_criteria: this.initial_provider_search_criteria,
            grid_rows_count: 0
        });
        this.is_search_ui = false;
        this.is_grid_visible = false;
        session_storage.remove(this.page_metadata.storage_key);
    };

    //New button handler.
    new_handler = e => {
        this.props.history.push(`${this.page_metadata.base_url}/${this.page_name}`);
    };

    //Function calls on initialization of Ag-Grid and catch its reference.
    get_grid_ref = grid_params => {
        this.setState({
            grid_params,
            grid_rows_count: grid_params.api.getModel().getRowCount()
        });
        var grid_width = document.getElementsByClassName('admin-wrapper')[0].clientWidth;
        if (this.total_column_width > (grid_width - this.width_padding)) {
            this.page_metadata.column_def.filter(item => {
                grid_params.columnApi.setColumnWidth(item.field, item.width, false);
            });
        }
    };

    //Used to create the title for export and print.
    set_title = separter => {
        this.company_name = this.props.user_login_details.user_data.data.company_name;
        this.current_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
        this.title = this.page_metadata.search_header;
        return this.title =
            this.title.replace(/<br>/g, separter) +
            separter +
            this.company_name +
            separter +
            this.current_date +
            separter +
            (this.state.grid_rows_count == 1
                ? this.state.grid_rows_count + ' record'
                : this.state.grid_rows_count + ' records') +
            separter +
            separter;
    };

    //Function calls on initialization of export report data
    on_export_button = () => {
        this.title = this.set_title('\n');
        this.page_name = this.page_name.replace(/<br>/g, '');
        export_print_utility.export_grid_data(this.state.grid_params, this.title, this.page_name);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Export);
    };

    //Function calls on initialization of Print report data
    on_print_button = () => {
        this.title = this.set_title('<br>');
        export_print_utility.print_grid_data(this.state.grid_params, this.title, this.page_name);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
    };

    // Function calls save billing audit on Export or Print
    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;

        const payload: ReportConstants.IPayloadForBillingAudit = {
            reportId: this.reportId,
            contextTitle: `${this.page_metadata.search_header} - ${ReportConstants.ReportEventAction[eventActionId]}`,
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: ReportConstants.ReportEntityTypeId.C_PROVIDER,
            data: { Records: this.state.grid_params.api.rowModel.rowsToDisplay.length }
        };

        const reqBody = export_print_utility.generateAuditDataEntryPayload(payload);

        ReportConstants.saveAuditPrintExport(reqBody, accessToken);
    };

    on_grid_out = () => {
        set_focus_on_element_with_id('app_header_dropdown');
    };

    handle_search_button_tab = () => {
        setTimeout(() => {
            let searchButton = document.getElementById('search_button_id');
            let self = this;
            searchButton && searchButton.addEventListener('keydown', function (event) {
                // apply a check where grid is empty
                if (!event.shiftKey && (self.row_data == null || (self.row_data.length == 0 && !self.state.show_grid))) {
                    set_focus_to_app_header(event);
                }
            });
        }, 200);
    };

    update_report_rows_count = () => {
        this.setState({
            grid_rows_count: this.state.grid_params.api.getModel().getRowCount()
        });
    };
    //It renders report grid and its search controls.
    render() {
        return (
            this.state.show_render && (
                <React.Fragment>
                    <Dimmer active={this.state.is_loading}>
                        <Loader size='massive'>Loading</Loader>
                    </Dimmer>
                    <div className={'admin-wrapper ' + this.page_metadata.id} style={!this.state.show_grid ? { paddingBottom: 20 } : {}}>
                        <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                            <Grid.Column computer={16}>
                                <h3 className='ui header left aligned' dangerouslySetInnerHTML={{ __html: this.page_metadata.search_header }} />
                            </Grid.Column>
                        </Grid>
                        <div id='admin-scrollable-area' className='wrapper' style={this.state.show_grid ? { flex: 1 } : {}}>
                            <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container'>
                                <Form autoComplete='off' onSubmit={e => this.search_handler(e)}>
                                    <Grid>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Provider Code</label>
                                                <Input
                                                    autoComplete='off'
                                                    name='provider_code'
                                                    id='provider_code'
                                                    maxLength={50}
                                                    onChange={e => this.input_change_handler(e)}
                                                    value={this.provider_search_criteria['provider_code'] ? this.provider_search_criteria['provider_code'] : ''}
                                                    type='text'
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Last Name</label>
                                                <Input
                                                    autoComplete='off'
                                                    name='last_name'
                                                    id='last_name'
                                                    maxLength={50}
                                                    onChange={e => this.input_change_handler(e)}
                                                    value={this.provider_search_criteria['last_name'] ? this.provider_search_criteria['last_name'] : ''}
                                                    type='text'
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>First Name</label>
                                                <Input
                                                    autoComplete='off'
                                                    name='first_name'
                                                    id='first_name'
                                                    maxLength={50}
                                                    onChange={e => this.input_change_handler(e)}
                                                    value={this.provider_search_criteria['first_name'] ? this.provider_search_criteria['first_name'] : ''}
                                                    type='text'
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4} verticalAlign="middle">
                                            <Form.Field >
                                                <label><p className="ui-grid-label-empty">show_inactive</p></label>
                                                <Checkbox
                                                    name='show_inactive'
                                                    id='show_inactive'
                                                    onChange={e => this.input_change_handler(e)}
                                                    checked={this.provider_search_criteria['show_inactive'] ? this.provider_search_criteria['show_inactive'] : false}
                                                    label={'Include Inactive'}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid style={{ margin: '0 -17px' }}>
                                        <Grid.Column computer={16} textAlign='right'>
                                            <Button id='clear_button_id' type='button' onClick={e => this.clear_handler(e)} basic>Clear</Button>
                                            {is_user_partner_customer_and_company_revEquip_revServe(this.company_service_type, this.current_user_type) || !this.page_metadata.new_provider_button_enabled ? null :
                                                <Button id='new_button_id' type='button' onClick={e => this.new_handler(e)} basic>New</Button>}
                                            <Button id='search_button_id' type='submit' primary>Search</Button>
                                        </Grid.Column>
                                    </Grid>
                                    {this.state.show_grid && (
                                        <Grid style={{ marginTop: '-1rem', marginBottom: 0 }}>
                                            <Grid.Column tablet={5} computer={4} textAlign='left'>
                                                <p style={{ fontSize: '16px' }}>Search Results</p>
                                            </Grid.Column>
                                            <Grid.Column tablet={5} computer={8} textAlign='center'>
                                                {
                                                    <p style={{ fontSize: '16px', minHeight: 22 }}>
                                                        {`${this.state.grid_rows_count} ${
                                                            this.state.grid_rows_count == 1 ? ' record shown' : ' records shown'
                                                            }`}
                                                    </p>
                                                }
                                            </Grid.Column>
                                            <Grid.Column tablet={2} computer={4} textAlign='right' />
                                        </Grid>
                                    )}
                                </Form>
                            </div>
                            {this.state.show_grid && !this.state.is_loading && (
                                <GridView
                                    id={`id_grid_${this.page_name}`}
                                    row={this.row_data}
                                    column={this.page_metadata.column_def}
                                    style={{ height: '100%' }}
                                    wrapperStyle={{ width: '100%', height: '100%', display: 'flex' }}
                                    suppressMovableColumns={false}
                                    enableColResize={true}
                                    onRowDoubleClicked={this.on_row_double_clicked}
                                    selectionType={'single'}
                                    get_grid_ref={this.get_grid_ref}
                                    suppressSizeToFit={true}
                                    headerHeight={this.grid_header_height}
                                    headerIdForTabNavigation={constants.search_grid_id}
                                    onForceGridOut={this.on_grid_out}
                                    on_filter_button_click={() => this.update_report_rows_count()}
                                />
                            )}
                        </div>
                        {this.state.show_grid && (
                            <div
                                className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                                id='applicationFooterSticky'
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                                <Grid.Column computer={16} textAlign='right'>
                                    <Button
                                        id='export_report_button'
                                        type='submit'
                                        onClick={this.on_export_button}
                                        basic>Export</Button>
                                    <Button
                                        onKeyDown={set_focus_to_app_header}
                                        id='print_report_button'
                                        type='submit'
                                        onClick={this.on_print_button}
                                        disabled={this.state.is_search_button_disabled}
                                        primary>Print</Button>
                                </Grid.Column>
                            </div>
                        )}
                    </div>
                </React.Fragment>
            )
        );
    }
}

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        selected_patient: state.patient_details.patient_header,
        user_login_details: state.user_login_details,
        features: {            
            isProviderClaimHoldEnabled: get_lauch_darkley_key_value(state.launch_darkly, providerBillingSettingsFeatures.rsiB31057ProviderClaimsSettingsPane),
        }
    };
};

export default withRouter(connect(mapStateToProps)(ProviderSearchComponent));