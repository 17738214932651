export const readonly_checkbox_cell_renderer = (params) => {
    var input = document.createElement('input');
    input.type = "checkbox";
    input.checked = params.value;
    input.disabled = true;
    return input;
}

export const disabled_button_cell_renderer = (params) => {
    var input = document.createElement('input');

    input.type = "button";
    input.value = params.value;
    input.className = "grid_row_button";
    input.disabled = true;
    return input;
}


