
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LoaderComponent } from '../../../shared/component/loading_component'
import { remove_move_835s_constants, remove_move_835s_messages } from '../../dashboard/dashboard_constants';
import TextareaAutosize from 'react-textarea-autosize';
import { bindActionCreators } from 'redux';
import { Accordion, Button, Form, Grid, Icon, Input, Dimmer, Loader, Header, Label } from 'semantic-ui-react';
import { handle_click_on_enter, set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import { toastr as toaster } from "react-redux-toastr";
import * as session_storage from '../../../shared/session_storage_utility';
import { get_eob_keys, submit_remove_move } from '../action/remove_move_835s_actions';
import { log_error } from '../../../shared/action/shared_action';
import { custom_date_format, get_all_error, get_columns, show_html_content_toaster } from '../../../shared/utility';
import GridView from '../../../shared/component/grid';
import { messages, currencyFormatter, dateFormatter,   } from '../../../reports/report_constants';
import Selection from '../../../shared/component/selection_component';
import * as constants from '../../../admin/constants/constants';
import { user_companies } from '../../../charge/action/charge_action';
export class RemoveMove835sComponent extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            controls_is_expanded: true,
            is_search_disabled: false,
            is_submitted: false,
            grid_rows_count: 0,
            selected_eob_keys: [],
            show_grid: false,
            is_error_control: {
                eob_keys: false
            },
            error: {},
            companies: [],
            selected_company_id: '',
            selected_action: '',
            is_company_disabled: false
        }
    }

    search_criteria: any = {
        eob_keys: '',
    };
    token: string = this.props.user_login_details.user_data.data.token_details.access_token;
    is_mounted = false;
    validation_rule = /^[0-9]+(,[0-9]+)*$/;
    page_metadata: any = remove_move_835s_constants(this);
    eob_keys_column_defs: any[] = [];
    grid_data: any = {
        rows: []
    };
    total_column_width: number = 0;
    grid_header_height: number = 0;

    componentDidMount() {
        this.is_mounted = true;
        user_companies(this.token,false).then(response => {
            if (response && response.data && response.data.data) {
                this.setState({
                    companies: response.data.data.companies
                })
            }
        });
        document.body.classList.add('admin-framework');
        let stored_search_criteria = session_storage.get(this.page_metadata.session_storage_key);

        if (stored_search_criteria && stored_search_criteria.search_criteria) {
            let updatedCriteria = { ...stored_search_criteria.search_criteria };
            this.search_criteria = updatedCriteria;
            this.search_eob_keys();
        }
    };

    componentWillUnmount = () => {
        this.clear_handler();
        this.is_mounted = false;
        document.body.classList.remove('admin-framework');

        if (this.props.history.location) {
            let path = this.props.history.location.pathname,
                path_contains_this_page = path ? path.includes('remove_move_835s') : false;

            if (path_contains_this_page !== false) {
                session_storage.remove(this.page_metadata.session_storage_key);
            }
        }
    };

    controls_toggle = () => {
        if (this.is_mounted) {
            this.setState({
                controls_is_expanded: !this.state.controls_is_expanded
            });
        }
    };

    handle_change = (e) => {
        const { name, value } = e.target;
        this.search_criteria = {
            ...this.search_criteria,
            [name]: value,
        };
        this.setState((prev_state) => ({
            is_error_control: {
                ...this.state.is_error_control,
                [name]: value && value.trim() ? !this.validation_rule.test(this.search_criteria.eob_keys) : true,
            },
        }));
    };

    clear_handler = () => {
        if (this.is_mounted) {
            this.setState({
                show_grid: false,
                is_search_disabled: false,
                is_submitted: false,
                grid_rows_count: 0,
                is_error_control: {
                    eob_keys: false,
                },
                selected_company_id: "",
                selected_action: "",
                error: {}
            });
        }

        this.grid_data.rows = [];
        this.search_criteria = {
            eob_keys: "",
        };
        session_storage.remove(this.page_metadata.session_storage_key);
    };

    handle_navigation_after_last_element = (event) => {
        if (
            !event.shiftKey &&
            event.keyCode === "9" &&
            !this.state.show_grid
        ) {
            set_focus_to_app_header(event);
        }
    };

    has_invalid_eob_keys = () => {
        let is_error = false;
        let searched_eob_keys = this.search_criteria.eob_keys.split(',');
        if (!this.search_criteria.eob_keys && this.is_mounted) {
            is_error = true;
            toaster.error('', remove_move_835s_messages.required_field);
        } else {
            if (/\s/g.test(this.search_criteria.eob_keys)) {
                is_error = true;
                is_error && toaster.error('', remove_move_835s_messages.enter_valid_numbers_separated_by_commas);
            } else {
                let is_key_invalid = searched_eob_keys.every((item) => {
                    return parseInt(item) > 2147483648;
                });
                if (is_key_invalid) {
                    is_error = true;
                    is_error && toaster.error('', remove_move_835s_messages.at_least_one_835_is_invalid);
                } else {
                    is_error = !this.validation_rule.test(this.search_criteria.eob_keys);
                    is_error && toaster.error('', remove_move_835s_messages.enter_valid_numbers_separated_by_commas);
                }
            }
        }

        this.setState({
            is_error_control: {
                eob_keys: is_error
            }
        });

        return is_error;
    };

    get_grid_ref = (grid_params: {
        api: { getModel: () => { (): any; new(): any; getRowCount: { (): any; new(): any } } };
        columnApi: { setColumnWidth: (arg0: any, arg1: any, arg2: boolean) => void };
    }) => {
        this.setState({
            grid_params,
            grid_rows_count: grid_params.api.getModel().getRowCount()
        });

        let grid_width = document.getElementsByClassName('admin-wrapper')[0].clientWidth;

        if (this.total_column_width > grid_width) {
            this.eob_keys_column_defs.filter((item: { [x: string]: any; field: any }) => {
                grid_params.columnApi.setColumnWidth(item.field, item['width'], false);
            });
        }
    };

    on_check_box_selection = (items) => {
        if (this.state.selected_eob_keys.length !== items.length) {
            if (this.is_mounted) {
                this.setState({
                    selected_eob_keys: items
                });
            }
        }
    };

    row_data_formatter = (row_data, column_def) => {
        row_data.map((row) => {
            column_def.map((value) => {
                switch (value.type) {
                    case "date":
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined ? dateFormatter(row[value.field]) : "";
                        break;
                    case "currency":
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined
                                ? currencyFormatter(row[value.field])
                                : currencyFormatter(0);
                        break;
                    case "number":
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined ? parseInt(row[value.field]) : 0;
                        break;
                    default:
                        row[value.field] = row[value.field] != null || row[value.field] != undefined ? row[value.field] : "";
                }
            });
            return row;
        });
        return row_data;
    };

    search_eob_keys = async () => {
        if (this.is_mounted) {
            this.setState({
                loading: true,
                show_grid: false,
                is_search_disabled: true,
                is_submitted: true,
            });
        }

        this.grid_data.rows = [];
        this.eob_keys_column_defs = this.page_metadata.eob_keys_column_defs;
       
        let criteria = this.search_criteria;
        let param = `eob_keys=${criteria.eob_keys}`;

        if (this.has_invalid_eob_keys()) {
            if (this.is_mounted) {
                this.setState({
                    loading: false,
                    is_search_disabled: false
                });
            }
            return;
        }

        try {
            let response = await get_eob_keys(this.token, param);
            if (response && response.data) {
                if (response.data.data) {
                    let eob_keys_result = response.data.data;
                    eob_keys_result = this.row_data_formatter(eob_keys_result, this.eob_keys_column_defs);
                    this.grid_data.rows = eob_keys_result || [];
                    this.total_column_width = 0;
                    const grid_height = get_columns(this.grid_data.rows, this.eob_keys_column_defs);
                    this.grid_header_height = grid_height.header_height;
                    this.total_column_width = grid_height.total_column_width;

                    if (response.data.messages && response.data.messages.length > 0) {
                        toaster.error("", response.data.messages[0].message);
                    }

                    session_storage.set(this.page_metadata.session_storage_key, { search_criteria: criteria });

                    if (this.is_mounted) {
                        this.setState({
                            show_grid: true,
                            controls_is_expanded: false,
                            grid_rows_count: this.grid_data.rows && this.grid_data.rows.length,
                            selected_eob_keys: []
                        });
                    }
                } else {
                    toaster.error("", remove_move_835s_messages.at_least_one_835_is_invalid);
                    if (this.is_mounted) {
                        this.setState({
                            is_error_control: {
                                eob_keys: true
                            }
                        });
                    }
                }

                if (this.is_mounted) {
                    this.setState({
                        loading: false,
                        is_search_disabled: false,
                    });
                }
            }
        } catch (error) {
            if (this.is_mounted) {
                this.setState({
                    loading: false,
                    is_search_disabled: false,
                    is_error: false
                });
            }
            log_error(error);
            if (error.response.data) {
                const toastr_options = show_html_content_toaster(get_all_error(error.response.data));
                toaster.error("", toastr_options);
            }
        }

    };
   
    companies_dropdown_change = (e) => {
        this.setState({
            selected_company_id: e
        });
    };

    action_dropdown_change = (e) => {
        let disableCompany = false;
        
        if (e === "R") {
            disableCompany = true;
        }

        if (e.trim() !== "") {
            this.setState({
                selected_action: e,
                is_company_disabled: disableCompany,
                error: {}
            }); 
        } else {
            this.setState({
                selected_action: e,
                is_company_disabled: disableCompany
            });
        }
    };

    confirm_button_click = () => {
        if(this.state.selected_action.trim() === '') {
            toaster.error('', remove_move_835s_messages.required_field);
            let error = this.state.error
            error.action  = true;
            this.setState({error: error});
            return;
        }
        this.make_remove_move();
    }
    
    make_remove_move = () => {
        if (this.is_mounted) {
            this.setState({
                loading: true,
                is_search_disabled: true,
                is_submitted: true,
            });
        }

        const body = {
            eob_keys: this.state.selected_eob_keys.map(x => x.eob_key).join(','),
            action: this.state.selected_action,
            company_id: this.state.selected_company_id
        }  

        submit_remove_move(this.token, body).then(()=>{
            toaster.success('', this.state.selected_action === "M" ?
                 remove_move_835s_messages.succesfully_moved_835s : 
                 remove_move_835s_messages.succesfully_removed_835s);
        }).catch(response => {
            if (response && response.response) {
                toaster.error("", response.response.data);
            }
        }).finally(()=>{
            this.setState({
                loading: false,
            });
            this.clear_handler();
        });
    }
 
    render() {
        const { loading, controls_is_expanded, is_search_disabled, show_grid, is_error_control, error, selected_eob_keys, companies, selected_company_id, is_company_disabled, selected_action } = this.state;

        return (
            <>
                <Dimmer active={loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <div className={'admin-wrapper ' + this.page_metadata.id} style={!show_grid ? { paddingBottom: 20 } : {}}>
                    <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                        <Grid.Column computer={16}>
                            <h3 className='ui header left aligned' dangerouslySetInnerHTML={{ __html: this.page_metadata.title }} />
                        </Grid.Column>
                    </Grid>
                    <div id='admin-scrollable-area' className='wrapper' style={show_grid ? { flex: 1 } : {}}>
                        <div className='patient-search-form patient_search_bottom_padding common-forms-search' id='search-criteria-container'>
                            <Form autoComplete='off' >
                                <Grid>
                                    <Grid.Column
                                        computer={16}
                                        tablet={16}
                                        textAlign='left'
                                        className='accordionColumn'
                                        id='accordion-column'
                                    >
                                        <Accordion fluid styled className='common-accordion'>
                                            <Accordion.Title
                                                active={controls_is_expanded}
                                                index={0}
                                                onClick={this.controls_toggle}
                                            >
                                                <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name={controls_is_expanded ? 'angle up' : 'angle down'} />
                                                Search Criteria
                                            </Accordion.Title>
                                            <Accordion.Content active={controls_is_expanded}>
                                                <Grid>
                                                    <Grid.Column tablet={9} computer={13}>
                                                        <Form.Field >
                                                            <label>
                                                                <span style={{ fontWeight: 'bold' }}>Enter 835 Key(s)</span>
                                                                <span
                                                                    style={{ fontStyle: 'italic' }}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: ' (Separate each 835 Key with a comma)',
                                                                    }}
                                                                />
                                                                <span
                                                                    className={
                                                                        is_error_control.eob_keys
                                                                            ? 'req-alert'
                                                                            : 'req-alert_normal'
                                                                    }
                                                                    dangerouslySetInnerHTML={{ __html: ' (required)' }}
                                                                />
                                                            </label>
                                                            <TextareaAutosize
                                                                autoComplete="new-password"
                                                                name={'eob_keys'}
                                                                maxLength={250}
                                                                id={'eob_keys'}
                                                                onChange={(e) => this.handle_change(e)}
                                                                value={this.search_criteria.eob_keys || ""}
                                                                className={
                                                                    is_error_control.eob_keys
                                                                        ? 'req-background-inp'
                                                                        : ''
                                                                }
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid>
                                                <Grid>
                                                    <Grid.Column tablet={16} computer={16} textAlign='right'>
                                                        <Button
                                                            type='reset'
                                                            id='clear_button'
                                                            basic
                                                            onClick={this.clear_handler}
                                                            content={'Clear'}
                                                        />
                                                        <Button
                                                            id='search_error'
                                                            className='primary'
                                                            type='submit'
                                                            onKeyDown={this.handle_navigation_after_last_element}
                                                            style={{ marginRight: '0' }}
                                                            disabled={is_search_disabled}
                                                            content={'Search'}
                                                            onClick={this.search_eob_keys}
                                                        />
                                                    </Grid.Column>
                                                </Grid>
                                            </Accordion.Content>
                                        </Accordion>
                                    </Grid.Column>
                                </Grid>
                            </Form>
                        </div>
                        {show_grid && (
                                <GridView
                                    id={this.page_metadata.report_grid_id}
                                    row={this.grid_data.rows}
                                    column={this.eob_keys_column_defs}
                                    headerHeight={this.grid_header_height}
                                    suppressSizeToFit={false}
                                    emptyMessage={messages.no_records_found}
                                    get_grid_ref={this.get_grid_ref}
                                    headerIdForTabNavigation={this.page_metadata.report_grid_id}
                                    enableColResize={true}
                                    selectionType={'single'}
                                    style={{ height: '100%' }}
                                    wrapperClass = {'ws_grid_remove_move_835s'}
                                    suppressMovableColumns={false}
                                    onRowSelection={(item) => this.on_check_box_selection(item)}
                                    checkboxSelection={true}
                                    handleCheckboxNavigation={true}
                                />
                        )}
                    </div>
                    {show_grid && (
                        <div
                            className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area common-forms'
                            id='applicationFooterSticky'
                        >
                            <Grid>
                                <Grid.Column tablet={5} computer={8}>
                                 
                                </Grid.Column>
                                <Grid.Column tablet={4} computer={3} textAlign='right'>
                                    <Form.Field>
                                        <label>Action{' '}
                                            {
                                                <span
                                                    className={
                                                        error.action
                                                            ? 'req-alert'
                                                            : 'req-alert_normal'
                                                    }
                                                >(required)
                                                </span>
                                            }
                                        </label>
                                        <Selection
                                            id='action'
                                            name='action'
                                            options={constants.option_formater(
                                                remove_move_835s_constants(this).action_options,
                                                'text',
                                                'value',
                                                true, // to add blank object
                                                false, // to shorted object
                                                'Select'
                                            )}
                                            onChange={(value, e) => {
                                                this.action_dropdown_change(value);
                                            }}
                                            style={ error.action
                                                ? 'dropdown-options-wrap req-background-inp'
                                                : 'dropdown-options-wrap'}
                                            disabled={!selected_eob_keys ||  selected_eob_keys.length === 0}
                                            defaultValue={this.state.selected_action}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column tablet={4} computer={3} textAlign='right'>
                                    <Form.Field>
                                        <label>Move To{' '}
                                            {
                                                <span className= 'req-alert_normal'>(required)
                                                </span>
                                            }
                                        </label>
                                        <Selection
                                            id='companies'
                                            name='companies'
                                            options={constants.option_formater(
                                                companies,
                                                'company_name',
                                                'company_id',
                                                true, // to add blank object
                                                false, // to shorted object
                                                'Select'
                                            )}
                                            onChange={(value, e) => {
                                                this.companies_dropdown_change(value);
                                            }}
                                            style={'dropdown-options-wrap'}
                                            disabled={!selected_eob_keys ||  selected_eob_keys.length === 0 || is_company_disabled}
                                            defaultValue={this.state.selected_company_id}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column tablet={3} computer={2} textAlign='left'>
                                    <Button
                                        id='confirm'
                                        className='primary'
                                        type='submit'
                                        style={{ marginRight: '0', marginTop: '1.6em'}}
                                        disabled={!selected_eob_keys ||  selected_eob_keys.length === 0 || (selected_company_id.trim() === '' && selected_action !== "R")}
                                        content={'Confirm'}
                                        onClick={this.confirm_button_click}
                                    />     
                                </Grid.Column>
                            </Grid>
                        </div>
                    )}
                </div>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
        },
        dispatch
    );
};

const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoveMove835sComponent);