import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Dimmer, Grid, Loader, Header, Checkbox } from 'semantic-ui-react';
import * as ProcCodeReplacementConstants from '../constant/procedure_code_replacement_constants';
import GridView from "../../shared/component/grid";
import {
    get_procedure_code_replacements_criteria, get_procedure_code_replacements_insurance_class,
    get_procedure_code_replacements_payer, get_procedure_code_replacement
} from '../action/procedure_code_replacement_action';
import DrawerComponent from '../../shared/component/drawer_component';
import { Position } from '@blueprintjs/core';
import { custom_date_format, get_columns } from '../../shared/utility';
import { date_format } from '../../global_constants';
import { print_grid_data, export_grid_data, generateAuditDataEntryPayload } from "../../reports/util/export_print_utility";

import ClaimsScrubbingProcedureReplacementAddEditComponent from './claims_scrubbing_procedure_replacement_add_edit_component';
import ClaimsScrubbingProcedureReplacementAddEditNewComponent from './claims_scrubbing_procedure_replacement_add_edit_new_component';
import { AlertConfirm } from '../../shared/component/alert_confirm_component';
import * as ReportConstants from '../../reports/report_constants';
import { get_lauch_darkley_key_value } from '../../shared/utility';
import * as FFConstant from '../../feature_flip_constant';

export class ClaimsScrubbingProcedureReplacementComponent extends React.Component<any, any> {

    _is_mounted = false;
    _grid_ref = { api: null };
    _master_proc_code_replacement_list = [];
    _raw_proc_code_replacement_list = [];
    _display_proc_code_replacement = [];
     grid_header_height: number = 0;
    _criteria_list = [];
    _insurance_class_list = [];
    _payer_list = [];
    _proc_replacement_data = null;
    is_proc_replacement_data_updated = false;
    reportId: string = '0';

    //#region life cycle methods
    constructor(props) {
        super(props);
        this.state = {
            filter_options: {
                show_inactive: false,
                show_expired: false,
                show_future: false
            },
            loading: {
                proc_code_replacement_list:true,
                criteria: true,
                insurance_class: true,
                payer: true
            },
            alert: {
                display: false,
                message: '',
                action: '',
                title: '',
                label_yes: ''
            },
        };  
    }

    componentDidMount = () => {
        this._is_mounted = true;
        document.body.classList.add('admin-framework');
        this.load_criteria_list();
        this.load_insurance_class_list();
        this.load_payer_list();
        this.get_proc_code_replacement_list();
    }

    componentWillUnmount = () => {
        this._is_mounted = false;
        document.body.classList.remove('admin-framework');
        delete this._criteria_list;
        delete this._insurance_class_list;
        delete this._payer_list;
    }
    //#endregion 

    //#region Loading state update

    get_updated_loader_state = (identifier, state) => {
        let loading = this.state.loading;
        loading[`${identifier}`] = state;
        return loading;
    }

    update_loader_display = (identifier, state) => {
        let loading = this.state.loading;
        loading[`${identifier}`] = state;
        this.setState({ loading });
    }

    display_loader = () => {

        let display_loader = false

        if (
            this._is_mounted == false ||
            this.state.loading.proc_code_replacement_list == true ||
            this.state.loading.criteria == true ||
            this.state.loading.insurance_class == true ||
            this.state.loading._payer_list == true 
        ) {

            display_loader = true;
        }
        return display_loader;
    }
    //#endregion

    //#region Load and format data

    get_proc_code_replacement_list = async () => {
        if (this._is_mounted == false) return;
        let is_loading = true;
        this.update_loader_display(ProcCodeReplacementConstants.loading_identifier.proc_code_replacement_list, is_loading);
        await get_procedure_code_replacement(this.props.user_login_details.user_data.data.token_details.access_token)
            .then(response => {
                if (response.data.data) {
                    this._raw_proc_code_replacement_list = response.data.data;
                    let formatted_data = this.format_data(response.data.data);
                    this._master_proc_code_replacement_list = formatted_data;
                    this.set_grid_display(formatted_data);
                    this._display_proc_code_replacement = this.filter_data(this.state.filter_options);
                }
                else {
                    this._master_proc_code_replacement_list = [];
                    this._display_proc_code_replacement = [];
                    this._raw_proc_code_replacement_list = [];
                }
                is_loading = false;
            }, (error) => {
                is_loading = false;
                this._master_proc_code_replacement_list = [];
                this._display_proc_code_replacement = [];
                this._raw_proc_code_replacement_list = [];
            })


        if (!is_loading) this.update_loader_display(ProcCodeReplacementConstants.loading_identifier.proc_code_replacement_list, is_loading);
    }

    format_data = (raw_data) => {
        if (!this.props.features.kraken_claim_scrubbing_visibility_ff_key) {
            var raw_data_filtered = raw_data.filter(function (item, index, arr) {
                return (item.claim_processing_criteria.claim_processing_criteria_id == ProcCodeReplacementConstants.criteria_type.payer_include_insurance_classes
                    || item.claim_processing_criteria.claim_processing_criteria_id == ProcCodeReplacementConstants.criteria_type.payer_exclude_insurance_classes) ? false : true;
            });
            raw_data = raw_data_filtered
        }
        var data_list = [];
        var item = null;
        raw_data.map((data_item) => {
            let payers = [];
            let ins_classes = [];
            let payers_names = [];
            let ins_classes_names = [];
            let excluded = (data_item.claim_processing_criteria.claim_processing_criteria_id == ProcCodeReplacementConstants.criteria_type.payer_exclude_insurance_classes);
            if (data_item.claim_processing_criteria != null) {
                payers = data_item.claim_processing_criteria_source_details.filter(insClass => { return insClass.claim_processing_criteria_source_type_id === ProcCodeReplacementConstants.criteria_source_type.payer });
                ins_classes = data_item.claim_processing_criteria_source_details.filter(payer => { return payer.claim_processing_criteria_source_type_id === ProcCodeReplacementConstants.criteria_source_type.insurance_class });
                if (payers.length > 0) {
                    let payers_ids = payers.map(ids => ids.claim_processing_criteria_source_data_id);
                    payers_names = this._payer_list.filter(payerNames => { return payers_ids.indexOf(payerNames.value) > -1 }).map(item => item.name);
                }
                if (ins_classes.length > 0) {
                    let ins_class_ids = ins_classes.map(ids => ids.claim_processing_criteria_source_data_id);
                    ins_classes_names = this._insurance_class_list.filter(insClassNames => { return ins_class_ids.indexOf(insClassNames.value) > -1 }).map(item => item.name);
                }
            }
            item = {
                claim_processing_rule_id: data_item.claim_processing_rule_id,
                claim_processing_rule_name: data_item.claim_processing_rule_name,
                original_procedure_code: data_item.original_procedure_codes == null ? '' : data_item.original_procedure_codes.proc_code,
                replacement_procedure_code: data_item.replacement_procedure_codes == null ? '' : data_item.replacement_procedure_codes.claim_processing_result_data,
                status: data_item.is_active ? 'Active' : 'Inactive',
                last_updated_date_time: custom_date_format(data_item.last_updated, date_format.date_time_without_sec_format),
                last_updated_user: data_item.last_updated_user,
                is_active: data_item.is_active,
                claim_processing_criteria_source_details: data_item.claim_processing_criteria_source_details,
                claim_processing_criteria: data_item.claim_processing_criteria,
                start_date: data_item.start_date==null?null: custom_date_format(data_item.start_date, date_format["mm/dd/yyyy"]),
                end_date: data_item.end_date==null?null: custom_date_format(data_item.end_date, date_format["mm/dd/yyyy"])
            };

            if (this.props.features.kraken_claim_scrubbing_visibility_ff_key) {
                item.qualifying_criteria_payer = data_item.claim_processing_criteria == null ? '' : payers.length + ' selected',
                item.qualifying_criteria_insurance_class = data_item.claim_processing_criteria == null ? '' : ins_classes.length + (excluded == true ? ' excluded' : ' selected'),
                item.qualifying_criteria_payer_content = this.format_names_content(payers_names),
                item.qualifying_criteria_insurance_class_content = this.format_names_content(ins_classes_names);
            } else {
                item.qualifying_criteria = data_item.claim_processing_criteria == null ? '' : data_item.claim_processing_criteria_source_details.length + ' ' + data_item.claim_processing_criteria.claim_processing_criteria_name.toString() + ' selected';
            }


            data_list.push(item);
        });
        return data_list;
    }

    format_names_content = (names) => {
        let content = (names.length > 0 ? names.join(', ') : '');
        return content;
    }

    set_grid_display = (display_data) => {
        const grid_height = get_columns(
            display_data,
            (this.props.features.kraken_claim_scrubbing_visibility_ff_key ?
                ProcCodeReplacementConstants.grid_settings_criteria_double_column.column_defs : ProcCodeReplacementConstants.grid_settings_criteria_single_column.column_defs)
        );
        this.grid_header_height = grid_height.header_height;

    }

    filter_data = (filter_options) => {
        let filtered_list = [];
        let current_date = new Date();
        current_date.setHours(0, 0, 0, 0);

         filtered_list = this._master_proc_code_replacement_list.filter(item =>
            (
                 (new Date(item.start_date) <= current_date && (item.end_date == null || new Date(item.end_date) >= current_date)) // active/current records
                 || (filter_options.show_expired ? new Date(item.end_date) < current_date : false) //include expired records
                 || (filter_options.show_future ? new Date(item.start_date) > current_date : false)//include future records
            )
            && (!filter_options.show_inactive ? item.is_active == true : true)// apply show in-active
        );
        return filtered_list;
    }


    load_criteria_list = async () => {
        if (this._is_mounted == false) return;

        let criteria_data = [];
        let loading = true;
        this.update_loader_display(ProcCodeReplacementConstants.loading_identifier.criteria, loading);

        await get_procedure_code_replacements_criteria(this.props.user_login_details.user_data.data.token_details.access_token, ProcCodeReplacementConstants.page_rule_list)
            .then(
            (response) => {
                    if (response.data.data) {
                        criteria_data = response.data.data.map((val, index) => { return { value: val.claim_processing_criteria_id, text: val.claim_processing_criteria_name } })
                    }
                    loading = false;
                },
                (error) => {
                    loading = false;
                }
        );
        this._criteria_list = criteria_data;
        if (!loading) this.update_loader_display(ProcCodeReplacementConstants.loading_identifier.criteria, loading);
    }


    load_insurance_class_list = async () => {
        if (this._is_mounted == false) return;
        let loading = true;
        let insurance_class_data = [];
        this.update_loader_display(ProcCodeReplacementConstants.loading_identifier.insurance_class, loading);

        await get_procedure_code_replacements_insurance_class(this.props.user_login_details.user_data.data.token_details.access_token)
            .then(
            (response) => {
                    if (response.data.data) {
                        insurance_class_data = response.data.data.map((val, index) => { return { value: val.insurance_class_id, name: val.display_text, on_hover_msg: '' } })
                    }
                    loading = false;
                },
                (error) => {
                    loading = false;
                }
        );

        this._insurance_class_list = insurance_class_data;
        if (!loading) this.update_loader_display(ProcCodeReplacementConstants.loading_identifier.insurance_class, loading);
    }


    load_payer_list = async () => {
        if (this._is_mounted == false) return;
        let loading = true;
        let payer_data = [];
        this.update_loader_display(ProcCodeReplacementConstants.loading_identifier.payer, loading);

        await get_procedure_code_replacements_payer(this.props.user_login_details.user_data.data.token_details.access_token)
            .then(
            (response) => {
                    if (response.data.data) {
                        payer_data = response.data.data.map((val, index) => { return { value: val.payer_id, name: val.display_name, on_hover_msg: '' } })
                    }
                    loading = false;
                },
                (error) => {
                    loading = false;
                }
        );

        this._payer_list = payer_data;
        if (!loading) this.update_loader_display(ProcCodeReplacementConstants.loading_identifier.payer, loading);
    }
    //#endregion

    //#region Access methods for class variables
    read_criteria_data = () => {
        if (this.state.loading.criteria==false && (!this._criteria_list || this._criteria_list.length == 0)) {
            this.load_criteria_list().then(() => {
                return this._criteria_list;
            });
        } else {
            return this._criteria_list;
        }
    }

    read_insurance_class_data = () => {
        if (this.state.loading.insurance_class == false &&  (!this._insurance_class_list || this._insurance_class_list.length == 0)) {
            this.load_insurance_class_list().then(() => {
                return this._insurance_class_list;
            });
        } else {
            return this._insurance_class_list;
        }
    }

    read_payer_data = () => {
        if (this.state.loading.payer == false &&  (!this._payer_list || this._payer_list.length == 0)) {
            this.load_payer_list().then(() => {
                return this._payer_list;
            });
        } else {
            return this._payer_list;
        }
    }
    //#endregion

    //#region Event Handling
    update_filter_options = (event, filter_type) => {
        let filter_options = this.state.filter_options;
        if (filter_options[`${filter_type}`] != undefined) {
            filter_options[`${filter_type}`] = event.target.checked
        }
       
        this.update_loader_display(ProcCodeReplacementConstants.loading_identifier.proc_code_replacement_list, true);

        this._display_proc_code_replacement = this.filter_data(filter_options);

        let loading = this.get_updated_loader_state(ProcCodeReplacementConstants.loading_identifier.proc_code_replacement_list, false);

        this.setState({
            loading,
            filter_options: filter_options
        });
        
    }

    new_proc_code_replacement_handler = () => {
        this.is_proc_replacement_data_updated = false;;
        this._proc_replacement_data = {
            claim_processing_rule_id: 0,
            claim_processing_rule_name: '',
            original_procedure_codes: {
                claim_processing_procedure_code_id: 0,
                procedure_code_id: 0,
                proc_code: '',
                is_active: true,
                proc_desc1:''

            },
            replacement_procedure_codes: {
                claim_processing_result_id: 0,
                claim_processing_result_data: '',
                is_active: true,
                claim_processing_result_desc:''
            },
            is_active: true,
            claim_processing_criteria_source_details: [],
            claim_processing_criteria: {
                claim_processing_criteria_id: 0,
                claim_processing_criteria_name:''
            }
        }

        this.handleDrawerOpen();
    };

    //Used to create the title for export and print.
    set_title = separator => {
        let company_name = this.props.user_login_details.user_data.data.company_name;
        let current_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
        let title = ProcCodeReplacementConstants.page_title.name;
        let row_count = this._grid_ref.api.getModel().getRowCount();
        title =
            title.replace(/<br>/g, separator) +
            separator +
            company_name +
            separator +
            current_date +
            separator +
            (row_count == 1
            ? row_count + ' record'
            : row_count + ' records') +
            separator +
            separator;
        return title;
    };

    //Function calls on initialization of export report data
    on_export_button = () => {
        let title = this.set_title("\n");
        let page_name = ProcCodeReplacementConstants.page_title.name;
        page_name = page_name.replace(/<br>/g, "");
        export_grid_data(this._grid_ref, title, page_name);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Export);
    };

    //Function calls on initialization of Print report data
    on_print_button = () => {
        let title = this.set_title("<br>");
        let page_name = ProcCodeReplacementConstants.page_title.name;
        print_grid_data(this._grid_ref, title, page_name);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
    };

    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;

        const payload: ReportConstants.IPayloadForBillingAudit = {
            reportId: this.reportId,
            contextTitle: `${ProcCodeReplacementConstants.page_title.name} - ${ReportConstants.ReportEventAction[eventActionId]}`,
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: ReportConstants.ReportEntityTypeId.C_PROCEDURE_CODE,
            data: { Records: this._display_proc_code_replacement.length }
        }

        const reqBody = generateAuditDataEntryPayload(payload);

        ReportConstants.saveAuditPrintExport(reqBody, accessToken);
    }

    private on_row_clicked = (e) => {
        var proc_code_data = this._raw_proc_code_replacement_list.filter(x => x.claim_processing_rule_id == e.data.claim_processing_rule_id);
        if (proc_code_data.length > 0) {
            this._proc_replacement_data = JSON.parse(JSON.stringify(proc_code_data[0]));
        }
        this.handleDrawerOpen();
    }

    //#endregion Event Handling

    //#region drawer region
    private handleDrawerClose = () => {
        if (this.is_proc_replacement_data_updated) {
            this.alert_box_helper(true, ProcCodeReplacementConstants.prompt_settings.message, '',
                ProcCodeReplacementConstants.prompt_settings.unsaved_changes, ProcCodeReplacementConstants.prompt_settings.continue);
        }
        else {
            this.setState({ isDrawerOpen: false });
        }
    }

    private handleDrawerOpen = () => {
        this.setState({ isDrawerOpen: true });
    }

    private procCodeReplacementSaveCallback = (is_save_successful) => {
        if (is_save_successful) {
            this.get_proc_code_replacement_list().then(() => {
                this.setState({ isDrawerOpen: false });
            });
            
        }
    }

    private procCodeReplacementUpdateCallback = () => {
        this.is_proc_replacement_data_updated = true;
    }
    //#endregion

    //#region alert box region
    alert_box_helper = (show_alert_box, alert_msg, action, title, label_yes) => {
        this.setState({
            alert: {
                display: show_alert_box,
                message: alert_msg,
                action: action,
                title: title,
                label_yes: label_yes
            }
        })
    }

    handle_confirm = (e) => {
        e.preventDefault();         
        this.setState({
            alert: {
                display: false,
                message: '',
                action: '',
                title: '',
                label_yes: ''
            }
        });
        this.setState({ isDrawerOpen: false });
    }
    //#endregion

    //#region grid ref
    get_grid_ref = (grid_params) => {
        this._grid_ref = grid_params;
    };
    //#endregion
    
    //#region Content generating helper methods.
    get_render_contents = () => {
        let render_contents =
            <React.Fragment>
                <Grid style={{ marginTop: 0, marginBottom: 0 }}>
                    <Grid.Column mobile={4} tablet={8} computer={8} style={{ textAlign: 'left' }} className="proc_code_replacement_filters">
                        <Checkbox id={ProcCodeReplacementConstants.filter_options.show_inactive.settings.id}
                            label={ProcCodeReplacementConstants.filter_options.show_inactive.settings.name}
                            checked={this.state.filter_options.show_inactive}
                            onChange={(e) => this.update_filter_options(e, ProcCodeReplacementConstants.filter_options.show_inactive.type)}
                        />
                        <Checkbox id={ProcCodeReplacementConstants.filter_options.show_expired.settings.id}
                            label={ProcCodeReplacementConstants.filter_options.show_expired.settings.name}
                            checked={this.state.filter_options.show_expired}
                            onChange={(e) => this.update_filter_options(e, ProcCodeReplacementConstants.filter_options.show_expired.type)}
                        />
                        <Checkbox id={ProcCodeReplacementConstants.filter_options.show_future.settings.id}
                            label={ProcCodeReplacementConstants.filter_options.show_future.settings.name}
                            checked={this.state.filter_options.show_future}
                            onChange={(e) => this.update_filter_options(e, ProcCodeReplacementConstants.filter_options.show_future.type)}
                        />
                    </Grid.Column>
                    <Grid.Column mobile={4} tablet={8} computer={8} style={{ textAlign: 'right' }}>
                        <Button id={ProcCodeReplacementConstants.new_button.id} onClick={() => this.new_proc_code_replacement_handler()}>
                            {ProcCodeReplacementConstants.new_button.name}
                        </Button>
                    </Grid.Column>
                   
                </Grid>
            </React.Fragment>;

        return render_contents;
    }

    get_drawer_component = () => {
        let render_contents =
            <React.Fragment>
                <Grid style={{ marginTop: 0, marginBottom: 0 }}>
                    <Grid.Column computer={16} textAlign="right" className="proc_code_replacement_drawer_wrapper">

                        <DrawerComponent
                            key={this.props.key_id}
                            canOutsideClickClose={true}
                            canEscapeKeyClose={false}
                            isOpen={this.state.isDrawerOpen}
                            className="ext-bp-drawer proc_code_replacement_drawer"
                            handleDrawerClose={this.handleDrawerClose}
                            position={Position.RIGHT}
                            title=""
                            isCloseButtonShown={true}
                            innerContent={this.get_drawer_inner_content()}
                            size="40%"
                            disableAutoClose={true}
                        />
                    </Grid.Column>
                </Grid>
            </React.Fragment>;

        return render_contents;
    }

    get_drawer_inner_content = () => {
        
        let criteria_list_copy = [...this.read_criteria_data()];
        let payer_list_copy =[...this.read_payer_data()];
        let insurance_class_list_copy = [...this.read_insurance_class_data()];
        let drawer_content;

        if (this.props.features.kraken_claim_scrubbing_visibility_ff_key) {
            drawer_content = <ClaimsScrubbingProcedureReplacementAddEditNewComponent
                saveHandlerCallback={this.procCodeReplacementSaveCallback}
                updateHandlerCallback={this.procCodeReplacementUpdateCallback}
                data={this._proc_replacement_data}
                criteria_list={criteria_list_copy}
                payers_list={payer_list_copy}
                insurance_class_list={insurance_class_list_copy}
            />
        } else {
            var criteria_list_copy_filtered = criteria_list_copy.filter(function (item, index, arr) {
                return (item.value == ProcCodeReplacementConstants.criteria_type.payer_include_insurance_classes || item.value == ProcCodeReplacementConstants.criteria_type.payer_exclude_insurance_classes) ? false : true;
            });
            drawer_content = <ClaimsScrubbingProcedureReplacementAddEditComponent
                saveHandlerCallback={this.procCodeReplacementSaveCallback}
                updateHandlerCallback={this.procCodeReplacementUpdateCallback}
                data={this._proc_replacement_data}
                criteria_list={criteria_list_copy_filtered}
                payers_list={payer_list_copy}
                insurance_class_list={insurance_class_list_copy}
            />
        }
            

        return (drawer_content);
    };
    //#endregion

    render() {
        let show_loader = this.display_loader();
        return (
            <div className='admin-wrapper'>

                <Dimmer active={show_loader}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>

                <AlertConfirm
                    open={this.state.alert.display}
                    close={() => this.alert_box_helper(false, '', '', '', '')}
                    cancel={() => this.alert_box_helper(false, '', '', '', '')}
                    confirm={this.handle_confirm}
                    message={this.state.alert.message}
                    title={this.state.alert.title}
                    labelYes={this.state.alert.label_yes}
                />

                <Grid className='headerGrid' style={{ marginTop: 10, marginBottom: 10, marginRight: 2 }}>
                    <Grid.Column mobile={4} tablet={8} computer={16}>
                        <Header id={ProcCodeReplacementConstants.page_title.id} as='h3' textAlign="left">{ProcCodeReplacementConstants.page_title.name}</Header>
                    </Grid.Column>
                </Grid>

                <div id='admin-scrollable-area' className='wrapper' style={{ flex: 1 }}>
                        <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container'>
                            {this.get_render_contents()}
                        </div>
                   
                    {!show_loader && (this.props.features.kraken_claim_scrubbing_visibility_ff_key ? 
                        <GridView
                            id={ProcCodeReplacementConstants.grid_settings_criteria_double_column.grid_id}
                            row={this._display_proc_code_replacement}
                            column={ProcCodeReplacementConstants.grid_settings_criteria_double_column.column_defs}
                            style={{ height: '100%' }}
                            wrapperClass={'grid_wrapper'}
                            wrapperStyle={{ width: '100%', height: '100%', display: 'flex', flex: 1 }}
                            enableColResize={true}
                            onCellClicked={this.on_row_clicked}
                            selectionType={'single'}
                            headerHeight={this.grid_header_height}
                            get_grid_ref={this.get_grid_ref}
                            emptyMessage={ProcCodeReplacementConstants.grid_settings_criteria_double_column.no_records_found_message}
                        />
                        :
                        <GridView
                            id={ProcCodeReplacementConstants.grid_settings_criteria_single_column.grid_id}
                            row={this._display_proc_code_replacement}
                            column={ProcCodeReplacementConstants.grid_settings_criteria_single_column.column_defs}
                            style={{ height: '100%' }}
                            wrapperClass={'grid_wrapper'}
                            wrapperStyle={{ width: '100%', height: '100%', display: 'flex', flex: 1 }}
                            enableColResize={true}
                            onCellClicked={this.on_row_clicked}
                            selectionType={'single'}
                            headerHeight={this.grid_header_height}
                            get_grid_ref={this.get_grid_ref}
                            emptyMessage={ProcCodeReplacementConstants.grid_settings_criteria_single_column.no_records_found_message}
                        />
                    )}

                    {!show_loader && this.state.isDrawerOpen && this.get_drawer_component()}

                </div>

                <div className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area' id='applicationFooterSticky'>
                    <Grid textAlign='right' style={{ marginTop: 0.5, marginBottom: 0.5 }}>
                        <Grid.Column computer={16} textAlign='right'>
                            <Button id={ProcCodeReplacementConstants.export_button.id} type='submit' onClick={() => this.on_export_button()} basic>
                                {ProcCodeReplacementConstants.export_button.name}
                            </Button>
                            <Button id={ProcCodeReplacementConstants.print_button.id} type='submit' onClick={() => this.on_print_button()} primary>
                                {ProcCodeReplacementConstants.print_button.name}
                            </Button>
                        </Grid.Column>
                    </Grid>
                </div>

            </div>
        );
    }
}

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
        features: {            
            kraken_claim_scrubbing_visibility_ff_key: get_lauch_darkley_key_value(state.launch_darkly, FFConstant.kraken_claim_scrubbing_visibility_ff_key),
        }
    };
};

export default connect(mapStateToProps)(ClaimsScrubbingProcedureReplacementComponent);