import * as global_constants from '../../global_constants';
import { log_error } from '../../shared/action/shared_action';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request';

export const get_company_full_detail = (token, company_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.company_urls.company}/${company_id}${global_constants.end_points.company_urls.company_full_detail}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(request_config);
}
export const add_company = (token, company) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.company_urls.company}`;

    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': company
    })

    return request.make(request_config);
}

export const edit_company = (token, company) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.company_urls.company}/${company.company_id}`;


    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'put',
        'params': {
            'patient_id': company.id
        },
        'data': company
    })
    return request.make(request_config);
}
export const get_company_reference = (token, company_id) => {
    var url = url = `${global_constants.base_end_points}${global_constants.end_points.company_urls.company}${global_constants.end_points.company_urls.company_reference}/${company_id}`;
    //if (company_id == '') {
    //     url = `${global_constants.base_end_points}${global_constants.end_points.company_urls.company}${global_constants.end_points.company_urls.company_reference}`;
    //}
    //else {
    //    url = `${global_constants.base_end_points}${global_constants.end_points.company_urls.company}${global_constants.end_points.company_urls.company_reference}/${company_id}`;
    //}
    
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(request_config);
}

//TODO: chanhe URL
export const get_parent_company= (parem, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.company_urls.company}/${global_constants.end_points.company_urls.company_reference}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(request_config);
}

// Get check company ff
export const check_company_ff = (token, param) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.company_urls.check_company_ff}`;
    let request = new Request();
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    };
  
    let request_config = new RequestConfig({
      url: url,
      headers: headers,
      method: 'get',
      params: param
    });
  
    return request.make(request_config);
  };

export const check_company_allow_override_format_for_demand_claims = (token, params) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.company_urls.check_allow_override_format_for_demand_claims}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get',
        params: params
    });
    return request.make(request_config);
};


export const get_user_companies_without_user_type_filter = (user_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.company_urls.user_companies_without_user_type_filter}/${user_id}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });
    return request.make(request_config);
};

export const get_clearinghouse_domains = (token) => {
    var url = url = `${global_constants.base_end_points}${global_constants.end_points.company_urls.company}${global_constants.end_points.company_urls.clearinghouse_domains}`;

    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(request_config);
};

export const quick_search_location = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.unassigned_payment.quick_search_location}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            keyword: param,
            pageSize: global_constants.constants.Quick_Search_Suggestion_List_Size
        }
    })
    return request.make(request_config);
}

export const search_place = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.unassigned_payment.search_place}`;
    
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(param)
    }
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(request_config);
}
export const get_additional_settings = (locationid, token) => {
    var url = `${global_constants.base_end_points}${global_constants.end_points.company_urls.company}${global_constants.end_points.company_urls.additional_settings}/${locationid}`;

    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token        
    }
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(request_config);
}

export const get_place_of_service = (id, token) => {
    var url = `${global_constants.base_end_points}${global_constants.end_points.company_urls.place_of_service}/${id}`;

    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });
    return request.make(request_config);
}