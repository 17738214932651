function yearAutoSelection(val, selectedYear) {
    var yearFormat = 'yyyy';
    var todayDate = new Date();
    var year = val;
    var date_regex = /^\d{4}$/;
    var minYear = new Date(todayDate.getFullYear() - 130, todayDate.getMonth(), todayDate.getDate()).getFullYear();
    var isValidYear = date_regex.test(year);
    if (isValidYear && year > 0) {
        selectedYear = year;
    }
    else if (parseInt(year) == 0) {
       
        selectedYear = new Date(todayDate).getFullYear();
    }
    else {
        selectedYear = '';
    }
    if (selectedYear < minYear) {
        selectedYear = '';
    }

    return selectedYear.toString();

}

export default yearAutoSelection;