import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Form, Grid } from "semantic-ui-react";
import { LoaderComponent } from '../../shared/component/loading_component';
import { toastr } from 'react-redux-toastr';
import { upload_835_file } from '../action/payment_action';
import { upload_835_constants } from '../payment_constants';
import ReportHeader from '../../reports/component/report_header';
import { get_lauch_darkley_key_value, isFilesizeWithinLimits } from "./../../shared/utility"
import { bindActionCreators } from 'redux';
import { argos_bill_2473_upload_835s } from "../../feature_flip_constant";
import * as global_constant from '../../global_constants';

class Upload835Component extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            is_upload_disabled: false,
        }
    }

    is_mounted = false;
    page_metadata: any = upload_835_constants;
    upload_835_settings: any;
    // Blacklist of extensions to ignore, for instance ['exe', 'dmg', 'bat', 'sh', 'php', 'html', 'js']
    extension_blacklist: any[] = [];
    // Blacklist of mime type like [video/*, audio/*]
    mime_type_blacklist: any[] = [];
    maximum_upload_filesize_in_mb: number = 0;

    componentDidMount() {
        this.is_mounted = true;

        if (global_constant && global_constant.upload_835) {
            this.upload_835_settings = global_constant.upload_835;
        }

        if (this.upload_835_settings) {
            // Single extension blacklist
            if (this.upload_835_settings.extension_blacklist && Array.isArray(this.upload_835_settings.extension_blacklist)) {
                this.extension_blacklist = this.upload_835_settings.extension_blacklist;
            }

            // File kind blacklist (mime type or usage of generic wildcard like video/*, audio/*)
            if (this.upload_835_settings.mime_type_blacklist && Array.isArray(this.upload_835_settings.mime_type_blacklist)) {
                this.mime_type_blacklist = this.upload_835_settings.mime_type_blacklist;
            }

            // Maximum upload filesize in MB
            if (this.upload_835_settings.maximum_upload_filesize_in_mb && !isNaN(this.upload_835_settings.maximum_upload_filesize_in_mb)) {
                this.maximum_upload_filesize_in_mb = this.upload_835_settings.maximum_upload_filesize_in_mb;
            }
        }
        document.body.classList.add('reports');
    };

    componentWillUnmount = () => {
        this.is_mounted = false;
        this.extension_blacklist = [];
        this.mime_type_blacklist = [];
        document.body.classList.remove('reports');
    };

    upload_file = () => {
        let input: any = this.refs.input_reader;
        input.click();
    };

    on_change = async (event) => {
        if (!event || !event.target) {
            return;
        }

        let files = event.target.files as FileList,
            isValidFileSelection = this.checkFiles(files);

        if (!isValidFileSelection) {
            return;
        }

        let token = this.props.user_login_details.user_data.data.token_details.access_token,
            data = new FormData(),
            // In previous validation, we made sure there is at least 1 file
            file = files[0];

        data.append('file', file);

        // Start loading
        this.setState({
            loading: true,
            is_upload_disabled: true
        });

        let uploadProcessResponse = await upload_835_file(token, data);

        if (uploadProcessResponse && uploadProcessResponse.status && uploadProcessResponse.status === global_constant.http_response_status.OK) {
            toastr.success('', this.page_metadata.message.success);
        } else {
            toastr.error('', this.page_metadata.message.error);
        }

        // End loading
        this.setState({
            loading: false,
            is_upload_disabled: false
        });
    };

    checkFiles = (files: FileList) => {
        let wellDefinedFiles = files !== null && files !== undefined;

        // Not really needed as the select file input closes only when at least a file is selected or cancel is pressed
        if (!wellDefinedFiles || files.length === 0) {
            toastr.error('', this.page_metadata.message.no_files_selected);
            return false;
        }

        // The file component is set to accept only one file at a time, let's make sure it's the case
        if (files.length > 1) {
            toastr.error('', this.page_metadata.message.too_many_files_selected);
            return false;
        }

        // Extract a file from the FileList
        let file = files[0];

        return this.isValidFilesize(file) &&
            !this.isFileExtensionBlacklisted(file) &&
            !this.isMimeTypeBlacklisted(file);
    };

    isValidFilesize = (file: File) => {
        let maxFileSize = this.maximum_upload_filesize_in_mb,
            checkEmptyFile = true,
            wrongFilesize = !isFilesizeWithinLimits(file, checkEmptyFile, maxFileSize);

        if (wrongFilesize) {
            file = null;
            toastr.error('', this.page_metadata.message.wrong_filesize.replace('%d', `${maxFileSize}`));
            return false;
        }

        return true;
    };

    isFileExtensionBlacklisted = (file: File) => {
        let extension = file.name.split('.').pop(),
            formattedExtension = `.${extension}`;

        if (this.extension_blacklist.indexOf(formattedExtension) !== -1) {
            toastr.error('', this.page_metadata.message.file_type_blacklisted);
            return true;
        }

        return false;
    };

    isMimeTypeBlacklisted = (file: File) => {
        let mimeType = file.type,
            isMimeTypeBlacklisted = false;

        this.mime_type_blacklist.forEach((blacklistedMimeType) => {
            if (isMimeTypeBlacklisted) return; // Skip the remaining iterations if the condition is met

            if (mimeType.match(blacklistedMimeType)) {
                toastr.error('', this.page_metadata.message.file_type_blacklisted);
                isMimeTypeBlacklisted = true;
            }
        });

        return isMimeTypeBlacklisted;
    };

    render() {
        return (
            <LoaderComponent loading={this.state.loading}>
                <div className={'common-forms-add'}>
                    <div className={'common-forms-search report-framework'}>
                        <ReportHeader title={this.page_metadata.title} />
                        <div
                            id="report-scrollable-area"
                            className="report-wrapper">
                            <div className="patient-search-form  patient_search_bottom_padding"
                                id="report-criteria-container">
                                <Form autoComplete="off">
                                    <Grid>
                                        <Grid.Column tablet={8} computer={8}>
                                            <Form.Field >
                                                <div style={{ display: 'flex' }}>
                                                    <Button type="button"
                                                        id="btn-download-template" primary
                                                        onClick={this.upload_file}
                                                        disabled={this.state.is_upload_disabled}>{this.page_metadata.button_label}</Button>
                                                </div>

                                                <input
                                                    type='file'
                                                    ref='input_reader'
                                                    style={{ display: 'none' }}
                                                    value=''
                                                    onChange={this.on_change}
                                                    name='input_upload'
                                                    id='id_input_upload'
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                </Form>
                            </div>
                        </div>
                        <div
                            className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area"
                            id="applicationFooterSticky">
                        </div>
                    </div>
                </div>
            </LoaderComponent>
        );
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
        },
        dispatch
    );
};

const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        features: {
            argosBill2473Upload835s: get_lauch_darkley_key_value(state.launch_darkly, argos_bill_2473_upload_835s)
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Upload835Component));
