import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import MaskedInput from 'react-text-mask';
import { Button, Checkbox, Dimmer, Form, Grid, Loader, Modal } from 'semantic-ui-react';
import * as global_constants from '../../global_constants';
import { add_referring_physician, get_medical_list, get_primary_specility_list } from '../action/shared_action';
import { check_valid_npi, is_mobile, unformat_zip_code, zip_code_formation, unmask, is_null_or_white_space } from '../utility';
import SelectionComponent from "./selection_component";
import { handle_focus_on_tab, set_focus_on_element_with_id } from './../../shared/tab_navigation_utility';

class AddReferringPhysician extends React.Component<any, any> {
    _is_mounted = false;
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            loading : false,
            form: {
                md_code: this.props.md_code,
                primary_specility: '',
                title: '',
                first_name: '',
                middle_name: '',
                last_name: '',
                credentials: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
                phone: '',
                fax: '',
                email: '',
                website: '',
                medical_group: '',
                npi: '',
                state_license: '',
                medicaid_license: '',
                taxonomy_code: '',
                other1: '',
                other2: '',
                is_active: true,
                is_force_save: false
            },
            states: [],
            medical_group: [],
            primary_specility: [],
            primary_spl_focus:true,
            master_data: this.props.user_login_details.master_data.data,
            selected_medical_group: '',
            selected_primary_specility: '',
            force_save_confirm: false,
            error: {
                first_name: false,
                last_name: false,
                npi: false,
                phone: false,
                fax: false,
                zip: false,
                email: false,
            },
            last_name_label: false,
            npi_error_label: false,
            is_submitted : false
        };


    }
    initial_state_form = null;
    initial_error_data = null;
    force_code = 800003; // error code for duplicate NPI number
    md_code_disabled = true;
    is_npi_valid = false;
    loading = false;
    previous_error = false;
    medical_group_unfiltered = [];
    primary_spaciality_unfiltered = [];
    token = '';

    UNSAFE_componentWillMount = () => {
        //document.getElementById("primary_spaciality").focus()
    }
    componentDidMount = async () => {
        this._is_mounted = true;
        let { primary_spl_focus} = this.state
        let medical_group = [];
        let specility = [];
        this.token = this.props.user_login_details.user_data.data.token_details.access_token; // update token at component update
        const states = this.state.master_data.states.map((val, index) => {
            return { key: index, value: val.id, text: val.name }
        });
        if (this._is_mounted) {
            this.setState({ states: states });
        }
       var default_medical = [{ key: '00', value: '', text: 'Select' }];
        if(this.props.medical_group && this.props.medical_group.length > 0){
            medical_group = this.props.medical_group;
            this.medical_group_unfiltered = this.props.medical_group_unfiltered;
        }else{
            medical_group = await get_medical_list(this.token).then(res => {
                if (res.data.data != null && res.data.data.length > 0) {
                    this.medical_group_unfiltered = res.data.data;
                    return res.data.data.map((val, index) => {
                        return { key: index, value: val.id, text: val.medical_group }
                    });
                }
            });  // get medical group list from api
        }
        var default_specility = [{ key: '00', value: '', text: 'Select' }];
        if(this.props.specility && this.props.specility.length > 0){
            specility = this.props.specility;
            this.primary_spaciality_unfiltered = this.props.primary_spaciality_unfiltered;
        }else{
            specility = await get_primary_specility_list(this.token).then(res => {
                if (res.data.data != null && res.data.data.length > 0) {
                    this.primary_spaciality_unfiltered = res.data.data;
                    return res.data.data.map((val, index) => {
                        return { key: index, value: val.id, text: val.specialty_desc }
                    });
                }
            }); // get primary specility list from api
        }
        
        this.initial_state_form = { ...this.state.form };
        this.initial_error_data = this.state.error;
        if (is_mobile()) {
            primary_spl_focus = false
        }
        if (this._is_mounted) {
            var final_medical_group = [];
            var final_speciality = [];
            if (medical_group) {
                final_medical_group = [...default_medical, ...medical_group];
            }
            else {
                final_medical_group = [...default_medical];
            }
            if (specility) {
                final_speciality = [...default_specility, ...specility];
            }
            else {
                final_speciality = [...default_specility];
            }
            this.setState({ medical_group: final_medical_group, primary_specility: [...default_specility, ...specility], primary_spl_focus });
        }
    }
    componentWillUnmount() {
        this._is_mounted = false;
    }
    option_open = () => {
        //set render content with based on max lenth value
        var _this = this;
        setTimeout(function () {
            var max_length = Math.max(..._this.state.primary_specility.map(e => e.text.length))

            var drop_down = document.querySelector('.auto-scroll-dropdown .visible.menu.transition');
            if (drop_down) {
                drop_down["style"]["overflow"] = "auto";
            }
            var input = document.querySelectorAll('.auto-scroll-dropdown .visible.menu.transition  > div');
            for (let x = 0; x < input.length; x++) {
                var item = input[x];
                if (item && item["style"]) {
                    item["style"]["min-width"] = (max_length * 8) + "px";
                }
            }
        }, 0);
    }

    componentDidUpdate(previousProps, previousState) {
        this.token = this.props.user_login_details.user_data.data.token_details.access_token; // update token at component update
        if (previousProps.add_physician != this.props.add_physician) {

            if (this._is_mounted) {
                this.setState({ showModal: true });
                this.handle_on_open_modal_window();
            }
        }
    }
    handle_on_open_modal_window = () => {
        setTimeout(() => {
            let closeIcons: NodeListOf<HTMLElement> = document.querySelectorAll('i.close.icon') as NodeListOf<HTMLElement>;
            let closeIcon = closeIcons[closeIcons.length - 1]
            if (closeIcon) {
                closeIcon.tabIndex = 0;
                closeIcon.id = "add_new_referring_physician_model_close_button";
                closeIcon.addEventListener("keydown", function (event) {
                    if (event.shiftKey && event.keyCode == 9) {
                        event.preventDefault();
                        set_focus_on_element_with_id("referring_physician_save_btn");
                    }

                    if (event.keyCode == 13) {
                        event.preventDefault();
                        var el = event.target as HTMLElement
                        el.click();
                    }
                });
            }
        }, 200)
    }
    // filter dropdown value
    filter_value_by_id = (data, selected_id) => {

        return data.filter((val, index) => {
            return val.id == selected_id;
        });

    }

    get_taxonomy_code = (specility_code) => {
        let data =  this.primary_spaciality_unfiltered.filter((val, index) => {
            return val.id == specility_code;
        }).reduce((acc, val) => { return val.taxonomy_code });
        return data ? data.taxonomy_code : '';
    }

    // check if data has only letters and numbers
    input_data_validation = (data, type) => {
        if (type == 'name') {
            var letterNumber = /^[0-9a-zA-Z\s]+$/;
            return !data.match(letterNumber);
        } else if (type == 'number') {
            data = data.replace(/[^0-9]/g, '');
            return (data.length != 10);
        } else if (type == 'zip') {
            data = data && data.length > 0 && data.replace(/[^0-9]/g, '');
            return !(data.length == 5 || data.length == 9);
        } else if (type == 'email') {
            var email_invalid = data.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? false : true;
            return (email_invalid);
        }

    }


    // validate form before saving data
    validate_form = () => {
        const error = { ...this.state.error };
        const form = this.state.form;
        error.first_name = (form.first_name.trim() == '') ? true : false;
        error.last_name = (form.last_name.trim() == '') ? true : false;
        error.zip = form.zip.length > 0 &&  this.input_data_validation(form.zip, 'zip');
        error.phone = form.phone.length > 0 && this.input_data_validation(form.phone, 'number');
        error.fax = form.fax.length > 0 && this.input_data_validation(form.fax, 'number');
        error.email = form.email.length>0 && this.input_data_validation(form.email, 'email');
        if (form.last_name.trim() != '') {
            error.last_name = this.input_data_validation(form.last_name, 'name');
        }
        if (this._is_mounted) {
            this.setState({
                error: error, last_name_label: form.last_name.trim() != '' ? this.input_data_validation(form.last_name.trim(), 'name') : false,
                is_submitted: true
            });
        }
        if (Object.keys(error).some((data) => { return error[data] == true })) {
            this.previous_error = true;
            toastr.error('', global_constants.constants.mandatory_fields);
            return false;
        }

        return true;

    }

    // close modal and empty form data
    closeModal = () => {
        // setting the focus to parent element
        set_focus_on_element_with_id("add_referring_physican_add_button");
        this.previous_error = false;
        if (this._is_mounted) {
            this.setState({ showModal: false, form: this.initial_state_form, error: this.initial_error_data, is_submitted: false, last_name_label: false });
        }
    }

    // save form data
    saveHandle = async (e) => {
        if (e.isDefaultPrevented) {
            e.preventDefault();
        }
        if (this.validate_form()) {
            const medical_group_obj = this.state.form.medical_group ? this.filter_value_by_id(this.medical_group_unfiltered, this.state.form.medical_group) : {};
            const primary_spaciality_obj = this.state.form.primary_specility ? this.filter_value_by_id(this.primary_spaciality_unfiltered, this.state.form.primary_specility) : {};
            const form_data = { ...this.state.form };
            if (this._is_mounted) {
                this.setState({ loading: true });
            }
            form_data.name = {
                first_name: form_data.first_name.trim(),
                middle_name: form_data.middle_name,
                last_name: form_data.last_name.trim()
            };
            form_data.address = {
                address1: form_data.address1,
                address2: form_data.address2,
                city: form_data.city,
                stateid: form_data.state,
                zip: unformat_zip_code(form_data.zip)
            };
            form_data.medical_group_id = this.state.form.medical_group;
            form_data.specialty_id = is_null_or_white_space(this.state.form.primary_specility) ? null : this.state.form.primary_specility;
            form_data.medical_group = JSON.stringify(medical_group_obj) != '{}' ? medical_group_obj[0] : {};
            form_data.speciality = JSON.stringify(primary_spaciality_obj) != '{}' ? primary_spaciality_obj[0] : {};
            
            form_data.phone = form_data.phone.replace(/\D/g, '');
            form_data.fax = form_data.fax.replace(/\D/g, '');
            form_data.zip = unformat_zip_code(form_data.zip);
            form_data.medical_group_id = (typeof form_data.medical_group_id === 'string' && !isNaN(parseInt(form_data.medical_group_id, 10))) ?
                (form_data.medical_group_id = parseInt(form_data.medical_group_id, 10)) : 0;

            await add_referring_physician(this.token, form_data).then(res => {
                let response = res.data.messages ? res.data.messages.reduce((acc, val) => { return val.code }) : 0;
                let need_force_save = response.code == this.force_code;
                let form = need_force_save ? this.state.form : this.initial_state_form;
                let error = need_force_save ? this.state.error : this.initial_error_data;
                let is_submitted_form = need_force_save ? true : false;
                if (!need_force_save) {
                    var referring_physician_format = { "label": '', "title": '', "md_code": '' ,'id' : ''};
                    var _title = (res.data.data.name.first_name ? res.data.data.name.first_name : '') + " " + (res.data.data.name.last_name ? res.data.data.name.last_name : '') + (res.data.data.address == null ? '' : res.data.data.address.address1 == null || res.data.data.address.address1=='' ? '' : ", " + res.data.data.address.address1);
                    // Set Auto search control for referring physician
                    referring_physician_format = {
                        'id': res.data.data.id,
                        "title": res.data.data.id.toString(),
                        "md_code": res.data.data.md_code,
                        "label": _title == ' ' ? res.data.data.md_code : (res.data.data.md_code) + " - " + _title

                    };
                    //
                    this.previous_error = false;
                    this.props.get_formatted_physician(referring_physician_format);
                } 

                if (this._is_mounted) {


                    if (need_force_save) {
                        // handle onOpen Confirmation modal window
                        this.handle_on_open_confirmation_modal_window();
    //                    this.reffering_physician_ok_btn_focus();
                    }

                    this.setState({
                        force_save_confirm: need_force_save,
                        showModal: need_force_save,
                        is_submitted: is_submitted_form,
                        form,
                        error,
                        loading: false
                    })
                }

            });
        }
    }
    handle_on_open_confirmation_modal_window = () => {
        setTimeout(() => {
            let closeIcons: NodeListOf<HTMLElement> = document.querySelectorAll('i.close.icon') as NodeListOf<HTMLElement>;
            let closeIcon = closeIcons[closeIcons.length - 1]
            closeIcon.tabIndex = 0;
            closeIcon.id = "add_new_referring_physician_confirm_model_close_button";
            closeIcon.addEventListener("keydown", function (event) {
                if (event.shiftKey && event.keyCode == 9) {
                    event.preventDefault();
                    set_focus_on_element_with_id("confirm_referring_physician_ok_btn");
                }

                if (event.keyCode == 13) {
                    event.preventDefault();
                    var el = event.target as HTMLElement
                    el.click();
                }
            });

            // initially set focus on OK button.
            set_focus_on_element_with_id("confirm_referring_physician_ok_btn");
        }, 200)
    }

    // set input field value in state
    handleChange = (e) => {
        const input_field = e.target.name;
        let input_value = e.target.value;
        const error = { ...this.state.error };
        let form_data = this.state.form
        if ((input_field == 'first_name' || input_field == 'last_name') && this.state.is_submitted) {
            error[input_field] = (input_value.trim() == '') ? true : false;
            form_data[input_field] = input_value ? input_value : '';
        } else if (input_field == 'npi') {
            error[input_field] = input_value.length>0 ? !check_valid_npi(input_value) : false;
            form_data[input_field] = input_value ? input_value : '';
        } else if (input_field == 'fax' || input_field == 'phone') {
            let is_error = this.input_data_validation(input_value, 'number');
            error[input_field] = is_error;
            form_data[input_field] = input_value ? input_value : '';
        } else if (input_field == 'zip') {
            let field_value = input_value && input_value.length > 0 && input_value.replace(/[^0-9]/g, '');
            if(!isNaN(field_value)){
              error[input_field] = this.input_data_validation(field_value, "zip")
              form_data[input_field] = field_value ? zip_code_formation(field_value) : '';
            }
        } else if (input_field == 'email') {
            error[input_field] = this.input_data_validation(input_value, 'email');
            form_data[input_field] = input_value ? input_value : '';
        } else {
            form_data[input_field] = input_value ? input_value : '';
        }

        if (this._is_mounted) {
            this.setState({
                form: form_data,
                error: error
            });
        }
    }

    // show modal on state change
    controlHandler = (event) => {
        if (this._is_mounted) {
            this.setState({ showModal: true });
        }
    }

    // on click on confirm OK button save form data forcefully
    force_save = (e) => {
        if (this._is_mounted) {
            this.setState({ force_save_confirm:false, form: { ...this.state.form, is_force_save: true } }, () => {
                this.saveHandle(e);
            });
        }
    }

    on_close_confirmation_modal = () => {

        // closing the modal
        this.setState({ force_save_confirm: false })

        // setting focus to parent save button
        set_focus_on_element_with_id("referring_physician_save_btn");
    }

    render() {
        const { primary_spl_focus} = this.state
        const last_name_label = this.state.last_name_label ? { display: 'block' } : { display: 'none' };
        const npi_error_label = this.state.is_submitted && this.state.error.npi ? { display: 'block', height: '42px' } : { display: 'none' };
        const form = this.state.form;
        return < >
            <div className="item">
                <Modal
                    onClose={this.on_close_confirmation_modal}
                    centered={false}
                    className="demand_statement_confirm default-modal"
                    open={this.state.force_save_confirm}
                    closeIcon
                    closeOnDimmerClick={false}
                >
                    <Modal.Header>Confirm</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Grid>
                                <Grid.Column>
                                    <p>Another Referring Physician has the same NPI number. Are you sure you want to continue?</p>
                                </Grid.Column>
                            </Grid>
                            <Grid>
                                <Grid.Column computer={16} textAlign="right">
                                    <Button type="reset" basic onClick={this.on_close_confirmation_modal}>Cancel</Button>
                                    <Button onKeyDown={(event) => { handle_focus_on_tab(event, "add_new_referring_physician_confirm_model_close_button") }} autoFocus id="confirm_referring_physician_ok_btn" type='submit' onClick={this.force_save} primary>OK</Button>
                                </Grid.Column>
                            </Grid>

                        </Modal.Description>

                    </Modal.Content>
                </Modal>
            </div>
            <Modal
                onClose={this.closeModal}
                centered={false}
                className=" default-modal"
                open={this.state.showModal}
                closeIcon={true}
                id="add_ref_physician_model_pop"

                closeOnDimmerClick={false}
            >
                <Dimmer active={this.state.loading} style={{'zIndex' : 9999}}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>                    
                <Modal.Header><div>
                    <h3>Add Referring Physician</h3>
                </div></Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <div className="common-forms-add" style={{ padding: '0' }}>
                            <Form>
                                <Grid>

                                    <Grid.Column mobile={16} tablet={8} computer={8}>
                                        <Form.Field >
                                            <label>MD Code <span className="req-alert"></span></label>
                                            <input
                                                readOnly={true} 
                                                autoComplete="off"
                                                disabled={this.md_code_disabled}
                                                name='md_code' value={form.md_code}
                                                id="md_code" maxLength={50}
                                                onChange={this.handleChange}
                                            />
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column mobile={16} tablet={8} computer={8}>
                                        <Form.Field className="physician-select">
                                            <label>Primary Speciality <span className="req-alert"></span></label>
                                            <SelectionComponent
                                                name='primary_spaciality'
                                                id='primary_spaciality'
                                                options={this.state.primary_specility}
                                                onChange={(value) => { this.setState({ form: { ...this.state.form, primary_specility: value, taxonomy_code: value != '' ? this.get_taxonomy_code(value) : '' } }) }}
                                                defaultValue={this.state.form.primary_specility}
                                                autoFocus={primary_spl_focus}
                                            />
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column mobile={4} tablet={2} computer={3}>
                                        <Form.Field >
                                            <label>Title<span className="req-alert"></span></label>
                                            <Form.Input fluid disabled={false}
                                                autoComplete="off"
                                                name='title' value={form.title}
                                                onChange={this.handleChange}
                                                id="case_name_txt" maxLength={50}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column mobile={12} tablet={5} computer={5}>
                                        <Form.Field className={this.state.error.first_name ? 'error requiredWithBgColor' : ''}  >
                                            <label>First Name <span className={this.state.error.first_name ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                            <input disabled={false}
                                                autoComplete="off"
                                                name='first_name' value={form.first_name}
                                                onChange={this.handleChange}
                                                id="case_name_txt" maxLength={20}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={4} computer={3}>
                                        <Form.Field >
                                            <label>Middle Name <span className="req-alert"></span></label>
                                            <input disabled={false}
                                                autoComplete="off"
                                               
                                                name='middle_name' value={form.middle_name}
                                                onChange={this.handleChange}
                                                id="case_name_txt" maxLength={1}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={5} computer={5}>
                                        <Form.Field className={this.state.error.last_name ? "requiredWithBgColor error" : ''} >
                                            <label>Last Name <span className={this.state.error.last_name ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                            <input disabled={false}
                                                autoComplete="off"
                                                name='last_name'
                                                onChange={this.handleChange}
                                                value={form.last_name}
                                                id="case_name_txt" maxLength={20}
                                            />
                                            <div className="requiredText error-box" style={last_name_label}>
                                                Letters and Numbers Only
                                            </div>
                                            
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column mobile={16} tablet={8} computer={4}>
                                        <Form.Field >
                                            <label>Credentials <span className="req-alert"></span></label>
                                            <input disabled={false}
                                                autoComplete="off"
                                                name='credentials' value={form.credentials}
                                                onChange={this.handleChange}
                                                id="case_name_txt" maxLength={100}
                                            />
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column mobile={16} tablet={16} computer={16}>
                                        <Form.Field >
                                            <label>Address 1 <span className="req-alert"></span></label>
                                            <input disabled={false}
                                                autoComplete="off"
                                                name='address1' value={form.address1}
                                                onChange={this.handleChange}
                                                id="case_name_txt" maxLength={50}
                                            />
                                        </Form.Field>
                                    </Grid.Column>


                                    <Grid.Column mobile={16} tablet={16} computer={16}>
                                        <Form.Field >
                                            <label>Address 2 <span className="req-alert"></span></label>
                                            <input disabled={false}
                                                autoComplete="off"
                                                onChange={this.handleChange}
                                                name='address2' value={form.address2}
                                                id="case_name_txt" maxLength={50}
                                            />
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column mobile={16} tablet={8} computer={5}>
                                        <Form.Field >
                                            <label>City <span className="req-alert"></span></label>
                                            <input disabled={false}
                                                autoComplete="off"
                                                name='city' value={form.city}
                                                onChange={this.handleChange}
                                                id="case_name_txt" maxLength={30}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={8} computer={4}>
                                        <Form.Field >
                                            <label>State <span className="req-alert"></span></label>                                                
                                            <SelectionComponent
                                                name='primary_state'
                                                id='primary_state'
                                                options={this.state.states}
                                                onChange={(value) => {
                                                    this.setState({ form: { ...this.state.form, state: value } })
                                                }}
                                                placeHolder="Select"
                                                defaultValue={this.state.form.state}
                                                hidden={true}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={8} computer={4}>
                                        <Form.Field className={this.state.form.zip && unformat_zip_code(this.state.form.zip).length > 0 && this.state.is_submitted && this.state.error.zip ? "error" : ""}>
                                        <label>
                                            Zip <span className="req-alert" />
                                        </label>
                                        <div className="ui input">
                                            <input
                                            autoComplete='new-password'
                                            name='zip'
                                            value={form.zip}
                                            onChange={this.handleChange}
                                            id="case_name_txt"  
                                            maxLength={10}
                                            />
                                        </div>                    
                                        {this.state.form.zip && unformat_zip_code(this.state.form.zip).length > 0 && this.state.is_submitted && this.state.error.zip
                                            && <div className="requiredText">{global_constants.constants.zip_compulsory}</div>
                                        }
                                        </Form.Field>                                               
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={8} computer={3} />
                                    <Grid.Column mobile={16} tablet={8} computer={5}>
                                        <Form.Field className={this.state.form.phone.length > 0
                                            && this.state.is_submitted && this.state.error.phone ? 'error' : ''}>
                                            <label>Phone Number <span className="req-alert"></span></label>
                                            <MaskedInput
                                                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                className={'form-control'}
                                                placeholder="(___) ___-____"
                                                guide={true}
                                                name="phone"
                                                id="phone-no"
                                                value={unmask(form.phone)}
                                                autoComplete="off"
                                                onChange={this.handleChange} />
                                            <div className="requiredText">{this.state.form.phone.length > 0
                                                && this.state.is_submitted && this.state.error.phone
                                                ? global_constants.constants.ten_digits_compulsory : ''}</div>

                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={8} computer={4}>
                                        <Form.Field className={this.state.form.fax.length > 0
                                            && this.state.is_submitted && this.state.error.fax ? 'error ' : ''} >
                                            <label>Fax <span className="req-alert"></span></label>
                                            <MaskedInput
                                                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                className={'form-control'}
                                                placeholder="(___) ___-____"
                                                guide={true}
                                                name="fax"
                                                id="fax-no"
                                                value={unmask(form.fax)}
                                                autoComplete="off"
                                                onChange={this.handleChange} />
                                            <div className="requiredText">{this.state.form.fax.length > 0
                                                && this.state.is_submitted && this.state.error.fax
                                                ? global_constants.constants.ten_digits_compulsory : ''}</div>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={8} computer={4}>
                                        <Form.Field className={this.state.form.email.length > 0
                                            && this.state.is_submitted && this.state.error.email ? 'error' : ''} >
                                            <label>Email <span className="req-alert"></span></label>
                                            <input  disabled={false}
                                                autoComplete="off"
                                                name='email' value={form.email}
                                                onChange={this.handleChange}
                                                id="case_name_txt" maxLength={50}
                                               
                                            />
                                            <div className="requiredText">{this.state.error.email && this.state.is_submitted  ? global_constants.constants.valid_email : ''}</div>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={8} computer={3}>
                                        <Form.Field >
                                            <label>Website <span className="req-alert"></span></label>
                                            <input disabled={false}
                                                autoComplete="off" value={form.website}
                                                name='website' onChange={this.handleChange}
                                                id="case_name_txt" maxLength={50}
                                            />
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column mobile={16} tablet={8} computer={5}>
                                        <Form.Field >
                                            <label>Medical Group <span className="req-alert"></span></label>                                                                                      
                                            <SelectionComponent
                                                name='primary_medical_group'
                                                id='primary_medical_group'
                                                options={this.state.medical_group}
                                                onChange={(value) => {
                                                    this.setState({ form: { ...this.state.form, medical_group: value } })
                                                }}
                                                defaultValue={this.state.form.medical_group}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={8} computer={4}>
                                        <Form.Field className={this.state.is_submitted && this.state.error.npi ? "error" : ''} >
                                            <label>NPI</label>
                                            <input disabled={false}
                                                autoComplete="off"
                                                name='npi' value={form.npi}
                                                onChange={this.handleChange}
                                                id="case_name_txt" maxLength={10}
                                            />{
                                                //<label> <span style={{ 'display': this.state.is_submitted && this.state.error.npi ? 'none' : 'block' }} className={"req-alert_normal"}>required for billing</span></label>
                                            }
                                            <div className="requiredText " style={npi_error_label}>
                                                Invalid NPI (Example 1234567893)
                                             </div>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={8} computer={4}>
                                        <Form.Field >
                                            <label>State License <span className="req-alert"></span></label>
                                            <input disabled={false}
                                                autoComplete="off" value={form.state_license}
                                                name='state_license' onChange={this.handleChange}
                                                id="case_name_txt" maxLength={50}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={8} computer={3}>
                                        <Form.Field >
                                            <label>Medicaid License <span className="req-alert"></span></label>
                                            <input disabled={false}
                                                autoComplete="off" value={form.medicaid_license}
                                                name='medicaid_license' onChange={this.handleChange}
                                                id="case_name_txt" maxLength={50}
                                            />
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column mobile={16} tablet={8} computer={5}>
                                        <Form.Field >
                                            <label>Taxonomy Code <span className="req-alert"></span></label>
                                            <input disabled={false}
                                                autoComplete="off"
                                                onChange={this.handleChange} value={form.taxonomy_code}
                                                name='taxonomy_code'
                                                id="case_name_txt" maxLength={50}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={8} computer={4}>
                                        <Form.Field >
                                            <label>Other 1 <span className="req-alert"></span></label>
                                            <input disabled={false}
                                                autoComplete="off"
                                                name='other1' value={form.other1}
                                                onChange={this.handleChange}
                                                id="case_name_txt" maxLength={50}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} tablet={8} computer={4}>
                                        <Form.Field >
                                            <label>Other 2 <span className="req-alert"></span></label>
                                            <input disabled={false}
                                                autoComplete="off"
                                                name='other2' value={form.other2}
                                                onChange={this.handleChange}
                                                id="case_name_txt" maxLength={50}
                                            />
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column mobile={16} tablet={16} computer={16}>
                                        <Checkbox checked={this.state.form.is_active} onChange={(e) => this.setState({ form: { ...this.state.form, is_active: !this.state.form.is_active } })} label='Active' />
                                    </Grid.Column>

                                </Grid>
                            </Form>

                        </div>

                        <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area" id="applicationFooterSticky">

                            <Button id="patient_cancel_btn" basic onClick={this.closeModal} >Cancel</Button>
                            <Button onKeyDown={(event) => { handle_focus_on_tab(event, "add_new_referring_physician_model_close_button") }} id="referring_physician_save_btn" type='submit' primary onClick={(e) => { this.saveHandle(e) }} >Save</Button>
                        </div>


                    </Modal.Description>

                </Modal.Content>
            </Modal>
        </ >;
    }

}

const mapSTateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
    };
}

export default connect(mapSTateToProps, null)(AddReferringPhysician);