import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { refresh_token } from '../../login/action/login_action';
import * as local_storage from '../../shared/local_storage_utility';
import { change_company_configuration, change_company_grid_header_id } from '../user_constants';
import { SearchModalComponent } from './../../shared/component/search_modal_component';
import { get_company_list } from './../../user/action/change_company_action';
import { update_company_details } from '../../shared/utility';
import { remove_r2_url_from_store } from '../../shared/action/shared_action';


export class ChangeCompanyComponent extends React.Component<any, any> {
    [x: string]: any;
    constructor(props) {
        super(props);
        this.state = { show_modal: this.props.show_popup };
    };

    UNSAFE_componentWillMount() {
        this.setState({ show_modal: this.props.show_popup });
        //this.resetComponent()
    }
    componentDidUpdate(previousProps, previousState) {
        if (previousProps.show_popup !== this.props.show_popup) {
            this.setState({ show_modal: this.props.show_popup })
        }
    }
    onSearch = async (obj) => {
        // TODO verify token 
        let token = '';
        let auth = local_storage.get("auth_data");
        if (auth && auth.token_details) {
            token = auth.token_details.access_token
        };
        return await get_company_list(token, obj.company_name, obj.company_code);

    }
    onClearFilters = () => {

    }
    onGridRowSelection = async (selectedRow) => {
        var user_data = this.props.user_login_details.user_data.data;
        user_data.company_id = selectedRow.id;
        user_data.company_name = selectedRow.name;
        user_data.gpms_code = selectedRow.gpms_code; 
        user_data.company_type_id = selectedRow.company_type_id;

        local_storage.set("wait_for_master_data_update", true);
        local_storage.set('company_change_on_same_tab', true);

        // set auth data in local storage on company changed 
        update_company_details(selectedRow);
        this.props.remove_r2_url_from_store();

        await this.props.refresh_token(user_data, true);
        console.log("End Change Company, refreshing token, user data =>", this.props.user_login_details.user_data.data);
        this.closeCompanyModal();
        this.props.onChangeCompany(selectedRow);
    }
    closeCompanyModal = () => {
        this.setState({ show_modal: false });
        this.props.onCloseCompanyModal();
    }
    render() {
        const { show_modal } = this.state;
        return (
            <SearchModalComponent onGridRowSelection={this.onGridRowSelection} showModal={show_modal} modal_config={change_company_configuration} onSearchClick={this.onSearch} onClearClick={this.onClearFilters} onCloseModal={this.closeCompanyModal} gridHeaderNavigation={change_company_grid_header_id} />
        )
    }
}
const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details
    }
}


const mapDispatchToProps = (dispatch) => {

    return bindActionCreators({
        refresh_token: refresh_token,
        remove_r2_url_from_store: remove_r2_url_from_store
    }, dispatch)
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangeCompanyComponent))
