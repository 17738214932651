import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AutoSearchComponent from '../../shared/component/auto_search_component';
import * as local_storage from '../../shared/local_storage_utility';
import * as global_constants from '../../global_constants';
import DiagnosisAdvancedSeach from '../../diagnosis/component/diagnosis_advance_search_component';
import { add_update_case_diagnosis_code, diagnosis_code_quick_search_data, get_advance_search, get_case_diagnosis_code } from '../../diagnosis/action/diagnosis_action';
import * as diagnosis_constant from '../../diagnosis/diagnosis_constant';

class DiagnosisCodeQuickSearchComponent extends React.Component<any, any> {
    [x: string]: any;
    constructor(props: any) {
        super(props);
        this.state = {
            selected_diagnosis_code: {
                "title": (props.diagnosis_code && props.diagnosis_code.title) || '',
                "label": (props.diagnosis_code && props.diagnosis_code.label) || '',
                "diagnosis_code_id": (props.diagnosis_code && props.diagnosis_code.diagnosis_code_id) || '',
                "code": (props.diagnosis_code && props.diagnosis_code.code) || '',
                "description": (props.diagnosis_code && props.diagnosis_code.description) || ''
            },
            advance_search_data: null, 
            is_focused: props.is_focused || false, 
            is_disabled: props.is_disabled || false, 
            error_class: props.error_class || '', 
            error_message: props.error_message || false, 
            is_view_mode: props.is_view_mode || false
        }
        this.diagnosis_child = React.createRef();
    }

    token: any = '';
    _is_mounted = false;

    // Fetching the report id and params.
    UNSAFE_componentWillMount = () => {
        var auth_data = local_storage.get('auth_data');
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
    };

    componentDidMount = async () => {
        this._is_mounted = true;
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.diagnosis_code && this.props.diagnosis_code.diagnosis_code_id && (this.props.diagnosis_code.diagnosis_code_id != prevProps.diagnosis_code.diagnosis_code_id)) {
            if (this._is_mounted) {
                this.setState({
                    selected_diagnosis_code: {
                        "title": (this.props.diagnosis_code && this.props.diagnosis_code.title) || '',
                        "label": (this.props.diagnosis_code && this.props.diagnosis_code.label) || '',
                        "diagnosis_code_id": (this.props.diagnosis_code && this.props.diagnosis_code.diagnosis_code_id) || '',
                        "code": (this.props.diagnosis_code && this.props.diagnosis_code.code) || '',
                        "description": (this.props.diagnosis_code && this.props.diagnosis_code.description) || ''
                    }
                });
            }
        }
    }
    
    componentWillUnmount = () => {
        this._is_mounted = false;
    };

    clear_dx_code_quick_search = () => {
        if (this._is_mounted) {
            let item = {
                "title": '',
                "label": '',
                "diagnosis_code_id": '',
                "code": '',
                "icd_type": ''
            };
            this.setState({
                selected_diagnosis_code: item
            });  
            this.props.update_diagnosis_code &&  this.props.update_diagnosis_code(item);          
        }
    }

    on_item_selection = (item) => {
            
        if (this._is_mounted) {
            let name = item.code + ' - ' + item.description;
            let parsed_item = {
                "title": name,
                "label": name,
                "diagnosis_code_id": item.diagnosis_code_id,
                "code": item.code,
                "description": item.description,
                "icd_type": item.icd_type != undefined ? item.icd_type : item.selected_icd_type // selected_icd_type is comming from advance control
            }; 
            this.setState({
                selected_diagnosis_code: parsed_item
            });

            this.props.update_diagnosis_code &&  this.props.update_diagnosis_code(parsed_item);
        }
    }

    // preapare the suggestion list with search result
    prepare_suggestion = (data) => {
        let formattedList = [];
        var data_length = data.data.length;
        if (data && data_length) {
            for (let i = 0; i < data_length; i++) {
                let item = data.data[i];
                let name = item.code + ' - ' + item.description

                item = { ...item, title: name, label: name, diagnosis_code_id : item.diagnosis_code_id, code: item.code, is_active: "true" };
                formattedList.push(item);
            }
        }
        return formattedList;
    }

    // get the quick search location apis data
    get_diagnosis_code_quick_search_data_list = async (search_keyword) => {
        return await diagnosis_code_quick_search_data(search_keyword, this.token);
    }

    //Auto search for dx code
    render_suggestion_result = (props) => {
        return (
            <div key={props.title} tabIndex={0} className="item_auto_search">
                <div key={props.title} className="fs_13">
                    <span>{props.label}</span>
                </div>
            </div>
        )
    }

    on_diagnosis_code_advance_search = async (params) => {

        params = params ? params : {};
        params["Page_size"] = global_constants.constants.PAGE_SIZE;

        const search_data = await get_advance_search(params, this.props.user_login_details.user_data.data.token_details.access_token).then(res => res.data);
        const search_result = (search_data.data !== null && search_data.data.result !== null) ? search_data.data.result : [];
        var grid_data = {
            ...this.state.grid_conf,
            rows: search_result,
            column: diagnosis_constant.search_diagnosis_code_configuration(diagnosis_constant.diagnosis_search_header_id).column_defs,
            paginationPageSize: diagnosis_constant.search_diagnosis_code_configuration(diagnosis_constant.diagnosis_search_header_id).pagination_size,
        };
        if (this._is_mounted) {
            this.setState({
                advance_search_data: grid_data
            });
        }
    }

    on_diagnosis_advance_search_grid_row_selection = (item) => {    
        this.on_item_selection(item);
    }

    render() {
        const { advance_search_data, is_focused, is_disabled, error_class, error_message, selected_diagnosis_code, is_view_mode } = this.state;

        return (
            <React.Fragment>
                <DiagnosisAdvancedSeach
                    onGridRowSelection={this.on_diagnosis_advance_search_grid_row_selection}
                    gridConfig={advance_search_data}
                    controlId={global_constants.constants.advanced_control_type.diagnosiscode_search}
                    onSearch={this.on_diagnosis_code_advance_search}
                    disabled_element={is_view_mode}
                    keyPress={this.on_diagnosis_code_advance_search}
                    clear_dx_code_quick_search={this.clear_dx_code_quick_search}
                />
                <AutoSearchComponent
                    on_blur={this.props.onBlur}
                    control_id={this.props.name}
                    on_focus={this.props.onFocus}
                    default_value={selected_diagnosis_code}
                    errorMessage={error_message || "No Record Found !"} 
                    prepareRenderList={this.render_suggestion_result} 
                    getList={this.get_diagnosis_code_quick_search_data_list}
                    prepareDataList={this.prepare_suggestion} 
                    selectresult={this.on_item_selection} 
                    is_focus={is_focused}
                    is_disabled={is_disabled} 
                    clear_search={this.clear_dx_code_quick_search}
                    errorClass={error_class}
                />
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

// Get user and login details from store.
const mapStateToProps = (state) => {
  return {
    user_login_details: state.user_login_details,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosisCodeQuickSearchComponent);
