import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { toastr as toaster } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { Dimmer, Form, Grid, Input, Loader } from 'semantic-ui-react';
import { toastr } from 'react-redux-toastr';
import { get_patient_search } from '../../patient/action/patient_search_action';
import { log_error } from '../../shared/action/shared_action';
import AutoSearchComponent from '../../shared/component/auto_search_component';
import DatePicker from '../../shared/component/date_picker_component';
import SelectionComponent from '../../shared/component/selection_component';
import * as session_storage from '../../shared/session_storage_utility';
import * as shared_constant from '../../shared/shared_constants';
import { get_user_emr_facility } from '../../sso/action/sso_action';
import { custom_date_format, format_patient_name, get_all_error, is_mobile } from '../../shared/utility';
import {
    get_report_data,
    get_report_metadata,
    get_sub_report_data,
    get_updated_dropdown_data,
    get_updated_dependent_dropdown_data,
    remove_billing_exceptions,
    reprocess_billing_exceptions,
    set_company_name,
    get_report_metadata_next_level,
    get_advanced_search_data,
} from '../action/report_action';
import * as report_constants from '../report_constants';
import * as export_print_utility from '../util/export_print_utility';
import * as eob_print_utility from '../util/eob_print_utility';
import * as patient_statement_print_utility  from '../util/patient_statement_print_utility';
import * as eob_error_print_utility from '../util/eob_error_print_utility';
import * as export_print_attorney_statements from '../util/export_print_attorney_statements';
import * as report_data_formatter from '../util/report_data_formatter';
import { set_url_history } from '../util/report_data_formatter';
import * as global_constant from './../../global_constants';
import * as Action from './../../shared/action/autosearch_action';
import AdvancedControl from './../../shared/component/advanced_control';
import { set_focus_on_element_with_id, set_focus_to_app_header } from './../../shared/tab_navigation_utility';
import ReportController from './report_controller';
import ReportFooter from './report_footer';
import ReportGrid from './report_grid';
import ReportHeader, { SubReportHeader } from './report_header';
import * as global_constants from '../../global_constants';
import { AlertConfirm } from '../../shared/component/alert_confirm_component';
import { messages } from '../report_constants';
import { get_lauch_darkley_key_value } from '../../shared/utility';
import DateRangePickerComponent from '../../shared/component/date_range_component';
import PercentageControl from '../../shared/component/percentage_decimal_control';
import { DataEntry } from '../../shared/audit/print_export_constants';
import * as PrintExportConstants from '../../shared/audit/print_export_constants';
import * as constants from '../../admin/constants/constants';
import { AssignReportValueFromElementById, getReportDynamic, getValueFromReportComponentType, findSensitiveData } from '../../shared/audit/print_export_utility';
import { ChargesBillingAuditLogEpicsFeature } from '../../admin/constants/constants';


class ReportComponent extends React.Component<any, any> {
    _is_mounted = false;
    default_focus_is_on_date_picker = false;
    constructor(props) {
        super(props);
        // Define initial state value.
        this.state = {
            loading: false,
            is_grid_visible: false,
            search_criteria: false,
            is_reset: false,
            show_date: false,
            report_footer_obj: {},
            grid_ref: null,
            is_print_disabled: true,
            is_export_disabled: true,

            is_remove_disabled: true,
            is_reprocess_disabled: true,
            link_drill_down_last_page: false,
            additional_actions: [],
            open_confirm_alert: false,
            confirm_message: '',
            open_confirm_alert_for_actions: false,
            confirm_message_for_actions: '',
            action_to_execute: {},
            selected_items_for_actions: [],
            selected_items: [],

            is_validate: true,
            is_error_control: {},
            is_submitted: false,
            column_header_height: 41,
            grid_conf: {
                isPagination: true,
                gridAutoHeight: true,
                selectionType: 'single',
                paginationPageSize: 20,
            },
            show_advanced_search_modal: true,
            grid_data: {},
            advanced_search_values: {},
            report_rows_count: 0,
            re_render: false,
            drill_down_title_shown: false,
            disclaimer_is_expended: false,
            controls_is_expended: true,
            displayGroupRowCount: false,
            printable_data: '',
            show_hover_text: false,
            hover_value: '',
            hover_x: 0,
            hover_y: 0
        };
        this.props.patient_details.patient_search = null;
    }

    //Initialize default reports data.
    report_metadata: any = {
        allow_grouping: false,
        allow_paging: false,
        column_parameters: '',
        container_type: 2,
        default_sort_column: '',
        disclaimer: '',
        id: '',
        input_fields: [
            {
                auto_postback: false,
                control_type: '',
                data_type: null,
                default_value: null,
                disabled: true,
                invisible: true,
                label_name: null,
                master_data: [],
                name: null,
                param_name: null,
                required: true,
                sequence: 0
            }
        ],
        order_by: '',
        orientation: '',
        page_size: 0,
        parameter_validator: [
            {
                control_type: '',
                express: '',
                has_regex_expression: false,
                parameter_name: '',
                validation_message: ``
            }
        ],
        pls_type: 0,
        query: '',
        report_header: '',
        selectable: true,
        ShowFooter: false,
        show_Parm_Lavel_2: true,
        title: '',
        title_holder: '',
        suppress_autorun: false
    };

    // Report Default Data.
    report_data: any = {
        cols: [],
        rows: [],
        footer_obj: {},
        Company: '',
        DefaultSortColumn: '',
        Disclaimer: '',
        OrderBy: '',
        header: '',
        ShowFooter: false,
        status: '',
        title: '',
        no_of_records: 0,
        r4_to_r6_routes: [],
        eob_glossary: [],
        eob_recoup: [],
        eob_error: [],
        eob_change: [],
        attorney_dataset: ''
    };

    // Define class level variables.
    token = '';
    report_params = '';
    report_params_list = [];
    default_date = null;
    report_id = 0;
    sub_report_id = 0;
    company_name = '';
    patient_name = '';
    account_no = null;
    current_date = '';
    title = '';
    is_sub_report_controls = true;
    is_sub_report_data = false;
    is_initial_result = false;
    is_reload_grid = true;
    report_container: any;
    default_date_range = { startDate: null, endDate: null };
    // Define controller data.
    control_data = [];
    initial_control_data = [];
    input_fields = [];
    initial_input_fields = [];
    search_criteria = [];
    disclaimer = '';
    is_financial = false;
    run_report_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
    report_name = ''
    meta_loader = false;
    report_loader = false;
    report_rows_count = 0;
    lookup_sequence: Boolean
    current_company: any
    total_column_width: number = 0;
    grid_header_height: number = 0;
    is_print_show_company = true
    control_data_text = {}
    initial_control_data_text = {}
    is_mobile:any;
    is_control_visible= []
    child_column_def: any;
    has_hierarchy= false
    location:any={};
    printable_data =''
    from_popup = false
    advanced_search_config = {};
    show_pagination = false;
    emr_user_fecility_list = [];
    filter_emr_user_fecility_list = false;
    limitation_by_emr_facilities_permission = false;

    // json data for report
    patientStatementPrintData:any = {};

    // customize report
    expandGroupReportByDefault = false;
    expandedReports = [30382]; 
    customColumnToTotal = [{ reportId: 'report_id_30382', column: 'Failed Charge Count'}];
    // Fetching the report id and parameters.
    UNSAFE_componentWillMount = () => {
        if(!this.props.set_url_history){
            set_url_history();
        }
        this.from_popup = this.props.from_popup || false
        this.location = this.props.location || this.location
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.report_params = decodeURIComponent(this.location.search);
        this.report_params = this.report_params.replace('?', '');
        this.is_sub_report_data = this.location.pathname.indexOf('sub_report_data') != -1;
        this.report_id = this.location.state && this.location.state.report_id ? this.location.state.report_id : session_storage.get("report_id");
        this.is_sub_report_controls = this.location.state && this.location.state.is_sub_report_controls ? true : false;
        this.is_financial = this.location.state && this.location.state.is_financial ? true : false;
        this.advanced_search_config = report_constants.reports_advance_control_config(
            shared_constant.patient_search_configuration_id
        )
        this.show_pagination = report_constants.report_allow_pagination(this.report_id);


        if (report_constants.financial_reports.indexOf(this.report_id) != -1) {
            const patient_id = session_storage.get("active_patient") || 0;
            if (!patient_id) {
                this.props.history.push(`/no_patient_selected`);
                return;
            }
        }

        if (session_storage.get('report_search_criteria')) {
            this.is_initial_result = true;
        }
    };

    componentWillUnmount = () => {
        this._is_mounted = false;
        if(!this.props.set_url_history){
            document.body.classList.remove('reports', 'patient-header', 'patient-subHeader');
        }
        //No need to remove session storage as we have to maintain back track from other comp to report comp.
        // we are removing in report interceptor comp.
        // e.g 30050
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.location !== this.props.location) {
            this.is_sub_report_data = false;
            this.sub_report_id = 0; //ADD THIS LINE
            this.location = nextProps.location || this.location
            this.setState({
                drill_down_title_shown: false
            })            
            if (nextProps.location.pathname.indexOf('sub_report_data') != -1) {
                this.is_sub_report_data = nextProps.location.pathname.indexOf('sub_report_data') != -1;
                this.is_sub_report_controls = this.location.state && this.location.state.is_sub_report_controls ? true : false;
                if (this.is_sub_report_data) {
                    this.report_params = decodeURIComponent(nextProps.location.search);
                    this.report_params = this.report_params.replace('?', '');
                    this.report_id = this.location.state && this.location.state.report_id ? this.location.state.report_id : session_storage.get("report_id");
                    this.get_sub_report_data(this.report_params);
                    if (this.is_sub_report_controls) {
                        this.is_sub_report_data = false;
                        this.get_report_metadata(this.report_id);
                    }
                }
            } else {
                this.get_report_metadata(this.report_id);
                this.is_initial_result = true;
            }
        }
    }

    // Here we hit the all API.
    componentDidMount = () => {
        this._is_mounted = true;
        document.body.classList.add('reports');
        this.is_mobile = is_mobile();
        if (this.is_sub_report_data) {
          if (this.is_sub_report_controls) {
            this.is_sub_report_data = false;
            this.get_report_metadata(this.report_id);
          } else {
            this.get_sub_report_data(this.report_params);
          }
        } else {
          this.get_report_metadata(this.report_id);
        }
    };

    get_data_request = () => {
        if (this.is_sub_report_data) {
            if (this.is_sub_report_controls) {
                this.is_sub_report_data = false;
                this.get_report_metadata(this.report_id);
            } else {
                this.get_sub_report_data(this.report_params);
            }
		} else {
			if (this.report_id === report_constants.failed_charges_report_id_30380) { this.is_initial_result = true }
            this.get_report_metadata(this.report_id);
        }
    }

    start_loading = () => {
        this.setState({
            loading: true,
            is_search_button_disabled: true
        });
    }

    stop_loading = () => {
        if (!this.report_loader && !this.meta_loader) {
            this.setState({
                loading: false,
                is_search_button_disabled: false
            });
        }
    }

    private isProviderCredentialingListReport = (reportId) => {
        return reportId == report_constants.providerCredentialingListReportId
	}

    private includeRowTotal = (reportId) => {
		const filteredArray = report_constants.includeRowTotalReportIds.filter(rid => rid === reportId);
		if (filteredArray !== null && filteredArray.length > 0) return true;
		return false;

	}

    // Get reports meta data so we can use it to create controller.
    get_report_metadata = async report_id => {
        if (this._is_mounted) {
            this.meta_loader = true;
            this.start_loading();
            this.setState({
                is_grid_visible: false,
                show_date: false,
            });
        }
        
        if (this.props.shared_details && this.props.shared_details.left_menu_data && this.props.shared_details.left_menu_data.data) {
            var menu_list = [ ...this.props.shared_details.left_menu_data.data ];
            this.limitation_by_emr_facilities_permission = menu_list.some(data => {
                return data.name == 'Limitation by EMR Facilities' && data.view
            })
        }
        // get emr facility list if user have permission for this
        if (this.limitation_by_emr_facilities_permission && report_id == 30251) {
            await get_user_emr_facility(this.props.user_login_details.user_data.data.user_id).then((res) => {
                if (res && res.data && res.data.status && res.data.data.emr_revflow_fecility) {
                    this.emr_user_fecility_list = res.data.data.emr_revflow_fecility;
                    this.filter_emr_user_fecility_list = true;
                } else {
                    this.filter_emr_user_fecility_list = false;
                }
            })
        }

        await get_report_metadata(report_id, this.token).then(async (response) => {
            this.report_metadata = response.data.data;
            this.input_fields = this.report_metadata.input_fields;
            this.disclaimer = this.report_metadata.disclaimer;
            this.has_hierarchy = response.data.data.has_hierarchy
            this.expandGroupReportByDefault = this.expandedReports.indexOf(report_id) > -1 ? true : false;
            //Check initial result and set search criteria in controls
            if (this.is_initial_result && session_storage.get('report_search_criteria')) {
                this.search_criteria = session_storage.get('report_search_criteria');
                for(let i =0;i< this.search_criteria.length; i++){
                    if (
                        this.search_criteria[i].name.toLowerCase() == report_constants.enum_drop_down_type.company.toLowerCase() ||
                        this.search_criteria[i].name.toLowerCase() == report_constants.enum_drop_down_type.practice.toLowerCase() ||
                        (this.report_id === report_constants.failed_charges_report_id_30380 && this.search_criteria[i].name.toLowerCase() == report_constants.enum_drop_down_type.location.toLowerCase())
                        ) {
                            if (this.report_metadata.pls_type == 0) {
                                if (this.search_criteria[i].depends_on > 0) {
                                    await this.get_update_dependent_dropdown_data(this.search_criteria[i].depends_on, this.input_fields, false);
                                } else {
                                    this.get_dropdown_master_data(this.search_criteria[i]);
                                }
                            } else {
                                this.get_dropdown_master_data(this.search_criteria[i]);
                            }
                        }
                        if(this.input_fields[i].control_type && this.input_fields[i].control_type.toLowerCase().indexOf('daterange') > -1) {
                            this.input_fields[i].default_value = this.search_criteria[i].default_value;
                        }else{
                            this.input_fields[i].default_value = this.search_criteria[i].default_value;
                        }
                }
                if (this.report_metadata.show_Parm_Lavel_2) {
                    this.get_report_metadata_next_level(this.report_id, this.search_criteria);
                }
                this.get_report_data(this.report_id, this.search_criteria);
            };

            //Set Default value to control data and reset error for controls.
            this.input_fields = this.set_control_data();
            this.input_fields.forEach(input_field => {
                if (input_field.control_type === "DropDownList") {
                    this.evaluate_custom_properties(input_field.report_param_id, this.input_fields, input_field.default_value, false);
                }
            }); 
            //Set Current Company id for patient search
            this.input_fields.filter(data => {
                !data.invisible && this.is_control_visible.push(data.name)
                if (data.name.toLowerCase() == report_constants.enum_drop_down_type.company.toLowerCase() )
                    this.current_company = data.default_value;
            });
            this.filter_location_based_on_emr_facility();

            //Check auto run 
            if (!(this.is_initial_result && session_storage.get('report_search_criteria'))) {
                this.check_auto_run();
            }

            //Set Initial search data
            this.initial_control_data = [...this.control_data];

            if (this._is_mounted) {
                this.meta_loader = false;
                this.stop_loading();
                this.setState({
                    search_criteria: true,
                    is_reset: true,
                    advanced_search_values: session_storage.get('report_advance_search')
                        ? session_storage.get('report_advance_search')
                        : {},
                });
            }
        },
        error => {
            if (this._is_mounted) {
                this.meta_loader = false;
                this.stop_loading();
            }
            log_error(error);
            if (error.response.data) {
                const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                toaster.error('', toastr_options);
                
            }
            });
    };

    // filter location dropdown value based on emr facility list if user have permission for this
    filter_location_based_on_emr_facility = () => {
        let update_location = this.input_fields.some(item => {
            return item.name == 'emr_user_fecility' || item.name == 'limitation_by_emr_facilities_permission'
        });
        if (this.filter_emr_user_fecility_list && update_location) {
            this.input_fields.map(data => {
                if (this.filter_emr_user_fecility_list && data.name == 'emr_user_fecility') {
                    data.master_data = this.emr_user_fecility_list;
                    data.default_value = this.emr_user_fecility_list.join();
                }
                if (data.name == 'limitation_by_emr_facilities_permission') {
                    data.default_value = this.filter_emr_user_fecility_list;
                }
                if (data.name == 'posidlist') {
                    //let master_data = this.filter_location_based_on_emr_facility(data);
                    let master_data = [];
                    let all = undefined;
                    if (this.limitation_by_emr_facilities_permission) {
                        this.emr_user_fecility_list.map(item => {
                            data.master_data.filter(a => {
                                if (a.value == '-1') {
                                    all = a;
                                }
                                if (item == a.value) {
                                    master_data.push(a);
                                }
                            })
                        });
                        if (master_data && master_data.length > 1) {
                            if (all) {
                                master_data.unshift(all);
                            }
                        }
                    }
                    data.master_data = master_data;
                }
            });
        }
    }
    //Get Report Data.
    get_report_data = async (report_id, param) => {
        if (this._is_mounted) {
            this.report_loader = true;
            this.start_loading();
            this.setState({
                is_grid_visible: false,
                show_date: false
            });
        }
        this.report_params_list = param;
        await get_report_data(report_id, param, this.token).then(
        (response) => {
            if (response.data && response.data.data) {
                if (this.includeRowTotal(report_id)) {
                    response.data.data.allow_grouping = true;
                    this.setState({
                        displayGroupRowCount: true
                    });
				}
			const additionalAction = report_constants.get_additional_actions_with_report_id(this.report_id);
			const formatted_data = report_data_formatter.formate_report_cols_row_data(response.data.data, report_id, this.props.features, false, additionalAction);
            response.data.data = formatted_data.data;
            this.has_hierarchy = response.data.data.HasHierarchy
            this.report_data.rows = response.data.data.ReportRows ? response.data.data.ReportRows : [];
            this.report_data.cols = response.data.data.ReportColumn ? response.data.data.ReportColumn : [];
            this.report_data.footer_obj = response.data.data.footer_obj ? response.data.data.footer_obj : {};
            this.report_data.header = response.data.data.ReportHeader;
            this.report_data.ShowFooter = response.data.data.ShowFooter;
            this.report_data.allow_grouping = response.data.data.allow_grouping;
            this.report_data.r4_to_r6_routes = response.data.data.R4ToR6Route;
            this.report_data.attorney_dataset = response.data.data.attorney_dataset;
            this.report_data.title = response.data.data.Title;
            this.disclaimer = response.data.data.Disclaimer;
            this.company_name = response.data.data.Company;
            this.report_data.DefaultSortColumn = response.data.data.DefaultSortColumn;
            this.report_data.link_drill_down_last_page = response.data.data.link_drill_down_last_page;
            this.report_data.additional_actions = response.data.data.additional_actions;

            this.total_column_width = formatted_data.total_column_width;
            this.grid_header_height = formatted_data.grid_header_height;

                if (this._is_mounted) {
                    this.report_loader = false;
                    this.stop_loading();
                    this.setState({
                        is_print_disabled: false,
                        is_export_disabled: false,
                        is_remove_disabled: false,
                        is_reprocess_disabled: false,
                        link_drill_down_last_page: this.report_data.link_drill_down_last_page,
                        show_date: true,
                        is_grid_visible: true,
                        column_header_height: 45,
                        controls_is_expended: false,
                        additional_actions: this.report_data.additional_actions
                    });
                }
            }
        },
        (error) => {
            if (this._is_mounted) {
            this.report_loader = false;
            this.setState({
                loading: false,
                is_search_button_disabled: false,
                controls_is_expended: true
            });
            }
            log_error(error);
            if (error.response.data) {
            const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
            toaster.error("", toastr_options);
            }
        }
        );
    };

    // get report metadata next level
    get_report_metadata_next_level = async (report_id: number, param: any[]) => {
        if (this._is_mounted) {
          this.meta_loader = true;
          this.start_loading();
        }
        await get_report_metadata_next_level(report_id, param, this.token).then(
          (response) => {
            this.report_metadata = response.data.data;
            this.input_fields = this.report_metadata.input_fields;
            this.disclaimer = this.report_metadata.disclaimer;
            this.has_hierarchy = response.data.data.has_hierarchy;

            // Set Default value to control data and reset error for controls.
            this.input_fields = this.set_control_data();

            // Set Current Company id for patient search
            this.input_fields.filter((data) => {
              !data.invisible && this.is_control_visible.push(data.name);
              if (data.name.toLowerCase() == report_constants.enum_drop_down_type.company.toLowerCase())
                this.current_company = data.default_value;
            });

            // Set Initial search data
            this.initial_control_data = [...this.control_data];
            if (this._is_mounted) {
              this.meta_loader = false;
              this.stop_loading();
            }
          },
          (error: { response: { data: any } }) => {
            if (this._is_mounted) {
              this.meta_loader = false;
              this.stop_loading();
            }
            log_error(error);
            if (error.response.data) {
              const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
              toaster.error('', toastr_options);
            }
          }
        );
    };

    //Get Sub Report Data.
    get_sub_report_data = async (param) => {
        if (this._is_mounted) {
        this.report_loader = true;
        this.start_loading();
        this.setState({
            is_grid_visible: false
        });
        }
        await get_sub_report_data(param, this.token).then(
        async (response) => {
            if (response.data && response.data.data) {
                this.get_sub_report_id(param);
                const detail_for_reason = (param && param.toLowerCase().indexOf(report_constants.billing_exception_detail_for_reason.toLowerCase()) > -1) ? true : false;
                if (this.isProviderCredentialingListReport(this.sub_report_id)) {
                    response.data.data.allow_grouping = true;
                    this.setState({
                        displayGroupRowCount: true
                    });
                }
            const additionalAction = report_constants.get_additional_actions(this.report_id,this.sub_report_id);
            const formatted_data = report_data_formatter.formate_report_cols_row_data(
                response.data.data,
                this.sub_report_id,
                this.props.features,
                detail_for_reason,
                additionalAction
                );
                response.data.data = formatted_data.data;
                if (this.sub_report_id === report_constants.patient_statement) { this.patientStatementPrintData = response.data.data.PatientStatement; }
               
            this.has_hierarchy = response.data.data.HasHierarchy
            this.report_data.header = response.data.data.ReportHeader;
            this.report_data.ShowFooter = response.data.data.ShowFooter;
            this.report_data.allow_grouping = response.data.data.allow_grouping;
            this.report_data.title = response.data.data.Title;
            this.disclaimer = response.data.data.Disclaimer;
            this.company_name = response.data.data.Company;
            this.report_data.r4_to_r6_routes = response.data.data.R4ToR6Route;
            this.report_data.DefaultSortColumn = response.data.data.DefaultSortColumn;
                this.report_data.eob_glossary = response.data.data.eob_glossary;
                this.report_data.eob_recoup = response.data.data.eob_recoup;
                this.report_data.eob_error = response.data.data.eob_error;
                this.report_data.eob_change = response.data.data.eob_change;
            this.report_data.rows = response.data.data.ReportRows ? response.data.data.ReportRows : [];
            this.report_data.cols = response.data.data.ReportColumn ? response.data.data.ReportColumn : [];
            this.report_data.footer_obj = response.data.data.footer_obj ? response.data.data.footer_obj : {};
            this.report_data.link_drill_down_last_page = response.data.data.link_drill_down_last_page
            this.report_data.additional_actions = response.data.data.additional_actions;
            if((this.sub_report_id === report_constants.failed_charges_report_id_30286)|| (this.sub_report_id === report_constants.failed_charges_report_id_30380)){
                const searchParams = new URLSearchParams(this.report_params);
                const ruleReasonId = searchParams.get("RuleReasonId") || "";
                const companyCode = searchParams.get("CompanyCode") || "";
                let { header, title } = await report_constants.get_header_section_for_failed_charge_drill_down(this.token,Number(ruleReasonId),`${companyCode.toUpperCase()} - ${this.company_name}`);
                this.report_data.header = header;
                this.report_data.title = title;
            }

            this.total_column_width = formatted_data.total_column_width;
            this.grid_header_height = formatted_data.grid_header_height;
                if (this._is_mounted) {
                    this.report_loader = false;
                    if(!this.props.auto_print){
                        this.stop_loading();
                    }
                    this.setState({
                        is_print_disabled: false,
                        is_export_disabled: false,
                        is_remove_disabled: false,
                        is_reprocess_disabled: false,
                        is_grid_visible: true,
                        column_header_height: 45,
                        report_rows_count: this.report_data.rows.length,
                        link_drill_down_last_page: this.report_data.link_drill_down_last_page,
                        additional_actions: this.report_data.additional_actions
                    });
                }
            }
        },
        (error) => {
            if (this._is_mounted) {
            this.report_loader = false;
            this.setState({
                loading: false
            });
            }
            log_error(error);
            if (error.response.data) {
                const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                toaster.error('', toastr_options);
                
            }
        }
        );
    };

    //Get updated dropdown data on dropdown change.
    get_updated_dropdown_data = async (sequence, params) => {
        await get_updated_dropdown_data(params, this.token).then(response => {
            this.input_fields.filter((data) => {
                if (sequence == 1 && data.name.toLowerCase() == report_constants.enum_drop_down_type.practice.toLowerCase() ) {
                    data.master_data = response.data.data[report_constants.enum_drop_down_type.practice];
                    data.default_value = '';
                    this.control_data[data.sequence] = '';
                }
                if (sequence == 1 && data.name.toLowerCase() == report_constants.enum_drop_down_type.provider.toLowerCase() ) {
                    data.master_data = response.data.data[report_constants.enum_drop_down_type.provider];
                    data.default_value = '';
                    this.control_data[data.sequence] = '';
                }
                if (data.name.toLowerCase() == report_constants.enum_drop_down_type.location.toLowerCase()) {
                    data.master_data = response.data.data[report_constants.enum_drop_down_type.location];
                    data.default_value = '';
                    this.control_data[data.sequence] = '';
                }
            })
            if (this._is_mounted) {
                this.setState({
                    re_render: true
                });
                this.setState({
                    re_render: false
                });
            }
        },
        error => {
            if (this._is_mounted) {
                this.setState({
                    re_render: false
                });
            }
            log_error(error);
            if (error.response.data) {
                const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                toaster.error('', toastr_options);
                
            }
        });
    };

    //Get updated dropdown data on dropdown change.
    get_update_dependent_dropdown_data = async (param_id, input_list, change_default_value = true) => {
        var param_object = {};
        input_list.map((item) => {
            param_object[item.param_name] = item.default_value;
        })
        try {
            let response = await get_updated_dependent_dropdown_data(param_object, this.report_id, param_id, this.token);           
            Object.keys(response.data.data).map((key) => {
                this.input_fields.filter((data) => {
                    if (data.name.toLowerCase()==key.toLowerCase()) {
                        data.master_data = response.data.data[key];
                        if (change_default_value) {
                            data.default_value = '';
                            this.control_data[data.sequence] = '';
                        }
                    }
                })
            });

            this.filter_location_based_on_emr_facility();
            if (this._is_mounted) {
                this.setState({
                    re_render: true
                });
                this.setState({
                    re_render: false
                });
            }
        } catch (error) {
            if (this._is_mounted) {
                this.setState({
                    re_render: false
                });
            }
            log_error(error);
            if (error.response.data) {
                const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                toaster.error('', toastr_options);
            }
        }
    };

    //Get updated dropdown data on dropdown change.
    get_dropdown_master_data = async (search_data) => {
        let params = [];
        if (search_data.sequence == 1 && search_data.name.toLowerCase().trim() == report_constants.enum_drop_down_type.company.toLowerCase()) {
            params.push(this.search_criteria[search_data.sequence - 1].default_value);
        } else if (search_data.sequence == 2 && search_data.name.toLowerCase() == report_constants.enum_drop_down_type.practice.toLowerCase()) {
            this.search_criteria[search_data.sequence - 2].invisible ? params.push('@companyId') : params.push(this.search_criteria[search_data.sequence - 2].default_value);
            params.push(this.search_criteria[search_data.sequence - 1].default_value);
        }

        await get_updated_dropdown_data(params, this.token).then(response => {
            this.input_fields.filter((data) => {
                if (search_data.sequence == 1 && data.name.toLowerCase() == report_constants.enum_drop_down_type.practice.toLowerCase()) {
                    data.master_data = response.data.data[report_constants.enum_drop_down_type.practice];
                }
                if (search_data.sequence == 2 && data.name.toLowerCase() == report_constants.enum_drop_down_type.location.toLowerCase()) {
                    data.master_data = response.data.data[report_constants.enum_drop_down_type.location];
                }
            })
            this.filter_location_based_on_emr_facility();
            if (this._is_mounted) {
                this.setState({
                    re_render: true
                });
                this.setState({
                    re_render: false
                });
            }
        },
        error => {
            if (this._is_mounted) {
                this.setState({
                    re_render: false
                });
            }
            log_error(error);
            if (error.response.data) {
                const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                toaster.error('', toastr_options);
                
            }
        });
    };

    //Check auto run 
    check_auto_run = () => {
        if (!this.report_metadata.suppress_autorun) {
            let auto_run = true;
            this.input_fields.map(data => {
                if (data.required && (!data.default_value) && (data.default_value != '0')) {
                    auto_run = false;
                }
            })
            if (auto_run) {
                this.run_report_handler('');
            }
        }
    }

    // Set Default value to control data and reset error for controls.
    set_control_data = () => {
        return this.input_fields.map((item, index) => {
            if (!item.invisible && item.control_type.toLowerCase() != 'blank') {
                if (item.required) {
                    if (this._is_mounted) {
                        this.setState(({ is_error_control }) => ({
                            is_error_control: {
                                ...is_error_control,
                                [item.sequence]: false,
                            }
                        }))
                    }
                }
                if (item.control_type.toLowerCase().indexOf('daterange') > -1) {
                    if (item.default_value) {
                        try {
                            this.control_data[item.sequence] = JSON.parse(item.default_value);
                        } catch (error) {
                            this.control_data[item.sequence] = this.default_date_range;
                        }
                    } else {
                        this.control_data[item.sequence] = this.default_date_range;
                    }
                } else {
                    this.control_data[item.sequence] = item.default_value;
                }
            }
            this.initial_input_fields[index] = { ...item };
            return { ...item };
        });
    }

    //Use to get sub-report id from query string
    get_sub_report_id = param => {
        this.sub_report_id = param.split('&', 1);
        this.sub_report_id = parseInt(this.sub_report_id[0].split('rid=')[1]);
    };

    //Calender change handler.
    calendar_change_handler = (name, date_object, id) => {
        this.control_data[id] = date_object ? custom_date_format(date_object._d, 'mm/dd/yyyy') : '';
        if (this.state.is_error_control[id] !== '') {
            if (this._is_mounted) {
                this.setState(prev_state => ({
                    is_error_control: {
                        ...prev_state.is_error_control,
                        [id]: moment.isMoment(date_object) ? false : true
                    }
                }))
            }
        }
    };

    //Dropdown change handler.
    dropdown_change_handler = (event) => {
        const { id, value, name } = event.target;
        this.control_data[id] = value;
        this.input_fields[id - 1].default_value = value;
        this.control_data_text = {
            ...this.control_data_text,
            [name]: {
                value,
                text: event.target.selectedOptions
                    ? event.target.selectedOptions[0].outerText
                    : event.target.options[event.target.selectedIndex].text
            }
        };

        if (this.report_metadata.pls_type == 0) {
            var depend_input_felid = this.input_fields.filter((inp) => {
                return inp.depends_on == this.input_fields[id - 1].report_param_id;
            });
            if (depend_input_felid.length > 0) {
                this.get_update_dependent_dropdown_data(this.input_fields[id - 1].report_param_id, this.input_fields);
                let field = this.input_fields.find(inp => inp.depends_on == this.input_fields[id - 1].report_param_id);
                field.enabled = false;
            } else {
                //if there is no depended column go with previous approach  
                if (this.input_fields[id - 1].auto_postback) {
                    this.update_control_type(id, this.input_fields[id - 1].name);
                }
                //Clear Lookup Object Patient on company change
                if (this.input_fields[id - 1].name.toLowerCase() == report_constants.enum_drop_down_type.company.toLowerCase() && this.clear_patient_id()) {
                    this.current_company = value;
                    this.clear_quick_search(this.lookup_sequence);
                }
            }
        } else {
            if (this.input_fields[id - 1].auto_postback) {
                this.update_control_type(id, this.input_fields[id - 1].name);
            }

            //Clear Lookup Object Patient on company change
            if (this.input_fields[id - 1].name.toLowerCase() == report_constants.enum_drop_down_type.company.toLowerCase() && this.clear_patient_id()) {
                this.current_company = value;
                this.clear_quick_search(this.lookup_sequence);
            }
        }

        let report_param_id = this.input_fields[id - 1].report_param_id
        this.evaluate_custom_properties(report_param_id, this.input_fields, value, true);

        if (this._is_mounted) {
            this.setState({
                is_reset: false
            });
        }
        if (this.state.is_error_control[id] !== '') {
            if (this._is_mounted) {
                this.setState(prev_state => ({
                    is_error_control: {
                        ...prev_state.is_error_control,
                        [id]: false
                    }
                }));
            }
        }

    };

    evaluate_custom_properties = (report_param_id, input_fields, value, reset_to_default_value) => {
        let dependent_inputs = input_fields.filter(x => x.depends_on === report_param_id);
        if (dependent_inputs.length > 0) {
            dependent_inputs.forEach(dependent_input => {
                if (dependent_input && dependent_input.custom_Properties) {
                    let custom_properties = JSON.parse(dependent_input.custom_Properties);
                    let values = custom_properties.values;

                    if (values.includes(value)) {
                        dependent_input.default_value = "";
                        this.control_data[dependent_input.sequence] = dependent_input.default_value;
                        dependent_input.disabled = custom_properties.disabled;
                        dependent_input.required = false;
                    } else {
                        dependent_input.disabled = false;
                        dependent_input.required = true;
                        let item = dependent_input.master_data.find(x=> x.selected);
                        if (item && reset_to_default_value) {
                            dependent_input.default_value = item.value;
                            this.control_data[dependent_input.sequence] = dependent_input.default_value;
                        }
                    }
                }
            });
        }
    }

    //Input change handler.
    checkbox_change_handler = (e) => {
        let { id } = e.target;
        this.control_data[id] = e.target.checked;
        if (this._is_mounted) {
            this.setState(prev_state => ({
                is_error_control: {
                    ...prev_state.is_error_control,
                    [id]: false
                }
            }))
        }
    }

    //Input change handler.
    input_change_handler = e => {
        let { id, value } = e.target;
        this.control_data[id] = value;
        if (this.state.is_error_control[id] !== '') {
            if (this._is_mounted) {
                this.setState(prev_state => ({
                    is_error_control: {
                        ...prev_state.is_error_control,
                        [id]: false
                    }
                }))
            }
        }
    };

    // Number Input change handler.
    number_input_change_handler = e => {
        let { id, value } = e.target;
        if (value.length > 0 && !/^\d+$/.test(value)) {
            toaster.error('', report_constants.messages.numeric_allow);
        }
        const txt_pid = e.target.value.trim();
        this.control_data[id] = e.target.validity.valid
            ? Number(txt_pid) <= 2147483647
                ? txt_pid
                : this.max_value_validation(txt_pid)
            : this.control_data[id];
        if (this.state.is_error_control[id] !== '') {
            if (this._is_mounted) {
                this.setState((prev_state) => ({
                    is_error_control: {
                        ...prev_state.is_error_control,
                        [id]: false
                    }
                }));
            }
        }
    };

    max_value_validation = (input) => {
        if (Number(input) > 2147483647) {
          return this.max_value_validation(input.substring(0, input.length - 1));
        } else {
          return Number(input);
        }
    };

    // Get lockup sequence for clear it
    clear_patient_id = () => {
        return this.input_fields.filter(item => {
            if (item.control_type == 'LookupObjectPatient' || item.control_type == 'LookupObject' ) {
                this.lookup_sequence = item.sequence;
                return true;
            }
        })
    }

    //Set dropdown default value.
    set_default_value = data => {
        let default_value = '';
        let default_text = '';
        //Set input default value in case of load and back traverse.
        // Already set session storage value to input-fields object.
        if (this.is_initial_result && session_storage.get('report_search_criteria')) {
            this.control_data[data.sequence] = data.default_value;
             data.master_data &&
               data.master_data.map(item => {
                 if (item['value'] == data.default_value) {
                     if (data.name.toLowerCase() == report_constants.enum_drop_down_type.company.toLowerCase()) {
                     default_text = item.text //.split('-')[1] && item.text.split('-')[1].trim();
                   } else {
                     default_text = item.text;
                   }
                    this.control_data_text = {
                        ...this.control_data_text,
                        [data.name]: {
                        value: data.default_value,
                        text: default_text
                        }
                    };
                 }
                 return;
               });
            return data.default_value;
        }

        // Set value which is in control data previously.
        if (!this.state.is_reset) {
            return this.control_data[data.sequence];
        }

        //Set default selected  or blank value
        data.master_data && data.master_data.map(function (item) {
            if (item.selected) {
                default_value = item.value;
                default_text = item.text;
            }
        });
        this.control_data[data.sequence] = default_value;
        this.control_data_text = {
            ...this.control_data_text,
            [data.name]: {
              value: data.default_value,
              text: default_text
            }
        };
        this.initial_control_data_text = {...this.control_data_text}
        return default_value;
    };

    //Set text control default value.
    set_text_default_value = (data) => {
        this.control_data[data.sequence] = data.default_value;
    }

    /* ADVANCE AND QUICK SEARCH CONTROL START */
    get_quick_search_data_list = async (input_value, item) => {
        const advanced_search_config = this.advanced_search_config[item.control_name];
        if (item.control_name == report_constants.advance_search_enum.patient) {
            return await Action.get_searchList(
                this.token,
                this.current_company,
                input_value.trim(),
                global_constant.constants.Quick_Search_Suggestion_List_Size,
                global_constant.end_points.patient_urls.quick_search
            );
        } else {
            const value = input_value ? input_value.trim() : input_value;
            let company_id = this.current_company;
            const company_field = this.input_fields.filter((item) => item.param_name === '@companyid');
            if (company_field && company_field[0] && company_field[0].default_value) {
                company_id = company_field[0].default_value;
            }
            const url = `${advanced_search_config.quick_search_url}?keyword=${value}&page_size=${global_constant.constants.Quick_Search_Suggestion_List_Size}&company_id=${company_id}`;
            return await get_advanced_search_data(this.token, url);
        }
    }

    render_result = (props) => {
        return (
            <div key={props.title} tabIndex={0} className='item_auto_search'>
                <div className='fs_13'>
                    <span className='bold'>{props.label}</span>
                    <span>{props.title ? '(' + props.title + ')' : ''}</span>
                </div>
            </div>
        );
    }

    on_item_selection = (selected_row, item) => {
        const formatted_selected_row = report_constants.row_selection_format(
            selected_row,
            item,
            this.control_data
        );

        selected_row = { ...formatted_selected_row.selected_row };
        this.control_data = [...formatted_selected_row.control_data];
        let { advanced_search_values } = this.state;

        advanced_search_values = {
            ...advanced_search_values,
            [item.sequence]: selected_row
        };
        if (this._is_mounted) {
            this.setState({
                advanced_search_values
            });
        }
        session_storage.set('report_advance_search', advanced_search_values);
        this.closeModal();
    }

    // close the modal on selection of patient through quick search
    closeModal = () => {
        this.setState({ show_advanced_search_modal: false })
    }

    clear_quick_search = (sequence) => {
        this.control_data[sequence] = '';
        let { advanced_search_values } = this.state;
        advanced_search_values = {
            ...advanced_search_values,
            [sequence]: {}
        };
        this.setState((prev_state) => ({
            advanced_search_values
        }));
        session_storage.set('report_advance_search', advanced_search_values);
    }

    grid_row_selection = (selected_row, item) => {
        const formatted_selected_row: any = report_constants.row_selection_format(
            selected_row,
            item,
            this.control_data
        );
        selected_row = { ...formatted_selected_row.selected_row };
        this.control_data = [...formatted_selected_row.control_data];
        let { advanced_search_values } = this.state;
        advanced_search_values = {
            ...advanced_search_values,
            [item.sequence]: selected_row
        };
        this.setState({
            advanced_search_values
        });
        session_storage.set('report_advance_search', advanced_search_values);
        this.closeModal();
    }

    on_advance_search = async (param, item) => {
        const advanced_search_config = this.advanced_search_config[item.control_name];
        if (item.control_name == report_constants.advance_search_enum.patient) {
            const params = {
                patient_name: {
                    first_name: param.first_name,
                    last_name: param.last_name
                },
                id: param.Id,
                page_size: param.page_size,
                company_id: this.current_company // Pass current company in advance search.
            };
            // Search function.
            await this.props.get_patient_search(params, this.token).then((res) => {
                this.setState((prev) => ({
                    grid_data: {
                        ...prev.grid_data,
                        [item.control_name]: {
                            ...prev.grid_conf,
                            rows: this.props.patient_details.patient_search,
                            column: advanced_search_config.column_defs,
                            messages: 'No Record Available.'
                        }
                    }
                }));
            });
        } else {
            let url = `${advanced_search_config.advanced_search_url}`;
            let company_id = this.current_company
            const company_field = this.input_fields.filter(item=> item.param_name === "@companyid");
            if (company_field && company_field[0] && company_field[0].default_value) {
                company_id = company_field[0].default_value
            }
            switch (item.control_name) {
                case report_constants.advance_search_enum.insurance_class:
                case report_constants.advance_search_enum.insurance:
                    param = { ...param, records: global_constant.constants.PAGE_SIZE, company_id };
                    break;
                default:
                    break;
            }
            // Search function.
            await get_advanced_search_data(this.token, url, param).then((response) => {
                if (response.data) {
                    let row_data = response.data.data
                        ? response.data.data.result
                            ? response.data.data.result
                            : response.data.data
                        : [];
                    this.setState((prev) => ({
                        grid_data: {
                            ...prev.grid_data,
                            [item.control_name]: {
                                ...prev.grid_conf,
                                rows: row_data,
                                column: advanced_search_config.column_defs,
                                messages: 'No Record Available.'
                            }
                        }
                    }));
                }
            });
        }
    }
    /* ADVANCE AND QUICK SEARCH CONTROL END */

    dateRangePickerHandler = (sequence, dateRange) => {
        const{ startDate, endDate } = dateRange
        const dateRangePicker = {
            startDate: startDate?custom_date_format(startDate, 'mm/dd/yyyy') : '',
            endDate: endDate?custom_date_format(endDate, 'mm/dd/yyyy') : ''
        };
        this.control_data[sequence] = dateRangePicker
        if (this.state.is_error_control[sequence] !== '') {
            if (this._is_mounted) {
                this.setState(prev_state => ({
                    is_error_control: {
                        ...prev_state.is_error_control,
                        [sequence]: !!!dateRangePicker.startDate || !!!dateRangePicker.endDate ? true : false
                    }
                }))
            }
        }
    };

    //Set controls on page load.
    set_control_type = (control_type, item, defaultFocus) => {
        switch (control_type) {
            case 'DropDownList':
                return (
                    <Form.Field>
                        {item.label_name && (
                            <label>
                                {item.label_name}
                                {item.required && [287, 24].indexOf(this.report_id) == -1 && (
                                    <span
                                        className={
                                            !this.control_data[item.sequence] &&
                                            this.state.is_submitted &&
                                            item.required &&
                                            this.state.is_error_control[item.sequence]
                                                ? 'req-alert'
                                                : 'req-alert_normal'
                                        }
                                    >
                                        {' '}
                                        (required)
                                    </span>
                                )}
                            </label>
                        )}
                        <SelectionComponent
                            id={item.sequence}
                            name={item.name}
                            placeHolder={'Select'}
                            hidden={true}
                            options={item.master_data}
                            onChange={(value, event) => {
                                this.dropdown_change_handler(event);
                            }}
                            defaultValue={this.set_default_value(item)}
                            autoFocus={defaultFocus}
                            isRequired={
                                !this.control_data[item.sequence] &&
                                item.required &&
                                this.state.is_submitted &&
                                this.state.is_error_control[item.sequence]
                                    ? true
                                    : false
                            }
                            style={'dropdown-options-wrap'}
                            disabled={item.disabled}
                        />
                    </Form.Field>
                );
            case 'DateRange':
                return (
                    <Form.Field>
                        {item.label_name && (
                            <label>
                                {item.label_name}
                                {item.required && (
                                    <span
                                        className={
                                            (!!!this.control_data[item.sequence] ||
                                                !!!this.control_data[item.sequence].startDate ||
                                                !!!this.control_data[item.sequence].endDate) &&
                                            item.required &&
                                            this.state.is_submitted &&
                                            this.state.is_error_control[item.sequence]
                                                ? 'req-alert'
                                                : 'req-alert_normal'
                                        }
                                    >
                                        {' '}
                                        (required)
                                    </span>
                                )}
                            </label>
                        )}
                        <DateRangePickerComponent
                            is_disabled={item.disabled}
                            id={item.sequence.toString()}
                            updateDatesChange={(startDate: any, endDate: any) =>
                                this.dateRangePickerHandler(item.sequence, { startDate, endDate })
                            }
                            startDate={
                                this.control_data[item.sequence] &&
                                this.control_data[item.sequence].startDate &&
                                moment(this.control_data[item.sequence].startDate)
                                    ? moment(this.control_data[item.sequence].startDate)
                                    : null
                            }
                            endDate={
                                this.control_data[item.sequence] &&
                                this.control_data[item.sequence].endDate &&
                                moment(this.control_data[item.sequence].endDate)
                                    ? moment(this.control_data[item.sequence].endDate)
                                    : null
                            }
                            error={
                                (!this.control_data[item.sequence] ||
                                    !!!this.control_data[item.sequence].startDate ||
                                    !!!this.control_data[item.sequence].endDate) &&
                                item.required &&
                                this.state.is_submitted &&
                                this.state.is_error_control[item.sequence]
                            }
                        />
                    </Form.Field>
                );
            case 'PercentageTextBox':
                return (
                    <Form.Field>
                        {item.label_name && (
                            <label>
                                {item.label_name}
                                {item.required && (
                                    <span
                                        className={
                                            !(
                                                this.control_data[item.sequence] &&
                                                this.control_data[item.sequence].trim()
                                            ) &&
                                            item.required &&
                                            this.state.is_submitted &&
                                            this.state.is_error_control[item.sequence]
                                                ? 'req-alert'
                                                : 'req-alert_normal'
                                        }
                                    >
                                        {' '}
                                        (required)
                                    </span>
                                )}
                            </label>
                        )}
                        <PercentageControl
                            name={item.name}
                            id={item.sequence}
                            onChange={(value) =>
                                this.input_change_handler({ target: { id: item.sequence, value: value } })
                            }
                            defaultValue={this.control_data[item.sequence] ? this.control_data[item.sequence] : ''}
                            disabled={item.disabled}
                            allowNegative={false}
                            maxLength={6}
                            suffix={'%'}
                            prefix={''}
                            className={
                                !(this.control_data[item.sequence] && this.control_data[item.sequence].trim()) &&
                                item.required &&
                                this.state.is_submitted &&
                                this.state.is_error_control[item.sequence]
                                    ? 'error red-error-thin required_with_bg_color'
                                    : ''
                            }
                        />
                    </Form.Field>
                );
            case 'Date':
                this.default_focus_is_on_date_picker = true;
                return (
                    <Form.Field>
                        {item.label_name && (
                            <label>
                                {item.label_name}
                                {item.required && (
                                    <span
                                        className={
                                            !this.control_data[item.sequence] &&
                                            item.required &&
                                            this.state.is_submitted &&
                                            this.state.is_error_control[item.sequence]
                                                ? 'req-alert'
                                                : 'req-alert_normal'
                                        }
                                    >
                                        {' '}
                                        (required)
                                    </span>
                                )}
                            </label>
                        )}
                        <DatePicker
                            id={item.sequence.toString()}
                            is_disabled={item.disabled}
                            name={item.name}
                            date_update={(date_object) =>
                                this.calendar_change_handler(item.name, date_object, item.sequence)
                            }
                            date={
                                this.control_data[item.sequence] && moment(this.control_data[item.sequence])
                                    ? moment(this.control_data[item.sequence])
                                    : null
                            }
                            autoFocusOnCalendarInput={defaultFocus}
                            styleClass={
                                !this.control_data[item.sequence] &&
                                item.required &&
                                this.state.is_submitted &&
                                this.state.is_error_control[item.sequence]
                                    ? 'required_with_bg_color'
                                    : ''
                            }
                            is_required={
                                !this.control_data[item.sequence] &&
                                item.required &&
                                this.state.is_submitted &&
                                this.state.is_error_control[item.sequence]
                                    ? true
                                    : false
                            }
                        />
                    </Form.Field>
                );
            case 'LookupObject':
            case 'LookupObjectPatient':
                let patient_control = item.name;
                patient_control = patient_control.toLowerCase().replace(/id$/, '');
                item = {
                    ...item,
                    control_name: ['pat', 'patient'].indexOf(patient_control) > -1 ? 'patientID' : item.name
                };
                return (
                    <Form.Field className='advance-quick-search'>
                        {item.label_name && (
                            <label>
                                {item.label_name}
                                {item.required && (
                                    <span
                                        className={
                                            !this.control_data[item.sequence] &&
                                            this.state.is_submitted &&
                                            item.required &&
                                            this.state.is_error_control[item.sequence]
                                                ? 'req-alert'
                                                : 'req-alert_normal'
                                        }
                                    >
                                        {' '}
                                        (required)
                                    </span>
                                )}
                            </label>
                        )}
                        <AdvancedControl
                            id={item.sequence}
                            name={item.control_name}
                            showModal={this.state.show_advanced_search_modal}
                            disabled_element={item.disabled}
                            onGridRowSelection={(row) => {
                                this.grid_row_selection(row, item);
                            }}
                            gridConfig={this.state.grid_data[item.control_name]}
                            controlId={
                                this.advanced_search_config &&
                                this.advanced_search_config[item.control_name] &&
                                this.advanced_search_config[item.control_name].controlId
                            }
                            autoFocusForSearchIcon={defaultFocus}
                            onSearch={(param) => {
                                this.on_advance_search(param, item);
                            }}
                            search_type={
                                this.advanced_search_config &&
                                this.advanced_search_config[item.control_name] &&
                                this.advanced_search_config[item.control_name].search_type
                            }
                            headerIdForGridTabNavigation={shared_constant.patient_search_configuration_id}
                        />
                        <AutoSearchComponent
                            control_id={item.sequence}
                            getInputValue={null}
                            default_value={this.state.advanced_search_values[item.sequence] || {}}
                            errorMessage={'No Record Found !'}
                            prepareRenderList={this.render_result}
                            getList={(input_value) => {
                                return this.get_quick_search_data_list(input_value, item);
                            }}
                            prepareDataList={(data) =>
                                report_constants.prepare_quick_search_suggestions(data, item.control_name)
                            }
                            selectresult={(row) => this.on_item_selection(row, item)}
                            show_clear_search={true}
                            clear_search={() => this.clear_quick_search(item.sequence)}
                            errorClass={false ? 'search-error-thin' : ''}
                            is_disabled={item.disabled}
                        />
                    </Form.Field>
                );
            case 'CheckBox':
                return (
                    <Form.Field className='ui checkbox' style={{ marginTop: 32 }}>
                        <div>
                            <input
                                type='checkbox'
                                name={item.name}
                                id={item.sequence}
                                checked={this.control_data[item.sequence] ? true : false}
                                onChange={this.checkbox_change_handler}
                                disabled={item.disabled}
                            />{' '}
                            <label className='chkbx_label' htmlFor={item.name}>
                                {item.label_name}
                            </label>
                        </div>
                    </Form.Field>
                );
            case 'NumericTextBox':
                return (
                    <Form.Field>
                        {item.label_name && (
                            <label>
                                {item.label_name}
                                {item.required && (
                                    <span
                                        className={
                                            !this.control_data[item.sequence] &&
                                            item.required &&
                                            this.state.is_submitted &&
                                            this.state.is_error_control[item.sequence]
                                                ? 'req-alert'
                                                : 'req-alert_normal'
                                        }
                                    >
                                        {' '}
                                        (required)
                                    </span>
                                )}
                            </label>
                        )}
                        <Input
                            name={item.name}
                            type='text'
                            autoFocus={defaultFocus}
                            id={item.sequence}
                            autoComplete='off'
                            pattern='[0-9]*'
                            maxLength={item.max_length}
                            onChange={this.number_input_change_handler}
                            value={this.control_data[item.sequence] ? this.control_data[item.sequence] : ''}
                            className={
                                !this.control_data[item.sequence] &&
                                item.required &&
                                this.state.is_submitted &&
                                this.state.is_error_control[item.sequence]
                                    ? 'error red-error-thin required_with_bg_color'
                                    : ''
                            }
                        />
                    </Form.Field>
                );
            default:
                return (
                    <Form.Field>
                        {item.label_name && (
                            <label>
                                {item.label_name}
                                {item.required && (
                                    <span
                                        className={
                                            !(
                                                this.control_data[item.sequence] &&
                                                this.control_data[item.sequence].trim()
                                            ) &&
                                            item.required &&
                                            this.state.is_submitted &&
                                            this.state.is_error_control[item.sequence]
                                                ? 'req-alert'
                                                : 'req-alert_normal'
                                        }
                                    >
                                        {' '}
                                        (required)
                                    </span>
                                )}
                            </label>
                        )}
                        <input
                            name={item.name}
                            type='text'
                            autoFocus={defaultFocus}
                            id={item.sequence}
                            onChange={this.input_change_handler}
                            value={this.control_data[item.sequence] ? this.control_data[item.sequence] : ''}
                            disabled={item.disabled}
                            className={
                                !(this.control_data[item.sequence] && this.control_data[item.sequence].trim()) &&
                                item.required &&
                                this.state.is_submitted &&
                                this.state.is_error_control[item.sequence]
                                    ? 'error red-error-thin required_with_bg_color'
                                    : ''
                            }
                        />
                    </Form.Field>
                );
        }
    }

    //Update controls on page load.
    update_control_type = (sequence, name) => {
        let params = [];
        if (sequence == 1 && name.toLowerCase().trim() == report_constants.enum_drop_down_type.company.toLowerCase()) {
            params.push(this.control_data[sequence]);
        } else if (sequence == 2 && name.toLowerCase() == report_constants.enum_drop_down_type.practice.toLowerCase()) {
            this.input_fields[sequence - 2].invisible ? params.push('@companyId') : params.push(this.control_data[sequence - 1]);
            params.push(this.control_data[sequence]);
        }
        this.get_updated_dropdown_data(sequence, params);
    };

    //Required Field Validator.
    required_field_validator = () => {
        var is_error = false;
        this.input_fields.map((item, index) => {
            if (!item.invisible && item.control_type.toLowerCase() !== 'blank') {
                if (item.control_type == 'text' || item.control_type.toLowerCase().indexOf('text') > -1) {
                    this.control_data[index + 1] = this.control_data[index + 1] ? this.control_data[index + 1].trim() : this.control_data[index + 1];
                }
                if (item.required) {
                    if(item.control_type.toLowerCase().indexOf('daterange') > -1) {
                        !!this.control_data[index + 1].startDate && !!this.control_data[index + 1].endDate ? this.state.is_error_control[index + 1] = false : this.state.is_error_control[index + 1] = true
                    }else{
                        this.control_data[index + 1] ? this.state.is_error_control[index + 1] = false : this.state.is_error_control[index + 1] = true
                    }
                }
                let paramEmpty;
                if(item.control_type.toLowerCase().indexOf('daterange') > -1) {
                    paramEmpty = !!!this.control_data[index + 1].startDate || !!!this.control_data[index + 1].endDate;
                }else{
                    paramEmpty = !this.control_data[index + 1];
                }
                if (!is_error && item.required && paramEmpty) {
                    toaster.error('', report_constants.messages.mandatory_fields);
                    is_error = true
                }
            }
        });
        return is_error;
    };

    //Function used to Parameter Validator.
    parameter_validator = () => {
        let is_error = false;
        let is_valid_date = true;
        const control_data = this.control_data.map((item, index) => {
            if (this.input_fields[index - 1].control_type.toLowerCase() == 'date') {
                if (isNaN(new Date(item).getTime())) {
                    is_valid_date = false;
                    return item;
                }
                else {
                    return new Date(item).getTime();
                }
            }
            return item;
        });

        if (!is_valid_date) {
            return is_error;
        }

        this.report_metadata.parameter_validator.map(item => {
            if (item.control_type.toLowerCase() == 'date') {
                var express = item.express.toString();
                express = express.replace('#CurrentDate', new Date().getTime().toString());
                express = express.replace('#ReportParam', 'control_data');
                express = express.replace('#ReportParam', 'control_data');
            }

            if (!is_error && !item.has_regex_expression) {
                if (!eval(express)) {
                    toaster.error('', item.validation_message);
                    is_error = true;
                }
            }
        });
        return is_error;
    };

    //Function used to Run Report based on controllers values.
    run_report_handler = e => {
        var is_error = false;
        if (this._is_mounted) {
            this.setState({
                is_submitted: true,
                controls_is_expended: this.report_metadata.show_Parm_Lavel_2 || false
            });
        }
        is_error = this.required_field_validator();
        if (is_error) {
            this.setState({
                controls_is_expended: true
            })
            return;
        }
        is_error = this.parameter_validator();
        if (is_error) {
            this.setState({
                controls_is_expended: true
            })
            return;
        }

        this.input_fields.filter((data, index) => {
            this.search_criteria[index] = { ...data, master_data: null }
        });

        switch (this.report_id) {
          // Patient Visit Log
          case 131:
            this.search_criteria[2].default_value = 'visitlog';
            this.search_criteria[1].default_value = session_storage.get('active_patient')
              ? session_storage.get('active_patient')
              : 0;
            this.control_data.map((data, index) => {
              this.search_criteria[index - 1].default_value = data;
            });
            break;
          // Patient Payment Log - DB Driven
          case 132:
            this.control_data.map((data, index) => {
              this.search_criteria[index - 1].default_value = data;
            });
            this.search_criteria[1].default_value = session_storage.get('active_patient')
              ? session_storage.get('active_patient')
              : 0;
            break;
          // Patient Statement Log - DB Driven
          case 30290:
            this.control_data.map((data, index) => {
              this.search_criteria[index - 1].default_value = data;
            });
            this.search_criteria[1].default_value = session_storage.get('active_patient')
              ? session_storage.get('active_patient')
              : 0;
            break;
          default:
            this.control_data.map((data, index) => {
              if (
                this.search_criteria[index - 1] &&
                this.search_criteria[index - 1].control_type.toLowerCase() == 'daterange'
              ) {
                this.search_criteria[index - 1].default_value = JSON.stringify(data);
              } else {
                this.search_criteria[index - 1].default_value = data;
              }
            });
            break;
        }
        const {featureFlipName}= getReportDynamic(this.report_id);

        if (get_lauch_darkley_key_value(this.props.launch_darkly, featureFlipName))
        {
            AssignReportValueFromElementById(this.search_criteria);
        }

        if (this.report_metadata.show_Parm_Lavel_2) {
            this.get_report_metadata_next_level(this.report_id, this.search_criteria);
        } else {
            this.get_report_data(this.report_id, this.search_criteria);
            session_storage.set('report_search_criteria', this.search_criteria);
            this.initial_control_data_text = {...this.control_data_text}
            this.run_report_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
        }

        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    //Reset the search control of Reports.
    clear_handler = e => {
        this.is_initial_result = false;
        this.control_data = [];
        this.initial_control_data = [];
        this.control_data_text = {};
        this.initial_control_data_text = {};
        this.get_report_metadata(this.report_id);
        if (this._is_mounted) {
            this.setState({
                show_date: false,
                is_submitted: false,
                is_print_disabled: true,
                is_export_disabled: true,
                is_remove_disabled: true,
                is_reprocess_disabled: true,
                is_grid_visible: false,
                is_reset: true,
                advanced_search_values: {},//to clear patient advance search control 
                controls_is_expended: true,
                disclaimer_is_expended: false
            })
        }
        session_storage.remove('report_search_criteria');
        session_storage.remove('report_advance_search');
        this.initial_control_data_text={}
    };

    //Function calls on initialization of Ag-Grid and catch its reference.
    get_grid_ref = grid_params => {
        if (this._is_mounted) {
          this.setState(
            {
              grid_params,
              report_rows_count: grid_params.api.getModel().getRowCount()
            },
            () => {
              const report_id = this.get_report_grid_id();
              const grid_width = document.querySelector(`#${report_id} .ag-body`).clientWidth;
              if (this.total_column_width > grid_width - report_constants.const_data_width) {
                this.report_data.cols.filter((item) => {
                  grid_params.columnApi.setColumnWidth(item.field, item.width, false);
                });
              }

              // setting the focus on grid header in case of sub report
              if (this.is_sub_report_data) {
                set_focus_on_element_with_id(report_data_formatter.report_grid_first_header_id);
              }
              this.check_is_company_print();
              if (this.props.auto_print) {
                this.render_print_data();
              }
              if (this.props.downloadPdf) {
                this.download_pdf();
              }
            }
          );
        }        
    };

    // Use to set the grid row and footer height.
    getRowHeight = (params) => {
        if (params.node.rowPinned) {
            return 38;
        }
        else {
            return 28;
        }
    }

    //Used to create the title for export and print.
    set_title = (separator) => {
        this.check_is_company_print();
        this.patient_name = (this.location.pathname.indexOf('patient') != -1) ?
            this.props.selected_patient.data.name.first_name +
            " " +
            this.props.selected_patient.data.name.last_name +
            separator : "";
        this.account_no = (this.location.pathname.indexOf('patient') != -1) ? 'Account#:' + session_storage.get('active_patient') + separator : "";
        this.current_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
        this.title = this.report_data.title;
        this.title = this.title && this.title
        .replace('%20', ' ')
        .replace('%2c', ',')
        .replace('%23', '#')
        .replace(/<Font Color=red>/g, '')
        .replace(/<font color=red>/g, '')
        .replace(/<\/Font>/g, '')
        .replace(/<\/font>/g, '')
        .replace('-1', 'All');
        return this.title = decodeURIComponent(
            (this.sub_report_id === report_constants.failed_charges_report_id_30286?
            encodeURIComponent(this.report_data.header.replace(/<br>/g, separator)):
            this.report_data.header.replace(/<br>/g, separator)) +
            (this.is_print_show_company ? this.company_name + separator : '') +
            (this.report_id === 131 ? this.patient_name : '') +
            this.title.replace(/<br>/g, separator) +
            this.current_date +
            separator +
            (this.state.report_rows_count == 1
              ? this.state.report_rows_count + ' record'
              : this.state.report_rows_count + ' records') +
            separator +
            separator
        );
    }

    //Used to create the title for export and print.
    set_drill_down_title = () => {
        let separator = '<br>';
        this.title = this.report_data.title;
        this.title = this.title && this.title
        .replace('%20', ' ')
        .replace('%2c', ',')
        .replace('%23', '#')
        .replace(/<Font Color=red>/g, '')
        .replace(/<font color=red>/g, '')
        .replace(/<\/Font>/g, '')
        .replace(/<\/font>/g, '')
			.replace('-1', 'All');
		var sub_report_title = ((this.sub_report_id === report_constants.failed_charges_report_id_30286) || (this.sub_report_id === report_constants.failed_charges_report_id_30380))
			? this.title : this.company_name + separator + this.title;
		return decodeURIComponent(sub_report_title);
    }

    //Function calls on initialization of export report data
    on_export_button = () => {
        this.title = this.set_title('\n');
        this.report_name =
          this.sub_report_id === report_constants.failed_charges_report_id_30286
            ? report_constants.failed_charges_name
                : this.report_data.header.replace(/<br>/g, '');
        if (this.is_patient_statement_log())
            this.state.grid_params.columnApi.getAllColumns().shift();
        export_print_utility.export_grid_data(this.state.grid_params, this.title, this.report_name);
        this.saveReportEventAction(report_constants.ReportEventAction.Export);
    };

    //Function calls on initialization of Print report data
    on_print_button = () => {
        this.title = this.set_title('<br>');
        this.report_name =
          this.sub_report_id === report_constants.failed_charges_report_id_30286
            ? report_constants.failed_charges_name
                : this.report_data.header.replace(/<br>/g, '');
        if (this.sub_report_id == report_constants.eob_report) {
            let gridData = eob_print_utility.get_grid_data_for_eob(this.state.grid_params);
            eob_print_utility.on_print_button_for_eob_report(
                gridData,
                this.report_data.eob_glossary,
                this.report_data.eob_recoup
            );
        } else if (this.report_id == report_constants.attorney_statement) {
          export_print_attorney_statements.on_print_button_attorney_statement(
            this.report_data.attorney_dataset,
            this.props.user_login_details.user_data.data.user_id
          );
        }
        else {
            if (this.is_patient_statement_log())
                this.state.grid_params.columnApi.getAllColumns().shift();

            export_print_utility.print_grid_data(this.state.grid_params, this.title, this.report_name);
        }
        this.saveReportEventAction(report_constants.ReportEventAction.Print);
    };


    private is_patient_statement_log = () => {
        if (this.report_id == report_constants.patient_statement_log && this.state.grid_params && this.state.grid_params.columnApi
            && this.state.grid_params.columnApi.getAllColumns().length && this.state.grid_params.columnApi.getAllColumns()[0].colDef.headerName == 'Preview')
            return true;
        else
            return false;
    }

    private getAuditSearchCriteria = (data: DataEntry, isViewable: boolean) => {
        this.search_criteria.map(item=>{
            const labelName = isViewable ? item.label_name.replace(' ','_') : item.name.replace(' ','_')
            if(labelName==='')
                return;
            if (this.props.features.magnusPlat5013PatientEventsExcludingSsn && findSensitiveData(this.report_id, item))
                return;
            if (moment.isMoment(item.default_value))
                data[labelName] = custom_date_format(item.default_value, global_constants.date_format.standard_date_time_format);
            else
            { 
                data[labelName] =isViewable ?  item.invisible?'': getValueFromReportComponentType(item) : item.default_value
            }
        });
    }

    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;
        const reportId = String(this.sub_report_id === 0 ? this.report_id : this.sub_report_id);
        const {entityReportId,featureFlipName}= getReportDynamic(reportId);

        if( get_lauch_darkley_key_value(this.props.launch_darkly, featureFlipName))
        {
            const dataEntry: DataEntry = {
                reportId: reportId,
                Records: Number(this.report_data.rows.length)
            };
            this.getAuditSearchCriteria(dataEntry,false);
            const viewableEntry: DataEntry = {};
            this.getAuditSearchCriteria(viewableEntry,true);
            viewableEntry[constants.getLabelRecords(eventActionId)] =  Number(this.report_data.rows.length);
            const data: PrintExportConstants.IPrinExportDto = {
                entityTypeId: entityReportId,
                auditDataEntry: dataEntry,
                viewableDataEntry: viewableEntry
            };

            PrintExportConstants.savePrintExportAudit(data, accessToken, eventActionId);
        }
        else
        {
            const payload: report_constants.IPayloadForBillingAudit = {
                reportId: reportId,
                contextTitle: `${reportId} - ${this.report_name} - ${report_constants.ReportEventAction[eventActionId]}`,
                eventActionId: eventActionId,
                userId: Number(user_id),
                companyId: Number(company_id),
                entityTypeId: report_constants.ReportEntityTypeId.a_rpt_ver,
                data: { Records: this.state.report_rows_count }
            }
            
            const reqBody = export_print_utility.generateAuditDataEntryPayload(payload);
            
            report_constants.saveAuditPrintExport(reqBody, accessToken);
        }
    };

     // Function calls on initialization of Print report data
    render_print_data = () => {
        this.report_name = this.report_data.header.replace(/<br>/g, '');
        this.title = this.set_title('<br>');
        this.printable_data = (() => {
            switch (true) {
                case this.sub_report_id == report_constants.eob_report:
                    return eob_print_utility.on_print_button(
                        this.state.grid_params,
                        this.title,
                        this.report_data.eob_glossary,
                        this.report_data.eob_recoup,
                        this.props.auto_print
                    );
                case this.sub_report_id == report_constants.eob_error_report:
                    return eob_error_print_utility.on_print_button(
                        this.state.grid_params,
                        this.title,
                        this.report_data.eob_error,
                        this.report_data.eob_change,
                        this.props.auto_print,
                        this.props.features.argos_bill_1162_editable_835s,
                    );
                case this.report_id == report_constants.attorney_statement:
                    return export_print_attorney_statements.on_print_button_attorney_statement(
                        this.report_data.attorney_dataset,
                        this.props.auto_print
                    );
                case this.sub_report_id == report_constants.failed_charges_reason:
                    return export_print_utility.failed_charges_print(
                        this.state.grid_params,
                        this.title,
                        this.report_name,
                        'print_report_button',
                        this.props.auto_print
                    );
                case this.sub_report_id === report_constants.patient_statement:
                    return patient_statement_print_utility.get_patient_statement_print_data(
                        this.patientStatementPrintData,
                        this.props.auto_print
                    );
                default:
                    return export_print_utility.print_grid_data(
                        this.state.grid_params,
                        this.title,
                        this.report_name,
                        'print_report_button',
                        this.props.auto_print
                    );
            }
        })();
        this.setState({
            loading: false,
            printable_data: this.printable_data
        });
    };
 
    download_pdf = () => {
        this.title = this.set_title('<br>');
        if (this.sub_report_id == report_constants.eob_report) {
            this.printable_data = eob_print_utility.on_print_button(
                this.state.grid_params,
                this.title,
                this.report_data.eob_glossary,
                this.report_data.eob_recoup,
                true
            );
        } else if (this.sub_report_id == report_constants.eob_error_report) {
            this.printable_data = eob_error_print_utility.on_print_button(
                this.state.grid_params,
                this.title,
                this.report_data.eob_error,
                this.report_data.eob_change,
                true,
                this.props.features.argos_bill_1162_editable_835s,
            );
        } else if (this.report_id == report_constants.attorney_statement) {
            this.printable_data = export_print_attorney_statements.on_print_button_attorney_statement(
                this.report_data.attorney_dataset,
                true
            );
        }
        else if (this.sub_report_id === report_constants.patient_statement) {
            this.printable_data = patient_statement_print_utility.get_patient_statement_print_data(
                this.patientStatementPrintData,
                true
            );
        }
        else {
          this.printable_data = export_print_utility.print_grid_data(
            this.state.grid_params,
            this.title,
            this.report_name,
            'print_report_button',
            true
          );
        }
        export_print_utility.open_printable_report_new_window(
          this.printable_data,
          this.get_report_grid_id(),
          this.props.after_download_pdf
        );
    }

    //To handle focus on grid.
    handle_focus_for_grid = (event) => {
        let child_element_exist = this.disclaimer;
        
        if(child_element_exist) {
            return;
        }
        // apply a check where grid is empty
        // if it is not empty send the focus to app header
        if (!event.shiftKey && event.keyCode == '9' && ((!this.state.is_grid_visible) || (this.report_data.rows == null) || (this.report_data.rows.length == 0))) {
            set_focus_to_app_header(event);
        }
    }

    on_group_by_Change = (fields) => {
        if (this._is_mounted) {
            let row_count = 0;
            if (fields && fields.length > 0) {
                this.state.grid_params.api.forEachNodeAfterFilter((row, index) => {
                    if (row.group && row.level == 0) {
                        row_count++;
                    }
                });
            } else {
                row_count = this.state.grid_params.api.getModel().getRowCount();
            }
            this.setState({
                report_rows_count: row_count
            });
        }
    };

    on_filter_button_click = () =>{
        let row_count = 0;
        if (this.has_hierarchy) {
          this.state.grid_params.api.forEachNodeAfterFilter((row, index) => {
            if (row.group) {
              row_count++;
            }
          });
        } else if (this.report_data.allow_grouping) {
            //Update group row count
            let group = false;
            this.state.grid_params.api.forEachNodeAfterFilter((row, index) => {
                if (row.group && row.level==0) {
                    row_count++;
                    group = true
                }
            });
            if (!group) {
                row_count = this.state.grid_params.api.getModel().getRowCount();
            }
        } else {
          row_count = this.state.grid_params.api.getModel().getRowCount();
        }

        this.setState({
          report_rows_count: row_count
        });
        report_data_formatter.on_filter_button_click(this.state.grid_params, this.report_data, this.report_id);
    }

    show_drill_down_title = () => { 
        this.setState({
            drill_down_title_shown : !this.state.drill_down_title_shown
        })
    }

    check_is_company_print = () => {
        let company_array: any = [];
        let row_data =[];
        this.state.grid_params.api.forEachNodeAfterFilter((rowNode) => {
            row_data.push(rowNode.data);
            return;
        });
        this.company_name = this.company_name
          ? this.company_name
          : this.props.user_login_details.user_data.data.company_name;
        this.report_data.cols.filter(item => {
          if (item.headerName && item.headerName == 'Company Name') {
            company_array = this.filter_multiple_company_from_company_column(item,row_data)
          }
        });
        if (this.initial_control_data_text['companyid']) {
            if(this.initial_control_data_text['companyid']['text']){
                this.company_name = this.initial_control_data_text['companyid']['text'];
            }
          this.is_print_show_company = this.initial_control_data_text['companyid']['value'] == -1 ? false : true;
        }
        this.check_multiple_company_in_grid(company_array)
        if (!this.from_popup && !this.is_sub_report_data) {
          this.props.set_company_name(this.company_name);
        } else if (this.props.get_company_name) {
          this.company_name = this.props.get_company_name;
        }
    };
    
    filter_multiple_company_from_company_column = (item, row_data) => {
        let company_row: any = [];
        row_data.filter(compItem => {
          company_row.indexOf(compItem[item.field]) == -1 && company_row.push(compItem[item.field]);
          return;
        });
        return company_row;
    };
    
    check_multiple_company_in_grid = (company_array) => {
        if (company_array.length == 1) {
            this.company_name = company_array[0];
            this.is_print_show_company = true;
        } else if (company_array.length > 1) {
            this.is_print_show_company = false;
        }
    }

    // Disclaimer Toggler
    disclaimer_toggle = () => {
        if (this._is_mounted) {
            this.setState({
                disclaimer_is_expended: !this.state.disclaimer_is_expended
            });
        }
    }
    // Search Criteria Toggler
    controls_toggle = () => {
        if (this._is_mounted) {
            this.setState({
                controls_is_expended: !this.state.controls_is_expended
            });
        }
    }

    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }

    // Click function of checkbox
    on_check_box_selection = items => {
        let columnName = "stringCol4";
        let reportColumns = this.report_data.cols;

        if (reportColumns && reportColumns.length > 0) {
            reportColumns.forEach(function (currentItem) {
                if (currentItem.headerName == "Record ID") {
                    columnName = currentItem.field;
                }
            });
        }

        this.setState({
            selected_items: items.map(item => item[columnName])
        });
    }

    on_check_box_selection_for_actions = items => {
        this.setState({
            selected_items: items
        });
    }

    get_grid_option = () => {
        if (this.report_data.link_drill_down_last_page) {
            return {
                onRowSelection: this.on_check_box_selection,
                checkboxSelection: true
            }
        }
        if (this.report_data.additional_actions && this.report_data.additional_actions.length >0) {
            return {
                onRowSelection: this.on_check_box_selection_for_actions,
                checkboxSelection: true
            }
        }

        return null;
    }

    remove_items = () => {
        const params = {
            rejected_record_ids: [...this.state.selected_items],
            user_id: this.props.user_login_details.user_data.data.user_id
        }
        this.start_loading();
        remove_billing_exceptions(params, this.token).then(
            (response) => {
                if (response.data && response.data.status === 1) {
                    this.setState({ selected_items: [] });
                    this.get_data_request();
                } else {
                    if (response.data.messages[0].message.indexOf('<br') > -1) {
                        this.show_html_content_toaster(response.data.messages[0].message);
                    } else {
                        toaster.error('', response.data.messages[0].message);
                    }
                }
            },
            (error) => {
                if (error.response.data) toaster.error('', error.response.data.messages[0].message);
            }
        );
    }

    reprocess_items = () => {
        const params = {
            rejected_record_ids: [...this.state.selected_items],
            user_id: this.props.user_login_details.user_data.data.user_id
        }

        this.start_loading();
        reprocess_billing_exceptions(params, this.token).then(
            (response) => {
                if (response.data && response.data.status === 1) {
                    this.setState({ selected_items: [] });
                    this.get_data_request();
                } else {
                    if (response.data.messages[0].message.indexOf('<br') > -1) {
                        this.show_html_content_toaster(response.data.messages[0].message);
                    } else {
                        toaster.error('', response.data.messages[0].message);
                    }
                }
            },
            (error) => {
                if (error.response.data) toaster.error('', error.response.data.messages[0].message);
            }
        );
    }

    handle_confirm = (e) => {
        e.preventDefault();
        this.setState(
            { open_confirm_alert: false },
            () => {
                this.remove_items();
            }
        );
    }

    // Function callback to reprocess checked items
    on_reprocess_button = () => {
        if (this.state.selected_items.length > 0) {
            this.reprocess_items();
        } else {
            toastr.error('', messages.reprocess_error);
        }
    };

    //Function callback to remove checked items
    on_remove_button = () => {
        if (this.state.selected_items.length > 0) {
            this.setState({
                open_confirm_alert: true,
                confirm_message: messages.remove_confirm
            });
        } else {
            toastr.error('', messages.remove_error);
        }
    };

    execute_action = async (action,selected_items) => {
        this.start_loading();
        let  {status,message,refresh} = await action.on_click(this.token,selected_items);
        if(status){
            if (message) toaster.success('', message);
            if(refresh){
                this.setState({ selected_items: [] });
                this.get_data_request();
            } else {
                this.stop_loading();
            }
        } else {
            if (message) toaster.error('', message);
            this.stop_loading();
        }
    }

    handle_confirm_for_actions = (e) => {
        e.preventDefault();
        let action = this.state.action_to_execute;
        let selected_items = this.state.selected_items_for_actions;
        this.setState(
            { 
                open_confirm_alert_for_actions: false,
                confirm_message_for_actions:'',
                action_to_execute:{},
                selected_items_for_actions: []
            },
            () => {
                this.execute_action(action,selected_items);
            }
        );
    }

    show_confirmation_alert = (action,selected_items) => {
        if(action.confirmation_alert){
            this.setState({
                open_confirm_alert_for_actions: true,
                confirm_message_for_actions: action.confirmation_alert,
                action_to_execute: action,
                selected_items_for_actions: selected_items,
            });
        } else {
            this.execute_action(action,selected_items)
        }
    };

    validate_items_selected = (action) => {
        if(action.validate_items_selected){
            if (this.state.selected_items.length > 0) {
                this.show_confirmation_alert(action,this.state.selected_items)
            } else {
                toastr.error('', action.validate_items_selected_msg || messages.select_record_error);
            }
        }else{
            this.show_confirmation_alert(action,[])
        }
    };

    render_confirmation_alert = () => {
        if (this.report_data.link_drill_down_last_page) {
            return (<AlertConfirm
                open={this.state.open_confirm_alert}
                close={() => this.setState({ open_confirm_alert: false })}
                cancel={() => this.setState({ open_confirm_alert: false })}
                confirm={this.handle_confirm}
                message={this.state.confirm_message}
            />);
        }

        return null;
    }

    render_confirmation_alert_for_additional_actions = () => {
        let additional_actions = (this.report_data && this.report_data.additional_actions?this.report_data.additional_actions.filter((action)=> action.confirmation_alert):[])
        if (additional_actions.length >0) {
            return (<AlertConfirm
                open={this.state.open_confirm_alert_for_actions}
                close={() => this.setState({ open_confirm_alert_for_actions: false })}
                cancel={() => this.setState({ open_confirm_alert_for_actions: false })}
                confirm={this.handle_confirm_for_actions}
                message={this.state.confirm_message_for_actions}
            />);
        }
        return null;
    }

    get_report_grid_id = () => {
        if (this.report_id === 30200) {
            let is_report = this.location.pathname.indexOf('sub_report_data') === -1;
            return (is_report) ? `report_id_${this.report_id}` : `sub_report_id_${this.sub_report_id}`;
        }

        return this.sub_report_id == 0 ? `report_id_${this.report_id}` : `report_id_${this.sub_report_id}`;
    }

    get_hover_column = () => {
        if (this.report_id == 30380) {
            return "stringCol5";
        } else {
            return null;
        }
    }

    get_hover_column_source = () => {
        if (this.report_id == 30380) {
            return "stringCol7";
        } else {
            return null;
        }
    }

    onCellMouseOverFunc = (hover_text: string, loc_x: number, loc_y: number) =>  {
        this.setState({
            show_hover_text: true,
            hover_value: hover_text,
            hover_x: loc_x,
            hover_y: loc_y
        });
    }

    onCellMouseOutFunc = () => {
        this.setState({
            show_hover_text: false
        });
    }

    //It renders report grid and its search controls.
    render() {
        return (
            <div className={
                this.is_financial
                    ? 'common-forms-add financial'
                    : 'common-forms-add'
            }>
                <Dimmer active={this.state.loading}>
                    <Loader size={this.props.downloadPdf ? 'small':'massive'}>Loading</Loader>
                </Dimmer>
                {this.render_confirmation_alert()}
                {this.render_confirmation_alert_for_additional_actions()}
                <div
                    style={{display: 'flex'}}
                    className={
                        this.is_sub_report_data
                            ? 'common-forms-search report-framework subReport'
                            : 'common-forms-search report-framework'
                    }>
                    {this.is_sub_report_data
                        ? <SubReportHeader
                            title={this.report_data.header}
                            length={this.state.report_rows_count}
                            show_drilldown_title={this.show_drill_down_title}                            
                            drilldown_title_shown={this.state.drill_down_title_shown}
                            drilldown_title={this.set_drill_down_title()}
                        />
                        : <ReportHeader title={this.report_metadata.report_header} />
                    }
                    {this.props.auto_print && (                    
                        <div
                            id='report-scrollable-area'
                            className='report-wrapper'
                            style={{flex: '1 1 0',overflow: 'auto', padding: 5}}
                        >
                            <React.Fragment>
                                {!this.is_sub_report_data && (<Grid className='dateTime'>
                                    <Grid.Column tablet={5} computer={4} textAlign='left'>
                                        <p style={{ fontSize: '16px', minHeight: 22 }}>
                                            {this.run_report_date}
                                        </p>
                                    </Grid.Column>
                                    <Grid.Column tablet={5} computer={8} textAlign='center'>
                                        {<p style={{ fontSize: '16px', minHeight: 22 }}>
                                            {`${this.state.report_rows_count} ${(this.state.report_rows_count == 1 ? ' record shown' : ' records shown')}`}
                                        </p>}
                                    </Grid.Column>
                                    <Grid.Column tablet={2} computer={4} textAlign='right'>
                                    </Grid.Column>
                                </Grid>)}
                                <div className="main-grid-wrapper grid_wrapper"
                                dangerouslySetInnerHTML={{ __html: this.state.printable_data }} />
                            </React.Fragment>
                        </div>
                    )}  
                    <div
                        id='report-scrollable-area'
                        className='report-wrapper'
                        style={this.props.auto_print ? { display: 'none' } : this.state.is_grid_visible ? { flex: 1 } : {}}
                    >
                        {this.state.search_criteria && !this.is_sub_report_data && (
                            <div className='patient-search-form  patient_search_bottom_padding' id='report-criteria-container'>
                                {this.is_control_visible.length > 0 && (
                                    <ReportController
                                        run_report_handler={this.run_report_handler}
                                        input_fields={this.input_fields}
                                        set_control_type={this.set_control_type}
                                        clear_handler={this.clear_handler}
                                        handle_focus_for_grid={this.handle_focus_for_grid}
                                        is_search_button_disabled={this.state.is_search_button_disabled}
                                        controls_toggle={this.controls_toggle}
                                        controls_is_expended={this.state.controls_is_expended}
                                        show_parm_level_2={this.report_metadata.show_Parm_Lavel_2}
                                    />
                                )}
                            </div>
                        )}
                        {this.state.is_grid_visible && (
                            <React.Fragment>
                                {!this.is_sub_report_data && !this.show_pagination && (<Grid className='dateTime'>
                                    <Grid.Column tablet={5} computer={4} textAlign='left'>
                                        <p style={{ fontSize: '16px', minHeight: 22 }}>
                                            {this.run_report_date}
                                        </p>
                                    </Grid.Column>
                                    <Grid.Column tablet={5} computer={8} textAlign='center'>
                                        {<p style={{ fontSize: '16px', minHeight: 22 }}>
                                            {`${this.state.report_rows_count} ${(this.state.report_rows_count == 1 ? ' record shown' : ' records shown')}`}
                                        </p>}
                                    </Grid.Column>
                                    <Grid.Column tablet={2} computer={4} textAlign='right'>
                                    </Grid.Column>
                                </Grid>)}
                                {this.state.show_hover_text && (
                                    <div id="hoverText" className='panel panel-default'
                                        style={{ position: 'fixed', backgroundColor: 'white', padding: '3px', border: '1px solid black', borderRadius: '5px', pointerEvents: 'none', zIndex: 10, left: this.state.hover_x, top: (this.state.hover_y - 30) }}>{this.state.hover_value}</div>    
                                )}
                                <ReportGrid
                                    id={this.get_report_grid_id()}
                                    row={this.report_data.rows}
                                    column={this.report_data.cols}
                                    headerHeight={this.grid_header_height}
                                    styles={{ height: '100%', display: 'flex', flex: 1, flexDirection: 'column' }}
                                    wrapperStyle={{ width: '100%', height: '100%', display: 'flex', flex: 1 }}
                                    wrapperClass={this.has_hierarchy ? 'grid_wrapper_hierarchy' : 'grid_wrapper'}
                                    suppressMovableColumns={true}
                                    suppressColumnVirtualisation={true}
                                    enableColResize={true}
                                    rowGroupPanelShow={this.report_data.allow_grouping}
                                    onGridOut={() => {
                                        set_focus_on_element_with_id("export_report_button")
                                    }
                                    }
                                    emptyMessage={report_constants.messages.no_records_found}
                                    pinnedBottomRowData={
                                        this.report_data.ShowFooter && [this.report_data.footer_obj]
                                    }
                                    get_grid_ref={this.get_grid_ref}
                                    on_filter_button_click={this.on_filter_button_click}
                                    getRowHeight={this.getRowHeight}
                                    headerIdForTabNavigation={report_data_formatter.report_grid_first_header_id}
                                    displayGroupRowCount={this.state.displayGroupRowCount}
                                    onGroupByChange={this.on_group_by_Change}
                                    has_hierarchy={this.has_hierarchy}
                                    {...this.get_grid_option()}
                                    isPagination={this.show_pagination}
                                    onCellHoverTooltipColumn={this.get_hover_column()}
                                    onCellHoverTooltipSource={this.get_hover_column_source()}
                                    onCellMouseOverFunc={this.onCellMouseOverFunc}
                                    onCellMouseOutFunc={this.onCellMouseOutFunc}
                                    expandGroupByDefault={this.expandGroupReportByDefault}
                                    customColumnToTotal={this.customColumnToTotal}
                                    
                                />
                            </React.Fragment>
                        )}
                    </div>
                    <div className='ui grid sixteen wide computer sixteen wide tablet column footer-area' id='applicationFooterSticky'>
                        <ReportFooter
                            on_print_button={() => this.on_print_button()}
                            on_export_button={() => this.on_export_button()}
                            on_reprocess_button={() => this.on_reprocess_button()}
                            on_remove_button={() => this.on_remove_button()}
                            validate_items_selected={this.validate_items_selected}
                            export_disabled={this.state.is_export_disabled}
                            print_disabled={this.state.is_print_disabled}
                            reprocess_disabled={this.state.is_reprocess_disabled}
                            remove_disabled={this.state.is_remove_disabled}
                            disclaimer={this.disclaimer}
                            disclaimer_toggle={this.disclaimer_toggle}
                            disclaimer_is_expended={this.state.disclaimer_is_expended}
                            link_drill_down_last_page={this.state.link_drill_down_last_page}
                            additional_actions={this.state.additional_actions}
                            is_export_notvisible = {this.props.is_export_disabled}
                            is_print_notvisible = {this.props.is_print_disabled}
                            is_close_button={this.props.is_close_button}
                            on_close={this.props.on_close}
                        />
                    </div>
                    {/*<PatientStatementPrint />*/}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        get_patient_search: get_patient_search,
        set_company_name: set_company_name
    }, dispatch)
}

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        selected_patient: state.patient_details.patient_header,
        user_login_details: state.user_login_details,
        patient_details: state.patient_details,
        get_company_name: state.report_details.company_name,
        shared_details: state.shared_details,
        features: {
            rsiB30996LedgerVisitCheckEftNumber835ViewModal: get_lauch_darkley_key_value(state.launch_darkly, 'rsiB30996LedgerVisitCheckEftNumber835ViewModal'),            
            argos_bill_1162_editable_835s: get_lauch_darkley_key_value(state.launch_darkly, "argosBill1162Editable835S"),
            magnusPlat5013PatientEventsExcludingSsn: get_lauch_darkley_key_value(state.launch_darkly, "magnusPlat5013PatientEventsExcludingSsn"),
        },
        launch_darkly:state.launch_darkly
    };
};

export default connect(
    mapStateToProps, mapDispatchToProps
)(ReportComponent);