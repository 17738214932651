import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { Button, Checkbox, Grid, Input, Radio, Table } from 'semantic-ui-react';
import * as global_constants from '../../global_constants';
import DatePicker from '../../shared/component/calendar_component';
import { custom_date_format, date_format_with_no_time, generate_guid } from '../../shared/utility';
import { enum_case_page_state, enum_type_of_insurance } from '../case_constants';
import { add_preauth } from './../../case/action/subscriber_action';
import CheckboxComponent from "./../../shared/component/checkbox_component"

export class InsurancePreauthorizationComponent extends React.Component<any, any> {
    _is_mounted = false;
    constructor(props) {
        super(props);
        //Set initial state
        this.state = {
            show_inactive_preauths: false,
            preauth_list: []
        }
    }

    //Set initial variables
    insurance_type = '';
    insurance_type_id=''
    page_state = '';
    active_preauth = 0;
    preauth_list = [];
    is_view_mode = true;
    preauth_obj = {
        from_date: null,
        thru_date: null,
        is_active: true,
        pre_auth_number: '',
        pre_auth_visits: '',
        visits_used:'',
        subscriber_id: 0,
        case_id:0,
        is_units: false,
        is_selected: false,
        is_saved: false,
        is_changed: false,
        id: 0,
        unique_id:'00000000-0000-0000-0000-000000000000'
    }

    // Get preauth on DOM load
    UNSAFE_componentWillMount() {
        this.get_preauth_list(true);
        this.is_view_mode = this.page_state == enum_case_page_state.edit ? true : false;
    }

    // Set this refrence in ref prop
    componentDidMount() {
        this._is_mounted = true;
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    // Get preauth after DOM render
    componentDidUpdate(previousProps) {
        if (previousProps.subscriber_data.id !== this.props.subscriber_data.id) {
            this.get_preauth_list(this._is_mounted);
        } else if (previousProps.page_state != this.props.page_state) {
            this.get_preauth_list(this._is_mounted);
        }
        else if (previousProps.subscriber_fetched_time != this.props.subscriber_fetched_time) {
            this.get_preauth_list(this._is_mounted);
        }
        this.is_view_mode = this.page_state == enum_case_page_state.edit ? true : false;
    }

    // Used to fetch and bind the data from insurnace component
    get_preauth_list = (is_mount) => {
        this.preauth_list = this.props.subscriber_data.subscriber_pre_auth;
        this.preauth_list = this.preauth_list.map(data => {
            data.is_saved = true;
            data.visits_used = data.visits_used ? data.visits_used : 0;
            return data;
        })
        this.page_state = this.props.page_state;
        this.insurance_type = this.props.insurance_type;
        this.insurance_type_id = this.props.insurance_type.split(' ')[0];
        //Remove it as it is call from will mount event
        if (is_mount) {
            this.setState({
                preauth_list: this.preauth_list,
                show_inactive_preauths: false
            });
        }
    }

    // Function call on click of Show Inctive checkbox and show all active and inactive preauth
    show_inactive_preauths_change = () => {
        if (this._is_mounted) {
            this.setState({
                show_inactive_preauths: !this.state.show_inactive_preauths,
            });
        }
    }

    // Function call on click of Add Preauthorization and refresh preauth list
    add_preauth = () => {
        this.preauth_list.push({ ...this.preauth_obj, subscriber_id: this.props.subscriber_data.id, case_id: this.props.subscriber_data.case_id })
        if (this._is_mounted) {
            this.setState({
                preauth_list: this.preauth_list
            });
        }
    }

    // Used to validate the preauth list
    validate_preauth_data = () => {
        var invalid = false;
        this.preauth_list.map((data) => {
            if ((data.thru_date || data.pre_auth_number || data.pre_auth_visits) && data.from_date == null) {
                invalid = true;
            }
        })
        return invalid;
    }

    // Used to check valid list is empty or not
    is_empty_preauth_list = () => {
        if (this.get_valid_preauth_data().length > 0) {
            return false;
        }
        return true;
    }

    // Used to fetch valid records which have from date value
    get_valid_preauth_data = (update=false) => {
        var valid_preauth_list = [];
        valid_preauth_list = this.preauth_list.filter((data, index) => {
            if (data.from_date != null) {
                data.is_saved = true;
                data.is_changed = false;
                data.sequence_number = index;
                data.pst_coverage = this.insurance_type === enum_type_of_insurance.primary_insurance ? "P" : (this.insurance_type === enum_type_of_insurance.secondary_insurance ? "S" : "T");
                data.visits_used = data.visits_used ? data.visits_used : 0;
                if (update) {
                    data.unique_id = generate_guid();
                }
                return data;
            }
        });
        if (this._is_mounted) {
            this.setState({
                show_inactive_preauths: false
            });
        }
        return valid_preauth_list;
    }


    // Function used to save the preauth data into DB
    on_save_preauth = (case_id, subscriber_id, preauth_list) => {
        preauth_list = preauth_list.map((data, index) => {
            if (data.subscriber_id == 0) {
                data.subscriber_id = subscriber_id
            }
            return data;
        })
        return add_preauth(this.props.user_login_details.user_data.data.token_details.access_token,case_id, subscriber_id, preauth_list);
    }

    // Function calls on click of Delete Preauthorization and refresh preauth list
    delete_preauth = () => {
      //toaster custom option definition
       const toastrOptions = {
            timeOut: 3000, // by setting to 0 it will prevent the auto close
        }
        let delete_list = this.preauth_list.filter((data) => {
            if (data.is_selected)
                return true;
        })

        if (delete_list.length==0) {
            toastr.warning('', "Please select a preauthorization", toastrOptions);
            return;
        }
        this.preauth_list = this.preauth_list.filter((data) => {
            if (!data.is_selected)
                return true;
        })
        if (this._is_mounted) {

            this.setState({
                preauth_list: this.preauth_list
            });
        }
    }

    // Function calls on date change.
    on_date_change = (date_object, index, property) => {
        this.preauth_list[index][property] = date_object ? date_format_with_no_time(date_object) : date_object;
        this.preauth_list[index]["is_changed"] = true;
        if (this._is_mounted) {
            this.setState({
                preauth_list: this.preauth_list
            });
        }
    }

    // Function calls on checkbox change.
    on_checkbox_change = (e, index, property) => {
        this.preauth_list[index][property] = e.currentTarget.checked;
        this.preauth_list[index]["is_changed"] = true;
        if (this._is_mounted) {
            this.setState({
                preauth_list: this.preauth_list
            });
        }
    }

    // Function calls on textbox change.
    on_textbox_change = (e, index, property) => {
        if (property == "pre_auth_visits") {
            if (e.currentTarget.value.length > 0 && e.currentTarget.value.match(/[^0-9]/g) && e.currentTarget.value.match(/[^0-9]/g).join("") != null) {
                return;
            }
        }
        this.preauth_list[index][property] = e.currentTarget.value;
        this.preauth_list[index]["is_changed"] = true;
        if (this._is_mounted) {
            this.setState({
                preauth_list: this.preauth_list
            });
        }
    }

    // Function calls on radio change.
    on_radio_change = (e, index, property, value) => {
        this.preauth_list[index][property] = value;
        this.preauth_list[index]["is_changed"] = true;
        if (this._is_mounted) {
            this.setState({
                preauth_list: this.preauth_list
            });
        }
    }
    componentWillUnmount = () => {
        this._is_mounted = false;
    }
    // Function render the html structure on component state change.
    render() {
        return (
            <Grid>
                <Grid.Column>
                    <Grid className="padd-15">
                        <Grid.Column mobile={16} tablet={16} computer={16}>
                            <p className="sub-heading-blue mar-r-15 float-left mar-b-0">{this.insurance_type} Preauthorizations</p>
                            {
                               /* <Checkbox
                                    label='Show Inactive'
                                    id={this.insurance_type_id + "show_inactive_preauths"}
                                    checked={this.state.show_inactive_preauths}
                                    onChange={this.show_inactive_preauths_change} />*/
                                <CheckboxComponent
                                    label='Show Inactive'
                                    id={this.insurance_type_id + "show_inactive_preauths"}
                                    checked={this.state.show_inactive_preauths}
                                    onChange={this.show_inactive_preauths_change}                                   
                                />
                            }
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={16}>
                            <div className="customtable">
                                <div className="custom-table-responsive table-elements-disabled">
                                    <Table>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell style={{ width: '50px', minWidth: '50px' }}>  </Table.HeaderCell>
                                                <Table.HeaderCell style={{ minWidth: '200px' }}>Preauthorization Number</Table.HeaderCell>
                                                <Table.HeaderCell style={{ width: '160px', minWidth: '150px' }}>From Date</Table.HeaderCell>
                                                <Table.HeaderCell style={{ width: '160px', minWidth: '150px' }}>Through Date</Table.HeaderCell>
                                                <Table.HeaderCell style={{ width: '170px', minWidth: '160px' }}>Number Authorized</Table.HeaderCell>
                                                <Table.HeaderCell style={{ width: '170px', minWidth: '160px' }}>Amount Used</Table.HeaderCell>
                                                {!this.is_view_mode && <Table.HeaderCell style={{ width: '160px', minWidth: '150px' }}>Visits or Units</Table.HeaderCell>}
                                                <Table.HeaderCell style={{ width: '100px', minWidth: '90px' }} textAlign="center">Active</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        {this.state.preauth_list.length > 0 &&(
                                            <Table.Body>
                                                {
                                                    this.state.preauth_list.map((preauth_row, index) => {
                                                        if (preauth_row.is_active || preauth_row.is_changed || this.state.show_inactive_preauths) {
                                                            return (<Table.Row key={index}>
                                                                <Table.Cell textAlign="center">
                                                                    <CheckboxComponent
                                                                        checked={preauth_row.is_selected}
                                                                        id={this.insurance_type_id + "_select_preauth" + index}
                                                                        onChange={(e) => { this.on_checkbox_change(e, index, 'is_selected') }}
                                                                        disabled={(preauth_row.id ? true : false) || this.is_view_mode}
                                                                    /> 
                                                                   
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <Input fluid
                                                                        type='text'
                                                                        maxLength={50}
                                                                        onChange={(e) => { this.on_textbox_change(e, index, 'pre_auth_number') }}
                                                                        value={preauth_row.pre_auth_number ? preauth_row.pre_auth_number : ''}
                                                                        disabled={this.is_view_mode}
                                                                        id={this.insurance_type_id + "_num_preauth" + index}
                                                                    />
                                                                </Table.Cell>
                                                                <Table.Cell className={(preauth_row.is_saved && !preauth_row.from_date) ? `error requiredWithBgColor custom-datepicker-for-table datepicker-keybowrd-thru-date-${index}` : `custom-datepicker-for-table datepicker-keybowrd-from-date-${index}`} >
                                                                    <DatePicker
                                                                        date_update={(date_object) => { this.on_date_change(date_object, index, 'from_date') }}
                                                                        class_name={`datepicker-keybowrd-from-date-${index}`}
                                                                        date={preauth_row.from_date ? moment.utc(preauth_row.from_date) : null}
                                                                        is_disabled={this.is_view_mode}
                                                                        id={this.insurance_type_id + "_from_preauth" + index}                                                                    
                                                                    />
                                                                </Table.Cell>
                                                                <Table.Cell className={`custom-datepicker-for-table datepicker-keybowrd-thru-date-${index}`}>
                                                                    <DatePicker
                                                                        date_update={(date_object) => { this.on_date_change(date_object, index, 'thru_date') }}
                                                                        class_name={`datepicker-keybowrd-thru-date-${index}`}
                                                                        date={preauth_row.thru_date ? moment.utc(preauth_row.thru_date) : null}
                                                                        is_disabled={this.is_view_mode}
                                                                        id={this.insurance_type_id + "_through_preauth" + index}
                                                                    />
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <Input fluid
                                                                        type='text'
                                                                        maxLength={9}
                                                                        onChange={(e) => { this.on_textbox_change(e, index, 'pre_auth_visits') }}
                                                                        value={(this.is_view_mode) ? (preauth_row.pre_auth_visits != null && preauth_row.pre_auth_visits != undefined && preauth_row.pre_auth_visits !== "") ? (preauth_row.is_units ? (preauth_row.pre_auth_visits == 1) ? preauth_row.pre_auth_visits + ' Unit' : preauth_row.pre_auth_visits + ' Units' :
                                                                            (preauth_row.pre_auth_visits == 1) ? preauth_row.pre_auth_visits + ' Visit' : preauth_row.pre_auth_visits + ' Visits') : '' : preauth_row.pre_auth_visits}
                                                                        disabled={this.page_state == enum_case_page_state.edit}
                                                                        id={this.insurance_type_id + "_visit_preauth" + index}
                                                                    />
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <span className={this.is_view_mode ? "text-disabled" : ''}>{
                                                                        (preauth_row.visits_used != null && preauth_row.visits_used != undefined && preauth_row.visits_used !== "") ? (preauth_row.is_units ?
                                                                            (preauth_row.visits_used == 1) ? preauth_row.visits_used + ' Unit' : preauth_row.visits_used + ' Units' :
                                                                            (preauth_row.visits_used == 1) ? preauth_row.visits_used + ' Visit' : preauth_row.visits_used + ' Visits')
                                                                            : ''}
                                                                        </span>
                                                                </Table.Cell>
                                                                {!(this.is_view_mode)  && 
                                                                    <Table.Cell textAlign="center">
                                                                    {/* <Radio
                                                                        name={this.insurance_type_id + "is_units" + index}
                                                                        checked={!preauth_row.is_units}
                                                                        label={"Visits"}
                                                                        value="false"
                                                                        onChange={(e, value) => { this.on_radio_change(e, index, 'is_units', !value.checked) }}
                                                                        disabled={this.is_view_mode}
                                                                    />
                                                                    &nbsp;
                                                                    &nbsp;
                                                                    <Radio
                                                                        name={this.insurance_type_id + "is_units" + index}
                                                                        checked={preauth_row.is_units}
                                                                        label={"Units"}
                                                                        value="true"
                                                                        onChange={(e, value) => { this.on_radio_change(e, index, 'is_units', value.checked) }}
                                                                        disabled={this.is_view_mode}
                                                                    />*/}                                                                
                                                                        <input
                                                                            type="radio"
                                                                            name={this.insurance_type_id + "is_units" + index}
                                                                            value="false"
                                                                            checked={!preauth_row.is_units}
                                                                            id={this.insurance_type_id + "_is_visits_" + index}
                                                                            disabled={this.is_view_mode}
                                                                            onChange={(e) => { this.on_radio_change(e, index, 'is_units', !e.target.checked) }}
                                                                        /> <label> Visits</label>                                                                
                                                                    &nbsp;
                                                                    &nbsp;
                                                                    
                                                                        <input
                                                                            type="radio"
                                                                            name={this.insurance_type_id + "is_units" + index}
                                                                            value="true"
                                                                            checked={preauth_row.is_units}
                                                                            id={this.insurance_type_id + "_is_units_" + index}
                                                                            disabled={this.is_view_mode}
                                                                            onChange={(e) => { this.on_radio_change(e, index, 'is_units', e.target.checked) }}
                                                                        /> <label> Units</label>
                                                                    </Table.Cell>
                                                                }
                                                                <Table.Cell textAlign="center">
                                                                    {/*<Checkbox
                                                                        id={this.insurance_type_id + "_active_preauth_" + index}
                                                                        checked={preauth_row.is_active}
                                                                        onChange={(e) => { this.on_checkbox_change(e, index, 'is_active') }}
                                                                        disabled={this.is_view_mode}
                                                                    />*/}
                                                                    <CheckboxComponent                                                                    
                                                                        id={this.insurance_type_id + "_active_preauth_" + index}
                                                                        checked={preauth_row.is_active}
                                                                        onChange={(e, checked) => { this.on_checkbox_change(e, index, 'is_active') }}
                                                                        disabled={this.is_view_mode}
                                                                    />
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            )
                                                        }
                                                    })
                                                }
                                            </Table.Body>
                                        )}
                                    </Table>
                                </div>
                            </div>
                        </Grid.Column>

                        <Grid.Column mobile={16} tablet={16} computer={16} textAlign="right" className="mar-t-15">
                            <Button
                                type="button"
                                basic id={this.insurance_type_id + "_delete_preauth"}
                                disabled={this.is_view_mode}
                                onClick={this.delete_preauth}>Delete</Button>
                            <Button
                                type="button"
                                primary id={this.insurance_type_id + "_add_preauth"}
                                disabled={this.is_view_mode}
                                onClick={this.add_preauth}>Add Preauthorization</Button>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>

        )
    }
}
    // Function used to get user details from store.
    const mapStateToProps = (state) => {
        return {
            user_login_details: state.user_login_details,
        };
    }

export default withRouter(connect(mapStateToProps)(InsurancePreauthorizationComponent))