import * as React from 'react';
import { Button, Grid, Modal } from "semantic-ui-react";
import { set_focus_on_element_with_id, handle_focus_on_tab } from './../../shared/tab_navigation_utility';

const AlertConfirm = (props) => {
    const { open, title, message, confirm, cancel, close, labelYes, labelNo, hide_cancel_button, highlightMessage } = props;
    var hide_cancel_button_class = { display: hide_cancel_button ? "none" : "" }
    return (
        <div className="item" id="id_div_alert">
            <Modal
                onClose={close}
                centered={false}
                className="default-modal"
                open={open}
                closeIcon
                closeOnDimmerClick={false}>
                <Modal.Header>{title || 'Confirm'}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Grid>
                            <Grid.Column>
                                <p>{message}</p>
                                {highlightMessage && <p className='highlightMessage'>{highlightMessage}</p>}
                            </Grid.Column>
                        </Grid>
                        <Grid>
                            <Grid.Column computer={16} textAlign="right">
                                <Button id="id_alert_btn_cancel" style={hide_cancel_button_class} type="reset" basic onClick={cancel}>{labelNo || 'Cancel'}</Button>
                                <Button id="id_alert_btn_ok" type='submit'
                                    onClick={e => confirm(e)}
                                    onKeyDown={(event) => { handle_focus_on_tab(event, "confirm_modal_window_close_icon") }}
                                    primary>
                                    {labelYes || 'OK'}
                                </Button>
                            </Grid.Column>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </div>)
};

export { AlertConfirm };

