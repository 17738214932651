import * as React from 'react';
import { Button, Dimmer, Icon, Input, Loader, Popup } from 'semantic-ui-react';
import AdvancedControlModal from './advanced_control_modal';
import { connect } from 'react-redux';
import { toastr as toaster } from 'react-redux-toastr';
import { toastr_options, url_rewrite } from '../../../../global_constants';
import { log_error } from '../../../../shared/action/shared_action';
import { get_all_error } from '../../../../shared/utility';
import { quick_advance_search } from '../../action/constants_action';
import { handle_click_on_enter, set_focus_to_app_header } from '../../../../shared/tab_navigation_utility';

interface IProps {
  data: any[];
  id?: string;
  class_name?: string;
  search_box_id?: string;
  advanced_control_name: string;
  lookup_id: string;
  add_label: string;
  controlId: string;
  search_type: string;
  is_add_edit_allowed: boolean;
  default_options: any[];
  options: any[];
  search_api: { global_base: string; end_points: any[] };
  user_login_details: { user_data: { data: { user_type_r3_id: any; token_details: { access_token: string } } } };
  action_callback: Function;
  onRef: any;
}

export class SearchComponent extends React.PureComponent<IProps, any> {
  static defaultProps: Partial<IProps> = {
    data: [],
    id: 'multi-select-dropdown',
    class_name: '',
    search_box_id: 'multi-select-dropdown-search',
    is_add_edit_allowed: false
  };

  constructor(props) {
    super(props);
    this.state = {
      original_data: this.props.data,
      data: this.props.data,
      loading: false,
      re_render: false,
      typing: false,
      typingTimeout: 0,
      look_up_params: {},
      filter_text: '',
      user_type: this.props.user_login_details.user_data.data.user_type_r3_id
    };
    this.token = this.props.user_login_details.user_data.data.token_details.access_token;
  }

  token: string;
  node: any;
  is_mounted = false;

  componentDidMount() {
    this.is_mounted = true;
    this.props.onRef(this);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      if (this.is_mounted) {
        this.setState({
          data: this.props.data,
          original_data: this.props.data
        });
      }
    }
  }

  componentWillUnmount() {
    this.is_mounted = false;
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.props.onRef(undefined);
  }

  on_search = (event) => {
    const value = event.target.value.toString().toLowerCase();
    if (value) {
      let data = this.state.original_data.filter((item) => {
        if (
          item['name'].toString().toLowerCase().includes(value) ||
          item['description'].toString().toLowerCase().includes(value)
        ) {
          return item;
        }
      });
      if (this.is_mounted) {
        this.setState({
          filter_text: value,
          data
        });
      }
    } else {
      if (this.is_mounted) {
        this.setState({
          filter_text: value,
          data: this.state.original_data
        });
      }
    }
  };

  on_advance_search = (params) => {
    Object.keys(params).forEach((key) => {
      params[key] = params[key] ? params[key].toString().trim() : params[key];
    });

    if (this.is_mounted) {
      this.setState(
        {
          re_render: false,
          look_up_params: params,
          show_advance_modal: false,
          filter_text: ''
        },
        () => {
          this.search_request(params);
        }
      );
    }
  };

  search_request = async (params) => {
    if (this.is_mounted) {
      this.setState({
        re_render: true
      });
    }

    params = {
      ...params,
      ['user_type']: params['user_type'] || this.state.user_type
    };

    await quick_advance_search(this.token, this.props.search_api, params)
      .then((response) => {
        if (response.data && response.data.data) {
          if (this.is_mounted) {
            this.setState({
              data: response.data.data,
              original_data: response.data.data,
              re_render: false
            });
          }
        }
      })
      .catch((error) => {
        if (this.is_mounted) {
          this.setState({
            data: this.props.data,
            original_data: this.props.data,
            re_render: false
          });
        }
        if (error.response) {
          error.response.map((item) => {
            if (item.error.response && item.error.response.data && item.error.response.data.messages) {
              log_error(item.error);
              const toastr_options = this.show_html_content_toaster(get_all_error(item.error.response.data));
              toaster.error('', toastr_options);
            }
            return;
          });
        }
      });
  };

  on_advance_Clear = (look_up_params) => {
    if (this.is_mounted) {
      this.setState({
        look_up_params: look_up_params
      });
    }
  };

  clear_search = () => {
    if (this.is_mounted) {
      this.setState({
        data: this.props.data,
        original_data: this.props.data,
        filter_text: '',
        look_up_params: {}
      });
    }
  };

  toggleDrawer = (event, item) => {
    const type = event.target.dataset.type;
    this.props.action_callback(type, this.props.advanced_control_name, item);
  };

  // Show multiple messages
  show_html_content_toaster = (msg: any) => {
    return {
      component: () => (
        <div>
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        </div>
      ),
      timeOut: toastr_options.toastr_time_out,
      preventDuplicates: true
    };
  };

  handle_keydown_on_cancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const list: any = document.getElementById(`${this.props.advanced_control_name}_list`);
    const first_child: any = list.firstChild;
    const list_items = list.children;
    for (let i = 0; i < list_items.length; i++) {
      list_items[i].className = '';
    }
    if (!e.shiftKey && e.keyCode == '9') {
      if (first_child.lastChild.childElementCount > 0) {
        first_child.className = 'focused';
        first_child.lastChild.firstChild.focus();
      } else {
        list.parentNode.nextSibling.firstChild.focus();
      }
    } else if (e.shiftKey && e.keyCode == '9') {
      e.target.previousSibling.children[0].focus();
    }
  };

  on_row_click = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const list: any = document.getElementById(`${this.props.advanced_control_name}_list`);
    const list_items = list.children;
    for (let i = 0; i < list_items.length; i++) {
      list_items[i].className = '';
    }
    if (e.target.tagName === 'LI') {
      e.target.className = 'focused';
    } else if (e.target.tagName === 'SPAN') {
      e.target.parentNode.className = 'focused';
    }
  };

  handle_icon_keydown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const list: any = document.getElementById(`${this.props.advanced_control_name}_list`);
    const main_input = document.getElementById(`${this.props.advanced_control_name}_closeIcon`);
    const activeElement: any = document.activeElement;
    const row = activeElement.parentNode.parentNode;
    const next_ele: any = row.nextSibling;
    const prev_ele: any = row.previousSibling;
    switch (e.keyCode) {
      // if the Tab key is pressed
      case 9:
        if (!e.shiftKey) {
          if (activeElement.nextSibling) {
            activeElement.nextSibling.focus();
          } else {
            if (next_ele) {
              row.className = '';
              this.ele_focus(next_ele, true);
            } else {
              list.parentNode.nextSibling.firstChild.focus();
            }
          }
        } else {
          if (activeElement.previousSibling) {
            activeElement.previousSibling.focus();
          } else {
            if (prev_ele) {
              row.className = '';
              this.ele_focus(prev_ele, true);
            } else {
              main_input.focus();
            }
          }
        }
        break;
      // if the UP key is pressed
      case 38:
        row.className = '';
        if (prev_ele) {
          this.ele_focus(prev_ele, true);
        } else {
          main_input.focus();
        }
        break;
      // if the Down key is pressed
      case 40:
        row.className = '';
        if (next_ele) {
          this.ele_focus(next_ele, true);
        } else {
          list.parentNode.nextSibling.firstChild.focus();
        }
        break;
    }
  };

  handle_add_button_keydown = (e) => {
    const list: any = document.getElementById(`${this.props.advanced_control_name}_list`);
    const last_child: any = list.lastChild;
    const main_input = document.getElementById(`${this.props.advanced_control_name}_closeIcon`);
    e.preventDefault();
    e.stopPropagation();
    if (e.shiftKey && e.keyCode == 9) {
      if (last_child.lastChild.childElementCount > 0) {
        last_child.className = 'focused';
        last_child.lastChild.lastChild.focus();
      } else {
        main_input.focus();
      }
    } else if (e.keyCode == '9') {
      const add_button = document.getElementById(`${this.props.advanced_control_name}_button`);
      if (add_button.id === 'permission_sets_button') {
        set_focus_to_app_header(e);
      } else {
        const edit_permission = document.getElementById('edit_permission');
        edit_permission.focus();
      }
    }
  };

  ele_focus = (ele, set_className = false) => {
    if (set_className) {
      ele.className = 'focused';
    }
    if (ele.getElementsByClassName('pencil')[0]) {
      ele.getElementsByClassName('pencil')[0].focus();
    } else {
      ele.getElementsByClassName('eye')[0].focus();
    }
  };

  render() {
    let parent_class = this.props.class_name + ' roles_search';
    return (
      <div
        className={parent_class}
        id={this.props.id ? this.props.id : ''}
        ref={(node) => {
          this.node = node;
        }}
      >
        <Dimmer active={this.state.loading}>
          <Loader size='massive'>Loading</Loader>
        </Dimmer>
        <div className='header'>
          <div className='field advance-quick-search'>
            <AdvancedControlModal
              id={this.props.advanced_control_name}
              controlId={this.props.controlId}
              search_type={this.props.search_type}
              onSearch={this.on_advance_search}
              options={this.props.options}
              default_options={this.props.default_options}
              form_data={this.state.look_up_params}
              onClear={this.on_advance_Clear}
            />
            <Input
              id={this.props.search_box_id ? this.props.search_box_id : ''}
              autoComplete='off'
              type='text'
              value={this.state.filter_text}
              onChange={(value) => this.on_search(value)}
              icon='search'
              placeholder={'Search'}
            />
            <button
              type='button'
              className='closeIcon'
              onClick={this.clear_search}
              onKeyDown={(e) => {
                handle_click_on_enter(e);
                this.handle_keydown_on_cancel(e);
              }}
              id={`${this.props.advanced_control_name}_closeIcon`}
            >
              <i aria-hidden='true' className='close icon close icon auto-search-icon'></i>
            </button>
          </div>
        </div>
        <div className='body'>
          <ul id={`${this.props.advanced_control_name}_list`}>
            {this.state.data.length > 0
              ? this.state.data.map((item) => {
                  return (
                    <li
                      key={item.lookup_id + '-' + item.id}
                      tabIndex={0}
                      onClick={this.on_row_click}
                    >
                      {item.description ? (
                        <Popup
                          trigger={<span className={'item-name'}>{item.name}</span>}
                          content={item.description}
                          on={'hover'}
                          wide={'very'}
                          flowing
                          hoverable
                          hideOnScroll={true}
                        />
                      ) : (
                        <span className={'item-name'}>{item.name}</span>
                      )}
                      <span className={'action_icons'}>
                        {this.props.is_add_edit_allowed ? (
                          <React.Fragment>
                            <Icon
                              aria-hidden='true'
                              name='pencil'
                              data-type={'edit'}
                              tabIndex={0}
                              onClick={(e) => this.toggleDrawer(e, item.id)}
                              onKeyDown={(e) => {
                                this.handle_icon_keydown(e);
                                handle_click_on_enter(e);
                              }}
                            />
                            <Icon
                              aria-hidden='true'
                              name='copy outline'
                              data-type={'copy'}
                              tabIndex={0}
                              onClick={(e) => this.toggleDrawer(e, item.id)}
                              onKeyDown={(e) => {
                                this.handle_icon_keydown(e);
                                handle_click_on_enter(e);
                              }}
                            />
                            <Icon
                              aria-hidden='true'
                              name='trash alternate outline'
                              data-type={'delete'}
                              tabIndex={0}
                              onClick={(e) => this.toggleDrawer(e, item.id)}
                              onKeyDown={(e) => {
                                this.handle_icon_keydown(e);
                                handle_click_on_enter(e);
                              }}
                            />
                          </React.Fragment>
                        ) : (
                          <Icon
                            aria-hidden='true'
                            name='eye'
                            data-type={'view'}
                            tabIndex={0}
                            onClick={(e) => this.toggleDrawer(e, item.id)}
                            onKeyDown={(e) => {
                              this.handle_icon_keydown(e);
                              handle_click_on_enter(e);
                            }}
                          />
                        )}
                      </span>
                    </li>
                  );
                })
              : (this.state.filter_text ||
                  (Object.keys(this.state.look_up_params).length > 0 &&
                    this.state.look_up_params.constructor === Object)) && (
                  <li style={{ justifyContent: 'center' }}>
                    <span>{url_rewrite.error_message}</span>
                  </li>
                )}
          </ul>
        </div>
        {this.props.is_add_edit_allowed && (
          <div className='footer'>
            <Button
              type='button'
              className='reset'
              data-type={'new'}
              onClick={(e) => this.toggleDrawer(e, {})}
              onKeyDown={(e) => {
                this.handle_add_button_keydown(e);
                handle_click_on_enter(e);
              }}
              id={`${this.props.advanced_control_name}_button`}
            >
              <Icon aria-hidden='true' name='add circle' />
              {this.props.add_label}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_login_details: state.user_login_details
  };
};

export default connect(mapStateToProps)(SearchComponent);
