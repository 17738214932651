import { details_tamplate, status_cell_renderer } from '../patient/util/case_utility';
import { mm_dd_yyyy_comparator, mm_dd_yyyy_h_mm_ss_tt_comparator, number_comparator, text_comparator } from '../shared/component/grid/comparators';
import { get_header_template_with_navigation } from '../shared/component/grid/constants';
import { handle_click_on_enter } from '../shared/tab_navigation_utility';
import { custom_date_format } from '../shared/utility';
import * as global_constants from '../global_constants';

export const GET_PATIENTS = 'PATIENTS:GET_PATIENTS';
export const GET_PATIENT_DETAILS = 'PATIENT:GET_PATIENT';
export const GET_PATIENT_MEDICARE_CAP = 'PATIENT:GET_PATIENT_MEDICARE_CAP';
export const ADD_UPDATE_PATIENT_MEDICARE_CAP = 'PATIENT:ADD_UPDATE_PATIENT_MEDICARE_CAP';
export const PATIENT_HEADER = 'PATIENT:PATIENT_HEADER';
export const PATIENT_HEADER_CASE = 'PATIENT:HEADER_CASE';
export const DOCTOR_ORDER = 'PATIENT:DOCTOR_ORDER';
export const PATIENT_COLLECTION_FLAG = 'PATIENT:COLLECTION_FLAG'

// Patient Account Notes
export const REMOVE_PATIENT_ACCOUNT_NOTES = 'PATIENT:REMOVE_ACCOUNT_NOTES';
export const ADD_PATIENT_ACCOUNT_NOTES = 'PATIENT:ADD_ACCOUNT_NOTES';
export const GET_PATIENT_TASK_AND_ACCOUNT_NOTES = 'PATIENT:GET_TASKAND_ACCOUNT_NOTES';
export const GET_PATIENT_ACCOUNT_NOTES_CODE = 'PATIENT:GET_ACCOUNT_NOTES_CODE';
export const GET_PATIENT_ACCOUNT_NOTES = 'PATIENT:GET_ACCOUNT_NOTES';
export const GET_PATIENT_ACCOUNT_TASKS = 'PATIENT:GET_ACCOUNT_TASKS';

//Medical records
export const GET_PATIENT_GET_MEDICAL_OUTPUT_REPORTS = 'PATIENT:GET_MEDICAL_OUTPUT_REPORTS';
export const GET_PATIENT_GET_MEDICAL_SCANNED_DOCUMENTS = 'PATIENT:GET_MEDICAL_SCANNED_DOCUMENTS';
export const GET_PATIENT_CASES = 'PATIENT:GET_PATIENT_CASES';
export const UPDATE_PATIENT_HEADER_INFO = 'USER:UPDATE_PATIENT_HEADER_INFO';

//Patient Search
export const PATIENT_SEARCH_DATA = 'PATIENT:SEARCH_DATA';

//Patient account note added status
export const GET_PATIENT_ACCOUNT_NOTES_STATUS = 'GET_PATIENT_ACCOUNT_NOTES_STATUS';

export const GET_PRINT_PATIENT_FORM_DATA = 'PATIENT:GET_PRINT_FORM_DATA_LIST';
export const GET_PATIENT_ACCOUNT_TASK_NOTES = 'PATIENT:GET_ACCOUNT_TASK_NOTES';

export const PAGESIZE = 5000;
export const PAGEINDEX = 0;

export enum enum_primary_phone {
    home = "1",
    work = "3",
    cell="2"
}

// Status Patient Advance Search
var status = (params) => {
    if (params === true) {
        return 'Active'
    } else {
        return 'Inactive'
    }
}

const statusStyle = {
    'color': 'black',
    'background-color': '#dce1e7',
    'text-align': 'center'
};
const headerTemplate = {
    template: '<div class="ag-cell-label-container" role="presentation">' +
        '<i class="small filter icon filter-ipad filter-active-icon"></i>' +
        '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '<div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '</div>' +
        '</div>'
};
const headerNoFilterIconTemplate = {
    template: '<div class="ag-cell-label-container" role="presentation">' +
        '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '<div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '</div>' +
        '</div>'
};
const headerStatusTemplate = {
    template: '<div class="ag-cell-label-container column-center" role="presentation">' +
        '<i class="small filter icon filter-ipad filter-active-icon"></i>' +
        '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '<div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '</div>' +
        '</div>'
};
const finance_headerTemplate = {
    template: '<div class="ag-cell-label-container account-summary-header"   role="presentation">' +
        '<i class="small filter icon filter-ipad filter-active-icon"></i>' +
        '<div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '</div>' +
        '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '</div>'
};

export const amount_headerTemplate = {
    template: '<div class="ag-cell-label-container amt-text-right" role="presentation">' +
        '<div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '<i class="small filter icon filter-ipad-amount filter-active-icon"></i>' +
        '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '</div>' +
        '</div>'
};

const header_template_align_right = {
    template: '<div class="ag-cell-label-container ag-cell-align_right" role="presentation">' +
        '<i class="small filter icon filter-ipad filter-active-icon"></i>' +
        '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '<div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '</div>' +
        '</div>'
};

export const patient_search_id = "patient_search_id";

// Patient Search Configuration.
export const emr_patient_id_col_name = 'EMR Patient ID';
export const search_result_row_height_const = 55; // patient advance search grid top portion (search result + no. of record + Prev/Next button)
export const search_patient_configuration = (id_for_navigation) => {
    return {
        title: "Patient Search",
        pagination_size: 20,
        grid_header_height: 60,
        control_defs: [{
            input: [{ label: "Company Name" }, { label: "Company Code" }],
            button: [{ name: "Search" }, { name: "Clear" }, { name: "Close" }]
        }],
        column_defs: [
            {
                headerName: "First Name",
                headerComponentParams: get_header_template_with_navigation(id_for_navigation),
                field: "first_name",
                filter: "textFilter",
                unSortIcon: true,
                comparator: text_comparator
            },
            {
                headerName: "MI",
                headerComponentParams: headerTemplate,
                field: "middle_initial",
                filter: "textFilter",
                unSortIcon: true,
                comparator: text_comparator
            },
            {
                headerName: "Last Name",
                headerComponentParams: headerTemplate,
                field: "last_name",
                filter: "textFilter",
                unSortIcon: true,
                comparator: text_comparator
            },
            {
                headerName: "Date of Birth",
                headerComponentParams: headerTemplate,
                field: "date_of_birth",
                filter: "dateFilter",
                unSortIcon: true,
                comparator: mm_dd_yyyy_comparator
            },
            {
                headerName: "Patient ID",
                headerComponentParams: headerTemplate,
                field: "id",
                filter: "numberFilter",
                comparator: number_comparator,
                unSortIcon: true,
            },
            {
                headerName: "EMR Patient ID",
                headerComponentParams: headerTemplate,
                field: "alt_record_id2",
                filter: "textFilter",
                unSortIcon: true,
                comparator: text_comparator
            },
            {
                headerName: "Alternate Patient ID",
                headerComponentParams: headerTemplate,
                field: "alt_record_id",
                filter: "textFilter",
                unSortIcon: true,
                comparator: text_comparator
            },
            {
                headerName: "SSN",
                headerComponentParams: headerTemplate,
                field: "ssn",
                filter: "textFilter",
                suppressSizeToFit: true,
                unSortIcon: true,
                comparator: text_comparator
            },
            {
                headerName: "Address Line 1",
                headerComponentParams: headerTemplate,
                field: "address1",
                filter: "textFilter",
                unSortIcon: true,
                comparator: text_comparator
            },
            {
                headerName: "City",
                headerComponentParams: headerTemplate,
                field: "city",
                filter: "textFilter",
                unSortIcon: true,
                comparator: text_comparator
            },
            {
                headerName: "State",
                headerComponentParams: headerTemplate,
                field: "state_id",
                filter: "textFilter",
                unSortIcon: true,
                comparator: text_comparator
            },
            {
                headerName: "Zip Code",
                headerComponentParams: headerTemplate,
                field: "zip",
                filter: "textFilter",
                unSortIcon: true,
                comparator: text_comparator
            },
            {
                headerName: "Status",
                headerComponentParams: headerStatusTemplate,
                field: "is_active",
                filter: "checklistFilter",
                cellStyle: statusStyle,
                unSortIcon: true,
            }
        ]
    }
};

export const account_notes_patient_view_id = "account_notes_patient_view";
export const account_notes_patient_header_view_id = "account_notes_patient_header_view_id";

export const account_notes_configuration = {
    column_defs: [
    {
        headerName: "",
        headerComponentParams: headerTemplate,
        field: "id",
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 32,
        maxWidth: 32,
        suppressSorting: true,
        suppressFilter: true,
        suppressSizeToFit: true,
        valueGetter: function chainValueGetter(params) {
            return "";
        }
    },
    {
        headerName: "Note",
        headerComponentParams: headerTemplate,
        // headerComponentParams: get_header_template_with_navigation(id_for_navigation),
        field: "note_text",
        filter: "textFilter",
        minWidth: 350,
        unSortIcon: true,
        cellClass: "cell-wrap-text",
        comparator: text_comparator
    },
    {
        headerName: "Type",
        headerComponentParams: headerTemplate,
        field: "note_code",
        filter: "textFilter",
        unSortIcon: true,
        minWidth: 140,
        comparator: text_comparator
    },
    {
        headerName: "Date",
        headerComponentParams: headerTemplate,
        field: "created_date",
        filter: "dateFilter",
        unSortIcon: true,
        minWidth: 120,
        comparator: mm_dd_yyyy_comparator
    },
    //Task
    {
        headerName: "Due Date",
        headerComponentParams: headerTemplate,
        field: "due_date",
        filter: "dateFilter",
        unSortIcon: true,
        minWidth: 150,
        comparator: mm_dd_yyyy_comparator
    },
    {
        headerName: "Created by",
        headerComponentParams: headerTemplate,
        field: "created_by",
        filter: "textFilter",
        unSortIcon: true,
        minWidth: 250,
        comparator: text_comparator
    },
    //Task
    {
        headerName: "Assignee",
        headerComponentParams: headerTemplate,
        field: "assignee",
        filter: "textFilter",
        unSortIcon: true,
        minWidth: 250,
        comparator: text_comparator
    },
    //Task
    {
        headerName: "Status",
        headerComponentParams: headerTemplate,
        field: "task_status",
        filter: "textFilter",
        unSortIcon: true,
        minWidth: 150,
        comparator: text_comparator
    },
    {
        headerName: "Stuck on",
        headerComponentParams: headerTemplate,
        field: "stick_on",
        filter: "textFilter",
        unSortIcon: true,
        minWidth: 150,
        comparator: text_comparator
    }
    ]
};

export const sticky_code = {
    Case: 0,
    Charge: 1,
    Patient: 2,
    Payment: 3
};

export const sticky_code_list = [
    { key: 1, text: "-- Not A Sticky --", value: "-- Not A Sticky --" },
    { key: 3, text: "Charge", value: "Charge" },
    { key: 4, text: "Payment", value: "Payment" }
]

export const patient_messages = {
    added_successfully: 'Patient added successfully.',
    updated_successfully: 'Patient details updated successfully.',
    noteAdded: 'Note added successfully.',
    noteRemoved: 'Sticky removed from the note(s).',
    no_records: 'There are no notes for this patient.',
    note_already_removed: 'No sticky to be removed',
    no_records_selected: 'Please select a note',
    required_fields: "You must complete all required fields.",
    special_characters_validation: "Special characters ( ~ ! @ # $ % ^ & * ( ) _ + ` = { } | [ ] \ : \" ; < > ? , . /) are not allowed in First Name or Last Name. You can include a hyphen (-) or apostrophe (') in the First Name or Last Name.",
    date_of_birth_validation: "Patient's Date of Birth cannot be future date.",
    duplicate_speciality: "There cannot be duplicate specialties in a year for patient cap amount.",
    date_of_birth_responsibility_validation: "Patient's Responsible Party Date of Birth cannot be future date.",
    note_removed: 'Sticky removed from the note(s).',
    note_added: 'Note added successfully.',
    select_record: "Please select a record",
    no_image_found: 'No Image Found',
    select_finalized_reports: 'Please select finalized output reports to be printed.',
    medicare_checkbox_select: 'Please select a Medicare Cap',
    finalized_report_only_print: 'Finalized output report(s) can only be printed.',
    output_report_only_print: 'output report(s) can only be printed.',
    report_not_found: 'There is an error while fetching the document, please try again.',
    select_scanned_doc: 'Please select a scanned document first for print.',
    pdf_generation_error: "PDF generation error.",
    case_clone_chk_not_selected: 'Please select a case to clone.',
    patient_select_letter_validation: 'You must select a letter to continue.',
    patient_letter_preview_alert: 'Will this letter be provided to the patient?',
    patient_switch_error: 'The patient account could not be opened. Try again.',
    invalid_remove_id: 'Please enter valid Patient Id to Remove.',
    invalid_keep_id: 'Please enter valid Patient Id to Keep.',
    duplicate_remove_keep_id: 'Patient ID to Remove and Patient ID to Keep cannot be same.'
}

export const medical_reord_output_reports_grid_header_id = "medical_reord_output_reports_grid_header_id";
export const output_reports_configuration = {
    column_defs: [
        {
            headerName: "Name",
            headerComponentParams: get_header_template_with_navigation(medical_reord_output_reports_grid_header_id),
            field: "group",
            cellRenderer: "agGroupCellRenderer",
            filter: "groupTextFilter",
            checkboxSelection: true,
            minWidth: 400,
            autoHeight: true,
            unSortIcon: true,
            cellClass: "cell-wrap-text",
            comparator: text_comparator
        },
        {
            headerName: "Note",
            field: "note_info.visit_type",
            suppressFilter: true,
            minWidth: 200,
            autoHeight: true,
            unSortIcon: true,
            cellClass: "cell-wrap-text",
            comparator: text_comparator
        },
        {
            headerName: "DOS",
            field: "note_info.date_of_service",
            suppressFilter: true,
            minWidth: 120,
            unSortIcon: true,
            suppressSizeToFit: true,
            comparator: mm_dd_yyyy_comparator
        },
        {
            headerName: "Type",
            field: "output_report_type",
            suppressFilter: true,
            minWidth: 200,
            autoHeight: true,
            unSortIcon: true,
            cellClass: "cell-wrap-text",
            comparator: text_comparator
        },
        {
            headerName: "Created Date",
            field: "created_date",
            suppressFilter: true,
            minWidth: 130,
            unSortIcon: true,
            suppressSizeToFit: true,
            comparator: mm_dd_yyyy_comparator
        },
        {
            headerName: "Status",
            field: "status",
            suppressFilter: true,
            unSortIcon: true,
            minWidth: 140
        }
    ]
}

export const medical_record_scanned_documents_grid_header_id = "medical_record_scanned_documents_grid_header_id";

export const scanned_documents_configuration = {
    column_defs: [
        {
            headerName: "",
            field: "id",
            width: 50,
            suppressSorting: true,
            suppressFilter: true,
            suppressSizeToFit: true,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            valueGetter: function chainValueGetter(params) {
                return '';
            }
        },
        {
            headerName: "Date Created",
            headerComponentParams: headerTemplate,
            field: "created_date",
            filter: "dateTimeFilter",
            minWidth: 190,
            unSortIcon: true,
            sort: "desc",
            comparator: mm_dd_yyyy_h_mm_ss_tt_comparator
        },
        {
            headerName: "Document Name",
            headerComponentParams: headerTemplate,
            field: "document_name_display",
            filter: "textFilter",
            minWidth: 320,
            autoHeight: true,
            unSortIcon: true,
            cellClass: "cell-wrap-text",
            comparator: text_comparator
        },
        {
            headerName: "Image Type",
            headerComponentParams: headerTemplate,
            field: "image_type",
            filter: "textFilter",
            minWidth: 170,
            autoHeight: true,
            unSortIcon: true,
            cellClass: "cell-wrap-text",
            comparator: text_comparator
        },
        {
            headerName: "Category Type",
            headerComponentParams: headerTemplate,
            field: "category_type",
            filter: "textFilter",
            minWidth: 190,
            autoHeight: true,
            unSortIcon: true,
            cellClass: "cell-wrap-text",
            comparator: text_comparator
        },
        {
            headerName: "Document Date",
            headerComponentParams: headerTemplate,
            field: "document_date_display",
            filter: "dateFilter",
            unSortIcon: true,
            minWidth: 220,
            comparator: mm_dd_yyyy_comparator
        },
        {
            headerName: "Case ID",
            headerComponentParams: headerTemplate,
            field: "case_info.id",
            filter: "textFilter",
            unSortIcon: true,
            minWidth: 190
        },
        {
            headerName: "Case",
            headerComponentParams: headerTemplate,
            field: "case_display",
            filter: "textFilter",
            minWidth: 160,
            autoHeight: true,
            unSortIcon: true,
            cellClass: "cell-wrap-text",
            comparator: text_comparator
        }
    ]
}
export const financial_record_configuration = {
    column_defs: [
        {
            headerName: "",
            headerComponentParams: headerTemplate,
            field: "name",
            filter: "textFilter",
            minWidth: 130,
            unSortIcon: true,
            comparator: text_comparator

        },
        {
            headerName: "Charge Amount",
            headerComponentParams: header_template_align_right,
            field: "charge_amount",
            filter: "textFilter",
            minWidth: 200,
            unSortIcon: true,
            cellClass: "cell-finance-text-align",
            comparator: text_comparator
        },
        {
            headerName: "Payment Amount",
            headerComponentParams: header_template_align_right,
            field: "payment_amount",
            filter: "textFilter",
            minWidth: 210,
            unSortIcon: true,
            cellClass: "cell-finance-text-align",
            comparator: text_comparator
        },
        {
            headerName: "Adjustment Amount",
            headerComponentParams: header_template_align_right,
            field: "adjustment_amount",
            filter: "textFilter",
            minWidth: 260,
            unSortIcon: true,
            cellClass: "cell-finance-text-align",
            comparator: text_comparator
        },
        {
            headerName: "Balance",
            headerComponentParams: header_template_align_right,
            field: "balance",
            filter: "textFilter",
            minWidth: 170,
            unSortIcon: true,
            cellClass: "cell-finance-text-align",
            comparator: text_comparator
        }
    ]
}
export const duplicate_patient_configuration = {
    column_defs: [

        {
            headerName: "Name",
            headerComponentParams: headerTemplate,
            field: "name",
            filter: "textFilter",
            unSortIcon: true,
            suppressFilter: true,
            comparator: text_comparator

        },
        {
            headerName: "Phone",
            headerComponentParams: headerTemplate,
            field: "phone",
            filter: "textFilter",
            unSortIcon: true,
            suppressFilter: true,
            comparator: text_comparator

        },
        {
            headerName: "Address",
            headerComponentParams: headerTemplate,
            field: "address",
            filter: "textFilter",
            unSortIcon: true,
            suppressFilter: true,
            comparator: text_comparator

        },

    ]
}


export const print_patient_forms_configuration = {

    grid_header_height: 35,
    column_defs: [

        {
            headerName: "",
            headerComponentParams: headerNoFilterIconTemplate,
            field: "image_packet_id",
            width: 35,
            suppressFilter: true,
            suppressSizeToFit: true,
            valueGetter: function chainValueGetter(params) {
                return '';
            },
            cellRenderer: function (params) {
                var eDiv = document.createElement('div');
                eDiv.innerHTML = '<span><input type="radio" class="radio-id" style="padding: 15px 10p 15px 10px;" name="radio_btn"/></span>';
                var eRadio = eDiv.querySelector('.radio-id');
                eRadio.addEventListener("keydown", handle_click_on_enter);
                eRadio.addEventListener('click', function () {
                    params.node.setSelected(true)
                });
                return eDiv;
            }
        },

        {
            headerName: "Date Created",
            headerComponentParams: headerTemplate,
            field: "created_date",
            filter: "dateTimeFilter",
            minWidth: 200,
            unSortIcon: true,
            comparator: mm_dd_yyyy_h_mm_ss_tt_comparator
        },
        {
            headerName: "Documented Name",
            headerComponentParams: headerTemplate,
            field: "image_name",
            filter: "textFilter",
            unSortIcon: true,
            minWidth: 250,
            comparator: text_comparator
        },
        {
            headerName: "Place Of Service",
            headerComponentParams: headerTemplate,
            field: "location_name",
            filter: "textFilter",
            unSortIcon: true,
            minWidth: 300,
            comparator: text_comparator
        },
        {
            headerName: "Practice",
            headerComponentParams: headerTemplate,
            field: "practice_name",
            filter: "textFilter",
            unSortIcon: true,
            minWidth: 300,
            comparator: text_comparator
        }
    ]
}

export const patient_cases_configuration_grid_header_id = "patient_cases_configuration_grid_header_id";
export const patient_cases_configuration = {
    grid_header_height: 35,
    column_defs: [
        {
            headerName: '',
            field: "group",
            cellRenderer: "agGroupCellRenderer",
            width: 70,
            suppressSorting: true,
            suppressFilter: true,
            suppressSizeToFit: true,
            valueGetter: function chainValueGetter(params) {
                return '';
            },
            cellRendererParams: {
                innerRenderer: function (params) {
                    if (params.node.group) {
                        var eDiv = document.createElement('div');
                        eDiv.innerHTML = '<span style="cursor: default;" class="case-row-radio-span"><input type="radio" class="radio-id" style="padding: 10px 10px 10px 10px;" name="radio_btn"></span>';
                        var eRadio = eDiv.querySelector('.radio-id');
                        eRadio.addEventListener('click', function () {
                            params.node.setSelected(true)
                        });
                        return eDiv.firstChild;
                    } else {
                        var eDiv = document.createElement('div');
                        eDiv.innerHTML = details_tamplate(params.data);
                        return eDiv.firstChild;
                    }
                }
            },
            colSpan: function (params) {
                if (!params.node.group) {
                    return 15;
                }
                return 1;
            }
        },
        {
            headerName: "Case",
            children: [
                {
                    headerName: "ID",
                    headerComponentParams: get_header_template_with_navigation(patient_cases_configuration_grid_header_id),
                    field: "id",
                    filter: "numberFilter",
                    comparator: number_comparator,
                    width: 100,
                    suppressSizeToFit: true,
                    unSortIcon: true
                },
                {
                    headerName: "Name",
                    headerComponentParams: headerTemplate,
                    field: "description",
                    filter: "textFilter",
                    unSortIcon: true,
                    minWidth: 220,
                    cellClass: "cell-wrap-text",
                    comparator: text_comparator
                }
            ]
        },
        {
            headerName: "Insurance",
            headerClass: 'col-group-gray',
            children: [
                {
                    headerName: "Primary",
                    headerComponentParams: headerTemplate,
                    headerClass: 'col-gray',
                    field: "primary_text",
                    filter: "textFilter",
                    unSortIcon: true,
                    minWidth: 220,
                    cellClass: "cell-wrap-text",
                    comparator: text_comparator
                },
                {
                    headerName: "Secondary",
                    headerComponentParams: headerTemplate,
                    headerClass: 'col-gray',
                    field: "secondary_text",
                    filter: "textFilter",
                    unSortIcon: true,
                    minWidth: 220,
                    cellClass: "cell-wrap-text",
                    comparator: text_comparator
                },
                {
                    headerName: "Tertiary",
                    headerComponentParams: headerTemplate,
                    headerClass: 'col-gray',
                    field: "tertiary_text",
                    filter: "textFilter",
                    unSortIcon: true,
                    minWidth: 220,
                    cellClass: "cell-wrap-text",
                    comparator: text_comparator
                }
            ]
        },
        {
            headerName: "Visits With Charges",
            children: [
                {
                    headerName: "Count",
                    headerComponentParams: headerTemplate,
                    field: "charge_visit_count",
                    filter: "numberFilter",
                    comparator: number_comparator,
                    cellStyle: { textAlign: 'center' },
                    minWidth: 120,
                    unSortIcon: true
                },
                {
                    headerName: "DOS From",
                    headerComponentParams: headerTemplate,
                    field: "date_of_service_from",
                    filter: "dateFilter",
                    unSortIcon: true,
                    minWidth: 150,
                    comparator: mm_dd_yyyy_comparator
                },
                {
                    headerName: "DOS To",
                    headerComponentParams: headerTemplate,
                    field: "date_of_service_to",
                    filter: "dateFilter",
                    unSortIcon: true,
                    minWidth: 130,
                    comparator: mm_dd_yyyy_comparator
                }
            ]
        },
        {
            headerName: "Dates",
            headerClass: 'col-group-gray',
            children: [
                {
                    headerName: "Injury/Onset",
                    headerComponentParams: headerTemplate,
                    headerClass: 'col-gray',
                    field: "injury_date",
                    filter: "dateFilter",
                    minWidth: 160,
                    unSortIcon: true,
                    comparator: mm_dd_yyyy_comparator
                },
                {
                    headerName: "Discharge",
                    headerComponentParams: headerTemplate,
                    headerClass: 'col-gray',
                    field: "discharge_date",
                    filter: "dateFilter",
                    unSortIcon: true,
                    minWidth: 150,
                    comparator: mm_dd_yyyy_comparator
                },
                {
                    headerName: "Case Effective",
                    headerComponentParams: headerTemplate,
                    headerClass: 'col-gray',
                    field: "effective_date",
                    filter: "dateFilter",
                    unSortIcon: true,
                    minWidth: 180,
                    comparator: mm_dd_yyyy_comparator
                },
                {
                    headerName: "Effective End",
                    headerComponentParams: headerTemplate,
                    headerClass: 'col-gray',
                    field: "effective_end_date",
                    filter: "dateFilter",
                    unSortIcon: true,
                    minWidth: 170,
                    comparator: mm_dd_yyyy_comparator
                }
            ]
        },
        {
            headerName: "Status",
            cellStyle: { textAlign: "center" },
            children: [
                {
                    headerName: "Incomplete",
                    headerComponentParams: headerTemplate,
                    field: "is_incomplete_rf3",
                    filter: "checklistFilter",
                    cellStyle: { textAlign: 'center' },
                    minWidth: 150,
                    unSortIcon: true,
                    cellRenderer: params => status_cell_renderer(params)
                },
                {
                    headerName: "Active",
                    headerComponentParams: headerTemplate,
                    field: "is_active",
                    filter: "checklistFilter",
                    cellStyle: { textAlign: 'center' },
                    unSortIcon: true,
                    minWidth: 120,
                    cellRenderer: params => status_cell_renderer(params)
                }
            ]
        }
    ]
}

export const addPatientInitialConfiguration = {
    nameMinLength: 2,
    nameMaxLength: 50,
    startsWithLetterRegexp: /^[a-zA-Z]/,
    alphanumericRegexp: /^[a-zA-Z0-9'.", -]+$/
};

export const patientInvalidFieldMessage = {
    invalidMinimumCharacters: `Must have ${addPatientInitialConfiguration.nameMinLength} characters`,
    invalidFirstCharacter: "First character must be a letter",
    invalidAlphaNumericField: "Invalid characters",
    invalidMaxLengthField: `Exceeds ${addPatientInitialConfiguration.nameMaxLength} character limit`
};

export const account_task_comments_configuration = {
    column_defs: [
        {
            headerName: "Comment",
            headerComponentParams: headerTemplate,
            field: "comment_text",
            filter: "textFilter",
            minWidth: 350,
            unSortIcon: false,
            cellClass: "cellWrapAutoHeight",
            comparator: text_comparator
        }, 
        {
            headerName: "Created By",
            headerComponentParams: headerTemplate,
            field: "created_by",
            filter: "textFilter",
            unSortIcon: true,
            minWidth: 250,
            cellClass: "cellWrapAutoHeight",
            comparator: text_comparator
        },
        {
            headerName: "Created Date",
            headerComponentParams: headerTemplate,
            type: 'dateTime',
            field: "created_date",
            filter: "dateFilter",
            unSortIcon: false,
            minWidth: 150,
            comparator: mm_dd_yyyy_comparator,
            cellRenderer: params => {
                return custom_date_format(params.value, global_constants.date_format["mm/dd/yyyy"])
            }
        }
    ]
};

export const defaultRowHeight = 28;
