import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get_r2_url } from '../action/shared_action';
import { Grid, Dimmer, Loader } from "semantic-ui-react";

 /* 
  * Basic Layout for all R2 Embedded Pages 
  * The page name and base url for r2 pages is persisted(stored in local storage) to avoid issues on refresh/F5 
  */ 
class R2EmbeddedPageComponent extends React.Component<any, any> {
    [x: string]: any;

    constructor(props) {
        super(props);
        this.state = {
            is_loading: true
        };
    }

   UNSAFE_componentWillMount() {
        document.body.classList.add("admin-framework");
        this.is_mounted = true;        
     // Get R2 page Url for the embedded page    
      this.get_R2_url()
       
    }

    componentDidUpdate(previousProps, previousState) {
        // For opening the Existing or New R2 page
        if (this.props.persisted_details.r2_page_data.r2_base_url != previousProps.persisted_details.r2_page_data.r2_base_url) {
            this.get_R2_url()
        }
    }

    componentWillUnmount = () => {
        document.body.classList.remove("admin-framework");
    };

    // To Get Correct Individual URL of R2 page
    get_R2_url = async () => {
        if (this.is_mounted) {
            this.setState({
                is_loading: true
            });
        }
        if (this.props.persisted_details) {
            await this.props.get_r2_url(this.props.persisted_details.r2_page_data.r2_base_url, this.props.user_login_details.user_data.data.company_id, this.props.user_login_details.user_data.data.token_details.access_token, this.props.user_login_details.user_data.data.token_details.token_guid)
            .then((response) => {
                if (this.is_mounted) {
                    this.setState({
                        is_loading: false
                    });
        }
            });
    }
    }
   
    render() {
        return (
            <React.Fragment>
                <Dimmer active={this.state.is_loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <div className={"admin-wrapper "} style={{paddingBottom:15}}>
                    <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                        <Grid.Column computer={16}>
                            <h3
                                className='ui header left aligned'
                                dangerouslySetInnerHTML={{
                                    __html: this.props.persisted_details.r2_page_data.page_name
                                }}
                            />
                        </Grid.Column>
                    </Grid>
                    {this.props.shared_details.user_r2_url && !this.state.is_loading &&(
                        <iframe
                            id='r2_embedded_page'
                            src={this.props.shared_details.user_r2_url.data}
                            width='100%'
                            className='iframe-border'
                            style={{ border: "1px solid #a1aebb", borderTop: "2px solid #a1aebb", flex: "1 1 0%" }}
                        />
                    )}
                </div>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        get_r2_url: get_r2_url 
    }, dispatch)
}


const mapStateToProps = (state) => {

    return {
        user_login_details: state.user_login_details,
        shared_details: state.shared_details,
        persisted_details: state.persisted_details
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(R2EmbeddedPageComponent)