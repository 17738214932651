import * as React from 'react';
import { Input } from 'semantic-ui-react';
import { disable_constant_count_multi_select_draggable } from '../../global_constants';
import { parse_JSON } from './grid/utils';
import { Icon as BlueprintIcon } from '@blueprintjs/core';
import { Icon as SemanticIcon } from 'semantic-ui-react';
import { Manager, Reference, Popper } from 'react-popper';

interface IProps {
  data: any[]; // data list
  show_filter?: boolean; // display search filter or not
  id?: string; // id of the component
  class_name?: string; // any additional class if applied
  selected_list: any[]; // if default list is provided
  onChangeSelected: Function;
  key_id?: string;
  search_box_id?: string; // id of search box
  has_validation?: boolean; // in case of any validation
  forceOnChangeSelected?: boolean; // will force onChangeSelected method to triggered on item change.
  is_lazy_loading?: boolean; // check if lazy loading is allowed
  page_size_for_lazy_load?: number; // page size for lazy loading
  allow_disable?: boolean; // disable selection after a particular number of selection
  initial_request?: Function;
  no_record?: string;
  type?: string; // UI filed type
  pageSize?: number; // page size to load more data on scroll list from API
  getData?: Function;
  formatMultiSelect?: Function;
  is_disabled?: boolean;
  tags_per_row?: number;
}

export class MultiSelectDraggableDropDownComponent extends React.PureComponent<IProps, any> {
  node: any;
  static defaultProps: Partial<IProps> = {
    data: [],
    show_filter: true,
    id: 'multi-select-dropdown',
    class_name: '',
    selected_list: [],
    key_id: '',
    search_box_id: 'multi-select-dropdown-search',
    has_validation: false,
    forceOnChangeSelected: false,
    is_lazy_loading: false,
    page_size_for_lazy_load: 20,
    is_disabled: false,
    allow_disable: false,
    tags_per_row: 2
  };
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      selected_values: this.props.selected_list,
      filter_text: '',
      show: false,
      dragOver: '',
      typingTimeout: 0,
      display_reset: this.props.selected_list && this.props.selected_list.length > 0 ? true : false,
      lazy_loaded_data: this.props.is_lazy_loading
        ? this.props.data.slice(0, this.props.page_size_for_lazy_load) || []
        : [],
      no_record_msg: this.props.no_record || " "
    };
    this.page_index = 2;
    this.page_size = this.props.pageSize;
    this.total_size = this.props.data ? this.props.data.length : 0;
  }

  is_mounted = false;
  page_index = 0;
  page_size = 0;
  total_size = 0;
  loading = false;
  is_list_end = false;
  usable_percentage_for_tags : number= 92;

  componentDidMount = () => {
    this.is_mounted = true;
  };

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      let lazy_loaded_data = [];
      if (this.props.is_lazy_loading) {
        const props_data = JSON.stringify(this.props.data);
        lazy_loaded_data = JSON.parse(props_data).slice(0, this.props.page_size_for_lazy_load) || [];
      }
      if (this.is_mounted) {
        this.setState({
          data: this.props.data,
          lazy_loaded_data,
          selected_values: this.props.selected_list
        });
      }
    }

    if (JSON.stringify(prevProps.selected_list) !== JSON.stringify(this.props.selected_list)) {
      if (this.is_mounted) {
        this.setState({
          selected_values: this.props.selected_list
        });
      }
    }
  }

  componentWillUnmount = () => {
    this.is_mounted = false;
    if (this.props.is_lazy_loading && this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
  };

  fetch_data = async (keyword = '') => {
    this.loading = true;
    await this.props.getData(this.page_index, this.page_size, keyword).then(
      (res) => {
        if (res.data && res.data.data && res.data.data.result && res.data.data.result.length > 0) {
          if (this.is_mounted) {
            this.page_index = res.data.data.pageIndex + 1;
            this.page_size = res.data.data.pageSize;
            let data = res.data.data.result || [];
            if (this.props.formatMultiSelect) {
              data = this.props.formatMultiSelect(res.data.data.result || [], this.props.type);
            }

            const data_slot = data.slice(0, this.props.page_size_for_lazy_load);
            this.setState((prevState) => ({
              data: res.data.data.pageIndex > 1 ? [...prevState.data, ...data] : data,
              lazy_loaded_data:
                res.data.data.pageIndex > 1 ? [...this.state.lazy_loaded_data, ...data_slot] : [...data_slot]
            }));
          }
          this.loading = false;
          if (res.data.data.result.length < this.page_size) {
            this.is_list_end = true;
          }
        } else {
          this.loading = false;
          this.is_list_end = true;
          this.setState({ no_record_msg: "No Record Found !"})
        }
      },
      (error) => {
        this.loading = false;
        this.is_list_end = true;
        this.setState({ no_record_msg: "No Record Found !"})
      }
    );
  };

  load_more_data = (event) => {
    const container = event.currentTarget;
    if (container.scrollTop + container.offsetHeight >= container.scrollHeight-100) {
      if (
        this.state.data &&
        this.state.data.length > 0 &&
        this.state.lazy_loaded_data.length !== this.state.data.length
      ) {
        let data = [...this.state.lazy_loaded_data];
        const props_data = JSON.stringify(this.state.data);
        const updated_data =
          JSON.parse(props_data).slice(data.length, data.length + this.props.page_size_for_lazy_load) || [];
        data = data.concat(updated_data);

        data = data.filter((item) =>
          item.name.toString().toLowerCase().includes(this.state.filter_text.toString().toLowerCase())
        );
        if (this.is_mounted) {
          this.setState({
            lazy_loaded_data: data
          });
        }
      } else if (this.props.getData && !this.loading && !this.is_list_end) {
        this.fetch_data(this.state.filter_text);
      }
    }
  };

  on_select = (event, item) => {
    const { checked } = event.target;
    const selected_values = this.state.selected_values;
    let index = this.state.selected_values.findIndex((val) => val.value == item.value);
    if (checked && index == -1) {
      selected_values.push(item);
    } else {
      selected_values.splice(index, 1);
    }
    if (this.is_mounted) {
      this.setState(
        {
          selected_values,
          display_reset: selected_values && selected_values.length > 0 ? true : false
        },
        () => {
          this.props.forceOnChangeSelected &&
            this.props.onChangeSelected &&
            this.props.onChangeSelected(this.props.key_id, this.state.selected_values);
        }
      );
    }
  };

  on_search = async (event) => {
    const { value } = event.target;
    const self = this;
    let quick_search_request = false;
    let data = [];
    this.is_list_end = false; //Reset list scroll end status

    if (value) {
      if (this.props.is_lazy_loading) {
        if (self.state.typingTimeout) {
          clearTimeout(self.state.typingTimeout);
        }
        quick_search_request = true;
      } else {
        data = this.props.data.filter((item) =>
          item.name.toString().toLowerCase().includes(value.toString().toLowerCase())
        );
      }

      if (this.is_mounted) {
        self.setState(
          {
            data: this.props.is_lazy_loading ? [] : data,
            filter_text: value,
            lazy_loaded_data: [],
            no_record_msg: this.props.is_lazy_loading? " ": 'No Record Found !',
            typingTimeout: quick_search_request
              ? setTimeout(() => {
                  self.page_index = 1;
                  self.fetch_data(value);
                }, 350)
              : 0
          },
          () => {
            this.props.forceOnChangeSelected &&
              this.props.onChangeSelected &&
              this.props.onChangeSelected(this.props.key_id, this.state.selected_values);
          }
        );
      }
    } else {
      if (this.is_mounted) {
        this.setState({
          data: this.props.is_lazy_loading ? [] : this.props.data,
          filter_text: value,
          lazy_loaded_data: [],
          no_record_msg: " ",
          typingTimeout: this.props.is_lazy_loading
            ? setTimeout(() => {
                self.page_index = 1;
                self.fetch_data();
              }, 350)
            : 0
        });
      }
    }
  };

  on_reset = () => {
    if (this.props.is_lazy_loading) {
      this.props.initial_request();
    }
    if (this.is_mounted) {
      this.setState(
        {
          filter_text: '',
          selected_values: [],
          data: this.props.data,
          display_reset: false
        },
        () => {
          this.props.forceOnChangeSelected &&
            this.props.onChangeSelected &&
            this.props.onChangeSelected(this.props.key_id, this.state.selected_values);
        }
      );
    }
  };

  handleClick = () => {
    if (!this.state.show) {
      document.addEventListener('mousedown', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('mousedown', this.handleOutsideClick, false);
    }
    if (this.is_mounted) {
      this.setState((prevState) => ({
        show: !prevState.show
      }));
    }
  };

  handleOutsideClick = (e, force = false) => {
    if (!force && this.node && this.node.contains(e.target)) {
      return;
    }
    this.handleClick();
    this.props.onChangeSelected(this.props.key_id, this.state.selected_values);
  };

  key_tab_press = (event) => {
    if (event.which == 13) {
      // on enter event
      event.preventDefault();
      this.handleClick();
    }

    // closing on shift tab and esc
    if (event.shiftKey && event.which == '9') {
      if (this.state.show) {
        this.handleOutsideClick(event, true);
      }
    }

    this.close_dropdown_on_esc(event);
  };

  close_dropdown_on_esc = (event) => {
    // closing on shift tab and esc
    if (event.which == '27') {
      event.preventDefault && event.preventDefault();
      event.stopPropagation && event.stopPropagation();
      if (this.state.show) {
        this.handleOutsideClick(event, true);
      }
    }
  };

  reset_on_enter = (event) => {
    if (event.which == 13) {
      // on enter event
      event.preventDefault();
      event.stopPropagation();
      this.on_reset();
    }
  };

  clear_quick_search = (e, item) => {
    e.preventDefault && e.preventDefault();
    e.stopPropagation && e.stopPropagation();
    let newArray = this.state.selected_values.filter((val) => item.value != val.value);
    if (this.is_mounted) {
      this.setState(
        {
          selected_values: newArray,
          display_reset: newArray && newArray.length > 0 ? true : false
        },
        () => {
          this.props.forceOnChangeSelected &&
            this.props.onChangeSelected &&
            this.props.onChangeSelected(this.props.key_id, this.state.selected_values);
        }
      );
    }
  };

  handleDragStart = (dragEvent) => {
    const userAgent = window.navigator.userAgent;
    const isIE = userAgent.indexOf('Trident/') >= 0;
    const data = dragEvent.target.dataset;
    dragEvent.dataTransfer.setData(isIE ? 'text' : 'text/plain', JSON.stringify(data));
  };

  handleDragOver = (e) => {
    e.preventDefault();
  };

  // Received data here view drop
  handleOnDrop = (event) => {
    event.preventDefault();
    const userAgent = window.navigator.userAgent;
    const isIE = userAgent.indexOf('Trident/') >= 0;
    const textData = event.dataTransfer.getData(isIE ? 'text' : 'text/plain');
    let data_source = parse_JSON(textData);
    if (data_source && Object.keys(data_source).length !== 0) {
      if (data_source && data_source.id) {
        let data_destination = event.currentTarget.dataset;
        if (data_source.value != data_destination.value) {
          let groups = this.state.selected_values.filter((item) => item.value != data_source.value);
          let index = groups.findIndex((item) => item.value == data_destination.value);
          if (index != -1) {
            let position = data_source.id < data_destination.id ? index + 1 : index;
            groups.splice(position, 0, data_source);
            if (this.is_mounted) {
              this.setState(
                {
                  selected_values: groups
                },
                () => {
                  this.props.onChangeSelected(this.props.key_id, this.state.selected_values);
                }
              );
            }
          }
        }
      }
    }
  };

  render_panel() {
    let panel_data = this.state.data;
    if (this.props.is_lazy_loading) {
      panel_data = this.state.lazy_loaded_data;
    }
    return (
      <ul style={{ marginBottom: '10px', marginTop: '0px' }}>
        {panel_data.length > 0 ? (
          panel_data.map((item, index) => {
            const val_exist = this.state.selected_values.findIndex((val) => val.value == item.value);
            const is_checked = val_exist > -1 ? true : false;
            return (
              <li
                key={item.value + '-' + index}
                className={
                  this.state.selected_values.length >=
                    disable_constant_count_multi_select_draggable[this.props.key_id] &&
                  val_exist == -1 &&
                  this.props.allow_disable
                    ? 'disabled'
                    : ''
                }
              >
                <label>
                  <span>
                    <input
                      type='checkbox'
                      id={
                        this.props.key_id
                          ? 'provider-multi-select-chk_' + item.value + '_' + this.props.key_id
                          : 'chk_' + item.value
                      }
                      name={index + '__' + item.value}
                      onChange={(value) => this.on_select(value, item)}
                      checked={is_checked}
                      disabled={
                        this.state.selected_values.length >=
                          disable_constant_count_multi_select_draggable[this.props.key_id] &&
                        val_exist == -1 &&
                        this.props.allow_disable
                          ? true
                          : false
                      }
                    />
                  </span>
                  {item.name}
                </label>
              </li>
            );
          })
        ) : (
          <li key={'error'} className={''}>
            {!this.loading && <label>
            <span className='no-data-found'>{this.state.no_record_msg || 'No Record Found !'}</span>
            </label>}
          </li>
        )}
      </ul>
    );
  }

  handle_blur_on_panel = (event) => {
    if (this.state.show) {
      let current_ele = event.target;
      let related_ele = event.relatedTarget;
      if (
        current_ele &&
        current_ele.nodeName == 'INPUT' &&
        current_ele.type == 'checkbox' &&
        related_ele &&
        ((related_ele.nodeName == 'DIV' && related_ele.id == 'droppable_ele') ||
          (related_ele.nodeName == 'INPUT' && related_ele.type == 'radio'))
      ) {
        this.handleOutsideClick(event, true);
      }
    }
  };
  
  calculate_tag_percentage() {
    return Math.round(this.usable_percentage_for_tags/ this.props.tags_per_row);
  }

  render() {
    const validation = this.props.has_validation && !this.state.show ? 'req-background-inp' : '';
    let parent_class = this.props.class_name + ' multi-select-dropdown multi-select-dropdown-draggable';

    return (
      <Manager>
        <div
          className={parent_class}
          id={this.props.id ? this.props.id : 'multi-select-dropdown'}
          ref={(node) => {
            this.node = node;
          }}
          {...(this.props.is_disabled ? { style: { cursor: 'not-allowed', opacity: 0.5 } } : {})}
        >
          <Reference>
            {({ ref }) => (
              <div
                className={'multi-select-dropdown-header multi-select-draggable-close ' + validation}
                id='droppable_ele'
                {...(this.props.is_disabled
                  ? { style: { cursor: 'not-allowed' } }
                  : { onClick: this.handleClick, onKeyDown: (e) => this.key_tab_press(e) })}
                tabIndex={0}
                ref={ref}
              >
                {this.state.selected_values.map((item, index) => {
                  return (
                    <label
                      key={index}
                      id={index}
                      style={{ margin: 2, width: `${this.calculate_tag_percentage()}%` }}
                      className='tooltip'
                      data-id={index}
                      data-checked={item.checked}
                      data-disabled={item.disabled}
                      data-is_checked={item.is_checked}
                      data-name={item.name}
                      data-value={item.value}
                      data-label_text={item.label_text}
                      {...(this.props.is_disabled
                        ? {
                            style: { cursor: 'not-allowed' }
                          }
                        : {
                            draggable: true,
                            onDragOver: (ev) => ev.preventDefault(),
                            onDragStart: this.handleDragStart,
                            onDrop: this.handleOnDrop
                          })}
                    >
                      <span title={item.name} style={{ width: '100%' }}>
                        <BlueprintIcon icon='equals' className='multi-select-drag-icon' />
                        {item.label_text}
                        <div
                          className='multiselect-cross-button'
                          {...(this.props.is_disabled
                            ? { style: { cursor: 'not-allowed' } }
                            : { onClick: (e) => this.clear_quick_search(e, item) })}
                        >
                          <BlueprintIcon icon='cross' className='multi-select-remove-icon' />
                        </div>
                      </span>
                    </label>
                  );
                })}
                {this.state.display_reset && (
                  <button
                    className='multi-select-reset-button'
                    type='button'
                    disabled={this.props.is_disabled}
                    onKeyDown={(e) => this.reset_on_enter(e)}
                    onClick={() => this.on_reset()}
                  >
                    <SemanticIcon className='close icon' />
                  </button>
                )}
              </div>
            )}
          </Reference>
          {this.state.show ? (
            <Popper placement='top'>
              {({ ref, style, placement }) => (
                <div className='multi-select-dropdown-body' ref={ref} style={{ ...style }} data-placement={placement}>
                  {this.props.show_filter && (
                    <Input
                      id={this.props.search_box_id ? this.props.search_box_id : 'multi-select-dropdown-search'}
                      autoComplete='off'
                      name='name'
                      type='text'
                      value={this.state.filter_text}
                      onChange={(value) => this.on_search(value)}
                      placeholder='Search...'
                    />
                  )}
                  <div
                    className='multi-select-dropdown-body-inner'
                    id={this.props.id + '_inner'}
                    onBlur={this.handle_blur_on_panel}
                    onKeyDown={(e) => this.close_dropdown_on_esc(e)}
                    {...(this.props.is_lazy_loading
                      ? { onScroll: this.load_more_data, onWheel: this.load_more_data }
                      : {})}
                  >
                    {this.render_panel()}
                  </div>
                </div>
              )}
            </Popper>
          ) : null}
        </div>
      </Manager>
    );
  }
}

export default MultiSelectDraggableDropDownComponent;
