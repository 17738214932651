import * as React from 'react';
import { Button, Checkbox, Dimmer, Form, Grid, Loader, Header } from 'semantic-ui-react';
import GridView from './../../../shared/component/grid';
import { payer_groups } from '../constants';
import { set_focus_on_element_with_id } from '../../../shared/tab_navigation_utility';
const render_payer_list = (props) => {
    return (<>
        <Grid.Column computer={16}>
            {props.id == "payer_override" && (<label>Payers (Through current company override)</label>)}
            <div className="customtable" style={{ backgroundColor: "#FFF" }} id={props.id}>  
                <GridView    
                        id={`${props.id}_grid`}
                        row={props.payer_list}
                        column={payer_groups.payers}
                        checkboxSelection={true}                       
                        onRowSelection={(items) => { props.on_check_box_selection(items, props.id) }}
                        gridAutoHeight={true}
                        get_grid_ref={props.get_account_notes_grid_ref}
                        emptyMessage=' '
                        name='Payer_Groups'
                        onGridOut={props.handle_on_grid_out}
                        handleCheckboxNavigation={true}
                    />
                </div>
            </Grid.Column>
            <Grid.Column computer={16} textAlign="right">
            <Button id={`${props.id}-btn`} type='button' onClick={e => props.remove_payer(e, props.id)} basic>{props.btn_label}</Button>
            </Grid.Column>            
        </>
    )
}

export default render_payer_list;