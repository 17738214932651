import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Accordion, Button, Dimmer, Form, Grid, Header, Icon, Loader } from "semantic-ui-react";
import ClaimAdjCodeSearch from '../../admin/constants/component/claim_adjustment_code_advanced_search';
import * as admin_payment_const from '../../admin/constants/constants';
import * as global_constants from '../../global_constants';
import * as charge_constant from '../../charge/charge_constant';
import * as shared_constants from '../../shared/shared_constants';
import * as session_storage from '../../shared/session_storage_utility';
import Selection from '../../shared/component/selection_component';
import { content_length, content_width } from '../../shared/component/grid/utils';
import { format_dashes_number, format_date, get_age, custom_date_format, get_all_error } from '../../shared/utility';
import * as  charge_constants from './../charge_constant';
import { get_charge_review_details, get_pending_reason_list, release_charge, search_company_provider, quick_search_company_provider, user_companies_by_charge_status, release_charge_Detail } from '../action/charge_action';
import { set_focus_to_app_header, set_focus_on_element_with_id, handle_click_on_enter } from './../../shared/tab_navigation_utility';
import ChargeReviewDetailRowComponent from '../component/charge_review_detail_row_component';
import AutoSearchComponent from './../../shared/component/auto_search_component';
import AddPrimaryInsurance from '../../shared/component/add_primary_insurance_component';
import AdvancedControl from "../../shared/component/advanced_control";
import { advanced_search_class, quick_search_class, get_contracts, get_insurance_types, quick_search_class_by_company, search_class_by_company, get_company_modifiers } from '../../shared/action/shared_action';
import { quick_search_location, search_place, insurance_advance_search_by_company, insurance_quick_search_by_company, procedure_code_advance_search_by_company, procedure_code_quick_search_by_company, quick_search_location_by_company, search_company_pos, get_searchList } from './../../shared/action/autosearch_action';
import { enum_type_of_search } from './../../case/case_constants';
import { print_charge_detail, export_charge_detail } from '../utility';
import PaymentPagination from '../../payment/component/payment_pagination_component';
import { get_patient_search } from '../../patient/action/patient_search_action';
import * as ReportConstants from '../../reports/report_constants';
import { providerBillingSettingsFeatures,  } from '../../admin/constants/constants';
import * as moment from 'moment';
import ConfirmationComponent from '../../shared/component/confirmation_component';
import { generateAuditDataEntryPayload } from '../../reports/util/export_print_utility';
import { DataEntry } from "../../shared/audit/print_export_constants";
import { ChargesBillingAuditLogEpicsFeature } from "../../admin/constants/constants";
import { get_lauch_darkley_key_value } from "../../shared/utility";
import * as PrintExportConstants from '../../shared/audit/print_export_constants';
import { getValueByElementId } from '../../shared/audit/print_export_utility';

export class ChargeReviewDetailComponent extends React.Component<any, any> {
    [x: string]: any;
    _is_mounted = false;
    all_charge_audit_cached_data = [];
    page_wise_cached_data = [];
    page_size = charge_constant.pagination.page_size;
    page_size_for_lazy_load = charge_constant.pagination.lazy_load_page_size;
    constructor(props) {
        super(props);
        this.state = {
            //
            show_bummepd_charge_message_popup: false,
            selected_company_id: this.props.user_login_details.user_data.data.company_id,
            restrict_pos: this.props.user_login_details.user_data.data.restrict_pos_flag,
            //
            is_search_button_disabled: false,
            is_release_button_disabled: false,
            show_grid: false,
            data_searched: false,
            charge_audit_list: [],
            all_charge_audit_list: [],
            total_visit: 0,
            insurance_class_search_data: null,
            insurance_class_quick_search_format:
            {
                "title": '',
                "label": '',
            },

            provider_search_data: [],
            provider_quick_search_format: {
                "title": '',
                "label": '',
            },
            location_search_data: [],
            location_quick_search_format: {
                "title": '',
                "label": '',
            },
            patient_search_data: [],
            patient_quick_search_format: {
                "title": '',
                "label": ''
            },
            grid_conf: {
                isPagination: true,
                gridAutoHeight: true,
                selectionType: 'single',
                paginationPageSize: 20,
            },
            pending_reasion_list: [],
            charge_audit_action_list: [],
            search_creteria: {
                insurance_class_id: 0,
                provider_id: 0,
                location_id: 0,
                company_id: this.props.user_login_details.user_data.data.company_id,
                insurance_id: 0,
                procedure_code_id: 0,
                //charge_status: ''
            },
            add_primary_insurance_modal: false,
            insurance_types: null,
            insurance_contract: null,
            ins_code_selected_row: null,
            ins_code_modal: false,
            search_form: {
                insurance_class_id: null,
                insurance_code_id: null,
                message: '',
                show_inactive: true
            },
            procedure_code_search_data: {},
            selected_procedure_code: {
                "title": '',
                "label": ''
            },
            pagination_data: { row_count: 0 },
            procedure_code_modifiers1_list: [],
            procedure_code_modifiers2_list: [],
            procedure_code_modifiers3_list: [],
            controls_is_expended: true
        }
        this.charge_audit_row_nodes = [];
    }
    patient_id = session_storage.get('active_patient');

    componentDidMount = () => {
        this._is_mounted = true;
        let controls = this.get_control();
        if (this._is_mounted) {
            this.setState({
                mode: this.add_mode,
                loading: true,
                control: controls
            });
        }
        this.default_search_form = {
            search_form: { ...this.state.search_form },
            payer_selected_row: null,
            adj_code_selected_row: null
        };
        this.get_insurance_master_info();
        this.load_comopany_data('T');
        this.get_procedure_code_modifiers_list(this.state.selected_company_id);
        document.body.classList.add('charge-review-detail');
        if (this.props.location && this.props.location.state && this.props.location.state.search_creteria && this._is_mounted) {
            this.setState({
                search_creteria: this.props.location.state.search_creteria,
                selected_company_id: this.props.user_login_details.user_data.data.company_id,//this.props.location.state.company_id,
                location_quick_search_format: this.props.location.state.location_search_text
            }, async () => {
                await this.get_charge_review_details();
            })
        } else if (this.props.location && this.props.location.state && this.props.location.state.charge_visit) {
            this.on_row_selection_advance_search(enum_type_of_search.place_of_service, this.props.location.state.charge_visit)
        }
        this.get_pending_reason_list();
        set_focus_on_element_with_id('advance-control-insurance_class');

        let scrollArea = document.getElementById('scrollable-content-area');
        scrollArea.addEventListener('scroll', (event) => {
            this.load_more_data(event, 'scroll');
        });
        scrollArea.addEventListener('keydown', (event) => {
            this.load_more_data(event, 'keydown');
        });
        scrollArea.addEventListener('mousewheel', (event) => {
            this.load_more_data(event, 'mousewheel');
        });
    };

    load_more_data = (event, type) => {
        var container = event.currentTarget;
        if (container.scrollTop + container.offsetHeight >= container.scrollHeight - 100) {
            if (this.page_wise_charge_cached_data && this.page_wise_charge_cached_data.length > 0) {
                if (this._is_mounted) {
                    var data = [...this.state.charge_audit_list];
                    var updated_data = this.page_wise_charge_cached_data.splice(0, this.page_size_for_lazy_load);
                    data = data.concat(updated_data);
                    if (this._is_mounted) {
                        this.setState({
                            charge_audit_list: data
                        }, () => { });
                    }
                }
            }
        }
    };

    componentWillUnmount = () => {
        this._is_mounted = false;
        document.body.classList.remove('charge-review-detail');
    }

    get_pending_reason_list = () => {
        if (this._is_mounted) {
            this.setState({
                loading: true,
            });
        }
        get_pending_reason_list(this.props.user_login_details.user_data.data.token_details.access_token).then(res => {
            if (res.data.data) {
                if (res.data.data.length > 0) {
                    var action_list = this.update_data(charge_constants.action_list, true);
                    if (this._is_mounted) {
                        this.setState({
                            show_grid: true,
                            loading: false,
                            charge_audit_action_list: action_list,
                            pending_reasion_list: this.update_data(res.data.data, false)
                        })
                    }
                }
            }
        }, (error) => {
            if (this._is_mounted) {
                this.setState({
                    show_grid: false,
                    loading: false,
                    charge_audit_list: [],
                    all_charge_audit_list: []
                })
            }
        })
    }

    update_data = (source, action_list) => {
        var target = action_list ? [{ "text": 'Select Release or Pend', "value": global_constants.constants.dropdown_default_info.value_string, "key": "Select Release or Pend" }]
            : [{ "text": 'Pend Reason', "value": global_constants.constants.dropdown_default_info.value_string, "key": "Pend Reason" }];
        var source_length = source.length;

        if (source && source_length > 0) {
            for (let y = 0; y < source_length; y++) {
                let item = source[y];
                if (action_list) {
                    target.push({
                        "text": item.action_desc,
                        "value": item.action_id,
                        "key": item.action_id
                    })
                } else {
                    target.push({
                        "text": item.pending_reason_desc,
                        "value": item.pending_reason_id,
                        "key": item.pending_reason_id
                    })
                }
            }
        }

        return target;

    }



    get_charge_review_details = async () => {
        if (this._is_mounted) {
            this.setState({
                loading: true,
                is_search_button_disabled: true,
            }, async () => {
                this.state.search_creteria.insurance_class_id = this.state.search_creteria.insurance_class_id ? this.state.search_creteria.insurance_class_id : 0;
                this.state.search_creteria.provider_id = this.state.search_creteria.provider_id ? this.state.search_creteria.provider_id : 0;
                this.state.search_creteria.location_id = this.state.search_creteria.location_id ? this.state.search_creteria.location_id : 0;
                this.state.search_creteria.insurance_id = this.state.search_creteria.insurance_id ? this.state.search_creteria.insurance_id : 0;
                this.state.search_creteria.procedure_code_id = this.state.search_creteria.procedure_code_id ? this.state.search_creteria.procedure_code_id : 0;
                this.state.search_creteria.patient_id = this.state.search_creteria.patient_id ? this.state.search_creteria.patient_id : 0;
                await get_charge_review_details(this.state.search_creteria, this.props.user_login_details.user_data.data.token_details.access_token).then(res => {
                    if (res.data.data) {
                        if (res.data.data.length > 0) {
                            res.data.data.map((value, index) => {
                                value.dob = value.dob ? custom_date_format(value.dob, global_constants.date_format["mm/dd/yyyy"]) : '';
                                value.posted_date = value.posted_date ? custom_date_format(value.posted_date, global_constants.date_format["mm/dd/yyyy"]) : '';
                                value.pending_reason_id = '';
                                value.action_id = '';
                                value.date_of_injury = value.date_of_injury ? custom_date_format(value.date_of_injury, global_constants.date_format["mm/dd/yyyy"]) : '';
                                value.date_of_service = value.date_of_service ? custom_date_format(value.date_of_service, global_constants.date_format["mm/dd/yyyy"]) : '';
                                value.charges.map((charge, charge_index) => {
                                    charge.procMod1 = charge.procMod1 == '0' ? '' : charge.procMod1;
                                    charge.procMod2 = charge.procMod2 == '0' ? '' : charge.procMod2;
                                    charge.procMod3 = charge.procMod3 == '0' ? '' : charge.procMod3;
                                    value.charge_status = charge.charge_status;
                                    charge.procedure_code = charge.proc_code ? charge.proc_code.proc_code : '';
                                    charge.date_of_service = charge.date_of_service ? custom_date_format(charge.date_of_service, global_constants.date_format["mm/dd/yyyy"]) : '';
                                    charge.injury_date = charge.injury_date ? custom_date_format(charge.injury_date, global_constants.date_format["mm/dd/yyyy"]) : '';
                                    if (charge.injury_date == global_constants.constants.default_date) {
                                        charge.injury_date = '';
                                    }
                                    charge.selected = false;
                                })

                            })
                            this.all_charge_audit_cached_data = [...res.data.data];
                            let record_count = res.data.data.length;
                            this.page_wise_charge_cached_data = [...this.all_charge_audit_cached_data].slice(0, this.page_size);
                            var showdata = this.page_wise_charge_cached_data.splice(0, this.page_size_for_lazy_load);
                            if (this._is_mounted) {
                                this.setState({
                                    show_grid: true,
                                    data_searched: true,
                                    loading: false,
                                    is_search_button_disabled: false,
                                    total_visit: res.data.data.length,
                                    charge_audit_list: showdata,
                                    all_charge_audit_list: res.data.data,
                                    pagination_data: { row_count: record_count },
                                })
                            }
                        } else {
                            if (this._is_mounted) {
                                this.setState({
                                    show_grid: false,
                                    data_searched: true,
                                    loading: false,
                                    is_search_button_disabled: false,
                                    charge_audit_list: [],
                                    all_charge_audit_list: [],
                                    pagination_data: { row_count: 0 },
                                })
                            }
                        }
                    } else {
                        if (this._is_mounted) {
                            this.setState({
                                show_grid: false,
                                data_searched: true,
                                loading: false,
                                is_search_button_disabled: false,
                                charge_audit_list: [],
                                pagination_data: { row_count: 0 },
                                all_charge_audit_list: []
                            })
                        }
                    }
                }, (error) => {
                    if (this._is_mounted) {
                        this.setState({
                            show_grid: false,
                            data_searched: true,
                            loading: false,
                            is_search_button_disabled: false,
                            charge_audit_list: [],
                            pagination_data: { row_count: 0 },
                            all_charge_audit_list: []
                        })
                    }
                })

            });
        }

    }

    clear_search_result = (is_company_reset = false) => {
        var charge_audit_search = this.state.search_creteria;
        charge_audit_search.provider_id = 0;
        charge_audit_search.insurance_class_id = 0;
        charge_audit_search.insurance_id = 0;
        charge_audit_search.procedure_code_id = 0;
        charge_audit_search.patient_id = 0
        //
        this.setState({
            search_creteria: charge_audit_search,
            data_searched: false,
            charge_audit_list: [],
            pagination_data: { row_count: 0 },
            all_charge_audit_list: [],
            total_visit: 0,
            controls_is_expended: true,
            provider_quick_search_format: {
                "title": '',
                "label": '',
            },
            insurance_class_quick_search_format: {
                "title": '',
                "label": '',
            },
            selected_procedure_code: {
                "title": '',
                "label": ''
            },
            patient_quick_search_format: {
                "title": '',
                "label": ''
            },
            ins_code_selected_row: null
        }, () => {
            var criteria = this.state.search_creteria;
            //To reset the intial state of company and search result
            if (is_company_reset && this.props.location && this.props.location.state && this.props.location.state.charge_visit) {
                this.on_row_selection_advance_search(enum_type_of_search.place_of_service, this.props.location.state.charge_visit)
                if (criteria.company_id != this.props.user_login_details.user_data.data.company_id) {
                    criteria.company_id = this.props.user_login_details.user_data.data.company_id;
                }
                if (this._is_mounted) {
                    this.setState({
                        search_creteria: criteria,
                        selected_company_id: this.props.user_login_details.user_data.data.company_id,
                        data_searched: false,
                    });
                }
            } else {
                criteria.location_id = 0;
                if (this._is_mounted) {
                    this.setState({
                        search_creteria: criteria,
                        data_searched: false,
                        location_quick_search_format: {
                            "title": '',
                            "label": '',
                        }
                    });
                }
            }
        });
    }

    on_document_check_changed = items => {
        if (this._is_mounted) {
            this.setState({
                selected_charge_list: [...items]
            });
        }
    }
    on_insurance_class_grid_row_selection = (selected_row) => {
        this.on_row_selection_advance_search(enum_type_of_search.insurance_class, selected_row);
    }
    on_row_selection_advance_search = (type_of_search, selected_row) => {
        var id = '';
        var name = '';
        var code = '';
        var selected_data = '';
        let description = '';

        var charge_audit_search = this.state.search_creteria;

        if (type_of_search === enum_type_of_search.place_of_service) {
            if (selected_row) {
                id = selected_row.id;
                if ((selected_row.code != null && selected_row.code != undefined) || (selected_row.description != null && selected_row.description != undefined)) {
                    code = (selected_row.code == null && selected_row.code == undefined) ? '' : selected_row.code;
                    name = (selected_row.name == null && selected_row.name == undefined) ? '' : selected_row.name;
                    description = (selected_row.description == null && selected_row.description == undefined) ? '' : selected_row.description;
                }
            }
            selected_data = code + " - " + (name || description);
            var format_loc = { "label": '', "title": '', "pos_code": '' };
            if (parseInt(id) > 0) {
                // Set Auto search control for location
                format_loc = {
                    "title": id.toString(),
                    "pos_code": code,
                    "label": selected_data
                };
            }
            charge_audit_search.location_id = id;
            if (this._is_mounted) {
                this.setState({
                    location_quick_search_format: format_loc,
                    search_creteria: charge_audit_search,
                });
            }
        }
        else if (type_of_search === enum_type_of_search.provider) {
            if (selected_row) {
                //id = selected_row.id;
                //if ((selected_row.code != null && selected_row.code != undefined) || (selected_row.name != null && selected_row.name != undefined)) {
                //    code = (selected_row.code == null && selected_row.code == undefined) ? '' : selected_row.code;
                //    name = (selected_row.description == null && selected_row.description == undefined) ? '' : selected_row.description;
                //}
                id = selected_row.provider_id;
                if ((selected_row.provider_code != null && selected_row.provider_code != undefined) || (selected_row.provider_desc != null && selected_row.provider_desc != undefined)) {
                    //name = ((selected_row.last_name == null && selected_row.last_name == undefined) ? '' : selected_row.last_name) + ", " + ((selected_row.first_name == null && selected_row.first_name == undefined) ? '' : selected_row.first_name);
                    code = (selected_row.provider_code == null && selected_row.provider_code == undefined) ? '' : selected_row.provider_code;
                    name = (selected_row.provider_desc == null && selected_row.provider_desc == undefined) ? '' : selected_row.provider_desc;
                }
            }
            selected_data = code + " - " + name;
            var format_bp = { "label": '', "title": '', "bp_code": '' };
            if (parseInt(id) > 0) {
                format_bp = {
                    "title": id.toString(),
                    "bp_code": code,
                    "label": selected_data
                };
            }
            charge_audit_search.provider_id = id;

            if (this._is_mounted) {
                this.setState({
                    provider_quick_search_format: format_bp,
                    search_creteria: charge_audit_search,
                });
            }
        }
        else if (type_of_search === enum_type_of_search.insurance_class) {
            if (selected_row) {
                id = selected_row.id;
                if ((selected_row.code != null && selected_row.code != undefined) || (selected_row.description != null && selected_row.description != undefined)) {
                    code = (selected_row.code == null && selected_row.code == undefined) ? '' : selected_row.code;
                    name = (selected_row.description == null && selected_row.description == undefined) ? '' : selected_row.description;
                }
            }
            selected_data = code + " - " + name;
            var format_bp = { "label": '', "title": '', "bp_code": '' };
            if (parseInt(id) > 0) {
                format_bp = {
                    "title": id.toString(),
                    "bp_code": code,
                    "label": selected_data
                };
            }

            charge_audit_search.insurance_class_id = id;
            if (this._is_mounted) {
                this.setState({
                    insurance_class_quick_search_format: format_bp,
                    search_creteria: charge_audit_search,
                });
            }
        }
        else if (type_of_search === charge_constant.enum_type_of_search.procedure_code) {
            if (selected_row) {
                id = selected_row.procedure_code_id;
                if ((selected_row.proc_code != null && selected_row.proc_code != undefined) || (selected_row.proc_desc1 != null && selected_row.proc_desc1 != undefined)) {
                    code = (selected_row.proc_code == null && selected_row.proc_code == undefined) ? '' : selected_row.proc_code;
                    name = (selected_row.proc_desc1 == null && selected_row.proc_desc1 == undefined) ? '' : selected_row.proc_desc1;
                }
            }
            selected_data = code + " - " + name;
            var format_data = { "label": '', "title": '' };
            if (parseInt(id) > 0) {
                // Set Auto search control for provider
                format_data = {
                    ...selected_row,
                    "is_amt_enabled": `${(selected_row.is_amt_enabled == true || selected_row.is_amt_enabled == "true") ? "true" : "false"}`,
                    "is_unit_enabled": `${(selected_row.is_unit_enabled == true || selected_row.is_unit_enabled == "true") ? "true" : "false"}`,
                    "is_active": selected_row.is_active ? "true" : "false",
                    "title": id.toString(),
                    "label": selected_data,
                };

            }
            charge_audit_search.procedure_code_id = id;
            if (this._is_mounted) {
                this.setState({
                    selected_procedure_code: format_data,
                    search_creteria: charge_audit_search,

                });
            }
        }
        else if (type_of_search === enum_type_of_search.patient_id) {
            if (selected_row) {
                id = selected_row.id;
                selected_data = (selected_row.last_name == null ? '' : selected_row.last_name) + (selected_row.middle_initial == null ? '' : ' ' + selected_row.middle_initial) + (selected_row.first_name == null ? '' : ' ' + selected_row.first_name);
            }
            var format_patient = { "label": '', "title": '' };
            // Set Auto search control for patient
            format_patient = {
                "title": id,
                "label": selected_data
            };
            charge_audit_search.patient_id = id;
            if (this._is_mounted) {
                this.setState({
                    patient_quick_search_format: format_patient,
                    search_creteria: charge_audit_search,
                });
            }
        }
    }

    on_insurance_class_search = async (params) => {
        this.configure_grid_data(enum_type_of_search.insurance_class, params);
    }
    configure_grid_data = async (type_of_search, params) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        params = params ? params : {};
        // params.page_size = 100;
        var grid_data = [];
        if (type_of_search === enum_type_of_search.place_of_service) {
            const search_data_location = await search_company_pos(this.state.selected_company_id, this.state.restrict_pos, params, token).then(res => res.data);
            const search_location_result = search_data_location.data !== null ? search_data_location.data.result : [];
            grid_data = { ...this.state.grid_conf, rows: search_location_result, column: shared_constants.search_location_by_company_configuration(type_of_search).column_defs, messages: search_data_location.messages };
            if (this._is_mounted) {
                this.setState({ location_search_data: grid_data });
            }
        }
        else if (type_of_search === enum_type_of_search.provider) {
            const search_data_provider = await search_company_provider(this.state.selected_company_id, params, token).then(res => res.data);
            const search_provider_result = search_data_provider.data !== null ? search_data_provider.data.result : [];
            grid_data = { ...this.state.grid_conf, rows: search_provider_result, column: shared_constants.search_provider_by_company_configuration(type_of_search).column_defs, messages: search_data_provider.messages };
            if (this._is_mounted) {
                this.setState({ provider_search_data: grid_data });
            }
        }
        else if (type_of_search === enum_type_of_search.insurance_class) {
            const search_data_ins_class = await search_class_by_company(this.state.selected_company_id, params, token).then(res => res.data);
            const search_class_result = search_data_ins_class.data !== null ? search_data_ins_class.data.result : [];
            grid_data = { ...this.state.grid_conf, rows: search_class_result, column: shared_constants.search_class_by_company_configuration(type_of_search + '_headerid').column_defs, messages: search_data_ins_class.messages };
            if (this._is_mounted) {
                this.setState({ insurance_class_search_data: grid_data });
            }
        }
        else if (type_of_search === enum_type_of_search.patient_id) {
            var patient_params = {
                patient_name: {
                    first_name: params.first_name,
                    last_name: params.last_name
                },
                id: params.Id,
                page_size: params.page_size,
                company_id: this.state.selected_company_id
            }
            // Search function.
            if (this._is_mounted) {
                this.props.get_patient_search(patient_params, token).then(res => {
                    this.setState({
                        patient_search_data: { ...this.state.grid_conf, rows: this.props.patient_details.patient_search, column: shared_constants.patient_search_configuration(shared_constants.patient_search_configuration_id).column_defs, messages: "No Patient Found" }

                    });
                });
            }
        }

    }
    //Auto search for Location
    render_suggestion_result = (props) => {
        return (
            <div key={props.title} tabIndex={0} className="item_auto_search">
                <div key={props.title} className="fs_13">
                    <span>{props.label}</span>
                </div>
            </div>

        )
    }

    render_patient_result = (props) => {
        return (
            <div key={props.title} tabIndex={0} className="item_auto_search">
                <div className='fs_13'>
                    <span className="bold">{props.label}</span><span>{props.title ? '(' + props.title + ')' : ''}</span>
                </div>
            </div>
        )
    }
    // get the quick search provider
    get_insurance_class_quick_search_data_list = async (search_keyword) => {
        var search_key_obj = { keyword: search_keyword };
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await quick_search_class_by_company(this.state.selected_company_id, search_key_obj, token);
    }
    // preapare the suggestion list with search result
    prepare_suggestion = (_data, type_of_search) => {
        let formattedList = [];
        var data_length = 0;
        if (_data.data) {
            data_length = _data.data.length;
            _data = _data.data;
        }
        else {
            data_length = _data.length;
        }
        if (_data && data_length) {
            for (var i = 0; i < data_length; i++) {
                var item = _data[i];
                if (type_of_search === enum_type_of_search.place_of_service) {
                    formattedList.push({
                        "title": `${item.id}`,
                        "code": item.code,
                        "name": item.description,

                        "label": (item.code) + " - " + (item.description),

                    })
                }
                else if (type_of_search === enum_type_of_search.provider) {
                    formattedList.push({
                        "title": `${item.id || item.provider_id}`,
                        "code": item.provider_code,
                        "name": item.provider_desc,

                        "label": (item.provider_code) + " - " + (item.provider_desc),

                    })
                }
                else if (type_of_search === enum_type_of_search.insurance_class) {
                    formattedList.push({
                        "title": `${item.id}`,
                        "code": item.code,
                        "name": item.description,

                        "label": (item.code) + " - " + (item.description),

                    })
                }
                else if (type_of_search === charge_constant.enum_type_of_search.procedure_code) {
                    formattedList.push({
                        ...item,
                        "code": item.code,
                        "name": item.description,
                        "is_amt_enabled": `${(item.is_amt_enabled == true || item.is_amt_enabled == "true") ? "true" : "false"}`,
                        "is_unit_enabled": `${(item.is_unit_enabled == true || item.is_unit_enabled == "true") ? "true" : "false"}`,
                        "is_active": item.is_active ? "true" : "false",
                        "title": `${item.id}`,
                        "label": (item.code) + " - " + (item.description),
                    });

                }
                else if (type_of_search === charge_constant.enum_type_of_search.patient_id) {
                    formattedList.push({
                        ...item,
                        "label": (item.last_name == null ? '' : item.last_name + ' ') + (item.middle_initial == null ? '' : item.middle_initial + ' ') + (item.first_name == null ? '' : item.first_name),
                        "title": item.id.toString(),
                        "email": item.email,
                        "dob": (item.date_of_birth == null ? '' : format_date(new Date(item.date_of_birth), false)).toString(),
                        "age": (item.date_of_birth == null ? '' : get_age(item.date_of_birth)) + ' Yrs',
                        "home": (item.phone.home == null || item.phone.home == '' ? ' ' : ' H: ' + format_dashes_number(item.phone.home)),
                        "work": (item.phone.work == null || item.phone.work == '' ? ' ' : ', W: ' + format_dashes_number(item.phone.work)),
                        "cell": (item.phone.cell == null || item.phone.cell == '' ? ' ' : ', M: ' + format_dashes_number(item.phone.cell)),
                    });

                }

            }

        }
        return formattedList;
    }

    on_item_selection = (item, type) => {
        var charge_audit_search = this.state.search_creteria;

        if (type === enum_type_of_search.place_of_service) {
            charge_audit_search.location_id = item.title;
            if (this._is_mounted) {
                this.setState({
                    location_quick_search_format: item,
                    search_creteria: charge_audit_search
                });
            }
        }
        else if (type === enum_type_of_search.provider) {
            charge_audit_search.provider_id = item.title;
            if (this._is_mounted) {
                this.setState({
                    provider_quick_search_format: item,
                    search_creteria: charge_audit_search
                });
            }

        }
        else if (type === enum_type_of_search.insurance_class) {
            charge_audit_search.insurance_class_id = item.title;

            if (this._is_mounted) {
                this.setState({
                    insurance_class_quick_search_format: item,
                    search_creteria: charge_audit_search
                });
            }
        }
        else if (type === charge_constant.enum_type_of_search.procedure_code) {
            charge_audit_search.procedure_code_id = item.title;
            var format_data = this.get_selected_peoc_item(item);
            if (this._is_mounted) {
                this.setState({
                    selected_procedure_code: format_data,
                    search_creteria: charge_audit_search
                });
            }
        }
        else if (type === enum_type_of_search.patient_id) {
            charge_audit_search.patient_id = item.title;
            if (this._is_mounted) {
                this.setState({
                    patient_quick_search_format: item,
                    search_creteria: charge_audit_search
                });
            }
        }
    }

    clear_quick_search = (type_of_search) => {
        var charge_audit_search = this.state.search_creteria;
        if (type_of_search == enum_type_of_search.place_of_service) {
            charge_audit_search.location_id = "0";
            if (this._is_mounted) {
                this.setState({
                    location_quick_search_format: {
                        "title": '',
                        "label": '',
                    },
                    search_creteria: charge_audit_search
                });
            }
        }
        else if (type_of_search == enum_type_of_search.provider) {
            charge_audit_search.provider_id = 0;
            if (this._is_mounted) {
                this.setState({
                    provider_quick_search_format: {
                        "title": '',
                        "label": '',
                    },
                    search_creteria: charge_audit_search
                });
            }
        }
        else if (type_of_search == enum_type_of_search.insurance_class) {
            charge_audit_search.insurance_class_id = 0;
            if (this._is_mounted) {
                this.setState({
                    insurance_class_quick_search_format: {
                        "title": '',
                        "label": '',
                    },
                    search_creteria: charge_audit_search
                });
            }
        }
        else if (type_of_search == charge_constant.enum_type_of_search.procedure_code) {
            charge_audit_search.procedure_code_id = 0;
            if (this.current_setTime_out) {
                clearTimeout(this.current_setTime_out);
            }
            if (this._is_mounted) {
                this.setState({
                    selected_procedure_code: {
                        "title": '',
                        "label": ''
                    },
                    search_creteria: charge_audit_search
                });
            }
        }
        else if (type_of_search == charge_constant.enum_type_of_search.patient_id) {
            charge_audit_search.patient_id = 0;
            if (this.current_setTime_out) {
                clearTimeout(this.current_setTime_out);
            }
            if (this._is_mounted) {
                this.setState({
                    patient_quick_search_format: {
                        "title": '',
                        "label": ''
                    },
                    search_creteria: charge_audit_search
                });
            }
        }

    }
    on_blur_auto_search = (e, type_of_search) => {

    }

    on_location_grid_row_selection = (selected_row) => {
        this.on_row_selection_advance_search(enum_type_of_search.place_of_service, selected_row);
    }
    on_provider_grid_row_selection = (selected_row) => {
        this.on_row_selection_advance_search(enum_type_of_search.provider, selected_row);
    }
    on_patient_grid_row_selection = (selected_row) => {
        this.on_row_selection_advance_search(enum_type_of_search.patient_id, selected_row);
    }
    // get the quick search location
    get_location_quick_search_data_list = async (search_keyword) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await quick_search_location_by_company(this.state.selected_company_id, this.state.restrict_pos, search_keyword, token);
    }
    // get the quick search provider
    get_provider_quick_search_data_list = async (search_keyword) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await quick_search_company_provider(this.state.selected_company_id, search_keyword, token);
    }
    // get the quick search patient
    get_patient_quick_search_data_list = async (search_keyword) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await get_searchList(token, this.state.selected_company_id, search_keyword.trim(), global_constants.constants.Quick_Search_Suggestion_List_Size, global_constants.end_points.patient_urls.quick_search);
    }
    on_location_search = async (params) => {
        this.configure_grid_data(enum_type_of_search.place_of_service, params);
    }
    // function/method to handle the when searching of provider occurs
    on_provider_search = async (params) => {
        this.configure_grid_data(enum_type_of_search.provider, params);
    }

    on_patient_search = async (params) => {
        this.configure_grid_data(enum_type_of_search.patient_id, params);
    }

    get_all_charge_data = () => {
        let row_items = [];
        this.charge_audit_row_nodes.map((node) => {
            if (node) {
                var item = node.get_updated_data();
                row_items = [...row_items, item];
            }
        })
        return row_items;
    }
    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }

    updated_modifiers = (charge_item) => {
        let modifier1 = this.state.procedure_code_modifiers1_list.filter(p => {
            if (p.key == charge_item.proc_mod1) {
                return p;
            }
        });
        let modifier2 = this.state.procedure_code_modifiers1_list.filter(p => {
            if (p.key == charge_item.proc_mod2) {
                return p;
            }
        });
        let modifier3 = this.state.procedure_code_modifiers1_list.filter(p => {
            if (p.key == charge_item.proc_mod3) {
                return p;
            }
        });

        return { 'modifier1': modifier1, 'modifier2': modifier2, 'modifier3': modifier3 }
    }

    release_save_charge = () => {
        if (this._is_mounted) {
            this.setState({ loading: true, is_release_button_disabled: true });
        }
        var charge_list = this.get_all_charge_data();
        var isValid = true;
        let release_charges = [];


        charge_list.map((charge_audit_item, index) => {
            if (charge_audit_item.action_id != 0) {
                charge_audit_item.charges.map((charge_item, charge_index) => {
                    if (charge_audit_item.action_id == 2) {
                        if (charge_audit_item.pending_reason_id == undefined || charge_audit_item.pending_reason_id == null || charge_audit_item.pending_reason_id == 0) {
                            isValid = false;
                        } else {
                            isValid = true;
                        }
                        if (!isValid) {
                            let ele: any = document.querySelector('#pending_reasion_' + index);
                            if (ele) {
                                ele.focus();
                            }
                        }
                    }
                    let modifiers = this.updated_modifiers(charge_item);

                    if (charge_audit_item.action_id && charge_audit_item.action_id != 0 && charge_audit_item.action_id != '0' && charge_item.charge_status == 'T') {
                        if (charge_audit_item.pending_reason_id == 0) {
                            release_charges.push({
                                charge_id: charge_item.charge_id,
                                pending_reason_id: charge_audit_item.pending_reason_id ? charge_audit_item.pending_reason_id : 0,
                                location_id: 0,
                                unit: charge_item.units ? charge_item.units : 0,
                                modifier1: modifiers.modifier1.length > 0 ? modifiers.modifier1[0].code : '',
                                modifier2: modifiers.modifier2.length > 0 ? modifiers.modifier2[0].code : '',
                                modifier3: modifiers.modifier3.length > 0 ? modifiers.modifier3[0].code : '',
                                proc_code: charge_item.proc_code.proc_code,
                                company_id: this.state.selected_company_id,
                                charge_open_time: charge_item.charge_open_time,
                                visit_id: charge_audit_item.visit_id,
                                Visit_action: 'R'
                            })
                        }
                        else {
                            release_charges.push({
                                charge_id: charge_item.charge_id,
                                pending_reason_id: charge_audit_item.pending_reason_id ? charge_audit_item.pending_reason_id : 0,
                                location_id: 0,
                                unit: charge_item.units ? charge_item.units : 0,
                                modifier1: modifiers.modifier1.length > 0 ? modifiers.modifier1[0].code : '',
                                modifier2: modifiers.modifier2.length > 0 ? modifiers.modifier2[0].code : '',
                                modifier3: modifiers.modifier3.length > 0 ? modifiers.modifier3[0].code : '',
                                proc_code: charge_item.proc_code.proc_code,
                                company_id: this.state.selected_company_id,
                                charge_open_time: charge_item.charge_open_time,
                                visit_id: charge_audit_item.visit_id,
                                Visit_action: 'P'
                            })
                        }
                    }

                });
            }
            else {
                charge_audit_item.charges.map((charge_item, charge_index) => {
                    let modifiers = this.updated_modifiers(charge_item);
                    if (charge_item.is_charge_changed) {
                        release_charges.push({
                            charge_id: charge_item.charge_id,
                            pending_reason_id: charge_item.reason_code ? charge_item.reason_code : 0,
                            location_id: 0,

                            unit: charge_item.units ? charge_item.units : 0,
                            modifier1: modifiers.modifier1.length > 0 ? modifiers.modifier1[0].code : '',
                            modifier2: modifiers.modifier2.length > 0 ? modifiers.modifier2[0].code : '',
                            modifier3: modifiers.modifier3.length > 0 ? modifiers.modifier3[0].code : '',
                            proc_code: charge_item.proc_code.proc_code,
                            company_id: this.state.selected_company_id,
                            charge_open_time: charge_item.charge_open_time,
                            Visit_action: ''
                        })
                    }
                });
            }

        });


        if (isValid) {
            if (release_charges.length > 0) {
                release_charge_Detail(this.props.user_login_details.user_data.data.token_details.access_token, release_charges, this.state.selected_company_id).then(res => {
                    if (res.data.data) {
                        toastr.success('', charge_constants.charge_messages.charge_released);
                        if (this._is_mounted) {
                            this.setState({
                                loading: false,
                                is_release_button_disabled: false,
                                show_grid: false,
                                pagination_data: { row_count: 0 },
                                charge_audit_list: [],
                                total_visit: 0
                            });
                        }
                        this.get_charge_review_details();
                    } else {
                        if (this._is_mounted) {
                            this.setState({ loading: false });
                        }
                        if (res.data.messages.length > 0) {
                            const toastr_options = this.show_html_content_toaster(get_all_error(res.data));
                            toastr.error('', toastr_options);
                        }
                    }
                }, (error) => {
                    if (error && error.response && error.response.data && error.response.data.messages && error.response.data.messages.length > 0) {
                        let charge_exception = error.response.data.messages.filter((item) => {
                            return item.code === global_constants.charge_exception.code
                        })
                        if (charge_exception && charge_exception.length > 0) {
                            if (this._is_mounted) {
                                this.setState({
                                    loading: false,
                                    show_bummepd_charge_message_popup: true,
                                    disable_charge_release_button: false,
                                    is_release_button_disabled: false
                                })
                            }
                        } else {
                            if (this._is_mounted) {
                                this.setState({ loading: false, disable_charge_release_button: false, is_release_button_disabled: false });
                            }
                            if (error.response.data.messages.length > 0) {
                                const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                                toastr.error('', toastr_options);
                            }
                        }
                    } else {
                        if (this._is_mounted) {
                            this.setState({ loading: false, disable_charge_release_button: false, is_release_button_disabled: false });
                        }
                    }
                });
            }
            else {
                if (this._is_mounted) {
                    this.setState({ loading: false, is_release_button_disabled: false });
                }
                toastr.warning('', charge_constants.charge_messages.no_audit_selected);
            }
        } else {
            if (this._is_mounted) {
                this.setState({ loading: false, is_release_button_disabled: false });
            }
            toastr.warning('', charge_constants.charge_messages.no_pending_reason);
        }

    }
    release_charge = () => {
        if (this._is_mounted) {
            this.setState({ loading: true, is_release_button_disabled: true });
        }
        var charge_list = this.get_all_charge_data();
        var isValid = true;
        let release_charges = [];


        charge_list.map((charge_audit_item, index) => {

            charge_audit_item.charges.map((charge_item, charge_index) => {
                if (charge_audit_item.action_id == 2) {
                    if (charge_audit_item.pending_reason_id == undefined || charge_audit_item.pending_reason_id == null || charge_audit_item.pending_reason_id == 0) {
                        isValid = false;
                    } else {
                        isValid = true;
                    }
                    if (!isValid) {
                        let ele: any = document.querySelector('#pending_reasion_' + index);
                        if (ele) {
                            ele.focus();
                        }
                    }
                }
                if (charge_audit_item.action_id && charge_audit_item.action_id != 0 && charge_audit_item.action_id != '0' && charge_item.charge_status == 'T') {
                    release_charges.push({
                        charge_id: charge_item.charge_id,
                        pending_reason_id: charge_audit_item.pending_reason_id,
                        location_id: 0
                    })
                }

            });

        });


        if (isValid) {
            if (release_charges.length > 0) {
                release_charge(this.props.user_login_details.user_data.data.token_details.access_token, release_charges, this.state.selected_company_id).then(res => {
                    if (res.data.data) {
                        toastr.success('', charge_constants.charge_messages.charge_released);
                        if (this._is_mounted) {
                            this.setState({
                                loading: false,
                                is_release_button_disabled: false,
                                show_grid: false,
                                pagination_data: { row_count: 0 },
                                charge_audit_list: [],
                                total_visit: 0
                            });
                        }
                        this.get_charge_review_details();
                    }
                }, (error) => {
                    if (this._is_mounted) {
                        this.setState({ loading: false, disable_charge_release_button: false, is_release_button_disabled: false });
                    }
                });
            }
            else {
                if (this._is_mounted) {
                    this.setState({ loading: false, is_release_button_disabled: false });
                }
                toastr.warning('', charge_constants.charge_messages.no_audit_selected);
            }
        } else {
            if (this._is_mounted) {
                this.setState({ loading: false, is_release_button_disabled: false });
            }
            toastr.warning('', charge_constants.charge_messages.no_pending_reason);
        }

    }

    print_charge_detail = () => {
        let title = this.set_title("Charge Review Detail", "<br/>");
        print_charge_detail(this.formate_data_for_print_export(" "), title, "Charge Review Detail");
        this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
    }
    // export Data to csv
    export_charge_detail = () => {
        let title = this.set_title("Charge Review Detail", "\n");
        export_charge_detail(this.formate_data_for_print_export(" "), title, "Charge Review Detail");
        this.saveReportEventAction(ReportConstants.ReportEventAction.Export);
    }

    private getAuditSearchCriteria = (data: DataEntry) => {
        Object.keys(this.state.search_creteria).map(key => {        
        data[key] = this.state.search_creteria[key]
        });
      }

    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;

        if (this.props.magnusPlat1731BillingCharges) {
            const dataEntry: DataEntry = {
                reportId: this.reportId,
            }
            this.getAuditSearchCriteria(dataEntry);

            const viewableEntry: DataEntry = {
                Company_Search: getValueByElementId("audit_company"),
                Insurance_class: getValueByElementId("insurance_class"),
                Insurance: getValueByElementId("ctrl"),
                Provider: getValueByElementId("billing_provider"),
                Location: getValueByElementId("location"),
                Patient_Search: getValueByElementId("patient_id"),
                Procedure_Code: getValueByElementId("PROCEDURE_CODE"),
            };

            const data: PrintExportConstants.IPrinExportDto = {
                entityTypeId: PrintExportConstants.EntityTypeId.ChargeReview,
                auditDataEntry: dataEntry,
                viewableDataEntry: viewableEntry,
            };

            PrintExportConstants.savePrintExportAudit(data, accessToken, eventActionId);
        }
        else {
            const { user_id, company_id } = this.props.user_login_details.user_data.data;
            const payload: ReportConstants.IPayloadForBillingAudit = {
                reportId: this.reportId,
                contextTitle: `Charge Review Detail - ${ReportConstants.ReportEventAction[eventActionId]}`,
                eventActionId: eventActionId,
                userId: Number(user_id),
                companyId: Number(company_id),
                entityTypeId: ReportConstants.ReportEntityTypeId.ChargeAudit,
                data: { VisitCount: this.state.total_visit }
            }
            
            const reqBody = generateAuditDataEntryPayload(payload);
            ReportConstants.saveAuditPrintExport(reqBody, accessToken);
        }
    }

    formate_data_for_print_export = (separter: string) => {
        let all_charge_audit_list = this.get_all_charge_data();

        all_charge_audit_list = all_charge_audit_list.map((charge_audit_list) => {
            charge_audit_list.charges = charge_audit_list.charges.map((charge_item) => {
                let modifiers = this.updated_modifiers(charge_item);
                const proc_code =
                    charge_item.proc_code.proc_code == null && charge_item.proc_code.proc_code == undefined
                        ? ''
                        : charge_item.proc_code.proc_code + ' - ';
                const proc_desc =
                    charge_item.proc_code.proc_desc1 == null && charge_item.proc_code.proc_desc1 == undefined
                        ? ''
                        : charge_item.proc_code.proc_desc1;
                charge_item = {
                    ...charge_item,
                    procModCode1: modifiers.modifier1.length > 0 ? modifiers.modifier1[0].text : '',
                    procModCode2: modifiers.modifier2.length > 0 ? modifiers.modifier2[0].text : '',
                    procModCode3: modifiers.modifier3.length > 0 ? modifiers.modifier3[0].text : '',
                    procedure_code: proc_code + proc_desc
                };
                return charge_item;
            });
            return charge_audit_list;
        });

        let subHeader = JSON.parse(JSON.stringify(charge_constants.charge_audit_col_header.subHeader));
        subHeader = subHeader.filter((item) => item.field !== 'reason_code');
        const formatted_data = all_charge_audit_list.map((item) => {
            const header = JSON.parse(JSON.stringify(charge_constants.charge_audit_col_header.header));
            header[0].name = `Patient #${item.patient_id}`;
            item.place_of_service = `${item.place_of_service_name}${separter}(${item.place_of_service_code})`;
            item.billing_provider_name = `${item.billing_provider_name}${separter}(${item.billing_provider_code})`;
            item.date_of_service = item.charges[0].date_of_service;
            return {
                header,
                subHeader,
                ...item
            };
        });
        return formatted_data;
    }

    //Used to create the title for export and print.
    set_title = (title: string, separter: any) => {
        let current_date = moment(new Date()).format("MM/DD/YYYY") + " " + moment(new Date()).format("hh:mm:ss A");
        if (separter === '\n') {
            title = decodeURIComponent(
                title + separter + current_date + separter + "Visit Count: " + this.state.total_visit + separter + separter
            );
        } else {
            title = decodeURIComponent(
                current_date + separter + "Visit Count: " + this.state.total_visit + separter + separter
            );
        }
        return title;
    }

    //
    load_comopany_data = (charge_status) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        user_companies_by_charge_status(token, this.props.user_login_details.user_data.data.user_id, charge_status).then(res => {
            if (res.data.data) {
                if (this._is_mounted) {
                    this.setState({
                        user_company_options: this.update_company_data(res.data.data)
                    })

                }
            }
        })
    }
    update_company_data = (source) => {
        var target = [{ "text": '', "value": global_constants.constants.dropdown_default_info.value_string, "key": "" }];
        var source_length = source.length;
        if (source && source_length > 0) {
            for (let y = 0; y < source_length; y++) {
                let item = source[y];
                target.push({
                    "text": item.company_code + ' - ' + item.company_name + '(Charges:' + item.charge_count + ')',
                    "value": item.company_id,
                    "key": item.company_id
                })
            }
        }
        return target;

    }
    on_company_change = (value, e) => {
        var creteria = this.state.search_creteria;
        creteria.company_id = value;
        this.get_procedure_code_modifiers_list(value);
        this.setState({
            selected_company_id: value,
            charge_audit_list: [],
            pagination_data: { row_count: 0 },
            show_grid: false,
            total_visit: 0,
            search_creteria: creteria,
        }, () => { this.clear_search_result(false); })
    }
    // quick search handler in payer and adjustment code
    on_quick_search = async (params, type) => {
        if (type == charge_constant.enum_type_of_search.ins_code_enum) {
            return await insurance_quick_search_by_company(this.state.selected_company_id, params, this.token);
        } else if (type == charge_constant.enum_type_of_search.procedure_code) {
            // let param = { keyword: params };
            return await procedure_code_quick_search_by_company(this.state.selected_company_id, params, this.token);
        }
    };
    on_advanced_search = async (params, type) => {
        if (charge_constant.enum_type_of_search.procedure_code == type) {
            await procedure_code_advance_search_by_company(this.state.selected_company_id, params, this.token).then(
                res => {
                    let result = res.data ? res.data.data : [];
                    if (this._is_mounted) {
                        this.setState({
                            proc_code_search_data: {
                                rows: result,
                                column: admin_payment_const.search_class_configuration.column_defs
                            }
                        });
                    }
                },
                error => {
                    if (error.response && error.response.data && error.response.data.messages) {
                        toastr.error('', error.response.data.messages[0].message);
                    }
                }
            );
        } else if (charge_constant.enum_type_of_search.ins_code_enum == type) {
            params.records = 100;
            await insurance_advance_search_by_company(this.state.selected_company_id, params, this.token).then(
                res => {
                    let result = res.data ? res.data.data : [];
                    if (this._is_mounted) {
                        this.setState({
                            ins_code_search_data: {
                                rows: result,
                                column: admin_payment_const.search_insurance_configuration.column_defs
                            }
                        });
                    }
                },
                error => {
                    if (error.response && error.response.data && error.response.data.messages) {
                        toastr.error('', error.response.data.messages[0].message);
                    }
                }
            );
        }
    };
    // add primary insurance
    add_primary_insurance = () => {
        if (this._is_mounted) {
            this.setState({ add_primary_insurance_modal: !this.state.add_primary_insurance_modal });
        }
    };
    get_insurance_master_info = async () => {
        var ins_type = await get_insurance_types(this.token).then(res => {
            if (res.data.data != null && res.data.data.length > 0) {
                return res.data.data.map((val, index) => {
                    return { key: index, value: val.id, text: val.name };
                });
            }
        }); // Get All insurance types from api

        const available_contracts = await get_contracts(this.token).then(res => {
            if (res.data.data != null && res.data.data.length > 0) {
                return res.data.data;
            }
        });
        if (this._is_mounted) {
            this.setState({
                insurance_types: ins_type,
                insurance_contract: available_contracts
            });
        }
    };
    set_primary_insurance_details = data => {
        if (this._is_mounted && data) {
            let row = {};
            row['insurance_code'] = data.insurance_code;
            row['insurance_name'] = data.name;
            row['address1'] = data.address ? data.address.address1 : '';
            row['city'] = data.address ? data.address.city : '';
            row['state'] = data.address ? data.address.state : '';
            row['zip'] = data.address ? data.address.zip : '';
            this.setState({
                ins_code_selected_row: row,
                ins_code_modal: !this.state.ins_code_modal,
                search_form: {
                    ...this.state.search_form,
                    insurance_code_id: data.id
                }
            });
        }
    };
    on_close_primary_insurance_modal = () => {
        // setting the focus on Add New Button
        let modalButtons: any = document.querySelectorAll('#bg_fff2');
        if (modalButtons.length > 0) {
            modalButtons[modalButtons.length - 1].focus();
        }
    };
    // update state selected in searhc form
    update_data_ins = (data, type) => {
        if (type == charge_constant.enum_type_of_search.procedure_code) {
            if (this._is_mounted) {
                this.setState({
                    search_form: {
                        ...this.state.search_form,
                        procedure_code_id: data ? data.id : null
                    },
                    procedure_code_selected_row: data,
                    is_focused: false,
                    ins_error: data == null ? false : data ? false : true
                });
            }
        } else {
            var charge_audit_search = this.state.search_creteria;
            charge_audit_search.insurance_id = data && data.id ? data.id : '';
            if (this._is_mounted) {
                this.setState({
                    search_form: {
                        ...this.state.search_form,
                        insurance_code_id: data ? data.id : null,
                        search_creteria: charge_audit_search,
                    },
                    ins_code_selected_row: data,
                    is_focused: false,
                    ins_error: data && data.id ? false : this.state.ins_error
                });
            }
        }
    };
    get_control = () => {
        let controls = {
            procedure_code: {
                type: 'procedure_code',
                grid_config: {
                    rows: null,
                    column: shared_constants.search_procedure_code_configuration('procedure_code_search').column_defs
                },
                control_id: global_constants.constants.advanced_control_type.procedure_code,
                selected_row: null,
                error_message: 'No Record Found !',
                label: ['proc_code', 'proc_desc1']
            },
            insurance_code: {
                type: 'ins_code',
                grid_config: {
                    rows: null,
                    column: admin_payment_const.search_insurance_configuration.column_defs
                },
                control_id: 'TERTIARY_INSURANCE',
                error_message: 'No Record Found !',
                //label: ['code', 'description'],
                label: ['insurance_code', 'insurance_name', 'address1', 'city', 'state', 'zip'],
                selected_row: null
            }
        };
        return controls;
    };
    //Procedure code search
    on_provider_code_grid_row_selection = (selected_row) => {
        this.on_row_selection_advance_search(charge_constant.enum_type_of_search.procedure_code, selected_row);
    }

    on_procedure_code_search = async (params) => {
        var grid_data = [];
        const search_data = await procedure_code_advance_search_by_company(this.state.selected_company_id, params,
            this.props.user_login_details.user_data.data.token_details.access_token).then(res => res.data);
        const search_result = search_data.data !== null ? search_data.data.result : [];

        let col_def = this.column_def_format(shared_constants.search_procedure_code_configuration('procedure_code_searcch_' + this.props.row_count).column_defs, search_result);
        grid_data = {
            ...this.state.grid_conf, rows: search_result,
            column: col_def,
            messages: search_data.messages
        };
        if (this._is_mounted) {
            this.setState({
                loading: false,
                procedure_code_search_data: grid_data
            });
        }
    }
    get_procedure_code_quick_search_data_list = async (search_keyword) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await procedure_code_quick_search_by_company(this.state.selected_company_id, search_keyword, token);
    }

    column_def_format = (column_def, rows) => {
        if (rows && rows.length > 0) {
            let address1_length = 0, address2_length = 0;
            const result = rows.map((item) => {
                const text_address1_length = content_length(item.proc_desc1);
                if (text_address1_length > address1_length) {
                    address1_length = text_address1_length;
                }
                return item;
            });
            column_def = column_def.map(item => {
                if (item.field == 'proc_desc1') {
                    return { ...item, minWidth: content_width(address1_length) };
                }
                return item;
            })
        }
        return column_def;
    }
    get_selected_peoc_item = (selected_row) => {
        var format_data = { "label": '', "title": '' };
        var id = '';
        var name = '';
        var code = '';
        var selected_data = '';
        if (selected_row) {
            id = selected_row.id;
            if ((selected_row.code != null && selected_row.code != undefined) || (selected_row.description != null && selected_row.description != undefined)) {
                code = (selected_row.code == null && selected_row.code == undefined) ? '' : selected_row.code;
                name = (selected_row.description == null && selected_row.description == undefined) ? '' : selected_row.description;
            }
        }
        selected_data = code + " - " + name;

        if (parseInt(id) > 0) {
            // Set Auto search control for provider
            format_data = {
                ...selected_row,
                "is_amt_enabled": `${(selected_row.is_amt_enabled == true || selected_row.is_amt_enabled == "true") ? "true" : "false"}`,
                "is_unit_enabled": `${(selected_row.is_unit_enabled == true || selected_row.is_unit_enabled == "true") ? "true" : "false"}`,
                "title": id.toString(),
                "label": selected_data,
            };
        }
        return format_data;
    }
    // Pagination change callback function
    on_pagination_change = (start_index, end_index) => {
        this.page_wise_charge_cached_data = this.all_charge_audit_cached_data.slice(start_index, end_index);
        //To scroll top and left with every page change
        var element = document.getElementById('scrollable-content-area');
        element.scrollTop = 0;
        element.scrollLeft = 0;
        const data = this.page_wise_charge_cached_data.splice(0, this.page_size_for_lazy_load);
        if (this._is_mounted) {
            this.setState({
                charge_audit_list: data,
            });
        }
    }
    update_data_modifier = (source, modifire1) => {
        var target = modifire1 == 1 ? [{ "text": '-- Please Select a Modifier 1 --', "value": global_constants.constants.dropdown_default_info.value_string, "key": "-- Please Select a Modifier 1 --", "code": '0' }]
            : modifire1 == 2 ? [{ "text": '-- Please Select a Modifier 2 --', "value": global_constants.constants.dropdown_default_info.value_string, "key": "-- Please Select a Modifier 2 --", "code": '0' }]
                : [{ "text": '-- Please Select a Modifier 3 --', "value": global_constants.constants.dropdown_default_info.value_string, "key": "-- Please Select a Modifier 3 --", "code": '0' }];
        var source_length = source.length;

        if (source && source_length > 0) {
            for (let y = 0; y < source_length; y++) {
                let item = source[y];
                if (item.procedure_modifier_code && item.procedure_modifier_code == 'KX') {
                    this._kx_modifier_id = item.id;
                }

                target.push({
                    "text": (item.procedure_modifier_code ? item.procedure_modifier_code : '') + ' - ' + (item.procedure_modifier_desc ? item.procedure_modifier_desc : ''),
                    "value": item.id,
                    "key": item.id,
                    "code": item.procedure_modifier_code
                })
            }
        }

        return target;

    }
    get_procedure_code_modifiers_list = async (company_id) => {
        await get_company_modifiers(this.props.user_login_details.user_data.data.token_details.access_token, company_id)
            .then(response => {
                if (response.data.data) {
                    if (this._is_mounted) {
                        this.setState({
                            procedure_code_modifiers1_list: this.update_data_modifier(response.data.data, 1),
                            procedure_code_modifiers2_list: this.update_data_modifier(response.data.data, 2),
                            procedure_code_modifiers3_list: this.update_data_modifier(response.data.data, 3)
                        });
                    }
                }
                else {
                    if (this._is_mounted) {
                        this.setState({
                            procedure_code_modifiers1_list: this.update_data_modifier([], 1),
                            procedure_code_modifiers2_list: this.update_data_modifier([], 2),
                            procedure_code_modifiers3_list: this.update_data_modifier([], 3)
                        });
                    }
                }
            }, (error) => {
                if (this._is_mounted) {
                    this.setState({
                        procedure_code_modifiers1_list: this.update_data_modifier([], 1),
                        procedure_code_modifiers2_list: this.update_data_modifier([], 2),
                        procedure_code_modifiers3_list: this.update_data_modifier([], 3)
                    });
                }
            })

    }

    refresh_bummepd_charge_modal = () => {
        if (this._is_mounted) {
            this.setState({
                show_bummepd_charge_message_popup: false,
                is_release_button_disabled: false,
                show_grid: false,
                pagination_data: { row_count: 0 },
                charge_audit_list: [],
                total_visit: 0
            }, () => {
                this.get_charge_review_details();
            });
        }
    }
    on_close_bummepd_charge_popup = () => {
        if (this._is_mounted) {
            this.setState({
                show_bummepd_charge_message_popup: false
            });
        }

    }

    // Search Criteria Toggler
    controls_toggle = () => {
        if (this._is_mounted) {
            this.setState((prev_state) => ({
                controls_is_expended: !prev_state.controls_is_expended
            }));
        }
    }

    //
    render() {
        var display = this.state.show_grid && this.state.charge_audit_list.length > 0 ? "block" : "none";
        return (
            <React.Fragment>
                <div className="common-forms">
                    <AddPrimaryInsurance
                        insurance_types={this.state.insurance_types}
                        insurance_contract={this.state.insurance_contract}
                        set_primary_insurance_details={this.set_primary_insurance_details}
                        add_primary_insurance={this.state.add_primary_insurance_modal}
                        on_close_modal={this.on_close_primary_insurance_modal}
                    />
                    <Grid id="applicationPageTitleSticky">
                        <Grid.Column computer={16}>
                            <Header as='h3' textAlign="left" >
                                Charge Review Detail
                            </Header>
                            <Form autoComplete="off" className="normal-form padd-r-0" style={{ paddingBottom: 10 }}>
                                <Grid style={{ marginRight: 0 }}>
                                    <Grid.Column computer={16} tablet={16} textAlign='left' className='accordionColumn'>
                                        <Accordion fluid styled>
                                            <Accordion.Title active={this.state.controls_is_expended} index={0} onClick={this.controls_toggle}>
                                                <Icon
                                                    onKeyDown={handle_click_on_enter}
                                                    tabIndex={0}
                                                    name={this.state.controls_is_expended ? 'angle down' : 'angle right'}
                                                />
                                                Search Criteria
                                            </Accordion.Title>
                                            <Accordion.Content active={this.state.controls_is_expended}>
                                                <Grid style={{ marginTop: 0 }}>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <label>Company</label>
                                                            <Selection
                                                                id='audit_company'
                                                                name='audit_company'
                                                                options={this.state.user_company_options}
                                                                onChange={(value, e) => this.on_company_change(value, e)}
                                                                defaultValue={this.state.selected_company_id}
                                                                style={'dropdown-options-wrap'}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field className="advance-quick-search">
                                                            <label>Insurance Class</label>
                                                            <AdvancedControl
                                                                onGridRowSelection={this.on_insurance_class_grid_row_selection}
                                                                gridConfig={this.state.insurance_class_search_data}
                                                                controlId={global_constants.constants.advanced_control_type.insurance_class}
                                                                onSearch={this.on_insurance_class_search}
                                                                search_type={enum_type_of_search.insurance_class}
                                                                headerIdForGridTabNavigation={enum_type_of_search.insurance_class + '_headerid'}
                                                            />
                                                            <AutoSearchComponent
                                                                on_blur={(e) => {
                                                                    this.on_blur_auto_search(e, enum_type_of_search.insurance_class);
                                                                }}
                                                                control_id={"insurance_class"}
                                                                default_value={this.state.insurance_class_quick_search_format}
                                                                errorMessage={'No Insurance Class Found !'}
                                                                prepareRenderList={this.render_suggestion_result}
                                                                getList={this.get_insurance_class_quick_search_data_list}
                                                                prepareDataList={(data) =>
                                                                    this.prepare_suggestion(data, enum_type_of_search.insurance_class)
                                                                }
                                                                selectresult={(item) =>
                                                                    this.on_item_selection(item, enum_type_of_search.insurance_class)
                                                                }
                                                                is_focus={false}
                                                                show_clear_search={true}
                                                                clear_search={() => this.clear_quick_search(enum_type_of_search.insurance_class)}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field className="advance-quick-search">
                                                            <label>Insurance</label>
                                                            <ClaimAdjCodeSearch
                                                                on_quick_search={this.on_quick_search}
                                                                on_advanced_search={this.on_advanced_search}
                                                                addClicks={this.add_primary_insurance}
                                                                search_result={this.state.ins_code_search_data}
                                                                control={this.state.control ? this.state.control.insurance_code : null}
                                                                headerIdForGridTabNavigation={
                                                                    admin_payment_const.payer_alert_search_insurance_code_id
                                                                }
                                                                update_data={this.update_data_ins}
                                                                selected_row={this.state.ins_code_selected_row}
                                                                hide_modal={this.state.ins_code_modal}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field className="advance-quick-search">
                                                            <label>Provider</label>
                                                            <AdvancedControl
                                                                onGridRowSelection={this.on_provider_grid_row_selection}
                                                                gridConfig={this.state.provider_search_data}
                                                                controlId={global_constants.constants.advanced_control_type.provider}
                                                                onSearch={this.on_provider_search}
                                                                search_type={enum_type_of_search.provider}
                                                                headerIdForGridTabNavigation={enum_type_of_search.provider}
                                                            />
                                                            <AutoSearchComponent
                                                                on_blur={(e) => {
                                                                    this.on_blur_auto_search(e, enum_type_of_search.provider);
                                                                }}
                                                                control_id={"billing_provider"}
                                                                default_value={this.state.provider_quick_search_format}
                                                                errorMessage={'No Provider Found !'}
                                                                prepareRenderList={this.render_suggestion_result}
                                                                getList={this.get_provider_quick_search_data_list}
                                                                prepareDataList={(data) =>
                                                                    this.prepare_suggestion(data, enum_type_of_search.provider)
                                                                }
                                                                selectresult={(item) => this.on_item_selection(item, enum_type_of_search.provider)}
                                                                is_focus={false}
                                                                show_clear_search={true}
                                                                clear_search={() => this.clear_quick_search(enum_type_of_search.provider)}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field className="advance-quick-search">
                                                            <label>Location</label>
                                                            <AdvancedControl
                                                                onGridRowSelection={this.on_location_grid_row_selection}
                                                                gridConfig={this.state.location_search_data}
                                                                controlId={global_constants.constants.advanced_control_type.location}
                                                                onSearch={this.on_location_search}
                                                                search_type={enum_type_of_search.place_of_service}
                                                                headerIdForGridTabNavigation={enum_type_of_search.place_of_service}
                                                            />
                                                            <AutoSearchComponent
                                                                on_blur={(e) => {
                                                                    this.on_blur_auto_search(e, enum_type_of_search.place_of_service);
                                                                }}
                                                                control_id={"location"}
                                                                default_value={this.state.location_quick_search_format}
                                                                errorMessage={'No Location Found !'}
                                                                prepareRenderList={this.render_suggestion_result}
                                                                getList={this.get_location_quick_search_data_list}
                                                                prepareDataList={(data) =>
                                                                    this.prepare_suggestion(data, enum_type_of_search.place_of_service)
                                                                }
                                                                selectresult={(item) =>
                                                                    this.on_item_selection(item, enum_type_of_search.place_of_service)
                                                                }
                                                                is_focus={false}
                                                                show_clear_search={true}
                                                                clear_search={() => this.clear_quick_search(enum_type_of_search.place_of_service)}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={6} computer={4}>
                                                        <Form.Field className="advance-quick-search">
                                                            <label>Patient</label>
                                                            <AdvancedControl
                                                                id={'patient_id_search'}
                                                                onGridRowSelection={this.on_patient_grid_row_selection}
                                                                gridConfig={this.state.patient_search_data}
                                                                controlId={global_constants.constants.advanced_control_type.patient}
                                                                onSearch={this.on_patient_search}
                                                                search_type={enum_type_of_search.patient_id}
                                                                headerIdForGridTabNavigation={enum_type_of_search.patient_id}
                                                            />
                                                            <AutoSearchComponent
                                                                on_blur={(e) => {
                                                                    this.on_blur_auto_search(e, enum_type_of_search.patient_id);
                                                                }}
                                                                control_id={"patient_id"}
                                                                default_value={this.state.patient_quick_search_format}
                                                                errorMessage={'No Patient Found !'}
                                                                prepareRenderList={this.render_patient_result}
                                                                getList={this.get_patient_quick_search_data_list}
                                                                prepareDataList={(data) =>
                                                                    this.prepare_suggestion(data, enum_type_of_search.patient_id)
                                                                }
                                                                selectresult={(item) =>
                                                                    this.on_item_selection(item, enum_type_of_search.patient_id)
                                                                }
                                                                is_focus={false}
                                                                show_clear_search={true}
                                                                clear_search={() => this.clear_quick_search(enum_type_of_search.patient_id)}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field className="advance-quick-search">
                                                            <label>Procedure Code</label>
                                                            <Form.Field className="advance-quick-search">
                                                                <AdvancedControl
                                                                    id={'procedure_code_search'}
                                                                    gridConfig={this.state.procedure_code_search_data}
                                                                    onGridRowSelection={this.on_provider_code_grid_row_selection}
                                                                    controlId={global_constants.constants.advanced_control_type.procedure_code}
                                                                    onSearch={this.on_procedure_code_search}
                                                                    search_type={'procedure_code_searcch_' + this.props.row_count}
                                                                    headerIdForGridTabNavigation={'procedure_code_search'}
                                                                />
                                                                <AutoSearchComponent
                                                                    default_value={this.state.selected_procedure_code}
                                                                    prepareRenderList={this.render_suggestion_result}
                                                                    getList={this.get_procedure_code_quick_search_data_list}
                                                                    prepareDataList={(data) =>
                                                                        this.prepare_suggestion(
                                                                            data,
                                                                            charge_constant.enum_type_of_search.procedure_code
                                                                        )
                                                                    }
                                                                    selectresult={(item) =>
                                                                        this.on_item_selection(
                                                                            item,
                                                                            charge_constant.enum_type_of_search.procedure_code
                                                                        )
                                                                    }
                                                                    errorMessage={'No Record Found !'}
                                                                    control_id={global_constants.constants.advanced_control_type.procedure_code}
                                                                    on_focus={this.onFocus}
                                                                    is_focus={false}
                                                                    show_clear_search={true}
                                                                    clear_search={() =>
                                                                        this.clear_quick_search(charge_constant.enum_type_of_search.procedure_code)
                                                                    }
                                                                />
                                                            </Form.Field>
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid>
                                            </Accordion.Content>
                                        </Accordion>
                                    </Grid.Column>
                                </Grid>
                                <Grid style={{ marginTop: 0 }}>
                                    {this.state.show_grid && this.state.charge_audit_list.length > 0 && (
                                        <Grid.Column computer={8} style={{ alignItems: 'flex-end', display: 'flex' }} className='padd-r-0'>
                                            <p>Visit Count: {this.state.total_visit}</p>
                                        </Grid.Column>
                                    )}
                                    <Grid.Column
                                        computer={this.state.show_grid && this.state.charge_audit_list.length > 0 ? 8 : 16}
                                        textAlign='right'
                                    >
                                        <Button
                                            id='clear_search_charge_btn'
                                            type='button'
                                            onClick={() => this.clear_search_result(true)}
                                            content={'Clear'}
                                            basic
                                        />
                                        <Button
                                            id='search_charge_btn'
                                            type='button'
                                            primary
                                            disabled={this.state.is_search_button_disabled}
                                            onClick={this.get_charge_review_details}
                                            style={{ marginRight: 15 }}
                                            content={'Search'}
                                        />
                                    </Grid.Column>
                                </Grid>
                            </Form>
                        </Grid.Column>
                    </Grid>
                    <div className="auto-height-patient-full-details-scroll" id="scrollable-content-area" style={this.state.data_searched && this.state.charge_audit_list.length == 0 ? { display: 'flex', justifyContent: 'center', alignItems: 'center' } : {}}>
                        <Dimmer active={this.state.loading}>
                            <Loader size="massive">Loading</Loader>
                        </Dimmer>
                        <Grid className="mar-t-5 padd-r-0">
                            <Grid.Column mobile={16} tablet={16} computer={16}>
                                {this.state.show_grid && this.state.charge_audit_list.length > 0 && (
                                    <Grid>
                                        <Grid.Column id="charge_review_details_section" computer={16} className="padd-r-0">
                                            {this.state.charge_audit_list.map((item, index) => {
                                                return (
                                                    item.charges.length > 0 && <ChargeReviewDetailRowComponent key={'details_' + index} total_visit={this.state.total_visit}
                                                        onRef={ref => (this.charge_audit_row_nodes[index] = ref)}
                                                        search_creteria={this.state.search_creteria}
                                                        location_search_text={this.state.location_quick_search_format}
                                                        data={item} row_count={index} pending_reasion_list={this.state.pending_reasion_list}
                                                        modifier2_data={this.state.procedure_code_modifiers2_list}
                                                        modifier1_data={this.state.procedure_code_modifiers1_list}
                                                        modifier3_data={this.state.procedure_code_modifiers3_list}
                                                    />
                                                )
                                            })
                                            }
                                        </Grid.Column>
                                    </Grid>
                                )}
                                {this.state.data_searched && this.state.charge_audit_list.length == 0 &&
                                    <Grid>
                                        <Grid.Column id="charge_review_details_section" computer={16} className="text-center-no-record">
                                            <p>No Records Found.</p>
                                        </Grid.Column>
                                    </Grid>
                                }
                            </Grid.Column >
                        </Grid>
                    </div>
                    <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area" id="applicationFooterSticky">
                        <Grid >
                            <Grid.Column mobile={4} tablet={4} computer={2}>
                            </Grid.Column>
                            <Grid.Column mobile={8} tablet={8} computer={10} textAlign={"left"}>
                                <PaymentPagination
                                    data={this.state.pagination_data}
                                    onChange={this.on_pagination_change}
                                    changeTimestamp={this.state.data_change_timestamp}
                                    page_size={this.page_size}
                                    is_google_paging={true}
                                    is_show_number_pager={true}
                                />
                            </Grid.Column>
                            <Grid.Column mobile={4} tablet={4} computer={4}>
                                <Button id="charge_export_btn" disabled={!(this.state.charge_audit_list.length > 0)} type='button' onKeyDown={set_focus_to_app_header} basic onClick={this.export_charge_detail} >Export</Button>
                                <Button id="charge_print_btn" disabled={!(this.state.charge_audit_list.length > 0)} type='button' onClick={this.print_charge_detail} basic >Print</Button>
                                <Button id="charge_payment_btn" type='button' onKeyDown={set_focus_to_app_header} disabled={this.state.charge_audit_list.length <= 0 || this.state.is_release_button_disabled} primary onClick={() => this.release_save_charge()}>Save</Button>
                            </Grid.Column>
                        </Grid>
                    </div>

                </div>
                {this.state.show_bummepd_charge_message_popup ?
                    <ConfirmationComponent message={global_constants.charge_exception.message}
                        title='Confirm' show_modal={this.state.show_bummepd_charge_message_popup}
                        onCloseModal={this.on_close_bummepd_charge_popup}
                        save_button_text='Refresh'
                        on_force_save_click={(e) => { this.refresh_bummepd_charge_modal() }} />
                    : ''}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        patient_id: state.patient_details && state.patient_details.patient_header && state.patient_details.patient_header.data && state.patient_details.patient_header.data.id || '',
        patient_details: state.patient_details,
        magnusPlat1731BillingCharges: get_lauch_darkley_key_value(state.launch_darkly, ChargesBillingAuditLogEpicsFeature.magnusPlat1731BillingCharges)
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        get_patient_search: get_patient_search
        //update_patient_header_info: update_patient_header_info,
    }, dispatch)
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChargeReviewDetailComponent));