import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Grid } from "semantic-ui-react";
import { toastr as toaster } from 'react-redux-toastr';
import { global_messages, toastr_options, constants } from '../../../global_constants';
import ReportHeader from '../../../reports/component/report_header';
import { messages, report_ledger_full_configuration_header_id } from '../../../reports/report_constants';
import { log_error } from '../../../shared/action/shared_action';
import GridView from '../../../shared/component/grid';
import { LoaderComponent } from '../../../shared/component/loading_component';
import * as session_storage from '../../../shared/session_storage_utility';
import { set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import { get_all_error, get_columns, get_lauch_darkley_key_value } from '../../../shared/utility';
import { get_claims_detail, claims_change_status_action, claims_print_status_action, claims_print_status, get_edi_file } from '../../action/billing_actions';
import { batch_billing_configuration, run_status_update } from '../../billing_constants';
import { row_data_formatter, check_internal_staff } from '../../utility';
import { patient_messages } from '../../../patient/patient_constants';
import * as FFConstant from '../../../feature_flip_constant';
import { ResizableDraggableModal } from '../../../shared/resizable-draggable-modal';

export class BatchBillingClaimDetailComponent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      is_grid_visible: false,
      loading: false,
      is_new_disabled: false,
      grid_rows_count: 0,
      grid_params: null,
      show_modal: false,
      edi_file_info: ''
    };
  }

  batch_billing: any = {};
  report_data: any = {
    rows: [],
    cols: []
  };
  total_column_width: number = 0;
  grid_header_height: number = 0;
  grid_width = 0;
  token: any = '';
  is_mounted = false;
  claim_id = 0;
  run_number = 0;
  selected_items: any = [];
  pdf_counter: number = 0;


  // Fetching the report id and params.
  UNSAFE_componentWillMount = () => {
    this.batch_billing = batch_billing_configuration(this.props);
    this.report_data.cols = [...this.batch_billing.column_defs_claim_detail];
    this.token = this.props.user_login_details.user_data.data.token_details.access_token;
  };

  componentDidMount = () => {
    this.is_mounted = true;
    const route_array = this.props.history.location.pathname.split('/');
    if (
      route_array &&
      route_array[2] == 'batch_billing' &&
      route_array[3] == this.props.history.location.state.claim_id
    ) {
      this.claim_id = this.props.history.location.state.claim_id;
      this.run_number = this.props.history.location.state.data.run_number;
      this.get_claims_detail();
    } else {
      this.props.history.goBack();
    }
    if (!document.body.classList.contains('reports')) {
      document.body.classList.add('reports');
    }
  };

  componentWillUnmount = () => {
    document.body.classList.remove('reports');
    this.is_mounted = false;
    this.report_data = {};
    if (this.props.history.location) {
      const route_array = this.props.history.location.pathname.split('/');
      route_array && route_array[2] !== 'batch_billing' && session_storage.remove('batch_billing_search_criteria');
    }
  };

  get_edi_file_data = async () => {
    if (this.is_mounted) {
      this.setState({
        loading: true
      });
    }
    await get_edi_file(this.token, this.run_number).then(
      (response) => {
        if (response.data) {
          if (response.data.messages) {
            log_error(response.data.messages);
            const toastr_options = this.show_html_content_toaster(get_all_error(response.data.messages));
            toaster.error('', toastr_options);
            if (this.is_mounted) {
              this.setState({
                loading: false
              });
            }
          } else {
            if (this.is_mounted) {
              this.setState({
                loading: false,
                edi_file_info: response.data.data || constants.no_records_found_message,
                show_modal: true
              });
            }
          }
        } else {
          if (this.is_mounted) {
            this.setState({
              loading: false
            });
          }
        }
      },
      (error: { response: { data: any } }) => {
        if (this.is_mounted) {
          this.setState({
            loading: false
          });
        }
        if (error.response && error.response.data) {
          log_error(error);
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error('', toastr_options);
        }
      }
    );
  };

  // Get Report Data.
  get_claims_detail = async () => {
    this.grid_width = document.getElementsByClassName('report-framework')[0].clientWidth;
    if (this.is_mounted) {
      this.setState({
        loading: true,
        is_grid_visible: false,
      });
    }
    await get_claims_detail(this.token, this.claim_id).then(
      (response) => {
        if (response.data && response.data.data) {
          this.formate_data(response);
          if (this.is_mounted) {
            this.setState({
              loading: false,
              is_grid_visible: true,
              grid_rows_count: this.report_data['rows'] && this.report_data['rows'].length
            });
          }
        } else {
          if (response.data.messages) {
            log_error(response.data.messages);
            const toastr_options = this.show_html_content_toaster(get_all_error(response.data.messages));
            toaster.error('', toastr_options);
          }
          if (this.is_mounted) {
            this.setState({
              loading: false
            });
          }
        }
      },
      (error: { response: { data: any } }) => {
        if (this.is_mounted) {
          this.setState({
            loading: false
          });
        }
        if (error.response && error.response.data) {
          log_error(error);
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error('', toastr_options);
        }
      }
    );
  };

  // Show multiple messages
  show_html_content_toaster = (msg: any) => {
    return {
      component: () => (
        <div>
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        </div>
      ),
      timeOut: toastr_options.toastr_time_out,
      preventDuplicates: true
    };
  };

  // Function calls on initialization of Ag-Grid and catch its reference.
  get_grid_ref = (grid_params: {
    api: { getModel: () => { (): any; new(): any; getRowCount: { (): any; new(): any } } };
    columnApi: { setColumnWidth: (arg0: any, arg1: any, arg2: boolean) => void };
  }) => {
    if (this.is_mounted) {
      this.setState({
        grid_params,
        grid_rows_count: grid_params.api.getModel().getRowCount()
      });
    }
    if (this.total_column_width > this.grid_width) {
      this.report_data.cols.filter((item: { [x: string]: any; field: any }) => {
        grid_params.columnApi.setColumnWidth(item.field, item['width'], false);
      });
    }
  };

  //Function calls after click of filter button and recalculate the footer sum.
  on_filter_button_click = () => {
    // Update row count
    this.setState({
      grid_rows_count: this.state.grid_params.api.getModel().getRowCount()
    });
  };

  handle_navigation_after_last_element = (event) => {
    // apply a check where grid is empty
    if (
      !event.shiftKey &&
      event.keyCode == '9' &&
      (this.state.report_data == null || this.state.report_data.length == 0)
    ) {
      set_focus_to_app_header(event);
    }
  };
  // Select case_id on checkbox
  on_check_box_selection = (items) => {
    if (this.selected_items.length !== items.length) {
      this.selected_items = items;
    }
  };

  // Buttons on click
  handle_change_status = async (format_print_status_type) => {
    if (this.selected_items && this.selected_items.length <= 0) {
      toaster.error('', this.batch_billing.update_msg.empty_error_all);
      return;
    }
    let params = this.selected_items.map((item) => item.claim_hdr_id);
    if (this.is_mounted) {
      this.setState({
        loading: true,
        is_update_disabled: true
      });
    }

    let token = this.props.user_login_details.user_data.data.token_details.access_token;
    if (format_print_status_type === 4) {
      if (this.is_mounted) {
        this.setState({
          re_render: true
        });
        }

      claims_print_status_action(params, token).then(
        (response) => {
          this.generate_pdf(response.data.data);
        },
        (error) => {
          this.setState({
            re_render: false
          });
          if (error.response.data) {
            const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
            toaster.error('', toastr_options);
          }
        }
      );
    }
    await claims_change_status_action(token, this.run_number, format_print_status_type, run_status_update.claim_deatils, params).then(
      (response) => {
        if (response.data && response.data.status === 1) {
          toaster.success('', response.data.messages[0].message);
          this.formate_data(response);
          if (this.is_mounted) {
            this.selected_items = [];
            this.setState(
              {
                loading: false,
                is_update_disabled: false,
                is_grid_visible: false,
              },
              () => {
                this.setState({
                  loading: false,
                  is_update_disabled: false,
                  is_grid_visible: true,
                  grid_rows_count: this.report_data['rows'] && this.report_data['rows'].length
                });
              }
            );
          }
        } else {
          if (response.data.messages) {
            const toastr_options = this.show_html_content_toaster(get_all_error(response.data.messages));
            toaster.error('', toastr_options);
          }
          if (this.is_mounted) {
            this.selected_items = [];
            this.setState({
              loading: false,
              is_update_disabled: false
            });
          }
        }
      },
      (error) => {
        if (this.is_mounted) {
          this.setState({
            loading: false,
            is_update_disabled: false
          });
        }
        if (error.response.data) {
          log_error(error);
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error('', toastr_options);
        }
      }
    );
  };

  generate_pdf = (data) => {
    const pdf_file = new Blob([data], {
      type: 'application/pdf'
    });
    if (pdf_file.size <= 0) {
      if (this.is_mounted) {
        this.setState({ re_render: false });
      }
      toaster.error('', patient_messages.report_not_found);
      return false;
    } else {
      // For IE
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        if (this.is_mounted) {
          this.setState({
            re_render: false
          });
        }
        window.navigator.msSaveOrOpenBlob(pdf_file);
      } else {
        const pdf_content = window.URL.createObjectURL(pdf_file);
        const win = open(pdf_content, 'demand_claim' + this.pdf_counter++, '');
        if (!win) {
          toaster.error('', global_messages.popup_blocked);
        } else {
          win.focus();
          win.onload = () => {
            win.print();
            // window.URL.revokeObjectURL(pdf_content);
          };
        }
        if (this.is_mounted) {
          this.setState({
            re_render: false
          });
        }
      }
    }
  };

  close_modal = () => {
    if (this.is_mounted) {
      this.setState({
        show_modal: false
      });
    }
  }

  formate_data = (response) => {
    response.data.data = row_data_formatter(response.data.data, this.report_data.cols);
    this.report_data['rows'] = response.data.data || [];
    this.total_column_width = 0;
    const grid_height = get_columns(this.report_data['rows'], this.report_data.cols);
    this.grid_header_height = grid_height.header_height;
    this.total_column_width = grid_height.total_column_width;
  }

  render() {
    let fullPath = this.report_data.rows && this.report_data.rows.length > 0
                   ? this.report_data.rows[0].full_path
                   : '';
    let pathOnly = fullPath != null && fullPath.length > 0
                   ? fullPath.substring(0, fullPath.lastIndexOf("\\"))
                   : '';
    let filename = this.report_data.rows && this.report_data.rows.length > 0
                   ? this.report_data.rows[0].file_name
                   : '';

    return (
      <LoaderComponent loading={this.state.loading}>
        <div className={'common-forms-add '}>
          <div className={'common-forms-search report-framework'}>
            <ReportHeader title={'Batch Claims - Run Details'} />
            {this.state.is_grid_visible && (
              <React.Fragment>
                <div
                  id='report-scrollable-area'
                  className='report-wrapper'
                  style={{
                    flex: '1 1 0px',
                    marginRight: 5
                  }}
                >
                  <GridView
                    id={this.batch_billing.report_grid_id}
                    row={this.report_data.rows}
                    column={this.report_data.cols}
                    wrapperClass={'grid_wrapper paginated_grid'}
                    style={{ height: '100%', display: 'flex', flexFlow: 'column' }}
                    wrapperStyle={{ width: '100%', height: '100%', display: 'flex' }}
                    headerHeight={this.grid_header_height}
                    suppressMovableColumns={true}
                    suppressColumnVirtualisation={true}
                    enableColResize={true}
                    emptyMessage={messages.no_records_found}
                    get_grid_ref={this.get_grid_ref}
                    headerIdForTabNavigation={report_ledger_full_configuration_header_id}
                    suppressSizeToFit={true}
                    gridAutoHeight={false}
                    checkboxSelection={true}
                    onRowSelection={this.on_check_box_selection}
                    on_filter_button_click={() => {
                      this.on_filter_button_click();
                    }}
                  />
                </div>
                <div
                  className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area"
                  id="applicationFooterSticky"
                  style={{ paddingRight: 5, paddingLeft: 0 }}>
                  <Grid style={{ padding: '9px 0px' }}>
                    {this.props.rsiBill393UiBillingFailedCharges && <React.Fragment>
                      <Grid.Column tablet={9} computer={12} textAlign='left' >
                        <Button
                          id='btn-output'
                          type='button'
                          style = {{minWidth: '114px'}}
                          basic
                          content={'View Output'}
                          onClick={this.get_edi_file_data}
                        />
                        {check_internal_staff() && this.report_data.rows && this.report_data.rows[0] && <span> Path: {pathOnly} {`FileName : ${filename}`} </span>}
                      </Grid.Column>
                    </React.Fragment>}
                    <Grid.Column
                      tablet={this.props.rsiBill393UiBillingFailedCharges ? 7 : 16}
                      computer={this.props.rsiBill393UiBillingFailedCharges ? 4 : 16}
                      textAlign='right'>
                      <Button
                        id='btn-queued'
                        type='button'
                        basic
                        content={'Queued'}
                        disabled={this.state.is_update_disabled}
                        onClick={
                          () => this.handle_change_status(this.batch_billing.format_print_status.queued)
                        }
                      />
                      <Button
                        id='btn-pend'
                        type='button'
                        basic
                        content={'Pend'}
                        disabled={this.state.is_update_disabled}
                        onClick={() =>
                          this.handle_change_status(this.batch_billing.format_print_status.pending)
                        }
                      />
                      <Button
                        id='btn-print'
                        className='primary'
                        type='button'
                        style={{ marginRight: '0' }}
                        content={'Print'}
                        onKeyDown={set_focus_to_app_header}
                        disabled={this.state.is_update_disabled}
                        onClick={() =>
                          this.handle_change_status(this.batch_billing.format_print_status.printed)
                        }
                      />
                    </Grid.Column>
                  </Grid>
                </div>
              </React.Fragment>
            )}
          </div>
          {this.state.show_modal && (
            <ResizableDraggableModal
              showModal={true}
              dimmer={false}
              boundWith={"window"}
              dragHandleClassName={"daragble-header"}
              autoResizeOnload={true}
              popUpPosition={{
                x: 300,
                y: 65,
                width: 1000,
                height: 700,
                minHeight: 400,
                minWidth: 515,
                maxWidth: 1600,
                maxHeight: 900
              }}
              enable={{
                bottomRight: true
              }}
              className={"modal_container"}
            >
              <ResizableDraggableModal.Header
                className={"daragble-header"}
                id="daragble-header"
                onRequestClose={this.close_modal}
                styles={{ borderBottom: 0, padding: 20 }}
              >
                <h3
                  className={"ui header left aligned"}
                  style={{ fontSize: 22, color: "#354052" }}>EDI File View</h3>
              </ResizableDraggableModal.Header>
              <ResizableDraggableModal.Content
                className={"daragble-content scrolling"}
                styles={{ maxHeight: "inherit", paddingTop: "1rem", flexDirection: 'row' }}
              >
                <ResizableDraggableModal.Description className={"daragble-description"}
                  dangerouslySetInnerHTML={{
                    __html:
                      <div id="report-scrollable-area" className={"report-wrapper"} style={{ flex: "1 1 0", overflow: "auto", padding: '5px', border: "1px solid #a1aebb" }}>
                        <div className="main-grid-wrapper grid_wrapper">
                          <div style={{ display: 'flex', justifyContent: "space-between" }}>
                            {this.state.edi_file_info && <div>
                              {check_internal_staff() && <table>
                                <tbody>
                                  <tr>
                                    <td>Filename: </td>
                                    <td>{filename}</td>
                                  </tr>
                                  <tr>
                                    <td>Path: </td>
                                    <td>{pathOnly}</td>
                                  </tr>
                                </tbody>
                              </table>}
                              <table >
                                <tbody>
                                  <tr><td><br/></td></tr>
                                  <tr>
                                    <td style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}>{this.state.edi_file_info.body}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>}
                          </div>
                        </div>
                      </div>
                  }}
                >
                </ResizableDraggableModal.Description>
              </ResizableDraggableModal.Content>
              <ResizableDraggableModal.Footer
                className={"daragble-footer"}
                id="daragble-footer"
              >
                <Button
                  primary
                  onClick={this.close_modal}
                  style={{ float: 'right', margin: 19 }}>
                  Close
                </Button>
              </ResizableDraggableModal.Footer>
            </ResizableDraggableModal>
          )}
        </div>
      </LoaderComponent>
    );
  }
}

// Get user and login details from store.
const mapStateToProps = (state) => {
  return {
    user_login_details: state.user_login_details,
    rsiBill393UiBillingFailedCharges: get_lauch_darkley_key_value(state.launch_darkly, FFConstant.rsi_bill_393_ui_billing_failed_charges)
  };
};
export default connect(mapStateToProps)(BatchBillingClaimDetailComponent);
