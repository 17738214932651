import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Dimmer, Form, Grid, Icon, Loader, Modal } from 'semantic-ui-react';
import * as global_constants from '../../global_constants';
import { get_control_list } from '../../shared/action/advanced_control_action';
import GridView from '../../shared/component/grid';
import { content_length, content_width } from '../../shared/component/grid/utils';
import { get_advance_search } from '../action/diagnosis_action';
import * as diagnosis_constant from '../diagnosis_constant';
import { handle_focus_on_tab, set_focus_on_element_with_id } from './../../shared/tab_navigation_utility';

interface IProps {
    controlId: string,
    onGridRowSelection: Function,
    onSearch: Function,
    onCloseModal: Function,
    gridConfig: { rows?: any; },
    showModal?: boolean,
    get_control_list?: Function,
    advance_control_state?: object,
    user_login_details?: { user_data: { data: { token_details: { access_token: any; }; }; }; },
    addClicks: Function,
    keyPress: Function,
    disabled_element?: string,
    clear_dx_code_quick_search: Function

}
class DiagnosisAdvancedSeach extends React.Component<IProps, any> {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            //control_data: null,
           // form_data: {},
            //control_id: this.props.controlId,
            grid_config: {},
            error_message: '',
            disabled_element: this.props.disabled_element ? this.props.disabled_element : false,
            form_data: {
                icd10_code: '',
                description: '',
                search_option: '1',
                icd9_code: ''
            },
            rows: null,
            column: diagnosis_constant.search_diagnosis_code_configuration(diagnosis_constant.diagnosis_search_header_id).column_defs,
            paginationPageSize: diagnosis_constant.search_diagnosis_code_configuration(diagnosis_constant.diagnosis_search_header_id).pagination_size,
            is_grid_visible: false,
            is_loading: false
        };


    }

    icd_type = null;

    controlHandle = (e) => {
        if (this.state.disabled_element) {
            return;
        }
        e.preventDefault();

        // handle model open window
        this.handle_on_open_modal_window();
        this.setState({ showModal: true });

        if (this.props.clear_dx_code_quick_search) {
            this.props.clear_dx_code_quick_search();
        }

        window.setTimeout(function () {
            var model = document.getElementById('diagnosis_advance_seach_model_pop');
            if (model) {
                var fist_input = model.getElementsByTagName('input');
                if (fist_input && fist_input.length > 0 && fist_input[0]) {
                    fist_input[0].focus();
                }
            }
        }, 500);
    }
    handle_on_open_modal_window = () => {
        setTimeout(() => {
            let closeIcons: NodeListOf<HTMLElement> = document.querySelectorAll('i.close.icon') as NodeListOf<HTMLElement>;
            let closeIcon = closeIcons[closeIcons.length - 1]
            closeIcon.tabIndex = 0;
            closeIcon.id = "diagnosis_advance_model_close_button";
            closeIcon.addEventListener("keydown", function (event) {
                if (event.shiftKey && event.keyCode == 9) {
                    event.preventDefault();
                    set_focus_on_element_with_id("icd9_search_btn");
                }

                if (event.keyCode == 13) {
                    event.preventDefault();
                    var el = event.target as HTMLElement
                    el.click();
                }
            });
        }, 200)
    }
    componentDidUpdate(previousProps, previousState) {

        if (previousState.showModal != this.state.showModal) {
            this.setState({ showModal: this.state.showModal });

            //if (this.state.showModal) {
            //    var token = this.props.user_login_details.user_data.data.token_details.access_token;
            //    this.props.get_control_list(this.state.control_id, token);
            //}
        }

        //if (previousProps.advance_control_state !== this.props.advance_control_state) {
        //    const control_id = this.state.control_id.toLowerCase();
        //    this.setState({ control_data: this.props.advance_control_state[control_id] });
        //}
        if (previousProps.gridConfig != this.props.gridConfig && typeof this.props.gridConfig.rows != 'undefined' && Object.keys(this.props.gridConfig).length > 0) {
            const messages = "No Record Available";// typeof this.props.gridConfig.messages != 'undefined' && this.props.gridConfig.messages !== null ? this.props.gridConfig.messages.map(msg => msg.message).join('.') : '';
            this.setState({ grid_config: this.props.gridConfig, error_message: messages });
        }
        if (previousProps.disabled_element != this.props.disabled_element) {
            this.setState({ disabled_element: this.props.disabled_element });
        }
    }


    /* getHandler method provide handles on bases of provided lable button */

    dx_search = (isDx9Code) => {
        var data = this.state.form_data;// add page size property
        data['page_size'] = global_constants.constants.PAGE_SIZE;
        data['is_active'] = true;
        if (isDx9Code) {
            data['code'] = data['icd9_code'];
            data['icd_type'] = 1;
        } else {
            data['code'] = data['icd10_code'];
            data['icd_type'] = 0;
        }
        
        //this.props.onSearch(data); // fetch data from search apis on click search button
        this.on_search_api_call(data);
    }

    //Diagnosis search ICD-10 Code APi call
    on_search_api_call = async (params) => {
        this.setState({ rows: null, is_grid_visible: true, is_loading: true });
        this.icd_type = params.icd_type;
        params = params ? params : {};
        params["Page_size"] = global_constants.constants.PAGE_SIZE;
        let column_def = diagnosis_constant.search_diagnosis_code_configuration(diagnosis_constant.diagnosis_search_header_id).column_defs;
        await get_advance_search(params, this.props.user_login_details.user_data.data.token_details.access_token).then(res => {
            const state_object = { rows: [], is_loading: false };
            state_object['column'] = column_def;
            if (res.data && res.data.data && res.data.data.result && res.data.data.result.length > 0) {
                let length = 0;
                const result = res.data.data.result.map((item) => {
                    const text_length = content_length(item.description);
                    if (text_length > length) {
                        length = text_length;
                    }
                    return item;
                });
                state_object.rows = res.data.data.result;
                column_def = column_def.map(item => {
                    if (item.field == 'description') {
                        return { ...item, minWidth: content_width(length) };
                    }
                    return item;
                })
                state_object['column'] = column_def;
            }
            this.setState(state_object);
        });
    }

    closeModal = () => {
        // e.preventDefault();
        this.setState({
            showModal: false,
            grid_config: {},
            form_data: {
                icd10_code: '',
                description: '',
                search_option: '1',
                icd9_code: ''
            },
            error_message: '',
            rows: null,
            is_grid_visible: false,
            is_loading: false
        });
        if (typeof this.props.onCloseModal !== 'undefined') {
            this.props.onCloseModal();
        }

    }

    clearFormHandler = (e) => {
        this.setState({
            form_data: {
                icd10_code: '',
                description: '',
                search_option: '1',
                icd9_code: ''
            },
            error_message: '',
            rows: null,
            is_grid_visible: false,
            is_loading: false
        });
    }

    handleChange = (e) => {
        const name = e.target.name;
        let input_val = e.target.value;
        const form_updated_data = Object.assign({}, this.state.form_data, { [name]: input_val });
        this.setState({ form_data: form_updated_data });
    }

    onSelection = (selectedRows) => {
        //this.props.onGridRowSelection(selectedRows);
        //this.closeModal();
    }

    onDoubleClick = (selectedRows) => {
        selectedRows.selected_icd_type = this.icd_type
        var duplicate = this.props.onGridRowSelection(selectedRows);
        if (!duplicate) {
            this.closeModal();
        }
       
    }

    input_key_press = (event) => {

        // (event.target.type == 'button' && event.target.innerText == 'Clear') 
        // search event must not happen when clear keyCode tapped is 13
        if (event.which == 13 &&
            (!(event.target.type == 'button' && event.target.innerText == 'Clear')) && // must not be a clear button
            (!(event.target.parentElement.parentElement.classList.contains('pagination'))) // must not be a element of pagination
        ) {
            if (this.props.keyPress) {
                var data = this.state.form_data;// add page size property
                data['page_size'] = global_constants.constants.PAGE_SIZE;
                data['is_active'] = true;
                if (event.target.id == 'dx9') {
                    data['code'] = data['icd9_code'];
                    data['icd_type'] = 1;
                } else {
                    data['code'] = data['icd10_code'];
                    data['icd_type'] = 0;
                }
                //this.props.keyPress(data, event);
                this.on_search_api_call(data);
            }
        }
    }

    //key_tab_press = (event, type) => {
    //    if (event.keyCode === 13) {  // on enter event
    //        if (type == 'open') {
    //            this.controlHandle(event);
    //        } else if (type == 'Search') {
    //            this.getHandler(type, event);
    //        }
    //    }

    //}

    handle_search_gem_tab = (event) => {

        // if grid is not empty then focus should move on cross icon.
        if (!this.state.rows || (this.state.rows.length == 0)) {
            handle_focus_on_tab(event, "diagnosis_advance_model_close_button");
        }
    }

    on_grid_out = () => {
        set_focus_on_element_with_id("diagnosis_advance_model_close_button");
    }

    render() {
        const form_fields = this.state.control_data ? this.state.control_data.inputFields : null;
        const { rows } = this.state.grid_config;

        const grid_config = { ... this.state.grid_config };
        const that = this;
        return (
            <>
                <button type="button" className="searchIcon" disabled={this.state.disabled_element} onClick={this.controlHandle} >
                    <Icon aria-hidden="true" name='search' />
                </button>
                    <Modal
                        onClose={this.closeModal}
                        centered={false}
                        className=" default-modal"
                        open={this.state.showModal}
                        closeIcon={true}
                        id="diagnosis_advance_seach_model_pop"

                        closeOnDimmerClick={false}
                    >
                        <Modal.Header><div>
                            <h3>Search for an ICD-10 Code</h3>
                        </div></Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                            <Dimmer active={this.state.is_loading}><Loader size='massive' /></Dimmer>
                            <div onKeyPress={(e) => that.input_key_press(e)}>

                                <Form >
                                    <div className="common-forms" style={{ padding: '5px 7px' }}>
                                        <Grid>
                                            <Grid.Column tablet={8} computer={8} >
                                                <Form.Field>
                                                    <label>ICD-10 Code</label>
                                                <input type="text" id="dx10" name="icd10_code" value={this.state.form_data.icd10_code}
                                                        maxLength={25} onChange={that.handleChange}  />                                                    
                                                </Form.Field>
                                            </Grid.Column>

                                            <Grid.Column tablet={8} computer={8} textAlign="right" >
                                                <Form.Field>
                                                    <label>&nbsp;</label>
                                                <Button type="button" onClick={(e) => that.clearFormHandler(e)} basic >Clear</Button>
                                                <Button type="button" id="search_btn" onClick={(e) => that.dx_search(false)} primary >Search</Button>                                                    
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid >
                                        <Grid>
                                            <Grid.Column tablet={16} computer={16} >
                                                <Form.Field>
                                                    <label>Code Description</label>
                                                    <input type="text" id="dx10" name="description" value={this.state.form_data.description}
                                                        maxLength={255} onChange={that.handleChange}  />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid>
                                    </div>
                                    <div style={{ padding: '20px 0px 0px 0px' }}>
                                        <Grid>
                                            <Grid.Column tablet={16} computer={16} >
                                                <Form.Field>
                                                    <h3><b>Select the Search Method</b></h3>
                                                    <label>Search for <b>Description(s)</b> that:</label>
                                                </Form.Field>
                                          
                                                <div className="ui radio radio-large-text">
                                                <input type="radio" checked={this.state.form_data.search_option === "1"} onChange={that.handleChange} value="1" name="search_option" />
                                                <label>Contain all the words you enter. To find an exact phrase, enclose it in quotation marks. For example, to find <strong>knee pain</strong> enclose it in quotation marks: " knee pain". To just find the words <strong>knee</strong> and <strong>pain</strong> anywhere in the description, don't use quotation marks.</label>
                                            </div>
                                                <div className="ui radio radio-large-text">
                                                    <input type="radio" checked={this.state.form_data.search_option === "2"} onChange={that.handleChange} value="2" name="search_option" />
                                                    <label>Start with this word or phrase.</label>
                                                </div>
                                                <div className="ui radio radio-large-text">
                                                    <input type="radio" checked={this.state.form_data.search_option === "3"} onChange={that.handleChange} value="3" name="search_option" />
                                                    <label> Contain <i>only</i> this word or phrase (exact match).</label>
                                                </div>
                                            </Grid.Column>
                                        </Grid>
                                        <Grid>
                                            <Grid.Column tablet={16} computer={16} >
                                                <Form.Field>
                                                    <h3><b>Alternate Search Method</b></h3>
                                                    <label>Use GEMs (Medicare's tool) to suggest possible ICD-10 alternatives for ICD-9 codes:</label>
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid>
                                    </div>
                                    <div className="common-forms mar-t-15" style={{ padding: '5px 7px' }}>
                                        <Grid>
                                            <Grid.Column tablet={8} computer={8} >
                                                <Form.Field>
                                                    <label>ICD-9 Code</label>
                                                    <input type="text" id="dx9" name="icd9_code" value={this.state.form_data.icd9_code}
                                                        maxLength={25} onChange={that.handleChange} />
                                                </Form.Field>
                                            </Grid.Column>

                                            <Grid.Column tablet={8} computer={8} textAlign="right" >
                                                <Form.Field>
                                                    <label>&nbsp;</label>
                                                    <Button type="button" id="icd9_search_btn" onClick={(e) => that.dx_search(true)} onKeyDown={this.handle_search_gem_tab} primary >Search GEMs</Button>
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid >
                                        </div>
                                    </Form>
                                <br></br>
                                <div className="mar-t-15">
                                    {this.state.rows != null ?
                                        <GridView
                                            id={'grid_id_diagnosis'}
                                            column={this.state.column}
                                            row={this.state.rows}
                                            isPagination={true}
                                            gridAutoHeight={true}
                                            selectionType={'single'}
                                            paginationPageSize={this.state.paginationPageSize}
                                            loadingMessage={' '}
                                            onRowDoubleClicked={this.onDoubleClick}
                                            onGridOut={this.on_grid_out}
                                            headerIdForTabNavigation={diagnosis_constant.diagnosis_search_header_id}/>
                                        : ''}
                                </div>

                                </div>



                            </Modal.Description>

                        </Modal.Content>
                    </Modal>
                    
            </>
        )
    }

}

const mapDispatchToProps = (dispatch) => {
    return { get_control_list: (control_id, token) => dispatch(get_control_list(control_id, token)) }
};

const mapSTateToProps = (state) => {
    return {
        advance_control_state: state.advanced_control_details,
        user_login_details: state.user_login_details,
    };
}

export default connect(mapSTateToProps, mapDispatchToProps)(DiagnosisAdvancedSeach);