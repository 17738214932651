import * as moment from 'moment';
import * as React from 'react';
import { amount_formatter } from '../../shared/utility';
import { get_cc_number, get_money_type_text, get_place_of_service, get_provider } from '../utility';
import { payment_const } from '../payment_constants';

class ItemInactiveComponent extends React.Component<any, any> {
    [x: string]: any;

    // Get innitalise data when component created
    constructor(props) {
        super(props);
    }

    is_checked = () => false;

    get_date = (date, pattern) => {
        if (date) {
            return moment(date).format(pattern);
        }
        return '';
    }

    render_checkbox = () => {
        if (this.props.data.is_active && this.props.data.is_active == true) {
            return (<input type="checkbox"
                id={`id_check_credit_item_${this.props.row_num}`}
                disabled={true}
                checked={false}
            />);
        }
        return null;
    }

    //Render function
    render() {
        const { data } = this.props;
        return (<tr>
            <td className="text-center" style={{ width: '50px' }}>
                {this.render_checkbox()}
            </td>
            <td data-label="date">
                {this.get_date(data.date_of_service, 'MM/DD/YYYY')}
            </td>
            <td data-label="date">
                {this.get_date(data.deposit_date, 'MM/DD/YYYY')}
            </td>
            <td data-label="transaction-type" >
                {data.patient_credit_type && data.patient_credit_type.credit_type || ''}
            </td>
            <td data-label="payment-code">
                {data['payment_code'].payment_code}
            </td>
            <td data-label="unallocated-amount" className='text-right'>{amount_formatter(data.amount)}</td>
            <td data-label="total-payment" className='text-right'>{amount_formatter(data.original_amount)}</td>
            <td data-label="payment-method">
                {get_money_type_text(data.payment_money_type, payment_const.all_payment_method)}
            </td>
            <td data-label="credit-card">
                {data.cc_num || data.check_num || ''}
            </td>
            <td data-label="place-service">
                {get_place_of_service(data.place_of_service)}
            </td>
            <td data-label="provider">
                {get_provider(data.billing_provider)}
            </td>
            <td data-label="insurance">
                {data.insurance_name || ''}
            </td>
            <td data-label="comment">
                {data['comment'] || ''}
            </td>
            {this.props.addBatchNum &&
                <td data-label="batch-num">
                    {data['batch_Num'] || ''}
                </td>
            }
            <td data-label="ceated-date" >{this.get_date(data.created_on, 'MM/DD/YYYY')}</td>
            <td data-label="created-by">{data.created_by || ''}</td>
            <td data-label="date-updated" >{this.get_date(data.updated_on, 'MM/DD/YYYY')}</td>
            <td data-label="updated-by" >{data.updated_by || ''}</td>
        </tr>);
    }
};

export default ItemInactiveComponent;