import { date_format } from '../../global_constants';
import { custom_date_format, fixSSN } from './../../shared/utility';

// Patient search data
export const format_patient_search = response => {
    if (response && response.data && response.data.data && response.data.data.result && response.data.data.result.length > 0) {
        return response.data.data.result.map((item) => {
            const { name, id, date_of_birth, ssn, alt_record_id, address, is_active } = item;
            item.date_of_birth = custom_date_format(date_of_birth, date_format["mm/dd/yyyy"]);
            item.ssn = fixSSN(ssn);
            item.first_name = name && getString(name.first_name) || '';
            item.middle_initial = name && getString(name.middle_initial) || '';
            item.last_name = name && getString(name.last_name) || '';
            item.alt_record_id = getString(alt_record_id);
            item.id = getString(id);
            item.address1 = address && getString(address.address1) || '';
            item.city = address && getString(address.city) || '';
            item.state_id = address && getString(address.state_id) || '';
            item.zip = address && getString(address.zip) || '';
            item.is_active = getStatus(is_active);
            return item;
        });
    }
    return [];
}

const getStatus = (value) => {
    if (value === true) {
        return 'Active'
    } else {
        return 'Inactive'
    }
}

const getString = (value) => {
    return value && value.toString().trim() || '';
}
