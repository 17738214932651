import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Dimmer, Form, Grid, Loader } from "semantic-ui-react";
import { set_focus_on_element_with_id, set_focus_to_app_header } from '../../shared/tab_navigation_utility';
import * as export_print_utility from '../../reports/util/export_print_utility';
import * as moment from 'moment';
import { isMoment } from 'moment';
import * as billing_constant from '../billing_constants';
import DateRangePickerComponent from '../../shared/component/date_range_component';
import Selection from '../../shared/component/selection_component'
import GridView from '../../shared/component/grid';
import CheckboxComponent from '../../shared/component/checkbox_component';
import { custom_date_format, get_all_error, get_columns, strip,  } from '../../shared/utility';
import { toastr } from 'react-redux-toastr';
import { date_format, toastr_options } from '../../global_constants';
import { user_parent_companies } from '../../user/action/user_action';
import * as report_constants from '../../reports/report_constants';
import { get_open_claims_search, update_open_claims_data, close_open_claims } from '../action/open_claims_action';
import * as session_storage from '../../shared/session_storage_utility';
import * as ReportConstants from '../../reports/report_constants';
import { providerBillingSettingsFeatures,  } from '../../admin/constants/constants';

export class OpenClaimsComponent extends React.Component<any, any> {
    constructor(props) {
        super(props);        
        this.state = {
            loading: false,
            show_grid: false,
            is_error: false,
            is_search_disabled: false,
            is_close_disabled: false,
            grid_params: [],
            parent_company_list: [],
            title: 'Open Claims',
            grid_rows_count: 0,
            pop_up_row: null,
            search_criteria: {
                from_date: null,
                to_date: null,
                parent_company_id: -1,
                parent_company_name: 'All'
            }
        };
        this.handle_print = this.handle_print.bind(this);
        this.set_title = this.set_title.bind(this);
        this.handle_date_change = this.handle_date_change.bind(this);
        this.handle_clear = this.handle_clear.bind(this);
        this.create_parent_company_listings = this.create_parent_company_listings.bind(this);
    }
    _is_mounted = false;
    total_column_width: number = 0;
    grid_header_height: number = 0;
    width_padding: number = 100;
    selected_claims: any = [];
    grid_data: any = {
        rows: [],
        grid_conf: billing_constant.open_claims_setting_columns,
        footer_object: {}
    };
    print_search_criteria: {
        parent_company_id: '',
        parent_company_name: '',
        from_date: '',
        to_date: ''
    }
    reportId: string = '0';

    componentDidMount = async () => {
        this._is_mounted = true;
        document.body.classList.add('admin-framework');
        let token = this.props.user_login_details.user_data.data.token_details.access_token;
        let soft_only = (this.props.user_login_details.user_data.data.user_type_id === 1 || this.props.user_login_details.user_data.data.user_type_id === 2) ? false : true;

        let open_claims_search = session_storage.get(`open_claims_criteria`);
        
        if (open_claims_search && open_claims_search.search_criteria) {
            if (open_claims_search.search_criteria.software_only) {
                soft_only = true
            }
            let updatedCriteria = { ...open_claims_search.search_criteria, from_date: open_claims_search.search_criteria.from_date ? moment(custom_date_format(open_claims_search.search_criteria.from_date, date_format["mm/dd/yyyy"])) : null, to_date: open_claims_search.search_criteria.to_date ? moment(custom_date_format(open_claims_search.search_criteria.to_date, date_format["mm/dd/yyyy"])) : null }
            this.setState({ search_criteria: updatedCriteria })
            this.handle_search();
        }
        this.fetch_parent_companies(soft_only, token); 
    }

    componentWillUnmount = () => {
        document.body.classList.remove('admin-framework');
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[2] !== 'open_claims' && session_storage.remove('open_claims_criteria');
        }
    }

    fetch_parent_companies = async (software_only ,token) => {
        await this.props.user_parent_companies(software_only, token);
        let response = this.props.user_parent_companies_response;
        if (response && response.status == 1) {
            this.create_parent_company_listings(response.data);
        } else {
            if (response && response.data) {
                const toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                toastr.error('', toastr_options);
            }
        }
        this.setState({ loading: false })
    }

    create_parent_company_listings = (data) => {
        let company_listings = data.map(function (val) {
            return { key: val.company_id, value: val.company_id, text: val.company_name };
        })

        if (data.length > 0) {
            this.setState({
                parent_company_list: company_listings,
                search_criteria: { ...this.state.search_criteria, parent_company_id: data[0].company_id, parent_company_name: data[0].company_name }
            })
        } else {
            this.setState({
                parent_company_list: company_listings
            })
        }
    }

    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }

    set_title = separter => {
        let from = this.print_search_criteria.from_date ? moment(this.print_search_criteria.from_date).format('MM/DD/YYYY') : "01/01/1975";
        let to = this.print_search_criteria.to_date ? moment(this.print_search_criteria.to_date).format('MM/DD/YYYY') : moment(new Date()).format('MM/DD/YYYY');
        let company_name = this.print_search_criteria.parent_company_name;
        let current_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
        let software_only = (this.props.user_login_details.user_data.data.user_type_id === 1 || this.props.user_login_details.user_data.data.user_type_id === 2) ? this.state.search_criteria.software_only ? this.state.search_criteria.software_only : "False" : "True";
        let title = this.state.title.replace(/<br>/g, separter) + separter + 'From Date: ' + from + separter + 'Through Date: ' + to + separter + 'Parent Company: ' + company_name + separter + 'Software Only: ' + software_only + separter + current_date + separter +
            (this.state.grid_rows_count == 1
                ? this.state.grid_rows_count + ' record'
                : this.state.grid_rows_count + ' records') +
            separter +
            separter;
        return title;
    };

    handle_print = () => {
        export_print_utility.print_grid_data(this.state.grid_params, this.set_title('<br>'), 'Open Claims', 'print_open_claims_button');
        this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
    };

    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;
        const payload: ReportConstants.IPayloadForBillingAudit = {
            reportId: this.reportId,
            contextTitle: "Open Claims" + " - " + ReportConstants.ReportEventAction[eventActionId],
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: ReportConstants.ReportEntityTypeId.D_Charge,
            data: { Records: this.state.grid_rows_count }
        }
        const reqBody = export_print_utility.generateAuditDataEntryPayload(payload);
        ReportConstants.saveAuditPrintExport(reqBody, accessToken);
    };

    update_report_rows_count = () => {
        this.setState({
            grid_rows_count: this.state.grid_params.api.getDisplayedRowCount()
        });

        var grid_width = document.getElementsByClassName('admin-wrapper')[0].clientWidth;

        if (this.total_column_width > (grid_width - this.width_padding)) {
            this.grid_data.grid_conf.filter(item => {
                this.grid_data.grid_conf.setColumnWidth(item.field, item.width, false);
            });
        }
    };

    get_grid_ref = grid_params => {
        this.setState({
            grid_params,
            grid_rows_count: grid_params.api.getModel().getRowCount()
        });
        var grid_width = document.getElementsByClassName('admin-wrapper')[0].clientWidth;
        if (this.total_column_width > (grid_width - this.width_padding)) {
            this.grid_data.grid_conf.filter(item => {
                if (item.field=='notes') {
                    grid_params.columnApi.setColumnWidth('notes', 250, false);
                } else {
                    grid_params.columnApi.setColumnWidth(item.field, item.width, false);

                }
            });
        }
    };

    on_grid_out = () => {
        set_focus_on_element_with_id('app_header_dropdown');
    };

    handle_date_change = (startDate, endDate) => {
        this.setState(prevState => ({
            search_criteria: { ...prevState.search_criteria, from_date: startDate, to_date: endDate },
            is_error: startDate ? (isMoment(startDate) ? false : true) : false || endDate ? (isMoment(endDate) ? false : true) : false
        }));
    }

    handle_change = (val, value) => {
        this.setState(prevState => ({
            search_criteria: { ...prevState.search_criteria, [val]: (val === "software_only" ? value.target.checked : value) }
        }));

        if (val === "parent_company_id") {
            let updatedCompanyName = this.state.parent_company_list.find(item => item.value.toString() === value.toString()).text;
            this.setState(prevState => ({
                search_criteria: { ...prevState.search_criteria, parent_company_name: updatedCompanyName }
            }));
        }

        if (val === "software_only") {
            this.setState({ loading: true })
            let token = this.props.user_login_details.user_data.data.token_details.access_token;
            this.fetch_parent_companies(value.target.checked, token);
        }
    };

    // Select open claims to close 
    on_check_box_selection = (items) => {
        this.selected_claims = items;
    };

    handle_close_claims = async () => {
        if (this.selected_claims && this.selected_claims.length == 0) {
            toastr.warning('', billing_constant.error_messages.select_single_claim);
            return;
        }
        if (this.selected_claims && this.selected_claims.length > 0) {
            // Create Query Param from checked items
            const params = this.selected_claims.map((value) => {
                return { Run_comp_id: value.run_comp_id, Notes: value.notes };
            });
            this.setState({
                loading: true,
                is_search_disabled: true,
                is_close_disabled: true
            });
            await close_open_claims(params).then(
                (response) => {
                    if (response.data && response.data.status === 1) {
                        let toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                        toastr.success("", toastr_options);
                        this.selected_claims=[]
                        this.setState({
                            loading: false,
                            is_search_disabled: false,
                            is_close_disabled: false
                        });
                        this.handle_search();
                    } else {
                        if (response.data.messages && response.data.messages[0].message.indexOf("<br") > -1) {
                            let toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                            toastr.error("", toastr_options);
                        }
                        this.selected_claims = [];
                        this.setState({
                            loading: false,
                            is_search_disabled: false,
                            is_close_disabled: false
                        });
                    }
                },
                (error) => {
                    this.setState({
                        loading: false,
                        is_search_disabled: false,
                        is_close_disabled: false
                    });
                    if (error.response.data) {
                        const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                        toastr.error("", toastr_options);
                    }
                }
            );
        }
    };

    handle_clear = () => {
        this.setState({
            show_grid: false,
            is_search_disabled: false,
            is_close_disabled: false,
            search_criteria: {
                from_date: null,
                to_date: null,
                parent_company_id: -1,
                parent_company_name: 'All'
            },
            grid_rows_count: 0,
            loading: false,
            is_error: false
        });
        this.grid_data = {
            rows: [],
            grid_conf: billing_constant.open_claims_setting_columns
        }
        this.selected_claims = []
        if (this.props.user_login_details.user_data.data.user_type_id === 1 || this.props.user_login_details.user_data.data.user_type_id === 2) {
            this.setState({ loading: true })
            let token = this.props.user_login_details.user_data.data.token_details.access_token;
            this.fetch_parent_companies(false, token);
        }
        session_storage.remove(`open_claims_criteria`);
    }

    //Function used to initialize the footer object.
    initialize_footer_obj = (column_def) => {
        var footer_obj = {};
        column_def.map((item, index) => {
            if (index == 1) {
                footer_obj[item.field] = "Total";
            } else if (item.type == "currency") {
                footer_obj[item.field] = 0;
            } else {
                footer_obj[item.field] = null;
            }
        });
        return footer_obj;
    };

    row_data_formatter = (data,grid_column,is_detail_grid=false) => {
        let result = {
            data: [],
            footer_obj: {}
        };
        let footer_obj = this.initialize_footer_obj(grid_column);
       
        data.map(row => {
            grid_column.map((value) => {
                if (is_detail_grid) {
                    row['notes'] = "";
                    row['checkbox'] = '';
                }

                switch (value.type) {
                    case 'date':
                        row[value.field] = row[value.field] ? custom_date_format(row[value.field], date_format["mm/dd/yyyy"]) : "";
                        break;
                    case 'time':
                        row[value.field] = row[value.field] ? custom_date_format(row[value.field], 'h:MM:ss TT') : "";
                        break;
                    case 'number':
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined
                                ? parseInt(row[value.field])
                                : 0;
                        break;
                    case 'boolean':
                        row[value.field] = row[value.field] ? "Active" : "Inactive";
                        break;
                    case "currency":
                        footer_obj[value.field] += row[value.field] ? parseFloat(row[value.field]) : 0;
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined
                            ? report_constants.currencyFormatter(row[value.field])
                            : report_constants.currencyFormatter(0);
                        break;
                    case "link":
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined ? row[value.field].toString() : "";
                        break;
                    case "hyper_link":
                        row[`link_${value.field}`] = strip(row[value.field]);
                        break;
                    default:
                        row[value.field] = row[value.field] ? row[value.field].toString().trim() : "";
                }
            });
            return row;
        });

        grid_column.map((value) => {
            if (value.type == "currency") {
                footer_obj[value.field] = report_constants.currencyFormatter(footer_obj[value.field]);
            }
        });

        result.data = data;
        result.footer_obj = footer_obj;
        return result;
    }

    handle_search = async (event = null) => {
        if (!this.state.search_criteria.parent_company_id) {
            toastr.error('', 'Please select a Parent Company.');
            return;
        }
       
        this.setState({
            is_search_disabled: true,
            is_close_disabled: true,
            loading: true,
            show_grid: false
        });

        this.print_search_criteria = { ...this.state.search_criteria };
        this.grid_data = {
            rows: [],
            grid_conf: billing_constant.open_claims_setting_columns
        }
        this.selected_claims = []
        await this.props.update_open_claims_data();

        if (this.state.is_error && event) {
            event.preventDefault();
        } else {
            var token = this.props.user_login_details.user_data.data.token_details.access_token;
            let criteria = this.state.search_criteria
            let updated_criteria = {
                from_date: criteria.from_date ? custom_date_format(criteria.from_date, date_format["mm/dd/yyyy"]) : null,
                to_date: criteria.to_date ? custom_date_format(criteria.to_date, date_format["mm/dd/yyyy"]) : null,
                parent_company_id: parseInt(criteria.parent_company_id),
                software_only: (this.props.user_login_details.user_data.data.user_type_id === 1 || this.props.user_login_details.user_data.data.user_type_id === 2) ? criteria.software_only ? criteria.software_only : false : true
            }

            await this.props.get_open_claims_search(updated_criteria, token);
            let response = this.props.billing_reducer_reference;

            if (response && response.status == 1) {
                if (response.data && response.data.length > 0) {
                    let formated_date = this.row_data_formatter(response.data, this.grid_data.grid_conf,true);
                    this.grid_data.rows = formated_date.data;
                    this.grid_data.footer_object = formated_date.footer_obj;
                }
                session_storage.set(`open_claims_criteria`, {
                    search_criteria: this.state.search_criteria
                });
                const grid_height = get_columns(this.grid_data.rows, this.grid_data.grid_conf, this.grid_data.footer_object);
                this.total_column_width = grid_height.total_column_width;
                this.grid_header_height = grid_height.header_height;
            } else {
                if (response && response.data) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                    toastr.error('', toastr_options);
                }
            }
        }
        this.setState({
            is_search_disabled: false,
            is_close_disabled: false,
            loading: false,
            show_grid: true,
            grid_rows_count: this.grid_data.rows.length
        });

    }

    //Function calls after click of filter button and recalculate the footer sum.
    on_filter_button_click = () => {
        var footer_obj = this.initialize_footer_obj(billing_constant.open_claims_setting_columns);
        var cell_data;
        var row_api = this.state.grid_params.api;
        if (row_api) {
            row_api.forEachNodeAfterFilter((row, index) => {
                row = row.data;
                billing_constant.open_claims_setting_columns.map((value, index) => {
                    switch (value.type) {
                        case 'currency':
                            if (row[value.field].indexOf('(') != -1) {
                                cell_data = row[value.field] ? '-' + row[value.field].substring(2, row[value.field].length - 1) : 0;
                            } else {
                                cell_data = row[value.field] ? row[value.field].substring(1, row[value.field].length) : 0;
                            }
                            footer_obj[value.field] += isNaN(parseFloat(cell_data)) ? 0 : parseFloat(cell_data.split(',').join(''));
                            break;
                        default:
                    }
                });
            });

            billing_constant.open_claims_setting_columns.map((value, index) => {
                if (value.type == 'currency') {
                    footer_obj[value.field] = report_constants.currencyFormatter(footer_obj[value.field]);
                }
            });
            row_api.setPinnedBottomRowData([footer_obj]);
        }
    };
    
    grid_height = (params) => {
        if (!params.node.isRowPinned()) {
            return 50;
        } else {
            return 38;
        }       
    }

    render() {
        const { loading, show_grid, is_error, search_criteria, parent_company_list, is_search_disabled, is_close_disabled, grid_rows_count } = this.state
        return (
            <React.Fragment>
                <Dimmer active={loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <div className='admin-wrapper' style={this.grid_data.rows.length != 0 ? { paddingBottom: 20 } : {}}>
                    <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                        <Grid.Column computer={16}>
                            <h3 className='ui header left aligned'>Open Claims</h3>
                        </Grid.Column>
                    </Grid>
                    <div id='admin-scrollable-area' className='wrapper' style={show_grid ? { flex: 1 } : {}}>
                        <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container'>
                            <Form id="open_claims_container" autoComplete='off' onSubmit={e => this.handle_search(e)}>
                                <Grid style={{ marginBottom: 0 }}>
                                    <Grid.Column computer={16} >
                                        <Grid>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field >
                                                    <label>Parent Company</label>
                                                    <Selection
                                                        placeHolder='Select'
                                                        name='open_claims_company'
                                                        id="open_claims_company"
                                                        options={parent_company_list}
                                                        onChange={(value) => this.handle_change('parent_company_id', value)}
                                                        defaultValue={search_criteria.parent_company_id}
                                                        disabled={false}
                                                        hidden={true}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label>Date Range
                                                    </label>
                                                    <DateRangePickerComponent
                                                        updateDatesChange={this.handle_date_change}
                                                        startDate={search_criteria.from_date}
                                                        endDate={search_criteria.to_date}
                                                        error={is_error}
                                                        maxDate={moment()}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            {(this.props.user_login_details.user_data.data.user_type_id === 1 ||
                                                this.props.user_login_details.user_data.data.user_type_id === 2) &&
                                                <Grid.Column tablet={8} computer={4}>
                                                    <Form.Field style={{ marginTop: 32 }}>
                                                        <CheckboxComponent
                                                            name='software_only'
                                                            id='software_only'
                                                            onChange={e => this.handle_change("software_only", e)}
                                                            checked={search_criteria.software_only ? search_criteria.software_only : false}
                                                            label={'Software Only'}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>}

                                        </Grid>
                                    </Grid.Column>
                                </Grid>
                                <Grid style={{ margin: '0 -17px' }}>
                                    <Grid.Column computer={16} textAlign='right'>
                                        <Button type="button" id="clear_form" onClick={this.handle_clear} basic >Clear</Button>
                                        <Button id="open_claims_search" disabled={is_search_disabled} type='submit' primary>Search</Button>
                                    </Grid.Column>
                                </Grid>
                                {show_grid && (
                                    <Grid style={{ marginTop: '-1rem', marginBottom: 0 }}>
                                        <Grid.Column tablet={5} computer={4} textAlign='left'>
                                            <p style={{ fontSize: '16px' }}>Search Results</p>
                                        </Grid.Column>
                                        <Grid.Column tablet={5} computer={8} textAlign='center'>
                                            {
                                                <p style={{ fontSize: '16px', minHeight: 22 }}>
                                                    {`${grid_rows_count} ${grid_rows_count == 1 ? ' record shown' : ' records shown'
                                                        }`}
                                                </p>
                                            }
                                        </Grid.Column>
                                        <Grid.Column tablet={2} computer={4} textAlign='right' />
                                    </Grid>
                                )}
                            </Form>
                        </div>
                        {show_grid && (
                            <GridView
                                id="open_claims"
                                row={this.grid_data.rows}
                                column={this.grid_data.grid_conf}
                                style={{ height: '100%' }}
                                wrapperStyle={{ width: '100%', height: '100%', display: 'flex' }}
                                suppressMovableColumns={false}
                                enableColResize={true}
                                selectionType={'single'}
                                checkboxSelection={true}
                                gridAutoRowHeight={true}
                                onRowSelection={(item) => this.on_check_box_selection(item)}
                                get_grid_ref={this.get_grid_ref}
                                suppressSizeToFit={true}
                                headerHeight={this.grid_header_height}
                                headerIdForTabNavigation={billing_constant.search_grid_id}
                                onForceGridOut={this.on_grid_out}
                                on_filter_button_click={() => {
                                    this.on_filter_button_click();
                                    this.update_report_rows_count();
                                }}
                                pinnedBottomRowData={[this.grid_data.footer_object]}
                                getRowHeight={this.grid_height}
                            />
                        )}
                    </div>
                    {show_grid > 0 && (
                        <div className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area' id='applicationFooterSticky' style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <Grid.Column computer={16} textAlign='right'>
                                <Button
                                    onKeyDown={set_focus_to_app_header}
                                    id='print_open_claims_button'
                                    type='button'
                                    onClick={this.handle_print}
                                    basic
                                >
                                    Print
                                </Button>
                                <Button primary id='close_open_claims_button' type='button' disabled={is_close_disabled} style={{ marginRight: 0 }} onClick={this.handle_close_claims}>
                                    Close
                                </Button>
                            </Grid.Column>
                        </div>
                    )}
                </div>                
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        user_parent_companies: user_parent_companies,
        get_open_claims_search: get_open_claims_search,
        update_open_claims_data: update_open_claims_data
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        user_parent_companies_response: state.user_details.user_parent_companies_response,
        billing_reducer_reference: state.billing_reducer_reference.open_claims_search_data,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OpenClaimsComponent));