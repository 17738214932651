import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Dimmer, Form, Grid, Loader } from "semantic-ui-react";
import * as report_constants from '../../../reports/report_constants';
import Selection from '../../../shared/component/selection_component'
import * as constants from '../constants';
import GridView from '../../../shared/component/grid';
import * as moment from 'moment';
import { isMoment } from 'moment';
import { custom_date_format, get_all_error, get_columns} from '../../../shared/utility';
import { date_format, toastr_options } from '../../../global_constants';
import { get_scheduled_month_end_search, cancel_scheduled_month_end_job, initiate_scheduled_month_end_job} from '../action/constants_action';
import { user_companies } from '../../../user/action/user_action';
import { get_lauch_darkley_key_value } from '../../../shared/utility';
import { toastr } from 'react-redux-toastr';
import { set_focus_on_element_with_id, set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import * as export_print_utility from '../../../reports/util/export_print_utility';
import ConfirmationComponent from '../../../shared/component/confirmation_component';
import DateRangePickerComponent from './../../../shared/component/date_range_component';
import * as session_storage from '../../../shared/session_storage_utility';
import { MonthEndScheduleFeature } from '../../../admin/constants/constants';

export class ScheduleMonthEndSearchComponent extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            is_search_disabled: false,
            status_list: this.props.features.isMonthEndQueuedEnabled ? constants.schedule_month_end_status_ff_on : constants.schedule_month_end_status,
            company_list: [],
            search_criteria: {
                status: 'all',
                company_id: (props.history.location.state && props.history.location.state.company_id) ? props.history.location.state.company_id : props.user_login_details.user_data.data ? props.user_login_details.user_data.data.company_id : -1,
                company_name: (props.history.location.state && props.history.location.state.company_name) ? props.history.location.state.company_name : props.user_login_details.user_data.data ? props.user_login_details.user_data.data.company_name : "All",
                from_date: moment().subtract(1, 'day'),
                to_date: moment(custom_date_format(new Date(), date_format["mm/dd/yyyy"])),
                status_name:"All"
            },
            show_grid: false,
            is_error: false,
            title: 'Schedule Month End',
            grid_params: [],
            grid_rows_count: 0,
            show_cancel_modal: false,
            show_initiate_modal: false,
            selectedRow: {}           
        };

        this.handleSearch = this.handleSearch.bind(this);
        this.createNewScheduleJob = this.createNewScheduleJob.bind(this);
        this.createCompanyListings = this.createCompanyListings.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleExport = this.handleExport.bind(this);
        this.setTitle = this.setTitle.bind(this);
        this.handlePrint = this.handlePrint.bind(this);
        this.rowDataFormatter = this.rowDataFormatter.bind(this);  
        this.handleDateChange = this.handleDateChange.bind(this);
    }
    total_column_width: number = 0;
    grid_header_height: number = 0;
    width_padding: number = 100;
    reportId: string = "0";
    print_search_criteria: {
        status: '',
        company_id: '',
        company_name: '',
        from_date: '',
        to_date: '',
        status_name:''
    }
    grid_data: any = {
        rows: [],
        grid_conf: (this.props.user_login_details.user_data.data.user_type_id === 1 ||
            this.props.user_login_details.user_data.data.user_type_id === 2) ?
            constants.search_schedule_month_configuration.column_defs
            : constants.search_schedule_month_configuration.column_defs.filter(data => data.field !== "initiate_job")
    };
  
    componentDidMount = async () => {
        let token = this.props.user_login_details.user_data.data.token_details.access_token;
        document.body.classList.add('admin-framework');
        await this.props.user_companies(true, token);
        let response = this.props.user_companies_response;
        if (response && response.status == 1) {
            this.createCompanyListings(response.data);
        } else {
            const toastr_options = this.show_html_content_toaster(get_all_error(response.data));
              toastr.error('', toastr_options);
        }

        let constant_page_search = session_storage.get(`constant_month_end_criteria`);

        if (this.props.history.location.state && this.props.history.location.state.prevPath && (this.props.history.location.state.prevPath !== this.props.location.pathname) && constant_page_search && constant_page_search.search_criteria) {
            let updatedCriteria = { ...constant_page_search.search_criteria, from_date: moment(custom_date_format(constant_page_search.search_criteria.from_date, date_format["mm/dd/yyyy"])), to_date: moment(custom_date_format(constant_page_search.search_criteria.to_date, date_format["mm/dd/yyyy"]))}
            this.setState({ search_criteria: updatedCriteria })
            this.handleSearch();
        }

        if (this.props.history.location.state && this.props.history.location.state.company_id) {
            this.handleSearch();
        }
    }

    componentWillUnmount = () => {
        document.body.classList.remove('admin-framework');
        if (this.props.history.location.state && (this.props.history.location.state.company_id || this.props.history.location.state.company_name)) {
            this.props.history.replace({ state: undefined });
        }
    }

    createCompanyListings = (data) => {
        let company_listings = data.map(function (val) {
            return { key: val.company_id, value: val.company_id, text: val.gpms_code + ' - ' + val.company_name };
        })

        this.setState({
            company_list: company_listings
        })
    }

    handleClear = () => {
        this.setState({
            show_grid: false,
            show_initiate_modal: false,
            show_cancel_model: false,
            search_criteria: {                
                status: 'all',
                company_id: this.props.user_login_details.user_data.data ? this.props.user_login_details.user_data.data.company_id : -1,
                company_name: this.props.user_login_details.user_data.data ? this.props.user_login_details.user_data.data.company_name : "All",
                from_date: moment().subtract(1, 'day'),
                to_date: moment(custom_date_format(new Date(), date_format["mm/dd/yyyy"]))
            },
            grid_rows_count: 0,
            loading: false,
            is_error: false
        });
        this.grid_data = {
            rows: [],
            grid_conf: (this.props.user_login_details.user_data.data.user_type_id === 1 ||
                this.props.user_login_details.user_data.data.user_type_id === 2) ?
                constants.search_schedule_month_configuration.column_defs
                : constants.search_schedule_month_configuration.column_defs.filter(data => data.field !== "initiate_job")
        }
        if (this.props.history.location.state && (this.props.history.location.state.company_id || this.props.history.location.state.company_name || this.props.history.location.state.prevPath)) {
            this.props.history.replace({ state: undefined });
        }
        session_storage.remove(`constant_month_end_criteria`);
    }

    handleDateChange = (startDate, endDate) => {
        this.setState(prevState => ({
            search_criteria: { ...prevState.search_criteria, from_date: startDate, to_date: endDate },
            is_error: (isMoment(startDate) ? false : true) || (isMoment(endDate) ? false : true)
        }));
    }

    handleChange = (val, value) => {
        this.setState(prevState => ({
            search_criteria: { ...prevState.search_criteria, [val]: value }
        }));

        if (val === "company_id") {
            let updatedCompanyName = this.state.company_list.find(item => item.value.toString() === value.toString()).text;
            this.setState(prevState => ({
                search_criteria: { ...prevState.search_criteria, company_name: updatedCompanyName }
            }));
        }
        if (val === "status") {
            let updatedSatusName = this.state.status_list.find(item => item.value.toString() === value.toString()).text;
            this.setState(prevState => ({
                search_criteria: { ...prevState.search_criteria, status_name: updatedSatusName }
            }));
        }
    };

    rowDataFormatter = (data) => {
        data.map(row => {
            this.grid_data.grid_conf.map((value) => {
                switch (value.type) {
                    case 'date':
                        row[value.field] = row[value.field] ? report_constants.dateTimeFormatter(row[value.field]) + ", " + row['user_name'] : "";
                        break;
                    case 'time':
                        row[value.field] = row[value.field] ? custom_date_format(row[value.field], 'h:MM:ss TT') : "";
                        break;
                    case 'number':
                        row[value.field] = row[value.field] ? parseInt(row[value.field]) : "";
                        break;
                    case 'boolean':
                        row[value.field] = row[value.field] ? "Active" : "Inactive";
                        break;
                    default:
                        row[value.field] = row[value.field] ? row[value.field].toString().trim() : "";
                }
            });
            return row;
        });

        return data;
    }

    handleSearch = async (event = null) => {
        if (! this.state.search_criteria.from_date || ! this.state.search_criteria.to_date) {
            toastr.error('', 'Please fill all the mandatory fields or check highlighted field values.');
            return;
        }

        if (this.state.search_criteria.from_date > this.state.search_criteria.to_date) {
            toastr.error('', 'Through Date" must be on or after the "From Date."');
            return;
        }

        this.setState({
            is_search_disabled: true,
            loading: true,
            show_grid : false
        });

        this.print_search_criteria = { ...this.state.search_criteria };
        this.grid_data = {
            rows: [],
            grid_conf: constants.search_schedule_month_configuration.column_defs
        }
        if (this.state.is_error && event) {
            event.preventDefault();
        } else {
            var token = this.props.user_login_details.user_data.data.token_details.access_token;

            await this.props.get_scheduled_month_end_search(this.state.search_criteria, token);
            let response = this.props.constants_reducer_reference;

            if (response && response.status == 1) {
                if (response.data && response.data.length > 0) {
                    this.grid_data.rows = this.rowDataFormatter(response.data);
                } 
                session_storage.set(`constant_month_end_criteria`, {
                    search_criteria: this.state.search_criteria
                });
                const grid_height = get_columns(this.grid_data.rows, this.grid_data.grid_conf);
                this.total_column_width = grid_height.total_column_width;
                this.grid_header_height = grid_height.header_height;
            } else {
                const toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                toastr.error('', toastr_options);
            }
        }
        this.setState({
            is_search_disabled: false,
            loading: false,
            show_grid: true,
            grid_rows_count: this.grid_data.rows.length
        });
        
    }

    createNewScheduleJob = (event) => {
        event.preventDefault();
        this.props.history.push('/admin/schedule_new_job');
    }

    setTitle = separter => {
        let status = this.print_search_criteria.status_name;
        let from = moment(this.print_search_criteria.from_date).format('MM/DD/YYYY');
        let to = moment(this.print_search_criteria.to_date).format('MM/DD/YYYY');
        let company_name = this.print_search_criteria.company_name;

        let current_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
        let title = this.state.title.replace(/<br>/g, separter) + separter + 'Status: ' + status + separter + 'From Date: ' + from + separter + 'Through Date: ' + to + separter + 'Company: ' + company_name + separter + current_date + separter +
            (this.state.grid_rows_count == 1
                ? this.state.grid_rows_count + ' record'
                : this.state.grid_rows_count + ' records') +
            separter +
            separter;
        return title;
    };

    handleExport = () => {        
        export_print_utility.export_grid_data(this.state.grid_params, this.setTitle('\n'), 'Schedule Month End');
        this.saveReportEventAction(report_constants.ReportEventAction.Export);
    };

    handlePrint = () => {
        export_print_utility.print_grid_data(this.state.grid_params, this.setTitle('<br>'), 'Schedule Month End');
        this.saveReportEventAction(report_constants.ReportEventAction.Print);
    };

    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;

        const payload: report_constants.IPayloadForBillingAudit = {
            reportId: this.reportId,
            contextTitle: `Schedule Month End - ${report_constants.ReportEventAction[eventActionId]}`,
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: report_constants.ReportEntityTypeId.A_COMPANY,
            data: { Records: this.state.grid_params.api.rowModel.rowsToDisplay.length }
        };

        const reqBody = export_print_utility.generateAuditDataEntryPayload(payload);

        report_constants.saveAuditPrintExport(reqBody, accessToken);
    };

    getGridRef = grid_params => {
        this.setState({
            grid_params,
            grid_rows_count: grid_params.api.getModel().getRowCount()
        });
        var grid_width = document.getElementsByClassName('admin-wrapper')[0].clientWidth;
        if (this.total_column_width > (grid_width - this.width_padding)) {
            this.grid_data.grid_conf.filter(item => {
                grid_params.columnApi.setColumnWidth(item.field, item.width, false);
            });
        }       
    };

    onGridOut = () => {
        set_focus_on_element_with_id('app_header_dropdown');
    };

    updateReportRowsCount = () => {
        this.setState({
            grid_rows_count: this.state.grid_params.api.getDisplayedRowCount()
        });
        //this.state.grid_params.api.sizeColumnsToFit();
        var grid_width = document.getElementsByClassName('admin-wrapper')[0].clientWidth;

        if (this.total_column_width > (grid_width - this.width_padding)) {
            this.grid_data.grid_conf.filter(item => {
                this.grid_data.grid_conf.setColumnWidth(item.field, item.width, false);
            });
        } 
    };

    onCellClick = async ({ data, column }) => {
        if ((column.colId == 'initiate_job')) {
            if (data.initiate_job == 'Active' && this.props.features.isMonthEndQueuedEnabled) {
                this.setState({ show_initiate_modal: false, selectedRow: data });
                this.initiate_scheduled_month_end_job_queued();
            } else if(data.initiate_job == 'Active') {
                this.setState({ show_initiate_modal: true, selectedRow: data });
            }
        }
        if (column.colId == 'cancel_job') {
            if (data.cancel_job == 'Active') {
                this.setState({ show_cancel_modal: true, selectedRow: data })
            }
        }
    }

    onCloseInitate = () => {
        this.setState({ show_initiate_modal: false})
    }

    onCloseCancel = () => {
        this.setState({ show_cancel_modal: false})
    }

    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }

    cancel_scheduled_month_end_job = () => {
        let token = this.props.user_login_details.user_data.data.token_details.access_token;
        var data = {
            company_id: this.state.selectedRow.company_Id,
            start_date: this.state.selectedRow.date_range_start
        };
        this.onCloseCancel();
        this.setState({
            is_search_disabled: false,
            loading: true
        });
        cancel_scheduled_month_end_job(token, data).then(resp => { 
            if (resp.data.status == 1) {
                this.handleSearch();
            } else {
                this.setState({
                    is_search_disabled: true,
                    loading: false
                });
                const toastr_options = this.show_html_content_toaster(get_all_error(resp.data));
                toastr.error('', toastr_options);
            }
        }, (error) => {
            this.setState({
                is_search_disabled: true,
                loading: false
            });
            const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
            toastr.error('', toastr_options);
        })
    }

    initiate_scheduled_month_end_job = () => {
        let token = this.props.user_login_details.user_data.data.token_details.access_token;
        var data = {
            company_id: this.state.selectedRow.company_Id,
            start_date: this.state.selectedRow.date_range_start
        };
        this.onCloseInitate();
        this.setState({
            is_search_disabled: false,
            loading: true
        });
        initiate_scheduled_month_end_job(token, data).then(resp => {
            if (resp.data.status == 1) {
                this.handleSearch();
            } else {
                this.setState({
                    is_search_disabled: true,
                    loading: false
                });
                const toastr_options = this.show_html_content_toaster(get_all_error(resp.data));
                toastr.error('', toastr_options);  
            }
        }, (error) => {
            this.setState({
                is_search_disabled: true,
                loading: false
            });
            const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
            toastr.error('', toastr_options);
        })
    }

    initiate_scheduled_month_end_job_queued = () => {
        let token = this.props.user_login_details.user_data.data.token_details.access_token;
        var data = {
            company_id: this.state.selectedRow.company_Id,
            start_date: this.state.selectedRow.date_range_start
        };

        initiate_scheduled_month_end_job(token, data).then(resp => {
            this.setState({
                is_search_disabled: false,
                loading: true
            });
            if (resp.data.status == 1) {
                this.handleSearch();
                const toastr_options = this.show_html_content_toaster(get_all_error(resp.data));
                toastr.success('', toastr_options);  
            } else {
                this.setState({
                    is_search_disabled: true,
                    loading: false
                });
                const toastr_options = this.show_html_content_toaster(get_all_error(resp.data));
                toastr.error('', toastr_options);  
            }
        }, (error) => {
            this.setState({
                is_search_disabled: true,
                loading: false
            });
            const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
            toastr.error('', toastr_options);
        })
    }
  
    render() {
        const { loading, search_criteria, status_list, is_error, company_list, is_search_disabled, show_grid, grid_rows_count, show_initiate_modal, show_cancel_modal } = this.state;

        return (
            <React.Fragment>
                <Dimmer active={loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <div className='admin-wrapper' style={this.grid_data.rows.length != 0 ? { paddingBottom: 20 } : {}}>
                    <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                        <Grid.Column computer={16}>
                            <h3 className='ui header left aligned'>Schedule Month End</h3>
                        </Grid.Column>
                    </Grid>
                    <div id='admin-scrollable-area' className='wrapper' style={show_grid ? { flex: 1 } : {}}>
                        <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container'>
                            <Form id="schedule_month_end_container" autoComplete='off' onSubmit={e => this.handleSearch(e)}>
                                <Grid style={{ marginBottom: 0 }}>
                                    <Grid.Column computer={16} >
                                        <Grid>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field >
                                                    <label>Status</label>
                                                    <Selection
                                                        placeHolder='Select'
                                                        name='scheduled_month_job_status'
                                                        id="scheduled_month_job_status"
                                                        options={status_list}
                                                        onChange={(value) => this.handleChange('status', value)}
                                                        defaultValue={search_criteria.status}
                                                        disabled={false}
                                                        hidden={true}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label>Date Range {<span className={is_error ? "req-alert" : "req-alert_normal"}> (required)</span>}
                                                    </label>
                                                    <DateRangePickerComponent
                                                        updateDatesChange={this.handleDateChange}
                                                        startDate={search_criteria.from_date}
                                                        endDate={search_criteria.to_date}
                                                        error={is_error}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field >
                                                    <label>Company</label>
                                                    <Selection
                                                        placeHolder='Select'
                                                        name='scheduled_month_job_company'
                                                        id="scheduled_month_job_company"
                                                        options={company_list}
                                                        onChange={(value) => this.handleChange('company_id', value)}
                                                        defaultValue={search_criteria.company_id}
                                                        disabled={false}
                                                        hidden={true}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid>
                                    </Grid.Column>
                                </Grid>
                                <Grid style={{ margin: '0 -17px' }}>
                                    <Grid.Column computer={16} textAlign='right'>
                                        <Button type="button" id="clear_form" onClick={this.handleClear} basic >Clear</Button>
                                        <Button type='button' id="create_new_schedule_job" onClick={this.createNewScheduleJob} basic>New</Button>
                                        <Button id="schedule_month_end_search" disabled={is_search_disabled} type='submit' primary>Search</Button>
                                    </Grid.Column>
                                </Grid>
                                {show_grid  && (
                                    <Grid style={{ marginTop: '-1rem', marginBottom: 0 }}>
                                        <Grid.Column tablet={5} computer={4} textAlign='left'>
                                                <p style={{ fontSize: '16px' }}>Search Results</p>
                                        </Grid.Column>
                                        <Grid.Column tablet={5} computer={8} textAlign='center'>
                                            {
                                                <p style={{ fontSize: '16px', minHeight: 22 }}>
                                                    {`${grid_rows_count} ${ grid_rows_count == 1 ? ' record shown' : ' records shown'
                                                        }`}
                                                </p>
                                            }
                                        </Grid.Column>
                                        <Grid.Column tablet={2} computer={4} textAlign='right' />
                                    </Grid>
                                )}
                            </Form>
                        </div>
                        { show_grid  && (
                            <GridView
                                id="schedule_month_end"
                                row={this.grid_data.rows}
                                column={this.grid_data.grid_conf}
                                style={{ height: '100%' }}
                                wrapperStyle={{ width: '100%', height: '100%', display: 'flex' }}
                                suppressMovableColumns={false}
                                enableColResize={true}
                                selectionType={'single'}
                                get_grid_ref={this.getGridRef}
                                suppressSizeToFit={true}
                                headerHeight={this.grid_header_height}
                                headerIdForTabNavigation={constants.search_grid_id}
                                onForceGridOut={this.onGridOut}
                                onCellClicked={this.onCellClick}
                                on_filter_button_click={this.updateReportRowsCount}
                            />
                        )}
                    </div>
                    {show_grid > 0 && (
                        <div className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area' id='applicationFooterSticky' style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <Grid.Column computer={16} textAlign='right'>
                                <Button id='export_schedule_month_button' type='submit' onClick={this.handleExport} basic>
                                    Export
                                </Button>
                                <Button
                                    onKeyDown={set_focus_to_app_header}
                                    id='print_report_button'
                                    type='submit'
                                    onClick={this.handlePrint}
                                    primary
                                    style={{ marginRight: 0 }}
                                >
                                    Print
                                </Button>
                            </Grid.Column>
                        </div>
                    )}
                </div>
                {show_cancel_modal &&
                    <ConfirmationComponent message="Are you sure you want to cancel the job?"
                        title='Confirm' show_modal={show_cancel_modal}
                        onCloseModal={this.onCloseCancel}
                        save_button_text='Ok'
                        on_force_save_click={(e) => { this.cancel_scheduled_month_end_job() }}
                        custom_id={"cancel_job"}
                    />
                }
                {show_initiate_modal &&
                    <ConfirmationComponent message="Scheduled month-end jobs are run nightly. Are you sure that you want to run this job right now?"
                        title='Confirm' show_modal={show_initiate_modal}
                        onCloseModal={this.onCloseInitate}
                        save_button_text='Ok'
                        on_force_save_click={(e) => { this.initiate_scheduled_month_end_job() }}
                        custom_id={"initiate_job"}
                    />
                }
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        user_companies: user_companies,
        get_scheduled_month_end_search: get_scheduled_month_end_search
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        user_companies_response: state.user_details.user_companies_response,
        constants_reducer_reference: state.constants_reducer_reference.scheduled_month_end_search_data,
        features: { 
            isMonthEndQueuedEnabled: get_lauch_darkley_key_value(state.launch_darkly, MonthEndScheduleFeature.sumajB33855MonthEndClosePerformance)
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScheduleMonthEndSearchComponent));