import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Icon } from 'semantic-ui-react';
import { setTimeout } from 'timers';
// Sub Layouts
import { user_authentication } from '../../login/action/login_action';
import { set_url_history } from '../../reports/util/report_data_formatter';
import * as session_storage from '../../shared/session_storage_utility';
import { left_menu_permission, store_r2_data } from '../action/shared_action';
import * as shared_constants from '../shared_constants';
import { detect_ie, detect_ie_11, is_mobile, r2_decode_url, r2_form_path, get_lauch_darkley_key_value } from '../utility';
import { updated_charges_summary } from '../../charge/charge_constant';
import { summary_and_forecast_constants, provider_summary_report } from '../../reports/report_constants';
import { dynamic_form_letters } from '../../admin/constants/patient_letter_constants';
import * as FFConstant from '../../feature_flip_constant';

// Left menu of the application based on user permissions.
export class LeftMenuComponent extends React.Component<any, any> {
  [x: string]: any;
    _is_mounted = false;
    _ff_dashboard_new = true;
  constructor(props) {
    super(props);
    // Initialize state property here
    this.state = {
      visible: true,
      activeItem: 'Patient'
    };
  }
  // Window inner Height
    window_height: number;

    componentDidMount() {
        this._is_mounted = true;
    this.window_height = window.innerHeight;
    window.addEventListener('resize', this.update_window_height);
    window.addEventListener('orientationchange', this.update_window_height);
    var mobile = is_mobile();
    if (mobile === true) {
      //collapse side menu on mobile devices
      document
        .getElementById('sidebar-wrapper')
        .querySelector('i')
        .click();
    }
  }

    componentWillUnmount = () => {
    this._is_mounted = false;
    window.removeEventListener('resize', this.update_window_height);
    window.removeEventListener('orientationchange', this.update_window_height);
  };

  toggle_menu_visibility = () => {
      if (this._is_mounted) {
          this.setState({ visible: !this.state.visible });
      }
    this.props.menuToggle(!this.state.visible);
  };
  
  update_window_height = () => {
    let element: HTMLElement = document.querySelector('.visible');
    element && (element.style.maxHeight = null);
    element && element.classList.remove('visible');

      if (this._is_mounted) {
          this.setState({
              close_on_resize: false
          });
      }
    
    window.setTimeout(() => {
      this.window_height = window.innerHeight;
    }, 200);
  };

  on_menu_click = e => {
    let active_meu = e.currentTarget;
    let active_meu_offset = active_meu.getBoundingClientRect().top;
    active_meu.lastElementChild.style.maxHeight = `${this.window_height - (active_meu_offset + 40)}px`;
  };

    open_r2_page = (name) => {
        var open_r2 = true;
        for (var i = 0; i < shared_constants.r2_page_to_r6_page.length; i++) {
            var item = shared_constants.r2_page_to_r6_page[i]
            if (item.value == name && this.props[item.key] == true) {
                open_r2 = false;
                break;
            }
        }
        return open_r2;
    }

  // navigation on selecting a menu
  on_left_menu_click = (event, menu_data) => {
    if (menu_data.r6_path == null || menu_data.r6_path == '' || menu_data.r6_path == undefined) {
      menu_data.r6_path = '/coming_soon';
    }

    if (event.target.parentElement.classList.contains('visible')) {
      event.target.parentElement.classList.toggle('visible');
    }
    // R2 Embedded Pages
    // Note:: Clear "shared_constants.is_open_r2_page[menu_data.name]" when all R2 pages are visible in R6
    // shared_constants.is_open_r2_page will return true for the pages which will be open in r6.

      if (menu_data.is_r2 && this.open_r2_page(menu_data.name) && shared_constants.is_open_r2_page[menu_data.name]) {
      // for decoding R2 EXACT url after converting const values
      const r2_base_url = r2_decode_url(menu_data.r2_url);

      // store r2 base url and page name in the store
      this.props.store_r2_data(r2_base_url, menu_data.name);

      // Form url pathname as an input for R6
      const pathname = r2_form_path(menu_data.name);

      this.props.history.push(`/R2/${pathname}`);
     }
    //Charge Integration Audit from DB
    else if (menu_data.r6_path.indexOf(updated_charges_summary.component_path) != -1) {
        if (session_storage.get(updated_charges_summary.session_key) && (session_storage.get(updated_charges_summary.session_key).search_criteria)){
            session_storage.set(updated_charges_summary.session_key, '');
            this.props.history.push(`${menu_data.r6_path}`, { refresh: true })
        }
        else {
            this.props.history.push(`${menu_data.r6_path}`, { refresh: false })
        }
    }
    //Reports from DB
      else if (menu_data.r6_path.indexOf('report') != -1 && menu_data.report_id) {
        //if report id 508 provider summary report, check FF and call new version
      if (menu_data.report_id == provider_summary_report.old
        && this.props.kraken3155updatestoprovidersummaryreport) {
        menu_data.report_id = provider_summary_report.new}
      session_storage.set('url_history', []);
      set_url_history();
      this.props.history.push(`${menu_data.r6_path}`, { report_id: menu_data.report_id, flag: true });
    } else if (menu_data.r6_path.indexOf('patient_search') != -1) {
      this.props.patient_details.patient_search = null;
      session_storage.set('patient_criteria', '');
      this.props.history.push(`${menu_data.r6_path}`);
      }
      else if (menu_data.r6_path.indexOf('charge/release_claim_delay') != -1) {          
          session_storage.remove('release_cliam_dela_search_criteria');
          this.props.history.push(`${menu_data.r6_path}`);
      } else if (menu_data.r6_path.indexOf('report/payment_adjustment_summary') != -1) {
          session_storage.remove('payment_adjustment_summary_search_criteria');
          this.props.history.push(`${menu_data.r6_path}`);
      }
      //Charge Integration Audit from DB
      else if (menu_data.r6_path.indexOf(summary_and_forecast_constants.component_path) != -1) {
          
          if (session_storage.get(summary_and_forecast_constants.session_key) && (session_storage.get(summary_and_forecast_constants.session_key).search_criteria)) {
              session_storage.set(summary_and_forecast_constants.session_key, '');
              this.props.history.push(`${menu_data.r6_path}`, { refresh: true })
          }
          else {
              this.props.history.push(`${menu_data.r6_path}`, { refresh: false })
          }
      }
      else if (menu_data.r6_path.indexOf(dynamic_form_letters.base_url) != -1) {
          if (session_storage.get(dynamic_form_letters.session_key) && (session_storage.get(dynamic_form_letters.session_key).search_criteria)) {
              session_storage.set(dynamic_form_letters.session_key, '');
              this.props.history.push(`${menu_data.r6_path}`, { refresh: true })
          }
          else {
              this.props.history.push(`${menu_data.r6_path}`, { refresh: false })
          }
      }
    // R6 new pages
    else {
      // remove r2 base url and page name from the store, if present
      this.props.store_r2_data(null, null);

      this.props.history.push(`${menu_data.r6_path}`);
    }
  };

    handle_left_submenu_click = event => {        
        if (event.currentTarget.innerText.trim() == 'Dashboard') {
            // remove r2 base url and page name from the store, if present
            this.props.store_r2_data(null, null);
            this.props.history.push(`${'/dashboard'}`);
        }
        else {
            if (event.target.nodeName == 'A') {
                event.target.nextElementSibling.nextElementSibling.classList.toggle('visible');
            } else {
                event.target.parentElement.nextElementSibling.nextElementSibling.classList.toggle('visible');
            }
        }
  };

  handle_left_submenu_item_on_blur = event => {
    let code = event.keyCode || event.which;
    if (code) return;

    if (event.target.getAttribute('role') == 'option') {
      let relatedTarget = event.relatedTarget;

        if (detect_ie_11()) {
        relatedTarget = document.activeElement;
      }
      if (!relatedTarget || relatedTarget.getAttribute('role') != 'option') {
        if (event.target.parentElement.classList.contains('visible')) {
          event.target.parentElement.classList.toggle('visible');
        }
      }
    }
  };

  handle_left_submenu_on_blur = event => {
    let code = event.keyCode || event.which;
    if (code) return;

    // after internal on blur this will be triggered
    // this should only work when attribute role is listbox
    if (event.target.getAttribute('role') == 'listbox') {
      let relatedTarget = event.relatedTarget;

        if (detect_ie_11()) {
          relatedTarget = document.activeElement;

          // ignoring when scrolling in the case of IE
          if (relatedTarget.getAttribute('class') == 'menu transition dropdown-menu sub-menu visible') return;
      }

      // event.relatedTarget will be null if clicked outside anywhere in the window
      // role attribute will be listbox when a listbox is open and again i am opening another listbox
      if (!relatedTarget || relatedTarget.getAttribute('role') != 'option') {
          if (event.target.children[2].classList.contains('visible')) {

          // removing the height from the element
          let active_menu = event.currentTarget;
           active_menu.lastElementChild.style.maxHeight = '';


          event.target.children[2].classList.toggle('visible');
        }
      }
    }

      if (detect_ie_11()) {
          if (event.target.getAttribute('class') == 'menu transition dropdown-menu sub-menu visible') {
              let relatedTarget = document.activeElement;
             
              
              if (relatedTarget) {

                  // ignoring same listbox to close in the case of IE
                  if (relatedTarget.getAttribute('role') == 'listbox' &&
                      event.target.parentElement.children[0].children[2].textContent == document.activeElement.children[0].children[2].textContent) {
                      return;
                  }

                  if (event.target.classList.contains('visible')) {
                      event.target.classList.toggle('visible');
                  }
              }
          }
      }
  };

  // Left menu with Icon
  render_new_menu = () => {
    const { visible, active, sub_active } = this.state;

    // Form Drop Down Items
      const trigger = (menu_name, menu_icon) => {
        switch (menu_name) {
            case 'Dashboard':
                return (
                    <a onClick={this.handle_left_submenu_click}>
                        <Icon name={menu_icon} />
                        <span> {menu_name}</span>
                    </a>
                );
            case 'Month End Reports':
                return (
                    <a onClick={this.handle_left_submenu_click}>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='20'
                            height='20'
                            viewBox='0 0 36 36'
                            className='month-end-icon'
                        >
                            <rect className='a' width='36' height='36' />
                            <path
                                className='b'
                                d='M27.23-13.949V7.23A3.035,3.035,0,0,1,24.2,10.256H3.026A3.026,3.026,0,0,1,0,7.23L.015-13.949a3.012,3.012,0,0,1,.877-2.138,3.012,3.012,0,0,1,2.134-.888H4.538V-20H7.564v3.026h12.1V-20h3.026v3.026H24.2A3.035,3.035,0,0,1,27.23-13.949Zm-24.2,3.026H24.2v-3.026H3.026ZM24.2,7.23V-7.9H3.026V7.23Z'
                                transform='translate(4.538 23.026)'
                            />
                            <g transform='translate(11 14.857)'>
                                <text className='c'>
                                    <tspan x='0' y='0'>
                                        31
                                    </tspan>
                                </text>
                            </g>
                        </svg>
                        <Icon aria-hidden='true' name='angle right' className='sidebar-caret' />
                        <span> {menu_name}</span>
                    </a>
                );
            default:
                return (
                    <a onClick={this.handle_left_submenu_click}>
                        <Icon name={menu_icon} />
                        <Icon aria-hidden='true' name='angle right' className='sidebar-caret' />
                        <span> {menu_name}</span>
                    </a>
                );
        }
    };

    // List from database
 
      var menu_list = {...this.props.shared_details.left_menu_data};
      // Add Dashboard Menu
      var dashboard_menu_list = menu_list.data
          .filter((menu, index) => {
              return menu.id == -999;
          });
      if (this.props.rsiB29536Dashboard) {
         
          if (dashboard_menu_list.length <= 0) {
              menu_list.data.push({
                  create: true,
                  delete: true,
                  id: -999,
                  is_r2: false,
                  menu_id: -999,
                  menu_order: 0,
                  name: "Dashboard",
                  parent_id: 0,
                  r2_url: "",
                  r6_parent_id: 0,
                  r6_path: "/dashboard",
                  report_id: null,
                  sub_menu: null,
                  switch_id: null,
                  update: true,
                  view: true
              });
          }
      }
      else {
          if (dashboard_menu_list.length >= 0) {
              menu_list.data
                  .filter((menu, index) => {
                      if (menu.id == -999) {
                          menu_list.data.splice(index, 1);
                      }
                  });
          }
      }

      menu_list.data = this.filter_menu_list_on_FF(menu_list.data);
      
      //
    if (menu_list) {
        menu_list = menu_list.data;

      // list from constant
      var menu_mapping: Array<any> = menu_list
        .filter((menu, index) => {
          return menu.r6_parent_id == 0;
        })
        .sort(function(obj1, obj2) {
          var nameA = obj1.menu_order,
            nameB = obj2.menu_order;
          if (nameA < nameB)
            //sort string ascending
            return -1;
          if (nameA > nameB) return 1;
          return 0;
        });

      var _this = this;
      return (
        <div className={'ui secondary vertical menu'}>
          {menu_mapping.map(function(parent_manu, index) {
            var parent_manu_icon = shared_constants.app_left_parent_menu.filter((menu_item, index) => {
              return menu_item.id == parent_manu.id;
            })[0];
            if (parent_manu && parent_manu.view) {
              var child_manu_List: Array<any> = menu_list.filter((menu, index) => {
                return menu.r6_parent_id == parent_manu.id;
              });

              // ignore sub menu items
              shared_constants.ignore_menu_items.map((item) => {
                if(_this.props[item.key] == false) {
                    child_manu_List = child_manu_List.filter((sub_menu) => {
                        if (sub_menu.name == "Failed Charges") {
                            sub_menu.report_id = _this.props.rsiBill393UiBillingFailedCharges ? 30380 : 30286
                        }
                    if(sub_menu.name != item.value) {
                      return sub_menu;
                    }
                  })
                }
              }); 
              
              child_manu_List = child_manu_List.sort(function(obj1, obj2) {
                var nameA = obj1.menu_order,
                  nameB = obj2.menu_order;
                if (nameA < nameB)
                  //sort string ascending
                  return -1;
                if (nameA > nameB) return 1;
                return 0;
              });

              return (
                <div
                  role='listbox'
                  key={parent_manu.name}
                  onBlur={_this.handle_left_submenu_on_blur}
                  onClick={e => _this.on_menu_click(e)}
                  onFocus={e => _this.on_menu_click(e)}
                  aria-expanded='false'
                  className='ui basic dropdown main-dropdown'
                  tabIndex={0}
                >
                  {trigger(parent_manu.name, parent_manu_icon.icon)}
                  <i aria-hidden='true' className='dropdown icon' />
                  <div className='menu transition dropdown-menu sub-menu'>
                    {child_manu_List.map(function(sub_menu, index) {
                      if (sub_menu && sub_menu.view) {
                        return (
                          <div
                            tabIndex={0}
                            role='option'
                            className='item'
                            onClick={event => {
                              _this.on_left_menu_click(event, sub_menu);
                            }}
                            onBlur={_this.handle_left_submenu_item_on_blur}
                            key={index}
                          >
                            {sub_menu.name}
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              );
            }
          })}
        </div>
      );
    }
    };

    filter_menu_list_on_FF = (menu_list: any[]): any[] => {
        if (this.props.leviathanLocationClaimHolds != null && this.props.leviathanLocationClaimHolds != undefined) {
            if (this.props.leviathanLocationClaimHolds) {
                menu_list.filter((menu, index) => {
                    if (menu.name === shared_constants.provider_claim_holds_name) {
                        menu_list.splice(index, 1);
                    }
                });

            } else {
                menu_list.filter((menu, index) => {
                    if (menu.name === shared_constants.claim_holds_name) {
                        menu_list.splice(index, 1);
                    }
                });
            }
            return menu_list;
        } 
    }

  handleLeftMenuAnnotation = event => {
    if (event.keyCode == '13') {
      event.preventDefault();
      event.target.click();
    }
  };

  handle_tab_navigation_for_left_menu = event => {
    let code = event.keyCode || event.which;

    // when keycode detected is 40 (down key)
    // send the focus to its sibling
    if (code == 40) {
      event.preventDefault();
      event.stopPropagation();
      // if it is toggle icon then go to next child
      if (event.target.id == 'left_menu_toggle_icon') {
        event.target.nextElementSibling.children[0].focus();
      } else {
        if (code == 40) {
          if (event.target.nextElementSibling) {
            let downKeyTarget = event.target;

              if (detect_ie_11()) {
              setTimeout(() => {
                downKeyTarget.nextElementSibling.focus();
              }, 0);
            } else {
              event.target.nextElementSibling.focus();
            }
          } else {
            // not doing anything if it is last item
          }
        }
      }
    }

    // when the keycode detected is 39 (right key)
    // if the node has children then open the menu
    // else do nothing
    if (code == 39 || code == 13) {
      event.preventDefault();
      event.stopPropagation();

      if (event.target.getAttribute('role') == 'listbox') {
        // open on right arrow or enter
        if (!event.target.children[2].classList.contains('visible')) {
          event.target.children[2].classList.toggle('visible');
        }

        // open or closed doesnot matter transfer the focus to ist element of the dropdown
        // enter, right arrow
          if (detect_ie_11()) {
          let target = event.target;
          setTimeout(() => {
            target.children[2].children[0].focus();
          }, 0);
        } else {
          event.target.children[2].children[0].focus();
        }
      }

      if (event.target.getAttribute('role') == 'option' && code == 13) {
        event.target.click();
      }
    }

    // handle tab for the edgge case of firefox
    if (code == 9 && !event.shiftKey) {
      if (event.target.getAttribute('role') == 'listbox') {
        if (event.target.children[2].classList.contains('visible')) {
          event.preventDefault();
          event.stopPropagation();

            if (detect_ie_11()) {
            let target = event.target;
            setTimeout(() => {
              target.children[2].children[0].focus();
            }, 0);
          } else {
            event.target.children[2].children[0].focus();
          }
        }
      }
    }

    // when keycode detected is 39 (up key)
    // send the focus to its previous sibling
    if (code == 38) {
      event.preventDefault();
      event.stopPropagation();

      // i am transferring my focus to previous element of the list
      if (event.target.previousElementSibling) {
        event.target.previousElementSibling.focus();
      } else {
        if (event.target.parentElement) {
          // i am transferring my focus to previous element of the list
          if (event.target.parentElement.previousElementSibling) {
            if (event.target.parentElement.previousElementSibling.id == 'left_menu_toggle_icon') {
              let upKeyTarget = event.target;
                if (detect_ie_11()) {
                setTimeout(() => {
                  upKeyTarget.parentElement.previousElementSibling.focus();
                }, 0);
              } else {
                event.target.parentElement.previousElementSibling.focus();
              }
            }
          }
        }
      }
    }

    // when keycode detected is 37 (left key)
    // send the focus to its parent
    if (code == 37) {
      event.preventDefault();
      event.stopPropagation();
      if (event.target.getAttribute('role') == 'option') {
        if (event.target.parentElement.classList.contains('visible')) {
          event.target.parentElement.classList.toggle('visible');
          event.target.parentElement.parentElement.focus();
        }
      }
    }

    // on shift tab
    if (code == 9 && event.shiftKey) {
      if (event.target.getAttribute('role') == 'option') {
        if (!event.target.previousElementSibling) {
          event.preventDefault();
          event.stopPropagation();

          event.target.parentElement.parentElement.focus();
        }
      }
    }

    // when esc is clicked
    if (code == 27) {
      if (event.target.getAttribute('role') == 'listbox') {
        event.target.children[2].classList.toggle('visible');
        event.target.focus();
      }

      if (event.target.getAttribute('role') == 'option') {
        event.target.parentElement.classList.toggle('visible');
        event.target.parentElement.parentElement.focus();
      }
    }
  };

  render() {
    const { visible } = this.state;
    return (
      <React.Fragment>
        <div onKeyDown={this.handle_tab_navigation_for_left_menu} id='sidebar-wrapper' className={this.state.visible ? '' : 'toggled'}>
          <Icon
            id='left_menu_toggle_icon'
            onKeyDown={this.handleLeftMenuAnnotation}
            tabIndex={0}
            name={visible ? 'angle double left' : 'angle double right'}
            onClick={this.toggle_menu_visibility}
          />

          {this.render_new_menu()}
        </div>
      </React.Fragment>
    );
  }
}
const activeStyle = { background: '#e2f7ff !important' };

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      authentication: user_authentication,
      menu_permission: left_menu_permission,
      store_r2_data: store_r2_data
    },
    dispatch
  );
};

const mapStateToProps = state => {
    console.log('state.launch_darkly', state.launch_darkly)
    return {
        user_details: state.user_login_details,
        shared_details: state.shared_details,
        patient_details: state.patient_details,
        rsiB26337ConvertAdjustmentPages: get_lauch_darkley_key_value(state.launch_darkly, "rsiB26337ConvertAdjustmentPages"),
        rsiB26337ConvertR2AdjustmentPages: !get_lauch_darkley_key_value(state.launch_darkly, "rsiB26337ConvertAdjustmentPages"),
        ptfmB28584ClaimsScrubbingProcReplacement: get_lauch_darkley_key_value(state.launch_darkly, 'ptfmB28584ClaimsScrubbingProcReplacement'),
        rsiB26251ConvertManageConstants: get_lauch_darkley_key_value(state.launch_darkly, "rsiB26251ConvertManageConstants"),
        rsiB28566ConvertMergeAccountsPage: get_lauch_darkley_key_value(state.launch_darkly, "rsiB28566ConvertMergeAccountsPage"),
        rsiB28096ConvertStatusCategoryPageSearch: get_lauch_darkley_key_value(state.launch_darkly, "rsiB28096ConvertStatusCategoryPageSearch"),
        rsiB28091ConvertStatusGroupCodePageSearch: get_lauch_darkley_key_value(state.launch_darkly, "rsiB28091ConvertStatusGroupCodePageSearch"),
        tfB28776BillingFailedChargeRules: get_lauch_darkley_key_value(state.launch_darkly, "tfB28776BillingFailedChargeRules"),
        tfB29284BillingFailedChargesReport: get_lauch_darkley_key_value(state.launch_darkly, "tfB29284BillingFailedChargesReport"),
        rsiB29037ConvertOpenZeroPayment: get_lauch_darkley_key_value(state.launch_darkly, "rsiB29037ConvertOpenZeroPayment"),
        rsiB28087ConvertStatusCodePageSearch: get_lauch_darkley_key_value(state.launch_darkly, "rsiB28087ConvertStatusCodePageSearch"),
        rsiB28381ConvertAddEditPayerRulesPageSearch: get_lauch_darkley_key_value(state.launch_darkly, "rsiB28381ConvertAddEditPayerRulesPageSearch"),
        rsiB28983ConvertDemandClaimsPage: get_lauch_darkley_key_value(state.launch_darkly, "rsiB28983ConvertDemandClaimsPage"),
        rsiB29084FormatterHomePageUpdates: get_lauch_darkley_key_value(state.launch_darkly, "rsiB29084FormatterHomePageUpdates"),
        //rsiB29874ConvertErrorCorrectionsSearchCriteria: get_lauch_darkley_key_value(state.launch_darkly, "rsiB29874ConvertErrorCorrectionsSearchCriteria"),
        rsiB29536Dashboard: get_lauch_darkley_key_value(state.launch_darkly, "rsiB29536Dashboard"),
        rsiB30130ConvertChargeEditRules: get_lauch_darkley_key_value(state.launch_darkly, "rsiB30130ConvertChargeEditRules"),
        rsiB30151ConvertEditPaymentsSearchView: get_lauch_darkley_key_value(state.launch_darkly, "rsiB30151ConvertEditPaymentsSearchView"),
        rsiB30144ConvertChargeCaseTransfer: get_lauch_darkley_key_value(state.launch_darkly, "rsiB30144ConvertChargeCaseTransfer"),
        rsiB30359BatchUpdatesSearchCriteria: get_lauch_darkley_key_value(state.launch_darkly, "rsiB30359BatchUpdatesSearchCriteria"),
        argosB31470ExportedCharges: get_lauch_darkley_key_value(state.launch_darkly, "argosB31470ExportedCharges"),
        rsiB32441BatchUpdatesExtendDosSearchLength: get_lauch_darkley_key_value(state.launch_darkly, "rsiB32441BatchUpdatesExtendDosSearchLength"),
        rsiB32935RolesPermissionsMenuAndPermissions: get_lauch_darkley_key_value(state.launch_darkly, "rsiB32935RolesPermissionsMenuAndPermissions"),
        rsiB32929ConvertUserBulkUpload: get_lauch_darkley_key_value(state.launch_darkly, "rsiB32929ConvertUserBulkUpload"),
        krakenB34873DynamicFormLetters: get_lauch_darkley_key_value(state.launch_darkly, "krakenB34873DynamicFormLetters"),
        primeB32881BulkPaymentUpdates: get_lauch_darkley_key_value(state.launch_darkly, "primeB32881BulkPaymentUpdates"),
        primeB32927AdditionalConfigurabilityForClaims: get_lauch_darkley_key_value(state.launch_darkly, FFConstant.prime_additional_configurability_for_claims),
        rsiBill393UiBillingFailedCharges: get_lauch_darkley_key_value(state.launch_darkly, FFConstant.rsi_bill_393_ui_billing_failed_charges),
        goliathBill353835ValidationConfiguration: get_lauch_darkley_key_value(state.launch_darkly, "goliathBill353835ValidationConfiguration"),
        leviathanBill542StreamlineClaimHoldsSummary: get_lauch_darkley_key_value(state.launch_darkly, FFConstant.leviathan_streamline_claim_holds),
        krakenBill812ProviderClaimHoldsCardToStandAlonePage: get_lauch_darkley_key_value(state.launch_darkly, FFConstant.kraken_provider_claim_holds_card_to_stand_alone_page),
        primeBill616ReactivateCharges: get_lauch_darkley_key_value(state.launch_darkly, FFConstant.prime_reactivate_charges),
        argosBill2473Upload835S: get_lauch_darkley_key_value(state.launch_darkly, FFConstant.argos_bill_2473_upload_835s),
        kraken3155updatestoprovidersummaryreport: get_lauch_darkley_key_value(state.launch_darkly, FFConstant.kraken_bill_3155_updates_to_provider_summary_report),
        leviathanLocationClaimHolds: get_lauch_darkley_key_value(state.launch_darkly, FFConstant.leviathan_bill_3434_location_credential_claim_holds_r6)
    }
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LeftMenuComponent)
);
