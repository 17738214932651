import * as React from 'react';
import { EditorState, Modifier } from 'draft-js';
import RichTextEditorHelper, { IRichTextEditorCustomMenuOptionItem } from '../../rich_text_editor_helper';

class CustomMenuComponent extends React.Component<any, any> {

    signal_drop_down_expanded: boolean = false;

    constructor(props) {
        super(props);

        if (this.props.config.sortData) {
            this.props.config.options = RichTextEditorHelper.sort_options(this.props.config.options);
        }

        this.state = {
            open: false
        }
        this.props.modalHandler.registerCallBack(this.expand_collapse_dropdown);

    }

    componentWillUnmount() {
        const { modalHandler } = this.props;
        modalHandler.deregisterCallBack(this.expand_collapse_dropdown);
    }

    get_custom_menu_options(options: Array<IRichTextEditorCustomMenuOptionItem> = []) {

        
        let display_entries =  options.map((option_item, index) => {
            option_item.tooltip = option_item.tooltip ? option_item.tooltip.trim() : '';
            let display_tool_tip = option_item.tooltip.length > 0 ? true : false
            return (
                <li onClick={() => this.addPlaceholder(option_item.value)}
                    key={index.toString()}
                    className="rdw-dropdownoption-default">
                        <div className={display_tool_tip ? 'rte-tooltip' : ''}>
                            <span>{option_item.text}</span>
                            {display_tool_tip && <span className='rte-tooltiptext'>{option_item.tooltip}</span>}
                        </div>
                </li>
            )
        });

        return display_entries;
    }

    expand_collapse_dropdown = () => {
        this.setState({
            open: this.signal_drop_down_expanded
        });
        this.signal_drop_down_expanded = false;
    };

    openDropdown = () => {
        let open_state_inverted = !this.state.open;
        this.signal_drop_down_expanded = open_state_inverted;
        this.setState({ open: open_state_inverted })
    }

    addPlaceholder = (placeholder) => {

        const contentState = Modifier.replaceText(
            this.props.editorState.getCurrentContent(),
            this.props.editorState.getSelection(),
            placeholder,
            this.props.editorState.getCurrentInlineStyle(),
        );
        this.props.onChange(EditorState.push(this.props.editorState, contentState, 'insert-characters'));
    }

    get_custom_menu() {
        if (this.props.config.inDropdown) {
           return this.render_dropdown()
        }
        else {
           return this.render_buttons();
        }
    }

    render_dropdown() {
        let placeHolderTitle = this.props.config.dropDownTitle ? this.props.config.dropDownTitle : "SELECT";
        let cssClass = this.props.config.inDropdown ? this.props.config.cssClass : '';
        let drop_down_class = "close";
        let caretto_class = "open"
        if (this.state.open) {
            drop_down_class = "open";
            caretto_class = "close"
        }

        return (
            <li className={`rte-custom-menu-dropdown ${cssClass}`}>
                <div onClick={() => this.openDropdown()} className="rdw-block-wrapper" aria-label="rdw-block-control">
                    <div className={`rdw-dropdown-wrapper rdw-block-dropdown`} aria-label="rdw-dropdown">
                        <a className="rdw-dropdown-selectedtext" title={placeHolderTitle}>
                            <span>{placeHolderTitle}</span>
                            <div className={`rdw-dropdown-caretto${caretto_class}`}></div>
                        </a>
                        <ul className={`rte-place-holder-dropdown rdw-dropdown-optionwrapper custom-button-options-${drop_down_class}`}>
                            {this.get_custom_menu_options(this.props.config.options)}
                        </ul>
                    </div>
                </div>
            </li>
        )
    }

    render_buttons() {
        let cssClass = this.props.config.inDropdown ? this.props.config.cssClass : '';
        return (
            <div className={`rte-custom-menu-buttons ${cssClass}`}>
                {this.get_custom_menu_options(this.props.config.options)}
            </div>
        )
    }

    render() {
        return (this.get_custom_menu())
    }
}

export default CustomMenuComponent;