import * as report_constants from '../report_constants';
import * as dateFormat from 'dateformat';
import { amount_formatter_without_dollar_sign, currency_formatter, print_html_data_to_pdf } from '../../shared/utility';
import * as _ from 'lodash';

export const get_patient_statement_print_data = (data: any, auto_print = false) => {
    let patient_statement_data: report_constants.PatientStatementReport = data.value.data;
    if (patient_statement_data !== null) {
        // calculate statemtent totals
        let totals = {
            chargeTotal: 0,
            adjustmentTotal: 0,
            balanceTotal: 0,
            paymentTotal: 0
        };

        patient_statement_data.patientCharges.forEach((patientCharge) => {
            totals.chargeTotal += patientCharge.chargeAmount;
            totals.adjustmentTotal += patientCharge.adjustmentAmount;
            totals.balanceTotal += patientCharge.patientBalance;
        });

        patient_statement_data.patientPaymentLogs.forEach((paymentLog) => {
            totals.paymentTotal += paymentLog.paymentAmount;
        });

        let print_html = '';
        print_html = '<div id="ps-paper-statement-background">';
        //section 1
        print_html += '<div>';
        //section 1.1 - company info
        print_html += '<div class="ps-section">';
        print_html += '<div class="ps-section-logo"><div style="width: 133px;"></div>';
        print_html += '<div class="ps-top-header-company-address">';
        print_html += `<p class="ps-top-header-logo-heading">${patient_statement_data.statementHeader.company.name}</p>`;
        print_html += `<p class="ps-top-header-paragraph">${patient_statement_data.statementHeader.company.address}</p>`;
        print_html += `<p class="ps-top-header-paragraph">${patient_statement_data.statementHeader.company.city}, ${patient_statement_data.statementHeader.company.state} ${patient_statement_data.statementHeader.company.zip}</p>`;
        print_html += '</div>';
        print_html += '</div>';
        //section 1.2 - payment info
        print_html += '<div class="ps-card">';
        print_html += '<table id="ps-payment-invoice-table" class="ui celled table">';
        print_html += '<thead class="">';
        print_html += '<tr class="ps-top-header-table-header">';
        print_html += '<th style="font-size: 15px !important" colspan="6" id="ps-top-header-payment-header" class=""><b>Please fill out if paying by card</b></th>';
        print_html += '</tr>';
        print_html += '<tr class="">';
        print_html += '<th style="background: none" colspan="6" class="ps-no-padding">';
        print_html += '<div class="ps-top-header-icons">';
        print_html += '<div class="ps-icon-section"><img class="ps-card-icons" src="/src/assets/images/VisaIcon.png"><input type="checkbox"></div>';
        print_html += '<div class="ps-icon-section"><img class="ps-card-icons" src="/src/assets/images/MasterIcon.png"><input type="checkbox"></div>';
        print_html += '<div class="ps-icon-section"><img class="ps-card-icons-discover" src="/src/assets/images/DiscoverIcon.png"><input type="checkbox"></div>';
        print_html += '<div class="ps-icon-section"><img class="ps-card-icons" src="/src/assets/images/AmericanExpressIcon.png"><input type="checkbox"></div>';
        print_html += '</div>';
        print_html += '</th>';
        print_html += '</tr>';
        print_html += '</thead>';
        print_html += '<tbody class="">';
        print_html += '<tr class="">';
        print_html += '<td colspan="4" class="">';
        print_html += '<p class="ps-top-header-heading ps-table-names ps-empty-table-names">Credit card number:</p > ';
        print_html += '</td>';
        print_html += '<td colspan="2" class="">';
        print_html += '<p class="ps-top-header-heading ps-table-names ps-empty-table-names">Expires:</p>';
        print_html += '</td>';
        print_html += '</tr>';
        print_html += '<tr class="">';
        print_html += '<td colspan="4" class="">';
        print_html += '<p class="ps-top-header-heading ps-table-names ps-empty-table-names">Signature:</p>';
        print_html += '</td>';
        print_html += '<td colspan="2" class="">';
        print_html += '<p class="ps-top-header-heading ps-table-names ps-empty-table-names">Payment amount:</p>';
        print_html += '</td>';
        print_html += '</tr>';
        print_html += '<tr class="">';
        print_html += '<th colspan="2" class="">';
        print_html += '<div class="ps-payment-align">';
        print_html += '<div class="ps-top-header-heading ps-table-names-bottom">Invoice date:</div>';
        print_html += `<div class="ps-last-row-text">${patient_statement_data.statementHeader.invoice.invoiceDate}</div>`;
        print_html += '</div>';
        print_html += '</th>';
        print_html += '<th colspan="2" class="">';
        print_html += '<div class="ps-payment-align">';
        print_html += '<div class="ps-top-header-heading ps-table-names-bottom">Account number:</div>';
        print_html += `<div class="ps-last-row-text">${patient_statement_data.statementHeader.invoice.accountNumber}</div>`;
        print_html += '</div>';
        print_html += '</th>';
        print_html += '<th colspan="2" class="ps-balance-background">';
        print_html += '<div class="ps-payment-align">';
        print_html += '<div class="ps-top-header-heading ps-table-names-bottom">Balance due:</div>';
        print_html += `<div class="ps-last-row-text">${currency_formatter(patient_statement_data.statementHeader.invoice.balanceDue)}</div>`;
        print_html += '</div>';
        print_html += '</th>';
        print_html += '</tr>';
        print_html += '</tbody>';
        print_html += '</table>';
        print_html += '</div>';
        print_html += '</div>';
        // end section 1
        //section 2
        print_html += '<div class="ps-default-block ps-padding">';
        print_html += '<div class="ps-default-block ps-padding-inner-block-left">';
        print_html += '<div>';
        print_html += '<p class="ps-top-header-address-heading">Addressee:</p>';
        print_html += '</div>';
        print_html += '<div style="padding-left: 6px">';
        print_html += `<p class="ps-top-header-address-heading">${patient_statement_data.billingAddresses.patient.name}</p>`;
        print_html += `<p class="ps-top-header-address-paragraph">${patient_statement_data.billingAddresses.patient.address}</p>`;
        print_html += `<p class="ps-top-header-address-paragraph">${patient_statement_data.billingAddresses.patient.city}, ${patient_statement_data.billingAddresses.patient.state} ${patient_statement_data.billingAddresses.patient.zip}</p>`;
        print_html += '</div>';
        print_html += '</div>';
        print_html += '<div class="ps-default-block-company ps-padding-inner-block-right">';
        print_html += '<div>';
        print_html += '<p class="ps-top-header-address-heading">Make Checks Payable To:</p>';
        print_html += '</div>';
        print_html += '<div style="padding-left: 6px">';
        print_html += `<p class="ps-top-header-address-heading">${patient_statement_data.billingAddresses.company.name}</p>`;
        print_html += `<p class="ps-top-header-address-paragraph">${patient_statement_data.billingAddresses.company.address}</p>`;
        print_html += `<p class="ps-top-header-address-paragraph">${patient_statement_data.billingAddresses.company.city}, ${patient_statement_data.billingAddresses.company.state} ${patient_statement_data.billingAddresses.company.zip}</p>`;
        print_html += '</div>';
        print_html += '</div>';
        print_html += '</div>';
        print_html += '<div class="ps-default-block ps-notice-block">';
        print_html += '<div class="ps-notice-checkbox"><input type="checkbox"></div>';
        print_html += '<div>';
        print_html += '<p class="ps-notice-font">Please check box if above address is incorrect or insurance information has changed, and indicate changes(s) on reverse side.</p>';
        print_html += '</div>';
        print_html += '</div>';
        print_html += '<div class="ps-divider-block">';
        print_html += '<div class="ps-divider-text">PLEASE RETURN TOP PORTION WITH YOUR PAYMENT</div>';
        print_html += '<hr class="ps-dashed">';
        print_html += '</div>';
        print_html += '</div>';
        // end section 2
        // section 3 - patient charges
        print_html += '<div>';
        print_html += '<div class="ps-heading-block">Patient Charges</div>';
        print_html += '<table id="ps-patient-charges-table" class="ui very compact table">';
        print_html += '<thead class="">';
        print_html += '<tr class="">';
        print_html += '<th class="ps-table-header ps-border-removed">Date of Service</th>';
        print_html += '<th class="ps-table-header ps-border-removed">Provider</th>';
        print_html += '<th class="ps-table-header ps-border-removed">Location</th>';
        print_html += '<th class="ps-table-header ps-border-removed">Charges</th>';
        print_html += '<th class="ps-table-header ps-border-removed">Payments/Adjustments</th>';
        print_html += '<th class="ps-table-header">Patient Balance</th>';
        print_html += '</tr>';
        print_html += '</thead>';
        print_html += '<tbody class="">';
        // for each patient charge
        patient_statement_data.patientCharges.forEach((patientCharge, index) => {
            var lastRow = patient_statement_data.patientCharges.length - 1;
            if (index !== lastRow) {
                if ((index === 15 || index % 54 === 0) && index !== 0) { // applies page breaks
                    print_html += '<tr class="ps-apply-page-break">';
                    print_html += `<td class="ps-border-removed ps-border-bottom-removed">${patientCharge.dateOfService}</td>`;
                    print_html += `<td class="ps-border-removed ps-border-bottom-removed">${patientCharge.provider}</td>`;
                    print_html += `<td class="ps-border-removed ps-border-bottom-removed">${patientCharge.locationOfService}</td>`;
                    print_html += `<td class="ps-border-removed ps-border-bottom-removed">${amount_formatter_without_dollar_sign(patientCharge.chargeAmount)}</td>`;
                    print_html += `<td class="ps-border-removed ps-border-bottom-removed">${amount_formatter_without_dollar_sign(patientCharge.adjustmentAmount)}</td>`;
                    print_html += `<td class="ps-border-bottom-removed">${amount_formatter_without_dollar_sign(patientCharge.patientBalance)}</td>`;
                    print_html += '</tr>';
                }
                else {
                    print_html += '<tr class="">';
                    print_html += `<td class="ps-border-removed ps-border-bottom-removed">${patientCharge.dateOfService}</td>`;
                    print_html += `<td class="ps-border-removed ps-border-bottom-removed">${patientCharge.provider}</td>`;
                    print_html += `<td class="ps-border-removed ps-border-bottom-removed">${patientCharge.locationOfService}</td>`;
                    print_html += `<td class="ps-border-removed ps-border-bottom-removed">${amount_formatter_without_dollar_sign(patientCharge.chargeAmount)}</td>`;
                    print_html += `<td class="ps-border-removed ps-border-bottom-removed">${amount_formatter_without_dollar_sign(patientCharge.adjustmentAmount)}</td>`;
                    print_html += `<td class="ps-border-bottom-removed">${amount_formatter_without_dollar_sign(patientCharge.patientBalance)}</td>`;
                    print_html += '</tr>';
                }
            }
            else {
                print_html += '<tr class="">';
                print_html += `<td class="ps-border-removed">${patientCharge.dateOfService}</td>`;
                print_html += `<td class="ps-border-removed">${patientCharge.provider}</td>`;
                print_html += `<td class="ps-border-removed">${patientCharge.locationOfService}</td>`;
                print_html += `<td class="ps-border-removed">${amount_formatter_without_dollar_sign(patientCharge.chargeAmount)}</td>`;
                print_html += `<td class="ps-border-removed">${amount_formatter_without_dollar_sign(patientCharge.adjustmentAmount)}</td>`;
                print_html += `<td class="">${amount_formatter_without_dollar_sign(patientCharge.patientBalance)}</td>`;
                print_html += '</tr>';
            }


        });
        print_html += '</tbody>';
        print_html += '<tfoot class="">';
        print_html += '<tr class="">';
        print_html += '<td class="ps-border-removed"></td>';
        print_html += '<td class="ps-border-removed"></td>';
        print_html += '<td class="ps-border-removed"><b>TOTALS</b></td>';
        print_html += `<td class="ps-border-removed"><b>${currency_formatter(totals.chargeTotal)}</b></td>`;
        print_html += `<td class="ps-border-removed"><b>${currency_formatter(totals.adjustmentTotal)}</b></td>`;
        print_html += `<td class=""><b>${currency_formatter(totals.balanceTotal)}</b></td>`;
        print_html += '</tr>';
        print_html += '</tfoot>';
        print_html += '</table>';
        print_html += '</div>';
        //end section 3
        //section 4 - payment logs
        print_html += '<div>';
        print_html += '<div class="ps-heading-block">Patient Payment Log</div>';
        print_html += '<table id="ps-patient-payment-log-table" class="ui very compact table">';
        print_html += '<thead class="">';
        print_html += '<tr class="center aligned">';
        print_html += '<th style="text-align:center" class="ps-table-header ps-center ps-border-removed">Deposit Date</th>';
        print_html += '<th style="text-align:center" class="ps-table-header ps-center ps-border-removed">Payment Type</th>';
        print_html += '<th style="text-align:center" class="ps-table-header ps-center">Payment Amount</th>';
        print_html += '</tr>';
        print_html += '</thead>';
        print_html += '<tbody class="">';
        // for each payment log
        patient_statement_data.patientPaymentLogs.forEach((paymentLog, index) => {
            var lastRow = patient_statement_data.patientPaymentLogs.length - 1;

            if (index !== lastRow) {
                print_html += '<tr class="">';
                print_html += `<td class="left aligned ps-payment-log-padding-left ps-border-removed ps-border-bottom-removed">${paymentLog.depositDate}</td>`;
                print_html += `<td class="left aligned ps-payment-log-padding-left ps-border-removed ps-border-bottom-removed">${paymentLog.paymentType}</td>`;
                print_html += `<td class="right aligned ps-payment-log-padding-right ps-border-bottom-removed">${amount_formatter_without_dollar_sign(paymentLog.paymentAmount)}</td>`;
                print_html += '</tr>';
            }
            else {
                print_html += '<tr class="">';
                print_html += `<td class="left aligned ps-payment-log-padding-left ps-border-removed">${paymentLog.depositDate}</td>`;
                print_html += `<td class="left aligned ps-payment-log-padding-left ps-border-removed">${paymentLog.paymentType}</td>`;
                print_html += `<td class="right aligned ps-payment-log-padding-right">${amount_formatter_without_dollar_sign(paymentLog.paymentAmount)}</td>`;
                print_html += '</tr>';
            }
        });

        print_html += '</tbody>';
        print_html += '<tfoot class="">';
        print_html += '<tr class="">';
        print_html += '<td class="ps-border-removed"></td>';
        print_html += '<td class="right aligned ps-border-removed"><b>TOTALS</b></td>';
        print_html += `<td class="right aligned ps-payment-log-padding-right"><b>${currency_formatter(totals.paymentTotal)}</b></td>`;
        print_html += '</tr>';
        print_html += '</tfoot>';
        print_html += '</table>';
        print_html += '</div>';
        // end section 4
        // section 5 - statement balances
        print_html += '<div>';
        print_html += '<div class="ps-heading-block"></div>';
        print_html += '<table id="ps-patient-totals-table" class="ui very compact table">';
        print_html += '<thead class="">';
        print_html += '<tr class="center aligned">';
        print_html += '<th style="text-align:center" class="ps-table-header ps-center ps-border-removed">Total Balance</th>';
        print_html += '<th style="text-align:center" class="ps-table-header ps-center ps-border-removed">Claims in Process</th>';
        print_html += '<th style="text-align:center" class="ps-table-header ps-center">Pay this Amount</th>';
        print_html += '</tr>';
        print_html += '</thead>';
        print_html += '<tbody class="">';
        print_html += '<tr>';
        print_html += `<td style="text-align:center" class="ps-center ps-border-removed"><b>${currency_formatter(patient_statement_data.balances.totalBalance)}</b></td>`;
        print_html += `<td style="text-align:center" class="ps-center ps-border-removed"><b>${currency_formatter(patient_statement_data.balances.claimsInProcess)}</b></td>`;
        print_html += `<td style="text-align:center" class="ps-center"><b>${currency_formatter(patient_statement_data.balances.payThisAmount)}</b></td>`;
        print_html += '</tr>';
        print_html += '</tbody>';
        print_html += '</table>';
        print_html += '</div>';
        // end section 5
        //section 6 - patient statement footer
        print_html += '<div>';
        print_html += '<div>';
        // check to see if payment url is available
        if (patient_statement_data.statementFooter.onlinePaymentUrl !== '') {
            print_html += '<div class="ps-billing-block">';
            print_html += '<p class="ps-billing-header">Pay Your Bill Online:</p>';
            print_html += `<p class="ps-billing-text">${patient_statement_data.statementFooter.onlinePaymentUrl}</p>`;
            print_html += '</div>';
        }
        print_html += '<div class="ps-billing-block">';
        print_html += '<p class="ps-billing-header">Billing Questions:</p>';
        print_html += `<p class="ps-billing-text">${patient_statement_data.statementFooter.companyPhoneNumber}</p>`;
        print_html += '</div>';
        print_html += '</div>';
        print_html += '<div class="ps-footer-notice-block">';
        print_html += `<p class="ps-billing-text">(This statement may only contain payments received through ${patient_statement_data.statementFooter.statementDate}.)</p>`;
        print_html += '</div>';
        print_html += '</div>';
        print_html += '</div>';

        if (auto_print) {
            return print_html;
        }
        else {
            print_html_data_to_pdf(print_html, "print_report_button", "Patient Statement");
        }

    }
};