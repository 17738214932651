import * as moment from 'moment';
import * as React from "react";
import { connect } from "react-redux";
import { toastr } from 'react-redux-toastr';
import { Button, Checkbox, Dimmer, Form, Grid, Header, Loader } from "semantic-ui-react";
import * as session_storage from '../../../shared/session_storage_utility';
import ClaimAdjCodeSearch from "../../constants/component/claim_adjustment_code_advanced_search";
import { add_data, advanced_search_payer, get_data, get_search_data, update_data } from '../action/constants_action';
import * as admin_payment_const from '../constants';
import { set_focus_to_app_header } from './../../../shared/tab_navigation_utility';
import PromptNavigation from '../../../shared/component/prompt_navigation_component';
import * as global_constants from './../../../global_constants';
class ClaimAdjCodeCompanyViewEdit extends React.Component<any, any> {
    _is_mount = false;
    constructor(props) {
        super(props);
        this.state = {
            patient_id: session_storage.get("active_patient"),
            loading: false,
            control: null,
            search_form: {
                adj_code: '',
                payer_id: '',
                is_excluded: false,
                is_active: true
            },
            is_active: false,
            search_data: { rows: null, auto_search: null },
            adj_code_selected_row: null,
            payer_selected_row: null,
            claim_adj_search_data: null,
            payer_search_data: null,
            mode: '',
            row: null,
            adj_code_error: false,
            payer_error: false,
            by_pass_prompt:false,
            is_save_button_disabled: false
        };
    }
    default_search_form = null;
    initial_form_data = null;
    adjustment_code_enum = "adjustment_code";
    payer_enum = "payer";
    token = "";
    is_fetched_row = false;
    is_cancel = false;
    id = 0;
    search_criteria = null;
    payer_row = null;
    adj_code_row = null;
    initial_action = '';
    is_submitted = false;
    by_pass_prompt: boolean = false;
    go_next: boolean = false;
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.user_login_details != this.props.user_login_details) {
            this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        }        
    }

    // quick search handler in payer and adjustment code
    on_quick_search = async (params, type) => {
        let url = '';
        if (type == this.adjustment_code_enum) {
            url = admin_payment_const.claim_adjust_code.api.claim_adj_quick_search + "?keyword=" + params + "&pageSize=" + global_constants.constants.Quick_Search_Suggestion_List_Size;
        } else if (type == this.payer_enum) {
            url = admin_payment_const.claim_payer_search.api.payer_quick_search + "?keyword=" + params + "&page_size=" + global_constants.constants.Quick_Search_Suggestion_List_Size;
        }
        return await get_search_data(this.token, url);
    }
    UNSAFE_componentWillMount = () => {
        const { search_form } = this.state
        this.initial_form_data = { ...search_form };
        this.search_criteria = this.props.history.location && typeof this.props.history.location.state !=undefined ? this.props.history.location.state : null;
    }

    // advacned search for payer and adjustment code
    on_advanced_search = (params, type) => {

        if (this.adjustment_code_enum == type) {
            let url = admin_payment_const.claim_adjust_code.api.claim_adjcode_advanced_search;

            var q_str = Object.keys(params).map(key => {
                let key1 = key;
                if (key == 'page_size') {
                    key1 = "pageSize";
                } else if (key == "adjustment_code") {
                    key1 = "code"
                } else if (key == "adjustment_desc") {
                    key1 = "description"
                }
                return key1 + '=' + params[key]
            }).join('&');
            this.get_result(url + "?" + q_str + "");
        } else if (this.payer_enum == type) {
            params.Description = typeof params.Description == 'undefined' ? '' : params.Description;
            params.Outbound_id = typeof params.Outbound_id == 'undefined' ? '' : params.Outbound_id;
            params.name = typeof params.name == 'undefined' ? '' : params.name;
            params.Inbound_id = typeof params.Inbound_id == 'undefined' ? '' : params.Inbound_id;
            let url = admin_payment_const.claim_payer_search.api.payer_advanced_search;
            advanced_search_payer(this.token, url, params).then((res) => {
                let result = res.data;
                this.setState({
                    payer_search_data: {
                        rows: result.data,
                        column: admin_payment_const.claim_payer_search.column_defs
                    }
                })
            }, error => {
                if (error.response && error.response.data && error.response.data.messages) {
                    toastr.error('', error.response.data.messages[0].message);
                }
            });
        };

    }


    // filter and modify result
    get_result = (url) => {
        get_search_data(this.token, url).then((res) => {
            let result = res.data;
            if (result) {
                result = result.data.map((val, index) => {
                    val.is_active = val.is_active ? "Active" : "Inactive";
                    return val;
                });
            }
            if (this._is_mount) {
                this.setState({
                    claim_adj_search_data: {
                        rows: result,
                        column: admin_payment_const.claim_adjust_code.column_def
                    }
                });
            }
        }, error => {
            if (error.response && error.response.data && error.response.data.messages) {
                toastr.error('', error.response.data.messages[0].message);
            }
        });
    }

    
    // make http request on bases of request type get api result
    make_request = (url, type, data) => {
        let result = null;
        if (type == "GET") {
            return get_data(this.token, url);
        } else if (type == "POST") {
            return add_data(this.token, url, data);
        } else if (type == "PUT") {
            return update_data(this.token, url, data);
        }

        return result;
    }
    componentWillUnmount = () => {
        this._is_mount = false;
        document.body.classList.remove('admin-framework');
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[2] !== 'claim_adj_code_company' && session_storage.remove('claim_adj_comp_reason_code_search_criteria');
        }
    };

    // update state selected in searhc form
    update_data = (data, type) => {
        if (type == this.adjustment_code_enum) {
            let is_default_excluded = data ? (data.is_excluded != undefined ? data.is_excluded : data.adj_code_is_excluded) : ''
            data && (data.is_excluded = data.is_excluded.toString())
            if (this._is_mount) {
                this.setState({
                    search_form: {
                        ...this.state.search_form, adj_code: data ? data.carc : ''
                    },
                    adj_code_error: this.is_submitted ? (data ? (data.claim_adjustment_code_id ? false : true) : true):false,
                    //row: { is_default_excluded },
                    adj_code_selected_row: data
                });
            }
            this.adj_code_row= data;
        } else {
            if (this._is_mount) {
                this.setState({
                    search_form: {
                        ...this.state.search_form, payer_id: data ? data.payer_key_id : ''
                    }
                    ,
                    payer_error: this.is_submitted ? (data ? (data.payer_key_id ? false : true) : true):false,
                    payer_selected_row: data
                });
            }
            this.payer_row= data;
        }
        
    }

    // validate form
    validate_form = () => {
        this.is_submitted = true;
        let adj_code_error = false;
        let payer_error = false;
        if (this.state.search_form.adj_code == '') {
            adj_code_error = true;
        }

        if (this.state.search_form.payer_id == '') {
            payer_error = true;
        }
        if (this._is_mount) {
            this.setState({
                adj_code_error: adj_code_error,
                payer_error: payer_error
            });
        }
        if (adj_code_error || payer_error) {
            return false;
        }
        return true;
    }


    // button handler like save 
    button_handler = async(e, from_pop_up) => {
        e.preventDefault();
        this.go_next = false;
        this.is_submitted = true;
        const { search_form } = this.state        
        if (this.validate_form()) {
            let controls = this.get_control();
            if (this._is_mount) {
                this.setState({ loading: true, control: controls, is_save_button_disabled: true });
            }
            session_storage.set('claim_adj_comp_reason_code_search_criteria', {});
            let url = admin_payment_const.claim_adjust_code_company.api.claim_adjust_code_base_url;
            url = this.id ? url + "/" + this.props.match.params.id : url;
            let method = this.id ? "PUT" : "POST";
            await this.make_request(url, method, search_form).then(res => {
                if (res.data.status == 1) {
                    if (this.id) {
                        this.go_next = true;
                        toastr.success('', admin_payment_const.messages.update_adj_code_company);
                        this.setState({ loading: false, by_pass_prompt: !from_pop_up ? true : false, is_save_button_disabled: false })
                        this.initial_form_data = { ...search_form }                        
                        if (!from_pop_up && this.go_next) {
                            this.go_next = false;
                            this.props.history.goBack();
                        }                         
                    } else {
                        this.go_next = true;
                        toastr.success('', admin_payment_const.messages.add_adj_code_company);
                        this.setState({ loading: false, by_pass_prompt: !from_pop_up ? true : false, is_save_button_disabled: false})
                        this.initial_form_data = { ...search_form }                        
                        if (!from_pop_up && this.go_next) {
                            this.go_next = false;
                            this.props.history.goBack();
                        }
                    }

                } else {
                    if (res.data.messages && res.data.messages[0].code == '50002') {
                        toastr.error('', res.data.messages[0].message);
                    }
                    if (this._is_mount) {
                        this.setState({
                            loading: false,
                            adj_code_selected_row: { ...this.adj_code_row },
                            payer_selected_row: { ...this.payer_row }, 
                            is_save_button_disabled: false
                        });
                    }
                }
            }, error => {
                this.setState({ 
                    loading: false, 
                    is_save_button_disabled: false
                 });
                if (error.response && error.response.data && error.response.data.messages) {
                    toastr.error('', error.response.data.messages[0].message);
                }
            });
        } else {
            toastr.error('', admin_payment_const.mandatory_fields);
        }        
    }

    // display grid in view mode
    view_mode_detail = (row) => {

        return (<><Grid>
            <Grid.Column mobile={8} tablet={8} computer={4} >
                <Form.Field className="advance-quick-search">
                    <label>Created By </label>
                    <p>{row.created_by}</p>
                </Form.Field>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={8} computer={4} >
                <Form.Field className="advance-quick-search">
                    <label>Created Date </label>
                    <p>{moment(row.created_date).format('MM/DD/YYYY hh:mm:ss A')}</p>
                </Form.Field>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={8} computer={4} >
                <Form.Field className="advance-quick-search">
                    <label>Updated By </label>
                    <p>{row.updated_by}</p>
                </Form.Field>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={8} computer={4} >
                <Form.Field className="advance-quick-search">
                    <label>Updated Date </label>
                    <p>{moment(row.updated_date).format('MM/DD/YYYY hh:mm:ss A')}</p>
                </Form.Field>
            </Grid.Column>
        </Grid>
        </>
        );

    }

    // formate row on fetched data
    format_selected_row = (row) => {
        let formatted = {
            adjustment_code: {
                adj_code: row.adj_code,
                selected_row: {
                    carc: row.adj_code,
                    description: row.adj_code_desc,
                }
            },
            payer: {
                payer_id: row.payer_id,
                selected_row: {
                    name: row.payer,
                    description: row.payer_desc,
                }
            },
            is_excluded: row.is_excluded,
            is_active: row.is_active,
            created_by: row.created_by,
            is_default_excluded: row.adj_code_is_excluded,
            created_date: row.created_date,
            updated_date: row.updated_date,
            updated_by: row.updated_by,
        };
        return formatted;
    }


    // get view detail by id by api calling
    get_view_detail = async (view_id) => {
        let result = null;

        await this.make_request(admin_payment_const.claim_adjust_code_company.api.claim_adjust_code_base_url + "/" + view_id, "GET", null).then((res) => {
            if (res.data && res.data.data)
                result = this.format_selected_row(res.data.data);
            else {
                toastr.warning('', "No record found");
                this.props.history.push("/payments/claim_adj_code_company/search");
            }
        }, error => {
            if (error.response && error.response.data && error.response.data.messages) {
                toastr.error('', error.response.data.messages[0].message);
            }
        });

        if (this._is_mount) {   
            let search_form = {
                adj_code: result.adjustment_code.adj_code,
                payer_id: result.payer.payer_id,
                is_excluded: result.is_excluded,
                is_active: result.is_active
            }
            this.initial_form_data = { ...search_form }
            this.setState({
                is_fetched: this.is_fetched_row,
                adj_code_selected_row: result.adjustment_code.selected_row,
                payer_selected_row: result.payer.selected_row,
                search_form: {
                    ...this.state.search_form,
                    ...search_form
                },
                loading: false,
                row: result,
                is_active: result.is_active
            });            
        }
        this.default_search_form = {
            adj_code_selected_row: result.adjustment_code.selected_row,
            payer_selected_row: result.payer.selected_row,
            search_form: {
                adj_code: result.adjustment_code.adj_code,
                payer_id: result.payer.payer_id,
                is_excluded: result.is_excluded,
                is_active: result.is_active
            }
        };
        this.adj_code_row = result.adjustment_code.selected_row;
        this.payer_row = result.payer.selected_row;        
    }

    // get control data 
    get_control = () => {
        let controls = {
            adjustment_code: {
                type: "adjustment_code",
                grid_config: {
                    rows: null,
                    column: admin_payment_const.claim_adjust_code.column_def
                },
                control_id: 'CLAIM_ADJUSTMENT_CODE',
                error_message: 'No Record Found !',
                label: ["carc", "description"]
            },
            payer: {
                type: "payer",
                grid_config: {
                    rows: null,
                    column: admin_payment_const.claim_payer_search.column_defs
                },
                control_id: 'PAYER_DESCRIPTION',
                error_message: 'No Record Found !',
                label: ["name", "description"],
            },
        };
        return controls;
    }

    componentDidMount = () => {
        this._is_mount = true;
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.id = this.props.match.params ? this.props.match.params.id : 0;
        let controls = this.get_control();
        if (this.props.match.params.id) {
            if (this._is_mount) {
                this.setState({
                    loading: true,
                    control: controls
                });
            }
            this.is_fetched_row = true;
            this.get_view_detail(this.props.match.params.id);
        } else {
            if (this._is_mount) {
                this.setState({
                    control: controls
                });
            }
            this.default_search_form = {
                search_form: { ...this.state.search_form },
                payer_selected_row: null,
                adj_code_selected_row: null
            };
        }


    }
 // cancel the edit mode
    cancel_handler = () => {
        this.by_pass_prompt = true;
        this.setState({ by_pass_prompt: true }, () => this.props.history.goBack());
    };
    set_focus = () => {
        document.body.classList.add('admin-framework');
        let elem = document.getElementById('report-criteria-container');
        let all_form_elem = elem.getElementsByTagName('input');
        all_form_elem[0].focus();
    }
    render() {
        let is_excluded = this.state.search_form.is_excluded;
        const { by_pass_prompt, search_form } = this.state
        return (<>
            {!by_pass_prompt && (
                <PromptNavigation
                    is_data_changed={((JSON.stringify(this.initial_form_data) !== JSON.stringify(search_form)))}
                    save={e => this.button_handler(e, true)}
                    go_next_location={this.go_next}
                    history={this.props.history}
                    set_focus={this.set_focus}
                />
            )}
            <div className="common-forms-search common-forms-search-flex" >
                <Dimmer active={this.state.loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <Grid>
                    <Grid.Column computer={16}>
                        <Header as='h3' dividing textAlign="left">
                            Claim Adjustment Reason Codes - Company Payer Override
                        </Header>
                    </Grid.Column>
                </Grid>
                <div id="scrollable-content-area" style={{ padding: '15px 15px 20px 12px' }}>
                    <div className='patient_search_bottom_padding' id='report-criteria-container'>
                        <Form id="patient-search-criteria-container" className=' '  autoComplete="off" >
                            <Grid>

                                <Grid.Column mobile={8} tablet={16} computer={4}  >
                                    <Form.Field  >
                                        <label>Override for Company</label>
                                        <p></p>
                                        <p>{this.props.user_login_details.user_data.data.company_name}</p>
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column mobile={8} tablet={8} computer={5} >
                                    <Form.Field className={this.state.adj_code_error  ? "requiredWithBgColor advance-quick-search error" : "advance-quick-search"}>
                                        <label>Claim Adjustment Reason Code 
                                            <span className={this.state.adj_code_error  ? "req-alert" : "req-alert_normal"}>(required)</span>
                                        </label>
                                        <ClaimAdjCodeSearch
                                            control={this.state.control ? this.state.control.adjustment_code : null}
                                            update_data={this.update_data}
                                            search_result={this.state.claim_adj_search_data}
                                            on_quick_search={this.on_quick_search}
                                            on_advanced_search={this.on_advanced_search}
                                            is_focused={true}
                                            selected_row={this.state.adj_code_selected_row}                                            
                                            headerIdForGridTabNavigation={admin_payment_const.claim_adjust_code_header_id}
                                    />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column mobile={8} tablet={8} computer={5}  >
                                    <Form.Field className={this.state.payer_error  ? "requiredWithBgColor advance-quick-search error" : "advance-quick-search"}>
                                        <label>Payer <span className={this.state.payer_error ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                        <ClaimAdjCodeSearch
                                            on_quick_search={this.on_quick_search}
                                            on_advanced_search={this.on_advanced_search}
                                            search_result={this.state.payer_search_data}
                                            control={this.state.control ? this.state.control.payer : null}
                                            update_data={this.update_data}
                                            selected_row={this.state.payer_selected_row}
                                            headerIdForGridTabNavigation={admin_payment_const.claim_payer_search_header_id}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid>

                            <Grid>
                                <Grid.Column mobile={8} tablet={16} computer={4} className='empty_column '  >
                                </Grid.Column>
                                <Grid.Column mobile={8} tablet={8} computer={5} textAlign={'left'}  >
                                    <Form.Field className="" >
                                        <label></label>
                                        <Checkbox label='Is Excluded' tabIndex={0} checked={is_excluded} onChange={() =>
                                            this.setState((prev) => ({
                                                search_form: { ...prev.search_form, is_excluded: !this.state.search_form.is_excluded }
                                            }))
                                        } />

                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column mobile={8} tablet={8} computer={5}  verticalAlign={'bottom'} >
                                    <Form.Field className="">
                                        <label></label>
                                        <Checkbox label='Is Active'  checked={this.state.search_form.is_active} onChange={() =>
                                            this.setState((prev) => ({
                                                search_form: {
                                                    ...prev.search_form, is_active: !this.state.search_form.is_active
                                                },
                                                is_active: !this.state.is_active
                                            }))
                                        } />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid>
                            {this.state.row && this.id ? this.view_mode_detail(this.state.row) : ''}
                                <Grid>
                                    <Grid.Column mobile={8} tablet={8} computer={4} >
                                        <Form.Field className="advance-quick-search">
                                            <label>Default Exclude Value </label>
                                            <p>{(this.state.row && this.state.row.is_default_excluded) && "True" || "False"}</p>
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid>
                         </Form>
                       
                    </div>
                </div>
                <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area" id="applicationFooterSticky">                        
                    <Button type="reset" id="bg_fff1" onClick={(e) => this.cancel_handler()} basic >Cancel</Button>
                    <Button onKeyDown={set_focus_to_app_header} id="btn_save" type='submit' onClick={(e) => this.button_handler(e, false)} primary disabled={this.state.is_save_button_disabled}> Save</Button>
                </div>
            </div>
        </>
        );
    }

}


//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
    };
};
export default connect(mapStateToProps, null)(ClaimAdjCodeCompanyViewEdit);