import { Tab, Tabs } from '@blueprintjs/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Dimmer, Grid, Input, Loader, Radio, Segment } from 'semantic-ui-react';
import Selection from '../../../shared/component/selection_component';
import * as local_storage from '../../../shared/local_storage_utility';
import { log_error } from '../../../shared/action/shared_action';
import { get_adjustment_code } from '../../../payment/batch_payment/action/batch_payment_action';
import { get_lauch_darkley_key_value as get_launch_darkly_key_value } from '../../../shared/utility';
import * as constants from '../constants';
import * as constants_actions from '../action/constants_action';
import { toastr as toaster } from 'react-redux-toastr';
import * as _ from 'lodash';
import TabbedSingleSelect from './tabbed_single_select';

interface IFormFields {
  inboundId: string;
  payerId: number;
  description: string;
  insurance_details: Array<InsuranceDetail>;
}

interface InsuranceDetail{
  payerLevel: string;
  paymentCodeId: number;
  adjCodeId: number;
  insAdjustments: boolean;
  insAdjustmentsOff: boolean;
  insBalances: boolean;
  insBalancesOff: boolean;
  patBalances: boolean;
  patBalancesOff: boolean;
  patAdjCodeId: number;
  isSelected: boolean;
}

const DetailDefault: InsuranceDetail = {
  payerLevel: '',
  paymentCodeId: 0,
  adjCodeId: 0,
  insAdjustments: false,
  insAdjustmentsOff: false,
  insBalances: false,
  insBalancesOff: false,
  patBalances: false,
  patBalancesOff: false,
  patAdjCodeId: 0,
  isSelected: false
}
class PayerRulesAddEditDrawerComponent extends React.Component<any, any> {
  _isMount = false;
  is_user_partner_customer = false;
  current_user_type: any;
  company_service_type: any;
  selected_inbound: any;
  constructor(props) {
    super(props);
    this.state = {
      formFields: this.props.payerRule.originalRowData,
      copyFormFields: JSON.parse(JSON.stringify(this.props.payerRule.originalRowData)),
      isSubmitted: false,
      externalErrors: {},
      formErrors: {},
      isPatAdjPrimary: false,
      inbound_ids: [],
      payer_ids: [],
      payment_ids: [],
      adjustment_ids: [],
      is_error_control: {},
      is_selected_inbound: false,
      save_disable: false,
      inbound_disable: true,
      copySecDisable: true,
      copyPriTerDisable: true,
      copySecTerDisable: true,
      dropdownChanges: false,
      labelSave: this.props.openPanelMode.labelSave,
      ...this.handle_tabs_on_load(),
      loading: false
    };

    const auth_data = local_storage.get('auth_data');
    if (auth_data && auth_data.user_type_r3_id) {
      this.current_user_type = auth_data.user_type_r3_id;
      this.company_service_type = auth_data.company_service_type;
    }
    if(this.props.payerRule.originalRowData && this.props.openPanelMode.isUpdateRow) {
      this.currentFormFields = JSON.parse(JSON.stringify(this.props.payerRule.originalRowData));
      this.handle_tabs_on_load();
    }
    this.get_page_metadata();
  }
  select_option = { text: 'Select', value: 0, key: 'Select' };
  token: string = '';
  private currentFormFields: IFormFields = {
    inboundId: '',
    payerId: 0,
    description: '',
    insurance_details: [
      {...DetailDefault, payerLevel: "Primary"},
      {...DetailDefault, payerLevel: "Secondary"},
      {...DetailDefault, payerLevel: "Tertiary"}],
  };
  page_metadata: any;
  required_field: any = {
    inboundId: true,
    payerId: true
  };
  isUpdateHandlerCallback = true

  componentDidMount() {
    this._isMount = true;
    this.token = this.props.user_login_details.user_data.data.token_details.access_token;
    this.get_api_data();
    this.enableDisableTabButtons(this.page_metadata.tab_pri);
    this.enableDisableTabButtons(this.page_metadata.tab_sec);
  };

  componentWillUnmount() {
    this._isMount = false;
  }
  /*Dropdown API call*/
  get_api_data = async () => {
    this.setState({
      loading: true
    });
    const dropdown_data = {};
    dropdown_data['inbound_ids'] = await this.get_inbounds();
    dropdown_data['payer_ids'] = await this.get_payers(this.selected_inbound);
    dropdown_data['payment_ids'] = await this.get_payment_codes();
    dropdown_data['adjustment_ids'] = await this.get_adjustment_codes();

    if(this._isMount){
      this.setState(dropdown_data);
      this.setState({
        loading: false
      });
    }

  };

  get_page_metadata = () => {
    this.token = this.props.user_login_details.user_data.data.token_details.access_token;
    //Verifying page_name is correct or not.
    this.page_metadata = constants.payer_rule_summary;
  };

  get_inbounds = async () => {
    try {
      let response = await constants_actions.get_data(this.token, this.page_metadata.api.enhanced_inbound_per_tab.url);
      if (response.data && response.data.data && this._isMount) {
        if (this.props.openPanelMode.isUpdateRow) {
          let inbound = response.data.data.find(row => row.inboundId === this.currentFormFields.inboundId);
          if (inbound !== undefined && inbound !== null) {
            this.currentFormFields.inboundId = inbound.inboundId;
            this.setState({
              formFields: this.currentFormFields,
              save_disable: false,
              is_selected_inbound: true
            });
          }
        }
        this.setState({
          inbound_disable: false
        });

        if (this.props.openPanelMode.isCreateRow && this.props.openPanelMode.inboundId) {
          this.currentFormFields.inboundId = this.props.openPanelMode.inboundId;
          this.setState({
            formFields: this.currentFormFields,
            save_disable: false,
            is_selected_inbound: true,
            inbound_disable: true
          });
        }
        return [{
          key: "tab1",
          label: "Found on Release 835s",
          items: response.data.data.filter((row: any) => row.tab === 1).map((row: any) => ({ value: row.inboundId, label: row.inboundId !== null && row.inboundId !== undefined && row.payerDescription !== null && row.payerDescription !== undefined ? `${row.inboundId} - ${row.payerDescription}` : `${row.inboundId || ''}${row.payerDescription || ''}` }))
        }, {
          key: "tab2",
          label: "All Other IDs",
          items: response.data.data.filter((row: any) => row.tab === 2).map((row: any) => ({ value: row.inboundId, label: row.inboundId !== null && row.inboundId !== undefined ? row.inboundId : '' }))
        }]
      } else {
        return [];
      }
    } catch (error) {
      log_error(error);
    }
  };

  get_payers = (inboundId: string) => {
    return inboundId ? constants_actions.get_data(this.token, this.page_metadata.api.payer_rule_per_tab.url + inboundId).then(
      response => {
        if (response.data && response.data.data && this.state.is_selected_inbound && this._isMount) {
          if (this.props.openPanelMode.isCreateRow) {
            this.currentFormFields.payerId = 0;
          }
          this.setState({
            formFields: this.currentFormFields,
            save_disable: false
          });
          return [{
            key: "tab1",
            label: "Related Codes",
            items: response.data.data.filter((row: any) => row.tab === 1).map((row: any) => ({ value: row.payerId, label: row.payerCode !== null && row.payerCode !== undefined && row.payerDescription != null && row.payerDescription !== undefined ? `${row.payerCode} - ${row.payerDescription}` : `${row.payerCode || ''}${row.payerDescription || ''}` }))
          }, {
            key: "tab2",
            label: "All Other Codes",
            items: response.data.data.filter((row: any) => row.tab === 2).map((row: any) => ({ value: row.payerId, label: row.payerCode !== null && row.payerCode !== undefined && row.payerDescription != null && row.payerDescription !== undefined ? `${row.payerCode} - ${row.payerDescription}` : `${row.payerCode || ''}${row.payerDescription || ''}` }))
          }]
        } else {
          return [];
        }
      },
      error => {
        return [];
      }
    ) : [];
  };

  handle_tabs_on_load() {
    let defaultSelectedTabId = this.props.payerRule.originalRowData.claimTabId || 'Primary_id';

    return {
      defaultSelectedTabId
    };
  };

  saveHandler = (ev) => {
    ev.preventDefault();
    let payerRuleToSave = this.currentFormFields;
    let validationResult = true;
    validationResult = this.validationsPassed();
    if (validationResult) {
      this.props.saveHandlerCallback(payerRuleToSave);
      this.props.updateHandlerCallback(!this.isUpdateHandlerCallback);
    } else {
      toaster.error('', constants.payer_rule_summary.required_fields_message);
    }
    this.setState({
      isSubmitted: true
    });
  };

  validationsPassed() {
    let formIsValid = true;
    let errors = {};
    this.currentFormFields.inboundId = this.currentFormFields.inboundId.toString().trim();

    if (this.required_field.inboundId && (!this.currentFormFields.inboundId || this.currentFormFields.inboundId == '0')) {
      errors['inboundId'] = true;
      formIsValid = false;
    }
    if (this.required_field.payerId && (this.currentFormFields.payerId == 0 || isNaN(this.currentFormFields.payerId))) {
      errors['payerId'] = true;
      formIsValid = false;
    }

    if (!this.verifyTabRequired(this.page_metadata.tab_pri)) {
      errors[this.page_metadata.pay_code + this.page_metadata.tab_pri] = this.currentFormFields.insurance_details[this.page_metadata.tab_pri].paymentCodeId == 0;
      errors[this.page_metadata.adj_code + this.page_metadata.tab_pri] = this.currentFormFields.insurance_details[this.page_metadata.tab_pri].adjCodeId == 0;
      errors[this.page_metadata.pat_adj_code + this.page_metadata.tab_pri] = this.currentFormFields.insurance_details[this.page_metadata.tab_pri].patAdjCodeId == 0;
      formIsValid = false;
    }
    if (!this.verifyTabRequired(this.page_metadata.tab_sec)) {
      errors[this.page_metadata.pay_code + this.page_metadata.tab_sec] = this.currentFormFields.insurance_details[this.page_metadata.tab_sec].paymentCodeId == 0;
      errors[this.page_metadata.adj_code + this.page_metadata.tab_sec] = this.currentFormFields.insurance_details[this.page_metadata.tab_sec].adjCodeId == 0;
      errors[this.page_metadata.pat_adj_code + this.page_metadata.tab_sec] = this.currentFormFields.insurance_details[this.page_metadata.tab_sec].patAdjCodeId == 0;
      formIsValid = false;
    }
    if (!this.verifyTabRequired(this.page_metadata.tab_ter)) {
      errors[this.page_metadata.pay_code + this.page_metadata.tab_ter] = this.currentFormFields.insurance_details[this.page_metadata.tab_ter].paymentCodeId == 0;
      errors[this.page_metadata.adj_code + this.page_metadata.tab_ter] = this.currentFormFields.insurance_details[this.page_metadata.tab_ter].adjCodeId == 0;
      errors[this.page_metadata.pat_adj_code + this.page_metadata.tab_ter] = this.currentFormFields.insurance_details[this.page_metadata.tab_ter].patAdjCodeId == 0;
      formIsValid = false;
    }
    if (this._isMount) {
      this.setState({
        formErrors: errors
      });
    }
    return formIsValid;
  };

  onChangeDropdownHandler = (e) => {
    let { value, name } = e.target;
    value = parseInt(value)
    const dropdownName = name.split('_');
    const prefixName = dropdownName[0];
    const tabNumber = dropdownName[1];
    if (prefixName == 'paymentCodeId') {
      this.currentFormFields.insurance_details[tabNumber].paymentCodeId = value;
    } else if (prefixName == 'adjCodeId') {
      this.currentFormFields.insurance_details[tabNumber].adjCodeId = value;
    }else {
      this.currentFormFields.insurance_details[tabNumber].patAdjCodeId = value;
    }
    this.setState({
      formFields: this.currentFormFields
    });
    this.enableDisableTabButtons(tabNumber);
    this.validationsPassed();
    this.notify_changes();
  };

  // Dropdown change Handler
  on_dropdown_change = async ({ value }, e) => {
    this.props.updateHandlerCallback(this.isUpdateHandlerCallback);
    const { name } = e.target;
    if (name === "payerId") {
      value = parseInt(value);
    } else {
      value = value ? `${value}` : '';
    }
    this.currentFormFields = {
      ...this.currentFormFields,
      [name]: value
    };
    const dropdown_data = {};
    if (name == 'inboundId' && value != null && `${value}`.trim().length > 1) {
      this.setState({ is_selected_inbound: true, save_disable: true, dropdownChanges: true });
      dropdown_data['payer_ids'] = await this.get_payers(this.selected_inbound = value.trim());
      this._isMount && this.setState(dropdown_data);
    } else if (name == 'inboundId') {
      dropdown_data['payer_ids'] = [];
      this.currentFormFields.payerId = 0;
      this.setState({ is_selected_inbound: true, save_disable: true, dropdown_data, dropdownChanges: true });
    }
    this._isMount && this.setState({
      formFields: this.currentFormFields
    },this.notify_changes);
    this.validationsPassed();
  };

  onChangePostSkipHandler = (e: any, radio_val: string, tab_number: any) => {
    if (radio_val === this.page_metadata.rd_post + tab_number) {
      this.currentFormFields['insurance_details'][tab_number].insAdjustments = true;
      this.currentFormFields['insurance_details'][tab_number].insAdjustmentsOff = false;
    } else {
      this.currentFormFields['insurance_details'][tab_number].insAdjustmentsOff = true;
      this.currentFormFields['insurance_details'][tab_number].insAdjustments = false;
    }
    if (this._isMount) {
      this.setState({
        formFields: this.currentFormFields
      });
    }
    this.enableDisableTabButtons(tab_number);
    this.notify_changes();
  };

  onChangeKeepTransferHandler = (e: any, radio_val: string, tab_number: any) => {
    if (radio_val === this.page_metadata.rd_current + tab_number) {
      this.currentFormFields['insurance_details'][tab_number].insBalances = true;
      this.currentFormFields['insurance_details'][tab_number].insBalancesOff = false;
    } else {
      this.currentFormFields['insurance_details'][tab_number].insBalancesOff = true;
      this.currentFormFields['insurance_details'][tab_number].insBalances = false;
    }
    if (this._isMount) {
      this.setState({
        formFields: this.currentFormFields
      });
    }
    this.enableDisableTabButtons(tab_number);
    this.notify_changes();
  };

  onChangeKeepAdjustHandler = (e: any, radio_val: string, tab_number: any) => {
    if (radio_val === this.page_metadata.rd_keep + tab_number) {
      this.currentFormFields['insurance_details'][tab_number].patBalances = true;
      this.currentFormFields['insurance_details'][tab_number].patBalancesOff = false;
    } else {
      this.currentFormFields['insurance_details'][tab_number].patBalancesOff = true;
      this.currentFormFields['insurance_details'][tab_number].patBalances = false;
      this.currentFormFields['insurance_details'][tab_number].patAdjCodeId = 0;
    }
    if (this._isMount) {
      this.setState({
        formFields: this.currentFormFields
      });
    }
    this.enableDisableTabButtons(tab_number);
    this.notify_changes();
  };

  get_payment_codes = () => {
    if (this.props.user_login_details.master_data &&
      this.props.user_login_details.master_data.data &&
      this.props.user_login_details.master_data.data.payment_code) {
      let payment_code_data = this.props.user_login_details.master_data.data.payment_code.filter(code => code.is_active == true);
      let data = payment_code_data.sort((obj1, obj2) => {
        let code1 = obj1.payment_code || '';
        let code2 = obj2.payment_code || '';
        const value1 = code1.toString().toLowerCase();
        const value2 = code2.toString().toLowerCase();
        if (value1 < value2) {
          return -1;
        }
        if (value1 > value2) {
          return 1;
        }
        return 0;
      });
      return data.map((val, index) => {
        return {
          key: index,
          value: val.payment_id,
          text: `${val.payment_code} - ${val.payment_desc}`,
          data: val
        }
      });
    } else {
      return [];
    }
  };

  get_adjustment_codes = async () => {
    try {
      let response = await get_adjustment_code(this.token);
      if (response.data && response.data.data) {
        let data = response.data.data.sort((obj1, obj2) => {
          let code1 = obj1.adjustment_code || '';
          let code2 = obj2.adjustment_code || '';
          const value1 = code1.toString().toLowerCase();
          const value2 = code2.toString().toLowerCase();
          if (value1 < value2) {
            return -1;
          }
          if (value1 > value2) {
            return 1;
          }
          return 0;
        });
        return data.map((val, index) => {
          return {
            key: index,
            value: val.adjustment_code_id,
            text: `${val.adjustment_code} - ${val.adjustment_desc}`,
            data: val
          }
        })
      } else {
        return [];
      }
    } catch (error) {
      log_error(error);
    }
  };

  copyToSecondary = (e) => {
    e.preventDefault();
    this.copyFromTo(this.page_metadata.tab_pri, this.page_metadata.tab_sec);
    this.enableDisableTabButtons(this.page_metadata.tab_sec);
    this.notify_changes();
  };

  copyToTertiary = (e: any, tab_number: any)  => {
    e.preventDefault();
    this.copyFromTo(tab_number, this.page_metadata.tab_ter);
    this.notify_changes();
  };

  copyFromTo = (fromTab, toTab) => {
    this.currentFormFields.insurance_details[toTab].paymentCodeId =  this.currentFormFields.insurance_details[fromTab].paymentCodeId;
    this.currentFormFields.insurance_details[toTab].adjCodeId =  this.currentFormFields.insurance_details[fromTab].adjCodeId;
    this.currentFormFields.insurance_details[toTab].insAdjustments =  this.currentFormFields.insurance_details[fromTab].insAdjustments;
    this.currentFormFields.insurance_details[toTab].insAdjustmentsOff =  this.currentFormFields.insurance_details[fromTab].insAdjustmentsOff;
    this.currentFormFields.insurance_details[toTab].insBalances =  this.currentFormFields.insurance_details[fromTab].insBalances;
    this.currentFormFields.insurance_details[toTab].insBalancesOff =  this.currentFormFields.insurance_details[fromTab].insBalancesOff;
    this.currentFormFields.insurance_details[toTab].patBalances =  this.currentFormFields.insurance_details[fromTab].patBalances;
    this.currentFormFields.insurance_details[toTab].patBalancesOff =  this.currentFormFields.insurance_details[fromTab].patBalancesOff;
    this.currentFormFields.insurance_details[toTab].patAdjCodeId =  this.currentFormFields.insurance_details[fromTab].patAdjCodeId;
    this.setState({
      formFields: this.currentFormFields
    });
    this.validationsPassed();
  };

  enableDisableTabButtons = (tab_number: any) => {
    if (tab_number < this.page_metadata.tab_ter) {
      if(this.verifyTabRequired(tab_number)){
        this.setStateTabButtons(tab_number, false);
      } else {
        this.setStateTabButtons(tab_number, true);
      }
    }
  };

  setStateTabButtons = (tab_number: any, stateTabButton: boolean) => {
    if(tab_number == this.page_metadata.tab_pri){
      this.setState({
        copySecDisable: stateTabButton,
        copyPriTerDisable: stateTabButton
      });
    } else if (tab_number == this.page_metadata.tab_sec){
      this.setState({
        copySecTerDisable: stateTabButton
      });
    }
  };

  verifyTabRequired = (tab_number: any) => {
    if (this.currentFormFields.insurance_details[tab_number].paymentCodeId > 0 &&
      this.currentFormFields.insurance_details[tab_number].adjCodeId > 0) {
      if (this.verifyRadiosRequired(tab_number)) {
        return this.verifyPatAdjustment(tab_number);
      }
    }
    return false;
  };

  verifyRadiosRequired = (tab_number: any) => {
    if (this.currentFormFields.insurance_details[tab_number].insAdjustments ||
      this.currentFormFields.insurance_details[tab_number].insAdjustmentsOff) {
      if (this.currentFormFields.insurance_details[tab_number].insBalances ||
        this.currentFormFields.insurance_details[tab_number].insBalancesOff) {
        if (this.currentFormFields.insurance_details[tab_number].patBalances ||
          this.currentFormFields.insurance_details[tab_number].patBalancesOff) {
          return true;
        }
      }
    }
    return false;
  };

  verifyPatAdjustment = (tab_number: any) => {
    if (this.currentFormFields.insurance_details[tab_number].patBalances ||
      (this.currentFormFields.insurance_details[tab_number].patBalancesOff &&
        this.currentFormFields.insurance_details[tab_number].patAdjCodeId > 0)) {
      return true;
    }
    return false;
  };

  onChangeInputsHandler = e => {
    const input_field = e.target.name;
    let input_value = e.target.value;
    this.currentFormFields[input_field] = input_value;
    if(this._isMount) {
      this.setState({
        formFields: this.currentFormFields
      });
    }
    this.notify_changes();
  };

  notify_changes = () => {
    !(_.isEqual(this.state.formFields, this.state.copyFormFields))?this.props.updateHandlerCallback(this.isUpdateHandlerCallback):this.props.updateHandlerCallback(!this.isUpdateHandlerCallback);
  }

  render() {
    const { externalErrors, formErrors, isSubmitted } = this.state;
    const inbounds = this.state.inbound_ids;
    const payers = this.state.payer_ids;
    const payment_ids = [this.select_option, ...this.state.payment_ids];
    const adjustment_ids = [this.select_option, ...this.state.adjustment_ids];
    return (
      <React.Fragment>
        <Dimmer active={this.state.loading}>
          <Loader size='massive'>Loading</Loader>
        </Dimmer>
        <div className='bp3-dialog-save-button'>
          <Button
            id='save_payer_rule_btn'
            className='primary'
            type='button'
            disabled={this.props.save_disable}
            onClick={(e) => this.saveHandler(e)}
            content={this.state.labelSave}
          />
        </div>
        <Grid columns='equal'>
          <Grid.Row>
            <Grid.Column>
              <label>
                Inbound ID<span
                  className={'req req-alert_normal'}
                  dangerouslySetInnerHTML={{ __html: '*' }}
                />
              </label>
              <TabbedSingleSelect
                id='inboundId'
                defaultValue={this.currentFormFields.inboundId}
                sources={inbounds}
                onChange={(value: any) => { this.on_dropdown_change(value, { target: { name: 'inboundId' } }) }}
                required={isSubmitted && formErrors.inboundId}
                disabled={this.props.save_disable || this.state.inbound_disable}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <label>
                Payer Code<span
                  className={'req req-alert_normal'}
                  dangerouslySetInnerHTML={{ __html: '*' }}
                />
              </label>
              <TabbedSingleSelect
                id='payerId'
                defaultValue={this.currentFormFields.payerId}
                sources={payers}
                onChange={(value: any) => { this.on_dropdown_change(value, { target: { name: 'payerId' } }) }}
                disabled={!this.state.is_selected_inbound || this.state.save_disable}
                required={isSubmitted && formErrors.payerId}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <label>
                Description
              </label>
              <Input
                autoComplete='off'
                name='description'
                id='description'
                type='text'
                disabled={this.props.save_disable}
                defaultValue={this.currentFormFields.description || ''}
                maxLength={250}
                onChange={(e) => this.onChangeInputsHandler(e)}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className='last-row'>
            <Segment>
              <Tabs animate={true} id='billing-tab-group' defaultSelectedTabId={this.state.defaultSelectedTabId} className="advance-quick-search" >
                <Tab
                  id='Primary_id'
                  title={this.renderTitle('Primary')}
                  panel={this.renderPrimaryPanel(payment_ids, adjustment_ids)} />
                <Tab
                  id='Secondary_id'
                  title={this.renderTitle('Secondary')}
                  panel={this.renderSecondaryPanel(payment_ids, adjustment_ids)} />
                <Tab
                  id='Tertiary_id'
                  title={this.renderTitle('Tertiary')}
                  panel={this.renderTertiaryPanel(payment_ids, adjustment_ids)} />
              </Tabs>
            </Segment>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }

  private renderTitle = (title) => {
    return (<span>{title}<span
      className={'req req-alert_normal'}
      dangerouslySetInnerHTML={{ __html: '*' }}
    /></span>);
  };

  private renderPrimaryPanel = (payment_ids, adjustment_ids) => {
    return this.getTabPanel(this.page_metadata.tab_pri, payment_ids, adjustment_ids);
  };
  private renderSecondaryPanel = (payment_ids, adjustment_ids) => {
    return this.getTabPanel(this.page_metadata.tab_sec, payment_ids, adjustment_ids);
  };
  private renderTertiaryPanel = (payment_ids, adjustment_ids) => {
    return this.getTabPanel(this.page_metadata.tab_ter, payment_ids, adjustment_ids);
  };

  private getTabPanel = (tab_number, payment_ids, adjustment_ids) => {
    const { externalErrors, formErrors, isSubmitted } = this.state;
    return (
      <Grid style={{ marginTop: '-12%' }}>
        <Grid.Row>
          <Grid.Column tablet={16} computer={8}>
            <label>
              <span>Payment Code<span
                className={'req req-alert_normal'}
                dangerouslySetInnerHTML={{ __html: '*' }}
              /></span>
            </label>
            <Selection
              id={this.page_metadata.pay_code + tab_number}
              name={this.page_metadata.pay_code + tab_number}
              disabled={this.props.save_disable}
              options={payment_ids}
              onChange={(value, e) => {
                this.onChangeDropdownHandler(e);
              }}
              defaultValue={this.currentFormFields.insurance_details[tab_number].paymentCodeId}
              isRequired={isSubmitted && formErrors[this.page_metadata.pay_code + tab_number]}
            />
          </Grid.Column>
          <Grid.Column tablet={16} computer={8}>
            <label>
              <span>Adjustment Code<span
                className={'req req-alert_normal'}
                dangerouslySetInnerHTML={{ __html: '*' }}
              /></span>
            </label>
            <Selection
              id={this.page_metadata.adj_code + tab_number}
              name={this.page_metadata.adj_code + tab_number}
              disabled={this.props.save_disable}
              options={adjustment_ids}
              onChange={(value, e) => {
                this.onChangeDropdownHandler(e);
              }}
              defaultValue={this.currentFormFields.insurance_details[tab_number].adjCodeId}
              isRequired={isSubmitted && formErrors[this.page_metadata.adj_code + tab_number]}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16}>
            <label>
              <span>Insurance Adjustments<span
                className={'req req-alert_normal'}
                dangerouslySetInnerHTML={{ __html: '*' }}
              /></span>
            </label>
          </Grid.Column>
          <Grid.Column tablet={16} computer={16}>
            <Radio
              className='providers-ui-radio-ssn'
              label='Post'
              value={this.page_metadata.rd_post + tab_number}
              name={'pst_skp_flag_group_' + tab_number}
              checked={this.currentFormFields.insurance_details[tab_number].insAdjustments}
              onChange={(e) => this.onChangePostSkipHandler(e, this.page_metadata.rd_post + tab_number, tab_number)}
            />
            <Radio
              className='providers-ui-radio-tin'
              label='Skip'
              value={this.page_metadata.rd_skip + tab_number}
              name={'pst_skp_flag_group_' + tab_number}
              checked={this.currentFormFields.insurance_details[tab_number].insAdjustmentsOff}
              onChange={(e) => this.onChangePostSkipHandler(e, this.page_metadata.rd_skip + tab_number, tab_number)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16}>
            <label>
              <span>Insurance Balances<span
                className={'req req-alert_normal'}
                dangerouslySetInnerHTML={{ __html: '*' }}
              /></span>
            </label>
          </Grid.Column>
          <Grid.Column tablet={16} computer={16}>
            <Radio
              className='providers-ui-radio-ssn'
              label='Keep Current'
              value={this.page_metadata.rd_current + tab_number}
              name={'kct_ttn_flag_group_' + tab_number}
              checked={this.currentFormFields.insurance_details[tab_number].insBalances}
              onChange={(e) => this.onChangeKeepTransferHandler(e, this.page_metadata.rd_current + tab_number, tab_number)}
            />
            <Radio
              className='providers-ui-radio-tin'
              label='Transfer to Next'
              value={this.page_metadata.rd_next + tab_number}
              name={'kct_ttn_flag_group_' + tab_number}
              checked={this.currentFormFields.insurance_details[tab_number].insBalancesOff}
              onChange={(e) => this.onChangeKeepTransferHandler(e, this.page_metadata.rd_next + tab_number, tab_number)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16}>
            <label>
              <span>Patient Balances<span
                className={'req req-alert_normal'}
                dangerouslySetInnerHTML={{ __html: '*' }}
              /></span>
            </label>
          </Grid.Column>
          <Grid.Column tablet={16} computer={16}>
            <Radio
              className='providers-ui-radio-ssn'
              label='Keep'
              value={this.page_metadata.rd_keep + tab_number}
              name={'kep_adj_flag_group_' + tab_number}
              checked={this.currentFormFields.insurance_details[tab_number].patBalances}
              onChange={(e) => this.onChangeKeepAdjustHandler(e, this.page_metadata.rd_keep + tab_number, tab_number)}
            />
            <Radio
              className='providers-ui-radio-tin'
              label='Adjust'
              value={this.page_metadata.rd_adjust + tab_number}
              name={'kep_adj_flag_group_' + tab_number}
              checked={this.currentFormFields.insurance_details[tab_number].patBalancesOff}
              onChange={(e) => this.onChangeKeepAdjustHandler(e, this.page_metadata.rd_adjust + tab_number, tab_number)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {this.currentFormFields.insurance_details[tab_number].patBalancesOff && (<Grid.Column tablet={16} computer={8} textAlign='left'>
            <label style={{ width: "190px", display: "inline-block" }}>
              <span>Patient Adjustment Code<span
                className={'req req-alert_normal'}
                dangerouslySetInnerHTML={{ __html: '*' }}
              /></span>
            </label>
            <Selection
              id={this.page_metadata.pat_adj_code + tab_number}
              name={this.page_metadata.pat_adj_code + tab_number}
              disabled={this.props.save_disable}
              options={adjustment_ids}
              onChange={(value, e) => {
                this.onChangeDropdownHandler(e);
              }}
              defaultValue={this.currentFormFields.insurance_details[tab_number].patAdjCodeId}
              isRequired={isSubmitted && formErrors[this.page_metadata.pat_adj_code + tab_number]}
            />
          </Grid.Column>
          )}
          <Grid.Column tablet={16} computer={8} className='empty_column '></Grid.Column>
        </Grid.Row>
        <br />
        {tab_number < this.page_metadata.tab_ter && (<Grid.Row>
          <Grid.Column tablet={16} computer={16}>
            {tab_number == this.page_metadata.tab_pri && (
              <Button
                id={'apply_secondary'+ tab_number}
                type='button'
                disabled={this.state.copySecDisable}
                onClick={(e) => this.copyToSecondary(e)}
                basic content="Apply to Secondary" />
            )}
            <Button
              id={'apply_tertiary'+ tab_number}
              type='button'
              disabled={(tab_number == this.page_metadata.tab_pri && this.state.copyPriTerDisable)
                || (tab_number == this.page_metadata.tab_sec && this.state.copySecTerDisable)}
              onClick={(e) => this.copyToTertiary(e, tab_number)}
              basic content="Apply to Tertiary" />
          </Grid.Column>
        </Grid.Row>
        )}
      </Grid>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    user_login_details: state.user_login_details
  };
};

export default connect(mapStateToProps)(PayerRulesAddEditDrawerComponent);
