import { currencyFormatter, dateFormatter } from '../reports/report_constants';
import { strip } from '../shared/component/grid/utils';
import { removeClass, addClass } from '../shared/utility';
import * as _ from 'lodash';
import { requestPatientStatements } from './billing_constants';
import { enum_user_type_r3_id } from '../global_constants';
import * as local_storage from '../shared/local_storage_utility';

const checkbox_elements = (type, altr) => {
  return {
    [`checkbox_${type}`]: document.querySelector(`.ag-cell-label-container.checkbox #${type}`) as HTMLInputElement,
    [`checked_${type}`]: document.querySelector(`.ag-cell-label-container.checkbox #checked_${type}`) as HTMLElement,
    [`unchecked_${type}`]: document.querySelector(
      `.ag-cell-label-container.checkbox #unchecked_${type}`
    ) as HTMLElement,
    [`indeterminate_${type}`]: document.querySelector(`.ag-cell-label-container.checkbox #indeterminate_${type}`),

    [`checkbox_${altr}`]: document.querySelector(`.ag-cell-label-container.checkbox #${altr}`) as HTMLInputElement,
    [`checked_${altr}`]: document.querySelector(`.ag-cell-label-container.checkbox #checked_${altr}`) as HTMLElement,
    [`unchecked_${altr}`]: document.querySelector(
      `.ag-cell-label-container.checkbox #unchecked_${altr}`
    ) as HTMLElement,
    [`indeterminate_${altr}`]: document.querySelector(
      `.ag-cell-label-container.checkbox #indeterminate_${altr}`
    ) as HTMLElement
  };
};

const checkboxElementById = (id:string) => {
  return {
    [`checkbox_${id}`]: document.querySelector(`.ag-cell-label-container.checkbox #${id}`) as HTMLInputElement,
    [`checked_${id}`]: document.querySelector(`.ag-cell-label-container.checkbox #checked_${id}`) as HTMLElement,
    [`unchecked_${id}`]: document.querySelector(
      `.ag-cell-label-container.checkbox #unchecked_${id}`
    ) as HTMLElement,
    [`indeterminate_${id}`]: document.querySelector(`.ag-cell-label-container.checkbox #indeterminate_${id}`)
  };
};

const select_rows_checkbox = (params, type, altr, compare_field, field_not_equal, paginated) => {
  let counts = {
    [`${type}_length`]: 0,
    [`${altr}_length`]: 0,
    enable_checkbox_length: 0
  };

  params.api.forEachNodeAfterFilterAndSort((node) => {
    let item = node.data;
    if (paginated) {
      if (
        node.rowIndex >= params.api.paginationProxy.topRowIndex &&
        node.rowIndex <= params.api.paginationProxy.bottomRowIndex
      ) {
        if (item[compare_field] !== field_not_equal) {
          counts.enable_checkbox_length++;
          if (item[type]) {
            counts[`${type}_length`]++;
          }
          if (item[altr]) {
            counts[`${altr}_length`]++;
          }
        }
      }
    } else {
      if (item[compare_field] !== field_not_equal) {
        counts.enable_checkbox_length++;
        if (item[type]) {
          counts[`${type}_length`]++;
        }
        if (item[altr]) {
          counts[`${altr}_length`]++;
        }
      }
    }
  });
};

export const cell_checkbox_renderr = (params, is_disabled: string, type: string) => {
  let id = is_disabled ? `disabled_${type}_${params.data.row_index}` : `checkb_${type}_${params.data.row_index}`;
  let spn = is_disabled ? `disabled_span${type}_${params.data.row_index}` : `span_${type}_${params.data.row_index}`;
  return `
      <span ref="eCellWrapper" class="ag-cell-wrapper grid-checkbox" id='${spn}'>
        <input type='checkbox' ${params.value ? 'checked' : ''} ${is_disabled ? 'disabled' : ''} id="${id}" />
        <span class="ag-selection-checkbox">
          <span class="ag-icon ag-icon-checkbox-checked ${params.value ? '' : 'ag-hidden'}"></span>
          <span class="ag-icon ag-icon-checkbox-unchecked ${!params.value ? '' : 'ag-hidden'} ${
    is_disabled ? 'disabled' : ''
  }"></span>
        </span>
      </span>`;
};

export const handle_cell_checkbox = (
  params,
  type: string,
  altr: string,
  compare_field: string,
  field_not_equal: string | boolean,
    paginated = false,
  allow_close=false
) => {
  let eDiv = document.createElement('div');
  eDiv.innerHTML = `${cell_checkbox_renderr(params, params.data[`disabled_${type}`], type)}`;
  let eCheckbox = eDiv.querySelector('input[type=checkbox]');
  if (eCheckbox) {
      if (params.data[compare_field] !== field_not_equal || allow_close) {
      let chb = document.querySelector(`#span_${altr}_${params.data.row_index}`);
      let chb1 = document.querySelector(`#span_${type}_${params.data.row_index}`);
      if (params.data[type]) {
        let al = document.querySelector(`#checkb_${altr}_${params.data.row_index}`);
        if (al) {
          al.setAttribute('disabled', 'true');
          chb ? addClass(chb.querySelector('.ag-icon-checkbox-unchecked'), 'disabled') : '';
        }
        let al1 = document.querySelector(`#checkb_${type}_${params.data.row_index}`);
        if (al1) {
          al1.removeAttribute('disabled');
          chb1 ? removeClass(chb1.querySelector('.ag-icon-checkbox-unchecked'), 'disabled') : '';
        }
      } else {
        let al = document.querySelector(`#checkb_${type}_${params.data.row_index}`);
        if (al) {
          al.removeAttribute('disabled');
          chb1 ? removeClass(chb.querySelector('.ag-icon-checkbox-unchecked'), 'disabled') : '';
        }
        let al1 = document.querySelector(`#checkb_${altr}_${params.data.row_index}`);
        if (al1) {
          al1.removeAttribute('disabled');
          chb ? removeClass(chb.querySelector('.ag-icon-checkbox-unchecked'), 'disabled') : '';
        }
      }
    }
    eCheckbox.addEventListener('change', (event: any) => {
      params.node.setDataValue(type, event.target.checked);
      params.node.setDataValue(altr, false);
        update_header_checkbox_status(params, type, altr, compare_field, field_not_equal, paginated, allow_close);
    });
  }
  return eDiv;
};

export const update_header_checkbox_status = (
  params,
  type: string,
  altr: string,
  compare_field: string,
  field_not_equal: string | boolean,
    paginated,
    allow_close=false
) => {
  let elements: any = checkbox_elements(type, altr);
  let counts = {
    [`${type}_length`]: 0,
    [`${altr}_length`]: 0,
    enable_checkbox_length: 0
  };
  params.api.forEachNodeAfterFilterAndSort((node) => {
    let item = node.data;
    if (paginated) {
      if (
        node.rowIndex >= params.api.paginationProxy.topRowIndex &&
        node.rowIndex <= params.api.paginationProxy.bottomRowIndex
      ) {
          if (item[compare_field] !== field_not_equal || allow_close) {
          counts.enable_checkbox_length++;
          if (item[type]) {
            counts[`${type}_length`]++;
          }
          if (item[altr]) {
            counts[`${altr}_length`]++;
          }
        }
      }
    } else {
        if (item[compare_field] !== field_not_equal || allow_close) {
        counts.enable_checkbox_length++;
        if (item[type]) {
          counts[`${type}_length`]++;
        }
        if (item[altr]) {
          counts[`${altr}_length`]++;
        }
      }
    }
  });

  if (elements[`checkbox_${type}`]) {
    elements[`checkbox_${type}`].checked = false;
  }
  if (counts.enable_checkbox_length === counts[`${type}_length`]) {
    if (elements[`checked_${type}`]) {
      removeClass(elements[`checked_${type}`], 'ag-hidden');
      addClass(elements[`indeterminate_${type}`], 'ag-hidden');
      addClass(elements[`unchecked_${type}`], 'ag-hidden');
      removeClass(elements[`unchecked_${type}`], 'disabled');
    }
    if (elements[`checked_${altr}`]) {
      addClass(elements[`checked_${altr}`], 'ag-hidden');
      addClass(elements[`indeterminate_${altr}`], 'ag-hidden');
      removeClass(elements[`unchecked_${altr}`], 'ag-hidden');
      addClass(elements[`unchecked_${altr}`], 'disabled');
      elements[`checkbox_${altr}`].disabled = true;
    }
  } else if (counts[`${type}_length`] > 0) {
    if (elements[`checked_${type}`]) {
      addClass(elements[`checked_${type}`], 'ag-hidden');
      addClass(elements[`unchecked_${type}`], 'ag-hidden');
      removeClass(elements[`indeterminate_${type}`], 'ag-hidden');
    }
  } else {
    if (elements[`checked_${type}`]) {
      addClass(elements[`checked_${type}`], 'ag-hidden');
      addClass(elements[`indeterminate_${type}`], 'ag-hidden');
      removeClass(elements[`unchecked_${type}`], 'ag-hidden');
      removeClass(elements[`unchecked_${type}`], 'disabled');
    }
    if (elements[`checked_${altr}`]) {
      addClass(elements[`checked_${altr}`], 'ag-hidden');
      addClass(elements[`indeterminate_${altr}`], 'ag-hidden');
      removeClass(elements[`unchecked_${altr}`], 'ag-hidden');
      removeClass(elements[`unchecked_${altr}`], 'disabled');
      elements[`checkbox_${altr}`].disabled = false;
    }
  }
};

export const handle_header_checkbox = (params, type, altr, compare_field, field_not_equal, paginated = false,allow_close=false) => {
  let elements: any = checkbox_elements(type, altr);
  let counts = {
    [`${type}_length`]: 0,
    [`${altr}_length`]: 0,
    enable_checkbox_length: 0
  };
  params.api.forEachNodeAfterFilterAndSort((node) => {
    let item = node.data;
    if (paginated) {
      if (
        node.rowIndex >= params.api.paginationProxy.topRowIndex &&
        node.rowIndex <= params.api.paginationProxy.bottomRowIndex
      ) {
          if (item[compare_field] !== field_not_equal || allow_close) {
          counts.enable_checkbox_length++;
          if (item[type]) {
            counts[`${type}_length`]++;
          }
          if (item[altr]) {
            counts[`${altr}_length`]++;
          }
        }
      }
    } else {
        if (item[compare_field] !== field_not_equal || allow_close) {
        counts.enable_checkbox_length++;
        if (item[type]) {
          counts[`${type}_length`]++;
        }
        if (item[altr]) {
          counts[`${altr}_length`]++;
        }
      }
    }
  });

    
  if (counts.enable_checkbox_length === counts[`${type}_length`]) {
    if (elements[`checked_${type}`]) {
      addClass(elements[`checked_${type}`], 'ag-hidden');
      addClass(elements[`indeterminate_${type}`], 'ag-hidden');
      removeClass(elements[`unchecked_${type}`], 'ag-hidden');
      addClass(elements[`unchecked_${type}`], 'disabled');
      elements[`checkbox_${altr}`].disabled = true;
    }
  } else if (allow_close) {
      if (elements[`checked_${type}`]) {
          elements[`checkbox_${type}`].disabled = false;
          removeClass(elements[`unchecked_${type}`], 'disabled');
      }
  }

  if (counts.enable_checkbox_length === counts[`${altr}_length`]) {
    if (elements[`checked_${altr}`]) {
      addClass(elements[`checked_${altr}`], 'ag-hidden');
      addClass(elements[`indeterminate_${altr}`], 'ag-hidden');
      removeClass(elements[`unchecked_${altr}`], 'ag-hidden');
      addClass(elements[`unchecked_${altr}`], 'disabled');
        elements[`checkbox_${altr}`].disabled = true;
    }
  }

  elements[`checkbox_${type}`].addEventListener('click', (event: any) => {
    params.api.forEachNodeAfterFilterAndSort((node) => {
      if (paginated) {
        if (
          node.rowIndex >= params.api.paginationProxy.topRowIndex &&
          node.rowIndex <= params.api.paginationProxy.bottomRowIndex
        ) {
            if (node.data[compare_field] !== field_not_equal || allow_close) {
            node.setDataValue(altr, false);
            node.setDataValue(type, event.target.checked);
          }
        }
      } else {
          if (node.data[compare_field] !== field_not_equal || allow_close) {
          node.setDataValue(altr, false);
          node.setDataValue(type, event.target.checked);
        }
      }
    });
    if (elements[`checkbox_${altr}`]) {
      elements[`checkbox_${altr}`].disabled = event.target.checked;
    }
    if (event.target.checked) {
      if (elements[`checked_${type}`]) {
        removeClass(elements[`checked_${type}`], 'ag-hidden');
        addClass(elements[`indeterminate_${type}`], 'ag-hidden');
        addClass(elements[`unchecked_${type}`], 'ag-hidden');
        removeClass(elements[`unchecked_${type}`], 'disabled');
      }
      if (elements[`checked_${altr}`]) {
        addClass(elements[`checked_${altr}`], 'ag-hidden');
        addClass(elements[`indeterminate_${altr}`], 'ag-hidden');
        removeClass(elements[`unchecked_${altr}`], 'ag-hidden');
        addClass(elements[`unchecked_${altr}`], 'disabled');
      }
    } else {
      if (elements[`checked_${type}`]) {
        addClass(elements[`checked_${type}`], 'ag-hidden');
        addClass(elements[`indeterminate_${type}`], 'ag-hidden');
        removeClass(elements[`unchecked_${type}`], 'ag-hidden');
        removeClass(elements[`unchecked_${type}`], 'disabled');
      }
      if (elements[`checked_${altr}`]) {
        addClass(elements[`checked_${altr}`], 'ag-hidden');
        addClass(elements[`indeterminate_${altr}`], 'ag-hidden');
        removeClass(elements[`unchecked_${altr}`], 'ag-hidden');
        removeClass(elements[`unchecked_${altr}`], 'disabled');
      }
    }
  });
};

export const cellCheckboxReqStmtRender = (params: any, type: string) => {
  let id:string = `checkb_${type}_${params.data.requestProcessId}`;
  let spn:string =`span_${type}_${params.data.requestProcessId}`;
  return `
      <span ref="eCellWrapper" class="ag-cell-wrapper grid-checkbox" id='${spn}'>
        <input type='checkbox' ${params.value ? 'checked' : ''} id="${id}" />
        <span class="ag-selection-checkbox">
          <span id="rowSpanChecked_${params.data.requestProcessId}" class="ag-icon ag-icon-checkbox-checked ${params.value ? '' : 'ag-hidden'}"></span>
          <span id="rowSpanUnchecked_${params.data.requestProcessId}" class="ag-icon ag-icon-checkbox-unchecked ${!params.value ? '' : 'ag-hidden'}"></span>
        </span>
      </span>`;
};

export const evaluateSelectAllReqStmt = (params: any, component:any) => {
  let renderedNodeschecked:any = params.api.getRenderedNodes().map((node: any) => {
    let checkboxReqStmtRow: any = document.querySelector(`#checkb_checkboxReqStmt_${node.data.requestProcessId}`);
    if (checkboxReqStmtRow) {
      return checkboxReqStmtRow.checked
    }
  })
  let allChecked: string = 'unchecked';
  if (_.includes(renderedNodeschecked, true) || _.includes(renderedNodeschecked, false)) {
    if (!_.includes(renderedNodeschecked, false)) {
      allChecked = 'checked';
    } else if (_.includes(renderedNodeschecked, true) && _.includes(renderedNodeschecked, false)) {
      if(component.state.selectedRows.length === requestPatientStatements.configurationGrid.selectedRowsLimit){
        allChecked = 'checked';
      }else{
        allChecked = 'indeterminate';
      }
    }
  }

  let elements: any = checkboxElementById('selectRequestProcessId');
  let checked:boolean = false;
  if (elements['checked_selectRequestProcessId']) {
    switch (allChecked) {
      case 'checked':
        removeClass(elements['checked_selectRequestProcessId'], 'ag-hidden');
        addClass(elements['unchecked_selectRequestProcessId'], 'ag-hidden');
        addClass(elements['indeterminate_selectRequestProcessId'], 'ag-hidden');
        elements['checkbox_selectRequestProcessId'].setAttribute("reqStmtChecked", true);
        elements['checkbox_selectRequestProcessId'].setAttribute("reqStmtIndeterminate", false);
        checked = true;
        break;
      case 'unchecked':
        removeClass(elements['unchecked_selectRequestProcessId'], 'ag-hidden');
        addClass(elements['checked_selectRequestProcessId'], 'ag-hidden');
        addClass(elements['indeterminate_selectRequestProcessId'], 'ag-hidden');
        elements['checkbox_selectRequestProcessId'].setAttribute("reqStmtChecked", false);
        elements['checkbox_selectRequestProcessId'].setAttribute("reqStmtIndeterminate", false);
        break;
      case 'indeterminate':
        removeClass(elements['indeterminate_selectRequestProcessId'], 'ag-hidden');
        addClass(elements['checked_selectRequestProcessId'], 'ag-hidden');
        addClass(elements['unchecked_selectRequestProcessId'], 'ag-hidden');
        elements['checkbox_selectRequestProcessId'].setAttribute("reqStmtChecked", false);
        elements['checkbox_selectRequestProcessId'].setAttribute("reqStmtIndeterminate", true);
        break;
      default:
        break;
    }

    removeClass(elements['unchecked_selectRequestProcessId'], 'disabled');
    removeClass(elements['checkbox_selectRequestProcessId'], 'disabledCursor');
    removeClass(elements['indeterminate_selectRequestProcessId'], 'disabled');
    removeClass(elements['indeterminate_selectRequestProcessId'], 'disabledCursor');

    if(component.state.selectedRows.length === requestPatientStatements.configurationGrid.selectedRowsLimit){
      addClass(elements['unchecked_selectRequestProcessId'], 'disabled');
      addClass(elements['indeterminate_selectRequestProcessId'], 'disabled');
      addClass(elements['indeterminate_selectRequestProcessId'], 'disabledCursor');
      if(!checked){
        addClass(elements['checkbox_selectRequestProcessId'], 'disabledCursor');
      }
    }
  }
}

export const handleReqStmtCheckbox = (params: any, checked: any, node: any, component: any) => {
  let renderedNodes:Array<number> = params.api.getRenderedNodes().map((node: any) => node.data.requestProcessId)

  if (checked && node.data.status == 'Complete' && component.state.selectedRows.length < requestPatientStatements.configurationGrid.selectedRowsLimit && renderedNodes.indexOf(node.data.requestProcessId) >= 0) {
    component.setState({
      selectedRows: [...component.state.selectedRows, node.data]
    });
  }
  if (!checked && renderedNodes.indexOf(node.data.requestProcessId) >= 0) {
    component.setState({
      selectedRows: _.remove(component.state.selectedRows, (row: any) => row.requestProcessId != node.data.requestProcessId)
    });
  }
  if (component.state.selectedRows.length == requestPatientStatements.configurationGrid.selectedRowsLimit) {
    params.api.forEachNode((rowNode: any) => {
      if (!rowNode.data.select) {
        let checkboxReqStmtRow:any = document.querySelector(`#checkb_checkboxReqStmt_${rowNode.data.requestProcessId}`);
        let spanReqStmtRow:any = document.querySelector(`#rowSpanUnchecked_${rowNode.data.requestProcessId}`);
        if (checkboxReqStmtRow) {
          checkboxReqStmtRow.setAttribute('disabled', 'true');
          addClass(checkboxReqStmtRow, 'disabledCursor');
          addClass(spanReqStmtRow, 'disabledCursor');
        }
      }
    })
  }
  if (!node.data.select && component.state.selectedRows.length == (requestPatientStatements.configurationGrid.selectedRowsLimit - 1)) {
    params.api.forEachNode((rowNode: any) => {
      let checkboxReqStmtRow:any = document.querySelector(`#checkb_checkboxReqStmt_${rowNode.data.requestProcessId}`);
      let spanReqStmtRow:any = document.querySelector(`#rowSpanUnchecked_${rowNode.data.requestProcessId}`);
      if (checkboxReqStmtRow) {
        checkboxReqStmtRow.removeAttribute('disabled');
        removeClass(checkboxReqStmtRow, 'disabledCursor');
        removeClass(spanReqStmtRow, 'disabledCursor');
      }
    })
  }
  evaluateSelectAllReqStmt(params,component);
}

export const handleHeaderSelectAllLimit = (params: any, id: string, colname: string, component: any) => {
  let elements: any = checkboxElementById(id);
  elements[`checkbox_${id}`].addEventListener('click', () => {
    let checkValue:boolean =elements[`checkbox_selectRequestProcessId`].getAttribute("reqStmtChecked") === 'true';
    let indeterminateValue:boolean = elements[`checkbox_selectRequestProcessId`].getAttribute("reqStmtIndeterminate") === 'true';
    let newValue:boolean = !checkValue;
    if (indeterminateValue) {
      newValue = true;
    }
    let selectedCount:number = component.state.selectedRows.length;
    let renderedNodes:Array<number> = params.api.getRenderedNodes().map((node: any) => node.data.requestProcessId);
    params.api.forEachNodeAfterFilterAndSort((node: any) => {
      if (newValue) {
        if (node.data.status == 'Complete' && !node.data.select && selectedCount < requestPatientStatements.configurationGrid.selectedRowsLimit && renderedNodes.indexOf(node.data.requestProcessId) >= 0) {
          selectedCount++
          node.setDataValue(colname, newValue);
          handleReqStmtCheckbox(params, newValue, node, component)
        }
      } else {
        if (node.data.select && renderedNodes.indexOf(node.data.requestProcessId) >= 0) {
          node.setDataValue(colname, newValue);
          handleReqStmtCheckbox(params, newValue, node, component)
        }
      }
    });
  });
};

export const handleCellCheckboxReqStmt = (params: any, id: string, colname: string, component: any) => {
  let eDiv:any = document.createElement('div');
  if (params.node.data.status == 'Complete') {
    eDiv.innerHTML = `${cellCheckboxReqStmtRender(params, id)}`;
    let eCheckbox:any = eDiv.querySelector('input[type=checkbox]');
    let span:any = eDiv.querySelector(`#rowSpanUnchecked_${params.node.data.requestProcessId}`);
    if (eCheckbox) {
      if (!params.node.data.select && component.state.selectedRows.length == requestPatientStatements.configurationGrid.selectedRowsLimit) {
        eCheckbox.setAttribute('disabled', 'true');
        if(span){
          addClass(span, 'disabledCursor');
        }
      }
      eCheckbox.addEventListener('change', (event: any) => {
        params.node.setDataValue(colname, event.target.checked);
        handleReqStmtCheckbox(params, event.target.checked, params.node, component)
      });
    }
  }
  return eDiv;
}

export const clear_checbox_selection_on_pagination = (
  params,
  type,
  altr,
  compare_field,
  field_not_equal,
  checkbox_stattus_all_notes
) => {
  let elements: any = checkbox_elements(type, altr);
  params.api.forEachNodeAfterFilterAndSort((node) => {
    if (node.data[compare_field] !== field_not_equal) {
      node.setDataValue(altr, false);
      node.setDataValue(type, false);
      node.setDataValue('closed_notes', '');
      if (
        node.rowIndex >= params.api.paginationProxy.topRowIndex &&
        node.rowIndex <= params.api.paginationProxy.bottomRowIndex
      ) {
        if (checkbox_stattus_all_notes) {
          let txt_claim_notes: any = document.querySelector(`[col-id=closed_notes] #notes_field_${node.rowIndex}`);
          if (txt_claim_notes) {
            addClass(txt_claim_notes, 'disabled');
            txt_claim_notes.querySelector('input').disabled = checkbox_stattus_all_notes;
          }
        } else {
          let txt_claim_notes: any = document.querySelector(`[col-id=closed_notes] #notes_field_${node.rowIndex}`);
          if (txt_claim_notes) {
            removeClass(txt_claim_notes, 'disabled');
            txt_claim_notes.querySelector('input').disabled = checkbox_stattus_all_notes;
          }
        }
      }
    }
  });
  if (elements[`checkbox_${type}`]) {
    elements[`checkbox_${type}`].checked = false;
    elements[`checkbox_${type}`].disabled = false;
    addClass(elements[`checked_${type}`], 'ag-hidden');
    addClass(elements[`indeterminate_${type}`], 'ag-hidden');
    removeClass(elements[`unchecked_${type}`], 'ag-hidden');
    removeClass(elements[`unchecked_${type}`], 'disabled');
  }
  if (elements[`checkbox_${altr}`]) {
    elements[`checkbox_${altr}`].checked = false;
    elements[`checkbox_${altr}`].disabled = false;
    addClass(elements[`checked_${altr}`], 'ag-hidden');
    addClass(elements[`indeterminate_${altr}`], 'ag-hidden');
    removeClass(elements[`unchecked_${altr}`], 'ag-hidden');
    removeClass(elements[`unchecked_${altr}`], 'disabled');
  }
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const e277_report_notes_input = (params) => {
  return `
  <div class="ui form field" id="notes_field_${params.data.row_index}">
     <input  id="notes_input_${params.data.row_index}" class="txt_claim_notes" maxLength="250" autocomplete="new-password" autoComplete='off' />
  </div>`;
};

// FUnction use to format the grid data on load.
export const row_data_formatter = (row_data: any[], column_def: any[]) => {
  row_data.map((row) => {
    column_def.map((value) => {
      switch (value.type) {
        case 'date':
          row[value.field] =
            row[value.field] != null || row[value.field] != undefined ? dateFormatter(row[value.field]) : '';
          break;
        case 'currency':
          row[value.field] =
            row[value.field] != null || row[value.field] != undefined
              ? currencyFormatter(row[value.field])
              : currencyFormatter(0);
          break;
        case 'number':
          row[value.field] = row[value.field] != null || row[value.field] != undefined ? parseInt(row[value.field]) : 0;
          break;
        case 'hyper_link':
              {
                  if (value.field == 'set_id') {
                      row[value.field] = row[value.field] ? row[value.field] : '';
                  }
                  row[`link_${value.field}`] = strip(row[value.field]);
              }
          break;
        case 'boolean':
          row[value.field] = JSON.parse(row[value.field]) ? 'True' : 'False';
        default:
          row[value.field] = row[value.field] != null || row[value.field] != undefined ? row[value.field] : '';
      }
    });
    return row;
  });
  return row_data;
};

export const date_remove_timezone = (date: any) => {
  if (!date) {
    return date;
  }
  
  return date.format("YYYY-MM-DDTHH:mm:ss");
};

export const check_internal_staff = () => {
  let current_user_type =  enum_user_type_r3_id.Sys_Admin;
  const auth_data = local_storage.get('auth_data');

  if (auth_data && auth_data.user_type_r3_id) {
    current_user_type = auth_data.user_type_r3_id;
  }

  return current_user_type == enum_user_type_r3_id.BMS ||
    current_user_type == enum_user_type_r3_id.Sys_Admin;
};