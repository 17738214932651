import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Dimmer, Grid, Loader } from 'semantic-ui-react';
import { case_clone } from '../../case/action/case_action';
import { update_patient_header_info } from '../../patient/action/patient_header_action';
import ConfirmationComponent from '../../shared/component/confirmation_component';
import GridView from '../../shared/component/grid/grid_child_ui_component';
import * as session_storage from '../../shared/session_storage_utility';
import { get_patient_cases } from '../action/patient_action';
import * as patient_constants from '../patient_constants';
import { case_messages } from './../../case/case_constants';
import { patient_messages } from './../../patient/patient_constants';
import { handle_click_on_enter } from '../../shared/tab_navigation_utility';
import * as global_constants from '../../global_constants';
export class CasesComponent extends React.Component<any, any> {
    [x: string]: any;
    _is_mounted = false;
    constructor(props) {
        super(props);

        this.state = {
            shown: true,
            selected_item: [],
            grid_ref: {},
            loading: false,
            clone_case_confirmation_box: false
        }

        this.grid_reference = React.createRef();
    }

    componentDidMount() {
        this._is_mounted = true;  
        const { user_login_details, match } = this.props;
        if (match.params.patient_id && (!(isNaN(Number(match.params.patient_id)) || Number(match.params.patient_id) >= global_constants.maximum_value.integer))) {
            this.props.get_patient_cases(match.params.patient_id, user_login_details.user_data.data.token_details.access_token, false);
        }
    }

    componentDidUpdate(previousProps, previousState) {
        const { user_login_details, match } = this.props;
        if (match.params.patient_id !== previousProps.match.params.patient_id) {
            this.props.get_patient_cases(match.params.patient_id, user_login_details.user_data.data.token_details.access_token, false);
            if (this._is_mounted) {
                this.setState({ shown: true });
            }
        }

        if (previousProps.shown != this.props.shown) {
            if (this._is_mounted) {
                this.setState({ shown: false }); // show on change props
            }
        }
    }

    // toggle to show and hide Cases Component
    private toggle = () => {
        if (this._is_mounted) {
            this.setState({
                shown: !this.state.shown
            });
        }
    }

    on_add_click(): any {

    }

    new_case = () => {
        this.props.history.push(`/case`);
    }

    // Click functin of radio selection
    on_check_change = items => {
        if (this._is_mounted) {
            this.setState({
                selected_item: items
            });
        }
    }

    //Function calls on intialization of Ag-Grid and catch its reference.
    get_grid_ref = (grid_params) => {
        if (this._is_mounted) {
            this.setState({ grid_ref: grid_params });
        }
    }
    on_double_click = (selectedRows) => {
        session_storage.set('active_case', selectedRows.id.toString());
        this.props.update_patient_header_info(false, 0, true);
        this.props.history.push(`/case/${selectedRows.id}`);
    }

    // Inactive case
    on_check_change_inactive = (event) => {
        const { user_login_details, match } = this.props;
        if (event.target.checked) {
            this.props.get_patient_cases(match.params.patient_id, user_login_details.user_data.data.token_details.access_token, true);
        } else {
            this.props.get_patient_cases(match.params.patient_id, user_login_details.user_data.data.token_details.access_token, false);
        }
    }
    on_close_confirmation = () => {
        if (this._is_mounted) {
            this.setState({ clone_case_confirmation_box: false });
        }
    }
    clone_case_click = () => {
        if (this.state.selected_item) {
            if (this.state.selected_item.length > 0) {
                if (this._is_mounted) {
                    this.setState({ clone_case_confirmation_box: true });
                }
            }
            else
                toastr.error('', patient_messages.case_clone_chk_not_selected);
        }
    }

    clone_case = () => {
        var item = this.state.selected_item[0];
        var case_id = item.id;
        var patient_id = item.patient_id;
        case_clone(this.props.user_login_details.user_data.data.token_details.access_token, patient_id, case_id, '').then(res => {
            if (res.data.data) {
                session_storage.set('active_case', res.data.data.toString());
                //to identify the case is cloned
                session_storage.set('is_clone_case', true);
                this.props.update_patient_header_info(true, 0, false);
                this.props.history.push(`/case/${res.data.data}`);
            }

        });
    }
    componentWillUnmount() {
        this._is_mounted = false;
    }

    handle_tab_navigation_for_next_grid = () => {
        this.grid_reference.ensureScrollIsLeft();
        this.grid_reference.handle_first_tab();
    }
   
    render() {
        var hidden = {
            display: this.state.shown ? "none" : "block"
        }
        return (
            <>
                {this.state.clone_case_confirmation_box ?
                    <ConfirmationComponent message={case_messages.clone_case_message} save_button_text="Yes" cancel_button_text="No"
                        title="Clone Case" show_modal={this.state.clone_case_confirmation_box} onCloseModal={this.on_close_confirmation} on_force_save_click={(e) => { this.clone_case() }} />
                    : ''}
                <div className="common-forms-view" id="cases" >
                    <span className="get-content-width"></span>
                    <div className="ui grid">
                        <div className="sixteen wide computer column">
                            <div className="accordion ui fluid styled common-accordion">
                                <div className="active title" onClick={this.toggle.bind(this)} >Cases<i tabIndex={0} onKeyDown={handle_click_on_enter} aria-hidden="true"
                                    className={!this.state.shown ? "ellipsis angle up icon" : "ellipsis angle down icon"}></i></div>
                                {!this.state.shown ?
                                    <div className="content active" style={hidden}>
                                        <Dimmer active={this.props.case_loading}>
                                            <Loader size='massive'>Loading</Loader>
                                        </Dimmer>
                                        <Grid.Column>
                                            <div className="div-show-inactive-case">
                                                <input type="checkbox" onKeyDown={this.handle_tab_navigation_for_next_grid} onChange={this.on_check_change_inactive} />
                                                <span style={{ paddingLeft: '5px' }}>Show Inactive Cases</span>
                                            </div>
                                            <GridView
                                                id={'id_grid_patient_cases'}
                                                row={this.props.patient_cases}
                                                column={patient_constants.patient_cases_configuration.column_defs}
                                                onRef={instance => { this.grid_reference = instance; }}
                                                radioSelection={true}
                                                gridAutoHeight={true}
                                                onRowSelection={this.on_check_change}
                                                onRowDoubleClicked={this.on_double_click}
                                                enableEnterOnNavigation={true}
                                                get_grid_ref={this.get_grid_ref}
                                                headerIdForTabNavigation={patient_constants.patient_cases_configuration_grid_header_id}
                                            />
                                        </Grid.Column>
                                        <div className="patient_credit_footer_div">
                                            <button type="button" id="bg_fff2" className="ui basic button" role="button" onClick={() => this.clone_case_click()}>Clone Case</button>
                                            <button type="submit" className="ui primary button" role="button" onClick={() => this.new_case()}>New Case</button>
                                        </div>
                                    </div> : ''}

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        get_patient_cases: get_patient_cases,
        update_patient_header_info: update_patient_header_info
    }, dispatch)
}

const mapStateToProps = ({ user_login_details, user_details, shared_details, patient_details }) => {
    return {
        user_login_details: user_login_details,
        user_details: user_details,
        shared_details: shared_details,
        patient_details: patient_details,
        patient_cases: patient_details.patient_cases,
        case_loading: patient_details.case_loading
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CasesComponent))