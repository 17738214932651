import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Dimmer, Grid, Header, Loader } from "semantic-ui-react";
import { get_patient_account_notes_action, get_patient_account_notes_status_action } from "../../patient/action/patient_action";
import { update_patient_header_info } from '../../patient/action/patient_header_action';
import ChargeAccountNotes from "../../payment/component/payments_notes";
import * as session_storage from '../../shared/session_storage_utility';
import { account_note_sticky_code } from '../../shared/shared_constants';
import { set_focus_to_app_header } from './../../shared/tab_navigation_utility';
import { get_charge_case_list } from './../action/charge_action';
import { prepare_dropdown_data, prepare_case_options } from './../utility';
import AddChargeComponent from './add_charge_component';
export class ChargeComponent extends React.Component<any, any> {
    [x: string]: any;
    _is_mounted = false;   
    show_charge_review_return_btn = false;
    show_charge_audit_return_btn = false;
    show_charge_return_button_text = "";
    constructor(props) {
        super(props);
        this.state = {
            is_save_button_disabled: false,
            account_notes: [],
            save_confirmation_box: false,
            selected_case_id: 0,
            case_list: null,
            selected_charge_id: 0,
            charge_review_state: {}
        }
        this.add_charge = React.createRef();
    }
    sticky_code = account_note_sticky_code.charge;
    patient_id = session_storage.get('active_patient');

    //Get Data from api's
    componentDidMount = () => {
        this._is_mounted = true;
        document.body.classList.add('charge-view');
        if (this.props.location && this.props.location.state && this.props.location.state.from_charge_review) {
            this.show_charge_review_return_btn = this.props.location.state.from_charge_review;
            this.show_charge_return_button_text = "Return to Charge Review";
            if (this._is_mounted) {
                this.setState({
                    charge_review_state: this.props.location.state
                })
            }
        }
        else if (this.props.location && this.props.location.state && this.props.location.state.from_charge_audit) {
            this.show_charge_audit_return_btn = this.props.location.state.from_charge_audit;
            this.show_charge_return_button_text = "Return to Charge Audit";
            if (this._is_mounted) {
                this.setState({
                    charge_review_state: this.props.location.state
                })
            }
        }

        this.patient_id = session_storage.get('active_patient');
        if ((this.patient_id == '' || this.patient_id == undefined)) {
            session_storage.set('no_patient_selected_for', this.props.location.pathname);
            this.props.history.push(`/no_patient_selected`);
        } else {
           this.get_data();
           this.get_case_list();
        }
    };

    get_data = () => {
        this.get_patient_account_notes(this.patient_id, this.sticky_code);
    }
    get_case_list = async () => {
        await get_charge_case_list(this.props.user_login_details.user_data.data.token_details.access_token, this.patient_id).then(response => {
            if (response.data.data) {
                if (response.data.data.length > 0) {
                    if (this._is_mounted) {
                        this.setState({
                            case_list: prepare_case_options(response.data.data),
                            selected_case_id: response.data.data.length > 1 ? 0 : response.data.data[0].id
                        });
                    }

                } else {
                    if (this._is_mounted) {
                        this.setState({
                            case_list: [],
                            selected_case_id: 0
                        });
                    }
                }
            }
            else {
                if (this._is_mounted) {
                    this.setState({
                        case_list: prepare_dropdown_data([], true),
                        selected_case_id: 0
                    });
                }

            }
        });
    }
    componentWillUnmount = () => {
        this._is_mounted = false;
        document.body.classList.remove('charge-view');
    }
    //Get charge account Notes Data from API function
    componentDidUpdate(prevProps, prevState) {
        if (this.props.patient_account_notes_status && this.props.patient_account_notes_status.updated && this.props.patient_account_notes_status.addedNoteType === "Charge") {
            this.get_patient_account_notes(this.patient_id, this.sticky_code);
            this.props.get_patient_account_notes_status({
                updated: false,
                addedNoteType: "Charge"
            });
        }
        //change page data if patent changes from header, instead of navigate to other page
        if (prevProps.patient_id != this.props.patient_id) {            
            this.patient_id = session_storage.get("active_patient");
            if ((this.show_charge_audit_return_btn || this.show_charge_review_return_btn)) {
                if (this.props.patient_id != this.patient_id) {                  
                    this.get_data();
                    this.get_case_list();
                }
            }
            else {
                this.get_data();
                this.get_case_list();
            }
        }

    }

    //Get account Notes Data for charge stick
    get_patient_account_notes = async (patient_id, sticky_code) => {
        await get_patient_account_notes_action(patient_id, this.props.user_login_details.user_data.data.token_details.access_token, sticky_code).then(
            response => {
                if (this._is_mounted) {
                    this.setState({
                        account_notes: response.data,
                        loading: false
                    });
                }
            }
        );
    };
    clear_charges = () => {
        if (this.add_charge) {
            this.add_charge.reset_after_save(this.state.selected_case_id);
        }
    }

    save_apply_payment_click = () => {
        if (this.add_charge) {
            this.add_charge.on_save_payment_charge();
        }
    }

    on_save_click = (is_force_save) => {
        if (this.add_charge) {           
            this.add_charge.on_save_charge_click();
        }
    }
    set_selected_case = (case_id, charge_id) => {
        if (this._is_mounted) {
            this.setState({
                selected_case_id: case_id,
                selected_charge_id: charge_id

            })
        }
    }

    goto_charge_review_after_save = () => {
        if (this.show_charge_review_return_btn) {
            this.goto_charge_review();
        }
        if (this.show_charge_audit_return_btn) {
            this.goto_charge_audit();
        }
    }
    //go back to charge review page with old search creteria
    goto_charge_review = () => {
        this.props.history.push({
            pathname: '/charge/charge_review_detail/' + this.state.charge_review_state.search_creteria.location_id,
            search: '',
            state: this.state.charge_review_state
        })
    }
    goto_charge_audit = () => {
        this.props.history.push({
            pathname: '/charge/charge_audit_detail',
            search: '',
            state: { charge_status: this.state.charge_review_state.search_creteria.charge_status, company_id: this.state.charge_review_state.search_creteria.company_id, restrict_pos: this.state.charge_review_state.search_creteria.restrict_pos_flag, user_type_id: this.state.charge_review_state.search_creteria.user_type_id }
            //state: {char}this.state.charge_review_state.search_creteria
        })
    }
    set_save_button_disabled = (is_disable) => {
        if (this._is_mounted) {
            this.setState({
                is_save_button_disabled: is_disable,
            });
        }
    }
    render() {
        const { loading, selected_case_id, case_list, selected_charge_id } = this.state
        return (
            <div className="common-forms unassigned-payment">
                {
                    this.state.account_notes.length > 0 && (
                        <ChargeAccountNotes show_stick_on={true}
                            accountNotes={this.state.account_notes}
                        />
                    )}
                {

                    case_list && case_list.length > 0 ?
                        <React.Fragment>
                            <div className="auto-height-patient-full-details-scroll" id="scrollable-content-area">
                                <Dimmer active={loading}>
                                    <Loader size="massive">Loading</Loader>
                                </Dimmer>

                                <Grid>
                                    <Grid.Column computer={16}>
                                        <Header as="h3" dividing textAlign="left">
                                            Charges
                        </Header>
                                    </Grid.Column>
                                </Grid>
                                <AddChargeComponent onRef={instance => { this.add_charge = instance; }} set_save_btn_disable={this.set_save_button_disabled} selected_case={this.state.selected_case_id} case_list={this.state.case_list}
                                    set_parent_selected_case={this.set_selected_case} save_response={this.goto_charge_review_after_save}
                                    charge_review_or_audit={this.show_charge_review_return_btn || this.show_charge_audit_return_btn} />


                            </div>
                            {
                                selected_case_id > 0 && selected_charge_id <= 0 ?
                                    <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area" id="applicationFooterSticky">
                                        <Button id="charge_cancel_btn" type='button' onClick={this.clear_charges} basic >Clear Charges</Button>
                                        <Button id="charge_payment_btn" type='button' disabled={this.state.is_save_button_disabled} onClick={this.save_apply_payment_click} basic >Save & Apply Payment in Full</Button>
                                        <Button id="charge_save_btn" type='button' disabled={this.state.is_save_button_disabled} onClick={() => { this.on_save_click(false) }} onKeyDown={set_focus_to_app_header} primary >Save</Button>
                                    </div>
                                    : selected_case_id > 0 && selected_charge_id > 0 ?
                                        <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area" id="applicationFooterSticky">
                                            {(this.show_charge_review_return_btn || this.show_charge_audit_return_btn) &&
                                                <Button id="btn_redirect_to_charge_review" type="button" basic onClick={() => this.goto_charge_review_after_save()}>
                                                {this.show_charge_return_button_text}
                                                </Button>
                                            }
                                            <Button id="charge_save_btn" type='button' onClick={() => { this.on_save_click(false) }} onKeyDown={set_focus_to_app_header} primary >Save</Button>

                                        </div>
                                        : ''
                            }
                        </React.Fragment>
                        : case_list && case_list.length == 0 ?
                            <React.Fragment >
                                <Grid>
                                    <Grid.Column computer={16}>
                                        <Header as="h3" dividing textAlign="left">
                                            Charges
                        </Header>
                                    </Grid.Column>
                                </Grid>
                                <Grid>
                                    <Grid.Column mobile={16} tablet={16} computer={16} className="no-patient-found">
                                        <i aria-hidden="true" className="warning sign icon"></i>
                                        <span> No Cases Available </span>
                                    </Grid.Column>
                                </Grid>

                            </React.Fragment>
                            : ''

                }

            </div>


        )
    }
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        patient_account_notes_status: state.patient_details.patient_account_notes_status,
        patient_details: state.patient_details,
        patient_id: state.patient_details && state.patient_details.patient_header && state.patient_details.patient_header.data && state.patient_details.patient_header.data.id || ''
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        update_patient_header_info: update_patient_header_info,
        get_patient_account_notes_status: (data) => dispatch(get_patient_account_notes_status_action(data)),

    }, dispatch)
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChargeComponent));