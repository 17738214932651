import * as global_constants from '../../../global_constants';
import { RequestConfig } from '../../../shared/network/config';
import { Request } from '../../../shared/network/request';

export const get_batch_tasks = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.batch_tasks_urls.batch_tasks}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

export const update_batch_tasks_status = (token, data) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.batch_tasks_urls.batch_tasks}`;

    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'put',       
        'data': data
    })
    return request.make(request_config);
}
export const get_batch_task_details = (token, task_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.batch_tasks_urls.batch_tasks}/${task_id}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

export const get_batch_task_runs = (token, task_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.batch_tasks_urls.batch_tasks}/${task_id}/runs`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

export const get_batch_task_run_details = (token, run_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.batch_tasks_urls.batch_tasks}/runs/${run_id}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

export const get_batch_task_run_logs = (token, run_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.batch_tasks_urls.batch_tasks}/runs/${run_id}/logs`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};
export const get_batch_tasks_programs = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.batch_tasks_urls.batch_tasks_programs}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

export const get_batch_tasks_program_details = (token, program_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.batch_tasks_urls.batch_tasks_programs}/${program_id}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

export const get_batch_tasks_program_logs = (token, program_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.batch_tasks_urls.batch_tasks_programs}/${program_id}/logs`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

export const get_batch_tasks_program_runs = (token, program_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.batch_tasks_urls.batch_tasks_programs}/${program_id}/runs`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

export const get_batch_task_log_details = (token, log_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.batch_tasks_urls.logs}/${log_id}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

