import * as global_constants from '../../global_constants';
import { ACTION_CONSTANT, payment_const } from '../../payment/payment_constants';
import { log_error } from '../../shared/action/shared_action';
import * as payment_constants from '../payment_constants';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request';
import { generate_guid } from '../../shared/utility';

const requestProceedPaymentsDetails = (
    requestConfig: any,
    dispatch: any,
    param: any,
    action_type: any,
    patientId: any,
    token: any
) => {
    let request = new Request();
    request.make(requestConfig).then(
        (response) => {
            dispatch({
                type: payment_const.save_success,
                payload: { response: response.data, data: param, action_type },
            });
            //Get credit if payment add success
            if (response.data && response.data.status === 1) {
                dispatch(get_patient_credits(patientId, token));
            }
        },
        (error) => {
            dispatch({
                type: payment_const.save_failure,
                payload: error,
            });
            log_error(error);
        }
    );
};

// save payment details
export const savePaymentDetails = (param, actionType, patientId, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.unassigned_payment.save_payment}`;
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': param
    })

    return (dispatch) => requestProceedPaymentsDetails(requestConfig, dispatch, param, actionType, patientId, token);
}

export const addPaymentDetails = (param, actionType, patientId, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.unassigned_payment.addPayment}`;
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': param
    })

    return (dispatch) => requestProceedPaymentsDetails(requestConfig, dispatch, param, actionType, patientId, token);
}

export const updatePaymentDetails = (param, actionType, patientId, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.unassigned_payment.updatePayment}`;
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': param
    })

    return (dispatch) => requestProceedPaymentsDetails(requestConfig, dispatch, param, actionType, patientId, token);
}

export const deletePaymentDetails = (param, actionType, patientId, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.unassigned_payment.deletePayment}`;
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': param
    })

    return (dispatch) => requestProceedPaymentsDetails(requestConfig, dispatch, param, actionType, patientId, token);
}

export const saveChargePayment = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.unassigned_payment.save_payment}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': param
    })

    return request.make(requestConfig);
}

export const addChargePayment = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.unassigned_payment.addPayment}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': param
    })

    return request.make(requestConfig);
}

// get insurance
export const get_insurance = (id, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.unassigned_payment.get_insurance}/${id}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config);
}

// search palce of service
export const search_place = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.unassigned_payment.search_place}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(param)
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config);

}

export const search_provider = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.unassigned_payment.search_provider}/${param.id}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify({ "Description": param.provider_name, "Code": param.RP_provider_code, "Page_size": global_constants.constants.PAGE_SIZE, "Apply_pos_code": false }),
        //'id' : param.id
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config);
}

export const search_billing_and_treating_provider = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.unassigned_payment.search_billing_and_treating_provider}/${param.id}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify({ "Description": param.provider_name, "Code": param.RP_provider_code, "Page_size": global_constants.constants.PAGE_SIZE, "Apply_pos_code": false }),
        //'id' : param.id
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config);
}

// quick search locations by keywords
export const quick_search_location = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.unassigned_payment.quick_search_location}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            keyword: param,
            pageSize: global_constants.constants.Quick_Search_Suggestion_List_Size

        }
    })

    return request.make(request_config);
}
// quick search locations by keywords
export const quick_search_provider = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.unassigned_payment.quick_search_provider}/${param.locationId}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            keyword: param.key,
            pageSize: global_constants.constants.Quick_Search_Suggestion_List_Size

        }
    })

    return request.make(request_config);
}

// Get patient credit listing
export const get_patient_credits = (patient_id, token) => {
    const url = `${global_constants.base_end_points}/payments/${patient_id}/credits`;

    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    
    return dispatch =>
        request.make(request_config).then(
            response => dispatch({
                type: ACTION_CONSTANT.GET_PATIENT_CREDITS,
                payload: response.data
            }),
            error => {
                log_error(error)
            }
        );
}

// Get patient credit listing
export const get_reset_eobs_list = (param: string, token: string) => {
    const url = `${global_constants.base_end_points}/payments/search_eob?${param}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'silversurfer': param
    }
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config)
        .then(response => {
            if (response) {
                return response;
            }
        });
}

// GET Account notes
export const get_patient_account_receipt = async (patient_id, token) => {
    // account notes
    const url = `${global_constants.base_end_points}/payments/${patient_id}/receipts`;

    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            patient_id
        }
    })

    return (
        request.make(request_config).then(
            response => {
                return response.data
            },
            error => {
                log_error(error)
            }
        )
    );
};


export const save_credits = (data, type) => {
    return { type : type, payload: data };
}

export const get_reset_835s = (param: string, token: string) => {
  const url = `${global_constants.base_end_points}/payments/reset_eob?${param}`;
  let request = new Request();
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
    silversurfer: param
  };

  let request_config = new RequestConfig({
    url: url,
    headers: headers,
    method: "get"
  });
  return request.make(request_config);
};

export const search_eob_835s = (param: string, token: string) => {
  const url = `${global_constants.base_end_points}/payments/search_eob_release?${param}`;
  let request = new Request();
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  let request_config = new RequestConfig({
    url: url,
    headers: headers,
    method: "get"
  });
  return request.make(request_config);
};

export const search_eob_editable_835s = (param: string, token: string) => {
	const url = `${global_constants.base_end_points}/payments/search_eob_editable_835s?${param}`;
	let request = new Request();
	let headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
		Authorization: "Bearer " + token
	};

	let request_config = new RequestConfig({
		url: url,
		headers: headers,
		method: "get"
	});
	return request.make(request_config);
};

export const get_eob_835s = (token: string) => {
  const url = `${global_constants.base_end_points}/payments/eob_release`;
  let request = new Request();
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  let request_config = new RequestConfig({
    url: url,
    headers: headers,
    method: "get"
  });
  return request.make(request_config);
};

export const update_eob_835s = (param, token) => {
  const url = `${global_constants.base_end_points}/payments/update_eob`;
  let request = new Request();
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  let request_config = new RequestConfig({
    url: url,
    headers: headers,
    method: "put",
    data: param
  });
  return request.make(request_config);
};

export const release_eob_835s = (param, params, token) => {
  const url = `${global_constants.base_end_points}/payments/release_eob`;
  let request = new Request();
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  let request_config = new RequestConfig({
    url: url,
    headers: headers,
    method: "put",
    data: param,
    params: {
      company_id: params.company_id,
      deposit_date: params.deposit_date,
      batch_number: params.batch_number
    }
  });
  return request.make(request_config);
};

// search payer by advacned control
export const advanced_search_eob_payor = (params, token) => {
  const url = `${global_constants.base_end_points}/payments/search_eob_payor`;
  let request = new Request();
  let headers = {
    Authorization: "Bearer " + token,
    silversurfer: JSON.stringify(params)
  };

  let request_config = new RequestConfig({
    url: url,
    headers: headers,
    method: "get",
    params: {
      ...params
    }
  });
  return request.make(request_config);
};

// quick search payer by keywords
export const quick_search_eob_payor = (company_id, params, token) => {
  const url = `${global_constants.base_end_points}/payments/quick_search_eob_payor`;
  let request = new Request();
  let headers = {
    Authorization: "Bearer " + token
  };

  let request_config = new RequestConfig({
    url: url,
    headers: headers,
    method: "get",
    params: {
      company_id: company_id,
      keyword: params.keyword,
      page_size: global_constants.constants.Quick_Search_Suggestion_List_Size,
      enableRestriction: false
    }
  });
  return request.make(request_config);
};


export const get_charge_details = (charge_id, token) => {
    let temp_url = '/charge/errorcorrect';
    const url = `${global_constants.base_end_points}${temp_url}/${charge_id}`;
    // const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.batch_billing.run_set_details}/${charge_id}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });
    return request.make(request_config);
};

export const get_preauthorization_list_by_charge = (charge_id, token) => {
    let temp_url1 = '/subscriber';
    let temp_url2 = '/pre_authorization_list_by_charge';
    const url = `${global_constants.base_end_points}${temp_url1}/${charge_id}${temp_url2}`;
    // const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.batch_billing.run_set_details}/${charge_id}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });
    return request.make(request_config);
}
export const get_error_correction_payment_details = (charge_id, token) => {
    let end_point = '/charge/errorcorrect/payment';
    const url = `${global_constants.base_end_points}${end_point}/${charge_id}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });
    return request.make(request_config);
}

export const get_error_correction_data = (patient_id, dos_from, dos_to, show_inactive, token) => {
    const url = `${global_constants.base_end_points}/payments/error_correction_report?patient_id=${patient_id}&dos_from=${dos_from}&dos_to=${dos_to}&show_inactive=${show_inactive}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

/**
 * Fetch editable data for error correction
 * @param token
 * @param params
 */
export const get_editable_error_correction = (token, params) => {
    let end_point = '/charge/errorcorrection_data';
    params.page_size = payment_constants.error_correction_search_page_size;
    const url = `${global_constants.base_end_points}${end_point}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'silversurfer': JSON.stringify(params)
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: "get"
    });
    return request.make(request_config);
};

/**
 * Save error correction charges and payments in the editable grid
 * Update all charge and payment edited rows
 * @param param
 * @param token
 */
export const save_edittable_error_correction = (param, token) => {
    let end_point = '/charge/save_errorcorrection_data';
    const url = `${global_constants.base_end_points}${end_point}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': param
    })

    return request.make(request_config);
}


/**
 * Save charge edits
 * @param param
 * @param token
 */
export const save_charge_for_error_correction = (param, token) => {
    let end_point = '/charge/save_errorcorrection';
    const url = `${global_constants.base_end_points}${end_point}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': param
    })

   return request.make(request_config);
}

export const insurance_by_charge = (charge_id, token) => {
    let end_point = '/insurance/from_ins_by_charge';
    const url = `${global_constants.base_end_points}${end_point}/${charge_id}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });
    return request.make(request_config);
}

export const get_adjustment_reason = (token) => {
    let end_point = '/adjustment_code/get_adjustment_reason';
    const url = `${global_constants.base_end_points}${end_point}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });
    return request.make(request_config);
}

export const get_adjustment_qualifier = (token) => {
    let end_point = '/adjustment_code/get_adjustment_qualifier';
    const url = `${global_constants.base_end_points}${end_point}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });
    return request.make(request_config);
}

export const get_adjustment_codes = (token) => {
    let end_point = '/adjustment_code/get_adjustment_code';
    const url = `${global_constants.base_end_points}${end_point}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });
    return request.make(request_config);
}

export const save_errorcorrection_payment = (param, token) => {
    let end_point = '/charge/save_errorcorrection_payment';
    const url = `${global_constants.base_end_points}${end_point}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': param
    })

    return request.make(request_config);
}


export const get_edit_payments_list = (token, params) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.edit_payments.search}`;
    let request = new Request();
    let headers = {
      Authorization: "Bearer " + token
    };
  
    let request_config = new RequestConfig({
      url: url,
      headers: headers,
      method: "get",
      params
    });
    return request.make(request_config);
};

export const delete_payment_adjustment = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.edit_payments.delete}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': param
    })

   return request.make(request_config);
}

export const payment_edit_update = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.edit_payments.update}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': param
    })

   return request.make(request_config);
};

export const get_bulk_payment_updates_master_data_by_company = (token, params) => {
  const url = `${global_constants.base_end_points}${global_constants.end_points.payment.bulk_payment_updates.master_data_by_company}`;
  let request = new Request();
  let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
  };

  let request_config = new RequestConfig({
      url: url,
      headers: headers,
      method: 'get',
      params
  });
  return request.make(request_config);
};

export const search_bulk_payment_updates = (token, params) => {
  const url = `${global_constants.base_end_points}${global_constants.end_points.payment.bulk_payment_updates.search}`;
  let request = new Request();
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
    silversurfer: JSON.stringify(params)
  };

  let request_config = new RequestConfig({
    url: url,
    headers: headers,
    method: 'get'
  });
  return request.make(request_config);
};

export const update_bulk = (token, params) => {
  const url = `${global_constants.base_end_points}${global_constants.end_points.payment.bulk_payment_updates.update}`;
  let request = new Request();
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token
  };

  let request_config = new RequestConfig({
    url: url,
    headers: headers,
    method: 'put',
    data: params
  });
  
  return request.make(request_config);
};

export const release835DetailCard = (param: any, token: string) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.relase_835_details.release835DetailCard}?${param}`;
    let request = new Request();
    let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
      Authorization: "Bearer " + token
    };
    let request_config = new RequestConfig({
      url: url,
      headers: headers,
      method: "get",
    });
    return request.make(request_config);
};

export const get_release_835_eob_patient_claim_details = (token: string, eob_key: number, patient_id: number, company_id: number, case_id: number) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.relase_835_details.get_release_835_eob_patient_claim_details}`;

    let request = new Request();
    let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
    };
    let params = {
        eob_key: eob_key,
        patient_id: patient_id,
        company_id: company_id,
        case_id: case_id
    }
    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: "get",
        params,
        rnum: generate_guid()
    });
    return request.make(request_config);
};

export const updateEOBValidationErrors = (eob_key: any, token: string) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.relase_835_details.validationEob}/${eob_key}`;
    let request = new Request();
    let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
      Authorization: "Bearer " + token
    };
    let request_config = new RequestConfig({
      url: url,
      headers: headers,
      method: "get"
    });
    return request.make(request_config);
};

export const saveEditable835 = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.release_835_save_editable_fields.save_eob_835_editable_fields}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': param
    })

   return request.make(request_config);
};

export const get_eob835_unassigned_insurance_by_inbound_id = (eob_key:number, company_id:number, token:string) =>
{
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.relase_835_details.get_unassigned_insurance_by_inbound_id}`;

    let request = new Request();
    let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
    };
    let params = {
        eob_key: eob_key,
        company_id: company_id
    }
    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: "get",
        params
    });
    return request.make(request_config);
}

export const e835_add_inboud_id_to_payers_config = (inbound_id: string, payer_ids:Array<number>, company_id:number, token:string) => 
{
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.relase_835_details.e835_add_inbound_id_to_payers_config}`;

    var param = { 'inbound_id': inbound_id, 'payer_ids': payer_ids, 'company_id': company_id };

    let request = new Request();

    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': param
    })

   return request.make(request_config);
}

/**
 * Upload 835 file
 * @param param
 * @param token
 */
export const upload_835_file = (token, param) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.upload_835_file.upload}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': param
    })

    return request.make(request_config);
}
