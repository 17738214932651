import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { toastr as toaster } from 'react-redux-toastr';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { Button, Form, Grid, Table } from 'semantic-ui-react';
import { currencyFormatter, option_formater as option_formatter } from '../../admin/constants/constants';
import * as global_constants from '../../global_constants';
import * as payment_constants from '../payment_constants';
import ReportHeader from '../../reports/component/report_header';
import { get_contracts, get_insurance_types, log_error } from '../../shared/action/shared_action';
import ConfirmationComponent from '../../shared/component/confirmation_component';
import DateRangePickerComponent from '../../shared/component/date_range_component';
import { currency_comparator, text_comparator } from '../../shared/component/grid/comparators';
import { LoaderComponent } from '../../shared/component/loading_component';
import Selection from '../../shared/component/selection_component';
import * as session_storage from '../../shared/session_storage_utility';
import { set_focus_to_app_header } from '../../shared/tab_navigation_utility';
import { get_all_error, set_order_data, get_lauch_darkley_key_value } from '../../shared/utility';
import { get_adjustment_reason, get_edit_payments_list, delete_payment_adjustment, payment_edit_update } from '../action/payment_action';
import { get_references } from '../batch_payment/action/batch_payment_action';
import { edit_payments_conf } from '../utility';
import EditPaymentRowComponent from './edit_payment_row_component';
import PaymentPagination from './payment_pagination_component';
import * as local_storage from '../../shared/local_storage_utility';

export class EditPaymentsComponent extends React.Component<any, any> {
   
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      is_submitted: false,
      is_grid_visible: false,
      is_clear_button_disabled: false,
      is_search_disabled: false,
      is_delete_disabled: false,
      is_save_disabled: false,
      all_selected: false,
      insurance_types: [],
      insurance_contract: [],
      should_update_child: false,
      is_update_unsaved: false,
      update_loading: false,
      pagination_data: { row_count: 0 },
      display_data: [],
      pagination_start_index: 0,
      pagination_end_index: this.page_size,
      paginated: false,
      error: {
        ins_code: false
      },
        delete_confirmation_box: false,
        show_bummepd_charge_message_popup: false
    };
    this.charge_update_ref = React.createRef();
  }
    page_size = 15;
    charge_open_date=new Date();
  run_report_date: string;
  edit_payment_column_def = edit_payments_conf.column_def;
  payments_node: any;
  charge_update_ref: any;
  token: any = '';
  is_mounted = false;
  patient_id: any = null;
  grid_data = [];
  initial_data = [];
  is_initial_result = false;
  search_criteria = {
    from: null,
    till: null,
    ins_code: '',
    patient_id: 0
  };
  dropdown_data: any = {
    ins_code: [],
    adjustment_qualifier: [],
    money_type: [],
    carc: [],
    payment_code: []
  };
  no_adjustment_code = 'No Adjustment Code';
  rowNodes = [];
  sort_type: string = '';
  sort_key: string = '';
  insurance_code_all = "All";
  insurance_code_all_value = " ";

  // Fetching the report id and params.
  UNSAFE_componentWillMount = () => {
    this.token = this.props.user_login_details.user_data.data.token_details.access_token;
      this.patient_id = session_storage.get('active_patient');     
      
    this.search_criteria = {
      ...this.search_criteria,
      patient_id: session_storage.get('active_patient')
    };
    if (session_storage.get('edit_payments_search_criteria')) {
      this.is_initial_result = true;
      this.search_criteria = {
        ...session_storage.get('edit_payments_search_criteria')
      };
      this.search_criteria = {
        ...this.search_criteria,
        from: this.search_criteria.from ? moment(moment(this.search_criteria.from).format('MM/DD/YYYY')) : null,
        till: this.search_criteria.till ? moment(moment(this.search_criteria.till).format('MM/DD/YYYY')) : null
      };
    }
  };

  componentDidMount = () => {
      this.is_mounted = true;     
    if (this.patient_id == '' || this.patient_id == undefined) {
      session_storage.set('no_patient_selected_for', this.props.location.pathname);
      this.props.history.push(`/no_patient_selected`);
    } else {
      if (!document.body.classList.contains('reports')) {
        document.body.classList.add('reports');
      }
      document.body.classList.add('reports');
      if (this.is_initial_result) {
        this.get_initial_data();
        this.get_edit_payments_list();
      } else {
        this.get_initial_data();
      }
    }
  };

  get_initial_data = () => {
    this.get_data();
    this.get_insurance_master_info();
  };

  //Get Payments NotesData from API function
    componentDidUpdate(prevProps, prevState) {     
    //change page data if patient changes from header, instead of navigate to other page
    if (prevProps.patient_id && prevProps.patient_id != this.props.patient_id) {
      this.patient_id = session_storage.get('active_patient');
      this.get_initial_data();
      this.clear_handler();
    }
  }
  

  componentWillUnmount = () => {
    this.is_mounted = false;
    document.body.classList.remove('reports');
    this.is_mounted = false;
    this.grid_data = [];
    session_storage.remove('edit_payments_search_criteria');
  };

  get_insurance_master_info = async () => {
    var ins_type = await get_insurance_types(this.token).then((res) => {
      if (res.data.data && res.data.data.length > 0) {
        return res.data.data.map((val, index) => {
          return { key: index, value: val.id, text: val.name };
        });
      }
    }); // Get All insurance types from api

    const available_contracts = await get_contracts(this.token).then((res) => {
      if (res.data.data && res.data.data.length > 0) {
        return res.data.data;
      }
    });
    if (this.is_mounted) {
      this.setState({
        insurance_types: ins_type,
        insurance_contract: available_contracts
      });
    }
  };

  get_data = async () => {
    if (this.is_mounted) {
      this.setState({ re_render: true });
    }
    let res = await this.props.get_references(this.patient_id, this.token, 1);
    const insurances = Array.from(
      new Set(this.props.batch_payment_reference.batch_reference.data[5].refrences.map((a) => a.value))
    ).map((value) => {
      return this.props.batch_payment_reference.batch_reference.data[5].refrences.find((a) => a.value === value);
    });

    this.dropdown_data = {
      ins_code: option_formatter(
        this.props.batch_payment_reference.batch_reference.data[5].refrences,
        'title',
        'value',
        true, // to add blank object
        false, // to shorted object
        this.insurance_code_all
      ),
      adjustment_qualifier: option_formatter(
        this.props.batch_payment_reference.batch_reference.data[4].refrences,
        'value',
        'value',
        true, // to add blank object
        true, // to shorted object
        'NA'
      ),
      money_type: option_formatter(
        this.props.user_login_details.payment_methods,
        'text',
        'value',
        true, // to add blank object
        false // to shorted object
      )
    };

    this.dropdown_data['carc'] = await this.get_adjustment_reason();
    let payment_code_list = [];
    let payment_code_data = [...this.props.user_login_details.master_data.data.payment_code];
    payment_code_data.map((val) => {
      val['description'] = val['payment_code'] + ' - ' + val['payment_desc'];
    });
    payment_code_list = set_order_data(
      this.get_active_data(this.props.user_login_details.master_data.data.payment_code),
      'payment_desc'
    );
    this.dropdown_data['payment_code'] = option_formatter(
      payment_code_list,
      'description',
      'payment_id',
      true, // to add blank object
      true // to shorted object
    );
    if (!this.is_initial_result) {
      this.search_criteria = {
        ...this.search_criteria,
        ins_code: this.dropdown_data.ins_code[0].value
      };
    }
    if (this.is_mounted) {
      this.setState({ re_render: false });
    }
  };

  get_active_data = (data) => {
    return data.filter((item) => {
      if (item.is_active) {
        return item;
      }
    });
  };

  formate_data = (data) => {
    let formatted_data = [];
    formatted_data = data.map((item, index) => {
      return (item = {
        ...item,
        date_of_service: moment(item.date_of_service).format('MM/DD/YYYY') || '',
        adjustment_amount: item.adjustment_amount ? item.adjustment_amount : 0,
        xfer_amount: item.xfer_amount ? item.xfer_amount : 0,
        payment_amount: item.payment_amount ? item.payment_amount : 0,
        pat_resp_amt: item.pat_resp_amt ? item.pat_resp_amt : 0,
        adjustment_code: item.adjustment_code || '',
        adjustment_reason: item.adjustment_reason || '',
        batch_num: item.batch_num || '',
        selected: false
      });
    });

    return formatted_data;
  };

  get_adjustment_reason = () => {
    return get_adjustment_reason(this.token).then(
      (response) => {
        if (response.data && response.data.data) {
          return option_formatter(
            response.data.data,
            'adjustment_reason',
            'id',
            true, // to add blank object
            false, // to shorted object
            this.no_adjustment_code
          );
        } else {
          return [];
        }
      },
      (error) => {
        return [];
      }
    );
  };

  get_edit_payments_list = async () => {
    if (this.is_mounted) {
      this.grid_data = [];
      this.sort_type = '';
      this.sort_key = '';
      this.setState({
        pagination_data: { row_count: 0 },
        loading: true,
        is_submitted: true,
        is_grid_visible: false,
        is_clear_button_disabled: true,
        is_search_disabled: true,
        is_delete_disabled: false,
        is_save_disabled: false,
        all_selected: false,
        display_data: []
      });
    }
    let params = {
      ...this.search_criteria,
      from: this.search_criteria.from ? moment(this.search_criteria.from).format('MM-DD-YYYY') : '',
      till: this.search_criteria.till ? moment(this.search_criteria.till).format('MM-DD-YYYY') : '',
      ins_code: this.search_criteria.ins_code == this.insurance_code_all_value ? null : this.search_criteria.ins_code
    };

    await get_edit_payments_list(this.token, params).then(
      (response: { data: { status: any; data: any[]; messages: any } }) => {
          if (response.data.status) {
              if (response.data.data.length > 0) {
                  this.charge_open_date = response.data.data[0].charge_open_date;
              }
          this.grid_data = this.formate_data(response.data.data);
          this.initial_data = this.formate_data(response.data.data);
          
          if (this.is_mounted) {
            this.setState({
              loading: false,
              is_submitted: false,
              is_grid_visible: true,
              is_clear_button_disabled: false,
              is_search_disabled: false,
              is_delete_disabled: false,
              is_save_disabled: false,
              display_data: this.grid_data.slice(0, this.page_size),
              pagination_data: { row_count: this.grid_data.length }
            });
          }
        } else {
          if (this.is_mounted) {
            this.setState({
              loading: false,
              is_submitted: false,
              is_grid_visible: true,
              is_clear_button_disabled: false,
              is_search_disabled: false,
              is_delete_disabled: false,
              is_save_disabled: false,
              display_data: [],
              pagination_data: { row_count: 0 }
            });
          }
          log_error(response.data.messages);
          if (response.data.messages) {
            const toastr_options = this.show_html_content_toaster(get_all_error(response.data.messages));
            toaster.error('', toastr_options);
          }
        }
      },
      (error: { response: { data: any } }) => {
        if (this.is_mounted) {
          this.setState({
            loading: false,
            is_submitted: false,
            is_grid_visible: true,
            is_clear_button_disabled: false,
            is_search_disabled: false,
            is_delete_disabled: false,
            is_save_disabled: false,
            display_data: [],
            pagination_data: { row_count: 0 }
          });
        }
        log_error(error);
        if (error.response.data) {
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error('', toastr_options);
        }
      }
    );
  };

  // Calender change handler.
  range_date_change_handler = (from_date: any, end_date: any) => {
    if (this.is_mounted) {
      this.search_criteria = {
        ...this.search_criteria,
        from: from_date || null,
        till: end_date || null
      };
      this.setState({
        search_criteria: this.search_criteria,
        is_error_date: from_date
          ? moment.isMoment(from_date)
            ? false
            : true
          : false || end_date
          ? moment.isMoment(end_date)
            ? false
            : true
          : false
      });
    }
  };

  // Dropdown change handler.
  dropdown_change_handler = (event: {
    target: { selectedOptions?: any; options?: any; selectedIndex?: any; id?: any; value?: any };
  }) => {
    const { id, value } = event.target;
    this.search_criteria[id] = value;
    let {error} = this.state;
    if(value) {
      error.ins_code = false;
    }
    this.setState({
      search_criteria: this.search_criteria,
      error: error
    });
  };

    required_field_validator = () => {

      let has_error = false;
      let error = {
          ins_code: false          
      };

      if (! this.search_criteria.ins_code.trim()) {
          has_error = true;
          error.ins_code = true;

          let ele = document.getElementById("ins_code")
          ele && ele.focus();
          
      }

      if (has_error) {
          toaster.error('', global_constants.constants.mandatory_fields);
          if (this.is_mounted) {
              this.setState({
                  error: error
              });
          }

          return true;
      }

      return false;
  }

  search_handler = (e: { preventDefault: () => void; stopPropagation: () => void }) => {
    let is_error = false;
    if (this.is_mounted) {
      this.setState({ is_submitted: true });
    }
    // // Validate Required Field
    // is_error = this.required_field_validator();
    // if (is_error) {
    //   return;
    // }
    this.get_edit_payments_list();
    session_storage.set('edit_payments_search_criteria', this.search_criteria);
    this.run_report_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  clear_handler = () => {
    if (this.is_mounted) {
      this.setState({
        loading: true,
        is_grid_visible: false,
        is_clear_button_disabled: false,
        is_search_disabled: false,
        is_delete_disabled: true,
        is_save_disabled: true,
        all_selected: false,
        display_data: [],
        pagination_start_index: 0,
        pagination_end_index: this.page_size,
        paginated: false,
        is_submitted: false
      });
    }
    this.search_criteria = {
      from: null,
      till: null,
      ins_code: this.dropdown_data.ins_code[0].value,
      patient_id: session_storage.get('active_patient')
    };
    session_storage.remove('edit_payments_search_criteria');
    this.grid_data = [];
    this.initial_data = [];
    this.sort_type = '';
    this.sort_key = '';
    if (this.is_mounted) {
      this.setState({
        loading: false
      });
    }
  };

  show_html_content_toaster = (msg: any) => {
    return {
      component: () => (
        <div>
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        </div>
      ),
      timeOut: global_constants.toastr_options.toastr_time_out,
      preventDuplicates: true
    };
  };

    select_all_handler = (event) => {
        let display_data = this.state.display_data;
        // updating the grid values
        display_data.map((row) => {
            row.selected = event.target.checked;
        })
    // updating the grid values
    this.grid_data.map((row) => {
      row.selected = event.target.checked;
    })

    if (event.target.checked) {      
      this.setState({
        all_selected: true,
          should_update_child: true,
          display_data: display_data,
      }, () => {
          this.setState({
              should_update_child: false
          })
      });
    } else {
      this.setState({
        all_selected: false,
          should_update_child: true,
          display_data: display_data
      },()=> {
              this.setState({
                  should_update_child: false
              })
          });
    }    
  };

  select_row_handler = (checked: any, item) => {

    let update_index = this.grid_data.findIndex((row_item) => row_item.payment_adjustment_id == item.payment_adjustment_id);
    let new_node = this.grid_data[update_index];
    new_node.selected = checked;
    this.grid_data[update_index] = new_node;

    //setting if all are selected or not
    const { all_selected } = this.state;
    let is_all_checked = true;

    this.grid_data.map((row_item) => {
      if(!row_item.selected) {
        is_all_checked = false;
      }
    });

    if (is_all_checked !== all_selected) {
      this.setState({
        all_selected: is_all_checked
      });
    }
  }

  // Call on column sorting click
  sortBy = (key) => {
    this.persist_data_change(() => {
      let arrayCopy = [...this.state.display_data];
      this.sort_key = key;
      this.sort_type = this.get_sort_type(key, this.sort_type);
      if (this.sort_type !== '') {
        switch (key) {
          case 'payment_amount':
            arrayCopy.sort((a, b) => {
              let first = parseFloat(a.payment_amount);
              let next = parseFloat(b.payment_amount);
              return this.order_currency_by(first, next, this.sort_type);
            });
            break;
          case 'adjustment_amount':
            arrayCopy.sort((a, b) => {
              let first = parseFloat(a.adjustment_amount);
              let next = parseFloat(b.adjustment_amount);
              return this.order_currency_by(first, next, this.sort_type);
            });
            break;
          case 'xfer_amount':
            arrayCopy.sort((a, b) => {
              let first = parseFloat(a.xfer_amount);
              let next = parseFloat(b.xfer_amount);
              return this.order_currency_by(first, next, this.sort_type);
            });
            break;
          case 'pat_resp_amt':
            arrayCopy.sort((a, b) => {
              let first = parseFloat(a.pat_resp_amt);
              let next = parseFloat(b.pat_resp_amt);
              return this.order_currency_by(first, next, this.sort_type);
            });
            break;
          case 'payment_code':
            arrayCopy.sort((a, b) => {
              return this.order_by(a.payment_code, b.payment_code, this.sort_type);
            });
            break;
          case 'money_type':
            arrayCopy.sort((a, b) => {
              return this.order_by(a.money_type, b.money_type, this.sort_type);
            });
            break;
          case 'transfer_to_payer':
            arrayCopy.sort((a, b) => {
              return this.order_by(a.xfer_ins_code, b.xfer_ins_code, this.sort_type);
            });
            break;          
          case 'from_insurance':
            arrayCopy.sort((a, b) => {
              return this.order_by(a.from_ins_code, b.from_ins_code, this.sort_type);
            });
            break;          
          default:
            arrayCopy.sort((a, b) => this.order_by(a[key], b[key], this.sort_type));
            break;
        }
      } else {
        arrayCopy = this.grid_data.slice(this.state.pagination_start_index, this.state.pagination_end_index);
      }
      this.setState({
        display_data: arrayCopy
      });
    });
  }

  // Change sorting type
  get_sort_type = (key, type) => {
    if (key === this.sort_key) {
      switch (type) {
        case 'asc':
          return 'desc';
        case 'desc':
          return '';
        default:
          return 'asc';
      }
    }
  };

  // Perform sorting
  order_by = (first, next, type) => {
    if (type === 'asc') {
      return text_comparator(first, next);
    } else if (type === 'desc') {
      return text_comparator(next, first);
    }
  };

  order_currency_by = (first, next, type) => {
    if (type === 'asc') {
      return currency_comparator(first, next);
    } else if (type === 'desc') {
      return currency_comparator(next, first);
    }
  };

  // Change sorting icon
  render_sort_icon = (key) => {
    if (key === this.sort_key) {
      switch (this.sort_type) {
        case 'asc':
          return 'ascending';
        case 'desc':
          return 'descending';
        default:
          return '';
      }
    }
  };

    render_header = () => {
        if (this.props.edit_payments_small_updates_ff) {
            this.edit_payment_column_def = edit_payments_conf.update_column_def;
        } else {
            this.edit_payment_column_def = edit_payments_conf.column_def;
        }
    return (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textAlign='center' verticalAlign='middle' style={{ width: '35px', minWidth: '35px' }}>
            <Form.Field className='ui checkbox'>
              <input
                type='checkbox'
                name={'all_selected'}
                id={'all_selected'}
                checked={this.state.all_selected}
                onChange={this.select_all_handler}
              />
              <label className='chkbx_label' htmlFor={'all_selected'}></label>
            </Form.Field>
          </Table.HeaderCell>
          {this.edit_payment_column_def.map((colData, index) => {
            return (
              <Table.HeaderCell
                style={{ width: `${colData.width}px`, minWidth: `${colData.minWidth}px` }}
                key={`row-${colData.field}-${index}`}
              >
                <div
                  className={colData.sort ? 'table-col-header-container' : ''}
                  onClick={() => this.sortBy(colData.field)}
                  style={{ cursor: 'pointer' }}
                >
                  <span className='header-col-label' id={colData.field}>
                    {colData.headerName}
                  </span>
                  {colData.sort && (
                    <span className='col-header-sort-icon'>
                      <i aria-hidden='true' className={`sort ${this.render_sort_icon(colData.field)} small icon`}></i>
                    </span>
                  )}
                </div>
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>
    );
  };

  render_body = (grid_data: any[]) => {
    const auth_data = local_storage.get("auth_data");
    let accrual_flag = false;
    let open_month = null;
    if (auth_data) {
        accrual_flag = auth_data.accrual_accounting || false;
        open_month = auth_data.open_month || null;
    }
    const { insurance_types, insurance_contract } = this.state;
    if (grid_data.length > 0) {
      return grid_data.map((item, index) => {
        return (
            <EditPaymentRowComponent
             edit_payments_small_updates_ff= {this.props.edit_payments_small_updates_ff}
            key={`row-${index}`}
            item={item}
            index={index}
            onRef={(ref) => (this.rowNodes[index] = ref)}
            dropdown_data={this.dropdown_data}
            insurance_types={insurance_types}
            insurance_contract={insurance_contract}
            select_row_handler={this.select_row_handler}
            shouldUpdate={this.state.should_update_child}
            accrualFlag={accrual_flag}            
            openMonth={open_month}
          />
        );
      });
    } else {
      return (
        <Table.Row>
          <Table.Cell colSpan={15} textAlign='center'>
            {global_constants.url_rewrite.error_message}
          </Table.Cell>
        </Table.Row>
      );
    }
  };

  validate_data_for_save = (data) => {
    let error = false;
    let error_msg = "";

    for(let i = 0; i < data.length; i++) {
        let item = data[i];
        let adj_qual = item.adjustment_qualifier;
        if (adj_qual == "PR" || adj_qual == "CO")
        {
            let transfer_detail = item.pat_resp_amt;
            let adjustment_amount = item.adjustment_amount;
            let payment_amount = item.payment_amount;
            if (transfer_detail != 0 && adjustment_amount != 0)
            {
                error = true;
                error_msg = "Can not enter value in both Adjustment Amt and Transfer Detail for qualifier PR and CO.";
                break;
            }
            if (adj_qual == "PR" && transfer_detail == 0)
            {
                error = true;
                error_msg = "For PR qualifier, enter value in Transfer Detail.";
                break;
            }
            if (adj_qual == "CO" && (adjustment_amount == 0 && payment_amount == 0))
            {
                error = true;
                error_msg = "For CO qualifier, enter value in Adjustment Amt and/or Payment Amt.";
                break;
            }
          }
    }

    if(error) {
      toaster.error('', error_msg);
      return false;
    }

    return true;
  }

  // Save Data
  handle_save_click = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    this.setState({
        loading: true
    });
    this.persist_data_change(() => {
          let data_changed = []

          // Preparing changed data to send for update
          this.grid_data.map((node, index) => {
            let grid_node = {...node};
            let initial_node = {...this.initial_data[index]};
            let key_to_remove = 'selected';
            delete grid_node[key_to_remove]; 
            delete initial_node[key_to_remove]; 
            if (JSON.stringify(grid_node) != JSON.stringify(initial_node)) {
                grid_node.deposit_date = grid_node.deposit_date ? this.format_date_for_save(grid_node.deposit_date) : null,
            data_changed.push(grid_node); 
            }
          });

          if( ! this.validate_data_for_save(data_changed)) {
            this.setState({
              loading: false
            });
            return;
          }

          payment_edit_update(data_changed, this.token).then(res => {
              if (res.data && res.data.status == 1 && this.is_mounted) {
                  this.setState({
                      loading: false
                  });
                  toaster.success('', `Saved Successfully.`);
                  if (this.props.edit_payments_small_updates_ff && document.getElementById('app_header_search_patient_icon')) {
                      document.getElementById('app_header_search_patient_icon').click();
                  }
                  this.get_edit_payments_list();
              }
          },
              error => {
                  if (error && error.response && error.response.data && error.response.data.messages && error.response.data.messages.length > 0) {
                      let charge_exception = error.response.data.messages.filter((item) => {
                          return item.message === global_constants.charge_exception.text
                      })
                      if (charge_exception && charge_exception.length > 0) {
                          if (this.is_mounted) {
                              this.setState({
                                  loading: false,
                                  show_bummepd_charge_message_popup: true
                              })
                          }
                      } else {
                          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                          toaster.error('', toastr_options);
                      }
                  }

              this.setState({
                  loading: false
              });
              //if (error.response.data) {
              //    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
              //    toaster.error("", toastr_options);
              //}
          });
      });
  };
  get_date = (date, pattern) => {
      if (date) {
          return moment(date).format(pattern);
      }
      return '';
  }

    format_date_for_save = (date) => {
        if (date) {
            return moment(date).format('YYYY-MM-DDTHH:mm:ss');
        }
        return '';
    }

  on_close_delete_confirmation = () => {
    if (this.is_mounted) {
        this.setState({
            delete_confirmation_box: false
        });
    }
  }

  on_delete_confirmation = () => {
    if (this.is_mounted) {
        this.setState({
            delete_confirmation_box: false
        });
    }

    this.delete_handler(true);
  }

  // Delete Data
  delete_handler = (bypass_delete_check: any= false) => {
   
    this.setState({
      loading: true
    });

    this.persist_data_change(() => {
      let selected_data = []
     
      this.grid_data.map((item) => {
        if (item.selected) {
          selected_data.push(item.payment_adjustment_id);
        }
      });
       

      if(selected_data.length == 0) {
        this.setState({
          loading: false
        });
        toaster.error('', payment_constants.payment_message.DELETE_ERROR);
        return;
      }

      if (!bypass_delete_check) {
        this.setState({
            delete_confirmation_box: true,
            loading: false
        });
        return;
     }

        let final_data = { ids: selected_data, charge_open_date: this.charge_open_date }
        delete_payment_adjustment(final_data, this.token).then(res => {
        if (res.data && res.data.status == 1 && this.is_mounted) {
            this.setState({
                loading: false
            });
            toaster.success('', `Deleted Successfully.`);
            this.get_edit_payments_list();
        }         
      },
            error => {
                if (error && error.response && error.response.data && error.response.data.messages && error.response.data.messages.length > 0) {
                    let charge_exception = error.response.data.messages.filter((item) => {
                        return item.message === global_constants.charge_exception.text
                    })
                    if (charge_exception && charge_exception.length > 0) {
                        if (this.is_mounted) {
                            this.setState({
                                loading: false,
                                show_bummepd_charge_message_popup: true
                            })
                        }
                    } else {
                        const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                        toaster.error("", toastr_options);
                    }
                }
          this.setState({
              loading: false
          });
         
      });
    });
  };

  // Pagination change callback function
  on_pagination_change = (start_index, end_index) => {

    this.persist_data_change(() => {
      this.sort_type = '';
      this.sort_key = '';
      const data = this.grid_data.slice(start_index, end_index);
      this.setState({
        display_data: data,
        //should_update_child: true,
        is_update_unsaved: false, //discord unsaved changes
        pagination_start_index: start_index,
        pagination_end_index: end_index,
        paginated: true
      });
    });
  };

  persist_data_change = (callback) => {
    let startIndex = this.state.pagination_start_index;
    let endIndex = this.state.pagination_end_index;
    
    this.rowNodes.map((node, index) => {
      if(node && node.has_data_changed(node.props.item)) {
        let update_index = this.grid_data.findIndex((item) => item.payment_adjustment_id == node.props.item.payment_adjustment_id);
        let new_node = this.rowNodes[index].get_updated_data();
        this.grid_data[update_index] = new_node;
      }
    });

    callback();
  }
    refresh_bummepd_charge_modal = () => {
        if (this.is_mounted) {
            this.setState({
                show_bummepd_charge_message_popup: false
            }, () => {
                this.run_report_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
                this.get_edit_payments_list();
                //if (this.props.charge_review_or_audit) {
                //    if (this.props.save_response) {
                //        this.props.save_response();
                //    }
                //}
                //else if (this.state.charge.charge_id > 0) {
                //    this.props.history.goBack();
                //}
            });
        }
    }
    on_close_bummepd_charge_popup = () => {
        if (this.is_mounted) {
            this.setState({
                show_bummepd_charge_message_popup: false
            });
        }
    }
  render() {
    let {is_submitted, error, delete_confirmation_box} = this.state;
    return (
      <LoaderComponent loading={this.state.loading}>
          {delete_confirmation_box ?
                <ConfirmationComponent message={payment_constants.payment_message.DELETE_CONFIRM}
                    title='Confirm' save_button_text={"OK"} show_modal={delete_confirmation_box} onCloseModal={this.on_close_delete_confirmation} on_force_save_click={(e) => { this.on_delete_confirmation() }} />
                : ''}
            {this.state.show_bummepd_charge_message_popup ?
                <ConfirmationComponent message={global_constants.charge_exception.message}
                    title='Confirm' show_modal={this.state.show_bummepd_charge_message_popup}
                    onCloseModal={this.on_close_bummepd_charge_popup}
                    save_button_text='Refresh'
                    on_force_save_click={(e) => { this.refresh_bummepd_charge_modal() }} />
                : ''}
        <div className={'common-forms-add '}>
          <div className={'common-forms-search report-framework error-correction'} style={{ paddingRight: 0 }}>
            <ReportHeader title={'Edit Payments'} />
            <div
              style={{
                overflow: 'auto',
                paddingRight: 15,
                flex: '1 1 0px'
              }}
            >
              <div id='id_div_popper' />
              <div
                className='patient-search-form  patient_search_bottom_padding'
                id='report-criteria-container'
                style={
                  this.state.is_grid_visible ? { padding: '7px 15px 0 15px' } : { borderBottom: '1px solid #cccccc' }
                }
              >
                <Form autoComplete='off' onSubmit={this.search_handler}>
                  <Grid>
                    <Grid.Column tablet={8} computer={4}>
                      <Form.Field>
                        <label>Insurance<span className={error.ins_code && is_submitted ? "req-alert" : 'req-alert_normal'}> (required)</span></label>
                        <Selection
                          id={'ins_code'}
                          name={'ins_code'}
                          defaultValue={this.search_criteria.ins_code}
                          options={this.dropdown_data.ins_code}
                          onChange={(value, event) => {
                            this.dropdown_change_handler(event);
                          }}
                          style={error.ins_code && is_submitted ? 'req-background-inp' : ''}
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column tablet={8} computer={4}>
                      <Form.Field>
                        <label>Deposit Date</label>
                        <DateRangePickerComponent
                          updateDatesChange={(from_date: any, end_date: any) =>
                            this.range_date_change_handler(from_date, end_date)
                          }
                          startDate={this.search_criteria.from}
                          endDate={this.search_criteria.till}
                          error={false}
                          id={'deposit_date'}
                          maxDate={moment()}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid>
                  <Grid>
                    <Grid.Column tablet={16} computer={16} textAlign='right'>
                      <Button
                        type='reset'
                        id='clear_report_button'
                        basic
                        onClick={this.clear_handler}
                        disabled={this.state.is_clear_button_disabled}
                        content={'Clear'}
                      />
                      <Button
                        id='create_report_button'
                        className='primary'
                        type='submit'
                        style={{ marginRight: '0' }}
                        disabled={this.state.is_search_disabled}
                        content={'Search'}
                      />
                    </Grid.Column>
                  </Grid>
                </Form>
              </div>
              <div
                className='customtable'
                style={this.state.is_grid_visible ? { borderTop: 0, borderBottom: 0 } : { display: 'none' }}
              >
                <PaymentPagination
                  data={this.state.pagination_data}
                  onChange={this.on_pagination_change}
                  changeTimestamp={this.state.data_change_timestamp}
                  message={this.run_report_date}
                  page_size={this.page_size}
                />
              </div>
              {this.state.is_grid_visible && (
                <Form autoComplete='off' className='normal-form padd-r-0' style={{ paddingBottom: 0 }}>
                  <div className='customtable' style={{ borderTop: 0 }}>
                    <div className='table-responsive' id='edit_charge'>
                      <Table id='edit_payments'>
                        {this.render_header()}
                        <Table.Body>{this.render_body(this.state.display_data)}</Table.Body>
                      </Table>
                    </div>
                  </div>
                </Form>
              )}
            </div>
            <div
              className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
              id='applicationFooterSticky'
              style={{ paddingRight: 15 }}
            >
              <Grid style={{ marginTop: 0, marginBottom: 0 }}>
                <Grid.Column computer={16} tablet={16} textAlign='right'>
                  <Button
                    id='delete_button'
                    type='button'
                    onClick={(e)=>this.delete_handler(false)}
                    basic
                    disabled={this.state.is_grid_visible ? this.state.is_delete_disabled : true}
                    content={'Delete'}
                  />
                  <Button
                    id='save'
                    primary
                    onClick={this.handle_save_click}
                    type='button'
                    onKeyDown={set_focus_to_app_header}
                    style={{ marginRight: '0' }}
                    disabled={this.state.is_grid_visible ? this.state.is_delete_disabled : true}
                    content={'Save'}
                  />
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </div>
      </LoaderComponent>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return bindActionCreators(
    {
      get_references: get_references
    },
    dispatch
  );
};

// Get user and login details from store.
const mapStateToProps = (state: {
    user_login_details: any;
    launch_darkly: any;
  batch_payment_reference: any;
  patient_details: { patient_header: { data: { id: any } } };
}) => {
  return {
    user_login_details: state.user_login_details,
      batch_payment_reference: state.batch_payment_reference,
      edit_payments_small_updates_ff: get_lauch_darkley_key_value(state.launch_darkly, "rsiB32470EditPaymentsSmallUpdates"),
    patient_id:
      (state.patient_details &&
        state.patient_details.patient_header &&
        state.patient_details.patient_header.data &&
        state.patient_details.patient_header.data.id) ||
      ''
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPaymentsComponent);
