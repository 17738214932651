import * as React from 'react'
import { connect } from 'react-redux';
import { Button, Grid, Form, Input, Checkbox, Accordion, Icon } from 'semantic-ui-react';
import { expected_rate } from '../../constants';
import DateRangePickerComponent from '../../../../shared/component/date_range_component';  
import { date_format } from '../../../../global_constants';
import moment = require('moment');
import { custom_date_format } from '../../../../shared/utility';
import  FeeComponent  from './fee_component';
import { handle_click_on_enter } from '../../../../shared/tab_navigation_utility';

export class DefinitionComponent extends React.Component<any, any>{

    _is_mounted = false;
    
    // #region lifecycle methods
    constructor(props) {
        super(props);
        this.state = {
            display_on_hover: false
        }
    }

    componentDidMount = () => {
        this._is_mounted = true;
    }

    componentWillUnmount = () => {
        this._is_mounted = false;
    }

    shouldComponentUpdate(nextProps, nextState) {
        let data = this.props.data;
        let new_data = nextProps.data;

        if (this.state.display_on_hover != nextState.display_on_hover) return true;
        
        // #region expected rate object compare
        if (data.expected_rate_id != new_data.expected_rate_id) return true
        if (data.name != new_data.name) return true
        if (data.start_date != new_data.start_date) return true
        if (data.end_date != new_data.end_date) return true
        if (data.is_expanded != new_data.is_expanded) return true;
        if (data.is_modified != new_data.is_modified) return true;
        if (data.is_expired != new_data.is_expired) return true;
        if (data.include_cpt_with_no_value != new_data.include_cpt_with_no_value) return true
        // #endregion 
        if (JSON.stringify(this.props.validation) !== JSON.stringify(nextProps.validation)) return true
        if (this.props.row_id != nextProps.row_id) return true;
        if (this.props.is_save_in_progress != nextProps.is_save_in_progress) return true;
        //Insurance Code Id
        if (JSON.stringify(data.assigned_insurances_ids) != JSON.stringify(new_data.assigned_insurances_ids)) return true;
        if (JSON.stringify(data.assigned_insurance_class_ids) != JSON.stringify(new_data.assigned_insurance_class_ids)) return true;
        //Expected Rate Fees Data
        if (data.expected_rate_fees.length != new_data.expected_rate_fees.length) return true;
        if (JSON.stringify(data.expected_rate_fees) != JSON.stringify(new_data.expected_rate_fees)) return true;

        return false;
    }
    // #endregion lifecycle methods

    //#region call back methods
    toggle_expected_rate_definition = () => {
        this.props.onToggleExpectedRateDefinition && this.props.onToggleExpectedRateDefinition( this.props.data.expected_rate_id);
    }  

    update_expected_rate_definition = (identifier, event) => {
        var value = event;
        if (identifier == expected_rate.identifier.name)
            value = event.target.value;
        this.props.updateExpectedRateDefinition && this.props.updateExpectedRateDefinition(this.props.data.expected_rate_id, identifier, value);
    }

    on_click_apply_to_insurance_codes = () => {
        this.props.openInsuranceCodesDrawer && this.props.openInsuranceCodesDrawer();
    }

    add_new_fee_item = (expected_rate_id) => {
        this.props.addNewFeeItem && this.props.addNewFeeItem(expected_rate_id,0);
    }

    update_expected_rate_fee_definition_include_future_providers = (expected_rate_fee_id, identifier, value, future) => {
        var data = {
            expected_rate_id: this.props.data.expected_rate_id,
            expected_rate_fee_id: expected_rate_fee_id,
            identifier: identifier,
            value: value,
            future: future
        };
        this.update_expected_rate_definition(expected_rate.identifier.expected_rate_fee_definition, data);
    }

    update_expected_rate_fee_definition = (expected_rate_fee_id, identifier, value) => {
        var data = {
            expected_rate_id: this.props.data.expected_rate_id,
            expected_rate_fee_id: expected_rate_fee_id,
            identifier: identifier,
            value: value,
            future: false
        };
        this.update_expected_rate_definition(expected_rate.identifier.expected_rate_fee_definition, data);
    }

    copy_expected_rate_definition_prompt = () => {
        this.props.copy_expected_rate_definition_prompt(this.props.data.expected_rate_id);
    }
   
    update_expected_rate_fee_definition_procedure_code = (expected_rate_fee_id, procedure_code_id, identifier, value) => {
        var data = {
            expected_rate_id: this.props.data.expected_rate_id,
            expected_rate_fee_id: expected_rate_fee_id,
            procedure_code_id: procedure_code_id,
            identifier: identifier,
            value: value
        };
        this.update_expected_rate_definition(expected_rate.identifier.expected_rate_fee_definition_procedure_code, data);
    }

    delete_expected_rate_definition_prompt = () => {
        this.props.delete_expected_rate_definition_prompt(this.props.data.expected_rate_id, (this.props.data.name || expected_rate.expected_rate_untitled));
    }

    copy_expected_rate_fee_definition = (expected_rate_fee_id) => {
        this.props.copyExpectedRateFeeDefinition && this.props.copyExpectedRateFeeDefinition(this.props.data.expected_rate_id,expected_rate_fee_id);
    }

    delete_expected_rate_fee_definition_prompt = (expected_rate_fee_id) => {
        this.props.deleteExpectedRateFeeDefinitionPrompt && this.props.deleteExpectedRateFeeDefinitionPrompt(this.props.data.expected_rate_id, expected_rate_fee_id);
    }
    //#endregion

    // #region helper methods
    getDisplayFormatDate = (date) => {
        if (!date)
            return null;
        else
            return moment(custom_date_format(date, date_format["mm/dd/yyyy"]));
    }

    convertInDateFormat = (date) => {
        if (date)
            return date.format('YYYY-MM-DDT00:00:00');
        else
            return null;
    }

    handleDOSChange = (startDate, endDate) => {
        var date = {
            start_date: this.convertInDateFormat(startDate),
            end_date: this.convertInDateFormat(endDate)
        };
        this.update_expected_rate_definition(expected_rate.identifier.dos_range, date);
    }

    handle_include_cpt_with_no_value = (e) => {
        const { checked } = e.target;
        this.update_expected_rate_definition(expected_rate.identifier.include_cpt_with_no_value, checked);
    };
    // #endregion

    // #region Validation Helper Methods
    has_title_validation = () => {
        var has_required_validation = this.props.validation && this.props.validation.has_required_validation
        var has_validation_msg = this.props.validation &&
            (this.props.validation.name && this.props.validation.name.length > 0) ||
            (this.props.validation.start_date && this.props.validation.start_date.length > 0) ||
            (this.props.validation.end_date && this.props.validation.end_date.length > 0) ||
            (this.props.validation.assigned_insurances_ids && this.props.validation.assigned_insurances_ids.length > 0) ||
            (this.props.validation.expected_rate_fees && this.props.validation.expected_rate_fees.length > 0) ||
            (this.props.validation.expected_rate_fee_validations && this.props.validation.expected_rate_fee_validations.length > 0) ||
            (this.props.validation.expected_rate_fee_procedure_codes_validations && this.props.validation.expected_rate_fee_procedure_codes_validations.length > 0);

        return (this.props.is_save_in_progress && (has_validation_msg || has_required_validation));
    }

    mark_field_for_validation = (field_name) => {
        let has_field_validation = false;
        switch (field_name) {
            case expected_rate.identifier.name:
                has_field_validation = !this.props.data.name || this.props.validation.name;
                break;
            case expected_rate.identifier.dos_range:
                has_field_validation = !this.props.data.start_date || (this.props.data.end_date && new Date(this.props.data.end_date).getTime() == NaN) || this.props.validation.start_date || this.props.validation.end_date;
                break;
            
        }
        has_field_validation = has_field_validation && this.props.is_save_in_progress;
        return has_field_validation;
    }

    mark_field_for_required_validation = (field_name) => {
        let has_required_filed_validation = false;
        switch (field_name) {
            case expected_rate.identifier.name:
                has_required_filed_validation = !this.props.data.name;
                break;
            case expected_rate.identifier.dos_range:
                has_required_filed_validation = !this.props.data.start_date || (this.props.data.end_date && new Date(this.props.data.end_date).getTime() == NaN);
                break;
        }
        has_required_filed_validation = has_required_filed_validation && this.props.is_save_in_progress;
        return has_required_filed_validation;
    }

    display_required_text = (field_name) => {
        let has_validation_msg = false;
        switch (field_name) {
            case expected_rate.identifier.name:
                has_validation_msg = this.props.data.name && this.props.validation.name;               
                break;
            case expected_rate.identifier.dos_range:
                has_validation_msg = this.props.data.start_date && this.props.validation.end_date && this.props.validation.start_date;
                break;
            case expected_rate.identifier.expected_rate_fees:
                has_validation_msg = this.props.validation.expected_rate_fees || (this.props.data.expected_rate_fees && this.props.data.expected_rate_fees.length > 0);
                break;
        }
        has_validation_msg = has_validation_msg && this.props.is_save_in_progress;
        return has_validation_msg;
    }

    //this is different from others as it needs to load red when no codes are assigned.
    get_insurance_button_validation = () => {
        let has_insurance_validation = !this.props.data.assigned_insurances_ids ||
            this.props.data.assigned_insurances_ids.length == 0 ||
            (this.props.validation.assigned_insurances_ids && this.props.validation.assigned_insurances_ids.length > 0);

        let has_class_validation = !this.props.data.assigned_insurance_class_ids ||
        this.props.data.assigned_insurance_class_ids.length == 0

        return has_insurance_validation && has_class_validation;
    }

    get_validation_for_expected_rate_fees = () => {
        return this.props.validation.expected_rate_fee_validations;
    }

    get_validation_for_expected_rate_fee_procedure_codes = () => {
        return this.props.validation.expected_rate_fee_procedure_codes_validations;
    }

    get_required_text_div = (field_name) => {
        return (
            <div className='requiredText'>
                {
                    this.display_required_text(field_name)
                        ? this.props.validation[field_name]
                        : ''
                }
            </div>
        );
    }
    // #endregion

    //#region action events
    on_mouse_enter_copy_icon = () => {
        this.setState({ display_on_hover:true});
    }

    on_mouse_leave_copy_icon = () => {
        this.setState({ display_on_hover: false });
    }
    //#endregion
    
    render() {
        var hidden = { display: this.props.data.is_expanded ? "block" : "none" }
        return (
 
            <div className="expected_rate">
                <div className="ui form common-forms">
                    <div className="sixteen wide computer column">
                            <Accordion fluid styled className='common-accordion'>
                                <Accordion.Title
                                    index={0}
                                    active={this.props.data.is_expanded}
                                    onClick={this.toggle_expected_rate_definition.bind(this)}
                                    onMouseEnter={this.on_mouse_enter_copy_icon}
                                    onMouseLeave={this.on_mouse_leave_copy_icon}
                                    className={this.has_title_validation() ? 'req-alert' : ''}
                                >
                                    <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name={this.props.data.is_expanded ? 'angle up' : 'angle down'} />
                                    <span id={'er_title_' + this.props.row_id}>{this.props.data.name || expected_rate.expected_rate_untitled}</span>
                                    {
                                        this.state.display_on_hover &&
                                        <span style={{ float: 'right' }}>
                                            <i id={'er_copy_' + this.props.row_id} onClick={this.copy_expected_rate_definition_prompt.bind(this)}
                                                className="icon copy"
                                                style={{ marginRight: 2 }}
                                            /> 
                                            <i id={'er_trash_' + this.props.row_id} onClick={this.delete_expected_rate_definition_prompt.bind(this)}
                                                className="icon trash"
                                                style={{ marginRight: 1 }}
                                            /> 
                                        </span>
                                    }
                            </Accordion.Title>
                            <Accordion.Content active={this.props.data.is_expanded}>
                                <Grid>
                                    <Grid.Column tablet={8} computer={4}>
                                        <Form.Field>
                                            <label>
                                                Name<span className={this.mark_field_for_required_validation(expected_rate.identifier.name) ? "req-alert" : 'req-alert_normal'}> (required)</span>
                                            </label>
                                            <Input
                                                autoComplete='off'
                                                name='name'
                                                id={'er_name_' + this.props.row_id}
                                                value={this.props.data.name}
                                                type='text'
                                                onChange={value => this.update_expected_rate_definition(expected_rate.identifier.name, value)}
                                                maxLength={100}
                                                className={this.mark_field_for_validation(expected_rate.identifier.name) ? 'req-border-inp' : ''}
                                            />
                                            {this.get_required_text_div(expected_rate.identifier.name)}
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column tablet={8} computer={4}>
                                        <Form.Field>
                                            <label>
                                                Date Of Service Range<span className={this.mark_field_for_required_validation(expected_rate.identifier.dos_range) ? "req-alert" : 'req-alert_normal'}> (required)</span>
                                            </label>
                                            <DateRangePickerComponent
                                                updateDatesChange={this.handleDOSChange}
                                                startDate={this.getDisplayFormatDate(this.props.data.start_date)}
                                                endDate={this.getDisplayFormatDate(this.props.data.end_date)}
                                                id={"er_" + this.props.row_id}
                                                error={this.mark_field_for_validation(expected_rate.identifier.dos_range)}
                                            />
                                            {this.get_required_text_div(expected_rate.identifier.dos_range) }
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column tablet={8} computer={4}>   
                                        <Form.Field>
                                            <label>
                                                &nbsp;
                                            </label>
                                            <Button id={"apply_insurance_code_button_" + this.props.row_id} className={this.get_insurance_button_validation() ? "red" : "ui primary button"} type='button' onClick={this.on_click_apply_to_insurance_codes}>{expected_rate.apply_insurance_button_text}</Button>
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid>
                                <Grid>
                                    <Grid.Column computer={16} textAlign='left'> 
                                        <Checkbox
                                            name='audit_required'
                                            id={"include_cpt_code_" + this.props.row_id}
                                            label={expected_rate.include_cpt_code_text}
                                            checked={this.props.data.include_cpt_with_no_value}
                                            onChange={e => this.handle_include_cpt_with_no_value(e)}
                                        />
                                    </Grid.Column>
                                    {this.get_required_text_div(expected_rate.identifier.expected_rate_fees)}
                                    <Grid.Column className="expected_rate_fee" computer={16} textAlign='left'>
                                        <FeeComponent
                                            key_id={this.props.row_id}
                                            location_list={this.props.location_list}
                                            provider_list={this.props.provider_list}
                                            fee_rate_type_list={this.props.fee_rate_type_list}
                                            expected_rate_id={this.props.data.expected_rate_id}
                                            expected_rate_fees={this.props.data.expected_rate_fees}
                                            addNewFeeItem={this.add_new_fee_item}
                                            updateExpectedRatFeeDefinitionIncludeFutureProviders={this.update_expected_rate_fee_definition_include_future_providers}
                                            updateExpectedRatFeeDefinition={this.update_expected_rate_fee_definition}
                                            is_save_in_progress={this.props.is_save_in_progress}
                                            validation={this.get_validation_for_expected_rate_fees()}
                                            updateExpectedRatFeeDefinitionProcedureCode={this.update_expected_rate_fee_definition_procedure_code}
                                            copyExpectedRateFeeDefinition={this.copy_expected_rate_fee_definition}
                                            deleteExpectedRateFeeDefinitionPrompt={this.delete_expected_rate_fee_definition_prompt}
                                            proc_code_validation={this.get_validation_for_expected_rate_fee_procedure_codes()}
                                    />
                                    </Grid.Column>
                                </Grid>
                            </Accordion.Content>
                        </Accordion>
                    </div>
                </div>
             </div>
        )
    }
}
//Get user and login details from store.
const mapStateToProps = state => {
    return {
    };
};
export default connect(mapStateToProps)(DefinitionComponent);