import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import { Grid, GridColumn, Header, Table } from "semantic-ui-react";
import { currencyFormatter } from '../../../charge/charge_constant';
import * as global_constants from '../../../global_constants';
import ConfirmationComponent from '../../../shared/component/confirmation_component';
import BatchPaymentDetailRowComponent from '../../batch_payment/component/batch_payment_row_detail_component';
import { is_null_or_white_space, try_parse_currency_float, try_parse_float, get_lauch_darkley_key_value } from './../../../shared/utility';
import { adjustment_code_search } from './../action/batch_payment_action';
import * as batch_payment_constants from './../batch_payment_constants';
import { enum_posting_type, total_adjustment, total_columns, messages, enum_amount_type, enum_page_type, enum_selection_type } from './../batch_payment_constants';
import { kraken_bill_333_remove_and_replace_medicare_crossover_on_payers_page } from '../../../feature_flip_constant';

export class BatchPaymentDetailComponent extends React.Component<any, any> {
    [x: string]: any;
    _is_mounted = false;
    _deleted_charges = [];
    _existing_charge = [];
    _all_charge = [];
    _kx_modifier_id = '';

    constructor(props) {
        super(props);
        this.state = {
            posting_type: this.props.posting_type,
            // total_adjustments: total_adjustment(this.props.posting_type),
            charge_detail: this.props.data || [],
            adjustment_code: [],
            yes_no_confirm_message: "",
            show_yes_no_confirm_message: false,
            current_row_count: 0,
            yes_no_confirm_popup: false,
            excess_group_amt_confim_popup: false,
            excess_allow_amt_confim_popup: false,
            excess_pay_amt_confim_popup: false,
            excess_adjustment_amt_confim_popup: false,
            excess_group_amt_confim_message: '',
            excess_group_amt: 0,
            shortage_amount: 0,
            charge_total: {
                total_chg_amt: 0,
                owed_amount: 0,
                pay_amount: 0,
                allowed_amount: 0,
                adj_amounts: 0,
                xfer_amount: 0,
                xfer_det_amounts: ''
            },
            //show_valid_qualifire_popup:false

        }
        this.rowNodes = []
    }
    componentDidMount = () => {
        this._is_mounted = true;
        if (this.props.onRef) {
            this.props.onRef(this);
        }
        this.get_adjustment_data();
        this.update_row_total();
    }
    componentWillUnmount = () => {
        this._is_mounted = false;
        if (this.props.onRef) {
            this.props.onRef(undefined);
        }
    }
    // component will update
    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if (nextProps.data !== this.props.data) {
            // this.props.loading(true);
        }
        else if (nextProps.posting_type !== this.props.posting_type) {
            // this.props.loading(true);
        }
    }
    //Component update function
    componentDidUpdate(previousProps, previousState) {
        if (previousProps.data !== this.props.data) {
            if (this._is_mounted) {
                this.setState({
                    posting_type: this.props.posting_type, charge_detail:
                        this.props.data || []
                }, () => {
                    this.update_row_total();
                    this.props.loading(false);
                })
            }
        }
        else if (previousProps.posting_type !== this.props.posting_type) {
            if (this._is_mounted) {
                this.setState({
                    posting_type: this.props.posting_type,
                    //charge_detail: this.props.data || []
                }, () => {
                    this.update_row_total();
                    this.props.loading(false);
                })
            }
        }
    }
    get_total = (key) => {
        let total = 0;
        if (this.state.charge_detail) {
            var state_data = this.state.charge_detail.data || this.state.charge_detail;
            for (var i = 0; i < state_data.length; i++) {
                total += parseFloat(state_data[i][key]);
            }
        }
        return currencyFormatter(total);
    }
    update_data = (data) => {
        let state_data = data ? data : this.state.charge_detail;
        let total_adjustments = total_adjustment(this.props.posting_type);
        let default_adjustment_reason = this.props.posting_type != enum_posting_type.multiple_entry ? '45' : '0';
        let default_adjustment_reason_text = this.props.posting_type != enum_posting_type.multiple_entry ? '45 - Charges exceed your contracted/ legislated fee arrangement.' : 'No Adjustment Code';
        let default_adjustment_qual = this.props.posting_type != enum_posting_type.multiple_entry ? 'CO' : ' ';
        let default_adjustment_qual_text = this.props.posting_type != enum_posting_type.multiple_entry ? 'CO' : 'NA';
        for (var i = 0; i < state_data.data.length; i++) {
            state_data.data[i]['allow_amount'] = '';
            state_data.data[i]['pay_amount'] = '';
            state_data.data[i]['owed_amount'] = state_data.data[i].owed_amount;
            if (total_adjustments == 1) {
                state_data.data[i]['adj_amounts'] = [{ "sequence": 1, "amount": '' }];
                state_data.data[i]['xfer_det_amounts'] = [{ "sequence": 1, "amount": '' }];
                state_data.data[i].adj_code_reason_ids = [{ "sequence": 1, "value": default_adjustment_reason, title: default_adjustment_reason_text }];
                state_data.data[i].adj_qualifier_ids = [{ "sequence": 1, "value": default_adjustment_qual, title: default_adjustment_qual_text }];
            }
            else {
                state_data.data[i]['adj_amounts'] = [{ "sequence": 1, "amount": '' }, { "sequence": 2, "amount": '' }, { "sequence": 3, "amount": '' }, { "sequence": 4, "amount": '' }, { "sequence": 5, "amount": '' }];
                state_data.data[i]['xfer_det_amounts'] = [{ "sequence": 1, "amount": '' }, { "sequence": 2, "amount": '' }, { "sequence": 3, "amount": '' }, { "sequence": 4, "amount": '' }, { "sequence": 5, "amount": '' }];
                state_data.data[i].adj_code_reason_ids = [{ "sequence": 1, "value": default_adjustment_reason, title: default_adjustment_reason_text }, { "sequence": 2, "value": default_adjustment_reason, title: default_adjustment_reason_text }, { "sequence": 3, "value": default_adjustment_reason, title: default_adjustment_reason_text }, { "sequence": 4, "value": default_adjustment_reason, title: default_adjustment_reason_text }, { "sequence": 5, "value": default_adjustment_reason, title: default_adjustment_reason_text }];
                state_data.data[i].adj_qualifier_ids = [{ "sequence": 1, "value": default_adjustment_qual, title: default_adjustment_qual_text }, { "sequence": 2, "value": default_adjustment_qual, title: default_adjustment_qual_text }, { "sequence": 3, "value": default_adjustment_qual, title: default_adjustment_qual_text }, { "sequence": 4, "value": default_adjustment_qual, title: default_adjustment_qual_text }, { "sequence": 5, "value": default_adjustment_qual, title: default_adjustment_qual_text }];
            }
            if (total_adjustments == 2) {
                state_data.data[i]["yes_no"] = 'N';
            }
            state_data.data[i]['xfer_amount'] = '';
            state_data.data[i].error = { transfer_code_id: false };
            state_data.data[i].transfer_code_id = '3';
            state_data.data[i].transfer_code_text = '3 - Copay';
            state_data.data[i].xfer_ins_code_id = '';
            state_data.data[i].xfer_ins_code_text = '';
            state_data.data[i].from_ins_code_id = state_data.data[i].cur_ins_code;
            state_data.data[i].from_ins_code_text = state_data.data[i].ins_code + ' ' + state_data.data[i].ins_name;
            state_data.data[i].yes_no = '';
            state_data.data[i].row_index = i;
        }
        if (data) {
            return state_data;
        }
        if (this._is_mounted) {
            this.setState({
                charge_detail: state_data,
            })

        }
    }

    //Name formatting
    format_name = (data) => {
        let value = '';
        if (data) {
            if (data.carc) {
                value = `${data.carc}​​​​​​​​`;
            }
            if (data.description) {
                value = `${value}​​​​​​​​ - ${data.description}​​​​​​​​`;
            }
        }
        return value;
    };


    update_master_data = (source, default_text) => {
        var target = [{ "text": default_text,"original_text": default_text, "value": global_constants.constants.dropdown_default_info.value_string, "key": default_text }];
        var source_length = source.length;

        if (source && source_length > 0) {
            for (let y = 0; y < source_length; y++) {
                let item = source[y];
                let name = this.format_name(item);
                target.push({
                    original_text: name,
                    text: name.length > 130 ? name.substr(0, 130) + '...' : name,
                    value: item.carc,
                    key: item.carc
                })
            }
        }
        
        return target.sort((a, b) => a.text.localeCompare(b.text));
    }
    get_adjustment_data = async () => {
        await adjustment_code_search({ pageSize: global_constants.constants.PAGE_SIZE }, this.props.user_login_details.user_data.data.token_details.access_token).then(res => {
            if (res.data.data) {
                //this.update_master_data(res.data.data,'No Adjustment Code')
                if (this._is_mounted) {
                    this.setState({
                        adjustment_code: this.update_master_data(res.data.data, 'No Adjustment Code')
                    })
                }
            }
        }, err => {

        });
    }

    get_all_charge_data_group = () => {
        var excessAmt = try_parse_float(this.props.parent_state.payment_amount) - this.state.charge_total.pay_amount;
        if (excessAmt > 0) {

            var msg = `The Total Group Payment Amount is less than the Payment Amount by
            ${currencyFormatter(excessAmt)}. Do you want to post an over-payment to the last accepted charge line?`;

            if (this.props.page_type == batch_payment_constants.enum_page_type.batch_adjustment) {
                msg = `The Total Group Adjustment Amount is less than the Total Adjustment Amount by
                ${currencyFormatter(excessAmt)}. Do you want to post an over-adjustment to the last accepted charge line?`;

            }
            this.setState({
                excess_group_amt_confim_popup: true,
                excess_group_amt_confim_message: msg,
                excess_group_amt: excessAmt
            });
            return true;
        }
        return false;
    }
    on_close_confirmation_group = () => {
        this.setState({
            excess_group_amt_confim_popup: false,
            excess_group_amt_confim_message: '',
        }, () => {
            var current_row_count = this.state.current_row_count;
            if (document.getElementById('transfer_insurance' + current_row_count)) {
                document.getElementById('transfer_insurance' + current_row_count).focus();
            }
        })
    }
    on_ok_confirmation_group = () => {
        let row_items = this.rowNodes;
        var data_item: any = {};
        var count = 0;
        var update_data = false;
        for (var i = (row_items.length - 1); i >= 0; i--) {
            if (row_items[i]) {
                var item = row_items[i].get_updated_data();
                if (item.yes_no && item.yes_no.toUpperCase() == 'Y') {
                    data_item = item;
                    count = i;
                    update_data = true;
                    break;
                }
            }
        }
        if (update_data) {
            data_item.pay_amount = try_parse_float(data_item.pay_amount) + try_parse_float(this.state.excess_group_amt);

            data_item.xfer_amount = try_parse_float(data_item.owed_amount) - try_parse_float(data_item.pay_amount);

            this.setState({
                excess_group_amt_confim_popup: false,
                excess_group_amt_confim_message: '',
            }, () => {
                if (this.rowNodes[count].set_updated_data) {
                    this.rowNodes[count].set_updated_data(data_item);
                }
                var current_row_count = this.state.current_row_count;
                if (document.getElementById('transfer_insurance' + current_row_count)) {
                    document.getElementById('transfer_insurance' + current_row_count).focus();
                }
            });
        } else {
            this.setState({
                excess_group_amt_confim_popup: false,
                excess_group_amt_confim_message: '',
            }, () => {
                var current_row_count = this.state.current_row_count;
                if (document.getElementById('transfer_insurance' + current_row_count)) {
                    document.getElementById('transfer_insurance' + current_row_count).focus();
                }
            })
        }
    }

    get_all_charge_data = () => {
        let row_items = [];
        this.rowNodes.map((node) => {
            if (node) {
                var item = node.get_updated_data();
                row_items = [...row_items, { ...item }];
            }
        })
        //return [...row_items];
        return { total: this.state.charge_total, data: [...row_items] };

    }

    on_close_confirmation = () => {
        var current_row_count = this.state.current_row_count;
        if (this._is_mounted) {
            this.setState({
                yes_no_confirm_message: '',
                show_yes_no_confirm_message: false,
                current_row_count: 0
            }, () => {
                var current_ref = this.rowNodes[current_row_count];
                if (current_ref && current_ref.on_close_confirmation) {
                    current_ref.on_close_confirmation(this.state.shortage_amount, this.state.yes_no_confirm_popup);
                }
                if (document.getElementById('transfer_insurance' + current_row_count)) {
                    document.getElementById('transfer_insurance' + current_row_count).focus();
                }
            })
        }
    }
    on_ok_click = async () => {
        var current_row_count = this.state.current_row_count;
        if (this._is_mounted) {
            this.setState({
                yes_no_confirm_message: '',
                show_yes_no_confirm_message: false,
                current_row_count: 0
            }, () => {
                var current_ref = this.rowNodes[current_row_count];
                if (current_ref && current_ref.on_ok_click) {
                    current_ref.on_ok_click(this.state.shortage_amount, this.state.yes_no_confirm_popup);
                }
                if (document.getElementById('transfer_insurance' + current_row_count)) {
                    document.getElementById('transfer_insurance' + current_row_count).focus();
                }
            })
        }
    }
    show_yes_no_confirm_message_popup = (shortage, row_count, yes_no_confirm_popup) => {

        var yes_no_confirm_message = `By including this charge, the Total Group Payment Amount 
            is more than the Payment Amount by ${currencyFormatter(shortage)}. Do you want to
            correct the payment amount for this charge line?`;

        if (this.props.page_type == batch_payment_constants.enum_page_type.batch_adjustment) {
            yes_no_confirm_message = `By including this charge, the Total Group Adjustment Amount 
            is more than the Total Adjustment Amount by ${currencyFormatter(shortage)}. Do you want to
            correct the Adjustment amount for this charge line?`;
        }
        if (this._is_mounted) {
            this.setState({
                yes_no_confirm_message: yes_no_confirm_message,
                show_yes_no_confirm_message: true,
                yes_no_confirm_popup: yes_no_confirm_popup,
                current_row_count: row_count,
                shortage_amount: shortage
            }, () => {
                var selectedObject = document.getElementsByTagName('select');
                if (selectedObject && selectedObject.length) {
                    for (var i = 0; i < selectedObject.length; i++) {
                        for (var j = 0; j < selectedObject[i].length; j++) {
                            selectedObject[i].options[j].classList.add("display-none");
                        }
                    }
                }

            })
        }
    }

    update_row_total = () => {
        this.update_total(true);
    }

    update_total = (has_child = false, index = null, focus_on_element='', type='') => {
        var state_data = has_child ? this.state.charge_detail.data : this.get_all_charge_data().data;
        var charge_total = this.state.charge_total;

        if (state_data.length > 0) {
            charge_total.total_chg_amt = 0;
            charge_total.owed_amount = 0;
            charge_total.pay_amount = 0;
            charge_total.allowed_amount = 0;
            charge_total.adj_amounts = 0;
            charge_total.xfer_amount = 0;
            //charge_total.xfer_det_amounts = 0;
            for (var i = 0; i < state_data.length; i++) {
                charge_total.total_chg_amt = try_parse_float(charge_total.total_chg_amt += try_parse_float(state_data[i]['total_chg_amt']));
                charge_total.owed_amount = try_parse_float(charge_total.owed_amount += try_parse_float(state_data[i]['owed_amount']));
                charge_total.pay_amount = try_parse_float(charge_total.pay_amount += try_parse_float(state_data[i]['pay_amount']));
                charge_total.allowed_amount = try_parse_float(charge_total.allowed_amount += try_parse_float(state_data[i]['allow_amount']));
                // Initial load of the transfer value should show owed amount
                let xfer_amount = state_data[i]['xfer_amount'] != undefined && state_data[i]['xfer_amount'] != ''
                                  ? state_data[i]['xfer_amount']
                                  : state_data[i]['owed_amount'];
                charge_total.xfer_amount = try_parse_float(charge_total.xfer_amount += try_parse_float(xfer_amount));

                for (var j = 0; j < state_data[i]['adj_amounts'].length; j++) {
                    charge_total.adj_amounts = try_parse_float(charge_total.adj_amounts += try_parse_float(state_data[i]['adj_amounts'][j]['amount']));
                    // charge_total.xfer_det_amounts += try_parse_float(state_data[i]['xfer_det_amounts'][j]['amount']);
                }
            }
            this.setState({
                charge_total: charge_total
             },() => {
                if(focus_on_element == 'focus' && index!=null){
                    var active_ele:any = document.activeElement;
                    var ele_val = this.props.page_type == batch_payment_constants.enum_page_type.batch_payments ? type == 'allowed' ? 'payment_' : 'adjustment0_' : 'adjustment0_';
                    if(active_ele.id == ele_val + index){
                        active_ele.select && active_ele.select()
                    }
                }
            });

        }
    }


    table_row_validation = (save_click = false, start_count = 0) => {
        //var data:any = this.get_all_charge_data();
        // let payments = [...data.data];
        var all_valid = true;
        var all_ins_valid = true;
        var all_allow_amount_valid = true;
        var all_pay_amount_valid = true;
        var all_adj_amount_valid = true;
        var invalid_row = {}
        var invalid_row_index = 0
        //var is_valid_qualifir = true;

        for (var i = start_count; i < this.rowNodes.length; i++) {
            var node = this.rowNodes[i]
            if (node) {
                var val = node.get_updated_data();
                let payment_amount = is_null_or_white_space(val.pay_amount.toString()) ? 0 : try_parse_float(val.pay_amount),
                    isMultipleEntry = this.state.posting_type == enum_posting_type.multiple_entry,
                    isPayment = this.props.page_type == batch_payment_constants.enum_page_type.batch_payments;

                // validate adjustment qualifier
                let allQualifierOk = this.collect_data_and_validate_adjustment_qualifier(val.adj_qualifier_ids, payment_amount, i);
                if (!allQualifierOk) {
                    return false;
                }

                // Validate adjustment code
                if (isPayment && isMultipleEntry) {
                    let allAdjustmentCodeOk = this.collect_data_and_validate_adjustment_code(val.adj_code_reason_ids, i);
                    if (!allAdjustmentCodeOk) {
                        return false;
                    }
                }

                if (try_parse_float(val.xfer_amount) != 0 && (val.xfer_ins_code_id == undefined || val.xfer_ins_code_id == null || val.xfer_ins_code_id.trim().toString() == '')) {

                    if (document.getElementById('transfer_insurance' + val.row_index)) {
                        document.getElementById('transfer_insurance' + val.row_index).className = 'mar-t-0 dropdown-options-wrap req-background-inp ';
                        document.getElementById('transfer_insurance' + val.row_index).focus();
                    }
                    all_ins_valid = false;
                    invalid_row = val;
                    invalid_row_index = i;
                    break;
                }

                if (this.state.posting_type == enum_posting_type.group) {
                    if (payment_amount != 0 && (payment_amount >= 100000 || payment_amount <= -100000)) {
                        all_pay_amount_valid = false;
                        invalid_row = val;
                        invalid_row_index = i;
                        break;
                    }
                }
                else {
                    if (try_parse_float(val.allow_amount) != 0 && (try_parse_float(val.allow_amount) >= 100000 || try_parse_float(val.allow_amount) <= -100000)) {
                        all_allow_amount_valid = false;
                        invalid_row = val;
                        invalid_row_index = i;
                        break;
                    }
                    if (payment_amount != 0 && (payment_amount >= 100000 || payment_amount <= -100000)) {
                        all_pay_amount_valid = false;
                        invalid_row = val;
                        invalid_row_index = i;
                        break;
                    }
                    if (val.adj_amounts.length > 0) {
                        for (var j = 0; j < val.adj_amounts.length; j++) {
                            if (try_parse_float(val.adj_amounts[j].amount) != 0 && (try_parse_float(val.adj_amounts[j].amount) >= 100000 || try_parse_float(val.adj_amounts[j].amount) <= -100000)) {
                                all_adj_amount_valid = false;
                                invalid_row = val;
                                invalid_row_index = i;
                                break;
                            }
                        }
                        if (!all_adj_amount_valid) {
                            break;
                        }
                    }
                }
            }
        }

        if (!all_ins_valid) {
            toastr.error('', messages.required_field);
            all_valid = false;
        }
        if (!all_allow_amount_valid) {
            all_valid = false;
            if (this._is_mounted) {
                this.setState({
                    excess_allow_amt_confim_popup: true,
                    current_row_count: invalid_row_index
                })
            }
        }
        if (!all_pay_amount_valid) {
            all_valid = false;
            if (this._is_mounted) {
                this.setState({
                    excess_pay_amt_confim_popup: true,
                    current_row_count: invalid_row_index
                })
            }
        }
        if (!all_adj_amount_valid) {
            all_valid = false;
            if (this._is_mounted) {
                this.setState({
                    excess_adjustment_amt_confim_popup: true,
                    current_row_count: invalid_row_index
                })
            }
        }
        if (!all_valid) {
            return false;
        }

        if (this.state.posting_type == enum_posting_type.group) {
            if (this.get_all_charge_data_group()) {
                return false;
            }
        }
        if (this.props.page_type == batch_payment_constants.enum_page_type.batch_payments) {
            if (this.state.posting_type != enum_posting_type.group && try_parse_float(this.state.charge_total.pay_amount) != try_parse_float(this.props.parent_state.payment_amount)) {
                toastr.error('', messages.less_payment_amount);
                return false;
            }
        }
        if (!save_click) {
            if (this.props.on_save) {
                this.props.on_save(true);
            }
        }
        return true;
    }

    collect_data_and_validate_adjustment_qualifier = (adj_qualifier_ids, payment_amount, index) => {
        let allQualifierOk = true,
            isMultipleEntry = this.state.posting_type == enum_posting_type.multiple_entry,
            isBatchAdjustment = this.props.page_type == enum_page_type.batch_adjustment,
            isGroup = this.state.posting_type == enum_posting_type.group;

        for (let j = 0; j < adj_qualifier_ids.length; j++) {
            // Lets get the adjustment qualifier control
            let adjustment_qualifier_control = document.getElementById(`${enum_selection_type.adjustment_qual}${j}${index}`) as HTMLSelectElement;

            if (!adjustment_qualifier_control) {
                continue;
            }

            let selectedAdjustmentQualifier = adjustment_qualifier_control.options[adjustment_qualifier_control.selectedIndex].text,
                // Values from get_updated_data are not being updated when zero/null/empty is set
                adjustment_control = document.getElementById(`${enum_selection_type.adjustment_input}${j}_${index}`) as HTMLInputElement,
                payment_adjustment_control = document.getElementById(`${enum_selection_type.payment_adjust_input}_${index}`) as HTMLInputElement,
                payment_adjust_control = document.getElementById(`${enum_selection_type.payment_adjust_input}${index}`) as HTMLInputElement,
                adjustment_code_control = document.getElementById(`${enum_selection_type.adjustment_code}${j}${index}`) as HTMLInputElement;

            if (!adjustment_control && !payment_adjustment_control && !payment_adjust_control) {
                continue;
            }

            // Page not following control id naming conventions for adjustment page and posting type = group.
            let rawAdjustmentAmount = '';
            if (adjustment_control) {
                rawAdjustmentAmount = adjustment_control.value;
            }
            if (payment_adjustment_control) {
                rawAdjustmentAmount = payment_adjustment_control.value;
            }
            if (isBatchAdjustment && isGroup && payment_adjust_control) {
                rawAdjustmentAmount = payment_adjust_control.value;
            }

            // clean $ symbol
            if (rawAdjustmentAmount.indexOf('$') > -1) {
                rawAdjustmentAmount = rawAdjustmentAmount.replace('$', '');
            }

            let adjustment_amount = is_null_or_white_space(rawAdjustmentAmount) ? '' : try_parse_float(rawAdjustmentAmount),
                transfer_detail_control = document.getElementById(`${enum_selection_type.transfer_input}${j}_${index}`) as HTMLInputElement;

            if ((!transfer_detail_control || !adjustment_code_control) && isMultipleEntry) {
                continue;
            }

            let rawTransferDetailAmount = transfer_detail_control ? transfer_detail_control.value : '';
            if (rawTransferDetailAmount.indexOf('$') > -1) {
                rawTransferDetailAmount = rawTransferDetailAmount.replace('$', '');
            }            
            
            let transfer_detail_amount = is_null_or_white_space(rawTransferDetailAmount) ? '' : try_parse_float(rawTransferDetailAmount),
                isValidAdjustmentQualifier = this.validate_adjustment_qualifier(
                    selectedAdjustmentQualifier,
                    payment_amount,
                    adjustment_amount,
                    transfer_detail_amount
                );

            if (!isValidAdjustmentQualifier) {
                adjustment_qualifier_control.className = 'mar-t-0 dropdown-options-wrap req-background-inp ';
                allQualifierOk = false;
                break;
            }
            
            adjustment_qualifier_control.className = 'mar-t-0 ';
        }

        return allQualifierOk;
    };

    validate_adjustment_qualifier = (
        adj_qual,
        payment_amount,
        adjustment_amount,
        transfer_detail
    ) => {
        let errorMessage = '',
            isAdjustment = this.props.page_type == batch_payment_constants.enum_page_type.batch_adjustment,
            isPayment = this.props.page_type == batch_payment_constants.enum_page_type.batch_payments,
            isMultipleEntry = this.state.posting_type == enum_posting_type.multiple_entry,
            isNA = adj_qual == 'NA',
            isPR = adj_qual == 'PR';

        // Generate error if Adjustment Qualifier is set to PR when trying to Save.
        if (adjustment_amount !== 0 && isPR) {
            toastr.error('', messages.qualifier_pr_forbidden_adjustment);
            return false;
        }

        if (isAdjustment) {
            // If Adjustment field is 0.00, default Adjustment Qualifier to NA.
            if (adjustment_amount === 0 && !isNA) {
                errorMessage = messages.qualifier_na_required_adjustment;
            }
        }

        if (isPayment && !isMultipleEntry) {
            // If Payment and adjustment fields are 0.00, default Adjustment Qualifier to NA.
            if (payment_amount === 0 && adjustment_amount === 0 && !isNA) {
                errorMessage = messages.qualifier_na_required_payment;
            }
        }

        if (isPayment && isMultipleEntry) {
            let isTransferDetailFilled = try_parse_float(transfer_detail) !== 0;
            // When Transfer Detail is filled in, the only valid Adj Qualifiers are PR and NA.
            if (isTransferDetailFilled && (!isPR && !isNA)) {
                errorMessage = messages.qualifier_pr_na_transfer_detail_required;
            }
        }

        if (!is_null_or_white_space(errorMessage)) {
            toastr.error('', errorMessage);
            return false;
        }

        return true;
    };

    collect_data_and_validate_adjustment_code = (adj_code_reason_ids, index) => {
        let adjustmentCodeOk = true;

        for (let j = 0; j < adj_code_reason_ids.length; j++) {
            // Lets get the adjustment code control
            let adjustment_code_control = document.getElementById(`${enum_selection_type.adjustment_code}${j}${index}`) as HTMLInputElement;

            if (!adjustment_code_control) {
                continue;
            }

            let selectedAdjustmentCode = adjustment_code_control ? adjustment_code_control.value : '',
                adjustment_control = document.getElementById(`${enum_selection_type.adjustment_input}${j}_${index}`) as HTMLInputElement;

            if (!adjustment_control) {
                continue;
            }

            let adjustment_amount = try_parse_currency_float(adjustment_control.value),
                transfer_detail_control = document.getElementById(`${enum_selection_type.transfer_input}${j}_${index}`) as HTMLInputElement;

            if ((!transfer_detail_control || !adjustment_code_control)) {
                continue;
            }
            
            let transfer_detail_amount = try_parse_currency_float(transfer_detail_control.value),
                isValidAdjustmentCode = this.validate_adjustment_code(
                    adjustment_amount,
                    transfer_detail_amount,
                    selectedAdjustmentCode
                );

            if (!isValidAdjustmentCode) {
                adjustment_code_control.className = 'mar-t-0 select_advance_reason req-background-inp ';
                adjustmentCodeOk = false;
                break;
            }

            adjustment_code_control.className = 'mar-t-0 select_advance_reason ';
        }

        return adjustmentCodeOk;
    };

    validate_adjustment_code = (adjustment_amount, transfer_detail, adjustment_code) => {
        let errorMessage = '',
            isPayment = this.props.page_type == batch_payment_constants.enum_page_type.batch_payments,
            isMultipleEntry = this.state.posting_type == enum_posting_type.multiple_entry,
            isTransferDetailFilled = try_parse_float(transfer_detail) !== 0;

        if (isPayment && isMultipleEntry) {
            // If an Adjustment or Transfer Detail amount is entered, Adjustment Code is required
            if ((adjustment_amount !== 0 || isTransferDetailFilled) && adjustment_code == '0') {
                errorMessage = messages.adjustment_code_required;
            }
        }

        if (!is_null_or_white_space(errorMessage)) {
            toastr.error('', errorMessage);
            return false;
        }

        return true;
    };

    //re_calculate_qualifier = () => {
    //    this.setState({
    //        show_valid_qualifire_popup: false
    //    });

    //    for (var i = 0; i < this.rowNodes.length; i++) {
    //        var node = this.rowNodes[i]
    //        if (node) {
    //            var data = node.get_updated_data();
    //            //data = node.qualifiers_validation(data,0);
    //            node.set_updated_data(data);
    //        }
    //    }
    //}
    //on_close_qualifire_validation = () => {
    //    this.setState({
    //        show_valid_qualifire_popup: false
    //    })
    //}


    on_close_exccess_amount_confirmation = (type) => {
        var invalid_row_count = this.state.current_row_count;
        if (type === enum_amount_type.allowed) {
            if (this._is_mounted) {
                this.setState({
                    excess_allow_amt_confim_popup: false,
                    current_row_count: 0
                }, () => {
                    var current_ref = this.rowNodes[invalid_row_count];
                    if (current_ref) {
                        var data_item = current_ref.get_updated_data();
                        if (current_ref.calculate_row_data) {
                            data_item = current_ref.calculate_row_data(data_item, 0, true);
                        }
                        if (current_ref.set_updated_data) {
                            current_ref.set_updated_data(data_item);
                        }
                    }
                    if (document.getElementById('transfer_insurance' + invalid_row_count)) {
                        document.getElementById('transfer_insurance' + invalid_row_count).focus();
                    }
                })
            }
        } else if (type === enum_amount_type.payment) {
            if (this._is_mounted) {
                this.setState({
                    excess_pay_amt_confim_popup: false,
                    current_row_count: 0
                }, () => {
                    var current_ref = this.rowNodes[invalid_row_count];
                    if (current_ref) {
                        var data_item = current_ref.get_updated_data();
                        if (current_ref.calculate_row_data) {
                            data_item = current_ref.calculate_row_data(data_item, 0, true);
                        }
                        if (current_ref.set_updated_data) {
                            current_ref.set_updated_data(data_item);
                        }
                    }
                    if (document.getElementById('transfer_insurance' + invalid_row_count)) {
                        document.getElementById('transfer_insurance' + invalid_row_count).focus();
                    }
                })
            }
        } else if (type === enum_amount_type.adjustment) {
            if (this._is_mounted) {
                this.setState({
                    excess_adjustment_amt_confim_popup: false,
                    current_row_count: 0
                }, () => {
                    var current_ref = this.rowNodes[invalid_row_count];
                    if (current_ref) {
                        var data_item = current_ref.get_updated_data();
                        if (current_ref.calculate_row_data) {
                            var index = 0;
                            for (var j = 0; j < data_item.adj_amounts.length; j++) {
                                if (try_parse_float(data_item.adj_amounts[j].amount) != 0 && (try_parse_float(data_item.adj_amounts[j].amount) >= 100000 || try_parse_float(data_item.adj_amounts[j].amount) <= -100000)) {
                                    index = j;
                                    break;
                                }
                            }
                            data_item = current_ref.calculate_row_data(data_item, index, false);
                        }
                        if (current_ref.set_updated_data) {
                            current_ref.set_updated_data(data_item);
                        }
                    }
                    if (document.getElementById('transfer_insurance' + invalid_row_count)) {
                        document.getElementById('transfer_insurance' + invalid_row_count).focus();
                    }
                })
            }
        }

    }

    on_ok_exccess_amount_confirmation = (type) => {
        var invalid_row_count = this.state.current_row_count;
        if (type === enum_amount_type.allowed) {
            if (this._is_mounted) {
                this.setState({
                    excess_allow_amt_confim_popup: false,
                    current_row_count: 0
                }, () => {
                    this.table_row_validation(false, invalid_row_count + 1);
                    if (document.getElementById('transfer_insurance' + invalid_row_count)) {
                        document.getElementById('transfer_insurance' + invalid_row_count).focus();
                    }
                })
            }
        } else if (type === enum_amount_type.payment) {
            if (this._is_mounted) {
                this.setState({
                    excess_pay_amt_confim_popup: false,
                    current_row_count: 0
                }, () => {
                    this.table_row_validation(false, invalid_row_count + 1);
                    if (document.getElementById('transfer_insurance' + invalid_row_count)) {
                        document.getElementById('transfer_insurance' + invalid_row_count).focus();
                    }
                })
            }
        } else if (type === enum_amount_type.adjustment) {
            if (this._is_mounted) {
                this.setState({
                    excess_adjustment_amt_confim_popup: false,
                    current_row_count: 0
                }, () => {
                    this.table_row_validation(false, invalid_row_count + 1);
                    if (document.getElementById('transfer_insurance' + invalid_row_count)) {
                        document.getElementById('transfer_insurance' + invalid_row_count).focus();
                    }
                })
            }
        }
    }

    update_below_sibling_dropdown = (prop_name, prop_text, current_data_item, current_row_index, multiple_index, multi_row) => {
        let row_items = this.rowNodes;
        let row_index = current_row_index;
        let multi_lavle_index = multiple_index;
        if (this.props.posting_type != enum_posting_type.multiple_entry) {
            row_index = row_index + 1;
        }
        for (var i = row_index; i < row_items.length; i++) {
            var current_ref = row_items[i];
            if (current_ref) {
                var data_item = current_ref.get_updated_data();
                if (multi_row) {
                    if (this.props.posting_type == enum_posting_type.multiple_entry) {
                        if (i == row_index) {
                            multi_lavle_index = multi_lavle_index + 1;
                        } else {
                            multi_lavle_index = 0;
                        }
                        for (var j = multi_lavle_index; j < data_item[prop_name].length; j++) {
                            data_item[prop_name][j].value = current_data_item[prop_name][multiple_index].value;
                            data_item[prop_name][j].title = current_data_item[prop_name][multiple_index].title;
                        }
                    } else {
                        data_item[prop_name][multiple_index].value = current_data_item[prop_name][multiple_index].value;
                        data_item[prop_name][multiple_index].title = current_data_item[prop_name][multiple_index].title;
                    }
                } else {
                    data_item[prop_name] = current_data_item[prop_name];
                    data_item[prop_text] = current_data_item[prop_text];
                }

                //if (current_ref.qualifiers_validation) {
                //    data_item = current_ref.qualifiers_validation(data_item, multiple_index);
                //}
                if (current_ref.set_updated_data) {
                    current_ref.set_updated_data(data_item);
                }
            }
        }
    }

    update_sibling_crossover_txt = (crossover: string) =>
    {
        if (!this._is_mounted) return;
        let charges = this.state.charge_detail;
        charges.data.map(item => item.crossover = crossover);
        this.setState({ charge_detail: charges });
    }

    update_crossover_txt = (crossover: string, position:number) =>
    {
        if (!this._is_mounted) return;
        let charges = this.state.charge_detail;
        charges.data.map((item, index) =>
        {
            if (index == position)item.crossover = crossover
        });
        this.setState({ charge_detail: charges });
    }

    render() {
        let isPayments = this.props.page_type == enum_page_type.batch_payments,
            isGroup = this.state.posting_type == enum_posting_type.group,
            isNotMultipleEntry = this.state.posting_type != enum_posting_type.multiple_entry;
        
        return (
            <React.Fragment>

                <Grid>
                    <GridColumn computer={16} className="padd-r-0">
                        <Grid id="applicationPageTitleSticky">
                            <Grid.Column computer={16} className="padd-r-0">
                                <Header className="heading_detail">
                                    Transaction Detail
                         </Header>
                                <br />

                                <div className='customtable'>
                                    <div className='table-responsive' id='batch_payment_details_table'>
                                        <Table className="batch_payment_table">
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell style={{ width: '87px', minWidth: '87px' }}>Charge ID</Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '86px', minWidth: '86px' }}>Date of Service</Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '50px', minWidth: '50px' }}>CPT Code</Table.HeaderCell>
                                                    {this.props.page_type == enum_page_type.batch_payments ? <Table.HeaderCell className="text-right" style={{ width: '50px', minWidth: '50px' }}>Units</Table.HeaderCell> : null}
                                                    <Table.HeaderCell className="text-right" style={{ width: '50px', minWidth: '50px' }}>Charges</Table.HeaderCell>
                                                    <Table.HeaderCell className="text-right" style={{ width: '50px', minWidth: '50px' }}>Balance</Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '143px', minWidth: '143px' }}>Paid By Insurance</Table.HeaderCell>
                                                    {
                                                        (this.state.posting_type == enum_posting_type.group) &&
                                                        <Table.HeaderCell style={{ width: '50px', minWidth: '50px' }}>Yes/No</Table.HeaderCell>
                                                    }
                                                    {
                                                        this.state.posting_type == enum_posting_type.group ?
                                                            <Table.HeaderCell className="text-right" style={{ width: '100px', minWidth: '100px' }}>{this.props.page_type == enum_page_type.batch_payments ? 'Payment/Adjustment' : 'Adjustment'}</Table.HeaderCell>
                                                            : null
                                                    }
                                                    {this.state.posting_type != enum_posting_type.group ?
                                                        <Table.HeaderCell className="text-right" style={{ width: '100px', minWidth: '100px' }}>Allowed</Table.HeaderCell> : null}
                                                    {
                                                        this.props.page_type == enum_page_type.batch_payments && this.state.posting_type != enum_posting_type.group &&
                                                        <Table.HeaderCell className="text-right" style={{ width: '100px', minWidth: '100px' }}>Payment</Table.HeaderCell>}
                                                    {
                                                        this.state.posting_type != enum_posting_type.group ?
                                                            <Table.HeaderCell className="text-right" style={{ width: '100px', minWidth: '100px' }}>Adjustment</Table.HeaderCell> : null}
                                                    <Table.HeaderCell className="text-right" style={{ width: '50px', minWidth: '50px' }}>Transfer</Table.HeaderCell>
                                                    {
                                                        this.state.posting_type == enum_posting_type.multiple_entry ?
                                                            <Table.HeaderCell className="text-right" style={{ width: '138px', minWidth: '138px' }}>Transfer Detail</Table.HeaderCell> : null
                                                    }
                                                    <Table.HeaderCell style={{ width: '320px', minWidth: '320px' }}>Transfer to Payer</Table.HeaderCell>
                                                    {
                                                        this.props.features.krakenBill333RemoveAndReplaceMedicareCrossoverOnPayersPage?
                                                        <Table.HeaderCell style={{ width: '50px', minWidth: '50px' }}>Crossover</Table.HeaderCell>
                                                        :null
                                                    }
                                                    
                                                    {
                                                        // Batch Payments - Group: Suppress Adjustment Qualifier and Adjustment Code fields
                                                        isPayments && isGroup
                                                        ? null
                                                        : <Table.HeaderCell style={{ width: '97px', minWidth: '97px' }}>Adjustment Qualifier</Table.HeaderCell>}
                                                    {   
                                                        isNotMultipleEntry
                                                        // Batch Payments - Group: Suppress Adjustment Qualifier and Adjustment Code fields
                                                        ? isPayments && isGroup ? null : <Table.HeaderCell style={{ width: '141px', minWidth: '141px' }}>Adjustment Code</Table.HeaderCell>
                                                        : isPayments && isGroup ? null : <Table.HeaderCell style={{ minWidth: '160px' }} >Adjustment Code</Table.HeaderCell>
                                                    }
                                                    <Table.HeaderCell style={{ width: '141px', minWidth: '141px' }}>Billing Provider</Table.HeaderCell>

                                                    {
                                                        this.state.posting_type != enum_posting_type.multiple_entry ?
                                                            <Table.HeaderCell style={{ width:'120px', minWidth: '120px' }} >Transaction Code</Table.HeaderCell> : null
                                                    }

                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {
                                                    this.state.charge_detail.data && this.state.charge_detail.data.length > 0 ?
                                                        this.state.charge_detail.data.map((item, index) => {
                                                            return (
                                                                <BatchPaymentDetailRowComponent
                                                                    key={index}
                                                                    row_count={index}
                                                                    data={item}
                                                                    adjustment_data={this.state.adjustment_code}
                                                                    parent_state={this.props.parent_state}
                                                                    show_yes_no_confirm_message_popup={this.show_yes_no_confirm_message_popup}
                                                                    isClear={this.state.clear_charge}
                                                                    posting_type={this.state.posting_type}
                                                                    update_child_force={this.state.update_child_force}
                                                                    update_total={this.update_total}
                                                                    charge_total={this.state.charge_total}
                                                                    onRef={ref => (this.rowNodes[index] = ref)}
                                                                    total_count={this.state.charge_detail ? this.state.charge_detail.data ? this.state.charge_detail.data.length : 0 : 0}
                                                                    page_type={this.props.page_type}
                                                                    update_below_sibling_dropdown={this.update_below_sibling_dropdown}
                                                                    update_sibling_crossover_txt={this.update_sibling_crossover_txt}
                                                                    update_crossover_txt={this.update_crossover_txt}
                                                                />
                                                            );
                                                        }) : <Table.Row><Table.Cell className="text-center" colSpan={total_columns(this.state.posting_type, this.props.features.krakenBill333RemoveAndReplaceMedicareCrossoverOnPayersPage)} >No Record Available</Table.Cell></Table.Row>
                                                }
                                            </Table.Body>
                                            {this.state.charge_detail.data && this.state.charge_detail.data.length > 0 &&
                                                <Table.Footer>
                                                    <Table.Row className="footer">
                                                        <Table.Cell style={{ width: '87px', minWidth: '87px' }}>Total</Table.Cell>
                                                        <Table.Cell style={{ width: '86px', minWidth: '86px' }}></Table.Cell>
                                                        <Table.Cell style={{ width: '50px', minWidth: '50px' }}></Table.Cell>
                                                        {
                                                            this.props.page_type == enum_page_type.batch_payments
                                                                ? <Table.Cell style={{ width: '50px', minWidth: '50px' }}></Table.Cell>
                                                                : null
                                                        }
                                                        <Table.Cell className="text-right" style={{ width: '50px', minWidth: '50px' }}>{currencyFormatter(this.state.charge_total.total_chg_amt)}</Table.Cell>
                                                        <Table.Cell className="text-right" style={{ width: '50px', minWidth: '50px' }}>{currencyFormatter(this.state.charge_total.owed_amount)}</Table.Cell>
                                                        <Table.Cell style={{ width: '143px', minWidth: '143px' }}></Table.Cell>
                                                        {
                                                            this.state.posting_type == enum_posting_type.group
                                                                ? <Table.Cell style={{ width: '50px', minWidth: '50px' }}></Table.Cell>
                                                                : null
                                                        }
                                                        {
                                                            //pay_amount
                                                            this.props.page_type == enum_page_type.batch_payments && this.state.posting_type == enum_posting_type.group
                                                                ? <Table.Cell className="text-right" style={{ width: '100px', minWidth: '100px' }}>{currencyFormatter(this.state.charge_total.pay_amount)}</Table.Cell>
                                                                : null
                                                        }
                                                        {
                                                            //allow_amount
                                                            this.state.posting_type != enum_posting_type.group
                                                                ? <Table.Cell className="text-right" style={{ width: '100px', minWidth: '100px' }}>{currencyFormatter(this.state.charge_total.allowed_amount)}</Table.Cell>
                                                                : null
                                                        }
                                                        {
                                                            //pay_amount
                                                            this.props.page_type == enum_page_type.batch_payments && this.state.posting_type != enum_posting_type.group
                                                                ? <Table.Cell className="text-right" style={{ width: '100px', minWidth: '100px' }}>{currencyFormatter(this.state.charge_total.pay_amount)}</Table.Cell>
                                                                : null
                                                        }
                                                        {
                                                            //adj_amounts
                                                            this.state.posting_type != enum_posting_type.group
                                                                ? <Table.Cell className="text-right" style={{ width: '100px', minWidth: '100px' }}>{currencyFormatter(this.state.charge_total.adj_amounts)}</Table.Cell>
                                                                : this.props.page_type == enum_page_type.batch_adjustment
                                                                    ? <Table.Cell ></Table.Cell>
                                                                    : null
                                                        }
                                                        {
                                                            //xfer_amount
                                                            <Table.Cell className="text-right" style={{ width: '50px', minWidth: '50px' }}>{currencyFormatter(this.state.charge_total.xfer_amount)}</Table.Cell>
                                                        }
                                                        {
                                                            this.state.posting_type == enum_posting_type.multiple_entry
                                                                ? <Table.Cell style={{ width: '138px', minWidth: '138px' }}></Table.Cell>
                                                                : null
                                                        }
                                                        <Table.Cell style={{ width: '98px', minWidth: '98px' }}></Table.Cell>
                                                        <Table.Cell style={{ width: '97px', minWidth: '97px' }}></Table.Cell>
                                                        {
                                                            this.state.posting_type != enum_posting_type.multiple_entry
                                                                ? <Table.Cell style={{ width: '141px', minWidth: '141px' }}></Table.Cell>
                                                                : <Table.Cell ></Table.Cell>
                                                        }
                                                        <Table.Cell style={{ width: '141px', minWidth: '141px' }}></Table.Cell>
                                                        {
                                                            this.state.posting_type != enum_posting_type.multiple_entry
                                                                ? <Table.Cell ></Table.Cell>
                                                                : null
                                                        }
                                                    </Table.Row>
                                                </Table.Footer>
                                            }
                                        </Table>
                                    </div>
                                </div>
                                {this.state.show_yes_no_confirm_message &&
                                    <ConfirmationComponent message={this.state.yes_no_confirm_message}
                                        title='Confirm' show_modal={this.state.show_yes_no_confirm_message}
                                        onCloseModal={this.on_close_confirmation}
                                        save_button_text='Ok'
                                        on_force_save_click={(e) => { this.on_ok_click() }}
                                        custom_id={"yes_no_close"}
                                    />
                                }

                                {this.state.excess_group_amt_confim_popup &&
                                    <ConfirmationComponent message={this.state.excess_group_amt_confim_message}
                                        title='Confirm' show_modal={this.state.excess_group_amt_confim_popup}
                                        onCloseModal={this.on_close_confirmation_group}
                                        save_button_text='Ok'
                                        on_force_save_click={(e) => { this.on_ok_confirmation_group() }}
                                        custom_id={"exess_group_close"} />
                                }

                                {this.state.excess_allow_amt_confim_popup &&
                                    <ConfirmationComponent message={'The allow/payment/adjustment amount entered is $100,000.00 or more.  Click OK to save and continue or click CANCEL to change the allow/payment/adjustment amount'}
                                        title='Confirm' show_modal={this.state.excess_allow_amt_confim_popup}
                                        onCloseModal={() => this.on_close_exccess_amount_confirmation('allowed')}
                                        save_button_text='Ok'
                                        on_force_save_click={(e) => { this.on_ok_exccess_amount_confirmation("allowed") }}
                                        custom_id={"excess_allow_amt_confim_popup"} />
                                }

                                {this.state.excess_pay_amt_confim_popup &&
                                    <ConfirmationComponent message={'The allow/payment/adjustment amount entered is $100,000.00 or more.  Click OK to save and continue or click CANCEL to change the allow/payment/adjustment amount'}
                                        title='Confirm' show_modal={this.state.excess_pay_amt_confim_popup}
                                        onCloseModal={() => this.on_close_exccess_amount_confirmation('payment')}
                                        save_button_text='Ok'
                                        on_force_save_click={(e) => { this.on_ok_exccess_amount_confirmation("payment") }}
                                        custom_id={"excess_pay_amt_confim_popup"} />
                                }
                                {this.state.excess_adjustment_amt_confim_popup &&
                                    <ConfirmationComponent message={'The allow/payment/adjustment amount entered is $100,000.00 or more.  Click OK to save and continue or click CANCEL to change the allow/payment/adjustment amount'}
                                        title='Confirm' show_modal={this.state.excess_adjustment_amt_confim_popup}
                                        onCloseModal={() => this.on_close_exccess_amount_confirmation("adjustment")}
                                        save_button_text='Ok'
                                        on_force_save_click={(e) => { this.on_ok_exccess_amount_confirmation("adjustment") }}
                                        custom_id={"excess_adjustment_amt_confim_popup"} />
                                }

                                {
                                    //this.state.show_valid_qualifire_popup &&
                                    //<ConfirmationComponent message={'If the CO qualifier is selected, a Payment or Adjustment must be entered.If the PR qualifier is used, an Adjustment must not be entered.'}
                                    //    title='Confirm' show_modal={this.state.show_valid_qualifire_popup}
                                    //onCloseModal={() => this.on_close_qualifire_validation()}
                                    //    save_button_text='Re-calculate'
                                    //on_force_save_click={(e) => { this.re_calculate_qualifier() }}
                                    //custom_id={"in_valid_qualifire_popup"} />
                                }

                                <br></br>
                            </Grid.Column>
                        </Grid>
                    </GridColumn>



                </Grid>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        features: {
            krakenBill333RemoveAndReplaceMedicareCrossoverOnPayersPage: get_lauch_darkley_key_value(state.launch_darkly, kraken_bill_333_remove_and_replace_medicare_crossover_on_payers_page)
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BatchPaymentDetailComponent));