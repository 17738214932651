const cloneDeep = require('lodash/clonedeep')
import * as global_constants from '../../../global_constants';
import { RequestConfig } from '../../../shared/network/config';
import { Request } from '../../../shared/network/request';
import * as api_request from '../../../shared/network/request_extensions';
import * as constant from '../constants';
import { log_error } from '../../../shared/action/shared_action';
import { create_endpoint, custom_date_format } from '../../../shared/utility';
import { date_format } from '../../../global_constants';
import axios from 'axios';

export const get_search_data = (token:string, api:string):any => 
    api_request.get_request(`${global_constants.base_end_points}${api}`, token);

export const add_data = (token:string, api:string, data_object:any):any => 
    api_request.post_config_request(`${global_constants.base_end_points}${api}`, token, cloneDeep({ data: { data: data_object } }));

export const get_data = (token:string, api:string):any => 
    api_request.get_request(`${global_constants.base_end_points}${api}`, token);


export const update_data = (token: string, api: string, data_object: any): any => 
    api_request.put_config_request(`${global_constants.base_end_points}${api}`, token, cloneDeep({ data: { data: data_object } }));


//********* Claim Adjustment code apis**********

export const claim_adj_code_search = (token, params) => {
    const url = `${global_constants.base_end_points}${constant.claim_adjust_code_company.api.claim_adjcode_comp_search}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };
    let queryString = Object.keys(params)
        .map(key => key + '=' + params[key])
        .join('&');

    let request_config = new RequestConfig({
        url: url + '?' + queryString,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

export const claim_adj_code_advanced_search = (token, api, data_object) => {
    const url = `${global_constants.base_end_points}/update`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get',
        data: { data: data_object, api: api }
    });

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

export const calim_adj_payer_advanced_search = (token, api, data_object) => {
    const url = `${global_constants.base_end_points}/claim_adj_code_comp_payer_ov/search`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get',
        data: { data: data_object, api: api }
    });

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

// search payer by advanced control
export const advanced_search_payer = (token, api_url, params) => {
    const url = `${global_constants.base_end_points}${api_url}`;
    let request = new Request();
    let headers = {
        Authorization: 'Bearer ' + token,
        silversurfer: JSON.stringify(params)
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get',
        params: {
            ...params
        }
    });
    return request.make(request_config);
};

// search payer by advanced control
export const adj_payer_advanced_search = (params, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.insurance.search_payer}`;
    let request = new Request();
    let headers = {
        Authorization: 'Bearer ' + token,
        silversurfer: JSON.stringify(params)
    };
    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get',
        params: {
            ...params
        }
    });
    return request.make(request_config);
};

export const get_payer_errors = (params, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payers.errors}`;
    return api_request.get_config_request(url, token, null);
};

	export const set_payer_error_exclusions = async (token, adjustCode, ruleLayer, ids) => {
		const url = `${global_constants.base_end_points}${global_constants.end_points.payers.error_exclusions}`;
		let request = new Request();
		let headers = {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + token
		};
		let request_config = new RequestConfig({
			url: url,
			headers: headers,
			method: 'put',
			data: {
				payerIds: ids,
				adjustCode: adjustCode,
				ruleLayer: ruleLayer
			}
		});
		return await request.make(request_config);
	};

export const referral_source_type_advanced_search = (token, api_url, params) => {
    const url = `${global_constants.base_end_points}${api_url}`;
    let request = new Request();
    let headers = {
        Authorization: 'Bearer ' + token,
        silversurfer: JSON.stringify(params)
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get',
        params: {
            ...params
        }
    });
    return request.make(request_config);
};

//get image data based on image index id
export const open_image = (token, image_api_url) => {
    // API call to log error in database
    const url = `${global_constants.base_end_points}${image_api_url}`;
    let request = new Request();
    let headers = {
        'Content-Type': 'application/image',
        Authorization: 'Bearer ' + token
    };
    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get',
        responseType: 'arraybuffer',
        transformResponse: function (data, headersGetter) {
            return { data: data, headers: headersGetter };
        }
    });
    return request.make(request_config);
};

//get company details based on company id
export const get_company_details = (token, company_id) => {
    const url = `${global_constants.base_end_points}/company/${company_id}`;

    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });
    return request.make(request_config);
};

//get company dto based on company id
export const get_companyDto_by_id = (token, company_id) => {
	const url = `${global_constants.base_end_points}/company/get_company_dto/${company_id}`;

	let request = new Request();
	let headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + token
	};

	let request_config = new RequestConfig({
		url: url,
		headers: headers,
		method: 'get'
	});
	return request.make(request_config);
};

// get provider billing list
export const get_provider_billings = (token, provider_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.provider.provider}/${provider_id}${
        global_constants.end_points.provider.provider_billing
        }`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let requestConfig = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(requestConfig);
};

export const add_location_claim_hold = (token, location_id, claim_hold_list) => {
    //const url_0 = `${global_constants.base_end_points}${global_constants.end_points.provider.provider}/${location_id}${global_constants.end_points.provider.provider_claims}`;
    var url = `${global_constants.base_end_points}/locations/${location_id}/claim_hold`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let requestConfig = new RequestConfig({
        url: url,
        headers: headers,
        method: 'put',
        data: claim_hold_list
    });

    return request.make(requestConfig);
};

export const get_location_claim_hold = (token, location_id) => {
    //const url_0 = `${global_constants.base_end_points}${global_constants.end_points.provider.provider}/${location_id}${global_constants.end_points.provider.provider_claims}`;
    var url = `${global_constants.base_end_points}/locations/${location_id}/claim_hold`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let requestConfig = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(requestConfig);
};

// get provider claims list
export const get_provider_claim_hold = (token, provider_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.provider.provider}/${provider_id}${
        global_constants.end_points.provider.provider_claims
        }`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let requestConfig = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(requestConfig);
};

// add provider claim Hold
export const add_provider_claim_hold = (token, provider_id, billing_list) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.provider.provider}/${provider_id}${
        global_constants.end_points.provider.provider_claims
        }`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let requestConfig = new RequestConfig({
        url: url,
        headers: headers,
        method: 'post',
        data: billing_list
    });

    return request.make(requestConfig);
};

// update provider claim hold
export const update_provider_claim_hold = (token, provider_id, billing_list) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.provider.provider}/${provider_id}${
        global_constants.end_points.provider.provider_claims
        }`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let requestConfig = new RequestConfig({
        url: url,
        headers: headers,
        method: 'put',
        data: billing_list
    });

    return request.make(requestConfig);
};

// get provider claims list
export const get_provider_pending_reason = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.provider.provider}${
        global_constants.end_points.provider.provider_pending_reason
        }`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let requestConfig = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(requestConfig);
};

// get provider billing list
export const add_provider_billings = (token, provider_id, billing_list) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.provider.provider}/${provider_id}${
        global_constants.end_points.provider.provider_billing
        }`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let requestConfig = new RequestConfig({
        url: url,
        headers: headers,
        method: 'post',
        data: billing_list
    });

    return request.make(requestConfig);
};

// update provider billing list
export const update_provider_billings = (token, provider_id, billing_list) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.provider.provider}/${provider_id}${
        global_constants.end_points.provider.provider_billing
        }`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let requestConfig = new RequestConfig({
        url: url,
        headers: headers,
        method: 'put',
        data: billing_list
    });

    return request.make(requestConfig);
};

// Update Payers
export const update_payers = (token, api, data_object) => {
    const url = `${global_constants.base_end_points}${api}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'put',
        data: data_object
    });

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

export const add_user = (token, api, data_object) => {
    const url = `${global_constants.base_end_points}${api}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'post',
        data: data_object
    });

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

//********* Scheduled Month End apis**********
export const get_scheduled_month_end_search = (search_criteria, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.scheduled_month_end_urls.search_data}`;
    let new_date = search_criteria;

    let updated_criteria = {
        from_date: custom_date_format(new_date.from_date, date_format["mm/dd/yyyy"]),
        to_date: custom_date_format(new_date.to_date, date_format["mm/dd/yyyy"]),
        company_id: search_criteria.company_id,
        status: search_criteria.status
    }

    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(updated_criteria)
    }

    let request = new Request();
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return dispatch => request.make(request_config).then(
        response => dispatch({
            type: constant.SCHEDULED_MONTH_END_SEARCH,
            payload: response
        }),
        error => {
            log_error(error)
        }
    )
}

export const cancel_scheduled_month_end_job = (token, data) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.scheduled_month_end_urls.cancel_job}`;

    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let requestConfig = new RequestConfig({
        url: url,
        headers: headers,
        method: 'put',
        data: JSON.stringify(data)
    });

    return request.make(requestConfig);
};


export const initiate_scheduled_month_end_job = (token, data) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.scheduled_month_end_urls.initiate_job}`;

    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let requestConfig = new RequestConfig({
        url: url,
        headers: headers,
        method: 'put',
        data: data
    });

    return request.make(requestConfig);
};

export const add_scheduled_month_end_job = (token, data) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.scheduled_month_end_urls.add_new_job}`;

    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'post',
        data: data
    });

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

// #region ExpectedRates Action methods
export const get_billing_tax_number = (token) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.expected_rate_urls.get_billing_tax_number}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(requestConfig);
}

export const get_expected_rates = (token) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.expected_rate_urls.get_expected_rates}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(requestConfig);
}

export const get_insurance_codes = (token) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.expected_rate_urls.get_insurance_code}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(requestConfig);
}

export const get_locations = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.expected_rate_urls.expected_rate_locations}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(requestConfig);
}

export const get_providers = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.expected_rate_urls.expected_rate_providers}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(requestConfig);
}

export const get_procedure_codes = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.expected_rate_urls.get_procedure_codes}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(requestConfig);
}

export const expected_rate_fee_rate_type = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.expected_rate_urls.expected_rate_fee_rate_type}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(requestConfig);
}

export const validate_expected_rates = (token, expected_rates_list) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.expected_rate_urls.validate_expected_rates}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': expected_rates_list
    })

    return request.make(requestConfig);
}

export const save_expected_rates = (token, expected_rates_list) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.expected_rate_urls.save_expected_rate}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': expected_rates_list
    })

    return request.make(requestConfig);
}
// #endregion


/**
 * Get Referring Physician Status
 * @param token
 * @param key
 */
export const get_referring_physician_status = (token, key) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.referring_physician_status_end_urls.search_incomplete}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': { key }
    })
    return request.make(request_config);
}

/**
 * Update referring physician status
 * @param token
 * @param physicianIds
 */
export const update_referring_physician_status = (token, physicianIds) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.referring_physician_status_end_urls.update_status}?${physicianIds}`;

    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'put'
    })
    return request.make(request_config);
}

// Manage Constant
export const get_constants = (token, params) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.manage_constant.constants}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get',
        params: {
            ...params
        }
    });

    return request.make(request_config).then((response) => {
        if (response) {
            return response;
        }
    });
};

export const copy_all_constants = (token, data) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.manage_constant.constants}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'post',
        data: { data: data }
    });

    return request.make(request_config).then((response) => {
        if (response) {
            return response;
        }
    });
};

export const copy_selected_sub_constants = (token, data) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.manage_constant.copy_selected_sub_constants}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'put',
        data: { data: data }
    });
    return request.make(request_config);
};

export const get_sub_constants = (token, params) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.manage_constant.sub_constants}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get',
        params: {
            ...params
        }
    });

    return request.make(request_config).then((response) => {
        if (response) {
            return response;
        }
    });
};

export const copy_selected_constants = (token, data) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.manage_constant.sub_constants}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'post',
        data: { data: data }
    });

    return request.make(request_config).then((response) => {
        if (response) {
            return response;
        }
    });
};

// get provider claims list
export const get_payer_group_by_company = (token, params) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.provider.get_payer_group_by_company}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let requestConfig = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get',
        params: {
            ...params
        }
    });

    return request.make(requestConfig);
};

export const get_default_payer_group_by_company = (token, params) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.provider.get_default_payer_group_by_company}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let requestConfig = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get',
        params: {
            ...params
        }
    });

    return request.make(requestConfig);
};

// Roles & Permissions
export const get_roles_list = (token, params) => {
    const url = create_endpoint('roles_and_permissions', ['base', 'roles']);
    let queryString = Object.keys(params)
        .map((key) => key + '=' + params[key])
        .join('&');
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url + '?' + queryString,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then((response) => {
        if (response) {
            return response;
        }
    });
};

export const get_permission_sets_list = (token, params) => {
    const url = create_endpoint('roles_and_permissions', ['base', 'permission_sets_list']);
    let queryString = Object.keys(params)
        .map((key) => key + '=' + params[key])
        .join('&');
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url + '?' + queryString,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then((response) => {
        if (response) {
            return response;
        }
    });
};

export const get_permission_list = (token, params) => {
    const url = create_endpoint('roles_and_permissions', ['base', 'get_permission_list']);
    let queryString = Object.keys(params)
        .map((key) => key + '=' + params[key])
        .join('&');
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url + '?' + queryString,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then((response) => {
        if (response) {
            return response;
        }
    });
};

export const update_permissions = (token, data) => {
    const url = create_endpoint('roles_and_permissions', ['base', 'update_permissions'])
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'put',
        data: { data: data }
    });
    return request.make(request_config);
};

export const quick_advance_search = (
    token: string,
    search_api: { global_base: any; end_points: any[] },
    params: { [x: string]: string }
) => {
    const url = create_endpoint(search_api.global_base, search_api.end_points);
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };
    let queryString = Object.keys(params)
        .map((key) => key + '=' + params[key])
        .join('&');
    let request_config = new RequestConfig({
        url: queryString ? url + '?' + queryString : url,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then((response) => {
        if (response) {
            return response;
        }
    });
};

export const get_role = (token, params) => {
    const url = create_endpoint('roles_and_permissions', ['base', 'get_role']);
    let queryString = Object.keys(params)
        .map((key) => key + '=' + params[key])
        .join('&');
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url + '?' + queryString,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then((response) => {
        if (response) {
            return response;
        }
    });
};

export const get_permission_set = (token, params) => {
    const url = create_endpoint('roles_and_permissions', ['base', 'get_permission_set']);
    let queryString = Object.keys(params)
        .map((key) => key + '=' + params[key])
        .join('&');
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url + '?' + queryString,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then((response) => {
        if (response) {
            return response;
        }
    });
};

export const search_r2_role = (token, params) => {
    const url = create_endpoint('roles_and_permissions', ['base', 'search_r2_role']);
    let queryString = Object.keys(params)
        .map((key) => key + '=' + params[key])
        .join('&');
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url + '?' + queryString,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then((response) => {
        if (response) {
            return response;
        }
    });
};

export const get_role_with_permission = (token, params) => {
    const url = create_endpoint('roles_and_permissions', ['base', 'get_role_with_permission']);
    let queryString = Object.keys(params)
        .map((key) => key + '=' + params[key])
        .join('&');
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url + '?' + queryString,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then((response) => {
        if (response) {
            return response;
        }
    });
};

export const add_update_role = (token, data) => {
    const url = create_endpoint('roles_and_permissions', ['base', 'add_update_role'])
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'put',
        data: { data: data }
    });
    return request.make(request_config);
};

export const add_update_permission_set = (token, data) => {
    const url = create_endpoint('roles_and_permissions', ['base', 'add_update_permission_set'])
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'put',
        data: { data: data }
    });
    return request.make(request_config);
};

export const update_role_status = (token, data) => {
    const url = create_endpoint('roles_and_permissions', ['base', 'update_role_status'])
    let queryString = Object.keys(data)
        .map((key) => key + '=' + data[key])
        .join('&');
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url + '?' + queryString,
        headers: headers,
        method: 'put'
    });

    return request.make(request_config);
};

export const update_permission_set_status = (token, data) => {
    const url = create_endpoint('roles_and_permissions', ['base', 'update_permission_set_status'])
    let queryString = Object.keys(data)
        .map((key) => key + '=' + data[key])
        .join('&');
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url + '?' + queryString,
        headers: headers,
        method: 'put'
    });

    return request.make(request_config);
};


/**
 * Download template
 * @param token
 * @param params
 */
export const get_bulk_user_template = (token, user_type) => {
    const url = `${global_constants.base_end_points}/user/bulk_user_template?request=${user_type}`;
    let request = new Request();
    let headers = {
        Authorization: 'Bearer ' + token
    };

    let requestConfig = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get',
        disable_retry: true,
        responseType: 'blob',
        body: {
            request: user_type
        }
    });

    return request.make(requestConfig);
};


/**
 * Download template
 * @param token
 * @param params
 */
export const get_bulk_user_template1 = (token, user_type) => {
    const END_POINT = global_constants.base_end_points;
    const END_POINT1 = 'http://localhost:51976/api';
    const url = `${END_POINT}/user/bulk_user_template?request=${user_type}`;
    return axios({
        url,
        method: "GET",
        headers: {
            Authorization: 'Bearer ' + token,
            "Content-Type": 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
        },
        responseType: 'blob'
    }).then(res => res);

    //return fetch(url, {
    //    method: "GET",
    //    headers: {
    //        "Content-Type": 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
    //    },
    //}).then(response => response.blob());
};


/**
 * Bluk user upload
 * @param token
 * @param params
 */
export const bulk_user_upload = (token, params) => {
    const url = `${global_constants.base_end_points}/user/bulk_user_upload`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };


    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': params
    })

    return request.make(request_config);
};

/**
 * User Bulk Upload: user type dopdown list data
 * @param token
 */
export const user_bulk_upload_user_type = (token) => {
    const url = `${global_constants.base_end_points}/user/BulkUserTypes`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(requestConfig);
}