import * as React from 'react';
import { text_comparator, currency_comparator } from '../../shared/component/grid/comparators';

interface IProps {
    columns: Array<any>,
    rows: any[],
    id?: string,
    name?: string,
    onSortChanged?: Function,
    onColumnMoved?: Function
}


/**
 * Provider Billing header component
 * This is custome table header and has been developed to manage table sort, resize and reorder functionalites
 */
class ClaimIndicatorHeaderComponent extends React.Component<IProps, any> {

    sort_type: string = '';
    sort_key: string = '';

    // Get innitalise data when component created
    constructor(props) {
        super(props);
        this.state = {
            cols: this.props.columns,
            dragOver: ''
        }
    }

    componentDidMount() {
        var table = document.getElementById('id-table-billing-settings-form');
        //resizableGrid(table);
    }

    //Call on column sorting click
    sortBy = (key) => {        
        let arrayCopy = [...this.props.rows];
        console.log(arrayCopy);
        this.sort_type = this.get_sort_type(key, this.sort_type);
        this.sort_key = key;
        if (this.sort_type !== '') {
            if (key === 'not_used_for_billing') {
                arrayCopy.sort((a, b) => {
                    let first = a.not_used_for_billing ? true : false;
                    let next = b.not_used_for_billing ? true : false;
                    return this.order_by(first, next, this.sort_type)
                });
            }
            else if (key === 'total_chg_amt' ) {
                arrayCopy.sort((a, b) => {
                    let first = parseFloat(a.unformatted_chg_amt);
                    let next = parseFloat(b.unformatted_chg_amt);
                    return this.order_currency_by(first, next, this.sort_type); 
                });                
            }
            else if (key === 'owed_amt') {
                arrayCopy.sort((a, b) => {
                    let first = parseFloat(a.unformatted_owed_amt);
                    let next = parseFloat(b.unformatted_owed_amt);                   
                    return this.order_currency_by(first, next, this.sort_type);
                });
            }
            else if (key === 'late_reason') {
                arrayCopy.sort((a, b) => {
                    return this.order_by(a.late_reason_text, b.late_reason_text, this.sort_type);
                });
            }
            else if (key === 'condition_code') {
                arrayCopy.sort((a, b) => {
                    return this.order_by(a.condition_code_text, b.condition_code_text, this.sort_type);
                });
            }
            else if (key === 'action') {
                arrayCopy.sort((a, b) => {
                    return this.order_by(a.claim_action_text, b.claim_action_text, this.sort_type);
                });
            }
            else {
                arrayCopy.sort((a, b) => this.order_by(a[key], b[key], this.sort_type));
            }
        }
        this.props.onSortChanged(arrayCopy);
    }

    //Change sorting type
    get_sort_type = (key, type) => {
        let stype = 'asc';
        if (key === this.sort_key) {
            if (type === '') {
                stype = 'asc';
            } else if (type === 'asc') {
                stype = 'desc';
            } else if (type === 'desc') {
                stype = '';
            }
        }
        return stype;
    }

    //Perform sorting
    order_by = (first, next, type) => {
        if (type === 'asc') {
            return text_comparator(first, next);
        } else if (type === 'desc') {
            return text_comparator(next, first);
        }
    }
    order_currency_by = (first, next, type) => {
        if (type === 'asc') {
            return currency_comparator(first, next);
        } else if (type === 'desc') {
            return currency_comparator(next, first);
        }
    }
    //Change sorting icon
    render_sort_icon = (key) => {
        if (key === this.sort_key) {
            if (this.sort_type === 'asc') {
                return 'ascending'
            } else if (this.sort_type === 'desc') {
                return 'descending';
            }
        }
        return '';
    }

    //Render header columns 
    render_columns = () => {
        return this.state.cols.map((col) => {
            return (
                <th style={{ ...col.style, position: 'relative' }} key={col.field}>
                    <div className={col.headerName == 'Rebill' ? '' : 'table-col-header-container'}
                        onClick={() => this.sortBy(col.field)}>
                        <span className="header-col-label"
                            id={col.field}
                        >{col.headerName}</span>
                        {col.headerName != 'Rebill' &&
                            <span className="col-header-sort-icon">
                                <i aria-hidden="true" className={`sort ${this.render_sort_icon(col.field)} small icon`}></i>
                            </span>
                        }
                    </div>
                    <div className="header-col-resize" />
                </th>
            );
        })
    }

    //Render function
    render() {
        return (<tr>{this.render_columns()}</tr>);
    }
};

//apply column resizing functionality
const resizableGrid = (table) => {
    var col_resize = table.querySelectorAll('thead .header-col-resize');
    if (!col_resize) return;

    for (var i = 0; i < col_resize.length; i++) {
        setListeners(col_resize[i]);
    }

    function setListeners(div) {
        var pageX, curCol, nxtCol, curColWidth, nxtColWidth;

        div.addEventListener('mousedown', function (e) {
            curCol = e.target.parentElement;
            nxtCol = curCol.nextElementSibling;
            pageX = e.pageX;
            var padding = paddingDiff(curCol);
            curColWidth = curCol.offsetWidth - padding;

            if (nxtCol)
                nxtColWidth = nxtCol.offsetWidth - padding;
        });

        //div.addEventListener('mouseover', function (e) {
        //    e.target.style.borderRight = '2px solid #0000ff';
        //})

        //div.addEventListener('mouseout', function (e) {
        //    e.target.style.borderRight = '';
        //})

        document.addEventListener('mousemove', function (e) {
            if (curCol) {
                var diffX = e.pageX - pageX;
                //if (nxtCol)
                //nxtCol.style.width = (nxtColWidth - (diffX)) + 'px';

                curCol.style.width = (curColWidth + diffX) + 'px';
                curCol.style.minWidth = (curColWidth + diffX) + 'px';
            }
        });

        document.addEventListener('mouseup', function (e) {
            curCol = undefined;
            nxtCol = undefined;
            pageX = undefined;
            nxtColWidth = undefined;
            curColWidth = undefined
        });
    }

    function paddingDiff(col) {
        if (getStyleVal(col, 'box-sizing') == 'border-box') {
            return 0;
        }

        var padLeft = getStyleVal(col, 'padding-left');
        var padRight = getStyleVal(col, 'padding-right');
        return (parseInt(padLeft) + parseInt(padRight));
    }

    function getStyleVal(elm, css) {
        return (window.getComputedStyle(elm, null).getPropertyValue(css))
    }
};

export default ClaimIndicatorHeaderComponent;