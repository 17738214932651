import * as moment from 'moment';
import * as React from "react";
import { connect } from "react-redux";
import { toastr } from 'react-redux-toastr';
import { Button, Dimmer, Form, Grid, Loader, Modal } from "semantic-ui-react";
import Selection from '../../shared/component/selection_component';
import * as local_storage from '../../shared/local_storage_utility';
import * as session_storage from '../../shared/session_storage_utility';
import { handle_focus_on_tab, set_focus_on_element_with_id } from '../../shared/tab_navigation_utility';
import { get_print_receipts, get_print_reciept_logo } from '../action/patient_header_action';
import { modal_hide_on_print, modal_show_after_print, modal_show_after_print_ios, phone_format, execute_print, print_html_data_to_pdf } from './../../shared/utility';

class PrintPatientReciept extends React.Component<any, any> {
    _is_mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            patient_id: session_storage.get("active_patient"),
            patient_name: '',
            loading: false,
            show_preview: false,
            show_confirm: false,
            selected_credit: null,
            is_submitted: false,
            credit_list: null,
            credit_data: null,
            preview_data: null,
            logo: '',
            location_id: null,
            company_details: null,
        };
    }

    print_preview_enum = 'preview_box';
    print_confirm_enum = 'confirm_box';

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.show != this.props.show) {


            if (this._is_mounted) {
                this.setState({ show_confirm: true, loading: true, patient_name: this.props.patient_name });
            }
            this.get_credit_list();

        }

        if (this.state.credit_list != prevState.credit_list && this.state.credit_list) {
            if (this._is_mounted) {
                this.setState({ loading: false });
            }
        }

        if (prevProps.patient_name != this.props.patient_name) {
            if (this._is_mounted) {
                this.setState({ patient_name: this.props.patient_name });
            }
        }
    }
    handle_on_open_modal_window = () => {
        setTimeout(() => {
            let closeIcons: NodeListOf<HTMLElement> = document.querySelectorAll('i.close.icon') as NodeListOf<HTMLElement>;
            let closeIcon = closeIcons[closeIcons.length - 1]
            closeIcon.tabIndex = 0;
            closeIcon.id = "patient_reciept_model_close_button";

            let no_credit_close_button = document.getElementById("no_credit_close_button");

            closeIcon.addEventListener("keydown", function (event) {
                if (event.shiftKey && event.keyCode == 9) {
                    event.preventDefault();

                    if (no_credit_close_button) {
                        set_focus_on_element_with_id("no_credit_close_button");
                    } else {
                        set_focus_on_element_with_id("print_patient_credit");
                    }
                }

                if (event.keyCode == 13) {
                    event.preventDefault();
                    var el = event.target as HTMLElement
                    el.click();
                }
            });


            // setting the focus for the first time.
            if (no_credit_close_button) {
                set_focus_on_element_with_id("no_credit_close_button");
            } else {
                set_focus_on_element_with_id("patient_receipt_select");
            }

        }, 500)
    }

    componentDidMount() {
        this._is_mounted = true;
    }
    componentWillUnmount() {
        this._is_mounted = false;
    }
    get_credit_list = async () => {
        const token = local_storage.get("auth_data").token_details.access_token;
        let patient_id = session_storage.get("active_patient");
        let currentDate = moment().format('MM/DD/YYYY');
        var company_id = null;
        if (this.props.user_login_details.user_data.data) {
            company_id = this.props.user_login_details.user_data.data ? this.props.user_login_details.user_data.data.company_id : 0;
            if (this._is_mounted) {
                this.setState({ company_details: this.props.user_login_details.user_data.data });
            }
        }

        await get_print_receipts(patient_id, currentDate, token).then(res => {
            let result = res.data.data;
            if (result != null && result.length > 0) {

                var credit_list = result.map((val, index) => {
                    //let text = moment(val.deposit_date).format('MM/DD/YYYY') + '-' + val.payment_type + '-' + this.format_amount(val.original_amount) + (val.comment != '' ? '-' + val.comment : '')
                    let text = moment(val.deposit_date).format('MM/DD/YYYY') + '-' + val.payment_type + '-' + this.format_amount(val.original_amount, true);
                    text = text.replace(/-/g, " - ");
                    return { key: index, value: val.id, text: text }
                });

                let preview_data = typeof result[0].id != 'undefined' ? this.get_credit_by_id(result[0].id, result) : null;
                if (preview_data.place_of_service) {
                    this.get_print_reciept_logo(company_id, preview_data.place_of_service.id, token);
                }
                if (this._is_mounted) {
                    this.setState({ credit_data: result, credit_list: credit_list, selected_credit: (typeof result[0].id != 'undefined' ? result[0].id : null), preview_data: preview_data, loading: false });
                }
            } else {
                if (this._is_mounted) {
                    this.setState({ loading: false, credit_data: null, credit_list: null, preview_data: null, selected_credit: null });
                }
            }

        }, error => {
            if (this._is_mounted) {
                this.setState({ loading: false });
            }
            if (error.response && error.response.data && error.response.data.messages) {
                toastr.error('', error.response.data.messages[0].message);
            }
        });
    }

    // get location logo /company logo
    get_print_reciept_logo = async (company_id, location_id, token) => {
        await get_print_reciept_logo(company_id, location_id, token).then(res => {
            let result = res.data.data;
            if (result != null && result != '') {
                let logo = "data:image/JPEG;base64," + result;
                if (this._is_mounted) {
                    this.setState({ logo: logo });
                }
            }

        });
    }

    // format amount into decimal form
    format_amount = (number, is_sign) => {
        let original_number = Number(number);
        number = Number(number);
        let places = 2;
        let symbol = is_sign ? '$' : '';
        let thousand = ',';
        let decimal = ".";
        var negative = original_number < 0 ? "-" : "";
        var i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "";
        var j = (j = i.length) > 3 ? j % 3 : 0;
        var formatted_amount = negative + symbol + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - Number(i)).toFixed(places).slice(2) : "");
        if (original_number < 0 && symbol) {
            formatted_amount = formatted_amount.replace("-" + symbol, symbol + "-")
        }
        return formatted_amount;
    }



    // print handler call on click print button
    print_preview_handler = (e) => {
        e.preventDefault();
        var get_credit_preview_data = this.get_credit_by_id(this.state.selected_credit, this.state.credit_data);
        if (this._is_mounted) {
            this.handle_on_open_modal_confirm_print_window();
            this.setState({ preview_data: get_credit_preview_data, show_preview: true }, () => {
                document.querySelector('#printPopup i').classList.add('close-hidden-print');
            });
        }
    }


    // print the preview data
    printHandler() {
        var printPopup = document.getElementById('printPopup');
        print_html_data_to_pdf(printPopup.innerHTML, 'printButton', 'Patient Receipt');
        /*
        modal_hide_on_print(printPopup.innerHTML);
        if (window.print) {
            if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
                setTimeout(function () {
                    execute_print();
                }, 100)

                setTimeout(function () {
                    modal_show_after_print_ios();
                }, 5000)

            }
            else {
                if (window.matchMedia) {
                    var mediaQueryList = window.matchMedia('print');
                    mediaQueryList.addListener(function (mql) {
                        if (mql.matches) {
                        } else {
                            afterPrint();
                        }
                    });
                }
                var afterPrint = () => modal_show_after_print();
                // These are for Firefox
                window.onafterprint = afterPrint;
                execute_print();
            }
        }
        */
    }

    // get preview data by credit id
    get_credit_by_id = (id, credit_list) => {
        return credit_list.filter((val, index) => val.id == id).reduce((acc, val) => acc = val);
    }

    // open confirm popup
    print_confirm_handle = (e) => {
        if (this._is_mounted) {
            this.handle_on_open_modal_confirm_print_window();
            this.setState({ show_preview: true });
        }
    }

    handle_on_open_modal_confirm_print_window = () => {
        setTimeout(() => {
            let closeIcons: NodeListOf<HTMLElement> = document.querySelectorAll('i.close.icon') as NodeListOf<HTMLElement>;
            let closeIcon = closeIcons[closeIcons.length - 1]
            closeIcon.tabIndex = 0;
            closeIcon.id = "confirm_print_model_window_close_icon";

            closeIcon.addEventListener("keydown", function (event) {
                if (event.shiftKey && event.keyCode == 9) {
                    event.preventDefault();

                    set_focus_on_element_with_id("printButton");
                }

                if (event.keyCode == 13) {
                    event.preventDefault();
                    var el = event.target as HTMLElement
                    el.click();
                }
            });

            // setting the focus for the first time.
            set_focus_on_element_with_id("confirm_print_model_window_close_button");

        }, 0)
    }
    // close popup 
    handleClose = (type) => {
        if (type == this.print_confirm_enum) {

            // set focus to parent element
            set_focus_on_element_with_id("print_patient_receipt_handle_icon");

            if (this._is_mounted) {
                this.setState({ show_confirm: false, preview_data: null });
            }
        } else if (type == this.print_preview_enum) {
            if (this._is_mounted) {
                // set focus to parent element
                set_focus_on_element_with_id("print_patient_credit");
                this.setState({ show_preview: false });
            }
        }

    }
    render() {
        let font_size_style = { fontSize: '16px' };
        const preview = this.state.preview_data;
        
        let check_style = preview && preview.payment_type == 'Cash' ? { display: 'none' } : {};
        let zip = preview && (preview.place_of_service.address.zip.length > 5 ? preview.place_of_service.address.zip.substring(0, 5) + '-' + preview.place_of_service.address.zip.substring(5, preview.place_of_service.address.zip.length) : preview.place_of_service.address.zip);
        return (<>

            <Modal
                onClose={e => this.handleClose(this.print_confirm_enum)}
                centered={false}
                onMount={this.handle_on_open_modal_window}
                className="print_reciept_modal add-new-note"
                open={this.state.show_confirm}
                closeIcon
                id={"print_review_header"}
                closeOnDimmerClick={false}
            >
                <Modal.Header className='print-reciept-modal-header'>Patient Receipt for {this.state.patient_name}</Modal.Header>
                <Dimmer active={this.state.loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <Modal.Content id={"printReview"}>
                    <Modal.Description>
                        <div className="common-forms" style={{ padding: '0px' }}>
                            {this.state.credit_list ?
                                <form className="ui form">
                                    <Grid>
                                        <Grid.Column computer={8} tablet={16}>

                                            <Form.Field>
                                                <label>Select Credit :  </label>
                                                <Selection
                                                    id="patient_receipt_select"
                                                    defaultValue={this.state.selected_credit}
                                                    style={'menu-options-inline'}
                                                    placeHolder='Select'
                                                    options={this.state.credit_list}
                                                    onChange={value => this.setState({ selected_credit: value })}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid className='popup_button_footer'>
                                        <Grid.Column computer={16} textAlign="right">
                                            <Button type="reset" basic onClick={e => this.handleClose(this.print_confirm_enum)}>Close</Button>
                                            <Button id="print_patient_credit" type='submit' onKeyDown={(event) => { handle_focus_on_tab(event, "patient_reciept_model_close_button") }} onClick={this.print_preview_handler} primary>Print Patient Credit</Button>
                                        </Grid.Column>
                                    </Grid>
                                </form> :
                                <form className="ui form">
                                    <Grid>
                                        <Grid.Column computer={8} tablet={16}>
                                            <p>Patient has no credits on file.</p>
                                        </Grid.Column>
                                        <Grid.Column computer={16} tablet={16} textAlign={'right'}>
                                            <Button id="no_credit_close_button" type="reset" basic onKeyDown={(event) => { handle_focus_on_tab(event, "patient_reciept_model_close_button") }} onClick={e => this.handleClose(this.print_confirm_enum)}>Close</Button>
                                        </Grid.Column>
                                    </Grid>
                                </form>
                            }
                        </div>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
            {preview && <Modal
                onClose={e => this.handleClose(this.print_preview_enum)}
                centered={false}
                className=" default-modal"
                id="printPopup"
                open={this.state.show_preview}
                closeIcon
                closeOnDimmerClick={false}
            >
                <Modal.Header className={'print_reciept_modal print-reciept-modal-header'} >Confirm Print</Modal.Header>
                <Modal.Content className="print-patient-inner" >
                    <Modal.Description>
                        <div>
                            <br />
                            <Grid>
                                <Grid.Column className={'print_current_date'} style={{ 'display': 'none' }} tablet={16} computer={16} mobile={16} textAlign={'left'}><p>{moment().format("MM/DD/YYYY")}</p></Grid.Column>
                                <Grid.Column tablet={16} computer={16} mobile={16} textAlign={'center'}><p><b className="font-bold">Payment Receipt</b> </p></Grid.Column>
                                <Grid.Column tablet={16} computer={16} mobile={16} textAlign={'center'} verticalAlign={'top'}>
                                    {this.state.logo ? <img src={this.state.logo} style={{ maxWidth: '100%', maxHeight: '100%' }} /> : ''} {this.state.logo ? <br></br> : ''}
                                    {this.state.company_details.gpms_code}&nbsp;-&nbsp;{this.state.company_details.company_name} {this.state.company_details.company_name ? <br></br> : ''}
                                    {preview.place_of_service.address.address1} {preview.place_of_service.address.address1 ? <br></br> : ''}
                                    {preview.place_of_service.address.city ? preview.place_of_service.address.city + ',' : ''}&nbsp;
                                    {preview.place_of_service.address.state_id}&nbsp;
                                {zip} {preview.place_of_service.phone ? <br></br> : ''}
                                    {preview.place_of_service ? phone_format(preview.place_of_service.phone) : ''}
                                </Grid.Column>

                                <Grid.Column tablet={16} computer={16} mobile={16} textAlign={'left'}>
                                    <label><b>Patient Name : </b></label> &nbsp;&nbsp;&nbsp;&nbsp;
                                <label>
                                        {this.state.patient_name}
                                    </label>
                                </Grid.Column>
                                <Grid.Column tablet={16} computer={16} mobile={16} textAlign={'left'}>
                                    <label><b>Account Number : </b></label> &nbsp;&nbsp;&nbsp;&nbsp;
                                <label>
                                        {preview.patient_id}
                                    </label>
                                </Grid.Column>
                                <Grid.Column tablet={16} computer={16} mobile={16} textAlign={'left'}>
                                    <label><b>Payment Date : </b></label> &nbsp;&nbsp;&nbsp;&nbsp;
                                <label>
                                        {moment(preview.deposit_date).format("MM/DD/YYYY")}
                                    </label>
                                </Grid.Column>
                                <Grid.Column tablet={16} computer={16} mobile={16} textAlign={'left'}>
                                    <label><b>Payment Type : </b></label> &nbsp;&nbsp;&nbsp;&nbsp;
                                <label>
                                        {preview.payment_type}
                                    </label>
                                </Grid.Column>
                                <Grid.Column tablet={16} computer={16} mobile={16} textAlign={'left'}>
                                    <label><b>Payment Amount : </b></label> &nbsp;&nbsp;&nbsp;&nbsp;
                                <label>
                                        {this.format_amount(preview.original_amount, '')}
                                    </label>
                                </Grid.Column>
                                <Grid.Column style={check_style} tablet={16} computer={16} mobile={16} textAlign={'left'}>
                                    <label><b> {preview.payment_type == 'Credit' ? "Credit Card Number :" : 'Check Number :'} </b></label>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <label>
                                        {preview.payment_type == 'Credit' ? preview.credit_card_auth_num : preview.check_num}
                                    </label>
                                </Grid.Column>
                                {preview.comment ?
                                    <Grid.Column className={preview.comment ? '' : 'close-hidden-print'} tablet={16} computer={16} mobile={16} textAlign={'left'}>
                                        <label><b>Comment : </b></label> &nbsp;&nbsp;&nbsp;&nbsp;
                                <label>
                                            {preview.comment}
                                        </label>
                                    </Grid.Column> : ''}

                                <Grid.Column tablet={16} computer={16} mobile={16} textAlign={'center'}>
                                    <b className="font-bold">{preview.future_appointment} </b><br />
                                </Grid.Column>

                            </Grid>
                        </div>
                        <Grid className='print_reciept_modal'>
                            <Grid.Column computer={16} textAlign="right">
                                <Button type="reset" basic id="confirm_print_model_window_close_button" onClick={e => this.handleClose(this.print_preview_enum)}>Close</Button>
                                <Button type='submit' onClick={this.printHandler} id="printButton" onKeyDown={(event) => { handle_focus_on_tab(event, "confirm_print_model_window_close_icon") }} primary>Print</Button>
                            </Grid.Column>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
            </Modal>}

        </>
        );
    }

}


//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
    };
};
export default connect(mapStateToProps, null)(PrintPatientReciept);