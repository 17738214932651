import { mm_dd_yyyy_comparator, text_comparator } from '../shared/component/grid/comparators';

export const case_messages = {
    added_successfully: 'Case saved successfully.',
    updated_successfully: 'Case updated successfully.',
    updated_no_changes: 'There were no changes made which can be saved.',
    updated_error: 'Unable to save. Please try again.',

    required_fields: "You must complete all required fields.",
    req_for_billing: 'You can edit these fields, but you cannot leave them blank.',
    req_for_billing_dx: 'At least one diagnosis code is required',

    primary_insurance_undefined_error: 'If you have a Secondary Insurance, then you must have a Primary Insurance.',
    secondary_insurance_undefined_error: 'If you have a Tertiary Insurance, then you must have a Secondary Insurance.',
    zip_code_error: 'Zip can be 5 or 9 digits',
    primary_insurance_cant_remove_error: 'The Primary Insurance cannot be removed, only changed.',
    emp_name_required: 'The Employer Name is required.',
    relationship_error: 'If Insurance is Medicare, then relationship must be SELF.',
    primary_secondary_insurance_same: 'The Primary Insurance and Secondary Insurance are the same. This is not allowed.',
    secondary_tertiary_insurance_same: 'The Secondary Insurance and Tertiary Insurance are the same. This is not allowed.',
    primary_tertiary_insurance_same: 'The Primary Insurance and Tertiary Insurance are the same. This is not allowed.',
    policy_number_error_tricare: 'The policy number should be 9 or 11 numbers.',
    policy_number_error_medicare: 'Medicare Policy Number is not in the correct format.',
    policy_number_error_medicare_primary_insurance: 'Medicare Policy Number is not in the correct format for the primary insurance.',
    policy_number_error_medicare_secondary_insurance: 'Medicare Policy Number is not in the correct format for the secondary insurance.',
    policy_number_error_medicare_tertiary_insurance: 'Medicare Policy Number is not in the correct format for the tertiary insurance.',
    policy_number_error_rail_road: 'RailRoad Policy Number is not in the correct format.',
    policy_number_error_rail_road_primary_insurance: 'RailRoad Policy Number is not in the correct format for the primary insurance.',
    policy_number_error_rail_road_secondary_insurance: 'RailRoad Policy Number is not in the correct format for the secondary insurance.',
    policy_number_error_rail_road_tertiary_insurance: 'RailRoad Policy Number is not in the correct format for the tertiary insurance.',
    preauth_error_message: 'The Pre-Authorizations requires a From Date. Save case without Authorization?',
    preauth_doctors_order_error_message: 'The Pre-Authorizations and Doctor\'s Orders requires a From Date.Save Case without Authorization and Doctor\'s Orders?',
    required_for_billing_text: 'required for billing',
    required_for_worker_compensation: 'required if Workers’ Compensation',
    clone_case_message: 'You have requested to make a new case based on this case\'s information. Do you want to continue?',
}
export const configurable_date = {
    trans_start: "04/01/2018",
    trans_end: "12/31/2019",
    default_date: "1900-01-01T00:00:00"
}
export enum enum_date_type {
    start_of_care = "start_of_care",
    assignment = "assignment",
    injury = "injury",
    effective = "effective",
    discharge = "discharge",
    next_visit = "next_visit",
    referral = "referral",
    date_of_service = "date_of_service",
    plan_of_care = "plan_of_care",
}
export enum enum_type_of_search {
    referring_physician = "referring_physician",
    second_referring_physician = "second_referring_physician",
    place_of_service = "place_of_service",
    provider = "provider",
    special_program = "special_program",
    referral_source = "referral_source",
    search_referral_source_type = "search_referral_source_type",
    primary_insurance = "primary_insurance",
    secondary_insurance = "secondary_insurance",
    tertiary_insurance = "tertiary_insurance",
    insurance = "insurance",
    treating_provider = "treating_provider",
    insurance_class = "insurance_class",
    note_code = "note_code",
    patient_id = "patient_id"

}
export enum enum_case_page_state {
    edit = "edit",
    add = "add"
}
export enum enum_type_of_insurance {
    primary_insurance = "Primary Insurance",
    secondary_insurance = "Secondary Insurance",
    tertiary_insurance = "Tertiary Insurance",

}
export enum enum_type_of_physician {
    primary_physician = "Primary Physician",
    secondary_physician = "Secondary Physician",
    none = "None"
}
export enum enum_case_link_id {
    primary_insurance = "primary_physician",
    secondary_insurance = "secondary_physician",
    tertiary_insurance = "tertiary_insurance",
    foto = "foto",
    verification_form = "verification_form",
    conditional_billing = "conditional_billing"
}
const headerTemplate = {
    template: '<div class="ag-cell-label-container" role="presentation">' +
        '<i class="small filter icon filter-ipad filter-active-icon"></i>' +
        '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '<div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '</div>' +
        '</div>'
};

export const foto_report_configuration = {
    title: "Patient Search",
    grid_header_height: 35,
    column_defs: [
        {
            headerName: "Report Type",
            headerComponentParams: headerTemplate,
            field: "report_type",
            filter: "textFilter",
            unSortIcon: true,
            minWidth: 175,
            comparator: text_comparator
        },
        {
            headerName: "Survey Date",
            headerComponentParams: headerTemplate,
            field: "survey_date",
            filter: "dateFilter",
            minWidth: 115,
            unSortIcon: true,
            comparator: mm_dd_yyyy_comparator
        }
    ]
};

export const feature_flags = {
    payment_posting_feedback: "argosBill1423PaymentPostingFeedback",
};

export const fields_to_compare = {
    excluded_default: ["formatted_", "formated_", "unique_id", "is_selected", "old_"],
    excluded_required: ["billing_provider_code", "discipline", "provider"],
    decimal: ["in_network_copay", "separate_copay_amount", "ytd_deductible_met","in_network_deduct", "out_network_copay", "amount_to_collect", "out_network_deduct"]
};