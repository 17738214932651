import * as global_constants from '../../global_constants';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request';

const { base_end_points, end_points } = global_constants
export const get_searchList = (token, company_id, key, page_size, partialUrl, include_inactive= false) => {

    const url = `${global_constants.base_end_points}${partialUrl.trim()}`;

    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            keyword: key,
            pageSize: page_size,
            companyId: company_id,
            include_inactive: include_inactive
        }
    })
    return request.make(request_config);
}
export const get_companyList = (token, key, partialUrl) => {

    const url = `${global_constants.base_end_points}${partialUrl.trim()}`;

    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            keyword: key
        }
    })

    return request.make(request_config);
}

export const referring_physician_advance_search = (params, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.provider_config.provider_config}${global_constants.end_points.provider_config.referring_physician_advance_search}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(params)
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config);
}

// quick search referring_physician by keywords
export const referring_physician_quick_search = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.provider_config.provider_config}${global_constants.end_points.provider_config.referring_physician_quick_search}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            keyword: param,
            pageSize: global_constants.constants.Quick_Search_Suggestion_List_Size,

        }
    })

    return request.make(request_config);
}

export const referring_source_advance_search = (params, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.referring_source.referring_source}${global_constants.end_points.referring_source.referring_source_advance_search}`;
    var value = (params.Source_Des ? params.Source_Des : '');
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            description: value
        }
    })

    return request.make(request_config);
}


// quick search referring_source by keywords
export const referring_source_quick_search = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.referring_source.referring_source}${global_constants.end_points.referring_source.referring_source_quick_search}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            keyword: param,
            page_size: global_constants.constants.Quick_Search_Suggestion_List_Size,
            page_index: 0

        }
    })

    return request.make(request_config);
}

export const referring_source_type_quick_search = (param, token) => {
    const {
        referral_type,
        referring_source,
        quick_search_referring_source_type
    } = end_points.referring_source
    const url = `${base_end_points}${referring_source}${referral_type}${quick_search_referring_source_type}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            keyword: param,
            page_size: global_constants.constants.Quick_Search_Suggestion_List_Size,
            page_index: 0

        }
    })

    return request.make(request_config)
}

export const referring_source_type_advance_search = (param, token) => {
    const {
        referral_type,
        referring_source,
        search_referring_source_type
    } = end_points.referring_source
    const url = `${base_end_points}${referring_source}${referral_type}${search_referring_source_type}`;
    const description = param.SourceType_Des ? param.SourceType_Des : "";
    const code = param.SourceType_Code ? param.SourceType_Code : "";
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            code,
            description
        }
    })

    return request.make(request_config)
}

export const search_place = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.provider_config.search_place}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(param)
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(request_config);
}
export const search_company_pos = (company_id, restrict_pos, param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.provider_config.search_company_pos}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(param)
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            company_id,
            restrict_pos
        }
    })

    return request.make(request_config);
}
export const search_provider = (param, token, is_treating_provider = false) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.provider_config.search_provider}/${param.id ? param.id : -1}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify({ "Description": param.provider_name, "Code": param.RP_provider_code, "Page_size": global_constants.constants.PAGE_SIZE, "Apply_pos_code": param.apply_pos_code }),
        //'id' : param.id
    }
    if (is_treating_provider) {
        headers = {
            'Authorization': 'Bearer ' + token,
            'silversurfer': JSON.stringify({ "Description": param.provider_name, "Code": param.RP_provider_code, "Page_size": global_constants.constants.PAGE_SIZE, "Apply_pos_code": param.apply_pos_code, "Provider_type": 2 }),
            //'id' : param.id
        }
    }



    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config);
}

// quick search locations by keywords
export const quick_search_location = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.provider_config.quick_search_location}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            keyword: param,
            pageSize: global_constants.constants.Quick_Search_Suggestion_List_Size

        }
    })

    return request.make(request_config);
}
export const quick_search_location_by_company = (company_id, restrict_pos, param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.provider_config.quick_search_company_pos}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            company_id,
            restrict_pos,
            keyword: param,
            page_size: global_constants.constants.Quick_Search_Suggestion_List_Size

        }
    })

    return request.make(request_config);
}
// quick search locations by keywords
export const quick_search_provider = (param, token, location_id, is_treating_provider_search = false) => {
    const url = `${global_constants.base_end_points}${is_treating_provider_search ? global_constants.end_points.provider_config.quick_search_treating_provider : global_constants.end_points.provider_config.quick_search_provider}/${location_id}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            keyword: param,
            pageSize: global_constants.constants.Quick_Search_Suggestion_List_Size

        }
    })

    return request.make(request_config);
}
export const special_program_advance_search = (params, patient_id, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.referring_source.referring_source}${global_constants.end_points.referring_source.search_special_program}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(params)
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config);
}


// quick search referring_source by keywords
export const special_program_quick_search = (param, patient_id, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.referring_source.referring_source}${global_constants.end_points.referring_source.quick_special_program}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            keyword: param,
            page_size: global_constants.constants.Quick_Search_Suggestion_List_Size,
            page_index: 0

        }
    })

    return request.make(request_config);
}
// quick search referring_source by keywords for primary
export const insurance_quick_search = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.subscriber_url.subscriber}${global_constants.end_points.subscriber_url.insurance.insurance}${global_constants.end_points.subscriber_url.insurance.quick_insurance}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            keyword: param,
            page_size: global_constants.constants.Quick_Search_Suggestion_List_Size,


        }
    })

    return request.make(request_config);
}
export const insurance_quick_search_by_company = (company_id, param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.subscriber_url.insurance.quick_insurance_by_company}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            company_id,
            keyword: param,
            page_size: global_constants.constants.Quick_Search_Suggestion_List_Size,


        }
    })

    return request.make(request_config);
}
// quick search referring_source by keywords by secondary and tertiary
export const insurance_quick_search_sec_tertiary = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.subscriber_url.subscriber}${global_constants.end_points.subscriber_url.insurance.insurance}${global_constants.end_points.subscriber_url.insurance.quick_insurance_sec_ter}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            keyword: param,
            page_size: global_constants.constants.Quick_Search_Suggestion_List_Size,


        }
    })

    return request.make(request_config);
}
export const insurance_advance_search = (params, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.subscriber_url.subscriber}${global_constants.end_points.subscriber_url.insurance.insurance}${global_constants.end_points.subscriber_url.insurance.search_insurance}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(params)
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config)

}
export const insurance_advance_search_by_company = (company_id, params, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.subscriber_url.insurance.search_insurance_by_company}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(params)
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            company_id: company_id,
        }
    })

    return request.make(request_config)

}
export const insurance_advance_search_sec_tertiary = (params, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.subscriber_url.subscriber}${global_constants.end_points.subscriber_url.insurance.insurance}${global_constants.end_points.subscriber_url.insurance.search_insurance_sec_ter}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(params)
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config)

}

export const procedure_code_advance_search = (params, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.procedure_code.procedure_code}${global_constants.end_points.procedure_code.lookup_search}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(params)
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(request_config)

}

// quick search referring_source by keywords by secondary and tertiary
export const procedure_code_quick_search = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.procedure_code.procedure_code}${global_constants.end_points.procedure_code.quick_search}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            keyword: param,
            page_size: global_constants.constants.Quick_Search_Suggestion_List_Size,


        }
    })

    return request.make(request_config);
}
export const procedure_code_advance_search_by_company = (company_id, params, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.procedure_code.procedure_code}${global_constants.end_points.procedure_code.lookup_search_by_company}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(params)
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            company_id: company_id
        }
    })
    return request.make(request_config)

}

// quick search referring_source by keywords by secondary and tertiary
export const procedure_code_quick_search_by_company = (company_id, param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.procedure_code.procedure_code}${global_constants.end_points.procedure_code.quick_search_by_company}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            company_id: company_id,
            keyword: param,
            page_size: global_constants.constants.Quick_Search_Suggestion_List_Size,


        }
    })

    return request.make(request_config);
}

export const search_note_code = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.search_note_code}`;

    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(param)
    }
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config);
}

// quick search note code by keywords
export const quick_search_note_code = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.quick_search_note_code}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            keyword: param,
            pageSize: global_constants.constants.Quick_Search_Suggestion_List_Size,
            is_from_patient: true

        }
    })

    return request.make(request_config);
}

// quick search referring_source by keywords by secondary and tertiary
export const adjustment_code_quick_search = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.adjustment_code_url.quick_search}`;
    let request = new Request();
    let headers = {
      Authorization: 'Bearer ' + token
    };
    let request_config = new RequestConfig({
      url: url,
      headers: headers,
      method: 'get',
      params: {
        keyword: param,
        page_size: global_constants.constants.Quick_Search_Suggestion_List_Size
      }
    });
    return request.make(request_config);
}

// quick search referring_source by keywords by secondary and tertiary
export const payment_code_quick_search = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment_code_url.quick_search}`;
    let request = new Request();
    let headers = {
      Authorization: 'Bearer ' + token
    };
    let request_config = new RequestConfig({
      url: url,
      headers: headers,
      method: 'get',
      params: {
        keyword: param,
        page_size: global_constants.constants.Quick_Search_Suggestion_List_Size
      }
    });
    return request.make(request_config);
}