import * as React from 'react';
import * as moment from 'moment';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';

class ReportRow835Component extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            show_payers: true
        };
    }

    render_inner_angle_icon = (errorId) => {
        return (
            <span className="angle-group-expand-collapse" onClick={this.handle_group_click}>
                <i id={`error-${errorId}`} aria-hidden="true" className={`angle-color angle ${this.state.show_payers ? 'up' : 'down'} icon`}></i>
            </span>
        );
    }

    handle_group_click = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                show_payers: !prevState.show_payers
            }
        });
    }

    render_inner_header = (index) => {
        return this.props.innerColumn.map((col, i) => {
            return (
                <Table.HeaderCell
                    key={`lhclevel${i}-h${index}`}
                    content={col.headerName}
                />
            );
        });
    }

    render_inner_rows = (payers, index, errorId) => {
        return payers.map((item, i) => {
            item = {
                ...item,
            }
            return (
                <Table.Row key={`plevel${i}-h${index}`} className={'multi-select-dropdown-inactive-pay-row'} id={"error-exclusions-inner-rows"}>
                    {this.props.innerColumn.map(col => {
                        return (
                            <Table.Cell
                                key={`prlevel${errorId}${i}-${col.field}`}
                                content={item[col.field]}
                            />
                        );
                    })}
                </Table.Row>
            );
        });
    }

    render() {
        return (
            <React.Fragment key={this.props.index}>
                <Table.Row key={`tplevel${this.props.index}-${this.props.error.payer_error_id}`}>
                    <Table.Cell content={this.render_inner_angle_icon(this.props.error.payer_error_id)} />
                    <Table.Cell style={{ textAlign: 'left' }} content={this.props.error.error_description} />
                    <Table.Cell style={{ textAlign: 'left' }} content={this.props.error.exclusions} />
                    <Table.Cell style={{ textAlign: 'left' }} content={this.props.error.last_updated_by} />
                    <Table.Cell style={{ textAlign: 'left' }} content={moment(this.props.error.last_updated_date).format('MM/DD/YYYY') + ' ' + moment(this.props.error.last_updated_date).format('hh:mm:ss A')} />
                </Table.Row>
                {this.state.show_payers &&
                    (<Table.Row key={`llevel${this.props.error.payer_error_id}-${this.props.index}`}>
                    <Table.Cell colSpan={12} className={'exclusion-report-table-cell'}>
                        <Table className={'exclusion-report-inner-table'}>
                            <Table.Header>
                                <Table.Row>
                                    {this.render_inner_header(this.props.index)}
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>{this.render_inner_rows(this.props.payers, this.props.index, this.props.error.payer_error_id)}</Table.Body>
                        </Table>
                    </Table.Cell>
                    </Table.Row>)
                }

            </React.Fragment>
        );
    }
}

// Get user and login details from store.
const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details
    };
};

export default connect(mapStateToProps)(ReportRow835Component);