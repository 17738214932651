import { header_template, headerStatusTemplate, statusStyle } from "./constants";

export const error_exclusions = {
    id: 'edit_835_exclusions_id',
    edit_header: '835 Error Exclusions',
    edit_detail: 'Edit Exclusions',
    base_url: '/admin',
    session_key: '835_error_exclusions',
    save_button_id: 'save_button_835'
}

export const default_excluded = 'Select Payers to be excluded';
export const number_excluded = ' Payers selected to be excluded';
export const not_selected = '--NOT SELECTED--';
export const no_error_selected = 'Please select an Error Description';
export const toastr_fail = 'Exclusions failed to update';

// #region UI Elements Name/Id
export const save_button = {
    id: 'pl_save_btn_id',
    name: 'Save'
}

export const preview_button = {
    id: 'pl_preview_btn_id',
    name: 'Print Preview'
}

export const name_textbox = {
    id: 'pl_name_id',
    name: 'Name'
}

export const global_checkbox = {
    id: 'pl_global_id',
    name: 'Global'
}

export const active_checkbox = {
    id: 'pl_active_id',
    name: 'Active'
}


export const alert = {
    message: 'This action will cause any unsaved data to be lost. Continue?',
    confirm_btn_name: 'Continue'
}
// #endregion UI Elements Name/Id


export interface IConfigurationSearchDataDto {
    payerDetailId: number;
    payerId: number;
    payerCode: string;
    description: string;
    outboundIdList: string;
    inboundIdList: string;
    isActive: boolean;
}
export interface IConfigurationDisplayDataDto {
    payer_detail_id: number;
    payerId: number;
    payer_code: string;
    description: string;
    outbound_id: string;
    inbound_id: string;
    is_active: boolean;
    row_count: any;
}

export interface IPayerError {
    payerId: number;
    errorCode: string;
    name: string;
    ruleLayer: string;
}

export interface IStateTemplate {
    // save variables
    show_save_alert: boolean;
    save_btn_disabled: boolean;
    //allow_save: boolean;
    //is_saved: boolean;
    save_on_error_selection_change: boolean;
    ruleLayer: string;
    adjustCode: string;
    is_saving: boolean;
    disable_print_and_export: boolean;
    unsaved_changes: boolean;


    // error selection variables
    error_descriptions: [];
    selected_error: Array<IPayerError>;
    new_selection: Array<IPayerError>;
    revert_selection: boolean;
    defaultValue: string;

    // other
    is_loading: {
        search_data: boolean;
    },
    report_data_loading: boolean;
    grid_rows_count: number;
    pagination_data: { row_count: any },
    current_page: number;
	footer_message: string;
	db_save_date: string;
    selected_message: string;
    refresh_report: boolean;
    return_selected: boolean;
    items_per_page: number;
    export_data: any[];
}

export const grid_settings =
{
    grid_id: 'grid_edit_exclusions',
    no_records_found_message: 'No Record Available',
    column_defs: [
        {
            headerName: "",
            field: "checkbox",
            headerCheckboxSelection: true,
            checkboxSelection: true,
            suppressFilter: true,
            suppressSorting: true,
            suppressSizeToFit: true,
            resizable: false,
            suppressMovable: true,
            minWidth: 40,
            maxWidth: 40,
            width: 40,
        },
        {
            headerName: 'Payer Code',
            headerComponentParams: header_template,
            field: 'payer_code',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            resizable: true,
            width: 140,
            suppressSizeToFit: true,
        },
        {
            headerName: 'Description',
            headerComponentParams: header_template,
            field: 'description',
            tooltipField: 'description',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            resizable: true,
            minWidth: 800,
        },
        {
            headerName: 'Outbound ID',
            headerComponentParams: header_template,
            field: 'outbound_id',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            resizable: true,
            width: 150,
            suppressSizeToFit: true
        //},
        //{
        //    headerName: 'Inbound ID',
        //    headerComponentParams: header_template,
        //    field: 'inbound_id',
        //    filter: 'textFilter',
        //    type: 'string',
        //    unSortIcon: true,
        //    resizable: true,
        //    width: 150,
        //    suppressSizeToFit: true
        //},
        //{
        //    headerName: 'Active',
        //    headerComponentParams: headerStatusTemplate,
        //    field: 'is_active',
        //    filter: 'checklistFilter',
        //    minWidth: 100,
        //    width: 120,
        //    type: 'string',
        //    unSortIcon: true,
        //    cellStyle: { textAlign: 'center' },
        //    cellRenderer: (params) => {
        //        if (params.data.is_active) {
        //            return `<i tabindex="0" aria-hidden="true" class="checkmark icon"></i>`;
        //        } else {
        //            return;
        //        }
        //    }
        }
    ],
}


