import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Icon, Modal, Transition } from 'semantic-ui-react';
import * as global_constant from './../../global_constants';
import * as Action from './../../shared/action/autosearch_action';
import AutoSearchComponent from './../../shared/component/auto_search_component';
import * as session_storage from '../../shared/session_storage_utility';
import { caseFormat, format_dashes_number, format_date, getContentAreaDimension, get_age, get_age_months, nevigate_to_patient_view, phone_format } from './../../shared/utility';
import { handle_click_on_enter, handle_click_on_enter_with_focus } from './../../shared/tab_navigation_utility';
import { set_active_patient } from '../../login/action/login_action';
export class PatientSearchModalComponent extends React.Component<any, any> {
    _is_mounted = false;
    constructor(props) {

        super(props);
        // Initialize state property here       
        this.state = {
            //shown: false,
            patientId: this.props.patientId,
            showModal: false,
            quick_search_error_message: '',
        };
    }
    componentWillUnmount = () => {
        this._is_mounted = false;
        if (this.props.onRef) {
            this.props.onRef(undefined);
        }
        //document.body.classList.remove('patient-subHeader', 'patient-header');
    }
    UNSAFE_componentWillMount = () => {
        // document.body.classList.add('patient-header');
    }
    componentDidMount() {
        this._is_mounted = true;
        if (this.props.onRef) {
            this.props.onRef(this);
        }
        //this.get_patient_header_details(this.props.patientId);
        //getContentAreaDimension();
    }
    handle_on_open_modal_window = () => {
        document.body.classList.add('application_header_patient_seach'); 
        setTimeout(() => {
            let closeIcons: NodeListOf<HTMLElement> = document.querySelectorAll('i.close.icon') as NodeListOf<HTMLElement>;
            let closeIcon = closeIcons[closeIcons.length - 1]
            closeIcon.tabIndex = 0;
            closeIcon.id = "app_header_search_modal_close_icon";

            // setting the focus for the first time.
            let auto_search_component = document.getElementById("patient_header_quick_search_input");
            auto_search_component.addEventListener('keydown', function (event) {
                if (event.keyCode == 9) {
                    event.preventDefault();
                    closeIcon.focus();
                }
            })

            closeIcon.addEventListener("keydown", function (event) {
                if (event.shiftKey && event.keyCode == 9) {
                    event.preventDefault();
                    auto_search_component.focus();
                }

                if (event.keyCode == 13) {
                    event.preventDefault();
                    var el = event.target as HTMLElement
                    el.click();
                }
            });
        }, 200)
    }
    onselection = (item) => {
        var navigate_to = `/patient/${item.title}`;
        this.props.set_active_patient(item.title);
        session_storage.set('active_patient', item.title);       
        var no_patient_selected_for_screen = session_storage.get('no_patient_selected_for');
        session_storage.set('no_patient_selected_for', null);
        if (nevigate_to_patient_view(this.props.location.pathname)) {
            if (this._is_mounted) {
                this.setState({ patientId: item.title });
            }
            this.closeModal();
            this.props.history.push(`${no_patient_selected_for_screen ? no_patient_selected_for_screen : navigate_to}`);
        } else {
            if (this._is_mounted) {
                this.setState({ patientId: item.title });
            }
            this.closeModal();
        }
    }

    //close the modal on selection of patient through quick search
    closeModal = () => {

        if (this._is_mounted) {
            document.body.classList.remove('application_header_patient_seach'); 
            this.setState({ showModal: false })

            // set focus on parent
            let parentEle = document.getElementById("app_header_search_patient_icon");
            parentEle.focus();
        }
    }

    is_webpt_company_type = () => {
        return (this.props.user_login_details.user_data.data.company_type_id == global_constant.a_company_type.Web_PT);
    }

    is_emr_id_valid = (emr_id): boolean => {
        let emr_id_valid = true;
        if (emr_id == undefined || emr_id == null || emr_id.trim().length == 0)
            emr_id_valid = false;

        return emr_id_valid;
    }

    get_age_display = (dob) => {
        let age_years: number, age_months: number, display_age: string;
        age_years = get_age(dob);
        age_months = get_age_months(dob);
        display_age = (isNaN(age_years) || age_years == 0 ? '' : age_years + 'y ') + (isNaN(age_months) || age_months == 0 ? '' : age_months + 'm')
        return display_age;
    }

    //Prepare suggestion list to show in Quick Search result
    prepareSuggestion = (data) => {
        let formattedList = [];
        let isCompanyTypeWebPT = this.is_webpt_company_type();
        if (data && data.data.length > 0) {
            data.data.map((item) => {
                formattedList.push({
                    "label": (item.last_name == null ? '' : item.last_name + ' ') + (item.middle_initial == null ? '' : item.middle_initial + ' ') + (item.first_name == null ? '' : item.first_name),
                    "title": item.id.toString(),
                    "title_display": isCompanyTypeWebPT && this.is_emr_id_valid(item.alt_record_id2) ? '(EMR ' + item.alt_record_id2 + ', Billing ' + item.id.toString() + ')' : '(Billing ' + item.id.toString() + ')',
                    "email": item.email,
                    "dob": (item.date_of_birth == null ? '' : format_date(new Date(item.date_of_birth), false)).toString(),
                    "age": (item.date_of_birth == null ? '' : isCompanyTypeWebPT ? this.get_age_display(item.date_of_birth) : get_age(item.date_of_birth) + ' Yrs'),
                    "gender": item.gender == null ? '' : item.gender,
                    "home": (item.phone.home == null || item.phone.home == '' ? '' : isCompanyTypeWebPT ? ', ' + phone_format(item.phone.home) + ' (H)' : ' H: ' + format_dashes_number(item.phone.home)),
                    "work": (item.phone.work == null || item.phone.work == '' ? '' : isCompanyTypeWebPT ? ', ' + phone_format(item.phone.work) + ' (W)' : ', W: ' + format_dashes_number(item.phone.work)),
                    "cell": (item.phone.cell == null || item.phone.cell == '' ? '' : isCompanyTypeWebPT ? ', ' + phone_format(item.phone.cell) + ' (C)' : ', M: ' + format_dashes_number(item.phone.cell)),
                });

                return;
            });
        }
        else {
            if (this._is_mounted) {
                this.setState({ quick_search_error_message: 'No Patient Found !' })
            }
        }
        return formattedList;

    }
    renderResult = (props) => {
        if (this.is_webpt_company_type()) {
            return (
                <div key={props.title} tabIndex={0} className="item_auto_search">
                    <span className="bold"><span style={{ color:'#007298'}}>{props.label + ' '}</span> {props.title_display}</span>
                    <div className="fs_13">
                        <span>{props.dob + ' '}({props.age}){props.gender == '' ? '' : ', ' + props.gender}</span>
                        <span>{props.home}</span>
                        <span>{props.cell}</span>
                        <span>{props.work}</span>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div key={props.title} tabIndex={0} className="item_auto_search">
                    <span className="bold">{props.label}</span>
                    <div className="fs_13">
                        <span>#{props.title + ' '}</span>
                        <span>{props.dob + ' '}({props.age}),</span>
                        <span>{props.home}</span>
                        <span>{props.cell}</span>
                        <span>{props.work}</span>
                    </div>
                </div>
            )

        }
        
    }
    getDataList = async (inputValue, companyid) => {
        var token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await Action.get_searchList(token, companyid, inputValue.trim(), global_constant.constants.Quick_Search_Suggestion_List_Size, global_constant.end_points.patient_urls.quick_search);

    }

    update_quick_search = () => {
        this.setState({ showModal: true })
    }

    render() {
        const { showModal } = this.state;

        return (

            <Modal
                onClose={this.closeModal}
                open={showModal}
                className="patient-modal"
                onOpen={this.handle_on_open_modal_window}
                trigger={<span> <img src={'/src/assets/images/search.png'} id="app_header_search_patient_icon" tabIndex={0} onKeyDown={handle_click_on_enter} aria-hidden="true" onClick={this.update_quick_search} /> </span>}
                closeIcon
                closeOnDimmerClick={true}
            >
                <Transition visible={true} animation='scale' duration={500}>
                    <Modal.Content>

                        <Modal.Description>
                            <AutoSearchComponent control_id="patient_header_quick_search_input" errorMessage={this.state.quick_search_error_message} getList={this.getDataList} prepareRenderList={this.renderResult} prepareDataList={this.prepareSuggestion}
                                selectresult={this.onselection} is_focus={true} is_disabled={false} show_clear_search={false} />

                        </Modal.Description>
                    </Modal.Content>
                </Transition>
            </Modal>
        );
    }

}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        set_active_patient: set_active_patient,
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
    };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientSearchModalComponent))