import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Dimmer, Form, Grid, Input, Loader } from 'semantic-ui-react';
import * as export_print_utility from '../../../reports/util/export_print_utility';
import GridView from '../../../shared/component/grid';
import * as session_storage from '../../../shared/session_storage_utility';
import { set_focus_on_element_with_id, set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import { phone_format, format_zip_code, get_columns, get_lauch_darkley_key_value } from '../../../shared/utility';
import * as constants_actionss from '../action/constants_action';
import * as constants from '../constants';
import FeatureFlipUtils from '../../../shared/feature_flip_helper';
import { toastr as toaster } from 'react-redux-toastr';
import * as global_constants from '../../../global_constants';
import { log_error } from '../../../shared/action/shared_action';
import * as local_storage from '../../../shared/local_storage_utility';
import { is_user_partner_customer_and_company_revEquip_revServe } from "./../../../shared/utility"
import * as ReportConstants from '../../../reports/report_constants';
import * as FF from '../../../feature_flip_constant';

class LocationSearchComponent extends React.Component<any, any> {
    advance_controls: any;
    constructor(props) {
        super(props);
        // Define initial state value.
        this.state = {
            show_grid: false,
            show_render: true,
            locations_search_criteria: [],
            is_loading: false,
            shown: false,
            grid_rows_count: 0
        };
    }

    // Define initial properties and its value.
    _is_mounted = false;
    page_name: string = 'locations';
    page_metadata: any;
    unlisten: any;
    locations_search_criteria: any = {
        location_code: '',
        location_name: '',
        show_inactive: false
    };
    initial_search_criteria: any = {
        location_code: '',
        location_name: '',
        show_inactive: false
    };
    token: string = '';
    row_data = [];
    code_id: number = 0;
    is_grid_visible = false;
    title = '';
    current_date: any;
    company_name: any;
    total_column_width: number = 0;
    grid_header_height: number = 0;
    current_user_type: global_constants.enum_user_type_r3_id = 1;
    company_service_type = 0;
    reportId: string = "0";

    // Fetching the page_name and state.
    UNSAFE_componentWillMount = () => {
        this.get_page_metadata();
        var auth_data = local_storage.get("auth_data");
        if (auth_data) {
            this.current_user_type = auth_data.user_type_r3_id;
            this.company_service_type = auth_data.company_service_type;
        }

        this.unlisten = this.props.history.listen(() => {
            this.get_page_metadata();
        });
    };

    componentDidMount = () => {
        document.body.classList.add('admin-framework');
        let elem = document.getElementById('search-criteria-container');
        let all_form_elem = elem.getElementsByTagName('input');
        all_form_elem[0].focus();
        this.handle_search_button_tab();
    };

    componentWillUnmount = () => {
        this.unlisten();
        document.body.classList.remove('admin-framework');
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[2] !== 'locations' && session_storage.remove('locations_search_criteria');
        }
    };

    //Use to fetch params and page metadata
    get_page_metadata = () => {
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        //Verifying page_name is correct or not.
        this.page_metadata = constants.locations;

        this.page_metadata.column_def = this.props.features.LocationClaimHolds ? this.page_metadata.column_def : this.page_metadata.column_def.filter(c => c.field !== "claim_holds");

        this.locations_search_criteria = session_storage.get('locations_search_criteria') || this.locations_search_criteria;
        if (!this.locations_search_criteria) {
            this.clear_handler(null);
        }
        if (session_storage.get('locations_search_criteria')) {
            this.search_handler(this);
        }
        this.setState({
            show_render: true
        });
    };

    // Data formatter
    format_data = (rows, columns) => {
        let formatted_data = rows.map(row_data => {
            columns.filter(col_data => {
                switch (col_data.type) {
                    case 'currency':
                        row_data[col_data.field] =
                            row_data[col_data.field] != null || row_data[col_data.field] != undefined
                                ? constants.currencyFormatter(row_data[col_data.field])
                                : constants.currencyFormatter(0);
                        break;
                    case 'boolean':
                        if (col_data.field === 'apply_tax' || col_data.field === 'supply_only_charge_entry') {
                            row_data[col_data.field] = row_data[col_data.field] ? true : false;
                        } else {
                            row_data[col_data.field] = row_data[col_data.field] ? 'Active' : 'Inactive';
                        }
                        break;
                    case 'number':
                        row_data[col_data.field] = row_data[col_data.field];
                        break;
                    case 'string':
                        row_data[col_data.field] = row_data[col_data.field];
                        break;
                    case 'phone':
                        row_data[col_data.field] = row_data[col_data.field] ? phone_format(row_data[col_data.field]) : row_data[col_data.field];
                        break;
                    case 'zip':
                        row_data[col_data.field] = row_data[col_data.field] ? format_zip_code(row_data[col_data.field]) : row_data[col_data.field];
                        break;
                    default:
                        row_data[col_data.field] = row_data[col_data.field];
                }
            });
            return row_data;
        });
        return formatted_data;
    };

    //On double click of grid, it redirects user to view page.
    on_row_double_clicked = selected_row_data => {
        this.code_id = selected_row_data['place_of_service_id'];
        this.props.history.push(`${this.page_metadata.base_url}/${this.page_name}/${this.code_id}`);
    };

    //Input change handler.
    input_change_handler = e => {
        const { value, checked, type, name } = e.target;
        if (type == 'checkbox') {
            this.locations_search_criteria[name] = checked;
        } else {
            this.locations_search_criteria[name] = value;
        }
        this.setState({
            locations_search_criteria: this.locations_search_criteria
        });
    };

    //Search button handler and used to search the data on the basis of search criteria.
    search_handler = e => {
        var url = this.page_metadata.api.search.url;
        for (let key in this.locations_search_criteria) {
            url = url.replace(
                key + '_value',
                typeof this.locations_search_criteria[key] !== 'undefined' ? encodeURIComponent(this.locations_search_criteria[key].toString().trim()) : ''
            );
        }
        this.setState({
            is_loading: true,
            is_search_button_disabled: true
        });
        constants_actionss.get_search_data(this.token, url).then(

            response => {
                if (response.data) {
                    this.row_data = response.data.data ? response.data.data : [];
                    this.row_data = this.format_data(this.row_data, this.page_metadata.column_def);
                    const grid_height = get_columns(this.row_data, this.page_metadata.column_def);
                    this.total_column_width = grid_height.total_column_width;
                    this.grid_header_height = grid_height.header_height;
                    this.setState({
                        show_grid: true,
                        is_loading: false,
                        locations_search_criteria: this.locations_search_criteria,
                        grid_rows_count: this.row_data && this.row_data.length,
                        is_search_button_disabled: false
                    });
                    session_storage.set('locations_search_criteria', this.locations_search_criteria);
                } else {
                    if (response.data.messages[0].message.indexOf('<br') > -1) {
                        this.show_html_content_toaster(response.data.messages[0].message);
                    } else {
                        toaster.error('', response.data.messages[0].message);
                    }
                    if (this._is_mounted) {
                        this.setState({
                            loading: false,
                            is_search_button_disabled: false
                        });
                    }
                }
            },
            error => {
                this.setState({
                    is_loading: false,
                    is_search_button_disabled: false
                });
                log_error(error);
                if (error.data.messages[0].message.indexOf('<br') > -1) {
                    this.show_html_content_toaster(error.response.data.messages[0].message);
                } else {
                    toaster.error('', error.response.data.messages[0].message);
                }
            }
        );
    };

    // Show multiple messages
    show_html_content_toaster = msg => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ),
            timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        };
    };

    //Reset button the search control.
    clear_handler = e => {
        this.locations_search_criteria = { ...this.initial_search_criteria };
        this.setState({
            show_grid: false,
            shown: false,
            locations_search_criteria: this.initial_search_criteria,
            grid_rows_count: 0
        });
        this.is_grid_visible = false;
        session_storage.remove('locations_search_criteria');
    };

    //New button handler.
    new_handler = e => {
        this.props.history.push(`${this.page_metadata.base_url}/${this.page_name}`);
    };

    //Function calls on initialization of Ag-Grid and catch its reference.
    get_grid_ref = grid_params => {
        this.setState({
            grid_params,
            grid_rows_count: grid_params.api.getModel().getRowCount()
        });
        var grid_width = document.getElementsByClassName('admin-wrapper')[0].clientWidth;
        if (this.total_column_width > grid_width) {
            this.page_metadata.column_def.filter(item => {
                grid_params.columnApi.setColumnWidth(item.field, item.width, false);
            });
        }
    };

    //Used to create the title for export and print.
    set_title = separter => {
        this.company_name = this.props.user_login_details.user_data.data.company_name;
        this.current_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
        this.title = this.page_metadata.search_header;
        return this.title =
            this.title.replace(/<br>/g, separter) +
            separter +
            this.company_name +
            separter +
            this.current_date +
            separter +
            (this.state.grid_rows_count == 1
                ? this.state.grid_rows_count + ' record'
                : this.state.grid_rows_count + ' records') +
            separter +
            separter;
    };

    //Function calls on initialization of export report data
    on_export_button = () => {
        this.title = this.set_title('\n');
        this.page_name = this.page_name.replace(/<br>/g, '');
        export_print_utility.export_grid_data(this.state.grid_params, this.title, this.page_name);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Export);
    };

    //Function calls on initialization of Print report data
    on_print_button = () => {
        this.title = this.set_title('<br>');
        export_print_utility.print_grid_data(this.state.grid_params, this.title, this.page_name);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
    };

    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;

        const payload: ReportConstants.IPayloadForBillingAudit = {
            reportId: this.reportId,
            contextTitle: `${this.page_metadata.search_header} - ${ReportConstants.ReportEventAction[eventActionId]}`,
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: ReportConstants.ReportEntityTypeId.C_PLACE_OF_SERVICE,
            data: { Records: this.state.grid_params.api.rowModel.rowsToDisplay.length }
        };

        const reqBody = export_print_utility.generateAuditDataEntryPayload(payload);
        ReportConstants.saveAuditPrintExport(reqBody, accessToken);
    };

    on_grid_out = () => {
        set_focus_on_element_with_id('app_header_dropdown');
    };

    handle_search_button_tab = () => {
        setTimeout(() => {
            let searchButton = document.getElementById('search_button_id');
            let self = this;
            searchButton.addEventListener('keydown', function (event) {
                let keyCode = event.keyCode || event.which;

                // apply a check where grid is empty
                if (!event.shiftKey && keyCode == 9 && !self.state.show_grid) {
                    set_focus_to_app_header(event);
                }
                if (!event.shiftKey && keyCode == 9 && (self.row_data == null && self.row_data.length == 0 && !self.state.show_grid)) {
                    set_focus_to_app_header(event);
                }
            });
        }, 200);
    };

    update_report_rows_count = () => {
        this.setState({
            grid_rows_count: this.state.grid_params.api.getModel().getRowCount()
        });
    };

    //It renders report grid and its search controls.
    render() {
        return (
            this.state.show_render && (
                <React.Fragment>
                    <Dimmer active={this.state.is_loading}>
                        <Loader size='massive'>Loading</Loader>
                    </Dimmer>
                    <div className={'admin-wrapper ' + this.page_metadata.id} style={!this.state.show_grid ? { paddingBottom: 20 } : {}}>
                        <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                            <Grid.Column computer={16}>
                                <h3 className='ui header left aligned' dangerouslySetInnerHTML={{ __html: this.page_metadata.search_header }} />
                            </Grid.Column>
                        </Grid>
                        <div id='admin-scrollable-area' className='wrapper' style={this.state.show_grid ? { flex: 1 } : {}}>
                            <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container'>
                                <Form autoComplete='off' onSubmit={e => this.search_handler(e)}>
                                    <Grid>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Location Code</label>
                                                <Input
                                                    autoComplete='off'
                                                    name='location_code'
                                                    id='location_code'
                                                    maxLength={50}
                                                    onChange={e => this.input_change_handler(e)}
                                                    value={this.locations_search_criteria['location_code'] ? this.locations_search_criteria['location_code'] : ''}
                                                    type='text'
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Location Name</label>
                                                <Input
                                                    autoComplete='off'
                                                    name='location_name'
                                                    id='location_name'
                                                    maxLength={26}
                                                    onChange={e => this.input_change_handler(e)}
                                                    value={this.locations_search_criteria['location_name'] ? this.locations_search_criteria['location_name'] : ''}
                                                    type='text'
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field className='ui checkbox'>
                                                <div style={{ marginTop: 32 }}>
                                                    <input
                                                        type='checkbox'
                                                        name='show_inactive'
                                                        id='show_inactive'
                                                        onChange={e => this.input_change_handler(e)}
                                                        checked={this.locations_search_criteria['show_inactive'] ? this.locations_search_criteria['show_inactive'] : false}
                                                    />{' '}
                                                    <label className='chkbx_label' htmlFor='show_inactive'>
                                                        Include Inactive
                                                    </label>
                                                </div>
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid style={{ margin: '0 -17px' }}>
                                        <Grid.Column computer={16} textAlign='right'>
                                            <Button id='clear_button_id' type='button' onClick={e => this.clear_handler(e)} basic>
                                                Clear
                                            </Button>
                                            {is_user_partner_customer_and_company_revEquip_revServe(this.company_service_type, this.current_user_type) ? null :
                                                <Button id='new_button_id' type='button' onClick={e => this.new_handler(e)} basic>
                                                    New
                                                </Button>
                                            }
                                            <Button id='search_button_id' type='submit' primary disabled={this.state.is_search_button_disabled}>
                                                Search
                                            </Button>
                                        </Grid.Column>
                                    </Grid>
                                    {this.state.show_grid && (
                                        <Grid style={{ marginTop: '-1rem', marginBottom: 0 }}>
                                            <Grid.Column tablet={5} computer={4} textAlign='left'>
                                                <p style={{ fontSize: '16px' }}>Search Results</p>
                                            </Grid.Column>
                                            <Grid.Column tablet={5} computer={8} textAlign='center'>
                                                {
                                                    <p style={{ fontSize: '16px', minHeight: 22 }}>
                                                        {`${this.state.grid_rows_count} ${this.state.grid_rows_count == 1 ? ' record shown' : ' records shown'
                                                            }`}
                                                    </p>
                                                }
                                            </Grid.Column>
                                            <Grid.Column tablet={2} computer={4} textAlign='right' />
                                        </Grid>
                                    )}
                                </Form>
                            </div>
                            {this.state.show_grid && !this.state.is_loading && (
                                // TODO: Purge B-28286 FF
                                <GridView
                                    id={this.page_name}
                                    row={this.row_data}
                                    column={this.page_metadata.column_def}
                                    style={{ height: '100%' }}
                                    wrapperStyle={{ width: '100%', height: '100%', display: 'flex' }}
                                    suppressMovableColumns={false}
                                    enableColResize={true}
                                    onRowDoubleClicked={this.on_row_double_clicked}
                                    selectionType={'single'}
                                    get_grid_ref={this.get_grid_ref}
                                    suppressSizeToFit={true}
                                    headerHeight={this.grid_header_height}
                                    headerIdForTabNavigation={constants.search_grid_id}
                                    onForceGridOut={this.on_grid_out}
                                    on_filter_button_click={() => this.update_report_rows_count()}
                                />
                            )}
                        </div>
                        {this.state.show_grid && (
                            <div
                                className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                                id='applicationFooterSticky'
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                                <Grid.Column computer={16} textAlign='right'>
                                    <Button id='export_report_button' type='submit' onClick={this.on_export_button} basic>
                                        Export
                                    </Button>
                                    <Button onKeyDown={set_focus_to_app_header} id='print_report_button' type='submit' onClick={this.on_print_button} primary>
                                        Print
                                    </Button>
                                </Grid.Column>
                            </div>
                        )}
                    </div>
                </React.Fragment>
            )
        );
    }
}

//Get user and login details from store.
// TODO: Purge B-28286 FF
const mapStateToProps = state => {
    return {
        selected_patient: state.patient_details.patient_header,
        user_login_details: state.user_login_details,
        features: {
            LocationClaimHolds: get_lauch_darkley_key_value(state.launch_darkly, FF.leviathan_bill_3434_location_credential_claim_holds_r6),
        }
    };
};

export default connect(mapStateToProps)(LocationSearchComponent);