import * as React from 'react';
import { connect } from 'react-redux';
import { toastr as toaster } from 'react-redux-toastr';
import MaskedInput from 'react-text-mask';
import { Button, Dimmer, Form, Grid, Header, Loader } from 'semantic-ui-react';
import * as global_constants from '../../../global_constants';
import GridView from '../../../shared/component/grid';
import PromptNavigation from '../../../shared/component/prompt_navigation_component';
import Selection from '../../../shared/component/selection_component';
import * as session_storage from '../../../shared/session_storage_utility';
import { set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import { custom_date_format, get_all_error, get_lauch_darkley_key_value, is_null_or_white_space, nullifyString, toBoolean, unmask } from '../../../shared/utility';
import * as constant_action from '../action/constants_action';
import * as constants from '../constants';
import * as local_storage from '../../../shared/local_storage_utility';
import {kraken_bill_333_remove_and_replace_medicare_crossover_on_payers_page } from '../../../feature_flip_constant';

class PayerAddEditComponent extends React.Component<any, any> {
  [x: string]: any;
  _is_mounted = false;
  constructor(props) {
    super(props);
    // Define initial state value.
    this.state = {
      patient_master_data: this.props.user_login_details.formated_master_data,
      show_render: true,
      form_object: {},
      is_loading: false,
      is_submitted: false,
      error: {},
      grid_conf: {
        isPagination: true,
        gridAutoHeight: true,
        selectionType: 'single',
        paginationPageSize: 20
      },
      by_pass_prompt: false,
      payer_groups: [],
      company_specific_overrides_payer_group_list: [],
      insurance_rows_data: [],
      is_save_button_disabled: false
    };
  }

  // Define initial properties and its value.
  page_name: string = '';
  page_metadata: any;
  token: string = '';
  form_object: any = {};
  code_id: 0;
  payers_search_criteria: any = {
    description: '',
    name: '',
    outbound_Id: '',
    show_inactive: false
  };
  page_state: string = '';
  initial_form_object: any = {};
  go_next: boolean = false;
  by_pass_prompt: boolean = false;
  payer_ins_code_detail: any = [];
  payer: any = {};
  cross_company_settings: any = {};
  company_specific_overrides: any = {};
  insurance_item: any = [];
  selected_insurance_item: any = [];
  overridable_field = [];
  company_service_type = 0;

  // Fetching the page_name and state.
  UNSAFE_componentWillMount = () => {
    this._is_mounted = true;
    const auth_data = local_storage.get('auth_data');
    if (auth_data) {
        this.company_service_type = auth_data.company_service_type;
    }
    this.token = this.props.user_login_details.user_data.data.token_details.access_token;
    this.page_name =
    this.props.match.params && this.props.match.params.page_name ? this.props.match.params.page_name : '';
    this.code_id = this.props.match.params && this.props.match.params.id ? this.props.match.params.id : 0;
    this.set_overridable_field_by_feature_flip(this.props.krakenBill333RemoveAndReplaceMedicareCrossoverOnPayersPage);
    this.page_metadata = JSON.parse(JSON.stringify(constants.payer));
    this.page_metadata.control_data = this.filter_control_data(this.page_metadata.control_data, this.props.krakenBill333RemoveAndReplaceMedicareCrossoverOnPayersPage);
    this.set_default_form_data(this.page_metadata.control_data);
    if (this.props.rsiB31486AddCompanyMultiSelectDropDownToPayersPage) {
      this.get_payer_group_by_company();
      this.get_payer_group(false);
   } else {
    this.get_payer_group();
   }

    if (this.code_id) {
      this.get_data(this.code_id);
    } else {
      this.setState({
        form_object: this.form_object
      });
    }
  };

  componentDidMount = () => {
    this._is_mounted = true;
    document.body.classList.add('admin-framework');
    let elem = document.getElementById('search-criteria-container');
    let all_form_elem = elem.getElementsByTagName('input');
    all_form_elem[0].focus();
  };

  componentWillUnmount = () => {
    this._is_mounted = false;
    document.body.classList.remove('admin-framework');
    if (this.props.history.location) {
      var route_array = this.props.history.location.pathname.split('/');
      route_array && route_array[2] !== 'payer' && session_storage.remove('payers_search_criteria');
    }
  };

  filter_control_data(control_data, denies_medicare_ff: boolean)
  { 
      let filtered_control_data = [];
      if (denies_medicare_ff) {
          filtered_control_data = control_data.filter((item) => {
              if (item.name != 'medicare_crossover') {
                  return item;
              }
          });
      }
      else {
          filtered_control_data = control_data.filter((item) => {
              if (item.name != 'denies_crossover') {
                  return item;
              }
          });
      }
      return filtered_control_data;
    }

    set_overridable_field_by_feature_flip = (denies_medicare_ff:boolean) =>
    {
        if (denies_medicare_ff) {
            this.overridable_field = 
                [
                    'company_specific_overrides_denies_crossover',
                    'company_specific_overrides_accepts_primary_paper_claims',
                    'company_specific_overrides_accepts_secondary_paper_claims',
                    'company_specific_overrides_accepts_secondary_claims_electronically',
                    'company_specific_overrides_include_on_277_rpt',
                    'company_specific_overrides_treatment_notes_required'
                ];
        }
        else
        {
            this.overridable_field =
                [
                    'company_specific_overrides_medicare_crossover',
                    'company_specific_overrides_accepts_primary_paper_claims',
                    'company_specific_overrides_accepts_secondary_paper_claims',
                    'company_specific_overrides_accepts_secondary_claims_electronically',
                    'company_specific_overrides_include_on_277_rpt',
                    'company_specific_overrides_treatment_notes_required'
                ];
        }   

    }


  get_page_state = () => {
    if (this.code_id) {
      return 'edit';
    } else {
      return 'add';
    }
  };

  // Get Payer Group
  get_payer_group = (fetch_company_specific:any=true) => {
    let url = this.page_metadata.api.payer_groups.api;
    constant_action.get_search_data(this.token, url).then(
      res => {
        if (res.data) {
          let payer_groups = constants.option_formater(
            res.data.data,
            'payer_group_name',
            'payer_group_id',
            false, // to add blank object
            false // to shorted object
          );
          payer_groups.unshift({ text: 'NONE', value: 0 });
          var company_specific_payer_group_list = payer_groups;

          var old_user_data = local_storage.get("auth_data");
          if (old_user_data) {
            this.company_service_type = old_user_data.company_service_type;
          }
          if (this.company_service_type == global_constants.CompanyServiceType.SoftwareOnly) {
            company_specific_payer_group_list = [];
            let software_only_data = res.data.data.filter((item) => {
              if (item.is_software_only) {
                return item;
              }
            });
            company_specific_payer_group_list = constants.option_formater(
              software_only_data,
              'payer_group_name',
              'payer_group_id',
              false, // to add blank object
              true // to shorted object
            );
            company_specific_payer_group_list.unshift({ text: 'NONE', value: 0 });
          }
          let state_data = {};
          if(fetch_company_specific) {
            state_data = {
              payer_groups: payer_groups,
              company_specific_overrides_payer_group_list: company_specific_payer_group_list,
              is_loading: false
            }  
          } else {
            state_data = {
              payer_groups: payer_groups,
              is_loading: false
            }   
          }

          if (this._is_mounted) {
            this.setState(state_data);
          }
        }
      },
      error => {
        if (this._is_mounted) {
          this.setState({
            is_loading: false
          });
        }
        if (error.response && error.response.data && error.response.data.messages) {
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error('', toastr_options);
        }
      }
    );
  };

  // Get Payer Group
  get_payer_group_by_company = () => {
    var user_data = local_storage.get("auth_data");
    let params = {
      company_id : user_data.company_id,
      showInactive: false
    }
    constant_action.get_payer_group_by_company(this.token, params).then(
      res => {
        if (res.data) {
          let payer_groups = constants.option_formater(
            res.data.data,
            'payer_group_name',
            'payer_group_id',
            false, // to add blank object
            false // to shorted object
          );
          payer_groups.unshift({ text: 'NONE', value: 0 });
          var company_specific_payer_group_list = payer_groups;


          if (user_data) {
            this.company_service_type = user_data.company_service_type;
          }
          if (this.company_service_type == global_constants.CompanyServiceType.SoftwareOnly) {
            company_specific_payer_group_list = [];
            let software_only_data = res.data.data.filter((item) => {
              if (item.is_software_only) {
                return item;
              }
            });
            company_specific_payer_group_list = constants.option_formater(
              software_only_data,
              'payer_group_name',
              'payer_group_id',
              false, // to add blank object
              true // to shorted object
            );
            company_specific_payer_group_list.unshift({ text: 'NONE', value: 0 });
          }

          if (this._is_mounted) {
            this.setState({
              company_specific_overrides_payer_group_list: company_specific_payer_group_list,
              is_loading: false
            });
          }
        }
      },
      error => {
        if (this._is_mounted) {
          this.setState({
            is_loading: false
          });
        }
        if (error.response && error.response.data && error.response.data.messages) {
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error('', toastr_options);
        }
      }
    );
  };

  // Get the data on the basis of ID.
  get_data = code_id => {
    let url = this.page_metadata.api.get.url;
    url = url.replace('id_value', code_id);
    if (this._is_mounted) {
      this.setState({
        is_loading: true
      });
    }
    constant_action.get_data(this.token, url).then(
      response => {
        if (response.data && response.data.data) {
          this.payer = { ...response.data.data.payer };
          this.cross_company_settings = { ...response.data.data.cross_company_settings };
          this.company_specific_overrides = response.data.data.company_specific_overrides
            ? {
              ...response.data.data.company_specific_overrides
            }
            : this.company_specific_overrides;
          this.payer_ins_code_detail = response.data.data.payer_ins_code_detail;
          this.form_object = {
            ...this.merge_objects(response.data.data.payer, 'payer_'),
            ...this.merge_objects(response.data.data.cross_company_settings, 'cross_company_settings_'),
            ...this.merge_objects(response.data.data.company_specific_overrides, 'company_specific_overrides_'),
            payer_ins_code_detail: this.payer_ins_code_detail
          };

          this.form_object = this.format_object(this.form_object, this.page_metadata.control_data);
          this.initial_form_object = { ...this.form_object };
          if (this._is_mounted) {
            this.setState({
              form_object: { ...this.form_object },
              insurance_rows_data: this.payer_ins_code_detail,
              is_loading: false
            });
          }
        }
      },
      error => {
        if (this._is_mounted) {
          this.setState({
            is_loading: false
          });
        }
        if (error.response && error.response.data && error.response.data.messages) {
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error('', toastr_options);
        }
      }
      );
  };

  // Data formatter
  format_object = (data_object, control_data) => {
    for (var field in data_object) {
      control_data.filter(data => {
        if (data.name == field) {
          if (data.format) {
            data_object[field] = data_object[field]
              ? custom_date_format(data_object[field], 'mm/dd/yyyy h:MM:ss TT')
              : data_object[field];
          }
        }
      });
    }
    return data_object;
  };

  merge_objects = (obj, new_key) => {
    if (obj) {
        return Object.keys(obj).reduce((result, key) => {
        result[new_key + key] = obj[key];
        return result;
      }, {});
    } else {
      return {};
    }
  };

  // Function used to set the input controls.
  set_default_form_data = control_data => {
    control_data.map(data => {
      let name = `${data.form_name}_${data.name}`;
      if (data.is_edit) {
        if (this._is_mounted) {
          this.setState(({ error }) => ({
            error: {
              ...error,
              [name]: false
            }
          }));
        }
        switch (data.control_type) {
          case 'checkbox':
            this.form_object[name] = data.default_value ? true : false;
            break;
          case 'text':
            this.form_object[name] = '';
            break;
          case 'days':
            this.form_object[name] = '';
            break;
          case 'drop_down_list':
            this.form_object[name] = data.default_value;
            break;
          default:
            this.form_object[name] = '';
        }
      }
    });
    this.initial_form_object = { ...this.form_object };
    if (this._is_mounted) {
      this.setState({
        form_object: this.form_object
      });
    }
  };

  //Set the default value to controls.
  set_controls = data_row => {
     let name = `${data_row.form_name}_${data_row.name}`;
        let disabled_control = false;
        if (this.page_state == 'edit' && (data_row.form_name == "cross_company_settings" || data_row.form_name == "payer")) {
            if (this.company_service_type == global_constants.CompanyServiceType.SoftwareOnly) {
                disabled_control = true;
            }
        }
    switch (data_row.control_type) {
      case 'drop_down_list':
        return (
          <Form.Field>
            {data_row.label && (
              <label>
                {data_row.label}
                {data_row.is_required && (
                  <span
                    className={
                      !this.state.form_object[name] &&
                        data_row.is_required &&
                        this.state.is_submitted &&
                        this.state.error[name]
                        ? 'req-alert'
                        : 'req-alert_normal'
                    }
                  >
                    {' '}
                    (required)
                  </span>
                )}
              </label>
            )}
            <Selection
                    id={name}
                    name={name}
                    options={name.indexOf('_payer_group') > -1 ? (name.indexOf('company_specific_overrides_payer_group') > -1 ? this.state.company_specific_overrides_payer_group_list : this.state.payer_groups) : data_row.master_data}
                    onChange={(value, e) => {
                        this.dropdown_change_handler(e);
                    }}
                    defaultValue={this.default_value_dropdown(data_row, this.state.form_object[name])}
                    style={
                        !this.state.form_object[name] &&
                            data_row.is_required &&
                            this.state.is_submitted &&
                            this.state.error[name]
                            ? 'dropdown-options-wrap req-background-inp'
                            : 'dropdown-options-wrap'
                    }
                    disabled={disabled_control}
            />
          </Form.Field>
        );
      case 'checkbox':
        return (
          <Form.Field className='ui checkbox'>
            <div style={data_row.custom_style}>
              <input
                disabled={disabled_control}
                type='checkbox'
                id={name}
                name={name}
                checked={this.state.form_object[name] ? true : false}
                onChange={e => this.checkbox_handler(e)}
              />
              <label className='chkbx_label' htmlFor={name}>
                {data_row.label}
              </label>
            </div>
          </Form.Field>
        );
      case 'label':
        return (
          <Form.Field>
            {data_row.label && <label>{data_row.label}</label>}
            <label className={'values'}>{this.state.form_object[name] ? this.state.form_object[name] : ''}</label>
          </Form.Field>
        );
      case 'days':
        return (
          <Form.Field>
            {data_row.label && (
              <label>
                {data_row.label}
                {data_row.is_required && (
                  <span
                    className={
                      !this.state.form_object[name] &&
                        data_row.is_required &&
                        this.state.is_submitted &&
                        this.state.error[name]
                        ? 'req-alert'
                        : 'req-alert_normal'
                    }
                  >
                    {' '}
                    (required)
                  </span>
                )}
              </label>
            )}
            <MaskedInput
              mask={[/\d/, /\d/, ',', /\d/, /\d/, /\d/]}
              className={
                !this.state.form_object[name] &&
                  data_row.is_required &&
                  this.state.is_submitted &&
                  this.state.error[name]
                  ? 'form-control req-background-inp'
                  : 'form-control'
              }
              placeholder='__,___'
              guide={true}
              id={name}
              name={name}
              autoComplete='new-password'
              showMask={true}
              disabled={disabled_control}
              onChange={e => this.input_change_handler(e)}
              value={this.state.form_object[name] ? this.state.form_object[name] : ''}
            />
            {data_row.is_required_text && this.state.is_submitted && this.state.is_error_on_change[name] && (
              <div className='requiredText'>{data_row.is_required_msg}</div>
            )}
          </Form.Field>
        );
      default:
        return (
          <Form.Field
            className={
              !this.state.form_object[name] && data_row.is_required && this.state.is_submitted && this.state.error[name]
                ? 'requiredWithBgColor error'
                : ''
            }
          >
            {data_row.label && (
              <label>
                {data_row.label}
                {data_row.is_required && (
                  <span
                    className={
                      !this.state.form_object[name] &&
                        data_row.is_required &&
                        this.state.is_submitted &&
                        this.state.error[name]
                        ? 'req-alert'
                        : 'req-alert_normal'
                    }
                  >
                    {' '}
                    (required)
                  </span>
                )}
              </label>
            )}
            <input
              maxLength={data_row.max_length ? data_row.max_length : 50}
              name={name}
              id={name}
              type={data_row.control_type}
              value={this.state.form_object[name] ? this.state.form_object[name] : ''}
              onChange={e => this.input_change_handler(e)}
              disabled={!!this.code_id && data_row.non_editable || disabled_control}
              pattern={data_row.pattern}
              autoComplete='new-password'
            />
            {data_row.is_required_text && this.state.is_submitted && this.state.error[name] && (
              <div className='requiredText'>{data_row.is_required_msg}</div>
            )}
            {data_row.help_text && <div style={payers_css.help_text}>{data_row.help_text}</div>}
          </Form.Field>
        );
    }
  };

    default_value_dropdown = (data, value) => {
      if (this.overridable_field.indexOf(`company_specific_overrides_${data.name}`) > -1) {
          if (this.company_specific_overrides[`ov_${data.name}`]) {
              if (this.form_object[`${data.form_name}_${data.name}`]) {
                  return true;
              } else {
                  return false;
              }
          } else {
              this.cross_company_settings[`ov_${data.name}`] = false;
              this.company_specific_overrides[`ov_${data.name}`] = false;
              return 'default';
          }
      } else if (!this.cross_company_settings['payer_group_id'] && !this.form_object.cross_company_settings_payer_group_id) {
          this.form_object.cross_company_settings_payer_group_id = 0;
          this.cross_company_settings['payer_group_id'] = 0;
          return value ? value : 0;
      } else {
          return value ? value : '';
      }
  };

  // Dropdown change handler.
    dropdown_change_handler = e => {
        const { value, name } = e.target;
    if (this.overridable_field.indexOf(name) > -1) {
        let ov_name = name.replace('company_specific_overrides_', 'ov_');
        if (value === 'default') {
            this.cross_company_settings[ov_name] = false;
            this.company_specific_overrides[ov_name] = false;
        } else if (value !== 'default') {
            this.cross_company_settings[ov_name] = true;
            this.company_specific_overrides[ov_name] = true;
            this.form_object[name] = JSON.parse(value);
        }
        
    } else {
      this.form_object[name] = value == '' ? null : value;
    }

    if (this._is_mounted) {
      this.setState({
        error: {
          ...this.state.error,
          [name]: false
        },
        form_object: this.form_object
      });
    }
  };

  // Input change handler.
  input_change_handler = e => {
    const { value, name } = e.target;
    let error = this.state.error;
    if (e.target.validity.valid) {
      error[name] = this.validate_value(value, name);
    }
    if (this._is_mounted) {
      this.setState(prev_state => ({
        error: {
          ...prev_state.error,
          [name]: error[name]
        },
        form_object: this.form_object
      }));
    }
  };

  // Checkbox checkbox handler.
  checkbox_handler = e => {
    const { checked, name } = e.target;
    this.form_object[name] = JSON.parse(checked);
    if (this._is_mounted) {
      this.setState({
        form_object: {
          ...this.form_object
        }
      });
    }
  };

  // validate each input box on change event
    validate_value = (value, name) => {
    let error = false;
    switch (name) {
      case 'cross_company_settings_days_to_999':
      case 'company_specific_overrides_days_to_999':
      case 'cross_company_settings_days_to_277':
      case 'company_specific_overrides_days_to_277':
        value = value && value.length > 0 && value.replace(/[^0-9]/g, '');
        this.form_object[name] = value && value.length > 0 && unmask(value);
        break;
      case 'company_specific_overrides_tax_identification_number':
        error = value ? value.length > 0 && value.length != 9 : false;
        this.form_object[name] = value;
        break;
      case 'company_specific_overrides_type_of_bill':
        error = value ? value.length > 0 && value.length != 3 : false;
        this.form_object[name] = value;
        break;
      case 'company_specific_overrides_taxonomy_code':
        error = value ? value.length > 0 && value.length != 10 : false;
        this.form_object[name] = value;
        break;
      default:
        const required_felid = this.page_metadata.control_data
          .filter((item) => item.is_required)
          .filter((item) => `${item.form_name}_${item.name}` === name);
        this.form_object[name] = value;
        error = required_felid && required_felid.length > 0 ? (value.trim() == '' ? true : false) : false;
    }
    return error;
  };

  // Select Insurance Code to Remove
  insurance_row_selector = items => {
    this.selected_insurance_item = items;
    this.setState({
      selected_insurance_item: this.selected_insurance_item
    });
  };

  // Remove Selected Insurance Code
  remove_insurance_handler = e => {
    if (this.selected_insurance_item.length > 0 && this._is_mounted) {
      let selected_insurance_items: any = this.selected_insurance_item.map(item => item.insurance_code_id);
      this.payer_ins_code_detail = this.payer_ins_code_detail.map(item => {
        if (selected_insurance_items.indexOf(item.insurance_code_id) > -1) {
          return (item = {
            ...item,
            payer_id_key: 0
          });
        } else {
          return item;
        }
      });

      this.setState({
        insurance_rows_data: this.payer_ins_code_detail.filter(item => {
          return item.payer_id_key != 0;
        }),
        selected_insurance_item: []
      });
      this.selected_insurance_item = [];
    } else toaster.warning('', 'Please select a Insurances code');
  };

  //Save the form object and redirect it to search
    save_handler = (e, from_pop_up) => {
    if (this._is_mounted) {
      this.setState({
        is_submitted: true
      });
    }
    var is_error = false;
    this.go_next = false;

    this.page_metadata.control_data
      .filter(item => item.is_required)
      .filter(data => {
        this.form_object[`${data.form_name}_${data.name}`] =
          this.form_object[`${data.form_name}_${data.name}`] &&
            typeof this.form_object[`${data.form_name}_${data.name}`] !== 'boolean'
            ? this.form_object[`${data.form_name}_${data.name}`].toString().trim()
            : this.form_object[`${data.form_name}_${data.name}`];

        if (!this.form_object[`${data.form_name}_${data.name}`].toString() && this._is_mounted) {
          this.setState(prev_state => ({
            error: {
              ...prev_state.error,
              [`${data.form_name}_${data.name}`]: true
            }
          }));
          toaster.error('', constants.mandatory_fields);
          is_error = true;
        }
      });

    for (var key in this.state.error) {
      if (this.state.error[key]) {
        toaster.error('', constants.mandatory_fields);
        is_error = true;
      }
    }

    if (is_error) {
      return;
    }
    if (this._is_mounted) {
      this.setState({
        is_loading: true,
        is_save_button_disabled: true
      });
    }
    // Set data to be upload
    this.page_metadata.control_data.map(({ form_name, name }) => {
      if (this.form_object) {
        switch (form_name) {
          case 'payer':
            this.payer[name] = this.form_object[form_name + '_' + name];
            break;
          case 'cross_company_settings':
            this.cross_company_settings[name] = this.form_object[form_name + '_' + name];
            break;
          case 'company_specific_overrides':
            this.company_specific_overrides[name] = this.form_object[form_name + '_' + name];
            break;
          default:
            break;
        }
      }
    });

    session_storage.set(`payers_search_criteria`, this.payers_search_criteria);

    this.cross_company_settings["cross_company_settings_days_to_999"] = nullifyString(this.cross_company_settings["cross_company_settings_days_to_999"]);
    this.cross_company_settings["cross_company_settings_days_to_277"] = nullifyString(this.cross_company_settings["cross_company_settings_days_to_277"]);
    this.cross_company_settings["company_specific_overrides_days_to_999"] = nullifyString(this.cross_company_settings["company_specific_overrides_days_to_999"]);
    this.cross_company_settings["company_specific_overrides_days_to_277"] = nullifyString(this.cross_company_settings["company_specific_overrides_days_to_277"]);
    this.cross_company_settings["days_to_999"] = nullifyString(this.cross_company_settings["days_to_999"]);
    this.cross_company_settings["days_to_277"] = nullifyString(this.cross_company_settings["days_to_277"]);
    this.company_specific_overrides["days_to_999"] = nullifyString(this.company_specific_overrides["days_to_999"]);
    this.company_specific_overrides["days_to_277"] = nullifyString(this.company_specific_overrides["days_to_277"]);
    this.company_specific_overrides["medicare_crossover"] = toBoolean(this.company_specific_overrides["medicare_crossover"]);
    this.company_specific_overrides["denies_crossover"] = toBoolean(this.company_specific_overrides["denies_crossover"]);
    this.company_specific_overrides["accepts_primary_paper_claims"] = toBoolean(this.company_specific_overrides["accepts_primary_paper_claims"]);
    this.company_specific_overrides["accepts_secondary_paper_claims"] = toBoolean(this.company_specific_overrides["accepts_secondary_paper_claims"]);
    this.company_specific_overrides["accepts_secondary_claims_electronically"] = toBoolean(this.company_specific_overrides["accepts_secondary_claims_electronically"]);
    this.company_specific_overrides["include_on_277_rpt"] = toBoolean(this.company_specific_overrides["include_on_277_rpt"]);
    this.company_specific_overrides["treatment_notes_required"] = toBoolean(this.company_specific_overrides["treatment_notes_required"]);

    if (this.code_id) {
      //Update
      this.update_payer(from_pop_up);
    } else {
      //Add
      this.add_payer(from_pop_up);
    }
  };

  // Update Payer
  update_payer = from_pop_up => {
    let url = this.page_metadata.api.update.url;
    url = url.replace('id_value', this.code_id);
    constant_action
      .update_payers(this.token, url, {
        payer: { ...this.payer },
        cross_company_settings: { ...this.cross_company_settings },
        company_specific_overrides: { ...this.company_specific_overrides },
        payer_ins_code_detail: this.payer_ins_code_detail
      })
      .then(
        response => {
          if (response.data && response.data.data) {
            this.go_next = true;
            toaster.success('', this.page_metadata.validation_message.update);
            this.initial_form_object = { ...this.form_object };
            if (this._is_mounted) {
              this.setState({
                is_loading: false,
                by_pass_prompt: !from_pop_up ? true : false,
                is_save_button_disabled: false
              });
            }
            if (!from_pop_up && this.go_next) {
              this.go_next = false;
              this.props.history.goBack();              
            }
          } else {
            this.go_next = false;
            toaster.error('', response.data.messages[0].message);
            if (this._is_mounted) {
              this.setState({
                is_loading: false,
                is_save_button_disabled: false
              });
            }
          }
        },
        error => {
          if (this._is_mounted) {
            this.go_next = false;
            this.setState({
              is_loading: false,
              is_save_button_disabled: false
            });
          }
          if (error.response.data) toaster.error('', error.response.data.messages[0].message);
        }
      );
  };

  // Add Payer
  add_payer = from_pop_up => {
    let url = this.page_metadata.api.add.url;
    constant_action
      .add_data(this.token, url, {
        payer: { ...this.payer },
        cross_company_settings: { ...this.cross_company_settings },
        company_specific_overrides: { ...this.company_specific_overrides },
        payer_ins_code_detail: this.payer_ins_code_detail
      })
      .then(
        response => {
          if (response.data && response.data.data) {
            this.go_next = true;
            toaster.success('', this.page_metadata.validation_message.save);
            this.initial_form_object = { ...this.form_object };
            if (this._is_mounted) {
              this.setState({
                is_loading: false,
                by_pass_prompt: !from_pop_up ? true : false,
                is_save_button_disabled: false
              });
            }
            if (!from_pop_up && this.go_next) {
              this.go_next = false;
              this.props.history.goBack();
            }
          } else {
            this.go_next = false;
            toaster.error('', response.data.messages[0].message);
            if (this._is_mounted) {
              this.setState({
                is_loading: false,
                is_save_button_disabled: false
              });
            }
          }
        },
        error => {
          if (this._is_mounted) {
            this.go_next = false;
            this.setState({
              is_loading: false,
              is_save_button_disabled: false
            });
          }
          if (error.response.data) toaster.error('', error.response.data.messages[0].message);
        }
      );
  };

  //Function used to Run Report based on controllers values.
  cancel_handler = () => {
    this.by_pass_prompt = true;
    this.setState(
      {
        by_pass_prompt: true
      },
        () => this.props.history.goBack()
    );
  };

  // Show multiple messages
  show_html_content_toaster = msg => {
    return {
      component: () => (
        <div>
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        </div>
      ),
      timeOut: global_constants.toastr_options.toastr_time_out,
      preventDuplicates: true
    };
  };

  //It renders report grid and its search controls.
    render() {
    this.page_state = this.get_page_state();
    return (
      this.state.show_render && (
        <React.Fragment>
          {!this.state.by_pass_prompt && (
            <PromptNavigation
              is_data_changed={JSON.stringify(this.initial_form_object) !== JSON.stringify(this.form_object)}
              save={e => this.save_handler(e, true)}
              go_next_location={this.go_next}
              history={this.props.history}
            />
          )}
          <Dimmer active={this.state.is_loading}>
            <Loader size='massive'>Loading</Loader>
          </Dimmer>
          <div
            className={'admin-wrapper view-edit ' + this.page_metadata.id}
            style={!this.state.show_grid ? { paddingBottom: 0 } : {}}
          >
            <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
              <Grid.Column computer={16}>
                <h3 className='ui header left aligned'>{this.page_metadata.header}</h3>
              </Grid.Column>
            </Grid>
            <div className='wrapper' style={payers_css.wrapper}>
              <Form autoComplete='off' name='view_edit_form' className='common-forms' style={payers_css.common_forms}>
                <div id='admin-scrollable-area' style={payers_css.scrollbar_area}>
                  {constants
                    .group_by(this.page_metadata.control_data, 'container')
                    .map((container_object, container_index) => {
                      return (
                        <div
                          className='patient-search-form patient_search_bottom_padding'
                          id='search-criteria-container'
                          style={payers_css.patient_search_form}
                          key={'outer_row_' + container_index}
                        >
                          <Grid>
                            <Grid.Column computer={16}>
                              <Header as='h4'>{this.page_metadata.container_heading[container_index - 1]}</Header>
                            </Grid.Column>
                          </Grid>
                              {constants.group_by(container_object, 'row').map((row_object, row_index) => {
                              return (
                              <Grid key={'outer_row_' + row_index}>
                                {row_object.map((data_row, index) => {
                                  if (this.page_state == 'edit' && data_row.is_edit) {
                                    return (
                                      <Grid.Column
                                        className={data_row.control_type === 'checkbox' ? 'form_column' : ''}
                                        key={'inner_row_' + index}
                                        tablet={data_row.view_container_tablet}
                                        computer={data_row.view_container_computer}
                                        verticalAlign='top'
                                      >
                                        {this.set_controls(data_row)}
                                      </Grid.Column>
                                    );
                                  } else if (this.page_state == 'add' && data_row.is_add) {
                                    return (
                                      <Grid.Column
                                        className={data_row.control_type === 'checkbox' ? 'form_column' : ''}
                                        key={'inner_row_' + index}
                                        tablet={data_row.view_container_tablet}
                                        computer={data_row.view_container_computer}
                                        verticalAlign='top'
                                      >
                                        {this.set_controls(data_row)}
                                      </Grid.Column>
                                    );
                                  }
                                })}
                              </Grid>
                            );
                          })}
                        </div>
                      );
                    })}
                  {this.page_state == 'edit' && this.payer_ins_code_detail && (
                    <React.Fragment>
                      <Grid.Column computer={16}>
                        <Header
                          as='h4'
                          style={{
                            color: '#7f8fa4',
                            display: 'block',
                            fontSize: 16,
                            fontWeight: 400,
                            lineHeight: '21px',
                            paddingTop: 10,
                            paddingBottom: 15
                          }}
                        >
                          Insurances Codes
                        </Header>
                      </Grid.Column>
                      <Grid>
                        <Grid.Column computer={16}>
                          <div className='customtable' style={{ backgroundColor: '#FFF' }}>
                            <GridView
                              id={`insurance_code_grid`}
                              row={this.state.insurance_rows_data}
                              column={constants.payer.insurance_column_def}
                              emptyMessage='No Record Available'
                              name='Insurance_Code'
                              checkboxSelection={true}
                              gridAutoHeight={true}
                              onGridOut={this.props.get_account_notes_grid_ref}
                              headerIdForTabNavigation={constants.search_grid_id}
                              onRowSelection={items => {
                                this.insurance_row_selector(items);
                              }}
                            />
                          </div>
                        </Grid.Column>
                        {this.payer_ins_code_detail.length > 0 && (
                          <Grid.Column computer={16} textAlign='right'>
                            <Button id='id_btn_new_row' type='button' onClick={this.remove_insurance_handler} basic>
                              Remove Selected
                            </Button>
                          </Grid.Column>
                        )}
                      </Grid>
                    </React.Fragment>
                  )}
                </div>
              </Form>
            </div>
            <div
              className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
              id='applicationFooterSticky'
              style={payers_css.footerArea}
            >
              <Button id='cancel_button_id' type='button' onClick={() => this.cancel_handler()} basic>
                Cancel
              </Button>
              <Button
                type='submit'
                onKeyDown={set_focus_to_app_header}
                onClick={e => this.save_handler(e, false)}
                id='save_button_id'
                primary
                disabled={this.state.is_save_button_disabled}
              >
                Save
              </Button>
            </div>
          </div>
        </React.Fragment>
      )
    );
  }
}

// Css Style
const payers_css = {
  locations_id: {
    paddingBottom: 0
  },
  headerGrid: {
    marginTop: 0,
    marginBottom: 0,
    paddingRight: 15
  },
  wrapper: {
    borderTop: 0,
    marginBottom: 0
  },
  common_forms: {
    paddingTop: 0,
    height: '100%',
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column'
  } as React.CSSProperties,

  patient_search_form: {
    paddingBottom: 0,
    flexBasis: 'auto',
    borderColor: '#cccccc',
    borderStyle: 'solid',
    borderWidth: '2px 1px 1px',
    margin: '0 0 15px 0px',
    background: '#fff',
    padding: '25px 15px'
  },
  footerArea: {
    paddingLeft: 0,
    paddingBottom: 0,
    marginBottom: 12,
    paddingRight: 25,
    paddingTop: 20
  },
  help_text: {
    color: '#757575',
    fontSize: 16,
    fontStyle: 'italic',
    fontWeight: 400,
    margin: 0,
    padding: 0
  },
  scrollbar_area: {
    height: '100%',
    paddingRight: 15
  } as React.CSSProperties,
  save_button: {
    marginRight: 0
  }
};

//Get user and login details from store.
const mapStateToProps = state => {
  return {
    user_login_details: state.user_login_details,
      rsiB31486AddCompanyMultiSelectDropDownToPayersPage: get_lauch_darkley_key_value(state.launch_darkly, "rsiB31486AddCompanyMultiSelectDropDownToPayersPage"),
      krakenBill333RemoveAndReplaceMedicareCrossoverOnPayersPage: get_lauch_darkley_key_value(state.launch_darkly, kraken_bill_333_remove_and_replace_medicare_crossover_on_payers_page)    
  };
};

export default connect(mapStateToProps)(PayerAddEditComponent);