import * as React from "react";
import GridView from "../../shared/component/grid";
import { get_columns } from "../../shared/utility";
import { messages, report_ledger_full_configuration_header_id } from "../report_constants";

class InnerGridRenderer extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.column_def = this.props.data["child_column"] ? this.props.data["child_column"] : [];
    this.inner_rows = this.props.data["details"] ? this.props.data["details"] : [];
    this.grid_class = this.props.data["grid_class"] ? this.props.data["grid_class"] : '';
    this.row_class = this.props.data["row_class"] ? this.props.data["row_class"] : {};
  }

  inner_rows: any;
  column_def: any;
  _is_mounted: boolean = false;
  total_column_width: number;
  grid_header_height: number;
  grid_class: string;
  row_class: object;

  UNSAFE_componentWillMount = () => {
    const grid_height = get_columns(this.inner_rows, this.column_def);
    this.total_column_width = grid_height.total_column_width;
    this.grid_header_height = grid_height.header_height;
  };

  componentDidMount() {
    this._is_mounted = true;
  }

  // Function calls on initialization of Ag-Grid and catch its reference.
  get_grid_ref = (grid_params: { columnApi: { setColumnWidth: (arg0: any, arg1: any, arg2: boolean) => void; }; }) => {
    let grid_width = document.getElementsByClassName("inner-grid-wrapper")[0].clientWidth;
    if (this.total_column_width > grid_width) {
      this.column_def.filter((item: { field: any; width: any; }) => {
        grid_params.columnApi.setColumnWidth(item.field, item.width, false);
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.props.data && this.props.data.child_column && (
          <GridView
            id={"inner_grid_id"}
            column={this.column_def}
            row={this.inner_rows}
            headerHeight={this.grid_header_height}
            gridAutoHeight={true}
            style={{ height: "100%" }}
            wrapperStyle={{ width: "100%", height: "100%", display: "flex", flex: 1 }}
            wrapperClass={`inner-grid-wrapper ${this.grid_class}`}
            enableColResize={false}
            emptyMessage={messages.no_records_found}
            headerIdForTabNavigation={report_ledger_full_configuration_header_id}
            get_grid_ref={this.get_grid_ref}
            suppressMovableColumns={true}
            suppressColumnVirtualisation={true}
            rowClassRules={this.row_class}
        />
        )}
      </React.Fragment>
    );
  }
}

export default InnerGridRenderer;
