import { IProviderBillingStrategy } from './provider_billing_validator_strategy';

export class ConcreteStrategyDataInGroups implements IProviderBillingStrategy {
    private hasSomeValueInGroups(dataToCheck: any) {
        const isAnActiveData = dataToCheck.status;
        if (!isAnActiveData) {
            return !isAnActiveData;
        }

        const hasValueForLocations = dataToCheck.location_ids.length > 0;
        const hasValueForInsuranceClasses = dataToCheck.insurance_class_ids.length > 0;
        const hasValueForInsuranceCodes = dataToCheck.insurance_code_ids.length > 0;

        return hasValueForLocations && (hasValueForInsuranceClasses || hasValueForInsuranceCodes);
    }

    validate(data: any[]): boolean {
        return this.hasSomeValueInGroups(data);
    }
}

export class ConcreteStrategySingleDataInGroups implements IProviderBillingStrategy {
    private hasSomeValueInGroups(dataToCheck: any) {
        const hasValueForLocations = dataToCheck.location_ids.length > 0;
        const hasValueForInsuranceClasses = dataToCheck.insurance_class_ids.length > 0;
        const hasValueForInsuranceCodes = dataToCheck.insurance_code_ids.length > 0;

        return hasValueForLocations && (hasValueForInsuranceClasses || hasValueForInsuranceCodes);
    }

    validate(data: any[]): boolean {
        return this.hasSomeValueInGroups(data);
    }
}

export class ConcreteStrategyOnlyLocationInGroups implements IProviderBillingStrategy {
    private hasSomeValueInGroups(dataToCheck: any) {
        const hasValueForLocations = dataToCheck.location_ids.length > 0;
        const hasValueForInsuranceClasses = dataToCheck.insurance_class_ids.length > 0;
        const hasValueForInsuranceCodes = dataToCheck.insurance_code_ids.length > 0;

        return (hasValueForLocations && ! hasValueForInsuranceClasses && ! hasValueForInsuranceCodes);
    }

    validate(data: any[]): boolean {
        return this.hasSomeValueInGroups(data);
    }
}

export class ConcreteStrategyOnlyProviderInGroups implements IProviderBillingStrategy {
    private hasSomeValueInGroups(dataToCheck: any) {
        const hasValueForProviders = dataToCheck.provider_ids.length > 0;
        const hasValueForInsuranceClasses = dataToCheck.insurance_class_ids.length > 0;
        const hasValueForInsuranceCodes = dataToCheck.insurance_code_ids.length > 0;

        return (hasValueForProviders && !hasValueForInsuranceClasses && !hasValueForInsuranceCodes);
    }

    validate(data: any[]): boolean {
        return this.hasSomeValueInGroups(data);
    }
}

export class ConcreteStrategySingleDataInGroupsLocationClaimHold implements IProviderBillingStrategy {
    private hasSomeValueInGroups(dataToCheck: any) {
        const hasValueForProviders = dataToCheck.provider_ids.length > 0;
        const hasValueForInsuranceClasses = dataToCheck.insurance_class_ids.length > 0;
        const hasValueForInsuranceCodes = dataToCheck.insurance_code_ids.length > 0;

        return hasValueForProviders && (hasValueForInsuranceClasses || hasValueForInsuranceCodes);
    }

    validate(data: any[]): boolean {
        return this.hasSomeValueInGroups(data);
    }
}