import * as moment from 'moment';
import * as React from 'react';
import { Grid, Header, Table } from 'semantic-ui-react';
const PaymentNotes = props => {
    return (<Grid id="applicationPageTitleSticky">
        <Grid.Column computer={16}>
            <Header as='h4' textAlign='left' id='account_note_header_text'>
                Account Notes
                </Header>
            <div className='customtable vertical-scroll-3 mar-t-10'>
                <div>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={props.show_stick_on ? 8 : 11}>Note</Table.HeaderCell>
                                <Table.HeaderCell width={1}>Code</Table.HeaderCell>
                                <Table.HeaderCell width={1}>Date</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Posted by</Table.HeaderCell>
                                {props.show_stick_on && <Table.HeaderCell width={3}>Stuck on</Table.HeaderCell>}
                            </Table.Row>
                        </Table.Header>
                        {props.accountNotes.length > 0 &&
                            (<Table.Body>
                                {props.accountNotes.map((item, index) => {
                                    return (
                                        <Table.Row key={index}>
                                            <Table.Cell>{item.note_text}</Table.Cell>
                                            <Table.Cell>{item.note_code}</Table.Cell>
                                            <Table.Cell>{moment(item.created_date).format('MM/DD/YYYY')}</Table.Cell>
                                            <Table.Cell>{item.posted_by}</Table.Cell>
                                            {props.show_stick_on && <Table.Cell>{item.stick_on}</Table.Cell>}
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>)
                        }
                    </Table>
                </div>
            </div>
            <br></br>
        </Grid.Column>
    </Grid>);
};

export default PaymentNotes;
