import * as React from 'react'
import { connect } from 'react-redux';
import MultiSelectDropDownComponent from '../../../../shared/component/multi_select_dropdown_component';
import Selection from '../../../../shared/component/selection_component';
import { Checkbox, Table } from 'semantic-ui-react';
import { expected_rate } from '../../constants';
import CurrencyFormatter from '../../../../shared/component/currency_formatter';
import FeeProcedureCodesComponent from './fee_procedure_codes_component';
import PercentageControl from '../../../../shared/component/percentage_decimal_control';
import { get_columns, get_lauch_darkley_key_value } from "./../../../../shared/utility"
import { argos_bill_1627_expected_rates_improvements } from './../../../../feature_flip_constant';
import * as NumberFormat from 'react-number-format';

export class FeeDefinitionComponent extends React.Component<any, any>{
    _is_mounted = false;
    _include_future_providers = true;

    constructor(props) {
        super(props);

        this.state = {
            multiselect_list: { location: [], provider: [] },
            selection_list: {
                location: this.props.data.assigned_location_ids,
                provider: this.props.data.assigned_provider_ids
            },
            show_cpt_panel: false,
            display_on_hover: false,
            is_include_future_selected: { location: false, provider: this.props.data.include_future_providers },
            is_include_future_disabled: { location: false, provider: false }
        };
        this.toggleCptCodePanel = this.toggleCptCodePanel.bind(this);
    }

    componentDidMount = () => {
        this._is_mounted = true;
        if (this.state.is_include_future_selected.provider == true) {
            this.init_providers(this.props.key_id);
        }
    }

    componentWillUnmount = () => {
        this._is_mounted = false;
    }

    shouldComponentUpdate(nextProps, nextState) {
        let val = false
        //Check Props coming in
        if (this.props.key_id != nextProps.key_id) 
        val= true;

        if (this.props.expected_rate_id != nextProps.expected_rate_id)  val= true;

        if (this.state.display_on_hover != nextState.display_on_hover)  val= true;

        if (JSON.stringify(this.props.fee_type_list) != JSON.stringify(nextProps.fee_type_list))  val= true;

        if (this.props.is_save_in_progress != nextProps.is_save_in_progress)  val= true;

        if (JSON.stringify(this.props.validation) != JSON.stringify(nextProps.validation))  val= true;

        if (JSON.stringify(this.props.data) != JSON.stringify(nextProps.data))  val= true;

        //Check State
        if (JSON.stringify(this.state.multiselect_list.location) != JSON.stringify(nextState.multiselect_list.location))  val= true;
        if (JSON.stringify(this.state.multiselect_list.provider) != JSON.stringify(nextState.multiselect_list.provider))  val= true;

        if (JSON.stringify(this.state.selection_list.location) != JSON.stringify(nextState.selection_list.location))  val= true;
        if (JSON.stringify(this.state.selection_list.provider) != JSON.stringify(nextState.selection_list.provider))  val= true;

       return val;
    }

    on_click_add_new_fee = () => {
        this.props.addNewFeeItem && this.props.addNewFeeItem(this.props.expected_rate_id);
    }

    handle_fee_value_change = (identifier, value) => {
        this.props.updateExpectedRatFeeDefinition &&
            this.props.updateExpectedRatFeeDefinition(this.props.data.expected_rate_fee_id, identifier, value);
    };

    handle_fee_rate_type_change = (fee_rate_type_id) => {
        this.props.updateExpectedRatFeeDefinition &&
            this.props.updateExpectedRatFeeDefinition(this.props.data.expected_rate_fee_id, expected_rate.identifier.fee_rate_type, fee_rate_type_id);
    }

    copy_expected_rate_fee_definition_prompt = () => {
        this.props.copyExpectedRateFeeDefinition(this.props.data.expected_rate_fee_id);
    }

    delete_expected_rate_fee_definition_prompt = () => {
        this.props.deleteExpectedRateFeeDefinitionPrompt && this.props.deleteExpectedRateFeeDefinitionPrompt(this.props.data.expected_rate_fee_id);
    }

    on_open_location = (expected_rate_fee_id) => {
        var location_list = this.props.onLocationOpen(expected_rate_fee_id);
        if (this._is_mounted) {
            this.setState(prevState => ({
                multiselect_list: {
                    ...prevState.multiselect_list,
                    location: location_list
                }
            }))
        }
    }

    future_change = (value) => {
        let future_selected = value;
    }

    select_change = (id, values, identifier) => {
        let include_future_providers = false;
        let future_values = [];
        let future = false;
        if (identifier == expected_rate.identifier.provider) {
            if ((values && values.length == 1 && values[0] == -1) || (this.state.is_include_future_selected.provider == true && values.length > 0)) {
                future = include_future_providers = true;
                var provider_list = this.props.onProviderOpen(this.props.data.expected_rate_fee_id);
                provider_list.map((item) => {
                    if (item.is_disabled == false) {
                        future_values.push(item.value);
                    }
                });
            }
        }
        let list = this.state.multiselect_list[identifier].map((item) => {
            if (values.indexOf(item.id) > -1) {
                item = {
                    ...item,
                    checked: true,
                    is_checked: true
                };
            } else {
                item = {
                    ...item,
                    checked: false,
                    is_checked: false
                };
            }
            return item;
        });
        if (this._is_mounted) {
            if (identifier == expected_rate.identifier.provider) {
                this.setState({
                    is_include_future_selected: {
                        [identifier]: future
                    }
                });
            }
            this.setState(prevState => ({
                multiselect_list: {
                    ...prevState.multiselect_list,
                    [identifier]: list
                },
                selection_list: {
                    ...prevState.selection_list,
                    [identifier]: [...values]
                }
            }), () => {
                if (include_future_providers && identifier == expected_rate.identifier.provider) {
                    this.props.updateExpectedRatFeeDefinition && this.props.updateExpectedRatFeeDefinitionIncludeFutureProviders(this.props.data.expected_rate_fee_id, identifier, future_values, future);
                } else {
                    this.props.updateExpectedRatFeeDefinition && this.props.updateExpectedRatFeeDefinition(this.props.data.expected_rate_fee_id, identifier, values, future);
                }
            });
        }
    }

    on_open_provider = (expected_rate_fee_id) => {
        var provider_list = this.props.onProviderOpen(expected_rate_fee_id);
        if (this._is_mounted) {
            this.setState(prevState => ({
                multiselect_list: {
                    ...prevState.multiselect_list,
                    provider: provider_list
                }
            }))
        }
    }

    init_providers = (expected_rate_fee_id) => {
        //BILL-1172
        var provider_list = this.props.onProviderOpen(expected_rate_fee_id);
        let provider_selection_list = this.state.selection_list.provider;
        if (this._is_mounted) {
            let inConflict = false;
            provider_list.map((item) => {
                if (this.state.is_include_future_selected.provider == true) {
                    if (this.state.selection_list.provider.indexOf(item.value) == -1 && item.is_disabled == false) {
                        provider_selection_list.push(item.value);
                    }
                }
                if (item.is_disabled == true) {
                    inConflict = true;
                }
            });
            this.setState(prevState => ({
                multiselect_list: {
                    ...prevState.multiselect_list,
                    provider: provider_list
                },
                selection_list: {
                    ...prevState.selection_list,
                    provider: provider_selection_list
                }
            }))
            this.setState({ is_include_future_disabled: { provider: inConflict } });
        }
    }

    has_validation = (field_name, procedure_code_id = '') => {
        let has_validation_msg = false;
        switch (field_name) {
            case expected_rate.identifier.provider:
                has_validation_msg =
                    (this.props.data.assigned_provider_ids.length == 0) ||
                    (this.props.validation && this.props.validation.assigned_provider_ids && this.props.validation.assigned_provider_ids.length > 0);
                break;
            case expected_rate.identifier.location:
                has_validation_msg =
                    this.props.data.assigned_location_ids.length == 0 ||
                    (this.props.validation && this.props.validation.assigned_location_ids && this.props.validation.assigned_location_ids.length > 0);
                break;
            case expected_rate.identifier.fee_rate_type_id:
                has_validation_msg = this.props.data.fee_rate_type_id <= 0 ||
                    (this.props.validation && this.props.validation.fee_rate_type_id && this.props.validation.fee_rate_type_id.length > 0);
                break;
            case expected_rate.identifier.percentage:
                if (this.props.data.fee_rate_type_id == expected_rate.fee_type.reduction_percent) {
                    if (!this.props.data.percentage || this.props.data.percentage <= 0 ||
                        (this.props.validation && this.props.validation.percentage && this.props.validation.percentage.length > 0))
                        has_validation_msg = true;
                }
                break;
            case expected_rate.identifier.modifier_percentage_reduction:
                if (this.props.data.fee_rate_type_id == expected_rate.fee_type.fee_for_service) {
                    if (
                        !this.props.data.modifier_percentage_reduction ||
                        this.props.data.modifier_percentage_reduction <= 0 ||
                        (this.props.validation &&
                            this.props.validation.modifier_percentage_reduction &&
                            this.props.validation.modifier_percentage_reduction.length > 0)
                    )
                        has_validation_msg = true;
                }
                break;
            case expected_rate.identifier.per_visit:
                if (this.props.data.fee_rate_type_id == expected_rate.fee_type.flat_rate) {
                    if (!this.props.data.per_visit || this.props.data.per_visit <= 0 ||
                        (this.props.validation && this.props.validation.per_visit && this.props.validation.per_visit.length > 0))
                        has_validation_msg = true;
                }
                break;
            case expected_rate.identifier.units:
                if (this.props.features.argosBill1627ExpectedRatesImprovements && this.props.data.fee_rate_type_id == expected_rate.fee_type.fee_for_service) {
                    if (
                        !this.props.data.units || this.props.data.units.floatValue <= 0 ||
                        (this.props.validation && this.props.validation.units && this.props.validation.units.length > 0)
                    )
                        has_validation_msg = true;
                }
                break;
            case expected_rate.identifier.expected_reimbursement_unit:
                if (this.props.data.fee_rate_type_id == expected_rate.fee_type.fee_for_service && this.props.data.mppr && procedure_code_id){
                    let proc_code = this.props.data.expected_rate_fee_procedure_codes.filter(pc => pc.procedure_code_id == procedure_code_id);
                    if((proc_code[0].mppr_rate && (proc_code[0].expected_reimbursement_unit == null || proc_code[0].expected_reimbursement_unit === '' )) || (this.props.proc_code_validation && this.props.proc_code_validation.procedure_code_id )){
                        has_validation_msg = true;
                    }
                }
            case expected_rate.identifier.per_evaluation:
                break;
        }
        has_validation_msg = has_validation_msg && this.props.is_save_in_progress;
        return has_validation_msg;
    }

    get_validation_msg = (field_name) => {
        let validation_msg = '';
        switch (field_name) {
            case expected_rate.identifier.provider:
                validation_msg =
                    (this.props.validation && this.props.validation.assigned_provider_ids)
                        ? this.props.validation.assigned_provider_ids : '';
                break;
            case expected_rate.identifier.location:
                validation_msg =

                    (this.props.validation && this.props.validation.assigned_location_ids)
                        ? this.props.validation.assigned_location_ids : '';
                break;
            case expected_rate.identifier.fee_rate_type_id:
                validation_msg =
                    (this.props.validation && this.props.validation.fee_rate_type_id)
                        ? this.props.validation.fee_rate_type_id : '';
                break;
            case expected_rate.identifier.percentage:
                if (this.props.data.fee_rate_type_id == expected_rate.fee_type.reduction_percent) {
                    validation_msg =
                        (this.props.validation && this.props.validation.percentage)
                            ? this.props.validation.percentage : '';

                }
                break;
            case expected_rate.identifier.modifier_percentage_reduction:
                if (this.props.data.fee_for_service == expected_rate.fee_type.fee_for_service) {
                    validation_msg =
                        (this.props.validation && this.props.validation.modifier_percentage_reduction)
                            ? this.props.validation.modifier_percentage_reduction : '';
                }
                break;
            case expected_rate.identifier.per_visit:
                if (this.props.data.fee_rate_type_id == expected_rate.fee_type.flat_rate) {
                    validation_msg =
                        (this.props.validation && this.props.validation.per_visit)
                            ? this.props.validation.per_visit : '';
                }
                break;
            case expected_rate.identifier.per_evaluation:
                break;
        }
        return validation_msg;
    }

    get_fee_default_values = (value) => {
        if (value == null)
            value = '';
        return value;
    }

    toggleCptCodePanel() {
        this.props.updateExpectedRatFeeDefinition &&
            this.props.updateExpectedRatFeeDefinition(this.props.data.expected_rate_fee_id, expected_rate.identifier.is_expanded, !this.props.data.is_expanded);
    }

    get_required_text_div = (field_name) => {
        return (
            <div className='requiredText'>
                {
                    this.has_validation(field_name)
                        ? this.get_validation_msg(field_name)
                        : ''
                }
            </div>
        );
    }

    update_expected_rate_fee_definition_procedure_code = (procedure_code_id, identifier, value) => {
        this.props.updateExpectedRatFeeDefinitionProcedureCode && this.props.updateExpectedRatFeeDefinitionProcedureCode(this.props.data.expected_rate_fee_id, procedure_code_id, identifier, value);
    }

    get_proc_code_validation_list = () => {
        let proc_code_validation = null;

        if (this.props.validation && this.props.validation.proc_codes_validation_list) {
            proc_code_validation = this.props.validation.proc_codes_validation_list
        }

        return proc_code_validation;
    }

    //#region action events
    on_mouse_enter_row = () => {
        this.setState({ display_on_hover: true });
    }

    on_mouse_leave_row = () => {
        this.setState({ display_on_hover: false });
    }

    handle_checkbox = (e, identifier) => {
        const { checked } = e.target;
        this.props.updateExpectedRatFeeDefinition &&
            this.props.updateExpectedRatFeeDefinition(this.props.data.expected_rate_fee_id, identifier, checked);
    };

    //#endregion
    render() {
        const icon = this.props.data.is_expanded ? "angle down icon cpt" : "angle right icon cpt";
        return (
            <React.Fragment>
                <Table.Row key={this.props.data.expected_rate_fee_id} onMouseEnter={this.on_mouse_enter_row} onMouseLeave={this.on_mouse_leave_row}>
                    <Table.Cell>
                        <i onClick={this.toggleCptCodePanel} className={icon}></i>
                    </Table.Cell>
                    <Table.Cell>
                        <MultiSelectDropDownComponent
                            id={"location-multi-select_" + this.props.key_id}
                            key={"location-multi-select_" + this.props.key_id}
                            key_id={this.props.key_id}
                            data={this.state.multiselect_list.location}
                            selected_list={this.state.selection_list.location}
                            onChangeSelected={(id, values) => this.select_change(id, values, expected_rate.identifier.location )}
                            onOpen={this.on_open_location}
                            has_validation={this.has_validation(expected_rate.identifier.location)}
                            show_filter={false}
                            forceOnChangeSelected={true}
                        />
                        {this.get_required_text_div(expected_rate.identifier.location)}
                    </Table.Cell>
                    <Table.Cell>
                        <MultiSelectDropDownComponent
                            id={"provider-multi-select_" + this.props.key_id}
                            key={"provider-multi-select_" + this.props.key_id}
                            key_id={this.props.key_id}
                            data={this.state.multiselect_list.provider}
                            selected_list={this.state.selection_list.provider}
                            onChangeSelected={(id, values) => this.select_change(id, values, expected_rate.identifier.provider)}
                            onOpen={this.on_open_provider}
                            has_validation={this.has_validation(expected_rate.identifier.provider)}
                            show_filter={false}
                            include_future_selected={this.state.is_include_future_selected.provider}
                            include_future_disabled={this.state.is_include_future_disabled.provider}
                            include_future={this._include_future_providers}
                            include_future_values={this._include_future_providers}
                            forceOnChangeSelected={true}
                        />
                        {this.get_required_text_div(expected_rate.identifier.provider)}
                    </Table.Cell>
                    <Table.Cell>
                        <Selection
                            id={'er_feerate_' + this.props.key_id}
                            defaultValue={this.props.data.fee_rate_type_id ? this.props.data.fee_rate_type_id : ''}
                            options={this.props.fee_type_list}
                            onChange={value => this.handle_fee_rate_type_change(value)}
                            isRequired={this.has_validation(expected_rate.identifier.fee_rate_type_id)}
                        />
                        {this.get_required_text_div(expected_rate.identifier.fee_rate_type_id)}
                    </Table.Cell>
                    <Table.Cell>
                        {this.props.data.fee_rate_type_id == expected_rate.fee_type.fee_for_service && (
                            <Checkbox
                                name='assistant_modifier_reduction'
                                id={'assistant_modifier_reduction__' + this.props.key_id}
                                key={'assistant_modifier_reduction__' + this.props.data.expected_rate_fee_id}
                                label={expected_rate.assistant_modifier_reduction}
                                checked={this.props.data.assistant_modifier_reduction || false}
                                onChange={e => this.handle_checkbox(e, expected_rate.identifier.assistant_modifier_reduction)}
                            />
                        )}
                    </Table.Cell>
                    <Table.Cell>
                        {this.props.data.fee_rate_type_id == expected_rate.fee_type.fee_for_service && <Checkbox
                            name='mppr'
                            id={'mppr__' + this.props.key_id}
                            key={'mppr__' + this.props.data.expected_rate_fee_id}
                            label={expected_rate.mppr}
                            checked={this.props.data.mppr || false}
                            onChange={e => this.handle_checkbox(e, expected_rate.identifier.mppr)}
                        />}
                    </Table.Cell>
                    {this.props.features.argosBill1627ExpectedRatesImprovements &&
                        <Table.Cell>
                            {this.props.data.fee_rate_type_id == expected_rate.fee_type.fee_for_service && <Checkbox
                                name='max_number_of_units'
                                id={'max_number_of_units__' + this.props.key_id}
                                key={'max_number_of_units__' + this.props.data.expected_rate_fee_id}
                                label={expected_rate.max_number_of_units}
                                checked={this.props.data.max_number_of_units || false}
                                onChange={e => this.handle_checkbox(e, expected_rate.identifier.max_number_of_units)}
                            />}
                        </Table.Cell>
                    }
                    <Table.Cell>
                        {this.props.data.fee_rate_type_id == expected_rate.fee_type.fee_for_service &&
                            this.props.data.assistant_modifier_reduction && (
                                <React.Fragment>
                                    <PercentageControl
                                        key={'modifier_percentage_reduction_' + this.props.data.expected_rate_fee_id}
                                        id={'modifier_percentage_reduction_' + this.props.key_id}
                                        onChange={(value) => this.handle_fee_value_change(expected_rate.identifier.modifier_percentage_reduction, value)}
                                        focused={false}
                                        defaultValue={this.props.data.modifier_percentage_reduction}
                                        allowNegative={false}
                                        maxLength={6}
                                        suffix={'%'}
                                        prefix={''}
                                        className={
                                            this.has_validation(expected_rate.identifier.modifier_percentage_reduction)
                                                ? 'numberFormat req-background-inp'
                                                : 'numberFormat '
                                        }
                                    />
                                    {this.get_required_text_div(expected_rate.identifier.modifier_percentage_reduction)}
                                </React.Fragment>
                            )}
                        
                        {this.props.data.fee_rate_type_id == expected_rate.fee_type.reduction_percent  && (
                                <React.Fragment>
                                    <PercentageControl
                                        key={'percentage' + this.props.data.expected_rate_fee_id}
                                        id={'percentage' + this.props.key_id}
                                        onChange={(value) => this.handle_fee_value_change(expected_rate.identifier.percentage, value)}
                                        focused={false}
                                        defaultValue={this.props.data.percentage}
                                        allowNegative={false}
                                        maxLength={6}
                                        suffix={'%'}
                                        prefix={''}
                                        className={
                                            this.has_validation(expected_rate.identifier.percentage)
                                                ? 'numberFormat req-background-inp'
                                                : 'numberFormat '
                                        }
                                    />
                                    {this.get_required_text_div(expected_rate.identifier.percentage)}
                                </React.Fragment>
                            )}
                    </Table.Cell>
                    <Table.Cell>
                        {(this.props.data.fee_rate_type_id == expected_rate.fee_type.flat_rate) &&
                            <CurrencyFormatter
                                key={'per_visit_' + this.props.data.expected_rate_fee_id}
                                id={'per_visit_' + this.props.key_id}
                                onChange={value => this.handle_fee_value_change(expected_rate.identifier.per_visit, value)}
                                prefix='$'
                                focused={false}
                                defaultValue={this.get_fee_default_values(this.props.data.per_visit)}
                                allowNegative={false}
                                className={this.has_validation(expected_rate.identifier.per_visit) ? 'numberFormat req-background-inp' : 'numberFormat '}
                            />
                        }
                        {this.get_required_text_div(expected_rate.identifier.per_visit)}
                    </Table.Cell>
                    <Table.Cell>
                        {(this.props.data.fee_rate_type_id == expected_rate.fee_type.flat_rate) &&
                            <CurrencyFormatter
                                key={'per_evaluation_' + this.props.data.expected_rate_fee_id}
                                id={'per_evaluation_' + this.props.key_id}
                                onChange={value => this.handle_fee_value_change(expected_rate.identifier.per_evaluation, value)}
                                prefix='$'
                                focused={false}
                                defaultValue={this.get_fee_default_values(this.props.data.per_evaluation)}
                                allowNegative={false}
                                className={this.has_validation(expected_rate.identifier.per_evaluation) ? 'numberFormat req-background-inp' : 'numberFormat '}
                            />
                        }
                        {this.get_required_text_div(expected_rate.identifier.per_evaluation)}
                    </Table.Cell>
                    {this.props.features.argosBill1627ExpectedRatesImprovements &&
                        <Table.Cell>
                            {this.props.data.fee_rate_type_id == expected_rate.fee_type.fee_for_service &&
                                this.props.data.max_number_of_units && (
                                    <React.Fragment>
                                        <NumberFormat
                                            id={'units_' + this.props.key_id}
                                            key={'units_' + this.props.data.expected_rate_fee_id}
                                            onValueChange={(value) => this.handle_fee_value_change(expected_rate.identifier.units, value)}
                                            value={this.props.data.units == null ? '' : this.props.data.units}
                                            allowNegative={false}
                                            decimalScale={0}
                                            maxLength="3"
                                            displayType="input"
                                            className={
                                                this.has_validation(expected_rate.identifier.units)
                                                    ? 'numberFormat req-background-inp'
                                                    : 'numberFormat '
                                            }
                                        />
                                        {this.get_required_text_div(expected_rate.identifier.units)}
                                    </React.Fragment>
                                )}
                        </Table.Cell>
                    }
                    <Table.Cell className="icon-column">
                        {this.state.display_on_hover &&
                            <span>
                                <i id={'erf_copy_' + this.props.key_id} onClick={this.copy_expected_rate_fee_definition_prompt.bind(this)}
                                    className="icon copy"
                                />
                                <i id={'erf_trash_' + this.props.key_id} onClick={this.delete_expected_rate_fee_definition_prompt.bind(this)}
                                    className="icon trash"
                                />
                            </span>
                        }
                    </Table.Cell>
                </Table.Row>
                {this.props.data.is_expanded &&
                    <Table.Row>
                    <Table.Cell className="cpt-panel-container" colSpan={this.props.features.argosBill1627ExpectedRatesImprovements ? expected_rate.proc_code_columns_with_max_number_of_units : expected_rate.proc_code_default_columns} key={'cpt_panel_' + this.props.key_id}>
                            <div className="cpt-panel">
                                <FeeProcedureCodesComponent
                                    key={this.props.data.expected_rate_fee_id}
                                    fee_rate_type_id={this.props.data.fee_rate_type_id}
                                    fee_max_number_of_units={this.props.data.max_number_of_units}
                                    data={this.props.data.expected_rate_fee_procedure_codes}
                                    expected_rate_fee_id={this.props.data.expected_rate_fee_id}
                                    updateExpectedRatFeeDefinitionProcedureCode={this.update_expected_rate_fee_definition_procedure_code}
                                    validation={this.get_proc_code_validation_list()}
                                    apply_mppr={this.props.data.mppr}
                                    has_validation={this.has_validation}
                                />
                            </div>
                        </Table.Cell>
                    </Table.Row >
                }
            </React.Fragment>
        );
    }
}

//Get user and login details from store.   
const mapStateToProps = state => {
    return {
        features: {
            argosBill1627ExpectedRatesImprovements: get_lauch_darkley_key_value(state.launch_darkly, argos_bill_1627_expected_rates_improvements)
        }
    };
};
export default connect(mapStateToProps)(FeeDefinitionComponent);