import * as React from "react";
import { Grid, Table } from "semantic-ui-react";
import { amount_formatter } from "../../shared/utility";
function remove_dollar_symbol(data) {
    return data.replace(/[$]/g, '')
}
const AllocatedTotalPaymentInfo = props => {
    return (<Grid className="padd-r-0">
        <Grid.Column computer={16}>
            <div className='customtable mar-t-10'>
                <div>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={3} />
                                <Table.HeaderCell width={3} textAlign="right">
                                    <b>Unallocated Amount</b>
                                </Table.HeaderCell>
                                <Table.HeaderCell width={3} textAlign="right">
                                    <b>Total Payment</b>
                                </Table.HeaderCell>
                                <Table.HeaderCell width={6} textAlign="right" />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>Deductible</Table.Cell>
                                <Table.Cell textAlign="right">{remove_dollar_symbol(amount_formatter(props.paymentsCredits.deductible_amount))}</Table.Cell>
                                <Table.Cell textAlign="right">{remove_dollar_symbol(amount_formatter(props.paymentsCredits.deductible_total))}</Table.Cell>
                                <Table.Cell textAlign="right"><b className="font-bold">Insurance Balance:</b> {remove_dollar_symbol(amount_formatter(props.paymentsCredits.insurance_balance))}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Coinsurance</Table.Cell>
                                <Table.Cell textAlign="right">{remove_dollar_symbol(amount_formatter(props.paymentsCredits.coinsurance_amount))}</Table.Cell>
                                <Table.Cell textAlign="right">{remove_dollar_symbol(amount_formatter(props.paymentsCredits.coinsurance_total))}</Table.Cell>
                                <Table.Cell textAlign="right"><b className="font-bold">Patient Balance:</b> {remove_dollar_symbol(amount_formatter(props.paymentsCredits.patient_balance))}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Copay</Table.Cell>
                                <Table.Cell textAlign="right">{remove_dollar_symbol(amount_formatter(props.paymentsCredits.copay_amount))}</Table.Cell>
                                <Table.Cell textAlign="right">{remove_dollar_symbol(amount_formatter(props.paymentsCredits.copay_total))}</Table.Cell>
                                <Table.Cell textAlign="right"></Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Payment on Account</Table.Cell>
                                <Table.Cell textAlign="right">{remove_dollar_symbol(amount_formatter(props.paymentsCredits.payment_on_account_amount))}</Table.Cell>
                                <Table.Cell textAlign="right">{remove_dollar_symbol(amount_formatter(props.paymentsCredits.payment_on_account_total))}</Table.Cell>
                                <Table.Cell textAlign="right"></Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Patient Refund</Table.Cell>
                                <Table.Cell textAlign="right">{remove_dollar_symbol(amount_formatter(props.paymentsCredits.patient_refund_amount))}</Table.Cell>
                                <Table.Cell textAlign="right">{remove_dollar_symbol(amount_formatter(props.paymentsCredits.patient_refund_total))}</Table.Cell>
                                <Table.Cell textAlign="right"></Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Supply Record</Table.Cell>
                                <Table.Cell textAlign="right">{remove_dollar_symbol(amount_formatter(props.paymentsCredits.supply_record_amount))}</Table.Cell>
                                <Table.Cell textAlign="right">{remove_dollar_symbol(amount_formatter(props.paymentsCredits.supply_record_total))}</Table.Cell>
                                <Table.Cell textAlign="right"></Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Wellness</Table.Cell>
                                <Table.Cell textAlign="right">{remove_dollar_symbol(amount_formatter(props.paymentsCredits.wellness_amount))}</Table.Cell>
                                <Table.Cell textAlign="right">{remove_dollar_symbol(amount_formatter(props.paymentsCredits.wellness_total))}</Table.Cell>
                                <Table.Cell textAlign="right"></Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Medical Record</Table.Cell>
                                <Table.Cell textAlign="right">{remove_dollar_symbol(amount_formatter(props.paymentsCredits.medical_amount))}</Table.Cell>
                                <Table.Cell textAlign="right">{remove_dollar_symbol(amount_formatter(props.paymentsCredits.medical_total))}</Table.Cell>
                                <Table.Cell textAlign="right"></Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Other</Table.Cell>
                                <Table.Cell textAlign="right">{remove_dollar_symbol(amount_formatter(props.paymentsCredits.other_amount))}</Table.Cell>
                                <Table.Cell textAlign="right">{remove_dollar_symbol(amount_formatter(props.paymentsCredits.other_total))}</Table.Cell>
                                <Table.Cell textAlign="right"></Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Interest</Table.Cell>
                                <Table.Cell textAlign="right">{remove_dollar_symbol(amount_formatter(props.paymentsCredits.interest_amount))}</Table.Cell>
                                <Table.Cell textAlign="right">{remove_dollar_symbol(amount_formatter(props.paymentsCredits.interest_total))}</Table.Cell>
                                <Table.Cell textAlign="right"></Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Insurance Overpayment</Table.Cell>
                                <Table.Cell textAlign="right">{remove_dollar_symbol(amount_formatter(props.paymentsCredits.ins_overpayment_amount))}</Table.Cell>
                                <Table.Cell textAlign="right">{remove_dollar_symbol(amount_formatter(props.paymentsCredits.ins_overpayment_total))}</Table.Cell>
                                <Table.Cell textAlign="right"></Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </Grid.Column>
    </Grid>);
};

export default AllocatedTotalPaymentInfo;
