import * as React from 'react';
import { connect } from 'react-redux';
import { toastr as toaster } from 'react-redux-toastr';
import { Button, Form, Grid, Input } from 'semantic-ui-react';
import * as global_constants from '../../../global_constants';
import PromptNavigation from '../../../shared/component/prompt_navigation_component';
import Selection from '../../../shared/component/selection_component';
import { LoaderComponent } from '../../../shared/component/loading_component';
import * as session_storage from '../../../shared/session_storage_utility';
import { set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import * as constants_actions from '../action/constants_action';
import * as constants from '../constants';

class PayerRulesAddEditComponent extends React.Component<any, any> {
    _is_mounted = false;

    constructor(props) {
        super(props);
        // Define initial state value.
        this.state = {
            show_render: true,
            form_object: {},
            is_loading: false,
            is_submitted: false,
            required_error: {},
            required_filed: {},
            by_pass_prompt: false,
            is_save_button_disabled: false
        };
    }

    // Define initial properties and its value.
    form_object: any = {
        id: 0,
        company_id: 0,
        payer_id: '',
        description: '',
        insurance_class_id: '',
        paycode_id: 0,
        adjustment_code_id: 0,
        transfer_to_payer: false,
        transfer_to_next_payer: false,
        not_transfer_to_patient: false
    };

    required_filed: any = {
        payer_id: true,
        insurance_class_id: true,
        paycode_id: true,
        adjustment_code_id: true
    };

    page_name: string = 'add_edit_payer_rules';
    disable_fields = false;
    page_metadata: any;
    token: string = '';
    payer_rule_id: number = 0;
    is_grid_visible = false;
    go_next: boolean = false;
    is_cancel: boolean = false;
    initial_form_object: any = {};
    search_criteria: any = {
        payer_id: '',
        description: '',
        insurance_class_id: '',
        payment_code_id: '',
        adjustment_code_id: '',
    };

    // Fetching the page_name and state.
    UNSAFE_componentWillMount = () => {
        const { user_data } = this.props.user_login_details;
        this.token = user_data.data.token_details.access_token;
        this.form_object['company_id'] = this.props.user_login_details.user_data.data.company_id;
        this.payer_rule_id = this.props.match.params && this.props.match.params.id ? this.props.match.params.id : 0;
        //Verifying page_name is correct or not.
        this.initial_form_object = this.init_fields(this.form_object);
        this.page_metadata = constants.add_edit_payer_rules;
    };

    componentDidMount = () => {
        this._is_mounted = true;
        document.body.classList.add('admin-framework');
        let elem = document.getElementById('search-criteria-container');
        let all_form_elem = elem.getElementsByTagName('input');
        all_form_elem[0].focus();
        if (this.payer_rule_id) {
            this.get_data(this.payer_rule_id);
        }
    };

    componentWillUnmount = () => {
        document.body.classList.remove('admin-framework');
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[2] !== this.page_name && session_storage.remove(`constant_${this.page_name}_criteria`);
        }
    };

    init_fields = (value) => {
        const object = { ...value };
        object.payer_id = value.payer_id || '';
        object.description = value.description || '';
        object.insurance_class_id = value.insurance_class_id || '';
        object.paycode_id = value.paycode_id || '';
        object.adjustment_code_id = value.adjustment_code_id || '';
        object.transfer_to_payer = value.transfer_to_payer || false;
        object.transfer_to_next_payer = value.transfer_to_next_payer || false;
        object.not_transfer_to_patient = value.not_transfer_to_patient || false;
        return object;
    }

    // Get the data on the basis of ID.
    get_data = async (id) => {
        let url = this.page_metadata.api.get.url;
        url = url.replace('id_value', id);
        this.setState({
            is_loading: true
        });
        constants_actions.get_data(this.token, url).then(
            response => {
                if (response.data && response.data.data) {
                    let data = this.checked_value(response.data.data);
                    this.form_object = this.init_fields(data);
                    this.initial_form_object = { ...this.form_object };
                    this.setState({
                        form_object: this.form_object,
                        is_loading: false
                    });
                }
            },
            error => {
                this.setState({
                    is_loading: false
                });
            }
        );
    };

    /**
     * Clear flags if multiple value true
     * To make mutual exculsive
     */
    checked_value = (data) => {
        let count = 0;
        if (data.transfer_to_payer) {
            count++;
        }
        if (data.transfer_to_next_payer) {
            count++;
        }
        if (data.not_transfer_to_patient) {
            count++;
        }

        if (count > 1) {
            data['transfer_to_payer'] = false;
            data['transfer_to_next_payer'] = false;
            data['not_transfer_to_patient'] = false;
        }
        return data;
    }

    //Input change handler.
    input_change_handler = e => {
        const { value, name } = e.target;
        this.form_object[name] = value;
        this.setState(prev_state => ({
            required_error: {
                ...prev_state.required_error,
                [name]: this.is_error(value, name)
            },
            form_object: this.form_object
        }));
    };

    //Input checkbox handler.
    checkbox_handler = e => {
        const { checked, name } = e.target;
        this.form_object[name] = checked;
        this.setState(prev_state => ({
            required_error: {
                ...prev_state.required_error,
                [name]: false
            },
            form_object: this.form_object
        }));
    };

    on_change_handler = e => {
        const { checked, name } = e.target;
        this.form_object['transfer_to_payer'] = false;
        this.form_object['transfer_to_next_payer'] = false;
        this.form_object['not_transfer_to_patient'] = false;
        this.form_object[name] = checked;
        this.setState(prev_state => ({
            required_error: {
                ...prev_state.required_error,
                [name]: false
            },
            form_object: this.form_object
        }));
    };

    //Dropdown change handler
    on_change_dropdown_handler = e => {
        const { value, name } = e.target;
        this.form_object[name] = value == ' ' ? null : value;
        this.setState(prev_state => ({
            required_filed: {
                ...this.required_filed
            },
            required_error: {
                ...prev_state.required_error,
                [name]: false
            },
            form_object: this.form_object
        }));
    };


    // validate each input box on change event
    is_error = (value, name) => {
        if (this.required_filed[name] && !value) {
            return true;
        }
        return false;
    };

    is_valid = () => {
        let is_error = false;
        let required_error = { ...this.state.required_error };
        for (let name in this.form_object) {
            if (this.required_filed[name] && !this.form_object[name]) {
                required_error[name] = true;
                is_error = true;
            }
        }

        if (is_error) {
            this.setState({
                required_error,
            });
            toaster.error('', constants.mandatory_fields);
        }
        return is_error;
    }

    is_selected = () => {
        if (this.form_object['transfer_to_payer'] ||
            this.form_object['transfer_to_next_payer'] ||
            this.form_object['not_transfer_to_patient']) return true;

        toaster.error('', this.show_html_content_toaster(this.page_metadata.validation_message.error_transfer));
        return false;
    }

    //Save the form object and redirect it to search
    save_handler = (e, from_pop_up) => {
        this.setState({ is_submitted: true });
        this.go_next = false;
        //Check validation
        if (this.is_valid()) {
            return;
        }

        //Validate radio group selection 
        if (!this.is_selected()) {
            return;
        }

        //Start loader
        this.setState({
            is_loading: true,
            is_save_button_disabled: true
        });
        session_storage.set('payerrules_search_criteria', { ...this.search_criteria });
        if (this.payer_rule_id) {
            //Update
            let url = this.page_metadata.api.update.url;
            url = url.replace('id_value', this.payer_rule_id);
            constants_actions.update_data(this.token, url, this.form_object).then(
                response => {
                    if (response.data && response.data.data) {
                        this.initial_form_object = { ...this.form_object };
                        this.go_next = true;
                        toaster.success('', this.page_metadata.validation_message.update);
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false
                        }, () => {
                            if (!from_pop_up && this.go_next) {
                                this.go_next = false;
                                this.props.history.goBack();
                            }
                        });
                    } else {
                        if (response.data.messages[0].message.indexOf('<br') > -1) {
                            this.show_html_content_toaster(response.data.messages[0].message);
                        } else {
                            toaster.error('', response.data.messages[0].message);
                        }
                        this.go_next = false;
                        if (this._is_mounted) {
                            this.setState({
                                is_loading: false,
                                is_save_button_disabled: false
                            });
                        }
                    }
                },
                error => {
                    if (this._is_mounted) {
                        this.go_next = false;
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false
                        });
                    }
                    if (error.response.data) toaster.error('', error.response.data.messages[0].message);
                    toaster.error('', this.page_metadata.validation_message.failure);

                }
            );
        } else {
            //Add
            let url = this.page_metadata.api.add.url;
            constants_actions.add_data(this.token, url, this.form_object).then(
                response => {
                    if (response.data && response.data.data) {
                        this.initial_form_object = { ...this.form_object };
                        this.go_next = true;
                        toaster.success('', this.page_metadata.validation_message.save);
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false
                        }, () => {
                            if (!from_pop_up && this.go_next) {
                                this.go_next = false;
                                this.props.history.goBack();
                            }
                        });
                    } else {
                        if (response.data.messages[0].message.indexOf('<br') > -1) {
                            this.show_html_content_toaster(response.data.messages[0].message);
                        } else {
                            toaster.error('', response.data.messages[0].message);
                        }
                        this.go_next = false;
                        if (this._is_mounted) {
                            this.setState({
                                is_loading: false,
                                is_save_button_disabled: false
                            });
                        }
                    }
                },
                error => {
                    if (this._is_mounted) {
                        this.go_next = false;
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false
                        });
                    }
                    if (error.response.data) {
                        toaster.error('', error.response.data.messages[0].message);
                    }
                    toaster.error('', this.page_metadata.validation_message.failure);

                }
            );
        }
    };

    //Function used to Run Report based on controllers values.
    cancel_handler = () => {
        this.initial_form_object = { ...this.form_object };
        this.setState({ by_pass_prompt: true }, () => this.props.history.goBack());
    };

    // Show multiple messages
    show_html_content_toaster = msg => {
        return {
            component: () => (<div><div dangerouslySetInnerHTML={{ __html: msg }} /></div>),
            timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        };
    };

    is_data_unsaved = () => {
        if (JSON.stringify(this.initial_form_object) !== JSON.stringify(this.form_object)) {
            return true;
        }
        return false;
    }

    //It renders report grid and its search controls.
    render() {
        const { form_object, required_error, is_submitted } = this.state;

        return (
            this.state.show_render && (
                <React.Fragment>
                    {!this.state.by_pass_prompt &&
                        (<PromptNavigation
                            is_data_changed={this.is_data_unsaved()}
                            save={e => this.save_handler(e, true)}
                            go_next_location={this.go_next}
                            history={this.props.history}
                        />)}
                    <LoaderComponent loading={this.state.is_loading}>
                        <div className={'admin-wrapper view-edit Locations ' + this.page_metadata.id} style={loaction_css.locations_id}>
                            <Grid className='headerGrid' style={loaction_css.headerGrid}>
                                <Grid.Column computer={16}>
                                    <h3 className='ui header left aligned'>{this.page_metadata.add_header}</h3>
                                </Grid.Column>
                            </Grid>
                            <div className='wrapper' style={loaction_css.wrapper}>
                                <Form autoComplete='off' name='view_edit_form' className='common-forms' style={loaction_css.common_forms}>
                                    <div id='admin-scrollable-area' style={loaction_css.scrollbar_area}>
                                        <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container' style={loaction_css.patient_search_form}>
                                            <Grid>
                                                <Grid.Column tablet={8} computer={4}>
                                                    <Form.Field>
                                                        <label>
                                                            Payer{' '}
                                                            {this.required_filed.payer_id && (
                                                                <span className={!form_object.payer_id && this.required_filed.payer_id && is_submitted && required_error.payer_id ? 'req-alert' : 'req-alert_normal'}>
                                                                    (required)
                                                             </span>
                                                            )}
                                                        </label>
                                                        <Selection
                                                            id='payer_id'
                                                            name='payer_id'
                                                            placeHolder={'Select'}
                                                            hidden={true}
                                                            options={this.props.location.state.payers}
                                                            onChange={(value, e) => {
                                                                this.on_change_dropdown_handler(e);
                                                            }}
                                                            defaultValue={this.form_object.payer_id || ''}
                                                            style={
                                                                !form_object.payer_id && this.required_filed.payer_id && is_submitted && required_error.payer_id
                                                                    ? 'dropdown-options-wrap req-background-inp'
                                                                    : 'dropdown-options-wrap'
                                                            }
                                                            disabled={this.disable_fields}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column tablet={8} computer={4}>
                                                    <Form.Field>
                                                        <label>Description</label>
                                                        <Input
                                                            autoComplete='off'
                                                            id='description'
                                                            name='description'
                                                            maxLength={250}
                                                            onChange={e => this.input_change_handler(e)}
                                                            value={this.form_object['description'] ? this.form_object['description'] : ''}
                                                            type='text'
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column tablet={8} computer={4}>
                                                    <Form.Field>
                                                        <label>
                                                            Insurance Class{' '}
                                                            {this.required_filed.insurance_class_id && (
                                                                <span className={!form_object.insurance_class_id && this.required_filed.insurance_class_id && is_submitted && required_error.insurance_class_id ? 'req-alert' : 'req-alert_normal'}>
                                                                    (required)
                                                             </span>
                                                            )}
                                                        </label>
                                                        <Selection
                                                            id='insurance_class_id'
                                                            name='insurance_class_id'
                                                            placeHolder={'Select'}
                                                            hidden={true}
                                                            options={this.props.location.state.insurance_class}
                                                            onChange={(value, e) => {
                                                                this.on_change_dropdown_handler(e);
                                                            }}
                                                            defaultValue={this.form_object.insurance_class_id || ''}
                                                            style={
                                                                !form_object.insurance_class_id && this.required_filed.insurance_class_id && is_submitted && required_error.insurance_class_id
                                                                    ? 'dropdown-options-wrap req-background-inp'
                                                                    : 'dropdown-options-wrap'
                                                            }
                                                            disabled={this.disable_fields}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column tablet={8} computer={4}>
                                                    <Form.Field>
                                                        <label>
                                                            Payment Code{' '}
                                                            {this.required_filed.paycode_id && (
                                                                <span className={!form_object.paycode_id && this.required_filed.paycode_id && is_submitted && required_error.paycode_id ? 'req-alert' : 'req-alert_normal'}>
                                                                    (required)
                                                             </span>
                                                            )}
                                                        </label>
                                                        <Selection
                                                            id='paycode_id'
                                                            name='paycode_id'
                                                            placeHolder={'Select'}
                                                            hidden={true}
                                                            options={this.props.location.state.payment_codes}
                                                            onChange={(value, e) => {
                                                                this.on_change_dropdown_handler(e);
                                                            }}
                                                            defaultValue={this.form_object.paycode_id || ''}
                                                            style={
                                                                !form_object.paycode_id && this.required_filed.paycode_id && is_submitted && required_error.paycode_id
                                                                    ? 'dropdown-options-wrap req-background-inp'
                                                                    : 'dropdown-options-wrap'
                                                            }
                                                            disabled={this.disable_fields}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column tablet={8} computer={4}>
                                                    <Form.Field>
                                                        <label>
                                                            Adjustment Code{' '}
                                                            {this.required_filed.adjustment_code_id && (
                                                                <span className={!form_object.adjustment_code_id && this.required_filed.adjustment_code_id && is_submitted && required_error.adjustment_code_id ? 'req-alert' : 'req-alert_normal'}>
                                                                    (required)
                                                             </span>
                                                            )}
                                                        </label>
                                                        <Selection
                                                            id='adjustment_code_id'
                                                            name='adjustment_code_id'
                                                            placeHolder={'Select'}
                                                            hidden={true}
                                                            options={this.props.location.state.adjustment_codes}
                                                            onChange={(value, e) => {
                                                                this.on_change_dropdown_handler(e);
                                                            }}
                                                            defaultValue={this.form_object.adjustment_code_id || ''}
                                                            style={
                                                                !form_object.adjustment_code_id && this.required_filed.adjustment_code_id && is_submitted && required_error.adjustment_code_id
                                                                    ? 'dropdown-options-wrap req-background-inp'
                                                                    : 'dropdown-options-wrap'
                                                            }
                                                            disabled={this.disable_fields}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column tablet={8} computer={4}>
                                                    <Form.Field >
                                                        <label><p className="ui-grid-label-empty">TEXT</p></label>
                                                        <div className="checkbox">
                                                            <input
                                                                name="transfer_to_payer"
                                                                id="transfer_to_payer"
                                                                type="radio"
                                                                checked={this.form_object.transfer_to_payer}
                                                                onChange={this.on_change_handler}
                                                            />
                                                            <label className="CheckLabel" htmlFor={"transfer_to_payer"}>Transfer Back to Payer</label>
                                                        </div>
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column tablet={8} computer={4}>
                                                    <Form.Field >
                                                        <label><p className="ui-grid-label-empty">TEXT</p></label>
                                                        <div className="checkbox">
                                                            <input
                                                                name="transfer_to_next_payer"
                                                                id="transfer_to_next_payer"
                                                                type="radio"
                                                                onChange={this.on_change_handler}
                                                                checked={this.form_object.transfer_to_next_payer}
                                                            />
                                                            <label className="CheckLabel" htmlFor={"transfer_to_next_payer"}>Transfer to Next Payer</label>
                                                        </div>
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column tablet={8} computer={4}>
                                                    <Form.Field >
                                                        <label><p className="ui-grid-label-empty">TEXT</p></label>
                                                        <div className="checkbox">
                                                            <input
                                                                name="not_transfer_to_patient"
                                                                id="not_transfer_to_patient"
                                                                type="radio"
                                                                checked={this.form_object.not_transfer_to_patient}
                                                                onChange={this.on_change_handler}
                                                            />
                                                            <label className="CheckLabel" htmlFor={"not_transfer_to_patient"}>Do Not Transfer to Patient</label>
                                                        </div>
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid>
                                        </div>
                                    </div>
                                    <div className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area' id='applicationFooterSticky' style={loaction_css.footerArea}>
                                        <Button
                                            id='cancel_button_id'
                                            type='button' onClick={this.cancel_handler}
                                            basic>Cancel</Button>
                                        <Button
                                            id='save_button_id'
                                            type='button'
                                            disabled={this.state.is_save_button_disabled}
                                            onKeyDown={set_focus_to_app_header}
                                            onClick={e => this.save_handler(e, false)}
                                            primary style={loaction_css.save_button}>Save</Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </LoaderComponent>
                </React.Fragment>
            )
        );
    }
}
// Css Style
const loaction_css = {
    locations_id: {
        paddingBottom: 0
    },
    headerGrid: {
        marginTop: 0,
        marginBottom: 0,
        paddingRight: 15
    },
    wrapper: {
        borderTop: 0,
        marginBottom: 0
    },
    common_forms: {
        paddingTop: 0,
        height: '100%',
        paddingBottom: 0,
        display: 'flex',
        flexDirection: 'column'
    } as React.CSSProperties,

    patient_search_form: {
        paddingBottom: 0,
        flexBasis: 'auto',
        borderColor: '#cccccc',
        borderStyle: 'solid',
        borderWidth: '1px 0px 0px',
        margin: '0 0 15px 0px',
        padding: '25px 15px 0 0'
    },
    footerArea: {
        paddingLeft: 0,
        paddingBottom: 0,
        marginBottom: 12,
        paddingRight: 25,
        paddingTop: 20
    },
    scrollbar_area: {
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column'
    } as React.CSSProperties,
    save_button: {
        marginRight: 0
    }
};
//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
        shared_details: state.shared_details,
    };
};

export default connect(mapStateToProps)(PayerRulesAddEditComponent);