import * as moment from 'moment';
import * as React from "react";
import { connect } from "react-redux";
import { toastr } from 'react-redux-toastr';
import { Button, Dimmer, Form, Grid, Loader } from "semantic-ui-react";
import { export_grid_data, print_grid_data, generateAuditDataEntryPayload } from '../../../reports/util/export_print_utility';
import GridView from '../../../shared/component/grid';
import * as local_storage from '../../../shared/local_storage_utility';
import * as session_storage from '../../../shared/session_storage_utility';
import { set_column_data_width, get_columns } from '../../../shared/utility';
import ClaimAdjCodeSearch from "../../constants/component/claim_adjustment_code_advanced_search";
import { adj_payer_advanced_search, get_data, get_search_data } from '../action/constants_action';
import * as payment_constants from '../constants';
import CheckboxComponent from './../../../shared/component/checkbox_component';
import * as global_constants from './../../../global_constants';
import { set_focus_on_element_with_id, set_focus_to_app_header } from './../../../shared/tab_navigation_utility';
import * as ReportConstants from '../../../reports/report_constants';

class ClaimAdjCodePayerOverrideSearchComponent extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            patient_id: session_storage.get("active_patient"),
            loading: false,
            show_grid: false,
            control: null,
            search_form: {
                adj_code_id: '',
                payer_id: '',
                is_excluded: false,
                show_inactive: false,
                search_criteria: []
            },
            claim_adj_search_data: null,
            payer_search_data: null,
            adj_code_selected_row: null,
            payer_selected_row: null,
            grid_rows_count: 0,
            is_search_button_disabled: false
        };
        this.claimadj_codesearch = React.createRef();
        this.claimpayer_codesearch = React.createRef();
    }
    empty_search_form = null;
    adjustment_code_enum = "adjustment_code";
    payer_enum = "payer";
    page_name: string = "claim_adj_code_pay_ov";
    page_metadata = {
        search_header: "Claim Adjustment Reason Codes - Payer Override Search"
    }
    id = 0;
    token = "";
    _is_mounted = false
    search_criteria: any = {
        adj_code_id: '',
        payer_id: '',
        is_excluded: false,
        show_inactive: false
    };
    grid_ref = { api: null };
    is_grid_visible = false;
    is_search_ui: any = false;
    row_data = [];
    total_column_width: number = 0;
    grid_header_height: number = 0;
    claimadj_codesearch: any;
    claimpayer_codesearch: any;
    company_name: any;
    current_date: string;
    title: any;
    reportId: string = "0";

    UNSAFE_componentWillMount() {
        this.empty_search_form = { ...this.state.search_form };
    }

    set_criteria_in_state = (search_criteria) => {
        let { search_form, payer_selected_row, adj_code_selected_row } = this.state
        for (let key in search_criteria) {
            search_form[key] = search_criteria[key]
            if (key == "adj_code_id" || key == "adj_code_selected_row")
                adj_code_selected_row = search_criteria.adj_code_selected_row
            if (key == "payer_id" || key == "payer_selected_row")
                payer_selected_row = search_criteria.payer_selected_row
        }
        this.setState({
            search_form,
            payer_selected_row,
            adj_code_selected_row
        }, () => {
            this.on_search();
        });
    }
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.user_login_details != this.props.user_login_details) {
            this.set_default_focus();
            this.handle_tab_on_search();
            this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        }
    }


    on_quick_search = async (params, type) => {
        let url = '';
        if (type == this.adjustment_code_enum) {
            url = payment_constants.eob_claim_adjcode_search.api.claim_adj_quick_search + "?keyword=" + params + "&pageSize=" + global_constants.constants.Quick_Search_Suggestion_List_Size;
            return await get_search_data(this.token, url);
        } else {
            url = payment_constants.claim_payer_search.api.payer_quick_search + "?keyword=" + params + "&page_size=" + global_constants.constants.Quick_Search_Suggestion_List_Size;
            let result = await get_search_data(this.token, url);
            result.data.data = result.data && result.data.data ? result.data.data.sort((a, b) => a.name.localeCompare(b.name)) : result
            return result
        }
    }

    on_advanced_search = async (params, type) => {

        if (this.adjustment_code_enum == type) {
            let query_params = "?pageSize=5000"
            if (params["adjustment_code"])
                query_params = `${query_params}&code=${params.adjustment_code}`
            if (params["adjustment_desc"])
                query_params = `${query_params}&description=${params.adjustment_desc}`
            let url = `${payment_constants.eob_claim_adjcode_search.api.eob_claim_adjcode_search}${query_params}`
            this.get_result(url);
        } else {
            params.Description = params.Description || ""
            params.Outbound_id = params.Outbound_id || ""
            params.name = params.name || ""
            params.Inbound_id = params.Inbound_id || ""
            adj_payer_advanced_search(params, this.token).then((res) => {
                this.setState({
                    payer_search_data: {
                        rows: res.data.data,
                        column: payment_constants.claim_payer_search.column_defs
                    }
                })
            }, error => {
                if (error.response && error.response.data && error.response.data.messages) {
                    toastr.error('', error.response.data.messages[0].message);
                }
            });
        }

    }

    get_result = (url) => {
        get_data(this.token, url).then((res) => {
            let result = res.data;
            if (result) {
                result = result.data.map((val, index) => {
                    val.is_text_excluded = val.is_excluded ? "True" : "False";
                    return val;
                });
            }
            this.setState({
                claim_adj_search_data: {
                    rows: result,
                    column: payment_constants.eob_claim_adjcode_search.column_defs
                }
            });
        }, error => {
            if (error.response && error.response.data && error.response.data.messages) {
                toastr.error('', error.response.data.messages[0].message);
            }
        });
    }

    update_data = (data, type) => {
        if (type == this.adjustment_code_enum) {
            data && (data.is_excluded = data.is_excluded.toString())
            this.setState({
                search_form: {
                    ...this.state.search_form, adj_code_id: data ? data.carc : ''
                },
                adj_code_selected_row: data

            });
        } else {
            this.setState({
                search_form: {
                    ...this.state.search_form, payer_id: data ? data.payer_key_id : ''
                },
                payer_selected_row: data
            });
        }
    }

    select_grid_checkbox = (flag) => {
        if (this.grid_ref.api != null) {
            this.grid_ref.api.forEachNode((node) => {
                node.setSelected(true);
            });
        }
    }

    on_search = () => {
        const { search_form } = this.state
        this.setState({
            loading: true,
            is_search_button_disabled: true
        });
        const token = local_storage.get("auth_data").token_details.access_token;
        let queryString = `?pageSize=5000&isActive=${search_form.show_inactive}&isDefExcl=${search_form.is_excluded}`;
        if (search_form.adj_code_id != "") {
            queryString += `&adjCode=${search_form.adj_code_id}`
        }
        if (search_form.payer_id != "") {
            queryString += `&payerID=${search_form.payer_id}`
        }
        let url = `${payment_constants.eob_claim_adjcode_search.api.get.url}${queryString}`
        get_search_data(this.token, url).then(res => {
            if (res.data) {
                this.row_data = res.data.data ? res.data.data : []
                this.row_data = this.row_data.map((val, index) => {
                    val.adj_code_desc = val.adj_code + " - " + val.lk10096_Desc;
                    val.id = val.eob_claimadjcode_payer_ov_id
                    val.is_active = val.is_active ? "Active" : "Inactive";
                    val.is_def_excl = val.is_def_excl ? "True" : "False";
                    return val;
                });
                const grid_height = get_columns(this.row_data, payment_constants.eob_claim_adjcode_search.pay_ov_column_def);
                this.total_column_width = grid_height.total_column_width;
                this.grid_header_height = grid_height.header_height;
                if (this._is_mounted) {
                    this.setState({
                        show_grid: true,
                        loading: false,
                        grid_rows_count: this.row_data && this.row_data.length,
                        is_search_button_disabled: false
                    }, () => {
                        this.select_grid_checkbox(true);
                    });
                    this.set_search_criteria_onsearch()
                }
            }
        }, error => {
            this.setState({
                loading: false,
                is_search_button_disabled: false
            })
            if (error.response.data)
                toastr.error('', error.response.data.messages[0].message);
        })
    }



    componentDidMount = () => {
        this._is_mounted = true;
        document.body.classList.add('admin-framework');
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        let controls = {
            adjustment_code: {
                type: "adjustment_code",
                grid_config: {
                    rows: null,
                    column: payment_constants.claim_adjust_code.column_def
                },
                control_id: 'CLAIM_ADJUSTMENT_CODE',
                selected_row: null,
                error_message: 'No Record Found !',
                label: ["carc", "description"]
            },
            payer: {
                type: "payer",
                grid_config: {
                    rows: null,
                    column: payment_constants.claim_payer_search.column_defs
                },
                control_id: 'PAYER_DESCRIPTION',
                error_message: 'No Record Found !',
                label: ["name", "description"],
                selected_row: null,
            },
        }
        this.setState({
            control: controls
        });

        let claim_adj_search_criteria = session_storage.get('claim_adj_reason_code_search_criteria');
        this.search_criteria = claim_adj_search_criteria && claim_adj_search_criteria.search_criteria || this.search_criteria
        if (claim_adj_search_criteria) {
            this.set_criteria_in_state(this.search_criteria)
        }
        this.set_default_focus();
        this.handle_tab_on_search();
    }

    // Will unmount
    componentWillUnmount = () => {
        document.body.classList.remove('admin-framework');
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[2] !== 'claim_adj_code_pay_ov' && session_storage.remove('claim_adj_reason_code_search_criteria');
        }
    }
    //On double click of grid, it redirects user to view page.
    on_row_double_clicked = (selected_row_data) => {
        this.id = selected_row_data["id"];
        // this.set_search_criteria_onsearch()
        this.props.history.push(`/payments/claim_adj_code_pay_ov/${this.id}`, { by_pass_prompt: false });
    }
    add_handler = (e) => {
        e.preventDefault()
        this.props.history.push(`/payments/claim_adj_code_pay_ov`, { search_criteria: this.search_criteria });
    }

    get_grid_ref = (grid_params) => {
        this.setState({
            grid_params,
            grid_rows_count: grid_params.api.getModel().getRowCount()
        });
        var grid_width = document.getElementsByClassName('admin-wrapper')[0].clientWidth;
        if (this.total_column_width > grid_width) {
            payment_constants.eob_claim_adjcode_search.pay_ov_column_def.filter(item => {
                grid_params.columnApi.setColumnWidth(item.field, item.width, false)
            })
        }
        this.grid_ref = grid_params;
    }

    set_search_criteria_onsearch = () => {
        const { search_form, adj_code_selected_row, payer_selected_row } = this.state
        this.search_criteria = {
            show_inactive: search_form.show_inactive,
            is_excluded: search_form.is_excluded
        }
        if (search_form.adj_code_id != "") {
            this.search_criteria.adj_code_id = search_form.adj_code_id,
                this.search_criteria.adj_code_selected_row = adj_code_selected_row
        }
        if (search_form.payer_id != "") {
            this.search_criteria.payer_id = search_form.payer_id,
                this.search_criteria.payer_selected_row = payer_selected_row
        }

        session_storage.set('claim_adj_reason_code_search_criteria', {
            search_criteria: this.search_criteria,
        });
    }

    clear_handle = () => {
        let initial_form = { ...this.empty_search_form }
        this.setState({
            search_form: initial_form,
            show_grid: false,
            adj_code_selected_row: null,
            payer_selected_row: null,
            grid_rows_count: 0
        });
        this.search_criteria = {}
        this.claimadj_codesearch.clear_quick_search(this.claimadj_codesearch.props.control.type)
        this.claimpayer_codesearch.clear_quick_search(this.claimpayer_codesearch.props.control.type)
        session_storage.remove('claim_adj_reason_code_search_criteria');
    }

    set_default_focus = () => {
        let elem = document.getElementById('search-criteria-container');
        let all_form_elem = elem.getElementsByTagName('input');
        all_form_elem[0].focus();
    };

    handle_tab_on_search = () => {
        setTimeout(() => {
            let searchButton = document.getElementById('search_button_id');
            let self = this;
            searchButton.addEventListener('keydown', function (event) {
                // apply a check where grid is empty
                if (!event.shiftKey && (self.row_data == null || (self.row_data.length == 0 && !self.state.show_grid))) {
                    set_focus_to_app_header(event);
                }
            });
        }, 200);
    };

    on_grid_out = () => {
        set_focus_on_element_with_id("app_header_dropdown");
    }
    update_report_rows_count = () => {
        this.setState({
            grid_rows_count: this.state.grid_params.api.getModel().getRowCount()
        });
    };

    //Used to create the title for export and print.
    set_title = (separator) => {
        this.company_name = this.props.user_login_details.user_data.data.company_name;
        this.current_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
        this.title = this.page_metadata.search_header;
        return (this.title =
            this.title.replace(/<br>/g, separator) +
            separator +
            this.company_name +
            separator +
            this.current_date +
            separator +
            (this.state.grid_rows_count == 1
                ? this.state.grid_rows_count + ' record'
                : this.state.grid_rows_count + ' records') +
            separator +
            separator);
    };

    //Function calls on initialization of export report data
    on_export_button = () => {
        this.title = this.set_title('\n');
        this.page_name = this.page_name.replace(/<br>/g, '');
        export_grid_data(this.state.grid_params, this.title, this.page_name);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Export);
    };

    //Function calls on initialization of Print report data
    on_print_button = () => {
        this.title = this.set_title('<br>');
        print_grid_data(this.state.grid_params, this.title, this.page_name);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
    };

    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;

        const payload: ReportConstants.IPayloadForBillingAudit = {
            reportId: this.reportId,
            contextTitle: `${this.page_metadata.search_header} - ${ReportConstants.ReportEventAction[eventActionId]}`,
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: ReportConstants.ReportEntityTypeId.D_CLAIM,
            data: { Records: Number(this.state.grid_params.api.rowModel.rowsToDisplay.length) }
        }

        const reqBody = generateAuditDataEntryPayload(payload);
        ReportConstants.saveAuditPrintExport(reqBody, accessToken);
    };

    render() {
        return (<React.Fragment>
            <Dimmer active={this.state.loading}>
                <Loader size='massive'>Loading</Loader>
            </Dimmer>
            <div className={'admin-wrapper'}>
                <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                    <Grid.Column computer={16}>
                        <h3 className='ui header left aligned' dangerouslySetInnerHTML={{ __html: this.page_metadata.search_header }} />
                    </Grid.Column>
                </Grid>
                <div id='admin-scrollable-area' className='wrapper' style={this.state.show_grid ? { flex: 1 } : {}}>
                    <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container'>
                        <Form autoComplete='off' onSubmit={this.on_search}>
                            <Grid>
                                <Grid.Column mobile={8} tablet={8} computer={4}>
                                    <Form.Field className="advance-quick-search">
                                        <label>Claim Adjustment Reason Code</label>
                                        <ClaimAdjCodeSearch
                                            control={this.state.control ? this.state.control.adjustment_code : null}
                                            update_data={this.update_data}
                                            search_result={this.state.claim_adj_search_data}
                                            on_quick_search={this.on_quick_search}
                                            on_advanced_search={this.on_advanced_search}
                                            is_focused={true}
                                            selected_row={this.state.adj_code_selected_row}
                                            onRef={instance => { this.claimadj_codesearch = instance; }}
                                            headerIdForGridTabNavigation={payment_constants.eob_claim_adjcode_search_grid_header_id}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column mobile={8} tablet={8} computer={4}>
                                    <Form.Field className="advance-quick-search">
                                        <label>Payer</label>
                                        <ClaimAdjCodeSearch
                                            on_quick_search={this.on_quick_search}
                                            on_advanced_search={this.on_advanced_search}
                                            search_result={this.state.payer_search_data}
                                            control={this.state.control ? this.state.control.payer : null}
                                            onRef={instance => { this.claimpayer_codesearch = instance; }}
                                            update_data={this.update_data} selected_row={this.state.payer_selected_row}
                                            headerIdForGridTabNavigation={payment_constants.claim_payer_search_header_id} />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={8} computer={3} verticalAlign={'bottom'} textAlign={'left'}>
                                    <Form.Field className="">
                                        <CheckboxComponent
                                            label='Is Excluded'
                                            id="pay_ov_is_exclude"
                                            checked={this.state.search_form.is_excluded}
                                            onChange={(evt, checked) => {
                                                this.setState((prev) => ({
                                                    search_form: { ...prev.search_form, is_excluded: checked }
                                                }))
                                            }}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={8} computer={4} verticalAlign={'bottom'} textAlign={'left'}>
                                    <Form.Field className="">
                                        <CheckboxComponent
                                            label='Show Inactive'
                                            id="claim_adj_pay_ov_inative"
                                            checked={this.state.search_form.show_inactive}
                                            onChange={(evt, checked) => {
                                                this.setState((prev) => ({
                                                    search_form: {
                                                        ...prev.search_form, show_inactive: checked
                                                    }
                                                }))
                                            }}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid>
                            <Grid style={{ margin: '0 -17px' }}>
                                <Grid.Column computer={16} textAlign='right'>
                                    <Button id='clear_button_id' type='button' onClick={this.clear_handle} basic>Clear</Button>
                                    <Button id='new_button_id' type='button' onClick={e => this.add_handler(e)} basic>New</Button>
                                    <Button id='search_button_id' type='submit' primary disabled={this.state.is_search_button_disabled}>Search</Button>
                                </Grid.Column>
                            </Grid>
                            {this.state.show_grid && (
                                <Grid style={{ marginTop: '-1rem', marginBottom: 0 }}>
                                    <Grid.Column tablet={5} computer={4} textAlign='left'>
                                        <p style={{ fontSize: '16px' }}>Search Results</p>
                                    </Grid.Column>
                                    <Grid.Column tablet={5} computer={8} textAlign='center'>
                                        {
                                            <p style={{ fontSize: '16px', minHeight: 22 }}>
                                                {`${this.state.grid_rows_count} ${
                                                    this.state.grid_rows_count == 1 ? ' record shown' : ' records shown'
                                                    }`}
                                            </p>
                                        }
                                    </Grid.Column>
                                    <Grid.Column tablet={2} computer={4} textAlign='right' />
                                </Grid>
                            )}
                        </Form>
                    </div>
                    {this.state.show_grid && !this.state.loading && (
                        <GridView
                            id="claim_adj_pay_ovr_search"
                            row={this.row_data}
                            column={payment_constants.eob_claim_adjcode_search.pay_ov_column_def}
                            selectionType={'single'}
                            style={{ height: '100%' }}
                            wrapperStyle={{ width: '100%', height: 0, display: 'flex', flex: '1 1 auto' }}
                            onRowDoubleClicked={this.on_row_double_clicked}
                            get_grid_ref={this.get_grid_ref}
                            suppressMovableColumns={false}
                            enableColResize={true}
                            suppressSizeToFit={true}
                            onForceGridOut={this.on_grid_out}
                            headerHeight={this.grid_header_height}
                            headerIdForTabNavigation={payment_constants.pay_ov_grid_header_id}
                            on_filter_button_click={() => this.update_report_rows_count()}
                        />
                    )}
                </div>
                {this.state.show_grid && (
                    <div
                        className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                        id='applicationFooterSticky'
                        style={{ paddingLeft: 0, paddingRight: 0 }}
                    >
                        <Grid.Column computer={16} textAlign='right'>
                            <Button id='export_report_button' type='submit' onClick={this.on_export_button} basic>
                                Export
                        </Button>
                            <Button
                                onKeyDown={set_focus_to_app_header}
                                id='print_report_button'
                                type='submit'
                                onClick={this.on_print_button}
                                primary
                                style={{ marginRight: 0 }}
                            >
                                Print
                        </Button>
                        </Grid.Column>
                    </div>
                )}
            </div>
        </React.Fragment>
        );
    }
}


//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details
    };
};
export default connect(mapStateToProps, null)(ClaimAdjCodePayerOverrideSearchComponent);