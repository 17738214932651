import * as React from 'react';
import { Dimmer, Loader } from "semantic-ui-react";

const LoaderComponent = (props) => {
    return (<Dimmer.Dimmable
        dimmed={props.loading}
        className={`loader-container ${props.wrapperClass}`}
        style={props.style}>
        <Dimmer active={props.loading}>
            <Loader size='massive'>Loading</Loader>
        </Dimmer>
        {props.children}
    </Dimmer.Dimmable>);
};

export { LoaderComponent };