import * as React from "react";
import { connect } from "react-redux";
import * as moment from "moment";
import { Button, Dimmer, Form, Grid, Input, Loader, Header } from "semantic-ui-react";
import { get_batch_task_log_details } from "../action/batch_tasks_action";
import { dateTimeFormatter } from "../../../reports/report_constants";
import ReportHeader from "../../../reports/component/report_header";
import ReportFooter from "../../../reports/component/report_footer";
import { card_print, downloadCSV } from "../../../reports/util/export_print_utility";
import { batch_log_card_columns } from "../batch_tasks_constants";
import { show_html_content_toaster, get_title, get_sub_title, print_card_template, export_card_content } from '../utility';

export class BatchTaskLogDetailComponent extends React.Component<any, any> {
    handle_navigation_after_last_element: any;
    is_mounted: boolean;

    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            batch_log_details_grid_visible: false,
            batch_task_run_details: {}
        };
    }

    UNSAFE_componentWillMount = async () => {
    }

    componentDidMount = async () => {
        this.is_mounted = true;
        document.body.classList.add("reports");

        let log_id = this.props.match.params.log_id
        let token = this.props.user_login_details.user_data.data.token_details.access_token;
        await get_batch_task_log_details(token, log_id).then((response) => {
            this.setState({
                loading: false,
                batch_task_run_details: response.data.data,
                batch_log_details_grid_visible: true
            })
        });
    };

    componentWillUnmount = () => {
        document.body.classList.remove("reports");
        this.is_mounted = false;
    };

    // Function calls on initialization of export report data
    on_export_button = () => {
        let date = moment(new Date()).format("MM/DD/YYYY") + " " + moment(new Date()).format("hh:mm:ss A");
        let title_row = ['Batch Log', '\n' + date+'\n']
        let csv = [title_row];
        csv.push(export_card_content(batch_log_card_columns, this.state.batch_task_run_details));
        downloadCSV(csv, "Batch Log");
    };

    // Function calls on initialization of Print report data
    on_print_button = () => {
        let content = get_title('Batch Log', '<br/>', print_card_template(batch_log_card_columns, this.state.batch_task_run_details));
        card_print('Batch Log', content);
    };

    render_batch_log_details = () => {
        if (this.state.batch_log_details_grid_visible) {
            return (<Form><Grid style={{ padding: 15 }}>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                        <label>Run</label>
                        <div className="values">
                            {this.state.batch_task_run_details.batch_run_name}
                        </div>
                    </Form.Field>
                </Grid.Column>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                        <label>Program</label>
                        <div className="values">
                            {this.state.batch_task_run_details.batch_program_name}
                        </div>
                    </Form.Field>
                </Grid.Column>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                        <label>Date</label>
                        <div className="values">
                            {dateTimeFormatter(this.state.batch_task_run_details.log_dt)}
                        </div>
                    </Form.Field>
                </Grid.Column>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                        <label>Type</label>
                        <div className="values">
                            {this.state.batch_task_run_details.log_type}
                        </div>
                    </Form.Field>
                </Grid.Column>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                        <label>Category</label>
                        <div className="values">
                            {this.state.batch_task_run_details.category}
                        </div>
                    </Form.Field>
                </Grid.Column>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                        <label>Priority</label>
                        <div className="values">
                            {this.state.batch_task_run_details.priority}
                        </div>
                    </Form.Field>
                </Grid.Column>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                        <label>Machine</label>
                        <div className="values">
                            {this.state.batch_task_run_details.machine_name}
                        </div>
                    </Form.Field>
                </Grid.Column>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                        <label>Thread</label>
                        <div className="values">
                            {this.state.batch_task_run_details.managed_thread_id}
                        </div>
                    </Form.Field>
                </Grid.Column>
                <Grid.Column tablet={16} computer={16}>
                    <Form.Field>
                        <label>Message</label>
                        <div className="values">
                            {this.state.batch_task_run_details.message}
                        </div>
                    </Form.Field>
                </Grid.Column>
            </Grid></Form>);
        }
        return null;
    }

    render() {
        return (
            <div className={"common-forms-add"}>
                <Dimmer active={this.state.loading}>
                    <Loader size="massive">Loading</Loader>
                </Dimmer>
                <div className={"common-forms-search report-framework batch-tasks-program"} style={{ paddingRight: 0 }}>
                    <ReportHeader title={"Batch Log"} />
                    <div style={{ overflow: "auto", paddingRight: 20, flex: "1 1 0" }}>
                        <div
                            id="report-scrollable-area"
                            className="report-wrapper"
                            style={this.state.batch_log_details_grid_visible ? { flex: 1 } : {}}
                        >
                            {this.render_batch_log_details()}
                        </div>
                    </div>
                    <div
                        className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area"
                        id="applicationFooterSticky"
                        style={{ paddingRight: 25 }}
                    >
                        <ReportFooter
                            on_print_button={this.on_print_button}
                            on_export_button={this.on_export_button}
                            export_disabled={this.state.is_export_disabled}
                            print_disabled={this.state.is_print_disabled}
                        />
                    </div>
                </div>
            </div>);
    }
}


// Get user and login details from store.
const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details
    };
};

export default connect(mapStateToProps)(BatchTaskLogDetailComponent);
