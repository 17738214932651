import { date_format } from '../../global_constants';
import * as patient_constants from '../patient_constants';
import { custom_date_format } from './../../shared/utility';

export default function (state = [], { type, payload }) {

    switch (type) {

        case patient_constants.PATIENT_HEADER:
            return { ...state, ["patient_header"]: payload.data };

        case patient_constants.GET_PATIENTS:
            return { ...state, ["patients"]: payload.data };

        case patient_constants.PATIENT_HEADER_CASE:
            return { ...state, ["patient_header_case"]: payload.data };

        case patient_constants.DOCTOR_ORDER:
            return { ...state, ["doctors_order"]: payload.data };

        // Single patient details
        case patient_constants.GET_PATIENT_DETAILS:
            return { ...state, ["selected_patient"]: payload.data };

        // get selected patient medicare cap data
        case patient_constants.GET_PATIENT_MEDICARE_CAP:
            return { ...state, ["selected_patient_medicare_cap"]: payload.data };

        //add update selected patient medicare cap data
        case patient_constants.ADD_UPDATE_PATIENT_MEDICARE_CAP:
            return { ...state, ["selected_patient_medicare_cap_updated_data"]: payload.data };


        // Patient Task details
        case patient_constants.GET_PATIENT_TASK_AND_ACCOUNT_NOTES:

            var output = format_account_notes(payload.task, payload.account_notes)

            return { ...state, ["selected_patient_task_and_account_notes"]: output };

        // get patient task list
        case patient_constants.GET_PATIENT_ACCOUNT_TASKS:
            return { ...state, ["patient_task"]: payload };
       // get patient account note list
        case patient_constants.GET_PATIENT_ACCOUNT_NOTES:
            return { ...state, ["patient_account_notes"]: payload };

        // Patient Remove Account notes
        case patient_constants.REMOVE_PATIENT_ACCOUNT_NOTES:
            return { ...state, ["selected_patient_remove_account_notes"]: payload.data };

        // Patient Add Account Notes
        case patient_constants.ADD_PATIENT_ACCOUNT_NOTES:
            return { ...state, ["selected_patient_add_account_notes"]: payload.data };

        // Patient Add Note Codes
        case patient_constants.GET_PATIENT_ACCOUNT_NOTES_CODE:
            return { ...state, ["selected_patient_account_notes_codes"]: payload.data };

        case patient_constants.PATIENT_COLLECTION_FLAG:
            return { ...state, ["patient_collection_flag"]: payload };

        // Patient Medical records- output reports
        case patient_constants.GET_PATIENT_GET_MEDICAL_OUTPUT_REPORTS:
            return { ...state, ["medical_output_reports"]: payload };
        // Patient Medical records- scanned documents
        case patient_constants.GET_PATIENT_GET_MEDICAL_SCANNED_DOCUMENTS:
            return { ...state, ["medical_scanned_documents"]: payload };
        case patient_constants.PATIENT_SEARCH_DATA:
            return { ...state, ["patient_search"]: payload };

        case patient_constants.GET_PATIENT_ACCOUNT_NOTES_STATUS:
            return {
                ...state,
                ["patient_account_notes_status"]: payload
            };
        //Patient cases listing
        case patient_constants.GET_PATIENT_CASES:
            return { ...state, ["patient_cases"]: payload.cases, case_loading: payload.case_loading };

        case patient_constants.UPDATE_PATIENT_HEADER_INFO:
            return {
                ...state, ["patient_header_reload"]: payload.patient_header_reload,
                ['selected_patient_header_case_id']: payload.selected_patient_header_case_id,
                ["selcted_case_reload"]: payload.selcted_case_reload
            };
        case patient_constants.GET_PRINT_PATIENT_FORM_DATA:
            return { ...state, ...payload };

        case patient_constants.GET_PATIENT_ACCOUNT_TASK_NOTES:
            return { ...state, ...payload };
        default:
            return state
    }

}


// This is a comparison function that will result in dates being sorted in
// DESCENDING order.
const date_sort_desc = (obj1, obj2) => {

    if (new Date(obj1.created_date) > new Date(obj2.created_date)) return -1;
    if (new Date(obj1.created_date) < new Date(obj2.created_date)) return 1;
    return 0;

};

// Format data to be given to grid
const format_account_notes = (task_data, account_notes_data) => {
    var combined_account_notes = [];

    // Format Code 

    // TASK
    var task = task_data;//task_data.data.data;
    //ACCOUNT NOTES
    var account_note = account_notes_data;//account_notes_data.data.data;

    combined_account_notes = task.concat(account_note);
    combined_account_notes = combined_account_notes.sort(date_sort_desc);

    var combined_account_notes_length = combined_account_notes.length;

    if (combined_account_notes && combined_account_notes_length > 0) {

        for (let j = 0; j < combined_account_notes_length; j++) {
            let current_obj = combined_account_notes[j];
            if (current_obj.due_date) {
                current_obj.created_date = current_obj.created_date == null ? null : custom_date_format(current_obj.created_date, date_format["mm/dd/yyyy"]);
                current_obj.id = current_obj.task_id;
                current_obj.due_date = current_obj.due_date == null ? null : custom_date_format(current_obj.due_date, date_format["mm/dd/yyyy"]);
                current_obj.note_text = current_obj.task_description;
                current_obj.note_code = current_obj.task_type.task_type;
                current_obj.stick_on = "";
                current_obj.assignee = current_obj.taskGroup != null && current_obj.task_group.task_group_id > 0 ? current_obj.task_group.group_name : current_obj.assignee;
                current_obj.created_by = current_obj.created_by_name;
                current_obj.isTask = true;

            }
            else {
                current_obj.created_date = current_obj.created_date == null ? null : custom_date_format(current_obj.created_date, date_format["mm/dd/yyyy"]);
                current_obj.created_by = current_obj.posted_by;
                current_obj.due_date = '';
                current_obj.assignee = "";
                current_obj.task_status = "";
                current_obj.isTask = false;

            }
        }
    }

    return combined_account_notes

}