import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { setTimeout } from 'timers';
import { custom_prompt, get_master_data, get_master_data_by_type, refresh_token, set_active_patient, unsaved_form_data_for_prompt, update_payment_method  } from '../../login/action/login_action';
import { MasterData } from '../../login/login_constants';
import PatientHeaderComponent from '../../patient/component/patient_header_component';
import PatientSearchStripeComponent from '../../patient/component/patient_search_stripe_component';
import LaunchDarkley from '../../shared/launch_darkley/launch_darkley';
import * as local_storage from '../../shared/local_storage_utility';
import * as session_storage from '../../shared/session_storage_utility';
import {
    can_we_open_new_tab, clear_patient_header_info, detect_ie,
    get_lauch_darkley_key_value, is_firefox, open_new_tab, show_empty_patient_header_on_current_page, close_chat,show_header, clear_sotrage_after_company_change_on_main_page
} from '../../shared/utility';
import { sso_identity_application, token_verify_post } from '../../sso/action/sso_action';
import { UserTasks } from '../../user/component/user_tasks';
import CompanyInformationIconComponent from '../component/company_information_icon_component';
import { ResizableDraggableModal } from '../resizable-draggable-modal';
import * as shared_constants from '../shared_constants';
import * as global_constant from './../../global_constants';
import { left_menu_permission, prompt_for_logout, set_notes_modal_status, set_prevent_company_model_status } from './../../shared/action/shared_action';
import ChangeCompanyComponent from './../../user/component/change_company_component';
import * as Action from './../action/autosearch_action';
import { show_404_page } from './../../login/action/login_action';
import { handle_shift_tab_of_app_header } from './../tab_navigation_utility';
import AccountNotesViewAddComponent from './account_notes_view_add_component';
import { get_sso_user_emr_permissionset } from '../../user/action/user_action';
import ConfirmationComponent from './confirmation_component';
import { OnlinePaymentSupportFlag } from '../../company/company_constants';
import { magnus_plat_5681_isolate_auth0 } from '../../feature_flip_constant';
import { toastr as toaster } from "react-redux-toastr";

export class AppHeaderComponent extends React.Component<any, any> {
    [x: string]: any;
    _ff_dashboard_new = true;
    userData = {
        first_name: '',
        last_name: '',
        email: ''
    }
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            showQuickSearch: false,
            patientid: '',
            showModal: false,
            showModalChild: false,
            isShowParent: false,
            showCompanyModal: false,
            quick_search_error_message: '',
            is_need_patient_header_update: false,
            open_new_tab: true,
            is_sso_user: false,
            show_analytics_icon: false
        };

        // initially set prompt_for_logout to not_required
        this.props.prompt_for_logout(global_constant.prompt_for_logout.NOT_REQUIRED);
        this.props.set_notes_modal_status(false)
        this.props.set_unsaved_form_data_for_prompt(false);
        this.props.set_custom_prompt({});
    };

    UNSAFE_componentWillMount() {
        var logingData = local_storage.get('auth_data');
        if (logingData) {
            this.userData.first_name = logingData.first_name;
            this.userData.last_name = logingData.last_name;
            this.userData.email = logingData.user_email;

            // there is an issue where this property is not filled out when first logging in, could be due to a delay on the server side...refreshing token to get the value
            if (this.props.goliath_bill1938_online_payment_support && logingData.online_Patient_payment_Url == null) {
                this.props.refresh_token(this.props.user_login_details.user_data.data);
            }
        }
    }

    async componentDidMount() {
        this.props.update_payment_method();
        let responseTokenVerify;
        if (sessionStorage['sso_user_login']) {
            if (this.props.magnusPlat5681IsolateAuth0) {
                this.setState({
                    is_sso_user: true
                });
            }
            else {
                responseTokenVerify = await token_verify_post();
                if (responseTokenVerify && responseTokenVerify.data.content) {
                    this.setState({
                        is_sso_user: true
                    });
                }
            }
        }  
        await this.show_analytics_link();
    }

    componentDidUpdate(previousProps, previousState) {
       
        // Clear patient Id if on Advance Search
        if ((clear_patient_header_info(this.props.location.pathname)
            || (session_storage.get('change_company') && this.props.location.pathname.indexOf('dashboard') > 0)) && 
           session_storage.get('active_patient') != '') {
           session_storage.set('active_patient', '');
           this.props.set_active_patient('')
        }
       
        if(this.props.display_404_page) {
            this.props.history.push('/404_page');
            this.props.show_404_page(false);
        }

        // we have already handled the prompt, thus make user force_logout
        if (this.props.check_prompt_for_logout == global_constant.prompt_for_logout.HANDLED) {
            //this.force_logout();
            close_chat();

            setTimeout(() => {
                this.props.history.push({
                    pathname: `/logout`,
                    state: {
                        force_logout: true
                    }
                });
            }, 0);
        }
        
        // if custom_prompt state is continue then invoke 
        if(this.props.check_custom_prompt_change != null && this.props.check_custom_prompt_change.stage &&
            this.props.check_custom_prompt_change.value == global_constant.custom_prompt_values.COMPANY_CHANGE &&
            this.props.check_custom_prompt_change.stage == global_constant.custom_prompt_stages.CONTINUE) {

            this.props.set_custom_prompt({}); 
            this.openChangeCompanyModal();
        }        
    }

    // toggle to show and hide patient drop down menu.
    toggle = () => {
        // check if box is currently opened
        const isShow = this.state.showQuickSearch;
        this.setState({
            // toggle value of `opened`
            showQuickSearch: !isShow,
        });
    };

    handleSelect(eventKey) {
        eventKey.preventDefault();
        alert(`selected ${eventKey}`);
    }

    //  Open Knowledge Base link on click
    on_help_link_click = async () => {
        window.open(global_constant.url.KNOWLEDGE_BASE_LINK, "_blank") //to open url on new tab
    }

    //  Open Ideas Portal link on click
    on_ideas_portal_link_click = async () => {
        window.open(global_constant.url.IDEAS_PORTAL_LINK, "_blank") //to open url on new tab
    }

    //  API call on logout
    on_log_out = async () => {  
        close_chat();
        setTimeout(() => {
            if (this.props.magnusPlat5681IsolateAuth0) {
                if (sessionStorage['sso_user_login']) {
                    this.props.history.push('/v2/logout');
                } else {
                    this.props.history.push('/logout');
                }
            } else {
                this.props.history.push('/logout');
            }
        }, 0);
    }

    //trigger to show the company name with gpms code with dropdown menu
    trigger = (
        <>
            <span className="CompName">{this.props.user_login_details.user_data.data.first_name} {this.props.user_login_details.user_data.data.last_name}</span>
            <span className="DepName">{this.props.user_login_details.user_data.data.user_type_r3_id != global_constant.user_type.Customer ? this.props.user_login_details.user_data.data.gpms_code + ' - ' : ''}{this.props.user_login_details.user_data.data.company_name}</span>
        </>
    )
    onselection = (item) => {       
        var navigate_to = `/patient/${item.title}`;        
        this.props.history.push(`${navigate_to}`);
        session_storage.set('active_patient', item.title);
        this.setState({ patientid: item.title });
        this.props.set_active_patient(item.title);
        this.closeModal();
    }
    //close the modal on selection of patient through quick search
    closeModal = () => {
        //if open modal using application header
        if (this.state.isShowParent) {
            this.setState({ showModal: false, isShowParent: false })
        } else {
            this.setState({ showModalChild: true })
        }
    }

    toggleChangeCompany = () => {
        // apply this modal only for Internet explorer D-10988
        if(detect_ie()){
            let count = parseInt(local_storage.get("open_tab_count"));
            if(count > 1) {
                this.props.set_prevent_company_model_status(true);
                return false;
            }
        }
        // applying custom prompt functionality for unsaved data.
        if(this.props.check_unsaved_form_data_for_prompt) {
            // Invoking the custom prompt for company change functionality
            this.props.set_custom_prompt({
                value: global_constant.custom_prompt_values.COMPANY_CHANGE,
                stage: global_constant.custom_prompt_stages.INVOKE
            });           
        } else {
            this.openChangeCompanyModal();
        }
    }

    openChangeCompanyModal = () => {
        // check if box is currently opened        
        this.setState({
            // toggle value of `opened`
            showCompanyModal: true,
        });
        this.closeModal();
    }
    // navigation on selecting a menu
    on_header_menu_click = (navigate_to) => {
        //For not showing patient header 
        // TODO: need to verify for all nevigation, is patient id null?
        this.setState({ patientid: null });
        this.props.history.push(`${navigate_to}`);
        //For not showing the company modal
        setTimeout(this.closeCompanyModal, 20);
    }

    closeCompanyModal = () => {
        this.setState({ showCompanyModal: false });
    }

    changeCompany = (selected_row:any) => {
        //On change company remove and reset storage.
        this.remove_session_storage();
        this.remove_local_storage();
        this.props.patient_details.patient_search = null;
        this.setState({ patientid: null });        
         // get master data when user change company
        this.props.get_master_data(MasterData.all, this.props.user_login_details.user_data.data.user_id, this.props.user_login_details.user_data.data.token_details.access_token, true);
        this.props.get_master_data_by_type(MasterData.all, this.props.user_login_details.user_data.data.user_id, this.props.user_login_details.user_data.data.token_details.access_token, true);
       
        LaunchDarkley.update(
            () => {
          session_storage.set('change_company', true);
          var navigate_to = '/patient_search';
          if (this.props.rsiB29536Dashboard) {
            navigate_to = '/dashboard';
          }
          this.props.history.push(`${navigate_to}`);
                this.closeCompanyModal();
                clear_sotrage_after_company_change_on_main_page();
            }
        );
        //this.props.left_menu_permission(this.props.user_login_details.user_data.data.user_id, selected_row.id, this.props.user_login_details.user_data.data.token_details.access_token)
       
    }

    remove_session_storage = () => {
        session_storage.set('active_patient', '');
        session_storage.set('patient_criteria', '');
        this.props.set_active_patient('');

        local_storage.remove('report_navigation_active_patient');
        local_storage.remove('report_navigation');
    };

    remove_local_storage = () => {

    };

    getDataList = async (inputValue, companyid) => {
        return await Action.get_searchList(this.props.user_login_details.user_data.data.token_details.access_token, this.props.user_login_details.user_data.data.company_id, inputValue.trim(), global_constant.constants.Quick_Search_Suggestion_List_Size, global_constant.end_points.patient_urls.quick_search);

    }
    setOnPageFocus = (event) => {
        if (event.shiftKey && event.keyCode == '9') {
            event.preventDefault();
            let listButtons = document.body.getElementsByTagName('button');
            listButtons[listButtons.length - 1].focus();
        }
    }

    toggle_app_header_dropdown = () => {
        document.getElementById("new_app_header_dropdown").classList.toggle("show");
    }

    close_app_header_on_close = (event) => {

        if ( event.target.id != "app_header_dropdown") {
            if (document.getElementById("app_header_dropdown")) {
                if (document.getElementById("app_header_dropdown").children[1].classList.contains('visible')) {

                    document.getElementById("app_header_dropdown").children[1].classList.toggle("visible");
                }
            }
        }
    }

    handle_click_on_app_header = (event) => {
        let open_new_tab = can_we_open_new_tab();
        this.setState({
            open_new_tab: open_new_tab
        });
        let should_toggle = true;

         // not reopening the app header iframe if New Tab is clicked in the case of firefox
        // because when going to new window, this iframe close automatically in firefox
        if (is_firefox() && event.target.innerText == "New Tab") {
            should_toggle = false
        }
       
        if (should_toggle) {
            document.getElementById("app_header_dropdown").children[1].classList.toggle("visible");
        }
    }

    /**
     * Function capture keyboard events in app header dropdown
     * */
    handle_tab_navigation_for_app_header = (event) => {

        let code = event.keyCode || event.which;

        // when keycode detected is 13 (enter) || 40 (down key)
        // perform close or open operation for that control
        if (code == 13 || code == 40) {

            // means dropdown is closed
            if (event.target.children[1]) {

                // it is closed and need to be opened
                if (!event.target.children[1].classList.contains('visible')) {
                    event.target.children[1].classList.toggle('visible');
                    event.target.children[1].children[0].focus();
                } else {

                    // focusing on next element on keydown
                    if (code == 40) {

                        // i am already open and want my sibling to take focus from here
                        if (event.target.getAttribute('role') == 'option') {
                            event.target.nextElementSibling.focus();
                        }

                        // i was already open by mouse and i am not entering into my dropdown list
                        if (event.target.getAttribute('role') == 'listbox') {
                            event.target.children[1].children[0].focus();
                        }
                    }

                    if (code == 13) {
                        event.preventDefault();
                        // trigger click event for submenus
                        event.target.click();
                    }

                }
            } else {

                if (code == 13) {
                    event.preventDefault();
                    // trigger click event for submenus
                    event.target.click();
                } else {
                    // means i am open and visiting to child menu on key down
                    if (event.target.nextElementSibling) {
                        if (!event.target.nextElementSibling.classList.contains('divider')) {

                            // setting the focus to next subling
                            event.target.nextElementSibling.focus();
                        } else {

                            // skipping the divider and jumping to next -> next subling
                            event.target.nextElementSibling.nextElementSibling.focus();
                        }
                    } else {

                        //I am not changing the focus if it is last element of the dropdown list
                    }
                }
            }
        }

        // when up arrow is clicked
        if (code == 38) {
            event.preventDefault();

            // this means grid is open, 
            // up arrow will only operate on open key 
            if (!event.target.children[1]) {
                if (event.target.previousElementSibling) {
                    if (!event.target.previousElementSibling.classList.contains('divider')) {
                        let target = event.target;
                        // setting the focus to previous subling
                        target.previousElementSibling.focus();
                    } else {

                        // skipping the divider and jumping to previous -> previous subling
                        event.target.previousElementSibling.previousElementSibling.focus();
                    }
                } else {
                    // we will close only if it is visible
                    if (event.target.parentElement.classList.contains('visible')) {
                        // i am closing dropdown now
                        event.target.parentElement.classList.toggle('visible');
                        event.target.parentElement.parentElement.focus();
                    }
                }
            } else {
            }
        }

        // when esc is clicked
        if (code == 27) {
            if (event.target.getAttribute('role') == 'listbox') {
                event.target.children[1].classList.toggle('visible');
                event.target.focus();
            }

            if (event.target.getAttribute('role') == 'option') {
                event.target.parentElement.classList.toggle('visible');
                event.target.parentElement.parentElement.focus();
            }
        }

        if (code == 9 && event.target.textContent == "Logout" && ! event.shiftKey) {
            // we will close only if it is visible
            if (event.target.parentElement.classList.contains('visible')) {
                // i am closing dropdown now
                event.target.parentElement.classList.toggle('visible');
            }
        }

        if (code == 9 && event.shiftKey && event.target.getAttribute('role') == 'listbox') {
            event.preventDefault();
            handle_shift_tab_of_app_header();
        }

    }

    handle_app_header_submenu_on_blur = (event) => {

        let code = event.keyCode || event.which;
        if (code) return;

        // after internal on blur this will be triggered
        // this should only work when attribute role is listbox
        if (event.currentTarget.getAttribute('role') == 'listbox') {
            let relatedTarget = event.relatedTarget;

            if (detect_ie()) {

                if (!relatedTarget) {
                    relatedTarget = document.activeElement;
                }
            }

            let is_drawer_open = document.querySelector('.bp3-drawer-body');

            // event.relatedTarget will be null if clicked outside anywhere in the window
            // role attribute will be listbox when a listbox is open and again i am opening another listbox
            if (!relatedTarget || (relatedTarget.getAttribute('role') != 'option')) {

                // if Drawer is open
                if(is_drawer_open && relatedTarget.getAttribute('role') != 'option') return;

                if (event.currentTarget.children[1].classList.contains('visible')) {
                    event.currentTarget.children[1].classList.toggle('visible');
                }
            }
        }
    }

    handle_app_header_submenu_item_on_blur = (event) => {

        let code = event.keyCode || event.which;
        if (code) return;

        if (event.target.getAttribute('role') == 'option') {
            let relatedTarget = event.relatedTarget;

            if (detect_ie()) {

                if (!relatedTarget) {
                    relatedTarget = document.activeElement;
                }
            }
            let is_drawer_open = document.querySelector('.bp3-drawer-body');
            if (!relatedTarget || (relatedTarget.getAttribute('role') != 'option')) {

                // if Drawer is open
                if(is_drawer_open && relatedTarget.getAttribute('role') != 'option') return;


                if (event.target.parentElement.classList.contains('visible')) {
                    event.target.parentElement.classList.toggle('visible');
                }
            }
        }
    }
    
    render_account_notes = () => {
        let { name } =
          this.props.patient_details &&
          this.props.patient_details.patient_header &&
          this.props.patient_details.patient_header.data
            ? this.props.patient_details.patient_header.data
            : "";
        let patient_name =
          name &&
          `${name.first_name ? name.first_name : ""} ${name.middle_initial ? name.middle_initial : ""} ${
            name.last_name ? name.last_name : ""
          }`;
        return (
          <ResizableDraggableModal
            showModal={this.props.show_notes_modal}
            dimmer={false}
            boundWith={"window"}
            dragHandleClassName={"daragble-header"}
            autoResizeOnload={false}
            popUpPosition={{
              x: 300,
              y: 65,
              width: 699,
              height: 542,
              minHeight: 400,
              minWidth: 515,
              maxWidth: 1600,
              maxHeight: 900
            }}
            enable={{
              bottomRight: true
            }}
            className={'modal_container'}
          >
            <ResizableDraggableModal.Header
              className={"daragble-header"}
              id='daragble-header'
              onRequestClose={() => this.props.set_notes_modal_status(false)}
            >{`Account Notes for ${patient_name}`}</ResizableDraggableModal.Header>
            <ResizableDraggableModal.Content className={"daragble-content scrolling"}>
              <ResizableDraggableModal.Description className={"daragble-description"}>
                <AccountNotesViewAddComponent
                  is_patient_header={true}
                  is_view={false}
                  close_icon={() => this.props.set_notes_modal_status(false)}
                  patient_id={this.props.active_patient_id}
                  patient_name={patient_name}  
                  gpms_code={this.props.user_login_details.user_data.data.gpms_code}
                  company_name={this.props.user_login_details.user_data.data.company_name}
                  data_model_key={"dialog_account_notes"}
                  show_account_notes={this.props.show_notes_modal}
                  showModal={this.props.show_notes_modal}
                />
              </ResizableDraggableModal.Description>
            </ResizableDraggableModal.Content>
          </ResizableDraggableModal>
        );
    }

    on_webPt_analytics_link_click = () => {
        if (sessionStorage['sso_user_login']) {
            var open_url = '';
            var redir_url = '';

            if (this.props.magnusPlat5681IsolateAuth0) {
                sso_identity_application().then(
                    response => {
                        if (response.data) {
                            const userApplications = response.data;
                            const analyticsApplicationId = 9;
                            const analyticsApplication = userApplications.find((applicationItem) => {
                                return applicationItem.applicationId === analyticsApplicationId;
                            });

                            if (analyticsApplication) {
                                const facility_array = local_storage.get("facility_array");
                                if (facility_array && facility_array.length >= 1) {
                                    redir_url = global_constant.analytics_icon_config.webpt_analytics_redir_url + facility_array[0].id;
                                }
                                open_url = global_constant.analytics_icon_config.webpt_analytics_client_url + btoa(redir_url);
                            }
                            window.open(open_url, global_constant.sso_config_data.analytics_win_name);
                        } else {
                            open_new_tab(this.props.rsiB29536Dashboard);
                        }
                    },
                    error => {
                        if (error.response && error.response.status === 403) {
                            const toastrMessageOptions = {
                                timeOut: 5000,
                                onHideComplete: () => window.location.href = global_constant.sso_url.webpt_emr_authorize,
                            };
                            toaster.error("", "Hold tight! Your session has expired and you are being re-authenticated.", toastrMessageOptions);
                        }
                    }
                );
            } else {
                token_verify_post().then((res) => {
                    if (res.data.content) {
                        var data = res.data.content.response;
                        var app_url = data.applications.filter((item) => {
                            return item.applicationId == 9;
                        })
                        if (app_url) {
                            var facility_array = local_storage.get("facility_array");
                            if (facility_array && facility_array.length >= 1) {
                                redir_url = global_constant.analytics_icon_config.webpt_analytics_redir_url + facility_array[0].id;
                            }
                            open_url = global_constant.analytics_icon_config.webpt_analytics_client_url + btoa(redir_url);
                        }
                        window.open(open_url, global_constant.sso_config_data.analytics_win_name);
                    } else {
                        open_new_tab(this.props.rsiB29536Dashboard);
                    }
                });
            }
        }
    }

    show_analytics_link = async () => {
        
        var old_user_data = local_storage.get("auth_data");

        if (old_user_data && sessionStorage['sso_user_login'] && old_user_data.company_type_id == 5) {

            await get_sso_user_emr_permissionset(this.props.user_login_details.user_data.data.user_id).then((response) => {

                if (response && response.data) {

                    var emr_permission_set_array = response.data.permissions;

                    

                    if (emr_permission_set_array && emr_permission_set_array.indexOf(shared_constants.analytics_permission_description) != -1) {
                        console.log('EMR Permission set returned true');

                        if (response.data._embedded && response.data._embedded.facilities) {
                            local_storage.set("facility_array", response.data._embedded.facilities);
                        }
                        this.setState({ show_analytics_icon: true });
                    }
                    else {
                        console.log('EMR Permission set returned false');

                        this.setState({ show_analytics_icon: false });
                    }
                    console.log("Show Analytics Icon Done  = " + this.state.show_analytics_icon);
                } else {
                    console.log('EMR Permission set result else section', response);

                }
            }, (error) => {
                console.log('EMR Permission set request error', error);
            });
        }
    }

    on_webPt_emr_link_click = () => {
        const RESPONSE_STATUS_FORBIDDEN = 403;
        if (this.props.magnusPlat5681IsolateAuth0) {           
            sso_identity_application().then(
                response => {
                    if (response.data) {
                        const userApplications = response.data;
                        const emrApplicationId = 1;
                        const emrApplication = userApplications.find((applicationItem) => {
                            return applicationItem.applicationId === emrApplicationId;
                        });

                        if (emrApplication) {
                            window.open(global_constant.sso_url.webpt_emr_url, '_blank');
                        }
                    } else {
                        open_new_tab(this.props.rsiB29536Dashboard);
                    }
                },
                error => {
                    if (error.response && error.response.status === RESPONSE_STATUS_FORBIDDEN) {                        
                        const toastrMessageOptions = {
                            timeOut: 5000,
                            onHideComplete: () => window.location.href = global_constant.sso_url.webpt_emr_authorize,
                        };
                        toaster.error("", "Hold tight! Your session has expired and you are being re-authenticated.", toastrMessageOptions);
                    }
                }
            );
        }
        else {
            var open_url = '';
            token_verify_post().then((res) => {
                if (res.data.content) {
                    var data = res.data.content.response;
                    var app_url = data.applications.filter((item) => {
                        return item.applicationId = 1;
                    });
                    open_url = `${app_url[0].homeUri}`
                    const form: HTMLFormElement = document.createElement("form");
                    form.method = "post";
                    form.action = open_url;
                    form.target = "_blank";
                    const input: HTMLInputElement = document.createElement("input");
                    input.type = "hidden";
                    input.name = "token";
                    input.value = data.sessionId;
                    form.appendChild(input);
                    document.body.appendChild(form);
                    form.submit();
                } else {
                    open_new_tab(this.props.rsiB29536Dashboard);
                }
            });
        }       
    }

    show_emr_link = () => {
        let is_show_emr_link = false;
        var old_user_data = local_storage.get("auth_data");

        if (old_user_data) {
            if (this.state.is_sso_user && old_user_data.company_type_id == 5) {
                is_show_emr_link = true;
            }
        }
        return is_show_emr_link;
    }

    show_chat_icon = () => {
        var show_icon = true;
        var old_user_data = local_storage.get('auth_data');
        console.log('auth_data', old_user_data);
        if (old_user_data && old_user_data.allow_live_chat) {
            show_icon = old_user_data.allow_live_chat
        }
        return show_icon;
    }

    render() {
        const { showCompanyModal, is_need_patient_header_update } = this.state;
        var currentPatientId = session_storage.get('active_patient');
        var show_patient_header = false;
        var show_patient_search_only_header = false;
        show_patient_header = show_header(this.props.location.pathname) ? currentPatientId ? true : false : false;
        show_patient_search_only_header = show_header(this.props.location.pathname) ? currentPatientId ? false : true : false;

        if ((!currentPatientId || !show_patient_header) && !show_patient_search_only_header) {
            show_patient_search_only_header = show_empty_patient_header_on_current_page(this.props.location.pathname);
        }
        var change_company_menu = this.props.shared_details.left_menu_data.data.filter((menu_item, index) => {
            return menu_item.id == shared_constants.app_header_menu.change_company;
        })[0];

        var change_password_menu = this.props.shared_details.left_menu_data.data.filter((menu_item, index) => {
            return menu_item.id == shared_constants.app_header_menu.change_password;
        })[0];

        return (
            <React.Fragment>
                <header id="applicationHeader" className="application_header">
                    <div className="logo">
                        <a href="#" onClick={e => e.preventDefault()} tabIndex={-1}>
                            <img tabIndex={-1} src={'/src/assets/images/logoWebPTWhite.png'} />
                        </a>
                    </div>
                    <span className="AppName">Billing <span className="version">{global_constant.show_app_version ? global_constant.build_version:''}</span></span>
                    
                    <div className="tranparentSpace" onClick={this.closeModal}></div>
                    <div className="chat_user_info">

                        <div onKeyDown={this.handle_tab_navigation_for_app_header} className="userinfo header-name" style={{
                            display: 'flex',
                        alignItems: 'center'
                    }}>
                        {
                                this.show_emr_link() &&
                            <div>
                                {
                                        <div id="webPt_emr_link" onClick={this.on_webPt_emr_link_click} className="col" ><div className="row">
                                            <span className="pat-header-icon-icemoon icon-emr_product text-center"> </span>
                                    </div>
                                            <div className="row" id="main-icon-text">
                                                <span className="text-center" style={{ fontSize:"13" }}>EMR</span>
                                    </div>
                                    </div>
                                }
                                
                            </div>
                            }
                            {
                                this.state.show_analytics_icon &&
                                <div>
                                    {
                                        <div id="webPt_analytics_link" onClick={this.on_webPt_analytics_link_click} className="col" ><div className="row">
                                            <span className="pat-header-icon-icemoon icon-analytics_product text-center"> </span>
                                        </div>
                                            <div className="row" id="analytics-main-icon-text">
                                                <span className="text-center" style={{ fontSize: "13" }}>Analytics</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            
                           
                        <span className="task_icon_container">
                                <div>
                                    {this.state.is_sso_user ? <UserTasks  /> : null}
                                </div>
                            </span>
                            <div>
                                <span className="CompName">{this.props.user_login_details.user_data.data.first_name} {this.props.user_login_details.user_data.data.last_name}</span>
                                <span className="DepName">
                                    <div className="text" style={{ position: 'relative' }} role="alert" aria-live="polite">{(this.props.user_login_details.user_data.data.user_type_r3_id != global_constant.user_type.Customer) ? (this.props.user_login_details.user_data.data.gpms_code == null || this.props.user_login_details.user_data.data.gpms_code.trim() == "") ? this.props.user_login_details.user_data.data.company_name : this.props.user_login_details.user_data.data.gpms_code + ' - ' + this.props.user_login_details.user_data.data.company_name : this.props.user_login_details.user_data.data.company_name}
                                        <CompanyInformationIconComponent />
                                    </div>
                                </span>
                            </div>
                            <div id="app_header_dropdown" role="listbox" onClick={this.handle_click_on_app_header} onBlur={this.handle_app_header_submenu_on_blur} aria-expanded="false" className="ui dropdown" tabIndex={0}>
                                <i aria-hidden="true" className="dropdown icon" style={{ top: '-10px' }}></i>
                                <div onBlur={this.handle_app_header_submenu_item_on_blur} className="menu transition">
                                    <div
                                        tabIndex={0}
                                        role='option'
                                        className={this.state.open_new_tab ? 'item' : 'item disabled'}
                                        {...(this.state.open_new_tab && {
                                            onClick: () => open_new_tab(this.props.rsiB29536Dashboard)
                                        })}
                                        dangerouslySetInnerHTML={{
                                            __html: 'New Tab'
                                        }}
                                    />
                                    {
                                        (change_company_menu && change_company_menu.view) && (this.props.user_login_details.user_data.data.user_type_r3_id != global_constant.user_type.Customer) ? <div tabIndex={0} role="option" className="item" onClick={this.toggleChangeCompany}>{change_company_menu.name}</div> : ''
                                    }
                                    {
                                        (change_password_menu && change_password_menu.view) ? <div tabIndex={0} role="option" className="item" onClick={() => this.on_header_menu_click('/manage_account')}>{change_password_menu.name}</div> : ''
                                }
                                <div tabIndex={0} role="option" className="item" onClick={() => this.on_help_link_click()}>Help</div>
                                <div tabIndex={0} role="option" className="item" onClick={() => this.on_ideas_portal_link_click()}>Enter Idea</div>
                                    <div tabIndex={0} role="option" className="item" onClick={() => this.on_log_out()}>Logout</div>
                                </div>
                        </div>
                        </div>
                        {this.show_chat_icon() &&
                            <div id="bms_chat">
                            <iframe id="chatFrame" src={`/chat/embedded_chat.html?first_name=${this.userData.first_name}&last_name=${this.userData.last_name}&email=${this.userData.email}`}></iframe>
                            </div>
                        }
                    </div>
                    <ChangeCompanyComponent show_popup={showCompanyModal} onChangeCompany={this.changeCompany} onCloseCompanyModal={this.closeCompanyModal} />
                    <ConfirmationComponent
                        message={'Please close all other tabs in order to change Company.'}
                        title='Confirm'
                        show_modal={this.props.show_prevent_change_model}
                        save_button_text='OK'
                        showCancelButton={false}
                        on_force_save_click={()=>this.props.set_prevent_company_model_status(false)}
                        onCloseModal={()=>this.props.set_prevent_company_model_status(false)}
                    />
                </header>
                {show_patient_search_only_header ? <PatientSearchStripeComponent /> : ""}
                {show_patient_header ?
                    <PatientHeaderComponent show={this.state.showModalChild} is_need_to_update={is_need_patient_header_update}
                        onModalClose={() => this.setState({ showModalChild: false })}
                        patientId={currentPatientId} token={this.props.user_login_details.user_data.data.token_details.access_token} /> : ''}

                {this.props.show_notes_modal && this.render_account_notes()}
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        prompt_for_logout: prompt_for_logout,
        get_master_data: get_master_data,
        get_master_data_by_type: get_master_data_by_type,
        left_menu_permission: left_menu_permission,
        set_notes_modal_status: set_notes_modal_status,
        update_payment_method: update_payment_method,
        set_active_patient: set_active_patient,
        set_unsaved_form_data_for_prompt: unsaved_form_data_for_prompt,
        set_custom_prompt: custom_prompt,
        set_prevent_company_model_status,
        show_404_page: show_404_page,
        refresh_token: refresh_token
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        shared_details: state.shared_details,
        patient_details: state.patient_details,
        show_notes_modal : state.shared_details.show_notes_modal,
        show_prevent_change_model : state.shared_details.show_prevent_change_model,
        active_patient_id: state.user_login_details.active_patient_id,
        check_unsaved_form_data_for_prompt: state.user_login_details.unsaved_form_data_for_prompt,
        check_custom_prompt_change: state.user_login_details.custom_prompt,     
        rsiB29536Dashboard: get_lauch_darkley_key_value(state.launch_darkly, "rsiB29536Dashboard"),
        display_404_page: state.user_login_details.display_404_page,
        goliath_bill1938_online_payment_support: get_lauch_darkley_key_value(state.launch_darkly, OnlinePaymentSupportFlag.goliath_bill1938_online_payment_support),
        magnusPlat5681IsolateAuth0: get_lauch_darkley_key_value(state.launch_darkly, magnus_plat_5681_isolate_auth0)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppHeaderComponent))