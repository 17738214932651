import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Dimmer, Grid, Loader, Form, Input } from 'semantic-ui-react';
import * as constants from '../patient_letter_constants';
import { R6ResponseData, R6ResponseStatus, IDropDownDataDto, IMultiSelectDropDownDataDto, date_format } from '../../../../app/global_constants';
import * as action from '../action/patient_letter_action';
import Selection from '../../../shared/component/selection_component';
import { toastr as toaster } from 'react-redux-toastr';
import { get_all_error, custom_date_format, get_columns, show_html_content_toaster } from '../../../shared/utility';
import * as session_storage from '../../../shared/session_storage_utility';
import GridView from '../../../shared/component/grid';
import { Position, Drawer } from '@blueprintjs/core';
import DrawerComponent from '../../../shared/component/drawer_component';
import FormLetterDetailComponent from './form_letter_detail_component';
import { AlertConfirm } from '../../../shared/component/alert_confirm_component';


class FormLettersSearchComponent extends React.Component<any, any> {

    _is_mounted = false;
    page_metadata: any;
    token: string = '';
    _grid_ref = { api: null };
    grid_header_height: number = 0;
    state: constants.IStateTemplate;
    company_dd_list: Array<IDropDownDataDto>;
    new_search_request_object: constants.IPatientLetterSearchRequestDto;
    display_form_letters_list: Array<constants.IDisplayFormLettersDto> = [];
    patient_letter_variable_list: Array<constants.IPatientLetterVariableDto> = [];
    master_company_list_multiselect_display: Array<IMultiSelectDropDownDataDto> = [];
    drawer_letter: constants.IDrawerState = {
        patient_letter_edit: null,
        display_patient_letter_id: 0,
        is_updated: false
    }

    // #region Constructor
    constructor(props) {

        super(props);

        this.state = {
            search_request_obj: { letter_name: '', company_id: -1, show_inactive: false },
            is_loading: {
                company: false,
                search_data: false,
                patient_letter_variables: false,
                patient_letter_edit: false
            },
            show_grid: false,
            show_drawer: false,
            show_save_alert: false
        }

        this.new_search_request_object = { letter_name: '', company_id: -1, show_inactive: false };
    }
    // #endregion

    // #region life cycle methods
    // Fetching the page_name and state.
    UNSAFE_componentWillMount = () => {
        this.get_page_metadata();
    };

    componentDidMount = () => {
        this._is_mounted = true;
        this.get_user_companies_list();
        this.get_patient_letter_variables_list();
        document.body.classList.add('admin-framework');

        // #region Load serach request from session
        let search_criteria_from_session: constants.IPatientLetterSearchRequestDto;
        search_criteria_from_session = this.get_search_criteria_from_session();
        if (search_criteria_from_session != null) {
            this.setState({
                search_request_obj: {
                    letter_name: search_criteria_from_session.letter_name,
                    company_id: search_criteria_from_session.company_id,
                    show_inactive: search_criteria_from_session.show_inactive
                }
            });
            this.search_handler(true);
        }
        // #endregion Load serach request from session
    };

    componentWillUnmount = () => {
        this._is_mounted = false;
        document.body.classList.remove('admin-framework');
        delete this.master_company_list_multiselect_display;
        delete this.patient_letter_variable_list;
        delete this.new_search_request_object;
    };
    // #endregion 

    // #region Helper Methods
    is_loading(): boolean {

        return (this.state.is_loading.company ||
            this.state.is_loading.search_data ||
            this.state.is_loading.patient_letter_variables ||
            this.state.is_loading.patient_letter_edit);
    }

    get_search_criteria_from_session = (): constants.IPatientLetterSearchRequestDto => {

        let search_criteria_from_session: constants.IPatientLetterSearchRequestDto = null;
        let session_data = session_storage.get(constants.dynamic_form_letters.session_key);
        if (session_data != undefined && session_data.search_criteria != undefined) {
            search_criteria_from_session = session_data.search_criteria;
        }
        return search_criteria_from_session;
    }

    //Use to fetch params and page metadata
    get_page_metadata = () => {
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        //Verifying page_name is correct or not.
        this.page_metadata = constants.dynamic_form_letters;
    };

    // #endregion

    // #region Data fetch Methods
    get_patient_letter_variables_list = async () => {
        if (!this._is_mounted) return;

        let state_read: constants.IStateTemplate = this.state;
        state_read.is_loading.patient_letter_variables = true;
        this.setState({ is_loading: state_read.is_loading });

        await action.get_patient_letter_variables(this.token).then(
            (response: R6ResponseData) => {
                if (response && response.data && response.data.data) {
                    this.patient_letter_variable_list = response.data.data;
                    state_read.is_loading.patient_letter_variables = false;
                    this.setState({ is_loading: state_read.is_loading });
                }
                else {
                    state_read.is_loading.patient_letter_variables = false;
                    this.setState({ is_loading: state_read.is_loading });
                    const toastr_options = show_html_content_toaster(get_all_error(response.data.messages));
                    toaster.error("", toastr_options);
                }
            },
            (error) => {
                state_read.is_loading.patient_letter_variables = false;
                this.setState({ is_loading: state_read.is_loading });
                const toastr_options = show_html_content_toaster(error.message);
                toaster.error("", toastr_options);
            }
        );
    }

    // Get the letter details by Id.
    get_patient_lettter = async () => {
        if (!this._is_mounted) return;

        let state_read: constants.IStateTemplate = this.state;
        state_read.is_loading.patient_letter_edit = true;
        this.setState({ is_loading: state_read.is_loading });

        await action.get_patient_letter_edit(this.drawer_letter.display_patient_letter_id, this.token).then(
            (response: R6ResponseData) => {
                if (response && response.data && response.data.data) {
                    var letter = response.data.data;
                    let patient_letter_object: constants.IPatientLetterSaveRequestDto;
                    patient_letter_object = {
                        patient_letter_id: letter.patient_letter_id,
                        patient_letter_name: letter.patient_letter_name,
                        patient_letter_content: letter.patient_letter_content,
                        is_active: letter.is_active,
                        is_global: letter.is_global,
                        created_date: letter.created_date,
                        created_by: letter.created_by,
                        updated_by: this.props.user_login_details.user_data.data.user_id,
                        updated_date: null,
                        company_list: letter.company_list,
                        cloned_from: letter.cloned_from
                    };
                    this.drawer_letter.patient_letter_edit = patient_letter_object;
                    state_read.is_loading.patient_letter_edit = false;
                    this.setState({ is_loading: state_read.is_loading });
                }
                else {
                    state_read.is_loading.patient_letter_edit = false;
                    this.setState({ is_loading: state_read.is_loading });
                    const toastr_options = show_html_content_toaster(get_all_error(response.data.messages));
                    toaster.error("", toastr_options);
                }
            },
            (error) => {
                state_read.is_loading.patient_letter_edit = false;
                this.setState({ is_loading: state_read.is_loading });
                const toastr_options = show_html_content_toaster(error.message);
                toaster.error("", toastr_options);
            }
        );
    }

    get_user_companies_list = async () => {

        if (!this._is_mounted) return;

        let state_read: constants.IStateTemplate = this.state;
        state_read.is_loading.company = true;
        this.setState({ is_loading: state_read.is_loading });

        await action.get_user_companies(this.props.user_login_details.user_data.data.user_id, this.token).then(
            (response: R6ResponseData) => {
                if (response && response.data && response.data.data) {
                    this.master_company_list_multiselect_display = this.format_company_multiselect_display(response.data.data);
                    this.format_company_list(response.data.data);
                    state_read.is_loading.company = false;
                    this.setState({ is_loading: state_read.is_loading });
                }
                else {
                    state_read.is_loading.company = false;
                    this.setState({ is_loading: state_read.is_loading });
                    const toastr_options = show_html_content_toaster(get_all_error(response.data.messages));
                    toaster.error("", toastr_options);
                }
            },
            (error) => {
                state_read.is_loading.company = false;
                this.setState({ is_loading: state_read.is_loading });
                const toastr_options = show_html_content_toaster(error.message);
                toaster.error("", toastr_options);
            }
        );
    }

    //Search button handler
    search_handler = async (is_load_from_session: boolean = false) => {
        if (!this._is_mounted) return;

        // #region local variables
        let search_response_data: Array<constants.IPatientLetterSearchDataDto> = [];
        let state_read: constants.IStateTemplate = this.state;
        // #endregion

        state_read.is_loading.search_data = true;
        this.setState({ is_loading: state_read.is_loading });

        // #region Request Parameters session or state
        let search_request: constants.IPatientLetterSearchRequestDto;
        if (is_load_from_session)
            search_request = this.get_search_criteria_from_session();
        else
            search_request = this.state.search_request_obj;

        session_storage.set(constants.dynamic_form_letters.session_key, {
            search_criteria: search_request
        });
        // #endregion

        // #region Fetch data from server
        await action.search_patient_letter(search_request, this.token).then(
            (response: R6ResponseData) => {
                if (response && response.data && response.data.data) {
                    search_response_data = response.data.data;
                    this.display_form_letters_list = this.get_grid_display_data(search_response_data);
                    state_read.is_loading.search_data = false;
                    state_read.show_grid = true;
                    this.setState({ is_loading: state_read.is_loading, show_grid: state_read.show_grid })
                }
            },
            (error) => {
                state_read.is_loading.search_data = false;
                state_read.show_grid = false;
                this.setState({ is_loading: state_read.is_loading, show_grid: state_read.show_grid });
                const toastr_options = show_html_content_toaster(error.message);
                toaster.error("", toastr_options);
            }
        );
        // #endregion 

    }
    // #endregion 

    // #region Data Transformation 
    format_company_list = (data: Array<constants.ICompanyListDto>) => {
        if (!this._is_mounted) return;

        let transformed_dd_data: Array<IDropDownDataDto>;

        transformed_dd_data = data.map((val) => {
            return { key: val.company_id, value: val.company_id, text: val.gpms_code + " - " + val.company_name }
        });
        this.company_dd_list = transformed_dd_data;
        this.company_dd_list.unshift({ key: '', value: '-1', text: '--ALL COMPANIES' });
    }

    format_company_multiselect_display(company_list: Array<constants.ICompanyListDto>): Array<IMultiSelectDropDownDataDto> {
        if (!this._is_mounted) return;

        let transformed_data: Array<IMultiSelectDropDownDataDto>;

        transformed_data = company_list.map((company) => {
            return {
                value: company.company_id,
                name: company.gpms_code + " - " + company.company_name,
                on_hover_msg: '',
                description:''
            }
        });

        return transformed_data;
    }
    // #endregion

    // #region handlers

    clear_handler = e => {
        if (!this._is_mounted) return;

        let state_read: constants.IStateTemplate = this.state;
        state_read.is_loading.company = false;
        state_read.is_loading.search_data = false;
        state_read.is_loading.patient_letter_variables = false;
        state_read.show_grid = false;

        this.display_form_letters_list = [];
        this.setState({
            search_request_obj: { ...this.new_search_request_object },
            is_loading: state_read.is_loading,
            show_grid: state_read.show_grid
        });
    }

    //New button handler.
    new_handler = e => {

        this.drawer_letter.display_patient_letter_id = 0;

        let state_read = this.state;
        state_read.show_drawer = true;
        this.setState({ show_drawer: state_read.show_drawer });
    }


    letter_name_change_handler = e => {

        const { value, name } = e.target;

        var new_search_request_object: constants.IPatientLetterSearchRequestDto;
        new_search_request_object = {
            letter_name: value,
            company_id: this.state.search_request_obj.company_id,
            show_inactive: this.state.search_request_obj.show_inactive
        }
        this.setState({ search_request_obj: new_search_request_object });
    }


    company_change_handler = e => {

        const { value, name } = e.target;
        var new_search_request_object: constants.IPatientLetterSearchRequestDto;
        new_search_request_object = {
            letter_name: this.state.search_request_obj.letter_name,
            company_id: value,
            show_inactive: this.state.search_request_obj.show_inactive
        }
        this.setState({ search_request_obj: new_search_request_object });
    }


    show_inactive_handler = e => {

        const { checked, name } = e.target;

        var new_search_request_object: constants.IPatientLetterSearchRequestDto;
        new_search_request_object = {
            letter_name: this.state.search_request_obj.letter_name,
            company_id: this.state.search_request_obj.company_id,
            show_inactive: checked
        }
        this.setState({ search_request_obj: new_search_request_object });
    }

    on_letter_update = (is_updated: boolean) => {

        this.drawer_letter.is_updated = is_updated;
    }
    // #endregion

    //#region grid settings
    get_grid_ref = (grid_params) => {
        this._grid_ref = grid_params;
    };

    get_grid_display_data = (raw_data: Array<constants.IPatientLetterSearchDataDto>): Array<constants.IDisplayFormLettersDto> => {
        if (!this._is_mounted) return;

        var data_list: Array<constants.IDisplayFormLettersDto> = [];
        var item: constants.IDisplayFormLettersDto;
        raw_data.map((data_item) => {
            item = {
                patient_letter_id: data_item.patient_letter_id,
                patient_letter_name: data_item.patient_letter_name,
                status: data_item.is_active ? 'Active' : 'Inactive',
                global: data_item.is_global ? 'Yes' : 'No',
                last_updated_date_time: custom_date_format(data_item.updated_date, date_format.date_time_without_sec_format),
                last_updated_user: data_item.updated_by,
                company: this.get_companies(data_item.company_list)
            };
            data_list.push(item);
        });
        return data_list;
    }

    get_companies = (company_list: Array<constants.IPatientLetterCompanyDto>) => {
        
        if (company_list && company_list.length > 0) {
            return company_list.map(t => t.gpms_code).join(",");
        }
        else
            return '';
    }

    is_show_grid = (): boolean => {
        return this.state.show_grid && !this.is_loading();
    }

    display_record_count = (): string => {
        let count = this.display_form_letters_list ? this.display_form_letters_list.length : 0;
        if (count <= 1)
            return count + " record shown";
        else
            return count + " records shown";
    }
    //#endregion

    //#region grid events
    //On double click of grid, it redirects user to view page.
    on_row_double_clicked = (selected_row_data) => {
        this.drawer_letter.display_patient_letter_id = selected_row_data.patient_letter_id;

        //load letter from db
        this.get_patient_lettter().then(() => {
            let state_read = this.state;
            state_read.show_drawer = true;
            this.setState({ show_drawer: state_read.show_drawer });
        });
        return false;
    };
    //#endregion

    // #region Drawer Display
    show_drawer = (): boolean => {
        return (!this.is_loading() && this.state.show_drawer);
    }

    get_drawer_component = () => {

        let render_contents =
            <React.Fragment>
                <Grid style={{ marginTop: 0, marginBottom: 0 }}>
                    <Grid.Column computer={16} textAlign="right" className="proc_code_replacement_drawer_wrapper">

                        <DrawerComponent
                            key={this.props.key_id}
                            canOutsideClickClose={true}
                            canEscapeKeyClose={false}
                            isOpen={this.state.show_drawer}
                            className="custom-width-drawer form-letter-drawer"
                            handleDrawerClose={this.handle_drawer_close}
                            position={Position.RIGHT}
                            title=""
                            isCloseButtonShown={true}
                            innerContent={this.get_drawer_inner_content()}
                            size="90%"
                            disableAutoClose={true}
                        />
                    </Grid.Column>
                </Grid>
            </React.Fragment>;

        return render_contents;
    }

    get_drawer_inner_content = () => {

        let patient_letter_object: constants.IPatientLetterSaveRequestDto;

        // Edit Option
        if (this.drawer_letter.display_patient_letter_id > 0) {
            patient_letter_object = this.drawer_letter.patient_letter_edit;
        } else {
            patient_letter_object = {
                patient_letter_id: 0,
                patient_letter_name: '',
                patient_letter_content: '',
                is_active: true,
                is_global: false,
                created_date: null,
                created_by: this.props.user_login_details.user_data.data.user_id,
                updated_by: this.props.user_login_details.user_data.data.user_id,
                updated_date: null,
                company_list: [],
                cloned_from: null
            }
        }


        return (
            <React.Fragment>
                <FormLetterDetailComponent
                    companyList={[...this.master_company_list_multiselect_display]}
                    letterObject={patient_letter_object}
                    letterVariableList={[...this.patient_letter_variable_list]}
                    loggedInUserDetails={this.props.user_login_details.user_data.data}
                    onLetterUpdate={this.on_letter_update}
                />
            </React.Fragment>
        );
    }


    handle_drawer_close = () => {

        let state_read = this.state;

        if (this.drawer_letter.is_updated) {
            state_read.show_save_alert = true;
            this.setState({ show_save_alert: state_read.show_save_alert });
        }
        else {
            state_read.show_drawer = false;
            this.setState({ show_drawer: state_read.show_drawer });
            if (this.display_form_letters_list.length > 0) this.search_handler();

        }
    }
    // #endregion

    // #region Alert
    on_close_alert() {
        let state_read = this.state;
        state_read.show_save_alert = false;
        this.setState({ show_save_alert: state_read.show_save_alert });
    }


    on_confirm_alert = () => {

        let state_read = this.state;
        state_read.show_save_alert = false;
        state_read.show_drawer = false
        this.drawer_letter.is_updated = false;
        this.setState({
            show_save_alert: state_read.show_save_alert,
            show_drawer: state_read.show_save_alert
        });

    }

    // #endregion Alert

    //#region Render
    render() {
        return (
            <React.Fragment>
                <Dimmer active={this.is_loading()}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>

                <div className={'admin-wrapper ' + this.page_metadata.id} style={!this.state.show_grid ? { paddingBottom: 20 } : {}}>

                    <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                        <Grid.Column computer={16}>
                            <h3 className='ui header left aligned' dangerouslySetInnerHTML={{ __html: this.page_metadata.search_header }} />
                        </Grid.Column>
                    </Grid>

                    <AlertConfirm
                        open={this.state.show_save_alert}
                        close={() => this.on_close_alert()}
                        cancel={() => this.on_close_alert()}
                        confirm={() => this.on_confirm_alert()}
                        message={constants.alert.message}
                        labelYes={constants.alert.cofirm_btn_name}
                    />

                    <div id='admin-scrollable-area' className='wrapper' style={this.state.show_grid ? { flex: 1 } : {}}>
                        <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container'>
                            <Form autoComplete='off' onSubmit={() => this.search_handler()}>
                                <Grid>
                                    <Grid.Column tablet={8} computer={4}>
                                        <Form.Field>
                                            <label>Letter Name</label>
                                            <Input
                                                autoComplete='off'
                                                name='letter_name'
                                                id='letter_name'
                                                maxLength={50}
                                                type='text'
                                                value={this.state.search_request_obj.letter_name}
                                                onChange={e => this.letter_name_change_handler(e)}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column tablet={8} computer={4}>
                                        <Form.Field>
                                            <label>Companies</label>
                                            <Selection
                                                id='company'
                                                name='company'
                                                options={this.company_dd_list}
                                                onChange={(value, e) => {
                                                    this.company_change_handler(e);
                                                }}
                                                defaultValue={this.state.search_request_obj.company_id}
                                                style={'dropdown-options-wrap'}
                                            />

                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column tablet={8} computer={4}>
                                        <Form.Field className='ui checkbox'>
                                            <div style={{ marginTop: 32 }}>
                                                <input
                                                    type='checkbox'
                                                    name='show_inactive'
                                                    id='show_inactive'
                                                    checked={this.state.search_request_obj.show_inactive}
                                                    onChange={e => this.show_inactive_handler(e)}
                                                />
                                                <label className='chkbx_label' htmlFor='show_inactive'>
                                                    Include Inactive </label>
                                            </div>
                                        </Form.Field>

                                    </Grid.Column>
                                </Grid>
                                <Grid style={{ margin: '0 -17px' }}>
                                    <Grid.Column computer={16} textAlign='right'>
                                        <Button id='clear_button_id' type='button' onClick={e => this.clear_handler(e)} basic>
                                            Clear
                                            </Button>

                                        <Button id='new_button_id' type='button' onClick={e => this.new_handler(e)} basic>
                                            New
                                            </Button>

                                        <Button id='search_button_id' type='submit' primary>
                                            Search
                                        </Button>
                                    </Grid.Column>
                                </Grid>
                                {this.is_show_grid() && (
                                    <Grid style={{ marginTop: '-1rem', marginBottom: 0 }}>
                                        <Grid.Column tablet={5} computer={4} textAlign='left'>
                                            <p style={{ fontSize: '16px' }}>Search Results</p>
                                        </Grid.Column>
                                        <Grid.Column tablet={5} computer={8} textAlign='center'>
                                            {
                                                <p style={{ fontSize: '16px', minHeight: 22 }}>
                                                    {this.display_record_count()}
                                                </p>
                                            }
                                        </Grid.Column>
                                        <Grid.Column tablet={2} computer={4} textAlign='right' />
                                    </Grid>
                                )}
                            </Form>
                        </div>

                        {this.is_show_grid() && (
                            <GridView
                                id={constants.grid_settings.grid_id}
                                row={this.display_form_letters_list}
                                column={constants.grid_settings.column_defs}
                                style={{ height: '100%' }}
                                wrapperClass={'grid_wrapper'}
                                wrapperStyle={{ width: '100%', height: '100%', display: 'flex' }}
                                onRowDoubleClicked={this.on_row_double_clicked}
                                suppressMovableColumns={false}
                                enableColResize={true}
                                selectionType={'single'}
                                suppressSizeToFit={true}
                                get_grid_ref={this.get_grid_ref}
                                headerHeight={this.grid_header_height}
                                emptyMessage={constants.grid_settings.no_records_found_message}
                            />
                        )}

                        {this.show_drawer() && this.get_drawer_component()}
                    </div>
                </div>
            </React.Fragment>

        );
    }
    // #endregion
}

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details
    };
};
export default connect(mapStateToProps)(FormLettersSearchComponent);