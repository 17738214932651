import * as React from 'react';
import { Button, Form, Grid, Modal } from 'semantic-ui-react';
import { duplicate_patient_configuration } from '../patient_constants';
import GridView from './../../shared/component/grid';

class DuplicatePatientComponent extends React.Component<any, any> {
    [x: string]: any;
    _is_mounted = false;
    constructor(props) {
        super(props);
        //TODO: remove when year picker implemented
        this.state = { rowData: this.props.duplicate_patient_data, showModal: this.props.showModal };
    }
    UNSAFE_componentWillMount() {       
            this.setState({ show_modal: this.props.showModal, rowData: this.props.duplicate_patient_data });     
        //this.resetComponent()
    }
    componentDidMount() {
        this._is_mounted = true;
    }
    componentWillUnmount() {
        this._is_mounted = false;
        if (this.props.onRef) {
            this.props.onRef(undefined)
        }
    }  
    onDoubleClick = (selectedRows) => {
        this.props.onGridRowSelection(selectedRows);
        this.closeModal();
    }
    componentDidUpdate(previousProps, previousState) {
        if (previousProps.showModal !== this.props.showModal) {
            if (this._is_mounted) {
                this.setState({ showModal: this.props.showModal });
            }
        }
    }
    //close the modal on selection of patient through quick search
    closeModal = () => {
        //if open modal using application header
        if (this._is_mounted) {
            this.setState({ showModal: false, rowData: [], errorMessage: '' });
        }
        this.props.onCloseModal();

    };
    on_save_click = () => {
        this.props.on_force_save_click()

    }
    render() {
        const { columnData, rowData, showModal, errorMessage } = this.state;
        return (
            <div className="item">
                <Modal
                    onClose={this.closeModal}
                    centered={false}
                    className="searchCompany"
                    open={showModal}
                    closeIcon
                    closeOnDimmerClick={false}
                >
                    <Modal.Header>Duplicate Patient Found</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Grid>
                                <Grid.Column>
                                    <Grid>
                                        <Grid.Column tablet={14} computer={14}>
                                            <Form.Field>
                                                <label>Multiple patient were found with this name and date of birth. Click save to save the record anyways or choose an existing record</label>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={2} computer={2} align="right">
                                            <Button type='submit'  primary onClick={this.on_save_click}>Save</Button>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                            </Grid>
                            <br/>
                            {rowData && rowData.length > 0 && <GridView row={rowData}
                                column={duplicate_patient_configuration.column_defs}                                
                                isPagination={false}
                                gridAutoHeight={true}
                                onRowDoubleClicked={this.onDoubleClick} />
                               
                            }
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}



export default DuplicatePatientComponent