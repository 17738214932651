import { IProviderBillingStrategy } from './provider_billing_validator_strategy';
import { ProviderBillingService } from './provider_billing_service';

export class ConcreteStrategyDataRestrictionSpecific implements IProviderBillingStrategy {
    private providerBillingService = new ProviderBillingService();

    private hasDataRestrictionFromUI(elementToCheck: any, indexElement: number, dataToCompare: any[]) {
        return (elementToCheck.status) ? this.providerBillingService.dataRestrictionMet(elementToCheck, indexElement, dataToCompare) : false;
    }

    public validate(data: any[]): boolean {
        return !this.hasDataRestrictionFromUI(data[0], data[1], data[2]);
    }
}
