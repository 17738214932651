import * as global_constants from '../../global_constants';
import { currency_comparator, number_comparator, text_comparator, mm_dd_yyyy_h_mm_ss_tt_comparator, month_name_comparator } from '../../shared/component/grid/comparators';
import { get_header_template_with_navigation } from '../../shared/component/grid/constants';
import * as moment from 'moment';
import { get_text_max_width, custom_date_format } from '../../shared/utility';
import { readonly_checkbox_cell_renderer } from '../../shared/component/grid/gridview_cell_renderer';
import {
    get_role,
    get_permission_set,
    update_role_status,
    update_permission_set_status
  } from '../constants/action/constants_action';
import * as PrintExportConstants from '../../shared/audit/print_export_constants';
import * as ReportConstants from '../../reports/report_constants';

// Custom formatting and styling for grid header
export const header_template_with_navigation = id => {
    return {
        template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '<i class="small filter icon filter-ipad filter-active-icon"></i>' +
            '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '<div ref="eLabel" class="ag-header-cell-label" role="presentation" id="' +
            id +
            '" tabindex="0">' +
            '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
            '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
            '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
            '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
            '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
            '</div>' +
            '</div>'
    };
};

export const header_template = {
    template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '<i class="small filter icon filter-ipad filter-active-icon"></i>' +
        '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '<div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '</div>' +
        '</div>'
};

export const HEADER_TEMPLATE_FIRST_COLUMN = {
    template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '<i class="small filter icon filter-ipad filter-active-icon"></i>' +
        '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '<div ref="eLabel" class="ag-header-cell-label header-first-col-container" role="presentation" tabindex="0">' +
        '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '</div>' +
        '</div>'
};

export const headerStatusTemplate = {
    template:
        '<div class="ag-cell-label-container column-center" role="presentation">' +
        '<i class="small filter icon filter-ipad filter-active-icon"></i>' +
        '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '<div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '</div>' +
        '</div>'
};

export const headerCurrencyTemplate = {
    template:
        '<div class="ag-cell-label-container column-right" role="presentation">' +
        '<i class="small filter icon filter-ipad-amount filter-active-icon"></i>' +
        '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '<div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '</div>' +
        '</div>'
};

export const header_template_no_filter_icon = {
    template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '<div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '</div>' +
        '</div>'
};

export const statusStyle = {
    color: 'black',
    'background-color': '#dce1e7',
    'text-align': 'center'
};

export const page_list = [
    'payment_code',
    'adjustment_code',
    'transaction_message_code',
    'claim_adjustment_code',
    'special_program',
    'procedure_code',
    'referring_physician',
    'referral_type',
    'modifier',
    'contract',
    'medical_group',
    'diagnosis_code',
    'referral_source',
    'provider_qualifier',
    'company',
    'insurance_class',
    'revenue_center',
    'status_group_code',
    'status_category',
    //  'users'
];

export const page_lists = [
    { name: 'payment_code', is_page_add_edit: true },
    { name: 'adjustment_code', is_page_add_edit: true },
    { name: 'transaction_message_code', is_page_add_edit: true },
    { name: 'claim_adjustment_code', is_page_add_edit: true },
    { name: 'special_program', is_page_add_edit: true },
    { name: 'procedure_code', is_page_add_edit: true },
    { name: 'referring_physician', is_page_add_edit: true },
    { name: 'referral_type', is_page_add_edit: true },
    { name: 'modifier', is_page_add_edit: true },
    { name: 'contract', is_page_add_edit: true },
    { name: 'medical_group', is_page_add_edit: true },
    { name: 'diagnosis_code', is_page_add_edit: true },
    { name: 'referral_source', is_page_add_edit: true },
    { name: 'provider_qualifier', is_page_add_edit: true },
    { name: 'revenue_center', is_page_add_edit: true },
    { name: 'company', is_page_add_edit: false },
    { name: 'insurance_class', is_page_add_edit: false },
    { name: 'status_group_code', is_page_add_edit: true },
    { name: 'status_category', is_page_add_edit: true },
];

export const mandatory_fields = 'You must fill in all required fields or check the validity of the highlighted fields.';
export const insurance_mandatory_fields = 'Either Insurance Class or Insurance Code is required.';

export const messages = {
    compnay_override_exists: 'The override already exists for this company ,payer and code',
    update_adj_code_company: 'Claim adjustment reason code -Company override updated.',
    add_adj_code_company: 'Claim adjustment reason code -Company override added.',
    payer_override_exists: 'The override already exists for this payer and code',
    payer_update_adj_code: 'Payer override updated successfully.',
    payer_add_adj_code: 'Payer override added successfully.',
    payer_alert_add: 'Payer alert added successfully.',
    payer_alert_update: 'Payer alert updated successfully.',
    referral_type_add: 'Referral type added successfully.',
    referral_type_update: 'Referral type updated successfully.',
    provider_code_validaion: 'Provider code must be between 3 and 5 characters in length'
};

export const search_result_row_height_const = 17;
export const search_grid_id = 'search_grid_id';

export const group_by = function (xs, key) {
    let grp_array = xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, []);
    return grp_array;
};

//To Do, need to move this in utility
export const formatNumber = function (number) {
    // this puts commas into the number eg 1000 goes to 1,000,
    //return Math.floor(number).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

// Currency formatter for grid cell

//To Do, need to move this in utility
export const currencyFormatter = function (data) {
    var format_number = '';
    if (data < 0) {
        format_number = '($' + formatNumber((parseFloat(data) * -1).toFixed(2)) + ')';
    } else {
        format_number = '$' + formatNumber(parseFloat(data).toFixed(2));
    }
    return format_number;
};

//To Do, need to move this in utility
export const option_formater = (array, text, value, add_blank = false, sorted = true, blank_text = ' ', blank_value = ' ') => {
    // let options = array
    let options = array.map(item => {
        return {
        text: item[text] ? item[text].replace(/\n/g, ' ') : '',
        value: item[value]
      };
    });

    if (sorted) {
        options = options.sort(function (obj1, obj2) {
            var nameA = (obj1.text == null || obj1.text == undefined) ? '' : obj1.text.toLowerCase().trim(),
                nameB = (obj2.text == null || obj2.text == undefined) ? '' : obj2.text.toLowerCase().trim();
            if (nameA < nameB)
                //sort string ascending
                return -1;
            if (nameA > nameB) return 1;
            return 0;
        });
    }

    if (add_blank) {
        options.unshift({
            text: blank_text,
            value: blank_value
        });
    }
    return options;
};

export const option_formatter_order_by_sequence = (array:Array<any>, text:string, value:string)=>{
    let options = array.sort((a:any, b:any) => a.sequence - b.sequence);
    options = options.map((item:any) => {
        return {
            text: item[text] ? item[text].replace(/\n/g, ' ') : '',
            value: item[value]
        };
    });
    return options;
}

export const option_formatter_multiSelect = (options: any[], text = 'name', value = 'value') => {
  let selected_options = [];
  let formatted_options = options.map((item) => {
    item = {
      ...item,
      name: item[text],
      disabled: item.checked || false,
      is_checked: item.checked || false,
      value: item[value],
      on_hover_msg: null
    };
    if (item.checked) {
      selected_options.push(item[value]);
    }
    return item;
  });
  return formatted_options;
};

//To Do, need to move this in utility
export const loadMime = (file, callback) => {
    //List of known mimes
    var mimes = [
        {
            mime: 'image/jpeg',
            pattern: [0xff, 0xd8, 0xff],
            mask: [0xff, 0xff, 0xff]
        },
        {
            mime: 'image/png',
            pattern: [0x89, 0x50, 0x4e, 0x47],
            mask: [0xff, 0xff, 0xff, 0xff]
        },
        {
            mime: 'image/gif',
            pattern: [0x47, 0x49, 0x46, 0x38],
            mask: [0xff, 0xff, 0xff, 0xff]
        }
        // you can expand this list @see https://mimesniff.spec.whatwg.org/#matching-an-image-type-pattern
    ];
    function check(bytes, mime) {
        for (var i = 0, l = mime.mask.length; i < l; ++i) {
            if ((bytes[i] & mime.mask[i]) - mime.pattern[i] !== 0) {
                return false;
            }
        }
        return true;
    }

    var blob = file.slice(0, 4); //read the first 4 bytes of the file
    var reader = new FileReader();

    reader.onloadend = (e: any) => {
        if (e.target.readyState === FileReader.DONE) {
            var bytes = new Uint8Array(e.target.result);
            for (var i = 0, l = mimes.length; i < l; ++i) {
                if (check(bytes, mimes[i]))
                    return callback(
                        'Mime: ' + mimes[i].mime + ' <br> Browser:' + file.type,
                        true,
                        `data:${mimes[i].mime};base64`,
                        file
                    );
            }
            return callback('Mime: unknown <br> Browser:' + file.type, false, 'unknown', file);
        }
    };
    reader.readAsArrayBuffer(blob);
};

// show inactive and available  name for reset data after save or update
export const checkbox_name = /(is_active|show_inactive|is_excluded|is_avaliable|show_inactive|include_inactive|Show_inactive)/;

export const set_column_data_width = (rows, columns) => {
    let total_column_width = 0;
    columns.map((data) => {
        var max_len_text = get_text_max_width(rows, data['field'])

        data.width = max_len_text > get_header_max_text(data.headerName) ?
            max_len_text : get_header_max_text(data.headerName);
        total_column_width += data.width;
    })
    return total_column_width;
};

export const get_header_max_text = header => {
    let header_array = header.split('<br>');
    return get_text_max_width(header_array);
};

export const getLabelRecords = (eventTypeId: number): string => {
    return `Total_Record_${eventTypeId === ReportConstants.ReportEventAction.Print ? "Printed" : "Exported"}`
}

export const payment_code = {
    id: 'payment_code_id',
    search_header: 'Payment Code Search',
    add_header: 'Payment Code',
    edit_header: 'Payment Code',
    base_url: '/payments',
    create_button_label: 'New',
    show_print_on_search: true,
    show_export_on_search: true,
    edit_permission_name: 'Payment Code - Edit',
    control_data: [
        // Search
        {
            control_type: 'text',
            label: 'Payment Code',
            name: 'payment_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_search: true,
            is_add: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 7,
            view_container_computer: 6,
            search_container_tablet: 6,
            search_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Payment Description',
            name: 'payment_desc',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 7,
            view_container_computer: 6,
            search_container_tablet: 6,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Show Inactive',
            name: 'show_inactive',
            default_value: false,
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            custom_style: { marginTop: 32 },
            view_container_tablet: 6,
            view_container_computer: 3,
            search_container_tablet: 4,
            search_container_computer: 4
        },
        {
            control_type: 'label',
            label: 'Payment Code',
            name: 'payment_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_edit: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 7,
            view_container_computer: 6,
            search_container_tablet: 6,
            search_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Payment Description',
            name: 'payment_desc',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 7,
            view_container_computer: 6,
            search_container_tablet: 6,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Available to Clinic',
            name: 'clinic_credit_flag',
            default_value: '',
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 2,
            view_container_tablet: 7,
            view_container_computer: 3,
            search_container_tablet: 3,
            search_container_computer: 3
        },
        {
            control_type: 'checkbox',
            label: 'Copay Flag',
            name: 'copay_flag',
            default_value: '',
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 2,
            view_container_tablet: 7,
            view_container_computer: 3,
            search_container_tablet: 3,
            search_container_computer: 3
        },
        {
            control_type: 'checkbox',
            label: 'Insurance Indicator',
            name: 'ins_indicator',
            default_value: '',
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 2,
            view_container_tablet: 7,
            view_container_computer: 3,
            search_container_tablet: 3,
            search_container_computer: 3
        },
        {
            control_type: 'checkbox',
            label: 'Is Active',
            name: 'is_active',
            default_value: true,
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 2,
            view_container_tablet: 7,
            view_container_computer: 3,
            search_container_tablet: 3,
            search_container_computer: 3
        }
    ],

    api: {
        add: {
            type: 'POST',
            url: '/constant/payment_code'
        },
        search: {
            type: 'GET',
            url:
                '/constant/payment_code/search?payment_code=payment_code_value&payment_desc=payment_desc_value&show_inactive=show_inactive_value'
        },
        update: {
            type: 'PUT',
            url: '/constant/payment_code/id_value'
        },
        get: {
            type: 'GET',
            url: '/constant/payment_code/id_value'
        }
    },

    column_def: [
        {
            headerName: 'Payment Code',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'payment_code',
            filter: 'textFilter',
            minWidth: 200,
            width: 200,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Payment Description',
            headerComponentParams: header_template,
            field: 'payment_desc',
            filter: 'textFilter',
            minWidth: 250,
            width: 700,
            type: 'string',
            sort: 'asc',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Payment Code ID',
            headerComponentParams: header_template,
            field: 'payment_code_id',
            filter: 'textFilter',
            minWidth: 250,
            width: 250,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'is_active',
            filter: 'checklistFilter',
            minWidth: 120,
            width: 150,
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true
        }
    ],

    validation_message: {
        error: '',
        save: 'Payment Code Added.',
        update: 'Payment Code Updated.',
        warning: ''
    },

    param_validator: [
        {
            expression: '',
            message: ''
        }
    ]
};

export const adjustment_code = {
    id: 'adjustment_code_id',
    search_header: 'Adjustment Code Search',
    add_header: 'Adjustment Code',
    edit_header: 'Adjustment Code',
    base_url: '/payments',
    create_button_label: 'New',
    show_print_on_search: true,
    show_export_on_search: true,
    edit_permission_name: 'Adjustment Code - Edit',
    control_data: [
        {
            control_type: 'text',
            label: 'Adjustment Code',
            name: 'adjustment_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_search: true,
            is_add: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 7,
            view_container_computer: 6,
            search_container_tablet: 6,
            search_container_computer: 4
        },
        {
            control_type: 'label',
            label: 'Adjustment Code',
            name: 'adjustment_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_edit: true,
            row: 1,
            view_container_tablet: 7,
            view_container_computer: 6,
            search_container_tablet: 6,
            search_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Adjustment Description',
            name: 'adjustment_desc',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_search: true,
            is_add: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 7,
            view_container_computer: 6,
            search_container_tablet: 6,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Show Inactive',
            name: 'show_inactive',
            default_value: false,
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            custom_style: { marginTop: 32 },
            view_container_tablet: 6,
            view_container_computer: 6,
            search_container_tablet: 4,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Insurance Indicator',
            name: 'ins_indicator',
            default_value: '',
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 2,
            view_container_tablet: 7,
            view_container_computer: 6,
            search_container_tablet: 3,
            search_container_computer: 3
        },
        {
            control_type: 'checkbox',
            label: 'Is Active',
            name: 'is_active',
            default_value: true,
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 2,
            view_container_tablet: 7,
            view_container_computer: 6,
            search_container_tablet: 3,
            search_container_computer: 3
        }
    ],

    api: {
        add: {
            type: 'POST',
            url: '/constant/adjustment_code'
        },
        search: {
            type: 'GET',
            url:
                '/constant/adjustment_code/search?adjustment_code=adjustment_code_value&adjustment_desc=adjustment_desc_value&show_inactive=show_inactive_value'
        },
        update: {
            type: 'PUT',
            url: '/constant/adjustment_code/id_value'
        },
        get: {
            type: 'GET',
            url: '/constant/adjustment_code/id_value'
        }
    },

    column_def: [
        {
            headerName: 'Adjustment Code',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'adjustment_code',
            filter: 'textFilter',
            minWidth: 200,
            width: 200,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Adjustment Description',
            headerComponentParams: header_template,
            field: 'adjustment_desc',
            filter: 'textFilter',
            minWidth: 250,
            width: 700,
            type: 'string',
            sort: 'asc',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Adjustment Code ID',
            headerComponentParams: header_template,
            field: 'adjustment_code_id',
            filter: 'textFilter',
            minWidth: 250,
            width: 250,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'is_active',
            filter: 'textFilter',
            minWidth: 120,
            width: 150,
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true,
            comparator: text_comparator
        }
    ],

    validation_message: {
        error: '',
        save: 'Adjustment Code Added.',
        update: 'Adjustment Code Updated.',
        warning: ''
    },

    param_validator: [
        {
            expression: ' ',
            message: ''
        }
    ]
};

export const transaction_message_code = {
    id: 'transaction_message_id',
    search_header: 'Transaction Message Search',
    add_header: 'Transaction Message',
    edit_header: 'Transaction Message',
    base_url: '/payments',
    create_button_label: 'New',
    control_data: [
        {
            control_type: 'text',
            label: 'Transaction Message Code',
            name: 'transaction_message_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_search: true,
            is_add: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 7,
            view_container_computer: 6,
            search_container_tablet: 6,
            search_container_computer: 4
        },
        {
            control_type: 'label',
            label: 'Transaction Message Code',
            name: 'transaction_message_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_edit: true,
            row: 1,
            view_container_tablet: 7,
            view_container_computer: 6,
            search_container_tablet: 6,
            search_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Transaction Message',
            name: 'transaction_message',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_search: true,
            is_add: true,
            row: 1,
            max_length: 500,
            view_container_tablet: 7,
            view_container_computer: 6,
            search_container_tablet: 6,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Show Inactive',
            name: 'show_inactive',
            default_value: false,
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            custom_style: { marginTop: 32 },
            view_container_tablet: 6,
            view_container_computer: 3,
            search_container_tablet: 4,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'On Statements',
            name: 'on_statements',
            default_value: '',
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 2,
            view_container_tablet: 7,
            view_container_computer: 6,
            search_container_tablet: 3,
            search_container_computer: 3
        },
        {
            control_type: 'checkbox',
            label: 'On Claims',
            name: 'on_claims',
            default_value: '',
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 2,
            view_container_tablet: 7,
            view_container_computer: 6,
            search_container_tablet: 3,
            search_container_computer: 3
        },
        {
            control_type: 'checkbox',
            label: 'Is Active',
            name: 'is_active',
            default_value: true,
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 2,
            view_container_tablet: 7,
            view_container_computer: 3,
            search_container_tablet: 3,
            search_container_computer: 3
        }
    ],

    api: {
        add: {
            type: 'POST',
            url: '/constant/transaction_message_code'
        },
        search: {
            type: 'GET',
            url:
                '/constant/transaction_message_code/search?transaction_message_code=transaction_message_code_value&transaction_message=transaction_message_value&show_inactive=show_inactive_value&page_size=' +
                global_constants.constants.PAGE_SIZE
        },
        update: {
            type: 'PUT',
            url: '/constant/transaction_message_code/id_value'
        },
        get: {
            type: 'GET',
            url: '/constant/transaction_message_code/id_value'
        }
    },

    column_def: [
        {
            headerName: 'Transaction Message Code',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'transaction_message_code',
            filter: 'textFilter',
            minWidth: 300,
            width: 300,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Transaction Message',
            headerComponentParams: header_template,
            field: 'transaction_message',
            filter: 'textFilter',
            minWidth: 250,
            width: 700,
            type: 'string',
            // sort: 'asc',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'ID',
            headerComponentParams: header_template,
            field: 'transaction_message_code_id',
            filter: 'textFilter',
            minWidth: 100,
            width: 120,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'is_active',
            filter: 'checklistFilter',
            minWidth: 120,
            width: 120,
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true
        }
    ],

    validation_message: {
        error: '',
        save: 'Transaction Message Added.',
        update: 'Transaction Message Updated.',
        warning: ''
    },

    param_validator: [
        {
            expression: '',
            message: ''
        }
    ]
};

export const claim_adjustment_code = {
    id: 'claim_adjustment_code_id',
    search_header: 'Claim Adjustment Reason Code Search',
    add_header: 'Claim Adjustment Reason Code',
    edit_header: 'Claim Adjustment Reason Code',
    base_url: '/payments',
    create_button_label: 'New',
    show_print_on_search: true,
    show_export_on_search: true,
    control_data: [
        {
            control_type: 'text',
            label: 'CARC',
            name: 'carc',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_search: true,
            row: 1,
            max_length: 10,
            view_container_tablet: 7,
            view_container_computer: 6,
            search_container_tablet: 6,
            search_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Claim Adjustment Reason Code',
            name: 'carc',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_add: true,
            row: 1,
            max_length: 10,
            view_container_tablet: 7,
            view_container_computer: 6,
            search_container_tablet: 6,
            search_container_computer: 4
        },
        {
            control_type: 'label',
            label: 'Claim Adjustment Reason Code',
            name: 'carc',
            default_value: '',
            master_data: [], // In case of dropdown
            is_edit: true,
            row: 1,
            view_container_tablet: 7,
            view_container_computer: 6,
            search_container_tablet: 6,
            search_container_computer: 4
        },
        {
            control_type: 'textarea',
            label: 'Description',
            name: 'description',
            default_value: '',
            master_data: [], // In case of dropdown
            is_edit: true,
            is_search: true,
            is_add: true,
            row: 1,
            max_length: 255,
            view_container_tablet: 7,
            view_container_computer: 6,
            search_container_tablet: 6,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Is Excluded',
            name: 'is_excluded',
            default_value: '',
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 2,
            view_container_tablet: 6,
            view_container_computer: 3,
            search_container_tablet: 4,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Is Excluded',
            name: 'is_excluded',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            custom_style: { marginTop: 32 },
            view_container_tablet: 6,
            view_container_computer: 3,
            search_container_tablet: 4,
            search_container_computer: 4
        },
        {
            control_type: 'label',
            label: 'Created By',
            name: 'created_by_desc',
            default_value: '',
            master_data: [], // In case of dropdown
            is_edit: true,
            row: 3,
            view_container_tablet: 7,
            view_container_computer: 3,
            search_container_tablet: 3,
            search_container_computer: 3
        },
        {
            control_type: 'label',
            label: 'Created Date',
            name: 'created_date',
            default_value: '',
            format: 'mm/dd/yyyy h:MM:ss TT',
            master_data: [], // In case of dropdown
            is_edit: true,
            row: 3,
            view_container_tablet: 7,
            view_container_computer: 3,
            search_container_tablet: 3,
            search_container_computer: 3
        },
        {
            control_type: 'label',
            label: 'Updated By',
            name: 'updated_by_desc',
            default_value: '',
            master_data: [], // In case of dropdown
            is_edit: true,
            row: 3,
            view_container_tablet: 7,
            view_container_computer: 3,
            search_container_tablet: 3,
            search_container_computer: 3
        },
        {
            control_type: 'label',
            label: 'Updated Date',
            name: 'updated_date',
            default_value: '',
            format: 'mm/dd/yyyy h:MM:ss TT',
            master_data: [], // In case of dropdown
            is_edit: true,
            row: 3,
            view_container_tablet: 7,
            view_container_computer: 3,
            search_container_tablet: 3,
            search_container_computer: 3
        }
    ],

    api: {
        add: {
            type: 'POST',
            url: '/constant/eob_claim_adj_code'
        },
        search: {
            type: 'GET',
            url:
                '/constant/eob_claim_adj_code/search?carc=carc_value&description=description_value&is_excluded=is_excluded_value&page_size=' +
                global_constants.constants.PAGE_SIZE
        },
        update: {
            type: 'PUT',
            url: '/constant/eob_claim_adj_code/id_value'
        },
        get: {
            type: 'GET',
            url: '/constant/eob_claim_adj_code/id_value'
        }
    },

    column_def: [
        {
            headerName: 'CARC',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'carc',
            filter: 'textFilter',
            minWidth: 120,
            width: 120,
            maxWidth:150,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Description',
            headerComponentParams: header_template,
            field: 'description',
            filter: 'textFilter',
            minWidth: 200,
            width: 800,
            maxWidth:1300,
            type: 'string',
            // sort: 'asc',
            unSortIcon: true,
            comparator: text_comparator,
            tooltipField: 'description'
        },
        {
            headerName: 'Is Excluded',
            headerComponentParams: headerStatusTemplate,
            field: 'is_excluded',
            filter: 'checklistFilter',
            minWidth: 180,
            width: 180,
            type: 'string',
            unSortIcon: true,
            cellRenderer: params => {
                return `<div style="text-align:center"><input type='checkbox' ${
                    params.value ? 'checked' : ''
                    } disabled/></div>`;
            }
        },
        {
            headerName: 'ID',
            headerComponentParams: header_template,
            field: 'claim_adjustment_code_id',
            filter: 'textFilter',
            minWidth: 120,
            width: 120,
            maxWidth:150,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        }
    ],

    validation_message: {
        error: '',
        save: 'Claim Adjustment Reason Code Added.',
        update: 'Claim Adjustment Reason Code Updated.',
        warning: ''
    },

    param_validator: [
        {
            expression: '',
            message: ''
        }
    ]
};

export const special_program = {
    id: 'special_programs_id',
    search_header: 'Special Programs',
    add_header: 'Special Programs',
    edit_header: 'Special Programs',
    base_url: '/admin',
    create_button_label: 'New',
    show_print_on_search: true,
    show_export_on_search: true,
    grid_header_height: 60,
    control_data: [
        // Search
        {
            control_type: 'text',
            label: 'Special Program Code',
            name: 'special_program_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 7,
            view_container_computer: 6,
            search_container_tablet: 7,
            search_container_computer: 5
        },
        {
            control_type: 'text',
            label: 'Special Program Description',
            name: 'special_program_name',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 7,
            view_container_computer: 6,
            search_container_tablet: 7,
            search_container_computer: 5
        },
        {
            control_type: 'checkbox',
            label: 'Include Unavailable',
            name: 'is_avaliable',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            custom_style: { marginTop: 32 },
            view_container_tablet: 6,
            view_container_computer: 3,
            search_container_tablet: 6,
            search_container_computer: 4
        },
        // Add/Edit
        {
            control_type: 'text',
            label: 'Special Program Code',
            name: 'special_program_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 5,
            view_container_tablet: 7,
            view_container_computer: 5,
            search_container_tablet: 6,
            search_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Special Program Description',
            name: 'special_program_name',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 25,
            view_container_tablet: 7,
            view_container_computer: 5,
            search_container_tablet: 6,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Is Available',
            name: 'is_avaliable',
            default_value: true,
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            custom_style: { marginTop: 32 },
            row: 1,
            view_container_tablet: 6,
            view_container_computer: 4,
            search_container_tablet: 4,
            search_container_computer: 4
        }
    ],

    api: {
        add: {
            type: 'POST',
            url: '/constant/special_program'
        },
        search: {
            type: 'GET',
            url:
                '/constant/special_program/search_special_program?special_program_code=special_program_code_value&special_program_name=special_program_name_value&is_avaliable=is_avaliable_value'
        },
        update: {
            type: 'PUT',
            url: '/constant/special_program/id_value'
        },
        get: {
            type: 'GET',
            url: '/constant/special_program/id_value'
        }
    },

    column_def: [
        {
            headerName: 'Special Program Code',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'special_program_code',
            filter: 'textFilter',
            minWidth: 140,
            width: 230,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Special Program Description',
            headerComponentParams: header_template,
            field: 'special_program_name',
            filter: 'textFilter',
            minWidth: 140,
            width: 600,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Special Program ID',
            headerComponentParams: header_template,
            field: 'special_program_id',
            filter: 'numberFilter',
            minWidth: 120,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: number_comparator
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'is_avaliable',
            filter: 'checklistFilter',
            minWidth: 120,
            width: 120,
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true
        }
    ],

    validation_message: {
        error: '',
        save: 'Special Program Added.',
        update: 'Special Program Updated.',
        warning: ''
    },

    param_validator: [
        {
            expression: '',
            message: ''
        }
    ]
};

export const procedure_code = {
    id: 'payment_code_id',
    search_header: 'Procedure Code Search',
    add_header: 'Procedure Code Add',
    edit_header: 'Procedure Code Edit',
    base_url: '/charge',
    create_button_label: 'New',
    show_print_on_search: true,
    show_export_on_search: true,
    prompt_unsaved: true,
    lookup_search_list: ['revenue_center_id'],
    is_lookup_search: true,
    grid_header_height: 155,
    entity_type_id: PrintExportConstants.EntityTypeId.ProcedureCodeSearch,
    control_data: [
        // Search
        {
            control_type: 'text',
            label: 'Procedure Code',
            name: 'procedure_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Procedure Description',
            name: 'procedure_desc1',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 150,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Alternate Procedure Code',
            name: 'alt_proc_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Include Inactive',
            name: 'show_inactive',
            default_value: false,
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            custom_style: { marginTop: 32 },
            view_container_tablet: 6,
            view_container_computer: 4,
            search_container_tablet: 6,
            search_container_computer: 4
        },
        // View
        {
            control_type: 'text',
            label: 'Procedure Code',
            name: 'proc_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 7,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Procedure Description',
            name: 'proc_desc1',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 100,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'lookup_search',
            lookup_id: 'REVENUE_CENTER',
            label: 'Revenue Center',
            name: 'revenue_center_id',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            advance_search: '/constant/procedure_code/get_revenue_center',
            quick_search: '/constant/procedure_code/quick_serch_revenue_center',
            advance_search_lookup: '/constant/procedure_code/get_revenue_center?page_size=5000&id=',
            search_criteria_field: 'revenue_center_id',
            advance_search_field: { id: 'revenue_center_id', label: 'revenue_center_desc', title: 'revenue_center_code' },
            label_list: ['revenue_center_desc'],
            suggestion_label_list: ['revenue_center_code', 'revenue_center_desc'],
            get_quick_search_list: ['revenue_center_desc']
        },
        {
            control_type: 'currency',
            label: 'Standard Charge Amount',
            name: 'std_chg_amt',
            default_value: 0,
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'currency',
            label: 'Medicare Allowable Amount',
            name: 'medicare_allowable_amt',
            default_value: 0,
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 2,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Alternate Procedure Code',
            name: 'alt_proc_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 2,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'textarea',
            label: 'Note',
            name: 'note',
            default_value: '',
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 2,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'drop_down_list',
            label: 'Procedure Type',
            name: 'proc_type',
            default_value: null,
            master_data: [
                { value: '0', text: 'None', selected: false },
                { value: '1', text: 'Timed 15 Minutes', selected: false },
                { value: '2', text: 'Timed 30 Minutes', selected: false },
                { value: '3', text: 'Timed 60 Minutes', selected: false },
                { value: '4', text: 'Timed 120 Minutes', selected: false },
                { value: '5', text: 'Untimed', selected: false },
                { value: '6', text: 'Supply', selected: false },
                { value: '7', text: 'Uncategorized', selected: false }
            ], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 2,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Durable Medical Equipment (DME)',
            name: 'dme',
            default_value: '',
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 3,
            custom_style: {},
            view_container_tablet: 6,
            view_container_computer: 4,
            search_container_tablet: 6,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Audit Required',
            name: 'audit_required',
            default_value: '',
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 3,
            custom_style: {},
            view_container_tablet: 6,
            view_container_computer: 4,
            search_container_tablet: 6,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Never Bill',
            name: 'never_bill',
            default_value: '',
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 3,
            custom_style: {},
            view_container_tablet: 6,
            view_container_computer: 4,
            search_container_tablet: 6,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'CMS Therapy Code',
            name: 'is_cms_therapy',
            default_value: '',
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 3,
            custom_style: {},
            view_container_tablet: 6,
            view_container_computer: 4,
            search_container_tablet: 6,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Is Active',
            name: 'is_active',
            default_value: true,
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 4,
            custom_style: {},
            view_container_tablet: 6,
            view_container_computer: 4,
            search_container_tablet: 6,
            search_container_computer: 4
        },
        {
            control_type: 'label',
            label: 'Last Set Inactive Date',
            name: 'last_set_inactive_date',
            format: 'mm/dd/yyyy h:MM:ss TT',
            default_value: null,
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 5,
            custom_style: {},
            view_container_tablet: 6,
            view_container_computer: 4,
            search_container_tablet: 6,
            search_container_computer: 4
        },
        {
            control_type: 'label',
            label: 'Last Set Inactive By',
            name: 'last_set_inactive_by_user',
            default_value: '',
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 5,
            custom_style: {},
            view_container_tablet: 6,
            view_container_computer: 4,
            search_container_tablet: 6,
            search_container_computer: 4
        }
    ],

    api: {
        add: {
            type: 'POST',
            url: '/constant/procedure_code'
        },
        search: {
            type: 'GET',
            url:
                '/constant/procedure_code/search_procedure_code?procedure_code=procedure_code_value&procedure_desc1=procedure_desc1_value&alt_proc_code=alt_proc_code_value&show_inactive=show_inactive_value&page_size=5000'
        },
        update: {
            type: 'PUT',
            url: '/constant/procedure_code/id_value'
        },
        get: {
            type: 'GET',
            url: '/constant/procedure_code/id_value'
        }
    },

    column_def: [
        {
            headerName: 'Procedure Code',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'proc_code',
            filter: 'textFilter',
            minWidth: 140,
            width: 140,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Procedure Description',
            headerComponentParams: header_template,
            field: 'proc_desc1',
            filter: 'textFilter',
            minWidth: 150,
            width: 900,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Standard Charge Amount',
            headerComponentParams: headerCurrencyTemplate,
            field: 'std_chg_amt',
            filter: 'currencyFilter',
            minWidth: 190,
            width: 190,
            type: 'currency',
            unSortIcon: true,
            //cellRenderer: currencyFormatter,
            comparator: currency_comparator,
            cellStyle: { textAlign: 'right' }
        },
        {
            headerName: 'Medicare Allowable Amount',
            headerComponentParams: headerCurrencyTemplate,
            field: 'medicare_allowable_amt',
            filter: 'currencyFilter',
            minWidth: 210,
            width: 200,
            type: 'currency',
            // cellRenderer: currencyFormatter,
            unSortIcon: true,
            comparator: currency_comparator,
            cellStyle: { textAlign: 'right' }
        },
        {
            headerName: 'Alternate Procedure Code',
            headerComponentParams: header_template,
            field: 'alt_proc_code',
            filter: 'textFilter',
            minWidth: 145,
            width: 145,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Procedure Type',
            headerComponentParams: header_template,
            field: 'proc_type_description',
            filter: 'textFilter',
            minWidth: 180,
            width: 180,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Revenue Center Code',
            headerComponentParams: header_template,
            field: 'revenue_center_code',
            filter: 'numberFilter',
            minWidth: 135,
            width: 135,
            type: 'string',
            unSortIcon: true,
            comparator: number_comparator
        },
        {
            headerName: 'Revenue Center Description',
            headerComponentParams: header_template,
            field: 'revenue_center_description',
            filter: 'textFilter',
            minWidth: 180,
            width: 180,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Durable Medical Equipment',
            headerComponentParams: header_template,
            field: 'dme',
            filter: 'checklistFilter',
            minWidth: 150,
            width: 150,
            type: 'string',
            unSortIcon: true,
            cellRenderer: readonly_checkbox_cell_renderer,
            suppressSizeToFit: true,
            cellStyle: { textAlign: 'center' },
            hide: true
        },
        {
            headerName: 'Audit Required',
            headerComponentParams: header_template,
            field: 'audit_required',
            filter: 'checklistFilter',
            minWidth: 150,
            width: 150,
            type: 'string',
            unSortIcon: true,
            cellRenderer: readonly_checkbox_cell_renderer,
            suppressSizeToFit: true,
            cellStyle: { textAlign: 'center' },
            hide: false
        },
        {
            headerName: 'Never Bill',
            headerComponentParams: header_template,
            field: 'never_bill',
            filter: 'checklistFilter',
            minWidth: 150,
            width: 150,
            type: 'string',
            unSortIcon: true,
            cellRenderer: readonly_checkbox_cell_renderer,
            suppressSizeToFit: true,
            cellStyle: { textAlign: 'center' },
            hide: false
        },
        {
            headerName: 'CMS Therapy Code',
            headerComponentParams: header_template,
            field: 'is_cms_therapy',
            filter: 'checklistFilter',
            minWidth: 150,
            width: 150,
            type: 'string',
            unSortIcon: true,
            cellRenderer: readonly_checkbox_cell_renderer,
            suppressSizeToFit: true,
            cellStyle: { textAlign: 'center' },
            hide: false
        },
        {
            headerName: 'Procedure Code ID',
            headerComponentParams: header_template,
            field: 'procedure_code_id',
            filter: 'numberFilter',
            minWidth: 140,
            width: 140,
            type: 'string',
            unSortIcon: true,
            comparator: number_comparator
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'is_active',
            filter: 'checklistFilter',
            minWidth: 120,
            width: 120,
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true
        }
    ],

    validation_message: {
        error: '',
        save: 'Procedure Codes Added.',
        update: 'Procedure Codes Updated.',
        warning: ''
    },

    param_validator: [
        {
            expression: '',
            message: ''
        }
    ]
};

export const REVENUE_CENTER = {
    column_def: [
        {
            headerName: 'Revenue Center Code',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'revenue_center_code',
            filter: 'textFilter',
            minWidth: 240,
            width: 300,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Revenue Center Description',
            headerComponentParams: header_template,
            field: 'revenue_center_desc',
            filter: 'textFilter',
            minWidth: 270,
            width: 300,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        }
    ]
};

export const referring_physician = {
    id: 'referring_physician_id',
    search_header: 'Referring Physicians',
    add_header: 'Referring Physicians',
    edit_header: 'Referring Physicians',
    base_url: '/admin',
    create_button_label: 'New',
    show_print_on_search: true,
    show_export_on_search: true,
    advance_search: true,
    grid_header_height: 45,
    control_data: [
        // Search
        {
            control_type: 'text',
            label: 'MD Code',
            name: 'md_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 50,
            search_container_tablet: 8,
            search_container_computer: 4,
            view_container_tablet: 8,
            view_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'First Name',
            name: 'first_name',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 50,
            search_container_tablet: 8,
            search_container_computer: 4,
            view_container_tablet: 8,
            view_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Last Name',
            name: 'last_name',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 50,
            search_container_tablet: 8,
            search_container_computer: 4,
            view_container_tablet: 8,
            view_container_computer: 4
        },
        // In Advance Search
        {
            control_type: 'drop_down_list',
            label: 'Primary Specialty',
            name: 'speciality_id',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 2,
            in_advance_search: true,
            max_length: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            view_container_tablet: 8,
            view_container_computer: 4
        },
        {
            control_type: 'drop_down_list',
            label: 'Medical Group',
            name: 'medical_group_id',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 2,
            in_advance_search: true,
            max_length: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            view_container_tablet: 8,
            view_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'City',
            name: 'city',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 2,
            in_advance_search: true,
            max_length: 50,
            search_container_tablet: 8,
            search_container_computer: 3,
            view_container_tablet: 8,
            view_container_computer: 4
        },
        {
            control_type: 'drop_down_list',
            label: 'State',
            name: 'state',
            default_value: '',
            is_state: true,
            with_select: false,
            is_placeholder: true,
            master_data: [], // In case of dropdown
            is_search: true,
            row: 2,
            in_advance_search: true,
            max_length: 2,
            search_container_tablet: 8,
            search_container_computer: 3,
            view_container_tablet: 8,
            view_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Zip Code',
            name: 'zip',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 2,
            in_advance_search: true,
            max_length: 10,
            search_container_tablet: 8,
            search_container_computer: 2,
            view_container_tablet: 8,
            view_container_computer: 4,
            pattern: '[0-9]*'
        },
        {
            control_type: 'checkbox',
            label: 'Include Inactive',
            name: 'is_active',
            default_value: false,
            master_data: [], // In case of dropdown
            is_search: true,
            row: 3,
            in_advance_search: true,
            search_container_tablet: 6,
            search_container_computer: 4,
            view_container_tablet: 8,
            view_container_computer: 4
        },
        // View Edit
        {
            control_type: 'text',
            label: 'Title',
            name: 'title',
            default_value: '',
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 1,
            max_length: 26,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 3
        },
        {
            control_type: 'label',
            label: 'MD Code',
            name: 'md_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_edit: true,
            row: 0,
            max_length: 50,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 3
        },
        {
            control_type: 'text',
            label: 'First Name',
            name: 'first_name',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_add: true,
            is_edit: true,
            row: 1,
            max_length: 26,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 5
        },
        {
            control_type: 'text',
            label: 'Middle Initial',
            name: 'middle_intial',
            default_value: '',
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 1,
            max_length: 1,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 3
        },
        {
            control_type: 'text',
            label: 'Last Name',
            name: 'last_name',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_add: true,
            is_edit: true,
            row: 1,
            max_length: 26,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 5
        },
        {
            control_type: 'text',
            label: 'Credentials',
            name: 'credentials',
            default_value: '',
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 2,
            max_length: 100,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 3
        },
        {
            control_type: 'text',
            label: 'Address 1',
            name: 'address1',
            default_value: '',
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 2,
            max_length: 32,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 7,
            view_container_computer: 6
        },
        {
            control_type: 'text',
            label: 'Address 2',
            name: 'address2',
            default_value: '',
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 2,
            max_length: 32,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 7,
            view_container_computer: 6
        },
        {
            control_type: 'text',
            label: 'City',
            name: 'city',
            default_value: '',
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 3,
            max_length: 26,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 4
        },
        {
            control_type: 'drop_down_list',
            label: 'State',
            name: 'state',
            default_value: '',
            is_placeholder: true,
            is_add: true,
            is_edit: true,
            row: 3,
            max_length: 2,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 4
        },
        {
            control_type: 'zip',
            label: 'Zip Code',
            name: 'zip',
            default_value: '',
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 3,
            max_length: 10,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 4,
            is_required_text: true,
            is_required_msg: 'Zip can be 5 or 9 digits.',
            pattern: '[0-9]*'
        },
        {
            control_type: 'phone',
            label: 'Phone',
            name: 'phone',
            default_value: '',
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 3,
            max_length: 10,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 4,
            is_required_text: true,
            is_required_msg: 'Please enter 10 digits',
            pattern: '[0-9]*'
        },
        {
            control_type: 'phone',
            label: 'Fax',
            name: 'fax',
            default_value: '',
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 4,
            max_length: 10,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 4,
            is_required_text: true,
            is_required_msg: 'Please enter 10 digits',
            pattern: '[0-9]*'
        },
        {
            control_type: 'text',
            label: 'Email',
            name: 'email',
            default_value: '',
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 4,
            max_length: 50,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 4,
            is_required_text: true,
            is_required_msg: 'Please enter valid email address.'
        },
        {
            control_type: 'text',
            label: 'Website',
            name: 'website',
            default_value: '',
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 4,
            max_length: 26,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 4
        },
        {
            control_type: 'drop_down_list',
            label: 'Medical Group',
            name: 'medical_group_id',
            default_value: '',
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 5,
            max_length: 4,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'NPI',
            name: 'npi',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: false,
            is_add: true,
            is_edit: true,
            row: 5,
            max_length: 10,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 4,
            is_required_text: true,
            is_required_msg: 'Invalid NPI (Example 1234567893)'
        },
        {
            control_type: 'text',
            label: 'State License',
            name: 'state_license',
            default_value: '',
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 5,
            max_length: 26,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Medicaid License',
            name: 'medicalaid_license',
            default_value: '',
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 5,
            max_length: 26,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Other 1',
            name: 'other1',
            default_value: '',
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 6,
            max_length: 26,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Other 2',
            name: 'other2',
            default_value: '',
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 6,
            max_length: 26,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 4
        },
        {
            control_type: 'drop_down_list',
            label: 'Primary Specialty',
            name: 'speciality_id',
            default_value: '',
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 6,
            max_length: 4,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Taxonomy Code',
            name: 'taxonomy_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 6,
            max_length: 26,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Is Active',
            name: 'is_active',
            default_value: true,
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 7,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 4
        }
    ],

    api: {
        add: {
            type: 'POST',
            url: '/constant/referring_physicians?is_force_save=save_force'
        },
        search: {
            type: 'GET',
            url:
                '/constant/referring_physicians/search?md_code=md_code_value&first_name=first_name_value&last_name=last_name_value&speciality_id=speciality_id_value&medical_group_id=medical_group_id_value&city=city_value&state=state_value&zip=zip_value&is_active=is_active_value'
        },
        update: {
            type: 'PUT',
            url: '/constant/referring_physicians/id_value?is_force_save=save_force'
        },
        get: {
            type: 'GET',
            url: '/constant/referring_physicians/id_value'
        }
    },

    column_def: [
        {
            headerName: 'MD Code',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'md_code',
            filter: 'textFilter',
            minWidth: 110,
            width: 129,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'First Name',
            headerComponentParams: header_template,
            field: 'first_name',
            filter: 'textFilter',
            minWidth: 145,
            width: 600,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Last Name',
            headerComponentParams: header_template,
            field: 'last_name',
            filter: 'textFilter',
            minWidth: 145,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Credentials',
            headerComponentParams: header_template,
            field: 'credentials',
            filter: 'textFilter',
            minWidth: 150,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Address 1',
            headerComponentParams: header_template,
            field: 'address1',
            filter: 'textFilter',
            minWidth: 140,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Address 2',
            headerComponentParams: header_template,
            field: 'address2',
            filter: 'textFilter',
            minWidth: 145,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'City',
            headerComponentParams: header_template,
            field: 'city',
            filter: 'textFilter',
            minWidth: 95,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'State',
            headerComponentParams: header_template,
            field: 'state',
            filter: 'textFilter',
            minWidth: 105,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Zip Code',
            headerComponentParams: header_template,
            field: 'zip',
            filter: 'textFilter',
            minWidth: 120,
            width: 210,
            type: 'zip',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Phone',
            headerComponentParams: header_template,
            field: 'phone',
            filter: 'textFilter',
            minWidth: 115,
            width: 210,
            type: 'phone',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'NPI',
            headerComponentParams: header_template,
            field: 'npi',
            filter: 'numberFilter',
            minWidth: 95,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: number_comparator
        },
        {
            headerName: 'State License',
            headerComponentParams: header_template,
            field: 'state_license',
            filter: 'textFilter',
            minWidth: 120,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Medical License',
            headerComponentParams: header_template,
            field: 'medicalaid_license',
            filter: 'textFilter',
            minWidth: 125,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Referring Physician ID',
            headerComponentParams: header_template,
            field: 'referring_physician_id',
            filter: 'numberFilter',
            minWidth: 155,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: number_comparator
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'is_active',
            filter: 'checklistFilter',
            minWidth: 120,
            width: 120,
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true
        }
    ],

    validation_message: {
        error: '',
        save: 'Referring Physician Added.',
        update: 'Referring Physician Updated.',
        warning: ''
    },

    param_validator: [
        {
            expression: '',
            message: ''
        }
    ]
};

export const modifier = {
    id: 'modifier_id',
    search_header: 'Modifiers',
    add_header: 'Modifiers',
    edit_header: 'Modifiers',
    base_url: '/charge',
    create_button_label: 'New',
    show_print_on_search: true,
    show_export_on_search: true,
    entity_type_id: PrintExportConstants.EntityTypeId.Modifiers,
    control_data: [
        // Search
        {
            control_type: 'text',
            label: 'Modifier Code',
            name: 'modifier_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Modifier Description',
            name: 'modifier_desc',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Include Inactive',
            name: 'show_inactive',
            default_value: false,
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            custom_style: { marginTop: 32 },
            view_container_tablet: 7,
            view_container_computer: 3,
            search_container_tablet: 3,
            search_container_computer: 3
        },
        // Edit/Add
        {
            control_type: 'text',
            label: 'Modifier Code',
            name: 'modifier_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Modifier Description',
            name: 'modifier_desc',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Is Active',
            name: 'is_active',
            default_value: true,
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 2,
            view_container_tablet: 7,
            view_container_computer: 3,
            search_container_tablet: 3,
            search_container_computer: 3
        }
    ],

    api: {
        add: {
            type: 'POST',
            url: '/constant/modifier'
        },
        search: {
            type: 'GET',
            url:
                '/constant/modifier/search?modifier_code=modifier_code_value&modifier_desc=modifier_desc_value&show_inactive=show_inactive_value'
        },
        update: {
            type: 'PUT',
            url: '/constant/modifier/id_value'
        },
        get: {
            type: 'GET',
            url: '/constant/modifier/id_value'
        }
    },

    column_def: [
        {
            headerName: 'Modifier Code',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'modifier_code',
            filter: 'textFilter',
            minWidth: 170,
            width: 200,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Modifier Description',
            headerComponentParams: header_template,
            field: 'modifier_desc',
            filter: 'textFilter',
            minWidth: 212,
            width: 700,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Modifier ID',
            headerComponentParams: header_template,
            field: 'modifier_id',
            filter: 'numberFilter',
            minWidth: 150,
            width: 250,
            type: 'string',
            unSortIcon: true,
            comparator: number_comparator
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'is_active',
            filter: 'checklistFilter',
            minWidth: 120,
            width: 150,
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true
        }
    ],

    validation_message: {
        error: '',
        save: 'Modifier Code Added.',
        update: 'Modifier Code Updated.',
        warning: ''
    },

    param_validator: [
        {
            expression: '',
            message: ''
        }
    ]
};

export const contract = {
    id: 'contract_id',
    search_header: 'Contracts',
    add_header: 'Contracts',
    edit_header: 'Contracts',
    base_url: '/admin',
    create_button_label: 'New',
    show_print_on_search: true,
    show_export_on_search: true,
    control_data: [
        // Search
        {
            control_type: 'text',
            label: 'Contract Code',
            name: 'contract_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Contract Description',
            name: 'contract_desc',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Include Inactive',
            name: 'include_inactive',
            default_value: false,
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },

        // View edit
        {
            control_type: 'text',
            label: 'Contract Code',
            name: 'contract_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Contract Description',
            name: 'contract_desc',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Is Active',
            name: 'is_active',
            default_value: true,
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 1,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        }
    ],

    api: {
        add: {
            type: 'POST',
            url: '/constant/contract'
        },
        search: {
            type: 'GET',
            url:
                '/constant/contract/search?contract_code=contract_code_value&contract_desc=contract_desc_value&include_inactive=include_inactive_value'
        },
        update: {
            type: 'PUT',
            url: '/constant/contract/id_value'
        },
        get: {
            type: 'GET',
            url: '/constant/contract/id_value'
        }
    },

    column_def: [
        {
            headerName: 'Contract Code',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'contract_code',
            filter: 'textFilter',
            minWidth: 172,
            width: 200,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Contract Description',
            headerComponentParams: header_template,
            field: 'contract_desc',
            filter: 'textFilter',
            minWidth: 215,
            width: 700,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Contract ID',
            headerComponentParams: header_template,
            field: 'contract_id',
            filter: 'numberFilter',
            minWidth: 148,
            width: 250,
            type: 'string',
            unSortIcon: true,
            comparator: number_comparator
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'is_active',
            filter: 'checklistFilter',
            minWidth: 120,
            width: 150,
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true
        }
    ],

    validation_message: {
        error: '',
        save: 'Contract Code Added.',
        update: 'Contract Code Updated.',
        warning: ''
    },

    param_validator: [
        {
            expression: '',
            message: ''
        }
    ]
};

export const medical_group = {
    id: 'medical_group_id',
    search_header: 'Medical Groups',
    add_header: 'Medical Groups',
    edit_header: 'Medical Groups',
    base_url: '/admin',
    create_button_label: 'New',
    show_print_on_search: true,
    show_export_on_search: true,
    control_data: [
        // Search
        {
            control_type: 'text',
            label: 'Medical Group',
            name: 'medical_group',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 6,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Include Inactive',
            name: 'show_inactive',
            default_value: false,
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 6,
            search_container_tablet: 8,
            search_container_computer: 4
        },

        // View edit
        {
            control_type: 'text',
            label: 'Medical Group',
            name: 'medical_group',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Is Active',
            name: 'is_active',
            default_value: true,
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 1,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 6,
            search_container_tablet: 8,
            search_container_computer: 4
        }
    ],

    api: {
        add: {
            type: 'POST',
            url: '/constant/medical_group'
        },
        search: {
            type: 'GET',
            url:
                '/constant/medical_group/search_medical_group?medical_group=medical_group_value&show_inactive=show_inactive_value'
        },
        update: {
            type: 'PUT',
            url: '/constant/medical_group/id_value'
        },
        get: {
            type: 'GET',
            url: '/constant/medical_group/get_medical_group?medical_group_id=id_value'
        }
    },

    column_def: [
        {
            headerName: 'Medical Group',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'medical_group',
            filter: 'textFilter',
            minWidth: 175,
            width: 700,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Medical Group ID',
            headerComponentParams: header_template,
            field: 'medical_group_id',
            filter: 'numberFilter',
            minWidth: 195,
            width: 250,
            type: 'string',
            unSortIcon: true,
            comparator: number_comparator
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'is_active',
            filter: 'checklistFilter',
            minWidth: 120,
            width: 150,
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true
        }
    ],

    validation_message: {
        error: '',
        save: 'Medical Group Added.',
        update: 'Medical Group Updated.',
        warning: ''
    },

    param_validator: [
        {
            expression: '',
            message: ''
        }
    ]
};

export const provider_qualifier = {
    id: 'provider_qualifier',
    search_header: 'Provider Qualifiers',
    add_header: 'Provider Qualifiers',
    edit_header: 'Provider Qualifiers',
    base_url: '/billing',
    create_button_label: 'New',
    show_print_on_search: true,
    show_export_on_search: true,
    grid_header_height: 55,
    control_data: [
        // Search
        {
            control_type: 'text',
            label: 'Provider Qualifier Code',
            name: 'provider_qualifier_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 10,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Provider Qualifier Description',
            name: 'provider_qualifier_desc',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 250,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Include Inactive',
            name: 'show_inactive',
            default_value: false,
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },

        // View edit
        {
            control_type: 'label',
            label: 'Provider Qualifier Code',
            name: 'provider_qualifier_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            row: 1,
            max_length: 10,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Provider Qualifier Code',
            name: 'provider_qualifier_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_add: true,
            row: 1,
            max_length: 10,
            view_container_tablet: 8,
            view_container_computer: 5,
            search_container_tablet: 8,
            search_container_computer: 5
        },
        {
            control_type: 'text',
            label: 'Provider Qualifier Description',
            name: 'provider_qualifier_desc',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 250,
            view_container_tablet: 8,
            view_container_computer: 5,
            search_container_tablet: 8,
            search_container_computer: 5
        },
        {
            control_type: 'checkbox',
            label: 'Is Active',
            name: 'is_active',
            default_value: true,
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 1,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        }
    ],

    api: {
        add: {
            type: 'POST',
            url: '/constant/provider_qualifier'
        },
        search: {
            type: 'GET',
            url:
                '/constant/provider_qualifier/search?provider_qualifier_code=provider_qualifier_code_value&provider_qualifier_desc=provider_qualifier_desc_value&show_inactive=show_inactive_value&page_size=5000'
        },
        update: {
            type: 'PUT',
            url: '/constant/provider_qualifier/id_value'
        },
        get: {
            type: 'GET',
            url: '/constant/provider_qualifier/id_value'
        }
    },

    column_def: [
        {
            headerName: 'Provider Qualifier Code',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'provider_qualifier_code',
            filter: 'textFilter',
            minWidth: 285,
            width: 200,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Provider Qualifier Description',
            headerComponentParams: header_template,
            field: 'provider_qualifier_desc',
            filter: 'textFilter',
            minWidth: 285,
            width: 700,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Provider Qualifier ID',
            headerComponentParams: header_template,
            field: 'provider_qualifier_id',
            filter: 'numberFilter',
            minWidth: 215,
            width: 250,
            type: 'string',
            unSortIcon: true,
            comparator: number_comparator
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'is_active',
            filter: 'checklistFilter',
            minWidth: 120,
            width: 150,
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true
        }
    ],

    validation_message: {
        error: '',
        save: 'Provider Qualifier Added.',
        update: 'Provider Qualifier Updated.',
        warning: ''
    },

    param_validator: [
        {
            expression: '',
            message: ''
        }
    ]
};

export const practice = {
    id: 'practices_id',
    search_header: 'Practices',
    add_header: 'Practices',
    edit_header: 'Practices',
    base_url: '/admin',
    api: {
        add: {
            type: 'POST',
            url: '/constant/practice'
        },
        search: {
            type: 'GET',
            url:
                '/constant/practice/search?practice_code=practice_code_value&practice_name=practice_name_value&show_inactive=show_inactive_value&page_size=5000'
        },
        update: {
            type: 'PUT',
            url: '/constant/practice/id_value'
        },
        get: {
            type: 'GET',
            url: '/constant/practice/id_value'
        },
        logo: {
            type: 'GET',
            url: '/constant/practice/logo'
        }
    },

    column_def: [
        {
            headerName: 'Practice Code',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'practice_code',
            filter: 'textFilter',
            minWidth: 170,
            width: 170,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: '	Practice Description',
            headerComponentParams: header_template,
            field: 'practice_name',
            filter: 'textFilter',
            minWidth: 215,
            width: 600,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Practice ID',
            headerComponentParams: header_template,
            field: 'practice_id',
            filter: 'numberFilter',
            minWidth: 155,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: number_comparator
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'is_active',
            filter: 'checklistFilter',
            minWidth: 120,
            width: 120,
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true
        }
    ],

    validation_message: {
        error: '',
        save: 'Practice Added.',
        update: 'Practice Updated.',
        warning: ''
    }
};

export const locations = {
    id: 'locations_id',
    search_header: 'Locations',
    add_header: 'Locations',
    edit_header: 'Locations',
    base_url: '/admin',
    grid_header_height: 45,
    api: {
        add: {
            type: 'POST',
            url: '/constant/locations'
        },
        search: {
            type: 'GET',
            url:
                '/constant/locations/search?location_code=location_code_value&location_name=location_name_value&show_inactive=show_inactive_value'
        },
        update: {
            type: 'PUT',
            url: '/constant/locations/id_value'
        },
        get: {
            type: 'GET',
            url: '/constant/locations/id_value'
        },
        logo: {
            type: 'GET',
            url: '/constant/locations/logo'
        },
        practice: {
            type: 'GET',
            url: '/constant/practice/search?practice_code=&practice_name=&show_inactive=false&page_size=5000'
        }
    },

    column_def: [
        {
            headerName: 'Location Code',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'pos_code',
            minWidth: 135,
            width: 170,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: '	Location Description',
            headerComponentParams: header_template,
            field: 'pos_name',
            minWidth: 210,
            width: 600,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Address',
            headerComponentParams: header_template,
            field: 'address1',
            minWidth: 130,
            width: 210,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'City',
            headerComponentParams: header_template,
            field: 'city',
            minWidth: 95,
            width: 210,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'State',
            headerComponentParams: header_template,
            field: 'state',
            minWidth: 110,
            width: 210,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Zip',
            headerComponentParams: header_template,
            field: 'zip',
            filter: 'textFilter',
            minWidth: 90,
            width: 210,
            type: 'zip',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Phone',
            headerComponentParams: header_template,
            field: 'phone',
            filter: 'textFilter',
            minWidth: 110,
            width: 210,
            type: 'phone',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'NPI',
            headerComponentParams: header_template,
            field: 'npi',
            filter: 'numberFilter',
            minWidth: 95,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: number_comparator
        },
        {
            headerName: 'TIN',
            headerComponentParams: header_template,
            field: 'tax_id',
            filter: 'numberFilter',
            minWidth: 95,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: number_comparator
        },
        {
            headerName: 'Practice',
            headerComponentParams: header_template,
            field: 'practice_name',
            filter: 'textFilter',
            minWidth: 130,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Apply Tax',
            headerComponentParams: headerStatusTemplate,
            field: 'apply_tax',
            filter: 'checklistFilter',
            minWidth: 140,
            suppressSizeToFit: true,
            cellStyle: { textAlign: 'center' },
            type: 'boolean',
            unSortIcon: true,
            cellRenderer: readonly_checkbox_cell_renderer
        },
        {
            headerName: 'Supply Only Charge Entry',
            headerComponentParams: headerStatusTemplate,
            field: 'supply_only_charge_entry',
            filter: 'checklistFilter',
            minWidth: 140,
            cellStyle: { textAlign: 'center' },
            type: 'boolean',
            unSortIcon: true,
            cellRenderer: readonly_checkbox_cell_renderer
        },
        {
            headerName: 'Location ID',
            headerComponentParams: header_template,
            field: 'place_of_service_id',
            filter: 'numberFilter',
            minWidth: 130,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: number_comparator
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'is_active',
            filter: 'checklistFilter',
            minWidth: 120,
            width: 120,
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true
        },
        {
            headerName: 'Claim Holds',
            headerComponentParams: headerStatusTemplate,
            field: 'claim_holds',
            filter: 'textFilter',
            minWidth: 120,
            width: 120,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        }
    ],

    validation_message: {
        error: '',
        save: 'Location Added.',
        update: 'Location Updated.',
        warning: ''
    },

    // Launch Darkly feature flip gridview columns to show/hide.
    // TODO: Purge B-28286 FF
    column_def_ff_columns: ['apply_tax', 'supply_only_charge_entry'],
};

export const claim_adjust_code_header_id = 'claim_adjust_code_header_id';
export const claim_adjust_code = {
    grid_header_height: 60,
    api: {
        claim_adj_quick_search: '/constant/eob_claim_adj_code/quick_serch_eob_claim_code',
        claim_adjcode_advanced_search: '/constant/eob_claim_adj_code/get_eob_claim_code'
    },
    column_def: [
        {
            headerName: 'Code',
            headerComponentParams: get_header_template_with_navigation(claim_adjust_code_header_id),
            field: 'carc',
            filter: 'textFilter',
            minWidth: 100,
            width: 100,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Description',
            headerComponentParams: header_template,
            field: 'description',
            filter: 'textFilter',
            minWidth: 150,
            width: 150,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Is Excluded',
            headerComponentParams: header_template,
            field: 'text_is_excluded',
            filter: 'checklistFilter',
            minWidth: 140,
            suppressSizeToFit: true,
            cellStyle: { textAlign: 'center' },
            cellRenderer: params => {
                var eDiv = document.createElement('div');
                eDiv.innerHTML = `<input type='checkbox' ${params.value === 'Is Excluded' ? 'checked' : ''} disabled/>`;
                return eDiv;
            },
            type: 'string',
            unSortIcon: true
        }
    ]
};

export const claim_adjust_code_company_grid_header_id = 'claim_adjust_code_company_grid_header_id';
export const claim_adjust_code_company = {
    grid_header_height: 60,
    api: {
        claim_adjcode_comp_search: '/constant/claim_adj_code_comp_payer_ov/search',
        claim_adj_quick_search: '/constant/eob_claim_adj_code/quick_serch_eob_claim_code',
        claim_adjcode_advanced_search: '/constant/eob_claim_adj_code/get_eob_claim_code',
        claim_adjust_code_base_url: '/constant/claim_adj_code_comp_payer_ov'
    },
    column_def: [
        {
            headerName: 'Claim Adjustment Reason Code',
            headerComponentParams: get_header_template_with_navigation(claim_adjust_code_company_grid_header_id),
            field: 'adj_code_desc',
            filter: 'textFilter',
            minWidth: 320,
            width: 1200,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Payer',
            headerComponentParams: header_template,
            field: 'payer',
            filter: 'textFilter',
            minWidth: 120,
            width: 400,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Is Excluded',
            headerComponentParams: headerStatusTemplate,
            field: 'text_is_excluded',
            filter: 'checklistFilter',
            width: 155,
            minWidth: 155,
            // suppressSizeToFit: true,
            cellStyle: { textAlign: 'center' },
            cellRenderer: params => {
                var eDiv = document.createElement('div');
                eDiv.innerHTML = `<input type='checkbox' ${
                    params.value === 'Is Excluded' ? 'checked' : 'unchecked'
                    } disabled/>`;
                return eDiv;
            },
            type: 'string',
            unSortIcon: true
        },
        {
            headerName: 'ID',
            headerComponentParams: header_template,
            field: 'id',
            filter: 'numberFilter',
            comparator: number_comparator,
            width: 95,
            minWidth: 95,
            // suppressSizeToFit: true,
            unSortIcon: true
        },
        {
            headerName: 'Status',
            headerClass: 'col-group-gray',
            headerComponentParams: headerStatusTemplate,
            field: 'is_active',
            filter: 'checklistFilter',
            width: 125,
            minWidth: 125,
            // suppressSizeToFit: true,
            cellStyle: statusStyle,
            unSortIcon: true
        }
    ],
    page_size: 20
};

export const pay_ov_grid_header_id = 'pay_ov_grid_header_id';
export const eob_claim_adjcode_search_grid_header_id = 'eob_claim_adjcode_search_grid_header_id';

export const eob_claim_adjcode_search = {
    grid_header_height: 60,
    api: {
        add: {
            type: 'POST',
            url: '/constant/claim_adj_reason_code_payer_ov/add'
        },
        update: {
            type: 'PUT',
            url: '/constant/claim_adj_reason_code_payer_ov/update'
        },
        get: {
            type: 'GET',
            url: '/constant/claim_adj_reason_code_payer_ov/search'
        },
        eob_claim_adjcode_search: '/constant/eob_claim_adj_code/get_eob_claim_code',
        claim_adj_quick_search: '/constant/eob_claim_adj_code/quick_serch_eob_claim_code'
    },
    column_defs: [
        {
            headerComponentParams: get_header_template_with_navigation(eob_claim_adjcode_search_grid_header_id),
            headerName: 'Code',
            field: 'carc',
            filter: 'textFilter',
            minWidth: 100,
            width: 100,
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Description',
            headerComponentParams: header_template,
            field: 'description',
            filter: 'textFilter',
            minWidth: 150,
            width: 150,
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Is Excluded',
            field: 'is_text_excluded',
            filter: 'checklistFilter',
            minWidth: 140,
            suppressSizeToFit: true,
            cellStyle: { textAlign: 'center' },
            cellRenderer: params => {
                var eDiv = document.createElement('div');
                eDiv.innerHTML = `<input type='checkbox'${params.value === 'True' ? 'checked' : 'unchecked'} disabled/>`;
                return eDiv;
            },
            unSortIcon: true
        }
    ],
    pay_ov_column_def: [
        {
            headerName: 'Claim Adjustment Reason Code',
            headerComponentParams: get_header_template_with_navigation(pay_ov_grid_header_id),
            field: 'adj_code_desc',
            filter: 'textFilter',
            minWidth: 320,
            width: 1200,
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Payer',
            headerComponentParams: header_template,
            field: 'lk10097_Desc',
            filter: 'textFilter',
            minWidth: 120,
            width: 400,
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Is Excluded',
            headerComponentParams: headerStatusTemplate,
            field: 'is_def_excl',
            filter: 'checklistFilter',
            width: 155,
            minWidth: 155,
            // suppressSizeToFit: true,
            cellStyle: { textAlign: 'center' },
            cellRenderer: params => {
                var eDiv = document.createElement('div');
                eDiv.innerHTML = `<input type='checkbox' ${params.value === 'True' ? 'checked' : 'unchecked'} disabled/>`;
                return eDiv;
            },
            unSortIcon: true
        },
        {
            headerName: 'ID',
            headerComponentParams: header_template,
            field: 'id',
            filter: 'numberFilter',
            comparator: number_comparator,
            width: 95,
            minWidth: 95,
            // suppressSizeToFit: true,
            unSortIcon: true
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'is_active',
            filter: 'checklistFilter',
            width: 125,
            minWidth: 125,
            // suppressSizeToFit: true,
            cellStyle: statusStyle,
            unSortIcon: true
        }
    ],
    page_size: 5000
};

export const claim_payer_search_header_id = 'claim_payer_search_header_id';
export const claim_payer_search = {
    grid_header_height: 60,
    api: {
        payer_quick_search: '/insurance_payer/quick_search',
        payer_advanced_search: '/insurance_payer/search'
    },
    column_defs: [
        {
            headerName: 'Name',
            field: 'name',
            filter: 'textFilter',
            minWidth: 120,
            unSortIcon: true,
            headerComponentParams: get_header_template_with_navigation(claim_payer_search_header_id),
            comparator: text_comparator
        },
        {
            headerName: 'Description',
            field: 'description',
            filter: 'textFilter',
            minWidth: 355,
            unSortIcon: true,
            headerComponentParams: header_template,
            comparator: text_comparator
        },
        {
            headerName: 'Outbound ID',
            field: 'outbound_id',
            filter: 'textFilter',
            minWidth: 180,
            unSortIcon: true,
            headerComponentParams: header_template,
            comparator: text_comparator
        },
        {
            headerName: 'Inbound ID',
            field: 'inbound_id',
            filter: 'textFilter',
            minWidth: 160,
            unSortIcon: true,
            headerComponentParams: header_template,
            comparator: text_comparator
        }
    ],
    page_size: 5000
};

export const payer_alert_search_grid_id = 'payer_alert_search_grid_id';
export const payer_alert_search_insurance_code_id = 'payer_alert_search_insurance_code_id';
export const payer_alert_search_insurance_class_id = 'payer_alert_search_insurance_class_id';

export const payer_alert_search = {
    grid_header_height: 45,
    api: {
        payer_alert_search: '/constant/payer_alerts/search',
        payer_alert: '/constant/payer_alerts'
    },
    report_title: 'Payer Alerts',
    report_name: 'Payer Alerts',
    column_def: [
        {
            headerName: 'Insurance Class',
            headerComponentParams: get_header_template_with_navigation(payer_alert_search_grid_id),
            field: 'insurance_class_desc',
            filter: 'textFilter',
            minWidth: 195,
            width: 195,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Insurance Code',
            headerComponentParams: header_template,
            field: 'insurance_code_desc',
            filter: 'textFilter',
            minWidth: 265,
            width: 265,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Alert Message',
            headerComponentParams: header_template,
            field: 'message',
            filter: 'textFilter',
            minWidth: 182,
            width: 182,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Status',
            headerClass: 'col-group-gray',
            headerComponentParams: header_template,
            field: 'status',
            filter: 'checklistFilter',
            width: 125,
            minWidth: 125,
            suppressSizeToFit: true,
            cellStyle: statusStyle,
            unSortIcon: true
        }
    ],
    page_size: 20
};

export const search_insurance_configuration = {
    column_defs: [
        {
            headerName: 'Ins Code',
            field: 'insurance_code',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 130,
            headerComponentParams: get_header_template_with_navigation(payer_alert_search_insurance_code_id),
            unSortIcon: true
        },
        {
            headerName: 'Insurance Name',
            field: 'insurance_name',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 195,
            headerComponentParams: header_template,
            unSortIcon: true
        },
        {
            headerName: 'Address 1',
            field: 'address1',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 155,
            headerComponentParams: header_template,
            unSortIcon: true
        },
        {
            headerName: 'City',
            field: 'city',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 100,
            headerComponentParams: header_template,
            unSortIcon: true
        },
        {
            headerName: 'Phone',
            field: 'phone_customer',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 120,
            headerComponentParams: header_template,
            unSortIcon: true
        },
        {
            headerName: 'Insurance Class',
            field: 'insurance_class',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 190,
            headerComponentParams: header_template,
            unSortIcon: true
        }
    ]
};

export const search_class_configuration = {
    column_defs: [
        {
            headerName: 'Ins Class Code',
            field: 'insurance_class_code',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 175,
            unSortIcon: true,
            headerComponentParams: get_header_template_with_navigation(payer_alert_search_insurance_class_id)
        },
        {
            headerName: 'Ins Class Desc',
            field: 'insurance_class_description',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 175,
            unSortIcon: true,
            headerComponentParams: header_template
        }
    ]
};

export const referral_type = {
    id: 'referral_type_id',
    search_header: 'Referral Types',
    add_header: 'Referral Types',
    edit_header: 'Referral Types',
    base_url: '/admin',
    create_button_label: 'New',
    show_print_on_search: true,
    show_export_on_search: true,
    control_data: [
        // Search
        {
            control_type: 'text',
            label: 'Referral Type Code',
            name: 'referral_type_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 5,
            search_container_tablet: 8,
            search_container_computer: 5
        },
        {
            control_type: 'text',
            label: 'Referral Type Description',
            name: 'referral_type_desc',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 6,
            search_container_tablet: 8,
            search_container_computer: 6
        },
        {
            control_type: 'checkbox',
            label: 'Include Inactive',
            name: 'show_inactive',
            default_value: false,
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 5,
            search_container_tablet: 8,
            search_container_computer: 5
        },

        // View edit
        {
            control_type: 'text',
            label: 'Referral Type Code',
            name: 'referral_type_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 5,
            search_container_tablet: 8,
            search_container_computer: 5
        },
        {
            control_type: 'text',
            label: 'Referral Type Description',
            name: 'referral_type_desc',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 6,
            search_container_tablet: 8,
            search_container_computer: 6
        },
        {
            control_type: 'checkbox',
            label: 'Is Active',
            name: 'is_active',
            default_value: true,
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 1,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 5,
            search_container_tablet: 8,
            search_container_computer: 5
        }
    ],

    api: {
        add: {
            type: 'POST',
            url: '/constant/referral_type'
        },
        search: {
            type: 'GET',
            url:
                '/constant/referral_type/search?referral_type_code=referral_type_code_value&referral_type_desc=referral_type_desc_value&show_inactive=show_inactive_value'
        },
        update: {
            type: 'PUT',
            url: '/constant/referral_type/id_value'
        },
        get: {
            type: 'GET',
            url: '/constant/referral_type/id_value'
        }
    },

    column_def: [
        {
            headerName: 'Referral Type Code',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'referral_type_code',
            filter: 'textFilter',
            minWidth: 210,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Referral Type Description',
            headerComponentParams: header_template,
            field: 'referral_type_desc',
            filter: 'textFilter',
            minWidth: 250,
            width: 500,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Referral Type ID',
            headerComponentParams: header_template,
            field: 'referral_type_id',
            filter: 'numberFilter',
            minWidth: 190,
            width: 220,
            type: 'string',
            unSortIcon: true,
            comparator: number_comparator
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'is_active',
            filter: 'checklistFilter',
            minWidth: 120,
            width: 120,
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true
        }
    ],

    validation_message: {
        error: '',
        save: 'Referral Type Added.',
        update: 'Referral Type Updated.',
        warning: ''
    },

    param_validator: [
        {
            expression: '',
            message: ''
        }
    ]
};

export const diagnosis_code = {
    id: 'diagnosis_code_id',
    search_header: 'Diagnoses',
    add_header: 'Diagnoses',
    edit_header: 'Diagnoses',
    base_url: '/admin',
    create_button_label: 'New',
    show_print_on_search: true,
    show_export_on_search: true,
    control_data: [
        // Search
        {
            control_type: 'text',
            label: 'Diagnosis Code',
            name: 'diagnosis_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Diagnosis Description',
            name: 'diagnosis_desc',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 255,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'drop_down_list',
            label: 'ICD Type',
            name: 'icd_type',
            default_value: 0,
            master_data: [{ value: 0, text: 'ICD-10', selected: true }, { value: 1, text: 'ICD-9', selected: false }], // In case of dropdown
            is_placeholder: true,
            is_search: true,
            row: 1,
            max_length: 4,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Include Inactive',
            name: 'show_inactive',
            default_value: false,
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            custom_style: { marginTop: 0, paddingTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },

        // View edit
        {
            control_type: 'text',
            label: 'Diagnosis Code',
            name: 'dx_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_add: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 5,
            search_container_tablet: 8,
            search_container_computer: 5
        },
        {
            control_type: 'label',
            label: 'Diagnosis Code',
            name: 'dx_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_edit: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 5,
            search_container_tablet: 8,
            search_container_computer: 5
        },
        {
            control_type: 'text',
            label: 'Diagnosis Description',
            name: 'dx_Desc',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 5,
            search_container_tablet: 8,
            search_container_computer: 5
        },
        {
            control_type: 'drop_down_list',
            label: 'ICD Type',
            name: 'icd_type',
            default_value: 0,
            master_data: [{ value: 0, text: 'ICD-10', selected: true }, { value: 1, text: 'ICD-9', selected: false }], // In case of dropdown
            is_placeholder: true,
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 4,
            view_container_tablet: 8,
            view_container_computer: 3,
            search_container_tablet: 8,
            search_container_computer: 3
        },
        {
            control_type: 'checkbox',
            label: 'Is Active',
            name: 'is_active',
            default_value: true,
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 1,
            custom_style: { marginTop: 0, paddingTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 3,
            search_container_tablet: 8,
            search_container_computer: 3
        }
    ],

    api: {
        add: {
            type: 'POST',
            url: '/constant/diagnosis_code'
        },
        search: {
            type: 'GET',
            url:
                '/constant/diagnosis_code/search?diagnosis_code=diagnosis_code_value&diagnosis_desc=diagnosis_desc_value&icd_type=icd_type_value&show_inactive=show_inactive_value&page_Size=5000'
        },
        update: {
            type: 'PUT',
            url: '/constant/diagnosis_code/id_value'
        },
        get: {
            type: 'GET',
            url: '/constant/diagnosis_code/id_value'
        }
    },

    column_def: [
        {
            headerName: 'Diagnosis Code',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'dx_code',
            filter: 'textFilter',
            minWidth: 185,
            width: 185,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Diagnosis Description',
            headerComponentParams: header_template,
            field: 'dx_Desc',
            filter: 'textFilter',
            minWidth: 225,
            width: 500,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Diagnosis ID',
            headerComponentParams: header_template,
            field: 'diagnosis_code_id',
            filter: 'numberFilter',
            minWidth: 160,
            width: 200,
            type: 'string',
            unSortIcon: true,
            comparator: number_comparator
        },
        {
            headerName: 'ICD Type',
            headerComponentParams: header_template,
            field: 'icd_type',
            filter: 'textFilter',
            minWidth: 172,
            width: 200,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'is_active',
            filter: 'checklistFilter',
            minWidth: 120,
            width: 120,
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true
        }
    ],

    validation_message: {
        error: '',
        save: 'Diagnosis Added.',
        update: 'Diagnosis Updated.',
        warning: ''
    },

    param_validator: [
        {
            expression: '',
            message: ''
        }
    ]
};

export const referral_source = {
    id: 'referral_source_id',
    search_header: 'Referral Sources',
    add_header: 'Referral Sources',
    edit_header: 'Referral Sources',
    base_url: '/admin',
    create_button_label: 'New',
    show_print_on_search: true,
    show_export_on_search: true,
    lookup_search_list: ['type_id'],
    is_lookup_search: true,
    grid_header_height: 55,
    control_data: [
        // Search
        {
            control_type: 'text',
            label: 'Referral Source Description',
            name: 'source_description',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 500,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'lookup_search',
            lookup_id: 'ADMIN_SEARCH_REFERRAL_SOURCE_TYPE',
            label: 'Referral Source Type ID',
            name: 'ref_source_type_id',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            advance_search: '/constant/referral_source/referral_types',
            quick_search: '/constant/referral_source/referral_types/lookup',
            search_criteria_field: 'id',
            advance_search_field: { id: 'id', label: 'description', title: 'code' },
            label_list: ['code', 'description'],
            suggestion_label_list: ['code', 'description']
        },
        {
            control_type: 'checkbox',
            label: 'Include Inactive',
            name: 'show_inactive',
            default_value: false,
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        // Add/Edit
        {
            control_type: 'lookup_search',
            lookup_id: 'ADMIN_SEARCH_REFERRAL_SOURCE_TYPE',
            label: 'Referral Source Type ID',
            name: 'referral_source_type_id',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            advance_search: '/constant/referral_source/referral_types',
            quick_search: '/constant/referral_source/referral_types/lookup',
            advance_search_lookup:
                '/constant/referral_source/get_referring_source_type?page_size=5000&referring_source_type_id=',
            search_criteria_field: 'id',
            advance_search_field: { id: 'id', label: 'description', title: 'code' },
            label_list: ['code', 'description'],
            suggestion_label_list: ['code', 'description'],
            get_quick_search_list: ['source_type_code', 'source_type_desc']
        },
        {
            control_type: 'text',
            label: 'Source Description',
            name: 'source_desc',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 500,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Address 1',
            name: 'address1',
            default_value: '',
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Address 2',
            name: 'address2',
            default_value: '',
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'City',
            name: 'city',
            default_value: '',
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 2,
            max_length: 50,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 4
        },
        {
            control_type: 'drop_down_list',
            label: 'State',
            name: 'state',
            default_value: '',
            is_add: true,
            is_edit: true,
            row: 2,
            max_length: 2,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 4
        },
        {
            control_type: 'zip',
            label: 'Zip',
            name: 'zip',
            default_value: '',
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 2,
            max_length: 10,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 4,
            is_required_text: true,
            is_required_msg: 'Zip can be 5 or 9 digits.',
            pattern: '[0-9]*'
        },
        {
            control_type: 'phone',
            label: 'Phone',
            name: 'phone',
            default_value: '',
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 2,
            max_length: 10,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 4,
            is_required_text: true,
            is_required_msg: 'Please enter 10 digits',
            pattern: '[0-9]*'
        },
        {
            control_type: 'phone',
            label: 'Fax',
            name: 'fax',
            default_value: '',
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 3,
            max_length: 10,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 4,
            is_required_text: true,
            is_required_msg: 'Please enter 10 digits',
            pattern: '[0-9]*'
        },
        {
            control_type: 'text',
            label: 'Email',
            name: 'email',
            default_value: '',
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 3,
            max_length: 50,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 4,
            is_required_text: true,
            is_required_msg: 'Please enter valid email address.'
        },
        {
            control_type: 'text',
            label: 'Website',
            name: 'website',
            default_value: '',
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 3,
            max_length: 50,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Is Active',
            name: 'is_active',
            default_value: true,
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 4,
            custom_style: {},
            view_container_tablet: 6,
            view_container_computer: 4,
            search_container_tablet: 6,
            search_container_computer: 4
        }
    ],

    api: {
        add: {
            type: 'POST',
            url: '/constant/referral_source/add'
        },
        search: {
            type: 'GET',
            url:
                '/constant/referral_source/search_ref_source?source_description=source_description_value&ref_source_type_id=ref_source_type_id_value&show_inactive=show_inactive_value'
        },
        update: {
            type: 'PUT',
            url: '/constant/referral_source/id_value'
        },
        get: {
            type: 'GET',
            url: '/constant/referral_source/get_referring_source?referring_source_id=id_value'
        }
    },

    column_def: [
        {
            headerName: 'Source Description',
            headerComponentParams: get_header_template_with_navigation(search_grid_id),
            field: 'source_desc',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Referral Source Type ID',
            headerComponentParams: header_template,
            field: 'source_type_desc',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Address',
            headerComponentParams: header_template,
            field: 'address1',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'City',
            headerClass: 'col-group-gray',
            headerComponentParams: header_template,
            field: 'city',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator,
        },
        {
            headerName: 'State',
            headerClass: 'col-group-gray',
            headerComponentParams: header_template,
            field: 'state',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator,
        },
        {
            headerName: 'Zip',
            headerClass: 'col-group-gray',
            headerComponentParams: header_template,
            field: 'zip',
            filter: 'textFilter',
            type: 'zip',
            unSortIcon: true,
            comparator: text_comparator,
        },
        {
            headerName: 'Phone',
            headerClass: 'col-group-gray',
            headerComponentParams: header_template,
            field: 'phone',
            filter: 'textFilter',
            type: 'phone',
            unSortIcon: true,
            comparator: text_comparator,
        },
        {
            headerName: 'Fax',
            headerClass: 'col-group-gray',
            headerComponentParams: header_template,
            field: 'fax',
            filter: 'textFilter',
            type: 'phone',
            unSortIcon: true,
            comparator: text_comparator,
        },
        {
            headerName: 'Email',
            headerClass: 'col-group-gray',
            headerComponentParams: header_template,
            field: 'email',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator,
        },
        {
            headerName: 'Website',
            headerClass: 'col-group-gray',
            headerComponentParams: header_template,
            field: 'website',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'is_active',
            filter: 'checklistFilter',
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true
        }
    ],

    validation_message: {
        error: '',
        save: 'Referral Source Added.',
        update: 'Referral Source Updated.',
        warning: ''
    },

    param_validator: [
        {
            expression: '',
            message: ''
        }
    ]
};

export const ADMIN_SEARCH_REFERRAL_SOURCE_TYPE = {
    column_def: [
        {
            headerName: 'Source Type Code',
            field: 'code',
            filter: 'textFilter',
            comparator: text_comparator,
            headerComponentParams: HEADER_TEMPLATE_FIRST_COLUMN,
            unSortIcon: true,
            minWidth: 175
        },
        {
            headerName: 'Source Type Description',
            unSortIcon: true,
            headerComponentParams: header_template,
            field: 'description',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 175
        }
    ]
};

export const insurance = {
    id: 'insurance_id',
    search_header: 'Insurances',
    add_header: 'Insurances',
    edit_header: 'Insurances',
    base_url: '/admin',
    insurance_classe_header_id: 'insurance_classe_header_id',
    payerid_header_id: 'payerid_header_id',
    grid_header_height: 45,
    api: {
        add: {
            type: 'POST',
            url: '/constant/insurance/add_ins_code'
        },
        search: {
            type: 'GET',
            url:
                '/constant/insurance/search?Insurance_code=Insurance_code_value&Insurance_name=Insurance_name_value&Address=Address_value&Insurance_class_id=Insurance_class_id_value&Payer_id=Payer_id_value&Show_inactive=Show_inactive_value&Page_size=5000'
        },
        update: {
            type: 'PUT',
            url: '/constant/insurance/id_value'
        },
        get: {
            type: 'GET',
            url: '/constant/insurance/details/id_value'
        },
        payer_quick_search: '/insurance_payer/quick_search',
        payer_advanced_search: '/insurance_payer/search',
        insurance_class_quick_search: '/insurance_class/quick_search',
        insurance_class_advanced_search: '/insurance_class'
    },

    column_def: [
        {
            headerName: 'Insurance Code',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'insurance_code',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            suppressSizeToFit: true,
            comparator: text_comparator
        },
        {
            headerName: 'Insurance Name',
            headerComponentParams: header_template,
            field: 'insurance_name',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            suppressSizeToFit: true,
            comparator: text_comparator
        },
        {
            headerName: 'Insurance Class',
            headerComponentParams: header_template,
            field: 'insurance_class',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            suppressSizeToFit: true,
            comparator: text_comparator,
        },
        {
            headerName: 'Insurance Type',
            headerComponentParams: header_template,
            field: 'insurance_type',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            suppressSizeToFit: true,
            comparator: text_comparator
        },
        {
            headerName: 'Payer Name',
            headerComponentParams: header_template,
            field: 'payer_name',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            suppressSizeToFit: true,
            comparator: text_comparator
        },
        {
            headerName: 'Payer Description',
            headerComponentParams: header_template,
            field: 'payer_desc',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            suppressSizeToFit: true,
            comparator: text_comparator
        },
        {
            headerName: 'Address',
            headerComponentParams: header_template,
            field: 'addresses',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            suppressSizeToFit: true,
            comparator: text_comparator
        },
        {
            headerName: 'City',
            headerComponentParams: header_template,
            field: 'city',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            suppressSizeToFit: true,
            comparator: text_comparator
        },
        {
            headerName: 'State',
            headerComponentParams: header_template,
            field: 'province',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            suppressSizeToFit: true,
            comparator: text_comparator
        },
        {
            headerName: 'Zip Code',
            headerComponentParams: header_template,
            field: 'zip_code',
            filter: 'textFilter',
            type: 'zip',
            unSortIcon: true,
            suppressSizeToFit: true,
            comparator: text_comparator
        },
        {
            headerName: 'Phone',
            headerComponentParams: header_template,
            field: 'phone_number',
            filter: 'textFilter',
            type: 'phone',
            unSortIcon: true,
            suppressSizeToFit: true,
            comparator: text_comparator
        },
        {
            headerName: 'Contact Name',
            headerComponentParams: header_template,
            field: 'contract_name',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            suppressSizeToFit: true,
            comparator: text_comparator
        },
        {
            headerName: 'Authorization Required',
            headerComponentParams: header_template,
            field: 'authorization_required',
            filter: 'checklistFilter',
            type: 'string',
            unSortIcon: true,
            cellRenderer: readonly_checkbox_cell_renderer,
            suppressSizeToFit: false,
            cellStyle: { textAlign: 'center' }
        },
        {
            headerName: 'Injury Date Required',
            headerComponentParams: header_template,
            field: 'injury_date_required_flag',
            filter: 'checklistFilter',
            type: 'string',
            unSortIcon: true,
            cellRenderer: readonly_checkbox_cell_renderer,
            suppressSizeToFit: false,
            cellStyle: { textAlign: 'center' },
            hide: false
        },
        {
            headerName: 'Tax ID Number',
            headerComponentParams: header_template,
            field: 'tax_identification_number',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            suppressSizeToFit: true,
            comparator: text_comparator,
            hide: false
        },
        {
            headerName: 'Taxonomy Code',
            headerComponentParams: header_template,
            field: 'taxonomy_code',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            suppressSizeToFit: true,
            comparator: text_comparator,
            hide: false
        },
        {
            headerName: 'Apply Specialty Modifiers',
            headerComponentParams: header_template,
            field: 'apply_specialty_modifiers',
            filter: 'checklistFilter',
            type: 'string',
            unSortIcon: true,
            cellRenderer: readonly_checkbox_cell_renderer,
            suppressSizeToFit: false,
            cellStyle: { textAlign: 'center' },
            hide: false
        },
        {
            headerName: 'Do Not Accept Assignment',
            headerComponentParams: header_template,
            field: 'do_not_accept_assignment',
            filter: 'checklistFilter',
            type: 'string',
            unSortIcon: true,
            cellRenderer: readonly_checkbox_cell_renderer,
            suppressSizeToFit: false,
            cellStyle: { textAlign: 'center' },
            hide: false
        },
        {
            headerName: 'Insurance ID',
            headerComponentParams: header_template,
            field: 'insurance_id',
            filter: 'numberFilter',
            type: 'string',
            unSortIcon: true,
            suppressSizeToFit: true,
            comparator: number_comparator
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'is_active',
            filter: 'checklistFilter',
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true,
            suppressSizeToFit: false
        }
    ],

    //these contain 'field' from above defined column_def.
    column_def_ff_columns: ['injury_date_required_flag', 'tax_identification_number', 'taxonomy_code', 'apply_specialty_modifiers'],

    column_do_not_accept_assignment_def_ff_columns: ['do_not_accept_assignment'],


    payerid_column_defs: [
        {
            headerName: 'Name',
            field: 'name',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 175,
            unSortIcon: true,
            headerComponentParams: get_header_template_with_navigation('payerid_header_id')
        },
        {
            headerName: 'Description',
            field: 'description',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 175,
            unSortIcon: true,
            headerComponentParams: header_template
        },
        {
            headerName: 'Outbound ID',
            field: 'outbound_id',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 175,
            unSortIcon: true,
            headerComponentParams: header_template
        },
        {
            headerName: 'Inbound ID',
            field: 'inbound_id',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 175,
            unSortIcon: true,
            headerComponentParams: header_template
        }
    ],
    insurance_class_column_defs: [
        {
            headerName: 'Ins Class Code',
            field: 'insurance_class_code',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 175,
            unSortIcon: true,
            headerComponentParams: get_header_template_with_navigation('insurance_classe_header_id')
        },
        {
            headerName: 'Ins Class Desc',
            field: 'insurance_class_description',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 175,
            unSortIcon: true,
            headerComponentParams: header_template
        }
    ],

    messages: {
        error: '',
        save: 'Insurance Added.',
        update: 'Insurance Updated.',
        warning: ''
    }
};

//Provider constants
export const admin_provider = {
    id: 'provider_id',
    search_header: 'Providers',
    add_header: 'Providers',
    edit_header: 'Providers',
    base_url: '/admin',
    route: 'admin',
    new_provider_button_enabled: true,
    billing_enabled: true,
    provider_enabled: true,
    claims_enabled: true,
    storage_key: 'provider_admin_search_criteria',
    grid_header_height: 45,
    api: {
        add: {
            type: 'POST',
            url: '/constant/providers'
        },
        search: {
            type: 'GET',
            url:
                '/constant/providers/search?provider_code=provider_code_value&first_name=first_name_value&last_name=last_name_value&show_inactive=show_inactive_value&page_Size=5000'
        },
        update: {
            type: 'PUT',
            url: '/constant/providers/id_value'
        },
        get: {
            type: 'GET',
            url: '/constant/providers/id_value'
        }
    },

    column_def: [
        {
            headerName: 'Provider Code',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'provider_code',
            minWidth: 135,
            width: 170,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Last Name',
            headerComponentParams: header_template,
            field: 'last_name',
            minWidth: 160,
            width: 210,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'First Name',
            headerComponentParams: header_template,
            field: 'first_name',
            minWidth: 160,
            width: 210,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Description',
            headerComponentParams: header_template,
            field: 'description',
            minWidth: 160,
            width: 210,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Provider Type',
            headerComponentParams: header_template,
            field: 'provider_type',
            minWidth: 170,
            width: 210,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Primary Specialty',
            headerComponentParams: header_template,
            field: 'primary_specialty',
            filter: 'textFilter',
            minWidth: 195,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'National Provider Identifier',
            headerComponentParams: header_template,
            field: 'national_provider_identifier',
            filter: 'textFilter',
            minWidth: 195,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'State License Number',
            headerComponentParams: header_template,
            field: 'state_license_number',
            filter: 'textFilter',
            minWidth: 165,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Provider ID',
            headerComponentParams: header_template,
            field: 'provider_id',
            filter: 'numberFilter',
            minWidth: 150,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: number_comparator
        },
        {
            headerName: 'Alt Record ID',
            headerComponentParams: header_template,
            field: 'alt_record_id',
            filter: 'numberFilter',
            minWidth: 150,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'status',
            filter: 'checklistFilter',
            minWidth: 120,
            width: 120,
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true
        },
        {
            headerName: 'Claim Holds',
            headerComponentParams: headerStatusTemplate,
            field: 'claim_holds',
            filter: 'textFilter',
            minWidth: 120,
            width: 120,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        }
    ],

    validation_message: {
        error: '',
        save: 'Provider Added.',
        update: 'Provider Updated.',
        warning: ''
    }
};

//Provider claim holds constants
export const billing_provider = {
    id: 'provider_id',
    search_header: 'Provider Claim Holds',
    add_header: 'Provider Claim Holds',
    edit_header: 'Provider Claim Holds',
    base_url: '/billing',
    route: 'billing',
    new_provider_button_enabled: false,
    billing_enabled: false,
    provider_enabled: false,
    claims_enabled: true,
    storage_key: 'provider_billing_search_criteria',
    grid_header_height: 45,
    api: {
        add: {
            type: 'POST',
            url: '/constant/providers'
        },
        search: {
            type: 'GET',
            url:
                '/constant/providers/search?provider_code=provider_code_value&first_name=first_name_value&last_name=last_name_value&show_inactive=show_inactive_value&page_Size=5000'
        },
        update: {
            type: 'PUT',
            url: '/constant/providers/id_value'
        },
        get: {
            type: 'GET',
            url: '/constant/providers/id_value'
        }
    },

    column_def: [
        {
            headerName: 'Provider Code',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'provider_code',
            minWidth: 135,
            width: 170,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Last Name',
            headerComponentParams: header_template,
            field: 'last_name',
            minWidth: 160,
            width: 210,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'First Name',
            headerComponentParams: header_template,
            field: 'first_name',
            minWidth: 160,
            width: 210,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Description',
            headerComponentParams: header_template,
            field: 'description',
            minWidth: 160,
            width: 210,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Provider Type',
            headerComponentParams: header_template,
            field: 'provider_type',
            minWidth: 170,
            width: 210,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Primary Specialty',
            headerComponentParams: header_template,
            field: 'primary_specialty',
            filter: 'textFilter',
            minWidth: 195,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'National Provider Identifier',
            headerComponentParams: header_template,
            field: 'national_provider_identifier',
            filter: 'textFilter',
            minWidth: 195,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'State License Number',
            headerComponentParams: header_template,
            field: 'state_license_number',
            filter: 'textFilter',
            minWidth: 165,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Provider ID',
            headerComponentParams: header_template,
            field: 'provider_id',
            filter: 'numberFilter',
            minWidth: 150,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: number_comparator
        },
        {
            headerName: 'Alt Record ID',
            headerComponentParams: header_template,
            field: 'alt_record_id',
            filter: 'numberFilter',
            minWidth: 150,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'status',
            filter: 'checklistFilter',
            minWidth: 120,
            width: 120,
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true
        },
        {
            headerName: 'Claim Holds',
            headerComponentParams: headerStatusTemplate,
            field: 'claim_holds',
            filter: 'textFilter',
            minWidth: 120,
            width: 120,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        }
    ],

    validation_message: {
        error: '',
        save: 'Provider Added.',
        update: 'Provider Updated.',
        warning: ''
    }
};

export const billing_claimholds = {
    id: 'claimholds_id',
    search_header: 'Claim Holds',
    provider_header: 'Provider Claim Holds',
    location_header: 'Location Claim Holds',
    add_header: 'Claim Holds',
    edit_header: 'Claim Holds',
    base_url: '/billing',
    route: 'billing',
    new_provider_button_enabled: false,
    billing_enabled: false,
    provider_enabled: false,
    claims_enabled: true,
    provider_storage_key: 'provider_billing_search_criteria',
    location_storage_key: 'location_search_criteria',
    grid_header_height: 45,
    api: {
        add: {
            type: 'POST',
            url: '/constant/providers'
        },
        search: {
            type: 'GET',
            provider_url:
                '/constant/providers/search?provider_code=provider_code_value&first_name=first_name_value&last_name=last_name_value&show_inactive=show_inactive_value&page_Size=5000',
            location_url:
                '/constant/locations/search?location_code=location_code_value&location_name=location_name_value&show_inactive=show_inactive_value&return_claimholds=true'
        },
        update: {
            type: 'PUT',
            url: '/constant/providers/id_value'
        },
        get: {
            type: 'GET',
            url: '/constant/providers/id_value'
        }
    },

    provider_column_def: [
        {
            headerName: 'Provider Code',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'provider_code',
            minWidth: 135,
            width: 170,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Last Name',
            headerComponentParams: header_template,
            field: 'last_name',
            minWidth: 160,
            width: 210,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'First Name',
            headerComponentParams: header_template,
            field: 'first_name',
            minWidth: 160,
            width: 210,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Description',
            headerComponentParams: header_template,
            field: 'description',
            minWidth: 160,
            width: 210,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Provider Type',
            headerComponentParams: header_template,
            field: 'provider_type',
            minWidth: 170,
            width: 210,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Primary Specialty',
            headerComponentParams: header_template,
            field: 'primary_specialty',
            filter: 'textFilter',
            minWidth: 195,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'National Provider Identifier',
            headerComponentParams: header_template,
            field: 'national_provider_identifier',
            filter: 'textFilter',
            minWidth: 195,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'State License Number',
            headerComponentParams: header_template,
            field: 'state_license_number',
            filter: 'textFilter',
            minWidth: 165,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Provider ID',
            headerComponentParams: header_template,
            field: 'provider_id',
            filter: 'numberFilter',
            minWidth: 150,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: number_comparator
        },
        {
            headerName: 'Alt Record ID',
            headerComponentParams: header_template,
            field: 'alt_record_id',
            filter: 'numberFilter',
            minWidth: 150,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'status',
            filter: 'checklistFilter',
            minWidth: 120,
            width: 120,
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true
        },
        {
            headerName: 'Claim Holds',
            headerComponentParams: headerStatusTemplate,
            field: 'claim_holds',
            filter: 'textFilter',
            minWidth: 120,
            width: 120,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        }
    ],

    location_column_def: [
        {
            headerName: 'Location Code',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'pos_code',
            minWidth: 135,
            width: 170,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Location Description',
            headerComponentParams: header_template,
            field: 'pos_name',
            minWidth: 210,
            width: 600,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Address',
            headerComponentParams: header_template,
            field: 'address1',
            minWidth: 130,
            width: 210,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'City',
            headerComponentParams: header_template,
            field: 'city',
            minWidth: 95,
            width: 210,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'State',
            headerComponentParams: header_template,
            field: 'state',
            minWidth: 110,
            width: 210,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Zip',
            headerComponentParams: header_template,
            field: 'zip',
            filter: 'textFilter',
            minWidth: 90,
            width: 210,
            type: 'zip',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Phone',
            headerComponentParams: header_template,
            field: 'phone',
            filter: 'textFilter',
            minWidth: 110,
            width: 210,
            type: 'phone',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'NPI',
            headerComponentParams: header_template,
            field: 'npi',
            filter: 'numberFilter',
            minWidth: 95,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: number_comparator
        },
        {
            headerName: 'TIN',
            headerComponentParams: header_template,
            field: 'tax_id',
            filter: 'numberFilter',
            minWidth: 95,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: number_comparator
        },
        {
            headerName: 'Practice',
            headerComponentParams: header_template,
            field: 'practice_name',
            filter: 'textFilter',
            minWidth: 130,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Apply Tax',
            headerComponentParams: headerStatusTemplate,
            field: 'apply_tax',
            filter: 'checklistFilter',
            minWidth: 140,
            suppressSizeToFit: true,
            cellStyle: { textAlign: 'center' },
            type: 'boolean',
            unSortIcon: true,
            cellRenderer: readonly_checkbox_cell_renderer
        },
        {
            headerName: 'Supply Only Charge Entry',
            headerComponentParams: headerStatusTemplate,
            field: 'supply_only_charge_entry',
            filter: 'checklistFilter',
            minWidth: 140,
            cellStyle: { textAlign: 'center' },
            type: 'boolean',
            unSortIcon: true,
            cellRenderer: readonly_checkbox_cell_renderer
        },
        {
            headerName: 'Location ID',
            headerComponentParams: header_template,
            field: 'place_of_service_id',
            filter: 'numberFilter',
            minWidth: 130,
            width: 210,
            type: 'string',
            unSortIcon: true,
            comparator: number_comparator
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'is_active',
            filter: 'checklistFilter',
            minWidth: 120,
            width: 120,
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true
        },
        {
            headerName: 'Claim Holds',
            headerComponentParams: headerStatusTemplate,
            field: 'claim_holds',
            filter: 'textFilter',
            minWidth: 120,
            width: 120,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        }
    ],

    validation_message: {
        error: '',
        save: 'Provider Added.',
        update: 'Provider Updated.',
        warning: ''
    }
};

// Payer groups constants
export const payer_groups = {
    id: 'payer_group_id',
    search_header: 'Payer Groups',
    add_header: 'Payer Groups',
    edit_header: 'Payer Groups',
    base_url: '/billing',
    payer_header_id: 'payer_header_id',
    api: {
        add: {
            type: 'POST',
            url: '/constant/payer_group'
        },
        search: {
            type: 'GET',
            url:
                '/constant/payer_group/search?payer_group_code=payer_group_code_value&payer_group_name=payer_group_name_value&show_inactive=show_inactive_value&Page_size=800'
        },
        update: {
            type: 'PUT',
            url: '/constant/payer_group/id_value'
        },
        get: {
            type: 'GET',
            url: '/constant/payer_group/id_value'
        },
        payer_quick_search: '/insurance_payer/quick_search',
        payer_advanced_search: '/insurance_payer/search'
    },

    column_def: [
        {
            headerName: 'Payer Group Code',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'payer_group_code',
            filter: 'textFilter',
            minWidth: 200,
            width: 200,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Payer Group Name',
            headerComponentParams: header_template,
            field: 'payer_group_name',
            filter: 'textFilter',
            minWidth: 210,
            width: 700,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Payer Group ID',
            headerComponentParams: header_template,
            field: 'payer_group_id',
            filter: 'numberFilter',
            minWidth: 180,
            width: 180,
            type: 'number',
            unSortIcon: true,
            comparator: number_comparator
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'is_active',
            filter: 'checklistFilter',
            minWidth: 120,
            width: 150,
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true
        }
    ],
    payer_column_defs: [
        {
            headerName: 'Name',
            field: 'name',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 120,
            unSortIcon: true,
            headerComponentParams: get_header_template_with_navigation('payer_header_id')
        },
        {
            headerName: 'Description',
            field: 'description',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 375,
            unSortIcon: true,
            headerComponentParams: header_template
        },
        {
            headerName: 'Outbound ID',
            field: 'outbound_id',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 160,
            unSortIcon: true,
            headerComponentParams: header_template
        },
        {
            headerName: 'Inbound ID',
            field: 'inbound_id',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 160,
            unSortIcon: true,
            headerComponentParams: header_template
        }
    ],
    payers: [
        {
            headerName: '',
            headerComponentParams: header_template_no_filter_icon,
            field: 'id',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            width: 40,
            suppressSorting: true,
            suppressFilter: true,
            suppressSizeToFit: true,
            cellStyle: { 'padding-left': '6px' },
            valueGetter: function chainValueGetter(params) {
                return '';
            }
        },
        {
            headerName: 'Payer Group Code',
            headerComponentParams: header_template_no_filter_icon,
            field: 'name',
            suppressFilter: true,
            minWidth: 200,
            unSortIcon: true
        },
        {
            headerName: 'Description',
            headerComponentParams: header_template_no_filter_icon,
            field: 'description',
            suppressFilter: true,
            unSortIcon: true,
            minWidth: 140
        }
    ],
    messages: {
        error: '',
        save: 'Payer Group Added.',
        update: 'Payer Group Updated.',
        warning: ''
    }
};

//Payers
export const payer = {
    id: 'payer_id',
    header: 'Payers',
    base_url: '/billing',
    create_button_label: 'New',
    show_print_on_search: true,
    show_export_on_search: true,
    container_heading: ['Cross-Company Settings', 'Company Specific Overrides'],

    control_data: [
        // View edit #### Cross-Company Settings
        {
            control_type: 'text',
            label: 'Payer Code',
            name: 'payer_code',
            default_value: '',
            is_required: true,
            is_add: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 1,
            form_name: 'payer'
        },
        {
            control_type: 'text',
            label: 'Payer Code',
            name: 'payer_code',
            default_value: '',
            is_edit: true,
            non_editable: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 1,
            form_name: 'payer'
        },
        {
            control_type: 'text',
            label: 'Description',
            name: 'description',
            default_value: '',
            is_required: true,
            is_add: true,
            is_edit: true,
            row: 1,
            max_length: 100,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 1,
            form_name: 'payer'
        },
        {
            control_type: 'text',
            label: 'Inbound IDs',
            name: 'inbound_ids',
            help_text: 'for multiples: separate by commas (,)',
            default_value: '',
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 500,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 1,
            form_name: 'cross_company_settings'
        },
        {
            control_type: 'text',
            label: 'Outbound ID',
            name: 'outbound_id',
            default_value: '',
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 100,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 1,
            form_name: 'cross_company_settings'
        },
        // Row 2
        {
            control_type: 'drop_down_list',
            label: 'Payer Group',
            name: 'payer_group_id',
            default_value: 0,
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_add: true,
            row: 2,
            max_length: 4,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 1,
            form_name: 'cross_company_settings'
        },
        {
            control_type: 'days',
            label: '# of days to 999',
            name: 'days_to_999',
            default_value: '',
            is_edit: true,
            is_add: true,
            row: 2,
            max_length: 5,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 1,
            form_name: 'cross_company_settings'
        },
        {
            control_type: 'days',
            label: '# of days to 277',
            name: 'days_to_277',
            default_value: '',
            is_edit: true,
            is_add: true,
            row: 2,
            max_length: 5,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 1,
            form_name: 'cross_company_settings'
        },
        {
            control_type: 'checkbox',
            label: 'Medicare Crossover',
            name: 'medicare_crossover',
            default_value: false,
            is_edit: true,
            is_add: true,
            row: 2,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 1,
            form_name: 'cross_company_settings'
        },
        {
            control_type: 'checkbox',
            label: 'Denies Crossover',
            name: 'denies_crossover',
            default_value: false,
            is_edit: true,
            is_add: true,
            row: 2,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 1,
            form_name: 'cross_company_settings'
        },
        // Row 3
        {
            control_type: 'checkbox',
            label: 'Accepts Primary Paper Claims',
            name: 'accepts_primary_paper_claims',
            default_value: false,
            is_edit: true,
            is_add: true,
            row: 3,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 1,
            form_name: 'cross_company_settings'
        },
        {
            control_type: 'checkbox',
            label: 'Accepts Secondary Paper Claims',
            name: 'accepts_secondary_paper_claims',
            default_value: false,
            is_edit: true,
            is_add: true,
            row: 3,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 1,
            form_name: 'cross_company_settings'
        },
        {
            control_type: 'checkbox',
            label: 'Accepts Secondary Claims Electronically',
            name: 'accepts_secondary_claims_electronically',
            default_value: false,
            is_edit: true,
            is_add: true,
            row: 3,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 1,
            form_name: 'cross_company_settings'
        },
        {
            control_type: 'checkbox',
            label: 'Include on 277 Report',
            name: 'include_on_277_rpt',
            default_value: false,
            is_edit: true,
            is_add: true,
            row: 3,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 1,
            form_name: 'cross_company_settings'
        },
        // Row 4
        {
            control_type: 'checkbox',
            label: 'Treatment Notes Required',
            name: 'treatment_notes_required',
            default_value: false,
            is_edit: true,
            is_add: true,
            row: 4,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 1,
            form_name: 'cross_company_settings'
        },
        {
            control_type: 'checkbox',
            label: 'Is Active',
            name: 'is_Active',
            parent_obj: 'payer',
            default_value: true,
            is_edit: true,
            is_add: true,
            row: 4,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 1,
            form_name: 'cross_company_settings'
        },
        // View edit #### Company Specific Overrides
        {
            control_type: 'text',
            label: 'Inbound IDs',
            name: 'inbound_ids',
            help_text: 'for multiples: separate by commas (,)',
            default_value: '',
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 500,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 2,
            form_name: 'company_specific_overrides'
        },
        {
            control_type: 'text',
            label: 'Outbound ID',
            name: 'outbound_id',
            default_value: '',
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 100,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 2,
            form_name: 'company_specific_overrides'
        },
        {
            control_type: 'drop_down_list',
            label: 'Payer Group',
            name: 'payer_group_id',
            default_value: 0,
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 2,
            form_name: 'company_specific_overrides'
        },
        {
            control_type: 'days',
            label: '# of days to 999',
            name: 'days_to_999',
            default_value: '',
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 5,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 2,
            form_name: 'company_specific_overrides'
        },
        //  Row 2
        {
            control_type: 'days',
            label: '# of days to 277',
            name: 'days_to_277',
            default_value: '',
            is_edit: true,
            is_add: true,
            row: 2,
            max_length: 5,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 2,
            form_name: 'company_specific_overrides'
        },
        {
            control_type: 'drop_down_list',
            label: 'Medicare Crossover',
            name: 'medicare_crossover',
            default_value: 0,
            master_data: [
                { value: 'default', text: 'Default', selected: false },
                { value: true, text: 'Yes', selected: false },
                { value: false, text: 'No', selected: false }
            ],
            is_edit: true,
            is_add: true,
            row: 2,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 2,
            form_name: 'company_specific_overrides'
        },
        {
            control_type: 'drop_down_list',
            label: 'Denies Crossover',
            name: 'denies_crossover',
            default_value: 0,
            master_data: [
                { value: 'default', text: 'Default', selected: false },
                { value: true, text: 'Yes', selected: false },
                { value: false, text: 'No', selected: false }
            ],
            is_edit: true,
            is_add: true,
            row: 2,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 2,
            form_name: 'company_specific_overrides'
        },   
        {
            control_type: 'drop_down_list',
            label: 'Accepts Primary Paper Claims',
            name: 'accepts_primary_paper_claims',
            default_value: 0,
            master_data: [
                { value: 'default', text: 'Default', selected: false },
                { value: true, text: 'Yes', selected: false },
                { value: false, text: 'No', selected: false }
            ],
            is_edit: true,
            is_add: true,
            row: 2,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 2,
            form_name: 'company_specific_overrides'
        },
        {
            control_type: 'drop_down_list',
            label: 'Accepts Secondary Paper Claims',
            name: 'accepts_secondary_paper_claims',
            default_value: 0,
            master_data: [
                { value: 'default', text: 'Default', selected: false },
                { value: true, text: 'Yes', selected: false },
                { value: false, text: 'No', selected: false }
            ],
            is_edit: true,
            is_add: true,
            row: 2,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 2,
            form_name: 'company_specific_overrides'
        },
        //  Row 3
        {
            control_type: 'drop_down_list',
            label: 'Accepts Secondary Claims Electronically',
            name: 'accepts_secondary_claims_electronically',
            default_value: 0,
            master_data: [
                { value: 'default', text: 'Default', selected: false },
                { value: true, text: 'Yes', selected: false },
                { value: false, text: 'No', selected: false }
            ],
            is_edit: true,
            is_add: true,
            row: 3,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 2,
            form_name: 'company_specific_overrides'
        },
        {
            control_type: 'drop_down_list',
            label: 'Include on 277 Report',
            name: 'include_on_277_rpt',
            default_value: 0,
            master_data: [
                { value: 'default', text: 'Default', selected: false },
                { value: true, text: 'Yes', selected: false },
                { value: false, text: 'No', selected: false }
            ],
            is_edit: true,
            is_add: true,
            row: 3,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 2,
            form_name: 'company_specific_overrides'
        },
        {
            control_type: 'drop_down_list',
            label: 'Treatment Notes Required',
            name: 'treatment_notes_required',
            default_value: 0,
            master_data: [
                { value: 'default', text: 'Default', selected: false },
                { value: true, text: 'Yes', selected: false },
                { value: false, text: 'No', selected: false }
            ],
            is_edit: true,
            is_add: true,
            row: 3,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 2,
            form_name: 'company_specific_overrides'
        },
        {
            control_type: 'text',
            label: 'Tax Identification Number',
            name: 'tax_identification_number',
            default_value: '',
            is_edit: true,
            is_add: true,
            row: 3,
            max_length: 9,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 2,
            pattern: '[0-9]*',
            form_name: 'company_specific_overrides',
            is_required_text: true,
            is_required_msg: 'Tax Identification Number (9 digits)'
        },
        //  Row 4
        {
            control_type: 'text',
            label: 'Taxonomy Code',
            name: 'taxonomy_code',
            default_value: '',
            is_add: true,
            is_edit: true,
            row: 4,
            max_length: 10,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 2,
            pattern: '^[0-9a-zA-Z]+$',
            form_name: 'company_specific_overrides',
            is_required_text: true,
            is_required_msg: 'Taxonomy Code (10 characters)'
        },
        {
            control_type: 'text',
            label: 'Type of Bill',
            name: 'type_of_bill',
            default_value: '',
            is_edit: true,
            is_add: true,
            row: 4,
            max_length: 3,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 2,
            pattern: '[0-9]*',
            form_name: 'company_specific_overrides',
            is_required_text: true,
            is_required_msg: 'Type of Bill (3 digits)'
        },
        {
            control_type: 'checkbox',
            label: 'Is Active',
            name: 'is_Active',
            default_value: false,
            is_edit: true,
            is_add: true,
            row: 4,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4,
            container: 2,
            form_name: 'company_specific_overrides'
        }
    ],

    api: {
        add: {
            type: 'POST',
            url: '/constant/payers'
        },
        search: {
            type: 'GET',
            url:
                '/constant/payers/search?name=name_value&description=description_value&outbound_Id=outbound_Id_value&show_inactive=show_inactive_value&page_size=5000'
        },
        update: {
            type: 'PUT',
            url: '/constant/payers/id_value'
        },
        get: {
            type: 'GET',
            url: '/constant/payers/id_value'
        },
        payer_groups: {
            type: 'GET',
            api: '/constant/payer_group/search?Page_size=800'
        }
    },

    column_def: [
        {
            headerName: 'Payer Code',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'name',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator,
            suppressSizeToFit: true
        },
        {
            headerName: 'Description',
            headerComponentParams: header_template,
            field: 'description',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator,
            suppressSizeToFit: true
        },
        {
            headerName: 'Outbound ID',
            headerComponentParams: header_template,
            field: 'outbound_id_list',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator,
            suppressSizeToFit: true
        },
        {
            headerName: 'Medicare Crossover',
            headerComponentParams: header_template,
            field: 'medicare_crossover',
            filter: 'checklistFilter',
            type: 'string',
            unSortIcon: true,
            cellRenderer: readonly_checkbox_cell_renderer,
            cellStyle: { textAlign: 'center' },
            hide: false,
            suppressSizeToFit: false
        },
        {
            headerName: 'Denies Crossover',
            headerComponentParams: header_template,
            field: 'denies_crossover',
            filter: 'checklistFilter',
            type: 'string',
            unSortIcon: true,
            cellRenderer: readonly_checkbox_cell_renderer,
            cellStyle: { textAlign: 'center' },
            hide: false,
            suppressSizeToFit: false
        },
        {
            headerName: 'Accepts Primary Paper Claims',
            headerComponentParams: header_template,
            field: 'accepts_primary_paper_claims',
            filter: 'checklistFilter',
            type: 'string',
            unSortIcon: true,
            cellRenderer: readonly_checkbox_cell_renderer,
            cellStyle: { textAlign: 'center' },
            hide: false,
            suppressSizeToFit: false
        },
        {
            headerName: 'Accepts Secondary Paper Claims',
            headerComponentParams: header_template,
            field: 'accepts_secondary_paper_claims',
            filter: 'checklistFilter',
            type: 'string',
            unSortIcon: true,
            cellRenderer: readonly_checkbox_cell_renderer,
            cellStyle: { textAlign: 'center' },
            hide: false,
            suppressSizeToFit: false
        },
        {
            headerName: 'Accepts Secondary Claims Electronically',
            headerComponentParams: header_template,
            field: 'accepts_secondary_claims_electronically',
            filter: 'checklistFilter',
            type: 'string',
            unSortIcon: true,
            cellRenderer: readonly_checkbox_cell_renderer,
            cellStyle: { textAlign: 'center' },
            hide: false,
            suppressSizeToFit: false
        },
        {
            headerName: 'Include on 277 Report',
            headerComponentParams: header_template,
            field: 'include_on_277_rpt',
            filter: 'checklistFilter',
            type: 'string',
            unSortIcon: true,
            cellRenderer: readonly_checkbox_cell_renderer,
            cellStyle: { textAlign: 'center' },
            hide: false,
            suppressSizeToFit: false
        },
        {
            headerName: 'Treatment Notes Required',
            headerComponentParams: header_template,
            field: 'treatment_notes_required',
            filter: 'checklistFilter',
            type: 'string',
            unSortIcon: true,
            cellRenderer: readonly_checkbox_cell_renderer,
            cellStyle: { textAlign: 'center' },
            hide: false,
            suppressSizeToFit: false
        },
        {
            headerName: 'Tax ID Number',
            headerComponentParams: header_template,
            field: 'tax_identification_number',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator,
            hide: false,
            suppressSizeToFit: true
        },
        {
            headerName: 'Taxonomy Code',
            headerComponentParams: header_template,
            field: 'taxonomy_code',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator,
            hide: false,
            suppressSizeToFit: true
        },
        {
            headerName: 'Type of Bill',
            headerComponentParams: header_template,
            field: 'type_of_bill',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator,
            hide: false,
            suppressSizeToFit: true
        },
        {
            headerName: 'Payer ID',
            headerComponentParams: header_template,
            field: 'payer_id',
            filter: 'numberFilter',
            type: 'string',
            unSortIcon: true,
            comparator: number_comparator,
            suppressSizeToFit: true
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'isActive',
            filter: 'checklistFilter',
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true,
            suppressSizeToFit: false
        }
    ],
    
    //these contain 'field' from above defined column_def.
    column_def_ff_columns: ['medicare_crossover', 'accepts_primary_paper_claims', 'accepts_secondary_paper_claims',
        'accepts_secondary_claims_electronically', 'include_on_277_rpt', 'treatment_notes_required',
        'tax_identification_number', 'taxonomy_code', 'type_of_bill'],

    insurance_column_def: [
        {
            headerName: '',
            headerComponentParams: header_template_with_navigation('ins_code'),
            field: 'insurance_code_id',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            width: 40,
            suppressSorting: true,
            suppressFilter: true,
            suppressSizeToFit: true,
            cellStyle: { 'padding-left': '12px' },
            valueGetter: function chainValueGetter(params) {
                return '';
            }
        },
        {
            headerName: 'Insurance Code',
            headerComponentParams: header_template_with_navigation('ins_code'),
            field: 'ins_code',
            minWidth: 150,
            filter: 'numberFilter',
            type: 'number',
            unSortIcon: true,
            comparator: number_comparator
        },
        {
            headerName: 'Insurance Name',
            headerComponentParams: header_template_with_navigation('ins_code'),
            field: 'ins_name',
            minWidth: 200,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        }
    ],
    validation_message: {
        error: '',
        save: 'Payer Added.',
        update: 'Payer Updated.',
        warning: ''
    }
};

export const company = {
    id: 'company_id',
    search_header: 'Company',
    add_header: 'Company',
    edit_header: 'Company',
    base_url: '/admin',
    create_button_label: 'New',
    is_add_edit: false,
    show_print_on_search: true,
    show_export_on_search: true,
    grid_header_height: 45,
    control_data: [
        // Search
        {
            control_type: 'text',
            label: 'Company Name',
            name: 'company_name',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Company Code',
            name: 'company_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'drop_down_list',
            label: 'Mailing State',
            name: 'mailing_state',
            default_value: '',
            is_state: true,
            with_select: true,
            is_placeholder: true,
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 2,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Include Inactive',
            name: 'show_inactive',
            default_value: false,
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        }
    ],
    api: {
        search: {
            type: 'GET',
            url:
                '/constant/company/search?company_name=company_name_value&company_code=company_code_value&show_inactive=show_inactive_value&mailing_state=mailing_state_value&page_size=5000'
        }
    },
    column_def: [
        {
            headerName: 'Company Code',
            headerComponentParams: get_header_template_with_navigation(search_grid_id),
            field: 'company_code',
            filter: 'textFilter',
            minWidth: 140,
            width: 140,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Company Name',
            headerComponentParams: header_template,
            field: 'company_name',
            filter: 'textFilter',
            minWidth: 185,
            width: 185,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Address',
            headerComponentParams: header_template,
            field: 'address',
            filter: 'textFilter',
            minWidth: 135,
            width: 135,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'City',
            headerComponentParams: header_template,
            field: 'city',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator,
            minWidth: 100,
            width: 125
        },
        {
            headerName: 'State',
            headerComponentParams: header_template,
            field: 'state',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator,
            minWidth: 115,
            width: 115
        },
        {
            headerName: 'Export',
            headerComponentParams: headerStatusTemplate,
            field: 'export',
            filter: 'checklistFilter',
            minWidth: 150,
            width: 150,
            type: 'string',
            unSortIcon: true,
            cellRenderer: params => {
                return `<div style="text-align:center"><input type='checkbox' ${
                    params.value ? 'checked' : ''
                    } disabled/></div>`;
            }
        },
        {
            headerName: 'Test/ Demo',
            headerComponentParams: headerStatusTemplate,
            field: 'test_demo',
            filter: 'checklistFilter',
            minWidth: 170,
            width: 170,
            type: 'string',
            unSortIcon: true,
            cellRenderer: params => {
                return `<div style="text-align:center"><input type='checkbox' ${
                    params.value ? 'checked' : ''
                    } disabled/></div>`;
            }
        },
        {
            headerName: 'Accrual Accounting',
            headerComponentParams: headerStatusTemplate,
            field: 'accrual_accounting',
            filter: 'checklistFilter',
            minWidth: 175,
            width: 175,
            type: 'string',
            unSortIcon: true,
            cellRenderer: params => {
                return `<div style="text-align:center"><input type='checkbox' ${
                    params.value ? 'checked' : ''
                    } disabled/></div>`;
            }
        },
        {
            headerName: 'Updated Date',
            headerComponentParams: header_template,
            field: 'updated_date',
            filter: 'dateTimeFilter',
            type: 'dateTime',
            unSortIcon: true,
            comparator: mm_dd_yyyy_h_mm_ss_tt_comparator,
            minWidth: 130,
            width: 130,
            cellRenderer: params => {
                return custom_date_format(params.value, global_constants.date_format["mm/dd/yyyy h:MM:ss TT"])
            }
        },
        {
            headerName: 'Company ID',
            headerComponentParams: header_template,
            field: 'company_id',
            filter: 'numberFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator,
            minWidth: 140,
            width: 140
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'status',
            filter: 'checklistFilter',
            minWidth: 120,
            width: 120,
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true
        }
    ]
};

export const users = {
    id: 'users_id',
    search_header: 'Users',
    base_url: '/admin',
    create_button_label: 'New',
    show_print_on_search: true,
    show_export_on_search: true,
    lookup_search_list: ['user_type_id', 'role_id', 'default_company_id'],
    is_lookup_search: true,
    grid_header_height: 55,
    is_pagination: true,
    paginate_size: 20,
    user_type_header_id: 'user_type_header_id',
    role_header_id: 'role_header_id',
    default_company_header_id: 'default_company_header_id',
    api: {
        search: {
            type: 'GET',
            url: '/constant/users/search?first_name=first_name_value&last_name=last_name_value&email=email_value&login_id=login_id_value&show_inactive=show_inactive_value&user_type_id=user_type_id_value&role_id=role_id_value&default_company_id=default_company_id_value&page_size=5000'
        },
        get: {
            type: 'GET',
            url: '/user/full_details/id_value'
        },
        update: {
            type: 'PUT',
            url: '/user/full_details/id_value'
        },
        add: {
            type: 'POST',
            url: '/user/full_details'
        },
        get_permissions: {
            type: 'GET',
            url: '/user/permission_references'
        },
        user_type_quick_search: '/constant/users/quick_search_user_type',
        user_type_advance_search: '/constant/users/get_search_user_type',
        role_quick_search: '/constant/users/quick_search_roles',
        role_advance_search: '/constant/users/get_search_roles',
        default_company_quick_search: '/constant/users/quick_search_default_company',
        default_company_advance_search: '/constant/users/get_search_default_company'
    },

    column_def: [
        {
            headerName: 'First Name',
            headerComponentParams: header_template,
            field: 'first_name',
            filter: 'textFilter',
            minWidth: 150,
            width: 150,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Last Name',
            headerComponentParams: header_template,
            field: 'last_name',
            filter: 'textFilter',
            minWidth: 150,
            width: 150,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'User Name',
            headerComponentParams: get_header_template_with_navigation(search_grid_id),
            field: 'user_name',
            filter: 'textFilter',
            minWidth: 150,
            width: 150,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'User Type',
            headerClass: 'col-group-gray',
            headerComponentParams: header_template,
            field: 'user_type',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator,
            minWidth: 140,
            width: 140
        },
        {
            headerName: 'Email',
            headerClass: 'col-group-gray',
            headerComponentParams: header_template,
            field: 'email',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator,
            minWidth: 120,
            width: 120
        },
        {
            headerName: 'Role',
            headerClass: 'col-group-gray',
            headerComponentParams: header_template,
            field: 'role',
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator,
            minWidth: 120,
            width: 120
        },
        {
            headerName: 'Phone',
            headerClass: 'col-group-gray',
            headerComponentParams: header_template,
            field: 'phone',
            filter: 'textFilter',
            type: 'phone',
            unSortIcon: true,
            comparator: text_comparator,
            minWidth: 125,
            width: 125
        },
        {
            headerName: 'Default Company Code',
            headerClass: 'col-group-gray',
            headerComponentParams: header_template,
            field: 'default_company_code',
            filter: 'textFilter',
            type: 'string',
            minWidth: 185,
            width: 185,
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Default Company',
            headerClass: 'col-group-gray',
            headerComponentParams: header_template,
            field: 'default_company',
            filter: 'textFilter',
            type: 'string',
            minWidth: 200,
            width: 200,
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'isActive',
            filter: 'checklistFilter',
            minWidth: 120,
            width: 120,
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true
        }
    ],
    user_type_column_def: [
        {
            headerName: 'Name',
            headerComponentParams: get_header_template_with_navigation('user_type_header_id'),
            field: 'name',
            filter: 'textFilter',
            minWidth: 100,
            width: 100,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        }
    ],
    role_column_def: [
        {
            headerName: 'Name',
            headerComponentParams: get_header_template_with_navigation('role_header_id'),
            field: 'role_name',
            filter: 'textFilter',
            minWidth: 100,
            width: 100,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        }
    ],
    default_company_column_def: [
        {
            headerName: 'Company Code',
            headerComponentParams: get_header_template_with_navigation('default_company_header_id'),
            field: 'gpms_code',
            filter: 'textFilter',
            minWidth: 180,
            width: 150,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Company Name',
            headerComponentParams: get_header_template_with_navigation(search_grid_id),
            field: 'company_name',
            filter: 'textFilter',
            minWidth: 185,
            width: 185,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        }
    ],
    validation_message: {
        error: '',
        save: 'User Added.',
        update: 'User Updated.',
        warning: ''
    },
};
export const insurance_class = {
    id: 'insurance_classes_id',
    search_header: 'Insurance Classes',
    add_header: 'Insurance Classes',
    edit_header: 'Insurance Classes',
    base_url: '/admin',
    create_button_label: 'New',
    show_print_on_search: true,
    show_export_on_search: true,
    grid_header_height: 45,
    control_data: [
        // Search
        {
            control_type: 'text',
            label: 'Insurance Class Code',
            name: 'ins_class_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Insurance Class Description',
            name: 'ins_class_desc',
            default_value: '',
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Include Inactive',
            name: 'include_inactive',
            default_value: false,
            master_data: [], // In case of dropdown
            is_search: true,
            row: 1,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },

        // View edit
        {
            control_type: 'text',
            label: 'Insurance Class Code',
            name: 'ins_class_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'text',
            label: 'Insurance Class Description',
            name: 'ins_class_desc',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 50,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'drop_down_list',
            label: 'Payment Code',
            name: 'payment_code',
            default_value: 0,
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 4,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'drop_down_list',
            label: 'Adjustment Code',
            name: 'adjustment_code',
            default_value: 0,
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 4,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'drop_down_list',
            label: 'Insurance Type',
            name: 'insurance_type',
            default_value: 0,
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_add: true,
            row: 2,
            max_length: 4,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Audit Required',
            name: 'audit_required',
            default_value: false,
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 2,
            custom_style: { marginTop: 0, paddingTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Authorization Required',
            name: 'auth_required',
            default_value: false,
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 2,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Apply Tax',
            name: 'apply_tax',
            default_value: false,
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 2,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Apply Excise Tax',
            name: 'apply_excise_tax',
            default_value: false,
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 3,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Enable Functional Reporting',
            name: 'enable_functional_reporting',
            default_value: false,
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 3,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Enable MIPS',
            name: 'enable_imps',
            default_value: false,
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 3,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Adjust Secondary Balance',
            name: 'adjust_secondary_balance',
            default_value: false,
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 3,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'drop_down_list',
            label: 'Delay Description',
            name: 'pend_reason',
            default_value: 0,
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_add: true,
            row: 2,
            max_length: 4,
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Provider Credentials Required',
            name: 'provider_credentials_required',
            default_value: false,
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 3,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        },
        {
            control_type: 'checkbox',
            label: 'Is Active',
            name: 'is_active',
            default_value: true,
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 3,
            custom_style: { marginTop: 32 },
            view_container_tablet: 8,
            view_container_computer: 4,
            search_container_tablet: 8,
            search_container_computer: 4
        }
    ],

    api: {
        add: {
            type: 'POST',
            url: '/insurance/insurance_classes'
        },
        search: {
            type: 'GET',
            url: '/insurance/insurance_classes/search?ins_class_code=ins_class_code_value&ins_class_desc=ins_class_desc_value&include_inactive=include_inactive_value&page_Size=5000'
        },
        update: {
            type: 'PUT',
            url: '/insurance/insurance_classes/id_value'
        },
        get: {
            type: 'GET',
            url: '/insurance/insurance_classes/id_value'
        },
        memberget: {
            type: 'GET',
            url: '/company/id_value/full_details'
        }
    },

    column_def: [
        {
            headerName: 'Insurance Class Code',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'insurance_class_code',
            filter: 'textFilter',
            minWidth: 165,
            width: 165,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Insurance Class Description',
            headerComponentParams: header_template,
            field: 'insurance_class_description',
            filter: 'textFilter',
            minWidth: 130,
            width: 400,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Provider Credentials Required',
            headerComponentParams: header_template,
            field: 'provider_credentials_required',
            filter: 'checklistFilter',
            minWidth: 130,
            width: 130,
            type: 'boolean',
            unSortIcon: true,
            cellStyle: { 'text-align': 'center' },
            cellRenderer: params => {
                var eDiv = document.createElement('div');
                eDiv.innerHTML = `<input type='checkbox' ${params.value ? 'checked' : ''} disabled/>`;
                return eDiv;
            }
        },
        {
            headerName: 'Audit Required',
            headerComponentParams: header_template,
            field: 'audit_required',
            filter: 'checklistFilter',
            minWidth: 130,
            width: 130,
            type: 'boolean',
            unSortIcon: true,
            cellStyle: { 'text-align': 'center' },
            cellRenderer: params => {
                var eDiv = document.createElement('div');
                eDiv.innerHTML = `<input type='checkbox' ${params.value ? 'checked' : ''} disabled/>`;
                return eDiv;
            }
        },
        {
            headerName: 'Authorization Required',
            headerComponentParams: header_template,
            field: 'auth_required',
            filter: 'checklistFilter',
            minWidth: 160,
            width: 160,
            type: 'boolean',
            unSortIcon: true,
            cellStyle: { 'text-align': 'center' },
            cellRenderer: params => {
                var eDiv = document.createElement('div');
                eDiv.innerHTML = `<input type='checkbox' ${params.value ? 'checked' : ''} disabled/>`;
                return eDiv;
            }
        },
        {
            headerName: 'Apply Tax',
            headerComponentParams: header_template,
            field: 'apply_tax',
            filter: 'checklistFilter',
            minWidth: 150,
            width: 150,
            type: 'string',
            unSortIcon: true,
            cellRenderer: readonly_checkbox_cell_renderer,
            cellStyle: { textAlign: 'center' },
            hide: false
        },
        {
            headerName: 'Apply Excise Tax',
            headerComponentParams: header_template,
            field: 'apply_excise_tax',
            filter: 'checklistFilter',
            minWidth: 150,
            width: 150,
            type: 'string',
            unSortIcon: true,
            cellRenderer: readonly_checkbox_cell_renderer,
            cellStyle: { textAlign: 'center' },
            hide: false
        },
        {
            headerName: 'Adjust Secondary Balance',
            headerComponentParams: header_template,
            field: 'adjust_secondary_balance',
            filter: 'checklistFilter',
            minWidth: 150,
            width: 150,
            type: 'string',
            unSortIcon: true,
            cellRenderer: readonly_checkbox_cell_renderer,
            cellStyle: { textAlign: 'center' },
            hide: false,
            suppressSizeToFit: true
        },
        {
            headerName: 'Apply Specialty Modifiers',
            headerComponentParams: header_template,
            field: 'apply_specialty_modifiers',
            filter: 'checklistFilter',
            minWidth: 150,
            width: 150,
            type: 'string',
            unSortIcon: true,
            cellRenderer: readonly_checkbox_cell_renderer,
            cellStyle: { textAlign: 'center' },
            hide: false,
            suppressSizeToFit: true
        },
        {
            headerName: 'Insurance Class ID',
            headerComponentParams: header_template,
            field: 'insurance_class_id',
            filter: 'numberFilter',
            minWidth: 150,
            width: 150,
            type: 'string',
            unSortIcon: true,
            comparator: number_comparator
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'is_active',
            filter: 'checklistFilter',
            minWidth: 120,
            width: 120,
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true
        }
    ],

    validation_message: {
        error: '',
        save: 'Insurance Class Added.',
        update: 'Insurance Class Updated.',
        warning: ''
    },

    param_validator: [
        {
            expression: '',
            message: ''
        }
    ]
};

export const schedule_month_end_id = "schedule_month_end_id";

export const schedule_month_end_status = [
    { text: 'All', value: 'all' },
    { text: 'Scheduled', value: 'scheduled' },
    { text: 'Running', value: 'running' },
    { text: 'Complete', value: 'complete' },
    { text: 'Failed', value: 'failed' }
];

export const schedule_month_end_status_ff_on = [
    { text: 'All', value: 'all' },
    { text: 'Scheduled', value: 'scheduled' },
    { text: 'Pending', value: 'pending' },
    { text: 'Running', value: 'running' },
    { text: 'Complete', value: 'complete' },
    { text: 'Failed', value: 'failed' }
];

export const search_schedule_month_configuration = {
    column_defs: [
        {
            headerName: "Month",
            headerComponentParams: get_header_template_with_navigation("schedule_month_end_id"),
            field: "month",
            filter: "monthNameFilter",
            minWidth: 90,
            width: 90,
            type: 'string',
            unSortIcon: true,
            comparator: month_name_comparator
        },
        {
            headerName: "Year",
            headerComponentParams: header_template,
            field: "year",
            filter: "textFilter",
            minWidth: 90,
            width: 90,
            type: 'number',
            unSortIcon: true,
            comparator: number_comparator
        },
        {
            headerName: "Company code",
            headerComponentParams: header_template,
            field: "company_code",
            minWidth: 150,
            width: 150,
            filter: "textFilter",
            unSortIcon: true,
            type: 'string',
            comparator: text_comparator
        },
        {
            headerName: "Company",
            headerComponentParams: header_template,
            field: "company_name",
            minWidth: 170,
            width: 170,
            filter: "textFilter",
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: "Requested",
            headerComponentParams: header_template,
            field: "requested_Date",
            filter: "textFilter",
            type: 'date',
            minWidth: 200,
            width: 230,
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: "Start Time",
            headerComponentParams: header_template,
            field: "start_time",
            filter: "textFilter",
            minWidth: 120,
            width: 120,
            type: 'time',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: "End Time",
            headerComponentParams: header_template,
            field: "end_time",
            filter: "textFilter",
            minWidth: 120,
            width: 120,
            type: 'time',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: "Status",
            headerComponentParams: header_template,
            field: "status",
            filter: "textFilter",
            type: 'string',
            minWidth: 100,
            width: 100,
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: "Message",
            headerComponentParams: header_template,
            field: "message",
            filter: "textFilter",
            type: 'string',
            minWidth: 160,
            width: 160,
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: "Cancel",
            headerComponentParams: header_template,
            field: "cancel_job",
            minWidth: 100,
            width: 100,
            filter: "checklistFilter",
            type: 'boolean',
            cellClass: "text-center",
            unSortIcon: true,
            cellRenderer: function (params) {
                var eAnc;
                if (params.value === "Active") {
                    eAnc = document.createElement('a');
                    eAnc.classList.add("blue");
                    eAnc.innerHTML = `<i class="icon trash alternate outline"></i>`
                } else {
                    eAnc = document.createElement('div');
                    eAnc.innerHTML = `<i class="icon trash alternate outline" style="color: #dbdbdb"></i>`
                }
                return eAnc;
            }
        },
        {
            headerName: "Initiate",
            headerComponentParams: header_template,
            field: "initiate_job",
            filter: "checklistFilter",
            type: 'boolean',
            minWidth: 100,
            width: 100,
            unSortIcon: true,
            cellClass: "text-center",
            cellRenderer: function (params) {
                var eAncInit;
                if (params.value === "Active") {
                    eAncInit = document.createElement('a');
                    eAncInit.classList.add("blue");
                    eAncInit.innerHTML = `<i class="icon video play"></i>`;
                } else {
                    eAncInit = document.createElement('div');
                    eAncInit.innerHTML = `<i class="icon video play" style="color: #dbdbdb"></i>`
                }
                return eAncInit;
            }
        }
    ]
};

export const start_year_for_schedule_job = 2020;
export const current_month_for_schedule_job = moment().month() == 11 ? 1 : moment().month() + 2;
export const current_year_for_schedule_job = moment().month() == 11 ? moment().year() + 1 : moment().year();
export const no_of_year_for_schedule_job = 100;
export const SCHEDULED_MONTH_END_SEARCH = 'SCHEDULED_MONTH_END:SCHEDULED_MONTH_END_SEARCH';

export const warningModalForScheduleJob = {
    title: 'Attention',
    message: 'You are attempting to schedule a month end close for a current/future month. Do you wish to proceed?'
}

export const expected_rate = {
    expected_rate_untitled: 'Untitled',
    component_display_title: 'Contract Management',
    no_data_display_text: 'No Expected Rates have been configured.',
    active_header_text: 'Active',
    expired_header_text: 'Expired',
    create_expected_rate_button_text: 'Add New Contract',
    save_button_text: 'Save',
    apply_insurance_code_button_text: 'Apply to Insurance Codes',
    apply_insurance_button_text: 'Apply to Insurance',
    include_cpt_code_text: 'Include CPT Codes with no value',
    assistant_modifier_reduction: 'Assistant Modifier Reduction',
    mppr:'MPPR',
    max_number_of_units: 'Max Number of Units',
    proc_code_columns_with_max_number_of_units: 12,
    proc_code_default_columns: 10,
    prompt_title: {
        unsaved_changes: 'Unsaved Changes',
        delete: 'Delete',
        confirm: 'Confirm',
    },
    prompt_message: {
        add_new: 'This action will cause any unsaved data to be lost. Continue?',
        to_delete: 'You are about to delete',
        to_continue: 'Do you wish to continue?',
        has_deleted: 'has been deleted.', 
        delete_fee_definition: 'Are you sure you want to delete this Expected Rate Fee ?'
    },
    prompt_button_label: {
        continue: 'Continue',
        delete: 'Delete',
        ok: 'OK'
    },
    warningModal: {
        title: 'Reimbursement Rates Missing',
        localStorageKey: 'contractManagement',
        flatRateId: 3,
        missingExpectedReimbursementsRates: (expectedRates:Array<any>) => {
            let message:string = '';
            expectedRates.map((expectedRate:any) => {
                message = message.concat(`CPT Codes under the ${expectedRate.name} Fee Schedule are missing Expected Reimbursements Rates.\n\n`);
            })
            if (message !== '') {
                message = message.concat('These are recommended but not required.');
            }
            return message;
        }
    },
    validation_message: {
        toastr: 'Please fix the validation issues.',
        insurance_validation_ms: 'Please select at least 1 Insurance Class or Insurance Code.',
        name: 'Name allows space, alphabets and numbers only. Special Characters are not allowed.',
        end_date: 'End date must come after start date.',
        assigned_insurances_ids: {
            hover: 'Previously defined in Expected Rate: ',
            required: 'Insurance is required.',
            validation: 'Please fix Insurance validation.'
        },
        required_message: '(required)',
        fee_definition: {
            provider: 'Select atleast one provider',
            location: 'select atleast on location'
        },
        expected_rate_fees_required: 'A minimum of one Rate Fee Definition is required.',
        expected_rate_fee_percentage: 'A maximum of 100% is allowed for Percentage.',
        modifier_percentage_reduction: 'A maximum of 99.99% is allowed for Percentage.'
    },
    //This for Expected Rate Definition.
    validation_obj_structure: {
        id: 'expected_rate_id',
        has_required_validation: 'has_required_validation',
        name: 'name',
        start_date: 'start_date',
        end_date: 'end_date',
        billing_tax_number: 'billing_tax_number',
        assigned_insurances_ids: 'assigned_insurances_ids',
        assigned_insurance_class_ids: 'assigned_insurance_class_ids',
        expected_rate_fees: 'expected_rate_fees',
        expected_rate_fees_validations: 'expected_rate_fee_validations',
        expected_rate_fee_procedure_codes_validations: 'expected_rate_fee_procedure_codes_validations'
    },
    //Object structure for ExpectedRate Rate Fee Definition
    expected_rate_fee_validation: {
        has_required_validation: 'has_required_validation',
        id: 'expected_rate_fee_id',
        assigned_location_ids: 'assigned_location_ids',
        assigned_provider_ids: 'assigned_provider_ids',
        include_future_providers: 'include_future_providers',
        fee_rate_type_id: 'fee_rate_type_id',
        percentage: 'percentage',
        modifier_percentage_reduction: 'modifier_percentage_reduction',
        per_visit: 'per_visit',
        per_evaluation: 'per_evaluation',
        proc_codes_validation_list:'proc_codes_validation_list',
        expected_rate_fee_procedure_codes: {
            id: 'procedure_code_id',
            expected_reimbursement_unit:'expected_reimbursement_unit'
        },
        units: 'units'
    },
    save_error_msg: 'Error saving expected rates.',
    save_success_msg: 'Expected Rate(s) saved successfully.',
    identifier: {
        name: 'name',
        billing_tax_number: 'billing_tax_number',
        dos_range: 'dos_range',
        assigned_insurances_ids: 'assigned_insurances_ids',
        location: 'location',
        provider: 'provider',
        fee_rate_type:'fee_rate_type',
        fee_rate_type_id: 'fee_rate_type_id',
        percentage: 'percentage',
        per_visit: 'per_visit',
        per_evaluation: 'per_evaluation',
        expected_rate_fee_definition: 'expected_rate_fee_definition',
        expected_rate_fee_definition_include_future_providers: 'expected_rate_fee_definition_include_future_providers',
        expected_rate_fees: 'expected_rate_fees',
        is_expanded: 'is_expanded',
        expected_rate_fee_definition_procedure_code: 'expected_rate_fee_definition_procedure_code',
        procedure_code_id: 'procedure_code_id',
        expected_reimbursement_unit: 'expected_reimbursement_unit',
        updated_billed_amount: 'updated_billed_amount',
        is_carve_out: 'is_carve_out',
        is_active: 'is_active',
        include_cpt_with_no_value:'include_cpt_with_no_value',
        modifier_percentage_reduction: 'modifier_percentage_reduction',
        assistant_modifier_reduction: 'assistant_modifier_reduction',
        mppr: 'mppr',
        mppr_rate: 'mppr_rate',
        max_number_of_units: 'max_number_of_units',
        units: 'units'
    },
    fee_type: {
        fee_for_service: 1,
        reduction_percent: 2,
        flat_rate: 3
    },
    alert_action: {
        add_new_expected_rate: 'add_new_expected_rate',
        copy_expected_rate: 'copy_expected_rate',
        delete_expected_rate: 'delete_expected_rate',
        delete_expected_rate_fee: 'delete_expected_rate_fee'
    },
    load_identifier: {
        billing_tax_number: 'billing_tax_number',
        cpt: 'cpt',
        providers: 'providers',
        locations: 'locations',
        insurance_codes: 'insurance_codes',
        fee_rate_type_list: 'fee_rate_type_list',
        expected_rates: 'expected_rates'
    },
    proc_code_obj: {
        id: 'expected_rate_fee_procedure_code_id',
        procedure_code_id: 'procedure_code_id',
        proc_code:'proc_code',
        reimbursement_unit: 'expected_reimbursement_unit',
        updated_billed_amount: 'updated_billed_amount',
        std_chg_amt: 'std_chg_amt',
        carve_out: 'is_carve_out',
        last_updated: 'last_updated',
        is_modified: 'is_modified',
        mppr_rate: 'mppr_rate'
    },
    multiple_fee_schedule_column_defs:{
        default_billed: 'Default Billed',
        billed_amount: 'Billed Amount',
        updated_billed_amount:'Updated Billed Amount'
    }
}

export const account_rollup_configuration = {
    column_defs: [
        {
            headerComponentParams: header_template,
            headerCheckboxSelection: false,
            checkboxSelection: true,
            field: "checkbox",
            width: 35,
            maxWidth: 35,
            suppressFilter: true,
            suppressSorting: true,
            suppressSizeToFit: true,
        },
        {
            headerName: "Patient ID",
            headerComponentParams: header_template,
            field: "id",
            filter: "textFilter",
            minWidth: 90,
            width: 90,
            type: 'number',
            suppressFilter: true,
            suppressSorting: true,
        },
        {
            headerName: "Company Code",
            headerComponentParams: header_template,
            field: "company_id",
            minWidth: 150,
            width: 150,
            type: 'company_id',
            suppressFilter: true,
            suppressSorting: true,
        },
        {
            headerName: "Company Name",
            headerComponentParams: header_template,
            field: "company_name",
            minWidth: 170,
            width: 170,
            type: 'company_name',
            suppressFilter: true,
            suppressSorting: true,
        },
        {
            headerName: "Patient Name",
            headerComponentParams: header_template,
            field: "first_name",
            type: 'name',
            minWidth: 200,
            width: 230,
            suppressFilter: true,
            suppressSorting: true,
        }
    ]
};

export const referring_physician_status_configuration = {
    title: "Referring Physician Status",
    grid_id: "referring_physician_status_grid",
    selection_error: 'Please select at least one record to update.',
    success_message: 'Referring Physician(s) status completed.',
    invalid_search: 'Search criteria is invalid.',
    column_defs: [
        {
            headerComponentParams: header_template,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            field: "checkbox",
            width: 35,
            maxWidth: 35,
            suppressFilter: true,
            suppressSorting: true,
            suppressSizeToFit: true,
            cellRenderer: params => {
                if (params.data && params.data.is_complete) {
                    return `<div><input type='checkbox' disabled/></div>`;
                }
                return '<span/>';
            }
        },
        {
            headerName: "MD Code",
            headerComponentParams: get_header_template_with_navigation("referring_physician_status_id"),
            field: "md_code",
            filter: "textFilter",
            minWidth: 90,
            width: 90,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: "First Name",
            headerComponentParams: header_template,
            field: "first_name",
            filter: "textFilter",
            minWidth: 100,
            width: 100,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: "Last Name",
            headerComponentParams: header_template,
            field: "last_name",
            filter: "textFilter",
            minWidth: 100,
            width: 100,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: "Address",
            headerComponentParams: header_template,
            field: "address1",
            filter: "textFilter",
            type: 'string',
            minWidth: 150,
            width: 150,
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: "City",
            headerComponentParams: header_template,
            field: "city",
            filter: "textFilter",
            type: 'string',
            minWidth: 100,
            width: 100,
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: "State",
            headerComponentParams: header_template,
            field: "state",
            filter: "textFilter",
            type: 'string',
            minWidth: 100,
            width: 100,
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: "ZIP",
            headerComponentParams: header_template,
            field: "zip",
            filter: "textFilter",
            type: 'string',
            minWidth: 100,
            width: 100,
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: "Complete",
            headerComponentParams: headerStatusTemplate,
            field: "is_complete",
            filter: "checklistFilter",
            type: 'string',
            minWidth: 130,
            width: 130,
            unSortIcon: true,
            cellRenderer: params => {
                if (params.getValue()) {
                    return '<div style="text-align:center"><span><i class="small check icon"></i></span></div>';
                }
                return '<span/>';
            }
        }
    ]
};

export const caseEditingRedirect = {
    redirectPageDefaultValue: '',
    redirectPageClaimIndicator: 'claim_indicator',
    redirectPageDemandClaims: 'demand_claims',
    claimIndicatorMenuId: 131,
    demandClaimMenuId: 133,
    menuName: 'Billing',
    menuId: 26,
    defaultR6ParentId : 0,
};

export const adminBillingAuditLogFeatures = {
    magnusPlat604AuditLogCreateTheAddUpdateDeleteControllersForPaymentActions: "magnusPlat604AuditLogCreateTheAddUpdateDeleteControllersForPaymentActions"
};

export const providerBillingSettingsFeatures = {
    magnusB28238ProviderBillingSettingsPane: "magnusB28238ProviderBillingSettingsPane",    
    rsiB31057ProviderClaimsSettingsPane: "rsiB31057ProviderClaimHoldsCardLayout",
};

export const BillingAuditLogPagesFeature = {
    rsiB32441BatchUpdatesExtendDosSearchLength: "rsiB32441BatchUpdatesExtendDosSearchLength"
};

export const UnassignedPaymentFeatures = {
    magnusPlat713AddBatchNumToUnassignedPaymentsPage: "magnusPlat713AddBatchNumToUnassignedPaymentsPage",
};

export const ChargesBillingAuditLogEpicsFeature = {
    magnusPlat1731BillingCharges: "magnusPlat1731BillingCharges",
};

export const BatchClaimsFeatures = {
    projectFlag: "leviathanB34935NonProdFormatTesting"
}

export const ExpectedRateMultipleFeeSchedule = {
    sumajB30566ExpectedRateMultipleFeeSchedules: "sumajB30566ExpectedRateMultipleFeeSchedules"
}

export const MonthEndScheduleFeature = {
    sumajB33855MonthEndClosePerformance: 'sumajB33855MonthEndClosePerformance'
}

export const ProviderCredentialsFF = {
    page_name: 'insurance_class',
    column_def_ff_columns: ["provider_credentials_required"]
}

export const edit_permission_page_list = ["Edit Referring Physicians", "Edit Diagnoses", "Edit Procedure Codes", "Add/Edit Revenue Centers"];
export const edit_permission_page_names = ["referring_physician", "diagnosis_code", "procedure_code", "revenue_center"];
export const new_supress_page_list = ["insurance_class", "referring_physician", "diagnosis_code", "procedure_code"];

export const revenue_center = {
    id: 'revenue_center_id',
    search_header: 'Revenue Centers',
    add_header: 'Revenue Center',
    edit_header: 'Revenue Center',
    base_url: '/charge',
    create_button_label: 'New',
    show_print_on_search: true,
    show_export_on_search: true,
    always_enable_save:true,
    entity_type_id: PrintExportConstants.EntityTypeId.RevenueCenters,
    control_data: [
        {
            control_type: 'text',
            label: 'Revenue Center Code',
            name: 'revenue_center_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_search: true,
            is_add: true,
            is_edit: true,
            row: 1,
            max_length: 2,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 4,
            pattern: '[0-9]*'
        },
        {
            control_type: 'text',
            label: 'Revenue Center Description',
            name: 'revenue_center_desc',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_search: true,
            is_add: true,
            is_edit: true,
            row: 1,
            max_length: 50,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 6
        },
        {
            control_type: 'checkbox',
            label: 'Include Inactive',
            name: 'include_inactive',
            default_value: false,
            master_data: [], // In case of dropdown
            custom_style: { marginTop: 22 },
            is_search: true,           
            row: 1,
            search_container_tablet: 7,
            search_container_computer: 4 
        },       
        {
            control_type: 'checkbox',
            label: 'Count Visit',
            name: 'count_visit',
            default_value: false,
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 2,
            view_container_tablet: 5,
            view_container_computer: 4,
            always_enable: true
        },
        {
            control_type: 'checkbox',
            label: 'Allow CPT Edit',
            name: 'allow_cpt_edit_flag',
            default_value: false,
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 2,
            view_container_tablet: 6,
            view_container_computer: 3,
            always_enable: true
        },
        {
            control_type: 'checkbox',
            label: 'Is Active',
            name: 'is_active',
            default_value: true,
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 2,
            view_container_tablet: 5,
            view_container_computer: 3,
            always_enable: true
        }
    ],
    api: {
        add: {
            type: 'POST',
            url: '/constant/revenue_center'
        },
        search: {
            type: 'GET',
            url:`/constant/revenue_center/search?revenue_center_code=revenue_center_code_value&revenue_center_desc=revenue_center_desc_value&include_inactive=include_inactive_value&page_Size=${global_constants.constants.PAGE_SIZE}`
        },
        update: {
            type: 'PUT',
            url: '/constant/revenue_center/id_value'
        },
        get: {
            type: 'GET',
            url: '/constant/revenue_center/id_value'
        }
    },
    column_def: [
        {
            headerName: 'Revenue Center Code',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'revenue_center_code',
            filter: 'textFilter',
            minWidth: 300,
            width: 300,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Revenue Center Description',
            headerComponentParams: header_template,
            field: 'revenue_center_desc',
            filter: 'textFilter',
            minWidth: 250,
            width: 700,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Revenue Center ID',
            headerComponentParams: header_template,
            field: 'revenue_center_id',
            filter: 'textFilter',
            minWidth: 100,
            width: 120,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Count Visit',
            headerComponentParams: header_template,
            field: 'count_visit',
            filter: 'checklistFilter',
            minWidth: 100,
            width: 120,
            type: 'string',
            unSortIcon: true,
            cellStyle: { textAlign: 'center' },
            cellRenderer: (params) => {
                if (params.data.count_visit) {
                  return`<i tabindex="0" aria-hidden="true" class="checkmark icon"></i>`;
                } else {
                  return;
                }
            }            
        },
        {
            headerName: 'Allow CPT Edit',
            headerComponentParams: header_template,
            field: 'allow_cpt_edit_flag',
            filter: 'checklistFilter',
            minWidth: 100,
            width: 120,
            type: 'string',
            unSortIcon: true,
            cellStyle: { textAlign: 'center' },
            cellRenderer: (params) => {
                if (params.data.allow_cpt_edit_flag) {
                  return `<i tabindex="0" aria-hidden="true" class="checkmark icon"></i>`;
                } else {
                  return;
                }
            }
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'is_active',
            filter: 'checklistFilter',
            minWidth: 120,
            width: 120,
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true
        }
    ],
    validation_message: {
        error: '',
        save: 'Revenue Center Added.',
        update: 'Revenue Center Updated.',
        warning: ''
    },
};

export const status_group_code = {
    id: 'e277_status_group_code',
   // id: 'status_group_code_id',
    search_header: 'Status Group Code',
    add_header: 'Status Group Code',
    edit_header: 'Status Group Code',
    base_url: '/billing',
    create_button_label: 'New',
    show_print_on_search: true,
    show_export_on_search:false,
    always_enable_save: true,
    control_data: [
        {
            control_type: 'text',
            label: 'Group Code',
            name: 'group_code',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_search: true,
            is_add: true,
            is_edit: true,
            row: 1,
            max_length: 50,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 4,
            pattern: '[0-9]*'
        },
        {
            control_type: 'textarea',
            label: 'Description',
            name: 'description',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: false,
            is_search: false,
            is_add: true,
            is_edit: true,
            row: 1,
            max_length: 255,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 6,
            disable_enter:true
        },
        {
            control_type: 'text',
            label: 'Description',
            name: 'description',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: false,
            is_search: true,
            is_add: false,
            is_edit: false,
            row: 1,
            max_length: 255,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 6
        },
        {
            control_type: 'checkbox',
            label: 'Include Inactive',
            name: 'include_inactive',
            default_value: false,
            master_data: [], // In case of dropdown
            custom_style: { marginTop: 22 },
            is_search: true,
            row: 1,
            search_container_tablet: 7,
            search_container_computer: 4
        },
        
        {
            control_type: 'checkbox',
            label: 'Is Active',
            name: 'is_active',
            default_value: true,
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 2,
            view_container_tablet: 5,
            view_container_computer: 3,
            always_enable: true
        }
    ],
    api: {
        add: {
            type: 'POST',
            url: '/e277_status_group'
        },
        search: {
            type: 'GET',
            url: `/e277_status_group/search?name=group_code_value&description=description_value&include_inactive=include_inactive_value&page_size=${global_constants.constants.PAGE_SIZE}`
        },
        update: {
            type: 'PUT',
            url: '/e277_status_group/id_value'
        },
        get: {
            type: 'GET',
            url: '/e277_status_group/id_value'
        }
    },
    column_def: [
        {
            headerName: 'Group Code',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'group_code',
            filter: 'textFilter',
            minWidth: 300,
            width: 300,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Description',
            headerComponentParams: header_template,
            field: 'description',
            filter: 'textFilter',
            minWidth: 250,
            width: 700,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },        
       
        {
            headerName: 'Active',
            headerComponentParams: headerStatusTemplate,
            field: 'is_active',
            filter: 'checklistFilter',
            minWidth: 100,
            width: 120,
            type: 'string',
            unSortIcon: true,
            cellStyle: { textAlign: 'center' },
            cellRenderer: (params) => {
                if (params.data.is_active) {
                    return `<i tabindex="0" aria-hidden="true"  class="checkmark icon"></i>`;
                } else {
                    return;
                }
            }
        }
    ],
    validation_message: {
        error: 'The Group Code could not be saved.',
        save: 'The Group Code was saved.',
        update: 'The Group Code was saved.',
        warning: ''
    },
};

export const status_category = {
    id: 'e277_status_category',
   // id: 'status_category_id',
    search_header: 'Status Category',
    add_header: 'Status Category',
    edit_header: 'Status Category',
    base_url: '/billing',
    create_button_label: 'New',
    show_print_on_search: true,
    show_export_on_search:false,
    always_enable_save: true,
    control_data: [
        {
            control_type: 'text',
            label: 'Category',
            name: 'status_cat',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_search: true,
            is_add: true,
            is_edit: true,
            row: 1,
            max_length: 50,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 4,
            pattern: '[0-9]*'
        },
        {
            control_type: 'textarea',
            label: 'Description',
            name: 'description',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: false,
            is_search: false,
            disable_enter: true,
            is_add: true,
            is_edit: true,
            row: 1,
            max_length: 255,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 6
        },
        {
            control_type: 'text',
            label: 'Description',
            name: 'description',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: false,
            is_search: true,
            is_add: false,
            is_edit: false,
            row: 1,
            max_length: 255,
            search_container_tablet: 7,
            search_container_computer: 6,
            view_container_tablet: 8,
            view_container_computer: 6
        },
        {
            control_type: 'checkbox',
            label: 'Include Inactive',
            name: 'include_inactive',
            default_value: false,
            master_data: [], // In case of dropdown
            custom_style: { marginTop: 22 },
            is_search: true,
            row: 1,
            search_container_tablet: 7,
            search_container_computer: 4
        },
        
        {
            control_type: 'checkbox',
            label: 'Is Active',
            name: 'is_active',
            default_value: true,
            master_data: [], // In case of dropdown
            is_add: true,
            is_edit: true,
            row: 2,
            view_container_tablet: 5,
            view_container_computer: 3,
            always_enable: true
        }
    ],
    api: {
        add: {
            type: 'POST',
            url: '/e277_status_cat'
        },
        search: {
            type: 'GET',
            url: `/e277_status_cat/search?status_cat=status_cat_value&description=description_value&include_inactive=include_inactive_value&page_size=${global_constants.constants.PAGE_SIZE}`
        },
        update: {
            type: 'PUT',
            url: '/e277_status_cat/id_value'
        },
        get: {
            type: 'GET',
            url: '/e277_status_cat/id_value'
        }
    },
    column_def: [
        {
            headerName: 'Category',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'status_cat',
            filter: 'textFilter',
            minWidth: 300,
            width: 300,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Description',
            headerComponentParams: header_template,
            field: 'description',
            filter: 'textFilter',
            minWidth: 250,
            width: 700,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },        
       
        {
            headerName: 'Active',
            headerComponentParams: headerStatusTemplate,
            field: 'is_active',
            filter: 'checklistFilter',
            minWidth: 100,
            width: 120,
            type: 'string',
            unSortIcon: true,
            cellStyle: { textAlign: 'center' },
            cellRenderer: (params) => {
                if (params.data.is_active) {
                    return `<i tabindex="0" aria-hidden="true" class="checkmark icon"></i>`;
                } else {
                    return;
                }
            }
        }
    ],
    validation_message: {
        error: 'The Status Category could not be saved.',
        save: 'The Status Category was saved.',
        update: 'The Status Category was saved.',
        warning: ''
    }
};

export const manage_constant = {
    validation_message: {
      error_company: `Please select Copy Constants From and Copy Constants To.`,
      warning_msg: 'The selected company already contains constants. Proceed with caution.',
      same_company: `You can't copy the constants to the same company, please try again!!`,
      selection_error: 'No record selected to copy.',
      error_msg: 'The constants failed to copy to the selected company. Contact support if the issue persists.'
    },
    sub: {
      save_button_text: 'Save Constants',
      info_text:
        'The system will copy the selected tables. Please hit button "Copy Selected Table Constants" when you are ready.',
      sucess_msg: 'The constants have been copied successfully.'
    },
    all: {
      save_button_text: 'Copy All Constants',
      info_text: 'This will copy all constants to the selected company. Click "Copy All Constants" to proceed.',
      sucess_msg: 'The constants have been copied successfully.'
    },
    selected: {
      save_button_text: 'Copy Selected Constants',
      info_text:
        'This will copy all selected constants to the selected company. Click "Copy Selected Constants" to proceed.',
      sucess_msg: 'The constants have been copied successfully.'
    },
    source_column_def: [
      {
        headerName: 'Constant Description',
        field: 'value',
        width: 400,
      },
      {
        headerName: 'Revenue Center Code',
        field: 'key',
        width: 400,
      }
    ],
    destination_column_def: [
      {
        headerName: '',
        field: 'checkbox',
        width: 35
      },
      {
        headerName: 'Constant Description',
        field: 'value',
        width: 400,
      },
      {
        headerName: 'Revenue Center Code',
        field: 'key',
        width: 400,
      }
    ],
    manage_constant_type: {
        procedureCodeReplacement: 14,
        enhancedPayerRule: 15,
    },
    manage_constant_table_name: {
        enhanced_payer_rule: 'enhanced_payer_rule',
    },
    copy_alert_settings: {
        title:"Unsaved Procedure Code Replacement",
        message: "All constants copied successfully except the following",
        constantName: "Procedure Code Replacement rules",
        yesButtonLabel:"OK"

    }
};

export const status_code = {
    id: 'status_code_id',
    search_header: 'Status Code',
    add_header: 'Status Code',
    edit_header: 'Status Code',
    base_url: '/billing',
    create_button_label: 'New',
    group_code_header_id: 'group_code_header_id',
    payerid_header_id: 'payerid_header_id',
    show_print_on_search: true,
    show_export_on_search: false,
    always_enable_save: true,
    group_code_inactive: {
        type: "group_code",
        grid_config: {
            rows: null,
            column: status_group_code.column_def
        },
        control_id: 'GROUP_CODE_INACTIVE',
        error_message: 'No Record Found !',
        label: ["name", "description"],
        selected_row: null,
    },
    group_code: {
        type: "group_code",
        grid_config: {
            rows: null,
            column: status_group_code.column_def
        },
        control_id: 'GROUP_CODE',
        error_message: 'No Record Found !',
        label: ["name", "description"],
        selected_row: null,
    },
    payer: {
        type: "payer",
        grid_config: {
            rows: null,
            column: insurance.payerid_column_defs
        },
        control_id: 'PAYER_DESCRIPTION',
        error_message: 'No Record Found !',
        label: ["name", "description"],
        selected_row: null,
    },
    api: {
        add: {
            type: 'POST',
            url: '/status_code'
        },
        search: {
            type: 'GET',
            url: `/status_code/search?code=code_value&group_id=group_id_value&payer_id=payer_id_value&include_inactive=include_inactive_value&description=description_value&page_size=${global_constants.constants.PAGE_SIZE}`
        },
        update: {
            type: 'PUT',
            url: '/status_code/id_value'
        },
        get: {
            type: 'GET',
            url: '/status_code/id_value'
        }
    },
    column_def: [
        {
            headerName: 'Code',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'code',
            filter: 'textFilter',
            minWidth: 300,
            width: 300,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Description',
            headerComponentParams: header_template,
            field: 'description',
            filter: 'textFilter',
            minWidth: 250,
            width: 700,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Group Code',
            headerComponentParams: header_template,
            field: 'group_name',
            filter: 'textFilter',
            minWidth: 150,
            width: 150,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Payer',
            headerComponentParams: header_template,
            field: 'payer_name',
            filter: 'textFilter',
            minWidth: 150,
            width: 150,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Active',
            headerComponentParams: headerStatusTemplate,
            field: 'is_active',
            filter: 'checklistFilter',
            minWidth: 100,
            width: 120,
            type: 'string',
            unSortIcon: true,
            cellStyle: { textAlign: 'center' },
            cellRenderer: params => {
                return `<div style="text-align:center">${params.value ? '<i class="checkmark icon"/>' : ''}</div>`;
            }
        }
    ],
    validation_message: {
        error: 'The Code could not be saved.',
        save: 'The Code was saved.',
        update: 'The Code was saved.',
        warning: ''
    },
    group_code_column_defs: [
        {
            headerName: 'Group Code',
            field: 'name',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 120,
            unSortIcon: true,
            headerComponentParams: get_header_template_with_navigation('group_code_header_id')
        },
        {
            headerName: 'Description',
            field: 'description',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 375,
            unSortIcon: true,
            headerComponentParams: header_template
        }
    ]
};

//Add Edit Payer Rules
export const add_edit_payer_rules = {
    id: 'add_edit_payer_rules_id',
    search_header: 'Add/Edit Payer Rules',
    add_header: 'Add/Edit Payer Rules',
    edit_header: 'Add/Edit Payer Rules',
    base_url: '/payments',
    grid_header_height: 55,
    api: {
        add: {
            type: 'POST',
            url: '/constant/payerrule'
        },
        search: {
            type: 'GET',
            url: '/constant/payerrule/search?payer_id=payer_id_value&description=description_value&insurance_class_id=insurance_class_id_value&payment_code_id=payment_code_id_value&adjustment_code_id=adjustment_code_id_value&page_Size=5000'
        },
        update: {
            type: 'PUT',
            url: '/constant/payerrule/id_value'
        },
        get: {
            type: 'GET',
            url: '/constant/payerrule/id_value'
        },
        payers: {
            type: 'GET',
            url: '/constant/payerrule/payer'
        }       
    },

    column_def: [
        {
            headerName: 'Payer',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'payer_name',
            minWidth: 135,
            width: 170,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Description',
            headerComponentParams: header_template,
            field: 'description',
            minWidth: 160,
            width: 210,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Insurance Class',
            headerComponentParams: header_template,
            field: 'insurance_class_description',
            minWidth: 160,
            width: 210,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Payment Code',
            headerComponentParams: header_template,
            field: 'payment_code_description',
            minWidth: 160,
            width: 210,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Adjustment Code',
            headerComponentParams: header_template,
            field: 'adjustment_code_description',
            minWidth: 170,
            width: 210,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Transfer Back to Payer',
            headerComponentParams: header_template,
            field: 'transfer_to_payer',
            filter: 'checklistFilter',
            minWidth: 165,
            width: 210,
            type: 'boolean',
            unSortIcon: true,
            cellStyle: { textAlign: 'center' },
            cellRenderer: params => {
                var eDiv = document.createElement('div');
                eDiv.innerHTML = `<input type='checkbox' ${params.value ? 'checked' : ''} disabled/>`;
                return eDiv;
            }
        },
        {
            headerName: 'Transfer to Next Payer',
            headerComponentParams: header_template,
            field: 'transfer_to_next_payer',
            filter: 'checklistFilter',
            minWidth: 165,
            width: 210,
            type: 'boolean',
            unSortIcon: true,
            cellStyle: { textAlign: 'center' },
            cellRenderer: params => {
                var eDiv = document.createElement('div');
                eDiv.innerHTML = `<input type='checkbox' ${params.value ? 'checked' : ''} disabled/>`;
                return eDiv;
            }
        },
        {
            headerName: 'Do Not Transfer to Patient',
            headerComponentParams: header_template,
            field: 'not_transfer_to_patient',
            filter: 'checklistFilter',
            minWidth: 165,
            width: 210,
            type: 'boolean',
            unSortIcon: true,
            cellStyle: { textAlign: 'center' },
            cellRenderer: params => {
                var eDiv = document.createElement('div');
                eDiv.innerHTML = `<input type='checkbox' ${params.value ? 'checked' : ''} disabled/>`;
                return eDiv;
            }
        }
    ],

    validation_message: {
        error: '',
        save: 'The Payer Rule was saved.',
        update: 'The Payer Rule was saved.',
        failure: 'The Payer Rule could not be saved.',
        error_transfer: 'One of the following must be selected.<ul><li>Transfer Back to Payer</li><li>Transfer to Next Payer</li><li>Do Not Transfer to Patient</li></ul>',
        warning: ''
    }
};

//Failed charge rule
export const failed_charge_rule = {
    id: 'failed_charge_rule_id',
    header: 'Failed Charge Rules',
    search_header: 'Failed Charge Rules',
    base_url: '/billing',
    create_button_label: 'New',
    show_print_export: true,
    container_heading: ['Failed Charge Rule Information'],
    column_def: [
        {
            headerName: 'Failure Reason Code',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'reason_code',
            filter: 'numberFilter',
            minWidth: 200,
            width: 200,
            type: 'number',
            unSortIcon: true,
            comparator: number_comparator
        },
        {
            headerName: 'Failure Reason Short Description',
            headerComponentParams: header_template,
            field: 'reason_short_description',
            filter: 'textFilter',
            minWidth: 210,
            width: 700,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Failure Reason Long Description',
            headerComponentParams: header_template,
            field: 'reason_long_description',
            filter: 'textFilter',
            minWidth: 210,
            width: 700,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Status',
            headerComponentParams: headerStatusTemplate,
            field: 'is_active',
            filter: 'checklistFilter',
            minWidth: 120,
            width: 150,
            type: 'boolean',
            cellStyle: statusStyle,
            unSortIcon: true
        }
    ],
    control_data: [
        {
            control_type: 'text',
            label: 'Failure Reason Code',
            name: 'reason_code',
            form_name: 'failed_charge_rule',
            default_value: '',
            is_required: false,
            is_edit: true,
            is_add: false,
            is_hidden:true,
            non_editable:true,
            row: 1,
            max_length: 100,
            view_container_tablet: 8,
            view_container_computer: 4,
            container: 1,
        },
        {
            control_type: 'text',
            label: 'Failure Reason Short Description',
            name: 'reason_short_description',
            form_name: 'failed_charge_rule',
            focus: true,
            default_value: '',
            is_required: true,
            is_edit: true,
            is_add: true,
            row: 1,
            max_length: 100,
            view_container_tablet: 8,
            view_container_computer: 4,
            container: 1,
        },
        {
            control_type: 'textarea',
            label: 'Failure Reason Long Description',
            name: 'reason_long_description',
            form_name: 'failed_charge_rule',
            default_value: '',
            master_data: [], // In case of dropdown
            is_required: true,
            is_edit: true,
            is_add: true,
            disable_enter: false,
            row: 1,
            max_length: 250,
            view_container_tablet: 8,
            view_container_computer: 8,
            container: 1,
        },
        {
            control_type: 'checkbox',
            label: 'Is Active',
            name: 'active',
            form_name: 'failed_charge_rule',
            default_value: true,
            master_data: [], // In case of dropdown
            is_edit: true,
            is_add: true,
            row: 1,
            view_container_tablet: 8,
            view_container_computer: 4,
            container: 1,
            custom_style: { marginTop: 32 }
        }],
    api: {
        get: {
            type: 'GET',
            url: (id:Number) => `/constant/failed_charge_rules/${id}`
        },
        search: {
            type: 'GET',
            url:
                '/constant/failed_charge_rules/search?failure_reason_code=failure_reason_code_value&failure_reason_short_description=failure_reason_short_description_value&show_inactive=show_inactive_value&Page_size=800'
        },
        add: {
            type: 'POST',
            url: '/constant/failed_charge_rules'
        },
        update: {
            type: 'PUT',
            url: (id:Number) => `/constant/failed_charge_rules/${id}`
        }
    },
    validation_message: {
        error: '',
        save: 'Failed Charge Rule Added.',
        update: 'Failed Charge Rule Updated.',
        warning: ''
    },
    prompt_message: {
        add_new: 'This action will cause any unsaved data to be lost. Continue?',
    }
}

export const demand_claims = {
    id: 'demand_claims_id',
    base_url: '/billing',
    search_header: 'Demand Claims',
    grid_header_height: 55,
    mode_types:[
        {
            value:"DEMAND", 
            text:"Demand"
        },
        {
            value:"BATCH", 
            text:"Batch"
        }
    ],
    bill_types: [
        {
            value:"0", 
            text:"Primary"
        },
        {
            value:"1", 
            text:"Secondary"
        },
        {
            value:"2", 
            text:"Tertiary"
        }
    ],
    api: {
        search: {
            type: 'GET',
            url: '/demand_claims/search'
        },
        format_list: {
            type: 'GET',
            url: '/demand_claims/format_list'
        },
        batch_claims: {
            type: 'PUT',
            url: '/demand_claims/batch_claims'
        },
        format_form_list: {
            type: 'GET',
            url: '/demand_claims/format_form_list'
        }
    },
    column_def: [
        {
            headerComponentParams: header_template,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            width: 54,
            maxWidth: 54,
            field:"checkbox",
            suppressFilter: true,
            suppressSorting: true,
            suppressSizeToFit: true,
            cellRenderer: params => {
                if (params.data && params.data.status_desc && params.data.status_desc != "Ready") {
                    return `<div><input style="height: 15px; width: 15px;" type='checkbox' disabled/></div>`;
                }
                
                if (params.data && params.data.checkbox== "Total") {
                    return `<div>Total</div>`;
                }
            }
        },
        {
            headerName: 'Charge ID',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'charge_id',
            minWidth: 135,
            width: 170,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Patient ID',
            headerComponentParams: header_template,
            field: 'patient_id',
            minWidth: 135,
            width: 170,
            filter: 'textFilter',
            type: 'static',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Date of Service',
            headerComponentParams: header_template,
            field: 'date_of_service',
            minWidth: 160,
            width: 210,
            filter: 'dateTimeFilter',
            type: 'date',
            unSortIcon: true,
            comparator: mm_dd_yyyy_h_mm_ss_tt_comparator
        },
        {
            headerName: 'CPT Code',
            headerComponentParams: header_template,
            field: 'proc_code',
            minWidth: 135,
            width: 170,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Charge Amount',
            headerComponentParams: headerCurrencyTemplate,
            field: 'total_chg_amt',
            minWidth: 190,
            width: 190,
            filter: 'currencyFilter',
            type: 'currency',
            unSortIcon: true,
            comparator: currency_comparator,
            cellStyle: { textAlign: 'right' }
        },
        {
            headerName: 'Balance',
            headerComponentParams: headerCurrencyTemplate,
            field: 'owed_amt',
            minWidth: 150,
            width: 150,
            filter: 'currencyFilter',
            type: 'currency',
            unSortIcon: true,
            comparator: currency_comparator,
            cellStyle: { textAlign: 'right' }
        },
        {
            headerName: 'Last Billed',
            headerComponentParams: header_template,
            field: 'last_billed_date',
            minWidth: 170,
            width: 210,
            filter: 'dateTimeFilter',
            type: 'date',
            unSortIcon: true,
            comparator: mm_dd_yyyy_h_mm_ss_tt_comparator
        },
        {
            headerName: 'Charge Status',
            headerComponentParams: header_template,
            field: 'status_desc',
            minWidth: 170,
            width: 210,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Payer Group',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'payer_group',
            minWidth: 135,
            width: 170,
            filter: 'textFilter',
            type: 'static',
            unSortIcon: true,
            comparator: text_comparator
        },
    ],
    validation_message: {
        error: '',
        error_transfer: 'One of the following must be selected.<ul><li>Transfer Back to Payer</li><li>Transfer to Next Payer</li><li>Do Not Transfer to Patient</li></ul>',
        warning: ''
    }
};

export const roles_permission_set_controls = {
  permission_sets: [
    {
      label: 'Permission Set Name',
      id: 'name',
      name: 'name',
      type: 'text',
      maxLength: 100,
      default_value: '',
      is_required: true
    },
    {
      label: 'Description',
      id: 'description',
      name: 'description',
      type: 'text',
      maxLength: 100,
      default_value: '',
      is_required: true
    },
    {
      label: 'Required User Type',
      id: 'user_type',
      name: 'user_type',
      type: 'select',
      default_value: '',
      is_required: true
    }
  ],
  roles: [
    {
      label: 'Role Name',
      id: 'name',
      name: 'name',
      type: 'text',
      maxLength: 100,
      default_value: '',
      is_required: true
    },
    {
      label: 'Description',
      id: 'description',
      name: 'description',
      type: 'text',
      maxLength: 100,
      default_value: '',
      is_required: true
    },
    {
        label: 'R2 Role',
        id: 'r2_role_id',
        name: 'r2_role_id',
        type: 'select',
        default_value: '',
        is_required: true
    },
    {
      label: 'User Type',
      id: 'user_type',
      name: 'user_type',
      type: 'select',
      default_value: '',
      is_required: true
    },
    {
      label: 'Service Type',
      id: 'service_type',
      name: 'service_type',
      type: 'select',
      default_value: '',
      is_required: true
    }
  ]
};

export const service_type_fields ={
    label: 'Service Type',
    id: 'service_type',
    name: 'service_type',
    type: 'select',
    default_value: '',
    is_required: true
  }

export const user_bulk_upload = {
    id: 'user_bulk_upload',
    base_url: '/admin',
    search_header: 'User Bulk Upload',
    grid_header_height: 35,
    column_def: [
        {
            headerName: 'Row',
            headerComponentParams: header_template_with_navigation(search_grid_id),
            field: 'rowId',
            minWidth: 135,
            width: 170,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Error',
            headerComponentParams: header_template,
            field: 'error',
            minWidth: 135,
            width: 170,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Validation',
            headerComponentParams: header_template,
            field: 'validation',
            minWidth: 135,
            width: 170,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        }
    ],
    message: {
        error: 'Oof! An error occurred and no users were uploaded.',
        warning: '',
        success: X => {
            if (X == 1) {
                return `Sweet! ${X} user was uploaded successfully.`
            }

            return `Sweet! ${X} users were uploaded successfully.`
        },
    }
};

export const roles_permission_set_data = {
    extended_table_controls: {
        permission_sets: [{
            headerName: 'Name',
            headerComponentParams: header_template,
            field: 'name',
            minWidth: 135,
            width: 170,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Description',
            headerComponentParams: header_template,
            field: 'description',
            minWidth: 135,
            width: 170,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        }],
        permissions: [{
            headerName: 'Name',
            headerComponentParams: header_template,
            field: 'name',
            minWidth: 135,
            width: 170,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Description',
            headerComponentParams: header_template,
            field: 'menu_description',
            minWidth: 135,
            width: 170,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        }],
        roles:[{
            headerName: 'Name',
            headerComponentParams: header_template,
            field: 'name',
            minWidth: 170,
            width: 170,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'User Type',
            headerComponentParams: header_template,
            field: 'required_user_level',
            minWidth: 170,
            width: 170,
            filter: 'textFilter',
            type: 'user_type',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Service Type',
            headerComponentParams: header_template,
            field: 'service_type',
            minWidth: 170,
            width: 170,
            filter: 'textFilter',
            type: 'service_type',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
            headerName: 'Description',
            headerComponentParams: header_template,
            field: 'description',
            minWidth: 180,
            width: 180,
            filter: 'textFilter',
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        }],
    },
    permission_sets:{
        url: get_permission_set,
        params:'permission_set_id',
        extended_params: 'permission_id',
        permission_tab_text:'Permission Sets',
        text_mapping_key: 'permission_list',
        remove_url: update_permission_set_status,
        extended_text_controls:[{
            label: 'Permission Name',
            id: 'name'
          },
          {
            label: 'Description',
            id: 'menu_description',
          }]
    },
    roles: {
        url: get_role,
        params:'role_id',
        extended_params: 'ps_id',
        permission_tab_text:'Permissions',
        text_mapping_key: 'e_ps_list',
        remove_url: update_role_status,
        extended_text_controls:[{
            label: 'Permission Set Name',
            id: 'name'
          },
          {
            label: 'Description',
            id: 'description',
        }]
    }
}

//add edit enhanced payer rules
let enhanced_payer_rule_cell_style = {
    'background': '#8494a6',
    'color': '#f7f7f7',    
};

export const payer_rule_summary = {
    component_path: 'payer_rule',
    session_key: 'payer_rule_summary_sesion',
    page_name: 'Payer Rules',
    search_button_text: 'Search',
    clear_button_text: 'Clear',
    parent_company_dd_name: 'Parent Company',
    user_company_dd_name: 'Company',
    export_button_text: 'Export',
    print_button_text: 'Print',
    grid_id: 'id_grid_enhanced_add_edit_payer_rules',
    payer_rule_summary_configuration_header_id: "payer_rule_summary_configuration_header_id",
    update_button_text: 'Remove',
    updated_charge_summary_checkbox_id: "payer_rule_summary_checkbox_id",
    remove_modal_title: "Remove Charge Updates",
    remove_modal_message: "An Account Note will not be added to the patient's chart. Do you want to continue?",
    remove_modal_ok_text: "Yes",
    remove_modal_cancel_text: "No",
    remove_successful_text: "The selected entries were removed.",
    remove_error_text: "One or more entries could not be removed. Try again.",
    empty_error: "Please select at least one record to remove.",
    no_records_found_message: 'No Record Available',
    detail_field_name: "insurance_details",
    search_header: 'Add/Edit Payer Rules',
    primary_payer_rule: 'Primary',
    secondary_payer_rule: 'Secondary',
    tertiary_payer_rule: 'Tertiary', 
    primary_id: 'Primary_id',
    secondary_id: 'Secondary_id',
    tertiary_id: 'Tertiary_id',
    subtable_type: 'group',
    successfully_saved_message: 'The Payer Rule was saved.',
    required_fields_message:'Unable to save. Please review the required fields.',
    tab_pri: 0,
    tab_sec: 1,
    tab_ter: 2,
    rd_post: 'pst_',
    rd_skip: 'skp_',
    rd_current: 'kct_',
    rd_next: 'ttn_',
    rd_keep: 'kep_',
    rd_adjust: 'adj_',
    pay_code: 'paymentCodeId_',
    adj_code: 'adjCodeId_',
    pat_adj_code: 'patAdjCodeId_',
    api: {
        inbounds: {
            type: 'GET',
            url: '/constant/payerrule/inbound'
        },
        search: {
            type: 'GET',
            url: '/constant/payerrule/search_enhanced?inbound_id=inbound_id_value&description=description_value&payer_id=payer_id_value&payment_code_id=payment_code_id_value&adjustment_code_id=adjustment_code_id_value&page_Size=5000'
        },
        enhanced_inbound_per_tab: {
            type: 'GET',
            url: '/constant/payerrule/enhanced_inbound_per_tab'
        },
        payer_rule_per_tab: {
            type: 'GET',
            url: '/constant/payerrule/payercode_per_tab?inbound_id='
        },
        save_payer_rule: {
            type: 'POST',
            url: '/constant/payerrule/savepayerrule'
        },
        update_payer_rule: {
            type: 'PUT',
            url: '/constant/payerrule/updatepayerrule'
		},
		get_eob_key_list_matching_rule_error: {
			type: 'GET',
			url: '/constant/eobrelease/getEobKeyListMatchingRuleError?inbound_id='
		}
    },
    inner_cols: [
        {
            headerName: 'Payer Level',
            field: 'payerLevel',
            align: 'left'
        },
        {
            headerName: 'Payment Code',
            field: 'paymentCode',
            align: 'left'
        },
        {
            headerName: 'Adjustment Code',
            field: 'adjCode',
            align: 'left'
        },
        {
            headerName: 'Adjustments',
            field: 'insAdjustmentsDescription',
            align: 'left'
        },
        {

            headerName: 'Balances',
            field: 'insBalancesDescription',
            align: 'left'
        },
        {

            headerName: 'Patient Balances',
            field: 'patBalancesDescription',
            align: 'left'
        },
        {

            headerName: 'Patient Adj. Code',
            field: 'patAdjCode',
            align: 'left'
        }
    ],
    column_def: [
        {            
            headerName: '',            
            field: 'group',            
            type: 'string',
            unSortIcon: true,
            maxWidth: 40,
            width: 40,
            suppressSorting: true,
            suppressFilter: true,
            resizable: false,           
            cellStyle: enhanced_payer_rule_cell_style,           
            cellRendererParams: {
                innerRenderer: params => {
                    if (!params.node.group && !params.node.rowPinned && params.data) {
                        let eDiv = document.createElement('div');
                        eDiv.innerHTML = payer_rule_summary_details_template(params.data, params.column.actualWidth);                        
                        return eDiv.firstChild;
                    }
                }                
            },
            colSpan: function (params) {
                if (!params.node.group && !params.node.rowPinned) {
                    return params.columnApi.getAllColumns().length;
                }
                return 1;
            },
            cellRenderer: 'agGroupCellRenderer',
            valueGetter: function chainValueGetter(params) {
                return '';
            }

        },

        {
            headerName: 'Inbound ID',
            headerComponentParams: header_template,
            field: 'inboundId',
            filter: 'textFilter',
            type: 'string',
            cellStyle: enhanced_payer_rule_cell_style,     
            unSortIcon: true,
            resizable: true,
        },
        {
            headerName: 'Description',
            headerComponentParams: header_template,
            field: 'description',
            filter: 'textFilter',
            type: 'string',
            cellStyle: enhanced_payer_rule_cell_style,     
            unSortIcon: true,
            resizable: true,
        },
        {
            headerName: 'Payer Code',
            headerComponentParams: header_template,
            field: 'payerCode',
            filter: 'textFilter',
            type: 'string',
            cellStyle: enhanced_payer_rule_cell_style,     
            unSortIcon: true,
            resizable: true,
        }
    ],
    validation_message: {
        error: '',
        save: 'The Payer Rule was saved.',
        update: 'The Payer Rule was saved.',
        failure: 'The Payer Rule could not be saved.',
        error_transfer: 'One of the following must be selected.<ul><li>Transfer Back to Payer</li><li>Transfer to Next Payer</li><li>Do Not Transfer to Patient</li></ul>',
        warning: ''
    }
};

const payer_rule_summary_details_template = (data, grouped_column_width) => {
    return `<div class="grid_inner_table">
            <div class="table-responsive custom-default-table" style="min-width: 800px; background-color: #ffffff;">
            <div class="grid_result_enhanced_container">
                <table class="ui table grid_result_enhanced_table">
                    <thead>
                        <tr id="inner_table">
                            ${payer_rule_summary_render_header(data, grouped_column_width)}
                        </tr>
                    </thead>
                    <tbody>
                        ${payer_rule_summary_render_rows(data)}
                    </tbody>
                </table>
            </div>
            </div>
        </div>`;
};

export const payer_rule_summary_render_header = (data, grouped_column_width) => {    
    let nodes = `<th style="min-width:${grouped_column_width}px; border-bottom: 2px solid #00a7e1 !important;"></th>`;
    var total_len = data.child_column.length;
    data.child_column.map((item, index) => {
        if (total_len - 1 == index) {
            nodes = `
        ${nodes}  
        <th style="min-width:${100}px;text-align:${item.align}; border-bottom: 2px solid #00a7e1 !important;">${item.headerName}</th>
        `;
        } else {
            nodes = `
        ${nodes}
        <th style="max-width:${item.width}px;min-width:${item.width}px;width:${item.width}px;text-align:${item.align}; border-bottom: 2px solid #00a7e1 !important;">${item.headerName}</th>
        `;
        }
    });
    return nodes;
};

export const payer_rule_summary_render_rows = data => {    
    let nodes = '';
    data.details &&
        data.details.map(value => {
            nodes = `
            ${nodes}
            <tr>             
                <td style="border-right:0;">&nbsp;</td>                               
                <td onclick="set_selected_option(this.innerText)" style="color: #007097 !important; cursor: pointer;">${value.payerLevel || ''}</td>
                <td>${value.paymentCode || ''}</td>
                <td>${value.adjCode || ''}</td>
                <td>${value.insAdjustmentsDescription || ''}</td>
                <td>${value.insBalancesDescription || ''}</td>         
                <td>${value.patBalancesDescription || ''}</td>   
                <td>${value.patAdjCode || ''}</td>   
            </tr>`;
        });
    return nodes;
};

export const roles_ps_keys_to_delete = ['selected_list', 'user_type'];

export const roles_permissions_msg = {
    permissions_validation_msg : 'You must select at least one Permission.',
    role_add_msg: 'Role added successfully.',
    permission_set_add_msg: 'Permission Set added successfully.',
    role_update_msg: 'Role updated successfully.',
    permission_set_update_msg: 'Permission Set updated successfully.',
}

export const ProviderCredentials = {
    projectFlag: "goliathB35950ProviderCredentialingAndHolds"
}

export const render_do_not_accept_assignment = {
    argosBill1177NewInsuranceOptionDoNotAcceptAssignment: "argosBill1177NewInsuranceOptionDoNotAcceptAssignment"
};

export const get_header_checkbox_template = (field) => {
    return {
        template:
            '<div class="ag-cell-label-container checkbox" role="presentation">' +
            '<span class="ag-header-select-all grid-checkbox" role="presentation" ref="cbSelectAll">' +
            '<input type="checkbox" id="' + field + '">' +
            '<span class="ag-checkbox-checked ag-hidden" role="presentation" id="checked_' + field + '">' +
            '<span class="ag-icon ag-icon-checkbox-checked"></span>' +
            '</span>' +
            '<span class="ag-checkbox-unchecked" role="presentation" id="unchecked_' + field + '">' +
            '<span class="ag-icon ag-icon-checkbox-unchecked"></span>' +
            '</span>' +
            '<span class="ag-checkbox-indeterminate ag-hidden" role="presentation" id="indeterminate_' + field + '">' +
            '<span class="ag-icon ag-icon-checkbox-indeterminate"></span>' +
            '</span>' +
            '</span>' +
            '<i class="small filter icon filter-ipad filter-active-icon"></i>' +
            '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '<div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
            '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
            '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
            '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
            '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
            '</div>' +
            '</div>'
    }
}