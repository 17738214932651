import * as global_constants from '../../global_constants';
import { log_error } from '../../shared/action/shared_action';
import { currentDateTime, get_date } from '../../shared/calendar_utils';
import { mm_dd_yyyy_h_mm_ss_tt_comparator } from '../../shared/component/grid/comparators';
import { TEMPLATE_NO_RECORD } from '../../shared/component/grid/constants';
import { custom_date_format } from "../../shared/utility";
import * as patient_constants from '../patient_constants';
import { format_account_notes } from '../util/account_note_utility';
import { format_subscriber } from '../util/case_utility';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request';

export const get_patients = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })


    return dispatch =>
        request.make(request_config).then(
            response => dispatch({
                type: patient_constants.GET_PATIENTS,
                payload: response
            }),
            error => {
                log_error(error)
            }
        );
}

export const get_patientList = (token, company_id, key, page_size, is_patient_id:boolean = false) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.quick_search}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            keyword: key,
            pageSize: page_size,
            companyId: company_id,
            is_patient_id: is_patient_id
        }
    })
    return request.make(request_config);
}


export const get_patient_details = (token, patient_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}`;

    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return dispatch => request.make(request_config).then(
        response => dispatch({
            type: patient_constants.GET_PATIENT_DETAILS,
            payload: response
        }),
        error => {
            log_error(error)
        }
    );
}

export const add_patient = (token, patient) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': patient
    })

    return request.make(request_config);
}

export const edit_patient = (token, patient) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient.id}`;
   
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'put',
        'params': {
            'patient_id': patient.id
        },
        'data': patient
    })
    return request.make(request_config);
}

export const get_patient_medicare_cap = (token, patient_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${global_constants.end_points.patient_urls.medicare_caps}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return dispatch => request.make(request_config).then(
        response => dispatch({
            type: patient_constants.GET_PATIENT_MEDICARE_CAP,
            payload: response || {}
        }),
        error => {
            log_error(error)
        }
    );
}

export const add_update_patient_medicare_cap = (token, patient_id, data) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${global_constants.end_points.patient_urls.medicare_caps}`;
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }
    let request = new Request();
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': data
    })

    return dispatch => request.make(request_config).then(
        response => dispatch({
            type: patient_constants.ADD_UPDATE_PATIENT_MEDICARE_CAP,
            payload: response
        }),
        error => {
            log_error(error)
        }
    );
}

export const add_update_patient_flag = (token, patient, patient_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}/update_patient_flag`;
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }
    let request = new Request();

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': patient
    })

    return request.make(request_config);
}



// ********************************************************** Patient Account Notes Start **********************************************//
//GET Account notes 
export const get_patient_account_notes = (patient_id, token) => {

    // account notes
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${global_constants.end_points.patient_urls.account_notes}`;
    var sticky_code = patient_constants.sticky_code.Patient
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }
    let request = new Request();

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            sticky_code: sticky_code,
        }
    })

    return dispatch => request.make(request_config).then(

         response => dispatch({
             type: patient_constants.GET_PATIENT_ACCOUNT_NOTES,
             payload: (response.data && response.data.data) || []
                }),

        error => {
            dispatch({
                type: patient_constants.GET_PATIENT_ACCOUNT_NOTES,
                payload: []
            })
            log_error(error)
        }
    )
}

//GET Account task
export const get_patient_account_tasks = (patient_id, token) => {

    // account notes
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.task}${global_constants.end_points.patient_urls.patients}/${patient_id}`;
    var sticky_code = patient_constants.sticky_code.Patient
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }
    let request = new Request();

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
    })

    return dispatch => request.make(request_config).then(
         response => dispatch({
             type: patient_constants.GET_PATIENT_ACCOUNT_TASKS,
             payload: (response.data && response.data.data) ||[]
                }),
        error => {
            dispatch({
                type: patient_constants.GET_PATIENT_ACCOUNT_TASKS,
                payload: []
            })
            log_error(error)
        }
    )
}

// GET Account notes and task
export const set_patient_account_notes_and_task = (tasks,notes) => {
    var data = {
        task: tasks,
        account_notes: notes
    };
    return dispatch => dispatch({
        type: patient_constants.GET_PATIENT_TASK_AND_ACCOUNT_NOTES,
        payload: data
    });
}



//GET Account notes 
export const get_patient_account_notes_list = (patient_id, token, model_key) => {

    // account notes
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${global_constants.end_points.patient_urls.account_notes}`;
    var sticky_code = patient_constants.sticky_code.Patient
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }
    let request = new Request();

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            sticky_code: sticky_code,
        }
    })

    return dispatch => {
        const note_model = {
            account_task_notes: [],
            task_notes_loading: true,
            empty_message: ' ',
            task_notes_fatch_time: currentDateTime(),
        }
        dispatch({
            type: patient_constants.GET_PATIENT_ACCOUNT_TASK_NOTES,
            payload: { [model_key]: note_model }
        })
        request.make(request_config).then(
            response => {
                const data = (response.data && response.data.data) || [];
                dispatch(get_patient_account_tasks_list(patient_id, token, model_key, data ));
            },

            error => {
                const data = [];
                dispatch(get_patient_account_tasks_list(patient_id, token, model_key, data));
                log_error(error)
            }
        )
    }
}

//GET Account task
export const get_patient_account_tasks_list = (patient_id, token, model_key, notes_data) => {

    // account notes
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.task}${global_constants.end_points.patient_urls.patients}/${patient_id}`;
    var sticky_code = patient_constants.sticky_code.Patient
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }
    let request = new Request();

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
    })

    return dispatch => request.make(request_config).then(
        response => {
            const data = (response.data && response.data.data) || [];
            const data_combined = format_account_notes(data, notes_data);
            const note_model = {
                account_task_notes: data_combined,
                task_notes_loading: false,
                empty_message: TEMPLATE_NO_RECORD,
                task_notes_fatch_time: currentDateTime(),
                // account_task_notes: data_combined.combined_account_notes,
                // total_column_width: data_combined.grid_width.total_column_width,
                // header_height: data_combined.grid_width.header_height
            }
            dispatch({
                type: patient_constants.GET_PATIENT_ACCOUNT_TASK_NOTES,
                payload: { dialog_account_notes: note_model, patient_view_account_notes: note_model }
            })
        },
        error => {
            const data = [];
            const data_combined = format_account_notes(data, notes_data);
            const note_model = {
                account_task_notes: data_combined,
                task_notes_loading: false,
                empty_message: TEMPLATE_NO_RECORD,
                task_notes_fatch_time: currentDateTime(),
            }
            dispatch({
                type: patient_constants.GET_PATIENT_ACCOUNT_TASK_NOTES,
                payload: { [model_key]: note_model }
            })
            log_error(error)
        }
    )
}

// REMOVE
export const remove_patient_account_notes = (token, patient_id, note_ids) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${global_constants.end_points.patient_urls.account_notes}${global_constants.end_points.shared_urls.remove}`;
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }
    let request = new Request();

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'put',
        'data': note_ids
    })
    return dispatch =>
        request.make(request_config)
            .then(
                response => dispatch({
                    type: patient_constants.REMOVE_PATIENT_ACCOUNT_NOTES,
                    payload: response
                }),
                error => {
                    log_error(error)
                }
        )
}

const get_add_patient_account_notes_request_config = (patient_id, token, account_notes) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${global_constants.end_points.patient_urls.account_notes}`;
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': account_notes
    })
    return request_config;
}



// ADD
export const add_patient_account_notes = (patient_id, token, account_notes) => {
    

    let request = new Request();
    let request_config = get_add_patient_account_notes_request_config(patient_id, token, account_notes);

    return dispatch => request.make(request_config).then(
        response => dispatch({
            type: patient_constants.ADD_PATIENT_ACCOUNT_NOTES,
            payload: response
        }),
        error => {
            log_error(error)
        }
    );
}

// ADD Patient Account Note and Return Response
export const add_patient_account_notes_return_response = (patient_id, token, account_notes) => {
    

    let request = new Request();
    let request_config = get_add_patient_account_notes_request_config(patient_id, token, account_notes);
   
    return request.make(request_config);
}

// Get Account Note Code 
export const get_patient_account_note_code = (patient_id, is_from_patient, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${global_constants.end_points.patient_urls.account_notes}${global_constants.end_points.patient_urls.note_codes}/${is_from_patient}`;

    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request = new Request();
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return (
        dispatch => request.make(request_config)
            .then(
                response => dispatch({
                    type: patient_constants.GET_PATIENT_ACCOUNT_NOTES_CODE,
                    payload: response
                }),
                error => {
                    log_error(error)
                }
            )
    );
}

// ********************************************************** Patient Account Notes End **********************************************//


// Get Print Patient forms data
export const get_patient_form_data = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}${global_constants.end_points.patient_urls.get_patient_form_list}`;

    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request = new Request();
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return dispatch => {

        dispatch({
            type: patient_constants.GET_PRINT_PATIENT_FORM_DATA,
            payload: { print_patient_form_data: null, print_form_loading: true, empty_message: ' '}
        })

        request.make(request_config).then(
            response => {
                let data = [];
                if (response.data && response.data.data && response.data.data.length > 0) {
                    data = response.data.data;
                    data = data.map(item => {
                        item["created_date"] = item["created_date"] == null ? null : custom_date_format(item["created_date"], global_constants.date_format["mm/dd/yyyy h:MM:ss TT"]);
                        return item;
                    })
                    data.sort((item1, item2) => mm_dd_yyyy_h_mm_ss_tt_comparator(item2["created_date"], item1["created_date"]));
                }

                dispatch({
                    type: patient_constants.GET_PRINT_PATIENT_FORM_DATA,
                    payload: {
                        print_patient_form_data: data,
                        print_form_loading: false,
                        empty_message: TEMPLATE_NO_RECORD,
                        print_form_fatch_time: currentDateTime(),
                    }
                })
            },
            error => {
                dispatch({
                    type: patient_constants.GET_PRINT_PATIENT_FORM_DATA,
                    payload: {
                        print_patient_form_data: [],
                        print_form_loading: false,
                        empty_message: TEMPLATE_NO_RECORD,
                        print_form_fatch_time: currentDateTime(),
                    }
                })
                log_error(error)
            }
        )
    }

}

// GET Account notes
export const get_patient_account_notes_action = async (patient_id, token, stickyCode) => {
    // account notes
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${global_constants.end_points.patient_urls.account_notes}`;

    let params = {         
       sticky_code: stickyCode            
    }
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }
   
    let request = new Request();
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': params
    })

    return (
        request.make(request_config).then(
            response => {
                return response.data
            },
            error => {
                log_error(error)
            }
        )
    )
};

export const get_patient_account_notes_status_action = data => {
    return {
        type: patient_constants.GET_PATIENT_ACCOUNT_NOTES_STATUS,
        payload: data
    };
};

/**
  * Cases: patient case listing API calling
  * @param patient_id
  * @param case_id
  * @param token
  */
export const get_patient_cases = (patient_id, token, in_active_cases) => {
    const { base_end_points, end_points } = global_constants;
    const url = `${base_end_points}${end_points.patient_urls.patients}/${patient_id}${end_points.patient_urls.cases}`;
    

    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            in_active_cases
        }
    })

    return dispatch => {

        dispatch({
            type: patient_constants.GET_PATIENT_CASES,
            payload: { cases: null, case_loading: true }
        })

        request.make(request_config).then(
            response => dispatch({
                type: patient_constants.GET_PATIENT_CASES,
                payload: { cases: fromatCase(response), case_loading: false }
            }),
            error => {
                dispatch({
                    type: patient_constants.GET_PATIENT_CASES,
                    payload: { cases: [], case_loading: false }
                })
                log_error(error)
            }
        )
    }
}

//Get case name
const get_description = (start_of_care_date, description) => {
    let desc = `${description || ''}`;
    if (start_of_care_date) {
        desc = `${get_date(start_of_care_date, 'MM/DD/YYYY')} ${description || ''}`;
    }
    return desc;
}
// Get content width dynamically
const get_content_width = (element, text) => {
    if (element && text && text.length>10) {
        element.innerText = text;
        return element.offsetWidth;
    }
    return 0;
}

//Format data source to render in the case grid
const fromatCase = (response) => {
    if (response.data && response.data.data && response.data.data.length > 0) {
        var element: HTMLSpanElement = document.querySelector('.get-content-width');
        let description = '';
        const data= response.data.data.map((value) => {
            const items = format_subscriber(value.subscriber);
            description = get_description(value.start_of_care_date, value.description);
            value['primary_text'] = items[0].primary;
            value['secondary_text'] = items[0].secondary;
            value['tertiary_text'] = items[0].tertiary;
            value['description'] = description;
            value['injury_date'] = get_date(value.injury_date, 'MM/DD/YYYY');
            value['discharge_date'] = get_date(value.discharge_date, 'MM/DD/YYYY');
            value['effective_date'] = get_date(value.effective_date, 'MM/DD/YYYY');
            value['effective_end_date'] = get_date(value.effective_end_date, 'MM/DD/YYYY');
            value['date_of_service_to'] = get_date(value.date_of_service_to, 'MM/DD/YYYY');
            value['date_of_service_from'] = get_date(value.date_of_service_from, 'MM/DD/YYYY');
            value['created_date'] = get_date(value.created_date, 'MM/DD/YYYY');
            value['participants'] = [{ items, ...value }];
            value['group'] = value.id;
            value['primary_width'] = get_content_width(element, items[0].primary);
            value['secondary_width'] = get_content_width(element, items[0].secondary);
            value['tertiary_width'] = get_content_width(element, items[0].tertiary);
            value['description_width'] = get_content_width(element, description);
            return value;
        })
        if (element) {
            element.innerText = '';
        }
        return data;
    }
    return [];
}


export const account_rollup = (token, patient_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}${global_constants.end_points.patient_urls.account_rollup}/${patient_id}`;
   
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'put',
        patient_id: patient_id
    })
    return request.make(request_config);
}
//Merge patient accounts
export const merge_accounts = (token, patient_id_to_remove, patient_id_to_keep) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.merge_accounts}`;
    
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'put',
        'params': {
            'patient_id_to_remove': patient_id_to_remove,
            'patient_id_to_keep': patient_id_to_keep
        },
    })
    return request.make(request_config);
}