import { IValidator } from "./IValidator";
import { addPatientInitialConfiguration, patientInvalidFieldMessage } from '../patient_constants';

export class AllowedCharactersValidator implements IValidator {
    private errorMessages = new Array<string>();

    isValid(textValue: string): boolean {
        const alphanumericRegexp = addPatientInitialConfiguration.alphanumericRegexp;
        const hasAllValidCharacters = alphanumericRegexp.test(textValue);

        this.errorMessages = [];
        if (!hasAllValidCharacters) {
            this.errorMessages.push(patientInvalidFieldMessage.invalidAlphaNumericField);
        }
        return hasAllValidCharacters;
    }

    getErrorMessages(): string[] {
        return this.errorMessages;
    }
}
