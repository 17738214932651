import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { bindActionCreators } from 'redux';
import { Button, Dimmer, Form, Grid, Header, Loader, Accordion, Icon } from 'semantic-ui-react';
import { clearTimeout, setTimeout } from 'timers';
import { date_format, a_company_type } from '../../global_constants';
import { handle_click_on_enter, set_focus_to_app_header, set_focus_on_element_with_id } from './../../shared/tab_navigation_utility';
import DatePicker from '../../shared/component/date_picker_component';
import GridView from '../../shared/component/grid';
import * as session_storage from '../../shared/session_storage_utility';
import { get_patient_search } from '../action/patient_search_action';
import * as patient_constants from '../patient_constants';
import { custom_date_format, unmask, fixSSN, phone_format, setup_tabId_in_localStorage, get_columns } from './../../shared/utility';
import { set_active_patient } from '../../login/action/login_action';
import * as ReportConstants from '../../reports/report_constants';
import * as export_print_utility from '../../reports/util/export_print_utility';

export class PatientsearchComponent extends React.Component<any, any> {
    _is_mounted = false;
    handle_company_changed = false; // is used to handle post operations on company change.
    constructor(props) {
        super(props);
        this.state = {
            shown: true,
            is_save_button_disabled:false,
            isPatientSearchPadding: true,
            loading: false,
            startDate: moment(),
            // to store available height for grid
            container_height: 0,
            // Patient search JSON object
            // It is used to bind up patient search object while searching
            patient: {
                Id: '',
                Company_id: this.props.user_details.user_data.data.company_id,
                Date_of_birth: null,
                Patient_name: {},
                Responsible_name: {},
                ssn: '',
                Phone: '',
                Show_in_active: false,
                Policy_number: '',
                Alt_record_id: '',
                Page_index: patient_constants.PAGEINDEX,
                Page_size: patient_constants.PAGESIZE,
            },
            frm: {
                txtFirstName: '',
                txtMiName: '',
                txtLastName: '',
                txtDOB: null,
                txtPatientId: '',
                txtPhoneNo: '',
                txtPolicyNo: '',
                txtAltPId: '',
                txtssn: '',
                Patient: true,
                ShowInActive: false
            },
            accordion_is_expanded:true
            //,control_search : { }
        };

        //this.props.patient_details.patient_search = null;
        this.handleCalendarChange = this.handleCalendarChange.bind(this);
        this.setFocusToAppHeader = this.setFocusToAppHeader.bind(this);
    }
    patient_search_col_conf; 
    page_title: string = 'Patient Search';
    total_column_width: number = 0;
    grid_header_height: number = 0;
    set_patient_object = (patient) => {
        let form_object = {
            txtFirstName: '',
            txtMiName: '',
            txtLastName: '',
            txtDOB: null,
            txtPatientId: '',
            txtPhoneNo: '',
            txtPolicyNo: '',
            txtAltPId: '',
            txtssn: '',
            Patient: true,
            ShowInActive: false
        };
        let is_responsible_search = patient.Is_responsible_search;
        is_responsible_search = is_responsible_search == "false" || is_responsible_search == false ? false : is_responsible_search == "true" || is_responsible_search == true ? true : false
        //var is_patient_select =  !(patient.Is_responsible_search || patient.Is_responsible_search == "true");
        if (!is_responsible_search) {
            form_object.Patient = true;
            form_object.txtFirstName = patient.Patient_name.First_name;
            form_object.txtMiName = patient.Patient_name.Middle_initial;
            form_object.txtLastName = patient.Patient_name.Last_name;
            if (patient.Date_of_birth) {
                form_object.txtDOB = patient.Date_of_birth == '' ? null : moment(patient.Date_of_birth);
            }
            form_object.txtssn = fixSSN(patient.ssn);
            form_object.txtPhoneNo = phone_format(patient.Phone);
        }
        else {
            form_object.Patient = false;
            form_object.txtFirstName = patient.Responsible_name.First_name;
            form_object.txtMiName = patient.Responsible_name.Middle_initial;
            form_object.txtLastName = patient.Responsible_name.Last_name;
            if (patient.Responsible_date_of_birth) {
                form_object.txtDOB = patient.Responsible_date_of_birth == '' ? null : moment(patient.Responsible_date_of_birth);
            }
            form_object.txtssn = fixSSN(patient.Responsible_ssn);
            form_object.txtPhoneNo = phone_format(patient.Responsible_phone);
        }
        form_object.txtPatientId = patient.Id;
        form_object.txtPolicyNo = patient.Policy_number;
        form_object.txtAltPId = patient.Alt_record_id;
        form_object.ShowInActive = patient.Show_in_active;

        //Company ID update here if company change after login
        patient.Company_id = this.props.user_details.user_data.data.company_id ? this.props.user_details.user_data.data.company_id : 0;

        this.setState({
            frm: form_object,
            patient: patient
        }, () => { this.on_page_load(patient); })
    }
    // Patient Option. 
    private optionPatient = () => {

        var patient = this.state.patient;

        if (this.state.frm.Patient === true) {

            //Company ID
            patient.Company_id = this.props.user_details.user_data.data.company_id ? this.props.user_details.user_data.data.company_id : 0;

            patient.Date_of_birth = this.state.frm.txtDOB == null ? null : custom_date_format(this.state.frm.txtDOB, date_format["mm/dd/yyyy"]);

            // Patient Name
            patient.Patient_name.First_name = this.state.frm.txtFirstName ? this.state.frm.txtFirstName.trim() : this.state.frm.txtFirstName;
            patient.Patient_name.Middle_initial = this.state.frm.txtMiName ? this.state.frm.txtMiName.trim() : this.state.frm.txtMiName;
            patient.Patient_name.Last_name = this.state.frm.txtLastName ? this.state.frm.txtLastName.trim() : this.state.frm.txtLastName;

            //Show Inactive Patients
            patient.Show_in_active = this.state.frm.ShowInActive;

            // Patient Account /  Alternate Patient ID
            patient.Alt_record_id = this.state.frm.txtAltPId ? this.state.frm.txtAltPId.toString().trim() : this.state.frm.txtAltPId;

            //Responsible Party - blank
            patient.Responsible_ssn = "";
            patient.Responsible_phone = "";
            patient.Responsible_date_of_birth = "";

            patient.Is_responsible_search = "false"

            //Patient - SSN,Phone,
            patient.ssn = unmask(this.state.frm.txtssn);
            patient.Phone = unmask(this.state.frm.txtPhoneNo);

            // Patient ID
            patient.Id = this.state.frm.txtPatientId ? this.state.frm.txtPatientId.toString().trim() : this.state.frm.txtPatientId;

            patient.Policy_number = this.state.frm.txtPolicyNo ? this.state.frm.txtPolicyNo.toString().trim() : this.state.frm.txtPolicyNo;


        } else {
            patient.Patient_name.First_name = "";
            patient.Patient_name.Middle_initial = "";
            patient.Patient_name.Last_name = "";
        }
        if (this._is_mounted) {
            this.setState({
                patient: patient,
            });
        }
    }

    // Responsible Party Option.
    private optionResponsible_Party = () => {
        var patient = this.state.patient;

        if (this.state.frm.Patient === false) {

            //Company ID
            patient.Company_id = this.props.user_details.user_data.data.company_id ? this.props.user_details.user_data.data.company_id : 0;

            patient.DateOfBirth = null;

            //Responsible Party Name
            patient.Responsible_name.First_name = this.state.frm.txtFirstName ? this.state.frm.txtFirstName.trim() : this.state.frm.txtFirstName;
            patient.Responsible_name.Middle_initial = this.state.frm.txtMiName ? this.state.frm.txtMiName.trim() : this.state.frm.txtMiName;
            patient.Responsible_name.Last_name = this.state.frm.txtLastName ? this.state.frm.txtLastName.trim() : this.state.frm.txtLastName;

            //Show Inactive Patients
            patient.Show_in_active = this.state.frm.ShowInActive;

            // Patient Account /  Alternate Patient ID
            patient.Alt_record_id = this.state.frm.txtAltPId ? this.state.frm.txtAltPId.toString().trim() : this.state.frm.txtAltPId;

            //Responsible Party -SSN,Phone, DOB,
            patient.Responsible_ssn = unmask(this.state.frm.txtssn);
            patient.Responsible_phone = unmask(this.state.frm.txtPhoneNo);
            patient.Responsible_date_of_birth = this.state.frm.txtDOB == null ? null : custom_date_format(this.state.frm.txtDOB, date_format["mm/dd/yyyy"]);

            patient.Is_responsible_search = true;

            //Patient Option - blank
            patient.ssn = "";
            patient.Phone = "";
            patient.Date_of_birth = "";

            //Patient ID
            patient.Id = this.state.frm.txtPatientId ? this.state.frm.txtPatientId.toString().trim() : this.state.frm.txtPatientId;

            //Policy Number
            patient.Policy_number = this.state.frm.txtPolicyNo ? this.state.frm.txtPolicyNo.toString().trim() : this.state.frm.txtPolicyNo;




        } else {
            patient.Responsible_name.First_name = "";
            patient.Responsible_name.Middle_initial = "";
            patient.Responsible_name.Last_name = ""
        }

        if (this._is_mounted) {
            this.setState({
                patient: patient,
            });
        }
    }


    // toggle to show and hide Advance Search
    private toggle = () => {
        const self = this;

        if (this._is_mounted) {
            this.setState({
                shown: !this.state.shown
            });
        }
    }

    componentDidUpdate(previousProps, previousState) {
        
        // refresh if company is changed
        if ((this.state.patient.Company_id 
            != this.props.user_login_details.user_data.data.company_id ) // company is changed
            && ! this.handle_company_changed )
        {
            var criteria = session_storage.get('patient_criteria');
            // criteria is empty, now form data should be refreshed
            if (!criteria) {
                this.handle_company_changed = true;
                this.refresh_form_data_on_company_change();
            } 
        }
    }


    componentWillUnmount() {
        this._is_mounted = false;
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
        }
    }
    componentDidMount() {
        this._is_mounted = true;
        this.patient_search_col_conf = this.filter_columns_based_on_company_type(patient_constants.search_patient_configuration(patient_constants.patient_search_id).column_defs);
        var criteria = session_storage.get('patient_criteria');
        if (criteria) {
            this.set_patient_object(criteria);            
        }
        setup_tabId_in_localStorage();
    }

    //Handle New Patient
    handleNewPatient = (event) => {
        event.preventDefault();
        this.props.history.push('/patient_registration');
        //this.props.history.push('/coming_soon');
    }

    //Handle Clear
    Handle_clear = (event) => {

        this.props.patient_details.patient_search = null;
        if (this._is_mounted) {
            this.setState({
                isPatientSearchPadding: true,
                accordion_is_expanded:true,
                grid_data: null,
                frm: {
                    txtFirstName: '',
                    txtMiName: '',
                    txtLastName: '',
                    txtDOB: null,
                    txtPatientId: '',
                    txtPhoneNo: '',
                    txtPolicyNo: '',
                    txtAltPId: '',
                    txtssn: '',
                    Patient: true,
                    ShowInActive: false
                }
            });
        }
    }

    refresh_form_data_on_company_change = () => {

    this.props.patient_details.patient_search = null;
    let patient = this.state.patient;
    patient.Company_id = this.props.user_details.user_data.data.company_id ? this.props.user_details.user_data.data.company_id : 0;
    this.patient_search_col_conf = this.filter_columns_based_on_company_type(patient_constants.search_patient_configuration(patient_constants.patient_search_id).column_defs);
    if (this._is_mounted) {
        this.setState({
            shown: true,
            patient: patient,
            isPatientSearchPadding: true,
            grid_data: null,
            frm: {
                txtFirstName: '',
                txtMiName: '',
                txtLastName: '',
                txtDOB: null,
                txtPatientId: '',
                txtPhoneNo: '',
                txtPolicyNo: '',
                txtAltPId: '',
                txtssn: '',
                Patient: true,
                ShowInActive: false
            },
            accordion_is_expanded: true
        }, () => {

            // handle company change completed
            this.handle_company_changed = false;
        });
    }
}

    // Handle Patient Search 
    handleSearch = (event) => {
        if (this._is_mounted) {
            this.setState({
                is_save_button_disabled: true
            })
        }
        event.preventDefault();

        // Patient 
        this.optionPatient();

        // Responsible Party
        this.optionResponsible_Party();

        // Get Search Data.
        var token = this.props.user_login_details.user_data.data.token_details.access_token;
        if (this._is_mounted) {
            this.setState({
                grid_data: null,
                isPatientSearchPadding: false,
                loading: true
            });
        }
        // Search function.
        this.props.get_patient_search(this.state.patient, token).then(res => {
            if (this._is_mounted) {
                const grid_height = get_columns(this.props.patient_details.patient_search.slice(0, 20), this.patient_search_col_conf);
                this.total_column_width = grid_height.total_column_width;
                this.grid_header_height = grid_height.header_height;
                this.setState({
                    loading: false,
                    is_save_button_disabled: false,
                    accordion_is_expanded: false
                });
            }
        });
    }

    getString = (value) => {
        return value && value.toString().trim() || '';
    }
    getStatus = (value) => {
        if (value === true) {
            return 'Active'
        } else {
            return 'Inactive'
        }
    }

    on_patient_search_double_click = (selectedRows) => {
        session_storage.set('active_patient', selectedRows.id);
        this.props.set_active_patient(selectedRows.id)
        var navigate_to = `/patient/${selectedRows.id}`;
        //var navigate_to = `/coming_soon`;
        this.props.history.push(`${navigate_to}`);
    }

    // Will handle date selection from calendar  
    handleCalendarChange(dt) {

        var state = { ...this.state };
        var form_data = state.frm;
        form_data.txtDOB = dt;
        if (this._is_mounted) {
            this.setState({
                frm: form_data
            });
        }
    }
    max_value_validation = (input) => {
        if (Number(input) > 2147483647) {
            return this.max_value_validation(input.substring(0, input.length - 1));
        }
        else {
            return Number(input);
        }
    }

    on_grid_out = () => {
        set_focus_on_element_with_id("app_header_dropdown");
    }


    setFocusToAppHeader = (event) => {

        // apply a check where grid is empty
        if (!event.shiftKey && event.keyCode == '9' && (this.props.patient_details.patient_search == null || (this.props.patient_details.patient_search.length == 0))) {
            set_focus_to_app_header(event);
        }
    }
    on_page_load = (crieteria) => {
        // Search function.
        this.setState({
            loading:true
        })
        var token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.props.get_patient_search(crieteria, token).then(res => {
            if (this._is_mounted) {
                const grid_height = get_columns(this.props.patient_details.patient_search.slice(0, 20), this.patient_search_col_conf);
                this.total_column_width = grid_height.total_column_width;
                this.grid_header_height = grid_height.header_height;
                this.setState({
                    loading: false,   
                    accordion_is_expanded: false
                });
            }
        });
    }
    on_change_selection = () => {

    }

    get_grid_ref = (grid_params) => {
        this.setState({
            grid_params,
            grid_rows_count: grid_params.api.getModel().getRowCount()
        });
        var grid_width = document.getElementsByClassName('common-forms-search')[0].clientWidth;
        if (this.total_column_width > grid_width) {
            this.patient_search_col_conf.filter(item => {
                grid_params.columnApi.setColumnWidth(item.field, item['width'], false);
            })
        }
    }

    controls_toggle = () => {
        if (!this._is_mounted) return;

        this.setState({
            accordion_is_expanded: !this.state.accordion_is_expanded
        });
        
    }

    is_webpt_company_type = (): boolean => {
        return (this.props.user_login_details.user_data.data.company_type_id == a_company_type.Web_PT); //WebPT
    }

    filter_columns_based_on_company_type = (patient_search_col_conf) => {
        let filtered_columns = patient_search_col_conf;
        
        if (!this.is_webpt_company_type()) {
            filtered_columns = patient_search_col_conf.filter((column_config) => {
                if (column_config.headerName != patient_constants.emr_patient_id_col_name) return column_config;
            });
        }
        
        return filtered_columns;
    }

    // #region  Print/Export Button
    get_print_export_title = separter => {
        const company_name = this.props.user_login_details.user_data.data.company_name;
        const current_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
        return (
            this.page_title.replace(/<br>/g, separter) +
            separter +
            company_name +
            separter +
            current_date +
            separter +
            (this.state.grid_rows_count == 1
                ? this.state.grid_rows_count + ' record'
                : this.state.grid_rows_count + ' records') +
            separter +
            separter);
    };

    //Function calls on initialization of export report data
    on_export_button = () => {
        const title = this.get_print_export_title('\n');
        export_print_utility.export_grid_data(this.state.grid_params, title, this.page_title);
        this.audit_user_action(ReportConstants.ReportEventAction.Export);
    };


    private audit_user_action = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;

        const payload: ReportConstants.IPayloadForBillingAudit = {
            reportId: "0",
            contextTitle: `${this.page_title} - ${ReportConstants.ReportEventAction[eventActionId]}`,
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: ReportConstants.ReportEntityTypeId.D_Patient,
            data: { Records: this.state.grid_rows_count }
        };

        const reqBody = export_print_utility.generateAuditDataEntryPayload(payload);

        ReportConstants.saveAuditPrintExport(reqBody, accessToken);
    }
    // #endregion Export Button;

    render() {
        const { criteria_height, container_height } = this.state;
        let height = 0;
        let is_webpt_company = this.is_webpt_company_type();
        if (criteria_height) {
            height = container_height - (criteria_height + patient_constants.search_result_row_height_const);
        }

        var is_patient_search_padding = true;
        if (this.props.patient_details.patient_search != null && this.props.patient_details.patient_search != undefined) {
            is_patient_search_padding = false;
        }      
        
        var hidden = {
            display: this.state.shown ? "none" : "block"
        }

        return (
            <div className="common-forms-search patient-search">
                <Dimmer active={this.state.loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <Grid>
                    <Grid.Column computer={16}>
                        <Header as='h3' textAlign="left">Patient Search</Header>
                    </Grid.Column>
                </Grid>
                <div className={`border-wrap ${is_patient_search_padding ? '' : 'patient_search_bottom_padding'}`}>
                    <Form id="patient-search-criteria-container" className="patient-search-form" autoComplete="off" onReset={this.Handle_clear.bind(this)}>
                        <Grid>
                            <Grid.Column computer={16} tablet={16} textAlign='left' className='accordionColumn'>
                                <Accordion fluid styled className='common-accordion'>
                                    <Accordion.Title active={this.state.accordion_is_expanded} index={0} onClick={this.controls_toggle}>
                                        <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name={this.state.accordion_is_expanded ? 'angle up' : 'angle down'} />
		                                Search Criteria
	                                </Accordion.Title>
                                    <Accordion.Content active={this.state.accordion_is_expanded}>
                                        <Grid>
                                        <Grid.Column tablet={7} computer={4}>
                                            <Form.Field>
                                                <label>First Name</label>
                                                <input name="txt_first_name_test" value={this.state.frm.txtFirstName} autoComplete="new-password" type="text" autoFocus maxLength={20} onChange={e => {
                                                    var stFrm = this.state.frm;
                                                    stFrm.txtFirstName = e.target.value;
                                                    this.setState({ frm: stFrm })
                                                }} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={2} computer={2}>
                                            <Form.Field>
                                                <label>MI</label>
                                                <input name="txt_middel_name" type="text" value={this.state.frm.txtMiName} autoComplete="new-password" maxLength={1} onChange={e => {
                                                    var stFrm = this.state.frm;
                                                    stFrm.txtMiName = e.target.value;
                                                    this.setState({ frm: stFrm })
                                                }} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={7} computer={4}>
                                            <Form.Field>
                                                <label>Last Name</label>
                                                <input name="txt_last_name" type="text" value={this.state.frm.txtLastName} autoComplete="new-password" maxLength={20} onChange={e => {
                                                    var stFrm = this.state.frm;
                                                    stFrm.txtLastName = e.target.value;
                                                    this.setState({ frm: stFrm })
                                                }} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={7} computer={3}>
                                            <Form.Field >
                                                <label>Date of Birth</label>
                                                <DatePicker maxDate={moment().format('MM/DD/YYYY')} date_update={this.handleCalendarChange} date={this.state.frm.txtDOB} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={9} computer={3}>
                                            <Form.Field>
                                                <label>Patient ID</label>
                                                <input name="txt_patient_id" type="text" autoComplete="new-password" pattern="[0-9]*" value={this.state.frm.txtPatientId}
                                                    maxLength={10} onChange={e => {
                                                        var stFrm = this.state.frm;
                                                        var txt_pid = e.target.value.trim();
                                                        stFrm.txtPatientId = (e.target.validity.valid) ? (Number(txt_pid) <= 2147483647 ? txt_pid : this.max_value_validation(txt_pid)) : this.state.frm.txtPatientId;
                                                        this.setState({ frm: stFrm })
                                                    }} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={9} computer={4}>
                                            <Form.Field>
                                                <label>Phone Number</label>
                                                <MaskedInput
                                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    className="form-control"
                                                    placeholder="(___) ___-____"
                                                    guide={true}
                                                    name="txt_phone_number"
                                                    id="my-phone-no"
                                                    value={this.state.frm.txtPhoneNo}
                                                    autoComplete="new-password"
                                                    onChange={e => {
                                                        var stFrm = this.state.frm;
                                                        stFrm.txtPhoneNo = e.target.value;
                                                        this.setState({ frm: stFrm })
                                                    }} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={9} computer={4}>
                                            <Form.Field>
                                                <label>Policy Number</label>
                                                <input name="txt_policy_number" value={this.state.frm.txtPolicyNo} type="text" autoComplete="new-password" maxLength={50} onChange={e => {
                                                    var stFrm = this.state.frm;
                                                    stFrm.txtPolicyNo = e.target.value;
                                                    this.setState({ frm: stFrm })
                                                }} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={9} computer={4}>
                                            <Form.Field>
                                                    <label>{is_webpt_company ? 'EMR Patient ID' : 'Alternate Patient ID'}</label>
                                                <input name="txt_alt_patient_id" value={this.state.frm.txtAltPId} type="text" autoComplete="new-password" maxLength={50} onChange={e => {
                                                    var stFrm = this.state.frm;
                                                    stFrm.txtAltPId = e.target.value;
                                                    this.setState({ frm: stFrm })
                                                }} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={9} computer={4}>
                                            <Form.Field>
                                                <label>SSN</label>
                                                <MaskedInput
                                                    mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    className="form-control"
                                                    placeholder="___-__-____"
                                                    guide={true}
                                                    id="my-ssn-no"
                                                    name="txt_ssn_number"
                                                    autoComplete="new-password"
                                                    value={this.state.frm.txtssn}
                                                    onChange={e => {
                                                        var stFrm = this.state.frm;
                                                        stFrm.txtssn = e.target.value;
                                                        this.setState({ frm: stFrm })
                                                    }}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={9} computer={3}>
                                            <Form.Field>
                                                <label>Patient</label>
                                                <input type="radio" value={this.state.frm.Patient ? 1 : 0} checked={this.state.frm.Patient} name="optradio" onChange={this.on_change_selection} className={this.state.shown ? 'should_navigate' : ''} style={{ marginRight: '5px' }} onClick={e => {
                                                    var stFrm = this.state.frm;
                                                    stFrm.Patient = true;
                                                    this.setState({ frm: stFrm })
                                                }} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={9} computer={3}>
                                            <Form.Field>
                                                <label>Responsible Party</label>
                                                <input type="radio" value={this.state.frm.Patient ? 0 : 1} checked={!this.state.frm.Patient} name="optradio" onChange={this.on_change_selection} style={{ marginRight: '5px' }} onClick={e => {
                                                    var stFrm = this.state.frm;
                                                    stFrm.Patient = false;
                                                    this.setState({ frm: stFrm })
                                                }} />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column tablet={9} computer={4}>
                                            <Form.Field>
                                                <label>Show Inactive Patients</label>
                                                <input type="checkbox" value={this.state.frm.ShowInActive || false} checked={this.state.frm.ShowInActive} onChange={this.on_change_selection} onClick={e => {
                                                    var stFrm = this.state.frm;
                                                    stFrm.ShowInActive = !stFrm.ShowInActive;
                                                    this.setState({ frm: stFrm })
                                                }} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={9} computer={6}>
                                        </Grid.Column>
                                    </Grid>
                                    </Accordion.Content>
	                            </Accordion>
                            </Grid.Column>
                        </Grid>
                        <Grid style={{ margin: '0 -17px' }}>
                            <Grid.Column computer={16} textAlign="right">
                                <Button type="reset" id="bg_fff1" basic >Clear</Button>
                                <Button type='button' id="bg_fff2" onClick={this.handleNewPatient.bind(this)} basic>New Patient</Button>
                                <Button id="patient_search_button_search" onKeyDown={this.setFocusToAppHeader} type='submit' disabled={this.state.is_save_button_disabled} onClick={this.handleSearch.bind(this)} primary>Search</Button>
                            </Grid.Column>
                        </Grid>
                    </Form>
                    <React.Fragment>
                        {(this.props.patient_details.patient_search && !this.state.loading) && (<GridView
                            id={'id_grid_patient_search'}
                            row={this.props.patient_details.patient_search}
                            column={this.patient_search_col_conf}
                            style={{  display:  'flex',  flexDirection:  'column', height:  '100%'  }} 
                            wrapperStyle={{ width: '100%', display: 'flex', flex: 1 }}
                            isPagination={true}
                            headerHeight={this.grid_header_height}
                            paginationPageSize={patient_constants.search_patient_configuration(patient_constants.patient_search_id).pagination_size}
                            onRowDoubleClicked={this.on_patient_search_double_click}
                            onGridOut={this.on_grid_out}
                            enableColResize={true}   
                            suppressSizeToFit={true}
                            get_grid_ref={this.get_grid_ref}
                                headerIdForTabNavigation={patient_constants.patient_search_id} />)}
                        {
                            this.state.grid_rows_count > 0 &&
                            <Grid style={{ margin: '0 -17px' }}>
                                <Grid.Column computer={16} textAlign="right">
                                    <Button id='export_button' type='submit' onClick={this.on_export_button} className='ui primary button'>
                                        Export
                                    </Button>
                                </Grid.Column>
                            </Grid>
                        }
                    </React.Fragment>

                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        get_patient_search: get_patient_search,
        set_active_patient: set_active_patient,
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        user_details: state.user_login_details,
        patient_details: state.patient_details
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientsearchComponent));