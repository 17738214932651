import * as React from 'react';
import * as moment from 'moment';
import { connect } from 'react-redux';
import { toastr as toaster } from 'react-redux-toastr';
import * as global_constants from '../../../global_constants';
import { log_error } from '../../../shared/action/shared_action';
import { get_columns, get_all_error, convert_db_datetime_to_local_string } from '../../../shared/utility';
import * as report_constants from '../../../reports/report_constants';
import * as report_service from '../../../reports/action/report_action';
import { set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import PrintReport835Component from './835_print_report_component';

export class ExclusionReport835Component extends React.Component<any, any> {
    [x: string]: any;
    constructor(props: any) {
        super(props);
        // Define initial state value.
        this.state = {
            response_data: null,
            report_data: {},
            is_grid_visible: false,
            is_show_pop_up: false,
            grid_params: null,
            loading: true,
            row_params: {},
            show_date: false,
            report_rows_count: 0,
            is_error: {},
            refresh_report: false,
            return_selected: false,
            selected_error: [{
                payerId: 0,
                errorCode: '0',
                name: '--',
                ruleLayer: ''
            }],
            record_last_updated_date: { date: null, user: null, selected: [] },
            export_data: []
        };
    }
    _is_mounted = false;

    // Define class level variables.
    error_excusion_column_def = report_constants.error_excusion_835__configuration.column_defs;
    report_data: any = {
        row: []
    };
    total_column_width: number = 0;
    grid_header_height: number = 0;
    criteria: {
        company_id: ''
    };
    title = "";
    page_name = "835 Error Exclusions"
    current_date: any;
    company_name: any;

    // Fetching the report id and params.
    UNSAFE_componentWillMount = () => {
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.report_data = [];
    };

    componentWillMount = () => {
        this.get_report_data().then(() => {
            //debugger;
            this.props.onReportSelectionUpdated(this.state.record_last_updated_date);
            this.props.get_export_data(this.state.export_data);
        });
    }

    componentWillUnmount = () => {
        document.body.classList.remove('reports');
        this._is_mounted = false;

    };

    componentDidMount() {
        this._is_mounted = true;
        document.body.classList.add('reports');
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.refresh_report !== nextProps.refresh_report || this.state.return_selected !== nextProps.return_selected || this.state.selected_error !== nextProps.selected_error) {
            this.setState({
                refresh_report: nextProps.refresh_report,
                return_selected: nextProps.return_selected,
                selected_error: nextProps.selected_error
            });
        }
    }

    componentDidUpdate() {
        if (this.state.refresh_report) {
            this.setState({ refresh_report: false });
            this.get_report_data().then(() => {
                this.props.onReportRefreshed();
                this.props.get_export_data(this.state.export_data);
            });
        }
        if (this.state.return_selected) {
            this.setState({ return_selected: false });
            if (this.state.response_data) {
                this.update_parent_controls();
            }
        }
    }

    //Get Report Data.
    get_report_data = async () => {
        if (this._is_mounted) {
            this.setState({
                loading: true,
                is_grid_visible: false,
                is_save_button_disabled: true
            });
        }

        this.criteria = {
            company_id: this.props.user_login_details.user_data.data.company_id
        }

        await report_service.get_835_error_exclusions_report(this.criteria, this.token).then(
            response => {
                if (response.data && response.data.data) {
                    this.setState({
                        export_data: response.data.data
                    })
                    response.data.data = this.row_data_formatter(response.data.data);
                    this.max_inner_width = 0;
                    this.report_data['row'] = response.data.data
                        ? response.data.data.map(item => {
                            const child_column = report_constants.error_excusion_835__configuration.child_column.map(value => ({ ...value }));
                            const inner_table_width = get_columns(item.payers || [], child_column);
                            this.max_inner_width =
                                inner_table_width.total_column_width > this.max_inner_width
                                    ? inner_table_width.total_column_width
                                    : this.max_inner_width;
                            item = {
                                ...item,
                                expanded: true,
                                participants: [
                                    {
                                        ...item,
                                        child_column
                                    }
                                ]
                            };
                            delete item['payers'];
                            return item;
                        })
                        : [];
                    this.total_column_width = 0;
                    const grid_height = get_columns(
                        this.report_data['row'],
                        this.error_excusion_column_def,
                        null
                    );

                    this.grid_header_height = grid_height.header_height;
                    this.total_column_width = grid_height.total_column_width;
                    if (this._is_mounted) {
                        this.setState({
                            loading: false,
                            report_data: this.report_data,
                            is_grid_visible: true,
                            report_rows_count: this.report_data['row'] && this.report_data['row'].length,
                            response_data: response.data.data
                        });
                        this.update_parent_controls();
                        this.props.report_loading(this.state.loading);
                    }
                } else {
                    log_error(response.data.messages[0].message.indexOf('<br') > -1);
                    if (response.data.messages[0].message.indexOf('<br') > -1) {
                        const toastr_options = this.show_html_content_toaster(get_all_error(response.data.messages[0].message));
                        toaster.error("", toastr_options);
                    }
                    if (this._is_mounted) {
                        this.setState({
                            loading: false,
                            is_save_button_disabled: false,
                            record_last_updated_date: { date: null, user: null, selected: [] }
                        });
                        this.props.report_loading(this.state.loading);
                    }
                }
            },
            error => {
                if (this._is_mounted) {
                    this.setState({
                        loading: false,
                        is_save_button_disabled: false
                    });
                    this.props.report_loading(this.state.loading);
                }
                log_error(error);
                if (error.response && error.response.data) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error("", toastr_options);
                }
            }
        );
    };

    update_parent_controls = () => {
        let record_selected_return = { date: null, user: null, selected: [] };
        if (this.state.response_data) {
			this.state.response_data.map(item => {
				if (item.last_updated_date) {
					item.last_updated_date = new Date(convert_db_datetime_to_local_string(item.last_updated_date));
				}
                //item.last_updated_date = new Date(convert_db_datetime_to_local_string(new Date(item.last_updated_date)));
                if (this.state.selected_error[0].name == '--' || this.state.selected_error[0].name == item.error_description) {
                    if (record_selected_return.date == null || new Date(item.last_updated_date) > new Date(record_selected_return.date)) {
                        record_selected_return.date = new Date(item.last_updated_date);
                        record_selected_return.user = item.last_updated_by;
                    }
                    if (this.state.selected_error[0].name != '--') {
                        record_selected_return.selected = item.payers;
                    }
                }
            })
            //debugger;
            this.props.onReportSelectionUpdated(record_selected_return);
        }
    };

    // Show multiple messages
    show_html_content_toaster = msg => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ),
            timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        };
    };

    //FUnction use to format the grid data on load.
    row_data_formatter = data => {
        data.map(row => {
            // Formate Inner grid Row
            row['payers'] = row.payers && row.payers.map((item) => {
                return (item = {
                    payer_code: item.payer_code || "",
                    description: item.description || "",
                    outbound_id: item.outbound_id || "",
                });
            });
            return row;
        });
        return data;
    };

    capitalize_text = string => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    //Function calls on intialization of Ag-Grid and catch its reference.
    get_grid_ref = grid_params => {
        if (this._is_mounted) {
            this.setState({
                grid_params,
                report_rows_count: grid_params.api.getModel().getRowCount()
            });
        }
        if (this.total_column_width > this.grid_width) {
            this.error_excusion_column_def.filter((item, index) => {
                grid_params.columnApi.setColumnWidth(item.field, item['width'], false);
            });
        }
    };

    //Used to create the title for export and print.
    set_title = separter => {
        this.company_name = this.props.user_login_details.user_data.data.company_name;
        this.current_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
        return this.title =
            this.title.replace(/<br>/g, separter) +
            separter +
            this.company_name +
            separter +
            this.current_date +
            separter +
            (this.state.report_rows_count == 1
                ? this.state.report_rows_count + ' record'
                : (this.state.report_rows_count / 2) + ' records') +
            separter +
            separter;
    };



    handle_navigation_after_last_element = event => {

        // apply a check where grid is empty
        if (
            !event.shiftKey &&
            event.keyCode == '9' &&
            (this.state.report_data == null || this.state.report_data.length == 0)
        ) {
            set_focus_to_app_header(event);
        }
    };

    render() {
        return (
            <PrintReport835Component report_data={this.state.export_data} />
        );
    }
}

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
        is_expand: state.shared_details.is_expand,
    };
};

export default connect(mapStateToProps)(ExclusionReport835Component);