import { saveAs } from "../../shared/file-saver.min";
import * as report_constants from "../report_constants";
import * as report_data_formatter from "../util/report_data_formatter";
import { execute_print, addClass, removeClass, print_html_data_to_pdf, is_safari, strip } from "./../../shared/utility";
import * as ReportConstants from '../../reports/report_constants';
// Print Data to pdf 
export const printPDF = (printElement, print_report_button, report_name) => {
    let draggableModal = document.getElementsByClassName("react-draggable") as HTMLCollectionOf<HTMLElement>;
    if (draggableModal && draggableModal.length > 0) {
        addClass(draggableModal[0], "hide_on_print");
    }

    let drawer = document.getElementsByClassName('bp3-portal') as HTMLCollectionOf<HTMLElement>; //drawer
    if (drawer && drawer.length > 0) {
        for (let i = 0; i < drawer.length; i++) {
            drawer[i].setAttribute('style', 'display:none!important');
        }
    }
    let ua = window.navigator.userAgent;
    let doc_title = document.title;
    // Print functionality start
    if (window.print) {
        document.title = report_name
        if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
            setTimeout(function () {
                execute_print();
            }, 100);

            setTimeout(function () {
                document.body.style.overflow = null;
                printElement.remove();
                print_report_button.blur();
                if (draggableModal && draggableModal.length > 0) {
                    removeClass(draggableModal[0], "hide_on_print");
                }
                if (drawer && drawer.length > 0) {
                    for (let i = 0; i < drawer.length; i++) {
                        drawer[i].removeAttribute('style');
                    }
                }
                document.title = doc_title
            }, 4000);
        } else {
            if (window.matchMedia) {
                let mediaQueryList = window.matchMedia("print");
                mediaQueryList.addListener(function (mql) {
                    if (mql.matches) {
                    } else {
                        afterPrint();
                    }
                });
            }
            let afterPrint = function () {
                if (ua.indexOf("Trident/7.0") > -1) {
                    document.body.removeChild(printElement);
                } else {
                    printElement.remove();
                }
                document.body.style.overflow = null;
                print_report_button.blur();
                if (draggableModal && draggableModal.length > 0) {
                    removeClass(draggableModal[0], "hide_on_print");
                }
                if (drawer && drawer.length > 0) {
                    for (let i = 0; i < drawer.length; i++) {
                        drawer[i].removeAttribute('style');
                    }
                }
                document.title = doc_title
            };
            // These are for Firefox
            window.onafterprint = afterPrint;
            execute_print();
        }
    }
};

// Download Data to CSV
export const downloadCSV = (csv, file_name="exported_data")=>{
    let csvContent: any = csv.map((item) => {
        item = item.map((row: string, index: number) => {
          row = item.length - 1 == index ? row + "\n" : row;
          return row;
        });
        return item.join(",");
      });
      let blob = new Blob(csvContent, {
        type: "text/csv;charset=utf-8"
      });
    
      //Blob.js
    saveAs(blob, file_name + ".csv"); 
}

// Download string to txt
export const downloadTxtFromString = (string, file_name)=>{
      let blob = new Blob([string], {
        type: "text/plain;charset=utf-8"
      });
      //Blob.js
      saveAs(blob, file_name + ".txt"); 
}

// Function calls on click of Export button for Export Grid Data into CSV
export const export_grid_data = (grid_ref: { api: any; columnApi?: any }, title: string, report_name: string) => {
    let csv = [];
    let row_api = grid_ref.api;
    let col_api = grid_ref.columnApi;
    let header_data = col_api.getAllColumns();
    let table_header = [];
    let table_rows = [];
    let title_row = [];
    title_row.push(title);
    csv.push(title_row);

    let grouped_column = col_api.getAllDisplayedColumnGroups();
    let grouped_header = col_api.columnController.primaryHeaderRowCount;
    /**
     * If Grid had multiple header
     */
    // Render Grid Parent Header
    if (grouped_column && grouped_header > 1) {
        grouped_column.map((colData, index: number) => {
            for (let index = 0; index < colData.children.length; index++) {
                let header_name = "";
                if (colData.children.length > 2) {
                    if (Math.floor(colData.children.length / 2) == index) {
                        header_name = colData.originalColumnGroup.colGroupDef.headerName;
                    } else {
                        header_name = "";
                    }
                } else {
                    // fall in case of 1, 2
                    if (index == 0) {
                        header_name = colData.originalColumnGroup.colGroupDef.headerName;
                    } else {
                        header_name = "";
                    }
                }
                table_header.push(header_name);
            }
        });
        csv.push(table_header);
        table_header = [];
    }

    // Render Grid Header
    header_data.map((colData: { colDef: { hide: any; field: string; headerName: string } }) => {
        if (!colData.colDef.hide && colData.colDef.field !== "group" && colData.colDef.field !== "checkbox") {
            table_header.push(colData.colDef.headerName);
        }
    });
    csv.push(table_header);
    table_header = [];

    if (row_api.getModel().getRowCount() > 0) {
        row_api.forEachNodeAfterFilterAndSort((data) => {
            if (data.group && !data.data.details) {
              if (data.data.group) {
                prepare_export_data_for_grouped_column(header_data, data, csv);
              } else {
                header_data.map((colData: { colDef: { hide: any; field: string; type: string } }) => {
                  if (!colData.colDef.hide && colData.colDef.field !== 'group' && colData.colDef.field !== 'checkbox') {
                    if (data.data[colData.colDef.field]) {
                      if (colData.colDef.type == 'hyper_link') {
                        table_rows.push(`"${report_data_formatter.get_link_value(data.data[colData.colDef.field])}"`);
                      } else {
                        table_rows.push(`"${strip(data.data[colData.colDef.field])}"`);
                      }
                    } else if (data.data[colData.colDef.field] === 0) {
                      table_rows.push(`"${strip(data.data[colData.colDef.field])}"`);
                    } else {
                      table_rows.push('');
                    }
                  }
                });
                csv.push(table_rows);
                table_rows = [];
              }
            } else if (data.parent) {
                if (data.parent.expanded && data.data.details && data.data.details.length > 0) {
                    let sub_header = [""];
                    data.data.child_column.map((item: { headerName: string }) => {
                        sub_header.push(`"${strip(item.headerName)}"`);
                    });
                    csv.push(sub_header);
                    sub_header = [];
                    data.data.details.map((row: { [x: string]: any }) => {
                        let sub_row = [""];
                        data.data.child_column.map((item: { field: string; type: string }) => {
                            if (item.type == "hyper_link") {
                                sub_row.push(`"${report_data_formatter.get_link_value(row[item.field])}"`);
                            } else {
                                sub_row.push(`"${strip(row[item.field])}"`);
                            }
                        });
                        csv.push(sub_row);
                        sub_row = [];
                    });
                }
            } else {
                // Render expended Grid row
                header_data.map((colData: { colDef: { hide: any; field: string; type: string } }) => {
                    if (
                        !colData.colDef.hide &&
                        colData.colDef.field !== "group" &&
                        colData.colDef.field !== "checkbox"
                    ) {
                        if (data.data[colData.colDef.field]) {
                            if (colData.colDef.type == "hyper_link") {
                                table_rows.push(`"${report_data_formatter.get_link_value(data.data[colData.colDef.field])}"`);
                            } else {
                                table_rows.push(`"${strip(data.data[colData.colDef.field])}"`);
                            }
                        } else if (data.data[colData.colDef.field] === 0) {
                            table_rows.push(`"${strip(data.data[colData.colDef.field])}"`);
                        } else {
                            table_rows.push("");
                        }
                    }
                });
                csv.push(table_rows);
                table_rows = [];
            }
        });
    } else {
        for (let index = 0; index < header_data.length; index++) {
            let header_name = "";
            if (header_data.length > 2) {
                if (Math.floor(header_data.length / 2) == index) {
                    header_name = report_constants.messages.no_records_found;
                } else {
                    header_name = "";
                }
            } else {
                // fall in case of 1, 2
                if (index == 0) {
                    header_name = report_constants.messages.no_records_found;
                } else {
                    header_name = "";
                }
            }
            table_rows.push(header_name);
        }

        // table_rows.push(`"${messages.no_records_found}"`);
        csv.push(table_rows);
        table_rows = [];
    }
    // Render Grid Footer Row
    if (row_api.getPinnedBottomRow(0)) {
        let footer_obj = row_api.getPinnedBottomRow(0).data;
        let footer_cell: any;
        let footer_row = [];
        let first_cell = '';
        if (footer_obj) {
            header_data.map((colData: { colDef: { hide: any; field: string } }) => {
              if (colData.colDef.field === 'group' && footer_obj[colData.colDef.field]) {
                first_cell = footer_obj[colData.colDef.field];
              }
                if (!colData.colDef.hide && colData.colDef.field !== "group" && colData.colDef.field !== "checkbox") {
                    footer_cell = footer_obj[colData.colDef.field] ? footer_obj[colData.colDef.field] : "";
                    footer_row.push(`"${footer_cell || first_cell}"`);
                    first_cell = ''
                }
            });
            csv.push(footer_row);
        }
    }
    // Download CSV File
    downloadCSV(csv, report_name);
};

export const print_merge_accounts = (title: string, data: any, error_msg : string, print_btn_name: string = "print_report_button") => {
    let remove_id = data.patient_id_to_remove.label
      ? data.patient_id_to_remove.title
        ? data.patient_id_to_remove.label + ' (' + data.patient_id_to_remove.title + ')'
        : data.patient_id_to_remove.label
      : '';
    let keep_id = data.patient_id_to_keep.label
      ? data.patient_id_to_keep.title
        ? data.patient_id_to_keep.label + ' (' + data.patient_id_to_keep.title + ')'
        : data.patient_id_to_keep.label
      : '';

    let printContent = `
    <br /><br /><span class="mergeError">${error_msg}</span><br /><br />
    <div class="patientMerge">
      <span class="mergePrint">${title}</span><br /><br />
      <div class="row">
        <div class="column"><span>Patient ID to Remove:</span></div>
        <div class="column spanData"><span>${remove_id}</span></div>
      </div>
      <div class="row">
        <div class="column"><span>Patient ID to Keep:</span></div>
        <div class="column spanData"><span> ${keep_id}</span></div>
      </div>
    </div>    
    `;

    // Print functionality start
    print_html_data_to_pdf(printContent, print_btn_name);
}

// Function calls on click of print button to Print Grid Data
export const print_grid_data = (grid_ref: { api: any; columnApi?: any }, title: string, report_name: string, print_btn_name: string  = "print_report_button", auto_print= false) => {
    if (!print_btn_name) {
        print_btn_name= "print_report_button"
    }
    let row_api = grid_ref.api;
    let col_api = grid_ref.columnApi;
    let header_data = col_api.getAllColumns();
    let first_col_width: number;
    let printContent = "";
    printContent += `<div class="reportPrint">${title}<table id="mainGrid"><thead>`;
    let grouped_column = col_api.getAllDisplayedColumnGroups();
    let grouped_header = col_api.columnController.primaryHeaderRowCount;
    /**
     * If Grid had multiple header
     */
    // Render Grid Parent Header
    if (grouped_column && grouped_header > 1) {
        printContent += "<tr>";
        grouped_column.map((colData, index: number) => {
            printContent += `<th colspan="${colData.originalColumnGroup.children &&
                colData.originalColumnGroup.children.length}" class="center">${
                colData.originalColumnGroup.colGroupDef.headerName
            }</th>`;
        });
        printContent += "</tr>";
    }

    // Render Grid Header
    printContent += "<tr>";
    header_data.map(
        (colData: { colDef: { width: any; hide: any; field: string; headerName: any; type: any } }, index: number) => {
            if (!colData.colDef.hide && colData.colDef.field !== "group" && colData.colDef.field !== "checkbox") {
                if (index == 1) {
                    first_col_width = Math.round(colData.colDef.width);
                    printContent += `<th style="width: ${first_col_width +"px" || "auto"};" ${
                      alignCellTextRight(colData)
                  }>${colData.colDef.headerName}</th>`;
                } else {
                    printContent += `<th ${
                      alignCellTextRight(colData)
                  }>${colData.colDef.headerName}</th>`;
                }
            }
        }
    );
    printContent += "</tr>";
    printContent += "</thead>";
    printContent += "<tbody>";
    
    if (row_api.getModel().getRowCount() > 0) {
        row_api.forEachNodeAfterFilterAndSort((data) => {
          if(data.data && data.data["pos_name"]){
            data.data = {
                ...data.data,
                pos_name: data.data["pos_name"]
            };
          }
            if (data.group) {
              if (data.data.group) {
                printContent += prepare_print_data_for_grouped_column(header_data, data, [], report_name);
              } else {
                printContent += `<tr>`;
                header_data.map((colData: { colDef: { hide: any; field: string; type: string } }) => {
                  if (!colData.colDef.hide && colData.colDef.field !== 'group' && colData.colDef.field !== 'checkbox') {
                    if (colData.colDef.type == 'hyper_link') {
                      printContent += `<td>${report_data_formatter.get_link_value(
                        data.data[colData.colDef.field]
                      )}</td>`;
                    } else if (data.data[colData.colDef.field]) {
                      printContent += `<td ${
                        alignCellTextRight(colData)} ${heighLightCellText(report_name, colData, data.data)
                      }>${data.data[colData.colDef.field]}</td>`;
                    } else if (data.data[colData.colDef.field] === 0) {
                      printContent += `<td ${
                          alignCellTextRight(colData)} ${heighLightCellText(report_name, colData, data.data)
                      }>${data.data[colData.colDef.field]}</td>`;
                    } else {
                      printContent += `<td></td>`;
                    }
                  }
                });
                printContent += '</tr>';
              }
            } else if (data.parent) {
                printContent += "</tr>";
                if (data.parent.expanded && data.data.details && data.data.details.length > 0) {
                    printContent += `
                    <tr>
                      <td colspan='${header_data.length}' class="subGrid">
                        ${render_inner_grid(data.data.child_column, data.data.details, first_col_width)}
                      </td>
                    </tr>
                  `;
                }
            } else {
                printContent += `<tr>`;
                header_data.map((colData) => {
                    if (!colData.colDef.hide && colData.colDef.field !== "checkbox") {
                        if (colData.colDef.type == "hyper_link") {
                            printContent += `<td>${report_data_formatter.get_link_value(data.data[colData.colDef.field])}</td>`;
                        } else if (data.data[colData.colDef.field]) {
                            printContent += `<td ${
                              alignCellTextRight(colData)} ${heighLightCellText(report_name, colData, data.data)
                            }>${data.data[colData.colDef.field]}</td>`;
                        } else if (data.data[colData.colDef.field] === 0) {
                           printContent += `<td ${
                              alignCellTextRight(colData)} ${heighLightCellText(report_name, colData, data.data)
                            }>${data.data[colData.colDef.field]}</td>`;
                        } else {
                            printContent += `<td></td>`;
                        }
                    }
                });
                printContent += `</tr>`;
            }
        });
    } else {
        printContent += `<tr><td colspan="100%" class="no_records">${report_constants.messages.no_records_found}</td></tr>`;
    }

    // Render Grid Footer Row
    if (row_api.getPinnedBottomRow(0)) {
        let footer_obj = row_api.getPinnedBottomRow(0).data;
        let footer_cell;
        let first_cell = ''
        if (footer_obj) {
            printContent += `<tr class="footer">`;
            header_data.map((colData) => {
                if (colData.colDef.field === 'group' && footer_obj[colData.colDef.field]) {
                  first_cell = footer_obj[colData.colDef.field];
                }
                if (!colData.colDef.hide && colData.colDef.field !== "group" && colData.colDef.field !== "checkbox") {
                    footer_cell = footer_obj[colData.colDef.field] ? footer_obj[colData.colDef.field] : "";
                    printContent += `<td ${
                        alignCellTextRight(colData)
                    }>${footer_cell || first_cell}</td>`;
                    first_cell = '';
                }
            });
            printContent += "</tr>";
        }
    }

    printContent += "</tbody>";
    printContent += "</table>";
    printContent += "</div>";
    // Print functionality start
  if (auto_print) {
    return printContent;
  } else {
    print_html_data_to_pdf(printContent, print_btn_name, report_name);
  }
};

// Function calls on formate print data for grouped column
export const prepare_print_data_for_grouped_column = (
  header_data: { colDef: { hide: any; field: string; type: string } }[],
  data: { level: number; data: { group: any; sum_amount: any; }; parent: { expanded: any; }; expanded: any; childrenAfterSort: any[]; },
  field_ignored: any = [],
  report_name = ''
) => {
  let printContent = '';
  if (
    (data.level === 0 && data.data && data.data.group) ||
    (data.parent && data.parent.expanded && data.data && data.data.group)
  ) {
    printContent += `<tr>`;
    printContent += `<td colspan='${header_data.length}'>
      <b style='padding-left:${15 * data.level}px'>
        ${data.data.group}  ${data.data.sum_amount||''}
      </b>
    </td>`;
    printContent += `</tr>`;
  }
  data.expanded &&
    data.childrenAfterSort.map((item) => {
      if (item.group) {
        prepare_print_data_for_grouped_column(header_data, item, field_ignored, report_name);
      } else {
        printContent += `<tr>`;
        header_data.map((colData: { colDef: { hide: any; field: string; type: string } }) => {
          if (
            !colData.colDef.hide &&
            colData.colDef.field !== 'group' &&
            colData.colDef.field !== 'checkbox' &&
            !field_ignored.includes(colData.colDef.field)
          ) {
            if (colData.colDef.type == 'hyper_link') {
              printContent += `<td>${report_data_formatter.get_link_value(item.data[colData.colDef.field])}</td>`;
            } else if (item.data[colData.colDef.field]) {
              printContent += `<td${
                alignCellTextRight(colData)} ${heighLightCellText(report_name, colData, item.data)
              }>${item.data[colData.colDef.field]}</td>`;
            } else if (item.data[colData.colDef.field] === 0) {
              printContent += `<td${
                alignCellTextRight(colData)} ${heighLightCellText(report_name, colData, item.data)
              }>${item.data[colData.colDef.field]}</td>`;
            } else {
              printContent += `<td></td>`;
            }
          }
        });
        printContent += `</tr>`;
      }
    });
  return printContent;
};

// Function calls on formate export data for grouped column
export const prepare_export_data_for_grouped_column = (
    header_data: { colDef: { hide: any; field: string; type: string } }[],
    data: { level: number; data: { group: any; sum_amount: any; }; parent: { expanded: any; }; expanded: any; childrenAfterSort: any[]; },
    csv: any[],
    field_ignored:any =[]
) => {
    let table_rows = [];
    if (
      (data.level === 0 && data.data && data.data.group) ||
      (data.parent && data.parent.expanded && data.data && data.data.group)
      ) {
        table_rows.push(`"${data.data.group}  ${data.data.sum_amount}"`);
        csv.push(table_rows);
        table_rows = [];
    }
    data.expanded &&
        data.childrenAfterSort.map((item) => {
            if (item.group) {
                prepare_export_data_for_grouped_column(header_data, item.childrenAfterSort, csv, field_ignored);
            } else {
                header_data.map((colData: { colDef: { hide: any; field: string; type: string } }) => {
                    if (
                        !colData.colDef.hide &&
                        colData.colDef.field !== 'group' &&
                        colData.colDef.field !== 'checkbox' &&
                        !field_ignored.includes(colData.colDef.field)
                    ) {
                        if (colData.colDef.type == 'hyper_link') {
                            table_rows.push(
                                `"${report_data_formatter.get_link_value(item.data[colData.colDef.field])}"`
                            );
                        } else if (item.data[colData.colDef.field]) {
                            table_rows.push(`"${strip(item.data[colData.colDef.field])}"`);
                        } else if (item.data[colData.colDef.field] === 0) {
                          table_rows.push(`"${strip(item.data[colData.colDef.field])}"`);
                        } else {
                            table_rows.push('');
                        }
                    }
                });
                csv.push(table_rows);
                table_rows = [];
            }
        });
};

/**
 * get_export_columnKeys- function specify exportable column fields
 * @param report_id
 * @param columns
 */
export const get_export_columnKeys = (report_id, columns = []) => {
    let export_columnKeys = {};
    if (report_id === 30250 && !report_data_formatter.is_hyperlink_type_exist(columns)) {
        columns.map((item) => {
            if (item.field !== 'checkbox') {
                if (!export_columnKeys.hasOwnProperty('columnKeys')) {
                    export_columnKeys['columnKeys'] = []; // initialize property first time
                }
                export_columnKeys['columnKeys'].push(item.field); // add a exportable field
            }
        });
    }
    return export_columnKeys;
}

// Inner Table Grid
export const render_inner_grid = (column_def: any, inner_rows: { [x: string]: any }[], first_col_width: number) => {
  return `
  <table id="subGrid">
  <thead>
      <tr id="inner_table">
          ${inner_render_columns(column_def, first_col_width)}
      </tr>
  </thead>
  <tbody>
      ${inner_render_rows(inner_rows, column_def)}
  </tbody>
</table>`;
};

// Inner Table Grid Header
export const inner_render_columns = (column_def: { field: string; headerName: string }[], first_col_width: number) => {
  let nodes = `<th style="width:${first_col_width - 12}px"><div style="width: ${first_col_width -
    12}px;"></div></th>`;
  column_def.map((item: { field: string; headerName: string }) => {
    const align =
      item.field.toLowerCase().indexOf("decimal") > -1 || item.field.toLowerCase().indexOf("int") > -1
        ? "right"
        : "left";
    nodes = `
          ${nodes}
          <th class="${align}">
            ${item.headerName}
          </th>
          `;
  });
  return nodes;
};

// Inner Table Grid Rows
export const inner_render_rows = (rows: { [x: string]: any }[], column_def) => {
  let nodes = "";
  rows.map((row: { [x: string]: any }) => {
    nodes = `
        ${nodes}
        <tr>
            ${inner_render_row(row, column_def)}
        </tr>`;
  });
  return nodes;
};

// Inner Table Grid Columns
export const inner_render_row = (row: { [x: string]: any }, column_def) => {
  let nodes = `<td></td>`;
  column_def.map((item: { field: string }) => {
    const align =
      item.field.toLowerCase().indexOf("decimal") > -1 || item.field.toLowerCase().indexOf("int") > -1
        ? "right"
        : "left";
    nodes = `
            ${nodes}
            <td class="${align}">${row[item.field] === undefined || row[item.field] === null?'':row[item.field]}</td>
            `;
    });
    return nodes;
};

// Function calls on click of print button to Print Grid Data
export const multi_grid_print = (
  grid_ref: { api: any; columnApi?: any }[],
  title: string[],
  report_name: string,
  field_ignored: string[]
) => {
  let printContent = "";
  grid_ref.map((item, index) => {
    printContent += `<div class="reportPrint">`;
    printContent += `${title[index]}`;
    if (item) {
      printContent += print_grid(item, field_ignored);
    }
    printContent += "</div>";
  });
  // Print functionality start
  print_html_data_to_pdf(printContent, "print_report_button", report_name);
};

const print_grid = (grid_ref: { api: any; columnApi?: any }, field_ignored: any) => {
  let row_api = grid_ref.api;
  let col_api = grid_ref.columnApi;
  let header_data = col_api.getAllColumns();
  let first_col_width: number;
  let printContent = "";
  let grouped_column = col_api.getAllDisplayedColumnGroups();
  let grouped_header = col_api.columnController.primaryHeaderRowCount;
  printContent += '<table id="mainGrid">';
  printContent += "<thead>";
  /**
   * If Grid had multiple header
   */
  // Render Grid Parent Header
  if (grouped_column && grouped_header > 1) {
    printContent += "<tr>";
    grouped_column.map((colData) => {
      printContent += `<th colspan="${
        colData.originalColumnGroup.children && colData.originalColumnGroup.children.length
      }" class="center">${colData.originalColumnGroup.colGroupDef.headerName}</th>`;
    });
    printContent += "</tr>";
  }

  // Render Grid Header
  printContent += "<tr>";
  header_data.map(
    (colData: { colDef: { width: any; hide: any; field: string; headerName: any; type: any } }, index: number) => {
      if (
        !colData.colDef.hide &&
        colData.colDef.field !== "group" &&
        colData.colDef.field !== "checkbox" &&
        !field_ignored.includes(colData.colDef.field)
      ) {
        if (index == 1) {
          first_col_width = Math.round(colData.colDef.width);
          printContent += `<th style="width: ${first_col_width + "px" || "auto"};" ${
            alignCellTextRight(colData)
          }>${colData.colDef.headerName}</th>`;
        } else {
          printContent += `<th ${
            alignCellTextRight(colData)
          }>${colData.colDef.headerName}</th>`;
        }
      }
    }
  );
  printContent += "</tr>";
  printContent += "</thead>";
  printContent += "<tbody>";

  if (row_api.getModel().getRowCount() > 0) {
    row_api.forEachNodeAfterFilterAndSort((data) => {
      if (data.data && data.data["pos_name"]) {
        data.data = {
          ...data.data,
          pos_name: data.data["pos_name"]
        };
      }
      if (data.group) {
        if (data.data.group) {
          printContent += prepare_print_data_for_grouped_column(header_data, data, field_ignored);
        } else {
          printContent += `<tr>`;
          header_data.map((colData: { colDef: { hide: any; field: string; type: string } }) => {
            if (
              !colData.colDef.hide &&
              colData.colDef.field !== "group" &&
              colData.colDef.field !== "checkbox" &&
              !field_ignored.includes(colData.colDef.field)
            ) {
              if (colData.colDef.type == "hyper_link") {
                printContent += `<td>${report_data_formatter.get_link_value(data.data[colData.colDef.field])}</td>`;
              } else if (data.data[colData.colDef.field]) {
                printContent += `<td ${
                  alignCellTextRight(colData)
                }>${data.data[colData.colDef.field]}</td>`;
              } else if (data.data[colData.colDef.field] === 0) {
                printContent += `<td ${
                  alignCellTextRight(colData)
                }>${data.data[colData.colDef.field]}</td>`;
              } else {
                printContent += `<td></td>`;
              }
            }
          });
          printContent += "</tr>";
        }
      } else if (data.parent) {
        printContent += "</tr>";
        if (data.parent.expanded && data.data.details && data.data.details.length > 0) {
          printContent += `
                    <tr>
                      <td colspan='${header_data.length}' class="subGrid">
                        ${render_inner_grid(data.data.child_column, data.data.details, first_col_width)}
                      </td>
                    </tr>
                  `;
        }
      } else {
        printContent += `<tr>`;
        header_data.map((colData) => {
          if (
            !colData.colDef.hide &&
            colData.colDef.field !== "checkbox" &&
            !field_ignored.includes(colData.colDef.field)
          ) {
            if (colData.colDef.type == "hyper_link") {
              printContent += `<td>${report_data_formatter.get_link_value(data.data[colData.colDef.field])}</td>`;
            } else if (data.data[colData.colDef.field]) {
              printContent += `<td ${
                alignCellTextRight(colData)
              }>${data.data[colData.colDef.field]}</td>`;
            } else if (data.data[colData.colDef.field] === 0) {
              printContent += `<td ${
                alignCellTextRight(colData)
              }>${data.data[colData.colDef.field]}</td>`;
            } else {
              printContent += `<td></td>`;
            }
          }
        });
        printContent += `</tr>`;
      }
    });
  } else {
    printContent += `<tr><td colspan="100%" class="no_records">${report_constants.messages.no_records_found}</td></tr>`;
  }

  // Render Grid Footer Row
  if (row_api.getPinnedBottomRow(0)) {
    let footer_obj = row_api.getPinnedBottomRow(0).data;
    let footer_cell;
    if (footer_obj) {
      printContent += `<tr class="footer">`;
      header_data.map((colData) => {
        if (
          !colData.colDef.hide &&
          colData.colDef.field !== "group" &&
          colData.colDef.field !== "checkbox" &&
          !field_ignored.includes(colData.colDef.field)
        ) {
          footer_cell = footer_obj[colData.colDef.field] ? footer_obj[colData.colDef.field] : "";
          printContent += `<td ${
            alignCellTextRight(colData)
          }>${footer_cell}</td>`;
        }
      });
      printContent += "</tr>";
    }
  }

  printContent += "</tbody>";
  printContent += "</table>";
  return printContent;
};

// Function calls on click of print button to Print card Data
export const card_print = (report_name: string, template: string) => {
    let printContent = `<div class="reportPrint">${template}</div>`;
    // Print functionality start
    print_html_data_to_pdf(printContent, "print_report_button", report_name);
};

// Function calls on click of Export button for Export Grid Data into CSV
export const multi_grid_export = (
  grid_ref: { api: any; columnApi?: any }[],
  title: string[],
  report_name: string,
  field_ignored: string[]
) => {
  let csv = [];
  let title_row = [];

  grid_ref.map((item, index) => {
    title_row.push(title[index]);
    csv.push(title_row);
    title_row = [];
    if (item) {
      export_grid(item, csv, field_ignored);
    }
  });
  // Download CSV File
  downloadCSV(csv, report_name);
};

const export_grid = (grid_ref: { api: any; columnApi?: any }, csv, field_ignored: any) => {
  let row_api = grid_ref.api;
  let col_api = grid_ref.columnApi;
  let header_data = col_api.getAllColumns();
  let table_header = [];
  let table_rows = [];
  let grouped_column = col_api.getAllDisplayedColumnGroups();
  let grouped_header = col_api.columnController.primaryHeaderRowCount;
  /**
   * If Grid had multiple header
   */
  // Render Grid Parent Header
  if (grouped_column && grouped_header > 1) {
    grouped_column.map((colData, index: number) => {
      for (let index = 0; index < colData.children.length; index++) {
        let header_name = "";
        if (colData.children.length > 2) {
          if (Math.floor(colData.children.length / 2) == index) {
            header_name = colData.originalColumnGroup.colGroupDef.headerName;
          } else {
            header_name = "";
          }
        } else {
          // fall in case of 1, 2
          if (index == 0) {
            header_name = colData.originalColumnGroup.colGroupDef.headerName;
          } else {
            header_name = "";
          }
        }
        table_header.push(header_name);
      }
    });
    csv.push(table_header);
    table_header = [];
  }

  // Render Grid Header
  header_data.map((colData: { colDef: { hide: any; field: string; headerName: string } }) => {
    if (
      !colData.colDef.hide &&
      colData.colDef.field !== "group" &&
      colData.colDef.field !== "checkbox" &&
      !field_ignored.includes(colData.colDef.field)
    ) {
      table_header.push(colData.colDef.headerName);
    }
  });
  csv.push(table_header);
  table_header = [];

  if (row_api.getModel().getRowCount() > 0) {
    row_api.forEachNodeAfterFilterAndSort((data) => {
      if (data.group && !data.data.details) {
        if (data.data.group) {
          prepare_export_data_for_grouped_column(header_data, data, csv, field_ignored);
        } else {
          header_data.map((colData: { colDef: { hide: any; field: string; type: string } }) => {
            if (
              !colData.colDef.hide &&
              colData.colDef.field !== "group" &&
              colData.colDef.field !== "checkbox" &&
              !field_ignored.includes(colData.colDef.field)
            ) {
              if (data.data[colData.colDef.field]) {
                if (colData.colDef.type == "hyper_link") {
                  table_rows.push(`"${report_data_formatter.get_link_value(data.data[colData.colDef.field])}"`);
                } else {
                  table_rows.push(`"${data.data[colData.colDef.field]}"`);
                }
              } else if (data.data[colData.colDef.field] === 0) {
                table_rows.push(`"${data.data[colData.colDef.field]}"`);
              } else {
                table_rows.push("");
              }
            }
          });
          csv.push(table_rows);
          table_rows = [];
        }
      } else if (data.parent) {
        if (data.parent.expanded && data.data.details && data.data.details.length > 0) {
          let sub_header = [""];
          data.data.child_column.map((item: { headerName: string }) => {
            sub_header.push(`"${item.headerName}"`);
          });
          csv.push(sub_header);
          sub_header = [];
          data.data.details.map((row: { [x: string]: any }) => {
            let sub_row = [""];
            data.data.child_column.map((item: { field: string; type: string }) => {
              if (item.type == "hyper_link") {
                sub_row.push(`"${report_data_formatter.get_link_value(row[item.field])}"`);
              } else {
                sub_row.push(`"${row[item.field]}"`);
              }
            });
            csv.push(sub_row);
            sub_row = [];
          });
        }
      } else {
        // Render expended Grid row
        header_data.map((colData: { colDef: { hide: any; field: string; type: string } }) => {
          if (
            !colData.colDef.hide &&
            colData.colDef.field !== "group" &&
            colData.colDef.field !== "checkbox" &&
            !field_ignored.includes(colData.colDef.field)
          ) {
            if (data.data[colData.colDef.field]) {
              if (colData.colDef.type == "hyper_link") {
                table_rows.push(`"${report_data_formatter.get_link_value(data.data[colData.colDef.field])}"`);
              } else {
                if(colData.colDef.field == "treating_provider_license" || colData.colDef.field == "cpt_code"){
                  table_rows.push(`="${data.data[colData.colDef.field].toString().trim()}"`);
                }else{
                  table_rows.push(`"${data.data[colData.colDef.field]}"`);
                }
              }
            } else if (data.data[colData.colDef.field] === 0) {
              table_rows.push(`"${data.data[colData.colDef.field]}"`);
            } else {
              table_rows.push("");
            }
          }
        });
        csv.push(table_rows);
        table_rows = [];
      }
    });
  } else {
    for (let index = 0; index < header_data.length; index++) {
      let header_name = "";
      if (header_data.length > 2) {
        if (Math.floor(header_data.length / 2) == index) {
          header_name = report_constants.messages.no_records_found;
        } else {
          header_name = "";
        }
      } else {
        // fall in case of 1, 2
        if (index == 0) {
          header_name = report_constants.messages.no_records_found;
        } else {
          header_name = "";
        }
      }
      table_rows.push(header_name);
    }

    // table_rows.push(`"${messages.no_records_found}"`);
    csv.push(table_rows);
    table_rows = [];
  }
  // Render Grid Footer Row
  if (row_api.getPinnedBottomRow(0)) {
    let footer_obj = row_api.getPinnedBottomRow(0).data;
    let footer_cell: any;
    let footer_row = [];
    if (footer_obj) {
      header_data.map((colData: { colDef: { hide: any; field: string } }) => {
        if (
          !colData.colDef.hide &&
          colData.colDef.field !== "group" &&
          colData.colDef.field !== "checkbox" &&
          !field_ignored.includes(colData.colDef.field)
        ) {
          footer_cell = footer_obj[colData.colDef.field] ? footer_obj[colData.colDef.field] : "";
          footer_row.push(`"${footer_cell}"`);
        }
      });
      csv.push(footer_row);
    }
  }
  return csv;
};

// Function calls on click of Export button for Export Grid Data into CSV
export const export_grid_pagination_data = (grid_ref, title: string, report_name: string, field_ignored) => {
  let csv = [];
  let row_api = grid_ref.api;
  let col_api = grid_ref.columnApi;
  let header_data = col_api.getAllColumns();
  let table_header = [];
  let table_rows = [];
  let title_row = [];
  title_row.push(title);
  csv.push(title_row);

  let grouped_column = col_api.getAllDisplayedColumnGroups();
  let grouped_header = col_api.columnController.primaryHeaderRowCount;
  /**
   * If Grid had multiple header
   */
  // Render Grid Parent Header
  if (grouped_column && grouped_header > 1) {
    grouped_column.map((colData, index: number) => {
      for (let index = 0; index < colData.children.length; index++) {
        let header_name = '';
        if (colData.children.length > 2) {
          if (Math.floor(colData.children.length / 2) == index) {
            header_name = colData.originalColumnGroup.colGroupDef.headerName;
          } else {
            header_name = '';
          }
        } else {
          // fall in case of 1, 2
          if (index == 0) {
            header_name = colData.originalColumnGroup.colGroupDef.headerName;
          } else {
            header_name = '';
          }
        }
        table_header.push(header_name);
      }
    });
    csv.push(table_header);
    table_header = [];
  }

  // Render Grid Header
  header_data.map((colData: { colDef: { hide: any; field: string; headerName: string } }) => {
    if (
      !colData.colDef.hide &&
      colData.colDef.field !== 'group' &&
      colData.colDef.field !== 'checkbox' &&
      !field_ignored.includes(colData.colDef.field)
    ) {
      table_header.push(colData.colDef.headerName);
    }
  });
  csv.push(table_header);
  table_header = [];

  if (row_api.getModel().getRowCount() > 0) {
    row_api.forEachNodeAfterFilterAndSort((node) => {
      if (
        node.rowIndex >= grid_ref.api.paginationProxy.topRowIndex &&
        node.rowIndex <= grid_ref.api.paginationProxy.bottomRowIndex
      ) {
        if (node.group && !node.data.details) {
          if (node.data.group) {
            prepare_export_data_for_grouped_column(header_data, node, csv);
          } else {
            header_data.map((colData: { colDef: { hide: any; field: string; type: string } }) => {
              if (
                !colData.colDef.hide &&
                colData.colDef.field !== 'group' &&
                colData.colDef.field !== 'checkbox' &&
                !field_ignored.includes(colData.colDef.field)
              ) {
                if (node.data[colData.colDef.field]) {
                  if (colData.colDef.type == 'hyper_link') {
                    table_rows.push(`"${report_data_formatter.get_link_value(node.data[colData.colDef.field])}"`);
                  } else {
                    table_rows.push(`"${node.data[colData.colDef.field]}"`);
                  }
                } else if (node.data[colData.colDef.field] === 0) {
                  table_rows.push(`"${node.data[colData.colDef.field]}"`);
                } else {
                  table_rows.push('');
                }
              }
            });
            csv.push(table_rows);
            table_rows = [];
          }
        } else if (node.parent) {
          if (node.parent.expanded && node.data.details && node.data.details.length > 0) {
            let sub_header = [''];
            node.data.child_column.map((item: { headerName: string }) => {
              sub_header.push(`"${item.headerName}"`);
            });
            csv.push(sub_header);
            sub_header = [];
            node.data.details.map((row: { [x: string]: any }) => {
              let sub_row = [''];
              node.data.child_column.map((item: { field: string; type: string }) => {
                if (item.type == 'hyper_link') {
                  sub_row.push(`"${report_data_formatter.get_link_value(row[item.field])}"`);
                } else {
                  sub_row.push(`"${row[item.field]}"`);
                }
              });
              csv.push(sub_row);
              sub_row = [];
            });
          }
        } else {
          // Render expended Grid row
          header_data.map((colData: { colDef: { hide: any; field: string; type: string } }) => {
            if (
              !colData.colDef.hide &&
              colData.colDef.field !== 'group' &&
              colData.colDef.field !== 'checkbox' &&
              !field_ignored.includes(colData.colDef.field)
            ) {
              if (node.data[colData.colDef.field]) {
                if (colData.colDef.type == 'hyper_link') {
                  table_rows.push(`"${report_data_formatter.get_link_value(node.data[colData.colDef.field])}"`);
                } else {
                  table_rows.push(`"${node.data[colData.colDef.field]}"`);
                }
              } else if (node.data[colData.colDef.field] === 0) {
                table_rows.push(`"${node.data[colData.colDef.field]}"`);
              } else {
                table_rows.push('');
              }
            }
          });
          csv.push(table_rows);
          table_rows = [];
        }
      }
    });
  } else {
    for (let index = 0; index < header_data.length; index++) {
      let header_name = '';
      if (header_data.length > 2) {
        if (Math.floor(header_data.length / 2) == index) {
          header_name = report_constants.messages.no_records_found;
        } else {
          header_name = '';
        }
      } else {
        // fall in case of 1, 2
        if (index == 0) {
          header_name = report_constants.messages.no_records_found;
        } else {
          header_name = '';
        }
      }
      table_rows.push(header_name);
    }

    // table_rows.push(`"${messages.no_records_found}"`);
    csv.push(table_rows);
    table_rows = [];
  }
  // Render Grid Footer Row
  if (row_api.getPinnedBottomRow(0)) {
    let footer_obj = row_api.getPinnedBottomRow(0).data;
    let footer_cell: any;
    let footer_row = [];
    if (footer_obj) {
      header_data.map((colData: { colDef: { hide: any; field: string } }) => {
        if (
          !colData.colDef.hide &&
          colData.colDef.field !== 'group' &&
          colData.colDef.field !== 'checkbox' &&
          !field_ignored.includes(colData.colDef.field)
        ) {
          footer_cell = footer_obj[colData.colDef.field] ? footer_obj[colData.colDef.field] : '';
          footer_row.push(`"${footer_cell}"`);
        }
      });
      csv.push(footer_row);
    }
  }
  // Download CSV File
  downloadCSV(csv, report_name);
};

// Function calls on click of print button to Print Grid Data
export const print_grid_pagination_data = (
  grid_ref: { api: any; columnApi?: any },
  title: string,
  report_name: string,
  print_btn_name: string = 'print_report_button',
  field_ignored: any
) => {
  if (!print_btn_name) {
    print_btn_name = 'print_report_button';
  }
  let row_api = grid_ref.api;
  let col_api = grid_ref.columnApi;
  let header_data = col_api.getAllColumns();
  let first_col_width: number;
  let printContent = '';
  printContent += `<div class="reportPrint">${title}<table id="mainGrid"><thead>`;
  let grouped_column = col_api.getAllDisplayedColumnGroups();
  let grouped_header = col_api.columnController.primaryHeaderRowCount;
  /**
   * If Grid had multiple header
   */
  // Render Grid Parent Header
  if (grouped_column && grouped_header > 1) {
    printContent += '<tr>';
    grouped_column.map((colData, index: number) => {
      printContent += `<th colspan="${
        colData.originalColumnGroup.children && colData.originalColumnGroup.children.length
      }" class="center">${colData.originalColumnGroup.colGroupDef.headerName}</th>`;
    });
    printContent += '</tr>';
  }

  // Render Grid Header
  printContent += '<tr>';
  header_data.map(
    (colData: { colDef: { width: any; hide: any; field: string; headerName: any; type: any } }, index: number) => {
      if (
        !colData.colDef.hide &&
        colData.colDef.field !== 'group' &&
        colData.colDef.field !== 'checkbox' &&
        !field_ignored.includes(colData.colDef.field)
      ) {
        if (index == 1) {
          first_col_width = Math.round(colData.colDef.width);
          printContent += `<th style="width: ${first_col_width + 'px' || 'auto'};" ${
            alignCellTextRight(colData)
          }>${colData.colDef.headerName}</th>`;
        } else {
          printContent += `<th ${
            alignCellTextRight(colData)
          }>${colData.colDef.headerName}</th>`;
        }
      }
    }
  );
  printContent += '</tr>';
  printContent += '</thead>';
  printContent += '<tbody>';

  if (row_api.getModel().getRowCount() > 0) {
    row_api.forEachNodeAfterFilterAndSort((node) => {
      if (
        node.rowIndex >= grid_ref.api.paginationProxy.topRowIndex &&
        node.rowIndex <= grid_ref.api.paginationProxy.bottomRowIndex
      ) {
        if (node.data && node.data['pos_name']) {
          node.data = {
            ...node.data,
            pos_name: node.data['pos_name']
          };
        }
        if (node.group) {
          if (node.data.group) {
            printContent += prepare_print_data_for_grouped_column(header_data, node);
          } else {
            printContent += `<tr>`;
            header_data.map((colData: { colDef: { hide: any; field: string; type: string } }) => {
              if (
                !colData.colDef.hide &&
                colData.colDef.field !== 'group' &&
                colData.colDef.field !== 'checkbox' &&
                !field_ignored.includes(colData.colDef.field)
              ) {
                if (colData.colDef.type == 'hyper_link') {
                  printContent += `<td>${report_data_formatter.get_link_value(node.data[colData.colDef.field])}</td>`;
                } else if (node.data[colData.colDef.field]) {
                  printContent += `<td ${
                    alignCellTextRight(colData)
                  }>${node.data[colData.colDef.field]}</td>`;
                } else if (node.data[colData.colDef.field] === 0) {
                  printContent += `<td ${
                    alignCellTextRight(colData)
                  }>${node.data[colData.colDef.field]}</td>`;
                } else {
                  printContent += `<td></td>`;
                }
              }
            });
            printContent += '</tr>';
          }
        } else if (node.parent) {
          printContent += '</tr>';
          if (node.parent.expanded && node.data.details && node.data.details.length > 0) {
            printContent += `
                    <tr>
                      <td colspan='${header_data.length}' class="subGrid">
                        ${render_inner_grid(node.data.child_column, node.data.details, first_col_width)}
                      </td>
                    </tr>
                  `;
          }
        } else {
          printContent += `<tr>`;
          header_data.map((colData) => {
            if (
              !colData.colDef.hide &&
              colData.colDef.field !== 'checkbox' &&
              !field_ignored.includes(colData.colDef.field)
            ) {
              if (colData.colDef.type == 'hyper_link') {
                printContent += `<td>${report_data_formatter.get_link_value(node.data[colData.colDef.field])}</td>`;
              } else if (node.data[colData.colDef.field]) {
                printContent += `<td ${
                  alignCellTextRight(colData)
                }>${node.data[colData.colDef.field]}</td>`;
              } else if (node.data[colData.colDef.field] === 0) {
                printContent += `<td ${
                  alignCellTextRight(colData)
                }>${node.data[colData.colDef.field]}</td>`;
              } else {
                printContent += `<td></td>`;
              }
            }
          });
          printContent += `</tr>`;
        }
      }
    });
  } else {
    printContent += `<tr><td colspan="100%" class="no_records">${report_constants.messages.no_records_found}</td></tr>`;
  }

  // Render Grid Footer Row
  if (row_api.getPinnedBottomRow(0)) {
    let footer_obj = row_api.getPinnedBottomRow(0).data;
    let footer_cell;
    if (footer_obj) {
      printContent += `<tr class="footer">`;
      header_data.map((colData) => {
        if (
          !colData.colDef.hide &&
          colData.colDef.field !== 'group' &&
          colData.colDef.field !== 'checkbox' &&
          !field_ignored.includes(colData.colDef.field)
        ) {
          footer_cell = footer_obj[colData.colDef.field] ? footer_obj[colData.colDef.field] : '';
          printContent += `<td ${
            alignCellTextRight(colData)
          }>${footer_cell}</td>`;
        }
      });
      printContent += '</tr>';
    }
  }

  printContent += '</tbody>';
  printContent += '</table>';
  printContent += '</div>';

  // Print functionality start
  print_html_data_to_pdf(printContent, 'print_report_button', report_name);
};

export const open_printable_report_new_window = (printable_data: string, report_id: string, after_download_pdf: (arg0: boolean) => void) => {
  let print_content = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <Title>${document.title}</Title>
      <link rel="stylesheet" type="text/css" href="${window.location.origin}/src/assets/css/main.css?ver=1234">
      <link rel="stylesheet" type="text/css" href="${window.location.origin}/src/assets/css/custom.css">
      <link rel="stylesheet" type="text/css" href="${window.location.origin}/src/assets/css/printstyle.css">
      <link rel="stylesheet" type="text/css" href="${window.location.origin}/src/assets/css/printable_report_new_tab.css">
    </head>
    <body>
      <div id="printElement">`;
  print_content += printable_data;
  print_content += `
      </div>
      <div class="footer_section">
        <button id="print_report_close" type="submit" class="ui basic button" role="button" onClick="window.close()">Close</button>
        <button id="print_report_new" type="submit" class="ui primary button" role="button" onClick="printPdf()">Print</button>
      </div>
      <script type="text/javascript">
      function is_safari() {
          var isSafari =
            navigator.vendor &&
            navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') == -1 &&
            navigator.userAgent.indexOf('FxiOS') == -1;
          return isSafari;
        };
        
        function isSafariMobileDevice() {
          var ua = window.navigator.userAgent;
          var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
          var webkit = !!ua.match(/WebKit/i);
          var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
          return iOSSafari;
        };
        function detect_ie() {
          var ua = window.navigator.userAgent;
          var msie = ua.indexOf('MSIE ');
          if (msie > 0) {
              // IE 10 or older => return version number
              return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
          }
      
          var trident = ua.indexOf('Trident/');
          if (trident > 0) {
              // IE 11 => return version number
              var rv = ua.indexOf('rv:');
              return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
          }
      
          var edge = ua.indexOf('Edge/');
          if (edge > 0) {
              // Edge (IE 12+) => return version number
              return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
          }
          // other browser
          return false;
      }
      
        function execute_print() {
          if (navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
            if (isSafariMobileDevice()) {
              document.execCommand('print');
            } else {
              window.print();
            }
          } else {
            if (is_safari()) {
              document.execCommand('print');
            } else {
              if(detect_ie()) {
                document.execCommand('print');
              } else {
                window.print();
              }
            }
          }
        };
        
        function printPdf () {
            const print_report_button = document.getElementById('print_report_new');
            if (window.print) {
              if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
                setTimeout(function () {
                  execute_print();
                }, 100);
                setTimeout(function () {
                  print_report_button.blur();
                }, 4000);
              } else {
                if (window.matchMedia) {
                  let mediaQueryList = window.matchMedia('print');
                  mediaQueryList.addListener(function (mql) {
                    if (mql.matches) {
                    } else {
                      afterPrint();
                    }
                  });
                }
                let afterPrint = function afterPrint() {
                  print_report_button.blur();
                };
                // These are for Firefox
                window.onafterprint = afterPrint;
                execute_print();
              }
            }
        }
      </script>
    </body>
  </html>`;
  if (is_safari()) {
    const blob = new Blob([print_content], { type: 'text/html' });
    const blobUrl = URL.createObjectURL(blob);
    let win = window.open(blobUrl, report_id );
    if (win) {
      win.onload = () => {
        window.URL.revokeObjectURL(blobUrl);
        after_download_pdf(false);
      };
    }
  } else {
    let win = window.open('', report_id);
    if (win) {
      win.document.head.innerHTML = '';
      win.document.body.innerHTML = '';
      win.document.write(print_content);
      win.focus();
      after_download_pdf(false);
    }
  }
}

export const heighLightCellText =(report_name, colData, data )=>{
  return report_name == 'Expected Rates Report' &&
      ((colData.colDef.field === 'stringCol4' && +data['int32Col0']) ||
          (colData.colDef.field === 'stringCol5' && +data['int32Col1']))
      ? ' style="color:red"'
      : '';
}

export const alignCellTextRight =(colData)=>{
  return colData.colDef.type == 'currency' || colData.colDef.type == 'number' || colData.colDef.type == 'link'
      ? ` class="right"`
      : '';
}

// Function calls on click of print button to Print Grid Data
export const failed_charges_print = (
    grid_ref: { api: any; columnApi?: any },
    title: string,
    report_name: string,
    print_btn_name: string = 'print_report_button',
    auto_print = false
) => {
    if (!print_btn_name) {
        print_btn_name = 'print_report_button';
    }
    let row_api = grid_ref.api;
    let col_api = grid_ref.columnApi;
    let header_data = col_api.getAllColumns();
    let first_col_width: number;
    let printContent = '';
    printContent += `<div class="reportPrint">
  <div style="display:flex; justify-content: space-between;">
    <div>${title}</div>
    <div>
    <table>
    <tbody>`;
    if (row_api.getModel().getRowCount() > 0) {
        row_api.forEachNodeAfterFilterAndSort((data) => {
            header_data.map((colData, index) => {
                if (header_data.length - 1 !== index) {
                    printContent += `<tr>`;
                    printContent += `<td ${alignCellTextRight(colData)}>${colData.colDef.headerName}: </td>`;
                    if (!colData.colDef.hide && colData.colDef.field !== 'checkbox') {
                        if (colData.colDef.type == 'hyper_link') {
                            printContent += `<td>${report_data_formatter.get_link_value(
                                data.data[colData.colDef.field]
                            )}</td>`;
                        } else if (data.data[colData.colDef.field]) {
                            printContent += `<td ${alignCellTextRight(colData)} ${heighLightCellText(
                                report_name,
                                colData,
                                data.data[colData.colDef.field]
                            )}>${data.data[colData.colDef.field]}</td>`;
                        } else if (data.data[colData.colDef.field] === 0) {
                            printContent += `<td ${alignCellTextRight(colData)} ${heighLightCellText(
                                report_name,
                                colData,
                                data.data[colData.colDef.field]
                            )}>${data.data[colData.colDef.field]}</td>`;
                        } else {
                            printContent += `<td></td>`;
                        }
                    }
                    printContent += `</tr>`;
                }
            });
        });
    } else {
        printContent += `<tr><td colspan="100%" class="no_records">${report_constants.messages.no_records_found}</td></tr>`;
    }
    printContent += `
  </tbody>
  </table>
  </div>
  </div>
  <table id="mainGrid">
  <thead>`;
    // Render Grid Header
    printContent += '<tr>';
    header_data.map(
        (colData: { colDef: { width: any; hide: any; field: string; headerName: any; type: any } }, index: number) => {
            if (header_data.length - 1 === index) {
                if (!colData.colDef.hide && colData.colDef.field !== 'group' && colData.colDef.field !== 'checkbox') {
                    if (index == 1) {
                        first_col_width = Math.round(colData.colDef.width);
                        printContent += `<th style="width: ${first_col_width + 'px' || 'auto'};" ${alignCellTextRight(
                            colData
                        )}>${colData.colDef.headerName}</th>`;
                    } else {
                        printContent += `<th ${alignCellTextRight(colData)}>${colData.colDef.headerName}</th>`;
                    }
                }
            }
        }
    );
    printContent += '</tr>';
    printContent += '</thead>';
    printContent += '<tbody>';

    if (row_api.getModel().getRowCount() > 0) {
        row_api.forEachNodeAfterFilterAndSort((data) => {
            printContent += `<tr>`;
            header_data.map((colData, index) => {
                if (header_data.length - 1 === index) {
                    if (!colData.colDef.hide && colData.colDef.field !== 'checkbox') {
                        if (colData.colDef.type == 'hyper_link') {
                            printContent += `<td style="white-space: pre-wrap;">${report_data_formatter.get_link_value(
                                data.data[colData.colDef.field]
                            )}</td>`;
                        } else if (data.data[colData.colDef.field]) {
                            printContent += `<td style="white-space: pre-wrap;" ${alignCellTextRight(colData)} ${heighLightCellText(
                                report_name,
                                colData,
                                data.data[colData.colDef.field]
                            )}>${data.data[colData.colDef.field]}</td>`;
                        } else if (data.data[colData.colDef.field] === 0) {
                            printContent += `<td style="white-space: pre-wrap;" ${alignCellTextRight(colData)} ${heighLightCellText(
                                report_name,
                                colData,
                                data.data[colData.colDef.field]
                            )}>${data.data[colData.colDef.field]}</td>`;
                        } else {
                            printContent += `<td></td>`;
                        }
                    }
                }
            });
            printContent += `</tr>`;
        });
    } else {
        printContent += `<tr><td colspan="100%" class="no_records">${report_constants.messages.no_records_found}</td></tr>`;
    }

    printContent += '</tbody>';
    printContent += '</table>';
    printContent += '</div>';
    // Print functionality start
    if (auto_print) {
        return printContent;
    } else {
        print_html_data_to_pdf(printContent, print_btn_name, report_name);
    }
};

export const generateAuditDataEntryPayload = (payload: report_constants.IPayloadForBillingAudit) => {
  let reqDataEntry = {
    context: payload.contextTitle,
    user_id: payload.userId,
    company_id: payload.companyId,
    entity_type: ReportConstants.ReportEntityTypeId[payload.entityTypeId],
    event_action: ReportConstants.ReportEventAction[payload.eventActionId],
    event_date_time: new Date().toISOString(),
    event_meta_data: payload.data == null ? "" : payload.data,
    source_id: "billing"
  };
  let reqBody: ReportConstants.IRequestBodyForBillingAudit = {
    reportId: payload.reportId,
    reportEntityTypeId: payload.entityTypeId,
    reportEventActionId: payload.eventActionId,
    reportDataEntry: JSON.stringify(reqDataEntry),
  };
  return reqBody;
};