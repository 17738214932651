import { min } from "moment";
import * as React from "react";
class InnerGridTableRendererComponent extends React.Component<any, any> {
    constructor(props) {
        super(props);
    }
    render() {
        return (
                <div className="grid_inner_table">
                    <div className="table-responsive custom-default-table">
                        <table style={{paddingLeft:'69px'}}>
                            <thead>
                                <tr id="inner_table">
                                    {this.props.data.child_column.map((item: any, index: any) => {
                                        return (<th style={{width:item.width,maxWidth:item.width,minWidth:item.width,textAlign:item.align}} key={index}>{item.headerName}</th>)
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.data.details.map((item: any, index: any) => {
                                    return (<tr key={index}>
                                        {this.props.data.child_column.map((col: any, index: any) => {
                                            return (<td key={index} style={{textAlign: col.align }}>{item[col.field]}</td>)
                                        })}
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
        )
    }
}

export default InnerGridTableRendererComponent;