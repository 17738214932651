import * as sjcl from './sjcl';
import { get_private_key_for_encryption } from './../action/shared_action';
var CryptoJS = require('crypto-js');
import { constants } from '../../global_constants';
export var sjcl_password: string = null;

export default class CryptoService {

    static sjcl_properties: any = { adata: '', iter: 1000, mode: 'ccm', ts: 64, ks: 256, iv: sjcl.codec.base64.fromBits([294185508, -696763750, 1782465994, 327296730]), v: 1, cipher: 'aes', salt: null };

    static set_value = (value) => {
        sjcl_password = value;
    }

    /**
     * Get Encryption password from api
     * */
   static get_encryption_key = () => {
        if (!sjcl_password) {
            return get_private_key_for_encryption().then(res => {
                if (res.status == 200) {
                    sjcl_password = res.data.value;
                    return res.data.value;
                }
            });
        }
        return;
    }

    /**
     * We are using Stanford Javascript Crypto Library with aes cypher to encrypt data
     * */
   static encrypt = (data) => {

       if (sjcl_password) {
           
           // old ccm algo
           //CryptoService.sjcl_properties.salt = sjcl_password;
           //let encrypted = JSON.parse(sjcl.encrypt(sjcl_password, data, CryptoService.sjcl_properties, {}));
           //return encrypted.ct;

           let encrypted = CryptoJS.AES.encrypt(data, sjcl_password);
           return encrypted.toString();
        }
    }

     /**
     * We are using Stanford Javascript Crypto Library with aes cypher to decrypt data
     * */
    static decrypt = (data) => {
        if (sjcl_password) {
            // old ccm algo
            //CryptoService.sjcl_properties.ct = data;
            //CryptoService.sjcl_properties.salt = sjcl_password;
            //return sjcl.decrypt(sjcl_password, JSON.stringify(CryptoService.sjcl_properties), {}, {});

            let bytes = CryptoJS.AES.decrypt(data, sjcl_password);
            let plaintext = bytes.toString(CryptoJS.enc.Utf8);

            return plaintext;
        }
    }

    /*
     * This method is used to encrypt user password.
     * */
    static encrypt_password = (data) => {

        var key = CryptoJS.enc.Utf8.parse(constants.password_encrpytion_key);
        var iv = CryptoJS.enc.Utf8.parse(constants.password_encrpytion_key);
        var encryptedlogin = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), key,
            {
                keySize: 128 / 8,
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
        return encryptedlogin.toString();
    }

} 

