import { date_format } from '../../global_constants';
import { caseFormat, custom_date_format } from '../../shared/utility';

// Format report data on lebel 1
export const format_reports = items => {
    if (items && items.data && items.data.length > 0) {
        return items.data.map((value) => {
            const name = `${custom_date_format(value.start_of_care_date, date_format["mm/dd/yyyy"])} ${case_name(value.case_name)}`;
            return {
                group: name,
                participants: formatParticipants(value.output_report, name)
            }
        });
    }
    return [];
}



// Format report data on level 2
const formatParticipants = (subitems, parentName) => {
    if (subitems && subitems.length > 0) {
        return subitems.map((value) => {
            if (value.note_info) {
                value.note_info.created_date = custom_date_format(value.note_info.created_date, date_format["mm/dd/yyyy"]);
                value.note_info.date_of_service = custom_date_format(value.note_info.date_of_service, date_format["mm/dd/yyyy"])
            }
            return {
                ...value,
                created_date: custom_date_format(value.created_date, date_format["mm/dd/yyyy"]),
                group: `${value.report_name || ''}`,
                parent_name: parentName
            }
        });
    }
    return null;
}

// Document case name function
const case_name = (value) => {
    if (value) {
        return ` - ${value}`;
    }
    return '';
}

// Document name function
const get_document = (value, date) => {
    if (value) {
        //return `${value} ${custom_date_format(date, date_format["mm/dd/yyyy h:MM:ss TT"])}`;
        return `${value}`;
    }
    return '';
}

// Format documents functions
export const format_documents = items => {
    if (items && items.data && items.data.length > 0) {
        return items.data.map(item => {
            const { document_name, case_info, created_date, document_date, category_type, image_type } = item;
            item.created_date = custom_date_format(created_date, date_format["mm/dd/yyyy h:MM:ss TT"]);
            item.document_name_display = get_document(document_name, created_date);
            item.document_date_display = custom_date_format(item.document_date, date_format["mm/dd/yyyy"]);
            if (case_info) {
                item.case_display = caseFormat(case_info);
            }
            return item;
        });
    }
    return [];
}