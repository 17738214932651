import * as moment from 'moment';
import * as React from 'react';
import { Form, Table } from 'semantic-ui-react';
import * as global_constants from '../../global_constants';
import AddPrimaryInsurance from '../../shared/component/add_primary_insurance_component';
import AdvancedControl from '../../shared/component/advanced_control';
import AutoSearchComponent from '../../shared/component/auto_search_component';
import CurrencyFormatter from '../../shared/component/currency_formatter';
import Selection from '../../shared/component/selection_component';
import * as local_storage from '../../shared/local_storage_utility';
import { camelize, phone_format, set_column_data_width } from '../../shared/utility';
import { prepare_insurance_code } from '../utility';
import { insurance_advance_search, insurance_quick_search } from './../../shared/action/autosearch_action';
import * as shared_constants from './../../shared/shared_constants';
import Calendar from '../../shared/component/calendar_component';
import { get_popper_id } from '../utility';

class EditPaymentRowComponent extends React.PureComponent<any, any> {
  // Get initialize data when component created
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item,
      index: this.props.index,
      // selected: this.props.selected,
      from_insurance_data: {},
      add_primary_insurance_modal: false,
      transfer_insurance_data: {},
      insurance_quick_search_format: prepare_insurance_code(this.props.item, this.INSURANCE.FROM),
      transfer_insurance_quick_search_format: prepare_insurance_code(this.props.item, this.INSURANCE.TRANSFER),
      grid_conf: {
        isPagination: true,
        gridAutoHeight: true,
        selectionType: 'single',
        paginationPageSize: 20
      },
      advanced_from_modal_state: false,
      advanced_transfer_modal_state: false,      
      minDate: null
    };
  }
  is_mounted = false;
  insurance_type: any = null;
  INSURANCE = {
    FROM: 'FROM',
    TRANSFER: 'TRANSFER'
  };
  token: any = '';
  grid_data = [];
  total_column_width: number = 0;

  componentDidMount() {
    this.is_mounted = true;
    this.props.onRef(this);

    let date = null;
    if (this.props.accrualFlag) {
        date = new Date(this.props.openMonth);
    }

    this.setState({
        minDate: date
    });
  }

  componentWillUnmount() {
    this.is_mounted = false;
    this.props.onRef(undefined);
  }

  //Component update function
  componentDidUpdate(previousProps, previousState) {
    const { shouldUpdate, selected, item } = this.props;
    if (shouldUpdate === true && (previousProps.item.selected !== item.selected || item.selected !== this.state.item.selected)) {
      //this.setState({ item });
        this.setState((prevState) => ({
            item: {
                ...prevState.item,
                selected: item.selected
            }
        }));
    } else if (item != previousProps.item) {
      this.setState({
        item: this.props.item,
        index: this.props.index,
        // selected: this.props.selected,
        insurance_quick_search_format: prepare_insurance_code(this.props.item, this.INSURANCE.FROM),
        transfer_insurance_quick_search_format: prepare_insurance_code(this.props.item, this.INSURANCE.TRANSFER)
      });
    }
  }

  on_row_selection_advance_search = (type_of_search: any, selected_row: any) => {
    let id = '',
      selected_data = '',
      code = '',
      name = '',
      address = '',
      city = '',
      state = '',
      zip = '';
    let item = this.state.item;

    if (selected_row) {
      id = selected_row.id;
      if (selected_row.insurance_code || selected_row.insurance_name) {
        code = selected_row.insurance_code || '';
        name = selected_row.insurance_name || '';
        address = selected_row.address1 || '';
        city = selected_row.city || '';
        state = selected_row.state || '';
        zip = selected_row.zip || '';
      }
    }
    selected_data = code;
    selected_data += name ? ` - ${name}` : '';
    if (address) {
      selected_data += address ? `, ${address}` : '';
      selected_data += city ? `, ${city}` : '';
      selected_data += state ? `, ${state}` : '';
      selected_data += zip ? `, ${zip}` : '';
    }
    let format_ins = { label: '', title: '', insurance_code: '' };
    if (parseInt(id) > 0) {
      // Set Auto search control for location
      format_ins = {
        title: id.toString(),
        label: selected_data,
        insurance_code: code
      };
    }

    if (this.is_mounted) {
      if (type_of_search == this.INSURANCE.FROM) {
        item.from_ins_code  = selected_row.insurance_code;
        item.from_ins_name  = selected_row.insurance_name;
        item.from_address  = selected_row.address;
        item.from_city  = selected_row.city;
        item.from_state  = selected_row.state;
        item.from_zip  = selected_row.zip;
        item.from_ins_code_id  = id;
        
        this.setState({
          insurance_quick_search_format: format_ins,
          add_primary_insurance_modal: false,
          item: item
        });
      } else {

        item.xfer_ins_code  = selected_row.insurance_code;
        item.xfer_ins_name  = selected_row.insurance_name;
        item.xfer_address  = selected_row.address;
        item.xfer_city  = selected_row.city;
        item.xfer_state  = selected_row.state;
        item.xfer_zip  = selected_row.zip;        
        item.xfer_ins_code_id  = id;

        this.setState({
          transfer_insurance_quick_search_format: format_ins,
          add_primary_insurance_modal: false,
          item: item
        });
      }
    }
  };

  //Auto search for Location
  render_suggestion_result = (props: { title: React.ReactText; label: React.ReactNode }) => {
    return (
      <div key={props.title} tabIndex={0} className='item_auto_search'>
        <div key={props.title} className='fs_13'>
          <span>{props.label}</span>
        </div>
      </div>
    );
  };

  // prepare the suggestion list with search result
  prepare_suggestion = (data: any) => {
    let formattedList = [];
    let selected_data = '';
    let data_length = data.data.length;
    if (data && data_length) {
      for (let i = 0; i < data_length; i++) {
        let item = data.data[i];
        selected_data = item.insurance_code || '';
        selected_data += item.insurance_name ? ` - ${item.insurance_name}` : '';
        selected_data += item.address1 ? `, ${item.address1}` : '';
        selected_data += item.city ? `, ${item.city}` : '';
        selected_data += item.state ? `, ${item.state}` : '';
        selected_data += item.zip ? `, ${item.zip}` : '';

        formattedList.push({
          title: `${item.id}`,
          name: item.id,
          label: selected_data,
          insurance_code: item.insurance_code,
          insurance_class_code: item.insurance_class_code,
          balance_type: item.balance_type,
          insurance_type: item.insurance_type,
          insurance_class: item.insurance_class,
          insurance_name: item.insurance_name,
          address: item.address1,
          city: item.city,
          state: item.state,
          zip: item.zip
        });
      }
    }
    return formattedList;
  };

  get_insurance_quick_search_data_list = async (search_keyword) => {
    return await insurance_quick_search(search_keyword, this.token);
  };

  set_parent_show_from_modal_state = (flag, controlId) => {
    if (this.is_mounted) {
      this.setState({ advanced_from_modal_state: flag });
    }
  };

  set_parent_show_transfer_modal_state = (flag, controlId) => {
    if (this.is_mounted) {
      this.setState({ advanced_transfer_modal_state: flag });
    }
  };

    //Get data retruns updated data item.
    get_updated_data = () => ({ ...this.state.item });

  has_data_changed = (item) => {
    return (JSON.stringify(item) != JSON.stringify(this.state.item)) && 
            (item.payment_adjustment_id == this.state.item.payment_adjustment_id);
  }

  on_item_selection = (selected_item: any, type: any) => {   
    let item = this.state.item;

    let code = selected_item.insurance_code || '';
    let name = selected_item.insurance_name || '';
    let address = selected_item.address || '';
    let city = selected_item.city || '';
    let state = selected_item.state || '';
    let zip = selected_item.zip || '';

      let selected_data = code;
      selected_data += name ? ` - ${name}` : '';
      if (address) {
        selected_data += address ? `, ${address}` : '';
        selected_data += city ? `, ${city}` : '';
        selected_data += state ? `, ${state}` : '';
        selected_data += zip ? `, ${zip}` : '';
      }

    if (this.is_mounted) {
      if (type == this.INSURANCE.FROM) {
        item.from_ins_code  = selected_item.insurance_code;
        item.from_ins_name  = selected_item.insurance_name;
        item.from_address  = selected_item.address;
        item.from_city  = selected_item.city;
        item.from_state  = selected_item.state;
        item.from_zip  = selected_item.zip;
        item.from_ins_code_id  = selected_item.title;

        this.setState({
          insurance_quick_search_format: {
            title: selected_item.title.toString(),
            label: selected_data,
            item: item
          }
        });
      } else {

        item.xfer_ins_code  = selected_item.insurance_code;
        item.xfer_ins_name  = selected_item.insurance_name;
        item.xfer_address  = selected_item.address;
        item.xfer_city  = selected_item.city;
        item.xfer_state  = selected_item.state;
        item.xfer_zip  = selected_item.zip;
        item.xfer_ins_code_id  = selected_item.title;

        this.setState({
          transfer_insurance_quick_search_format: {
            title: selected_item.title.toString(),
            label: selected_data,
            item: item
          }
        });
      }
    }
  };

  clear_quick_search = (type_of_search: any) => {

    let item = this.state.item;

    if (this.is_mounted) {
      if (type_of_search == this.INSURANCE.FROM) {
        item.from_ins_code  = '';
        item.from_ins_name  = '';
        item.from_address  = '';
        item.from_city  = '';
        item.from_state  = '';
        item.from_zip  = '';
        item.from_ins_code_id  = '';

        this.setState({
          insurance_quick_search_format: {
            title: '',
            label: '',
            item: item
          }
        });
      } else {

        item.xfer_ins_code  = '';
        item.xfer_ins_name  = '';
        item.xfer_address  = '';
        item.xfer_city  = '';
        item.xfer_state  = '';
        item.xfer_zip  = '';
        item.xfer_ins_code_id  = '';

        this.setState({
          transfer_insurance_quick_search_format: {
            title: '',
            label: '',
            item: item
          }
        });
      }
    }
  };

  row_input_handler = (e: any, type) => {
    let { value } = e.target;
    if (this.is_mounted) {      
      this.setState((prev_state) => ({
        item: {
          ...prev_state.item,
          [type]: value
        }
      }));
    }
  };

  row_dropdown_handler = (value: any, type: any) => {
    if (this.is_mounted) {
      this.setState((prev_state) => ({
        item: {
          ...prev_state.item,
          [type]: value
        }
      }));
    }
  };

  handleCalendarChange = (dt, name) => {       

    if (this.is_mounted) {
        this.setState(prev_state => ({
            item: {
                ...prev_state.item,
                [name]: dt
            }
        }))
    }
}

  row_money_control_handler = (value: any, type: any) => {
    if (this.is_mounted) {
      this.setState((prev_state) => ({
        item: {
          ...prev_state.item,
          [type]: value || 0
        }
      }));
    }
  };

  configure_grid_data = async (type_of_search: any, params: any) => {
    let auth_data = local_storage.get('auth_data');
    const token = auth_data.token_details.access_token;
    params = params ? params : { insurance_name: '', address: '', city: '', insurance_code: '' };
    params.records = global_constants.constants.PAGE_SIZE;
    let grid_data = [];
    let search_data = null;

    search_data = await insurance_advance_search(params, token).then((res) => res.data);
    for (let data = 0; data < search_data.data.length; data++) {
      search_data.data[data].phone_customer = phone_format(search_data.data[data].phone_customer);
    }
    const search_result = search_data.data || [];
    this.total_column_width = set_column_data_width(
      search_result.slice(0, this.state.grid_conf.paginationPageSize),
      shared_constants.search_insurance_configuration.column_defs
    );
    grid_data = {
      ...this.state.grid_conf,
      rows: search_result,
      column: shared_constants.search_insurance_configuration.column_defs,
      messages: search_result.messages
    };
    if (this.is_mounted) {
      if (type_of_search == this.INSURANCE.FROM) {
        this.setState({ from_insurance_data: grid_data });
      } else {
        this.setState({ transfer_insurance_data: grid_data });
      }
    }
  };

  render_checkbox_element = (index, is_disabled: boolean, selected: boolean) => {
    return (
      <Form.Field className='ui checkbox' style={{ margin: 0 }}>
        <input
          type='checkbox'
          name={`rowId-${index}-checkbox`}
          id={`rowId-${index}-checkbox`}
          checked={selected}
          onChange={this.row_checkbox_handler}
          disabled={is_disabled}
        />
        <label className='chkbx_label' htmlFor={`rowId-${index}-checkbox`}></label>
      </Form.Field>
    );
  };

  render_money_control_element = (type: any, name: any, value: any, is_disabled: boolean) => {
    return (
      <CurrencyFormatter
        id='add_id_input_amount'
        onChange={(value) => this.row_money_control_handler(value, type)}
        prefix='$'
        maxLength={15}
        defaultValue={value}
        allowNegative={true}
        className={false ? 'red-error-thin numberFormat' : 'numberFormat'}
        disabled={is_disabled}
      />
    );
  };

  render_input_element = (type: any, name: any, value: any, is_disabled: boolean) => {
    return (
      <input
        id={name}
        name={name}
        maxLength={50}
        value={value || ''}
        disabled={is_disabled}
        onChange={(e) => this.row_input_handler(e, type)}
      />
    );
  };

  render_dropdown_element = (type: any, name: string, value: any, is_disabled: boolean, options: any[]) => {
    return (
      <Selection
        id={name}
        name={name}
        defaultValue={value || ''}
        options={options}
        onChange={(value) => this.row_dropdown_handler(value, type)}
        disabled={is_disabled}
      />
    );
  };

  row_checkbox_handler = (e: any) => {
    if (this.is_mounted) {
      let item = this.state.item;
      this.setState({item: {...item, selected: e.target.checked }});
      this.props.select_row_handler(e.target.checked, this.props.item);
    }
  };

  render_calendar_element = (id, name, value, disabled=false) => {
    return(
        <Calendar
            id={id}
            id_popper={get_popper_id()}
            maxDate={moment().format('MM/DD/YYYY')}
            date_update={(dt) => this.handleCalendarChange(dt, name)}
            date={value}
            is_disabled={disabled}
            class_name={"datepicker-keybowrd-charge"}            
            minDate={this.state.minDate}
        />
    );
}

  set_primary_insurance_details = (data, type: any = null) => {
    let item = this.state.item;
    let id = '',
      selected_data = '',
      code = '',
      name = '',
      address = '',
      city = '',
      state = '',
      zip = '';
    if (data) {
      id = data.id.toString();
      if (data.insurance_code || data.name) {
        code = data.insurance_code || '';
        name = data.name || '';
        address = data.address.address1 || '';
        city = data.address.city || '';
        state = data.address.state_id || '';
        zip = data.address.zip || '';
      }
    }

    selected_data = code;
    selected_data += name ? ` - ${name}` : '';
    if (address) {
      selected_data += address ? `, ${address}` : '';
      selected_data += city ? `, ${city}` : '';
      selected_data += state ? `, ${state}` : '';
      selected_data += zip ? `, ${zip}` : '';
    }
    let format_ins = { label: '', title: '', insurance_code: '' };
    if (parseInt(id) > 0) {
      // Set Auto search control for location
      format_ins = {
        title: id.toString(),
        label: selected_data,
        insurance_code: code
      };
    }

    if (this.is_mounted) {
      if (type == this.INSURANCE.FROM) {
        item.from_ins_code  = code;
        item.from_ins_name  = name;
        item.from_address  = address;
        item.from_city  = city;
        item.from_state  = state;
        item.from_zip  = zip;
        item.from_ins_code_id  = id;

        this.setState({
          insurance_quick_search_format: format_ins,
          add_primary_insurance_modal: false,
          advanced_from_modal_state: false,
          item:item
        });
      } else {
        item.xfer_ins_code  = code;
        item.xfer_ins_name  = name;
        item.xfer_address  = address;
        item.xfer_city  = city;
        item.xfer_state  = state;
        item.xfer_zip  = zip;
        item.xfer_ins_code_id  = id;

        this.setState({
          transfer_insurance_quick_search_format: format_ins,
          add_primary_insurance_modal: false,
          advanced_transfer_modal_state: false,
          item:item
        });
      }
    }
  };

  add_primary_insurance = (type: any = null) => {
    if (this.is_mounted) {
      this.insurance_type = type;
      this.setState({ add_primary_insurance_modal: !this.state.add_primary_insurance_modal });
    }
  };

  onFocus = (e) => {
    let obj = e.target && e.target.getBoundingClientRect();
    let sibling = e.target && e.target.parentElement && e.target.parentElement.nextElementSibling;
    if (obj && sibling) {
      let style = `position:fixed;left:${obj.left}px;top:${obj.top + obj.height}px;width:${obj.width}px !important;`;
      sibling.setAttribute('style', style);
    }
  };

  render() {
    const {
      item,
      index,
      from_insurance_data,
      transfer_insurance_data,
      insurance_quick_search_format,
      transfer_insurance_quick_search_format
    } = this.state;
    return (
      <React.Fragment>
        <Table.Row key={`rowId-${index}`}>
          <Table.Cell textAlign='center' verticalAlign='middle'>
            {this.render_checkbox_element(index, false, item.selected)}
            <AddPrimaryInsurance
              insurance_types={this.props.insurance_types}
              insurance_contract={this.props.insurance_contract}
              set_primary_insurance_details={this.set_primary_insurance_details}
              add_primary_insurance={this.state.add_primary_insurance_modal}
              insurance_type={this.insurance_type}
            />
          </Table.Cell>
                {/* Patient ID */
                    !this.props.edit_payments_small_updates_ff && <Table.Cell>{item.patient_id}</Table.Cell>
                }
          {/* Date of Service */}
                <Table.Cell>{moment(item.date_of_service).format('MM/DD/YYYY')}</Table.Cell>
                {/* Procedure code */
                    this.props.edit_payments_small_updates_ff && <Table.Cell>{item.proc_code}</Table.Cell>
                }
          {/* Deposit Date */}
          <Table.Cell className={"custom-datepicker-for-table"}>
              <div>
                  {this.render_calendar_element(
                      `rowId-${index}-deposit_date`,
                      "deposit_date", 
                      item.deposit_date ? moment(item.deposit_date) : null,
                      item.all_disabled)}                       
              </div>
          </Table.Cell>
          {/* Payment */}
          <Table.Cell>
            {this.render_money_control_element(
              'payment_amount',
              `rowId-${index}-payment_amount`,
              item.payment_amount,
              item.all_disabled
            )}
          </Table.Cell>
          {/* Payment Code */}
          <Table.Cell>
            {this.render_dropdown_element(
              'payment_code',
              `rowId-${index}-payment_code`,
              item.payment_code,
              item.all_disabled,
              this.props.dropdown_data.payment_code
            )}
          </Table.Cell>
          {/* Adjustment */}
          <Table.Cell>
            {this.render_money_control_element(
              'adjustment_amount',
              `rowId-${index}-adjustment_amount`,
              item.adjustment_amount,
              item.all_disabled
            )}
          </Table.Cell>
          {/*Adjustment Code  */}
          <Table.Cell>{item.adjustment_code}</Table.Cell>
          {/* Transfer */}
          <Table.Cell>
            {this.render_money_control_element(
              'xfer_amount',
              `rowId-${index}-xfer_amount`,
              item.xfer_amount,
              item.all_disabled
            )}
          </Table.Cell>
          {/* Xfer Det Amt */}
          <Table.Cell>
            {this.render_money_control_element(
              'pat_resp_amt',
              `rowId-${index}-payment_amount`,
              item.pat_resp_amt,
              item.all_disabled
            )}
          </Table.Cell>
          {/* From Insurance */}
          <Table.Cell>
            <Form.Field className={false ? 'requiredWithBgColor advance-quick-search' : 'advance-quick-search'}>
              <AdvancedControl
                showModal={this.state.advanced_from_modal_state}
                onGridRowSelection={(selected_row: any) =>
                  this.on_row_selection_advance_search(this.INSURANCE.FROM, selected_row)
                }
                gridConfig={from_insurance_data}
                addClicks={() => this.add_primary_insurance(this.INSURANCE.FROM)}
                set_parent_show_modal_state={this.set_parent_show_from_modal_state}
                controlId={global_constants.constants.advanced_control_type.primary_insurance}
                onSearch={(params: any) => this.configure_grid_data(this.INSURANCE.FROM, params)}
                search_type={camelize(global_constants.constants.advanced_control_type.primary_insurance)}
                headerIdForGridTabNavigation={`rowId-${index}-from_insurance` + 'grid_header_id'}
              />
              <AutoSearchComponent
                on_blur={(e: any) => {}}
                control_id={`rowId-${index}-from_insurance`}
                default_value={insurance_quick_search_format}
                on_focus={this.onFocus}
                errorMessage={'No Record Found !'}
                prepareRenderList={this.render_suggestion_result}
                getList={this.get_insurance_quick_search_data_list}
                prepareDataList={(data: any) => this.prepare_suggestion(data)}
                selectresult={(item: any) => this.on_item_selection(item, this.INSURANCE.FROM)}
                is_focus={false}
                show_clear_search={true}
                clear_search={() => this.clear_quick_search(this.INSURANCE.FROM)}
                errorClass={false ? 'req-background-inp' : ''}
                is_disabled={item.all_disabled}
              />
            </Form.Field>
          </Table.Cell>
          {/* Transfer to Payer */}
          <Table.Cell>
            <Form.Field className={false ? 'requiredWithBgColor advance-quick-search' : 'advance-quick-search'}>
              <AdvancedControl
                showModal={this.state.advanced_transfer_modal_state}
                onGridRowSelection={(selected_row: any) =>
                  this.on_row_selection_advance_search(this.INSURANCE.TRANSFER, selected_row)
                }
                gridConfig={transfer_insurance_data}
                addClicks={() => this.add_primary_insurance(this.INSURANCE.TRANSFER)}
                set_parent_show_modal_state={this.set_parent_show_transfer_modal_state}
                controlId={global_constants.constants.advanced_control_type.primary_insurance}
                onSearch={(params: any) => this.configure_grid_data(this.INSURANCE.TRANSFER, params)}
                search_type={camelize(global_constants.constants.advanced_control_type.primary_insurance)}
                headerIdForGridTabNavigation={`rowId-${index}-transfer_Payer` + 'grid_header_id'}
              />
              <AutoSearchComponent
                on_blur={(e: any) => {}}
                control_id={`rowId-${index}-transfer_Payer`}
                default_value={transfer_insurance_quick_search_format}
                errorMessage={'No Record Found !'}
                prepareRenderList={this.render_suggestion_result}
                getList={this.get_insurance_quick_search_data_list}
                prepareDataList={(data: any) => this.prepare_suggestion(data)}
                selectresult={(item: any) => this.on_item_selection(item, this.INSURANCE.TRANSFER)}
                is_focus={false}
                show_clear_search={true}
                clear_search={() => this.clear_quick_search(this.INSURANCE.TRANSFER)}
                errorClass={false ? 'req-background-inp' : ''}
                is_disabled={item.all_disabled}
                on_focus={this.onFocus}
              />
            </Form.Field>
          </Table.Cell>
          {/* Money Type */}
          <Table.Cell>
            {this.render_dropdown_element(
              'money_type',
              `rowId-${index}-money_type`,
              item.money_type,
              item.all_disabled,
              this.props.dropdown_data.money_type
            )}
                </Table.Cell>
                {/* check number */
                    this.props.edit_payments_small_updates_ff &&
                <Table.Cell>
                    {this.render_input_element(
                        'check_num',
                        `rowId-${index}-check_number`,
                        item.check_num,
                        item.all_disabled
                    )}
                </Table.Cell>
                }
          {/* Batch */}
          <Table.Cell>
            {this.render_input_element(
              'batch_num',
              `rowId-${index}-batch_num`,
              item.batch_num,
              item.all_disabled
            )}
          </Table.Cell>
          {/* Adjustment Qualifier */}
          <Table.Cell>
            {this.render_dropdown_element(
              'adjustment_qualifier',
              `rowId-${index}-adjustment_qualifier`,
              item.adjustment_qualifier,
              item.all_disabled,
              this.props.dropdown_data.adjustment_qualifier
            )}
          </Table.Cell>
          {/* CARC */}
          <Table.Cell className={'custom-datepicker-for-table'}>
            {this.render_dropdown_element(
              'adjustment_reason',
              `rowId-${index}-proc_mod1`,
              item.adjustment_reason,
              item.all_disabled,
              this.props.dropdown_data.carc
            )}
          </Table.Cell>
        </Table.Row>
      </React.Fragment>
    );
  }
}

export default EditPaymentRowComponent;
