import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Dimmer, Form, Grid, Input, Loader } from 'semantic-ui-react';
import * as export_print_utility from '../../reports/util/export_print_utility';
import GridView from '../../shared/component/grid';
import * as session_storage from '../../shared/session_storage_utility';
import { toastr } from 'react-redux-toastr';
import { set_focus_on_element_with_id, set_focus_to_app_header } from '../../shared/tab_navigation_utility';
import { phone_format, get_columns } from '../../shared/utility';
import * as global_constants from '../../global_constants';
import * as constants_actions from '../action/billing_actions';
import * as constants from '../../admin/constants/constants';
import * as local_storage from '../../shared/local_storage_utility';
import { custom_date_format, get_all_error, unmask } from '../../shared/utility';
import * as ReportConstants from '../../reports/report_constants';
import { providerBillingSettingsFeatures,  } from '../../admin/constants/constants';

class FailedChargeRulesComponent extends React.Component<any, any> {
    advance_controls: any;
    [x: string]: any;
    constructor(props) {
        super(props);
        // Define initial state value.
        this.state = {
            show_grid: false,
            show_render: true,
            search_criteria: [],
            is_loading: false,
            shown: false,
            grid_rows_count: 0,
            is_search_button_disabled: false
        };
    }

    // Define initial properties and its value.
    _is_mounted = false;
    page_name: string = 'failed_charge_rules';
    report_name: string = 'Failed Charge Rules'
    page_metadata: any;
    failed_charge_rules_search_criteria: any = {
        failure_reason_code: '',
        failure_reason_short_description: '',
        show_inactive: false
    };
    initial_search_criteria: any = {
        failure_reason_code: '',
        failure_reason_short_description: '',
        show_inactive: false
    };
    token: string = '';
    row_data = [];
    code_id: number = 0;
    is_grid_visible = false;
    is_search_ui: any = false;
    title = '';
    current_date: any;
    company_name: any;
    total_column_width: number = 0;
    grid_header_height: number = 0;
    user_type_id: any;
    reportId: string = '0';
    
    // Fetching the page_name and state.
    UNSAFE_componentWillMount = () => {
        this.get_page_metadata();
    };

    componentDidMount = () => {
        document.body.classList.add('admin-framework');
        let elem = document.getElementById('search-criteria-container');
        let all_form_elem = elem.getElementsByTagName('input');
        all_form_elem[0].focus();
        this.handle_search_button_tab();
    };

    componentWillUnmount = () => {
        document.body.classList.remove('admin-framework');
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[2] !== 'failed_charge_rules' && session_storage.remove('payers_search_criteria');
        }
    };

    //Use to fetch params and page metadata
    get_page_metadata = () => {
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.user_type_id = local_storage.get('auth_data').user_type_r3_id;

        //Verifying page_name is correct or not.
        this.page_metadata = constants.failed_charge_rule;

        let failed_charge_rules_search_criteria = session_storage.get('failed_charge_rules_search_criteria')
        this.failed_charge_rules_search_criteria = (failed_charge_rules_search_criteria && failed_charge_rules_search_criteria.search_criteria) || this.failed_charge_rules_search_criteria;
        if (failed_charge_rules_search_criteria) {
            this.search_handler(this);
        }

        this.setState({
            show_render: true
        });
    };

    // Data formatter
    format_data = (rows, columns) => {
        let formatted_data = rows.map(row_data => {
            columns.filter(col_data => {
                switch (col_data.type) {
                    case 'boolean':
                        row_data[col_data.field] = row_data[col_data.field] ? 'Active' : 'Inactive';
                        break;
                    case 'number':
                        row_data[col_data.field] = row_data[col_data.field];
                        break;
                    case 'string':
                        row_data[col_data.field] = row_data[col_data.field];
                        break;
                    default:
                        row_data[col_data.field] = row_data[col_data.field];
                }
            });
            return row_data;
        });
        return formatted_data;
    };

    //Input change handler.
    input_change_handler = e => {
        let { value, checked, type, name } = e.target;

        //Validate inputs with correct values
        if (name === 'failure_reason_code') {
            value = value && value.length > 0 && value.replace(/[^0-9]/g, '');
        }

        if (type == 'checkbox') {
            this.failed_charge_rules_search_criteria[name] = checked;
        } else {
            this.failed_charge_rules_search_criteria[name] = value;
        }

        this.setState({
            search_criteria: this.failed_charge_rules_search_criteria
        });
    };

    //Search button handler and used to search the data on the basis of search criteria.
    search_handler = e => {
        var url = this.page_metadata.api.search.url;
        for (let key in this.failed_charge_rules_search_criteria) {
            url = url.replace(
                key + '_value',
                typeof this.failed_charge_rules_search_criteria[key] !== 'undefined' ? encodeURIComponent(this.failed_charge_rules_search_criteria[key].toString().trim()) : ''
            );
        }
        this.setState({
            is_loading: true,
            is_search_button_disabled: true
        });
        constants_actions.get_search_data(this.token, url).then(
            response => {
                if (response.data) {
                    this.row_data = response.data.data ? response.data.data : [];
                    this.row_data = this.format_data(this.row_data, this.page_metadata.column_def);
                    const grid_height = get_columns(this.row_data, this.page_metadata.column_def);
                    this.total_column_width = grid_height.total_column_width;
                    this.grid_header_height = grid_height.header_height;
                    this.is_search_ui = true;
                    this.setState({
                        show_grid: true,
                        is_loading: false,
                        search_criteria: this.failed_charge_rules_search_criteria,
                        grid_rows_count: this.row_data && this.row_data.length,
                        is_search_button_disabled: false
                    });
                    session_storage.set('failed_charge_rules_search_criteria', {
                        search_criteria: this.failed_charge_rules_search_criteria,
                    });
                } else {
                    if (response.data.messages[0].message.indexOf('<br') > -1) {
                        this.show_html_content_toaster(response.data.messages[0].message);
                    } else {
                        toastr.error('', response.data.messages[0].message);
                    }
                    this.go_next = false;
                    if (this._is_mounted) {
                        this.setState({
                            is_loading: false,
                            is_search_button_disabled: false
                        });
                    }
                }
            }, error => {
                if (this._is_mounted) {
                    this.go_next = false;
                    this.setState({
                        is_loading: false,
                        is_search_button_disabled: false
                    });
                }
                if (error.response.data) toastr.error('', error.response.data.messages[0].message);
            }
        );
    };

    //Reset button the search control.
    clear_handler = e => {
        this.failed_charge_rules_search_criteria = { ...this.initial_search_criteria };
        this.setState({
            show_grid: false,
            shown: false,
            search_criteria: this.initial_search_criteria,
            grid_rows_count: 0
        });
        this.is_grid_visible = false;
        session_storage.remove('failed_charge_rules_search_criteria');
    };

    //New button handler.
    new_handler = () => {
        this.props.history.push(`${this.page_metadata.base_url}/${this.page_name}`);
    };

    //Function calls on initialization of Ag-Grid and catch its reference.
    get_grid_ref = grid_params => {
        this.setState({
            grid_params,
            grid_rows_count: grid_params.api.getModel().getRowCount()
        });
        var grid_width = document.getElementsByClassName('admin-wrapper')[0].clientWidth;
        if (this.total_column_width > grid_width) {
            this.page_metadata.column_def.filter(item => {
                grid_params.columnApi.setColumnWidth(item.field, item.width, false);
            });
        }
    };

    //Used to create the title for export and print.
    set_title = separter => {
        this.company_name = this.props.user_login_details.user_data.data.company_name;
        this.current_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
        this.title = this.page_metadata.search_header;
        return this.title =
            this.title.replace(/<br>/g, separter) +
            separter +
            this.company_name +
            separter +
            this.current_date +
            separter +
            (this.state.grid_rows_count == 1
                ? this.state.grid_rows_count + ' record'
                : this.state.grid_rows_count + ' records') +
            separter +
            separter;
    };

    //Function calls on initialization of Print report data
    on_print_button = () => {
        this.title = this.set_title('<br>');
        export_print_utility.print_grid_data(this.state.grid_params, this.title, this.report_name);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
    };

    //Function calls on initialization of export report data
    on_export_button = () => {
        this.title = this.set_title('\n');
        this.page_name = this.page_name.replace(/<br>/g, '');
        export_print_utility.export_grid_data(this.state.grid_params, this.title, this.report_name);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Export);
    };

    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;
        const payload: ReportConstants.IPayloadForBillingAudit = {
            reportId: this.reportId,
            contextTitle: this.report_name + " - " + ReportConstants.ReportEventAction[eventActionId],
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: ReportConstants.ReportEntityTypeId.D_Charge,
            data: { Records: this.state.grid_rows_count }
        }
        const reqBody = export_print_utility.generateAuditDataEntryPayload(payload);
        ReportConstants.saveAuditPrintExport(reqBody, accessToken);
    };

    on_grid_out = () => {
        set_focus_on_element_with_id('app_header_dropdown');
    };

    //On double click of grid, it redirects user to view page.
    on_row_double_clicked = selected_row_data => {
        this.props.history.push(`${this.page_metadata.base_url}/${this.page_name}/${selected_row_data.rule_reason_id}`);
    };

    handle_search_button_tab = () => {
        setTimeout(() => {
            let searchButton = document.getElementById('search_button_id');
            let self = this;
            searchButton.addEventListener('keydown', function (event) {
                // apply a check where grid is empty
                if (!event.shiftKey && (self.row_data == null || (self.row_data.length == 0 && !self.state.show_grid))) {
                    set_focus_to_app_header(event);
                }
            });
        }, 200);
    };

    update_report_rows_count = () => {
        this.setState({
            grid_rows_count: this.state.grid_params.api.getModel().getRowCount()
        });
    };

    // Show multiple messages
    show_html_content_toaster = msg => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ),
            timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        };
    };

    has_access_to_create_payer = () => {
        // user type with customer or partner has no access to create a payer

    }

    //It renders report grid and its search controls.
    render() {
        return (
            this.state.show_render && (
                <React.Fragment>
                    <Dimmer active={this.state.is_loading}>
                        <Loader size='massive'>Loading</Loader>
                    </Dimmer>
                    <div className={'admin-wrapper ' + this.page_metadata.id} style={!this.state.show_grid ? { paddingBottom: 20 } : {}}>
                        <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                            <Grid.Column computer={16}>
                                <h3 className='ui header left aligned' dangerouslySetInnerHTML={{ __html: this.page_metadata.header }} />
                            </Grid.Column>
                        </Grid>
                        <div id='admin-scrollable-area' className='wrapper' style={this.state.show_grid ? { flex: 1 } : {}}>
                            <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container'>
                                <Form autoComplete='off' onSubmit={e => this.search_handler(e)}>
                                    <Grid>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Failure Reason Code</label>
                                                <Input
                                                    autoComplete='off'
                                                    name='failure_reason_code'
                                                    id='failure_reason_code'
                                                    maxLength={10}
                                                    onChange={e => this.input_change_handler(e)}
                                                    value={this.failed_charge_rules_search_criteria['failure_reason_code'] ? this.failed_charge_rules_search_criteria['failure_reason_code'] : ''}
                                                    pattern='[0-9]*'
                                                    type='text'
                                            />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Failure Reason Short Description</label>
                                                <Input
                                                    autoComplete='off'
                                                    name='failure_reason_short_description'
                                                    id='failure_reason_short_description'
                                                    maxLength={100}
                                                    onChange={e => this.input_change_handler(e)}
                                                    value={this.failed_charge_rules_search_criteria['failure_reason_short_description'] ? this.failed_charge_rules_search_criteria['failure_reason_short_description'] : ''}
                                                    type='text'
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field className='ui checkbox'>
                                                <div style={{ marginTop: 32 }}>
                                                    <input
                                                        type='checkbox'
                                                        name='show_inactive'
                                                        id='show_inactive'
                                                        onChange={e => this.input_change_handler(e)}
                                                        checked={this.failed_charge_rules_search_criteria['show_inactive'] ? this.failed_charge_rules_search_criteria['show_inactive'] : false}
                                                    />{' '}
                                                    <label className='chkbx_label' htmlFor='show_inactive'>
                                                        Include Inactive
                                                    </label>
                                                </div>
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid style={{ margin: '0 -17px' }}>
                                        <Grid.Column computer={16} textAlign='right'>
                                            <Button id='clear_button_id' type='button' onClick={e => this.clear_handler(e)} basic>
                                                Clear
                                            </Button>

                                            <Button id='new_button_id' type='button' onClick={e => this.new_handler()} basic>
                                                New
                                             </Button>
                                            
                                            <Button id='search_button_id' type='submit' primary disabled={this.state.is_search_button_disabled}>
                                                Search
                                            </Button>
                                        </Grid.Column>
                                    </Grid>
                                    {this.state.show_grid && (
                                        <Grid style={{ marginTop: '-1rem', marginBottom: 0 }}>
                                            <Grid.Column tablet={5} computer={4} textAlign='left'>
                                                <p style={{ fontSize: '16px' }}>Search Results</p>
                                            </Grid.Column>
                                            <Grid.Column tablet={5} computer={8} textAlign='center'>
                                                {
                                                    <p style={{ fontSize: '16px', minHeight: 22 }}>
                                                        {`${this.state.grid_rows_count} ${
                                                            this.state.grid_rows_count == 1 ? ' record shown' : ' records shown'
                                                            }`}
                                                    </p>
                                                }
                                            </Grid.Column>
                                            <Grid.Column tablet={2} computer={4} textAlign='right' />
                                        </Grid>
                                    )}
                                </Form>
                            </div>
                            {this.state.show_grid && !this.state.is_loading && (
                                <GridView
                                    id={`id_grid_${this.page_name}`}
                                    row={this.row_data}
                                    column={this.page_metadata.column_def}
                                    style={{ height: '100%' }}
                                    wrapperStyle={{ width: '100%', height: '100%', display: 'flex' }}
                                    suppressMovableColumns={false}
                                    enableColResize={true}
                                    onRowDoubleClicked={this.on_row_double_clicked}
                                    selectionType={'single'}
                                    get_grid_ref={this.get_grid_ref}
                                    suppressSizeToFit={true}
                                    headerHeight={this.grid_header_height}
                                    headerIdForTabNavigation={constants.search_grid_id}
                                    onForceGridOut={this.on_grid_out}
                                    on_filter_button_click={() => this.update_report_rows_count()}
                                />
                            )}
                        </div>
                        {this.state.show_grid && (
                            <div
                                className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                                id='applicationFooterSticky'
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                                <Grid.Column computer={16} textAlign='right'>
                                    <Button id='export_report_button' type='submit' onClick={this.on_export_button} basic>
                                        Export
                                    </Button>
                                    <Button id='print_report_button' onKeyDown={set_focus_to_app_header} type='submit' onClick={this.on_print_button} primary>
                                        Print
                                    </Button>
                                </Grid.Column>
                            </div>
                        )}
                    </div>
                </React.Fragment>
            )
        );
    }
}

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
    };
};

export default connect(mapStateToProps)(FailedChargeRulesComponent);
