import * as global_constants from '../../global_constants';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request';
/**
 * Verifies user at the time of login
 * @param Username 
 * @param Password 
 * @param WebptId 
 */
export const sso_user_authentication = (Username, Password, WebptId) => {

    const url = `${global_constants.base_end_points_v2}${global_constants.end_points.oauth_urls.sso_login}`;
    var data = "user_name=" + Username + "&password=" + encodeURIComponent(Password) + "&webpt_id=" + WebptId;

    let request = new Request();
    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
    };

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': data
    });

    return request.make(request_config);
}
