import * as moment from 'moment';
import * as React from 'react';
import { toastr } from 'react-redux-toastr';
import { Dropdown, Grid, Button } from 'semantic-ui-react';
import { patientCreditType, PAYMENT_ACTION, payment_message, payment_const } from '../../payment/payment_constants';
import * as local_storage from '../../shared/local_storage_utility';
import * as session_storage from '../../shared/session_storage_utility';
import { AlertConfirm } from '../../shared/component/alert_confirm_component';
import ItemAdditionalComponent from './item_additional_payments_component';
import { set_focus_on_element_with_id } from '../../shared/tab_navigation_utility';
import { yyyy_mm_dd_comparator } from '../utility';
import { amount_formatter } from '../../shared/utility';

const data = {
    id: 0,
    patient_credit_id: 0,
    date_of_service: null,
    credit_type_id: patientCreditType.copay,
    payment_code: {},
    amount: '',
    original_amount: 0,
    place_of_service: {},
    billing_provider: {},
    insurance_code: '',
    insurance_name: ''
}

class AdditionalPaymentsComponents extends React.Component<any, any> {

    _is_mounted = false;
    list_data: any = [];
    rowNodes: any;
    is_amount_negative_error = false;
    is_negative_valid = false;

    constructor(props) {
        super(props);
        this.state = {
            payment_code_list: [],
            insurance_list: [],
            display_data: [{ ...data }],
            select_data: [],
            select_all: false,
            should_update_child: true,
            total_amount: 0,
            open_confirm_alert: false,
            confirm_message: ''
        }

        this.rowNodes = [];
        this.list_data = [{ ...data }]
    }

    componentDidMount() {
        this._is_mounted = true;
        this.props.onRef(this);
    }

    //Component update function
    componentDidUpdate(previousProps, previousState) {
        if (previousState.select_data.length !== this.state.select_data.length) {
            const { select_data, select_all } = this.state;
            const is_all_checked = select_data.length === this.state.display_data.length;
            if (is_all_checked !== select_all) {
                this.setState({
                    select_all: is_all_checked,
                    should_update_child: false
                });
            }
        }

        if (this.props.clear_data !== previousProps.clear_data) {
            this.list_data = [{
                ...data,
                place_of_service: this.props.place_of_service,
                billing_provider: this.props.billing_provider
            }];

            this.setState({
                display_data: [...this.list_data],
                select_data: [],
                select_all: false,
                should_update_child: true
            });

        } else if (this.props.place_of_service !== previousProps.place_of_service) {
            this.list_data = this.list_data.map(item => ({
                ...item,
                place_of_service: this.props.place_of_service,
                billing_provider: this.props.billing_provider
            }));

            this.setState({
                display_data: [...this.list_data]
            });
        }
    }

    componentWillUnmount() {
        this._is_mounted = false;
        this.props.onRef(undefined)
    }

    //Get data retruns additional payments data list.
    get_data_list = () => {
        let row_items = [];
        let is_any_invalid = false;
        this.is_amount_negative_error = false;
        this.is_negative_valid = false;
        this.rowNodes.map((node) => {
            if (node) {
                if (node.is_valid() == true) {
                    if (!this.is_amount_negative_error) {
                        this.is_amount_negative_error = node.is_negative_amount_error();
                    }
                    if (!this.is_negative_valid) {
                        this.is_negative_valid = node.is_negative_valid();
                    }
                    row_items.push(node.get_updated_data());
                } else {
                    is_any_invalid = true;
                }
            }
        });

        return is_any_invalid ? [] : row_items;
    };

    get_negative_amount_status = () => ({
        amount_negative_error: this.is_amount_negative_error,
        negative_valid: this.is_negative_valid
    })
    // Checkbox row check/uncheck
    on_check_change(checked, item, data) {
        if (checked) {
            this.setState({
                select_data: [...this.state.select_data, { ...data }]
            });
        } else {
            const list_filter = this.state.select_data.filter(value => (value.id !== item.id));
            this.setState({
                select_data: list_filter
            });
        }
    }

    // Check all
    on_check_change_all = (event) => {
        if (event.target.checked) {
            this.setState(state => ({
                select_all: true,
                should_update_child: true,
                select_data: [...state.display_data],
            }));
        } else {
            this.setState({
                select_all: false,
                should_update_child: true,
                select_data: []
            })
        }
    }

    on_data_change = (data, index) => {
        this.list_data[index] = data;
    }

    on_amount_change = (data, index) => {
        this.list_data[index] = data;
        this.setState({
            total_amount: this.get_total_amount()
        })
    }

    //Get total additional amount sum
    get_total_amount = () => {
        let total = 0;
        this.list_data.map((value) => {
            total += parseFloat(isNaN(value.amount) ? 0 : value.amount || 0);
            return value;
        });
        return total;
    }

    handle_navigation_on_modal = () => {
        setTimeout(() => {
            let closeIcons: NodeListOf<HTMLElement> = document.querySelectorAll('i.close.icon:not(.auto-search-icon)') as NodeListOf<HTMLElement>;
            let closeIcon = closeIcons[closeIcons.length - 1]
            if (closeIcon) {
                closeIcon.tabIndex = 0;
                closeIcon.id = "confirm_modal_window_close_icon";
                closeIcon.addEventListener("keydown", function (event) {
                    if (event.shiftKey && event.keyCode == 9) {
                        event.preventDefault();
                        let prevButton = document.getElementById("id_alert_btn_ok");
                        prevButton.focus();
                    }

                    if (event.keyCode == 13) {
                        event.preventDefault();
                        var el = event.target as HTMLElement
                        el.click();
                    }
                });
            }

            set_focus_on_element_with_id("id_alert_btn_cancel");

        }, 0)
    }

    //Add an additional payment row
    add_additiona_payment = (e) => {
        let id_num = this.list_data.length ? this.list_data[this.list_data.length - 1].id + 1 : 0;
        this.list_data.push({
            ...data, id: id_num,
            place_of_service: this.props.place_of_service,
            billing_provider: this.props.billing_provider
        });
        const is_all_checked = this.state.select_data.length === this.list_data.length;
        this.setState({
            select_all: is_all_checked,
            should_update_child: false,
            display_data: [...this.list_data]
        });

    }


    handle_confirm = (e) => {
        e.preventDefault();
        this.setState(
            { open_confirm_alert: false },
            () => { this.remove_additiona_payment(); }
        );
    }

    // Show additional payment delete alert
    show_delete_modal = (e) => {
        e.preventDefault();
        if (this.state.select_data.length > 0) {
            this.setState({
                open_confirm_alert: true,
                confirm_message: payment_message.DELETE_CONFIRM
            });
        } else {
            toastr.error('', payment_message.DELETE_ERROR);
        }
    }

    //Remove an additional payment row
    remove_additiona_payment = () => {
        if (this.state.select_data.length == 0) {
            toastr.error('', payment_message.DELETE_ERROR);
            return;
        }

        this.list_data = this.list_data.filter((item) => {
            return (this.state.select_data.findIndex((value) => (value.id === item.id)) === -1);
        });

        if (this.list_data.length == 0) { //  Keep added an empty row as always
            this.list_data = [{
                ...data,
                place_of_service: this.props.place_of_service,
                billing_provider: this.props.billing_provider
            }];
        }

        this.setState((prevState) => ({
            display_data: [...this.list_data],
            select_data: [],
            select_all: false,
            total_amount: this.get_total_amount()
        }));
    }

    render_rows = () => {
        if (this.state.display_data && this.state.display_data.length > 0) {
            return this.state.display_data.map((item, index) => {
                return (<ItemAdditionalComponent
                    onRef={ref => (this.rowNodes[index] = ref)}
                    data={item}
                    key={index}
                    row_num={index}
                    disabled={false}
                    is_fetched={true}
                    is_submitted={this.props.is_submitted}
                    checked={this.state.select_all}
                    patientId={this.props.patientId}
                    shouldUpdate={this.state.should_update_child}
                    paymentCode={this.props.paymentCode}
                    insurance={this.props.insurance}
                    transaction_types={this.props.transaction_types}
                    payment_method_list={this.props.payment_method_list}
                    onChange={this.on_check_change.bind(this)}
                    on_data_change={this.on_data_change}
                    on_amount_change={this.on_amount_change}
                    clear_data={this.props.clear_data}
                />);
            });
        }

        return (<tr><td /><td><p className="ui-row-cell-empty">TEXT</p></td><td /><td /><td /><td /><td /><td /></tr>);
    }

    render_footer = () => {
        return (<tr><td>Total</td><td /><td /><td /><td />
            <td className='text-right'>{amount_formatter(this.state.total_amount)}</td><td /><td /></tr>);
    }

    //Tab key navigation and return to top header from bottom.
    set_focus_on_header = (event) => {
        if (!event.shiftKey && event.keyCode == '9') {
            event.preventDefault();
            set_focus_on_element_with_id("app_header_dropdown");
        }
    }

    //Render function
    render() {
        return (<>
            <Grid.Column computer={16} className="btn-container-additional-payments">
                <span className="title-additiona-payments">Additional Payments</span>
                <div>
                    <Button id="btn-delete-additional" type="button" basic onClick={this.show_delete_modal}>Delete</Button>
                    <Button id="btn-add-additional" type='button' primary onClick={this.add_additiona_payment}>Add</Button>
                </div>
            </Grid.Column>
            <Grid.Column computer={16}>
                <div className="customtable" id="id-additional-payment-level0">
                    <div className="table-responsive" id="id-additional-payment-level1">
                        <table className="ui table">
                            <thead>
                                <tr>
                                    <th className="text-center">
                                        <input type="checkbox"
                                            id="id_check_all_items"
                                            onChange={this.on_check_change_all}
                                            checked={this.state.select_all}
                                            value="" />
                                    </th>
                                    <th style={{ minWidth: '155px', width: '14%' }}>Date of Service</th>
                                    <th style={{ minWidth: '155px', width: '14%' }}>Transaction Type</th>
                                    <th style={{ minWidth: '155px', width: '15%' }}>Payment Code</th>
                                    <th style={{ minWidth: '155px', width: '14%' }}>Insurance</th>
                                    <th style={{ minWidth: '155px', width: '14%' }}>Amount</th>
                                    <th style={{ minWidth: '155px', width: '14%' }}>Provider</th>
                                    <th style={{ minWidth: '155px', width: '14%' }}>Location</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.render_rows()}
                            </tbody>
                            <tfoot>
                                {this.render_footer()}
                            </tfoot>
                        </table>
                    </div>
                </div>
            </Grid.Column>
            <AlertConfirm
                open={this.state.open_confirm_alert}
                close={() => this.setState({ open_confirm_alert: false })}
                cancel={() => this.setState({ open_confirm_alert: false })}
                confirm={this.handle_confirm}
                message={this.state.confirm_message}
            />
        </>);
    }
}

export default AdditionalPaymentsComponents;