import * as React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import MaskedInput from "react-text-mask";
import { Button, Dimmer, Form, Grid, Loader, Modal, Input } from "semantic-ui-react";
import * as global_constants from "../../global_constants";
import * as shared_constants from "../../shared/shared_constants";
import { add_referring_source } from "../action/shared_action";
import { enum_case_page_state, enum_type_of_search } from "./../../case/case_constants";
import { referring_source_type_advance_search, referring_source_type_quick_search } from "./../../shared/action/autosearch_action";
import AdvancedControl from "./../../shared/component/advanced_control";
import AutoSearchComponent from "./../../shared/component/auto_search_component";
import SelectionComponent from "./selection_component";
// import RadioButtonUtility from "./radio_buttons_utility"
import { handle_focus_on_tab } from './../../shared/tab_navigation_utility';
import { unformat_zip_code, unmask, zip_code_formation, get_all_error } from "./../../shared/utility";

const radioButtonOptions = [
  {
    selected:false,
    label:"Windows",
    name:"radiobutton",
    value:"window"
  },
  {
    selected:false,
    label:"Ubantu",
    name:"radiobutton",
    value:"ubantu"
  }
]

interface AddRefferal {
  show_modal: boolean;
  master_data: { states: any[]; };
  page_state: enum_case_page_state;
  form: {
    source_type_id: string;
    source_desc: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    type: string;
    phone: string;
    fax: string;
    email: string;
    website: string;
    is_active: boolean;
    is_force_save: boolean;
  };
  search_referral_source_type_quick_search_format: { label: string; title: string; rs_code: string };
  search_referral_source_type_data: any
  states:any[];
  grid_conf: object;
  force_save_confirm: boolean;
  error: {
    source_type_id: boolean;
    source_desc: boolean;
    phone: boolean;
    fax: boolean;
    zip: boolean;
    email: boolean;
  };
  source_desc_label: boolean;
  add_search_referral_source_type_modal: boolean;
}

class AddRefferingSource extends React.Component<any, AddRefferal> {
  constructor(props) {
    super(props);
    this.state = {
      show_modal: false,
      form: {
        source_type_id: "",
        source_desc: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        type:"",
        fax: "",
        email: "",
        website: "",
        is_active: true,
        is_force_save: false
      },
      page_state: enum_case_page_state.add,
      search_referral_source_type_data: {},
      search_referral_source_type_quick_search_format: {
        title: "",
        label: "",
        rs_code: ""
      },
      grid_conf: {
        isPagination: true,
        gridAutoHeight: true,
        selectionType: "single",
        paginationPageSize: 20
      },
      states: [],
      master_data: this.props.user_login_details.master_data.data,
      force_save_confirm: false,
      error: {
        source_type_id: false,
        source_desc: false,
        phone: false,
        fax: false,
        zip: false,
        email: false
      },
      source_desc_label: false,
      add_search_referral_source_type_modal: false
    };
  }
  initial_form_data = null;
  initial_error_data = null;
  loading = false;
  is_submitted = false;
  token = "";

  componentDidMount = async () => {
    this.token = this.props.user_login_details.user_data.data.token_details.access_token; // update token at component update
    const states = this.state.master_data.states.map((val, index) => {
      return { key: index, value: val.id, text: val.name };
    });
    this.setState({ states: states });
    this.initial_form_data = { ...this.state.form };
    this.initial_error_data = { ...this.state.error };
  };

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.add_referring_source !== this.props.add_referring_source) {
      let format_rs = { label: "", title: "", rs_code: "" };
        this.setState({ show_modal: true, form: { ...this.state.form }, search_referral_source_type_quick_search_format: format_rs });
        this.handle_navigation_on_modal("add_referral_source_cross_icon", "add_referral_source_save_button");
    }
  } 

  /*componentDidUpdate(preProps, prevState) {
      this.token = this.props.user_login_details.user_data.data.token_details.access_token; // update token at component update
      if (preProps.add_referring_source !== this.props.add_referring_source) {
          let format_rs = { label: "", title: "", rs_code: "" };
          this.setState({ show_modal: true, form: { ...this.state.form }, search_referral_source_type_quick_search_format: format_rs });
      }
  }*/

  // filter dropdown value
  filter_value_by_id = (data, selected_id) => {
    return data.filter((val, index) => {
      return val.id == selected_id;
    });
  };

  // check if data has only letters and numbers
  input_data_validation = (data, type) => {
    let isValid ;
    switch (type) {
      case "name":
        let letterNumber = /^[0-9a-zA-Z\s]+$/;
        isValid = !data.match(letterNumber);
        break;
      case "number":
        data = data.replace(/[^0-9]/g, "");
        isValid = data.length != 10;
        break;
      case "zip":
        data = data && data.length > 0 && data.replace(/[^0-9]/g, '');       
        isValid = data && ((data.length > 0 && data.length < 5) || (data.length > 5 && data.length < 9));
        break;
      case "email":
        data.length > 0
          ? (isValid = data.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
              ? false
              : true)
          : (isValid = false);
        break;
      default:
        break;
    }
    return isValid;
  };

  // validate form before saving data
  validate_form = () => {
    const { form } = this.state;
    const error = { ...this.state.error };
    error.source_type_id = form.source_type_id === "" ? true : false;
    error.source_desc = form.source_desc.trim() === "" ? true : false;
    error.zip = form.zip.length > 0 && this.input_data_validation(form.zip, "zip");
    error.phone = form.phone.length > 0 && this.input_data_validation(form.phone, "number");
    error.fax = form.fax.length > 0 && this.input_data_validation(form.fax, "number");
    error.email = form.email.length > 0 && this.input_data_validation(form.email, "email");
    this.setState({
      error: error,
      source_desc_label:
        form.source_desc.trim() != ""
          ? true
          : false
    });
    if(Object.keys(error).some(data => {return error[data] == true;})) {
      toastr.error("",global_constants.constants.mandatory_fields);
      this.is_submitted = true;
      return false;
    }
    return true;
  };

  // close modal and empty form data
  closeModal = () => {
    this.setState({
      show_modal: false,
      form: this.initial_form_data,
      error: this.initial_error_data
    });
      this.is_submitted = false;


      // setting up the focus to the parent
      let ele: HTMLElement = document.querySelector('#bg_fff2[name=Add]') as HTMLElement;
    
      if (ele) {
          ele.focus();
      }
  };
    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }
  // save form data
  save_referral_source_data = async e => {
    e.preventDefault();
    if (this.validate_form()) { 
      let {show_modal, form, error} = this.state
      let form_obj = {};
      form_obj["id"] = 0;
      form_obj["type_id"] = form.source_type_id;
      form_obj["type"] = form.type;
      form_obj["description"] = form.source_desc;
      form_obj["address1"] = form.address1;
      form_obj["address2"] = form.address2;
      form_obj["city"] = form.city;
      form_obj["state"] = form.state;
      form_obj["zip"] = unformat_zip_code(form.zip);
      form_obj["phone"] = form.phone;
      form_obj["fax"] = form.fax !=="" ? unmask(form.fax) : form.fax
      form_obj["email"] = form.email;
      form_obj["website"] = form.website;
      form_obj["is_active"] = form.is_active;
       await add_referring_source(this.token, form_obj).then(res => {
        let response = res.data;
           if (response.status === 1 && response.messages === null) {
               this.is_submitted = false;
               form_obj["id"] = response.data
               this.props.get_formatted_referral_source_info(form_obj)
               show_modal = false
               form = this.initial_form_data
               error = this.initial_error_data
           }
           else {
               if (response.messages) {
                   const toastr_options = this.show_html_content_toaster(get_all_error(response));
                   toastr.error('', toastr_options);
               }
           }
        this.setState({
          show_modal,
          form,
          error
      })
      });
    }
  };

  // set input field value in state
  handle_input_change = e => {
    const input_field = e.target.name;
    let input_value = e.target.value;
    const { error } = this.state;
    let form_data = this.state.form
    if (input_field == "source_desc" && this.is_submitted) {
      error[input_field] = input_value.trim() == "" ? true : false;
      form_data[input_field] = input_value ? input_value : '';
    } else if (input_field == "fax" || input_field == "phone") {
      let is_error = this.input_data_validation(input_value, "number");
      error[input_field] = is_error;
      form_data[input_field] = input_value ? input_value : '';
    } else if (input_field == "zip") {
      let field_value = input_value && input_value.length > 0 && input_value.replace(/[^0-9]/g, '');
      if(!isNaN(field_value)){
        error[input_field] = this.input_data_validation(field_value, "zip")
        form_data[input_field] = field_value ? zip_code_formation(field_value) : '';
      }
    } else if (input_field == "email") {
      error[input_field] = this.input_data_validation(input_value, "email");
      form_data[input_field] = input_value;
    } else {
      form_data[input_field] = input_value ? input_value : '';
    }

    this.setState({
      form: form_data,
      error: error
    });
  }

  // function/method for searching referral source type
  on_search_referral_source_type_row_selection = selected_row => {
    this.on_row_selection_advance_search(
      enum_type_of_search.search_referral_source_type,
      selected_row
    );
  };
    on_row_selection_advance_search = (type_of_search, selected_row) => {
    let id = "";
    let name = "";
    let code = "";
    const { form, error } = this.state;
    let selected_data = "";
    if (selected_row) {
      id = selected_row.id;
      code = selected_row.code;
      id = id.toString();
      form.source_type_id = id;
      form.type = selected_row.description
      error.source_type_id = id ? false : true;
      if (selected_row.description != null && selected_row.description != undefined) {
        name = selected_row.description == null && selected_row.description == undefined
                ? ""
                : `${selected_row.code}-${selected_row.description}`;
      }
    }
    selected_data = name;
    let format_rs = { label: "", title: "", rs_code: "" };
    format_rs = {
      title: id,
      rs_code: code,
      label: selected_data
    };
    this.setState({
      search_referral_source_type_quick_search_format: format_rs,
      form,
      error
    });
  };

  on_search_referral_source_type_search = async params => {
    this.configure_grid_data(
      enum_type_of_search.search_referral_source_type,
      params
    );
  };

  // function/method to handle to configure data after the search happens in advance controls
  configure_grid_data = async (type_of_search, params) => {
    try {
      const {user_login_details: {user_data: {data: {token_details: { access_token }}}}} = this.props;
      const { grid_conf } = this.state;
      const token = access_token;
      params.page_size = 100;
      let grid_data: {
        rows: any;
        column: {
          headerName: string;
          field: string;
          filter: string;
          comparator: (text1: any, text2: any) => 0 | 1 | -1;
          headerComponentParams: { template: string };
          unSortIcon: boolean;
          minWidth: number;
        }[];
        messages: any;
      };
      const search_referral_source_type_data = await referring_source_type_advance_search( params, token).then(res => res.data);
        grid_data = {
        ...grid_conf,
        rows: search_referral_source_type_data.data !== null ? search_referral_source_type_data.data : [],
        column: shared_constants.search_referring_source_type_configuration.column_defs,
        messages: search_referral_source_type_data.messages
      };
      this.setState({ search_referral_source_type_data: grid_data }); 
    } catch (error) {
        toastr.error("", error);
    }

  };

  // function for render suggestions
    render_suggestion_result = props => {
        return (
            <div key={props.title} tabIndex={0} className="item_auto_search">
                <div key={props.title} className="fs_13">
                    <span>{props.label}</span>
                </div>
            </div>
        );
    };

  // preapare the suggestion list with search result
  prepare_suggestion = (_data, type_of_search) => {
    let formattedList = [];
    let data_length = 0;
    if (_data.data) {
      data_length = _data.data.length;
      _data = _data.data;
    } else {
      data_length = _data.length;
    }
    if (_data && data_length) {
      for (let i = 0; i < data_length; i++) {
        let item = _data[i];
        if (type_of_search === enum_type_of_search.search_referral_source_type) {
          formattedList.push({
            title: `${item.id}`,
            code: item.code,
            name: item.description,
            label: `${item.code}-${item.description}`
          });
        }
      }
    }
    return formattedList;
  };

  // select the item on click suggested items
  on_item_selection = (item, type) => {
    const { form, error } = this.state;
    form.source_type_id = item.title;
    form.type = item.name
    error.source_type_id = item.title ? false : true;
    this.setState({
      form,
      error
    });
  };

  // for clear quick search
  clear_quick_search = type_of_search => {
    let {search_referral_source_type_quick_search_format, form, error} = this.state;
    form.source_type_id = "";
    form.type = ""
    if (this.is_submitted) error.source_type_id = true;
    search_referral_source_type_quick_search_format = {
      title: "",
      label: "",
      rs_code: ""
    };
    this.setState({
      search_referral_source_type_quick_search_format,
      form,
      error
    });
  };

  // function for getting referral souce data for quick search
  get_search_referral_source_type_quick_search_data_list = async search_keyword => {
    const token = this.props.user_login_details.user_data.data.token_details
      .access_token;
    return await referring_source_type_quick_search(search_keyword, token);
  };
  get_input_value = (input, type) => {
    let {search_referral_source_type_quick_search_format, form, error} = this.state;
    if(input.length==0 && this.is_submitted){
      error.source_type_id = true;
      form.source_type_id = "";
      form.type = ""
      search_referral_source_type_quick_search_format = {
        title: "",
        label: "",
        rs_code: ""
      };
      this.setState({
        search_referral_source_type_quick_search_format,
        form,
        error
      });
    }
    }

    handle_navigation_on_modal = (close_icon_id, on_shift_tab_close_icon) => {
        setTimeout(() => {
            let closeIcons: NodeListOf<HTMLElement> = document.querySelectorAll('i.close.icon:not(.auto-search-icon)') as NodeListOf<HTMLElement>;
            let closeIcon = closeIcons[closeIcons.length - 1]
            if (closeIcon) {
                closeIcon.tabIndex = 0;
                closeIcon.id = close_icon_id;
                closeIcon.addEventListener("keydown", function (event) {
                    if (event.shiftKey && event.keyCode == 9) {
                        event.preventDefault();
                        let search_button = document.getElementById(on_shift_tab_close_icon);
                        search_button.focus();
                    }

                    if (event.keyCode == 13) {
                        event.preventDefault();
                        var el = event.target as HTMLElement
                        el.click();
                    }
                });
            }

            let elementToFocusOn = document.getElementById("advance-control-SEARCH_REFERRAL_SOURCE_TYPE");
            if (elementToFocusOn) {
                elementToFocusOn.focus();
            }
        }, 500)
    }
  render() {
    const source_desc_label = this.state.source_desc_label ? { display: "block" } : { display: "none" };
    const { error, form, search_referral_source_type_quick_search_format } = this.state;
     return <>
         <Dimmer active={this.loading}>
           <Loader size="massive">Loading</Loader>
         </Dimmer>
         <Modal
           onClose={this.closeModal}
           centered={false}
           className=" default-modal"
           open={this.state.show_modal}
           closeIcon={true}
           id="add_refferal_model_pop"
           closeOnDimmerClick={false}
         >
         <Modal.Header>
           <h3>Add Referral Source</h3>
         </Modal.Header>
         <Modal.Content>
           <Modal.Description>
             <div className="common-forms-add" style={{ padding: "0" }}>
               <Form>
                 <Grid>
                     <Grid.Column mobile={16} tablet={8} computer={8}>
                       <Form.Field className={`advance-quick-search ${error.source_type_id ? "requiredWithBgColor error" : ""}`}>
                         <label>
                           Referral Source Type Id{" "}
                           <span className={ error.source_type_id ? "req-alert" : "req-alert_normal" } >
                             (required)
                           </span>
                         </label>
                         <AdvancedControl
                           disabled_element={this.state.page_state === enum_case_page_state.edit}
                           onGridRowSelection={this.on_search_referral_source_type_row_selection}
                           gridConfig={this.state.search_referral_source_type_data}
                           controlId={global_constants.constants.advanced_control_type.search_referral_source_type}
                           onSearch={this.on_search_referral_source_type_search}
                           search_type={"SEARCH_REFERRAL_SOURCE_TYPE"}
                           headerIdForGridTabNavigation={shared_constants.search_referring_source_type_grid_header_id}
                         />
                         <AutoSearchComponent
                           getInputValue={(input) => this.get_input_value(input, enum_type_of_search.search_referral_source_type)}
                           default_value={this.state.search_referral_source_type_quick_search_format}
                           errorMessage={"No Referral Source Type Found !"}
                           prepareRenderList={this.render_suggestion_result}
                           getList={this.get_search_referral_source_type_quick_search_data_list}
                           prepareDataList={data =>this.prepare_suggestion(data,enum_type_of_search.search_referral_source_type)}
                           selectresult={item =>this.on_item_selection(item,enum_type_of_search.search_referral_source_type)}
                           is_focus={false}
                           is_disabled={this.state.page_state ===enum_case_page_state.edit}
                           show_clear_search={true}
                           clear_search={() =>this.clear_quick_search(enum_type_of_search.search_referral_source_type)
                           }
                         />
                       </Form.Field>
                     </Grid.Column>
                     <Grid.Column mobile={16} tablet={8} computer={8}>
                       <Form.Field className={error.source_desc ? "requiredWithBgColor" : ""}>
                         <label>
                           Source Description{" "}
                           <span className={error.source_desc ? "req-alert": "req-alert_normal"}>
                             (required)
                           </span>
                         </label>
                         <Form.Input
                           fluid
                           disabled={false}
                           autoComplete="off"
                           className={error.source_desc ? "error" : ""}
                           name="source_desc"
                           value={form.source_desc}
                           onChange={this.handle_input_change}
                           id="case_name_txt"
                           maxLength={500}
                         />
                       </Form.Field>
                     </Grid.Column>

                     <Grid.Column mobile={16} tablet={8} computer={8}>
                       <Form.Field>
                         <label>
                           Address 1 <span className="req-alert" />
                         </label>
                         <Form.Input
                           fluid
                           disabled={false}
                           autoComplete="off"
                           name="address1"
                           value={form.address1}
                           onChange={this.handle_input_change}
                           id="case_name_txt"
                           maxLength={50}
                         />
                       </Form.Field>
                     </Grid.Column>
                     <Grid.Column mobile={16} tablet={8} computer={8}>
                       <Form.Field>
                         <label>
                           Address 2 <span className="req-alert" />
                         </label>
                         <Form.Input
                           fluid
                           disabled={false}
                           autoComplete="off"
                           onChange={this.handle_input_change}
                           name="address2"
                           value={form.address2}
                           id="case_name_txt"
                           maxLength={50}
                         />
                       </Form.Field>
                     </Grid.Column>

                     <Grid.Column mobile={16} tablet={5} computer={5}>
                       <Form.Field>
                         <label>
                           City <span className="req-alert" />
                         </label>
                         <Form.Input
                           fluid
                           disabled={false}
                           autoComplete="off"
                           name="city"
                           value={form.city}
                           onChange={this.handle_input_change}
                           id="case_name_txt"
                           maxLength={50}
                         />
                       </Form.Field>
                     </Grid.Column>
                     <Grid.Column mobile={16} tablet={5} computer={5}>
                       <Form.Field>
                         <label>
                           State <span className="req-alert" />
                         </label>                           
                           <SelectionComponent
                               name='state'
                               id='reffering_state'
                               placeHolder="Select"
                               options={this.state.states}
                               onChange={(value) => {
                                   this.setState({
                                       form: { ...this.state.form, state: value }
                                   });
                               }}
                               defaultValue={this.state.form.state}
                               hidden={true}
                           />
                       </Form.Field>
                     </Grid.Column>
                     <Grid.Column mobile={16} tablet={6} computer={6}>
                       <Form.Field className={this.state.form.zip && unformat_zip_code(this.state.form.zip).length > 0 && this.is_submitted && error.zip ? "error" : ""}>
                         <label>
                           Zip <span className="req-alert" />
                         </label>
                         <div className="ui input">
                           <input
                             autoComplete='new-password'
                             name='zip'
                             value={form.zip}
                             onChange={this.handle_input_change}
                             id="case_name_txt"  
                             maxLength={10}
                           />
                         </div>                    
                         {this.state.form.zip && unformat_zip_code(this.state.form.zip).length > 0 && this.is_submitted && error.zip
                           && <div className="requiredText">{global_constants.constants.zip_compulsory}</div>
                         }
                       </Form.Field>
                     </Grid.Column>

                     <Grid.Column mobile={16} tablet={5} computer={5}>
                       <Form.Field className={ this.state.form.phone.length > 0 && this.is_submitted && error.phone ? "error " : ""}>
                         <label>
                           Phone Number <span className="req-alert" />
                         </label>
                         <MaskedInput
                           mask={["(",/[1-9]/,/\d/,/\d/,")"," ",/\d/,/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/]}
                           className="form-control"
                           placeholder="(___) ___-____"
                           guide={true}
                           name="phone"
                           id="phone-no"
                           value={unmask(form.phone)}
                           autoComplete="off"
                           onChange={this.handle_input_change}
                         />                          
                           {this.state.form.phone.length > 0 && this.is_submitted && error.phone ? <div className="requiredText">{global_constants.constants.ten_digits_compulsory}</div>: ""}
                       </Form.Field>
                     </Grid.Column>
                     <Grid.Column mobile={16} tablet={5} computer={5}>
                       <Form.Field className={ this.state.form.fax.length > 0 && this.is_submitted && error.fax ? "error " : ""}>
                         <label>
                           Fax <span className="req-alert" />
                         </label>
                         <MaskedInput
                           mask={["(",/[1-9]/,/\d/,/\d/,")"," ",/\d/,/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/]}
                           className="form-control"
                           placeholder="(___) ___-____"
                           guide={true}
                           name="fax"
                           id="fax-no"
                           value={unmask(form.fax)}
                           autoComplete="off"
                           onChange={this.handle_input_change}
                         />                          
                           {this.state.form.fax.length > 0 && this.is_submitted && error.fax ? <div className="requiredText">{global_constants.constants.ten_digits_compulsory}</div> : ""}
                       </Form.Field>
                     </Grid.Column>
                     <Grid.Column mobile={16} tablet={6} computer={6}>
                       <Form.Field className={this.state.form.email.length > 0 && this.is_submitted && error.email ? "error" : ""}>
                         <label>
                           Email <span className="req-alert" />
                         </label>
                         <Input
                           fluid
                           disabled={false}
                           autoComplete="off"
                           name="email"
                           value={form.email}
                           onChange={this.handle_input_change}
                           id="case_name_txt"
                           maxLength={50}
                         />                          
                           {error.email && this.is_submitted
                             ? <div className="requiredText">{global_constants.constants.valid_email}</div>
                             : ""
                           }
                       </Form.Field>
                     </Grid.Column>

                     <Grid.Column mobile={16} tablet={16} computer={16}>
                       <Form.Field>
                         <label>
                           Website <span className="req-alert" />
                         </label>
                         <Form.Input
                           fluid
                           disabled={false}
                           autoComplete="off"
                           value={form.website}
                           name="website"
                           onChange={this.handle_input_change}
                           id="case_name_txt"
                           maxLength={50}
                         />
                       </Form.Field>
                     </Grid.Column>
                     <Grid.Column mobile={16} tablet={16} computer={16}>
                       <input 
                       type="checkbox"
                         checked={this.state.form.is_active}
                         onChange={e =>
                           this.setState({
                             form: {
                               ...this.state.form,
                               is_active: !this.state.form.is_active
                             }
                           })
                         }                         
                       /> Is Active
                     </Grid.Column>                      
                 </Grid>
               </Form>
             </div>
             <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area padd-r-0" id="applicationFooterSticky">
               <Button id="patient_cancel_btn" basic onClick={this.closeModal}>
                 Cancel
               </Button>
                            <Button onKeyDown={(event) => { handle_focus_on_tab(event, "add_referral_source_cross_icon") }} id="add_referral_source_save_button" type="submit" primary onClick={this.save_referral_source_data}>
                 Save
               </Button>
             </div>
           </Modal.Description>
         </Modal.Content>
       </Modal>
     </>;
  }
}
const mapSTateToProps = state => {
  return {
    user_login_details: state.user_login_details
  };
};
export default connect(
  mapSTateToProps,
  null
)(AddRefferingSource);
