import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GridView from '../../shared/component/grid';
import * as report_constant from '../report_constants';
import { Button, Grid, Header } from "semantic-ui-react";
import { get_eob_details_data, update_zero_payment_notes } from '../action/report_action';
import { LoaderComponent } from '../../shared/component/loading_component';
import { get_all_error, get_columns } from '../../shared/utility';
import { toastr } from 'react-redux-toastr';
import { set_focus_on_element_with_id } from '../../shared/tab_navigation_utility';
import { toastr_options } from '../../global_constants';

export class OpenZeroPaymentsDetailsInformationComponent extends React.Component<any, any> {
    [x: string]: any;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            is_update_disabled: false,
            grid_params: [],
            is_grid_visible: false
        }
    }
    _is_mounted = false;
    grid_data: any = {
        rows: [],
        grid_conf: report_constant.open_zero_payments_setting_columns
    };
    total_column_width: number = 0;
    grid_header_height: number = 0;
    selected_eobs: any = [];

    componentWillUnmount = () => {
        this._is_mounted = false;
    };

    componentDidMount() {
        this._is_mounted = true;
        this.get_eob_details_data();
    }

    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }

    // Get Remarks Data.
    get_eob_details_data = async () => {
        if (this._is_mounted) {
            this.setState({
                loading: true,
                is_grid_visible: false,
            });
        }
        this.selected_eobs = []
        this.grid_data.row = []
        await get_eob_details_data(this.props.eob_key, this.props.token).then(
            (response) => {
                if (response.data && response.data.data) {
                    this.grid_data.rows = this.row_data_formatter(response.data.data, this.grid_data.grid_conf, true);
                    const grid_height = get_columns(this.grid_data.rows, this.grid_data.grid_conf);
                    this.total_column_width = grid_height.total_column_width;
                    this.grid_header_height = grid_height.header_height;
                } else {
                    const toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                    toastr.error('', toastr_options);
                }
                if (this._is_mounted) {
                    this.setState({
                        loading: false,
                        is_grid_visible: true,
                    });
                }
            },
            (error: { response: { data: any } }) => {
                if (this._is_mounted) {
                    this.setState({
                        loading: false
                    });
                }

                if (error.response.data) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toastr.error("", toastr_options);
                }
            }
        );
    };

    get_grid_ref = grid_params => {
        if (this._is_mounted) {
            this.setState({
                grid_params
            });
        }
       
        var grid_width = document.getElementById('open_zero_details_payment').clientWidth;
        
        if (this.total_column_width > grid_width) {
            this.grid_data.grid_conf.filter(item => {
                if (item.field == 'notes') {
                    grid_params.columnApi.setColumnWidth('notes', 250, false);
                } else {
                    grid_params.columnApi.setColumnWidth(item.field, item.width, false);

                }
            });
        }
        this.props.setDetailsInfo(this.state.grid_params);
    };

    on_grid_out = () => {
        set_focus_on_element_with_id('app_header_dropdown');
    };

    update_report_rows_count = () => {
        var grid_width = document.getElementById('open_zero_details_payment').clientWidth;

        if (this.total_column_width > grid_width) {
            this.grid_data.grid_conf.filter(item => {
                if (item.field == 'notes') {
                    this.state.grid_params.columnApi.setColumnWidth('notes', 250, false);
                } else {
                    this.state.grid_params.columnApi.setColumnWidth(item.field, item.width, false);

                }
            });
        }
        this.props.setDetailsInfo(this.state.grid_params);
    };

    grid_height = (params) => {
        if (!params.node.isRowPinned()) {
            return 50;
        } else {
            return 38;
        }
    }

    // Select eob to close 
    on_check_box_selection = (items) => {
        this.selected_eobs = items;
    };

    on_cell_Click = async ({ data, column }) => {
        if (column.colId == 'patient_id') {
            var patient_id = data.patient_id;
            var navigate_to = `/patient/${patient_id}`;
            this.props.history.push(`${navigate_to}`);
        }
    }

    row_data_formatter = (data, grid_column, is_detail_grid = false) => {
        data.map(row => {
            grid_column.map((value) => {
                if (is_detail_grid) {
                    row['notes'] = "";
                    row['checkbox'] = '';
                }
                switch (value.type) {
                    case "currency":
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined
                                ? report_constant.currencyFormatter(row[value.field])
                                : "";
                        break;
                    default:
                        row[value.field] = row[value.field] ? row[value.field].toString().trim() : "";
                }
            });
            return row;
        });

        return data;
    }

    handle_close_eob = async () => {
        if (this.selected_eobs && this.selected_eobs.length == 0) {
            toastr.warning('', report_constant.error_messages.select_single_eob);
            return;
        }
        if (this.selected_eobs && this.selected_eobs.length > 0) {
            // Create Query Param from checked items
            const params = this.selected_eobs.map((value) => {
                return { eob_key: this.props.eob_key, note: value.notes, charge_no: value.chgno, account_no: value.patient_id, group_no: parseInt(value.group_id), sequence: parseInt(value.sequence) };
            });
            if (this._is_mounted) {
                this.setState({
                    loading: true,
                    is_update_disabled: true
                });
            }

            await update_zero_payment_notes(params, this.props.token).then(
                (response) => {
                    if (response.data && response.data.status == 1) {
                        toastr.success('', report_constant.error_messages.closed_succesfully);
                        this.props.history.goBack();
                    } else {
                        let toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                        toastr.error("", toastr_options);
                    }
                    this.selected_eobs = [];
                    if (this._is_mounted) {
                        this.setState({
                            loading: false,
                            is_update_disabled: false
                        });
                    }
                   
                },
                (error) => {
                    if (this._is_mounted) {
                        this.setState({
                            loading: false,
                            is_update_disabled: false
                        });
                    }
                    
                    if (error.response.data) {
                        const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                        toastr.error("", toastr_options);
                    }
                }
            );
        }
    };

    render() {
        return (
            <LoaderComponent loading={this.state.loading}>
                <div style={{ border: " 1px solid #cccccc", background: " #fff", marginTop: 25 }} className="open-zero-framework" id="open_zero_details_payment">
                    <div className="ui grid headerGrid" style={{ margin: 0, paddingTop: 5 }}>
                        <div className="sixteen wide computer column">
                            <Header as='h2' textAlign="left" className="section-heading-miniHead">Detail Information</Header>
                        </div>
                    </div>
                    {this.state.is_grid_visible && (
                        <GridView
                            id="open_zero_information"
                            row={this.grid_data.rows}
                            column={this.grid_data.grid_conf}
                            style={{ height: '100%' }}
                            wrapperStyle={{ width: '100%', height: '100%', display: 'flex' }}
                            suppressMovableColumns={false}
                            enableColResize={true}
                            selectionType={'single'}
                            gridAutoHeight={true}
                            checkboxSelection={true}
                            get_grid_ref={this.get_grid_ref}
                            suppressSizeToFit={true}
                            headerHeight={this.grid_header_height}
                            onRowSelection={(item) => this.on_check_box_selection(item)}
                            headerIdForTabNavigation={"open_zero_information_id"}
                            onForceGridOut={this.on_grid_out}
                            on_filter_button_click={() => {
                                this.update_report_rows_count();
                            }}
                            getRowHeight={this.grid_height}
                            onCellClicked={this.on_cell_Click}
                     />)}
                </div>
                <Grid style={{ marginTop: 0, marginBottom: 0 }}>
                    <Grid.Column tablet={16} computer={16} textAlign="right">
                        <Button primary id='update_btn' type='button' disabled={this.state.is_update_disabled} onClick={this.handle_close_eob}>
                            Close
                        </Button>
                    </Grid.Column>
                </Grid>
            </LoaderComponent>
         )}
     }
            
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
                    }, dispatch)
                }
                
                
const mapStateToProps = (state) => {
    return {
                }
            }
            
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OpenZeroPaymentsDetailsInformationComponent));