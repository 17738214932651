import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { bindActionCreators } from 'redux';
import { Checkbox, Dimmer, Form, Grid, GridColumn, Loader } from 'semantic-ui-react';
import { clearTimeout } from 'timers';
import * as global_constants from '../../global_constants';
import { get_patient_details } from '../../patient/action/patient_action';
import { currentDateTime } from '../../shared/calendar_utils';
import AddPrimaryInsurance from '../../shared/component/add_primary_insurance_component';
import DatePicker from '../../shared/component/date_picker_component';
import InsuranceDoctorsOrdersComponent from '../component/insurance_doctors_order_component';
import InsuranceEligibilityComponent from '../component/insurance_eligibility_component';
import InsurancePreauthorizationComponent from '../component/insurnace_preauthorization_component';
import { add_subscriber, get_subscriber } from './../../case/action/subscriber_action';
import { insurance_advance_search, insurance_advance_search_sec_tertiary, insurance_quick_search, insurance_quick_search_sec_tertiary } from './../../shared/action/autosearch_action';
import AutoSearchComponent from './../../shared/component/auto_search_component';
import AdvancedControlWithResizable from './../../shared/component/resizable_advanced_control';
//import ZipFormatter from './../../shared/component/zip_formatter'
import SelectionComponent from "./../../shared/component/selection_component";
import * as shared_constants from './../../shared/shared_constants';
import { camelize, check_allowed_special_chars, custom_date_format, is_all_numbers, is_valid_value, phone_format, set_column_data_width, unformat_zip_code, unmask, zip_code_formation, trim_nested_json_obj_values, date_format_with_no_time, is_null_or_white_space, get_lauch_darkley_key_value } from './../../shared/utility';
import { case_messages, configurable_date, enum_case_page_state, enum_type_of_insurance, enum_type_of_search, feature_flags } from './../case_constants';
import { handle_click_on_enter, set_focus_on_element_with_id } from '../../shared/tab_navigation_utility';
import { string } from 'prop-types';

// Add Edit and view case details
//Define the state with their data types
interface IProps {
    patient_id: string,
    get_update_subscriber_info?: Function,
    case_id: string,
    ins_type: string,
    page_state: any,
    form_submitted: boolean,
    shown: boolean,
    user_login_details: {formated_master_data: any, user_data: { data: { token_details: { access_token: any; }; }; }},
    get_show_status_insurance: Function,
    onRef?: any,
    patient_details: any,
    get_patient_details: any,
    insurance_types: any,
    insurance_contract: any,
    features: {
        is_payment_posting_feedback_enabled: boolean,
    },
}
interface IState {
    shown: boolean,
    master_data: any,
    loading: boolean,
    doctors_fetch_time: any,
    subscriber_fetched_time: any,
    case_id: number,
    timeout: any,
    // is_edit_mode: boolean,
    page_state: enum_case_page_state,
    form_submited: boolean,
    insurance_search_data: any,
    grid_conf: any,
    control_flags: {
        is_show_msp_type: boolean,
        is_show_claim_number_ssn: boolean,
        is_show_ssn_claim_number: boolean,
        is_worker_comp: boolean,
        is_req_validation_policy_no_rr: boolean,
        is_req_validation_policy_no_mc: boolean,
        is_req_validation_policy_no_ch: boolean,
        is_insurance_show: boolean,
        is_insurance_active: boolean,
        is_same_as_patient: boolean
    },
    insurance_quick_search_format: {
        "title": string,
        "label": string
    },
    patient_data: any,
    insurance_code_confirmation_box: boolean,

    subscriber: {
        id: number,
        case_id: number,
        patient_id: string,
        insurance_code_id: string,
        group_number: string,
        policy_number: string,
        pst_coverage: string,
        msp_type: string,
        same_as_patient: boolean,
        is_us_selected: boolean,
        insurance: {
            phone_cust: string,
            id: string,
            payer_formatted_description: string,
        }
        name: {
            middle_initial: string,
            is_name_requried: boolean,
            suffix: string,
            first_name: string,
            last_name: string,

        },
        address: {
            address1: string,
            address2: string,
            city: string,
            province: string,
            zip: string,
            unformat_zip: string,
            postal_code: string,
            state_id: string,
            country_id: number,
            is_required: boolean
        },
        phone: string,
        relationship: string,
        ssn: string,
        gender: string,
        date_of_birth: Date,
        formatted_date_of_birth: any,
        is_active: boolean,
        employee_name: string,
        employee_address: {
            address1: string,
            address2: string,
            city: string,
            province: string,
            zip: string,
            unformat_zip: string,
            postal_code: string,
            state_id: string,
            country_id: number,
            is_required: boolean
        },
        employee_phone: string,
        parent_id: string,
        top_ancestor_id: string,
        subscriber_doctors_orders: any,
        subscriber_eligibility: any,
        subscriber_pre_auth: any

        // Store old values
        old_insurance: string,
        old_date_of_birth: any,
        old_group_number: string,
        old_policy_number: string,
        old_first_name: string,
        old_last_name: string,
        old_address1: string,
        old_city: string,
        old_state_id: string,
        old_zip: string,
        old_relationship: string,

        is_insurance_update: boolean,
        is_insurance_available: boolean
    },

    subscriber_form_invalid: {
        insurance_code_id: boolean,
        group_number: boolean,
        policy_number: boolean,
        phone: boolean,
        employee_phone: boolean,
        name: {
            first_name: boolean,
            last_name: boolean,
        },
        address: {
            address1: boolean,
            city: boolean,
            province: boolean,
            zip: boolean,
            postal_code: boolean,
            state_id: boolean,
            country_id: boolean,
        },
        employee_address: {
            address1: boolean,
            city: boolean,
            province: boolean,
            zip: boolean,
            postal_code: boolean,
            state_id: boolean,
            country_id: boolean,
        },
        employee_name: boolean,
        relationship: boolean,
        gender: boolean,
        date_of_birth: boolean,
        ssn: boolean, formatted_date_of_birth: boolean,
        phone_cust: boolean

    },
    modified_data: {
        subscriber: {
            insurance_code_id: boolean,
            group_number: boolean,
            policy_number: boolean,
            name: {
                first_name: boolean,
                last_name: boolean,
            },
            address: {
                address1: boolean,
                city: boolean,
                province: boolean,
                zip: boolean,
                postal_code: boolean,
                state_id: boolean,
                country_id: boolean,
            },
            relationship: boolean,
            gender: boolean,
            date_of_birth: boolean,

        }
    },
    add_primary_insurance_modal: boolean,
    advanced_modal_state: boolean,
    features: {
        is_payment_posting_feedback_enabled: boolean,
    },
}

export class InsuranceComponent extends React.Component<IProps, IState> {
  grid_params: any;
    [x: string]: any;
    _is_mount = false;
    constructor(props) {
        super(props);

        this.state = {
            shown: this.props.shown ? this.props.shown : true,
            master_data: this.props.user_login_details.formated_master_data,
            doctors_fetch_time: '',
            subscriber_fetched_time: currentDateTime(),
            loading: false,
            page_state: this.props.page_state,//this.props.match.params.case_id ? enum_case_page_state.edit : enum_case_page_state.add,
            form_submited: this.props.form_submitted,
            case_id: parseInt(this.props.case_id),
            timeout: '',
            insurance_search_data: {},
            control_flags: {
                is_show_msp_type: false,
                is_show_claim_number_ssn: false,
                is_show_ssn_claim_number: false,
                is_worker_comp: false,
                is_req_validation_policy_no_rr: false,
                is_req_validation_policy_no_mc: false,
                is_req_validation_policy_no_ch: false,
                is_insurance_show: false,
                is_insurance_active: false,
                is_same_as_patient: false
            },
            grid_conf: {
                isPagination: true,
                gridAutoHeight: true,
                selectionType: 'single',
                paginationPageSize: 20,
            },
            insurance_quick_search_format: {
                "title": '',
                "label": '',

            },
            patient_data: null,
            insurance_code_confirmation_box: false,

            subscriber: {
                id: 0,
                case_id: parseInt(this.props.case_id),
                insurance: {
                    phone_cust: null,
                    id: "-999",
                    payer_formatted_description: '',
                },
                insurance_code_id: null,
                group_number: null,
                policy_number: null,
                pst_coverage: this.props.ins_type === enum_type_of_insurance.primary_insurance ? 'P' : this.props.ins_type === enum_type_of_insurance.secondary_insurance ? 'S' : 'T',
                msp_type: this.props.ins_type === enum_type_of_insurance.primary_insurance ? null : '12',
                same_as_patient: false,
                is_us_selected: true,
                patient_id: this.props.patient_id,
                //phone_cust: null,
                name: {
                    middle_initial: null,
                    is_name_requried: false,
                    suffix: null,
                    first_name: '',
                    last_name: null,

                },
                address: {
                    address1: null,
                    address2: null,
                    city: null,
                    province: null,
                    zip: null,
                    unformat_zip: null,
                    postal_code: null,
                    state_id: null,
                    country_id: 1,
                    is_required: false
                },
                phone: null,
                relationship: null,
                ssn: null,
                gender: null,
                date_of_birth: null,
                formatted_date_of_birth: null,
                is_active: true,
                employee_name: null,
                employee_address: {
                    address1: null,
                    address2: null,
                    city: null,
                    province: null,
                    zip: '',
                    unformat_zip: '',
                    postal_code: null,
                    state_id: null,
                    country_id: null,
                    is_required: false
                },
                employee_phone: null,
                parent_id: null,
                top_ancestor_id: null,
                subscriber_doctors_orders: [],
                subscriber_eligibility: [],
                subscriber_pre_auth: [],
                //To Store old values
                old_insurance: null,
                old_date_of_birth: null,
                old_group_number: null,
                old_policy_number: null,
                old_first_name: null,
                old_last_name: null,
                old_address1: null,
                old_city: null,
                old_state_id: null,
                old_zip: null,
                old_relationship: null,
                is_insurance_update: false,
                is_insurance_available: false,
            },
            subscriber_form_invalid: {
                insurance_code_id: false,
                group_number: false,
                policy_number: false,
                name: {
                    first_name: false,
                    last_name: false,
                },
                address: {
                    address1: false,
                    city: false,
                    province: false,
                    zip: false,
                    postal_code: false,
                    state_id: false,
                    country_id: false,
                },
                relationship: false,
                employee_name: false,
                gender: false,
                date_of_birth: false,
                formatted_date_of_birth: false,
                phone: false,
                employee_phone: false,
                ssn: false,
                phone_cust: false,
                employee_address: {
                    address1: false,
                    city: false,
                    province: false,
                    zip: false,
                    postal_code: false,
                    state_id: false,
                    country_id: false,
                },
            },
            modified_data: {
                subscriber: {
                    insurance_code_id: false,
                    group_number: false,
                    policy_number: false,
                    name: {
                        first_name: false,
                        last_name: false,
                    },
                    address: {
                        address1: false,
                        city: false,
                        province: false,
                        zip: false,
                        postal_code: false,
                        state_id: false,
                        country_id: false,
                    },
                    relationship: false,
                    gender: false,
                    date_of_birth: false,
                }
            },
            add_primary_insurance_modal: false,
            advanced_modal_state: false,
            features: {
                is_payment_posting_feedback_enabled: this.props.features.is_payment_posting_feedback_enabled,
            },
        }

        this.insurance_doctors_orders = React.createRef();
        this.insurance_eligibility = React.createRef();
        this.insurance_preauthorization = React.createRef();

    }

    is_subscriber_available = () => {
        return (is_valid_value(this.state.subscriber.insurance_code_id) || is_valid_value(this.state.subscriber.group_number) || is_valid_value(this.state.subscriber.policy_number)
            || is_valid_value(this.state.subscriber.name.first_name) || is_valid_value(this.state.subscriber.name.last_name) || is_valid_value(this.state.subscriber.name.suffix) || is_valid_value(this.state.subscriber.name.middle_initial)
            || is_valid_value(this.state.subscriber.address.address1) || is_valid_value(this.state.subscriber.address.address2) || is_valid_value(this.state.subscriber.address.city) || is_valid_value(this.state.subscriber.address.state_id) || is_valid_value(this.state.subscriber.address.zip) || is_valid_value(this.state.subscriber.address.province) || is_valid_value(this.state.subscriber.address.postal_code)
            || is_valid_value(this.state.subscriber.phone) || is_valid_value(this.state.subscriber.ssn) || is_valid_value(this.state.subscriber.formatted_date_of_birth != null) || is_valid_value(this.state.subscriber.relationship) || is_valid_value(this.state.subscriber.gender)
            || is_valid_value(this.state.subscriber.employee_name) || is_valid_value(this.state.subscriber.employee_address.address1) || is_valid_value(this.state.subscriber.employee_address.address2) || is_valid_value(this.state.subscriber.employee_address.city) || is_valid_value(this.state.subscriber.employee_address.state_id) || is_valid_value(this.state.subscriber.employee_address.zip)
            || is_valid_value(this.state.subscriber.employee_phone)
        );
    }
    get_insurance_data = (ins_type, patient_id, case_id, is_call_from_outside = false) => {
        if (is_call_from_outside) {
            return this.get_sub_info_promiss(ins_type, patient_id, case_id, is_call_from_outside)
        } else {
            if (!(patient_id == '' || isNaN(Number(case_id)) || Number(case_id) >= global_constants.maximum_value.integer)) {
                var insrurance_request = {
                    patient_id: patient_id,
                    case_id: case_id,
                    pst_coverage: ins_type === enum_type_of_insurance.primary_insurance ? 'P' : ins_type === enum_type_of_insurance.secondary_insurance ? 'S' : 'T'
                };
                get_subscriber(insrurance_request, this.props.user_login_details.user_data.data.token_details.access_token).then(response => {
                    if (response.data.data != null) {
                        var auto_fill_input = (response.data.data.insurance.insurance_code == null ? '' :
                            response.data.data.insurance.insurance_code) + ' - ' +
                            (response.data.data.insurance.name == null ? '' : response.data.data.insurance.name) +
                            ', ' + (response.data.data.insurance.address != null ?
                                ((response.data.data.insurance.address.address1 == null ? '' :
                                    response.data.data.insurance.address.address1) + ', ' +
                                    (response.data.data.insurance.address.city == null ? '' :
                                        response.data.data.insurance.address.city) + ', ' +
                                    (response.data.data.insurance.address.state_id == null ? '' :
                                        response.data.data.insurance.address.state_id) + ', ' +
                                    (response.data.data.insurance.address.zip == null ? '' :
                                        response.data.data.insurance.address.zip)) : '');
                        // Set date object for assignment Date
                        if (!(response.data.data.date_of_birth == '' || response.data.data.date_of_birth == null || response.data.data.date_of_birth == undefined || response.data.data.date_of_birth == configurable_date.default_date)) {
                            response.data.data.formatted_date_of_birth = moment(response.data.data.date_of_birth);
                            response.data.data.old_date_of_birth = moment(response.data.data.date_of_birth);
                        }
                        //insurance available if insurance code not empty or null
                        if (response.data.data.insurance.id) {
                            response.data.data.is_insurance_available = true;
                        }

                        //if required for billing field comes '' convert it into null              
                        response.data.data.policy_number = response.data.data.policy_number == '' ? null : response.data.data.policy_number;
                        response.data.data.name.first_name = response.data.data.name.first_name == '' ? null : response.data.data.name.first_name;
                        response.data.data.name.last_name = response.data.data.name.last_name == '' ? null : response.data.data.name.last_name;
                        response.data.data.address.address1 = response.data.data.address.address1 == '' ? null : response.data.data.address.address1;
                        response.data.data.address.city = response.data.data.address.city == '' ? null : response.data.data.address.city;
                        response.data.data.address.state_id = response.data.data.address.state_id == '' ? null : response.data.data.address.state_id;
                        response.data.data.address.zip = response.data.data.address.zip == '' ? null : zip_code_formation(response.data.data.address.zip);
                        response.data.data.address.unformat_zip = response.data.data.address.zip == '' ? null : response.data.data.address.zip;
                        response.data.data.relationship = response.data.data.relationship == '' ? null : response.data.data.relationship;
                        response.data.data.group_number = response.data.data.group_number == '' ? null : response.data.data.group_number;
                        response.data.data.address.province = response.data.data.address.province ? response.data.data.address.province.toString().trim() : response.data.data.address.province;
                        response.data.data.address.postal_code = response.data.data.address.postal_code ? response.data.data.address.postal_code.toString().trim() : response.data.data.address.postal_code;

                        response.data.data.employee_address.zip = response.data.data.employee_address.zip == '' ? null : zip_code_formation(response.data.data.employee_address.zip);
                        response.data.data.employee_address.unformat_zip = response.data.data.employee_address.zip == '' ? null : response.data.data.employee_address.zip;
                        //format zip_code
                        //response.data.data.address.zip = format_zip_code(response.data.data.address.zip);
                        //response.data.data.employee_address.zip = format_zip_code(response.data.data.employee_address.zip);
                        //To store old values
                        response.data.data.old_insurance = response.data.data.insurance.insurance_code;
                        response.data.data.old_policy_number = response.data.data.policy_number;
                        response.data.data.old_first_name = response.data.data.name.first_name;
                        response.data.data.old_last_name = response.data.data.name.last_name;
                        response.data.data.old_address1 = response.data.data.address.address1;
                        response.data.data.old_city = response.data.data.address.city;
                        response.data.data.old_state_id = response.data.data.address.state_id;
                        response.data.data.old_zip = response.data.data.address.zip;
                        response.data.data.old_relationship = response.data.data.relationship;
                        response.data.data.old_group_number = response.data.data.group_number;

                        // payer description formatting
                        let payer_formatted_description = is_null_or_white_space(response.data.data.insurance.payer_code) || is_null_or_white_space(response.data.data.insurance.payer_description)
                            ? ''
                            : `${response.data.data.insurance.payer_code} - ${response.data.data.insurance.payer_description}`;
                        response.data.data.insurance.payer_formatted_description = payer_formatted_description;

                        //to show or hide the group no , ssn , policy number and phone
                        var control_flags = this.get_control_flag_on_bind_insurance(response.data.data, ins_type);

                        control_flags.is_insurance_show = true;
                        control_flags.is_insurance_active = response.data.data.is_active;
                        //////
                        this.props.get_show_status_insurance(control_flags);

                        //To show province and postal code based on coutnry selection
                        if (response.data.data.address.country_id) {
                            if (response.data.data.address.country_id > 1) {
                                response.data.data.is_us_selected = false;
                            }
                            else {
                                response.data.data.is_us_selected = true;
                            }
                        }
                        else {
                            response.data.data.address.country_id = 1;
                            response.data.data.is_us_selected = true;
                        }

                        //
                        if (response.data.data.insurance && response.data.data.insurance.id > 0) {
                            var currentdatetime = currentDateTime();
                            if (this._is_mount) {
                                this.setState({
                                    loading: false,
                                    control_flags: control_flags,
                                    insurance_quick_search_format: { "label": auto_fill_input, "title": response.data.data.insurance.id.toString() },
                                    subscriber: response.data.data,
                                    doctors_fetch_time: currentdatetime,
                                    subscriber_fetched_time: currentdatetime
                                })
                            }
                        }
                        else {

                            var currentdatetime = currentDateTime();
                            if (this._is_mount) {
                                this.setState({
                                    loading: false,
                                    control_flags: control_flags,
                                    subscriber: response.data.data,
                                    subscriber_fetched_time: currentdatetime,
                                });
                            }
                        }

                    }
                    else {
                        var control_flags = this.state.control_flags;
                        control_flags.is_insurance_show = false;
                        //this.setState({
                        //    control_flags: control_flags,
                        //})
                        this.props.get_show_status_insurance(control_flags);
                    }

                }, function (error) { })
            }
        }

    }

    get_sub_info_promiss = (ins_type, patient_id, case_id, is_call_from_outside) => {
        var insrurance_request = {
            patient_id: patient_id,
            case_id: case_id,
            pst_coverage: ins_type === enum_type_of_insurance.primary_insurance ? 'P' : ins_type === enum_type_of_insurance.secondary_insurance ? 'S' : 'T'
        };
        return get_subscriber(insrurance_request, this.props.user_login_details.user_data.data.token_details.access_token).then(response => {
            if (response.data.data != null) {
                var auto_fill_input = (response.data.data.insurance.insurance_code == null ? '' :
                    response.data.data.insurance.insurance_code) + ' - ' +
                    (response.data.data.insurance.name == null ? '' : response.data.data.insurance.name) +
                    ', ' + (response.data.data.insurance.address != null ?
                        ((response.data.data.insurance.address.address1 == null ? '' :
                            response.data.data.insurance.address.address1) + ', ' +
                            (response.data.data.insurance.address.city == null ? '' :
                                response.data.data.insurance.address.city) + ', ' +
                            (response.data.data.insurance.address.state_id == null ? '' :
                                response.data.data.insurance.address.state_id) + ', ' +
                            (response.data.data.insurance.address.zip == null ? '' :
                                response.data.data.insurance.address.zip)) : '');
                // Set date object for assignment Date
                if (!(response.data.data.date_of_birth == '' || response.data.data.date_of_birth == null || response.data.data.date_of_birth == undefined || response.data.data.date_of_birth == configurable_date.default_date)) {
                    response.data.data.formatted_date_of_birth = moment(response.data.data.date_of_birth);
                    response.data.data.old_date_of_birth = moment(response.data.data.date_of_birth);
                }
                //insurance available if insurance code not empty or null
                if (response.data.data.insurance.id) {
                    response.data.data.is_insurance_available = true;
                }

                //if required for billing field comes '' convert it into null              
                response.data.data.policy_number = response.data.data.policy_number == '' ? null : response.data.data.policy_number;
                response.data.data.name.first_name = response.data.data.name.first_name == '' ? null : response.data.data.name.first_name;
                response.data.data.name.last_name = response.data.data.name.last_name == '' ? null : response.data.data.name.last_name;
                response.data.data.address.address1 = response.data.data.address.address1 == '' ? null : response.data.data.address.address1;
                response.data.data.address.city = response.data.data.address.city == '' ? null : response.data.data.address.city;
                response.data.data.address.state_id = response.data.data.address.state_id == '' ? null : response.data.data.address.state_id;
                response.data.data.address.zip = response.data.data.address.zip == '' ? null : zip_code_formation(response.data.data.address.zip);
                response.data.data.address.unformat_zip = response.data.data.address.zip == '' ? null : response.data.data.address.zip;
                response.data.data.relationship = response.data.data.relationship == '' ? null : response.data.data.relationship;
                response.data.data.group_number = response.data.data.group_number == '' ? null : response.data.data.group_number;
                response.data.data.address.province = response.data.data.address.province ? response.data.data.address.province.toString().trim() : response.data.data.address.province;
                response.data.data.address.postal_code = response.data.data.address.postal_code ? response.data.data.address.postal_code.toString().trim() : response.data.data.address.postal_code;

                response.data.data.employee_address.zip = response.data.data.employee_address.zip == '' ? null : zip_code_formation(response.data.data.employee_address.zip);
                response.data.data.employee_address.unformat_zip = response.data.data.employee_address.zip == '' ? null : response.data.data.employee_address.zip;
                //format zip_code
                //response.data.data.address.zip = format_zip_code(response.data.data.address.zip);
                //response.data.data.employee_address.zip = format_zip_code(response.data.data.employee_address.zip);
                //To store old values
                response.data.data.old_insurance = response.data.data.insurance.insurance_code;
                response.data.data.old_policy_number = response.data.data.policy_number;
                response.data.data.old_first_name = response.data.data.name.first_name;
                response.data.data.old_last_name = response.data.data.name.last_name;
                response.data.data.old_address1 = response.data.data.address.address1;
                response.data.data.old_city = response.data.data.address.city;
                response.data.data.old_state_id = response.data.data.address.state_id;
                response.data.data.old_zip = response.data.data.address.zip;
                response.data.data.old_relationship = response.data.data.relationship;
                response.data.data.old_group_number = response.data.data.group_number;

                // payer description formatting
                let payer_formatted_description = is_null_or_white_space(response.data.data.insurance.payer_code) || is_null_or_white_space(response.data.data.insurance.payer_description)
                    ? ''
                    : `${response.data.data.insurance.payer_code} - ${response.data.data.insurance.payer_description}`;
                response.data.data.insurance.payer_formatted_description = payer_formatted_description;

                //to show or hide the group no , ssn , policy number and phone
                var control_flags = this.get_control_flag_on_bind_insurance(response.data.data, ins_type);
                control_flags.is_insurance_show = true;
                control_flags.is_insurance_active = response.data.data.is_active;
                //////
                this.props.get_show_status_insurance(control_flags);

                //To show province and postal code based on coutnry selection
                if (response.data.data.address.country_id) {
                    if (response.data.data.address.country_id > 1) {
                        response.data.data.is_us_selected = false;
                    }
                    else {
                        response.data.data.is_us_selected = true;
                    }
                }
                else {
                    response.data.data.address.country_id = 1;
                    response.data.data.is_us_selected = true;
                }

                //
                if (response.data.data.insurance && response.data.data.insurance.id > 0) {
                    var currentdatetime = currentDateTime();
                    if (this._is_mount) {
                        this.setState({
                            loading: false,
                            control_flags: control_flags,
                            insurance_quick_search_format: { "label": auto_fill_input, "title": response.data.data.insurance.id.toString() },
                            subscriber: response.data.data,
                            doctors_fetch_time: currentdatetime,
                            subscriber_fetched_time: currentdatetime
                        });
                    }

                }
                else {
                    var currentdatetime = currentDateTime();
                    if (this._is_mount) {
                        this.setState({
                            loading: false,
                            control_flags: control_flags,
                            subscriber: response.data.data,
                            subscriber_fetched_time: currentdatetime,
                        });
                    }
                }

            }
            else {
                var control_flags = this.state.control_flags;
                control_flags.is_insurance_show = false;
                //this.setState({
                //    control_flags: control_flags,
                //})
                this.props.get_show_status_insurance(control_flags);
            }

        }, function (error) { })
    }

    total_column_width: number = 0;

    componentDidMount() {
        this._is_mount = true;
        if (this.state.case_id > 0) {
            this.get_insurance_data(this.props.ins_type, this.props.patient_id, this.props.case_id);
        }
        this.get_patient_details(this.state.subscriber.patient_id);
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }
    // Get patient patient data for EDIT
    get_patient_details = async patient_id => {
        await this.props.get_patient_details(this.props.user_login_details.user_data.data.token_details.access_token, patient_id);
    };
    UNSAFE_componentWillMount() {

        if (this.props.onRef) {
            this.props.onRef(this);
        }

    }

    componentWillUnmount = () => {
        this._is_mount = false;
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
        }
    }
    componentDidUpdate(previousProps, previousState) {
        if (this._is_mount) {
            if (previousProps.shown != this.props.shown) {
                this.setState({ shown: false }); // show on change props
            }
            if (previousProps.page_state !== this.props.page_state) {
                this.setState({ page_state: this.props.page_state }); // show on change props
            }
            if (previousProps.form_submitted != this.props.form_submitted) {
                this.setState({ form_submited: this.props.form_submitted }); // show on change props
            }
        }
    }

    // toggle to show and hide Cases Component
    toggle = () => {
        if (this._is_mount) {
            this.setState({
                shown: !this.state.shown
            });
        }
    }
    toggle_by_parent_component = (flag) => {
        if (this._is_mount) {
            this.setState({
                shown: flag
            });
        }
    }
    on_blur_auto_search = (e) => {
        var subscriber_state = this.state.subscriber;
        var subscriber_invalid = this.state.subscriber_form_invalid;
        var search_control_id = 'insurance_search_auto' + this.state.subscriber.pst_coverage ? this.state.subscriber.pst_coverage : '';
        if (document.getElementById(search_control_id)) {
            var val = document.getElementById(search_control_id)["value"];
            if (val == '') {
                subscriber_state.is_insurance_available = false;
            }
        }

        //if (subscriber_state.old_insurance && subscriber_state.old_insurance != "-999" && e.currentTarget.innerText == "") {
        //    subscriber_invalid.insurance_code_id = true;
        //}
        //else {
        //    subscriber_invalid.insurance_code_id = false;
        //}
        if (this._is_mount) {
            this.setState({
                subscriber: subscriber_state,
                subscriber_form_invalid: subscriber_invalid
            });
        }
    }
    on_insurance_selection = (selected_row) => {
        var id = ''; var selected_data = ''; var code = ''; var name = ''; var address = ''; var city = ''; var state = ''; var zip = '';
        var subscriber_state = this.state.subscriber;
        var subscriber_invalid = this.state.subscriber_form_invalid;
        var subscriber_modified = this.state.modified_data;
        let payer_description = !is_null_or_white_space(selected_row.payer_description) ? selected_row.payer_description : '',
            payer_code = !is_null_or_white_space(selected_row.payer_code) ? selected_row.payer_code : '';
        if (selected_row) {
            id = selected_row.id;
            if ((selected_row.insurance_code != null && selected_row.insurance_code != undefined) || (selected_row.insurance_name != null && selected_row.insurance_name != undefined)) {
                code = (selected_row.insurance_code == null && selected_row.insurance_code == undefined) ? '' : selected_row.insurance_code;
                name = (selected_row.insurance_name == null && selected_row.insurance_name == undefined) ? '' : selected_row.insurance_name;
                address = (selected_row.address1 == null && selected_row.address1 == undefined) ? '' : selected_row.address1;
                city = (selected_row.city == null && selected_row.city == undefined) ? '' : selected_row.city;
                state = (selected_row.state == null && selected_row.state == undefined) ? '' : selected_row.state;
                zip = (selected_row.zip == null && selected_row.zip == undefined) ? '' : selected_row.zip;
            }
        }
        selected_data = (code == null ? '' : code) + ' - ' + (name == null ? '' : name) + ', ' + (address != null ? ((address == null ? '' : address) +
            ', ' + (city == null ? '' : city) + ', ' + (state == null ? '' : state) + ', ' + (zip == null ? '' : zip)) : '');

        var format_ins = { "label": '', "title": '', "insurance_code": '' };
        if (parseInt(id) > 0) {
            // Set Auto search control for location
            format_ins = {
                "title": id.toString(),
                "label": selected_data,
                "insurance_code": code
            };
        }
        subscriber_state.insurance_code_id = subscriber_state.insurance.id = id;
        if (subscriber_state.insurance_code_id != '') {
            subscriber_state.insurance_code_id = subscriber_state.insurance_code_id.toString();
        }

        let payer_formatted_description = is_null_or_white_space(payer_code) || is_null_or_white_space(payer_description)
            ? ''
            : `${payer_code} - ${payer_description}`;
        subscriber_state.insurance.payer_formatted_description = payer_formatted_description;

        subscriber_invalid.insurance_code_id = false;
        subscriber_state.is_insurance_available = true;
        //Same as patient
        var control_flag = this.insurance_validation(selected_row);
        if (control_flag.is_same_as_patient) {
            subscriber_state.same_as_patient = true;
            subscriber_state = this.same_as_patient_data_update(control_flag, subscriber_state);
        }
        if (this._is_mount) {
            this.setState({
                control_flags: control_flag,
                insurance_quick_search_format: format_ins,
                subscriber: subscriber_state,
                subscriber_form_invalid: subscriber_invalid,
                modified_data: subscriber_modified
            });
        }
        //var control_flag = this.insurance_validation(selected_row);
        this.props.get_show_status_insurance(control_flag);

    }
    on_insurance_search = async (params) => {
        // var parm = { "insurance_name": "", "address": "", "city": "", "insurance_code": "", "records": 100 };
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        params = params ? params : { "insurance_name": "", "address": "", "city": "", "insurance_code": "" };
        params.records = global_constants.constants.PAGE_SIZE;
        var grid_data = [];
        var search_data = null;
        if (this.props.ins_type == enum_type_of_insurance.primary_insurance) {
            search_data = await insurance_advance_search(params, token).then(res => res.data);
        }
        else {
            search_data = await insurance_advance_search_sec_tertiary(params, token).then(res => res.data);
        }
        // const search_data = await this.props.ins_type == enum_type_of_insurance.primary_insurance ? insurance_advance_search(params, token).then(res => res.data) : insurance_advance_search_sec_tertiary(params, token).then(res => res.data);
        for (var data = 0; data < search_data.data.length; data++) {
            search_data.data[data].phone_customer = phone_format(search_data.data[data].phone_customer);
        }
        const search_result = search_data.data !== null ? search_data.data : [];
        this.total_column_width = set_column_data_width(search_result.slice(0, this.state.grid_conf.paginationPageSize), shared_constants.search_insurance_configuration.column_defs);
        grid_data = {
            ...this.state.grid_conf, rows: search_result,
            column: shared_constants.search_insurance_configuration.column_defs,
            messages: search_result.messages
        };
        if (this._is_mount) {
            this.setState({ insurance_search_data: grid_data });
        }
    }
    get_input_value = (key, type_of_search) => {

    }
    render_suggestion_result = (props) => {
        return (
            <div key={props.title} tabIndex={0} className="item_auto_search">
                <div key={props.title} className="fs_13">
                    <span>{props.label}</span>
                </div>
            </div>

        )
    }
    prepare_suggestion = (data) => {
        var formattedList = [];
        var selected_data = '';
        var data_length = data.data.length;
        if (data && data_length) {
            for (let i = 0; i < data_length; i++) {
                let item = data.data[i];
                selected_data = (item.insurance_code == null ? '' : item.insurance_code) + ' - ' + (item.insurance_name == null ? '' : item.insurance_name) + ', '
                    + (item.address1 == null ? '' : item.address1) +
                    ', ' + (item.city == null ? '' : item.city) + ', ' + (item.state == null ? '' : item.state) + ', ' + (item.zip == null ? '' : item.zip);
                formattedList.push({
                    "title": `${item.id}`,
                    "name": item.id,
                    "label": selected_data,
                    "insurance_code": item.insurance_code,
                    "insurance_class_code": item.insurance_class_code,
                    "balance_type": item.balance_type,
                    "insurance_type": item.insurance_type,
                    "insurance_class": item.insurance_class,
                    "payer_description": item.payer_description,
                    "payer_code": item.payer_code,
                })
            }
        }
        return formattedList;
    }
    get_insurance_quick_search_data_list = async (search_keyword) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        if (this.props.ins_type == enum_type_of_insurance.primary_insurance) {
            return await insurance_quick_search(search_keyword, token);
        }
        else {
            return await insurance_quick_search_sec_tertiary(search_keyword, token);

        }
    }
    on_item_selection = (item) => {
        var subscriber_state = this.state.subscriber;
        var subscriber_invalid = this.state.subscriber_form_invalid;
        var subscriber_modified = this.state.modified_data;
        subscriber_invalid.insurance_code_id = false;
        let payer_description = !is_null_or_white_space(item.payer_description) ? item.payer_description : '',
            payer_code = !is_null_or_white_space(item.payer_code) ? item.payer_code : '';
        if ((subscriber_state.old_insurance ? subscriber_state.old_insurance : null) !== item.title) {
            subscriber_modified.subscriber.insurance_code_id = true;
        }
        else {
            subscriber_modified.subscriber.insurance_code_id = false;
        }
        //
        subscriber_state.insurance_code_id = subscriber_state.insurance.id = item.title;

        let payer_formatted_description = is_null_or_white_space(payer_code) || is_null_or_white_space(payer_description)
            ? ''
            : `${payer_code} - ${payer_description}`;
        subscriber_state.insurance.payer_formatted_description = payer_formatted_description;

        subscriber_state.is_insurance_available = true;
        var control_flag = this.insurance_validation(item);
        if (control_flag.is_same_as_patient) {
            subscriber_state.same_as_patient = true;
            subscriber_state = this.same_as_patient_data_update(control_flag, subscriber_state);
        }
        if (this._is_mount) {
            this.setState({
                control_flags: control_flag,
                //selected_ins: item,
                subscriber: subscriber_state,
                subscriber_form_invalid: subscriber_invalid,
                modified_data: subscriber_modified
            });
        }       
        this.props.get_show_status_insurance(control_flag);
    }
    clear_quick_search = () => {
        var subscriber_data = this.state.subscriber;
        var subscriber_invalid = this.state.subscriber_form_invalid;

        if (subscriber_data.old_insurance && (!(subscriber_data.old_insurance == "-999" || subscriber_data.old_insurance == "0"))) {
            subscriber_invalid.insurance_code_id = true;
        }
        subscriber_data.insurance.id = "-999";
        subscriber_data.insurance.payer_formatted_description = "";
        subscriber_data.is_insurance_available = false;
        // subscriber_data.insurance_code_id = "";
        if (this._is_mount) {
            this.setState({
                insurance_quick_search_format: {
                    "title": '',
                    "label": '',

                },
                subscriber: subscriber_data,
                subscriber_form_invalid: subscriber_invalid
            });
        }

    }

    on_subscriber_form_change = (e, _obj = null) => {
        var field_name = e.target.name != undefined ? e.target.name.replace("txt_", "") : e.target.name;
        var field_value = e.target.value != undefined ? e.target.value.trimStart() : e.target.value;
        var state_subscriber = this.state.subscriber;
        var subscriber_modified_data = this.state.modified_data;
        var state_invalid_subscriber = this.state.subscriber_form_invalid;
        if (field_name == 'ssn' || field_name == 'phone' || field_name == 'employee_phone') {
            field_value = unmask(field_value);
        }
        var is_modified = false;
        if (_obj == null) {
            state_subscriber[field_name] = field_value;
            //
            //To check whether the state for subscriber required for billing controls is modified of not
            if (field_name == 'group_number') {
                if (state_subscriber.case_id <= 0) { is_modified = false; } else {
                    is_modified = (state_subscriber['old_group_number'] == null ? '' : state_subscriber['old_group_number']) !== field_value;
                }
                subscriber_modified_data.subscriber.group_number = is_modified;
                state_invalid_subscriber.group_number = is_modified ? state_subscriber.group_number == '' : false;

            }
            if (field_name == 'policy_number') {
                if (field_value) {
                    field_value = field_value.toString().toUpperCase();
                }
                if (state_subscriber.case_id <= 0) { is_modified = false; } else {
                    is_modified = (state_subscriber['old_policy_number'] == null ? '' : state_subscriber['old_policy_number']) !== field_value;
                }
                subscriber_modified_data.subscriber.policy_number = is_modified;
                state_invalid_subscriber.policy_number = is_modified ? state_subscriber.policy_number == '' : false;
            }
            state_subscriber[field_name] = field_value;
            //
        }
        else {

            if (_obj.is_obj_on_another_hirerchy) {
                if (_obj.is_dropdown) {
                    state_subscriber[_obj.obj_hierarch_name][_obj.name] = _obj.value;
                    if (_obj.name == "country_id" && _obj.value != 1) {
                        state_subscriber.is_us_selected = false;
                        state_subscriber.address.zip = '';
                        state_subscriber.address.state_id = '  ';
                    }
                    if (_obj.name == "country_id" && _obj.value == 1) {
                        state_subscriber.is_us_selected = true;
                        state_subscriber.address.postal_code = '';
                        state_subscriber.address.province = '';
                    }
                }
                else {


                    //zip code and postal code validation on change
                    if (field_name == 'zip' || field_name == 'employee_zip') {
                        if (_obj) {
                            field_value = unformat_zip_code(field_value);
                            field_value = is_all_numbers(field_value) ? (Number(field_value) <= 2147483647 ? field_value : state_subscriber[_obj.obj_hierarch_name]['unformat_' + _obj.name]) : state_subscriber[_obj.obj_hierarch_name]['unformat_' + _obj.name];
                            //field_value = (e.target.validity.valid) ? (Number(e.target.value) <= 2147483647 ? e.target.value : state_subscriber[_obj.obj_hierarch_name][_obj.name]) : state_subscriber[_obj.obj_hierarch_name][_obj.name];
                            if (field_value.length > 5 && field_value.length <= 9) {
                                field_value = zip_code_formation(field_value);
                            }
                        }
                        state_subscriber[_obj.obj_hierarch_name][_obj.name] = field_value;
                        state_subscriber[_obj.obj_hierarch_name]['unformat_' + _obj.name] = unformat_zip_code(field_value);
                    }
                    if (field_name == 'province' || field_name == 'postal_code') {
                        let valid = check_allowed_special_chars(field_value);
                        if (_obj) {
                            field_value = valid ? state_subscriber[_obj.obj_hierarch_name][_obj.name] : e.target.value;
                        }
                        state_subscriber[_obj.obj_hierarch_name][_obj.name] = field_value;
                    }
                    // state_subscriber[_obj.obj_hierarch_name][_obj.name] = field_value;
                    //To check whether the state for subscriber required for billing controls is modified of not
                    if (_obj.obj_hierarch_name == 'name' && _obj.name == 'first_name') {
                        if (state_subscriber.case_id <= 0) { is_modified = false; } else {
                            is_modified = (state_subscriber['old_first_name'] == null ? '' : state_subscriber['old_first_name']) !== field_value;
                        }
                        subscriber_modified_data.subscriber.name.first_name = is_modified;
                        state_invalid_subscriber.name.first_name = is_modified ? field_value == '' : false;
                        //state_invalid_subscriber.name.first_name = is_modified ? state_subscriber.name.first_name == '' : false;
                        state_subscriber[_obj.obj_hierarch_name][_obj.name] = field_value;

                    }
                    if (_obj.obj_hierarch_name == 'name' && _obj.name == 'last_name') {
                        if (state_subscriber.case_id <= 0) { is_modified = false; } else {
                            is_modified = (state_subscriber['old_last_name'] == null ? '' : state_subscriber['old_last_name']) !== field_value;
                        }
                        subscriber_modified_data.subscriber.name.last_name = is_modified;
                        state_invalid_subscriber.name.last_name = is_modified ? field_value == '' : false;
                        //state_invalid_subscriber.name.last_name = is_modified ? state_subscriber.name.last_name == '' : false;
                        state_subscriber[_obj.obj_hierarch_name][_obj.name] = field_value;
                    }
                    if (_obj.obj_hierarch_name == 'address' && _obj.name == 'address1') {
                        if (state_subscriber.case_id <= 0) { is_modified = false; } else {
                            is_modified = (state_subscriber['old_address1'] == null ? '' : state_subscriber['old_address1']) !== field_value;
                        }
                        subscriber_modified_data.subscriber.address.address1 = is_modified;
                        state_invalid_subscriber.address.address1 = is_modified ? field_value == '' : false;
                        //state_invalid_subscriber.address.address1 = is_modified ? state_subscriber.address.address1 == '' : false;
                        state_subscriber[_obj.obj_hierarch_name][_obj.name] = field_value;
                    }
                    if (_obj.obj_hierarch_name == 'address' && _obj.name == 'city') {
                        if (state_subscriber.case_id <= 0) { is_modified = false; } else {
                            is_modified = (state_subscriber['old_city'] == null ? '' : state_subscriber['old_city']) !== field_value;
                        }
                        subscriber_modified_data.subscriber.address.city = is_modified;
                        state_invalid_subscriber.address.city = is_modified ? field_value == '' : false;
                        // state_invalid_subscriber.address.city = is_modified ? state_subscriber.address.city == '' : false;
                        state_subscriber[_obj.obj_hierarch_name][_obj.name] = field_value;
                    }
                    if (_obj.obj_hierarch_name == 'address' && _obj.name == 'zip') {
                        if (state_subscriber.case_id <= 0) { is_modified = false; } else {
                            is_modified = (state_subscriber['old_zip'] == null ? '' : state_subscriber['old_zip']) !== unformat_zip_code(field_value);
                        }
                        state_invalid_subscriber.address.zip = is_modified ? state_subscriber.address.zip == '' : false;
                        subscriber_modified_data.subscriber.address.zip = is_modified;
                        state_subscriber[_obj.obj_hierarch_name][_obj.name] = field_value;
                    }
                    if ((_obj.obj_hierarch_name == 'address' && _obj.name == 'address2') || (_obj.obj_hierarch_name == 'name' && _obj.name == 'middle_initial') || (_obj.obj_hierarch_name == 'name' && _obj.name == 'suffix') || (_obj.obj_hierarch_name == 'employee_address' && _obj.name == 'address1') || (_obj.obj_hierarch_name == 'employee_address' && _obj.name == 'address2') || (_obj.obj_hierarch_name == 'employee_address' && _obj.name == 'city')) {
                        state_subscriber[_obj.obj_hierarch_name][_obj.name] = field_value;
                    }
                    //

                }
            }
            else {
                if (_obj.is_dropdown) {
                    //To check whether the state for case name is modified of not
                    if (_obj.name == 'relationship') {
                        state_invalid_subscriber.relationship = false;
                        if ((state_subscriber.old_relationship ? state_subscriber.old_relationship : "0") !== _obj.value) {
                            if (_obj.value && _obj.value.toString() == "0") {
                                state_invalid_subscriber.relationship = true;
                            }
                            else {
                                state_invalid_subscriber.relationship = false;
                            }
                            subscriber_modified_data.subscriber.relationship = true;
                        }
                        else {
                            subscriber_modified_data.subscriber.relationship = false;
                        }
                    }
                    //
                    state_subscriber[_obj.name] = _obj.value;
                }
            }


        }

        var is_form_invalid_insurance = this.validate_form_field_data();
        var is_same_as_Patient = this.check_same_patient_details();
        state_subscriber.same_as_patient = is_same_as_Patient;
        state_subscriber.address.state_id = state_subscriber.address.state_id == '' ? '  ' : state_subscriber.address.state_id;
        if (this._is_mount) {
            this.setState({
                subscriber: state_subscriber,
                subscriber_form_invalid: is_form_invalid_insurance.insurance_data,
                modified_data: subscriber_modified_data
            });
        }
    }
    get_control_flag_on_bind_insurance = (data, ins_type) => {
        var show_controls_flag = {
            is_show_msp_type: false,
            is_show_claim_number_ssn: false,
            is_show_ssn_claim_number: false,
            is_worker_comp: false,
            is_req_validation_policy_no_rr: false,
            is_req_validation_policy_no_mc: false,
            is_req_validation_policy_no_ch: false,
            is_insurance_show: false,
            is_insurance_active: false,
            is_same_as_patient: false

        }

        if (data.insurance.insurance_type == 'R') {
            //show_controls_flag.is_same_as_patient = true;
            if (ins_type != enum_type_of_insurance.primary_insurance) {
                show_controls_flag.is_show_msp_type = true;
            }
            // vmCase.tertiaryInsurance.mspType = parseInt(vmCase.tertiaryInsurance.mspType); //No Need to parse the value SQA-2054
            //show_controls_flag.is_req_validation_policy_no_mc = true;
            
        }
        if (data.insurance.balance_type == 'W') {
            show_controls_flag.is_worker_comp = true;
            show_controls_flag.is_show_claim_number_ssn = true;
            show_controls_flag.is_same_as_patient = true;
        }
        if (data.insurance.insurance_class.insurance_class_code == 'MC') {
            show_controls_flag.is_req_validation_policy_no_mc = true;
        }
        if (data.insurance.insurance_class.insurance_class_code == 'RR') {
            show_controls_flag.is_req_validation_policy_no_rr = true;
            //show_controls_flag.is_req_validation_policy_no_mc = false;
        }
        if (data.insurance.insurance_class.insurance_class_code == 'AU') {
            show_controls_flag.is_show_ssn_claim_number = true;
        }
        if (data.insurance.insurance_class.insurance_class_code == 'CH') {
            show_controls_flag.is_req_validation_policy_no_ch = true;
        }
        if (data.insurance.insurance_class.insurance_class_code == 'IN') {
            show_controls_flag.is_show_claim_number_ssn = true;
        }
        return show_controls_flag;
    }
    insurance_validation = (item) => {
        if (item) {
            let data = {
                insurance: {
                    "insurance_code": item.insurance_code,
                    "insurance_class_code": item.insurance_class_code,
                    "balance_type": item.balance_type,
                    "insurance_type": item.insurance_type,
                    "insurance_class": { "insurance_class_code": item.insurance_class_code },

                }
            }
            let insurance_data = { data: data };
            return this.get_control_flag_on_bind_insurance(data, this.props.ins_type);
        }
    }
    same_as_patient_data_update = (control_flag, state_subscriber) => {
        var is_checked = control_flag.is_same_as_patient;
        var is_worker_comp = control_flag.is_worker_comp;
        var _patient = Object.assign({}, this.props.patient_details.selected_patient.data);
        // var _patient = Object.assign({}, this.props.persisted_details.selected_patient.data);
        var _is_us_selected = _patient.address.country_id == 1;
        var relation_ship = "1";
        if (is_worker_comp) {
            relation_ship = "4";
        }
        if (state_subscriber) {
            state_subscriber.same_as_patient = is_checked;
            if (is_checked) {
                var updated_subscriber_data = {

                    ...state_subscriber,
                    is_us_selected: _is_us_selected,
                    name: {
                        middle_initial: _patient.name.middle_initial,
                        suffix: _patient.name.suffix,
                        first_name: _patient.name.first_name, // == '' ? null : _patient.name.first_name,
                        last_name: _patient.name.last_name, // == '' ? null : _patient.name.last_name,

                        is_name_requried: false
                    },
                    address: {
                        address1: _patient.address.address1,// == '' ? null : _patient.address.address1,
                        address2: _patient.address.address2,
                        city: _patient.address.city,// == '' ? null : _patient.address.city,
                        province: _is_us_selected ? '' : _patient.address.province,
                        //zip: _patient.address.zip,//== '' ? null : _patient.address.zip,
                        zip: zip_code_formation(_patient.address.zip),//== '' ? null : _patient.address.zip,
                        unformat_zip: _patient.address.zip,
                        postal_code: _is_us_selected ? '' : _patient.address.postal_code,
                        //state_id: _patient.address.state_id,// == '' ? null : _patient.address.state_id,
                        state_id: _patient.address.state_id ? _patient.address.state_id.toString().trim() : _patient.address.state_id,
                        country_id: _patient.address.country_id,// == '' ? null : _patient.address.country_id,
                        is_required: false
                    },
                    relationship: relation_ship,
                    phone: (_patient.phone.primary == "1" ? _patient.phone.home : _patient.phone.primary == "2" ? _patient.phone.cell : _patient.phone.work),


                    formatted_date_of_birth: moment(_patient.date_of_birth),
                    gender: _patient.gender,// == '' ? null : _patient.gender,
                    ssn: _patient.ssn,

                    same_as_patient: is_checked

                };

            }

        }
        return updated_subscriber_data;
    }
    copy_data = async (e, data) => {

        //if (this.props.patient_details.selected_patient.data && this.props.patient_details.selected_patient.status != 0) {
        //let _patient = Object.assign({}, this.props.persisted_details.selected_patient.data);
        let _patient = Object.assign({}, this.props.patient_details.selected_patient.data);
        let _is_us_selected = _patient.address.country_id == 1;

        // let patient_validation = Object.assign({}, this.state.patient_form_invalid);

        if (data.checked) {
            var relation_ship = "1";
            if (this.state.control_flags.is_worker_comp) {
                relation_ship = "4";
            }
            var updated_subscriber_data = {

                ...this.state.subscriber,
                is_us_selected: _is_us_selected,
                name: {
                    middle_initial: _patient.name.middle_initial,
                    suffix: _patient.name.suffix,
                    first_name: _patient.name.first_name,// == '' ? null : _patient.name.first_name,
                    last_name: _patient.name.last_name,// == '' ? null : _patient.name.last_name,
                    is_name_requried: false
                },
                address: {
                    address1: _patient.address.address1,// == '' ? null : _patient.address.address1,
                    address2: _patient.address.address2,
                    city: _patient.address.city,// == '' ? null : _patient.address.city,
                    province: _is_us_selected ? '' : _patient.address.province,
                    //zip: _patient.address.zip,// == '' ? null : _patient.address.zip,
                    zip: zip_code_formation(_patient.address.zip),// == '' ? null : _patient.address.zip,
                    unformat_zip: _patient.address.zip,// == '' ? null : _patient.address.zip,
                    postal_code: _is_us_selected ? '' : _patient.address.postal_code,
                    //state_id: _patient.address.state_id,// == '' ? null : _patient.address.state_id,
                    state_id: _patient.address.state_id ? _patient.address.state_id.toString().trim() : _patient.address.state_id,
                    country_id: _patient.address.country_id,// == '' ? null : _patient.address.country_id,
                    is_required: false
                },
                relationship: relation_ship,
                phone: (_patient.phone.primary == "1" ? _patient.phone.home : _patient.phone.primary == "2" ? _patient.phone.cell : _patient.phone.work),


                formatted_date_of_birth: _patient.date_of_birth == '' ? null : _patient.date_of_birth,
                gender: _patient.gender,// == '' ? null : _patient.gender,
                ssn: _patient.ssn,

                same_as_patient: data.checked,


            };
            //if (!(updated_subscriber_data.date_of_birth == null || updated_subscriber_data.date_of_birth == undefined)) {

            updated_subscriber_data.formatted_date_of_birth = moment(_patient.date_of_birth);

            if (this._is_mount) {
                this.setState({

                    subscriber: updated_subscriber_data,
                    //patient_form_invalid: updated_validation.patient_data
                });
            }

        }
        else {
            let updated_subscriber_data_new = {

                ...this.state.subscriber,
                same_as_patient: data.checked
            }
            if (this._is_mount) {
                this.setState({

                    subscriber: updated_subscriber_data_new,
                    //patient_form_invalid: updated_validation.patient_data
                });
            }

        }




    }

    handle_calendar_change = (dt) => {
        var m = date_format_with_no_time(dt);
        var subscriber_info = this.state.subscriber;
        var subscriber_invalid = this.state.subscriber_form_invalid;
        var subscriber_modified_data = this.state.modified_data;
        if (m.isValid()) { subscriber_invalid.date_of_birth = false; }
        if ((subscriber_info.old_date_of_birth ? subscriber_info.old_date_of_birth : null) !== m) {
            subscriber_modified_data.subscriber.date_of_birth = true;
            if (m == null) {
                subscriber_invalid.formatted_date_of_birth = true;
            }
            else {
                subscriber_invalid.formatted_date_of_birth = false;
            }
        }
        else {
            subscriber_modified_data.subscriber.date_of_birth = false;
            subscriber_invalid.formatted_date_of_birth = false;
        }
        subscriber_info.formatted_date_of_birth = m;
        var is_same_as_Patient = this.check_same_patient_details();
        subscriber_info.same_as_patient = is_same_as_Patient;
        if (this._is_mount) {
            this.setState({
                subscriber: subscriber_info,
                subscriber_form_invalid: subscriber_invalid,
                modified_data: subscriber_modified_data
            });
        }
    }
    on_change_subscriber_active = (e, data) => {
        if (this._is_mount) {
            this.setState({
                subscriber: {
                    ...this.state.subscriber,
                    is_active: data.checked
                }
            })
        }
    }
    //valid subscriber details
    valid_subscriber = () => {
        if (this.state.subscriber.insurance_code_id) {

        }

    }
    //Set the state from parent component
    set_state_from_parent = (subscriber_state_data) => {
        if (this._is_mount) {
            if (subscriber_state_data.is_subscriber_data && !subscriber_state_data.is_modified_data && !subscriber_state_data.is_valid_data) {
                this.setState({
                    subscriber: subscriber_state_data.subscriber
                })
            }
            if (subscriber_state_data.is_subscriber_data && subscriber_state_data.is_modified_data && subscriber_state_data.is_valid_data) {
                this.setState({
                    subscriber: subscriber_state_data.subscriber,
                    subscriber_form_invalid: subscriber_state_data.subscriber_form_invalid,
                    modified_data: subscriber_state_data.modified_data
                })
            }
            if (!subscriber_state_data.is_subscriber_data && subscriber_state_data.is_modified_data && !subscriber_state_data.is_valid_data) {
                this.setState({
                    modified_data: subscriber_state_data.modified_data
                })
            }
            if (!subscriber_state_data.is_subscriber_data && !subscriber_state_data.is_modified_data && subscriber_state_data.is_valid_data) {
                this.setState({
                    subscriber_form_invalid: subscriber_state_data.subscriber_form_invalid,
                }, () => {
                    this.forceUpdate();
                })
            }
        }
    }
    // function / method to validate the data of form when the state changes
    validate_form_field_data = () => {
        var in_valid_form_data = false;
        var subscriber_data = this.state.subscriber;//subscriber_state;
        var valid = this.state.subscriber_form_invalid;//validate_field;       
        var search_control_id = 'insurance_search_auto' + this.state.subscriber.pst_coverage ? this.state.subscriber.pst_coverage : '';
        if (document.getElementById(search_control_id)) {
            var val = document.getElementById(search_control_id)["value"];
            if (val) {
                valid.insurance_code_id = false;
            }
        }

        if (this.state.control_flags.is_worker_comp) {
            if (subscriber_data.employee_name == null || subscriber_data.employee_name == undefined || subscriber_data.employee_name == '') {
                valid.employee_name = true;
                in_valid_form_data = true;
            } else {
                valid.employee_name = false;
            }

        }
        if (subscriber_data.employee_phone == null || subscriber_data.employee_phone == undefined || subscriber_data.employee_phone == '') {
            valid.employee_phone = false;
        } else {
            if (subscriber_data.employee_phone.length > 0 && subscriber_data.employee_phone.length != 10) {
                valid.employee_phone = true;
                in_valid_form_data = true;
            } else {
                valid.employee_phone = false;
            }
        }
        if (subscriber_data.phone == null || subscriber_data.phone == undefined || subscriber_data.phone == '') {
            valid.phone = false;

        } else {
            if (subscriber_data.phone.length > 0 && subscriber_data.phone.length != 10) {
                valid.phone = true;
                in_valid_form_data = true;
            } else {
                valid.phone = false;
            }
        }
        if (subscriber_data.is_us_selected) {
            //patient zip
            if (subscriber_data.address.unformat_zip == null || subscriber_data.address.unformat_zip == undefined || subscriber_data.address.unformat_zip == '') {
                if (this.state.modified_data.subscriber.address.zip) {
                    valid.address.zip = true;
                }
                else {
                    valid.address.zip = false;
                }
            }
            else {
                if ((subscriber_data.address.unformat_zip.length > 0 && subscriber_data.address.unformat_zip.length < 5) || (subscriber_data.address.unformat_zip.length > 5 && subscriber_data.address.unformat_zip.length < 9)) {
                    valid.address.zip = true;
                    in_valid_form_data = true;
                } else {
                    valid.address.zip = false;
                }
            }
        }
        //Subscriber's Employer  zip
        if (subscriber_data.employee_address.unformat_zip == null || subscriber_data.employee_address.unformat_zip == undefined || subscriber_data.employee_address.unformat_zip == '') {
            valid.employee_address.zip = false;
        }
        else {
            if ((subscriber_data.employee_address.unformat_zip.length > 0 && subscriber_data.employee_address.unformat_zip.length < 5) || (subscriber_data.employee_address.unformat_zip.length > 5 && subscriber_data.employee_address.unformat_zip.length < 9)) {
                valid.employee_address.zip = true;
                in_valid_form_data = true;
            } else {
                valid.employee_address.zip = false;
            }
        }
        if (subscriber_data.ssn == null || subscriber_data.ssn == undefined || subscriber_data.ssn == '') {
            valid.ssn = false;
        } else {
            if (subscriber_data.ssn.length > 0 && subscriber_data.ssn.length != 9) {
                valid.ssn = true;
                in_valid_form_data = true;
            } else {
                valid.ssn = false;
            }
        }
        let subscriber_available_flag = this.is_subscriber_available();
        var validate_form = {
            in_valid_form_data: in_valid_form_data,
            insurance_data: valid,
            is_subscrible_available: subscriber_available_flag,
            is_insurance_available: subscriber_data.is_insurance_available//is_valid_value(this.state.subscriber.insurance_code_id) && this.state.subscriber.insurance_code_id != "0"
        };
        if (this._is_mount) {
            if (is_valid_value(this.state.subscriber.insurance_code_id) && this.state.subscriber.insurance_code_id != "0") {
                var subscriber = this.state.subscriber;
                subscriber.is_insurance_update = true;
                this.setState({
                    subscriber: subscriber,
                    subscriber_form_invalid: valid
                })
            }
            else {
                this.setState({
                    subscriber_form_invalid: valid,
                    subscriber: subscriber_data
                })
            }
        }
        return validate_form;
    }
    on_save_subscriber = (case_id) => {
        var subscriber_data = this.state.subscriber;
        subscriber_data.date_of_birth = subscriber_data.formatted_date_of_birth == null ? null : subscriber_data.formatted_date_of_birth;
        subscriber_data.case_id = case_id;
        if (this.insurance_eligibility)
            subscriber_data.subscriber_eligibility = this.insurance_eligibility.get_updated_eligibility_list()
        if (this.props.ins_type == enum_type_of_insurance.primary_insurance)
            subscriber_data.subscriber_doctors_orders = this.insurance_doctors_orders.get_valid_doctors_order_list()
        subscriber_data.subscriber_pre_auth = this.insurance_preauthorization.get_valid_preauth_data();
        //convert formatted zip code to unformatted one
        subscriber_data.address.zip = unformat_zip_code(subscriber_data.address.zip);
        subscriber_data.employee_address.zip = unformat_zip_code(subscriber_data.employee_address.zip);
        ///////
        if (this._is_mount) {
            this.setState({
                subscriber: subscriber_data
            });
        }
        this.valid_subscriber();

        return add_subscriber(this.props.user_login_details.user_data.data.token_details.access_token, trim_nested_json_obj_values(this.state.subscriber));


    }
    // check subscriber patient details match with session patient detail whether it is same or not
    check_same_patient_details = () => {
        //var state_patient = Object.assign({}, this.props.persisted_details.selected_patient.data);
        var state_patient = Object.assign({}, this.props.patient_details.selected_patient.data);
        var state_subscriber = Object.assign({}, this.state.subscriber);
        delete state_subscriber.address['unformat_zip'];
        state_patient.name.suffix = state_patient.name.suffix == null ? '' : state_patient.name.suffix;
        state_patient.name.middle_initial = state_patient.name.middle_initial == null ? '' : state_patient.name.middle_initial;
        state_subscriber.name.suffix = state_subscriber.name.suffix == null ? '' : state_subscriber.name.suffix;
        state_subscriber.name.middle_initial = state_subscriber.name.middle_initial == null ? '' : state_subscriber.name.middle_initial;

        state_patient.address.province = state_patient.address.province && state_patient.address.province.toString().trim() != '' ? state_patient.address.province : '';
        state_patient.address.postal_code = state_patient.address.postal_code && state_patient.address.postal_code.toString().trim() != '' ? state_patient.address.postal_code : '';
        state_subscriber.address.province = state_subscriber.address.province && state_subscriber.address.province.toString().trim() != '' ? state_subscriber.address.province : '';
        state_subscriber.address.postal_code = state_subscriber.address.postal_code && state_subscriber.address.postal_code.toString().trim() != '' ? state_subscriber.address.postal_code : '';

        state_patient.address.state_id = state_patient.address.state_id && state_patient.address.state_id.trim() != '' ? state_patient.address.state_id : '';

        state_patient.address.zip = state_patient.address.zip && state_patient.address.zip.trim() != '' ? zip_code_formation(state_patient.address.zip) : '';
        state_subscriber.address.state_id = state_subscriber.address.state_id && state_subscriber.address.state_id.trim() != '' ? state_subscriber.address.state_id : '';
        state_patient.address.is_required = state_patient.address.is_required == null ? false : state_patient.address.is_required;
        // state_patient.address.unformat_zip = state_patient.address.zip && state_patient.address.zip.trim() != '' ? unformat_zip_code(state_patient.address.zip) : '';
        // state_subscriber.address.zip = state_subscriber.address.zip && state_subscriber.address.zip.trim() != '' ? state_subscriber.address.zip : '';

        let _same_as_patient = false;

        if (JSON.stringify(state_patient.name, function (key, val) { if (key !== 'preferred_name') { return val; } }) === JSON.stringify(state_subscriber.name, function (key, val) { if (key !== 'preferred_name') { return val; } }) &&
            JSON.stringify(state_subscriber.address, function (key, val) {
                if (val == null || val == '' || val == undefined) { return ""; } return val;
            }) === JSON.stringify(state_patient.address, function (key, val) {
                if (val == null || val == '' || val == undefined) { return ""; } return val;
            }) &&

            (state_subscriber.formatted_date_of_birth ? state_patient.date_of_birth ? moment(state_subscriber.formatted_date_of_birth).format('LL') == moment(state_patient.date_of_birth).format('LL') : false : state_patient.date_of_birth ? false : true) &&
            //state_patient.responsible_party.date_of_birth.format('LL') == state_patient.date_of_birth.format('LL') &&
            state_subscriber.gender == state_patient.gender &&
            state_subscriber.ssn == state_patient.ssn &&

            (state_patient.phone.primary == "1" ? state_subscriber.phone == state_patient.phone.home : state_patient.phone.primary == "2" ? state_subscriber.phone == state_patient.phone.cell : state_subscriber.phone == state_patient.phone.work)

        ) {
            _same_as_patient = true;
        }
        this.state.subscriber.address.unformat_zip = unformat_zip_code(this.state.subscriber.address.zip);
        if (this.state.subscriber.relationship) {
            if (this.state.control_flags.is_worker_comp) {
                if (parseInt(this.state.subscriber.relationship.toString()) !== 4) {
                    _same_as_patient = false
                }
            }
            else {
                if (parseInt(this.state.subscriber.relationship.toString()) !== 1) {
                    _same_as_patient = false
                }
            }

        }

        return _same_as_patient;

    }

    get_on_update_subscriber_data = () => {
        var subscriber = this.state.subscriber;
        //convert formatted zip code to unformatted one
        subscriber.address.zip = unformat_zip_code(subscriber.address.zip);
        subscriber.employee_address.zip = unformat_zip_code(subscriber.employee_address.zip);
        ///////
        if (this.insurance_eligibility)
            subscriber.subscriber_eligibility = this.insurance_eligibility.get_updated_eligibility_list()
        if (this.props.ins_type == enum_type_of_insurance.primary_insurance)
            subscriber.subscriber_doctors_orders = this.insurance_doctors_orders.get_valid_doctors_order_list()

        subscriber.subscriber_pre_auth = this.insurance_preauthorization.get_valid_preauth_data(true);
        if (this._is_mount) {
            this.setState({
                subscriber: subscriber
            });
        }
        return subscriber
    }

    validate_updated_details = () => {
        var error_msg = "";
        var error_count = 0;
        var subscriber_invalid = this.state.subscriber_form_invalid;
        if (this.state.subscriber_form_invalid.insurance_code_id) {
            error_count = 1;
        }

        if (this.state.modified_data.subscriber.date_of_birth && (this.state.subscriber.formatted_date_of_birth == undefined || this.state.subscriber.formatted_date_of_birth == null)) {
            subscriber_invalid.formatted_date_of_birth = true;
            error_count = 1;
        }
        else {
            subscriber_invalid.formatted_date_of_birth = false;
        }
        if (this.state.modified_data.subscriber.name.first_name && (this.state.subscriber.name.first_name === '' || this.state.subscriber.name.first_name === undefined || this.state.subscriber.name.first_name === null)) {
            subscriber_invalid.name.first_name = true;
            error_count = 1;
        }
        else {
            subscriber_invalid.name.first_name = false;
        }
        if (this.state.modified_data.subscriber.name.last_name && (this.state.subscriber.name.last_name === '' || this.state.subscriber.name.last_name === undefined || this.state.subscriber.name.last_name === null)) {
            subscriber_invalid.name.last_name = true;
            error_count = 1;
        }
        else {
            subscriber_invalid.name.last_name = false;
        }
        if (this.state.modified_data.subscriber.address.address1 && (this.state.subscriber.address.address1 === '' || this.state.subscriber.address.address1 === undefined || this.state.subscriber.address.address1 === null)) {
            subscriber_invalid.address.address1 = true;
            error_count = 1;
        }
        else {
            subscriber_invalid.address.address1 = false;
        }
        if (this.state.modified_data.subscriber.address.city && (this.state.subscriber.address.city === '' || this.state.subscriber.address.city === undefined || this.state.subscriber.address.city === null)) {
            subscriber_invalid.address.city = true;
            error_count = 1;
        }
        else {
            subscriber_invalid.address.city = false;
        }
        if (this.state.subscriber.address.country_id && this.state.subscriber.address.country_id.toString() == "1") {
            if (this.state.modified_data.subscriber.address.zip && (this.state.subscriber.address.zip === '' || this.state.subscriber.address.zip === undefined || this.state.subscriber.address.zip === null)) {
                subscriber_invalid.address.zip = true;
                error_count = 1;
            }
            else {
                subscriber_invalid.address.zip = false;
            }
        }


        if (this.state.modified_data.subscriber.relationship && (parseInt(this.state.subscriber.relationship.toString()) === 0 || this.state.subscriber.relationship === undefined || this.state.subscriber.relationship === null)) {
            subscriber_invalid.relationship = true;
            error_count = 1;
        }
        else {
            subscriber_invalid.relationship = false;
        }
        if (this.state.modified_data.subscriber.group_number && (this.state.subscriber.group_number == '' || this.state.subscriber.group_number === undefined || this.state.subscriber.group_number === null)) {
            subscriber_invalid.group_number = true;
            error_count = 1;
        }
        else {
            subscriber_invalid.group_number = false;
        }
        if (this.state.modified_data.subscriber.policy_number && (this.state.subscriber.policy_number == '' || this.state.subscriber.policy_number === undefined || this.state.subscriber.policy_number === null)) {
            subscriber_invalid.policy_number = true;
            error_count = 1;
        }
        else {
            subscriber_invalid.policy_number = false;
        }
        if (this._is_mount) {
            this.setState({ subscriber_form_invalid: subscriber_invalid });
        }
        return error_count;
    }

    get_grid_ref = grid_params => {
      var grid_width = document.getElementsByClassName('daragble-App')[0].clientWidth;
      if (this.total_column_width > grid_width) {
            shared_constants.search_insurance_configuration.column_defs.filter(item => {
                grid_params.columnApi.setColumnWidth(item.field, item.width, false);
            });
      }      
    }
    

    add_primary_insurance = () => {
        if (this._is_mount) {
            this.setState({ add_primary_insurance_modal: !this.state.add_primary_insurance_modal });
        }
    }

    set_primary_insurance_details = (data) => {
        let id = ''; let selected_data = ''; let code = ''; let name = ''; let address = ''; let city = ''; let state = ''; let zip = '';
        let subscriber_state = this.state.subscriber;
        let subscriber_invalid = this.state.subscriber_form_invalid;
        if (data) {
            id = data.id.toString();
            if ((data.insurance_code != null && data.insurance_code != undefined) || (data.name != null && data.name != undefined)) {
                code = (data.insurance_code == null && data.insurance_code == undefined) ? '' : data.insurance_code;
                name = (data.name == null && data.name == undefined) ? '' : data.name;
                address = (data.address.address1 == null && data.address.address1 == undefined) ? '' : data.address.address1;
                city = (data.address.city == null && data.address.city == undefined) ? '' : data.address.city;
                state = (data.address.state_id == null && data.address.state_id == undefined) ? '' : data.address.state_id;
                zip = (data.address.zip == null && data.address.zip == undefined) ? '' : data.address.zip;
            }
        }
        selected_data = (code == null ? '' : code) + ' - ' + (name == null ? '' : name) + ', ' + (address != null ? ((address == null ? '' : address) +
            ', ' + (city == null ? '' : city) + ', ' + (state == null ? '' : state) + ', ' + (zip == null ? '' : zip)) : '');

        let format_ins = { "label": '', "title": '', "insurance_code": '' };
        if (parseInt(id) > 0) {
            // Set Auto search control for location
            format_ins = {
                "title": id.toString(),
                "label": selected_data,
                "insurance_code": code
            };
        }
        subscriber_state.insurance_code_id = subscriber_state.insurance.id = id;
        subscriber_state.is_insurance_available = true;
        subscriber_invalid.insurance_code_id = false;
        if (this._is_mount) {
            this.setState({
                insurance_quick_search_format: format_ins,
                add_primary_insurance_modal: false,
                advanced_modal_state: false,
                subscriber: subscriber_state
            })
        }

        // setting to focus to the search icon
        set_focus_on_element_with_id('resizable-control-' + camelize(this.props.ins_type));
    }

    set_parent_show_modal_state = (flag, controlId) => {
        if (this._is_mount) {
            this.setState({ advanced_modal_state: flag });
        }
    }
    reset_invalid_form_data = () => {

        var subscriber_form_invalid = {
            insurance_code_id: false,
            group_number: false,
            policy_number: false,
            name: {
                first_name: false,
                last_name: false,
            },
            address: {
                address1: false,
                city: false,
                province: false,
                zip: false,
                postal_code: false,
                state_id: false,
                country_id: false,
            },
            relationship: false,
            employee_name: false,
            gender: false,
            date_of_birth: false,
            formatted_date_of_birth: false,
            phone: false,
            employee_phone: false,
            ssn: false,
            phone_cust: false,
            employee_address: {
                address1: false,
                city: false,
                province: false,
                zip: false,
                postal_code: false,
                state_id: false,
                country_id: false,
            },
        }
        if (this._is_mount) {
            this.setState({ subscriber_form_invalid: subscriber_form_invalid });
        }
    }

    handleCalendarChange = () => { }

    initialize_insurance_preauthorization_reference = (instance) => {
        this.insurance_preauthorization = instance;
    }
    initialize_insurance_eligibility_reference = (instance) => {
        this.insurance_eligibility = instance;
    }
    initialize_insurance_doctors_orders_reference = (instance) => {
        this.insurance_doctors_orders = instance;
    }

    get_input_value_for_insurance = (input) => {
        this.get_input_value(input, enum_type_of_search.insurance)
    }

    on_subscriber_form_change_for_msp_type = (value, e) => {
        this.on_subscriber_form_change(e, { name: "msp_type", value: value, is_dropdown: true })
    }

    on_subscriber_form_change_for_country_id = (value, e) => {
        this.on_subscriber_form_change(e, { name: "country_id", value: value, is_dropdown: true, is_obj_on_another_hirerchy: true, obj_hierarch_name: "address" })
    }

    on_subscriber_form_change_for_state_id = (value, e) => {
        this.on_subscriber_form_change(e, { name: "state_id", value: value, is_dropdown: true, is_obj_on_another_hirerchy: true, obj_hierarch_name: "address" })
    }
    on_subscriber_form_change_for_relationship = (value, e) => {
        this.on_subscriber_form_change(e, { name: "relationship", value: value, is_dropdown: true, is_obj_on_another_hirerchy: false, obj_hierarch_name: "address" })
    }

    on_subscriber_form_change_for_gender = (value, e) => {
        this.on_subscriber_form_change(e, { name: "gender", value: value, is_dropdown: true, is_obj_on_another_hirerchy: false, obj_hierarch_name: "address" })
    }

    on_subscriber_form_change_for_employee_state = (value, e) => {
        this.on_subscriber_form_change(e, { name: "state_id", value: value, is_dropdown: true, is_obj_on_another_hirerchy: true, obj_hierarch_name: "employee_address" })
    }

    render() {
        let hidden = {
            display: this.state.shown ? "none" : "block"
        }      
        let cntrl_id = "insurance_search_auto" + this.state.subscriber.pst_coverage ? this.state.subscriber.pst_coverage : '';
        let is_edit_mode = this.state.page_state == enum_case_page_state.edit;
        let control_id = this.props.ins_type == enum_type_of_insurance.primary_insurance ? global_constants.constants.advanced_control_type.primary_insurance : this.props.ins_type == enum_type_of_insurance.secondary_insurance ? global_constants.constants.advanced_control_type.secondary_insurance : global_constants.constants.advanced_control_type.tertiary_insurance;
        let ins_type_id = this.props.ins_type === enum_type_of_insurance.primary_insurance ? "primary_insurance" : (this.props.ins_type === enum_type_of_insurance.secondary_insurance ? "secondary_insurance" : "tertiary_insurance");
        return (
            <React.Fragment>
                <div className="ui grid">


                    <AddPrimaryInsurance insurance_types={this.props.insurance_types} insurance_contract={this.props.insurance_contract} set_primary_insurance_details={this.set_primary_insurance_details} add_primary_insurance={this.state.add_primary_insurance_modal}
                    />
                    <div className="sixteen wide computer column">
                        <div className="accordion ui fluid styled common-accordion">
                            <div className="active title" onClick={this.toggle.bind(this)} >{this.props.ins_type}<i tabIndex={0} onKeyDown={handle_click_on_enter} className={this.state.shown ? "angle down styled-accordion-icon horizontal icon " : "angle up styled-accordion-icon horizontal icon "}></i></div>
                            <div className="content active" style={hidden} >
                                <Dimmer active={this.state.loading}>
                                    <Loader size='massive'>Loading</Loader>
                                </Dimmer>
                                {//is_edit_mode ?

                                    //this.props.ins_type == enum_type_of_insurance.primary_insurance || (this.props.ins_type == enum_type_of_insurance.secondary_insurance) || ( this.props.ins_type == enum_type_of_insurance.tertiary_insurance) ?
                                    this.props.ins_type == enum_type_of_insurance.primary_insurance || (this.props.ins_type == enum_type_of_insurance.secondary_insurance) || (this.props.ins_type == enum_type_of_insurance.tertiary_insurance) ?
                                        <Grid.Row>
                                            { this.state.features.is_payment_posting_feedback_enabled
                                            ?
                                            <Grid.Column className="mar-b-20" mobile={16} tablet={16} computer={16}>
                                                <Grid>
                                                    <Grid.Column mobile={8} tablet={8} computer={8}>
                                                        <Form.Field className="advance-quick-search">
                                                            <label>Insurance <span className={this.state.subscriber_form_invalid.insurance_code_id && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span></label>
                                                            <AdvancedControlWithResizable
                                                                showModal={this.state.advanced_modal_state}
                                                                addClicks={this.add_primary_insurance} disabled_element={this.state.page_state === enum_case_page_state.edit}
                                                                onGridRowSelection={this.on_insurance_selection}
                                                                gridConfig={this.state.insurance_search_data}
                                                                controlId={control_id}
                                                                onSearch={this.on_insurance_search}
                                                                set_parent_show_modal_state={this.set_parent_show_modal_state}
                                                                get_grid_ref={this.get_grid_ref}
                                                                search_type={camelize(this.props.ins_type)}
                                                                headerIdForGridTabNavigation={shared_constants.insurance_search_grid_header_id} />

                                                            <AutoSearchComponent on_blur={this.on_blur_auto_search} control_id={cntrl_id}
                                                                getInputValue={this.get_input_value_for_insurance} default_value={this.state.insurance_quick_search_format}
                                                                errorMessage={'No Insurance Found !'} prepareRenderList={this.render_suggestion_result} getList={this.get_insurance_quick_search_data_list}
                                                                prepareDataList={this.prepare_suggestion} selectresult={this.on_item_selection} is_focus={false}
                                                                is_disabled={this.state.page_state === enum_case_page_state.edit} show_clear_search={true} clear_search={this.clear_quick_search}
                                                                errorClass={this.state.subscriber_form_invalid.insurance_code_id && this.state.form_submited ? 'search-error-thin' : ''}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>

                                                    <Grid.Column mobile={4} tablet={4} computer={4}>
                                                        <Form.Field>
                                                            <label>Payer Description</label>
                                                            <Form.Input name='txt_payer_description' value={this.state.subscriber.insurance.payer_formatted_description || ''}
                                                                id="txt_payer_description"
                                                                disabled={true}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid>
                                            </Grid.Column>
                                            :
                                            <Grid.Column className="mar-b-20" mobile={16} tablet={16} computer={16}>
                                            <Grid>
                                                <Grid.Column mobile={16} tablet={16} computer={16}>
                                                    <Form.Field className="advance-quick-search">
                                                        <label>Insurance <span className={this.state.subscriber_form_invalid.insurance_code_id && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span></label>
                                                        <AdvancedControlWithResizable
                                                            showModal={this.state.advanced_modal_state}
                                                            addClicks={this.add_primary_insurance} disabled_element={this.state.page_state === enum_case_page_state.edit}
                                                            onGridRowSelection={this.on_insurance_selection}
                                                            gridConfig={this.state.insurance_search_data}
                                                            controlId={control_id}
                                                            onSearch={this.on_insurance_search}
                                                            set_parent_show_modal_state={this.set_parent_show_modal_state}
                                                            get_grid_ref={this.get_grid_ref}
                                                            search_type={camelize(this.props.ins_type)}
                                                            headerIdForGridTabNavigation={shared_constants.insurance_search_grid_header_id} />

                                                        <AutoSearchComponent on_blur={this.on_blur_auto_search} control_id={cntrl_id}
                                                            getInputValue={this.get_input_value_for_insurance} default_value={this.state.insurance_quick_search_format}
                                                            errorMessage={'No Insurance Found !'} prepareRenderList={this.render_suggestion_result} getList={this.get_insurance_quick_search_data_list}
                                                            prepareDataList={this.prepare_suggestion} selectresult={this.on_item_selection} is_focus={false}
                                                            is_disabled={this.state.page_state === enum_case_page_state.edit} show_clear_search={true} clear_search={this.clear_quick_search}
                                                            errorClass={this.state.subscriber_form_invalid.insurance_code_id && this.state.form_submited ? 'search-error-thin' : ''}
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid>
                                            </Grid.Column>}
                                            <Grid.Column className="mar-b-20" mobile={16} tablet={16} computer={16}>
                                                <Grid>
                                                    <Grid.Column mobile={16} tablet={8} computer={4}>
                                                        <Form.Field >
                                                            {this.state.control_flags && !(this.state.control_flags.is_show_ssn_claim_number || this.state.control_flags.is_show_claim_number_ssn) &&
                                                                < label > Group Number <span className={this.state.subscriber_form_invalid.group_number && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span></label>
                                                            }
                                                            {this.state.control_flags && (this.state.control_flags.is_show_ssn_claim_number && !this.state.control_flags.is_show_claim_number_ssn) &&
                                                                < label > SSN <span className={this.state.subscriber_form_invalid.group_number && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span></label>
                                                            }
                                                            {this.state.control_flags && ((!this.state.control_flags.is_show_ssn_claim_number && this.state.control_flags.is_show_claim_number_ssn) || (this.state.control_flags.is_show_ssn_claim_number && this.state.control_flags.is_show_claim_number_ssn)) &&
                                                                < label > Claim Number <span className={this.state.subscriber_form_invalid.group_number && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span></label>
                                                            }
                                                            <Form.Input autoComplete="new-password" maxLength={50} name='txt_group_number' value={this.state.subscriber.group_number || ''} onChange={
                                                                e => { this.on_subscriber_form_change(e) }
                                                            } id="subscriber_group_txt" disabled={this.state.page_state === enum_case_page_state.edit}
                                                                className={this.state.page_state === enum_case_page_state.add && this.state.subscriber_form_invalid.group_number && this.state.form_submited ? "search-error-thin" : ''}
                                                                error={this.state.page_state === enum_case_page_state.add && this.state.subscriber_form_invalid.group_number && this.state.form_submited}
                                                            />
                                                            <p className={this.state.subscriber_form_invalid.group_number && this.state.form_submited ? "required-place-bottom req-alert" : 'required-place-bottom'}><i>{case_messages.required_for_billing_text} </i></p>
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column mobile={16} tablet={8} computer={4}>
                                                        <Form.Field >

                                                            {
                                                                this.state.control_flags && !(this.state.control_flags.is_show_ssn_claim_number || this.state.control_flags.is_show_claim_number_ssn) &&
                                                                < label > Policy Number <span className={this.state.subscriber_form_invalid.policy_number && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span></label>

                                                            }
                                                            {this.state.control_flags && (this.state.control_flags.is_show_ssn_claim_number && !this.state.control_flags.is_show_claim_number_ssn) &&
                                                                < label > Claim Number <span className={this.state.subscriber_form_invalid.policy_number && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span></label>
                                                            }
                                                            {this.state.control_flags && ((!this.state.control_flags.is_show_ssn_claim_number && this.state.control_flags.is_show_claim_number_ssn) || (this.state.control_flags.is_show_ssn_claim_number && this.state.control_flags.is_show_claim_number_ssn)) &&
                                                                < label >SSN <span className={this.state.subscriber_form_invalid.policy_number && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span></label>
                                                            }
                                                            <Form.Input autoComplete="new-password" maxLength={50} name='txt_policy_number' value={this.state.subscriber.policy_number || ''} onChange={
                                                                e => { this.on_subscriber_form_change(e) }
                                                            } id="subscriber_policy_txt" disabled={this.state.page_state === enum_case_page_state.edit}
                                                                className={this.state.page_state === enum_case_page_state.add && this.state.subscriber_form_invalid.policy_number && this.state.form_submited ? "search-error-thin" : ''}
                                                                error={this.state.page_state === enum_case_page_state.add && this.state.subscriber_form_invalid.policy_number && this.state.form_submited}
                                                            />
                                                            <p className={this.state.subscriber_form_invalid.policy_number && this.state.form_submited ? "required-place-bottom req-alert" : 'required-place-bottom'}><i>{case_messages.required_for_billing_text} </i></p>
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    {this.state.control_flags && this.state.control_flags.is_show_msp_type &&
                                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                                            <Form.Field >
                                                                < label > MSP Type  </label>
                                                                <SelectionComponent
                                                                    name='subscriber_policy'
                                                                    id="subscriber_policy_txt"
                                                                    options={this.state.master_data.subscriber_msp_type}
                                                                    onChange={this.on_subscriber_form_change_for_msp_type}
                                                                    defaultValue={this.state.subscriber.msp_type || ''}
                                                                    style='menu-options-inline'
                                                                    //isRequired={this.state.error.state ? true : false}
                                                                    disabled={this.state.page_state === enum_case_page_state.edit}
                                                                />
                                                                {
                                                                    //<Dropdown fluid placeholder='Select' selection options={this.state.master_data.subscriber_msp_type} value={this.state.subscriber.msp_type || ''}
                                                                    //    onChange={(e, { value }) => { this.on_subscriber_form_change(e, { name: "msp_type", value: value, is_dropdown: true }) }}
                                                                    //    id="subscriber_policy_txt" disabled={this.state.page_state === enum_case_page_state.edit}

                                                                    ///>
                                                                    //<Form.Input autoComplete="new-password" maxLength={50} name='txt_msp_type' value={this.state.subscriber.msp_type || ''} onChange={
                                                                    //    e => { this.on_subscriber_form_change(e) }
                                                                    //} id="subscriber_policy_txt" disabled={this.state.page_state === enum_case_page_state.edit}
                                                                    //className={parseInt(this.state.case_id) > 0 && this.state.page_state === enum_case_page_state.add && this.state.subscriber_form_invalid.policy_number && this.state.form_submited ? "red-error-thin" : ''}
                                                                    //error={parseInt(this.state.case_id) > 0 && this.state.page_state === enum_case_page_state.add && this.state.subscriber_form_invalid.policy_number && this.state.form_submited}
                                                                    ///>
                                                                }
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    }
                                                    {this.state.case_id > 0 && this.state.page_state === enum_case_page_state.edit &&
                                                        <Grid.Column mobile={16} tablet={16} computer={4}>
                                                            <Form.Field >
                                                                < label > Phone  </label>
                                                                <Form.Input autoComplete="new-password" maxLength={50} name='txt_phone_cust' value={this.state.subscriber.insurance.phone_cust || ''} onChange={
                                                                    (e, { value }) => { this.on_subscriber_form_change(e, { name: "insurance", value: value, is_dropdown: false, is_obj_on_another_hirerchy: true, obj_hierarch_name: "insurance" }) }
                                                                } id="subscriber_phone_txt" disabled={this.state.page_state === enum_case_page_state.edit}
                                                                //className={parseInt(this.state.case_id) > 0 && this.state.page_state === enum_case_page_state.add && this.state.subscriber_form_invalid.policy_number && this.state.form_submited ? "red-error-thin" : ''}
                                                                //error={parseInt(this.state.case_id) > 0 && this.state.page_state === enum_case_page_state.add && this.state.subscriber_form_invalid.policy_number && this.state.form_submited}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    }
                                                </Grid>
                                            </Grid.Column>
                                            <Grid.Column mobile={16} tablet={16} computer={9}>
                                                <Grid>
                                                    <Grid.Column className="mar-b-20 padding-zero" mobile={16} tablet={16} computer={9}>

                                                        <Grid>
                                                            <Grid.Column mobile={16} tablet={16} computer={16}>
                                                                <p>
                                                                    Subscriber Information     </p>
                                                            </Grid.Column>
                                                        </Grid>
                                                        <Grid>
                                                            <Grid.Column mobile={8} tablet={8} computer={16}>
                                                                <Form.Field >
                                                                    <Checkbox disabled={this.state.page_state === enum_case_page_state.edit} label='Same as Patient' name='txt_same_as_patient' checked={this.state.subscriber.same_as_patient || false}
                                                                        onChange={this.copy_data} />
                                                                </Form.Field>
                                                            </Grid.Column>
                                                        </Grid>
                                                        <Grid>
                                                            <Grid.Column mobile={8} tablet={8} computer={5}>
                                                                <Form.Field >
                                                                    <label>First Name <span className={this.state.subscriber_form_invalid.name.first_name && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span></label>
                                                                    <Form.Input fluid
                                                                        autoFocus autoComplete="new-password" maxLength={50} name='txt_first_name' value={this.state.subscriber.name.first_name || ''}
                                                                        onChange={(e, { value }) => { this.on_subscriber_form_change(e, { name: "first_name", value: value, is_dropdown: false, is_obj_on_another_hirerchy: true, obj_hierarch_name: "name" }) }} id="subscriber_first_name_txt" disabled={this.state.page_state === enum_case_page_state.edit}
                                                                        error={this.state.subscriber_form_invalid.name.first_name && this.state.form_submited}
                                                                        className={''}
                                                                    />
                                                                    <p className={this.state.subscriber_form_invalid.name.first_name && this.state.form_submited ? "required-place-bottom req-alert" : 'required-place-bottom'}><i>{case_messages.required_for_billing_text} </i></p>
                                                                </Form.Field>
                                                            </Grid.Column>
                                                            <Grid.Column mobile={8} tablet={8} computer={3}>
                                                                <Form.Field >
                                                                    <label>MI</label>
                                                                    <Form.Input fluid autoComplete="new-password" maxLength={1} name='txt_middle_initial' value={this.state.subscriber.name.middle_initial ? this.state.subscriber.name.middle_initial.trim() : ''}
                                                                        onChange={
                                                                            (e, { value }) => { this.on_subscriber_form_change(e, { name: "middle_initial", value: value, is_dropdown: false, is_obj_on_another_hirerchy: true, obj_hierarch_name: "name" }) }
                                                                        } id="subscriber_mi_txt" disabled={this.state.page_state === enum_case_page_state.edit}
                                                                    />
                                                                </Form.Field>
                                                            </Grid.Column>
                                                            <Grid.Column mobile={8} tablet={8} computer={5}>
                                                                <Form.Field >
                                                                    <label>Last Name <span className={this.state.subscriber_form_invalid.name.last_name && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span></label>
                                                                    <Form.Input fluid autoComplete="new-password" maxLength={50} name='txt_last_name' value={this.state.subscriber.name.last_name || ''}
                                                                        onChange={
                                                                            (e, { value }) => { this.on_subscriber_form_change(e, { name: "last_name", value: value, is_dropdown: false, is_obj_on_another_hirerchy: true, obj_hierarch_name: "name" }) }
                                                                        } id="subscriber_last_name_txt" disabled={this.state.page_state === enum_case_page_state.edit}
                                                                        error={this.state.subscriber_form_invalid.name.last_name && this.state.form_submited}
                                                                        className={''}
                                                                    />
                                                                    <p className={this.state.subscriber_form_invalid.name.last_name && this.state.form_submited ? "required-place-bottom req-alert" : 'required-place-bottom'}><i>{case_messages.required_for_billing_text} </i></p>
                                                                </Form.Field>
                                                            </Grid.Column>
                                                            <Grid.Column mobile={8} tablet={8} computer={3}>
                                                                <Form.Field >
                                                                    <label>Suffix</label>
                                                                    <Form.Input fluid autoComplete="new-password" maxLength={3} name='txt_suffix' value={this.state.subscriber.name.suffix || ''}
                                                                        onChange={
                                                                            (e, { value }) => { this.on_subscriber_form_change(e, { name: "suffix", value: value, is_dropdown: false, is_obj_on_another_hirerchy: true, obj_hierarch_name: "name" }) }
                                                                        } id="subscriber_suffix_txt" disabled={this.state.page_state === enum_case_page_state.edit}
                                                                    />
                                                                </Form.Field>
                                                            </Grid.Column>
                                                        </Grid>
                                                        <Grid>
                                                            <Grid.Column>
                                                                <Form.Field >
                                                                    <label>Address 1 <span className={this.state.subscriber_form_invalid.address.address1 && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span></label>
                                                                    <Form.Input autoComplete="new-password" maxLength={50} name='txt_address1' value={this.state.subscriber.address.address1 || ''}
                                                                        onChange={
                                                                            (e, { value }) => { this.on_subscriber_form_change(e, { name: "address1", value: value, is_dropdown: false, is_obj_on_another_hirerchy: true, obj_hierarch_name: "address" }) }
                                                                        } id="subscriber_address1_txt" disabled={this.state.page_state === enum_case_page_state.edit}
                                                                        error={this.state.subscriber_form_invalid.address.address1 && this.state.form_submited} />
                                                                    <p className={this.state.subscriber_form_invalid.address.address1 && this.state.form_submited ? "required-place-bottom req-alert" : 'required-place-bottom'}><i>{case_messages.required_for_billing_text} </i></p>
                                                                </Form.Field>
                                                            </Grid.Column>
                                                        </Grid>
                                                        <Grid>
                                                            <Grid.Column>
                                                                <Form.Field >
                                                                    <label>Address 2</label>
                                                                    <Form.Input autoComplete="new-password" maxLength={50} name='txt_address2' value={this.state.subscriber.address.address2 || ''}
                                                                        onChange={
                                                                            (e, { value }) => { this.on_subscriber_form_change(e, { name: "address2", value: value, is_dropdown: false, is_obj_on_another_hirerchy: true, obj_hierarch_name: "address" }) }
                                                                        } id="subscriber_address2_txt" disabled={this.state.page_state === enum_case_page_state.edit}
                                                                    />
                                                                </Form.Field>
                                                            </Grid.Column>
                                                        </Grid>
                                                        <Grid>
                                                            <Grid.Column mobile={8} tablet={8} computer={8}>
                                                                <Form.Field >
                                                                    <label>City <span className={this.state.subscriber_form_invalid.address.city && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span></label>
                                                                    <Form.Input autoComplete="new-password" maxLength={30} name='txt_city' value={this.state.subscriber.address.city || ''}
                                                                        onChange={
                                                                            (e, { value }) => { this.on_subscriber_form_change(e, { name: "city", value: value, is_dropdown: false, is_obj_on_another_hirerchy: true, obj_hierarch_name: "address" }) }
                                                                        } id="subscriber_city_txt" disabled={this.state.page_state === enum_case_page_state.edit}
                                                                        error={this.state.subscriber_form_invalid.address.city && this.state.form_submited} />
                                                                    <p className={this.state.subscriber_form_invalid.address.city && this.state.form_submited ? "required-place-bottom req-alert" : 'required-place-bottom'}><i>{case_messages.required_for_billing_text} </i></p>
                                                                </Form.Field>
                                                            </Grid.Column>
                                                            <Grid.Column mobile={8} tablet={8} computer={8}>
                                                                <Form.Field >
                                                                    <label>Country <span className="req-alert_normal">(required)</span></label>
                                                                    {
                                                                        <SelectionComponent
                                                                            name='subscriber_country'
                                                                            id="subscriber_country_txt"
                                                                            options={this.state.master_data.country}
                                                                            onChange={this.on_subscriber_form_change_for_country_id}
                                                                            defaultValue={this.state.subscriber.address.country_id || ''}
                                                                            style={this.state.subscriber_form_invalid.address.country_id && this.state.form_submited ? "red-error-thin" : ''}
                                                                            //isRequired={this.state.error.state ? true : false}
                                                                            disabled={this.state.page_state === enum_case_page_state.edit}
                                                                        />
                                                                        //<Dropdown fluid placeholder='Select' selection options={this.state.master_data.country} value={this.state.subscriber.address.country_id}
                                                                        //    onChange={(e, { value }) => { this.on_subscriber_form_change(e, { name: "country_id", value: value, is_dropdown: true, is_obj_on_another_hirerchy: true, obj_hierarch_name: "address" }) }}
                                                                        //    id="subscriber_country_txt" disabled={this.state.page_state === enum_case_page_state.edit}
                                                                        //    className={this.state.subscriber_form_invalid.address.country_id && this.state.form_submited ? "red-error-thin" : ''}
                                                                        ///>
                                                                    }
                                                                    <p className={this.state.subscriber_form_invalid.address.country_id && this.state.form_submited ? "required-place-dropdown req-alert" : 'required-place-dropdown'}><i>{case_messages.required_for_billing_text} </i></p>
                                                                </Form.Field>
                                                            </Grid.Column>
                                                        </Grid>
                                                        <Grid>
                                                            <Grid.Column style={this.state.subscriber.is_us_selected ? { display: 'block' } : { display: 'none' }} mobile={8} tablet={8} computer={8}>
                                                                <Form.Field >
                                                                    <label>State <span className="req-alert_normal">(required)</span></label>
                                                                    {
                                                                        //<Dropdown placeholder='Select' fluid selection options={this.state.master_data.states} value={this.state.subscriber.address.state_id}
                                                                        //    onChange={(e, { value }) => { this.on_subscriber_form_change(e, { name: "state_id", value: value, is_dropdown: true, is_obj_on_another_hirerchy: true, obj_hierarch_name: "address" }) }}
                                                                        //    id="subscriber_state_txt" disabled={this.state.page_state === enum_case_page_state.edit}
                                                                        //    className={this.state.subscriber_form_invalid.address.state_id && this.state.form_submited ? 'red-error-thin' : ''} />
                                                                    }
                                                                    <SelectionComponent
                                                                        name='subscriber_state'
                                                                        id="subscriber_state_txt"
                                                                        placeHolder='Select'
                                                                        options={this.state.master_data.states}
                                                                        onChange={this.on_subscriber_form_change_for_state_id}
                                                                        defaultValue={this.state.subscriber.address.state_id || ''}
                                                                        style={this.state.subscriber_form_invalid.address.state_id && this.state.form_submited ? 'red-error-thin' : ''}
                                                                        //isRequired={this.state.error.state ? true : false}
                                                                        disabled={this.state.page_state === enum_case_page_state.edit}
                                                                        hidden={true}
                                                                    />
                                                                    <p className={this.state.subscriber_form_invalid.address.state_id && this.state.form_submited ? "required-place-dropdown req-alert" : 'required-place-dropdown'}><i>{case_messages.required_for_billing_text} </i></p>
                                                                </Form.Field>
                                                            </Grid.Column>
                                                            <Grid.Column style={this.state.subscriber.is_us_selected ? { display: 'block' } : { display: 'none' }} mobile={8} tablet={8} computer={8}>
                                                                <Form.Field >
                                                                    <label>ZIP Code <span className={this.state.subscriber_form_invalid.address.zip && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span></label>
                                                                    <Form.Input autoComplete="new-password" pattern="[0-9]*" maxLength={10} name='txt_zip' value={this.state.subscriber.address.zip || ''}
                                                                        onChange={(e, { value }) => { this.on_subscriber_form_change(e, { name: "zip", value: value, is_dropdown: false, is_obj_on_another_hirerchy: true, obj_hierarch_name: "address" }) }}
                                                                        id="subscriber_zip_txt" disabled={this.state.page_state === enum_case_page_state.edit}
                                                                        error={this.state.subscriber_form_invalid.address.zip && this.state.form_submited} />
                                                                    <p className={this.state.subscriber_form_invalid.address.zip && this.state.form_submited ? "required-place-bottom req-alert" : 'required-place-bottom'}><i>{this.state.subscriber_form_invalid.address.zip && this.state.form_submited
                                                                        && this.state.subscriber.address.unformat_zip && this.state.subscriber.address.unformat_zip != null && this.state.subscriber.address.unformat_zip.length > 0
                                                                        && this.state.subscriber.address.unformat_zip.length != 5 && this.state.subscriber.address.unformat_zip.length != 9
                                                                        ? global_constants.constants.zip_compulsory : case_messages.required_for_billing_text}
                                                                    </i></p>

                                                                </Form.Field>
                                                            </Grid.Column>
                                                        </Grid>
                                                        <Grid>
                                                            <Grid.Column style={this.state.subscriber.is_us_selected ? { display: 'none' } : { display: 'block' }} mobile={8} tablet={8} computer={8}>
                                                                <Form.Field >
                                                                    <label>Province </label>
                                                                    <Form.Input autoComplete="new-password" maxLength={20} pattern="/^[a-zA-Z0-9 ]/" name='txt_province' value={this.state.subscriber.address.province || ''}
                                                                        onChange={(e, { value }) => { this.on_subscriber_form_change(e, { name: "province", value: value, is_dropdown: false, is_obj_on_another_hirerchy: true, obj_hierarch_name: "address" }) }}
                                                                        id="subscriber_province_txt" disabled={this.state.page_state === enum_case_page_state.edit}
                                                                        error={this.state.subscriber_form_invalid.address.province && this.state.form_submited} />
                                                                </Form.Field>
                                                            </Grid.Column>
                                                            <Grid.Column style={this.state.subscriber.is_us_selected ? { display: 'none' } : { display: 'block' }} mobile={8} tablet={8} computer={8}>
                                                                <Form.Field >
                                                                    <label>Postal Code </label>
                                                                    <Form.Input autoComplete="new-password" pattern="/^[a-zA-Z0-9 ]/" maxLength={20} name='txt_postal_code' value={this.state.subscriber.address.postal_code || ''}
                                                                        onChange={(e, { value }) => { this.on_subscriber_form_change(e, { name: "postal_code", value: value, is_dropdown: false, is_obj_on_another_hirerchy: true, obj_hierarch_name: "address" }) }}
                                                                        id="subscriber_postal_code_txt" disabled={this.state.page_state === enum_case_page_state.edit}
                                                                        error={this.state.subscriber_form_invalid.address.postal_code && this.state.form_submited} />
                                                                </Form.Field>
                                                            </Grid.Column>
                                                        </Grid>
                                                        <Grid>
                                                            <Grid.Column mobile={8} tablet={8} computer={5}>
                                                                <Form.Field >
                                                                    <label>Phone</label>
                                                                    <MaskedInput
                                                                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                                        className={this.state.subscriber_form_invalid.phone && this.state.form_submited ? "red-error-thin" : ''}
                                                                        placeholder="(___) ___-____"
                                                                        guide={true}
                                                                        id="patient-home-phone"
                                                                        name="txt_phone"
                                                                        autoComplete="new-password"
                                                                        showMask={true}
                                                                        onChange={this.on_subscriber_form_change}

                                                                        value={this.state.subscriber.phone || ''}
                                                                        disabled={this.state.page_state === enum_case_page_state.edit}
                                                                    />
                                                                    <div className="requiredText"><i>{this.state.subscriber_form_invalid.phone && this.state.form_submited &&
                                                                        this.state.subscriber.phone && this.state.subscriber.phone.length != 10
                                                                        && this.state.subscriber.phone.length > 0 ? global_constants.constants.ten_digits_compulsory : ''}</i></div>

                                                                </Form.Field >
                                                            </Grid.Column>
                                                            <Grid.Column mobile={8} tablet={8} computer={5}>
                                                                <Form.Field>
                                                                    <label>SSN </label>
                                                                    <MaskedInput
                                                                        mask={[/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                                        className={this.state.subscriber_form_invalid.ssn && this.state.form_submited ? "red-error-thin" : ''}
                                                                        placeholder="___-__-____"
                                                                        guide={true}
                                                                        id="patient-ssn"
                                                                        autoComplete="new-password"
                                                                        name="txt_ssn"
                                                                        onChange={this.on_subscriber_form_change}
                                                                        disabled={this.state.page_state === enum_case_page_state.edit}
                                                                        value={this.state.subscriber.ssn || ''}
                                                                        showMask={true}
                                                                    />
                                                                    <div className="requiredText"><i>{this.state.subscriber_form_invalid.ssn && this.state.form_submited && this.state.subscriber.ssn && this.state.subscriber.ssn.length != 9
                                                                        && this.state.subscriber.ssn.length > 0 ? global_constants.constants.ssn_compulsory : ''}</i></div>

                                                                </Form.Field>
                                                            </Grid.Column>
                                                            <GridColumn mobile={8} tablet={8} computer={6}>
                                                                <Form.Field >
                                                                    <label>Date of Birth <span className={this.state.subscriber_form_invalid.formatted_date_of_birth && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span></label>

                                                                    <DatePicker is_disabled={this.state.page_state === enum_case_page_state.edit} id="txt_date_of_birth" maxDate={moment().format('MM/DD/YYYY')} date_update={this.handle_calendar_change} date={this.state.subscriber.formatted_date_of_birth} is_required={this.state.subscriber_form_invalid.formatted_date_of_birth && this.state.form_submited} />
                                                                    <p className={this.state.subscriber_form_invalid.formatted_date_of_birth && this.state.form_submited ? "required-place-dropdown req-alert" : 'required-place-dropdown'}><i>{case_messages.required_for_billing_text} </i></p>
                                                                </Form.Field>
                                                            </GridColumn>
                                                        </Grid>
                                                        <Grid>
                                                            <Grid.Column mobile={8} tablet={8} computer={8}>
                                                                <Form.Field >
                                                                    <label>Relationship <span className={this.state.subscriber_form_invalid.relationship && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span></label>
                                                                    {
                                                                        <SelectionComponent
                                                                            name='relationship'
                                                                            id="txt_relationship"
                                                                            placeHolder='Select'
                                                                            options={this.state.master_data.subscriber_relationship}
                                                                            onChange={this.on_subscriber_form_change_for_relationship}
                                                                            defaultValue={this.state.subscriber.relationship || ''}
                                                                            style={this.state.subscriber_form_invalid.relationship && this.state.form_submited ? "req-background-inp" : ''}
                                                                            //isRequired={this.state.error.state ? true : false}
                                                                            disabled={this.state.page_state === enum_case_page_state.edit}
                                                                            hidden={true}

                                                                        />
                                                                        //<Dropdown placeholder='Select' fluid selection upward options={this.state.master_data.subscriber_relationship} value={parseInt(this.state.subscriber.relationship)}
                                                                        //    className={this.state.subscriber_form_invalid.relationship && this.state.form_submited ? "req-background-inp" : ''} id="txt_relationship"
                                                                        //    onChange={(e, { value }) => { this.on_subscriber_form_change(e, { name: "relationship", value: value, is_dropdown: true, is_obj_on_another_hirerchy: false, obj_hierarch_name: "address" }) }}
                                                                        //    disabled={this.state.page_state === enum_case_page_state.edit}
                                                                        ///>
                                                                    }
                                                                    <p className={this.state.subscriber_form_invalid.relationship && this.state.form_submited ? "required-place-dropdown req-alert" : 'required-place-dropdown'}><i>{case_messages.required_for_billing_text} </i></p>
                                                                </Form.Field>
                                                            </Grid.Column>
                                                            <Grid.Column mobile={8} tablet={8} computer={8}>
                                                                <Form.Field >
                                                                    <label>Gender <span className="req-alert_normal">(required)</span></label>
                                                                    {
                                                                        <SelectionComponent
                                                                            name='gender'
                                                                            id="txt_gender"
                                                                            placeHolder='Select'
                                                                            options={this.state.master_data.gender}
                                                                            onChange={this.on_subscriber_form_change_for_gender}
                                                                            defaultValue={this.state.subscriber.gender || ''}
                                                                            style={this.state.subscriber_form_invalid.gender && this.state.form_submited ? "req-background-inp" : ''}
                                                                            //isRequired={this.state.error.state ? true : false}
                                                                            disabled={this.state.page_state === enum_case_page_state.edit}
                                                                            hidden={true}
                                                                        />

                                                                        //<Dropdown placeholder='Select' fluid selection upward options={this.state.master_data.gender} value={this.state.subscriber.gender}
                                                                        //    className={this.state.subscriber_form_invalid.gender && this.state.form_submited ? "req-background-inp" : ''} id="txt_gender"
                                                                        //    onChange={(e, { value }) => { this.on_subscriber_form_change(e, { name: "gender", value: value, is_dropdown: true, is_obj_on_another_hirerchy: false, obj_hierarch_name: "address" }) }}
                                                                        //    disabled={this.state.page_state === enum_case_page_state.edit}
                                                                        ///>
                                                                    }
                                                                    <p className={this.state.subscriber_form_invalid.gender && this.state.form_submited ? "required-place-dropdown req-alert" : 'required-place-dropdown'}><i>{case_messages.required_for_billing_text} </i></p>
                                                                </Form.Field>
                                                            </Grid.Column>

                                                        </Grid>

                                                    </Grid.Column>


                                                    <Grid.Column mobile={16} tablet={16} computer={7} className="padding-zero">
                                                        <Grid>
                                                            <Grid.Column mobile={16} tablet={16} computer={16}>
                                                                <p className="mar-t-15">
                                                                    Subscriber's Employer    </p>
                                                            </Grid.Column>
                                                        </Grid>
                                                        <Grid>
                                                            <GridColumn mobile={16} tablet={16} computer={16}>
                                                                <Form.Field >
                                                                    < label > Name   <span className={this.state.control_flags.is_worker_comp && this.state.subscriber_form_invalid.employee_name && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span>  </label>
                                                                    <Form.Input autoComplete="new-password" maxLength={50} name='txt_employee_name' value={this.state.subscriber.employee_name || ''}
                                                                        onChange={
                                                                            e => { this.on_subscriber_form_change(e) }
                                                                        } id="subscriber_employee_name_txt" disabled={this.state.page_state === enum_case_page_state.edit}
                                                                        className={this.state.control_flags.is_worker_comp && this.state.subscriber_form_invalid.employee_name && this.state.form_submited ? "req-border-inp" : ''}
                                                                        error={this.state.control_flags.is_worker_comp && this.state.subscriber_form_invalid.employee_name && this.state.form_submited}
                                                                    />
                                                                    <p className={this.state.control_flags.is_worker_comp && this.state.subscriber_form_invalid.employee_name && this.state.form_submited ? "required-place-bottom req-alert" : 'required-place-bottom'}><i>{case_messages.required_for_worker_compensation} </i></p>

                                                                </Form.Field>
                                                            </GridColumn>
                                                        </Grid>
                                                        <Grid>
                                                            <Grid.Column>
                                                                <Form.Field >
                                                                    <label>Address 1</label>
                                                                    <Form.Input autoComplete="new-password" maxLength={50} name='txt_employee_address1' value={this.state.subscriber.employee_address.address1 || ''}
                                                                        onChange={(e, { value }) => { this.on_subscriber_form_change(e, { name: "address1", value: value, is_dropdown: false, is_obj_on_another_hirerchy: true, obj_hierarch_name: "employee_address" }) }}
                                                                        id="employee_address1_txt" disabled={this.state.page_state === enum_case_page_state.edit}
                                                                    />
                                                                </Form.Field>
                                                            </Grid.Column>
                                                        </Grid>
                                                        <Grid>
                                                            <Grid.Column>
                                                                <Form.Field >
                                                                    <label>Address 2</label>
                                                                    <Form.Input autoComplete="new-password" maxLength={50} name='txt_employee_address2' value={this.state.subscriber.employee_address.address2 || ''}
                                                                        onChange={(e, { value }) => { this.on_subscriber_form_change(e, { name: "address2", value: value, is_dropdown: false, is_obj_on_another_hirerchy: true, obj_hierarch_name: "employee_address" }) }}
                                                                        id="subscriber_employee_address2_txt" disabled={this.state.page_state === enum_case_page_state.edit}
                                                                    />
                                                                </Form.Field>
                                                            </Grid.Column>
                                                        </Grid>
                                                        <Grid>
                                                            <Grid.Column mobile={8} tablet={8} computer={8}>
                                                                <Form.Field >
                                                                    <label>City </label>
                                                                    <Form.Input autoComplete="new-password" maxLength={30} name='employee_address_city' value={this.state.subscriber.employee_address.city || ''}
                                                                        onChange={(e, { value }) => { this.on_subscriber_form_change(e, { name: "city", value: value, is_dropdown: false, is_obj_on_another_hirerchy: true, obj_hierarch_name: "employee_address" }) }}
                                                                        id="employee_address_city_txt" disabled={this.state.page_state === enum_case_page_state.edit}
                                                                    />
                                                                </Form.Field>
                                                            </Grid.Column>
                                                            <Grid.Column mobile={8} tablet={8} computer={8}>
                                                                <Form.Field >
                                                                    <label>State </label>
                                                                    {
                                                                        <SelectionComponent
                                                                            name='employee_state'
                                                                            id="txt_employee_state"
                                                                            placeHolder='Select'
                                                                            hidden={true}
                                                                            options={this.state.master_data.states}
                                                                            onChange={this.on_subscriber_form_change_for_employee_state}
                                                                            defaultValue={this.state.subscriber.employee_address.state_id || ''}
                                                                            //style={this.state.subscriber_form_invalid.gender && this.state.form_submited ? "req-background-inp" : ''}
                                                                            //isRequired={this.state.error.state ? true : false}
                                                                            disabled={this.state.page_state === enum_case_page_state.edit}
                                                                        />
                                                                        //<Dropdown fluid selection options={this.state.master_data.states} value={this.state.subscriber.employee_address.state_id}
                                                                        //    onChange={(e, { value }) => { this.on_subscriber_form_change(e, { name: "state_id", value: value, is_dropdown: true, is_obj_on_another_hirerchy: true, obj_hierarch_name: "employee_address" }) }}
                                                                        //    id="employee_address_state_txt" disabled={this.state.page_state === enum_case_page_state.edit}
                                                                        ///>
                                                                    }
                                                                </Form.Field>
                                                            </Grid.Column>
                                                        </Grid>
                                                        <Grid>
                                                            <Grid.Column mobile={8} tablet={8} computer={8}>
                                                                <Form.Field >
                                                                    <label>ZIP Code </label>
                                                                    <Form.Input autoComplete="new-password" pattern="[0-9]*" maxLength={10} name='txt_employee_zip' value={this.state.subscriber.employee_address.zip || ''}
                                                                        onChange={(e, { value }) => { this.on_subscriber_form_change(e, { name: "zip", value: value, is_dropdown: false, is_obj_on_another_hirerchy: true, obj_hierarch_name: "employee_address" }) }}
                                                                        id="subscriber_employee_zip_txt" disabled={this.state.page_state === enum_case_page_state.edit}
                                                                        error={this.state.subscriber_form_invalid.employee_address.zip && this.state.form_submited} />
                                                                    <div className="requiredText"><i>{this.state.subscriber_form_invalid.employee_address.zip && this.state.form_submited
                                                                        && this.state.subscriber.employee_address.unformat_zip && this.state.subscriber.employee_address.unformat_zip != null && (this.state.subscriber.employee_address.unformat_zip.length > 0
                                                                            && (this.state.subscriber.employee_address.unformat_zip.length != 5) || (this.state.subscriber.employee_address.unformat_zip.length != 9))
                                                                        ? global_constants.constants.zip_compulsory : ''}
                                                                    </i></div>
                                                                </Form.Field>
                                                            </Grid.Column>
                                                            <Grid.Column mobile={8} tablet={8} computer={8}>
                                                                <Form.Field >
                                                                    <label>Phone</label>
                                                                    <MaskedInput
                                                                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                                        className={this.state.subscriber_form_invalid.employee_phone && this.state.form_submited ? "red-error-thin" : ''}
                                                                        placeholder="(___) ___-____"
                                                                        guide={true}
                                                                        id="employee-home-phone"
                                                                        name="txt_employee_phone"
                                                                        autoComplete="new-password"
                                                                        showMask={true}
                                                                        onChange={this.on_subscriber_form_change}
                                                                        disabled={this.state.page_state === enum_case_page_state.edit}
                                                                        value={this.state.subscriber.employee_phone || ''}

                                                                    />
                                                                    <div className="requiredText"><i>{this.state.subscriber_form_invalid.employee_phone && this.state.form_submited &&
                                                                        this.state.subscriber.employee_phone && this.state.subscriber.employee_phone.length != 10
                                                                        && this.state.subscriber.employee_phone.length > 0 ? global_constants.constants.ten_digits_compulsory : ''}</i></div>

                                                                </Form.Field>
                                                            </Grid.Column>
                                                        </Grid>
                                                        <Grid>
                                                            <Grid.Column mobile={8} tablet={8} computer={16}>
                                                                <Form.Field >
                                                                    <Checkbox label='Subscriber Active' name='txt_is_active' checked={this.state.subscriber.is_active || false}
                                                                        onChange={this.on_change_subscriber_active} disabled={this.state.page_state === enum_case_page_state.edit} />
                                                                </Form.Field>
                                                            </Grid.Column>
                                                        </Grid>
                                                    </Grid.Column>
                                                </Grid>
                                            </Grid.Column>
                                            {
                                                <InsurancePreauthorizationComponent onRef={this.initialize_insurance_preauthorization_reference} case_id={this.state.case_id} subscriber_fetched_time={this.state.subscriber_fetched_time} subscriber_data={this.state.subscriber}
                                                    page_state={this.state.page_state} insurance_type={this.props.ins_type} form_submitted={this.state.form_submited} />
                                            }
                                            {
                                                <InsuranceEligibilityComponent onRef={this.initialize_insurance_eligibility_reference} case_id={this.state.case_id} subscriber_data={this.state.subscriber} subscriber_fetched_time={this.state.subscriber_fetched_time}
                                                    page_state={this.state.page_state} insurance_type={this.props.ins_type} form_submitted={this.state.form_submited} />
                                            }

                                            {
                                                this.props.ins_type === enum_type_of_insurance.primary_insurance ?
                                                    <InsuranceDoctorsOrdersComponent onRef={this.initialize_insurance_doctors_orders_reference} case_id={this.state.case_id} subscriber_data={this.state.subscriber}
                                                        page_state={this.state.page_state} insurance_type={this.props.ins_type} form_submitted={this.state.form_submited} doctors_fetch_time={this.state.doctors_fetch_time} />
                                                    : ""
                                            }

                                        </Grid.Row>
                                        : <Grid.Column className="textCenter">
                                            Coming Soon
                                </Grid.Column>}

                            </div>

                        </div>
                    </div>
                </div >

            </React.Fragment>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        get_patient_details: get_patient_details,

    }, dispatch)
}

const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        user_details: state.user_login_details,
        patient_details: state.patient_details,
        features: {
            is_payment_posting_feedback_enabled: get_lauch_darkley_key_value(state.launch_darkly, feature_flags.payment_posting_feedback)
        },
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InsuranceComponent));