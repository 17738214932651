import { reducer as toastr_reducer } from 'react-redux-toastr';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { reducer as form_reducer } from 'redux-form';
import { persistReducer, persistStore } from 'redux-persist';
import createCompressor from './shared/redux-persist-transform-compress';
import storage from 'redux-persist/lib/storage'; // defaults to local Storage for web and AsyncStorage for react-native
import thunk from 'redux-thunk';
import login_reducer from '../app/login/reducer/login_reducer';
import patient_reducer from '../app/patient/reducer/patient_reducer';
import payment_reducer from '../app/payment/reducer/payment_reducer';
import payment_adjustment_reducer from '../app/payment/reducer/adj_qual_and_reason_codes_reducer';
import report_reducer from '../app/reports/reducer/report_reducer';
import advanced_control_reducer from '../app/shared/reducer/advanced_control_reducer';
import persist_reducer from '../app/shared/reducer/persist_reducer';
import shared_reducer from '../app/shared/reducer/shared_reducer';
import user_reducer from '../app/user/reducer/user_reducer';
import batch_payment_reducer from '../app/payment/batch_payment/reducer/batch_payment_reducer';
import constants_reducer from '../app/admin/constants/reducer/constants_reducer';
import billing_reducer from '../app/billing/reducer/billing_reducer';
import { generate_guid, detect_ie, get_sso_token_value_from_url } from './shared/utility';
import * as global_constant from './global_constants';
import launch_darkly_reducer from './shared/launch_darkley/reducer/launch_darkly_reducer';

// Combine all reducer to one place i.e - store
const all_reducers = combineReducers({
    user_login_details: login_reducer,
    patient_details: patient_reducer,  
    shared_details: shared_reducer,
    user_details: user_reducer,
    form: form_reducer,  // Register redux-form in the store
    toastr: toastr_reducer ,// Register react-redux-toastr in the store, it required the same name so do not change name i.e. toastr
    advanced_control_details: advanced_control_reducer,
    persisted_details: persist_reducer,// Persisted store i.e :- Stored in local storage
    payment_details: payment_reducer,
    report_details: report_reducer,
    launch_darkly: launch_darkly_reducer, 
    batch_payment_reference: batch_payment_reducer,
    constants_reducer_reference: constants_reducer,
    billing_reducer_reference: billing_reducer,
    payment_adjustment: payment_adjustment_reducer
});

try {

    if(window.location.pathname == "/login") {
        let token = get_sso_token_value_from_url(window.location.href, global_constant.sso_config_data.sso_login_token_key);    

        if(token) {
            sessionStorage.removeItem("sessionId");
            sessionStorage.removeItem("tabId");
        }
    }

    if (!sessionStorage.getItem('sessionId') || sessionStorage.getItem('sessionId') == "undefined") {
        sessionStorage.setItem('sessionId', generate_guid());
    }

    // if tab id is not present in the session then create one
    // also store this in localStorage to let other sibling tabs to detect
    if (!sessionStorage.getItem('tabId') || sessionStorage.getItem('tabId') == "undefined") {
        let tabId = generate_guid();
        sessionStorage.setItem('tabId', tabId);
        localStorage.setItem('tabId' + tabId, sessionStorage.getItem('sessionId'));

        // for all IE browsers we are deleting tabid cookie
        if (detect_ie()) {
            var name = 'tabId' + tabId;
            document.cookie = name + "=" + sessionStorage.getItem('sessionId') + 0 + "; path=/";
        }
    }
} catch (e) {

    // this will be called in the case of new tab when
    // manipulation in sessionStorage is not accessed by sibling tab
    // thus we are reloading the tab to access the session altered by sibling tab
    // when new tab function is called.
    location.reload();
}

const compressor = createCompressor({ whitelist: ['user_login_details', 'persisted_details'] }); // or
const persist_config = {
    key: 'root' + sessionStorage.getItem('sessionId'),
    storage,
    // only include reducer which needs to be stored in local storage/persisted
    whitelist: ['user_login_details', 'persisted_details'],
    transforms: [compressor]
}

// To persist data(usr details, token , etc) in the local storage. Persisting data prevents data loss on refresh
const persistedReducer = persistReducer(persist_config, all_reducers);

// Thunk is a middleware for return a promise
const create_store_with_middleware = applyMiddleware(thunk)(createStore);

const store = create_store_with_middleware(persistedReducer);

export default () => {    
    const persistor = persistStore(store);
    return { store, persistor }
}

export const _store_ = store;
