import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { toastr as toaster } from 'react-redux-toastr';
import { Button, Dimmer, Form, Grid, Loader } from 'semantic-ui-react';
import AdvancedControl from '../../shared/component/advanced_control';
import AutoSearchComponent from '../../shared/component/auto_search_component';
import DatePicker from '../../shared/component/date_picker_component';
import Selection from '../../shared/component/selection_component';
import CheckboxComponent from '../../shared/component/checkbox_component';
import {
  get_companies,
  lookup_companies,
  create_patient_statement,
  reset_patient_statement,
  batch_options
} from '../action/patient_statement_action';
import {
  prepare_month_options,
  prepare_year_options,
  get_previous_month,
  get_dropdown_default_year,
  requestPatientStatements
} from '../billing_constants';
import * as billing_constants from '../billing_constants';
import { downloadTxtFromString } from '../../reports/util/export_print_utility';
import * as local_storage from '../../shared/local_storage_utility';
import * as global_constants from '../../global_constants';
import { get_all_error } from '../../shared/utility';
import { check_company_ff } from '../../company/action/company_action';
import RequestPatientStatementsComponent from './request_patient_statements_component';
import { get_lauch_darkley_key_value as get_launch_darkly_key_value } from '../../shared/utility';

class PatientStatementsComponent extends React.Component<any, any> {
  // constants for patient statements
  CURRENT_DATE = moment();
  CURRENT_YEAR = moment().format('YYYY');
  DROPDOWN_YEAR = get_dropdown_default_year();
  CURRENT_MONTH = moment().month() + 1;
  PREV_MONTH = get_previous_month();
  START_YEAR = 2002;
  ERROR_FILE_GENERATION = 'Patient statement file could not be generated. Please try again.';
  RESET_AVAILABLE_COMPANIES_ENUM = 'RESET_AVAILABLE_COMPANIES';
  RUN_AVAILABLE_COMPANIES_ENUM = 'RUN_AVAILABLE_COMPANIES';
  ERROR_IN_RESET_FF_ON = 'Patient Statement batch file could not be reset. Please try again.';
  ERROR_IN_RESET_FF_OFF = 'Selected company could not be reset. Please try again.';
  RESET_SUCCESSFUL_FF_ON = 'Alright! Batch file reset.';
  RESET_SUCCESSFUL_FF_OFF = 'Company successfully removed from the list.';
  RUN_SUCCESSFUL_FF_ON =  'Nice! Batch file generated.';
  RUN_SUCCESSFUL_FF_OFF =  'Patient statement file generated successfully.';

  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      show_run_card: false,
      show_reset_card: false,
      statement_date: this.CURRENT_DATE,
      message_date: this.CURRENT_DATE,
      run_card_selected_month: this.PREV_MONTH,
      run_card_selected_year: this.DROPDOWN_YEAR,
      run_card_selected_company: {},
      run_card_selected_batch: '',
      reset_card_selected_company: {},
      reset_card_selected_month: this.PREV_MONTH,
      reset_card_selected_year: this.DROPDOWN_YEAR,
      reset_card_selected_batch: '',
      run_companies_grid_data: null,
      reset_companies_grid_data: null,
      test_file_selection: false,
      pay_right_format_selection: false,
      is_submitted: false,
      is_reset_submitted: false,
      error: {},
      reset_error: {},
      disable_run_card_batch: true,
      disable_reset_card_batch: true,
      reset_card_batch_options: [],
      run_card_batch_options: [],
      statementBatches: {
        reset: false,
        run: false
      }
    };
  }

  token: any = '';
  company_service_type: any = 0;
  soft_only: any = 0;
  month_options = prepare_month_options();
  year_options = prepare_year_options(this.START_YEAR, this.CURRENT_YEAR);
  _is_mounted = false;
  requestPatientStatementsComponent:any = React.createRef();

  // Fetching the report id and params.
  UNSAFE_componentWillMount = () => {
    var auth_data = local_storage.get('auth_data');
    this.token = this.props.user_login_details.user_data.data.token_details.access_token;
    this.soft_only = auth_data.user_type_id == 1 || auth_data.user_type_id == 2 ? false : true;
  };

  componentDidMount = async () => {
    if (!this.state.show_reset_card) {
      this.setState({
        loading: false,
        show_reset_card: true,
        show_run_card: true
      });
    }

    this._is_mounted = true;
    document.body.classList.add('admin-framework');
    let elem = document.getElementById('search-criteria-container');
    let all_form_elem = elem.getElementsByTagName('input');
    all_form_elem[0].focus();
    this.token = this.props.user_login_details.user_data.data.token_details.access_token;
  };

  prepare_reset_card_companies = (data) => {
    let prepared_data = [];
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        prepared_data.push({
          is_disabled: false,
          value: data[i].value,
          name: data[i].company_code + ' - ' + data[i].company_name,
          on_hover_msg: null
        });
      }
    }

    return prepared_data;
  };

  componentWillUnmount = () => {
    this._is_mounted = false;
    document.body.classList.remove('admin-framework');
  };

  handle_statement_date_change = (dt) => {
    let { error } = this.state;
    let m = moment(dt);
    if (dt != null && m.isValid()) {
      error.statement_date = false;
    } else {
      error.statement_date = true;
    }

    if (this._is_mounted) {
      this.setState({
        statement_date: dt,
        error: error
      });
    }
  };

  handle_message_date_change = (dt) => {
    let { error } = this.state;
    let m = moment(dt);

    if (dt != null && m.isValid()) {
      error.message_date = false;
    } else {
      error.message_date = true;
    }

    if (this._is_mounted) {
      this.setState({
        message_date: dt,
        error: error
      });
    }
  };

  on_month_change_run_card = (value, e) => {
    if (this._is_mounted) {
      this.setState({
        run_card_selected_month: value,
        run_card_selected_company: {},
        disable_run_card_batch: true,
        run_card_batch_options: [],
        statementBatches: {
          ...this.state.statementBatches,
          run: false
        }
      });
    }
  };

  on_year_change_run_card = (value, e) => {
    if (this._is_mounted) {
      this.setState({
        run_card_selected_year: value,
        run_card_selected_company: {},
        disable_run_card_batch: true,
        run_card_batch_options: [],
        statementBatches: {
          ...this.state.statementBatches,
          run: false
        }
      });
    }
  };

  on_month_change_reset_card = (value, e) => {
    if (this._is_mounted) {
      this.setState({
        reset_card_selected_month: value,
        reset_card_selected_company: {},
        disable_reset_card_batch: true,
        reset_card_batch_options: [],
        statementBatches: {
          ...this.state.statementBatches,
          reset: false
        }
      });
    }
  };

  on_year_change_reset_card = (value, e) => {
    if (this._is_mounted) {
      this.setState({
        reset_card_selected_year: value,
        reset_card_selected_company: {},
        disable_reset_card_batch: true,
        reset_card_batch_options: [],
        statementBatches: {
          ...this.state.statementBatches,
          reset: false
        }
      });
    }
  };

  on_advanced_grid_row_select = (item, type) => {
    this.handle_selection_errors(type);
    const prepared_result = {
      title: `${item.value}`,
      code: item.value,
      name: item.text,
      label: item.company_code == null ? item.company_name : item.company_code + ' - ' + item.company_name
    };
    this.on_advanced_search_grid_row_election(prepared_result, type);
  };

  on_advanced_search_grid_row_election = (item, type) => {
    if (this._is_mounted) {
      if (type == this.RUN_AVAILABLE_COMPANIES_ENUM) {
        this.setState(
          {
            run_card_selected_company: item
          },
          () =>
            this.check_company_ff(type, {
              selected_company: this.state.run_card_selected_company,
              selected_month: this.state.run_card_selected_month,
              statement_year: this.state.run_card_selected_year
            })
        );
      } else {
        this.setState(
          {
            reset_card_selected_company: item
          },
          () =>
          this.check_company_ff(type, {
            selected_company: this.state.reset_card_selected_company,
            selected_month: this.state.reset_card_selected_month,
            statement_year: this.state.reset_card_selected_year
          })
        );
      }
    }
  };

  handle_selection_errors = (type) => {
    const { error, reset_error } = this.state;
    if (type == this.RUN_AVAILABLE_COMPANIES_ENUM) {
      error.run_card_selected_company = false;
    }
    if (type == this.RESET_AVAILABLE_COMPANIES_ENUM) {
      reset_error.reset_card_selected_company = false;
    }
    if (this._is_mounted) {
      this.setState({
        error: error,
        reset_error: reset_error
      });
    }
  };

  on_advance_search = async (params, type) => {
    var search_data = null;
    var config_column = billing_constants.patient_statement.company_column_def;
    let new_params = {};
    if (type == this.RUN_AVAILABLE_COMPANIES_ENUM) {
      new_params = {
        company_code: params.code,
        company_name: params.name,
        month: this.state.run_card_selected_month,
        year: this.state.run_card_selected_year,
        show_already_run: false,
        is_software_only: this.soft_only
      };
    }
    if (type == this.RESET_AVAILABLE_COMPANIES_ENUM) {
      new_params = {
        company_code: params.code,
        company_name: params.name,
        month: this.state.reset_card_selected_month,
        year: this.state.reset_card_selected_year,
        show_already_run: true,
        is_software_only: this.soft_only
      };
    }

    search_data = await get_companies(new_params, this.token).then((res) => res.data);
    const search_result = search_data && search_data.data !== null ? search_data.data : [];
    const grid_data = {
      rows: search_result,
      column: config_column,
      messages: search_data.messages,
      paginationPageSize: 20
    };

    if (type == this.RUN_AVAILABLE_COMPANIES_ENUM) {
      this.setState({ run_companies_grid_data: grid_data });
    }
    if (type == this.RESET_AVAILABLE_COMPANIES_ENUM) {
      this.setState({ reset_companies_grid_data: grid_data });
    }
  };

  // get the searched value which is typed for searching location or providers
  get_value = (input, type) => {
    const { error, is_submitted, reset_error } = this.state;

    if (input.trim().length >= 3) {
      if (type == this.RUN_AVAILABLE_COMPANIES_ENUM) {
        this.lookup_for_run_available_companies(input);
      } else {
        this.lookup_for_reset_available_companies(input);
      }
    }

    if (type == this.RUN_AVAILABLE_COMPANIES_ENUM) {
      error.run_card_selected_company = true;
    } else {
      reset_error.reset_card_selected_company = true;
    }

    if (this._is_mounted) {
      if (is_submitted) {
        this.setState({
          error: error,
          reset_error: reset_error
        });
      }
    }
  };

  // clear data on clear icon
  clear_quick_search = (type) => {
    const { error, reset_error } = this.state;
    if (type == this.RUN_AVAILABLE_COMPANIES_ENUM) {
      error.run_card_selected_company = true;
      if (this._is_mounted) {
        this.setState({
          run_card_selected_company: {},
          disable_run_card_batch: true,
          run_card_batch_options: [],
          error: error,
          statementBatches: {
            ...this.state.statementBatches,
            run: false
          }
        });
      }
    } else {
      reset_error.reset_card_selected_company = true;
      if (this._is_mounted) {
        this.setState({
          reset_card_selected_company: {},
          disable_reset_card_batch: true,
          reset_card_batch_options: [],
          reset_error: reset_error,
          statementBatches: {
            ...this.state.statementBatches,
            reset: false
          }
        });
      }
    }
  };

  on_item_selection = (item, type) => {
    this.handle_selection_errors(type);
    this.on_advanced_search_grid_row_election(item, type);
  };

  // prepare the suggestion list with search result
  prepare_suggestion = (data) => {
    let formattedList = [];
    var data_length = data.data.length;
    if (data && data_length) {
      for (let i = 0; i < data_length; i++) {
        let item = data.data[i];
        formattedList.push({
          title: `${item.value}`,
          code: item.value,
          name: item.text,
          label: item.company_code == null ? item.company_name : item.company_code + ' - ' + item.company_name
        });
      }
    }
    return formattedList;
  };

  lookup_for_run_available_companies = async (search_keyword) => {
    let params = {
      keyword: search_keyword,
      month: this.state.run_card_selected_month,
      year: this.state.run_card_selected_year,
      show_already_run: false,
      is_software_only: this.soft_only
    };

    return await lookup_companies(params, this.token);
  };

  lookup_for_reset_available_companies = async (search_keyword) => {
    let params = {
      keyword: search_keyword,
      month: this.state.reset_card_selected_month,
      year: this.state.reset_card_selected_year,
      show_already_run: true,
      is_software_only: this.soft_only
    };

    return await lookup_companies(params, this.token);
  };

  //Auto search for class
  renderResult = (props) => {
    return (
      <div key={props.title} tabIndex={0} className='item_auto_search'>
        <div key={props.title} className='fs_13'>
          <span>{props.label}</span>
        </div>
      </div>
    );
  };

  validate_reset_patient_statement_form = () => {
    const reset_error = { ...this.state.reset_error };
    const { reset_card_selected_company } = this.state;
    reset_error.reset_card_selected_company = reset_card_selected_company.name ? false : true;
    if (this._is_mounted) {
      this.setState({
        reset_error: reset_error,
        is_reset_submitted: true
      });
    }
    if (
      Object.keys(reset_error).some((data) => {
        return reset_error[data] == true;
      })
    ) {
      toaster.error('', global_constants.constants.mandatory_fields);
      return false;
    }
    return true;
  };

  reset_patient_statement = async () => {
    if (!this.validate_reset_patient_statement_form()) return;
    let params: any = {
      month: this.state.reset_card_selected_month,
      year: this.state.reset_card_selected_year,
      values: [this.state.reset_card_selected_company.code]
    };
    this.setState({
      loading: true
    });
    if (this.state.statementBatches.reset) {
      params = {
        ...params,
        batch: this.state.reset_card_selected_batch
      };
      }

    await reset_patient_statement(params, this.token).then(
      (response) => {
        if (response.data && response.data.status == 1) {
          if (response.data.data) {
            if (this.state.statementBatches.reset) {
              toaster.success('', this.RESET_SUCCESSFUL_FF_ON);
            } else {
              toaster.success('', this.RESET_SUCCESSFUL_FF_OFF);
            }
          } else {
            if (this.state.statementBatches.reset) {
              toaster.success('', this.ERROR_IN_RESET_FF_ON);
            } else {
              toaster.success('', this.ERROR_IN_RESET_FF_OFF);
            }
          }

          if (this._is_mounted) {
            this.setState({
              loading: false,
              reset_card_selected_company: {},
              reset_card_selected_batch: '',
              reset_card_batch_options: [],
              disable_reset_card_batch: true,
              run_card_selected_company: {},
              run_card_selected_batch: '',
              run_card_batch_options: [],
              disable_run_card_batch: true,
              statementBatches:{run:false, reset:false}
            });
          }
        } else {
          if (this.state.statementBatches.reset) {
            toaster.error('', this.ERROR_IN_RESET_FF_ON );
          } else {
            toaster.error('', this.ERROR_IN_RESET_FF_OFF);
          }
          if (this._is_mounted) {
            this.setState({
              loading: false,
              reset_card_selected_company: {},
              reset_card_selected_batch: '',
              reset_card_batch_options: [],
              disable_reset_card_batch: true,
              run_card_selected_company: {},
              run_card_selected_batch: '',
              run_card_batch_options: [],
              disable_run_card_batch: true,
              statementBatches:{run:false, reset:false}
            });
          }
        }
      },
      (error) => {
        if (this._is_mounted) {
          this.setState({
            loading: false,
            reset_card_selected_company: {},
            statementBatches:{run:false, reset:false}
          });
        }
        if (this.state.statementBatches.reset) {
          toaster.error('', this.ERROR_IN_RESET_FF_ON );
        } else {
          toaster.error('', this.ERROR_IN_RESET_FF_OFF);
        }
      }
    );
  };

  validate_run_patient_statement_form = () => {
    const error = { ...this.state.error };
    const { run_card_selected_company, statement_date, message_date } = this.state;

    error.run_card_selected_company = run_card_selected_company.name ? false : true;
    error.statement_date = statement_date ? false : true;
    error.message_date = message_date ? false : true;

    if (this._is_mounted) {
      this.setState({
        error: error,
        is_submitted: true
      });
    }
    if (
      Object.keys(error).some((data) => {
        return error[data] == true;
      })
    ) {
      toaster.error('', global_constants.constants.mandatory_fields);
      return false;
    }

    return true;
  };

  run_patient_statement = async () => {
    if (!this.validate_run_patient_statement_form()) return;

    const {
      run_card_selected_company,
      run_card_selected_month,
      run_card_selected_year,
      statement_date,
      message_date,
      test_file_selection,
      pay_right_format_selection
    } = this.state;


    let params: any = {
      company_value: run_card_selected_company.code,
      company_text: run_card_selected_company.name,
      statement_date: statement_date.format('YYYY-MM-DDT00:00:00').toString(),
      message_date: message_date.format('YYYY-MM-DDT00:00:00').toString(),
      month: run_card_selected_month,
      year: run_card_selected_year,
      is_pay_right: pay_right_format_selection,
      is_test_file: test_file_selection
    };

    if (this.state.statementBatches.run) {
      params = {
        ...params,
        batch: this.state.run_card_selected_batch
      };
    }

    this.setState({
      loading: true
    });

    let result = await create_patient_statement(params, this.token).then(
      async (response) => {
        if (response.data.data === 'true') {
          toaster.success('', requestPatientStatements.requestPatientStatementsMessages.messageSuccess);
          await this.requestPatientStatementsComponent.refreshPatientStatementProcess({ companyId: params.company_value.split('`')[0], status: '-1', month: `${params.month}`, year: `${params.year}` })
        } else {
          toaster.error('', requestPatientStatements.requestPatientStatementsMessages.messageFail);
        }
        if (this._is_mounted) {
          this.setState({
            loading: false,
            is_submitted: false,
            run_card_selected_company: {},
            run_card_selected_batch: '',
            run_card_batch_options: [],
            disable_run_card_batch: true,
            reset_card_selected_company: {},
            reset_card_selected_batch: '',
            reset_card_batch_options: [],
            disable_reset_card_batch: true,
            statementBatches:{run:false, reset:false}
          });
        }
      },
      (error) => {
        if (this._is_mounted) {
          this.setState({
            loading: false,
            statementBatches:{run:false, reset:false}
          });
        }
        toaster.error('', this.ERROR_FILE_GENERATION);
      }
    );
  };

  cancel_button_clicked = (e) => {
    if (e.target.id === 'run_cancel_button_id') {
      this.setState({
        statement_date: this.CURRENT_DATE,
        message_date: this.CURRENT_DATE,
        run_card_selected_month: this.PREV_MONTH,
        run_card_selected_year: this.DROPDOWN_YEAR,
        run_card_selected_company: {},
        run_card_selected_batch: '',
        run_card_batch_options: [],
        disable_run_card_batch: true,
        error: {},
        is_submitted: false,
        test_file_selection: false,
        pay_right_format_selection: false,
        statementBatches:{...this.state.statementBatches, run:false}
      });
    } else {
      this.setState({
        reset_card_selected_month: this.PREV_MONTH,
        reset_card_selected_year: this.DROPDOWN_YEAR,
        reset_error: {},
        reset_card_selected_company: {},
        is_reset_submitted: false,
        reset_card_selected_batch: '',
        reset_card_batch_options: [],
        disable_reset_card_batch: true,
        statementBatches:{...this.state.statementBatches, reset:false}
      });
    }
  };

  //A function for formatting a date to MMddyy
  formatDate = (d) => {
    //get the month
    var month = d.getMonth();
    //get the day
    //convert day to string
    var day = d.getDate().toString();
    //get the year
    var year = d.getFullYear();

    //pull the last two digits of the year
    year = year.toString().substr(-2);

    //increment month by 1 since it is 0 indexed
    //converts month to a string
    month = (month + 1).toString();

    //if month is 1-9 pad right with a 0 for two digits
    if (month.length === 1) {
      month = '0' + month;
    }

    //if day is between 1-9 pad right with a 0 for two digits
    if (day.length === 1) {
      day = '0' + day;
    }

    //return the string "MMddyy"
    return month + day + year;
  };

  on_batch_change = (value, e) => {
    if (e.target.id === 'batch_run_program') {
      if (this._is_mounted) {
        this.setState({
          run_card_selected_batch: value
        });
      }
    } else {
      if (this._is_mounted) {
        this.setState({
          reset_card_selected_batch: value
        });
      }
    }
  };

  check_company_ff = async (type, selected_data) => {
    let company_id = 0;
    if (
      selected_data &&
      selected_data.selected_company &&
      selected_data.selected_company.code &&
      selected_data.selected_company.code.split('`') &&
      selected_data.selected_company.code.split('`')[0]
    ) {
      company_id = parseInt(selected_data.selected_company.code.split('`')[0]);
    }
    let params = {
      company_id,
      statement_month: selected_data.selected_month,
      statement_year: selected_data.statement_year,
      is_reset_statement: type == this.RUN_AVAILABLE_COMPANIES_ENUM ? false : true
    };
    await check_company_ff(this.token, {
      ff_name: 'rsi-b31565-statement-batches-designate-number-of-monthly-batches',
      company_id
    }).then((response) => {
      if (response.data && response.data.status == 1) {
        let statementBatches = this.state.statementBatches;
        if (type === this.RUN_AVAILABLE_COMPANIES_ENUM) {
          statementBatches = {
            ...statementBatches,
            run: response.data.data
          };
        } else {
          statementBatches = {
            ...statementBatches,
            reset: response.data.data
          };
        }
        this.setState(
          {
            statementBatches
          },
          () => {
            this.update_batches(type, params);
          }
        );
      }
    });
  }

  update_batches = async (type, params) => {
    if (
      (type === this.RUN_AVAILABLE_COMPANIES_ENUM && !this.state.statementBatches.run) ||
      (type === this.RESET_AVAILABLE_COMPANIES_ENUM && !this.state.statementBatches.reset)
    ) {
      return;
    }

    if (this._is_mounted) {
      this.setState({
        loading: true
      });
    }
    await batch_options(params, this.token).then(
      (response) => {
        if (response.data && response.data.status == 1 && response.data.data) {
          response.data.data = this.option_formatter(response.data.data, 'batch_option', 'batch_option');
          if (this._is_mounted) {
            if (type === this.RUN_AVAILABLE_COMPANIES_ENUM) {
              this.setState({
                loading: false,
                disable_run_card_batch: false,
                run_card_batch_options: [...response.data.data],
                run_card_selected_batch:
                  response.data.data[0] && response.data.data[0].value ? response.data.data[0].value : ''
              });
            } else {
              this.setState({
                loading: false,
                disable_reset_card_batch: false,
                reset_card_batch_options: [...response.data.data],
                reset_card_selected_batch:
                  response.data.data[0] && response.data.data[0].value ? response.data.data[0].value : ''
              });
            }
          }
        } else {
          if (this._is_mounted) {
            this.setState({
              loading: false
            });
          }
        }
      },
      (error) => {
        if (this._is_mounted) {
          this.setState({
            loading: false
          });
        }
        if (error.response.data) {
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error('', toastr_options);
        }
      }
    );
  };

  show_html_content_toaster = (msg) => {
    return {
      component: () => (
        <div>
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        </div>
      ),
      timeOut: global_constants.toastr_options.toastr_time_out,
      preventDuplicates: true
    };
  };

  option_formatter = (array, text, value) => {
    let options = array.map((item) => {
      return {
        ...item,
        text: item[text] ? item[text].replace(/\n/g, ' ') : '',
        value: item[value]
      };
    });
    return options;
  };

  render() {
    const {
      is_submitted,
      reset_error,
      error,
      statement_date,
      message_date,
      run_card_selected_month,
      run_card_selected_year,
      reset_card_selected_month,
      reset_card_selected_year,
      test_file_selection,
      pay_right_format_selection,
      is_reset_submitted,
      run_card_selected_batch,
      reset_card_selected_batch,
      disable_run_card_batch,
      disable_reset_card_batch
    } = this.state;
    return (
      <React.Fragment>
        <div className={'admin-wrapper view-edit'} style={{ paddingBottom: 0 }}>
          <Dimmer active={this.state.loading}>
            <Loader size='massive'>Loading</Loader>
          </Dimmer>
          <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
            <Grid.Column computer={16}>
              <h3 className='ui header left aligned'>{'Patient Statements'}</h3>
            </Grid.Column>
          </Grid>
          <div className='wrapper' style={patient_statement_css.wrapper}>
            <div id='admin-scrollable-area' style={patient_statement_css.scrollbar_area}>
              <Form
                autoComplete='off'
                name='view_edit_form'
                className='common-forms'
                style={patient_statement_css.common_forms_1}
              >
                <div
                  className='patient-search-form patient_search_bottom_padding'
                  id='search-criteria-container'
                  style={patient_statement_css.patient_search_form}
                  key={'outer_row_1'}
                >
                  <Grid>
                    <Grid.Column computer={16}>
                      <h4 className='ui header patient-statement-card-header'>{'Run Patient Statements'}</h4>
                    </Grid.Column>
                  </Grid>
                  <Grid>
                    <Grid.Column className={'form_column'} tablet={8} computer={4}>
                      <Form.Field className={is_submitted && error.statement_date ? 'requiredWithBgColor' : ''}>
                        <label>
                          Statement Date{' '}
                          <span className={is_submitted && error.statement_date ? 'req-alert' : 'req-alert_normal'}>
                            (required)
                          </span>
                        </label>
                        <DatePicker
                          id='case_onset_date'
                          is_disabled={false}
                          disabledElement={false}
                          maxDate={this.CURRENT_DATE}
                          date_update={this.handle_statement_date_change}
                          date={statement_date}
                          is_required={is_submitted && error.statement_date}
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column className={'form_column'} tablet={8} computer={4}>
                      <Form.Field className={is_submitted && error.message_date ? 'requiredWithBgColor' : ''}>
                        <label>
                          Message Date{' '}
                          <span className={is_submitted && error.message_date ? 'req-alert' : 'req-alert_normal'}>
                            (required)
                          </span>
                        </label>
                        <DatePicker
                          id='case_onset_date'
                          is_disabled={false}
                          disabledElement={false}
                          maxDate={this.CURRENT_DATE}
                          date_update={this.handle_message_date_change}
                          date={message_date}
                          is_required={is_submitted && error.message_date}
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column className={'form_column'} tablet={8} computer={4}>
                      <Form.Field>
                        <label>
                          Select Month
                        </label>
                        <Selection
                          id={'select_month_run_program'}
                          name={'select_month_run_program'}
                          options={this.month_options}
                          onChange={this.on_month_change_run_card}
                          defaultValue={run_card_selected_month}
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column className={'form_column'} tablet={8} computer={4}>
                      <Form.Field>
                        <label>
                          Select Year
                        </label>
                        <Selection
                          id={'select_year_run_program'}
                          name={'select_year_run_program'}
                          options={this.year_options}
                          onChange={this.on_year_change_run_card}
                          defaultValue={run_card_selected_year}
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column className={'form_column'} tablet={8} computer={4}>
                      <Form.Field
                        className={
                          is_submitted && error.run_card_selected_company
                            ? 'advance-quick-search case-reg-form referring-physician requiredWithBgColor error'
                            : 'advance-quick-search case-reg-form referring-physician'
                        }
                      >
                        <label>
                          Available Companies{' '}
                          <span
                            className={
                              is_submitted && error.run_card_selected_company ? 'req-alert' : 'req-alert_normal'
                            }
                          >
                            (required)
                          </span>
                        </label>
                        <AdvancedControl
                          onGridRowSelection={(row) =>
                            this.on_advanced_grid_row_select(row, this.RUN_AVAILABLE_COMPANIES_ENUM)
                          }
                          gridConfig={this.state.run_companies_grid_data}
                          controlId={'ADMIN_COMPANY_SEARCH'}
                          onSearch={(param) => this.on_advance_search(param, this.RUN_AVAILABLE_COMPANIES_ENUM)}
                          search_type={'run_patient_available_companies'}
                          headerIdForGridTabNavigation={'run_patient_available_companies'}
                        />
                        <AutoSearchComponent
                          errorClass={''}
                          getInputValue={(e) => this.get_value(e, this.RUN_AVAILABLE_COMPANIES_ENUM)}
                          default_value={this.state.run_card_selected_company}
                          errorMessage={'No Record Found !'}
                          prepareRenderList={this.renderResult}
                          getList={this.lookup_for_run_available_companies}
                          prepareDataList={this.prepare_suggestion}
                          selectresult={(item) => this.on_item_selection(item, this.RUN_AVAILABLE_COMPANIES_ENUM)}
                          is_disabled={false}
                          show_clear_search={true}
                          clear_search={() => this.clear_quick_search(this.RUN_AVAILABLE_COMPANIES_ENUM)}
                        />
                      </Form.Field>
                    </Grid.Column>
                    {this.state.statementBatches.run && (
                      <Grid.Column className={'form_column'} tablet={8} computer={4}>
                        <Form.Field>
                          <label>Batch</label>
                          <Selection
                            id={'batch_run_program'}
                            name={'batch_run_program'}
                            options={this.state.run_card_batch_options}
                            onChange={this.on_batch_change}
                            defaultValue={run_card_selected_batch}
                            disabled={disable_run_card_batch}
                          />
                        </Form.Field>
                      </Grid.Column>
                    )}
                  </Grid>
                </div>
                <div style={{ margin: '0px 2px 18px 0px' }} className='patient-statement-button-container'>
                  <Form.Group inline style={{ justifyContent: 'flex-end', marginBottom: 0, marginRight: -5 }}>
                    <CheckboxComponent
                      label='Test File'
                      checked={test_file_selection}
                      id='test_file_checkbox'
                      onChange={(evt, checked) => {
                        this.setState({
                          test_file_selection: checked
                        });
                      }}
                      disabled={this.state.is_view_mode}
                    />
                    {!this.soft_only && (
                      <CheckboxComponent
                        label='Payright Format'
                        checked={pay_right_format_selection}
                        id='pay_right_format_selection'
                        onChange={(evt, checked) => {
                          this.setState({
                            pay_right_format_selection: checked
                          });
                        }}
                        disabled={this.state.is_view_mode}
                      />
                    )}
                    <Button
                      id='run_cancel_button_id'
                      type='button'
                      onClick={this.cancel_button_clicked}
                      basic
                      content='Cancel'
                    />
                    <Button
                      id='run_statement_button_id'
                      type='submit'
                      onClick={() => this.run_patient_statement()}
                      primary
                      content='Run Patient Statements'
                    />
                  </Form.Group>
                </div>
              </Form>
              <div
                className='patient-search-form patient_search_bottom_padding'
                id='search-criteria-container_2'
                style={patient_statement_css.patient_search_form}
                key={'outer_row_2'}
              >
                <RequestPatientStatementsComponent
                onRef={instance => { this.requestPatientStatementsComponent = instance; }
                }/>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
// Css Style
const patient_statement_css = {
  locations_id: {
    paddingBottom: 0
  },
  headerGrid: {
    marginTop: 0,
    marginBottom: 0,
    paddingRight: 15
  },
  wrapper: {
    borderTop: 0,
    marginBottom: 0
  },
  common_forms_1: {
    paddingTop: 0,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  common_forms: {
    paddingTop: 0,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column'
  } as React.CSSProperties,

  patient_search_form: {
    paddingBottom: 0,
    flexBasis: 'auto',
    borderColor: '#cccccc',
    borderStyle: 'solid',
    borderWidth: '2px 1px 1px',
    margin: '0 0 15px 0px',
    background: '#fff',
    padding: '15px 15px 25px 15px'
  },
  footerArea: {
    paddingLeft: 0,
    paddingBottom: 0,
    marginBottom: 12,
    paddingRight: 25,
    paddingTop: 20
  },
  help_text: {
    color: '#757575',
    fontSize: 16,
    fontStyle: 'italic',
    fontWeight: 400,
    margin: 0,
    padding: 0
  },
  scrollbar_area: {
    height: '100%',
    paddingRight: 15
  } as React.CSSProperties,
  save_button: {
    marginRight: 0
  }
};

// Get user and login details from store.
const mapStateToProps = (state) => {
  return {
    user_login_details: state.user_login_details
  };
};

export default connect(mapStateToProps)(PatientStatementsComponent);
