import { header_template, header_template_no_filter_icon } from '../../../constants/constants';
import { text_comparator } from '../../../../shared/component/grid/comparators';
import BadgeRendererComponent from './BadgeRendererComponent';

export const overrides = (props) => {
    return {
        id: 'overrides_id',
        title: 'Overrides: Claim',
        unSavedChangesMsg: 'This action will cause any unsaved data to be lost. Continue?',
        messaging: {
            errorOnSave: "There were issues when saving overrides.",
            saveSuccessful: "Changes saved.",
            saveNoChanges: "There are no changes made.",
            errorGettingAnswerFromServer: "There was an issue getting response from server.",
            errorValidatingOverrides: "We couldn't retrieve overrides for validation.",
            errorDuplicatedSetting: "There is a setting containing similar values, Setting Name",
            errorSettingNameTooLarge: "Setting Name should be less than 80 characters.",
            errorInvalidNPI: "Invalid NPI number.",
            errorInvalidTaxonomy: "Taxonomy Code should have 10 characters.",
            errorInvalidTIN: "Invalid Tax Identification Number, should be less than 9 characters, without special symbols.",
            errorLocationMissing: "There should be at least 1 Location selected.",
            errorAdditionalCriteriaMissing: "Please select at least one Location, and also at least one Payer, Insurance Class or Insurance Code.",
            errorInsuranceClassCodeMissing: "There should be Insurance Class or Insurance Code selected.",
            errorSettingNameAlreadyExists: "The Setting Name already exists.",
            errorInsuranceClassRepeated: "The Insurance class or code is already selected in other setting for",
            errorZipCode: "Pay-To Zip Code can be 5 or 9 digits."
        },
        column_defs: [
            {
                headerName: "Setting Name",
                headerComponentParams: header_template,
                field: "setting_Name",
                type: "string",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator,
                cellStyle: {color: '#7f8fa4'},
                minWidth:350,
                cellRenderer : render_cell,
                tooltipField: "setting_Name"
            },
            {
                headerName: "Location",
                field: "locations",
                cellRendererFramework: BadgeRendererComponent,
                type: "string",
                unSortIcon: true,
                minWidth:80,
                headerClass: 'non-filter-column'
            },
            {
                headerName: "Payer",
                headerComponentParams: header_template_no_filter_icon,
                field: "payers",
                cellRendererFramework: BadgeRendererComponent,
                type: "string",
                unSortIcon: true,
                minWidth:80,
                headerClass: 'non-filter-column'
            },
            {
                headerName: "Insurance Class",
                headerComponentParams: header_template_no_filter_icon,
                field: "insurance_Classes",
                cellRendererFramework: BadgeRendererComponent,
                type: "string",
                unSortIcon: true,
                minWidth:80,
                headerClass: 'non-filter-column'
            },
            {
                headerName: "Insurance Code",
                headerComponentParams: header_template_no_filter_icon,
                field: "insurance_Codes",
                cellRendererFramework: BadgeRendererComponent,
                type: "string",
                unSortIcon: true,
                comparator: text_comparator,
                minWidth:80,
                headerClass: 'non-filter-column'
            },
            {
                headerName: "Tax ID Number",
                headerComponentParams: header_template,
                field: "tax_Identification_Number",
                type: "string",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator,
                cellStyle: {color: '#7f8fa4'},
                minWidth:150,
                tooltipField: "tax_Identification_Number",
                cellRenderer : render_cell
            },
            {
                headerName: "NPI",
                headerComponentParams: header_template,
                field: "national_Provider_Identifier",
                type: "string",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator,
                cellStyle: {color: '#7f8fa4'},
                minWidth:100,
                cellRenderer : render_cell,
                tooltipField: "national_Provider_Identifier"
            },
            {
                headerName: "Taxonomy",
                headerComponentParams: header_template,
                field: "taxonomy",
                type: "string",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator,
                cellStyle: {color: '#7f8fa4'},
                minWidth:125,
                tooltipField: "taxonomy",
                cellRenderer : render_cell
            },
            {
                headerName: "Status",
                headerComponentParams: header_template,
                field: "is_Active_Text",
                type: "string",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator,
                cellStyle: { color: '#7f8fa4' },
                minWidth: 125,
                tooltipField: "Status",
                cellRenderer: render_cell
            }
        ],
        tool_tip: {
            tax_id: 'Overrides Box 25 on CMS1500, Box 5 on \n UB04 and 2010AA loop on 837I and 837P.',
            npi: 'Overrides Box 33A on CMS1500, Box 56 on \n UB04 and 2010AA loop on 837I and 837P.',
            taxonomy: 'Overrides Box 33B on CMS1500, Box 81 on \n UB04 and 2010AA loop on 837I and 837P.',
            name_address_city_state: 'Overrides Box 33 on CMS1500, Box 2 on \n UB04 and 2010AA loop on 837I and 837P.' //pay_to_name,pay_to_address,pay_to_city,pay_to_state
        },
        message_code: {
            warning: 50018,
            validation: 50019
        }
    }
};

export const status_select_options = [
    {
        value: true,
        text: 'Active'
    },
    {
        value: false,
        text: 'Inactive'
    }
];

const render_cell = params => `<label style="padding-left:18px;">${params.value ? params.value : '-'}</label>`;

export const overridesCss = {
    locations_id: {
        paddingBottom: 0
    } as React.CSSProperties,

    headerGrid: {
        marginTop: 0,
        marginBottom: 0,
        paddingRight: 15
    },

    wrapper: {
        borderTop: 0,
        marginBottom: 0,
        overflow: 'hidden auto',
        height: 'calc(100vh - 200px)',
        fallbacks: [
            { height: '-moz-calc(100vh - 200px)' },
            { height: '-webkit-calc(100vh - 200px)' },
            { height: '-o-calc(100vh - 200px)' }
        ]
    },

    common_forms: {
        paddingTop: 0,
        height: '100%',
        paddingBottom: 0,
        display: 'flex',
        flexDirection: 'column'
    } as React.CSSProperties,

    accordionColumn: {
        padding: '1rem'
    },

    accordion_content: {
        padding: 0
    },

    new_button_section: {
        marginTop: -10
    },

    new_button: {
        marginRight: 0
    },

    footerArea: {
        paddingLeft: 0,
        paddingBottom: 0,
        marginBottom: 12,
        paddingRight: 25,
        paddingTop: 5
    },

    scrollbar_area: {
        paddingRight: 15
    } as React.CSSProperties,

    save_button: {
        marginRight: 0
    },

    badgeStyle: {
        paddingTop: "8px",
    },

    dashStyle: {
        paddingLeft: 0,
    },
};
