import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { toastr as toaster } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { Dimmer, Form, Grid, Loader } from 'semantic-ui-react';
import { constants, toastr_options } from '../../global_constants';
import { get_patient_search } from '../../patient/action/patient_search_action';
import { insurance_advance_search } from '../../shared/action/autosearch_action';
import { advanced_search_class, log_error } from '../../shared/action/shared_action';
import DateRangePickerComponent from '../../shared/component/date_range_component';
import MultiSelectDropDownComponent from '../../shared/component/multi_select_dropdown_component';
import PercentageControl from '../../shared/component/percentage_decimal_control';
import SelectionComponent from '../../shared/component/selection_component';
import { get, remove, set } from '../../shared/session_storage_utility';
import { custom_date_format, get_all_error, update_company_details, get_lauch_darkley_key_value, clear_sotrage_after_company_change_on_main_page } from '../../shared/utility';
import { check_company_ff } from "../../company/action/company_action";
import { get_companyDto_by_id } from '../../admin/constants/action/constants_action';
import * as local_storage from "../../shared/local_storage_utility";
import * as session_storage from "../../shared/session_storage_utility";
import { MasterData } from '../../login/login_constants';
import LaunchDarkley from '../../shared/launch_darkley/launch_darkley';
import { set_active_patient, get_master_data, get_master_data_by_type, refresh_token } from "../../login/action/login_action";
import {
    get_report_data,
    get_report_data_post,
    get_report_metadata,
    get_updated_dependent_dropdown_data,
    get_updated_dropdown_data,
    save_billing_audit,
    set_company_name
} from '../action/report_action';
import {
    const_data_width,
    enum_drop_down_type,
    messages,
    ReportEventAction,
    report_allow_pagination
} from '../report_constants';
import { export_grid_data, print_grid_data } from '../util/export_print_utility';
import {
    formate_report_cols_row_data,
    on_filter_button_click,
    report_grid_first_header_id
} from '../util/report_data_formatter';
import { set_focus_on_element_with_id, set_focus_to_app_header } from './../../shared/tab_navigation_utility';
import ReportController from './report_controller';
import ReportFooter from './report_footer';
import ReportGrid from './report_grid';
import ReportHeader from './report_header';
import { argos_bill_1627_expected_rates_improvements } from "../../feature_flip_constant";

class ExpectedRatesReport extends React.Component<any, any> {
    constructor(props) {
        super(props);
        // Define initial state value.
        this.state = {
            column_header_height: 41,
            controls_is_expended: true,
            disclaimer_is_expended: false,
            displayGroupRowCount: false,
            drill_down_title_shown: false,
            grid_ref: null,
            is_error_control: {},
            is_export_disabled: true,
            is_grid_visible: false,
            is_print_disabled: true,
            is_reset: false,
            is_submitted: false,
            is_validate: true,
            loading: false,
            re_render: false,
            report_footer_obj: {},
            report_rows_count: 0,
            search_criteria: false,
            show_date: false
        };
        this.props.patient_details.patient_search = null;
    }

    // Initialize default reports data.
    report_metadata: any = {
        allow_grouping: false,
        allow_paging: false,
        disclaimer: '',
        id: '',
        input_fields: [
            {
                control_type: '',
                data_type: null,
                default_value: null,
                label_name: null,
                master_data: null,
                drawer_data: null,
                name: null,
                param_name: null,
                required: true,
                sequence: 0
            }
        ],
        parameter_validator: [
            {
                control_type: '',
                express: '',
                has_regex_expression: false,
                parameter_name: '',
                validation_message: ``
            }
        ],
        report_header: '',
        ShowFooter: false,
        show_Parm_Lavel_2: true,
        title: ''
    };

    // Report Default Data.
    report_data: any = {
        cols: [],
        rows: [],
        footer_obj: {},
        Company: '',
        DefaultSortColumn: '',
        Disclaimer: '',
        OrderBy: '',
        header: '',
        ShowFooter: false,
        status: '',
        title: '',
        no_of_records: 0,
        r4_to_r6_routes: []
    };

    // Define class level variables.
    account_no = null;
    company_name = '';
    control_data = [];
    control_data_text = {};
    current_company: any;
    current_date = '';
    default_date = null;
    default_focus_is_on_date_picker = false;
    disclaimer = '';
    grid_header_height: number = 0;
    initial_control_data = [];
    initial_control_data_text = {};
    initial_input_fields = [];
    input_fields = [];
    is_control_visible = [];
    is_initial_result = false;
    is_mounted = false;
    is_print_show_company = true;
    location: any = {};
    meta_loader = false;
    patient_name = '';
    report_id = 0;
    report_loader = false;
    report_name = '';
    report_params = '';
    report_rows_count = 0;
    run_report_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
    search_criteria = [];
    show_pagination = false;
    title = '';
    token = '';
    total_column_width: number = 0;
    companyChangedName: string = '';
    companyChanged: boolean = false;

    // Fetching the report id and parameters.
    UNSAFE_componentWillMount = () => {
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.show_pagination = report_allow_pagination(this.report_id);
        const patient_id = get('active_patient') || 0;

        // User is opening this report from the menu (clean search criteria)
        if (this.props.history && this.props.history.action == 'PUSH') {
            remove('expected_search_criteria');
        }

        if (get('expected_search_criteria')) {
            this.is_initial_result = true;
        }
    };

    // Here we hit the all API.
    componentDidMount = () => {
        this.is_mounted = true;
        document.body.classList.add('reports');
        this.report_id = (this.props.location.state && this.props.location.state.report_id) || 0;
        this.get_report_metadata(this.report_id);
    };

    componentDidUpdate = () => {
        if (this.report_id != this.props.location.state.report_id) {
            this.report_id = (this.props.location.state && this.props.location.state.report_id) || 0;
            remove('expected_search_criteria');
            this.is_initial_result = false;
            this.clear_handler('e');
            this.get_report_metadata(this.report_id);
        }
    };

    componentWillUnmount = () => {
        this.is_mounted = false;
        document.body.classList.remove('reports', 'patient-header', 'patient-subHeader');
    };

    start_loading = () => {
        this.setState({
            loading: true,
            is_search_button_disabled: true
        });
    };

    stop_loading = () => {
        if (!this.report_loader && !this.meta_loader) {
            this.setState({
                loading: false,
                is_search_button_disabled: false
            });
        }
    };

    // Get reports meta data so we can use it to create controller.
    get_report_metadata = async (report_id) => {
        if (this.is_mounted) {
            this.meta_loader = true;
            this.start_loading();
            this.setState({
                is_grid_visible: false,
                show_date: false
            });
        }

        await get_report_metadata(report_id, this.token).then(
            async (response) => {
                this.report_metadata = response.data.data;
                this.input_fields = this.report_metadata.input_fields;
                this.disclaimer = this.report_metadata.disclaimer;
                // Check initial result and set search criteria in controls
                if (this.is_initial_result && get('expected_search_criteria')) {
                    this.search_criteria = get('expected_search_criteria');
                    for (let i = 0; i < this.search_criteria.length; i++) {
                        if (
                            this.search_criteria[i].name.toLowerCase() == enum_drop_down_type.company.toLowerCase() ||
                            this.search_criteria[i].name.toLowerCase() == enum_drop_down_type.practice.toLowerCase()
                        ) {
                            if (this.report_metadata.pls_type == 0) {
                                if (this.search_criteria[i].depends_on > 0) {
                                    await this.get_update_dependent_dropdown_data(
                                        this.search_criteria[i].depends_on,
                                        this.input_fields,
                                        false
                                    );
                                    this.get_multi_select_data();
                                } else {
                                    this.get_dropdown_master_data(this.search_criteria[i]);
                                }
                            } else {
                                this.get_dropdown_master_data(this.search_criteria[i]);
                            }
                        }
                        if (
                            this.input_fields[i].control_type &&
                            this.input_fields[i].control_type.toLowerCase().indexOf('daterange') > -1
                        ) {
                            this.input_fields[i].default_value = JSON.parse(this.search_criteria[i].default_value);
                        } else {
                            this.input_fields[i].default_value = this.search_criteria[i].default_value;
                        }
                    }
                    this.get_report_data(this.report_id, this.search_criteria);
                }

                // Set Default value to control data and reset error for controls.
                this.set_control_data();

                // Set Current Company id for patient search
                this.input_fields.filter((data) => {
                    !data.invisible && this.is_control_visible.push(data.name);
                    if (data.name.toLowerCase() == enum_drop_down_type.company.toLowerCase())
                        this.current_company = data.default_value;
                });

                // Get MultiSelect Master Data
                this.get_multi_select_data();

                // Set Initial search data
                this.initial_control_data = [...this.control_data];

                if (this.is_mounted) {
                    this.meta_loader = false;
                    this.stop_loading();
                    this.setState({
                        search_criteria: true,
                        is_reset: true
                    });
                }
            },
            (error) => {
                if (this.is_mounted) {
                    this.meta_loader = false;
                    this.stop_loading();
                }
                log_error(error);
                if (error.response.data) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error('', toastr_options);
                }
            }
        );
    };

    // Get updated dropdown data on dropdown change.
    get_multi_select_data = async () => {
        if (this.is_mounted) {
            this.setState({
                re_render: true
            });
        }
        const params = { page_size: constants.PAGE_SIZE, company_id: this.current_company, records: 5000 };
        const promise_array = [insurance_advance_search(params, this.token), advanced_search_class(params, this.token)];
        let currentSearchCriteria = get('expected_search_criteria');
        await Promise.all(promise_array)
            .then(
                (res) => {
                    this.input_fields.filter((data, index) => {
                        if (data.control_type == 'MultiSelect') {
                            this.control_data[index + 1] = [];
                            let default_value = currentSearchCriteria ? currentSearchCriteria[index].default_value : '-1',
                                no_value_selected = !this.control_data[index + 1] || this.control_data[index + 1] == '';

                            if (default_value != '-1' && no_value_selected) {
                                // Data comes in the form of string, so we need to convert it to an integer array
                                this.control_data[index + 1] = default_value.split(',').map((item) => parseInt(item));
                            }

                            switch (data.name) {
                                case 'InsuranceID':
                                    data.master_data =
                                        res[0].data && res[0].data.data && res[0].data.data.length > 0
                                            ? this.get_formatted_insurance_list('insurance_code', res[0].data.data)
                                            : [];
                                    break;
                                case 'InsuranceClassID':
                                    data.master_data =
                                        res[1].data && res[1].data.data && res[1].data.data.length > 0
                                            ? this.get_formatted_insurance_list('insurance_class', res[1].data.data)
                                            : [];
                                    break;
                                default:
                                    data.master_data = [];
                                    break;
                            }
                        }
                    });
                    if (this.is_mounted) {
                        this.setState({
                            re_render: false
                        });
                    }
                },
                (error) => {
                    if (this.is_mounted) {
                        this.setState({ re_render: false });
                    }
                }
            )
            .catch((error) => {
                if (this.is_mounted) {
                    this.setState({ re_render: false });
                }
            });
    };

    // Get updated dropdown data on dropdown change.
    get_update_dependent_dropdown_data = async (param_id, input_list, change_default_value = true) => {
        var param_object = {};
        input_list.map((item) => {
            param_object[item.param_name] = item.default_value;
        });
        try {
            let response = await get_updated_dependent_dropdown_data(
                param_object,
                this.report_id,
                param_id,
                this.token
            );
            Object.keys(response.data.data).map((key) => {
                this.input_fields.filter((data) => {
                    if (data.name.toLowerCase() == key.toLowerCase()) {
                        data.master_data = response.data.data[key];
                        if (change_default_value) {
                            data.default_value = '';
                            this.control_data[data.sequence] = '';
                        }
                    }
                });
            });

            if (this.is_mounted) {
                this.setState({
                    re_render: true
                });
                this.setState({
                    re_render: false
                });
            }
        } catch (error) {
            if (this.is_mounted) {
                this.setState({
                    re_render: false
                });
            }
            log_error(error);
            if (error.response.data) {
                const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                toaster.error('', toastr_options);
            }
        }
    };

    // Get updated dropdown data on dropdown change.
    get_updated_dropdown_data = async (sequence, params) => {
        await get_updated_dropdown_data(params, this.token).then(
            (response) => {
                this.input_fields.filter((data) => {
                    if (sequence == 1 && data.name.toLowerCase() == enum_drop_down_type.practice.toLowerCase()) {
                        data.master_data = response.data.data[enum_drop_down_type.practice];
                        data.default_value = '';
                        this.control_data[data.sequence] = '';
                    }
                    if (sequence == 1 && data.name.toLowerCase() == enum_drop_down_type.provider.toLowerCase()) {
                        data.master_data = response.data.data[enum_drop_down_type.provider];
                        data.default_value = '';
                        this.control_data[data.sequence] = '';
                    }
                    if (data.name.toLowerCase() == enum_drop_down_type.location.toLowerCase()) {
                        data.master_data = response.data.data[enum_drop_down_type.location];
                        data.default_value = '';
                        this.control_data[data.sequence] = '';
                    }
                });
                if (this.is_mounted) {
                    this.setState({
                        re_render: true
                    });
                    this.setState({
                        re_render: false
                    });
                }
            },
            (error) => {
                if (this.is_mounted) {
                    this.setState({
                        re_render: false
                    });
                }
                log_error(error);
                if (error.response.data) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error('', toastr_options);
                }
            }
        );
    };

    // Get updated dropdown data on dropdown change.
    get_dropdown_master_data = async (search_data) => {
        let params = [];
        if (
            search_data.sequence == 1 &&
            search_data.name.toLowerCase().trim() == enum_drop_down_type.company.toLowerCase()
        ) {
            params.push(this.search_criteria[search_data.sequence - 1].default_value);
        } else if (
            search_data.sequence == 2 &&
            search_data.name.toLowerCase() == enum_drop_down_type.practice.toLowerCase()
        ) {
            this.search_criteria[search_data.sequence - 2].invisible
                ? params.push('@companyId')
                : params.push(this.search_criteria[search_data.sequence - 2].default_value);
            params.push(this.search_criteria[search_data.sequence - 1].default_value);
        }

        await get_updated_dropdown_data(params, this.token).then(
            (response) => {
                this.input_fields.filter((data) => {
                    if (
                        search_data.sequence == 1 &&
                        data.name.toLowerCase() == enum_drop_down_type.practice.toLowerCase()
                    ) {
                        data.master_data = response.data.data[enum_drop_down_type.practice];
                    }
                    if (
                        search_data.sequence == 2 &&
                        data.name.toLowerCase() == enum_drop_down_type.location.toLowerCase()
                    ) {
                        data.master_data = response.data.data[enum_drop_down_type.location];
                    }
                });
                if (this.is_mounted) {
                    this.setState({
                        re_render: true
                    });
                    this.setState({
                        re_render: false
                    });
                }
            },
            (error) => {
                if (this.is_mounted) {
                    this.setState({
                        re_render: false
                    });
                }
                log_error(error);
                if (error.response.data) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error('', toastr_options);
                }
            }
        );
    };

    // Get Report Data.
    get_report_data = async (report_id, param) => {
        if (this.is_mounted) {
            this.report_loader = true;
            this.start_loading();
            this.setState({
                is_grid_visible: false,
                show_date: false
            });
        }
        await get_report_data_post(report_id, param, this.token).then(
            (response) => {
                if (response.data && response.data.data) {
                    const formatted_data = formate_report_cols_row_data(
                        response.data.data,
                        report_id,
                        this.props.features
                    );
                    response.data.data = formatted_data.data;
                    this.report_data.rows = response.data.data.ReportRows ? response.data.data.ReportRows : [];
                    this.report_data.cols = response.data.data.ReportColumn ? response.data.data.ReportColumn : [];
                    this.report_data.footer_obj = response.data.data.footer_obj ? response.data.data.footer_obj : {};
                    this.report_data.header = response.data.data.ReportHeader;
                    this.report_data.ShowFooter = response.data.data.ShowFooter;
                    this.report_data.allow_grouping = response.data.data.allow_grouping;
                    this.report_data.r4_to_r6_routes = response.data.data.R4ToR6Route;
                    this.report_data.title = response.data.data.Title;
                    this.disclaimer = response.data.data.Disclaimer;
                    this.company_name = response.data.data.Company;
                    this.report_data.DefaultSortColumn = response.data.data.DefaultSortColumn;
                    this.report_data.link_drill_down_last_page = response.data.data.link_drill_down_last_page;
                    this.report_data.additional_actions = response.data.data.additional_actions;
                    this.total_column_width = formatted_data.total_column_width;
                    this.grid_header_height = formatted_data.grid_header_height;

                    if (this.is_mounted) {
                        this.report_loader = false;
                        this.stop_loading();
                        this.setState({
                            is_print_disabled: false,
                            is_export_disabled: false,
                            link_drill_down_last_page: this.report_data.link_drill_down_last_page,
                            show_date: true,
                            is_grid_visible: true,
                            column_header_height: 45,
                            controls_is_expended: false,
                            additional_actions: this.report_data.additional_actions
                        });
                    }
                }
            },
            (error) => {
                if (this.is_mounted) {
                    this.report_loader = false;
                    this.setState({
                        loading: false,
                        is_search_button_disabled: false,
                        controls_is_expended: true
                    });
                }
                log_error(error);
                if (error.response.data) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error('', toastr_options);
                }
            }
        );
    };

    // Set Default value to control data and reset error for controls.
    set_control_data = () => {
        let { is_error_control } = this.state;
        this.input_fields.map((item, index) => {
            if (item.control_type.toLowerCase() != 'blank') {
                if (item.required) {
                    is_error_control = {
                        ...is_error_control,
                        [item.sequence]: false
                    };
                }
                this.control_data[item.sequence] = item.default_value;
                if (item.control_type === 'MultiSelect') {
                    this.control_data[item.sequence] = [];
                }
            }
            this.initial_input_fields[index] = { ...item };
            return item;
        });
        if (this.is_mounted) {
            this.setState({
                is_error_control
            });
        }
    };

    // Dropdown change handler.
    dropdown_change_handler = (event) => {
        const { id, value, name } = event.target;
        this.control_data[id] = value;
        this.input_fields[id - 1].default_value = value;
        this.control_data_text = {
            ...this.control_data_text,
            [name]: {
                value,
                text: event.target.selectedOptions
                    ? event.target.selectedOptions[0].outerText
                    : event.target.options[event.target.selectedIndex].text
            }
        };
        // Set Current compnay
        if (this.input_fields[id - 1].name.toLowerCase() == enum_drop_down_type.company.toLowerCase()) {
            this.current_company = value;
            this.companyChangedName = this.input_fields[id - 1].name.toLowerCase();
            this.companyChanged = true;
        }

        if (this.report_metadata.pls_type == 0) {
            var depend_input_felid = this.input_fields.filter((inp) => {
                return inp.depends_on == this.input_fields[id - 1].report_param_id;
            });
            if (depend_input_felid.length > 0) {
                this.get_update_dependent_dropdown_data(this.input_fields[id - 1].report_param_id, this.input_fields);
                this.get_multi_select_data();
            } else {
                // if there is no depended column go with previous approach
                if (this.input_fields[id - 1].auto_postback) {
                    this.update_control_type(id, this.input_fields[id - 1].name);
                }
            }
        } else {
            if (this.input_fields[id - 1].auto_postback) {
                this.update_control_type(id, this.input_fields[id - 1].name);
            }
        }

        if (this.is_mounted) {
            this.setState({
                is_reset: false
            });
        }
        if (this.state.is_error_control[id] !== '') {
            if (this.is_mounted) {
                this.setState((prev_state) => ({
                    is_error_control: {
                        ...prev_state.is_error_control,
                        [id]: false
                    }
                }));
            }
        }
    };

    // Update controls on page load.
    update_control_type = (sequence, name) => {
        let params = [];
        if (sequence == 1 && name.toLowerCase().trim() == enum_drop_down_type.company.toLowerCase()) {
            params.push(this.control_data[sequence]);
        } else if (sequence == 2 && name.toLowerCase() == enum_drop_down_type.practice.toLowerCase()) {
            this.input_fields[sequence - 2].invisible
                ? params.push('@companyId')
                : params.push(this.control_data[sequence - 1]);
            params.push(this.control_data[sequence]);
        }
        this.get_updated_dropdown_data(sequence, params);
    };

    // Input change handler.
    input_change_handler = (e) => {
        let { id, value } = e.target;
        this.control_data[id] = value;
        if (this.state.is_error_control[id] !== '') {
            if (this.is_mounted) {
                this.setState((prev_state) => ({
                    is_error_control: {
                        ...prev_state.is_error_control,
                        [id]: false
                    }
                }));
            }
        }
    };

    dateRangePickerHandler = (sequence, dateRange) => {
        const { startDate, endDate } = dateRange;
        const dateRangePicker = {
            startDate: startDate ? custom_date_format(startDate, 'mm/dd/yyyy') : '',
            endDate: endDate ? custom_date_format(endDate, 'mm/dd/yyyy') : ''
        };
        this.control_data[sequence] = dateRangePicker;
        if (this.state.is_error_control[sequence] !== '') {
            if (this.is_mounted) {
                this.setState((prev_state) => ({
                    is_error_control: {
                        ...prev_state.is_error_control,
                        [sequence]: !!!dateRangePicker.startDate || !!!dateRangePicker.endDate ? true : false
                    }
                }));
            }
        }
    };

    // multi select change handler
    multi_select_handler = (id, values) => {
        this.control_data[id] = [];
        this.input_fields[id - 1].master_data = this.input_fields[id - 1].master_data.map((item) => {
            if (values.indexOf(item.value) > -1) {
                this.control_data[id].push(item.value);
                item = {
                    ...item,
                    checked: true,
                    is_checked: true
                };
            } else {
                item = {
                    ...item,
                    checked: false,
                    is_checked: false
                };
            }
            return item;
        });
        if (this.state.is_error_control[id] !== '') {
            if (this.is_mounted) {
                this.setState((prev_state) => ({
                    is_error_control: {
                        ...prev_state.is_error_control,
                        [id]: false
                    }
                }));
            }
        }
    };

    // Set controls on page load.
    set_control_type = (control_type, item, defaultFocus) => {
        switch (control_type) {
            case 'MultiSelect':
                return (
                    <Form.Field>
                        {item.label_name && (
                            <label>
                                {item.label_name}
                                {item.required && (
                                    <span
                                        className={
                                            !this.control_data[item.sequence] &&
                                            this.state.is_submitted &&
                                            item.required &&
                                            this.state.is_error_control[item.sequence]
                                                ? 'req-alert'
                                                : 'req-alert_normal'
                                        }
                                    >
                                        {' '}
                                        (required)
                                    </span>
                                )}
                            </label>
                        )}
                        <MultiSelectDropDownComponent
                            class_name='manage-content-multi-select'
                            id={item.sequence}
                            key={item.name}
                            key_id={item.sequence}
                            data={item.master_data || []}
                            selected_list={this.control_data[item.sequence] || []}
                            onChangeSelected={this.multi_select_handler}
                            forceOnChangeSelected={true}
                            has_validation={false}
                            show_filter={true}
                            reset_disable={true}
                        />
                    </Form.Field>
                );
            case 'DropDownList':
                return (
                    <Form.Field>
                        {item.label_name && (
                            <label>
                                {item.label_name}
                                {item.required && (
                                    <span
                                        className={
                                            !this.control_data[item.sequence] &&
                                            this.state.is_submitted &&
                                            item.required &&
                                            this.state.is_error_control[item.sequence]
                                                ? 'req-alert'
                                                : 'req-alert_normal'
                                        }
                                    >
                                        {' '}
                                        (required)
                                    </span>
                                )}
                            </label>
                        )}
                        <SelectionComponent
                            id={item.sequence}
                            name={item.name}
                            placeHolder={'Select'}
                            hidden={true}
                            options={item.master_data}
                            onChange={(value, event) => {
                                this.dropdown_change_handler(event);
                            }}
                            defaultValue={this.control_data[item.sequence]}
                            autoFocus={defaultFocus}
                            isRequired={
                                !this.control_data[item.sequence] &&
                                item.required &&
                                this.state.is_submitted &&
                                this.state.is_error_control[item.sequence]
                                    ? true
                                    : false
                            }
                            style={'dropdown-options-wrap'}
                        />
                    </Form.Field>
                );
            case 'DateRange':
                return (
                    <Form.Field>
                        {item.label_name && (
                            <label>
                                {item.label_name}
                                {item.required && (
                                    <span
                                        className={
                                            (!!!this.control_data[item.sequence] ||
                                                !!!this.control_data[item.sequence].startDate ||
                                                !!!this.control_data[item.sequence].endDate) &&
                                            item.required &&
                                            this.state.is_submitted &&
                                            this.state.is_error_control[item.sequence]
                                                ? 'req-alert'
                                                : 'req-alert_normal'
                                        }
                                    >
                                        {' '}
                                        (required)
                                    </span>
                                )}
                            </label>
                        )}
                        <DateRangePickerComponent
                            id={item.sequence.toString()}
                            updateDatesChange={(startDate: any, endDate: any) =>
                                this.dateRangePickerHandler(item.sequence, { startDate, endDate })
                            }
                            startDate={
                                this.control_data[item.sequence] &&
                                this.control_data[item.sequence].startDate &&
                                moment(this.control_data[item.sequence].startDate)
                                    ? moment(this.control_data[item.sequence].startDate)
                                    : null
                            }
                            endDate={
                                this.control_data[item.sequence] &&
                                this.control_data[item.sequence].endDate &&
                                moment(this.control_data[item.sequence].endDate)
                                    ? moment(this.control_data[item.sequence].endDate)
                                    : null
                            }
                            error={
                                (!this.control_data[item.sequence] ||
                                    !!!this.control_data[item.sequence].startDate ||
                                    !!!this.control_data[item.sequence].endDate) &&
                                item.required &&
                                this.state.is_submitted &&
                                this.state.is_error_control[item.sequence]
                            }
                        />
                    </Form.Field>
                );
            case 'PercentageTextBox':
                return (
                    <Form.Field>
                        {item.label_name && (
                            <label>
                                {item.label_name}
                                {item.required && (
                                    <span
                                        className={
                                            !(
                                                this.control_data[item.sequence] &&
                                                this.control_data[item.sequence].trim()
                                            ) &&
                                            item.required &&
                                            this.state.is_submitted &&
                                            this.state.is_error_control[item.sequence]
                                                ? 'req-alert'
                                                : 'req-alert_normal'
                                        }
                                    >
                                        {' '}
                                        (required)
                                    </span>
                                )}
                            </label>
                        )}
                        <PercentageControl
                            name={item.name}
                            id={item.sequence}
                            onChange={(value) =>
                                this.input_change_handler({ target: { id: item.sequence, value: value } })
                            }
                            defaultValue={this.control_data[item.sequence] ? this.control_data[item.sequence] : ''}
                            allowNegative={false}
                            maxLength={6}
                            suffix={'%'}
                            prefix={''}
                            className={
                                !(this.control_data[item.sequence] && this.control_data[item.sequence].trim()) &&
                                item.required &&
                                this.state.is_submitted &&
                                this.state.is_error_control[item.sequence]
                                    ? 'error red-error-thin required_with_bg_color'
                                    : ''
                            }
                        />
                    </Form.Field>
                );
            default:
                return (
                    <Form.Field>
                        {item.label_name && (
                            <label>
                                {item.label_name}
                                {item.required && (
                                    <span
                                        className={
                                            !(
                                                this.control_data[item.sequence] &&
                                                this.control_data[item.sequence].trim()
                                            ) &&
                                            item.required &&
                                            this.state.is_submitted &&
                                            this.state.is_error_control[item.sequence]
                                                ? 'req-alert'
                                                : 'req-alert_normal'
                                        }
                                    >
                                        {' '}
                                        (required)
                                    </span>
                                )}
                            </label>
                        )}
                        <input
                            name={item.name}
                            type='text'
                            autoFocus={defaultFocus}
                            id={item.sequence}
                            onChange={this.input_change_handler}
                            value={this.control_data[item.sequence] ? this.control_data[item.sequence] : ''}
                            className={
                                !(this.control_data[item.sequence] && this.control_data[item.sequence].trim()) &&
                                item.required &&
                                this.state.is_submitted &&
                                this.state.is_error_control[item.sequence]
                                    ? 'error red-error-thin required_with_bg_color'
                                    : ''
                            }
                        />
                    </Form.Field>
                );
        }
    };

    // Required Field Validator.
    required_field_validator = () => {
        let is_error = false;
        let { is_error_control } = this.state;
        this.input_fields.map((item, index) => {
            if (item.control_type == 'text' || item.control_type.toLowerCase().indexOf('text') > -1) {
                this.control_data[index + 1] = this.control_data[index + 1]
                    ? this.control_data[index + 1].trim()
                    : this.control_data[index + 1];
            }
            if (item.required) {
                switch (true) {
                    case item.control_type.toLowerCase().indexOf('daterange') > -1:
                        !!this.control_data[index + 1].startDate && !!this.control_data[index + 1].endDate
                            ? (is_error_control[index + 1] = false)
                            : (is_error_control[index + 1] = true);
                        break;
                    default:
                        this.control_data[index + 1]
                            ? (is_error_control[index + 1] = false)
                            : (is_error_control[index + 1] = true);
                        break;
                }
            }
            let paramEmpty;
            switch (true) {
                case item.control_type.toLowerCase().indexOf('daterange') > -1:
                    paramEmpty = !!!this.control_data[index + 1].startDate || !!!this.control_data[index + 1].endDate;
                    break;
                default:
                    paramEmpty = !this.control_data[index + 1];
                    break;
            }
            if (!is_error && item.required && paramEmpty) {
                toaster.error('', messages.mandatory_fields);
                is_error = true;
            }
        });
        return { is_error, is_error_control };
    };

    // Function used to Parameter Validator.
    parameter_validator = () => {
        let is_error = false;
        let is_valid_date = true;
        const control_data = this.control_data.map((item, index) => {
            if (this.input_fields[index - 1].control_type.toLowerCase() == 'date') {
                if (isNaN(new Date(item).getTime())) {
                    is_valid_date = false;
                    return item;
                } else {
                    return new Date(item).getTime();
                }
            }
            return item;
        });

        if (!is_valid_date) {
            return is_error;
        }

        this.report_metadata.parameter_validator.map((item) => {
            if (item.control_type.toLowerCase() == 'date') {
                var express = item.express.toString();
                express = express.replace('#CurrentDate', new Date().getTime().toString());
                express = express.replace('#ReportParam', 'control_data');
                express = express.replace('#ReportParam', 'control_data');
            }
            if (!is_error && !item.has_regex_expression) {
                if (!eval(express)) {
                    toaster.error('', item.validation_message);
                    is_error = true;
                }
            }
        });
        return is_error;
    };

    // Function used to Run Report based on controllers values.
    run_report_handler = (e) => {
        if (this.is_mounted) {
            this.setState({
                is_submitted: true,
                controls_is_expended: false
            });
        }
        let { is_error = false, is_error_control } = this.required_field_validator();
        if (is_error) {
            this.setState({
                controls_is_expended: true,
                is_error_control
            });
            return;
        }
        is_error = this.parameter_validator();
        if (is_error) {
            this.setState({
                controls_is_expended: true
            });
            return;
        }

        this.input_fields.filter((data, index) => {
            this.search_criteria[index] = { ...data, master_data: null };
        });
        this.control_data.map((data, index) => {
            if (this.search_criteria[index - 1] && this.search_criteria[index - 1].control_type) {
                switch (this.search_criteria[index - 1].control_type) {
                    case 'DateRange':
                        this.search_criteria[index - 1].default_value = JSON.stringify(data);
                        break;
                    case 'MultiSelect':
                        this.search_criteria[index - 1].default_value = data.length > 0 ? data.join() : '-1';
                        break;
                    default:
                        this.search_criteria[index - 1].default_value = data;
                        break;
                }
            }
        });

        //Adding new logic to change the global company in the header when dropdown company is being changed.
        this.execute_report_to_change_the_company();
        set('expected_search_criteria', this.search_criteria);
        this.initial_control_data_text = { ...this.control_data_text };
        this.run_report_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');

        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    execute_report_to_change_the_company = async () => {
        if (this.companyChanged && this.props.features.argosBill1627ExpectedRatesImprovements) {
            this.companyChanged = false;
            if (this.is_mounted) {
                this.setState({
                    loading: true
                });
            }
            await this.change_company_by_report_dropdown_company(this.current_company, this.companyChangedName);
        }
        this.get_report_data(this.report_id, this.search_criteria);
    }

    change_company_by_report_dropdown_company = async (companyChangedId, companyChangedName) => {
        try {
            if (companyChangedName === enum_drop_down_type.company.toLowerCase()) {
                let getCompanyDtoById = await get_companyDto_by_id(this.token, companyChangedId);
                if (getCompanyDtoById.data) {
                    await this.change_company_from_report(this.props.user_login_details, getCompanyDtoById.data);
                }
            }
            if (this.is_mounted) {
                this.setState({
                    loading: false
                });
            }
        } catch (error) {
            if (this.is_mounted) {
                this.setState({
                    loading: false
                });
            }
            const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
            toaster.error('', this.show_html_content_toaster(toastr_options));
        }
    }

    change_company_from_report = async (user_login_details, selected_company) => {
        let user_data = user_login_details.user_data.data;
        user_data.company_id = selected_company.id;
        user_data.company_name = selected_company.name;
        user_data.gpms_code = selected_company.gpms_code;
        user_data.company_type_id = selected_company.company_type_id;

        local_storage.set("wait_for_master_data_update", true);
        local_storage.set('company_change_on_same_tab', true);

        update_company_details(selected_company, false);

        await this.props.refresh_token(user_data, true);

        session_storage.set('active_patient', '');
        session_storage.set('patient_criteria', '');
        this.props.set_active_patient('');

        local_storage.remove('report_navigation_active_patient');
        local_storage.remove('report_navigation');

        // get master data when user change company
        this.props.get_master_data(MasterData.all, user_login_details.user_data.data.user_id, user_login_details.user_data.data.token_details.access_token, true);
        this.props.get_master_data_by_type(MasterData.all, user_login_details.user_data.data.user_id, user_login_details.user_data.data.token_details.access_token, true);

        LaunchDarkley.update(
            () => {
                session_storage.set('change_company', true);
            }
        );
    }

    // Reset the search control of Reports.
    clear_handler = (e) => {
        this.is_initial_result = false;
        this.control_data = [];
        this.initial_control_data = [];
        this.control_data_text = {};
        this.initial_control_data_text = {};
        this.get_report_metadata(this.report_id);
        if (this.is_mounted) {
            this.setState({
                show_date: false,
                is_submitted: false,
                is_print_disabled: true,
                is_export_disabled: true,
                is_grid_visible: false,
                is_reset: true,
                controls_is_expended: true,
                disclaimer_is_expended: false
            });
        }
        remove('expected_search_criteria');
        this.initial_control_data_text = {};
    };

    // Function calls on initialization of Ag-Grid and catch its reference.
    get_grid_ref = (grid_params) => {
        if (this.is_mounted) {
            this.setState(
                {
                    grid_params,
                    report_rows_count: grid_params.api.getModel().getRowCount()
                },
                () => {
                    const grid_width = document.querySelector(`#report_id_${this.report_id} .ag-body`).clientWidth;
                    if (this.total_column_width > grid_width - const_data_width) {
                        this.report_data.cols.filter((item) => {
                            grid_params.columnApi.setColumnWidth(item.field, item.width, false);
                        });
                    }
                    this.check_is_company_print();
                }
            );
        }
    };

    // Used to create the title for export and print.
    set_title = (separator) => {
        this.check_is_company_print();
        this.current_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
        this.title = this.report_data.title;
        this.title =
            this.title &&
            this.title
                .replace('%20', ' ')
                .replace('%2c', ',')
                .replace('%23', '#')
                .replace(/<Font Color=red>/g, '')
                .replace(/<font color=red>/g, '')
                .replace(/<\/Font>/g, '')
                .replace(/<\/font>/g, '')
                .replace('-1', 'All');
        return (this.title = decodeURIComponent(
            this.report_data.header.replace(/<br>/g, separator) +
                (this.is_print_show_company ? this.company_name + separator : '') +
                (this.report_id === 131 ? this.patient_name : '') +
                this.title.replace(/<br>/g, separator) +
                this.current_date +
                separator +
                (this.state.report_rows_count == 1
                    ? this.state.report_rows_count + ' record'
                    : this.state.report_rows_count + ' records') +
                separator +
                separator
        ));
    };

    // Function calls on initialization of export report data
    on_export_button = () => {
        this.title = this.set_title('\n');
        this.report_name = this.report_data.header.replace(/<br>/g, '');
        export_grid_data(this.state.grid_params, this.title, this.report_name);
        this.saveReportEventAction(ReportEventAction.Export);
    };

    // Function calls on initialization of Print report data
    on_print_button = () => {
        this.title = this.set_title('<br>');
        this.report_name = this.report_data.header.replace(/<br>/g, '');
        print_grid_data(this.state.grid_params, this.title, this.report_name);
        this.saveReportEventAction(ReportEventAction.Print);
    };

    private saveReportEventAction = (eventActionId: number) => {
        let reqBody = {
            reportId: this.report_id,
            reportEventActionId: eventActionId
        };

        save_billing_audit(reqBody, this.token);
    };

    // To handle focus on grid.
    handle_focus_for_grid = (event) => {
        let child_element_exist = this.disclaimer;
        if (child_element_exist) {
            return;
        }
        // apply a check where grid is empty
        // if it is not empty send the focus to app header
        if (
            !event.shiftKey &&
            event.keyCode == '9' &&
            (!this.state.is_grid_visible || this.report_data.rows == null || this.report_data.rows.length == 0)
        ) {
            set_focus_to_app_header(event);
        }
    };

    on_group_by_Change = (fields) => {
        if (this.is_mounted) {
            let row_count = 0;
            if (fields && fields.length > 0) {
                this.state.grid_params.api.forEachNodeAfterFilter((row, index) => {
                    if (row.group && row.level == 0) {
                        row_count++;
                    }
                });
            } else {
                row_count = this.state.grid_params.api.getModel().getRowCount();
            }
            this.setState({
                report_rows_count: row_count
            });
        }
    };

    on_filter_button_click = () => {
        let row_count = 0;
        if (this.report_data.allow_grouping) {
            // Update group row count
            let group = false;
            this.state.grid_params.api.forEachNodeAfterFilter((row, index) => {
                if (row.group && row.level == 0) {
                    row_count++;
                    group = true;
                }
            });
            if (!group) {
                row_count = this.state.grid_params.api.getModel().getRowCount();
            }
        } else {
            row_count = this.state.grid_params.api.getModel().getRowCount();
        }

        this.setState({
            report_rows_count: row_count
        });
        on_filter_button_click(this.state.grid_params, this.report_data, this.report_id);
    };

    check_is_company_print = () => {
        let company_array: any = [];
        let row_data = [];
        this.state.grid_params.api.forEachNodeAfterFilter((rowNode) => {
            row_data.push(rowNode.data);
            return;
        });
        this.company_name = this.company_name
            ? this.company_name
            : this.props.user_login_details.user_data.data.company_name;
        this.report_data.cols.filter((item) => {
            if (item.headerName && item.headerName == 'Company Name') {
                company_array = this.filter_multiple_company_from_company_column(item, row_data);
            }
        });
        if (this.initial_control_data_text['companyid']) {
            if (this.initial_control_data_text['companyid']['text']) {
                this.company_name = this.initial_control_data_text['companyid']['text'];
            }
            this.is_print_show_company = this.initial_control_data_text['companyid']['value'] == -1 ? false : true;
        }
        this.check_multiple_company_in_grid(company_array);
        if (this.props.get_company_name) {
            this.company_name = this.props.get_company_name;
        }
    };

    filter_multiple_company_from_company_column = (item, row_data) => {
        let company_row: any = [];
        row_data.filter((compItem) => {
            company_row.indexOf(compItem[item.field]) == -1 && company_row.push(compItem[item.field]);
            return;
        });
        return company_row;
    };

    check_multiple_company_in_grid = (company_array) => {
        if (company_array.length == 1) {
            this.company_name = company_array[0];
            this.is_print_show_company = true;
        } else if (company_array.length > 1) {
            this.is_print_show_company = false;
        }
    };

    // Disclaimer Toggler
    disclaimer_toggle = () => {
        if (this.is_mounted) {
            this.setState({
                disclaimer_is_expended: !this.state.disclaimer_is_expended
            });
        }
    };

    // Search Criteria Toggler
    controls_toggle = () => {
        if (this.is_mounted) {
            this.setState({
                controls_is_expended: !this.state.controls_is_expended
            });
        }
    };

    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ),
            timeOut: toastr_options.toastr_time_out,
            preventDuplicates: true
        };
    };

    get_formatted_insurance_list = (type = 'insurance_code', list = []) => {
        let formatted_insurance_display = [];
        list.map((insurance_dtls) => {
            formatted_insurance_display.push({
                value: insurance_dtls['id'],
                name:
                    type == 'insurance_code'
                        ? insurance_dtls.insurance_code + '-' + insurance_dtls.insurance_name
                        : insurance_dtls.insurance_class_code + '-' + insurance_dtls.insurance_class_description
            });
        });
        return formatted_insurance_display;
    };

    render() {
        return (
            <div className={'common-forms-add'}>
                <Dimmer active={this.state.loading}>
                    <Loader size={this.props.downloadPdf ? 'small' : 'massive'}>Loading</Loader>
                </Dimmer>
                <div style={{ display: 'flex' }} className={'common-forms-search report-framework'}>
                    <ReportHeader title={this.report_metadata.report_header} />
                    <div
                        id='report-scrollable-area'
                        className='report-wrapper'
                        style={
                            this.props.auto_print ? { display: 'none' } : this.state.is_grid_visible ? { flex: 1 } : {}
                        }
                    >
                        {this.state.search_criteria && (
                            <div
                                className='patient-search-form  patient_search_bottom_padding'
                                id='report-criteria-container'
                            >
                                {this.is_control_visible.length > 0 && (
                                    <ReportController
                                        clear_handler={this.clear_handler}
                                        controls_is_expended={this.state.controls_is_expended}
                                        controls_toggle={this.controls_toggle}
                                        handle_focus_for_grid={this.handle_focus_for_grid}
                                        input_fields={this.input_fields}
                                        is_search_button_disabled={this.state.is_search_button_disabled}
                                        run_report_handler={this.run_report_handler}
                                        set_control_type={this.set_control_type}
                                        show_parm_level_2={this.report_metadata.show_Parm_Lavel_2}
                                    />
                                )}
                            </div>
                        )}
                        {this.state.is_grid_visible && (
                            <React.Fragment>
                                {!this.show_pagination && (
                                    <Grid className='dateTime'>
                                        <Grid.Column tablet={5} computer={4} textAlign='left'>
                                            <p style={{ fontSize: '16px', minHeight: 22 }}>{this.run_report_date}</p>
                                        </Grid.Column>
                                        <Grid.Column tablet={5} computer={8} textAlign='center'>
                                            {
                                                <p style={{ fontSize: '16px', minHeight: 22 }}>
                                                    {`${this.state.report_rows_count} ${
                                                        this.state.report_rows_count == 1
                                                            ? ' record shown'
                                                            : ' records shown'
                                                    }`}
                                                </p>
                                            }
                                        </Grid.Column>
                                        <Grid.Column tablet={2} computer={4} textAlign='right'></Grid.Column>
                                    </Grid>
                                )}
                                <ReportGrid
                                    column={this.report_data.cols}
                                    displayGroupRowCount={this.state.displayGroupRowCount}
                                    emptyMessage={messages.no_records_found}
                                    enableColResize={true}
                                    get_grid_ref={this.get_grid_ref}
                                    headerHeight={this.grid_header_height}
                                    headerIdForTabNavigation={report_grid_first_header_id}
                                    id={`report_id_${this.report_id}`}
                                    isPagination={this.show_pagination}
                                    on_filter_button_click={this.on_filter_button_click}
                                    onGridOut={() => {
                                        set_focus_on_element_with_id('export_report_button');
                                    }}
                                    onGroupByChange={this.on_group_by_Change}
                                    pinnedBottomRowData={this.report_data.ShowFooter && [this.report_data.footer_obj]}
                                    row={this.report_data.rows}
                                    rowGroupPanelShow={this.report_data.allow_grouping}
                                    styles={{ height: '100%', display: 'flex', flex: 1, flexDirection: 'column' }}
                                    suppressColumnVirtualisation={true}
                                    suppressMovableColumns={true}
                                    wrapperClass={'grid_wrapper'}
                                    wrapperStyle={{ width: '100%', height: '100%', display: 'flex', flex: 1 }}
                                />
                            </React.Fragment>
                        )}
                    </div>
                    <div
                        className='ui grid sixteen wide computer sixteen wide tablet column footer-area'
                        id='applicationFooterSticky'
                    >
                        <ReportFooter
                            disclaimer_is_expended={this.state.disclaimer_is_expended}
                            disclaimer_toggle={this.disclaimer_toggle}
                            disclaimer={this.disclaimer}
                            export_disabled={this.state.is_export_disabled}
                            on_export_button={this.on_export_button}
                            on_print_button={this.on_print_button}
                            print_disabled={this.state.is_print_disabled}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            get_patient_search: get_patient_search,
            set_company_name: set_company_name,
            refresh_token: refresh_token,
            set_active_patient: set_active_patient,
            get_master_data: get_master_data,
            get_master_data_by_type: get_master_data_by_type
        },
        dispatch
    );
};

// Get user and login details from store.
const mapStateToProps = (state) => {
    return {
        get_company_name: state.report_details.company_name,
        patient_details: state.patient_details,
        selected_patient: state.patient_details.patient_header,
        shared_details: state.shared_details,
        user_login_details: state.user_login_details,
        features: {
            argosBill1627ExpectedRatesImprovements: get_lauch_darkley_key_value(state.launch_darkly, argos_bill_1627_expected_rates_improvements)
        },
        launch_darkly: state.launch_darkly
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpectedRatesReport);
