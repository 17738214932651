import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Button, Checkbox, Dimmer, Form, Grid, Loader } from 'semantic-ui-react';
import PromptNavigation from '../../../shared/component/prompt_navigation_component';
import * as global_constants from './../../../global_constants';
import { set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import * as constants from '../constants';
import AdvancedControl from './../../../shared/component/advanced_control';
import AutoSearchComponent from './../../../shared/component/auto_search_component';
import * as session_storage from '../../../shared/session_storage_utility';
import { advanced_search_payer, quick_search_payer } from './../../../shared/action/shared_action';
import { get_data, update_data, add_data } from '../action/constants_action';
import PayerList from "./payers_list_component"
import MultiSelectDropDownComponent from '../../../shared/component/multi_select_dropdown_component';
import { get_company_list } from '../../../user/action/change_company_action';
import { company_name } from '../utility';
import { get_lauch_darkley_key_value } from '../../../shared/utility';

class PayerGroupsAddEditComponent extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {            
            is_loading: false,
            form_object: {
                payer_group_code: "",
                payer_group_name: "",
                isa05_qual: "",
                isa_send: "",
                isa07_qual: "",
                isa_rec: "",
                gs_send: "",
                gs_rec: "",
                sender_id_1000a: "",
                output_dir: "",
                break_file: false,
                split_by_pos:false,
                is_active: true,
                payers: [],
                payer_overrides: [],
                company_ids: [],
                is_software_only: false
            },
            error: {
                payer_group_code: false,
                payer_group_name:false,
                available_to_company:false
            },
            selected_payer: null,
            payer_grid_data: null,
            payer_item: [],
            payer_override_item: [],
            company_list: [],
            available_to_company: [],
            by_pass_prompt: false,
            is_save_button_disabled: false
        }
    }
    _is_mounted = false
    page_name: string = 'payer_groups';
    page_metadata: any;
    is_grid_visible = false;
    loading = false;
    token = '';
    initial_form_object = null;
    initial_error_data = null;
    code_id = 0;
    payer_search_enum = 'payer';
    go_next: boolean = false;
    is_cancel: boolean = false;
    payer_item = [];
    payer_override_item = [];
    selected_payer_item = [];
    selected_payer_override_item = [];
    by_pass_prompt: boolean = false;
    company_ids = { user_accessed_ids:[], user_non_accessed_ids:[] };
    updated_payer_list = []
    updated_payer_override_list = []
    UNSAFE_componentWillMount() {
        this.page_metadata = constants.payer_groups;
        this.code_id = this.props.match.params && this.props.match.params.id ? this.props.match.params.id : 0;
        this.initial_form_object = { ...this.state.form_object }
    }

    get_group_by_id = (code_id, data_list) => {
        let url = this.page_metadata.api.get.url;
        url = url.replace('id_value', code_id);
        get_data(this.token, url).then(res => {
            if (res.data && res.data.data) {
                let form_obj = { ...res.data.data }
                this.payer_override_item = [...form_obj.payer_overrides]
                this.payer_item = [...form_obj.payers]
                this.initial_form_object = { ...form_obj };
                this.company_ids = this.get_available_to_company(form_obj.company_ids, data_list)
                
                this.setState({
                    form_object: { ...form_obj },
                    payer_item: [...form_obj.payers],
                    payer_override_item: [...form_obj.payer_overrides],
                    available_to_company: this.company_ids.user_accessed_ids.map(value => value.company_id),
                    company_list: data_list,
                    is_loading: false
                })
            } else {
                toastr.warning('', "No record found");
                this.props.history.push("/billing/payer_groups/search");
            }
        }, error => {
            this.setState({
                is_loading: false
            })
            if (error.response.data)
                toastr.error('', error.response.data.messages[0].message);
        })
    }

    componentDidMount() {
        this._is_mounted = true
        this.set_focus();
        this.token = this.props.user_login_details.user_data.data.token_details.access_token; // update token at component update
        this.code_id = this.props.match.params && this.props.match.params.id ? this.props.match.params.id : 0;
        this.fetch_data(this.token);
    }

    componentWillUnmount() {
        this._is_mounted = false;
        document.body.classList.remove('admin-framework');
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[2] !== 'payer_groups' && session_storage.remove('payer_group_search_criteria');
        }
    }

    fetch_data = async (token) => {
        this.setState({ is_loading: true })
        const data_list = await this.fetch_company(token);
        if (this.code_id) {
            this.get_group_by_id(this.code_id, data_list);
        } else {
            this.setState({
                company_list: data_list,
                is_loading: false
            });
        }
    }

    fetch_company= (token) => {
        return get_company_list(token, '', '').then((res) => {
            let list_data = [];
            if (res.data && res.data.data) {
                list_data = this.option_formatter_multiSelect(res.data.data);
            }
            return list_data;
        });
    }

    option_formatter_multiSelect = (options: any[]) => {
        let formatted_options = options.map((item) => {
            item = {
                ...item,
                name: company_name(item),
                disabled: item.checked || false,
                is_checked: item.checked || false,
                value: item.id,
                on_hover_msg: null
            };
            return item;
        });

        return formatted_options;
    };

    // for cancel button

    cancel_handler = () => {
        this.by_pass_prompt = true;
        this.setState({by_pass_prompt: true},() => this.props.history.goBack());
    };

    get_payer_list = async (search_keyword) => {       
        let result = await quick_search_payer({ keyword: search_keyword},this.token);
        result.data.data = result.data && result.data.data ? result.data.data.sort((a, b) => a.name.localeCompare(b.name)) : result
        return result
    }

    // on provider search item selection
    on_advanced_search_grid_row_election = (selectedRow, type) => {
        if (this._is_mounted) {
            this.setState(prevState => ({
                selected_payer: selectedRow,
                form: {
                    ...prevState.form, insurance_payer: selectedRow
                }
            }));
        }
    }

    // clear data on clear icon
    clear_quick_search = (type) => {
        if (this._is_mounted) {
            this.setState({
                selected_payer: {}
            });
        }
    }

    // get the searched value which is typed for searching location or providers
    get_input_value = (input, type) => {
        if (input.trim().length >= 3) {
            this.get_payer_list(input);
        }
        if (this._is_mounted) {
            const { is_submitted, error } = this.state
            if (is_submitted) {
               this.setState({ form: { ...this.state.form, insurance_payer: null }});
            } else {
               this.setState({ form: { ...this.state.form, insurance_payer: null } });
            }
        }
    }

    //Auto search for class
    renderResult = (props) => {
        return (
            <div key={props.title} tabIndex={0} className="item_auto_search">
                <div key={props.title} className="fs_13">
                    <span>{props.label}</span>
                </div>
            </div>

        )
    }


    row_selection_format = (item, type) => {
        var formatted = null;
        if (type == this.payer_search_enum) {
            formatted = {
                "title": `${item.payer_key_id}`,
                "code": item.name,
                "description": item.description,
                "id": item.payer_key_id,
                "label": (item.name) + " - " + (item.description)
            }
        }
        return formatted;
    }
    // preapare the suggestion list with search result
    prepare_suggestion = (data, type) => {
        let formattedList = [];
        var data_length = data.data.length;
        if (data && data_length) {
            for (let i = 0; i < data_length; i++) {
                let item = data.data[i];
                if (type == this.payer_search_enum) {
                    formattedList.push({
                        "title": `${item.payer_key_id}`,
                        "code": item.name,
                        "description": item.description,
                        "id": item.payer_key_id,
                        "label": (item.name) + (item.description ? `- ${item.description}` : '')
                    });
                }
            }
        }
        return formattedList;
    }


    on_item_selection = (item, type) => {

       // this.handle_selection_errors(item, type);
        this.on_advanced_search_grid_row_election(item, type);
    }

    handle_selection_errors = (item, type) => {
        const error = { ...this.state.error };
        if (type == this.payer_search_enum) {
            error.payer_key_id = item ? (item.id || item.payer_key_id ? false : true) : true;
        }
        if (this._is_mounted) {
            this.setState({
                error: error
            });
        }
    }

    on_advanced_grid_row_select = (item, type) => {
      //  this.handle_selection_errors(item, type);
        const prepared_result = this.row_selection_format(item, type);
        this.on_advanced_search_grid_row_election(prepared_result, type);
    }

    // search location by typing name or code
    on_advance_search = async (params, type) => {
        var search_data = null;
        var config_column = null;
        var grid_type = '';
        if (type == this.payer_search_enum) {
            params.Description = typeof params.Description == 'undefined' ? '' : params.Description;
            params.Outbound_id = typeof params.Outbound_id == 'undefined' ? '' : params.Outbound_id;
            params.Inbound_id = typeof params.Inbound_id == 'undefined' ? '' : params.Inbound_id;
            params.name = typeof params.name == 'undefined' ? '' : params.name;
            search_data = await advanced_search_payer(params, this.token).then(res => res.data);
            config_column = constants.payer_groups.payer_column_defs
            grid_type = 'payer_grid_data'
        }
        const search_result = search_data && search_data.data !== null ? search_data.data : [];
        const grid_data = { ...this.state.grid_conf, rows: search_result, column: config_column, messages: search_data.messages, paginationPageSize: 20 };
        if (this._is_mounted) {
            this.setState({ [grid_type]: grid_data });
        }
    }
    add_payers = () => {
        const { selected_payer } = this.state
        if (selected_payer && Object.keys(selected_payer).length > 0 && this._is_mounted) {
            let find_obj = this.payer_item.filter(item => item.payer_id == selected_payer.id)
            if (find_obj.length == 0) {
                this.payer_item.push({
                    payer_id: selected_payer.id,
                    name: selected_payer.code,
                    description: selected_payer.description,
                    action: 1
                }),
                this.updated_payer_list.push({
                    payer_id: selected_payer.id,
                    name: selected_payer.code,
                    description: selected_payer.description,
                    action: 1 
                })
                this.setState({
                    payer_item: [...this.payer_item],
                    selected_payer: {}
                })
            } else {
                toastr.error('', 'There cannot be Duplicate Payers');
            }            
        }
        
    }

    handle_payer_list_chkbk = (items, payer_type) => {         
        if (payer_type == "payer_override") {
            this.selected_payer_override_item = items
        } else {
            this.selected_payer_item = items
        }          
    }

    remove_payer = (e, type) => {
        const selected_filter = type === 'payer_override' ? 'payer_details_id' : 'payer_id';
        if (this[`selected_${type}_item`].length > 0 && this._is_mounted) {
          let selected_items: any = this[`selected_${type}_item`].map((item) => item[selected_filter]);
          this[`${type}_item`] = this[`${type}_item`].filter((item) => {
            if (!selected_items.includes(item[selected_filter])) {
              return item;
            } else {
              this[`updated_${type}_list`].push({
                ...item,
                action: 2
              });
            }
          });
          this.setState({
            [`${type}_item`]: [...this[`${type}_item`]]
          });
          this[`selected_${type}_item`] = [];
        } else {
          toastr.warning('', 'Please select a payer');
        }
    }
    validate_form = () => {
        const { error, form_object, available_to_company } = this.state
        error.payer_group_code = form_object.payer_group_code.trim() == '' ? true : false
        error.payer_group_name = form_object.payer_group_name.trim() == '' ? true : false
        if (this.props.rsiB31486AddCompanyMultiSelectDropDownToPayersPage) {
            error.available_to_company = available_to_company.length == 0 ? true : false
        }
        if (this._is_mounted) {
            this.setState({
                error: error,
                is_submitted: true
            });
        }
        if (Object.keys(error).some((data) => { return error[data] == true })) {
            toastr.error('', constants.mandatory_fields);
            return false;
        }
        return true;
    }

    get_available_to_company = (company_ids: any[], data_list: any[]) => {
        let user_accessed_ids = [];
        let user_non_accessed_ids = [];
        if (company_ids && company_ids.length === 1 && company_ids[0].company_id == -1) {
            user_accessed_ids = company_ids;
        }
        else if (company_ids && company_ids.length > 0) {
            user_accessed_ids = company_ids.filter(value => {
                if (data_list.some((item) => item.id == value.company_id)) {
                    return true;
                }

                user_non_accessed_ids.push(value);
                return false;
            });
        }
        return { user_accessed_ids, user_non_accessed_ids};
    }

    save_handle = async (e, from_pop_up) => {
        e.preventDefault();
        this.go_next = false;      
        if (this.validate_form()) {            
            this.setState({
                is_loading: true,
                is_save_button_disabled: true
            })
            let { form_object } = this.state
            for (let name in form_object) {
                form_object[name] = form_object[name] && (typeof form_object[name] !== "object" &&  typeof form_object[name] !== "boolean") ? form_object[name].toString().trim() : form_object[name];
            }
            form_object.payers = [...this.updated_payer_list]
            form_object.payer_overrides = [...this.updated_payer_override_list]
            // Available to company params
            let ids = this.state.available_to_company.map(value => ({ company_id: value }));
            form_object.company_ids = [...this.company_ids.user_non_accessed_ids, ...ids]

            session_storage.set('payer_group_search_criteria', {});
            if (this.code_id) {
                let url = this.page_metadata.api.update.url;
                url = url.replace('id_value', this.code_id);
                update_data(this.token, url, form_object).then(res => {
                    if (res.data && res.data.data) {
                        this.go_next = true;                        
                        this.initial_form_object = { ...form_object }
                        toastr.success('', constants.payer_groups.messages.update);                     
                        this.setState({
                            is_loading: false,
                            by_pass_prompt: !from_pop_up ? true : false,
                            is_save_button_disabled: false
                        })
                        if (!from_pop_up && this.go_next) {
                            this.go_next = false;
                            this.props.history.goBack();
                        }    
                    } else {
                        this.setState({
                            is_loading:false,
                            is_save_button_disabled: false
                        })
                        if (res.data.messages[0].message.indexOf('<br') > -1) {
                            this.show_html_content_toaster(res.data.messages[0].message);
                        } else {
                            toastr.error('', res.data.messages[0].message);
                        }

                    }
                }, error => {
                    if (this._is_mounted) {
                        this.go_next = false;
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false
                        });
                    }
                    if (error.response.data) toastr.error('', error.response.data.messages[0].message);
                })
            } else {
                let url = this.page_metadata.api.add.url;
                add_data(this.token, url, form_object).then(res => {
                    if (res.data && res.data.data) {
                        this.go_next = true;
                        toastr.success('', constants.payer_groups.messages.save);
                        this.setState({
                            is_loading: false,
                            by_pass_prompt: !from_pop_up ? true : false,
                            is_save_button_disabled: false
                        });
                        this.initial_form_object = { ...form_object }                        
                        if (!from_pop_up && this.go_next) {
                            this.go_next = false;
                            this.props.history.goBack();
                        }
                    } else {
                        if (res.data.messages[0].message.indexOf('<br') > -1) {
                            this.show_html_content_toaster(res.data.messages[0].message);
                        } else {
                            toastr.error('', res.data.messages[0].message);
                        }
                        this.go_next = false;
                        if (this._is_mounted) {
                            this.setState({
                                is_loading: false,
                                is_save_button_disabled: false
                            });
                        }
                    }
                }, error => {
                    if (this._is_mounted) {
                        this.go_next = false;
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false
                        });
                    }
                    if (error.response.data) toastr.error('', error.response.data.messages[0].message);
                })
            }
        }
    }
    input_change_handler = (e) => {
        const { form_object, error, is_submitted } = this.state
        const { name, value } = e.target
        form_object[name] = value
        if ((name == "payer_group_code" || name == "payer_group_name") && is_submitted) {
            error[name] = value.trim()=='' ? true :false
        }
        if (this._is_mounted) {
            this.setState({
                form_object
            })
        }
    }
    show_html_content_toaster = msg => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ),
            timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        };
    };
    set_focus = () => {
        document.body.classList.add('admin-framework');
        let elem = document.getElementById('search-criteria-container');
        let all_form_elem = elem.getElementsByTagName('input');
        all_form_elem[0].focus();
    }

    // multi select change handler
    multi_select_handler = (id, values) => {
        const { error, is_submitted } = this.state;

        this.state.company_list.map((item) => {
            if (values.indexOf(item.value) > -1) {
                item = {
                    ...item,
                    checked: true,
                    is_checked: true
                };
            } else {
                item = {
                    ...item,
                    checked: false,
                    is_checked: false
                };
            }
            return item;
        });

        if (is_submitted) {
            error['available_to_company'] = values.length > 0 ? false : true
        }

        if (this._is_mounted) {
            //this.title_search_criteria[id] = `${values.length} Selected`;
            this.setState((prevState: { form_object: any }) => ({
                [id]: [...values]
            }));
        }
    };

    // Render multiSelect Control
    render_multiSelect_element = (error) => {
        if (this.props.rsiB31486AddCompanyMultiSelectDropDownToPayersPage) {
            let selectedCompanies = [...this.state.available_to_company];
            let is_include_future_selected = false;
            if (selectedCompanies && selectedCompanies.length === 1 && selectedCompanies[0] === -1) {
              is_include_future_selected = true;
            }

            return (<Grid.Column mobile={16} tablet={8} computer={8}>
                <Form.Field>
                    <label>Available to Company <span className={error.available_to_company ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                    <MultiSelectDropDownComponent
                        class_name='manage-content-multi-select'
                        id={'available_to_company'}
                        key={'available_to_company'}
                        key_id={'available_to_company'}
                        data={this.state.company_list}
                        selected_list={selectedCompanies}
                        onChangeSelected={this.multi_select_handler}
                        forceOnChangeSelected={true}
                        has_validation={error.available_to_company}
                        show_filter={true}
                        reset_disable={true}                        
                        include_future_selected={is_include_future_selected}
                        include_future={true}
                    />
                </Form.Field>
            </Grid.Column>);
        }

        return null;
    }

    render() {
        const { form_object, is_loading, selected_payer, error, payer_grid_data, payer_item, payer_override_item, by_pass_prompt } = this.state
         return (<>
                    {!by_pass_prompt && (
                <PromptNavigation
                            is_data_changed={((JSON.stringify(this.initial_form_object) !== JSON.stringify(form_object))
                            || (JSON.stringify(payer_item) !== JSON.stringify(form_object.payers))
                            || (JSON.stringify(payer_override_item) !== JSON.stringify(form_object.payer_overrides)))}
                            save={e => this.save_handle(e, true)}
                            go_next_location={this.go_next}
                            history={this.props.history}
                            set_focus={this.set_focus}
                        />
                    )}                 
                    <Dimmer active={is_loading}>
                        <Loader size='massive'>Loading</Loader>
                    </Dimmer>
                    <div className={'admin-wrapper  view-edit ' + this.page_metadata.id} style={payer_group_css.payer_group_id}>
                        <Grid className='headerGrid' style={payer_group_css.headerGrid}>
                            <Grid.Column computer={16}>
                                <h3 className='ui header left aligned'>{this.page_metadata.add_header}</h3>
                            </Grid.Column>
                        </Grid>
                        <div className='wrapper' style={payer_group_css.wrapper}>
                            <Form autoComplete='off' name='view_edit_form' className='common-forms'>
                                <div id='admin-scrollable-area' style={payer_group_css.scrollbar_area}>
                                    <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container' style={{ paddingBottom: 0 }}>
                                        <Grid>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field className={error.payer_group_code ? "requiredWithBgColor error" : ''} >
                                                <label>Payer Group Code <span className={error.payer_group_code ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                <input
                                                    autoComplete="off"
                                                    name='payer_group_code'
                                                    id="payer_group_code"
                                                    value={form_object.payer_group_code|| ""}
                                                    onChange={this.input_change_handler}
                                                    maxLength={50}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field className={error.payer_group_name ? "requiredWithBgColor error" : ''} >
                                                <label>Description <span className={error.payer_group_name ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                <input
                                                    autoComplete="off"
                                                    name='payer_group_name'
                                                    id="payer_group_name"
                                                    value={form_object.payer_group_name || ""}
                                                    onChange={this.input_change_handler}
                                                    maxLength={50}
                                                />
                                            </Form.Field>
                                    </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>ISA05 Qualifier</label>
                                                <input
                                                    autoComplete="off"
                                                    name='isa05_qual'
                                                    value={form_object.isa05_qual || ""}
                                                    onChange={this.input_change_handler}
                                                    maxLength={50}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field>
                                            <label>ISA Sender ID</label>
                                                <input
                                                    autoComplete="off"
                                                    name='isa_send'
                                                    id="isa_send"
                                                    value={form_object.isa_send || ""}
                                                    onChange={this.input_change_handler}
                                                    maxLength={15}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>ISA07 Qualifier</label>
                                                <input
                                                    autoComplete="off"
                                                    name='isa07_qual'
                                                    id='isa07_qual'
                                                    value={form_object.isa07_qual || ""}
                                                    onChange={this.input_change_handler}
                                                    maxLength={50}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>ISA Receiver ID</label>
                                                <input
                                                    autoComplete="off"
                                                    name='isa_rec'
                                                    id='isa_rec'
                                                    value={form_object.isa_rec || ""}
                                                    onChange={this.input_change_handler}
                                                    maxLength={15}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field>
                                            <label>GS Sender ID</label>
                                                <input
                                                    autoComplete="off"
                                                    name='gs_send'
                                                    id='gs_send'
                                                    value={form_object.gs_send || ""}
                                                    onChange={this.input_change_handler}
                                                    maxLength={15}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>GS Receiver ID</label>
                                                <input
                                                    autoComplete="off"
                                                    name='gs_rec'
                                                    id='gs_rec'
                                                    value={form_object.gs_rec || ""}
                                                    onChange={this.input_change_handler}
                                                    maxLength={15}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                                <Form.Field>
                                                <label>Sender ID 1000A</label>
                                                    <input
                                                        autoComplete="off"
                                                        name='sender_id_1000a'
                                                        id='sender_id_1000a'
                                                        value={form_object.sender_id_1000a || ""}
                                                        onChange={this.input_change_handler}
                                                        maxLength={50}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label>Output Directory</label>
                                                    <input
                                                        autoComplete="off"
                                                        name='output_dir'
                                                        value={form_object.output_dir || ""}
                                                        onChange={this.input_change_handler}
                                                        maxLength={500}
                                                    />
                                                </Form.Field>
                                        </Grid.Column>
                                        {this.render_multiSelect_element(error)}
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label>&nbsp;</label>
                                                <Checkbox className={'payer_group_checkbox'} label='Break File' name='break_file' id='break_file' checked={form_object.break_file ? form_object.break_file : false}
                                                        onChange={(e) => this.setState({ form_object: { ...form_object, break_file: !form_object.break_file } })}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label>&nbsp;</label>
                                                <Checkbox className={'payer_group_checkbox'} label='Split by POS' name='split_by_pos' id='split_by_pos' checked={form_object.split_by_pos ? form_object.split_by_pos : false}
                                                        onChange={(e) => this.setState({ form_object: { ...form_object, split_by_pos: !form_object.split_by_pos } })}
                                                    />
                                                </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                             <Form.Field>
                                                 <label>&nbsp;</label>
                                             <Checkbox className={'payer_group_checkbox'} label='Software Only' name='is_software_only' id='is_software_only' checked={form_object.is_software_only ? form_object.is_software_only : false}
                                                 onChange={(e) => this.setState({ form_object: { ...form_object, is_software_only: !form_object.is_software_only } })}
                                                 />
                                             </Form.Field>
                                         </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label>&nbsp;</label>
                                                <Checkbox className={'payer_group_checkbox'} label='Is Active' name='is_active' id='is_active' checked={form_object.is_active ? form_object.is_active : false}
                                                        onChange={(e) => this.setState({ form_object: { ...form_object, is_active: !form_object.is_active } })}
                                                    />
                                                </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                        <Grid>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field className="advance-quick-search">
                                                <label>Payers</label>
                                                <AdvancedControl
                                                    onGridRowSelection={(row) => this.on_advanced_grid_row_select(row, this.payer_search_enum)}
                                                    gridConfig={payer_grid_data}
                                                    controlId={'PAYER_DESCRIPTION'}
                                                    onSearch={param => this.on_advance_search(param, this.payer_search_enum)}
                                                    search_type={'PAYER_DESCRIPTION'}
                                                    headerIdForGridTabNavigation={constants.payer_groups.payer_header_id}
                                                />
                                                <AutoSearchComponent
                                                    errorClass={''}
                                                    getInputValue={e => this.get_input_value(e, this.payer_search_enum)}
                                                    default_value={selected_payer}
                                                    errorMessage={'No Record Found !'}
                                                    prepareRenderList={this.renderResult}
                                                    getList={this.get_payer_list}
                                                    prepareDataList={(data) => this.prepare_suggestion(data, this.payer_search_enum)}
                                                    selectresult={item => this.on_item_selection(item, this.payer_search_enum)}
                                                    show_clear_search={true} clear_search={() => this.clear_quick_search(this.payer_search_enum)}
                                                />
                                            </Form.Field>                                    
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field>
                                            <label>&nbsp;</label>
                                            <Button id='add_payer' type='button' onClick={this.add_payers} primary className="add_payer_groups">Add Payer</Button>
                                            </Form.Field>
                                        </Grid.Column>
                                        <PayerList
                                            btn_label="Remove Selected Payer"
                                            id="payer"
                                            payer_list={payer_item}
                                            on_check_box_selection={(items, payer_type) => { this.handle_payer_list_chkbk(items, payer_type) }}
                                            remove_payer={this.remove_payer}
                                        />
                                        <PayerList
                                            btn_label="Remove Selected Override"
                                            id="payer_override"
                                            payer_list={payer_override_item}
                                            on_check_box_selection={(items, payer_type) => { this.handle_payer_list_chkbk(items, payer_type) }}
                                            remove_payer={this.remove_payer} 
                                        />
                                    </Grid>
                                    </div>  
                                </div>
                            </Form>
                        </div>
                        <div className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area' id='applicationFooterSticky' style={payer_group_css.footerArea}>
                            <Button id='cancel_button_id' type='button' onClick={(e) => { this.cancel_handler() }} basic>Cancel</Button>
                            <Button type='submit' onKeyDown={set_focus_to_app_header} onClick={(e) => { this.save_handle(e, false) }} id='save_button_id' primary style={payer_group_css.save_button} disabled={this.state.is_save_button_disabled}>Save</Button>
                        </div>
                    </div>
                </>
            )
    }

}
const payer_group_css = {
    payer_group_id: {
        paddingBottom: 0
    },
    headerGrid: {
        marginTop: 0,
        marginBottom: 0,
        paddingRight: 15
    },
    wrapper: {
        marginBottom: 0
    },
    common_forms: {
        paddingTop: 0,
        height: '100%',
        paddingBottom: 0,
        display: 'flex',
        flexDirection: 'column'
    } as React.CSSProperties,

    patient_search_form: {
        paddingBottom: 0,
        flexBasis: 'auto',
        borderColor: '#cccccc',
        borderStyle: 'solid',
        borderWidth: '2px 1px 1px',
        margin: '0 0 15px 0px',
        background: '#fff',
        padding: '25px 15px'
    },
    footerArea: {
        paddingLeft: 0,
        paddingBottom: 0,
        marginBottom: 12,
        paddingRight: 25,
        paddingTop: 20
    },
    scrollbar_area: {
        height: '100%',
        paddingRight: 15
    } as React.CSSProperties,
    save_button: {
        marginRight: 0
    }
};
const mapSTateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        rsiB31486AddCompanyMultiSelectDropDownToPayersPage: get_lauch_darkley_key_value(state.launch_darkly, "rsiB31486AddCompanyMultiSelectDropDownToPayersPage")
    };
}

export default connect(mapSTateToProps, null)(PayerGroupsAddEditComponent);