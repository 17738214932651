import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import { Button, Dimmer, Form, Grid, Loader, Modal, Header, Table, GridColumn } from "semantic-ui-react";
import * as NumberFormat from 'react-number-format';

import { update_patient_header_info } from '../../patient/action/patient_header_action';
import AutoSearchComponent from './../../shared/component/auto_search_component';
import AdvancedControl from "../../shared/component/advanced_control";
import Selection from '../../shared/component/selection_component';
import CurrencyFormatter from '../../shared/component/currency_formatter';
import * as global_constants from '../../global_constants';
import { procedure_code_advance_search, procedure_code_quick_search } from '../../shared/action/autosearch_action';
import * as  shared_constants from '../../shared/shared_constants';
import ChargeDetailRowComponent from '../component/charge_details_row_component';
import { get_procedure_code_modifiers } from '../../shared/action/shared_action';
import { charge_messages, charge_status, enum_page_name } from '../charge_constant';
import { get_case_diagnosis_code } from '../../diagnosis/action/diagnosis_action';
import ConfirmationComponent from '../../shared/component/confirmation_component';
import { delete_charge } from './../action/charge_action';
import {get_lauch_darkley_key_value} from '../../shared/utility';
export class ChargeDetailComponent extends React.Component<any, any> {
    [x: string]: any;
    _is_mounted = false;
    _deleted_charges = [];
    _existing_charge = [];
    _all_charge = [];
    _kx_modifier_id = '';
    
    constructor(props) {
        super(props);
        this.state = {
            procedure_code_modifiers1_list: [],
            procedure_code_modifiers2_list: [],
            procedure_code_modifiers3_list: [],
            select_all: false,
            clear_charge: false,
            update_child_force: false,
            selected_charge: [],
            default_charge_detail_item: {
                proc_code: {
                    procedure_code_id: 0,
                    proc_code: "",
                    is_unit_enabled: "true",
                    is_amt_enabled: "false",
                    std_chg_amt: '',
                    title: '',
                    label: ''
                },
                checked: false,
                is_active: true,
                status: 0,
                status_message: '',
                cpt_code: '',
                units: '1',
                proc_mod1: '',
                proc_mod2: '',
                proc_mod3: '',
                dx: '',
                total_amount: '0'
            },
            charge_detail: [

            ],
            deleted_charges: [],
            charge_delete_confirmation_box: false,
            loading: false,
            show_error_message_popup: false,
            error_popup_message: '',
            error_popup_title: '',
            error_popup_ok_btn_text: '',
            error_popup_show_cancel: false,
            error_popup_action: () => {},
            payment_associated_with_visit : false
        }
        this.rowNodes = []
    }
    componentDidMount = () => {
        if(this.props.location && this.props.location.state && this.props.location.state.hasOwnProperty('payment_associated_with_visit')){
            this.setState({payment_associated_with_visit:this.props.location.state.payment_associated_with_visit})
        } 
        this._is_mounted = true;
        if (this.props.onRef) {
            this.props.onRef(this);
        }
        this.get_procedure_code_modifiers_list();
        if (this.props.charge_id <= 0) {
            setTimeout(() => {
                this.default_charge_row();
            }, 1000);
        }
    }
    componentWillUnmount = () => {
        this._is_mounted = false;
        if (this.props.onRef) {
            this.props.onRef(undefined);
        }
    }
        
    default_charge_row = () => {

        if (this._is_mounted) {
            this.setState({
                select_all: false,
                charge_detail: []
            }, () => {
                this.get_case_dx_data().then((data) => {
                    var availableDiagnosis = this.available_diagnosis(data);
                    var charge_detail_list = [];
                    //generate deafult 5 row 
                    var default_row = 5;
                    for (var i = 0; i < default_row; i++) {
                        var charge_item = { ...this.state.default_charge_detail_item };
                        charge_item.dx = availableDiagnosis;
                        charge_detail_list.push(charge_item);
                        if (this._is_mounted) {
                            this.setState({
                                select_all: false,
                                charge_detail: charge_detail_list
                            });
                        }
                    }
                })
               
            })
        }

    }

    //Component update function
    componentDidUpdate(previousProps, previousState) {       
        if (previousProps.selected_case_id !== this.props.selected_case_id && this.props.charge_id == 0) {
            this.get_case_dx_data().then((data) => {
                var availableDiagnosis = this.available_diagnosis(data);
                var charge_detail_list = this.get_all_charge_data();
                for (var i = 0; i < charge_detail_list.length; i++) {
                    charge_detail_list[i].dx = availableDiagnosis;
                }
                if (this._is_mounted) {
                    this.setState({
                        charge_detail: charge_detail_list
                    })
                }
            });            
        }
    }
    get_case_dx_data = async () => {
        var case_diagnosis = await get_case_diagnosis_code(this.props.selected_case_id, this.props.user_login_details.user_data.data.token_details.access_token);
        var data = (case_diagnosis.data == null || case_diagnosis.data.data == null) ? [] : case_diagnosis.data.data;
        return data;
    }

    available_diagnosis = (data) => {
        var diagnosisCount = 0;
        var availableDiagnosis = data.filter(function (obj, index) {
            if (diagnosisCount < 8 && obj.is_active == true) {
                diagnosisCount++;
                return obj;
            }
        }).map(function (elem) {
            return elem.sequence;
        }).join(",");

        return availableDiagnosis;
    }

    add_new_charge_row = () => {
        var charge_detail_list = this.get_all_charge_data();

        var availableDiagnosis = this.available_diagnosis(this.props.old_dx_list);

        var charge_item = { ...this.state.default_charge_detail_item };
        charge_item.dx = availableDiagnosis;

        charge_detail_list.push(charge_item);
        if (this._is_mounted) {
            this.setState({
                select_all: false,
                charge_detail: charge_detail_list
            });
        }
    }

    get_procedure_code_modifiers_list = async () => {

        await get_procedure_code_modifiers(this.props.user_login_details.user_data.data.token_details.access_token)
            .then(response => {
                if (response.data.data) {
                    if (this._is_mounted) {
                        this.setState({
                            procedure_code_modifiers1_list: this.update_data_modifier(response.data.data, 1),
                            procedure_code_modifiers2_list: this.update_data_modifier(response.data.data, 2),
                            procedure_code_modifiers3_list: this.update_data_modifier(response.data.data, 3)
                        });
                    }
                }
                else {
                    if (this._is_mounted) {
                        this.setState({
                            procedure_code_modifiers1_list: this.update_data_modifier([], 1),
                            procedure_code_modifiers2_list: this.update_data_modifier([], 2),
                            procedure_code_modifiers3_list: this.update_data_modifier([], 3)
                        });
                    }
                }
            }, (error) => {
                if (this._is_mounted) {
                    this.setState({
                        procedure_code_modifiers1_list: this.update_data_modifier([], 1),
                        procedure_code_modifiers2_list: this.update_data_modifier([], 2),
                        procedure_code_modifiers3_list: this.update_data_modifier([], 3)
                    });
                }
            })

    }

    update_data = (source, modifire1) => {
        var target = modifire1 ? [{ "text": '-- Please Select a Modifier 1 --', "value": global_constants.constants.dropdown_default_info.value_string, "key": "-- Please Select a Modifier 1 --" }]
            : [{ "text": '-- Please Select a Modifier 2 --', "value": global_constants.constants.dropdown_default_info.value_string, "key": "-- Please Select a Modifier 2 --" }];
        var source_length = source.length;

        if (source && source_length > 0) {
            for (let y = 0; y < source_length; y++) {
                let item = source[y];
                if (item.procedure_modifier_code && item.procedure_modifier_code=='KX') {
                    this._kx_modifier_id = item.id;
                }

                target.push({
                    "text": (item.procedure_modifier_code ? item.procedure_modifier_code : '') + ' - ' + (item.procedure_modifier_desc ? item.procedure_modifier_desc : ''),
                    "value": item.id,
                    "key": item.id
                })
            }
        }

        return target;

    }
    update_data_modifier = (source, modifire1) => {
        var target = modifire1 == 1 ? [{ "text": '-- Please Select a Modifier 1 --', "value": global_constants.constants.dropdown_default_info.value_string, "key": "-- Please Select a Modifier 1 --", "code": '0' }]
            : modifire1 == 2 ? [{ "text": '-- Please Select a Modifier 2 --', "value": global_constants.constants.dropdown_default_info.value_string, "key": "-- Please Select a Modifier 2 --", "code": '0' }]
                : [{ "text": '-- Please Select a Modifier 3 --', "value": global_constants.constants.dropdown_default_info.value_string, "key": "-- Please Select a Modifier 3 --", "code": '0' }];
        var source_length = source.length;

        if (source && source_length > 0) {
            for (let y = 0; y < source_length; y++) {
                let item = source[y];
                if (item.procedure_modifier_code && item.procedure_modifier_code == 'KX') {
                    this._kx_modifier_id = item.id;
                }

                target.push({
                    "text": (item.procedure_modifier_code ? item.procedure_modifier_code : '') + ' - ' + (item.procedure_modifier_desc ? item.procedure_modifier_desc : ''),
                    "value": item.id,
                    "key": item.id,
                    "code": item.procedure_modifier_code
                })
            }
        }

        return target;

    }
    on_check_change_charge = (event,rsiB35911ErrorCorrectionsPreventUserFromAddingNcModifierToChargeWithActivePayments) => {
        if (event.target.checked) {
            let row_items = [];
            this.rowNodes.map((node) => {
                if (node) {
                    var item = node.get_updated_data();
                    if ((item.status != charge_status.LOCKED) && !(rsiB35911ErrorCorrectionsPreventUserFromAddingNcModifierToChargeWithActivePayments && item.payment_associated_with_charge)) {
                        item.checked = true;
                    }
                    row_items = [...row_items, item];
                }
            })
            if (this._is_mounted) {
                this.setState({
                    charge_detail: row_items,
                    select_all: true,
                });
            }
        } else {
            let row_items = [];
            this.rowNodes.map((node) => {
                if (node) {
                    var item = node.get_updated_data();
                    item.checked = false;
                    row_items = [...row_items, item];
                }
            })
            if (this._is_mounted) {
                this.setState({
                    select_all: false,
                    charge_detail: row_items
                })
            }
        }
    }

    reverse_select_all = (checked, row_index) => {
        let row_items = [];
        this.rowNodes.map((node) => {
            if (node) {
                var item = node.get_updated_data();
                if (node.is_checked()) {
                    row_items = [...row_items, item];
                }
            }
        })
        if (row_items.length == this.state.charge_detail.length) {
            if (!this.state.select_all && this._is_mounted) {
                this.setState({
                    select_all: true,
                });
            }

        } else {
            if (this.state.select_all && this._is_mounted) {
                this.setState({
                    select_all: false,
                });
            }
        }
    }
    set_charge_detail_data(charge_data) {
        if (this._is_mounted) {
            this.setState({
                charge_detail: charge_data,
                update_child_force: true
            }, () => {
                if (this._is_mounted) {
                    this.setState({
                        update_child_force: false
                    });
                }
            })
        }
    }

    get_all_charge_data = () => {
        let row_items = [];
        this.rowNodes.map((node) => {
            if (node) {
                row_items = [...row_items, node.get_updated_data()];
            }
        })
        return row_items;
    }
    remove_charge_row = () => {
        var is_deleted = false, is_selected = false;
        this._existing_charge = [];
        this._deleted_charges = [];
        this._all_charge = [];       
        var charge_detail_list = this.get_all_charge_data();
        charge_detail_list.map((node) => {
            if (node) {
                if (node.checked) {
                    is_selected = true;
                    this._all_charge.push(node);
                    if (node.charge_id > 0) {
                        this._deleted_charges = [...this._deleted_charges, { charge_id: node.charge_id, charge_open_time: node.charge_open_time }];                        
                        is_deleted = true;
                    }
                    else {
                        node.is_active = false;
                    }
                } else {
                    this._all_charge = [...this._all_charge, node];
                    this._existing_charge = [...this._existing_charge, node];                    
                }

            }
        })
        var charges = this._all_charge;
        var delete_confirm = this.state.charge_delete_confirmation_box;
        if (!is_selected) {
            toastr.warning('', charge_messages.no_charge_selected);
        }
        else if (!is_deleted) {
            charges = this._existing_charge;
        }
        else if (this._deleted_charges == null || this._deleted_charges == undefined || this._deleted_charges.length < 1) {
            toastr.warning('', charge_messages.no_charge_selected);
        }
        else {
           //pop up come for confirmation 
            delete_confirm = true;
        }
        if (this._is_mounted) {
            this.setState({
                select_all: false,
                charge_detail: charges,
                update_child_force: true,
                charge_delete_confirmation_box: delete_confirm
            }, () => {
                if (this._is_mounted) {
                    this.setState({
                        update_child_force: false
                    });
                }
            });
        }
    }
    remove_charge_row_old = () => {
        let row_items = [];
        let selected_row = [];
        let deleted_row = [];
        var charge_detail_list = this.get_all_charge_data();
        charge_detail_list.map((node) => {
            if (node) {
                if (node.checked) {
                    if (node.charge_id > 0) {
                        //node.is_active = false;
                        deleted_row.push(node.charge_id);
                    }
                    else {
                        node.is_active = false;
                        selected_row.push(node);
                    }
                } else {
                    row_items = [...row_items, node];
                }

            }
        })
        if (selected_row.length > 0 && this._is_mounted) {
            this.setState({
                select_all: false,
                charge_detail: row_items,
                update_child_force: true
            }, () => {
                if (this._is_mounted) {
                    this.setState({
                        update_child_force: false
                    });
                }
            });
        }
        if (deleted_row.length > 0 && this._is_mounted) {
            this.setState({
                select_all: false,               
                charge_delete_confirmation_box: true,
                deleted_charges: deleted_row,               
            }, () => {                
            });
        }
        if (selected_row.length <= 0) {
            toastr.warning('', charge_messages.no_charge_selected);
        }
        else if (deleted_row.length <= 0) {
            toastr.warning('', charge_messages.no_charge_selected);
        }

    }
    on_delete_charge = () => {
        delete_charge(this.props.user_login_details.user_data.data.token_details.access_token, this._deleted_charges).then(res => {
            let response: global_constants.R6ResponseData = res.data;
            if (response.data) {
                //
                let row_items = [];
                var charge_detail_list = this.get_all_charge_data();
                let deleted_charge = this._deleted_charges;
                charge_detail_list.map((node) => {
                    if (node) {
                        var selected_charge = deleted_charge.filter((val, index) => val == node.charge_id);
                        if (node.checked) {
                            if (selected_charge[0] > 0) {
                                node.is_active = false;
                            }
                        }
                        else {
                            row_items = [...row_items, node];
                        }

                    }
                })
                if (this._is_mounted) {
                    this.setState({
                        select_all: false,
                        charge_detail: row_items,
                        update_child_force: true,
                        deleted_charges: []
                    }, () => {
                        if (this._is_mounted) {
                            this.setState({
                                update_child_force: false
                            });
                        }
                    });
                }
                //
                toastr.success('', charge_messages.charges_deleted);
                this.on_close_confirmation();
            } else {
                if (response.messages.length > 0 && response.messages[0].code == 500006) {
                    this.show_modal_popup(global_constants.charge_contains_payment_exception.title,
                        global_constants.charge_contains_payment_exception.message, "OK", this.on_close_error_confirmation);
                }
            }
        });
    }
    
    recalculate_charge_amount = async() => {
        if (this.props.ff_sumaj_B30566_expected_rate_multiple_fee_schedules) {
            this.setState({ loading: true });
            
            for (let i = 0; i < this.rowNodes.length; i++) {
                if(this.rowNodes[i]) await this.rowNodes[i].recalculate_charge_amount();
            }
            
            this.setState({ loading: false });
        }
    }

    // UI Control Section

    show_modal_popup(title: string, message: string, okBtnText: string, action: () => any, showCancel: boolean = false) {
        if (this._is_mounted) {
            this.setState({
                loading: false,
                show_error_message_popup: true,
                error_popup_message: message,
                error_popup_title: title,
                error_popup_ok_btn_text: okBtnText,
                error_popup_show_cancel: showCancel,
                error_popup_action: action
            })
        }
    }

    on_close_confirmation = () => {
        if (this._is_mounted) {
            this.setState({
                charge_delete_confirmation_box: false
            })
        }
    }

    on_cancel_click = () => {
        if (this._is_mounted) {
            this.setState({
                select_all: false,
                charge_detail: this._all_charge,
                update_child_force: true,
                deleted_charges: []
            }, () => {
                if (this._is_mounted) {
                    this.setState({
                        update_child_force: false
                    });
                }
            });
        }
    }

    on_close_error_confirmation() {
        if (this._is_mounted){
            this.setState({
                show_error_message_popup: false,
                charge_delete_confirmation_box: false
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.charge_delete_confirmation_box ?
                    <ConfirmationComponent message={charge_messages.delete_charge} cancel_button_text='No' save_button_text="Yes"
                        title='Confirm' show_modal={this.state.charge_delete_confirmation_box} onCloseModal={this.on_close_confirmation} on_cancel_click={this.on_cancel_click} on_force_save_click={(e) => { this.on_delete_charge() }} />
                    : ''}
                {this.state.show_error_message_popup ?
                    <ConfirmationComponent message={this.state.error_popup_message} save_button_text={this.state.error_popup_ok_btn_text} showCancelButton={this.state.error_popup_show_cancel}
                        title={this.state.error_popup_title} show_modal={this.state.show_error_message_popup} onCloseModal={this.on_close_error_confirmation} on_force_save_click={(e) => { this.on_close_error_confirmation() }} />
                    : ''}
                <Dimmer active={this.state.loading} page>
                    <Loader size="massive">Loading</Loader>
                </Dimmer>                    
                <Grid>
                    <GridColumn computer={16} className="padd-r-0">
                        <Grid id="applicationPageTitleSticky">
                            <Grid.Column computer={16} className="padd-r-0">
                                <Header as='h4' className="small">
                                    Charge Detail {this.props.rsiB35911ErrorCorrectionsPreventUserFromAddingNcModifierToChargeWithActivePayments && this.state.payment_associated_with_visit && <i className="dollar-icon"/>}
                         </Header>
                                <br />

                                <div className='customtable'>
                                    <div className='table-responsive' id='charge_details_proc_table'>
                                        <Table>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell style={{ width: '30px', minWidth: '30px' }}>
                                                        <input type="checkbox"
                                                            onChange={(e) => this.on_check_change_charge(e,this.props.rsiB35911ErrorCorrectionsPreventUserFromAddingNcModifierToChargeWithActivePayments)}
                                                            checked={this.state.select_all}
                                                            value="" />
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '120px', minWidth: '120px' }}>Status</Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '250px', minWidth: '250px' }}>CPT Code</Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '100px', minWidth: '100px' }}>Units</Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '250px', minWidth: '250px' }}>Amount</Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '250px', minWidth: '250px' }}>Modifier 1</Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '250px', minWidth: '250px' }}>Modifier 2</Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '250px', minWidth: '250px' }}>Modifier 3</Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '200px', minWidth: '200px' }}>Diagnosis Code(s)</Table.HeaderCell>
                                                    <Table.HeaderCell textAlign="right" style={{ width: '125px', minWidth: '125px' }}>Total Amount</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            {this.state.charge_detail.length > 0 && (
                                                <Table.Body>
                                                    {
                                                        this.state.charge_detail.map((item, index) => {                                                       
                                                            return (
                                                                <ChargeDetailRowComponent key={index} row_count={index} data={item}
                                                                    isClear={this.state.clear_charge}
                                                                    update_child_force={this.state.update_child_force}
                                                                    reverse_select_all={this.reverse_select_all}
                                                                    onRef={ref => (this.rowNodes[index] = ref)}
                                                                    modifier2_data={this.state.procedure_code_modifiers2_list}
                                                                    modifier1_data={this.state.procedure_code_modifiers1_list}
                                                                    modifier3_data={this.state.procedure_code_modifiers3_list}
                                                                    page_name={enum_page_name.charge} 
                                                                    calculate_charge_amount_params={this.props.calculate_charge_amount_params}
                                                                    ff_sumaj_B30566_expected_rate_multiple_fee_schedules={this.props.ff_sumaj_B30566_expected_rate_multiple_fee_schedules} />
                                                            );                                                       
                                                        })
                                                    }
                                                </Table.Body>
                                            )}
                                        </Table>
                                    </div>
                                </div>
                                <br></br>
                            </Grid.Column>
                        </Grid>
                    </GridColumn>

                    <Grid.Column computer={16} textAlign="right" className="padd-r-0">
                        <Button type="button" basic onClick={this.remove_charge_row} >Remove Charge</Button>
                        <Button type='button' onClick={this.add_new_charge_row} primary>Add Charge</Button>
                    </Grid.Column>

                </Grid>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        rsiB35911ErrorCorrectionsPreventUserFromAddingNcModifierToChargeWithActivePayments: get_lauch_darkley_key_value(state.launch_darkly, "rsiB35911ErrorCorrectionsPreventUserFromAddingNcModifierToChargeWithActivePayments")
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChargeDetailComponent));