import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import { ip_registartion_request } from '../action/user_action';
var pendoInit = require('../../../../config/pendo_init');

export class IPRegistration extends React.Component<any, any> {
    [x: string]: any;

    constructor(props) {
        super(props);
        // Initialize state property here
        this.state = {
            loading: false,
            is_submitted: false,
            token: "",
            ip_validation_response: {}
        };
    }
    componentDidMount() {

        this.get_token_from_url();
    }

    // get token from the application url
    get_token_from_url = async () => {
        // application url
        var url = window.location.href;

        // To get token from the URL
        var result = url.split('?')
        var token = result[result.length - 1]
    
        if (token === "" || token === undefined) {           
            toastr.error('', "Invalid Data. Try again later");
            return;
        }
        await this.props.ip_registartion_request(token)

        pendoInit();

        this.setState({
            token: token,
            ip_validation_response: this.props.ip_registartion_response
        });


        var response = this.props.ip_registartion_response;

        if (response.status === 1) {
            // if ip validation succes show message and move to login page
            toastr.success('', response.messages[0].message);
            this.redirect_to_login_click();
        }
        else {
            if (response.messages != undefined && response.messages.length > 0) {            
                toastr.error('', response.messages[0].message);
            }
            else {
                toastr.error('', "Registration of IP Failed. Try again later");
            }
            this.redirect_to_login_click();
           
        }

    }
    private redirect_to_login_click() {
        this.props.history.push("/login");
    }

    render() {
        // Redux form will generate a prop named 'handleSubmit'
        const { handleSubmit } = this.props;
        return (
            <>
                
            </>
        );
    }
}



const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        ip_registartion_request: ip_registartion_request
    }, dispatch)
}

const mapStateToProps = (state) => {
    return {
        ip_registartion_response: state.user_details.ip_registartion_response,
    };
}


/* - Connect component to redux
  - Used to map the redux store state and dispatch it to the props of a component
  - 2 input parameters:-
      1) mapStateToProps- The component will subscribe to Redux store updates. This means that any time the store is updated, mapStateToProps will be called [output]
      2) mapDispatchToProps- Map dispatched data from the action creater to props of a component [action creater]
*/
export default reduxForm({
    form: 'reset'
})(connect(mapStateToProps, mapDispatchToProps)(IPRegistration))
