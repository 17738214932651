
import * as React from 'react';
import * as moment from 'moment';
import { Form } from 'semantic-ui-react';
import { toastr as toaster } from 'react-redux-toastr';
import Calendar from '../../shared/component/calendar_component';
import Selection from '../../shared/component/selection_component';
import { AlertConfirm } from '../../shared/component/alert_confirm_component';
import { get_popper_id, check_copay_transaction_type, format_amount } from '../utility';
import CurrencyFormatter from '../../shared/component/currency_formatter';
import { ERROR_CORRECTION_MESSAGE } from '../payment_constants';
import { money_type } from '../../global_constants';
import { date_format_with_no_time } from '../../shared/utility';

class ErrorCorrectionPaymentDetailsRowComponent extends React.Component<any, any> {
    _is_mounted = false;
    initial_data: any = {};

    // Get innitalise data when component created
    constructor(props) {
        super(props);
        this.state = {
            checked: this.props.checked,
            data: this.init_data(this.props.data),
            minDate: null,
            is_fetched: false,
            err_adjustment_qualifier: false,
            open_confirm_alert: false,
            to_insurance: false,
            to_patient: false,
            delete: false,
        };
    }

    componentDidMount() {
        this._is_mounted = true;
        this.props.onRef(this);
        let date = null;
        if (!this.props.data.deposit_date_disabled && this.props.accrualFlag) {
            date = new Date(this.props.openMonth);
        }

        this.setState({
            minDate: date
        });
    }

    componentWillUnmount() {
        this._is_mounted = false;
        this.props.onRef(undefined)
    }

    //Component update function
    componentDidUpdate(previousProps, previousState) {
        const { shouldUpdate, checked } = this.props;
        const state_value = {};
        let is_update = false;
        if (shouldUpdate === true && previousProps.checked !== checked) {
            state_value['checked'] = checked;
            is_update = true;//state update available 
        } else if (this.state.checked === true && this.props.isClear === true) {
            state_value['checked'] = false;
            is_update = true;//state update available 
        }

        if (this.props.clear_data !== previousProps.clear_data) {
            state_value['err_amount'] = false;
            state_value['err_payment_code'] = false;
            state_value['err_insurance'] = false;
            state_value['disabled_date_of_service'] = false;
            is_update = true;//state update available 
        }
        if (this.props.data != previousProps.data) {
            state_value['data'] = this.init_data(this.props.data);
            state_value['is_fetched'] = true;
            state_value['checked'] = checked;
            is_update = true;//state update available 
        }
        if (is_update) {
            this.setState(state_value);
        }
    }

    init_data = (data) => {
        const form_data = { ...data };
        form_data['deposit_date'] = this.get_date(form_data.deposit_date, 'MM/DD/YYYY');
        form_data['adjustment_amount'] = format_amount(data.adjustment_amount || 0);
        form_data['payment_amount'] = format_amount(data.payment_amount || 0);
        form_data['transfer_detail_amount'] = format_amount(data.transfer_detail_amount || 0);
        form_data['xfer_amount'] = format_amount(data.xfer_amount || 0);
        form_data['from_ins_code'] = data.from_ins_code == 0 ? '0' : `${data.from_ins_code}`;
        form_data['xfer_ins_code'] = data.xfer_ins_code == 0 ? '0' : `${data.xfer_ins_code}`;
        form_data['batch_num'] = data.batch_num||'';
        form_data['adjustment_qualifier'] = data.adjustment_qualifier || '';
        form_data['adjustment_reason'] = data.adjustment_reason || '';
        form_data['delete'] = false;
        form_data['to_insurance'] = false;
        form_data['to_patient'] = false;
        form_data['to_charge_id'] = 0;
        this.initial_data = { ...form_data };
        return form_data;
    }

    //Get row number
    get_row_num = () => this.props.rowNum;
    //Get data retruns updated data item.
    get_updated_data = () => {
        const form_data = { ...this.state.data };
        return {
            ...form_data,
            adjustment_amount: form_data.adjustment_amount || 0,
            payment_amount: form_data.payment_amount || 0,
            transfer_detail_amount: form_data.transfer_detail_amount || 0,
            xfer_amount: form_data.xfer_amount || 0
        };
    };

    //Get data retruns data item.
    get_data = () => ({ ...this.props.data });

    //Check data change
    is_data_unsaved = () => {
        if (JSON.stringify(this.initial_data) !== JSON.stringify(this.state.data)) {
            return true;
        }
        return false;
    }


    btn_set_focus = (element) => {
        if (this._is_mounted) {
            setTimeout(() => {
                if (element) {
                    element.focus();
                }
            }, 100);
        }
    }

    btn_set_disabled = (element: HTMLElement) => {
        if (element) {
            element['disabled'] = true;
        }
    }

    // Will handle date selection from calendar  
    handle_calendar_change = (date) => {
        const deposit_date = this.get_date(date, 'MM/DD/YYYY');
        const data_updated = { ...this.state.data, deposit_date };
        this.setState(prevState => ({
            data: data_updated,
            is_fetched: false,
        }), () => {
            this.props.onDataChange({ ...this.state.data }, this.props.rowNum);
        });
    }

    on_check_change(event) {
        if (event.target.checked && event.target.name == 'delete') {
            this.setState({
                open_confirm_alert: event.target.checked
            })
        } else {
            const check_value = { [event.target.name]: event.target.checked };
            const data_updated = { ...this.state.data, ...check_value };
            this.setState(prevState => ({
                data: data_updated,
                ...check_value,
                is_fetched: false,
            }), () => {
                this.props.onDataChange({ ...this.state.data }, this.props.rowNum);
            });
        }
    }

    handle_confirm = (e) => {
        e.preventDefault();
        const check_value = { delete: true };
        const data_updated = { ...this.state.data, ...check_value };
        this.setState(prevState => ({
            data: data_updated,
            ...check_value,
            open_confirm_alert: false,
            is_fetched: false,
        }), () => {
            this.props.onDataChange({ ...this.state.data }, this.props.rowNum);
        });
    }

    handle_input_change = (e, input_name) => {
        const { value, name } = e.target;
        let value_updated = value;
        if (name == 'to_charge_id') {
            let isnum = /^\d+$/.test(value);
            if (isnum) {
                value_updated = value
            } else {
                value_updated = 0;
            }
        }

        this.setState(prevState => ({
            data: { ...prevState.data, [input_name]: value_updated },
        }), () => {
            this.props.onDataChange({ ...this.state.data }, this.props.rowNum);
        });
    }

    handle_amount_change = (value, input_name) => {
        this.setState(prevState => ({
            data: { ...prevState.data, [input_name]: value },
            err_adjustment_qualifier: this.required_adjustment_qualifier(prevState.data.adjustment_qualifier, { ...prevState.data, [input_name]: value })
        }), () => {
            this.props.onDataChange({ ...this.state.data }, this.props.rowNum);
        });
    }

    //Dropdown change handler.
    dropdown_change_handler = (data, e) => {
        const { value, name } = e.target;
        var form_data = { ...this.state.data };
        form_data[name] = value == ' ' ? '' : value;
        const object = {};
        if (name == 'adjustment_qualifier') {
            object['err_adjustment_qualifier'] = this.required_adjustment_qualifier(value, this.state.data);
        }
        this.setState(prev_state => ({
            data: form_data, ...object
        }));
    };

    get_date = (date, pattern) => {
        if (date) {
            return date_format_with_no_time(date).format(pattern);
        }
        return '';
    }

    //isChecked retruns selection status.
    is_checked = () => this.state.checked;

    //Check form validation
    is_valid = () => {
        let flag_value = true;
        let update_value = {};
        if (this.required_adjustment_qualifier(this.state.data.adjustment_qualifier, this.state.data)) {
            update_value['err_adjustment_qualifier'] = true;
            flag_value = false;
        }

        if (flag_value === false) {
            this.setState({ ...update_value })
        }
        return flag_value;
    }

    //This function amount return negative sign status to display toaster 
    is_negative_amount_error = () => ((this.state.data.credit_type_id == 7 && this.state.data.amount > 0) ? true : false);
    is_negative_valid = () => ((check_copay_transaction_type(this.state.data.credit_type_id) && this.state.data.amount < 0) ? true : false);


    required_adjustment_qualifier = (dropdown_value, form_data) => {
        const {
            payment_amount,
            adjustment_amount,
            transfer_detail_amount
        } = form_data;
        let adj_qual = dropdown_value || '';
        if (adj_qual == 'PR' || adj_qual == 'CO') {
            if (transfer_detail_amount != 0 && adjustment_amount != 0) {
                toaster.error('', 'Can not enter value in both Adjustment Amount and Transfer Detail Amount for qualifier PR and CO.');
                return true;
            } else if (adj_qual == "PR" && transfer_detail_amount == 0) {
                toaster.error('', 'For PR qualifier, enter value in Transfer Detail Amount.');
                return true;
            } else if (adj_qual == "CO" && (adjustment_amount == 0 && payment_amount == 0)) {
                toaster.error('', 'For CO qualifier, enter value in Adjustment Amount and/or Payment Amount.');
                return true;
            }
        }
        return false;
    }

    //Get default date selection
    default_date = (date, pattern) => {
        if (date) {
            const dt = moment(date).format(pattern);
            return moment(dt);
        }
        return null;
    }

    //Render function
    render() {
        const { data } = this.state;
        return (<tr>
            <td className="text-center">
                <input type="checkbox"
                    id={`insurance_checkbox_${this.props.rowNum}`}
                    name="to_insurance"
                    checked={this.state.to_insurance}
                    onChange={this.on_check_change.bind(this)}
                    disabled={this.props.data.to_insurance_disabled}
                />
            </td>
            <td className="text-center">
                <input type="checkbox"
                    id={`patient_checkbox_${this.props.rowNum}`}
                    name="to_patient"
                    checked={this.state.to_patient}
                    onChange={this.on_check_change.bind(this)}
                    disabled={this.props.data.to_patient_disabled}
                />
            </td>
            <td className="text-center">
                <AlertConfirm
                    open={this.state.open_confirm_alert}
                    close={() => this.setState({ open_confirm_alert: false })}
                    cancel={() => this.setState({ open_confirm_alert: false })}
                    confirm={this.handle_confirm}
                    message={ERROR_CORRECTION_MESSAGE.DELETE_PAYMENT_CONFIRM}
                />
                <input type="checkbox"
                    id={`delete_checkbox_${this.props.rowNum}`}
                    name="delete"
                    checked={this.state.delete}
                    onChange={this.on_check_change.bind(this)}
                    disabled={!this.props.data.allow_delete}
                />
            </td>
            <td data-label="date" className={`custom-datepicker-for-table datepicker-keybowrd-payment${this.props.rowNum} calendar-error-correction`}>
                <Calendar
                    id={`deposit_date_${this.props.rowNum}`}
                    id_popper={get_popper_id()}
                    maxDate={moment().format('MM/DD/YYYY')}
                    date_update={this.handle_calendar_change}
                    date={this.default_date(this.state.data['deposit_date'], 'MM/DD/YYYY')}
                    minDate={this.state.minDate}
                    is_disabled={this.props.data.deposit_date_disabled}
                    class_name={`datepicker-keybowrd-payment${this.props.rowNum}`}
                />
            </td>
            <td data-label="insurance-code" >
                <Selection
                    placeHolder={'Select'}
                    hidden={true}
                    id={`from_ins_code_${this.props.rowNum}`}
                    name='from_ins_code'
                    defaultValue={data.from_ins_code || ''}
                    options={this.props.insuranceCode || []}
                    disabled={this.props.data.from_ins_code_disabled}
                    onChange={(value, e) => {
                        this.dropdown_change_handler(value, e);
                    }}
                />
            </td>
            <td data-label="payment-code">
                <Selection
                    id={`payment_code_${this.props.rowNum}`}
                    defaultValue={data.payment_code || ''}
                    options={this.props.paymentCode || []}
                    name='payment_code'
                    disabled={this.props.data.payment_code_disabled}
                    onChange={(value, e) => {
                        this.dropdown_change_handler(value, e);
                    }}
                />
            </td>
            <td data-label="payment-amount">
                <Form.Field className={this.props.is_submitted && this.state.err_amount ? "requiredWithBgColor" : ""}>
                    <CurrencyFormatter
                        id={`payment_amount${this.props.rowNum}`}
                        onChange={(value) => this.handle_amount_change(value, 'payment_amount')}
                        prefix='$'
                        maxLength={15}
                        defaultValue={data.payment_amount}
                        allowNegative={true}
                        disabled={this.props.data.payment_amount_disabled}
                        className={this.props.is_submitted && this.state.err_amount ? 'red-error-thin numberFormat' : 'numberFormat'}
                    />
                </Form.Field>
            </td>
            <td data-label="adjustment-code">
                <Selection
                    id={`adjustment_code_${this.props.rowNum}`}
                    defaultValue={data.adjustment_code || ''}
                    options={this.props.adjustmentCode || []}
                    disabled={this.props.data.adjustment_code_disabled}
                    name='adjustment_code'
                    onChange={(value, e) => {
                        this.dropdown_change_handler(value, e);
                    }}
                />
            </td>
            <td data-label="adjustment-amount">
                <Form.Field className={this.props.is_submitted && this.state.err_amount ? "requiredWithBgColor" : ""}>
                    <CurrencyFormatter
                        id={`adjustment-amount${this.props.rowNum}`}
                        onChange={(value) => this.handle_amount_change(value, 'adjustment_amount')}
                        prefix='$'
                        maxLength={15}
                        defaultValue={data.adjustment_amount}
                        allowNegative={true}
                        disabled={this.props.data.adjustment_amount_disabled}
                        className={this.props.is_submitted && this.state.err_amount ? 'red-error-thin numberFormat' : 'numberFormat'}
                    />
                </Form.Field>
            </td>
            <td data-label="xfer_amount">
                <Form.Field className={this.props.is_submitted && this.state.err_amount ? "requiredWithBgColor" : ""}>
                    <CurrencyFormatter
                        id={`xfer_amount_${this.props.rowNum}`}
                        onChange={(value) => this.handle_amount_change(value, 'xfer_amount')}
                        prefix='$'
                        maxLength={15}
                        defaultValue={data.xfer_amount}
                        allowNegative={true}
                        disabled={this.props.data.xfer_amount_disabled}
                        className={this.props.is_submitted && this.state.err_amount ? 'red-error-thin numberFormat' : 'numberFormat'}
                    />
                </Form.Field>
            </td>
            <td data-label="transfer-detail-amount">
                <Form.Field className={this.props.is_submitted && this.state.err_amount ? "requiredWithBgColor" : ""}>
                    <CurrencyFormatter
                        id={`transfer-detail-amount_${this.props.rowNum}`}
                        onChange={(value) => this.handle_amount_change(value, 'transfer_detail_amount')}
                        prefix='$'
                        maxLength={15}
                        defaultValue={data.transfer_detail_amount}
                        allowNegative={true}
                        disabled={this.props.data.patient_resp_amount_disabled}
                        className={this.props.is_submitted && this.state.err_amount ? 'red-error-thin numberFormat' : 'numberFormat'}
                    />
                </Form.Field>
            </td>
            <td data-label="xfer-ins-code">
                <Selection
                    id={`xfer_ins_code${this.props.rowNum}`}
                    defaultValue={data.xfer_ins_code || ''}
                    options={this.props.tranferInsuranceCode || []}
                    disabled={this.props.data.xfer_ins_code_disabled}
                    name='xfer_ins_code'
                    onChange={(value, e) => {
                        this.dropdown_change_handler(value, e);
                    }}
                />
            </td>
            <td data-label="money-type">
                <Selection
                    id={`money_type${this.props.rowNum}`}
                    defaultValue={data.money_type || ''}
                    options={this.props.paymentType || []}
                    disabled={this.props.data.money_type_disabled}
                    name='money_type'
                    onChange={(value, e) => {
                        this.dropdown_change_handler(value, e);
                    }}
                />
            </td>
            <td>
                <Form.Field >
                    <Form.Input fluid
                        id={'check_num'}
                        name='check_num'
                        maxLength={20}
                        error={false}
                        disabled={this.props.data.check_num_disabled}
                        value={(data.money_type != money_type.CREDIT_CARD_NUM && data.money_type != money_type.DEBIT_CARD_NUM) && data.check_num != null  ? data.check_num 
                            : (data.money_type == money_type.CREDIT_CARD_NUM || data.money_type == money_type.DEBIT_CARD_NUM) && data.credit_card_num != null ? data.credit_card_num : ''
                        }
                        onChange={e => {
                            if(data.money_type === money_type.CREDIT_CARD_NUM || data.money_type === money_type.DEBIT_CARD_NUM){
                                this.handle_input_change(e, 'credit_card_num');
                            }else{
                                this.handle_input_change(e, 'check_num');
                            }
                        }}
                    />
                </Form.Field>
            </td>
            <td>
                <Form.Field >
                    <Form.Input fluid
                        id={'to_charge_id'}
                        name='to_charge_id'
                        maxLength={9}
                        error={false}
                        disabled={data.to_Charge_disabled}
                        value={data.to_charge_id || ''}
                        onChange={e => this.handle_input_change(e, 'to_charge_id')}
                    />
                </Form.Field>
            </td>
            <td data-label="adjustment_qualifier">
                <Selection
                    name='adjustment_qualifier'
                    id={`adjustment_qualifier${this.props.rowNum}`}
                    defaultValue={data.adjustment_qualifier || ''}
                    options={this.props.adjustmentQualifier || []}
                    disabled={this.props.data.adjustment_qualifier_disabled}
                    isRequired={this.props.isSubmitted && this.state.err_adjustment_qualifier}
                    onChange={(value, e) => {
                        this.dropdown_change_handler(value, e);
                    }}
                />
            </td>
            <td data-label="adjustment_reason">
                <Selection
                    id={`adjustment_reason${this.props.rowNum}`}
                    defaultValue={data.adjustment_reason || ''}
                    options={this.props.adjustmentReason || []}
                    disabled={this.props.data.adjustment_reason_disabled}
                    name='adjustment_reason'
                    onChange={(value, e) => {
                        this.dropdown_change_handler(value, e);
                    }}
                />
            </td>
            <td>
                <Form.Field >
                    <Form.Input fluid
                        id={'batch_num'}
                        name='batch_num'
                        maxLength={50}
                        error={false}
                        disabled={data.batch_num_disabled}
                        value={data.batch_num || ''}
                        onChange={e => this.handle_input_change(e, 'batch_num')}

                    />
                </Form.Field>
            </td>
            <td>{data.modified_by}</td>
            <td>{data.created_by}</td>
        </tr>);
    }
};

export default ErrorCorrectionPaymentDetailsRowComponent;