import * as React from 'react';
import { Pagination } from '../../shared/component/grid/pagination_component';
import { payment_const } from '../../payment/payment_constants';
import { NumberPaginator } from '../../shared/component/number_paginator_component';

interface IProps {
    id?: string,
    name?: string,
    data: { row_count: any; },
    changeTimestamp?: string,
    onChange?: Function,
    page_size?: number,
    is_google_paging?: boolean,
    is_show_number_pager?: boolean,
    message?: string
}

class PaymentPagination extends React.Component<IProps, any> {

    _is_mounted = false;
   
    constructor(props) {
        super(props);
        this.state = {
            current_page: 1,
            start_index: 0,
            end_index: 0,
            total_record: 0,
            total_page: 0,
            is_first_page: true,
            is_last_page: true,           
            pager: {},
            is_google_paging: this.props.is_google_paging ? this.props.is_google_paging : false,
            is_show_number_pager: this.props.is_show_number_pager ? this.props.is_show_number_pager : false,
            page_size: this.props.page_size ? this.props.page_size : payment_const.PAGINATION_SIZE,
           
        }
    }

    componentDidMount() {
        this._is_mounted = true;
       
        this.set_pager(1);
    }

    //Component update function
    componentDidUpdate(previousProps, previousState) {
        if (this.props.data !== previousProps.data) {
            const length = (this.props.data && this.props.data.row_count) ? this.props.data.row_count : 0;
            let pages = (length % this.state.page_size) > 0 ? parseInt((length / this.state.page_size).toString()) + 1 : parseInt((length / this.state.page_size).toString());
            this.setState({
                current_page: 1,
                start_index: 0,
                end_index: this.state.page_size > length ? length : this.state.page_size,
                total_record: length,
                total_page: pages,
                is_first_page: true,
                is_last_page: pages > 1 ? false : true,
            }, () => { this.set_pager(1)});
        }
    }

    componentWillUnmount() {
        this._is_mounted = false;
    }

    /**
     * Pagination functionality
     */
    on_move_first = () => {
        if (this._is_mounted) {
            let to_position = this.state.page_size > this.state.total_record ? this.state.total_record : this.state.page_size;
            this.setState({
                current_page: 1,
                start_index: 0,
                end_index: to_position,
                is_first_page: true,
                is_last_page: false
            }, () => {
                this.props.onChange(this.state.start_index, this.state.end_index);
            });
        }
        this.set_pager(1);
    }

    on_move_last = () => {
        if (this._is_mounted) {
            let from_position = ((this.state.total_page - 1) * this.state.page_size);
            this.setState({
                current_page: this.state.total_page,
                start_index: from_position,
                end_index: this.state.total_record,
                is_first_page: false,
                is_last_page: true
            }, () => {
                this.props.onChange(this.state.start_index, this.state.end_index);
            });
        }
        this.set_pager(this.state.total_page);
    }

    on_move_next = () => {
        const curr_page = this.state.current_page < this.state.total_page ? (this.state.current_page + 1) : this.state.current_page;
        if (curr_page <= this.state.total_page) {
            let from_position = ((curr_page - 1) * this.state.page_size);
            let to_position = (curr_page * this.state.page_size) > this.state.total_record ? this.state.total_record : (curr_page * this.state.page_size);
            if (this._is_mounted) {
                this.setState({
                    current_page: curr_page,
                    start_index: from_position,
                    end_index: to_position,
                    is_first_page: curr_page == 1,
                    is_last_page: curr_page == this.state.total_page
                }, () => {
                    this.props.onChange(this.state.start_index, this.state.end_index);
                });
            }
        }
        this.set_pager(curr_page);
    }

    on_move_previous = () => {
        const curr_page = this.state.current_page > 1 ? (this.state.current_page - 1) : this.state.current_page;
        if (this._is_mounted) {
            let from_position = ((curr_page - 1) * this.state.page_size);
            let to_position = (curr_page * this.state.page_size) > this.state.total_record ? this.state.total_record : (curr_page * this.state.page_size);
            this.setState({
                current_page: curr_page,
                start_index: from_position,
                end_index: to_position,
                is_first_page: curr_page == 1,
                is_last_page: curr_page == this.state.total_page
            }, () => {
                this.props.onChange(this.state.start_index, this.state.end_index);
            });
        }
        this.set_pager(curr_page);
    }

    on_move_to_page = (index) => {
        const curr_page = index;
        if (curr_page <= this.state.total_page) {
            if (this._is_mounted) {
                let from_position = ((curr_page - 1) * this.state.page_size);
                let to_position = (curr_page * this.state.page_size) > this.state.total_record ? this.state.total_record : (curr_page * this.state.page_size);
                this.setState({
                    current_page: curr_page,
                    start_index: from_position,
                    end_index: to_position,
                    is_first_page: curr_page == 1,
                    is_last_page: curr_page == this.state.total_page
                }, () => {
                    this.props.onChange(this.state.start_index, this.state.end_index);
                });
            }
        }
        this.set_pager(index);
    }
    set_pager = (page) => {
        // var { items, pageSize } = this.props;
        var pager = this.state.pager;

        if (page < 1 || page > pager.totalPages) {
            return;
        }
        var page_size =  this.state.page_size;
        // get new pager object for specified page
        pager = this.get_pager(page, page_size);

        if (this._is_mounted) {
            // update state
            this.setState({ pager: pager });
        }

        // call change page function in parent component
        //this.props.onChangePage(pageOfItems);

    }
    get_pager = (curr_page, page_size) => {
        // default to first page
        curr_page = curr_page || 1;

        // default page size is 10
        page_size = page_size || 5;

        // calculate total pages
        var total_pages = this.state.total_page;
        var start_page, end_page;
        if (this.state.total_page <= 6) {
            // less than 10 total pages so show all
            start_page = 1;
            end_page = this.state.total_page;
        } else {
            if (this.state.is_google_paging) {
                // more than 10 total pages so calculate start and end pages
                if (curr_page <= 3) {
                    start_page = 1;
                    end_page = 6;
                } else
                    if (Number(curr_page) + 2 >= this.state.total_page) {
                        start_page = Number(this.state.total_page) - 4;
                        end_page = this.state.total_page;
                    } else {
                        start_page = Number(curr_page) - 2;
                        end_page = Number(curr_page) + 2;
                    }
            }
            else {
                if (Number(curr_page) + 5 >= this.state.total_page) {
                    start_page = Number(this.state.total_page) - 5;
                    end_page = this.state.total_page;
                }
                else {
                    start_page = curr_page;
                    end_page = curr_page + 5;
                }
            }
        }
        // create an array of pages to ng-repeat in the pager control
        var pages = (end_page + 1) - start_page;
        var arr = [];
        for (var index = 0; index < pages; index++) {
            arr.push(start_page + index);
        }
        //pages = [Array((end_page + 1) - start_page).map(i => start_page + i)];

        // calculate start and end item indexes
        var start_index = (curr_page - 1) * page_size;

        //
        var start_pager = [];
        for (var index = 1; index < start_page; index++) {
            start_pager.push(index);
        }
        var mid_pager = [];
        for (var indx = start_page; indx <= end_page; indx++) {
            mid_pager.push(indx);
        }
        var end_pager = [];
        for (var indx = end_page + 1; indx <= total_pages; indx++) {
            end_pager.push(indx);
        }
        //

        // return object with all pager properties required by the view
        return {
            curr_page: curr_page,
            page_size: page_size,
            total_pages: total_pages,
            start_page: start_page,
            end_page: end_page,
            start_index: start_index,
            pages: arr,
            start_range: start_pager,
            mid_range: mid_pager,
            end_range:end_pager
        };
    }
    //Render function
    render() {
        return (
            this.props.is_show_number_pager
                ?
                <NumberPaginator
                    fromPage={this.props.data.row_count ? this.state.start_index + 1 : this.state.start_index}
                    toPage={this.state.end_index}
                    totalRecords={this.state.total_record}
                    isFirstPage={this.state.is_first_page}
                    isLastPage={this.state.is_last_page}
                    totalPage={this.state.total_page}
                    pager={this.state.pager}
                    showPager={this.state.is_show_number_pager}
                    message={this.props.message || 'Patient Credits'}
                    onFirst={this.on_move_first}
                    onPrevious={this.on_move_previous}
                    onNext={this.on_move_next}
                    onLast={this.on_move_last}
                    onSpecificPage={this.on_move_to_page}
                /> :
            <Pagination
            fromPage={this.props.data.row_count ? this.state.start_index + 1 : this.state.start_index}
            toPage={this.state.end_index}
            totalRecords={this.state.total_record}
            isFirstPage={this.state.is_first_page}
            isLastPage={this.state.is_last_page}
            totalPage={this.state.total_page}
            pager={this.state.pager}
            showPager={this.state.is_show_number_pager}
            message={this.props.message || 'Patient Credits'}
            onFirst={this.on_move_first}
            onPrevious={this.on_move_previous}
            onNext={this.on_move_next}
            onLast={this.on_move_last}
            onSpecificPage={this.on_move_to_page}
        />);
    }
}

export default PaymentPagination;