import * as React from 'react'
import { connect } from 'react-redux';
import DrawerComponent from '../../../../shared/component/drawer_component';
import { Drawer, Position } from '@blueprintjs/core';
import MultiSelectPanelComponent from '../../../../shared/component/multi_select_panel_component';
import { Grid, Segment } from 'semantic-ui-react';
import { Tab, Tabs, Tooltip } from '@blueprintjs/core';

export class AddInsuranceDrawerComponent extends React.Component<any, any>{
    _is_mounted = false;
    _selected_list = [];
    _selected_insurance_class_list: any = []
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            disable_insurance_code_tab: false,
            disable_insurance_class_tab: false,
            defaultSelectedTabId: 'insurance_class_id'
        };
        this._selected_list = this.props.selected_list;
        this._selected_insurance_class_list = this.props.selected_insurance_class_list;
    }
    componentDidMount = () => {
        this._is_mounted = true;
        this.setState({ ...this.handle_tabs_on_load() })
    }

    componentWillUnmount = () => {
        this._is_mounted = false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isOpen != this.props.isOpen) {
            this.setState({
                isOpen: this.props.isOpen
            });
        }
    }
    handle_tabs_on_load() {
        let disable_insurance_code_tab = false;
        let disable_insurance_class_tab = false;
        let defaultSelectedTabId = this.props.defaultSelectedTabId || 'insurance_class_id';

        if(this._selected_insurance_class_list.length > 0){
            disable_insurance_code_tab = true;
            defaultSelectedTabId = 'insurance_class_id';
        }else if(this._selected_list.length > 0){
            disable_insurance_class_tab = true;
            defaultSelectedTabId = 'insurance_code_id';
        }

        return {
            disable_insurance_code_tab,
            disable_insurance_class_tab,
            defaultSelectedTabId
        };
    }

    handle_drawer_close = () => {
        this.props.onInsuranceDrawerClose && this.props.onInsuranceDrawerClose(this.props.key_id, this._selected_list,this._selected_insurance_class_list);
    }

    handle_on_change_selected = (selected_list) => {
        if (this._is_mounted) {
            this._selected_list = selected_list;
            if (this._selected_list.length > 0) {
                this.setState({ disable_insurance_class_tab: true })
            } else {
                this.setState({ disable_insurance_class_tab: false })
            }
        }
    }

    handle_on_class_change_selected = (selected_insurance_class_list) => {
        if (this._is_mounted) {
            this._selected_insurance_class_list = selected_insurance_class_list
            if (this._selected_insurance_class_list.length > 0) {
                this.setState({ disable_insurance_code_tab: true })
            } else {
                this.setState({ disable_insurance_code_tab: false })
            }
        }
    }

    private getInsuranceClassPanel = () => {
        return (
            <div>
                <MultiSelectPanelComponent
                    id='insurance-class-multi-select'
                    key={this.props.key_id}
                    data={this.props.insurance_class_list}
                    selected_list={this.props.selected_insurance_class_list}
                    onChangeSelected={this.handle_on_class_change_selected}
                    is_save_in_progress={this.props.is_save_in_progress}
                />
            </div>
        );
    };

    private getInsuranceCodePanel = () => {
        return (
            <div>
                <MultiSelectPanelComponent
                    id='insurance-code-multi-select'
                    key={this.props.key_id}
                    data={this.props.insurance_list}
                    selected_list={this.props.selected_list}
                    onChangeSelected={this.handle_on_change_selected}
                    is_save_in_progress={this.props.is_save_in_progress}
                />
            </div>
        );
    };

    private renderInsuranceClassesPanel = () => {
        return this.getInsuranceClassPanel();
    };
    
    private renderInsuranceCodePanel = () => {
        return this.getInsuranceCodePanel();
    };

    get_inner_content_on_ff = () => {
        return (
            <React.Fragment>
                <Grid columns='equal'>
                    <Grid.Row className='last-row'>
                        <Segment>
                            <Tabs animate={true} id='billing-tab-group' defaultSelectedTabId={this.state.defaultSelectedTabId}>
                                <Tab
                                    id='insurance_class_id'
                                    disabled={this.state.disable_insurance_class_tab}
                                    panel={this.renderInsuranceClassesPanel()}
                                >
                                    <Tooltip
                                        content={
                                            <div>
                                                Insurance Class tab is disabled while
                                                <br />
                                                Insurance Codes are selected.
                                            </div>
                                        }
                                        disabled={!this.state.disable_insurance_class_tab}
                                        position={'top'}
                                    >
                                        Insurance Class
                                    </Tooltip>
                                </Tab>
                                <Tab
                                    id='insurance_code_id'
                                    disabled={this.state.disable_insurance_code_tab}
                                    panel={this.renderInsuranceCodePanel()}
                                >
                                    <Tooltip
                                        content={
                                            <div>
                                                Insurance Code tab is disabled while
                                                <br />
                                                Insurance Classes are selected.
                                            </div>
                                        }
                                        disabled={!this.state.disable_insurance_code_tab}
                                        position={'top'}
                                    >
                                        Insurance Code
                                    </Tooltip>
                                </Tab>
                                <Tabs.Expander />
                            </Tabs>
                        </Segment>
                    </Grid.Row>
                </Grid>
            </React.Fragment>
        )
    }

    render() {
        return (
            <DrawerComponent
                key={this.props.key_id}
                canOutsideClickClose={true}
                isOpen={this.props.isOpen}
                handleDrawerClose={this.handle_drawer_close}
                canEscapeKeyClose={false}
                title=""
                isCloseButtonShown={true}
                position={Position.RIGHT}
                innerContent={this.get_inner_content_on_ff()}
                className="ext-bp-drawer provider-drawer billing-provider-drawer claim-provider-drawer"
                size={Drawer.SIZE_STANDARD}
            />
        )
    }
}

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details
    };
};
export default connect(mapStateToProps)(AddInsuranceDrawerComponent);