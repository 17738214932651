import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Accordion, Button, Form, Grid, Icon } from 'semantic-ui-react';
import { get_search_data } from '../../../admin/constants/action/constants_action';
import { option_formatter_multiSelect, users } from '../../../admin/constants/constants';
import { constants, end_points, toastr_options, charge_exception } from '../../../global_constants';
import { get_patient_search } from '../../../patient/action/patient_search_action';
import ReportHeader from '../../../reports/component/report_header';
import { messages, currencyFormatter } from '../../../reports/report_constants';
import { export_grid_data, generateAuditDataEntryPayload, print_grid_data } from '../../../reports/util/export_print_utility';
import { log_error } from '../../../shared/action/shared_action';
import AdvancedControl from '../../../shared/component/advanced_control';
import AutoSearchComponent from '../../../shared/component/auto_search_component';
import DateRangePickerComponent from '../../../shared/component/date_range_component';
import DualInput from '../../../shared/component/dual_input_component';
import DualCurrencyInput from '../../../shared/component/dual_currency_input_component';
import GridView from '../../../shared/component/grid/index';
import { LoaderComponent } from '../../../shared/component/loading_component';
import MultiSelectDropDownComponent from '../../../shared/component/multi_select_dropdown_component';
import Selection from '../../../shared/component/selection_component';
import * as session_storage from '../../../shared/session_storage_utility';
import * as sharedConstants from '../../../shared/shared_constants';
import ConfirmationComponent from '../../../shared/component/confirmation_component';
import DisplayExtraRecordsWarning from '../../../shared/component/display_extra_records_warning';

import {
  handle_click_on_enter,
  set_focus_on_element_with_id,
  set_focus_to_app_header
} from '../../../shared/tab_navigation_utility';
import { format_dashes_number, format_date, get_age, get_all_error, get_columns, get_lauch_darkley_key_value } from '../../../shared/utility';
import {
  get_batch_updates_master_data,
  get_batch_updates_master_data_by_company,
  search_batch_updates,
  update_batch_status
} from '../../action/billing_actions';
import { batch_updates, batch_updates_report_grid_id } from '../../billing_constants';
import { row_data_formatter } from '../../utility';
import { get_searchList } from './../../../shared/action/autosearch_action';
import { get_company_list } from './../../../user/action/change_company_action';
import { change_company_configuration, change_company_grid_header_id } from './../../../user/user_constants';
import * as ReportConstants from '../../../reports/report_constants';
import { providerBillingSettingsFeatures, BillingAuditLogPagesFeature } from '../../../admin/constants/constants';
export class BatchUpdatesComponent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
      this.state = {
          show_bummepd_charge_message_popup: false,
          display_extra_records_modal: false,
          extra_record_count: 0,
      error: {
        company_id: false
      },
      search_criteria: {},
      update_criteria:  {
        action: '0',
        adjustment_code: '',
        transaction_message: '',
        rebill_status: '',
        claim_delay_flag: ''
      },
      patient_grid_data: {},
      companies_grid_data: {},
      grid_params: [],
      is_submitted: false,
      re_render: false,
      loading: false,
      show_grid: false,
      controls_is_expended: true,
      is_search_disabled: false,
      is_correct_charge_disabled: true,
      report_rows_count: 0
    };
  }
  is_mounted = false;
  is_initial_result: boolean = false;
  update_disabled: boolean = true;
  patient_id: any = null;
  token: string = '';
  total_column_width: number = 0;
  grid_header_height: number = 0;
  report_data: any = {
    rows: [],
    column_def: []
  };
  max_inner_width = 0;
  page_metadata: any;
  title_search_criteria: any = {};
  print_export_search_criteria: any = {};
  current_date: string;
  COMPANIES_ENUM = 'COMPANIES';
  PATIENT_ENUM = 'PATIENT';
  dropdown_data: any = {
    action: [],
    transaction_message: [],
    balance_type: [],
    place_of_service: [],
    rebill_status: [],
    claim_delay_reason: [],
    adjustment_code: [],
    // Multi Select
    insurance_class: [],
    insurance_type: [],
    insurance: [],
    payer: [],
    procedure: [],
    provider: []
  };
  initial_remove_claim_delay_reason: any[];
  initial_add_claim_delay_reason: any[];
  selected_row: any[] = [];
  reportId: string = '0';

  UNSAFE_componentWillMount = () => {
    // this.patient_id = session_storage.get('active_patient');
    this.token = this.props.user_login_details.user_data.data.token_details.access_token;
    this.page_metadata = this.get_initial_data();
    // getting up search criteria from the session
    if (session_storage.get('batch_update_criteria')) {
      this.is_initial_result = true;
      const search_criteria = session_storage.get('batch_update_criteria');
      this.title_search_criteria = {
        ...search_criteria
      };
      this.setState({
        search_criteria: {
          ...search_criteria,
          date_of_service_from: this.handle_date(search_criteria, 'date_of_service_from'),
          date_of_service_end: this.handle_date(search_criteria, 'date_of_service_end')
        }
      });
    } else {
      this.title_search_criteria = {
        ...this.page_metadata.title_search_criteria
      };
      this.setState({
        search_criteria: {
          ...this.page_metadata.initial_search_criteria
        }
      });
    }
  };

  get_initial_data = () => {
    return batch_updates(this.props);
  };
  componentDidMount = () => {
    this.is_mounted = true;
    if (!document.body.classList.contains('reports')) {
      document.body.classList.add('reports');
    }

    this.get_master_data();

    let company_control = document.getElementById('advance-control-reset_available_companies_search');
    if (company_control) company_control.focus();
  };

  // Get Payments NotesData from API function
  componentDidUpdate(prevProps: { patient_id: any }, prevState: any) {
    this.report_data.column_def = [...this.page_metadata.column_defs];
  }

  componentWillUnmount = () => {
    document.body.classList.remove('reports');
    this.is_mounted = false;
    session_storage.remove(`batch_update_criteria`);
  };

  check_update_permission = (action = []) => {
    let menu_list = [];
    this.props.shared_details.left_menu_data.data.map((menu) => {
      if (menu.name == 'Batch Updates - Adjust' && menu.view) {
        //menu.name=='Batch Updates - Adjust'
        this.update_disabled = false;
        menu_list = menu_list.concat(action.filter((item) => item.value == 0));
      } else if (menu.name == 'Batch Updates - Rebill' && menu.view) {
        //Batch Updates - Rebill
        this.update_disabled = false;
        menu_list = menu_list.concat(action.filter((item) => item.value == 1));
      } else if (menu.name == 'Batch Updates - Claim Delay' && menu.view) {
        //Batch Updates - Claim Delay
        this.update_disabled = false;
        menu_list = menu_list.concat(action.filter((item) => item.value == 2 || item.value == 3));
      }
    });

      return menu_list.sort((a: any, b: any) => {
          if (a.value < b.value) {
              return -1;
          }
          if (a.value > b.value) {
              return 1;
          }
          return 0;
      });
  };

  get_master_data = async () => {
    if (this.is_mounted) {
      this.setState({
        loading: true
      });
    }

    const arrData = [];
    const company_id = this.state.search_criteria.company_id;
    arrData.push(get_batch_updates_master_data_by_company(this.token, { company_id, request: 1 }));
    arrData.push(get_batch_updates_master_data_by_company(this.token, { company_id, request: 3 }));
    arrData.push(get_batch_updates_master_data_by_company(this.token, { company_id, request: 4 }));
    arrData.push(get_batch_updates_master_data_by_company(this.token, { company_id, request: 5 }));
    arrData.push(get_batch_updates_master_data_by_company(this.token, { company_id, request: 6 }));
    arrData.push(get_batch_updates_master_data_by_company(this.token, { company_id, request: 7 }));
    arrData.push(get_batch_updates_master_data_by_company(this.token, { company_id, request: 8 }));

    if (this.is_initial_result) {
      let params: any = {
        ...this.state.search_criteria,
        date_of_service_from: this.handle_date(this.state.search_criteria, 'date_of_service_from', true),
        date_of_service_end: this.handle_date(this.state.search_criteria, 'date_of_service_end', true)
      };
      delete params.selected_company;
      delete params.selected_patient;
      arrData.push(search_batch_updates(this.token, params));
    }
    await Promise.all(arrData)
      .then(
        (response) => {
          let state: any = {};         

          let element: any = {};
          for (let index = 0; index < response.length; index++) {
            if (response[index] && response[index].data.status) {
              element = { ...element, ...response[index].data.data };
            }
          }
          if (element) {
            this.format_primary_dropdown_data(element);
          }

          // If page reload
          if (response[7] && response[7].data.status) {
            response[7].data.data = row_data_formatter(response[7].data.data, this.report_data.column_def);
            this.report_data['rows'] = response[6].data.data || [];
            this.total_column_width = 0;
            const grid_height = get_columns(this.report_data['rows'], this.report_data.column_def);
            this.grid_header_height = grid_height.header_height;
            this.total_column_width = grid_height.total_column_width;

            // Set Title for print and export
            this.title_search_criteria = {
              ...this.state.search_criteria,
              date_of_service_from: this.handle_date(this.state.search_criteria, 'date_of_service_from', true),
              date_of_service_end: this.handle_date(this.state.search_criteria, 'date_of_service_end', true),
              place_of_service: this.get_dropdown_text(),
              // Multi Select
              balance_type: `${this.state.search_criteria.balance_type.length} Selected`,
              procedure: `${this.state.search_criteria.procedure.length} Selected`,
              provider: `${this.state.search_criteria.provider.length} Selected`,
              insurance: `${this.state.search_criteria.insurance.length} Selected`,
              insurance_type: `${this.state.search_criteria.insurance_type.length} Selected`,
              insurance_class: `${this.state.search_criteria.insurance_class.length} Selected`,
              payer: `${this.state.search_criteria.payer.length} Selected`
            };
            this.print_export_search_criteria = {
              ...this.title_search_criteria
            };

            state = {
              show_grid: true,
              report_rows_count: this.report_data['rows'] && this.report_data['rows'].length,
              is_search_disabled: false,
              is_export_disabled: false,
              is_print_disabled: false,
              is_updated_disabled: this.update_disabled || false,
              controls_is_expended: false
            };
          }
            if (this.is_mounted) {
            this.setState({
              loading: false,
              ...state,
              update_criteria: {
                ...this.state.update_criteria 
              }
            });
          }
        },
        (error) => {
          if (this.is_mounted) {
            this.setState({
              loading: false
            });
          }
          log_error(error);
          if (error.response.data) {
            const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
            toastr.error('', toastr_options);
          }
        }
      )
      .catch((error) => {
        if (this.is_mounted) {
          this.setState({
            loading: false
          });
        }
        log_error(error);       
      });

      this.call_secondary_data_for_dropdowns(company_id);
  };

  call_secondary_data_for_dropdowns = async (company_id) => {
    const arrData = [];
    arrData.push(get_batch_updates_master_data(this.token));
    arrData.push(get_batch_updates_master_data_by_company(this.token, { company_id, request: 2 }));
    arrData.push(get_batch_updates_master_data_by_company(this.token, { company_id, request: 9 }));
    arrData.push(get_batch_updates_master_data_by_company(this.token, { company_id, request: 10 }));

    await Promise.all(arrData)
      .then(
        (response: any) => {
          let state: any = {};
          if (response[0] && response[0].data.status) {
            const { action, rebill_status } = response[0].data.data;

            this.dropdown_data = {
              ...this.dropdown_data,
              action: this.check_update_permission(action || []),
              rebill_status: rebill_status || []
             };
          }

          let element: any = {};
          for (let index = 1; index < response.length; index++) {
            if (response[index] && response[index].data.status) {
              element = { ...element, ...response[index].data.data };
            }
          }
          if (element) {
            this.format_secondary_dropdown_data(element);
          }

            if (this.is_mounted) {
            const action_value = (this.dropdown_data.action && this.dropdown_data.action.length > 0 && this.dropdown_data.action[0].value >= 0) ? this.dropdown_data.action[0].value: '-1';
            this.setState({
              ...state,
              update_criteria: {
                ...this.state.update_criteria,
                action: action_value,
                adjustment_code:
                  (this.dropdown_data.adjustment_code && this.dropdown_data.adjustment_code[0].value) || '',
                transaction_message:
                  (this.dropdown_data.transaction_message && this.dropdown_data.transaction_message[0].value) || '',
                rebill_status: (this.dropdown_data.rebill_status && this.dropdown_data.rebill_status[0].value) || '',
                claim_delay_flag:
                  (this.dropdown_data.claim_delay_reason && this.dropdown_data.claim_delay_reason[0].value) || ''
              }
            });
          }
        },
        (error) => {         
          log_error(error);
          if (error.response.data) {
            const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
            toastr.error('', toastr_options);
          }
        }
      )
      .catch((error) => {       
        log_error(error);
        if (error.data && error.data.messages && error.data.messages[0].message.indexOf('<br') > -1) {
          this.show_html_content_toaster(error.response.data.messages[0].message);
        } else {
          toastr.error('', error.response.data.messages[0].message);
        }
      });

  }

  get_master_data_by_company = async () => {
    if (this.is_mounted) {
      this.setState({
        re_render: false
      });
    }

    const arrData = [];
    const company_id = this.state.search_criteria.company_id;
    arrData.push(get_batch_updates_master_data_by_company(this.token, { company_id, request: 1 }));
    arrData.push(get_batch_updates_master_data_by_company(this.token, { company_id, request: 2 }));
    arrData.push(get_batch_updates_master_data_by_company(this.token, { company_id, request: 3 }));
    arrData.push(get_batch_updates_master_data_by_company(this.token, { company_id, request: 4 }));
    arrData.push(get_batch_updates_master_data_by_company(this.token, { company_id, request: 5 }));
    arrData.push(get_batch_updates_master_data_by_company(this.token, { company_id, request: 6 }));
    arrData.push(get_batch_updates_master_data_by_company(this.token, { company_id, request: 7 }));
    arrData.push(get_batch_updates_master_data_by_company(this.token, { company_id, request: 8 }));
    arrData.push(get_batch_updates_master_data_by_company(this.token, { company_id, request: 9 }));
    arrData.push(get_batch_updates_master_data_by_company(this.token, { company_id, request: 10 }));

    await Promise.all(arrData)
      .then(
        (response) => {
          let element: any = {};
          for (let index = 0; index < response.length; index++) {
            if (response[index] && response[index].data.status) {
              element = { ...element, ...response[index].data.data };
            }
          }
          if (element) {
            this.formate_dropdown_data(element);
          }

          if (this.is_mounted) {
            this.setState({
              re_render: true,
              loading: false,
            });
          }
        },
        (error) => {
          if (this.is_mounted) {
            this.setState({
              loading: false
            });
          }
          log_error(error);
          if (error.response.data) {
            const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
            toastr.error('', toastr_options);
          }
        }
      )
      .catch((error) => {
        if (this.is_mounted) {
          this.setState({
            loading: false
          });
        }
        log_error(error);
        if (error.data && error.data.messages && error.data.messages[0].message.indexOf('<br') > -1) {
          this.show_html_content_toaster(error.response.data.messages[0].message);
        } else {
          toastr.error('', error.response.data.messages[0].message);
        }
      });
    };

  // Get Report Data.
  get_report_data = async () => {
    let params: any = {
      ...this.state.search_criteria,
      date_of_service_from: this.handle_date(this.state.search_criteria, 'date_of_service_from', true),
      date_of_service_end: this.handle_date(this.state.search_criteria, 'date_of_service_end', true)
    };
    delete params.selected_company;
    delete params.selected_patient;
    if (this.is_mounted) {
      this.setState({
        loading: true,
        show_grid: false,
        is_search_disabled: true,
        is_export_disabled: true,
        is_print_disabled: true,
        is_updated_disabled: true,
        display_extra_records_modal: false,
        extra_record_count: 0
      });
    }
    await search_batch_updates(this.token, params).then(
      (response) => {
          if (response.data.status && response.data.data) {
          let updated_records = row_data_formatter(response.data.data.batch_charge_detail_list, this.report_data.column_def);
          this.report_data['rows'] = updated_records || [];
          this.total_column_width = 0;
          const grid_height = get_columns(this.report_data['rows'], this.report_data.column_def);
          this.grid_header_height = grid_height.header_height;
          this.total_column_width = grid_height.total_column_width;
          const display_max_alert = response.data.data.total_record_count && response.data.data.total_record_count > 5000;
          if (this.is_mounted) {
            this.setState({
              loading: false,
              show_grid: true,
              report_rows_count: this.report_data['rows'] && this.report_data['rows'].length,
              is_search_disabled: false,
              is_export_disabled: false,
              is_print_disabled: false,
              is_updated_disabled: this.update_disabled || false,
              controls_is_expended: false, // closing the accordion on search
              display_extra_records_modal: display_max_alert,
              extra_record_count: display_max_alert ? response.data.data.total_record_count - 5000 : 0
            });
          }
        } else {
          if (response.data.messages) {
            log_error(response.data.messages);
            const toastr_options = this.show_html_content_toaster(get_all_error(response.data.messages));
            toastr.error('', toastr_options);
          }
          if (this.is_mounted) {
            this.setState({
              loading: false,
              is_search_disabled: false,
              controls_is_expended: true
            });
          }
        }
      },
      (error: { response: { data: any } }) => {
        if (this.is_mounted) {
          this.setState({
            loading: false,
            is_search_disabled: false,
            controls_is_expended: true
          });
        }
        if (error.response && error.response.data) {
          log_error(error);
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toastr.error('', toastr_options);
        }
      }
    );
  };

  formate_dropdown_data = (data: {
    claim_delay_reason: any,
    transaction_message: any,
    adjustment_code: any;
    insurance_class: any;
    insurance: any;
    insurance_type: any;
    location: any;
    payer: any;
    procedure: any;
    provider: any;
  }) => {
    const { claim_delay_reason, transaction_message, adjustment_code, insurance_class, insurance, insurance_type, location, payer, procedure, provider } = data;

    // Tracking values for add claim delay reason
    this.initial_add_claim_delay_reason = [...claim_delay_reason];

    this.dropdown_data = {
      ...this.dropdown_data,
      claim_delay_reason: this.initial_add_claim_delay_reason || [],
      adjustment_code: adjustment_code || [],
      place_of_service: location || [],
      transaction_message: transaction_message || [],
      insurance_class: insurance_class ? option_formatter_multiSelect(insurance_class) : [],
      insurance_type: insurance_type ? option_formatter_multiSelect(insurance_type) : [],
      insurance: insurance ? option_formatter_multiSelect(insurance) : [],
      payer: payer ? option_formatter_multiSelect(payer) : [],
      procedure: procedure ? option_formatter_multiSelect(procedure) : [],
      provider: provider ? option_formatter_multiSelect(provider) : [],
      balance_type: this.props.user_login_details.master_data.data.balance_type
        ? option_formatter_multiSelect(this.props.user_login_details.master_data.data.balance_type, 'name', 'id')
        : []
    };
    this.dropdown_data.place_of_service.unshift({ value: '-1', text: 'All' });

    // Tracking values for remove claim delay reason
    claim_delay_reason.unshift({ value: '01/01/1900', text: 'All' });
    this.initial_remove_claim_delay_reason = [...claim_delay_reason];
  };

  format_primary_dropdown_data = (data: {
    insurance_class: any;
    insurance: any;
    insurance_type: any;
    location: any;
    payer: any;
    procedure: any;
    provider: any;
  }) => {
    const { insurance_class, insurance, insurance_type, location, payer, procedure, provider } = data;

    this.dropdown_data = {
      ...this.dropdown_data,
      place_of_service: location || [],
      insurance_class: insurance_class ? option_formatter_multiSelect(insurance_class) : [],
      insurance_type: insurance_type ? option_formatter_multiSelect(insurance_type) : [],
      insurance: insurance ? option_formatter_multiSelect(insurance) : [],
      payer: payer ? option_formatter_multiSelect(payer) : [],
      procedure: procedure ? option_formatter_multiSelect(procedure) : [],
      provider: provider ? option_formatter_multiSelect(provider) : [],
      balance_type: this.props.user_login_details.master_data.data.balance_type
        ? option_formatter_multiSelect(this.props.user_login_details.master_data.data.balance_type, 'name', 'id')
        : []
    };
    this.dropdown_data.place_of_service.unshift({ value: '-1', text: 'All' });
  };

  format_secondary_dropdown_data = (data: {
    claim_delay_reason: any,
    transaction_message: any,
    adjustment_code: any;
  }) => {
    const { claim_delay_reason, transaction_message, adjustment_code } = data;

    // Tracking values for add claim delay reason
    this.initial_add_claim_delay_reason = [...claim_delay_reason];

    this.dropdown_data = {
      ...this.dropdown_data,
      claim_delay_reason: this.initial_add_claim_delay_reason || [],
      adjustment_code: adjustment_code || [],
      transaction_message: transaction_message || []
    };

    // Tracking values for remove claim delay reason
    claim_delay_reason.unshift({ value: '01/01/1900', text: 'All' });
    this.initial_remove_claim_delay_reason = [...claim_delay_reason];
  };

  handle_date = (search_criteria, name: string, title = false) => {
    if (title) {
      return search_criteria[name] ? moment(search_criteria[name]).format('MM/DD/YYYY') : '';
    } else {
      return search_criteria[name] ? moment(moment(search_criteria[name]).format('MM/DD/YYYY')) : null;
    }
  };

  get_dropdown_text = () => {
    let option = this.dropdown_data.place_of_service.filter((item) => {
      if (item.value == this.state.search_criteria.place_of_service) {
        return item.text;
      }
    });
    return option && option[0] ? option[0].text : '';
  };

  // Search Criteria Toggler
  controls_toggle = () => {
    if (this.is_mounted) {
      this.setState({
        controls_is_expended: !this.state.controls_is_expended
      });
    }
  };

  // Render Dropdown Control
  render_dropdown_element = (name: string, value: any, options: any[], is_disabled = false) => {
    return (
      <Selection
        id={name}
        name={name}
        defaultValue={value || ''}
        options={options}
        onChange={this.on_dropdown_change}
        disabled={is_disabled}
      />
    );
  };

  // Render multiSelect Control
  render_multiSelect_element = (name: string, options: any[], selected_items: any[]) => {
    return (
      <MultiSelectDropDownComponent
        class_name='manage-content-multi-select'
        id={name}
        key={name}
        key_id={name}
        data={options}
        selected_list={selected_items}
        onChangeSelected={this.multi_select_handler}
        forceOnChangeSelected={true}
        has_validation={false}
        show_filter={true}
        reset_disable={true}
        disabled={!this.state.search_criteria.company_id}
      />
    );
  };

  // Render Input Control
  render_input_element = (name: any, value: any, is_disabled: boolean) => {
    return (
      <Form.Field>
        <Form.Input
          fluid
          id={name}
          name={name}
          maxLength={50}
          value={value || ''}
          disabled={!this.state.search_criteria.company_id}
          onChange={this.on_input_change}
          pattern='[0-9]*'
          autoComplete='off'
          type='text'
        />
      </Form.Field>
    );
  };

  // Dropdown change Handler
  on_dropdown_change = (value: any, e) => {
    const { name, selectedOptions, options, selectedIndex } = e.target;
    if (this.is_mounted) {
      if (this.state.search_criteria[name] !== undefined) {
        this.title_search_criteria[name] = selectedOptions ? selectedOptions[0].outerText : options[selectedIndex].text;
        this.setState((prevState: { search_criteria: any }) => ({
          search_criteria: {
            ...prevState.search_criteria,
            [name]: value
          }
        }));
      } else {
        let claim_delay_flag = this.state.update_criteria.claim_delay_flag;
        if (name == 'action') {
          if (value == '2') {
            this.dropdown_data = {
              ...this.dropdown_data,
              claim_delay_reason: this.initial_add_claim_delay_reason || []
            };
            claim_delay_flag = this.initial_add_claim_delay_reason[0].value;
          }

          if (value == '3') {
            this.dropdown_data = {
              ...this.dropdown_data,
              claim_delay_reason: this.initial_remove_claim_delay_reason || []
            };
            claim_delay_flag = this.initial_remove_claim_delay_reason[0].value;
          }
        }

        this.setState((prevState: { update_criteria: any }) => ({
          update_criteria: {
            ...prevState.update_criteria,
            claim_delay_flag,
            [name]: value
          },
          is_updated_disabled: false
        }));
      }
    }
  };

  // multi select change handler
  multi_select_handler = (id, values) => {
    this.dropdown_data[id] = this.dropdown_data[id].map((item) => {
      if (values.indexOf(item.value) > -1) {
        item = {
          ...item,
          checked: true,
          is_checked: true
        };
      } else {
        item = {
          ...item,
          checked: false,
          is_checked: false
        };
      }
      return item;
    });
    if (this.is_mounted) {
      this.title_search_criteria[id] = `${values.length} Selected`;
      this.setState((prevState: { search_criteria: any }) => ({
        search_criteria: {
          ...prevState.search_criteria,
          [id]: [...values]
        }
      }));
    }
  };

  // Input change handler
  on_input_change = (e) => {
    const { name, value } = e.target;
    let search_criteria = { ...this.state.search_criteria };
    if (this.is_mounted) {
      if (value.length > 0 && !/^\d+$/.test(value)) {
        toastr.error('', this.page_metadata.messages.numeric_allow);
        return;
      }
      const txt_pid = e.target.value.trim();
      search_criteria[name] = e.target.validity.valid
        ? Number(txt_pid) <= 2147483647
          ? txt_pid
          : this.max_value_validation(txt_pid)
        : search_criteria[name];

      this.title_search_criteria[name] = search_criteria[name];
      this.setState({
        search_criteria: {
          ...search_criteria
        }
      });
    }
  };

  // Calender change handler.
  range_date_change_handler = (from_date: any, end_date: any) => {
    if (this.is_mounted) {
      this.title_search_criteria = {
        ...this.title_search_criteria,
        date_of_service_from: moment(from_date).format('MM/DD/YYYY') || '',
        date_of_service_end: moment(end_date).format('MM/DD/YYYY') || ''
      };
      this.setState((prevState: { search_criteria: any }) => ({
        search_criteria: {
          ...prevState.search_criteria,
          date_of_service_from: from_date || null,
          date_of_service_end: end_date || null
        },
        is_error_date: from_date
          ? moment.isMoment(from_date)
            ? false
            : true
          : false || end_date
          ? moment.isMoment(end_date)
            ? false
            : true
          : false
      }));
    }
  };

  // Dual Input change Handler
  dual_input_handler = (data, type) => {
    this.title_search_criteria = {
      ...this.title_search_criteria,
      [`${type}_from`]: data.start,
      [`${type}_to`]: data.end
    };
    if (this.is_mounted) {
      this.setState((prevState: { search_criteria: any }) => ({
        search_criteria: {
          ...prevState.search_criteria,
          [`${type}_from`]: data.start,
          [`${type}_to`]: data.end
        }
      }));
    }
  };

  max_value_validation = (input) => {
    if (Number(input) > 2147483647) {
      return this.max_value_validation(input.substring(0, input.length - 1));
    } else {
      return Number(input);
    }
  };

  handle_navigation_after_last_element = (event: { shiftKey: any; keyCode: string }) => {
    // apply a check where grid is empty
    if (!event.shiftKey && event.keyCode == '9' && !this.state.show_grid) {
      set_focus_to_app_header(event);
    }
  };

  show_html_content_toaster = (msg: any) => {
    return {
      component: () => (
        <div>
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        </div>
      ),
      timeOut: toastr_options.toastr_time_out,
      preventDuplicates: true
    };
  };

  //############## Advance Search Controls Start ####################
  /**Functions for Quick Search */
  on_item_selection(selected_row: any, name: any) {
    if (this.is_mounted) {
      if (name == this.PATIENT_ENUM) {
        let patient_search_value = {
          label: selected_row.label,
          title: selected_row.title
        };
        this.title_search_criteria['selected_patient'] = patient_search_value;
        this.setState((prev) => ({
          search_criteria: {
            ...prev.search_criteria,
            patient_id: patient_search_value.title,
            selected_patient: patient_search_value
          }
        }));
      } else {
        this.report_data = {
          column_def: [...this.page_metadata.column_defs],
          rows: []
        };

        let page_metadata = this.get_initial_data();
        this.title_search_criteria = {
          ...this.page_metadata.title_search_criteria
        };
        this.print_export_search_criteria = {
          ...this.title_search_criteria
        };
        this.is_initial_result = false;
        session_storage.remove(`batch_update_criteria`);

        const company_search_value = {
          title: `${selected_row.title}`,
          code: selected_row.gpms_code,
          name: selected_row.name,
          label: selected_row.label
        };
        this.title_search_criteria['selected_company'] = company_search_value;
        let error = this.state.error;
        error.company_id = false;

        let search_criteria = {
          ...page_metadata.initial_search_criteria,
          company_id: company_search_value.title,
          patient_id: '',
          selected_company: company_search_value,
          selected_patient: {}
        };
        this.selected_row = [];
        this.setState(
          {
            search_criteria: search_criteria,
            error: error,
            is_submitted: false,
            show_grid: false,
            is_correct_charge_disabled: true,
            loading: true
          },
          () => {
            this.get_master_data_by_company();
          }
        );
      }
    }
  }

  clear_quick_search = (name: string) => {
    if (this.is_mounted) {
      if (name == this.COMPANIES_ENUM) {
        this.title_search_criteria['selected_company'] = {};
        this.clear_handler(true);
      } else {
        this.title_search_criteria['selected_patient'] = {};
        this.setState((prev) => ({
          search_criteria: {
            ...prev.search_criteria,
            patient_id: '',
            selected_patient: {}
          }
        }));
      }
    }
  };

  // ON Grid Row Selection
  on_grid_row_selection = (selected_row: any, name: string) => {
    if (this.is_mounted) {
      if (name == this.PATIENT_ENUM) {
        const patient_name = this.format_name(selected_row.name);
        let patient_search_value = {
          label: patient_name,
          title: selected_row.id
        };
        this.title_search_criteria['selected_patient'] = patient_search_value;
        this.setState((prev) => ({
          search_criteria: {
            ...prev.search_criteria,
            patient_id: patient_search_value.title,
            selected_patient: patient_search_value
          }
        }));
      } else {
        this.report_data = {
          column_def: [...this.page_metadata.column_defs],
          rows: []
        };

        let page_metadata = this.get_initial_data();
        this.title_search_criteria = {
          ...this.page_metadata.title_search_criteria
        };
        this.print_export_search_criteria = {
          ...this.title_search_criteria
        };
        this.is_initial_result = false;
        session_storage.remove(`batch_update_criteria`);

        const prepared_result = {
          title: `${selected_row.id}`,
          code: selected_row.gpms_code,
          name: selected_row.name,
          label: selected_row.gpms_code == null ? selected_row.name : selected_row.gpms_code + ' - ' + selected_row.name
        };
        this.title_search_criteria['selected_company'] = prepared_result;
        let error = this.state.error;
        error.company_id = false;

        let search_criteria = {
          ...page_metadata.initial_search_criteria,
          company_id: prepared_result.title,
          patient_id: '',
          selected_company: prepared_result,
          selected_patient: {}
        };

        this.selected_row = [];
        this.setState(
          {
            search_criteria: search_criteria,
            error: error,
            is_submitted: false,
            show_grid: false,
            is_correct_charge_disabled: true,
            loading: true
          },
          () => {
            this.get_master_data_by_company();
          }
        );
      }
    }
  };

  format_name = (name: { last_name: any; middle_initial: string; first_name: string }) => {
    return (
      (name.last_name == null ? '' : name.last_name) +
      (name.middle_initial == null ? '' : ' ' + name.middle_initial) +
      (name.first_name == null ? '' : ' ' + name.first_name)
    );
  };

  on_patient_search = (pat_obj: { first_name: any; last_name: any; Id: any; page_size: any }, name: any) => {
    const params = {
      patient_name: {
        first_name: pat_obj.first_name,
        last_name: pat_obj.last_name
      },
      show_in_active: true,
      id: pat_obj.Id,
      page_size: pat_obj.page_size,
      company_id: this.state.search_criteria.company_id
    };
    // Search function.
    if (this.is_mounted) {
      this.props.get_patient_search(params, this.token).then((res: any) => {
        this.setState({
          patient_grid_data: {
            ...this.state.grid_conf,
            rows: this.props.patient_details.patient_search,
            column: sharedConstants.patient_search_configuration(sharedConstants.patient_search_configuration_id)
              .column_defs,
            messages: 'No Patient Found'
          }
        });
      });
    }
  };

  //It renders report grid and its search controls.
  get_data_list = async (inputValue: string, companyid: any, name: string) => {
    return await get_searchList(
      this.token,
      this.state.search_criteria.company_id,
      inputValue.trim(),
      constants.Quick_Search_Suggestion_List_Size,
      end_points.patient_urls.quick_search,
      false
    );
  };

  render_result = (props: { title: string; label: React.ReactNode }, type) => {
    return (
      <div key={props.title} tabIndex={0} className='item_auto_search'>
        <div className='fs_13'>
          <span className='bold'>{props.label}</span>
          {type == this.PATIENT_ENUM && <span>{props.title ? '(' + props.title + ')' : ''}</span>}
        </div>
      </div>
    );
  };

  //Prepare suggestion list to show in Quick Search result
  prepare_suggestion = (data: { data: any[] }, type: string) => {
    if (type == this.PATIENT_ENUM) {
      let formattedList = [];
      if (data && data.data.length > 0) {
        data.data.map(
          (item: {
            last_name: string;
            middle_initial: string;
            first_name: any;
            id: { toString: () => any };
            email: any;
            date_of_birth: string | number | Date;
            phone: { home: string; work: string; cell: string };
          }) => {
            formattedList.push({
              label:
                (item.last_name == null ? '' : item.last_name + ' ') +
                (item.middle_initial == null ? '' : item.middle_initial + ' ') +
                (item.first_name == null ? '' : item.first_name),
              title: item.id.toString(),
              email: item.email,
              dob: (item.date_of_birth == null ? '' : format_date(new Date(item.date_of_birth), false)).toString(),
              age: (item.date_of_birth == null ? '' : get_age(item.date_of_birth)) + ' Yrs',
              home:
                item.phone.home == null || item.phone.home == '' ? ' ' : ' H: ' + format_dashes_number(item.phone.home),
              work:
                item.phone.work == null || item.phone.work == ''
                  ? ' '
                  : ', W: ' + format_dashes_number(item.phone.work),
              cell:
                item.phone.cell == null || item.phone.cell == '' ? ' ' : ', M: ' + format_dashes_number(item.phone.cell)
            });
            return;
          }
        );
      } else {
        if (this.is_mounted) {
          this.setState({ quick_search_error_message: 'No Patient Found !' });
        }
      }
      return formattedList;
    } else {
      let formattedList = [];
      const data_length = data.data.length;
      if (data && data_length) {
        for (let i = 0; i < data_length; i++) {
          let item = data.data[i];
          formattedList.push({
            title: `${item.company_id}`,
            name: item.company_name,
            label: item.gpms_code == '' ? item.company_name : item.gpms_code + ' - ' + item.company_name,
            gpms_code: item.gpms_code
          });
        }
      }
      return formattedList;
    }
  };

  on_advance_search = async (params: { [x: string]: string; code?: any; name?: any }, type: string) => {
    let new_params = {};
    if (type == this.COMPANIES_ENUM) {
      get_company_list(this.token, params.name, params.code).then((response: { data: { data: any } }) => {
        if (response.data) {
          let row_data = response.data.data ? response.data.data : [];
          const grid_data = {
            rows: row_data,
            column: change_company_configuration.column_defs,
            messages: row_data.messages,
            paginationPageSize: 20
          };

          this.setState({ companies_grid_data: grid_data });
        }
      });
    } else {
      new_params = {
        patient_name: {
          first_name: params.first_name,
          last_name: params.last_name
        },
        show_in_active: true,
        id: params.Id,
        page_size: params.page_size,
        company_id: this.state.search_criteria.company_id
      };

      // Search function.
      if (this.is_mounted) {
        this.props.get_patient_search(new_params, this.token).then((res: any) => {
          this.setState({
            patient_grid_data: {
              ...this.state.grid_conf,
              rows: this.props.patient_details.patient_search,
              column: sharedConstants.patient_search_configuration(sharedConstants.patient_search_configuration_id)
                .column_defs,
              messages: 'No Patient Found'
            }
          });
        });
      }
    }
  };

  // get the searched value which is typed for searching location or providers
  get_value = (input: string, type: string) => {
    const { error, is_submitted } = this.state;
    if (input.trim().length >= 3) {
      if (type == this.COMPANIES_ENUM) {
        this.on_company_quick_search(input);
      } else {
      }
    }
  };

  render_advance_quick_search = (
    type: any,
    search_data: any,
    control_id: string,
    value: { label: string; title: string },
    get_quick_search_data_list: Function,
    grid_header_id,
    has_error = false,
    is_disabled = false
  ) => {
    return (
      <React.Fragment>
        <AdvancedControl
          onGridRowSelection={(row: any) => this.on_grid_row_selection(row, type)}
          gridConfig={search_data}
          disabled_element={is_disabled}
          controlId={control_id}
          onSearch={(param: any) => this.on_advance_search(param, type)}
          search_type={control_id}
          headerIdForGridTabNavigation={grid_header_id}
        />
        <AutoSearchComponent
          getInputValue={(e: any) => this.get_value(e, type)}
          default_value={value}
          errorMessage={'No Record Found!'}
          prepareRenderList={(props) => this.render_result(props, type)}
          getList={get_quick_search_data_list}
          prepareDataList={(data: any) => this.prepare_suggestion(data, type)}
          selectresult={(item: any) => this.on_item_selection(item, type)}
          control_id={control_id}
          is_disabled={is_disabled}
          show_clear_search={true}
          clear_search={() => this.clear_quick_search(type)}
          errorClass={has_error ? 'req-background-inp' : ''}
        />
      </React.Fragment>
    );
  };

  on_company_quick_search = async (params: string) => {
    let url = '';
    let result: any;
    if (params.trim().length > 0) {
      url =
        users.api.default_company_quick_search +
        '?keyword=' +
        params +
        '&page_size=' +
        constants.Quick_Search_Suggestion_List_Size;
      result = await get_search_data(this.token, url);
      return result;
    } else {
      let result: any = {};
      result.data = [];
      return result;
    }
  };

  validateForm = () => {
    let company_id = this.state.search_criteria.company_id;
    let patient_id = this.state.search_criteria.patient_id;
    let run_number = this.state.search_criteria.run_number;
    let date_of_service_from = this.state.search_criteria.date_of_service_from;
    let date_of_service_end = this.state.search_criteria.date_of_service_end;

    let has_error = false;
    let error = {
      company_id: false
    };

    if (!company_id) {
      has_error = true;
      error.company_id = true;
      let ele = document.getElementById('ADMIN_COMPANY_SEARCH');
      ele && ele.focus();
    }

    if (has_error) {
      toastr.error('', constants.mandatory_fields);
      if (this.is_mounted) {
        this.setState({
          error: error
        });
      }

      return false;
    }
    let page_metadata = this.get_initial_data();
    if (!patient_id && (!date_of_service_from || !date_of_service_end) && !run_number) {
      has_error = true;
      toastr.error('', page_metadata.messages.patient_dos_run_number_mandatory);
      return false;
    }

    if (date_of_service_from && date_of_service_end) {
        if (date_of_service_from <= date_of_service_end) {
            if (this.props.features.is366DaysDateRangeEnabled) {
                if ((date_of_service_end - date_of_service_from) / 86400000 >= 366) {
                    toastr.error('', page_metadata.messages.date_range_exceed + ' one year.');
                    return false;
                }
            }
            else {
                if ((date_of_service_end - date_of_service_from) / 86400000 >= 92) {
                    toastr.error('', page_metadata.messages.date_range_exceed + ' 92 days.');
                    return false;
                }
            }
      } else {
        toastr.error('', page_metadata.messages.date_range_invalid);
        return false;
      }
    }

    return true;
  };

  in_range_dos = (date_of_service_from, date_of_service_end) => {
    if (date_of_service_from <= date_of_service_end) {
      if ((date_of_service_end - date_of_service_from) / 86400000 >= 92) {
        return false;
      }
    } else {
      return false;
    }

    return;
  };
  //############## Advance Search Controls End ####################

  // Search Handler
  handle_search = (e) => {
    if (this.is_mounted) {
      this.setState({ is_submitted: true });
    }
    this.print_export_search_criteria = { ...this.title_search_criteria };
    if (!this.validateForm()) return;
    this.get_report_data();
    session_storage.set('batch_update_criteria', this.state.search_criteria);
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  // Function calls on click of Clear button and clear all the searched result as well as criteria.
  clear_handler = (on_clear_company: boolean = false) => {
    this.report_data = {
      column_def: [...this.page_metadata.column_defs],
      rows: []
    };

    let page_metadata = this.get_initial_data();
    this.title_search_criteria = {
      ...this.page_metadata.title_search_criteria
    };
    this.print_export_search_criteria = {
      ...this.title_search_criteria
    };
    this.is_initial_result = false;
    session_storage.remove(`batch_update_criteria`);

    let search_criteria = on_clear_company
      ? { ...page_metadata.initial_search_criteria, company_id: '', selected_company: {} }
      : page_metadata.initial_search_criteria;
    if (this.is_mounted) {
      this.selected_row = [];
      this.setState(
        {
          is_submitted: false,
          error: { company_id: on_clear_company },
          search_criteria: search_criteria,
          show_grid: false,
          display_extra_records_modal: false,
          extra_record_count: 0,
          is_correct_charge_disabled: true,
          controls_is_expended: true
        },
        () => {
          if (!on_clear_company) {
            this.get_master_data();
          }
        }
      );
    }
  };

  get_grid_ref = (grid_params: { columnApi: { setColumnWidth: (arg0: any, arg1: any, arg2: boolean) => void } }) => {
    if (this.is_mounted) {
      this.setState({
        grid_params
      });
    }
    let scrollable_area = document.getElementById('report-scrollable-area');
    if (scrollable_area) {
      const grid_width = scrollable_area.clientWidth;
      if (this.total_column_width > grid_width) {
        this.report_data.column_def.filter((item: { field: any; width: any }) => {
          grid_params.columnApi.setColumnWidth(item.field, item.width, false);
        });
      }
    }
  };

  on_grid_out = () => {
    set_focus_on_element_with_id('app_header_dropdown');
  };

  // Used to create the title for export and print.
  set_title = (separator: string) => {
    this.current_date = moment().format('MM/DD/YYYY') + ' ' + moment().format('hh:mm:ss A');
    const {
      date_of_service_from,
      date_of_service_end,
      days_aged_from,
      days_aged_to,
      charge_owed_amount_from,
      charge_owed_amount_to,
      run_number,
      balance_type,
      place_of_service,
      procedure,
      provider,
      insurance,
      insurance_type,
      insurance_class,
      payer,
      selected_patient,
      selected_company
    } = this.print_export_search_criteria;
    let title =
      'Batch Updates' +
      separator +
      'Company: ' +
      selected_company.label +
      separator +
      'Location: ' +
      place_of_service +
      separator +
      'Patient ID: ' +
      selected_patient.title +
      separator +
      'Date of Service: ' +
      (date_of_service_from || '') +
      (date_of_service_end ? ' - ' + date_of_service_end : '') +
      separator +
      'Procedure: ' +
      procedure +
      separator +
      'Provider: ' +
      provider +
      separator +
      'Insurance: ' +
      insurance +
      separator +
      'Insurance Type: ' +
      insurance_type +
      separator +
      'Insurance Class: ' +
      insurance_class +
      separator +
      'Payer: ' +
      payer +
      separator +
      'Balance Type: ' +
      balance_type +
      separator +
      'Days Aged: ' +
      (days_aged_from || '') +
      (days_aged_to ? ' - ' + days_aged_to : '') +
      separator +
      'Charge Owed Amount: ' +
      (charge_owed_amount_from || '') +
      (charge_owed_amount_to ? ' - ' + charge_owed_amount_to : '') +
      separator +
      'Run Number: ' +
      run_number +
      separator +
      this.current_date +
      separator +
      (this.state.report_rows_count == 1
        ? this.state.report_rows_count + ' record'
        : this.state.report_rows_count + ' records') +
      separator +
      separator;
    return title;
  };

  //Function calls on initialization of export report data
  on_export_button = () => {
    let title = this.set_title('\n');
    export_grid_data(this.state.grid_params, title, 'Batch Updates');
    this.saveReportEventAction(ReportConstants.ReportEventAction.Export);
  };

  //Function calls on initialization of Print report data
  on_print_button = () => {
    let title = this.set_title('<br/>');
    print_grid_data(this.state.grid_params, title, 'Batch Updates');
    this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
  };

    // Function calls save billing audit on Export or Print
    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;
        const payload: ReportConstants.IPayloadForBillingAudit = {
            reportId: this.reportId,
            contextTitle: "Batch Updates" + " - " + ReportConstants.ReportEventAction[eventActionId],
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: ReportConstants.ReportEntityTypeId.ChargeAudit,
            data: { Records: this.state.report_rows_count }
        };
        const reqBody = generateAuditDataEntryPayload(payload);
        ReportConstants.saveAuditPrintExport(reqBody, accessToken);
    };

  // Select Row's on checkbox
  on_check_box_selection = (items: any[]) => {
    if (this.is_mounted && (items.length != this.selected_row.length)) {     
      this.selected_row = items;
    }    
  };

  update_batch_status = async () => {
    let charge_ids = [];
    if (this.selected_row && this.selected_row.length <= 0) {
        toastr.error('', this.page_metadata.messages.valid_update_msg);
        return;
    }

    this.selected_row.map((item) => {
      switch (this.state.update_criteria.action) {
        case '3':
          if (item.status == 'L') {
              charge_ids.push({ charge_id: item.charge_id, charge_open_time: item.charge_open_time });
          }
          break;
        case '1':
              if (!(this.state.update_criteria.rebill_status == 'B'
                  && !(item.status == 'R'))) {
                  charge_ids.push({ charge_id: item.charge_id, charge_open_time: item.charge_open_time });
          }
          break;
        default:
              charge_ids.push({ charge_id: item.charge_id, charge_open_time: item.charge_open_time });
          break;
      }
      return item.charge_id;
    });

    const params = {
      charge_ids,
      ...this.state.update_criteria,
      company_id: this.state.search_criteria.company_id,
      run_number: this.state.search_criteria.run_number,
      transaction_message_id: parseInt(this.state.update_criteria.transaction_message)
    };

    // In case of mis-match of selection charges
    if (
      charge_ids &&
      this.selected_row &&
      charge_ids.length != this.selected_row.length &&
      charge_ids.length > 0
    ) {
      toastr.warning('', this.page_metadata.messages.mismatch_update_error);
    }

    if (charge_ids && charge_ids.length <= 0) {
      toastr.error('', this.page_metadata.messages.r2_valid_update_msg);
      return;
    }

    if (this.is_mounted) {
      this.setState({
        loading: true,
        is_search_disabled: true
      });
      }
      //format params
      params.adjustment_code = params.adjustment_code.toString();
      params.run_number = params.run_number == '' ? null : parseInt(params.run_number);
      params.action = params.action == '' ? 0 : parseInt(params.action);

    await update_batch_status(this.token, params).then(
      (response: { data: { status: number; data: any; messages: { message: any }[] } }) => {
          if (response.data && response.data.status == 1) {
              //toastr.success('', this.page_metadata.messages.update_action_success);
              if (charge_ids && charge_ids.length > 0
                  && response.data.data.charge_count > 0
                  && charge_ids.length != response.data.data.charge_count) {
                  toastr.warning('', this.page_metadata.messages.mismatch_update_error);
              }

              const msg = `${
                  response.data.data.charge_count
                  } Charge(s) were updated, Total charge amount: ${currencyFormatter(response.data.data.charge_amount)}.`;
              
              if (response.data.data.charge_count > 0) {
                  toastr.success('', msg);
              } else {
                  toastr.error('', msg);
              }

          if (this.is_mounted) {
            this.selected_row = [];
            this.setState(
              {
                loading: false,
                is_search_disabled: false
              },
              () => this.get_report_data()
            );
          }
        } else {
          if (response.data.messages) {
            const toastr_options = this.show_html_content_toaster(get_all_error(response.data.messages));
            toastr.error('', toastr_options);
          }
          if (this.is_mounted) {
            this.selected_row = [];
            this.setState({
              loading: false,
              is_search_disabled: false
            });
          }
        }
      },
        (error: { response: { data: any } }) => {
            if (error && error.response && error.response.data && error.response.data.messages && error.response.data.messages.length > 0) {
                let exception = error.response.data.messages.filter((item) => {
                    return item.code === charge_exception.code
                });
                if (exception && exception.length>0) {
                    if (this.is_mounted) {
                        this.setState({
                            loading: false,
                            show_bummepd_charge_message_popup: true,
                            is_search_disabled: false
                        })
                    }
                } else {
                    if (this.is_mounted) {
                        this.setState({
                            loading: false,
                            is_search_disabled: false
                        });
                    }
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toastr.error('', toastr_options);
                }
            }
            else {
                if (this.is_mounted) {
                    this.setState({
                        loading: false,
                        is_search_disabled: false
                    });
                }
                if (error.response.data) {
                    log_error(error);
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toastr.error('', toastr_options);
                }
            }
      }
    );
  };

  on_filter_changed = () => {
    this.setState((state) => ({
      report_rows_count: state.grid_params.api.getModel().getRowCount()
    }));
  };


    refresh_bummepd_charge_modal = () => {
        if (this.is_mounted) {
            this.setState({
                show_bummepd_charge_message_popup: false
            }, () => {
                this.selected_row = [];
                this.get_report_data();
            });
        }
    }
    on_close_bummepd_charge_popup = () => {
        if (this.is_mounted) {
            this.setState({
                show_bummepd_charge_message_popup: false
            });
        }
    }

  render() {
    const {
      loading,
      controls_is_expended,
      is_clear_disabled,
      is_error_date,
      search_criteria,
      is_search_disabled,
      update_criteria,
      error,
      is_submitted
    } = this.state;

      return (
        <React.Fragment >
        <LoaderComponent loading={loading}>
            <div className={'common-forms-add '}>
          <div className={'common-forms-search report-framework batch_billing_run'}>
            <ReportHeader title={this.page_metadata.title} />
            <div className='outer-wrap'>
              <div
              className='patient-search-form patient_search_bottom_padding'
              id='report-criteria-container'
              style={{ padding: 0, border: 0, background: 'transparent' }}
            >
              <Form autoComplete='off' onSubmit={this.handle_search}>
                <Grid>
                  <Grid.Column
                    computer={16}
                    tablet={16}
                    textAlign='left'
                    className='accordionColumn'
                    id='accordion-column'
                  >
                    <Accordion fluid styled>
                      <Accordion.Title active={controls_is_expended} index={0} onClick={this.controls_toggle}>
                        <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name='angle right' />
                        Search Criteria
                      </Accordion.Title>
                      <Accordion.Content active={controls_is_expended}>
                        <Grid>
                          <Grid.Column tablet={8} computer={4}>
                            <Form.Field
                              className={
                                error.company_id && is_submitted
                                  ? 'requiredWithBgColor advance-quick-search'
                                  : 'advance-quick-search'
                              }
                            >
                              <label>
                                {'Company'}
                                <span className={error.company_id && is_submitted ? 'req-alert' : 'req-alert_normal'}>
                                  {' '}
                                  (required)
                                </span>{' '}
                              </label>
                              {this.render_advance_quick_search(
                                this.COMPANIES_ENUM,
                                this.state.companies_grid_data,
                                'ADMIN_COMPANY_SEARCH',
                                search_criteria.selected_company,
                                this.on_company_quick_search,
                                change_company_grid_header_id,
                                error.company_id && is_submitted
                              )}
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column tablet={8} computer={4}>
                            <Form.Field>
                              <label>Location</label>
                              {this.render_dropdown_element(
                                'place_of_service',
                                search_criteria.place_of_service,
                                this.dropdown_data.place_of_service,
                                !search_criteria.company_id
                              )}
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column tablet={8} computer={4}>
                            <Form.Field className={'advance-quick-search'}>
                              <label>{'Patient ID'}</label>
                              {this.render_advance_quick_search(
                                this.PATIENT_ENUM,
                                this.state.patient_grid_data,
                                'PATIENT',
                                search_criteria.selected_patient,
                                this.get_data_list,
                                sharedConstants.patient_search_configuration_id,
                                false,
                                !search_criteria.company_id
                              )}
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column tablet={8} computer={4}>
                            <Form.Field>
                              <label>Date of Service</label>
                              <DateRangePickerComponent
                                updateDatesChange={(from_date: any, end_date: any) =>
                                  this.range_date_change_handler(from_date, end_date)
                                }
                                startDate={search_criteria.date_of_service_from}
                                endDate={search_criteria.date_of_service_end}
                                error={is_error_date}
                                id={'date_of_service'}
                                maxDate={moment()}
                                is_disabled={!search_criteria.company_id}
                                anchorDirection={'right'}
                              />
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column tablet={8} computer={4}>
                            <Form.Field>
                              <label>Procedure</label>
                              {this.render_multiSelect_element(
                                'procedure',
                                this.dropdown_data.procedure,
                                search_criteria.procedure
                              )}
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column tablet={8} computer={4}>
                            <Form.Field>
                              <label>Provider</label>
                              {this.render_multiSelect_element(
                                'provider',
                                this.dropdown_data.provider,
                                search_criteria.provider
                              )}
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column tablet={8} computer={4}>
                            <Form.Field>
                              <label>Insurance</label>
                              {this.render_multiSelect_element(
                                'insurance',
                                this.dropdown_data.insurance,
                                search_criteria.insurance
                              )}
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column tablet={8} computer={4}>
                            <Form.Field>
                              <label>Insurance Type</label>
                              {this.render_multiSelect_element(
                                'insurance_type',
                                this.dropdown_data.insurance_type,
                                search_criteria.insurance_type
                              )}
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column tablet={8} computer={4}>
                            <Form.Field>
                              <label>Insurance Class</label>
                              {this.render_multiSelect_element(
                                'insurance_class',
                                this.dropdown_data.insurance_class,
                                search_criteria.insurance_class
                              )}
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column tablet={8} computer={4}>
                            <Form.Field>
                              <label>Payer</label>
                              {this.render_multiSelect_element(
                                'payer',
                                this.dropdown_data.payer,
                                search_criteria.payer
                              )}
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column tablet={8} computer={4}>
                            <Form.Field>
                              <label>Balance Type</label>
                              {this.render_multiSelect_element(
                                'balance_type',
                                this.dropdown_data.balance_type,
                                search_criteria.balance_type
                              )}
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column tablet={8} computer={4}>
                            <Form.Field>
                              <label>Days Aged</label>
                              <DualInput
                                onChange={(data, e) => this.dual_input_handler(data, 'days_aged')}
                                defaultValue={{
                                  start: search_criteria.days_aged_from,
                                  end: search_criteria.days_aged_to
                                }}
                                placeHolder={{ start: 'From', end: 'To' }}
                                disabled={!search_criteria.company_id}
                              />
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column tablet={8} computer={4}>
                            <Form.Field>
                              <label>Charge Owed Amount</label>
                              <DualCurrencyInput
                                onChange={(data, e) => this.dual_input_handler(data, 'charge_owed_amount')}
                                defaultValue={{
                                  start: search_criteria.charge_owed_amount_from,
                                  end: search_criteria.charge_owed_amount_to
                                }}
                                placeHolder={{ start: 'From', end: 'To' }}
                                disabled={!search_criteria.company_id}
                              />
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column tablet={8} computer={4}>
                            <Form.Field>
                              <label>Run Number</label>
                              {this.render_input_element('run_number', search_criteria.run_number, false)}
                            </Form.Field>
                          </Grid.Column>
                        </Grid>
                      </Accordion.Content>
                    </Accordion>
                  </Grid.Column>
                </Grid>
              </Form>
            </div>
            <Grid style={{ marginTop: 0, marginBottom: 0 }}>
              <Grid.Column tablet={16} computer={16} textAlign='right'>
                <Button
                  type='reset'
                  id='clear_button'
                  basic
                  onClick={() => this.clear_handler()}
                  disabled={is_clear_disabled}
                  content={'Clear'}
                />
                <Button
                  id='search_error'
                  className='primary'
                  type='submit'
                  onKeyDown={this.handle_navigation_after_last_element}
                  style={{ marginRight: '0' }}
                  disabled={is_search_disabled}
                  content={'Search'}
                  onClick={this.handle_search}
                />
              </Grid.Column>
            </Grid>
            {this.state.show_grid && (
              <div id='report-scrollable-area' className='report-wrapper mar-t-10'>
                <Grid className='dateTime' style={{ marginTop: 10 }}>
                  <Grid.Column tablet={5} computer={4} textAlign='left'>
                    <p style={{ fontSize: '16px', minHeight: 22 }}>Search Results</p>
                  </Grid.Column>
                  <Grid.Column tablet={5} computer={8} textAlign='center'>
                    {
                      <p style={{ fontSize: '16px', minHeight: 22 }}>
                        {`${this.state.report_rows_count} ${
                          this.state.report_rows_count == 1 ? ' record shown' : ' records shown'
                        }`}
                      </p>
                    }
                  </Grid.Column>
                  <Grid.Column tablet={2} computer={4} textAlign='right' />
                </Grid>
                <GridView
                  id={batch_updates_report_grid_id}
                  row={this.report_data.rows}
                  column={this.report_data.column_def}
                  headerHeight={this.grid_header_height}
                  style={{ height: '100%', display: 'flex', flexFlow: 'column' }}
                  wrapperClass={'grid_wrapper_hierarchy'}
                  enableColResize={true}
                  gridFullHeight={true}
                  get_grid_ref={this.get_grid_ref}
                  onRowSelection={this.on_check_box_selection}
                  suppressSizeToFit={true}
                  headerIdForTabNavigation='error_corrections_configuration_header_id'
                  emptyMessage={messages.no_records_found}
                  checkboxSelection={true}
                  handleCheckboxNavigation={true}
                  on_filter_button_click={this.on_filter_changed}
                />
              </div>
            )}
            </div>
            {this.state.show_grid && (
              <Grid.Column
                tablet={16}
                computer={16}
                className='footer-area'
                id='applicationFooterSticky'
                style={{ paddingRight: 22, paddingLeft: 0 }}
              >
                <Grid style={{ marginTop: 0, marginBottom: 0 }}>
                  <Grid.Column tablet={8} computer={4} textAlign='left'>
                    <Form.Field>
                      <label>Action</label>
                      {this.render_dropdown_element(
                        'action',
                        update_criteria.action,
                        this.dropdown_data.action,
                        this.state.is_updated_disabled
                      )}
                    </Form.Field>
                  </Grid.Column>
                  {update_criteria.action == 0 && (
                    <React.Fragment>
                      <Grid.Column tablet={8} computer={4} textAlign='left'>
                        <Form.Field>
                          <label>Adjustment Code</label>
                          {this.render_dropdown_element(
                            'adjustment_code',
                            update_criteria.adjustment_code,
                            this.dropdown_data.adjustment_code,
                            !search_criteria.company_id || this.state.is_updated_disabled
                          )}
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column tablet={8} computer={4} textAlign='left'>
                        <Form.Field>
                          <label>Transaction Message</label>
                          {this.render_dropdown_element(
                            'transaction_message',
                            update_criteria.transaction_message,
                            this.dropdown_data.transaction_message,
                            this.state.is_updated_disabled
                          )}
                        </Form.Field>
                      </Grid.Column>
                    </React.Fragment>
                  )}
                  {update_criteria.action == -1 && <Grid.Column tablet={8} computer={4}></Grid.Column>}
                  {update_criteria.action == 1 && (
                    <Grid.Column tablet={8} computer={4} textAlign='left'>
                      <Form.Field>
                        <label>Set New Status</label>
                        {this.render_dropdown_element(
                          'rebill_status',
                          update_criteria.rebill_status,
                          this.dropdown_data.rebill_status,
                          this.state.is_updated_disabled
                        )}
                      </Form.Field>
                    </Grid.Column>
                  )}
                  {(update_criteria.action == 2 || update_criteria.action == 3) && (
                    <Grid.Column tablet={8} computer={4} textAlign='left'>
                      <Form.Field>
                        <label>Claim Delay Reason</label>
                        {this.render_dropdown_element(
                          'claim_delay_flag',
                          update_criteria.claim_delay_flag,
                          this.dropdown_data.claim_delay_reason,
                          this.state.is_updated_disabled
                        )}
                      </Form.Field>
                    </Grid.Column>
                  )}
                  {update_criteria.action != 0 && <Grid.Column tablet={8} computer={4}></Grid.Column>}
                  <Grid.Column
                    tablet={8}
                    computer={4}
                    textAlign='right'
                    style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}
                  >
                    <Button
                      id='export_report_button'
                      type='submit'
                      onClick={this.on_export_button}
                      basic
                      disabled={this.state.is_export_disabled}
                      content={'Export'}
                    />
                    <Button
                      id='print_report_button'
                      type='submit'
                      onClick={this.on_print_button}
                      disabled={this.state.is_print_disabled}
                      basic
                      content={'Print'}
                    />
                    <Button
                      id='is_updated_disabled'
                      className='primary'
                      onClick={this.update_batch_status}
                      type='button'
                      onKeyDown={set_focus_to_app_header}
                      style={{ marginRight: '0' }}
                      disabled={this.state.is_updated_disabled}
                      content={'Apply'}
                    />
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            )}
          </div>
        </div>
         </LoaderComponent>
        {this.state.show_bummepd_charge_message_popup ?
            <ConfirmationComponent message={charge_exception.message}
                title='Confirm' show_modal={this.state.show_bummepd_charge_message_popup}
                onCloseModal={this.on_close_bummepd_charge_popup}
                save_button_text='Refresh'
                on_force_save_click={(e) => { this.refresh_bummepd_charge_modal() }} />
            : ''}

        {this.state.display_extra_records_modal && <DisplayExtraRecordsWarning
            remaining_records={this.state.extra_record_count}
            show_modal={this.state.display_extra_records_modal} />}
      </React.Fragment >
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      get_patient_search: get_patient_search
    },
    dispatch
  );
};

const mapStateToProps = (state: {
  patient_details: { patient_header: { data: { id: any } } };
  user_login_details: any;
  shared_details: any;
  launch_darkly: any;
}) => {
  return {
    patient_details: state.patient_details,
    user_login_details: state.user_login_details,
    shared_details: state.shared_details,
    patient_id:
      (state.patient_details &&
        state.patient_details.patient_header &&
        state.patient_details.patient_header.data &&
        state.patient_details.patient_header.data.id) ||
	    '',
    features: {        
        is366DaysDateRangeEnabled: get_lauch_darkley_key_value(state.launch_darkly, BillingAuditLogPagesFeature.rsiB32441BatchUpdatesExtendDosSearchLength),
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BatchUpdatesComponent));