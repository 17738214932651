import insert_place_holder_text from './insert_place_holder_text';
import { RichTextEditorCustomMenuControl } from '../rich_text_editor_helper'

const get_custom_menu_component = (controlType: string) => {

    let CustomMenuControl = null;

    switch (controlType) {
        case RichTextEditorCustomMenuControl.addInlineText:
            CustomMenuControl = insert_place_holder_text;
            break;
    }
    return CustomMenuControl;
}

export default {
    get_custom_menu_component,
    insert_place_holder_text
};