import * as React from 'react';
import { sso_help_url } from '../../shared/shared_constants';

function ssoSuccessMessage() {
    return (
        <div>
            <p>
                You may now close this window. To access WebPT Billing, click the Billing icon in the upper right of WebPT
                EMR. <br /> <br /><br />

            </p>
            <p>
				<a href={sso_help_url} target="_blank">Click here</a> to
                learn more.
            </p>
        </div>
    );
}

export default ssoSuccessMessage;
