import * as moment from 'moment';
import * as React from 'react';
import * as NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-scroll';
import TextareaAutosize from 'react-textarea-autosize';
import { bindActionCreators } from 'redux';
import { Button, Checkbox, Dimmer, Form, Grid, GridColumn, Header, Loader, Modal, Table } from 'semantic-ui-react';
import DiagnosisComponent from '../../diagnosis/component/diagnosis_component';
import * as diagnosis_constant from '../../diagnosis/diagnosis_constant';
import * as global_constants from '../../global_constants';
import { update_patient_header_info } from '../../patient/action/patient_header_action';
import { get_contracts, get_insurance_types } from '../../shared/action/shared_action';
import AddReferringPhysician from '../../shared/component/add_referring_physician_component';
import AddReferringSource from '../../shared/component/add_refferring_source_component';
import ConfirmationComponent from '../../shared/component/confirmation_component';
import DatePicker from '../../shared/component/date_picker_component';
import Selection from '../../shared/component/selection_component';
import * as local_storage from '../../shared/local_storage_utility';
import * as session_storage from '../../shared/session_storage_utility';
import * as shared_constants from '../../shared/shared_constants';
import { quick_search_location, quick_search_provider, referring_physician_advance_search, referring_physician_quick_search, referring_source_advance_search, referring_source_quick_search, search_place, search_provider, special_program_advance_search, special_program_quick_search } from './../../shared/action/autosearch_action';
import AdvancedControl from './../../shared/component/advanced_control';
import AutoSearchComponent from './../../shared/component/auto_search_component';
import { set_focus_on_element_with_id, set_focus_to_app_header } from './../../shared/tab_navigation_utility';
import { custom_date_format, get_all_error, trim_nested_json_obj_values, valid_policy_number_ch, valid_policy_number_mc, valid_policy_number_new, valid_policy_number_rr, get_lauch_darkley_key_value, is_null_or_white_space, date_format_with_no_time } from './../../shared/utility';
import { add_case, add_new_pre_auth_data, get_case, get_descipline, update_case } from './../action/case_action';
import { case_messages, configurable_date, enum_case_page_state, enum_date_type, enum_type_of_insurance, enum_type_of_physician, enum_type_of_search, fields_to_compare } from './../case_constants';
import ConditionalBillingComponent from './conditional_billing_component';
import InsuranceComponent from './insurance_component';
import VerificationFormComponent from './verification_form_component';
import { set_active_patient } from '../../login/action/login_action';
import { caseEditingRedirect } from '../../admin/constants/constants';
import * as _ from 'lodash';

// Add Edit and view case details
//Define the state with their data types
interface IState {
    claim_indicator: boolean,
    demand_claim: boolean,
    redirectPage: string,
    statusOptionList: boolean,
    rad_window_type: { _has_visit_pre_auth: boolean, _has_unit_pre_auth: boolean, _current_pre_auth_tab: string },
    rad_win_btn_text:string,
    master_data: any,
    loading: boolean,
    patient_id: string,
    // is_edit_mode: boolean,
    page_state: enum_case_page_state,
    form_submited: boolean,
    primary_physician_search_data: any,
    second_physician_search_data: any,
    selected_primary_physician: any,
    selected_secondary_physician: any,
    location_search_data: any,
    provider_search_data: any,
    referral_search_data: any,
    special_program_search_data: any,
    grid_conf: any,
    is_primary_insurance_show: boolean,
    is_secondary_insurance_show: boolean,
    is_tertiary_insurance_show: boolean,
    is_conditional_billing_show: boolean,
    is_shown_insurance: boolean,
    add_physician_modal: boolean,
    selected_physican_for_add: enum_type_of_physician,
    show_primary_advanced_control: boolean,
    show_secondary_advanced_control: boolean,
    show_refferal_advanced_control: boolean,
    add_referral_modal: boolean,
    is_insurance_worker_comp: boolean,
    is_save_button_disabled: boolean,
    is_clone_case: boolean,
    referring_physician_quick_search_format: {
        "title": string,
        "label": string,
        "md_code": string
    },
    second_referring_physician_quick_search_format:
    {
        "title": string,
        "label": string,
        "md_code": string
    },
    location_quick_search_format:
    {
        "title": string,
        "label": string,
        "pos_code": string
    },
    provider_quick_search_format:
    {
        "title": string,
        "label": string,
        "bp_code": string
    },
    referral_source_quick_search_format:
    {
        "title": string,
        "label": string,
        "rs_code": string
    },
    special_program_quick_search_format:
    {
        "title": string,
        "label": string,
        "sb_code": string
    },
    show_primary_insurance: boolean,
    show_secondary_insurance: boolean,
    show_tertiary_insurance: boolean,
    show_foto: boolean,
    show_verification_form: boolean,
    show_conditional_billing: boolean,
    insurance_code_confirmation_box: boolean,
    doctors_order_confirmation_box: boolean,
    preauthorization_confirmation_box: boolean,
    preauthorization_doctors_order_confirmation_box: boolean,
    insurance_types: Array<any>,
    insurance_contract: Array<any>,
    case: {
        id: number,
        patient_id: number,
        description: string,
        injury_type: string,
        injury_date?: any,
        formatted_injury_date?: any,
        start_of_care_date?: any,
        formatted_start_of_care_date?: any,
        assignment_date?: any,
        formatted_assignment_date?: any,
        effective_date?: any,
        formatted_effective_date?: any,
        discharge_date?: any,
        formatted_discharge_date?: any,
        next_visit_date?: any,
        formatted_next_visit_date?: any,

        //to store old value for required for billing
        old_assignment_date: any,
        old_effective_date: any,
        old_case_name: string,
        old_referring_physician: string,
        old_location: string,
        old_provider: string,
        old_descipline: string,
        ////

        //other properties
        status: string,
        provider_id: string,
        md_code: string,
        secondary_md_code: string,
        company_id: number,
        discipline: number,
        plan_of_care_date: string,
        pos_code: string,
        billing_provider_code: string,
        treating_provider_code: string,
        is_active: boolean,
        alt_record_id: string,
        pt_count: number,
        ot_count: number,
        rt_count: number,
        sp_count: number,
        msw_count: number,
        bms_visit_count: number,
        other_count: number,
        is_deleted: boolean,
        is_default: boolean,
        incomplete_flag: number,
        si_flag: string,
        referring_source_id: number,
        allow_transmission: boolean,
        special_billing: boolean,
        note_code: string,
        is_incomplete_rf3: boolean,
        visit_count: number,
        effective_end_date: any,
        date_of_service_from: any,
        date_of_service_to: any,
        charge_visit_count: number,
        internal_information: string,
        accident_location: string,
        referral_date: any,
        formatted_referral_date?: any,
        referral_status: number,
        special_program: number,
        conditional_billing_code1: string,
        conditional_billing_code2: string,
        conditional_billing_code3: string,
        conditional_billing_note: string,
        patient_contact_comment: string,
        referring_physician: {
            id: string
        },
        secondary_referring_physician: {
            id: string
        },
        place_of_service: {
            id: string
        }, provider: {
            id: string
        },
        referring_source: string,
        special_programs: {
            id: string
        }
        created_audit: {
            name: string,
            date: string
        },
        updated_audit: {
            name: string,
            date: string
        },
        subscriber: Array<any>,
        add_new_pre_auth: Array<any>,
        has_active_charge: boolean
    },
    case_form_invalid: {
        case: {
            description: boolean,
            injury_type: boolean,
            injury_date: boolean,
            assignment_date: boolean,
            effective_date: boolean,
            start_of_care_date: boolean,
            next_visit_date: boolean,
            discharge_date: boolean,
            referring_physician: boolean,
            place_of_service: boolean,
            provider: boolean,
            descipline: boolean,
            patient_contact: boolean,
            patient_contact_comment: boolean,

            formatted_injury_date: boolean,
            formatted_assignment_date: boolean,
            formatted_effective_date: boolean,
            formatted_start_of_care_date: boolean,
            formatted_next_visit_date: boolean,
            formatted_discharge_date: boolean,
            formatted_referral_date: boolean

        }
    },
    modified_data: {
        case: {
            description: boolean,
            assignment_date: boolean,
            effective_date: boolean,
            referring_physician: boolean,
            place_of_service: boolean,
            provider: boolean,
            descipline: boolean

        }
    },
    new_pre_auth_data: Array<any>,
    show_case_stale_message_popup: boolean,
    case_before_edition: any
    
}

export class CaseRegistrationComponent extends React.Component<any, IState> {
    [x: string]: any;
    _is_mounted = false;
    _grouped_new_pre_auth_data: Array<any> = [];
    rebill: boolean = false;
    //_RAD_Window_For_Visit = false;
    constructor(props) {

        super(props);
        var is_calling_from_clone_case = session_storage.get('is_clone_case') ? session_storage.get('is_clone_case') : false;
        session_storage.set('is_clone_case', false);
        this.state = {
            claim_indicator: typeof this.props.shared_details.left_menu_data === 'undefined' ? false : this.get_menu_access(this.props.case_editing_url_redirect.claimIndicatorMenuId),
            demand_claim: typeof this.props.shared_details.left_menu_data === 'undefined' ? false : this.get_menu_access(this.props.case_editing_url_redirect.demandClaimMenuId),
            redirectPage: '',
            statusOptionList: false,
            show_case_stale_message_popup: false,
            rad_window_type: { _has_visit_pre_auth: false, _has_unit_pre_auth: false, _current_pre_auth_tab: '' },
            rad_win_btn_text:'Save',
            master_data: this.props.user_login_details.formated_master_data,
            patient_id: session_storage.get('active_patient'),
            loading: false,
            page_state: is_calling_from_clone_case ? enum_case_page_state.add : this.props.match.params.case_id ? enum_case_page_state.edit : enum_case_page_state.add,
            form_submited: false,
            primary_physician_search_data: {},
            second_physician_search_data: {},
            selected_primary_physician: {},
            selected_secondary_physician: {},
            location_search_data: {},
            provider_search_data: {},
            referral_search_data: {},
            special_program_search_data: {},
            selected_physican_for_add: enum_type_of_physician.none,
            show_primary_advanced_control: false,
            show_secondary_advanced_control: false,
            show_refferal_advanced_control: false,
            grid_conf: {
                isPagination: true,
                gridAutoHeight: true,
                selectionType: 'single',
                paginationPageSize: 20,
            },
            is_primary_insurance_show: true,
            is_secondary_insurance_show: true,
            is_tertiary_insurance_show: true,
            is_conditional_billing_show: (local_storage.get("auth_data") && local_storage.get("auth_data").allow_conditional_billing) ? local_storage.get("auth_data").allow_conditional_billing : false,// this.props.user_login_details.user_data.data.allow_conditional_billing,
            is_shown_insurance: false,
            is_insurance_worker_comp: false,
            is_save_button_disabled: false,
            is_clone_case: is_calling_from_clone_case,
            referring_physician_quick_search_format:
            {
                "title": '',
                "label": '',
                "md_code": ''
            },
            second_referring_physician_quick_search_format: {
                "title": '',
                "label": '',
                "md_code": ''
            },
            location_quick_search_format:
            {
                "title": '',
                "label": '',
                "pos_code": ''
            },
            provider_quick_search_format:
            {
                "title": '',
                "label": '',
                "bp_code": ''
            },
            referral_source_quick_search_format:
            {
                "title": '',
                "label": '',
                "rs_code": ''
            },
            special_program_quick_search_format:
            {
                "title": '',
                "label": '',
                "sb_code": ''
            },
            show_primary_insurance: true,
            show_secondary_insurance: true,
            show_tertiary_insurance: true,
            show_foto: true,
            show_verification_form: true,
            show_conditional_billing: this.props.user_login_details.user_data.data.allow_conditional_billing,
            insurance_code_confirmation_box: false,
            doctors_order_confirmation_box: false,
            preauthorization_confirmation_box: false,
            preauthorization_doctors_order_confirmation_box: false,
            insurance_types: [],
            insurance_contract: [],
            case: {
                id: this.props.match && this.props.match.params && this.props.match.params.case_id ? parseInt(this.props.match.params.case_id) : 0,
                patient_id: parseInt(session_storage.get('active_patient')),
                description: '',
                injury_type: "",
                injury_date: null,
                formatted_injury_date: null,
                start_of_care_date: null,
                formatted_start_of_care_date: null,
                assignment_date: null,
                formatted_assignment_date: null,
                effective_date: null,
                formatted_effective_date: is_calling_from_clone_case ? null : moment.parseZone(new Date()),
                discharge_date: null,
                formatted_discharge_date: null,
                next_visit_date: null,
                formatted_next_visit_date: null,
                //
                old_assignment_date: null,
                old_effective_date: null,
                old_case_name: null,
                old_referring_physician: null,
                old_location: null,
                old_provider: null,
                old_descipline: null,
                //
                //other properties
                status: null,
                provider_id: null,
                md_code: "",
                secondary_md_code: "",
                company_id: null,
                discipline: null,
                plan_of_care_date: null,
                pos_code: null,
                billing_provider_code: null,
                treating_provider_code: null,
                is_active: true,
                alt_record_id: null,
                pt_count: null,
                ot_count: null,
                rt_count: null,
                sp_count: null,
                msw_count: null,
                bms_visit_count: null,
                other_count: null,
                is_deleted: null,
                is_default: false,
                incomplete_flag: 0,
                si_flag: null,
                referring_source_id: null,
                allow_transmission: false,
                special_billing: false,
                note_code: null,
                is_incomplete_rf3: false,
                visit_count: null,
                effective_end_date: null,
                date_of_service_from: null,
                date_of_service_to: null,
                charge_visit_count: null,
                internal_information: null,
                accident_location: null,
                referral_date: null,
                formatted_referral_date: null,
                referral_status: 1,
                special_program: null,
                conditional_billing_code1: null,
                conditional_billing_code2: null,
                conditional_billing_code3: null,
                conditional_billing_note: null,
                patient_contact_comment: null,
                referring_physician: {
                    id: "-999"
                },
                secondary_referring_physician: {
                    id: "-999"
                }, place_of_service: {
                    id: "-999"
                },
                provider: {
                    id: "-999"
                }, referring_source: null,
                special_programs: {
                    id: "-999"
                }, created_audit: {
                    name: '',
                    date: new Date().toISOString()
                },
                updated_audit: {
                    name: '',
                    date: new Date().toISOString()
                },
                subscriber: [],
                add_new_pre_auth: [],
                has_active_charge: false
            },
            case_form_invalid: {
                case: {
                    description: false,
                    injury_type: false,
                    injury_date: false,
                    assignment_date: false,
                    effective_date: false,
                    start_of_care_date: false,
                    next_visit_date: false,
                    discharge_date: false,
                    referring_physician: false,
                    place_of_service: false,
                    provider: false,
                    descipline: false,
                    patient_contact_comment: false,
                    patient_contact: false,

                    formatted_injury_date: false,
                    formatted_assignment_date: false,
                    formatted_effective_date: false,
                    formatted_start_of_care_date: false,
                    formatted_next_visit_date: false,
                    formatted_discharge_date: false,
                    formatted_referral_date: false
                }
            },
            modified_data: {
                case: {
                    description: false,
                    assignment_date: false,
                    effective_date: false,
                    referring_physician: false,
                    place_of_service: false,
                    provider: false,
                    descipline: false

                }
            },
            add_physician_modal: false,
            add_referral_modal: false,
            new_pre_auth_data: [],
            case_before_edition: {}
        }
        // Initialize state property here
        this.diagnosis_child = React.createRef();
        this.insurance_child_primary = React.createRef();
        this.insurance_child_secondary = React.createRef();
        this.insurance_child_tertiary = React.createRef();
        this.conditional_billing_child = React.createRef();
        this.user_type_id = local_storage.get("auth_data") ? local_storage.get("auth_data").user_type_id : 0;
        this.is_rebill_force_save = null;
        this.show_new_pre_auth_red_windo_modal = false;
        this.pre_auth_not_selected = {
            id: 0,
            pre_auth_number: "",
            unique_id: "00000000-0000-0000-0000-000000000001"
        };
        this.fields_required_to_exclude = [];
    }

    reset_invalid_fields_with_view_mode = (is_form_submit = this.state.form_submited, is_loading = false, page_state = enum_case_page_state.edit) => {
        if (this._is_mounted) {
            this.setState({
                new_pre_auth_data: [],
                loading: is_loading, page_state: page_state, form_submited: is_form_submit,  //is_save_button_disabled: false,
                //reset all the validation field to false
                case_form_invalid: {
                    case: {
                        description: false,
                        injury_type: false,
                        injury_date: false,
                        assignment_date: false,
                        effective_date: false,
                        start_of_care_date: false,
                        next_visit_date: false,
                        discharge_date: false,
                        referring_physician: false,
                        place_of_service: false,
                        provider: false,
                        descipline: false,
                        patient_contact_comment: false,
                        patient_contact: false,
                        formatted_injury_date: false,
                        formatted_assignment_date: false,
                        formatted_effective_date: false,
                        formatted_start_of_care_date: false,
                        formatted_next_visit_date: false,
                        formatted_discharge_date: false,
                        formatted_referral_date: false
                    }

                }
            });
        }
    }
    // funtion/method to handle the cancle click on add/edit
    on_case_cancel_click = () => {
        if (this.state.case.id != 0) {
            this.reset_invalid_fields_with_view_mode(false);
            //reset the insrurance component data
            if (this.insurance_child_primary) {
                this.insurance_child_primary.reset_invalid_form_data();
                this.insurance_child_primary.insurance_doctors_orders.get_doctors_orders_data_from_props();
            }
            if (this.insurance_child_secondary) {
                this.insurance_child_secondary.reset_invalid_form_data();
            }
            if (this.insurance_child_tertiary) {
                this.insurance_child_tertiary.reset_invalid_form_data();
            }
            this.get_case_information(this.state.case.patient_id, this.state.case.id, true);
            this.update_insurance_data(this.state.case.id);
            if (this.diagnosis_child && this.diagnosis_child.get_case_diagnosis_code) {
                this.diagnosis_child.get_case_diagnosis_code(this.state.case.id);
            }
        }
        else {
            this.props.history.push(`/patient/${this.state.patient_id}`);
        }
        this.toggle_child_accordian();

    }
    scroll_height = 0;

    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }
    componentDidMount() {
        this._is_mounted = true;
        document.body.classList.add('case-view');
        // For EDIT
        if (this.state.page_state === enum_case_page_state.edit) {
            if (this.state.patient_id == '') {
                this.props.history.push(`/no_patient_selected`);
            }
            else {
                if (this._is_mounted) {
                    this.setState({ loading: true });
                }
                this.get_case_information(this.state.patient_id, this.state.case.id);
                //if (this.state.case.id > 0) {
                //    this.props.update_patient_header_info(true, this.state.case.id, true);
                //}
            }

        }
        else {
            if (this.state.is_clone_case) {
                if (Number(this.state.patient_id) > 0 && Number(this.state.case.id) > 0) {
                    if (this._is_mounted) {
                        this.setState({ loading: true });
                    }
                    this.get_case_information(this.state.patient_id, this.state.case.id);
                    this.setCaseBeforeEdition();
                    //if (this.state.case.id > 0) {
                    //    this.props.update_patient_header_info(true, this.state.case.id, true);
                    //}
                }
            }

        }
        this.get_insurance_master_info();
    }
    componentWillUnmount() {
        this._is_mounted = false;
        document.body.classList.remove('case-view');
    }
    url_tempered_action = () => {
        toastr.error('', global_constants.url_rewrite.error_message);
        if (this._is_mounted) {
            this.setState({
                loading: false
            });
        }
        session_storage.set('active_patient', '');
        this.props.set_active_patient('');
        this.props.history.push(`${global_constants.url_rewrite.navigate_to}`);
    }
    // funtion/method to get the case information when we view/edit case
    get_case_information = async (patient_id, case_id, is_reset = false) => {
        var _this = this;
        if (isNaN(Number(case_id)) || Number(case_id) >= global_constants.maximum_value.integer) {
            this.url_tempered_action();
        }
        else {
            await get_case(this.props.user_login_details.user_data.data.token_details.access_token, patient_id, case_id).then(response => {
                if (response.data.data) {
                    if (response.data.data.id == 0) {
                        this.url_tempered_action();
                    }
                    else {
                        response.data.data.injury_type = parseInt(response.data.data.injury_type);
                        // Set date object for injury Date 
                        if (!(response.data.data.injury_date == '' || response.data.data.injury_date == null || response.data.data.injury_date == undefined)) {
                            response.data.data.formatted_injury_date = moment(response.data.data.injury_date);
                        }
                        // Set date object for assignment Date
                        if (!(response.data.data.assignment_date == '' || response.data.data.assignment_date == null || response.data.data.assignment_date == undefined)) {
                            response.data.data.formatted_assignment_date = moment(response.data.data.assignment_date);
                            response.data.data.old_assignment_date = moment(response.data.data.assignment_date);
                        }
                        // Set date object  for discharge date
                        if (!(response.data.data.discharge_date == '' || response.data.data.discharge_date == null || response.data.data.discharge_date == undefined)) {
                            response.data.data.formatted_discharge_date = moment(response.data.data.discharge_date);
                        }
                        // Set date object for case effective date
                        if (!(response.data.data.effective_date == '' || response.data.data.effective_date == null || response.data.data.effective_date == undefined)) {
                            response.data.data.formatted_effective_date = moment(response.data.data.effective_date);
                            response.data.data.old_effective_date = moment(response.data.data.assignment_date);
                        }
                        //else {
                        //    response.data.data.formatted_effective_date = moment(new Date());
                        //}
                        // Set date object for next doctor visit date
                        if (!(response.data.data.next_visit_date == '' || response.data.data.next_visit_date == null || response.data.data.next_visit_date == undefined)) {
                            response.data.data.formatted_next_visit_date = moment(response.data.data.next_visit_date);
                        }
                        // Set date object  for start of care date
                        if (!(response.data.data.start_of_care_date == '' || response.data.data.start_of_care_date == null || response.data.data.start_of_care_date == undefined)) {
                            response.data.data.formatted_start_of_care_date = moment(response.data.data.start_of_care_date);
                        }
                        // Set date object  for start of care date
                        if (!(response.data.data.referral_date == '' || response.data.data.referral_date == null || response.data.data.referral_date == undefined)) {
                            response.data.data.formatted_referral_date = moment(response.data.data.referral_date);
                        }
                        // Set date object  for start of care date
                        if (response.data.data.created_audit) {
                            if (!(response.data.data.created_audit.date == '' || response.data.data.created_audit.date == null || response.data.data.created_audit.date == undefined)) {
                                //response.data.data.created_audit.date = moment(response.data.data.created_audit.date);
                                response.data.data.created_audit.date = response.data.data.created_audit.date == null ? null : custom_date_format(response.data.data.created_audit.date, global_constants.date_format["mm/dd/yyyy h:MM:ss TT"]);
                            }
                        }
                        // Set date object  for start of care date
                        if (response.data.data.updated_audit) {
                            if (!(response.data.data.updated_audit.date == '' || response.data.data.updated_audit.date == null || response.data.data.updated_audit.date == undefined)) {
                                response.data.data.updated_audit.date = moment(response.data.data.updated_audit.date);
                                response.data.data.updated_audit.date = response.data.data.updated_audit.date == null ? null : custom_date_format(response.data.data.updated_audit.date, global_constants.date_format["mm/dd/yyyy h:MM:ss TT"]);
                            }
                        }
                        //store old value of case for required for billing
                        response.data.data.old_case_name = response.data.data.description;
                        response.data.data.old_referring_physician = response.data.data.md_code;
                        response.data.data.old_location = response.data.data.pos_code;
                        response.data.data.old_provider = response.data.data.billing_provider_code;
                        response.data.data.old_descipline = response.data.data.discipline;
                        //For referral source
                        response.data.data.referral_status = response.data.data.referral_status ? response.data.data.referral_status : 1;

                        /////

                        var referring_physician_format = { "label": '', "title": '', "md_code": '' };
                        var second_referring_physician_format = { "label": '', "title": '', "md_code": '' };
                        var place_of_service_format = { "label": '', "title": '', "pos_code": '' };
                        var provider_format = { "label": '', "title": '', "bp_code": '' };
                        var referral_source_format = { "label": '', "title": '', "rs_code": '' };
                        var special_billing_format = { "label": '', "title": '', "sb_code": '' };

                        if (response.data.data.referring_physician.id > 0) {
                            var _title = (response.data.data.referring_physician.name.first_name ? response.data.data.referring_physician.name.first_name : '') + " " + (response.data.data.referring_physician.name.last_name ? response.data.data.referring_physician.name.last_name : '') + (response.data.data.referring_physician.address == null ? '' : response.data.data.referring_physician.address.address1 == null ? '' : ", " + response.data.data.referring_physician.address.address1);
                            // Set Auto search control for referring physician
                            referring_physician_format = {
                                "title": response.data.data.referring_physician.id.toString(),
                                "md_code": response.data.data.referring_physician.md_code,
                                "label": _title == ' ' ? response.data.data.referring_physician.md_code : (response.data.data.referring_physician.md_code) + " - " + _title
                            };
                        }

                        if (response.data.data.secondary_referring_physician.id > 0) {
                            var _title_s = (response.data.data.secondary_referring_physician.name.first_name ? response.data.data.secondary_referring_physician.name.first_name : '') + " " + (response.data.data.secondary_referring_physician.name.last_name ? response.data.data.secondary_referring_physician.name.last_name : '') + (response.data.data.secondary_referring_physician.address == null ? '' : response.data.data.secondary_referring_physician.address.address1 == null ? '' : ", " + response.data.data.secondary_referring_physician.address.address1);
                            second_referring_physician_format = {
                                "title": response.data.data.secondary_referring_physician.id.toString(),
                                "md_code": response.data.data.secondary_referring_physician.md_code,
                                "label": _title_s == ' ' ? response.data.data.secondary_referring_physician.md_code : (response.data.data.secondary_referring_physician.md_code) + " - " + _title_s

                            };
                        }
                        if (response.data.data.place_of_service.id > 0) {
                            place_of_service_format = {
                                "title": response.data.data.place_of_service.id.toString(),
                                "pos_code": response.data.data.place_of_service.pos_code,
                                "label": (response.data.data.place_of_service.code) + " - " + (response.data.data.place_of_service.name)

                            };
                        }
                        if (response.data.data.provider.id > 0) {
                            provider_format = {
                                "title": response.data.data.provider.id.toString(),
                                "bp_code": response.data.data.provider.pos_code,
                                "label": (response.data.data.provider.code) + " - " + (response.data.data.provider.description)

                            };
                        }
                        if (response.data.data.referring_source_id > 0) {
                            referral_source_format = {
                                "title": response.data.data.referring_source_id.toString(),
                                "rs_code": response.data.data.referring_source_id,
                                "label": response.data.data.referring_source

                            };
                        }
                        if (response.data.data.special_programs.id > 0) {
                            special_billing_format = {
                                "title": response.data.data.special_programs.id.toString(),
                                "sb_code": response.data.data.special_programs.special_program_code,
                                "label": (response.data.data.special_programs.special_program_code) + " - " + (response.data.data.special_programs.special_program_name)

                            };
                        }
                        //////
                        if (is_reset) {
                            if (this._is_mounted) {
                                this.setState({
                                    referring_physician_quick_search_format: referring_physician_format,
                                    second_referring_physician_quick_search_format: second_referring_physician_format,
                                    location_quick_search_format: place_of_service_format,
                                    provider_quick_search_format: provider_format,
                                    referral_source_quick_search_format: referral_source_format,
                                    special_program_quick_search_format: special_billing_format,
                                    case: response.data.data,
                                    loading: false,
                                    new_pre_auth_data: [],
                                    case_form_invalid: {
                                        case: {
                                            description: false,
                                            injury_type: false,
                                            injury_date: false,
                                            assignment_date: false,
                                            effective_date: false,
                                            start_of_care_date: false,
                                            next_visit_date: false,
                                            discharge_date: false,
                                            referring_physician: false,
                                            place_of_service: false,
                                            provider: false,
                                            descipline: false,
                                            patient_contact_comment: false,
                                            patient_contact: false,

                                            formatted_injury_date: false,
                                            formatted_assignment_date: false,
                                            formatted_effective_date: false,
                                            formatted_start_of_care_date: false,
                                            formatted_next_visit_date: false,
                                            formatted_discharge_date: false,
                                            formatted_referral_date: false
                                        }

                                    }
                                });
                            }

                        }
                        else {
                            if (this._is_mounted) {
                                this.setState({
                                    referring_physician_quick_search_format: referring_physician_format,
                                    second_referring_physician_quick_search_format: second_referring_physician_format,
                                    location_quick_search_format: place_of_service_format,
                                    provider_quick_search_format: provider_format,
                                    referral_source_quick_search_format: referral_source_format,
                                    special_program_quick_search_format: special_billing_format,
                                    case: response.data.data,
                                    loading: false,
                                    new_pre_auth_data: [],
                                });
                            }

                        }
                    }
                }
            }, function (error) {
                if (_this._is_mounted) {
                    _this.setState({
                        loading: false
                    });
                }

            })
        }
    }

    get_insurance_master_info = async () => {
        var ins_type = await get_insurance_types(this.token).then(res => {
            if (res.data.data != null && res.data.data.length > 0) {

                return res.data.data.map((val, index) => {
                    return { key: index, value: val.id, text: val.name }
                });
                //this.insurance_type_id = res.data.data;
                //return res.data.data.map((val, index) => {
                //    return { key: index, value: val.id, text: val.name }
                //});
            }
        }); // Get All insurance types from api

        const available_contracts = await get_contracts(this.token).then(res => {
            if (res.data.data != null && res.data.data.length > 0) {
                return res.data.data;
            }
        });
        if (this._is_mounted) {
            this.setState({
                insurance_types: ins_type,
                insurance_contract: available_contracts
            })
        }
    }

    check_relationship = (control_flags, subscriber) => {
        return control_flags.is_req_validation_policy_no_mc && parseInt(subscriber.relationship) == 1;
        //return ((control_flags.is_req_validation_policy_no_mc) && parseInt(subscriber.relationship)) == 1 ? true : false;
    }
    validate_policy_number_for_insurance = (insurance_subscriber_info, insurance_control_flag, insurance_invalid, policy_check_date, ins_type) => {
        var is_invalid_policy_no = false;
        var is_invalid_relationship = false;
        var trans_start = new Date(configurable_date.trans_start);
        var trans_end = new Date(configurable_date.trans_end);
        var err_message = '';
        var return_flag = true;
        if (insurance_control_flag && insurance_subscriber_info) {
           if (insurance_control_flag.is_req_validation_policy_no_mc && !this.check_relationship(insurance_control_flag, insurance_subscriber_info)) {
               err_message += case_messages.relationship_error + '<br/>';
                is_invalid_relationship = true;
            }
            if (insurance_control_flag.is_req_validation_policy_no_mc && !(insurance_control_flag.is_show_claim_number_ssn || insurance_control_flag.is_show_ssn_claim_number)) {
                // check for MC 
                if (!(insurance_subscriber_info.policy_number == null || insurance_subscriber_info.policy_number == undefined || insurance_subscriber_info.policy_number == '')) {
                    if (policy_check_date < trans_start) {
                        if (!valid_policy_number_mc(insurance_subscriber_info.policy_number)) {
                            err_message += case_messages.policy_number_error_medicare + '<br/>'
                            is_invalid_policy_no = true;

                        }
                    }
                    else if (policy_check_date > trans_end) {
                        if (!valid_policy_number_new(insurance_subscriber_info.policy_number)) {
                            err_message += case_messages.policy_number_error_medicare + '<br/>';
                            is_invalid_policy_no = true;
                        }
                    }
                    else {
                        if (!valid_policy_number_mc(insurance_subscriber_info.policy_number)) {
                            if (!valid_policy_number_new(insurance_subscriber_info.policy_number)) {
                                err_message += case_messages.policy_number_error_medicare + '<br/>';
                                is_invalid_policy_no = true;
                            }
                        }
                    }

                }
                
            }
            //check for RR
            if (insurance_control_flag.is_req_validation_policy_no_rr && !(insurance_control_flag.is_show_claim_number_ssn || insurance_control_flag.is_show_ssn_claim_number)) {
                if (!(insurance_subscriber_info.policy_number == null || insurance_subscriber_info.policy_number == undefined || insurance_subscriber_info.policy_number == '')) {

                    if (policy_check_date < trans_end) {
                        if (!valid_policy_number_rr(insurance_subscriber_info.policy_number)) {
                            if (!valid_policy_number_new(insurance_subscriber_info.policy_number)) {
                                err_message += case_messages.policy_number_error_rail_road + '<br/>';
                                is_invalid_policy_no = true;
                            }
                        }
                    }
                    else {
                        if (!valid_policy_number_new(insurance_subscriber_info.policy_number)) {
                            err_message += case_messages.policy_number_error_rail_road + '<br/>';
                            is_invalid_policy_no = true;
                        }
                    }


                }
                //if (!this.check_relationship(insurance_control_flag, insurance_subscriber_info)) {
                //    err_message += case_messages.relationship_error;
                //    is_invalid_relationship = true;
                //}
            }
            if (err_message != '') {
                const toastr_options = this.show_html_content_toaster(err_message);
                toastr.error('', toastr_options);
                if (is_invalid_policy_no) {
                    insurance_invalid.policy_number = true;
                }
                else {
                    insurance_invalid.policy_number = false;
                }
                if (is_invalid_relationship) {
                    insurance_invalid.relationship = true;
                }
                else {
                    insurance_invalid.relationship = false;
                }

                return_flag = false;
            }
            else {
                insurance_invalid.policy_number = false;
            }
            if (ins_type == enum_type_of_insurance.primary_insurance) {
                if (this.insurance_child_primary) {
                    var subscriber_state = {
                        is_subscriber_data: false,
                        is_modified_data: false,
                        is_valid_data: true,
                        subscriber_form_invalid: insurance_invalid
                    }
                    this.insurance_child_primary.set_state_from_parent(subscriber_state);
                }
            }
            else if (ins_type == enum_type_of_insurance.secondary_insurance) {
                if (this.insurance_child_secondary) {
                    var subscriber_state = {
                        is_subscriber_data: false,
                        is_modified_data: false,
                        is_valid_data: true,
                        subscriber_form_invalid: insurance_invalid
                    }



                    this.insurance_child_secondary.set_state_from_parent(subscriber_state);
                }
            }
            else {
                if (this.insurance_child_tertiary) {
                    var subscriber_state = {
                        is_subscriber_data: false,
                        is_modified_data: false,
                        is_valid_data: true,
                        subscriber_form_invalid: insurance_invalid
                    }
                    this.insurance_child_tertiary.set_state_from_parent(subscriber_state);
                }
            }
            //return return_flag;
        }
        return return_flag;
        //return true;

    }
    validate_insurance_data = () => {
        var primary_insurance_subscriber_info = null;
        var secondary_insurance_subscriber_info = null;
        var tertiary_insurance_subscriber_info = null;
        var primary_insurance_control_flag = null;
        var secondary_insurance_control_flag = null;
        var tertiary_insurance_control_flag = null;
        var primary_insurance_invalid = null;
        var secondary_insurance_invalid = null;
        var tertiary_insurance_invalid = null;
        if (this.insurance_child_primary) {
            if (this.insurance_child_primary.state) {
                primary_insurance_subscriber_info = this.insurance_child_primary.state.subscriber;
                primary_insurance_control_flag = this.insurance_child_primary.state.control_flags;
                primary_insurance_invalid = Object.assign({}, this.insurance_child_primary.state.subscriber_form_invalid);
            }
        }
        if (this.insurance_child_secondary) {
            if (this.insurance_child_secondary.state) {
                secondary_insurance_subscriber_info = this.insurance_child_secondary.state.subscriber;
                secondary_insurance_control_flag = this.insurance_child_secondary.state.control_flags;
                secondary_insurance_invalid = Object.assign({}, this.insurance_child_secondary.state.subscriber_form_invalid);
            }
        }
        if (this.insurance_child_tertiary) {
            if (this.insurance_child_tertiary.state) {
                tertiary_insurance_subscriber_info = this.insurance_child_tertiary.state.subscriber;
                tertiary_insurance_control_flag = this.insurance_child_tertiary.state.control_flags;
                tertiary_insurance_invalid = Object.assign({}, this.insurance_child_tertiary.state.subscriber_form_invalid);
            }
        }
        // Primary insurance is mendetory 
        if (tertiary_insurance_subscriber_info) {
            if ((tertiary_insurance_subscriber_info.is_insurance_available) && (tertiary_insurance_subscriber_info.is_active == true)) {
                if ((secondary_insurance_subscriber_info.is_active == true) && (secondary_insurance_subscriber_info.is_insurance_available)) {
                    if (primary_insurance_subscriber_info.is_active == false || !primary_insurance_subscriber_info.is_insurance_available) {
                        var msg = '';
                        if (primary_insurance_subscriber_info.old_insurance) {
                            msg = case_messages.primary_insurance_undefined_error + '<br/>' + case_messages.primary_insurance_cant_remove_error;
                        }
                        else {
                            msg = case_messages.primary_insurance_undefined_error;
                        }

                        const toastr_options = this.show_html_content_toaster(msg);
                        toastr.error('', toastr_options);
                        return false;
                    }
                }
            }
        }
        if (secondary_insurance_subscriber_info) {
            if ((secondary_insurance_subscriber_info.is_active == true) && (secondary_insurance_subscriber_info.is_insurance_available)) {
                if (primary_insurance_subscriber_info.is_active == false || !primary_insurance_subscriber_info.is_insurance_available) {
                    if (primary_insurance_subscriber_info.old_insurance) {
                        msg = case_messages.primary_insurance_undefined_error + '<br/>' + case_messages.primary_insurance_cant_remove_error;
                    }
                    else {
                        msg = case_messages.primary_insurance_undefined_error;
                    }

                    const toastr_options = this.show_html_content_toaster(msg);
                    toastr.error('', toastr_options);
                    return false;
                }
            }
        }
        if (tertiary_insurance_subscriber_info) {
            if ((tertiary_insurance_subscriber_info.is_active == true) && (tertiary_insurance_subscriber_info.is_insurance_available)) {
                if (secondary_insurance_subscriber_info.is_active == false || !secondary_insurance_subscriber_info.is_insurance_available) {
                    var msg = case_messages.secondary_insurance_undefined_error;
                    const toastr_options = this.show_html_content_toaster(msg);
                    toastr.error('', toastr_options);
                    return false;
                }
            }
        }
        if (primary_insurance_subscriber_info) {
            if ((primary_insurance_subscriber_info.employee_address != undefined && primary_insurance_subscriber_info.employee_address.unformat_zip != null)) {
                if ((primary_insurance_subscriber_info.employee_address.unformat_zip.length > 0 && primary_insurance_subscriber_info.employee_address.unformat_zip.length < 5) || (primary_insurance_subscriber_info.employee_address.unformat_zip.length > 5 && primary_insurance_subscriber_info.employee_address.unformat_zip.length < 9)) {
                    toastr.error('', case_messages.zip_code_error);
                    return false;
                }
            }
        }
        if (primary_insurance_subscriber_info) {
            if ((primary_insurance_subscriber_info.address != undefined && primary_insurance_subscriber_info.address.unformat_zip != null)) {
                if ((primary_insurance_subscriber_info.address.unformat_zip.length > 0 && primary_insurance_subscriber_info.address.unformat_zip.length < 5) || (primary_insurance_subscriber_info.address.unformat_zip.length > 5 && primary_insurance_subscriber_info.address.unformat_zip.length < 9)) {
                    toastr.error('', case_messages.zip_code_error);
                    return false;
                }
            }
        }
        if (secondary_insurance_subscriber_info) {
            if ((secondary_insurance_subscriber_info.employee_address != undefined && secondary_insurance_subscriber_info.employee_address.unformat_zip != null)) {
                if ((secondary_insurance_subscriber_info.employee_address.unformat_zip.length > 0 && secondary_insurance_subscriber_info.employee_address.unformat_zip.length < 5) || (secondary_insurance_subscriber_info.employee_address.unformat_zip.length > 5 && secondary_insurance_subscriber_info.employee_address.unformat_zip.length < 9)) {
                    toastr.error('', case_messages.zip_code_error);
                    return false;
                }
            }
        }
        if (secondary_insurance_subscriber_info) {
            if ((secondary_insurance_subscriber_info.address != undefined && secondary_insurance_subscriber_info.address.unformat_zip != null)) {
                if ((secondary_insurance_subscriber_info.address.unformat_zip.length > 0 && secondary_insurance_subscriber_info.address.unformat_zip.length < 5) || (secondary_insurance_subscriber_info.address.unformat_zip.length > 5 && secondary_insurance_subscriber_info.address.unformat_zip.length < 9)) {
                    toastr.error('', case_messages.zip_code_error);
                    return false;
                }
            }
        }
        if (tertiary_insurance_subscriber_info) {
            if ((tertiary_insurance_subscriber_info.employee_address != undefined && tertiary_insurance_subscriber_info.employee_address.unformat_zip != null)) {
                if ((tertiary_insurance_subscriber_info.employee_address.unformat_zip.length > 0 && tertiary_insurance_subscriber_info.employee_address.unformat_zip.length < 5) || (tertiary_insurance_subscriber_info.employee_address.unformat_zip.length > 5 && tertiary_insurance_subscriber_info.employee_address.unformat_zip.length < 9)) {
                    toastr.error('', case_messages.zip_code_error);
                    return false;
                }
            }
        }
        if (tertiary_insurance_subscriber_info) {
            if ((tertiary_insurance_subscriber_info.address != undefined && tertiary_insurance_subscriber_info.address.unformat_zip != null)) {
                if ((tertiary_insurance_subscriber_info.address.unformat_zip.length > 0 && tertiary_insurance_subscriber_info.address.unformat_zip.length < 5) || (tertiary_insurance_subscriber_info.address.unformat_zip.length > 5 && tertiary_insurance_subscriber_info.address.unformat_zip.length < 9)) {
                    toastr.error('', case_messages.zip_code_error);
                    return false;
                }
            }
        }
        if (primary_insurance_subscriber_info) {
            if (!primary_insurance_subscriber_info.is_active) {
                toastr.error('', case_messages.primary_insurance_cant_remove_error);
                return false;
            }
        }
        if (this.state.is_insurance_worker_comp) {
            if (primary_insurance_subscriber_info.employee_name == undefined || primary_insurance_subscriber_info.employee_name == "" || primary_insurance_subscriber_info.employee_name == null) {
                toastr.error('', case_messages.emp_name_required);
                return false;
            }
        }

        var errorMsg = '';
        if (primary_insurance_subscriber_info && secondary_insurance_subscriber_info && tertiary_insurance_subscriber_info) {
            if (primary_insurance_subscriber_info.insurance_code_id != undefined) {
                var countError = 0;
                if (secondary_insurance_subscriber_info.insurance_code_id != undefined) {

                    // check primary insuarnce and secondary insurance
                    // if ((primary_insurance_subscriber_info.insurance_code_id == secondary_insurance_subscriber_info.insurance_code_id) && secondary_insurance_subscriber_info.is_active) {
                    if ((primary_insurance_subscriber_info.insurance_code_id == secondary_insurance_subscriber_info.insurance_code_id) && secondary_insurance_subscriber_info.is_active) {
                        toastr.error('', case_messages.primary_secondary_insurance_same);
                        countError = countError + 1;
                    }

                    if (tertiary_insurance_subscriber_info.insurance_code_id != undefined) {
                        //if ((secondary_insurance_subscriber_info.insurance_code_id == tertiary_insurance_subscriber_info.insurance_code_id) &&
                        //    secondary_insurance_subscriber_info.is_active && tertiary_insurance_subscriber_info.is_active) {
                        if ((secondary_insurance_subscriber_info.insurance_code_id == tertiary_insurance_subscriber_info.insurance_code_id) && tertiary_insurance_subscriber_info.is_active) {
                            toastr.error('', case_messages.secondary_tertiary_insurance_same);
                            countError = countError + 1;
                        }

                        //if ((primary_insurance_subscriber_info.insurance_code_id == tertiary_insurance_subscriber_info.insurance_code_id)
                        //    && secondary_insurance_subscriber_info.is_active && tertiary_insurance_subscriber_info.is_active) {      
                        if ((primary_insurance_subscriber_info.insurance_code_id == tertiary_insurance_subscriber_info.insurance_code_id) && tertiary_insurance_subscriber_info.is_active) {
                            toastr.error('', case_messages.primary_tertiary_insurance_same);
                            countError = countError + 1;
                        }
                    }
                }
                if (countError > 0) return false;
            }
        }
        //Policy check validation
        var trans_start = new Date(configurable_date.trans_start);
        var trans_end = new Date(configurable_date.trans_end);
        var err_message = '';
        var policy_check_date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());//new Date();
        if (this.state.case.discharge_date) {
             policy_check_date = new Date(this.state.case.discharge_date);
        }
        //if (!policy_check_date) {
        //    policy_check_date = new Date();
        //}
        if (primary_insurance_control_flag && primary_insurance_subscriber_info) {
            if (primary_insurance_control_flag.is_req_validation_policy_no_ch && !(primary_insurance_control_flag.is_show_claim_number_ssn || primary_insurance_control_flag.is_show_ssn_claim_number)) {
                if (primary_insurance_subscriber_info.policy_number != undefined && primary_insurance_subscriber_info.policy_number != '') {
                    if (!valid_policy_number_ch(primary_insurance_subscriber_info.policy_number)) {
                        toastr.error('', case_messages.policy_number_error_tricare);

                        return false;
                    }
                }
            }
        }
        if (secondary_insurance_control_flag && secondary_insurance_subscriber_info) {
            if (secondary_insurance_control_flag.is_req_validation_policy_no_ch && !(secondary_insurance_control_flag.is_show_claim_number_ssn || secondary_insurance_control_flag.is_show_ssn_claim_number)) {
                if (secondary_insurance_subscriber_info.policy_number != undefined && secondary_insurance_subscriber_info.policy_number != '') {
                    if (!valid_policy_number_ch(secondary_insurance_subscriber_info.policy_number)) {
                        toastr.error('', case_messages.policy_number_error_tricare);

                        return false;
                    }
                }
            }
        }
        if (tertiary_insurance_control_flag && tertiary_insurance_subscriber_info) {
            if (tertiary_insurance_control_flag.is_req_validation_policy_no_ch && !(tertiary_insurance_control_flag.is_show_claim_number_ssn || tertiary_insurance_control_flag.is_show_ssn_claim_number)) {
                if (tertiary_insurance_subscriber_info.policy_number != undefined && tertiary_insurance_subscriber_info.policy_number != '') {
                    if (!valid_policy_number_ch(tertiary_insurance_subscriber_info.policy_number)) {
                        toastr.error('', case_messages.policy_number_error_tricare);

                        return false;
                    }
                }
            }
        }
        if (this.validate_policy_number_for_insurance(primary_insurance_subscriber_info, primary_insurance_control_flag, primary_insurance_invalid, policy_check_date, enum_type_of_insurance.primary_insurance)) {
            if (!primary_insurance_control_flag.is_worker_comp) {
                if (this.validate_policy_number_for_insurance(secondary_insurance_subscriber_info, secondary_insurance_control_flag, secondary_insurance_invalid, policy_check_date, enum_type_of_insurance.secondary_insurance)) {
                    if (this.validate_policy_number_for_insurance(tertiary_insurance_subscriber_info, tertiary_insurance_control_flag, tertiary_insurance_invalid, policy_check_date, enum_type_of_insurance.tertiary_insurance)) {
                        return true;
                    } else { return false; }
                }

                else { return false; }
            }
            else { return true; }
        }
        else {
            return false;
        }
        return true;
    }

    update_insurance_data = async (case_id) => {


        let arrData = []
        if (this.insurance_child_primary) {
            if (this.insurance_child_primary.get_insurance_data) {               
                arrData.push(this.insurance_child_primary.get_insurance_data(enum_type_of_insurance.primary_insurance, this.state.patient_id, case_id, true));
            }
        }
        if (this.insurance_child_secondary) {
            if (this.insurance_child_secondary.get_insurance_data) {
                arrData.push(this.insurance_child_secondary.get_insurance_data(enum_type_of_insurance.secondary_insurance, this.state.patient_id, case_id, true));
            }
        }
        if (this.insurance_child_tertiary) {
            if (this.insurance_child_tertiary.get_insurance_data) {
                arrData.push(this.insurance_child_tertiary.get_insurance_data(enum_type_of_insurance.tertiary_insurance, this.state.patient_id, case_id, true));
            }
        }
        else {
            if (this._is_mounted) {
                this.setState({ is_tertiary_insurance_show: false });
            }
        }

        await Promise.all(arrData).then(res => {
            
        }).catch(error => {
        })

        document.getElementById("scrollable-content-area").scrollTop = 0
    }
    on_case_after_save = async (case_id, is_add, rebill = false, no_changes = false) => {
        // if (is_insurance_add) {
        await this.update_insurance_data(case_id);
        //}
        if(no_changes){
            toastr.warning('', case_messages.updated_no_changes);
        } else {
            toastr.success('', is_add ? case_messages.added_successfully : case_messages.updated_successfully);
        }        
        this.reset_invalid_fields_with_view_mode();
        this.get_case_information(this.state.patient_id, case_id);
        if (this.diagnosis_child && this.diagnosis_child.get_case_diagnosis_code) {
            this.diagnosis_child.get_case_diagnosis_code(case_id);
        }
        this.is_rebill_force_save = null;
        this.props.update_patient_header_info(true, case_id, (is_add || rebill) ? false : true);

        if (this.state.redirectPage !== this.props.case_editing_url_redirect.redirectPageDefaultValue) {
            let navigate_to = `/billing/${this.state.redirectPage}`;
            this.props.history.push(`${navigate_to}`);
        } else {
            this.props.history.push(`/case/${case_id}`);
        }
    }
    // funtion/method to handle the add case functionality
    add_case = async (is_insurance_save) => {
        var _this = this;
        add_case(_this.props.user_login_details.user_data.data.token_details.access_token, _this.state.patient_id, trim_nested_json_obj_values(this.state.case)).then(async response => {
            if (response.data.data && response.data.data > 0) {
                session_storage.set('active_case', response.data.data);
                //add update dx code for case 
                if (this.diagnosis_child.add_update_diagnosis_code) {
                    var diagnosis_response = await this.diagnosis_child.add_update_diagnosis_code(response.data.data);
                }
                // For saving subscriber information
                let is_success = false
                if (is_insurance_save) {
                    //add subscriber data to case
                    let insurance_child = {};
                    if (this.state.is_insurance_worker_comp && this.insurance_child_primary.state.subscriber.is_insurance_available) {
                        insurance_child = { 'primary': this.insurance_child_primary };
                    }
                    else {
                        if (this.insurance_child_primary.state.subscriber.is_insurance_available) {
                            insurance_child['primary'] = this.insurance_child_primary;
                        }
                        if (this.insurance_child_secondary.state.subscriber.is_insurance_available) {
                            insurance_child['secondary'] = this.insurance_child_secondary;
                        }
                        if (this.insurance_child_tertiary.state.subscriber.is_insurance_available) {
                            insurance_child['tertairy'] = this.insurance_child_tertiary;
                        }
                    }
                    //let insurance_child = this.state.is_insurance_worker_comp ? { 'primary': this.insurance_child_primary } : { 'primary': this.insurance_child_primary, 'secondary': this.insurance_child_secondary, 'tertairy': this.insurance_child_tertiary }                    

                    let arrData = []
                    for (let insurance in insurance_child) {
                        if (insurance_child[insurance].on_save_subscriber) {
                            var subscriber_response = await insurance_child[insurance].on_save_subscriber(response.data.data).then(async res => {
                                is_success = true
                                if (res.data.status == 0) {
                                    if (res.data.messages && res.data.messages.length > 0) {
                                        if (res.data.messages[0].message != '') {
                                            toastr.error('', res.data.messages[0].message);
                                        }

                                    }
                                } else {
                                    const insurance_eligibility = insurance_child[insurance].insurance_eligibility
                                    const insurance_preauthorization = insurance_child[insurance].insurance_preauthorization
                                    if (res.data.messages && res.data.messages.length > 0) {
                                        if (res.data.messages[0].message != '') {
                                            toastr.warning('', res.data.messages[0].message);
                                        }
                                    }
                                    if (insurance_eligibility && !insurance_eligibility.is_list_empty()) {
                                        arrData.push(insurance_eligibility.on_save_eligibility(response.data.data, res.data.data, trim_nested_json_obj_values(insurance_eligibility.get_updated_eligibility_list())))
                                    }
                                    if (insurance === 'primary') {
                                        const insurance_doctors_orders = insurance_child[insurance].insurance_doctors_orders
                                        const doctors_order_list = insurance_doctors_orders.get_valid_doctors_order_list()
                                        if (doctors_order_list.length > 0) {
                                            arrData.push(insurance_doctors_orders.on_save_doctors_order(response.data.data, res.data.data, trim_nested_json_obj_values(doctors_order_list)))
                                        }

                                    }
                                    if (!(insurance_preauthorization.is_empty_preauth_list())) {
                                        arrData.push(insurance_preauthorization.on_save_preauth(response.data.data, res.data.data, trim_nested_json_obj_values(insurance_preauthorization.get_valid_preauth_data())))
                                    }
                                }
                            }, (error) => {

                                if (error.response && error.response.data && error.response.data.messages) {
                                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                                    toastr.error('', toastr_options);
                                }
                            })
                        }
                    }
                    await Promise.all(arrData).then(async res => {  
                      await  this.on_case_after_save(response.data.data, true);
                    }).catch(error => {
                    })
                }
                //remove is_success flag as there is no significance of using it
                if (!is_insurance_save) {
                    this.on_case_after_save(response.data.data, true);
                }
            }
            if (response.data.messages && response.data.messages.length > 0) {
                var case_data = _this.state.case;
                case_data.subscriber = [];
                if (_this._is_mounted) {
                    _this.setState({ case: case_data, loading: false, page_state: enum_case_page_state.edit });
                }
                toastr.warning('', response.data.messages[0].message);
               // this.on_case_after_save(response.data.data, true);
            }

        }, function (error) {
            var case_data = _this.state.case;
            case_data.subscriber = [];
            if (_this._is_mounted) {
                _this.setState({ case: case_data, loading: false, is_save_button_disabled: false });
            }
            if (error.response && error.response.data && error.response.data.messages) {
                const toastr_options = _this.show_html_content_toaster(get_all_error(error.response.data));
                toastr.error('', toastr_options);
            }

        });
    }

    format_update_case_data = (pre_auth_updated_data=undefined) => {
        var diagnosis_code_list = [];
        var diagnosis_update = false;

        //add update dx code for case 
        if (this.diagnosis_child.get_update_diagnosis_code_list) {
            var diagnosis_code_info = this.diagnosis_child.get_update_diagnosis_code_list();
            diagnosis_code_list = diagnosis_code_info.diagnosis_list;
            diagnosis_update = diagnosis_code_info.is_diagnosis_update;
        }

        var update_case_data = {
            case: this.state.case,
            add_new_pre_auth: pre_auth_updated_data ? pre_auth_updated_data : this.state.new_pre_auth_data,
            diagnosis: diagnosis_code_list,
            is_diagnosis_update: diagnosis_update,
            is_rebill_force_save: this.is_rebill_force_save,
        };

        if (update_case_data.case.injury_type != '') {
            update_case_data.case.injury_type = update_case_data.case.injury_type.toString();
        }
        if (update_case_data.case.created_audit.date) {
            var a_date = moment(update_case_data.case.created_audit.date);
            update_case_data.case.created_audit.date = a_date.toDate().toISOString();
        }
        if (update_case_data.case.updated_audit.date) {
            var a_date = moment(update_case_data.case.updated_audit.date);
            update_case_data.case.updated_audit.date = a_date.toDate().toISOString();
        }

        return update_case_data
    }
  
    groupBy=(list, keyGetter)=> {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
    }

    updated_data = (new_pre_auth_data) => {
        let updated_data = [];
        let group_data = this.groupBy([...new_pre_auth_data], pre_auth => pre_auth.visit_id);
        group_data.forEach((value, key, map) => {
                let units = 0;               
                let owed_amt = 0;               
                value.forEach((value, indx) => {
                            units += value.units;
                            owed_amt += value.owed_amt;                           
                });
            let merged_value = { ...value[0] };
            merged_value.units = units; 
            merged_value.owed_amt = owed_amt;
                updated_data.push(merged_value);           
        });
        return updated_data;
    }  
    
    get_rad_window_type = (data) => {
        let _has_visit_pre_auth = false;
        let _has_unit_pre_auth =false;
        let _current_pre_auth_tab = '';
        if (data && data.pri_pre_auth && data.pri_pre_auth.length > 0) {
            data.pri_pre_auth.forEach((val, indx) => {
                if (val.id == 0 && !val.is_units && val.is_active) {
                    _has_visit_pre_auth = true;
                }
                if (val.id == 0 && val.is_units && val.is_active) {
                    _has_unit_pre_auth = true;
                }
            });
        }
        if (data && data.sec_pre_auth && data.sec_pre_auth.length>0) {
    data.sec_pre_auth.forEach((val, indx) => {
                if (val.id == 0 && !val.is_units && val.is_active) {
                    _has_visit_pre_auth = true;
                }
                if (val.id == 0 && val.is_units && val.is_active) {
                    _has_unit_pre_auth = true;
                }
            });
        }
        if (data && data.ter_pre_auth && data.ter_pre_auth.length>0) {
    data.ter_pre_auth.forEach((val, indx) => {
                if (val.id == 0 && !val.is_units && val.is_active) {
                    _has_visit_pre_auth = true;
                }
                if (val.id == 0 && val.is_units && val.is_active) {
                    _has_unit_pre_auth = true;
                }
            });
        }
        if (_has_visit_pre_auth && _has_unit_pre_auth) { _current_pre_auth_tab = 'v'; }
        else if (_has_visit_pre_auth && !_has_unit_pre_auth) { _current_pre_auth_tab = 'v'; }
        else if (!_has_visit_pre_auth && _has_unit_pre_auth) { _current_pre_auth_tab = 'u'; }


        return { _has_visit_pre_auth, _has_unit_pre_auth, _current_pre_auth_tab };

    }

    set_default_auth_before_pre_auth_model_open = () => {
        let charge_visit_data = this.state.new_pre_auth_data
        if (charge_visit_data && charge_visit_data.length > 0) {
            const primarySub = charge_visit_data[0].pri_pre_auth.filter((x) => {
                if (this.state.rad_window_type._current_pre_auth_tab == 'u' && x.is_units && x.is_active && x.unique_id != "00000000-0000-0000-0000-000000000001") {
                    return x
                } else if (this.state.rad_window_type._current_pre_auth_tab == 'v' && !x.is_units && x.is_active && x.unique_id != "00000000-0000-0000-0000-000000000001") {
                    return x
                };
            });

            if (primarySub && primarySub.length > 0) {
                primarySub.map((auth: any, index: number) => {
                    var preAuthAllowedUnits = (auth.pre_auth_visits == undefined || auth.pre_auth_visits == null) ? 0 : auth.pre_auth_visits;
                    charge_visit_data.map((visit: any, key: number) => {
                        if (!visit.pri_pre_auth_selected_unique_id || visit.pri_pre_auth_selected_unique_id == "00000000-0000-0000-0000-000000000000") {
                            preAuthAllowedUnits > 0 ? visit.pri_pre_auth_selected_unique_id = auth.unique_id.trim() : '';
                            preAuthAllowedUnits = auth.is_units ? preAuthAllowedUnits - visit.units : preAuthAllowedUnits - 1;
                        }
                    })
                });
            }
        }
        return charge_visit_data;
    }

    set_eligibility_defualt_values = (subscriber) => {
        if (subscriber && subscriber.subscriber_eligibility && subscriber.subscriber_eligibility.length > 0) {
            subscriber.subscriber_eligibility.forEach((eligibility) => {
                if (eligibility) {
                    if (eligibility.amount_to_collect == "" || eligibility.amount_to_collect == null) {
                        eligibility.amount_to_collect = 0;
                    }
                    if (eligibility.co_insurance_percent == "" || eligibility.co_insurance_percent == null) {
                        eligibility.co_insurance_percent = 0;
                    }
                    if (eligibility.in_network_copay == "" || eligibility.in_network_copay == null) {
                        eligibility.in_network_copay = 0;
                    }
                    if (eligibility.in_network_deduct == "" || eligibility.in_network_deduct == null) {
                        eligibility.in_network_deduct = 0;
                    }
                    if (eligibility.in_or_out_of_network == "" || eligibility.in_or_out_of_network == null) {
                        eligibility.in_or_out_of_network = false;
                    }
                    if (eligibility.out_network_copay == "" || eligibility.out_network_copay == null) {
                        eligibility.out_network_copay = 0;
                    }
                    if (eligibility.out_network_deduct == "" || eligibility.out_network_deduct == null) {
                        eligibility.out_network_deduct = 0;
                    }
                    if (eligibility.per_visit_limitation == "" || eligibility.per_visit_limitation == null) {
                        eligibility.per_visit_limitation = 0;
                    }
                    if (eligibility.separate_copay_amount == "" || eligibility.separate_copay_amount == null) {
                        eligibility.separate_copay_amount = 0;
                    }
                    if (eligibility.ytd_deductible_met == "" || eligibility.ytd_deductible_met == null) {
                        eligibility.ytd_deductible_met = 0;
                    }
                }
            });
        }


        return subscriber;
    }

    add_new_pre_auth_data = (update_case_data,rebill=false) => {
        if (update_case_data && update_case_data.case && update_case_data.case.subscriber && update_case_data.case.subscriber.length > 0) {
            update_case_data.case.subscriber.forEach((subscriber) => {
                // Eligibility
                // set null or empty values to default values
                subscriber = this.set_eligibility_defualt_values(subscriber);
                if (subscriber && subscriber.subscriber_eligibility && subscriber.subscriber_eligibility.length > 0) {
                    subscriber.subscriber_eligibility.forEach((eligibility) => {
                        if (eligibility && !is_null_or_white_space(eligibility.effective_date)) {
                            eligibility.effective_date = date_format_with_no_time(eligibility.effective_date);
                        }

                        if (eligibility && !is_null_or_white_space(eligibility.termination_date)) {
                            eligibility.termination_date = date_format_with_no_time(eligibility.termination_date);
                        }
                    });
                }

                // Pre Authorization
                if (subscriber && subscriber.subscriber_pre_auth && subscriber.subscriber_pre_auth.length > 0) {
                    subscriber.subscriber_pre_auth.forEach((preAuth) => {
                        if (preAuth) {
                            if (preAuth.pre_auth_visits == "") {
                                preAuth.pre_auth_visits = null
                            }
                            else {
                                preAuth.pre_auth_visits = parseInt(preAuth.pre_auth_visits);
                            }
                            
                        }
                    });
                }
            });
        }
        
        add_new_pre_auth_data(this.props.user_login_details.user_data.data.token_details.access_token, this.state.patient_id, update_case_data).
            then((response) => {
               // this.get_rad_Window_type(update_case_data);
                for (let value of response.data.data) {

                    for (let valueItem of value.pri_pre_auth) {
                        valueItem.from_date = date_format_with_no_time(valueItem.from_date);
                        valueItem.thru_date = date_format_with_no_time(valueItem.thru_date);
                    }
                    //angular.forEach(value.secPreAuth, function (valueItem, keyItem) {
                    for (let valueItem of value.sec_pre_auth) {
                        valueItem.from_date = date_format_with_no_time(valueItem.from_date);
                        valueItem.thru_date = date_format_with_no_time(valueItem.thru_date);
                    }
                    for (let valueItem of value.ter_pre_auth) {
                        valueItem.from_date = date_format_with_no_time(valueItem.from_date);
                        valueItem.thru_date = date_format_with_no_time(valueItem.thru_date);
                    }
                }
                if (response.data.data.length > 0) {
                    response.data.data.map((value, key) => {
                        if (response.data.data[key].pri_pre_auth != undefined && response.data.data[key].pri_pre_auth.length > 0) {
                            response.data.data[key].pri_pre_auth.unshift(this.pre_auth_not_selected);
                        }
                        if (response.data.data[key].sec_pre_auth != undefined && response.data.data[key].sec_pre_auth.length > 0) {
                            response.data.data[key].sec_pre_auth.unshift(this.pre_auth_not_selected);
                        }
                        if (response.data.data[key].ter_pre_auth != undefined && response.data.data[key].ter_pre_auth.length > 0) {
                            response.data.data[key].ter_pre_auth.unshift(this.pre_auth_not_selected);
                        }
                    });
                }
                this._grouped_new_pre_auth_data = [...response.data.data];
                //
                let rad_window_type = this.get_rad_window_type(response.data.data[0]);
               
                //var new_data = rad_window_type._has_visit_pre_auth ? this.updated_data([...response.data.data]) : [...response.data.data];
                //
                var new_data = this.updated_data([...response.data.data]);
                update_case_data.add_new_pre_auth = new_data;
                update_case_data.case.add_new_pre_auth = new_data;
                let old_case_data = this.state.case;
                old_case_data.add_new_pre_auth = new_data;
                //updateCase.addNewPreAuth = data.data;

                //angular.forEach(data.data, function (value, key) {
               

                var new_pre_auth_response_data = new_data;

               
                if (update_case_data.case.add_new_pre_auth != null && update_case_data.case.add_new_pre_auth.length > 0 && this.user_type_id == 3) {
                   
                    this.show_new_pre_auth_red_windo_modal = true;
                    if (this._is_mounted) {
                        this.setState({
                            loading: false,
                            case: old_case_data,
                            new_pre_auth_data: new_pre_auth_response_data,
                            rad_window_type: rad_window_type,
                            rad_win_btn_text: rad_window_type._has_visit_pre_auth && rad_window_type._has_unit_pre_auth ? "Next":"Save"
                        });
                    }
                } else {
                    if (this._is_mounted) {
                        this.setState({
                            case: old_case_data,
                            loading: true,
                            new_pre_auth_data: new_pre_auth_response_data,
                            rad_window_type: rad_window_type,
                            rad_win_btn_text: rad_window_type._has_visit_pre_auth && rad_window_type._has_unit_pre_auth ? "Next" : "Save"
                        });
                    }
                    if (update_case_data.case.add_new_pre_auth == null || update_case_data.case.add_new_pre_auth.length == 0) {
                        update_case_data.is_rebill_force_save = rebill;
                    }
                    this.state.case_before_edition.is_diagnosis_update = update_case_data.is_diagnosis_update;
                    let hasNewItems = this.hasNewItemsCase(update_case_data);
                    if(!hasNewItems && (_.isEqual(this.state.case_before_edition, update_case_data) || _.isEqualWith(this.state.case_before_edition, update_case_data, this.isEqualDefaultValue))) {
                        this.on_case_after_save(this.state.case.id, false, false, true);
                    } else {
                        update_case_data.is_partial_update = hasNewItems? false : this.isPartialUpdate(update_case_data);
                        this.update_case(update_case_data, rebill);
                    }
                }
            },
                (error) => {
                    if (this._is_mounted) {
                        this.setState({
                            loading: false,
                            is_save_button_disabled: false
                        });
                    }
                    if (error.response.data) {
                        const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                        toastr.error('', toastr_options);
                    }
                })

    }


    // funtion/method to handle the update case functionality
    update_case = (update_case_data, rebill = false) => {
        var _this = this;
        update_case(_this.props.user_login_details.user_data.data.token_details.access_token, _this.state.patient_id, trim_nested_json_obj_values(update_case_data)).then(async response => {
            if(response.data.status ==  0 && response.data.messages.length == 0){
                toastr.error('', case_messages.updated_error);
            }
            if (response.data.status == 1) {
                if (response.data.messages != null && response.data.messages[0] != undefined && response.data.messages[0].code == 50001) {
                    toastr.warning('', response.data.messages[0].message);
                }
                if (response.data.messages != null && response.data.messages[0] != undefined && response.data.messages[0].code == "50003") {
                    if (update_case_data.case.add_new_pre_auth != null && update_case_data.case.add_new_pre_auth.length > 0 && this.user_type_id != 3) // rebill ka status
                    {
                        this.is_rebill_force_save = this.rebill;
                        this.show_new_pre_auth_red_windo_modal = true;
                        const charge_visit_data = this.set_default_auth_before_pre_auth_model_open();
                        if (_this._is_mounted) {
                            _this.setState({
                                loading: false,
                                new_pre_auth_data: charge_visit_data
                            })
                        }
                        return;
                    }
                }
            }

            if (response.data.data == 0) {
                session_storage.set('active_case', this.state.case.id.toString());
                this.on_case_after_save(this.state.case.id, false);

            } else if (response.data.data != 0) {
                session_storage.set('active_case', response.data.data.toString());
                this.on_case_after_save(response.data.data, false,true);
                this.props.history.push(`/case/${response.data.data}`);
            }
            if (response.data.messages && response.data.messages.length > 0) {
                var case_data = _this.state.case;
                case_data.subscriber = [];
                if (_this._is_mounted) {
                    _this.setState({ case: case_data, loading: false, page_state: enum_case_page_state.edit });
                }
                if (response.data.messages[0].code != 50001) {
                    toastr.warning('', response.data.messages[0].message);

                }
            }
            this.toggle_child_accordian();

        }, (error) => {
            //re-bil on after charges from effective date
            if (error && error.response && error.response.data && error.response.data.messages.length > 0) {
                _this.handle_update_case_error_response(error.response.data.messages, update_case_data);

            } else {
                if (_this._is_mounted) {
                    _this.setState({
                        loading: false,
                        is_save_button_disabled: false
                    })
                }
            }
        });

    }

    on_case_edit_click = () => {
        this.toggle_child_accordian();
        if (this._is_mounted) {
            this.setState({
                page_state: enum_case_page_state.add,
                is_save_button_disabled: false

            });
        }

        setTimeout(function () {
            set_focus_on_element_with_id("case_name_txt");
        }, 0);
        this.setCaseBeforeEdition();
    }
    enable_save_button = () => {
        if (this._is_mounted) {
            this.setState({ is_save_button_disabled: false });
        }
    }
    validate_form_field_insurance = () => {
        var insurance_field_valid = {
            "primary": { in_valid_form_data: false, insurance_data: {}, is_subscrible_available: false, is_insurance_available: false },
            "secondary": { in_valid_form_data: false, insurance_data: {}, is_subscrible_available: false, is_insurance_available: false },
            "tertiary": { in_valid_form_data: false, insurance_data: {}, is_subscrible_available: false, is_insurance_available: false }
        };
        if (this.insurance_child_primary) {
            if (this.insurance_child_primary.validate_form_field_data) {
                insurance_field_valid.primary = this.insurance_child_primary.validate_form_field_data();
                //  this.insurance_child_primary.state.subscriber_invalid_form = insurance_field_valid.primary.insurance_data;
            }
        }
        if (this.insurance_child_secondary) {
            if (this.insurance_child_secondary.validate_form_field_data) {
                insurance_field_valid.secondary = this.insurance_child_secondary.validate_form_field_data();
            }
        }
        if (this.insurance_child_tertiary) {
            if (this.insurance_child_tertiary.validate_form_field_data) {
                insurance_field_valid.tertiary = this.insurance_child_tertiary.validate_form_field_data();
            }
        }
        return insurance_field_valid;
    }

    on_case_save_edit_case = (redirect) => {
        this.setState({ redirectPage: redirect,statusOptionList: false });
        this.rebill = false;
        this.on_case_save_click(true, true, true, this.rebill)
    }

    get_menu_access = (menu_id: number) => {

        const filterByMenuId = this.props.shared_details.left_menu_data.data.filter(menu => menu.id === menu_id);

        const menu_mapping: Array<any> = this.props.shared_details.left_menu_data.data
            .filter((menu, index) => {
                return menu.r6_parent_id === this.props.case_editing_url_redirect.defaultR6ParentId &&
                    menu.name === this.props.case_editing_url_redirect.menuName &&
                    menu.id === this.props.case_editing_url_redirect.menuId;
            });

        return (menu_mapping.length === 0 || filterByMenuId.length === 0 || filterByMenuId[0].view === false) ? false : true;
    }
    // funtion/method while click on save button 
    on_case_save_click = (is_insurance_save = true, validate_doctors_order = true, validate_preauthorization = true,rebill=false) => {
        var caseData = this.state.case;
        var validate_field = this.state.case_form_invalid;
        // var page_state = this.state.case.id != 0 ? enum_case_page_state.edit : enum_case_page_state.add;
        var is_form_invalid = this.validate_form_field_data(caseData, validate_field);
        //ar is_show_toste

        var is_form_invalid_insurance = this.validate_form_field_insurance();
        var stop_submit = false;

        if (is_form_invalid.in_valid_form_data || is_form_invalid_insurance.primary.in_valid_form_data || is_form_invalid_insurance.secondary.in_valid_form_data || is_form_invalid_insurance.tertiary.in_valid_form_data) {
            toastr.error('', case_messages.required_fields);
            stop_submit = true;
        }
        if (!stop_submit) {
            //convert to string fomat from moment object for saving
            caseData.assignment_date = date_format_with_no_time(caseData.formatted_assignment_date);
            caseData.effective_date = date_format_with_no_time(caseData.formatted_effective_date);
            caseData.discharge_date = date_format_with_no_time(caseData.formatted_discharge_date);
            caseData.injury_date = date_format_with_no_time(caseData.formatted_injury_date);
            caseData.next_visit_date = date_format_with_no_time(caseData.formatted_next_visit_date);
            caseData.start_of_care_date = date_format_with_no_time(caseData.formatted_start_of_care_date);
            caseData.referral_date = date_format_with_no_time(caseData.formatted_referral_date);

            if (is_insurance_save) {
                //Insurance Validation
                if (!this.validate_insurance_data()) {
                    stop_submit = true;
                }
                else {
                    if (this.state.case.id > 0) {
                        var flag = this.validate_updated_details()
                        if (!flag) {
                            stop_submit = true;
                        }

                    }
                }
            }
            if (!stop_submit) {
                if (is_insurance_save) {
                    const is_primary_insurance_form_valid = (!is_form_invalid_insurance.primary.is_insurance_available && is_form_invalid_insurance.primary.is_subscrible_available)
                    const is_secondary_insurance_form_valid = (!is_form_invalid_insurance.secondary.is_insurance_available && is_form_invalid_insurance.secondary.is_subscrible_available)
                    const is_tertairy_insurance_form_valid = (!is_form_invalid_insurance.tertiary.is_insurance_available && is_form_invalid_insurance.tertiary.is_subscrible_available)
                    if (
                        ((!is_form_invalid_insurance.primary.is_insurance_available && this.is_change_in_primary_insurance()) || is_primary_insurance_form_valid)
                        || ((!is_form_invalid_insurance.secondary.is_insurance_available && this.is_change_in_secondary_insurance()) || is_secondary_insurance_form_valid)
                        || ((!is_form_invalid_insurance.tertiary.is_insurance_available && this.is_change_in_tertiary_insurance()) || is_tertairy_insurance_form_valid)
                    ) {
                        if (this._is_mounted) {
                            this.setState({
                                insurance_code_confirmation_box: true
                            });
                        }
                        return false
                    }
                }
                if (validate_preauthorization && validate_doctors_order && this.insurance_child_primary.insurance_preauthorization.validate_preauth_data() && this.insurance_child_primary.insurance_doctors_orders.check_invalid_data()) {
                    if (this._is_mounted) {
                        this.setState({
                            preauthorization_doctors_order_confirmation_box: true
                        });
                    }
                    return false;
                }
                if (validate_preauthorization && (this.insurance_child_primary.insurance_preauthorization.validate_preauth_data() || this.insurance_child_secondary.insurance_preauthorization.validate_preauth_data() || this.insurance_child_tertiary.insurance_preauthorization.validate_preauth_data())) {
                    //if (validate_preauthorization && (this.insurance_child_primary.insurance_preauthorization.validate_preauth_data() || this.insurance_child_secondary.insurance_preauthorization.validate_preauth_data())) {
                    if (this._is_mounted) {
                        this.setState({
                            preauthorization_confirmation_box: true
                        });
                    }
                    return false;
                }
                if (validate_doctors_order && this.insurance_child_primary.insurance_doctors_orders.check_invalid_data()) {
                    if (this._is_mounted) {
                        this.setState({
                            doctors_order_confirmation_box: true
                        });
                    }
                    return false;
                }
                if (this._is_mounted) {
                    this.setState({
                        preauthorization_doctors_order_confirmation_box: false,
                        preauthorization_confirmation_box: false,
                        doctors_order_confirmation_box: false,
                        insurance_code_confirmation_box: false
                    });
                }
                // check for dx if removed from complete case 
                if (this.state.case.id > 0) {
                    var invalid_dx = this.validate_dx_code();
                    if (invalid_dx) {
                        toastr.error('', case_messages.req_for_billing_dx);
                        stop_submit = true;
                    }
                    // Add subscriber data in case of primary insurance 
                    var subscriber_list = [];
                    //Clear the subscriber array when save click and before creating new array
                    // It will clear only in case of primary insurance fetching not for second and tertiary ins
                    caseData.subscriber = [];
                    if (is_form_invalid_insurance.primary.is_insurance_available) {
                        if (this.insurance_child_primary) {
                            if (this.insurance_child_primary.state) {
                                //comment below line as there is not significance to write it here
                                //this.insurance_child_primary.state.subscriber
                                //subscriber_list = this.insurance_child_primary.state.subscriber;
                                subscriber_list = this.insurance_child_primary.get_on_update_subscriber_data();

                                //subscriber_list.address.zip = unformat_zip_code(subscriber_list.address.zip);

                                caseData.subscriber.push(subscriber_list);
                                caseData.subscriber[0].date_of_birth = caseData.subscriber[0].formatted_date_of_birth == null ? null : caseData.subscriber[0].formatted_date_of_birth;
                                if (caseData.subscriber[0].is_us_selected) {
                                    caseData.subscriber[0].address.province = '';
                                    caseData.subscriber[0].address.postal_code = '';
                                } else {
                                    custom_date_format
                                    caseData.subscriber[0].address.zip = '';

                                }
                            }
                        }
                        if (this.insurance_child_secondary) {
                            if (this.insurance_child_secondary.state) {
                                //comment below line as there is not significance to write it here
                                // this.insurance_child_secondary.state.subscriber
                                //subscriber_list = this.insurance_child_primary.state.subscriber;
                                subscriber_list = this.insurance_child_secondary.get_on_update_subscriber_data();
                                //To remove the space on the basis of country selection

                                //subscriber_list.is_insurance_update = true;
                                caseData.subscriber.push(subscriber_list);
                                for (var index = 0; index < caseData.subscriber.length; index++) {
                                    if (caseData.subscriber[index].pst_coverage == 'S' || caseData.subscriber[index].psT_coverage == 'S') {
                                        caseData.subscriber[index].date_of_birth = caseData.subscriber[index].formatted_date_of_birth == null ? null : caseData.subscriber[index].formatted_date_of_birth;
                                        if (caseData.subscriber[index].is_us_selected) {
                                            caseData.subscriber[index].address.province = '';
                                            caseData.subscriber[index].address.postal_code = '';
                                        }
                                        else {
                                            caseData.subscriber[index].address.zip = '';
                                        }
                                    }
                                }
                            }
                        }
                        if (this.insurance_child_tertiary) {
                            if (this.insurance_child_tertiary.state) {
                                //comment below line as there is not significance to write it here
                                //this.insurance_child_tertiary.state.subscriber
                                //subscriber_list = this.insurance_child_primary.state.subscriber;
                                subscriber_list = this.insurance_child_tertiary.get_on_update_subscriber_data();
                                //To remove the space on the basis of country selection

                                //subscriber_list.is_insurance_update = true;
                                caseData.subscriber.push(subscriber_list);
                                for (var index = 0; index < caseData.subscriber.length; index++) {
                                    if (caseData.subscriber[index].pst_coverage == 'T' || caseData.subscriber[index].psT_coverage == 'T') {
                                        caseData.subscriber[index].date_of_birth = caseData.subscriber[index].formatted_date_of_birth == null ? null : caseData.subscriber[index].formatted_date_of_birth;
                                        if (caseData.subscriber[index].is_us_selected) {
                                            caseData.subscriber[index].address.province = '';
                                            caseData.subscriber[index].address.postal_code = '';
                                        }
                                        else {

                                            caseData.subscriber[index].address.zip = '';

                                        }
                                    }
                                }
                            }
                        }
                    }

                    if (this.conditional_billing_child) {
                        if (this.conditional_billing_child.state) {
                            let conditional_billing_data = this.conditional_billing_child.state.conditional_billing_form_data;
                            caseData.conditional_billing_code1 = conditional_billing_data.conditional_billing_code1.trim();
                            caseData.conditional_billing_code2 = conditional_billing_data.conditional_billing_code2.trim();
                            caseData.conditional_billing_code3 = conditional_billing_data.conditional_billing_code3.trim();
                            caseData.conditional_billing_note = conditional_billing_data.conditional_billing_note.trim();
                        }
                    }
                }
            }

        }

        if (!is_null_or_white_space(caseData.md_code)) {
            caseData.md_code = caseData.md_code.toString();
        }
        if (!is_null_or_white_space(caseData.pos_code)) {
            caseData.pos_code = caseData.pos_code.toString();
        }
        if (!is_null_or_white_space(caseData.billing_provider_code)) {
            caseData.billing_provider_code = caseData.billing_provider_code.toString();
        }

        if (!stop_submit) {
            if (this._is_mounted) {
                this.setState({
                    case: caseData,
                    form_submited: false,
                    case_form_invalid: is_form_invalid.case_data,
                    loading: true,
                    show_primary_insurance: true,
                    show_secondary_insurance: true,
                    show_tertiary_insurance: true,
                    is_save_button_disabled: true,

                });
            }
            // Add/Update data 
            if (this.state.case.id > 0) {
                var updateCase = this.format_update_case_data();
                this.add_new_pre_auth_data(updateCase, rebill);
                //this.update_case(updateCase);
            }
            else {
                this.update_conditional_billing_data_to_add();
                this.add_case(is_insurance_save);
            }
            this.toggle_child_accordian();

        }
        else {
            if (this._is_mounted) {
                this.setState({
                    case: caseData,
                    form_submited: true,
                    case_form_invalid: is_form_invalid.case_data,
                    //loading: true
                });
            }
        }

    }

    on_case_save = () => {
        this.rebill = false;
        this.on_case_save_click(true, true, true, this.rebill)
    }
    on_case_save_rebill_click = () => {
        this.rebill = true;
        this.on_case_save_click(true, true, true, this.rebill)
    }

    update_conditional_billing_data_to_add = () => {
        var caseData = this.state.case;
        if (this.conditional_billing_child) {
            if (this.conditional_billing_child.state) {
                let conditional_billing_data = this.conditional_billing_child.state.conditional_billing_form_data;
                caseData.conditional_billing_code1 = conditional_billing_data.conditional_billing_code1;
                caseData.conditional_billing_code2 = conditional_billing_data.conditional_billing_code2;
                caseData.conditional_billing_code3 = conditional_billing_data.conditional_billing_code3;
                caseData.conditional_billing_note = conditional_billing_data.conditional_billing_note;
            }
        }
        if (this._is_mounted) {
            this.setState({ case: caseData });
        }
    }
    toggle_child_accordian = () => {
        if (this.insurance_child_secondary) {
            if (this.insurance_child_secondary.toggle_by_parent_component) {
                this.insurance_child_secondary.toggle_by_parent_component(true);
            }
        }
        if (this.insurance_child_primary) {
            if (this.insurance_child_primary.toggle_by_parent_component) {
                this.insurance_child_primary.toggle_by_parent_component(true);
            }
        }
        if (this.insurance_child_tertiary) {
            if (this.insurance_child_tertiary.toggle_by_parent_component) {
                this.insurance_child_tertiary.toggle_by_parent_component(true);
            }
        }
        if (this.conditional_billing_child) {
            if (this.conditional_billing_child.toggle_by_parent_component) {
                this.conditional_billing_child.toggle_by_parent_component(true);
            }
        }
    }
    //To validate the case details while updating it
    validate_updated_details = () => {
        var error_msg = "";
        var error_count = 0;
        var case_invalid = this.state.case_form_invalid.case;
        if (this.state.modified_data.case.effective_date && (this.state.case.effective_date == undefined || this.state.case.effective_date == null)) {
            case_invalid.formatted_effective_date = true;
            error_count = 1;
        }
        else {
            case_invalid.formatted_effective_date = false;
        }
        if (this.state.modified_data.case.assignment_date && (this.state.case.assignment_date == undefined || this.state.case.assignment_date == null)) {
            case_invalid.formatted_assignment_date = true;
            error_count = 1;
        }
        else {
            case_invalid.formatted_assignment_date = false;
        }
        if (this.state.modified_data.case.description && (this.state.case.description === '' || this.state.case.description === undefined || this.state.case.description === null)) {
            case_invalid.description = true;
            error_count = 1;
        }
        else {
            case_invalid.description = false;
        }
        if (this.state.case_form_invalid.case.referring_physician) {
            //case_invalid.referring_physician = true;
            error_count = 1;
        }
        if (this.state.case_form_invalid.case.place_of_service) {
            //case_invalid.referring_physician = true;
            error_count = 1;
        }
        if (this.state.case_form_invalid.case.provider) {
            //case_invalid.referring_physician = true;
            error_count = 1;
        }
        if (this.state.modified_data.case.descipline && (parseInt(this.state.case.discipline.toString()) === 0 || this.state.case.discipline === undefined || this.state.case.discipline === null)) {
            case_invalid.descipline = true;
            error_count = 1;
        }
        else {
            case_invalid.descipline = false;
        }
        if (this.insurance_child_primary) {
            if (this.insurance_child_primary.validate_updated_details) {
                var ins_error_count = this.insurance_child_primary.validate_updated_details();
                error_count = error_count + parseInt(ins_error_count);
            }
        }
        if (this.insurance_child_secondary) {
            if (this.insurance_child_secondary.validate_updated_details) {
                var ins_error_count = this.insurance_child_secondary.validate_updated_details();
                error_count = error_count + parseInt(ins_error_count);
            }
        }
        if (this.insurance_child_tertiary) {
            if (this.insurance_child_tertiary.validate_updated_details) {
                var ins_error_count = this.insurance_child_tertiary.validate_updated_details();
                error_count = error_count + parseInt(ins_error_count);
            }
        }

        if (error_count >= 1) {
            toastr.error('', case_messages.req_for_billing);

            return false
        }


        return true;
    }

    validate_dx_code = () => {
        if (this.diagnosis_child.validate_dx_code) {
            return this.diagnosis_child.validate_dx_code(true);
        }
        return false;
    }


    // function/mehtod to update the states of the controls whenever they change
    on_case_info_change = (e, dropdown_obj = null) => {
        const field_name = e.target.name != undefined ? e.target.name.replace("txt_", "") : e.target.name;
        const field_value = e.target.value != undefined ? e.target.value.trimStart() : e.target.value;
        var state_case = this.state.case;
        var case_modified_data = this.state.modified_data;
        var state_invalid_case = this.state.case_form_invalid;
        if (dropdown_obj == null) {
            state_case[field_name] = field_value;
        }
        else {
            //
            //To check whether the state for case name is modified of not
            if (dropdown_obj.name == 'discipline') {
                state_invalid_case.case.descipline = false;
                if ((state_case.old_descipline ? state_case.old_descipline : "0") !== dropdown_obj.value) {
                    if (dropdown_obj.value && dropdown_obj.value.toString() == "0") {
                        state_invalid_case.case.descipline = true;
                    }
                    else {
                        state_invalid_case.case.descipline = false;
                    }
                    case_modified_data.case.descipline = true;
                }
                else {
                    case_modified_data.case.descipline = false;
                }
            }
            //

            state_case[dropdown_obj.name] = dropdown_obj.value;
        }
        //To check whether the state for case name is modified of not
        if (field_name == 'description') {
            if (state_case['old_case_name'] !== field_value) {
                case_modified_data.case.description = true
            }
        }

        //
        var is_form_invalid = this.validate_form_field_data(state_case, state_invalid_case);
        if (this._is_mounted) {
            this.setState({
                case: state_case,
                case_form_invalid: is_form_invalid.case_data,
                modified_data: case_modified_data
            });
        }

    }

    // Convert JSON data to string for .NET Core
    convertJsonDataToString = (caseData) => {
        caseData.md_code = caseData.md_code == null ? null : caseData.md_code.toString();
        caseData.secondary_md_code = caseData.secondary_md_code == null ? null : caseData.secondary_md_code.toString();
        caseData.pos_code = caseData.pos_code == null ? null : caseData.pos_code.toString();
        caseData.billing_provider_code = caseData.billing_provider_code == null ? null : caseData.billing_provider_code.toString();
        caseData.description = caseData.description == null ? null : caseData.description.toString();
        caseData.injury_type = caseData.injury_type == null ? null : caseData.injury_type.toString();
        caseData.injury_type = isNaN(caseData.injury_type) ? "1" : caseData.injury_type.toString();
        caseData.old_case_name = caseData.old_case_name == null ? null : caseData.old_case_name.toString();
        caseData.old_referring_physician = caseData.old_referring_physician == null ? null : caseData.old_referring_physician.toString();
        caseData.old_location = caseData.old_location == null ? null : caseData.old_location.toString();
        caseData.old_provider = caseData.old_provider == null ? null : caseData.old_provider.toString();
        caseData.old_descipline = caseData.old_descipline == null ? null : caseData.old_descipline.toString();
        caseData.status = caseData.status == null ? null : caseData.status.toString();
        caseData.provider_id = caseData.provider_id == null ? null : caseData.provider_id.toString();
        caseData.company_id = caseData.company_id == null ? null : caseData.company_id.toString();
        caseData.billing_provider_code = caseData.billing_provider_code == null ? null : caseData.billing_provider_code.toString();
        caseData.treating_provider_code = caseData.treating_provider_code == null ? null : caseData.treating_provider_code.toString();
        caseData.alt_record_id = caseData.alt_record_id == null ? null : caseData.alt_record_id.toString();
        caseData.si_flag = caseData.si_flag == null ? null : caseData.si_flag.toString();
        caseData.note_code = caseData.note_code == null ? null : caseData.note_code.toString();
        caseData.internal_information = caseData.internal_information == null ? null : caseData.internal_information.toString();
        caseData.accident_location = caseData.accident_location == null ? null : caseData.accident_location.toString();
        caseData.conditional_billing_code1 = caseData.conditional_billing_code1 == null ? null : caseData.conditional_billing_code1.toString();
        caseData.conditional_billing_code2 = caseData.conditional_billing_code2 == null ? null : caseData.conditional_billing_code2.toString();
        caseData.conditional_billing_code3 = caseData.conditional_billing_code3 == null ? null : caseData.conditional_billing_code3.toString();
        caseData.conditional_billing_note = caseData.conditional_billing_note == null ? null : caseData.conditional_billing_note.toString();
        caseData.patient_contact_comment = caseData.patient_contact_comment == null ? null : caseData.patient_contact_comment.toString();       
    }



    // function / method to validate the data of form when the state changes
    validate_form_field_data = (caseData, validate_field) => {
        var in_valid_form_data = false;
        var caseData = caseData;
        var valid = validate_field.case;
        this.convertJsonDataToString(caseData);
        //case Name
        if (caseData.note_code && caseData.note_code !== "0") {
            if (caseData.patient_contact_comment) {
                valid.patient_contact_comment = false;
                valid.patient_contact = false;
            }
            else {
                valid.patient_contact_comment = true;
                valid.patient_contact = false;
                in_valid_form_data = true;
            }
        }
        else {
            if (caseData.patient_contact_comment) {
                valid.patient_contact = true;
                valid.patient_contact_comment = false;
                in_valid_form_data = true;
            }
            else {
                valid.patient_contact = false;
                valid.patient_contact_comment = false;

            }
        }
        var validate_form = {
            in_valid_form_data: in_valid_form_data,
            case_data: { case: valid }
        };

        return validate_form;
    }
    // function / Method to handle the state change of the date controls used
    handle_calendar_change = (dt, date_type) => {
        let m = date_format_with_no_time(dt);
        var case_info = this.state.case;
        var case_invalid = this.state.case_form_invalid;
        var case_modified_data = this.state.modified_data;

        if (date_type == enum_date_type.assignment) {
            if (m != null && m.isValid()) { case_invalid.case.assignment_date = false; }
            if ((case_info.old_assignment_date ? case_info.old_assignment_date : null) !== m) {
                case_modified_data.case.assignment_date = true;
                if (m == null) {
                    case_invalid.case.formatted_assignment_date = true;
                }
                else {
                    case_invalid.case.formatted_assignment_date = false;
                }
            }
            else {
                case_modified_data.case.assignment_date = false;
                case_invalid.case.formatted_assignment_date = false;
            }
            case_info.formatted_assignment_date = m;
        }
        else if (date_type == enum_date_type.discharge) {
            if (m != null && m.isValid()) { case_invalid.case.discharge_date = false; }
            case_info.formatted_discharge_date = m;
        }
        else if (date_type == enum_date_type.effective) {
            if (m != null && m.isValid()) { case_invalid.case.effective_date = false; }
            if ((case_info.old_effective_date ? case_info.old_effective_date : null) !== m) {
                case_modified_data.case.effective_date = true;
                if (m == null) {
                    case_invalid.case.formatted_effective_date = true;
                }
                else {
                    case_invalid.case.formatted_effective_date = false;
                }
            }
            else {
                case_modified_data.case.effective_date = false;
                case_invalid.case.formatted_effective_date = false;
            }

            case_info.formatted_effective_date = m;
        }
        else if (date_type == enum_date_type.next_visit) {
            if (m != null && m.isValid()) { case_invalid.case.next_visit_date = false; }
            case_info.formatted_next_visit_date = m;
        }
        else if (date_type == enum_date_type.injury) {
            if (m != null && m.isValid()) { case_invalid.case.injury_date = false; }
            case_info.formatted_injury_date = m;
        }
        else if (date_type == enum_date_type.start_of_care) {
            if (m != null && m.isValid()) { case_invalid.case.start_of_care_date = false; }
            case_info.formatted_start_of_care_date = m;
        }
        else if (date_type == enum_date_type.referral) {
            if (m != null && m.isValid()) { case_invalid.case.formatted_referral_date = false; }
            case_info.formatted_referral_date = m;
        }
        else {
        }
        if (this._is_mounted) {
            this.setState({
                case: case_info,
                case_form_invalid: case_invalid,
                modified_data: case_modified_data
            });
        }
    }
    on_secondary_physician_grid_row_selection = (selected_row) => {
        this.on_row_selection_advance_search(enum_type_of_search.second_referring_physician, selected_row);
    }
    on_primary_physician_grid_row_selection = (selected_row) => {
        this.on_row_selection_advance_search(enum_type_of_search.referring_physician, selected_row);
    }
    on_location_grid_row_selection = (selected_row) => {
        this.on_row_selection_advance_search(enum_type_of_search.place_of_service, selected_row);
    }
    on_provider_grid_row_selection = (selected_row) => {
        this.on_row_selection_advance_search(enum_type_of_search.provider, selected_row);
    }
    on_special_program_row_selection = (selected_row) => {
        this.on_row_selection_advance_search(enum_type_of_search.special_program, selected_row);
    }
    on_referral_source_row_selection = (selected_row) => {
        this.on_row_selection_advance_search(enum_type_of_search.referral_source, selected_row);
    }
    on_row_selection_advance_search = (type_of_search, selected_row) => {
        var m_code = '';
        var f_name = '';
        var l_name = '';
        var address = "";
        var id = '';
        var name = '';
        var code = '';
        var selected_data = '';
        var case_state = this.state.case;
        var case_invalid = this.state.case_form_invalid;

        if (type_of_search === enum_type_of_search.referring_physician || type_of_search === enum_type_of_search.second_referring_physician) {
            if (selected_row) {
                id = selected_row.id;
                if ((selected_row.md_code != null && selected_row.md_code != undefined) || (selected_row.first_name != null && selected_row.first_name != undefined)
                    || (selected_row.last_name != null && selected_row.last_name != undefined)) {
                    code = (selected_row.md_code == null && selected_row.md_code == undefined) ? '' : selected_row.md_code;
                    f_name = (selected_row.first_name == null && selected_row.first_name == undefined) ? '' : selected_row.first_name;
                    l_name = (selected_row.last_name == null && selected_row.last_name == undefined) ? '' : selected_row.last_name;
                    address = (selected_row.address1 == null && selected_row.address1 == undefined) ? '' : selected_row.address1;
                }
            }
            selected_data = code + " - " + (f_name == null ? '' : f_name) + " " + (l_name == null ? '' : l_name) + (address == null ? '' : ", " + address);
            var format = { "label": '', "title": '', "md_code": '' };
            if (parseInt(id) > 0) {
                // Set Auto search control for referring physician
                format = {
                    "title": id.toString(),
                    "md_code": code,
                    "label": selected_data
                };
            }

            if (type_of_search === enum_type_of_search.referring_physician) {
                case_invalid.case.referring_physician = false;
                case_state.md_code = case_state.referring_physician.id = id;
                if (this._is_mounted) {
                    this.setState({ referring_physician_quick_search_format: format, case: case_state, case_form_invalid: case_invalid });
                }
            }
            else {
                case_state.secondary_md_code = case_state.secondary_referring_physician.id = id;
                if (this._is_mounted) {
                    this.setState({ second_referring_physician_quick_search_format: format, case: case_state });
                }
            }
        }

        else if (type_of_search === enum_type_of_search.place_of_service) {
            if (selected_row) {
                id = selected_row.id;
                if ((selected_row.code != null && selected_row.code != undefined) || (selected_row.name != null && selected_row.name != undefined)) {
                    code = (selected_row.code == null && selected_row.code == undefined) ? '' : selected_row.code;
                    name = (selected_row.name == null && selected_row.name == undefined) ? '' : selected_row.name;
                }
            }
            selected_data = code + " - " + name;
            var format_loc = { "label": '', "title": '', "pos_code": '' };
            if (parseInt(id) > 0) {
                // Set Auto search control for location
                format_loc = {
                    "title": id.toString(),
                    "pos_code": code,
                    "label": selected_data
                };
            }
            case_state.pos_code = case_state.place_of_service.id = id;
            case_invalid.case.place_of_service = false;
            if (this._is_mounted) {
                this.setState({
                    location_quick_search_format: format_loc,
                    case: case_state,
                    case_form_invalid: case_invalid
                });
            }
        }
        else if (type_of_search === enum_type_of_search.provider) {
            if (selected_row) {
                id = selected_row.id;
                if ((selected_row.code != null && selected_row.code != undefined) || (selected_row.name != null && selected_row.name != undefined)) {
                    code = (selected_row.code == null && selected_row.code == undefined) ? '' : selected_row.code;
                    name = (selected_row.description == null && selected_row.description == undefined) ? '' : selected_row.description;
                }
            }
            selected_data = code + " - " + name;           
            if (id) {
                get_descipline(this.props.user_login_details.user_data.data.token_details.access_token, id).then(response => {
                    if (response.data.data > 0) {
                        var format_bp = { "label": '', "title": '', "bp_code": '' };
                        if (parseInt(id) > 0) {
                            // Set Auto search control for provider
                            format_bp = {
                                "title": id.toString(),
                                "bp_code": code,
                                "label": selected_data
                            };
                        }
                        case_state.discipline = response.data.data;
                        case_state.billing_provider_code = case_state.provider.id = id;
                        case_invalid.case.provider = false;
                        if (response.data.data > 0) {
                            case_invalid.case.descipline = false;
                        }
                        if (this._is_mounted) {
                            this.setState({
                                provider_quick_search_format: format_bp,
                                case: case_state,
                                case_form_invalid: case_invalid
                            });
                        }
                    }

                });
            }
        }
        else if (type_of_search === enum_type_of_search.referral_source) {
            if (selected_row) {
                id = selected_row.id;
                if (selected_row.description != null && selected_row.description != undefined) {

                    name = (selected_row.description == null && selected_row.description == undefined) ? '' : selected_row.description;
                }
            }
            selected_data = name;
            var format_rs = { "label": '', "title": '', "rs_code": '' };
            if (parseInt(id) > 0) {
                // Set Auto search control for referral source
                format_rs = {
                    "title": id.toString(),
                    "rs_code": m_code,
                    "label": selected_data
                };
            }
            case_state.referring_source = selected_data;
            case_state.referring_source_id = parseInt(id);
            if (this._is_mounted) {
                this.setState({
                    referral_source_quick_search_format: format_rs,
                    case: case_state
                });
            }
        }
        else if (type_of_search === enum_type_of_search.special_program) {
            if (selected_row) {
                id = selected_row.special_program_id;
                if ((selected_row.special_program_code != null && selected_row.special_program_code != undefined) || (selected_row.special_program_name != null && selected_row.special_program_name != undefined)) {
                    code = (selected_row.special_program_code == null && selected_row.special_program_code == undefined) ? '' : selected_row.special_program_code;
                    name = (selected_row.special_program_name == null && selected_row.special_program_name == undefined) ? '' : selected_row.special_program_name;
                }
            }
            selected_data = code + " - " + name;
            var format_sb = { "label": '', "title": '', "sb_code": '' };
            if (parseInt(id) > 0) {
                // Set Auto search control for special program
                format_sb = {
                    "title": id.toString(),
                    "sb_code": code,
                    "label": selected_data
                };
            }
            case_state.special_program = parseInt(id);
            case_state.special_programs.id = id;
            if (this._is_mounted) {
                this.setState({
                    special_program_quick_search_format: format_sb,

                    case: case_state
                });
            }
        }

    }
    // function/method to handle the when searching of referring physician occurs
    on_referring_Physician_search = async (params) => {
        this.configure_grid_data(enum_type_of_search.referring_physician, params);
    }
    // function/method to handle the when searching of second referring physician occurs
    on_second_referring_Physician_search = async (params) => {
        this.configure_grid_data(enum_type_of_search.second_referring_physician, params);
    }
    // function/method to handle the when searching of location occurs
    on_location_search = async (params) => {
        this.configure_grid_data(enum_type_of_search.place_of_service, params);
    }
    // function/method to handle the when searching of provider occurs
    on_provider_search = async (params) => {
        this.configure_grid_data(enum_type_of_search.provider, params);
    }
    // function/method to handle the when searching of referral Source occurs
    on_referral_source_search = async (params) => {
        this.configure_grid_data(enum_type_of_search.referral_source, params);
    }
    // function/method to handle the when searching of special program occurs
    on_special_program_search = async (params) => {
        this.configure_grid_data(enum_type_of_search.special_program, params);
    }
    //filter of physician while selecting 
    referring_physician_filter = (data, md_code) => {
        var filtered = [];
        if (!(md_code == undefined || md_code == 0 || md_code == '')) {
            filtered = [];
            for (let row = 0; row < data.length; row++) {
                if (data[row].id != md_code) {
                    filtered.push(data[row]);
                }
            }
        }
        else {
            filtered = data;
        }
        return filtered;
    }
   

    // function/method to handle to configure data after the search happens in advance controls
    configure_grid_data = async (type_of_search, params) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        params = params ? params : {};
        // params.page_size = 100;
        var grid_data = [];
        if (type_of_search === enum_type_of_search.referring_physician || type_of_search === enum_type_of_search.second_referring_physician) {
            const search_data = await referring_physician_advance_search(params, token).then(res => res.data);
            const search_result = search_data.data !== null ? search_data.data.result : [];
            const row_data = this.referring_physician_filter(search_result, type_of_search === enum_type_of_search.referring_physician ? this.state.case.secondary_md_code : this.state.case.md_code);
            let column_def = shared_constants.search_referring_physician_configuration(type_of_search).column_defs;

            grid_data = { ...this.state.grid_conf, rows: row_data, column: column_def, messages: search_data.messages };
            type_of_search === enum_type_of_search.referring_physician && this._is_mounted ? this.setState({ primary_physician_search_data: grid_data }) : type_of_search === enum_type_of_search.second_referring_physician && this._is_mounted ? this.setState({ second_physician_search_data: grid_data }) : '';
        }
        else if (type_of_search === enum_type_of_search.place_of_service) {
            const search_data_location = await search_place(params, token).then(res => res.data);
            const search_location_result = search_data_location.data !== null ? search_data_location.data.result : [];
            grid_data = { ...this.state.grid_conf, rows: search_location_result, column: shared_constants.search_location_configuration(enum_type_of_search.place_of_service).column_defs, messages: search_data_location.messages };
            if (this._is_mounted) {
                this.setState({ location_search_data: grid_data });
            }
        }
        else if (type_of_search === enum_type_of_search.provider) {

            params.id = this.state.case.pos_code;
            params.apply_pos_code = false;
            const search_data_provider = await search_provider(params, token).then(res => res.data);
            const search_provider_result = search_data_provider.data !== null ? search_data_provider.data.result : [];
            grid_data = { ...this.state.grid_conf, rows: search_provider_result, column: shared_constants.search_provider_configuration(enum_type_of_search.provider).column_defs, messages: search_data_provider.messages };
            if (this._is_mounted) {
                this.setState({ provider_search_data: grid_data });
            }
        }
        else if (type_of_search === enum_type_of_search.special_program) {
            const search_data_special_billing = await special_program_advance_search(params, this.state.patient_id, token).then(res => res.data);
            const search_special_billing_result = search_data_special_billing.data !== null ? search_data_special_billing.data : [];
            grid_data = { ...this.state.grid_conf, rows: search_special_billing_result, column: shared_constants.search_special_program_configuration(enum_type_of_search.special_program).column_defs, messages: search_data_special_billing.messages };
            if (this._is_mounted) {
                this.setState({ special_program_search_data: grid_data });
            }
        }
        else if (type_of_search === enum_type_of_search.referral_source) {
            const search_data_referral_source = await referring_source_advance_search(params, token).then(res => res.data);
            const search_referral_source_data = search_data_referral_source.data !== null ? search_data_referral_source.data : [];
            grid_data = { ...this.state.grid_conf, rows: search_referral_source_data, column: shared_constants.search_referring_source_configuration(enum_type_of_search.referral_source).column_defs, messages: search_data_referral_source.messages };
            if (this._is_mounted) {
                this.setState({ referral_search_data: grid_data });
            }
        }

    }

    // get the quick search physician
    get_referring_physician_quick_search_data_list = async (search_keyword) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await referring_physician_quick_search(search_keyword, token);
    }
    // get the quick search location
    get_location_quick_search_data_list = async (search_keyword) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await quick_search_location(search_keyword, token);
    }
    // get the quick search provider
    get_provider_quick_search_data_list = async (search_keyword) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await quick_search_provider(search_keyword, token, 0);
    }
    // get the quick search referral source
    get_referral_source_quick_search_data_list = async (search_keyword) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await referring_source_quick_search(search_keyword, token);
    }
    // get the quick search special program
    get_special_program_quick_search_data_list = async (search_keyword) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await special_program_quick_search(search_keyword, this.state.patient_id, token);
    }
    // get the searched value which is typed for searching location or providers
    get_input_value = async (input, type) => {

    }

    //Auto search for Location
    render_suggestion_result = (props) => {
        return (
            <div key={props.title} tabIndex={0} className="item_auto_search">
                <div key={props.title} className="fs_13">
                    <span>{props.label}</span>
                </div>
            </div>

        )
    }
    // preapare the suggestion list with search result
    prepare_suggestion = (_data, type_of_search) => {
        let formattedList = [];
        //filter the data for referrring physician
        if (type_of_search === enum_type_of_search.referring_physician || type_of_search === enum_type_of_search.second_referring_physician) {
            _data = this.referring_physician_filter(_data.data, type_of_search === enum_type_of_search.referring_physician ? this.state.case.secondary_referring_physician.id : this.state.case.referring_physician.id);
        }
        var data_length = 0;
        if (_data.data) {
            data_length = _data.data.length;
            _data = _data.data;
        }
        else {
            data_length = _data.length;
        }
        if (_data && data_length) {
            for (var i = 0; i < data_length; i++) {
                var item = _data[i];
                if (type_of_search === enum_type_of_search.referring_physician || type_of_search === enum_type_of_search.second_referring_physician) {
                    formattedList.push({
                        "title": `${item.id}`,
                        "md_code": item.md_code,
                        "first_name": item.first_name,
                        "last_name": item.last_name,
                        "address": item.address,
                        "label": (item.md_code) + " - " + (item.first_name) + " " + (item.last_name) + (item.address == null ? '' : ", " + item.address),

                    })
                }
                else if (type_of_search === enum_type_of_search.place_of_service) {
                    formattedList.push({
                        "title": `${item.id}`,
                        "code": item.code,
                        "name": item.name,

                        "label": (item.code) + " - " + (item.name),

                    })
                }
                else if (type_of_search === enum_type_of_search.provider) {
                    formattedList.push({
                        "title": `${item.id}`,
                        "code": item.code,
                        "name": item.description,

                        "label": (item.code) + " - " + (item.description),

                    })
                }
                else if (type_of_search === enum_type_of_search.referral_source) {
                    formattedList.push({
                        "title": `${item.id}`,
                        "rs_code": item.type_id,
                        "name": item.description,
                        "label": item.description
                    })
                }
                else if (type_of_search === enum_type_of_search.special_program) {
                    formattedList.push({
                        "title": `${item.special_program_id}`,
                        "code": item.special_program_code,
                        "name": item.special_program_name,

                        "label": (item.special_program_code) + " - " + (item.special_program_name),

                    })
                }
            }

        }
        return formattedList;
    }

    // select the item on click suggested items
    on_item_selection = (item, type) => {
        var case_state = this.state.case;
        var case_modified = this.state.modified_data;
        var case_invalid = this.state.case_form_invalid;
        if (type === enum_type_of_search.referring_physician) {
            //
            case_invalid.case.referring_physician = false;
            if ((case_state.old_referring_physician ? case_state.old_referring_physician : null) !== item.title) {
                case_modified.case.referring_physician = true;
            }
            else {
                case_modified.case.referring_physician = false;
            }
            //
            case_state.md_code = case_state.referring_physician.id = item.title;
            if (this._is_mounted) {
                this.setState({
                    selected_primary_physician: item,
                    case: case_state,
                    case_form_invalid: case_invalid,
                    modified_data: case_modified
                });
            }
        }
        else if (type === enum_type_of_search.second_referring_physician) {
            case_state.secondary_md_code = case_state.secondary_referring_physician.id = item.title;
            if (this._is_mounted) {
                this.setState({ selected_secondary_physician: item, case: case_state });
            }
        }
        else if (type === enum_type_of_search.place_of_service) {
            //
            case_invalid.case.place_of_service = false;
            if ((case_state.old_location ? case_state.old_location : null) !== item.title) {
                case_modified.case.place_of_service = true;
            }
            else {
                case_modified.case.place_of_service = false;
            }
            //
            case_state.pos_code = case_state.place_of_service.id = item.title;
            if (this._is_mounted) {
                this.setState({ case: case_state, case_form_invalid: case_invalid  });
            }
        }
        else if (type === enum_type_of_search.provider) {
            //
            case_invalid.case.provider = false;
            if ((case_state.old_provider ? case_state.old_provider : null) !== item.title) {
                case_modified.case.provider = true;
            }
            else {
                case_modified.case.provider = false;
            }
            //
            case_state.billing_provider_code = case_state.provider.id = item.title;              
            if (item.title) {
                get_descipline(this.props.user_login_details.user_data.data.token_details.access_token, item.title).then(response => {
                    if (response.data.data > 0) {
                        case_state.discipline = response.data.data;
                        case_invalid.case.descipline = false;
                        case_invalid.case.provider = false;
                        if (this._is_mounted) {
                            this.setState({ case: case_state, case_form_invalid: case_invalid });
                        }
                    }
                });
            }

        }
        else if (type === enum_type_of_search.referral_source) {
            case_state.referring_source_id = item.title;
            case_state.referring_source = item.label;
            if (this._is_mounted) {
                this.setState({ case: case_state });
            }
        }
        else if (type === enum_type_of_search.special_program) {
            case_state.special_program = case_state.special_programs.id = item.title;
            if (this._is_mounted) {
                this.setState({ case: case_state });
            }
        }
    }

    on_change_special_billing = (e, data) => {
        var case_state = this.state.case;
        case_state.special_billing = data.checked;
        if (this._is_mounted) {
            this.setState({
                case: case_state
            });
        }
    }
    on_change_active_case = (e, data) => {
        var case_state = this.state.case;
        case_state.is_active = data.checked;
        if (this._is_mounted) {
            this.setState({
                case: case_state
            });
        }
    }
    clear_quick_search = (type_of_search) => {
        var case_data = this.state.case;
        var case_invalid = this.state.case_form_invalid;
        if (type_of_search == enum_type_of_search.referring_physician) {
            if (case_data.old_referring_physician) {
                case_invalid.case.referring_physician = true;
            }
            case_data.referring_physician.id = "-999";
            case_data.md_code = "";
            if (this._is_mounted) {
                this.setState({
                    referring_physician_quick_search_format: {
                        "title": '',
                        "label": '',
                        "md_code": ''
                    },
                    case: case_data,
                    case_form_invalid: case_invalid
                });
            }
        }
        else if (type_of_search == enum_type_of_search.second_referring_physician) {
            case_data.secondary_referring_physician.id = "-999";
            case_data.secondary_md_code = "";
            if (this._is_mounted) {
                this.setState({
                    second_referring_physician_quick_search_format: {
                        "title": '',
                        "label": '',
                        "md_code": ''
                    },
                    case: case_data
                });
            }
        }
        else if (type_of_search == enum_type_of_search.place_of_service) {
            if (case_data.old_location) {
                case_invalid.case.place_of_service = true;
            }
            case_data.place_of_service.id = "-999";
            case_data.pos_code = "";
            if (this._is_mounted) {
                this.setState({
                    location_quick_search_format: {
                        "title": '',
                        "label": '',
                        "pos_code": ''
                    },
                    case: case_data,
                    case_form_invalid: case_invalid
                });
            }
        }
        else if (type_of_search == enum_type_of_search.provider) {
            if (case_data.old_provider) {
                case_invalid.case.provider = true;
            }
            case_data.provider.id = "-999";
            case_data.billing_provider_code = "";
            if (this._is_mounted) {
                this.setState({
                    provider_quick_search_format: {
                        "title": '',
                        "label": '',
                        "bp_code": ''
                    },
                    case: case_data,
                    case_form_invalid: case_invalid
                });
            }
        }
        else if (type_of_search == enum_type_of_search.referral_source) {
            case_data.referring_source_id = 0;
            case_data.referring_source = "";
            if (this._is_mounted) {
                this.setState({
                    referral_source_quick_search_format: {
                        "title": '',
                        "label": '',
                        "rs_code": ''
                    },
                    case: case_data
                });
            }
        }
        else if (type_of_search == enum_type_of_search.special_program) {
            case_data.special_programs.id = "-999";
            case_data.special_program = 0;
            if (this._is_mounted) {
                this.setState({
                    special_program_quick_search_format: {
                        "title": '',
                        "label": '',
                        "sb_code": ''
                    },
                    case: case_data
                });
            }
        }
    }
    coming_soon = () => {
        toastr.info('', 'Coming soon');
    }
    on_blur_auto_search = (e, type_of_search) => {
        var case_data = this.state.case;
        var case_invalid = this.state.case_form_invalid;
        var case_modified = this.state.modified_data;
        var control_id = document.getElementById('auto' + global_constants.constants.advanced_control_type.location);
        if (type_of_search == enum_type_of_search.referring_physician) {
            control_id = document.getElementById('auto' + global_constants.constants.advanced_control_type.referring_physician);
            //if (case_data.old_referring_physician && case_data.old_referring_physician != "-999") {
            if (case_data.old_referring_physician && case_data.old_referring_physician != "-999" && control_id['value'] == "") {
                case_invalid.case.referring_physician = true;
            }
            else {
                case_invalid.case.referring_physician = false;
            }
            if (this._is_mounted) {
                this.setState({
                    case_form_invalid: case_invalid
                });
            }
        }
        else if (type_of_search == enum_type_of_search.place_of_service) {
            control_id = document.getElementById('auto' + global_constants.constants.advanced_control_type.location);
            if (case_data.old_location && case_data.old_location != "-999" && control_id['value']  == "") {
                case_invalid.case.place_of_service = true;
            }
            else {
                case_invalid.case.place_of_service = false;
            }
            if (this._is_mounted) {
                this.setState({
                    case_form_invalid: case_invalid
                });
            }
        }
        else if (type_of_search == enum_type_of_search.provider) {
            control_id = document.getElementById('auto' + global_constants.constants.advanced_control_type.provider);
            if (case_data.old_provider && case_data.old_provider != "-999" && control_id['value']  == "") {
                case_invalid.case.provider = true;
            }
            else {
                case_invalid.case.provider = false;
            }
            if (this._is_mounted) {
                this.setState({
                    case_form_invalid: case_invalid
                });
            }
        }

    }

    get_update_subscriber_info = () => {

    }
    get_show_status_primary_insurance = (control_flags) => {
        if (this._is_mounted) {
            this.setState({
                is_primary_insurance_show: control_flags.is_insurance_show && control_flags.is_insurance_active, is_insurance_worker_comp: control_flags.is_worker_comp
            });
        }
    }
    get_show_status_secondary_insurance = (control_flags) => {
        if (this._is_mounted) {
            this.setState({
                is_secondary_insurance_show: (control_flags.is_insurance_show && control_flags.is_insurance_active)
            });
        }
    }
    get_show_status_tertiary_insurance = (control_flags) => {
        if (this._is_mounted) {
            this.setState({
                is_tertiary_insurance_show: (control_flags.is_insurance_show && control_flags.is_insurance_active)
            });
        }
    }
    // add reffering physician modal
    show_add_referring_modal = (e, type_of_physcian) => {
        if (this._is_mounted) {
            this.setState({ add_physician_modal: !this.state.add_physician_modal, selected_physican_for_add: type_of_physcian });
        }
    }

    get_formatted_physician_info = (formatted_physician_info) => {
        if (this.state.selected_physican_for_add == enum_type_of_physician.primary_physician) {
            if (this._is_mounted) {
                this.setState({
                    referring_physician_quick_search_format: formatted_physician_info, add_physician_modal: false,
                    show_primary_advanced_control: false,
                    case: { ... this.state.case, referring_physician: { id: formatted_physician_info.id }, md_code: formatted_physician_info.id },
                    case_form_invalid: {
                        ...this.state.case_form_invalid, case: { ...this.state.case_form_invalid.case, referring_physician: false }
                    }

                });
            }
        }
        else {
            if (this._is_mounted) {
                this.setState({
                    second_referring_physician_quick_search_format: formatted_physician_info, add_physician_modal: false,
                    show_secondary_advanced_control: false,
                    case: { ... this.state.case, secondary_referring_physician: { id: formatted_physician_info.id }, secondary_md_code: formatted_physician_info.id },
                });
            }
        }


        //this.setState({ phy: !this.state.add_physician_modal });
    }

    /* function used to change the state of add refferal source model 
    after adding new source id and source description */
    get_formatted_referral_source_info = (item) => {
        const { case: case_data } = this.state
        if (item.is_active) {
            case_data.referring_source_id = item.id
            case_data.referring_source = item.description;
        }
        else {
            case_data.referring_source_id = 0;
            case_data.referring_source = "";
        }
        if (this._is_mounted) {
            this.setState({
                case: { ...case_data },
                add_referral_modal: false,
                show_refferal_advanced_control: false,
                referral_source_quick_search_format: {
                    "title": item ? item.id.toString() : '',
                    "label": item.description,
                    "rs_code": item.type_id
                }
            });
        }
    }

    // function used to show add source refferal model box
    show_add_referring_source_modal = (e) => {
        if (this._is_mounted) {
            this.setState({ add_referral_modal: !this.state.add_referral_modal });
        }
    }
    set_parent_show_modal_state = (show_status, control_type) => {
        if (control_type == global_constants.constants.advanced_control_type.referring_physician) {
            if (this._is_mounted) {
                this.setState({ show_primary_advanced_control: show_status });
            }
        }
        else if (control_type == global_constants.constants.advanced_control_type.second_referring_physician) {
            if (this._is_mounted) {
                this.setState({ show_secondary_advanced_control: show_status });
            }
        }
        else if (control_type == global_constants.constants.advanced_control_type.referral_source) {
            if (this._is_mounted) {
                this.setState({ show_refferal_advanced_control: show_status });
            }
        }

        //if (!show_status) {
        //    this.focus_referring_physician_search_icon();
        //}
    }
    // set current scrollTop value
    manage_scroll_height(container, element, type) {
        this.scroll_height = element.offsetTop;
        // var maxScrollPosition = container.scrollHeight - container.clientHeight;
    }
    // scroll handle to open relative tab and set scrollTop
    scroll_handle = (event, element, show) => {
        let scrollContainer = document.getElementById('scrollable-content-area');
        var my_element = document.getElementById(element);
        this.manage_scroll_height(scrollContainer, my_element, element)

        if (show == 'show_primary_insurance' && this._is_mounted) {
            this.setState({ show_primary_insurance: !this.state.show_primary_insurance }, this.set_scroll_height);
        } else if (show == 'show_secondary_insurance' && this._is_mounted) {
            this.setState({ show_secondary_insurance: !this.state.show_secondary_insurance }, this.set_scroll_height);
        } else if (show == 'show_tertiary_insurance' && this._is_mounted) {
            this.setState({ show_tertiary_insurance: !this.state.show_tertiary_insurance }, this.set_scroll_height);
        } else if (show == 'show_foto' && this._is_mounted) {
            this.setState({ show_foto: !this.state.show_foto }, this.set_scroll_height);
        } else if (show == 'show_verification_form' && this._is_mounted) {
            this.setState({ show_verification_form: !this.state.show_verification_form }, this.set_scroll_height);
        } else if (show == 'show_conditional_billing' && this._is_mounted) {
            this.setState({ show_conditional_billing: !this.state.show_conditional_billing }, this.set_scroll_height);
        }
    }

    // set scroll top value of each tab
    set_scroll_height = () => {
        document.getElementById('scrollable-content-area').scrollTop = this.scroll_height;
    }
    on_close_confirmation = () => {
        this.insurance_child_primary.insurance_doctors_orders.apply_validations();
        if (this._is_mounted) {
            this.setState({
                insurance_code_confirmation_box: false
            });
        }
    }
    on_insurance_code_force_save = () => {


    }


    on_doctors_order_close_confirmation = () => {
        this.insurance_child_primary.insurance_doctors_orders.apply_validations();
        if (this._is_mounted) {
            this.setState({
                doctors_order_confirmation_box: false
            });
        }
    }

    on_preauth_close_confirmation = () => {
        if (this._is_mounted) {
            this.setState({
                preauthorization_confirmation_box: false
            });
        }
    }

    on_preauthorization_doctors_order_close_confirmation = () => {
        if (this._is_mounted) {
            this.setState({
                preauthorization_doctors_order_confirmation_box: false
            });
        }
    }

    is_change_in_primary_insurance = () => {

        //checking for add eligibiity
        if (!this.insurance_child_primary.insurance_eligibility.is_list_empty()) {
            return true;
        }
        //checking for doctors orders
        if (!this.insurance_child_primary.insurance_doctors_orders.is_list_empty()) {
            return true;
        }

        //checking for preauthorization orders
        if (!this.insurance_child_primary.insurance_preauthorization.is_empty_preauth_list()) {
            return true;
        }
        return false;
    }

    is_change_in_secondary_insurance = () => {

        //checking for add eligibiity
        if (this.insurance_child_secondary && this.insurance_child_secondary.insurance_eligibility && !this.insurance_child_secondary.insurance_eligibility.is_list_empty()) {
            return true;
        }
        //checking for preauthorization orders
        if (this.insurance_child_secondary && this.insurance_child_secondary.insurance_eligibility && !this.insurance_child_secondary.insurance_preauthorization.is_empty_preauth_list()) {
            return true;
        }
        return false;
    }
    is_change_in_tertiary_insurance = () => {

        //checking for add eligibiity
        if (this.insurance_child_tertiary && this.insurance_child_tertiary.insurance_eligibility && !this.insurance_child_tertiary.insurance_eligibility.is_list_empty()) {
            return true;
        }
        //checking for preauthorization orders
        if (this.insurance_child_tertiary && this.insurance_child_tertiary.insurance_eligibility && !this.insurance_child_tertiary.insurance_preauthorization.is_empty_preauth_list()) {
            return true;
        }
        return false;
    }

    //handling red window pop up
    handle_update_case_error_response = (messages, update_case_data) => {
        for (let val of messages) {
            if (this._is_mounted) {
                if (val.code === global_constants.case_stale_exception.code) {
                    this.setState({
                        loading: false,
                        show_case_stale_message_popup: true
                    })
                }
            }
            else if (val.code == "50003" && update_case_data.case.add_new_pre_auth != null && update_case_data.case.add_new_pre_auth.length > 0 && this.user_type_id != 3) {
                    this.show_new_pre_auth_red_windo_modal = true;
                    const charge_visit_data = this.set_default_auth_before_pre_auth_model_open();
                if (this._is_mounted) {
                    this.setState({
                        loading: false,
                        new_pre_auth_data: charge_visit_data
                    });
                }
                return;
            }
            else {
                var case_data = this.state.case;
                const toastr_options = this.show_html_content_toaster(get_all_error(val));
                toastr.error('', toastr_options);
                if (this._is_mounted) {
                    this.setState({ case: case_data, loading: false, page_state: enum_case_page_state.edit, is_save_button_disabled: false });
                }
            }
        }
        if (this._is_mounted) {
            this.setState({
                loading: false,
                is_save_button_disabled: false
            });
        }
    }
    
    // close modal and empty form data
    close_new_pre_auth_red_window_modal = () => {
        this.show_new_pre_auth_red_windo_modal = false;
    };
    
    Rad_win_validation = (validation_for_visit,validation_for_unit) => {
        let final_auth_data = this._grouped_new_pre_auth_data;

        if (this._is_mounted) {
            this.setState({
                loading: true
            });
        }
        var isUnit = false;
        var isError = false;
        var preAuth = '';
        var preAuthNum = '';
        var dateRange = '';
        var visitMessage = 'The following PreAuthorizations have too many ' + (this.state.rad_window_type._current_pre_auth_tab == 'u' ? 'Units' : 'Visits') + ' Assigned. '

        final_auth_data.map((value: any, key: number) => {
            if (value.pri_pre_auth_selected_unique_id == this.pre_auth_not_selected.unique_id) {
                value.pri_pre_auth_selected_unique_id = undefined
            }
            if (value.sec_pre_auth_selected_unique_id == this.pre_auth_not_selected.unique_id) {
                value.sec_pre_auth_selected_unique_id = undefined
            }
            if (value.ter_pre_auth_selected_unique_id == this.pre_auth_not_selected.unique_id) {
                value.ter_pre_auth_selected_unique_id = undefined
            }
        });

        var updated_pre_auth_data = this.state.new_pre_auth_data;

        const primarySub = this.state.case.subscriber[0].subscriber_pre_auth.filter((x) => {
            if (this.state.rad_window_type._current_pre_auth_tab == 'u' && x.is_units && x.is_active) {
                return x
            } else if (this.state.rad_window_type._current_pre_auth_tab == 'v' && !x.is_units && x.is_active) {
                return x
            };
        });
        primarySub.map((item: any, index: number) => {
            preAuthNum = item.pre_auth_number;
            preAuth = item.unique_id;
            isUnit = item.is_units;
            dateRange = item.from_date + " - " + item.thru_date;
            var preAuthAllowedUnits = (item.pre_auth_visits == undefined || item.pre_auth_visits == null) ? 0 : item.pre_auth_visits;
            var preAuthVisit = (item.pre_auth_visits == undefined || item.pre_auth_visits == null) ? 0 : item.pre_auth_visits;
            var visitUsedP = (item.visits_used == undefined) ? "0" : item.visits_used;
            var invalid_pri_auth = false;

            updated_pre_auth_data.map((visit_value: any, key: number) => {
                if (updated_pre_auth_data[key].pri_pre_auth_selected_unique_id != null && updated_pre_auth_data[key].pri_pre_auth_selected_unique_id != undefined
                    && updated_pre_auth_data[key].pri_pre_auth_selected_unique_id.trim() != '00000000-0000-0000-0000-000000000000'
                    && preAuth.trim() == updated_pre_auth_data[key].pri_pre_auth_selected_unique_id.trim()) {

                    var visitCharges = this._grouped_new_pre_auth_data.filter(x => { return x.visit_id == visit_value.visit_id });
                    updated_pre_auth_data[key]['is_processed'] = true;
                    if (visitCharges && visitCharges.length > 0) {
                        if (isUnit) {
                            if (preAuthAllowedUnits <= 0) {
                                isError = true;
                                invalid_pri_auth = true;
                                return;
                            }
                            this._grouped_new_pre_auth_data.map((value: any, key1: number) => {
                                if (value.id == undefined && value.visit_id == visit_value.visit_id) {
                                    this._grouped_new_pre_auth_data[key1].is_unit = isUnit;
                                    if (preAuthAllowedUnits > 0) {
                                        this._grouped_new_pre_auth_data[key1].pri_pre_auth_selected_unique_id = updated_pre_auth_data[key].pri_pre_auth_selected_unique_id;
                                    }
                                    preAuth.trim() == this._grouped_new_pre_auth_data[key1].pri_pre_auth_selected_unique_id.trim() ? preAuthAllowedUnits = preAuthAllowedUnits - this._grouped_new_pre_auth_data[key1].units : "";
                                }
                            });
                        }
                        else {
                            if (preAuthAllowedUnits <= 0) {
                                isError = true;
                                invalid_pri_auth = true;
                                return;
                            }
                            this._grouped_new_pre_auth_data.map((value: any, key1: number) => {
                                if (value.id == undefined && value.visit_id == visit_value.visit_id) {
                                    this._grouped_new_pre_auth_data[key1].is_unit = isUnit;
                                    this._grouped_new_pre_auth_data[key1].pri_pre_auth_selected_unique_id = updated_pre_auth_data[key].pri_pre_auth_selected_unique_id;
                                }
                            });
                            preAuth.trim() == updated_pre_auth_data[key].pri_pre_auth_selected_unique_id.trim() ? preAuthAllowedUnits = preAuthAllowedUnits - 1 : "";

                        }
                    }
                } 
                else if (!updated_pre_auth_data[key].pri_pre_auth_selected_unique_id || updated_pre_auth_data[key].pri_pre_auth_selected_unique_id.trim() == this.pre_auth_not_selected.unique_id
                    || updated_pre_auth_data[key].pri_pre_auth_selected_unique_id.trim() == '00000000-0000-0000-0000-000000000000') {
                    var visitCharges = this._grouped_new_pre_auth_data.filter(x => { return x.visit_id == visit_value.visit_id });
                    if (visitCharges && visitCharges.length > 0) {
                        this._grouped_new_pre_auth_data.map((value: any, key1: number) => {
                            if (value.id == undefined && value.visit_id == visit_value.visit_id) {
                                this._grouped_new_pre_auth_data[key1].is_unit = isUnit;
                                this._grouped_new_pre_auth_data[key1].pri_pre_auth_selected_unique_id = updated_pre_auth_data[key].pri_pre_auth_selected_unique_id;
                            }
                        });
                    }
                }

                if (isError) {
                    return;
                }
            });
            if (invalid_pri_auth) {
                visitMessage += preAuthNum + ' | ' + dateRange + ' | ' + preAuthVisit + ' - ' + visitUsedP + ', ';
            }
        });

        const secondrySub = this.state.case.subscriber[1].subscriber_pre_auth.filter((x) => {
            if (this.state.rad_window_type._current_pre_auth_tab == 'u' && x.is_units && x.is_active) {
                return x
            } else if (this.state.rad_window_type._current_pre_auth_tab == 'v' && !x.is_units && x.is_active) {
                return x
            };
        });
        secondrySub.map((item: any, index: number) => {
            preAuthNum = item.pre_auth_number;
            preAuth = item.unique_id;
            isUnit = item.is_units;
            dateRange = item.from_date + " - " + item.thru_date;
            var preAuthAllowedUnits = (item.pre_auth_visits == undefined || item.pre_auth_visits == null) ? 0 : item.pre_auth_visits;
            var preAuthVisit = (item.pre_auth_visits == undefined || item.pre_auth_visits == null) ? 0 : item.pre_auth_visits;
            var visitUsedP = (item.visits_used == undefined) ? "0" : item.visits_used;
            var invalid_sec_auth = false;

            updated_pre_auth_data.map((visit_value: any, key: number) => {
                if (updated_pre_auth_data[key].sec_pre_auth_selected_unique_id != null && updated_pre_auth_data[key].sec_pre_auth_selected_unique_id != undefined
                    && updated_pre_auth_data[key].sec_pre_auth_selected_unique_id.trim() != '00000000-0000-0000-0000-000000000000'
                    && preAuth.trim() == updated_pre_auth_data[key].sec_pre_auth_selected_unique_id.trim()) {
                    updated_pre_auth_data[key]['is_processed'] = true;
                    var visitCharges = this._grouped_new_pre_auth_data.filter(x => { return x.visit_id == visit_value.visit_id });

                    if (visitCharges && visitCharges.length > 0) {
                        if (isUnit) {
                            if (preAuthAllowedUnits <= 0) {
                                isError = true;
                                invalid_sec_auth = true;
                                return;
                            }
                            this._grouped_new_pre_auth_data.map((value: any, key1: number) => {
                                if (value.id == undefined && value.visit_id == visit_value.visit_id) {
                                    this._grouped_new_pre_auth_data[key1].is_unit = isUnit;
                                    if (preAuthAllowedUnits > 0) {
                                        this._grouped_new_pre_auth_data[key1].sec_pre_auth_selected_unique_id = updated_pre_auth_data[key].sec_pre_auth_selected_unique_id;
                                    }
                                    preAuth.trim() == this._grouped_new_pre_auth_data[key1].sec_pre_auth_selected_unique_id.trim() ? preAuthAllowedUnits = preAuthAllowedUnits - this._grouped_new_pre_auth_data[key1].units : "";
                                }
                            });
                        }
                        else {
                            if (preAuthAllowedUnits <= 0) {
                                isError = true;
                                invalid_sec_auth = true;
                                return;
                            }
                            this._grouped_new_pre_auth_data.map((value: any, key1: number) => {
                                if (value.id == undefined && value.visit_id == visit_value.visit_id) {
                                    this._grouped_new_pre_auth_data[key1].is_unit = isUnit;
                                    this._grouped_new_pre_auth_data[key1].sec_pre_auth_selected_unique_id = updated_pre_auth_data[key].sec_pre_auth_selected_unique_id;
                                }
                            });
                            preAuth.trim() == updated_pre_auth_data[key].sec_pre_auth_selected_unique_id.trim() ? preAuthAllowedUnits = preAuthAllowedUnits - 1 : "";

                        }
                    }
                }
                else if (!updated_pre_auth_data[key].sec_pre_auth_selected_unique_id || updated_pre_auth_data[key].sec_pre_auth_selected_unique_id.trim() == this.pre_auth_not_selected.unique_id
                    || updated_pre_auth_data[key].sec_pre_auth_selected_unique_id.trim() == '00000000-0000-0000-0000-000000000000') {
                    var visitCharges = this._grouped_new_pre_auth_data.filter(x => { return x.visit_id == visit_value.visit_id });
                    if (visitCharges && visitCharges.length > 0) {
                        this._grouped_new_pre_auth_data.map((value: any, key1: number) => {
                            if (value.id == undefined && value.visit_id == visit_value.visit_id) {
                                this._grouped_new_pre_auth_data[key1].is_unit = isUnit;
                                this._grouped_new_pre_auth_data[key1].sec_pre_auth_selected_unique_id = updated_pre_auth_data[key].sec_pre_auth_selected_unique_id;
                            }
                        });
                    }
                }
                if (isError) {
                    return;
                }
            });
            if (invalid_sec_auth) {
                visitMessage += preAuthNum + ' | ' + dateRange + ' | ' + preAuthVisit + ' - ' + visitUsedP + ', ';
            }
        })

        const terSub = this.state.case.subscriber[2].subscriber_pre_auth.filter((x) => {
            if (this.state.rad_window_type._current_pre_auth_tab == 'u' && x.is_units && x.is_active) {
                return x
            } else if (this.state.rad_window_type._current_pre_auth_tab == 'v' && !x.is_units && x.is_active) {
                return x
            };
        });
        terSub.map((item: any, index: number) => {
            preAuthNum = item.pre_auth_number;
            preAuth = item.unique_id;
            isUnit = item.is_units;
            dateRange = item.from_date + " - " + item.thru_date;
            var preAuthAllowedUnits = (item.pre_auth_visits == undefined || item.pre_auth_visits == null) ? 0 : item.pre_auth_visits;
            var preAuthVisit = (item.pre_auth_visits == undefined || item.pre_auth_visits == null) ? 0 : item.pre_auth_visits;
            var visitUsedP = (item.visits_used == undefined) ? "0" : item.visits_used;
            var invalid_ter_auth = false;
            updated_pre_auth_data.map((visit_value: any, key: number) => {
                if (updated_pre_auth_data[key].ter_pre_auth_selected_unique_id != null && updated_pre_auth_data[key].ter_pre_auth_selected_unique_id != undefined
                    && updated_pre_auth_data[key].ter_pre_auth_selected_unique_id.trim() != '00000000-0000-0000-0000-000000000000'
                    && preAuth.trim() == updated_pre_auth_data[key].ter_pre_auth_selected_unique_id.trim()) {
                    updated_pre_auth_data[key]['is_processed'] = true;
                    var visitCharges = this._grouped_new_pre_auth_data.filter(x => { return x.visit_id == visit_value.visit_id });

                    if (visitCharges && visitCharges.length > 0) {
                        if (isUnit) {
                            if (preAuthAllowedUnits <= 0) {
                                isError = true;
                                invalid_ter_auth = true;
                                return;
                            }
                            this._grouped_new_pre_auth_data.map((value: any, key1: number) => {
                                if (value.id == undefined && value.visit_id == visit_value.visit_id) {
                                    this._grouped_new_pre_auth_data[key1].is_unit = isUnit;
                                    if (preAuthAllowedUnits > 0) {
                                        this._grouped_new_pre_auth_data[key1].ter_pre_auth_selected_unique_id = updated_pre_auth_data[key].ter_pre_auth_selected_unique_id;
                                    }
                                    preAuth.trim() == this._grouped_new_pre_auth_data[key1].ter_pre_auth_selected_unique_id.trim() ? preAuthAllowedUnits = preAuthAllowedUnits - this._grouped_new_pre_auth_data[key1].units : "";
                                }
                            });
                        }
                        else {
                            if (preAuthAllowedUnits <= 0) {
                                isError = true;
                                invalid_ter_auth = true;
                                return;
                            }
                            this._grouped_new_pre_auth_data.map((value: any, key1: number) => {
                                if (value.id == undefined && value.visit_id == visit_value.visit_id) {
                                    this._grouped_new_pre_auth_data[key1].is_unit = isUnit;
                                    this._grouped_new_pre_auth_data[key1].ter_pre_auth_selected_unique_id = updated_pre_auth_data[key].ter_pre_auth_selected_unique_id;
                                }
                            });
                            preAuth.trim() == updated_pre_auth_data[key].ter_pre_auth_selected_unique_id.trim() ? preAuthAllowedUnits = preAuthAllowedUnits - 1 : "";
                        }
                    }
                }
                else if (!updated_pre_auth_data[key].ter_pre_auth_selected_unique_id || updated_pre_auth_data[key].ter_pre_auth_selected_unique_id.trim() == this.pre_auth_not_selected.unique_id
                    || updated_pre_auth_data[key].ter_pre_auth_selected_unique_id.trim() == '00000000-0000-0000-0000-000000000000') {
                    var visitCharges = this._grouped_new_pre_auth_data.filter(x => { return x.visit_id == visit_value.visit_id });
                    if (visitCharges && visitCharges.length > 0) {
                        this._grouped_new_pre_auth_data.map((value: any, key1: number) => {
                            if (value.id == undefined && value.visit_id == visit_value.visit_id) {
                                this._grouped_new_pre_auth_data[key1].is_unit = isUnit;
                                this._grouped_new_pre_auth_data[key1].ter_pre_auth_selected_unique_id = updated_pre_auth_data[key].ter_pre_auth_selected_unique_id;
                            }
                        });
                    }
                }
                if (isError) {
                    return;
                }
            });
            if (invalid_ter_auth) {
                visitMessage += preAuthNum + ' | ' + dateRange + ' | ' + preAuthVisit + ' - ' + visitUsedP + ', ';
            }

        });
        
        if (isError) {

            this.show_new_pre_auth_red_windo_modal = true;
            if (this._is_mounted) {
                this.setState({
                    loading: false
                });
            }
            return toastr.warning('', visitMessage.substring(0, visitMessage.length - 2));

        }
        else {
            var updateCase = this.format_update_case_data(this._grouped_new_pre_auth_data);
            if (this._is_mounted) {
                this.setState({
                    loading: false,
                    new_pre_auth_data: updated_pre_auth_data,
                    rad_window_type: { ...this.state.rad_window_type, _current_pre_auth_tab: 'u' },
                    rad_win_btn_text: 'Save'
                })
            }

            
        }
    }
   
    on_button_click = () => {
        if (this.state.rad_win_btn_text == 'Save') {
            this.updateCaseUnderInvention();
        }
        else {
            this.Rad_win_validation(true, false);
        }
    }
    updateCaseUnderInvention = () => {       
        let final_auth_data = this._grouped_new_pre_auth_data;
               
        if (this._is_mounted) {
            this.setState({
                loading: true
            });
        }
        var isUnit = false;
        var isError = false;
        var preAuth = '';
        var preAuthNum = '';
        var dateRange = '';
        var visitMessage = 'The following PreAuthorizations have too many ' + (this.state.rad_window_type._current_pre_auth_tab == 'u' ? 'Units' : 'Visits') + ' Assigned. '

        final_auth_data.map((value: any, key: number) => {
            if (value.pri_pre_auth_selected_unique_id == this.pre_auth_not_selected.unique_id) {
                value.pri_pre_auth_selected_unique_id = undefined
            }
            if (value.sec_pre_auth_selected_unique_id == this.pre_auth_not_selected.unique_id) {
                value.sec_pre_auth_selected_unique_id = undefined
            }
            if (value.ter_pre_auth_selected_unique_id == this.pre_auth_not_selected.unique_id) {
                value.ter_pre_auth_selected_unique_id = undefined
            }
        });

        var updated_pre_auth_data =  this.state.new_pre_auth_data ;
        const priSub = this.state.case.subscriber[0].subscriber_pre_auth.filter((x) => {
            if (this.state.rad_window_type._current_pre_auth_tab == 'u' && x.is_units && x.is_active) {
                return x
            } else if (this.state.rad_window_type._current_pre_auth_tab == 'v' && !x.is_units && x.is_active) {
                return x
            };
        });
        priSub.map((item: any, index: number) => {
            preAuthNum = item.pre_auth_number;
            preAuth = item.unique_id;
            isUnit = item.is_units;
            dateRange = item.from_date + " - " + item.thru_date;
            var preAuthAllowedUnits = (item.pre_auth_visits == undefined || item.pre_auth_visits == null) ? 0 : item.pre_auth_visits;
            var preAuthVisit = (item.pre_auth_visits == undefined || item.pre_auth_visits == null) ? 0 : item.pre_auth_visits;
            var visitUsedP = (item.visits_used == undefined) ? "0" : item.visits_used;
            var invalid_auth = false;

            updated_pre_auth_data.map((visit_value: any, key: number) => {
                if (updated_pre_auth_data[key].pri_pre_auth_selected_unique_id != null && updated_pre_auth_data[key].pri_pre_auth_selected_unique_id != undefined
                    && updated_pre_auth_data[key].pri_pre_auth_selected_unique_id.trim() != '00000000-0000-0000-0000-000000000000' && preAuth.trim() == updated_pre_auth_data[key].pri_pre_auth_selected_unique_id.trim()) {

                    var visitCharges = this._grouped_new_pre_auth_data.filter(x => { return x.visit_id == visit_value.visit_id });
                    if (visitCharges && visitCharges.length > 0) {
                        if (isUnit) {
                            if (preAuthAllowedUnits <= 0) {
                                isError = true;
                                invalid_auth = true;
                                return;
                            }
                            this._grouped_new_pre_auth_data.map((value: any, key1: number) => {
                                if (value.id == undefined && value.visit_id == visit_value.visit_id) {
                                    this._grouped_new_pre_auth_data[key1].is_unit = isUnit;
                                    if (preAuthAllowedUnits > 0) {
                                        this._grouped_new_pre_auth_data[key1].pri_pre_auth_selected_unique_id = updated_pre_auth_data[key].pri_pre_auth_selected_unique_id;
                                    }
                                    preAuth.trim() == this._grouped_new_pre_auth_data[key1].pri_pre_auth_selected_unique_id.trim() ? preAuthAllowedUnits = preAuthAllowedUnits - this._grouped_new_pre_auth_data[key1].units : "";
                                }
                            });
                        }
                        else {
                            if (preAuthAllowedUnits <= 0) {
                                isError = true;
                                invalid_auth = true;
                                return;
                            }
                            this._grouped_new_pre_auth_data.map((value: any, key1: number) => {
                                if (value.id == undefined && value.visit_id == visit_value.visit_id) {
                                    this._grouped_new_pre_auth_data[key1].is_unit = isUnit;
                                    this._grouped_new_pre_auth_data[key1].pri_pre_auth_selected_unique_id = updated_pre_auth_data[key].pri_pre_auth_selected_unique_id;
                                }
                            });
                            preAuth.trim() == updated_pre_auth_data[key].pri_pre_auth_selected_unique_id.trim() ? preAuthAllowedUnits = preAuthAllowedUnits - 1 : "";

                        }
                    }
                }
                else if (!updated_pre_auth_data[key].pri_pre_auth_selected_unique_id || updated_pre_auth_data[key].pri_pre_auth_selected_unique_id.trim() == this.pre_auth_not_selected.unique_id
                    || updated_pre_auth_data[key].pri_pre_auth_selected_unique_id.trim() == '00000000-0000-0000-0000-000000000000') {
                    var visitCharges = this._grouped_new_pre_auth_data.filter(x => { return x.visit_id == visit_value.visit_id });
                    if (visitCharges && visitCharges.length > 0) {
                        this._grouped_new_pre_auth_data.map((value: any, key1: number) => {
                            if (value.id == undefined && value.visit_id == visit_value.visit_id) {
                                this._grouped_new_pre_auth_data[key1].is_unit = isUnit;
                                this._grouped_new_pre_auth_data[key1].pri_pre_auth_selected_unique_id = updated_pre_auth_data[key].pri_pre_auth_selected_unique_id;
                            }
                        });
                    }
                }
                if (isError) {
                    return;
                }
            });
            if (invalid_auth) {
                visitMessage +=  preAuthNum + ' | ' + dateRange + ' | ' + preAuthVisit + ' - ' + visitUsedP + ', ';
            }
        });

        const secSub = this.state.case.subscriber[1].subscriber_pre_auth.filter((x) => {
            if (this.state.rad_window_type._current_pre_auth_tab == 'u' && x.is_units && x.is_active) {
                return x
            } else if (this.state.rad_window_type._current_pre_auth_tab == 'v' && !x.is_units && x.is_active) {
                return x
            };
        });
        secSub.map((item: any, index: number) => {
            preAuthNum = item.pre_auth_number;
            preAuth = item.unique_id;
            isUnit = item.is_units;
            dateRange = item.from_date + " - " + item.thru_date;
            var preAuthAllowedUnits = (item.pre_auth_visits == undefined || item.pre_auth_visits == null) ? 0 : item.pre_auth_visits;
            var preAuthVisit = (item.pre_auth_visits == undefined || item.pre_auth_visits == null) ? 0 : item.pre_auth_visits;
            var visitUsedP = (item.visits_used == undefined) ? "0" : item.visits_used;
            var invalid_sec_auth = false;

            updated_pre_auth_data.map((visit_value: any, key: number) => {
                if (updated_pre_auth_data[key].sec_pre_auth_selected_unique_id != null && updated_pre_auth_data[key].sec_pre_auth_selected_unique_id != undefined
                    && updated_pre_auth_data[key].sec_pre_auth_selected_unique_id.trim() != '00000000-0000-0000-0000-000000000000'
                    && preAuth.trim() == updated_pre_auth_data[key].sec_pre_auth_selected_unique_id.trim()) {
                    var visitCharges = this._grouped_new_pre_auth_data.filter(x => { return x.visit_id == visit_value.visit_id });

                    if (visitCharges && visitCharges.length > 0) {
                        if (isUnit) {
                            if (preAuthAllowedUnits <= 0) {
                                isError = true;
                                invalid_sec_auth = true;
                                return;
                            }
                            this._grouped_new_pre_auth_data.map((value: any, key1: number) => {
                                if (value.id == undefined && value.visit_id == visit_value.visit_id) {
                                    this._grouped_new_pre_auth_data[key1].is_unit = isUnit;
                                    if (preAuthAllowedUnits > 0) {
                                        this._grouped_new_pre_auth_data[key1].sec_pre_auth_selected_unique_id = updated_pre_auth_data[key].sec_pre_auth_selected_unique_id;
                                    }
                                    preAuth.trim() == this._grouped_new_pre_auth_data[key1].sec_pre_auth_selected_unique_id.trim() ? preAuthAllowedUnits = preAuthAllowedUnits - this._grouped_new_pre_auth_data[key1].units : "";
                                }
                            });
                        }
                        else {
                            if (preAuthAllowedUnits <= 0) {
                                isError = true;
                                invalid_sec_auth = true;
                                return;
                            }
                            this._grouped_new_pre_auth_data.map((value: any, key1: number) => {
                                if (value.id == undefined && value.visit_id == visit_value.visit_id) {
                                    this._grouped_new_pre_auth_data[key1].is_unit = isUnit;
                                    this._grouped_new_pre_auth_data[key1].sec_pre_auth_selected_unique_id = updated_pre_auth_data[key].sec_pre_auth_selected_unique_id;
                                }
                            });
                            preAuth.trim() == updated_pre_auth_data[key].sec_pre_auth_selected_unique_id.trim() ? preAuthAllowedUnits = preAuthAllowedUnits - 1 : "";

                        }
                    }
                }
                else if (!updated_pre_auth_data[key].sec_pre_auth_selected_unique_id || updated_pre_auth_data[key].sec_pre_auth_selected_unique_id.trim() == this.pre_auth_not_selected.unique_id
                    || updated_pre_auth_data[key].sec_pre_auth_selected_unique_id.trim() == '00000000-0000-0000-0000-000000000000') {
                    var visitCharges = this._grouped_new_pre_auth_data.filter(x => { return x.visit_id == visit_value.visit_id });
                    if (visitCharges && visitCharges.length > 0) {
                        this._grouped_new_pre_auth_data.map((value: any, key1: number) => {
                            if (value.id == undefined && value.visit_id == visit_value.visit_id) {
                                this._grouped_new_pre_auth_data[key1].is_unit = isUnit;
                                this._grouped_new_pre_auth_data[key1].sec_pre_auth_selected_unique_id = updated_pre_auth_data[key].sec_pre_auth_selected_unique_id;
                            }
                        });
                    }
                }
                if (isError) {
                    return;
                }
            });
            if (invalid_sec_auth) {
                visitMessage += preAuthNum + ' | ' + dateRange + ' | ' + preAuthVisit + ' - ' + visitUsedP + ', ';
            }
        });

        const terSub = this.state.case.subscriber[2].subscriber_pre_auth.filter((x) => {
            if (this.state.rad_window_type._current_pre_auth_tab == 'u' && x.is_units && x.is_active) {
                return x
            } else if (this.state.rad_window_type._current_pre_auth_tab == 'v' && !x.is_units && x.is_active) {
                return x
            };
        });
        terSub.map((item: any, index: number) => {
            preAuthNum = item.pre_auth_number;
            preAuth = item.unique_id;
            isUnit = item.is_units;
            dateRange = item.from_date + " - " + item.thru_date;
            var preAuthAllowedUnits = (item.pre_auth_visits == undefined || item.pre_auth_visits == null) ? 0 : item.pre_auth_visits;
            var preAuthVisit = (item.pre_auth_visits == undefined || item.pre_auth_visits == null) ? 0 : item.pre_auth_visits;
            var visitUsedP = (item.visits_used == undefined) ? "0" : item.visits_used;
            var invalid_ter_auth = false;

            updated_pre_auth_data.map((visit_value: any, key: number) => {
                if (updated_pre_auth_data[key].ter_pre_auth_selected_unique_id != null && updated_pre_auth_data[key].ter_pre_auth_selected_unique_id != undefined
                    && updated_pre_auth_data[key].ter_pre_auth_selected_unique_id.trim() != '00000000-0000-0000-0000-000000000000'
                    && preAuth.trim() == updated_pre_auth_data[key].ter_pre_auth_selected_unique_id.trim()) {

                    var visitCharges = this._grouped_new_pre_auth_data.filter(x => { return x.visit_id == visit_value.visit_id });

                    if (visitCharges && visitCharges.length > 0) {
                        if (isUnit) {
                            if (preAuthAllowedUnits <= 0) {
                                isError = true;
                                invalid_ter_auth = true;
                                return;
                            }
                            this._grouped_new_pre_auth_data.map((value: any, key1: number) => {
                                if (value.id == undefined && value.visit_id == visit_value.visit_id) {
                                    this._grouped_new_pre_auth_data[key1].is_unit = isUnit;
                                    if (preAuthAllowedUnits > 0) {
                                        this._grouped_new_pre_auth_data[key1].ter_pre_auth_selected_unique_id = updated_pre_auth_data[key].ter_pre_auth_selected_unique_id;
                                    }
                                    preAuth.trim() == this._grouped_new_pre_auth_data[key1].ter_pre_auth_selected_unique_id.trim() ? preAuthAllowedUnits = preAuthAllowedUnits - this._grouped_new_pre_auth_data[key1].units : "";
                                }
                            });
                        }
                        else {
                            if (preAuthAllowedUnits <= 0) {
                                isError = true;
                                invalid_ter_auth = true;
                                return;
                            }
                            this._grouped_new_pre_auth_data.map((value: any, key1: number) => {
                                if (value.id == undefined && value.visit_id == visit_value.visit_id) {
                                    this._grouped_new_pre_auth_data[key1].is_unit = isUnit;
                                    this._grouped_new_pre_auth_data[key1].ter_pre_auth_selected_unique_id = updated_pre_auth_data[key].ter_pre_auth_selected_unique_id;
                                }
                            });
                            preAuth.trim() == updated_pre_auth_data[key].ter_pre_auth_selected_unique_id.trim() ? preAuthAllowedUnits = preAuthAllowedUnits - 1 : "";
                        }
                    }
                }
                else if (!updated_pre_auth_data[key].ter_pre_auth_selected_unique_id || updated_pre_auth_data[key].ter_pre_auth_selected_unique_id.trim() == this.pre_auth_not_selected.unique_id
                    || updated_pre_auth_data[key].ter_pre_auth_selected_unique_id.trim() == '00000000-0000-0000-0000-000000000000') {
                    var visitCharges = this._grouped_new_pre_auth_data.filter(x => { return x.visit_id == visit_value.visit_id });
                    if (visitCharges && visitCharges.length > 0) {
                        this._grouped_new_pre_auth_data.map((value: any, key1: number) => {
                            if (value.id == undefined && value.visit_id == visit_value.visit_id) {
                                this._grouped_new_pre_auth_data[key1].is_unit = isUnit;
                                this._grouped_new_pre_auth_data[key1].ter_pre_auth_selected_unique_id = updated_pre_auth_data[key].ter_pre_auth_selected_unique_id;
                            }
                        });
                    }
                }
                if (isError) {
                    return;
                }
            });
            if (invalid_ter_auth) {
                visitMessage += preAuthNum + ' | ' + dateRange + ' | ' + preAuthVisit + ' - ' + visitUsedP + ', ';
            }
        });
        
        if (isError) {

            this.show_new_pre_auth_red_windo_modal = true;
            if (this._is_mounted) {
                this.setState({
                    loading: false
                });
            }
            return toastr.warning('', visitMessage.substring(0, visitMessage.length - 2));

        }
        else {
            this.close_new_pre_auth_red_window_modal();
            var updateCase = this.format_update_case_data(this._grouped_new_pre_auth_data);
            if (this._is_mounted) {
                this.setState({
                    loading: true
                })
            }

            this.update_case(updateCase, this.rebill);
        }

    }

    change_new_pre_auth_red_window = (value, row, index, prop) => {      
        var data = this.state.new_pre_auth_data;
        data[index][prop] = value;       
        if (this._is_mounted) {
            this.setState({
                new_pre_auth_data: data
            });
        }
    }

    new_pre_auth_red_window_select_focus = (e,count) => {
        if (e.currentTarget && document.querySelector('#new_pre_auth_red_window_container')) {
            // set scroll while focus on select box
            document.querySelector('#new_pre_auth_red_window_container').scrollLeft = (e.currentTarget.offsetWidth * count);
        }
    }

    /**
     * Focus on a given element after a timeout of 100 millisec.
     * */
    btn_focus = (element) => {
        setTimeout(() => {
            if (element) {
                element.focus();
            }
        }, 100);
    }
    setup_primary_insurance_reference = (instance) => {
        this.insurance_child_primary = instance;
    }
    
    setup_secondary_insurance_reference = (instance) => {
        this.insurance_child_secondary = instance;
    }
    
    setup_tertiary_insurance_reference = (instance) => {
        this.insurance_child_tertiary = instance;
    }
    
    setup_conditional_billing_reference = (instance) => {
        this.conditional_billing_child = instance;
    }
    
    setup_diagnosis_reference = (instance) => {
        this.diagnosis_child = instance;
    }
    show_add_referring_modal_primary_physician = (e) => {
        this.show_add_referring_modal(e, enum_type_of_physician.primary_physician)
    }
    show_add_referring_modal_secondary_physician = (e) => {
        this.show_add_referring_modal(e, enum_type_of_physician.secondary_physician)
    }

    on_blur_auto_search_referring_physician = (e) => {
        this.on_blur_auto_search(e, enum_type_of_search.referring_physician)
    }
    on_blur_auto_search_place_of_service = (e) => {
        this.on_blur_auto_search(e, enum_type_of_search.place_of_service)
    }
    
    on_blur_auto_search_provider = (e) => {
        this.on_blur_auto_search(e, enum_type_of_search.provider)
    }
    
    get_input_value_referring_physician = (input) => {
        this.get_input_value(input, enum_type_of_search.referring_physician)
    }
    
    get_input_value_second_referring_physician = (input) => {
        this.get_input_value(input, enum_type_of_search.second_referring_physician)
    }
    
    get_input_value_place_of_service = (input) => {
        this.get_input_value(input, enum_type_of_search.place_of_service)
    }
    
    get_input_value_provider = (input) => {
        this.get_input_value(input, enum_type_of_search.provider)
    }
    
    get_input_value_referral_source = (input) => {
        this.get_input_value(input, enum_type_of_search.referral_source)
    }
    get_input_value_special_program = (input) => {
        this.get_input_value(input, enum_type_of_search.special_program)
    }
    
    prepare_suggestion_referring_physician = (data) => {
        return this.prepare_suggestion(data, enum_type_of_search.referring_physician)
    }
    prepare_suggestion_second_referring_physician = (data) => {
        return this.prepare_suggestion(data, enum_type_of_search.second_referring_physician)
    }
    prepare_suggestion_place_of_service = (data) => {
        return this.prepare_suggestion(data, enum_type_of_search.place_of_service)
    }
    prepare_suggestion_provider = (data) => {
        return this.prepare_suggestion(data, enum_type_of_search.provider)
    }
    
    prepare_suggestion_referral_source = (data) => {
        return this.prepare_suggestion(data, enum_type_of_search.referral_source)
    }
    prepare_suggestion_special_program = (data) => {
        return this.prepare_suggestion(data, enum_type_of_search.special_program)
    }
    
    on_item_selection_referring_physician = (item) => {
        this.on_item_selection(item, enum_type_of_search.referring_physician)
    }
    on_item_selection_second_referring_physician = (item) => {
        this.on_item_selection(item, enum_type_of_search.second_referring_physician)
    }
    on_item_selection_place_of_service = (item) => {
        this.on_item_selection(item, enum_type_of_search.place_of_service)
    }
    on_item_selection_provider = (item) => {
        this.on_item_selection(item, enum_type_of_search.provider)
    }
    on_item_selection_special_program = (item) => {
        this.on_item_selection(item, enum_type_of_search.special_program)
    }
    
    on_item_selection_referral_source = (item) => {
        this.on_item_selection(item, enum_type_of_search.referral_source)
    }
    clear_quick_search_referring_physician = () => {
        this.clear_quick_search(enum_type_of_search.referring_physician)
    }
    clear_quick_search_second_referring_physician = () => {
        this.clear_quick_search(enum_type_of_search.second_referring_physician)
    }
    clear_quick_search_place_of_service = () => {
        this.clear_quick_search(enum_type_of_search.place_of_service)
    }
    clear_quick_search_provider = () => {
        this.clear_quick_search(enum_type_of_search.provider)
    }
    clear_quick_search_referral_source = () => {
        this.clear_quick_search(enum_type_of_search.referral_source)
    }
    clear_quick_search_special_program = () => {
        this.clear_quick_search(enum_type_of_search.special_program)
    }
    handle_calendar_change_for_injury = (e) => {
        this.handle_calendar_change(e, enum_date_type.injury)
    }
    handle_calendar_change_for_start_of_care = (e) => {
        this.handle_calendar_change(e, enum_date_type.start_of_care)
    }
    handle_calendar_change_for_discharge = (e) => {
        this.handle_calendar_change(e, enum_date_type.discharge)
    }
    
    handle_calendar_change_for_assignment = (e) => {
        this.handle_calendar_change(e, enum_date_type.assignment)
    }
    handle_calendar_change_for_effective = (e) => {
        this.handle_calendar_change(e, enum_date_type.effective)
    }
    handle_calendar_change_for_next_visit = (e) => {
        this.handle_calendar_change(e, enum_date_type.next_visit)
    }
    
    handle_calendar_change_for_referral = (e) => {
        this.handle_calendar_change(e, enum_date_type.referral)
    }

    on_case_info_change_for_referral_status = (value, e) => {
        this.on_case_info_change(e, { name: "referral_status", value: value })
    }
    on_case_info_change_for_note_code = (value, e) => {
        this.on_case_info_change(e, { name: "note_code", value: value })
    }
    on_case_info_change_for_injury_type = (value, e) => {
        this.on_case_info_change(e, { name: "injury_type", value: value })
    }
    on_case_info_change_for_discipline = (value, e) => {
        this.on_case_info_change(e, { name: "discipline", value: value })
    }
    on_case_info_change_for_accident_location = (value, e) => {
        this.on_case_info_change(e, { name: "accident_location", value: value })
    }

    on_close_case_stale_message_popup = () => {
        if (this._is_mounted == false) return;

        if (this._is_mounted) {
            this.setState({
                show_case_stale_message_popup: false
            });
        }
    }

    refresh_case_stale_message_popup = () => {
        if (this._is_mounted) {
            this.setState({
                show_case_stale_message_popup: false
            }, () => {
                    if (this.state.case.id > 0)
                        var navigate_to = `/patient/${this.state.patient_id}`;
                        this.props.history.push(`${navigate_to}`);     
            });
        }
    }

    openOptionsList = () => {
        this.setState({ statusOptionList: !this.state.statusOptionList });
    }

    onBlurHandler = (event:any) => {
        if(event.relatedTarget && event.relatedTarget.getAttribute('role') == 'item'){
            event.relatedTarget.click()   
        }
        this.setState({ statusOptionList: false });
    }

    setCaseBeforeEdition = () => {
        let initialCase = _.cloneDeep(this.format_update_case_data());
        initialCase.case.assignment_date = date_format_with_no_time(initialCase.case.formatted_assignment_date);
        initialCase.case.effective_date = date_format_with_no_time(initialCase.case.formatted_effective_date);
        initialCase.case.discharge_date = date_format_with_no_time(initialCase.case.formatted_discharge_date);
        initialCase.case.injury_date = date_format_with_no_time(initialCase.case.formatted_injury_date);
        initialCase.case.next_visit_date = date_format_with_no_time(initialCase.case.formatted_next_visit_date);
        initialCase.case.start_of_care_date = date_format_with_no_time(initialCase.case.formatted_start_of_care_date);
        initialCase.case.referral_date = date_format_with_no_time(initialCase.case.formatted_referral_date);
        
        if (!is_null_or_white_space(initialCase.case.md_code)) {
            initialCase.case.md_code = initialCase.case.md_code.toString();
        }
        if (!is_null_or_white_space(initialCase.case.pos_code)) {
            initialCase.case.pos_code = initialCase.case.pos_code.toString();
        }
        if (!is_null_or_white_space(initialCase.case.billing_provider_code)) {
            initialCase.case.billing_provider_code = initialCase.case.billing_provider_code.toString();
        }

        let subscriber_list = [];
        let is_form_invalid_insurance = this.validate_form_field_insurance();
        if (is_form_invalid_insurance.primary.is_insurance_available) {
            initialCase.case.subscriber = [];
            if (this.insurance_child_primary && this.insurance_child_primary.state) {
                subscriber_list = this.insurance_child_primary.get_on_update_subscriber_data();
                initialCase.case.subscriber.push(_.cloneDeep(subscriber_list));
            }
            if (this.insurance_child_secondary && this.insurance_child_secondary.state) {
                subscriber_list = this.insurance_child_secondary.get_on_update_subscriber_data();
                initialCase.case.subscriber.push(_.cloneDeep(subscriber_list));
            }
            if (this.insurance_child_tertiary && this.insurance_child_tertiary.state) {
                subscriber_list = this.insurance_child_tertiary.get_on_update_subscriber_data();
                initialCase.case.subscriber.push(_.cloneDeep(subscriber_list));                                
            }
            for (let index = 0; index < initialCase.case.subscriber.length; index++) {
                initialCase.case.subscriber[index].date_of_birth = !initialCase.case.subscriber[index].formatted_date_of_birth? null : date_format_with_no_time(initialCase.case.subscriber[index].date_of_birth);
                initialCase.case.subscriber[index].is_insurance_update = true;
                if(!initialCase.case.subscriber[index].insurance_code_id){
                    initialCase.case.subscriber[index].is_insurance_update = false;
                }
                if (initialCase.case.subscriber[index].is_us_selected) {
                    initialCase.case.subscriber[index].address.province = '';
                    initialCase.case.subscriber[index].address.postal_code = '';
                }
                else {
                    initialCase.case.subscriber[index].address.zip = '';
                }
                if(initialCase.case.subscriber[index].subscriber_eligibility && initialCase.case.subscriber[index].subscriber_eligibility.length > 0){
                    initialCase.case.subscriber[index].subscriber_eligibility.map((value: any, key1: number) => {
                        initialCase.case.subscriber[index].subscriber_eligibility[key1].effective_date = date_format_with_no_time(value.effective_date);
                        initialCase.case.subscriber[index].subscriber_eligibility[key1].termination_date = date_format_with_no_time(value.termination_date);
                    })                
                }
                if(initialCase.case.subscriber[index].subscriber_pre_auth && initialCase.case.subscriber[index].subscriber_pre_auth.length > 0){
                    initialCase.case.subscriber[index].subscriber_pre_auth.map((value: any, key1: number) => {
                        initialCase.case.subscriber[index].subscriber_pre_auth[key1].from_date = date_format_with_no_time(value.from_date);
                        initialCase.case.subscriber[index].subscriber_pre_auth[key1].thru_date = date_format_with_no_time(value.thru_date);
                    })                
                }
            }
        }

        initialCase.is_rebill_force_save = false;
        initialCase.case.add_new_pre_auth = [];
        initialCase.case.old_descipline = initialCase.case.old_descipline == null ? null : initialCase.case.old_descipline.toString();
        this.setState({ case_before_edition: initialCase });
    }

    isPartialUpdate = (caseEdited) => {
        this.fields_required_to_exclude = fields_to_compare.excluded_required;
        let response =  _.isEqualWith(this.state.case_before_edition, caseEdited, this.isEqualDefaultValue);
        this.fields_required_to_exclude = [];
        return response;
    }

    hasNewItemsCase = (caseEdited) => {
        let editedDiagnosis = caseEdited.diagnosis;
        let originalDiagnosis = this.state.case_before_edition.diagnosis;
        if(originalDiagnosis && editedDiagnosis && originalDiagnosis.length < editedDiagnosis.length){
            return true;
        }
        let editedSubscriber = caseEdited.case.subscriber;
        let originalSubscriber = this.state.case_before_edition.case.subscriber;
        if(originalSubscriber && editedSubscriber && originalSubscriber.length < editedSubscriber.length){
            return true;
        }
        let coverageList = ['P','S','T'];
        for(let index in coverageList){            
            let orInsurance = originalSubscriber.find(subscriber => subscriber.pst_coverage == coverageList[index]);
            let edInsurance = editedSubscriber.find(subscriber => subscriber.pst_coverage == coverageList[index]);
            if(!orInsurance || !edInsurance) continue;

            let orEligibility = orInsurance.subscriber_eligibility? orInsurance.subscriber_eligibility.length : 0;
            let edEligibility = edInsurance.subscriber_eligibility? edInsurance.subscriber_eligibility.length : 0;
            let orPreAuth = orInsurance.subscriber_pre_auth? orInsurance.subscriber_pre_auth.length : 0;
            let edPreAuth = edInsurance.subscriber_pre_auth? edInsurance.subscriber_pre_auth.length : 0;
            let orDoctorOrders = orInsurance.subscriber_doctors_orders? orInsurance.subscriber_doctors_orders.length : 0;
            let edDoctorOrders = edInsurance.subscriber_doctors_orders? edInsurance.subscriber_doctors_orders.length : 0;
             
            if(orEligibility < edEligibility || orPreAuth < edPreAuth || orDoctorOrders < edDoctorOrders){
                return true;
            }
        }      
        return false;
    }

    isEqualDefaultValue = (initialValue, editedValue) => {
        let objWithDiff = this.objWithDiff(initialValue, editedValue);
        return this.isEqualDefaultValuesInObjects(objWithDiff, initialValue, editedValue);
    }

    isEqualDefaultValuesInObjects = (objWithDiff, initialValue, editedValue) => {
        let isEqual = true;
        for (let key in objWithDiff) {
            if(!this.isFieldExcluded(key)){
                if(_.isObject(objWithDiff[key]) && !moment.isMoment(objWithDiff[key])){
                    isEqual = this.isEqualDefaultValuesInObjects(objWithDiff[key], initialValue[key], editedValue[key]);                           
                } else {
                    isEqual = this.isDefaultValues(initialValue[key], editedValue[key], key);
                } 
            }
            if(!isEqual) break;                                  
        }
        return isEqual;
    }

    isFieldExcluded = (fieldName) => {
        return fields_to_compare.excluded_default.some(fExcluded => fieldName.startsWith(fExcluded)) || 
                this.fields_required_to_exclude.some(fExcluded => fieldName === fExcluded);
    }

    isDecimalField = (fieldName) => {
        return fields_to_compare.decimal.some(fDecimal => fieldName === fDecimal);
    }

    isDefaultValues = (initialValue, editedValue, fieldName) => {
        // check if initialValue is null or undefined
        if (initialValue == null || initialValue == undefined) {
            if (editedValue == null || editedValue == undefined) {
                return true;
            }
            return false;
        }
        if(this.isDecimalField(fieldName) && _.isString(editedValue)){
            return initialValue.toFixed(2) === editedValue;
        }
        if(moment.isMoment(initialValue)){
            if(moment.isMoment(editedValue)){
                return moment(initialValue).isSame(editedValue, 'day');
            }
            if(editedValue){
                return moment(initialValue).isSame(date_format_with_no_time(editedValue), 'day');
            }            
        }
        if(!initialValue && (!editedValue || editedValue === "0" || editedValue === "-999")){
            return true;
        }
        if(initialValue && editedValue && initialValue.toString().trim() === editedValue.toString().trim()){
            return true;
        }
        return false;
    }

    objWithDiff = (object, base) => {
        return _.transform(object, (result, value, key) => {
            if (!_.isEqual(value, base[key])) {
                result[key] = (_.isObject(value) && _.isObject(base[key])) ? this.objWithDiff(value, base[key]) : value;
            }
        });
    }

    render() {
        var style_margin = { marginTop: '8px', marginBottom: '8px' };
        var style_margin_diagnosis = { marginTop: '8px', marginBottom: '20px' };
        var message = 'You have entered information without selecting an Insurance Code. Your information cannot be validated for accuracy without a known insurance and will not be saved. Press "Save" to proceed or "Cancel" to enter the Insurance Code';
        var errorMessageDoctorsOrder = 'The Doctor\'s Orders requires a From Date. Save Case without Doctor\'s Orders?'
        let self = this;
        let rad_window_save_disable = (this.state.rad_window_type._has_unit_pre_auth && this.state.rad_window_type._has_visit_pre_auth
            && this.state.rad_window_type._current_pre_auth_tab == 'u'
            && this.state.new_pre_auth_data.filter(x => { return !x.is_processed; }).length == 0);

        var formate_new_pre_auth_row = (source) => {
            var no_pre_auth = source.filter((x) => { return this.pre_auth_not_selected.unique_id == x.unique_id; })
            var target = [];
            if (this.state.rad_window_type._current_pre_auth_tab == 'v') {
                source = source.filter((x) => { return !x.is_units && this.pre_auth_not_selected.unique_id != x.unique_id  })
            }
            if (this.state.rad_window_type._current_pre_auth_tab == 'u') {
                source = source.filter((x) => { return x.is_units && this.pre_auth_not_selected.unique_id != x.unique_id  })
            }
           
            
            if (source && source.length > 0 && no_pre_auth && no_pre_auth.length >0) {                
                source.unshift(no_pre_auth[0]);
            }
            if (source && source.length > 0) {
                for (let y = 0; y < source.length; y++) {
                    let item = source[y];


                    var display_text = (item.pre_auth_number != this.pre_auth_not_selected.pre_auth_number ?
                        item.pre_auth_number + " | " + item.from_date + " - " + item.thru_date + " | "
                        + (item.pre_auth_visits != null && item.pre_auth_visits > 0 ? (item.is_units ?
                            (item.pre_auth_visits != null ? item.pre_auth_visits + "U" : "0U") :
                            (item.pre_auth_visits != null ? item.pre_auth_visits + "V" : "0V"))
                            + " - " + (item.is_units ? (item.visits_used != null ? item.visits_used + "U" : "0U")
                                : (item.visits_used != null ? item.visits_used + "V" : "0V"))
                            : "NO VISIT RESTRICTION")
                        : item.pre_auth_number);


                    target.push({
                        "text": (display_text),
                        "value": item.unique_id,
                        "key": item.pre_auth_number + item.id + item.sequence_number
                    })
                }
            }
            return target;
        }

        var select_options_render = (options, defaultValue) => {

            var option_value = [];
            if (options) {
                var having_selected_value = true;
                option_value = options.map((item, index) => {
                    if (defaultValue === item.value) {
                        having_selected_value = false;
                        return <option key={Math.random()+index} value={item.value} selected={true}>{item.text}</option>;

                    }
                    return (<option key={Math.random() + index} value={item.value}>{item.text}</option>);
                });
                if (having_selected_value) {
                    option_value.unshift(<option key={options.length+Math.random()} selected={true}></option>);
                }
                return option_value

            }
            return null;
        }

        return (
            <React.Fragment>
                <Dimmer active={this.state.loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>

                {this.state.show_case_stale_message_popup ?
                    <ConfirmationComponent message={global_constants.case_stale_exception.message}
                        title='Confirm' show_modal={this.state.show_case_stale_message_popup}
                        onCloseModal={this.on_close_case_stale_message_popup}
                        save_button_text='Refresh'
                        on_force_save_click={(e) => { this.refresh_case_stale_message_popup() }} />
                    : ''}

                {this.state.insurance_code_confirmation_box ?
                    <ConfirmationComponent message={message}
                        title='Insurance Code' show_modal={this.state.insurance_code_confirmation_box} onCloseModal={this.on_close_confirmation} on_force_save_click={(e) => { this.on_case_save_click(false) }} />
                    : ''}

                {this.state.preauthorization_doctors_order_confirmation_box ?
                    <ConfirmationComponent message={case_messages.preauth_doctors_order_error_message}
                        title="Pre-Authorization and Doctor's Orders" show_modal={this.state.preauthorization_doctors_order_confirmation_box} onCloseModal={this.on_preauthorization_doctors_order_close_confirmation} on_force_save_click={(e) => { this.on_case_save_click(undefined, false, false) }} />
                    : ''}

                {this.state.doctors_order_confirmation_box ?
                    <ConfirmationComponent message={errorMessageDoctorsOrder}
                        title="Doctors's Orders" show_modal={this.state.doctors_order_confirmation_box} onCloseModal={() => {

                            self.insurance_child_primary.insurance_doctors_orders.apply_validations();
                            if (self._is_mounted) {
                                self.setState({
                                    doctors_order_confirmation_box: false
                                });
                            }
                        }} on_force_save_click={(e) => { this.on_case_save_click(true, false) }} />
                    : ''}

                {this.state.preauthorization_confirmation_box ?
                    <ConfirmationComponent message={case_messages.preauth_error_message}
                        title="Insurance" show_modal={this.state.preauthorization_confirmation_box} onCloseModal={this.on_preauth_close_confirmation} on_force_save_click={(e) => { this.on_case_save_click(undefined, undefined, false) }} />
                    : ''}

                <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column patient-menu padd-l-0" id="applicationPageTitleSticky">
                    <h3 className="ui header">Case</h3>
                    <ul className="subheader-menu">
                        {
                            this.state.is_primary_insurance_show || this.state.page_state == enum_case_page_state.add ?
                                <li><Link tabIndex={0} to="#primary_insurance" onClick={(e) => this.scroll_handle(e, 'primary_insurance', 'show_primary_insurance')} activeClass="active" smooth={true} containerId="scrollContainer">
                                    Primary Insurance
                           </Link>
                                </li>
                                : ''
                        }
                        {
                            ((!this.state.is_insurance_worker_comp && this.state.is_secondary_insurance_show) || (!this.state.is_insurance_worker_comp && this.state.page_state == enum_case_page_state.add)) ?
                                <li>
                                    <Link tabIndex={0} to="#secondary_insurance" onClick={(e) => this.scroll_handle(e, 'secondary_insurance', 'show_secondary_insurance')} containerId="scrollContainer">Secondary Insurance</Link>
                                </li> : ''
                        }
                        {
                            ((!this.state.is_insurance_worker_comp && this.state.is_tertiary_insurance_show) || (!this.state.is_insurance_worker_comp && this.state.page_state == enum_case_page_state.add)) ?
                                <li>
                                    <Link tabIndex={0} to="#tertiary_insurance" onClick={(e) => this.scroll_handle(e, 'tertiary_insurance', 'show_tertiary_insurance')} containerId="scrollContainer">Tertiary Insurance</Link>
                                </li> : ''
                        }

                        {
                            //<li>
                            //<Link to="#foto" smooth={true} onClick={(e) => this.scroll_handle(e, 'foto', 'show_foto')} containerId="scrollContainer">FOTO</Link>
                            //</li>
                        }

                        {this.state.case.id > 0 &&
                            <li>
                        <Link tabIndex={0} to="#verification_form" smooth={true} onClick={(e) => this.scroll_handle(e, 'verification_form', 'show_verification_form')} containerId="scrollContainer">Verification Forms</Link>
                            </li>
                        }
                        {
                            (this.state.is_conditional_billing_show) ?
                                <li>
                                    <Link tabIndex={0} to="#conditional_billing" smooth={true} onClick={(e) => this.scroll_handle(e, 'conditional_billing', 'show_conditional_billing')} containerId="scrollContainer">Conditional Billing</Link>
                                </li> : ''
                        }

                    </ul>
                </div>
                {

                }
                <AddReferringSource
                    add_referring_source={this.state.add_referral_modal}
                    get_formatted_referral_source_info={this.get_formatted_referral_source_info}
                />
                <AddReferringPhysician add_physician={this.state.add_physician_modal} md_code={this.state.case.md_code} get_formatted_physician={this.get_formatted_physician_info} />
                <div className="common-forms-add">
                    <div className={this.state.page_state === enum_case_page_state.edit ? 'auto-height-patient-scroll' : 'auto-height-scroll'} id="scrollable-content-area">
                        <Form className="patient-add-form" autoComplete="off" >
                            <Grid>
                                <Grid.Column className="mar-b-20" mobile={16} tablet={16} computer={8}>
                                    <Header as='h4' className="mar-t-15">
                                        Case Information
                                    </Header>
                                    <Grid>
                                        <Grid.Column mobile={16} tablet={16} computer={16}>
                                            <Form.Field>
                                                <label>Case Name <span className="req-alert"></span></label>
                                                <Form.Input fluid disabled={this.state.page_state === enum_case_page_state.edit}
                                                    autoFocus autoComplete="new-password" name='txt_description' value={this.state.case.description || ''} onChange={
                                                        e => { this.on_case_info_change(e) }
                                                    } id="case_name_txt" maxLength={50}
                                                    //className={this.state.case.id > 0 && this.state.page_state === enum_case_page_state.add && this.state.case_form_invalid.case.description && this.state.form_submited ? "red-error-thin" : ''}
                                                    error={this.state.case.id > 0 && this.state.page_state === enum_case_page_state.add && this.state.case_form_invalid.case.description && this.state.form_submited}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={16} computer={16}>
                                            <Form.Field>
                                                <label>Injury Type <span className={this.state.case_form_invalid.case.injury_type && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span></label>
                                                {
                                                    <Selection
                                                        placeHolder='Select'
                                                        name='injury_type'
                                                        id="case_injury_type"
                                                        options={this.state.master_data.injury_type}
                                                        onChange={this.on_case_info_change_for_injury_type}
                                                        defaultValue={(this.state.case.injury_type == '-999' ? '' : this.state.case.injury_type) || ''}
                                                        style={this.state.case_form_invalid.case.injury_type && this.state.form_submited ? "red-error-thin" : ''}
                                                        disabled={this.state.page_state === enum_case_page_state.edit}
                                                        hidden={true}
                                                    />
                                                   
                                                    
                                                }
                                                <p className={this.state.case_form_invalid.case.injury_type && this.state.form_submited ? "required-place-dropdown req-alert" : 'required-place-dropdown'}><i>{case_messages.required_for_billing_text} </i></p>
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Header as='h5' className="small form_section_heading">
                                        Dates
                                    </Header>
                                    <Grid>
                                        <GridColumn tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>Injury/Onset Date</label>
                                                <DatePicker id="case_onset_date" is_disabled={this.state.page_state === enum_case_page_state.edit} disabledElement={this.state.page_state === enum_case_page_state.edit} maxDate={moment().format('MM/DD/YYYY')} date_update={this.handle_calendar_change_for_injury} date={this.state.case.formatted_injury_date} is_required={this.state.case_form_invalid.case.formatted_injury_date && this.state.form_submited} />
                                            </Form.Field>
                                        </GridColumn>

                                        <GridColumn tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>Start of Care Date</label>
                                                <DatePicker id="case_start_of_care_date" is_disabled={this.state.page_state === enum_case_page_state.edit} maxDate={moment().format('MM/DD/YYYY')} date_update={this.handle_calendar_change_for_start_of_care} date={this.state.case.formatted_start_of_care_date} is_required={this.state.case_form_invalid.case.formatted_start_of_care_date && this.state.form_submited} />
                                            </Form.Field>
                                        </GridColumn>

                                        <GridColumn tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>Discharge Date</label>
                                                <DatePicker id="case_discharge_date" is_disabled={this.state.page_state === enum_case_page_state.edit} maxDate={moment().format('MM/DD/YYYY')} date_update={this.handle_calendar_change_for_discharge} date={this.state.case.formatted_discharge_date} is_required={this.state.case_form_invalid.case.formatted_discharge_date && this.state.form_submited} />
                                            </Form.Field>
                                        </GridColumn>
                                        <GridColumn tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>Assignment Date <span className={this.state.case_form_invalid.case.formatted_assignment_date && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span></label>
                                                <DatePicker id="case_assignment_date" is_disabled={this.state.page_state === enum_case_page_state.edit} maxDate={moment().format('MM/DD/YYYY')} date_update={this.handle_calendar_change_for_assignment} date={this.state.case.formatted_assignment_date} is_required={this.state.case_form_invalid.case.formatted_assignment_date && this.state.form_submited} />
                                                <p className={this.state.case_form_invalid.case.formatted_assignment_date && this.state.form_submited ? "required-place-dropdown req-alert" : 'required-place-dropdown'}><i>{case_messages.required_for_billing_text} </i></p>
                                            </Form.Field>
                                        </GridColumn>
                                        <GridColumn tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>Case Effective Date <span className={this.state.case_form_invalid.case.formatted_effective_date && this.state.form_submited ? "req-alert" : 'req-alert_normal'}> (required)</span></label>
                                                <DatePicker id="effective_date" is_disabled={this.state.page_state === enum_case_page_state.edit} maxDate={moment().format('MM/DD/YYYY')} date_update={this.handle_calendar_change_for_effective} date={this.state.case.formatted_effective_date} is_required={this.state.case_form_invalid.case.formatted_effective_date && this.state.form_submited} />
                                                <p className={this.state.case_form_invalid.case.formatted_effective_date && this.state.form_submited ? "required-place-dropdown req-alert" : 'required-place-dropdown'}><i>{case_messages.required_for_billing_text} </i></p>
                                            </Form.Field>
                                        </GridColumn>
                                        <GridColumn tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>Next Doctor Visit</label>

                                                <DatePicker id="case_next_visit_date" is_disabled={this.state.page_state === enum_case_page_state.edit} date_update={this.handle_calendar_change_for_next_visit} date={this.state.case.formatted_next_visit_date} is_required={this.state.case_form_invalid.case.formatted_next_visit_date && this.state.form_submited} />
                                            </Form.Field>
                                        </GridColumn>
                                    </Grid>
                                    <Header as='h4'>
                                        Patient's Physicians
                                    </Header>
                                    <Grid>
                                        <GridColumn mobile={16} tablet={16} computer={16}>
                                            <Form.Field className="advance-quick-search case-reg-form referring-physician">
                                                <label>Referring Physician <span className={this.state.case_form_invalid.case.referring_physician && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span></label>
                                                <AdvancedControl showModal={this.state.show_primary_advanced_control}
                                                    set_parent_show_modal_state={this.set_parent_show_modal_state}
                                                    addClicks={this.show_add_referring_modal_primary_physician}
                                                    disabled_element={this.state.page_state === enum_case_page_state.edit}
                                                    onGridRowSelection={this.on_primary_physician_grid_row_selection}
                                                    gridConfig={this.state.primary_physician_search_data}
                                                    controlId={global_constants.constants.advanced_control_type.referring_physician}
                                                    onSearch={this.on_referring_Physician_search}
                                                    search_type={'REFERRING_PHYSICIAN'}
                                                    headerIdForGridTabNavigation={enum_type_of_search.referring_physician}/>

                                                <AutoSearchComponent on_blur={this.on_blur_auto_search_referring_physician}
                                                    getInputValue={this.get_input_value_referring_physician} default_value={this.state.referring_physician_quick_search_format}
                                                    errorMessage={'No Physician Found !'} prepareRenderList={this.render_suggestion_result} getList={this.get_referring_physician_quick_search_data_list}
                                                    prepareDataList={this.prepare_suggestion_referring_physician} selectresult={this.on_item_selection_referring_physician} is_focus={false}
                                                    is_disabled={this.state.page_state === enum_case_page_state.edit} show_clear_search={true} clear_search={this.clear_quick_search_referring_physician}
                                                    errorClass={this.state.case_form_invalid.case.referring_physician && this.state.form_submited ? 'search-error-thin' : ''}
                                                    control_id={'auto' + global_constants.constants.advanced_control_type.referring_physician}
                                                />
                                                <p className={this.state.case_form_invalid.case.referring_physician && this.state.form_submited ? "required-place-dropdown req-alert" : 'required-place-dropdown'}><i>{case_messages.required_for_billing_text} </i></p>
                                            </Form.Field>
                                        </GridColumn>
                                        
                                        <GridColumn mobile={16} tablet={16} computer={16}>
                                            <Form.Field className="advance-quick-search second-referring-physician">
                                                <label>Second Referring Physician</label>
                                                <AdvancedControl set_parent_show_modal_state={this.set_parent_show_modal_state}
                                                    showModal={this.state.show_secondary_advanced_control}
                                                    addClicks={this.show_add_referring_modal_secondary_physician}
                                                    disabled_element={this.state.page_state === enum_case_page_state.edit}
                                                    onGridRowSelection={this.on_secondary_physician_grid_row_selection}
                                                    gridConfig={this.state.second_physician_search_data}
                                                    controlId={global_constants.constants.advanced_control_type.second_referring_physician}
                                                    onSearch={this.on_second_referring_Physician_search}                                                    
                                                    search_type={'SECOND_REFERRING_PHYSICIAN'}
                                                    headerIdForGridTabNavigation={enum_type_of_search.second_referring_physician}/>
                                                <AutoSearchComponent
                                                    getInputValue={this.get_input_value_second_referring_physician} default_value={this.state.second_referring_physician_quick_search_format}
                                                    errorMessage={'No Physician Found !'} prepareRenderList={this.render_suggestion_result} getList={this.get_referring_physician_quick_search_data_list}
                                                    prepareDataList={this.prepare_suggestion_second_referring_physician} selectresult={this.on_item_selection_second_referring_physician} is_focus={false}
                                                    is_disabled={this.state.page_state === enum_case_page_state.edit} show_clear_search={true} clear_search={this.clear_quick_search_second_referring_physician} />
                                            </Form.Field>
                                        </GridColumn>
                                    </Grid>

                                    <Header as='h5' className="small form_section_heading">
                                        Location and Provider
                                    </Header>

                                    <Grid>
                                        <GridColumn mobile={16} tablet={16} computer={16}>
                                            <Form.Field className="advance-quick-search case-reg-form location">
                                                <label>Location<span className={this.state.case_form_invalid.case.place_of_service && this.state.form_submited ? "req-alert" : 'req-alert_normal'}> (required)</span> </label>
                                                <AdvancedControl
                                                    disabled_element={this.state.page_state === enum_case_page_state.edit}
                                                    onGridRowSelection={this.on_location_grid_row_selection}
                                                    gridConfig={this.state.location_search_data}
                                                    controlId={global_constants.constants.advanced_control_type.location}
                                                    onSearch={this.on_location_search}
                                                    search_type={'LOCATION'} 
                                                    headerIdForGridTabNavigation={enum_type_of_search.place_of_service}
                                                />
                                                <AutoSearchComponent on_blur={this.on_blur_auto_search_place_of_service}
                                                    getInputValue={this.get_input_value_place_of_service} default_value={this.state.location_quick_search_format}
                                                    errorMessage={'No Location Found !'} prepareRenderList={this.render_suggestion_result} getList={this.get_location_quick_search_data_list}
                                                    prepareDataList={this.prepare_suggestion_place_of_service} selectresult={this.on_item_selection_place_of_service} is_focus={false}
                                                    is_disabled={this.state.page_state === enum_case_page_state.edit} show_clear_search={true} clear_search={this.clear_quick_search_place_of_service}
                                                    errorClass={this.state.case_form_invalid.case.place_of_service && this.state.form_submited ? 'search-error-thin' : ''}
                                                    control_id={'auto' + global_constants.constants.advanced_control_type.location}
                                                />
                                                <p className={this.state.case_form_invalid.case.place_of_service && this.state.form_submited ? "required-place-dropdown req-alert" : 'required-place-dropdown'}><i>{case_messages.required_for_billing_text} </i></p>
                                            </Form.Field>

                                        </GridColumn>
                                        <GridColumn mobile={16} tablet={16} computer={16}>
                                            <Form.Field className="advance-quick-search case-reg-form billing-provider">
                                                <label>Billing Provider<span className={this.state.case_form_invalid.case.provider && this.state.form_submited ? "req-alert" : 'req-alert_normal'}> (required)</span></label>
                                                <AdvancedControl
                                                    disabled_element={this.state.page_state === enum_case_page_state.edit}
                                                    onGridRowSelection={this.on_provider_grid_row_selection}
                                                    gridConfig={this.state.provider_search_data}
                                                    controlId={global_constants.constants.advanced_control_type.provider}
                                                    onSearch={this.on_provider_search}
                                                    search_type={'BILLING_PROVIDER'}
                                                    headerIdForGridTabNavigation={enum_type_of_search.provider}/>
                                                <AutoSearchComponent
                                                    on_blur={this.on_blur_auto_search_provider}
                                                    getInputValue={this.get_input_value_provider}
                                                    default_value={this.state.provider_quick_search_format}
                                                    errorMessage={'No Billing Provider Found !'}
                                                    prepareRenderList={this.render_suggestion_result}
                                                    getList={this.get_provider_quick_search_data_list}
                                                    prepareDataList={this.prepare_suggestion_provider}
                                                    selectresult={this.on_item_selection_provider}
                                                    is_focus={false}
                                                    is_disabled={this.state.page_state === enum_case_page_state.edit}
                                                    show_clear_search={true} clear_search={this.clear_quick_search_provider}
                                                    errorClass={this.state.case_form_invalid.case.provider && this.state.form_submited ? 'search-error-thin' : ''}
                                                    control_id={'auto' + global_constants.constants.advanced_control_type.provider}
                                                />
                                                <p className={this.state.case_form_invalid.case.provider && this.state.form_submited ? "required-place-dropdown req-alert" : 'required-place-dropdown'}><i>{case_messages.required_for_billing_text} </i></p>
                                            </Form.Field>
                                        </GridColumn>

                                        <Grid.Column mobile={8} tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>Discipline <span className={this.state.case_form_invalid.case.descipline && this.state.form_submited ? "req-alert" : 'req-alert_normal'}>(required)</span></label>
                                                {
                                                    <Selection
                                                        name='discipline'
                                                        id="txt_discipline"
                                                        options={this.state.master_data.disciplines}
                                                        onChange={this.on_case_info_change_for_discipline}
                                                        defaultValue={this.state.case.discipline || ''}
                                                        style={this.state.case_form_invalid.case.descipline && this.state.form_submited ? "red-error-thin dropdown-options-wrap" : 'dropdown-options-wrap'}
                                                        //isRequired={this.state.error.state ? true : false}
                                                        disabled={this.state.page_state === enum_case_page_state.edit}
                                                    />
                                                    //<Dropdown disabled={this.state.page_state === enum_case_page_state.edit} placeholder='Select' fluid selection options={this.state.master_data.disciplines} value={this.state.case.discipline || ''}
                                                    //    onChange={(e, { value }) => { this.on_case_info_change(e, { name: "discipline", value: value }) }}
                                                    //    className={this.state.case_form_invalid.case.descipline && this.state.form_submited ? "red-error-thin dropdown-options-wrap" : 'dropdown-options-wrap'}
                                                    ///>
                                                }
                                                <p className={this.state.case_form_invalid.case.descipline && this.state.form_submited ? "required-place-dropdown req-alert" : 'required-place-dropdown'}><i>{case_messages.required_for_billing_text} </i></p>
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>

                                <Grid.Column className="mar-b-20" mobile={16} tablet={16} computer={8}>
                                    <Header as='h4' className="mar-t-15">
                                        &nbsp;
                                    </Header>
                                    <Grid>
                                        <Grid.Column mobile={16} tablet={16} computer={16}>
                                            <Form.Field>
                                                <label>Accident Location</label>
                                                {
                                                    <Selection
                                                        name='accident_location'
                                                        id="case_accident_location"
                                                        //placeHolder=''
                                                        options={this.state.master_data.accident_location}
                                                        onChange={this.on_case_info_change_for_accident_location}
                                                        defaultValue={this.state.case.accident_location || ''}
                                                        //style={this.state.case_form_invalid.case.descipline && this.state.form_submited ? "red-error-thin dropdown-options-wrap" : 'dropdown-options-wrap'}
                                                        //isRequired={this.state.error.state ? true : false}
                                                        disabled={this.state.page_state === enum_case_page_state.edit}
                                                    />
                                                    //<Dropdown id="case_accident_location" placeholder='Select' disabled={this.state.page_state === enum_case_page_state.edit} fluid selection options={this.state.master_data.accident_location} value={this.state.case.accident_location || ''}
                                                    //    onChange={(e, { value }) => { this.on_case_info_change(e, { name: "accident_location", value: value }) }}
                                                    //    className={''}
                                                    ///>
                                                }
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>

                                    <Header as='h5' className="small form_section_heading">
                                        Internal Information
                                    </Header>

                                    <Grid>
                                        <Grid.Column mobile={8} tablet={8} computer={8}>
                                            <Form.Field>
                                                <Form.Field>
                                                    <Checkbox disabled={this.state.page_state === enum_case_page_state.edit} id="case_special_billing" label='Use Special Billing' name='txt_use_special_billing' checked={this.state.case.special_billing || false} onChange={this.on_change_special_billing} />
                                                </Form.Field>
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column mobile={8} tablet={8} computer={8}>
                                            <Form.Field className="advance-quick-search">
                                                <label>Alternate Referral Source</label>
                                                <AdvancedControl
                                                    set_parent_show_modal_state={this.set_parent_show_modal_state}
                                                    addClicks={this.show_add_referring_source_modal}
                                                    disabled_element={this.state.page_state === enum_case_page_state.edit}
                                                    onGridRowSelection={this.on_referral_source_row_selection}
                                                    gridConfig={this.state.referral_search_data}
                                                    controlId={global_constants.constants.advanced_control_type.referral_source}
                                                    onSearch={this.on_referral_source_search}
                                                    showModal={this.state.show_refferal_advanced_control}
                                                    search_type={'ALTERNATE_REFERRAL_SOURCE'}
                                                    headerIdForGridTabNavigation={enum_type_of_search.referral_source}
                                                />
                                                <AutoSearchComponent
                                                    getInputValue={this.get_input_value_referral_source}
                                                    default_value={this.state.referral_source_quick_search_format}
                                                    errorMessage={"No Referral Source Found !"}
                                                    prepareRenderList={this.render_suggestion_result}
                                                    getList={this.get_referral_source_quick_search_data_list}
                                                    prepareDataList={this.prepare_suggestion_referral_source}
                                                    selectresult={this.on_item_selection_referral_source}
                                                    is_focus={false}
                                                    is_disabled={this.state.page_state === enum_case_page_state.edit}
                                                    show_clear_search={true}
                                                    clear_search={this.clear_quick_search_referral_source}
                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column mobile={8} tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>Referral Date</label>
                                                <DatePicker id="case_referral_date" is_disabled={this.state.page_state === enum_case_page_state.edit} date_update={this.handle_calendar_change_for_referral} date={this.state.case.formatted_referral_date} is_required={this.state.case_form_invalid.case.formatted_referral_date && this.state.form_submited} />
                                            </Form.Field>

                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>Referral Status</label>
                                                {
                                                    <Selection
                                                        name='referral_status'
                                                        id="case_referral_status"
                                                        placeHolder='Select'
                                                        options={this.state.master_data.referral_status}
                                                        onChange={this.on_case_info_change_for_referral_status}
                                                        defaultValue={this.state.case.referral_status ? this.state.case.referral_status.toString() : '' || ''}
                                                        //style={this.state.case_form_invalid.case.descipline && this.state.form_submited ? "red-error-thin dropdown-options-wrap" : 'dropdown-options-wrap'}
                                                        //isRequired={this.state.error.state ? true : false}
                                                        disabled={this.state.page_state === enum_case_page_state.edit}
                                                        hidden={true}
                                                    />
                                                    //<Dropdown id="case_referral_status" disabled={this.state.page_state === enum_case_page_state.edit} placeholder='Select' fluid selection options={this.state.master_data.referral_status} value={this.state.case.referral_status ? this.state.case.referral_status.toString() : '' || ''}
                                                    //    onChange={(e, { value }) => { this.on_case_info_change(e, { name: "referral_status", value: value }) }}

                                                    ///>
                                                }
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column mobile={8} tablet={8} computer={8}>
                                            <Form.Field className="advance-quick-search">
                                                <label>Special Program</label>
                                                <AdvancedControl
                                                    disabled_element={this.state.page_state === enum_case_page_state.edit}
                                                    onGridRowSelection={this.on_special_program_row_selection}
                                                    gridConfig={this.state.special_program_search_data}
                                                    controlId={global_constants.constants.advanced_control_type.special_program}
                                                    onSearch={this.on_special_program_search}
                                                    search_type={'SPECIAL_PROGRAMME'}
                                                    headerIdForGridTabNavigation={enum_type_of_search.special_program}
                                                />
                                                <AutoSearchComponent
                                                    getInputValue={this.get_input_value_special_program} default_value={this.state.special_program_quick_search_format}
                                                    errorMessage={'No Record Found !'} prepareRenderList={this.render_suggestion_result} getList={this.get_special_program_quick_search_data_list}
                                                    prepareDataList={this.prepare_suggestion_special_program} selectresult={this.on_item_selection_special_program} is_focus={false}
                                                    is_disabled={this.state.page_state === enum_case_page_state.edit} show_clear_search={true} clear_search={this.clear_quick_search_special_program} />

                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column mobile={8} tablet={8} computer={8}>
                                            <Form.Field>
                                                <label>Patient Contact</label>
                                                {
                                                    <Selection
                                                        name='patient_contact'
                                                        id="case_patient_contact"
                                                        options={this.state.master_data.patient_contract}
                                                        onChange={this.on_case_info_change_for_note_code}
                                                        defaultValue={this.state.case.note_code || ''}
                                                        style={this.state.case_form_invalid.case.patient_contact && this.state.form_submited ? "req-background-inp" : ''}
                                                        //isRequired={this.state.error.state ? true : false}
                                                        disabled={this.state.page_state === enum_case_page_state.edit}
                                                    />
                                                    //<Dropdown id="case_patient_contact" disabled={this.state.page_state === enum_case_page_state.edit} placeholder='Select'
                                                    //    fluid selection options={this.state.master_data.patient_contract} value={this.state.case.note_code || ''}
                                                    //    onChange={(e, { value }) => { this.on_case_info_change(e, { name: "note_code", value: value }) }}
                                                    //    className={this.state.case_form_invalid.case.patient_contact && this.state.form_submited ? "req-background-inp" : ''}

                                                    ///>
                                                }
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={16} computer={16}>
                                            <Form.Field>
                                                <label>Patient Contact Comments</label>
                                                <TextareaAutosize
                                                    autoComplete="new-password" maxLength={250}
                                                    disabled={this.state.page_state === enum_case_page_state.edit}
                                                    id='txt_message'
                                                    name='txt_patient_contact_comment' value={this.state.case.patient_contact_comment || ''}
                                                    onChange={
                                                        e => { this.on_case_info_change(e) }
                                                    }
                                                    className={this.state.case_form_invalid.case.patient_contact_comment && this.state.form_submited ? "req-background-inp" : ''}

                                                />
                                            </Form.Field>

                                        </Grid.Column>
                                        {this.state.case.id > 0 ?
                                            <Grid.Column mobile={16} tablet={16} computer={16} >
                                                <Table basic="very" className="default-table">
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.Cell>Created by </Table.Cell>
                                                            <Table.Cell>{this.state.case.created_audit ? this.state.case.created_audit.name ? this.state.case.created_audit.name.toString() : '' : ''}</Table.Cell>
                                                            <Table.Cell>on</Table.Cell>
                                                            <Table.Cell>{this.state.case.created_audit ? this.state.case.created_audit.date ? this.state.case.created_audit.date.toString() : '' : ''}</Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell>Modified by</Table.Cell>
                                                            <Table.Cell>{this.state.case.updated_audit ? this.state.case.updated_audit.name ? this.state.case.updated_audit.name.toString() : '' : ''}</Table.Cell>
                                                            <Table.Cell>on</Table.Cell>
                                                            <Table.Cell>{this.state.case.updated_audit ? this.state.case.updated_audit.date ? this.state.case.updated_audit.date.toString() : '' : ''}</Table.Cell>
                                                        </Table.Row>
                                                    </Table.Body>
                                                </Table>
                                            </Grid.Column>
                                            : ""}

                                    </Grid>
                                </Grid.Column>

                                {
                                    //this.state.page_state == enum_case_page_state.edit ?
                                    <GridColumn style={style_margin_diagnosis} mobile={16} tablet={16} computer={16}>
                                        <DiagnosisComponent page_state={this.state.page_state} case_id={this.state.case.id}
                                            source_type={diagnosis_constant.dx_type.r5_case}
                                            show_sequence={false}
                                            is_dragging={(this.state.page_state == enum_case_page_state.add) ? true : false}
                                            onRef={this.setup_diagnosis_reference} case_data={this.state.case} />
                                    </GridColumn>
                                    //: ""
                                }

                                <br></br>
                                {this.state.page_state == enum_case_page_state.add || this.state.is_primary_insurance_show ?

                                    <Grid.Column mobile={16} tablet={16} computer={16} id="primary_insurance">
                                        <div className="common-forms-view">
                                            <InsuranceComponent insurance_types={this.state.insurance_types} insurance_contract={this.state.insurance_contract} onRef={this.setup_primary_insurance_reference} shown={this.state.show_primary_insurance} page_state={this.state.page_state} get_show_status_insurance={this.get_show_status_primary_insurance} form_submitted={this.state.form_submited} patient_id={this.state.patient_id} get_update_subscriber_info={this.get_update_subscriber_info} case_id={this.state.case.id} ins_type={enum_type_of_insurance.primary_insurance} />
                                        </div>
                                    </Grid.Column>
                                    : ''
                                }

                                {((!this.state.is_insurance_worker_comp && this.state.is_secondary_insurance_show) || (!this.state.is_insurance_worker_comp && this.state.page_state == enum_case_page_state.add)) ?
                                    <Grid.Column mobile={16} tablet={16} computer={16} id="secondary_insurance">
                                        <div className="common-forms-view">

                                            <InsuranceComponent insurance_types={this.state.insurance_types} insurance_contract={this.state.insurance_contract} onRef={this.setup_secondary_insurance_reference} shown={this.state.show_secondary_insurance} page_state={this.state.page_state} get_show_status_insurance={this.get_show_status_secondary_insurance} form_submitted={this.state.form_submited} patient_id={this.state.patient_id} get_update_subscriber_info={this.get_update_subscriber_info} case_id={this.state.case.id} ins_type={enum_type_of_insurance.secondary_insurance} />


                                        </div>
                                    </Grid.Column> : ''
                                }
                                {((!this.state.is_insurance_worker_comp && this.state.is_tertiary_insurance_show) || (!this.state.is_insurance_worker_comp && this.state.page_state == enum_case_page_state.add)) ?
                                    <Grid.Column mobile={16} tablet={16} computer={16} id="tertiary_insurance">
                                        <div className="common-forms-view">

                                            <InsuranceComponent insurance_types={this.state.insurance_types} insurance_contract={this.state.insurance_contract} onRef={this.setup_tertiary_insurance_reference} shown={this.state.show_tertiary_insurance} page_state={this.state.page_state} get_show_status_insurance={this.get_show_status_tertiary_insurance} form_submitted={this.state.form_submited} patient_id={this.state.patient_id} get_update_subscriber_info={this.get_update_subscriber_info} case_id={this.state.case.id} ins_type={enum_type_of_insurance.tertiary_insurance} />



                                        </div>
                                    </Grid.Column> : ''
                                }


                                {this.state.case.id > 0 &&
                                    <VerificationFormComponent shown={this.state.show_verification_form} patient_id={this.state.patient_id} case_id={this.state.case.id} />
                                }

                                {(this.state.is_conditional_billing_show) ?
                                    <Grid.Column mobile={16} tablet={16} computer={16} id="conditional_billing">
                                        <div className="common-forms-view">
                                            <ConditionalBillingComponent
                                                shown={this.state.show_conditional_billing}
                                                page_state={this.state.page_state}
                                                case_data={this.state.case}
                                                onRef={this.setup_conditional_billing_reference}
                                            />
                                        </div>
                                    </Grid.Column> : ''
                                }

                                <Grid.Column style={style_margin} mobile={16} tablet={16} computer={16}>
                                    <p className="instructinfo">Fields marked with <strong className={'font_bold'}>"required"</strong> must be completed before you can save. Fields marked with <strong className={'font_bold'}>"required for billing"</strong> do not need to be completed in order to save. However, you cannot bill charges for this patient until these fields are completed.</p>
                                </Grid.Column>

                                <Grid.Column style={style_margin} className="mar-t-20" mobile={16} tablet={16} computer={16} >
                                    <Form.Field>
                                        <Checkbox disabled={this.state.page_state === enum_case_page_state.edit || (this.state.page_state === enum_case_page_state.add && this.state.case.has_active_charge)} label='Case Active' checked={this.state.case.is_active} onChange={this.on_change_active_case} />
                                    </Form.Field>
                                </Grid.Column>


                            </Grid>

                        </Form>

                    </div>
                    {
                        this.state.page_state === enum_case_page_state.edit ?
                            <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area" id="applicationFooterSticky">
                                <Button id="patient_edit_btn" type='submit' primary onClick={this.on_case_edit_click}>Edit Case</Button>
                            </div>
                            :
                            <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area" id="applicationFooterSticky">
                                <Button
                                    id="patient_cancel_btn"
                                    basic
                                    onClick={this.on_case_cancel_click}>Cancel</Button>
                                {this.state.case.id > 0 && (
                                    <>
                                        <Button
                                            id="modal-action-default-btn"
                                            type="button"
                                            primary
                                            onClick={() => this.on_case_save_edit_case(this.props.case_editing_url_redirect.redirectPageDefaultValue)}>Save</Button>
                                        <div aria-expanded="false" className="ui dropdown" tabIndex={0} onBlur={this.onBlurHandler} onClick={this.openOptionsList}>
                                            <Button primary id="modal-action-btn">
                                                <i className="icon-icemoon icon-show-more position-icon-more" />
                                            </Button>
                                            <div className={this.state.statusOptionList ? 'save-case-more-container' : 'save-case-more-container-hidden'} tabIndex={1} onBlur={this.onBlurHandler} >
                                                <ul className="save-more-container-options">
                                                    <li className={this.state.claim_indicator ? 'save-more-container-options-li' : 'save-more-container-options-li-disabled disabled-style'} role="item" tabIndex={2} onClick={() => this.on_case_save_edit_case(this.props.case_editing_url_redirect.redirectPageClaimIndicator)}>
                                                        <a className={this.state.claim_indicator ? '' : 'save-more-option-label-name'}>Save and go to Claim Indicator</a>
                                                    </li>
                                                    <li className={this.state.demand_claim ? 'save-more-container-options-li' : 'save-more-container-options-li-disabled disabled-style'} role="item" tabIndex={3} onClick={() => this.on_case_save_edit_case(this.props.case_editing_url_redirect.redirectPageDemandClaims)}>
                                                        <a className={this.state.demand_claim ? '' : 'save-more-option-label-name'}>Save and go to Demand Claims</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {this.state.case.id == 0 && (
                                    <Button
                                        id="patient_save_btn"
                                        onKeyDown={set_focus_to_app_header}
                                        type="submit"
                                        disabled={this.state.is_save_button_disabled}
                                        primary
                                        onClick={(e) => {this.on_case_save();}}>Save</Button>
                                )}
                            </div>
                    }
                </div >

                <Modal
                    onClose={this.close_new_pre_auth_red_window_modal}
                    centered={false}
                    className=" default-modal"
                    open={this.show_new_pre_auth_red_windo_modal}

                    id="new_pre_auth_red_window"
                    closeOnDimmerClick={false}
                >

                    <Modal.Header>
                        <h3>Preauthorization </h3>
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <div><label>{this.state.rad_window_type._current_pre_auth_tab == 'u' ? 'Units' : 'Visits'}</label></div>
                            <div className="common-forms-add" style={{ padding: "0" }}>

                                <div className="mar-t-5 customtable">
                                    <div className="table-responsive" id="new_pre_auth_red_window_container">
                                        <Table id="new_pre_auth_red_window">
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell></Table.HeaderCell>
                                                    <Table.HeaderCell></Table.HeaderCell>
                                                    <Table.HeaderCell></Table.HeaderCell>
                                                    <Table.HeaderCell></Table.HeaderCell>
                                                    <Table.HeaderCell></Table.HeaderCell>
                                                    <Table.HeaderCell colSpan={3} textAlign="center" className="col-group-gray">Preauthorizations</Table.HeaderCell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.HeaderCell style={{ width: '135px', minWidth: '135px' }}>Date of Service</Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '120px', minWidth: '120px' }}>Amount Owed</Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '110px', minWidth: '110px' }}>Provider</Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '110px', minWidth: '110px' }}>Location</Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '70px', minWidth: '70px' }}>Units</Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '230px', minWidth: '230px' }} className="col-group-gray">Primary Preauthorizations</Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '230px', minWidth: '230px' }} className="col-group-gray">Secondary Preauthorizations</Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '230px', minWidth: '230px' }} className="col-group-gray">Tertiary Preauthorizations</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            {this.state.new_pre_auth_data.length > 0 &&
                                                <Table.Body>
                                                {
                                                    rad_window_save_disable ?
                                                        <Table.Row key="error_row">
                                                            <Table.Cell textAlign='center' colSpan={8}>{"There are no Visits left to apply Unit preauthorizations to."}</Table.Cell></Table.Row>
                                                     :
                                                    this.state.new_pre_auth_data.map((item: any, index: number) => {
                                                        if (!item.is_processed) {
                                                            return (
                                                                <Table.Row key={index}>
                                                                    <Table.Cell>{custom_date_format(item.date_of_service, global_constants.date_format["mm/dd/yyyy"])}</Table.Cell>
                                                                    <Table.Cell>
                                                                        <NumberFormat value={item.owed_amt} fixedDecimalScale={true} decimalScale={2}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>} />
                                                                    </Table.Cell>
                                                                    <Table.Cell>{item.billing_province}</Table.Cell>
                                                                    <Table.Cell>{item.pos_code}</Table.Cell>
                                                                    <Table.Cell>{item.units}</Table.Cell>

                                                                    <Table.Cell>
                                                                        {
                                                                            <div className="new_pre_auth_red_window_select">
                                                                                <Selection
                                                                                    name={'red_window_pri_auth_select_' + index}
                                                                                    id={'red_window_pri_auth_select_' + index}
                                                                                    options={formate_new_pre_auth_row(item.pri_pre_auth)}
                                                                                    onChange={value => this.change_new_pre_auth_red_window(value, item, index, 'pri_pre_auth_selected_unique_id')}
                                                                                    defaultValue={item.pri_pre_auth_selected_unique_id}
                                                                                    onFocus={(e) => this.new_pre_auth_red_window_select_focus(e, 1)}
                                                                                />
                                                                            </div>
                                                                        }


                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        {
                                                                            <div className="new_pre_auth_red_window_select">
                                                                                <Selection
                                                                                    name={'red_window_sec_auth_select_' + index}
                                                                                    id={'red_window_sec_auth_select_' + index}
                                                                                    options={formate_new_pre_auth_row(item.sec_pre_auth)}
                                                                                    onChange={value => this.change_new_pre_auth_red_window(value, item, index, 'sec_pre_auth_selected_unique_id')}
                                                                                    defaultValue={item.sec_pre_auth_selected_unique_id}
                                                                                    onFocus={(e) => this.new_pre_auth_red_window_select_focus(e, 1)}
                                                                                />
                                                                            </div>
                                                                        }

                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        {
                                                                            <div className="new_pre_auth_red_window_select">
                                                                                <Selection
                                                                                    name={'red_window_ter_auth_select_' + index}
                                                                                    id={'red_window_ter_auth_select_' + index}
                                                                                    options={formate_new_pre_auth_row(item.ter_pre_auth)}
                                                                                    onChange={value => this.change_new_pre_auth_red_window(value, item, index, 'ter_pre_auth_selected_unique_id')}
                                                                                    defaultValue={item.ter_pre_auth_selected_unique_id}
                                                                                    onFocus={(e) => this.new_pre_auth_red_window_select_focus(e, 1)}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            )
                                                        }
                                                      
                                                        })
                                                    }
                                                </Table.Body>
                                            }
                                        </Table>
                                    </div>
                                </div>


                            </div>
                            <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area padd-r-0">
                                <Button id="patient_save_btn"  type="button" primary onClick={() => this.on_button_click()}>
                                    {this.state.rad_win_btn_text}
                                </Button>
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </React.Fragment>
        )

    }

}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        //get_patient_search: get_patient_search
        update_patient_header_info: update_patient_header_info,
        set_active_patient: set_active_patient,
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        user_details: state.user_login_details,
        patient_details: state.patient_details,
        shared_details: state.shared_details,
        case_editing_url_redirect: {
            redirectPageDefaultValue: caseEditingRedirect.redirectPageDefaultValue,
            redirectPageClaimIndicator: caseEditingRedirect.redirectPageClaimIndicator,
            redirectPageDemandClaims: caseEditingRedirect.redirectPageDemandClaims,
            claimIndicatorMenuId: caseEditingRedirect.claimIndicatorMenuId,
            demandClaimMenuId: caseEditingRedirect.demandClaimMenuId,
            menuName: caseEditingRedirect.menuName,
            menuId: caseEditingRedirect.menuId,
            defaultR6ParentId: caseEditingRedirect.defaultR6ParentId,
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CaseRegistrationComponent));
