
import * as React from "react";
import { ItemRenderer, MultiSelect } from "@blueprintjs/select";
import { Button, ITagProps, IProps, MenuItem } from "@blueprintjs/core";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";
import {
    areItemsEqual,
    arrayContainsItem,
    createItem,
    itemSelectProps,
    IData,
    maybeAddCreatedItemToArrays,
    maybeDeleteCreatedItemFromArrays,
    renderCreateItemOption,
    ITEMS,
} from "./multi_select_utility";

export interface IMultiSelectState {
    allowCreate: boolean;
    createdItems: IData[];
    fill: boolean;
    selectedItems: IData[];
    hasInitialContent: boolean;
    intent: boolean;
    items: IData[];
    openOnKeyDown: boolean;
    popoverMinimal: boolean;
    resetOnSelect: boolean;
    tagMinimal: boolean;
}

export interface IMultiProps<T = {}> extends IProps {
    id: string;
    data?: T;
    onChange?: (items: IData[]) => void;
    onRef: any;
}

const ItemMultiSelect = MultiSelect.ofType<IData>();

export class MultiSelectComponent extends React.PureComponent<IMultiProps, IMultiSelectState> {

    public state: IMultiSelectState = {
        allowCreate: false,
        createdItems: [],
        fill: false,
        selectedItems: [],
        hasInitialContent: false,
        intent: false,
        items: itemSelectProps.items,
        openOnKeyDown: false,
        popoverMinimal: true,
        resetOnSelect: true,
        tagMinimal: true,
    };

    private renderTag = (item: IData) => item.title;

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    //Render View
    public render() {
        const { allowCreate, selectedItems, hasInitialContent, tagMinimal, popoverMinimal, ...flags } = this.state;
        const getTagProps = (_value: string, index: number): ITagProps => ({
            minimal: tagMinimal,
            className: "tag-item-style",
        });

        const initialContent = this.state.hasInitialContent ? (
            <MenuItem disabled={true} text={`${ITEMS.length} items loaded.`} />
        ) : (
                // explicit undefined (not null) for default behavior (show full list)
                undefined
            );
        const maybeCreateNewItemFromQuery = allowCreate ? createItem : undefined;
        const maybeCreateNewItemRenderer = allowCreate ? renderCreateItemOption : null;

        const clearButton =
            selectedItems.length > 0 ? <Button icon="cross" minimal={true} onClick={this.handleClear} /> : undefined;

        return (<ItemMultiSelect
            {...itemSelectProps}
            {...flags}
            createNewItemFromQuery={maybeCreateNewItemFromQuery}
            createNewItemRenderer={maybeCreateNewItemRenderer}
            initialContent={initialContent}
            itemRenderer={this.renderItem}
            itemsEqual={areItemsEqual}
            // we may customize the default filmSelectProps.items by
            // adding newly created items to the list, so pass our own
            items={this.state.items}
            noResults={<MenuItem disabled={true} text="No results." />}
            onItemSelect={this.handleItemSelect}
            onItemsPaste={this.handleItemsPaste}
            popoverProps={{ minimal: popoverMinimal }}
            tagRenderer={this.renderTag}
            tagInputProps={{ tagProps: getTagProps, onRemove: this.handleTagRemove, rightElement: clearButton, placeholder: 'View' }}
            selectedItems={this.state.selectedItems}
        />);
    }

    // NOTE: not using Items.itemRenderer here so we can set icons.
    private renderItem: ItemRenderer<IData> = (item, { modifiers, handleClick }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        return (
            <MenuItem
                active={modifiers.active}
                icon={<i aria-hidden="true" className={`${this.isItemSelected(item) ? 'check square outline' : 'square outline'} small icon`}></i>}
                key={item.title}
                onClick={handleClick}
                text={item.title}
                className="custom-item"
                shouldDismissPopover={false}
            />
        );
    };

    private handleTagRemove = (_tag: string, index: number) => {
        this.deselectItem(index);
    };

    private getSelectedItemIndex(item: IData) {
        return this.state.selectedItems.indexOf(item);
    }

    private isItemSelected(item: IData) {
        return this.getSelectedItemIndex(item) !== -1;
    }

    private selectItem(item: IData) {
        this.selectItems([item]);
    }

    private selectItems(itemsToSelect: IData[]) {
        const { createdItems, selectedItems, items } = this.state;

        let nextCreatedItems = createdItems.slice();
        let nextSelectedItems = selectedItems.slice();
        let nextItems = items.slice();

        itemsToSelect.forEach(item => {
            const results = maybeAddCreatedItemToArrays(nextItems, nextCreatedItems, item);
            nextItems = results.items;
            nextCreatedItems = results.createdItems;
            // Avoid re-creating an item that is already selected (the "Create
            // Item" option will be shown even if it matches an already selected
            // item).
            nextSelectedItems = !arrayContainsItem(nextSelectedItems, item) ? [...nextSelectedItems, item] : nextSelectedItems;
        });

        this.setState({
            createdItems: nextCreatedItems,
            selectedItems: nextSelectedItems,
            items: nextItems,
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.selectedItems);
            }
        });
    }

    private deselectItem(index: number) {
        const { selectedItems } = this.state;

        const item = selectedItems[index];
        const { createdItems: nextCreatedItems, items: nextItems } = maybeDeleteCreatedItemFromArrays(
            this.state.items,
            this.state.createdItems,
            item,
        );

        // Delete the item if the user manually created it.
        this.setState({
            createdItems: nextCreatedItems,
            selectedItems: selectedItems.filter((_film, i) => i !== index),
            items: nextItems,
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.selectedItems);
            }
        });
    }

    private handleItemSelect = (item: IData) => {
        if (!this.isItemSelected(item)) {
            this.selectItem(item);
        } else {
            this.deselectItem(this.getSelectedItemIndex(item));
        }
    };

    private handleItemsPaste = (items: IData[]) => {
        // On paste, don't bother with deselecting already selected values, just
        // add the new ones.
        this.selectItems(items);
    };

    private handleSwitchChange(prop: keyof IMultiSelectState) {
        return (event: React.FormEvent<HTMLInputElement>) => {
            const checked = event.currentTarget.checked;
            this.setState(state => ({ ...state, [prop]: checked }));
        };
    }

    private handleClear = () => this.setState({ selectedItems: [] }, () => {
        if (this.props.onChange) {
            this.props.onChange(this.state.selectedItems);
        }
    });
}