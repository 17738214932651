import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { toastr as toaster } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { Grid, Table, Form } from 'semantic-ui-react';
import AdvancedControl from '../../shared/component/advanced_control';
import AutoSearchComponent from '../../shared/component/auto_search_component';
import Selection from '../../shared/component/selection_component';
import * as local_storage from '../../shared/local_storage_utility';
import * as global_constants from '../../global_constants';
import { get_charge_details, get_preauthorization_list_by_charge, save_charge_for_error_correction } from '../action/payment_action';
import { get_popper_id } from '../utility';
import Calendar from '../../shared/component/calendar_component';
import * as shared_constants from '../../shared/shared_constants';
import DiagnosisCodeQuickSearchComponent from '../../diagnosis/component/diagnosis_code_quick_search_component';
import { procedure_code_advance_search, procedure_code_quick_search, quick_search_location, quick_search_provider, referring_physician_advance_search, referring_physician_quick_search, search_place, search_provider} from './../../shared/action/autosearch_action';
import { search_referring_physician_configuration, search_result_default_paze_size } from '../../shared/shared_constants';
import { get_procedure_code_modifiers } from '../../shared/action/shared_action';
import { enum_type_of_search } from './../../case/case_constants';
import AddReferringPhysician from '../../shared/component/add_referring_physician_component';
import { content_length, content_width } from '../../shared/component/grid/utils';
import { withRouter } from 'react-router-dom';
import { get_all_error, get_lauch_darkley_key_value, date_format_with_no_time } from '../../shared/utility';
import ConfirmationComponent from '../../shared/component/confirmation_component';
import { charge_messages } from '../../charge/charge_constant';
import * as session_storage from '../../shared/session_storage_utility';
import { AlertConfirm } from '../../shared/component/alert_confirm_component';
import { ERROR_CORRECTION_MESSAGE } from '../payment_constants';
import { get_multiple_fee_schedules } from '../../shared/action/shared_action';

export class ErrorCorrectionChargeViewUpdateComponent extends React.Component<any, any> {
    [x: string]: any;
    constructor(props: any) {
        super(props);
        this.state = {
            show_bummepd_charge_message_popup:false,
            is_loading: true,
            form_submited: false,
            open_confirm_alert: false,
            procedure_code_modifiers1_list: [], // used to store dropdown data
            procedure_code_modifiers2_list: [], // used to store dropdown data
            procedure_code_modifiers3_list: [], // used to store dropdown data
            preauth_list: [], // used to store dropdown data
            provider_quick_search_format:
            {
                "title": '',
                "label": '',
                "bp_code": '',
                "description": ''
            },
            location_quick_search_format:
            {
                "title": '',
                "label": '',
                "pos_code": '',
                "description": ''
            },
            referring_physician_quick_search_format:
            {
                "title": '',
                "label": '',
                "md_code": '',
                "referring_first_name" : '',
                "referring_last_name": '',
                "referring_address": ''
            },
            selected_procedure_code: this.empty_proc_code,
            dx1: this.dx_empty,
            dx2: this.dx_empty,
            dx3: this.dx_empty,
            dx4: this.dx_empty,
            dx5: this.dx_empty,
            dx6: this.dx_empty,
            dx7: this.dx_empty,
            dx8: this.dx_empty,
            error: {
                plan_of_care_date: false,
                date_of_service: false,
                provider_id: false,
                pos_Id: false,
                proc_id: false
            },
            grid_conf: {
                isPagination: true,
                gridAutoHeight: true,
                selectionType: 'single',
                paginationPageSize: 20,
            },
            provider_search_data: {},
            location_search_data: {},
            primary_physician_search_data: {},
            procedure_code_search_data: {},
            show_primary_advanced_control: false,
            add_physician_modal: false,
            save_confirmation_unit_box: false,
            charge_details: {
                cur_icd_type: null,
                is_rebill: false,
                is_delete: false,
                allow_delete: false,
                amount: null,
                dx1: null,
                dx2: null,
                dx3: null,
                dx4: null,
                dx5: null,
                dx6: null,
                dx7: null,
                dx8: null,
                date_of_service: null,
                plan_of_care_date: null,
                updated_date: null,
                start_of_care_date: null,
                location: null,
                modified_by: null,
                preauth_id: null,
                proc_mod1: null,
                proc_mod2: null,
                proc_mod3: null,
                units: null,
                total_charge_amount: null,
                referring_physician_code: null,
                cpt_code: null,
                provider_id: null,
                billing_provider: {
                    id: "-999"
                },
                place_of_service: {
                    id: "-999"
                },
                referring_physician: {
                    id: "-999"
                },
                proc_code: {
                    procedure_code_id: 0,
                    proc_code: "",
                    std_chg_amt: '',
                    title: '',
                    label: ''
                },
                md_code: null,
                pos_code: null,
                billing_provider_code: null
            }
        };
        this.diagnosis_child = React.createRef();
    }
    initial_data = {
        pos_Id: -999,
        date_of_service: null,
        proc_id: 0,
        amount: 0,
        provider_id: -999
    };

    token: any = '';
    _is_mounted = false;
    dx_empty = {
        "title": '',
        "label": '',
        "diagnosis_code_id": '',
        "code": '',
        "description": '',
        "icd_type": ''
    };
    empty_proc_code = {
        "title": '',
        "label": '',
        "proc_amount": '',
        "description": '',
        "proc_code": '',
        "procedure_code_id":0,
        "amount": '',
        "is_amt_enabled": "false",
        "is_unit_enabled": "true",
    }
    initial_form_object: any = {};
    patient_id = 0;

    // Fetching the report id and params.
    UNSAFE_componentWillMount = () => {
        var auth_data = local_storage.get('auth_data');
        this.patient_id = session_storage.get('active_patient');
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
    };

    componentDidMount = async () => {
        this._is_mounted = true;
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.get_patient_data();
        this.get_procedure_code_modifiers_list();
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    };

    componentWillUnmount = () => {
        this._is_mounted = false;

        if (this.props.onRef) {
            this.props.onRef(undefined)
        }
    };

    get_patient_data = () => {
        let charge_id = this.props.location.state.charge_id
        if (! charge_id) {
            this.props.history.goBack();
        } else {
            this.get_charge_details(charge_id);
            this.get_preauthorization_list_by_charge(charge_id);    
        }
    }

    get_charge_details = (charge_id) => {
        get_charge_details(charge_id, this.token).then(res => {
            if (res.data.data && this._is_mounted) {
                this.initialize_charge_details(res.data.data);                
            } else {
                toaster.error('', 'Selected Charge Id is inactive for this operation.');
                this.props.history.goBack();  
            }
        },
        error => {           
            toaster.error('', 'Selected Charge Id is inactive for this operation.');
            this.props.history.goBack();
        });
    }

    get_preauthorization_list_by_charge = (charge_id) => {
        get_preauthorization_list_by_charge(charge_id, this.token).then(res => {
            if (res.data.data && this._is_mounted) {
                this.setState({
                    preauth_list: this.update_preauth_data(res.data.data)
                })

            } else {
                if (res.data.messages && res.data.messages[0].message) {
                    let toastr_options = this.show_html_content_toaster(get_all_error(res.data));
                    toaster.error("", toastr_options);
                }          
            }
        },
        error => {           
            if (error.response.data) {
                const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                toaster.error("", toastr_options);
            }
        })
    }

    initialize_charge_details = (data) => {
        let { charge_details } = this.state;
        this.initial_data = {...data};
        var place_of_service_format = { "label": '', "title": '', "pos_code": '' , "description": ''};
        var billing_provider_format = { "label": '', "title": '', "bp_code": '', "description": '' };
        var referring_physician_format = { 
                "label": '', 
                "title": '', 
                "md_code": '', 
                "referring_first_name" : '',
                "referring_last_name": '',
                "referring_address": ''
        };
        var procedure_code = this.empty_proc_code;
        var diagnosis_code1, diagnosis_code2, diagnosis_code3, diagnosis_code4, diagnosis_code5, diagnosis_code6, diagnosis_code7, diagnosis_code8;
        diagnosis_code1 = diagnosis_code2 = diagnosis_code3 = diagnosis_code4 = diagnosis_code5 = diagnosis_code6 = diagnosis_code7 = diagnosis_code8 = this.dx_empty;

        if (data.pos_Id > 0) {
            charge_details.pos_Id = data.pos_Id;
            charge_details.place_of_service.id = data.pos_Id;
            place_of_service_format = {
                "title": data.pos_Id.toString(),
                "pos_code": data.pos_code,
                "label": (data.pos_code) + " - " + (data.pos_name),
                "description": data.pos_name
            };
        }

        if (data.provider_id > 0) {
            charge_details.provider_id = data.provider_id;
            charge_details.billing_provider.id = data.provider_id;
            billing_provider_format = {
                "title": data.provider_id.toString(),
                "bp_code": data.provider_code,
                "label": (data.provider_code) + " - " + (data.provider_desc),
                "description": data.provider_desc

            };
        }

        if (data.referring_physician_id > 0) {
            
            var _title = data.referring_physician_code + " - " + (data.referring_first_name == null ? '' : data.referring_first_name) + " " + (data.referring_last_name == null ? '' : data.referring_last_name) + (data.referring_address == null ? '' : ", " + data.referring_address);

            referring_physician_format = {
                "title": data.referring_physician_id.toString(),
                "md_code": data.referring_physician_code,
                "label": _title == ' ' ? data.referring_physician_code : _title,
                "referring_first_name" : data.referring_first_name,
                "referring_last_name": data.referring_last_name,
                "referring_address": data.referring_address
            };
        }

        if (data.proc_id > 0) {
            var _title = data.proc_code + " - " + data.proc_desc;
            // Set Auto search control for referring physician
            procedure_code = {
                "title": data.proc_id.toString(),
                "procedure_code_id": data.proc_id,
                "proc_amount": data.proc_amount,
                "label": _title,
                "description": data.proc_desc,
                "proc_code": data.proc_code,
                "is_amt_enabled": data.allow_cpt_edit_flag.toString(),
                "is_unit_enabled": "true",
                "amount": data.amount
            };
        }

        if (data.dx1_id > 0) {
            diagnosis_code1 = this.get_prepared_dx_code(data.dx1, data.dx1_id, data.dx1_desc, data.dx1_icd_type);
        }
        if (data.dx2_id > 0) {
            diagnosis_code2 = this.get_prepared_dx_code(data.dx2, data.dx2_id, data.dx2_desc, data.dx2_icd_type);
        }
        if (data.dx3_id > 0) {
            diagnosis_code3 = this.get_prepared_dx_code(data.dx3, data.dx3_id, data.dx3_desc, data.dx3_icd_type);
        }
        if (data.dx4_id > 0) {
            diagnosis_code4 = this.get_prepared_dx_code(data.dx4, data.dx4_id, data.dx4_desc, data.dx4_icd_type);
        }
        if (data.dx5_id > 0) {
            diagnosis_code5 = this.get_prepared_dx_code(data.dx5, data.dx5_id, data.dx5_desc, data.dx5_icd_type);
        }
        if (data.dx6_id > 0) {
            diagnosis_code6 = this.get_prepared_dx_code(data.dx6, data.dx6_id, data.dx6_desc, data.dx6_icd_type);
        }
        if (data.dx7_id > 0) {
            diagnosis_code7 = this.get_prepared_dx_code(data.dx7, data.dx7_id, data.dx7_desc, data.dx7_icd_type);
        }
        if (data.dx8_id > 0) {
            diagnosis_code8 = this.get_prepared_dx_code(data.dx8, data.dx8_id, data.dx8_desc, data.dx8_icd_type);
        }

        charge_details.allow_delete = data.allow_delete;

        charge_details.amount = data.amount;
        charge_details.units = data.units;
        charge_details.total_charge_amount = data.total_charge_amount;

        charge_details.preauth_id = data.preauth_id;
        charge_details.proc_mod1 = data.proc_mod1_id;
        charge_details.proc_mod2 = data.proc_mod2_id;
        charge_details.proc_mod3 = data.proc_mod3_id;
        charge_details.cur_icd_type = data.cur_icd_type;

        charge_details.date_of_service = date_format_with_no_time(data.date_of_service);
        charge_details.start_of_care_date = date_format_with_no_time(data.start_of_care_date);
        charge_details.plan_of_care_date = date_format_with_no_time(data.plan_of_care_date);
        charge_details.modified_by = data.modified_by;
        charge_details.updated_date = data.updated_date ? moment(data.updated_date).format("MM/DD/YYYY") : null;
        charge_details.charge_open_time = data.charge_open_time;
        charge_details.cur_ins = data.cur_ins_code;
        charge_details.is_charge_locked =  data.is_charge_locked;

        if (this._is_mounted) {
            this.setState({
                location_quick_search_format: place_of_service_format,
                provider_quick_search_format: billing_provider_format,
                referring_physician_quick_search_format: referring_physician_format,
                selected_procedure_code: procedure_code,
                charge_details: charge_details,
                dx1: diagnosis_code1,
                dx2: diagnosis_code2,
                dx3: diagnosis_code3,
                dx4: diagnosis_code4,
                dx5: diagnosis_code5,
                dx6: diagnosis_code6,
                dx7: diagnosis_code7,
                dx8: diagnosis_code8,
                is_loading: false
            }, () => {
                this.initial_form_object = this.prepare_charge_data();
            });
        }
    }

    get_prepared_dx_code = (dx, dx_id, dx_desc, icd_type) => {
        let name = dx + ' - ' + dx_desc;
        return {
            "title": name,
            "label": name,
            "diagnosis_code_id": dx_id,
            "code": dx,
            "description": dx_desc,
            "icd_type": icd_type
        };
    }

    // Done Initially Getting Modifiers from the api
    get_procedure_code_modifiers_list = async () => {
        await get_procedure_code_modifiers(this.token)
            .then(response => {
                if (response.data.data) {
                    if (this._is_mounted) {
                        this.setState({
                            procedure_code_modifiers1_list: this.update_modifiers_data(response.data.data, 1),
                            procedure_code_modifiers2_list: this.update_modifiers_data(response.data.data, 2),
                            procedure_code_modifiers3_list: this.update_modifiers_data(response.data.data, 3)
                        });
                    }
                }
                else {
                    if (this._is_mounted) {
                        this.setState({
                            procedure_code_modifiers1_list: this.update_modifiers_data([], 1),
                            procedure_code_modifiers2_list: this.update_modifiers_data([], 2),
                            procedure_code_modifiers3_list: this.update_modifiers_data([], 3)
                        });
                    }
                }
            }, (error) => {
                if (this._is_mounted) {
                    this.setState({
                        procedure_code_modifiers1_list: this.update_modifiers_data([], 1),
                        procedure_code_modifiers2_list: this.update_modifiers_data([], 2),
                        procedure_code_modifiers3_list: this.update_modifiers_data([], 3)
                    });
                }
            })

    }

    get_date = (date, pattern) => {
        if (date) {
            return moment(date).format(pattern);
        }
        return '';
    }

    update_preauth_data = (source) => {
        let target = [{ "text": '-- Please Select a Preauthorization --', "value": global_constants.constants.dropdown_default_info.value_string, "key": "-- Please Select a Preauthorization --" }];
        var source_length = source.length;

        if (source && source_length > 0) {
            for (let y = 0; y < source_length; y++) {
                let item = source[y];
                target.push({
                    "text": item.detail,
                    "value": item.id,
                    "key": item.id
                })
            }
        }

        return target;
    }

    update_modifiers_data = (source, modifier) => {
        let target = [];

        switch (modifier) {
            case 1:
                target = [{ "text": '-- Please Select a Modifier 1 --', "value": global_constants.constants.dropdown_default_info.value_string, "key": "-- Please Select a Modifier 1 --" }];
                break;
            case 2:
                target = [{ "text": '-- Please Select a Modifier 2 --', "value": global_constants.constants.dropdown_default_info.value_string, "key": "-- Please Select a Modifier 2 --" }];
                break;
            case 3:
                target = [{ "text": '-- Please Select a Modifier 3 --', "value": global_constants.constants.dropdown_default_info.value_string, "key": "-- Please Select a Modifier 3 --" }];
                break;
            default:
                target = []
                break;
        }
        var source_length = source.length;

        if (source && source_length > 0) {
            for (let y = 0; y < source_length; y++) {
                let item = source[y];
                if (item.procedure_modifier_code && item.procedure_modifier_code == 'KX') {
                    this._kx_modifier_id = item.id;
                }

                target.push({
                    "text": (item.procedure_modifier_code ? item.procedure_modifier_code : '') + ' - ' + (item.procedure_modifier_desc ? item.procedure_modifier_desc : ''),
                    "value": item.id,
                    "key": item.id
                })
            }
        }

        return target;
    }

    handle_confirm = (e) => {
        e.preventDefault();
        const check_value = { is_delete: true };
        if (this._is_mounted) {
            this.setState(prev_state => ({
                charge_details: {
                    ...prev_state.charge_details, ...check_value
                },
                open_confirm_alert: false,
                ...check_value
            }))
        }
    }

    on_check_change = (e) => {
        let name = e.currentTarget.name;
        let value = e.currentTarget.checked;
        if (value && name == 'is_delete') {
            this.setState({
                open_confirm_alert: value
            })
        } else {
            if (this._is_mounted) {
                const check_value = { [name]: value };
                this.setState(prev_state => ({
                    charge_details: {
                        ...prev_state.charge_details,
                        [name]: value
                    },
                    ...check_value
                }))
            }
        }
    }

    recalculate_charge_amount = async (type, procedure_code_id) => {
        if(this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting && procedure_code_id ){
            let billing_provider_id = this.state.charge_details.billing_provider.id;
            let place_of_service_id = this.state.charge_details.place_of_service.id;
            let charge_state = {...this.state.charge_details}
            let { cur_ins, date_of_service } = charge_state;
             
            if ( cur_ins && place_of_service_id && billing_provider_id && (date_of_service && moment(date_of_service).isValid())) {
                let token = this.props.user_login_details.user_data.data.token_details.access_token;
                let body = {
                    Location_id : place_of_service_id,
                    Billing_provider_id : billing_provider_id,
                    Primary_ins_id : cur_ins,
                    Date_of_service : date_of_service
                };
                let multiple_fee_schedules = await get_multiple_fee_schedules(token, procedure_code_id, body);

                let amount = (this.initial_data.proc_id == Number(procedure_code_id) && this.initial_data.pos_Id == Number(place_of_service_id) && this.initial_data.provider_id == Number(billing_provider_id)) && this.get_date(this.initial_data.date_of_service, 'MM/DD/YYYY') ==  date_of_service ? this.initial_data.amount : multiple_fee_schedules.data.data.std_chg_amt;

                if(type == 'procedure_code'){
                    return amount;
                }

                if(charge_state.amount != amount){
                    toaster.warning('', "Heads up! The Charge Amount has changed based on updates made.");
                }

                charge_state.total_charge_amount = Number(amount) * Number(charge_state.units);
                charge_state.amount = Number(amount);

                this.setState(prevState => ({
                    charge_details: charge_state,
                    selected_procedure_code: {
                        ...prevState.selected_procedure_code,
                        proc_amount: charge_state.amount
                    },
                }));
            }
        }
    }

    handleCalendarChange = (dt, name) => {       

        let has_error = false;
        if (dt == null) {
            has_error = true;
        } else {
            has_error = false;
        }

        if (this._is_mounted) {
            this.setState(prev_state => ({
                charge_details: {
                    ...prev_state.charge_details,
                    [name]: dt
                },
                error: {
                    ...prev_state.error,
                    [name]: has_error
                }
            }), () => {
                if( this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting && name == 'date_of_service' ){
                    this.recalculate_charge_amount(name, this.state.selected_procedure_code.procedure_code_id);
                }
            })
        }
    }

    clear_quick_search = (type_of_search) => {

        var charge = this.state.charge_details;
        let error = this.state.error;
        if (type_of_search == enum_type_of_search.place_of_service) {

            charge.place_of_service.id = "-999";
            
            charge.pos_code = "";
            error.pos_Id = true;
            if (this._is_mounted) {
                this.setState({
                    location_quick_search_format: {
                        "title": '',
                        "label": '',
                        "pos_code": ''
                    },
                    charge_details: charge,
                    error: error
                });
            }
        }
        else if (type_of_search == enum_type_of_search.provider) {
            charge.billing_provider.id = "-999";
            charge.billing_provider_code = "";
            error.provider_id = true;

            if (this._is_mounted) {
                this.setState({
                    provider_quick_search_format: {
                        "title": '',
                        "label": '',
                        "bp_code": ''
                    },
                    charge_details: charge,
                    error: error
                });
            }
        } else if (type_of_search == enum_type_of_search.referring_physician) {
            charge.referring_physician.id = 0;
            if (this._is_mounted) {
                this.setState({
                    referring_physician_quick_search_format: {
                        "title": '',
                        "label": '',
                        "md_code": ''
                    },
                    charge_details: charge,
                });
            }
        } else {
            // cpt code
            charge.units = 1;
            charge.total_charge_amount = Number(0) * Number(charge.units);
            charge.amount = Number(0);
            error.proc_id = true;
            if (this._is_mounted) {
                this.setState({
                    charge_details: charge,
                    selected_procedure_code: this.empty_proc_code,
                    error: error
                });
            }
        }
    }

    //Auto search for Location
    render_suggestion_result = (props) => {
        return (
            <div key={props.title} tabIndex={0} className="item_auto_search">
                <div key={props.title} className="fs_13">
                    <span>{props.label}</span>
                </div>
            </div>
        )
    }

    // get the searched value which is typed for searching location or providers
    get_input_value = (input, type) => {
        if (input.length >= 3) {
            this.get_referring_physician_quick_search_data_list(input);
        }
    }

    // preapare the suggestion list with search result
    prepare_suggestion = (_data, type_of_search: any = null) => {
        let formattedList = [];
        var data_length = 0;
        if (_data.data) {
            data_length = _data.data.length;
            _data = _data.data;
        }
        else {
            data_length = _data.length;
        }
        if (_data && data_length) {
            for (var i = 0; i < data_length; i++) {
                var item = _data[i];

                if (type_of_search === enum_type_of_search.referring_physician) {
                    formattedList.push({
                        "title": `${item.id}`,
                        "md_code": item.md_code,
                        "first_name": item.first_name,
                        "last_name": item.last_name,
                        "address": item.address,
                        "label": (item.md_code) + " - " + (item.first_name) + " " + (item.last_name) + (item.address == null ? '' : ", " + item.address),

                    })
                }
                else if (type_of_search === enum_type_of_search.place_of_service) {
                    formattedList.push({
                        "title": `${item.id}`,
                        "code": item.code,
                        "name": item.name,
                        "label": (item.code) + " - " + (item.name),

                    })
                }
                else if (type_of_search === enum_type_of_search.provider) {
                    formattedList.push({
                        "title": `${item.id}`,
                        "code": item.code,
                        "name": item.description,
                        "label": (item.code) + " - " + (item.description),

                    })
                }
                else {
                    item = {
                        ...item,
                        "is_amt_enabled": `${(item.is_amt_enabled == true || item.is_amt_enabled == "true") ? "true" : "false"}`,
                        "is_unit_enabled": `${(item.is_unit_enabled == true || item.is_unit_enabled == "true") ? "true" : "false"}`,    
                        "title": `${item.procedure_code_id}`,
                        "procedure_code_id": `${item.procedure_code_id}`,
                        "label": (item.proc_code) + " - " + (item.proc_desc1),
                        "description": item.proc_desc1,
                        "proc_amount" : item.std_chg_amt,
                        "proc_code": item.proc_code
                    };
                    formattedList.push(item);
                }

            }

        }
        return formattedList;
    }

    get_referring_physician_quick_search_data_list = async (search_keyword) => {
        return await referring_physician_quick_search(search_keyword, this.props.user_login_details.user_data.data.token_details.access_token);
    }

    referring_physician_search = async (params) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        params["Page_size"] = search_result_default_paze_size;
        const search_data = await referring_physician_advance_search(params, token)
            .then(res => res.data);

        const search_result = search_data.data !== null ? search_data.data.result : [];
        const grid_data = {
            ...this.state.grid_conf,
            rows: search_result,
            column: search_referring_physician_configuration("referring_physician_id").column_defs,
            paginationPageSize: search_referring_physician_configuration("referring_physician_id").pagination_size,
        };
        if (this._is_mounted) {
            this.setState({ referring_physician_search_data: grid_data });
        }
    }

    add_referring_physician = () => {
        if (this._is_mounted) {
            this.setState({ add_referring_physician_modal: !this.state.add_referring_physician_modal });
        }
    }
    set_advanced_control_modal_state = (show_status, control_type) => {
        if (this._is_mounted) {
            this.setState({ show_advanced_control: show_status });
        }
    }

    on_location_grid_row_selection = (selected_row) => {
        this.on_row_selection_advance_search(enum_type_of_search.place_of_service, selected_row);
    }
    on_provider_grid_row_selection = (selected_row) => {
        this.on_row_selection_advance_search(enum_type_of_search.provider, selected_row);
    }

    on_provider_code_grid_row_selection = (selected_row) => {
        this.on_row_selection_advance_search('procedure_code', selected_row);
    }

    on_primary_physician_grid_row_selection = (selected_row) => {
        this.on_row_selection_advance_search(enum_type_of_search.referring_physician, selected_row);
    }

    on_row_selection_advance_search = async (type_of_search, selected_row) => {
        var f_name = '';
        var l_name = '';
        var address = "";
        var id = '';
        var name = '';
        var code = '';
        var selected_data = '';
        var charge_state = this.state.charge_details;
        var error = this.state.error;
        
        if (type_of_search === enum_type_of_search.referring_physician) {
            if (selected_row) {
                id = selected_row.id;
                if ((selected_row.md_code != null && selected_row.md_code != undefined) || (selected_row.first_name != null && selected_row.first_name != undefined)
                    || (selected_row.last_name != null && selected_row.last_name != undefined)) {
                    code = (selected_row.md_code == null && selected_row.md_code == undefined) ? '' : selected_row.md_code;
                    f_name = (selected_row.first_name == null && selected_row.first_name == undefined) ? '' : selected_row.first_name;
                    l_name = (selected_row.last_name == null && selected_row.last_name == undefined) ? '' : selected_row.last_name;
                    address = (selected_row.address1 == null && selected_row.address1 == undefined) ? '' : selected_row.address1;
                }
            }
            selected_data = code + " - " + (f_name == null ? '' : f_name) + " " + (l_name == null ? '' : l_name) + (address == null ? '' : ", " + address);
            var format = { "label": '', "title": '', "md_code": '' };
            if (parseInt(id) > 0) {
                // Set Auto search control for referring physician
                format = {
                    "title": id.toString(),
                    "md_code": code,
                    "label": selected_data
                };
            }

            charge_state.md_code = charge_state.referring_physician.id = id;
            if (this._is_mounted) {
                this.setState({
                    referring_physician_quick_search_format: format,
                    charge_details: charge_state
                });
            }
        }

        else if (type_of_search === enum_type_of_search.place_of_service) {
            if (selected_row) {
                id = selected_row.id;
                if ((selected_row.code != null && selected_row.code != undefined) || (selected_row.name != null && selected_row.name != undefined)) {
                    code = (selected_row.code == null && selected_row.code == undefined) ? '' : selected_row.code;
                    name = (selected_row.name == null && selected_row.name == undefined) ? '' : selected_row.name;
                }
                error.pos_Id = false;

            }
            selected_data = code + " - " + name;
            var format_loc = { "label": '', "title": '', "pos_code": '' };
            if (parseInt(id) > 0) {
                // Set Auto search control for location
                format_loc = {
                    "title": id.toString(),
                    "pos_code": code,
                    "label": selected_data
                };
            }
            charge_state.pos_code = charge_state.place_of_service.id = id;
            error.pos_Id = false;
            if (this._is_mounted) {
                this.setState({
                    location_quick_search_format: format_loc,
                    charge_details: charge_state,
                    error: error
                });
            }
            if(this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting ){
                this.recalculate_charge_amount(type_of_search, this.state.selected_procedure_code.procedure_code_id);
            }
        }
        else if (type_of_search === enum_type_of_search.provider) {
            if (selected_row) {
                id = selected_row.id;
                if ((selected_row.code != null && selected_row.code != undefined) || (selected_row.name != null && selected_row.name != undefined)) {
                    code = (selected_row.code == null && selected_row.code == undefined) ? '' : selected_row.code;
                    name = (selected_row.description == null && selected_row.description == undefined) ? '' : selected_row.description;
                }
            }
            selected_data = code + " - " + name;
            var format_bp = { "label": '', "title": '', "bp_code": '' };
            if (parseInt(id) > 0) {
                format_bp = {
                    "title": id.toString(),
                    "bp_code": code,
                    "label": selected_data
                };
            }

            charge_state.billing_provider_code = charge_state.billing_provider.id = id;
            error.provider_id = false;
            if (this._is_mounted) {
                this.setState({
                    provider_quick_search_format: format_bp,
                    charge_details: charge_state,
                    error: error
                });
            }
            if(this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting ){
                this.recalculate_charge_amount(type_of_search, this.state.selected_procedure_code.procedure_code_id);
            }
        } else {
            if (selected_row) {
                id = selected_row.procedure_code_id;
                if ((selected_row.proc_code != null && selected_row.proc_code != undefined) || (selected_row.name != null && selected_row.name != undefined)) {
                    code = (selected_row.proc_code == null && selected_row.proc_code == undefined) ? '' : selected_row.proc_code;
                    name = (selected_row.proc_desc1 == null && selected_row.proc_desc1 == undefined) ? '' : selected_row.proc_desc1;
                }
            }
            selected_data = code + " - " + name;
            let format_data = this.empty_proc_code;
            
            if (parseInt(id) > 0) {  
                let updated_amount = this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting ? await this.recalculate_charge_amount(type_of_search, Number(id)) : selected_row.std_chg_amt;

                format_data = {
                    "title": id.toString(),
                    "procedure_code_id": Number(id),
                    "label": selected_data,
                    "proc_amount": updated_amount,
                    "description": selected_row.proc_desc1,
                    "proc_code": selected_row.proc_code,
                    "is_amt_enabled": selected_row.is_amt_enabled.toString(),
                    "is_unit_enabled": "true",
                    "amount": this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting ? updated_amount : this.initial_data.proc_id == Number(id) ? this.initial_data.amount : selected_row.std_chg_amt
                };
            }

            if(this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting && format_data.amount && (charge_state.amount != format_data.amount)){
                toaster.warning('', "Heads up! The Charge Amount has changed based on updates made.");
            }

            charge_state.total_charge_amount = Number(format_data.amount) * Number(charge_state.units);
            charge_state.amount = Number(format_data.amount);
            error.proc_id = false;
            if (this._is_mounted) {
                this.setState({
                    selected_procedure_code: format_data,
                    charge_details: charge_state,
                    error: error
                });
            }
        }
    }

    on_item_selection = async (item, type) => {
        var charge_state = this.state.charge_details;
        let error = this.state.error;
        if (type === enum_type_of_search.referring_physician) {
            charge_state.md_code = charge_state.referring_physician.id = item.title;
            if (this._is_mounted) {
                this.setState({
                    referring_physician_quick_search_format: item,
                    charge_details: charge_state,
                    error: error
                });
            }
        } else if (type === enum_type_of_search.place_of_service) {
            charge_state.pos_code = charge_state.place_of_service.id = item.title;
            error.pos_Id = false;
            if (this._is_mounted) {
                this.setState({
                    location_quick_search_format: item,
                    charge_details: charge_state,
                    error: error
                });
            }
            if(this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting ){
                this.recalculate_charge_amount(type, this.state.selected_procedure_code.procedure_code_id);
            }
        }
        else if (type === enum_type_of_search.provider) {
            charge_state.billing_provider_code = charge_state.billing_provider.id = item.title;
            error.provider_id = false;
            if (this._is_mounted) {
                this.setState({
                    provider_quick_search_format: item,
                    charge_details: charge_state,
                    error: error
                });
            }
            if(this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting ){
                this.recalculate_charge_amount(type, this.state.selected_procedure_code.procedure_code_id);
            }
        } else {
            let format_data = this.empty_proc_code;
            if (parseInt(item.procedure_code_id) > 0) {
                let updated_amount = this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting ? await this.recalculate_charge_amount(type, Number(item.procedure_code_id)) : item.proc_amount;
                format_data = {
                    "title": item.title,
                    "label": item.label,
                    "procedure_code_id": Number(item.procedure_code_id),
                    "proc_amount": updated_amount,
                    "description": item.description,
                    "proc_code": item.proc_code,
                    "is_amt_enabled": item.is_amt_enabled.toString(),
                    "is_unit_enabled": "true",
                    "amount": this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting ? updated_amount : this.initial_data.proc_id == Number(item.procedure_code_id) ? this.initial_data.amount : item.proc_amount
                };
            }

            if(this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting && format_data.amount && (charge_state.amount != format_data.amount)){
                toaster.warning('', "Heads up! The Charge Amount has changed based on updates made.");
            }

            charge_state.total_charge_amount = Number(format_data.amount) * Number(charge_state.units);
            charge_state.amount = Number(format_data.amount);
            error.proc_id = false;

            if (this._is_mounted) {
                this.setState({
                    charge_details: charge_state,
                    selected_procedure_code: format_data,
                    error: error
                });
            }
        }
    }

    // get the quick search location
    get_location_quick_search_data_list = async (search_keyword) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await quick_search_location(search_keyword, token);
    }
    // get the quick search provider
    get_provider_quick_search_data_list = async (search_keyword) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await quick_search_provider(search_keyword, token, 0);
    }

    // function/method to handle the when searching of referring physician occurs
    on_referring_Physician_search = async (params) => {
        this.configure_grid_data(enum_type_of_search.referring_physician, params);
    }

    configure_grid_data = async (type_of_search, params) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        params = params ? params : {};
        // params.page_size = 100;
        var grid_data = [];

        if (type_of_search === enum_type_of_search.referring_physician) {
            const search_data = await referring_physician_advance_search(params, token).then(res => res.data);
            const search_result = search_data.data !== null ? search_data.data.result : [];
            // const row_data = this.referring_physician_filter(search_result, type_of_search === enum_type_of_search.referring_physician ? this.state.case.secondary_md_code : this.state.case.md_code);
            let column_def = shared_constants.search_referring_physician_configuration(type_of_search).column_defs;

            grid_data = { ...this.state.grid_conf, rows: search_result, column: column_def, messages: search_data.messages };
            if (this._is_mounted) {
                this.setState({ primary_physician_search_data: grid_data });
            }
        }
        else if (type_of_search === enum_type_of_search.place_of_service) {
            const search_data_location = await search_place(params, token).then(res => res.data);
            const search_location_result = search_data_location.data !== null ? search_data_location.data.result : [];
            grid_data = { ...this.state.grid_conf, rows: search_location_result, column: shared_constants.search_location_configuration(type_of_search + 'grid_header_id').column_defs, messages: search_data_location.messages };
            if (this._is_mounted) {
                this.setState({ location_search_data: grid_data });
            }
        }
        else if (type_of_search === enum_type_of_search.provider) {

            params.id = this.state.charge_details.pos_code;
            params.apply_pos_code = false;
            const search_data_provider = await search_provider(params, token).then(res => res.data);
            const search_provider_result = search_data_provider.data !== null ? search_data_provider.data.result : [];
            grid_data = { ...this.state.grid_conf, rows: search_provider_result, column: shared_constants.search_provider_configuration(type_of_search + 'grid_header_id').column_defs, messages: search_data_provider.messages };
            if (this._is_mounted) {
                this.setState({ provider_search_data: grid_data });
            }
        } else {
            let search_data = await procedure_code_advance_search(params, this.token).then(res => res.data);
            const search_result = search_data.data !== null ? search_data.data.result : [];
            let col_def = this.column_def_format(shared_constants.search_procedure_code_configuration('procedure_code_searcch_').column_defs, search_result);
            grid_data = {
                ...this.state.grid_conf, rows: search_result,
                column: col_def,
                messages: search_data.messages
            };
            if (this._is_mounted) {
                this.setState({
                    loading: false,
                    procedure_code_search_data: grid_data
                });
            }
        }
    }
    column_def_format = (column_def, rows) => {
        if (rows && rows.length > 0) {
            let address1_length = 0, address2_length = 0;
            const result = rows.map((item) => {
                const text_address1_length = content_length(item.proc_desc1);
                if (text_address1_length > address1_length) {
                    address1_length = text_address1_length;
                }
                return item;
            });
            column_def = column_def.map(item => {
                if (item.field == 'proc_desc1') {
                    return { ...item, minWidth: content_width(address1_length) };
                }
                return item;
            })
        }
        return column_def;
    }

    set_parent_show_modal_state = (show_status, control_type) => {
        if (control_type == global_constants.constants.advanced_control_type.referring_physician) {
            if (this._is_mounted) {
                this.setState({ show_primary_advanced_control: show_status });
            }
        }
    }

    show_add_referring_modal_primary_physician = (e) => {
        this.show_add_referring_modal(e, "Primary Physician")
    }

    show_add_referring_modal = (e, type_of_physcian) => {
        if (this._is_mounted) {
            this.setState({ add_physician_modal: !this.state.add_physician_modal });
        }
    }
    clear_quick_search_referring_physician = () => {
        this.clear_quick_search(enum_type_of_search.referring_physician)
    }

    on_item_selection_referring_physician = (item) => {    
        this.on_item_selection(item, enum_type_of_search.referring_physician);
    }

    prepare_suggestion_referring_physician = (data) => {
        return this.prepare_suggestion(data, enum_type_of_search.referring_physician)
    }

    get_input_value_referring_physician = (input) => {
        this.get_input_value(input, enum_type_of_search.referring_physician)
    }

    get_formatted_physician_info = (formatted_physician_info) => {
        let { charge_details } = this.state;
        charge_details.referring_physician.id = formatted_physician_info.id;
        charge_details.md_code = formatted_physician_info.id;
        if (this._is_mounted) {
            this.setState({
                referring_physician_quick_search_format: formatted_physician_info,
                add_physician_modal: false,
                show_primary_advanced_control: false,
                charge_details: charge_details
            });
        }
    }

    // get the quick search procedure code
    get_procedure_code_quick_search_data_list = async (search_keyword) => {
        return await procedure_code_quick_search(search_keyword, this.token);
    }

    on_dropdown_change = (value, e) => {
        let name = e.target.name;
        if (this._is_mounted) {
            this.setState(prev_state => ({
                charge_details: {
                    ...prev_state.charge_details,
                    [name]: value
                }
            }))
        }
    }

    on_input_change = (e) => {
        let value = e.currentTarget.value;
        var isnum = /^\d+$/.test(value);
        var charge_state = { ...this.state.charge_details };
        let save_confirmation_unit_box = false;

        if (isnum) {
            charge_state.units = Number(value);
            if(charge_state.units > 9) {
                save_confirmation_unit_box = true;
            }
        } else {
            charge_state.units = ''
        }

        if(this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting){
            charge_state.total_charge_amount = Number(charge_state.units) * Number(charge_state.amount);
            charge_state.proc_amount = charge_state.amount;
        }else{
            charge_state.total_charge_amount = Number(charge_state.units) * Number(this.state.selected_procedure_code.proc_amount);
            charge_state.amount = Number(this.state.selected_procedure_code.proc_amount);
        }
        
        if (this._is_mounted) {
            this.setState({
                charge_details: charge_state,
                save_confirmation_unit_box: save_confirmation_unit_box
            });
        }
    }

    update_amount = (e) => {
        let value = e.currentTarget.value;
        var charge_state = { ...this.state.charge_details };
        charge_state.amount = Number(value);
        charge_state.total_charge_amount = Number(value) * Number(charge_state.units);

        this.setState(prevState => ({
            charge_details: charge_state,
            selected_procedure_code: {
                ...prevState.selected_procedure_code,
                proc_amount: charge_state.amount
            },
        }));
    }

    update_diagnosis_code = (name, value) => {
        if (this._is_mounted) {
            this.setState({
                [name]: value                
            });
        }
    }

    onScroll = (e) => {
        e.stopPropagation();
        let ele = document.querySelector(".results.transition.visible");
        if(ele && e.target.id == "edit_charge") {
            let activeElement: any = document.activeElement;

            activeElement && activeElement.blur();
        }
    }

    render_header = () => {
        return (
            <Table.Header>
            <Table.Row>
                <Table.HeaderCell style={{ width: '60px', minWidth: '60px' }}>Rebill</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '60px', minWidth: '60px' }}>Delete</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '155px', minWidth: '155px' }}>Date of Service</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '155px', minWidth: '155px' }}>Start of Care Date</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Provider</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Location</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Ref Phys</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>CPT Code</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Charge Amount</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Mod 1</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Mod 2</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Mod 3</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Units</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Dx 1</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Dx 2</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Dx 3</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Dx 4</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Dx 5</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Dx 6</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Dx 7</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Dx 8</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Total Charge</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>PreAuth</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '155px', minWidth: '155px' }}>Plan of Care Date</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Modified By</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Last Updated</Table.HeaderCell>
            </Table.Row>
        </Table.Header>);
    }

    render_checkbox_element = (name, value, is_disabled) => {
        return(
            <input type="checkbox"
                id={name}
                name={name}
                onChange={this.on_check_change}
                checked={value}
                disabled={is_disabled}
            />
        );
    }
    
    render_calendar_element = (name, value, is_required=false) => {
        return(
            <Calendar
                id={name}
                id_popper={get_popper_id()}
                maxDate={moment().format('MM/DD/YYYY')}
                date_update={(dt) => this.handleCalendarChange(dt, name)}
                date={value}
                is_required={is_required}
                class_name={"datepicker-keybowrd-charge"}
            />
        );
    }

    render_dropdown_element = (name, value, options) => {
        return(
            <Selection
                id={name}
                name={name}
                defaultValue={value || ''}
                options={options}
                onChange={this.on_dropdown_change}
            />
        );
    }

    render_input_element = (name, value, is_disabled) => {
        return(
            <Form.Field >
                <Form.Input fluid
                    id={name}
                    name={name}
                    maxLength={50}
                    value={value || ''}
                    disabled={is_disabled}
                    onChange={name == 'charge_amount' ? this.update_amount : this.on_input_change}
                />
            </Form.Field>
        );
    }

    on_blur_auto_search = (e, type_of_search) => {
    }
    
    onFocus = (e) => {
        let obj = e.target && e.target.getBoundingClientRect();
        let sibling = e.target && e.target.parentElement && e.target.parentElement.nextElementSibling;

        if(obj && sibling) {
            let style = `position:fixed;left:${obj.left}px;top:${obj.top + obj.height}px;width:${obj.width}px !important;`;
            sibling.setAttribute("style", style);
        }
    }

    render_advance_quick_search = (type, search_data, control_id, value, error_message, get_quick_search_data_list, has_error=false) => {
        return (
            <Form.Field className={has_error?'requiredWithBgColor advance-quick-search':'advance-quick-search'}>
                <AdvancedControl
                    onGridRowSelection={(selected_row) => this.on_row_selection_advance_search(type, selected_row)}
                    gridConfig={search_data}
                    controlId={control_id}
                    onSearch={(params)=> this.configure_grid_data(type, params)}
                    search_type={type}
                    headerIdForGridTabNavigation={type + 'grid_header_id'}
                />
                <AutoSearchComponent
                    on_blur={(e) => { this.on_blur_auto_search(e, type) }}
                    control_id={control_id}
                    default_value={value}
                    on_focus={this.onFocus}
                    errorMessage={error_message}
                    prepareRenderList={this.render_suggestion_result}
                    getList={get_quick_search_data_list}
                    prepareDataList={(data) => this.prepare_suggestion(data, type)}
                    selectresult={(item) => this.on_item_selection(item, type)}
                    is_focus={false}
                    show_clear_search={true} clear_search={() => this.clear_quick_search(type)}
                    errorClass={has_error ? 'req-background-inp' : ''}
                />
            </Form.Field>
        );
    }

    prepare_charge_data = () => {
        let state = this.state
        let { charge_details, provider_quick_search_format, location_quick_search_format, referring_physician_quick_search_format, selected_procedure_code } = this.state

        return {
            "date_of_service": date_format_with_no_time(charge_details.date_of_service),
            "start_of_care_date": date_format_with_no_time(charge_details.start_of_care_date),
            "plan_of_care_date": date_format_with_no_time(charge_details.plan_of_care_date),
            "provider_id": provider_quick_search_format.title,
            "pos_Id": location_quick_search_format.title,
            "referring_physician_id": referring_physician_quick_search_format.title,
            "charge_id": this.props.location.state.charge_id,
            "proc_code": selected_procedure_code.proc_code,
            "proc_id": selected_procedure_code.title,
            "proc_amount": selected_procedure_code.proc_amount,
            "proc_desc": selected_procedure_code.description,
            "proc_mod1_id": charge_details.proc_mod1,
            "proc_mod2_id": charge_details.proc_mod2,
            "proc_mod3_id": charge_details.proc_mod3,
            "total_charge_amount": charge_details.total_charge_amount,
            "units": charge_details.units,
            "preauth_id": charge_details.preauth_id,
            "rebill": charge_details.is_rebill,
            "delete": charge_details.is_delete,
            "dx1": state.dx1.code,
            "dx2": state.dx2.code,
            "dx3": state.dx3.code,
            "dx4": state.dx4.code,
            "dx5": state.dx5.code,
            "dx6": state.dx6.code,
            "dx7": state.dx7.code,
            "dx8": state.dx8.code,
            "patient_id": this.patient_id,
            "charge_open_time": charge_details.charge_open_time
          };
    }

    validate_dx_data = () => {
            let { charge_details, dx1, dx2, dx3, dx4, dx5, dx6, dx7, dx8 } = this.state;
            var chargeICDType = charge_details.cur_icd_type == '0' ? '1' : '0';
        
            if(
                (dx1.diagnosis_code_id > 0 && chargeICDType !=  dx1.icd_type) ||
                (dx2.diagnosis_code_id > 0 && chargeICDType !=  dx2.icd_type) ||
                (dx3.diagnosis_code_id > 0 && chargeICDType !=  dx3.icd_type) ||
                (dx4.diagnosis_code_id > 0 && chargeICDType !=  dx4.icd_type) ||
                (dx5.diagnosis_code_id > 0 && chargeICDType !=  dx5.icd_type) ||
                (dx6.diagnosis_code_id > 0 && chargeICDType !=  dx6.icd_type) ||
                (dx7.diagnosis_code_id > 0 && chargeICDType !=  dx7.icd_type) ||
                (dx8.diagnosis_code_id > 0 && chargeICDType !=  dx8.icd_type)
            ) {
                return false; 
            }

            return true;
    }

    // validate the form with values
    validateForm = (prepared_data) => {

        let has_error = false;
        let error = {
            plan_of_care_date: false,
            date_of_service: false,
            provider_id: false,
            pos_Id: false,
            proc_id: false
        };

        if (! prepared_data.plan_of_care_date) {
            has_error = true;
            error.plan_of_care_date = true;
            if (prepared_data.date_of_service) {
                let ele = document.getElementById("plan_of_care_date")
                ele && ele.focus();
            }
        }     
        if (! prepared_data.proc_id) {
            has_error = true;
            error.proc_id = true;
            let ele = document.getElementById("PROCEDURE_CODE");
            ele && ele.focus();
        }
        if (! prepared_data.pos_Id) {
            has_error = true;
            error.pos_Id = true;
            let ele = document.getElementById("LOCATION");
            ele && ele.focus();
        }
        if (! prepared_data.provider_id) {
            has_error = true;
            error.provider_id = true;
            let provider_ele = document.getElementById("PROVIDER");
            provider_ele && provider_ele.focus();
        }
        if (! prepared_data.date_of_service) {
            has_error = true;
            error.date_of_service = true;
            let dos_ele = document.getElementById("date_of_service");
            dos_ele && dos_ele.focus();
        }

        if (has_error) {
            toaster.error('', global_constants.constants.mandatory_fields);
            if (this._is_mounted) {
                this.setState({
                    error: error
                });
            }

            return false;
        }

        if( ! prepared_data.dx1 && ! prepared_data.dx2 && ! prepared_data.dx3 && ! prepared_data.dx4 && ! prepared_data.dx5 && ! prepared_data.dx6 && ! prepared_data.dx7 && ! prepared_data.dx8 ) {
            toaster.error('', charge_messages.req_atleast_1dx_for_charge);
            if (this._is_mounted) {
                this.setState({
                    error: error
                });
            }

            return false;
        }
        

        return true
    }
    

    prepare_data_to_save = (bypass_dx_check : boolean = false) => {

        let prepared_data = this.prepare_charge_data();

        if (this._is_mounted) {
            this.setState({
                form_submited: true
            })
        }

        if ( ! this.validateForm(prepared_data)) return;

        // if data is not changed
        if(! this.is_data_unsaved(prepared_data)) return;

        if(! bypass_dx_check) {
            if( ! this.validate_dx_data()) return;
        }

        // return prepared_data;
        save_charge_for_error_correction(prepared_data, this.token).then(res => {
            if (res.data.data && this._is_mounted) {
                this.initialize_charge_details(res.data.data);                
            } else {
                if (res.data.messages && res.data.messages[0].message) {
                    let charge_exception = res.data.messages.filter((item) => {
                        return item.code === global_constants.charge_exception.code
                    })
                    if (charge_exception && charge_exception.length > 0) {
                        if (this._is_mounted) {
                            this.setState({
                                loading: false,
                                show_bummepd_charge_message_popup: true
                            })
                        }
                    }
                    else {
                        let toastr_options = this.show_html_content_toaster(get_all_error(res.data));
                        toaster.error("", toastr_options);
                    }
                   
                }          
            }
        },
        error => { 
            if (error && error.response && error.response.data && error.response.data.messages && error.response.data.messages.length > 0) {
                let charge_exception = error.response.data.messages.filter((item) => {
                    return item.code === global_constants.charge_exception.code
                })
                if (charge_exception && charge_exception.length > 0) {
                    if (this._is_mounted) {
                        this.setState({
                            loading: false,
                            show_bummepd_charge_message_popup: true
                        })
                    }
                } else {
                    if (this._is_mounted) {
                        this.setState({
                            loading: false
                        })
                    }
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error('', toastr_options);
                }
            }
            else {
                if (this._is_mounted) {
                    this.setState({
                        loading: false
                    })
                }
                if (error.response.data) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error("", toastr_options);
                }
            }
        });
    }

    mark_form_submitted = () => {
        if (this._is_mounted) {
            this.setState({
                form_submited: true
            })
        }
   
    }

    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }

    
    is_data_unsaved = (form_object) => {
        if (JSON.stringify(this.initial_form_object) !== JSON.stringify(form_object)) {
            return true;
        }
        return false;
    }


    render_body = () => {
        const { provider_quick_search_format, location_quick_search_format, location_search_data, provider_search_data, referring_physician_quick_search_format, selected_procedure_code, procedure_code_search_data, preauth_list, dx1, dx2, dx3, dx4, dx5, dx6, dx7, dx8, error, form_submited } = this.state;
        const { is_rebill, is_delete, allow_delete, amount, date_of_service, plan_of_care_date, updated_date, start_of_care_date, modified_by, preauth_id, proc_mod1, proc_mod2, proc_mod3, units, total_charge_amount, is_charge_locked } = this.state.charge_details;

        return (
            <Table.Row>
                    <Table.Cell>
                        {this.render_checkbox_element("is_rebill", is_rebill, false)}
                    </Table.Cell>
                <Table.Cell>
                    <AlertConfirm
                        open={this.state.open_confirm_alert}
                        close={() => this.setState({ open_confirm_alert: false })}
                        cancel={() => this.setState({ open_confirm_alert: false })}
                        confirm={this.handle_confirm}
                        message={ERROR_CORRECTION_MESSAGE.DELETE_CHARGE_CONFIRM}
                    />
                    {this.render_checkbox_element("is_delete", is_delete, !allow_delete)}                        
                    </Table.Cell>
                    <Table.Cell className={"custom-datepicker-for-table"}>
                        <div className={error.date_of_service && form_submited ? "error requiredWithBgColor " : ""}>
                            {this.render_calendar_element("date_of_service", date_of_service, error.date_of_service && form_submited)}                       
                        </div>
                    </Table.Cell>
                    <Table.Cell className={"custom-datepicker-for-table"}>
                        {this.render_calendar_element("start_of_care_date", start_of_care_date)}
                    </Table.Cell>
                    <Table.Cell>

                        {/*Provider*/}
                        {this.render_advance_quick_search(enum_type_of_search.provider, provider_search_data, global_constants.constants.advanced_control_type.provider, provider_quick_search_format, 'No Billing Provider Found !', this.get_provider_quick_search_data_list, error.provider_id && form_submited)}
                                                      
                    </Table.Cell>
                    <Table.Cell>
                        {this.render_advance_quick_search(enum_type_of_search.place_of_service, 
                                                                location_search_data, 
                                                                global_constants.constants.advanced_control_type.location, 
                                                                location_quick_search_format, 
                                                                'No Billing Provider Found !', this.get_location_quick_search_data_list, error.pos_Id && form_submited)}                          
                    </Table.Cell>
                    <Table.Cell>
                        {/*Referring Pysician*/}
                        <Form.Field className="advance-quick-search">
                            <AdvancedControl showModal={this.state.show_primary_advanced_control}
                                set_parent_show_modal_state={this.set_parent_show_modal_state}
                                addClicks={this.show_add_referring_modal_primary_physician}
                                disabled_element={false}
                                onGridRowSelection={this.on_primary_physician_grid_row_selection}
                                gridConfig={this.state.primary_physician_search_data}
                                controlId={global_constants.constants.advanced_control_type.referring_physician}
                                onSearch={this.on_referring_Physician_search}
                                search_type={'REFERRING_PHYSICIAN'}
                                headerIdForGridTabNavigation={enum_type_of_search.referring_physician} />

                            <AutoSearchComponent 
                                on_blur={(e) => { this.on_blur_auto_search(e, enum_type_of_search.referring_physician) }}
                                on_focus={this.onFocus}
                                getInputValue={this.get_input_value_referring_physician} default_value={referring_physician_quick_search_format}
                                errorMessage={'No Physician Found !'} prepareRenderList={this.render_suggestion_result} getList={this.get_referring_physician_quick_search_data_list}
                                prepareDataList={this.prepare_suggestion_referring_physician}
                                selectresult={this.on_item_selection_referring_physician} is_focus={false}
                                is_disabled={false}
                                show_clear_search={true}
                                clear_search={this.clear_quick_search_referring_physician}
                                errorClass={''}
                                control_id={'auto' + global_constants.constants.advanced_control_type.referring_physician}
                            />
                        </Form.Field>
                    </Table.Cell>
                    <Table.Cell>
                    {this.render_advance_quick_search("procedure_code", 
                                                    procedure_code_search_data, 
                                                    global_constants.constants.advanced_control_type.procedure_code, 
                                                    selected_procedure_code, 
                                                    'No Record Found !', this.get_procedure_code_quick_search_data_list, error.proc_id && form_submited)}                       
                    </Table.Cell>
                    <Table.Cell>                       
                        {this.render_input_element('charge_amount', amount, this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting && !is_charge_locked ? !(selected_procedure_code.is_amt_enabled == 'true') : true)}
                    </Table.Cell>
                    <Table.Cell>
                        {this.render_dropdown_element('proc_mod1', proc_mod1, this.state.procedure_code_modifiers1_list)}
                    </Table.Cell>
                    <Table.Cell>
                        {this.render_dropdown_element('proc_mod2', proc_mod2, this.state.procedure_code_modifiers2_list)}
                    </Table.Cell>
                    <Table.Cell>
                        {this.render_dropdown_element('proc_mod3', proc_mod3, this.state.procedure_code_modifiers3_list)}
                    </Table.Cell>
                    <Table.Cell>
                        {this.render_input_element('units', units, false)}                        
                    </Table.Cell>
                    <Table.Cell>                      
                        {this.render_dx_element(dx1, 'dx1')}
                    </Table.Cell>
                    <Table.Cell>
                        {this.render_dx_element(dx2, 'dx2')}
                    </Table.Cell>
                    <Table.Cell>
                        {this.render_dx_element(dx3, 'dx3')}
                    </Table.Cell>
                    <Table.Cell>
                        {this.render_dx_element(dx4, 'dx4')}
                    </Table.Cell>
                    <Table.Cell>
                        {this.render_dx_element(dx5, 'dx5')}
                    </Table.Cell>
                    <Table.Cell>                       
                         {this.render_dx_element(dx6, 'dx6')}
                    </Table.Cell>
                    <Table.Cell>                       
                        {this.render_dx_element(dx7, 'dx7')}
                    </Table.Cell>
                    <Table.Cell>                       
                        {this.render_dx_element(dx8, 'dx8')}
                    </Table.Cell>
                    <Table.Cell>
                        {this.render_input_element('total_charge_amount', total_charge_amount, true)}                       
                    </Table.Cell>
                    <Table.Cell>
                        {this.render_dropdown_element('preauth_id', preauth_id, preauth_list)}
                    </Table.Cell>
                    <Table.Cell className={error.plan_of_care_date && form_submited ? "requiredWithBgColor custom-datepicker-for-table" : "custom-datepicker-for-table"}>
                        <div className={error.plan_of_care_date && form_submited ? "error requiredWithBgColor " : ""}>
                            {this.render_calendar_element("plan_of_care_date", plan_of_care_date, error.plan_of_care_date && form_submited)}
                        </div>
                    </Table.Cell>
                    <Table.Cell>
                        {modified_by}
                    </Table.Cell>
                    <Table.Cell>
                        {updated_date}
                    </Table.Cell>
                </Table.Row>
            );
    }

    render_dx_element = (dx_value, name) => {
        return (
            <Form.Field className="advance-quick-search" >
                <DiagnosisCodeQuickSearchComponent
                    diagnosis_code={dx_value}
                    name={name}
                    update_diagnosis_code={(dx) => this.update_diagnosis_code(name, dx)}
                    onBlur={(e) => { this.on_blur_auto_search(e, name) }}
                    onFocus={this.onFocus} />
            </Form.Field>
        );
    }

    on_close_unit_confirmation = () => {
        let charge_state = this.state.charge_details;

        charge_state.units = 1
        charge_state.total_charge_amount = Number(charge_state.units) * Number(this.state.selected_procedure_code.proc_amount);

        if (this._is_mounted) {
            this.setState({
                charge_details: charge_state,
                save_confirmation_unit_box: false
            });
        }
    }

    on_save_unit_confirmation = () => {
        if (this._is_mounted) {
            this.setState({
                save_confirmation_unit_box: false
            });
        }
    }


    refresh_bummepd_charge_modal = () => {
        if (this._is_mounted) {
            this.setState({
                show_bummepd_charge_message_popup: false
            }, () => {
                this.props.history.goBack();
            });
        }
    }
    on_close_bummepd_charge_popup = () => {
        if (this._is_mounted) {
            this.setState({
                show_bummepd_charge_message_popup: false
            });
        }
    }

    render() {
        const {add_physician_modal, save_confirmation_unit_box, referring_physician_quick_search_format } = this.state;

        return (
                <React.Fragment>
                    {save_confirmation_unit_box ?
                        <ConfirmationComponent message={charge_messages.unit_exceeded}
                            save_button_text={"OK"}
                            title='Confirm' show_modal={save_confirmation_unit_box} onCloseModal={this.on_close_unit_confirmation} on_force_save_click={(e) => { this.on_save_unit_confirmation() }} />
                        : ''}
                    <AddReferringPhysician add_physician={add_physician_modal} md_code={referring_physician_quick_search_format.md_code} get_formatted_physician={this.get_formatted_physician_info} />
                    <Form autoComplete="off" className="normal-form padd-r-0">
                        <Grid >
                            <Grid.Column computer={16} className="padd-r-0">
                                <div className="left aligned four wide computer five wide tablet column">
                                    <p style={{ fontSize: '16px', paddingTop: '16px', paddingLeft: '0px' }}>Charge</p>
                                </div>
                                <div className='customtable'>
                                    <div className='table-responsive' id='edit_charge' onScroll={this.onScroll}>
                                        <Table>
                                            {this.render_header()}
                                            <Table.Body>
                                                {this.render_body()}
                                            </Table.Body>
                                        </Table>
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid>
                </Form>
                {this.state.show_bummepd_charge_message_popup ?
                    <ConfirmationComponent message={global_constants.charge_exception.message}
                        title='Confirm' show_modal={this.state.show_bummepd_charge_message_popup}
                        onCloseModal={this.on_close_bummepd_charge_popup}
                        save_button_text='Refresh'
                        on_force_save_click={(e) => { this.refresh_bummepd_charge_modal() }} />
                    : ''}
                </React.Fragment>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};
// Get user and login details from store.
const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting: get_lauch_darkley_key_value(state.launch_darkly, "rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting")
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorCorrectionChargeViewUpdateComponent));