import * as React from 'react';
import { connect } from 'react-redux';
import { toastr as toaster } from 'react-redux-toastr';
import TextareaAutosize from 'react-textarea-autosize';
import { Button, Dimmer, Form, Grid, Loader } from 'semantic-ui-react';
import * as global_constants from '../../global_constants';
import ReportHeader from '../../reports/component/report_header';
import {
    currencyFormatter,
    dateFormatter,
    dateTimeFormatter,
    messages,
    report_ledger_full_configuration_header_id,
} from '../../reports/report_constants';
import { log_error } from '../../shared/action/shared_action';
import GridView from '../../shared/component/grid/grid_child_ui_component';
import * as session_storage from '../../shared/session_storage_utility';
import { set_focus_to_app_header } from '../../shared/tab_navigation_utility';
import { get_all_error, get_columns, get_lauch_darkley_key_value } from '../../shared/utility';
import { get_reset_eobs_list, get_reset_835s, updateEOBValidationErrors } from '../action/payment_action';
import { reset835s, payment_message } from '../payment_constants';
import { argos_bill_1162_editable_835s } from "../../feature_flip_constant"

export class Reset835EobComponent extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            report_data: [],
            is_grid_visible: false,
            is_show_pop_up: false,
            grid_params: null,
            loading: false,
            row_params: {},
            show_date: false,
            is_submitted: false,
            is_error_control: {
                eob_keys: false,
            },
            is_save_button_disabled: false,
            report_rows_count: 0,
            is_reset_disabled: true,
            is_cancel_disabled: true,
        };
    }
    // Define class level variables.
    is_mounted = false;
    eob_keys = [];
    report_container: any;
    reset_835s_column_def = reset835s.column_defs;
    patient_name: string;
    account_no: string;
    current_date: string;
    title: string;
    report_data: any = {
        rows: [],
    };
    total_column_width: number = 0;
    grid_header_height: number = 0;

    search_criteria: any = {
        company: '',
        eob_keys: '',
    };

    initial_search_criteria: any = {
        company: `${
            this.props.user_login_details.user_data.data.user_type_r3_id != global_constants.user_type.Customer
                ? this.props.user_login_details.user_data.data.gpms_code == null ||
                  this.props.user_login_details.user_data.data.gpms_code.trim() == ''
                    ? this.props.user_login_details.user_data.data.company_name
                    : this.props.user_login_details.user_data.data.gpms_code +
                      ' - ' +
                      this.props.user_login_details.user_data.data.company_name
                : this.props.user_login_details.user_data.data.company_name
        }`,
        eob_keys: '',
    };

    is_initial_result = false;
    footer_object = {};
    last_col_width = 0;
    grid_width = 0;
    max_inner_width = 0;
    token = '';
    validation_rule = /^[0-9]+(,[0-9]+)*$/;

    // Fetching the report id and params.
    UNSAFE_componentWillMount = () => {
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.search_criteria = {
            ...this.search_criteria,
            company: `${
                this.props.user_login_details.user_data.data.user_type_r3_id != global_constants.user_type.Customer
                    ? this.props.user_login_details.user_data.data.gpms_code == null ||
                      this.props.user_login_details.user_data.data.gpms_code.trim() == ''
                        ? this.props.user_login_details.user_data.data.company_name
                        : this.props.user_login_details.user_data.data.gpms_code +
                          ' - ' +
                          this.props.user_login_details.user_data.data.company_name
                    : this.props.user_login_details.user_data.data.company_name
            }`,
        };

        if (session_storage.get('reset_835s_search_criteria')) {
            this.is_initial_result = true;
            this.search_criteria = {
                ...session_storage.get('reset_835s_search_criteria'),
            };
        }
    };

    componentWillUnmount = () => {
        document.body.classList.remove('reports');
        this.is_mounted = false;
        this.search_criteria = {
            ...this.initial_search_criteria,
        };
        this.report_data = [];
        session_storage.remove('reset_835s_search_criteria');
    };

    componentDidMount() {
        this.is_mounted = true;
        document.body.classList.add('reports');
        if (this.is_initial_result) {
            this.get_report_data();
        }
    }

    //Get Report Data.
    get_report_data = async () => {
        // Create Query Param From Field
        this.grid_width = document.getElementsByClassName('report-wrapper')[0].clientWidth;
        let param = `eob_keys=${this.search_criteria.eob_keys}`;
        this.report_data['rows'] = [];
        this.eob_keys = [];
        if (this.is_mounted) {
            this.setState({
                search_criteria: this.search_criteria,
                loading: true,
                is_grid_visible: false,
                is_save_button_disabled: true,
            });
        }
        await get_reset_eobs_list(param, this.token).then(
            (response) => {
                if (response.data && response.data.status === 1) {
                    response.data.data = this.row_data_formatter(response.data.data, reset835s.column_defs);
                    this.max_inner_width = 0;
                    this.report_data['rows'] = response.data.data
                        ? response.data.data.map((item: any) => {
                              this.eob_keys.push(item.eob_key);
                              item['group'] = '';
                              const child_column = reset835s.child_column.map((value) => ({
                                  ...value,
                              }));
                              if (item.eob_payment_adjustment_list.length > 0) {
                                  item['details'] = this.row_data_formatter(
                                      item.eob_payment_adjustment_list,
                                      reset835s.child_column
                                  );
                                  item = {
                                      ...item,
                                      participants: [
                                          {
                                              ...item,
                                              child_column,
                                              grid_class: 'eob_list',
                                          },
                                      ],
                                  };
                              } else {
                                  item = {
                                      ...item,
                                      participants: [],
                                  };
                              }
                              delete item['details'];
                              return item;
                          })
                        : [];
                    this.total_column_width = 0;
                    const grid_height = get_columns(this.report_data['rows'], this.reset_835s_column_def);

                    this.grid_header_height = grid_height.header_height;
                    this.total_column_width = grid_height.total_column_width;
                    let searched_eob_keys = this.search_criteria.eob_keys.split(',');
                    const eob_keys = searched_eob_keys.filter((value) => {
                        return this.eob_keys.indexOf(parseInt(value)) === -1;
                    });
                    if (this.is_mounted) {
                        eob_keys.length > 0 && toaster.error('', reset835s.invalid_835_msg);
                        this.setState({
                            loading: false,
                            report_data: this.report_data,
                            is_grid_visible: true,
                            show_date: true,
                            is_save_button_disabled: false,
                            is_reset_disabled: false,
                            is_cancel_disabled: false,
                        });
                    }
                } else {
                    response.data.messages && log_error(response.data.messages[0].message.indexOf('<br') > -1);
                    if (response.data.messages && response.data.messages[0].message.indexOf('<br') > -1) {
                        const toastr_options = this.show_html_content_toaster(
                            get_all_error(response.data.messages[0].message)
                        );
                        toaster.error('', toastr_options);
                    }
                    toaster.error('', reset835s.invalid_835_msg);
                    if (this.is_mounted) {
                        this.setState({
                            loading: false,
                            report_data: this.report_data,
                            is_grid_visible: true,
                            show_date: true,
                            is_save_button_disabled: false,
                            is_reset_disabled: true,
                            is_cancel_disabled: true,
                        });
                    }
                }
            },
            (error) => {
                if (this.is_mounted) {
                    this.setState({
                        loading: false,
                        is_save_button_disabled: false,
                        is_reset_disabled: true,
                        is_cancel_disabled: true,
                    });
                }
                log_error(error);
                if (error.response.data) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error('', toastr_options);
                }
            }
        );
    };

    input_change_handler = (e: any) => {
        const { name, value } = e.target;
        this.search_criteria = {
            ...this.search_criteria,
            [name]: value,
        };
        this.setState((prev_state) => ({
            is_error_control: {
                ...this.state.is_error_control,
                [name]: value && value.trim() ? !this.validation_rule.test(this.search_criteria.eob_keys) : true,
            },
        }));
    };

    //Required Field Validator.
    required_field_validator = () => {
        let is_error = false;
        let searched_eob_keys = this.search_criteria.eob_keys.split(',');
        if (!this.search_criteria.eob_keys && this.is_mounted) {
            is_error = true;
            toaster.error('', messages.mandatory_fields);
        } else {
            if (/\s/g.test(this.search_criteria.eob_keys)) {
                is_error = true;
                is_error && toaster.error('', reset835s.msg);
            } else {
                let is_key_invalid = searched_eob_keys.every((item) => {
                    return parseInt(item) > 2147483648;
                });
                if (is_key_invalid) {
                    is_error = true;
                    is_error && toaster.error('', reset835s.invalid_835);
                } else {
                    is_error = !this.validation_rule.test(this.search_criteria.eob_keys);
                    is_error && toaster.error('', reset835s.msg);
                }
            }
        }
        this.setState((prev_state) => ({
            is_error_control: {
                ...prev_state.is_error,
                eob_keys: is_error,
            },
        }));
        return is_error;
    };

    //Function used to Run Report based on controllers values.
    run_report_handler = (e) => {
        var is_error = false;
        if (this.is_mounted) {
            this.setState({ is_submitted: true });
        }

        // Validate Required Field
        is_error = this.required_field_validator();
        if (is_error) {
            return;
        }
        this.get_report_data();
        session_storage.set('reset_835s_search_criteria', this.search_criteria);
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    //FUnction use to format the grid data on load.
    row_data_formatter = (data, column_def) => {
        data.map((row) => {
            column_def.map((value, index) => {
                switch (value.type) {
                    case 'date':
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined
                                ? dateFormatter(row[value.field])
                                : '';
                        break;
                    case 'dateTime':
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined
                                ? dateTimeFormatter(row[value.field])
                                : '';
                        break;
                    case 'currency':
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined
                                ? currencyFormatter(row[value.field])
                                : currencyFormatter(0);
                        break;
                    case 'number':
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined ? parseInt(row[value.field]) : 0;
                        break;
                    case 'boolean':
                        row[value.field] = row[value.field] ? 'Active' : 'Inactive';
                        break;
                    default:
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined
                                ? row[value.field].toString().trim()
                                : '';
                }
            });
            return row;
        });
        return data;
    };

    //Function calls on click of Clear button and clear all the searched result as well as criteria.
    clear_handler = () => {
        if (this.is_mounted) {
            this.setState({
                loading: true,
                is_grid_visible: false,
            });
        }
        this.search_criteria = {
            ...this.initial_search_criteria,
        };

        this.report_data = [];
        session_storage.remove('reset_835s_search_criteria');
        if (this.is_mounted) {
            this.setState({
                report_data: [],
                is_grid_visible: false,
                is_submitted: false,
                show_date: false,
                loading: false,
                is_reset_disabled: true,
                is_cancel_disabled: true,
                is_error: {
                    eob_keys: false,
                },
            });
        }
    };

    //Function calls on click of Clear button and clear all the searched result as well as criteria.
    cancel_handler = () => {
        this.report_data = [];
        if (this.is_mounted) {
            this.setState({
                is_grid_visible: false,
                is_submitted: false,
                show_date: false,
                loading: false,
                is_reset_disabled: true,
                is_cancel_disabled: true,
                is_error: {
                    eob_keys: false,
                },
            });
        }
    };

    // Show multiple messages
    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ),
            timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true,
        };
    };

    //Function calls after click of filter button and recalculate the footer sum.
    on_filter_button_click = () => {
        var row_api = this.state.grid_params.api;
        var footer_cell_data;
        let row_count = 0; // Update row count
        if (row_api) {
            row_api.forEachNodeAfterFilter((row, index) => {
                if (row.group) {
                    row_count++;
                    row = row.data;
                }
            });
        }
        // Update row count
        this.setState({
            report_rows_count: row_count, //this.state.grid_params.api.getModel().getRowCount()
        });
    };

    //Function calls on intialization of Ag-Grid and catch its reference.
    get_grid_ref = (grid_params) => {
        if (this.is_mounted) {
            this.setState({
                grid_params,
                report_rows_count: grid_params.api.getModel().getRowCount(),
            });
        }
        if (this.total_column_width > this.grid_width) {
            this.reset_835s_column_def.filter((item, index) => {
                grid_params.columnApi.setColumnWidth(item.field, item['width'], false);
            });
        }
    };

    //Function calls on initialization of export report data
    handle_navigation_after_last_element = (event) => {
        // apply a check where grid is empty
        if (
            !event.shiftKey &&
            event.keyCode == '9' &&
            (this.state.report_data == null || this.state.report_data.length == 0)
        ) {
            set_focus_to_app_header(event);
        }
    };

    // Select case_id on checkbox
    on_check_box_selection = (items) => {
        if (this.is_mounted) {
            this.setState({
                selected_eobs: items,
            });
        }
    };

    is_row_selectable = (params) => {
        if (params.data && params.data.participants) return true;

        return false;
    }

    // To handle focus on grid.
    handle_focus_for_grid = (event) => {
        // apply a check where grid is empty
        // if it is not empty send the focus to app header
        if (
            !event.shiftKey &&
            event.keyCode == '9' &&
            (!this.state.is_grid_visible || this.report_data.rows == null || this.report_data.rows.length == 0)
        ) {
            set_focus_to_app_header(event);
        }
    };

    reset_eobs_handler = async () => {
        if (this.state.selected_eobs && this.state.selected_eobs.length > 0) {
            // Create Query Param from checked items
            const keys = this.state.selected_eobs.map((value) => value.eob_key);
            let param = `eob_keys=${keys.join()}`;
            if (this.is_mounted) {
                this.setState({
                    loading: true,
                    is_save_button_disabled: true,
                });
            }
            await get_reset_835s(param, this.token).then(
                (response) => {
					if (response.data && response.data.status === 1) {
						if (argos_bill_1162_editable_835s) {
							this.reRunValidationForResetEobs(response.data.data);
						}

                        this.update_reset_835s_status(response.data.data);
                        if (this.is_mounted) {
                            this.setState({
                                selected_eobs: [],
                                loading: false,
                                is_save_button_disabled: false,
                                is_reset_disabled: false,
                                is_cancel_disabled: false,
                            });
                        }
                    } else {
                        response.data.messages && log_error(response.data.messages[0].message.indexOf('<br') > -1);
                        if (response.data.messages && response.data.messages[0].message.indexOf('<br') > -1) {
                            const toastr_options = this.show_html_content_toaster(
                                get_all_error(response.data.messages[0].message)
                            );
                            toaster.error('', toastr_options);
                        }
                        if (this.is_mounted) {
                            this.setState({
                                selected_eobs: [],
                                loading: false,
                                is_save_button_disabled: false,
                                is_reset_disabled: false,
                                is_cancel_disabled: false,
                            });
                        }
                    }
                },
                (error) => {
                    if (this.is_mounted) {
                        this.setState({
                            loading: false,
                            is_save_button_disabled: false,
                            is_reset_disabled: false,
                            is_cancel_disabled: false,
                        });
                    }
                    log_error(error);
                    if (error.response.data) {
                        const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                        toaster.error('', toastr_options);
                    }
                }
            );
        } else {
            toaster.error('', payment_message.RESET_835s_ERROR_SELECTION);
        }
	};

	reRunValidationForResetEobs = (eob_data) => {
		eob_data = eob_data.filter(eob => 
			eob.reset_status === 'SUCCESS'
		);

		eob_data.forEach(eob => {
			try {
				let response = updateEOBValidationErrors(eob.eob_key, this.token).then(res => {
					return res;
				});
			} catch (error) {
				console.log(error);
			}
		});
	}

    update_reset_835s_status = (eob_keys_data = []) => {
        const eob_keys_success = [];
        const eob_keys_fail = [];
        eob_keys_data.map((item) => {
            if (item.reset_status === 'SUCCESS') {
                eob_keys_success.push(item.eob_key);
                this.report_data.rows = this.report_data.rows.map((row) => {
                    if (row.eob_key === item.eob_key) {
                        return { ...row, status: 'Submitted' };
                    }
                    return { ...row };
                });
            } else {
                eob_keys_fail.push(item.eob_key);
            }
        });

        if (eob_keys_fail.length > 0) {
            toaster.error('', payment_message.RESET_835s_ERROR);
        } else if (eob_keys_success.length > 0) {
            toaster.success('', payment_message.RESET_835s_SUCCESS);
        }
        //clear checkbox selection
        if (this.state.grid_params) {
            this.state.grid_params.api.deselectAll();
        }
    }

    render() {
        return (
            <div className={'common-forms-add reset835_eob'}>
                <Dimmer active={this.state.loading}>
                    <Loader size="massive">Loading</Loader>
                </Dimmer>
                <div className={'common-forms-search report-framework'}>
                    <ReportHeader title={'Reset 835s'} />
                    <div
                        id="report-scrollable-area"
                        className="report-wrapper"
                        style={this.state.is_grid_visible ? { flex: 1 } : {}}
                    >
                        <div
                            className="patient-search-form  patient_search_bottom_padding"
                            id="report-criteria-container"
                        >
                            <Form autoComplete="off">
                                <Grid>
                                    <Grid.Column tablet={8} computer={4}>
                                        <Form.Field>
                                            <label>Company</label>
                                            <label className={'values'}>
                                                {this.search_criteria.company ? this.search_criteria.company : ''}
                                            </label>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column tablet={8} computer={12}>
                                        <Form.Field>
                                            <label>
                                                Enter 835 Key(s)
                                                <span
                                                    style={{ fontStyle: 'italic' }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: ' (Separate each 835 Key with a comma)',
                                                    }}
                                                />
                                                <span
                                                    className={
                                                        this.state.is_submitted && this.state.is_error_control.eob_keys
                                                            ? 'req-alert'
                                                            : 'req-alert_normal'
                                                    }
                                                    dangerouslySetInnerHTML={{ __html: ' (required)' }}
                                                />
                                            </label>
                                            <TextareaAutosize
                                                autoComplete="new-password"
                                                name={'eob_keys'}
                                                maxLength={250}
                                                id={'eob_keys'}
                                                onChange={this.input_change_handler}
                                                value={
                                                    this.search_criteria.eob_keys ? this.search_criteria.eob_keys : ''
                                                }
                                                className={
                                                    this.state.is_error_control.eob_keys && this.state.is_submitted
                                                        ? 'req-background-inp'
                                                        : ''
                                                }
                                            />
                                            <p
                                                className={'field_label'}
                                                style={{ color: '#757575', fontSize: 10, marginTop: 5 }}
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        'All of the 835 Keys entered must belong to the indicated company.',
                                                }}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid>
                                <Grid>
                                    <Grid.Column tablet={16} computer={16} textAlign="right">
                                        <Button
                                            type="reset"
                                            id="clear_report_button"
                                            basic
                                            onClick={this.clear_handler}
                                        >
                                            Clear
                                        </Button>
                                        <Button
                                            id="create_report_button"
                                            className="primary"
                                            onClick={this.run_report_handler}
                                            type="submit"
                                            onKeyDown={this.handle_focus_for_grid}
                                            style={{ marginRight: '0' }}
                                            disabled={this.state.is_save_button_disabled}
                                        >
                                            Search
                                        </Button>
                                    </Grid.Column>
                                </Grid>
                            </Form>
                        </div>
                        {this.state.is_grid_visible && (
                            <React.Fragment>
                                <Grid className="dateTime">
                                    <Grid.Column tablet={5} computer={4} textAlign="left">
                                        <p style={{ fontSize: '16px', minHeight: 22 }}>Search Results</p>
                                    </Grid.Column>
                                    <Grid.Column tablet={5} computer={8} textAlign="center">
                                        {
                                            <p style={{ fontSize: '16px', minHeight: 22 }}>
                                                {`${this.state.report_rows_count} ${
                                                    this.state.report_rows_count == 1
                                                        ? ' record shown'
                                                        : ' records shown'
                                                }`}
                                            </p>
                                        }
                                    </Grid.Column>
                                    <Grid.Column tablet={2} computer={4} textAlign="right" />
                                </Grid>
                                <GridView
                                    id={reset835s.report_grid_id}
                                    row={this.report_data.rows}
                                    column={this.reset_835s_column_def}
                                    headerHeight={this.grid_header_height}
                                    style={{ height: '100%' }}
                                    wrapperStyle={{ width: '100%', height: '100%', display: 'flex', flex: 1 }}
                                    wrapperClass={'grid_wrapper accordian_grid'}
                                    enableColResize={true}
                                    emptyMessage={messages.no_records_found}
                                    get_grid_ref={this.get_grid_ref}
                                    headerIdForTabNavigation={report_ledger_full_configuration_header_id}
                                    on_filter_button_click={() => {
                                        this.on_filter_button_click();
                                    }}
                                    checkboxSelection={true}
                                    onRowSelection={this.on_check_box_selection}
                                    suppressSizeToFit={true}
                                    isRowSelectable={this.is_row_selectable}
                                />
                            </React.Fragment>
                        )}
                    </div>
                    <div
                        className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area"
                        id="applicationFooterSticky"
                    >
                        <Grid.Column computer={16} tablet={16} textAlign="right">
                            <Button
                                id="cancel_eob"
                                type="submit"
                                basic
                                onClick={this.cancel_handler}
                                disabled={this.state.is_cancel_disabled}
                            >
                                Cancel
                            </Button>
                            <Button
                                id="reset_eob"
                                type="submit"
                                onClick={this.reset_eobs_handler}
                                onKeyDown={set_focus_to_app_header}
                                primary
                                disabled={this.state.is_reset_disabled}
                            >
                                Reset
                            </Button>
                        </Grid.Column>
                    </div>
                </div>
            </div>
        );
    }
}

//Get user and login details from store.
const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        selected_patient: state.patient_details.patient_header,
		is_expand: state.shared_details.is_expand,
		features: {
			argosBill1162Editable835S: get_lauch_darkley_key_value(state.launch_darkly, argos_bill_1162_editable_835s)
		}
    };
};

export default connect(mapStateToProps)(Reset835EobComponent);
