import * as React from 'react'
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import { ExpectedRateMultipleFeeSchedule, expected_rate } from '../../constants';
import CurrencyFormatter from '../../../../shared/component/currency_formatter';
import { custom_date_format, get_lauch_darkley_key_value } from '../../../../shared/utility';
import { date_format } from '../../../../global_constants';
import { argos_bill_1627_expected_rates_improvements } from './../../../../feature_flip_constant';

export class FeeProcedureCodesComponent extends React.Component<any, any>{
    _is_mounted = false;

    constructor(props) {
        super(props);
        this.state = {
            hover_id: ''
        };
    }

    componentDidMount = () => {
        this._is_mounted = true;
    }

    componentWillUnmount = () => {
        this._is_mounted = false;
    }
    
    get_default_values = (value) => {
        if (value == null)
            value = '';
        return value;
    }
   
    handle_carve_out_change = (procedure_code_id, e) => {
        const { checked } = e.target;
        this.props.updateExpectedRatFeeDefinitionProcedureCode &&
            this.props.updateExpectedRatFeeDefinitionProcedureCode(procedure_code_id, expected_rate.identifier.is_carve_out, checked);
    };

    handle_unit_change = (procedure_code_id, value) => {
        this.props.updateExpectedRatFeeDefinitionProcedureCode &&
            this.props.updateExpectedRatFeeDefinitionProcedureCode(procedure_code_id, expected_rate.identifier.expected_reimbursement_unit, value);
    };

    handle_updated_billed_amount_change = (procedure_code_id, value) => {
        this.props.updateExpectedRatFeeDefinitionProcedureCode &&
            this.props.updateExpectedRatFeeDefinitionProcedureCode(procedure_code_id, expected_rate.identifier.updated_billed_amount, value);
    };

    handle_updated_mppr_rate_change = (procedure_code_id, value) => {
        this.props.updateExpectedRatFeeDefinitionProcedureCode &&
            this.props.updateExpectedRatFeeDefinitionProcedureCode(procedure_code_id, expected_rate.identifier.mppr_rate, value);
    };

    get_validation_msg = (field_name, procedure_code_id) => {
        let validation_msg = '';
        switch (field_name) {
            case expected_rate.identifier.expected_reimbursement_unit:
                if (this.props.validation && this.props.validation.proc_codes_validation_list && this.props.validation.proc_codes_validation_list.length > 0) {
                    var validation_list = this.props.validation.proc_codes_validation_list.filter(x => x.procedure_code_id == procedure_code_id);
                    if (validation_list > 0)
                        validation_msg = validation_list[0][field_name]
                }
                break;
        }
        return validation_msg;
    }

    get_validation_text_div = (validation_mesage) => {
        return (
            <div className='requiredText'>
                {
                    validation_mesage
                }
            </div>
        );
    }

    hoverOn = (procedure_code_id) => {
        if (this._is_mounted) {
            this.setState({ hover_id: procedure_code_id })
        }
    };

    hoverOff = () => {
        if (this._is_mounted) {
            this.setState({ hover_id: '' })
        }
    };

    render_multiple_fee_schedules_Cell(proc_code){
        const expectedRateMultipleFeeScheduleFFValue = this.props.features.ff_sumaj_B30566_expected_rate_multiple_fee_schedules;

        if(expectedRateMultipleFeeScheduleFFValue){
            return (
                <Table.Cell>
                <CurrencyFormatter
                    key={'updated_billed_amount_' + this.props.data.procedure_code_id}
                    id={'updated_billed_amount_' + proc_code.procedure_code_id}
                    onChange={value => this.handle_updated_billed_amount_change(proc_code.procedure_code_id, value)}
                    prefix='$'
                    focused={false}
                    defaultValue={proc_code.updated_billed_amount}
                    allowNegative={false}
                    className={'numberFormat fee_unit'}
                />
            </Table.Cell>
            );
        }
    }
    
    get_proc_code_render_contents = () => {
        return (
            <React.Fragment>
                {
                    this.props.data.map((proc_code) => {
                        return (
                            <Table.Row key={proc_code.procedure_code_id} className="proc-code-row" onMouseEnter={e => this.hoverOn(proc_code.procedure_code_id)}
                            onMouseLeave={e => this.hoverOff()}>
                            <Table.Cell>
                            </Table.Cell>
                            <Table.Cell>
                                <label>{proc_code.proc_code}</label>
                            </Table.Cell>
                            <Table.Cell>
                                <label>${proc_code.std_chg_amt}</label>
                            </Table.Cell>
                            { this.render_multiple_fee_schedules_Cell(proc_code) }
                            <Table.Cell>
                                <CurrencyFormatter
                                    key={'unit_' + this.props.data.procedure_code_id}
                                    id={'unit_' + proc_code.procedure_code_id}
                                    onChange={value => this.handle_unit_change(proc_code.procedure_code_id, value)}
                                    prefix='$'
                                    focused={false}
                                    defaultValue={proc_code.expected_reimbursement_unit}
                                    allowNegative={false}
                                    className= {this.props.has_validation(expected_rate.identifier.expected_reimbursement_unit, proc_code.procedure_code_id) ? 'numberFormat req-background-inp fee_unit' : 'numberFormat fee_unit'}
                                    />
                                    {(this.props.fee_rate_type_id == expected_rate.fee_type.flat_rate || (this.props.features.argosBill1627ExpectedRatesImprovements && this.props.fee_rate_type_id == expected_rate.fee_type.fee_for_service && this.props.fee_max_number_of_units)) &&
                                    <label className={(proc_code.is_carve_out || this.state.hover_id == proc_code.procedure_code_id) ? 'carve-out' : 'carve-out carve-out-hide'}>
                                        <input type="checkbox" tabIndex={-1}
                                        id={"carve_out_" + proc_code.procedure_code_id}
                                        checked={proc_code.is_carve_out}
                                        onChange={e => this.handle_carve_out_change(proc_code.procedure_code_id, e)}
                                    />
                                    Carve-out
                                </label>}
                                {proc_code.validation_msg_for_reimbursement_unit && this.get_validation_text_div(proc_code.validation_msg_for_reimbursement_unit)}
                            </Table.Cell>
                            {(this.props.fee_rate_type_id == expected_rate.fee_type.fee_for_service) && this.props.apply_mppr && <Table.Cell>
                                <CurrencyFormatter
                                    key={'mppr_rate_' + this.props.data.procedure_code_id}
                                    id={'mppr_rate_' + proc_code.procedure_code_id}
                                    onChange={value => this.handle_updated_mppr_rate_change(proc_code.procedure_code_id, value)}
                                    prefix='$'
                                    focused={false}
                                    defaultValue={proc_code.mppr_rate}
                                    allowNegative={false}
                                    className='numberFormat fee_unit'/>

                            </Table.Cell>}
                            <Table.Cell>
                                <label>{custom_date_format(proc_code.last_updated, date_format.date_time_without_sec_format)}</label>
                            </Table.Cell>
                            </Table.Row>
                        )
                    })
                }
            </React.Fragment>
        ) 
    }

    render_default_billed_header(){
        const expectedRateMultipleFeeScheduleFFValue = this.props.features.ff_sumaj_B30566_expected_rate_multiple_fee_schedules;

        const { multiple_fee_schedule_column_defs } = expected_rate;
        
        if(expectedRateMultipleFeeScheduleFFValue){
            return multiple_fee_schedule_column_defs.default_billed;
        } else {
            return multiple_fee_schedule_column_defs.billed_amount;            
        }        
    }

    render_multiple_fee_schedules_header(){
        const expectedRateMultipleFeeScheduleFFValue = this.props.features.ff_sumaj_B30566_expected_rate_multiple_fee_schedules;
        
        const { multiple_fee_schedule_column_defs } = expected_rate;

        if(expectedRateMultipleFeeScheduleFFValue){
            return (
            <>
                <Table.HeaderCell style={{ width: '15%', }}>CPT Codes</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '15%', }}>{this.render_default_billed_header()}</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '15%', }}>{ multiple_fee_schedule_column_defs.updated_billed_amount }</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '25%', }}>Expected Reimbursement / Unit</Table.HeaderCell>                        
                {(this.props.fee_rate_type_id == expected_rate.fee_type.fee_for_service) && this.props.apply_mppr ? (
                    <Table.HeaderCell style={{ width: '15%', }}>MPPR Rate</Table.HeaderCell>
                ) : null}
            </>
            );
        } else {
            return (
            <>
                <Table.HeaderCell style={{ width: '15%', }}>CPT Codes</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '20%', }}>{this.render_default_billed_header()}</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '20%', }}>Expected Reimbursement / Unit</Table.HeaderCell>
                {(this.props.fee_rate_type_id == expected_rate.fee_type.fee_for_service) && this.props.apply_mppr? (
                    <Table.HeaderCell style={{ width: '20%', }}>MPPR Rate</Table.HeaderCell>
                ) : null}
            </>
            );            
        }
    }

    render() {
        return (
            <React.Fragment>
                <Table className="fee-procedure-codes" id={"fee-procedure-codes_" + this.props.expected_rate_fee_id} >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{ width: '5%', }}></Table.HeaderCell>
                            { this.render_multiple_fee_schedules_header() }
                            <Table.HeaderCell style={{ width: '25%', }}>Last Updated</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            this.get_proc_code_render_contents()
                        }
                    </Table.Body>
                </Table>
            </React.Fragment>
        )
    }
}

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        features: {
            ff_sumaj_B30566_expected_rate_multiple_fee_schedules: get_lauch_darkley_key_value(state.launch_darkly, ExpectedRateMultipleFeeSchedule.sumajB30566ExpectedRateMultipleFeeSchedules),
            argosBill1627ExpectedRatesImprovements: get_lauch_darkley_key_value(state.launch_darkly, argos_bill_1627_expected_rates_improvements)
        }
    };
};
export default connect(mapStateToProps)(FeeProcedureCodesComponent);