import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dimmer, Form, Loader, Table } from "semantic-ui-react";
import * as NumberFormat from 'react-number-format';
import * as moment from 'moment';
import { clearTimeout, setTimeout } from 'timers';
import AutoSearchComponent from './../../shared/component/auto_search_component';
import AdvancedControl from "../../shared/component/advanced_control";
import Selection from '../../shared/component/selection_component';
import CurrencyFormatter from '../../shared/component/currency_formatter';
import * as global_constants from '../../global_constants';
import { procedure_code_advance_search, procedure_code_quick_search, procedure_code_advance_search_by_company, procedure_code_quick_search_by_company} from '../../shared/action/autosearch_action';
import * as  shared_constants from '../../shared/shared_constants';
import { content_length, content_width } from '../../shared/component/grid/utils';
import { charge_status, enum_page_name, charge_amount_status } from '../charge_constant';
import { get_multiple_fee_schedules } from '../../shared/action/shared_action';
import { get_lauch_darkley_key_value } from '../../shared/utility';
import { getSelectedResultObject } from '../../shared/component/auto_search_utility';

export class ChargeDetailRowComponent extends React.Component<any, any> {
    _is_mounted = false;
    current_setTime_out;
    proc_code_key_down_key_value = 0;
    auto_search_event_object: any = {};
    auto_search_values = [];
    blur_call = false;
    item_selection_call = false;
    selected_procedure = {};
    charge_detail_item = {};
    company_id = this.props.company_id ? this.props.company_id : this.props.user_login_details.user_data.data.company_id;
    is_audit_page = this.props.page_name ? this.props.page_name == enum_page_name.audit : false;
    is_edit_for_audit_review = this.props.page_name ? (this.props.page_name == enum_page_name.audit || this.props.page_name == enum_page_name.review) : false;
    constructor(props) {
        super(props);

        this.state = {
            procedure_code_search_data: {},
            selected_procedure_code: {
                "title": '',
                "label": ''
            },
            grid_conf: {
                isPagination: true,
                gridAutoHeight: true,
                selectionType: 'single',
                paginationPageSize: 20,
            },
            charge_detail_item: this.props.data,
            old_data: this.props.data,
            old_proc_code:this.props.data.proc_code,
            loading: false
        }
    }

    componentDidMount = () => {
        this.props.onRef(this);
        this._is_mounted = true;
        if (this._is_mounted) {
            var format_data = this.get_selected_peoc_item(this.props.data.proc_code);
            this.setState({

                charge_detail_item: this.props.data,
                selected_procedure_code: format_data
            })
        }
        
    }

    componentWillUnmount = () => {
        this.props.onRef(undefined)
        this._is_mounted = false;
        if (this.current_setTime_out) {
            clearTimeout(this.current_setTime_out);
        }
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.data != this.props.data) {
            if (this._is_mounted) {
                var format_data = this.get_selected_peoc_item(this.props.data.proc_code);
                this.setState({
                    charge_detail_item: this.props.data,
                     selected_procedure_code: format_data
                });

            }
        } else if (previousProps.update_child_force != this.props.update_child_force && this.props.update_child_force==true) {
            var format_data = this.get_selected_peoc_item(this.props.data.proc_code);
            if (this._is_mounted) {
                this.setState({
                    charge_detail_item: this.props.data,
                    selected_procedure_code: format_data
                });
            }
        }
    }

    get_updated_data = () => {
        return this.state.charge_detail_item
    }

    amount_change = (value, id, index) => {
        var charge_state = { ...this.state.charge_detail_item };
        charge_state.proc_code.std_chg_amt = Number(value);
        charge_state.total_amount = Number(charge_state.proc_code.std_chg_amt) * Number(charge_state.units);
        if (this._is_mounted) {
            this.setState({
                charge_detail_item: charge_state,
            });
        }
    }

    on_change_unit_change = (value) => {
        var isnum = /^\d+$/.test(value);
        var charge_state = { ...this.state.charge_detail_item };
        
        if (isnum) {
            charge_state.units = Number(value);


        } else {
            charge_state.units = ''
        }
        charge_state.total_amount = Number(charge_state.units) * Number(charge_state.proc_code.std_chg_amt);
        charge_state.is_charge_changed = this.state.old_data.units != Number(charge_state.units);
        if (this._is_mounted) {
            this.setState({
                charge_detail_item: charge_state,
            });
        }
    }

    on_dx_sequence_keypress = (event) => {
        var regex = new RegExp("/[^0-9,]+/g");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
            if (((event.which >= 48 && event.which <= 57) || (event.which == 44) || (event.which == 9)) ||
                ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 44) || (event.charCode == 9))) {
                return true;
            }
            else {
                if (!(event.which == 8)) {
                    event.preventDefault();
                }
                else {
                    return false;
                }
            }
        }
    }

    on_dx_sequence_change = (value) => {
        var charge_state = { ...this.state.charge_detail_item };
        charge_state.dx = value;
        if (this._is_mounted) {
            this.setState({
                charge_detail_item: charge_state,
            });
        }
    }

    on_provider_code_grid_row_selection = (selected_row) => {
        this.on_row_selection_advance_search(selected_row);
    }

    on_row_selection_advance_search = async (selected_row) => {
        var id = '';
        var name = '';
        var code = '';
        var selected_data = '';
        if (selected_row) {
            id = selected_row.procedure_code_id;
            if ((selected_row.proc_code != null && selected_row.proc_code != undefined) || (selected_row.name != null && selected_row.name != undefined)) {
                code = (selected_row.proc_code == null && selected_row.proc_code == undefined) ? '' : selected_row.proc_code;
                name = (selected_row.proc_desc1 == null && selected_row.proc_desc1 == undefined) ? '' : selected_row.proc_desc1;
            }
        }
        selected_data = code + " - " + name;
        var format_data = { "label": '', "title": '' };
        if (parseInt(id) > 0) {
            // Set Auto search control for provider
            selected_row.is_active = selected_row.is_active ? selected_row.is_active.toString() : "false";
            format_data = {
                ...selected_row,
                "is_amt_enabled": `${(selected_row.is_amt_enabled == true || selected_row.is_amt_enabled == "true") ? "true" : "false"}`,
                "is_unit_enabled": `${(selected_row.is_unit_enabled == true || selected_row.is_unit_enabled == "true") ? "true" : "false"}`,
                "title": id.toString(),
                "label": selected_data,
            };
        }
        var charge_state = { ...this.state.charge_detail_item };
        charge_state.proc_code = selected_row;
        await this.with_loading(this.calculate_charge_amount)(charge_state)
        charge_state.total_amount = Number(charge_state.proc_code.std_chg_amt) * Number(charge_state.units);
        charge_state.is_charge_changed = this.state.old_proc_code.proc_code != selected_row.proc_code;
        if (this._is_mounted) {
            this.setState({
                selected_procedure_code: format_data,
                charge_detail_item: charge_state,

            });
        }
    }

    column_def_format = (column_def, rows) => {
        if (rows && rows.length > 0) {
            let address1_length = 0, address2_length = 0;
            const result = rows.map((item) => {
                const text_address1_length = content_length(item.proc_desc1);
                if (text_address1_length > address1_length) {
                    address1_length = text_address1_length;
                }
                return item;
            });
            column_def = column_def.map(item => {
                if (item.field == 'proc_desc1') {
                    return { ...item, minWidth: content_width(address1_length) };
                }
                return item;
            })
        }
        return column_def;
    }

    on_procedure_code_search = async (params) => {
        var grid_data = [];
        let search_data = this.is_edit_for_audit_review ? await procedure_code_advance_search_by_company(this.company_id,params,
            this.props.user_login_details.user_data.data.token_details.access_token).then(res => res.data) : await procedure_code_advance_search(params,
            this.props.user_login_details.user_data.data.token_details.access_token).then(res => res.data);
        const search_result = search_data.data !== null ? search_data.data.result : [];

        let col_def = this.column_def_format(shared_constants.search_procedure_code_configuration('procedure_code_searcch_' + this.props.row_count).column_defs, search_result);        
        grid_data = {
            ...this.state.grid_conf, rows: search_result,
            column: col_def,
            messages: search_data.messages
        };
        if (this._is_mounted) {
            this.setState({
                loading: false,
                procedure_code_search_data: grid_data
            });
        }
    }
    //Quick Search seggation for procedure code
    render_suggestion_result = (props) => {
        return (
            <div key={props.title} tabIndex={0} className="item_auto_search">
                <div key={props.title} className="fs_13">
                    <span>{props.label}</span>
                </div>
            </div>

        )
    }
    // get the quick search procedure code
    get_procedure_code_quick_search_data_list = async (search_keyword) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        return this.is_edit_for_audit_review ? await procedure_code_quick_search_by_company(this.company_id,search_keyword, token):await procedure_code_quick_search(search_keyword, token);
    }
    // preapare the suggestion list with search result
    prepare_suggestion = (_data, type_of_search) => {
        let formattedList = [];
        var data_length = 0;
        if (_data.data) {
            data_length = _data.data.length;
            _data = _data.data;
        }
        else {
            data_length = _data.length;
        }
        if (this.is_edit_for_audit_review) {
            for (var i = 0; i < data_length; i++) { 
                var item = _data[i];
                item = {
                    ...item,                   
                    procedure_code_id: item.id,
                    proc_code: item.code,
                    is_unit_enabled: "true",
                    is_amt_enabled: "false",
                    std_chg_amt: 0,
                    proc_desc1:item.description
                };
                _data[i] = item;
            }
        }

        if (_data && data_length) {
            for (var i = 0; i < data_length; i++) {
                var item = _data[i];
                item = {
                    ...item,
                    "is_amt_enabled": `${(item.is_amt_enabled == true || item.is_amt_enabled == "true") ? "true" : "false"}`,
                    "is_unit_enabled": `${(item.is_unit_enabled == true || item.is_unit_enabled == "true") ? "true" : "false"}`,
                    "is_active": item.is_active ? `${(item.is_active == true || item.is_active == "true") ? "true" : "false"}` : item.is_active,
                    "title": `${item.procedure_code_id}`,
                    "label": (item.proc_code) + " - " + (item.proc_desc1),
                };
                formattedList.push(item);
            }

        }
       
        return formattedList;
    }

    recalculate_charge_amount = async () => {
        if (this.props.ff_sumaj_B30566_expected_rate_multiple_fee_schedules &&
            this.state.charge_detail_item.proc_code.procedure_code_id &&
            this.state.charge_detail_item.status != charge_status.LOCKED) {
            let token = this.props.user_login_details.user_data.data.token_details.access_token;
            let multiple_fee_schedules = await get_multiple_fee_schedules(token,this.state.charge_detail_item.proc_code.procedure_code_id,{});
            let proc_code = multiple_fee_schedules.data.data;
            proc_code.charge_amount_status = charge_amount_status.RECALCULATED;
            await this.on_item_selection(proc_code);
        }
    }

    calculate_charge_amount = async (charge_state) => {
        if(this.props.ff_sumaj_B30566_expected_rate_multiple_fee_schedules){
            let { primary_ins_id, place_of_service_id, billing_provider_id, date_of_service } = this.props.calculate_charge_amount_params;
            if (this.props.page_name == enum_page_name.charge
                && primary_ins_id && place_of_service_id && billing_provider_id && (date_of_service && moment(date_of_service).isValid())
                && this.state.charge_detail_item.status != charge_status.LOCKED) {
                let token = this.props.user_login_details.user_data.data.token_details.access_token;
                let body = {
                    Location_id : place_of_service_id,
                    Billing_provider_id : billing_provider_id,
                    Primary_ins_id : primary_ins_id,
                    Date_of_service : date_of_service
                };
                let multiple_fee_schedules = await get_multiple_fee_schedules(token,charge_state.proc_code.procedure_code_id,body);
                charge_state.proc_code.std_chg_amt = multiple_fee_schedules.data.data.std_chg_amt;
            }
        }
    }

    with_loading = (calculate_charge_amount) => {
        return async (charge_state) => {
            const { proc_code } = charge_state; 

            if (proc_code.charge_amount_status !== charge_amount_status.RECALCULATED) {
                this.setState({ loading: true })
            }
    
            await calculate_charge_amount(charge_state)
    
            if (proc_code.charge_amount_status !== charge_amount_status.RECALCULATED) {
                this.setState({ loading: false })
            }
        }
    }
    
    // select the item on click suggested items
    on_item_selection = async (item) => {
        this.item_selection_call = true;       
        var charge_state = { ...this.state.charge_detail_item };
        charge_state.proc_code = item;        
        await this.with_loading(this.calculate_charge_amount)(charge_state)
        charge_state.total_amount = Number(charge_state.proc_code.std_chg_amt) * Number(charge_state.units);
        charge_state.is_charge_changed = this.state.old_proc_code.proc_code != item.proc_code;
        //console.log(this.props.data.proc_code.proc_code,charge_state.is_charge_changed);
        var format_data = this.get_selected_peoc_item(item);
        if (this._is_mounted) {
            this.setState({
                charge_detail_item: charge_state,
                selected_procedure_code: format_data
            });
        }
        if (this.blur_call) {
            await this.blur_auto_search(this.auto_search_event_object, this.auto_search_values);
        }
    }
    blur_auto_search = (event, value) => {
        let cntrl = document.getElementById(global_constants.constants.advanced_control_type.procedure_code + '_' + this.props.row_count);
        if ((cntrl && cntrl['value'] == '')) {
            this.clear_quick_search();
        }
        var format_data = { "label": '', "title": '' };
        var proc_code = {
            procedure_code_id: 0,
            proc_code: "",
            is_unit_enabled: "true",
            is_amt_enabled: "false",
            std_chg_amt: ''
        };
        var charge_state = { ...this.state.charge_detail_item };
        if (this.proc_code_key_down_key_value == 9) {
            if (value.results.length > 0 && value.results[0].label != '') {
                if (this.state.selected_procedure_code.label == undefined || this.state.selected_procedure_code.label == '') {
                    var selected_row = value.results[0];
                    charge_state.proc_code = selected_row;
                    this.with_loading(this.calculate_charge_amount)(charge_state)
                    charge_state.is_charge_changed = this.state.old_proc_code.proc_code != selected_row.proc_code;
                    charge_state.total_amount = Number(charge_state.proc_code.std_chg_amt) * Number(charge_state.units);

                    format_data = this.get_selected_peoc_item(selected_row);
                    this.selected_procedure = format_data;
                    this.charge_detail_item = charge_state;
                }

            } else {
                charge_state.units = 1;
                charge_state.proc_code = proc_code;
                charge_state.is_charge_changed = this.state.old_proc_code.proc_code != proc_code.proc_code;
                charge_state.total_amount = Number(charge_state.proc_code.std_chg_amt) * Number(charge_state.units);
                this.selected_procedure = format_data;
                this.charge_detail_item = charge_state;
            }
        }
        if (value.results.length == 0 || value.results[0].label == '') {
            charge_state.units = 1;
            charge_state.proc_code = proc_code;
            charge_state.is_charge_changed = this.state.old_proc_code.proc_code != proc_code.proc_code;
            charge_state.total_amount = Number(charge_state.proc_code.std_chg_amt) * Number(charge_state.units);
            this.selected_procedure = format_data;
            this.charge_detail_item = charge_state;
        } else {
            if (this.state.selected_procedure_code.label == undefined || this.state.selected_procedure_code.label == '') {
                var selected_row = getSelectedResultObject(this.auto_search_event_object, value);
                charge_state.proc_code = selected_row;
                this.with_loading(this.calculate_charge_amount)(charge_state)
                charge_state.total_amount = Number(charge_state.proc_code.std_chg_amt) * Number(charge_state.units);
                charge_state.is_charge_changed = this.state.old_proc_code.proc_code != selected_row.proc_code; 
                format_data = this.get_selected_peoc_item(selected_row);
                this.selected_procedure = format_data;
                this.charge_detail_item = charge_state;
                this.setState({
                    charge_detail_item: charge_state,
                    selected_procedure_code: format_data
                });
            } else {
                this.selected_procedure = this.state.selected_procedure_code;
                this.charge_detail_item = this.state.charge_detail_item;
            }
        }

        this.current_setTime_out = setTimeout(() => {
            var element, name, arr;
            element = document.getElementById("charge_details_proc_table");
            if (this.is_edit_for_audit_review && !(this.props.parent_row_count == null || this.props.parent_row_count==undefined) ) {
                element = document.getElementById("charge_details_proc_table" + this.props.parent_row_count);
            }
            name = "table-responsive";
            if (element) {
                arr = element.className.split(" ");
                if (arr.indexOf(name) == -1) {
                    element.className += " " + name;
                }
            }
            //
            if (!this.item_selection_call) {
                this.set_state_after_blur();
            }
            //
            if (this.current_setTime_out) {
                clearTimeout(this.current_setTime_out);
            }

        }, 500);
    }
    set_state_after_blur = () => {       
        if (this._is_mounted) {
            this.setState({
                charge_detail_item: this.charge_detail_item,
                selected_procedure_code: this.selected_procedure
            });
        }
    }

    on_blur_auto_search = async (e, value) => {
        this.auto_search_event_object = e;
        this.auto_search_values = value;
        this.blur_call = true;
        this.item_selection_call = false;
        this.blur_auto_search(e, value);  
    }
    
    on_key_down_auto_search = (e, value) => {
        this.proc_code_key_down_key_value = e.which || e.keyCode;
    }

    get_selected_peoc_item = (selected_row) => {
        var format_data = { "label": '', "title": '' };
        var id = '';
        var name = '';
        var code = '';
        var selected_data = '';
        if (selected_row) {
            id = selected_row.procedure_code_id;
            if ((selected_row.proc_code != null && selected_row.proc_code != undefined) || (selected_row.name != null && selected_row.name != undefined)) {
                code = (selected_row.proc_code == null && selected_row.proc_code == undefined) ? '' : selected_row.proc_code;
                name = (selected_row.proc_desc1 == null && selected_row.proc_desc1 == undefined) ? '' : selected_row.proc_desc1;
            }
        }
        selected_data = code + " - " + name;

        if (parseInt(id) > 0) {
            // Set Auto search control for provider
            format_data = {
                ...selected_row,
                "is_amt_enabled": `${(selected_row.is_amt_enabled == true || selected_row.is_amt_enabled == "true") ? "true" : "false"}`,
                "is_unit_enabled": `${(selected_row.is_unit_enabled == true || selected_row.is_unit_enabled == "true") ? "true" : "false"}`,
                "title": id.toString(),
                "label": selected_data,
            };
        }
        return format_data;
    }
    
    onFocus = (e) => {    
        let element = document.getElementById("charge_details_proc_table");
        if (this.is_edit_for_audit_review && !(this.props.parent_row_count == null || this.props.parent_row_count == undefined)) {
            element = document.getElementById("charge_details_proc_table" + this.props.parent_row_count);
        }
        if (element) {
            element.className = element.className.replace(/\btable-responsive\b/g, "");
        }
        if (this.current_setTime_out) {
            clearTimeout(this.current_setTime_out);
        }
    }

    clear_quick_search = () => {
        var charge_state = this.state.charge_detail_item;
        charge_state.units = 1;
        charge_state.proc_code = {
            procedure_code_id: 0,
            proc_code: "",
            is_unit_enabled: "true",
            is_amt_enabled: "false",
            std_chg_amt: ''
        };
        charge_state.is_charge_changed = this.state.old_proc_code.proc_code != "";
        charge_state.total_amount = Number(charge_state.proc_code.std_chg_amt) * Number(charge_state.units)
        if (this.current_setTime_out) {
            clearTimeout(this.current_setTime_out);
        }
        if (this._is_mounted) {
            this.setState({
                charge_detail_item: charge_state,
                selected_procedure_code: {
                    "title": '',
                    "label": ''
                },
            });
        }
    }

    on_modifier_change_method = (value, modifier1) => {
        var data = { ...this.state.charge_detail_item };
        if (modifier1==1) {
            data.proc_mod1 = value;
            data.is_charge_changed = this.state.old_data.proc_mod1 != value;
        } else if (modifier1 == 2) {
            data.proc_mod2 = value;
            data.is_charge_changed = this.state.old_data.proc_mod2 != value;
        }else {
            data.proc_mod3 = value;
            data.is_charge_changed = this.state.old_data.proc_mod3 != value;
        }
        
        if (this._is_mounted) {
            this.setState({
                charge_detail_item: data,
            });
        }
    }

    on_check_change = (event) => {
        var select = event.target.checked;
        var charge_state = { ...this.state.charge_detail_item };
        charge_state.checked = select;

        if (this._is_mounted) {
            this.setState({
                charge_detail_item: charge_state,
            });
        }
        setTimeout(() => {
            this.props.reverse_select_all(select, this.props.row_count);
        }, 100)

    }   
    is_checked = () => this.state.charge_detail_item.checked;
   
    render() {
        let review_charge_lock = this.is_audit_page ? false : !this.state.charge_detail_item.allow_edit;
        let charge_lock = this.state.charge_detail_item.status == charge_status.LOCKED;
        let disable_unit_amt = (this.state.charge_detail_item.status == charge_status.LOCKED || !(this.state.charge_detail_item.proc_code.is_unit_enabled == 'true' || this.state.charge_detail_item.proc_code.is_unit_enabled == true)) ? true : false;
        return (
            <React.Fragment>
            <Dimmer active={this.state.loading} page>
                <Loader size="massive">Loading</Loader>
            </Dimmer>
            {this.is_edit_for_audit_review ?
                <Table.Row key={this.props.row_count}>
                    <Table.Cell>
                        <Form.Field className="advance-quick-search" >
                            <AdvancedControl
                                id={'procedure_code_searcch_' + this.props.row_count}
                                gridConfig={this.state.procedure_code_search_data}
                                onGridRowSelection={this.on_provider_code_grid_row_selection}
                                controlId={global_constants.constants.advanced_control_type.procedure_code}
                                onSearch={this.on_procedure_code_search}
                                search_type={'procedure_code_searcch_' + this.props.row_count}
                                headerIdForGridTabNavigation={'procedure_code_searcch_' + this.props.row_count}
                                disabled_element={review_charge_lock}
                            />
                            <AutoSearchComponent
                                default_value={this.state.selected_procedure_code}
                                prepareRenderList={this.render_suggestion_result}
                                getList={this.get_procedure_code_quick_search_data_list}
                                prepareDataList={this.prepare_suggestion}
                                selectresult={this.on_item_selection}
                                on_blur={(e, value) => { this.on_blur_auto_search(e, value) }}
                                onKeyDown={(e, value) => this.on_key_down_auto_search(e, value)}
                                errorMessage={'No Record Found !'}
                                control_id={global_constants.constants.advanced_control_type.procedure_code + '_' + this.props.row_count}
                                on_focus={this.onFocus}
                                is_focus={false}
                                is_disabled={review_charge_lock}
                                show_clear_search={true}
                                clear_search={() => this.clear_quick_search()}
                                on_blur_parent_handle={true}
                            />
                        </Form.Field>
                    </Table.Cell>
                    <Table.Cell>
                        <Form.Field >
                            <Form.Input fluid
                                id={'procedure_code_unit_' + this.props.row_count}
                                name='txt_card_num'
                                maxLength={2}
                                onChange={(e) => this.on_change_unit_change(e.currentTarget.value)}
                                value={this.state.charge_detail_item.units || ''}
                                error={this.state.err_cc_num}
                                disabled={review_charge_lock}
                            />
                        </Form.Field>
                    </Table.Cell>
                    <Table.Cell>
                    
                        <Selection
                            style={review_charge_lock ? 'field disabled' : ''}
                            id={'procedure_code_modfire1_' + this.props.row_count}
                            defaultValue={this.state.charge_detail_item.proc_mod1 || ''}
                            options={
                                (this.props.rsiB35911ErrorCorrectionsPreventUserFromAddingNcModifierToChargeWithActivePayments && this.state.charge_detail_item.payment_associated_with_charge)
                                    ? this.props.modifier1_data.filter((item) => item.code != "NC")
                                    : this.props.modifier1_data
                            }
                            onChange={value => this.on_modifier_change_method(value, 1)}
                            disabled={review_charge_lock}
                        />
                    </Table.Cell>
                    <Table.Cell>
                        <Selection
                            style={review_charge_lock ? 'field disabled' : ''}
                            id={'procedure_code_modfire2' + this.props.row_count}
                            defaultValue={this.state.charge_detail_item.proc_mod2 || ''}
                            options={
                                (this.props.rsiB35911ErrorCorrectionsPreventUserFromAddingNcModifierToChargeWithActivePayments && this.state.charge_detail_item.payment_associated_with_charge)
                                    ? this.props.modifier2_data.filter((item) => item.code != "NC")
                                    : this.props.modifier2_data
                            }
                            onChange={value => this.on_modifier_change_method(value, 2)}
                            disabled={review_charge_lock}
                        />
                    </Table.Cell>
                    <Table.Cell>
                        <Selection
                            style={review_charge_lock ? 'field disabled' : ''}
                            id={'procedure_code_modfire3' + this.props.row_count}
                            defaultValue={this.state.charge_detail_item.proc_mod3 || ''}
                            options={
                                (this.props.rsiB35911ErrorCorrectionsPreventUserFromAddingNcModifierToChargeWithActivePayments && this.state.charge_detail_item.payment_associated_with_charge)
                                    ? this.props.modifier3_data.filter((item) => item.code != "NC")
                                    : this.props.modifier3_data
                            }
                            onChange={value => this.on_modifier_change_method(value, 3)}
                            disabled={review_charge_lock}
                        />
                    </Table.Cell>
                    <Table.Cell >
                        {this.state.charge_detail_item.dx}
                    </Table.Cell>
                    <Table.Cell >
                        {this.state.charge_detail_item.pos_identifier}
                    </Table.Cell>
                    <Table.Cell >
                        {this.state.charge_detail_item.insurance_pre_authorization}
                    </Table.Cell>
                        {this.props.charge_status == 'P' && this.props.rsiB34790ChargeCountForPendedChargesWillIncludeAllCharges && (
                            <Table.Cell >
                                {this.state.charge_detail_item.charge_status}
                            </Table.Cell>
                        )}
                </Table.Row>
                :
                <Table.Row key={this.props.row_count}>
                    <Table.Cell>
                        <input type="checkbox"
                            id={'charge_checkbox_' + this.props.row_count}
                            onChange={this.on_check_change}
                            checked={this.state.charge_detail_item.checked || false}
                            disabled={(this.state.charge_detail_item.status == charge_status.LOCKED)||(this.props.rsiB35911ErrorCorrectionsPreventUserFromAddingNcModifierToChargeWithActivePayments && this.state.charge_detail_item.payment_associated_with_charge)}
                        />
                    </Table.Cell>

                    <Table.Cell>
                        {this.state.charge_detail_item.status == 0 ? "NEW" :
                            (this.state.charge_detail_item.status == 1 ? "EDIT" :
                                ((this.state.charge_detail_item.status != 0 && this.state.charge_detail_item.status != 1)
                                    ? this.state.charge_detail_item.status_message : ""))}
                    </Table.Cell>
                    <Table.Cell>
                        <Form.Field className="advance-quick-search" >
                            <AdvancedControl
                                id={'procedure_code_searcch_' + this.props.row_count}
                                gridConfig={this.state.procedure_code_search_data}
                                onGridRowSelection={this.on_provider_code_grid_row_selection}
                                controlId={global_constants.constants.advanced_control_type.procedure_code}
                                onSearch={this.on_procedure_code_search}
                                search_type={'procedure_code_searcch_' + this.props.row_count}
                                headerIdForGridTabNavigation={'procedure_code_searcch_' + this.props.row_count}
                                disabled_element={charge_lock}
                            />
                            <AutoSearchComponent
                                default_value={this.state.selected_procedure_code}
                                prepareRenderList={this.render_suggestion_result}
                                getList={this.get_procedure_code_quick_search_data_list}
                                prepareDataList={this.prepare_suggestion}
                                selectresult={this.on_item_selection}
                                on_blur={(e, value) => { this.on_blur_auto_search(e, value) }}
                                onKeyDown={(e, value) => this.on_key_down_auto_search(e, value)}
                                errorMessage={'No Record Found !'}
                                control_id={global_constants.constants.advanced_control_type.procedure_code + '_' + this.props.row_count}
                                on_focus={this.onFocus}
                                is_focus={false}
                                is_disabled={charge_lock}
                                show_clear_search={true}
                                clear_search={() => this.clear_quick_search()}
                                on_blur_parent_handle={true}
                            />
                        </Form.Field>
                    </Table.Cell>
                    <Table.Cell>
                        <Form.Field >
                            <Form.Input fluid
                                id={'procedure_code_unit_' + this.props.row_count}
                                name='txt_card_num'
                                maxLength={3}
                                onChange={(e) => this.on_change_unit_change(e.currentTarget.value)}
                                value={this.state.charge_detail_item.units || ''}
                                error={this.state.err_cc_num}
                                disabled={disable_unit_amt}
                            />
                        </Form.Field>
                    </Table.Cell>
                    <Table.Cell>
                        <Form.Field>
                            <CurrencyFormatter
                                    onChange={(values) => { this.amount_change(values, "amount_to_collect", this.props.row_count) }}
                                    prefix='$'
                                    id={'procedure_code_amount_' + this.props.row_count}
                                    maxLength={15}
                                    defaultValue={(this.state.charge_detail_item.proc_code.std_chg_amt != null ? this.state.charge_detail_item.proc_code.std_chg_amt.toString() : "")}
                                    allowNegative={false}
                                    disabled={disable_unit_amt}
                                    zeroAsDefault={false}
                            />

                        </Form.Field>
                    </Table.Cell>
                    <Table.Cell>
                        <Selection
                            style={charge_lock ? 'field disabled' : ''}
                            id={'procedure_code_modfire1_' + this.props.row_count}
                            defaultValue={this.state.charge_detail_item.proc_mod1 || ''}
                            options={
                                (this.props.rsiB35911ErrorCorrectionsPreventUserFromAddingNcModifierToChargeWithActivePayments && this.state.charge_detail_item.payment_associated_with_charge)
                                    ? this.props.modifier1_data.filter((item) => item.code != "NC")
                                    : this.props.modifier1_data
                            }
                            onChange={value => this.on_modifier_change_method(value, 1)}
                            disabled={charge_lock}
                        />
                    </Table.Cell>
                    <Table.Cell>
                        <Selection
                            style={charge_lock ? 'field disabled' : ''}
                            id={'procedure_code_modfire2' + this.props.row_count}
                            defaultValue={this.state.charge_detail_item.proc_mod2 || ''}
                            options={
                                (this.props.rsiB35911ErrorCorrectionsPreventUserFromAddingNcModifierToChargeWithActivePayments && this.state.charge_detail_item.payment_associated_with_charge)
                                    ? this.props.modifier2_data.filter((item) => item.code != "NC")
                                    : this.props.modifier2_data
                            }
                            onChange={value => this.on_modifier_change_method(value, 2)}
                            disabled={charge_lock}
                        />
                    </Table.Cell>
                    <Table.Cell>
                        <Selection
                            style={charge_lock ? 'field disabled' : ''}
                            id={'procedure_code_modfire3' + this.props.row_count}
                            defaultValue={this.state.charge_detail_item.proc_mod3 || ''}
                            options={
                                (this.props.rsiB35911ErrorCorrectionsPreventUserFromAddingNcModifierToChargeWithActivePayments && this.state.charge_detail_item.payment_associated_with_charge)
                                    ? this.props.modifier3_data.filter((item) => item.code != "NC")
                                    : this.props.modifier3_data
                            }
                            onChange={value => this.on_modifier_change_method(value, 3)}
                            disabled={charge_lock}
                        />
                    </Table.Cell>
                    <Table.Cell>
                        <Form.Field >
                            <Form.Input fluid maxLength={20}
                                id={'procedure_code_dx' + this.props.row_count}
                                onChange={e => this.on_dx_sequence_change(e.currentTarget.value)}
                                onKeyPress={e => this.on_dx_sequence_keypress(e)}
                                value={this.state.charge_detail_item.dx || ''}
                                disabled={charge_lock}
                            />
                        </Form.Field>
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                        <NumberFormat
                            id={'procedure_code_total_amount' + this.props.row_count}
                            value={this.state.charge_detail_item.total_amount} fixedDecimalScale={true} decimalScale={2}
                            displayType={'text'} thousandSeparator={true} prefix={'$'}
                            renderText={value => <div>{value}</div>} />

                    </Table.Cell>
                </Table.Row>
            }</React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        rsiB34790ChargeCountForPendedChargesWillIncludeAllCharges: get_lauch_darkley_key_value(state.launch_darkly, "rsiB34790ChargeCountForPendedChargesWillIncludeAllCharges"),
        rsiB35911ErrorCorrectionsPreventUserFromAddingNcModifierToChargeWithActivePayments: get_lauch_darkley_key_value(state.launch_darkly, "rsiB35911ErrorCorrectionsPreventUserFromAddingNcModifierToChargeWithActivePayments")
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChargeDetailRowComponent));