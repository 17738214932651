import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { toastr as toaster } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { Grid, Form } from 'semantic-ui-react';
import AdvancedControl from '../../shared/component/advanced_control';
import AutoSearchComponent from '../../shared/component/auto_search_component';
import Selection from '../../shared/component/selection_component';
import * as local_storage from '../../shared/local_storage_utility';
import * as global_constants from '../../global_constants';
import { diagnosis_code_list, modifiers_list } from '../payment_constants'
import { save_charge_for_error_correction } from '../action/payment_action';
import { get_popper_id, format_date, get_check_credit_num } from '../utility';
import Calendar from '../../shared/component/calendar_component';
import * as shared_constants from '../../shared/shared_constants';
import { procedure_code_advance_search, procedure_code_quick_search, quick_search_location, quick_search_provider, referring_physician_advance_search, referring_physician_quick_search, search_place, search_provider } from './../../shared/action/autosearch_action';
import { search_referring_physician_configuration, search_result_default_paze_size } from '../../shared/shared_constants';
import { enum_type_of_search } from './../../case/case_constants';
import AddReferringPhysician from '../../shared/component/add_referring_physician_component';
import { content_length, content_width } from '../../shared/component/grid/utils';
import { get_all_error, currency_formatter, get_lauch_darkley_key_value, date_format_with_no_time } from '../../shared/utility';
import ConfirmationComponent from '../../shared/component/confirmation_component';
import { charge_messages } from '../../charge/charge_constant';
import * as session_storage from '../../shared/session_storage_utility';
import MultiSelectDraggableDropDownComponent from '../../shared/component/multi_select_draggable_component';
import ErrorCorrectionPrintableData from './error_corrections_printable_data';
import { dx_keys, mod_keys, format_multi_select_data, DX_API_PAGE_SIZE } from '../edit_charge_util';
import { search_diagnosis_codes_by_page } from '../../diagnosis/action/diagnosis_action';
import { get_multiple_fee_schedules } from '../../shared/action/shared_action';
import CurrencyFormatter from '../../shared/component/currency_formatter';
import { ErrorCorrectionsEnhancementsProject } from '../payment_constants';
export class ErrorCorrectionEditFormComponent extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            show_bummepd_charge_message_popup: false,
            is_loading: true,
            form_submited: false,
            open_confirm_alert: false,
            procedure_code_modifiers1_list: [], // used to store dropdown data
            procedure_code_modifiers2_list: [], // used to store dropdown data
            procedure_code_modifiers3_list: [], // used to store dropdown data
            provider_quick_search_format:
            {
                "title": '',
                "label": '',
                "bp_code": '',
                "description": ''
            },
            location_quick_search_format:
            {
                "title": '',
                "label": '',
                "pos_code": '',
                "description": ''
            },
            referring_physician_quick_search_format:
            {
                "title": '',
                "label": '',
                "md_code": '',
                "referring_first_name": '',
                "referring_last_name": '',
                "referring_address": ''
            },
            selected_procedure_code: this.empty_proc_code,
            dx1: this.dx_empty,
            dx2: this.dx_empty,
            dx3: this.dx_empty,
            dx4: this.dx_empty,
            dx5: this.dx_empty,
            dx6: this.dx_empty,
            dx7: this.dx_empty,
            dx8: this.dx_empty,
            error: { ...this.props.error },
            grid_conf: {
                isPagination: true,
                gridAutoHeight: true,
                selectionType: 'single',
                paginationPageSize: 20,
            },
            provider_search_data: {},
            location_search_data: {},
            primary_physician_search_data: {},
            procedure_code_search_data: {},
            show_primary_advanced_control: false,
            add_physician_modal: false,
            save_confirmation_unit_box: false,
            charge_details: {
                cur_icd_type: null,
                rebill: false,
                delete: false,
                allow_delete: false,
                amount: null,
                dx1: null,
                dx2: null,
                dx3: null,
                dx4: null,
                dx5: null,
                dx6: null,
                dx7: null,
                dx8: null,
                date_of_service: null,
                plan_of_care_date: null,
                updated_date: null,
                start_of_care_date: null,
                location: null,
                modified_by: null,
                preauth_id: null,
                proc_mod1: null,
                proc_mod2: null,
                proc_mod3: null,
                units: null,
                total_charge_amount: null,
                balance: null,
                insurance_balance: null,
                patient_balance: null,
                adjustment: null,
                payment: null,
                referring_physician_code: null,
                cpt_code: null,
                provider_id: null,
                billing_provider: {
                    id: "-999"
                },
                place_of_service: {
                    id: "-999"
                },
                referring_physician: {
                    id: "-999"
                },
                proc_code: {
                    procedure_code_id: 0,
                    proc_code: "",
                    std_chg_amt: '',
                    title: '',
                    label: ''
                },
                md_code: null,
                pos_code: null,
                billing_provider_code: null,
                selected_modifiers: modifiers_list(this.props.data),
                selected_diagnosis_codes: diagnosis_code_list(this.props.data)
            },
            dropdown_data: { ...props.dropdown_data },
            no_record_msg: " "
        };
    }

    token: any = '';
    _is_mounted = false;
    page_index = 1;
    page_size = 100;
    dx_empty = {
        "title": '',
        "label": '',
        "diagnosis_code_id": '',
        "code": '',
        "description": '',
        "icd_type": ''
    };
    empty_proc_code = {
        "title": '',
        "label": '',
        "proc_amount": '',
        "description": '',
        "proc_code": '',
        "procedure_code_id": 0,
        "is_amt_enabled": "false",
        "is_unit_enabled": "true",
        "amount": ''
    }
    initial_form_object: any = {};
    patient_id = 0;
    _kx_modifier_id: any;

    // Fetching the report id and params.
    UNSAFE_componentWillMount = () => {
        var auth_data = local_storage.get('auth_data');
        this.patient_id = session_storage.get('active_patient');
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
    };

    componentDidMount = async () => {
        this._is_mounted = true;

        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        
        this.get_data();

        if (this.props.onRef) {
            this.props.onRef(this);
        }
    };

    componentWillUnmount = () => {
        this._is_mounted = false;

        if (this.props.onRef) {
            this.props.onRef(undefined)
        }
    };

    //Component update function
    componentDidUpdate(previousProps, previousState) {
        const state_value = {};
        let is_update = false;

        if (this.props.error !== previousProps.error &&
            this.props.error !== this.state.error) {
            state_value['error'] = { ...this.props.error };
            is_update = true;//state update available 
        }

        if (!this.props.allowChargeDelete && this.props.allowChargeDelete !== previousProps.allowChargeDelete) {
            state_value['charge_details'] = {
                ...this.state.charge_details,
                delete: this.props.allowChargeDelete
            };
            is_update = true;//state update available 
        }

        if (is_update) {
            this.setState(state_value);
        }
    }

    get_data = () => {
        if (this.props.data.charge_id) {
            this.initialize_charge_details(this.props.data);
        } else {
            toaster.error('', 'Selected Charge Id is inactive for this operation.');
        }
    }

    initialize_charge_details = (data) => {

        let { charge_details } = this.state;

        var place_of_service_format = { "label": '', "title": '', "pos_code": '', "description": '' };
        var billing_provider_format = { "label": '', "title": '', "bp_code": '', "description": '' };
        var referring_physician_format = {
            "label": '',
            "title": '',
            "md_code": '',
            "referring_first_name": '',
            "referring_last_name": '',
            "referring_address": ''
        };
        var procedure_code = this.empty_proc_code;
        var diagnosis_code1, diagnosis_code2, diagnosis_code3, diagnosis_code4, diagnosis_code5, diagnosis_code6, diagnosis_code7, diagnosis_code8;
        diagnosis_code1 = diagnosis_code2 = diagnosis_code3 = diagnosis_code4 = diagnosis_code5 = diagnosis_code6 = diagnosis_code7 = diagnosis_code8 = this.dx_empty;

        if (data.pos_Id > 0) {
            charge_details.pos_Id = data.pos_Id;
            charge_details.place_of_service.id = data.pos_Id;
            place_of_service_format = {
                "title": data.pos_Id.toString(),
                "pos_code": data.pos_code,
                "label": (data.pos_code) + " - " + (data.pos_name),
                "description": data.pos_name
            };
        }

        if (data.provider_id > 0) {
            charge_details.provider_id = data.provider_id;
            charge_details.billing_provider.id = data.provider_id;
            billing_provider_format = {
                "title": data.provider_id.toString(),
                "bp_code": data.provider_code,
                "label": (data.provider_code) + " - " + (data.provider_desc),
                "description": data.provider_desc

            };
        }

        if (data.referring_physician_id > 0) {

            var _title = data.referring_physician_code + " - " + (data.referring_first_name == null ? '' : data.referring_first_name) + " " + (data.referring_last_name == null ? '' : data.referring_last_name) + (data.referring_address == null ? '' : ", " + data.referring_address);

            referring_physician_format = {
                "title": data.referring_physician_id.toString(),
                "md_code": data.referring_physician_code,
                "label": _title == ' ' ? data.referring_physician_code : _title,
                "referring_first_name": data.referring_first_name,
                "referring_last_name": data.referring_last_name,
                "referring_address": data.referring_address
            };
        }

        if (data.proc_id > 0) {
            var _title = data.proc_code + " - " + data.proc_desc;

            // Set Auto search control for referring physician
            procedure_code = {
                "title": data.proc_id.toString(),
                "procedure_code_id": data.proc_id,
                "proc_amount": data.proc_amount,
                "label": _title,
                "description": data.proc_desc,
                "proc_code": data.proc_code,
                "is_amt_enabled": data.allow_cpt_edit_flag.toString(),
                "is_unit_enabled": "true",
                "amount":data.amount
            };
        }

        if (data.dx1_id > 0) {
            diagnosis_code1 = this.get_prepared_dx_code(data.dx1, data.dx1_id, data.dx1_desc, data.dx1_icd_type);
        }
        if (data.dx2_id > 0) {
            diagnosis_code2 = this.get_prepared_dx_code(data.dx2, data.dx2_id, data.dx2_desc, data.dx2_icd_type);
        }
        if (data.dx3_id > 0) {
            diagnosis_code3 = this.get_prepared_dx_code(data.dx3, data.dx3_id, data.dx3_desc, data.dx3_icd_type);
        }
        if (data.dx4_id > 0) {
            diagnosis_code4 = this.get_prepared_dx_code(data.dx4, data.dx4_id, data.dx4_desc, data.dx4_icd_type);
        }
        if (data.dx5_id > 0) {
            diagnosis_code5 = this.get_prepared_dx_code(data.dx5, data.dx5_id, data.dx5_desc, data.dx5_icd_type);
        }
        if (data.dx6_id > 0) {
            diagnosis_code6 = this.get_prepared_dx_code(data.dx6, data.dx6_id, data.dx6_desc, data.dx6_icd_type);
        }
        if (data.dx7_id > 0) {
            diagnosis_code7 = this.get_prepared_dx_code(data.dx7, data.dx7_id, data.dx7_desc, data.dx7_icd_type);
        }
        if (data.dx8_id > 0) {
            diagnosis_code8 = this.get_prepared_dx_code(data.dx8, data.dx8_id, data.dx8_desc, data.dx8_icd_type);
        }

        charge_details.allow_delete = data.allow_delete;
        charge_details.rebill = data.rebill;
        charge_details.delete = data.delete;

        charge_details.amount = data.amount;
        charge_details.units = data.units;
        charge_details.total_charge_amount = data.total_charge_amount;

        charge_details.balance = data.balance;
        charge_details.insurance_balance = data.insurance_balance;
        charge_details.patient_balance = data.patient_balance;
        charge_details.adjustment = data.adjustment;
        charge_details.payment = data.payment;

        charge_details.preauth_id = data.preauth_id;
        charge_details.proc_mod1 = data.proc_mod1_id;
        charge_details.proc_mod2 = data.proc_mod2_id;
        charge_details.proc_mod3 = data.proc_mod3_id;
        charge_details.cur_icd_type = data.cur_icd_type;

        charge_details.date_of_service = data.date_of_service ? moment(data.date_of_service) : null;
        charge_details.start_of_care_date = data.start_of_care_date ? moment(data.start_of_care_date) : null;
        charge_details.plan_of_care_date = data.plan_of_care_date ? moment(data.plan_of_care_date) : null;
        charge_details.modified_by = data.modified_by;
        charge_details.updated_date = data.updated_date ? moment(data.updated_date).format("MM/DD/YYYY") : null;
        charge_details.charge_open_time = data.charge_open_time;
        charge_details.is_active = data.is_active;
        charge_details.cur_ins = data.cur_ins_code;
        charge_details.is_charge_locked = data.is_charge_locked;

        if (this._is_mounted) {
            this.setState({
                location_quick_search_format: place_of_service_format,
                provider_quick_search_format: billing_provider_format,
                referring_physician_quick_search_format: referring_physician_format,
                selected_procedure_code: procedure_code,
                charge_details: charge_details,
                dx1: diagnosis_code1,
                dx2: diagnosis_code2,
                dx3: diagnosis_code3,
                dx4: diagnosis_code4,
                dx5: diagnosis_code5,
                dx6: diagnosis_code6,
                dx7: diagnosis_code7,
                dx8: diagnosis_code8,
                is_loading: false
            }, () => {
                this.initial_form_object = this.prepare_charge_data();
            });
        }
    }

    recalculate_charge_amount = async (type, procedure_code_id) => {
        if(this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting && procedure_code_id ){
            let billing_provider_id = this.state.charge_details.billing_provider.id;
            let place_of_service_id = this.state.charge_details.place_of_service.id;
            let charge_state = {...this.state.charge_details}
            let { cur_ins, date_of_service } = charge_state;
             
            if ( cur_ins && place_of_service_id && billing_provider_id && (date_of_service && moment(date_of_service).isValid())) {
                let token = this.props.user_login_details.user_data.data.token_details.access_token;
                let body = {
                    Location_id : place_of_service_id,
                    Billing_provider_id : billing_provider_id,
                    Primary_ins_id : cur_ins,
                    Date_of_service : date_of_service
                };
                let multiple_fee_schedules = await get_multiple_fee_schedules(token, procedure_code_id, body);
                
                let amount = (this.props.initial_data.proc_id == Number(procedure_code_id) && this.props.initial_data.pos_Id == Number(place_of_service_id) && this.props.initial_data.provider_id == Number(billing_provider_id)) && this.get_date(this.props.initial_data.date_of_service, 'MM/DD/YYYY') ==  date_of_service ? this.props.initial_data.amount : multiple_fee_schedules.data.data.std_chg_amt;

                if(type == 'procedure_code'){
                    return amount;
                }

                if(charge_state.amount != amount){
                    toaster.warning('', "Heads up! The Charge Amount has changed based on updates made.");
                }

                charge_state.total_charge_amount = Number(amount) * Number(charge_state.units);
                charge_state.amount = Number(amount);

                if (this._is_mounted) {
                    this.setState({
                        charge_details: charge_state
                    }, () => {
                        this.props.onObjectChange({
                            amount: charge_state.amount,
                            total_charge_amount: charge_state.total_charge_amount
                        });
                    });
                }
            }
        }
    }

    get_prepared_dx_code = (dx, dx_id, dx_desc, icd_type) => {
        let name = dx + ' - ' + dx_desc;
        return {
            "title": name,
            "label": name,
            "diagnosis_code_id": dx_id,
            "code": dx,
            "description": dx_desc,
            "icd_type": icd_type
        };
    }

    get_date = (date, pattern) => {
        if (date) {
            return moment(date).format(pattern);
        }
        return '';
    }

    on_check_change = (e) => {
        let check_value = {};
        if (e.currentTarget.name == `${this.props.rowNum}-delete`) {
            check_value = { delete: !this.state.charge_details.delete, rebill: false }
        } else {
            check_value = { delete: false, rebill: !this.state.charge_details.rebill }
        }

        if (this._is_mounted) {
            this.setState(prev_state => ({
                charge_details: {
                    ...prev_state.charge_details,
                    ...check_value
                },
                ...check_value
            }), () => {
                this.props.onObjectChange(check_value);
            });
        }
    }

    handleCalendarChange = (dt, name) => {

        let has_error = false;
        if (dt == null && name != 'plan_of_care_date') {
            has_error = true;
        } else {
            has_error = false;
        }

        if (this._is_mounted) {
            this.setState(prev_state => ({
                charge_details: {
                    ...prev_state.charge_details,
                    [name]: dt
                },
                error: {
                    ...prev_state.error,
                    [name]: has_error
                }
            }), () => {
                this.props.onDataChange(name, dt ? dt.format('MM/DD/YYYY') : null);
                if( this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting && name == 'date_of_service' ){
                    this.recalculate_charge_amount(name, this.state.selected_procedure_code.procedure_code_id);
                }
            })
        }
    }

    clear_quick_search = (type_of_search) => {

        var charge = this.state.charge_details;
        let error = this.state.error;
        if (type_of_search == enum_type_of_search.place_of_service) {

            charge.place_of_service.id = "-999";

            charge.pos_code = "";
            error.pos_Id = true;
            if (this._is_mounted) {
                this.setState({
                    location_quick_search_format: {
                        "title": '',
                        "label": '',
                        "pos_code": ''
                    },
                    charge_details: charge,
                    error: error
                }, () => {
                    this.props.onObjectChange({
                        pos_Id: charge.place_of_service.id,
                        pos_code: '',
                        pos_name: ''
                    });
                });
            }
        }
        else if (type_of_search == enum_type_of_search.provider) {
            charge.billing_provider.id = "-999";
            charge.billing_provider_code = "";
            error.provider_id = true;

            if (this._is_mounted) {
                this.setState({
                    provider_quick_search_format: {
                        "title": '',
                        "label": '',
                        "bp_code": ''
                    },
                    charge_details: charge,
                    error: error
                }, () => {
                    this.props.onObjectChange({
                        provider_id: charge.billing_provider.id,
                        provider_code: '',
                        provider_desc: ''
                    });
                });
            }
        } else if (type_of_search == enum_type_of_search.referring_physician) {
            charge.referring_physician.id = 0;
            if (this._is_mounted) {
                this.setState({
                    referring_physician_quick_search_format: {
                        "title": '',
                        "label": '',
                        "md_code": ''
                    },
                    charge_details: charge,
                }, () => {
                    this.props.onObjectChange({
                        referring_physician_id: charge.referring_physician.id,
                        referring_physician_code: '',
                        referring_first_name: '',
                        referring_last_name: '',
                        referring_address: ''
                    });
                });
            }
        } else {
            // cpt code
            charge.units = 1;
            charge.total_charge_amount = Number(0) * Number(charge.units);
            charge.amount = Number(0);
            error.proc_id = true;
            if (this._is_mounted) {
                this.setState({
                    charge_details: charge,
                    selected_procedure_code: {...this.empty_proc_code},
                    error: error
                }, () => {
                    this.props.onObjectChange({
                        units: charge.units,
                        proc_id: this.empty_proc_code.procedure_code_id,
                        proc_code: this.empty_proc_code.proc_code,
                        proc_desc: this.empty_proc_code.description,
                        proc_amount: charge.amount,
                        total_charge_amount: charge.total_charge_amount,
                        amount: charge.amount
                    });
                });
            }
        }
    }

    //Auto search for Location
    render_suggestion_result = (props) => {
        return (
            <div key={props.title} tabIndex={0} className="item_auto_search">
                <div key={props.title} className="fs_13">
                    <span>{props.label}</span>
                </div>
            </div>
        )
    }

    // get the searched value which is typed for searching location or providers
    get_input_value = (input, type) => {
        if (input.length >= 3) {
            this.get_referring_physician_quick_search_data_list(input);
        }
    }

    // preapare the suggestion list with search result
    prepare_suggestion = (_data, type_of_search: any = null) => {
        let formattedList = [];
        var data_length = 0;
        if (_data.data) {
            data_length = _data.data.length;
            _data = _data.data;
        }
        else {
            data_length = _data.length;
        }
        if (_data && data_length) {
            for (var i = 0; i < data_length; i++) {
                var item = _data[i];

                if (type_of_search === enum_type_of_search.referring_physician) {
                    formattedList.push({
                        "title": `${item.id}`,
                        "md_code": item.md_code,
                        "first_name": item.first_name,
                        "last_name": item.last_name,
                        "address": item.address,
                        "label": (item.md_code) + " - " + (item.first_name) + " " + (item.last_name) + (item.address == null ? '' : ", " + item.address),

                    })
                }
                else if (type_of_search === enum_type_of_search.place_of_service) {
                    formattedList.push({
                        "title": `${item.id}`,
                        "code": item.code,
                        "name": item.name,
                        "label": (item.code) + " - " + (item.name),

                    })
                }
                else if (type_of_search === enum_type_of_search.provider) {
                    formattedList.push({
                        "title": `${item.id}`,
                        "code": item.code,
                        "name": item.description,
                        "label": (item.code) + " - " + (item.description),

                    })
                }
                else {
                    item = {
                        ...item,
                        "is_amt_enabled": `${(item.is_amt_enabled == true || item.is_amt_enabled == "true") ? "true" : "false"}`,
                        "is_unit_enabled": `${(item.is_unit_enabled == true || item.is_unit_enabled == "true") ? "true" : "false"}`,
                        "title": `${item.procedure_code_id}`,
                        "procedure_code_id": `${item.procedure_code_id}`,
                        "label": (item.proc_code) + " - " + (item.proc_desc1),
                        "description": item.proc_desc1,
                        "proc_amount": item.std_chg_amt,
                        "proc_code": item.proc_code
                    };
                    formattedList.push(item);
                }

            }

        }
        return formattedList;
    }

    get_referring_physician_quick_search_data_list = async (search_keyword) => {
        return await referring_physician_quick_search(search_keyword, this.props.user_login_details.user_data.data.token_details.access_token);
    }

    referring_physician_search = async (params) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        params["Page_size"] = search_result_default_paze_size;
        const search_data = await referring_physician_advance_search(params, token)
            .then(res => res.data);

        const search_result = search_data.data !== null ? search_data.data.result : [];
        const grid_data = {
            ...this.state.grid_conf,
            rows: search_result,
            column: search_referring_physician_configuration("referring_physician_id").column_defs,
            paginationPageSize: search_referring_physician_configuration("referring_physician_id").pagination_size,
        };
        if (this._is_mounted) {
            this.setState({ referring_physician_search_data: grid_data });
        }
    }

    add_referring_physician = () => {
        if (this._is_mounted) {
            this.setState({ add_referring_physician_modal: !this.state.add_referring_physician_modal });
        }
    }
    set_advanced_control_modal_state = (show_status, control_type) => {
        if (this._is_mounted) {
            this.setState({ show_advanced_control: show_status });
        }
    }

    on_location_grid_row_selection = (selected_row) => {
        this.on_row_selection_advance_search(enum_type_of_search.place_of_service, selected_row);
    }
    on_provider_grid_row_selection = (selected_row) => {
        this.on_row_selection_advance_search(enum_type_of_search.provider, selected_row);
    }

    on_provider_code_grid_row_selection = (selected_row) => {
        this.on_row_selection_advance_search('procedure_code', selected_row);
    }

    on_primary_physician_grid_row_selection = (selected_row) => {
        this.on_row_selection_advance_search(enum_type_of_search.referring_physician, selected_row);
    }

    on_row_selection_advance_search = async (type_of_search, selected_row) => {

        var f_name = '';
        var l_name = '';
        var address = "";
        var id = '';
        var name = '';
        var code = '';
        var selected_data = '';
        var charge_state = this.state.charge_details;
        var error = this.state.error;

        if (type_of_search === enum_type_of_search.referring_physician) {
            if (selected_row) {
                id = selected_row.id;
                if ((selected_row.md_code != null && selected_row.md_code != undefined) || (selected_row.first_name != null && selected_row.first_name != undefined)
                    || (selected_row.last_name != null && selected_row.last_name != undefined)) {
                    code = (selected_row.md_code == null && selected_row.md_code == undefined) ? '' : selected_row.md_code;
                    f_name = (selected_row.first_name == null && selected_row.first_name == undefined) ? '' : selected_row.first_name;
                    l_name = (selected_row.last_name == null && selected_row.last_name == undefined) ? '' : selected_row.last_name;
                    address = (selected_row.address1 == null && selected_row.address1 == undefined) ? '' : selected_row.address1;
                }
            }
            selected_data = code + " - " + (f_name == null ? '' : f_name) + " " + (l_name == null ? '' : l_name) + (address == null ? '' : ", " + address);
            var format = { "label": '', "title": '', "md_code": '' };
            if (parseInt(id) > 0) {
                // Set Auto search control for referring physician
                format = {
                    "title": id.toString(),
                    "md_code": code,
                    "label": selected_data
                };
            }

            charge_state.md_code = charge_state.referring_physician.id = id;
            if (this._is_mounted) {
                this.setState({
                    referring_physician_quick_search_format: format,
                    charge_details: charge_state
                }, () => {
                    this.props.onObjectChange({
                        referring_physician_id: id,
                        referring_physician_code: code,
                        referring_first_name: f_name,
                        referring_last_name: l_name,
                        referring_address: address
                    });
                });
            }
        } else if (type_of_search === enum_type_of_search.place_of_service) {
            if (selected_row) {
                id = selected_row.id;
                if ((selected_row.code != null && selected_row.code != undefined) || (selected_row.name != null && selected_row.name != undefined)) {
                    code = (selected_row.code == null && selected_row.code == undefined) ? '' : selected_row.code;
                    name = (selected_row.name == null && selected_row.name == undefined) ? '' : selected_row.name;
                }
                error.pos_Id = false;

            }
            selected_data = code + " - " + name;
            var format_loc = { "label": '', "title": '', "pos_code": '' };
            if (parseInt(id) > 0) {
                // Set Auto search control for location
                format_loc = {
                    "title": id.toString(),
                    "pos_code": code,
                    "label": selected_data
                };
            }
            charge_state.pos_code = charge_state.place_of_service.id = id;
            error.pos_Id = false;
            if (this._is_mounted) {
                this.setState({
                    location_quick_search_format: format_loc,
                    charge_details: charge_state,
                    error: error
                }, () => {
                    this.props.onObjectChange({
                        pos_Id: selected_row.id,
                        pos_code: selected_row.code,
                        pos_name: selected_row.name
                    });
                });
            }
            if(this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting ){
                this.recalculate_charge_amount(type_of_search, this.state.selected_procedure_code.procedure_code_id);
            }
        }
        else if (type_of_search === enum_type_of_search.provider) {
            if (selected_row) {
                id = selected_row.id;
                if ((selected_row.code != null && selected_row.code != undefined) || (selected_row.name != null && selected_row.name != undefined)) {
                    code = (selected_row.code == null && selected_row.code == undefined) ? '' : selected_row.code;
                    name = (selected_row.description == null && selected_row.description == undefined) ? '' : selected_row.description;
                }
            }
            selected_data = code + " - " + name;
            var format_bp = { "label": '', "title": '', "bp_code": '' };
            if (parseInt(id) > 0) {
                format_bp = {
                    "title": id.toString(),
                    "bp_code": code,
                    "label": selected_data
                };
            }

            charge_state.billing_provider_code = charge_state.billing_provider.id = id;
            error.provider_id = false;
            if (this._is_mounted) {
                this.setState({
                    provider_quick_search_format: format_bp,
                    charge_details: charge_state,
                    error: error
                }, () => {
                    this.props.onObjectChange({
                        provider_id: id,
                        provider_code: code,
                        provider_desc: name
                    });
                });
            }
            if(this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting ){
                this.recalculate_charge_amount(type_of_search, this.state.selected_procedure_code.procedure_code_id);
            }
        } else {
            if (selected_row) {
                id = selected_row.procedure_code_id;
                if ((selected_row.proc_code != null && selected_row.proc_code != undefined) || (selected_row.name != null && selected_row.name != undefined)) {
                    code = (selected_row.proc_code == null && selected_row.proc_code == undefined) ? '' : selected_row.proc_code;
                    name = (selected_row.proc_desc1 == null && selected_row.proc_desc1 == undefined) ? '' : selected_row.proc_desc1;
                }
            }
            selected_data = code + " - " + name;
            let format_data = this.empty_proc_code;

            if (parseInt(id) > 0) {
                let updated_amount = this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting ? await this.recalculate_charge_amount(type_of_search, Number(id)) : selected_row.std_chg_amt;
                format_data = {
                    "title": id.toString(),
                    "procedure_code_id": Number(id),
                    "label": selected_data,
                    "proc_amount": updated_amount,
                    "description": selected_row.proc_desc1,
                    "proc_code": selected_row.proc_code,
                    "is_amt_enabled": selected_row.is_amt_enabled.toString(),
                    "is_unit_enabled": "true",
                    "amount": this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting ? updated_amount : this.props.initial_data.proc_id == Number(id) ? this.props.initial_data.amount : selected_row.std_chg_amt
                };
            }

            if(this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting && format_data.amount && (charge_state.amount != format_data.amount)){
                toaster.warning('', "Heads up! The Charge Amount has changed based on updates made.");
            }

            charge_state.total_charge_amount = Number(format_data.amount) * Number(charge_state.units);
            charge_state.amount = Number(format_data.amount);
            error.proc_id = false;

            if (this._is_mounted) {
                this.setState({
                    selected_procedure_code: format_data,
                    charge_details: charge_state,
                    error: error
                }, () => {
                    this.props.onObjectChange({
                        proc_id: selected_row.procedure_code_id,
                        proc_code: selected_row.proc_code,
                        proc_desc: selected_row.proc_desc1,
                        proc_amount: charge_state.amount,
                        total_charge_amount: charge_state.total_charge_amount
                    });
                });
            }
        }
    }

    on_item_selection = async (item, type) => {
        var charge_state = this.state.charge_details;
        let error = this.state.error;
        if (type === enum_type_of_search.referring_physician) {
            charge_state.md_code = charge_state.referring_physician.id = item.title;
            if (this._is_mounted) {
                this.setState({
                    referring_physician_quick_search_format: item,
                    charge_details: charge_state,
                    error: error
                }, () => {
                    this.props.onObjectChange({
                        referring_physician_id: item.title,
                        referring_physician_code: item.md_code,
                        referring_first_name: item.first_name,
                        referring_last_name: item.last_name,
                        referring_address: item.address
                    });
                });
            }
        } else if (type === enum_type_of_search.place_of_service) {
            charge_state.pos_code = charge_state.place_of_service.id = item.title;
            error.pos_Id = false;
            if (this._is_mounted) {
                this.setState({
                    location_quick_search_format: item,
                    charge_details: charge_state,
                    error: error
                }, () => {
                    this.props.onObjectChange({
                        pos_Id: item.title,
                        pos_code: item.code,
                        pos_name: item.name
                    });
                });
            }
            if(this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting ){
                this.recalculate_charge_amount(type, this.state.selected_procedure_code.procedure_code_id);
            }
        }
        else if (type === enum_type_of_search.provider) {
            charge_state.billing_provider_code = charge_state.billing_provider.id = item.title;
            error.provider_id = false;
            if (this._is_mounted) {
                this.setState({
                    provider_quick_search_format: item,
                    charge_details: charge_state,
                    error: error
                }, () => {
                    this.props.onObjectChange({
                        provider_id: item.title,
                        provider_code: item.code,
                        provider_desc: item.name
                    });
                });
            }
            if(this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting ){
                this.recalculate_charge_amount(type, this.state.selected_procedure_code.procedure_code_id);
            }
        } else {
            let format_data = this.empty_proc_code;
            if (parseInt(item.procedure_code_id) > 0) {
                let updated_amount = this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting ? await this.recalculate_charge_amount(type, Number(item.procedure_code_id)) : item.proc_amount;
                format_data = {
                    "title": item.title,
                    "label": item.label,
                    "procedure_code_id": Number(item.procedure_code_id),
                    "proc_amount": updated_amount,
                    "description": item.description,
                    "proc_code": item.proc_code,
                    "is_amt_enabled": item.is_amt_enabled.toString(),
                    "is_unit_enabled": "true",
                    "amount": this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting ? updated_amount : this.props.initial_data.proc_id == Number(item.procedure_code_id) ? this.props.initial_data.amount : item.proc_amount
                };
            }

            if(this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting && format_data.amount && (charge_state.amount != format_data.amount)){
                toaster.warning('', "Heads up! The Charge Amount has changed based on updates made.");
            }

            charge_state.total_charge_amount = Number(format_data.amount) * Number(charge_state.units);
            charge_state.amount = Number(format_data.amount);
            error.proc_id = false;

            if (this._is_mounted) {
                this.setState({
                    charge_details: charge_state,
                    selected_procedure_code: format_data,
                    error: error
                }, () => {
                    this.props.onObjectChange({
                        proc_id: item.procedure_code_id,
                        proc_code: item.proc_code,
                        proc_desc: item.description,
                        proc_amount: charge_state.amount,
                        total_charge_amount: charge_state.total_charge_amount
                    });
                });
            }
        }
    }

    // get the quick search location
    get_location_quick_search_data_list = async (search_keyword) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await quick_search_location(search_keyword, token);
    }
    // get the quick search provider
    get_provider_quick_search_data_list = async (search_keyword) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await quick_search_provider(search_keyword, token, 0);
    }

    // function/method to handle the when searching of referring physician occurs
    on_referring_Physician_search = async (params) => {
        this.configure_grid_data(enum_type_of_search.referring_physician, params);
    }

    configure_grid_data = async (type_of_search, params) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        params = params ? params : {};
        // params.page_size = 100;
        var grid_data = [];

        if (type_of_search === enum_type_of_search.referring_physician) {
            const search_data = await referring_physician_advance_search(params, token).then(res => res.data);
            const search_result = search_data.data !== null ? search_data.data.result : [];
            // const row_data = this.referring_physician_filter(search_result, type_of_search === enum_type_of_search.referring_physician ? this.state.case.secondary_md_code : this.state.case.md_code);
            let column_def = shared_constants.search_referring_physician_configuration(type_of_search).column_defs;

            grid_data = { ...this.state.grid_conf, rows: search_result, column: column_def, messages: search_data.messages };
            if (this._is_mounted) {
                this.setState({ primary_physician_search_data: grid_data });
            }
        }
        else if (type_of_search === enum_type_of_search.place_of_service) {
            const search_data_location = await search_place(params, token).then(res => res.data);
            const search_location_result = search_data_location.data !== null ? search_data_location.data.result : [];
            grid_data = { ...this.state.grid_conf, rows: search_location_result, column: shared_constants.search_location_configuration(type_of_search + 'grid_header_id').column_defs, messages: search_data_location.messages };
            if (this._is_mounted) {
                this.setState({ location_search_data: grid_data });
            }
        }
        else if (type_of_search === enum_type_of_search.provider) {

            params.id = this.state.charge_details.pos_code;
            params.apply_pos_code = false;
            const search_data_provider = await search_provider(params, token).then(res => res.data);
            const search_provider_result = search_data_provider.data !== null ? search_data_provider.data.result : [];
            grid_data = { ...this.state.grid_conf, rows: search_provider_result, column: shared_constants.search_provider_configuration(type_of_search + 'grid_header_id').column_defs, messages: search_data_provider.messages };
            if (this._is_mounted) {
                this.setState({ provider_search_data: grid_data });
            }
        } else {
            let search_data = await procedure_code_advance_search(params, this.token).then(res => res.data);
            const search_result = search_data.data !== null ? search_data.data.result : [];
            let col_def = this.column_def_format(shared_constants.search_procedure_code_configuration('procedure_code_searcch_').column_defs, search_result);
            grid_data = {
                ...this.state.grid_conf, rows: search_result,
                column: col_def,
                messages: search_data.messages
            };
            if (this._is_mounted) {
                this.setState({
                    loading: false,
                    procedure_code_search_data: grid_data
                });
            }
        }
    }
    column_def_format = (column_def, rows) => {
        if (rows && rows.length > 0) {
            let address1_length = 0, address2_length = 0;
            const result = rows.map((item) => {
                const text_address1_length = content_length(item.proc_desc1);
                if (text_address1_length > address1_length) {
                    address1_length = text_address1_length;
                }
                return item;
            });
            column_def = column_def.map(item => {
                if (item.field == 'proc_desc1') {
                    return { ...item, minWidth: content_width(address1_length) };
                }
                return item;
            })
        }
        return column_def;
    }

    set_parent_show_modal_state = (show_status, control_type) => {
        if (control_type == global_constants.constants.advanced_control_type.referring_physician) {
            if (this._is_mounted) {
                this.setState({ show_primary_advanced_control: show_status });
            }
        }
    }

    show_add_referring_modal_primary_physician = (e) => {
        this.show_add_referring_modal(e, "Primary Physician")
    }

    show_add_referring_modal = (e, type_of_physcian) => {
        if (this._is_mounted) {
            this.setState({ add_physician_modal: !this.state.add_physician_modal });
        }
    }
    clear_quick_search_referring_physician = () => {
        this.clear_quick_search(enum_type_of_search.referring_physician)
    }

    on_item_selection_referring_physician = (item) => {
        this.on_item_selection(item, enum_type_of_search.referring_physician);
    }

    prepare_suggestion_referring_physician = (data) => {
        return this.prepare_suggestion(data, enum_type_of_search.referring_physician)
    }

    get_input_value_referring_physician = (input) => {
        this.get_input_value(input, enum_type_of_search.referring_physician)
    }

    get_formatted_physician_info = (formatted_physician_info) => {
        let { charge_details } = this.state;
        charge_details.referring_physician.id = formatted_physician_info.id;
        charge_details.md_code = formatted_physician_info.id;
        if (this._is_mounted) {
            this.setState({
                referring_physician_quick_search_format: formatted_physician_info,
                add_physician_modal: false,
                show_primary_advanced_control: false,
                charge_details: charge_details
            });
        }
    }

    // get the quick search procedure code
    get_procedure_code_quick_search_data_list = async (search_keyword) => {
        return await procedure_code_quick_search(search_keyword, this.token);
    }

    on_dropdown_change = (value, e) => {
        let name = e.target.name;
        if (this._is_mounted) {
            this.setState(prev_state => ({
                charge_details: {
                    ...prev_state.charge_details,
                    [name]: value
                }
            }), () => {
                this.props.onDataChange(name, value);
            })
        }
    }

    handle_input_change = (e, input_name) => {
        const { value, name } = e.target;
        let value_updated = value;
        if (name == 'charge_id') {
            let isnum = /^\d+$/.test(value);
            if (isnum) {
                value_updated = value
            } else {
                value_updated = 0;
            }
        }

        this.setState(prevState => ({
            data: { ...prevState.data, [input_name]: value_updated },
        }), () => {
            this.props.onDataChange(input_name, value_updated);
        });
    }

    on_input_change = (e) => {
        let value = e.currentTarget.value;
        var isnum = /^\d+$/.test(value);
        var charge_state = { ...this.state.charge_details };
        let save_confirmation_unit_box = false;

        if (isnum) {
            charge_state.units = Number(value);
            if (charge_state.units > 9) {
                save_confirmation_unit_box = true;
            }
        } else {
            charge_state.units = '';
        }
        if( this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting){
            charge_state.total_charge_amount = Number(charge_state.units) * Number(charge_state.amount);
        }else{
            charge_state.total_charge_amount = Number(charge_state.units) * Number(this.state.selected_procedure_code.proc_amount);
            charge_state.amount = Number(this.state.selected_procedure_code.proc_amount);
        }

        if (this._is_mounted) {
            this.setState({
                charge_details: charge_state,
                save_confirmation_unit_box: save_confirmation_unit_box
            }, () => {
                this.props.onObjectChange({
                    units: charge_state.units,
                    proc_amount: charge_state.amount,
                    total_charge_amount: charge_state.total_charge_amount
                });
            });
        }
    }

    update_diagnosis_code = (name, value) => {
        if (this._is_mounted) {
            this.setState({
                [name]: value
            });
        }
    }

    render_radio_element = (name, value, label, is_disabled) => {
        if (is_disabled) return null;

        return (<label className="radio-inline">
            <input
                id={`${name}-${this.props.rowNum}`}
                name={`${this.props.rowNum}-${name}`}
                type="radio"
                onClick={this.on_check_change}
                checked={value || ''}
                readOnly
                disabled={is_disabled}
            /> {label}
        </label>);
    }

    render_calendar_element = (name, value, is_required = false, is_disabled = false) => {
        return (<Calendar
            id={`${name}-${this.props.rowNum}`}
            id_popper={get_popper_id()}
            maxDate={moment().format('MM/DD/YYYY')}
            date_update={(dt) => this.handleCalendarChange(dt, name)}
            date={value}
            is_required={is_required}
            class_name={"datepicker-keybowrd-charge"}
            is_disabled={is_disabled}
        />);
    }

    render_dropdown_element = (name, value, options,is_disabled = false) => {
        return (
            <Selection
                id={`${name}-${this.props.rowNum}`}
                name={name}
                defaultValue={value || ''}
                options={options}
                onChange={this.on_dropdown_change}
                disabled={is_disabled}
            />
        );
    }

    render_input_element = (name, value, is_disabled) => {
        let contentAlignment = this.props.IsErrorCorrectionsEnhancementsEnabled ? 'semantic-input-text-left' : '';
        return (
            <Form.Field >
                <Form.Input fluid
                    id={`${name}-${this.props.rowNum}`}
                    name={name}
                    maxLength={50}
                    value={currency_formatter(value || '')}
                    disabled={is_disabled}
                    onChange={this.on_input_change}
                    className={contentAlignment}
                />
            </Form.Field>
        );
    }

    on_blur_auto_search = (e, type_of_search) => {
    }

    onFocus = (e) => {
        let obj = e.target && e.target.getBoundingClientRect();
        let sibling = e.target && e.target.parentElement && e.target.parentElement.nextElementSibling;

        if (obj && sibling) {
            let style = `position:fixed;left:${obj.left}px;top:${obj.top + obj.height}px;width:${obj.width}px !important;`;
            sibling.setAttribute("style", style);
        }
    }

    render_advance_quick_search = (type, search_data, control_id, value, error_message, get_quick_search_data_list, has_error = false,is_disabled=false) => {
        return (
            <Form.Field className={`advance-quick-search ${type}-${this.props.row_num} ${has_error ? 'requiredWithBgColor' : ''}`}>
                <AdvancedControl
                    onGridRowSelection={(selected_row) => this.on_row_selection_advance_search(type, selected_row)}
                    gridConfig={search_data}
                    controlId={control_id}
                    onSearch={(params) => this.configure_grid_data(type, params)}
                    search_type={type}
                    headerIdForGridTabNavigation={type + 'grid_header_id'}
                    disabled_element={is_disabled}
                />
                <AutoSearchComponent
                    on_blur={(e) => { this.on_blur_auto_search(e, type) }}
                    control_id={control_id}
                    default_value={value}
                    on_focus={this.onFocus}
                    errorMessage={error_message}
                    prepareRenderList={this.render_suggestion_result}
                    getList={get_quick_search_data_list}
                    prepareDataList={(data) => this.prepare_suggestion(data, type)}
                    selectresult={(item) => this.on_item_selection(item, type)}
                    is_focus={false}
                    show_clear_search={true}
                    clear_search={() => this.clear_quick_search(type)}
                    errorClass={has_error ? 'req-background-inp' : ''}
                    is_disabled={is_disabled}
                />
            </Form.Field>
        );
    }

    prepare_charge_data = () => {
        let state = this.state
        let { charge_details, provider_quick_search_format, location_quick_search_format, referring_physician_quick_search_format, selected_procedure_code } = this.state
        const diagnosis = {};
        charge_details.selected_diagnosis_codes.map((dx, index) => {
            diagnosis[`dx${index + 1}`] = dx.value;
        });
        const modifiers = {}
        charge_details.selected_modifiers.map((mod, index) => {
            modifiers[`proc_mod${index + 1}_id`] = mod.value;
        });
        return {
            "date_of_service": charge_details.date_of_service ? this.get_date(charge_details.date_of_service, 'MM/DD/YYYY') : null,
            "start_of_care_date": charge_details.start_of_care_date ? this.get_date(charge_details.start_of_care_date, 'MM/DD/YYYY') : null,
            "plan_of_care_date": charge_details.plan_of_care_date ? this.get_date(charge_details.plan_of_care_date, 'MM/DD/YYYY') : null,
            "provider_id": provider_quick_search_format.title,
            "pos_Id": location_quick_search_format.title,
            "referring_physician_id": referring_physician_quick_search_format.title,
            "charge_id": this.props.data.charge_id,
            "proc_code": selected_procedure_code.proc_code,
            "proc_id": selected_procedure_code.title,
            "proc_amount": selected_procedure_code.proc_amount,
            "proc_desc": selected_procedure_code.description,
            "proc_mod1_id": charge_details.proc_mod1,
            "proc_mod2_id": charge_details.proc_mod2,
            "proc_mod3_id": charge_details.proc_mod3,
            "total_charge_amount": charge_details.total_charge_amount,
            "units": charge_details.units,
            "preauth_id": charge_details.preauth_id,
            "rebill": charge_details.rebill,
            "delete": charge_details.delete,
            "dx1": state.dx1.code,
            "dx2": state.dx2.code,
            "dx3": state.dx3.code,
            "dx4": state.dx4.code,
            "dx5": state.dx5.code,
            "dx6": state.dx6.code,
            "dx7": state.dx7.code,
            "dx8": state.dx8.code,
            "patient_id": this.patient_id,
            "charge_open_time": charge_details.charge_open_time,
            ...diagnosis,
            ...modifiers
        };
    }

    validate_dx_data = () => {
        let { charge_details, dx1, dx2, dx3, dx4, dx5, dx6, dx7, dx8 } = this.state;
        var chargeICDType = charge_details.cur_icd_type == '0' ? '1' : '0';

        if (
            (dx1.diagnosis_code_id > 0 && chargeICDType != dx1.icd_type) ||
            (dx2.diagnosis_code_id > 0 && chargeICDType != dx2.icd_type) ||
            (dx3.diagnosis_code_id > 0 && chargeICDType != dx3.icd_type) ||
            (dx4.diagnosis_code_id > 0 && chargeICDType != dx4.icd_type) ||
            (dx5.diagnosis_code_id > 0 && chargeICDType != dx5.icd_type) ||
            (dx6.diagnosis_code_id > 0 && chargeICDType != dx6.icd_type) ||
            (dx7.diagnosis_code_id > 0 && chargeICDType != dx7.icd_type) ||
            (dx8.diagnosis_code_id > 0 && chargeICDType != dx8.icd_type)
        ) {
            return false;
        }

        return true;
    }

    // validate the form with values
    validateForm = (prepared_data) => {

        let has_error = false;
        let error = {
            date_of_service: false,
            provider_id: false,
            pos_Id: false,
            proc_id: false
        };

        if (!prepared_data.proc_id) {
            has_error = true;
            error.proc_id = true;
            let ele = document.getElementById("PROCEDURE_CODE");
            ele && ele.focus();
        }
        if (!prepared_data.pos_Id) {
            has_error = true;
            error.pos_Id = true;
            let ele = document.getElementById("LOCATION");
            ele && ele.focus();
        }
        if (!prepared_data.provider_id) {
            has_error = true;
            error.provider_id = true;
            let provider_ele = document.getElementById("PROVIDER");
            provider_ele && provider_ele.focus();
        }
        if (!prepared_data.date_of_service) {
            has_error = true;
            error.date_of_service = true;
            let dos_ele = document.getElementById("date_of_service");
            dos_ele && dos_ele.focus();
        }

        if (has_error) {
            toaster.error('', global_constants.constants.mandatory_fields);
            if (this._is_mounted) {
                this.setState({
                    error: error
                });
            }

            return false;
        }

        if (!prepared_data.dx1 && !prepared_data.dx2 && !prepared_data.dx3 && !prepared_data.dx4 && !prepared_data.dx5 && !prepared_data.dx6 && !prepared_data.dx7 && !prepared_data.dx8) {
            toaster.error('', charge_messages.req_atleast_1dx_for_charge);
            if (this._is_mounted) {
                this.setState({
                    error: error
                });
            }

            return false;
        }


        return true
    }

    get_updated_data = () => {
        let prepared_data = this.prepare_charge_data();

        if (this._is_mounted) {
            this.setState({
                form_submited: true
            })
        }

        return prepared_data;
    }


    prepare_data_to_save = (bypass_dx_check: boolean = false) => {

        let prepared_data = this.prepare_charge_data();

        if (this._is_mounted) {
            this.setState({
                form_submited: true
            })
        }

        if (!this.validateForm(prepared_data)) return;

        // if data is not changed
        if (!this.is_data_unsaved(prepared_data)) return;

        if (!bypass_dx_check) {
            if (!this.validate_dx_data()) return;
        }

        // return prepared_data;
        save_charge_for_error_correction(prepared_data, this.token).then(res => {
            if (res.data.data && this._is_mounted) {
                this.initialize_charge_details(res.data.data);
            } else {
                if (res.data.messages && res.data.messages[0].message) {
                    let charge_exception = res.data.messages.filter((item) => {
                        return item.code === global_constants.charge_exception.code
                    })
                    if (charge_exception && charge_exception.length > 0) {
                        if (this._is_mounted) {
                            this.setState({
                                loading: false,
                                show_bummepd_charge_message_popup: true
                            })
                        }
                    }
                    else {
                        let toastr_options = this.show_html_content_toaster(get_all_error(res.data));
                        toaster.error("", toastr_options);
                    }

                }
            }
        },
            error => {
                if (error && error.response && error.response.data && error.response.data.messages && error.response.data.messages.length > 0) {
                    let charge_exception = error.response.data.messages.filter((item) => {
                        return item.code === global_constants.charge_exception.code
                    })
                    if (charge_exception && charge_exception.length > 0) {
                        if (this._is_mounted) {
                            this.setState({
                                loading: false,
                                show_bummepd_charge_message_popup: true
                            })
                        }
                    } else {
                        if (this._is_mounted) {
                            this.setState({
                                loading: false
                            })
                        }
                        const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                        toaster.error('', toastr_options);
                    }
                }
                else {
                    if (this._is_mounted) {
                        this.setState({
                            loading: false
                        })
                    }
                    if (error.response.data) {
                        const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                        toaster.error("", toastr_options);
                    }
                }
            });
    }

    mark_form_submitted = () => {
        if (this._is_mounted) {
            this.setState({
                form_submited: true
            })
        }

    }

    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }


    is_data_unsaved = (form_object) => {
        if (JSON.stringify(this.initial_form_object) !== JSON.stringify(form_object)) {
            return true;
        }
        return false;
    }

    update_data = (source) => {
        let target = [];
        let screenWidth = window.screen.width;
        let subVal = 8;
        if (screenWidth > 1364) {
            subVal = 12;
        }
        if (source && source.length > 0) {
            for (let y = 0; y < source.length; y++) {
                let item = source[y];
                let text_val = (item.code ? item.code : '') + ' - ' + (item.description ? item.description : '');

                target.push({
                    disabled: false,
                    is_checked: false,
                    name: text_val,
                    ...item,
                    value: item.code,
                    label_text: text_val.substring(0, subVal)
                })
            }
        }
        return target;
    }

    // multi select change handler
    multi_select_handler = (id, values) => {
        let dropdown_data = this.state.dropdown_data[id].map((item) => {
            if (values.indexOf(item.value) > -1) {
                item = {
                    ...item,
                    checked: true,
                    is_checked: true
                };
            } else {
                item = {
                    ...item,
                    checked: false,
                    is_checked: false
                };
            }
            return item;
        });

        if (this._is_mounted) {
            this.setState((prevState) => ({
                charge_details: {
                    ...prevState.charge_details,
                    ['selected_' + id]: [...values]
                },
                dropdown_data: {
                    ...prevState.dropdown_data,
                    [id]: dropdown_data
                }
            }), () => {
                let param = {};
                if (id == 'diagnosis_codes') {
                    param = { ...dx_keys };
                    values.map((dx, index) => {
                        param[`dx${index + 1}`] = dx.value;
                        param[`dx${index + 1}_desc`] = dx.description;
                        param[`dx${index + 1}_icd_type`] = dx.icd_type;
                    });
                } else {
                    param = { ...mod_keys };
                    values.map((mod, index) => {
                        param[`proc_mod${index + 1}_id`] = mod.value;
                        param[`proc_mod${index + 1}`] = mod.procedure_modifier_code;
                        param[`proc_mod${index + 1}_desc`] = mod.procedure_modifier_desc;
                    });
                }
                this.props.onObjectChange(param);
            });
        }
    };

    get_initial_diagnosis_codes_list = async () => {
        this.setState({no_record_msg: " "})
        let query_string = `pageIndex=${this.page_index}&pageSize=${this.page_size}`;
        await search_diagnosis_codes_by_page(this.token, query_string)
            .then((res) => {
                if (res.data && res.data.data && res.data.data.result && res.data.data.result.length > 0) {
                    if (this._is_mounted) {
                        this.page_index = res.data.data.pageIndex;
                        this.page_size = res.data.data.pageSize;
                        this.setState(prevState => ({
                            dropdown_data: {
                                ...prevState.dropdown_data,
                                diagnosis_codes: this.update_data(res.data.data.result)
                            },
                        }));
                    }
                } else {
                    if (this._is_mounted) {
                        this.setState(prevState => ({
                            dropdown_data: {
                                ...prevState.dropdown_data,
                                diagnosis_codes: this.update_data([])
                            },
                            no_record_msg: "No Record Found !"
                        }));
                    }
                }                
            }, (error) => {
                if (this._is_mounted) {
                    this.setState(prevState => ({
                        dropdown_data: {
                            ...prevState.dropdown_data,
                            diagnosis_codes: this.update_data([])
                        },
                        no_record_msg: "No Record Found !"
                    }));
                }
            });
    }

    update_amount = (value) => {
        var charge_state = { ...this.state.charge_details };
        charge_state.amount = Number(value);
        charge_state.total_charge_amount = Number(value) * Number(charge_state.units);
        
        if (this._is_mounted) {
            this.setState({
                charge_details: charge_state
            }, () => {
                this.props.onObjectChange({
                    amount: charge_state.amount,
                    proc_amount: charge_state.amount,
                    total_charge_amount: charge_state.total_charge_amount
                });
            });
        }
    }
    
    render_form_regular = () => {
        const { provider_quick_search_format, location_quick_search_format, location_search_data, provider_search_data, referring_physician_quick_search_format, selected_procedure_code, procedure_code_search_data, error, dropdown_data } = this.state;
        const { allow_delete, amount, payment, adjustment,
            insurance_balance, balance, patient_balance,
            date_of_service, plan_of_care_date, preauth_id,
            units, total_charge_amount, is_active, is_charge_locked } = this.state.charge_details;

        let table_width = document.getElementById('error-correction-table').clientWidth;
        let delete_charge_flag =  is_active ? this.props.allowChargeDelete ? false : !allow_delete: !is_active;

        return (<Grid style={{ 'width': table_width }} className='error-corrections-non-print-component' >
            <Grid.Column tablet={4} computer={4}>
                <Form.Field >
                    <label>Charge ID</label>
                    <Form.Input fluid
                        id={`charge_id-${this.props.rowNum}`}
                        name='charge_id'
                        className="input-disabled-control"
                        maxLength={9}
                        error={false}
                        disabled
                        value={this.props.data.charge_id || ''}
                        onChange={e => this.handle_input_change(e, 'charge_id')}
                    />
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}>
                <Form.Field className={"custom-datepicker-for-table"}>
                    <label>Date of Service</label>
                    <div className={error.date_of_service && this.props.isSubmitted ? "error requiredWithBgColor " : ""}>
                        {this.render_calendar_element("date_of_service", date_of_service, error.date_of_service && this.props.isSubmitted, !is_active)}
                    </div>
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}>
                <Form.Field >
                    <label>Procedure Code</label>
                    {this.render_advance_quick_search("procedure_code",
                        procedure_code_search_data,
                        global_constants.constants.advanced_control_type.procedure_code,
                        selected_procedure_code,
                        'No Record Found !', this.get_procedure_code_quick_search_data_list, error.proc_id && this.props.isSubmitted, !is_active)}
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}>
                <Form.Field >
                    <label>Units</label>
                    <Form.Field >
                        <Form.Input fluid
                            id={`units-${this.props.rowNum}`}
                            name={'units'}
                            maxLength={50}
                            value={units || ''}
                            onChange={this.on_input_change}
                            disabled={!is_active}
                        />
                    </Form.Field>
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}>
                <Form.Field>
                    <label>Charge Amount</label>
                    <CurrencyFormatter
                        onChange={(values) => { this.update_amount(values) }}
                        prefix='$'
                        id={'amount'}
                        maxLength={15}
                        defaultValue={amount || 0}
                        allowNegative={true}
                        disabled={is_active ? this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting && !is_charge_locked ? !(selected_procedure_code.is_amt_enabled == 'true') : true : true}
                    />
                    </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}>
                <Form.Field >
                    <label>Total Charge Amount</label>
                    {this.render_input_element('total_charge_amount', total_charge_amount, true)}
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}>
                <Form.Field >
                    <label>Payment</label>
                    {this.render_input_element('payment', payment, true)}
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}>
                <Form.Field >
                    <label>Adjustment</label>
                    {this.render_input_element('adjustment', adjustment, true)}
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}>
                <Form.Field >
                    <label>Balance</label>
                    {this.render_input_element('balance', balance, true)}
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}>
                <Form.Field >
                    <label>Insurance Balance</label>
                    {this.render_input_element('insurance_balance', insurance_balance, true)}
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}>
                <Form.Field >
                    <label>Patient Balance</label>
                    {this.render_input_element('patient_balance', patient_balance, true)}
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}>
                <Form.Field >
                    <label>Last Modified</label>

                    <Form.Input fluid
                        id={`updated_date-${this.props.rowNum}`}
                        name='updated_date'
                        className="input-disabled-control"
                        maxLength={9}
                        error={false}
                        disabled
                        value={`${format_date(this.props.data.updated_date, 'MM/DD/YYYY')} - ${this.props.data.modified_by}`}
                        onChange={e => this.handle_input_change(e, 'updated_date')}
                    />
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}>
                <Form.Field >
                    <label>Provider</label>
                    {this.render_advance_quick_search(enum_type_of_search.provider,
                        provider_search_data,
                        global_constants.constants.advanced_control_type.provider,
                        provider_quick_search_format,
                        'No Billing Provider Found !',
                        this.get_provider_quick_search_data_list,
                        error.provider_id && this.props.isSubmitted,
                        !is_active)}
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}>
                <Form.Field >
                    <label>Location</label>
                    {this.render_advance_quick_search(enum_type_of_search.place_of_service,
                        location_search_data,
                        global_constants.constants.advanced_control_type.location,
                        location_quick_search_format,
                        'No Record Found !', this.get_location_quick_search_data_list, error.pos_Id && this.props.isSubmitted,!is_active)}
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}>
                <Form.Field className="advance-quick-search">
                    <label>Referring Physician</label>
                    <AdvancedControl showModal={this.state.show_primary_advanced_control}
                        set_parent_show_modal_state={this.set_parent_show_modal_state}
                        addClicks={this.show_add_referring_modal_primary_physician}
                        disabled_element={!is_active}
                        onGridRowSelection={this.on_primary_physician_grid_row_selection}
                        gridConfig={this.state.primary_physician_search_data}
                        controlId={global_constants.constants.advanced_control_type.referring_physician}
                        onSearch={this.on_referring_Physician_search}
                        search_type={'REFERRING_PHYSICIAN'}
                        headerIdForGridTabNavigation={enum_type_of_search.referring_physician}
                    />

                    <AutoSearchComponent
                        on_blur={(e) => { this.on_blur_auto_search(e, enum_type_of_search.referring_physician) }}
                        on_focus={this.onFocus}
                        getInputValue={this.get_input_value_referring_physician} default_value={referring_physician_quick_search_format}
                        errorMessage={'No Physician Found !'} prepareRenderList={this.render_suggestion_result} getList={this.get_referring_physician_quick_search_data_list}
                        prepareDataList={this.prepare_suggestion_referring_physician}
                        selectresult={this.on_item_selection_referring_physician} is_focus={false}
                        is_disabled={!is_active}
                        show_clear_search={true}
                        clear_search={this.clear_quick_search_referring_physician}
                        errorClass={''}
                        control_id={'auto' + global_constants.constants.advanced_control_type.referring_physician}
                    />
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}>
                <Form.Field className={"custom-datepicker-for-table"}>
                    <label>Plan of Care Date</label>
                    <div className={error.plan_of_care_date && this.props.isSubmitted ? "error requiredWithBgColor " : ""}>
                        {this.render_calendar_element("plan_of_care_date", plan_of_care_date, error.plan_of_care_date && this.props.isSubmitted,!is_active)}
                    </div>
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}>
                <Form.Field >
                    <label>Preauthorization</label>
                    {this.render_dropdown_element('preauth_id', preauth_id, this.props.preauth_list, !is_active)}
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}>
                <Form.Field >
                    <label>Modifiers</label>
                   
                    <MultiSelectDraggableDropDownComponent
                        class_name='manage-content-multi-select'
                        id={`modifiers-${this.props.rowNum}`}
                        key_id='modifiers'
                        search_box_id='modifiers'
                        data={delete_charge_flag && this.props.rsiB35911ErrorCorrectionsPreventUserFromAddingNcModifierToChargeWithActivePayments ? dropdown_data && dropdown_data.modifiers && dropdown_data.modifiers.length> 0 && dropdown_data.modifiers.filter(obj => obj.procedure_modifier_code != "NC") : dropdown_data.modifiers }
                        selected_list={this.state.charge_details.selected_modifiers || []}
                        onChangeSelected={this.multi_select_handler}
                        forceOnChangeSelected={true}
                        show_filter={true}
                        allow_disable={true}
                        is_disabled={!is_active}
                    />
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}>
                <Form.Field >
                    <label>Diagnosis Codes</label>
                    <MultiSelectDraggableDropDownComponent
                        class_name='manage-content-multi-select'
                        id={`diagnosis_codes-${this.props.rowNum}`}
                        key_id='diagnosis_codes'
                        search_box_id='diagnosis_codes'
                        data={dropdown_data.diagnosis_codes}
                        selected_list={this.state.charge_details.selected_diagnosis_codes || []}
                        onChangeSelected={this.multi_select_handler}
                        forceOnChangeSelected={true}
                        show_filter={true}
                        is_lazy_loading={true}
                        allow_disable={true}
                        page_size_for_lazy_load={20}
                        initial_request={this.get_initial_diagnosis_codes_list}
                        no_record={this.state.no_record_msg}
                        getData={this.props.getDxData}
                        pageSize={DX_API_PAGE_SIZE}
                        type={'diagnosis_codes'}
                        formatMultiSelect={format_multi_select_data}
                        is_disabled={!is_active}
                    />
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}><div style={{ display: 'flex' }}>
                <Form.Field >
                    <label><p className="ui-grid-label-empty">TEXT</p></label>
                    {this.render_radio_element("rebill", this.state.charge_details.rebill, 'Rebill', !is_active)}
                </Form.Field>
                <Form.Field style={{ marginLeft: 20 }}>
                    <label><p className="ui-grid-label-empty">TEXT</p></label>
                    {this.render_radio_element("delete", this.state.charge_details.delete, 'Delete', is_active ? this.props.allowChargeDelete ? false : !allow_delete: !is_active)}
                </Form.Field>
            </div>
            </Grid.Column>
        </Grid>);
    }

    render_form_enhanced = () => {
        const { provider_quick_search_format, location_quick_search_format, location_search_data, provider_search_data, referring_physician_quick_search_format, selected_procedure_code, procedure_code_search_data, error, dropdown_data } = this.state;
        const { allow_delete, amount, payment, adjustment,
            insurance_balance, balance, patient_balance,
            date_of_service, plan_of_care_date, preauth_id,
            units, total_charge_amount, is_active, is_charge_locked } = this.state.charge_details;

        let delete_charge_flag =  is_active ? this.props.allowChargeDelete ? false : !allow_delete: !is_active;
        let columnClass = 'error-correction-column';
        let labelClass = 'error-corrections-label';
        let gridClass = 'error-corrections-grid';
        let contentClass = 'text-left';
        let currencyClass = `numberFormat ${contentClass}`;
        let rowClass = 'error-corrections-row';
        
        return (<Grid className='error-corrections-non-print-component' >
            {/* First Row */}
            <Grid.Row className={rowClass}>
                <Grid.Column className={columnClass} tablet={3} computer={3}>
                    <Grid className= {gridClass}>
                        <Grid.Column className={columnClass} tablet={8} computer={8}>
                            <label className={labelClass}>Charge ID</label>
                        </Grid.Column>
                        <Grid.Column className={columnClass} tablet={8} computer={8}>
                            <label className={labelClass}>Date of Service</label>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column className={columnClass} tablet={6} computer={6}>
                    <Grid className= {gridClass}>
                        <Grid.Column className={columnClass} tablet={4} computer={4}>
                            <label className={labelClass}>Procedure Code</label>
                        </Grid.Column>
                        <Grid.Column className={columnClass} tablet={4} computer={4}>
                            <label className={labelClass}>Units</label>
                        </Grid.Column>
                        <Grid.Column className={columnClass} tablet={4} computer={4}>
                            <label className={labelClass}>Charge</label>
                        </Grid.Column>
                        <Grid.Column className={columnClass} tablet={4} computer={4}>
                            <label className={labelClass}>Total Charge</label>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column className={columnClass} tablet={3} computer={3}>
                    <Grid className= {gridClass}>
                        <Grid.Column className={columnClass} tablet={8} computer={8}>
                            <label className={labelClass}>Payment</label>
                        </Grid.Column>
                        <Grid.Column className={columnClass} tablet={8} computer={8}>
                            <label className={labelClass}>Adjustment</label>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column className={columnClass} tablet={4} computer={4}>
                    <Grid className= {gridClass}>
                        <Grid.Column className={columnClass} tablet={5} computer={5}>
                            <label className={labelClass}>Balance</label>
                        </Grid.Column>
                        <Grid.Column className={columnClass} tablet={5} computer={5}>
                            <label className={labelClass}>Ins Balance</label>
                        </Grid.Column>
                        <Grid.Column className={columnClass} tablet={6} computer={6}>
                            <label className={labelClass}>Patient Balance</label>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className={`${rowClass} components-row`}>
                <Grid.Column className={columnClass} tablet={3} computer={3}>
                    <Grid className= {gridClass}>
                        <Grid.Column className={columnClass} tablet={8} computer={8}>
                        <Form.Input fluid
                                id={`charge_id-${this.props.rowNum}`}
                                name='charge_id'
                                className="input-disabled-control"
                                maxLength={9}
                                error={false}
                                disabled
                                value={this.props.data.charge_id || ''}
                                onChange={e => this.handle_input_change(e, 'charge_id')}
                            />
                        </Grid.Column>
                        <Grid.Column className={columnClass} tablet={8} computer={8}>
                            
                            <Form.Field className={"custom-datepicker-for-table"}>
                                <div className={error.date_of_service && this.props.isSubmitted ? "error requiredWithBgColor " : ""}>
                                    {this.render_calendar_element("date_of_service", date_of_service, error.date_of_service && this.props.isSubmitted, !is_active)}
                                </div>
                            </Form.Field>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column className={columnClass} tablet={6} computer={6}>
                    <Grid className= {gridClass}>
                        <Grid.Column className={columnClass} tablet={4} computer={4}>
                        {this.render_advance_quick_search("procedure_code",
                                procedure_code_search_data,
                                global_constants.constants.advanced_control_type.procedure_code,
                                selected_procedure_code,
                                'No Record Found !', this.get_procedure_code_quick_search_data_list, error.proc_id && this.props.isSubmitted, !is_active)}
                        </Grid.Column>
                        <Grid.Column className={columnClass} tablet={4} computer={4}>
                        <Form.Input fluid
                                    id={`units-${this.props.rowNum}`}
                                    name={'units'}
                                    maxLength={50}
                                    value={units || ''}
                                    onChange={this.on_input_change}
                                    disabled={!is_active}
                                />
                        </Grid.Column>
                        <Grid.Column className={columnClass} tablet={4} computer={4}>
                            <CurrencyFormatter
                                    onChange={(values) => { this.update_amount(values) }}
                                    prefix='$'
                                    id={'amount'}
                                    maxLength={15}
                                    defaultValue={amount || 0}
                                    allowNegative={true}
                                    className={currencyClass}
                                    disabled={is_active ? this.props.rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting && !is_charge_locked ? !(selected_procedure_code.is_amt_enabled == 'true') : true : true}
                                />
                        </Grid.Column>
                        <Grid.Column className={columnClass} tablet={4} computer={4}>
                        {this.render_input_element('total_charge_amount', total_charge_amount, true)}
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column className={columnClass} tablet={3} computer={3}>
                    <Grid className= {gridClass}>
                        <Grid.Column className={columnClass} tablet={8} computer={8}>
                            {this.render_input_element('payment', payment, true)}
                        </Grid.Column>
                        <Grid.Column className={columnClass} tablet={8} computer={8}>
                            {this.render_input_element('adjustment', adjustment, true)}
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column className={columnClass} tablet={4} computer={4}>
                    <Grid className= {gridClass}>
                        <Grid.Column className={columnClass} tablet={5} computer={5}>
                            {this.render_input_element('balance', balance, true)}
                        </Grid.Column>
                        <Grid.Column className={columnClass} tablet={5} computer={5}>
                            {this.render_input_element('insurance_balance', insurance_balance, true)}
                        </Grid.Column>
                        <Grid.Column className={columnClass} tablet={6} computer={6}>
                            {this.render_input_element('patient_balance', patient_balance, true)}
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid.Row>

            {/* Second Row */}
            <Grid.Row className={rowClass}>
                <Grid.Column className={columnClass} tablet={3} computer={3}>
                    <Grid className= {gridClass}>
                        <Grid.Column className={columnClass} tablet={16} computer={16}>
                            <label className={labelClass}>Provider</label>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column className={columnClass} tablet={6} computer={6}>
                    <Grid className= {gridClass}>
                        <Grid.Column className={columnClass} tablet={8} computer={8}> 
                            <label className={labelClass}>Location</label>
                        </Grid.Column>
                        <Grid.Column className={columnClass} tablet={8} computer={8}> 
                            <label className={labelClass}>Referring Physician</label>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column className={columnClass} tablet={3} computer={3}>
                    <Grid className= {gridClass}>
                        <Grid.Column className={columnClass} tablet={16} computer={16}>
                            <label className={labelClass}>Preauthorization</label>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column className={columnClass} tablet={4} computer={4}>
                    <Grid className= {gridClass}>
                        <Grid.Column className={columnClass} tablet={16} computer={16}> 
                            <label className={labelClass}>Plan of Care Date</label>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className={`${rowClass} components-row`}>
                <Grid.Column className={columnClass} tablet={3} computer={3}>
                    <Grid className= {gridClass}>
                        <Grid.Column className={columnClass} tablet={16} computer={16}>
                        {this.render_advance_quick_search(enum_type_of_search.provider,
                                provider_search_data,
                                global_constants.constants.advanced_control_type.provider,
                                provider_quick_search_format,
                                'No Billing Provider Found !',
                                this.get_provider_quick_search_data_list,
                                error.provider_id && this.props.isSubmitted,
                                !is_active)}
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column className={columnClass} tablet={6} computer={6}>
                    <Grid className= {gridClass}>
                        <Grid.Column className={columnClass} tablet={8} computer={8}> 
                        {this.render_advance_quick_search(enum_type_of_search.place_of_service,
                                location_search_data,
                                global_constants.constants.advanced_control_type.location,
                                location_quick_search_format,
                                'No Record Found !', this.get_location_quick_search_data_list, error.pos_Id && this.props.isSubmitted,!is_active)}
                        </Grid.Column>
                        <Grid.Column className={columnClass} tablet={8} computer={8}> 
                        <Form.Field className="advance-quick-search">
                            <AdvancedControl showModal={this.state.show_primary_advanced_control}
                                set_parent_show_modal_state={this.set_parent_show_modal_state}
                                addClicks={this.show_add_referring_modal_primary_physician}
                                disabled_element={!is_active}
                                onGridRowSelection={this.on_primary_physician_grid_row_selection}
                                gridConfig={this.state.primary_physician_search_data}
                                controlId={global_constants.constants.advanced_control_type.referring_physician}
                                onSearch={this.on_referring_Physician_search}
                                search_type={'REFERRING_PHYSICIAN'}
                                headerIdForGridTabNavigation={enum_type_of_search.referring_physician}
                            />

                            <AutoSearchComponent
                                on_blur={(e) => { this.on_blur_auto_search(e, enum_type_of_search.referring_physician) }}
                                on_focus={this.onFocus}
                                getInputValue={this.get_input_value_referring_physician} default_value={referring_physician_quick_search_format}
                                errorMessage={'No Physician Found !'} prepareRenderList={this.render_suggestion_result} getList={this.get_referring_physician_quick_search_data_list}
                                prepareDataList={this.prepare_suggestion_referring_physician}
                                selectresult={this.on_item_selection_referring_physician} is_focus={false}
                                is_disabled={!is_active}
                                show_clear_search={true}
                                clear_search={this.clear_quick_search_referring_physician}
                                errorClass={''}
                                control_id={'auto' + global_constants.constants.advanced_control_type.referring_physician}
                            />
                        </Form.Field>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column className={columnClass} tablet={3} computer={3}>
                    <Grid className= {gridClass}>
                        <Grid.Column className={columnClass} tablet={16} computer={16}>
                        {this.render_dropdown_element('preauth_id', preauth_id, this.props.preauth_list, !is_active)}
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column className={columnClass} tablet={4} computer={4}>
                    <Grid className= {gridClass}>
                        <Grid.Column className={columnClass} tablet={16} computer={16}> 
                        <Form.Field className={"custom-datepicker-for-table"}>
                            <div className={error.plan_of_care_date && this.props.isSubmitted ? "error requiredWithBgColor " : ""}>
                                {this.render_calendar_element("plan_of_care_date", plan_of_care_date, error.plan_of_care_date && this.props.isSubmitted,!is_active)}
                            </div>
                        </Form.Field>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid.Row>

            {/* Third Row */}
            <Grid.Row className={rowClass}>
                <Grid.Column className={columnClass} tablet={3} computer={3}>
                    <Grid className= {gridClass}>
                        <Grid.Column className={columnClass} tablet={16} computer={16}>
                            <label className={labelClass}>Diagnosis Codes</label>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column className={columnClass} tablet={6} computer={6}>
                    <Grid className= {gridClass}>
                        <Grid.Column className={columnClass} tablet={16} computer={16}>
                            <label className={labelClass}>Modifiers</label>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column className={columnClass} tablet={3} computer={3}>
                    <Grid className= {gridClass}>
                        <Grid.Column className={columnClass} tablet={16} computer={16}>
                            <label className={labelClass}>Last Modified</label>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column className={columnClass} tablet={4} computer={4}>
                    <Grid className= {gridClass}>
                        <Grid.Column className={columnClass} tablet={8} computer={8}>
                            <label><p className="ui-grid-label-empty-error-corrections">TEXT</p></label>
                        </Grid.Column>
                        <Grid.Column className={columnClass} tablet={8} computer={8}>
                        <label><p className="ui-grid-label-empty-error-corrections">TEXT</p></label>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className={`${rowClass} components-row`}>
                <Grid.Column className={columnClass} tablet={3} computer={3}>
                    <Grid className= {gridClass}>
                        <Grid.Column className={columnClass} tablet={16} computer={16}>
                        <Form.Field >
                            <MultiSelectDraggableDropDownComponent
                                class_name='manage-content-multi-select'
                                id={`diagnosis_codes-${this.props.rowNum}`}
                                key_id='diagnosis_codes'
                                search_box_id='diagnosis_codes'
                                data={dropdown_data.diagnosis_codes}
                                selected_list={this.state.charge_details.selected_diagnosis_codes || []}
                                onChangeSelected={this.multi_select_handler}
                                forceOnChangeSelected={true}
                                show_filter={true}
                                is_lazy_loading={true}
                                allow_disable={true}
                                page_size_for_lazy_load={20}
                                initial_request={this.get_initial_diagnosis_codes_list}
                                no_record={this.state.no_record_msg}
                                getData={this.props.getDxData}
                                pageSize={DX_API_PAGE_SIZE}
                                type={'diagnosis_codes'}
                                formatMultiSelect={format_multi_select_data}
                                is_disabled={!is_active}
                            />
                        </Form.Field>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column className={columnClass} tablet={6} computer={6}>
                    <Grid className= {gridClass}>
                        <Grid.Column className={columnClass} tablet={16} computer={16}>
                        <Form.Field >
                            <MultiSelectDraggableDropDownComponent
                                class_name='manage-content-multi-select'
                                id={`modifiers-${this.props.rowNum}`}
                                key_id='modifiers'
                                search_box_id='modifiers'
                                data={delete_charge_flag && this.props.rsiB35911ErrorCorrectionsPreventUserFromAddingNcModifierToChargeWithActivePayments ? dropdown_data && dropdown_data.modifiers && dropdown_data.modifiers.length> 0 && dropdown_data.modifiers.filter(obj => obj.procedure_modifier_code != "NC") : dropdown_data.modifiers }
                                selected_list={this.state.charge_details.selected_modifiers || []}
                                onChangeSelected={this.multi_select_handler}
                                forceOnChangeSelected={true}
                                show_filter={true}
                                allow_disable={true}
                                is_disabled={!is_active}
                                tags_per_row = {3}
                            />
                        </Form.Field>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column className={columnClass} tablet={3} computer={3}>
                    <Grid className= {gridClass}>
                        <Grid.Column className={columnClass} tablet={16} computer={16}>
                        <Form.Field >
                            <Form.Input fluid
                                id={`updated_date-${this.props.rowNum}`}
                                name='updated_date'
                                className="input-disabled-control"
                                maxLength={9}
                                error={false}
                                disabled
                                value={`${format_date(this.props.data.updated_date, 'MM/DD/YYYY')} - ${this.props.data.modified_by}`}
                                onChange={e => this.handle_input_change(e, 'updated_date')}
                            />
                        </Form.Field>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column className={columnClass} tablet={4} computer={4}>
                    <Grid className= {gridClass}>
                        <Grid.Column className={columnClass} tablet={8} computer={8}>
                            {this.render_radio_element("rebill", this.state.charge_details.rebill, 'Rebill', !is_active)}
                        </Grid.Column>
                        <Grid.Column className={columnClass} tablet={8} computer={8}>
                            {this.render_radio_element("delete", this.state.charge_details.delete, 'Delete', is_active ? this.props.allowChargeDelete ? false : !allow_delete: !is_active)}
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
        </Grid>);
    }
    // Charge from
    render_form = () => {
      return this.props.IsErrorCorrectionsEnhancementsEnabled ? this.render_form_enhanced() : this.render_form_regular();
    }

    on_close_unit_confirmation = () => {
        let charge_state = this.state.charge_details;

        charge_state.units = 1
        charge_state.total_charge_amount = Number(charge_state.units) * Number(charge_state.amount);

        if (this._is_mounted) {
            this.setState({
                charge_details: charge_state,
                save_confirmation_unit_box: false
            }, () => {
                this.props.onObjectChange({
                    units: charge_state.units,
                    total_charge_amount: charge_state.total_charge_amount
                });
            });
        }
    }

    on_save_unit_confirmation = () => {
        if (this._is_mounted) {
            this.setState({
                save_confirmation_unit_box: false
            });
        }
    }


    refresh_bummepd_charge_modal = () => {
        if (this._is_mounted) {
            this.setState({
                show_bummepd_charge_message_popup: false
            }, () => {
                this.props.history.goBack();
            });
        }
    }
    on_close_bummepd_charge_popup = () => {
        if (this._is_mounted) {
            this.setState({
                show_bummepd_charge_message_popup: false
            });
        }
    }

    printable_charge_data = (data) => {
        const { provider_quick_search_format, location_quick_search_format, referring_physician_quick_search_format, selected_procedure_code } = this.state;
        const { amount, payment, adjustment, insurance_balance, balance, patient_balance, date_of_service, plan_of_care_date, preauth_id, total_charge_amount, selected_modifiers, selected_diagnosis_codes } = this.state.charge_details;

        let preval = this.props.preauth_list.find(val => val.value == preauth_id);

        var modifiers_list = selected_modifiers.map(function (item) {
            return item['name'];
        });

        var diagnosis_list = selected_diagnosis_codes.map(function (item) {
            return item['name'];
        });

        const form_data = { ...data };
        form_data['date_of_service'] = this.get_date(date_of_service, 'MM/DD/YYYY');
        form_data['proc_code'] = selected_procedure_code.label;
        form_data['amount'] = currency_formatter(amount || '');
        form_data['total_charge_amount'] = currency_formatter(total_charge_amount || '');
        form_data['payment'] = currency_formatter(payment || '');
        form_data['adjustment'] = currency_formatter(adjustment || '');
        form_data['balance'] = currency_formatter(balance || '');
        form_data['insurance_balance'] = currency_formatter(insurance_balance || '');
        form_data['patient_balance'] = currency_formatter(patient_balance || '');
        form_data['updated_date'] = `${this.get_date(form_data.updated_date, 'MM/DD/YYYY')} - ${form_data.modified_by}`
        form_data['plan_of_care_date'] = this.get_date(plan_of_care_date, 'MM/DD/YYYY');
        form_data['provider'] = provider_quick_search_format.label || '';
        form_data['place_of_service'] = location_quick_search_format.label || '';
        form_data['referring_physician'] = referring_physician_quick_search_format.label || '';
        form_data['preauth'] = preval && preval.value != '0' ? preval.text : '';
        form_data['modifiers'] = modifiers_list.join() || '';
        form_data['diagnosis_codes'] = diagnosis_list.join() || '';

        return form_data;
    }

    printable_payment_data = (data) => {
        if (data && data.length > 0) {
            return data.map(val => this.format_printable_payment_data(val))
        }
        return [];
    }

    format_printable_payment_data = (data) => {
        if (data.hide_row) {
            return null;
        }
        const form_data = { ...data };
        let incCode = this.props.insuranceCode.find(val => val.value == form_data.from_ins_code);
        let payment_code = this.props.paymentCode.find(val => val.value == form_data.payment_code);
        let adj_code = this.props.adjustmentCode.find(val => val.value == form_data.adjustment_code);
        let tranfer_insurance_code = this.props.tranferInsuranceCode.find(val => val.value == form_data.xfer_ins_code);
        let paymentType = this.props.paymentType.find(val => val.value == form_data.money_type);
        let adjustmentQualifier = this.props.adjustmentQualifier.find(val => val.value == form_data.adjustment_qualifier);
        let adj_reason = this.props.adjustmentReason.find(val => val.value == form_data.adjustment_reason);

        form_data['deposit_date'] = this.get_date(form_data.deposit_date, 'MM/DD/YYYY');
        form_data['payment'] = currency_formatter(form_data.payment_amount || '');
        form_data['adjustment'] = currency_formatter(form_data.adjustment_amount || '');
        form_data['from_ins_code'] = incCode ? incCode.text : '';
        form_data['payment_code'] = payment_code ? payment_code.text : '';
        form_data['adjustment_code'] = adj_code ? adj_code.text : '';
        form_data['transfer_detail_amount'] = currency_formatter(form_data.transfer_detail_amount || '')
        form_data['insurance_code'] = tranfer_insurance_code ? tranfer_insurance_code.text : '';
        form_data['payment_type'] = paymentType ? paymentType.text : '';
        form_data['adj_qualifier'] = adjustmentQualifier ? adjustmentQualifier.text : 'NA';
        form_data['adj_reason'] = adj_reason ? adj_reason.text : '';
        form_data['check_cred_num'] = get_check_credit_num(data)

        return form_data;
    }

    render() {
        const { add_physician_modal, save_confirmation_unit_box, referring_physician_quick_search_format } = this.state;

        return (
            <React.Fragment>
                <ErrorCorrectionPrintableData data={this.printable_charge_data(this.props.data)} payment_data={this.printable_payment_data(this.props.data.payments)}></ErrorCorrectionPrintableData>
                {save_confirmation_unit_box ?
                    <ConfirmationComponent message={charge_messages.unit_exceeded}
                        save_button_text={"OK"}
                        title='Confirm' show_modal={save_confirmation_unit_box} onCloseModal={this.on_close_unit_confirmation} on_force_save_click={(e) => { this.on_save_unit_confirmation() }} />
                    : ''}
                <AddReferringPhysician add_physician={add_physician_modal} md_code={referring_physician_quick_search_format.md_code} get_formatted_physician={this.get_formatted_physician_info} medical_group_unfiltered={this.props.medical_group_unfiltered} primary_spaciality_unfiltered={this.props.primary_spaciality_unfiltered} specility={this.props.specility} medical_group={this.props.medical_group} />
                {this.render_form()}
                {this.state.show_bummepd_charge_message_popup ?
                    <ConfirmationComponent message={global_constants.charge_exception.message}
                        title='Confirm' show_modal={this.state.show_bummepd_charge_message_popup}
                        onCloseModal={this.on_close_bummepd_charge_popup}
                        save_button_text='Refresh'
                        on_force_save_click={(e) => { this.refresh_bummepd_charge_modal() }} />
                    : ''}
            </React.Fragment>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};
// Get user and login details from store.
const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting: get_lauch_darkley_key_value(state.launch_darkly, "rsiB35540ChargeAmountFieldWillBeEditableBasedOnRevenueCenterSetting"),
        rsiB35911ErrorCorrectionsPreventUserFromAddingNcModifierToChargeWithActivePayments: get_lauch_darkley_key_value(state.launch_darkly, "rsiB35911ErrorCorrectionsPreventUserFromAddingNcModifierToChargeWithActivePayments"),
        IsErrorCorrectionsEnhancementsEnabled: get_lauch_darkley_key_value(state.launch_darkly, ErrorCorrectionsEnhancementsProject.projectFlag)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorCorrectionEditFormComponent);