export default class FeatureFlipUtils {
    /*
     params: columns - All grid cols,
             ffcolumns - Array of cols to show\hide
             flagVal - LD bool

    */
    static apply_column_ff(columns, ffcolumns, flagVal) {

        if (!columns || !ffcolumns || columns.length == 0 || ffcolumns.length == 0) return columns;

            let updated_column_def = columns.map((column) => {
                ffcolumns.filter((ffcolumn) => {
                    if (ffcolumn == column.field) column.hide = !flagVal;
                });
                return column;
            });

            return updated_column_def;
    }

     /*
     params: columns - All grid cols,
             ffcolumns - Array of cols to show\hide
             flagVal - LD bool
     This method will filter out the columns that are FF.
     Usage intented for table width calculation.
    */
    static get_display_column(columns, ffcolumns, flagVal) {

        if (!columns || !ffcolumns || columns.length == 0 || ffcolumns.length == 0) return columns;

        var updated_column_def = columns.filter((column) => {
            var ff_column = ffcolumns.filter((ff_column_field) => {
                if (ff_column_field == column.field && flagVal == false) return true;
            });
            if (ff_column.length == 0) return column;
        });

        return updated_column_def;
    }
}

    

  



