import * as moment from 'moment';
import { isMoment } from 'moment';
import * as React from 'react';
import { toastr } from 'react-redux-toastr';
import { Button, Form, Grid } from 'semantic-ui-react';
import { date_format } from '../../global_constants';
import * as session_storage from '../../shared/session_storage_utility';
import { custom_date_format } from '../../shared/utility';
import * as report_constants from '../report_constants';
import DateRangePickerComponent from '../../shared/component/date_range_component';
import { applyFocusOnDateRangePicker } from '../../shared/tab_navigation_utility';

export default class ReportSearchCriteriaComponent extends React.Component<any, any> {
    _is_mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            search_criteria: {
                start_date: moment("01/01/1975"),
                end_date: moment(custom_date_format(new Date().toString(), date_format["mm/dd/yyyy"])),
                is_active: false,
                patient_id: session_storage.get('active_patient'),
                charge_id: 0
            },
            is_error: false
        }
    }
    is_submitted = false;
    // Will handle date selection from calendar and update state  
    componentWillUnmount = () => {       
        this._is_mounted = false;
    }

    componentDidMount() {
        this._is_mounted = true;

        // setting the focus on first elemnt
       // let ele: any = document.querySelector('.react-datepicker__input-container input');
       // ele.focus();
        // setting the focus on Date Range Picker
        applyFocusOnDateRangePicker();
    }
    //Function calls on Run Report, it checks validation and calls parent function.
    run_report_handler = () => {
        this.is_submitted = true;

        if (this.state.search_criteria.start_date && this.state.search_criteria.end_date) {
            if (this.state.search_criteria.start_date._d <= this.state.search_criteria.end_date._d) {
                this.props.on_run_report(this.state.search_criteria);
            }
            else {
                toastr.error('', report_constants.messages.date_validation);
            }
        }
        else {
            if(!this.state.search_criteria.start_date || !this.state.search_criteria.end_end) {
                this.setState({ is_error: true })
            }
            toastr.error('', report_constants.messages.mandatory_fields);
        }
    }

    //Function calls on Clear button, it clears search criteria and calls parent function
    clear_handler = () => {
        if (this._is_mounted) {
            this.setState({
                search_criteria: { ...this.state.search_criteria, start_date: null, end_date: null },
                is_error:false,
            })
        }
        this.props.on_clear();
        this.is_submitted = false;
    }

    handleDateChange = (startDate, endDate) => {
        this.setState(prevState => ({
            search_criteria: { ...prevState.search_criteria, start_date: startDate, end_date: endDate },
            is_error: (isMoment(startDate) ? false : true) || (isMoment(endDate) ? false : true)
        }));
    }

    render() {
        return (
            <React.Fragment>
                <Grid>
                    <Grid.Column tablet={8} computer={4}>
                        <Form.Field>
                            <label>Date Range {<span className={this.state.is_error ? "req-alert" : "req-alert_normal"}> (required)</span>}
                            </label>
                            <DateRangePickerComponent
                                updateDatesChange={this.handleDateChange}
                                startDate={this.state.search_criteria.start_date}
                                endDate={this.state.search_criteria.end_date}
                                error={this.state.is_error}
                                startYear={1975}
                            />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column tablet={8} computer={5} className="ledgerfull-show-inactive-checkbox">
                        <label> </label>
                        <div className="ui checkbox"><input type="checkbox" onClick={() => this.setState({ search_criteria: { ...this.state.search_criteria, is_active: !this.state.search_criteria.is_active } })}
                        /><label>Show Inactive</label></div>
                    </Grid.Column>
                    <Grid.Column tablet={16} computer={16} textAlign='right'>
                        <Button type="reset" id="bg_fff1" onClick={this.clear_handler} basic >Clear</Button>
                        <Button onKeyDown={this.props.handle_navigation_after_last_element} type='submit' onClick={this.run_report_handler} disabled={this.props.is_search_button_disabled} id="" primary>Search</Button>
                    </Grid.Column>
                </Grid>
            </React.Fragment >
        )
    }
}