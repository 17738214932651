import { get_header_template_with_navigation } from '../../shared/shared_constants';
import { headerTemplate } from '../../reports/report_constants';
import { text_comparator, mm_dd_yyyy_h_mm_ss_tt_comparator, number_comparator } from '../../shared/component/grid/comparators';

export const batch_tasks_header_id = "batch_tasks_header_id";
export const batch_tasks_status_on_success_display = {
    1: "resumed",
    2: "suspended",
    3: "canceled",
}

export const batch_tasks_configuration = (r4_to_r6_routes, props: any) => {
    return {
        title: "Batch Tasks",
        report_grid_id: "batch_task_grid",
        program_execution_id: "program_execution_grid",
        batch_task_details_grid_id: "batch_task_details_grid_id",
        selection_error: 'Please select a record.',
        batch_tasks_column_defs: [
            {
                headerName: "",
                headerComponentParams: get_header_template_with_navigation(batch_tasks_header_id),
                field: "checkbox",
                headerCheckboxSelection: true,
                checkboxSelection: true,
                suppressFilter: true,
                suppressSorting: true,
                suppressSizeToFit: true,
                resizable: false,
                suppressMovable: true,
                minWidth: 40,
                maxWidth: 40,
                width: 40,
            },
            {
                headerName: "View",
                headerComponentParams: headerTemplate,
                field: "batch_task_id",
                type: "string",
                suppressFilter: true,
                suppressSorting: true,
                suppressSizeToFit: true,
                resizable: false,
                minWidth: 75,
                maxWidth: 75,
                width: 75,
                cellRenderer: function (params) {
                    var eDiv = document.createElement('div');
                    eDiv.innerHTML = "<span class='href_link'>View</span>";
                    let eSpan = eDiv.querySelector("span");
                    eSpan.addEventListener("click", (event: any) => {
                        props.history.push("/admin/batch_tasks/" + params.value);
                    });
                    return eDiv;
                }
            },
            {
                headerName: "Code",
                headerComponentParams: headerTemplate,
                field: "task_code",
                type: "string",
                filter: "textFilter",
                unSortIcon: true,
                comparator: text_comparator
            },
            {
                headerName: "Name",
                headerComponentParams: headerTemplate,
                field: "name",
                type: "string",
                filter: "textFilter",
                unSortIcon: true,
                comparator: text_comparator
            },
            {
                headerName: "Type",
                headerComponentParams: headerTemplate,
                field: "batch_type",
                type: "custom_enum",
                filter: "textFilter",
                unSortIcon: true,
                comparator: text_comparator
            },
            {
                headerName: "Status",
                headerComponentParams: headerTemplate,
                field: "status",
                type: "custom_enum",
                filter: "textFilter",
                unSortIcon: true,
                comparator: text_comparator
            },
            {
                headerName: "Last Run",
                headerComponentParams: headerTemplate,
                field: "last_run_dt",
                type: "datetime",
                unSortIcon: true,
                filter: 'dateTimeFilter',
                comparator: mm_dd_yyyy_h_mm_ss_tt_comparator,
            }
        ],
        program_execution_column_defs: [
            {
                headerName: "View",
                headerComponentParams: headerTemplate,
                field: "batch_program_id",
                type: "string",
                suppressFilter: true,
                suppressSorting: true,
                suppressSizeToFit: true,
                resizable: false,
                minWidth: 75,
                maxWidth: 75,
                width: 75,
                cellRenderer: function (params) {
                    var eDiv = document.createElement('div');
                    eDiv.innerHTML = "<span class='href_link'>View</span>";
                    let eSpan = eDiv.querySelector("span");
                    eSpan.addEventListener("click", (event: any) => {
                        props.history.push('/admin/batch_tasks/programs/' + params.value);
                    });
                    return eDiv;
                }
            },
            {
                headerName: "Type",
                headerComponentParams: headerTemplate,
                field: "program_type",
                type: "custom_enum",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator
            },
            {
                headerName: "Status",
                headerComponentParams: headerTemplate,
                field: "status",
                type: "custom_enum",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator
            },
            {
                headerName: "Start",
                headerComponentParams: headerTemplate,
                field: "start_dt",
                type: "datetime",
                unSortIcon: true,
                filter: 'dateTimeFilter',
                comparator: mm_dd_yyyy_h_mm_ss_tt_comparator,
            },
            {
                headerName: "End",
                headerComponentParams: headerTemplate,
                field: "end_dt",
                type: "datetime",
                unSortIcon: true,
                filter: 'dateTimeFilter',
                comparator: mm_dd_yyyy_h_mm_ss_tt_comparator,
            },
            {
                headerName: "Update",
                headerComponentParams: headerTemplate,
                field: "update_dt",
                type: "datetime",
                unSortIcon: true,
                filter: 'dateTimeFilter',
                comparator: mm_dd_yyyy_h_mm_ss_tt_comparator,
            },
            {
                headerName: "Machine",
                headerComponentParams: headerTemplate,
                field: "machine_name",
                type: "string",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator

            },
            {
                headerName: "Thread",
                headerComponentParams: headerTemplate,
                field: "managed_thread_id",
                type: "number",
                unSortIcon: true,
                filter: 'numberFilter',
                comparator: number_comparator
            },
            {
                headerName: "User",
                headerComponentParams: headerTemplate,
                field: "user_name",
                type: "string",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator
            },
            {
                headerName: "Path",
                headerComponentParams: headerTemplate,
                field: "program_path",
                type: "string",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator
            }
        ],
        task_runs_column_defs: [
            {
                headerName: "View",
                headerComponentParams: headerTemplate,
                field: "batch_run_id",
                suppressFilter: true,
                suppressSorting: true,
                suppressSizeToFit: true,
                resizable: false,
                minWidth: 75,
                maxWidth: 75,
                width: 75,
                cellRenderer: function (params) {
                    var eDiv = document.createElement('div');
                    eDiv.innerHTML = "<span class='href_link'>View</span>";
                    let eSpan = eDiv.querySelector("span");
                    eSpan.addEventListener("click", (event: any) => {
                        props.history.push('/admin/batch_tasks/runs/' + params.value);
                    });
                    return eDiv;
                },
                type: "hyper_link"
            },
            {
                headerName: "Status",
                headerComponentParams: headerTemplate,
                field: "status",
                type: "custom_enum",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator
            },
            {
                headerName: "Message",
                headerComponentParams: headerTemplate,
                field: "message",
                type: "string",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator
            },
            {
                headerName: "Start",
                headerComponentParams: headerTemplate,
                field: "start_dt",
                type: "datetime",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator
            },
            {
                headerName: "End",
                headerComponentParams: headerTemplate,
                field: "end_dt",
                type: "datetime",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator
            },
            {
                headerName: "Update",
                headerComponentParams: headerTemplate,
                field: "update_dt",
                type: "datetime",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator
            },
            {
                headerName: "Machine",
                headerComponentParams: headerTemplate,
                field: "machine_name",
                type: "string",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator
            },
            {
                headerName: "Thread",
                headerComponentParams: headerTemplate,
                field: "managed_thread_id",
                type: "number",
                unSortIcon: true,
                filter: 'numberFilter',
                comparator: number_comparator
            }
        ]
    };
};

export const batch_task_run_configuration = (r4_to_r6_routes, props: any) => {
    return {
        title: "Batch Run",
        batch_task_details_grid_id: "batch_run_grid",
        batch_run_logs_column_defs: [
            {
                headerName: "View",
                headerComponentParams: headerTemplate,
                field: "batch_log_id",
                type: "hyper_link",
                suppressFilter: true,
                suppressSorting: true,
                suppressSizeToFit: true,
                resizable: false,
                minWidth: 75,
                maxWidth: 75,
                width: 75,
                cellRenderer: function (params) {
                    var eDiv = document.createElement('div');
                    eDiv.innerHTML = "<span class='href_link'>View</span>";

                    let eSpan = eDiv.querySelector("span");

                    eSpan.addEventListener("click", (event: any) => {
                        props.history.push("/admin/batch_tasks/logs/" + params.value);
                    });
                    return eDiv;
                }
            },
            {
                headerName: "Type",
                headerComponentParams: headerTemplate,
                field: "log_type",
                type: "custom_enum",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator
            },
            {
                headerName: "Category",
                headerComponentParams: headerTemplate,
                field: "category",
                type: "string",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator
            },
            {
                headerName: "Priority",
                headerComponentParams: headerTemplate,
                field: "priority",
                type: "string",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator
            },
            {
                headerName: "Log Date",
                headerComponentParams: headerTemplate,
                field: "log_dt",
                type: "datetime",
                unSortIcon: true,
                filter: 'dateTimeFilter',
                comparator: mm_dd_yyyy_h_mm_ss_tt_comparator,
            },
            {
                headerName: "Message",
                headerComponentParams: headerTemplate,
                field: "message",
                type: "string",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator
            },
            {
                headerName: "Machine",
                headerComponentParams: headerTemplate,
                field: "machine_name",
                type: "string",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator
            },
            {
                headerName: "Thread",
                headerComponentParams: headerTemplate,
                field: "managed_thread_id",
                type: "number",
                unSortIcon: true,
                filter: 'numberFilter',
                comparator: number_comparator
            },
        ]
    };
};

export const programs_configuration = (r4_to_r6_routes, props: any) => {
    return {
        title: "Batch Program",
        program_run_grid: "program_run_grid",
        program_log_grid: "program_log_grid",
        program_runs_column_defs: [
            {
                headerName: "View",
                headerComponentParams: headerTemplate,
                field: "batch_run_id",
                suppressFilter: true,
                suppressSorting: true,
                suppressSizeToFit: true,
                resizable: false,
                minWidth: 75,
                maxWidth: 75,
                width: 75,
                cellRenderer: function (params) {
                    var eDiv = document.createElement('div');
                    eDiv.innerHTML = "<span class='href_link'>View</span>";
                    let eSpan = eDiv.querySelector("span");
                    eSpan.addEventListener("click", (event: any) => {
                        props.history.push('/admin/batch_tasks/runs/' + params.value);
                    });
                    return eDiv;
                },
                type: "hyper_link"
            },
            {
                headerName: "Task Name",
                headerComponentParams: headerTemplate,
                field: "task_name",
                type: "string",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator
            },
            {
                headerName: "Status",
                headerComponentParams: headerTemplate,
                field: "status",
                type: "custom_enum",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator
            },
            {
                headerName: "Message",
                headerComponentParams: headerTemplate,
                field: "message",
                type: "string",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator
            },
            {
                headerName: "Start",
                headerComponentParams: headerTemplate,
                field: "start_dt",
                type: "datetime",
                unSortIcon: true,
                filter: 'dateTimeFilter',
                comparator: mm_dd_yyyy_h_mm_ss_tt_comparator,
            },
            {
                headerName: "End",
                headerComponentParams: headerTemplate,
                field: "end_dt",
                type: "datetime",
                unSortIcon: true,
                filter: 'dateTimeFilter',
                comparator: mm_dd_yyyy_h_mm_ss_tt_comparator,
            },
            {
                headerName: "Update",
                headerComponentParams: headerTemplate,
                field: "update_dt",
                type: "datetime",
                unSortIcon: true,
                filter: 'dateTimeFilter',
                comparator: mm_dd_yyyy_h_mm_ss_tt_comparator,
            },
            {
                headerName: "Machine",
                headerComponentParams: headerTemplate,
                field: "machine_name",
                type: "string",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator
            },
            {
                headerName: "Thread",
                headerComponentParams: headerTemplate,
                field: "managed_thread_id",
                type: "number",
                unSortIcon: true,
                filter: 'numberFilter',
                comparator: number_comparator
            },
        ],
        program_logs_column_defs: [
            {
                headerName: "View",
                headerComponentParams: headerTemplate,
                field: "batch_log_id",
                suppressFilter: true,
                suppressSorting: true,
                suppressSizeToFit: true,
                resizable: false,
                minWidth: 75,
                maxWidth: 75,
                width: 75,
                cellRenderer: function (params) {
                    var eDiv = document.createElement('div');
                    eDiv.innerHTML = "<span class='href_link'>View</span>";

                    let eSpan = eDiv.querySelector("span");

                    eSpan.addEventListener("click", (event: any) => {
                        props.history.push("/admin/batch_tasks/logs/" + params.value);
                    });
                    return eDiv;
                },
                type: "hyper_link"
            },
            {
                headerName: "Type",
                headerComponentParams: headerTemplate,
                field: "log_type",
                type: "custom_enum",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator
            },
            {
                headerName: "Category",
                headerComponentParams: headerTemplate,
                field: "category",
                type: "string",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator
            },
            {
                headerName: "Priority",
                headerComponentParams: headerTemplate,
                field: "priority",
                type: "string",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator
            },
            {
                headerName: "Log Date",
                headerComponentParams: headerTemplate,
                field: "log_dt",
                type: "datetime",
                unSortIcon: true,
                filter: 'dateTimeFilter',
                comparator: mm_dd_yyyy_h_mm_ss_tt_comparator,
            },
            {
                headerName: "Message",
                headerComponentParams: headerTemplate,
                field: "message",
                type: "string",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator
            },
            {
                headerName: "Machine",
                headerComponentParams: headerTemplate,
                field: "machine_name",
                type: "string",
                unSortIcon: true,
                filter: "textFilter",
                comparator: text_comparator
            },
            {
                headerName: "Thread",
                headerComponentParams: headerTemplate,
                field: "managed_thread_id",
                type: "number",
                unSortIcon: true,
                filter: 'numberFilter',
                comparator: number_comparator
            }
        ]
    };
};

export const batch_task_card_columns = [
    { name: 'Task', field: 'name', type: 'string' },
    { name: 'Code', field: 'task_code', type: "string" },
    { name: 'Class', field: 'class_name', type: "string" },
    { name: 'Status', field: 'status', type: "string" },
    { name: 'Last Run Date', field: 'last_run_dt', type: "datetime" },
];

export const batch_program_card_columns = [
    { name: 'Start', field: 'start_dt', type: 'datetime' },
    { name: 'End', field: 'end_dt', type: 'datetime' },
    { name: 'Update', field: 'end_dt', type: 'datetime' },
    { name: 'Status', field: 'status', type: "string" },
    { name: 'Type', field: 'program_type', type: "string" },
    { name: 'Machine', field: 'machine_name', type: "string" },
    { name: 'Thread', field: 'managed_thread_id', type: "string" },
    { name: 'User', field: 'user_name', type: "string" },
    { name: 'Path', field: 'program_path', type: "string", computer: 'sixteen', tablet: 'sixteen' },
];

export const batch_run_card_columns = [
    { name: 'Task', field: 'batch_task_name', type: 'string' },
    { name: 'Program', field: 'batch_program_name', type: 'string' },
    { name: 'Start', field: 'start_dt', type: 'datetime' },
    { name: 'End', field: 'end_dt', type: 'datetime' },
    { name: 'Update', field: 'update_dt', type: 'datetime' },
    { name: 'Status', field: 'status', type: 'string' },
    { name: 'Machine', field: 'machine_name', type: 'string' },
    { name: 'Thread', field: 'managed_thread_id', type: 'string' },
];

export const batch_log_card_columns = [
    { name: 'Run', field: 'batch_run_name', type: 'string' },
    { name: 'Program', field: 'batch_program_name', type: 'string' },
    { name: 'Date', field: 'log_dt', type: 'datetime' },
    { name: 'Type', field: 'log_type', type: 'string' },
    { name: 'Category', field: 'category', type: 'string' },
    { name: 'Priority', field: 'priority', type: 'string' },
    { name: 'Machine', field: 'machine_name', type: 'string' },
    { name: 'Thread', field: 'managed_thread_id', type: 'string' },
    { name: 'Message', field: 'message', type: 'string', computer: 'sixteen', tablet: 'sixteen' },
];
