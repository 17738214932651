/**
 * creating a generic navigation method to handle tab, enter, up and down.
 * incomplete
 * @param event
 */
//export const general_navigation_new = (event) => {

//    /**
//     * List of focusable elements.
//     * */
//    //i.angle.up.horizontal.icon, i.angle.double.left.icon, i.angle.double.right.icon
//    //    let focusable: NodeListOf<HTMLElement> = document.querySelectorAll("input:not(.should_navigate), div.dropdown:not(.should_navigate), button:not(.should_navigate), textarea:not(.should_navigate), select:not(.should_navigate), i.angle.down.horizontal.icon") as NodeListOf<HTMLElement>;
//    let focusable: NodeListOf<HTMLElement> = document.querySelectorAll("input, div.dropdown, button, textarea, select, i.angle.down.horizontal.icon") as NodeListOf<HTMLElement>;

//    /**
//     * Fetching the keyCode of the event
//     * */
//    let keyCode = event.keyCode
//    let whichEvent = event.which

//    /**
//     * Fetching the target
//     * */
//    let target = event.currentTarget

//    /** 
//    *  should work for tab - 9, keycode 13 - enter, 38 - up, down - 40.
//    */
//    if (keyCode == 13 || keyCode == 9 || keyCode == 38 || keyCode == 40) {

//        /**
//         * Get the index of the currently focused element.
//         * */
//        var currentIndex = Array.prototype.indexOf.call(focusable, event.target)


//        /**
//         * Find the next items in the list.
//         * */
//        var nextIndex = currentIndex == focusable.length - 1 ? 0 : currentIndex + 1;

//        /**
//         * Working for tab
//         * */
//        if (keyCode === 9 || whichEvent === 9) {

//            // Focus the next element
//            if (nextIndex >= 0 && nextIndex < focusable.length) {

//                //if (focusable[currentIndex].className == "react-datepicker-ignore-onclickoutside") {

//                //}
//                //else {
//                event.preventDefault();
//                focusable[nextIndex].focus();
//                //}

//            }
//        }

//    }
//}

//export const general_navigation = (event) => {

//    /** 
//     *  My thought process here is to get only those whose style dont have display: none
//     *     let container = document.querySelector('.div:not([style*="display: none"])');
//        let focusable: NodeListOf<HTMLElement> = container.querySelectorAll("input, div.dropdown,button,textarea") as NodeListOf<HTMLElement>;
//     * */

//    let focusable: NodeListOf<HTMLElement> = document.getElementById("root").querySelectorAll('div.dropdown:not(.should_navigate), div:not(.should_navigate) > div > input, button:not(.should_navigate), textarea:not(.should_navigate)') as NodeListOf<HTMLElement>;
//    // document.body.querySelectorAll('div.dropdown:not(.should_navigate), div:not(.should_navigate) > div > input, button:not(.should_navigate), textarea:not(.should_navigate)')
//    // Find all form elements that can receive focus
//    //let focusable: NodeListOf<HTMLElement> = document.querySelectorAll('.div:not([style*="display:none"])').querySelectorAll('input, div.dropdown,button,textarea') as NodeListOf<HTMLElement>;
//    let keyCode = event.keyCode
//    let whichEvent = event.which
//    let target = event.target

//    if (keyCode == 13 || keyCode == 9 || keyCode == 38 || keyCode == 40) {


//        // Get the index of the currently focused element
//        var currentIndex = Array.prototype.indexOf.call(focusable, event.target)

//        // Find the next items in the list
//        var nextIndex = currentIndex == focusable.length - 2 ? 0 : currentIndex + 1;


//        if (keyCode === 9 || whichEvent === 9) {
//            // Focus the next element
//            if (nextIndex >= 0 && nextIndex < focusable.length) {
//                if (focusable[currentIndex].className == "react-datepicker-ignore-onclickoutside") {
//                }
//                else {
//                    event.preventDefault();
//                    focusable[nextIndex].focus();
//                }

//            }
//        }

//        if (keyCode === 13 || whichEvent === 13) {
//            // Focus the next element
//            if (nextIndex >= 0 && nextIndex < focusable.length) {
//                //handled for checkbox
//                if (focusable[currentIndex].getAttribute("type") == "checkbox") {
//                    target.click()
//                }

//                //handled for textbox - working fine

//                //handled for calender[focusable[currentIndex].className == "react-datepicker-ignore-onclickoutside"] - have to handle

//                //handled for button & textarea
//                if (focusable[currentIndex].getAttribute("type") != "button" &&
//                    focusable[currentIndex].tagName != "TEXTAREA") {
//                    focusable[nextIndex].focus();
//                }
//            }


//            if (target.getAttribute("role") == "listbox") {
//                //TODO: apply check if browser is not IE
//                if (target.getAttribute("aria-expanded") == "false") {
//                    target.click()
//                }
//            }
//        }

//        // up 38, down 40
//        if (keyCode == 38 || keyCode == 40) {
//            if (target.getAttribute("role") == "listbox") {
//                // if closed then open
//                if (target.getAttribute("aria-expanded") == "false") {
//                    target.click()
//                }
//            }
//        }
//    }
//}

/**
 * Apply Focus on tab to app header dropdown.
 * @param event
 */
export const set_focus_to_app_header = (event) => {

    handle_focus_on_tab(event, "app_header_dropdown");
}

/**
 * Apply click functionality on Enter.
 * @param event
 */
export const handle_click_on_enter = (event) => {

    if (event.keyCode == '13') {
        event.preventDefault();
        event.target.click();
    }
}

/**
 * Set focus on element with passed id on Enter
 * @param event
 * @param idToFocusOn
 */
export const handle_click_on_enter_with_focus = (event, idToFocusOn) => {

    if (event.keyCode == '13') {
        event.preventDefault();
        event.target.click();

        set_focus_on_element_with_id(idToFocusOn);
    }
}

/**
 * Set Focus on a element with passed id on Tab.
 * @param event
 * @param idToFocusOn
 */
export const handle_focus_on_tab = (event, idToFocusOn) => {

    if (!event.shiftKey && event.keyCode == '9') {
        event.preventDefault();

        set_focus_on_element_with_id(idToFocusOn);
    }
}

/**
 * Set Focus on a element with passed id on Shift Tab.
 * @param event
 * @param idToFocusOn
 */
export const handle_focus_on_shift_tab = (event, idToFocusOn) => {

    if (event.shiftKey && event.keyCode == '9') {
        event.preventDefault();

        set_focus_on_element_with_id(idToFocusOn);
    }
}
/**
 * Apply focus on element having id.
 * @param idToFocusOn
 */
export const set_focus_on_element_with_id = (idToFocusOn) => {

    let focusElement = document.getElementById(idToFocusOn);
    if (focusElement) {
        focusElement.focus();
    }
}

export const set_focus_on_close_drawer = (event, idToFocusOn) => {
    if (!event.shiftKey && event.keyCode == '9') {
        event.preventDefault();
        let focusElement: any = document.getElementsByClassName(idToFocusOn);
        if (focusElement && focusElement[0]) {
        focusElement[0].focus();
        }
    }
}

/**
 * Apply navigation to a modal window, which does following tasks to
 * set id on current close icon and apply event listener shift+tab and enter on it.
 * @param close_icon_id
 * @param on_shift_tab_close_icon
 */
export const handle_navigation_on_modal = (close_icon_id, on_shift_tab_close_icon) => {
    setTimeout(() => {
        let closeIcons: NodeListOf<HTMLElement> = document.querySelectorAll('i.close.icon:not(.auto-search-icon)') as NodeListOf<HTMLElement>;
        let closeIcon = closeIcons[closeIcons.length - 1]
        if (closeIcon) {
            closeIcon.tabIndex = 0;
            closeIcon.id = close_icon_id;
            closeIcon.addEventListener("keydown", function (event) {
                if (event.shiftKey && event.keyCode == 9) {
                    event.preventDefault();
                    let search_button = document.getElementById(on_shift_tab_close_icon);
                    search_button.focus();
                }

                if (event.keyCode == 13) {
                    event.preventDefault();
                    var el = event.target as HTMLElement
                    el.click();
                }
            });
        }
    }, 500)
}


/**
 * Function Applies focus on first datepicker element of the page.
 * @param none
 */
export const apply_focus_on_first_datepicker_of_current_page = () => {

    let ele: any = document.querySelector('.react-datepicker__input-container input');
    if (ele) {
        ele.focus();
    }
}

/**
 * Function Applies focus on daterange element of the page.
 * @param none
 */
export const applyFocusOnDateRangePicker = () => {
    let ele: any = document.querySelector('.DateInput_input');
    if (ele) {
        ele.focus();
    }
}


/**
 * Function Handles focus of shift tab in app header.
 * @param none
 */
export const handle_shift_tab_of_app_header = () => {

    // handling focus of shift tab of app header in case of report framework
    // setting the focus to i disclaimer icon in that case
    let footer_icons = document.querySelectorAll('body.reports .body-wrapper i#footer_disclaimer') as NodeListOf<HTMLElement>;
    if(footer_icons.length > 0) {
        let footer_icon = footer_icons[footer_icons.length - 1];
        footer_icon.focus();
        return; // dont process below functionality
    }

    // finding the last button of the page 
    // if I got that button, send focus to that button else
    // send focus to last left menu
    let buttons: NodeListOf<HTMLElement> = document.querySelectorAll('.body-wrapper button:not(.disabled)') as NodeListOf<HTMLElement>;

    if (buttons.length > 0) {
        let button = buttons[buttons.length - 1];
        button.focus();
    } else {
        const sideBars: any = document.getElementsByClassName("ui dropdown");
        let lastSideBar = sideBars[sideBars.length - 1];
        lastSideBar.focus();
    }
}