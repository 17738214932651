import { ACTION_CONSTANT, payment_const } from '../../payment/payment_constants';
import { currentDateTime } from '../../shared/calendar_utils';

const initial_state = {
    response: {},
    data: [],
    saved_credits : []
};

export default function (state = initial_state, { type, payload }) {
    switch (type) {

        case payment_const.save_failure:
            return {
                ...state, response: payload.response,
                data: payload.data,
                is_saved: false,
                save_data_time: currentDateTime()
            };
            break;
        case payment_const.save_success:
            return {
                ...state, response: payload.response,
                data: [...state.data, payload.data],
                is_saved: true,
                action_type: payload.action_type,
                save_data_time: currentDateTime()
            };
            break;

        //Get Patient Credits
        case ACTION_CONSTANT.GET_PATIENT_CREDITS:
            return {
                ...state,
                credits_data: payload.data || [],
                credit_fatch_time: currentDateTime(),
                is_saved: false,
                is_fatched: true
            };
            break;
        case payment_const.save_credits:
            let new_data = [ ...state.saved_credits, payload ];
            return {
                ...state,
                saved_credits: new_data,
                is_saved: false
            };
            break;
        case payment_const.change_flag:
            return {
                ...state,
                is_saved: payload
            };
            break;
        default:
            return state
    }

}
