import * as React from "react";
import { connect } from "react-redux";
import { Button, Dimmer, Loader, Checkbox } from 'semantic-ui-react';
import { R6ErrorMessage, R6ResponseData } from "../../global_constants";
import {
    get_eob835_unassigned_insurance_by_inbound_id, updateEOBValidationErrors
    , e835_add_inboud_id_to_payers_config
} from "../action/payment_action";
import { toastr as toaster } from "react-redux-toastr";
import { e835_inbound_id_set_up } from "../payment_constants";
import { generic_error_message } from "../../global_constants";


interface EOB835UnassignedInsuranceByInboundId
{
    ins_code_id: number;
    ins_code: string;
    ins_name: string;
    payer_id: number;
	payer_name: string;
    payer_desc: string;

    //Transactional
    checked:boolean
}

interface Release835sInboundIDSetupProps {
    company_id: number;
    eob_key: number;
    inbound_id: string,
    payer_desc: string
    user_login_details: any, // from redux store
    close_drawer_on_apply: any, //Call back method   
    update_entry_selection: any, //Call back method 
}

interface Release835sInboundIDSetupState {
    is_loading: boolean;
    display_apply_button: boolean;
    is_apply_disabled: boolean;
    title_text: string;
    unassigned_insurances: Array<EOB835UnassignedInsuranceByInboundId>;
    errors: Array<R6ErrorMessage>;
}

class Release835sInboundIDSetupComponent extends React.Component<Release835sInboundIDSetupProps, Release835sInboundIDSetupState>
{
    // #region Class Variables
    _token: string = null;
    _is_mounted: boolean = false;
    // #endregion Class Variables


    // #region Constructor
    constructor(props)
    {
        super(props);
        this.state =
        {
            is_loading: false,
            display_apply_button: true,
            is_apply_disabled: false,   
            title_text: e835_inbound_id_set_up.drawer_title_inbound_id_not_found, 
            unassigned_insurances: null,
            errors: null
        }
    }
    // #endregion Constructor


    // #region Life Cycle Methods
    componentDidMount = async () =>
    {
        this._is_mounted = true;
        this._token = this.props.user_login_details.user_data.data.token_details.access_token;
        
        await this.get_eob835_unassigned_insurance_by_inbound_id();
    }
    // #endregion Life Cycle Methods


    // #region Get Data Methods
    get_eob835_unassigned_insurance_by_inbound_id = async () =>
    {
        if (!this._is_mounted) return;

        this.setState({ is_loading: true })
        
        await updateEOBValidationErrors(this.props.eob_key, this._token).then(

            (response) =>
            {
                get_eob835_unassigned_insurance_by_inbound_id(this.props.eob_key, this.props.company_id, this._token).then(
                    (response:R6ResponseData) =>
                    {
                        //special case invalid patients. need to understand the error_code
                        if (response.data && response.data.data && response.data.data.length == 0)
                        {
                            let error : Array<R6ErrorMessage> = null;
                            let title = e835_inbound_id_set_up.drawer_title_inbound_id_attached;

                            //No Insurances Found condition
                            if (response.data.messages && response.data.messages.length == 1)
                            {
                                error = [{ code: response.data.messages[0].code, message: '' }];
                                title = e835_inbound_id_set_up.drawer_title_no_insurances_found;
                            }
                                
                            this.setState({
                                is_loading: false,
                                title_text: title,
                                display_apply_button: false,
                                unassigned_insurances: [],
                                errors: error,
                            });
                        }
                        else 
                        {
                            this.setState({
                                is_loading: false,
                                display_apply_button: true,
                                unassigned_insurances: response.data.data,
                                errors: null
                            });
                        }
                    },
                    (error) =>
                    {
                        toaster.error('', generic_error_message);
                        console.log(error)
                    }
                );
            },
            (error) =>
            {
                toaster.error('', generic_error_message);
                console.log(error)
            }
                
        );
        
    }
    // #endregion Get Data Methods


    // #region Event Action Methods
    on_click_checkbox = (payer_id :number, ins_code_id : number) =>
    {
        if (!this._is_mounted) return;

        let in_memory_data = this.state.unassigned_insurances;
        

        in_memory_data.map((row) => {
            if (row.payer_id == payer_id && row.ins_code_id == ins_code_id)
                row.checked = !row.checked;
        });

        let selected_entries = this.state.unassigned_insurances
            .filter((row) => row.checked == true)
            
        
        this.props.update_entry_selection(selected_entries && selected_entries.length > 0);
        this.setState({ unassigned_insurances: in_memory_data });
    }


    on_click_apply = () =>
    {
        if (!this._is_mounted) return;

        let selected_payers = this.state.unassigned_insurances
            .filter((row) => row.checked == true)
            .map((row) => { return row.payer_id });


        // #region Validation
        if (!selected_payers || selected_payers.length == 0)
        {
            toaster.error('', e835_inbound_id_set_up.no_insurances_selected);
            return; 
        }
        // #endregion Validation

        this.setState({ is_loading: true, is_apply_disabled: true });

        e835_add_inboud_id_to_payers_config(this.props.inbound_id, selected_payers, this.props.company_id, this._token).then(

            (response:R6ResponseData) =>
            {
                if (!(response && response.data) || (!response.data.data))
                {
                    this.setState({ is_loading: false, is_apply_disabled: false });
                    toaster.error('', e835_inbound_id_set_up.error_msg_unable_to_update_payer);
                }
                    
                else
                {
                    this.setState({ is_loading: false });
                    toaster.success('', e835_inbound_id_set_up.success_msg_on_save);
                    this.props.close_drawer_on_apply(this.props.eob_key);
                    
                }
            },

            (error) =>
            {
                this.setState({ is_loading: false, is_apply_disabled: false });
                toaster.error('', generic_error_message);
                console.log(error)
            }  
        );
    }
    // #endregion Event Action Methods


    // #region Render Content GeneratorMethods
    get_render_contents = () =>
    {
        let return_contents;
        if (this.state.unassigned_insurances.length == 0)
        {
            if (this.state.errors == null) {
                return_contents =
                    <div style={{ fontSize: "13px", lineHeight: "24px", position: "relative", width: "100%", textAlign: "justify", paddingTop: "3%" }} >
                        <div style={{ display: "block" }}>
                            {e835_inbound_id_set_up.drawer_p1_no_insurance_no_error_text}
                        </div>
                    </div>
            }
            else if (this.state.errors.length > 0 && this.state.errors[0].code == e835_inbound_id_set_up.no_insurances_with_code)
            {
                return_contents =
                    return_contents =
                    <div style={{ fontSize: "13px", lineHeight: "24px", position: "relative", width: "100%", textAlign: "justify", paddingTop: "3%" }}>
                        <div style={{ display: "block" }}>
                            {e835_inbound_id_set_up.drawer_p1_text}
                        </div>
                        <div style={{ paddingTop: "2%", display: "block" }}>
                            {e835_inbound_id_set_up.drawer_p2_with_error_text}
                            <div style={{ display: "block", fontWeight: "bold" }}>{this.props.inbound_id + ' - ' + this.props.payer_desc}</div>
                        </div>
                        <div style={{ paddingTop: "2%", display: "block" }}>
                            {e835_inbound_id_set_up.drawer_p3_with_error_text}
                        </div>
                    </div>
            }
        }
        else 
        {
            return_contents = 
                <div style={{ fontSize: "13px", lineHeight: "24px", position: "relative", width: "100%", textAlign:"justify", paddingTop:"3%" }}>
                    <div style={{display:"block"} }>
                        {e835_inbound_id_set_up.drawer_p1_text}
                    </div>
                    <div style={{ paddingTop: "2%", display: "block" }}>
                        {e835_inbound_id_set_up.drawer_p2_text}
                    </div>
                    <div style={{ paddingTop: "2%", display: "block" }}>
                        {e835_inbound_id_set_up.drawer_p3_text}
                        <div style={{display:"block", fontWeight:"bold"}}>{this.props.inbound_id + ' - ' + this.props.payer_desc}</div>
                    </div>
                    
                    <table style={{ padding: "0px", margin: "0px", border: "0px", textAlign: "left", verticalAlign: "top", width: "100%" }}>
                        <thead/>
                        <tbody>
                            {this.get_grid_rows()}
                        </tbody>
                    </table>
                    
                </div>
            
        }
        return return_contents;
    }

    get_grid_rows = () =>
    {
        return this.state.unassigned_insurances.map((row, index) => {

            return (
                <tr style={{ margin: "0px", border: "0px", padding: "0px", verticalAlign: "top", textAlign: "left" }} key={index}>
                    <td style={{ paddingTop: "5px", margin: "0px", border: "0px", verticalAlign:"top", textAlign:"left"} }>
                        <Checkbox
                            checked={row.checked ? true : false}
                            onClick={() => this.on_click_checkbox(row.payer_id, row.ins_code_id)}
                        />
                    </td>
                    <td style={{ paddingLeft: "1%", verticalAlign: "top", textAlign: "left", margin: "0px", border: "0px" }} >
                        <div style={{ textAlign: "left", display: "block" }}><span style={{ fontWeight: "bold" }}>Insurance</span>{': ' + row.ins_code + ' - ' + row.ins_name}</div>
                        <div style={{ textAlign: "left", display: "block" }}><span style={{ fontWeight: "bold" }}>Payer</span>{': ' + row.payer_name + ' - ' + row.payer_desc}</div>
                    </td>
                </tr>
            );

        });

    }
    // #endregion Render Content GeneratorMethods


    render() {
        return (
            <React.Fragment>
                <Dimmer active={this.state.is_loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                {
                    this.state.display_apply_button &&   
                    <div className='bp3-dialog-save-button'>
                        <Button
                                id='inbound_id_not_found_btn'
                                className='primary'
                                type='button'
                                content='Apply'
                                disabled={this.state.is_apply_disabled}
                                onClick={ this.on_click_apply}
                        />
                    </div>
                }
                <div>

                    <div style={{ fontSize: '26px', lineHeight: '32px', position: "relative", width: '100%' }}>
                        {this.state.title_text}
                    </div>
                    
                    {
                        this.state.unassigned_insurances != null && this.get_render_contents()
                    }
                </div>
                
            </React.Fragment>
        );
    }

}

//Get user and login details from store.
const mapStateToProps = (state, component_props) => {
    return {
        user_login_details: state.user_login_details,
        ...component_props
    };
};

export default connect(mapStateToProps)(Release835sInboundIDSetupComponent);


