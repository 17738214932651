import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Button, Dimmer, Form, Grid, Input, Loader } from 'semantic-ui-react';
import * as export_print_utility from '../../../reports/util/export_print_utility';
// import { cal_data_width } from '../../../reports/util/report_data_formatter';
import { advanced_search_class } from '../../../shared/action/shared_action';
import GridView from '../../../shared/component/grid';
import { set_focus_on_element_with_id, set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import { phone_format, set_column_data_width, format_zip_code, get_columns, get_lauch_darkley_key_value, is_user_partner_customer_and_company_revEquip_revServe } from '../../../shared/utility';
import AdvanceQuickSearch from "../../constants/component/claim_adjustment_code_advanced_search";
import * as constants_actions from '../action/constants_action';
import * as constants from '../constants';
import FeatureFlipUtils from '../../../shared/feature_flip_helper';
import CheckboxComponent from './../../../shared/component/checkbox_component';
import * as session_storage from '../../../shared/session_storage_utility';
import * as global_constants from '../../../global_constants';
import * as local_storage from '../../../shared/local_storage_utility';
import * as ReportConstants from '../../../reports/report_constants';

class InsurancesSearchComponent extends React.Component<any, any> {
    [x: string]: any;
    constructor(props) {
        super(props);
        // Define initial state value.
        this.state = {
            controls: null,
            show_grid: false,
            show_render: true,
            search_criteria: [],
            is_loading: false,
            shown: false,
            insurance_class_search_data: null,
            payer_search_data: null,
            insurance_class_selected_row: null,
            payerid_selected_row: null,
            grid_rows_count: 0
        };
        this.insurance_class_search = React.createRef();
        this.payer_search = React.createRef();
    }
    // Define initial properties and its value.
    _is_mounted = false;
    insurance_class_enum = "insurance_class";
    payer_enum = "payer";
    page_name: string = 'insurance';
    page_metadata: any;
    insurance_search_criteria: any = {
        Insurance_code: '',
        Insurance_name: '',
        Address: '',
        Insurance_class_id: '',
        Payer_id: '',
        Show_inactive: false
    };
    initial_search_criteria: any = {
        Insurance_code: '',
        Insurance_name: '',
        Address: '',
        Insurance_class_id: '',
        Payer_id: '',
        Show_inactive: false
    };
    token: string = '';
    row_data = [];
    is_search_ui: any = false;
    code_id: number = 0;
    is_grid_visible = false;
    title = '';
    current_date: any;
    company_name: any;
    total_column_width: number = 0;
    grid_header_height: number = 0;
    insurance_class_selected_row: null;
    payerid_selected_row: null;
    current_user_type: global_constants.enum_user_type_r3_id = 1;
    company_service_type = 0;
    reportId: string = "0";

    // Fetching the page_name and state.
    UNSAFE_componentWillMount = () => {
        this.get_page_metadata();
        var auth_data = local_storage.get("auth_data");
        if (auth_data) {
            this.current_user_type = auth_data.user_type_r3_id;
            this.company_service_type = auth_data.company_service_type;
        }
    };

    componentDidMount = () => {
        this._is_mounted = true
        document.body.classList.add('admin-framework');
        let elem = document.getElementById('search-criteria-container');
        let all_form_elem = elem.getElementsByTagName('input');
        all_form_elem[0].focus();
        let controls = {
            insurance_class: {
                type: "insurance_class",
                grid_config: {
                    rows: null,
                    column: constants.insurance.insurance_class_column_defs
                },
                control_id: 'INSURANCE_CLASS',
                selected_row: null,
                error_message: 'No Record Found !',
                label: ["insurance_class_code", "insurance_class_description"]
            },
            payer: {
                type: "payer",
                grid_config: {
                    rows: null,
                    column: constants.insurance.payerid_column_defs
                },
                control_id: 'PAYER_DESCRIPTION',
                error_message: 'No Record Found !',
                label: ["name", "description"],
                selected_row: null,
            },
        }
        this.setState({
            controls
        })
        this.handle_search_button_tab();
    };

    componentWillUnmount = () => {
        document.body.classList.remove('admin-framework');
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[2] !== 'insurance' && session_storage.remove('insurance_search_criteria');
        }
    };

    //Use to fetch params and page metadata
    get_page_metadata = () => {
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.page_metadata = constants.insurance;
        this.page_metadata.column_def = FeatureFlipUtils.apply_column_ff(this.page_metadata.column_def,
            constants.insurance.column_do_not_accept_assignment_def_ff_columns, this.props.argos_bill_1177_new_insurance_option_do_not_accept_assignment);
        let insurance_search_criteria = session_storage.get('insurance_search_criteria')
        this.insurance_search_criteria = (insurance_search_criteria && insurance_search_criteria.search_criteria) || this.insurance_search_criteria;
        this.insurance_class_selected_row = (insurance_search_criteria && insurance_search_criteria.insurance_class_selected_row) || this.insurance_class_selected_row
        this.payerid_selected_row = (insurance_search_criteria && insurance_search_criteria.payerid_selected_row) || this.payerid_selected_row
        if (insurance_search_criteria) {
            this.search_handler(this);
        }
        this.setState({
            show_render: true
        });
    };

    // Data formatter
    format_data = (rows, columns) => {
        let formatted_data = rows.map(row_data => {
            row_data.city = row_data.address.city ? row_data.address.city : ''
            row_data.zip_code = row_data.address.zip ? row_data.address.zip : ''
            row_data.province = row_data.address.province ? row_data.address.province : ''
            let addresses = '';
            addresses = addresses + (row_data.address.address1 ? row_data.address.address1 : '')
            addresses = addresses + (row_data.address.address2 ? (addresses ? ', ' : '') + row_data.address.address2 : '')
            row_data.addresses = addresses
            row_data.authorization_required = row_data.authorization_required || false
            row_data.injury_date_required_flag = row_data.injury_date_required_flag || false
            row_data.apply_specialty_modifiers = row_data.apply_specialty_modifiers || false           
            columns.filter(col_data => {
                switch (col_data.type) {
                    case 'currency':
                        row_data[col_data.field] =
                            row_data[col_data.field] != null || row_data[col_data.field] != undefined ? constants.currencyFormatter(row_data[col_data.field]) : constants.currencyFormatter(0);
                        break;
                    case 'boolean':
                        row_data[col_data.field] = row_data[col_data.field] ? 'Active' : 'Inactive';
                        break;
                    case 'number':
                        row_data[col_data.field] = row_data[col_data.field];
                        break;
                    case 'string':
                        row_data[col_data.field] = row_data[col_data.field];
                        break;
                    case 'phone':
                        row_data[col_data.field] = row_data[col_data.field] ? phone_format(row_data[col_data.field]) : row_data[col_data.field];
                        break;
                    case 'zip':
                        row_data[col_data.field] = row_data[col_data.field] ? format_zip_code(row_data[col_data.field]) : row_data[col_data.field];
                        break;
                    default:
                        row_data[col_data.field] = row_data[col_data.field];
                }
            });
            return row_data;
        });
        return formatted_data;
    };

    //On double click of grid, it redirects user to view page.
    on_row_double_clicked = selected_row_data => {
        this.code_id = selected_row_data[this.page_name + '_id'];
        this.props.history.push(`${this.page_metadata.base_url}/${this.page_name}/${this.code_id}`, { by_pass_prompt: false });
    };

    //Input change handler.
    input_change_handler = e => {
        const { value, checked, type, name } = e.target;
        if (type == 'checkbox') {
            this.insurance_search_criteria[name] = checked;
        } else {
            this.insurance_search_criteria[name] = value;
        }
        this.setState({
            search_criteria: this.insurance_search_criteria
        });
    };

    //Search button handler and used to search the data on the basis of search criteria.
    search_handler = e => {
        var url = this.page_metadata.api.search.url;
        for (let key in this.insurance_search_criteria) {
            url = url.replace(
                key + '_value',
                typeof this.insurance_search_criteria[key] !== 'undefined' ? encodeURIComponent(this.insurance_search_criteria[key].toString().trim()) : ''
            );
        }
        this.setState({
            is_loading: true
        });
        constants_actions.get_search_data(this.token, url).then(
            response => {
                if (response.data) {
                    this.row_data = response.data.data ? response.data.data : [];
                    this.row_data = this.format_data(this.row_data, this.page_metadata.column_def);
                    const grid_height = get_columns(this.row_data, this.page_metadata.column_def);
                    this.total_column_width = grid_height.total_column_width;
                    this.grid_header_height = grid_height.header_height;
                    this.is_search_ui = true;
                    this.setState({
                        show_grid: true,
                        is_loading: false,
                        search_criteria: this.insurance_search_criteria,
                        payerid_selected_row: this.payerid_selected_row,
                        insurance_class_selected_row: this.insurance_class_selected_row,
                        grid_rows_count: this.row_data && this.row_data.length
                    });
                    session_storage.set('insurance_search_criteria', {
                        search_criteria: this.insurance_search_criteria,
                        payerid_selected_row: this.payerid_selected_row,
                        insurance_class_selected_row: this.insurance_class_selected_row
                    });
                }
            },
            error => {
                this.setState({
                    is_loading: false
                });
                // TODO : do something here
                //                if (error.response.data) console.log(error.response.data.messages[0].message);
            }
        );
    };

    //Reset button the search control.
    clear_handler = e => {
        this.insurance_search_criteria = { ...this.initial_search_criteria };
        this.setState({
            show_grid: false,
            shown: false,
            search_criteria: this.initial_search_criteria,
            grid_rows_count: 0
        });
        this.is_search_ui = false;
        this.is_grid_visible = false;
        this.insurance_class_search.clear_quick_search(this.insurance_class_search.props.control.type)
        this.payer_search.clear_quick_search(this.payer_search.props.control.type)
        session_storage.remove('insurance_search_criteria');
        // this.props.history.push(`${this.page_metadata.base_url}/${this.page_name}/search`, {});
    };

    //New button handler.
    new_handler = e => {
        this.props.history.push(`${this.page_metadata.base_url}/${this.page_name}`, { by_pass_prompt: false });
    };

    //Function calls on initialization of Ag-Grid and catch its reference.
    get_grid_ref = grid_params => {
        this.setState({
            grid_params,
            grid_rows_count: grid_params.api.getModel().getRowCount()
        });
    };

    //Used to create the title for export and print.
    set_title = separter => {
        this.company_name = this.props.user_login_details.user_data.data.company_name;
        this.current_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
        this.title = this.page_metadata.search_header;
        return this.title =
            this.title.replace(/<br>/g, separter) +
            separter +
            this.company_name +
            separter +
            this.current_date +
            separter +
            (this.state.grid_rows_count == 1
                ? this.state.grid_rows_count + ' record'
                : this.state.grid_rows_count + ' records') +
            separter +
            separter;
    };

    //Function calls on initialization of export report data
    on_export_button = () => {
        this.title = this.set_title('\n');
        this.page_name = this.page_name.replace(/<br>/g, '');
        export_print_utility.export_grid_data(this.state.grid_params, this.title, this.page_name);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Export);
    };

    //Function calls on initialization of Print report data
    on_print_button = () => {
        this.title = this.set_title('<br>');
        export_print_utility.print_grid_data(this.state.grid_params, this.title, this.page_name);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
    };

    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;

        const payload: ReportConstants.IPayloadForBillingAudit = {
            reportId: this.reportId,
            contextTitle: `${this.page_metadata.search_header} - ${ReportConstants.ReportEventAction[eventActionId]}`,
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: ReportConstants.ReportEntityTypeId.C_INSURANCE_CODE,
            data: { Records: this.state.grid_params.api.rowModel.rowsToDisplay.length }
        };

        const reqBody = export_print_utility.generateAuditDataEntryPayload(payload);
        ReportConstants.saveAuditPrintExport(reqBody, accessToken);
    };
    on_grid_out = () => {
        set_focus_on_element_with_id('app_header_dropdown');
    };

    handle_search_button_tab = () => {
        setTimeout(() => {
            let searchButton = document.getElementById('search_button_id');
            let self = this;
            searchButton.addEventListener('keydown', function (event) {
                // apply a check where grid is empty
                if (!event.shiftKey && (self.row_data == null || (self.row_data.length == 0 && !self.state.show_grid))) {
                    set_focus_to_app_header(event);
                }
            });
        }, 200);
    };
    on_quick_search = async (params, type) => {
        let url = '';
        if (params.trim().length > 0) {
            if (this.insurance_class_enum == type) {
                url = constants.insurance.api.insurance_class_quick_search + "?keyword=" + params + "&page_size=" + global_constants.constants.Quick_Search_Suggestion_List_Size;
                let result = await constants_actions.get_search_data(this.token, url);
                result.data.data = result.data && result.data.data ? result.data.data.sort((a, b) => b.id - a.id) : result
                return result
            } else {
                url = constants.insurance.api.payer_quick_search + "?keyword=" + params + "&page_size=" + global_constants.constants.Quick_Search_Suggestion_List_Size;
                let result = await constants_actions.get_search_data(this.token, url);
                result.data.data = result.data && result.data.data ? result.data.data.sort((a, b) => a.name.localeCompare(b.name)) : result
                return result
            }
        } else {
            let result: any = {}
            result.data = []
            return result;
        }
    }

    on_advanced_search = async (params, type) => {

        if (this.insurance_class_enum == type) {
            params.code = params.code || ""
            params.description = params.description || ""
            advanced_search_class(params, this.token).then((res) => {
                if (this._is_mounted) {
                    this.setState({
                        insurance_class_search_data: {
                            rows: res.data.data,
                            column: constants.insurance.insurance_class_column_defs
                        }
                    });
                }
            }, error => {
                if (error.response && error.response.data && error.response.data.messages) {
                    toastr.error('', error.response.data.messages[0].message);
                }
            });
        } else {
            params.Description = params.Description || ""
            params.Outbound_id = params.Outbound_id || ""
            params.name = params.name || ""
            params.Inbound_id = params.Inbound_id || ""
            constants_actions.adj_payer_advanced_search(params, this.token).then((res) => {
                if (this._is_mounted) {
                    this.setState({
                        payer_search_data: {
                            rows: res.data.data,
                            column: constants.insurance.payerid_column_defs
                        }
                    })
                }
            }, error => {
                if (error.response && error.response.data && error.response.data.messages) {
                    toastr.error('', error.response.data.messages[0].message);
                }
            });
        }

    }
    update_data = (data, type) => {
        if (type == this.insurance_class_enum) {
            this.insurance_search_criteria.Insurance_class_id = data ? data.id : '';
            this.setState({
                search_criteria: this.insurance_search_criteria,
                insurance_class_selected_row: data
            });
            this.insurance_class_selected_row = data
        } else {
            this.insurance_search_criteria.Payer_id = data ? data.payer_key_id : '';
            this.setState({
                search_criteria: this.insurance_search_criteria,
                payerid_selected_row: data
            });
            this.payerid_selected_row = data
        }
    }

    update_report_rows_count = () => {
        this.setState({
            grid_rows_count: this.state.grid_params.api.getModel().getRowCount()
        });
    };
  
    //It renders report grid and its search controls.
    render() {
        return (
            this.state.show_render && (
                <React.Fragment>
                    <Dimmer active={this.state.is_loading}>
                        <Loader size='massive'>Loading</Loader>
                    </Dimmer>
                    <div className={'admin-wrapper ' + this.page_metadata.id} style={!this.state.show_grid ? { paddingBottom: 20 } : {}}>
                        <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                            <Grid.Column computer={16}>
                                <h3 className='ui header left aligned' dangerouslySetInnerHTML={{ __html: this.page_metadata.search_header }} />
                            </Grid.Column>
                        </Grid>
                        <div id='admin-scrollable-area' className='wrapper' style={this.state.show_grid ? { flex: 1 } : {}}>
                            <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container'>
                                <Form autoComplete='off' onSubmit={e => this.search_handler(e)}>
                                    <Grid>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Insurance Code</label>
                                                <Input
                                                    autoComplete='off'
                                                    name='Insurance_code'
                                                    id='Insurance_code'
                                                    onChange={e => this.input_change_handler(e)}
                                                    value={this.insurance_search_criteria['Insurance_code'] ? this.insurance_search_criteria['Insurance_code'] : ''}
                                                    type='text'
                                                    maxLength={50}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Insurance Name</label>
                                                <Input
                                                    autoComplete='off'
                                                    name='Insurance_name'
                                                    id='Insurance_name'
                                                    onChange={e => this.input_change_handler(e)}
                                                    value={this.insurance_search_criteria['Insurance_name'] ? this.insurance_search_criteria['Insurance_name'] : ''}
                                                    type='text'
                                                    maxLength={50}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Address</label>
                                                <Input
                                                    autoComplete='off'
                                                    name='Address'
                                                    id='Address'
                                                    onChange={e => this.input_change_handler(e)}
                                                    value={this.insurance_search_criteria['Address'] ? this.insurance_search_criteria['Address'] : ''}
                                                    type='text'
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={4}>
                                            <Form.Field className="advance-quick-search">
                                                <label>Insurance Class</label>
                                                <AdvanceQuickSearch
                                                    control={this.state.controls ? this.state.controls.insurance_class : null}
                                                    update_data={this.update_data}
                                                    search_result={this.state.insurance_class_search_data}
                                                    on_quick_search={this.on_quick_search}
                                                    on_advanced_search={this.on_advanced_search}
                                                    selected_row={this.state.insurance_class_selected_row}
                                                    onRef={instance => { this.insurance_class_search = instance; }}
                                                    headerIdForGridTabNavigation={constants.insurance.insurance_classe_header_id}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={4}>
                                            <Form.Field className="advance-quick-search">
                                                <label>Payer ID</label>
                                                <AdvanceQuickSearch
                                                    control={this.state.controls ? this.state.controls.payer : null}
                                                    update_data={this.update_data}
                                                    search_result={this.state.payer_search_data}
                                                    on_quick_search={this.on_quick_search}
                                                    on_advanced_search={this.on_advanced_search}
                                                    selected_row={this.state.payerid_selected_row}
                                                    onRef={instance => { this.payer_search = instance; }}
                                                    headerIdForGridTabNavigation={constants.insurance.payerid_header_id}
                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field style={{ marginTop: 32 }}>
                                                <CheckboxComponent
                                                    name='Show_inactive'
                                                    id='Show_inactive'
                                                    onChange={e => this.input_change_handler(e)}
                                                    checked={this.insurance_search_criteria['Show_inactive'] ? this.insurance_search_criteria['Show_inactive'] : false}
                                                    label={'Include Inactive'}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid style={{ margin: '0 -17px' }}>
                                        <Grid.Column computer={16} textAlign='right'>
                                            <Button id='clear_button_id' type='button' onClick={e => this.clear_handler(e)} basic>Clear</Button>
                                            {
                                                is_user_partner_customer_and_company_revEquip_revServe(this.company_service_type, this.current_user_type) ?
                                                    null : <Button id='new_button_id' type='button' onClick={e => this.new_handler(e)} basic>New</Button>
                                            }
                                            <Button id='search_button_id' type='submit' primary>Search</Button>
                                        </Grid.Column>
                                    </Grid>
                                    {this.state.show_grid && (
                                        <Grid style={{ marginTop: '-1rem', marginBottom: 0 }}>
                                            <Grid.Column tablet={5} computer={4} textAlign='left'>
                                                <p style={{ fontSize: '16px' }}>Search Results</p>
                                            </Grid.Column>
                                            <Grid.Column tablet={5} computer={8} textAlign='center'>
                                                {
                                                    <p style={{ fontSize: '16px', minHeight: 22 }}>
                                                        {`${this.state.grid_rows_count} ${
                                                            this.state.grid_rows_count == 1 ? ' record shown' : ' records shown'
                                                            }`}
                                                    </p>
                                                }
                                            </Grid.Column>
                                            <Grid.Column tablet={2} computer={4} textAlign='right' />
                                        </Grid>
                                    )}
                                </Form>
                            </div>
                            {this.state.show_grid && !this.state.is_loading && (
                                <GridView
                                    id={`id_grid_${this.page_name}`}
                                    row={this.row_data}                                    
                                    column={this.page_metadata.column_def}
                                    style={{ height: '100%' }}
                                    wrapperStyle={{ width: '100%', height: 0, display: 'flex', flex: '1 1 auto' }}
                                    suppressMovableColumns={false}
                                    enableColResize={true}
                                    onRowDoubleClicked={this.on_row_double_clicked}
                                    selectionType={'single'}
                                    get_grid_ref={this.get_grid_ref}
                                    suppressSizeToFit={true}
                                    headerHeight={this.grid_header_height}
                                    headerIdForTabNavigation={constants.search_grid_id}
                                    onForceGridOut={this.on_grid_out}
                                    on_filter_button_click={() => this.update_report_rows_count()}
                                />
                            )}
                        </div>
                        {this.state.show_grid && (
                            <div
                                className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                                id='applicationFooterSticky'
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                                <Grid.Column computer={16} textAlign='right'>
                                    <Button id='export_report_button' type='submit' onClick={this.on_export_button} basic>
                                        Export
                  </Button>
                                    <Button onKeyDown={set_focus_to_app_header} id='print_report_button' type='submit' onClick={this.on_print_button} primary>
                                        Print
                  </Button>
                                </Grid.Column>
                            </div>
                        )}
                    </div>
                </React.Fragment>
            )
        );
    }
}

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
        argos_bill_1177_new_insurance_option_do_not_accept_assignment: get_lauch_darkley_key_value(state.launch_darkly, constants.render_do_not_accept_assignment.argosBill1177NewInsuranceOptionDoNotAcceptAssignment)
    };
};

export default connect(mapStateToProps)(InsurancesSearchComponent);
