import { header_template, headerStatusTemplate, statusStyle } from "../../admin/constants/constants";
import { disabled_button_cell_renderer } from "../../shared/component/grid/gridview_cell_renderer";

//#region UI Elements Name and ID definition
export const print_button = {
    name: 'Print',
    id: 'procedure_code_replacements_print_button_id'
}

export const export_button = {
    name: 'Export',
    id: 'procedure_code_replacements_export_button_id'
}

export const new_button = {
    name: 'New',
    id: 'procedure_code_replacements_new_button_id'
}

export const save_button = {
    name: 'Save',
    id: 'procedure_code_replacements_save_button_id'
}
//#endregion

export const page_title = {
    name: 'Procedure Code Replacements',
    id: 'procedure_code_replacements_header_id'
}

export const grid_settings_criteria_double_column =
{
    grid_id: 'grid_proc_code_replacements',
    no_records_found_message: 'No Record Available',
    column_defs:
        [
            {
                headerName: 'Setting Name',
                headerComponentParams: header_template,
                field: 'claim_processing_rule_name',
                filter: 'textFilter',
                type: 'string',
                unSortIcon: true,
                resizable: false,
                suppressSizeToFit: true,
            },
            {
                headerName: 'Original Procedure Code',
                headerComponentParams: header_template,
                field: 'original_procedure_code',
                filter: 'textFilter',
                type: 'string',
                unSortIcon: true,
            },
            {
                headerName: 'Replacement Procedure Code',
                headerComponentParams: header_template,
                field: 'replacement_procedure_code',
                filter: 'textFilter',
                type: 'string',
                unSortIcon: true,
            },
            {
                headerName: 'Payer',
                headerComponentParams: header_template,
                field: 'qualifying_criteria_payer',
                filter: 'textFilter',
                type: 'string',
                unSortIcon: true,
                suppressSizeToFit: true,
                cellRenderer: 'GridCellButtonTooltip'
            },
            {
                headerName: 'Insurance Class',
                headerComponentParams: header_template,
                field: 'qualifying_criteria_insurance_class',
                filter: 'textFilter',
                type: 'string',
                unSortIcon: true,
                suppressSizeToFit: true,
                cellRenderer: 'GridCellButtonTooltip'
            },
            {
                headerName: 'Effective From',
                headerComponentParams: header_template,
                field: 'start_date',
                filter: 'textFilter',
                type: 'string',
                unSortIcon: true,
            },
            {
                headerName: 'Effective Through',
                headerComponentParams: header_template,
                field: 'end_date',
                filter: 'textFilter',
                type: 'string',
                unSortIcon: true,
            },
            {
                headerName: 'Last Update User',
                headerComponentParams: header_template,
                field: 'last_updated_user',
                filter: 'textFilter',
                type: 'string',
                unSortIcon: true,

            },
            {
                headerName: 'Last Update Date/Time',
                headerComponentParams: header_template,
                field: 'last_updated_date_time',
                filter: 'textFilter',
                type: 'string',
                unSortIcon: true,
            },
            {
                headerName: 'Status',
                headerComponentParams: headerStatusTemplate,
                field: 'status',
                filter: 'checklistFilter',
                minWidth: 120,
                width: 120,
                type: 'string',
                cellStyle: statusStyle,
                unSortIcon: true
            }
        ]
}

export const grid_settings_criteria_single_column =
{
    grid_id: 'grid_proc_code_replacements',
    no_records_found_message: 'No Record Available',
    column_defs:
        [
            {
                headerName: 'Setting Name',
                headerComponentParams: header_template,
                field: 'claim_processing_rule_name',
                filter: 'textFilter',
                type: 'string',
                unSortIcon: true,
                resizable: false,
                suppressSizeToFit: true,
            },
            {
                headerName: 'Original Procedure Code',
                headerComponentParams: header_template,
                field: 'original_procedure_code',
                filter: 'textFilter',
                type: 'string',
                unSortIcon: true,
            },
            {
                headerName: 'Replacement Procedure Code',
                headerComponentParams: header_template,
                field: 'replacement_procedure_code',
                filter: 'textFilter',
                type: 'string',
                unSortIcon: true,
            },
            {
                headerName: 'Qualifying Criteria',
                headerComponentParams: header_template,
                field: 'qualifying_criteria',
                filter: 'textFilter',
                type: 'string',
                unSortIcon: true,
                suppressSizeToFit: true,
                cellRenderer: disabled_button_cell_renderer
            },
            {
                headerName: 'Effective From',
                headerComponentParams: header_template,
                field: 'start_date',
                filter: 'textFilter',
                type: 'string',
                unSortIcon: true,
            },
            {
                headerName: 'Effective Through',
                headerComponentParams: header_template,
                field: 'end_date',
                filter: 'textFilter',
                type: 'string',
                unSortIcon: true,
            },
            {
                headerName: 'Last Update User',
                headerComponentParams: header_template,
                field: 'last_updated_user',
                filter: 'textFilter',
                type: 'string',
                unSortIcon: true,

            },
            {
                headerName: 'Last Update Date/Time',
                headerComponentParams: header_template,
                field: 'last_updated_date_time',
                filter: 'textFilter',
                type: 'string',
                unSortIcon: true,
            },
            {
                headerName: 'Status',
                headerComponentParams: headerStatusTemplate,
                field: 'status',
                filter: 'checklistFilter',
                minWidth: 120,
                width: 120,
                type: 'string',
                cellStyle: statusStyle,
                unSortIcon: true
            }
        ]
}

export const loading_identifier = {
    proc_code_replacement_list: 'proc_code_replacement_list',
    criteria: 'criteria',
    insurance_class: 'insurance_class',
    payer: 'payer'

}

export const filter_options = {
    show_inactive: {
        type:'show_inactive',
        settings:{
            name: 'Show Inactive',
            id: 'procedure_code_replacements_show_inactive_id'
        }
    },
    show_expired: {
        type: 'show_expired',
        settings:{
            name: 'Show Expired',
            id: 'procedure_code_replacements_show_expired_id'
        }
    },
    show_future: {
        type: 'show_future',
        settings: {
            name: 'Show Future',
            id: 'procedure_code_replacements_show_future_id'
        }
    }
}

export const statusSelectOptions = [
    {
        value: true,
        text: 'Active'
    },
    {
        value: false,
        text: 'Inactive'
    }
];

export const page_rule_list = [1]; // Identifier for Proc Code Replacement in DB.

export const proc_code_type = {
    original: 'original',
    replacement:'replacement'
}

export const criteria_type = {
    payers: 1,
    insurance_classes: 2,
    excluded_insurance_classes: 3,
    payer_include_insurance_classes: 7,
    payer_exclude_insurance_classes:8
}

export const criteria_source_type = {
    payer: 1,
    insurance_class: 2,
    insurance_code: 3,
    place_of_service: 4,
    provider: 5,
    procedure_code: 6
}

export const field_identifier = {
    claim_processing_rule_name: 'claim_processing_rule_name',
    original_procedure_codes: 'original_procedure_codes',
    replacement_procedure_codes: 'replacement_procedure_codes',
    claim_processing_criteria: 'claim_processing_criteria',
    claim_processing_criteria_source_details: 'claim_processing_criteria_source_details',
    claim_processing_criteria_payer_details: 'claim_processing_criteria_payer_details',
    claim_processing_criteria_insurance_class_details: 'claim_processing_criteria_insurance_class_details',
    claim_processing_criteria_excluded_insurance_class_details: 'claim_processing_criteria_excluded_insurance_class_details',
    claim_processing_status: 'claim_processing_status',
    original_procedure_code_advance_search: 'original_procedure_code_advance_search',
    replacement_procedure_code_advance_search: 'replacement_procedure_code_advance_search',
    claim_processing_criteria_source_data_id: 'claim_processing_criteria_source_data_id',
    effective_date_range: 'effective_date_range'

}

export const messages = {
    save_prompt_message: 'By saving these changes, I represent that the modifications to the procedure codes accurately represent the services actually performed and are fully supported in the patient’s medical record.',
    save_prompt_rule_name: 'Rule name: ',
    save_error_msg: 'Error saving Procedure Code Replacement.',
    save_success_msg: 'Procedure Code Replacement successfully saved.'
}

export const prompt_settings = {
    message: 'This action will cause any unsaved data to be lost. Continue?',
    unsaved_changes: 'Unsaved changes',
    continue: 'Continue'
}

export interface IClaimsScrubbingProcedureReplacementAddEditNewStateTemplate {
    insurance_class_tab_disabled: false;
    exclude_insurance_class_tab_disabled: false;
    is_save_in_progress: false;
    is_submitted: false;
    proc_replacement_data: any;
    validation_msg: any;
    selected_payer_items?: Array<any>,
    selected_insurance_class_items?: Array<any>,
    selected_excluded_insurance_class_items?: Array<any>,
    grid_conf: any;
    open_save_alert: boolean,
    procedure_code_search_data?: any,
    show_loader:boolean
}

export interface IClaimsScrubbingProcedureReplacementAddEditNewData {
    claim_processing_rule_id: number,
    original_procedure_codes: any,
    replacement_procedure_codes: any,
    claim_processing_criteria:any,
    claim_processing_criteria_source_details: Array<any>
}
