import * as global_constants from './../../global_constants';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request';

//To add a new case
export const add_case = (token, patient_id, case_obj) => {
    
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${global_constants.end_points.case_url.case}/add`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': case_obj
    })

    return request.make(requestConfig)
 

}
// get case by case id
export const get_case = (token, patient_id, case_id) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${global_constants.end_points.case_url.case}/${case_id}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(requestConfig)
    
}
// update the existing case
export const update_case = (token, patient_id, case_obj) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${global_constants.end_points.case_url.case}/${case_obj.case.id}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': case_obj
    })

    return request.make(requestConfig)

}
// search referring physician
export const search_referring_physician = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.provider_config.provider_config}${global_constants.end_points.provider_config.referring_physician_advance_search}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(param)
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(requestConfig)

}
// get case by case id
export const get_descipline = (token, provider_id,) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.provider_config.descipline}/${provider_id}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(requestConfig)

}

export const get_foto_episodes = (token, patient_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${global_constants.end_points.case_url.case}${global_constants.end_points.case_url.foto_episodes}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }
    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(requestConfig)
}

export const retrive_foto_episodes = (token, patient_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${global_constants.end_points.case_url.case}${global_constants.end_points.case_url.retrive_foto_episodes}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }
    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(requestConfig)
}

export const get_foto_reports = (token, patient_id, case_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${global_constants.end_points.case_url.case}${global_constants.end_points.case_url.foto_reports}/${case_id}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }
    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(requestConfig)
}

export const retrive_foto_reports = (token, patient_id, case_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${global_constants.end_points.case_url.case}${global_constants.end_points.case_url.retrive_foto_reports}/${case_id}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }
    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(requestConfig)
}

//Get Patient cases print verification forms
export const get_print_case_verification_from = (token, patient_id, case_id, is_extended) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${
        global_constants.end_points.case_url.case
        }${global_constants.end_points.case_url.print_verification_forms}/${case_id}/${is_extended}`;
    let request = new Request();
    let headers = {
        'Content-Type': 'application/pdf',
        'Authorization': 'Bearer ' + token,
    }
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'responseType': 'arraybuffer',
        'transformResponse': function (data, headersGetter) {
            return { data: data, headers: headersGetter };
        }
    })
    return request.make(request_config);
};
// clone case
export const case_clone = (token, patient_id, case_id,case_name) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${global_constants.end_points.case_url.case}/${case_id}/clone?case_name=''`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': case_name
    })

    return request.make(requestConfig)

}

// update the existing case
export const add_new_pre_auth_data = (token, patient_id, case_obj) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${global_constants.end_points.case_url.case}/${case_obj.case.id}${global_constants.end_points.case_url.new_preauth}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': case_obj
    })

    return request.make(requestConfig)
}

export const get_case_visit_list = (patient_id, from_case_id, token) => {
    const url = `${global_constants.base_end_points}/patients/${patient_id}/cases/case_visit_list?case_id=${from_case_id}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

export const charge_case_transfer = (token, patient_id, data) => {
    const url = `${global_constants.base_end_points}/patients/${patient_id}/cases/charge_case_transfer?case_id=${data.case_id}`;

    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'put',
        data: data.visit_ids
    })

    return request.make(request_config);
}

export const charge_case_transfer_audit = (token, patient_id, data) => {
    const url = `${global_constants.base_end_points}/patients/${patient_id}/cases/charge_case_transfer_audit/sourceCase/${data.sourceCaseId}/targetCase/${data.targetCaseId}`;

    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'put',
        data: data.selectedVisitIds
    })

    return request.make(request_config);
}