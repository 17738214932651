export enum enum_company_reference {
    service_type = "service_type",
    hub_location = "hub_location",
    company_type = "company_type",
    tax_id_type = "tax_id_type",
    fax_display_name = "fax_display_name",
    fax_display_address = "fax_display_address_and_phone",
    medicare_type = "medicare_type",
    statement_header_information = "statement_header_information",
    small_balance_adjustment_code = "small_balance_adjustment_code",
    roll_up_type = 'roll_up_type',
    credit_card_type = 'credit_card_type',
    opp_location = 'opp_location'
}
export enum enum_quick_search_type {
    company = "company",
    tax_procedure_code = "tax_procedure_code",
    excise_procedure_code = "excise_procedure_code",   
    location = "location"
}
export const company_messages = {
    required_fields: "You must complete all required fields.",
    added_successfully: 'Company added successfully.',
    updated_successfully: 'Company details updated successfully.',
    show_use_formator_confirm_message:'No billing will be allowed when the Use Formatter box is unchecked. Are you sure you want to continue?'
}
export const AllowOverrideFormatForDemandClaims = {
    sumajB32093AllowOverrideFormatForDemandClaims: "sumajB32093AllowOverrideFormatForDemandClaims"
}
export const monthly_statement_batches_number = [{
    "text": 1,
    "value": 1,
    "key": 1,
},{
    "text": 2,
    "value": 2,
    "key": 2,
},{
    "text": 4,
    "value": 4,
    "key": 4,
}]

export const statement_batches_keys = ['number_of_monthly_statement_batches',
                        'preferred_statement_date1',
                        'preferred_statement_date2',
                        'preferred_statement_date3',
                        'preferred_statement_date4' ]

export const restrictAccessToR4AtCompanyLevel = {
    argosB33306RemoveAccessToR4WithOverrides: 'argosB33306RemoveAccessToR4WithOverrides'
}

export const featureFlags = {
    argosbill1579uscotupdate: 'argosBill1579UscOtUpdate',
    leviathan_bill_2454_clearinghouse_account_id: "leviathanBill2454ClearinghouseAccountId",
    sumajbill2467printclaimwithlineoverlay: "sumajBill2467PrintClaimWithLineOverlay"
}

export const OnlinePaymentSupportFlag = {
    goliath_bill1938_online_payment_support: 'goliathBill1938OnlinePaymentSupport'
}

export const clearinghouse_account_id_requirements = {
    required_service_type: 3,
    required_domain_id: 3 
}