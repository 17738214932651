import * as React from 'react';
import { connect } from 'react-redux';
import { Accordion, Input, Button, Dimmer, Form, Grid, Icon, Loader } from 'semantic-ui-react';
import { toastr as toaster } from 'react-redux-toastr';
import {
    currencyFormatter,
    messages,
    summary_forecast_calculate_configuration
} from '../../reports/report_constants';
import ReportHeader from '../../reports/component/report_header';
import ReportFooter from '../../reports/component/report_footer';
import { format_amount } from '../../payment/utility';

export class SummaryForecastCalculateComponent extends React.Component<any, any> {
    is_mounted: boolean;

    constructor(props: any) {
        super(props);
        this.state = {
            calculate_criteria: this.props.calculate_criteria,
            calculate_result: null,
            error: {}
        };
        this.calculate_criteria = this.props.calculate_criteria;
    }

    calculate_criteria: any = {
        new_patients: 0,
        actual_collections_pct: 0,
        visits: 0,
        est_payments: 0,
        charges: 0,
        est_payments_per_visit: 0,
        charges_per_visit: 0,
        total_expenses: 0
    };

    calculate_result: any = {
        est_payments: 0,
        est_pay_per_visit: 0,
        total_expenses: 0,
        expense_per_visit: 0,
        total_p_l: 0,
        est_p_l_per_visit: 0,
        has_result: false
    };

    page_metadata = summary_forecast_calculate_configuration;

    UNSAFE_componentWillMount = async () => {
    };

    componentDidMount = async () => {
        this.is_mounted = true;
        let elem = document.getElementById('calculate-criteria-container');
        let all_form_elem = elem.getElementsByTagName('input');
        all_form_elem[0].focus();
        this.handle_calc_button_tab();
    };

    componentWillUnmount = () => {
        this.is_mounted = false;
    };

    setCalcData = (calc_data) => {
        if (this.is_mounted) {
            this.setState({
                calculate_criteria: calc_data
            });
        };
    };


    //Handle calculate submit
    calculate_handler = (e) => {

        if (this.is_mounted) {
            this.setState({
            });
        }

        this.calculate_result.est_payments = (this.state.calculate_criteria.est_payments);
        this.calculate_result.est_pay_per_visit = format_amount(this.state.calculate_criteria.est_payments / this.state.calculate_criteria.visits);
        this.calculate_result.total_expenses = (this.state.calculate_criteria.total_expenses);
        this.calculate_result.expense_per_visit = format_amount(this.state.calculate_criteria.total_expenses / this.state.calculate_criteria.visits);
        this.calculate_result.total_p_l = (this.state.calculate_criteria.est_payments - this.state.calculate_criteria.total_expenses);
        this.calculate_result.est_p_l_per_visit = format_amount(this.calculate_result.est_pay_per_visit - this.calculate_result.expense_per_visit);
        this.calculate_result.has_result = true;
        this.setCalcResult(this.calculate_result);
        this.setState(this.state);
    };

    //set calc result
    setCalcResult = (calc_result) => {
        if (this.is_mounted) {
            this.setState({
                calculate_result: calc_result
            });
        };
    };

    // total expenses handler.
    total_expenses_handler = (e, blur) => {
        let error = {
            total_expenses: false
        }
        if (blur) {
            e.target.value = format_amount(e.target.value);
        } else {
            if (this.calculate_result.has_result) {
                this.calculate_result.has_result = false;
                this.setState(this.state);
            }
        }
        error[e.target.name] = false;
        if (this.is_mounted) {
            this.setState({
                error: error,
                [e.target.name]: e.target.value
            });
        }
        if (e.target.name == 'total_expenses') {
            var calc_data = calculate_criteria(this.state.calculate_criteria);
            calc_data.calculate_criteria.total_expenses = (format_amount(e.target.value || 0) as number);
            this.setCalcData(calc_data.calculate_criteria);
            this.calculate_result.total_expenses = parseFloat(this.state.calculate_criteria.total_expenses);
        }
    };

    //Function used to initialize the footer object.
    initialize_footer_obj = (column_def) => {
        var footer_obj = {};
        column_def.map((value, index) => {
            footer_obj[value.field] = null;
        });
        return footer_obj;
    };

    handle_calc_button_tab = () => {
        setTimeout(() => {
            let calcButton = document.getElementById('calc_button_id');
            calcButton.addEventListener('keydown', (e) => {

            });
        }, 200);
    };

    render() {
        return (
            <div>
                <ReportHeader title={this.page_metadata.title} />
                <div
                    className={'report-wrapper'}
                    style={{ paddingRight: 0, background: 0, border: 0, display: 'flex', flex: 1 }}
                >
                    {this.render_program()}
                </div>
            </div>
        );
    }

    render_program = () => {
        return (
            <div id='calculate-criteria-container' className='report-wrapper'
                style={{ width: '100%' }}
            >
                <div className='patient-search-form'>
                    <Form autoComplete='off' onSubmit={(e) => this.calculate_handler(e)} >
                        <Grid>
                            <Grid.Column tablet={4} computer={3}>
                                <Form.Field>
                                    <label>{this.page_metadata.total_expenses_label}</label>
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column tablet={4} computer={4}>
                                <Form.Field>
                                    <Input
                                        maxLength={12}
                                        name={'total_expenses'}
                                        type={'text'}
                                        id={'total_expenses'}
                                        onChange={e => this.total_expenses_handler(e, false)}
                                        onBlur={e => this.total_expenses_handler(e, true)}
                                        autoComplete='new-password'
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column tablet={4} computer={4}>
                                <Form.Field>
                                    <Button
                                        id='calc_button_id'
                                        type='submit'
                                        primary
                                        content='Calculate'
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid>
                    </Form>
                </div>
                <div id='calculate-result-container'>
                    {this.render_calc_result()}
                </div>
            </div>
        );
    };

    render_calc_result = () => {
        if (this.calculate_result && this.calculate_result.has_result) {
            let input_json = JSON.stringify({ "calculate_result": this.calculate_result, "page_metadata": this.page_metadata });
            return (
                <div className='patient-search-form'>
                    <Grid>
                        <Grid.Column computer={4}>
                            <span>{this.page_metadata.est_payments_label}</span>
                        </Grid.Column>
                        <Grid.Column computer={4}>
                            <span>{currencyFormatter(this.calculate_result.est_payments)}</span>
                        </Grid.Column>
                        <Grid.Column computer={4}>
                            <span>{this.page_metadata.est_pay_per_visit_label}</span>
                        </Grid.Column>
                        <Grid.Column computer={4}>
                            <span>{currencyFormatter(this.calculate_result.est_pay_per_visit)}</span>
                        </Grid.Column>
                    </Grid>
                    <Grid>
                        <Grid.Column computer={4}>
                            <span>{this.page_metadata.total_expenses_label}</span>
                        </Grid.Column>
                        <Grid.Column computer={4}>
                            <span>{currencyFormatter(this.calculate_result.total_expenses)}</span>
                        </Grid.Column>
                        <Grid.Column computer={4}>
                            <span>{this.page_metadata.expense_per_visit_label}</span>
                        </Grid.Column>
                        <Grid.Column computer={4}>
                            <span>{currencyFormatter(this.calculate_result.expense_per_visit)}</span>
                        </Grid.Column>
                    </Grid>
                    <Grid>
                        <Grid.Column computer={4}>
                            <span>{this.page_metadata.total_p_l_label}</span>
                        </Grid.Column>
                        <Grid.Column computer={4}>
                            <span>{currencyFormatter(this.calculate_result.total_p_l)}</span>
                        </Grid.Column>
                        <Grid.Column computer={4}>
                            <span>{this.page_metadata.est_p_l_per_visit_label}</span>
                        </Grid.Column>
                        <Grid.Column computer={4}>
                            <span>{currencyFormatter(this.calculate_result.est_p_l_per_visit)}</span>
                        </Grid.Column>
                    </Grid>
                    <Input
                        name={'calculate_result_data'}
                        type={'hidden'}
                        id={'calculate_result_data'}
                        value={input_json}
                    />
                </div>
            );
        } 
    };

    controls_toggler = (type) => {
        if (this.is_mounted) {
            this.setState({
                controls_is_expended: {
                    ...this.state.controls_is_expended,
                    [type]: !this.state.controls_is_expended[type]
                }
            });
        }
    };

}

// Get user and login details from store.
const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details
    };
};

export const summary_forecast_calculate_print = (data) => {
    if (data.calculate_result.has_result) {
        var print_html = '';
        print_html += `<div style='font-weight: bold;font-size:16px'>${data.page_metadata.title}</div>`;
        print_html += `<table style='border-top: 1px solid black'><thead>`;
        print_html += `<tr style='font-weight: bold;font-size:16px;'><td>${data.page_metadata.est_payments_label}</td><td>${data.page_metadata.est_pay_per_visit_label}</td><td>${data.page_metadata.total_expenses_label}</td><td>${data.page_metadata.expense_per_visit_label}</td><td>${data.page_metadata.total_p_l_label}</td><td>${data.page_metadata.est_p_l_per_visit_label}</td></tr>`;
        print_html += `</thead>`;
        print_html += `<tbody>`;
        print_html += `<tr><td>${currencyFormatter(data.calculate_result.est_payments)}</td><td>${currencyFormatter(data.calculate_result.est_pay_per_visit)}</td><td>${currencyFormatter(data.calculate_result.total_expenses)}</td><td>${currencyFormatter(data.calculate_result.expense_per_visit)}</td><td>${currencyFormatter(data.calculate_result.total_p_l)}</td><td>${currencyFormatter(data.calculate_result.est_p_l_per_visit)}</td></tr>`;
        print_html += `</tbody></table>`;
        return print_html;
    }
}

export const calculate_criteria = (calc) => {
    return { calculate_criteria: calc };
};

export default connect(mapStateToProps)(SummaryForecastCalculateComponent);
