import * as React from "react";
import { connect } from "react-redux";
import ReportComponent from "../../reports/component/report_component";
import { ResizableDraggableModal } from "../resizable-draggable-modal";
import { bindActionCreators } from "redux";

class PopUpReportsComponent extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      shown: this.props.show_model_popup_report
    };
  }
  render() {
    return (
      <React.Fragment>
        {
          <ResizableDraggableModal
            showModal={true}
            dimmer={false}
            boundWith={"window"}
            dragHandleClassName={"headerGrid"}
            autoResizeOnload={true}
            popUpPosition={{
              x: 300,
              y: 65,
              width: 1000,
              height: 700,
              minHeight: 400,
              minWidth: 515,
              maxWidth: 1600,
              maxHeight: 900
            }}
            enable={{
              bottomRight: true
            }}
            className={"modal_container"}
          >
            <ResizableDraggableModal.Header
              className={"daragble-header"}
              id="daragble-header"
              onRequestClose={() => this.props.on_close()}
              styles={{ borderBottom: 0, padding: 0 }}
            />
            <ResizableDraggableModal.Content
              className={"daragble-content scrolling"}
              styles={{ maxHeight: "inherit", paddingTop: "1rem", flexDirection: 'row' }}
            >
              <ResizableDraggableModal.Description className={"daragble-description"}>
                <ReportComponent
                  set_url_history={true}
                  location={this.props.location}
                  is_print_disabled={this.props.is_print_disabled}
                  is_export_disabled={this.props.is_export_disabled}
                  is_close_button={true}
                  on_close={this.props.on_close}
                  from_popup={true}
                  auto_print={this.props.auto_print}
                />
              </ResizableDraggableModal.Description>
            </ResizableDraggableModal.Content>
          </ResizableDraggableModal>
        }
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    //show_model_popup_report: state.shared_details.show_model_popup_report
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      //show_hide_model_popup_report: show_hide_model_popup_report
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PopUpReportsComponent);
