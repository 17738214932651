import * as global_constants from './../../global_constants';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request';
import { log_error } from '../../shared/action/shared_action';
import * as constants from '../billing_constants';

//********* Open claim search **********
export const get_open_claims_search = (search_criteria, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.open_claims.search_data}`;
    
    let headers = {
        'Authorization': 'Bearer ' + token
        //'silversurfer': JSON.stringify(search_criteria)
    }

    let request = new Request();
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        params: search_criteria
    })

    return dispatch => request.make(request_config).then(
        response => dispatch({
            type: constants.OPEN_CLAIMS_SEARCH,
            payload: response
        }),
        error => {
            log_error(error)
        }
    )
}

export const close_open_claims = (data) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.open_claims.close_open_claims}`;

    let request = new Request();
    let request_config = new RequestConfig({
        'url': url,
        'method': 'put',
        data: data
    })

    return request.make(request_config);
}

export const formatter_billing_register_rpt = (run_comp_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.open_claims.formatter_billing_register_rpt}`;
    
    let request = new Request();
    let request_config = new RequestConfig({
        'url': url,
        'method': 'get',
        params: {
            run_comp_id: run_comp_id
        }
    })

    return request.make(request_config);
}

//********* Empty claims data **********
export const update_open_claims_data = () => {
    return { type: constants.EMPTY_CLAIMS_DATA, payload: [] }
}