import { Service } from '@webpt/tasks-icon';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { task_icon_config } from '../../global_constants';
import * as local_storage from '../../shared/local_storage_utility';
import { user_tasks_sso_authentication } from '../action/user_action';
var eventTimeOut = null;
const user_icon_target_id = '#my-tasks-icon';
const patient_icon_target_id = '#my-patient-tasks-icon';
export class UserTasks extends React.Component<any, any> {

    constructor(props) {
        super(props);
        // Initialize state property here
        this.state = {

        }
    }
    componentDidUpdate = (previousProps, previousState) => {
        if (previousProps.patient_id != this.props.patient_id) {
            this.remove_patient_task_icon();
            this.generate_task_icon_patient(this.props.patient_id != undefined && this.props.patient_id != null && this.props.patient_id.toString().trim() != "" ? this.props.patient_id : '0');
           
        }
    }

    componentDidMount() {
        var user_data = local_storage.get("auth_data");
        let patient_id = this.props.patient_id;
        patient_id = this.props.patient_id != undefined && this.props.patient_id != null && this.props.patient_id.toString().trim() != "" ? this.props.patient_id : '0';

        if (this.props.is_patient_task && this.props.patient_id != undefined && this.props.patient_id != null && this.props.patient_id.toString() != "") {
            this.generate_task_icon_patient(patient_id);
        }
        else {
            if (user_data) {
                user_tasks_sso_authentication(user_data.user_id).then((res) => {
                    if (res && res.data.data && res.data.data.ssoRevflowAuthorize.success) {
                        var data = res.data.data.ssoRevflowAuthorize.accessCode;
                        Service.init({
                            gatewayUrl: task_icon_config.webpt_gateway_url, //The gateway url for fetching task count
                            tasksIconServiceUrl: task_icon_config.tasks_icon_service_url,
                            tasksClientUrl: task_icon_config.webpt_tasks_client_url,//The destination the icon links to on click.
                            app: task_icon_config.task_app,//Denotes what app the icon is embedded in so that the icon can perform the necessary SSO logic. i.e "reflow" or "emr"
                            authorize: () => data
                        });

                        Service.add({
                            target: user_icon_target_id,
                            queryType: 'byUser'
                        });
                        this.get_task_icon();

                    } else {
                        console.log('User tasks sso authentication fail with response', res);
                    }
                }, (error) => {
                    console.log('Error while user tasks sso authentication', error);
                });
            }
        }
    }
    generate_task_icon_patient = (patient_id) => {
        if (this.props.is_patient_task && this.props.patient_id != undefined && this.props.patient_id != null && this.props.patient_id.toString() != "") {

            var user_data = local_storage.get("auth_data");
            if (user_data) {
                user_tasks_sso_authentication(user_data.user_id).then((res) => {
                    if (res && res.data.data && res.data.data.ssoRevflowAuthorize.success) {
                        var data = res.data.data.ssoRevflowAuthorize.accessCode;
                        Service.init({
                            gatewayUrl: task_icon_config.webpt_gateway_url, //The gateway url for fetching task count
                            tasksIconServiceUrl: task_icon_config.tasks_icon_service_url,
                            tasksClientUrl: task_icon_config.webpt_tasks_client_url,//The destination the icon links to on click.
                            app: task_icon_config.task_app,//Denotes what app the icon is embedded in so that the icon can perform the necessary SSO logic. i.e "reflow" or "emr"
                            authorize: () => data
                        });

                        Service.add({
                            target: patient_icon_target_id,
                            queryType: 'byPatient',
                            style: { iconColor: 'dark' },
                            variables: {
                                'patientId': patient_id //'ET032019' //12822186 patient_id
                            }
                        });

                    } else {
                        console.log('Patient tasks sso authentication fail with response', res);
                    }
                }, (error) => {
                    console.log('Error while user tasks sso authentication', error);
                });
            }
        }
    }
    get_task_icon = () => {
        eventTimeOut = window.setTimeout(() => {
            if (document.querySelector(user_icon_target_id + ' a')) {
                window.clearTimeout(eventTimeOut);
                var task_href = document.querySelector(user_icon_target_id + ' a')['href'];
                //document.querySelector('#my-tasks-icon a').removeAttribute('href')
                document.querySelector(user_icon_target_id + ' a').addEventListener('click', (e) => { this.task_icon_on_click(e, task_href) })
            } else {
                this.get_task_icon();
                console.log('re try get_task_icon')
            }
        }, 50);
    }

    task_icon_on_click = (e, task_href) => {
        window.open(task_href);
        e.preventDefault();
    }

    componentWillUnmount() {

        if (!this.props.is_patient_task) {

            //icon.remove();
            this.remove_user_task_icon();
            window.clearTimeout(eventTimeOut);
        }
        if (this.props.is_patient_task) {

            //pat_icon.remove();
            this.remove_patient_task_icon();
        }

    }

    remove_patient_task_icon = () => {
        /* 
               The remove method performs all clean up achieved through the cleanup method,
               but also removes the task icon from the target element.
           */
        Service.remove(patient_icon_target_id);
    }
    remove_user_task_icon = () => {
        /* 
                The remove method performs all clean up achieved through the cleanup method,
                but also removes the task icon from the target element.
            */
        Service.remove(user_icon_target_id);
    }

    render() {
        return (
            <div id={this.props.is_patient_task && this.props.patient_id != undefined && this.props.patient_id != null && this.props.patient_id.toString() != "" ? "my-patient-tasks-icon" : "my-tasks-icon"}></div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        // user_tasks_auth_prop: user_tasks_sso
    }, dispatch);
};

const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTasks);