import * as global_constants from '../../global_constants';
import { log_error } from '../../shared/action/shared_action';
import * as patient_constants from '../patient_constants';
import { format_documents, format_reports } from '../util/medical_record_utility';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request';
/**
  * Medical Record: output repost API calling
  * @param patient_id
  * @param token
  */
export const get_output_reports = (patient_id, token) => {
    const { base_end_points, end_points } = global_constants;
    const url = `${base_end_points}${end_points.patient_urls.patients}/${patient_id}${end_points.patient_urls.notes}?patientId=${patient_id}`;
    var sticky_code = patient_constants.sticky_code.Patient

    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })


    return dispatch => {
        dispatch({
            type: patient_constants.GET_PATIENT_GET_MEDICAL_OUTPUT_REPORTS,
            payload: null
        })

        return request.make(request_config).then(
            response => dispatch({
                type: patient_constants.GET_PATIENT_GET_MEDICAL_OUTPUT_REPORTS,
                payload: format_reports(response.data)
            }),
            error => {
                dispatch({
                    type: patient_constants.GET_PATIENT_GET_MEDICAL_OUTPUT_REPORTS,
                    payload: []
                })
                log_error(error)
            }
        );
    }
}


/**
  * Medical Record: scanned documents API calling
  * @param patient_id
  * @param token
  */
export const get_scanned_documents = (patient_id, token) => {
    const { base_end_points, end_points } = global_constants;
    const url = `${base_end_points}${end_points.patient_urls.patients}/${patient_id}${end_points.note_urls.scanned_documents}?patientId=${patient_id}`;
    var sticky_code = patient_constants.sticky_code.Patient

    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return dispatch => {
        dispatch({
            type: patient_constants.GET_PATIENT_GET_MEDICAL_SCANNED_DOCUMENTS,
            payload: null
        })

        return request.make(request_config).then(
            response => dispatch({
                type: patient_constants.GET_PATIENT_GET_MEDICAL_SCANNED_DOCUMENTS,
                payload: format_documents(response.data)
            }),
            error => {
                dispatch({
                    type: patient_constants.GET_PATIENT_GET_MEDICAL_SCANNED_DOCUMENTS,
                    payload: []
                })
                log_error(error)
            }
        );
    }
}


//Medical Record - Print Reports .
export const print_output_report = (patient_id, obj_arr, token) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${global_constants.end_points.patient_urls.notes}${global_constants.end_points.note_urls.print_output_report}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(obj_arr),
        'Content-type': 'application/pdf',
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'responseType': 'arraybuffer',
        'transformResponse': function (data, headersGetter) {
            return { data: data, headers: headersGetter };
        }
    })


    return request.make(request_config)
}


//Medical Record - Print Reports .
export const print_scanned_documents = (patient_id, document_ids, token) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${global_constants.end_points.patient_urls.notes}${global_constants.end_points.note_urls.print_scanned_documents}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': document_ids
    })


    return request.make(request_config);
}

// get pdf data by guid
export const get_PDF_by_guid = (patient_id,guid, token) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${global_constants.end_points.patient_urls.notes}${global_constants.end_points.note_urls.open_PDF_by_guid}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-type': 'application/pdf',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'responseType': 'arraybuffer',
        'transformResponse': function (data, headersGetter) {
            return { data: data, headers: headersGetter };
        },
        'params': {
            'key_guid': guid
        }
    })


    return request.make(request_config);
}


//Medical Record - get_scanned_document .
export const open_scanned_document = (patient_id, image_index_id, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${global_constants.end_points.patient_urls.notes}${global_constants.end_points.note_urls.open_scanned_document}/${image_index_id}`;

    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })


    return request.make(request_config);
}
