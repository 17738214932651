export enum enum_amount_type {
    allowed = "allowed",
    payment = "payment",
    adjustment = "adjustment",   
    transfer_det = "transfer_det",
}
export enum enum_selection_type {
    adjustment_qual = "adjustment_qual",
    transaction_code = "transaction_code",
    adjustment_code = "adjustment_code",
    transfer_insurance = "transfer_insurance",
    from_insurance = "from_insurance",
    adjustment_input = "adjustment",
    payment_adjust_input = "payment_adjust",
    transfer_input = "transfer",
    payment_input = "payment",
}
export enum enum_posting_type {
    multiple_entry = 3,
    standard_payment = 1,
    group = 2,

}
export enum enum_page_type {
    batch_payments = 1,
    batch_adjustment = 2
}

export const payment_field_type = ['allowed', 'payment', 'adjustment', 'transfer']

export const total_adjustment = (posting_type) => {

    let total_adjustment = 1;
    if (posting_type == "3") { total_adjustment = 5 };
    return total_adjustment;
}
export const total_columns = (posting_type, payer_denies_crossover_ff:boolean) => {

    let total_columns = 16;
    if (posting_type == "2") { total_columns = 14 };
    if (payer_denies_crossover_ff) total_columns = total_columns + 1;
    return total_columns;
}
export const constant = {
    adjustment_search: '/constant/eob_claim_adj_code/get_eob_claim_code'
}

export const batch_payments_columns = [
    {
      headerName: 'Charge ID',
      field:'charge_id',
      type: 'string',
      display_in: ['1', '2', '3']
    },
    {
      headerName: 'Date of Service',
      field:'date_of_service',
      type:'date',
      display_in: ['1', '2', '3']
    },
    {
      headerName: 'CPT Code',
      field:'proc_code',
      type:'string',
      display_in: ['1', '2', '3']
    },
    {
      headerName: 'Units',
      align: 'right',
      field:'units',
      type: 'number',
      display_in: ['1', '2', '3']
    },
    {
      headerName: 'Charges',
      align: 'right',
      field:'total_chg_amt',
      type: 'currency',
      display_in: ['1', '2', '3']
    },
    {
      headerName: 'Balance',
      align: 'right',
      field:'owed_amount',
      type: 'currency',
      display_in: ['1', '2', '3']
    },
    {
      headerName: 'Paid By Insurance',
      field:'from_ins_code_text',
      type: 'string',
      display_in: ['1', '2', '3']
    },
    {
      headerName: 'Allowed',
      align: 'right',
      field:'allow_amount',
      type: 'currency',
      display_in: ['1', '3']
    },    
    {
      headerName: 'Payment',
      align: 'right',
      field:'pay_amount',
      type: 'currency',
      display_in: ['1', '3']
    },    
    {
      headerName: 'Yes/No',
      align: 'center',
      field:'yes_no',
      type:'yes_no',
      display_in: ['2']
    },
    {
      headerName: 'Payment/Adjustment',
      align: 'right',
      field:'pay_amount',
      type: 'currency',
      display_in: ['2']
    },
    {
      headerName: 'Adjustment',
      align: 'right',
      field:'adj_amounts',
      type: 'currency',
      display_in: ['1', '3']
    },
    {
      headerName: 'Transfer',
      align: 'right',
      field:'xfer_amount',
      type: 'currency',
      display_in: ['1', '2', '3']
    },
    {
      headerName: 'Transfer Detail',
      align: 'right',
      field:'xfer_det_amounts',
      type: 'currency',
      display_in: ['3']
    },
    {
      headerName: 'Transfer to Insurance',
      field:'xfer_ins_code_text',
      type:'string',
      display_in: ['1', '2', '3']
    },
    {
        headerName: 'Crossover',
        field: 'crossover',
        type: 'string',
        display_in: ['1', '2', '3']
    },
    {
      headerName: 'Adjustment Qualifier',
      field:'adj_qualifier_ids',
      type:'string',
      display_in: ['1', '2', '3']
    },
    {
      headerName: 'Adjustment Code',
      field:'adj_code_reason_ids',
      type:'string',
      display_in: ['1', '2', '3']
    }, {
        headerName: 'Billing Provider',
        field: 'prov_name',
        type: 'string',
        display_in: ['1', '2', '3']
    },
    {
      headerName: 'Transaction Code',
      field:'transfer_code_text',
      type:'string',
      display_in: ['1', '2']
    }
];
export const messages = {
    date_validation: '"Date of Service Through" must be on or after the "Date of Service From".',
    transfer_insurance_not_fill: 'Please select an insurance to transfer to.',
    less_payment_amount: 'Payment Amount is not equal to the Posted Amount.',
    close_open_month: 'Month already closed. Please use a current date.',
    required_field: 'Please fill all the mandatory fields or check highlighted field values.',
    qualifier_na_required_payment: 'When Payment/Adjustment amounts are both 0.00, the Qualifier must be NA.',
    qualifier_na_required_adjustment: 'When Adjustment amount is 0.00, the Qualifier must be NA.',
    qualifier_pr_forbidden_adjustment: 'When an Adjustment has been entered, PR is not a valid Qualifier.',
    qualifier_pr_na_transfer_detail_required: 'When a Transfer Detail amount is entered, the Qualifier should be PR.',
    adjustment_code_required: 'Adjustment Code is required.',
};
