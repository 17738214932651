import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Dimmer, Checkbox, Form, Grid, Header, Loader, Modal, Table, TableBody, Input, Icon } from "semantic-ui-react";
import * as moment from 'moment';
import { user_authentication } from '../../login/action/login_action';
import * as shared_constants from '../../shared/shared_constants';
import { set_url_history } from '../../reports/util/report_data_formatter';
import * as session_storage from '../../shared/session_storage_utility';
import { left_menu_permission, store_r2_data } from '../../shared/action/shared_action';
import { updated_charges_summary } from '../../charge/charge_constant';
import { detect_ie, detect_ie_11, is_mobile, r2_decode_url, r2_form_path, get_lauch_darkley_key_value } from '../../shared/utility';
import * as local_storage from './../../shared/local_storage_utility';
import * as FFConstant from '../../feature_flip_constant';
export class DashboardComponent extends React.Component<any, any> {
    [x: string]: any;
    _is_mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            clearing_house_website: this.props.user_details && this.props.user_details.user_data && this.props.user_details.user_data.data && this.props.user_details.user_data.data.clearing_house_website ? this.props.user_details.user_data.data.clearing_house_website : ''
        }
    }


    UNSAFE_componentWillMount = () => {

    };
    componentDidMount = async () => {
        session_storage.remove('change_company');
    };
    componentWillUnmount() {

    }
    open_r2_page = (name) => {
        var open_r2 = true;
        for (var i = 0; i < shared_constants.r2_page_to_r6_page.length; i++) {
            var item = shared_constants.r2_page_to_r6_page[i]
            if (item.value == name && this.props[item.key] == true) {
                open_r2 = false;
                break;
            }
        }
        return open_r2;
    }

    on_card_item_click = (event, menu_data) => {
        if (menu_data.r6_path == null || menu_data.r6_path == '' || menu_data.r6_path == undefined) {
            menu_data.r6_path = '/coming_soon';
        }

        if (event.target.parentElement.classList.contains('visible')) {
            event.target.parentElement.classList.toggle('visible');
        }
        // R2 Embedded Pages
        // Note:: Clear "shared_constants.is_open_r2_page[menu_data.name]" when all R2 pages are visible in R6
        // shared_constants.is_open_r2_page will return true for the pages which will be open in r6.

        if (menu_data.is_r2 && this.open_r2_page(menu_data.name) && shared_constants.is_open_r2_page[menu_data.name]) {
            // for decoding R2 EXACT url after converting const values
            const r2_base_url = r2_decode_url(menu_data.r2_url);

            // store r2 base url and page name in the store
            this.props.store_r2_data(r2_base_url, menu_data.name);

            // Form url pathname as an input for R6
            const pathname = r2_form_path(menu_data.name);

            this.props.history.push(`/R2/${pathname}`);
        }
        //Charge Integration Audit from DB
        else if (menu_data.r6_path.indexOf(updated_charges_summary.component_path) != -1) {
            if (session_storage.get(updated_charges_summary.session_key) && (session_storage.get(updated_charges_summary.session_key).search_criteria)) {
                session_storage.set(updated_charges_summary.session_key, '');
                this.props.history.push(`${menu_data.r6_path}`, { refresh: true })
            }
            else {
                this.props.history.push(`${menu_data.r6_path}`, { refresh: false })
            }
        }
        //Reports from DB
        else if (menu_data.r6_path.indexOf('report') != -1 && menu_data.report_id) {
            session_storage.set('url_history', []);
            set_url_history();
            this.props.history.push(`${menu_data.r6_path}`, { report_id: menu_data.report_id, flag: true });
        } else if (menu_data.r6_path.indexOf('patient_search') != -1) {
            this.props.patient_details.patient_search = null;
            session_storage.set('patient_criteria', '');
            this.props.history.push(`${menu_data.r6_path}`);
        }
        else if (menu_data.r6_path.indexOf('charge/release_claim_delay') != -1) {
            session_storage.remove('release_cliam_dela_search_criteria');
            this.props.history.push(`${menu_data.r6_path}`);
        }
        // R6 new pages
        else {
            // remove r2 base url and page name from the store, if present
            this.props.store_r2_data(null, null);

            this.props.history.push(`${menu_data.r6_path}`);
        }
    };
    render_new_menu = () => {
       
        const { visible, active, sub_active } = this.state;

        // Form Drop Down Items
        const trigger = (menu) => {
           
            return (
                <li key={menu.parent_dashboard}>
                    {
                        menu.parent_dashboard == 'EMR Balancing (Weekly)' ?
                            <div style={{fontSize:'16px'}}>
                                <strong key={'strong' + menu.name}>{menu.name}</strong>
                                <span key={'span' + menu.name} className="instructinfo">{menu.menu_description}</span>
                            </div>
                            :
                            menu.name == 'Rejections' && (menu.r6_path == '' || menu.r6_path == null) ?
                                <div style={{ fontSize: '16px' }}>
                                    <strong key={'strong' + menu.name}>{menu.name}</strong>
                                    <span key={'span' + menu.name} className="instructinfo">{menu.menu_description}</span>
                                </div>
                                :
                                menu.name == 'Rejections' && menu.r6_path ?
                                    <a target="_blank" key={menu.menu_description} href={menu.r6_path}>
                                        <strong key={'strong' + menu.name}>{menu.name}</strong>
                                        <span key={'span' + menu.name} className="instructinfo">{menu.menu_description}</span>
                                    </a>
                                    :
                        <a key={menu.menu_description} onClick={(e) => { this.on_card_item_click(e, menu) }}>
                            <strong key={'strong' + menu.name}>{menu.name}</strong>
                            <span key={'span' + menu.name} className="instructinfo">{menu.menu_description}</span>
                        </a>
                           
                        }
                </li>
            );

        };

        // List from database

        var menu_list = { ...this.props.shared_details.left_menu_data };

        if (menu_list) {
            menu_list = menu_list.data;
            ////////////////////
            var menu_filter: Array<any> = menu_list
                .filter((menu, index) => {
                    return (menu.parent_dashboard != null && menu.view)
                });
            // Add Dashboard Menu
            var rejection_menu_list = menu_filter
                .filter((menu, index) => {
                    return menu.id == -998;
                });
            if (this.props.rsiB29536Dashboard) {

                if (rejection_menu_list.length <= 0) {
                    let link_value = '';
                    var auth_data = local_storage.get("auth_data");
                    if (auth_data && auth_data.clearing_house_website) {
                        link_value = auth_data.clearing_house_website;
                        if (link_value && link_value.search(/^http[s]?:\/\//) < 0) {
                            link_value = '//' + link_value;
                        }
                    }
                    menu_filter.push({
                        create: true,
                        delete: true,
                        id: -998,
                        is_r2: false,
                        menu_id: -998,
                        menu_order: 0,
                        name: "Rejections",
                        menu_description: "Work clearinghouse rejections.",
                        parent_dashboard: 'Claims (Daily)',
                        parent_dashboard_order: 70,
                        parent_id: 0,
                        r2_url: "",
                        r6_parent_id: 0,
                        r6_path: link_value,
                        report_id: null,
                        sub_menu: null,
                        switch_id: null,
                        update: true,
                        view: true
                    });
                }
            }
            else {
                if (rejection_menu_list.length >= 0) {
                    menu_filter
                        .filter((menu, index) => {
                            if (menu.id == -998) {
                                menu_filter.splice(index, 1);
                            }
                        });
                }
            }

            ////////////////////
            // list from constant
            var menu_mapping: Array<any> = menu_filter
                .filter((menu, index) => {
                    return menu.parent_dashboard != null;
                })
                .sort(function (obj1, obj2) {
                    var nameA = obj1.parent_dashboard_order,
                        nameB = obj2.parent_dashboard_order;
                    if (nameA < nameB)
                        //sort string ascending
                        return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                });
            //
            const unique_cards = [];
            menu_mapping.map(item => {
                var findItem = unique_cards.find(x => x.parent_dashboard === item.parent_dashboard);
                if (!findItem)
                    unique_cards.push(item);
            });
            var sort_cards = unique_cards.sort(function (obj1, obj2) {
                var nameA = obj1.parent_dashboard_order,//Dashboard_menu_order,
                    nameB = obj2.parent_dashboard_order;
                if (nameA < nameB)
                    //sort string ascending
                    return -1;
                if (nameA > nameB) return 1;
                return 0;
            });
            //
            var _this = this;
            return (
                <Grid className="mar-t-0" style={{paddingRight:15}}>
                {
                    sort_cards.map(function (card_item, i) {

                        //var card_item_icon = shared_constants.app_left_parent_menu.filter((menu_item, index) => {
                        //    return menu_item.id == card_item.id;
                        //})[0];
                        if (card_item) {
                            var child_card_item: Array<any> = menu_filter.filter((menu, j) => {
                                return menu.parent_dashboard == card_item.parent_dashboard;
                            });

                            // ignore sub menu items
                            shared_constants.ignore_menu_items.map((item) => {
                                if (_this.props[item.key] == false) {
                                    child_card_item = child_card_item.filter((sub_menu) => {
                                        if (sub_menu.name != item.value) {
                                            return sub_menu;
                                        }
                                    })
                                }
                            });

                            var card_line_item = child_card_item.sort(function (obj1, obj2) {
                                var nameA = obj1.dashboard_menu_order,
                                    nameB = obj2.dashboard_menu_order;
                                if (nameA < nameB)
                                    //sort string ascending
                                    return -1;
                                if (nameA > nameB) return 1;
                                return 0;
                            });
                            return (<Grid.Column id={"card_information_section_" + i} computer={4} tablet={8} className="card_information_section" key={i}>
                                <Form autoComplete="off" className="normal-form card_details_form">
                                    <Grid className="form patient-search-form dashboard_card_subsection">
                                        <Grid.Column id={"dashboard_card_section_"+i} computer={16} className="dashboard_card_section">
                                            <ul>
                                                <li>
                                                    <h1 className="card-heading">{card_item.parent_dashboard}</h1>
                                                </li>
                                                {

                                                    card_line_item.map(function (sub_menu, k) {
                                                        if (sub_menu && sub_menu.view) {
                                                            return (

                                                                <React.Fragment key={k}>
                                                                    {trigger(sub_menu)}
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    })
                                                }
                                            </ul>
                                        </Grid.Column>
                                    </Grid>
                                </Form>
                            </Grid.Column>
                            );
                        }
                    }
                    )
                }
</Grid>    
                );
            }
    
        }
    render() {
        return (
            <React.Fragment>
                <div className="common-forms common-forms-add-flex">
                    <Header as='h3' dividing textAlign='left' id="applicationPageTitleSticky">
                        Dashboard
                    </Header>
                    <div className="auto-height-patient-full-details-scroll" id="scrollable-content-area">
                        
                            {this.render_new_menu()}

                       
                    </div>

                </div>

            </React.Fragment>
        );
    }
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            authentication: user_authentication,
            menu_permission: left_menu_permission,
            store_r2_data: store_r2_data
        },
        dispatch
    );
};

const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
        user_details: state.user_login_details,
        shared_details: state.shared_details,
        patient_details: state.patient_details,
        rsiB26337ConvertAdjustmentPages: get_lauch_darkley_key_value(state.launch_darkly, "rsiB26337ConvertAdjustmentPages"),
        rsiB26337ConvertR2AdjustmentPages: !get_lauch_darkley_key_value(state.launch_darkly, "rsiB26337ConvertAdjustmentPages"),
        rsiB26251ConvertManageConstants: get_lauch_darkley_key_value(state.launch_darkly, "rsiB26251ConvertManageConstants"),
        rsiB28566ConvertMergeAccountsPage: get_lauch_darkley_key_value(state.launch_darkly, "rsiB28566ConvertMergeAccountsPage"),
        rsiB28096ConvertStatusCategoryPageSearch: get_lauch_darkley_key_value(state.launch_darkly, "rsiB28096ConvertStatusCategoryPageSearch"),
        rsiB28091ConvertStatusGroupCodePageSearch: get_lauch_darkley_key_value(state.launch_darkly, "rsiB28091ConvertStatusGroupCodePageSearch"),
        tfB28776BillingFailedChargeRules: get_lauch_darkley_key_value(state.launch_darkly, "tfB28776BillingFailedChargeRules"),
        tfB29284BillingFailedChargesReport: get_lauch_darkley_key_value(state.launch_darkly, "tfB29284BillingFailedChargesReport"),
        rsiB29037ConvertOpenZeroPayment: get_lauch_darkley_key_value(state.launch_darkly, "rsiB29037ConvertOpenZeroPayment"),
        rsiB28087ConvertStatusCodePageSearch: get_lauch_darkley_key_value(state.launch_darkly, "rsiB28087ConvertStatusCodePageSearch"),
        rsiB28381ConvertAddEditPayerRulesPageSearch: get_lauch_darkley_key_value(state.launch_darkly, "rsiB28381ConvertAddEditPayerRulesPageSearch"),
        rsiB28983ConvertDemandClaimsPage: get_lauch_darkley_key_value(state.launch_darkly, "rsiB28983ConvertDemandClaimsPage"),
        rsiB29084FormatterHomePageUpdates: get_lauch_darkley_key_value(state.launch_darkly, "rsiB29084FormatterHomePageUpdates"),
		rsiB29536Dashboard: get_lauch_darkley_key_value(state.launch_darkly, "rsiB29536Dashboard"),
		leviathanBill542StreamlineClaimHoldsSummary: get_lauch_darkley_key_value(state.launch_darkly, FFConstant.leviathan_streamline_claim_holds)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardComponent);        