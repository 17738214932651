import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastr as toaster } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { Grid, Table, Header, GridColumn, Form } from 'semantic-ui-react';
import * as local_storage from '../../shared/local_storage_utility';
import * as global_constants from '../../global_constants';
import { get_error_correction_payment_details, insurance_by_charge, get_adjustment_reason } from '../action/payment_action';
import { get_adjustment_code, get_references } from '../batch_payment/action/batch_payment_action';
import { option_formater_for_multiple_column, set_order_data } from '../../shared/utility';
import * as constants from '../../admin/constants/constants';
import * as session_storage from '../../shared/session_storage_utility';
import ErrorCorrectionPaymentDetailsRowComponent from './error_correction_payment_details_row_component';
import { payment_message } from '../payment_constants';

export class ErrorCorrectionPaymentDetailsComponent extends React.Component<any, any> {

    list_data: any = [];
    rowNodes: any;

    constructor(props: any) {
        super(props);
        this.state = {
            is_loading: false,
            is_submitted: false,
            payments: [],
            payment_type: [],
            payment_code: [],
            adjustment_code: [],
            adjustment_qualifier: [],
            adjustment_reason: [],
            insurance_code: [],
            payment_code_list: [],
        };

        this.rowNodes = [];
        this.list_data = []
    }

    token: any = '';
    _is_mounted = false;
    patient_id : any = null;
    _kx_modifier_id: any;
    no_adjustment_code = 'No Adjustment Code';
    empty_option = { text: ' ', value: ' ' };

    // Fetching the report id and params.
    UNSAFE_componentWillMount = () => {
        var auth_data = local_storage.get('auth_data');
        this.patient_id = session_storage.get('active_patient');
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
    };

    componentDidMount = async () => {
        this._is_mounted = true;
        this.props.onRef(this);
        document.body.classList.add('admin-framework');
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;

        this.get_patient_data();
        this.get_api_data();
    };


    componentWillUnmount = () => {
        this._is_mounted = false;
        this.props.onRef(undefined)
        document.body.classList.remove('admin-framework');
    };

    get_patient_data = () => {
        this.get_payment_details(this.props.location.state.charge_id);
        this.get_data();
    }

    get_active_data = (data) => {
        return data.filter((item) => {
            if (item.is_active) {
                return item;
            }
        })
    }

    /*Dropdown API call*/
    get_api_data = async () => {
        const dropdown_data = {};
        dropdown_data['is_loading_dropdown'] = false;

        let insurance_code = await this.insurance_by_charge();
        dropdown_data['insurance_code'] = insurance_code;
        let tranfer_insurance_code = [this.empty_option, ...insurance_code];
        dropdown_data['tranfer_insurance_code'] = tranfer_insurance_code;

        dropdown_data['adjustment_reason'] = await this.get_adjustment_reason();

        let adjustment_code = await get_adjustment_code(this.token);
        adjustment_code.data.data = set_order_data(this.get_active_data(adjustment_code.data.data), 'adjustment_desc');
        dropdown_data['adjustment_code'] = option_formater_for_multiple_column(
            adjustment_code.data.data,
            'adjustment_code',
            'adjustment_desc',
            'adjustment_code_id',
            true, // to add blank object
            false // to shorted object
        );

        let payment_code_list = [];
        let payment_code_data = [...this.props.user_login_details.master_data.data.payment_code];
        payment_code_data.map((val) => {
            val['description'] = val['payment_code'] + ' - ' + val['payment_desc']
        });
        payment_code_list = set_order_data(this.get_active_data(this.props.user_login_details.master_data.data.payment_code), 'payment_desc');
        dropdown_data['payment_code'] = constants.option_formater(
            payment_code_list,
            'description',
            'payment_id',
            true, // to add blank object
            true // to shorted object
        );

        dropdown_data['payment_type'] = constants.option_formater(
            this.props.user_login_details.payment_methods,
            'text',
            'value',
            true, // to add blank object
            false // to shorted object
        )
        this.setState(dropdown_data);
    }


    get_adjustment_reason = () => {
        return get_adjustment_reason(this.token).then(
            response => {
                if (response.data && response.data.data) {
                    let options = response.data.data.map((val, index) => { return { key: index, value: val.id, text: val.adjustment_reason, data: val } });
                    options.unshift({
                        text: this.no_adjustment_code,
                        value: ' '
                    });
                    return options;
                } else {
                    return [];
                }
            },
            error => {
                return [];
            }
        );
    }


    insurance_by_charge = () => {
        return insurance_by_charge(this.props.location.state.charge_id, this.token).then(
            response => {
                if (response.data && response.data.data) {
                    return response.data.data.map((val, index) => { return { key: index, value: val.insurance_code_id, text: val.insurance_name, data: val } });
                } else {
                    return [];
                }
            },
            error => {
                return [];
            }
        );
    }

    get_data = async () => {
        let res = await this.props.get_references(this.patient_id, this.token, 1);
        let dropdown_data = {
            transfer_insurance_code: constants.option_formater(
                this.props.batch_payment_reference.batch_reference.data[5].refrences,
                'title',
                'value',
                true, // to add blank object
                false, // to shorted object
            ),
            adjustment_qualifier: constants.option_formater(
                this.props.batch_payment_reference.batch_reference.data[4].refrences,
                'value',
                'value',
                true, // to add blank object
                true, // to shorted object
                'NA'
            )
        };

        if (this._is_mounted) {
            this.setState({ ...dropdown_data });
        }
    };


    get_payment_details = (charge_id) => {
        get_error_correction_payment_details(charge_id, this.token).then(res => {
            if (res.data.data && this._is_mounted) {
                this.setState({
                    payments: res.data.data || []
                });
            }
        })
    }

    //Get data retruns additional payments data list.
    get_data_list = () => {
        let row_items = [];
        let is_any_invalid = false;
        this.rowNodes.map((node) => {
            if (node && node.is_data_unsaved()) {
                if (node.is_valid() == true) {
                    row_items.push(node.get_updated_data());
                } else {
                    is_any_invalid = true;
                }
            }
        });

        if (is_any_invalid) {
            toaster.error('', payment_message.required_field);
            this.setState({
                is_submitted: true
            });
        }
        return { is_invalid: is_any_invalid, row_items }
    };

    on_data_change = (data, index) => {
        this.list_data[index] = data;
    }

    render_header = () => {
        return (<Table.Header>
            <Table.Row>
                <Table.HeaderCell style={{ width: '65px', minWidth: '65px' }}>To Insurance</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '65px', minWidth: '65px' }}>To Patient</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '65px', minWidth: '65px' }}>Delete</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '155px', minWidth: '155px' }}>Deposit Date</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '140px', minWidth: '140px' }}>From Ins Code</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Payment Code</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Payment Amount</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Adjustment Code</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Adjustment Amount</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Transfer Amount</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Transfer Detail Amount</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Transfer Ins Code</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Payment Type</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '215px', minWidth: '215px' }}>Check Number</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>To Charge</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '140px' }}>Adjustment Qualifier</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '200px', minWidth: '200px' }}>Adjustment Reason</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Batch Number</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Modified By</Table.HeaderCell>
                <Table.HeaderCell style={{ width: '136px', minWidth: '136px' }}>Created By</Table.HeaderCell>
            </Table.Row>
        </Table.Header>);
    }

    render_body = () => {
        const auth_data = local_storage.get("auth_data");
        let accrual_flag = false;
        let open_month = null;
        if (auth_data) {
            accrual_flag = auth_data.accrual_accounting || false;
            open_month = auth_data.open_month || null;
        }

        return this.state.payments.map((row, index) => {
            return (<ErrorCorrectionPaymentDetailsRowComponent
                key={index}
                data={row}
                rowNum={index}
                onRef={ref => (this.rowNodes[index] = ref)}
                onDataChange={this.on_data_change}
                isSubmitted={this.state.is_submitted}
                insuranceCode={this.state.insurance_code || []}
                tranferInsuranceCode={this.state.tranfer_insurance_code || []}
                paymentCode={this.state.payment_code || []}
                adjustmentCode={this.state.adjustment_code || []}
                paymentType={this.state.payment_type || []}
                adjustmentQualifier={this.state.adjustment_qualifier || []}
                adjustmentReason={this.state.adjustment_reason || []}
                accrualFlag={accrual_flag}
                openMonth={open_month}
            />);
        })
    }

    render() {
        if (this.state.payments && this.state.payments.length > 0) {
            return (<Form autoComplete="off" className="normal-form padd-r-0 error-correction-payments">
                <Grid><GridColumn computer={16}>
                    <div className="left aligned four wide computer five wide tablet column">
                        <p style={{ fontSize: '16px', paddingTop: '16px', paddingLeft: '0px' }}>Payments / Adjustments ( *Designates Case's Current Insurances)</p>
                    </div>
                    <div className='customtable'>
                        <div className='table-responsive' >
                            <Table>
                                {this.render_header()}
                                <Table.Body>
                                    {this.render_body()}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </GridColumn></Grid>
            </Form>);
        }

        return null;
    }
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        get_references: get_references
    }, dispatch);
};
// Get user and login details from store.
const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        batch_payment_reference: state.batch_payment_reference,
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorCorrectionPaymentDetailsComponent));
