import { connect } from 'react-redux';
import * as React from 'react';
import * as constants from '../835_error_exclusions';
import { Button, Dimmer, Grid, Loader, Input, Modal } from 'semantic-ui-react';
import { R6ResponseData, R6ResponseStatus, IDropDownDataDto, IMultiSelectDropDownDataDto, toastr_options } from '../../../../app/global_constants';
import * as action from '../action/835_configuration_action';
import { toastr as toaster } from 'react-redux-toastr';
import { show_html_content_toaster, print_html_data_to_pdf, convert_db_datetime_to_local_string, format_AMPM } from '../../../shared/utility';
import * as session_storage from '../../../shared/session_storage_utility';
import GridView from '../../../shared/component/grid';
import { Position, Drawer } from '@blueprintjs/core';
import DrawerComponent from '../../../shared/component/drawer_component';
import * as local_storage from "../../../shared/local_storage_utility";
import SelectionComponent from '../../../shared/component/selection_component';
import { get_payer_errors, set_payer_error_exclusions } from '../action/constants_action';
import ExclusionReport835Component from './835_exclusion_report_component';
import ReportHeader from '../../../reports/component/report_header';
import { export_835_exclusion_report } from '../utility';
import * as report_constants from '../../../reports/report_constants';
import * as export_print_utility from '../../../reports/util/export_print_utility';
import PromptNavigation from '../../../shared/component/prompt_navigation_component';

export class EditAddErrorExclusions835Component extends React.Component<any, any> {
    [x: string]: any;
    xyz = true;
    _is_mounted = false;
    errors = {};
    page_metadata: any;
    token: string = '';
    _grid_ref = { api: null };
    grid_header_height: number = 0;
    state: constants.IStateTemplate;
    main_display_error_configuration_list: Array<constants.IConfigurationDisplayDataDto> = [];
    display_error_configuration_list: Array<constants.IConfigurationDisplayDataDto> = [];
    current_display_list: Array<constants.IConfigurationDisplayDataDto> = [];
    current_page = 1;
    default_page_size = 12;
    selected_exclusions: any = [];
    existing_exclusions: any = [];
    pagination_data: { row_count: 15 };
	footer_message: string = '';
	db_save_date: string = '';
    selected_message: string = constants.default_excluded;
    go_next: boolean = false;

    constructor(props: any) {
        super(props);

        // Define initial state value.
        this.state = {
            // save variables
            show_save_alert: false,
            save_btn_disabled: true,
            save_on_error_selection_change: false,
            ruleLayer: '',
            adjustCode: '',
            is_saving: false,
            disable_print_and_export: false,
            unsaved_changes: false,

            // error selection variables
            error_descriptions: null,
            selected_error: [{
                payerId: 0,
                errorCode: '0',
                name: constants.not_selected,
                ruleLayer: ''
            }],
            new_selection: [],
            revert_selection: false,
            defaultValue: constants.not_selected,

            // other
            is_loading: {
                search_data: false
            },
            report_data_loading: false,
            grid_rows_count: 0,
            pagination_data: { row_count: 15 },
            current_page: 1,
			footer_message: '',
			db_save_date: '',
            selected_message: constants.default_excluded,
            refresh_report: false,
            return_selected: false,
            items_per_page: this.default_page_size,
            export_data: []
        };
    }

    UNSAFE_componentWillMount = () => {
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.page_metadata = constants.error_exclusions;
    };

    componentDidMount = () => {
        this._is_mounted = true;
        document.body.classList.add('admin-framework');
        this.set_error_descriptions();
        this.search_handler();
    };

    componentWillUnmount = () => {
        this._is_mounted = false;
        document.body.classList.remove('admin-framework');
    };

    //Function used to Run Report based on controllers values.
    cancel_handler = () => {
        this.props.history.goBack();
    };

    save_handler = async () => {
        //failure in react to set save button state requires raw javascript call 
        var element = document.getElementById('search_button_id');
        element.setAttribute('disabled', 'disabled');
        let do_save = !this.is_save_btn_disabled();
        this.setState({
            disable_print_and_export: true,
            is_save_btn_disabled: true
        });
        try {
            if (do_save) {
                this.setState({
                    report_data_loading: true
                });
                this.send_save_data();
            }
        } catch (e) {
            console.log(e.message);
            this.show_toaster(e.message);
            this.setState({
                disable_print_and_export: false,
                is_save_btn_disabled: false
            });
        }
    }

    update_payload = () => {
        if (this.state.selected_error.length > 0) {
            this.setState({
                ruleLayer: this.state.selected_error[0].ruleLayer,
                adjustCode: this.state.selected_error[0].errorCode
            });
        }
    }

    send_save_data = async () => {
        this.setState({
            disable_print_and_export: true
        });
        let payerDetailIds = []
        this.selected_exclusions.forEach(payer => {
            payerDetailIds.push(payer.payer_detail_id || 0);
        });

        try {
			var dateUpdated = await set_payer_error_exclusions(this.token, this.state.adjustCode, this.state.ruleLayer, payerDetailIds);
        } catch (error) {
            this.show_toaster('Unable to save. Please retry.');
            var element = document.getElementById('search_button_id');
            element.removeAttribute('disabled');
            this.setState({
                refresh_report: false,
                return_selected: false,
                show_save_alert: false,
                save_on_error_selection_change: false,
                is_saving: false,
                disable_print_and_export: false,
                report_data_loading: false,
				is_save_btn_disabled: false,
				footer_message: ' '
            });
			this.update_save_status(this.state.selected_error, this.state.selected_error);
			this.footer_message = ' ';
            return;
        }

        this.update_payload();
        this.selected_exclusions = [];

        
        var first = this.props.user_login_details.user_data.data.first_name;
        var last = this.props.user_login_details.user_data.data.last_name;	

        this.setState({
            refresh_report: true,
            return_selected: false,
            show_save_alert: false,
            save_on_error_selection_change: false,
            is_saving: false,
            disable_print_and_export: false
        });
        this.show_toaster('');
    }

    render_save_message() {
        return (
            <div>
                Boom! Exclusions for {this.state.selected_error[0].name}  have been updated
                    </div>
        )
    }

    show_toaster = (error_description) => {
        if (error_description != '') {
            toaster.error('', error_description);
        } else {
            let toasterOptions = {
                timeOut: toastr_options.toastr_time_out + 3000,
                component: () => (
                    <div>
                        Boom! Exceptions for {this.state.selected_error[0].name}  have been updated
                    </div>
                )
            };
            toaster.success('', toasterOptions);
        }
    }

    is_loading(): boolean {
        return (this.state.is_loading.search_data);
    }

    on_row_double_clicked = (selected_row_data) => {
        return false;
    };


    search_handler = async () => {
        let search_response_data: Array<constants.IConfigurationSearchDataDto> = [];
        let state_read: constants.IStateTemplate = this.state;

        state_read.is_loading.search_data = true;
        this.setState({ is_loading: state_read.is_loading });


        await action.search_configuration(this.current_page, this.state.items_per_page, this.token).then(
            (response: R6ResponseData) => {
                if (response && response.data && response.data.data) {
                    search_response_data = response.data.data;
                    this.main_display_error_configuration_list = this.get_grid_display_data(search_response_data);
                    this.display_error_configuration_list = this.main_display_error_configuration_list;
                    state_read.is_loading.search_data = false;
                    this.setState({ is_loading: state_read.is_loading })
                }
            },
            (error) => {
                state_read.is_loading.search_data = false;
                this.setState({ is_loading: state_read.is_loading });
                const toastr_options = show_html_content_toaster(error.message);
                toaster.error("", toastr_options);
            }
        );
    }

    get_grid_ref = (grid_params) => {
        this._grid_ref = grid_params;
    };

    get_grid_display_data = (raw_data: Array<constants.IConfigurationSearchDataDto>): Array<constants.IConfigurationDisplayDataDto> => {
        if (!this._is_mounted) return;

        var data_list: Array<constants.IConfigurationDisplayDataDto> = [];
        var item: constants.IConfigurationDisplayDataDto;
        raw_data.map((data_item) => {
            item = {
                payer_detail_id: data_item.payerDetailId,
                payerId: data_item.payerId,
                payer_code: data_item.payerCode,
                description: data_item.description,
                outbound_id: data_item.outboundIdList,
                inbound_id: data_item.inboundIdList,
                is_active: data_item.isActive,
                row_count: 15
            };
            data_list.push(item);
        });
        return data_list;
    }

    getRowHeight = () => 33;

    is_save_btn_disabled = () => {
        if (this.state.selected_error[0].name == constants.not_selected || (this.selected_exclusions.length == 0 && this.existing_exclusions.length == 0) || this.check_existing_exclusions() == true) {
            this.setState({
                save_btn_disabled: true
            })
            return true;
        }
        else {
            this.setState({
                save_btn_disabled: false
            })
            return false;
        }
    }

    on_check_box_selection_flood_timout_id: any = null;
    on_check_box_selection = (items) => {
        if (this.state.selected_error[0].name == constants.not_selected && items.length > 0) {
            let gridRef = this._grid_ref;
            gridRef.api.deselectAll();
            toaster.error('', constants.no_error_selected);
            return;
        }
        this.selected_exclusions = items;
        this.selected_message = this.selected_exclusions.length > 0 ? this.selected_exclusions.length + constants.number_excluded : constants.default_excluded;

        if (this.on_check_box_selection_flood_timout_id != null) {
            clearTimeout(this.on_check_box_selection_flood_timout_id);
        }
        this.on_check_box_selection_flood_timout_id = setTimeout(() => {
            this.on_check_box_selection_flood_timout_id = null;
            this.setState({ return_selected: false });
            this.is_save_btn_disabled();
            this.check_unsaved_status(this.selected_error);
        }, 100);

    }

    on_pagination_change = (start_index, end_index) => {
        this.current_display_list = this.display_error_configuration_list.slice(start_index, end_index);
        //To scroll top and left with every page change
        var element = document.getElementById(this.page_metadata.id);
        element.scrollTop = 0;
        element.scrollLeft = 0;
        const data = this.current_display_list.splice(0, 15);
        if (this._is_mounted) {
            this.setState({
                current_display_list: data,
            });
        }
    }

    set_error_descriptions = async () => {
        get_payer_errors(null, this.token).then(response => {
            this.errors[constants.not_selected] = [];
            this.errors[constants.not_selected].push({
                errorCode: "0",
                name: constants.not_selected,
                payerId: 0,
                ruleLayer: ""
            })
            let errors = response.data.data;
            let error_descriptions = [{
                payer_id: 0,
                text: constants.not_selected,
            }];
            errors.forEach(error => {
                let text = error.name;

                //  The same error text can apply to multiple payer errors,
                //  so to ensure all the payers are included, errors are just
                //  pushed into an array that is the value of a dictionary keyed
                //  on the selection text.
                if (this.errors[text] == null) {
                    this.errors[text] = [];
                }
                this.errors[text].push(error);
                error_descriptions.push({ text: text, payer_id: error.payerId });
            });
            this.setState({
                error_descriptions: error_descriptions
            });
        });
    }

    check_existing_exclusions = () => {
        let count = 0;

        this.selected_exclusions.map(item => {
            if (this.existing_exclusions.indexOf(item.payer_code) != -1) {
                count++;
            } 
        });
        return (this.selected_exclusions.length == this.existing_exclusions.length) && (count == this.existing_exclusions.length);
    }

    on_error_description_change = (value, event) => {

        this.is_save_btn_disabled();

        let new_selection = this.errors[value];

        // save options if error selection is changed after save
        if (this.state.save_on_error_selection_change) {
            this.setState({ is_saving: true, report_data_loading: true });
            this.send_save_data();
            // will need to be called after save to preserve state for save
            this.update_save_status(new_selection, this.state.selected_error);
        }
        else {
            // reset save status and set current error to state
            this.update_save_status(new_selection, this.state.selected_error);
        }

        // reset save status and set current error to state
        this.setState({
            ruleLayer: new_selection.length > 0 ? new_selection[0].ruleLayer : this.state.selected_error[0].ruleLayer,
            adjustCode: new_selection.length > 0 ? new_selection[0].errorCode : this.state.selected_error[0].errorCode,
        });

        // filter payer list on payer specific errors
        if (value == constants.not_selected) {
            //clear selected payers
            this.display_error_configuration_list = this.main_display_error_configuration_list;
        } else {
            let payerIds = this.errors[value][0].payerIds == null ? [] : this.errors[value][0].payerIds.filter(f => f != 0);
            if (payerIds.length > 0) {
                this.display_error_configuration_list = this.main_display_error_configuration_list.filter(f => payerIds.indexOf(f.payerId) != -1);
            }
            else {
                this.display_error_configuration_list = this.main_display_error_configuration_list;
            }
        }
        this.check_unsaved_status(new_selection);
    }

    check_unsaved_status = (new_selection) => {
        if (new_selection) {
            //check for unsaved status
            //putting a one second timer here because state does not change fast enough for unsaved changes pop-up
            setTimeout(() => {
                this.is_save_btn_disabled();
                if ((this.state.selected_error != new_selection && this.state.selected_error[0].name != constants.not_selected) && this.selected_exclusions.length > 0 && !this.state.is_saving && !this.state.save_btn_disabled) {
                    this.setState({
                        show_save_alert: true,
                        defaultValue: new_selection[0].name,
                        new_selection
                    });
                }
                else {
                    this.setState({
                        return_selected: true,
                        selected_error: new_selection
                    });
                }
            }, 1000);
        }
    }

    update_save_status = (current_selection, old_selection) => {
        // reset save status and set current error to state
        this.setState({
            ruleLayer: current_selection.length > 0 ? current_selection[0].ruleLayer : old_selection[0].ruleLayer,
            adjustCode: current_selection.length > 0 ? current_selection[0].errorCode : old_selection[0].errorCode
        });
    }

    on_error_description_change_cancel = () => {
        let oldError = this.state.selected_error[0].name;
        this.setState({
            show_save_alert: false,
            defaultValue: oldError,
            revert_selection: true,
        });
    }

    on_error_description_change_continue = () => {
        this.setState({
            selected_error: this.state.new_selection,
            show_save_alert: false,
            revert_selection: false,
            return_selected: true
        });
    }

	on_print = () => {
        let print_metadata = `<div>
        Error Exclusion Report<br/>
        ${this.props.user_login_details.user_data.data.company_name}<br/>
        ${new Date().toLocaleString()}<br/>
        ${this.state.export_data.length} Errors<br/>
        </div><br/>`;
        let printData = document.getElementById('exclusion-report-835');
        if (printData && printData.outerHTML) {
            print_html_data_to_pdf(print_metadata + printData.outerHTML, 'print_error_exclusions_button', 'Error Exclusions');
        }
        this.saveReportEventAction(report_constants.ReportEventAction.Print);
    }

	on_export = () => {
		export_835_exclusion_report(this.state.export_data, this.props.user_login_details.user_data.data.company_name);
        this.saveReportEventAction(report_constants.ReportEventAction.Export);
    }

    get_export_data = (data) => {
        this.setState({
            export_data: data
        });
    }

    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;

        const payload: report_constants.IPayloadForBillingAudit = {
            reportId: this.reportId,
            contextTitle: `835 Error Exclusion Report - ${report_constants.ReportEventAction[eventActionId]}`,
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: report_constants.ReportEntityTypeId.Error_835_Exclusions
        }

        const reqBody = export_print_utility.generateAuditDataEntryPayload(payload);

        report_constants.saveAuditPrintExport(reqBody, accessToken);
    }

    report_loading = (loading) => {
        this.setState({
            report_data_loading: loading
        });
    }

    // #region Render
    render() {
        return (
            <React.Fragment>
                <PromptNavigation
                    prompt_message={'This action will cause any unsaved data to be lost. Continue?'}
                    no_button_text={'Continue'}
                    hide_yes_button={true}
                    no_button_class_name={'primary'}
                    is_data_changed={!this.state.save_btn_disabled}
                    save={undefined}
                    go_next_location={true}
                    history={this.props.history}
                />
                <Dimmer active={this.state.report_data_loading}>
                    <Loader size="massive">Loading</Loader>
                </Dimmer>
                <div id='admin-scrollable-area' style={error_css.scrollbar_area}>
                    <div style={error_css.common_forms_add}>
                        <div className={'common-forms-add'} >
                            <div className={'common-forms-search report-framework '} style={error_css.common_forms_search}>
                                <div
                                    id='report-scrollable-area'
                                    className='report-wrapper'
                                    style={error_css.report_wrapper}
                                >
                                    <Grid style={error_css.grid}>
                                        <Grid.Column computer={8} >
                                            <div style={error_css.grid_column_div}>
                                                <ReportHeader title={'Edit Exclusions'} />
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column computer={8} textAlign='right' >
                                            <div style={error_css.grid_column_div}>
                                                <Button id='export_error_exclusions_button' disabled={this.state.disable_print_and_export} basic onClick={() => this.on_export()} content='Export Exclusions' />
                                                <Button id='print_error_exclusions_button' disabled={this.state.disable_print_and_export} basic onClick={() => this.on_print()} content='Print Exclusions' />
                                                <Button
                                                    disabled={this.state.save_btn_disabled}
                                                    id='search_button_id'
                                                    type='submit'
                                                    primary style={error_css.save_button}
                                                    onClick={() => this.save_handler()}
                                                    content="Save"
                                                />
                                            </div>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid className="common-forms">
                                        <Grid.Column tablet={10} computer={8} style={error_css.grid_column_left}>
                                            <div style={error_css.grid_column_div_title}>
                                                <label>Error Description</label>
                                                <SelectionComponent
                                                    id='id_error_description'
                                                    hidden={true}
                                                    options={this.state.error_descriptions}
                                                    onChange={this.on_error_description_change}
                                                    defaultValue={this.state.defaultValue}
                                                    revertSelection={this.state.revert_selection} />
                                            </div>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid style={error_css.grid}>
                                        <Grid.Column computer={16} >
                                            <div style={error_css.grid_column_div_center}>
                                                <div style={error_css.grid_column_div_label}>
                                                    <label id='exclusion_selected_message'>{this.selected_message}</label>
                                                </div>
                                            </div>
                                        </Grid.Column>
                                    </Grid>
                                    <GridView
                                        id={constants.grid_settings.grid_id}
                                        style={error_css.gridview}
                                        wrapperStyle={error_css.gridview_wrapper}
                                        wrapperClass={'grid_wrapper'}
                                        row={this.display_error_configuration_list}
                                        column={constants.grid_settings.column_defs}
                                        checkboxSelection={true}
                                        getRowHeight={this.getRowHeight}
                                        suppressMovableColumns={false}
                                        enableColResize={true}
                                        selectionType={'single'}
                                        get_grid_ref={this.get_grid_ref}
                                        gridFullHeight={true}
                                        headerHeight={this.grid_header_height}
                                        emptyMessage={constants.grid_settings.no_records_found_message}
                                        onRowSelection={this.on_check_box_selection}
                                        paginationPageSize={this.state.items_per_page}
                                        isPagination={true}
                                        paginationMessage={' '}
                                        renderTopPager={false}
                                        renderBottomNumericPager={true}
                                        itemsPerPageOptions={[10, 15, 25, 50]}
                                        onPageSizeChange={(option) => {
                                            this.setState({
                                                items_per_page: option
                                            })
                                        }}
                                        numericPagerMessage={this.state.footer_message}
                                        excludePagerSizeSelect={true}
                                    />
                                    <Grid>
                                        <Grid.Column computer={16} >
                                        </Grid.Column>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ExclusionReport835Component
                        refresh_report={this.state.refresh_report}
                        return_selected={this.state.return_selected}
                        selected_error={this.state.selected_error}
                        get_export_data={this.get_export_data}
                        report_loading={this.report_loading}
                        onReportRefreshed={() => this.setState({
                            refresh_report: false
                        })}
						onReportSelectionUpdated={(data) => {							
                            this.existing_exclusions = [];
                            this.setState({
                                save_btn_disabled: true
                            });
                            let gridRef = this._grid_ref;
                            gridRef.api.deselectAll();
                            if (data.selected && data.selected.length > 0) {
                                var payer_codes = data.selected.map(({ payer_code }) => payer_code);
                                var node_payer_codes = [];
                                this.existing_exclusions = payer_codes;
                                gridRef.api.forEachNode((rowNode, index) => {
                                    node_payer_codes.push(rowNode.data.payer_code);
                                    if (payer_codes.indexOf(rowNode.data.payer_code) != -1) {
                                        if (index < gridRef.api.rowModel.rowsToDisplay.length - 1) {
                                            rowNode.setSelected(true);
                                        } else {
                                            rowNode.setSelected(true, false, true);
                                        }
                                    }
                                });
                                this.existing_exclusions = payer_codes;
                                this.selected_exclusions = gridRef.api.getSelectedRows();
                            }
                            if (data.date) {
								let localDateString = convert_db_datetime_to_local_string(data.date);
								this.footer_message = 'Last Updated on ' + localDateString + ' Pacific Time by User ' + data.user;
								this.setState({ footer_message: data ? 'Last Updated on ' + localDateString + ' Pacific Time by User ' + data.user : '', db_save_date: localDateString });
                            } else {
                                this.setState({
                                    footer_message: ' ', db_save_date: ' '
                                });
                            }
                        }
                        }
                    />
                </div>

                <Modal
                    onClose={() => this.on_error_description_change_cancel()}
                    centered={false}
                    className="default-modal"
                    open={this.state.show_save_alert}
                    closeIcon={true}
                    id="show_unsaved_data_loss_warning_id"
                    closeOnDimmerClick={false}>
                    <Modal.Header>
                        <h3>Unsaved Changes</h3>
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <div className="common-forms response-message-poup" style={{ padding: "0" }}>
                                <p>
                                    This action will cause any unsaved data to be lost.  Continue?
                                </p>
                            </div>
                            <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area padd-r-0">
                                <Button id="btnUnsavedWarningCancel" type="button" onClick={this.on_error_description_change_cancel}>
                                    Cancel
                                </Button>
                                <Button id="btnUnsavedWarningContinue" type="button" primary onClick={this.on_error_description_change_continue}>
                                    Continue
                                </Button>
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </React.Fragment>
        );
    }
    // #endregion
}

// Css Style
const error_css = {
    footerArea: {
        paddingLeft: 0,
        paddingBottom: 0,
        marginBottom: 12,
        paddingRight: 25,
        paddingTop: 5
    },
    scrollbar_area: {
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingRight: 15
    } as React.CSSProperties,
    save_button: {
        marginTop: 5,
        marginRight: 10,
        marginBottom: 20
    },
    common_forms_search: {
        paddingRight: '0px'
    },
    common_forms_add: {
        height: '95%'
    },
    report_wrapper: {
        flex: 1,
        marginTop: 10,
        paddingBottom: 25
    },
    grid: {
        marginTop: 0,
        marginBottom: -30
    },
    grid_column_div: {
        marginLeft: '17px',
        marginTop: '10px',
        marginBottom: '10px',
    },
    grid_column_div_label: {
        marginLeft: '17px',
        marginTop: '-5px',
        marginBottom: '25px',
    },
    grid_column_div_title: {
        marginLeft: '17px',
        marginTop: '10px',
        marginBottom: '10px',
    },
    grid_column_div_center: {
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center'
    },
    grid_column: {
        marginTop: -10,
        marginBottom: 10
    },
    grid_column_left: {
        marginTop: -20,
        marginBottom: 10
    },
    gridview: {
        height: '100%'
    },
    gridview_wrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flex: 2,
        paddingBottom: 35
    }

};


const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
        shared_details: state.shared_details,
    };
};

export default connect(mapStateToProps)(EditAddErrorExclusions835Component);
