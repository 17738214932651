import * as global_constants from '../../global_constants';
import { log_error } from '../../shared/action/shared_action';
import * as local_storage from '../../shared/local_storage_utility';
import * as session_storage from '../../shared/session_storage_utility';
import * as login_constants from '../login_constants';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request';

export const user_authentication = (Username, Password) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.login_urls.authenticate}`;
    // To allow the & character in passwords we used the encodeURIComponent to encode the password wrt story D-06478
    var data = "user_name=" + Username + "&password=" + encodeURIComponent(Password);
   // var data = { "user_name": Username, "password": Password };
    var user_name = Username;

    let request = new Request();
    let headers = {        
        'Content-Type': 'application/x-www-form-urlencoded'
    }
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': data
    })

  //  return request.make(request_config)
    return dispatch => request.make(request_config).then(
        response => {

            response.data.data ? (response.data.data["user_name"] = user_name) : null;

            // Set Token Expiration Time
            if (response.data.data && response.data.data.token_details) {
                var expiresTime = new Date();
                expiresTime = new Date(expiresTime.setSeconds(expiresTime.getSeconds() + parseInt(response.data.data.token_details.expires_in)));

                response.data.data.token_details['expires_time'] = expiresTime;
                // set auth data in local storage
                local_storage.set("auth_data", response.data.data);
            }


            dispatch({
                type: login_constants.USER_AUTHENTICATION,
                payload: response
            })
        },
        error => {
            log_error(error)
        }
    );
}

// Refresh Token at regular intervals
export const refresh_token = (data, change_company: boolean = false) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.user_urls.refresh_token}`;

    var old_user_data = local_storage.get("auth_data");
    
    if(data.session_details.session_id != old_user_data.session_details.session_id) {
        data = old_user_data;
        console.log("Mismatched data store data =>", data, ", => old_user_data =>", old_user_data);
    }

    let tokenData = `grant_type=refresh_token&user_name=${data.user_name}
        &user_id=${data.user_id}&refresh_token=${data.token_details.access_token}&company_id=${data.company_id}&user_type_id=${data.user_type_id}&user_type_r3_id=${data.user_type_r3_id}
        &provider_id=${data.provider_id}&provider_type_id=${data.provider_type_id}&session_id=${data.session_details.session_id}&change_company=${change_company}`;

    let user_data = {
        data: data
    };

    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + data.token_details.access_token
    }

    let request = new Request();
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': tokenData
    })
    //local_storage.set("should_queue_request", "true")
    return dispatch =>
        request.make(request_config, true)
            .then(
                response => {
                    if (response.data.status == 1) {
                        // Set Token Expiration Time
                        if (response.data.data.token_details) {
                            var expiresTime = new Date();
                            expiresTime = new Date(expiresTime.setSeconds(expiresTime.getSeconds() + parseInt(response.data.data.token_details.expires_in)));

                            response.data.data.token_details['expires_time'] = expiresTime;

                            // update auth data in local storage
                            const old_user_data = local_storage.get("auth_data");
                            if (response.data.data.session_details.session_id != old_user_data.session_details.session_id) {
                                console.log("Login Action, Get Session Id Mismatch while refreshing token.");
                            }

                            if (old_user_data) {
                                let auth_data = { ...old_user_data }
                                auth_data["token_details"] = { ...response.data.data.token_details };
                                auth_data["change_company"] = change_company;
                                auth_data["online_Patient_payment_Url"] = response.data.data.provider_details.online_Patient_payment_Url;
                                local_storage.set("auth_data", auth_data);

                            }
                            if (change_company) {
                                local_storage.set("wait_for_master_data_update", true)
                               session_storage.set_raw("company_changed", true);
                            }
                            user_data.data.token_details = response.data.data.token_details;
                            user_data.data.session_details = response.data.data.session_details; 
                        }

                        dispatch({
                            type: global_constants.constants.REFRESH_TOKEN,
                            payload: user_data
                        })

                        //resolving requests in pipeline
                        request.deque_pipeline();
                    } else {
                        var old_user_data = local_storage.get("auth_data");
                        old_user_data["token_details"] = null;
                        local_storage.set("auth_data", old_user_data);

                        dispatch({
                            type: global_constants.constants.REFRESH_TOKEN,
                            payload: null
                        })
                    }
                },
                error => {
                    var old_user_data = local_storage.get("auth_data");
                    old_user_data["token_details"] = null;
                    local_storage.set("auth_data", old_user_data);

                    dispatch({
                        type: global_constants.constants.REFRESH_TOKEN,
                        payload: null
                    })
                    log_error(error);
                }
            );
}


export const user_logout = (user_id, token) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.user_urls.logout}`;
    var data = "user_id=" + user_id + "&token=" + token

    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': data
    })
    
    return dispatch => request.make(request_config).then(
        response => dispatch({
            type: login_constants.USER_LOGOUT,
            payload: response
        }),
        error => {
            dispatch({
                type: login_constants.USER_LOGOUT,
                payload: error
            });
            log_error(error)
        }
    );

}


export const clear_cache = () => {

    return {
        type: global_constants.constants.CLEAR_APPLICATION_CACHE,
        payload: {}
    }
}

// Forgot Password API call
export const user_forgot_password = (username) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.user_urls.forgot_password}`;
    var data = "user_name=" + username;

    let request = new Request();
    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': data
    })

    return dispatch => request.make(request_config).then(
        response => dispatch({
            type: login_constants.FORGOT_PASSWORD,
            payload: response
        }),
        error => {
            log_error(error)
        }
    );
}

// validate Forgot Password token API call
export const user_forgot_password_token_validation = (token) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.user_urls.forgot_password_token_validation}`;
    var data = "token=" + token;
    let request = new Request();
    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': data
    })
    return dispatch => request.make(request_config).then(
        response => dispatch({
            type: login_constants.FORGOT_PASSWORD_TOKEN_VALIDATION,
            payload: response
        }),
        error => {
            log_error(error)
        }
    );
}

// Reset Password API call
export const user_reset_password = (new_password, user_id, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.user_urls.reset_password}`;
    var data = { new_password: new_password, token:token, user_id:user_id}
    let request = new Request();
    let headers = {}

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': data
    })
    return dispatch => request.make(request_config).then(
        response => dispatch({
            type: login_constants.RESET_PASSWORD,
            payload: response
        }),
        error => {
            log_error(error)
        }
    );
}

// re-send email for ip enabling
export const re_send_ip_registration_link = (user_name) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.user_urls.send_ip_enabled_email}`;
    var data = "user_name=" + user_name;
    let request = new Request();
    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': data
    })

    return dispatch => request.make(request_config).then(
        response => dispatch({
            type: login_constants.RE_SEND_IP_REGISTRATION_LINK,
            payload: response
        }),
        error => {
            log_error(error)
        }
    );
}

// get master data with cache and cache expire time
export const get_master_data = (type, user_id, token, get_update_data=false) => {
    let url = `${global_constants.base_end_points}${global_constants.end_points.user_urls.master_data}/?type=${type}&user_id=${user_id}`;
    if (get_update_data) {
        url += `&ref=${new Date().getTime()}`
    }
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return dispatch => request.make(request_config).then(
        response => dispatch({
            type: login_constants.MASTER_DATA,
            payload: response
        }),
        error => {
            log_error(error)
        }
    );
}

// get data by type with cache and cache expire time
export const get_master_data_by_type = (type, user_id, token,get_update_data=false) => {
    
    let url = `${global_constants.base_end_points}${global_constants.end_points.user_urls.master_data_by_type}/?type=${type}&user_id=${user_id}`;
    if (get_update_data) {
        url +=`&ref=${ new Date().getTime()}`
    }
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return dispatch => request.make(request_config).then(
        response => dispatch({
            type: login_constants.MASTER_DATA_BY_TYPE,
            payload: response
        }),
        error => {
            log_error(error)
        }
    );
}


//disabled navigation while change_password_required is requred 
export const change_password_required = (is_password_expired) => {
    return dispatch => {
        dispatch({
            type: global_constants.constants.IS_PASSWORD_EXPIRED,
            payload: { is_password_expired: is_password_expired }
        })
    }
}


//disable save prompt when auto logout event is happening
export const auto_logout = (auto_logout) => {
    return dispatch => {
        dispatch({
            type: global_constants.constants.AUTO_LOGOUT,
            payload: { auto_logout }
        })
    }
}
//update payment methods based on FF flag
export const update_payment_method = () => {
    return dispatch => {
        dispatch({
            type: global_constants.constants.UPDATE_PAYMENT_METHOD,
            payload: true 
        })
    }
}

/**
 * maintaining the active patient id
 * meaning of these states is explained in state reducer under condition ACTIVE_PATIENT_ID
 * @param active_patient_id
 */
export const set_active_patient = (active_patient_id) => {
    return dispatch =>
        dispatch({
            type: global_constants.constants.ACTIVE_PATIENT_ID,
            payload: { active_patient_id }
        });
} 

/**
 * @param option 
 * This Function is used by those componetes who want to display prompt on certian places
 * and is not redirected by redux router. 
 * option contains two params {value, stage}
 * value - name of the functionality with respect we have to invoke prompt
 * stage - state of the prompt
 * Stages 
 * invoke - initiate the prompt.
 * ongoing - prompt is open now.
 * continue - everything is done now, after sucess contnue with the pending task.
 */
export const custom_prompt = (option) => {
    return dispatch =>
        dispatch({
            type: global_constants.constants.CUSTOM_PROMPT,
            payload: { option }
        });
} 

/**
 * 
 * @param is_data_saved 
 */
export const unsaved_form_data_for_prompt = (is_data_saved) => {
    return dispatch =>
        dispatch({
            type: global_constants.constants.UNSAVED_FORM_DATA_FOR_PROMPT,
            payload: is_data_saved
        });
} 

/**
 * 
 * @param show_404_page 
 */
export const show_404_page = (show) => {
    return dispatch =>
        dispatch({
            type: global_constants.constants.DISPLAY_404_PAGE,
            payload: show
        });
} 

