import * as global_constants from '../../global_constants';
import { log_error } from '../../shared/action/shared_action';
import * as report_constants from '../report_constants';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request';
import * as api_request from '../../shared/network/request_extensions';
import { generate_guid } from '../../shared/utility';
const cloneDeep = require('lodash/clonedeep');

export const get_ledger_full_report_data = (search_criteria, token) => {
    const url = `${global_constants.base_end_points}${report_constants.end_urls.ledger_full_url}/${search_criteria.patient_id}/${
        search_criteria.start_date
    }/${search_criteria.end_date}/${search_criteria.is_active}`;

    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config).then(response => {
            if (response) {
                return response;
            }
        });
};

export const get_ledger_charges_report_data = (search_criteria, token) => {
    const url = `${global_constants.base_end_points}${report_constants.end_urls.ledger_charge_url}/${search_criteria.patient_id}/${
        search_criteria.charge_id
        }`;

    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config).then(response => {
            if (response) {
                return response;
            }
        });
};

export const get_ledger_payment_adjustment_report_data = (search_criteria, token) => {
    const url = `${global_constants.base_end_points}${report_constants.end_urls.ledger_patient_payment_adjustments_url}/${
        search_criteria.patient_id
    }/${search_criteria.charge_id}`;

    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    

    return request.make(request_config).then(response => {
            if (response) {
                return response;
            }
        });
};

export const get_ledger_claims_report_data = (search_criteria, token) => {
    const url = `${global_constants.base_end_points}${report_constants.end_urls.ledger_claim_url}/${search_criteria.charge_id}`;

    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config).then(response => {
            if (response) {
                return response;
            }
        });
};

export const get_patient_claim_data = (token, charge_id) => {
    const url = `${global_constants.base_end_points}/reports/patient_claims/${charge_id}`;

    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(request_config).then(response => {
            if (response) {
                return response;
            }
        });
};

export const get_charge_report_title = (token, charge_id) => {
    const url = `${global_constants.base_end_points}/reports/charge_report_title/${charge_id}`;

    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })


    return request.make(request_config).then(response => {
            if (response) {
                return response;
            }
        });
};

export const get_patient_payment_adjustments = (token, charge_id) => {
    const url = `${global_constants.base_end_points}/reports/patient_payment_adjustments/${charge_id}`;

    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config).then(response => {
            if (response) {
                return response;
            }
        });
};

// Get Reports metadata action
export const get_report_metadata = (report_id, token) => {
    const url = `${global_constants.base_end_points}${report_constants.end_urls.report_metadata}/${report_id}`;

    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config).then(response => {
            if (response) {
                return response;
            }
        });
};

// Get Reports metadata action
export const get_report_metadata_next_level = (report_id, param, token) => {
    const url = `${global_constants.base_end_points}${report_constants.end_urls.report_metadata_next_level}/${report_id}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'silversurfer': JSON.stringify(param)
    }

    let request_config = new RequestConfig({
      url: url,
      headers: headers,
      method: 'get'
    });

    return request.make(request_config).then((response) => {
      if (response) {
        return response;
      }
    });
};
// Get Reports updated dropdown data action
export const get_updated_dropdown_data = (param, token) => {
    const url = `${global_constants.base_end_points}${report_constants.end_urls.get_updated_dropdown_data}`;

    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'silversurfer': JSON.stringify(param)
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config).then(response => {
            if (response) {
                return response;
            }
        });
};

// Get Release Claim Delay dropdown data action
export const get_release_claims_references = (param, token) => {
    const url = `${global_constants.base_end_points}${report_constants.end_urls.get_release_claims_references}`;

    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'silversurfer': JSON.stringify(param)
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config).then(response => {
            if (response) {
                return response;
            }
        });
};

// Get Reports data action
export const get_report_data = (report_id, param, token) => {
    const url = `${global_constants.base_end_points}${report_constants.end_urls.get_report_data}/${report_id}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'silversurfer': JSON.stringify(param)
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })


    return request.make(request_config).then(response => {
            if (response) {
                return response;
            }
        });
};

export const get_report_data_post = (report_id, param, token) => {
    const url = `${global_constants.base_end_points}${report_constants.end_urls.get_report_data_post}/${report_id}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': param
    })


    return request.make(request_config).then(response => {
            if (response) {
                return response;
            }
        });
};

// Get Sub Reports data action
export const get_sub_report_data = (param, token) => {
    const url = `${global_constants.base_end_points}/reports/sub_report_data`;

    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'silversurfer': param.replace(/^\s+|\s+$|\s+(?=\s)/g, "")
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config)
        .then(response => {
            if (response) {
                return response;
            }
        });
};

export const get_demand_statement_report = (search_criteria, token) => {
    const url = `${global_constants.base_end_points}${report_constants.end_urls.demand_report_full_url}/${search_criteria.patient_id}/${
        search_criteria.start_date}/${search_criteria.end_date}/${search_criteria.is_active}`;

    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })


    return (dispatch, getState) => {
        request.make(request_config).then(
                response => {
                    dispatch({
                        type: report_constants.CONSTANTS.REQUEST_SUCCESS,
                        payload: response.data
                    });
                },
                error => {
                    dispatch({
                        type: report_constants.CONSTANTS.REQUEST_FAILURE,
                        payload: error
                    });
                    log_error(error);
                }
            );
    };
};

export const print_demand_statement = (print_params, token) => {
    const url = `${global_constants.base_end_points}${report_constants.end_urls.print_demand_report_full_url}/${print_params.patientId}/${
        print_params.fromDate
    }/${print_params.thruDate}/${print_params.isZeroBalance}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })


    return request.make(request_config);
};

// Add note to attorney statement printing
export const print_attorney_statement = (param, token) => {
    const url = `${global_constants.base_end_points}${report_constants.end_urls.print_attorney_report_full_url}/${param.patientId}/${param.total_amount_due}/${param.created_by}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })


    return request.make(request_config);
};

// Get Sub Reports data action
export const get_charge_audit_summary = (param, token) => {
    const url = `${global_constants.base_end_points}${report_constants.end_urls.charge_audit_summary_url}?${param}`;

    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'silversurfer': param
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config)
        .then(response => {
            if (response) {
                return response;
            }
        });
};

export const close_eob = (token, list_eob_keys) => {

    const url = `${global_constants.base_end_points}${report_constants.end_urls.close_eob_url}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': list_eob_keys
    })

    return request.make(requestConfig);

}

export const close_unrecognized_eob = (token, eob_key) => {

    let data = {
        'eobkey': + eob_key,
        'clinicCode': 'N/A'
    }
    const url = `${global_constants.base_end_points}${report_constants.end_urls.close_unrecognized_eob_url}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': data
    })

    return request.make(requestConfig);

}

export const update_unrecognized_eob = (token, params) => {
    const url = `${global_constants.base_end_points}${report_constants.end_urls.update_unrecognized_eob_url}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': params
    })
    return request.make(requestConfig);
}

// Get Reports updated dropdown data action
export const get_updated_dependent_dropdown_data = (input_list, report_id, param_id, token) => {
    const url = `${global_constants.base_end_points}/reports/updated_dependent_dropdown_data/${report_id}/${param_id}`;
    if(input_list !== undefined && input_list !== null) {
        Object.keys(input_list).forEach(key => {
            if(!_isNative(input_list[key])) {
                delete input_list[key];                
            }
        });
    }
   
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'silversurfer': JSON.stringify(input_list)
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config);
};

const _isNative = (value) => {
    return value !== Object(value);
};

/**
 * Billing exceptions action to reprocess selected items
 * @param params
 * @param token
 */
export const reprocess_billing_exceptions = (params, token) => {
    const url = `${global_constants.base_end_points}/webpt_integration/rejections/reprocess`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let requestConfig = new RequestConfig({
        url: url,
        headers: headers,
        method: 'post',
        data: params
    });

    return request.make(requestConfig);
}

/**
 * Billing exceptions action to remove selected items
 * @param params
 * @param token
 */
export const remove_billing_exceptions = (params, token) => {
    const url = `${global_constants.base_end_points}/webpt_integration/rejections/remove`;

    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let requestConfig = new RequestConfig({
        url: url,
        headers: headers,
        method: 'put',
        data: params
    });

    return request.make(requestConfig);
}

export const get_eob_header_data = (eob_key, token) => {
    const url = `${global_constants.base_end_points}${report_constants.end_urls.zero_payments}/${eob_key}/eob_header`;

    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

export const get_eob_remarks_data = (eob_key, token) => {
    const url = `${global_constants.base_end_points}${report_constants.end_urls.zero_payments}/${eob_key}/remarks`;

    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

export const get_eob_details_data = (eob_key, token) => {
    const url = `${global_constants.base_end_points}${report_constants.end_urls.zero_payments}/${eob_key}`;

    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

export const update_zero_payment_notes = (params, token) => {
    const url = `${ global_constants.base_end_points }${report_constants.end_urls.zero_payments }`;

    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let requestConfig = new RequestConfig({
        url: url,
        headers: headers,
        method: 'put',
        data: params
    });

    return request.make(requestConfig);
}

// function used for Set company
export const set_company_name = (company: any) => {
  return (dispatch) =>
    dispatch({
      type: report_constants.CONSTANTS.COMPANY_CHANGE,
      payload: company
    });
};

export const reprocess_failed_charge_rule = (request_body, token) => {
    const url = `${global_constants.base_end_points}/reports/failed_charges/reprocess`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let requestConfig = new RequestConfig({
        url: url,
        headers: headers,
        method: 'post',
        data: request_body
    });

    return request.make(requestConfig);
}

export const save_billing_audit = (requestBody, token) => {
    const url = `${global_constants.base_end_points}/reports/billing_audit`;
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };
    let requestConfig = new RequestConfig({
        url: url,
        headers: headers,
        method: 'POST',
        data: requestBody
    });
    let request = new Request();
    return request.make(requestConfig)
        .then(
            response => { },
            error => {
                console.log('Report BillingAudit error, see logs for more information.');
            }
        );
}


/**
 * Get payment adjustment summary
 * @param param
 * @param token
 */
export const get_payment_adjustment_summary = (token, param) => {
    const url = `${global_constants.base_end_points}${report_constants.end_urls.payment_adjustment_summery}/${param.start_date}/${param.end_date}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(requestConfig);
}


/**
 * Get payment adjustment summary
 * @param param
 * @param token
 */
export const get_payment_adjustment_summary_by_pos = (token, param) => {
    const url = `${global_constants.base_end_points}${report_constants.end_urls.payment_adjustment_summery_by_pos}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(param)
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(requestConfig);
}


/**
 * Get summary and forecast report
 * @param param
 * @param token
 */
export const get_summary_and_forecast = (token, param) => {
    const url = `${global_constants.base_end_points}${report_constants.end_urls.summary_and_forecast}/${param.company_id}/${param.month}/${param.year}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(requestConfig);
}

export const get_advanced_search_data = (token, api, param = {}) => {
    const url = `${global_constants.base_end_points}${api}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        silversurfer: JSON.stringify(param)
    };

    let requestConfig = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });
    return request.make(requestConfig);
}

export const get_835_error_exclusions_report = (param, token) => {
    //debugger;
    const url = `${global_constants.base_end_points}${report_constants.end_urls.error_835_exclusions}/${param.company_id}`;

    return api_request.get_request(url, token);
    //return api_request.get_config_request(url, token, cloneDeep({ 'params': { rnd: generate_guid() } }));
};