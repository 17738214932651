import * as React from 'react';
import { Dimmer, Loader, Modal } from 'semantic-ui-react';
import { handle_focus_on_tab, set_focus_on_element_with_id } from '../tab_navigation_utility';
import GridView from './grid';
import SearchCriteria from './search_criteria_component';

class SearchModalComponent extends React.Component<any, any> {
    [x: string]: any;
    constructor(props) {
        super(props);
        this.state = {
            rowData: null,
            showModal: this.props.showModal,
            errorMessage: '',
            loading: false
        };
    }
    UNSAFE_componentWillMount() {
        this.setState({ show_modal: this.props.showModal });
        //this.resetComponent()
    }

    onClearFilters = () => {
        this.setState({ rowData: null, errorMessage:''});
        this.props.onClearClick();
    }

    onSearch = (obj) => {
        const updateData = data => {
            this.setState({ rowData: data });
        };
        this.setState({
            loading: true
        })
        this.props.onSearchClick(obj).then(response => {
            //updateData(JSON.parse(response.data.data));
            if (response.data.data) {
                if (response.data.data == null) {
                    this.setState({
                        rowData: [],
                        loading: false
                    });
                }
                else if (response.data.data.length <= 0) {
                    this.setState({
                        rowData: [],
                        loading: false
                    });
                }
                else {
                    this.setState({
                        rowData: response.data.data,
                        errorMessage: '',
                        loading: false
                    });
                }                
            }            
            else {
                this.setState({
                    rowData: [],
                    loading: false
                });
            }
        });

    }

    onSearchTab = (event) => {

        if (!this.state.rowData) {
            handle_focus_on_tab(event, "search_criteria_modal_close_icon");
        }
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.showModal !== this.props.showModal) {

            if (this.props.showModal) {
                this.handle_on_open_modal_window();
            }
            this.setState({ showModal: this.props.showModal })
        }
    }
    //close the modal on selection of patient through quick search
    closeModal = () => {
        //if open modal using application header

        this.setState({ showModal: false, rowData: null, errorMessage: '' });
        this.props.onCloseModal();

    };
    onSelection = (selectedRows) => {
        //this.props.onGridRowSelection(selectedRows);
        //this.closeModal();
    }
    onDoubleClick = (selectedRows) => {
        this.props.onGridRowSelection(selectedRows);
        this.closeModal();
    }

    handle_on_open_modal_window = () => {
        setTimeout(() => {
            let closeIcons: NodeListOf<HTMLElement> = document.querySelectorAll('i.close.icon') as NodeListOf<HTMLElement>;
            let closeIcon = closeIcons[closeIcons.length - 1]
            closeIcon.tabIndex = 0;
            closeIcon.id = "search_criteria_modal_close_icon";
            closeIcon.addEventListener("keydown", function (event) {
                if (event.shiftKey && event.keyCode == 9) {
                    event.preventDefault();
                    set_focus_on_element_with_id("search_criteria_button_search");
                }

                if (event.keyCode == 13) {
                    event.preventDefault();
                    var el = event.target as HTMLElement
                    el.click();
                }
            });
        }, 200)
    }
    on_grid_out = () => {
        set_focus_on_element_with_id("search_criteria_modal_close_icon");        
    }

    render() {

        const { columnData, rowData, showModal, errorMessage } = this.state;

        return (
            <div className="item" >
                <Modal
                    onClose={this.closeModal}
                    centered={false}
                    className="searchCompany modal_container"
                    open={showModal}
                    closeIcon
                    closeOnDimmerClick={false}
                    style={{ top: '12px' }}
                >
                    <Modal.Header>{this.props.modal_config.title}</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                        <Dimmer active={this.state.loading}>
                            <Loader size='massive'>Loading</Loader>
                        </Dimmer>
                            <SearchCriteria onSearch={this.onSearch} onSearchTab={this.onSearchTab} onClear={this.onClearFilters} onCancel={this.closeModal} />
                            <span className='ErrorPatient'>{errorMessage}</span><br></br>
                            {rowData && <GridView
                                id={'grid_id_change_company'}
                                row={rowData}
                                column={this.props.modal_config.column_defs}
                                paginationPageSize={this.props.modal_config.pagination_size}
                                isPagination={true}
                                gridAutoHeight={true}
                                selectionType={'single'}
                                onRowSelection={this.onSelection}
                                onRowDoubleClicked={this.onDoubleClick}
                                onGridOut={this.on_grid_out}
                                headerIdForTabNavigation={this.props.gridHeaderNavigation}/>
                            }
                        </Modal.Description>

                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

export { SearchModalComponent };

