import * as React from 'react';
import Release835sComponent from './release_835s_component';
import Release835sFFComponent from './release_835s_ff_component';

function Release835sReRouteComponent(props) {
	const release835ssFF = props.release835sFF;
	if (release835ssFF) {
		return <Release835sFFComponent />;
	}
	return <Release835sComponent />;
}

export default Release835sReRouteComponent;