import * as React from "react";
import { clearTimeout, setTimeout } from 'timers';
import { get_columns, get_footer_content_width, print_html_data_to_pdf } from "../../shared/utility";
import * as report_constants from "../report_constants";
import GridView from "./../../shared/component/grid";
import { set_focus_on_element_with_id } from './../../shared/tab_navigation_utility';

// prepare print content
const print_html_data = (state) => {
    var header_data = report_constants.report_demand_statement_open_charges.column_defs
    var payment_log_header_names = report_constants.report_demand_statement_payment_log.column_defs;
    const ledgers = state.open_charge_grid_ref.api;
    const payments = state.pay_log_grid_ref.api;
    let company_info = document.getElementById('company_info').outerHTML;
    company_info = company_info.replace(/<h4>/g, '<div style="font-size:16px;margin: 10px 0;">').replace(/<\/h4>/g, "</div>");
    const check_info = document.getElementById('check_info').outerHTML;
    const footer_patient_info = document.getElementById('footer_patient_info').outerHTML;
    var title = '<div style="font-weight: bold;font-size:18px">Patient Ledger</div>';
    var printContent = "";
    //var printContent = '<html><head><style>.default-table-aggrid td.norecords{text-align:center}.onprint-divider{border-top: 2px solid #333333;padding-top:10px;}.check-pay-print img { width: 28px; vertical-align: middle; }.check-pay-print { border: 1px solid #cccccc; border-bottom: none; padding:0 10px 10px 10px; margin-bottom: 0px; }.default-table,.default-table-aggrid{width:100%;border-spacing:0;line-height:1.2}html{font:11pt sans-serif}*{box-sizing:border-box;-webkit-box-sizing:border-box}.check-pay-container{width:100%;display:inline-block;line-height:1.2}.check-pay-container>div:first-child{float:left}.check-pay-container>div:last-child{float:right}.default-table{font-size:12px}.default-table tbody td,.default-table thead th{border:1px solid #ccc;padding:10px;text-align:left}.default-table-aggrid{font-size:12px}.right.aligned{text-align:right!important}.default-table-aggrid tbody td,.default-table-aggrid thead th{border:1px solid #ccc;padding:8px;font-size:12px;text-align:left}.ui.checkbox{display:inline-block;font-size:12px}.check-pay-container h4,.check-pay-container.ui label{color:#333!important;font-size:16px!important}.check-pay-container>div{width:49%;display:inline-block;vertical-align:top}.check-pay-container .payable-section{background:#fff;border:0!important;padding:10px 15px 15px;margin-bottom:20px}.check-pay-container h4{margin:10px 0}.check-pay-container p{margin-top:5px;font-size:12px!important;margin-bottom:5px!important}.check-pay-container b{font-weight:800!important}.check-pay-container.cc{font-size:21px!important;vertical-align:top}</style></head><body>';

    printContent += '<div class="demand-report">' + title + '<div class="check-pay-container">' + company_info + check_info + '</div>';
    var title = "<div style='font-weight: bold;font-size:16px'>Patient Open Charges </div>";
    printContent += title + '<div><table class="default-table-aggrid"><thead><tr>';

    header_data.map((value) => {
        let class_name = (value.type == 'currency') ? 'class="right aligned"' : '';
        printContent += '<th ' + class_name + '>' + value.headerName + '</th>';
    });
    printContent += "</tr></thead><tbody>";
    if (ledgers.getModel().getRowCount() > 0) {
        ledgers.forEachNodeAfterFilterAndSort((data) => {
            printContent += '<tr>';
            if (data) {
                header_data.map((colData, index) => {
                    let class_name = (colData.type == 'currency') ? 'class="right aligned"' : '';
                    printContent += '<td ' + class_name + ' >' + data.data[colData.field] + '</td>';

                });
            } else {
                printContent += '<td>' + report_constants.messages.no_records_found + '</td>';
            }
            printContent += '</tr>';
        })

        var footer_obj = ledgers.getPinnedBottomRow(0).data;
        var footer_cell;
        printContent += '<tr class="footer">';
        header_data.map((colData, index) => {
            let class_name = (footer_obj[colData.field] == 'Total') ? '' : 'class="right aligned"';
            footer_cell = footer_obj[colData.field] ? footer_obj[colData.field] : "";
            printContent += '<td ' + class_name + '>' + footer_cell + '</td>';
        }
        );
        printContent += '</tr>';
    } else {
        printContent += '<tr><td  colspan="100%" class="norecords">' + report_constants.messages.no_records_found + '</td></tr>';
    }
    printContent += '</tbody></table></div>';

    var title = '<br><div style="font-weight: bold;font-size:16px">Patient Payment Log</div>';
    printContent += title;
    printContent += '<div><table class="default-table-aggrid"><thead><tr>';
    payment_log_header_names.map((value) => {
        let class_name = (value.type == 'currency') ? 'class="right aligned"' : '';
        printContent += '<th ' + class_name + '>' + value.headerName + '</th>';
    });
    printContent += "</tr></thead><tbody>";
    if (payments.getModel().getRowCount() > 0) {
        payments.forEachNodeAfterFilterAndSort((data) => {
            printContent += '<tr>';
            if (data) {
                payment_log_header_names.map((colData, index) => {
                    let class_name = (colData.type == 'currency') ? 'class="right aligned"' : '';
                    printContent += '<td ' + class_name + '>' + data.data[colData.field] + '</td>';

                });
            }
            printContent += '</tr>';
        })

        var footer_obj = payments.getPinnedBottomRow(0).data;
        var footer_cell;
        printContent += '<tr class="footer">';
        payment_log_header_names.map((colData, index) => {
            let class_name = (footer_obj[colData.field] == 'Total') ? '' : 'class="right aligned"';
            footer_cell = footer_obj[colData.field] ? footer_obj[colData.field] : "";
            printContent += '<td ' + class_name + '>' + footer_cell + '</td>';
        }
        );
        printContent += '</tr>';
    } else {
        printContent += '<tr><td  colspan="100%" class="no_records">' + report_constants.messages.no_records_found + '</td></tr>';
    }
    printContent += '</tbody></table></div><br><br>';
    printContent += footer_patient_info;
    printContent += '</div>';
    return printContent;
}

class DemandStatementReportGrid extends React.Component<any, any> {
    _is_mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            patient_open_charges: null,
            patient_payment_log: null,
            open_charge_footer_object: null,
            payment_log_footer_object: null,
            pay_log_grid_ref: {},
            open_charge_grid_ref: {},
            open_charges_columns: report_constants.report_demand_statement_open_charges.column_defs.map(col => ({ ...col }))
        };
    }
    patient_open_charges = [];
    patient_payment_log = [];
    report_container: any;
    total_open_charges_col_width: any;
    grid_open_charges_header_height: number = 35;
    total_payment_log_col_width: any;
    grid_payment_log_header_height: number = 35;
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.rows && this.props.rows.Ledgers) {            
            this.patient_open_charges = this.props.rows.Ledgers.map(object => {
                return { ...object };
            })
        }
        if (this.props.rows && this.props.rows.Ledgers) {           
            this.patient_payment_log = this.props.rows.Payments.map(object => {
                return { ...object };
            })            
        }
        this.row_data_formatter(this.patient_open_charges, 'open_charge', this._is_mounted);
        this.row_data_formatter(this.patient_payment_log, 'payment_log', this._is_mounted);

    }
    componentWillUnmount = () => {
        this._is_mounted = false;
        window.removeEventListener("resize", this.on_screen_change);
        if (this.report_container) {
            this.report_container.removeEventListener("scroll", this.on_scroll_change);
        }
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);

        }
    }
    componentDidMount() {
        this._is_mounted = true;  
        if (this.props.rows && this.props.rows.Ledgers) {
            this.patient_open_charges = this.props.rows.Ledgers.map(object => {
                return { ...object };
            });
            let open_charges_columns = report_constants.report_demand_statement_open_charges.column_defs.map(col => ({ ...col }))
            const grid_height = get_columns(this.patient_open_charges, open_charges_columns);
            this.total_open_charges_col_width = grid_height.total_column_width;
            this.grid_open_charges_header_height = grid_height.header_height;
            this.setState({ open_charges_columns });
        }
        if (this.props.rows && this.props.rows.Ledgers) {
            this.patient_payment_log = this.props.rows.Payments.map(object => {
                return { ...object };
            });

            const grid_height = get_columns(this.patient_payment_log, report_constants.report_demand_statement_payment_log.column_defs);
            this.total_payment_log_col_width = grid_height.total_column_width;
            this.grid_payment_log_header_height = grid_height.header_height;
        }
        this.row_data_formatter(this.patient_open_charges, 'open_charge');
        this.row_data_formatter(this.patient_payment_log, 'payment_log');

    }
    UNSAFE_componentWillMount() {

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.rows != this.props.rows && this.props.rows.Ledgers) {
            this.patient_open_charges = this.props.rows.Ledgers.map(object => {
                return { ...object };
            });
            this.row_data_formatter(this.patient_open_charges, 'open_charge',this._is_mounted);
        }
        if (prevProps.rows != this.props.rows && this.props.rows.Payments) {
            this.patient_payment_log = this.props.rows.Payments.map(object => {
                return { ...object };
            });
            this.row_data_formatter(this.patient_payment_log, 'payment_log',this._is_mounted);
        }


    }



    //Function used to initialize the footer object.
    initialize_footer_obj = (column_def, type) => {
        var footer_obj = {};
        column_def.map((value, index) => {
            const index_val = (type == 'open_charge') ? 4 : 1;
            if (index == index_val) {
                footer_obj[value.field] = "Total"
            }
            else if (value.type == "currency" || value.type == "number") {
                footer_obj[value.field] = 0;
            }
            else {
                footer_obj[value.field] = null;
            }
        })
        return footer_obj;
    }

    //FUnction use to format the grid data on load.
    row_data_formatter = (data, report_name, is_mount = true) => {
        var column_def;
        switch (report_name) {
            case 'open_charge':
                column_def = report_constants.report_demand_statement_open_charges.column_defs;
                break;
            case 'payment_log':
                column_def = report_constants.report_demand_statement_payment_log.column_defs;
                break
            default:
        }


        var footer_obj = this.initialize_footer_obj(column_def, report_name);


        data.map((row) => {
            column_def.map((value, index) => {
                switch (value.type) {
                    case "date":
                        row[value.field] = (row[value.field] != null || row[value.field] != undefined) ? report_constants.dateFormatter(row[value.field]) : "";
                        break;
                    case "currency":
                        footer_obj[value.field] += row[value.field] ? parseFloat(row[value.field]) : 0
                        row[value.field] = (row[value.field] != null || row[value.field] != undefined) ? report_constants.currencyFormatter(row[value.field]) : 0;
                        break;
                    case "number":
                        footer_obj[value.field] += row[value.field] ? parseInt(row[value.field]) : 0
                        row[value.field] = (row[value.field] != null || row[value.field] != undefined) ? parseInt(row[value.field]) : 0;
                        break;
                    default:
                        row[value.field] = (row[value.field] != null || row[value.field] != undefined) ? row[value.field].toString().trim() : "";
                }

            })
            return row;
        })


        column_def.map((value, index) => {
            if (value.type == "currency") {
                footer_obj[value.field] = report_constants.currencyFormatter(footer_obj[value.field]);
            }
        });
        if (is_mount) {
            report_name == 'open_charge' ? this.setState({ open_charge_footer_object: footer_obj }) : this.setState({ payment_log_footer_object: footer_obj });
        }


        return data;
    }

    printHandler() {
        const print_content = print_html_data(this.state); // pass grid data to print content maker
        print_html_data_to_pdf(print_content,'yes_button_print_modal','Demand Statement');
        /*
        modal_hide_on_print(print_content);
        let doc_title = document.title;
        if (window.print) {
            document.title = 'Demand Statement'
            if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
                setTimeout(function () {
                    execute_print();
                }, 100)

                setTimeout(function () {
                    modal_show_after_print_ios();
                    document.title = doc_title
                }, 5000)

            }
            else {
                if (window.matchMedia) {
                    var mediaQueryList = window.matchMedia('print');
                    mediaQueryList.addListener(function (mql) {
                        if (mql.matches) {
                        } else {
                            afterPrint();
                            document.title = doc_title
                        }
                    });
                }
                var afterPrint = () =>  modal_show_after_print();
                // These are for Firefox
                window.onafterprint = afterPrint;
                execute_print();
            }
        }
        */
    }

    on_filter_button_click = (report_name) => {
        var row_api = null;
        var footer_obj = null;
        var colum_def = null;
        if (report_name == 'open_charge') {
            footer_obj = this.initialize_footer_obj(report_constants.report_demand_statement_open_charges.column_defs, report_name);
            row_api = this.state.open_charge_grid_ref.api;
            colum_def = report_constants.report_demand_statement_open_charges.column_defs;
        } else {
            footer_obj = this.initialize_footer_obj(report_constants.report_demand_statement_payment_log.column_defs, report_name);
            row_api = this.state.pay_log_grid_ref.api;
            colum_def = report_constants.report_demand_statement_payment_log.column_defs;
        }
        var cell_data;

        if (row_api) {
            row_api.forEachNodeAfterFilter((row, index) => {
                row = row.data;
                colum_def.map((value, index) => {
                    switch (value.type) {
                        case "currency":
                            if (row[value.field].indexOf("(") != -1) {
                                cell_data = row[value.field] ? "-" + row[value.field].substring(2, row[value.field].length - 1) : 0
                            } else {
                                cell_data = row[value.field] ? row[value.field].substring(1, row[value.field].length) : 0
                            }
                            footer_obj[value.field] += isNaN(parseFloat(cell_data)) ? 0 : parseFloat(cell_data)
                            break;
                        case "number":
                            footer_obj[value.field] += row[value.field] ? parseInt(row[value.field]) : 0
                            break;
                        default:
                    }
                })
            })

            colum_def.map((value, index) => {
                if (value.type == "currency") {
                    footer_obj[value.field] = report_constants.currencyFormatter(footer_obj[value.field]);
                }
            })
            row_api.setPinnedBottomRowData([footer_obj]);
        }
    }
    
    on_scroll_change = () => {
        setTimeout(() => {
            this.on_screen_change();
        }, 1000)
    }
    // On window resize, used to reset the height.
    on_screen_change = () => {
        setTimeout(() => {
            if (this.state.open_charge_grid_ref && this.state.open_charge_grid_ref.columnApi) {
                this.state.open_charge_grid_ref.columnApi.autoSizeAllColumns();
            }
        }, 500)
    };
    //Function calls on intialization of Ag-Grid and catch its reference.
    get_grid_ref = grid_params => {
        // window.addEventListener('resize', this.on_screen_change);
        this.report_container = document.getElementsByClassName("ag-body-viewport")[0];
        const { open_charge_footer_object, payment_log_footer_object} = this.state
        if (this.report_container) {
            // this.report_container.addEventListener("scroll", this.on_scroll_change);
        }
        if (grid_params.columnApi.getAllColumns().length == 11) {
            if (this._is_mounted) {
                if (this.total_open_charges_col_width > this.report_container.clientWidth) {
                    this.state.open_charges_columns.map(item => {
                        let width = get_footer_content_width(open_charge_footer_object[item.field])
                        if (open_charge_footer_object[item.field] != null && width > item['width']) {
                            grid_params.columnApi.setColumnWidth(item.field, width, false);
                        } else {
                            grid_params.columnApi.setColumnWidth(item.field, item['width'], false);
                        }
                    });
                }
                this.setState({
                    open_charge_grid_ref: grid_params
                })
            }
        } else {            
            if (this._is_mounted) {
                if (this.total_payment_log_col_width > this.report_container.clientWidth) {
                    report_constants.report_demand_statement_payment_log.column_defs.filter(item => {
                        grid_params.columnApi.setColumnWidth(item.field, item['width'], false);
                    })
                }
                report_constants.report_demand_statement_payment_log.column_defs.map(item => {
                    let width = get_footer_content_width(payment_log_footer_object[item.field])
                    if (!payment_log_footer_object[item.field] && width > item['width'])
                        grid_params.columnApi.setColumnWidth(item.field, width, false);

                })
                this.setState({ pay_log_grid_ref: grid_params })
            }
        }
        set_focus_on_element_with_id("visa_checkbox_button");
    };
    render() {
        return (
            <div id="grid_data" className="mar-t-15">
                <h2 className='form-heading-miniGray'>Patient Open Charges</h2>
                <GridView
                    id={report_constants.report_demand_statement_open_charges.grid_id}
                    row={this.patient_open_charges}
                    style={{ height: "calc(100vh - 550px)" }}
                    suppressMovableColumns={false}
                    suppressColumnVirtualisation={true}
                    enableColResize={true}
                    gridAutoHeight={true}
                    headerHeight={this.grid_open_charges_header_height}
                    emptyMessage={report_constants.messages.no_records_found}
                    column={this.state.open_charges_columns}
                    pinnedBottomRowData={[this.state.open_charge_footer_object]}
                    // on_filter_button_click={() => this.on_filter_button_click('open_charge')}
                    get_grid_ref={this.get_grid_ref}
                    headerIdForTabNavigation={report_constants.report_demand_statement_open_charges_header_id}
                />
                <h2 className='form-heading-miniGray'>Patient Payment Log</h2>
                <GridView
                    id={report_constants.report_demand_statement_payment_log.grid_id}
                    row={this.patient_payment_log}
                    style={{ height: "calc(100vh - 550px)" }}
                    suppressColumnVirtualisation={true}
                    suppressMovableColumns={false}
                    enableColResize={true}
                    gridAutoHeight={true}
                    headerHeight={this.grid_payment_log_header_height}
                    emptyMessage={report_constants.messages.no_records_found}
                    column={report_constants.report_demand_statement_payment_log.column_defs}
                    pinnedBottomRowData={[this.state.payment_log_footer_object]}
                   // on_filter_button_click={() => this.on_filter_button_click('payment_log')}
                    get_grid_ref={this.get_grid_ref}
                    headerIdForTabNavigation={report_constants.report_demand_statement_payment_log_header_id}
                />
            </div>
        );
    }

}
export default DemandStatementReportGrid;