import * as global_constants from '../../../global_constants';
import { RequestConfig } from '../../../shared/network/config';
import { Request } from '../../../shared/network/request';

export const get_payers = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.manage_payers.auto_ftp_payers}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

export const get_payer_by_id = (token, payer_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.manage_payers.auto_ftp_payers}/${payer_id}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

export const add_payer = (token, payer) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.manage_payers.auto_ftp_payers}`;

    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': payer
    })

    return request.make(request_config);
}

export const edit_payer = (token, payer_id, payer) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.manage_payers.auto_ftp_payers}/${payer_id}`;

    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'put',
        'data': payer
    })
    return request.make(request_config);
}

export const get_active_payers = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.manage_payers.auto_ftp_active_payers}`;

    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
}