import * as React from "react";
import { connect } from "react-redux";
import { Button, Dimmer, Form, Grid, Icon, Loader } from "semantic-ui-react";
import { clearTimeout, setTimeout } from 'timers';
import * as global_constants from "../../global_constants";
import { get_control_list } from "../../shared/action/advanced_control_action";
import RndModal from "../../shared/component/rnd_modal";
import GridView from "./grid";
import { handle_click_on_enter, handle_focus_on_tab, set_focus_on_element_with_id } from './../../shared/tab_navigation_utility';
import { get_columns } from './../../shared/utility'

interface IProps {
    controlId: string;
    onGridRowSelection: Function;
    onSearch: Function;
    onCloseModal: Function;
    gridConfig: { rows?: any; column?: any; };
    showModal?: boolean;
    get_control_list?: Function;
    advance_control_state?: object;
    user_login_details?: { user_data: { data: { token_details: { access_token: any; }; }; }; },
    addClicks: Function;
    keyPress: Function;
    disabled_element?: string;
    set_parent_show_modal_state?: Function;
    get_grid_ref?: Function;
    search_type?: string;
    headerIdForGridTabNavigation?: string;
}

class AdvancedControlWithResizable extends React.Component<IProps, any> {
    _is_mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            control_data: null,
            loading: false,
            form_data: {},
            control_id: this.props.controlId,
            grid_config: {},
            page_size: global_constants.constants.PAGE_SIZE,
            error_message: "",
            disabled_element: this.props.disabled_element ? this.props.disabled_element : false,
            popUpPosition: {
                x: 300,
                y: 65,
                width: 699,
                height: 282,
                minHeight: 282,
                minWidth: 699,
                maxWidth: 1600,
                maxHeight: 900
            },
        };
    }
    subtitle: any;
    window_width: number;
    width = 699;
    height = 282;
    child_ref: any;
    is_search: boolean = false;
    is_close: boolean = false;
    is_resized: boolean = false;
    rnd: any;
    total_column_width: number = 0;
    grid_header_height: number = 0;
   
    componentDidMount = () => {
        this._is_mounted = true;
        this.window_width = window.innerWidth;
        this.pop_update_size();
        this.focus_default();
    };
    focus_default = () => {
        if (this._is_mounted) {
            this.setState({
                timeout: setTimeout(function () {
                    if(! document) return; // if no DOM is there no need to go furthur.

                    var model = document.getElementById("daragble-App");
                    if (model) {
                        var fist_input = model.getElementsByTagName("input");
                        if (fist_input && fist_input.length > 0 && fist_input[0]) {
                            fist_input[0].focus();
                        }
                    }
                }, 500)
            });
        }
    }
    componentWillUnmount() {
        this._is_mounted = false;
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
        }
    }
    pop_update_size = () => {
        if (window.matchMedia("(min-width: 1920px)").matches) {
            this.width = 1300;
            this.height = this.is_search ? 850 : 282;
        } else if (window.matchMedia("(min-width: 1200px)").matches) {
            this.width = 900;
            this.height = this.is_search ? 650 : 282;
        } else if (window.matchMedia("(min-width: 992px)").matches) {
            this.width = 850;
            this.height = this.is_search ? 650 : 282;
        } else if (window.matchMedia("(min-width: 768px)").matches) {
            this.width = 699;
            this.height = this.is_search ? 650 : 282;
        }
        var popX = window.innerWidth - this.width;
        var popY = window.innerHeight - this.height;
        if (this._is_mounted) {
            this.setState({
                popUpPosition: {
                    ...this.state.popUpPosition,
                    width: !this.is_resized && this.width,
                    height: this.height,
                    minHeight: this.is_search ? 600 : 282,
                    x: Math.round(popX / 2),
                },
                loading: false
            });
        }
    }

    controlHandle = e => {
        if (this.state.disabled_element) {
            return;
        }
        e.preventDefault();
        if (this._is_mounted) {
            this.setState({ showModal: true });
            this.handle_on_open_resizable_advance_control_modal_window();
        }
        if (typeof this.props.set_parent_show_modal_state != "undefined") {
            this.props.set_parent_show_modal_state(true, this.props.controlId);
        }
        this.focus_default();
    };

    handle_on_open_resizable_advance_control_modal_window = () => {
        setTimeout(() => {
            if(! document) return; // if no DOM is there no need to go furthur.
            let closeIcons: NodeListOf<HTMLElement> = document.querySelectorAll('i.close.icon') as NodeListOf<HTMLElement>;
            let closeIcon = closeIcons[closeIcons.length - 1]
            closeIcon.tabIndex = 0;
            closeIcon.id = "resizable_advance_control_close_icon";
            closeIcon.addEventListener("keydown", function (event) {
                if (event.shiftKey && event.keyCode == 9) {
                    event.preventDefault();
                    let search_button = document.getElementById("resizable_advance_control_search_button");
                    search_button.focus();
                }
                
                if (event.keyCode == 13) {
                    event.preventDefault();
                    var el = event.target as HTMLElement
                    el.click();
                }
            });
        }, 500)
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousState.showModal != this.state.showModal) {
            if (this._is_mounted) {
                this.setState({ showModal: this.state.showModal });
            }
            if (this.state.showModal) {
                var token = this.props.user_login_details.user_data.data.token_details.access_token;
                this.props.get_control_list(this.state.control_id, token);
            }
        }
        if (previousProps.showModal !== this.props.showModal) {
            if (this._is_mounted) {
                this.setState({ showModal: this.props.showModal });
            }
        }

        if (previousProps.advance_control_state !== this.props.advance_control_state) {
            const control_id = this.state.control_id.toLowerCase();
            if (this._is_mounted) {
                this.setState({ control_data: this.props.advance_control_state[control_id] });
            }
        }
        if (
            previousProps.gridConfig != this.props.gridConfig &&
            typeof this.props.gridConfig.rows != "undefined" &&
            Object.keys(this.props.gridConfig).length > 0
        ) {
            const messages = "No Record Available"; // typeof this.props.gridConfig.messages != 'undefined' && this.props.gridConfig.messages !== null ? this.props.gridConfig.messages.map(msg => msg.message).join('.') : '';
            this.is_search = true;
            let config:any = { ...this.props.gridConfig };
            const grid_height = get_columns(config.rows.slice(0, config.paginationPageSize), config.column);
            this.total_column_width = grid_height.total_column_width;
            this.grid_header_height = grid_height.header_height;
            if (this._is_mounted) {
                this.setState({
                    grid_config: config,
                    error_message: messages
                });
            }
            this.pop_update_size();
            this.is_resized = false;
        }
        if (previousProps.disabled_element != this.props.disabled_element) {
            if (this._is_mounted) {
                this.setState({ disabled_element: this.props.disabled_element });
            }
        }
    }

    /* getHandler method provide handles on bases of provided lable button */
    getHandler = (action, e) => {
        switch (action) {
            case "Search":
                var data = this.state.form_data; // add page size property
                data["page_size"] = global_constants.constants.PAGE_SIZE;
                if (this._is_mounted) {
                    this.setState({
                        loading: true
                    });
                }
                this.props.onSearch(data); // fetch data from search apis on click search button
                break;
            case "Clear":
                this.clearFormHandler(e);
                break;
            case "Cancel":
                this.closeModal();
                break;
            case "Add Referring Physician":
                this.props.addClicks();
                break;
            case "Add New":
                this.props.addClicks();
                break;
            case "Add":
                this.props.addClicks();
                break;
            case "New":
                this.props.addClicks();
                break;
            case "Add New":
                this.props.addClicks();
                break;
            default:
                //this.props.onAddClick();
                break;
        }
    };

    closeModal = () => {
        this.is_search = false;
        this.is_resized = false;
        this.pop_update_size();
        if (this._is_mounted) {
            this.setState({ showModal: false, grid_config: {}, form_data: {}, error_message: "" });
        }
        if (typeof this.props.onCloseModal !== "undefined") {
            this.props.onCloseModal();
        }
        if (typeof this.props.set_parent_show_modal_state != "undefined") {
            this.props.set_parent_show_modal_state(false, this.props.controlId);
        }
        if (this.props.search_type) {
            set_focus_on_element_with_id('resizable-control-' + this.props.search_type);
        }
    };

    clearFormHandler = e => {
        this.update_rnd();
        if (this._is_mounted) {
            this.setState({ form_data: {}, grid_config: {}, error_message: "" });
        }
    };

    handleChange = e => {
        const name = e.target.name;
        let input_val = e.target.value;
        const form_updated_data = Object.assign({}, this.state.form_data, { [name]: input_val });
        if (this._is_mounted) {
            this.setState({ form_data: form_updated_data });
        }
    };

    onDoubleClick = selectedRows => {
        this.closeModal();
        this.props.onGridRowSelection(selectedRows);
    };

    input_key_press = event => {
        if (event.which == 13) {
            if (this.props.keyPress) {
                var data = this.state.form_data; // add page size property
                data["page_size"] = global_constants.constants.PAGE_SIZE;
                this.props.keyPress(data, event);
            }
        }
    };

    key_tab_press = (event, type) => {
        if (event.keyCode === 13) {
            // on enter event
            if (type == "open") {
                this.controlHandle(event);
                if (typeof this.props.set_parent_show_modal_state != "undefined") {
                    this.props.set_parent_show_modal_state(true, this.props.controlId);
                }
            } else if (type == "Search") {
                this.getHandler(type, event);
            }
        }
    };

    update_rnd() {
        if (this._is_mounted) {
            this.setState({
                popUpPosition: {
                    ...this.state.popUpPosition,
                    height: 282,
                    minHeight: 282
                },
            })
        }
        this.rnd &&
            this.rnd.updateSize({
                width: this.state.popUpPosition.width,
                height: this.state.popUpPosition.height,
                minHeight: this.state.popUpPosition.minHeight,
                minWidth: 628
            });
    }

    handle_search_tab = (event) => {

        // if grid is not empty then focus should move on cross icon.
        if (!this.state.grid_config.rows || (this.state.grid_config.rows.length == 0)) {

            handle_focus_on_tab(event, "resizable_advance_control_close_icon")

        }
    }

    on_grid_out = () => {
        set_focus_on_element_with_id("resizable_advance_control_close_icon");
    }

    get_grid_ref = grid_params => {
        if (this.props.get_grid_ref) {
            this.props.get_grid_ref(grid_params);
        }
        var grid_width = document.getElementById('advanced_control_with_resizable_grid').clientWidth;
        if (this.total_column_width > grid_width) {
            this.props.gridConfig.column.filter(item => {
                grid_params.columnApi.setColumnWidth(item.field, item.width, false);
            });
        }
    }

    render() {
        const form_fields = this.state.control_data ? this.state.control_data.inputFields : null;
        // const { rows } = this.props.gridConfig;
        const { rows } = this.state.grid_config;
        // const grid_config = { ... this.props.gridConfig };
        const grid_config = { ...this.state.grid_config };
        const that = this;
        let button_controls = [];
        let input_controls = [];
        if (form_fields != null) {
            button_controls = form_fields.filter(element => element.type == "Button" && element).reverse(); // filter button controls
            input_controls = form_fields.filter(element => element.type != "Button" && element);
        }
        return (
            <React.Fragment>
                <button
                    type="button"
                    className="searchIcon"
                    disabled={this.state.disabled_element}
                    onClick={this.controlHandle}
                    id={this.props.search_type ? 'resizable-control-' + this.props.search_type : ''}                  
                >
                    <Icon aria-hidden="true"  name="search" />
                </button>
                {form_fields != null && this.state.showModal && (
                    <RndModal
                        isOpen={this.state.showModal}
                        onRequestClose={this.closeModal}
                        ariaHideApp={false}
                        // Rnd properties
                        className={"default-modal"}
                        default={this.state.popUpPosition}
                        minWidth={this.state.popUpPosition.minWidth}
                        minHeight={this.state.popUpPosition.minHeight}
                        maxWidth={this.state.popUpPosition.maxWidth}
                        maxHeight={this.state.popUpPosition.maxHeight}
                        onResize={(e, direction, ref, delta, position) => {
                            this.is_resized = true;
                            if (this._is_mounted) {
                                this.setState({
                                    popUpPosition: {
                                        ...this.state.popUpPosition,
                                        width: ref.style.width,
                                        height: ref.style.height,
                                    },
                                });
                            }
                        }}
                        size={{ width: this.state.popUpPosition.width, height: this.state.popUpPosition.height }}

                        bounds="window"
                        dragHandleClassName="daragble-header"
                        resizeHandleClasses={{
                            bottomRight: "resize_bottom_right"
                        }}
                        style={{ overflow: "hidden" }}
                        reference={upDate => {
                            this.rnd = upDate;
                        }}
                    >
                        <Dimmer active={this.state.loading}>
                            <Loader size='huge'>Loading</Loader>
                        </Dimmer>
                        <div className="header daragble-header" style={{ cursor: "all-scroll",borderRadius: '.28571429rem .28571429rem 0 0' }} id="daragble-header">
                            <h3>{this.state.control_data.title}</h3>
                            <i tabIndex={0} onKeyDown={handle_click_on_enter} aria-hidden="true" className="close icon" onClick={this.closeModal} />
                        </div>
                        <div className="content">
                            <div className="description">
                                <div className="daragble-App" id="daragble-App">
                                    <div className="common-forms" style={{ padding: "0" }}>
                                        <Form>
                                            <Grid>
                                                {input_controls.map((field, index) => {
                                                    let fieldAttibute: any = {};
                                                    fieldAttibute = {
                                                        name: field.name,
                                                        type: field.type == "TextBox" ? "text" : field.type,
                                                        value: this.state.form_data.hasOwnProperty(field.name) ? this.state.form_data[field.name] : "",
                                                        onChange: that.handleChange,
                                                        onKeyUp: e => {
                                                            this.key_tab_press(e, "Search");
                                                        },
                                                        onKeyPress: that.input_key_press
                                                    };
                                                    if (field.maxLength != null && field.type == "TextBox") {
                                                        fieldAttibute.maxLength = field.maxLength;
                                                    }
                                                    return (
                                                        <Grid.Column tablet={8} computer={8} key={index}>
                                                            <Form.Field>
                                                                <label>{field.labelName}</label>
                                                                <input {...fieldAttibute} />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    );
                                                })}
                                            </Grid>
                                            <Grid>
                                                <Grid.Column tablet={16} computer={16} textAlign="right" className="mar-t-10">
                                                    {button_controls.map((field, index) => {

                                                        if (field.action == 'Search') {
                                                            return (
                                                                <Button
                                                                    key={index}
                                                                    name={field.name}
                                                                    className="ui primary button"
                                                                    type="button"
                                                                    onKeyDown={ this.handle_search_tab }
                                                                    onClick={e => that.getHandler(field.action, e)}
                                                                    id="resizable_advance_control_search_button"
                                                                >
                                                                    {field.action}
                                                                </Button>
                                                            );
                                                        }

                                                        if (field.action == 'Add New') {
                                                            return (
                                                                <Button
                                                                    key={index}
                                                                    name={field.name}
                                                                    className="basic"
                                                                    type="button"
                                                                    onKeyDown={(event) => handle_focus_on_tab(event, "resizable_advance_control_search_button")}
                                                                    onClick={e => that.getHandler(field.action, e)}
                                                                    id={"add_new_" + this.state.control_data.title}
                                                                >
                                                                    {field.action}
                                                                </Button>
                                                            );
                                                        }

                                                        return (
                                                            <Button
                                                                key={index}
                                                                name={field.name}
                                                                className="basic"
                                                                type="button"
                                                                onClick={e => that.getHandler(field.action, e)}
                                                                id="bg_fff2"
                                                            >
                                                                {field.action}
                                                            </Button>
                                                        );
                                                    })}
                                                </Grid.Column>
                                            </Grid>
                                        </Form>
                                    </div>
                                </div>
                                <div className="mar-t-15">
                                    {(rows && !this.state.loading) && (
                                        <GridView id="advanced_control_with_resizable_grid"
                                            row={rows}
                                            {...grid_config}
                                            style={{ height: '100%' }}
                                            wrapperStyle={{ width: '100%', height: '100%', display: 'flex' }}
                                            isPagination={true}
                                            gridAutoHeight={false}
                                            selectionType={"single"}
                                            onRowDoubleClicked={this.onDoubleClick}
                                            enableColResize={true}
                                            suppressSizeToFit={true}
                                            get_grid_ref={this.get_grid_ref}
                                            onGridOut={this.on_grid_out}
                                            headerIdForTabNavigation={this.props.headerIdForGridTabNavigation}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </RndModal>
                )}
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return { get_control_list: (control_id, token) => dispatch(get_control_list(control_id, token)) };
};

const mapSTateToProps = state => {
    return {
        advance_control_state: state.advanced_control_details,
        user_login_details: state.user_login_details
    };
};

export default connect(
    mapSTateToProps,
    mapDispatchToProps
)(AdvancedControlWithResizable);