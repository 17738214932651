import * as React from "react";
import * as report_constants from "../report_constants";
import GridView from "./../../shared/component/grid";
import HierarchyGridView from "../../shared/component/grid/grid_child_ui_component";
const ReportGrid = (props) => {
  return props.has_hierarchy ? (
    <HierarchyGridView
      id={props.id}
      row={props.row}
      column={props.column}
      style={props.styles}
      selectionType={"single"}
      suppressMovableColumns={props.suppressMovableColumns}
      enableColResize={props.enableColResize}
      onRowDoubleClicked={props.onRowDoubleClicked}
      emptyMessage={report_constants.messages.no_records_found}
      pinnedBottomRowData={props.pinnedBottomRowData}
      gridAutoRowHeight={props.gridAutoRowHeight}
      on_filter_button_click={props.on_filter_button_click}
      get_grid_ref={props.get_grid_ref}
      onCellClicked={props.onCellClicked}
      suppressSizeToFit={true}
      getRowHeight={props.getRowHeight}      
      wrapperStyle={props.wrapperStyle}
      wrapperClass={props.wrapperClass}
      {...props}
    />
  ) : (
    <GridView
      id={props.id}
      row={props.row}
      column={props.column}
      style={props.styles}
      selectionType={"single"}
      suppressMovableColumns={props.suppressMovableColumns}
      enableColResize={props.enableColResize}
      onRowDoubleClicked={props.onRowDoubleClicked}
      emptyMessage={report_constants.messages.no_records_found}
      pinnedBottomRowData={props.pinnedBottomRowData}
      gridAutoRowHeight={props.gridAutoRowHeight}
      on_filter_button_click={props.on_filter_button_click}
      get_grid_ref={props.get_grid_ref}
      onCellClicked={props.onCellClicked}
      suppressSizeToFit={true}
      onGridOut={props.onGridOut}
      getRowHeight={props.getRowHeight}
      wrapperStyle={props.wrapperStyle}
      wrapperClass={props.wrapperClass}
      {...props}
    />
  );
};

export default ReportGrid;
