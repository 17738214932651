import * as React from 'react';
import * as moment from 'moment';
import { toastr as toaster } from 'react-redux-toastr';
import { Accordion, Button, Form, Grid, Icon, Input } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { LoaderComponent } from '../../shared/component/loading_component';
import Selection from '../../shared/component/selection_component';
import MultiSelectDropDownComponent from '../../shared/component/multi_select_dropdown_component';
import { handle_click_on_enter, set_focus_to_app_header } from '../../shared/tab_navigation_utility';
import * as session_storage from '../../shared/session_storage_utility';
import { check_internal_staff, row_data_formatter } from '../../billing/utility';
import { get_claim_holds_summary_search_data, get_claim_holds_summary_page_metadata } from '../action/charge_action';
import { user_companies } from '../../user/action/user_action';
import { log_error } from '../../shared/action/shared_action';
import ReportHeader from '../../reports/component/report_header';
import { isNullOrUndefined } from 'util';
import {
	claimhold_dropdown_data, claimhold_summary_reponse,
	claim_hold_summary_configuration_grid_header_id,
	claim_hold_summary_configuration,
    claim_holds_summary_row_data,
    claim_holds_summary_row_data_state

} from '../charge_constant';
import * as charge_constants from '../charge_constant';
import GridView from '../../shared/component/grid/grid_child_ui_component';
import * as report_constants from '../../reports/report_constants';
import { custom_date_format, get_columns, charge_get_columns, get_all_error } from '../../shared/utility';

enum ClaimHoldSummaryPageMetadataType {
	All = 0,
	Practice = 1,
	Location = 2,
	Payer = 3,
	Insurance_Class = 4,
	Claim_Hold_Category = 5
}

export enum claim_holds_group_by {
	COMPANY = 'Company',
	INSURANCE_CLASS = 'Insurance Class',
	CLAIM_DELAY_CATEGORY = 'Claim Delay Category'
}

export const claim_holds_summary_hold_category_dd_options: { value: number, name: string }[] = [
	{ value: 0, name: 'Charge Review' },
	{ value: 1, name: 'Delayed Claims' },
	{ value: 2, name: 'Failed Charges' }
];

export const claim_holds_summary_group_by_dd_options: { value: number, text: string }[] = [
	{ value: 0, text: claim_holds_group_by.COMPANY },
	{ value: 1, text: claim_holds_group_by.INSURANCE_CLASS },
	{ value: 2, text: claim_holds_group_by.CLAIM_DELAY_CATEGORY }
];

export const toaster_validation_error: string = 'Please fill all the mandatory fields or check highlighted field values';

export const date_format = {
	'mm/dd/yyyy': 'mm/dd/yyyy',
	'mm-dd-yyyy': 'mm-dd-yyyy',
	'yyyy': 'yyyy',
	'mm/dd/yyyy h:MM:ss TT': 'mm/dd/yyyy h:MM:ss TT',
	standard_date_time_format: 'mm/dd/yyyy hh:mm:ss',
	date_time_format: 'mm/dd/yyyy h:mm:ss TT',
	date_time_without_sec_format: 'mm/dd/yyyy h:MM TT',
	'mm/yyyy': 'mm/yyyy',
}

export class ClaimHoldsSummaryComponent extends React.Component<any, any> {
    dropdown_data: any = {
        insurance_classes: [],
        payers: [],
        locations: [],
        practices: [],
        company_options: [],
        claim_hold_category: []
	};

	max_inner_width = 0;
	complete_mock_accorian_rows: any[] = [];
	grid_header_height: number = 0;
	total_column_width: number = 0;
	footer_object: any = {};
	charge_audit_column_def = report_constants.report_charge_audit_summary.column_defs;
	claim_hold_summary_grid_metadata: any = {};

	persisted_payer_dropdown_data: any[] = [];
	persisted_location_dropdown_data: { value: number, name: string, practice_id:string}[] = [];

    empty_selection_options: any[] = [];

    default_company_index: number = -1;

    claim_holds_summary_row_list_state: claim_holds_summary_row_data_state[] = [];

    is_mounted = true;
    is_submitted = false;
    page_title: string = 'Claim Holds Summary';
    row_data: any[];
    title_search_criteria: any = {};
    token: string = '';

	constructor(props) {
		super(props);
		this.state = {
			controls_is_expanded: false,
			loading: false,
			is_loading_dropdown: false,
			initial_load: true,
			is_new_disabled: false,
			form_invalid: {
				company: false,
				practice: false,
				location: false,
				payer: false,
				insurance_class: false,
				claim_hold_category: false
			},
			form_submited: false,
			search_criteria: {
				company: 0,
				practices: [],
				locations: [],
				payers: [],
				insurance_classes: [],
				claim_hold_category: [],
				group_by: ''
            },
            claim_hold_summary_footer_obj: {},
            claim_holds_summary_row_list: []
		};
	}

	// Fetching the report id and params.
	UNSAFE_componentWillMount = () => {
		this.is_mounted = true;
		this.token = this.props.user_login_details.user_data.data.token_details.access_token;
		this.state.search_criteria.group_by = claim_holds_group_by.COMPANY;
		this.get_company_dropdown_data()
			.then(() => { this.get_dropdown_metadata_by_company() });
	};
    
	componentDidMount = (): void => {
		
	};

	componentWillUnmount = () => {
		this.is_mounted = false;
        //TODO: There's a convention in other components that clears out certain values. review other components and decide if we need to add of that here. Add a test to ensure these values are cleared.
	};

	get_company_dropdown_data = async () => {
		await this.props.user_companies(false, this.token);
		let data = this.props.user_companies_response.data;
		if (data != null && data.length > 0) {
			var company_id_obj = data.find(c_data => c_data.company_id === this.props.user_login_details.user_data.data.company_id);

			this.dropdown_data.company_options = data.map((val) => {
				return { key: val.company_id, value: val.company_id, text: val.gpms_code + " - " + val.company_name };
			});

			if (session_storage.get('claim_hold_summary_search_criteria')) {
				const search_criteria = session_storage.get('claim_hold_summary_search_criteria');
				this.set_selected_company(search_criteria.company);
				return;
			} else {
				this.set_selected_company(company_id_obj.company_id);
			}
		}
        
	}

	get_dropdown_metadata_by_company = async (companyID: number = null) => {
		if (this.is_mounted) {
			this.setState({
				re_render: false,
				loading: true
            });
        }

        if (isNullOrUndefined(companyID)) {
            companyID = this.props.user_login_details.user_data.data.company_id
        }
        
        
        this.claim_hold_summary_grid_metadata = claim_hold_summary_configuration(this.props, 'Grouping');

		const arrData = [];
		arrData.push(get_claim_holds_summary_page_metadata(this.token, { companyID, request: ClaimHoldSummaryPageMetadataType.Insurance_Class }));
		arrData.push(get_claim_holds_summary_page_metadata(this.token, { companyID, request: ClaimHoldSummaryPageMetadataType.Location }));
		arrData.push(get_claim_holds_summary_page_metadata(this.token, { companyID, request: ClaimHoldSummaryPageMetadataType.Practice }));
		if (this.persisted_payer_dropdown_data.length === 0) {
			arrData.push(get_claim_holds_summary_page_metadata(this.token, { companyID, request: ClaimHoldSummaryPageMetadataType.Payer }));
		}

		await Promise.all(arrData)
			.then(
				(response) => {
                    let elements: claimhold_dropdown_data[] = [];
					for (let index = 0; index < response.length; index++) {
						if (response[index] && response[index].data.status) {
							elements.push(response[index].data.data);
						}
					}
					if (elements) {
                        this.format_dropdown_data(this.concatenate_dropdown_data(elements));
					}

					if (this.is_mounted && !this.state.initial_load) {
						this.setState({
							re_render: true,
							loading: false,
						});
					}

					if (this.is_mounted && this.state.initial_load) {
						this.search_handler();
						this.setState({
							initial_load: false
						});
					}
				},
				(error) => {
					if (this.is_mounted) {
						this.setState({
							loading: false
						});
					}
					log_error(error);
					if (error.response.data) {
						//const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
						//toastr.error('', toastr_options);
					}
				}
			)
			.catch((error) => {
				if (this.is_mounted) {
					this.setState({
						loading: false
					});
				}
				log_error(error);
				if (error.data && error.data.messages && error.data.messages[0].message.indexOf('<br') > -1) {
					//this.show_html_content_toaster(error.response.data.messages[0].message);
				} else {
					//toastr.error('', error.response.data.messages[0].message);
				}
			});
    };

    concatenate_dropdown_data(dataArray: claimhold_dropdown_data[]): claimhold_dropdown_data {

        let result: claimhold_dropdown_data = { insurance_classes: null, locations: null, payers: null, practices: null};

        dataArray.forEach((data) =>
        {
            if (data.insurance_classes) result.insurance_classes = data.insurance_classes;
            if (data.locations) result.locations = data.locations;
            if (data.payers) result.payers = data.payers;
            if (data.practices) result.practices = data.practices;
        });

        return result;
    }

    format_dropdown_data(data: claimhold_dropdown_data) {
        const { insurance_classes, payers, locations, practices
        } = data;

        this.dropdown_data = {
            ...this.dropdown_data,
            insurance_classes: insurance_classes || [],
            payers: payers ? payers : this.persisted_payer_dropdown_data,
            locations: locations || [],
            practices: practices || [],
            claim_hold_category: claim_holds_summary_hold_category_dd_options
		};

		if (session_storage.get('claim_hold_summary_search_criteria')) {
			const search_criteria = session_storage.get('claim_hold_summary_search_criteria');
			this.setState({
				search_criteria: {
					...search_criteria
				}
			});
			return;
		}

		this.persisted_payer_dropdown_data = this.dropdown_data.payers;
		this.state.search_criteria.practices = this.dropdown_data.practices.map((prac) => {
            return prac.value;
        });

        this.state.search_criteria.payers = this.dropdown_data.payers.map((payer) => {
            return payer.value;
        });

        this.state.search_criteria.insurance_classes = this.dropdown_data.insurance_classes.map((ic) => {
            return ic.value;
        });

        this.state.search_criteria.claim_hold_category = this.dropdown_data.claim_hold_category.map((c_hold) => {
            return c_hold.value;
		});

		this.dropdown_data.locations = this.dropdown_data.locations.filter(location => this.state.search_criteria.practices.indexOf(location.practice_id) > -1);
		this.persisted_location_dropdown_data = this.dropdown_data.locations;
		this.state.search_criteria.locations = this.dropdown_data.locations.map((loc) => {
			return loc.value;
		});
    };
    
    multi_select_handler = (id, values) => {
        this.dropdown_data[id] = this.dropdown_data[id].map((item) => {
            if (values.indexOf(item.value) > -1) {
                item = {
                    ...item,
                    checked: true,
                    is_checked: true
                };
            } else {
                item = {
                    ...item,
                    checked: false,
                    is_checked: false
                };
            }
            return item;
        });
        if (this.is_mounted) {
            this.title_search_criteria[id] = `${values.length} Selected`;
            this.setState((prevState: { search_criteria: any }) => ({
                search_criteria: {
                    ...prevState.search_criteria,
                    [id]: [...values]
                }
            }));
        }

        if (this.is_mounted) {
            if (id === 'practices') {
                var filtered_locations = this.persisted_location_dropdown_data.filter(location => values.indexOf(location.practice_id) > -1);
                this.dropdown_data = {
                    ...this.dropdown_data,
                    location: filtered_locations
                };
                this.title_search_criteria['location'] = `${filtered_locations.length} Selected`;

                this.state.search_criteria.locations = filtered_locations.map((loc) => {
                    return loc.value;
				});
			}
		}

		session_storage.set('claim_hold_summary_search_criteria', this.state.search_criteria);
    };

	set_selected_company = (company_id: number) => {
		//this.default_company_index = company_id;
		this.state.search_criteria.company = company_id;
    }
    
    controls_toggle = () => {
        if (this.is_mounted) {
            this.setState({
                controls_is_expanded: !this.state.controls_is_expanded
            });
        }
    };

    //TODO: Write test for validation after this is completed
	validate_form_field_data = () => {
		let is_error = false;
		if (this.state.search_criteria.company === 0) {
			is_error = true;
			return is_error;
		}
		if (this.state.search_criteria.practices.length === 0) {
			is_error = true;
			return is_error;
		}
		if (this.state.search_criteria.locations.length === 0) {
			is_error = true;
			return is_error;
		}
		if (this.state.search_criteria.payers.length === 0) {
			is_error = true;
			return is_error;
		}
		if (this.state.search_criteria.insurance_classes.length === 0) {
			is_error = true;
			return is_error;
		}
		if (this.state.search_criteria.claim_hold_category.length === 0) {
			is_error = true;
			return is_error;
		}

		return is_error;
    };

    //TODO: handle the event
    //TODO: Add test
	group_by_dropdown_change_handler = (value, event) => {
		if (this.is_mounted) {
			if (value === '0') {
				this.state.search_criteria.group_by = claim_holds_group_by.COMPANY;
				return;
			}
			if (value === '1') {
				this.state.search_criteria.group_by = claim_holds_group_by.INSURANCE_CLASS;
				return;
			}
			this.state.search_criteria.group_by = claim_holds_group_by.CLAIM_DELAY_CATEGORY;
		}
    };

	search_handler = async () => {
		 //Validate Required Field
		let is_error = this.validate_form_field_data();
		if (is_error) {
			toaster.error('', toaster_validation_error);
			return;
		}

        if (this.is_mounted) {
            this.setState({
                loading: true,
                show_grid: false
            });
		}

		let search_parameters = {
			...this.state.search_criteria
		}

		this.set_search_criteria_selections_if_select_all(search_parameters);
		
        //TODO: (response) (error) is an error handling mechanism, do we need 'if then' aswell?
		await get_claim_holds_summary_search_data(this.token, search_parameters).then(
			(response) => {
                let responseData: claim_holds_summary_row_data[];
                if (response.data.status == 1) {
                    responseData = response.data.data;

                    //TODO: replace parameter input with responseData
					this.initial_load_claim_holds_summary_row_list(responseData);

                    if (this.is_mounted) {
                        this.setState({
                            is_loading: false,
                            show_grid: true
                        });
                    }
                } else {
                    if (this.is_mounted) {
                        this.setState({
                            loading: false,
                            show_grid: false
                        });
                    }
				}

				if (session_storage.get('claim_hold_summary_search_criteria')) {
					const search_criteria = session_storage.get('claim_hold_summary_search_criteria');
					this.setState({
						search_criteria: {
							...search_criteria
						}
					});
				}

				this.setState({
					loading: false,
					 is_loading_dropdown: false
				});
            },
            (error) => {
                this.setState({
                    loading: false,
                    show_grid: false
                });

                log_error(error);
            }
		);

        this.load_visible_claim_holds_summary_rows();
	};

	set_search_criteria_selections_if_select_all = (search_parameters: any) => {
		session_storage.set('claim_hold_summary_search_criteria', search_parameters);
        if (!isNullOrUndefined(this.dropdown_data.practices) &&
            !isNullOrUndefined(search_parameters.practices) &&
            search_parameters.practices.length === this.dropdown_data.practices.length) {
            search_parameters.practices = [-1];
        }
        if (!isNullOrUndefined(this.dropdown_data.locations) &&
            !isNullOrUndefined(search_parameters.locations) &&
            search_parameters.locations.length === this.dropdown_data.locations.length) {
            search_parameters.locations = [-1];
        }
        if (!isNullOrUndefined(this.dropdown_data.payers) &&
            !isNullOrUndefined(search_parameters.payers) &&
            search_parameters.payers.length === this.dropdown_data.payers.length) {
            search_parameters.payers = [-1];
        }
        if (!isNullOrUndefined(this.dropdown_data.insurance_classes) &&
            !isNullOrUndefined(search_parameters.insurance_classes) &&
            search_parameters.insurance_classes.length === this.dropdown_data.insurance_classes.length) {
            search_parameters.insurance_classes = [-1];
        }
    }

    // Function calls on click of Clear button and clear all the searched result as well as criteria.
	clear_handler = () => {
		session_storage.remove('claim_hold_summary_search_criteria');
        this.get_dropdown_metadata_by_company();
    };

    company_dropdown_change_handler = (event: string) => {
		var company_id = parseInt(event);
		session_storage.remove('claim_hold_summary_search_criteria');
        this.get_dropdown_metadata_by_company(company_id);
	};

	initialize_footer_obj = column_def => {
		var footer_obj = {};
		column_def.map((value, index) => {
			if (value.field !== 'group') {
				if (index == 0) {
					footer_obj[value.field] = 'Total';
				} else if (value.type == 'currency' || value.type == 'number') {
					footer_obj[value.field] = 0;
				} else {
					footer_obj[value.field] = null;
				}
			}
		});
		return footer_obj;
	};

	capitalize_text = string => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};

	currencyFormatter = (value) => {
		return value < 0 ? '($' + this.formatNumber((parseFloat(value) * -1).toFixed(2)) + ')' : '$' + this.formatNumber(parseFloat(value).toFixed(2));
	};

	dateFormatter = (value) => {
		return custom_date_format(value, date_format['mm/dd/yyyy']);
	};

	formatNumber = (number) => {
		// this puts commas into the number eg 1000 goes to 1,000,
		//return Math.floor(number).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
		// Change for B-23856 R6: Updates to Patient Payment Log
		// return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1');
		number = /^\d+?\.\d+?$/.test(number) ? parseFloat(number).toFixed(2) : number;
		return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    };

    onGroupRowClicked = (params) => {
        this.claim_holds_summary_row_list_state.forEach((element) => {
            if (element.parent_id == params.data.row_id || (element.row_id == params.data.row_id && element.parent_id == 0)) {
                element.visible = !element.visible;
                if (element.parent_id == 0) {
                    if (element.visible) {
                        element.group = '▼';
                    } else {
                        element.group = '▶';
                    }
                }
            }
        });
        this.load_visible_claim_holds_summary_rows();
    };

    initial_load_claim_holds_summary_row_list(claim_hold_summary_values: claim_holds_summary_row_data[]): void {
        this.claim_holds_summary_row_list_state = claim_hold_summary_values.map((value) => {
            return {
                ...value,
                group: value.parent_id == 0 ? '▶' : '',
                visible: false
            };
		});

		var child_column_config = charge_constants.claim_hold_summary_configuraton_child_column.child_column;
		var footer_obj = this.initialize_footer_obj(child_column_config);

		var parent_columns = claim_hold_summary_values.filter(value => value.parent_id === 0);

		parent_columns.map(row => {
			child_column_config.map((value, index) => {
				switch (value.type) {
					case 'currency':
						footer_obj[value.field] += row[value.field] ? parseFloat(row[value.field]) : 0;
						row[value.field] =
							row[value.field] != null || row[value.field] != undefined
								? report_constants.currencyFormatter(row[value.field])
								: report_constants.currencyFormatter(0);
						break;
					case 'number':
						footer_obj[value.field] += row[value.field] ? parseInt(row[value.field]) : 0;
						if (value.field == 'visit_count') {
							row[value.field] =
								row[value.field] != null || row[value.field] != undefined ? parseInt(row[value.field]) : null;
						} else {
							row[value.field] =
								row[value.field] != null || row[value.field] != undefined ? parseInt(row[value.field]) : 0;
						}

						break;
					default:
						row[value.field] =
							row[value.field] != null || row[value.field] != undefined
								? row[value.field] === true//row.is_active
									? this.capitalize_text(row[value.field].toString().trim())
									: row[value.field].toString().trim()
								: '';
				}
			});
			return row;
		});
		child_column_config.map((value, index) => {
			if (value.type == 'currency') {
				footer_obj[value.field] = report_constants.currencyFormatter(footer_obj[value.field]);
			}
		});
		if (this.is_mounted) {
			this.setState({ claim_hold_summary_footer_obj: { ...footer_obj } });
		}
		this.footer_object = footer_obj;
    }

    load_visible_claim_holds_summary_rows(): void {
        this.setState({
            claim_holds_summary_row_list: this.claim_holds_summary_row_list_state.filter((value) => {
                return value.visible || value.parent_id == 0;
            })
        });
    }

    render_multiSelect_element = (name: string, options: any[], selected_items: any[]) => {
        return (
            <MultiSelectDropDownComponent
                class_name='manage-content-multi-select'
                id={name}
                key={name}
                key_id={name}
                data={options}
                selected_list={selected_items}
                onChangeSelected={this.multi_select_handler}
                forceOnChangeSelected={true}
                has_validation={false}
                show_filter={true}
                reset_disable={false}
                include_future_selected={false}
                disabled={false}
                include_eye_feature={true}
            />
        );
    };

	render() {
		const {
			loading,
            controls_is_expanded,
            search_criteria = this.state.search_criteria,
            practices = this.dropdown_data.practices,
            locations = this.dropdown_data.locations,
            payers = this.dropdown_data.payers,
            insurance_classes = this.dropdown_data.insurance_classes,
            claim_hold_category = this.dropdown_data.claim_hold_category,
		} = this.state;
		return (
			<React.Fragment>
                <LoaderComponent loading={loading || this.state.is_loading_dropdown} style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
				<div className={'common-forms-add '}>
						<div className={'common-forms-search report-framework'}>
						<ReportHeader title={this.page_title} />					
							<div className='report-wrapper'
								id='report-scrollable-area'
                                style={{ padding: 0, border: 0, background: 'transparent' }}
							>
                                <Form autoComplete='off'>
								<Grid>
									<Grid.Column
										computer={16}
										tablet={16}
										textAlign='left'
										className='accordionColumn'
										id='accordion-column'
									>
										<Accordion fluid styled>
											<Accordion.Title
												active={controls_is_expanded}
												index={0}
												onClick={this.controls_toggle}
											>
                                                    <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name={controls_is_expanded ? 'angle down' : 'angle right'} />
												Search Criteria
											</Accordion.Title>
                                                <Accordion.Content active={controls_is_expanded} style={{paddingTop: 21}}>
												<Grid>
														<Grid.Column tablet={8} computer={4}>
														<Form.Field>
															<label>
																Company
															</label>
															{
																<Selection
																	name='company'
																	id='company_dropdown'
                                                                    options={this.dropdown_data.company_options}
																	onChange={this.company_dropdown_change_handler}
																		defaultValue={this.state.search_criteria.company}
																	style={this.state.form_invalid.company && this.state.form_submited ? 'red-error-thin' : ''}
																/>
															}
														</Form.Field>
													</Grid.Column>
													<Grid.Column tablet={8} computer={4}>
														<Form.Field>
															<label>Practice</label>
															{this.render_multiSelect_element(
																'practices',
																practices,
																search_criteria.practices
															)}
														</Form.Field>
													</Grid.Column>
													<Grid.Column tablet={8} computer={4}>
														<Form.Field>
																<label>Location</label>
																{this.render_multiSelect_element(
																	'locations',
																	locations,
																	search_criteria.locations
																)}
														</Form.Field>
														</Grid.Column>
														<Grid.Column tablet={8} computer={4}>
															<Form.Field>
																<label>Payer</label>
																{this.render_multiSelect_element(
																	'payers',
																	payers,
																	search_criteria.payers
																)}
															</Form.Field>
														</Grid.Column>
												</Grid>
												<Grid>
													<Grid.Column tablet={8} computer={4}>
														<Form.Field>
															<label>Insurance Class</label>
															{this.render_multiSelect_element(
																'insurance_classes',
																insurance_classes,
																search_criteria.insurance_classes
															)}
														</Form.Field>
													</Grid.Column>
													<Grid.Column tablet={8} computer={4}>
														<Form.Field>
															<label>
																Claim Hold Category
															</label>
																{this.render_multiSelect_element(
																	'claim_hold_category',
																	claim_hold_category,
																	search_criteria.claim_hold_category
																)}
														</Form.Field>
														</Grid.Column>
														<Grid.Column tablet={8} computer={4}>
															<Form.Field>
																<label>
																	Group By
																</label>
																{
																	<Selection
																		name='group_by'
																		id='group_by_dropdown'
																		//placeHolder='Select'
																		options={claim_holds_summary_group_by_dd_options}
																		onChange={this.group_by_dropdown_change_handler}
																	/>
																}
															</Form.Field>
														</Grid.Column>
												</Grid>
												<Grid>
													<Grid.Column
														tablet={(!this.props.BatchClaimsProjectFFVal || !check_internal_staff()) ? 16 : 8}
														computer={(!this.props.BatchClaimsProjectFFVal || !check_internal_staff()) ? 16 : 8}
														textAlign='right'>
														<Button
															type='button'
															id='clear_button'
															basic
															onClick={this.clear_handler}
															disabled={this.state.is_clear_disabled}
															content={'Clear'}
														/>
														<Button
															type='submit'
															id='search_claim_holds_button'
															className='primary'
															onClick={this.search_handler}
															disabled={this.state.is_new_disabled}
															style={{ marginRight: '0' }}
															content={'Search'}
														/>
													</Grid.Column>
												</Grid>
											</Accordion.Content>
										</Accordion>
										</Grid.Column>								
								</Grid>
								</Form>
								
							</div>
						</div>
					</div>
					<GridView
						id={'accordian_grid_claim_hold_summary_grid'}
                        row={this.state.claim_holds_summary_row_list}
                        column={this.claim_hold_summary_grid_metadata.column_def}
                        headerHeight={this.grid_header_height}
                        onGroupRowClicked={this.onGroupRowClicked}
                        style={{ height: '100%', width: '100%', paddingBottom: '10px' }}
						wrapperStyle={{ flex: '100%' }}
						wrapperClass={'grid_wrapper accordian_grid'}
						enableColResize={true}
						headerIdForTabNavigation={claim_hold_summary_configuration_grid_header_id}
						pinnedBottomRowData={[this.footer_object]}
						detailsName='details'
					/>
				</LoaderComponent>
			</React.Fragment >
		);
	}
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            user_companies: user_companies
        },
        dispatch
    );
};

	// Get user and login details from store.
const mapStateToProps = (state) => {
	return {
        user_login_details: state.user_login_details,
        user_companies_response: state.user_details.user_companies_response,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimHoldsSummaryComponent);

export const claim_hold_summary_grid_mock_company_data: claim_holds_summary_row_data[] = [
    {
        parent_id: 0,
        row_id: 1,
        row_label: 'Practice 1',
        number_impacted_visits: 7,
        company_id: 438,
        three_days_total_charge_amount: '75.00',
        three_days_total_charges: 1,
        seven_days_total_charge_amount: '75.00',
        seven_days_total_charges: 5,
        fourteen_days_total_charge_amount: '75.00',
        fourteen_days_total_charges: 1,
        twentyone_days_total_charge_amount: '75.00',
        twentyone_days_total_charges: 5,
        twentyone_plus_days_total_charge_amount: '75.00',
        twentyone_plus_days_total_charges: 23,
        total_amount_owed: '5375.00',
        total_charges: 512
    },
    {
        parent_id: 1,
        row_id: 2,
        row_label: 'Location 1',
        number_impacted_visits: 3,
        company_id: 438,
		three_days_total_charge_amount: '5.00',
		three_days_total_charges: 2,
		seven_days_total_charge_amount: '20.00',
		seven_days_total_charges: 5,
		fourteen_days_total_charge_amount: '50.00',
		fourteen_days_total_charges: 2,
		twentyone_days_total_charge_amount: '15.00',
		twentyone_days_total_charges: 4,
		twentyone_plus_days_total_charge_amount: '45.00',
		twentyone_plus_days_total_charges: 7,
		total_amount_owed: '135.00',
		total_charges: 23
	},
    {
        parent_id: 1,
        row_id: 2,
        row_label: 'Location 2',
        number_impacted_visits: 4,
        company_id: 438,
		three_days_total_charge_amount: '7.00',
		three_days_total_charges: 5,
		seven_days_total_charge_amount: '23.00',
		seven_days_total_charges: 7,
		fourteen_days_total_charge_amount: '42.00',
		fourteen_days_total_charges: 4,
		twentyone_days_total_charge_amount: '10.00',
		twentyone_days_total_charges: 3,
		twentyone_plus_days_total_charge_amount: '75.00',
		twentyone_plus_days_total_charges: 6,
		total_amount_owed: '157.00',
		total_charges: 29
	},
    {
        parent_id: 0,
        row_id: 3,
        row_label: 'Practice 2',
		number_impacted_visits: 14,
		company_id: 438,
		three_days_total_charge_amount: '75.00',
		three_days_total_charges: 3,
		seven_days_total_charge_amount: '75.00',
		seven_days_total_charges: 6,
		fourteen_days_total_charge_amount: '75.00',
		fourteen_days_total_charges: 2,
		twentyone_days_total_charge_amount: '75.00',
		twentyone_days_total_charges: 4,
		twentyone_plus_days_total_charge_amount: '75.00',
		twentyone_plus_days_total_charges: 6,
		total_amount_owed: '2480.00',
        total_charges: 21
    },
	{
        parent_id: 3,
        row_id: 4,
        row_label: 'Location 1',
        number_impacted_visits: 14,
        company_id: 438,
		three_days_total_charge_amount: '5.00',
		three_days_total_charges: 3,
		seven_days_total_charge_amount: '20.00',
		seven_days_total_charges: 6,
		fourteen_days_total_charge_amount: '50.00',
		fourteen_days_total_charges: 2,
		twentyone_days_total_charge_amount: '15.00',
		twentyone_days_total_charges: 4,
		twentyone_plus_days_total_charge_amount: '45.00',
		twentyone_plus_days_total_charges: 6,
		total_amount_owed: '135.00',
		total_charges: 21
	},
    {
        parent_id: 0,
        row_id: 5,
        row_label: 'Practice 3',
        number_impacted_visits: 10,
        company_id: 438,
        three_days_total_charge_amount: '45.00',
        three_days_total_charges: 3,
        seven_days_total_charge_amount: '95.00',
        seven_days_total_charges: 6,
        fourteen_days_total_charge_amount: '80.00',
        fourteen_days_total_charges: 2,
        twentyone_days_total_charge_amount: '14.00',
        twentyone_days_total_charges: 4,
        twentyone_plus_days_total_charge_amount: '1020.00',
        twentyone_plus_days_total_charges: 6,
        total_amount_owed: '1254.00',
        total_charges: 21
    },
	{
        parent_id: 5,
        row_id: 6,
        row_label: 'Location 1',
        number_impacted_visits: 10,
        company_id: 438,
		three_days_total_charge_amount: '5.00',
		three_days_total_charges: 3,
		seven_days_total_charge_amount: '20.00',
		seven_days_total_charges: 6,
		fourteen_days_total_charge_amount: '50.00',
		fourteen_days_total_charges: 2,
		twentyone_days_total_charge_amount: '15.00',
		twentyone_days_total_charges: 4,
		twentyone_plus_days_total_charge_amount: '45.00',
		twentyone_plus_days_total_charges: 6,
		total_amount_owed: '135.00',
		total_charges: 21
	}
];

//export const claim_hold_summary_grid_mock_insurance_class_data: claim_holds_summary_row_data[] = [
//	{
//		company_code: 'Auto Insurance',
//		number_impacted_visits: 7,
//		company_id: 438,
//		user_type_id: 1,
//		three_days_total_charge_amount: '75.00',
//		three_days_total_charges: 1,
//		seven_days_total_charge_amount: '75.00',
//		seven_days_total_charges: 5,
//		fourteen_days_total_charge_amount: '75.00',
//		fourteen_days_total_charges: 1,
//		twentyone_days_total_charge_amount: '75.00',
//		twentyone_days_total_charges: 5,
//		twentyone_plus_days_total_charge_amount: '75.00',
//		twentyone_plus_days_total_charges: 23,
//		total_amount_owed: '5375.00',
//		total_charges: 512,
//		details: [{
//			company_code: '1657 - Progressive Auto',
//			number_impacted_visits: 3,
//			three_days_total_charge_amount: '5.00',
//			three_days_total_charges: 2,
//			seven_days_total_charge_amount: '20.00',
//			seven_days_total_charges: 5,
//			fourteen_days_total_charge_amount: '50.00',
//			fourteen_days_total_charges: 2,
//			twentyone_days_total_charge_amount: '15.00',
//			twentyone_days_total_charges: 4,
//			twentyone_plus_days_total_charge_amount: '45.00',
//			twentyone_plus_days_total_charges: 7,
//			total_amount_owed: '135.00',
//			total_charges: 23
//		},
//		{
//			company_code: '1658 - Storer Injury Lawyers',
//			number_impacted_visits: 4,
//			three_days_total_charge_amount: '7.00',
//			three_days_total_charges: 5,
//			seven_days_total_charge_amount: '23.00',
//			seven_days_total_charges: 7,
//			fourteen_days_total_charge_amount: '42.00',
//			fourteen_days_total_charges: 4,
//			twentyone_days_total_charge_amount: '10.00',
//			twentyone_days_total_charges: 3,
//			twentyone_plus_days_total_charge_amount: '75.00',
//			twentyone_plus_days_total_charges: 6,
//			total_amount_owed: '157.00',
//			total_charges: 29
//		}]
//	},
//	{
//		company_code: 'Blue Cross',
//		number_impacted_visits: 14,
//		company_id: 438,
//		user_type_id: 1,
//		three_days_total_charge_amount: '75.00',
//		three_days_total_charges: 3,
//		seven_days_total_charge_amount: '75.00',
//		seven_days_total_charges: 6,
//		fourteen_days_total_charge_amount: '75.00',
//		fourteen_days_total_charges: 2,
//		twentyone_days_total_charge_amount: '75.00',
//		twentyone_days_total_charges: 4,
//		twentyone_plus_days_total_charge_amount: '75.00',
//		twentyone_plus_days_total_charges: 6,
//		total_amount_owed: '2480.00',
//		total_charges: 21,
//		details: [{
//			company_code: '1020 - UT Blue Cross',
//			number_impacted_visits: 14,
//			three_days_total_charge_amount: '5.00',
//			three_days_total_charges: 3,
//			seven_days_total_charge_amount: '20.00',
//			seven_days_total_charges: 6,
//			fourteen_days_total_charge_amount: '50.00',
//			fourteen_days_total_charges: 2,
//			twentyone_days_total_charge_amount: '15.00',
//			twentyone_days_total_charges: 4,
//			twentyone_plus_days_total_charge_amount: '45.00',
//			twentyone_plus_days_total_charges: 6,
//			total_amount_owed: '135.00',
//			total_charges: 21
//		}]
//	},
//	{
//		company_code: 'CIGNA',
//		number_impacted_visits: 10,
//		company_id: 438,
//		user_type_id: 1,
//		three_days_total_charge_amount: '45.00',
//		three_days_total_charges: 3,
//		seven_days_total_charge_amount: '95.00',
//		seven_days_total_charges: 6,
//		fourteen_days_total_charge_amount: '80.00',
//		fourteen_days_total_charges: 2,
//		twentyone_days_total_charge_amount: '14.00',
//		twentyone_days_total_charges: 4,
//		twentyone_plus_days_total_charge_amount: '1020.00',
//		twentyone_plus_days_total_charges: 6,
//		total_amount_owed: '1254.00',
//		total_charges: 21,
//		details: [{
//			company_code: '1656 - Lucent',
//			number_impacted_visits: 14,
//			three_days_total_charge_amount: '5.00',
//			three_days_total_charges: 3,
//			seven_days_total_charge_amount: '20.00',
//			seven_days_total_charges: 6,
//			fourteen_days_total_charge_amount: '50.00',
//			fourteen_days_total_charges: 2,
//			twentyone_days_total_charge_amount: '15.00',
//			twentyone_days_total_charges: 4,
//			twentyone_plus_days_total_charge_amount: '45.00',
//			twentyone_plus_days_total_charges: 6,
//			total_amount_owed: '135.00',
//			total_charges: 21
//		}]
//	}
//];

//export const claim_hold_summary_grid_mock_claim_hold_category_data: claim_holds_summary_row_data[] = [
//	{
//		company_code: 'Delayed Claims',
//		number_impacted_visits: 7,
//		company_id: 438,
//		user_type_id: 1,
//		three_days_total_charge_amount: '75.00',
//		three_days_total_charges: 1,
//		seven_days_total_charge_amount: '75.00',
//		seven_days_total_charges: 5,
//		fourteen_days_total_charge_amount: '75.00',
//		fourteen_days_total_charges: 1,
//		twentyone_days_total_charge_amount: '75.00',
//		twentyone_days_total_charges: 5,
//		twentyone_plus_days_total_charge_amount: '75.00',
//		twentyone_plus_days_total_charges: 23,
//		total_amount_owed: '5375.00',
//		total_charges: 512,
//		details: [{
//			company_code: 'Charges For Newly Imported Codes',
//			number_impacted_visits: 3,
//			three_days_total_charge_amount: '5.00',
//			three_days_total_charges: 2,
//			seven_days_total_charge_amount: '20.00',
//			seven_days_total_charges: 5,
//			fourteen_days_total_charge_amount: '50.00',
//			fourteen_days_total_charges: 2,
//			twentyone_days_total_charge_amount: '15.00',
//			twentyone_days_total_charges: 4,
//			twentyone_plus_days_total_charge_amount: '45.00',
//			twentyone_plus_days_total_charges: 7,
//			total_amount_owed: '135.00',
//			total_charges: 23
//		},
//		{
//			company_code: 'Evals missing Functional Reporting Codes',
//			number_impacted_visits: 4,
//			three_days_total_charge_amount: '7.00',
//			three_days_total_charges: 5,
//			seven_days_total_charge_amount: '23.00',
//			seven_days_total_charges: 7,
//			fourteen_days_total_charge_amount: '42.00',
//			fourteen_days_total_charges: 4,
//			twentyone_days_total_charge_amount: '10.00',
//			twentyone_days_total_charges: 3,
//			twentyone_plus_days_total_charge_amount: '75.00',
//			twentyone_plus_days_total_charges: 6,
//			total_amount_owed: '157.00',
//			total_charges: 29
//		}]
//	},
//	{
//		company_code: 'Charge Audit',
//		number_impacted_visits: 14,
//		company_id: 438,
//		user_type_id: 1,
//		three_days_total_charge_amount: '75.00',
//		three_days_total_charges: 3,
//		seven_days_total_charge_amount: '75.00',
//		seven_days_total_charges: 6,
//		fourteen_days_total_charge_amount: '75.00',
//		fourteen_days_total_charges: 2,
//		twentyone_days_total_charge_amount: '75.00',
//		twentyone_days_total_charges: 4,
//		twentyone_plus_days_total_charge_amount: '75.00',
//		twentyone_plus_days_total_charges: 6,
//		total_amount_owed: '2480.00',
//		total_charges: 21,
//		details: [{
//			company_code: 'Charge Review',
//			number_impacted_visits: 14,
//			three_days_total_charge_amount: '5.00',
//			three_days_total_charges: 3,
//			seven_days_total_charge_amount: '20.00',
//			seven_days_total_charges: 6,
//			fourteen_days_total_charge_amount: '50.00',
//			fourteen_days_total_charges: 2,
//			twentyone_days_total_charge_amount: '15.00',
//			twentyone_days_total_charges: 4,
//			twentyone_plus_days_total_charge_amount: '45.00',
//			twentyone_plus_days_total_charges: 6,
//			total_amount_owed: '135.00',
//			total_charges: 21
//		}]
//	},
//	{
//		company_code: 'Failed Charges',
//		number_impacted_visits: 10,
//		company_id: 438,
//		user_type_id: 1,
//		three_days_total_charge_amount: '45.00',
//		three_days_total_charges: 3,
//		seven_days_total_charge_amount: '95.00',
//		seven_days_total_charges: 6,
//		fourteen_days_total_charge_amount: '80.00',
//		fourteen_days_total_charges: 2,
//		twentyone_days_total_charge_amount: '14.00',
//		twentyone_days_total_charges: 4,
//		twentyone_plus_days_total_charge_amount: '1020.00',
//		twentyone_plus_days_total_charges: 6,
//		total_amount_owed: '1254.00',
//		total_charges: 21,
//		details: [{
//			company_code: 'Member not sending electronic claims',
//			number_impacted_visits: 14,
//			three_days_total_charge_amount: '5.00',
//			three_days_total_charges: 3,
//			seven_days_total_charge_amount: '20.00',
//			seven_days_total_charges: 6,
//			fourteen_days_total_charge_amount: '50.00',
//			fourteen_days_total_charges: 2,
//			twentyone_days_total_charge_amount: '15.00',
//			twentyone_days_total_charges: 4,
//			twentyone_plus_days_total_charge_amount: '45.00',
//			twentyone_plus_days_total_charges: 6,
//			total_amount_owed: '135.00',
//			total_charges: 21
//		}]
//	}
//];



