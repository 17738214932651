import { Popup } from "semantic-ui-react";
import * as React from "react";

const GridCellTooltip = (props) => {
  return (
    <Popup
      trigger={<span>{props.value}</span>}
      content={props.value}
      on={"hover"}
      wide={"very"}
      flowing
      hoverable
      hideOnScroll
    />
  );
};

export default GridCellTooltip;
