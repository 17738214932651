export class ProviderBillingService {
    private dataEmptyMatching(dataLeft: string[any], dataRight: string[any]): boolean {
        return dataLeft.length == 0 && dataRight.length == 0;
    }

    private dataGroupMatching(dataLeft: string[any], dataRight: string[any]): boolean {
        let hasMatched = false;

        for (let data of dataLeft) {
            if (dataRight.includes(data)) {
                hasMatched = true;
                break;
            }
        }

        return hasMatched;
    }

    public dataRestrictionMet(elementToCheck: any, indexElement: number, dataToCompare: any[]): boolean {
        let row: any;
        let rowIndex: number = undefined;
        let dataRestrictionFound: boolean = false;

        for (row of dataToCompare) {
            rowIndex = (typeof rowIndex == 'number') ? rowIndex + 1 : 0;
            if (rowIndex == indexElement || !row.status) {
                continue;
            }

            const sameLocation = this.dataEmptyMatching(elementToCheck.location_ids, row.location_ids) || this.dataGroupMatching(elementToCheck.location_ids, row.location_ids);
            const sameInsuranceClass = this.dataEmptyMatching(elementToCheck.insurance_class_ids, row.insurance_class_ids) || this.dataGroupMatching(elementToCheck.insurance_class_ids, row.insurance_class_ids);
            const sameInsuranceCode = this.dataEmptyMatching(elementToCheck.insurance_code_ids, row.insurance_code_ids) || this.dataGroupMatching(elementToCheck.insurance_code_ids, row.insurance_code_ids);

            if (!sameLocation || !sameInsuranceClass || !sameInsuranceCode) {
                continue;
            }

            dataRestrictionFound = true;
            break;
        }

        return dataRestrictionFound;
    }

    public rollbackToPreviousSelectedItems(dataIncoming: any[]): void {
        if (!dataIncoming[2]) {
            return;
        }
        const nameElement: string = Object.keys(dataIncoming[2])[0];
        const instance: any = dataIncoming[0][nameElement];
        const previousValues: string[] = dataIncoming[1][nameElement];
        const newValues: string[] = dataIncoming[2][nameElement];

        if (previousValues != newValues) {
            instance.update_to_specific_selections_without_notification([...previousValues]);
        }
    }
}