import * as React from 'react';
import { connect } from "react-redux";
import GridView from '../../shared/component/grid';
import * as charge_constants from './../charge_constant';
import SelectionComponent from "../../shared/component/selection_component";
import { Button, Dimmer, Form, Grid, Input, Loader } from 'semantic-ui-react';
import { initial_load, reactivate, search } from '../action/reactivate_charge_action';
import { toastr_options } from '../../global_constants';
import { get_all_error, is_null_or_white_space, is_valid_int32 } from '../../shared/utility';
import { toastr } from 'react-redux-toastr';
import ReportHeader from '../../reports/component/report_header';
import { messages, metadata } from '../constant/reactivate_constants';

class ReactivateComponent extends React.Component<any, any> {
    is_mounted: boolean = false;
    metadata: any = {};

    constructor(props: any) {
      super(props);
        this.state = {
            grid_configuration : {
                columns : charge_constants.reactivate_charges_configuration.column_defs,
                rows: [],
            },
            search_criteria: {
                charge_id: "",
                gpms_code: props.user_login_details.user_data.data.gpms_code,
            },
            is_search_disabled: false,
            company_options: [],
            is_grid_visible: false,
            is_error: false,
            is_reactivate_disabled: true,
            patient_id: 0,
        }
    };

    componentDidMount = () => {
        this.is_mounted = true;
        this.metadata = metadata;
        document.body.classList.add("reports");

        this.initial_load();
    };

    componentWillUnmount = () => {
        this.is_mounted = false;
        document.body.classList.remove("reports");
    };

    input_change_handler = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            search_criteria: { ...prevState.search_criteria, [name]: value },
            is_error: value ? false : true
        }));
    };

    dropdown_change_handler = (event: {
      target: { selectedOptions?: any; options?: any; selectedIndex?: any; id?: any; value?: any };
    }) => {
      const { id, value } = event.target;

      this.setState(prevState => ({
        search_criteria: { ...prevState.search_criteria, [id]: value },
    }));
    };

    handle_clear = () => {
        this.setState({
            is_search_disabled: false,
            search_criteria: {
                charge_id: "",
                gpms_code: this.props.user_login_details.user_data.data.gpms_code,
            },
            grid_configuration : {
                columns : charge_constants.reactivate_charges_configuration.column_defs,
                rows: [],
            },
            loading: false,
            is_error: false,
            is_reactivate_disabled: true,
        });
    };

    handle_cancel = () => {
        this.setState({
            is_search_disabled: false,
            loading: false,
            is_error: false,
            grid_configuration : {
                columns : charge_constants.reactivate_charges_configuration.column_defs,
                rows: [],
            },
            is_grid_visible: false,
            is_reactivate_disabled: true,
        });
    };

    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    };

    initial_load = async () => {
        this.setState({
            loading: true,
            is_search_disabled: true,
        });

        var token = this.props.user_login_details.user_data.data.token_details.access_token;
        await initial_load(token).then(
            (response) => {
                if (response.data && response.data.status === 1) {
                    let company_options = response.data.data.map((val) => {
                        return { key: val.id, value: val.gpms_code, text: `${val.gpms_code} - ${val.name}` };
                    });

                    this.setState({
                        loading: false,
                        company_options: company_options,
                        is_search_disabled: false,
                        is_reactivate_disabled: true,
                    });
                } else {
                    const toastr_options = this.show_html_content_toaster(get_all_error(response.data.data));
                    toastr.error('', toastr_options);
                    this.setState({
                        is_search_disabled: false,
                        loading: false,
                        company_options: [],
                        is_reactivate_disabled: true,
                    });
                    this.handle_clear();
                }
            },
            (error) => {
                this.handle_clear();
            }
        );
    };

    bindEventToChargeSelector = () => {
        let reactivateChargeCheckbox = document.getElementById('checkb_is_charge') as HTMLInputElement;

        if (reactivateChargeCheckbox) {
            let that = this;
            reactivateChargeCheckbox.addEventListener('change', (event: any) => {
                that.onChargeSelected(event.target.checked);
            });
        }
    };

    onChargeSelected = (isChecked: boolean) => {
        this.setState({
            is_reactivate_disabled: !isChecked,
        });

        this.bindEventToChargeSelector();
    };

    search = async () => {
        if (is_null_or_white_space(this.state.search_criteria.charge_id)) {
            this.setState({ is_error: true });
            toastr.error('', messages.required_charge_id);
            return;
        }

        if (!is_valid_int32(this.state.search_criteria.charge_id)) {
            this.setState({ is_error: true });
            toastr.error('', messages.invalid_charge_id);
            return;
        }

        this.setState({
            loading: true,
            is_search_disabled: true,
            is_reactivate_disabled: true,
        });

        let data = {
            charge_id: this.state.search_criteria.charge_id && this.state.search_criteria.charge_id != "" ? this.state.search_criteria.charge_id : 0,
            gpms_code: this.state.search_criteria.gpms_code,
        };

        var token = this.props.user_login_details.user_data.data.token_details.access_token;
        await search(token, data).then(
            (response) => {
                if (response.data && response.data.status === 1) {
                    this.setState({
                        grid_configuration : {
                            ...this.state.grid_configuration,
                            rows: response.data.data
                        },
                        loading: false,
                        is_search_disabled: false,
                        is_grid_visible: true,
                        is_reactivate_disabled: true,
                    });
                } else {
                    this.setState({
                        grid_configuration : {
                            ...this.state.grid_configuration,
                            rows: []
                        },
                        loading: false,
                        is_search_disabled: false,
                        is_grid_visible: false,
                        is_reactivate_disabled: true,
                    });
                    
                    if (response && response.data && response.data.messages) {
                        toastr.error('', response.data.messages[0].message);
                    }
                }
            },
            (error) => {
                this.setState({
                    loading: false,
                    is_search_disabled: false,
                    grid_configuration : {...this.state.grid_configuration, rows: []},
                    is_grid_visible: false,
                    is_reactivate_disabled: true,
                });
                if (error.response && error.response.data && error.response.data.messages) {
                    toastr.error('', error.response.data.messages[0].message);
                }
            }
        ).then(() => {
            this.bindEventToChargeSelector();
        });
    };

    handle_reactivate = async () => {
        this.setState({
            loading: true,
            is_search_disabled: true,
            is_reactivate_disabled: true,
        });

        let reactivateChargeCheckbox = document.getElementById('checkb_is_charge') as HTMLInputElement;
        let chargeSelectedForReactivation = reactivateChargeCheckbox.checked;

        if (!chargeSelectedForReactivation) {
            this.setState({ is_error: true });
            toastr.error('', 'You must select a charge to reactivate.');
            return;
        }

        let chargeIdInput = document.getElementById('charge_id') as HTMLInputElement;
        let charge_id = chargeIdInput.value;
        let clinicCodeControl = document.getElementById('gpms_code') as HTMLSelectElement;
        let clinicCode = clinicCodeControl.value;
        let reactivateVisitCheckbox = document.getElementById('checkb_is_visit') as HTMLInputElement;
        let reactivateVisit = reactivateVisitCheckbox.checked;
        // There has to be a better way to obtain the patient id from the grid, this feels hacky
        let patientId = "";
        let patientIdContainers = document.querySelectorAll('[col-id="patient_id"]');

        if (patientIdContainers && patientIdContainers.length > 1) {
            let patientIdContainer = patientIdContainers.item(1) as HTMLDivElement;
            patientId = patientIdContainer.innerText;
        }

        let data = {
            charge_id: charge_id,
            gpms_code: clinicCode,
            activate_visit: reactivateVisit,
            patient_id: patientId,
        };

        var token = this.props.user_login_details.user_data.data.token_details.access_token;
        reactivate(token, data).then(
            (response) => {
                if (response.data && response.data.status === 1) {
                    let successMessage = "Charge successfully reactivated.";

                    if (reactivateVisit) {
                        successMessage = "Charge and associated visit successfully reactivated.";
                    }
                    toastr.success("", successMessage);

                    this.setState({
                        is_search_disabled: false,
                        loading: false,
                        is_error: false,
                        grid_configuration : {
                            columns : charge_constants.reactivate_charges_configuration.column_defs,
                            rows: [],
                        },
                        search_criteria: {
                            charge_id: "",
                            gpms_code: this.props.user_login_details.user_data.data.gpms_code,
                        },
                        is_grid_visible: false,
                        is_reactivate_disabled: true,
                    });
                } else {
                    this.setState({
                        grid_configuration : {
                            ...this.state.grid_configuration,
                            rows: []
                        },
                        loading: false,
                        is_search_disabled: false,
                        is_grid_visible: false,
                        is_reactivate_disabled: true,
                    });
                    toastr.error('', "There was a problem reactivating the charge, please try again.");
                }
            },
            (error) => {
                this.setState({
                    loading: false,
                    is_search_disabled: false,
                    grid_configuration : {...this.state.grid_configuration, rows: []},
                    is_grid_visible: false,
                    is_reactivate_disabled: true,
                });
                if (error.response && error.response.data && error.response.data.messages) {
                    toastr.error('', error.response.data.messages[0].message);
                }
            }
        );
    };

    render() {
        const { loading, is_search_disabled, search_criteria, company_options, is_grid_visible, is_error, is_reactivate_disabled } = this.state;

        return (
            <div className={"common-forms-add"}>
                <Dimmer active={loading}>
                    <Loader size="massive">Loading</Loader>
                </Dimmer>

                <div className={"common-forms-search report-framework"}>
                    <ReportHeader title={this.metadata.title} />
                    <div
                        id="report-scrollable-area"
                        className="report-wrapper"
                        style={is_grid_visible ? { flex: 1 } : {}}
                    >
                        <div className="patient-search-form  patient_search_bottom_padding" id="report-criteria-container">
                            <Form autoComplete='off'>
                                <Grid>
                                    <Grid.Column tablet={4} computer={4}>
                                        <Form.Field>
                                            <label>Company</label>
                                            <SelectionComponent
                                            id="gpms_code"
                                            name="gpms_code"
                                            options={company_options}
                                            onChange={(value, event) => {
                                                this.dropdown_change_handler(event);
                                            }}
                                            defaultValue={search_criteria.gpms_code}
                                            style={"dropdown-options-wrap"}
                                            />
                                        </Form.Field>
                                    </Grid.Column>

                                    <Grid.Column tablet={4} computer={4}>
                                        <Form.Field>
                                            <label>Charge ID <span className={is_error ? "req-alert" : 'req-alert_normal'}>(required)</span></label>
                                                <Input
                                                    autoComplete='off'
                                                    name='charge_id'
                                                    id='charge_id'
                                                    maxLength={45}
                                                    type='text'
                                                    value={search_criteria.charge_id}
                                                    onChange={(e) => this.input_change_handler(e)}
                                                    className={is_error ? 'req-border-inp': "" }
                                                    error={is_error}
                                                />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid>

                                <Grid style={{ margin: '0 0px' }}>
                                    <Grid.Column computer={16} textAlign='right'>
                                        <Button id='clear_button_id' type='button' onClick={this.handle_clear}>Clear</Button>
                                        <Button id='search_button_id' disabled={is_search_disabled} type='submit' primary onClick={this.search}>Search</Button>
                                    </Grid.Column>
                                </Grid>
                            </Form>
                        </div>
                        {is_grid_visible && (
                            <React.Fragment>
                                <Grid style={{ marginTop: '-1rem', marginBottom: 0 }}>
                                    <Grid.Column tablet={5} computer={4} textAlign='left'>
                                        <p style={{ fontSize: '16px' }}>Search Results</p>
                                    </Grid.Column>
                                    <Grid.Column tablet={11} computer={12} textAlign='right' />
                                </Grid>
                                <GridView
                                    id="reactivate_charges_grid"
                                    row={this.state.grid_configuration.rows}
                                    column={this.state.grid_configuration.columns}
                                    style={{ height: '100%' }}
                                    wrapperStyle={{ width: '100%', height: '100%', display: 'flex' }}
                                    suppressMovableColumns={false}
                                    enableColResize={true}
                                    selectionType={'single'}
                                    suppressSizeToFit={true}
                                />
                            </React.Fragment>
                        )}
                    </div>
                    {is_grid_visible && (
                        <div className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                            id='applicationFooterSticky'
                            style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <Grid.Column computer={16} textAlign='right'>
                                <Button type="button" id="cancel_data" onClick={this.handle_cancel} basic >Cancel</Button>
                                <Button primary id='reactivate_charge' disabled={is_reactivate_disabled} type='button' style={{ marginRight: 0 }} onClick={this.handle_reactivate}>
                                    Reactivate
                                </Button>
                            </Grid.Column>
                        </div>
                    )}
                </div>
            </div>
        );
    };
}

const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
        shared_details: state.shared_details,
    };
};

  export default connect(mapStateToProps)(ReactivateComponent);
