import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Dimmer, Form, Grid, Header, Loader, Modal } from "semantic-ui-react";
import * as global_constants from '../../global_constants';
import { update_patient_header_info } from '../../patient/action/patient_header_action';
import { GRID_NAME } from '../../shared/component/grid/constants';
import GridViewGroup from '../../shared/component/grid/grid_group_component';
import * as session_storage from '../../shared/session_storage_utility';
import { custom_date_format, get_all_error } from './../../shared/utility';
import { delete_charge, get_patient_view_charge_list } from './../action/charge_action';
import { charge_messages, currencyFormatter, patient_charge_configuration, view_charge_grid_header_id } from './../charge_constant';
import { set_focus_to_app_header, set_focus_on_element_with_id, handle_navigation_on_modal, handle_focus_on_tab } from './../../shared/tab_navigation_utility';
import ConfirmationComponent from '../../shared/component/confirmation_component';

export class ViewChargeComponent extends React.Component<any, any> {
    [x: string]: any;
    _is_mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            patient_charge_list: [],
            show_delete_warning_charge_popup: false,
            selected_charge_list: [],
            footer_row_data: [],
            show_grid: false,
            show_bummepd_charge_message_popup:false,
            charge_popup_message: '',
            charge_popup_title: '',
            charge_popup_show_cancel: true,
            charge_popup_ok_btn_text: '',
            charge_popup_action: () => {}
        }

        this.grid_reference = React.createRef();
    }
    patient_id = session_storage.get('active_patient');
    
    componentDidMount = () => {
        this._is_mounted = true;
        this.patient_id = session_storage.get('active_patient');
        document.body.classList.add('view_charges');
        if (this.patient_id == '' || this.patient_id == undefined) {
            session_storage.set('no_patient_selected_for', this.props.location.pathname);
            this.props.history.push(`/no_patient_selected`);

        } else {
            this.get_patient_charge_list();
        }

    };

    componentWillUnmount = () => {
        this._is_mounted = false;
        document.body.classList.remove('view_charges', 'patient-header', 'patient-subHeader');
        const sideBars = document.getElementsByClassName("ui dropdown");
        const lastSideBar = sideBars[sideBars.length - 1];
        lastSideBar.removeEventListener("keydown", this.last_side_bar_event_listener, true);
    }

    componentDidUpdate(prevProps, prevState) {
        //change page data if patent changes from header, instead of navigate to other page
        if (prevProps.patient_id != this.props.patient_id) {
            this.patient_id = session_storage.get("active_patient");
            this.get_patient_charge_list();
        }

    }

    //Get Data from api's
    get_patient_charge_list = async () => {
        if (this._is_mounted) {
            this.setState({
                loading: true,
                selected_charge_list:[]
            });
        }
        await get_patient_view_charge_list(this.props.user_login_details.user_data.data.token_details.access_token, this.patient_id).then(response => {
            if (response.data.data) {
                if (response.data.data.length > 0) {
                    var total_unit = 0;
                    var total_charge_amount = 0;
                    var total_payment_amount = 0;
                    var total_adjustment_amount = 0;
                  
                    response.data.data.map((charge_item) => {
                        charge_item.last_billed_date = charge_item.last_billed_date ? custom_date_format(charge_item.last_billed_date, global_constants.date_format["mm/dd/yyyy"]) : '';
                        charge_item.date_of_service = charge_item.date_of_service ? custom_date_format(charge_item.date_of_service, global_constants.date_format["mm/dd/yyyy"]) : '';
                        charge_item.start_of_care_date = charge_item.start_of_care_date ? custom_date_format(charge_item.start_of_care_date, global_constants.date_format["mm/dd/yyyy"]) : '';

                        total_unit = total_unit + (charge_item.units);                        
                        total_charge_amount = total_charge_amount + (charge_item.total_amount);
                        charge_item.total_amount = currencyFormatter((charge_item.total_amount));

                        total_payment_amount = total_payment_amount + (charge_item.payment_amount);
                        charge_item.payment_amount = currencyFormatter((charge_item.payment_amount));

                        total_adjustment_amount = total_adjustment_amount + (charge_item.adjusted_amount);
                        charge_item.adjusted_amount = currencyFormatter((charge_item.adjusted_amount));


                        var charge_case_desc = '';
                        charge_case_desc = charge_case_desc + (charge_item.injury_disc ? charge_item.injury_disc : '');
                        charge_case_desc = charge_case_desc + (charge_item.injury_date ? " - " + custom_date_format(charge_item.injury_date, global_constants.date_format["mm/dd/yyyy"]) : '');

                        charge_case_desc = charge_case_desc + (charge_item.effective_date ? " - Eff: " + custom_date_format(charge_item.effective_date, global_constants.date_format["mm/dd/yyyy"]) : '');
                        charge_item.injury_disc = charge_case_desc;
                        charge_item.selected = false;

                    });
                    var footer_obj = {};
                    patient_charge_configuration.footer_def.map((value, index) => {                        
                        if (index == 0) {
                            footer_obj[value.field] = "Total"
                        }
                        else if (value.type == 'currency' || value.type == 'number') {
                            footer_obj[value.field] = 0;
                        } else {
                            footer_obj[value.field] = '';
                        }
                    });

                    footer_obj['units'] = total_unit;
                    footer_obj['total_amount'] = currencyFormatter(total_charge_amount);
                    footer_obj['payment_amount'] = currencyFormatter(total_payment_amount);
                    footer_obj['adjusted_amount'] = currencyFormatter(total_adjustment_amount);
                    if (this._is_mounted) {
                        this.setState({
                            patient_charge_list: response.data.data,
                            footer_row_data :[footer_obj],
                            loading: false,
                            show_grid:true
                        });
                    }

                } else {
                    if (this._is_mounted) {
                        this.setState({
                            patient_charge_list: [],
                            footer_row_data:[],
                            loading: false,
                            show_grid: true
                        });
                    }
                }
            }
            else {
                if (this._is_mounted) {
                    this.setState({
                        patient_charge_list: [],
                        footer_row_data: [],
                        loading: false,
                        show_grid: true
                    });
                }
            }
        });
    }
    
    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }

    delete_charges = () => {
        var selected_charge = [];
        this.state.selected_charge_list.map(function (row, index) {
            if (row.allow_edit) {
                selected_charge.push({ charge_id: row.charge_id, charge_open_time: row.charge_open_time });
            }
        });

        if (selected_charge.length == 0) {
            toastr.warning('', charge_messages.no_charge_selected);
        } else {
            if (this._is_mounted) {
                handle_navigation_on_modal("delete_confirmation_modal_close_icon", "btnDeleteWarning");
                this.apply_default_focus_on_modal();
                this.setState({
                    show_delete_warning_charge_popup: true
                })
            }
        }
    }

    apply_default_focus_on_modal = () => {
        setTimeout(() => {
            set_focus_on_element_with_id("btnDeleteCancleWarning");
        }, 500)
    }
    close_delete_warning_charge_popup = () => {
        if (this._is_mounted) {
            set_focus_on_element_with_id("charge_Delete_btn");
            this.setState({
                show_delete_warning_charge_popup: false
            })
        }
    }

    yes_delete_warning_charge_popup = () => {
        var selected_charge = [];
        this.state.selected_charge_list.map(function (row, index) {
            if (row.allow_edit) {
                selected_charge.push({ charge_id: row.charge_id, charge_open_time: row.charge_open_time });
            }
        });

        if (this._is_mounted) {
            this.setState({
                loading: true
            });
        }
        delete_charge(this.props.user_login_details.user_data.data.token_details.access_token, selected_charge).then(res=> {
            let response: global_constants.R6ResponseData = res.data;
            if (response.data) {
                if (this._is_mounted) {
                    this.setState({
                        show_delete_warning_charge_popup: false,
                        loading: false,
                    })
                }
                toastr.success('', charge_messages.charges_deleted);
                this.get_patient_charge_list();
            }
            else {
                if (this._is_mounted) {
                    this.setState({
                        patient_charge_list: [],
                        footer_row_data: [],
                        loading: false,
                        show_delete_warning_charge_popup: false
                    });
                }
                if (response.messages.length > 0 && response.messages[0].code == 500006) {
                    this.show_modal_popup(global_constants.charge_contains_payment_exception.title,
                        global_constants.charge_contains_payment_exception.message, "OK", this.on_close_bummepd_charge_popup, false);
                }
                else if (response.messages.length > 0) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(response));
                    toastr.error('', toastr_options);
                }

            }
        }, (error) => {           
            let charge_exception = error.response.data.messages.filter((item) => {
                return item.code === global_constants.charge_exception.code
            })
            if (charge_exception && charge_exception.length > 0) {
                if (this._is_mounted) {
                    this.setState({
                        loading: false,
                        show_bummepd_charge_message_popup: true,
                        show_delete_warning_charge_popup: false
                    })
                    this.show_modal_popup('Confirm', global_constants.charge_exception.message, 'Refresh', this.refresh_bummepd_charge_modal);
                }
            }
            else {
                if (this._is_mounted) {
                    this.setState({
                        loading: false,
                        show_delete_warning_charge_popup: false
                    });
                }
                if (error.response.data) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toastr.error('', toastr_options);
                }
            }
        })
    }

    edit_charge = (for_visit) => {
        var selected_charge = [];
        this.state.selected_charge_list.map(function (row, index) {
            if (row.allow_edit) {
                selected_charge.push(row.charge_id);
            }
        });

        if (selected_charge.length == 0) {
            toastr.warning('', charge_messages.no_charge_selected);
        } else if (selected_charge.length > 1){
            toastr.warning('', charge_messages.select_single_charge);
        } else {
            this.props.history.push({
                pathname: '/charge/edit_charge/' + selected_charge[0],
                search: '',
                state: { charge_visit: for_visit }
            })
        }
    }
    
    on_document_check_changed = items => {
        if (this._is_mounted) {
            this.setState({
                selected_charge_list: [...items]
            });
        }
    }

    handle_after_grid_is_ready = () => {
        // when thr grid is ready setting the focus to the grid header
        this.set_focus_to_first_cell_of_the_header();
        // this is applied because view charge has horizontall scroll
        // when it is scrolled, the header ist cell focus is lost,
        // its keydown event is also gets lost
        const sideBars = document.getElementsByClassName("ui dropdown");
        const lastSideBar = sideBars[sideBars.length - 1];
        let _this = this;
        lastSideBar.addEventListener('keydown', this.last_side_bar_event_listener);
    }

    last_side_bar_event_listener = (event) => {
        let keycode = event.keyCode || event.which;
        if (keycode == 9 && !event.shiftKey) {
            if (document.getElementById("view_charge_grid")) { 
                if (this.state.patient_charge_list && (this.state.patient_charge_list.length > 0)) {
                    //scrolling grid column to the left
                    this.grid_reference.ensureScrollIsLeft();
                    this.grid_reference.handle_first_tab();
                    let _this = this;
                    setTimeout(() => {
                        _this.set_focus_to_first_cell_of_the_header();
                    }, 0);
                }
            }
        }
    }

    set_focus_to_first_cell_of_the_header = () => {
        let firstColumnsOfGrid: NodeListOf<HTMLElement> = document.getElementById("view_charge_grid").querySelectorAll('.ag-header-cell:not(.ag-header-cell-sortable)') as NodeListOf<HTMLElement>;
        let firstColumnOfGrid = firstColumnsOfGrid[0]
        firstColumnOfGrid.setAttribute("tabindex", "0");
        firstColumnOfGrid.focus();
    }

    throw_me_out_from_the_grid = () => {
        set_focus_on_element_with_id("charge_Delete_btn")
    }

    // UI Control Section

    show_modal_popup(title: string, message: string, okBtnText: string, action: () => any, showCancel: boolean = true){
        if (this._is_mounted) {
            this.setState({
                loading: false,
                show_bummepd_charge_message_popup: true,
                charge_popup_message: message,
                charge_popup_title: title,
                charge_popup_ok_btn_text: okBtnText,
                charge_popup_show_cancel: showCancel,
                charge_popup_action: action
            })
        }
    }

    refresh_bummepd_charge_modal = () => {
        if (this._is_mounted) {
            this.setState({
                show_bummepd_charge_message_popup: false
            }, () => {
                this.get_patient_charge_list();
            });
        }
    }
    on_close_bummepd_charge_popup = () => {
        if (this._is_mounted){
            this.setState({
                show_bummepd_charge_message_popup: false
            }, this.get_patient_charge_list);
        }
    }

    render() {
        return (
            <React.Fragment>
                <Dimmer active={this.state.loading}>
                    <Loader size="massive">Loading</Loader>
                </Dimmer>
                <div className="common-forms-add padd-b-0">
                    {
                        this.state.patient_charge_list.length > 0 ?
                            <Header as='h3' onKeyDown={this.reset_grid_navigation} textAlign="left">
                                View Charges
                        </Header>
                            :
                            <Header as='h3' onKeyDown={this.reset_grid_navigation} dividing textAlign="left">
                                View Charges
                        </Header>
                    }
                    {
                        this.state.show_grid &&
                        (this.state.patient_charge_list.length > 0  ?
                            <div className='auto-height-patient-scroll' id="scrollable-content-area">                                
                                <Form autoComplete="off" className="patient-add-form padd-b-0" style={{ height: '100%' }}>
                                    <div className="common-forms-add" style={{ padding: "0", height: '100%'  }}>
                                        <GridViewGroup
                                            id={'view_charge_grid'}
                                            style={{ height: '100%' }}
                                            onRef={instance => { this.grid_reference = instance; }}
                                            name={GRID_NAME.VIEW_CHARGE}
                                            rowWrap={true}
                                            wrapperStyle={{ width: '100%', height: '100%', display: 'flex' }}
                                            row={this.state.patient_charge_list}
                                            column={patient_charge_configuration.column_defs}
                                            checkboxSelection={true}
                                            headerHeight={patient_charge_configuration.grid_header_height}
                                            pinnedBottomRowData={this.state.footer_row_data}
                                            onRowSelection={this.on_document_check_changed}
                                            get_grid_ref={this.handle_after_grid_is_ready}
                                            onGridOut={this.throw_me_out_from_the_grid}
                                            handleCheckboxNavigation={true}
                                          />
                                    </div>
                                </Form>
                            </div>
                            :
                            <Grid>
                                <Grid.Column mobile={16} tablet={16} computer={16} className="no-patient-found">
                                    <i aria-hidden="true" className="warning sign icon"></i>
                                    <span> No Charges Available </span>
                                </Grid.Column>
                            </Grid>
                        )
                    }
                    {this.state.show_grid &&
                        (this.state.patient_charge_list.length > 0 &&
                            <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area" id="applicationFooterSticky">
                                <Button id="charge_Delete_btn" type='button' basic onClick={this.delete_charges} >Delete</Button>
                                <Button id="charge_edit_all_btn" type='button' basic onClick={() => this.edit_charge(true)} >Edit all Charges for Visit</Button>
                                <Button id="charge_edit_btn" onKeyDown={set_focus_to_app_header} type='button' primary onClick={() => this.edit_charge(false)}>Edit Charge</Button>
                            </div>
                        )
                    }
                </div>
                <Modal
                    onClose={this.close_delete_warning_charge_popup}
                    centered={false}
                    className=" default-modal"
                    open={this.state.show_delete_warning_charge_popup}
                    closeIcon={true}
                    id="deleteChargePopup"
                    closeOnDimmerClick={false}
                >

                    <Modal.Header>
                        <h3>Confirm</h3>
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <div className="common-forms" style={{ padding: "0" }}>
                                <p>{charge_messages.delete_charge_confirm}</p>
                            </div>
                            <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area padd-r-0">
                                <Button id="btnDeleteCancleWarning" type="button" basic onClick={() => this.close_delete_warning_charge_popup()}>
                                    No
                                 </Button>
                                <Button id="btnDeleteWarning" onKeyDown={(event) => { handle_focus_on_tab(event, "delete_confirmation_modal_close_icon");}}type="button" primary onClick={() => this.yes_delete_warning_charge_popup()}>
                                    Yes
                                </Button>
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
                {this.state.show_bummepd_charge_message_popup ?
                    <ConfirmationComponent message={this.state.charge_popup_message}
                        title={this.state.charge_popup_title} show_modal={this.state.show_bummepd_charge_message_popup}
                        onCloseModal={this.on_close_bummepd_charge_popup} showCancelButton={this.state.charge_popup_show_cancel}
                        save_button_text={this.state.charge_popup_ok_btn_text}
                        on_force_save_click={(e) => {this.state.charge_popup_action()}} />
                    : ''}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        patient_id: state.patient_details && state.patient_details.patient_header && state.patient_details.patient_header.data && state.patient_details.patient_header.data.id || ''
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        update_patient_header_info: update_patient_header_info,
    }, dispatch)
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewChargeComponent));