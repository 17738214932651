import * as React from "react";
import { connect } from "react-redux";
import { toastr as toaster } from "react-redux-toastr";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Button, Dimmer, Grid, Loader } from "semantic-ui-react";
import { toastr_options } from "../../global_constants";
import { currencyFormatter, dateFormatter, messages } from "../../reports/report_constants";
import { print_grid_data, export_grid_data, generateAuditDataEntryPayload } from "../../reports/util/export_print_utility";
import { log_error } from "../../shared/action/shared_action";
import GridView from "../../shared/component/grid";
import { set_focus_on_element_with_id, set_focus_to_app_header } from "../../shared/tab_navigation_utility";
import * as ReportConstants from '../../reports/report_constants';
import { get_charge_pre_audit_summary } from "../action/charge_action";
import {
    charge_audit_configuration,
    charge_audit_configuration_grid_header_id
} from "../charge_constant";
import * as PrintExportConstants from '../../shared/audit/print_export_constants';
import moment = require("moment");
import { DataEntry } from "../../shared/audit/print_export_constants";
import { ChargesBillingAuditLogEpicsFeature } from "../../admin/constants/constants";
import { get_lauch_darkley_key_value } from "../../shared/utility";

export class ChargeAuditComponent extends React.Component<any, any> {
    constructor(props) {
        super(props);
        // Define initial state value.
        this.state = {
            show_grid: false,
            is_loading: false,
            shown: false,
            grid_conf: {},
            grid_rows_count: 0,
            advance_search_value: {},
            disable_charge_release_button: false,
            grid_data: {},
            charge_audit_footer_obj: {},
            selected_charge_list: []
        };
    }

    // Define initial properties and its value.
    is_mounted = false;
    page_name: string = "";
    page_metadata: any;
    token: string = "";
    title = "";
    current_date: any;
    company_name: any;
    total_column_width: number = 0;
    row_data: any[];
    grid_header_height: number;
    footer_object = {};
    user_type_id: any;
    component_did_update:boolean = false;
    reportId: string = '0';

    UNSAFE_componentWillMount = () => {
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.user_type_id = this.props.user_login_details.user_data.data.user_type_id;
        document.body.classList.add("admin-framework");
    };

    componentDidMount() {
        this.is_mounted = true;
        this.get_page_metadata();
    }

    //Use to load multiple page with same component
    componentDidUpdate = () => {
        if (!this.component_did_update && "/charge/charge_audit" == this.props.match.path) {
            this.component_did_update = true;
            document.body.classList.add("admin-framework");
        }
    };

    componentWillUnmount = () => {
        document.body.classList.remove("admin-framework");
    };

    get_page_metadata = async () => {
        if (this.is_mounted) {
            this.setState({
                is_loading: true,
                show_grid: false
            });
        }
        this.page_metadata = charge_audit_configuration(this.props);
        this.page_name = this.page_metadata.header;
        await get_charge_pre_audit_summary(this.token).then(
            (response) => {
                if (response.data.status == 1) {
                    this.row_data = [...this.row_data_formatter(response.data.data)];
                    if (this.is_mounted) {
                        this.setState({
                            is_loading: false,
                            show_grid: true
                        });
                    }
                } else {
                    if (response.data.messages && response.data.messages[0].message.indexOf("<br") > -1) {
                        this.show_html_content_toaster(response.data.messages[0].message);
                    } else {
                        toaster.error("", response.data.messages[0].message);
                    }
                    if (this.is_mounted) {
                        this.setState({
                            loading: false,
                            show_grid: false
                        });
                    }
                }
            },
            (error) => {
                this.setState({
                    loading: false,
                    show_grid: false
                });

                log_error(error);
                if (error.data.messages[0].message.indexOf("<br") > -1) {
                    this.show_html_content_toaster(error.response.data.messages[0].message);
                } else {
                    toaster.error("", error.response.data.messages[0].message);
                }
            }
        );
    };

    //Function used to initialize the footer object.
    initialize_footer_obj = (column_def) => {
        var footer_obj = {};
        column_def.map((value, index) => {
            if (value.children) {
                value.children.map((item, newIndex) => {
                    if (index == 0 && newIndex == 0) {
                        footer_obj[item.field] = "Total";
                    } else if (item.type == "currency" || item.type == "number" || item.type == "link") {
                        footer_obj[item.field] = 0;
                    } else {
                        footer_obj[item.field] = null;
                    }
                });
            }
        });
        return footer_obj;
    };

    //FUnction use to format the grid data on load.
    row_data_formatter = (data) => {
        var footer_obj = this.initialize_footer_obj(this.page_metadata.column_def);
        data.map((row: { [x: string]: any }) => {
            this.page_metadata.column_def.map((item) => {
                if (item.children) {
                    item.children.map((value) => {
                        switch (value.type) {
                            case "date":
                                row[value.field] =
                                    row[value.field] != null || row[value.field] != undefined
                                        ? dateFormatter(row[value.field])
                                        : "";
                                break;
                            case "currency":
                                footer_obj[value.field] += row[value.field] ? parseFloat(row[value.field]) : 0;
                                row[value.field] =
                                    row[value.field] != null || row[value.field] != undefined
                                        ? currencyFormatter(row[value.field])
                                        : currencyFormatter(0);
                                break;
                            case "number":
                                footer_obj[value.field] += row[value.field] ? parseInt(row[value.field]) : 0;
                                row[value.field] =
                                    row[value.field] != null || row[value.field] != undefined
                                        ? parseInt(row[value.field])
                                        : 0;
                                break;
                            case "link":
                                row[value.field] = 
                                    row[value.field] != null || row[value.field] != undefined ? row[value.field].toString() : "0";
                                footer_obj[value.field] += row[value.field] ? parseFloat(row[value.field]) : "0";    
                                break;
                            default:
                                row[value.field] =
                                    row[value.field] != null || row[value.field] != undefined ? row[value.field] : "";
                        }
                    });
                }
            });
            return row;
        });

        this.page_metadata.column_def.map((item: { children: { type: string; field: string | number }[] }) => {
            item.children &&
                item.children.map((value: { type: string; field: string | number }) => {
                    if (value.type == "currency") {
                        footer_obj[value.field] = currencyFormatter(footer_obj[value.field]);
                    } else if (value.type == "link") {
                        footer_obj[value.field] = parseInt(footer_obj[value.field]).toString();
                    }
                });
        });

        if (this.is_mounted) {
            this.setState({
                charge_audit_footer_obj: { ...footer_obj }
            });
        }
        this.footer_object = footer_obj;
        return data;
    };

    //Function calls on initialization of Ag-Grid and catch its reference.
    get_grid_ref = (grid_params) => {
        setTimeout(() => {
            grid_params.columnApi.autoSizeAllColumns();
        }, 200);

        this.setState({
            grid_params,
            grid_rows_count: grid_params.api.getModel().getRowCount()
        });
    };

    //Used to create the title for export and print.
    set_title = (separter) => {
        this.company_name = this.props.user_login_details.user_data.data.company_name;
        this.current_date = moment(new Date()).format("MM/DD/YYYY") + " " + moment(new Date()).format("hh:mm:ss A");
        this.title = this.page_metadata.header;
        return this.title =
            this.title.replace(/<br>/g, separter) +
            separter +
            this.company_name +
            separter +
            this.current_date +
            separter +
            (this.state.grid_rows_count == 1
                ? this.state.grid_rows_count + " record"
                : this.state.grid_rows_count + " records") +
            separter +
            separter;
    };

    // Show multiple messages
    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ),
            timeOut: toastr_options.toastr_time_out,
            preventDuplicates: true
        };
    };

    on_grid_out = () => {
        set_focus_on_element_with_id("app_header_dropdown");
    };

    //Function calls on initialization of export report data
    on_export_button = () => {
        this.title = this.set_title("\n");
        this.page_name = this.page_name.replace(/<br>/g, "");
        export_grid_data(this.state.grid_params, this.title, this.page_name);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Export);
    };

    //Function calls on initialization of Print report data
    on_print_button = () => {
        this.title = this.set_title("<br>");
        print_grid_data(this.state.grid_params, this.title, this.page_name);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
    };

    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;

        if (this.props.magnusPlat1731BillingCharges) {
            const dataEntry: DataEntry = {
                reportId: this.reportId,
                Records: Number(this.state.grid_params.api.rowModel.rowsToDisplay.length)
            }

            const data: PrintExportConstants.IPrinExportDto = {
                entityTypeId: PrintExportConstants.EntityTypeId.ChargeAudit,
                auditDataEntry: dataEntry
            };

            PrintExportConstants.savePrintExportAudit(data, accessToken, eventActionId);
        }
        else {
            const { user_id, company_id } = this.props.user_login_details.user_data.data;
            
            const payload: ReportConstants.IPayloadForBillingAudit = {
                reportId: this.reportId,
                contextTitle: `${this.page_name} - ${ReportConstants.ReportEventAction[eventActionId]}`,
                eventActionId: eventActionId,
                userId: Number(user_id),
                companyId: Number(company_id),
                entityTypeId: ReportConstants.ReportEntityTypeId.ChargeAudit,
                data: { Records: Number(this.state.grid_params.api.rowModel.rowsToDisplay.length) }
            }
    
            const reqBody = generateAuditDataEntryPayload(payload);
            ReportConstants.saveAuditPrintExport(reqBody, accessToken);
        }
    };

    render() {
        return (
            <React.Fragment>
                <Dimmer active={this.state.is_loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <div className={"admin-wrapper "} style={!this.state.show_grid ? { paddingBottom: 20 } : {}}>
                    <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                        <Grid.Column computer={16}>
                            <h3
                                className='ui header left aligned'
                                dangerouslySetInnerHTML={{ __html: this.page_name }}
                            />
                        </Grid.Column>
                    </Grid>
                    <div id='admin-scrollable-area' className='wrapper' style={this.state.show_grid ? { flex: 1 } : {}}>
                        {this.state.show_grid && !this.state.is_loading && (
                            <GridView
                                id={this.page_metadata.id}
                                row={this.row_data}
                                column={this.page_metadata.column_def}
                                style={{ height: "100%" }}
                                wrapperStyle={{ width: "100%", height: "100%", display: "flex" }}
                                selectionType={"single"}
                                get_grid_ref={this.get_grid_ref}
                                headerHeight={this.grid_header_height}
                                headerIdForTabNavigation={charge_audit_configuration_grid_header_id}
                                onForceGridOut={this.on_grid_out}
                                enableColResize={false}
                                emptyMessage={messages.no_records_found}
                                pinnedBottomRowData={[this.footer_object]}
                            />
                        )}
                    </div>
                    {this.state.show_grid && (
                        <div
                            className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                            id='applicationFooterSticky'
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                            <Grid.Column computer={16} textAlign='right'>
                                <Button id='export_report_button' type='submit' onClick={this.on_export_button} basic>
                                    Export
                                </Button>
                                <Button
                                    onKeyDown={set_focus_to_app_header}
                                    id='print_report_button'
                                    type='submit'
                                    onClick={this.on_print_button}
                                    primary
                                >
                                    Print
                                </Button>
                            </Grid.Column>
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        magnusPlat1731BillingCharges: get_lauch_darkley_key_value(state.launch_darkly, ChargesBillingAuditLogEpicsFeature.magnusPlat1731BillingCharges)
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            // update_patient_header_info: update_patient_header_info
        },
        dispatch
    );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChargeAuditComponent));
