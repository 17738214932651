import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import MaskedInput from 'react-text-mask';
import { Button, Checkbox, Dimmer, Form, Grid, Loader, Modal } from 'semantic-ui-react';
import { enum_case_page_state } from '../../case/case_constants';
import * as global_constants from '../../global_constants';
import * as local_storage from '../../shared/local_storage_utility';
import { add_primary_insurance, advanced_search_class, advanced_search_payer, quick_search_class, quick_search_payer } from '../action/shared_action';
import { search_class_configuration, search_payer_configuration } from '../shared_constants';
import { unmask, zip_code_formation, unformat_zip_code } from '../utility';
import AdvancedControl from './../../shared/component/advanced_control';
import AutoSearchComponent from './auto_search_component';
import ContractsSelectionBoxComponent from './contracts_selection_box_component';
import DatePicker from './date_picker_component';
import SelectionComponent from "./selection_component";
import moment = require('moment');
import { handle_focus_on_tab, set_focus_on_element_with_id, handle_navigation_on_modal } from '../../shared/tab_navigation_utility';

const toastrOptions = {
    timeOut: 3000, // by setting to 0 it will prevent the auto close
}

class AddPrimaryInsurance extends React.Component<any, any> {
    name_input: HTMLInputElement;
    _is_mount = false;
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            new_insurance_data: {
                available_contract: [],
                insurance_code: '',
                address: {
                    address1: '',
                    address2: '',
                    city: '',
                    province: '',
                    country_id: '',
                    is_required: true,
                    zip: '',
                    state_id: '',
                    postal_code: ''
                },
                injury_date_required_flag: false,
                contact_name: '',
                contact_email: '',
                phone_clm_status: '',
                fax: '',
                dx_change_poc_required: false,
                is_active: true,
                insurance_class: {
                    id: 0,
                    name: '',
                    insurance_class_code: ''
                },
                insurance_type: '',
                id: 0,
                name: '',
                insurance_class_id: '',
                phone_cust: '',
                is_authorization_required: false,
                insurance_type_id: '',
                balance_type: '',
            },
            form: {
                insurance_name: '',
                insurance_code: '',
                address1: '',
                address2: '',
                city: '',
                country_id: 1,
                state: '',
                zip: '',
                balance_type: '',
                insurance_class: null,
                insurance_payer: null,
                insurance_type_id: '',
                injury_date_required: false,
                payer_description: '',
                contact_name: '',
                contact_email: '',
                phone_customer_service: '',
                phone_claim_status: '',
                fax: '',
                weblink: '',
                weblink_user_id: '',
                weblink_password: '',
                dde: '',
                dde_user_id: '',
                dde_password: '',
                note: '',
                sec_routing: '',
                new_plan_required: false,
                icd_code_type: "1",
                icd10_start_date: this.default_date('10/01/2015', "MM/DD/YYYY"),
                authorization_required: false,
                is_active: true,
                selected_contracts: [],
                is_required: true,
                postal_code: '',
                province: ''
            },
            country_ids: [],
            balance_type: [],
            states: [],
            countries: [],
            icd_code_types: [],
            available_contracts: [],
            icd10_start_date: this.default_date('10/01/2015', "MM/DD/YYYY"),
            insurance_type_id: [],
            master_data: this.props.user_login_details.master_data.data,
            error: {
                insurance_name: false,
                address1: false,
                city: false,
                country_id: false,
                state: false,
                balance_type: false,
                insurance_type_id: false,
                payer_description: false,
                phone_customer_service: false,
                zip: false,
                insurance_class: false,
                contact_email: false,
                phone_claim_status: false,
                fax: false,
                province: false,
                postal_code: false
            },
            selected_insurance_class: '',
            selected_payer: '',
            is_submitted: false,
            grid_conf: null,
            insurance_class_grid_data: null,
            insurance_payer_grid_data: null
        };
    }
    loading = false;
    token = '';
    initial_state_form = null;
    initial_error_data = null;
    previous_error = false;
    insurance_type_id = [];
    selected_contracts_arr = [];
    class_search_enum = 'class';
    payer_search_enum = 'payer';
    //Get default date selection
    default_date = (date, pattern) => {
        if (date) {
            const dt = moment(date).format(pattern);
            return moment(dt);
        }
        return null;
    }
    indexes_to_remove_arr = [];
    user_type_r3 = 0;
    is_bms_or_sysadmin = false;

    componentDidMount = async () => {
        this._is_mount = true;
        this.token = this.props.user_login_details.user_data.data.token_details.access_token; // update token at component update
        this.user_type_r3 = this.props.user_login_details.user_data.data.user_type_r3_id;
        var company_service_type = 0;
        var old_user_data = local_storage.get("auth_data");
        var company_service_type = 0;
        if (old_user_data) {
            company_service_type = old_user_data.company_service_type;
        }
        //allow same feature for customer or partner user if they are software only as  sys admin and bms 
        this.is_bms_or_sysadmin = (this.user_type_r3 === 1 || this.user_type_r3 === 2) ? true : (company_service_type == global_constants.CompanyServiceType.SoftwareOnly ? true : false);

        // Following lines will fetch the states from master data
        const states = this.state.master_data.states.map((val, index) => {
            return { key: index, value: val.id, text: val.name }
        });

        // Following lines will fetch the countries from master data
        const countries = this.state.master_data.country.map((val, index) => {
            return { key: index, value: val.id, text: val.name }
        });

        // Following lines will fetch the countries from master data
        const icd_code_types = this.state.master_data.icd_code_type.map((val, index) => {
            return { key: index, value: val.id, text: val.name }
        });

        // Following lines will fetch the balance types from master data
        const balance_type = this.state.master_data.balance_type.map((val, index) => {
            return { key: index, value: val.id, text: val.name }
        });

        var default_insurance_types = [{ key: '00', value: '', text: 'List' }];
        //const insurance_types = this.props.insurance_types; // Get All insurance types from api

        //const insurance_types = await get_insurance_types(this.token).then(res => {
        //    if (res.data.data != null && res.data.data.length > 0) {
        //        //this.insurance_type_id = res.data.data;
        //        //return res.data.data.map((val, index) => {
        //        //    return { key: index, value: val.id, text: val.name }
        //        //});
        //    }
        //}); // Get All insurance types from api

        //const available_contracts = await get_contracts(this.token).then(res => {
        //    if (res.data.data != null && res.data.data.length > 0) {
        //        return res.data.data;
        //    }
        //}); 
        const available_contracts = this.props.insurance_contract; // Get All contracts from api

        if (this._is_mount) {
            this.setState({ available_contracts, balance_type, icd_code_types, states, insurance_type_id: this.props.insurance_types, country_ids: countries });
        }

        // Following line will create a clone of form state that would be treated as a initial state
        this.initial_state_form = { ...this.state.form };
        this.initial_error_data = this.state.error;
    }

    componentWillUnmount = () => {
        this._is_mount = false;
    }

    componentDidUpdate(previousProps, previousState) {
        this.token = this.props.user_login_details.user_data.data.token_details.access_token; // update token at component update
        if (previousProps.add_primary_insurance != this.props.add_primary_insurance && this._is_mount) {
            this.setState({ showModal: true });
            handle_navigation_on_modal("add_new_primary_insurance_close_icon", "new_primary_insurance_save_btn");         
            const refresh_contracts = this.props.insurance_contract ? [...this.props.insurance_contract] : [];
            //var refresh_contracts = available_contracts.filter((el, index) => {
            //    el.is_active = false
            //    return true
            //});
            this.setState((prevState) => ({
                available_contracts: refresh_contracts, form: {
                    ...prevState.form, insurance_class: {
                        id: 0,
                        name: '',
                        insurance_class_code: ''
                    }
                }, insurance_class_id: '', selected_insurance_class: ''
            }));
        }
        if (previousProps.insurance_types != this.props.insurance_types && this._is_mount) {
            this.setState({ insurance_type_id: this.props.insurance_types });
        }
        if (previousProps.insurance_contract != this.props.insurance_contract && this._is_mount) {
            this.setState({ available_contracts: this.props.insurance_contract });
        }

    }

    // check if data has only letters and numbers
    input_data_validation = (data, type) => {
        if (type === 'number') {
            data = data.toString().replace(/[^0-9]/g, '');
            return (data.length != 10);
        } else if (type === 'country_id' || type == 'insurance_type_id') {
            let reg = new RegExp('/^[\s\d\s]*$/');
            return typeof data !== 'number' && !data.match(reg);
        } else if (type === 'zip') {
            data = data && data.length > 0 && data.replace(/[^0-9]/g, '');
            return !(data.length == 5 || data.length == 9);
        } else if (type === 'email') {
            let email_invalid = data.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? false : true;
            return (email_invalid);
        } else if (type === 'postal_code') {
            data = data.toString().replace(/[^0-9]/g, '');
            return (data === '');
        }
    }


    select_contracts_to_add = (e, item, idx) => {

        var data = Object.assign([], this.state.available_contracts);
        if (item.checked) {
            data[idx].is_active = true;
        } else {
            data[idx].is_active = false;
        }
        if (this._is_mount) {
            this.setState({ available_contracts: data });
        }
    }

    select_contracts_to_remove = (e, item, idx) => {

        var data = Object.assign([], this.state.form.selected_contracts);
        if (item.checked) {
            data[idx].is_active = true;
        } else {
            data[idx].is_active = false;
        }
        if (this._is_mount) {
            this.setState({ form: { ...this.state.form, selected_contracts: data } });
        }
    }

    add_selected_contracts = () => {
        const available_contracts = [...this.state.available_contracts];

        var remove_contracts = available_contracts.filter((el, index) => {
            return el.is_active == false
        });
        var selected_contracts = available_contracts.filter((el, index) => {
            let res = el.is_active
            el.is_active = false
            return res == true
        });
        if (this._is_mount) {
            this.setState({ form: { ...this.state.form, selected_contracts: this.state.form.selected_contracts.concat(selected_contracts) }, available_contracts: remove_contracts });
        }
    }

    add_all_contracts = () => {
        const available_contracts = [...this.state.form.selected_contracts, ...this.state.available_contracts];
        const selected_contracts = available_contracts.filter((el, index) => {

            el.is_active = false
            return true
        });

        if (this._is_mount) {
            this.setState({ form: { ...this.state.form, selected_contracts: selected_contracts }, available_contracts: [] });
        }
    }

    remove_selected_contracts = () => {
        const available_contracts = [...this.state.form.selected_contracts];
        const remove_contracts = available_contracts.filter((el, index) => {
            return el.is_active == false
        });
        const selected_contracts = available_contracts.filter((el, index) => {
            let res = el.is_active
            el.is_active = false
            return res == true
        });

        if (this._is_mount) {
            this.setState({ form: { ...this.state.form, selected_contracts: remove_contracts }, available_contracts: [...selected_contracts, ...this.state.available_contracts] });
        }

    }

    remove_all_contracts = () => {
        const available_contracts = [...this.state.form.selected_contracts, ...this.state.available_contracts];
        const selected_contracts = available_contracts.filter((el, index) => {

            el.is_active = false
            return true
        });

        if (this._is_mount) {
            this.setState({ available_contracts: selected_contracts, form: { ...this.state.form, selected_contracts: [] } });
        }
    }

    // validate form before saving data
    validate_form = () => {
        const error = { ...this.state.error };
        const form = this.state.form;
        let error_in_insurance_class = false;

        if (form.insurance_class == null || form.insurance_class.id == 0) {
            error_in_insurance_class = true;
        }
        error.insurance_name = (form.insurance_name.trim() == '') ? true : false;
        error.address1 = (form.address1.trim() == '') ? true : false;
        error.city = (form.city.trim() == '') ? true : false;
        error.country_id = this.input_data_validation(form.country_id, 'country_id');
        error.balance_type = (form.balance_type.trim() == '') ? true : false;
        error.insurance_type_id = this.input_data_validation(form.insurance_type_id, 'insurance_type_id');
        error.payer_description = this.is_bms_or_sysadmin ? (form.insurance_payer ? ((form.insurance_payer.description.trim() == '') ? true : false) : true) : false;
        error.phone_customer_service = this.input_data_validation(form.phone_customer_service, 'number');
        error.insurance_class = error_in_insurance_class;
        error.contact_email = form.contact_email.length > 0 && this.input_data_validation(form.contact_email, 'email');
        error.phone_claim_status = form.phone_claim_status.length > 0 && this.input_data_validation(form.phone_claim_status, 'number');
        error.fax = form.fax.length > 0 && this.input_data_validation(form.fax, 'number');

        if (this.state.form.country_id != 1) {
            error.province = (form.province.trim() == '') ? true : false;
            error.postal_code = this.input_data_validation(form.postal_code, 'postal_code');

        } else {
            error.state = (form.state.trim() == '') ? true : false;
            error.zip = this.input_data_validation(form.zip, 'zip');
        }

        if (this._is_mount) {
            this.setState({
                error: error,
                is_submitted: true
            });
        }
        if (Object.keys(error).some((data) => { return error[data] == true })) {
            this.previous_error = true;
            toastr.error('', global_constants.constants.mandatory_fields, toastrOptions);
            return false;
        }


        return true;

    }

    // close modal and empty form data
    closeModal = () => {
        //     this.props.closeModal()
        this.previous_error = false;

        if (this._is_mount) {
            this.setState({ showModal: false, selected_payer: '', form: this.initial_state_form, error: this.initial_error_data, is_submitted: false });
            set_focus_on_element_with_id("add_new_Insurance");

            if (this.props.on_close_modal) {
                this.props.on_close_modal()
            }
        }
    }

    mountModal = () => {
        this.name_input.focus();
    }


    // save form data
    saveHandle = async (e) => {
        e.preventDefault();
        if (this.validate_form()) {
            this.loading = true;
            const form_data = { ...this.state.form };
            /*  Here we need to create the data that is needed to be sent to the API
             * 
             * */
            let new_insurance_data = { ...this.state.new_insurance_data };

            new_insurance_data.available_contract = form_data.selected_contracts;
            new_insurance_data.insurance_code = form_data.insurance_class ? form_data.insurance_class.code.trim() : '';
            new_insurance_data.address = {
                address1: form_data.address1,
                address2: form_data.address2,
                city: form_data.city,
                province: form_data.province,
                Country_id: form_data.country_id,
                Is_required: form_data.is_required,
                zip: unformat_zip_code(form_data.zip),
                State_id: form_data.state,
                Postal_code: form_data.postal_code
            };
            new_insurance_data.injury_date_required_flag = form_data.injury_date_required;
            new_insurance_data.contact_name = form_data.contact_name.trim();
            new_insurance_data.contact_email = form_data.contact_email.trim();
            new_insurance_data.phone_clm_status = form_data.phone_claim_status.replace(/\D/g, '');;
            new_insurance_data.fax = form_data.fax.replace(/\D/g, '');
            new_insurance_data.web_link = form_data.weblink.trim();
            new_insurance_data.dx_change_poc_required = form_data.new_plan_required;
            new_insurance_data.is_active = form_data.is_active;
            new_insurance_data.insurance_class = form_data.insurance_class ? {
                id: form_data.insurance_class.id,
                name: form_data.insurance_class.name,
                Insurance_class_code: form_data.insurance_class.code
            } : {};
            new_insurance_data.insurance_type = form_data.insurance_class ? form_data.insurance_class.name : '';
            new_insurance_data.id = 0;
            new_insurance_data.name = form_data.insurance_name;
            new_insurance_data.insurance_class_id = form_data.insurance_class ? form_data.insurance_class.id : 0;
            new_insurance_data.phone_cust = form_data.phone_customer_service;
            new_insurance_data.is_authorization_required = form_data.authorization_required;
            new_insurance_data.insurance_type_id = form_data.insurance_type_id;
            new_insurance_data.balance_type = form_data.balance_type;

            if (this.is_bms_or_sysadmin) {
                new_insurance_data.payer_id = form_data.insurance_payer.id.toString();
                new_insurance_data.payer_key_id = form_data.insurance_payer.id;
                new_insurance_data.dde = form_data.dde.trim();
                new_insurance_data.dde_user_id = form_data.dde_user_id;
                new_insurance_data.dde_pwd = form_data.dde_password.trim();
                new_insurance_data.web_link_user_id = form_data.weblink_user_id.trim();
                new_insurance_data.web_link_password = form_data.weblink_password.trim();
                new_insurance_data.note = form_data.note.trim();
                new_insurance_data.secure_routing = form_data.sec_routing;
                new_insurance_data.icd_code_for_billing = form_data.icd_code_type;
                new_insurance_data.icd10_start_date = form_data.icd10_start_date;
            }

            await add_primary_insurance(this.token, new_insurance_data).then(res => {

                // passing the type as props which was defined by the parent component, else null will be send
                this.props.set_primary_insurance_details(res.data.data, this.props.insurance_type);
                this.loading = false;
                this.closeModal();
            });
        }

    }

    // set input field value in state
    handleChange = (e) => {

        const input_field = e.target.name;
        let input_value = e.target.value;
        const error = { ...this.state.error };
        let form_data = this.state.form
        /* Following lines we will check for validation while changing certain fields
         * 
         * */
        if ((input_field == 'insurance_name' || input_field == 'address1' || input_field == 'city' || input_field == 'balance_type') && this.state.is_submitted) {
            error[input_field] = (input_value.trim() == '') ? true : false;
            form_data[input_field] = input_value ? input_value : '';
        } else if (input_field == 'zip') {
            let field_value = input_value && input_value.length > 0 && input_value.replace(/[^0-9]/g, '');
            if(!isNaN(field_value)){
                form_data[input_field] = field_value ? zip_code_formation(field_value) : '';                
                if (this.state.is_submitted) {
                    error[input_field] = this.input_data_validation(field_value, "zip")
                }            
            }            
        } else if (input_field == 'postal_code') {
            input_value = (e.target.validity.valid) ? (Number(e.target.value).toString().length <= 20 ? e.target.value.replace(/[^a-z0-9]/gi, '') : this.state.form.postal_code) : this.state.form.postal_code;
            form_data[input_field] = input_value ? input_value : '';
            if (this.state.is_submitted) {
                error[input_field] = (input_value.trim() == '') ? true : false;
            }
        } else if (input_field == 'phone_customer_service' || input_field == 'phone_claim_status' || input_field == 'fax') {
            let is_error = this.input_data_validation(unmask(input_value), 'number');
            error[input_field] = is_error;
            form_data[input_field] = input_value ? input_value : '';
        } else if (input_field == 'contact_email') {
            error[input_field] = this.input_data_validation(input_value, 'email');
            form_data[input_field] = input_value ? input_value : '';
        } else if (input_field == 'province') {
            input_value = e.target.value.replace(/[^a-z0-9]/gi, '')
            form_data[input_field] = input_value ? input_value : '';
            if (this.state.is_submitted) {
                error[input_field] = (input_value.trim() == '') ? true : false;
            }
        } else {
            form_data[input_field] = input_value ? input_value : '';
        }

        if (this._is_mount) {
            this.setState({
                form: form_data,
                error: error
            });
        }
    }

    handleDropDownChange = (e, item) => {
        const error = { ...this.state.error };
        var form = { ...this.state.form };
        let field = item.name;
        let value;

        if (field == 'country_id' || field == 'insurance_type_id') {
            value = parseInt(item.value);
        }
        else {
            value = item.value;
        }

        if (value != '') {
            error[field] = false;
        }



        if (field == 'country_id' && this.state.is_submitted) {
            if (value != 1) {
                error.state = false;
                error.zip = false;
                error.province = true;
                error.postal_code = true;
                form.state = "";
                form.zip = "";
            }
            if (value == 1) {
                error.state = true;
                error.zip = true;
                error.province = false;
                error.postal_code = false;
                form.province = '';
                form.postal_code = '';
            }
        }

        if (this._is_mount) {
            this.setState({ form: { ...form, [field]: value }, error: error });
        }
    }

    // show modal on state change
    controlHandler = (event) => {

        if (this._is_mount) {
            this.setState({ showModal: true });

        }
    }

    // get the quick search class apis data
    getClassDataList = async (search_keyword) => {
        var search_key_obj = { keyword: search_keyword };
        const token = local_storage.get("auth_data").token_details.access_token;
        return await quick_search_class(search_key_obj, token);
    }

    // get the quick search provider apis data
    getPayerDataList = async (search_keyword) => {
        var search_key_obj = { keyword: search_keyword };
        const token = local_storage.get("auth_data").token_details.access_token;
        return await quick_search_payer(search_key_obj, token);
    }

    // on provider search item selection
    onAdvancedSearchGridRowSelection = (selectedRow, type) => {
        var selected_row = null;
        var state = { ...this.state };
        var form = { ...state.form };
        if (this._is_mount) {
            if (type == this.class_search_enum) {
                this.setState(prevState => ({
                    selected_insurance_class: selectedRow,
                    form: { ...prevState.form, insurance_class: selectedRow }
                }));
            } else {
                this.setState(prevState => ({
                    selected_payer: selectedRow,
                    form: {
                        ...prevState.form, insurance_payer: selectedRow
                    }
                }));
            }
        }

    }

    // clear data on clear icon
    clear_quick_search = (type) => {

        const error = { ...this.state.error };
        if (type == this.payer_search_enum && this.state.is_submitted) {
            error.payer_description = true;
        }

        if (type == this.class_search_enum && this.state.is_submitted) {
            error.insurance_class = true;
        }
        if (this._is_mount) {
            if (type == this.class_search_enum) {
                this.setState({ selected_insurance_class: '', form: { ...this.state.form, insurance_class: null }, error: error });
            } else {
                this.setState({ selected_payer: '', form: { ...this.state.form, insurance_payer: null }, error: error });
            }
        }
    }

    // get the searched value which is typed for searching location or providers
    get_input_value = (input, type) => {

        const error = { ...this.state.error };

        if (input.length >= 3) {
            if (type === this.class_search_enum) {
                this.getClassDataList(input);
            } else {
                this.getPayerDataList(input);
            }
        }

        if (type == this.class_search_enum) {
            error.insurance_class = true;
        } else {
            error.payer_description = true;
        }
        if (this._is_mount) {
            if (this.state.is_submitted) {
                if (type == this.class_search_enum) {
                    this.setState({ form: { ...this.state.form, insurance_class: null }, error: error });
                } else {
                    this.setState({ form: { ...this.state.form, insurance_payer: null }, error: error });
                }
            } else {
                if (type == this.class_search_enum) {
                    this.setState({ form: { ...this.state.form, insurance_class: null } });
                } else {
                    this.setState({ form: { ...this.state.form, insurance_payer: null } });
                }
            }
        }
    }

    //Auto search for class
    renderResult = (props) => {
        return (
            <div key={props.title} tabIndex={0} className="item_auto_search">
                <div key={props.title} className="fs_13">
                    <span>{props.label}</span>
                </div>
            </div>

        )
    }


    row_selection_format = (item, type) => {
        var formatted = null;
        if (type == this.class_search_enum) {

            formatted = {
                "title": `${item.id}`,
                "code": item.insurance_class_code,
                "name": item.insurance_class_description,
                "id": item.id,
                "label": (item.insurance_class_code) + " - " + (item.insurance_class_description),

            }
        } else if (type == this.payer_search_enum) {
            formatted = {
                "title": `${item.payer_key_id}`,
                "code": item.name,
                "description": item.description,
                "id": item.payer_key_id,
                "label": (item.name) + " - " + (item.description),

            }
        }

        return formatted;
    }
    // preapare the suggestion list with search result
    prepareSuggestion = (data, type) => {
        let formattedList = [];
        var data_length = data.data.length;
        if (data && data_length) {
            for (let i = 0; i < data_length; i++) {
                let item = data.data[i];
                if (type == this.class_search_enum) {

                    formattedList.push({
                        "title": `${item.id}`,
                        "code": item.insurance_class_code,
                        "name": item.insurance_class_description,
                        "id": item.id,
                        "label": (item.insurance_class_code) + " - " + (item.insurance_class_description),

                    });
                } else if (type == this.payer_search_enum) {
                    formattedList.push({
                        "title": `${item.payer_key_id}`,
                        "code": item.name,
                        "description": item.description,
                        "id": item.payer_key_id,
                        "label": (item.name) + " - " + (item.description),

                    });
                }
            }
        }
        return formattedList;
    }


    onselection = (item, type) => {

        this.handleSelectionErrors(item, type);
        this.onAdvancedSearchGridRowSelection(item, type);
    }

    handleSelectionErrors = (item, type) => {
        const error = { ...this.state.error };
        if (type == this.payer_search_enum) {
            error.payer_description = this.is_bms_or_sysadmin ? (item ? ((item.description.trim() == '') ? true : false) : true) : false;
        }
        if (type == this.class_search_enum) {
            error.insurance_class = false;
        }
        if (this._is_mount) {
            this.setState({
                error: error
            });
        }
    }

    onAdvancedGridRowSelect = (item, type) => {
        this.handleSelectionErrors(item, type);
        const prepared_result = this.row_selection_format(item, type);
        this.onAdvancedSearchGridRowSelection(prepared_result, type);
    }

    // search location by typing name or code
    onAdvancedSearch = async (params, type) => {
        const token = local_storage.get("auth_data").token_details.access_token;
        var search_data = null;
        var config = null;
        var grid_type = '';
        if (type == this.class_search_enum) {
            search_data = await advanced_search_class(params, token).then(res => res.data);
            config = search_class_configuration(this.class_search_enum +'_header');
            grid_type = 'insurance_class_grid_data'
        } else if (type == this.payer_search_enum) {
            params.Description = typeof params.Description == 'undefined' ? '' : params.Description;
            params.Outbound_id = typeof params.Outbound_id == 'undefined' ? '' : params.Outbound_id;
            params.Inbound_id = typeof params.Inbound_id == 'undefined' ? '' : params.Inbound_id;
            params.name = typeof params.name == 'undefined' ? '' : params.name;
            search_data = await advanced_search_payer(params, token).then(res => res.data);
            config = search_payer_configuration;
            grid_type = 'insurance_payer_grid_data'
        }

        const search_result = search_data && search_data.data !== null ? search_data.data : [];
        const grid_data = { ...this.state.grid_conf, rows: search_result, column: config.column_defs, messages: search_data.messages };
        if (this._is_mount) {
            this.setState({ [grid_type]: grid_data });
        }
    }

    // Will handle date selection from calendar  
    handleCalendarChange = (dt) => {
        var state = { ...this.state };
        var form_data = state.form;
        form_data.icd10_start_date = dt;
        if (this._is_mount) {
            this.setState({
                form: form_data
            });
        }
    }

    hide_field_user_type = () => this.is_bms_or_sysadmin ? { display: 'block' } : { display: 'none' };

    render() {

        const form = this.state.form;
        return (
            < >
                <Dimmer active={this.loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <Modal
                    onClose={this.closeModal}
                    onMount={this.mountModal}
                    centered={false}
                    className=" default-modal"
                    open={this.state.showModal}
                    closeIcon={true}
                    id="add_primary_insurance_modal_popup"
                    closeOnDimmerClick={false}
                    size={"large"}
                >
                    <Modal.Header><div>
                        <h3>Add New Insurance</h3>
                    </div></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <div className="common-forms-add" style={{ padding: '0' }}>
                                <Form>
                                    <Grid>

                                        <Grid.Column mobile={16} tablet={8} computer={8}>
                                            <Form.Field className={this.state.error.insurance_name ? "requiredWithBgColor error" : ''} >
                                                <label>Insurance Name <span className={this.state.error.insurance_name ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                <input
                                                    ref={(input) => { this.name_input = input; }}
                                                    autoComplete="off"
                                                    name='insurance_name'
                                                    value={form.insurance_name}
                                                    onChange={this.handleChange}
                                                    maxLength={50}
                                                    disabled={false}
                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column mobile={16} tablet={8} computer={8}>
                                            <Form.Field >
                                                <label>Insurance Code</label>
                                                <input
                                                    id='insurance_code_input'
                                                    autoComplete="off"
                                                    name='insurance_code'
                                                    value={form.insurance_code}
                                                    onChange={this.handleChange}
                                                    disabled={true}
                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column mobile={16} tablet={16} computer={16}>
                                            <Form.Field className={this.state.error.address1 ? "requiredWithBgColor error" : ''}>
                                                <label>Address 1 <span className={this.state.error.address1 ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                <input disabled={false}
                                                    autoComplete="off"
                                                    name='address1'
                                                    value={form.address1}
                                                    onChange={this.handleChange}
                                                    id="case_name_txt" maxLength={50}
                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column mobile={16} tablet={16} computer={16}>
                                            <Form.Field >
                                                <label>Address 2</label>
                                                <input disabled={false}
                                                    autoComplete="off"
                                                    onChange={this.handleChange}
                                                    name='address2'
                                                    value={form.address2}
                                                    id="case_name_txt" maxLength={50}
                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field className={this.state.error.city ? "requiredWithBgColor error" : ''}>
                                                <label>City <span className={this.state.error.city ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                <input disabled={false}
                                                    autoComplete="off"
                                                    name='city'
                                                    value={form.city}
                                                    onChange={this.handleChange}
                                                    id="case_name_txt" maxLength={50}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field className={this.state.error.country_id ? "requiredWithBgColor error" : ''}>
                                                <label>Country ID <span className={this.state.error.country_id ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                {
                                                    //<Dropdown name='country_id'
                                                    //placeholder='Select'
                                                    //options={this.state.country_ids}
                                                    //onChange={this.handleDropDownChange}
                                                    //value={this.state.form.country_id}
                                                    //className='menu-options-inline'
                                                    //fluid selection
                                                    ///>
                                                }
                                                <SelectionComponent
                                                    name='country_id'
                                                    options={this.state.country_ids}
                                                    onChange={(value, event) => { this.handleDropDownChange(event, event.target) }}
                                                    defaultValue={this.state.form.country_id}
                                                    style='menu-options-inline'
                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column style={this.state.form.country_id == 1 ? { display: 'block' } : { display: 'none' }} mobile={16} tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>State <span className={this.state.error.state ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                {
                                                    //<Dropdown name='state'
                                                    //    placeholder='Select'
                                                    //    options={this.state.states}
                                                    //    onChange={this.handleDropDownChange}
                                                    //    value={this.state.form.state}
                                                    //    className={this.state.error.state ? "req-background-inp menu-options-inline" : 'menu-options-inline'}

                                                    //    fluid selection
                                                    ///>
                                                }
                                                <SelectionComponent
                                                    name='state'
                                                    placeHolder='Select'
                                                    options={this.state.states}
                                                    onChange={(value, event) => { this.handleDropDownChange(event, event.target) }}
                                                    defaultValue={this.state.form.state}
                                                    style='menu-options-inline'
                                                    isRequired={this.state.error.state ? true : false}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column style={this.state.form.country_id == 1 ? { display: 'block' } : { display: 'none' }} mobile={16} tablet={8} computer={4}>
                                        <Form.Field className={this.state.error.zip ? "requiredWithBgColor error" : ''}>
                                            <label>Zip Code <span className={this.state.error.zip ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                            <input
                                                autoComplete='new-password'
                                                name='zip'
                                                value={form.zip}
                                                onChange={this.handleChange}
                                                id="case_name_txt"  
                                                maxLength={10}
                                            />                   
                                            {this.state.form.zip && unformat_zip_code(this.state.form.zip).length > 0 && this.state.is_submitted && this.state.error.zip
                                                && <div className="requiredText">{global_constants.constants.zip_compulsory}</div>
                                            }
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column style={this.state.form.country_id != 1 ? { display: 'block' } : { display: 'none' }} mobile={16} tablet={8} computer={4}>
                                            <Form.Field className={this.state.error.province ? "requiredWithBgColor error" : ''}>
                                                <label>Province  <span className={this.state.error.province ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                <input disabled={false}
                                                    autoComplete="off"
                                                    name='province'
                                                    value={form.province}
                                                    onChange={this.handleChange}
                                                    id="case_name_txt" maxLength={20}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column style={this.state.form.country_id != 1 ? { display: 'block' } : { display: 'none' }} mobile={16} tablet={8} computer={4}>
                                            <Form.Field className={this.state.error.postal_code ? "requiredWithBgColor error" : ''}>
                                                <label>Postal Code <span className={this.state.error.postal_code ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                <input disabled={false}
                                                    autoComplete="off"
                                                    name='postal_code'
                                                    value={form.postal_code}
                                                    onChange={this.handleChange}
                                                    maxLength={20}
                                                    className={this.state.form.postal_code.length > 0
                                                        && this.state.is_submitted && this.state.error.postal_code ? 'error' : ''}
                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Balance Type <span className={this.state.error.balance_type ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                {
                                                    //<Dropdown name='balance_type'
                                                    //    placeholder='Select'
                                                    //    options={this.state.balance_type}
                                                    //    onChange={this.handleDropDownChange}
                                                    //    value={form.balance_type}
                                                    //    className={this.state.error.balance_type ? "req-background-inp menu-options-inline" : 'menu-options-inline'}
                                                    //    fluid selection
                                                    ///>
                                                }

                                                <SelectionComponent
                                                    name='balance_type'
                                                    placeHolder='Select'
                                                    options={this.state.balance_type}
                                                    onChange={(value, event) => { this.handleDropDownChange(event, event.target) }}
                                                    defaultValue={form.balance_type}
                                                    style='menu-options-inline'
                                                    isRequired={this.state.error.balance_type ? true : false}
                                                />

                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field className={this.state.error.insurance_class ? "requiredWithBgColor error advance-quick-search" : 'advance-quick-search'}>
                                                <label>Insurance Class <span className={this.state.error.insurance_class ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                <AdvancedControl disabled_element={false}
                                                    onGridRowSelection={(row) => this.onAdvancedGridRowSelect(row, this.class_search_enum)} gridConfig={this.state.insurance_class_grid_data}
                                                    controlId={'INSURANCE_CLASS'} onSearch={param => this.onAdvancedSearch(param, this.class_search_enum)}
                                                    search_type={'INSURANCE_CLASS'} headerIdForGridTabNavigation={this.class_search_enum + '_header'} />
                                                <AutoSearchComponent
                                                    errorClass={''}
                                                    getInputValue={e => this.get_input_value(e, this.class_search_enum)} default_value={this.state.selected_insurance_class}
                                                    errorMessage={'No Record Found !'} prepareRenderList={this.renderResult} getList={this.getClassDataList}
                                                    prepareDataList={(data) => this.prepareSuggestion(data, this.class_search_enum)} selectresult={val => this.onselection(val, this.class_search_enum)}
                                                    is_disabled={false} show_clear_search={true} clear_search={() => this.clear_quick_search(this.class_search_enum)} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Insurance Type ID <span className={this.state.error.insurance_type_id ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                {
                                                    //<Dropdown name='insurance_type_id'
                                                    //    placeholder='Select'
                                                    //    options={this.state.insurance_type_id}
                                                    //    onChange={this.handleDropDownChange}
                                                    //    value={this.state.form.insurance_type_id}
                                                    //    className={this.state.error.insurance_type_id ? "req-background-inp menu-options-inline" : 'menu-options-inline'}
                                                    //    fluid selection
                                                    ///>
                                                }
                                                <SelectionComponent
                                                    name='insurance_type_id'
                                                    placeHolder='Select'
                                                    options={this.state.insurance_type_id}
                                                    onChange={(value, event) => { this.handleDropDownChange(event, event.target) }}
                                                    defaultValue={this.state.form.insurance_type_id}
                                                    style='menu-options-inline'
                                                    isRequired={this.state.error.insurance_type_id ? true : false}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field >
                                                <Checkbox label='Injury Date Required' name='is_active' checked={this.state.form.injury_date_required}
                                                    onChange={(e) => this.setState({ form: { ...this.state.form, injury_date_required: !this.state.form.injury_date_required } })} disabled={this.state.page_state === enum_case_page_state.edit} />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column style={this.hide_field_user_type()} mobile={16} tablet={8} computer={5}>
                                            <Form.Field className={this.state.error.payer_description ? "requiredWithBgColor error advance-quick-search" : 'advance-quick-search'}>
                                                <label>Payer Description <span style={this.is_bms_or_sysadmin ? { display: 'inline' } : { display: 'none' }} className={this.state.error.payer_description ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                <AdvancedControl
                                                    disabled_element={!this.is_bms_or_sysadmin}
                                                    onGridRowSelection={(row) => this.onAdvancedGridRowSelect(row, this.payer_search_enum)} gridConfig={this.state.insurance_payer_grid_data}
                                                    controlId={'PAYER_DESCRIPTION'} onSearch={param => this.onAdvancedSearch(param, this.payer_search_enum)}
                                                    search_type={'PAYER_DESCRIPTION'} />
                                                <AutoSearchComponent
                                                    is_disabled={!this.is_bms_or_sysadmin}
                                                    errorClass={''}
                                                    getInputValue={e => this.get_input_value(e, this.payer_search_enum)} default_value={this.state.selected_payer}
                                                    errorMessage={'No Record Found !'} prepareRenderList={this.renderResult} getList={this.getPayerDataList}
                                                    prepareDataList={(data) => this.prepareSuggestion(data, this.payer_search_enum)} selectresult={item => this.onselection(item, this.payer_search_enum)}
                                                    show_clear_search={true} clear_search={() => this.clear_quick_search(this.payer_search_enum)} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={5}>
                                            <Form.Field >
                                                <label>Contact Name</label>
                                                <input disabled={false}
                                                    autoComplete="off"
                                                    onChange={this.handleChange}
                                                    id="contact_name_txt"
                                                    name="contact_name"
                                                    maxLength={70}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={5}>
                                            <Form.Field >
                                                <label>Contact Email</label>
                                                <input disabled={false}
                                                    autoComplete="off"
                                                    onChange={this.handleChange}
                                                    id="contact_name_email"
                                                    name="contact_email"
                                                    maxLength={50}
                                                    className={this.state.form.contact_email.length > 0 &&
                                                        this.state.is_submitted &&
                                                        this.state.error.contact_email ? "red-error-thin form-control" : 'form-control'}
                                                />
                                                <div className="requiredText">{this.state.form.contact_email.length > 0 &&
                                                    this.state.error.contact_email &&
                                                    this.state.is_submitted ? global_constants.constants.valid_email : ''}</div>
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column mobile={16} tablet={8} computer={5}>
                                            <Form.Field className={this.state.is_submitted && this.state.error.phone_customer_service ? "requiredWithBgColor error" : ''}>
                                                <label>Phone Customer Service <span className={this.state.is_submitted && this.state.error.phone_customer_service ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                <MaskedInput
                                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    className={this.state.form.phone_customer_service.length > 0 &&
                                                        this.state.is_submitted &&
                                                        this.state.error.phone_customer_service ? "red-error-thin form-control" : 'form-control'}
                                                    placeholder="(___) ___-____"
                                                    guide={true}
                                                    name="phone_customer_service"
                                                    id="phone_customer_service"
                                                    autoComplete="off"
                                                    value={unmask(form.phone_customer_service)}
                                                    onChange={this.handleChange} />
                                                <div className="requiredText">{this.state.form.phone_customer_service.length > 0
                                                    && this.state.is_submitted && this.state.error.phone_customer_service
                                                    ? global_constants.constants.ten_digits_compulsory : ''}</div>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={5}>
                                            <Form.Field>
                                                <label>Phone Claim Status</label>
                                                <MaskedInput
                                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    className={this.state.form.phone_claim_status.length > 0 &&
                                                        this.state.is_submitted &&
                                                        this.state.error.phone_claim_status ? "red-error-thin form-control" : 'form-control'}
                                                    placeholder="(___) ___-____"
                                                    guide={true}
                                                    name="phone_claim_status"
                                                    id="phone_claim_status"
                                                    autoComplete="off"
                                                    value={unmask(form.phone_claim_status)}
                                                    onChange={this.handleChange} />
                                                <div className="requiredText">{this.state.form.phone_claim_status.length > 0
                                                    && this.state.is_submitted && this.state.error.phone_claim_status
                                                    ? global_constants.constants.ten_digits_compulsory : ''}</div>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={5}>
                                            <Form.Field>
                                                <label>Fax</label>
                                                <MaskedInput
                                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    className={this.state.form.fax.length > 0 &&
                                                        this.state.is_submitted &&
                                                        this.state.error.fax ? "red-error-thin form-control" : 'form-control'}
                                                    placeholder="(___) ___-____"
                                                    guide={true}
                                                    name="fax"
                                                    id="fax-no"
                                                    autoComplete="off"
                                                    value={unmask(form.fax)}
                                                    onChange={this.handleChange} />
                                                <div className="requiredText">{this.state.form.fax.length > 0
                                                    && this.state.is_submitted && this.state.error.fax
                                                    ? global_constants.constants.ten_digits_compulsory : ''}</div>
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column mobile={16} tablet={8} computer={5}>
                                            <Form.Field >
                                                <label>Weblink</label>
                                                <input disabled={false}
                                                    autoComplete="off"
                                                    name='weblink'
                                                    onChange={this.handleChange}
                                                    id="weblink" maxLength={250}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column style={this.hide_field_user_type()} mobile={16} tablet={8} computer={5}>
                                            <Form.Field >
                                                <label>Weblink User ID</label>
                                                <input
                                                    autoComplete="off"
                                                    name='weblink_user_id'
                                                    onChange={this.handleChange}
                                                    id="weblink_user_id" maxLength={50}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column style={this.hide_field_user_type()} mobile={16} tablet={8} computer={5}>
                                            <Form.Field >
                                                <label>Weblink Password</label>
                                                <input
                                                    autoComplete="off"
                                                    name='weblink_password'
                                                    onChange={this.handleChange}
                                                    id="weblink_password" maxLength={50}
                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column style={this.hide_field_user_type()} mobile={16} tablet={8} computer={5}>
                                            <Form.Field >
                                                <label>DDE</label>
                                                <input
                                                    autoComplete="off"
                                                    name='dde'
                                                    onChange={this.handleChange}
                                                    id="dde" maxLength={250}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column style={this.hide_field_user_type()} mobile={16} tablet={8} computer={5}>
                                            <Form.Field >
                                                <label>DDE User ID</label>
                                                <input
                                                    autoComplete="off"
                                                    name='dde_user_id'
                                                    onChange={this.handleChange}
                                                    id="dde_user_id" maxLength={50}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column style={this.hide_field_user_type()} mobile={16} tablet={8} computer={5}>
                                            <Form.Field >
                                                <label>DDE Password</label>
                                                <input
                                                    autoComplete="off"
                                                    name='dde_password'
                                                    onChange={this.handleChange}
                                                    id="dde_password" maxLength={50}
                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column style={this.hide_field_user_type()} mobile={16} tablet={8} computer={5}>
                                            <Form.Field >
                                                <label>Note</label>
                                                <input
                                                    autoComplete="off"
                                                    name='note'
                                                    onChange={this.handleChange}
                                                    id="note" maxLength={250}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column style={this.hide_field_user_type()} mobile={16} tablet={8} computer={5}>
                                            <Form.Field >
                                                <label>SEC Routing</label>
                                                <input
                                                    autoComplete="off"
                                                    name='sec_routing'
                                                    onChange={this.handleChange}
                                                    id="sec_routing" maxLength={10}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={5}>
                                            <Form.Field >
                                                <label>&nbsp;</label>
                                                <Checkbox label='Diagnosis change requires new plan of care' name='diagnosis_change_required' checked={this.state.form.new_plan_required}
                                                    onChange={(e) => this.setState({ form: { ...this.state.form, new_plan_required: !this.state.form.new_plan_required } })} disabled={this.state.page_state === enum_case_page_state.edit} />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column style={this.hide_field_user_type()} mobile={16} tablet={8} computer={5}>
                                            <Form.Field >
                                                <label>ICD Codes for Billing</label>

                                                {
                                                    //<Dropdown name='icd10_code'
                                                    //    placeholder='Select'
                                                    //    options={this.state.icd_code_types}
                                                    //    onChange={(e, { value }) => {

                                                    //        this.setState({ form: { ...this.state.form, icd_code_type: value } })
                                                    //    }}
                                                    //    value={this.state.form.icd_code_type}
                                                    //    className='menu-options-inline'
                                                    //    fluid selection
                                                    ///>


                                                }
                                                <SelectionComponent
                                                    name='icd10_code'
                                                    options={this.state.icd_code_types}
                                                    onChange={(value, event) => { this.setState({ form: { ...this.state.form, icd_code_type: value } }) }}
                                                    defaultValue={this.state.form.icd_code_type}
                                                    style='menu-options-inline'
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column style={this.hide_field_user_type()} mobile={16} tablet={8} computer={5}>
                                            <Form.Field >
                                                <label>ICD-10 Start Date</label>
                                                <DatePicker id="icd10_start_date"
                                                    maxDate={moment().format('MM/DD/YYYY')}
                                                    date={this.state.icd10_start_date}
                                                    selected={this.state.form.icd10_start_date}
                                                    date_update={this.handleCalendarChange}
                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column mobile={16} tablet={8} computer={5}>
                                            <Form.Field >
                                                <label>&nbsp;</label>
                                                <Checkbox label='Authorization Required' name='authorization_required' checked={this.state.form.authorization_required}
                                                    onChange={(e) => this.setState({ form: { ...this.state.form, authorization_required: !this.state.form.authorization_required } })} disabled={this.state.page_state === enum_case_page_state.edit} />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column mobile={16} tablet={16} computer={16}>
                                            <Form.Field >
                                                <Checkbox label='Active' name='is_active' checked={this.state.form.is_active}
                                                    onChange={(e) => this.setState({ form: { ...this.state.form, is_active: !this.state.form.is_active } })} disabled={this.state.page_state === enum_case_page_state.edit} />
                                            </Form.Field>
                                        </Grid.Column>

                                        <ContractsSelectionBoxComponent
                                            available_contracts={this.state.available_contracts}
                                            selected_contracts={this.state.form.selected_contracts}
                                            add_all_contracts={this.add_all_contracts}
                                            add_selected_contracts={this.add_selected_contracts}
                                            remove_all_contracts={this.remove_all_contracts}
                                            remove_selected_contracts={this.remove_selected_contracts}
                                            select_contracts_to_add={this.select_contracts_to_add}
                                            select_contracts_to_remove={this.select_contracts_to_remove}
                                        />
                                    </Grid>
                                </Form>
                            </div>
                            <br />
                            <Grid>
                                <Grid.Column computer={16} textAlign="right" >
                                    <Button id="new_primary_insurance_cancel_btn" basic onClick={this.closeModal} >Cancel</Button>
                                    <Button id="new_primary_insurance_save_btn" onKeyDown={(event) => handle_focus_on_tab(event, "add_new_primary_insurance_close_icon")} type='submit' primary onClick={this.saveHandle} >Save</Button>
                                </Grid.Column>
                            </Grid>

                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </ >
        )
    }

}

const mapSTateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
    };
}

export default connect(mapSTateToProps, null)(AddPrimaryInsurance);