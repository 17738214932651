import * as global_constants from '../../global_constants';
import { log_error } from '../../shared/action/shared_action';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request';

export const advanced_control_configuration = {
    title: "",
    pagination_size: 20,
    control_defs: [],
    column_defs: [
    ]
};

/* 
 * @method  : get_control_config return modal grid data configurations 
 * @paramateres  :   controls list and page size (default page size 20)
 * @ return  : object
 * */

export const get_control_config = (config ,page_size) => {
    
    advanced_control_configuration.title = typeof config.title != 'undefined' ? config.title : '';
    advanced_control_configuration.pagination_size = page_size;
    const input_fields = config.inputFields;

    if (input_fields.length>0) {
        const inputs = input_fields.filter((data, index) => {
            if (data.type == 'TextBox' && data.type != 'null')
                return data;
            
        }).map((data) => { return { label: data.labelName } });

        const buttons = input_fields.filter((data, index) => {
            if (data.type == 'Button' && data.action != 'null')
                return data

        }).map((data) => { return { name: data.action } });

        advanced_control_configuration.control_defs['button'] = buttons;
        advanced_control_configuration.control_defs['input'] = inputs;

        const column_name = input_fields.filter((data, index) => {
            if (data.type == 'TextBox' && data.type != 'null')
                return data;

        }).map((data) => {
            return {
                headerName: data.labelName,
                field: data.name,
                filter: "textFilter",
            }});
       

        advanced_control_configuration.column_defs = column_name;
    }
 
    return advanced_control_configuration;
}

export const get_control_list = (controlId,token) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.user_urls.master_data_control}${controlId}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }
        
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return (dispatch, getState) => {
        const stored_state = getState();
        const control = controlId.toLowerCase();
        if(control===""){
            dispatch({
                type: controlId,
                payload: stored_state.advanced_control_details[control]
            });
        }
        if (typeof stored_state.advanced_control_details[control] != 'undefined') {
            dispatch({
                type: controlId,
                payload: stored_state.advanced_control_details[control]
            });
        } else {
            request.make(request_config).then(
                    response => dispatch({
                        type: controlId,
                        payload: response.data.data
                    }),
                    error => {
                        log_error(error)
                    }
                )
        }
    }
}


