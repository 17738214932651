import * as React from 'react';
import { Table, Icon, Segment, Grid, Checkbox } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import { handle_click_on_enter } from './../../shared/tab_navigation_utility';
import { user_type_r3_id, BMS_ADMIN_FULL_RIGHT_ROLE_ID, BMS_TEMPLATE_COMPANY_GMPS_CODE} from './../user_constants'

export class AppPermission extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      initial_data: undefined,
      data: this.props.company_permission_list,
      selected_data: [],
      expandedRows: [],
      all_permission: false,
      has_default_company: this.props.has_default_company,
      can_select_permission_of_company: this.props.can_select_permission_of_company,
      can_change_default_company: this.props.can_change_default_company,
      is_sys_admin_or_customer: this.props.is_sys_admin_or_customer,
      default_company: {},
      list_of_companies_with_editable_permissions: undefined,
      list_of_companies_of_user_type_sys_admin: undefined,
      list_of_companies_of_user_type_customer: undefined
    };
  }

  list_of_companies_with_editable_permissions: any = undefined;
  user_type_sys_admin = 'sys_admin';
  user_type_customer = 'customer';
  default_company:any = {};
  does_default_company_exist = false;

  componentDidUpdate(prevProps, prevState) {
    if (this.props.update_additional_permission) {
      let all_permission = true;
      this.props.company_permission_list.some((company, index, companies) => {
        if (!company.is_access) {
          all_permission = false;
          return true;
        }
      });

      this.setState({
        data: this.props.company_permission_list,
        initial_data: this.props.company_permission_list,
        all_permission: all_permission,
        is_sys_admin_or_customer: this.props.is_sys_admin_or_customer,
        can_select_permission_of_company: this.props.can_select_permission_of_company,
        can_change_default_company: this.props.can_change_default_company,
      });
    }
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }
  handleRowClick(rowId) {
    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter(id => id !== rowId)
      : currentExpandedRows.concat(rowId);

    this.setState({ expandedRows: newExpandedRows });
  }

  renderItemCaret(rowId) {
    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);
    const clickCallback = () => this.handleRowClick(rowId);
    if (isRowCurrentlyExpanded) {
      return (
        <Icon
          onClick={clickCallback}
          className='angle-color'
          style={{ cursor: 'pointer' }}
          tabIndex={0}
          onKeyDown={handle_click_on_enter}
          name='angle up'
        />
      );
    } else {
      return (
        <Icon
          onClick={clickCallback}
          className='angle-color'
          style={{ cursor: 'pointer'}}
          tabIndex={0}
          onKeyDown={handle_click_on_enter}
          name='angle down'
        />
      );
    }
  }

  update_default_company = (index, sub_index) => {
    let default_company = {
      company: index,
      sub_company: sub_index
    };

    let exisitng_default = this.state.default_company;

    // checking if exisitng data and new data is not same
    // then update default company
    // if default company already exist then dont update default company
    if(exisitng_default && exisitng_default.company != index && exisitng_default.sub_company != sub_index
      && ! this.default_company.company && ! this.default_company.sub_company) { 
      
      //
      this.does_default_company_exist = true;
      this.default_company = default_company;
    }
  }

  // child components rendering
  renderItemDetails(item, id, index, sub_index) {
    let cantMakeItDefault = item.company_id == 0;
    let set_company_as_default = item.is_default_company;

    // setting company as default, only if company is default
    // and we can make it default
    if(set_company_as_default && ! cantMakeItDefault) {
        this.update_default_company(index, sub_index);
    }

    return (
      <React.Fragment>
        <Table.Cell />
        <Table.Cell>{item.company_name}</Table.Cell>
        <Table.Cell>{item.state}</Table.Cell>
        <Table.Cell>{item.gpms_code}</Table.Cell>
        <Table.Cell className='text-center'>
          <Checkbox
            toggle
            checked={item.is_access}
            onChange={(e, data) => {
              this.enable_permission_of_a_company(e, data, index, sub_index);
            }}
            onKeyDown={handle_click_on_enter}
            disabled={!this.state.can_select_permission_of_company}
          />
        </Table.Cell>
        <Table.Cell className='text-center'>
          {!cantMakeItDefault && (
            <Checkbox
              toggle
              onChange={(e, data) => {
                this.make_company_as_default(e, data, index, sub_index);
              }}
              checked={set_company_as_default}
              onKeyDown={handle_click_on_enter}
              disabled={!this.state.can_change_default_company}
            />
          )}
        </Table.Cell>
      </React.Fragment>
    );
  }

  // rendering parent compoent
  renderItem(item, index) {
    let isOnlyCompany = item.companies.length == 0;
    let cantMakeItDefault = item.company_id == 0;
    let set_company_as_default = item.is_default_company;

    // updating the default company only if
    // this parent compant has no children 
    // and we can make it default
    // and company is a default company
    if(isOnlyCompany && !cantMakeItDefault && set_company_as_default) {
      this.update_default_company(index, -1);
    }

    // checking default company for the child
    if(item.child_default_company) {
      this.update_default_company(item.child_default_company.company, item.child_default_company.sub_company);
    }

    const itemRows = [
      <Table.Row key={'row-data-' + index}>
        <Table.Cell>
          {!isOnlyCompany ? this.renderItemCaret(index) : <Icon />}
          {item.company_name}
        </Table.Cell>
        <Table.Cell>{item.company_name}</Table.Cell>
        <Table.Cell>{item.state}</Table.Cell>
        <Table.Cell>{item.gpms_code}</Table.Cell>
        <Table.Cell className='text-center'>
          <Checkbox
            toggle
            checked={item.is_access}
            indeterminate={item.indeterminate ? item.indeterminate : false}
            onChange={(e, data) => {
              this.toggle_permission_of_parent(e, data, index);
            }}
            onKeyDown={handle_click_on_enter}
            disabled={!this.state.can_select_permission_of_company}
          />
        </Table.Cell>
        <Table.Cell className='text-center'>
          {isOnlyCompany && !cantMakeItDefault && (
            <Checkbox
              toggle
              checked={set_company_as_default}
              onChange={(e, data) => {
                this.make_company_as_default(e, data, index, -1);
              }}
              onKeyDown={handle_click_on_enter}
              disabled={!this.state.can_change_default_company}
            />
          )}
        </Table.Cell>
      </Table.Row>
    ];

    if (this.state.expandedRows.includes(index) && item.companies.length > 0) {
      item.companies.forEach((sub_company, sub_index) => {

        // dont show the company whose company_id = 0
        if(sub_company.company_id == 0 ) return;

        itemRows.push(
          <Table.Row key={'row-expanded-' + index + 'subcompany' + sub_index}>
            {this.renderItemDetails(sub_company, item.company_id, index, sub_index)}
          </Table.Row>
        );
      });
    }

    return itemRows;
  }

  toggle_permission_of_parent = (e, toggle, index) => {
    let data = this.state.data;
    let company_indeterminate = false;

    // can only change the access if company is not default
    if (!data[index].is_default_company) {
      data[index].is_access = toggle.checked;
    }

    data[index].companies = data[index].companies.map((item, index, companies) => {
      let sub_company = { ...item };

      // can only change the access if company is not default
      if (!sub_company.is_default_company) {
        sub_company.is_access = toggle.checked;
      } else {

        // if has subcompany default and tried to off the parent company
        if(!toggle.checked ) {
          company_indeterminate = true;
        }
      }

      return sub_company;
    });


    let enabledIndeterminate = data[index].companies.filter((sub_company, index, subcompanies) => {
      if(sub_company.is_access && index < subcompanies.length) {
        company_indeterminate = true;
      }

      return sub_company.is_access == false;
    });
    if (data[index].companies.length > 0) {
      if (enabledIndeterminate.length == 0) {
        data[index].is_access = true;
        data[index].indeterminate = false;
      } else {
        data[index].is_access = false;
        data[index].indeterminate = company_indeterminate;
      }
    } else {
      data[index].indeterminate = false;
    }

    var list_of_companies_with_editable_permissions = this.state.list_of_companies_with_editable_permissions;
    var list_of_companies_of_user_type_sys_admin = this.state.list_of_companies_of_user_type_sys_admin;
    var list_of_companies_of_user_type_customer = this.state.list_of_companies_of_user_type_customer;
    if (this.props.can_select_permission_of_company) {
      list_of_companies_with_editable_permissions = data;
    } else {
      if (this.props.is_sys_admin_or_customer == this.user_type_sys_admin) {
        list_of_companies_of_user_type_sys_admin = data;
      } else if (this.props.is_sys_admin_or_customer == this.user_type_customer) {
        list_of_companies_of_user_type_customer = data;
      }
    }

    let all_permission = this.state.all_permission;
    if (all_permission) {
      data.some((company, index, companies) => {
        if (!company.is_access) {
          all_permission = false;
          return true;
        }
      });
    }

    this.setState({
      data: data,
      list_of_companies_with_editable_permissions: list_of_companies_with_editable_permissions,
      list_of_companies_of_user_type_customer: list_of_companies_of_user_type_customer,
      list_of_companies_of_user_type_sys_admin: list_of_companies_of_user_type_sys_admin,
      all_permission: all_permission
    });
  };

  toggle_permission_all = (e, toggle) => {
    let data = this.state.data;
    let new_data = data.map((item, index, companies) => {
      let company = { ...item };
      let company_indeterminate = false;

      // can only change the access if company is not default
      if (!company.is_default_company) {
        company.is_access = toggle.checked;
      }

      company.companies = company.companies.map((item1, index, companies) => {
        let sub_company = { ...item1 };
        // can only change the access if company is not default
        if (!sub_company.is_default_company) {
          sub_company.is_access = toggle.checked;
        } else {

          if(!toggle.checked) {
            company_indeterminate = true;
          }

        }
        return sub_company;
      });

      let enabledIndeterminate = company.companies.filter((sub_company, index, subcompanies) => {
        if(sub_company.is_access && index < subcompanies.length) {
          company_indeterminate = true;
        }
  
        return sub_company.is_access == false;
      });
      if (company.companies.length > 0) {
        if (enabledIndeterminate.length == 0) {
          company.is_access = true;
          company.indeterminate = false;
        } else {
          company.is_access = false;
          company.indeterminate = company_indeterminate;
        }
      } else {
        company.indeterminate = false;
      }

      return company;
    });

    var list_of_companies_with_editable_permissions = this.state.list_of_companies_with_editable_permissions;
    var list_of_companies_of_user_type_sys_admin = this.state.list_of_companies_of_user_type_sys_admin;
    var list_of_companies_of_user_type_customer = this.state.list_of_companies_of_user_type_customer;
    if (this.props.can_select_permission_of_company) {
      list_of_companies_with_editable_permissions = new_data;
    } else {
      if (this.props.is_sys_admin_or_customer == this.user_type_sys_admin) {
        list_of_companies_of_user_type_sys_admin = new_data;
      } else if (this.props.is_sys_admin_or_customer == this.user_type_customer) {
        list_of_companies_of_user_type_customer = new_data;
      }
    }

    this.setState({
      data: new_data,
      all_permission: toggle.checked,
      list_of_companies_with_editable_permissions: list_of_companies_with_editable_permissions,
      list_of_companies_of_user_type_customer: list_of_companies_of_user_type_customer,
      list_of_companies_of_user_type_sys_admin: list_of_companies_of_user_type_sys_admin
    });
  };

  make_company_as_default = (e, value, index, sub_index) => {
    let data = this.state.data;
    let is_customer = this.props.is_sys_admin_or_customer == this.user_type_customer;
    let remove_access_default = this.props.is_sys_admin_or_customer == '' && !this.props.can_select_permission_of_company;
    let default_company = {};

    // disable all company as default
    let new_data = data.map((item, company_index, companies) => {
      let company = { ...item };
      let company_indeterminate = false;

      company.is_default_company = false;
      if (is_customer || remove_access_default) {
        company.is_access = false;
      }

      company.companies = company.companies.map((item1, sub_company_index, companies) => {
        let sub_company = { ...item1 };
        sub_company.is_default_company = false;

        if (is_customer || remove_access_default) {
          sub_company.is_access = false;
        }
        
        if(company_index == index && sub_index == sub_company_index) {
          sub_company.is_default_company = true;
          sub_company.is_access = true;
        }

        return sub_company;
      });
     
      let enabledIndeterminate = company.companies.filter((sub_company, index, subcompanies) => {
        if(sub_company.is_access && index < subcompanies.length) {
          company_indeterminate = true;
        }
  
        return sub_company.is_access == false;
      });
      if (company.companies.length > 0) {
        if (enabledIndeterminate.length == 0) {
          company.is_access = true;
          company.indeterminate = false;
        } else {
          company.is_access = false;
          company.indeterminate = company_indeterminate;
        }
      } else {
        company.indeterminate = false;
      }
    
      return company;
    });

    // -1 for those companies who has no child companies
    if (sub_index == -1) {
      new_data[index].is_default_company = true;
      new_data[index].is_access = true;
      new_data[index].indeterminate = false;
      default_company = {
        company: index,
        sub_company: -1
      };
    } else {
      // new_data[index].companies[sub_index].is_default_company = true;
      // new_data[index].companies[sub_index].is_access = true;

      // if( ! new_data[index].is_access) {
      //   new_data[index].indeterminate = true;
      // }

      default_company = {
        company: index,
        sub_company: sub_index
      };
    }

    var list_of_companies_with_editable_permissions = this.state.list_of_companies_with_editable_permissions;
    var list_of_companies_of_user_type_sys_admin = this.state.list_of_companies_of_user_type_sys_admin;
    var list_of_companies_of_user_type_customer = this.state.list_of_companies_of_user_type_customer;
    if (this.props.can_select_permission_of_company) {
      list_of_companies_with_editable_permissions = new_data;
    } else {
      if (this.props.is_sys_admin_or_customer == this.user_type_sys_admin) {
        list_of_companies_of_user_type_sys_admin = new_data;
      } else if (this.props.is_sys_admin_or_customer == this.user_type_customer) {
        list_of_companies_of_user_type_customer = new_data;
      }
    }

    let all_permission = this.state.all_permission;
    if (all_permission) {
      new_data.some((company, index, companies) => {
        if (!company.is_access) {
          all_permission = false;
          return true;
        }
      });
    }

    this.setState({
      data: new_data,
      has_default_company: true,
      default_company: default_company,
      list_of_companies_with_editable_permissions: list_of_companies_with_editable_permissions,
      list_of_companies_of_user_type_customer: list_of_companies_of_user_type_customer,
      list_of_companies_of_user_type_sys_admin: list_of_companies_of_user_type_sys_admin,
      all_permission: all_permission
    });
  };

  get_default_company = () => {
    let default_company = this.state.default_company;

    if(! default_company.index) {
      default_company = this.default_company;
    }

    return default_company;
  };

  enable_permission_of_a_company = (e, value, index, sub_index) => {
    let data = this.state.data;
    let parent_be_indeterminate = false;

    //if not a default company then changing the permission
    if (!data[index].companies[sub_index].is_default_company) {

      // changing permission of sub company
      data[index].companies[sub_index].is_access = value.checked;
    }

    const enabledSubcompanies = data[index].companies.filter((sub_company, index, subcompanies) => {
      if(sub_company.is_access && index < subcompanies.length) {
        parent_be_indeterminate = true;
      }

      return sub_company.is_access == false;
    });

    // if all subcompnies are checked then enable parent company
    // No need to change permission of the company
    if (enabledSubcompanies.length == 0) {
      data[index].is_access = true;
      data[index].indeterminate = false;
    } else {
      data[index].is_access = false;
      data[index].indeterminate = parent_be_indeterminate;
    }
    var list_of_companies_with_editable_permissions = this.state.list_of_companies_with_editable_permissions;
    var list_of_companies_of_user_type_sys_admin = this.state.list_of_companies_of_user_type_sys_admin;
    var list_of_companies_of_user_type_customer = this.state.list_of_companies_of_user_type_customer;
    if (this.props.can_select_permission_of_company) {
      list_of_companies_with_editable_permissions = data;
    } else {
      if (this.props.is_sys_admin_or_customer == this.user_type_sys_admin) {
        list_of_companies_of_user_type_sys_admin = data;
      } else if (this.props.is_sys_admin_or_customer == this.user_type_customer) {
        list_of_companies_of_user_type_customer = data;
      }
    }
    let all_permission = this.state.all_permission;
    if (!value.checked) {
      all_permission = false;
    }
    this.setState({
      data: data,
      list_of_companies_with_editable_permissions: list_of_companies_with_editable_permissions,
      list_of_companies_of_user_type_customer: list_of_companies_of_user_type_customer,
      list_of_companies_of_user_type_sys_admin: list_of_companies_of_user_type_sys_admin,
      all_permission: all_permission
    });
  };

  get_update_app_permission_list = () => {
    let update_list = this.state.data;
    return update_list;
  };
  has_default_company = () => {
    return this.state.has_default_company || this.does_default_company_exist;
  };

  get_list_of_companies_with_editable_permissions() {
    if (this.list_of_companies_with_editable_permissions) {
      return [...this.state.list_of_companies_with_editable_permissions];
    } else {
      return this.state.list_of_companies_with_editable_permissions;
    }
  }

  get_list_of_companies_of_user_type_sys_admin() {
    return this.state.list_of_companies_of_user_type_sys_admin;
  }

  get_list_of_companies_of_user_type_customer() {
    return this.state.list_of_companies_of_user_type_customer;
    }

    check_bms_template_company = (item) => {
        var is_templete_company = false;
        if (item.gpms_code === BMS_TEMPLATE_COMPANY_GMPS_CODE) {
            is_templete_company = true;
        }
        else {
            if (item.companies.length > 0) {
                item.companies.filter((comp) => {
                    if (comp.gpms_code === BMS_TEMPLATE_COMPANY_GMPS_CODE) {
                        is_templete_company = true;
                    }
                })
            }
        }
        return is_templete_company;
    }

  render() {
    let allItemRows = [];

    this.state.data.forEach((item, index) => {
        //console.log('selected_user_type', this.props.selected_user_type)
        //console.log('selected_role_type', this.props.selected_role_type)

         // show Template company for user having user type sys Admin or BMS and Role is BMS full Right
        var haveTempleteCompany = this.check_bms_template_company(item);
        var show_templateCompany = false;
        if (this.props.selected_user_type && this.props.selected_user_type.code == user_type_r3_id.sys_admin  ) {
            show_templateCompany = true;

        }
        else if (this.props.selected_user_type && this.props.selected_user_type.code == user_type_r3_id.bms
            && this.props.selected_role_type && this.props.selected_role_type.code == BMS_ADMIN_FULL_RIGHT_ROLE_ID) {
            show_templateCompany = true;

        }
        if (haveTempleteCompany && !show_templateCompany) {
            return;
        }

      // dont show the company whose company_id = 0
        if (item.company_id == 0 ) {
            return;
        }

      const perItemRows = this.renderItem(item, index);
      allItemRows = allItemRows.concat(perItemRows);
    });

    return (
      <div className='customtable' style={{ border: 0 }}>
        <div className='table-responsive'>
          <Table selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Parent Company</Table.HeaderCell>
                <Table.HeaderCell>Company</Table.HeaderCell>
                <Table.HeaderCell>State</Table.HeaderCell>
                <Table.HeaderCell>Company Code</Table.HeaderCell>
                <Table.HeaderCell className='text-center'>
                  <div style={user_css.postionRe}>
                    <Checkbox
                      toggle
                      checked={this.state.all_permission}
                      onChange={this.toggle_permission_all}
                      onKeyDown={handle_click_on_enter}
                      disabled={!this.state.can_select_permission_of_company}
                    />
                    <span style={user_css.allText}> All</span>
                  </div>
                </Table.HeaderCell>
                <Table.HeaderCell className='text-center'>Default</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {allItemRows.length > 0 && <Table.Body>{allItemRows}</Table.Body>}
          </Table>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

const mapStateToProps = state => {
  return {};
};
// Css Style
const user_css = {
  postionRe: {
    margin: 0,
    padding: 0,
    display: 'inline-block',
    width: '120px',
    position: 'relative'
  } as React.CSSProperties,
  allText: {
    position: 'absolute',
    top: '1px',
    left: '90px'
  } as React.CSSProperties
};
export default reduxForm({
  form: 'reset'
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AppPermission)
);
