import { connect } from 'react-redux';
import * as React from 'react';
import { Button, Dimmer, Grid, Loader, Form, Input, Divider } from 'semantic-ui-react';
import * as constants from '../835_error_exclusions';

import EditAddErrorExclusions835Component from './835_edit_add_error_exclusions_component';



class ErrorExclusions835Component extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        // Define initial state value.
        this.state = {}
    }

    _is_mounted = false;
    page_metadata: any;
    token: string = '';


    UNSAFE_componentWillMount = () => {
        this.get_page_metadata();
    };

    componentDidMount = () => {
        this._is_mounted = true;
        document.body.classList.add('admin-framework');
    };

    componentWillUnmount = () => {
        this._is_mounted = false;
        document.body.classList.remove('admin-framework');
    };

    get_page_metadata = () => {
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        //Verifying page_name is correct or not.
        this.page_metadata = constants.error_exclusions;
    };

    is_loading(): boolean {
        return false;
    }

    render() {
        return (
            <React.Fragment>
                <div className={'admin-wrapper ' + this.page_metadata.id + ' error_835_wrapper'} style={error_css.locations_id}>
                    <Grid className='headerGrid' style={error_css.headerGrid}>
                        <Grid.Column computer={16}>
                            <h3 className='ui header left aligned'>{this.page_metadata.edit_header}</h3>
                        </Grid.Column>
                    </Grid>
                    <EditAddErrorExclusions835Component />
                </div>
            </React.Fragment>
        );
    }
}

// Css Style
const error_css = {
    locations_id: {
        paddingBottom: 0
    },
    headerGrid: {
        marginTop: 0,
        marginBottom: 0,
        paddingRight: 15
    }
};

const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
        shared_details: state.shared_details,
    };
};

export default connect(mapStateToProps)(ErrorExclusions835Component);