// Row Counts
import * as report_constants from "../report_constants";
import { print_html_data_to_pdf } from "../../shared/utility";
import { print_attorney_statement } from '../action/report_action';
import * as local_storage from '../../shared/local_storage_utility';
import { user_type_r3_id } from "../../user/user_constants";



export const on_print_button_attorney_statement = (mdlDto, current_user_id, auto_print = false) => {
    var ua = window.navigator.userAgent;
    var printContent = '';
    var objcount = mdlDto.length;
    var vpatient_id = '';
    var amount_due = 0.00;
    var pagerows = 0;
    var pagerowsmax = 30;

    for (var i = 0; i < objcount; i++) {
        if ((mdlDto[i].patient_id != vpatient_id) || pagerows > pagerowsmax) {

            if (mdlDto[i].patient_id != vpatient_id) {
                // Create Patient Note on patient id change
                const token = local_storage.get("auth_data").token_details.access_token;
                var param = {
                    patientId: mdlDto[i].patient_id,
                    total_amount_due: mdlDto[i].total_amount_due,
                    created_by: current_user_id
                }
                const response = print_attorney_statement(param, token);
            }

            if ((vpatient_id != '') && (mdlDto[i].patient_id != vpatient_id)) {
                // SubTotal record on Patient ID change (but not the first id change)
                printContent += "<tr><td colspan=6><hr></td></tr>";
                printContent += "<tr>";
                printContent += "  <td>&nbsp;</td>";
                printContent += "  <td>&nbsp;</td>";
                printContent += "  <td>&nbsp;</td>";
                printContent += "  <td>&nbsp;</td>";
                printContent += "  <td style='text-align: right;'>TOTAL BALANCE DUE:</td>";
                printContent += "  <td style='text-align: right;'>" + report_constants.currencyFormatter(amount_due) + "</td>";
                printContent += "</tr>";
                printContent += "</tr><td colspan=6>&nbsp;</td></tr>";
                printContent += "</tr><td colspan=6>PLEASE CALL WITH STATUS OF YOUR CASE: " + mdlDto[i].company_phone + "</td></tr>";
                printContent += "</table></div>";
                amount_due = 0.00;
            } else {
                if (vpatient_id != '') {
                    printContent += "</table></div>";
                }
            }
            // Page Headers
            printContent += "<div class='reportAttorneyStatement' style='page-break-after: always;' >";
            printContent += "<table><tr>";
            printContent += "  <td style='width:5%'>&nbsp;</td>";
            printContent += "  <td style='width:45%'>" + mdlDto[i].company_name + "</td> ";
            printContent += "  <td style='width:50%'>";
            printContent += "</tr>";
            printContent += "<tr>";
            printContent += "  <td>&nbsp;</td>";
            printContent += "  <td>" + mdlDto[i].company_address1 + "</td>";
            printContent += "  <td></td>";
            printContent += "</tr>";
            if (mdlDto[i].company_address2 != '') {
                printContent += "<tr>";
                printContent += "  <td>&nbsp;</td>";
                printContent += "  <td>" + mdlDto[i].company_address2 + "</td>";
                printContent += "  <td></td>";
                printContent += "</tr>";
                printContent += "<tr>";
            }
            printContent += "  <td>&nbsp;</td>";
            printContent += "  <td>" + mdlDto[i].company_city + " " + mdlDto[i].company_state + " " + mdlDto[i].company_zip + "</td>";
            printContent += "  <td></td>";
            printContent += "</tr>";
            printContent += "</table>";
            printContent += "<table>";
            printContent += "<tr>";
            printContent += "  <td style='width:5%;'>&nbsp;</td>";
            printContent += "  <td style='width:45%; text-align: left;'>" + mdlDto[i].ins_name + "</td>";
            printContent += "  <td style='width:15%; text-align: left;'>STATEMENT DATE:</td>";
            printContent += "  <td style='width:35%; text-align: left;'>" + mdlDto[i].statement_date + "</td>";
            printContent += "</tr>";
            printContent += "<tr>";
            printContent += "  <td>&nbsp;</td>";
            printContent += "  <td style='text-align: left;'>" + mdlDto[i].ins_address1 + "</td>";
            printContent += "  <td style='text-align: left;'>PATIENT NAME:</td>";
            printContent += "  <td style='text-align: left;'>" + mdlDto[i].patient_name + "</td>";
            printContent += "</tr>";
            if (mdlDto[i].ins_address2 != '') {
                printContent += "<tr>";
                printContent += "  <td>&nbsp;</td>";
                printContent += "  <td>" + mdlDto[i].ins_address2 + "</td>";
                printContent += "  <td style='text-align: left;'>PATIENT ID:</td>";
                printContent += "  <td>" + mdlDto[i].patient_id + "</td>";
                printContent += "</tr>";
                printContent += "<br>";
                printContent += "<tr>";
                printContent += "  <td>&nbsp;</td>";
                printContent += "  <td>" + mdlDto[i].ins_city + "&nbsp;&nbsp;" + mdlDto[i].ins_state + "&nbsp;" + mdlDto[i].ins_zip + "</td>";
                printContent += "  <td style='text-align: left;'>DATE OF INJURY:</td>";
                printContent += "  <td>" + mdlDto[i].date_of_injury + "</td>";
                printContent += "</tr>";
            } else {
                printContent += "<tr>";
                printContent += "  <td>&nbsp;</td>";
                printContent += "  <td>" + mdlDto[i].ins_city + "&nbsp;&nbsp;" + mdlDto[i].ins_state + "&nbsp;" + mdlDto[i].ins_zip + "</td>";
                printContent += "  <td style='text-align: left;'>PATIENT ID:</td>";
                printContent += "  <td>" + mdlDto[i].patient_id + "</td>";
                printContent += "</tr>";
                printContent += "<br>";
                printContent += "<tr>";
                printContent += "  <td>&nbsp;</td><td>&nbsp;</td>";
                printContent += "  <td style='text-align: left;'>DATE OF INJURY:</td>";
                printContent += "  <td>" + mdlDto[i].date_of_injury + "</td>";
                printContent += "</tr>";
            }
            printContent += "</table>";
            printContent += "<br>";
            // ROW Column Headers
            printContent += "<table><thead>";
            printContent += "<td style='text-decoration: underline;'>Date of Service</td>";
            printContent += "<td style='text-decoration: underline;'>Proc Code</td>";
            printContent += "<td style='text-decoration: underline;'>Description</td>";
            printContent += "<td style='text-align: right; text-decoration: underline;'>Charge Amount</td>";
            printContent += "<td style='text-align: right; text-decoration: underline;'>Payment/Adj Amount</td>";
            printContent += "<td style='text-align: right; text-decoration: underline;'>Amount Due</td>";
            printContent += "</thead>";
            pagerows = 0;
        }
        // ROW Data
        pagerows += 1;
        printContent += "<tr>";
        printContent += "<td>" + mdlDto[i].row_date_of_service + "</td>";
        printContent += "<td>" + mdlDto[i].row_proc_code + "</td>";
        printContent += "<td>" + mdlDto[i].row_description + "</td>";
        printContent += "<td style='text-align: right;'>" + report_constants.currencyFormatter(mdlDto[i].row_charge_amount) + "</td>";
        printContent += "<td style='text-align: right;'>" + report_constants.currencyFormatter(mdlDto[i].row_pay_adj_amount) + "</td>";
        printContent += "<td style='text-align: right;'>" + report_constants.currencyFormatter(mdlDto[i].row_amount_due) + "</td>";
        printContent += "</tr>";
        amount_due += mdlDto[i].row_amount_due;
        vpatient_id = mdlDto[i].patient_id;

        // SubTotal record on last page
        if ((i == objcount - 1) && (vpatient_id != '')) {
            printContent += "<tr><td colspan=6><hr></td></tr>"
            printContent += "<tr>"
            printContent += "  <td>&nbsp;</td>";
            printContent += "  <td>&nbsp;</td>";
            printContent += "  <td>&nbsp;</td>";
            printContent += "  <td>&nbsp;</td>";
            printContent += "  <td style='text-align: right;'>TOTAL BALANCE DUE:</td>";
            printContent += "  <td style='text-align: right;'>" + report_constants.currencyFormatter(amount_due) + "</td>";
            printContent += "</tr>";
            printContent += "</tr><td colspan=6>&nbsp;</td></tr>";
            printContent += "</tr><td colspan=6>PLEASE CALL WITH STATUS OF YOUR CASE: " + mdlDto[i - 1].company_phone + "</td></tr>";
            printContent += "</table></div></div>";
        }
    }
    if (auto_print) {
        return printContent;
    } else {
        print_html_data_to_pdf(printContent, 'print_report_button');
    }
};