// Add/Edit Payer Rules Search Component
import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Button, Checkbox, Dimmer, Form, Grid, Input, Loader } from 'semantic-ui-react';
import * as export_print_utility from '../../../reports/util/export_print_utility';
import GridView from '../../../shared/component/grid';
import AdvanceQuickSearch from "../../constants/component/claim_adjustment_code_advanced_search";
import * as session_storage from '../../../shared/session_storage_utility';
import { set_focus_on_element_with_id, set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import * as constants_actions from '../action/constants_action';
import { get_columns, is_user_partner_customer_and_company_revEquip_revServe,  } from "./../../../shared/utility"
import * as constants from '../constants';
import * as local_storage from '../../../shared/local_storage_utility';
import * as global_constants from '../../../global_constants';
import { LoaderComponent } from '../../../shared/component/loading_component'
import * as ReportConstants from '../../../reports/report_constants';
import { providerBillingSettingsFeatures,  } from '../../../admin/constants/constants';
import {
    advanced_search_payer,
    advanced_search_rejection_group,
    quick_search_payer,
    quick_search_rejection_group
} from '../../../shared/action/shared_action';

class StatusCodeSearchComponent extends React.Component<any, any> {


    constructor(props) {
        super(props);
        // Define initial state value.
        this.state = {
            show_grid: false,
            show_render: true,
            search_criteria: {},
            is_loading: false,
            is_search_button_disabled: false,
            shown: false,
            grid_rows_count: 0,
            group_code_inactive: null,
            payer: null,
            payer_search_data: null,
            group_code_search_data: null,
            group_code_selected_row: {},
            payerid_selected_row: {}
        };
    }

    // Define initial properties and its value.
    _is_mounted = false;
    page_name: string = 'status_code';
    page_metadata: any;
    width_padding: number = 100;

    search_criteria: any = {
        code: '',
        description: '',
        group_id: '',
        payer_id: '',
        include_inactive: false
    };

    initial_search_criteria: any = {
        code: '',
        description: '',
        group_id: '',
        payer_id: '',
        include_inactive: false
    };

    token: string = '';
    row_data = [];
    is_search_ui: any = false;
    code_id: number = 0;
    is_grid_visible = false;
    title = '';
    current_date: any;
    company_name: any;
    total_column_width: number = 0;
    grid_header_height: number = 0;
    current_user_type: global_constants.enum_user_type_r3_id = 1;
    company_service_type = 0;
    group_code_selected_row: any;
    payerid_selected_row: any;
    search_enum = {
        payer: 'payer',
        group_code: 'group_code'
    };
    reportId: string = '0';

    // Fetching the page_name and state.
    UNSAFE_componentWillMount = () => {
        this.get_page_metadata();
        var auth_data = local_storage.get("auth_data");
        if (auth_data) {
            this.current_user_type = auth_data.user_type_r3_id;
            this.company_service_type = auth_data.company_service_type;
        }
    };

    componentDidMount = () => {
        this._is_mounted = true
        document.body.classList.add('admin-framework');
        let elem = document.getElementById('search-criteria-container');
        let all_form_elem = elem.getElementsByTagName('input');
        all_form_elem[0].focus();
        this.handle_search_button_tab();
        this.setState({
            group_code_inactive: this.page_metadata.group_code_inactive,
            payer: this.page_metadata.payer
        })
    };

    componentWillUnmount = () => {
        this._is_mounted = false;
        document.body.classList.remove('admin-framework');
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[2] !== 'status_code' && session_storage.remove('status_code_search_criteria');
        }
    };

    //Use to fetch params and page metadata
    get_page_metadata = () => {
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        //Verifying page_name is correct or not.
        this.page_metadata = constants.status_code;
        let search_data = session_storage.get('status_code_search_criteria')
        this.search_criteria = (search_data && search_data.search_criteria) || this.search_criteria;
        this.group_code_selected_row = (search_data && search_data.group_code_selected_row) || this.group_code_selected_row;
        this.payerid_selected_row = (search_data && search_data.payerid_selected_row) || this.payerid_selected_row;
        if (search_data) {
            this.search_handler(this);
        }

        this.setState({
            show_render: true
        });
    };

    // Data formatter
    format_data = (rows, columns) => {
        let formatted_data = rows.map(row_data => {
            columns.filter(col_data => {
                switch (col_data.type) {
                    case 'currency':
                        row_data[col_data.field] =
                            row_data[col_data.field] != null || row_data[col_data.field] != undefined ? constants.currencyFormatter(row_data[col_data.field]) : constants.currencyFormatter(0);
                        break;
                    case 'boolean':
                        row_data[col_data.field] = row_data[col_data.field] ? true : false;
                        break;
                    case 'number':
                        row_data[col_data.field] = row_data[col_data.field];
                        break;
                    case 'string':
                        row_data[col_data.field] = row_data[col_data.field];
                        break;
                    default:
                        row_data[col_data.field] = row_data[col_data.field];
                }
            });
            return row_data;
        });
        return formatted_data;
    };

    //On double click of grid, it redirects user to view page.
    on_row_double_clicked = row_data => {
        this.code_id = row_data.id;
        this.props.history.push(`${this.page_metadata.base_url}/${this.page_name}/${this.code_id}`);
    };

    //Input change handler.
    input_change_handler = e => {
        const { value, checked, type, name } = e.target;
        if (type == 'checkbox') {
            this.search_criteria[name] = checked;
        } else {
            this.search_criteria[name] = value;
        }
        this.setState({
            search_criteria: this.search_criteria
        });
    };

    //Search button handler and used to search the data on the basis of search criteria.
    search_handler = e => {
        var url = this.page_metadata.api.search.url;
        for (let key in this.search_criteria) {
            url = url.replace(key + '_value', encodeURIComponent(this.search_criteria[key].toString().trim()));
        }
        this.setState({
            is_loading: true,
            is_search_button_disabled: true
        });
        constants_actions.get_search_data(this.token, url).then(
            response => {
                if (response.data) {
                    this.row_data = response.data.data ? response.data.data : [];
                    this.row_data = this.format_data(this.row_data, this.page_metadata.column_def);
                    const grid_height = get_columns(this.row_data, this.page_metadata.column_def);
                    this.total_column_width = grid_height.total_column_width;
                    this.grid_header_height = grid_height.header_height;
                    this.is_search_ui = true;
                    this.setState({
                        show_grid: true,
                        is_loading: false,
                        is_search_button_disabled: false,
                        search_criteria: this.search_criteria,
                        grid_rows_count: this.row_data && this.row_data.length,
                        payerid_selected_row: this.payerid_selected_row,
                        group_code_selected_row: this.group_code_selected_row
                    });

                    session_storage.set('status_code_search_criteria', {
                        search_criteria: this.search_criteria,
                        payerid_selected_row: this.payerid_selected_row,
                        group_code_selected_row: this.group_code_selected_row
                    });
                }
            },
            error => {
                this.setState({
                    is_loading: false,
                    is_search_button_disabled: false
                });
            }
        );
    };

    //Reset button the search control.
    clear_handler = e => {
        this.search_criteria = { ...this.initial_search_criteria };
        this.group_code_selected_row = {};
        this.payerid_selected_row = {};
        this.setState({
            show_grid: false,
            shown: false,
            search_criteria: this.initial_search_criteria,
            grid_rows_count: 0,
            group_code_selected_row: {},
            payerid_selected_row: {}
        });
        this.is_search_ui = false;
        this.is_grid_visible = false;
        session_storage.remove('status_code_search_criteria');
    };

    //New button handler.
    new_handler = e => {
        this.props.history.push(`${this.page_metadata.base_url}/${this.page_name}`);
    };

    //Function calls on initialization of Ag-Grid and catch its reference.
    get_grid_ref = grid_params => {
        this.setState({
            grid_params,
            grid_rows_count: grid_params.api.getModel().getRowCount()
        });
        var grid_width = document.getElementsByClassName('admin-wrapper')[0].clientWidth;
        if (this.total_column_width > (grid_width - this.width_padding)) {
            this.page_metadata.column_def.filter(item => {
                grid_params.columnApi.setColumnWidth(item.field, item.width, false);
            });
        }
    };

    //Used to create the title for export and print.
    set_title = separter => {
        this.company_name = this.props.user_login_details.user_data.data.company_name;
        this.current_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
        this.title = this.page_metadata.search_header;
        return this.title =
            this.title.replace(/<br>/g, separter) +
            separter +
            this.company_name +
            separter +
            this.current_date +
            separter +
            (this.state.grid_rows_count == 1
                ? this.state.grid_rows_count + ' record'
                : this.state.grid_rows_count + ' records') +
            separter +
            separter;
    };

    //Function calls on initialization of export report data
    on_export_button = () => {
        this.title = this.set_title('\n');
        this.page_name = this.page_name.replace(/<br>/g, '');
        export_print_utility.export_grid_data(this.state.grid_params, this.title, this.page_name);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Export);
    };

    //Function calls on initialization of Print report data
    on_print_button = () => {
        this.title = this.set_title('<br>');
        export_print_utility.print_grid_data(this.state.grid_params, this.title, this.page_name);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
    };

    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;
        const payload: ReportConstants.IPayloadForBillingAudit = {
            reportId: this.reportId,
            contextTitle: this.page_metadata.search_header + " - " + ReportConstants.ReportEventAction[eventActionId],
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: ReportConstants.ReportEntityTypeId.D_Charge,
            data: { Records: this.state.grid_rows_count }
        }
        const reqBody = export_print_utility.generateAuditDataEntryPayload(payload);
        ReportConstants.saveAuditPrintExport(reqBody, accessToken);
    };

    on_grid_out = () => {
        set_focus_on_element_with_id('app_header_dropdown');
    };

    handle_search_button_tab = () => {
        setTimeout(() => {
            let searchButton = document.getElementById('search_button_id');
            let self = this;
            searchButton.addEventListener('keydown', function (event) {
                // apply a check where grid is empty
                if (!event.shiftKey && (self.row_data == null || (self.row_data.length == 0 && !self.state.show_grid))) {
                    set_focus_to_app_header(event);
                }
            });
        }, 200);
    };

    update_report_rows_count = () => {
        this.setState({
            grid_rows_count: this.state.grid_params.api.getModel().getRowCount()
        });
    };

    update_data = (data, type) => {
        if (type == this.search_enum.group_code) {
            this.search_criteria.group_id = data ? data.e277_status_cd_grp_id : '';
            this.setState({
                search_criteria: this.search_criteria,
                group_code_selected_row: data
            });
            this.group_code_selected_row = data
        } else {
            this.search_criteria.payer_id = data ? data.payer_key_id : '';
            this.setState({
                search_criteria: this.search_criteria,
                payerid_selected_row: data
            });
            this.payerid_selected_row = data
        }
    }

    on_quick_search = async (search_keyword: any, type: string) => {
        let result: any;
        if (type == this.search_enum.payer) {
            result = await quick_search_payer({ keyword: search_keyword }, this.token);
        } else {
            result = await quick_search_rejection_group({ keyword: search_keyword, include_inactive: true }, this.token);
        }
        result.data.data = result.data && result.data.data ? result.data.data.sort((a, b) => a.name.localeCompare(b.name)) : result;
        return result;
    };

    on_advanced_search = async (params, type: string) => {
        if (type == this.search_enum.payer) {
            params.Description = params.Description || ""
            params.Outbound_id = params.Outbound_id || ""
            params.name = params.name || ""
            params.Inbound_id = params.Inbound_id || ""
            advanced_search_payer(params, this.token).then((res) => {
                if (this._is_mounted) {
                    this.setState({
                        payer_search_data: {
                            rows: res.data.data,
                            column: constants.insurance.payerid_column_defs
                        }
                    })
                }
            }, error => {
                if (error.response && error.response.data && error.response.data.messages) {
                    toastr.error('', error.response.data.messages[0].message);
                }
            });

        } else {
            params.Description = params.Description || "";
            params.Name = params.Name || "";
            params.show_inactive = params.show_inactive || false;
            advanced_search_rejection_group(params, this.token).then((res) => {
                if (this._is_mounted) {
                    this.setState({
                        group_code_search_data: {
                            rows: res.data.data,
                            column: constants.status_code.group_code_column_defs
                        }
                    });
                }
            }, error => {
                if (error.response && error.response.data && error.response.data.messages) {
                    toastr.error('', error.response.data.messages[0].message);
                }
            });
        }
    };

    //It renders report grid and its search controls.
    render() {
        return (
            this.state.show_render && (
                <LoaderComponent loading={this.state.is_loading || this.state.is_loading_dropdown}>                   
                    <div className={'admin-wrapper ' + this.page_metadata.id} style={!this.state.show_grid ? { paddingBottom: 20 } : {}}>
                        <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                            <Grid.Column computer={16}>
                                <h3 className='ui header left aligned' dangerouslySetInnerHTML={{ __html: this.page_metadata.search_header }} />
                            </Grid.Column>
                        </Grid>
                        <div id='admin-scrollable-area' className='wrapper' style={this.state.show_grid ? { flex: 1 } : {}}>
                            <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container'>
                                <Form autoComplete='off' onSubmit={e => this.search_handler(e)}>
                                    <Grid>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Code</label>
                                                <Input
                                                    autoComplete='off'
                                                    name='code'
                                                    id='code'
                                                    maxLength={50}
                                                    onChange={e => this.input_change_handler(e)}
                                                    value={this.search_criteria['code'] ? this.search_criteria['code'] : ''}
                                                    type='text'
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Description</label>
                                                <Input
                                                    autoComplete='off'
                                                    id='description'
                                                    name='description'
                                                    maxLength={255}
                                                    onChange={e => this.input_change_handler(e)}
                                                    value={this.search_criteria['description'] ? this.search_criteria['description'] : ''}
                                                    type='text'
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field className="advance-quick-search">
                                                <label>Group Code</label>
                                                <AdvanceQuickSearch
                                                    control={this.state.group_code_inactive ? this.state.group_code_inactive : null}
                                                    update_data={this.update_data}
                                                    search_result={this.state.group_code_search_data}
                                                    on_quick_search={this.on_quick_search}
                                                    on_advanced_search={this.on_advanced_search}
                                                    selected_row={this.state.group_code_selected_row}
                                                    headerIdForGridTabNavigation={constants.status_code.group_code_header_id}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field className="advance-quick-search">
                                                <label>Payer</label>
                                                <AdvanceQuickSearch
                                                    control={this.state.payer ? this.state.payer : null}
                                                    update_data={this.update_data}
                                                    search_result={this.state.payer_search_data}
                                                    on_quick_search={this.on_quick_search}
                                                    on_advanced_search={this.on_advanced_search}
                                                    selected_row={this.state.payerid_selected_row}
                                                    headerIdForGridTabNavigation={constants.insurance.payerid_header_id}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid>
                                        <Grid.Column tablet={8} computer={4} verticalAlign="middle">
                                            <Form.Field >
                                                <Checkbox
                                                    name='include_inactive'
                                                    id='include_inactive'
                                                    onChange={e => this.input_change_handler(e)}
                                                    checked={this.search_criteria['include_inactive'] ? this.search_criteria['include_inactive'] : false}
                                                    label={'Include Inactive'}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid style={{ margin: '0 -17px' }}>
                                        <Grid.Column computer={16} textAlign='right'>
                                            <Button id='clear_button_id' type='button' onClick={e => this.clear_handler(e)} basic>Clear</Button>
                                            {is_user_partner_customer_and_company_revEquip_revServe(this.company_service_type, this.current_user_type) ? null :
                                                <Button id='new_button_id' type='button' onClick={e => this.new_handler(e)} basic>New</Button>}
                                            <Button id='search_button_id' type='submit' primary>Search</Button>
                                        </Grid.Column>
                                    </Grid>
                                    {this.state.show_grid && (
                                        <Grid style={{ marginTop: '-1rem', marginBottom: 0 }}>
                                            <Grid.Column tablet={5} computer={4} textAlign='left'>
                                                <p style={{ fontSize: '16px' }}>Search Results</p>
                                            </Grid.Column>
                                            <Grid.Column tablet={5} computer={8} textAlign='center'>
                                                {
                                                    <p style={{ fontSize: '16px', minHeight: 22 }}>
                                                        {`${this.state.grid_rows_count} ${
                                                            this.state.grid_rows_count == 1 ? ' record shown' : ' records shown'
                                                            }`}
                                                    </p>
                                                }
                                            </Grid.Column>
                                            <Grid.Column tablet={2} computer={4} textAlign='right' />
                                        </Grid>
                                    )}
                                </Form>
                            </div>
                            {this.state.show_grid && !this.state.is_loading && (
                                <GridView
                                    id={`id_grid_${this.page_name}`}
                                    row={this.row_data}
                                    column={this.page_metadata.column_def}
                                    style={{ height: '100%' }}
                                    wrapperStyle={{ width: '100%', height: '100%', display: 'flex' }}
                                    suppressMovableColumns={false}
                                    enableColResize={true}
                                    onRowDoubleClicked={this.on_row_double_clicked}
                                    selectionType={'single'}
                                    get_grid_ref={this.get_grid_ref}
                                    suppressSizeToFit={true}
                                    headerHeight={this.grid_header_height}
                                    headerIdForTabNavigation={constants.search_grid_id}
                                    onForceGridOut={this.on_grid_out}
                                    on_filter_button_click={() => this.update_report_rows_count()}
                                />
                            )}
                        </div>
                        {this.state.show_grid && (
                            <div
                                className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                                id='applicationFooterSticky'
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                                <Grid.Column computer={16} textAlign='right'>
                                    {this.page_metadata.show_export_on_search
                                        && (<Button
                                            id='export_report_button'
                                            type='submit'
                                            onClick={this.on_export_button}
                                            basic>Export</Button>)
                                    }
                                    <Button
                                        onKeyDown={set_focus_to_app_header}
                                        id='print_report_button'
                                        type='submit'
                                        onClick={this.on_print_button}
                                        disabled={this.state.is_search_button_disabled}
                                        primary>Print</Button>
                                </Grid.Column>
                            </div>
                        )}
                    </div>
                </LoaderComponent>
            )
        );
    }
}

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
    };
};

export default connect(mapStateToProps)(StatusCodeSearchComponent);