import * as React from 'react';
import { text_comparator } from '../../../shared/component/grid/comparators';
import { get_selected_insurance_code_value, location_label, insurance_name } from '../utility';
const col_style = { display: 'flex', alignItems: 'center' };

interface IProps {
    columns: Array<any>,
    rows: Array<any>,
    id?: string,
    name?: string,
    onSortChanged?: Function,
    onColumnMoved?: Function
}


/**
 * Provider Billing header component
 * This is custome table header and has been developed to manage table sort, resize and reorder functionalites
 */
class ProviderBillingHeaderComponent extends React.Component<IProps, any> {

    sort_type: string = '';
    sort_key: string = '';

    // Get innitalise data when component created
    constructor(props) {
        super(props);
        this.state = {
            cols: this.props.columns,
            dragOver: ''
        }
    }

    componentDidMount() {
        var table = document.getElementById('id-table-billing-settings-form');
        resizableGrid(table);
    }

    //Move required information on view drag
    handleDragStart = e => {
        let isIE = window.navigator.userAgent.indexOf('Trident/') >= 0;
        e.dataTransfer.setData(isIE ? 'text' : 'text/plain', `${e.target.id}`);
    };

    handleDragOver = e => e.preventDefault();

    handleDragEnter = e => {
        const { id } = e.target;
        this.setState({ dragOver: id });
    };

    //Received data here view drop
    handleOnDrop = e => {
        e.preventDefault();
        let destination_field = e.currentTarget.id;
        let isIE = window.navigator.userAgent.indexOf('Trident/') >= 0;
        const source_field = e.dataTransfer.getData(isIE ? 'text' : 'text/plain');
        if (source_field && source_field !== destination_field) {
            let source_col = null;
            let source_col_index = -1;
            let destination_col_index = -1;
            let columns = this.state.cols.filter((value, index) => {
                if (value.field === source_field) {
                    source_col = value;
                    source_col_index = index;
                } else if (value.field === destination_field) {
                    destination_col_index = index;
                }
                return value.field != source_field;
            });
            let index = columns.findIndex(value => value.field == destination_field);
            if (index != -1 && source_col_index != -1 && destination_col_index != -1) {
                let position = (source_col_index < destination_col_index) ? index + 1 : index;
                columns.splice(position, 0, source_col);
                this.setState(prevState => ({
                    cols: columns, dragOver: destination_field
                }), () => {
                    this.props.onColumnMoved(this.props.id, this.state.cols);
                });
            }
        }
    };

    //Call on column sorting click
    sortBy = (key) => {
        let arrayCopy = [...this.props.rows];
        this.sort_type = this.get_sort_type(key, this.sort_type);
        this.sort_key = key;
        if (this.sort_type !== '') {
            if (key === 'location') {
                arrayCopy.sort((a, b) => {
                    return this.order_by(location_label(a), location_label(b), this.sort_type)
                });
            } else if (key === 'insurance_class_id') {
                arrayCopy.sort((a, b) => {
                    return this.order_by(insurance_name(a), insurance_name(b), this.sort_type)
                });
            } else if (key === 'insurance_code') {
                arrayCopy.sort((a, b) => {
                    return this.order_by(get_selected_insurance_code_value(a.insurance_code).label,
                        get_selected_insurance_code_value(b.insurance_code).label, this.sort_type)
                });
            } else if (key === 'dos_range') {
                arrayCopy.sort((a, b) => {
                    return this.order_by(b['start_date'], a['start_date'], this.sort_type);
                });        
            } else if (key === 'not_used_for_billing') {
                arrayCopy.sort((a, b) => {
                    let first = a.not_used_for_billing ? true : false;
                    let next = b.not_used_for_billing ? true : false;
                    return this.order_by(first, next, this.sort_type)
                });
            } else {
                arrayCopy.sort((a, b) => this.order_by(a[key], b[key], this.sort_type));
            }
        }
        this.props.onSortChanged(this.props.id, arrayCopy);
    }

    //Change sorting type
    get_sort_type = (key, type) => {
        let stype = 'asc';
        if (key === this.sort_key) {
            if (type === '') {
                stype = 'asc';
            } else if (type === 'asc') {
                stype = 'desc';
            } else if (type === 'desc') {
                stype = '';
            }
        }
        return stype;
    }

    //Perform sorting
    order_by = (first, next, type) => {
        if (type === 'asc') {
            return text_comparator(first, next);
        } else if (type === 'desc') {
            return text_comparator(next, first);
        }
    }

    //Change sorting icon
    render_sort_icon = (key) => {
        if (key === this.sort_key) {
            if (this.sort_type === 'asc') {
                return 'ascending'
            } else if (this.sort_type === 'desc') {
                return 'descending';
            }
        }
        return '';
    }

    //Render header columns 
    render_columns = () => {
        return this.state.cols.map((col) => {
            return (
                <th style={{ ...col.style, position: 'relative' }} key={col.field}>
                    <div className="table-col-header-container"
                        onClick={() => this.sortBy(col.field)}
                    >
                        <span className="header-col-label"
                            id={col.field}
                            draggable
                            onDragStart={this.handleDragStart}
                            onDragOver={this.handleDragOver}
                            onDrop={this.handleOnDrop}
                            onDragEnter={this.handleDragEnter}
                        >{col.headerName}</span>
                        <span className="col-header-sort-icon">
                            <i aria-hidden="true" className={`sort ${this.render_sort_icon(col.field)} small icon`}></i>
                        </span>
                    </div>
                    <div className="header-col-resize" />
                </th>
            );
        })
    }

    //Render function
    render() {
        return (<tr>{this.render_columns()}</tr>);
    }
};

//apply column resizing functionality
const resizableGrid = (table) => {
    var col_resize = table.querySelectorAll('thead .header-col-resize');
    if (!col_resize) return;

    for (var i = 0; i < col_resize.length; i++) {
        setListeners(col_resize[i]);
    }

    function setListeners(div) {
        var pageX, curCol, nxtCol, curColWidth, nxtColWidth;

        div.addEventListener('mousedown', function (e) {
            curCol = e.target.parentElement;
            nxtCol = curCol.nextElementSibling;
            pageX = e.pageX;
            var padding = paddingDiff(curCol);
            curColWidth = curCol.offsetWidth - padding;

            if (nxtCol)
                nxtColWidth = nxtCol.offsetWidth - padding;
        });

        //div.addEventListener('mouseover', function (e) {
        //    e.target.style.borderRight = '2px solid #0000ff';
        //})

        //div.addEventListener('mouseout', function (e) {
        //    e.target.style.borderRight = '';
        //})

        document.addEventListener('mousemove', function (e) {
            if (curCol) {
                var diffX = e.pageX - pageX;
                //if (nxtCol)
                //nxtCol.style.width = (nxtColWidth - (diffX)) + 'px';

                curCol.style.width = (curColWidth + diffX) + 'px';
                curCol.style.minWidth = (curColWidth + diffX) + 'px';
            }
        });

        document.addEventListener('mouseup', function (e) {
            curCol = undefined;
            nxtCol = undefined;
            pageX = undefined;
            nxtColWidth = undefined;
            curColWidth = undefined
        });
    }

    function paddingDiff(col) {
        if (getStyleVal(col, 'box-sizing') == 'border-box') {
            return 0;
        }

        var padLeft = getStyleVal(col, 'padding-left');
        var padRight = getStyleVal(col, 'padding-right');
        return (parseInt(padLeft) + parseInt(padRight));
    }

    function getStyleVal(elm, css) {
        return (window.getComputedStyle(elm, null).getPropertyValue(css))
    }
};

export default ProviderBillingHeaderComponent;