import { ChargesBillingAuditLogEpicsFeature } from "../../admin/constants/constants";
import { save_print_export_audit } from "../action/print_export_action";

export interface IPrinExportDto {
    entityTypeId: number|string,
    auditDataEntry?: {},
    viewableDataEntry?: {}
}

export type DataEntry = Record<string, string|boolean|number|object>;

export enum EntityTypeId {
    ARptVer = 10,
    ChargeAudit = 12,
    ChargeReview = 74,
    Charges = 1,
    ChargesAuditSummary = 72,
    Modifiers = 67,
    ProcedureCodeSearch = 68,
    ReleasePendedCharges = 75,
    RevenueCenters = 43,
    PatientStatementListing = 78,
    CrossCompanyPatientSearch = 80,
}

export const DynamicInformationReportAudit= [
    {
        reportGroupName: 'Charges',
        featureFlipName: 'magnusPlat1731BillingCharges',
        reports: [
            {
                reportId: 30120,
                entityReportId: EntityTypeId.ReleasePendedCharges
            },
        ],
    },
    {
        reportGroupName: 'Patient',
        featureFlipName: 'magnusPlat4442PatientUpdateLogs',
        reports: [
            {
                reportId: 30081,
                entityReportId: EntityTypeId.PatientStatementListing
            },
        ],
    },
    {
        reportGroupName: 'ExcludeSensitiveInformationPatients',
        featureFlipName: 'magnusPlat5013PatientEventsExcludingSsn',
        reports: [
            {
                reportId: 326,
                entityReportId: EntityTypeId.CrossCompanyPatientSearch
            },
        ],
    },
];

export const savePrintExportAudit = (reqBody: IPrinExportDto, access_token: any, eventAction: number) => {
    save_print_export_audit(reqBody, access_token, eventAction);
};
