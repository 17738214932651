import { get_date } from '../../shared/calendar_utils';
import { fixSSN, phone_format } from '../../shared/utility';

export const format_subscriber = (subscriber) => {
    const data = [{
        name: 'Name',
        primary: '',
        secondary: '',
        tertiary: ''
    },
    {
        name: 'Insurance Class',
        primary: '',
        secondary: '',
        tertiary: ''
    },
    {
        name: 'Payer Group',
        primary: '',
        secondary: '',
        tertiary: ''
    },
    {
        name: 'Group #',
        primary: '',
        secondary: '',
        tertiary: ''
    },
    {
        name: 'Policy #',
        primary: '',
        secondary: '',
        tertiary: ''
    },
    {
        name: 'Address',
        primary: '',
        secondary: '',
        tertiary: ''
    },
    {
        name: 'Phone',
        primary: '',
        secondary: '',
        tertiary: ''
    },
    {
        name: 'Subscriber',
        primary: '',
        secondary: '',
        tertiary: ''
    },
    {
        name: 'Relationship',
        primary: '',
        secondary: '',
        tertiary: ''
    },
    {
        name: 'Address',
        primary: '',
        secondary: '',
        tertiary: ''
    },
    {
        name: 'DoB',
        primary: '',
        secondary: '',
        tertiary: ''
    },
    {
        name: 'SSN',
        primary: '',
        secondary: '',
        tertiary: ''
    },
    {
        name: 'Gender',
        primary: '',
        secondary: '',
        tertiary: ''
    }
    ];
    if (subscriber && subscriber.length > 0) {
        subscriber.map((value, index) => {
            const key = get_column(index);
            const { insurance, group_number, policy_number, name, relationship, address, date_of_birth, ssn, gender } = value;
            data[0][key] = cell_name(insurance);
            data[1][key] = cell_class(insurance);
            data[2][key] = cell_payer(insurance);
            data[3][key] = group_number || ' ';
            data[4][key] = policy_number || ' ';
            data[5][key] = insurance && get_address(insurance.address) || ' ';
            data[6][key] = insurance && insurance.phone_cust && phone_format(insurance.phone_cust) || ' ';
            data[7][key] = get_subscriber(name);
            data[8][key] = relationship || ' ';
            data[9][key] = get_address(address) || ' ';
            data[10][key] = get_date(date_of_birth, 'MM/DD/YYYY');
            data[11][key] = ssn && fixSSN(ssn) || ' ';
            data[12][key] = gender || ' ';
        });
    }
    return data
}
const get_column = (index) => {
    if (index === 0) {
        return 'primary';
    } else if (index === 1) {
        return 'secondary';
    } else if (index === 2) {
        return 'tertiary';
    }
}

const get_subscriber = (object) => {
    if (object) {
        const { first_name, last_name } = object;
        return `${first_name || ' '} ${last_name || '  '}`;
    }
    return '';
}
const cell_name = (value) => {
    if (value && value.name) {
        return value.name;
    }
    return ' ';
}
const cell_class = (value) => {
    if (value && value.insurance_class && value.insurance_class.name) {
        return value.insurance_class.name;
    }
    return ' ';
}
const cell_payer = (value) => {
    if (value && value.payer_group) {
        return value.payer_group;
    }
    return ' ';
}
const cell_group = (value) => {
    if (value && value.group_number) {
        return value.group_number;
    }
    return ' ';
}
const cell_policy = (value) => {
    if (value && value.policy_number) {
        return value.policy_number;
    }
    return ' ';
}

const get_address = (address) => {
    const { address1, address2, city, state_id, zip } = address;
    return `${address1 || ' '}<br/>${address2 || ' '}<br/>${city || ' '} ${state_id || ' '} ${zip || ' '}`;
}

const get_referring_physician = (referring_physician) => {
    if (referring_physician && referring_physician.name) {
        const { first_name, last_name } = referring_physician.name;
        return `${first_name || ''} ${last_name || ''}`;
    }
    return '';
}

const render_rows = (data) => {
    let nodes = '';
    data.map((value) => {
        const { name, primary, secondary, tertiary } = value;
        nodes = `${nodes}<tr><td>${name}</td><td>${primary}</td><td>${secondary}</td><td>${tertiary}</td><td></td></tr>`;
    })
    return nodes;
}

const details_tamplate = (data) => {
    const { referring_physician, items } = data;
    return ('<div class="case-detail-container">' +
        `<p>Referring Physician: ${get_referring_physician(referring_physician)}</p>` +
        '<div class="table-responsive custom-default-table">' +
        '<table class="ui table">' +
        '<thead>' +
        '<tr id="ss"><th></th><th><b>Primary Insurance</b></th><th><b>Secondary Insurance</b></th><th><b>Tertiary Insurance</b></th></tr>' +
        '</thead>' +
        '<tbody>' +
        `${render_rows(items)}` +
        '</tbody>' +
        '</table>' +
        '</div>' +
        '</div>');
}

function status_cell_renderer(params) {
    var icon = (params.getValue() && params.getValue() === true) ? '<i class="small check icon"></i>' : '';
    var eDiv = document.createElement('div');
    eDiv.innerHTML = `<span>${icon}</span>`;
    return eDiv;
}

export { details_tamplate, status_cell_renderer };

