import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import MaskedInput from 'react-text-mask';
import { bindActionCreators } from 'redux';
import { Button, Dimmer, Form, Grid, Header, Icon, Loader } from 'semantic-ui-react';
import { clearTimeout, setTimeout } from 'timers';
import * as global_constants from '../../global_constants';
import { change_password_required } from '../../login/action/login_action';
import { get_user_data, have_default_access, update_user_data, update_user_password } from '../action/user_action';
import * as Action from './../../shared/action/autosearch_action';
import AutoSearchComponent from './../../shared/component/auto_search_component';
import { SearchModalComponent } from './../../shared/component/search_modal_component';
import { phone_format, unmask } from './../../shared/utility';
import { get_company_list } from './../../user/action/change_company_action';
import { search_company_configuration, search_company_grid_header_id } from './../user_constants';
// Sub Layouts
import PasswordStrengthComponent from './password_strength_component';
import { handle_click_on_enter, set_focus_to_app_header, set_focus_on_element_with_id } from './../../shared/tab_navigation_utility';
import {  get_lauch_darkley_key_value } from '../../shared/utility';
import * as user_constants from '../user_constants';
/*
 1) Manage my account is used to update current user data- First name, last name , email, phone, company
 2) Change User password
 */
class ManageMyAccountComponent extends React.Component<any, any> {
    [x: string]: any;
    is_mounted = false;
    
    constructor(props) {
        super(props);
        this.password_child = React.createRef();
        this.state = {
            user_data: {},
            manage_account_form: {
                first_name: "",
                last_name: "",
                email: "",
                masked_phone: '',
                unmasked_phone: '',
                company_id: '',
                selected_company: null,
                company_input: null
            },
            quick_search_format: {
                "title": '',
                "name": '',
                "label": '',
                "gpms_code": ''
            },
            user_id: this.props.user_login_details.user_data.data.user_id,
            manage_account_form_invalid: {
                first_name: false,
                last_name: false,
                email: false,
                phone: false,
                company: false
            },
            is_edit_enabled: false,
            is_submitted: false,
            is_federated_user: false,
            show_modal: false,
            loading: true,
            has_company_access: false,
            password_data: {
                password_form: {}
            },
        }
    }

    async componentDidMount() {
        this.is_mounted = true;
        if (!document.body.classList.contains('manage-account')) {
            document.body.classList.add('manage-account');
        }
        // setting the focus on edit button for the first time, when component mounts.
        set_focus_on_element_with_id("manage_my_account_edit_btn");

        // Get User Details
        await this.props.get_user_data(this.state.user_id, this.props.user_login_details.user_data.data.token_details.access_token);
        await have_default_access(this.state.user_id, this.props.user_login_details.user_data.data.token_details.access_token).then(response => {
            if (response.data) {
                if (this.is_mounted) {
                    this.setState({ has_company_access: response.data.data });
                }
            }

        });
        if (this.is_mounted) {
            this.setState({
                loading: false,
                manage_account_form: {
                    first_name: this.props.user_details.user_data.data.name.first_name,
                    last_name: this.props.user_details.user_data.data.name.last_name,
                    email: this.props.user_details.user_data.data.email,
                    unmasked_phone: phone_format(this.props.user_details.user_data.data.phone),
                    company_id: this.props.user_details.user_data.data.default_company_id,
                    company_name_format: this.props.user_details.user_data.data.default_company_code == null ? this.props.user_details.user_data.data.default_company_name : this.props.user_details.user_data.data.default_company_code + ' - ' + this.props.user_details.user_data.data.default_company_name,
                    selected_company: this.props.user_details.user_data.data.default_company_code == null ? this.props.user_details.user_data.data.default_company_name : this.props.user_details.user_data.data.default_company_code + ' - ' + this.props.user_details.user_data.data.default_company_name,
                },
                quick_search_format: {
                    "title": `${this.props.user_details.user_data.data.default_company_id}`,
                    "name": this.props.user_details.user_data.data.default_company_name,
                    "label": this.props.user_details.user_data.data.default_company_code == null ? this.props.user_details.user_data.data.default_company_name : this.props.user_details.user_data.data.default_company_code + ' - ' + this.props.user_details.user_data.data.default_company_name,
                    "gpms_code": this.props.user_details.user_data.data.default_company_code
                },
            });
            if(this.props.user_login_details.is_password_expired){
                this.setState({ is_edit_enabled: true });
            }

            if (this.props.ff_magnus_plat1702_disable_password_reset_for_federated_user) {
                this.set_state_is_federated_user(this.props.user_details.user_data.data.is_federated_user ? true : false);
            }
        }
    }
    componentWillUnmount() {
        document.body.classList.remove('manage-account');
        this.is_mounted = false;
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
        }
    }
    // Child -> parent data Interaction
    // Password_change component data on manage_my_account
    on_password_data_change = (password_data) => {
        if (this.is_mounted) {
            this.setState({
                password_data: password_data
            });
        }
    }

    //Change event for input :- first name, last name ...
    on_manage_account_form_change = (e) => {
        var field_name = e.target.name ? e.target.name.replace("txt_", "") : e.target.name;
        var field_value = e.target.value;
        var state = { ...this.state }
        if (field_name == 'phone') {
            state.manage_account_form['masked_phone'] = field_value;
            state.manage_account_form['unmasked_phone'] = unmask(field_value);
            field_value = state.manage_account_form['unmasked_phone'];
        }
        else {
            state.manage_account_form[field_name] = field_value
        }

        var is_valid = this.validate_value(field_name, field_value.trim());
        state.manage_account_form_invalid[field_name] = is_valid;
        if (this.is_mounted) {
            this.setState({
                manage_account_form: state.manage_account_form,
                manage_account_form_invalid: state.manage_account_form_invalid
            });
        }
    }

    // validate each input box on change event
    validate_value(field_name, field_value) {

        switch (field_name) {
            case 'email':
                var email_invalid = field_value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? false : true;
                return (!field_value || email_invalid);
                break;
            case 'first_name':
                return (!field_value);
                break;
            case 'last_name':
                return (!field_value);
                break;
            case 'phone':
                return (!field_value || (field_value && field_value.length > 0 && field_value.length != 10));
                break;
        }
    }

    // validate all values in the form 
    validate_form(data) {


        var manage_account_form_invalid = {
            first_name: !data.first_name,
            last_name: !data.last_name,
            email: !(data.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) || !data.email,
            phone: !unmask(data.unmasked_phone) || (unmask(data.unmasked_phone) && unmask(data.unmasked_phone).length > 0 && unmask(data.unmasked_phone).length != 10),
            company: !(data.company_name_format || data.selected_company)
        }
        if (this.is_mounted) {
            this.setState({
                manage_account_form: data,
                manage_account_form_invalid: manage_account_form_invalid
            });
        }
    }

    // Click function of edit button
    // Enable form editing
    on_manage_account_edit_click = () => {
        if (this.is_mounted) {
            this.setState({ is_edit_enabled: true });
        }
        this.validate_form(this.state.manage_account_form)
    }
    //To update state 
    update_state = () => {
        if (this.is_mounted) {
            this.setState({
                is_edit_enabled: false,
                manage_account_form: {
                    first_name: this.props.user_details.user_data.data.name.first_name,
                    last_name: this.props.user_details.user_data.data.name.last_name,
                    email: this.props.user_details.user_data.data.email,
                    unmasked_phone: phone_format(this.props.user_details.user_data.data.phone),
                    company_name_format: this.props.user_details.user_data.data.default_company_code == null ? this.props.user_details.user_data.data.default_company_name : this.props.user_details.user_data.data.default_company_code + ' - ' + this.props.user_details.user_data.data.default_company_name,
                    selected_company: this.props.user_details.user_data.data.default_company_code == null ? this.props.user_details.user_data.data.default_company_name : this.props.user_details.user_data.data.default_company_code + ' - ' + this.props.user_details.user_data.data.default_company_name,
                    company_id: this.props.user_details.user_data.data.default_company_id,
                },
                quick_search_format: {
                    "title": `${this.props.user_details.user_data.data.default_company_id}`,
                    "name": this.props.user_details.user_data.data.default_company_name,
                    "label": this.props.user_details.user_data.data.default_company_code == null ? this.props.user_details.user_data.data.default_company_name : this.props.user_details.user_data.data.default_company_code + ' - ' + this.props.user_details.user_data.data.default_company_name,
                    "gpms_code": this.props.user_details.user_data.data.default_company_code
                },
                manage_account_form_invalid: {
                    first_name: false,
                    last_name: false,
                    email: false,
                    phone: false,
                    company: false
                },
                is_submitted: false,
                password_data: {
                    password_form: {}
                }

            });
            
            if (this.props.ff_magnus_plat1702_disable_password_reset_for_federated_user) {
                this.set_state_is_federated_user(this.props.user_details.user_data.data.is_federated_user ? true : false);
            }
        }
    }
    // Click function of cancel button
    // Disable form 
    // Put initial values back in the form
    on_manage_account_cancel_click = () => {
        var _this = this;
        var current_state = this.state.manage_account_form;
        current_state.company_name_format = current_state.company_input;
        if (this.is_mounted) {
            this.setState({
                manage_account_form: current_state,
                timeout: setTimeout(() => { this.update_state() }, 50)
            });
        }
    }

    // click function of save button
    on_manage_account_save_click = async () => {

        // validate password data
        var is_password_form_invalid = this.password_child.current.validate_password_form(this.state.password_data.password_form);
        if (this.is_mounted) {
            this.setState({
                is_submitted: true
            });
        }
        // Validate Data
        if (this.state.manage_account_form_invalid.first_name || this.state.manage_account_form_invalid.last_name || this.state.manage_account_form_invalid.email || this.state.manage_account_form_invalid.phone || (this.state.has_company_access ? this.state.manage_account_form_invalid.company : false)
            ||
            ((this.state.password_data.password_form.new_password || this.state.password_data.password_form.old_password || this.state.password_data.password_form.confirm_password) && (!this.state.password_data.password_form.new_password || !this.state.password_data.password_form.old_password || !this.state.password_data.password_form.confirm_password))) {
            toastr.error('', global_constants.constants.mandatory_fields);
        }
        else if (!is_password_form_invalid) {
            if (this.is_mounted) {
                this.setState({
                    loading: true
                });
            }
            // Save Data
            var is_edit_enabled = false;
            //save user data
            var user_data = { ...this.props.user_details.user_data.data }
            var state_data = this.state.manage_account_form;
            state_data.first_name = state_data.first_name.trim();
            state_data.last_name = state_data.last_name.trim();
            state_data.email = state_data.email.trim();
            
            user_data.name.first_name = state_data.first_name;
            user_data.name.last_name = state_data.last_name;
            user_data.phone = unmask(state_data.unmasked_phone);
            user_data.email = state_data.email;
            user_data.default_company_id = state_data.company_id;
            user_data.default_company_code = this.state.quick_search_format.gpms_code;
            user_data.default_company_name = this.state.quick_search_format.name;

            await this.props.update_user_data(this.state.user_id, this.props.user_login_details.user_data.data.token_details.access_token, user_data)

            if (this.state.password_data.password_form.new_password && this.state.password_data.password_form.old_password && this.state.password_data.password_form.confirm_password) {
                var password_data = {
                    old_password: this.state.password_data.password_form.old_password,
                    new_password: this.state.password_data.password_form.new_password,
                    confirm_password: this.state.password_data.password_form.confirm_password,
                    user_id: this.state.user_id
                }

                await this.props.update_user_password(this.props.user_login_details.user_data.data.token_details.access_token, password_data);

                if (this.props.user_details.user_password.messages.length > 0) {
                    if (this.props.user_details.user_password.status == 1) {
                        toastr.success('', this.props.user_details.user_password.messages[0].message);
                        if (this.props.user_login_details.is_password_expired) {
                            this.props.user_login_details.is_password_expired = false;
                            this.props.change_password_required(false);
                            if (this.props.rsiB29536Dashboard) {
                                this.props.history.push('/dashboard');
                            }
                            else {
                                this.props.history.push('/patient_search');
                            }
                        }
                    }
                    else {
                        is_edit_enabled = true;
                        toastr.error('', this.props.user_details.user_password.messages[0].message);
                    }
                }
            }
            if (this.is_mounted) {
                this.setState({
                    loading: false,
                    is_edit_enabled: is_edit_enabled,
                    manage_account_form:state_data
                });
            }

        }
    }

    //******************************* Company Search starts********************//
    // get Company list
    getDataList = async (inputValue, companyid) => {
        return await Action.get_companyList(this.props.user_login_details.user_data.data.token_details.access_token, inputValue.trim(), global_constants.end_points.company_urls.company_lookup);

    }

    prepareSuggestion = (data) => {
        let formattedList = [];
        var data_length = data.data.length;
        if (data && data_length) {
            for (let i = 0; i < data_length; i++) {
                let item = data.data[i];
                formattedList.push({
                    "title": `${item.id}`,
                    "name": item.name,
                    "label": item.gpms_code == '' ? item.name : item.gpms_code + ' - ' + item.name,
                    "gpms_code": item.gpms_code
                })
            }
        }
        return formattedList;
    }

    onSearch = async (obj) => {
        return await get_company_list(this.props.user_login_details.user_data.data.token_details.access_token, obj.company_name, obj.company_code);
    }

    closeCompanyModal = () => {
        this.setState({ show_modal: false });
        set_focus_on_element_with_id("default_company_search_icon");
    }

    onGridRowSelection = (selectedRow) => {
        var state = { ...this.state }
        state.manage_account_form['company_id'] = selectedRow.id;
        state.manage_account_form['company_name_format'] = selectedRow.gpms_code == null ? selectedRow.name : selectedRow.gpms_code + ' - ' + selectedRow.name;
        if (this.is_mounted) {
            this.setState({
                manage_account_form: state.manage_account_form
            });
        }
        this.update_company(selectedRow.id, selectedRow.gpms_code, selectedRow.name);
        this.validateCompany(this.state.manage_account_form.company_name_format, true);
        this.closeCompanyModal();
    }
    update_company = (company_id, gpms_code, name) => {
        var state = { ...this.state }
        state.quick_search_format = {
            title: `${company_id}`,
            label: gpms_code == null ? name : gpms_code + ' - ' + name,
            gpms_code: gpms_code,
            name: name
        }
        if (this.is_mounted) {
            this.setState({
                timeout: setTimeout(() => {
                    if (this.is_mounted) {
                        this.setState({
                            quick_search_format: state.quick_search_format
                        });
                    }
                }, 50)
            });
        }
        //setTimeout(() => {
        //    this.setState({
        //        quick_search_format: state.quick_search_format
        //    })
        //}, 50);
    }

    onClearFilters = () => {

    }

    onselection = (item) => {
        if (item.name) {
            var state = { ...this.state }
            state.manage_account_form['company_id'] = item.title || '';
            state.manage_account_form['selected_company'] = item.label || '';
            if (this.is_mounted) {
                this.setState({

                    manage_account_form: state.manage_account_form
                });
            }
            this.update_company(item.title, item.gpms_code, item.name);
            this.validateCompany(this.state.manage_account_form.company_input, true);
        }
    }
    get_input_value = (input) => {
        var state = { ...this.state }
        state.manage_account_form['company_input'] = input;
        state.manage_account_form['company_name_format'] = state.manage_account_form.company_name_format == null ? '' : state.manage_account_form.company_name_format;
        state.manage_account_form['selected_company'] = state.manage_account_form.selected_company == null ? '' : state.manage_account_form.selected_company;
        if (this.is_mounted) {
            this.setState({
                manage_account_form: state.manage_account_form,

            });
        }
        this.validateCompany(input, false);

    }
    validateCompany = (value, isCompanySelected) => {
        var state = { ...this.state };
        if (value == '') {
            state.manage_account_form_invalid['company'] = true;
        }
        else {
            if (!isCompanySelected) {
                //If input value is equal to the default selected value
                if (value.trim() == this.state.manage_account_form.company_name_format.trim() || value.trim() == this.state.manage_account_form.selected_company.trim()) {
                    state.manage_account_form_invalid['company'] = false;
                }
                else {
                    state.manage_account_form_invalid['company'] = true;
                }
            }
            else {
                state.manage_account_form_invalid['company'] = false;
            }
        }
        if (this.is_mounted) {
            this.setState({
                manage_account_form_invalid: state.manage_account_form_invalid
            });
        }
    }
    renderResult = (props) => {
        return (
            <div key={props.title} tabIndex={0} className="item_auto_search">
                <div key={props.title} className="fs_13">
                    <span>{props.label}</span>
                </div>
            </div>

        )
    }
    clear_quick_search = () => {
        var user_data = this.state.manage_account_form;
        var user_invalid = this.state.manage_account_form_invalid;
        user_data.company_id = "";
        user_invalid.company = true;
        if (this.is_mounted) {
            this.setState({
                quick_search_format: {
                    "title": '',
                    "name": '',
                    "label": '',
                    "gpms_code": ''
                },
                manage_account_form: user_data,
                manage_account_form_invalid: user_invalid
            });
        }
    }
    //******************************* Company Search ends********************//

    // #region Set Is Federated User
    set_state_is_federated_user = (value: boolean) => {
        this.setState({ is_federated_user: value});
    }
    // #endregion

    render() {
        return (
            <>
                <Dimmer active={this.state.loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <div className="common-forms manage-my-account-default-company">
                    <div className="auto-height-scroll" id="scrollable-content-area">
                        <Form id="extra-margin">
                            <Header as='h3' dividing textAlign="left">
                                Manage My Account
                </Header>
                            <Grid.Row className="margin-rt-0">
                                <Grid>
                                    <Grid.Column>
                                        <Grid doubling columns={3} style={{ Padding: '0' }}>
                                            <Grid.Column>
                                                <Form.Field >
                                                    <label>First Name{' '} 
                                                         <span className={this.state.is_submitted && this.state.manage_account_form_invalid.first_name ? 'req-alert' : 'req-alert_normal'}>
                                                            (required)
                                                         </span></label>
                                                    <Form.Input maxLength={50} fluid autoFocus disabled={this.props.user_login_details.is_password_expired || !this.state.is_edit_enabled} autoComplete='new_password' name='txt_first_name' value={this.state.manage_account_form.first_name} onChange={this.on_manage_account_form_change}
                                                       // error={this.state.manage_account_form_invalid.first_name}
                                                        className={this.state.is_submitted && this.state.manage_account_form_invalid.first_name ? 'req-border-inp' : ''}/>
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Form.Field >
                                                    <label>Last Name{' '}
                                                        <span className={this.state.is_submitted && this.state.manage_account_form_invalid.last_name ? 'req-alert' : 'req-alert_normal'}>
                                                        (required)
                                                         </span></label>
                                                    <Form.Input maxLength={50} disabled={this.props.user_login_details.is_password_expired || !this.state.is_edit_enabled} autoComplete='new_password' name='txt_last_name' value={this.state.manage_account_form.last_name} onChange={this.on_manage_account_form_change}
                                                       // error={this.state.manage_account_form_invalid.last_name}
                                                        className={this.state.is_submitted && this.state.manage_account_form_invalid.last_name ? 'req-border-inp' : ''}/>
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column>
                                                {this.state.has_company_access ?
                                                    <Form.Field 
                                                        className={
                                                            this.state.is_submitted && this.state.manage_account_form_invalid.company
                                                                ? 'advance-quick-search  requiredWithBgColor error'
                                                                : 'advance-quick-search '
                                                        }
                                                    >
                                                        <Icon id="default_company_search_icon" onKeyDown={handle_click_on_enter} tabIndex={0} disabled={this.props.user_login_details.is_password_expired || !this.state.is_edit_enabled} className={!this.state.is_edit_enabled ? "searchIcon" : "searchIcon cursor"} aria-hidden="true" onClick={() => { if (this.state.is_edit_enabled) { this.setState({ show_modal: true }) } }} name='search' />

                                                        <label>Default Company{' '}
                                                            <span className={this.state.is_submitted && this.state.manage_account_form_invalid.company ? 'req-alert' : 'req-alert_normal'}>
                                                                (required)
                                                         </span></label>

                                                        <AutoSearchComponent errorClass={''} getInputValue={this.get_input_value} default_value={this.state.quick_search_format} errorMessage={'No Company Found !'}
                                                            prepareRenderList={this.renderResult} getList={this.getDataList} prepareDataList={this.prepareSuggestion} loginDetail={this.props.user_login_details.user_data.data}
                                                            selectresult={this.onselection} is_focus={false} is_disabled={this.props.user_login_details.is_password_expired || !this.state.is_edit_enabled}
                                                            show_clear_search={true} clear_search={() => this.clear_quick_search()} />
                                                    </Form.Field>

                                                    : ''}
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Form.Field>
                                                    <label>Email {' '}
                                                        <span className={this.state.is_submitted && this.state.manage_account_form_invalid.email ? 'req-alert' : 'req-alert_normal'}>
                                                            (required)
                                                         </span></label>
                                                    <Form.Input disabled={this.props.user_login_details.is_password_expired || !this.state.is_edit_enabled}
                                                        autoComplete='new_password' name='txt_email'
                                                        value={this.state.manage_account_form.email}
                                                        onChange={this.on_manage_account_form_change}
                                                        error={this.state.is_submitted &&  this.state.manage_account_form_invalid.email}
                                                        className={this.state.is_submitted && this.state.manage_account_form_invalid.email ? 'req-border-inp' : ''} />
                                                    <div className="requiredText">
                                                        {this.state.is_submitted && this.state.manage_account_form_invalid.email && this.state.manage_account_form.email ? global_constants.constants.valid_email : ''}
                                                    </div>
                                                </Form.Field>
                                            </Grid.Column>
                                          
                                            <Grid.Column>
                                                <Form.Field>
                                                    <label>Phone {' '}
                                                        <span className={this.state.is_submitted && this.state.manage_account_form_invalid.phone ? 'req-alert' : 'req-alert_normal'}>
                                                            (required)
                                                         </span></label>
                                                    <MaskedInput
                                                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                        className={this.state.is_submitted && this.state.manage_account_form_invalid.phone ? 'req-background-inp' : ''}
                                                        placeholder="(___) ___-____"
                                                        guide={true}
                                                        id="my-input-id"
                                                        onBlur={() => { }}
                                                        disabled={this.props.user_login_details.is_password_expired || !this.state.is_edit_enabled}
                                                        name='txt_phone'
                                                        value={this.state.manage_account_form.unmasked_phone}
                                                        onChange={this.on_manage_account_form_change}
                                                        autoComplete='new_password'
                                                        showMask={true}
                                                    />
                                                    <div className="requiredText">
                                                        {unmask(this.state.manage_account_form.unmasked_phone) && unmask(this.state.manage_account_form.unmasked_phone).length != 10 && unmask(this.state.manage_account_form.unmasked_phone).length > 0 ? global_constants.constants.ten_digits_compulsory : ''}
                                                    </div>
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid>

                                    </Grid.Column>
                                </Grid>
                            </Grid.Row>

                            <PasswordStrengthComponent password_data={this.on_password_data_change} is_edit_enabled={this.state.is_edit_enabled && !this.state.is_federated_user} ref={this.password_child} is_submitted={this.state.is_submitted} />
                            <SearchModalComponent onGridRowSelection={this.onGridRowSelection} showModal={this.state.show_modal} modal_config={search_company_configuration} onSearchClick={this.onSearch} onClearClick={this.onClearFilters} onCloseModal={this.closeCompanyModal} gridHeaderNavigation={search_company_grid_header_id}/>
                            <Grid>
                                <Grid.Column align="right">

                                </Grid.Column>
                            </Grid>
                        </Form>
                    </div>
                    <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area" id="applicationFooterSticky">
                        {this.state.is_edit_enabled ?
                            <><Button id="manage_my_account_cancel_btn" basic onClick={this.on_manage_account_cancel_click}>Cancel</Button>
                                <Button className="margin-rt-15" id="manage_my_account_save_btn" onKeyDown={set_focus_to_app_header} type='submit' primary onClick={this.on_manage_account_save_click}>Save</Button></> :
                            <Button className="margin-rt-15" id="manage_my_account_edit_btn" onKeyDown={set_focus_to_app_header} primary onClick={this.on_manage_account_edit_click}>Edit</Button> 

                        }
                    </div>
                </div>

            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        get_user_data: get_user_data,
        update_user_data: update_user_data,
        update_user_password: update_user_password,
        change_password_required: change_password_required
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        user_details: state.user_details,
        shared_details: state.shared_details,
        rsiB29536Dashboard: get_lauch_darkley_key_value(state.launch_darkly, "rsiB29536Dashboard"),
        ff_magnus_plat1702_disable_password_reset_for_federated_user: get_lauch_darkley_key_value(state.launch_darkly, user_constants.federated_user.disablePasswordResetForFederatedUser)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageMyAccountComponent)