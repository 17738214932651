import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Checkbox, Form, Grid } from 'semantic-ui-react';
import * as global_constant from '../../global_constants';
import { handle_click_on_enter } from '../../shared/tab_navigation_utility';
import { set_url_history } from '../../reports/util/report_data_formatter';
import GridView from '../../shared/component/grid';
import * as utility from '../../shared/utility';
import { financial_record_configuration, patient_messages } from '../patient_constants';
import { add_update_patient_flag } from './../action/patient_action';
import { get_patient_letters, get_patient_letter_cases } from './../action/patient_letter_action';
import * as session_storage from '../../shared/session_storage_utility';
import Selection from '../../shared/component/selection_component';
import { get_lauch_darkley_key_value } from '../../shared/utility';

export class FinancialComponent extends React.Component<any, any> {
    _is_mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            shown: false,
            grid_ref: null,
            patient: {
                id: 0,
                company_id: '',
                name: {
                    middle_initial: '',
                    suffix: '',
                    first_name: '',
                    last_name: '',
                    is_name_requried: false,
                    preferred_name: ''

                },
                date_of_birth: null,
                gender: '',
                patient_notes: '',
                address: {
                    address1: '',
                    address2: '',
                    city: '',
                    province: '',
                    zip: '',
                    postal_code: '',
                    state_id: '',
                    country_id: 1,
                    is_required: false
                },
                phone: {

                    home: '',
                    work: '',
                    cell: '',
                    primary: '1'

                },
                is_active: true,
                collection_flag: false,
                payment_plan: false,
                marital_status: '',
                email: '',
                ssn: '',
                driver_license: '',
                spoken_language: 'EN',
                reminder_type: '1',
                alt_record_id: '',
                alt_record_id2: '',
                alt_record_id3: '',
                is_force_save: false,
                is_patient_statement: true,
                case_name: '',
                note_name: '',
                note_code: '',
                total_insurance_pay_amount: '',
                total_ansurance_adj_amount: '',
                total_insurance_balance: '',
                total_patient_pay_amount: '',
                total_patient_adj_amount: '',
                total_patient_balance: '',
                location_id: '',
                billing_provider_id: '',
                ts_discipline: '',
                ts_location_id: '',
                case_effective_date: '',
                responsible_party: {
                    name: {
                        middle_initial: '',
                        Suffix: '',
                        First_name: '',
                        Last_name: '',
                        Is_name_requried: false,
                        Preferred_name: ''

                    },
                    address: {
                        address1: '',
                        address2: '',
                        city: '',
                        province: '',
                        zip: '',
                        postal_code: '',
                        state_id: '',
                        country_id: 1,
                        is_required: false
                    },
                    phone: {

                        home: '',
                        work: '',
                        cell: '',
                        primary: ''

                    },
                    date_of_birth: null,
                    ssn: '',
                    gender: '',
                    email: '',
                    driver_license: '',
                    is_received_90: false,
                    apply_dunning_code: false,
                    marital_status: '',
                    //dunning_code: '',
                    same_as_patient: false,
                    //  number_of_statements_received: '',
                    hsa: ''
                },
                emergency_contact: {
                    name: {
                        middle_initial: '',
                        suffix: '',
                        first_name: '',
                        last_name: '',
                        is_name_requried: false,
                        preferred_name: ''

                    },
                    phone: {

                        home: '',
                        work: '',
                        cell: '',
                        primary: ''

                    },

                }
            },
            patient_letters: [],
            patient_letter_cases: [],
            case_id: -1,
            err_select_letter: false
        }
    }

    // Toggle to show and hide Financial Component
    private toggle = () => {
        if (this._is_mounted) {
            this.setState({
                shown: !this.state.shown
            }, () => {
                if (typeof this.props.toggle != 'undefined') {
                    this.props.toggle(this.state.shown);
                }
            });
        }

    }

    on_add_click(): any {
        this.props.parent_props
    }
    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: global_constant.toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }
    componentDidMount() {
        this._is_mounted = true;
        if (this.props.parent_props.patient_details.selected_patient) {
            var state = { ...this.state }
            state.patient = this.props.parent_props.patient_details.selected_patient.data;
            if (this._is_mounted) {
                this.setState({
                    patient: state.patient
                });
            }
        }
        this.get_patient_letter_cases();
        this.get_patient_letters();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.shown != this.props.shown) {
            if (this._is_mounted) {
                this.setState({ shown: this.props.shown });
            }
        }
        if (this.props.match && this.props.match.params.patient_id !== prevProps.match.params.patient_id) {
            this.get_patient_letter_cases();
        }
    }

    checked_patient_flag = (e, data, name) => {
        var patient_data = { ...this.props.parent_props.patient_details.selected_patient.data }
        var update_collection_flag = false;
        if (name == "collection") {
            patient_data.collection_flag = data.checked;
            update_collection_flag = true;
        }
        if (name == "payment") {
            patient_data.payment_plan = data.checked;
        }
        if (this._is_mounted) {
            this.setState({
                patient: patient_data
            });
        }

        this.update_patient_flag(patient_data, update_collection_flag);
    }

    update_patient_flag = (patient_data, update_collection_flag) => {
        //this.setState({ loading: true });

        add_update_patient_flag(this.props.user_login_details.user_data.data.token_details.access_token, patient_data, patient_data.id)
            .then(async response => {
                if (response.data.status == 1 && response.data.data) {
                    this.props.update_flag(patient_data.collection_flag, update_collection_flag);
                }
                else {
                    //this.setState({ loading: false });
                }
            },
                (error) => {
                    //this.setState({ loading: false });                    

                    if (error.response && error.response.data && error.response.data.messages) {
                        const toastr_options = this.show_html_content_toaster(utility.get_all_error(error.response.data));
                        toastr.error('', toastr_options);
                    }
                })
    }

    // Redirect to selected Financial report
    create_report_button_handler = (report_name, event) => {
        var report_id = 0;
        switch (report_name) {
            case "ledger_full":
                var route = '/patient/report/ledger_full'
                this.props.history.push(`${route}`);
                session_storage.set("url_history", []);
                set_url_history();
                break;
            case "demand_statement":
                var route = '/patient/report/demand_statement'
                this.props.history.push(`${route}`);
                break;
            case "ledger_visit":
                report_id = 30100;
                this.props.history.push(`/patient/report/report_interceptor`, {
                    report_id: report_id
                });
                session_storage.set("url_history", []);
                set_url_history();
                break;
            case "visit_log":
                var report_id = 131;
                this.props.history.push(`/patient/report/report_interceptor`, {
                    report_id: report_id
                });
                session_storage.set("url_history", []);
                set_url_history();
                break;
            case "billing_history":
                report_id = 407;
                this.props.history.push(`/patient/report/report_interceptor`, {
                    report_id: report_id
                });
                session_storage.set("url_history", []);
                set_url_history();
                break;
            case "patient_letters":
                this.patient_letter_preview();
                break;
            default:
        }
        session_storage.set("report_id", report_id);
        event.preventDefault();// Added in case of safari mac book, it reloads the browser.
    }

    //Function calls after click of filter button and recalculate the footer sum.
    on_filter_button_click = () => {
        const { grid_ref } = this.state;
        if (grid_ref && grid_ref.api && grid_ref.api.rowModel.rowsToDisplay.length > 1) {
            grid_ref.api.setPinnedBottomRowData([this.props.rowTotal]);
        } else if (grid_ref && grid_ref.api && grid_ref.api.rowModel.rowsToDisplay.length > 0) {
            const totalRow = { ...grid_ref.api.rowModel.rowsToDisplay[0].data, ...{ name: "Total" } };
            grid_ref.api.setPinnedBottomRowData([totalRow]);
        } else {
            grid_ref.api.setPinnedBottomRowData(null);
        }
    }

    //Function calls on intialization of Ag-Grid and catch its reference.
    get_grid_ref = (grid_params) => {
        if (this._is_mounted) {
            this.setState({ grid_ref: grid_params });
        }
    }

    //Patient Letter Preview- check validation then redirect to preview screen
    patient_letter_preview = () => {
        if (this.state.letter_id) {
            this.props.history.push(`/patient_letters/preview`, {
                patient_id: this.props.match.params.patient_id,
                letter_id: this.state.letter_id,
                letter_name: this.state.letter_name,
                case_id: this.state.case_id
            });
            session_storage.set("url_history", []);
            set_url_history();
        }
    }

    on_change = (event, name) => {
        event.preventDefault();
        if (name === 'select_letter') {
            const selected = this.state.patient_letters.filter(data => {
                return data.value == event.target.value
            });
            if (selected && selected.length > 0) {
                this.setState({
                    letter_id: event.target.value,
                    letter_name: selected[0].text,
                    err_select_letter: false
                });
            }
        } else {
            this.setState({ case_id: event.target.value });
        }
    }

    get_patient_letters = () => {
        get_patient_letters(this.props.user_login_details.user_data.data.token_details.access_token).then(
            response => {
                if (response && response.data && response.data.data) {
                    this.setState({
                        patient_letters: response.data.data.map(item => ({ key: item.key, value: item.key, text: item.value }))
                    })
                }
            },
            error => {
                this.setState({
                    patient_letters: []
                })
            }
        );
    }

    get_patient_letter_cases = () => {
        let token = this.props.user_login_details.user_data.data.token_details.access_token;
        let query_string = `patient_id=${this.props.match.params.patient_id}`
        get_patient_letter_cases(token, query_string).then(
            response => {
                if (response && response.data && response.data.data) {
                    const default_all_case = { key: -1, value: -1, text: 'All Cases' };
                    const options = response.data.data.map(item => ({ key: item.key, value: item.key, text: item.value }));
                    this.setState({
                        patient_letter_cases: [default_all_case, ...options]
                    });
                }
            },
            error => {
                this.setState({
                    patient_letter_cases: []
                })
            }
        );
    }

    componentWillUnmount() {
        this._is_mounted = false;
    }

    render_letter_dropdown = () => {
        return (<div className="letters-container">
            <Selection
                id="id-select-letter"
                placeHolder={'Select Letter'}
                hidden={true}
                defaultValue={''}
                options={this.state.patient_letters}
                onChange={(value, event) => this.on_change(event, 'select_letter')}
                isRequired={this.state.err_select_letter}
                wrapperStyle={'letters-selection-container'}
            />
            <label style={{ minWidth: '20px' }} />
            <Selection
                id="id-select-case"
                placeHolder={null}
                defaultValue={''}
                onChange={(value, event) => this.on_change(event, 'select_case')}
                options={this.state.patient_letter_cases}
                isRequired={this.props.is_submitted && this.state.err_case}
                wrapperStyle={'letters-selection-container'}
            />
        </div>);
    }

    render() {
        var hidden = {
            display: this.state.shown ? "none" : "block"
        }
        return (
            <div className="common-forms-view" id="financial">
                <div className="ui grid">
                    <div className="sixteen wide computer column">
                        <div className="accordion ui fluid styled common-accordion common-accordion-updated">
                            <div className="active title" onClick={this.toggle.bind(this)} >Financial<i tabIndex={0} aria-hidden="true" onKeyDown={handle_click_on_enter}
                                className={!this.state.shown ? "ellipsis angle up icon" : "ellipsis angle down icon"}></i></div>
                            <div className="content active" style={hidden}>
                                <Grid>
                                    <Grid.Column tablet={5} computer={5} textAlign="left">
                                        <h3 className="font-size-16" >Account Summary</h3>
                                    </Grid.Column>
                                    <Grid.Column tablet={11} computer={11} textAlign="right" className="inline-checkboxes">
                                        <Form.Field >
                                            <Checkbox onKeyDown={handle_click_on_enter} label='Payment Plan' checked={this.props.parent_props.patient_details.selected_patient.data.payment_plan || false} onChange={(e, data) => this.checked_patient_flag(e, data, 'payment')} />
                                            <Checkbox onKeyDown={handle_click_on_enter} label='Collection' className="collection-flag" checked={this.props.parent_props.patient_details.selected_patient.data.collection_flag || false} onChange={(e, data) => this.checked_patient_flag(e, data, 'collection')} />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid>
                                <Grid>
                                    <Grid.Column tablet={16} computer={16} className="inline-checkboxes">
                                        {this.props.financial_data && <GridView
                                            id={'financial_grid_id'}
                                            column={financial_record_configuration.column_defs}
                                            row={this.props.financial_data}
                                            gridAutoHeight={true}
                                            get_grid_ref={this.get_grid_ref}
                                            pinnedBottomRowData={[this.props.rowTotal]}
                                            on_filter_button_click={this.on_filter_button_click}
                                        />}
                                    </Grid.Column>
                                </Grid>

                                <Grid>
                                    <Grid.Column tablet={10} computer={10} textAlign="left">
                                        <label>Ledger Full</label>
                                    </Grid.Column>
                                    <Grid.Column tablet={6} computer={6} textAlign="left">
                                        <Button type='button' id="ledger_full" primary onClick={(e) => this.create_report_button_handler('ledger_full', e)} >Create Report</Button>
                                    </Grid.Column>
                                </Grid>
                                <Grid>
                                    <Grid.Column tablet={10} computer={10} textAlign="left">
                                        <label>Ledger Visit</label>
                                    </Grid.Column>
                                    <Grid.Column tablet={6} computer={6} textAlign="left">
                                        <Button type='button' id="ledger_visit" primary onClick={(e) => this.create_report_button_handler('ledger_visit', e)} >Create Report</Button>
                                    </Grid.Column>
                                </Grid>
                                <Grid>
                                    <Grid.Column tablet={10} computer={10} textAlign="left">
                                        <label>Visit Log</label>
                                    </Grid.Column>
                                    <Grid.Column tablet={6} computer={6} textAlign="left">
                                        <Button type='button' id="visit_log" primary onClick={(e) => this.create_report_button_handler('visit_log', e)} >Create Report</Button>
                                    </Grid.Column>
                                </Grid>
                                <Grid>
                                    <Grid.Column tablet={10} computer={10} textAlign="left">
                                        <label>Billing History</label>
                                    </Grid.Column>
                                    <Grid.Column tablet={6} computer={6} textAlign="left">
                                        <Button type='button' id="billing_history" primary onClick={(e) => this.create_report_button_handler('billing_history', e)} >Create Report</Button>
                                    </Grid.Column>
                                </Grid>
                                <Grid>
                                    <Grid.Column tablet={10} computer={10} textAlign="left">
                                        <label>Demand Statement</label>
                                    </Grid.Column>
                                    <Grid.Column tablet={6} computer={6} textAlign="left">
                                        <Button type='button' id="demand_statement" primary onClick={(e) => this.create_report_button_handler('demand_statement', e)} >Create Report</Button>
                                    </Grid.Column>
                                </Grid>
                                <Grid>
                                    <Grid.Column tablet={10} computer={10} textAlign="left">
                                        <div className="form-letters-container">
                                            <label style={{ minWidth: '150px' }}>Form Letters</label>
                                            {this.render_letter_dropdown()}
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column tablet={6} computer={6} textAlign="left">
                                        <Button type='button' id="form_letters" primary onClick={(e) => this.create_report_button_handler('patient_letters', e)} >Create Report</Button>
                                    </Grid.Column>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        selected_patient: state.patient_details.patient_header,
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FinancialComponent))