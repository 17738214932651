import * as React from 'react';
import ErrorCorrectionsComponent from './error_corrections_component';
import ErrorCorrectionsFFComponent from './error_correction_ff_component';

function ErrorCorrectionReRouteComponent(props) {
  const errorCorrectionsFF = props.errorCorrectionsFF;
  if (errorCorrectionsFF) {
    return <ErrorCorrectionsFFComponent />;
  }
  return <ErrorCorrectionsComponent />;
}

export default ErrorCorrectionReRouteComponent;