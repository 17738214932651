import * as React from 'react';
import { Progress } from 'semantic-ui-react';
import { handle_focus_on_shift_tab } from './../../shared/tab_navigation_utility';

class PasswordStrengthComponent extends React.Component<any, any> {
    [x: string]: any;
    UNSAFE_componentWillMount() {
    }
    constructor(props) {
        super(props);
        this.state = { strength: 0, input: '' };
    }
    //Rule to check the password strength 
    strength_rule = (password) => {
        var passwordRating = 0;
        var strength = 0;
        if (/^(?:.*[!@\#$%^&=?_.,:;\\-]+.*)$/.test(password))//Match any special character
        {
            passwordRating += 5;
        }
        if (/^(?=.*[a-z]).+$/.test(password))//Match any lower case character
        {
            passwordRating += 5;
        }
        if (/^(?=.*[A-Z]).+$/.test(password))//Match any upper case character
        {
            passwordRating += 5;
        }
        if (/^(?=.*[0-9]).+$/.test(password))//Match any number
        {
            passwordRating += 5;
        }
        passwordRating += (password.length * 10) <= 80 ? (password.length * 10) : 80;

        strength = passwordRating;
        return strength;

    }
    componentDidUpdate() {
        //this get_password_strength_arguments will pass the selected input and strength to parent component
        //this.props.get_password_strength_arguments(this.state.input, this.state.strength);
    }
    on_change = (event) => {
        this.setState({ input: event.currentTarget.value, strength: this.strength_rule(event.currentTarget.value) });
        this.props.password_data(event.currentTarget.value)
    }
    render() {
        const { strength, input } = this.state;
        return <div className="passwrd-strength">
            <div className="form-group">
                <label>New Password</label>
                <div className="ui input form-control">
                    <input type="password" onKeyDown={(event) => { handle_focus_on_shift_tab(event, "success") }} id="user_password_new_input" className={(this.state.input == '' && this.props.is_submitted) ? "red-error-thin" : ""} onChange={this.on_change} autoFocus/>
            </div>
                {input != '' ? strength < 80 ? <Progress error percent={100} active>Weak Password </Progress> : this.state.strength < 95 ? <Progress warning percent={100} active> Moderate Password</Progress> : <Progress success percent={100} active> Strong Password</Progress> : ''}
            </div>
            </div>
    }
}
export default PasswordStrengthComponent