import * as React from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { Input, Button, Dimmer, Form, Grid, Loader } from 'semantic-ui-react';
import { set_focus_to_app_header } from '../../shared/tab_navigation_utility';
import { print_html_data_to_pdf } from '../../shared/utility';
import ReportHeader from '../../reports/component/report_header';
import * as session_storage from '../../shared/session_storage_utility';
import Selection from '../../shared/component/selection_component';
import * as global_constants from '../../global_constants';
import {
    currencyFormatter, summary_and_forecast_constants, summary_forecast_clinic_stats_configuration } from '../report_constants';
import { get_user_companies_without_user_type_filter } from '../../company/action/company_action';
import {
    prepare_month_options,
    prepare_year_options
} from '../../billing/billing_constants';
import { get_summary_and_forecast, } from '../action/report_action';
import { toastr as toaster } from 'react-redux-toastr';
import { SummaryForecastClinicStatsComponent, clinic_summary_stats, summary_forecast_clinic_stats_print } from '../component/summary_forecast_clinic_stats_component';
import { SummaryForecastCalculateComponent, calculate_criteria, summary_forecast_calculate_print } from '../component/summary_forecast_calculate_component';
import * as ReportConstants from '../../reports/report_constants';
import { generateAuditDataEntryPayload } from '../util/export_print_utility';

// #region Object Declarations
interface ISearchCriteria {
    company_id:number,
    month:number,
    year:number
};

interface IReportObject {
    company_name: string,
    month_name: string,
    year: string,
    data:object


}
// #endregion

export class SummaryAndForecastReportComponent extends React.Component<any, any> {

    // #region class variables
    is_mounted: boolean = false;
    reportId: string = "0";
    // #endregion class variables


    // #region Constructor
    constructor(props)
    {
        super(props);
        let searchObj: ISearchCriteria;
        let reportObj: IReportObject;
        reportObj = {
            company_name: "",
            month_name: "",
            year: "",
            data: null
        };
        searchObj = {
            company_id: -1,
            month: summary_and_forecast_constants.default_month,
            year: moment().year()
        };
        this.state = {
            loading: false,
            search_criteria: searchObj,
            report_data: reportObj,
            user_companies: [],
            is_searched_requested: false
        }
    }
    // #endregion Constructor

    // #region life cycle methods
    async componentDidMount()
    {
        this.is_mounted = true;
        document.body.classList.add('reports');
        
        let session_data = this.load_seach_criteria_from_session();
        if (session_data) {
            this.setState({
                search_criteria: {
                    company_id: session_data.company_id,
                    month: session_data.month,
                    year: session_data.year
                }
            });
            this.generate_summary_and_forecast_report(true);
        }
        await this.get_company_data();
    }

    componentWillUnmount = () => {
        this.is_mounted = false;
        document.body.classList.remove('reports');
    }
    // #endregion Life Cycle methods.

    // #region Data Fetch Methods
    get_transaction_year = () => {
        let currentYear = moment().add(1, 'years').format('YYYY');
        let startYear = moment().subtract(summary_and_forecast_constants.look_back_years, 'years').format('YYYY');
        let year_options = prepare_year_options(startYear, currentYear);
        return year_options;
    }

    get_transaction_month = () => {
        let monthOptions = prepare_month_options();
        return monthOptions;
    }

    get_company_data = async () => {
        if (this.is_mounted == false) return;

        this.setState({ loading: true });
        let userId:number = this.props.user_login_details.user_data.data.user_id;
        let user_default_company_id: number = this.props.user_login_details.user_data.data.company_id;

        await get_user_companies_without_user_type_filter(userId).then(
            (response) => {
                if (response && response.data && response.data.data) {

                    let userCompaniesDisplayData = [];
                    let userData = response.data.data;
                    userData.map((item) => {
                        userCompaniesDisplayData.push({
                            value: item.company_id,
                            text: item.gpms_code == null ? item.company_name : item.gpms_code + " - " + item.company_name
                        });
                    });

                    let searchObjFromState = this.state.search_criteria;
                    searchObjFromState.company_id = this.get_default_company_for_dd(user_default_company_id, userCompaniesDisplayData);
                    this.setState({ user_companies: userCompaniesDisplayData, loading: false, search_criteria: searchObjFromState})
                }

            },
            (error) => {
                this.setState({ user_companies: [], loading: false })
            }
        );
    }

    get_default_company_for_dd = (default_company_id, userCompanies) => {

        if (userCompanies == undefined || userCompanies.length == 0) return -1;

        let dd_default_company_id;

        let is_user_default_company_listed = false;

        userCompanies.map((item) => {
            if (item.value == default_company_id) is_user_default_company_listed = true;
        });

        dd_default_company_id = is_user_default_company_listed ? default_company_id : userCompanies[0].value;

        return dd_default_company_id;
    } 

    load_seach_criteria_from_session = () =>  {
        let search = null;
        let search_criteria_from_session = session_storage.get(summary_and_forecast_constants.session_key);
        if (search_criteria_from_session && search_criteria_from_session.search_criteria) {
            return search_criteria_from_session.search_criteria;
        }
        return search;
    }

    // #endregion Data Fetch Methods

    // #region action handlers
    clear_handler = () => {
        if (this.is_mounted == false) return;

        session_storage.remove(summary_and_forecast_constants.session_key);
        let searchObjFromState = this.state.search_criteria
        let user_default_company_id: number = this.props.user_login_details.user_data.data.company_id;
        let dd_default_company_id: number = this.get_default_company_for_dd(user_default_company_id, this.state.user_companies);

        searchObjFromState.company_id = dd_default_company_id;
        searchObjFromState.month = summary_and_forecast_constants.default_month;
        searchObjFromState.year = moment().year();

        let report_object = this.get_report_object_with_data_and_criteria(null, searchObjFromState);           
        this.setState({ search_criteria: searchObjFromState, report_data: report_object, is_searched_requested: false});
    }

    generate_summary_and_forecast_report = (read_from_session=false) => {
        
        if (this.is_mounted == false) return;
        this.setState({ report_data: null, is_searched_requested: true, loading: true });

        let searchObj = null

        if (read_from_session == true) searchObj = this.load_seach_criteria_from_session();
        else searchObj = this.state.search_criteria;

        if (!searchObj ||
            (searchObj.company_id == -1)) {
            this.setState({ loading: false });
            return;
        }

        session_storage.set(summary_and_forecast_constants.session_key, {
            search_criteria: searchObj
        });

        let token = this.props.user_login_details.user_data.data.token_details.access_token;
        let param = {
            company_id: searchObj.company_id,
            month: searchObj.month,
            year: searchObj.year
        };

        get_summary_and_forecast(token, param).then(
            
            (response) => {
                if (response && response.data) {
                    if (response.data.status == 1) {
                        let report_object = this.get_report_object_with_data_and_criteria(response.data.data, param);           
                        this.setState({ report_data: report_object, loading: false, is_searched_requested: false });
                        
                    }
                    else {
                        let report_object = this.get_report_object_with_data_and_criteria(null, param);           
                        this.setState({ report_data: report_object, loading: false, is_searched_requested: false });
                        this.show_toaster_error(response.data.messages);
                    }
                }
                return;
            },
            (error) => {
                let report_object = this.get_report_object_with_data_and_criteria(null, param);           
                this.setState({ report_data: report_object, loading: false, is_searched_requested: false});
                return;
            }
        );
        return;
    }

    print_handler = () => {
        if (this.is_mounted == false) return;
        var printPopup = this.print_page();
        print_html_data_to_pdf(printPopup, 'printButton', summary_and_forecast_constants.title);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
    }

    // Print Page
    print_page = () => {
        var print_html_data: any = "";
        var clinic_summary_stats_data = document.getElementById('clinic_summary_stats_data') as HTMLInputElement;
        var calculate_result_data = document.getElementById('calculate_result_data') as HTMLInputElement;
        print_html_data += `<div style='font-weight: bold;font-size:16px'>${summary_and_forecast_constants.title}</div>`;
        print_html_data += `<div style='font-weight: bold;font-size:16px'>${this.state.report_data.company_name}</div>`;
        print_html_data += `<div style='font-weight: bold;font-size:16px'>Month: ${this.state.report_data.month_name}</div>`;
        print_html_data += `<div style='font-weight: bold;font-size:16px'>Year: ${this.state.report_data.year}</div>`;
        print_html_data += `<br/>`;
        print_html_data += `<div class="default-table">`;
        print_html_data += summary_forecast_clinic_stats_print(JSON.parse(clinic_summary_stats_data.value));
        if (calculate_result_data != null) {
            print_html_data += `<br/>`;
            print_html_data += summary_forecast_calculate_print(JSON.parse(calculate_result_data.value));
        }
        print_html_data += `</div>`;
        return print_html_data;
    }

    get_report_object_with_data_and_criteria = (response_data, criteria) =>  {

        let company_object = this.state.user_companies.filter((item) => {
            if (item.value == criteria.company_id) return item;
        })

        let reportObj: IReportObject;
        reportObj = {
            company_name: company_object[0] == undefined ? '' : company_object[0].text,
            month_name: this.get_transaction_month().filter(obj => { return obj.value === criteria.month })[0].text,
            year:criteria.year,
            data:response_data
        };
        
        return reportObj;
    }

    on_criteria_change = (field_identifier, value) => {
        if (this.is_mounted == false) return;

        let searchObjFromState = this.state.search_criteria;
        if (field_identifier == summary_and_forecast_constants.search_field.company) {
            searchObjFromState.company_id = parseInt(value);
        }
        else if (field_identifier == summary_and_forecast_constants.search_field.month) {
            searchObjFromState.month = parseInt(value);
        }
        else if (field_identifier == summary_and_forecast_constants.search_field.year){
            searchObjFromState.year = parseInt(value);
        }
        this.setState({ search_criteria: searchObjFromState});
    }
    // #endregion actions handlers

    // #region Validation
    mark_field_for_validation = (field_identifier) =>
    {
        if (this.is_mounted == false) return false;

        if (this.state.is_searched_requested == false) return false;

        let isrequired: boolean = false;
        let searchObjFromState = this.state.search_criteria;

        if (field_identifier == summary_and_forecast_constants.search_field.company) {
            
            if (searchObjFromState.company_id == undefined || 
                searchObjFromState.company_id == -1 ||
                searchObjFromState.company_id == null) isrequired = true;
        }
        else if (field_identifier == summary_and_forecast_constants.search_field.month) {
            if (searchObjFromState.month == undefined ||
                searchObjFromState.month == null) isrequired = true;

        }
        else if (field_identifier == summary_and_forecast_constants.search_field.year) {
            if (searchObjFromState.year == undefined ||
                searchObjFromState.year == null) isrequired = true;
        }
        return isrequired
    }

    show_toaster_error = (messages) => {        
        if (messages && messages.length > 0) {
            var toaster_message = "";
            messages.forEach((element) => {
                if (toaster_message != "")
                    toaster_message = toaster_message + "<br>" + element.message;
                else
                    toaster_message = element.message;
            });
            if (toaster_message.indexOf('<br') > -1) {
                var toastr_options = this.show_html_content_toaster(toaster_message);
                toaster.error('', toastr_options);
            } else {
                toaster.error('', toaster_message);
            }

        }
    }

    // Show multiple messages
    show_html_content_toaster = msg => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ),
            timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        };
    };
    // #endregion Validation


    // #region Enable/Disable
    is_report_data_available = () => {
        if (this.is_mounted == false) return false;
        return(this.state.report_data != undefined && this.state.report_data.data != null);
    }
    // #endregion

    // Function calls save billing audit on Export or Print
    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;

        const payload: ReportConstants.IPayloadForBillingAudit = {
            reportId: this.reportId,
            contextTitle: `${summary_and_forecast_constants.title} - ${ReportConstants.ReportEventAction[eventActionId]}`,
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: ReportConstants.ReportEntityTypeId.a_rpt_ver,
            data: { SearchCriteria: this.state.search_criteria }
        }

        const reqBody = generateAuditDataEntryPayload(payload);

        ReportConstants.saveAuditPrintExport(reqBody, accessToken);
    };
    
    render()
    {
        return (
            <div className={'common-forms reset835_eob auto-height-scroll'}>
                <Dimmer active={this.state.loading}>
                    <Loader size="massive">Loading</Loader>
                </Dimmer>
                <div className={'common-forms-search report-framework'}>
                    <ReportHeader title={summary_and_forecast_constants.title} />
                    <div id="report-scrollable-area">
                        <div className="report-wrapper"
                            style={this.is_report_data_available() ? { flex: 1 } : {}}>
                            <div
                                className="patient-search-form  patient_search_bottom_padding"
                                id="report-criteria-container"
                            >
                                <Form autoComplete="off">
                                    <Grid>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Clinic</label>
                                                <Selection
                                                    id='company_dd_id'
                                                    name='company_dd'
                                                    options={this.state.user_companies}
                                                    onChange={(value) => {
                                                        this.on_criteria_change(summary_and_forecast_constants.search_field.company, value);
                                                    }}
                                                    defaultValue={this.state.search_criteria.company_id}
                                                    isRequired={this.mark_field_for_validation(summary_and_forecast_constants.search_field.company)}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={4} computer={4}>
                                            <Form.Field>
                                                <label>
                                                    <span>Transaction Month</span>
                                                </label>
                                                <Selection
                                                    id='transaction_month_dd_id'
                                                    name='transaction_month_dd'
                                                    options={this.get_transaction_month()}
                                                    onChange={(value) => {
                                                        this.on_criteria_change(summary_and_forecast_constants.search_field.month, value);
                                                    }}
                                                    defaultValue={this.state.search_criteria.month}
                                                    isRequired={this.mark_field_for_validation(summary_and_forecast_constants.search_field.month)}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={4} computer={4}>
                                            <Form.Field>
                                                <label>
                                                    <span>Transaction Year</span>
                                                </label>
                                                <Selection
                                                    id='transaction_year_dd_id'
                                                    name='transaction_year_dd'
                                                    options={this.get_transaction_year()}
                                                    onChange={(value) => {
                                                        this.on_criteria_change(summary_and_forecast_constants.search_field.year, value);
                                                    }}
                                                    defaultValue={this.state.search_criteria.year}
                                                    isRequired={this.mark_field_for_validation(summary_and_forecast_constants.search_field.year)}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid>
                                        <Grid.Column tablet={16} computer={16} textAlign="right">
                                            <Button
                                                id="clear_button"
                                                basic
                                                onClick={() => this.clear_handler()}
                                            >
                                                Clear
                                        </Button>
                                            <Button
                                                id="search_button"
                                                className="primary"
                                                onClick={() => this.generate_summary_and_forecast_report()}
                                                type="submit"
                                                style={{ marginRight: '0' }}
                                            >
                                                Search
                                        </Button>
                                        </Grid.Column>
                                    </Grid>
                                </Form>
                            </div>
                        </div>
                        <div style={this.is_report_data_available() ? { flex: 1 } : {}}>
                            {this.render_clinic_summary()}
                        </div>
                        <div style={this.is_report_data_available() ? { flex: 1 } : {}}>
                            {this.render_calc()}
                        </div>
                        <div
                            className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area"
                            id="applicationFooterSticky"
                        >
                            <Grid.Column computer={16} tablet={16} textAlign="right">
                                <Button
                                    id="printButton"
                                    type="submit"
                                    onClick={() => this.print_handler()}
                                    onKeyDown={set_focus_to_app_header}
                                    primary
                                    disabled={!this.is_report_data_available()}
                                >
                                    Print
                            </Button>
                            </Grid.Column>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render_clinic_summary = () => {
        if (this.is_report_data_available()) {
            let report_data = this.normalize_report_data();
            return (<SummaryForecastClinicStatsComponent clinic_summary_stats={({
                new_patients: report_data.patients,
                actual_collections_pct: report_data.actualCollectionPercentage,
                visits: report_data.visits,
                est_payments: report_data.estPayments,
                charges: report_data.charges,
                est_payments_per_visit: report_data.estPaymentsPerVisit,
                charges_per_visit: report_data.chargesPerVisit
            })} />);
        }
    }

    render_calc = () => {
        if (this.is_report_data_available()) {
            let report_data = this.normalize_report_data();
            return (<SummaryForecastCalculateComponent calculate_criteria={({
                new_patients: report_data.patients,
                actual_collections_pct: report_data.actualCollectionPercentage,
                visits: report_data.visits,
                est_payments: report_data.estPayments,
                charges: report_data.charges,
                est_payments_per_visit: report_data.estPaymentsPerVisit,
                charges_per_visit: report_data.chargesPerVisit,
                total_expenses: 0
            })} />);
        }
    }

    normalize_report_data = () => {
        return {
            patients: this.state.report_data.data.patients == null ? 0 : this.state.report_data.data.patients,
            actualCollectionPercentage: this.state.report_data.data.actualCollectionPercentage == null ? 0 : this.state.report_data.data.actualCollectionPercentage,
            visits: this.state.report_data.data.visits == null ? 0 : this.state.report_data.data.visits,
            estPayments: this.state.report_data.data.estPayments == null ? 0 : this.state.report_data.data.estPayments,
            charges: this.state.report_data.data.charges == null ? 0 : this.state.report_data.data.charges,
            estPaymentsPerVisit: this.state.report_data.data.estPaymentsPerVisit == null ? 0 : this.state.report_data.data.estPaymentsPerVisit,
            chargesPerVisit: this.state.report_data.data.chargesPerVisit == null ? 0 : this.state.report_data.data.chargesPerVisit,
        };
    }
}

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
        selected_patient: state.patient_details.patient_header,
        is_expand: state.shared_details.is_expand,
    };
};

export default connect(mapStateToProps)(SummaryAndForecastReportComponent);
