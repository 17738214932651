import { currency_value, format_number } from './utils';
import { MONTH_NAMES } from './constants';

function mm_dd_yyyy_number(date) {
    if (date === undefined || date === null || date.length < 10) {
        return null;
    }
    let month = date.substring(0, 2);
    let day = date.substring(3, 5);
    let year = date.substring(6, 10);
    return year * 10000 + month * 100 + day;
}

function h_mm_ss_tt_number(date) {
    if (date === undefined || date === null || date.length < 20) {
        return null;
    }

    let hour: number = 0;
    let mimute: number = 0;
    let second: number = 0;
    const time_value = date.substring(11);
    const items = time_value.split(" ");
    if (items && items.length > 0) {
        items.map((item, index) => {
            if (index == 0) {
                const times = item.split(":");
                if (times && times.length > 0) {
                    times.map((value, position) => {
                        if (position === 0) {
                            hour = hour + value;
                        } else if (position === 1) {
                            mimute = mimute + value;
                        } else if (position === 2) {
                            second = second + value;
                        }
                    })
                }
            } else if (index == 1) {
                hour = hour + ((item && item == 'PM') ? 12 : 0);
            }
        })
    }
    return hour * 10000 + mimute * 100 + second;
}

function mm_dd_yyyy_comparator(date1, date2) {
    var date1Number = mm_dd_yyyy_number(date1);
    var date2Number = mm_dd_yyyy_number(date2);
    if (date1Number === null && date2Number === null) {
        return 0;
    }
    if (date1Number === null) {
        return -1;
    }
    if (date2Number === null) {
        return 1;
    }
    return date1Number - date2Number;
}

function mm_dd_yyyy_h_mm_ss_tt_comparator(date1, date2) {
    var date1Number = mm_dd_yyyy_number(date1);
    var date2Number = mm_dd_yyyy_number(date2);
    if (date1Number === null && date2Number === null) {
        return 0;
    }
    if (date1Number === null) {
        return -1;
    }
    if (date2Number === null) {
        return 1;
    }
    if (date1Number != date2Number) {
        return date1Number - date2Number;
    } else {
        var time1Number = h_mm_ss_tt_number(date1);
        var time2Number = h_mm_ss_tt_number(date2);
        if (time1Number === null && time2Number === null) {
            return 0;
        }
        if (time1Number === null) {
            return -1;
        }
        if (time2Number === null) {
            return 1;
        }
        return time1Number - time2Number;
    }
}

//Grid number sort in the column and filter
function number_comparator(num1, num2) {
    if (num1 == null || num1 == NaN) {
      if (num2 == null || num2 == NaN) {
        return 0;
      }
      return -1;
    }

    if (num2 == null || num2 == NaN) {
      return 1;
    }
    num1 = format_number(num1);
    num2 = format_number(num2);
    return num1 - num2;
}

//Grid text sort in the column and filter
function text_comparator(text1, text2) {
    if (text1 == null || text1 == undefined || text1 == 'undefined') {
        if (text2 == null || text2 == undefined || text1 == 'undefined') {
            return 0;
        }
        return -1;
    }

    if (text2 == null || text2 == undefined || text1 == 'undefined') {
        return 1;
    }

    const value1 = text1.toString().toLowerCase().trim();
    const value2 = text2.toString().toLowerCase().trim();
    if (value1 < value2) {
        return -1;
    }
    if (value1 > value2) {
        return 1;
    }
    return 0;
}

//Grid currency sort in the column and filter
function currency_comparator(currency1, currency2) {
    if (currency1 == null || currency1 == undefined) {
        if (currency2 == null || currency2 == undefined) {
            return 0;
        }
        return -1;
    }

    if (currency2 == null || currency2 == undefined) {
        return 1;
    }

    const value1 = Number(currency_value(currency1));
    const value2 = Number(currency_value(currency2));
    if (value1 < value2) {
        return -1;
    }
    if (value1 > value2) {
        return 1;
    }
    return 0;
}

//Grid link value sort in the column and filter
function link_number_comparator(value1, value2) {
    const num1 = Number(value1);
    const num2 = Number(value2);
    if (num1 < num2) {
        return -1;
    }
    if (num1 > num2) {
        return 1;
    }
    return 0;
}

//Grid month name sort in the column and filter
function month_name_comparator(month1, month2) {
    if (month1 == null || month1 == undefined) {
        if (month2 == null || month2 == undefined) {
            return 0;
        }
        return -1;
    }

    if (month2 == null || month2 == undefined) {
        return 1;
    }

    const value1 = MONTH_NAMES.indexOf(month1.toString().trim());
    const value2 = MONTH_NAMES.indexOf(month2.toString().trim());
    return value1 - value2;
}

export {
    mm_dd_yyyy_comparator,
    mm_dd_yyyy_h_mm_ss_tt_comparator,
    number_comparator,
    text_comparator,
    currency_comparator,
    link_number_comparator,
    month_name_comparator
};

