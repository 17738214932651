import { text_comparator } from '../shared/component/grid/comparators';
import { HEADER_TEMPLATE_FIRST_COLUMN, get_header_template_with_navigation } from '../shared/component/grid/constants';

export const dx_type = {
    none: 0,
    initialLoad: 1,
    note: 2,
    r3_case: 3,
    r2_case: 4,
    charge: 5,
    r5_case: 3,
    charge_capture: 7
}

export const diagnosis_search_header_id = "diagnosis_search_header_id";

const headerTemplate = {
    template: '<div class="ag-cell-label-container" role="presentation">' +
        '<i class="small filter icon filter-ipad filter-active-icon"></i>' +
        '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '<div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '</div>' +
        '</div>'
};

export const search_diagnosis_code_configuration = (id_for_navigation) => {
    return {
        pagination_size: 20,
        column_defs: [
            {
                headerName: "Diagnosis Code",
                field: "code",
                filter: "textFilter",
                comparator: text_comparator,
                headerComponentParams: get_header_template_with_navigation(id_for_navigation),
                unSortIcon: true,
                minWidth: 200,
                suppressSizeToFit: true,
            },
            {
                headerName: "Diagnosis Description",
                unSortIcon: true,
                headerComponentParams: headerTemplate,
                field: "description",
                filter: "textFilter",
                comparator: text_comparator,
                minWidth: 400,
                //cellClass: "cell-wrap-text",
                //autoHeight: true,
            }
        ]

    }
};

export const error_messages = {
    dulicate_dx_code: 'There cannot be Duplicate Diagnosis Codes',
    dc_code_checkbox_select:'Select an ICD-10 code to delete.'
}