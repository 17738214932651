
export class RequestConfig {

    /**
     * creating an config of the reqest parameter
     * EX.
     *  let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

        or we use use this as a builder pattern to create a request config.
     * 
     * */
    // config object
    config: object = {};
   
    constructor(config: object) {
        this.config = config
    }

    add_url(url) {
        this.config['url'] = url
    }

    add_headers(headers) {
        this.config['headers'] = headers
    }

    add_params(params) {
        this.config['params'] = params
    }

    add_data(data) {
        this.config['data'] = data
    }
    add_payload(payload) {
        this.config['url'] = payload
    }

    get(): any {

        return this.config
    }
} 
