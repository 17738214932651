import { IValidator } from "./IValidator";
import { addPatientInitialConfiguration, patientInvalidFieldMessage } from '../patient_constants';

export class MinLengthValidator implements IValidator {
    private errorMessages = new Array<string>();

    isValid(data: any): boolean {
        const hasValidLength = data.length >= addPatientInitialConfiguration.nameMinLength;

        this.errorMessages = [];
        if (!hasValidLength) {
            this.errorMessages.push(patientInvalidFieldMessage.invalidMinimumCharacters);
        }
        return hasValidLength;
    }

    getErrorMessages(): string[] {
        return this.errorMessages;
    }
}
