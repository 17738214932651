import * as React from 'react';
import { toastr } from 'react-redux-toastr';
import { Button, Dimmer, Grid, Loader } from 'semantic-ui-react';
import * as global_constants from '../../../app/global_constants';
import { get_access_token } from '../../shared/utility';
import { get_print_case_verification_from } from '../action/case_action';
import { handle_click_on_enter } from '../../shared/tab_navigation_utility';

class VerificationFormComponent extends React.PureComponent<any, any> {
    [x: string]: any;
    _is_mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            shown: true,
            loading: false,
            showModal: false
        };
    }
    pdfFileUrl;
    componentDidMount = () => {
        this._is_mounted = true;
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.shown != this.props.shown && this._is_mounted) {
            this.setState({ shown: false }); // show on change props
        }
    }

    // toggle to show and hide Cases Component
    private toggle = () => {
        if (this._is_mounted) {
            this.setState({
                shown: !this.state.shown
            });
        }
    };

    //Get Print Case Verification From
    print_case_verification_from = is_extended => {
        if (this._is_mounted) {
            this.setState({
                loading: true
            });
        }
        get_print_case_verification_from(
            get_access_token,
            this.props.patient_id,
            this.props.case_id,
            is_extended
        ).then(
            response => {
                this.generate_pdf(response.data.data);
            },
            error => {
                if (this._is_mounted) {
                    this.setState({
                        loading: false
                    });
                }
                toastr.error('', global_constants.global_messages.pdf_generation_error);
                return false;
            }
        );
    };
    generate_pdf = data => {
        const pdf_file = new Blob([data], {
            type: 'application/pdf'
        });
        if (pdf_file.size <= 0) {
            toastr.error('', global_constants.global_messages.pdf_generation_error);
            if (this._is_mounted) {
                this.setState({
                    loading: false
                });
            }
            return false;
        } else {
            const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
            const pdf_content = window.URL.createObjectURL(pdf_file);
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                if (this._is_mounted) {
                    this.setState({
                        loading: false
                    });
                }
                window.navigator.msSaveOrOpenBlob(pdf_file);
            } else {
                var win = open(pdf_content, 'insurance', '');

                if (!win) {
                    toastr.error('', global_constants.global_messages.popup_blocked);
                } else {
                    win.focus();
                    win.onload = function () {
                        win.print();
                    };
                }

                if (this._is_mounted) {
                    this.setState({
                        loading: false
                    });
                }
            }
        }
    };
    componentWillUnmount = () => {
        this._is_mounted = false;
    }
    render() {
        var hidden = {
            display: this.state.shown ? 'none' : 'block'
        };
        return (
            <Grid.Column mobile={16} tablet={16} computer={16} id='verification_form'>
                <div className='common-forms-view'>
                    <div className='ui grid'>
                        <div className='sixteen wide computer column'>
                            <div className='accordion ui fluid styled common-accordion'>
                                <div className='active title'  onClick={this.toggle.bind(this)}>
                                    Verification Forms
                                    <i tabIndex={0} onKeyDown={handle_click_on_enter} 
                                        aria-hidden='true'
                                        className={
                                            this.state.shown
                                                ? 'angle down styled-accordion-icon horizontal icon '
                                                : 'angle up styled-accordion-icon horizontal icon '
                                        }
                                    />
                                </div>
                                <div className='content active' style={hidden}>
                                    <Dimmer active={this.state.loading}>
                                        <Loader size='medium'>Loading</Loader>
                                    </Dimmer>
                                    <Grid.Column className='text-right mar-t-15 mar-t-15'>
                                        <Button
                                            type='button'
                                            basic
                                            onClick={() => this.print_case_verification_from(false)}
                                        >
                                            Print Insurance Verification
                    </Button>
                                        <Button
                                            type='button'
                                            primary
                                            onClick={() => this.print_case_verification_from(true)}
                                        >
                                            Print Extended Insurance Verification
                    </Button>
                                    </Grid.Column>
                                    {/* <Grid.Column className="textCenter">Coming Soon</Grid.Column> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Grid.Column>
        );
    }
}

export default VerificationFormComponent;
