import * as global_constants from '../../global_constants';
import { log_error } from '../../shared/action/shared_action';
import * as patient_constants from '../patient_constants';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request';

export const get_patients_header = (patient_id, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${global_constants.end_points.patient_urls.header}`;

    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return dispatch =>
        request.make(request_config).then(
            response => dispatch({
                type: patient_constants.PATIENT_HEADER,
                payload: response || {}
            }),
            error => {
                log_error(error)
            }
        );
}

// Get Patient Header selected case details 
export const get_patient_header_case_info = (patient_id, case_id, token) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${global_constants.end_points.patient_urls.header}${global_constants.end_points.patient_urls.header_case}/${case_id}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return dispatch =>
        request.make(request_config).then(
            response => dispatch({
                type: patient_constants.PATIENT_HEADER_CASE,
                payload: response
            }),
            error => {
                log_error(error)
            }
        );
}

// Get doctors order 
export const get_doctors_order_info = (from_date, thru_date, case_id, token) => {
    
    var doctorsOrderVisitparam = {'case_id':'', 'from_date': '', 'thru_date':''};
    if (case_id != undefined) {   
        doctorsOrderVisitparam.case_id = case_id;
        if (from_date != null && thru_date != null) {
            doctorsOrderVisitparam.from_date = from_date;
            doctorsOrderVisitparam.thru_date = thru_date;
        } else if (from_date == null && thru_date != null) {
            doctorsOrderVisitparam.thru_date = thru_date;
        }
        else if (from_date != null && thru_date == null) {
            doctorsOrderVisitparam.from_date = from_date;
        }
    }
    const url = `${global_constants.base_end_points}${global_constants.end_points.subscriber_url.subscriber}${global_constants.end_points.subscriber_url.doctors_orders_visit}`;

    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': doctorsOrderVisitparam
    })
    return dispatch =>
        request.make(request_config).then(
            response => dispatch({
                type: patient_constants.DOCTOR_ORDER,
                payload: response
            }),
            error => {
                log_error(error)
            }
        );
}

// update_collection_flag using store
export const update_collection_flag = (value) => {

    return dispatch => dispatch({
        type: patient_constants.PATIENT_COLLECTION_FLAG,
        payload: value
    })

}


// update_collection_flag using store
export const update_patient_header_info = (patient_header_reload, selected_patient_header_case_id, case_reload) => {
    return dispatch => dispatch({
        type: patient_constants.UPDATE_PATIENT_HEADER_INFO,
        payload: {
            patient_header_reload: patient_header_reload,
            selected_patient_header_case_id: selected_patient_header_case_id,
            selcted_case_reload: case_reload
        }
    })

}

// get_print_receipts

export const get_print_receipts = (patient_id ,current_date,token) => {
    const url = `${global_constants.base_end_points}${ global_constants.end_points.print_receipt.payment}/${patient_id}${global_constants.end_points.print_receipt.get_print_reciept}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify({ clientCurrentDate: current_date })
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            clientCurrentDate: current_date
        }
    })
    return request.make(request_config)
}

// get company logo
export const get_company_logo = (company_id,  token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.print_receipt.get_company_logo}/${company_id}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
    })
    return request.make(request_config)
}

// get print patient reciept logo
export const get_print_reciept_logo = (company_id, location_id, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.print_receipt.print_patient_logo}/${company_id}/${location_id}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
    })
    return request.make(request_config)
}