import * as React from 'react';
import { Grid } from 'semantic-ui-react';
import { Tab, Tabs } from '@blueprintjs/core';
import { roles_permission_set_data } from '../../constants';
import GridView from '../../../../shared/component/grid';
import { get_columns } from '../../../../shared/utility';
import { DIMEN } from '../../../../shared/component/grid/constants';
/**
 * Responsible to retrieve Extended Component
 **/
interface IProps {
  drawer_type: string;
  extendedTabId: string;
  data: { e_ps_list: any[]; e_role_list: any[]; permission_list: any[] };
}

export default class ExtendedPermissionSetRolesViewComponent extends React.PureComponent<IProps, any> {
  is_mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      grid_show: false,
      controls: roles_permission_set_data[this.props.drawer_type],
      data: {
        e_ps_list: this.props.data.e_ps_list || [],
        e_role_list: this.props.data.e_role_list || [],
        permission_list: this.props.data.permission_list || []
      },
      grid_params: {},
      selectedTab: this.props.extendedTabId || 'extended_ps_permission_id',
      col_def:
        this.props.extendedTabId === 'extended_ps_permission_id'
          ? this.props.drawer_type === 'permission_sets'
            ? roles_permission_set_data.extended_table_controls.permission_sets
            : roles_permission_set_data.extended_table_controls.permissions
          : roles_permission_set_data.extended_table_controls.roles
    };
  }
  total_column_width: number = 0;
  grid_header_height: number = 0;

  componentDidMount = () => {
    this.is_mounted = true;
    this.calculate_grid_width();
  };

  componentWillUnmount = () => {
    this.is_mounted = false;
  };

  calculate_grid_width = () => {
    let rows =
      this.state.selectedTab === 'extended_ps_permission_id'
        ? this.props.drawer_type === 'permission_sets'
          ? this.state.data.e_ps_list
          : this.state.data.permission_list
        : this.state.data.e_role_list;
    const grid_height = get_columns(rows, this.state.col_def);
    this.total_column_width = grid_height.total_column_width;
    this.grid_header_height = grid_height.header_height;
    if (this.is_mounted) {
      this.setState({
        grid_show: true
      });
    }
  };

  get_grid_ref = (grid_params) => {
    let col_def = this.state.col_def;
    this.setState({
      grid_params
    });
    let grid_width = document.getElementsByClassName('panel_row')[0].clientWidth;
    if (this.total_column_width > grid_width - 100) {
      col_def.filter((item) => {
        grid_params.columnApi.setColumnWidth(item.field, item.width, false);
      });
    }
  };

  render_panel_data = () => {
    return (
      this.state.grid_show && (
        <GridView
          id={this.state.selectedTab === 'extended_ps_permission_id' ? 'ps_permission_data' : 'roles_data'}
          row={
            this.state.selectedTab === 'extended_ps_permission_id'
              ? this.props.drawer_type === 'permission_sets'
                ? this.state.data.e_ps_list
                : this.state.data.permission_list
              : this.state.data.e_role_list
          }
          column={this.state.col_def}
          style={{ height: '100%' }}
          wrapperStyle={{ width: '100%', height: '100%', display: 'flex' }}
          get_grid_ref={this.get_grid_ref}
          enableColResize={true}
          headerHeight={this.grid_header_height}
          suppressMovableColumns={false}
          suppressSizeToFit={true}
          isPagination={true}
          paginationPageSize={DIMEN.PAGINATION_SIZE}
          gridAutoHeight={false}
        />
      )
    );
  };

  render_text_controls = () => {
    return this.state.controls.extended_text_controls.map((item) => {
      return (
        <React.Fragment key={item.id}>
          <Grid.Row>
            <Grid.Column>
              <label>{item.label}</label>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <span style={{wordBreak: 'break-all'}}>
                {this.state.data[this.state.controls.text_mapping_key] &&
                  this.state.data[this.state.controls.text_mapping_key][0] &&
                  this.state.data[this.state.controls.text_mapping_key][0][item.id]}
              </span>
            </Grid.Column>
          </Grid.Row>
        </React.Fragment>
      );
    });
  };

  setSelectedTab = (val) => {
    if (this.is_mounted) {
      this.setState(
        {
          grid_show: false,
          selectedTab: val,
          col_def:
            val === 'extended_ps_permission_id'
              ? this.props.drawer_type === 'permission_sets'
                ? roles_permission_set_data.extended_table_controls.permission_sets
                : roles_permission_set_data.extended_table_controls.permissions
              : roles_permission_set_data.extended_table_controls.roles
        },
        () => {
          this.calculate_grid_width();
        }
      );
    }
  };

  render() {
    return (
      <Grid columns='equal'>
        {this.render_text_controls()}
        <Grid.Row className='panel_row'>
          <Grid.Column>
            <Tabs
              animate={true}
              id='extended-permission-tab-group'
              defaultSelectedTabId={this.props.extendedTabId || 'extended_ps_permission_id'}
              onChange={this.setSelectedTab}
            >
              <Tab
                id='extended_ps_permission_id'
                title={this.state.controls.permission_tab_text}
                panel={this.render_panel_data()}
              />
              <Tab id='extended_roles_id' title='Roles' panel={this.render_panel_data()} />
              <Tabs.Expander />
            </Tabs>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
