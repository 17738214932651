import { AgGridReact } from 'ag-grid-react';
import 'ag-grid/dist/styles/ag-grid.css';
import 'ag-grid/dist/styles/ag-theme-balham.css';
import * as React from 'react';
import {
    DIMEN, GRID_AUTO_HEIGHT_STYLE, GRID_BASE_STYLE, GRID_FILTER_COMPONENTS, ICONS, TEMPLATE_LOADING,
    TEMPLATE_NO_RECORD, TYPE_EQUAL, GRID_NAME
} from './constants';
import { Pagination } from './pagination_component';
import { isTouchDevice, copy_cell_text } from './utils';

const objectValue = {
    valueOption1: TYPE_EQUAL,
    valueOption2: 'OR',
    valueOption3: TYPE_EQUAL,
    textValue1: '',
    textValue2: ''
};

interface IProps {
    id?: string,
    name?: string,
    column: any[],
    row: any[],
    onRowSelection?: Function,
    onRowDoubleClicked?: Function,
    selectionType?: string,
    paginationPageSize?: number,
    isPagination?: boolean,
    emptyMessage?: string,
    headerHeight?: number,
    style?: any,
    suppressMovableColumns?: boolean,
    enableColResize?: boolean,
    gridAutoHeight?: boolean,
    checkboxSelection?: boolean,
    get_grid_ref?: Function,
    isTotal?: boolean,
    pinnedBottomRowData?: any[],
    wrapperStyle?: object,
    rowWrap?: boolean,
    headerIdForTabNavigation?: string,
    onGridOut?: Function,
    onRef?: any,
    handleCheckboxNavigation?: boolean,
}

/**
 * Grid view component use to display data
 */
class GridView extends React.Component<IProps, any> {
    is_mounted = false;
    gridApi: any;
    gridColumnApi: any;
    focused_cell_column: any;
    focused_cell_index: any;
    container_grid: HTMLElement;

    //Get innitalise data when component created
    constructor(props) {
        super(props);
        const pageSize = this.props.paginationPageSize || DIMEN.PAGINATION_SIZE;
        this.state = {
            frameworkComponents: GRID_FILTER_COMPONENTS,
            defaultColDef: { suppressKeyboardEvent: this.keyboardEvent.bind(this) },
            current_page: 1, from_page: 1,
            to_page: this.props.row ? pageSize > this.props.row.length ? this.props.row.length : pageSize : 0,
            total_record: this.props.row ? this.props.row.length : 0,
            total_page: this.props.row ? ((this.props.row.length % pageSize) > 0 ? parseInt((this.props.row.length / pageSize).toString()) + 1 : parseInt((this.props.row.length / pageSize).toString())) : 0,
            is_first_page: true, is_last_page: true,
            getNodeChildDetails: function getNodeChildDetails(rowItem) {
                if (rowItem.participants) {
                    return {
                        group: true,
                        expanded: rowItem.group === "Group C",
                        children: rowItem.participants,
                        key: rowItem.group
                    };
                } else {
                    return null;
                }
            }
        };
    }

    // F=grid function callback on ready state
    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
        if (isTouchDevice()) {
            params.api.addEventListener('rowClicked', this.onRowClick.bind(this));
        } else {
            params.api.addEventListener('rowDoubleClicked', this.onRowDoubleClick.bind(this));
        }
        if (this.props.selectionType || this.props.checkboxSelection) {
            params.api.addEventListener('rowSelected', this.onSelectionChanged.bind(this));
        }
        if (this.props.gridAutoHeight) {
            params.api.addEventListener('columnResized', this.onColumnResized.bind(this));
            if (this.is_mounted) {
                this.setState({
                    timeout: setTimeout(() => { params.api.resetRowHeights() }, 500)
                });
            }
            
        }
        if (this.props.rowWrap) {
            if (this.is_mounted) {
                this.setState({
                    timeout: setTimeout(() => { params.api.resetRowHeights() }, 200)
                });
            }
           
        }
        if (this.props.get_grid_ref) {
            this.props.get_grid_ref(params);
        }
        this.handle_first_tab();
        this.container_grid = document.querySelector(`#${this.props.id || 'myGridGroup'}`);
        if (this.container_grid) {
            this.container_grid.addEventListener('mouseleave', this.handle_mouse_leave);
        }
        this.setGridOverflow();

    }

    handle_mouse_leave = event => {
        this.gridApi.clearFocusedCell();
    }

    /**
    * Handle the tab event for the first time
    */
    handle_first_tab = () => {
        if (this.props.headerIdForTabNavigation) {
            let self = this;
            let header: HTMLElement = document.getElementById(this.props.headerIdForTabNavigation) as HTMLElement;
            if (header) {
                // remove if already exist
                header.removeEventListener("keydown", this.event_listener_to_handle_header_tab, true);
                //Add listener
                header.addEventListener("keydown", this.event_listener_to_handle_header_tab);
            }
        } else if (this.props.handleCheckboxNavigation && this.props.id) {
            // here i want to apply tab index on checkbox header created
            const gridCells: NodeListOf<HTMLElement> = document.getElementById(this.props.id).querySelectorAll('.ag-header-cell:not(.ag-header-cell-sortable)') as NodeListOf<HTMLElement>;
            gridCells[0].setAttribute("tabindex", "0");
            gridCells[0].removeEventListener("keydown", this.event_listener_to_handle_checkbox_header_tab, true);
            gridCells[0].addEventListener("keydown", this.event_listener_to_handle_checkbox_header_tab);
        }
    }


    /**
     * Handle checkbox based grid navigation.
     * */
    event_listener_to_handle_checkbox_header_tab = (event) => {
        if (event.which == 32) {
            event.target.querySelector('span').click();
        }

        if (!event.shiftKey && event.keyCode == 9 && this.props.row.length > 0) {
            event.preventDefault();
            this.set_focus_on_first_cell_of_first_column();
        }
    }

    event_listener_to_handle_header_tab = (event) => {
        if (!event.shiftKey && event.keyCode == 9 && this.props.row.length > 0) {
            event.preventDefault();
            this.set_focus_on_first_cell_of_first_column();
        }
    }

    /**
     * Set focus to the first cell of first display row.
     * This is done to handle the tabbing when we traverse the grid again and again
     * */
    set_focus_on_first_cell_of_first_column = () => {

        if (this.gridApi && this.gridColumnApi) {
            // scrolls to the first row
            this.gridApi.ensureIndexVisible(0);

            // scrolls to the first column
            var firstCol = this.gridColumnApi.getAllDisplayedColumns()[0];
            this.gridApi.ensureColumnVisible(firstCol);

            let topRowIndex = 0;
            if (this.props.paginationPageSize) {
                topRowIndex = (this.state.current_page - 1) * this.props.paginationPageSize;
                // sets focus into the first grid cell
                this.gridApi.setFocusedCell(topRowIndex, firstCol);
            } else {
                // sets focus into the first grid cell
                this.gridApi.setFocusedCell(0, firstCol);
            }

           
        }
    }
    keyboardEvent({ event }) {
        copy_cell_text(event, this.gridApi);// Mac safari copy
        if (this.props.selectionType) {
            if (event.code === 'Enter') {
                var selectedRows = this.gridApi.getSelectedRows();
                if (selectedRows && selectedRows.length > 0) {
                    this.props.onRowDoubleClicked && this.props.onRowDoubleClicked(selectedRows[0]);
                }
            }
        }

        if (this.props.onGridOut) {
            // break out from the grid on alt + shift
            if (event.altKey && event.shiftKey) {

                event.preventDefault();

                if (this.gridColumnApi.getAllColumns() && this.gridColumnApi.getAllColumns()[0]) {
                    // scrolling left before breaking the grid
                    this.gridApi.ensureColumnVisible(this.gridColumnApi.getAllColumns()[0].colId);
                }
                this.gridApi.clearFocusedCell();
                this.props.onGridOut();
            }
        }
    }

    keyRowSelection = (index) => {
        if (this.props.selectionType) {
            this.gridApi.forEachNode((node) => {
                if (index === node.rowIndex) {
                    node.setSelected(true);
                }
            });
        }
    }

    navigateToNextCell(params) {

        var previousCell = params.previousCellDef;
        var suggestedNextCell = params.nextCellDef;

        var KEY_UP = 38;
        var KEY_DOWN = 40;
        var KEY_LEFT = 37;
        var KEY_RIGHT = 39;
        const { from_page, to_page } = this.state;

        switch (params.key) {
            case KEY_DOWN:
                previousCell = params.previousCellDef;
                let nextIndex = previousCell.rowIndex + 1;
                if (this.props.isPagination && this.props.isPagination === true) {
                    nextIndex = (previousCell.rowIndex) === (to_page - 1) ? (from_page - 1) : previousCell.rowIndex + 1;
                } else if (nextIndex > this.gridApi.rowRenderer.lastRenderedRow) {
                    nextIndex = this.gridApi.rowRenderer.lastRenderedRow;
                }
                // set selected cell on current cell + 1
                this.keyRowSelection(nextIndex);
                return {
                    rowIndex: nextIndex,
                    column: previousCell.column,
                    floating: previousCell.floating
                };
            // return suggestedNextCell;
            case KEY_UP:
                previousCell = params.previousCellDef;
                // set selected cell on current cell - 1
                let previous_index = previousCell.rowIndex !== 0 ? previousCell.rowIndex - 1 : previousCell.rowIndex;
                this.keyRowSelection(previous_index);
                return suggestedNextCell;
            case KEY_LEFT:
            case KEY_RIGHT:
                return suggestedNextCell;
            default:
                throw "this will never happen, navigation is always on of the 4 keys above";
        }
    }

    // grid function callback on ready state
    onGridResize() {
        this.gridApi.sizeColumnsToFit();
    }
    // Set Grid no record height 
    setGridOverflow =()=>{
        if (this.props.row.length === 0) {
            let grid_id:any = document.getElementById(this.props.id || 'myGrid');
            if (grid_id) {
              let ag_header = grid_id.querySelector('.ag-header').offsetHeight;
              let ag_body = grid_id.querySelector('.ag-body').offsetHeight;
              let ag_overlay: any = grid_id.querySelector('.ag-overlay');
              ag_overlay.style.marginTop = `${ag_header}px`;
              ag_overlay.style.height = `${ag_body}px`;
              ag_overlay.style.minHeight = `30px`;
            }
          }
    }
    // Grid data wrap
    onColumnResized(event) {
        if (event.finished) {
            this.gridApi.resetRowHeights();
        }
    }
    // grid row click function call
    onSelectionChanged() {
        var selectedRows = this.gridApi.getSelectedRows();
        this.props.onRowSelection && this.props.onRowSelection(selectedRows);
    }

    // grid row double click function call
    onRowDoubleClick({ data, rowIndex }) {
        this.props.onRowDoubleClicked && this.props.onRowDoubleClicked(data);
    }

    // grid row single click function call
    onRowClick({ data, rowIndex }) {
        this.props.onRowDoubleClicked && this.props.onRowDoubleClicked(data);
    }

    // grid row cell click function call
    onCellFocused(params) {
        this.focused_cell_column = params.column;
        this.focused_cell_index = params.rowIndex;
    }

    onCellMouseOver(params) {
        if (params.rowIndex !== this.focused_cell_index) {
            this.gridApi.setFocusedCell(params.rowIndex, params.column.colId);
        }
    }

    onCellMouseOut(params) {
    }

    // grid Pagination Changed callback
    onPaginationChanged({ api }) {
        if (this.is_mounted) {
            this.setState({
                is_first_page: this.state.current_page == 1,
                is_last_page: this.state.current_page == this.state.total_page
            });
        }
    }

    // grid Pagination Changed callback
    onFilterChanged({ api }) {
        const row_count = api.rowModel.rowsToDisplay.length;
        const pageSize = this.props.paginationPageSize || DIMEN.PAGINATION_SIZE;
        if (this.is_mounted) {
            this.setState({
                current_page: 1, from_page: 1, to_page: pageSize > row_count ? row_count : pageSize, total_record: row_count,
                total_page: (row_count % pageSize) > 0 ? parseInt((row_count / pageSize).toString()) + 1 : parseInt((row_count / pageSize).toString()),
                is_first_page: true,
                is_last_page: true,

            });
        }
        this.gridApi.paginationGoToFirstPage();

    }

    on_move_first = () => {
        this.gridApi.paginationGoToFirstPage();
        const pageSize = this.props.paginationPageSize || DIMEN.PAGINATION_SIZE;
        if (this.is_mounted) {
            this.setState({ current_page: 1, from_page: 1, to_page: pageSize > this.props.row.length ? this.props.row.length : pageSize });
        }
    }
    on_move_last = () => {
        this.gridApi.paginationGoToLastPage();
        const pageSize = this.props.paginationPageSize || DIMEN.PAGINATION_SIZE;
        if (this.is_mounted) {
            this.setState({
                current_page: this.state.total_page, to_page: this.state.total_record, from_page: ((this.state.total_page - 1) * pageSize) + 1
            });
        }

    }
    on_move_next = () => {
        this.gridApi.paginationGoToNextPage();
        const pageSize = this.props.paginationPageSize || DIMEN.PAGINATION_SIZE;
        const curr_page = this.state.current_page < this.state.total_page ? (this.state.current_page + 1) : this.state.current_page;
        if (curr_page <= this.state.total_page && this.is_mounted) {
            this.setState({
                current_page: curr_page,
                to_page: (curr_page * pageSize) > this.state.total_record ? this.state.total_record : (curr_page * pageSize), from_page: ((curr_page - 1) * pageSize) + 1
            })
        }
    }
    on_move_previous = () => {
        this.gridApi.paginationGoToPreviousPage();
        const pageSize = this.props.paginationPageSize || DIMEN.PAGINATION_SIZE;
        const curr_page = this.state.current_page > 1 ? (this.state.current_page - 1) : this.state.current_page;
        if (this.is_mounted) {
            this.setState({
                current_page: curr_page,
                to_page: (curr_page * pageSize) > this.state.total_record ? this.state.total_record : (curr_page * pageSize), from_page: ((curr_page - 1) * pageSize) + 1
            });
        }
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.row !== this.props.row) {
            if (this.gridApi) {
                const pageSize = this.props.paginationPageSize || DIMEN.PAGINATION_SIZE;
                if (this.is_mounted) {
                    this.setState({
                        current_page: 1, from_page: 1, to_page: pageSize > this.props.row.length ? this.props.row.length : pageSize, total_record: this.props.row.length,
                        total_page: (this.props.row.length % pageSize) > 0 ? parseInt((this.props.row.length / pageSize).toString()) + 1 : parseInt((this.props.row.length / pageSize).toString()),
                        is_first_page: true, is_last_page: true,

                    });
                }
                this.gridApi.paginationGoToFirstPage();
            }
        }
    }
    getOptions = () => {
        let attributes = {};
        if (this.props.checkboxSelection && this.props.checkboxSelection === true) {
            attributes = { ...attributes, ...{ rowSelection: 'multiple', suppressRowClickSelection: true } };
        } else if (this.props.selectionType) {
            attributes = { ...attributes, ...{ rowSelection: this.props.selectionType } };
        }
        if (this.props.isPagination) {
            attributes = { ...attributes, ...{ pagination: this.props.isPagination } };
        }
        if (this.props.gridAutoHeight) {
            attributes = { ...attributes, ...{ gridAutoHeight: this.props.gridAutoHeight } };
        }
        if (this.props.isTotal && this.props.isTotal === true) {
            attributes = { ...attributes, ...{ getRowStyle: this.getRowStyleTotal.bind(this) } };
        } if (this.props.name && this.props.name === GRID_NAME.MEDICAL_RECORD) {
            attributes['isRowSelectable'] = this.isRowSelectable.bind(this);
        } else if (this.props.name && this.props.name === GRID_NAME.VIEW_CHARGE) {
            attributes['isRowSelectable'] = this.is_row_selectable_view_charge.bind(this);
        }
        return attributes;
    }

    isRowSelectable(rowNode) {
        if (rowNode.data && rowNode.data.participants && rowNode.data.participants.length > 0) return false;

        return true;
    }
    is_row_selectable_view_charge(rowNode) {
        if (rowNode.data && (rowNode.data.allow_edit == false || rowNode.data.allow_edit == "Total")) return false;

        return true;
    }

    getRowStyleTotal(params) {
        if (params && (params.node.rowIndex == params.api.rowModel.rowsToDisplay.length - 1)) {
            return { fontWeight: 600 }
        }
        return null;
    }

    gridStyle = () => {
        if (this.props.gridAutoHeight && this.props.gridAutoHeight === true) {
            return { ...GRID_AUTO_HEIGHT_STYLE };
        } else {
            return { ...GRID_BASE_STYLE, ...this.props.style };
        }
    }

    renderPegination = () => {
        if (this.props.isPagination && this.props.isPagination === true && this.state.total_record && this.state.total_record > 0) {
            return (<Pagination
                fromPage={this.state.from_page}
                toPage={this.state.to_page}
                totalRecords={this.state.total_record}
                isFirstPage={this.state.is_first_page}
                isLastPage={this.state.is_last_page}
                onFirst={this.on_move_first}
                onPrevious={this.on_move_previous}
                onNext={this.on_move_next}
                onLast={this.on_move_last}
            />);
        }
        return null;
    }
    componentDidMount() {
        this.is_mounted = true;

        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }
    componentWillUnmount() {
        this.is_mounted = false;
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
        }
        if (this.props.onRef) {
            this.props.onRef(this);
        }

        if (this.container_grid) {
            this.container_grid.removeEventListener('mouseleave', this.handle_mouse_leave);
        }
    }

    ensureScrollIsLeft = () => {
        this.gridApi.ensureColumnVisible(this.gridColumnApi.getAllColumns()[0].colId);
    }
    //Render function
    render() {
        const style = this.gridStyle();
        const options = this.getOptions();
        const wrapperStyle = this.props.wrapperStyle ? this.props.wrapperStyle : { width: "100%", height: "100%" };
        return (
            <div style={wrapperStyle}>
                <div
                    id={this.props.id || "myGridGroup"}
                    style={style}
                    className="ag-theme-balham custom-multiselect">
                    {this.renderPegination()}
                    <AgGridReact
                        defaultColDef={this.state.defaultColDef}
                        columnDefs={this.props.column}
                        rowData={this.props.row}
                        icons={ICONS}
                        gridOptions={options}
                        enableSorting={true}
                        enableFilter={true}
                        suppressMenuHide={true}
                        suppressPaginationPanel={true}
                        suppressMovableColumns={true}
                        suppressDragLeaveHidesColumns={true}
                        getNodeChildDetails={this.state.getNodeChildDetails}
                        headerHeight={this.props.headerHeight || DIMEN.HEIGHT_HEADER}
                        paginationPageSize={this.props.paginationPageSize || DIMEN.PAGINATION_SIZE}
                        frameworkComponents={this.state.frameworkComponents}
                        overlayLoadingTemplate={TEMPLATE_LOADING}
                        overlayNoRowsTemplate={this.props.emptyMessage || TEMPLATE_NO_RECORD}
                        onPaginationChanged={this.onPaginationChanged.bind(this)}
                        navigateToNextCell={this.navigateToNextCell.bind(this)}
                        onCellMouseOver={this.onCellMouseOver.bind(this)}
                        onCellMouseOut={this.onCellMouseOut.bind(this)}
                        onCellFocused={this.onCellFocused.bind(this)}
                        onFilterChanged={this.onFilterChanged.bind(this)}
                        onGridSizeChanged={this.onGridResize.bind(this)}
                        onGridReady={this.onGridReady.bind(this)}
                        pinnedBottomRowData={this.props.pinnedBottomRowData || null}
                        enableColResize={this.props.enableColResize ? this.props.enableColResize : false} />

                </div>
            </div>
        );
    }
}

export default GridView;