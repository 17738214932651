import * as React from 'react';

interface IProps {
    id?: string,
    name?: string,
    style?: string,
    wrapperStyle?: string,
    defaultValue?: React.ReactText,
    placeHolder?: string,
    isRequired?: boolean,
    disabled?: boolean,
    options: any[],
    onChange: Function,
    autoFocus?: boolean,
    hidden?: boolean,
    onKeyDown?: Function,
    onFocus?: Function,
    revertSelection?: boolean
}

class Selection extends React.PureComponent<IProps, any> {

    SELECT: string = '';

    // Get innitalise data when component created
    constructor(props) {
        super(props);
        this.state = {
            placeHolder: this.props.placeHolder,
            value: this.props.defaultValue
        }
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.defaultValue !== this.props.defaultValue) {
            this.setState({
                placeHolder: this.props.placeHolder,
                value: this.props.defaultValue
            })
        }
    }
    //Selection change
    on_change = event => {
        if (this.props.onChange) {
            if (event.target.value != this.SELECT) {
                // first argument is returning the value and the second one is returning the whole event object
                this.props.onChange(event.target.value, event);
                this.setState({ placeHolder: null, value: event.target.value })
            }
        }
    }

    //Render items
    render_options = () => {
        if (this.props.options) {
            return this.props.options.map((item, index) => (<option key={index} value={item.value} title={item.original_text}>{item.text}</option>));
        }
        return null;
    }

    render_placeholder = () => {
        if (this.state.placeHolder != null && this.state.placeHolder != undefined) {
            if (this.props.hidden) {
                return <option key={-1} value={this.SELECT} disabled hidden>{this.props.placeHolder}</option>;
            }

            return <option key={-1} value={this.SELECT}>{this.props.placeHolder}</option>;
        }
        return null;
    }

    //Apply validation css
    isRequired = () => {
        if (this.props.isRequired == true && (this.props.disabled == false || this.props.disabled == undefined)) {
            return 'req-background-inp';
        }
        return '';
    }

    on_key_down = (event) => {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(event);
        }
    }
    on_focus = (event) => {
        if (this.props.onFocus) {
            this.props.onFocus(event);
        }
    }

    revert_selection = () => {
        if (this.props.revertSelection) {
            console.log('this is the default value: ', this.props.defaultValue);
            this.setState({
                value: this.props.defaultValue
            });
        }
    }

    //Render function
    render() {
        let select_props: any = {}
        if (this.props.onKeyDown) {
            select_props.onKeyDown = this.on_key_down 
        }
        if (this.props.onFocus) {
            select_props.onFocus = this.on_focus
        }

        if (this.props.revertSelection) {
            this.revert_selection
        }

        return (<div className={`select-options-dropdown ${this.props.wrapperStyle}`}>
            <select className={`${this.props.style} ${this.isRequired()}`}
                id={this.props.id}
                value={this.state.value}
                name={this.props.name}
                onChange={this.on_change}
                disabled={this.props.disabled}
                autoFocus={this.props.autoFocus || false}
                {...select_props}
            >
                {this.render_placeholder()}
                {this.render_options()}
            </select>
        </div>);
    }
};

export default Selection;