const cloneDeep = require('lodash/clonedeep');
import { RequestConfig } from '../../../shared/network/config';
import { Request } from '../../../shared/network/request';
import * as api_request from '../../../shared/network/request_extensions';
import * as global_constants from '../../../global_constants';
import * as constants from '../patient_letter_constants';
import { generate_guid } from '../../../shared/utility';


export const search_patient_letter = (search_request_obj: constants.IPatientLetterSearchRequestDto, token: string) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_letter.search_patient_letter}`;

    return api_request.get_header_request(url, token, { 'silversurfer': JSON.stringify(search_request_obj) } );
}

// Get the list of all the companies
export const get_user_companies = (userid: number, token: string) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.company_urls.get_user_companies_by_user_type}`

    return api_request.get_config_request(url, token, cloneDeep({ 'params': { userid: userid } }));
}
 
// Get patient letter
export const get_patient_letter_edit = (letterid: number, token: string) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_letter.get_patient_letter_edit}`

    return api_request.get_config_request(url, token, cloneDeep({ 'params': { letterid: letterid, rnd: generate_guid()} }));
}

// Get the list of all the patient letter variables
export const get_patient_letter_variables = (token: string) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_letter.get_patient_letter_variables}`;

    return api_request.get_request(url, token);
}

export const save_patient_letter = (token: string, patient_letter: any) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_letter.save_patient_letter}`;


    return api_request.post_config_request(url, token, cloneDeep({ data: { 'data': patient_letter } } ) );
}


