import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import { Button, Checkbox, Dimmer, Form, Grid, Header, Loader, Table, TableBody, Progress } from 'semantic-ui-react';
import { set_focus_to_app_header } from '../../shared/tab_navigation_utility';
import AdvancedControl from '../../shared/component/advanced_control';
import AutoSearchComponent from '../../shared/component/auto_search_component';
import IPPermissionComponent from '../../shared/component/ip_permission_component';
import AppPermission from './app_permission_component';
import AdditionalPermission from './additional_permission_component';
import { advanced_search_user_type, advanced_search_user_role } from '../../shared/action/shared_action';
import { get_data, get_search_data, update_payers, add_user } from '../../admin/constants/action/constants_action';
import * as constants from '../../admin/constants/constants';
import * as global_constants from '../../global_constants';
import { toastr as toaster } from 'react-redux-toastr';
import { get_all_error, unmask, get_lauch_darkley_key_value as get_launch_darkly_key_value } from '../../shared/utility';
import * as session_storage from '../../shared/session_storage_utility';
import MaskedInput from 'react-text-mask';
import * as user_constants from '../user_constants';
import * as global_constant from '../../global_constants';
import * as local_storage from '../../shared/local_storage_utility';
import { check_company_ff } from '../../company/action/company_action';

export class UserAddEditComponent extends React.Component<any, any> {
  additional_permission_component_ref: any;
  app_permission_component_ref: any;
  constructor(props) {
    super(props);
    // Initialize state property here
    this.state = {
      master_data: this.props.user_login_details ? this.props.user_login_details.formated_master_data : {},
      strength: 0,
      show_render: true,
      form_object: {
        additional_permission: [],
        company_permission: [],
        ip_address: [],
        user_details: {
          password: null,
          user_name: '',
          user_type: { user_type_id: 0, name: '' },
          user_Role: { role_id: 0, role_name: '', role_descraption: '' },
          is_override_charge_entry_supply: false,
          is_allow_outside_access: false,
          id: 0,
          name: {
            first_name: '',
            last_name: ''
          },
          phone: '',
          email: '',
          is_active: true,
          is_allow_access_to_r4_company_override : false
        }
      },
      is_loading: false,
      is_submitted: false,
      selected_user_type: null,
      selected_role: null,
      grid_conf: null,
      user_type_grid_data: null,
      role_grid_data: null,
      update_additional_permission: false,
      error: {},
      required: {},
      can_select_permission_of_company: false,
      is_sys_admin_or_customer: '',
      is_save_button_disabled: false
    };
    this.ip_address_component_ref = React.createRef();
  }

  auth_data = local_storage.get('auth_data');
  previous_error = false;
  _is_mounted = true;
  _app_permission_from_state = true;
  token: any;
  page_name: any;
  code_id: any;
  page_state: string;
  by_pass_prompt: boolean = false;
  page_metadata: any;
  ip_address_component_ref: any;
  user_details: any = {};
  additional_permission: any = [];
  company_permission: any = [];
  ip_address: any = [];
  [x: string]: any;
  user_type_search_enum = 'user_type';
  role_search_enum = 'role';
  user_type_sys_admin = 'sys_admin';
  user_type_customer = 'customer';
  go_next: boolean = false;
  form_object: any = {
    user_details: {
      password: '',
      user_name: '',
      user_type: {
        user_type_id: 0,
        name: ''
      },
      user_Role: {
        role_id: 0,
        role_name: '',
        role_descraption: ''
      },
      is_override_charge_entry_supply: true,
      is_allow_outside_access: true,
      id: 0,
      name: {
        middle_initial: '',
        suffix: '',
        first_name: '',
        last_name: '',
        is_name_requried: true,
        preferred_name: ''
      },
      email: '',
      phone: '',
      default_company_id: 0,
      default_company_code: '',
      default_company_name: '',
      is_active: true,
      is_allow_access_to_r4_company_override: false,
      is_federated_user: false
    },
    company_permission: [
      {
        companies: [
          {
            company_id: 0,
            company_name: '',
            state: '',
            gpms_code: '',
            parent_company_id: 0,
            is_user_company: true,
            is_default_company: true
          }
        ],
        company_id: 0,
        company_name: '',
        state: '',
        gpms_code: '',
        parent_company_id: 0,
        is_user_company: true,
        is_default_company: true
      }
    ],
    additional_permission: [
      {
        ps_id: 0,
        ps_name: '',
        ps_description: '',
        is_access: true
      }
    ],
    ip_address: [
      {
        ip_address_id: 0,
        ip_address: '',
        is_revoking: true,
        is_enabled: true
      }
    ]
  };

  required_field: any = {
    first_name: true,
    last_name: true,
    email: true,
    phone: true,
    user_name: true,
    user_type: true,
    user_Role: true
  };

  users_search_criteria: any = {
    first_name: '',
    last_name: '',
    login_id: '',
    user_type_id: '',
    email: '',
    role_id: '',
    default_company_id: '',
    show_inactive: false
  };

  UNSAFE_componentWillMount = () => {
    this.token = this.props.user_login_details.user_data.data.token_details.access_token;
    this.get_page_metadata();
  };

  componentDidMount() {
    this._is_mounted = true;
    document.body.classList.add('admin-framework');

    if (this.code_id) {
      this.get_data(this.code_id);
    } else {
      this.get_permissions();
    }
  }

  componentWillUnmount = () => {
    document.body.classList.remove('admin-framework');
    if (this.props.history.location) {
      var route_array = this.props.history.location.pathname.split('/');
      route_array && route_array[2] !== 'users' && session_storage.remove('users_search_criteria');
    }
  };

  get_page_state = () => {
    if (this.code_id) {
      return 'edit';
    } else {
      return 'add';
    }
  };

  //Use to fetch params and page metadata
  get_page_metadata = () => {
    this.token = this.props.user_login_details.user_data.data.token_details.access_token;
    this.page_metadata = constants.users;
    this.page_name =
      this.props.match.params && this.props.match.params.page_name ? this.props.match.params.page_name : '';
    this.code_id = this.props.match.params && this.props.match.params.id ? this.props.match.params.id : 0;
    let users_search_criteria = session_storage.get('users_search_criteria');
    this.users_search_criteria =
      (users_search_criteria && users_search_criteria.search_criteria) || this.users_search_criteria;
    this.user_type_r3_id = this.props.user_login_details.user_data.data.user_type_r3_id;
  };

  // Get the data on the basis of ID.
  get_data = async code_id => {
    let { selected_user_type, selected_role } = this.state;
    let url = this.page_metadata.api.get.url;
    url = url.replace('id_value', code_id);
    if (this._is_mounted) {
      this.setState({
        is_loading: true
      });
    }
    await get_data(this.token, url).then(
      response => {
        if (response.data && response.data.data) {
          this.form_object = { ...response.data.data };
          this.user_details = { ...response.data.data.user_details };

          let { user_type, user_Role } = this.user_details;
          let can_select_permission_of_company = this.state.can_select_permission_of_company;
          let is_sys_admin_or_customer = this.state.is_sys_admin_or_customer;

          if (user_type.user_type_id == 1 || user_type.user_type_id == 4) {
            can_select_permission_of_company = false;
            if (user_type.user_type_id == 1) {
              is_sys_admin_or_customer = this.user_type_sys_admin;
            } else {
              is_sys_admin_or_customer = this.user_type_customer;
            }
          } else if (user_type.user_type_id == 2 || user_type.user_type_id == 3) {
            can_select_permission_of_company = true;
            is_sys_admin_or_customer = '';
          }

          // storing default permissions in class variable
          this.company_permission = this.form_object.company_permission;
          this.additional_permission = this.form_object.additional_permission;
          selected_user_type = {
            code: user_type.user_type_id,
            label: user_type.name,
            name: `${user_type.user_type_id}_${user_type.name}`,
            title: user_type.user_type_id && user_type.user_type_id.toString()
          };
          selected_role = {
            code: user_Role.role_id,
            label: user_Role.role_name,
            name: `${user_Role.role_id}_${user_Role.role_name}`,
            title: user_Role.role_id && user_Role.role_id.toString()
          };

          if (this._is_mounted) {
            this.setState(
              {
                form_object: { ...this.form_object },
                selected_user_type,
                selected_role,
                is_loading: false,
                can_select_permission_of_company: can_select_permission_of_company,
                is_sys_admin_or_customer: is_sys_admin_or_customer,
                update_additional_permission: true
              },
              () => {
                this.setState({ update_additional_permission: false });
              }
            );
          }
        }
      },
      error => {
        if (this._is_mounted) {
          this.setState({
            is_loading: false
          });
        }
        if (error.response && error.response.data && error.response.data.messages) {
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error('', toastr_options);
        }
      }
    );
  };
  // Get the data on the basis of ID.
  get_permissions = () => {
    let url = this.page_metadata.api.get_permissions.url;
    if (this._is_mounted) {
      this.setState({
        is_loading: true
      });
    }
    get_data(this.token, url).then(
      response => {
        if (response.data && response.data.data) {
          let from_obj = { ...this.state.form_object };
          from_obj.additional_permission = response.data.data.additional_permission;
          from_obj.company_permission = response.data.data.company_permission;

          // storing default permissions in class variable
          this.company_permission = response.data.data.company_permission;
          this.additional_permission = response.data.data.additional_permission;

          if (this._is_mounted) {
            this.setState(
              {
                form_object: from_obj,
                is_loading: false,
                update_additional_permission: true
              },
              () => {
                this.setState({ update_additional_permission: false });
              }
            );
          }
        }
      },
      error => {
        if (this._is_mounted) {
          this.setState({
            is_loading: false
          });
        }
        if (error.response && error.response.data && error.response.data.messages) {
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error('', toastr_options);
        }
      }
    );
  };

  on_advanced_grid_row_select = (item, type) => {
    this.handle_selection_errors(item, type);
    const prepared_result = this.row_selection_format(item, type);
    this.on_advanced_search_grid_row_election(prepared_result, type);
  };

  handle_selection_errors = (item, type) => {
    const error = { ...this.state.error };
    if (type == this.role_search_enum) {
      error.user_role_id = false;
    }
    if (type == this.user_type_search_enum) {
      error.user_type_id = false;
    }
    if (this._is_mounted) {
      this.setState({
        error: error
      });
    }
  };

  on_advanced_search_grid_row_election = (selectedRow, type) => {
    let user_details = this.state.form_object.user_details;

    if (type == this.user_type_search_enum) {
      let selected_user_type = this.state.selected_user_type;

      if (!selected_user_type || selected_user_type.code != selectedRow.code) {
        this.clear_quick_search(this.role_search_enum);
      }

      let can_select_permission_of_company = this.state.can_select_permission_of_company;
      let is_sys_admin_or_customer = this.state.is_sys_admin_or_customer;

      if (selectedRow.code == 1 || selectedRow.code == 4) {
        if (selectedRow.code == 1) {
          is_sys_admin_or_customer = this.user_type_sys_admin;
        } else {
          is_sys_admin_or_customer = this.user_type_customer;
        }

        can_select_permission_of_company = false;
      } else if (selectedRow.code == 2 || selectedRow.code == 3) {
        can_select_permission_of_company = true;
        is_sys_admin_or_customer = '';
      }

      user_details.user_type = selectedRow;
      this.setState(
        {
          can_select_permission_of_company: can_select_permission_of_company,
          is_sys_admin_or_customer: is_sys_admin_or_customer,
          selected_user_type: selectedRow,
          form_object: {
            ...this.state.form_object,
            user_details: {
              ...user_details
            }
          },
          update_additional_permission: true
        },
        () => {
          this.setState({ update_additional_permission: false });
        }
      );
    } else {
      user_details.user_Role = selectedRow;

      this.setState(prevState => ({
        selected_role: selectedRow,
        form_object: {
          ...this.state.form_object,
          user_details: {
            ...user_details
          }
        }
      }));
    }
  };

  // clear data on clear icon
  clear_quick_search = type => {
    const { error, form_object } = this.state;
    if (type == this.user_type_search_enum && this.state.is_submitted) {
      error.user_type_id = true;
    }

    if (type == this.role_search_enum && this.state.is_submitted) {
      error.user_role_id = true;
    }
    if (this._is_mounted) {
      if (type == this.user_type_search_enum) {
        form_object.user_details.user_type = null;
        this.setState({
          selected_user_type: {},
          form_object: form_object,
          error
        });
      } else {
        form_object.user_details.user_Role = null;
        this.setState({
          selected_role: {},
          form_object: form_object,
          error
        });
      }
    }
  };

  // get the searched value which is typed for searching location or providers
  get_value = (input, type) => {
    const error = { ...this.state.error };
    let user_details = this.state.form_object.user_details;

    if (input.trim().length >= 3) {
      if (type === this.user_type_search_enum) {
        this.get_user_type_list(input);
      } else {
        this.get_role_list(input);
      }
    }
    if (type == this.class_search_enum) {
      error.user_type_id = true;
    } else {
      error.user_role_id = true;
    }
    if (this._is_mounted) {
      const { is_submitted, error } = this.state;
      if (is_submitted) {
        if (type == this.user_type_search_enum) {
          user_details.user_type = null;
        } else {
          user_details.user_Role = null;
        }

        this.setState({
          form_object: {
            ...this.state.form_object,
            user_details: {
              ...user_details
            }
          },
          error: error
        });
      } else {
        if (type == this.user_type_search_enum) {
          user_details.user_type = null;
        } else {
          user_details.user_Role = null;
        }

        this.setState({
          form_object: {
            ...this.state.form_object,
            user_details: {
              ...user_details
            }
          }
        });
      }
    }
  };

    get_user_type_list = async search_keyword => {
        let params = '&page_size=' + global_constants.constants.Quick_Search_Suggestion_List_Size + '&is_from_user_add_edit=true';

    let url = global_constants.end_points.user_urls.user_type_quick_search + '?keyword=' + search_keyword + '' + params;
    return await get_search_data(this.token, url);
  };

  get_role_list = async search_keyword => {
      let params = '&page_size=' + global_constants.constants.Quick_Search_Suggestion_List_Size ;
    
    let userTypeId = null;
      
    if(this.state.selected_user_type && this.state.selected_user_type.code) {
      userTypeId = this.state.selected_user_type.code;
    }

    if(! userTypeId) {
      userTypeId = local_storage.get('auth_data').user_type_r3_id;
    }
    if (userTypeId) {
      params = params + '&user_type_id=' + userTypeId;
    }

    let url = global_constants.end_points.user_urls.user_role_quick_search + '?keyword=' + search_keyword + '' + params;
    return await get_search_data(this.token, url);
  };

  //Auto search for class
  renderResult = props => {
    return (
      <div key={props.title} tabIndex={0} className='item_auto_search'>
        <div key={props.title} className='fs_13'>
          <span>{props.label}</span>
        </div>
      </div>
    );
  };

  row_selection_format = (item, type) => {
    var formatted = null;
    if (type == this.user_type_search_enum) {
      formatted = {
        name: item.name,
        id: item.user_type_id
      };
      formatted = {
        title: `${item.user_type_id}`,
        code: item.user_type_id,
        name: item.name,
        label: item.name
      };
    } else if (type == this.role_search_enum) {
      formatted = {
        name: item.role_name,
        id: item.role_id
      };
      formatted = {
        title: `${item.role_id}`,
        code: item.role_id,
        name: item.role_name,
        label: item.role_name
      };
    }

    return formatted;
  };

  // preapare the suggestion list with search result
  prepare_suggestion = (data, type) => {
    let formattedList = [];
    var data_length = data.data.length;
    if (data && data_length) {
      for (let i = 0; i < data_length; i++) {
        let item = data.data[i];
        if (type == this.user_type_search_enum) {
          formattedList.push({
            title: `${item.user_type_id}`,
            code: item.user_type_id,
            name: item.name,
            label: item.name
          });
        } else if (type == this.role_search_enum) {
          formattedList.push({
            title: `${item.role_id}`,
            code: item.role_id,
            name: item.role_name,
            label: item.role_name
          });
        }
      }
    }
    return formattedList;
  };

  on_advance_search = async (params, type) => {
    var search_data = null;
    var config_column = null;
    var grid_type = '';
    let new_params = params;
    if (type == this.user_type_search_enum) {
      new_params = { ...params, is_from_user_add_edit: true };

      search_data = await advanced_search_user_type(new_params, this.token).then(res => res.data);
      config_column = constants.users.user_type_column_def;
      grid_type = 'user_type_grid_data';
    } else if (type == this.role_search_enum) {
      
      let userTypeId = null;
      
      if(this.state.selected_user_type && this.state.selected_user_type.code) {
        userTypeId = this.state.selected_user_type.code;
      }

      if(! userTypeId) {
        userTypeId = local_storage.get('auth_data').user_type_r3_id;
      }

      if (userTypeId) {
        new_params = { ...params, user_type_id: userTypeId };
      }

      search_data = await advanced_search_user_role(new_params, this.token).then(res => res.data);
      config_column = constants.users.role_column_def;
      grid_type = 'role_grid_data';
    }

    const search_result = search_data && search_data.data !== null ? search_data.data : [];
    const grid_data = {
      ...this.state.grid_conf,
      rows: search_result,
      column: config_column,
      messages: search_data.messages,
      paginationPageSize: 20
    };

    this.setState({ [grid_type]: grid_data });
  };

  on_item_selection = (item, type) => {
    this.handle_selection_errors(item, type);
    this.on_advanced_search_grid_row_election(item, type);
  };

  // Checkbox checkbox handler.
  checkbox_handler = e => {
    const { checked, name } = e.target;
    let { form_object } = this.state;
    form_object.user_details[name] = JSON.parse(checked);

    if (this._is_mounted) {
      this.setState(prev_state => ({
        form_object: form_object
      }));
    }
  };

  // set input field value in state
  input_change_handler = e => {
    const name = e.target.name;
    let value = e.target.value;
    const error = { ...this.state.error };
    let user_details = this.state.form_object.user_details;

    /* Following lines we will check for validation while changing certain fields
     *
     * */
    if (name == 'first_name' || name == 'last_name') {
      if (this.state.is_submitted) {
        error[name] = value.trim() == '' ? true : false;
      }

      user_details.name[name] = value;
    } else if (name == 'user_name') {
      if (this.state.is_submitted) {
        error[name] = value.trim() == '' ? true : false;
      }
      user_details[name] = value;
    } else if (name == 'phone') {
      let error_val = this.input_data_validation(unmask(value), 'number');
      value = value && value.length > 0 && value.replace(/[^0-9]/g, '');
      let is_error = name == 'phone' ? error_val : value.length > 0 && error_val;
      error[name] = is_error;
      user_details[name] = value;
    } else if (name == 'email') {
      error[name] = this.input_data_validation(value, 'email');
      user_details[name] = value;
    } else if (name == 'password') {
      user_details[name] = value;
    }

    if (this._is_mounted) {
      this.setState({
        form_object: {
          ...this.state.form_object,
          user_details: {
            ...user_details
          }
        },
        error: error
      });
    }
  };

  //Function used to Run Report based on controllers values.
  cancel_handler = () => {
    this.by_pass_prompt = true;
    this.setState(
      {
        by_pass_prompt: true
      },
      () => this.props.history.goBack()
    );
  };

  // check if data has only letters and numbers
  input_data_validation = (data, type) => {
    if (type === 'number') {
      data = data.toString().replace(/[^0-9]/g, '');
      return data.length != 10;
    } else if (type === 'country_id' || type == 'insurance_type_id') {
      let reg = new RegExp('/^[sds]*$/');
      return typeof data !== 'number' && !data.match(reg);
    } else if (type === 'zip') {
      return !(data.length == 5 || data.length == 9);
    } else if (type === 'email') {
      let email_invalid = data.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? false : true;
      return email_invalid;
    } else if (type === 'postal_code') {
      data = data.toString().replace(/[^0-9]/g, '');
      return data === '';
    }
  };

  // validate form before saving data
  validate_form = () => {
    const error = { ...this.state.error };
    const form_object = this.state.form_object;
    let error_in_data_type = false;
    let error_in_role = false;
    let selected_user_type = this.state.selected_user_type;
    let selected_role = this.state.selected_role;

    if (
      form_object.user_details.user_type == null ||
      form_object.user_details.user_type.code == 0 ||
      !selected_user_type ||
      !selected_user_type.code
    ) {
      error_in_data_type = true;
    }
    if (
      form_object.user_details.user_Role == null ||
      form_object.user_details.user_Role.role_id == 0 ||
      !selected_role ||
      !selected_role.code
    ) {
      error_in_role = true;
    }
    error.user_type_id = error_in_data_type;
    error.user_role_id = error_in_role;
    error.first_name = form_object.user_details.name.first_name.trim() == '' ? true : false;
    error.last_name = form_object.user_details.name.last_name.trim() == '' ? true : false;
    error.user_name = form_object.user_details.user_name.trim() == '' ? true : false;
    error.phone = this.input_data_validation(form_object.user_details.phone, 'number');
    error.email =
      form_object.user_details.email.length >= 0 && this.input_data_validation(form_object.user_details.email, 'email');

    if (this.page_state == 'add') {
      error.password =
        !form_object.user_details.password || form_object.user_details.password.trim() == '' ? true : false;
    }

    if (this._is_mounted) {
      this.setState({
        error: error,
        is_submitted: true
      });
    }
    if (
      Object.keys(error).some(data => {
        return error[data] == true;
      })
    ) {
      this.previous_error = true;
      toaster.error('', global_constants.constants.mandatory_fields);
      return false;
    }

    if (!this.app_permission_component_ref.has_default_company()) {
      this.previous_error = true;
      toaster.error('', global_constants.constants.default_company_mandatory);
      return false;
    }

    if (!this.check_ip_address_validation()) {
      this.previous_error = true;
      toaster.error('', global_constants.constants.mandatory_fields);
      return false;
    }

    if (form_object.user_details.password && form_object.user_details.password != '' && this.state.strength < 95) {
      this.toaster_for_password_strength();
      return false;
    }
    
    if(this.page_state == 'edit') {

      let default_company = this.app_permission_component_ref.get_default_company();

      if(! default_company.company && !form_object.user_details.default_company_id) {
        this.previous_error = true;
        toaster.error('', global_constants.constants.default_company_mandatory);
        return false;
      }

      /*
        If parent company is default company & has children 
        then we will ask user to select a new default company. 
        (This is a edge case of R4. This should not happen in R6)
      */
      if(! default_company.company) {
        let company_permissions = this.get_update_app_permission_list();
        let default_required = false;
        company_permissions.map((company, index, companies) => {
          if(company.is_default_company && company.companies.length > 0) {
            default_required = true;
           
          }
        });

        if(default_required) {
          toaster.error('', global_constants.constants.default_company_mandatory);
          return false;
        }
      }
    }

    return true;
  };

  toaster_for_password_strength = () => {
    if (this.state.form_object.user_details.password) {
      var strength = this.strength_rule(this.state.form_object.user_details.password);

      if (strength < 95) {
        const toastr_options = this.show_html_content_toaster(user_constants.is_weak_password);
        toaster.error('', '', toastr_options);
      }
    }
  };

  show_html_content_toaster = msg => {
    return {
      component: () => (
        <div>
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        </div>
      ),
      timeOut: global_constant.toastr_options.toastr_time_out,
      preventDuplicates: true
    };
  };

  //Rule to check the password strength
  strength_rule = password => {
    var passwordRating = 0;
    var strength = 0;
    if (/^(?:.*[!@\#$%^&=?_.,:;\\-]+.*)$/.test(password)) {
      //Match any special character
      passwordRating += 5;
    }
    if (/^(?=.*[a-z]).+$/.test(password)) {
      //Match any lower case character
      passwordRating += 5;
    }
    if (/^(?=.*[A-Z]).+$/.test(password)) {
      //Match any upper case character
      passwordRating += 5;
    }
    if (/^(?=.*[0-9]).+$/.test(password)) {
      //Match any number
      passwordRating += 5;
    }
    passwordRating += password.length * 10 <= 80 ? password.length * 10 : 80;

    strength = passwordRating;
    return strength;
  };
  //Save the form object and redirect it to search
  save_handler = (e, from_pop_up) => {
    if (this.validate_form()) {
      if (this._is_mounted) {
        this.setState({
          is_loading: true,
          is_save_button_disabled: true
        });
      }

      let form_object = { ...this.state.form_object };
      /*  Here we need to create the data that is needed to be sent to the API
       *
       * */
      let new_user_data: any = {};
      new_user_data.ip_address = this.get_update_ip_address_list();
      new_user_data.additional_permission = this.get_update_additional_permission_list();
      new_user_data.company_permission = this.get_update_app_permission_list();
      new_user_data.user_details = form_object.user_details;

      new_user_data.user_details.name.first_name = form_object.user_details.name.first_name.trim();
      new_user_data.user_details.name.last_name = form_object.user_details.name.last_name.trim();
      new_user_data.user_details.user_name = form_object.user_details.user_name.trim();
      new_user_data.user_details.email = form_object.user_details.email.trim();

      new_user_data.user_details.user_Role = {
        role_id: this.state.selected_role.code,
        role_name: this.state.selected_role.label,
        role_descraption: ''
      };

      new_user_data.user_details.user_type = {
        user_type_id: this.state.selected_user_type.code,
        name: this.state.selected_user_type.label
      };
      session_storage.set('users_search_criteria', {});
      if (this.code_id) {
        //Update
        this.update_user(from_pop_up, new_user_data);
      } else {
        //Add
        this.add_user(from_pop_up, new_user_data);
      }
    }
  };

  // Update Payer
  update_user = (from_pop_up, form_object) => {
    let url = this.page_metadata.api.update.url;
    url = url.replace('id_value', this.code_id);
    update_payers(this.token, url, form_object).then(
      response => {
        if (response.data && response.data.data) {
          this.go_next = true;
          toaster.success('', this.page_metadata.validation_message.update);
          if (this._is_mounted) {
            this.setState({
              is_loading: false,
              is_save_button_disabled: false
            });
          }
          if (!from_pop_up && this.go_next) {
            this.go_next = false;
            this.props.history.goBack();
          }
        } else {
          this.go_next = false;
          toaster.error('', response.data.messages[0].message);
          if (this._is_mounted) {
            this.setState({
              is_loading: false,
              is_save_button_disabled: false
            });
          }
        }
      },
      error => {
        if (this._is_mounted) {
          this.go_next = false;
          this.setState({
            is_loading: false,
            is_save_button_disabled: false
          });
        }
        if (error.response.data) toaster.error('', error.response.data.messages[0].message);
      }
    );
  };

  // Add Payer
  add_user = (from_pop_up, form_object) => {
    let url = this.page_metadata.api.add.url;
    add_user(this.token, url, form_object).then(
      response => {
        if (response.data && response.data.data) {
          this.go_next = true;
          toaster.success('', this.page_metadata.validation_message.save);
          if (this._is_mounted) {
            this.setState({
              is_loading: false,
              is_save_button_disabled: false
            });
          }
          if (!from_pop_up && this.go_next) {
            this.go_next = false;
            this.props.history.goBack();
          }
        } else {
          this.go_next = false;
          toaster.error('', response.data.messages[0].message);
          if (this._is_mounted) {
            this.setState({
              is_loading: false,
              is_save_button_disabled: false
            });
          }
        }
      },
      error => {
        if (this._is_mounted) {
          this.go_next = false;
          this.setState({
            is_loading: false,
            is_save_button_disabled: false
          });
        }
        if (error.response.data) toaster.error('', error.response.data.messages[0].message);
      }
    );
  };

  get_update_ip_address_list = () => {
    if (this.ip_address_component_ref) {
      return this.ip_address_component_ref.get_update_ip_address_list();
    }
    return [];
  };
  get_update_app_permission_list = () => {
    if (this.app_permission_component_ref) {
      return this.app_permission_component_ref.get_update_app_permission_list();
    }
    return [];
  };
  get_update_additional_permission_list = () => {
    if (this.additional_permission_component_ref) {
      return this.additional_permission_component_ref.get_update_additional_permission_list();
    }
    return [];
  };

  check_ip_address_validation = () => {
    if (this.ip_address_component_ref) {
      return this.ip_address_component_ref.validate_ip_address_list();
    }
    return true;
  };

  //Change event for input :- old password, new password ...
  private on_password_form_change = event => {
    var field_name = event.currentTarget.name ? event.currentTarget.name.replace('txt_', '') : event.currentTarget.name;
    var field_value = event.currentTarget.value;

    var form_object = this.state.form_object;
    var strength = this.state.strength;
    var error = this.state.error;

    form_object.user_details.password = field_value;
    strength = this.strength_rule(event.currentTarget.value);

    // state.password_form[field_name] = field_value;

    //var is_valid = this.validate_password_value(field_name, field_value, strength);
    error.password = this.state.is_submitted && !field_value;

    // this.props.password_data(state)
    this.setState({
      strength: strength,
      form_object: form_object,
      error: error
    });
  };

  prepare_company_permissions = () => {
    let company_permission = undefined;
    let canSelectPermission = this.state.can_select_permission_of_company;
    let is_sys_admin_or_customer = this.state.is_sys_admin_or_customer; 
    let default_company_of_logged_in_user = local_storage.get('auth_data').company_id;
    let child_default_company = null;

    let _this = this;
    if (this.state.update_additional_permission) {
      let default_company = this.app_permission_component_ref.get_default_company();

      // fetching data from child prop
      if (!canSelectPermission) {

        if (is_sys_admin_or_customer == this.user_type_sys_admin) {
          company_permission = this.app_permission_component_ref.get_list_of_companies_of_user_type_sys_admin();
        } else if (is_sys_admin_or_customer == this.user_type_customer) {
          company_permission = this.app_permission_component_ref.get_list_of_companies_of_user_type_customer();
        }
      } else {
        company_permission = this.app_permission_component_ref.get_list_of_companies_with_editable_permissions();
      }

      if (this.page_state == 'edit' && this._app_permission_from_state) {
        this._app_permission_from_state = false;

        let new_obj = this.state.form_object.company_permission.map(function(item, index, companies) {
          const company = { ...item };

          if (is_sys_admin_or_customer == _this.user_type_customer) {
            if (!default_company.company && company.is_default_company) {
              company.is_access = company.is_access;
            } else {
              company.is_access = false;
            }
          } else {
            company.is_access = item.is_access;
          }

          // done to enable permission in case of sys admin
          if (is_sys_admin_or_customer == _this.user_type_sys_admin) {
            company.is_access = true;
          }        
          
          if(company.is_default_company) {
            company.is_access = true;
          } 
  
          company.companies = company.companies.map(function(item, sub_index, sub_companies) {
            let sub_company = { ...item };

            if (is_sys_admin_or_customer == _this.user_type_customer) {
              if (!default_company.company && sub_company.is_default_company) {
                sub_company.is_access = sub_company.is_access;
              } else {
                sub_company.is_access = false;
              }
            }
 
             // done to enable permission in case of sys admin
            if (is_sys_admin_or_customer == _this.user_type_sys_admin) {
              sub_company.is_access = true;
            }

            if(sub_company.is_default_company) {
              sub_company.is_access = true;
            }   
            
            // not to implement this feature at the time of edit
            // /**
            //  * If User Type is customer
            //  * then give default access & default permission to default_company_of_logged_in_user
            //  */
            // if(is_sys_admin_or_customer == _this.user_type_customer) {
            //   if(sub_company.company_id == default_company_of_logged_in_user) {
            //     sub_company.is_access = true;
            //     sub_company.is_default_company = true;
            //     child_default_company = {
            //       company: index,
            //       sub_company: sub_index
            //     }
            //   } else {
            //     sub_company.is_default_company = false;
            //   }
            // }
  
            return sub_company;
          });          
  
          let company_indeterminate =  false;
          let enabledIndeterminate = company.companies.filter((sub_company, index, subcompanies) => {
            if(sub_company.is_access && index < subcompanies.length) {
              company_indeterminate = true;
            }
      
            return sub_company.is_access == false;
          });
          if (company.companies.length > 0) {
            if (enabledIndeterminate.length == 0) {
              company.is_access = true;
              company.indeterminate = false;
            } else {
              company.is_access = false;
              company.indeterminate = company_indeterminate;
            }
           } else {
              company.indeterminate = false;
 
            /** not to perform this at the time of edit
             * If User Type is customer
             * then give default access & default permission to default_company_of_logged_in_user
             */
              // if(is_sys_admin_or_customer == _this.user_type_customer) {
              //   if(company.company_id == default_company_of_logged_in_user) {
              //     company.is_access = true;
              //     company.is_default_company = true;
              //   } else {
              //     company.is_default_company = false;
              //   }
              // }
            }

            // company.child_default_company = child_default_company;
  
          return company;
        });
        return new_obj;
  
      }

      // page state == add
      company_permission = company_permission || [...this.state.form_object.company_permission];
      let new_obj = company_permission.map(function(item, index, companies) {
        const company = { ...item };

        if (is_sys_admin_or_customer == _this.user_type_customer) {
          if (!default_company.company && company.is_default_company) {
            company.is_access = company.is_access;
          } else {
            company.is_access = false;
          }
        } else {
          company.is_access = item.is_access;
        }

        // done to enable permission in case of sys admin
        if (is_sys_admin_or_customer == _this.user_type_sys_admin) {
          company.is_access = true;
        }

        if (!default_company.company) {
          company.is_default_company = company.is_default_company;
        } else {
          company.is_default_company = false;
        }
        
        // setting default company 
        if (default_company && default_company.sub_company == -1 && default_company.company == index) {
          company.is_default_company = true;
          company.is_access = true;
        }

        company.companies = company.companies.map(function(item, sub_index, sub_companies) {
          let sub_company = { ...item };

          if (is_sys_admin_or_customer == _this.user_type_customer) {
            if (!default_company.company && sub_company.is_default_company) {
              sub_company.is_access = sub_company.is_access;
            } else {
              sub_company.is_access = false;
            }
          }

           // done to enable permission in case of sys admin
          if (is_sys_admin_or_customer == _this.user_type_sys_admin) {
            sub_company.is_access = true;
          }

          if (!default_company.company) {
            sub_company.is_default_company = sub_company.is_default_company;
          } else {
            sub_company.is_default_company = false;
          }

          // setting default company 
          if (default_company && default_company.sub_company == sub_index && default_company.company == index) {
            sub_company.is_default_company = true;
            sub_company.is_access = true;
            child_default_company = {
              company: index,
              sub_company: sub_index
            }
          }

          /**
             * If User Type is customer
             * then give default access & default permission to default_company_of_logged_in_user
             */
            if(is_sys_admin_or_customer == _this.user_type_customer && _this.page_state != 'edit') {
              if(sub_company.company_id == default_company_of_logged_in_user) {
                sub_company.is_access = true;
                sub_company.is_default_company = true;
                child_default_company = {
                  company: index,
                  sub_company: sub_index
                }
              } else {
                
                  sub_company.is_default_company = false;
              }
            }
          return sub_company;
        });

        let company_indeterminate =  false;
        let enabledIndeterminate = company.companies.filter((sub_company, index, subcompanies) => {
          if(sub_company.is_access && index < subcompanies.length) {
            company_indeterminate = true;
          }
    
          return sub_company.is_access == false;
        });

        if (company.companies.length > 0) {
          if (enabledIndeterminate.length == 0) {
            company.is_access = true;
            company.indeterminate = false;
          } else {
            company.is_access = false;
            company.indeterminate = company_indeterminate;
          }
        } else {
          company.indeterminate = false;

           /**
             * If User Type is customer
             * then give default access & default permission to default_company_of_logged_in_user
             */
            if(is_sys_admin_or_customer == _this.user_type_customer && _this.page_state != 'edit') {
              if(company.company_id == default_company_of_logged_in_user) {
                company.is_access = true;
                company.is_default_company = true;
              } else {
                company.is_default_company = false;
              }
            }
        }
        

        company.child_default_company = child_default_company;
        return company;
      });
      return new_obj;
    } else {
      return this.state.form_object.company_permission;
    }
  };

  prepare_additional_permissions() {
    let selected_user_type = this.state.selected_user_type;
    let curent_user_type = local_storage.get('auth_data')
      ? local_storage.get('auth_data').user_type_r3_id
        ? local_storage.get('auth_data').user_type_r3_id
        : 0
      : 0;

    if (this.state.update_additional_permission && selected_user_type) {
      let new_additional_permission = this.state.form_object.additional_permission.filter(function(
        permission,
        index,
        permissions
      ) {
        if (
          permission.required_user_level <= 0 ||
          (permission.required_user_level >= selected_user_type.code &&
            permission.required_user_level >= curent_user_type)
        ) {
          return permission;
        }
      });

      return new_additional_permission;
    } else {
      return this.state.form_object.additional_permission;
    }
  }

  // Render Allow Access to R4 Company Overrides
  render_allow_access_r4_company_overrides() {
    return (
        this.props.ff_argos_b33306_restrict_access_to_r4_with_overrides && this.user_type_r3_id == global_constants.enum_user_type_r3_id.Sys_Admin && (
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                        <Checkbox
                            name='is_allow_access_to_r4_company_override'
                            id='is_allow_access_to_r4_company_override'
                            onChange={e => this.checkbox_handler(e)}
                            checked={this.state.form_object.user_details.is_allow_access_to_r4_company_override || false}
                            label='Allow Access to R4 Company Override'
                        />
                    </Form.Field>
                </Grid.Column>
           )
    );
  }

  // Render disable password for federated User
    render_disable_password_for_federated_user() {
        if (this.props.ff_magnus_plat1702_disable_password_reset_for_federated_user) {
            return (this.state.form_object.user_details.is_federated_user);
        } else {
            return false;
        }
    }

  render() {
    const { is_submitted, error, strength } = this.state;
    let { ip_address, user_details } = this.state.form_object;

    let company_permission = this.prepare_company_permissions();
    let additional_permission = this.prepare_additional_permissions();
      
    this.page_state = this.get_page_state();
    return this.state.show_render && (
      <React.Fragment>
        <Dimmer active={this.state.is_loading}>
          <Loader size='massive'>Loading</Loader>
        </Dimmer>
        <div className={'admin-wrapper view-edit Locations '} style={user_css.user_id}>
          <Grid className='headerGrid' style={user_css.headerGrid}>
            <Grid.Column computer={16}>
              <h3 className='ui header left aligned'>{'Users'}</h3>
            </Grid.Column>
          </Grid>

          <div className='wrapper' style={user_css.wrapper}>
            <Form autoComplete='off' name='view_edit_form' className='common-forms' style={user_css.common_forms}>
              <div id='admin-scrollable-area' style={user_css.scrollbar_area}>
                <Grid>
                  <Grid.Column tablet={8} computer={4}>
                    <Form.Field className={is_submitted && error.first_name ? 'requiredWithBgColor error' : ''}>
                      <label>
                        First Name{' '}
                        <span className={is_submitted && error.first_name ? 'req-alert' : 'req-alert_normal'}>
                          (required)
                        </span>
                      </label>
                      <input
                        autoComplete='new-password'
                        name='first_name'
                        id='first_name'
                        onChange={e => this.input_change_handler(e)}
                        value={user_details.name.first_name ? user_details.name.first_name : ''}
                        type='text'
                        autoFocus={true}
                        maxLength={50}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column tablet={8} computer={4}>
                    <Form.Field className={is_submitted && error.last_name ? 'requiredWithBgColor error' : ''}>
                      <label>
                        Last Name{' '}
                        <span className={is_submitted && error.last_name ? 'req-alert' : 'req-alert_normal'}>
                          (required)
                        </span>
                      </label>
                      <input
                        autoComplete='new-password'
                        name='last_name'
                        id='last_name'
                        onChange={e => this.input_change_handler(e)}
                        value={user_details.name.last_name ? user_details.name.last_name : ''}
                        type='text'
                        maxLength={50}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column tablet={8} computer={4}>
                    <Form.Field
                      className={
                        is_submitted && (!user_details.email || error.email) ? 'requiredWithBgColor error' : ''
                      }
                    >
                      <label>
                        Email{' '}
                        <span
                          className={
                            is_submitted && (!user_details.email || error.email) ? 'req-alert' : 'req-alert_normal'
                          }
                        >
                          (required)
                        </span>
                      </label>
                      <input
                        autoComplete='new-password'
                        name='email'
                        id='email'
                        onChange={e => this.input_change_handler(e)}
                        value={user_details.email ? user_details.email : ''}
                        type='text'
                        maxLength={50}
                      />
                      {is_submitted && error.email && (
                        <div className='requiredText'>{global_constants.constants.valid_email}</div>
                      )}
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column tablet={8} computer={4}>
                    <Form.Field
                      className={
                        is_submitted && (!user_details.phone || error.phone) ? 'requiredWithBgColor error' : ''
                      }
                    >
                      <label>
                        Phone{' '}
                        <span
                          className={
                            is_submitted && (!user_details.phone || error.phone) ? 'req-alert' : 'req-alert_normal'
                          }
                        >
                          (required)
                        </span>
                      </label>
                      <MaskedInput
                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        className={
                          is_submitted && (!user_details.phone || error.phone)
                            ? 'form-control req-background-inp'
                            : 'form-control'
                        }
                        placeholder='(___) ___-____'
                        guide={true}
                        name='phone'
                        id='phone'
                        autoComplete='new-password'
                        showMask={true}
                        onChange={e => this.input_change_handler(e)}
                        value={user_details.phone ? user_details.phone : ''}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column tablet={8} computer={4}>
                    <Form.Field className={is_submitted && error.user_name ? 'requiredWithBgColor error' : ''}>
                      <label>
                        User Name{' '}
                        <span className={is_submitted && error.user_name ? 'req-alert' : 'req-alert_normal'}>
                          (required)
                        </span>
                      </label>
                      <input
                        autoComplete='new-password'
                        name='user_name'
                        id='user_name'
                        onChange={e => this.input_change_handler(e)}
                        value={user_details.user_name ? user_details.user_name : ''}
                        type='text'
                        maxLength={50}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column tablet={8} computer={4}>
                    <Form.Field
                      className={
                        this.page_state == 'add' && is_submitted && error.password ? 'requiredWithBgColor error' : ''
                      }
                    >
                      <label>
                        Password{' '}
                        {this.page_state == 'add' ? (
                          <span className={is_submitted && error.password ? 'req-alert' : 'req-alert_normal'}>
                            (required)
                          </span>
                        ) : (
                          ''
                        )}
                      </label>

                      <Form.Input
                        autoComplete='new_password'
                        className='concealInput'
                        type='text'
                        disabled={this.render_disable_password_for_federated_user()}
                        name='txt_password'
                        value={user_details.password || ''}
                        onFocus={this.toaster_for_password_strength}
                        error={error.password}
                        onBlur={this.toaster_for_password_strength}
                        onChange={this.on_password_form_change}
                      />

                      {user_details.password != undefined &&
                      user_details.password != null &&
                      user_details.password != '' ? (
                        strength < 80 ? (
                          <Progress error percent={100} active>
                            Weak Password{' '}
                          </Progress>
                        ) : strength < 95 ? (
                          <Progress warning percent={100} active>
                            {' '}
                            Moderate Password
                          </Progress>
                        ) : (
                          <Progress success percent={100} active>
                            {' '}
                            Strong Password
                          </Progress>
                        )
                      ) : (
                        ''
                      )}
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column tablet={8} computer={4}>
                    <Form.Field
                      className={
                        this.state.error.user_type_id
                          ? 'advance-quick-search case-reg-form referring-physician requiredWithBgColor error'
                          : 'advance-quick-search case-reg-form referring-physician'
                      }
                    >
                      <label>
                        User Type{' '}
                        <span
                          className={is_submitted && this.state.error.user_type_id ? 'req-alert' : 'req-alert_normal'}
                        >
                          (required)
                        </span>
                      </label>
                      <AdvancedControl
                        onGridRowSelection={row => this.on_advanced_grid_row_select(row, this.user_type_search_enum)}
                        gridConfig={this.state.user_type_grid_data}
                        controlId={'ADMIN_SEARCH_USER_TYPE'}
                        onSearch={param => this.on_advance_search(param, this.user_type_search_enum)}
                        search_type={constants.users.user_type_header_id}
                        headerIdForGridTabNavigation={constants.users.user_type_header_id}
                      />
                      <AutoSearchComponent
                        errorClass={''}
                        getInputValue={e => this.get_value(e, this.user_type_search_enum)}
                        default_value={this.state.selected_user_type}
                        errorMessage={'No Record Found !'}
                        prepareRenderList={this.renderResult}
                        getList={this.get_user_type_list}
                        prepareDataList={data => this.prepare_suggestion(data, this.user_type_search_enum)}
                        selectresult={val => this.on_item_selection(val, this.user_type_search_enum)}
                        is_disabled={false}
                        show_clear_search={true}
                        clear_search={() => this.clear_quick_search(this.user_type_search_enum)}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column tablet={8} computer={4}>
                    <Form.Field
                      className={
                        this.state.error.user_role_id
                          ? 'advance-quick-search case-reg-form referring-physician requiredWithBgColor error'
                          : 'advance-quick-search case-reg-form referring-physician'
                      }
                    >
                      <label>
                        Role{' '}
                        <span
                          className={is_submitted && this.state.error.user_role_id ? 'req-alert' : 'req-alert_normal'}
                        >
                          (required)
                        </span>
                      </label>
                      <AdvancedControl
                        onGridRowSelection={row => this.on_advanced_grid_row_select(row, this.role_search_enum)}
                        gridConfig={this.state.role_grid_data}
                        controlId={'ADMIN_SEARCH_ROLE'}
                        onSearch={param => this.on_advance_search(param, this.role_search_enum)}
                        search_type={constants.users.role_header_id}
                        headerIdForGridTabNavigation={constants.users.role_header_id}
                      />

                      <AutoSearchComponent
                        errorClass={''}
                        getInputValue={e => this.get_value(e, this.role_search_enum)}
                        default_value={this.state.selected_role}
                        errorMessage={'No Record Found !'}
                        prepareRenderList={this.renderResult}
                        getList={this.get_role_list}
                        prepareDataList={data => this.prepare_suggestion(data, this.role_search_enum)}
                        selectresult={val => this.on_item_selection(val, this.role_search_enum)}
                        is_disabled={false}
                        show_clear_search={true}
                        clear_search={() => this.clear_quick_search(this.role_search_enum)}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                      <Checkbox
                        name='is_override_charge_entry_supply'
                        id='is_override_charge_entry_supply'
                        onChange={e => this.checkbox_handler(e)}
                        checked={
                          user_details.is_override_charge_entry_supply
                            ? user_details.is_override_charge_entry_supply
                            : false
                        }
                        label={'Override Charge Entry Supply'}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                      <Checkbox
                        name='is_allow_outside_access'
                        id='is_allow_outside_access'
                        onChange={e => this.checkbox_handler(e)}
                        checked={user_details.is_allow_outside_access ? user_details.is_allow_outside_access : false}
                        label={'Allow Outside Access'}
                      />
                    </Form.Field>
                  </Grid.Column>
                  { this.render_allow_access_r4_company_overrides() }
                  <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                      <Checkbox
                        name='is_active'
                        id='is_active'
                        onChange={e => this.checkbox_handler(e)}
                        checked={user_details.is_active || false}
                        label={'Is Active'}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid>
                {/*App Permission*/}
                <div
                  className='patient-search-form patient_search_bottom_padding'
                  id='search-criteria-container'
                  style={user_css.app_permission}
                >
                  <Grid className='headerGrid' style={user_css.headerGrid}>
                    <Grid.Column computer={16}>
                      <h3 className='ui header left aligned'>{'App Permission'}</h3>
                    </Grid.Column>
                  </Grid>
                  <Grid style={{ marginTop: 0, marginBottom: 0 }}>
                    <Grid.Column computer={16}>
                      <Grid.Row>
                        <AppPermission
                          onRef={instance => {
                              this.app_permission_component_ref = instance;
                          }}
                          company_permission_list={company_permission}
                          update_additional_permission={this.state.update_additional_permission}
                          has_default_company={this.page_state == 'edit'}
                          can_select_permission_of_company={this.state.can_select_permission_of_company}
                          is_sys_admin_or_customer={this.state.is_sys_admin_or_customer}
                          can_change_default_company={this.state.is_sys_admin_or_customer != this.user_type_customer}
                          selected_user_type={this.state.selected_user_type}
                          selected_role_type={this.state.selected_role}
                        />
                      </Grid.Row>
                    </Grid.Column>
                  </Grid>
                </div>

                {/*Additional Permission and IP Permission */}
                <Grid>
                  <Grid.Column tablet={16} computer={8}>
                    {/*Additional Permission*/}
                    <AdditionalPermission
                      onRef={instance => {
                        this.additional_permission_component_ref = instance;
                      }}
                      additional_permission_list={additional_permission}
                      update_additional_permission={this.state.update_additional_permission}
                    />
                  </Grid.Column>
                  <Grid.Column tablet={16} computer={8}>
                    {/*IPPermissionComponent*/}
                    <IPPermissionComponent
                      onRef={instance => {
                        this.ip_address_component_ref = instance;
                      }}
                      title='IP Permissions'
                      ip_list={ip_address}
                      get_parent_ip_list={this.state.update_additional_permission}
                      additional_style={user_css.user_ip_grid}
                      form_submited={this.state.is_submitted}
                      default_enabled={true}
                    />
                  </Grid.Column>
                </Grid>
              </div>
            </Form>
          </div>
          <div
            className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
            id='applicationFooterSticky'
            style={user_css.footerArea}
          >
            <Button id='cancel_button_id' type='button' onClick={() => this.cancel_handler()} basic>
              Cancel
            </Button>
            <Button
              type='submit'
              onKeyDown={set_focus_to_app_header}
              onClick={e => this.save_handler(e, false)}
              id='save_button_id'
              primary
              style={user_css.save_button}
              disabled={this.state.is_save_button_disabled}
            >
              Save
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// Css Style
const user_css = {
  user_id: {
    paddingBottom: 0
  },
  headerGrid: {
    marginTop: 0,
    marginBottom: 0,
    paddingRight: 15
  },
  wrapper: {
    borderTop: 0,
    marginBottom: 0,
    width: '100%'
  },
  common_forms: {
    paddingTop: 0,
    height: '100%',
    paddingBottom: 0,
    display: 'block'
    // flexDirection: 'column',
    // width:'100%'
  } as React.CSSProperties,
  app_permission: {
    paddingBottom: 0,
    flexBasis: 'auto',
    borderColor: '#cccccc',
    borderStyle: 'solid',
    borderWidth: '2px 1px 1px',
    margin: '30px 0 30px 0px',
    background: '#fff',
    padding: '1px 15px',
    fontSize: 22
  },
  ui_grid: {
    borderColor: '#cccccc',
    borderStyle: 'solid',
    borderWidth: '2px 1px 1px',
    background: '#fff',
    padding: 0,
    marginTop: 15
  },
  footerArea: {
    paddingLeft: 0,
    paddingBottom: 0,
    marginBottom: 12,
    paddingRight: 25,
    paddingTop: 20
  },
  scrollbar_area: {
    height: '100%',
    paddingRight: 15
  } as React.CSSProperties,
  save_button: {
    marginRight: 0
  },
  postionRe: {
    margin: 0,
    padding: 0,
    display: 'inline-block',
    width: '120px',
    position: 'relative'
  } as React.CSSProperties,
  allText: {
    position: 'absolute',
    top: '1px',
    left: '90px'
  } as React.CSSProperties,
  user_ip_grid: {
    borderColor: '#cccccc',
    borderStyle: 'solid',
    borderWidth: '2px 1px 1px',
    background: '#fff',
    padding: 0,
    height: '100%'
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

const mapStateToProps = state => {
  return {
    user_login_details: state.user_login_details,
    ff_argos_b33306_restrict_access_to_r4_with_overrides: get_launch_darkly_key_value(state.launch_darkly, user_constants.remove_access_to_r4_with_overrides.argosB33306RemoveAccessToR4WithOverridesFF),
    ff_magnus_plat1702_disable_password_reset_for_federated_user: get_launch_darkly_key_value(state.launch_darkly, user_constants.federated_user.disablePasswordResetForFederatedUser)
  };
};

export default reduxForm({
  form: 'reset'
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserAddEditComponent)
);
