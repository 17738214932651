import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import { left_menu_permission } from '../../shared/action/shared_action';
import * as local_storage from '../../shared/local_storage_utility';
import * as session_storage from '../../shared/session_storage_utility';
import { get_lauch_darkley_key_value, setup_tabId_in_localStorage } from '../../shared/utility';
import CryptoService from './../../shared/crypto_service/crypto_service';
import LaunchDarkley from './../../shared/launch_darkley/launch_darkley';
var pendoInit = require('../../../../config/pendo_init');
import * as login_constants from '../../../app/login/login_constants'
import { oauth_authentication } from '../action/callback_action';
import { clear_cache, get_master_data, get_master_data_by_type } from '../../login/action/login_action';
import { magnus_plat_5681_isolate_auth0 } from '../../feature_flip_constant';
import * as global_constant from '../../global_constants';

export class CallbackComponent extends React.Component<any, any> {
    constructor(props) {
        super(props);
        // hard reload if new build notification
        if (session_storage.get('hard_reload_application')) {
            session_storage.remove('hard_reload_application');
            window.location.reload(true);
        }
        session_storage.clear_all();
        local_storage.clear_all();
        this.state = {
            loading: false,
            is_sso_login: false,
            message: ''
        };
    }

    get_cookie = (name) => {
        const nameLenPlus = name.length + 1;
        return (
            document.cookie
                .split(';')
                .map((c) => c.trim())
                .filter((cookie) => {
                    return cookie.substring(0, nameLenPlus) === `${name}=`;
                })
                .map((cookie) => {
                    return decodeURIComponent(cookie.substring(nameLenPlus));
                })[0] || null
        );
    };

    // Left menu data
    get_menu_data = async () => {
        await this.props.menu_permission(
            this.props.user_login_details.user_data.data.user_id,
            this.props.user_login_details.user_data.data.company_id,
            this.props.user_login_details.user_data.data.token_details.access_token
        );
    };

    get_master_data = async () => {
        try {
            this.props.get_master_data(
                login_constants.MasterData.all,
                this.props.user_login_details.user_data.data.user_id,
                this.props.user_login_details.user_data.data.token_details.access_token
            );
            this.props.get_master_data_by_type(
                login_constants.MasterData.all,
                this.props.user_login_details.user_data.data.user_id,
                this.props.user_login_details.user_data.data.token_details.access_token
            );
        } catch (e) { }
    };

    on_callback_page_load = async () => {
        LaunchDarkley.update(async () => {
            if (this.props.magnusPlat5681IsolateAuth0) {
                const queryParams = new URLSearchParams(window.location.search)
                const code = queryParams.get("code")
                const state = queryParams.get("state")

                if (code != null && state != null) {
                    await this.login_via_sso(code, state);
                }
                else {
                    this.setState({
                        message: 'The code and state is required',
                        loading: false
                    });
                }
            } else {
                this.setState({
                    loading: true
                });
            }
        });
    };

    login_via_sso = async (code, state) => {
        this.props.clear_cache();
        this.setState({
            is_sso_login: true,
            loading: true,
        });

        sessionStorage['sso_user_login'] = true;

        setup_tabId_in_localStorage();

        let is_main_window = session_storage.get_raw('is_main_window');

        if (is_main_window == undefined || is_main_window == 'undefined') {
            session_storage.set_raw('is_main_window', true);
        }
        await CryptoService.get_encryption_key();
        await this.props.oauth_authentication(code, state);
        if (
            this.props.user_login_details &&
            this.props.user_login_details.user_data &&
            this.props.user_login_details.user_data.status == 1
        ) {
            await this.get_menu_data();
            this.get_master_data();
            pendoInit();

            LaunchDarkley.update(() => {
                if (this.props.rsiB29536Dashboard) {
                    this.props.history.push('/dashboard');
                } else {
                    this.props.history.push('/patient_search');
                }
            });
        }
        else {
            const result = this.props.user_login_details.user_data.data.result;
            if (result && result.code === global_constant.code_sso_user_not_mapped) {
                sessionStorage[global_constant.universal_profile_session] = JSON.stringify(result);
                this.props.history.push(result.redirectUrl);
            }
            this.setState({
                message: this.props.user_login_details.user_data.messages[0].message,
                loading: false
            });
        }
    }

    componentDidMount() {
        CryptoService.get_encryption_key();
        local_storage.set('open_tab_count', 0);
        this.on_callback_page_load();
    }

    render() {
        return (
            <React.Fragment>
                <Dimmer active={this.state.loading}>
                    <Loader size="massive">Loading</Loader>
                </Dimmer>
                {this.state.message}
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        oauth_authentication: oauth_authentication,
        clear_cache: clear_cache,
        get_master_data: get_master_data,
        get_master_data_by_type: get_master_data_by_type,
        menu_permission: left_menu_permission
    }, dispatch)
}

const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        rsiB29536Dashboard: get_lauch_darkley_key_value(state.launch_darkly, 'rsiB29536Dashboard'),
        magnusPlat5681IsolateAuth0: get_lauch_darkley_key_value(state.launch_darkly, magnus_plat_5681_isolate_auth0)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CallbackComponent);