import * as React from 'react';
import CurrencyFormatter from '../../shared/component/currency_formatter';

interface IProps {
    id?: string,
    className?: string,
    style?: object,
    onChange: Function,
    defaultValue?: { start: string | number, end: string | number },
    placeHolder?: { start: string, end: string },
    maxLength?: number,
    isRequired?: boolean,
    disabled?: boolean,
}


/** 
* Dual Currency Input UI component
*/
class DualCurrencyInput extends React.PureComponent<IProps, any> {

    static defaultProps: Partial<IProps> = {
        defaultValue: { start: '', end: '' },
        placeHolder: { start: '', end: '' },
        className: ''
    };

    // Get innitalise data when component created
    constructor(props) {
        super(props);
        this.state = {
            start: props.defaultValue.start || '',
            end: props.defaultValue.end || '',
            focused_start: false,
            focused_end: false,
        }
    }

    componentDidUpdate(previousProps, previousState) {
        if (JSON.stringify(previousProps.defaultValue) !== JSON.stringify(this.props.defaultValue)) {
            this.setState({
                start: this.props.defaultValue.start || '',
                end: this.props.defaultValue.end || '',
            })
        }


        if (this.props.disabled != previousProps.disabled) {
            this.setState({
                disabled: this.props.disabled
            });
        }
    }

    handle_change = (value, name) => {
        this.setState({ [name]: value },
            () => {
                this.props.onChange({
                    start: this.state.start || '',
                    end: this.state.end || '',
                }, null)
            });
    }

    render_separator = () => (<div className="range-input-separator-view">
        <span aria-label="to" className="range-input-separator">
            <span role="img"
                style={{ fontSize: '12px' }}
                aria-label="swap-right"
                className="swap-right">
                <svg viewBox="0 0 1024 1024" focusable="false" data-icon="swap-right" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                    <path d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path>
                </svg>
            </span>
        </span>
    </div>);

    render_input_start = () => {
        return (<div className="range-input-inner-container">
            <CurrencyFormatter
                id={"uid-dual-start"}
                name="start"
                prefix='$'
                maxLength={15}
                defaultValue={this.state.start}
                allowNegative={true}
                className={'numberFormat'}
                placeHolder={`${this.props.placeHolder.start || 'From'}`}
                onChange={(value) => this.handle_change(value, 'start')}
                onFocus={e => this.setState({ focused_start: true })}
                onBlur={e => this.setState({ focused_start: false })}
            />
        </div>);
    }

    render_input_end = () => {
        return (<div className="range-input-inner-container">
            <CurrencyFormatter
                id={"uid-dual-end"}
                name="end"
                prefix='$'
                maxLength={15}
                defaultValue={this.state.end}
                allowNegative={true}
                className={'numberFormat'}
                placeHolder={`${this.props.placeHolder.end || 'To'}`}
                onChange={(value) => this.handle_change(value, 'end')}
                onFocus={e => this.setState({ focused_end: true })}
                onBlur={e => this.setState({ focused_end: false })}
            />
        </div>);
    }

    is_active = () => {
        if (this.state.focused_start || this.state.focused_end) {
            return 'range-input-values-focus';
        }

        return '';
    }

    /**
     * UI render function
     */
    render() {
        return (<div
            id={this.props.id || 'uid-range-input'}
            className={`range-input-container ${this.props.className} ${this.is_active()}`}>
            {this.render_input_start()}
            {this.render_separator()}
            {this.render_input_end()}
        </div>);
    }
};

export default DualCurrencyInput;