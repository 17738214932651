import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import { Button, Dimmer, Form, Grid, Input, Loader } from 'semantic-ui-react';
import * as export_print_utility from '../../../reports/util/export_print_utility';
import AdvancedControl from '../../../shared/component/advanced_control';
import AutoSearchComponent from '../../../shared/component/auto_search_component';
import DatePicker from '../../../shared/component/date_picker_component';
import GridView from '../../../shared/component/grid';
import SelectionComponent from '../../../shared/component/selection_component';
import * as session_storage from '../../../shared/session_storage_utility';
import { handle_click_on_enter, set_focus_on_element_with_id, set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import { fixSSN, phone_format, format_zip_code, get_columns, get_lauch_darkley_key_value } from '../../../shared/utility';
import * as constant_action from '../action/constants_action';
import * as constants from '../constants';
import * as global_constants from '../../../global_constants';
import { toastr as toaster } from 'react-redux-toastr';
import { log_error } from '../../../shared/action/shared_action';
import * as local_storage from '../../../shared/local_storage_utility';
import FeatureFlipUtils from '../../../shared/feature_flip_helper';
import { is_user_partner_customer_and_company_revEquip_revServe } from '../../../shared/utility';
import { dateFormatter, dateTimeFormatter } from '../../../reports/report_constants';
import * as ReportConstants from '../../../reports/report_constants';
import { providerBillingSettingsFeatures, ProviderCredentials } from '../../../admin/constants/constants';
import * as PrintExportConstants from '../../../shared/audit/print_export_constants';
import { ChargesBillingAuditLogEpicsFeature } from "../../../admin/constants/constants";
import { claim_adjustment_reason_code_company_overrides } from "../../../feature_flip_constant"

class ConstantSearchComponent extends React.Component<any, any> {
    advance_controls: any;
    grid_header_height: number;
    constructor(props) {
        super(props);
        // Define initial state value.
        this.state = {
            patient_master_data: this.props.user_login_details.formated_master_data,
            show_grid: false,
            show_render: true,
            search_criteria: {},
            is_loading: false,
            shown: false,
            show_advance_modal: true,
            grid_conf: {
                isPagination: true,
                gridAutoHeight: true,
                selectionType: 'single',
                paginationPageSize: 20
            },
            grid_rows_count: 0,
            advance_search_value: {},
            grid_data: [],
            is_search_button_disabled: false,
            is_add_edit_allowed: true,
            is_new_button_visible: false
        };
    }

    // Define initial properties and its value.
    _is_mounted = false;
    current_user_type: global_constants.enum_user_type_r3_id = 1;
    company_service_type = 0;
    page_name: string = '';
    page_metadata: any;
    search_criteria: any = {};
    token: string = '';
    row_data = [];
    is_search_ui: any = false;
    code_id: number = 0;
    is_grid_visible = false;
    title = '';
    current_date: any;
    company_name: any;
    total_column_width: number = 0;
    menu_permission_object: Array<any> = [{ view: true }];
    reportId: string = '0';

    // Fetching the page_name and state.
    UNSAFE_componentWillMount = () => {
        var auth_data = local_storage.get("auth_data");
        if (auth_data && auth_data.user_type_r3_id) {
            this.current_user_type = auth_data.user_type_r3_id;
            this.company_service_type = auth_data.company_service_type;
        }
        this.get_page_metadata();
    };

    componentDidMount = () => {
        this._is_mounted = true;
        document.body.classList.add('admin-framework');
        this.new_button_visible_status();
        this.set_default_foucs();
        this.handle_tab_on_search();
    };

    //Use to load multiple page with same component
    componentDidUpdate = () => {
        if (this.page_name != this.props.match.params.page_name) {
            this.reset_all_states_props();
            this.get_page_metadata();
            this.new_button_visible_status();
            this.set_default_foucs();
            this.handle_tab_on_search();
        }
    };

    componentWillUnmount = () => {
        document.body.classList.remove('admin-framework');
        if (this.props.history.location && constants.page_list.indexOf(this.page_name) > -1) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[2] !== this.page_name && session_storage.remove(`constant_${this.page_name}_criteria`);
        }
    };

    set_default_foucs = () => {
        let elem = document.getElementById('search-criteria-container');
        let all_form_elem = elem.getElementsByTagName('input');
        all_form_elem[0].focus();
    };

    handle_tab_on_search = () => {
        setTimeout(() => {
            let searchButton = document.getElementById('search_button_id');
            let self = this;
            if (searchButton != null && searchButton != undefined) {
                searchButton.addEventListener('keydown', function (event) {
                    // apply a check where grid is empty
                    if (!event.shiftKey && (self.row_data == null || (self.row_data.length == 0 && !self.state.show_grid))) {
                        set_focus_to_app_header(event);
                    }
                });
            }
            
        }, 200);
    };

    on_grid_out = () => {
        set_focus_on_element_with_id('app_header_dropdown');
    };

    //Use to reset the component
    reset_all_states_props = () => {
        this.set_default_search_data(this.page_metadata.control_data);
        this.setState({
            show_grid: false,
            search_criteria: this.search_criteria
        });
        this.is_search_ui = false;
        this.is_grid_visible = false;
        session_storage.remove(`constant_${this.page_name}_criteria`);
    };

    //Use to fetch params and page metadata
    get_page_metadata = () => {
        this.page_name = this.props.match.params.page_name;
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        let constant_page_search = session_storage.get(`constant_${this.page_name}_criteria`);
        //Verifying page_name is correct or not.
        if (constants.page_list.indexOf(this.page_name) > -1) {
            this.page_metadata = constants[this.page_name];
            this.search_criteria = (constant_page_search && constant_page_search.search_criteria) || {};
            if (constant_page_search && constant_page_search.search_criteria) {
                this.search_handler(this);
            } else {
                this.set_default_search_data(this.page_metadata.control_data);
            }
            //Case: When redirecting from View to Search
            this.setState({
                is_add_edit_allowed: this.checkUpdatePermission(),
                show_render: true,
                advance_search_value:
                    constant_page_search && constant_page_search.advance_search_value && constant_page_search.advance_search_value
                        ? session_storage.get(`constant_${this.page_name}_criteria`).advance_search_value
                        : {}
            });
        } else {
            //Redirecting user to back state, as page_name is not correct
            this.setState({
                show_render: false
            });
            this.props.history.goBack();
        }
    };

    checkUpdatePermission = () => {
        let is_add_edit_allowed = true;
        if (this.page_metadata.edit_permission_name) {
            this.menu_permission_object = this.props.shared_details.left_menu_data.data.filter((menu) => {
                return menu.name === this.page_metadata.edit_permission_name;
            });
            if (this.menu_permission_object.length === 0 || !this.menu_permission_object[0].view) {
                is_add_edit_allowed = false;
            }
        }
        return is_add_edit_allowed;
    };

    ////////////////////////////////////////////////////////////////////////////////Advance search////////////////////////////////////
    on_advance_search_click = (data_row, value) => {
        let { grid_data } = this.state
        let queryString = Object.keys(value)
            .map(key => key + '=' + value[key])
            .join('&');
        let url = `${data_row.advance_search}?${queryString}`;
        // Search function.     
        constant_action.get_data(this.token, url).then(response => {
            if (response.data) {
                let row_data = response.data.data ? response.data.data : [];
                grid_data[data_row.name] = {
                    ...this.state.grid_conf,
                    rows: row_data,
                    column: constants[data_row.lookup_id].column_def,
                    messages: 'No Record Available.'
                }
                this.setState({
                    grid_data
                });
            }
        });
    };

    get_quick_search_data_list = async (data_row, input_value) => {
        let value = input_value ? input_value.trim() : input_value;
        let url = `${data_row.quick_search}?keyword=${value}&page_size=${global_constants.constants.Quick_Search_Suggestion_List_Size}`;
        return await constant_action.get_data(this.token, url);
    };

    //Prepare suggestion list to show in Quick Search result
    prepare_suggestion = (data, data_row) => {
        let formattedList = [];
        var data = data.data;
        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                let item = data[i];
                let label = data_row.suggestion_label_list;
                var selected_data = '';
                for (let key of label) {
                    if (item.hasOwnProperty(key) && item[key] != null) {
                        selected_data = selected_data != '' ? selected_data + '-' + item[key] : item[key] + '';
                    }
                }
                let selected_label = data_row.label_list;
                var selected__label_data = '';
                for (let key of selected_label) {
                    if (item.hasOwnProperty(key) && item[key] != null) {
                        selected__label_data = selected__label_data != '' ? selected__label_data + '-' + item[key] : item[key] + '';
                    }
                }
                formattedList.push({
                    selected_label: selected__label_data,
                    label: selected_data,
                    title: `${item[data_row.advance_search_field.title]}-${item[data_row.advance_search_field.id]}`,
                    name: item[data_row.advance_search_field.label],
                    code: item[data_row.advance_search_field.title],
                    id: item[data_row.advance_search_field.id]
                });
            }
        }
        return formattedList;
    };

    render_result = props => {
        return (
            <div key={props.id} tabIndex={0} className='item_auto_search'>
                <div key={props.title} className='fs_13'>
                    <span>{props.label}</span>
                </div>
            </div>
        );
    };

    on_item_selection = (selected_row, data_row) => {
        let { advance_search_value } = this.state;
        this.search_criteria[data_row.name] = selected_row.id;
        advance_search_value[data_row.name] = {
            label: selected_row.selected_label,
            title: selected_row.title
        };
        if (this._is_mounted) {
            this.setState({
                advance_search_value
            });
        }
        this.closeModal();
    };

    //close the modal on selection of patient through quick search
    closeModal = () => {
        if (this._is_mounted) {
            this.setState({ show_advance_modal: false });
        }
    };

    clear_quick_search = field => {
        this.search_criteria[field] = '';
        let { advance_search_value } = this.state;
        advance_search_value[field] = { label: '', title: '' };
        if (this._is_mounted) {
            this.setState({
                advance_search_value
            });
        }
    };

    on_grid_row_selection = (selected_row, data_row) => {
        let { advance_search_value } = this.state;
        this.search_criteria[data_row.name] = selected_row[data_row.search_criteria_field];
        let label = data_row.label_list;
        var selected_data = '';
        for (let key of label) {
            if (selected_row.hasOwnProperty(key) && selected_row[key] != null) {
                selected_data = selected_data != '' ? selected_data + '-' + selected_row[key] : selected_row[key] + '';
            }
        }
        advance_search_value[data_row.name] = {
            label: selected_data,
            title: selected_row[data_row.advance_search_field.id]
        };
        if (this._is_mounted) {
            this.setState({
                advance_search_value
            });
        }
        this.closeModal();
    };

    ////////////////////////////////////////////////////////////////////////////////Advance search////////////////////////////////////

    //Function used to set the input controls.
    set_controls = (data_row, index) => {
        switch (data_row.control_type) {
            case 'drop_down_list':
                return (
                    <Form.Field>
                        {data_row.label && (
                            <label>
                                {data_row.label}
                                {data_row.is_required && <span> (required)</span>}
                            </label>
                        )}
                        <SelectionComponent
                            id={data_row.name}
                            placeHolder={!data_row.is_placeholder ? 'Select' : null}
                            hidden={true}
                            options={
                                ((typeof data_row.is_state != 'undefined' && data_row.is_state) || data_row.name == 'state')
                                    ? (data_row.with_select ? constants.option_formater([{ "text": "Select", "value": " ", "key": 'Select' }
                                        , ...this.state.patient_master_data.states],
                                        'text',
                                        'value',
                                        false, // to add blank object
                                        false // Sorted order
                                    )
                                        : constants.option_formater(
                                            this.state.patient_master_data.states,
                                            'text',
                                            'value',
                                            true, // to add blank object
                                            false // Sorted order
                                        ))
                                    : data_row.name == 'speciality_id'
                                        ? constants.option_formater(this.state.patient_master_data.referring_specialty, 'specialty_desc', 'specialty_id')
                                        : data_row.name == 'medical_group_id'
                                            ? constants.option_formater(this.state.patient_master_data.medical_group, 'medical_group', 'medical_group_id')
                                            : data_row.master_data
                            }
                            onChange={(value, e) => {
                                this.dropdown_change_handler(e);
                            }}
                            defaultValue={this.search_criteria[data_row.name]}
                            style={'dropdown-options-wrap'}
                        />
                    </Form.Field>
                );
            case 'date':
                return (
                    <Form.Field>
                        {data_row.label && (
                            <label>
                                {data_row.label}
                                {data_row.required && <span> (required)</span>}
                            </label>
                        )}
                        <DatePicker
                            id={data_row.name}
                            date_update={date_object => this.calendar_change_handler(data_row, date_object)}
                            date={this.search_criteria[data_row.name]}
                            is_required={data_row.is_required}
                        />
                    </Form.Field>
                );
            case 'checkbox':
                return (
                    <Form.Field className='ui checkbox'>
                        <div style={data_row.custom_style}>
                            <input
                                type='checkbox'
                                name={data_row.name}
                                id={data_row.name}
                                checked={this.search_criteria[data_row.name] ? this.search_criteria[data_row.name] : false}
                                onChange={e => this.input_change_handler(data_row, e)}
                            />{' '}
                            <label className='chkbx_label' htmlFor={data_row.name}>
                                {data_row.label}
                            </label>
                        </div>
                    </Form.Field>
                );
            case 'textarea':
                return (
                    <Form.Field>
                        {data_row.label && <label>{data_row.label}</label>}
                        <TextareaAutosize
                            name={data_row.name}
                            id={data_row.name}
                            autoComplete='new-password'
                            value={this.search_criteria[data_row.name] ? this.search_criteria[data_row.name] : ''}
                            onChange={e => this.input_change_handler(data_row, e)}
                            className='textarea-single-row'
                            maxLength={data_row.max_length ? data_row.max_length : 250}
                        />
                    </Form.Field>
                );
            case 'lookup_search':
                return (
                    <Form.Field className='advance-quick-search'>
                        {data_row.label && <label>{data_row.label}</label>}
                        <AdvancedControl
                            id={data_row.name}
                            showModal={this.state.show_advance_modal}
                            onGridRowSelection={row => {
                                this.on_grid_row_selection(row, data_row);
                            }}
                            gridConfig={this.state.grid_data[data_row.name]}
                            controlId={data_row.lookup_id}
                            onSearch={value => {
                                this.on_advance_search_click(data_row, value);
                            }}
                        />
                        <AutoSearchComponent
                            control_id={data_row.lookup_id}
                            getInputValue={null}
                            default_value={this.state.advance_search_value[data_row.name] || { title: '', label: '' }}
                            errorMessage={'No Record Found !'}
                            prepareRenderList={this.render_result}
                            getList={input_value => {
                                return this.get_quick_search_data_list(data_row, input_value);
                            }}
                            prepareDataList={data => this.prepare_suggestion(data, data_row)}
                            selectresult={row => this.on_item_selection(row, data_row)}
                            show_clear_search={true}
                            clear_search={() => this.clear_quick_search(data_row.name)}
                            errorClass={
                                !this.state.search_criteria[data_row.name] &&
                                    data_row.is_required &&
                                    this.state.is_submitted &&
                                    this.state.is_error_control[data_row.name]
                                    ? 'search-error-thin'
                                    : ''
                            }
                        />
                    </Form.Field>
                );
            default:
                return (
                    <Form.Field>
                        {data_row.label && <label>{data_row.label}</label>}
                        <Input
                            maxLength={data_row.max_length}
                            name={data_row.name}
                            type={data_row.control_type}
                            id={data_row.name}
                            onChange={e => this.input_change_handler(data_row, e)}
                            value={this.search_criteria[data_row.name] ? this.search_criteria[data_row.name] : ''}
                            style={{ width: '100%' }}
                        />
                    </Form.Field>
                );
        }
    };

    // Data formatter
    format_data = (rows, columns) => {
        let formatted_data = rows.map(row_data => {
            columns.filter(col_data => {
                switch (col_data.type) {
                    case 'currency':
                        row_data[col_data.field] =
                            row_data[col_data.field] != null || row_data[col_data.field] != undefined
                                ? constants.currencyFormatter(row_data[col_data.field])
                                : "";// constants.currencyFormatter(0);
                        break;
                    case 'boolean': {
                        if (this.page_name === 'insurance_class' && (col_data.field === 'auth_required' || col_data.field === 'provider_credentials_required' || col_data.field === 'audit_required')) {
                            row_data[col_data.field] = row_data[col_data.field] ? row_data[col_data.field] : false;
                        }
                        else {
                            row_data[col_data.field] = row_data[col_data.field] ? 'Active' : 'Inactive';
                        }
                    }
                        break;
                    case 'number':
                        row_data[col_data.field] = row_data[col_data.field];
                        break;
                    case 'string':
                        {
                            row_data[col_data.field] = row_data[col_data.field];
                            if (col_data.field === 'icd_type') {
                                row_data[col_data.field] = row_data[col_data.field] == 0 ? 'ICD-10' : 'ICD-9';
                            } else if (col_data.field == "revenue_center_description" || col_data.field == "revenue_center_code" || col_data.field == "proc_type_description") {
                                row_data[col_data.field] = row_data[col_data.field] == null ? '' : row_data[col_data.field];
                            }
                        }
                        break;
                    case 'phone':
                        row_data[col_data.field] = row_data[col_data.field] ? phone_format(row_data[col_data.field]) : row_data[col_data.field];
                        break;
                    case 'zip':
                        row_data[col_data.field] = row_data[col_data.field] ? format_zip_code(row_data[col_data.field]) : row_data[col_data.field];
                        break;
                    case 'ssn':
                        row_data[col_data.field] = row_data[col_data.field] ? fixSSN(row_data[col_data.field]) : row_data[col_data.field];
                        break;
                    case 'date':
                        row_data[col_data.field] = row_data[col_data.field] ? dateFormatter(row_data[col_data.field]) : '';
                        break;
                    case 'dateTime':
                        row_data[col_data.field] = row_data[col_data.field] ? dateTimeFormatter(row_data[col_data.field]) : '';
                        break;
                    default:
                        row_data[col_data.field] = row_data[col_data.field];
                }
            });
            return row_data;
        });
        return formatted_data;
    };

    //created this because the above code is used for another ff
    apply_ff_again = (column_def, page_name) => {

        if (!page_name || !column_def || column_def.length == 0)
            return column_def;

        var ff_config_list = constants.ProviderCredentialsFF;

        return FeatureFlipUtils.apply_column_ff(column_def,
            ff_config_list.column_def_ff_columns, this.props.features.isProviderCredentials);
    };

    //Set the default value to controls.
    set_default_search_data = control_data => {
        this.search_criteria = {};
        control_data.map(data => {
            if (data.is_search) {
                // Set only Search controls
                switch (data.control_type) {
                    case 'checkbox':
                        this.search_criteria[data.name] = data.default_value ? true : false;
                        break;
                    case 'textbox':
                        this.search_criteria[data.name] = '';
                        break;
                    case 'drop_down_list':
                        this.search_criteria[data.name] = data.default_value;
                        break;
                    case 'lookup_search':
                        this.clear_quick_search(data.name);
                        break;
                    case 'date':
                        this.search_criteria[data.name] = null;
                        break;
                    case 'radio':
                        this.search_criteria[data.name] = false;
                        break;
                    default:
                        this.search_criteria[data.name] = '';
                }
            }
        });
    };

    //On double click of grid, it redirects user to view page.
    on_row_double_clicked = selected_row_data => {
        if (!this.state.is_add_edit_allowed) {
            return;
        }
        this.code_id = selected_row_data[this.page_name + '_id'];
        if (this.page_name == 'claim_adjustment_code' && this.props.features.claimAdjustmentReasonCodeCompanyOverrides)
        {
            this.props.history.push(`${this.page_metadata.base_url}/claim_adj_reason_code_with_comp_ov_component/${this.code_id}`);
        }
        else
        {
            this.props.history.push(`${this.page_metadata.base_url}/${this.page_name}/${this.code_id}`);
        }
        
    };

    //Input change handler.
    input_change_handler = (data_row, e) => {
        if (data_row.control_type == 'checkbox') {
            this.search_criteria[data_row.name] = e.target.checked;
        } else if (data_row.name == 'zip') {
            !isNaN(e.target.value) && (this.search_criteria[data_row.name] = e.target.value);
        } else {
            this.search_criteria[data_row.name] = e.target.value;
        }
        this.setState({
            search_criteria: this.search_criteria
        });
    };

    //Calender change handler.
    calendar_change_handler = (data_row, date_object) => {
        //this.search_criteria[id] = date_object ? date_object : null;
    };

    //Dropdown change handler.
    dropdown_change_handler = e => {
        const { id, value } = e.target;
        const { search_criteria } = this.state
        this.search_criteria[id] = value;
        search_criteria[id] = value;
        this.setState({
            search_criteria
        })
    };

    //Search button handler and used to search the data on the basis of search criteria.
    search_handler = e => {
        let url = this.page_metadata.api.search.url;
        let route = this.page_metadata.api.search.route;
        for (let key in this.search_criteria) {
            url = url.replace(
                key + '_value',
                typeof this.search_criteria[key] !== 'undefined' ? encodeURIComponent(this.search_criteria[key].toString().trim()) : ''
            );
        }
        this.setState({
            is_loading: true,
            is_search_button_disabled: true
        });
        constant_action.get_search_data(this.token, url).then(
            response => {
                if (response.data) {
                    this.row_data = response.data.data ? response.data.data : [];
                    this.row_data = this.format_data(this.row_data, this.page_metadata.column_def);
                    //apply additional ff on columns
                    this.page_metadata.column_def = this.apply_ff_again(this.page_metadata.column_def, this.page_name);
                    const grid_height = get_columns(this.row_data, this.page_metadata.column_def)
                    this.total_column_width = grid_height.total_column_width;
                    this.grid_header_height = grid_height.header_height;
                    this.is_search_ui = true;
                    this.setState({
                        show_grid: true,
                        is_loading: false,
                        is_search_button_disabled: false,
                        search_criteria: this.search_criteria,
                        grid_rows_count: this.row_data && this.row_data.length
                    });
                    session_storage.set(`constant_${this.page_name}_criteria`, {
                        search_criteria: this.search_criteria,
                        advance_search_value: this.state.advance_search_value
                    });
                } else {
                    if (response.data.messages[0].message.indexOf('<br') > -1) {
                        const toastr_options = this.show_html_content_toaster(response.data.messages[0].message);
                        toaster.error('', toastr_options);
                    } else {
                        toaster.error('', response.data.messages[0].message);
                    }
                    if (this._is_mounted) {
                        this.setState({
                            loading: false,
                            is_search_button_disabled: false
                        });
                    }
                }
            },
            error => {
                this.setState({
                    is_loading: false,
                    is_search_button_disabled: false
                });

                log_error(error);
                if (error.response.data.messages[0].message.indexOf('<br') > -1) {
                    const toastr_options = this.show_html_content_toaster(error.response.data.messages[0].message);
                    toaster.error('', toastr_options);
                } else {
                    toaster.error('', error.response.data.messages[0].message);
                }
            }
        );
    };

    //Reset button the search control.
    clear_handler = e => {
        session_storage.remove(`constant_${this.page_name}_criteria`);
        this.set_default_search_data(this.page_metadata.control_data);
        this.setState({
            show_grid: false,
            shown: false,
            search_criteria: this.search_criteria,
            grid_rows_count: 0
        });
        this.is_search_ui = false;
        this.is_grid_visible = false;
    };

    //New button handler.
    new_handler = e => {
        if (this.page_name == 'claim_adjustment_code' && this.props.features.claimAdjustmentReasonCodeCompanyOverrides) {
            this.props.history.push(`${this.page_metadata.base_url}/claim_adj_reason_code_with_comp_ov_component`);
        }
        else
        {
            this.props.history.push(`${this.page_metadata.base_url}/${this.page_name}`);
        }

        // let page_list: any = ["insurance_class"]
        // if (!page_list.includes(this.page_name))
        //this.props.history.push(`${this.page_metadata.base_url}/${this.page_name}`);
        // if (this.page_name == "insurance_class")
        //     this.props.history.push('/coming_soon');
    };

    //Function calls on initialization of Ag-Grid and catch its reference.
    get_grid_ref = grid_params => {
        this.setState({
            grid_params,
            grid_rows_count: grid_params.api.getModel().getRowCount()
        });
        var grid_width = document.getElementsByClassName('admin-wrapper')[0].clientWidth;
        if (this.total_column_width > grid_width) {
            this.page_metadata.column_def.filter(item => {
                grid_params.columnApi.setColumnWidth(item.field, item.width, false);
            });
        }
    };

    //Used to create the title for export and print.
    set_title = separter => {
        this.company_name = this.props.user_login_details.user_data.data.company_name;
        this.current_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
        this.title = this.page_metadata.search_header;
        return this.title =
            this.title.replace(/<br>/g, separter) +
            separter +
            this.company_name +
            separter +
            this.current_date +
            separter +
            (this.state.grid_rows_count == 1
                ? this.state.grid_rows_count + ' record'
                : this.state.grid_rows_count + ' records') +
            separter +
            separter;
    };

    //Function calls on initialization of export report data
    on_export_button = () => {
        this.title = this.set_title('\n');
        this.page_name = this.page_name.replace(/<br>/g, '');
        export_print_utility.export_grid_data(this.state.grid_params, this.title, this.page_metadata.search_header);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Export);
    };

    //Function calls on initialization of Print report data
    on_print_button = () => {
        this.title = this.set_title('<br>');
        export_print_utility.print_grid_data(this.state.grid_params, this.title, this.page_metadata.search_header);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
    };

    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        if (this.props.features.magnusPlat1731BillingCharges) {
            const dataEntry: PrintExportConstants.DataEntry = {
                reportId: this.reportId,
                Records: Number(this.state.grid_params.api.rowModel.rowsToDisplay.length)
            };
            this.getSearchCriteria(dataEntry);

            const viewableEntry: PrintExportConstants.DataEntry = {};
            this.getSearchCriteria(viewableEntry, true);
            viewableEntry[constants.getLabelRecords(eventActionId)] = Number(this.state.grid_params.api.rowModel.rowsToDisplay.length);

            const entityTypeId = this.page_metadata.entity_type_id;
            const data: PrintExportConstants.IPrinExportDto = {
                entityTypeId: entityTypeId != null ? entityTypeId : PrintExportConstants.EntityTypeId.ARptVer,
                auditDataEntry: dataEntry,
                viewableDataEntry: viewableEntry
            };

            PrintExportConstants.savePrintExportAudit(data, accessToken, eventActionId);
        }
        else {
            const { user_id, company_id } = this.props.user_login_details.user_data.data;

            const payload: ReportConstants.IPayloadForBillingAudit = {
                reportId: this.reportId,
                contextTitle: this.page_metadata.search_header + " - " + ReportConstants.ReportEventAction[eventActionId],
                eventActionId: eventActionId,
                userId: Number(user_id),
                companyId: Number(company_id),
                entityTypeId: ReportConstants.ReportEntityTypeId.a_rpt_ver,
                data: { Records: this.state.grid_params.api.rowModel.rowsToDisplay.length }
            };

            const reqBody = export_print_utility.generateAuditDataEntryPayload(payload);
            ReportConstants.saveAuditPrintExport(reqBody, accessToken);
        }
    };

    private getSearchCriteria = (data: PrintExportConstants.DataEntry, isViewable: boolean = false) => {
        const searchData = this.page_metadata.control_data.filter(criteria => criteria.is_search);
        searchData.map((dataRow) => {
            const replaceName = isViewable ? dataRow.label.replace(" ", "_") : dataRow.name;
            data[replaceName] = this.state.search_criteria[dataRow.name];
        });
    }

    update_report_rows_count = () => {
        this.setState({
            grid_rows_count: this.state.grid_params.api.getDisplayedRowCount()
        });
        this.state.grid_params.api.sizeColumnsToFit();
    };

    // Show multiple messages
    show_html_content_toaster = msg => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ),
            timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        };
    };

    new_button_visible_status = () => {
        let is_new_button_visible = false;
        if (this.page_name == 'company') {
            if (
                this.current_user_type != global_constants.enum_user_type_r3_id.Customer &&
                this.current_user_type != global_constants.enum_user_type_r3_id.Partner
            ) {
                is_new_button_visible = true;
            }
        } else if (this.page_name == 'revenue_center') {
            this.menu_permission_object = this.props.shared_details.left_menu_data.data.filter((menu) => {
                return (
                    menu.r6_path &&
                    menu.r6_path.split('/')[2] &&
                    menu.r6_path.split('/')[2] == this.page_name
                );
            });
            if (this.menu_permission_object.length == 0 || this.menu_permission_object[0].view) {
                is_new_button_visible = true;
            }
        } else if (this.page_name == 'claim_adjustment_code') {
            if
            (
                this.current_user_type == global_constants.enum_user_type_r3_id.Sys_Admin ||
                this.current_user_type == global_constants.enum_user_type_r3_id.BMS
            )
            {
                is_new_button_visible = true;
            }
        }
        else {
            if (constants.new_supress_page_list.indexOf(this.page_name) !== -1) {
                if (
                    !is_user_partner_customer_and_company_revEquip_revServe(this.company_service_type, this.current_user_type)
                ) {
                    is_new_button_visible = true;
                }
            } else {
                is_new_button_visible = true;
            }
        }
        this.setState({
            is_new_button_visible: is_new_button_visible
        });
    }

    //It renders report grid and its search controls.
    render() {
        return (
            this.state.show_render && (
                <React.Fragment>
                    <Dimmer active={this.state.is_loading}>
                        <Loader size='massive'>Loading</Loader>
                    </Dimmer>
                    <div className={'admin-wrapper ' + this.page_metadata.id} style={!this.state.show_grid ? { paddingBottom: 20 } : {}}>
                        <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                            <Grid.Column computer={16}>
                                <h3 className='ui header left aligned' dangerouslySetInnerHTML={{ __html: this.page_metadata.search_header }} />
                            </Grid.Column>
                        </Grid>
                        <div id='admin-scrollable-area' className='wrapper' style={this.state.show_grid ? { flex: 1 } : {}}>
                            <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container'>
                                <Form autoComplete='off' onSubmit={e => this.search_handler(e)}>
                                    <Grid style={{ marginBottom: 0 }}>
                                        {constants
                                            .group_by(
                                                this.page_metadata.control_data
                                                    .filter(data_object => {
                                                        return data_object.is_search;
                                                    })
                                                    .filter(data_object => {
                                                        return !data_object.in_advance_search;
                                                    }),
                                                'row'
                                            )
                                            .map((data_object, index) => {
                                                return (
                                                    <Grid.Column computer={16} key={'outer_row_' + index}>
                                                        <Grid>
                                                            {data_object.map((data_row, index) => {
                                                                return (
                                                                    <Grid.Column
                                                                        className={data_row.control_type === 'checkbox' ? 'form_column' : ''}
                                                                        key={'inner_row_' + index}
                                                                        tablet={data_row.search_container_tablet}
                                                                        verticalAlign='middle'
                                                                        computer={data_row.search_container_computer}
                                                                    >
                                                                        {this.set_controls(data_row, index)}
                                                                    </Grid.Column>
                                                                );
                                                            })}
                                                        </Grid>
                                                    </Grid.Column>
                                                );
                                            })}
                                    </Grid>
                                    {this.page_metadata.advance_search && (
                                        <div className='accordion ui fluid styled normal-accordion' style={{ marginBottom: 0 }}>
                                            <div className='active title' onClick={() => this.setState({ shown: !this.state.shown })}>
                                                Advanced Search
                        <i
                                                    tabIndex={0}
                                                    onKeyDown={handle_click_on_enter}
                                                    aria-hidden='true'
                                                    className={this.state.shown ? 'angle up horizontal icon' : 'angle down horizontal icon'}
                                                />
                                            </div>
                                            {this.state.shown && (
                                                <div className='content active'>
                                                    <Grid.Row>
                                                        {constants
                                                            .group_by(
                                                                this.page_metadata.control_data
                                                                    .filter(data_object => {
                                                                        return data_object.is_search;
                                                                    })
                                                                    .filter(data_object => {
                                                                        return data_object.in_advance_search;
                                                                    }),
                                                                'row'
                                                            )
                                                            .map((data_object, index) => {
                                                                return (
                                                                    <Grid.Column computer={16} key={'outer_row_' + index}>
                                                                        <Grid>
                                                                            {data_object.map((data_row, index) => {
                                                                                if (data_row.is_search && data_row.in_advance_search) {
                                                                                    return (
                                                                                        <Grid.Column
                                                                                            className={data_row.control_type === 'checkbox' ? 'form_column' : ''}
                                                                                            key={'inner_row_' + index}
                                                                                            tablet={data_row.search_container_tablet}
                                                                                            computer={data_row.search_container_computer}
                                                                                        >
                                                                                            {this.set_controls(data_row, index)}
                                                                                        </Grid.Column>
                                                                                    );
                                                                                }
                                                                            })}
                                                                        </Grid>
                                                                    </Grid.Column>
                                                                );
                                                            })}
                                                    </Grid.Row>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <Grid style={{ margin: '0 -17px' }}>
                                        <Grid.Column computer={16} textAlign='right'>
                                            <Button
                                                id='clear_button_id'
                                                type='button'
                                                onClick={(e) => this.clear_handler(e)}
                                                basic
                                                content={'Clear'}
                                            />
                                            {this.state.is_new_button_visible && this.state.is_add_edit_allowed && (
                                                <Button
                                                    id='new_button_id'
                                                    type='button'
                                                    onClick={(e) => this.new_handler(e)}
                                                    basic
                                                    content={this.page_metadata.create_button_label}
                                                />
                                            )}
                                            <Button
                                                id='search_button_id'
                                                type='submit'
                                                primary
                                                disabled={this.state.is_search_button_disabled}
                                                content={'Search'}
                                            />
                                        </Grid.Column>
                                    </Grid>
                                    {this.state.show_grid && (
                                        <Grid style={{ marginTop: '-1rem', marginBottom: 0 }}>
                                            <Grid.Column tablet={5} computer={4} textAlign='left'>
                                                <p style={{ fontSize: '16px' }}>Search Results</p>
                                            </Grid.Column>
                                            <Grid.Column tablet={5} computer={8} textAlign='center'>
                                                {
                                                    <p style={{ fontSize: '16px', minHeight: 22 }}>
                                                        {`${this.state.grid_rows_count} ${
                                                            this.state.grid_rows_count == 1 ? ' record shown' : ' records shown'
                                                            }`}
                                                    </p>
                                                }
                                            </Grid.Column>
                                            <Grid.Column tablet={2} computer={4} textAlign='right' />
                                        </Grid>
                                    )}
                                </Form>
                            </div>
                            {this.state.show_grid && !this.state.is_loading && (
                                <GridView
                                    id={`id_grid_${this.page_name}`}
                                    row={this.row_data}
                                    column={this.page_metadata.column_def}
                                    style={{ height: '100%' }}
                                    wrapperStyle={{ width: '100%', height: '100%', display: 'flex' }}
                                    suppressMovableColumns={false}
                                    enableColResize={true}
                                    onRowDoubleClicked={this.on_row_double_clicked}
                                    selectionType={'single'}
                                    get_grid_ref={this.get_grid_ref}
                                    suppressSizeToFit={this.page_name == 'claim_adjustment_code' ? false : true}
                                    headerHeight={this.grid_header_height}
                                    headerIdForTabNavigation={constants.search_grid_id}
                                    onForceGridOut={this.on_grid_out}
                                    on_filter_button_click={() => this.update_report_rows_count()}
                                />
                            )}
                        </div>
                        {this.state.show_grid && (this.page_metadata.show_export_on_search || this.page_metadata.show_print_on_search) && (
                            <div
                                className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                                id='applicationFooterSticky'
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                                <Grid.Column computer={16} textAlign='right'>
                                    {this.page_metadata.show_export_on_search && (
                                        <Button id='export_report_button' type='submit' onClick={this.on_export_button} basic>
                                            Export
                  </Button>)
                                    }
                                    {this.page_metadata.show_print_on_search && (
                                        <Button
                                            onKeyDown={set_focus_to_app_header}
                                            id='print_report_button'
                                            type='submit'
                                            onClick={this.on_print_button}
                                            primary
                                            style={{ marginRight: 0 }}
                                        >
                                            Print
                  </Button>)
                                    }
                                </Grid.Column>
                            </div>
                        )}
                    </div>
                </React.Fragment>
            )
        );
    }
}

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
        shared_details: state.shared_details,
        features: {
            isProviderCredentials: get_lauch_darkley_key_value(state.launch_darkly, ProviderCredentials.projectFlag),
            magnusPlat1731BillingCharges: get_lauch_darkley_key_value(state.launch_darkly, ChargesBillingAuditLogEpicsFeature.magnusPlat1731BillingCharges),
            claimAdjustmentReasonCodeCompanyOverrides: get_lauch_darkley_key_value(state.launch_darkly, claim_adjustment_reason_code_company_overrides)
        }
    };
};

export default connect(mapStateToProps)(ConstantSearchComponent);
