import * as React from "react";
import { connect } from "react-redux";
import { Button, Form, Grid } from "semantic-ui-react";
import { toastr as toaster } from "react-redux-toastr";
import GridView from "../../../shared/component/grid";
import { currencyFormatter, dateFormatter, messages, dateTimeFormatter, IPayloadForBillingAudit } from "../../../reports/report_constants";
import { strip, get_columns, get_all_error, get_lauch_darkley_key_value as get_launch_darkley_key_value } from "../../../shared/utility";
import ReportHeader from "../../../reports/component/report_header";
import { log_error } from "../../../shared/action/shared_action";
import { batch_billing_claims_configuration, run_status_update } from "../../billing_constants";
import { get_batch_billing_claims, claims_change_status_action, claims_print_status_action, obtain_batch_claims_export_action, claims_print_status } from '../../action/billing_actions';
import { LoaderComponent } from '../../../shared/component/loading_component';
import { set_focus_to_app_header } from "../../../shared/tab_navigation_utility";
import { global_messages } from "../../../global_constants";
import { patient_messages } from "../../../patient/patient_constants";
import { generateAuditDataEntryPayload, multi_grid_export } from "../../../reports/util/export_print_utility";
import * as ReportConstants from "../../../reports/report_constants";
import * as FFConstant from "../../../feature_flip_constant";
import { capitalize } from "../../utility";

/**
 * Batch Billing - Generated Claims page
 */
export class BatchBillingClaimsComponent extends React.Component<any, any> {
    
    is_mounted: boolean;
    current_date: string;
    title: string;
    set_id: string;
    grid_width = 0;
    grid_header_height: number = 0;
    total_column_width: number = 0;
    batch_billing_claims: any;
    report_data: any = { rows: [], set_status: '' };
    column_defs: any = [];
    export_column_defs: any = [];
    export_row_data: any = [];
    selected_items: any = [];
    pdf_counter: number = 0;
    scrollbar_ref:any
    sticky_footer_spacing:number = 5;
    reportId: string = "0";

    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            search_visible: true,
            grid_visible: false,
            export_grid_visible: false,
            is_update_disabled: false,
            report_rows_count: 0,
            export_report_rows_count: 0
        };

        this.batch_billing_claims = batch_billing_claims_configuration;
        this.column_defs = this.batch_billing_claims.column_defs;
        this.export_column_defs = this.batch_billing_claims.export_column_defs;
        this.scrollbar_ref = React.createRef();
    }

    componentDidMount = async () => {
        this.is_mounted = true;
        const route_array = this.props.history.location.pathname.split('/');
        if (
            route_array &&
            route_array[2] == 'batch_billing' &&
            this.props.history.location.state.data &&
            route_array[3] == this.props.history.location.state.data.set_id
        ) {
            this.set_id = this.props.history.location.state.data.set_id;
            this.get_batch_billing_claims();
        } else {
            this.props.history.goBack();
        }

        if (!document.body.classList.contains('reports')) {
            document.body.classList.add('reports');
        }
    };

    componentWillUnmount = () => {
        document.body.classList.remove("reports");
        this.is_mounted = false;
        this.report_data = {};
    };

    // Get Batch Billing- Generated Claims
    get_batch_billing_claims = async () => {
        this.grid_width = document.getElementsByClassName("report-wrapper")[0].clientWidth;
        if (this.is_mounted) {
            this.setState({
                loading: true,
                grid_visible: false,
                search_visible: false
            });
        }
        let token = this.props.user_login_details.user_data.data.token_details.access_token;
        await get_batch_billing_claims(token, this.set_id).then(
            (response) => {
                if (response.data && response.data.data && response.data.data.run_detail_list) {
                    this.formate_data(response);
                    if (this.is_mounted) {
                        this.setState({
                            report_data: this.report_data,
                            loading: false,
                            grid_visible: true,
                            search_visible: true,
                            is_update_disabled: this.report_data["rows"].length == 0
                        });
                        if (
                            this.scrollbar_ref &&
                            this.scrollbar_ref.current &&
                            this.scrollbar_ref.current.scrollHeight &&
                            this.scrollbar_ref.current.offsetHeight
                        ) {
                            this.sticky_footer_spacing =
                                this.scrollbar_ref.current.scrollHeight > this.scrollbar_ref.current.offsetHeight
                                    ? 25
                                    : 5;
                        }
                    }
                } else {
                    log_error(response.data.messages[0].message.indexOf("<br") > -1);
                    if (response.data.messages[0].message.indexOf("<br") > -1) {
                        const toastr_options = this.show_html_content_toaster(
                            get_all_error(response.data.messages[0].message)
                        );
                        toaster.error("", toastr_options);
                    }
                    if (this.is_mounted) {
                        this.setState({
                            loading: false,
                            grid_visible: true,
                            search_visible: true,
                            is_update_disabled: true
                        });
                    }
                }
            },
            (error) => {
                if (this.is_mounted) {
                    this.setState({
                        loading: false,
                        grid_visible: true,
                        search_visible: true,
                        is_update_disabled: true
                    });
                }
                log_error(error);
                if (error.response.data) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error("", toastr_options);
                }
            }
        );
    };

    // FUnction use to format the grid data on load.
    row_data_formatter = (row_data, column_def) => {
        row_data.map((row) => {
            column_def.map((value, index) => {
                switch (value.type) {
                    case "date":
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined ? dateFormatter(row[value.field]) : "";
                        break;
                    case "datetime":
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined ? dateTimeFormatter(row[value.field]) : "";
                        break;
                    case "currency":
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined
                                ? currencyFormatter(row[value.field])
                                : currencyFormatter(0);
                        break;
                    case "number":
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined ? parseInt(row[value.field]) : 0;
                        break;
                    case "hyper_link":
                        row[`link_${value.field}`] = strip(row[value.field]);
                        break;
                    case "custom_enum":
                        if (value.field == "batch_type" || value.field == "program_type") {
                            row[value.field] = row[value.field];
                        }
                        if (value.field == "status") {
                            row[value.field] = row[value.field];
                        }
                        break;
                    default:
                        row[value.field] = row[value.field] != null || row[value.field] != undefined ? row[value.field] : "";
                }
                if (value.field == "status" && row[value.field] === "Payer ID Not Found") {
                }
            });

            return row;
        });
        return row_data;
    };

    //Grid instance
    get_grid_ref = (grid_params) => {
        this.setState({
            grid_params,
            report_rows_count: grid_params.api.getModel().getRowCount()
        });
        if (this.total_column_width > this.grid_width) {
            this.column_defs.filter((item, index) => {
                grid_params.columnApi.setColumnWidth(item.field, item["width"], false);
            });
        }
    };


    is_row_selectable = (params) => {
        if (params.data && params.data.allow_select) return true;

        return false;
    };

    // Select case_id on checkbox
    on_check_box_selection = (items) => {
        if (this.selected_items.length !== items.length) {
            this.selected_items = items;
        }
    };

    // Buttons on click
    handle_change_status = async (format_print_status_type) => {
        if (this.selected_items && this.selected_items.length <= 0) {
            toaster.error('', this.batch_billing_claims.empty_error);
            return;
        }
        let params = this.selected_items.map(item => item.claim_hdr_id);

        if (this.is_mounted) {
            this.setState({
                loading: true,
                is_update_disabled: true
            });
        }

        let token = this.props.user_login_details.user_data.data.token_details.access_token;
        // Print Data
        if (format_print_status_type === 4) {
          if (this.is_mounted) {
            this.setState({
             re_render: true
            });
          }
          claims_print_status(params, token).then(
            (response) => {
              this.generate_pdf(response.data.data);
            },
            (error) => {
              this.setState({
                re_render: false
              });
              if (error.response.data) {
                const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                toaster.error('', toastr_options);
              }
            }
          );
        }

        await claims_change_status_action(token, this.set_id, format_print_status_type, run_status_update.run_set_details, params).then(
          (response) => {
            if (response.data && response.data.status === 1) {
              toaster.success('', response.data.messages[0].message);
              this.formate_data(response);
              if (this.is_mounted) {
                this.selected_items = [];
                this.setState(
                  {
                    loading: false,
                    grid_visible: false,
                    search_visible: false,
                    is_update_disabled: false
                  },
                  () => {
                    this.setState({
                      grid_visible: true,
                      search_visible: true,
                      is_update_disabled: this.report_data['rows'].length == 0
                    });
                  }
                );
                if (
                  this.scrollbar_ref &&
                  this.scrollbar_ref.current &&
                  this.scrollbar_ref.current.scrollHeight &&
                  this.scrollbar_ref.current.offsetHeight
                ) {
                  this.sticky_footer_spacing =
                    this.scrollbar_ref.current.scrollHeight > this.scrollbar_ref.current.offsetHeight ? 25 : 5;
                }
              }
            } else {
              if (response.data.messages) {
                const toastr_options = this.show_html_content_toaster(get_all_error(response.data.messages));
                toaster.error('', toastr_options);
              }
              if (this.is_mounted) {
                this.selected_items = [];
                this.setState({
                  loading: false,
                  is_update_disabled: false
                });
              }
            }
          },
          (error) => {
            if (this.is_mounted) {
              this.setState({
                loading: false,
                is_update_disabled: false
              });
            }
            if (error.response.data) {
              log_error(error);
              const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
              toaster.error('', toastr_options);
            }
          }
        );
    };

    //Toaster function
    show_html_content_toaster = (msg) => {
        return {
            component: () => (<div><div dangerouslySetInnerHTML={{ __html: msg }} /></div>),
            timeOut: 5000,
            preventDuplicates: true
        };
    };

    //Render search section
    render_search = () => {
        return (<Form><Grid style={{ padding: 15 }}>
            <Grid.Column tablet={8} computer={4}><Form.Field>
                <label>Set ID</label>
                <div className="values">{this.set_id}</div>
            </Form.Field></Grid.Column>
            <Grid.Column tablet={8} computer={4}><Form.Field>
                <label>Set Status</label>
                <div className="values">{this.report_data['set_status']}</div>
            </Form.Field></Grid.Column>
        </Grid></Form>);
    }

    generate_pdf = (data) => {
        const pdf_file = new Blob([data], {
          type: 'application/pdf'
        });
        if (pdf_file.size <= 0) {
          if (this.is_mounted) {
            this.setState({ re_render: false });
          }
          toaster.error('', patient_messages.report_not_found);
          return false;
        } else {
          // For IE
          if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            if (this.is_mounted) {
              this.setState({
                re_render: false
              });
            }
            (window.navigator as any).msSaveOrOpenBlob(pdf_file);
          } else {
            const pdf_content = window.URL.createObjectURL(pdf_file);
            const win = open(pdf_content, 'demand_claim' + this.pdf_counter++, '');
            if (!win) {
              toaster.error('', global_messages.popup_blocked);
            } else {
              win.focus();
              win.onload = () => {
                win.print();
                // window.URL.revokeObjectURL(pdf_content);
              };
            }
            if (this.is_mounted) {
              this.setState({
                re_render: false
              });
            }
          }
        }
    };

    // Convert array buffer to base64
    arrayBufferToBase64(arrayBuffer) {
        return btoa(new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    }

    formate_data = (response) => {
        response.data.data.run_detail_list = this.row_data_formatter(response.data.data.run_detail_list, this.column_defs);
        this.report_data['rows'] = response.data.data.run_detail_list || [];
        this.report_data['set_status'] = response.data.data.set_status || '';
        this.total_column_width = 0;
        const grid_height = get_columns(this.report_data['rows'], this.column_defs);
        this.grid_header_height = grid_height.header_height;
        this.total_column_width = grid_height.total_column_width;
    } 

    // Buttons on click
    on_export_button = async () => {
        if (this.is_mounted) {
            this.setState({
                loading: true,
                export_grid_visible: false
            });
        }

        if (this.selected_items && this.selected_items.length <= 0) {
            toaster.error('', this.batch_billing_claims.empty_error);
            this.setState({
                loading: false
            });
            return;
        }
        let params = this.selected_items.map((item) => item.claim_hdr_id);
        let token = this.props.user_login_details.user_data.data.token_details.access_token;

        await obtain_batch_claims_export_action(token, { set_id: this.set_id, claim_header_id: params }).then(
            (response) => {
                if (response.data && response.data.data) {
                    this.formate_export_data(response.data.data);
                    if (this.is_mounted) {
                        this.setState(
                            {
                                export_grid_visible: true
                            },
                            () => {
                                setTimeout(() => {
                                    let title = this.set_title('\n');
                                    let grid_params = [this.state.grid_params_export];
                                    multi_grid_export(grid_params, [title], 'Batch Claims - Generated Claims', []);
                                    grid_params = [];
                                    title = '';
                                    this.saveReportEventAction(ReportConstants.ReportEventAction.Export);
                                    setTimeout(() => {
                                        if (this.is_mounted) {
                                            this.setState({
                                                loading: false
                                            });
                                        }
                                    }, 1000);
                                }, 0);
                                
                            }
                        );
                    }
                } else {
                    if (this.is_mounted) {
                        this.setState({
                            loading: false,
                            export_grid_visible: false
                        });
                    }
                }
            },
            (error) => {
                if (this.is_mounted) {
                    this.setState({
                        loading: false,
                        export_grid_visible: false
                    });
                }
                log_error(error);
                if (error.response.data) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error('', toastr_options);
                }
            }
        );
    };

    get_grid_ref_export = (grid_params_export) => {
        if (this.is_mounted) {
            this.setState({
                grid_params_export,
                export_report_rows_count: grid_params_export.api.getModel().getRowCount()
            });
        }
    };

    set_title = (separter: string) => {
        let title_search_criteria: any = {};
        let title = "";
        let rows_count: string;
        rows_count =
            this.state.export_report_rows_count == 1
                ? this.state.export_report_rows_count + " record"
                : this.state.export_report_rows_count + " records";
        title_search_criteria = {
            title: "Batch Claims - Generated Claims",
            set_id: this.set_id,
            set_status: this.report_data["set_status"],
            rows_count
        };
        Object.keys(title_search_criteria).forEach((key) => {
            if (["current_date", "title", "rows_count"].indexOf(key) > -1) {
                title += title_search_criteria[key] + separter;
            } else {
                title += capitalize(key.replace(/_/g, " ")) + ": " + title_search_criteria[key] + separter;
            }
        });
        return title + separter + separter;
    };

    formate_export_data = (response) => {
        this.export_row_data = this.row_data_formatter(response, this.export_column_defs) || [];
    };

    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;
        const payload: IPayloadForBillingAudit = {
            reportId: this.reportId,
            contextTitle: "Batch Claims - Generated Claims" + " - " + ReportConstants.ReportEventAction[eventActionId],
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: ReportConstants.ReportEntityTypeId.D_CLAIM,
            data: { Records: this.state.report_rows_count }
        };
        const reqBody = generateAuditDataEntryPayload(payload);
        ReportConstants.saveAuditPrintExport(reqBody, accessToken);
    } 

    render() {
        return (<div className={"common-forms-add"}>
            <LoaderComponent loading={this.state.loading}>
                <div className={"common-forms-search report-framework batch-tasks-program"} style={{ paddingRight: 0 }}>
                    <ReportHeader title={this.batch_billing_claims.title} />
                    <div style={{ overflow: "auto", paddingRight: 15, flex: "1 1 0" }} ref={this.scrollbar_ref}>
                        <div
                            id="report-scrollable-area"
                            className="report-wrapper"
                            style={this.state.search_visible ? { flex: 1 } : {}}
                        >
                            {this.render_search()}
                        </div>
                        {this.state.grid_visible && (
                            <div style={{ border: " 1px solid #cccccc", background: " #fff", marginTop: 20 }}>
                                <GridView
                                    id={this.batch_billing_claims.batch_billing_claims_grid_id}
                                    row={this.report_data.rows}
                                    column={this.column_defs}
                                    headerHeight={this.grid_header_height}
                                    gridAutoHeight={true}
                                    enableColResize={true}
                                    suppressSizeToFit={true}
                                    emptyMessage={messages.no_records_found}
                                    paginationPageSize={30}
                                    isPagination={true}
                                    paginationMessage={' '}
                                    get_grid_ref={this.get_grid_ref}
                                    checkboxSelection={true}
                                    onRowSelection={this.on_check_box_selection}
                                    isRowSelectable={this.is_row_selectable}
                                    headerIdForTabNavigation={this.batch_billing_claims.batch_billing_header_id}
                                />
                            </div>
                        )}
                    </div>
                    {this.state.grid_visible && (
                        <div
                            className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area"
                            id="applicationFooterSticky"
                            style={{ paddingRight: this.sticky_footer_spacing }}>
                            <Button
                                id='btn-queued'
                                type='button'
                                basic
                                content={'Queued'}
                                disabled={this.state.is_update_disabled}
                                onClick={
                                    () => this.handle_change_status(this.batch_billing_claims.format_print_status.queued)
                                }
                            />
                            <Button
                                id='btn-pend'
                                type='button'
                                basic
                                content={'Pend'}
                                disabled={this.state.is_update_disabled}
                                onClick={() =>
                                    this.handle_change_status(this.batch_billing_claims.format_print_status.pending)
                                }
                            />
                            {this.props.rsi_bill_456_add_export_feature_on_batch_claims && (
                                <Button
                                    id="export_report_button"
                                    type="button"
                                    basic
                                    onKeyDown={set_focus_to_app_header}
                                    disabled={this.state.is_update_disabled}
                                    onClick={this.on_export_button}
                                    content={"Export Claim Details"}
                                />
                            )}
                            <Button
                                id='btn-print'
                                className='primary'
                                type='button'
                                style={{ marginRight: '0' }}
                                content={'Print'}
                                onKeyDown={set_focus_to_app_header}
                                disabled={this.state.is_update_disabled}
                                onClick={() =>
                                    this.handle_change_status(this.batch_billing_claims.format_print_status.printed)
                                }
                            />
                        </div>
                    )}
                        {this.props.rsi_bill_456_add_export_feature_on_batch_claims && this.state.export_grid_visible && (
                            <div
                                style={{
                                    display: "none",
                                    border: " 1px solid #cccccc",
                                    background: " #fff",
                                    marginTop: 20
                                }}
                            >
                                <GridView
                                    id={this.batch_billing_claims.batch_billing_export_grid_id}
                                    row={this.export_row_data}
                                    column={this.export_column_defs}
                                    headerHeight={this.grid_header_height}
                                    emptyMessage={messages.no_records_found}
                                    headerIdForTabNavigation={this.batch_billing_claims.batch_billing_header_id}
                                    get_grid_ref={this.get_grid_ref_export}
                                />
                            </div>
                        )}
                </div>
            </LoaderComponent>
        </div>);
    }
}

// Get user and login details from store.
const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        rsi_bill_456_add_export_feature_on_batch_claims: get_launch_darkley_key_value(
            state.launch_darkly,
            FFConstant.rsi_bill_456_add_export_feature_on_batch_claims
        )
    };
};

export default connect(mapStateToProps)(BatchBillingClaimsComponent);
