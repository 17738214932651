import { Tab, Tabs, Tooltip } from '@blueprintjs/core';
import * as React from 'react';
import * as global_constants from '../../../../global_constants';
import { connect } from 'react-redux';
import { toastr as toaster } from 'react-redux-toastr';
import { Button, Grid, Input, Segment } from 'semantic-ui-react';
import MultiSelectPanelComponent from '../../../../shared/component/multi_select_panel_component';
import { set_focus_on_element_with_id } from '../../../../shared/tab_navigation_utility';
import { check_valid_npi, is_null_or_white_space } from '../../../../shared/utility';
import { insurance_name, is_alpha_numeric, location_name } from '../../utility';
import * as action from '../../action/overrides_action';
import { overrides, status_select_options } from './overrides_constants';
import { AlertConfirm } from '../../../../shared/component/alert_confirm_component';
import Selection from '../../../../shared/component/selection_component';


interface IFormFields {
  claim_Override_ID: any;
  company_ID: number;
  setting_Name: string;
  tax_Identification_Number: string;
  national_Provider_Identifier: number;
  taxonomy: string;
  pay_To_Name: string;
  pay_To_Address: string;
  pay_To_City: string;
  pay_To_State: string;
  pay_To_Zip_Code: string;
  location_ids: number[];
  payer_ids: number[];
  insurance_class_ids: number[];
  insurance_code_ids: number[];
  locations: Object;
  payers: Object;
  insurance_Classes: Object;
  insurance_Codes: Object;
  locations_Json_Version: number;
  payers_Json_Version: number;
  insurance_Classes_Json_Version: number;
  insurance_Codes_Json_Version: number;
  include_Future: string;
  needsPersistence: boolean;
  read_Date_Time: Date,
  is_Active: boolean,
  is_Active_Text: string,
  row_Id: number
}

interface IStateOption {
    value: string,
    text: string
}

interface IClientValidationOutcome {
    settingNameMissing: boolean;
    invalidNPI: boolean;
    invalidTaxonomy: boolean;
    invalidTIN: boolean;
    invalidPayerZip: boolean;
    isLocationAvailable: boolean;
    isAdditionalCriteriaAvailable: boolean;
    invalidInsCriteria: boolean;
    settingNameRepeated: boolean;
}

class LocationOverrideComponent extends React.Component<any, any> {
  _isMount = false;
  persistedOverrides: [];
  inMemoryOverrides: [];
  isAdd: boolean;
  isEdit: boolean;
  originalSettingName: string;
  isOverrideValid: boolean;
  shouldCheckInsuranceCodes: boolean;
  private readonly tabLocationsData = [];
  private readonly tabPayersData = [];
  private readonly tabInsuranceClassesData = [];
  private readonly tabInsuranceCodesData = [];
  private readonly jsonVersion = null;
  private readonly overridesConstants = null;
  _transformed_state_options: Array<IStateOption>;
    
  constructor(props) {
    super(props);
    this.state = {
      formFields: this.props.overrideInMemoryStorage.originalRowData,
      isSubmitted: false,
      alert: {
          showBox: false,
          message:''
      },
      formErrors: {},
      saveDisabled: this.props.save_disable,
      ...this.handleTabsOnLoad()
    };
    
    this.currentFormFields = {
      ...this.props.overrideInMemoryStorage.originalRowData,
    };

    this.tabLocationsData = this.props.locations.map((val, index) => {
      return { value: val.id, name: location_name(val) };
    });

    this.tabPayersData = this.props.payers.map((val, index) => {
      return { value: val.payer_key_id, name: val.description };
    });

    this.tabInsuranceClassesData = this.props.insuranceClasses.map((val, index) => {
      return { value: val.id, name: insurance_name(val) };
    });

    this.tabInsuranceCodesData = this.props.insuranceCodes.map((val, index) => {
      return { value: val.insurance_code_id, name: val.insurance_display_name };
    });

    this.jsonVersion = 1;
    this.overridesConstants = overrides(this.props);
    this.inMemoryOverrides = this.props.overrides;
    this.isAdd = this.props.openPanelMode ? this.props.openPanelMode.isOnCreateMode : false;
    this.isEdit = this.props.openPanelMode ? this.props.openPanelMode.isOnEditMode : false;
    this.isOverrideValid = true;
    this.shouldCheckInsuranceCodes = false;
    this._transformed_state_options = this.get_state_display_format();
  }

  currentFormFields: IFormFields = {
    claim_Override_ID: 0,
    company_ID: null,
    setting_Name: '',
    tax_Identification_Number: '',
    national_Provider_Identifier: null,
    taxonomy: '',
    pay_To_Name: '',
    pay_To_Address: '',
    pay_To_City: '',
    pay_To_State: '',
    pay_To_Zip_Code: '',
    location_ids: [],
    payer_ids: [],
    insurance_class_ids: [],
    insurance_code_ids: [],
    locations: '',
    payers: '',
    insurance_Classes: '',
    insurance_Codes: '',
    locations_Json_Version: this.jsonVersion,
    payers_Json_Version: this.jsonVersion,
    insurance_Classes_Json_Version: this.jsonVersion,
    insurance_Codes_Json_Version: this.jsonVersion,
    include_Future: '',
    needsPersistence: true,
    read_Date_Time: null,
    is_Active: true,
    is_Active_Text: 'Active',
    row_Id:0
  };

  componentDidMount() {
    this._isMount = true;
    this.currentFormFields.claim_Override_ID = this.currentFormFields.claim_Override_ID ? this.currentFormFields.claim_Override_ID : '';
    this.currentFormFields.company_ID = this.props.company_ID;
    this.currentFormFields.locations_Json_Version = this.jsonVersion;
    this.currentFormFields.payers_Json_Version = this.jsonVersion;
    this.currentFormFields.insurance_Classes_Json_Version = this.jsonVersion;
    this.currentFormFields.insurance_Codes_Json_Version = this.jsonVersion;
    this.originalSettingName = this.currentFormFields.setting_Name;
    this.currentFormFields.location_ids = this.normalizeArray(this.currentFormFields.location_ids);
    this.currentFormFields.payer_ids = this.normalizeArray(this.currentFormFields.payer_ids);
    this.currentFormFields.insurance_class_ids = this.normalizeArray(this.currentFormFields.insurance_class_ids);
    this.currentFormFields.insurance_code_ids = this.normalizeArray(this.currentFormFields.insurance_code_ids);
    
    setTimeout(() => {
      set_focus_on_element_with_id('Setting_Name');
    }, 0);
  }

    get_state_display_format = (): Array<IStateOption> => {
        let master_state_list = this.props.user_login_details.master_data.data.states;
        if (!master_state_list) return [];

        let transformed_state_list: Array<IStateOption> = [];

        master_state_list.forEach(masterState => {
            transformed_state_list.push({ value: masterState.id, text: masterState.name });
        });
        return transformed_state_list;
    }

  required_field: any = {
    settingName: true,
  };

  handleTabsOnLoad() {
    let { insurance_class_ids, insurance_code_ids } = this.props.overrideInMemoryStorage.originalRowData;
    let disable_insurance_code_tab = false;
    let disable_insurance_class_tab = false;
    let defaultSelectedTabId = this.props.overrideTabId || 'location_id';
    insurance_class_ids = insurance_class_ids ? insurance_class_ids : [];
    insurance_code_ids = insurance_code_ids ? insurance_code_ids : [];

    if (!insurance_code_ids.length && insurance_class_ids.length > 0) {
      disable_insurance_code_tab = true;
      defaultSelectedTabId = this.props.overrideTabId === 'insurance_code_id' ? 'location_id' : defaultSelectedTabId;
    } else if (insurance_code_ids.length > 0 && !insurance_class_ids.length) {
      disable_insurance_class_tab = true;
      defaultSelectedTabId = this.props.overrideTabId === 'insurance_class_id' ? 'location_id' : defaultSelectedTabId;
    }

    return {
      disable_insurance_code_tab,
      disable_insurance_class_tab,
      defaultSelectedTabId
    };
  };

  updateAndShowErrors = (errors: any) => {
    this.setState({
      formFields: this.currentFormFields,
      formErrors: errors,
      saveDisabled: false,
    });

    return this.isOverrideValid;
  };

  // #region Validation 
    ui_validate_form = async () => {

        this.isOverrideValid = true; // Reset each time.

        let validation = {
            settingNameMissing: false,
            invalidNPI: false,
            invalidTaxonomy: false,
            invalidTIN: false,
            invalidPayerZip: false,
            isLocationAvailable: false,
            isAdditionalCriteriaAvailable: false,
            invalidInsCriteria:false,
            settingNameRepeated: false
        } as IClientValidationOutcome,
            trimmedSettingName = this.currentFormFields.setting_Name ? this.currentFormFields.setting_Name.trim() : '',
            trimmedTaxonomy = this.currentFormFields.taxonomy ? this.currentFormFields.taxonomy.trim() : '',
            trimmedZip = this.state.formFields.pay_To_Zip_Code ? this.state.formFields.pay_To_Zip_Code.trim() : '';

        this.currentFormFields.setting_Name = trimmedSettingName;
        this.currentFormFields.taxonomy = trimmedTaxonomy;
        this.currentFormFields.pay_To_Zip_Code = trimmedZip;

        //Validate if Setting name availabiity and length
        validation.settingNameMissing = this.is_ui_setting_name_invalid();
        validation.invalidNPI = this.is_ui_npi_invalid();
        validation.invalidTaxonomy = this.is_ui_taxonomy_invalid();
        validation.invalidTIN = this.is_ui_tax_id_invalid();
        validation.invalidPayerZip = this.is_ui_zip_invalid();
        validation.isLocationAvailable = this.is_ui_locations_available();
        validation.isAdditionalCriteriaAvailable = this.is_location_available_with_additional_criteria();
        validation.invalidInsCriteria = this.is_inavlid_with_insclass_and_inscode();
        validation.settingNameRepeated = this.is_setting_name_repeated_in_memory();

        this.isOverrideValid = !validation.settingNameMissing &&
            !validation.invalidNPI &&
            !validation.invalidTaxonomy &&
            !validation.invalidTIN &&
            !validation.invalidPayerZip &&
            validation.isLocationAvailable &&
            validation.isAdditionalCriteriaAvailable &&
            !validation.invalidInsCriteria &&
            !validation.settingNameRepeated;

        this.setState({
            formFields: this.currentFormFields,
            formErrors: validation,
            saveDisabled: this.isOverrideValid,
        });
          
    }

    is_ui_setting_name_invalid = () : boolean => {
        const mandatory_fields_empty = this.required_field.settingName && is_null_or_white_space(this.currentFormFields.setting_Name);
        let is_setting_name_invalid: boolean = false;
        if (mandatory_fields_empty) {
            is_setting_name_invalid = true;
        } else {
            if (this.currentFormFields.setting_Name.length > 80) {
                is_setting_name_invalid = false;
                toaster.error('', this.overridesConstants.messaging.errorSettingNameTooLarge);
            }
        }
        return is_setting_name_invalid;
    }

    is_ui_tax_id_invalid = (): boolean => {
        let is_tax_id_invalid: boolean = false;

        if (!is_null_or_white_space(this.currentFormFields.tax_Identification_Number)) {
            let validCharaters = /^[0-9a-zA-Z]+$/;
            let matchValues = this.currentFormFields.tax_Identification_Number.match(validCharaters);
            let isSpecialCharacters = matchValues == null || matchValues.length == 0 ? true : false;
            is_tax_id_invalid = (isSpecialCharacters || this.currentFormFields.tax_Identification_Number.length > 9);
             
            if (is_tax_id_invalid) {
                toaster.error('', this.overridesConstants.messaging.errorInvalidTIN);
            }
        }
        return is_tax_id_invalid;
    }

    is_ui_npi_invalid = (): boolean => {
        let is_npi_invalid: boolean = false;

        let normalizedNPIString = this.currentFormFields.national_Provider_Identifier ? this.currentFormFields.national_Provider_Identifier : 0;
        let normalizedNPI = parseInt(normalizedNPIString + "");
        if (normalizedNPI != 0) {
            is_npi_invalid = !(
                !isNaN(normalizedNPI) &&
                normalizedNPI > 0 &&
                check_valid_npi(normalizedNPI + ""));


            if (is_npi_invalid) {
                toaster.error('', this.overridesConstants.messaging.errorInvalidNPI);
            }
        }

        return is_npi_invalid;
    }

    is_ui_taxonomy_invalid = (): boolean => {
        let is_taxonomy_invalid: boolean = false;

        if (!is_null_or_white_space(this.currentFormFields.taxonomy)) {    
            is_taxonomy_invalid = !(is_alpha_numeric(this.currentFormFields.taxonomy) && this.currentFormFields.taxonomy.length == 10);
            
            if (is_taxonomy_invalid) {
                toaster.error('', this.overridesConstants.messaging.errorInvalidTaxonomy);
            }
        }
        return is_taxonomy_invalid;
    }

    is_ui_zip_invalid = () => {

        let is_zip_invalid: boolean = false;

        if (!is_null_or_white_space(this.currentFormFields.pay_To_Zip_Code)) {

            //Format Zip to XXXXX-XXXX
            let zip_length = this.currentFormFields.pay_To_Zip_Code.length;
            if (zip_length > 5 && zip_length <= 9) {
                this.currentFormFields.pay_To_Zip_Code = this.currentFormFields.pay_To_Zip_Code.substring(0, 5) +
                    '-' +
                    this.currentFormFields.pay_To_Zip_Code.substring(5, this.currentFormFields.pay_To_Zip_Code.length);
                this.currentFormFields.pay_To_Zip_Code = this.currentFormFields.pay_To_Zip_Code.trim();
            }

            let allowed_format = /^\d{5}(?:[-\s]\d{4})?$/;
            is_zip_invalid = !this.currentFormFields.pay_To_Zip_Code.match(allowed_format);
            if (is_zip_invalid) toaster.error('', this.overridesConstants.messaging.errorZipCode);
        }
        return is_zip_invalid;
    }

    is_ui_locations_available = () => {
        let is_location_available: boolean = true;

        if (!this.currentFormFields.location_ids || this.currentFormFields.location_ids.length == 0) {
            is_location_available = false;
            toaster.error('', this.overridesConstants.messaging.errorLocationMissing);
        }
        return is_location_available;
    }

    is_location_available_with_additional_criteria = () => {
        let additional_criteria_available: boolean = true;

        if ((this.currentFormFields.location_ids && this.currentFormFields.location_ids.length > 0) &&
            (!this.currentFormFields.payer_ids || this.currentFormFields.payer_ids.length == 0) &&
            (!this.currentFormFields.insurance_class_ids || this.currentFormFields.insurance_class_ids.length == 0) &&
            (!this.currentFormFields.insurance_code_ids || this.currentFormFields.insurance_code_ids.length == 0)
        ) {
            additional_criteria_available = false;
            toaster.error('', this.overridesConstants.messaging.errorAdditionalCriteriaMissing);
        }
        return additional_criteria_available;
    }

    is_inavlid_with_insclass_and_inscode = () => {

        let is_ins_criteria_invalid = false;

        // We need to ensure there are insurance class(es) selected or insurance code(s) at least one
        if ((this.currentFormFields.insurance_class_ids && this.currentFormFields.insurance_class_ids.length >= 1) &&
            (this.currentFormFields.insurance_code_ids && this.currentFormFields.insurance_code_ids.length >= 1)) {
            is_ins_criteria_invalid = true;
            toaster.error('', this.overridesConstants.messaging.errorInsuranceClassCodeMissing);
        }
        return is_ins_criteria_invalid;
    }

    is_setting_name_repeated_in_memory = ():boolean => {
        let inMemoryOverrides = this.inMemoryOverrides as IFormFields[]
        let is_setting_name_repeated_inmemory: boolean = false;

            // Check if there are no repeated names in memory
        for (let inMemoryOverride of inMemoryOverrides) {
            let isEditAndHasChangedName = this.isEdit && this.currentFormFields.setting_Name !== this.originalSettingName;

            if ((this.isAdd || isEditAndHasChangedName) && this.currentFormFields.setting_Name == inMemoryOverride.setting_Name) {
                is_setting_name_repeated_inmemory = true;
                toaster.error('', this.overridesConstants.messaging.errorSettingNameAlreadyExists);
            }
        }
        return is_setting_name_repeated_inmemory;
    }
  // #endregion Validation

  

    saveHandler = async (ev) => {
        this.isOverrideValid = true;
        ev.preventDefault();
    
        this.setState({
            isSubmitted: true,
            saveDisabled: true,
        });

        //Verify the Client side vaidation.
        await this.ui_validate_form();

        if (!this.isOverrideValid) return;

        // Normalize arrays
        this.currentFormFields.location_ids = this.normalizeArray(this.currentFormFields.location_ids);
        this.currentFormFields.payer_ids = this.normalizeArray(this.currentFormFields.payer_ids);
        this.currentFormFields.insurance_class_ids = this.normalizeArray(this.currentFormFields.insurance_class_ids);
        this.currentFormFields.insurance_code_ids = this.normalizeArray(this.currentFormFields.insurance_code_ids);

        // Fields not present in the form, need to manually set them to proper values      
        this.currentFormFields.include_Future = JSON.stringify({
            locations: this.currentFormFields.location_ids && this.currentFormFields.location_ids.length === 1 && this.currentFormFields.location_ids[0] == -1,
            payers: this.currentFormFields.payer_ids && this.currentFormFields.payer_ids.length === 1 && this.currentFormFields.payer_ids[0] == -1,
            insurance_classes: this.currentFormFields.insurance_class_ids && this.currentFormFields.insurance_class_ids.length === 1 && this.currentFormFields.insurance_class_ids[0] == -1,
            insurance_codes: this.currentFormFields.insurance_code_ids && this.currentFormFields.insurance_code_ids.length === 1 && this.currentFormFields.insurance_code_ids[0] == -1,
        });

        this.currentFormFields.locations = this.currentFormFields.location_ids && this.currentFormFields.location_ids.length > 0 ? JSON.stringify({ Ids: this.currentFormFields.location_ids }) : '';
        this.currentFormFields.payers = this.currentFormFields.payer_ids && this.currentFormFields.payer_ids.length > 0 ? JSON.stringify({ Ids: this.currentFormFields.payer_ids }) : '';
        this.currentFormFields.insurance_Classes = this.currentFormFields.insurance_class_ids && this.currentFormFields.insurance_class_ids.length > 0 ? JSON.stringify({ Ids: this.currentFormFields.insurance_class_ids }) : '';
        this.currentFormFields.insurance_Codes = this.currentFormFields.insurance_code_ids && this.currentFormFields.insurance_code_ids.length > 0 ? JSON.stringify({ Ids: this.currentFormFields.insurance_code_ids }) : '';   

        this.currentFormFields.claim_Override_ID = this.currentFormFields.claim_Override_ID == '' ? 0 : this.currentFormFields.claim_Override_ID;

        //validate server side validations
        await action.validate_override_settingname_and_collision(this.props.token, this.currentFormFields, this.inMemoryOverrides).then(
            response => {
                if (response.data) {
                    this.isOverrideValid = response.data.data;

                    // #region Validation and Warning Message
                    let warning_message_array = []; 
                    let validation_message_array = []; 
                    if (response.data.messages && response.data.messages.length > 0) {
                        warning_message_array = response.data.messages.filter(message => message.code == this.overridesConstants.message_code.warning);
                        validation_message_array = response.data.messages.filter(message => message.code == this.overridesConstants.message_code.validation);
                    }
                    
                    if (!this.isOverrideValid && validation_message_array && validation_message_array.length > 0) {
                        this.show_toaster_error(validation_message_array)
                        this.setState({
                            saveDisabled: false
                        });
                    }
                    else if (this.isOverrideValid && warning_message_array && warning_message_array.length > 0) {
                        this.show_warning_message(warning_message_array);
                    }
                    else {
                        this.props.saveHandlerCallback(this.currentFormFields);
                    }
                    
                }
            },
            error => {
                this.isOverrideValid = false;
                let message = this.overridesConstants.messaging.errorGettingAnswerFromServer;
                if (error.response && error.response.data && error.response.data.messages && error.response.data.messages.length > 0)
                    message = error.response.data.messages[0].message;
                this.show_toaster_error(message);
                this.setState({saveDisabled: false});

            }
        );

    };

  normalizeArray = (value: any) => {
    if (!Array.isArray(value)) {
      return [parseInt(value)];
    }

    let integerCollection = value.map(function (x) { 
      return parseInt(x, 10); 
    });

    return integerCollection;
  };

  show_toaster_error = (messages) => {
    let toaster_message = "";

    if (!messages) {
      return;
    }

    if (Array.isArray(messages) && messages.length > 0) {
      messages.forEach((element) => {
          if (toaster_message != "")
              toaster_message = toaster_message + "<br>" + element.message;
          else
              toaster_message = element.message;
      });
    } else {
      toaster_message = messages;
    }

    if (toaster_message.indexOf('<br') > -1) {
        let toastr_options = this.show_html_content_toaster(toaster_message);
        toaster.error('', toastr_options);
    } else {
        toaster.error('', toaster_message);
    }
  };

  show_html_content_toaster = msg => {
      return {
          component: () => (
              <div>
                  <div dangerouslySetInnerHTML={{ __html: msg }} />
              </div>
          ),
          timeOut: global_constants.toastr_options.toastr_time_out,
          preventDuplicates: true
      };
  };

  onChangeInputsHandler = (e) => {
    const input_field = e.target.name,
          input_value = e.target.value.trim(),
          errors = this.state.formErrors,
          settingNameValid = input_value && this.required_field.settingName && is_null_or_white_space(input_value.trim());

    this.currentFormFields.needsPersistence = true;
    this.currentFormFields[input_field] = input_value;
    
    errors['settingNameMissing'] = settingNameValid;
    
    this.setState({
      formFields: this.currentFormFields,
      formErrors: errors
    });
  };

  onChangeLocationsHandler = (selected_list: number[]): void => {
      this.currentFormFields.location_ids = selected_list;
      this.currentFormFields.needsPersistence = true;
  };

  onChangePayersHandler = (selected_list: number[]): void => {
      this.currentFormFields.payer_ids = selected_list;
      this.currentFormFields.needsPersistence = true;
  };

  onChangeInsuranceClassHandler = (selected_list: number[]): void => {    
    
    this.currentFormFields.insurance_class_ids = selected_list;
    this.currentFormFields.needsPersistence = true;
    if (selected_list.length > 0) {
      this.setState({
        disable_insurance_code_tab: true
      });
    } else {
      this.setState({
        disable_insurance_code_tab: false
      });
    }
  };

  onChangeInsuranceCodeHandler = (selected_list: number[]): void => {
    this.currentFormFields.insurance_code_ids = selected_list;
    this.currentFormFields.needsPersistence = true;
    if (selected_list.length > 0) {
      this.setState({
        disable_insurance_class_tab: true
      });
    } else {
      this.setState({
        disable_insurance_class_tab: false
      });
    }
  };

    onChangeState = (value) => {
        this.currentFormFields["pay_To_State"] = value;
        this.setState({ formFields: this.currentFormFields });
        this.currentFormFields.needsPersistence = true;
    };

    onChangeStatusDropdownHandler = (value) => {
        
        this.currentFormFields["is_Active"] = value == "true" ? true :false;
        this.setState({ formFields: this.currentFormFields });
        this.currentFormFields.needsPersistence = true;
    }

    get_label_with_tool_tip = (tool_tip_text: string, label_text: string) => {
        return (
            <Tooltip
                content={<div className='tool-tip-style'>{tool_tip_text}</div>}
                position={'top'}
                hoverCloseDelay={300}
                hoverOpenDelay={300}>
                <label style={{ pointerEvents: 'none', outline: 'none' }}>
                    <span>{label_text}</span>
                </label>
            </Tooltip>    
        );
    }

    // #region Alert Box
    show_warning_message = (warning_message_array) => {
       
        if (!warning_message_array && warning_message_array.length == 0) return;

        let alert_config = this.state.alert;
        alert_config.showBox = true;
        alert_config.message = warning_message_array[0].message;
        this.setState({ alert: alert_config, saveDisabled: true });
    }

    on_cancel_alert_box = () => {
        let alert_config = this.state.alert;
        alert_config.showBox = false;
        alert_config.message = '';
        this.setState({ alert: alert_config, saveDisabled: false});
    }

    on_alert_continue = () => {
        let alert_config = this.state.alert;
        alert_config.showBox = false;
        alert_config.message = '';
        this.setState({ alert: alert_config })
        this.props.saveHandlerCallback(this.currentFormFields);
    }
    // #endregion Alert Box

  render() {
      const { formErrors, isSubmitted } = this.state;
      
    return (
    <React.Fragment>
        <div>
            <AlertConfirm
                open={this.state.alert.showBox}
                close={() => this.on_cancel_alert_box()}
                cancel={() => this.on_cancel_alert_box()}
                confirm={() => this.on_alert_continue()}
                message={this.state.alert.message}
                title={'Warning'}
                labelYes={'Continue'}
            />

            <div className='bp3-dialog-save-button'>
              <Button
                id='save_provider_claim_btn'
                className='primary'
                type='button'
                disabled={this.state.saveDisabled}
                onClick={(e) => this.saveHandler(e)}
                content={'Apply'}
              />
            </div>

            <Grid columns='equal'>
              <Grid.Row>
                <Grid.Column tablet={16} computer={8}>
                  <label>
                    Setting Name{' '}
                    <span
                      className={
                        isSubmitted && (formErrors.settingNameMissing || formErrors.settingNameRepeated)
                          ? 'req-alert'
                          : 'req-alert_normal'
                      }
                    >
                      (required)
                    </span>
                  </label>
                  <Input
                    autoComplete='off'
                    name='setting_Name'
                    id='setting_Name'
                    type='text'
                    disabled={this.state.saveDisabled}
                    defaultValue={this.currentFormFields.setting_Name || ''}
                    onChange={(e) => this.onChangeInputsHandler(e)}
                    maxLength={80}
                    className={
                        isSubmitted && (formErrors.settingNameMissing || formErrors.settingNameRepeated) ? 'req-border-inp' : ''
                    }
                  />
                </Grid.Column>
                <Grid.Column tablet={16} computer={8}>
                    <label>
                        <span>Status </span>
                    </label>
                    <Selection
                        id='status'
                        name='status'
                        disabled={this.props.saveDisabled}
                        options={status_select_options}
                        onChange={(value) => {
                            this.onChangeStatusDropdownHandler(value);
                        }}
                        defaultValue={this.state.formFields.is_Active}
                    />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                 <Grid.Column tablet={16} computer={8}>
                    {this.get_label_with_tool_tip(this.overridesConstants.tool_tip.tax_id, 'Tax ID Number')}
                    <Input
                        autoComplete='off'
                        name='tax_Identification_Number'
                        id='tax_Identification_Number'
                        type='text'
                        disabled={this.state.saveDisabled}
                        defaultValue={this.currentFormFields.tax_Identification_Number || ''}
                        onChange={(e) => this.onChangeInputsHandler(e)}
                        maxLength={9}
                        className={
                          isSubmitted && formErrors.invalidTIN ? 'req-border-inp' : ''
                        }
                    />
                 </Grid.Column>
                 <Grid.Column tablet={16} computer={8}>
                    {this.get_label_with_tool_tip(this.overridesConstants.tool_tip.npi, 'NPI')}
                    <Input
                        autoComplete='off'
                        name='national_Provider_Identifier'
                        id='national_Provider_Identifier'
                        type='text'
                        disabled={this.state.saveDisabled}
                        defaultValue={this.currentFormFields.national_Provider_Identifier || ''}
                        onChange={(e) => this.onChangeInputsHandler(e)}
                        maxLength={10}
                        className={
                            isSubmitted && formErrors.invalidNPI ? 'req-border-inp' : ''
                        }
                    />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column tablet={16} computer={8}>
                    {this.get_label_with_tool_tip(this.overridesConstants.tool_tip.taxonomy, 'Taxonomy')}
                    <Input
                        autoComplete='off'
                        name='taxonomy'
                        id='taxonomy'
                        type='text'
                        disabled={this.state.saveDisabled}
                        defaultValue={this.currentFormFields.taxonomy || ''}
                        onChange={(e) => this.onChangeInputsHandler(e)}
                        maxLength={10}
                        className={
                            isSubmitted && formErrors.invalidTaxonomy ? 'req-border-inp' : ''
                        }
                    />            
                </Grid.Column>
                <Grid.Column tablet={16} computer={8}>
                    {this.get_label_with_tool_tip(this.overridesConstants.tool_tip.name_address_city_state, 'Pay-To Name')}
                    <Input
                        autoComplete='off'
                        name='pay_To_Name'
                        id='pay_To_Name'
                        type='text'
                        disabled={this.state.saveDisabled}
                        defaultValue={this.currentFormFields.pay_To_Name || ''}
                        onChange={(e) => this.onChangeInputsHandler(e)}
                        maxLength={100}
                    />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column tablet={16} computer={8}>
                    {this.get_label_with_tool_tip(this.overridesConstants.tool_tip.name_address_city_state, 'Pay-To Address')}
                    <Input
                        autoComplete='off'
                        name='pay_To_Address'
                        id='pay_To_Address'
                        type='text'
                        disabled={this.state.saveDisabled}
                        defaultValue={this.currentFormFields.pay_To_Address || ''}
                        onChange={(e) => this.onChangeInputsHandler(e)}
                        maxLength={100}
                    />        
                </Grid.Column>
                <Grid.Column tablet={16} computer={8}>
                    {this.get_label_with_tool_tip(this.overridesConstants.tool_tip.name_address_city_state, 'Pay-To City')}
                    <Input
                        autoComplete='off'
                        name='pay_To_City'
                        id='pay_To_City'
                        type='text'
                        disabled={this.state.saveDisabled}
                        defaultValue={this.currentFormFields.pay_To_City || ''}
                        onChange={(e) => this.onChangeInputsHandler(e)}
                        maxLength={100}
                    />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column tablet={16} computer={8}>
                    {this.get_label_with_tool_tip(this.overridesConstants.tool_tip.name_address_city_state, 'Pay-To State')}
                    <Selection
                        name='pay_To_State'
                        id='pay_To_State'
                        placeHolder='Select'
                        options={this._transformed_state_options}
                        onChange={(value) => this.onChangeState(value)}
                        defaultValue={this.state.formFields.pay_To_State || ''}
                        hidden={true}
                    />
                </Grid.Column>
                <Grid.Column tablet={16} computer={8}>
                    {this.get_label_with_tool_tip(this.overridesConstants.tool_tip.name_address_city_state, 'Pay-To Zip Code')}
                    <Input
                      autoComplete='off'
                      name='pay_To_Zip_Code'
                      id='pay_To_Zip_Code'
                      type='text'
                      disabled={this.state.saveDisabled}
                      value={this.state.formFields.pay_To_Zip_Code || ''}
                      onChange={(e) => this.onChangeInputsHandler(e)}
                      maxLength={10}
                      className={
                          isSubmitted && formErrors.invalidPayerZip ? 'req-border-inp' : ''
                      }
                    />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className='last-row'>
                <Segment>
                  <Tabs animate={true} id='billing-tab-group' defaultSelectedTabId={this.state.defaultSelectedTabId} renderActiveTabPanelOnly={true}>
                    <Tab id='location_id' title='Location' panel={this.renderLocationsPanel()} />
                    <Tab id='payer_id' title='Payer' panel={this.renderPayersPanel()} />
                    <Tab
                      id='insurance_class_id'
                      disabled={this.state.disable_insurance_class_tab}
                      panel={this.renderInsuranceClassesPanel()}
                    >
                        <Tooltip
                        content={
                          <div>
                            Insurance Class tab is disabled while
                            <br />
                            Insurance Codes are selected.
                          </div>
                        }
                        disabled={!this.state.disable_insurance_class_tab}
                        position={'top'}
                      >
                        Insurance Class
                      </Tooltip>
                    </Tab>
                    <Tab
                      id='insurance_code_id'
                      disabled={this.state.disable_insurance_code_tab}
                      panel={this.renderInsuranceCodePanel()}
                    >
                    <Tooltip
                        content={
                          <div>
                            Insurance Code tab is disabled while
                            <br />
                            Insurance Classes are selected.
                          </div>
                        }
                        disabled={!this.state.disable_insurance_code_tab}
                        position={'top'}
                      >
                        Insurance Code
                      </Tooltip>
                    </Tab>
                    <Tabs.Expander />
                  </Tabs>
                </Segment>
              </Grid.Row>
            </Grid>
        </div>
      </React.Fragment>
    );
  }

  private renderLocationsPanel = () => {
    let selectedLocationsItems = [];
    this.currentFormFields.location_ids = this.currentFormFields.location_ids ? this.currentFormFields.location_ids : [];

    if (this.currentFormFields.location_ids.length > 0) {
      // Sanitize array
      selectedLocationsItems = [...this.currentFormFields.location_ids].toString().split(",").map(function (x) { 
        return parseInt(x, 10); 
      });
    }

    let is_include_future_selected = false;
    if (selectedLocationsItems && selectedLocationsItems.length === 1 && selectedLocationsItems[0] === -1) {
      is_include_future_selected = true;
    }

    return (
      <div>
        <MultiSelectPanelComponent
          id='location-multi-select'
          data={this.tabLocationsData}
          selected_list={selectedLocationsItems}
          onChangeSelected={(selectedItems) => {
            this.onChangeLocationsHandler([...selectedItems]);
          }}
          ref='location_ids'
          include_future_selected={is_include_future_selected}
          include_future={true}
          displayExtended={true}
        />
      </div>
    );
  };

  private renderPayersPanel = () => {
    let selectedPayersItems = [];
    this.currentFormFields.payer_ids = this.currentFormFields.payer_ids ? this.currentFormFields.payer_ids : [];

    if (this.currentFormFields.payer_ids.length > 0) {
      selectedPayersItems = [...this.currentFormFields.payer_ids].toString().split(",").map(function (x) { 
        return parseInt(x, 10); 
      });
    }

    let includeFutureChecked = false;
    if (selectedPayersItems && selectedPayersItems.length === 1 && selectedPayersItems[0] === -1) {
      includeFutureChecked = true;
    }

    return (
      <div>
        <MultiSelectPanelComponent
          id='payer-multi-select'
          data={this.tabPayersData}
          selected_list={selectedPayersItems}
          onChangeSelected={(selectedItems) => {
            this.onChangePayersHandler([...selectedItems]);
          }}
          ref='payer_ids'
          include_future_selected={includeFutureChecked}
          include_future={true}
          displayExtended={true}
        />
      </div>
    );
  };

  private renderInsuranceClassesPanel = () => {
    let selectedInsuranceClassItems = [];
    this.currentFormFields.insurance_class_ids = this.currentFormFields.insurance_class_ids ? this.currentFormFields.insurance_class_ids : [];

    if (this.currentFormFields.insurance_class_ids.length > 0) {
      selectedInsuranceClassItems = [...this.currentFormFields.insurance_class_ids].toString().split(",").map(function (x) { 
        return parseInt(x, 10); 
      });
    }

    let includeFutureChecked = false;
    if (selectedInsuranceClassItems && selectedInsuranceClassItems.length === 1 && selectedInsuranceClassItems[0] === -1) {
      includeFutureChecked = true;
    }

    return (
      <div>
        <MultiSelectPanelComponent
          id='insurance-class-multi-select'
          data={this.tabInsuranceClassesData}
          selected_list={selectedInsuranceClassItems}
          onChangeSelected={(selectedItems) => {
            this.onChangeInsuranceClassHandler([...selectedItems]);
          }}
          ref='insurance_class_ids'
          include_future_selected={includeFutureChecked}
          include_future={false}
          displayExtended={true}
        />
      </div>
    );
  };

  private renderInsuranceCodePanel = () => {
    let selectedInsuranceCodeItems = [];
    this.currentFormFields.insurance_code_ids = this.currentFormFields.insurance_code_ids ? this.currentFormFields.insurance_code_ids : [];

    if (this.currentFormFields.insurance_code_ids.length > 0) {
      selectedInsuranceCodeItems = [...this.currentFormFields.insurance_code_ids].toString().split(",").map(function (x) { 
        return parseInt(x, 10); 
      });
    }

    let includeFutureChecked = false;
    if (selectedInsuranceCodeItems && selectedInsuranceCodeItems.length === 1 && selectedInsuranceCodeItems[0] === -1) {
      includeFutureChecked = true;
    }

    return (
      <div>
        <MultiSelectPanelComponent
          id='insurance-code-multi-select'
          data={this.tabInsuranceCodesData}
          selected_list={selectedInsuranceCodeItems}
          onChangeSelected={(selectedItems) => {
            this.onChangeInsuranceCodeHandler([...selectedItems]);
          }}
          ref='insurance_code_ids'
          include_future_selected={includeFutureChecked}
          include_future={false}
          displayExtended={true}
        />
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    user_login_details: state.user_login_details
  };
};

export default connect(mapStateToProps)(LocationOverrideComponent);
