import * as payment_constants from '../../payment_constants';

export default function (state = [], { type, payload }) {

    switch (type) {

        case payment_constants.BATCH_REFERENCE_DATA:
            return { ...state, ["batch_reference"]: payload.data };

       
        default:
            return state
    }

}

