import * as global_constants from '../../global_constants';
import { RequestConfig } from '../../shared/network/config';
import { Request } from '../../shared/network/request';
import * as local_storage from '../../shared/local_storage_utility';
import * as login_constants from '../../login/login_constants';
import { log_error } from '../../shared/action/shared_action';

export const oauth_authentication = (code, state) => {
  const url = `${global_constants.base_end_points_v2}${global_constants.end_points.oauth_urls.exchange}`;
  const data = {
    code: code,
    state: state,
  };

  let request = new Request();
  let request_config = new RequestConfig({
    url: url,
    method: "post",
    data: data,
  });

  return (dispatch) =>
    request.make(request_config).then(
      (response) => {
        // Set Token Expiration Time
        if (response.data.data && response.data.data.token_details) {
          let expiresTime = new Date();
          expiresTime = new Date(
            expiresTime.setSeconds(
              expiresTime.getSeconds() +
                parseInt(response.data.data.token_details.expires_in)
            )
          );

          response.data.data.token_details["expires_time"] = expiresTime;
          // set auth data in local storage
          local_storage.set("auth_data", response.data.data);
        }
        dispatch({
          type: login_constants.USER_AUTHENTICATION,
          payload: response,
        });
      },
      (error) => {
        log_error(error);
      }
    );
};