import * as React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { withRouter } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import { bindActionCreators } from "redux";
import { Button, Dimmer, Form, Grid, Loader } from "semantic-ui-react";
import { clearTimeout, setTimeout } from "timers";
import { clear_patient_header, custom_prompt_routes_for_account_notes_popup } from "../../global_constants";
import { add_patient_account_notes, get_patient_account_notes, get_patient_account_notes_list, get_patient_account_notes_status_action, get_patient_account_note_code, get_patient_account_tasks, remove_patient_account_notes, set_patient_account_notes_and_task } from "../../patient/action/patient_action";
import AccountNotesPrintNotesComponent from "../../patient/component/account_notes_print_notes_component";
import { patient_messages, sticky_code_list } from "../../patient/patient_constants";
import GridView from "../../shared/component/grid";
import { GRID_NAME } from "../../shared/component/grid/constants";
import Selection from "../../shared/component/selection_component";
import { set_notes_modal_status } from "../action/shared_action";
import { account_notes_modal_configuration } from "../shared_constants";
import { handle_focus_on_tab, set_focus_on_element_with_id } from "./../../shared/tab_navigation_utility";
import * as session_storage from "./../../shared/session_storage_utility";
import PromptNavigation from "./prompt_navigation_component";
import AutoSearchComponent from './../../shared/component/auto_search_component';
import AdvancedControl from "../../shared/component/advanced_control";
import { quick_search_note_code, search_note_code } from './../../shared/action/autosearch_action';
import * as shared_constants from '../../shared/shared_constants';
import * as global_constants from '../../global_constants';
import { enum_date_type, enum_type_of_search, enum_case_page_state, case_messages } from './../../case/case_constants';
import * as ReportConstants from '../../reports/report_constants';
import { save_billing_audit } from "../../reports/action/report_action";

/*1) Display the current patient's Account notes
2) Add New Account notes component used
3) Delete Existing Account notes implemented on the same page
*/
export class AccountNotesViewAddComponent extends React.Component<any, any> {
    grid_reference: React.RefObject<unknown>;
    header_height: number;
    grid_width: Element;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            shown: false,
            print_notes_shown: false,
            patient_id: props.patient_id,
            selected_account_notes: [],
            db_click: false,
            double_click_select: [],
            grid_params: {},
            patient_account_task_notes: [],
            note_fatch_time: "",
            formatted_patient_account_note_codes: [],
            by_pass_prompt: false,
            form_object: {},
            is_save_button_disabled: false,
            note_code_search_data: {},
            note_code_quick_search_format:
            {
                "title": '',
                "label": '',
                "note_code": ''
            },
            invalid_code: false
        };
        this.grid_reference = React.createRef();
    }

    // Define initial properties and its value.
    is_mounted = false;
    form_object: any = {
        patient_id: this.props.patient_id,
        note_code_id: 0,
        note_code: '',
        note_text: '',
        show_on_patient: false,
        show_on_charge: false,
        show_on_payment: false,
        show_on_case: false,
        stick_on: "-- Not A Sticky --",
        loading: false
    };
    initial_form_object: any = {};
    go_next: boolean = false;
    by_pass_prompt: boolean = false;
    total_column_width: number = 0;
    grid_header_height: number = 0;
    account_notes = {
        column: [],
        rows: []
    };

    UNSAFE_componentWillMount() {
        this.get_patient_account_note_code(this.state.patient_id);
        this.initial_form_object = { ...this.form_object };
        if (this.props.onRef) {
            this.props.onRef(this);
        }
        this.account_notes.column = account_notes_modal_configuration.column_defs;
    }

    componentDidMount() {
        this.is_mounted = true;
        this.get_account_details(this.state.patient_id);
        this.setState({
            form_object: this.form_object
        });
        this.grid_width = document.getElementsByClassName("description")[0];
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.patient_id != this.props.patient_id) {
            if (this.props.patient_id !== "") {
                if (this.is_mounted) {
                    this.props.set_notes_modal_status(true);
                    this.form_object = {
                        ...this.form_object,
                        patient_id: this.props.patient_id
                    };
                    this.setState({
                        patient_id: this.props.patient_id,
                        selected_account_notes: []
                    });
                }
                this.get_account_details(this.props.patient_id);
            } else {
                this.props.set_notes_modal_status(false);
                this.setState({
                    by_pass_prompt: true
                });
            }
        }

        if (previousProps.account_shown != this.props.account_shown) {
            if (this.is_mounted) {
                this.setState({
                    shown: !this.state.shown
                });
            }
        }

        //account notes clear selection
        if (previousProps.note_fatch_time != this.props.note_fatch_time) {
            if (this.is_mounted) {
                this.setState({
                    timeout: setTimeout(() => {
                        if (this.state.grid_params.api) {
                            this.state.grid_params.api.forEachNode((node: { setSelected: (arg0: boolean) => void }) => {
                                node.setSelected(false);
                            });
                        }
                    }, 200)
                });
            }
        }
    }

    componentWillUnmount() {
        this.is_mounted = false;
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
        }
        if (this.props.onRef) {
            this.props.onRef(undefined);
        }
    }

    get_account_details = (patient_id: any) => {
        this.props.get_patient_account_notes_list(
            patient_id,
            this.props.user_login_details.user_data.data.token_details.access_token,
            this.props.data_model_key
        );
    };

    // Print Button Functionality
    on_print_click(selected_account_notes: string | any[]): any {
        if (selected_account_notes.length === 0) {
            toastr.warning("", patient_messages.select_record);
        } else {
            if (this.is_mounted) {
                this.setState({
                    print_notes_shown: true
                });

                this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
            }
        }
    }

    private saveReportEventAction = (eventActionId: number) => {
        let reqBody =
        {
            reportId: "0",
            reportEventActionId: eventActionId
        };

        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        save_billing_audit(reqBody, accessToken);
    };

    // Remove Button Functionality
    on_remove_sticky_click = async () => {
        var selected_account_notes_length = this.state.selected_account_notes.length;
        var remove_note_list = [];
        var hasNote = false;

        // If record selected
        if (selected_account_notes_length > 0) {
            for (let k = 0; k < selected_account_notes_length; k++) {
                var item = this.state.selected_account_notes[k];

                if (!item.isTask) {
                    hasNote = true;
                }
                if (item.stick_on != null && item.stick_on != "" && item.stick_on != "-- Not A Sticky --") {
                    remove_note_list.push(item.id);
                }
            }

            if (remove_note_list.length == 0) {
                toastr.error("", patient_messages.note_already_removed);
                return;
            }
            if (this.is_mounted) {
                this.setState({
                    loading: true
                });
            }

            // API call to remove data
            await this.props.remove_patient_account_notes(
                this.props.user_login_details.user_data.data.token_details.access_token,
                this.state.patient_id,
                remove_note_list
            );
            if (
                this.props.patient_details.selected_patient_remove_account_notes &&
                this.props.patient_details.selected_patient_remove_account_notes.data
            ) {
                var errorMessage = hasNote ? patient_messages.note_removed : patient_messages.note_already_removed;
                toastr.success("", errorMessage);

                // Get New Data
                this.get_account_details(this.state.patient_id);
                this.props.get_patient_account_notes_status({
                    updated: true,
                    addedNoteType: "Payment"
                });
                this.props.get_patient_account_notes_status({
                    updated: true,
                    addedNoteType: "Charge"
                });
            }
            if (this.is_mounted) {
                this.setState({
                    loading: false,
                    selected_account_notes: []
                });
            }
        } else {
            toastr.warning("", patient_messages.no_records_selected);
        }
    };

    // Double click on account notes row
    // Open modal popup for viewing data
    on_view_account_notes = (selectedRows: any) => {
       
            if (this.is_mounted) {
                this.setState({
                    db_click: true
                });
            }
            var selected_row_arr = [];
            selected_row_arr[0] = selectedRows;
            this.double_click_selection(selected_row_arr);
            this.on_print_click(selectedRows);
    };

    // Click functin of checkbox
    double_click_selection = (items: any) => {
        if (this.is_mounted) {
            this.setState({
                double_click_select: items
            });
        }
    };

    // Click functin of checkbox
    on_check_box_selection = (items: any) => {
        if (this.is_mounted) {
            this.setState({
                selected_account_notes: items
            });
        }
    };

    // Close Print Modal
    close_print_modal = () => {
        if (this.is_mounted) {
            this.setState({
                print_notes_shown: false
            });
        }
        if (this.state.db_click === true) {
            if (this.is_mounted) {
                this.setState({
                    double_click_select: [],
                    db_click: false
                });
            }
        }
    };

    get_grid_params = (grid_params: any) => {
        if (this.is_mounted) {
            this.setState({
                grid_params: grid_params
            });
        }
    };

    get_rows = () => {
        let data_model = (this.props.patient_details && this.props.patient_details.dialog_account_notes) || {};
        this.account_notes.rows = data_model.account_task_notes || [];
        return data_model.task_notes_loading;
    };

    // get formatted patient account note code from the patient id
    get_patient_account_note_code = async (patient_id: any) => {
        var is_from_patient = true;
        if (this.props.get_data == false) {
            is_from_patient = false;
        }
        await this.props.get_patient_account_note_code(
            patient_id,
            is_from_patient,
            this.props.user_login_details.user_data.data.token_details.access_token
        );

        var formatted_patient_account_note_codes = [];
        if (
            this.props.patient_details.selected_patient_account_notes_codes &&
            this.props.patient_details.selected_patient_account_notes_codes.data
        ) {
            var code_length = this.props.patient_details.selected_patient_account_notes_codes.data.length;
            formatted_patient_account_note_codes.push({ key: 0, text: "Select", value: 0 });
            for (var i = 0; i < code_length; i++) {
                var code = this.props.patient_details.selected_patient_account_notes_codes.data[i];
                formatted_patient_account_note_codes.push({
                    key: code.note_code_id,
                    text: code.note_code,
                    value: code.note_code_id
                });
            }
            if (this.is_mounted) {
                this.setState({
                    formatted_patient_account_note_codes: formatted_patient_account_note_codes
                });
            }
        }
    };

    input_change_handler = (e: { target: { value: any; name?: string } }) => {
        const { name, value } = e.target;
        this.form_object[name] = value;
        if (this.is_mounted) {
            this.setState({
                form_object: {
                    ...this.form_object
                }
            });
        }
    };

    dropdown_change_handler = (data: any, e: { target: { name: any; value: any } }) => {
        const { name, value } = e.target;
        let { formatted_patient_account_note_codes } = this.state;
        if (name === "stick_on") {
            this.form_object[name] = value;
            this.form_object.show_on_case = value == "Case" ? true : false;
            this.form_object.show_on_charge = value == "Charge" ? true : false;
            this.form_object.show_on_patient = value == "Patient" ? true : false;
            this.form_object.show_on_payment = value == "Payment" ? true : false;
        } else {
            this.form_object.note_code_id = value;
            this.form_object.note_code = formatted_patient_account_note_codes.find(
                (i: { value: any }) => i.value == value
            ).text;
        }

        if (this.is_mounted) {
            this.setState({
                form_object: {
                    ...this.form_object
                }
            });
        }
    };


    // save new note data
    save_new_note = async (e: React.FormEvent<EventTarget>, from_pop_up: boolean) => {
        this.go_next = false;
        var invalid_code = this.form_object.note_code_id == 0;
        if (this.is_mounted) {
            this.setState({
                is_submitted: true,
                is_save_button_disabled: true,
                selected_account_notes: []
            });
        }
        if (
            !this.form_object.note_text ||
            !this.form_object.note_text.replace(/\s/g, "") ||
            this.form_object.note_code_id == 0
        )
        {
            toastr.error("", patient_messages.required_fields);
            if (this.is_mounted) this.setState({ is_save_button_disabled: false, invalid_code});
            return;
        }
        await this.props.add_patient_account_notes(
            this.state.patient_id,
            this.props.user_login_details.user_data.data.token_details.access_token,
            this.form_object
        );
        this.props.get_patient_account_notes_status({
            updated: true,
            addedNoteType: this.form_object.stick_on
        });

        if (
            this.props.patient_details.selected_patient_add_account_notes &&
            this.props.patient_details.selected_patient_add_account_notes.data
        ) {
            toastr.success("", patient_messages.note_added);
            this.go_next = true;
            this.initial_form_object = { ...this.form_object };
            if (from_pop_up) {
                if (this.is_mounted) {
                    this.setState(
                        {
                            loading: false,
                            by_pass_prompt: false,
                            is_submitted: false,
                            is_save_button_disabled: false
                        },
                        () => this.props.set_notes_modal_status(false)
                    );
                }
            } else {
                this.get_account_details(this.state.patient_id);
                this.go_next = false;
                if (this.is_mounted) {
                    this.form_object = {
                        patient_id: this.props.patient_id,
                        note_code_id: 0,
                        note_code: "",
                        note_text: "",
                        show_on_patient: false,
                        show_on_charge: false,
                        show_on_payment: false,
                        show_on_case: false,
                        stick_on: "-- Not A Sticky --",
                        loading: false
                    };
                    this.clear_quick_search;
                    this.initial_form_object = { ...this.form_object };
                    this.setState({
                        form_object: { ...this.form_object },
                        loading: false,
                        by_pass_prompt: false,
                        is_save_button_disabled: false,
                        is_submitted: false,
                        note_code_quick_search_format: {
                            "title": '',
                            "label": '',
                            "note_code": ''
                        }
                    });
                }
            }
        }
    };

    //Cancel button is only visible on patient header (Account note icon)
    on_cancel_click = () => {
        if (this.is_mounted) {
            // if modal is going to close then set the focus to parent element
            if (this.state.show_modal) {
                set_focus_on_element_with_id("trigger_add_new_notes_button_patient_header");
            }
            this.initial_form_object = { ...this.form_object };
            this.setState(
                {
                    form_object: { ...this.form_object },
                    loading: false,
                    by_pass_prompt: false,
                    is_save_button_disabled: false,
                    is_submitted: false
                },
                () => this.props.set_notes_modal_status(false)
            );
        }
    };

    handle_on_open_modal_window = () => {
        // setTimeout(() => {
        let closeIcons: NodeListOf<HTMLElement> = document.querySelectorAll("i.close.icon") as NodeListOf<HTMLElement>;
        let closeIcon = closeIcons[closeIcons.length - 1];
        closeIcon.tabIndex = 0;
        closeIcon.id = "add_new_account_notes_model_close_button";
        closeIcon.addEventListener("keydown", function (event) {
            if (event.shiftKey && event.keyCode == 9) {
                event.preventDefault();
                set_focus_on_element_with_id("save_button_add_account_note");
            }
            if (event.keyCode == 13) {
                event.preventDefault();
                var el = event.target as HTMLElement;
                el.click();
            }
        });
        // }, 200);
    };

    handle_on_grid_out = () => {
        if (this.props.is_patient_header) {
            set_focus_on_element_with_id("patient_header_close_button");
        } else {
            set_focus_on_element_with_id("account_notes_remove_sticky_button");
        }
    };

    is_page_prompt_open = () => {

        let status = session_storage.get("_prompt_status");

        return status && status.page ? status.page : false;
    }


    on_blur_auto_search = (e) => {
        var elem_note_code_value = document.getElementById('note_code')['value'];
        let code_invalid = elem_note_code_value == "";
        if (this.is_mounted) {
            this.setState({
                invalid_code: code_invalid
            });
        }

    }

    on_note_code_grid_row_selection = (selected_row) => {
        var id = '';
        var name = '';
        var code = '';
        var selected_data = '';
        var formobj_state = this.state.form_object;
        var nc_invalid = this.state.invalid_code;
        if (selected_row) {
            id = selected_row.id;
            code = (selected_row.code == null && selected_row.code == undefined) ? '' : selected_row.code;
            name = (selected_row.description == null && selected_row.description == undefined) ? '' : selected_row.description;
        }
        selected_data = code + " - " + name;
        var format_nc = { "label": '', "title": '', "note_code": '' };
        if (parseInt(id) > 0) {
            // Set Auto search control for action code
            format_nc = {
                "title": id.toString(),
                "note_code": code,
                "label": selected_data
            };
        }
        this.form_object.note_code_id = id;
        nc_invalid = false;
        if (this.is_mounted) {
            this.setState({
                note_code_quick_search_format: format_nc,
                form_object: { ...this.form_object },
                invalid_code: nc_invalid
            });
        }

    }

    //Auto search for Note Code
    render_suggestion_result = (props) => {
        return (
            <div key={props.title} tabIndex={0} className="item_auto_search">
                <div key={props.title} className="fs_13">
                    <span>{props.label}</span>
                </div>
            </div>

        )
    }

    // function/method to handle the when searching of note code occurs
    on_note_code_search = async (params) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        params = params ? params : {};
        params.is_from_patient = true;
        var grid_data = [];
        const search_data_note_code = await search_note_code(params, token).then(res => res.data);
        const search_note_code_result = search_data_note_code.data !== null ? search_data_note_code.data.result : [];
        grid_data = { ...this.state.grid_conf, rows: search_note_code_result, column: shared_constants.search_note_code_configuration(enum_type_of_search.note_code + 'grid_header_id').column_defs, messages: search_data_note_code.messages };
        if (this.is_mounted) {
            this.setState({ note_code_search_data: grid_data });
        }
    }

    // get the quick search note code
    get_note_code_quick_search_data_list = async (search_keyword) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await quick_search_note_code(search_keyword, token);
    }

    // select the item on click suggested items
    on_item_selection = (item) => {
        var state = { ...this.state }
        state.invalid_code = false;
        this.form_object.note_code_id = item.title;
        if (this.is_mounted) {
            this.setState({
               form_object: { ...this.form_object }
           });
         }
    }

    // prepare the suggestion list with search result for note code
    prepare_suggestion = (_data) => {
        let formattedList = [];
        var data_length = 0;
        if (_data.data) {
            data_length = _data.data.length;
            _data = _data.data;
        }
        else {
            data_length = _data.length;
        }
        if (_data && data_length) {
            for (var i = 0; i < data_length; i++) {
                var item = _data[i];
                formattedList.push({
                    "title": `${item.id}`,
                    "code": item.code,
                    "name": item.description,

                    "label": (item.code) + " - " + (item.description),
                })
            }
        }
        return formattedList;
    }

    clear_quick_search = () => {
        var state = { ...this.state }
        state.form_object.note_code_id = 0;
        state.form_object.note_text = "";
        state.invalid_code = true;
        if (this.is_mounted) {
            this.setState({
                note_code_quick_search_format: {
                    "title": '',
                    "label": '',
                    "code": ''
                },
            });
        }
    }

    render() {
        let is_loading = this.get_rows();
        if (this.state.loading == true) {
            is_loading = this.state.loading;
        }

        return (
            <React.Fragment>
                {!this.is_page_prompt_open() && !this.state.by_pass_prompt && (
                    <PromptNavigation
                        is_data_changed={JSON.stringify(this.initial_form_object) !== JSON.stringify(this.state.form_object)}
                        save={(e: any) => this.save_new_note(e, true)}
                        go_next_location={this.go_next}
                        history={this.props.history}
                        allow_for_selective_routes={custom_prompt_routes_for_account_notes_popup}
                        is_modal={true}
                    />
                )}
                <Dimmer active={is_loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <Grid.Column style={{ flex: "1", minHeight: "160px" }}>
                    <GridView
                        id={this.props.is_patient_header ? "account_notes_patient_header_grid_id" : "account_notes_grid_id"}
                        row={this.account_notes.rows}
                        column={this.account_notes.column}
                        gridAutoHeight={false}
                        checkboxSelection={true}
                        onRowDoubleClicked={this.on_view_account_notes}
                        onRowSelection={this.on_check_box_selection}
                        wrapperStyle={{ height: "100%" }}
                        style={{ height: "100%" }}
                        get_grid_ref={this.get_grid_params}
                        name={GRID_NAME.ACCOUNT_NOTES}
                        onGridOut={this.handle_on_grid_out}
                        enableEnterOnNavigation={true}
                        onRef={(instance: any) => {
                            this.grid_reference = instance;
                        }}
                        handleCheckboxNavigation={true}
                    />
                </Grid.Column>
                <div className='inner-header'>New Note</div>
                <Form className='common-forms'>
                    <Grid>
                        <Grid.Column computer={16}>
                            <Form.Field>
                                <label>
                                    Note{" "}
                                    <span
                                        className={
                                            this.state.is_submitted &&
                                                (!this.state.form_object.note_text || !this.state.form_object.note_text.replace(/\s/g, ""))
                                                ? "req-alert"
                                                : "req-alert_normal"
                                        }
                                    >
                                        (required)
                  </span>
                                </label>
                                <TextareaAutosize
                                    name='note_text'
                                    id={"note_text"}
                                    maxLength={2000}
                                    autoFocus
                                    autoComplete='new-note-value'
                                    className={
                                        this.state.is_submitted &&
                                            (!this.state.form_object.note_text || !this.state.form_object.note_text.replace(/\s/g, ""))
                                            ? "red-error-thin requiredWithBgColor"
                                            : ""
                                    }
                                    onChange={this.input_change_handler}
                                    value={this.state.form_object["note_text"] ? this.state.form_object["note_text"] : ""}
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid>
                    <Grid>
                        <Grid.Column>
                            <Grid>
                                <Grid.Column tablet={8} computer={8}>
                                    <Form.Field className={this.state.invalid_code && this.state.is_submitted ? "advance-quick-search case-reg-form requiredWithBgColor" : ' advance-quick-search case-reg-form'}>
                                        <label>Code <span className={this.state.invalid_code && this.state.is_submitted ? 'req-alert' : 'req-alert_normal'}>(required)</span></label>
                                        <AdvancedControl
                                            onGridRowSelection={this.on_note_code_grid_row_selection}
                                            gridConfig={this.state.note_code_search_data}
                                            controlId={global_constants.constants.advanced_control_type.note_code}
                                            onSearch={this.on_note_code_search}
                                            search_type={enum_type_of_search.note_code}
                                            headerIdForGridTabNavigation={enum_type_of_search.note_code + 'grid_header_id'}
                                        />
                                        <AutoSearchComponent
                                            control_id={global_constants.constants.advanced_control_type.note_code}
                                            default_value={this.state.note_code_quick_search_format}
                                            errorMessage={'No Note Code Found !'}
                                            prepareRenderList={this.render_suggestion_result}
                                            getList={this.get_note_code_quick_search_data_list}
                                            prepareDataList={(data) => this.prepare_suggestion(data)}
                                            selectresult={(item) => this.on_item_selection(item)}
                                            is_focus={false}
                                            show_clear_search={true}
                                            clear_search={() => this.clear_quick_search()}
                                            errorClass={this.state.invalid_code && this.state.is_submitted ? 'req-background-inp' : ''}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column tablet={8} computer={8}>
                                    <Form.Field>
                                        <label>Stick on</label>
                                        <Selection
                                            id={"id_stick_on"}
                                            name={"stick_on"}
                                            defaultValue={this.state.form_object.stick_on}
                                            options={sticky_code_list}
                                            onChange={(value: any, e: any) => {
                                                this.dropdown_change_handler(value, e);
                                            }}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>
                    </Grid>
                </Form>
                <Grid.Column computer={16} style={{ textAlign: "right" }} className='mar-t-15'>
                    <Button basic id='patient_header_close_button' onClick={this.props.onCancel || this.on_cancel_click}>
                        Cancel
                    </Button>
                    <Button basic id='account_notes_remove_sticky_button' onClick={this.on_remove_sticky_click}>
                        Remove Sticky
                    </Button>
                    <Button
                        basic
                        id={
                            this.props.is_patient_header
                                ? "print_selected_notes_button_patient_header"
                                : "print_selected_notes_button"
                        }
                        onClick={() => this.on_print_click(this.state.selected_account_notes)}
                        >
                        Print Selected Notes
                    </Button>
                    <Button
                        type='submit'
                        id='save_button_add_account_note'
                        onKeyDown={(event) => handle_focus_on_tab(event, "add_new_account_notes_model_close_button")}
                        primary
                        onClick={(e) => this.save_new_note(e, false)}
                        disabled={this.state.is_save_button_disabled}
                    >
                        Save
                    </Button>
                    {this.state.print_notes_shown && (
                        <AccountNotesPrintNotesComponent
                            is_patient_header={this.props.is_patient_header}
                            head={this.props.patient_data}
                            open={this.state.print_notes_shown}
                            note_info={
                                this.state.double_click_select.length > 0
                                    ? this.state.double_click_select
                                    : this.state.selected_account_notes
                            }
                            patient_id={this.props.patient_id}
                            patient_name={this.props.patient_name}
                            gpms_code={this.props.gpms_code}
                            company_name={this.props.company_name}
                            onCloseModal={this.close_print_modal}
                        />
                    )}
                </Grid.Column>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            remove_patient_account_notes: remove_patient_account_notes,
            get_patient_account_notes_status: get_patient_account_notes_status_action,
            get_patient_account_notes: get_patient_account_notes,
            get_patient_account_tasks: get_patient_account_tasks,
            set_patient_account_notes_and_task: set_patient_account_notes_and_task,
            get_patient_account_notes_list: get_patient_account_notes_list,
            add_patient_account_notes: add_patient_account_notes,
            get_patient_account_note_code: get_patient_account_note_code,
            set_notes_modal_status: set_notes_modal_status
        },
        dispatch
    );
};

const mapStateToProps = (state: {
    user_login_details: any;
    user_details: any;
    shared_details: any;
    patient_details: any;
    launch_darkly: any;
}) => {
    return {
        user_login_details: state.user_login_details,
        user_details: state.user_details,
        shared_details: state.shared_details,
        patient_details: state.patient_details,
        check_prompt_for_logout: state.shared_details.prompt_for_logout,
        show_notes_modal: state.shared_details.show_notes_modal        
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountNotesViewAddComponent));
