import * as global_constants from './../../global_constants';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request';

//To add a new case
export const get_charge_case_list = (token, patient_id) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${global_constants.end_points.charge_url.charge_case_list}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
    })

    return request.make(requestConfig);
}
export const get_charge_case_pre_auth_list = (token, case_id, in_active_pre_auth) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.subscriber_url.subscriber}/${case_id}/${in_active_pre_auth}${global_constants.end_points.charge_url.pre_authorization_list}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
    })

    return request.make(requestConfig);
}
export const get_charge_case_summary = (token, patient_id, case_id) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}${global_constants.end_points.case_url.case}/${case_id}${global_constants.end_points.case_url.case_summary}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
    })

    return request.make(requestConfig);
}
//To add a new charge
export const add_charge = (token, charge_obj, is_edit = false) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.charge_url.charge}/${is_edit}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': charge_obj
    })

    return request.make(requestConfig)


}

export const get_filtered_case_list = (token, api) => {
    var data = { api: api };
    const url = `${global_constants.base_end_points}${global_constants.end_points.charge_url.charge}/filtered_case_list`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'silversurfer': JSON.stringify(data)
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'       
    })
    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

export const get_claim_holds_summary_page_metadata = (token, params) => {
	const url = `${global_constants.base_end_points}${global_constants.end_points.charge_url.charge}/claim_holds_summary_metadata`;
	let request = new Request();
	let headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + token
	}

	let request_config = new RequestConfig({
		'url': url,
		'headers': headers,
		'method': 'get',
		params
	})
	return request.make(request_config).then(response => {
		if (response) {
			return response;
		}
	});
};

export const get_claim_holds_summary_search_data = (token, params) => {
	const url = `${global_constants.base_end_points}${global_constants.end_points.charge_url.charge}/claim_holds_summary`;
	let request = new Request();
	let headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + token,
		'silversurfer': JSON.stringify(params)
	}

	let request_config = new RequestConfig({
		'url': url,
		'headers': headers,
		'method': 'get'
	})
	return request.make(request_config).then(response => {
		if (response) {
			return response;
		}
	});
};

export const get_release_claim_search_data = (token, params) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.charge_url.charge}/release_claims`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'silversurfer': JSON.stringify(params)
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'       
    })
    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

export const add_release_claim_delay = (token, release_claim_obj) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.charge_url.charge}/release_claims`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': release_claim_obj
    })
    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
}

//To add a new case
export const get_patient_view_charge_list = (token, patient_id) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.charge_url.charge}/patients/${patient_id}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
    })

    return request.make(requestConfig);
}


export const delete_charge = (token, list_charge_ids) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.charge_url.charge}/delete_charge`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': list_charge_ids
    })

    return request.make(requestConfig);

}
export const get_charge_data = (token, charge_id, is_visit) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.charge_url.charge}/${charge_id}/${is_visit}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }
    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
    })
    return request.make(requestConfig);
};
export const get_charge_review_summary = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.charge_url.review_charge_summary}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }
    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
    })
    return request.make(requestConfig);
};


export const release_charge = (token, release_charges, company_id) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.charge_url.release_charge}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': release_charges,
        'params': {
            company_id: company_id
        }
    })

    return request.make(requestConfig);

}


export const release_charge_Detail = (token, release_charges, company_id) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.charge_url.release_charge_detail}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': release_charges,
        'params': {
            company_id: company_id
        }
    })

    return request.make(requestConfig);

}


export const release_audit_charge = (token, release_charges) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.charge_url.release_audit_charge}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': release_charges
    })

    return request.make(requestConfig);

}

export const release_audit_charge_handler = (token, releaseCharges) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.charge_url.release_audit_charges_handler}`;
    
    let request = new Request();
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': releaseCharges
    })

    return request.make(requestConfig);
}

export const get_charge_review_details = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.charge_url.charge_review_details}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'silversurfer': JSON.stringify(param)
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(requestConfig);
}
export const get_charge_audit_details = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.charge_url.charge_audit_details}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'silversurfer': JSON.stringify(param)
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(requestConfig);
}
export const get_pending_reason_list = (token, is_audit_detail=false) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.charge_url.pending_reason}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            is_audit_detail: is_audit_detail
        }
    })

    return request.make(requestConfig);

}

export const search_all_provider = (param, token, is_treating_provider = false) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.provider_config.search_all_provider}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify({ "Description": param.provider_name, "Code": param.RP_provider_code, "Page_size": global_constants.constants.PAGE_SIZE, "Apply_pos_code": param.apply_pos_code }),
    }
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config);
}
export const search_company_provider = (company_id,param, token, is_treating_provider = false) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.provider_config.search_company_provider}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify({ "Description": param.provider_name, "Code": param.RP_provider_code, "Page_size": global_constants.constants.PAGE_SIZE, "Apply_pos_code": param.apply_pos_code }),
    }
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            company_id
        }
    })

    return request.make(request_config);
}
export const quick_search_company_provider = (company_id,param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.provider_config.quick_search_company_provider}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            company_id: company_id,
            keyword: param,
            page_size: global_constants.constants.PAGE_SIZE

        }
    })

    return request.make(request_config);
}
// quick search locations by keywords
export const quick_search_all_provider = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.provider_config.quick_search_all_provider}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            keyword: param,
            pageSize: global_constants.constants.PAGE_SIZE

        }
    })

    return request.make(request_config);
}

export const get_updated_charges = (token, param) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.charge_url.updated_charges}`;
    //console.log("Charge Action:: get_updated_charges:: URL = " + url);
    //console.log("Charge Action:: get_updated_charges:: URL Param 1 = " + param.ParentCompanyId);
    //console.log("Charge Action:: get_updated_charges:: URL Param 2 = " + param.CompanyId);
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(param)
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(requestConfig);
}

export const user_companies = (token, include_all_companies_options) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.company_urls.user_companies}/${include_all_companies_options}`;

    //console.log("Charge Action:: get_parent_n_user_companies :: URL = " + url)
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(requestConfig);
}

//To deactivate charge audit list
export const deactivate_charge_audit  = (token, param) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.charge_url.deactivate_charge_audit}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'put',
        'data': param
        
    })

    return request.make(requestConfig);
}

export const get_charge_pre_audit_summary = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.charge_url.charge_pre_audit_summary}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(requestConfig);
}
export const user_companies_by_charge_status = (token, user_id,status) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.company_urls.user_companies_by_status}/${user_id}/${status}`;

    //console.log("Charge Action:: get_parent_n_user_companies :: URL = " + url)
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(requestConfig);
}
export const user_companies_by_charge_status_audit = (token, user_id, status) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.company_urls.user_companies_by_status_audit}/${user_id}/${status}`;

    //console.log("Charge Action:: get_parent_n_user_companies :: URL = " + url)
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(requestConfig);
}
// Get Patient Details by patient id
export const get_patient_details_by_id = (param, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}${global_constants.end_points.patient_urls.search}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        silversurfer: JSON.stringify(param)
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then((response) => {
        if (response) {
            return response;
        }
    });
}

export const get_procedure_code_list = (token, proc_code) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.procedure_code.search_proc_code}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            proc_code: proc_code,
        }
    })
    return request.make(request_config);
}

export const procedure_code_update = (token, params) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.procedure_code.complete_proc_code}`;
    
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'put',
        data: params
    })
    return request.make(request_config);
}
//To deactivate charge audit list
export const deactivate_bulk_charge_audit = (token, param) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.charge_url.deactivate_bulk_charge_audit}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'put',
        'data': param

    })

    return request.make(requestConfig);
}

export const validate_chg_for_correction = (charge_id, token) => {
    const url = `${global_constants.base_end_points}/charge/validate_chg_for_correction?charge_id=${charge_id}`;

    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};


export const get_charge_edit_rules_list = () => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.charge_url.charge_edit_rules}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'

    })

    return request.make(requestConfig);
}
