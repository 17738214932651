import { IProviderBillingStrategy } from './provider_billing_validator_strategy';
import { ProviderBillingService } from './provider_billing_service';

export class ConcreteStrategyDataRestrictionAll implements IProviderBillingStrategy {
    private providerBillingService = new ProviderBillingService();

    private hasDataRestrictionFromUI(dataToCheck: any[]): boolean {
        let restrictedDataFound = [];

        if (dataToCheck.length > 0) {
            restrictedDataFound = dataToCheck.filter((elementToCheck: any, indexElement: number, allRows: any[]) => {
                if (elementToCheck.status && this.providerBillingService.dataRestrictionMet(elementToCheck, indexElement, allRows)) {
                    return elementToCheck;
                }
            });
        }

        return restrictedDataFound.length > 0;
    }

    public validate(data: any[]): boolean {
        return !this.hasDataRestrictionFromUI(data);
    }
}
