import * as React from 'react';
import { Button, Form, Icon } from 'semantic-ui-react';
import { handle_click_on_enter } from '../../tab_navigation_utility';
import { currency_formatter } from '../../utility';
import CheckboxComponent from './checkbox_component';
import {
    DIMEN,
    GRID_NAME,
    GRID_PATIENT_STYLE,
    OPERATOR,
    OPTIONS,
    OR,
    TYPE_CONTAINS,
    TYPE_ENDS_WITH,
    TYPE_EQUAL,
    TYPE_NOT_CONTAINS,
    TYPE_NOT_EQUAL,
    TYPE_STARTS_WITH
} from './constants';

//Match grid records
export const isMatch = (nodeValue, value, type) => {
    switch (type) {
        case TYPE_CONTAINS:
            return value.toLowerCase()
                .split(" ")
                .every((filterWord) => {
                    return nodeValue.indexOf(filterWord) >= 0;
                });
        case TYPE_EQUAL:
            return nodeValue === value.toLowerCase();

        case TYPE_STARTS_WITH:
            return nodeValue.startsWith(value.toLowerCase());

        case TYPE_ENDS_WITH:
            return nodeValue.endsWith(value.toLowerCase());
        case TYPE_NOT_EQUAL:
            return nodeValue !== value.toLowerCase();
    }
}

//Compare and filter records
export const filterComparator = (filter, value, filterText) => {
    var valueLowerCase = value.toString().toLowerCase().trim();
    var filterTextLoweCase = filterText.toLowerCase().trim();

    switch (filter) {
        case TYPE_CONTAINS:
            return valueLowerCase.indexOf(filterTextLoweCase) >= 0;
        case TYPE_NOT_CONTAINS:
            return valueLowerCase.indexOf(filterTextLoweCase) === -1;
        case TYPE_EQUAL:
            return valueLowerCase === filterTextLoweCase;
        case TYPE_NOT_EQUAL:
            return valueLowerCase != filterTextLoweCase;
        case TYPE_STARTS_WITH:
            return valueLowerCase.indexOf(filterTextLoweCase) === 0;
        case TYPE_ENDS_WITH:
            var index = valueLowerCase.lastIndexOf(filterTextLoweCase);
            return index >= 0 && index === (valueLowerCase.length - filterTextLoweCase.length);
        default:
            return false;
    }
}

//Grid clear all opened and inactive filters
export const clear_filter = (api) => {
    const filterCols = Object.keys(api.filterManager.allFilters);
    if (filterCols.length > 0) {
        const columns = filterCols.filter(value => {
            const item = api.filterManager.allFilters[value];


            if (item.column.filterActive === false) {
                api.destroyFilter(value);
                return true;
            }
            return false;
        })
        if (columns.length > 0) {
            api.onFilterChanged();
        }
    }
}

// Grid: clear all opened filters
export const clear_all_filter = (api) => {
    const filterCols = Object.keys(api.filterManager.allFilters);
    if (filterCols.length > 0) {
        const columns = filterCols.map(value => {
            api.destroyFilter(value);
            return value;
        })
        if (columns.length > 0) {
            api.onFilterChanged();
        }
    }
}
//Get data value from object path
export const getValueObject = (path, obj) => {
    return path.split('.').reduce(function (prev, curr) {
        return prev ? prev[curr] : null
    }, obj || self)
}


//Calculate grid height based on records
export const gridHeight = (length, isPagination) => {
    const { HEIGHT_ROW, HEIGHT_CONSTANT, HEIGHT_NO_RECORD, PAGINATION_SIZE } = DIMEN;
    if (length && length > 0) {
        if (isPagination && length > PAGINATION_SIZE) {
            return { ...GRID_PATIENT_STYLE, ...{ height: `${(PAGINATION_SIZE * HEIGHT_ROW) + HEIGHT_CONSTANT}px` } };
        } else {
            return { ...GRID_PATIENT_STYLE, ...{ height: `${(length * HEIGHT_ROW) + HEIGHT_CONSTANT}px` } };
        }
    } else {
        return { ...GRID_PATIENT_STYLE, ...{ height: `${HEIGHT_NO_RECORD}px` } };
    }
}
// Function Used to create filter selection item
export const RenderItems = ({ selectAll, shouldUpdateChild, clearAll, options, onCheckChange }) => {
    return (
        <div className="multiselect-scrollbox">
            {options.map((value, index) => (
                <Form.Field key={index}>
                    <div className="ui checkbox">
                        <CheckboxComponent
                            onChange={onCheckChange}
                            selectCheckbox={selectAll}
                            shouldUpdate={shouldUpdateChild}
                            isClear={clearAll}
                            value={value}
                        />
                        <label
                            className="chkbx_label"
                            htmlFor={`checkbox_${value}`}
                            dangerouslySetInnerHTML={{ __html: value }}
                        />
                    </div>
                </Form.Field>
            ))}
        </div>
    );
};

// Function Used to create filter select all item
export const SelectAllRender = ({ selectAll, onChangeSelectAll }) => {
    return (
        <Form.Field>
            <div className="ui checkbox">
                <input
                    type="checkbox"
                    name={'select_all'}
                    id={'select_all'}
                    onKeyDown={handle_click_on_enter}
                    checked={selectAll}
                    onChange={onChangeSelectAll}
                />
                <label
                    className="chkbx_label"
                    htmlFor={'select_all'}
                    dangerouslySetInnerHTML={{ __html: 'Select All' }}
                />
            </div>
        </Form.Field>
    );
};

// Function Used to create filter UI
export const GridFilterFrom = ({
    filterType = '',
    onChangeSelectAll,
    selectAll,
    shouldUpdateChild,
    clearAll,
    handleFilter,
    handleClear,
    handleClose,
    valueOptionHandler,
    options,
    onCheckChange,
    inputHandler
}) => {
    return (
        <div className="filter-container">
            <span>
                Show items with value that
                <Icon name="close" onClick={handleClose} />
            </span>
            <Form.Field style={{marginBottom:'0'}}>
                <div className="ui checkbox" style={{marginLeft:'4px'}}>
                    <input
                        type="checkbox"
                        name={'select_all'}
                        id={'select_all'}
                        onKeyDown={handle_click_on_enter}
                        checked={selectAll}
                        onChange={onChangeSelectAll}
                    />
                    <label
                        className="chkbx_label"
                        htmlFor={'select_all'}
                        dangerouslySetInnerHTML={{ __html: 'Select All' }}
                    />
                </div>
            </Form.Field>
            <div className="multiselect-scrollbox">
                {options.map((value, index) => (
                    <Form.Field key={index}>
                        <div className="ui checkbox">
                            <CheckboxComponent
                                onChange={onCheckChange}
                                selectCheckbox={selectAll}
                                shouldUpdate={shouldUpdateChild}
                                isClear={clearAll}
                                value={value}
                            />
                            <label
                                className="chkbx_label"
                                htmlFor={`checkbox_${value}`}
                                dangerouslySetInnerHTML={{ __html: value }}
                            />
                        </div>
                    </Form.Field>
                ))}
            </div>
            <Form>
                {filterType !== 'checklist' && (
                    <React.Fragment>
                        <Form.Select
                            options={OPTIONS}
                            defaultValue={TYPE_EQUAL}
                            onChange={(e, result) => valueOptionHandler(result, 'Option1')}
                        />
                        <input type="text" ref={(el) => inputHandler(el, 'input1')} />
                        <Form.Select
                            className="half-width"
                            options={OPERATOR}
                            defaultValue={OR}
                            onChange={(e, result) => valueOptionHandler(result, 'Option2')}
                        />
                        <Form.Select
                            options={OPTIONS}
                            defaultValue={TYPE_EQUAL}
                            onChange={(e, result) => valueOptionHandler(result, 'Option3')}
                        />
                        <input type="text" ref={(el) => inputHandler(el, 'input2')} />
                    </React.Fragment>
                )}
                <Form.Group inline className="mar-b-0">
                    <Button primary onClick={handleFilter}>
                        Filter
                    </Button>
                    <Button basic onClick={handleClear}>
                        Clear
                    </Button>
                </Form.Group>
            </Form>
        </div>
    );
};

// Check touch screen
export const isTouchDevice = () => {
    return 'ontouchstart' in document.documentElement;
}

//get selected row for checked all, filter applied, with no filter applied.
export const filterSelectedDisplay = (api) => {
    let selectedRows = [];
    api.forEachNodeAfterFilterAndSort((node) => {
        if (node.selected && node.data) {
            selectedRows.push(node.data);
        }
    });

    return selectedRows;
}

export const strip = (value) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = value;
    return tmp.textContent || tmp.innerText || "";
}

export const navigate_tab_grid_first_cell = (gridOptions, container_id) => {
    let ediv = document.querySelector(`#${container_id}`);
    if (ediv != null && ediv != undefined) {
        let ediv_header_col_first = ediv.querySelector('.header-first-col-container');
        add_tab_listener(gridOptions, ediv_header_col_first);
    }
}

const add_tab_listener = (gridOptions, element) => {
    if (element != null && element != undefined) {
        // intercept key strokes within input element
        element.addEventListener("keydown", function (event) {

            if (event.keyCode == 9 && !event.shiftKey) {
                event.preventDefault();
                // scrolls to the first row
                gridOptions.api.ensureIndexVisible(0);
                // scrolls to the first column
                var firstCol = gridOptions.columnApi.getAllDisplayedColumns()[0];
                gridOptions.api.ensureColumnVisible(firstCol);
                // sets focus into the first grid cell
                gridOptions.api.setFocusedCell(0, firstCol);
                gridOptions.api.forEachNode((node) => {
                    if (node.rowIndex == 0) {
                        node.setSelected(true)
                    }
                });
            }
            //            var tabKeyCode = 9;
            //if (event.keyCode !== tabKeyCode && event.shiftKey) return;

        }, true);
    }
}

//Extract amount value
export const currency_value = (value) => {
    if (value) {
        value = value.toString();
        if (value.indexOf('(') !== -1) {
            return -value.replace(/[^0-9.-]+/g, "");
        }
        return value.replace(/[^0-9.-]+/g, "");
    }
    return 0;
}

// Content length of the contents
export const content_length = text => {
    if (text != null && text != undefined) {
        return text.length;
    }
    return 0;
};

//Calculate content width to render
export const content_width = text_lenth => {
    return text_lenth * 7 + 60;
};

export const account_notes_row_height = (params) => {
    let single_row_height = 28;
    const value = params.data.note_text;
    if (value != null && value != undefined && value.length > 40) {
        single_row_height = single_row_height * (Math.floor(value.length / 40) + 1);
    }
    return single_row_height;
}

export const getRowHeight = (name) => {
    if (name && name === GRID_NAME.ACCOUNT_NOTES) {
        return { getRowHeight: account_notes_row_height }
    }
}

/**
 * Detect Mac Safari
 */
export const is_mac_safari = () => {
    const ua = navigator.userAgent.toLowerCase();
    var is_mac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    //var is_mac = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i) ? true : false;
    if (is_mac && ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1) {
        return true;
    }
    return false;
}

// Mac Safari copy- grid cell text
export const copy_cell_text = (event, grid_api) => {
    if (event.metaKey && event.keyCode == 67 && is_mac_safari) {
        try {
            let focussedCell = grid_api.getFocusedCell();
            document.execCommand('copy');
        } catch (error) {
            console.log('copy fail');
        }
    }
}

//Grid row grouping by column drag function 

export const group_row_by_column = (rows, fields, props) => {
    if (rows && rows.length > 0 && fields && fields.length > 0) {
        const amount_fields = find_amount_fields(props.column, props.customColumnToTotal, props.id);
        var groups: any = null;
        const groupByKey = (data, property) => {
            return data.reduce((obj, item) => {
                // Check if the criteria is a function to run on the item or a property of it
                var key = typeof property === 'function' ? fields(item) : item[property];
                // If the key doesn't exist yet, create it
                if (!obj.hasOwnProperty(key)) {
                    obj[key] = { group: key, participants: [] };
                    amount_fields.map(value => {
                        obj[key][value] = 0.00;
                    })
                }

                obj[key].participants.push(item);// Push the value to the object
                amount_fields.map(value => {
                    obj[key][value] = sum_amount(obj[key][value], item[value]);// sum grouped item amounts
                });
                // Return the object to the next item in the loop
                return obj;
            }, {});
        };

        groups = (objectArray, fields, i) => {
            let group_object = groupByKey(objectArray, fields[i]);
            let items = [];
            const sort_field = `${fields[i]}`.replace(/link_/g, '');
            Object.keys(group_object).map(key => {
                items.push({
                    group: `${key}:`,
                    [sort_field]: key,
                    sum_amount: grouped_sum_format(amount_fields, group_object[key]),
                    participants: fields.length - 1 > i ? groups(group_object[key].participants || [], fields, i + 1) : group_object[key].participants
                });
                return key;
            });
            return items;
        };

        if (props.displayGroupRowCount) {
            let showColumnTotal = props.customColumnToTotal.filter(value => value.reportId === props.id).length > 0;
            groups = (objectArray, fields, i) => {
                let group_object = groupByKey(objectArray, fields[i]);
                let items = [];
                Object.keys(group_object).map(key => {
                    items.push({
                        group: `${key || "None"}:`,
                        [fields[i]]: key,
                        sum_amount: showColumnTotal ? `(${group_object[key].int32Col0})` : `(${(group_object[key].participants || []).length})`,
                        participants: fields.length - 1 > i ? groups(group_object[key].participants || [], fields, i + 1) : group_object[key].participants
                    });
                    return key;
                });
                return items;
            };
        }
        return groups(rows, fields, 0);
    }

    //return default
    return rows;
}

/**
 * Return grouped rows by Column with the number of gruped rows.
 *
 * @param {Array} rows
 * @param {Array} fields
 * @param {Array} columns
 * 
 * @returns {Array}
 */
// this method is a duplicate of group_row_by_column so it has been merged into one...will be removed in future
export const groupRowByColumnWithRowsCount = (rows, fields, props) => {
    if (rows && rows.length > 0 && fields && fields.length > 0) {
        const amountFields = find_amount_fields(props.column, props.customColumnToTotal, props.id);
        const groupByKey = (data, property) => {
            return data.reduce((obj, item) => {
                var key = typeof property === 'function' ? fields(item) : item[property];
                if (!obj.hasOwnProperty(key)) {
                    obj[key] = { group: key, participants: [] };
                    amountFields.map(value => {
                        obj[key][value] = 0.00;
                    });
                }

                obj[key].participants.push(item);
                amountFields.map(value => {
                    obj[key][value] = sum_amount(obj[key][value], item[value]);
                });
                return obj;
            }, {});
        };

        const groups = (objectArray, fields, i) => {
            let group_object = groupByKey(objectArray, fields[i]);
            let items = [];
            Object.keys(group_object).map(key => {
                items.push({
                    group: `${key || "None"}:`,
                    [fields[i]]: key,
                    sum_amount: `(${(group_object[key].participants || []).length})`,
                    participants: fields.length - 1 > i ? groups(group_object[key].participants || [], fields, i + 1) : group_object[key].participants
                });
                return key;
            });
            return items;
        };
        return groups(rows, fields, 0);
    }

    return rows;
}

const sum_amount = (sum, value) => {
    if (value) {
        value = value.toString();
        if (value.indexOf('(') !== -1) {
            return sum - Number(value.replace(/[^0-9.-]+/g, ""));
        }
        return sum + Number(value.replace(/[^0-9.-]+/g, ""));
    }
    return sum;
}

const find_amount_fields = (columns, customColumn, reportId) => {
    let amount_fields = [];
    if (columns && columns.length > 0) {
        columns.map(col => {
            if (col.field.indexOf('decimal') > -1) {
                amount_fields.push(col.field); // find currency key to sum group amount
            }


            if (col.field.indexOf('int') > -1 && customColumn.filter(value => col.headerName.indexOf(value.column) > -1).length > 0 && customColumn.filter(value => reportId.indexOf(value.reportId) > -1).length > 0) {
                amount_fields.push(col.field); // find number key to sum group amount
            }
        })
    }
    return amount_fields;
}

const grouped_sum_format = (amount_fields, data) => {
    let sum = '';
    amount_fields.map((value) => {
        sum = `${sum} ${currency_formatter(data[value])}`;
    });
    return sum;
}

export const get_group_column = () => ({
    headerName: '',
    field: 'group',
    hide: true,
    suppressSorting: true,
    suppressFilter: true,
    cellRenderer: 'agGroupCellRenderer',
    colSpan: function (params) {
        if (params.node.group) {
            return params.columnApi.getAllColumns().length;
        }
        return 1;
    },
    cellRendererParams: {
        suppressCount: true, // turn off the row count
        innerRenderer: function (params) {
            if (params.node.group) {
                var eDiv = document.createElement('div');
                eDiv.innerHTML = `<span>${params.data.group} <span class="grouped-row-amount-span">${params.data.sum_amount}</span></span>`;
                return eDiv.firstChild;
            }
        }
    }
});

export const parse_JSON = (value) => {
    if (value) {
        try {
            return JSON.parse(value);
        } catch (e) {
            return null;
        }
    }
    return null;
}

/**
 * function to get node value
 * @param node
 * @param nodeValue
 */
export const get_node_value = (node, nodeValue) => {
    if (node.group) {
        node.childrenAfterFilter.forEach((childNode) => {
            get_node_value(childNode, nodeValue);
        })
    } else {
        nodeValue(node);
    }
}

/**
 * function to a field value
 * @param value
 * @param listItems
 */
export const add_value = (value, listItems) => {
    if (value != null && value != undefined) {
        const valueString = value.toString().trim();
        if (listItems.indexOf(valueString) === -1) {
            listItems.push(valueString);
        }
    }
}

/**
 * function to a hyper-link field value
 * @param value
 * @param listItems
 */
export const add_link_value = (value, listItems) => {
    if (value != null && value != undefined) {
        const link_value = strip(value);
        if (listItems.indexOf(link_value) === -1) {
            listItems.push(link_value);
        }
    }
}

// Extract amount value
export const format_number = (value) => {
  if (value) {
    value = value.toString();
    if (value.indexOf('(') !== -1) {
      return Number(-value.replace(/[^0-9.-]+/g, ''));
    }
    return Number(value.replace(/[^0-9.-]+/g, ''));
  }
  return 0;
};

export const filterRowDataForGrouped = (array, filters) => {
    let executableString = ``;
    const newArray = array;
    const filterSize = Object.keys(filters).length;
    const filterField = (key) => {
      if (filters[key].value && filters[key].value.length > 0) {
        filters[key].value.map((filterItem, index) => {
          executableString +=
            index === filters[key].value.length - 1
              ? `item.${key} === '${filterItem}'`
              : `item.${key} === '${filterItem}' || `;
        });
      } else {
        executableString += 'item';
      }
    };
  
    Object.keys(filters).forEach((key, index) => {
      if (filterSize > 0) {
        if (index === 0) {
          executableString += `newArray.filter(function (item) {
          return `;
          filterField(key);
          executableString += `})`;
        } else {
          executableString += `.filter(function (item) {
          return `;
          filterField(key);
          executableString += `})`;
        }
      }
    });
    return eval(executableString);
  };