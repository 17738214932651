import { header_template } from '../admin/constants/constants';
import { currency_comparator, number_comparator, text_comparator, mm_dd_yyyy_comparator } from '../shared/component/grid/comparators';
import { get_header_template_with_navigation } from '../shared/component/grid/constants';
import * as moment from "moment";
import { strip } from '../shared/utility';
import { get_header_checkbox_template } from '../reports/report_constants';
import { handle_cell_checkbox, e277_report_notes_input, handleCellCheckboxReqStmt } from './utility';
import { readonly_checkbox_cell_renderer } from '../shared/component/grid/gridview_cell_renderer';

export const error_messages = {
    added_successfully: 'Changes saved successfully.',
    select_late_reason: 'Please select a late reason.',
    select_action: 'Please choose an action for ALL charges being rebilled from this screen.',
    date_validation_msg: 'Date of Service Through must be on or after the Date of Service From.',
    no_charges_to_save: 'There are no charges to save.',
    select_single_claim: 'Please select at least one record to close.'
}
export const claim_indicator_setting_columns = [
    {
        headerName: 'Charge ID',
        field: 'charge_id',
        renderer: 'charge_renderer',
        style: { minWidth: '110px', width: '110px' }
    },
    {
        headerName: 'Date of Service',
        field: 'date_of_service',
        renderer: 'date_of_service_renderer',
        style: { minWidth: '150px', width: '150px' }
    },
    {
        headerName: 'CPT Code',
        field: 'proc_code',
        renderer: 'proc_code_renderer',
        style: { minWidth: '100px', width: '100px' }
    },
    {
        headerName: 'Charge Amount',
        field: 'total_chg_amt',
        renderer: 'total_chg_amt_renderer',
        style: { minWidth: '150px', width: '150px' }
    },
    {
        headerName: 'Balance',
        field: 'owed_amt',
        renderer: 'owed_amt_renderer',
        style: { minWidth: '80px', width: '80px' }
    },
    {
        headerName: 'Last Billed',
        field: 'last_billed_date',
        renderer: 'last_billed_date_renderer',
        style: { minWidth: '150px', width: '150px' }
    },
    {
        headerName: 'Claim #',
        field: 'claim_code',
        renderer: 'claim_code_renderer',
        style: { minWidth: '150px', width: '150px' }
    },
    {
        headerName: 'Action',
        field: 'action',
        renderer: 'action_renderer',
        style: { minWidth: '150px', width: '150px' }
    },
    {
        headerName: 'Late Reason',
        field: 'late_reason',
        renderer: 'late_reason_renderer',
        style: { minWidth: '150px', width: '150px' }
    },
    {
        headerName: 'Condition Code',
        field: 'condition_code',
        renderer: 'condition_code_renderer',
        style: { minWidth: '150px', width: '150px' }
    },
    {
        headerName: 'Note',
        field: 'note',
        renderer: 'note_renderer',
        style: { minWidth: '200px', width: '200px' }
    },
    {
        headerName: 'Rebill',
        field: 'status',
        renderer: 'status_renderer',
        style: { textAlign: 'Center' }
    }
];

export const claim_reason = [
    { key: "-999", value: "-999", text: "Select" },
    { key: "1", value: "1", text: "Proof of Eligibility Unknown" },
    { key: "2", value: "2", text: "Litigation" },
    { key: "3", value: "3", text: "Authorization Delays" },
    { key: "4", value: "4", text: "Delay in Certifying Provider" },
    { key: "5", value: "5", text: "Delay in Supplying Billing Forms" },
    { key: "6", value: "6", text: "Delay in Delivery of Custom - made Appliances" },
    { key: "7", value: "7", text: "Third Party Processing Delay" },
    { key: "8", value: "8", text: "Delay in Eligibility Determination" },
    { key: "9", value: "9", text: "Original Claim Rej or Denied" },
    { key: "10", value: "10", text: "Administration Delay" },
    { key: "11", value: "11", text: "Other" },
]
export const claim_action = [
    { key: "-999", value: "-999", text: "Select" },
    { key: "Corrected", value: "Corrected", text: "Corrected" },
    { key: "Late", value: "Late", text: "Late" },
    { key: "Void", value: "Void", text: "Void" },
    { key: "Conditional", value: "Conditional", text: "Conditional" },
]

export const OPEN_CLAIMS_SEARCH = 'OPEN_CLAIMS:OPEN_CLAIMS_SEARCH';
export const EMPTY_CLAIMS_DATA = 'OPEN_CLAIMS:EMPTY_CLAIMS_DATA';

export const search_grid_id = "search_grid_id"
export const cliam_details_grid_id = "cliam_details_grid_id"

export const claim_notes_input = () => {
    return `
    <div class="ui form field">
       <input class="txt_claim_notes" maxLength="250" autocomplete="new-password" />
    </div>`;
    //<textarea class="txt_claim_notes" rows="1" cols="50" autocomplete="new-password" ></textarea>

};

export const open_claims_setting_columns = [
    {
        headerComponentParams: header_template,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        field: "checkbox",
        width: 35,
        maxWidth: 35,
        suppressFilter: true,
        suppressSorting: true,
        suppressSizeToFit: true,
    },
    {
        headerName: "Notes",
        headerComponentParams: get_header_template_with_navigation("search_grid_id"),
        minWidth: 200,
        field: "notes",
        width: 200,
        type: 'string',
        suppressFilter: true,
        suppressSorting: true,
        suppressSizeToFit: true,
        cellRenderer: (params) => {
            if (!params.node.rowPinned) {
                let eDiv = document.createElement("div");
                eDiv.innerHTML = `${claim_notes_input()}`;
                let input_box = eDiv.querySelector("input");
                if (input_box) {
                    input_box.value = params.node.data['notes'];
                    input_box.addEventListener("blur", (event: any) => {
                        params.node.setDataValue("notes", event.target.value);
                    });
                }
                return eDiv;
            } else {
                return params.value
            }
        }
    },
    {
        headerName: "Claim Date",
        headerComponentParams: header_template,
        field: "claim_date",
        minWidth: 90,
        width: 90,
        type: 'date',
        unSortIcon: true,
        filter: "dateFilter",
        comparator: mm_dd_yyyy_comparator
    },
    {
        headerName: "Group Code",
        headerComponentParams: header_template,
        field: "group_code",
        minWidth: 150,
        width: 150,
        filter: "textFilter",
        unSortIcon: true,
        type: 'string',
        comparator: text_comparator
    },
    {
        headerName: "Format",
        headerComponentParams: header_template,
        field: "format",
        minWidth: 170,
        width: 170,
        filter: "textFilter",
        type: 'string',
        unSortIcon: true,
        comparator: text_comparator
    },
    {
        headerName: "Company Code",
        headerComponentParams: header_template,
        field: "company_code",
        filter: "textFilter",
        type: 'string',
        minWidth: 200,
        width: 230,
        unSortIcon: true,
        comparator: text_comparator
    },
    {
        headerName: "Amount",
        headerComponentParams: header_template,
        field: "amount",
        filter: "currencyFilter",
        minWidth: 120,
        width: 120,
        type: "currency",
        unSortIcon: true,
        cellStyle: { textAlign: 'right' },
        comparator: currency_comparator,
    },
    {
        headerName: "Run Number",
        headerComponentParams: header_template,
        field: "run_number",
        filter: "numberFilter",
        minWidth: 120,
        width: 120,
        type: 'string',
        unSortIcon: true,
        cellStyle: { textAlign: 'right' },
        comparator: number_comparator
    },
    {
        headerName: "ISA",
        headerComponentParams: header_template,
        field: "isa",
        filter: "textFilter",
        type: 'string',
        minWidth: 100,
        width: 100,
        unSortIcon: true,
        comparator: text_comparator
    },
    {
        headerName: "999",
        headerComponentParams: header_template,
        field: "accepted_999",
        filter: "textFilter",
        type: 'string',
        minWidth: 160,
        width: 160,
        unSortIcon: true,
        comparator: text_comparator
    },
    {
        headerName: "999 Date",
        headerComponentParams: header_template,
        field: "date_999",
        minWidth: 100,
        width: 100,
        filter: "textFilter",
        type: 'date',
        cellClass: "text-center",
        unSortIcon: true,
        comparator: text_comparator
    },
    {
        headerName: "Details",
        headerComponentParams: header_template,
        field: "details",
        unSortIcon: true,
        type: "hyper_link",
        filter: "linkFilter",
        comparator: (link1, link2) => text_comparator(strip(link1), strip(link2)),
        cellRenderer: "PopUpLinkRenderer",
        cellRendererParams: {
            r4_to_r6_routes: [
                {
                    has_parameter: false,
                    parameters: null,
                    r4_route: "ReportSelection.aspx",
                    r6_route: "/report/sub_report_data?param"
                }
            ], is_print_disabled: true, is_export_disabled: true
        }
    }
];

export const prepare_month_options = () => {
    return moment.months().map((value, key) => {
        return {
            text: value,
            value: key + 1
        }
    })
}

export const get_previous_month = () => {
    let month = moment().month(); // month in moment starts from 0, therfore moment returns previous month i.e in case of July it returns 6

    if(month == 0) { // handling edge case for January i.e '0' return '12'
        return 12; // return December
    }
    return month;
}

export const get_dropdown_default_year = () => {

  let year = moment().format('YYYY');
  let month = moment().month();
 
  if(month == 0) {
    return moment().subtract(1, 'years').format('YYYY');
  }

  return year;
}

export const prepare_year_options = (from, to) => {

    let year_options = [];
    for (let i = from; i <= to; i++) {
        year_options.push({
            text: i,
            value: i
        })
    }

    return year_options;
}

export const patient_statement = {
    company_column_def: [      
        {
            headerName: 'Company Name',
            headerComponentParams: get_header_template_with_navigation(search_grid_id),
            field: 'company_name',
            filter: 'textFilter',
            minWidth: 185,
            width: 185,
            type: 'string',
            unSortIcon: true,
            comparator: text_comparator
        },
        {
          headerName: 'Company Code',
          headerComponentParams: header_template,
          field: 'company_code',
          filter: 'textFilter',
          minWidth: 185,
          width: 185,
          type: 'string',
          unSortIcon: true,
          comparator: text_comparator
      }
    ]
};

export const report277_configuration = (r4_to_r6_routes: any) => {
  return {
    title: '277 Report',
    report_grid_id: 'report_277',
    options_data: {
      service_type: [],
      parent_company_id: [],
      company_id: [],
      accepted_status: [
        {
          value: '0',
          text: '--ALL--',
          selected: true
        },
        {
          value: '1',
          text: 'Accepted',
          selected: false
        },
        {
          value: '2',
          text: 'Rejected ',
          selected: false
        }
      ],
      close_status: [
        {
          value: '0',
          text: '--ALL--',
          selected: true
        },
        {
          value: '1',
          text: 'Open',
          selected: false
        },
        {
          value: '2',
          text: 'Closed ',
          selected: false
        }
      ],
      received_status: [
        {
          value: '0',
          text: '--ALL--',
          selected: true
        },
        {
          value: '1',
          text: 'Received',
          selected: false
        },
        {
          value: '2',
          text: 'Not Received ',
          selected: false
        }
      ]
    },
    search_criteria: {
      service_type: '0',
      parent_company_id: '-1',
      company_id: '-1',
      patient_id: '',
      payer: '',
      payer_code: '',
      payer_id: '',
      dos_date_from: null,
      dos_date_to: null,
      file_date_from: null,
      file_date_to: null,
      days_elapsed: false,
      rejection_group: '',
      rejection_group_code: '',
      rejection_group_id: '',
      close_status: '0',
      received_status: '0',
      accepted_status: '0',
      run_id: ''
    },
    title_search_criteria: {
      service_type: '0',
      parent_company_id: '-1',
      company_id: '-1',
      payer: '',
      payer_id: '',
      dos: '',
      file_date: '',
      days_elapsed: false,
      rejection: '',
      rejection_id: '',
      close_status: '0',
      received_status: '0',
      accepted_status: '0',
      run_id: ''
    },
    update_msg: {
      empty_error: 'You must select at least one record to update.',
      closed_note_selected: 'Please add notes for bulk update.',
    },
    closed_msg: {
      success: '835s keys closed successfully',
      error: 'Please select a record to close 835s'
    },
    column_defs: [
      {
        headerName: 'Close',
        headerComponentParams: get_header_checkbox_template('is_close'),
        field: 'is_close',
        minWidth: 70,
        maxWidth: 70,
        width: 70,
        type: 'string',
        suppressFilter: true,
        suppressSorting: true,
        suppressSizeToFit: true,
        resizable: false,
        suppressMovable: true,
        cellRenderer: (params: any) => {
          return handle_cell_checkbox(params, 'is_close', 'is_note', 'closed', true, true);
        }
      },
      {
        headerName: 'Close Without Note',
        headerComponentParams: get_header_checkbox_template('is_note'),
        field: 'is_note',
        type: 'string',     
        suppressFilter: true,
        suppressSorting: true,
        suppressSizeToFit: true,
        suppressMovable: true,
        resizable: false,
        cellRenderer: (params: any) => {
          return handle_cell_checkbox(params, 'is_note', 'is_close', 'closed', true, true);
        }
      },
      {
        headerName: 'Closed Note',
        headerComponentParams: header_template,
        field: 'closed_notes',
        type: 'string',
        filter: 'textFilter',
        comparator: text_comparator,
        unSortIcon: true,
        minWidth: 300,
        maxWidth: 300,
        width: 300,
        suppressSizeToFit: true,
        resizable: false,
        cellStyle: { 'white-space': 'normal' },
        cellRenderer: (params) => {
          let eDiv = document.createElement('div');
          if (params.data.closed) {
            eDiv.innerHTML = params.value;
          } else {
            eDiv.innerHTML = `${e277_report_notes_input(params)}`;
            let input_box = eDiv.querySelector('input');
            if (input_box) {
              input_box.value = params.data['closed_notes'];
              input_box.addEventListener('blur', (event: any) => {
                params.node.setDataValue('closed_notes', event.target.value);
              });
            }
          }
          return eDiv;
        }
      },
      {
        headerName: 'Company Code',
        headerComponentParams: header_template,
        field: 'gpmS_code',
        type: 'string',
        filter: 'textFilter',
        comparator: text_comparator,
        unSortIcon: true
      },
      {
        headerName: 'Patient ID',
        headerComponentParams: header_template,
        field: 'patient_id',
        type: 'string',
        filter: 'textFilter',
        comparator: text_comparator,
        unSortIcon: true
      },
      {
        headerName: 'Last Name',
        headerComponentParams: header_template,
        field: 'last_name',
        type: 'string',
        filter: 'textFilter',
        comparator: text_comparator,
        unSortIcon: true
      },
      {
        headerName: 'First Name',
        headerComponentParams: header_template,
        field: 'first_name',
        type: 'string',
        filter: 'textFilter',
        comparator: text_comparator,
        unSortIcon: true
      },
      {
        headerName: 'Date of Service',
        headerComponentParams: header_template,
        field: 'date_of_service',
        type: 'hyper_link',
        unSortIcon: true,
        is_hyper_link: true,
        filter: 'linkDateFilter',
        comparator: (link1, link2) => mm_dd_yyyy_comparator(strip(link1), strip(link2)),
        cellRenderer: 'PopUpLinkRenderer',
        cellRendererParams: {
          r4_to_r6_routes: r4_to_r6_routes,
          is_tooltip: true,
          tooltip_feild: 'all_date_of_service'
        }
      },
      {
        headerName: 'Total Charge Amount',
        headerComponentParams: header_template,
        field: 'total_charge_amount',
        type: 'currency',
        filter: 'currencyFilter',
        comparator: currency_comparator,
        unSortIcon: true,
        cellStyle: { textAlign: 'right' }
      },
      {
        headerName: 'Rejection Group',
        headerComponentParams: header_template,
        field: 'rejection_group',
        type: 'string',
        filter: 'textFilter',
        comparator: text_comparator,
        unSortIcon: true
      },
      {
        headerName: 'Accepted',
        headerComponentParams: header_template,
        field: 'accepted',
        type: 'boolean',
        filter: 'textFilter',
        comparator: text_comparator,
        unSortIcon: true,
        cellStyle: { textAlign: 'center' },
        cellRenderer: (params) => {
          if (params.data.accepted === 'Yes') {
            let eDiv = document.createElement('div');
            eDiv.innerHTML = `<i tabindex="0" aria-hidden="true" class="checkmark icon"></i>`;
            return eDiv;
          } else {
            return;
          }
        }
      },
      {
        headerName: 'Received',
        headerComponentParams: header_template,
        field: 'received',
        type: 'boolean',
        filter: 'textFilter',
        comparator: text_comparator,
        unSortIcon: true,
        cellStyle: { textAlign: 'center' },
        cellRenderer: (params) => {
          if (params.data.received === 'Yes') {
            let eDiv = document.createElement('div');
            eDiv.innerHTML = `<i tabindex="0" aria-hidden="true" class="checkmark icon"></i>`;
            return eDiv;
          } else {
            return;
          }
        }
      },
      {
        headerName: 'Payer',
        headerComponentParams: header_template,
        field: 'payer_desc',
        type: 'string',
        filter: 'textFilter',
        comparator: text_comparator,
        unSortIcon: true
      },
      {
        headerName: 'Outbound ID',
        headerComponentParams: header_template,
        field: 'outbound_id_list',
        type: 'string',
        filter: 'textFilter',
        comparator: text_comparator,
        unSortIcon: true
      },
      {
        headerName: 'Run Number',
        headerComponentParams: header_template,
        field: 'run_number',
        type: 'string',
        filter: 'textFilter',
        comparator: text_comparator,
        unSortIcon: true
      },
      {
        headerName: 'Details',
        headerComponentParams: header_template,
        field: 'details',
        unSortIcon: true,
        type: 'hyper_link',
        filter: 'linkFilter',
        comparator: (link1: any, link2: any) => text_comparator(strip(link1), strip(link2)),
        cellRenderer: 'PopUpLinkRenderer',
        cellRendererParams: { r4_to_r6_routes: r4_to_r6_routes }
      },
      {
        headerName: 'File Date',
        headerComponentParams: header_template,
        field: 'file_date',
        type: 'date',
        filter: 'dateFilter',
        comparator: mm_dd_yyyy_comparator,
        unSortIcon: true
      },
      {
        headerName: 'Closed By',
        headerComponentParams: header_template,
        field: 'closed_by',
        type: 'string',
        filter: 'textFilter',
        comparator: text_comparator,
        unSortIcon: true
      },
      {
        headerName: 'Closed Date',
        headerComponentParams: header_template,
        field: 'closed_date',
        type: 'date',
        filter: 'dateFilter',
        comparator: mm_dd_yyyy_comparator,
        unSortIcon: true
      }
    ],
    rejection_group_column_defs: [
      {
        headerName: 'Name',
        field: 'name',
        filter: 'textFilter',
        comparator: text_comparator,
        minWidth: 120,
        unSortIcon: true,
        headerComponentParams: get_header_template_with_navigation('payer_header_id')
      },
      {
        headerName: 'Description',
        field: 'description',
        filter: 'textFilter',
        comparator: text_comparator,
        minWidth: 375,
        unSortIcon: true,
        headerComponentParams: header_template
      }
    ]
  };
};

export const run_status_update = {
    claim_deatils: 0,
    run_set_details: 1
}

export const batch_billing_configuration = (props) => ({
    title: 'Batch Claims - Current Runs',
    title_generated_claims: 'Batch Claims - Generated Claims',
    report_grid_id: 'batch_billing',
    options_data: {
        payer_groups: [],
        format_title_id: [],
        company_id: [],
        batch_type: [],
        date_type: [],
        run_status_type: [],
        schedule_type: [],
        create_type: [],
        create_type_standard: [
            { value: '0', text: 'Create Claim File' },
            { value: '2', text: 'Schedule Recurring Run' }
        ],
        create_type_admin: [
            { value: '0', text: 'Create Live Claim File' },
            { value: '1', text: 'Create Test Claim File' },
            { value: '2', text: 'Schedule Recurring Run' }
        ]
    },
    search_criteria: {
        start: moment().subtract(1, 'days'),
        end: moment(),
        batch_type: '0',
        date_type: '1',
        format_title_id: '0',
        payer_group_id: '0',
        company_id: '-1',
        run_status: '0',
        set_id: '',
        run_number: ''
    },
    create_claim_search_criteria: {
        create_type: '0',
        payer_group_id: '0',
        company_id: '-1',
        format_id: '0',
        is_test: false,
        show_pending: false,
        is_status_update: true,
        schedule_type_cd: ''
    },
    update_msg: {
        empty_error: 'You must select at least one record to',
        empty_error_all: 'You must select at least one record.',
        empty_delete_error: 'You must select at least one record to delete.',
        deleted_msg: 'You must select at least one record to delete.',
        numeric_allow: 'Only Numeric characters are allowed.'
    },
    format_run_status: {
        rerun: 1,
        release: 2,
        onhold: 3,
        cancel: 4
    },
    format_print_status: {
        NA: 0,
        pending: 1,
        queued: 2,
        in_progress: 3,
        printed: 4,
        cancelled: 5
    },
    column_defs_current_past_runs: [
        {
            headerComponentParams: header_template,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            field: 'checkbox',
            width: 35,
            maxWidth: 35,
            suppressFilter: true,
            suppressSorting: true,
            suppressSizeToFit: true,
            resizable: false
        },
        {
            headerName: 'Claim Count',
            headerComponentParams: header_template,
            field: 'claim_number',
            type: 'hyper_link',
            filter: 'numberFilter',
            unSortIcon: true,
            comparator: (link1, link2) => number_comparator(strip(link1), strip(link2)),
            cellRenderer: (params) => {
                if (params.value == 0) {
                    return params.value;
                } else {
                    return basic_link_renderer(params, props, `/billing/batch_billing/${params.data.run_number}`);
                }
            }
        },
        {
            headerName: 'Set ID',
            headerComponentParams: header_template,
            field: 'set_id',
            type: 'hyper_link',
            filter: 'numberFilter',
            unSortIcon: true,
            comparator: (link1, link2) => number_comparator(strip(link1), strip(link2)),
            cellRenderer: (params) => {
                if (!params.value || params.value == 0 || params.node.data.format_type != 1) {
                    return !params.value ? '' : params.value;
                } else {
                    return basic_link_renderer(params, props, `/billing/batch_billing/${params.data.set_id}/claims`);
                }
            }
        },
        {
            headerName: 'Format',
            headerComponentParams: header_template,
            field: 'format_title',
            type: 'string',
            filter: 'textFilter',
            comparator: text_comparator,
            unSortIcon: true
        },
        {
            headerName: 'Source',
            headerComponentParams: header_template,
            field: 'source',
            type: 'string',
            filter: 'textFilter',
            comparator: text_comparator,
            unSortIcon: true
        },
        {
            headerName: 'Run Status',
            headerComponentParams: header_template,
            field: 'run_status',
            type: 'string',
            filter: 'textFilter',
            comparator: text_comparator,
            unSortIcon: true
        },
        {
            headerName: 'Print Status',
            headerComponentParams: header_template,
            field: 'print_status',
            type: 'string',
            filter: 'textFilter',
            comparator: text_comparator,
            unSortIcon: true
        },
        {
            headerName: 'Run Number',
            headerComponentParams: header_template,
            field: 'run_number',
            type: 'string',
            filter: 'numberFilter',
            comparator: number_comparator,
            unSortIcon: true
        },
        {
            headerName: 'Payer Group',
            headerComponentParams: header_template,
            field: 'payer_group',
            type: 'string',
            filter: 'textFilter',
            comparator: text_comparator,
            unSortIcon: true
        },
        {
            headerName: 'Company',
            headerComponentParams: header_template,
            field: 'company',
            type: 'string',
            filter: 'textFilter',
            comparator: text_comparator,
            unSortIcon: true
        },
        {
            headerName: 'Process Date',
            headerComponentParams: header_template,
            field: 'process_date',
            type: 'date',
            filter: 'dateFilter',
            comparator: mm_dd_yyyy_comparator,
            unSortIcon: true
        },
        {
            headerName: 'Request Date',
            headerComponentParams: header_template,
            field: 'request_date',
            type: 'date',
            filter: 'dateFilter',
            comparator: mm_dd_yyyy_comparator,
            unSortIcon: true
        },
        {
            headerName: 'User',
            headerComponentParams: header_template,
            field: 'request_user',
            type: 'string',
            filter: 'textFilter',
            comparator: text_comparator,
            unSortIcon: true
        },
        {
            headerName: 'Update Charge Status',
            headerComponentParams: header_template,
            field: 'status_update',
            type: 'boolean',
            filter: 'textFilter',
            comparator: text_comparator,
            unSortIcon: true
        },
        {
          headerName: 'Passed Charges',
          headerComponentParams: header_template,
          field: 'num_chg_pass',
          type: 'number',
          filter: 'numberFilter',
          comparator: number_comparator,
          unSortIcon: true
        },
        {
          headerName: 'Failed Charges',
          headerComponentParams: header_template,
          field: 'num_chg_fail',
          type: 'number',
          filter: 'numberFilter',
          comparator: number_comparator,
          unSortIcon: true
        },
        {
            headerName: 'Run Type',
            headerComponentParams: header_template,
            field: 'isTest',
            type: 'boolean',
            filter: 'textFilter',
            comparator: text_comparator,
            unSortIcon: true
        },
        {
            headerName: 'Pending',
            headerComponentParams: header_template,
            field: 'isPending',
            type: 'boolean',
            filter: 'textFilter',
            comparator: text_comparator,
            unSortIcon: true
        },
        {
            headerName: 'Version',
            headerComponentParams: header_template,
            field: 'version',
            type: 'number',
            filter: 'textFilter',
            comparator: text_comparator,
            unSortIcon: true
        }
    ],
    column_defs_scheduled_runs: [
        {
            headerComponentParams: header_template,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            field: 'checkbox',
            width: 35,
            maxWidth: 35,
            suppressFilter: true,
            suppressSorting: true,
            suppressSizeToFit: true,
            resizable: false
        },
        {
            headerName: 'Format',
            headerComponentParams: header_template,
            field: 'title',
            type: 'string',
            filter: 'textFilter',
            comparator: text_comparator,
            unSortIcon: true
        },
        {
            headerName: 'Payer Group',
            headerComponentParams: header_template,
            field: 'payer_group_name',
            type: 'string',
            filter: 'textFilter',
            comparator: text_comparator,
            unSortIcon: true
        },
        {
            headerName: 'Request Date',
            headerComponentParams: header_template,
            field: 'last_request_date',
            type: 'date',
            filter: 'dateFilter',
            comparator: mm_dd_yyyy_comparator,
            unSortIcon: true
        },
        {
            headerName: 'Process Date',
            headerComponentParams: header_template,
            field: 'last_process_date',
            type: 'date',
            filter: 'dateFilter',
            comparator: mm_dd_yyyy_comparator,
            unSortIcon: true
        },
        {
            headerName: 'Company',
            headerComponentParams: header_template,
            field: 'company_name',
            type: 'string',
            filter: 'textFilter',
            comparator: text_comparator,
            unSortIcon: true
        },
        {
            headerName: 'Update Charge Status',
            headerComponentParams: header_template,
            field: 'status_update',
            type: 'boolean',
            filter: 'textFilter',
            comparator: text_comparator,
            unSortIcon: true
        },
        {
            headerName: 'Type',
            headerComponentParams: header_template,
            field: 'type',
            type: 'string',
            filter: 'textFilter',
            comparator: text_comparator,
            unSortIcon: true
        },
        {
          headerName: 'Passed Charges',
          headerComponentParams: header_template,
          field: 'num_chg_pass',
          type: 'number',
          filter: 'numberFilter',
          comparator: number_comparator,
          unSortIcon: true
        },
        {
          headerName: 'Failed Charges',
          headerComponentParams: header_template,
          field: 'num_chg_fail',
          type: 'number',
          filter: 'numberFilter',
          comparator: number_comparator,
          unSortIcon: true
        },        
        {
            headerName: 'Run Type',
            headerComponentParams: header_template,
            field: 'isTest',
            type: 'boolean',
            filter: 'textFilter',
            comparator: text_comparator,
            unSortIcon: true
        },
        {
            headerName: 'Pending',
            headerComponentParams: header_template,
            field: 'isPending',
            type: 'boolean',
            filter: 'textFilter',
            comparator: text_comparator,
            unSortIcon: true
        },
        {
            headerName: 'Version',
            headerComponentParams: header_template,
            field: 'version',
            type: 'number',
            filter: 'textFilter',
            comparator: text_comparator,
            unSortIcon: true
        }
    ],
    column_defs_claim_detail: [
        {
            headerComponentParams: header_template,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            field: 'checkbox',
            width: 35,
            maxWidth: 35,
            suppressFilter: true,
            suppressSorting: true,
            suppressSizeToFit: true,
            resizable: false
        },
        {
            headerName: 'Sequence',
            headerComponentParams: header_template,
            field: 'sequence',
            type: 'number',
            filter: 'numberFilter',
            comparator: number_comparator,
            unSortIcon: true
        },
        {
            headerName: 'Company',
            headerComponentParams: header_template,
            field: 'company_name',
            type: 'string',
            filter: 'textFilter',
            comparator: text_comparator,
            unSortIcon: true
        },
        {
            headerName: 'Account Number',
            headerComponentParams: header_template,
            field: 'patient_id',
            type: 'number',
            filter: 'numberFilter',
            comparator: number_comparator,
            unSortIcon: true
        },
        {
            headerName: 'Patient',
            headerComponentParams: header_template,
            field: 'patient_name',
            type: 'string',
            filter: 'textFilter',
            comparator: text_comparator,
            unSortIcon: true
        },
        {
            headerName: 'Insurance',
            headerComponentParams: header_template,
            field: 'insurance_name',
            type: 'string',
            filter: 'textFilter',
            comparator: text_comparator,
            unSortIcon: true
        },
        {
            headerName: 'Status',
            headerComponentParams: header_template,
            field: 'status',
            type: 'string',
            filter: 'textFilter',
            comparator: text_comparator,
            unSortIcon: true
        }
    ]
});

export const column_def_ff_columns = ['isPending', 'isTest', 'version'];

export enum batch_billing_page_type  {
    search=0,
    add=1
}

const basic_link_renderer = (params, props, pathname) => {
  if (!params.node.rowPinned) {
    let eDiv = document.createElement('span');
    eDiv.setAttribute('id', `${params.colDef.field}_${params.value}`);
    eDiv.setAttribute('class', 'href_link');
    eDiv.innerHTML = params.value;
    eDiv.addEventListener('click', () => {
      props.history.push({
        pathname,
        search: '',
        state: {
            claim_id: params.data.run_number,
            data: params.data
        }
      });
    });
    return eDiv;
  } else {
    return params.value;
  }    
};

export const batch_billing_claims_configuration = {
    title: "Batch Claims - Generated Claims",
    batch_billing_claims_grid_id: "batch_billing_claims_grid_id",
    batch_billing_export_grid_id: "batch_billing_claims_grid_id",
    batch_billing_header_id: "batch_billing_header_id",
    empty_error: 'You must select at least one record.',
    format_print_status: {
        NA: 0,
        pending: 1,
        queued: 2,
        in_progress: 3,
        printed: 4,
        cancelled: 5,
    },
    column_defs: [
        {
            headerComponentParams: header_template,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            field: 'checkbox',
            width: 35,
            maxWidth: 35,
            suppressFilter: true,
            suppressSorting: true,
            suppressSizeToFit: true,
            resizable: false
        },
        {
            headerName: "Run Number",
            headerComponentParams: header_template,
            field: "run_number",
            type: "number",
            unSortIcon: true,
            filter: 'numberFilter',
            comparator: number_comparator
        },
        {
            headerName: "Run Status",
            headerComponentParams: header_template,
            field: "run_status_description",
            type: "string",
            unSortIcon: true,
            filter: "textFilter",
            comparator: text_comparator
        },
        {
            headerName: "Sequence",
            headerComponentParams: header_template,
            field: "sequence",
            type: "number",
            unSortIcon: true,
            filter: 'numberFilter',
            comparator: number_comparator
        },
        {
            headerName: "Company",
            headerComponentParams: header_template,
            field: "company",
            type: "string",
            unSortIcon: true,
            filter: "textFilter",
            comparator: text_comparator
        },
        {
            headerName: "Patient ID",
            headerComponentParams: header_template,
            field: "patient_id",
            type: "number",
            unSortIcon: true,
            filter: 'numberFilter',
            comparator: number_comparator
        },
        {
            headerName: "Patient",
            headerComponentParams: header_template,
            field: "patient",
            type: "string",
            unSortIcon: true,
            filter: "textFilter",
            comparator: text_comparator
        },
        {
            headerName: "Insurance",
            headerComponentParams: header_template,
            field: "insurance",
            type: "string",
            unSortIcon: true,
            filter: "textFilter",
            comparator: text_comparator
        },
        {
            headerName: "Print Status",
            headerComponentParams: header_template,
            field: "print_status_description",
            type: "string",
            unSortIcon: true,
            filter: "textFilter",
            comparator: text_comparator
        }
    ],
    export_column_defs: [
        {
          headerName: 'Location ID',
          headerComponentParams: header_template,
          field: 'location_id',
          type: 'number',
          unSortIcon: true,
          filter: 'numberFilter',
          comparator: number_comparator
      },
      {
          headerName: 'GPMS',
          headerComponentParams: header_template,
          field: 'gpms_code',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Company Name',
          headerComponentParams: header_template,
          field: 'company_name',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Set ID',
          headerComponentParams: header_template,
          field: 'set_id',
          type: 'number',
          unSortIcon: true,
          filter: 'numberFilter',
          comparator: number_comparator
      },
      {
          headerName: 'Run Number',
          headerComponentParams: header_template,
          field: 'run_number',
          type: 'number',
          unSortIcon: true,
          filter: 'numberFilter',
          comparator: number_comparator
      },
      {
          headerName: 'Sequence',
          headerComponentParams: header_template,
          field: 'sequence',
          type: 'number',
          unSortIcon: true,
          filter: 'numberFilter',
          comparator: number_comparator
      },
      {
          headerName: 'Patient First Name',
          headerComponentParams: header_template,
          field: 'patient_first_name',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Patient Last Name',
          headerComponentParams: header_template,
          field: 'patient_last_name',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Patient DOB',
          headerComponentParams: header_template,
          field: 'patient_dob',
          type: 'date',
          unSortIcon: true,
          filter: 'dateFilter',
          comparator: mm_dd_yyyy_comparator
      },
      {
        headerName: 'Patient Account Number',
        headerComponentParams: header_template,
        field: 'patient_id',
        type: 'number',
        unSortIcon: true,
        filter: 'numberFilter',
        comparator: number_comparator
      },
      {
          headerName: 'Policy Number',
          headerComponentParams: header_template,
          field: 'policy_number',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Patient Address Line 1',
          headerComponentParams: header_template,
          field: 'patient_address1',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Patient Address Line 2',
          headerComponentParams: header_template,
          field: 'patient_address2',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Patient City',
          headerComponentParams: header_template,
          field: 'patient_city',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Patient State',
          headerComponentParams: header_template,
          field: 'patient_state',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Patient Zip',
          headerComponentParams: header_template,
          field: 'patient_zip',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Treating Provider',
          headerComponentParams: header_template,
          field: 'treating_provider',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Treating Provider NPI',
          headerComponentParams: header_template,
          field: 'treating_provider_npi',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Treating Provider License Number',
          headerComponentParams: header_template,
          field: 'treating_provider_license',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Case',
          headerComponentParams: header_template,
          field: 'case',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Claim DOS',
          headerComponentParams: header_template,
          field: 'claim_dos',
          type: 'date',
          unSortIcon: true,
          filter: 'dateFilter',
          comparator: mm_dd_yyyy_comparator
      },
      {
          headerName: 'Dx 1',
          headerComponentParams: header_template,
          field: 'dx1',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Dx 2',
          headerComponentParams: header_template,
          field: 'dx2',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Dx 3',
          headerComponentParams: header_template,
          field: 'dx3',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Dx 4',
          headerComponentParams: header_template,
          field: 'dx4',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Dx 5',
          headerComponentParams: header_template,
          field: 'dx5',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Dx 6',
          headerComponentParams: header_template,
          field: 'dx6',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Dx 7',
          headerComponentParams: header_template,
          field: 'dx7',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Dx 8',
          headerComponentParams: header_template,
          field: 'dx8',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'CPT Code',
          headerComponentParams: header_template,
          field: 'cpt_code',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
        headerName: 'Mod 1',
        headerComponentParams: header_template,
        field: 'proc_mod1',
        type: 'string',
        unSortIcon: true,
        filter: 'textFilter',
        comparator: text_comparator
      },
      {
        headerName: 'Mod 2',
        headerComponentParams: header_template,
        field: 'proc_mod2',
        type: 'string',
        unSortIcon: true,
        filter: 'textFilter',
        comparator: text_comparator
      },
      {
        headerName: 'Mod 3',
        headerComponentParams: header_template,
        field: 'proc_mod3',
        type: 'string',
        unSortIcon: true,
        filter: 'textFilter',
        comparator: text_comparator
      },      
      {
          headerName: 'Amount Billed',
          headerComponentParams: header_template,
          field: 'amount_billed',
          type: 'currency',
          unSortIcon: true,
          filter: 'currencyFilter',
          comparator: currency_comparator
      },
      {
          headerName: 'Charge ID',
          headerComponentParams: header_template,
          field: 'charge_id',
          type: 'number',
          unSortIcon: true,
          filter: 'numberFilter',
          comparator: number_comparator
      },
      {
          headerName: 'Units',
          headerComponentParams: header_template,
          field: 'units',
          type: 'number',
          unSortIcon: true,
          filter: 'numberFilter',
          comparator: number_comparator
      },
      {
          headerName: 'Billing Provider',
          headerComponentParams: header_template,
          field: 'billing_provider',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Billing Provider NPI',
          headerComponentParams: header_template,
          field: 'billing_provider_npi',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Company Tax ID',
          headerComponentParams: header_template,
          field: 'company_tax_id',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Primary/Secondary/Tertiary Indicator',
          headerComponentParams: header_template,
          field: 'primary_secondary_tertiary_indicator',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Insurance',
          headerComponentParams: header_template,
          field: 'insurance',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Location',
          headerComponentParams: header_template,
          field: 'location',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      },
      {
          headerName: 'Referring Physician',
          headerComponentParams: header_template,
          field: 'referring_physician',
          type: 'string',
          unSortIcon: true,
          filter: 'textFilter',
          comparator: text_comparator
      }
    ]
};

export const batch_updates_report_grid_id = 'report_id_batch_updates';

export const batch_updates = (props) => {
  return {
    title: 'Batch Updates',
    title_search_criteria: {
      selected_company: {
        title: props.user_login_details.user_data.data.company_id,
        code: props.user_login_details.user_data.data.gpms_code,
        name: props.user_login_details.user_data.data.company_name,
        label: `${props.user_login_details.user_data.data.gpms_code} - ${props.user_login_details.user_data.data.company_name}`
      },
      selected_patient: {
        label: '',
        title: ''
      },
      date_of_service_from: null,
      date_of_service_end: null,
      days_aged_from: '',
      days_aged_to: '',
      charge_owed_amount_from: '',
      charge_owed_amount_to: '',
      run_number: '',
      // dropdown
      place_of_service: 'All',
      // Multi Select
      balance_type: '0 Selected',
      procedure: '0 Selected',
      provider: '0 Selected',
      insurance: '0 Selected',
      insurance_type: '0 Selected',
      insurance_class: '0 Selected',
      payer: '0 Selected'
    },
    initial_search_criteria: {
      company_id: props.user_login_details.user_data.data.company_id,
      patient_id: '',
      date_of_service_from: null,
      date_of_service_end: null,
      days_aged_from: '',
      days_aged_to: '',
      charge_owed_amount_from: '',
      charge_owed_amount_to: '',
      run_number: '',
      // dropdown
      place_of_service: '-1',
      // Multi Select
      procedure: [],
      provider: [],
      insurance: [],
      insurance_type: [],
      insurance_class: [],
      payer: [],
      balance_type: [],
      selected_company: {
        title: `${props.user_login_details.user_data.data.company_id}`,
        code: props.user_login_details.user_data.data.gpms_code,
        name: props.user_login_details.user_data.data.company_name,
        label: `${props.user_login_details.user_data.data.gpms_code} - ${props.user_login_details.user_data.data.company_name}`
      },
      selected_patient: {
        label: '',
        title: ''
      }
    },
    messages: {
      update_action_success: 'Changes saved successfully.',
      empty_error: 'You must select at least one record to',
      empty_update_error: 'You must select at least one record to update.',
      mismatch_update_error: 'At least one charge selection is invalid. Only valid charges are being processed.',
      update_msg: 'You must select at least one record to update.',
      valid_update_msg: 'You must select at least one valid record.',
      r2_valid_update_msg: '0 Charge(s) were updated, Total Charge Amount: $0.00.',
      numeric_allow: 'Only Numeric characters are allowed.',
      patient_dos_run_number_mandatory: "Either 'Patient ID', 'Date of Service' or 'Run Number' must be entered.",
      date_range_exceed: 'Date range cannot exceed',
      date_range_invalid: '"Through Date" must be on or after the "From Date".',
    },
    column_defs: [
      {
        headerComponentParams: header_template,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        field: 'checkbox',
        width: 35,
        maxWidth: 35,
        suppressFilter: true,
        suppressSorting: true,
        suppressSizeToFit: true,
        resizable: false
      },
      {
        headerName: 'Charge ID',
        headerComponentParams: get_header_template_with_navigation('batch_updates_configuration_header_id'),
        field: 'charge_id',
        filter: 'numberFilter',
        type: 'number',
        unSortIcon: true,
        comparator: number_comparator
      },
      {
        headerName: 'Patient ID',
        headerComponentParams: header_template,
        field: 'patient_id',
        filter: 'numberFilter',
        type: 'number',
        unSortIcon: true,
        comparator: number_comparator
      },
      {
        headerName: 'Status',
        headerComponentParams: header_template,
        field: 'status',
        filter: 'textFilter',
        type: 'string',
        unSortIcon: true,
        comparator: text_comparator
      },
      {
        headerName: 'Date of Service',
        headerComponentParams: header_template,
        field: 'date_of_service',
        filter: 'dateFilter',
        type: 'date',
        unSortIcon: true,
        comparator: mm_dd_yyyy_comparator
      },
      {
        headerName: 'Days Aged',
        headerComponentParams: header_template,
        field: 'age_in_days',
        filter: 'numberFilter',
        type: 'number',
        unSortIcon: true,
        comparator: number_comparator
      },
      {
        headerName: 'Age Start Date',
        headerComponentParams: header_template,
        field: 'age_start_date',
        filter: 'dateFilter',
        type: 'date',
        unSortIcon: true,
        comparator: mm_dd_yyyy_comparator
      },
      {
        headerName: 'Insurance',
        headerComponentParams: header_template,
        field: 'insurance',
        filter: 'textFilter',
        type: 'string',
        unSortIcon: true,
        comparator: text_comparator
      },
      {
        headerName: 'Balance Type',
        headerComponentParams: header_template,
        field: 'balance_type',
        filter: 'textFilter',
        type: 'string',
        unSortIcon: true,
        comparator: text_comparator
      },
      {
        headerName: 'Insurance Type',
        headerComponentParams: header_template,
        field: 'insurance_type',
        filter: 'textFilter',
        type: 'string',
        unSortIcon: true,
        comparator: text_comparator
      },
      {
        headerName: 'Insurance Class',
        headerComponentParams: header_template,
        field: 'insurance_class',
        filter: 'textFilter',
        type: 'string',
        unSortIcon: true,
        comparator: text_comparator
      },
      {
        headerName: 'Payer',
        headerComponentParams: header_template,
        field: 'payer_name',
        filter: 'textFilter',
        type: 'string',
        unSortIcon: true,
        comparator: text_comparator
      },
      {
        headerName: 'Provider',
        headerComponentParams: header_template,
        field: 'provider',
        filter: 'textFilter',
        type: 'string',
        unSortIcon: true,
        comparator: text_comparator
      },
      {
        headerName: 'Location',
        headerComponentParams: header_template,
        field: 'location',
        filter: 'textFilter',
        type: 'string',
        unSortIcon: true,
        comparator: text_comparator
      },
      {
        headerName: 'Procedure',
        headerComponentParams: header_template,
        field: 'procedure',
        filter: 'textFilter',
        type: 'string',
        unSortIcon: true,
        comparator: text_comparator
      },
      {
        headerName: 'Days Since Last Billed',
        headerComponentParams: header_template,
        field: 'days_since_last_billed',
        cellStyle: { textAlign: 'right' },
        filter: 'numberFilter',
        type: 'number',
        unSortIcon: true,
        comparator: number_comparator
      },
      {
        headerName: 'Total Charge Amount',
        headerComponentParams: header_template,
        field: 'total_charge_amount',
        filter: 'currencyFilter',
        comparator: currency_comparator,
        type: 'currency',
        cellStyle: { textAlign: 'right' },
        unSortIcon: true
      },
      {
        headerName: 'Owed Amount',
        headerComponentParams: header_template,
        comparator: currency_comparator,
        field: 'owed_amount',
        filter: 'currencyFilter',
        cellStyle: { textAlign: 'right' },
        type: 'currency',
        unSortIcon: true
      },
      {
        headerName: 'Claim Delay',
        headerComponentParams: header_template,
        field: 'claim_delay',
        filter: 'textFilter',
        type: 'string',
        unSortIcon: true,
        comparator: text_comparator
      }
    ]
  };
};

export const requestPatientStatements = {
  getCompanies: "Companyid",
  statusOptions: [
    { text: '--ALL STATUS--', value: '-1' },
    { text: 'Running', value: '2' },
    { text: 'Complete', value: '3' },
    { text: 'Failed', value: '4' },
    { text: 'Reset', value: '5' }
  ],
  defaultValues: {
    status: '-1',
    initialYear: 2002
  },
  configurationGrid: {
    selectedRowsLimit: 10
  },
  confirmationMessages: {
    reset: 'Are you sure you want to reset selected record(s)?'
  },
  errorMessages: {
    notFound: 'No Record Found!',
    errorDownload: 'Error File Download.',
    errorInvalidReset: 'No Record Selected For Reset.',
    errorReset: 'Error Batch Reset'
  },
  requestPatientStatementsMessages:{
    messageSuccess: 'Nice! Your Request has been processed',
    messageFail: 'Sorry! Your Request could not be processed'
  },
  downloadMessages: {
    messageSuccess: (successCount: number): string => `Nice! (${successCount}) File(s) generated.`,
    messageFail: (failCount: number): string => `(${failCount}) File(s) download failed.`
  },
  resetMessages: {
    messageSuccess: (successCount: number): string => `Alright! (${successCount}) Batch file(s) reset.`,
    messageFail: (failCount: number): string => `(${failCount}) Batch file(s) reset failed.`
  },
  column_defs: (component: any) => {
    return [
      {
        headerName: "",
        field: "select",
        width: 25,
        suppressSorting: true,
        suppressFilter: true,
        suppressSizeToFit: true,
        headerComponentParams: (params: any) => get_header_checkbox_template("selectRequestProcessId"),
        cellRenderer: (params: any) => handleCellCheckboxReqStmt(params, `checkboxReqStmt`, 'select', component)
      },
      {
        headerName: "",
        field: "requestProcessId",
        headerComponentParams: header_template,
        suppressSorting: true,
        type: 'int',
        suppressFilter: true,
        suppressSizeToFit: true,
        hide: true,
      },
      {
        headerName: "Company",
        headerComponentParams: header_template,
        field: "company",
        filter: "textFilter",
        resizable: true,
        width: 60,
        type: 'string',
        unSortIcon: true,
        comparator: text_comparator
      },
      {
        headerName: "Month",
        headerComponentParams: header_template,
        field: "month",
        filter: "textFilter",
        resizable: true,
        width: 23,
        type: 'string',
        unSortIcon: true,
        comparator: text_comparator
      },
      {
        headerName: "Year",
        headerComponentParams: header_template,
        field: "year",
        filter: "textFilter",
        resizable: true,
        width: 19,
        type: 'string',
        unSortIcon: true,
        comparator: text_comparator
      },
      {
        headerName: "Batch",
        headerComponentParams: header_template,
        field: "batch",
        filter: "textFilter",
        resizable: true,
        width: 21,
        type: 'string',
        unSortIcon: true,
        comparator: text_comparator
      },
      {
        headerName: "Test File",
        headerComponentParams: header_template,
        field: "testFile",
        filter: "checklistFilter",
        resizable: true,
        width: 18,
        type: 'boolean',
        unSortIcon: true,
        cellRenderer: readonly_checkbox_cell_renderer
      },
      {
        headerName: "Payright",
        headerComponentParams: header_template,
        field: "payright",
        filter: "checklistFilter",
        resizable: true,
        width: 25,
        type: 'boolean',
        unSortIcon: true,
        cellRenderer: readonly_checkbox_cell_renderer
      },
      {
        headerName: "Requested",
        headerComponentParams: header_template,
        field: "requested",
        filter: "textFilter",
        resizable: true,
        width: 55,
        type: 'string',
        unSortIcon: true,
        comparator: text_comparator
      },
      {
        headerName: "Status",
        headerComponentParams: header_template,
        field: "status",
        filter: "textFilter",
        resizable: true,
        width: 22,
        type: 'string',
        unSortIcon: true,
        comparator: text_comparator
      },
      {
        headerName: "Message",
        headerComponentParams: header_template,
        field: "message",
        filter: "textFilter",
        resizable: true,
        width: 53,
        type: 'string',
        unSortIcon: true,
        comparator: text_comparator
      },
      {
        headerName: "File Name",
        headerComponentParams: header_template,
        field: "fileName",
        filter: "textFilter",
        resizable: true,
        width: 81,
        type: 'string',
        unSortIcon: true,
        comparator: text_comparator
      },
    ]
  }
}