import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Grid, Header, Button } from 'semantic-ui-react';
import { set_focus_to_app_header } from './../../shared/tab_navigation_utility';
import { LoaderComponent } from '../../shared/component/loading_component';
import OpenZeroRemarksAndAdjustmentCodeComponent from './open_zero_remarks_and_adjustment_code_component';
import OpenZeroPaymentsDetailsInformationComponent from './open_zero_details_information_component'
import { get_eob_header_data,} from '../action/report_action';
import { get_all_error, print_html_data_to_pdf, } from '../../shared/utility';
import { toastr } from 'react-redux-toastr';
import * as report_constant from '../report_constants';
import * as export_print_utility from '../../reports/util/export_print_utility';
import { toastr_options } from '../../global_constants';

export class OpenZeroPaymentsDetailsComponent extends React.Component<any, any> {
    [x: string]: any;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            eob_key: "",
            eob_details_data: [],
            eob_remarks_data: []
        };
    }

    _is_mounted = false;
    token: any = "";
    eob_header_data: any = {};
    reportId: string = "0";

    // Fetching the eob key and params.
    UNSAFE_componentWillMount = () => {
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.setState({ eob_key: this.props.match.params.eob_key });
    };

    componentWillUnmount = () => {
        this._is_mounted = false;
    };

    componentDidMount() {
        this._is_mounted = true;
        this.get_eob_header_data();
    }

    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }

    // Get Header Data.
    get_eob_header_data = async () => {
        if (this._is_mounted) {
            this.setState({
                loading: true,
            });
        }
        this.eob_header_data = {};
        await get_eob_header_data(this.state.eob_key, this.token).then(
            (response) => {
                if (response.data && response.data.data) {
                    this.eob_header_data = response.data.data || {};
                } else {
                    const toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                    toastr.error('', toastr_options);
                }
                if (this._is_mounted) {
                    this.setState({
                        loading: false
                    });
                }
            },
            (error: { response: { data: any } }) => {
                if (this._is_mounted) {
                    this.setState({
                        loading: false
                    });
                }
                if (error.response.data) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toastr.error("", toastr_options);
                }
            }
        );
    };

    setDetailsInfo = (info) => {
        if (this._is_mounted) {
            this.setState({
                eob_details_data: info
            })
        }
    }

    setRemarks = (info) => {
        if (this._is_mounted) {
            this.setState({
                eob_remarks_data: info
            })
        }
    }

    handle_print = () => {
        let printContent = '';
        printContent += '<div><br/><br/>';
        printContent += `<strong>835 Information</strong><br/><br/>`;
        printContent += this.prepare_html_data();
        printContent += `<br/><br/><br/><strong>Detail Information</strong><br/><br/>`;
        printContent += export_print_utility.print_grid_data(this.state.eob_details_data, "", 'Open Zero Payments', 'print_report_button',true);
        printContent += `<br/><br/><br/><strong>Remarks and Adjustment Code</strong><br/><br/>`;
        printContent += export_print_utility.print_grid_data(this.state.eob_remarks_data, "", 'Open Zero Payments', 'print_report_button', true );
        printContent +='</div>'
        
        print_html_data_to_pdf(printContent, 'print_report_button', 'Open Zero Payments');
        this.saveReportEventAction(report_constant.ReportEventAction.Print);
    };

    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;

        const payload: report_constant.IPayloadForBillingAudit = {
            reportId: this.reportId,
            contextTitle: `Open Zero Payments - ${report_constant.ReportEventAction[eventActionId]}`,
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: report_constant.ReportEntityTypeId.EOB_RELEASE,
            data: { EobKey: this.state.eob_key }
        }

        const reqBody = export_print_utility.generateAuditDataEntryPayload(payload);

        report_constant.saveAuditPrintExport(reqBody, accessToken);
    };
    prepare_html_data = () => {
        const { eob_header_data } = this;
        let check_amt = eob_header_data.check_amt != null || eob_header_data.check_amt != undefined
            ? report_constant.currencyFormatter(eob_header_data.check_amt)
                : "";
        let content = `<div class="eob-grid-container">
              <div class="eob-grid-item"><label class="eob-label-normal">Company</label><div class="eob-label-value">${eob_header_data.company_name}</div></div>
              <div class="eob-grid-item"><label class="eob-label-normal">Payor</label><div class="eob-label-value">${eob_header_data.payor}</div></div>
              <div class="eob-grid-item"><label class="eob-label-normal">Check Number</label><div class="eob-label-value">${eob_header_data.check_eft_num}</div></div>
              <div class="eob-grid-item"><label class="eob-label-normal">835 Date</label><div class="eob-label-value">${eob_header_data.eob_date}</div></div>
              <div class="eob-grid-item"><label class="eob-label-normal">Check Amount</label><div class="eob-label-value">${check_amt}</div></div>
              <div class="eob-grid-item"><label class="eob-label-normal">Released By</label><div class="eob-label-value">${eob_header_data.release_by}</div></div>
              <div class="eob-grid-item"><label class="eob-label-normal">Released Date</label><div class="eob-label-value">${eob_header_data.release_date}</div></div> 
            </div>`;
        return content;
    }

    render() {
        const { loading } = this.state
        const { eob_header_data } = this;

        let check_amt = eob_header_data.check_amt != null || eob_header_data.check_amt != undefined
            ? report_constant.currencyFormatter(eob_header_data.check_amt)
            : "";

        return (
            <div className="common-forms common-forms-add-flex open-zero-payment">
                <Header as='h3' textAlign="left" id="applicationPageTitleSticky"> Open Zero Payments</Header>
                <div className="auto-height-patient-full-details-scroll" id="scrollable-content-area">
                <Form autoComplete="off" className="normal-form comapny_details_form">
                    <LoaderComponent loading={loading}>
                        <div id="eob_information_section">
                            <Grid className="form patient-search-form comapny_details_sub_section mar-t-5 ">
                                <Grid.Column id="information_section" computer={16} className="">
                                        <Header as='h2' textAlign="left" className="section-heading-miniHead">835 Information</Header>
                                    <Grid>
                                        <Grid.Column className={'form_column'} tablet={8} computer={4}>
                                            <label className="label-normal">Company</label>
                                            <div className="label-value">{this.eob_header_data.company_name}</div>
                                        </Grid.Column>
                                        <Grid.Column className={'form_column'} tablet={8} computer={4}>
                                            <label className="label-normal">Payor</label>
                                            <div className="label-value">{this.eob_header_data.payor}</div>
                                        </Grid.Column>
                                        <Grid.Column className={'form_column'} tablet={8} computer={4}>
                                            <label className="label-normal">Check Number</label>
                                            <div className="label-value">{this.eob_header_data.check_eft_num}</div>
                                        </Grid.Column>
                                        <Grid.Column className={'form_column'} tablet={8} computer={4}>
                                            <label className="label-normal">835 Date</label>
                                            <div className="label-value">{this.eob_header_data.eob_date}</div>
                                        </Grid.Column>
                                        <Grid.Column className={'form_column'} tablet={8} computer={4}>
                                            <label className="label-normal">Check Amount</label>
                                            <div className="label-value">{check_amt}</div>
                                        </Grid.Column>
                                        <Grid.Column className={'form_column'} tablet={8} computer={4}>
                                            <label className="label-normal">Released By</label>
                                            <div className="label-value">{this.eob_header_data.release_by}</div>
                                        </Grid.Column>
                                        <Grid.Column className={'form_column'} tablet={8} computer={4}>
                                            <label className="label-normal">Released Date</label>
                                            <div className="label-value">{this.eob_header_data.release_date}</div>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                            </Grid>
                        </div>
                        </LoaderComponent>
                        <OpenZeroPaymentsDetailsInformationComponent eob_key={this.state.eob_key} token={this.token} setDetailsInfo={this.setDetailsInfo} />
                        <OpenZeroRemarksAndAdjustmentCodeComponent eob_key={this.state.eob_key} setRemarks={this.setRemarks} />
                    </Form>
                </div>
                <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area" id="applicationFooterSticky">
                    <Button
                        onKeyDown={set_focus_to_app_header}
                        id='print_report_button'
                        type='button'
                        onClick={this.handle_print}
                        primary
                    >
                        Print
                    </Button>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OpenZeroPaymentsDetailsComponent));