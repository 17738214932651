import * as React from 'react';
import { connect } from 'react-redux';
import { toastr as toaster } from 'react-redux-toastr';
import TextareaAutosize from 'react-textarea-autosize';
import { Button, Dimmer, Form, Grid, Loader } from 'semantic-ui-react';
import PromptNavigation from '../../../shared/component/prompt_navigation_component';
import * as constant_action from '../action/constants_action';
import * as constants from '../constants';
import { set_focus_to_app_header } from '../../../shared/tab_navigation_utility';

class FailedChargeRuleAddEditComponent extends React.Component<any, any> {

    page_name: string = '';
    page_metadata: any;
    code_id: 0;
    _is_mounted = false;
    initial_form_object: any = {};
    form_object: any = {};
    token: string = '';
    is_error:boolean = false;

    constructor(props) {
        super(props);
        // Define initial state value.
        this.state = {
            form_object: {},
            is_loading: false,
            is_submitted: false,
            error: {},
            is_save_button_disabled: false
        };
    }

    UNSAFE_componentWillMount = () => {
        this._is_mounted = true;
        this.page_metadata = constants.failed_charge_rule;
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.code_id = this.props.match && this.props.match.params && this.props.match.params.id ? this.props.match.params.id : 0;
        this.set_default_form_data(this.page_metadata.control_data);
        if (this.code_id) {
            this.get_data(this.code_id)
        }
    }

    componentDidMount = () => {
        document.body.classList.add('admin-framework');
    };

    componentWillUnmount = () => {
        this._is_mounted = false;
        document.body.classList.remove('admin-framework');
    };

    get_form_object = (form_object: any) => {
        return {
            failed_charge_rule_reason_code: form_object.reason_code,
            failed_charge_rule_reason_short_description: form_object.reason_short_description,
            failed_charge_rule_reason_long_description: form_object.reason_long_description,
            failed_charge_rule_active: form_object.is_active
        }
    }

    // Get the data on the basis of ID.
    get_data = code_id => {
        let url = this.page_metadata.api.get.url(code_id)
        if (this._is_mounted) {
            this.setState({
                is_loading: true
            })
        }
        constant_action.get_data(this.token, url).then(response=>{
            if (response.data && response.data.data) {
                this.form_object = this.get_form_object(response.data.data)
                this.initial_form_object = { ...this.form_object }
                if (this._is_mounted) {
                    this.setState({
                        form_object: this.form_object,
                        is_loading: false
                    })
                }
            }else{
                if (this._is_mounted) {
                    this.setState({
                        is_loading: false
                    })
                }
                if (response.data.messages[0]) toaster.warning('', response.data.messages[0].message)
                this.props.history.push("/billing/failed_charge_rules/search");
            }
        },error => {
            if (this._is_mounted) {
                this.setState({
                    is_loading: false
                })
            }
            if (error.response.data) toaster.error('', error.response.data.messages[0].message)
            this.props.history.goBack()
        })
    }
    // Function used to set the input controls.
    set_default_form_data = control_data => {
        control_data.map(data => {
            let name = `${data.form_name}_${data.name}`;
            if (data.is_edit) {
                if (this._is_mounted) {
                    this.setState(({ error }) => ({
                        error: {
                            ...error,
                            [name]: false
                        }
                    }));
                }
                switch (data.control_type) {
                    case 'checkbox':
                        this.form_object[name] = data.default_value ? true : false;
                        break;
                    case 'text':
                        this.form_object[name] = '';
                        break;
                    default:
                        this.form_object[name] = '';
                }
            }
        });
        this.initial_form_object = { ...this.form_object }
        if (this._is_mounted) {
            this.setState({
                form_object: this.form_object
            });
        }
    };

    // validate each input box on change event
    validate_value = (value, name) => {
        let error = false;
        switch (name) {
            case 'failed_charge_rule_reason_short_description':
                error = (value ? value.length > 0 && value.length > 100 : true);
                this.form_object[name] = value;
                break;
            case 'failed_charge_rule_reason_long_description':
                error = (value ? value.length > 0 && value.length > 250 : true);
                this.form_object[name] = value;
                break;
            default:
                this.form_object[name] = value;
                error = value.trim() == '' ? true : false;
        }
        return error;
    };

    // Input change handler.
    input_change_handler = e => {
        const { value, name } = e.target;
        let error = this.state.error;
        if (e.target.validity.valid) {
            error[name] = this.validate_value(value, name);
        }
        if (this._is_mounted) {
            this.setState(prev_state => ({
                error: {
                    ...prev_state.error,
                    [name]: error[name]
                },
                form_object: this.form_object
            }));
        }
    };

    // Checkbox checkbox handler.
    checkbox_handler = e => {
        const { checked, name } = e.target;
        this.form_object[name] = JSON.parse(checked);
        if (this._is_mounted) {
            this.setState({
                form_object: this.form_object
            })
        }
    };

    cancel_handler = () => {
        this.props.history.goBack()
    };

    save_handler = (e, from_pop_up) => {
        if (this._is_mounted) {
            this.setState({
                is_submitted: true
            });
        }
        this.is_error = false;

        this.page_metadata.control_data
            .filter(item => item.is_required)
            .filter(data => {
                this.form_object[`${data.form_name}_${data.name}`] =
                    this.form_object[`${data.form_name}_${data.name}`] &&
                        typeof this.form_object[`${data.form_name}_${data.name}`] !== 'boolean'
                        ? this.form_object[`${data.form_name}_${data.name}`].toString().trim()
                        : this.form_object[`${data.form_name}_${data.name}`];

                if (!this.form_object[`${data.form_name}_${data.name}`].toString() && this._is_mounted) {
                    this.setState(prev_state => ({
                        error: {
                            ...prev_state.error,
                            [`${data.form_name}_${data.name}`]: true
                        }
                    }));
                    toaster.error('', constants.mandatory_fields);
                    this.is_error = true;
                    if (this._is_mounted) {
                        this.setState({
                            is_submitted: false
                        });
                    }
                }
            });

        for (var key in this.state.error) {
            if (this.state.error[key]) {
                toaster.error('', constants.mandatory_fields);
                this.is_error = true;

                if (this._is_mounted) {
                    this.setState({
                        is_submitted: false
                    });
                }
            }
        }

        if (this.is_error) {
            return;
        }
        if (this._is_mounted) {
            this.setState({
                is_loading: true,
                is_save_button_disabled: true
            });
        }
        if (this.code_id) {
            //Update
            this.update_failed_charge_rule(from_pop_up)
        } else {
            //Add
            this.add_failed_charge_rule(from_pop_up)
        }
    }

    get_request_body = (form_object: any) => {
        return {
            Reason_code: form_object.failed_charge_rule_reason_code == '' ? 0 : form_object.failed_charge_rule_reason_code,
            Reason_short_description: form_object.failed_charge_rule_reason_short_description,
            Reason_long_description: form_object.failed_charge_rule_reason_long_description,
            Is_active: form_object.failed_charge_rule_active
        }
    }

    update_failed_charge_rule = from_pop_up => {
        let url = this.page_metadata.api.update.url(this.code_id)
        let request_body = this.get_request_body(this.form_object);
        constant_action.update_data(this.token, url, request_body).then(
            response => {
                if (response.data && response.data.data) {
                    toaster.success('', this.page_metadata.validation_message.update);
                    if (this._is_mounted) {
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false
                        })
                    }
                    this.props.history.goBack()
                } else {
                    toaster.error('', response.data.messages[0].message)
                    if (this._is_mounted) {
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false
                        })
                    }
                }
            },
            error => {
                if (this._is_mounted) {
                    this.setState({
                        is_loading: false,
                        is_save_button_disabled: false
                    })
                }
                if (error.response.data) toaster.error('', error.response.data.messages[0].message)
            }
        )
    }

    add_failed_charge_rule = from_pop_up => {
        let url = this.page_metadata.api.add.url;
        const request_body = this.get_request_body(this.form_object);
        constant_action
            .add_data(this.token, url, {
                ...request_body
            })
            .then(
                response => {
                    if (response.data && response.data.data) {
                        toaster.success('', this.page_metadata.validation_message.save);
                        if (this._is_mounted) {
                            this.setState({
                                is_loading: false,
                                is_save_button_disabled: false
                            });
                        }
                        this.props.history.goBack()
                    } else {
                        toaster.error('', response.data.messages[0].message);
                        if (this._is_mounted) {
                            this.setState({
                                is_loading: false,
                                is_save_button_disabled: false
                            });
                        }
                    }
                },
                error => {
                    if (this._is_mounted) {
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false
                        });
                    }
                    if (error.response.data) toaster.error('', error.response.data.messages[0].message);
                }
            );
    };

    is_data_updated = () => {
        return !this.state.is_submitted && (JSON.stringify(this.form_object)!==JSON.stringify(this.initial_form_object))
    }

    set_controls = data_row => {
        let name = `${data_row.form_name}_${data_row.name}`;
        let disabled_control = false;

        switch (data_row.control_type) {
            case 'checkbox':
                return (
                    <Form.Field className='ui checkbox' style={data_row.custom_style}>
                        <div>
                            <input
                                disabled={disabled_control}
                                type='checkbox'
                                id={name}
                                name={name}
                                checked={this.state.form_object[name] ? true : false}
                                onChange={e => this.checkbox_handler(e)}
                            />
                            <label htmlFor={name} style={failed_charge_rule_css.label}>
                                {data_row.label}
                            </label>
                        </div>
                    </Form.Field>
                );
            case 'textarea':
                return (
                    <Form.Field className={
                        !this.state.form_object[name] && data_row.is_required && this.is_error && this.state.error[name]
                            ? 'requiredWithBgColor error'
                            : ''
                    } >
                        {data_row.label && (
                            <label>
                                {data_row.label}
                                {data_row.is_required && (
                                    <span
                                        className={
                                            !this.state.form_object[name] &&
                                                data_row.is_required &&
                                                this.is_error &&
                                                this.state.error[name]
                                                ? 'req-alert'
                                                : 'req-alert_normal'
                                        }
                                    >
                                        {' '}
                      (required)
                                    </span>
                                )}
                            </label>
                        )}
                        <TextareaAutosize
                            autoComplete='off'
                            id={name}
                            name={name}
                            maxLength={data_row.max_length ? data_row.max_length : 250}
                            type='text'
                            style={!this.state.form_object[name] && data_row.is_required && this.is_error && this.state.error[name]
                                ? failed_charge_rule_css.textarea
                                : {}}
                            onChange={e => this.input_change_handler(e)}
                            value={this.state.form_object[name] ? this.state.form_object[name] : ''}
                            disabled={data_row.is_disable}
                            onKeyDown={
                                (event) => {
                                    if (data_row.disable_enter) {
                                        if (event.keyCode == 13 || event.which == 13) {
                                            event.preventDefault();
                                            return false;
                                        }
                                    }
                                }
                            }
                            onFocus={(event) => { event.currentTarget ? event.currentTarget.select() : '' }}
                        />
                    </Form.Field>
                );
            default:
                return (
                    <Form.Field
                        className={
                            !this.state.form_object[name] && data_row.is_required && this.is_error && this.state.error[name]
                                ? 'requiredWithBgColor error'
                                : ''
                        }
                    >
                        {data_row.label && (
                            <label>
                                {data_row.label}
                                {data_row.is_required && (
                                    <span
                                        className={
                                            !this.state.form_object[name] &&
                                                data_row.is_required &&
                                                this.is_error &&
                                                this.state.error[name]
                                                ? 'req-alert'
                                                : 'req-alert_normal'
                                        }
                                    >
                                        {' '}
                      (required)
                                    </span>
                                )}
                            </label>
                        )}
                        <input
                            maxLength={data_row.max_length ? data_row.max_length : 100}
                            name={name}
                            id={name}
                            type={data_row.control_type}
                            value={this.state.form_object[name] ? this.state.form_object[name] : ''}
                            onChange={e => this.input_change_handler(e)}
                            disabled={!!this.code_id && data_row.non_editable || disabled_control}
                            pattern={data_row.pattern}
                            autoFocus={data_row.focus}
                        />
                        {data_row.is_required_text && this.is_error && this.state.error[name] && (
                            <div className='requiredText'>{data_row.is_required_msg}</div>
                        )}
                        {data_row.help_text && <div style={failed_charge_rule_css.help_text}>{data_row.help_text}</div>}
                    </Form.Field>
                );
        }
    };

    render() {
        return <>
            <Dimmer active={this.state.is_loading}>
                <Loader size='massive'>Loading</Loader>
            </Dimmer>
            <div
                className={'admin-wrapper view-edit ' + this.page_metadata.id}
            >
                <PromptNavigation
                    is_data_changed={this.is_data_updated()}
                    go_next_location={this.is_data_updated()}
                    prompt_message={this.page_metadata.prompt_message.add_new}
                    hide_yes_button={true}
                    no_button_text="Continue"
                    no_button_class_name="primary"
                />
                <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                    <Grid.Column computer={16}>
                        <h3 className='ui header left aligned'>{this.page_metadata.header}</h3>
                    </Grid.Column>
                </Grid>
                <div className='wrapper' style={failed_charge_rule_css.wrapper}>
                    <Form autoComplete='off' name='view_edit_form' className='common-forms' style={failed_charge_rule_css.common_forms}>
                        <div id='admin-scrollable-area' style={failed_charge_rule_css.scrollbar_area}>
                            {constants
                                .group_by(this.page_metadata.control_data, 'container')
                                .map((container_object, container_index) => {
                                    return <div key={'outer_row_' + container_index} style={failed_charge_rule_css.failed_charge_rule_form}>
                                        {constants.group_by(container_object, 'row').map((row_object, row_index) => {
                                            return <Grid key={'outer_row_' + row_index}>
                                                {row_object.map((data_row, index) => {
                                                    return (
                                                        <Grid.Column
                                                            className={data_row.control_type === 'checkbox' ? 'form_column' : ''}
                                                            key={'inner_row_' + index}
                                                            tablet={data_row.view_container_tablet}
                                                            computer={data_row.view_container_computer}
                                                            verticalAlign='top'
                                                            style={ data_row.is_hidden && !this.state.form_object[`${data_row.form_name}_${data_row.name}`]
                                                                    ? failed_charge_rule_css.hidden : {}}
                                                        >
                                                            {this.set_controls(data_row)}
                                                        </Grid.Column>
                                                    );
                                                })} </Grid>;
                                        })}
                                    </div>
                                })}
                        </div>
                    </Form>
                </div>
                <div
                    className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                    id='applicationFooterSticky'
                    style={failed_charge_rule_css.footerArea}
                >
                    <Button id='cancel_button_id' type='button' onClick={() => this.cancel_handler()} basic>
                        Cancel
              </Button>
                    <Button
                        type='submit'
                        onKeyDown={set_focus_to_app_header}
                        onClick={e => this.save_handler(e, false)}
                        id='save_button_id'
                        primary
                        disabled={this.state.is_save_button_disabled}
                    >
                        Save
              </Button>
                </div>
            </div>
        </>
    }
}

// CSS Style
const failed_charge_rule_css = {
    wrapper: {
        borderTop: 0,
        marginBottom: 0
    },
    common_forms: {
        paddingTop: 0,
        height: '100%',
        paddingBottom: 0,
        display: 'flex',
        flexDirection: 'column'
    } as React.CSSProperties,
    scrollbar_area: {
        height: '100%',
        paddingRight: 15
    } as React.CSSProperties,
    help_text: {
        color: '#757575',
        fontSize: 16,
        fontStyle: 'italic',
        fontWeight: 400,
        margin: 0,
        padding: 0
    },
    failed_charge_rule_form: {
        padding: '25px 15px 0px 0px',
        flexBasis: 'auto',
        borderColor: 'rgb(204, 204, 204)',
        borderStyle: 'solid',
        borderWidth: '1px 0px 0px',
        margin: '0px 0px 15px'
    },
    footerArea: {
        paddingLeft: 0,
        paddingBottom: 0,
        marginBottom: 12,
        paddingRight: 25,
        paddingTop: 20
    },
    hidden: {
        display: 'none'
    },
    textarea: {
        border: '1px solid #ed1c24',
        backgroundColor: '#feedeb'
    },
    label: {
        lineHeight: 'inherit',
        minHeight: '17px'
    }
}

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details
    };
};

export default connect(mapStateToProps)(FailedChargeRuleAddEditComponent);