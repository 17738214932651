import * as moment from 'moment';
import * as React from 'react';
import { Grid, Checkbox } from 'semantic-ui-react';
import { ErrorOutlineIcon } from "@webpt/bonfire";
import { sticky_note_help_url } from '../payment_constants';
class PaymentStickyNotesComponent extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            paymentStickyNotes: this.props.paymentStickyNotes,
            excludedNote: this.props.excludedNote
        }
	}

    paymentStickyNotesInfo = () => {
        return (
            <Grid columns={4} style={{ margin: "0", paddingBottom: "8px" }}>
                <Grid.Row className="margin-zero " style={{ fontSize: "16px", fontFamily: "ProximaNova-Bold", paddingTop: "8px", paddingBottom: "2px" }}>
                    <Grid.Column tablet={9} computer={9} style={{ paddingLeft: "0px" }}>
                        <span className={this.hasExclusion() ? "sticky-note sticky-note-exclude" : "sticky-note sticky-note-error"}>
                            Payment Sticky Note</span>
                    </Grid.Column>
                    <Grid.Column tablet={2} computer={2}>Code</Grid.Column>
                    <Grid.Column tablet={2} computer={2}>Date</Grid.Column>
                    <Grid.Column tablet={3} computer={3}>Posted By</Grid.Column>
                </Grid.Row>
                {(this.state.paymentStickyNotes && this.state.paymentStickyNotes.map((paymentStickyNote: any, index: any) => {
                    return (
                        <Grid.Row className="margin-zero padding-zero" key={`sticky-${index}`}>
                            <Grid.Column tablet={9} computer={9} style={{ paddingLeft: "25px" }} className="text-nowrap">
                                {paymentStickyNote.note_text}
                            </Grid.Column>
                            <Grid.Column tablet={2} computer={2} className="text-nowrap">{paymentStickyNote.note_code}</Grid.Column>
                            <Grid.Column tablet={2} computer={2} className="text-nowrap">{moment(paymentStickyNote.created_date).format('MM/DD/YYYY')}</Grid.Column>
                            <Grid.Column tablet={3} computer={3} className="text-nowrap">{paymentStickyNote.posted_by}</Grid.Column>
                        </Grid.Row>
                    )
                }))}
            </Grid>
        )
    }

    onChecked = () => {
        this.props.onChecked(this.props.index)
    }

    hasExclusion = (): boolean => {
        return this.props.hasExclusion(this.props.index)
    }

    paymentStickyNotesError = () => {
        return (
            <div style={{ display: "flex", flexDirection: "row", paddingTop: "8px" }}>
                <div style={{ width: "35%" }}>
                    <span style={{ fontFamily: "ProximaNova-Regular", marginTop: "3px" }}>
                        <Checkbox checked={this.hasExclusion()} onChange={this.onChecked} label='Post and exclude error' />
                    </span>
                </div>
                <div style={this.hasExclusion() ?{ visibility:"hidden" }:{ visibility:"visible" }}><ErrorOutlineIcon size="small" style={{fill:"#98262B"}}/></div>
                <div style={{ width: "45%", paddingLeft: "2px" }}>
                    <span style={this.hasExclusion() ? { visibility: "hidden" } : { visibility: "visible", fontSize: "14px", fontFamily: "ProximaNova-Regular" }}>
                        Charge lines on this account will not post unless 'Post and exclude error' is selected.
                    </span>
                </div>
                <div style={{ width: "20%", textAlign: "right", paddingRight: "15px" }}>
                    <span style={this.hasExclusion() ? { visibility: "hidden" } : { visibility: "visible", color: "#007298", fontSize: "16px", fontFamily: "ProximaNova-Bold" }}>
						<a href={sticky_note_help_url} target="_blank">
                            Learn More
                        </a>
                    </span>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className={this.hasExclusion() ? "sticky-note-notice sticky-note-notice-exclude": "sticky-note-notice sticky-note-notice-error"}>
                <div style={{ width: "57%" }}>{this.paymentStickyNotesInfo()}</div>
                <div style={{ width: "43%" }}>{this.paymentStickyNotesError()}</div>
            </div>
        )
    }
}
export default PaymentStickyNotesComponent;
