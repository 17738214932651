// Add/Edit Payer Rules Search FF Component
import * as React from 'react';
import { connect } from 'react-redux';
import { Accordion, Button, Dimmer, Form, Grid, Icon, Input, Loader } from 'semantic-ui-react';
import { AlertConfirm } from '../../../shared/component/alert_confirm_component';
import { withRouter } from 'react-router-dom';
import * as session_storage from '../../../shared/session_storage_utility';
import { handle_click_on_enter, set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import * as constants_actions from '../action/constants_action';
import { get_columns,  } from "./../../../shared/utility"
import * as constants from '../constants';
import * as global_constants from '../../../global_constants';
import Selection from '../../../shared/component/selection_component';
import GridView from '../../../shared/component/grid/grid_child_ui_component';
import { LoaderComponent } from '../../../shared/component/loading_component';
import { get_adjustment_code } from '../../../payment/batch_payment/action/batch_payment_action';
import { advanced_search_payer } from './../../../shared/action/shared_action';
import * as moment from 'moment';
import { payer_rule_summary, expected_rate  } from '../constants';
import DrawerComponent from '../../../shared/component/drawer_component';
import { Position, Drawer } from '@blueprintjs/core';
import PayerRulesAddEditDrawerComponent from './payer_rules_add_edit_drawer_component';
import { toastr as toaster } from 'react-redux-toastr';
import { export_grid_data, generateAuditDataEntryPayload, print_grid_data } from '../../../reports/util/export_print_utility';
import * as ReportConstants from '../../../reports/report_constants';
import { updateEOBValidationErrors } from '../../../payment/action/payment_action';
import * as _ from 'lodash';

declare global {
    interface Window {
        set_selected_option: any;
    }
}

export class PayerRulesSearchFFComponent extends React.Component<any, any> {

    constructor(props) {
        super(props);
        // Define initial state value.
        this.state = {
            show_grid: false,
            show_render: true,
            payerrules_search_criteria: [],
            payer_rules: {
                rows: [],
                payer_rules_summary_column_def: payer_rule_summary.column_def,
                grid_header_height: 0,
                total_column_width: 0,
                max_inner_width: 0,
                grid_width: 0
            },
            is_loading: false,
            shown: false,
            grid_rows_count: 0,
            controls_is_expanded: true,
            inbounds: [],
            payer_codes: [],
            payment_codes: [],
            adjustment_codes: [],
            is_loading_dropdown: true,
            isDrawerOpen: false,
            payerRule: {
                originalRowData: {},
                originalRowIndex: {}
            },
            searchCriteriaSaved:{},
            alert: {
                display: false,
                message: '',
                action: '',
                title: '',
                label_yes: ''
            },
            isDataUpdated:false
        };
        this.get_page_metadata(true);
        window.set_selected_option = this.set_selected_option.bind(this);
    }

    // Define initial properties and its value.
    _is_mounted = false;
    _grid_ref = { api: null };
    page_name: string = 'add_edit_enhanced_payer_rules';
    page_metadata: any;
    width_padding: number = 100;
    payerrules_search_criteria: any = {
        inbound_id: '',
        description: '',
        payer_id: '',
        payment_code_id: '',
        adjustment_code_id: '',
    };
    initial_payerrules_search_criteria: any = {
        inbound_id: '',
        description: '',
        payer_id: '',
        payment_code_id: '',
        adjustment_code_id: '',
    };
    payer_codes_search: any = {
        Page_size: global_constants.constants.PAGE_SIZE,
        Description: '',
        Outbound_id: '',
        Inbound_id: '',
        name: ''
    };
    token: string = '';
    row_data = [];
    is_search_ui: any = false;
    code_id: number = 0;
    is_grid_visible = false;
    title = '';
    current_date: any;
    company_name: any;
    total_column_width: number = 0;
    grid_header_height: number = 0;
    current_user_type: global_constants.enum_user_type_r3_id = 1;
    company_service_type = 0;
    select_option = { text: 'Select', value: ' ', key: 'Select' };
    report_date = moment().format("MM/DD/YYYY hh:mm:ss A").toString();
    option_clicked: string = "";
    reportId: string = '0';
    on_event_detect: string = "";

    componentDidMount = () => {
        this._is_mounted = true;
        if (!document.body.classList.contains("reports")) {
            document.body.classList.add("reports");
        }

        this.handle_search_button_tab();
        this.get_api_data();
        history.pushState(null, document.title, location.href);
        window.addEventListener('popstate', this.on_go_back);
        window.addEventListener('beforeunload', this.on_reload_page);
    };

    componentWillUnmount = () => {
        document.body.classList.remove("reports");
        this._is_mounted = false;
        window.removeEventListener('popstate', this.on_go_back);
        window.removeEventListener('beforeunload', this.on_reload_page);        
    };

    //Use to fetch params and page metadata
    get_page_metadata = (isFirstCall = false) => {
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        //Verifying page_name is correct or not.
        this.page_metadata = constants.payer_rule_summary;
        if (!isFirstCall) {
            this.payerrules_search_criteria = (session_storage.get('payerrules_enhanced_search_criteria')) || this.payerrules_search_criteria;
            if (session_storage.get('payerrules_enhanced_search_criteria')) {
                this.search_handler(this);
            }
        }
    };

    /*Dropdown API call*/
    get_api_data = async () => {
        const dropdown_data = {};
        dropdown_data['inbounds'] = await this.get_inbounds();
        dropdown_data['payer_codes'] = await this.get_payer_codes();
        dropdown_data['payment_codes'] = await this.get_payment_codes();
        dropdown_data['adjustment_codes'] = await this.get_adjustment_codes();
        dropdown_data['is_loading_dropdown'] = false;
        this.setState(dropdown_data);
    }

    get_inbounds = () => {
        const url = this.page_metadata.api.inbounds.url;
        return constants_actions.get_data(this.token, url).then(
            response => {
                if (response.data && response.data.data) {
                    return response.data.data.map((val, index) => { return { key: index, value: val.inbound_id, text: val.inbound_id, data: val } });
                } else {
                    return [];
                }
            },
            error => {
                return [];
            }
        );
    }

    get_payer_codes = () => {
        return advanced_search_payer(this.payer_codes_search, this.token).then(
            (response) => {
                if (response.data && response.data.data) {
                    let data = response.data.data.filter(code => code.inbound_id !== '').sort((obj1, obj2) => {
                        let code1 = obj1.name || '';
                        let code2 = obj2.name || '';
                        const value1 = code1.toString().toLowerCase();
                        const value2 = code2.toString().toLowerCase();
                        if (value1 < value2) {
                            return -1;
                        }
                        if (value1 > value2) {
                            return 1;
                        }
                        return 0;
                    });
                    return data.map((val, index) => {
                        return {
                            key: index,
                            value: val.payer_key_id,
                            text: `${val.name} - ${val.description}`,
                            data: val
                        }
                    })
                } else {
                    return [];
                }
            },
            error => {
                return [];
            }
        );
    }

    get_payment_codes = () => {
        if (this.props.user_login_details.master_data &&
            this.props.user_login_details.master_data.data &&
            this.props.user_login_details.master_data.data.payment_code) {
            let payment_code_data = this.props.user_login_details.master_data.data.payment_code.filter(code => code.is_active == true);
            let data = payment_code_data.sort((obj1, obj2) => {
                let code1 = obj1.payment_code || '';
                let code2 = obj2.payment_code || '';
                const value1 = code1.toString().toLowerCase();
                const value2 = code2.toString().toLowerCase();
                if (value1 < value2) {
                    return -1;
                }
                if (value1 > value2) {
                    return 1;
                }
                return 0;
            });
            return data.map((val, index) => {
                return {
                    key: index,
                    value: val.payment_id,
                    text: `${val.payment_code} - ${val.payment_desc}`,
                    data: val
                }
            });
        } else {
            return [];
        }
    }

    get_adjustment_codes = () => {
        return get_adjustment_code(this.token).then(
            response => {
                if (response.data && response.data.data) {
                    let data = response.data.data.sort((obj1, obj2) => {
                        let code1 = obj1.adjustment_code || '';
                        let code2 = obj2.adjustment_code || '';
                        const value1 = code1.toString().toLowerCase();
                        const value2 = code2.toString().toLowerCase();
                        if (value1 < value2) {
                            return -1;
                        }
                        if (value1 > value2) {
                            return 1;
                        }
                        return 0;
                    });
                    return data.map((val, index) => {
                        return {
                            key: index,
                            value: val.adjustment_code_id,
                            text: `${val.adjustment_code} - ${val.adjustment_desc}`,
                            data: val
                        }
                    })
                } else {
                    return [];
                }
            },
            error => {
                return [];
            }
        );
    }

    onChangeDropdownHandler = e => {
        const { value, name } = e.target;
        this.payerrules_search_criteria[name] = value;
        this.setState({
            payerrules_search_criteria: this.payerrules_search_criteria
        });
    };

    //Input change handler.
    input_change_handler = e => {
        const { value, checked, type, name } = e.target;
        if (type == 'checkbox') {
            this.payerrules_search_criteria[name] = checked;
        } else {
            this.payerrules_search_criteria[name] = value;
        }
        this.setState({
            payerrules_search_criteria: this.payerrules_search_criteria
        });
    };

    //Search button handler and used to search the data on the basis of search criteria.
    search_handler = e => {
        var url = this.page_metadata.api.search.url;

        for (let key in this.payerrules_search_criteria) {
            url = url.replace(key + '_value', encodeURIComponent(this.payerrules_search_criteria[key].toString().trim()));
        }

        this.setState({
            is_loading: true,
            is_search_button_disabled: true
        });
        constants_actions.get_search_data(this.token, url).then(
            async response => {
                this.setState({
                    searchCriteriaSaved: {
                        inbound: _.find(this.state.inbounds, { 'value': this.payerrules_search_criteria.inbound_id}),
                        description: this.payerrules_search_criteria.description,
                        payerCode:_.find(this.state.payer_codes, { 'value': Number(this.payerrules_search_criteria.payer_id)}),
                        paymentCode:_.find(this.state.payment_codes, { 'value': Number(this.payerrules_search_criteria.payment_code_id)}),
                        adjustmentCode:_.find(this.state.adjustment_codes, { 'value': Number(this.payerrules_search_criteria.adjustment_code_id)}),
                    },
                    controls_is_expanded: false
                });
                if (response.data.data && response.data.data.length > 0) {
                    let formated_table = await this.format_data(response);
                    session_storage.set(payer_rule_summary.session_key, {
                        search_criteria: this.state.search_criteria
                    });
                    this.is_search_ui = true;
                    this.is_grid_visible = true;

                    this.setState({
                        loading: false,
                        payer_rules: formated_table,
                        show_grid: true,

                        is_loading: false,
                        is_search_button_disabled: false,
                        payerrules_search_criteria: this.payerrules_search_criteria,
                        grid_rows_count: formated_table.rows && formated_table.rows.length
                    });
                    session_storage.set('payerrules_enhanced_search_criteria', this.payerrules_search_criteria);
                } else {
                    this.setState({
                        is_loading: false,
                        payer_rules: {
                            rows: [],
                            payer_rules_summary_column_def: payer_rule_summary.column_def,
                            grid_header_height: 0,
                            total_column_width: 0,
                            max_inner_width: 0,
                            grid_width: 0
                        },
                        grid_rows_count: 0,
                        show_grid: true
                    });
                }
            },
            error => {
                this.setState({
                    is_loading: false,
                    is_search_button_disabled: false
                });

            }
        );
    };

    get_grid_ref = grid_ref => {
        this._grid_ref = grid_ref;

    };

    on_cell_Click = async ({ data, column }) => {
        if (column.colId === payer_rule_summary.subtable_type) {
            let primaryClicked = this.option_clicked.localeCompare(payer_rule_summary.primary_payer_rule) == 0;
            let secondaryClicked = this.option_clicked.localeCompare(payer_rule_summary.secondary_payer_rule) == 0;
            let tertiaryClicked = this.option_clicked.localeCompare(payer_rule_summary.tertiary_payer_rule) == 0;

            if (primaryClicked || secondaryClicked || tertiaryClicked) {      
                data.claimTabId = primaryClicked ? payer_rule_summary.primary_id : secondaryClicked ? payer_rule_summary.secondary_id : tertiaryClicked ? payer_rule_summary.tertiary_id: '';
                data.insurance_details[0].insAdjustmentsOff = !data.insurance_details[0].insAdjustments;
                data.insurance_details[0].insBalancesOff = !data.insurance_details[0].insBalances;
                data.insurance_details[0].patBalancesOff = !data.insurance_details[0].patBalances;
                data.insurance_details[1].insAdjustmentsOff = !data.insurance_details[1].insAdjustments;
                data.insurance_details[1].insBalancesOff = !data.insurance_details[1].insBalances;
                data.insurance_details[1].patBalancesOff = !data.insurance_details[1].patBalances;
                data.insurance_details[2].insAdjustmentsOff = !data.insurance_details[2].insAdjustments;
                data.insurance_details[2].insBalancesOff = !data.insurance_details[2].insBalances;
                data.insurance_details[2].patBalancesOff = !data.insurance_details[2].patBalances;
                
                this.setState({
                    payerRule: {
                        originalRowData: data,
                        originalRowIndex: column
                    },
                    openPanelMode: {
                        isUpdateRow: true,
                        isCreateRow: false,
                        labelSave: 'Update'
                    },
                });
                this.handleDrawerOpen();
            }
        }
        this.option_clicked = '';
    }

    set_selected_option(option) {
        this.option_clicked = option;
    }

    is_row_selectable = (params) => {
        if (params.data && params.data.participants) {
            return true;
        }
        return false;
    }

    //Format Data
    format_data = async (response) => {
        let formated_table_object = {
            rows: [],
            payer_rules_summary_column_def: payer_rule_summary.column_def,
            grid_header_height: 0,
            total_column_width: 0,
            max_inner_width: 0,
            grid_width: 0
        };
        formated_table_object.rows = response.data.data
            ? response.data.data.map(item => {
                const child_column = payer_rule_summary.inner_cols.map(value => ({ ...value }));
                item = {
                    ...item,
                    participants: [
                        {
                            ...item,
                            child_column,
                            details: item.insurance_details
                        }
                    ],
                    group: ''
                };
                delete item[payer_rule_summary.detail_field_name];
                return item;
            })
            : [];
        formated_table_object.total_column_width = 0;
        const grid_height = get_columns(
            formated_table_object.rows,
            formated_table_object.payer_rules_summary_column_def
        );

        formated_table_object.grid_header_height = grid_height.header_height;
        formated_table_object.total_column_width = grid_height.total_column_width;
        formated_table_object.grid_width = document.getElementsByClassName('report-wrapper')[0].clientWidth;
        return formated_table_object;
    }

    //Reset button the search control.
    clear_handler = e => {
        this.payerrules_search_criteria = { ...this.initial_payerrules_search_criteria };
        this.setState({
            show_grid: false,
            shown: false,
            payerrules_search_criteria: this.initial_payerrules_search_criteria,
            grid_rows_count: 0
        });
        this.is_search_ui = false;
        this.is_grid_visible = false;
        session_storage.remove('payerrules_enhanced_search_criteria');
    };

    // Search Criteria Toggler
    controls_toggle = () => {
        if (this._is_mounted) {
            this.setState({
                controls_is_expanded: !this.state.controls_is_expanded,
            });
        }
    };

    handle_search_button_tab = () => {
        setTimeout(() => {
            let searchButton = document.getElementById('search_button_id');
            let self = this;
            searchButton.addEventListener('keydown', function (event) {
                // apply a check where grid is empty
                if (!event.shiftKey && (self.row_data == null || (self.row_data.length == 0 && !self.state.show_grid))) {
                    set_focus_to_app_header(event);
                }
            });
        }, 200);
    };

    new_payer_rule_handler = () => {
        this.setState({
            openPanelMode: {
                isUpdateRow: false,
                isCreateRow: true,
                labelSave: 'Save'
            }
        });
        //this.unbindBillingRowData();
        this.handleDrawerOpen();
    };

    private handleDrawerClose = () => {
        if(this._is_mounted){
            this.state.isDataUpdated?
            this.alert_box_helper(true,expected_rate.prompt_message.add_new,'',expected_rate.prompt_title.unsaved_changes,expected_rate.prompt_button_label.continue):
            this.setState({
                isDrawerOpen: false,
                payerRule: {
                    originalRowData: {},
                    originalRowIndex: {}
                    },
            });
        }
    }
    private handleDrawerOpen = () => this.setState({ isDrawerOpen: true });

    private getPayerRulesAddComponent = () => {
        return (
            <PayerRulesAddEditDrawerComponent
                saveHandlerCallback={this.payerRulesSaveCallback}
                updateHandlerCallback={this.is_payer_rules_updated_callback}
                {...this.state} />
        );
    };

    // Show multiple messages
    show_html_content_toaster = msg => {
        return {
            component: () => (<div><div dangerouslySetInnerHTML={{ __html: msg }} /></div>),
            timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        };
    };

    private payerRulesSaveCallback = (incomingPayload) => {
        let dto = Object.assign({}, incomingPayload);
        this.setState({
            is_loading: true,
            is_save_button_disabled: true
        });
        dto.inboundId = dto.inboundId.trim();
		if (this.state.openPanelMode.isCreateRow) {
			let url_errors = this.page_metadata.api.get_eob_key_list_matching_rule_error.url + dto.inboundId;
			constants_actions.get_data(this.token, url_errors).then(
				async (response) => {
					if (response.data) {
						response.data.data.forEach(obj => {
							try {
								let response = updateEOBValidationErrors(obj.eob_Key, this.token).then(res => {
									return res;
								});
							} catch (error) {
								console.log(error);
							}
						});
					}
				}
			);

            let url = this.page_metadata.api.save_payer_rule.url;
            constants_actions.add_data(this.token, url, dto).then(
                async (response) => {
                    if (response.data && response.data.data !== -1) {
                        const inbounds = await this.get_inbounds();
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false,
                            inbounds
						});
						toaster.success('', payer_rule_summary.successfully_saved_message);
                        this.handleDrawerClose();
                        this.clear_handler(true);
                    } else {
                        if (response.data.messages[0].message.indexOf('<br') > -1) {
                            this.show_html_content_toaster(response.data.messages[0].message);
                        } else {
                            toaster.error('', response.data.messages[0].message);
                        }
                        if (this._is_mounted) {
                            this.setState({
                                is_loading: false,
                                is_save_button_disabled: false
                            });
                        }
                    }
                },
                error => {
                    if (this._is_mounted) {
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false
                        });
                    }
                    if (error.response.data) {
                        toaster.error('', error.response.data.messages[0].message);
                    }
                    toaster.error('', this.page_metadata.validation_message.failure);
                }
            );
        } else if (this.state.openPanelMode.isUpdateRow) {
            constants_actions.update_data(this.token, this.page_metadata.api.update_payer_rule.url, dto).then(
                response => {
                    if (response.data && response.data.data !== -1) {
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false,
                        });
                        toaster.success('', payer_rule_summary.successfully_saved_message);
                        this.handleDrawerClose();
                        this.clear_handler(true);
                    } else {
                        if (response.data.messages[0].message.indexOf('<br') > -1) {
                            this.show_html_content_toaster(response.data.messages[0].message);
                        } else {
                            toaster.error('', response.data.messages[0].message);
                        }
                        if (this._is_mounted) {
                            this.setState({
                                is_loading: false,
                                is_save_button_disabled: false
                            });
                        }
                    }
                },
                error => {
                    if (this._is_mounted) {
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false
                        });
                    }
                    if (error.response.data) {
                        toaster.error('', error.response.data.messages[0].message);
                    }
                    toaster.error('', this.page_metadata.validation_message.failure);
                }
            );
        }

    }

    buildTitle = (separator:string) => {
        return `Inbound ID: ${(this.state.searchCriteriaSaved.inbound && this.state.searchCriteriaSaved.inbound.text) ||''}${separator}`
                +`Description: ${this.state.searchCriteriaSaved.description ||''}${separator}`
                +`Payer Code: ${(this.state.searchCriteriaSaved.payerCode && this.state.searchCriteriaSaved.payerCode.text) ||''}${separator}`
                +`Payment Code: ${(this.state.searchCriteriaSaved.paymentCode && this.state.searchCriteriaSaved.paymentCode.text) ||''}${separator}`
                +`Adjustment Code: ${(this.state.searchCriteriaSaved.adjustmentCode && this.state.searchCriteriaSaved.adjustmentCode.text) ||''}${separator}`
                +`${moment(new Date()).format('MM/DD/YYYY hh:mm:ss A')}${separator}`
                +`${this.state.grid_rows_count} ${this.state.grid_rows_count==1?'record':'records'}${separator}${separator}`
    }

    print = () => {
        print_grid_data(this._grid_ref,this.buildTitle('<br/>'),this.page_metadata.search_header);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
    }

    export = () => {
        export_grid_data(this._grid_ref,this.buildTitle('\n'),this.page_metadata.search_header);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Export);
    }

    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;
            
        const payload: ReportConstants.IPayloadForBillingAudit = {
            reportId: this.reportId,
            contextTitle: `${this.page_name} - ${ReportConstants.ReportEventAction[eventActionId]}`,
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: ReportConstants.ReportEntityTypeId.EOB_ENHANCED_PAYER_RULE,
            data: { Records: Number(this.state.grid_rows_count) },
        }

        const reqBody = generateAuditDataEntryPayload(payload);

        ReportConstants.saveAuditPrintExport(reqBody, accessToken);
    };

    is_payer_rules_updated_callback = (is_updated: boolean) => {
        this.setState({isDataUpdated: is_updated})
    }

    alert_box_helper = (show_alert_box, alert_msg, action, title, label_yes) => {
        this.setState({
            alert: {
                display: show_alert_box,
                message: alert_msg,
                action: action,
                title: title,
                label_yes: label_yes
            }
        })
    }
    
    handle_confirm = (e) => {
        e.preventDefault();  
        this.setState(
            {
            isDataUpdated:false,
            alert: {
                display: false,
                message: '',
                action: '',
                title: '',
                label_yes: ''
            },
            isDrawerOpen: false
        });
        this.on_event_action();
    }

    on_cancel_or_close = () => {
        this.alert_box_helper(false, '', '', '', '');
        history.pushState(null, document.title, location.href);
        this.on_event_detect="";
    }

    on_event_action = () => {
        if(this.on_event_detect==="go_back"){
            this.go_back()
        }
        this.on_event_detect="";
    }

    on_go_back = () => {   
        if(this.state.isDataUpdated){
            this.on_event_detect="go_back";
            this.alert_box_helper(true,expected_rate.prompt_message.add_new,'',expected_rate.prompt_title.unsaved_changes,expected_rate.prompt_button_label.continue)    
        }
        else{
            history.back(); 
        }
    }

    on_reload_page = (e) => {
        if(this.state.isDataUpdated){
            e.returnValue = null;
        }
    }

    go_back = () => {        
        history.back(); 
    }

    //It renders report grid and its search controls.
    render() {
        const inbounds = [this.select_option, ...this.state.inbounds];
        const payer_codes = [this.select_option, ...this.state.payer_codes];
        const payment_codes = [this.select_option, ...this.state.payment_codes];
        const adjustment_codes = [this.select_option, ...this.state.adjustment_codes];

        return (
            this.state.show_render &&
            (<>
                <Dimmer active={this.state.is_loading || this.state.is_loading_dropdown}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <AlertConfirm
                    open={this.state.alert.display}
                    close={this.on_cancel_or_close}
                    cancel={this.on_cancel_or_close}
                    confirm={this.handle_confirm}
                    message={this.state.alert.message}
                    title={this.state.alert.title}
                    labelYes={this.state.alert.label_yes}
                />
                <div className={"common-forms-add "}>
                    <div className={"common-forms-search report-framework"}>
                        <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                            <Grid.Column computer={8}>
                                <h3 className='ui header left aligned' dangerouslySetInnerHTML={{ __html: this.page_metadata.search_header }} />
                            </Grid.Column>
                            <Grid.Column tablet={8} computer={8} textAlign='right'>
                                <Button
                                    id='export_report_button'
                                    type="button"
                                    basic
                                    content={'Export'}
                                    onClick={this.export}
                                    disabled={!this.state.show_grid}
                                />
                                <Button
                                    id='print_report_button'
                                    type='button'
                                    basic
                                    content={'Print'}
                                    onClick={this.print}
                                    disabled={!this.state.show_grid}
                                />
                                <Button
                                    id='btn-add'
                                    type='button'
                                    className='primary'
                                    content={'Add Payer Rule'}
                                    onClick={this.new_payer_rule_handler}
                                />
                            </Grid.Column>
                        </Grid>
                        <div id='report-scrollable-area' className='report-wrapper' style={this.state.show_grid ? { flex: 1 } : {}}>
                            <div className='patient-search-form patient_search_bottom_padding' id='report-criteria-container'>
                                <Form autoComplete='off' onSubmit={e => this.search_handler(e)}>
                                    <Grid>
                                        <Grid.Column computer={16} tablet={16} textAlign='left' className='accordionColumn'>
                                            <Accordion fluid styled>
                                                <Accordion.Title active={this.state.controls_is_expanded} index={0} onClick={this.controls_toggle}>
                                                    <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name='angle right' />
                                                    Search Criteria
                                                </Accordion.Title>
                                                <Accordion.Content active={this.state.controls_is_expanded}>
                                                    <Grid>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field>
                                                                <label>Inbound ID</label>
                                                                <Selection
                                                                    id='inbound_id'
                                                                    name='inbound_id'
                                                                    defaultValue={this.payerrules_search_criteria.inbound_id}
                                                                    options={inbounds}
                                                                    onChange={(value, e) => {
                                                                        this.onChangeDropdownHandler(e);
                                                                    }}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field>
                                                                <label>Description</label>
                                                                <Input
                                                                    autoComplete='off'
                                                                    id='description'
                                                                    name='description'
                                                                    maxLength={250}
                                                                    onChange={e => this.input_change_handler(e)}
                                                                    value={this.payerrules_search_criteria['description'] ? this.payerrules_search_criteria['description'] : ''}
                                                                    type='text'
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field>
                                                                <label>Payer Code</label>
                                                                <Selection
                                                                    id='payer_id'
                                                                    name='payer_id'
                                                                    defaultValue={this.payerrules_search_criteria.payer_id}
                                                                    options={payer_codes}
                                                                    onChange={(value, e) => {
                                                                        this.onChangeDropdownHandler(e);
                                                                    }}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field>
                                                                <label>Payment Code</label>
                                                                <Selection
                                                                    id='payment_code_id'
                                                                    name='payment_code_id'
                                                                    defaultValue={this.payerrules_search_criteria.payment_code_id}
                                                                    options={payment_codes}
                                                                    onChange={(value, e) => {
                                                                        this.onChangeDropdownHandler(e);
                                                                    }}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field>
                                                                <label>Adjustment Code</label>
                                                                <Selection
                                                                    id='adjustment_code_id'
                                                                    name='adjustment_code_id'
                                                                    defaultValue={this.payerrules_search_criteria.adjustment_code_id}
                                                                    options={adjustment_codes}
                                                                    onChange={(value, e) => {
                                                                        this.onChangeDropdownHandler(e);
                                                                    }}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid>
                                                    <Grid style={{ margin: '0 -17px' }}>
                                                        <Grid.Column computer={16} textAlign='right'>
                                                            <Button id='clear_button_id' type='button' onClick={e => this.clear_handler(e)} basic content="Clear" />
                                                            <Button id='search_button_id' type='submit' basic content="Search" />
                                                        </Grid.Column>
                                                    </Grid>
                                                </Accordion.Content>
                                            </Accordion>
                                        </Grid.Column>
                                    </Grid>
                                    {this.state.show_grid && (
                                        <Grid style={{ marginTop: '1rem', marginBottom: 0 }}>
                                            <Grid.Column tablet={5} computer={4} textAlign='left'>
                                                <p style={this.page_metadata.record_info_style}>{this.report_date}</p>
                                            </Grid.Column>
                                            <Grid.Column tablet={5} computer={8} textAlign='center'>
                                                {
                                                    <p style={this.page_metadata.record_info_style}>
                                                        {`${this.state.grid_rows_count} ${this.state.grid_rows_count == 1 ? ' record shown' : ' records shown'
                                                            }`}
                                                    </p>
                                                }
                                            </Grid.Column>
                                            <Grid.Column tablet={2} computer={4} textAlign='right' />
                                        </Grid>
                                    )}
                                </Form>
                            </div>
                            {this.state.show_grid && !this.state.is_loading && (
                                <GridView
                                    id={payer_rule_summary.grid_id}
                                    row={this.state.payer_rules.rows}
                                    column={this.state.payer_rules.payer_rules_summary_column_def}
                                    headerHeight={this.state.payer_rules.grid_header_height}
                                    style={{ height: '100%' }}
                                    wrapperStyle={{ width: '100%', height: '100%', display: 'flex', flex: 1 }}
                                    wrapperClass={'grid_wrapper accordian_grid'}
                                    enableColResize={true}
                                    emptyMessage={payer_rule_summary.no_records_found_message}
                                    headerIdForTabNavigation={payer_rule_summary.payer_rule_summary_configuration_header_id}
                                    onCellClicked={this.on_cell_Click}
                                    detailsName={payer_rule_summary.detail_field_name}
                                    get_grid_ref={this.get_grid_ref}
                                    suppressSizeToFit={true}
                                    isRowSelectable={this.is_row_selectable}
                                    disableHover={true}
                                    enableExpandAndCollapse={true}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {this.state.isDrawerOpen && <DrawerComponent
                    key={this.props.key_id}
                    canOutsideClickClose={true}
                    canEscapeKeyClose={false}
                    isOpen={this.state.isDrawerOpen}
                    className="ext-bp-drawer provider-drawer billing-provider-drawer"
                    handleDrawerClose={this.handleDrawerClose}
                    position={Position.RIGHT}
                    title=""
                    isCloseButtonShown={true}
                    innerContent={this.getPayerRulesAddComponent()}
                    size={Drawer.SIZE_STANDARD}
                    inbounds={this.state.inbounds}
                    payer_codes={this.state.payer_codes}
                    disableAutoClose={true}
                />}
            </>)

        );
    }
}

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        selected_patient: state.patient_details.patient_header,
        user_login_details: state.user_login_details,
    };
};

export default withRouter(connect(mapStateToProps)(PayerRulesSearchFFComponent));
