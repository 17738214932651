import * as React from 'react';
import { connect } from 'react-redux';
import { toastr as toaster } from 'react-redux-toastr';
import { Button, Form, Grid } from 'semantic-ui-react';
import { get_payer_group_by_company, get_search_data } from '../../../admin/constants/action/constants_action';
import { option_formater as option_formatter, payer } from '../../../admin/constants/constants';
import * as global_constants from '../../../global_constants';
import { toastr_options } from '../../../global_constants';
import ReportHeader from '../../../reports/component/report_header';
import { messages } from '../../../reports/report_constants';
import { log_error } from '../../../shared/action/shared_action';
import { LoaderComponent } from '../../../shared/component/loading_component';
import SelectionComponent from '../../../shared/component/selection_component';
import { BatchClaimsFeatures } from '../../../admin/constants/constants';
import * as local_storage from '../../../shared/local_storage_utility';
import * as session_storage from '../../../shared/session_storage_utility';
import { set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import { get_all_error, get_lauch_darkley_key_value } from '../../../shared/utility';
import { add_batch_billing, batch_billing_lookup } from '../../action/billing_actions';
import { batch_billing_configuration, batch_billing_page_type } from '../../billing_constants';

const DropdownInput = ({ label, id, options, change_handler, defaultValue }) => {
  return (
    <Grid.Column>
      <Form.Field>
        <label dangerouslySetInnerHTML={{ __html: label }} />
        <SelectionComponent
          id={id}
          name={id}
          options={options}
          onChange={change_handler}
          defaultValue={defaultValue}
          style={'dropdown-options-wrap'}
        />
      </Form.Field>
    </Grid.Column>
  );
};

const DropdownInputItalized = ({ labelPrefix, labelSuffixItalized, id, options, change_handler, defaultValue }) => {
    return (
        <Grid.Column>
			<Form.Field>
				<div style={{ display: 'flex' }}>
					<div>
						<label dangerouslySetInnerHTML={{ __html: labelPrefix + ' ' }} />
					</div>
					<div style={{ marginLeft: '.2rem', fontStyle: 'italic'}}>
						<label dangerouslySetInnerHTML={{ __html:labelSuffixItalized }} />
					</div>
				</div>
                <SelectionComponent
                    id={id}
                    name={id}
                    options={options}
                    onChange={change_handler}
                    defaultValue={defaultValue}
                    style={'dropdown-options-wrap'}
                />
            </Form.Field>
        </Grid.Column>
    );
};

enum create_type {
    LIVE = 0,
    TEST = 1,
    SCHEDULE = 2
}

export class BatchBillingCreateClaim extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      controls_is_expended: true,
      is_submitted: false,
      loading: false,
      is_loading_dropdown: true,
      is_save_disabled: false,
      is_clear_button_disabled: false,
	  is_cancel_disabled: false
    };
  }

  batch_billing: any = {};
  options_data: any = {
    ...this.batch_billing.options_data
  };
  form_data: any = {
    ...this.batch_billing.form_data
  };
  token: any = '';
  is_mounted = false;
  software_only_company = false;
  service_type = '0';
  current_user_type: global_constants.enum_user_type_r3_id = 1;
  formats = {
    format_list: [],
    pending_format_list: [],
    format_title_list: [],
    pending_format_title_list: []
  };
  // Fetching the report id and params.
  UNSAFE_componentWillMount = () => {
    this.batch_billing = batch_billing_configuration(this.props);
    this.options_data = {
      ...this.batch_billing.options_data
    };

    this.form_data = {
      ...this.batch_billing.create_claim_search_criteria
    };
    this.token = this.props.user_login_details.user_data.data.token_details.access_token;
    let auth_data = local_storage.get('auth_data');
    if (auth_data && auth_data.user_type_r3_id) {
      this.current_user_type = auth_data.user_type_r3_id;
      this.software_only_company = auth_data.company_service_type == '3';
    }
    this.service_type =
      this.current_user_type == global_constants.enum_user_type_r3_id.Partner ||
      this.current_user_type == global_constants.enum_user_type_r3_id.Customer
        ? '3' : this.service_type;

    if (this.props.BatchClaimsProjectFFVal && this.user_type_is_bms_or_SA()) {
      this.options_data.create_type = this.batch_billing.options_data.create_type_admin
    } else {
      this.options_data.create_type = this.batch_billing.options_data.create_type_standard;
    }
  };

  componentDidMount = () => {
    this.is_mounted = true;
    this.get_dropdowns_data();
    if (!document.body.classList.contains('reports')) {
      document.body.classList.add('reports');
    }
  };

  componentWillUnmount = () => {
    document.body.classList.remove('reports');
    this.is_mounted = false;
    this.form_data = {
      ...this.batch_billing.create_claim_search_criteria
    };
    if (this.props.history.location) {
      const route_array = this.props.history.location.pathname.split('/');
      route_array && route_array[2] !== 'batch_billing' && session_storage.remove('batch_billing_search_criteria');
    }
  };

  get_dropdowns_data = async () => {
    let arrData = [];
    if (this.is_mounted) {
      this.setState({
        re_render: true,
        is_loading_dropdown: true
      });
    }

    if (this.props.rsiB31486AddCompanyMultiSelectDropDownToPayersPage) {
      let params = {
          company_id: this.props.user_login_details.user_data.data.company_id,
        showInactive: false
      }
      arrData.push(get_payer_group_by_company(this.token, params));
    }
    else {
      arrData.push(get_search_data(this.token, payer.api.payer_groups.api));
    }
    arrData.push(batch_billing_lookup(this.token, 0, batch_billing_page_type.add));

    await Promise.all(arrData)
      .then((response) => {
        // Format,Batch Type, Run Status and Date Type Dropdown Data
        let format_data = [];
        let pending_format_data = [];
        let format_title_data = [];
        let pending_format_title_data = [];
        if (response[1].data && response[1].data.data && response[1].data.data.data) {
          let option_data = response[1].data.data.data;
          let schedule_type = (option_data.schedule_type && option_data.schedule_type.result) || [];
          let company_list = (option_data.company_list && option_data.company_list.result) || [];
          format_data = (option_data.format && option_data.format.result) || [];
          pending_format_data = (option_data.format && option_data.pending_format.result) || [];

          format_title_data = option_formatter(format_data, 'name', 'format_tile_id', false, false);
          format_data = option_formatter(format_data, 'name', 'id', false, false);
          pending_format_title_data = option_formatter(pending_format_data, 'name', 'format_tile_id', false, false);
          pending_format_data = option_formatter(pending_format_data, 'name', 'id', false, false);

          this.formats = {
            format_list: format_data,
            pending_format_list: pending_format_data,
            format_title_list: format_title_data,
            pending_format_title_list: pending_format_title_data
          };
          this.options_data = {
            ...this.options_data,
            format_id: format_data,
            schedule_type: option_formatter(schedule_type, 'description', 'id', false, false),
            company_id:
              option_formatter(
                company_list,
                'company_name',
                'company_id',
                false, // to add blank object
                false // to sorted object,
              ) || []
          };
        }
        // Payer Groups Dropdown Data
        if (response[0].data && response[0].data.data) {
          let payer_group_data = [];
          if (
            this.current_user_type == global_constants.enum_user_type_r3_id.Partner ||
            this.current_user_type == global_constants.enum_user_type_r3_id.Customer
          ) {
            payer_group_data = response[0].data.data.filter((item) => {
              if (item.is_software_only) {
                return item;
              }
            });
          } else {
            payer_group_data = response[0].data.data;
          }
          let payer_groups = option_formatter(
            payer_group_data,
            'payer_group_name',
            'payer_group_id',
            false, // to add blank object
            true // to shorted object
          );
          payer_groups.unshift({ text: '', value: 0 });
          this.options_data.payer_groups = payer_groups;
        }
        // Set Default company
        let company_id = '-1';
        const current_company = this.props.user_login_details.user_data.data.company_id;
        this.options_data.company_id.some((item) => item.value === current_company);
        if (this.service_type === '3') {
          company_id = this.options_data.company_id.some((item) => item.value === current_company)
            ? current_company
            : this.options_data.company_id.length > 0
            ? this.options_data.company_id[0].value
            : '-1';
        } else {
          company_id = this.options_data.company_id.length > 0 ? this.options_data.company_id[0].value : '-1';
        }
        // Set default value in dropdown
        this.form_data = {
          ...this.form_data,
          company_id: company_id,
          format_id: this.options_data.format_id.length > 0 ? this.options_data.format_id[0].value : '0',
          payer_group_id: this.options_data.payer_groups.length > 0 ? this.options_data.payer_groups[0].value : '0',
          schedule_type_cd: this.options_data.schedule_type.length > 0 ? this.options_data.schedule_type[0].value : '0'
        };

        this.setState({
          re_render: false,
          is_loading_dropdown: false
        });
      })
      .catch((error) => {
        if (this.is_mounted) {
          this.setState({
            is_show_pop_up: false,
            is_loading_dropdown: false
          });
        }
        if (error.response) {
          error.response.map((item: { error: { response: { data: { messages: any } } } }) => {
            if (item.error.response && item.error.response.data && item.error.response.data.messages) {
              log_error(item.error);
              const toastr_options = this.show_html_content_toaster(get_all_error(item.error.response.data));
              toaster.error('', toastr_options);
            }
            return;
          });
        }
      });
  };

  get_payer_group_on_company_change = async () => {

    if (this.props.rsiB31486AddCompanyMultiSelectDropDownToPayersPage) {
      let arrData = [];
      if (this.is_mounted) {
        this.setState({
          re_render: true,
          is_loading_dropdown: true
        });
      }

        let params = {
          company_id : this.form_data.company_id,
          showInactive: false
        }
        arrData.push(get_payer_group_by_company(this.token, params));

    await Promise.all(arrData)
      .then((response) => {
        // Payer Groups Dropdown Data
        if (response[0].data && response[0].data.data) {
          let payer_group_data = [];
          if (this.current_user_type == global_constants.enum_user_type_r3_id.Partner ||
            this.current_user_type == global_constants.enum_user_type_r3_id.Customer) {
            payer_group_data = response[0].data.data.filter((item) => {
              if (item.is_software_only) {
                return item;
              }
            });
          } else {
			  payer_group_data = response[0].data.data;
          }
          let payer_groups = option_formatter(
            payer_group_data,
            'payer_group_name',
            'payer_group_id',
            false, // to add blank object
            true // to shorted object
          );
          payer_groups.unshift({ text: '', value: 0 });
          this.options_data.payer_groups = payer_groups;
        }


        this.form_data = {
          ...this.form_data,
          payer_group_id: this.options_data.payer_groups.length > 0 ? this.options_data.payer_groups[0].value : '0'
        };      

        this.setState(
          {
            re_render: false,
            is_loading_dropdown: false
          });
      })
      .catch((error) => {
        if (this.is_mounted) {
          this.setState({
            is_show_pop_up: false,
            is_loading_dropdown: false
          });
        }
        if (error.response) {
          error.response.map((item) => {
            if (item.error.response && item.error.response.data && item.error.response.data.messages) {
              log_error(item.error);
              const toastr_options = this.show_html_content_toaster(get_all_error(item.error.response.data));
              toaster.error('', toastr_options);
            }
            return;
          });
        }
      });
    }
  }

  // Dropdown change handler.
  dropdown_change_handler = (event: {
    target: { selectedOptions?: any; options?: any; selectedIndex?: any; id?: any; value?: any };
  }) => {
    const { id, value } = event.target;
    this.form_data[id] = value;

    if (id === 'create_type') {
        this.update_format_options();
    }

    if (this.is_mounted) {
      this.setState({
        form_object: this.form_data
      }, ()=> {
        if(id === 'company_id') {
          this.get_payer_group_on_company_change();
        }
      });
    }
  };

  // Input change handler.
  checkbox_change_handler = (e: { target: { checked: any; name: any } }) => {
    const { checked, name } = e.target;
    this.form_data[name] = checked;

    if (name === 'show_pending') {
      this.update_format_options();
    }

    if (this.is_mounted) {
      this.setState({
        form_object: this.form_data
      });
    }
  };

  update_format_options = () => {
    if (this.form_data.show_pending) {
      if (this.form_data.create_type == create_type.LIVE || this.form_data.create_type == create_type.TEST) {
          this.options_data.format_id = this.formats.pending_format_list;
      } else {
        this.options_data.format_id = this.formats.pending_format_title_list;
      }
    } else {
      if (this.form_data.create_type == create_type.LIVE || this.form_data.create_type == create_type.TEST) {
        this.options_data.format_id = this.formats.format_list;
      } else {
        this.options_data.format_id = this.formats.format_title_list;
      }
    }
    this.form_data.format_id = this.options_data.format_id.length > 0 ? this.options_data.format_id[0].value : '0';
  };
  //Required Field Validator.
  required_field_validator = () => {
    let is_error = false;
    let from_date: any = this.form_data.start;
    let to_date: any = this.form_data.end;
    if (from_date && to_date) {
      is_error = false;
    } else {
      is_error = true;
      toaster.error('', messages.mandatory_fields);
    }
    this.setState({
      is_error_date: is_error
    });
    return is_error;
  };

  // Function used to Run Report based on controllers values.
  run_report_handler = (e: { preventDefault: () => void; stopPropagation: () => void }) => {
    if (this.is_mounted) {
      this.setState({
        is_submitted: true,
        loading: true,
        is_save_disabled: true
      });
    }
    this.add_batch_billing();
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  add_batch_billing = async () => {
    let run_type = 'schedule_run';
    let params = {
      ...this.form_data
    };
    if (this.form_data.create_type == create_type.LIVE || this.form_data.create_type == create_type.TEST) {
      run_type = 'manual_run';
      delete params.schedule_type_cd;
      if (this.form_data.create_type == create_type.TEST) {
        params.is_test = true;
      }
    }
    delete params.create_type;
    delete params.show_pending;

    await add_batch_billing(this.token, run_type, params).then(
      (response: { data: { status: number; messages: { message: any }[] } }) => {
        if (response.data && response.data.status === 1) {
          response.data.messages && toaster.success('', response.data.messages[0].message);
          if (this.is_mounted) {
            this.setState({
              loading: false,
              is_save_disabled: false
            }, () => { this.props.history.goBack(); });
          }
        } else {
          if (response.data.messages) {
            const toastr_options = this.show_html_content_toaster(get_all_error(response));
            toaster.error('', toastr_options);
          }
          if (this.is_mounted) {
            this.setState({
              loading: false,
              is_save_disabled: false
            });
          }
        }
      },
      (error: { response: { data: any } }) => {
        if (this.is_mounted) {
          this.setState({
            loading: false,
            is_save_disabled: false
          });
        }
        if (error.response.data) {
          log_error(error);
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error('', toastr_options);
        }
      }
    );
  };

  cancel_claim = () => {
    this.props.history.goBack();
  };

  // Show multiple messages
  show_html_content_toaster = (msg: any) => {
    return {
      component: () => (
        <div>
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        </div>
      ),
      timeOut: toastr_options.toastr_time_out,
      preventDuplicates: true
    };
  };

  handle_navigation_after_last_element = (event: { shiftKey: any; keyCode: string }) => {
    // apply a check where grid is empty
    if (
      !event.shiftKey &&
      event.keyCode == '9' &&
      (this.state.report_data == null || this.state.report_data.length == 0)
    ) {
      set_focus_to_app_header(event);
    }
	};

	user_type_is_bms_or_SA(): boolean {
		return this.current_user_type == global_constants.enum_user_type_r3_id.BMS ||
		  this.current_user_type == global_constants.enum_user_type_r3_id.Sys_Admin;
	}

  render() {
    return (
      <LoaderComponent loading={this.state.loading || this.state.is_loading_dropdown}>
        <div className={'common-forms-add '}>
          <div className={'common-forms-search report-framework'}>
            <ReportHeader title={'Batch Claims - Schedule New Run'} />
            <div
              className='patient-search-form  patient_search_bottom_padding'
              style={{ paddingTop: 0, borderBottom: '1px solid #cccccc' }}
              id='batch_billing_schedule_new_run'
            >
              <Form autoComplete='off' onSubmit={this.run_report_handler}>
                <Grid>
                  <Grid.Column computer={16} tablet={16} textAlign='left'>
                    <Grid columns={3}>
                      <DropdownInput
                        label='Create Claim File'
                        id='create_type'
                        change_handler={(
                          value: any,
                          event: {
                            target: {
                              selectedOptions?: any;
                              options?: any;
                              selectedIndex?: any;
                              id?: any;
                              value?: any;
                            };
                          }
                        ) => {
                          this.dropdown_change_handler(event);
                        }}
                        defaultValue={this.form_data.create_type}
                        options={this.options_data.create_type}
                      />
                      <DropdownInput
                        label='Company'
                        id='company_id'
                        change_handler={(
                          value: any,
                          event: {
                            target: {
                              selectedOptions?: any;
                              options?: any;
                              selectedIndex?: any;
                              id?: any;
                              value?: any;
                            };
                          }
                        ) => {
                          this.dropdown_change_handler(event);
                        }}
                        defaultValue={this.form_data.company_id}
                        options={this.options_data.company_id}
										/>
						<DropdownInputItalized
							labelPrefix='Payer Group'
							labelSuffixItalized='(Required)'
							id='payer_group_id'
							change_handler={(
								value: any,
								event: {
									target: {
										selectedOptions?: any;
										options?: any;
										selectedIndex?: any;
										id?: any;
										value?: any;
									};
								}
							) => {
								this.dropdown_change_handler(event);
							}}
							defaultValue={this.form_data.payer_group_id}
							options={this.options_data.payer_groups}
						/>
                    </Grid>
                    <Grid columns={3} style={{ marginTop: '-1rem' }}>
                      <DropdownInput
                        label='Format'
                        id='format_id'
                        change_handler={(
                          value: any,
                          event: {
                            target: {
                              selectedOptions?: any;
                              options?: any;
                              selectedIndex?: any;
                              id?: any;
                              value?: any;
                            };
                          }
                        ) => {
                          this.dropdown_change_handler(event);
                        }}
                        defaultValue={this.form_data.format_id}
                        options={this.options_data.format_id}
                      />
						{(!this.props.BatchClaimsProjectFFVal || this.user_type_is_bms_or_SA()) && (
                      <Grid.Column>
                        <Form.Field className='ui checkbox'>
                          <div style={{ marginTop: 32 }}>
                            <input
                              type='checkbox'
                              name={'show_pending'}
                              id={'show_pending'}
                              checked={this.form_data.show_pending}
                              onChange={(e) => this.checkbox_change_handler(e)}
                            />
                            <label className='chkbx_label' htmlFor={'show_pending'}>
                              Show Pending Formats
                            </label>
                          </div>
                        </Form.Field>
                      </Grid.Column>)}
                      <Grid.Column>
                        <Form.Field className='ui checkbox'>
                          <div style={{ marginTop: 32 }}>
                            <input
                              type='checkbox'
                              name={'is_status_update'}
                              id={'is_status_update'}
                              checked={this.form_data.is_status_update}
                              onChange={(e) => this.checkbox_change_handler(e)}
                            />
                            <label className='chkbx_label' htmlFor={'is_status_update'}>
                              Update Status
                            </label>
                          </div>
                        </Form.Field>
                      </Grid.Column>
                    </Grid>
                    {this.form_data.create_type == create_type.SCHEDULE && (
                      <Grid columns={3} style={{ marginTop: '-1rem' }}>
                        <DropdownInput
                          label='Type'
                          id='schedule_type_cd'
                          change_handler={(
                            value: any,
                            event: {
                              target: {
                                selectedOptions?: any;
                                options?: any;
                                selectedIndex?: any;
                                id?: any;
                                value?: any;
                              };
                            }
                          ) => {
                            this.dropdown_change_handler(event);
                          }}
                          defaultValue={this.form_data.schedule_type_cd}
                          options={this.options_data.schedule_type}
                        />
                      </Grid>
                    )}
                  </Grid.Column>
                </Grid>
              </Form>
            </div>
            <div
              className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
              id='applicationFooterSticky'
            >
              <Grid style={{ marginTop: 0, marginBottom: 0 }}>
                <Grid.Column computer={16} tablet={16} textAlign='right'>
                  <Button
                    id='cancel'
                    type='button'
                    onClick={this.cancel_claim}
                    basic
                    disabled={this.state.is_cancel_disabled}
                    content={'Cancel'}
                  />
                  <Button
                    id='create_report_button'
                    className='primary'
                    type='submit'
                    onClick={this.run_report_handler}
                    onKeyDown={this.handle_navigation_after_last_element}
                    style={{ marginRight: '0' }}
                    disabled={this.state.is_save_disabled}
                    content={'Save'}
                  />
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </div>
      </LoaderComponent>
    );
  }
}

// Get user and login details from store.
const mapStateToProps = (state: { user_login_details: any; patient_details: { patient_header: any }, launch_darkly: any }) => {
  return {
    user_login_details: state.user_login_details,
    selected_patient: state.patient_details.patient_header,
    rsiB31486AddCompanyMultiSelectDropDownToPayersPage: get_lauch_darkley_key_value(state.launch_darkly, "rsiB31486AddCompanyMultiSelectDropDownToPayersPage"),
    BatchClaimsProjectFFVal: get_lauch_darkley_key_value(state.launch_darkly, BatchClaimsFeatures.projectFlag)
  };
};

export default connect(mapStateToProps)(BatchBillingCreateClaim);
