import * as React from 'react';
import '../../../../assets/css/tabbed-single-select.css';
import * as _ from 'lodash';

interface ISource {
    label: string,
    noResultsLabel?: string,
    noSearchResultsLabel?: string,
    items: {
        value: string | number,
        label: string
    }[]
}

interface ITabbedSingleSelectState {
    id: string,
    defaultValue?: string | number,
    showModal: boolean,
    itemSelected: { tabId: number, value: string | number, label: string },
    activeTab: number,
    sources: ISource[],
    required: boolean,
    disabled: boolean
}

interface ITabbedSingleSelectProps {
    id: string,
    defaultValue?: string | number,
    sources: ISource[],
    onChange?: (value: any, id?: any) => void,
    required: boolean,
    disabled: boolean
}
export class TabbedSingleSelect extends React.Component<ITabbedSingleSelectProps, ITabbedSingleSelectState> {
    divRef: any;
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.id,
            showModal: false,
            itemSelected: { tabId: null, value: null, label: null },
            activeTab: 0,
            sources: this.props.sources || [],
            defaultValue: this.props.defaultValue,
            required: this.props.required || false,
            disabled: this.props.disabled || false
        }

        this.divRef = React.createRef()
        this.setDefaultValue()
    }

    componentDidUpdate(prevProps: Readonly<ITabbedSingleSelectProps>, prevState: Readonly<ITabbedSingleSelectState>, snapshot?: any): void {
        if (this.props.sources !== prevProps.sources) {
            this.setState({ sources: this.props.sources || [] }, () => {
                this.setDefaultValue()
            })
        }
        if (`${this.props.defaultValue}` !== `${prevProps.defaultValue}`) {
            this.setState({ defaultValue: this.props.defaultValue || null }, () => {
                this.setDefaultValue()
            })
        }
        if (this.props.required !== prevProps.required) {
            this.setState({ required: this.props.required || false })
        }
        if (this.props.disabled !== prevProps.disabled) {
            this.setState({ disabled: this.props.disabled || false })
        }
    }

    setDefaultValue = () => {
        if (this.state.defaultValue != null) {
            let item = this.state.sources.map((source: any, index: any) => {
                return source.items.filter((item: any) => item.value === this.state.defaultValue).map((item: any) => ({ ...item, tabId: index }))
            }).filter((item: any) => item.length > 0)
            if (item.length > 0) {
                this.setState({ itemSelected: { ...item[0][0], tabId: item[0][0].tabId }, activeTab: item[0][0].tabId })
                this.props.onChange && this.props.onChange({ ...item[0][0], tabId: item[0][0].tabId }, this.state.id)
            }
        } else {
            this.setState({ itemSelected: { value: null, label: null, tabId: null } })
            this.props.onChange && this.props.onChange({ value: null, label: null, tabId: null }, this.state.id)
        }
    }

    handleClickOutside = (event: any) => {
        if (this.divRef.current && !this.divRef.current.contains(event.target)) {
            this.setState({ showModal: false })
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside)
    }

    handleChange = (e: any) => {
        let value = e.target.value;
        this.setState({ itemSelected: { value: null, label: value, tabId: null } })
        this.props.onChange && this.props.onChange({ value: null, label: value, tabId: null }, this.state.id)
    }

    handleSelect = (tabId: number, item: any) => {
        this.setState({ itemSelected: { ...item, tabId }, showModal: false })
        this.props.onChange && this.props.onChange({ ...item, tabId }, this.state.id)
    }

    handleDelete = () => {
        this.setState({ itemSelected: { value: null, label: null, tabId: null } })
        this.props.onChange && this.props.onChange({ value: null, label: null, tabId: null }, this.state.id)
    }

    activateTab = ({ target }) => {
        const {
            dataset: { id }
        } = target;

        this.setState({ activeTab: id })
    }

    renderItems = (items: any) => {
        let itemsFiltered = items.filter((item: any) => !this.state.itemSelected.label || this.state.itemSelected.label == '' || !!this.state.itemSelected.value || (item.label || '').toLowerCase().includes((this.state.itemSelected.label || '').toLowerCase()))
        if (itemsFiltered.length == 0) {
            return (<div className='tabbed-dropdown-item'>{this.state.sources[this.state.activeTab].noSearchResultsLabel || 'Search has no results'}</div>)
        } else {
            return itemsFiltered.map((item, index) => {
                return <div key={index} className={this.state.activeTab == this.state.itemSelected.tabId && item.value === this.state.itemSelected.value ? 'tabbed-dropdown-item-active' : 'tabbed-dropdown-item'} onClick={() => { this.handleSelect(this.state.activeTab, item) }}>{item.label}</div>
            })
        }
    }

    render() {
        return (
            <div>
                <div id={this.state.id} ref={this.divRef} className={`ui dropdown tabbed-dropdown ${this.state.required && 'tabbed-dropdown-required'}`} style={{ padding: '0px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', flex: '1' }}>
                        <input type="text" placeholder="Search..." disabled={this.state.disabled} value={this.state.itemSelected.label || ''} style={{ padding: '5px', marginLeft: '5px' }}
                            onChange={this.handleChange} className={`tabbed-dropdown-input ${this.state.required && 'tabbed-dropdown-input-required'}`} onFocus={() => { this.setState({ showModal: true }) }} />
                        {this.state.itemSelected.value ?
                            <div className={`${this.state.disabled ? 'tabbed-dropdown-icon-disabled' : 'tabbed-dropdown-icon'} ${this.state.required ? 'tabbed-dropdown-icon-required' : ''}`} onClick={() => { !this.state.disabled && this.handleDelete() }}>
                                <i aria-hidden="true" className="close small icon"></i>
                            </div>
                            :
                            <div className={`${this.state.disabled ? 'tabbed-dropdown-icon-disabled' : 'tabbed-dropdown-icon'} ${this.state.required ? 'tabbed-dropdown-icon-required' : ''}`} onClick={() => { !this.state.disabled && this.setState((prevState) => ({ showModal: !prevState.showModal })) }}>
                                <i aria-hidden="true" className="chevron down small icon"></i>
                            </div>
                        }
                        <div className={`menu transition ${this.state.showModal ? 'visible' : ''}`} style={{ width: '100%', cursor: 'pointer' }}>
                            <div style={{ width: `${this.divRef && this.divRef.current && this.divRef.current.clientWidth ? `${this.divRef && this.divRef.current && this.divRef.current.clientWidth}px` : 'auto'}` }}>
                                <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '100%' }}>
                                    {this.state.sources.length > 0 &&
                                        <>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                {this.state.sources.map((source, index) => {
                                                    return (<div key={index} className={index == this.state.activeTab ? 'tabbed-dropdown-tab-active' : 'tabbed-dropdown-tab'}
                                                        onClick={() => { this.setState({ activeTab: index }) }}>{source.label}</div>)
                                                })}
                                            </div>
                                            <div style={{ maxHeight: '204px', overflow: 'auto' }}>
                                                {this.state.sources[this.state.activeTab].items.length == 0 ?
                                                    (<div className='tabbed-dropdown-item'>{this.state.sources[this.state.activeTab].noResultsLabel || 'Tab has no results'}</div>)
                                                    :
                                                    this.renderItems(this.state.sources[this.state.activeTab].items)
                                                }
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TabbedSingleSelect