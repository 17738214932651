import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr as toaster } from 'react-redux-toastr';
import { Button, Dimmer, Form, Grid, Loader } from 'semantic-ui-react';
import * as moment from 'moment';
import Selection from '../../shared/component/selection_component';
import { get_dropdown_default_year, prepare_month_options, prepare_year_options } from '../billing_constants';
import { get_previous_month } from '../billing_constants';
import { get_updated_dropdown_data } from '../../../app/reports/action/report_action';
import { requestPatientStatements } from '../billing_constants';
import GridView from '../../shared/component/grid';
import { getRequestPatientStatementProcess, downloadRequestPatientStatementProcessFile, resetPatientStatementsProcess } from '../action/patient_statement_action';
import { AlertConfirm } from '../../shared/component/alert_confirm_component';
import { downloadTxtFromString } from '../../reports/util/export_print_utility';
import { log_error } from "../../shared/action/shared_action";
import { handleHeaderSelectAllLimit, evaluateSelectAllReqStmt } from "../../billing/utility";

export class RequestPatientStatementsComponent extends React.Component<any, any> {

    token: any;
    defaultMonth = get_previous_month();
    defaultYear = get_dropdown_default_year();
    defaultCompanyId = this.props.userLoginDetails.user_data.data ? this.props.userLoginDetails.user_data.data.company_id : "";
    defaultStatus = requestPatientStatements.defaultValues.status;
    statusOptions = requestPatientStatements.statusOptions;
    monthOptions = prepare_month_options();
    yearOptions = prepare_year_options(requestPatientStatements.defaultValues.initialYear, moment().format('YYYY'));
    refreshParam: any = {};
    grid_data: any = {};
    gridParams: any;

    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            month: this.defaultMonth,
            year: this.defaultYear,
            companyId: this.defaultCompanyId,
            status: this.defaultStatus,
            companyOptions: [],
            grid_rows_count: 0,
            requestPatientStatementsRows: [],
            selectedRows: [],
            showGrid: false
        };
        this.getGridRef = this.getGridRef.bind(this);
        this.searchHandler = this.searchHandler.bind(this);
        this.clearHandler = this.clearHandler.bind(this);
        this.resetRequestPatientStatementAction = this.resetRequestPatientStatementAction.bind(this);
        this.showConfirmationAlert = this.showConfirmationAlert.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.downloadRequestPatientStatement = this.downloadRequestPatientStatement.bind(this);
        this.onPaginationChanged = this.onPaginationChanged.bind(this);
        this.getDropdownCompanyData = this.getDropdownCompanyData.bind(this);
        this.refreshPatientStatementProcess = this.refreshPatientStatementProcess.bind(this);
        this.getRequestPatientStatementProcessData = this.getRequestPatientStatementProcessData.bind(this);
        this.grid_data = {
            grid_conf: requestPatientStatements.column_defs(this)
        };
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    componentDidMount = async () => {
        this.token = this.props.userLoginDetails.user_data.data.token_details.access_token;
        await this.getDropdownCompanyData();
        this.setState({
            loading: false
        });
    }

    async getDropdownCompanyData() {
        let response = await get_updated_dropdown_data([], this.token);
        const Company = response.data.data[requestPatientStatements.getCompanies];
        let companyOptions: any = Company.filter((res) => res.value !== '-1');
        companyOptions.unshift({ value: -1, text: '--ALL COMPANIES--' });
        this.setState({
            companyOptions
        });
    }

    handleChange(key: string, value: any) {
        this.setState({
            [key]: value
        });
    }

    clearHandler() {
        this.setState({
            month: this.defaultMonth,
            year: this.defaultYear,
            companyId: this.defaultCompanyId,
            status: this.defaultStatus,
            showGrid: false,
            selectedRows: [],
        });
    }

    async searchHandler() {
        const { companyId, month, status, year } = this.state;
        this.refreshParam = { CompanyId: companyId, Month: month, Year: year, Status: status }
        await this.getRequestPatientStatementProcessData();
    }

    async refreshPatientStatementProcess(refreshParam: { companyId: string, status: string, month: string, year: string }) {
        let { companyId, status, month, year } = refreshParam;
        this.setState({
            month,
            year,
            companyId,
            status
        });
        this.refreshParam = {
            Month: month,
            Year: year,
            CompanyId: companyId,
            Status: status
        };
        this.getRequestPatientStatementProcessData();
    }

    async getRequestPatientStatementProcessData() {
        this.setState({
            loading: true,
            showGrid: false
        });
        if (!this.refreshParam.companyId) {
            const { companyId, month, status, year } = this.state;
            this.refreshParam = { CompanyId: companyId, Month: month, Year: year, Status: status }
        }
        let { data: { data: requestPatientStatementsRows } } = await getRequestPatientStatementProcess(this.refreshParam, this.token);
        if (!requestPatientStatementsRows.length) {
            toaster.error('', requestPatientStatements.errorMessages.notFound);
        }
        this.setState({
            requestPatientStatementsRows,
            loading: false,
            showGrid: true,
            selectedRows: [],
        });
    }

    getGridRef(grid_params: any) {
        this.gridParams = grid_params;
        handleHeaderSelectAllLimit(grid_params, "selectRequestProcessId", 'select', this);
    }

    resetRequestPatientStatementAction() {
        this.showConfirmationAlert()
    }

    async resetRequestPatientStatement() {
        if (this.state.selectedRows && this.state.selectedRows.length <= 0) {
            toaster.error("", requestPatientStatements.errorMessages.notFound);
            return;
        }
        if (this.state.selectedRows.filter(row => !row.testFile).length <= 0) {
            toaster.error("", requestPatientStatements.errorMessages.errorInvalidReset);
            return;
        }
        this.setState({
            loading: true
        });

        try {
            let successCount: number = 0, failCount: number = 0;
            let requestIds = this.state.selectedRows.filter(row => !row.testFile).map((row: any) => { return row.requestProcessId }).join(',');
            let { data: { data: resetPatientStatementProcessRows } } = await resetPatientStatementsProcess({ requestProcessIds: requestIds }, this.token);
            if (!resetPatientStatementProcessRows.length) {
                toaster.error('', requestPatientStatements.errorMessages.notFound);
            }
            let resetStatusId = parseInt(requestPatientStatements.statusOptions.find(item => item.text == 'Reset').value);
            successCount = resetPatientStatementProcessRows.filter(item => item.status === resetStatusId).length;
            failCount = resetPatientStatementProcessRows.filter(item => item.status !== resetStatusId).length;
            this.setState({
                loading: false
            });
            if (failCount > 0)
                toaster.error('', requestPatientStatements.resetMessages.messageFail(failCount));
            if (successCount > 0)
                toaster.success('', requestPatientStatements.resetMessages.messageSuccess(successCount));
            await this.refreshPatientStatementProcess({ companyId: this.refreshParam.CompanyId, status: `${resetStatusId}`, month: this.refreshParam.Month, year: this.refreshParam.Year });
        } catch (error) {
            this.setState({
                loading: false
            });
            log_error(error);
            toaster.error("", requestPatientStatements.errorMessages.errorReset);
        }
    }

    async downloadRequestPatientStatement() {
        let apis = [];
        let apiParams = [];
        if (this.state.selectedRows && this.state.selectedRows.length <= 0) {
            toaster.error("", requestPatientStatements.errorMessages.notFound);
            return;
        }
        this.setState({
            loading: true
        });
        this.state.selectedRows.map((row: any) => {
            let params: any = {
                fileName: row.fileName,
                requestProcessId: row.requestProcessId
            };
            apiParams.push(params);
            apis.push(downloadRequestPatientStatementProcessFile(params, this.token));
        });
        try {
            const result = await Promise.all(apis);
            let successCount: number = 0, failCount: number = 0;
            result.map(({ data: { data } }, index) => {
                if (data !== '') {
                    successCount++;
                    let fileName = apiParams[index].fileName;
                    downloadTxtFromString(data, fileName.trim());
                } else {
                    failCount++;
                }
            });
            this.setState({
                loading: false
            });
            if (failCount > 0)
                toaster.error('', requestPatientStatements.downloadMessages.messageFail(failCount));
            if (successCount > 0)
                toaster.success('', requestPatientStatements.downloadMessages.messageSuccess(successCount));
        } catch (error) {
            this.setState({
                loading: false
            });
            log_error(error);
            toaster.error("", requestPatientStatements.errorMessages.errorDownload);
        }
    }

    handleConfirm(e: any) {
        e.preventDefault();
        this.setState(
            {
                openConfirmAlert: false,
                confirmMessage: ''
            },
            () => {
                this.resetRequestPatientStatement();
            }
        );
    }

    showConfirmationAlert() {
        let testFiles = null;
        let testFilesSelected = this.state.selectedRows.filter(row => row.testFile);
        if (testFilesSelected.length > 0) {
            testFiles = `* (${testFilesSelected.length}) Test file(s) selected won\'t be reset.`;
        }
        this.setState({
            openConfirmAlert: true,
            confirmMessage: requestPatientStatements.confirmationMessages.reset,
            highlightMessage: testFiles
        });
    }

    onPaginationChanged(api: any) {
        evaluateSelectAllReqStmt({ api }, this);
    }

    render() {
        const calculatedHeight = this.state.requestPatientStatementsRows.length * 28 + 75;
        const gridHeight = calculatedHeight <= 300 ? calculatedHeight : 300;
        return (
            <>
                <Dimmer active={this.state.loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <AlertConfirm
                    open={this.state.openConfirmAlert}
                    close={() => this.setState({ openConfirmAlert: false })}
                    cancel={() => this.setState({ openConfirmAlert: false })}
                    confirm={this.handleConfirm}
                    message={this.state.confirmMessage}
                    highlightMessage={this.state.highlightMessage}
                />
                <div>
                    <Grid>
                        <Grid.Column computer={16}>
                            <h4 className='ui header patient-statement-card-header'>Request Patient Statements</h4>
                        </Grid.Column>
                    </Grid>
                    <Form autoComplete='off' name='formRequestPstmt' onSubmit={this.searchHandler}>
                        <Grid>
                            <Grid.Column tablet={8} computer={4}>
                                <Form.Field >
                                    <label>Company</label>
                                    <Selection
                                        name='selectCompanyRequestPstmt'
                                        id='selectCompanyRequestPstmt'
                                        options={this.state.companyOptions}
                                        onChange={(value) => this.handleChange('companyId', value)}
                                        defaultValue={this.state.companyId}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column tablet={8} computer={4}>
                                <Form.Field >
                                    <label>Status</label>
                                    <Selection
                                        name='selectStatusRequestPstmt'
                                        id="selectStatusRequestPstmt"
                                        options={this.statusOptions}
                                        onChange={(value) => this.handleChange('status', value)}
                                        defaultValue={this.state.status}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column tablet={8} computer={4}>
                                <Form.Field>
                                    <label>Select Month</label>
                                    <Selection
                                        id={'selectMonthRequestPstmt'}
                                        name={'selectMonthRequestPstmt'}
                                        options={this.monthOptions}
                                        onChange={(value) => this.handleChange('month', value)}
                                        defaultValue={this.state.month}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column tablet={8} computer={4}>
                                <Form.Field >
                                    <label>Select Year</label>
                                    <Selection
                                        name='selectYearRequestPstmt'
                                        id='selectYearRequestPstmt'
                                        options={this.yearOptions}
                                        onChange={(value) => this.handleChange('year', value)}
                                        defaultValue={this.state.year}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid>
                        <Grid>
                            <Grid.Column computer={16} textAlign='right'>
                                <Button
                                    id='buttonClearRequestPstmt'
                                    type='button'
                                    onClick={this.clearHandler}
                                    basic
                                    content='Clear'
                                />
                                <Button
                                    id='buttonSearchRequestPstmt'
                                    type='submit'
                                    primary
                                    content='Search'
                                />
                            </Grid.Column>
                        </Grid>
                    </Form>
                    {
                        this.state.showGrid &&
                        <>
                            <GridView
                                id={'viewRequestPatientStatement'}
                                row={this.state.requestPatientStatementsRows}
                                column={this.grid_data.grid_conf}
                                headerHeight={0}
                                gridAutoHeight={true}
                                enableColResize={true}
                                suppressSizeToFit={true}
                                emptyMessage={'No Record Available'}
                                paginationPageSize={10}
                                isPagination={true}
                                get_grid_ref={this.getGridRef}
                                headerIdForTabNavigation={"RequestPatientStatement"}
                                rowGroupPanelShow={true}
                                onPaginationChangeEvent={this.onPaginationChanged}
                            />
                            <Grid style={{ marginTop: '0' }}>
                                <Grid.Column
                                    tablet={16}
                                    computer={16}
                                    style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}
                                >
                                    <Button
                                        style={{ marginRight: "0" }}
                                        id="buttonResetRequestPstmt"
                                        onClick={this.resetRequestPatientStatementAction}
                                        disabled={this.state.selectedRows.length == 0 || this.state.selectedRows.filter(row => !row.testFile).length == 0}
                                        type='button'
                                        content={"Reset"}
                                        primary
                                    />
                                    <Button
                                        style={{ marginRight: "0" }}
                                        id="buttonDownloadRequestPstmt"
                                        onClick={this.downloadRequestPatientStatement}
                                        disabled={this.state.selectedRows.length == 0}
                                        type='button'
                                        content={"Download"}
                                        primary
                                    />
                                </Grid.Column>
                            </Grid>
                        </>
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        userLoginDetails: state.user_login_details,
        userCompaniesResponse: state.user_details.user_companies_response,
    }
}

export default connect(mapStateToProps)(RequestPatientStatementsComponent);