import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LoaderComponent } from '../../../shared/component/loading_component'
import { delete_account_notes_constants } from '../../dashboard/dashboard_constants';
import ReportHeader from '../../../reports/component/report_header';
import { bindActionCreators } from 'redux';
import { Accordion, Button, Form, Grid, Icon, Input, Dimmer, Loader, Header, Label } from 'semantic-ui-react';
import { handle_click_on_enter, set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import * as session_storage from '../../../shared/session_storage_utility';
import { get_patient_account_notes, inactivate_patient_account_notes } from '../action/delete_account_notes_actions';
import GridView from '../../../shared/component/grid';
import { isNullOrUndefined } from 'util';
import { custom_date_format, get_all_error, get_columns, show_html_content_toaster } from '../../../shared/utility';
import { log_error } from '../../../shared/action/shared_action';
import { toastr as toaster } from "react-redux-toastr";
import * as global_constants from '../../../global_constants';
import { messages } from '../../../reports/report_constants';
import { handleHeaderDeleteAccountNoteSelectAll, evaluateSelectAllDeleteAccountNote } from "../../constants/utility";
import ConfirmationComponent from '../../../shared/component/confirmation_component';

export class DeleteAccountNotesComponent extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            controls_is_expanded: true,
            show_grid: false,
            is_search_disabled: false,
            is_error: false,
            selectedRows: [],
            grid_rows_count: 0,
            search_criteria: {
                patient_id: "",
            }
        }
    }

    is_mounted = false;
    page_metadata: any = delete_account_notes_constants(this);
    token: string = '';
    grid_data: any = {
        rows: []
    };
    patient_account_note_column_defs: any[] = [];
    grid_header_height: number = 0;
    total_column_width: number = 0;
    current_date: string = "";
    grid_width: number = 0;
    grid_header_patient_info: any = {
        company_id: "",
        company_code: "",
        company_name: "",
        patient_id: "",
        patient_full_name: ""
    };
    inputPatientId: any = null;

    componentDidMount() {
        this.is_mounted = true;
        document.body.classList.add('admin-framework');
        let stored_search_criteria = session_storage.get(this.page_metadata.session_storage_key);

        if (stored_search_criteria && stored_search_criteria.search_criteria) {
            let updatedCriteria = { ...stored_search_criteria.search_criteria };

            this.setState({ search_criteria: updatedCriteria }, () => {
                this.searchAccountNote();
            });
        }
    };

    componentWillUnmount = () => {
        this.is_mounted = false;
        document.body.classList.remove('admin-framework');

        if (this.props.history.location) {
            let path = this.props.history.location.pathname,
                path_contains_this_page = path ? path.includes('delete_account_notes') : false;
            
            if(path_contains_this_page !== false) {
                session_storage.remove(this.page_metadata.session_storage_key);
            }
        }
    };

    controls_toggle = () => {
        if (this.is_mounted) {
            this.setState({
                controls_is_expanded: !this.state.controls_is_expanded
            });
        }
    };

    handle_change = (e) => {
        const { name, value } = e.target;
        
        let newValue = (e.target.validity.valid)
        ? Number(value) <= this.page_metadata.max_number_value
            ? value
            : this.max_value_validation(value)
        : this.state.search_criteria.patient_id;
        
        if (this.is_mounted) {
            this.setState(prevState => ({
                search_criteria: { ...prevState.search_criteria, [name]: newValue }
            }));
        }
    };

    max_value_validation = (input) => {
        if (Number(input) > this.page_metadata.max_number_value) {
            return this.max_value_validation(input.substring(0, input.length - 1));
        } else {
            return Number(input);
        }
    };

    clear_handler = () => {
        if (this.is_mounted) {
            this.setState({
                search_criteria : {
                    patient_id: ""
                },
                show_grid: false,
                is_search_disabled: false,
                is_error: false,
                grid_rows_count: 0,
                selectedRows: []
            });

            this.grid_data = {
                rows: []
            }
        }
        session_storage.remove(this.page_metadata.session_storage_key);
    };

    handle_navigation_after_last_element = (event) => {
        if (
            !event.shiftKey &&
            event.keyCode == "9" &&
            !this.state.show_grid
        ) {
            set_focus_to_app_header(event);
        }
    };

    searchAccountNote = async () => {
        if (this.is_mounted) {
            this.setState({
                loading: true,
                show_grid: false,
                is_search_disabled: true,
                is_error: false
            });
        }

        this.grid_data.rows = [];
        this.patient_account_note_column_defs = this.page_metadata.delete_account_note_column_defs;
        this.current_date = custom_date_format(new Date(), global_constants.date_format["mm/dd/yyyy h:MM:ss TT"]);
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        let criteria = this.state.search_criteria;

        if (criteria.patient_id === undefined || criteria.patient_id === null || criteria.patient_id.length === 0) {
            if (this.is_mounted) {
                this.setState({
                    loading: false,
                    is_search_disabled: false,
                    is_error: true
                });
            }
            return;
        }

        try {
            let patient_id = criteria.patient_id;
            let response = await get_patient_account_notes(this.token, patient_id);
            if (response && response.data) {
                if (response.data.data) {
                    let patient_account_notes = response.data.data.patientNotes;
                    //Patient info
                    this.grid_header_patient_info.company_id = response.data.data.companyId;
                    this.grid_header_patient_info.company_code = response.data.data.companyCode;
                    this.grid_header_patient_info.company_name = response.data.data.companyName;
                    this.grid_header_patient_info.patient_id = response.data.data.patientId;
                    this.grid_header_patient_info.patient_full_name = response.data.data.patientFullname;

                    patient_account_notes = this.row_data_formatter(patient_account_notes, this.patient_account_note_column_defs);
                    this.grid_data.rows = patient_account_notes || [];
                    this.total_column_width = 0;
                    const grid_height = get_columns(this.grid_data.rows, this.patient_account_note_column_defs);
                    this.grid_header_height = grid_height.header_height;
                    this.total_column_width = grid_height.total_column_width;

                    session_storage.set(this.page_metadata.session_storage_key, { search_criteria: criteria });

                    if (this.is_mounted) {
                        this.setState({
                            show_grid: true,
                            controls_is_expanded: false,
                            grid_rows_count: this.grid_data.rows && this.grid_data.rows.length,
                            selectedRows: [],
                            searchCriteriaUsed: criteria
                        });
                    }
                } else {
                    if (this.inputPatientId) {
                        this.inputPatientId.select();
                    }
                    log_error(response.data.messages[0].message);
                    toaster.error("", response.data.messages[0].message);
                }

                if (this.is_mounted) {
                    this.setState({
                        loading: false,
                        is_search_disabled: false,
                    });
                }
            }
        } catch (error) {
            if (this.is_mounted) {
                this.setState({
                    loading: false,
                    is_search_disabled: false,
                    is_error: false
                });
            }
            log_error(error);
            if (error.response.data) {
                const toastr_options = show_html_content_toaster(get_all_error(error.response.data));
                toaster.error("", toastr_options);
            }
        }
    }

    row_data_formatter = (row_data, column_def) => {
        row_data.map((row) => {
            column_def.map((value, index) => {
                switch (value.type) {
                    case "boolean":
                        if (value.field == "isActive") {
                            row[value.field] = row[value.field] == true ? "Active" : "Inactive";
                        }
                        break;
                    default:
                        row[value.field] = !isNullOrUndefined(row[value.field]) ? row[value.field] : "";
                }
            });

            return row;
        });
        return row_data;
    };

    get_grid_ref = (grid_params: {
        api: { getModel: () => { (): any; new(): any; getRowCount: { (): any; new(): any } } };
        columnApi: { setColumnWidth: (arg0: any, arg1: any, arg2: boolean) => void };
    }) => {
        this.setState({
            grid_params,
            grid_rows_count: grid_params.api.getModel().getRowCount()
        });

        let grid_width = document.getElementsByClassName('admin-wrapper')[0].clientWidth;

        if (this.total_column_width > grid_width) {
            this.patient_account_note_column_defs.filter((item: { [x: string]: any; field: any }) => {
                grid_params.columnApi.setColumnWidth(item.field, item['width'], false);
            });
        }

        handleHeaderDeleteAccountNoteSelectAll(grid_params, "checkAllId", 'is_checked', this);
    };

    onPaginationChanged = (api) => {
        evaluateSelectAllDeleteAccountNote({ api }, this);
    };

    onInactivate = () => {
        if (this.state.selectedRows.length === 0) {
            toaster.error("", this.page_metadata.validationMessage);
            return;
        }
        this.onShowConfirmModal(true);
    }

    inactivateRecords = async () => {
        let selectedRowsData = this.state.selectedRows.map((row: any) => {
            return row.noteId
        });

        this.setState({
            showConfirmModal: false,
            loading: true
        });

        try {
            let response = await inactivate_patient_account_notes(this.token, this.state.searchCriteriaUsed.patient_id, selectedRowsData);
            if (response && response.data) {
                if (response.data.data) {
                    toaster.success("", this.page_metadata.inactivateSuccessMessage);
                    this.searchAccountNote();
                } else {
                    toaster.error("", response.data.messages[0].message);
                    log_error(response.data.messages[0].message);
                }
            }
        } catch (error) {
            console.error(error)
            this.setState({
                loading: false
            });
        }
    }

    onShowConfirmModal = (showConfirmModal: boolean) => {
        this.setState({
            showConfirmModal
        });
    }

    render() {
        const { loading, controls_is_expanded, search_criteria, is_search_disabled, show_grid, is_error } = this.state;

        return (
            <>
                <Dimmer active={loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <div className="admin-wrapper claim_adjustment_code_id" style={{ paddingBottom: 20 }}>
                    <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                        <Grid.Column computer={16}>
                            <h3 className='ui header left aligned' dangerouslySetInnerHTML={{ __html: this.page_metadata.title }} />
                        </Grid.Column>
                    </Grid>
                    <div id='admin-scrollable-area' className='wrapper' style={show_grid ? { flex: 1 } : {}}>
                                <div className='patient-search-form patient_search_bottom_padding common-forms-search' id='search-criteria-container'>
                                    <Form autoComplete='off' >
                                        <Grid>
                                            <Grid.Column
                                                computer={16}
                                                tablet={16}
                                                textAlign='left'
                                                className='accordionColumn'
                                                id='accordion-column'
                                            >
                                                <Accordion fluid styled className='common-accordion'>
                                                    <Accordion.Title
                                                        active={controls_is_expanded}
                                                        index={0}
                                                        onClick={this.controls_toggle}
                                                    >
                                                <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name={controls_is_expanded ? 'angle up' : 'angle down'}  />
                                                        Search Criteria
                                                    </Accordion.Title>
                                                    <Accordion.Content active={controls_is_expanded}>
                                                        <Grid>
                                                            <Grid.Column tablet={8} computer={4}>
                                                                <Form.Field >
                                                                    <label>Patient ID <span className={is_error ? "req-alert" : 'req-alert_normal'}>(required)</span></label>
                                                                    <Input
                                                                        id='patient_id'
                                                                        name='patient_id'
                                                                        pattern='[0-9]*'
                                                                        value={search_criteria.patient_id || ""}
                                                                        onChange={(e) => this.handle_change(e)}
                                                                        type='text'
                                                                        className={is_error ? 'req-border-inp': "" }
                                                                        ref={input => this.inputPatientId = input}
                                                                    />
                                                                </Form.Field>
                                                            </Grid.Column>
                                                        </Grid>
                                                        <Grid>
                                                            <Grid.Column tablet={16} computer={16} textAlign='right'>
                                                                <Button
                                                                    type='reset'
                                                                    id='clear_button'
                                                                    basic
                                                                    onClick={this.clear_handler}
                                                                    content={'Clear'}
                                                                />
                                                                <Button
                                                                    id='search_error'
                                                                    className='primary'
                                                                    type='submit'
                                                                    onKeyDown={this.handle_navigation_after_last_element}
                                                                    style={{ marginRight: '0' }}
                                                                    disabled={is_search_disabled}
                                                                    content={'Search'}
                                                                    onClick={this.searchAccountNote}
                                                                />
                                                            </Grid.Column>
                                                        </Grid>
                                                    </Accordion.Content>
                                                </Accordion>
                                            </Grid.Column>
                                        </Grid>
                                    </Form>
                                </div>
                                    {show_grid && (
                                        <React.Fragment>
                                                <div className="container-info">
                                                    <div className="container-info-item">
                                                        <span className="container-info-bold-text">{this.grid_header_patient_info.patient_full_name} ({this.grid_header_patient_info.patient_id})</span>
                                                    </div>
                                                    <div className="container-info-item">
                                                        <div className="subcontainer-detail-info">
                                                            <div className="subcontainer-detail-info-item"><span className="container-info-bold-text">Company ID:</span> {this.grid_header_patient_info.company_id}</div>
                                                            <div className="subcontainer-detail-info-item"><span className="container-info-bold-text">Company Code:</span> {this.grid_header_patient_info.company_code}</div>
                                                            <div className="subcontainer-detail-info-item"><span className="container-info-bold-text">Company Name:</span> {this.grid_header_patient_info.company_name}</div>
                                                        </div>
                                                    </div>
                                    </div>
                                    <div className={"dashboard_grid_custom_dn"} >
                                                    <GridView
                                                        id={this.page_metadata.report_grid_id}
                                                        row={this.grid_data.rows}
                                                        column={this.patient_account_note_column_defs}
                                                        headerHeight={this.grid_header_height}
                                                        suppressSizeToFit={false}
                                                        isPagination={true}
                                                        paginationMessage={this.current_date}
                                                        suppressMovableColumns={false}
                                                        emptyMessage={messages.no_records_found}
                                                        get_grid_ref={this.get_grid_ref}
                                                        totalInBetween={true}
                                                        selectionType={'single'}
                                                        paginationPageSize={this.page_metadata.default_page_size}
                                                        headerIdForTabNavigation={this.page_metadata.report_grid_id}
                                                        enableColResize={true}
                                                        style={{ height: '100%'  }}
                                                        wrapperStyle={{ width: '100%', height: '100%', display: 'flex' }}
                                                        onPaginationChangeEvent={this.onPaginationChanged}
                                                    />
                                                </div>
                                        </React.Fragment>
                                    )}
                    </div>
                    {show_grid && (
                        <div
                            className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                            id='applicationFooterSticky'
                            style={{ padding: 0 }}
                        >
                            <Button name='saveButton' type='button' primary onClick={this.onInactivate}>
                                Inactivate
                            </Button>
                        </div>
                    )}
                </div>
                {this.state.showConfirmModal &&
                    <ConfirmationComponent message={this.page_metadata.confirmInactivateMessage}
                        title='Confirm' show_modal={this.state.showConfirmModal}
                        onCloseModal={()=>{this.onShowConfirmModal(false)}}
                        save_button_text='Ok'
                        on_force_save_click={(e) => { this.inactivateRecords() }}
                        custom_id={"cancel_job"}
                    />
                }
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
        },
        dispatch
    );
};

const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteAccountNotesComponent));
