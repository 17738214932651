import * as React from 'react';
import { connect } from 'react-redux';
import { toastr as toaster } from 'react-redux-toastr';
import MaskedInput from 'react-text-mask';
import TextareaAutosize from 'react-textarea-autosize';
import { Button, Dimmer, Form, Grid, Input, Loader, Modal } from 'semantic-ui-react';
import AdvancedControl from '../../../shared/component/advanced_control';
import AutoSearchComponent from '../../../shared/component/auto_search_component';
import CurrencyFormatter from '../../../shared/component/currency_formatter';
import DatePicker from '../../../shared/component/date_picker_component';
import PromptNavigation from '../../../shared/component/prompt_navigation_component';
import SelectionComponent from '../../../shared/component/selection_component';
import * as session_storage from '../../../shared/session_storage_utility';
import { set_focus_to_app_header, set_focus_on_element_with_id, handle_focus_on_tab } from '../../../shared/tab_navigation_utility';
import { check_valid_npi, custom_date_format, date_format_with_no_time, unmask, unformat_zip_code, zip_code_formation, is_null_or_white_space, date_format_with_no_time_return_string_or_null } from '../../../shared/utility';
import * as constant_action from '../action/constants_action';
import * as constants from '../constants';
import * as global_constants from '../../../global_constants';
import { log_error } from '../../../shared/action/shared_action';
import * as local_storage from '../../../shared/local_storage_utility';
import * as moment from 'moment';

class ConstantEditComponent extends React.Component<any, any> {
  _is_mounted = false;
  constructor(props) {
    super(props);
    // Define initial state value.
      this.state = {
          patient_master_data: {},
          user_type_r3: null,
          show_render: true,
          form_object: {},
          is_loading: false,
          is_submitted: false,
          is_error_control: {},
          is_error_on_change: {},
          grid_conf: {
              isPagination: true,
              gridAutoHeight: true,
              selectionType: 'single',
              paginationPageSize: 20
          },
          show_advance_modal: true,
          confirm_box_open: false,
          advance_search_value: [],
          by_pass_prompt: false,
          is_save_button_disabled: false,
          disable_payment_code_Is_Active: false,

      };
  }

  // Define initial properties and its value.
  page_name: string = '';
  page_metadata: any;
  token: string = '';
  form_object: any = {};
  code_id: 0;
  search_criteria: {};
  page_state: string = '';
  is_grid_visible = false;
  initial_form_object: any = {};
  go_next: boolean = false;
  by_pass_prompt: boolean = false;
  company_service_type: any = 0;
  menu_permission_object: Array<any> = [{ update: true }];

  // Fetching the page_name and state.
  UNSAFE_componentWillMount = () => {
    this._is_mounted = true;
    this.token = this.props.user_login_details.user_data.data.token_details.access_token;
    this.page_name = this.props.match.params && this.props.match.params.page_name ? this.props.match.params.page_name : '';
    this.code_id = this.props.match.params && this.props.match.params.id ? this.props.match.params.id : 0;
      var old_user_data = local_storage.get("auth_data");
      if (old_user_data) {
          this.company_service_type = old_user_data.company_service_type;
      }
    // Verifying page_name is correct or not.
    if (constants.page_list.indexOf(this.page_name) > -1) {
        let page_metadata = constants[this.page_name];
        this.page_metadata = {
          ...page_metadata,
          control_data : page_metadata.control_data.map(item=>({...item}))
        }
        this.set_default_search_data(this.page_metadata.control_data);
      // B-23679 its not requierd now
      // if (this.page_name == 'referring_physician') {
      //  this.page_metadata.control_data.filter(data_object => {
      //    return data_object.name === 'npi';
      //  })[0].is_required = false;
      // }

        this.set_default_form_data(this.page_metadata.control_data);
      if (this.code_id) {
          this.checkUpdatePermission();
          this.get_data(this.code_id);
      } else {
        this.setState({
            form_object: this.form_object,
            patient_master_data: this.props.user_login_details.formated_master_data,
            user_type_r3: this.props.user_login_details.user_data.data.user_type_r3_id
        });
      }
    } else {
      this.setState({
        show_render: false
      });
      this.props.history.goBack();
    }
  };

  componentDidMount = () => {
    this._is_mounted = true;
    document.body.classList.add('admin-framework');
    this.set_default_foucs();
  };

  componentWillUnmount = () => {
    this._is_mounted = false;
    document.body.classList.remove('admin-framework');
    if (this.props.history.location && constants.page_list.indexOf(this.page_name) > -1) {
      var route_array = this.props.history.location.pathname.split('/');
      route_array && route_array[2] !== this.page_name && session_storage.remove(`constant_${this.page_name}_criteria`);
    }
  };

  set_default_foucs = () => {
    let elem = document.getElementById('search-criteria-container');
    let all_form_elem = elem.getElementsByTagName('input');
    all_form_elem[0].focus();
  };

  get_page_state = () => {
    if (this.code_id) {
      return 'edit';
    } else {
      return 'add';
    }
  };

  // Set the default value to controls.
  set_default_search_data = control_data => {
    this.search_criteria = {};
    control_data.map(data => {
      if (data.is_search) {
        // Set only Search controls
        switch (data.control_type) {
          case 'checkbox':
            this.search_criteria[data.name] = data.default_value ? true : false;
            break;
          case 'textbox':
            this.search_criteria[data.name] = '';
            break;
          case 'drop_down_list':
            this.search_criteria[data.name] = data.default_value;
            break;
          case 'lookup_search':
            this.search_criteria[data.name] = '';
            break;
          case 'date':
            this.search_criteria[data.name] = null;
            break;
          case 'radio':
            this.search_criteria[data.name] = false;
            break;
          default:
            this.search_criteria[data.name] = '';
        }
      }
    });
  };

  // Get the data on the basis of ID.
  get_data = code_id => {
    let url = this.page_metadata.api.get.url;
    url = url.replace('id_value', code_id);
    if (this._is_mounted) {
      this.setState({
          is_loading: true,
          patient_master_data: this.props.user_login_details.formated_master_data,
          user_type_r3: this.props.user_login_details.user_data.data.user_type_r3_id
      });
    }
    constant_action.get_data(this.token, url).then(
      response => {
        if (response.data && response.data.data) {
          this.form_object = this.format_object(response.data.data, this.page_metadata.control_data);
          this.set_advance_search();
          this.initial_form_object = { ...this.form_object };
          // this.go_next = true;
            // Reg. B-23679 it is not required
            // if (this.page_name == 'referring_physician' && this.form_object.first_name == 'SELF') {
            //    this.page_metadata.control_data.filter(data_object => {
            //        return data_object.name === 'npi';
            //    })[0].is_required = false;
            // }
          if (this._is_mounted) {
            this.setState({
              form_object: this.form_object,
                is_loading: false,
                disable_payment_code_Is_Active: (this.page_name == 'payment_code' && this.form_object.payment_code == 'PPON') ? true : false,
            });
          }
        } else {
          if (response.data.messages[0].message.indexOf('<br') > -1) {
            this.show_html_content_toaster(response.data.messages[0].message);
          } else {
            toaster.error('', response.data.messages[0].message);
          }
          if (this._is_mounted) {
            this.setState({
              is_loading: false
            });
          }
        }
      },
      error => {
        if (this._is_mounted) {
          this.setState({
            is_loading: false
          });
        }

        log_error(error);
        if (error.data.messages[0].message.indexOf('<br') > -1) {
          this.show_html_content_toaster(error.response.data.messages[0].message);
        } else {
          toaster.error('', error.response.data.messages[0].message);
        }
      }
    );
  };

  // Data formatter
  format_object = (data_object, control_data) => {
    for (var field in data_object) {
      control_data.filter(data => {
        if (data.name == field) {
          if (data.format) {
            data_object[field] = data_object[field] ? custom_date_format(data_object[field], 'mm/dd/yyyy h:MM:ss TT') : data_object[field];
          }
          if (data.control_type == 'currency') {
            data_object[field] =
              data_object[field] != null && data_object[field] != undefined ? data_object[field].toString() : '';
        }
        }
      });
    }
    return data_object;
  };

  // Function used to set the input controls.
  set_default_form_data = control_data => {
    control_data.map(data => {
      if (data.is_edit) {
        if (this._is_mounted) {
          this.setState(({ is_error_control }) => ({
            is_error_control: {
              ...is_error_control,
              [data.name]: false
            }
          }));
        }
        switch (data.control_type) {
          case 'checkbox':
            this.form_object[data.name] = data.default_value ? true : false;
            break;
          case 'text':
            this.form_object[data.name] = '';
            break;
          case 'textarea':
            this.form_object[data.name] = '';
            break;
          case 'drop_down_list':
            this.form_object[data.name] = data.default_value;
            break;
          case 'date':
            this.form_object[data.name] = null;
            break;
          case 'radio':
            this.form_object[data.name] = false;
            break;
          case 'lookup_search':
            this.clear_quick_search(data.name);
            break;
          default:
            this.form_object[data.name] = '';
        }
      }
    });
    this.initial_form_object = { ...this.form_object };
    if (this._is_mounted) {
      this.setState({
        form_object: this.form_object
      });
    }
  };

  // Set the default value to controls.
    set_controls = (data_row, index) => {
    switch (data_row.control_type) {
      case 'drop_down_list':
        return (
          <Form.Field>
            {data_row.label && (
              <label>
                {data_row.label}
                {data_row.is_required && (
                  <span
                    className={
                      !this.state.form_object[data_row.name] &&
                      data_row.is_required &&
                      this.state.is_submitted &&
                      this.state.is_error_control[data_row.name]
                        ? 'req-alert'
                        : 'req-alert_normal'
                    }
                  >
                    {' '}
                                (required )
                  </span>
                )}
              </label>
            )}
            <SelectionComponent
              id={data_row.name}
              placeHolder={!data_row.is_placeholder ? 'Select' : null}
              hidden={true}
              options={
                data_row.name == 'state'
                  ? constants.option_formater(
                      this.state.patient_master_data.states,
                      'text',
                      'value',
                      true, // to add blank object
                      false // to add blank object
                    )
                  : data_row.name == 'speciality_id'
                  ? constants.option_formater(this.state.patient_master_data.referring_specialty, 'specialty_desc', 'specialty_id')
                  : data_row.name == 'medical_group_id'
                  ? constants.option_formater(this.state.patient_master_data.medical_group, 'medical_group', 'medical_group_id')
                  : data_row.master_data
              }
              onChange={(value, e) => {
                this.dropdown_change_handler(value, e);
              }}
              defaultValue={this.state.form_object[data_row.name] != undefined && this.state.form_object[data_row.name] != null && this.state.form_object[data_row.name].toString() ? this.state.form_object[data_row.name] : ''}
              style={'dropdown-options-wrap'}
              disabled={data_row.is_disable}
            />
          </Form.Field>
        );
      case 'date':
        return (
          <Form.Field>
            {data_row.label && (
              <label>
                {data_row.label}
                {data_row.is_required && (
                  <span
                    className={
                      !this.state.form_object[data_row.name] &&
                      data_row.is_required &&
                      this.state.is_submitted &&
                      this.state.is_error_control[data_row.name]
                        ? 'req-alert'
                        : 'req-alert_normal'
                    }
                  >
                    {' '}
                    (required)
                  </span>
                )}
              </label>
            )}
            <DatePicker
              date_update={date_object => this.calendar_change_handler(data_row, date_object)}
              date={this.state.form_object[data_row.name]}
              is_required={data_row.is_required}
              disabled={data_row.is_disable}
            />
          </Form.Field>
        );
      case 'checkbox':
        return (
          <Form.Field className='ui checkbox'>
            <div style={data_row.custom_style}>
              <input
                type='checkbox'
                name={data_row.name}
                id={data_row.name}
                checked={this.state.form_object[data_row.name] ? true : false}
                        onChange={e => this.input_change_handler(data_row, e)}
                        disabled={data_row.is_disable || (this.page_name == 'payment_code' && data_row.name == 'is_active' && this.state.disable_payment_code_Is_Active)}
              />
              <label className='chkbx_label' htmlFor={data_row.name}>
                {' '}
                {data_row.label}
              </label>
            </div>
          </Form.Field>
        );
      case 'label':
        return (
          <Form.Field>
            {data_row.label && <label>{data_row.label}</label>}
            <label className={'values'}>{this.state.form_object[data_row.name] ? this.state.form_object[data_row.name] : ''}</label>
          </Form.Field>
        );
      case 'textarea':
        return (
          <Form.Field>
            {data_row.label && <label>{data_row.label}</label>}
            <TextareaAutosize
              autoComplete='new-password'
              name={data_row.name}
              maxLength={data_row.max_length ? data_row.max_length : 250}
              id={data_row.name}
              onChange={e => this.input_change_handler(data_row, e)}
              value={this.state.form_object[data_row.name] ? this.state.form_object[data_row.name] : ''}
              disabled={data_row.is_disable}
              onKeyDown={
                (event) => {
                  if(data_row.disable_enter) {
                    if(event.keyCode==13 || event.which==13) {
                      event.preventDefault();
                      return false;
                    }
                  }
                }
              }
            />
          </Form.Field>
        );
      case 'currency':
        return (
          <Form.Field>
            {data_row.label && <label>{data_row.label}</label>}
            <CurrencyFormatter
              onChange={value => this.currency_change_handler(data_row, value)}
              prefix='$'
              focused={false}
              defaultValue={this.get_page_state() == 'add' ? '' : this.state.form_object[data_row.name]}
              allowNegative={true}
              className={'numberFormat'}
              maxLength={15}
              disabled={data_row.is_disable}
            />
          </Form.Field>
        );
      case 'phone':
      case 'fax':
        return (
          <Form.Field>
            {data_row.label && (
              <label>
                {data_row.label}
                {data_row.is_required && (
                  <span
                    className={
                      !this.state.form_object[data_row.name] &&
                      data_row.is_required &&
                      this.state.is_submitted &&
                      this.state.is_error_control[data_row.name]
                        ? 'req-alert'
                        : 'req-alert_normal'
                    }
                  >
                    {' '}
                    (required)
                  </span>
                )}
              </label>
            )}
            <MaskedInput
              mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              className={
                !this.state.form_object[data_row.name] &&
                data_row.is_required &&
                this.state.is_submitted &&
                this.state.is_error_control[data_row.name]
                  ? 'form-control req-background-inp'
                  : 'form-control'
              }
              placeholder='(___) ___-____'
              guide={true}
              id={data_row.name}
              name={data_row.name}
              autoComplete='new-password'
              showMask={true}
              onChange={e => this.input_change_handler(data_row, e)}
              value={this.state.form_object[data_row.name] ? this.state.form_object[data_row.name] : ''}
              disabled={data_row.is_disable}
            />
            {data_row.is_required_text && this.state.is_submitted && this.state.is_error_on_change[data_row.name] && (
              <div className='requiredText'>{data_row.is_required_msg}</div>
            )}
          </Form.Field>
        );
      case 'lookup_search':
        return (
          <Form.Field
            className={`advance-quick-search ${
              !this.state.form_object[data_row.name] && data_row.is_required && this.state.is_submitted && this.state.is_error_control[data_row.name]
                ? 'requiredWithBgColor error'
                : ''
            }`}
          >
            {data_row.label && (
              <label>
                {data_row.label}
                {data_row.is_required && (
                  <span
                    className={
                      !this.state.form_object[data_row.name] &&
                      data_row.is_required &&
                      this.state.is_submitted &&
                      this.state.is_error_control[data_row.name]
                        ? 'req-alert'
                        : 'req-alert_normal'
                    }
                  >
                    {' '}
                    (required)
                  </span>
                )}
              </label>
            )}
            <AdvancedControl
              showModal={this.state.show_advance_modal}
              onGridRowSelection={row => {
                this.on_grid_row_selection(row, data_row);
              }}
              gridConfig={this.state.grid_data}
              controlId={data_row.lookup_id}
              onSearch={value => {
                this.on_advance_search_click(data_row, value);
              }}
              disabled_element={(!!this.code_id && data_row.non_editable) || data_row.is_disable}
              search_type={data_row.lookup_id}
              headerIdForGridTabNavigation={constants.search_grid_id}
            />
            <AutoSearchComponent
              control_id={data_row.lookup_id}
              getInputValue={null}
              default_value={this.state.advance_search_value[data_row.name] || { title: '', label: '' }}
              errorMessage={'No Record Found !'}
              prepareRenderList={this.render_result}
              getList={input_value => {
                return this.get_quick_search_data_list(data_row, input_value);
              }}
              prepareDataList={data => this.prepare_suggestion(data, data_row)}
              selectresult={row => this.on_item_selection(row, data_row)}
              show_clear_search={true}
              clear_search={() => this.clear_quick_search(data_row.name)}
               is_disabled={(!!this.code_id && data_row.non_editable) || data_row.is_disable}
              errorClass={
                !this.state.form_object[data_row.name] &&
                data_row.is_required &&
                this.state.is_submitted &&
                this.state.is_error_control[data_row.name]
                  ? 'search-error-thin'
                  : ''
              }
            />
          </Form.Field>
        );
      case 'zip':
        return (
          <Form.Field>
            {data_row.label && (
              <label>
                {data_row.label}
                {data_row.is_required && (
                  <span
                    className={
                      !this.state.form_object[data_row.name] &&
                      data_row.is_required &&
                      this.state.is_submitted &&
                      this.state.is_error_control[data_row.name]
                        ? 'req-alert'
                        : 'req-alert_normal'
                    }
                  >
                    {' '}
                    (required)
                  </span>
                )}
              </label>
            )}              
            <Input
              maxLength={data_row.max_length ? data_row.max_length : 10}
              name={data_row.name}
              type={'text'}
              id={data_row.name}
              value={this.state.form_object[data_row.name] ? zip_code_formation(this.state.form_object[data_row.name]) : ''}
              onChange={e => this.input_change_handler(data_row, e)}
              onBlur={e => this.input_on_blur(e)}
              disabled={(!!this.code_id && data_row.non_editable) || data_row.is_disable}
              className={
                !this.state.form_object[data_row.name] &&
                data_row.is_required &&
                this.state.is_submitted &&
                this.state.is_error_control[data_row.name]
                  ? 'req-border-inp'
                  : ''
              }
              autoComplete='new-password'
              pattern={data_row.pattern}
            />
            {data_row.is_required_text && this.state.is_submitted && this.state.is_error_on_change[data_row.name] && (
              <div className='requiredText'>{data_row.is_required_msg}</div>
            )}
          </Form.Field>
        );
      default:
        return (
          <Form.Field>
            {data_row.label && (
              <label>
                {data_row.label}
                {data_row.is_required && (
                  <span
                    className={
                      !this.state.form_object[data_row.name] &&
                      data_row.is_required &&
                      this.state.is_submitted &&
                      this.state.is_error_control[data_row.name]
                        ? 'req-alert'
                        : 'req-alert_normal'
                    }
                  >
                    {' '}
                    (required)
                  </span>
                )}
              </label>
            )}
            <Input
              maxLength={data_row.max_length ? data_row.max_length : 50}
              name={data_row.name}
              type={data_row.control_type}
              id={data_row.name}
              value={this.state.form_object[data_row.name] ? this.state.form_object[data_row.name] : ''}
              onChange={e => this.input_change_handler(data_row, e)}
              onBlur={e => this.input_on_blur(e)}
              disabled={
                (!!this.code_id && data_row.non_editable) ||
                  (
                      this.page_name == 'referring_physician' &&
                      (this.state.user_type_r3 == 3 || this.state.user_type_r3 == 4) && 
                      (data_row.name == 'md_code' || data_row.name == 'first_name' ||
                          data_row.name == 'last_name' || data_row.name == 'npi') &&
                      this.get_page_state() == 'edit' && !(this.company_service_type == global_constants.CompanyServiceType.SoftwareOnly)
                  ) || (data_row.is_disable)
              }
                    className={
                        data_row.name!=='npi'?
                !this.state.form_object[data_row.name] &&
                data_row.is_required &&
                this.state.is_submitted &&
                this.state.is_error_control[data_row.name]
                  ? 'req-border-inp'
                  : '': this.state.is_submitted &&
                                this.state.is_error_on_change[data_row.name]
                                ? 'error'
                  : ''
              }
              autoComplete='new-password'
              pattern={data_row.pattern}
            />
            {data_row.is_required_text && this.state.is_submitted && this.state.is_error_on_change[data_row.name] && (
              <div className='requiredText'>{data_row.is_required_msg}</div>
            )}
          </Form.Field>
        );
    }
    };

    checkUpdatePermission = () => {
        this.menu_permission_object = this.props.shared_details.left_menu_data.data
            .filter((menu) => {
                return (menu.r6_path && menu.r6_path.split("/")[2] && menu.r6_path.split("/")[2] == this.page_name && constants.edit_permission_page_list.indexOf(menu.name) !== -1 ); 
            });
        
        if ((this.menu_permission_object.length == 0 || !this.menu_permission_object[0].view) && (constants.edit_permission_page_names.indexOf(this.page_name) !== -1)) {
            this.page_metadata.control_data.map((item) => {
              item['is_disable'] = item.always_enable ? false : true;
            })
            this.setState({ is_save_button_disabled: this.page_metadata.always_enable_save ? false : true });
        }
    }

  ////////////////////////////////////////////////////////////////////////////////Advance search////////////////////////////////////
  on_advance_search_click = (data_row, value) => {
    let queryString = Object.keys(value)
      .map(key => key + '=' + value[key])
      .join('&');
    let url = `${data_row.advance_search}?${queryString}`;
    // Search function.
    constant_action.get_data(this.token, url).then(response => {
       if (response.data && this._is_mounted) {
        let row_data = response.data.data ? response.data.data : [];
        this.setState({
          grid_data: {
            ...this.state.grid_conf,
            rows: row_data,
            column: constants[data_row.lookup_id].column_def,
            messages: 'No Record Available.'
          }
        });
      }
    });
  };

  get_quick_search_data_list = async (data_row, input_value) => {
    let value = input_value ? input_value.trim() : input_value;
    // let url = "/procedure_code/quick_serch_revenue_center?keyword=" + value + "&page_size=5000";
      let url = `${data_row.quick_search}?keyword=${value}&page_size=${global_constants.constants.Quick_Search_Suggestion_List_Size}`;
    return await constant_action.get_data(this.token, url);
  };

  // Prepare suggestion list to show in Quick Search result
  prepare_suggestion = (data, data_row) => {
    let formattedList = [];
    var data = data.data;
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        let label = data_row.suggestion_label_list;
        var selected_data = '';
        for (let key of label) {
          if (item.hasOwnProperty(key) && item[key] != null) {
            selected_data = selected_data != '' ? selected_data + '-' + item[key] : item[key] + '';
          }
        }
        let selected_label = data_row.label_list;
        var selected__label_data = '';
        for (let key of selected_label) {
          if (item.hasOwnProperty(key) && item[key] != null) {
            selected__label_data = selected__label_data != '' ? selected__label_data + '-' + item[key] : item[key] + '';
          }
        }
        formattedList.push({
          selected_label: selected__label_data,
          label: selected_data,
          title: `${item[data_row.advance_search_field.title]}-${item[data_row.advance_search_field.id]}`,
          name: item[data_row.advance_search_field.label],
          code: item[data_row.advance_search_field.title],
          id: item[data_row.advance_search_field.id]
        });
      }
    }
    return formattedList;
  };

  render_result = props => {
    return (
      <div key={props.title} tabIndex={0} className='item_auto_search'>
        <div key={props.title} className='fs_13'>
          <span>{props.label}</span>
        </div>
      </div>
    );
  };

  on_item_selection = (selected_row, data_row) => {
    let { advance_search_value } = this.state;
    this.form_object[data_row.name] = selected_row.id;
    advance_search_value[data_row.name] = {
      label: selected_row.selected_label,
      title: selected_row.title
    };
    if (this._is_mounted) {
      this.setState({
        advance_search_value
      });
    }
    this.closeModal();
  };

  // close the modal on selection of patient through quick search
  closeModal = () => {
    if (this._is_mounted) {
      this.setState({ show_advance_modal: false });
    }
  };

  clear_quick_search = field => {
    this.form_object[field] = '';
    let { advance_search_value } = this.state;
    advance_search_value[field] = { title: '', label: '' };
    this.setState({
      advance_search_value
    });
  };

  on_grid_row_selection = (selected_row, data_row) => {
    let { advance_search_value } = this.state;
    this.form_object[data_row.name] = selected_row[data_row.search_criteria_field];
    let label = data_row.label_list;
    var selected_data = '';
    for (let key of label) {
      if (selected_row.hasOwnProperty(key) && selected_row[key] != null) {
        selected_data = selected_data != '' ? selected_data + '-' + selected_row[key] : selected_row[key] + '';
      }
    }
    advance_search_value[data_row.name] = {
      label: selected_data,
      title: selected_row[data_row.advance_search_field.title]
    };
    if (this._is_mounted) {
      this.setState({
        advance_search_value
      });
    }
    this.closeModal();
  };

  ////////////////////////////////////////////////////////////////////////////////Advance search////////////////////////////////////

  // validate each input box on change event
  validate_value = (value, name) => {
    var error;
    switch (name) {
      case 'email':
        error = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? false : !value ? false : true;
        break;
      case 'phone':
        value = value.replace(/[^0-9]/g, '');
        error = value && value.length > 0 && value.length != 10;
        break;
      case 'fax':
        value = value.replace(/[^0-9]/g, '');
        error = value && value.length > 0 && value.length != 10;
        break;
      case 'ssn':
        error = value && value.length > 0 && value.length != 9;
        break;
      case 'zip':
        error = (value.length > 0 && value.length < 5) || (value.length > 5 && value.length < 9);
        break;
      case 'date_of_birth':
        error = value && value.length > 0 && value.length != 9;
        break;
      case 'default':
        return false;
    }
    return error;
  };

  currency_change_handler = (data_row, value) => {
    this.form_object[data_row.name] = value;
  };

  // Input change handler.
  input_change_handler = (data_row, e) => {
    const { name, value, checked } = e.target;
    let is_error_on_change = {};
    let is_error_control = {};
    if (data_row.control_type == 'checkbox') {
      this.form_object[name] = checked;
    } else if (name == 'npi') {
      is_error_on_change[name] = value.length > 0 ? !check_valid_npi(value) : false;
      is_error_control[name] = value.length > 0 ? true : false;
      this.form_object[name] = value;
    } else if (name == 'fax' || name == 'phone') {
      is_error_on_change[name] = this.validate_value(value, name);
      is_error_control[name] = unmask(value).trim() == '' ? true : false;
      this.form_object[name] = unmask(value);
    } else if (name == 'zip') {
      let field_value = value && value.length > 0 && value.replace(/[^0-9]/g, '');
      if(!isNaN(field_value)){
        is_error_on_change[name] = this.validate_value(field_value, name)
        is_error_control[name] = field_value == '' ? true : false;
        this.form_object[name] = field_value
      }
    } else if (name == 'email') {
      is_error_on_change[name] = this.validate_value(value, name);
      is_error_control[name] = !value && value.trim() == '' ? true : false;
      this.form_object[name] = value;
    } else if (this.page_metadata.referring_physician_id == 'referring_physician_id') {
      // Reg. B-23679 it is not required
      // if (name == 'first_name' && value == 'SELF') {
      //  this.page_metadata.control_data.filter(data_object => {
      //    return data_object.name === 'npi';
      //  })[0].is_required = false;
      // } else if (name == 'first_name' && value != 'SELF') {
      //  this.page_metadata.control_data.filter(data_object => {
      //    return data_object.name === 'npi';
      //  })[0].is_required = true;
      // }
      this.form_object[name] = value;
    } else if (this.page_name == 'revenue_center' && name == 'revenue_center_code'){
      let field_value = value && value.length > 0 && value.replace(/[^0-9]/g, '');
      if(!isNaN(field_value)){
        is_error_on_change[name] = this.validate_value(field_value, name)
        is_error_control[name] = field_value == '' ? true : false;
        this.form_object[name] = field_value
      }
    } else {
      is_error_control[name] = value.trim() == '' ? true : false;
      this.form_object[name] = value;
    }

    if (this._is_mounted) {
      this.setState(prev_state => ({
        is_error_on_change: {
          ...prev_state.is_error_on_change,
          [name]: is_error_on_change[name]
        },
        is_error_control: {
          ...prev_state.is_error_control,
          [name]: is_error_control[name]
        },
        form_object: this.form_object
      }));
    }
  };

  // Input Blur handler.
  input_on_blur = e => {
    const { name, value } = e.target;
    if (this.page_name == 'referring_physician' && name == 'first_name' && this._is_mounted) {
      this.form_object[name] = value ? value.toString().trim():'';
      // Reg. B-23679 it is not required
      //if(this.form_object[name] == 'SELF'){
      //  this.page_metadata.control_data.filter(data_object => {
      //    return data_object.name === 'npi';
      //  })[0].is_required = false;
      // }
      
      this.setState(prev_state => ({
        form_object: this.form_object
      }));
    }
  };

  // Calender change handler.
  calendar_change_handler = (data_row, date_object) => {
    // this.search_data[id] = date_object ? date_object : null;
  };

  // Dropdown change handler.
  dropdown_change_handler = (data, e) => {
    const { id, value } = e.target;
      this.form_object[id] = value;
      if (id === 'icd_type' && this.page_name === 'diagnosis_code') {
          this.form_object[id] = value === ' ' ? 0 : Number(value);
      }

    if (id == 'speciality_id') {
      this.form_object.taxonomy_code = this.state.form_object.taxonomy_code;
      const taxonomy_code = this.state.patient_master_data.referring_specialty.filter(data => {
        return data.specialty_id == value;
      })[0].taxonomy_code;
      this.form_object.taxonomy_code = taxonomy_code;
      if (this._is_mounted) {
        this.setState({
          form_object: {
            ...this.state.form_object,
            taxonomy_code: taxonomy_code
          }
        });
      }
    } else {
      if (this._is_mounted) {
        this.setState({
          form_object: {
            ...this.state.form_object
          }
        });
      }
    }
  };

  set_advance_search = async () => {
    let { advance_search_value } = this.state;
    let page_index = constants.page_list.indexOf(this.page_name);
    if (page_index != -1 && constants[this.page_name].is_lookup_search) {
      let { advance_search_value } = this.state;
      let lookup_search_control_data = constants[this.page_name].control_data.filter(item => item.control_type == 'lookup_search' && !item.is_search);
      let arrData = [];
      for (let item of lookup_search_control_data) {
        let id = this.form_object[item.name];
        if (id != null && id != undefined && id != '') {
        let url = `${item.advance_search_lookup}${id}`;
        // Search function.
        arrData.push(
          constant_action.get_data(this.token, url).then(response => {
            if (response.data && response.data.data) {
                let data = response.data.data;
              // data = data.length > 0 ? data[0] : [];
                data = typeof data === 'object' && data.constructor === Array ? (data.length > 0 ? data.filter(val => {
                    return val[item.name] == id
                })[0] : []) : data;
              let label = item.get_quick_search_list;
              var selected_data = '';
              for (let key of label) {
                if (data.hasOwnProperty(key) && data[key] != null) {
                  selected_data = selected_data != '' ? selected_data + '-' + data[key] : data[key] + '';
                }
              }

              advance_search_value[item.name] = {
                label: selected_data
              };
            }
          })
        );
      }

      }
      await Promise.all(arrData)
        .then(res => {
          this.setState({
            advance_search_value
          });
        })
        .catch(error => {});
    }
  };

  // Save the form object and redirect it to search
    save_handler = (e, is_force_save = false, from_pop_up) => {
    if (this._is_mounted) {
      this.setState({
        is_submitted: true
      });
    }
    var is_error = false;
      this.go_next = false;
    this.page_metadata.control_data.filter(data => {
      this.form_object[data.name] =
        this.form_object[data.name] && typeof this.form_object[data.name] !== 'boolean'
          ? this.form_object[data.name].toString().trim()
                : this.form_object[data.name];

        if (data.control_type == 'currency' && this.form_object[data.name] == '') {
            this.form_object[data.name] = 0;
        }

        if (data.name.indexOf('date') != -1 && data.format != undefined) {
            if (this.form_object[data.name] == '') {
                this.form_object[data.name] = null
            }
            else {
                this.form_object[data.name] = date_format_with_no_time_return_string_or_null(this.form_object[data.name]);
            }
        }
      if (data.is_required && !this.form_object[data.name] && this._is_mounted) {
        this.setState(prev_state => ({
          is_error_control: {
            ...prev_state.is_error_control,
            [data.name]: true
          }
        }));
        toaster.error('', constants.mandatory_fields);
        is_error = true;
      }
    });



    for (var key in this.state.is_error_on_change) {
      if (this.state.is_error_on_change[key]) {
        toaster.error('', constants.mandatory_fields);
        is_error = true;
      }
    }
    if (is_error) {
      return;
    }
    if (this._is_mounted) {
      this.setState({
        is_loading: true,
        is_save_button_disabled: true
      });
    }

      if (this.page_name == 'referring_physician') {
          this.form_object['medical_group_id'] = (this.form_object['medical_group_id'] == '') ?
              0 : this.form_object['medical_group_id'];
          this.form_object['speciality_id'] = (this.form_object['speciality_id'] == '') ?
              0 : this.form_object['speciality_id'];
      }

      if (this.page_name !== 'revenue_center') {
          //B-28565: The previously entered search criteria should remain.
          //Added 'revenue_center' page name check not to empty search criteria.
          session_storage.set(`constant_${this.page_name}_criteria`, {
              search_criteria: this.search_criteria,
              advance_search_value: {}
          });
      }
      if (this.page_name === 'procedure_code') {
        if (!this.form_object['effective_date'] || is_null_or_white_space(this.form_object['effective_date'])) {
          this.form_object['effective_date'] = null;
        } else {
            this.form_object['effective_date'] = date_format_with_no_time_return_string_or_null(this.form_object['effective_date']);
        }

        if (!this.form_object['last_set_inactive_date'] || is_null_or_white_space(this.form_object['last_set_inactive_date'])) {
          this.form_object['last_set_inactive_date'] = null;
        } else {
            this.form_object['last_set_inactive_date'] = date_format_with_no_time_return_string_or_null(this.form_object['last_set_inactive_date']);
        }
      }
    if (this.code_id) {
      // Update
      let url = this.page_metadata.api.update.url;
      url = url.replace('id_value', this.code_id);
        if (url.indexOf('save_force')) {
        url = url.replace('save_force', is_force_save);
      }
      constant_action.update_data(this.token, url, this.form_object).then(
        response => {
          if (response.data && response.data.data) {
            this.go_next = true;
            toaster.success('', this.page_metadata.validation_message.update);
            this.initial_form_object = { ...this.form_object }  
            this.setState({ loading: false, by_pass_prompt: !from_pop_up ? true : false })
            if (!from_pop_up && this.go_next) {
              this.go_next = false;
              let last_report_navigation = local_storage.get('last_report_navigation');
              local_storage.remove('last_report_navigation');
              if (!last_report_navigation) {
                  this.props.history.goBack()
              } else {
                  this.props.history.push(last_report_navigation);
              }
            }
          } else {
            if (this.page_name == 'referring_physician') {
              if (this._is_mounted) {
                  let open_confirm_box = true;// !(this.state.user_type_r3 == 3 || this.state.user_type_r3 == 4);
                if(open_confirm_box) {
                  this.handle_on_open_confirm_box_window();
                }

                this.setState({
                  confirm_box_open: open_confirm_box,
                  error_msg: response.data.messages[0].message
                });
                }
              // B-23679 its not required now
              // if (this.state.user_type_r3 == 3 || this.state.user_type_r3 == 4) {
              //  this.save_handler(e, true, from_pop_up);
              // }
            } else {
              this.go_next = false;
              toaster.error('', response.data.messages[0].message);
            }
            if (this._is_mounted) {
              this.setState({
                is_loading: false,
                is_save_button_disabled: false
              });
            }
          }
        },
        error => {
          if (this._is_mounted) {
            this.go_next = false;
            this.setState({
              is_loading: false,
              is_save_button_disabled: false
            });
          }
          if (error.response.data) toaster.error('', error.response.data.messages[0].message);
        }
      );
    } else {
      // Add
      let url = this.page_metadata.api.add.url;
        if (url.indexOf('save_force')) {
            url = url.replace('save_force', is_force_save);
      }
      constant_action.add_data(this.token, url, this.form_object).then(
        response => {
          if (response.data && response.data.data) {
            this.go_next = true;
            toaster.success('', this.page_metadata.validation_message.save);
            this.initial_form_object = { ...this.form_object };            
            this.setState({ loading: false, by_pass_prompt: !from_pop_up ? true : false })
            if (!from_pop_up && this.go_next) {
              this.go_next = false;
              let last_report_navigation = local_storage.get('last_report_navigation');
              local_storage.remove('last_report_navigation');
              if (!last_report_navigation) {
                  this.props.history.goBack()
              } else {
                  this.props.history.push(last_report_navigation);
              }
            }
          } else {
            if (this.page_name == 'referring_physician') {
              if (this._is_mounted) {
                this.handle_on_open_confirm_box_window();
                this.setState({
                  confirm_box_open: true,
                  error_msg: response.data.messages[0].message
                });
              }
            } else {
              this.go_next = false;
              toaster.error('', response.data.messages[0].message);
            }
            if (this._is_mounted) {
              this.setState({
                is_loading: false,
                is_save_button_disabled: false
              });
            }
          }
        },
        error => {
          if (this._is_mounted) {
            this.go_next = false;
            this.setState({
              is_loading: false,
              is_save_button_disabled: false
            });
          }
          if (error.response.data) toaster.error('', error.response.data.messages[0].message);
        }
      );
    }
  };
  
  handle_on_open_confirm_box_window = () => {
    setTimeout(() => {
        let closeIcons: NodeListOf<HTMLElement> = document.querySelectorAll('i.close.icon') as NodeListOf<HTMLElement>;
        let closeIcon = closeIcons[closeIcons.length-1]
        closeIcon.tabIndex = 0;
        closeIcon.id = "confirm_box_model_close_button";
        closeIcon.addEventListener("keydown", function (event) {
            if (event.shiftKey && event.keyCode == 9) {
                event.preventDefault();
                set_focus_on_element_with_id("confirm_button_confirm_box_window");
            }

            if (event.keyCode == 13) {
                event.preventDefault();
                var el = event.target as HTMLElement
                el.click();                                                           
            }
        });
        set_focus_on_element_with_id("confirm_button_confirm_box_window");
    }, 10)        
}
  // Function used to Run Report based on controllers values.
  cancel_handler = () => {
    this.by_pass_prompt = true;
    this.setState(
      {
        by_pass_prompt: true
      },
      () => {
        let last_report_navigation = local_storage.get('last_report_navigation');
        local_storage.remove('last_report_navigation');
        if (!last_report_navigation) {
            this.props.history.goBack()
        } else {
            this.props.history.push(last_report_navigation);
        }
      }
    );
  };

  // close popup
  confirm_close_handler = () => {
    if (this._is_mounted) {
      set_focus_on_element_with_id("save_button_id");
      this.setState({
        confirm_box_open: false
      });
    }
  };

  // confirm popup handler
  confirm_handler = e => {
    this.save_handler(e, true, false);
    if (this._is_mounted) {
      this.setState({
        is_loading: true,
        confirm_box_open: false
      });
    }
  };

  // Show multiple messages
  show_html_content_toaster = msg => {
    return {
      component: () => (
        <div>
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        </div>
      ),
      timeOut: global_constants.toastr_options.toastr_time_out,
      preventDuplicates: true
    };
  };
  // It renders report grid and its search controls.
  render() {
    this.page_state = this.get_page_state();
    return (
      this.state.show_render && (
        <React.Fragment>
          {!this.state.by_pass_prompt && (
            <PromptNavigation
              is_data_changed={JSON.stringify(this.initial_form_object) !== JSON.stringify(this.form_object)}
              save={e => this.save_handler(e, false, true)}
              go_next_location={this.go_next}
              history={this.props.history}
            />
          )}
          <Dimmer active={this.state.is_loading}>
            <Loader size='massive'>Loading</Loader>
          </Dimmer>
          <div
            className={'admin-wrapper view-edit ' + this.page_metadata.id}
            style={!this.state.show_grid ? { paddingBottom: 0 } : {}}
          >
            <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
              <Grid.Column computer={16}>
                <h3 className='ui header left aligned'>
                  {this.page_state == 'edit' ? this.page_metadata.edit_header : this.page_metadata.add_header}
                </h3>
              </Grid.Column>
            </Grid>
            <div id='admin-scrollable-area' className='wrapper' style={this.state.show_grid ? { flexGrow: 1 } : {}}>
              <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container'>
                <Form autoComplete='off' name='view_edit_form' className='common-forms'>
                  <Grid>
                      {constants.group_by(this.page_metadata.control_data.filter((data_object) => {
                        return data_object[`is_${this.page_state}`];
                        }), 'row').map((data_object, index) => {
                      return (
                        <Grid.Column computer={16} key={'outer_row_' + index}>
                          <Grid>
                            {data_object.map((data_row, index) => {
                                return (
                                  <Grid.Column
                                    className={data_row.control_type === 'checkbox' ? 'form_column' : ''}
                                    key={'inner_row_' + index}
                                    tablet={data_row.view_container_tablet}
                                    computer={data_row.view_container_computer}
                                    verticalAlign='middle'
                                  >
                                    {this.set_controls(data_row, index)}
                                  </Grid.Column>
                                );
                            })}
                          </Grid>
                        </Grid.Column>
                      );
                    })}
                  </Grid>
                </Form>
              </div>
            </div>
            <div
              className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
              id='applicationFooterSticky'
            >
              <Button id='cancel_button_id' type='button' onClick={this.cancel_handler} basic content={'Cancel'} />
              <Button
                type='submit'
                onKeyDown={set_focus_to_app_header}
                onClick={e => this.save_handler(e, false, false)}
                id='save_button_id'
                disabled={this.state.is_save_button_disabled}
                primary
                content={'Save'}
              />
            </div>
          </div>
          <Modal
            closeIcon
            centered={false}
            closeOnDimmerClick={false}
            open={this.state.confirm_box_open}
            onClose={this.confirm_close_handler}
            className='confirm_box default-modal'
            id={'confirm_box'}
          >
            <Modal.Header>Confirm</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <Grid>
                  <Grid.Column>
                    <p>{this.state.error_msg}</p>
                  </Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column computer={16} textAlign='right'>
                    <Button type='cancel' basic onClick={this.confirm_close_handler}>
                      No
                    </Button>
                    <Button type='button' id="confirm_button_confirm_box_window" onKeyDown={(e) => {
                      handle_focus_on_tab(e, "confirm_box_model_close_button")
                    }} onClick={this.confirm_handler} primary>
                      Yes
                    </Button>
                  </Grid.Column>
                </Grid>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        </React.Fragment>
      )
    );
  }
}

// Get user and login details from store.
const mapStateToProps = state => {
  return {
    selected_patient: state.patient_details.patient_header,
      user_login_details: state.user_login_details,
      shared_details: state.shared_details,
  };
};

export default connect(mapStateToProps)(ConstantEditComponent);
