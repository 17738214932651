import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Button, Dimmer, Form, Grid, Input, Loader } from 'semantic-ui-react';
import * as export_print_utility from '../../../reports/util/export_print_utility';
import GridView from '../../../shared/component/grid';
import { set_focus_on_element_with_id, set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import { phone_format, get_columns, format_zip_code, } from '../../../shared/utility';
import AdvanceQuickSearch from "../../constants/component/claim_adjustment_code_advanced_search";
import { get_search_data, get_data } from '../action/constants_action';
import * as constants from '../constants';
import CheckboxComponent from './../../../shared/component/checkbox_component';
import * as session_storage from '../../../shared/session_storage_utility';
import * as local_storage from '../../../shared/local_storage_utility';
import * as global_constants from './../../../global_constants';
import * as ReportConstants from '../../../reports/report_constants';
import { providerBillingSettingsFeatures, BillingAuditLogPagesFeature } from '../../../admin/constants/constants';
class UserSearchComponent extends React.Component<any, any> {
    [x: string]: any;
    constructor(props) {
        super(props);
        // Define initial state value.
        this.state = {
            controls: null,
            show_grid: false,
            show_render: true,
            search_criteria: [],
            is_loading: false,
            shown: false,
            user_type_search_data: null,
            role_search_data: null,
            default_company_search_data:null,
            user_type_selected_row: null,
            role_selected_row: null,
            default_company_selected_row:null,
            grid_rows_count: 0
        };
        this.user_type_search = React.createRef();
        this.role_search = React.createRef();
        this.default_company_search = React.createRef();
    }
    // Define initial properties and its value.
    _is_mounted = false;
    user_type_enum = "user_type";
    role_enum = "role";
    default_company_enum = "default_company";
    page_name: string = 'users';
    page_metadata: any;
    users_search_criteria: any = {
        first_name: '',
        last_name: '',
        login_id: '',
        user_type_id: '',
        email: '',
        role_id: '',
        default_company_id:'',
        show_inactive: false
    };
    initial_search_criteria: any = {
        first_name: '',
        last_name: '',
        login_id: '',
        user_type_id: '',
        email: '',
        role_id: '',
        default_company_id: '',
        show_inactive: false
    };
    token: string = '';
    row_data = [];
    is_search_ui: any = false;
    code_id: number = 0;
    is_grid_visible = false;
    title = '';
    current_date: any;
    company_name: any;
    total_column_width: number = 0;
    grid_header_height: number = 0;
    user_type_selected_row: null;
    role__selected_row: null;
    default_company_selected_row: null;
    reportId: string = "0";

    // Fetching the page_name and state.
    UNSAFE_componentWillMount = () => {
        this.get_page_metadata();
    };

    componentDidMount = () => {
        this._is_mounted = true
        document.body.classList.add('admin-framework');
        let elem = document.getElementById('search-criteria-container');
        let all_form_elem = elem.getElementsByTagName('input');
        all_form_elem[0].focus();
        let controls = {
            user_type: {
                type: "user_type",
                grid_config: {
                    rows: null,
                    column: constants.users.user_type_column_def
                },
                control_id: 'ADMIN_SEARCH_USER_TYPE',
                selected_row: null,
                error_message: 'No Record Found !',
                label: ["name"]
            },
            role: {
                type: "role",
                grid_config: {
                    rows: null,
                    column: constants.users.role_column_def
                },
                control_id: 'ADMIN_SEARCH_ROLE',
                error_message: 'No Record Found !',
                label: ["role_name"],
                selected_row: null,
            },
            default_company: {
                type: "default_company",
                grid_config: {
                    rows: null,
                    column: constants.users.default_company_column_def
                },
                control_id: 'ADMIN_COMPANY_SEARCH',
                error_message: 'No Record Found !',
                label: ["gpms_code","company_name"],
                selected_row: null,
            }
        }
        this.setState({
            controls
        })
        this.handle_search_button_tab();
    };

    componentWillUnmount = () => {
        document.body.classList.remove('admin-framework');
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[2] !== 'users' && session_storage.remove('users_search_criteria');
        }
    };

    //Use to fetch params and page metadata
    get_page_metadata = () => {
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.page_metadata = constants.users;
        let users_search_criteria = session_storage.get('users_search_criteria')
        this.users_search_criteria = (users_search_criteria && users_search_criteria.search_criteria) || this.users_search_criteria;
        this.user_type_selected_row = (users_search_criteria && users_search_criteria.user_type_selected_row) || this.user_type_selected_row
        this.role_selected_row = (users_search_criteria && users_search_criteria.role_selected_row) || this.role__selected_row
        this.default_company_selected_row = (users_search_criteria && users_search_criteria.default_company_selected_row) || this.default_company_selected_row
        if (users_search_criteria) {
            this.search_handler(this);
        }
        this.setState({
            show_render: true
        });
    };

    // Data formatter
    format_data = (rows, columns) => {
        let formatted_data = rows.map(row_data => {
             columns.filter(col_data => {
                switch (col_data.type) {
                    case 'currency':
                        row_data[col_data.field] =
                            row_data[col_data.field] != null || row_data[col_data.field] != undefined ? constants.currencyFormatter(row_data[col_data.field]) : constants.currencyFormatter(0);
                        break;
                    case 'boolean':
                        row_data[col_data.field] = row_data[col_data.field] ? 'Active' : 'Inactive';
                        break;
                    case 'number':
                        row_data[col_data.field] = row_data[col_data.field];
                        break;
                    case 'string':
                        row_data[col_data.field] = row_data[col_data.field];
                        break;
                    case 'phone':
                        row_data[col_data.field] = row_data[col_data.field] ? phone_format(row_data[col_data.field]) : row_data[col_data.field];
                        break;
                    case 'zip':
                        row_data[col_data.field] = row_data[col_data.field] ? format_zip_code(row_data[col_data.field]) : row_data[col_data.field];
                        break;
                    default:
                        row_data[col_data.field] = row_data[col_data.field];
                }
            });
            return row_data;
        });
        return formatted_data;
    };

    //On double click of grid, it redirects user to view page.
    on_row_double_clicked = selected_row_data => {
        this.code_id = selected_row_data['user_id'];
        this.props.history.push(`${this.page_metadata.base_url}/${this.page_name}/${this.code_id}`, { by_pass_prompt: false });
    };

    //Input change handler.
    input_change_handler = e => {
        const { value, checked, type, name } = e.target;
        if (type == 'checkbox') {
            this.users_search_criteria[name] = checked;
        } else {
            this.users_search_criteria[name] = value;
        }
        this.setState({
            search_criteria: this.users_search_criteria
        });
    };

    //Search button handler and used to search the data on the basis of search criteria.
    search_handler = e => {
        var url = this.page_metadata.api.search.url;
        for (let key in this.users_search_criteria) {
            url = url.replace(
                key + '_value',
                typeof this.users_search_criteria[key] !== 'undefined' ? encodeURIComponent(this.users_search_criteria[key].toString().trim()) : ''
            );
        }
        this.setState({
            is_loading: true
        });
        get_search_data(this.token, url).then(
            response => {
                if (response.data) {
                    this.row_data = response.data.data ? response.data.data : [];
                    this.row_data = this.format_data(this.row_data, this.page_metadata.column_def);
                    const grid_height = get_columns(this.row_data.slice(0, 20), this.page_metadata.column_def);
                    this.total_column_width = grid_height.total_column_width;
                    this.grid_header_height = grid_height.header_height;
                    this.is_search_ui = true;
                    this.setState({
                        show_grid: true,
                        is_loading: false,
                        search_criteria: this.users_search_criteria,
                        role_selected_row: this.role_selected_row,
                        user_type_selected_row: this.user_type_selected_row,
                        default_company_selected_row: this.default_company_selected_row,
                        grid_rows_count: this.row_data && this.row_data.length
                    });
                    session_storage.set('users_search_criteria', {
                        search_criteria: this.users_search_criteria,
                        role_selected_row: this.role_selected_row,
                        user_type_selected_row: this.user_type_selected_row,
                        default_company_selected_row: this.default_company_selected_row
                    });
                }
            },
            error => {
                this.setState({
                    is_loading: false
                });
             }
        );
    };

    //Reset button the search control.
    clear_handler = e => {
        this.users_search_criteria = { ...this.initial_search_criteria };
        this.setState({
            show_grid: false,
            shown: false,
            search_criteria: this.initial_search_criteria,
            grid_rows_count: 0
        });
        this.is_search_ui = false;
        this.is_grid_visible = false;
        this.user_type_search.clear_quick_search(this.user_type_search.props.control.type)
        this.role_search.clear_quick_search(this.role_search.props.control.type)
        this.default_company_search.clear_quick_search(this.default_company_search.props.control.type)
        session_storage.remove('users_search_criteria');
    };

    //New button handler.
    new_handler = e => {
        //this.props.history.push(`${this.page_metadata.base_url}/${this.page_name}`, { by_pass_prompt: false });
    };

    //Function calls on initialization of Ag-Grid and catch its reference.
    get_grid_ref = grid_params => {
        this.setState({
            grid_params,
            grid_rows_count: grid_params.api.getModel().getRowCount()
        });
        var grid_width = document.getElementsByClassName('admin-wrapper')[0].clientWidth;
        if (this.total_column_width > grid_width) {
            this.page_metadata.column_def.filter(item => {
                grid_params.columnApi.setColumnWidth(item.field, item.width, false);
            });
        }
    };

    //Used to create the title for export and print.
    set_title = separter => {
        this.company_name = this.props.user_login_details.user_data.data.company_name;
        this.current_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
        this.title = this.page_metadata.search_header;
        return this.title =
            this.title.replace(/<br>/g, separter) +
            separter +
            this.company_name +
            separter +
            this.current_date +
            separter +
            (this.state.grid_rows_count == 1
                ? this.state.grid_rows_count + ' record'
                : this.state.grid_rows_count + ' records') +
            separter +
            separter;
    };

    //Function calls on initialization of export report data
    on_export_button = () => {
        this.title = this.set_title('\n');
        this.page_name = this.page_name.replace(/<br>/g, '');
        export_print_utility.export_grid_data(this.state.grid_params, this.title, this.page_name);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Export);
    };

    //Function calls on initialization of Print report data
    on_print_button = () => {
        this.title = this.set_title('<br>');
        export_print_utility.print_grid_data(this.state.grid_params, this.title, this.page_name);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
    };

    on_grid_out = () => {
        set_focus_on_element_with_id('app_header_dropdown');
    };

    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;

        const payload: ReportConstants.IPayloadForBillingAudit = {
            reportId: this.reportId,
            contextTitle: `${this.page_metadata.search_header} - ${ReportConstants.ReportEventAction[eventActionId]}`,
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: ReportConstants.ReportEntityTypeId.A_User,
            data: { Records: this.state.grid_rows_count }
        };

        const reqBody = export_print_utility.generateAuditDataEntryPayload(payload);

        ReportConstants.saveAuditPrintExport(reqBody, accessToken);
    };

    handle_search_button_tab = () => {
        setTimeout(() => {
            let searchButton = document.getElementById('search_button_id');
            let self = this;
            searchButton.addEventListener('keydown', function (event) {
                // apply a check where grid is empty
                if (!event.shiftKey && (self.row_data == null || (self.row_data.length == 0 && !self.state.show_grid))) {
                    set_focus_to_app_header(event);
                }
            });
        }, 200);
    };
    on_quick_search = async (params, type) => {
        let url = '';
        let result;
        if (params.trim().length > 0) {
            switch (type) {                
                case 'user_type':
                    url = constants.users.api.user_type_quick_search + "?keyword=" + params + "&page_size=" + global_constants.constants.Quick_Search_Suggestion_List_Size;
                    break;
                case 'role':
                    let userTypeId =  this.props.user_login_details.user_data.data.user_type_id;                    
                    if (userTypeId)
                        params = params + '&user_type_id=' + userTypeId;
                    url = constants.users.api.role_quick_search + "?keyword=" + params + "&page_size=" + global_constants.constants.Quick_Search_Suggestion_List_Size;
                    break;
                default:
                    url = constants.users.api.default_company_quick_search + "?keyword=" + params + "&page_size=" + global_constants.constants.Quick_Search_Suggestion_List_Size;                  
                    break;
            }
            result = await get_search_data(this.token, url);
            return result;
        } else {
            let result: any = {}
            result.data = []
            return result;
        }
    }

    add_handler = e => {
        e.preventDefault();
        this.props.history.push(`${this.page_metadata.base_url}/${this.page_name}`, { by_pass_prompt: false });
    };
    on_advanced_search = (params, type) => {
        if (type == 'role') {
            
            let userTypeId = 0;
           
            if (local_storage.get('auth_data')) {
                userTypeId = local_storage.get('auth_data').user_type_r3_id;
            }
            params = { ...params, user_type_id: userTypeId };
            //if (userTypeId) {
            //    params = { ...params, user_type_id: userTypeId };
            //}
        }
        let queryString = Object.keys(params)
            .map(key => key + '=' + params[key])
            .join('&');
        let api_url = constants[this.page_name].api[`${type}_advance_search`]
        let url = `${api_url}?${queryString}`;
        // Search function.     
        get_data(this.token, url).then(response => {
            if (response.data) {
                let row_data = response.data.data ? response.data.data : [];
                if (this._is_mounted) {
                    switch (type) {
                        case 'user_type':
                            this.setState({
                                user_type_search_data: {
                                    rows: row_data,
                                    column: constants.users.user_type_column_def
                                }
                            })
                            break;
                        case 'role':
                            this.setState({
                                role_search_data: {
                                    rows: row_data,
                                    column: constants.users.role_column_def
                                }
                            })
                            break;
                        default:
                            this.setState({
                                default_company_search_data: {
                                    rows: row_data,
                                    column: constants.users.default_company_column_def
                                }
                            })
                            break;
                    }
                }
            }
        })
    }
    update_data = (data, type) => {
        if (type == this.user_type_enum) {
            this.users_search_criteria.user_type_id = data ? data.user_type_id : '';
            this.setState({
                search_criteria: this.users_search_criteria,
                user_type_selected_row: data
            });
            this.user_type_selected_row = data
        } else if (type == this.role_enum) {
            this.users_search_criteria.role_id = data ? data.role_id : '';
            this.setState({
                search_criteria: this.users_search_criteria,
                role_selected_row: data
            });
            this.role_selected_row = data
        } else {
            this.users_search_criteria.default_company_id = data ? data.company_id : '';
            this.setState({
                search_criteria: this.users_search_criteria,
                default_company_selected_row: data
            });
            this.default_company_selected_row = data
        }
    }

    update_report_rows_count = () => {
        this.setState({
            grid_rows_count: this.state.grid_params.api.getModel().getRowCount()
        });
    };
    //It renders report grid and its search controls.
    render() {
        return (
            this.state.show_render && (
                <React.Fragment>
                    <Dimmer active={this.state.is_loading}>
                        <Loader size='massive'>Loading</Loader>
                    </Dimmer>
                    <div className={'admin-wrapper ' + this.page_metadata.id} style={!this.state.show_grid ? { paddingBottom: 20 } : {}}>
                        <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                            <Grid.Column computer={16}>
                                <h3 className='ui header left aligned' dangerouslySetInnerHTML={{ __html: this.page_metadata.search_header }} />
                            </Grid.Column>
                        </Grid>
                        <div id='admin-scrollable-area' className='wrapper' style={this.state.show_grid ? { flex: 1 } : {}}>
                            <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container'>
                                <Form autoComplete='off'>
                                    <Grid>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>First Name</label>
                                                <Input
                                                    autoComplete='off'
                                                    name='first_name'
                                                    id='first_name'
                                                    onChange={e => this.input_change_handler(e)}
                                                    value={this.users_search_criteria['first_name'] ? this.users_search_criteria['first_name'] : ''}
                                                    type='text'
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Last Name</label>
                                                <Input
                                                    autoComplete='off'
                                                    name='last_name'
                                                    id='last_name'
                                                    onChange={e => this.input_change_handler(e)}
                                                    value={this.users_search_criteria['last_name'] ? this.users_search_criteria['last_name'] : ''}
                                                    type='text'
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>User Name</label>
                                                <Input
                                                    autoComplete='off'
                                                    name='login_id'
                                                    id='login_id'
                                                    onChange={e => this.input_change_handler(e)}
                                                    value={this.users_search_criteria['login_id'] ? this.users_search_criteria['login_id'] : ''}
                                                    type='text'
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={4}>
                                            <Form.Field className="advance-quick-search">
                                                <label>User Type</label>
                                                <AdvanceQuickSearch
                                                    control={this.state.controls ? this.state.controls.user_type : null}
                                                    update_data={this.update_data}
                                                    search_result={this.state.user_type_search_data}
                                                    on_quick_search={this.on_quick_search}
                                                    on_advanced_search={this.on_advanced_search}
                                                    selected_row={this.state.user_type_selected_row}
                                                    onRef={instance => { this.user_type_search = instance; }}
                                                    headerIdForGridTabNavigation={constants.users.user_type_header_id}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Email</label>
                                                <Input
                                                    autoComplete='off'
                                                    name='email'
                                                    id='email'
                                                    onChange={e => this.input_change_handler(e)}
                                                    value={this.users_search_criteria['email'] ? this.users_search_criteria['email'] : ''}
                                                    type='text'
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={4}>
                                            <Form.Field className="advance-quick-search">
                                                <label>Role</label>
                                                <AdvanceQuickSearch
                                                    control={this.state.controls ? this.state.controls.role : null}
                                                    update_data={this.update_data}
                                                    search_result={this.state.role_search_data}
                                                    on_quick_search={this.on_quick_search}
                                                    on_advanced_search={this.on_advanced_search}
                                                    selected_row={this.state.role_selected_row}
                                                    onRef={instance => { this.role_search = instance; }}
                                                    headerIdForGridTabNavigation={constants.users.role_header_id}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={8} tablet={8} computer={4}>
                                            <Form.Field className="advance-quick-search">
                                                <label>Default Company</label>
                                                <AdvanceQuickSearch
                                                    control={this.state.controls ? this.state.controls.default_company : null}
                                                    update_data={this.update_data}
                                                    search_result={this.state.default_company_search_data}
                                                    on_quick_search={this.on_quick_search}
                                                    on_advanced_search={this.on_advanced_search}
                                                    selected_row={this.state.default_company_selected_row}
                                                    onRef={instance => { this.default_company_search = instance; }}
                                                    headerIdForGridTabNavigation={constants.users.default_company_header_id}
                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field style={{ marginTop: 32 }}>
                                                <CheckboxComponent
                                                    name='show_inactive'
                                                    id='show_inactive'
                                                    onChange={e => this.input_change_handler(e)}
                                                    checked={this.users_search_criteria['show_inactive'] ? this.users_search_criteria['show_inactive'] : false}
                                                    label={'Include Inactive'}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                    <Grid style={{ margin: '0 -17px' }}>
                                        <Grid.Column computer={16} textAlign='right'>
                                            <Button id='clear_button_id' type='button' onClick={e => this.clear_handler(e)} basic>Clear</Button>
                                            <Button id='new_button_id' type='button' onClick={this.add_handler} basic>New</Button>
                                            <Button id='search_button_id' type='submit' onClick={e => this.search_handler(e)} primary>Search</Button>
                                        </Grid.Column>
                                    </Grid>                                    
                                </Form>
                            </div>
                            {this.state.show_grid && !this.state.is_loading && (
                                <GridView
                                    id={this.page_name}
                                    row={this.row_data}
                                    column={this.page_metadata.column_def}
                                    style={{ height: '100%', display: 'flex',flexDirection: 'column' }}
                                    wrapperStyle={{ width: '100%', height: 0, display: 'flex', flex: '1 1 auto' }}
                                    wrapperClass="user_admin_grid"
                                    suppressMovableColumns={false}
                                    enableColResize={true}
                                    onRowDoubleClicked={this.on_row_double_clicked}
                                    selectionType={'single'}
                                    isPagination={true}
                                    paginationPageSize={20}
                                    get_grid_ref={this.get_grid_ref}
                                    suppressSizeToFit={true}
                                    headerHeight={this.grid_header_height}
                                    headerIdForTabNavigation={constants.search_grid_id}
                                    onForceGridOut={this.on_grid_out}
                                    on_filter_button_click={() => this.update_report_rows_count()}
                                />
                            )}
                        </div>
                        {this.state.show_grid && (
                            <div
                                className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                                id='applicationFooterSticky'
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                                <Grid.Column computer={16} textAlign='right'>
                                    <Button id='export_report_button' type='submit' onClick={this.on_export_button} basic>
                                        Export
                  </Button>
                                    <Button onKeyDown={set_focus_to_app_header} id='print_report_button' type='submit' onClick={this.on_print_button} primary>
                                        Print
                  </Button>
                                </Grid.Column>
                            </div>
                        )}
                    </div>
                </React.Fragment>
            )
        );
    }
}

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
    };
};

export default connect(mapStateToProps)(UserSearchComponent);
