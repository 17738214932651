import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dimmer, Form, Grid, Loader, Button } from 'semantic-ui-react';
import { toastr as toaster } from 'react-redux-toastr';
import { log_error } from '../../shared/action/shared_action';
import * as session_storage from '../../shared/session_storage_utility';
import { custom_date_format, get_columns, charge_get_columns, get_all_error, get_lauch_darkley_key_value } from '../../shared/utility';
import * as report_service from '../action/report_action';
import * as report_constants from '../report_constants';
import * as export_print_utility from '../util/export_print_utility';
import { set_focus_to_app_header } from './../../shared/tab_navigation_utility';
import ReportFooter from './report_footer';
import ReportHeader from './report_header';
import DatePicker from '../../shared/component/date_picker_component';
import SelectionComponent from '../../shared/component/selection_component';
import GridView from '../../shared/component/grid/grid_child_ui_component';
import * as ReportConstants from '../../reports/report_constants';
import * as global_constants from '../../global_constants';
import DateRangePickerComponent from '../../shared/component/date_range_component';
import { ChargesBillingAuditLogEpicsFeature } from '../../admin/constants/constants';
import { DataEntry } from '../../shared/audit/print_export_constants';
import * as PrintExportConstants from '../../shared/audit/print_export_constants';
import { getValueByElementId } from '../../shared/audit/print_export_utility';

const FieldGroup = props => (
  <Grid.Column tablet={8} computer={4}>
    <Form.Field>
      <label>
        {props.label}
        {props.required && <span className={props.is_required ? 'req-alert' : 'req-alert_normal'}> (required)</span>}
      </label>
      <DatePicker
        id={props.id}
        name={props.id}
        date_update={props.date_update}
        is_required={props.is_required}
        date={props.date && moment(props.date) ? moment(props.date) : null}
        autoFocus={true}
        styleClass={props.styleClass}
      />
    </Form.Field>
  </Grid.Column>
);

export class ChargeAuditSummaryComponent extends React.Component<any, any> {
  [x: string]: any;
  _is_mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      report_data: [],
      is_grid_visible: false,
      is_show_pop_up: false,
      grid_params: null,
      loading: false,
      is_print_disabled: true,
      is_export_disabled: true,
      current_date: new Date(),
      row_params: {},
      charge_audit_footer_obj: {},
      show_date: false,
      report_rows_count: 0,
      is_error: {},
      is_save_button_disabled: false
    };
  }
  // Define class level variables.
  report_container: any;
  run_report_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
  charge_audit_column_def = report_constants.report_charge_audit_summary.column_defs;
  patient_name: string;
  account_no: string;
  current_date: string;
  title: string;
  report_metadata: any[];
  report_data: any = {
    rows: []
  };
  total_column_width: number = 0;
  grid_header_height: number = 0;

  options_data: any = {
    data_type: [
      {
        value: 'By Date Of Service',
        text: 'Date Of Service',
        selected: false
      },
      {
        value: 'By Created Date',
        text: 'Posting/Created Date',
        selected: false
      }
    ],
    company_id: [],
    practice_id: [],
    location_id: []
  };

  search_criteria: any = {
    company_id: '',
    practice_id: '',
    location_id: '',
    date_type: 'By Created Date',
    from_date: moment(),
    to_date: moment()
  };

  initial_search_criteria: any = {
    company_id: '',
    practice_id: '',
    location_id: '',
    date_type: 'By Created Date',
    from_date: moment(),
    to_date: moment()
  };

  title_search_criteria: any = {
    company_id: '',
    practice_id: '',
    location_id: '',
    date_type: 'Posting/Created Date',
    from_date: moment(),
    to_date: moment()
  };

  label_audit_search_criteria: any = {
    company_id: 'Company_Search',
    practice_id: 'Practice',
    location_id: 'Location',
    date_type: 'Date_Type',
    from_date: 'From_Date',
    to_date: 'Through_Date'
  };

  is_initial_result = false;
  footer_object = {};
  last_col_width = 0;
  grid_width = 0;
  max_inner_width = 0;
  reportId: string = "0";
  // Fetching the report id and params.
  UNSAFE_componentWillMount = () => {
    this.token = this.props.user_login_details.user_data.data.token_details.access_token;
    this.get_dropdown_master_data();
    this.search_criteria = {
      ...this.search_criteria,
      company_id: this.props.user_login_details.user_data.data.company_id
    };

    if (session_storage.get('charge_search_criteria')) {
      this.is_initial_result = true;
      let {from_date, to_date} = session_storage.get('charge_search_criteria')
      this.search_criteria = {
        ...session_storage.get('charge_search_criteria'),
        from_date: moment(from_date),
        to_date: moment(to_date)
      };
    }
    if (!this.is_initial_result)
      this.title_search_criteria = {
        ...this.search_criteria,
        company_id: this.props.user_login_details.user_data.data.company_name
      };
  };

  componentWillUnmount = () => {
    document.body.classList.remove('reports');
    this._is_mounted = false;
    this.search_criteria = {
      ...this.initial_search_criteria
    };
    this.report_data = [];
    this.report_metadata = [];
    session_storage.remove('charge_search_criteria');
  };

  componentDidMount() {
    this._is_mounted = true;
    document.body.classList.add('reports');
    if (this.is_initial_result) {
      this.get_report_data();
    }
  }

  //Get updated dropdown data on dropdown change.
  get_dropdown_master_data = async () => {
    if (this._is_mounted) {
      this.setState({
        re_render: false,
        loading: true
      });
    }
    // Get Master Data
    await report_service.get_updated_dropdown_data([], this.token).then(
      response => {
          //const { Company, Practice, Location } = response.data.data;
          const Company = response.data.data[report_constants.enum_drop_down_type.company];
          const Practice = response.data.data[report_constants.enum_drop_down_type.practice];
          const Location = response.data.data[report_constants.enum_drop_down_type.location];

        this.options_data = {
          ...this.options_data,
          company_id: Company,
          practice_id: Practice,
          location_id: Location
        };

        this.search_criteria = {
          ...this.search_criteria,
          practice_id: Practice && Practice.length > 1 ? '-1' : Practice[0].value,
          location_id: Location && Location.length > 1 ? '-1' : Location[0].value
        };
        let company_name = Company.filter(item => {
          if (item.value == this.search_criteria.company_id) {
            return item.text;
          }
        });
        this.title_search_criteria = {
          ...this.title_search_criteria,
          company_id: company_name && company_name[0] ? company_name[0].text : '',
          practice_id: Practice && Practice.length > 1 ? 'All' : Practice[0].text,
          location_id: Location && Location.length > 1 ? 'All' : Location[0].text,
          date_type: this.search_criteria.date_type,
          from_date: this.search_criteria.from_date,
          to_date: this.search_criteria.to_date
        };

        this.initial_search_criteria = {
          ...this.search_criteria,
          company_id: this.props.user_login_details.user_data.data.company_id
        };

        if (this._is_mounted) {
          this.setState({
            re_render: false
          });
        }
      },
      error => {
        if (this._is_mounted) {
          this.setState({
            loading: false
          });
        }
        log_error(error);
        if (error.data.messages[0].message.indexOf('<br') > -1) {
          this.show_html_content_toaster(error.response.data.messages[0].message);
        } else {
          toaster.error('', error.response.data.messages[0].message);
        }
      }
    );
  };

  //Get updated dropdown data on dropdown change.
  get_updated_dropdown_data = async (name, params) => {
    let new_params = params;
    if (this._is_mounted) {
      this.setState({
        re_render: true
      });
    }
    await report_service.get_updated_dropdown_data(params, this.token).then(
      async response => {
          const Practice = response.data.data[report_constants.enum_drop_down_type.practice];
          const Location = response.data.data[report_constants.enum_drop_down_type.location];
          
        if (name == 'company_id') {
          this.options_data.practice_id = Practice;
          this.search_criteria.practice_id = Practice && Practice.length > 1 ? '-1' : Practice[0].value;
          this.title_search_criteria.practice_id = Practice && Practice.length > 1 ? 'All' : Practice[0].text;
          this.options_data.location_id = Location;
          // New call bassis of first option of practice
          new_params.push(this.search_criteria['practice_id']);
          await report_service.get_updated_dropdown_data(new_params, this.token).then(
            response => {
                //const { Location } = response.data.data;
               const Location = response.data.data[report_constants.enum_drop_down_type.location];
              this.options_data.location_id = Location;
              this.search_criteria.location_id = Location && Location.length > 1 ? '-1' : Location[0].value;
              this.title_search_criteria.location_id = Location && Location.length > 1 ? 'All' : Location[0].text;
            },
            error => {
              if (this._is_mounted) {
                this.setState({
                  loading: false
                });
              }
              log_error(error);
              if (error.data.messages[0].message.indexOf('<br') > -1) {
                this.show_html_content_toaster(error.response.data.messages[0].message);
              } else {
                toaster.error('', error.response.data.messages[0].message);
              }
            }
          );
        }

        if (name == 'practice_id') {
            this.options_data.location_id = Location;
            this.search_criteria.location_id = Location[0].value;
            this.title_search_criteria.location_id = Location[0].text;
        }

        if (this._is_mounted) {
          this.setState({
            re_render: false
          });
        }
      },
      error => {
        if (this._is_mounted) {
          this.setState({
            loading: false
          });
        }
        log_error(error);
        if (error.data.messages[0].message.indexOf('<br') > -1) {
          this.show_html_content_toaster(error.response.data.messages[0].message);
        } else {
          toaster.error('', error.response.data.messages[0].message);
        }
      }
    );
  };

  //Get Report Data.
  get_report_data = async () => {
    // Create Query Param From Field
    this.grid_width = document.getElementsByClassName('report-wrapper')[0].clientWidth;
    let param = Object.keys(this.search_criteria)
      .map(key => {
        if(moment.isMoment(this.search_criteria[key])) {
          return `${key}=${custom_date_format(this.search_criteria[key], 'mm/dd/yyyy')}`
        } else {
          return `${key}=${this.search_criteria[key]}`
        }
      })
      .join('&');

    if (this._is_mounted) {
      this.setState({
        search_criteria: this.search_criteria,
        loading: true,
        is_grid_visible: false,
        is_save_button_disabled: true
      });
    }
    await report_service.get_charge_audit_summary(param, this.token).then(
      response => {
        if (response.data && response.data.data) {
          response.data.data = this.row_data_formatter(response.data.data);
          this.max_inner_width = 0;
          this.report_data['rows'] = response.data.data
            ? response.data.data.map(item => {
                const child_column = report_constants.report_charge_audit_summary.child_column.map(value => ({ ...value }));
                const inner_table_width = charge_get_columns(item.details || [], child_column);
                this.max_inner_width =
                  inner_table_width.total_column_width > this.max_inner_width
                    ? inner_table_width.total_column_width
                    : this.max_inner_width;
                item = {
                  ...item,
                  participants: [
                    {
                      ...item,
                      child_column
                    }
                  ],
                  group: ''
                };
                delete item['details'];
                return item;
              })
            : [];
          this.total_column_width = 0;
          const grid_height = get_columns(
            this.report_data['rows'],
            this.charge_audit_column_def,
            this.state.charge_audit_footer_obj
          );

          this.grid_header_height = grid_height.header_height;
          this.total_column_width = grid_height.total_column_width;

          if (this._is_mounted) {
            this.setState({
              loading: false,
              report_data: this.report_data,
              is_grid_visible: true,
              is_export_disabled: false,
              is_print_disabled: false,
              show_date: true,
              report_rows_count: this.report_data['rows'] && this.report_data['rows'].length,
              is_save_button_disabled: false
            });
          }
        } else {
          log_error(response.data.messages[0].message.indexOf('<br') > -1);
          if (response.data.messages[0].message.indexOf('<br') > -1) {
            const toastr_options = this.show_html_content_toaster(get_all_error(response.data.messages[0].message));
            toaster.error("", toastr_options);
          }
          if (this._is_mounted) {
            this.setState({
              loading: false,
              is_save_button_disabled: false
            });
          }
        }
      },
      error => {
        if (this._is_mounted) {
          this.setState({
            loading: false,
            is_save_button_disabled: false
          });
        }
        log_error(error);
        if (error.response.data) {
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error("", toastr_options);
        }
      }
    );
  };

  //Calender change handler.
  calendar_change_handler = (name, date_object) => {
    this.search_criteria[name] = date_object ? custom_date_format(date_object._d, 'mm/dd/yyyy') : '';
    this.title_search_criteria[name] = date_object ? custom_date_format(date_object._d, 'mm/dd/yyyy') : '';
    if (this.state.is_error[name] !== '') {
      if (this._is_mounted) {
        this.setState(prev_state => ({
          [`is_${name}`]: moment.isMoment(date_object) ? false : true,
          is_error: {
            ...prev_state.is_error,
            [name]: moment.isMoment(date_object) ? false : true
          }
        }));
      }
    }
  };

  //Dropdown change handler.
  dropdown_change_handler = event => {
    const { id, value } = event.target;
    this.search_criteria[id] = value;
    this.title_search_criteria[id] = event.target.selectedOptions
      ? event.target.selectedOptions[0].outerText
      : event.target.options[event.target.selectedIndex].text;

    if (id === 'company_id') {
      this.update_control_type(1, id);
    } else if (id === 'practice_id') {
      this.update_control_type(2, id);
    }

    if (this._is_mounted) {
      this.setState({
        is_reset: false
      });
    }
    if (this.state.is_error[id] !== '') {
      if (this._is_mounted) {
        this.setState(prev_state => ({
          is_error: {
            ...prev_state.is_error,
            [id]: false
          }
        }));
      }
    }
  };

  //Update controls on page load.
  update_control_type = (sequence, name) => {
    let params = [];
    if (sequence == 1 && name === 'company_id') {
      params.push(this.search_criteria['company_id']);
    } else if (sequence == 2 && name === 'practice_id') {
      params.push(this.search_criteria['company_id']);
      params.push(this.search_criteria['practice_id']);
    }
    this.get_updated_dropdown_data(name, params);
  };

  //Required Field Validator.
  required_field_validator = () => {
    let is_error = false;
    let from_date: any = moment(this.search_criteria.from_date).startOf('day');
    let to_date: any = moment(this.search_criteria.to_date).endOf('day');
    if (moment(this.search_criteria.from_date).isValid() && moment(this.search_criteria.to_date).isValid()) {
      if (from_date <= to_date) {
        if ((to_date - from_date) / 86400000 >= 31) {
          is_error = true;
          toaster.error('', report_constants.messages.date_rage_validation);
        }
      } else {
        is_error = true;
        toaster.error('', report_constants.messages.date_validation);
      }
    } else {
      if (!this.search_criteria.from_date && this._is_mounted) {
        is_error = true;
        this.setState(prev_state => ({
          is_error: {
            ...prev_state.is_error,
            from_date: true
          }
        }));
        toaster.error('', report_constants.messages.mandatory_fields);
      }
      if (!this.search_criteria.to_date && this._is_mounted) {
        is_error = true;
        this.setState(prev_state => ({
          is_error: {
            ...prev_state.is_error,
            to_date: true
          }
        }));
        toaster.error('', report_constants.messages.mandatory_fields);
      }
    }
    return is_error;
  };

  //Function used to Run Report based on controllers values.
  run_report_handler = e => {
    var is_error = false;
    if (this._is_mounted) {
      this.setState({ is_submitted: true });
    }

    // Validate Required Field
    is_error = this.required_field_validator();
    if (is_error) {
      return;
    }
    this.get_report_data();
    session_storage.set('charge_search_criteria', this.search_criteria);
    this.run_report_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  //Function used to initialize the footer object.
  initialize_footer_obj = column_def => {
    var footer_obj = {};
    column_def.map((value, index) => {
      if (value.field !== 'group') {
        if (index == 1) {
          footer_obj[value.field] = 'Total';
        } else if (value.type == 'currency' || value.type == 'number') {
          footer_obj[value.field] = 0;
        } else {
          footer_obj[value.field] = null;
        }
      }
    });
    return footer_obj;
  };

  //FUnction use to format the grid data on load.
  row_data_formatter = data => {
    var footer_obj = this.initialize_footer_obj(this.charge_audit_column_def);
    data.map(row => {
      // Formate Inner grid Row
      row['details'] = row.details && row.details.map((item) => {
        return (item = {
            proc_code: item.proc_code || "",
            units: item.units != null || item.units != undefined ? parseInt(item.units) : 0,
            proc_mod1: item.proc_mod1 || "",
            proc_mod2: item.proc_mod2 || "",
            charge_amount:
                item.charge_amount != null || item.charge_amount != undefined
                    ? report_constants.currencyFormatter(item.charge_amount)
                    : report_constants.currencyFormatter(0),
            billing_provider: item.billing_provider || "",
            treating_provider: item.treating_provider || "",
            pos_name: item.pos_name || "",
            pri_insurance: item.pri_insurance || "",
            sec_insurance: item.sec_insurance || "",
            ter_insurance: item.ter_insurance || "",
            dx1: item.dx1 || "",
            dx2: item.dx2 || "",
            dx3: item.dx3 || "",
            dx4: item.dx4 || "",
            is_active: item.is_active ? "Active" : "Inactive"
        });
      });
      this.charge_audit_column_def.map((value, index) => {
        switch (value.type) {
          case 'date':
            row[value.field] =
              row[value.field] != null || row[value.field] != undefined
                ? report_constants.dateFormatter(row[value.field])
                : '';
            break;
          case 'currency':
            footer_obj[value.field] += row[value.field] ? parseFloat(row[value.field]) : 0;
            row[value.field] =
              row[value.field] != null || row[value.field] != undefined
                ? report_constants.currencyFormatter(row[value.field])
                : report_constants.currencyFormatter(0);
            break;
          case 'number':
                footer_obj[value.field] += row[value.field] ? parseInt(row[value.field]) : 0;
                if (value.field == 'visit_count') {
                    row[value.field] =
                        row[value.field] != null || row[value.field] != undefined ? parseInt(row[value.field]) : null;
                } else {
                row[value.field] =
                    row[value.field] != null || row[value.field] != undefined ? parseInt(row[value.field]) : 0;}
           
            break;
          case 'boolean':
            row[value.field] = row[value.field] ? 'Active' : 'Inactive';
            break;
          default:
            row[value.field] =
              row[value.field] != null || row[value.field] != undefined
                ? row[value.field] === row.is_active
                  ? this.capitalize_text(row[value.field].toString().trim())
                  : row[value.field].toString().trim()
                : '';
        }
      });
      return row;
    });

    this.charge_audit_column_def.map((value, index) => {
      if (value.type == 'currency') {
        footer_obj[value.field] = report_constants.currencyFormatter(footer_obj[value.field]);
      }
    });
    if (this._is_mounted) {
      this.setState({ charge_audit_footer_obj: { ...footer_obj } });
    }
    this.footer_object = footer_obj;
    return data;
  };

  capitalize_text = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  //Function calls on click of Clear button and clear all the searched result as well as criteria.
  clear_handler = () => {
    if (this._is_mounted) {
      this.setState({
        loading: true,
        is_grid_visible: false
      });
    }
    this.get_dropdown_master_data();
    this.search_criteria = {
      ...this.initial_search_criteria,
      date_type: 'By Created Date',
      from_date: moment(),
      to_date: moment()
    };
    this.title_search_criteria = {
      company_id: this.props.user_login_details.user_data.data.company_name,
      practice_id: '',
      location_id: '',
      date_type: 'Posting/Created Date',
      from_date: moment(),
      to_date: moment()
    };

    this.report_data = [];
    this.report_metadata = [];
    this.is_submitted = false;
    session_storage.remove('charge_search_criteria');
    setTimeout(() => {
      if (this._is_mounted) {
        this.setState({
          report_data: [],
          is_grid_visible: false,
          is_export_disabled: true,
          is_print_disabled: true,
          show_date: false,
          loading: false,
          is_start_date: false,
          is_end_date: false,
          is_error: {
            start_date: false,
            end_date: false
          }
        });
      }
    }, 400);
  };

  // Show multiple messages
  show_html_content_toaster = msg => {
    return {
      component: () => (
        <div>
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        </div>
      ),
      timeOut: global_constants.toastr_options.toastr_time_out,
      preventDuplicates: true
    };
  };

  //Function calls after click of filter button and recalculate the footer sum.
  on_filter_button_click = () => {
    var footer_obj = this.initialize_footer_obj(this.charge_audit_column_def);
    var row_api = this.state.grid_params.api;
    var footer_cell_data;
    let row_count = 0; // Update row count
    if (row_api) {
      row_api.forEachNodeAfterFilter((row, index) => {
        if (row.group) {
          row_count++;
          row = row.data;
          this.charge_audit_column_def.map((value, index) => {
            if (value.field !== 'group' && row[value.field]) {
              switch (value.type) {
                case 'currency':
                  if (row[value.field].indexOf('-') != -1) {
                    footer_cell_data = row[value.field] ? '-' + row[value.field].replace(/[$,()]/g, '') : 0;
                  } else {
                    footer_cell_data = row[value.field] ? row[value.field].replace(/[$,()]/g, '') : 0;
                  }
                  footer_obj[value.field] += isNaN(parseFloat(footer_cell_data)) ? 0 : parseFloat(footer_cell_data);
                  break;
                case 'number':
                  footer_obj[value.field] += row[value.field] ? parseInt(row[value.field]) : 0;
                  break;
                default:
              }
            }
          });
        }
      });

      this.charge_audit_column_def.map((value, index) => {
        if (value.field !== 'group' && value.type == 'currency') {
          footer_obj[value.field] = report_constants.currencyFormatter(footer_obj[value.field]);
        }
      });

      this.footer_object = footer_obj;
      if (row_api.getPinnedBottomRow(0)) {
        row_api.setPinnedBottomRowData([footer_obj]);
      }
    }
    // Update row count
    this.setState({
      report_rows_count: row_count //this.state.grid_params.api.getModel().getRowCount()
    });
  };

  //Function calls on intialization of Ag-Grid and catch its reference.
  get_grid_ref = grid_params => {
    if (this._is_mounted) {
      this.setState({
        grid_params,
        report_rows_count: grid_params.api.getModel().getRowCount()
      });
    }
    if (this.total_column_width > this.grid_width) {
      this.charge_audit_column_def.filter((item, index) => {
        grid_params.columnApi.setColumnWidth(item.field, item['width'], false);
      });
    }
  };

  //Used to create the title for export and print.
  set_title = separter => {
    this.current_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
    return (this.title =
      'Charge Audit Summary' +
      separter +
      'Company: ' +
      this.title_search_criteria.company_id +
      separter +
      'Practice: ' +
      this.title_search_criteria.practice_id +
      separter +
      'Location: ' +
      this.title_search_criteria.location_id +
      separter +
      'Date Type: ' +
      this.title_search_criteria.date_type +
      separter +
      custom_date_format(this.title_search_criteria.from_date, 'mm/dd/yyyy') +
      ' - ' +
      custom_date_format(this.title_search_criteria.to_date, 'mm/dd/yyyy') +
      separter +
      this.current_date +
      separter +
      (this.state.report_rows_count == 1
        ? this.state.report_rows_count + ' record'
        : this.state.report_rows_count + ' records') +
      separter +
      separter);
  };

  //Function calls on initialization of export report data
  on_export_button = () => {
    this.title = this.set_title('\n');
    export_print_utility.export_grid_data(this.state.grid_params, this.title, 'Charge Audit Summary');
    this.saveReportEventAction(ReportConstants.ReportEventAction.Export);
  };

  //Function calls on initialization of Print report data
  on_print_button = () => {
    this.title = this.set_title('<br/>');
    export_print_utility.print_grid_data(this.state.grid_params, this.title, 'Charge Audit Summary');
    this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
  };

  private saveReportEventAction = (eventActionId: number) => {
    const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;

    if (this.props.magnusPlat1731BillingCharges) {
      const dataEntry: DataEntry = {
        reportId: this.reportId,
        Records: this.state.report_rows_count
      }
      this.getAuditSearchCriteria(dataEntry, false);

      const viewableEntry: DataEntry = {};
      this.getAuditSearchCriteria(viewableEntry, true);
      const labelRecords = `Total_Records_${eventActionId == ReportConstants.ReportEventAction.Print ? "Printed" : "Exported"}`;
      viewableEntry[labelRecords] = this.state.report_rows_count;

      const data: PrintExportConstants.IPrinExportDto = {
        entityTypeId: PrintExportConstants.EntityTypeId.ChargesAuditSummary,
        auditDataEntry: dataEntry,
        viewableDataEntry: viewableEntry
      };

      PrintExportConstants.savePrintExportAudit(data, accessToken, eventActionId);
    }
    else {
      const { user_id, company_id } = this.props.user_login_details.user_data.data;

      const payload: ReportConstants.IPayloadForBillingAudit = {
        reportId: this.reportId,
        contextTitle: `Charge Audit Summary - ${ReportConstants.ReportEventAction[eventActionId]}`,
        eventActionId: eventActionId,
        userId: Number(user_id),
        companyId: Number(company_id),
        entityTypeId: ReportConstants.ReportEntityTypeId.ChargeAudit,
        data: { Records: this.state.report_rows_count }
      }

      const reqBody = export_print_utility.generateAuditDataEntryPayload(payload);
      ReportConstants.saveAuditPrintExport(reqBody, accessToken);
    }
  };

  private getAuditSearchCriteria = (data: DataEntry, isViewable: boolean) => {
    Object.keys(this.search_criteria).map(key => {
      const labelName = isViewable ? this.label_audit_search_criteria[key] : key;
      if (moment.isMoment(this.search_criteria[key]))
        data[labelName] = custom_date_format(this.search_criteria[key], global_constants.date_format['mm/dd/yyyy']);
      else
        data[labelName] = isViewable ? getValueByElementId(key) : this.search_criteria[key];
    });
  }

  handle_navigation_after_last_element = event => {
    
    // apply a check where grid is empty
    if (
      !event.shiftKey &&
      event.keyCode == '9' &&
      (this.state.report_data == null || this.state.report_data.length == 0)
    ) {
      set_focus_to_app_header(event);
    }
  };

  dateRangeHandler = (fromDate: any, toDate: any) => {
    this.search_criteria['from_date'] = fromDate;
    this.search_criteria['to_date'] = toDate;
    this.title_search_criteria['from_date'] = fromDate;
    this.title_search_criteria['to_date'] = toDate;
    const is_from_date_not_empty = this.state.is_error['from_date'] !== ''
    const is_to_date_not_empty = this.state.is_error['to_date'] !== ''

    if(this._is_mounted){
      if (is_from_date_not_empty) {
          this.setState(prev_state => ({
            is_error: {
              ...prev_state.is_error,
              ['from_date']: moment.isMoment(fromDate) ? false : true
            }
          }));
      }
      if (is_to_date_not_empty) {
          this.setState(prev_state => ({
            is_error: {
              ...prev_state.is_error,
              ['to_date']: moment.isMoment(toDate) ? false : true
            }
          }));
      }
    }
  };

  on_group_by_Change = (fields) => {
      if (this._is_mounted) {
        let row_count = 0;
          if (fields && fields.length > 0) {
              this.state.grid_params.api.forEachNodeAfterFilter((row, index) => {
                  if (row.group && row.level == 0) {
                      row_count++;
                  }
              });
          } else {
              row_count = this.state.grid_params.api.getModel().getRowCount();
          }
          this.setState({
              report_rows_count: row_count
          });
          this.on_resizing_group_column();
      }
  };

  on_cell_mouse_down=()=>{
    this.on_resizing_group_column();
  }

  on_resizing_group_column(){
      let grid = document.getElementById("accordian_grid_charge_audit_summary")
      if(grid && (grid.getElementsByTagName("table") || []).length == 0){
          this.state.grid_params.columnApi.autoSizeColumns(['group']);
      }
  }

  render() {
    return (
      <div className={'common-forms-add'}>
        <Dimmer active={this.state.loading}>
          <Loader size='massive'>Loading</Loader>
        </Dimmer>
        <div className={'common-forms-search report-framework'}>
          <ReportHeader title={'Charge Audit Summary'} />
          <div
            id='report-scrollable-area'
            className='report-wrapper'
            style={this.state.is_grid_visible ? { flex: 1 } : {}}
          >            
            <div className='patient-search-form  patient_search_bottom_padding' id='report-criteria-container'>
              <Form autoComplete='off'>
                <Grid>
                  <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                      <label>Company</label>
                      <SelectionComponent
                        id='company_id'
                        name='company_id'
                        options={this.options_data.company_id}
                        onChange={(value, event) => {
                          this.dropdown_change_handler(event);
                        }}
                        defaultValue={this.search_criteria.company_id}
                        style={'dropdown-options-wrap'}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                      <label>Practice</label>
                      <SelectionComponent
                        id='practice_id'
                        name='practice_id'
                        options={this.options_data.practice_id}
                        onChange={(value, event) => {
                          this.dropdown_change_handler(event);
                        }}
                        defaultValue={this.search_criteria.practice_id}
                        style={'dropdown-options-wrap'}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                      <label>Location</label>
                      <SelectionComponent
                        id='location_id'
                        name='location_id'
                        options={this.options_data.location_id}
                        onChange={(value, event) => {
                          this.dropdown_change_handler(event);
                        }}
                        defaultValue={this.search_criteria.location_id}
                        style={'dropdown-options-wrap'}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                      <label>Date Type</label>
                      <SelectionComponent
                        id='date_type'
                        name='date_type'
                        options={this.options_data.data_type}
                        onChange={(value, event) => {
                          this.dropdown_change_handler(event);
                        }}
                        defaultValue={this.search_criteria.date_type}
                        style={'dropdown-options-wrap'}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                      <label>Date Range <span className={(this.state.is_error.from_date || this.state.is_error.to_date) && this.state.is_submitted?'req-alert':'req-alert_normal'}>(required)</span></label>
                      <DateRangePickerComponent
                        updateDatesChange={this.dateRangeHandler}
                        startDate={this.search_criteria.from_date}
                        endDate={this.search_criteria.to_date}
                        error={(this.state.is_error.from_date || this.state.is_error.to_date) && this.state.is_submitted}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column tablet={16} computer={16} textAlign='right'>
                    <Button type='reset' id='clear_report_button' basic onClick={this.clear_handler}>
                      Clear
                    </Button>
                    <Button
                      id='create_report_button'
                      className='primary'
                      onClick={this.run_report_handler}
                      type='submit'
                      onKeyDown={this.handle_focus_for_grid}
                      style={{ marginRight: '0' }}
                      disabled={this.state.is_save_button_disabled}
                    >
                      Search
                    </Button>
                  </Grid.Column>
                </Grid>
              </Form>
            </div>
            {this.state.is_grid_visible && (
              <React.Fragment>
                <Grid className='dateTime'>
                  <Grid.Column tablet={5} computer={4} textAlign='left'>
                    <p style={{ fontSize: '16px', minHeight: 22 }}>{this.run_report_date}</p>
                  </Grid.Column>
                  <Grid.Column tablet={5} computer={8} textAlign='center'>
                    {
                      <p style={{ fontSize: '16px', minHeight: 22 }}>
                        {`${this.state.report_rows_count} ${
                          this.state.report_rows_count == 1 ? ' record shown' : ' records shown'
                        }`}
                      </p>
                    }
                  </Grid.Column>
                  <Grid.Column tablet={2} computer={4} textAlign='right' />
                </Grid>
                <GridView
                  id={report_constants.report_charge_audit_summary.report_grid_id}
                  row={this.report_data.rows}
                  column={this.charge_audit_column_def}
                  headerHeight={this.grid_header_height}
                  style={{ height: '100%', display: 'flex', flex: 1, flexDirection: 'column' }}
                  enableColResize={true}
                  emptyMessage={report_constants.messages.no_records_found}
                  get_grid_ref={this.get_grid_ref}
                  headerIdForTabNavigation={report_constants.report_ledger_full_configuration_header_id}
                  pinnedBottomRowData={[this.footer_object]}
                  on_filter_button_click={() => {
                    this.on_filter_button_click();
                                }}
                  //wrapperClass={'grid_wrapper accordian_grid'}
                  detailsName='details'
                  rowGroupPanelShow={true}
                  suppressMovableColumns={true}
                  onGroupByChange={this.on_group_by_Change}
                  isGroupColumnAlwaysVisible={true}
                  onCellMouseDown={this.on_cell_mouse_down}
                  suppressColumnVirtualisation={true}
                />
              </React.Fragment>
            )}
          </div>
          <div
            className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
            id='applicationFooterSticky'
          >
            <ReportFooter
              on_print_button={() => this.on_print_button()}
              on_export_button={() => this.on_export_button()}
              export_disabled={this.state.is_export_disabled}
              print_disabled={this.state.is_print_disabled}
            />
          </div>
        </div>
      </div>
    );
  }
}

//Get user and login details from store.
const mapStateToProps = state => {
  return {
    user_login_details: state.user_login_details,
    selected_patient: state.patient_details.patient_header,
    is_expand: state.shared_details.is_expand,
    magnusPlat1731BillingCharges: get_lauch_darkley_key_value(state.launch_darkly, ChargesBillingAuditLogEpicsFeature.magnusPlat1731BillingCharges)
  };
};

export default connect(mapStateToProps)(ChargeAuditSummaryComponent);
