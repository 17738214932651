import * as React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { Button, Dimmer, Form, Grid, Loader, Modal } from "semantic-ui-react";
import { clearTimeout, setTimeout } from 'timers';
import { generate_barcode } from "../../shared/action/barcode_action";
//Sub-Layouts
import GridView from "../../shared/component/grid";
import { modal_hide_on_print, modal_show_after_print, modal_show_after_print_ios, execute_print, print_html_data_to_pdf } from "../../shared/utility";
import { get_patient_form_data } from "../action/patient_action";
import * as patient_constants from "../patient_constants";
import { set_focus_on_element_with_id, handle_focus_on_tab } from "../../shared/tab_navigation_utility";

/*
1) Display Print Patient Forms in View Patient
2) Print Checked Documents
*/
export class PrintPatientFormsComponent extends React.Component<any, any> {
    [x: string]: any;

    _is_mounted = false;
    constructor(props) {
        super(props);

        this.state = {
            shown: true,
            selected_patient_forms: [],
            patient_id: this.props.patient_id,
            loading: false,
            show_modal: false,
            print_form_list: [],
            print_image: {
                current_page: 1,
                total_pages: 0
            },
            disable_previous_button: false,
            disable_next_button: false,
            patient_forms_grid_ref: {}
        };
    }

    componentDidMount() {
        this._is_mounted = true;
        // Get Patient Form Data
        this.props.get_patient_form_data(this.props.user_login_details.user_data.data.token_details.access_token);
    }
   
    componentWillUnmount() {
        this._is_mounted = false;
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
        }
        if (this.state.timeout_print) {
            clearTimeout(this.state.timeout_print);
        }
    }
    componentDidUpdate(previousProps, previousState) {
        // Remove check box selection on patient change
        if (previousProps.patient_id != this.props.patient_id) {
            if (this._is_mounted) {
                this.setState({
                    selected_patient_forms: []
                });

            }
        }
        if (previousProps.show_print_patient_form != this.props.show_print_patient_form) {
            if (this._is_mounted) {
                this.setState({ shown: !this.state.shown });
            }
        }
        //account notes clear selection
        //if (previousProps.print_form_fatch_time != this.props.print_form_fatch_time) {
        //    setTimeout(()=> {
        //        if (this.state.patient_forms_grid_ref != null && this.state.patient_forms_grid_ref != undefined) {
        //            this.state.patient_forms_grid_ref.api.forEachNode(node => {
        //                node.setSelected(false);
        //            });
        //        }
        //    }, 200);
        //}
    }

  // toggle to show and hide Print Patient Forms Component
    private toggle = () => {
        if (this._is_mounted) {
            this.setState({
                shown: !this.state.shown
            });
        }
  };

  // This is a comparison function that will result in dates being sorted in
  // DESCENDING order.
  date_sort_desc = (obj1, obj2) => {
    if (new Date(obj1.created_date) > new Date(obj2.created_date)) return -1;
    if (new Date(obj1.created_date) < new Date(obj2.created_date)) return 1;
    return 0;
    };

    //get_patient_form_data
    //private get_patient_form_data = () => {
    //    let _this = this;
    //    get_patient_form_data(this.props.user_login_details.user_data.data.token_details.access_token).then(
    //        async response => {
    //            let patient_forms = response.data.data;
    //            let patient_forms_length = patient_forms && patient_forms.length ? patient_forms.length : 0;

    //            if (patient_forms_length > 0) {
    //                patient_forms = patient_forms.sort(this.date_sort_desc);
    //                let formatted_patient_forms = [];
    //                for (let form of patient_forms) {
    //                    form["created_date"] =
    //                        form["created_date"] == null ? null : custom_date_format(form["created_date"], date_format["mm/dd/yyyy h:MM:ss TT"]);
    //                }

    //                _this.setState({
    //                    loading: false,
    //                    patient_forms: patient_forms
    //                });
    //            } else {
    //                _this.setState({
    //                    loading: false,
    //                    patient_forms: []
    //                });
    //            }
    //        },
    //        function (error) {
    //            _this.setState({ loading: false });
    //            if (error.response && error.response.data && error.response.data.messages) {
    //                toastr.error("", error.response.data.messages[0].message);
    //            }
    //        }
    //    );
    //};

  // Click functin of checkbox
    on_check_box_selection = items => {
        if (this._is_mounted) {
            this.setState({
                selected_patient_forms: items
            });
        }
  };

  // Click functin of 'Print Checked Documents' on the patient View Page
  on_print_patient_forms = () => {
    if (this.state.selected_patient_forms.length == 0) {
      toastr.warning("", patient_constants.patient_messages.select_record);
    } else {
        let _this = this;
        if (this._is_mounted) {
            _this.setState({
                loading: true
            });
        }

      let patient_form_checked_ids = [];
      for (let value of this.state.selected_patient_forms) {
        patient_form_checked_ids.push(value.image_packet_id);
      }

      generate_barcode(this.props.user_login_details.user_data.data.token_details.access_token, this.state.patient_id, patient_form_checked_ids).then(
        async response => {
          let print_form_list = response.data.data;
          let print_form_list_length = print_form_list && print_form_list.length ? print_form_list.length : 0;
            if (print_form_list_length > 0) {
                if (_this._is_mounted) {
                    this.handle_navigation_on_modal();
                    _this.setState({
                        loading: false,
                        show_modal: true,
                        print_form_list: print_form_list,
                        selected_print_form: 0,
                        print_image: {
                            current_page: 1,
                            total_pages: print_form_list_length
                        },
                        disable_previous_button: true,
                        disable_next_button: print_form_list_length > 1 ? false : true
                    });
                }
          } else {
                toastr.warning("", patient_constants.patient_messages.no_image_found);
                if (_this._is_mounted) {
                    _this.setState({
                        loading: false
                    });
                }
          }
        },
          function (error) {
              if (_this._is_mounted) {
                  _this.setState({ loading: false });
              }
          if (error.response && error.response.data && error.response.data.messages) {
            toastr.error("", error.response.data.messages[0].message);
          }
        }
      );
    }
  };

  // Print functionality of popup
  on_popup_print_click = () => {
    var printContent = "";
    for (let value of this.state.print_form_list) {
      printContent += '<div style="width:100%; "><div style="text-align:center; position:relative; height:100%"> ';
      printContent += '<img src="data:image/png;base64,' + value.encoded_image + '"style="max-width: 100%; max-height:900px;" /> <br />';
      printContent += '<div style="max-width: 100%; position:absolute; bottom:40px;  display:block; width:100%; text-align:center;">';
      printContent += '<img src="data:image/png;base64,' + value.encoded_barcode + '" /></div> <br />';
      printContent +=
        '<span style="position:absolute; bottom:10px; display:block; width:100%; text-align:center;">' +
        this.props.patient_full_name +
        "</span><br />";
      printContent += `</div></div>`;
      printContent += `<footer style="text-align: center;"></footer>`;
    }
    print_html_data_to_pdf(printContent, 'printButton','Patient Forms');
    /*
    modal_hide_on_print(printContent);
    if (window.print) {
        if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
            if (this._is_mounted) {
                this.setState({
                    timeout: setTimeout(() => {
                      execute_print();
                    }, 100),
                    timeout_print: setTimeout(() => {
                      modal_show_after_print_ios();
                    }, 5000)
                });
            }
      } else {
        if (window.matchMedia) {
          var mediaQueryList = window.matchMedia('print');
          mediaQueryList.addListener(function(mql) {
              if (mql.matches) {
              } else {
                  afterPrint();
              }
          });
        }
        var afterPrint = () =>  modal_show_after_print();
        // These are for Firefox
        window.onafterprint = afterPrint;
        execute_print();
      }
    } */
    return;
  };

  // open close modal popup. (toggle state)
    toggle_modal_state = () => {
        if (this._is_mounted) {
            if (!this.state.show_modal) {
                this.handle_navigation_on_modal();
            } else {
                set_focus_on_element_with_id("print_selected_docs_button");
            }

            this.setState({
                show_modal: !this.state.show_modal
            });
        }
    };

    handle_navigation_on_modal = () => {
        setTimeout(() => {
            let closeIcons: NodeListOf<HTMLElement> = document.querySelectorAll('i.close.icon:not(.auto-search-icon)') as NodeListOf<HTMLElement>;
            let closeIcon = closeIcons[closeIcons.length - 1]
            if (closeIcon) {
                closeIcon.tabIndex = 0;
                closeIcon.id = "printModalCloseIcon";
                closeIcon.addEventListener("keydown", function (event) {
                    if (event.shiftKey && event.keyCode == 9) {
                        event.preventDefault();
                        set_focus_on_element_with_id("printButton");
                    }

                    if (event.keyCode == 13) {
                        event.preventDefault();
                        var el = event.target as HTMLElement
                        el.click();
                    }
                });
            }

            set_focus_on_element_with_id("printModalCloseButton");
        }, 0)
    }
  // Next/Previous button click
  set_print_form = type => {
    if (type == "next") {
      let selected_print_form =
        this.state.selected_print_form + 1 < this.state.print_form_list.length
          ? this.state.selected_print_form + 1
          : this.state.print_form_list.length - 1;
      let current_page =
        this.state.print_image.current_page + 1 < this.state.print_form_list.length
          ? this.state.print_image.current_page + 1
                : this.state.print_form_list.length;
        if (this._is_mounted) {
            this.setState({
                selected_print_form: selected_print_form,
                disable_next_button: current_page == this.state.print_form_list.length ? true : false,
                disable_previous_button: current_page != 1 ? false : true,
                print_image: {
                    current_page: current_page,
                    total_pages: this.state.print_form_list.length
                }
            });
        }
    } else if (type == "previous") {
      let selected_print_form = this.state.selected_print_form - 1 > 0 ? this.state.selected_print_form - 1 : 0;
        let current_page = this.state.print_image.current_page - 1 > 1 ? this.state.print_image.current_page - 1 : 1;
        if (this._is_mounted) {
            this.setState({
                selected_print_form: selected_print_form,
                disable_previous_button: current_page == 1 ? true : false,
                disable_next_button: current_page != this.state.print_form_list.length ? false : true,
                print_image: {
                    current_page: current_page,
                    total_pages: this.state.print_form_list.length
                }
            });
        }
    }
  };

  //Function calls on intialization of Ag-Grid and catch its reference.
    get_patient_forms_grid_ref = grid_params => {
        if (this._is_mounted) {
            this.setState({ patient_forms_grid_ref: grid_params });
        }
  };

    render() {

      let hidden = { display: this.state.shown ? "none" : "block" };
      let is_loading = this.props.print_form_loading;
      if (this.state.loading == true) {
          is_loading = this.state.loading;
      }

    return (
      <React.Fragment>
        <div className="common-forms-view section-margin">
          <div className="content active">
            <Dimmer active={is_loading}>
              <Loader size="massive">Loading</Loader>
            </Dimmer>
            <Grid.Column>
              <GridView
                id={'id_grid_print_patient_form'}
                row={this.props.print_patient_form_data}
                column={patient_constants.print_patient_forms_configuration.column_defs}
                headerHeight={patient_constants.print_patient_forms_configuration.grid_header_height}
                radioSelection={true}
                gridAutoHeight={true}
                onRowSelection={this.on_check_box_selection}
                get_grid_ref={this.get_patient_forms_grid_ref}
                emptyMessage={this.props.empty_message}
                enableEnterOnNavigation={true}
               />
            </Grid.Column>
            <Grid.Column computer={16} style={{ textAlign: "right" }} className="mar-t-15">
              <Button id="print_selected_docs_button" primary onClick={() => this.on_print_patient_forms()}>
                Print Selected Documents
              </Button>
            </Grid.Column>
          </div>
        </div>
        <Modal
          open={this.state.show_modal}
          onClose={this.toggle_modal_state}
          centered={false}
          className="add-new-note print-preview-modal"
          closeIcon
          closeOnDimmerClick={false}
          id="printPopup"
        >
          <Modal.Header>Print Preview</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Grid style={{ marginTop: "20px" }}>
                <Form className="sixteen wide computer column">
                  <Grid>
                    <Grid.Column computer={16} style={{ textAlign: "center" }} className="mar-t-15">
                      {this.state.print_form_list.length > 0 && (
                        <React.Fragment>
                          <img
                            className="print-preview-img"
                            src={"data:image/png;base64," + this.state.print_form_list[this.state.selected_print_form].encoded_image}
                          />
                          <br />
                          <img style ={this.state.print_form_list[this.state.selected_print_form].encoded_barcode === null?{visibility:"hidden" }:{ visibility:"visible"}} src={"data:image/png;base64," + this.state.print_form_list[this.state.selected_print_form].encoded_barcode} />
                          <br />
                          <label>{this.props.patient_full_name}</label>
                          <br />
                          <span>Page</span>
                          <br />
                          <span>
                            {this.state.print_image.current_page}/{this.state.print_image.total_pages}
                          </span>
                        </React.Fragment>
                      )}
                    </Grid.Column>
                  </Grid>
                </Form>
                <Grid.Column computer={16} style={{ textAlign: "right" }} className="mar-t-15">
                  {this.state.print_form_list.length > 1 && (
                    <React.Fragment>
                      <Button basic disabled={this.state.disable_previous_button} onClick={() => this.set_print_form("previous")}>
                        Previous
                      </Button>
                      <Button basic disabled={this.state.disable_next_button} onClick={() => this.set_print_form("next")}>
                        Next
                      </Button>
                    </React.Fragment>
                  )}
                  <Button id="printModalCloseButton" basic onClick={() => this.toggle_modal_state()}>
                    Close
                  </Button>
                  <Button id="printButton" primary onClick={() => this.on_popup_print_click()} onKeyDown={(event) => { handle_focus_on_tab(event, "printModalCloseIcon") }}>
                    Print
                  </Button>
                </Grid.Column>
              </Grid>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        get_patient_form_data: get_patient_form_data
    }, dispatch)
}

const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        patient_details: state.patient_details,
        print_patient_form_data: state.patient_details.print_patient_form_data,
        empty_message: state.patient_details.empty_message,
        print_form_loading: state.patient_details.print_form_loading,
        print_form_fatch_time: state.patient_details.print_form_fatch_time
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrintPatientFormsComponent));
