import * as global_constants from '../../../global_constants';
import { RequestConfig } from '../../../shared/network/config';
import { Request } from '../../../shared/network/request';

export const get_patient_account_notes = (token, patient_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.delete_account_notes.get_patient_account_notes}/${patient_id}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

export const inactivate_patient_account_notes = (token, patient_id, data) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.delete_account_notes.inactivate_patient_account_notes}/${patient_id}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'put',
        data: data
    });

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};