import * as React from "react";
import { connect } from "react-redux";
import Advanced_control from '../../../shared/component/advanced_control';
import * as session_storage from '../../../shared/session_storage_utility';
import AutoSearchComponent from './../../../shared/component/auto_search_component';

interface IProps {
    control: { label: string, type: string },
    update_data: Function,
    on_quick_search: Function,
    on_advanced_search: Function,
    search_result: object,
    selected_row?: object,
    is_disabled?: boolean,
    className?: string,
    onRef?: Function,
    is_focused?: boolean,
    addClicks?: Function,
    hide_modal?: boolean,
    headerIdForGridTabNavigation?: string
}


class ClaimAdjustmentCodeSearch extends React.Component<IProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            patient_id: session_storage.get("active_patient"),
            control: this.props.control,
            loading: false,
            is_submitted: false,
            grid_config: null,
            company_details: null,
            control_data: null,
            selected_row: {},
            label_list: null,
            advanced_modal_state : false
        };
    }
    instance = null;
    UNSAFE_componentWillMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }
    componentDidUpdate(prevProps, prevState) {

        if (prevProps.control != this.props.control) {
            this.setState({
                control: this.props.control, label_list: this.props.control.label,
                grid_config: {
                    [this.props.control.type]: {
                        column: null,
                        rows: null
                    }
                },
                selected_row: { [this.props.control.type]: {} }});
        }

        if (prevProps.is_focused != this.props.is_focused) {
            if (!this.props.is_focused) {
                //this.instance.blur();
            }
            
        }
        if (prevProps.selected_row !== this.props.selected_row) {
            let type = this.props.control.type;
            let row = {};
            if (this.props.selected_row) {
                let label = this.get_formatted_label(this.props.control.label, this.props.selected_row);
                row = { "label": label, ...this.props.selected_row };
            }
            this.setState({ selected_row: { ...this.state.selected_row, [type]:row}})
        }

        if (prevProps.search_result != this.props.search_result) {
            let type = this.state.control.type;
            this.setState({
                grid_config: {
                    [type]: { ...this.props.search_result }
                },
            });
        }

        if (prevProps.hide_modal !== this.props.hide_modal) {
            this.setState({ advanced_modal_state: false})
        }
        
    }
    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(undefined)
        }
    }
    on_quick_search = (params ,type) => {
       return this.props.on_quick_search(params ,type);
    }
// formatter lable with label values
    get_formatted_label = (label, item) => {
        var  selected_data = '';
        for (let key of label) {
            if (item.hasOwnProperty(key) && item[key] !=null) {
                selected_data = selected_data != "" ? selected_data + "-" + item[key] : item[key] + "";
            }

        }
        return selected_data;
    }

    // call advanced search and pass params
    on_advanced_search = (params ,type) => {
         this.props.on_advanced_search(params ,type);
    }

// compare two rows
    check_object_equal = (item, type) => {
        var row = this.state.selected_row;
        if (Object.keys(row[type]).length == 0) {
            return true;
        }
            
        if (row.hasOwnProperty(type) && row[type].label !== item.label) {
            return true;
        }
        return false;
    }

    // select item in grid and suggestions
    on_item_selection = (item, type) => {
        let need_update = this.check_object_equal(item,type);
        if (need_update) {
            this.setState({
                selected_row: { ...this.state.selected_row, [type]: item },
                grid_config: {
                    [type]: {
                        column: null,
                        rows: null
                    }
                }
            }, () => {
                this.props.update_data(item["row"], type);
            });
        }
    }
    // clear auto search field
    clear_quick_search = (type) => {
        this.setState({
            selected_row: {
                ...this.state.selected_row, [type]: {} }
        }, () => {
            this.props.update_data(null, type);
        });

    }
    AddClicks = () => {
        if (this.props.addClicks) {
            this.props.addClicks();
        }
    }
    // select grid item on double click
    on_grid_selection = (item, type) => {
        let label = this.prepare_suggestion({ data: [item] });
        this.on_item_selection(label[0], type);
        
    }

    // make suggestions with label value
    render_suggestion_result = (props) => {
        return (
            <div key={props.title} tabIndex={0} className="item_auto_search">
                <div key={props.title} className="fs_13">
                    <span>{props.label}</span>
                </div>
            </div>

        )
    }


    // preapre suggestions label
    prepare_suggestion = (data) => {
        var formattedList = [];      
        var data_length = data.data && data.data.length > 0 ? data.data.length : 0;
        if (data && data_length) {
            for (let i = 0; i < data_length; i++) {
                let item = data.data[i];
                let label = this.state.label_list;
                var selected_data = '';
                for (let key of label) {
                    if (item.hasOwnProperty(key) && item[key] !=null) {
                        selected_data = selected_data != "" ? selected_data + "-" + item[key]  : item[key]+"" ;
                    }
                    
                }
                
                formattedList.push({
                    "title" : i+"",
                    "label": selected_data,
                    "row" : item
                })
            }
        }
        return formattedList;
    }
    set_modal_state = (flag) => {
        this.setState({ advanced_modal_state :flag});
    }
    get_input_value = (input) => {

    }

    render() {
        let control = this.state.control;
        let grid = this.state.grid_config;
        let new_grid = control ? grid[control.type] : {};
        new_grid.paginationPageSize = 20
        if (control) {
            return (<> {
                        <Advanced_control
                            showModal={this.state.advanced_modal_state}
                            addClicks={this.AddClicks}
                            onGridRowSelection={(row) => this.on_grid_selection(row, control.type)}
                            gridConfig={new_grid}
                            controlId={control.control_id}
                            onSearch={(params) => this.on_advanced_search(params, control.type)}
                            disabled_element={this.props.is_disabled}
                            set_parent_show_modal_state={this.set_modal_state}
                            search_type={this.props.headerIdForGridTabNavigation}
                            headerIdForGridTabNavigation={this.props.headerIdForGridTabNavigation}
                            autoFocusForSearchIcon={this.props.is_focused}
                        /> }
                      <AutoSearchComponent 
                            getInputValue={this.get_input_value} default_value={this.state.selected_row[this.state.control.type]}
                            errorMessage={control.error_message} prepareRenderList={this.render_suggestion_result}
                            getList={(params) => this.on_quick_search(params, control.type)}
                            prepareDataList={(data) => this.prepare_suggestion(data)}
                            selectresult={(item) => this.on_item_selection(item, control.type)}
                            show_clear_search={true} clear_search={() => this.clear_quick_search(control.type)}
                            is_disabled={this.props.is_disabled}
                            errorClass={this.props.className}
                            ref={instance => this.instance = instance}
                        />

            </>
            );

        }
        return null;

    }
}


//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
    };
};
export default connect(mapStateToProps, null)(ClaimAdjustmentCodeSearch);