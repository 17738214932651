import * as local_storage from '../../shared/local_storage_utility';
import { request_queue } from './../../global_constants';
import { RequestManager } from './request_manager';

export class Request {

    constructor() { }

    /**
     * Make an request and make sure to return the 
     * response as request is resolved
     * 
     * @param object of RequestConfig
     * @param should_queue //should an requests send to queue after this request
     */
    make(config, should_queue: boolean = false): any {
        let req_manager = new RequestManager()
        return req_manager.process(config, should_queue)

    }

    /**
     * Time to deque all the requests present in the pipeline
     * i.e the global variable (request_queue). when the response is obtained
     * from each request, we are resolving the promise by sending response. 
     *
     */
    deque_pipeline() {
        local_storage.set("should_queue_request", "false");

        // in order to release queued request in First Come First Serve Order
        request_queue.reverse();
        let length = request_queue.length;
        for (let i = 0; i < length && request_queue.length > 0; i++) {
            if (local_storage.get("should_queue_request") == "true" ||  // if some process starts queue then stop dequeue process
                request_queue.length < 1) {

                // returning the request in reverse order
                request_queue.reverse();
                break;
            }

            // fetching queue request
            let promise = request_queue.pop();

            // executing the request
            let req_manager = new RequestManager();
            req_manager.execute_request(promise);
        }
    }
} 
