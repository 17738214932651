import * as global_constants from '../../global_constants';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request';


export const generate_barcode = (token, patient_id, id_list) => {
    // API call to log error in database
    const url = `${global_constants.base_end_points}${global_constants.end_points.barcode.barcode}${global_constants.end_points.barcode.gernerate_barcodes}/${patient_id}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(id_list)
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(request_config);
}
