import * as global_constants from '../../global_constants';
import { PATIENT_SEARCH_DATA } from '../patient_constants';
import { format_patient_search } from '../util/patient_search_utility';
import { log_error } from './../../shared/action/shared_action';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request';
import * as session_storage from '../../shared/session_storage_utility';

//Patient Search - Advance Search.
export const get_patient_search = (patient, token, save_patient_criteria = true) => {
    if (save_patient_criteria) {
        session_storage.set('patient_criteria', patient);
    }
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}${global_constants.end_points.patient_urls.search}`;

    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(patient)
    }

    let request = new Request();
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    
    return dispatch => {
        dispatch({
            type: PATIENT_SEARCH_DATA,
            payload: null
        })

        return request.make(request_config).then(
            response => dispatch({
                type: PATIENT_SEARCH_DATA,
                payload: format_patient_search(response)
            }),
            error => {
                dispatch({
                    type: PATIENT_SEARCH_DATA,
                    payload: []
                })
                log_error(error)
            }
        )
    };
}

//Patient Search - Advance Search.
export const get_patient_report = async (data, token,id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${id}/notes/print_patient_output_report`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(data)
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return await request.make(request_config);

}
