import * as React from 'react';
import { Grid, Icon } from 'semantic-ui-react';

/**
 * Pagination component
 * This is custome pagination and has developed to manage page information, total records
 * Also it's handling page navigation- frist page, last page, previous page, next page
 */
const NumberPaginator = (props) => {
    const { fromPage, toPage, totalRecords, isFirstPage, isLastPage, onFirst, onPrevious, onNext, onLast, onSpecificPage, totalPage, pager, showPager } = props;
    const eclipse = 5;
    
    return (
        
        <Grid columns={1} className="pagination-top">
           
            <Grid.Column tablet={16} textAlign={"center"}>
                <div>
                    <ul className="numberpagination PageButton">
                        <>
                            {pager && pager.start_range && pager.total_pages > 0 &&
                                <li>
                                    <button disabled={isFirstPage} onClick={onPrevious} className="button-page"><strong>Previous</strong></button>
                                </li>
                            }
                            {
                                showPager && pager && pager.start_range && pager.start_page <= 3 ?
                                    pager.start_range.map((page, index) =>
                                        <li key={index} className={pager.curr_page === page ? 'active' : ''}>
                                            <a onClick={() => onSpecificPage(page)}>{page}</a>
                                        </li>
                                    )
                                    :

                                    <>
                                        <li>
                                            <button disabled={isFirstPage} onClick={onFirst} className="button-page">
                                                1
                            </button>
                                        </li>
                                        <li>
                                            <button disabled={isFirstPage} onClick={() => onSpecificPage(pager.curr_page - eclipse)} className="button-page">
                                                ...
                            </button>
                                        </li>
                                    </>
                            }
                            {
                                showPager && pager && pager.mid_range &&
                                pager.mid_range.map((page, index) =>
                                    <li key={index} className={pager.curr_page === page ? 'active' : ''}>
                                        <a onClick={() => onSpecificPage(page)}>{page}</a>
                                    </li>
                                )

                            }
                            {
                                showPager && pager && pager.end_range && pager.end_page >= pager.total_pages - 2 ?
                                    pager.end_range.map((page, index) =>
                                        <li key={index} className={pager.curr_page === page ? 'active' : ''}>
                                            <a onClick={() => onSpecificPage(page)}>{page}</a>
                                        </li>
                                    )
                                    :
                                    <>

                                        <li>
                                            <button disabled={isLastPage} onClick={() => onSpecificPage(pager.curr_page + eclipse)} className="button-page">
                                                ...
                            </button>
                                        </li>
                                        <li>
                                            <button disabled={isLastPage} onClick={onLast} className="page-arrow-right button-page">
                                                {pager.total_pages}
                                            </button>
                                        </li>
                                    </>
                            }
                            {pager && pager.start_range && pager.total_pages > 0 &&
                                <li>
                                    <button disabled={isLastPage} onClick={onNext} className="button-page"><strong>Next</strong></button>
                                </li>
                            }
                        </> 

                    </ul>
                </div>
            </Grid.Column>
        </Grid>


    );
};

export { NumberPaginator };

