import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { Button, Dimmer, Input, Loader, Grid } from 'semantic-ui-react';
import * as global_constant from '../../global_constants';
import { user_forgot_password_token_validation, user_reset_password } from '../action/login_action';
import * as login_constants from '../login_constants';
import PasswordStrengthComponent from './../../shared/component/user_password_strength_component';
import { handle_focus_on_tab, handle_focus_on_shift_tab, handle_click_on_enter } from './../../shared/tab_navigation_utility';
var pendoInit = require('../../../../config/pendo_init');

export class ResetPasswordComponent extends React.Component<any, any> {
    [x: string]: any;

    constructor(props) {
        super(props);
        // Initialize state property here
        this.state = {
            loading: false,
            is_submitted: false,
            token: "",
            forgot_validation_response: {}
        };
    }
    componentDidMount() {

        this.get_token_from_url();
    }
    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: global_constant.toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }

    // get token from the application url
    get_token_from_url = async () => {
        // application url
        var url = window.location.href;

        // To get token from the URL
        var result = url.split('?')
        var token = result[result.length - 1]

        if (token === "" || token === undefined) {
            //TODO: Show Message 
            toastr.error('', "Invalid Data. Try again later");
            return;
        }


        await this.props.user_forgot_password_token_validation(token)

        if (this.props.forgot_password_token_validation && this.props.forgot_password_token_validation.data) {
            pendoInit(this.props.forgot_password_token_validation.data.user_id);
        }

        this.setState({
            token: token,
            forgot_validation_response: this.props.forgot_password_token_validation
        });

        var response = this.props.forgot_password_token_validation;

        if (response.status === 1) {
            // Continue
            //toastr.error('', "");
        }
        else {
            if (response.messages != undefined && response.messages.length > 0) {
                //vmUser.validation.data.message = response.messages[0].message;
                toastr.error('', response.messages[0].message);
            }
            else {
                toastr.error('', "Forgot Password token validation Failed. Try again later");
            }
            this.redirect_to_login_click();
            //TODO: Need to validate 
            //messageService.setMessage(vmUser.validation.data.message);
            //toastr.error('', "Invalid Data. Try again later");
            //$location.path(nonSecureURLConstant.messageURL);
        }

    }


    // method will execute when user will clicks on Submit button on Reset Password page
    on_submit = async (values) => {

        this.setState({
            is_submitted: true
        });

        if (values && values.confirmPassword && this.state.new_password) {
            if (values.confirmPassword === this.state.new_password) {
                this.setState({
                    loading: true,
                    is_submitted: true
                });

                const user_reset_password = await this.props.reset_password(values.confirmPassword, this.state.forgot_validation_response.data.user_id, this.state.token);

                this.setState({
                    loading: false,
                    is_submitted: true
                });
                var response = this.props.user_reset_password;
                const toastr_options = this.show_html_content_toaster(response.messages[0].message);
                if (response.status === 1) {
                    //vmUser.forgotPassword.data.result = true;
                    toastr.success('', toastr_options);
                    // sessionStorageService.set('fromForgotPasswordPage', true);
                    //$location.path(revflowConstant.loginPageUrl);
                    //  vmUser.forgotPassword.loading = false;
                    this.redirect_to_login_click();
                }

                else {
                    toastr.error('', toastr_options);
                    // vmUser.forgotPassword.loading = false;
                }

                if (this.props.user_reset_password && this.props.user_reset_password.length > 0) {



                }
            } else {
                toastr.error('', login_constants.MISMATCH_PASSWORD);
            }
        } else {
            toastr.error('', login_constants.RESET_REQUIRED_VALIDATION);
        }
    }

    private redirect_to_login_click() {
        this.props.history.push("/login");
    }

    // Generates HTML for field's in the form i.e. Forgot Password form
    private renderField = (field) => {
        const className = `form-control ${!field.input.value && this.state.is_submitted ? 'search-error-thin' : ''}`;
        const type = 'password';
        return (
            <div className="form-group">
                <label>{field.label}</label>
                {(field.input.name === 'password') ? <Input autoComplete="new_password" type={type} className={className} {...field.input} autoFocus /> : <Input type={type} className={className}{...field.input} />}
            </div>
        )
    }

    // Child -> parent data Interaction
    // Password_change component data on manage_my_account
    on_password_data_change = (password_data) => {
        this.setState({
            new_password: password_data
        });
    }

    render() {
        // Redux form will generate a prop named 'handleSubmit'
        const { handleSubmit } = this.props;
        return (
            <React.Fragment>
                <Dimmer active={this.state.loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <div className="login-wrapper" onKeyPress={this.login_handle_key_press}>
                    <Grid.Row>
                        <Grid.Column computer={8} className="text-right centerContent login-logo">
                            <div>
                                <img src={login_constants.IMAGES_PATH + login_constants.WEB_PT_LOGO} alt="logo" />
                                <div className="clr"></div><span className="version">{global_constant.show_app_version ? global_constant.build_version : ''}</span><span className="LoginAppName">Billing</span>
                            </div>
                        </Grid.Column>
                        <Grid.Column computer={8} className="centerContent">
                            <div className="loginbox text-left">
                                <h2>Reset Password</h2>
                                <form autoComplete="frm_reset_password" onSubmit={handleSubmit(this.on_submit)}>
                                    <PasswordStrengthComponent is_submitted={this.state.is_submitted} password_data={this.on_password_data_change} />
                                    <Field
                                        name="confirmPassword"
                                        label="Re-type New Password"
                                        autoComplete="con_password"
                                        component={this.renderField}
                                    />
                                    <div className="text-center">
                                        <Button id="success" onKeyDown={(event) => { handle_focus_on_tab(event, "user_password_new_input") }} primary className="success_btn" type="submit">RESET PASSWORD</Button>
                                    </div>
                                </form>
                            </div>
                            <div className="clear10"></div>
                            <div className="helpSection centerContent">
                                <span>{global_constant.copyright_info}</span>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </div>
            </React.Fragment>
        );
    }
}



const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        reset_password: user_reset_password,
        user_forgot_password_token_validation: user_forgot_password_token_validation
    }, dispatch)
}

const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        forgot_password_token_validation: state.user_login_details.forgot_password_token_validation,
        user_reset_password: state.user_login_details.user_reset_password
    };
}


/* - Connect component to redux
  - Used to map the redux store state and dispatch it to the props of a component
  - 2 input parameters:-
      1) mapStateToProps- The component will subscribe to Redux store updates. This means that any time the store is updated, mapStateToProps will be called [output]
      2) mapDispatchToProps- Map dispatched data from the action creater to props of a component [action creater]
*/
export default reduxForm({
    form: 'reset'
})(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordComponent))