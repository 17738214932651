import * as React from 'react';
import { handle_click_on_enter } from '../../tab_navigation_utility';
/**
 * Checkbox component to handle check/uncheck state from parent qand indivisual
 */
class CheckboxComponent extends React.Component<any, any> {
    [x: string]: any;
    _is_mounted = false;
    //Get innitalise data when component created
    constructor(props) {
        super(props);
        this.state = {
            selectCheckbox: this.props.selectCheckbox,
        };
        this.onChange = this.onChange.bind(this);
    }

    // Checkbox check change function
    onChange(event) {
        if (event.target.checked) {
            if (this._is_mounted) {
                this.setState({ selectCheckbox: true });
            }
            this.props.onChange(true, this.props.value);
        } else {
            if (this._is_mounted) {
                this.setState({ selectCheckbox: false });
            }
            this.props.onChange(false, this.props.value);
        }
    }

    //Render function
    render() {
        return (
            <input
                id={`checkbox_${this.props.value}`}
                type="checkbox"
                onChange={this.onChange}
                onKeyDown={handle_click_on_enter}
                checked={this.state.selectCheckbox}
                value={this.props.value}
                name={this.props.value}
            />
        );
    }

    //Component update function
    componentDidUpdate(prevProps) {
        if (this.props.shouldUpdate === true) {
            if (this.props.selectCheckbox !== prevProps.selectCheckbox && this.props.selectCheckbox !== this.state.selectCheckbox && this._is_mounted) {
                this.setState({ selectCheckbox: this.props.selectCheckbox });
            } else if (this.state.selectCheckbox === true && this.props.isClear === true && this._is_mounted) {
                this.setState({ selectCheckbox: false });
            }
        }
    }
    componentDidMount() {
        this._is_mounted = true;
    }
    componentWillUnmount() {
        this._is_mounted = false;
    }
};

export default CheckboxComponent;