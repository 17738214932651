
const GRID_COLUMN_WRAP = ['primary_width', 'secondary_width', 'tertiary_width', 'description_width'];
const WIDTH = 204;
function child_row_height(data) {
    if (data.subscriber && data.subscriber.length > 0) {
        return 660;
    }
    return 580;
}

function row_height(data) {
    let width = WIDTH; // cell content render width (220px-16px padding)
    let single_row_height = 28;
    GRID_COLUMN_WRAP.map(field => {
        const value = data[field];
        if (value != null && value != undefined && value > width) {
            width = value;
        }
    });
    if (width > WIDTH) {
        return single_row_height * (Math.floor(width / WIDTH) + 1);
    }
    return single_row_height;
}

function charge_child_row_height(data, paramApi) {
  const footer_row_height = 28;
  const inner_row_height = 39;
  const inner_header_height = 52;
  const scrollbar_height = 19;
  let row_height = 0;
  if (data.details && data.details.length > 0) {
    row_height = data.details.length * inner_row_height + inner_header_height + scrollbar_height;
    return row_height;
  }
  return footer_row_height;
}

function get_case_renderer() {

    function FullWidthCellRenderer() { }

    FullWidthCellRenderer.prototype.init = function (params) {
        var eTemp = document.createElement("div");
        eTemp.innerHTML = this.getTemplate(params);
        this.eGui = eTemp.firstElementChild;
    };

    FullWidthCellRenderer.prototype.getTemplate = function (params) {
        return tamplate(params.node.data);
    };

    FullWidthCellRenderer.prototype.getGui = function () {
        return this.eGui;
    };

    return FullWidthCellRenderer;
}

const get_referring_physician = (referring_physician) => {
    if (referring_physician && referring_physician.name) {
        const { first_name, last_name } = referring_physician.name;
        return `${first_name || ''} ${last_name || ''}`;
    }
    return '';
}

const render_rows = (data) => {
    let nodes = '';
    data.map((value) => {
        const { name, primary, secondary, tertiary } = value;
        nodes = `${nodes}<tr><td>${name}</td><td>${primary}</td><td>${secondary}</td><td>${tertiary}</td></tr>`;
    })
    return nodes;
}

const tamplate = (data) => {
    const { referring_physician, inner_child } = data;
    return ('<div class="case-detail-container">' +
        `<p>Referring Physician: ${get_referring_physician(referring_physician)}</p>` +
        '<div class="table-responsive custom-default-table" style="min-width: 1200px;">' +
        '<table class="ui table">' +
        '<thead>' +
        '<tr id="ss"><th></th><th><b>Primary Insurance</b></th><th><b>Secondary Insurance</b></th><th><b>Tertiary Insurance</b></th></tr>' +
        '</thead>' +
        '<tbody>' +
        `${render_rows(inner_child)}` +
        '</tbody>' +
        '</table>' +
        '</div>' +
        '</div>');
}

const inner_row_height = (data: { details: string | any[]; }, paramApi: any) => {
  const inner_row_height = 28;
  const inner_header_height = 52;
  const scrollbar_height = 19;
  let row_height = 0;
  if (data.details && data.details.length > 0) {
    // row_height = data.details.length * inner_row_height + inner_header_height;
    row_height = data.details.length * inner_row_height + inner_header_height //+ scrollbar_height;
    return row_height;
  }
  return row_height;
};

const inner_grid_reset_eob_row_height = (data: { details: string | any[]; }, paramApi: any) => {
  const inner_row_height = 28;
  const inner_header_height = 52;
  const scrollbar_height = 19;
  let row_height = 0;
  if (data.details && data.details.length > 0) {
    // row_height = data.details.length * inner_row_height + inner_header_height;
    row_height = data.details.length * inner_row_height + inner_header_height + 30//+ scrollbar_height;
    return row_height;
  }
  return row_height;
};
export { get_case_renderer, child_row_height, row_height, charge_child_row_height, inner_row_height, inner_grid_reset_eob_row_height};

