import * as global_constants from '../../global_constants';

export default  function (state = {}, action){
    const control = action.type.toLowerCase();
   
    switch (action.type) {
       
        case global_constants.constants.advanced_control_type[control]:
            let state_data = { ...state };
            if (action.payload) {
                state_data =  { ...state, [control] : action.payload };

            }
            
            return state_data;
            break;
        default:
            return state
    }
}