import * as moment from 'moment';
import * as React from 'react';
import { Form } from 'semantic-ui-react';
import { quick_search_location, quick_search_provider, search_place, search_billing_and_treating_provider } from "../../payment/action/payment_action";
import AdvancedControl from "../../shared/component/advanced_control";
import Calendar from '../../shared/component/calendar_component';
import Selection from '../../shared/component/selection_component';
import * as local_storage from '../../shared/local_storage_utility';
import { amount_formatter, date_format_with_no_time } from '../../shared/utility';
import { get_place_of_service, get_provider, get_popper_id, check_copay_transaction_type, NO_INSURANCE_REQUIRED_TRANSACTIONS } from '../utility';
import { patientCreditType } from '../payment_constants';
import AutoSearchComponent from './../../shared/component/auto_search_component';
import CurrencyFormatter from '../../shared/component/currency_formatter';
import { search_location_configuration, search_provider_configuration } from '../../shared/shared_constants';

class ItemAdditionalComponent extends React.Component<any, any> {

    button_location_clear: HTMLElement;
    button_provider_clear: HTMLElement;
    button_location_search: HTMLElement;
    button_provider_search: HTMLElement;
    _is_mounted = false;

    select_option = { text: 'Select', value: ' ', key: 'Select' };

    // Get innitalise data when component created
    constructor(props) {
        super(props);
        this.state = {
            checked: this.props.checked,
            closeControl: false,
            data: this.props.data,
            payment_code_options: this.props.paymentCode && this.props.paymentCode[this.props.data.credit_type_id] || [],
            patient_credit_type: {},
            location_id: this.props.data.place_of_service && this.props.data.place_of_service.id || 0,
            location_search_data: {},
            provider_search_data: {},
            selected_location: { label: get_place_of_service(this.props.data.place_of_service) },
            selected_provider: { label: get_provider(this.props.data.billing_provider) },
            minDate: null,
            is_fetched: false,
            err_amount: false,
            err_place_of_service: false,
            err_payment_code: false,
            err_insurance: false,
        };
    }

    componentDidMount() {
        this._is_mounted = true;
        this.props.onRef(this);
        this.setState({
            payment_code_options: this.props.paymentCode && this.props.paymentCode[this.props.data.credit_type_id] || [],
        })

        this.button_location_clear = document.querySelector(`.add-search-location-${this.props.row_num} .closeIcon`);
        this.button_provider_clear = document.querySelector(`.add-search-provider-${this.props.row_num} .closeIcon`);
        this.button_location_search = document.querySelector(`.add-search-location-${this.props.row_num} .searchIcon`);
        this.button_provider_search = document.querySelector(`.add-search-provider-${this.props.row_num} .searchIcon`);
        if (this.props.disabled && this.props.disabled === true) {
            this.btn_set_disabled(this.button_location_search);
            this.btn_set_disabled(this.button_provider_search);
        }
    }

    componentWillUnmount() {
        this._is_mounted = false;
        this.props.onRef(undefined)
    }

    //Component update function
    componentDidUpdate(previousProps, previousState) {
        const { shouldUpdate, checked } = this.props;
        const state_value = {};
        let is_update = false;
        if (shouldUpdate === true && previousProps.checked !== checked) {
            state_value['checked'] = checked;
            is_update = true;//state update available 
        } else if (this.state.checked === true && this.props.isClear === true) {
            state_value['checked'] = false;
            is_update = true;//state update available 
        }

        if (this.props.clear_data !== previousProps.clear_data) {
            state_value['err_amount'] = false;
            state_value['err_place_of_service'] = false;
            state_value['err_payment_code'] = false;
            state_value['err_insurance'] = false;
            state_value['disabled_date_of_service'] = false;
            is_update = true;//state update available 
        }
        if (this.props.data != previousProps.data) {
            const selected_location = { label: get_place_of_service(this.props.data.place_of_service) };
            const selected_provider = { label: get_provider(this.props.data.billing_provider) };
            state_value['data'] = { ...this.props.data };
            state_value['is_fetched'] = true;
            state_value['checked'] = checked;
            state_value['selected_location'] = selected_location;
            state_value['selected_provider'] = selected_provider;
            state_value['disabled_date_of_service'] = false;
            is_update = true;//state update available 
        }
        if (is_update) {
            this.setState(state_value);
        }
    }

    btn_set_focus = (element) => {
        if (this._is_mounted) {
            setTimeout(() => {
                if (element) {
                    element.focus();
                }
            }, 100);
        }
    }

    btn_set_disabled = (element: HTMLElement) => {
        if (element) {
            element['disabled'] = true;
        }
    }

    // Will handle date selection from calendar  
    handle_calendar_change = (date) => {
        const date_of_service = this.get_date(date, 'MM/DD/YYYY');
        const data_updated = { ...this.state.data, date_of_service };
        this.setState(prevState => ({
            data: data_updated,
            is_fetched: false,
        }), () => {
            this.props.on_data_change({ ...this.state.data }, this.props.row_num);
        });
    }

    on_check_change(event) {
        this.setState({ checked: event.target.checked });
        this.props.onChange(event.target.checked, this.props.data, this.state.data);
    }

    on_change_amount = value => {
        this.setState(prevState => ({
            data: { ...prevState.data, amount: value },
            err_amount: (value == '' || value == '0.00') ? true : false
        }), () => {
            this.props.on_amount_change({ ...this.state.data }, this.props.row_num);
        });
    }

    get_row_num = () => this.props.row_num;

    get_date = (date, pattern) => {
        if (date) {
            return date_format_with_no_time(date).format(pattern);
        }
        return '';
    }

    //Get data retruns data item.
    get_data = () => ({ ...this.props.data });

    //Get data retruns updated data item.
    get_updated_data = () => {
        return {
            patient_credit_id: 0,
            date_of_service: this.state.data.date_of_service,
            credit_type_id: this.state.data.credit_type_id,
            amount: this.state.data.amount,
            original_amount: this.state.data.amount,
            place_of_service: this.state.data.place_of_service,
            billing_provider: this.state.data.billing_provider,
            insurance_code: this.state.data.insurance_code ? this.state.data.insurance_code : -1,
            insurance_name: this.state.data.insurance_name ? this.state.data.insurance_name : '',
            payment_code: this.state.data.payment_code
        }
    };

    //isChecked retruns selection status.
    is_checked = () => this.state.checked;

    //Check form validation
    is_valid = () => {
        let flag_value = true;
        let update_value = {};
        let data = this.state.data;

        if (data.amount === '' || data.amount === '0.00') {
            update_value['err_amount'] = true;
            flag_value = false;
        }

        if (JSON.stringify(this.state.data.place_of_service) == '{}') {
            update_value['err_place_of_service'] = true;
            flag_value = false;
        }

        if (data.payment_code == null || JSON.stringify(data.payment_code) == '{}' || data.payment_code == '') {
            update_value['err_payment_code'] = true;
            flag_value = false;
        }

        if (data.insurance_code === '') {
            update_value['err_insurance'] = true;
            flag_value = false;
        }

        if (flag_value === false) {
            this.setState({ ...update_value })
        }
        return flag_value;
    }

    //This function amount return negative sign status to display toaster 
    is_negative_amount_error = () => ((this.state.data.credit_type_id == 7 && this.state.data.amount > 0) ? true : false);
    is_negative_valid = () => ((check_copay_transaction_type(this.state.data.credit_type_id) && this.state.data.amount < 0) ? true : false);

    required_location = () => {
        let value = '';
        if (this.props.is_submitted && this.state.err_place_of_service === true) {
            value = 'requiredWithBgColor';
        }
        return value;
    }

    required_insurance = () => {
        const is_insurance_required = (NO_INSURANCE_REQUIRED_TRANSACTIONS.indexOf(this.state.data.credit_type_id) === -1) ? true : false;
        if (is_insurance_required && this.state.err_insurance) {
            return true;
        }
        return false;
    }

    // search location by typing name or code
    on_place_search = async (params) => {
        const token = local_storage.get("auth_data").token_details.access_token;
        const search_data = await search_place(params, token).then(res => res.data);
        const search_result = search_data.data !== null ? search_data.data.result : [];
        const grid_data = {
            ...this.state.grid_conf, rows: search_result,
            column: search_location_configuration(`add-modal-location-${this.props.row_num}`).column_defs,
            messages: search_data.messages
        };
        this.setState({ location_search_data: grid_data });
    }

    // search provider using code and description
    on_provider_search = async (params) => {
        const token = local_storage.get("auth_data").token_details.access_token;
        params.id = this.state.location_id;
        const search_data = await search_billing_and_treating_provider(params, token).then(res => res.data);
        const search_result = search_data.data !== null ? search_data.data.result : [];
        const grid_data = {
            ...this.state.grid_conf, rows: search_result,
            column: search_provider_configuration(`add-modal-provider-${this.props.row_num}`).column_defs,
            messages: search_data.messages
        };
        this.setState({ provider_search_data: grid_data });
    }

    // select a location row in grid on modal
    on_location_grid_row_selection = (selected_row) => {
        const data_updated = { ...this.state.data, place_of_service: selected_row };
        this.setState(prevState => ({
            selected_location: { label: get_place_of_service(selected_row) },
            location_id: selected_row.id || 0,
            data: data_updated,
            is_fetched: false,
            err_place_of_service: (JSON.stringify(selected_row) == '{}') ? true : false
        }), () => {
            this.props.on_data_change({ ...this.state.data }, this.props.row_num);
        });
    }

    // on provider search item selection
    on_provider_grid_row_selection = (selected_row) => {
        const data_updated = { ...this.state.data, billing_provider: selected_row };
        this.setState(prevState => ({
            selected_provider: { label: get_provider(selected_row) },
            data: data_updated,
            is_fetched: false
        }), () => {
            this.props.on_data_change({ ...this.state.data }, this.props.row_num);
        });
    }

    //Auto search for Location
    render_result = (props) => {
        return (<div key={props.title} tabIndex={0} className="item_auto_search">
            <div key={props.title} className="fs_13">
                <span>{props.label}</span>
            </div>
        </div>);
    }

    // get the quick search location apis data
    get_location_data_list = async (search_keyword) => {
        const token = local_storage.get("auth_data").token_details.access_token;
        return await quick_search_location(search_keyword, token);
    }

    // get the quick search provider apis data
    get_provider_data_list = async (search_keyword) => {
        //var search_key_obj = { key: search_keyword, locationId: this.state.location_id };
        var search_key_obj = { key: search_keyword, locationId: -1 };//Search provider irrespective of location value as -1
        const token = local_storage.get("auth_data").token_details.access_token;
        return await quick_search_provider(search_key_obj, token);
    }

    // preapare the suggestion list with search result
    prepare_suggestion = (data) => {
        let formattedList = [];
        var data_length = data.data.length;
        if (data && data_length) {
            for (let i = 0; i < data_length; i++) {
                let item = data.data[i];
                let name = typeof item.name === 'undefined' ? item.description : item.name;
                item = { ...item, title: name, label: item.code == '' ? name : item.code + ' - ' + name, key: `${i}-${name}` };
                formattedList.push(item);
            }
        }
        return formattedList;
    }

    // select the item on click suggested items
    onselection = (item, type) => {
        if (type === 'provider') {
            this.on_provider_grid_row_selection(item);
        } else {
            this.on_location_grid_row_selection(item);
        }
    }

    clear_quick_search = (type) => {
        if (type === 'provider') {
            this.on_provider_grid_row_selection({});
        } else {
            this.on_location_grid_row_selection({});
        }
    }

    // get the searched value which is typed for searching location or providers
    get_input_value = (input, type) => {
        if (input.length >= 3) {
            if (type === 'provider') {
                this.get_provider_data_list(input);
            } else {
                this.get_location_data_list(input);
            }
        }
    }

    on_dropdown_payment_code = (value) => {
        const selected = this.state.payment_code_options.filter(data => {
            return data.value == value
        });

        if (selected && selected.length > 0) {
            const data_updated = { ...this.state.data, payment_code: selected[0].data };
            this.setState(prevState => ({
                data: data_updated,
                is_fetched: false,
                err_payment_code: false
            }), () => {
                this.props.on_data_change({ ...this.state.data }, this.props.row_num);
            });
        }
    }

    on_dropdown_insurance = (value) => {
        if (value === ' ') {
            this.setState(prevState => ({
                data: { ...this.state.data, insurance_code: '', insurance_name: '' },
                is_fetched: false,
                err_insurance: true
            }), () => {
                this.props.on_data_change({ ...this.state.data }, this.props.row_num);
            });

        } else {
            let list_data = this.props.insurance && this.props.insurance[this.state.data.credit_type_id] || []
            const selected = list_data.filter(data => {
                return data.value == value
            });
            if (selected && selected.length > 0) {
                const { key, value, text } = selected[0];
                const data_updated = { ...this.state.data, insurance_name: text, insurance_code: `${value}` };
                this.setState(prevState => ({
                    data: data_updated,
                    is_fetched: false,
                    err_insurance: false
                }), () => {
                    this.props.on_data_change({ ...this.state.data }, this.props.row_num);
                });
            }
        }
    }

    on_dropdown_transaction_type = (value) => {
        if (value != this.state.data.credit_type_id) {
            //B-32481 Make 'Insurance' a required field when adding an Additional Payment.
            //let index = NO_INSURANCE_REQUIRED_TRANSACTIONS.indexOf(parseInt(value));
            //const is_insurance_required = index === -1 ? true : false;

            let disabled_dos = { disabled_date_of_service: false };
            let clear_dos_value = {};
            let parsedValue = parseInt(value);
            if ((parsedValue === patientCreditType.paymentonaccount) || (parsedValue === patientCreditType.patientrefund)) {
                //disabled for 'Payment on Account' type and clear date of service
                disabled_dos['disabled_date_of_service'] = true;
                clear_dos_value['date_of_service'] = null;
            }

            let error = { err_insurance: true, err_payment_code: true }
            if (this.state.data.amount === '' || this.state.data.amount === '0.00') {
                error['err_amount'] = true;
            }

            if (JSON.stringify(this.state.data.place_of_service) == '{}') {
                error['err_place_of_service'] = true;
            }

            this.setState(prevState => ({
                data: {
                    ...prevState.data,
                    credit_type_id: value,
                    payment_code: {},
                    insurance_code: '',
                    insurance_name: '',
                    ...clear_dos_value
                },
                ...disabled_dos,
                payment_code_options: this.props.paymentCode && this.props.paymentCode[value] || [],
                is_fetched: false,
                ...error
            }), () => {
                this.props.on_data_change({ ...this.state.data }, this.props.row_num);
            });
        }
    }

    /**
     * Dropdown key event handling
     * Tab navigation and auto select insurance option
     */
    on_key_down = (e) => {
        if (e.keyCode == '9' && this.state.data.insurance_code === '') {
            //Changes for B-32481 
            //AC3.2: If a user tabs over the Insurance field, when Select is still the default option,
            //        automatically update to Patient.
            this.on_dropdown_insurance(0);
        }
    }

    // on close modal method will be fired
    close_locaton_modal = () => {
        this.btn_set_focus(this.button_location_clear);
    }

    close_provider_modal = () => {
        this.btn_set_focus(this.button_provider_clear);
    }

    //Get default date selection
    default_date = (date, pattern) => {
        if (date) {
            const dt = moment(date).format(pattern);
            return moment(dt);
        }
        return null;
    }

    render_checkbox = () => {
        return (<input type="checkbox"
            id={`id_additional_payment_${this.props.row_num}`}
            onChange={this.on_check_change.bind(this)}
            checked={this.state.checked} />);
    }

    get_insurance = (credit_type_id) => {
        let list = [{ ...this.select_option }];
        if (this.props.insurance) {
            const insurance_by_id = this.props.insurance[credit_type_id];
            if (insurance_by_id && insurance_by_id.length > 0) {
                list = list.concat(insurance_by_id);
            }
        }
        return list;
    }

    //Render function
    render() {
        const { data } = this.state;
        const insurance_options = this.get_insurance(data.credit_type_id);
        return (<tr>
            <td className="text-center">
                {this.render_checkbox()}
            </td>
            <td data-label="date" className={`custom-datepicker-for-table datepicker-keybowrd-${this.props.row_num}`}>
                <Calendar
                    id={`id_additional_date_of_service_${this.props.row_num}`}
                    id_popper={get_popper_id()}
                    date_update={this.handle_calendar_change}
                    date={this.default_date(this.state.data['date_of_service'], 'MM/DD/YYYY')}
                    is_disabled={this.state.disabled_date_of_service}
                    class_name={`datepicker-keybowrd-${this.props.row_num}`}
                />
            </td>
            <td data-label="transaction-type" >
                <Selection
                    placeHolder={'Select'}
                    hidden={true}
                    id={`id_additional_transaction_type_${this.props.row_num}`}
                    defaultValue={data.credit_type_id || ''}
                    options={this.props.transaction_types || []}
                    onChange={value => this.on_dropdown_transaction_type(value)}
                />
            </td>
            <td data-label="payment-code">
                <Selection
                    placeHolder={'Select'}
                    hidden={true}
                    id={`id_additional_payment_code_${this.props.row_num}`}
                    defaultValue={data.payment_code.payment_id || ''}
                    options={this.state.payment_code_options}
                    onChange={value => this.on_dropdown_payment_code(value)}
                    disabled={this.props.disabled}
                    isRequired={this.props.is_submitted && this.state.err_payment_code}
                />
            </td>
            <td data-label="insurance">
                <Selection
                    id={`id_additional_insurance_${this.props.row_num}`}
                    isRequired={this.props.is_submitted && this.state.err_insurance}
                    defaultValue={data.insurance_code}
                    options={insurance_options}
                    onChange={value => this.on_dropdown_insurance(value)}
                    onKeyDown={this.on_key_down}
                    disabled={this.props.disabled}
                />
            </td>
            <td data-label="amount">
                <Form.Field className={this.props.is_submitted && this.state.err_amount ? "requiredWithBgColor" : ""}>
                    <CurrencyFormatter
                        id={`id_additional_amount_${this.props.row_num}`}
                        onChange={(value) => this.on_change_amount(value)}
                        prefix='$'
                        maxLength={15}
                        defaultValue={data.amount}
                        allowNegative={true}
                        className={this.props.is_submitted && this.state.err_amount ? 'red-error-thin numberFormat' : 'numberFormat'}
                    />
                </Form.Field>
            </td>
            <td data-label="provider">
                <Form.Field className={`advance-quick-search add-search-provider-${this.props.row_num}`}>
                    <AdvancedControl
                        gridConfig={this.state.provider_search_data}
                        onGridRowSelection={this.on_provider_grid_row_selection}
                        controlId={'PROVIDER'}
                        onSearch={this.on_provider_search}
                        onCloseModal={this.close_provider_modal}
                        className={`add-modal-provider-${this.props.row_num}`}
                        search_type={`Add_PROVIDER_${this.props.row_num}`}
                        headerIdForGridTabNavigation={`add-modal-provider-${this.props.row_num}`}
                    />
                    <AutoSearchComponent
                        default_value={this.state.selected_provider}
                        getInputValue={(input) => this.get_input_value(input, 'provider')}
                        getList={this.get_provider_data_list}
                        prepareDataList={this.prepare_suggestion}
                        selectresult={(item) => this.onselection(item, 'provider')}
                        errorMessage={'No Billing Provider Found !'}
                        is_focus={false}
                        is_disabled={this.props.disabled}
                        show_clear_search={true}
                        clear_search={() => this.clear_quick_search('provider')}
                    />
                </Form.Field>
            </td>
            <td data-label="place-service">
                <Form.Field className={`advance-quick-search add-search-location-${this.props.row_num} ${this.required_location()}`}>
                    <AdvancedControl
                        gridConfig={this.state.location_search_data}
                        onGridRowSelection={this.on_location_grid_row_selection}
                        controlId={'LOCATION'}
                        onSearch={this.on_place_search}
                        onCloseModal={this.close_locaton_modal}
                        className={`add-modal-location-${this.props.row_num}`}
                        search_type={`Add_PLACE_SERVICE_LOCATION_${this.props.row_num}`}
                        headerIdForGridTabNavigation={`add-modal-location-${this.props.row_num}`}
                    />
                    <AutoSearchComponent
                        default_value={this.state.selected_location}
                        getInputValue={this.get_input_value}
                        getList={this.get_location_data_list}
                        prepareDataList={this.prepare_suggestion}
                        selectresult={this.onselection}
                        errorClass={this.props.is_submitted && this.state.err_place_of_service ? 'error' : ''}
                        errorMessage={'No Location Found !'}
                        is_focus={false}
                        is_disabled={this.props.disabled}
                        show_clear_search={true}
                        clear_search={() => this.clear_quick_search('location')}
                    />
                </Form.Field>
            </td>
        </tr>);
    }
};

export default ItemAdditionalComponent;