import { isNullOrUndefined } from "util";
import { is_null_or_white_space } from "../utility";

/**
 * Will extract the selected object out of the search component based on the subset of results and the selected entry
 *
 * @param {any} event Event from the search component containing specifically the related target of the selection
 * @param {any} value Value selected
 * @returns {any?}
 */
export const getSelectedResultObject = (event: any, value: any) => {
    let resultsForCriteria = value ? value.results : [],
        relatedTarget = event ? event.relatedTarget : null,
        defaultResult = value && value.results.length > 0 ? value.results[0] : null,
        selectedLabel = "",
        resultByLabel = [],
        result = defaultResult;

    if (isNullOrUndefined(resultsForCriteria) || resultsForCriteria.length == 0 || isNullOrUndefined(relatedTarget)) {
        return result;
    }

    // Get selected laberl (search component works using label as pivot)
    selectedLabel = relatedTarget.innerText;
    if (is_null_or_white_space(selectedLabel)) {
        return result;
    }

    // Look for the label, if present (should be), return it.
    resultByLabel = resultsForCriteria.filter(filteredResult => {
        let filteredResultNoSpaces = filteredResult.label.split(' ').join('').toLowerCase(),
            selectedLabelNoSpaces = selectedLabel.split(' ').join('').toLowerCase();

        return filteredResultNoSpaces == selectedLabelNoSpaces;
    });

    if (isNullOrUndefined(resultByLabel) || resultByLabel.length == 0) {
        return result;
    }

    return resultByLabel[0];
};
