import * as React from 'react';
import { connect } from 'react-redux';
import { Accordion, Grid, Icon } from 'semantic-ui-react';
import GridView from '../../shared/component/grid';
import { toastr as toaster } from 'react-redux-toastr';
import * as moment from 'moment';
import { LoaderComponent } from '../../shared/component/loading_component';
import {
    currencyFormatter,
    dateFormatter,
    messages,
    dateTimeFormatter,
    payment_adjustment_summary_configuration
} from '../../reports/report_constants';
import { strip, get_columns, get_all_error } from '../../shared/utility';
import { SubReportHeader } from '../../reports/component/report_header';
import ReportFooter from '../../reports/component/report_footer';
import { log_error } from '../../shared/action/shared_action';
import { multi_grid_export, multi_grid_print } from '../../reports/util/export_print_utility';
import { get_payment_adjustment_summary_by_pos } from '../action/report_action';
import { show_html_content_toaster, get_sub_title } from '../../admin/batch_tasks/utility';
import { handle_click_on_enter } from '../../shared/tab_navigation_utility';

export class PaymentAdjustmentSummaryByPosComponent extends React.Component<any, any> {
    handle_navigation_after_last_element: any;
    is_mounted: boolean;

    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            grid_visible: false,
            search_criteria: {},
            payments_rows: [],
            adjustments_rows: [],
            payments_rows_count: 0,
            adjustments_rows_count: 0,
            drill_down_title_shown: false,
            controls_is_expended: {
                payments: false,
                adjustments: false
            }
        };
    }

    search_criteria: any = {};
    current_date: string;
    title: string;
    grid_width = 0;
    total_column_width_adjustment = 0;
    grid_header_height: number = 0;
    grid_header_height_adjustment: number = 0;
    total_column_width: number = 0;
    total_column_width_adjustments: number = 0;

    page_metadata = payment_adjustment_summary_configuration;
    
    payments_column_defs: any = [];
    adjustments_column_defs: any = [];
    payments_rows: [];
    adjustments_rows: [];
    payments_footer: {};
    adjustments_footer: {};
    location: any = {};
    report_params = '';

    UNSAFE_componentWillMount = async () => {
        this.page_metadata.title = this.page_metadata.pos_title;
        this.location = this.props.location || this.location
        this.search_criteria = decodeURIComponent(this.location.search);
        this.search_criteria = this.search_criteria.replace('?', '');

        this.payments_column_defs = this.page_metadata.payment_summery_column_defs;
        this.adjustments_column_defs = this.page_metadata.adjustment_summery_column_defs;
        this.total_column_width = 0;
        this.total_column_width = 0;
    };

    componentDidMount = async () => {
        this.is_mounted = true;
        document.body.classList.add('reports');
        this.search_handler(this);
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.location !== this.props.location) {
            this.location = nextProps.location || this.location
            this.setState({
                drill_down_title_shown: false
            })
        }
    }

    componentWillUnmount = () => {
        document.body.classList.remove('reports');
        this.is_mounted = false;
        this.payments_rows = [];
        this.adjustments_rows = [];
    };

    //Handle search submit
    search_handler = (e) => {
        this.grid_width = document.getElementsByClassName('report-wrapper')[0].clientWidth;
        let token = this.props.user_login_details.user_data.data.token_details.access_token;
        if (this.is_mounted) {
            this.setState({
                loading: true,
                grid_visible: false
            });
        }
        var query_param = {
        };

        var paramlist = this.search_criteria.split('&')
        paramlist.map((item) => {
            var list = item.split('=');
            if (list && list.length > 1) {
                query_param[list[0]] = list[1];
            }
        });
        const param = {
            start_date: query_param['FDate'],
            end_date: query_param['TDate'],
            company_id: query_param['company_id'],
            pos_id: query_param['PosId']
        };

        this.title = `${query_param['Poscode']} - ${query_param['Posname']}<br>${query_param['FDate']} - ${query_param['TDate']}`

        get_payment_adjustment_summary_by_pos(token, param).then(
            (response) => {
                if (response.data && response.data.data) {
                    response.data.data.payment_summery = this.row_data_formatter(
                        response.data.data.payment_summery || [],
                        this.payments_column_defs,
                        'payments'
                    );
                    this.payments_rows = response.data.data.payment_summery || [];
                    response.data.data.adjustment_summery = this.row_data_formatter(
                        response.data.data.adjustment_summery || [],
                        this.adjustments_column_defs,
                        'adjustments'
                    );
                    this.adjustments_rows = response.data.data.adjustment_summery || [];

                    //Payments grid data
                    const grid_height = get_columns(this.payments_rows, this.payments_column_defs);
                    this.grid_header_height = grid_height.header_height;
                    this.total_column_width = grid_height.total_column_width;

                    //Adjustments grid data
                    const grid_height_adjustment = get_columns(this.adjustments_rows, this.adjustments_column_defs);
                    this.grid_header_height_adjustment = grid_height_adjustment.header_height;
                    this.total_column_width_adjustment = grid_height_adjustment.total_column_width;

                    if (this.is_mounted) {
                        this.setState({
                            loading: false,
                            grid_visible: true,
                            payments_rows: [...this.payments_rows],
                            adjustments_rows: [...this.adjustments_rows],
                            payments_rows_count: this.payments_rows.length,
                            adjustments_rows_count: this.adjustments_rows.length,
                            controls_is_expended: {
                                payments: true,
                                adjustments: true
                            }
                        });
                    }
                } else {
                    log_error(response.data.messages[0].message.indexOf('<br') > -1);
                    if (response.data.messages[0].message.indexOf('<br') > -1) {
                        const toastr_options = show_html_content_toaster(get_all_error(response.data.messages[0].message));
                        toaster.error('', toastr_options);
                    }
                    if (this.is_mounted) {
                        this.setState({
                            loading: false,
                        });
                    }
                }
            },
            (error) => {
                if (this.is_mounted) {
                    this.setState({
                        loading: false,
                    });
                }
                log_error(error);
                if (error.response.data) {
                    const toastr_options = show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error('', toastr_options);
                }
            }
        );
    };

    //Function used to initialize the footer object.
    initialize_footer_obj = (column_def) => {
        var footer_obj = {};
        column_def.map((value, index) => {
            if (index == 0) {
                footer_obj[value.field] = 'Total';
            } else if (value.type == 'currency' || value.type == 'number') {
                footer_obj[value.field] = 0;
            } else {
                footer_obj[value.field] = null;
            }
        });
        return footer_obj;
    };

    // FUnction use to format the grid data on load.
    row_data_formatter = (row_data, column_def, type) => {
        let footer = this.initialize_footer_obj(column_def);
        row_data.map((row) => {
            column_def.map((value, index) => {
                switch (value.type) {
                    case 'date':
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined ? dateFormatter(row[value.field]) : '';
                        break;
                    case 'datetime':
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined ? dateTimeFormatter(row[value.field]) : '';
                        break;
                    case 'currency':
                        footer[value.field] += row[value.field] ? parseFloat(row[value.field]) : 0;
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined
                                ? currencyFormatter(row[value.field])
                                : currencyFormatter(0);
                        break;
                    case 'number':
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined ? parseInt(row[value.field]) : 0;
                        break;
                    case 'hyper_link':
                        row[`link_${value.field}`] = strip(row[value.field]);
                        break;
                    default:
                        row[value.field] = row[value.field] != null || row[value.field] != undefined ? row[value.field] : '';
                }
            });

            return row;
        });
        column_def.map((value, index) => {
            if (value.type == 'currency') {
                footer[value.field] = currencyFormatter(footer[value.field]);
            }
        });
        if (type == 'payments') {
            this.payments_footer = footer;
        } else {
            this.adjustments_footer = footer;
        }
        return row_data;
    };

    get_grid_ref_payments = (grid_params) => {
        this.setState({
            grid_params_payments: grid_params,
            payments_rows_count: grid_params.api.getModel().getRowCount()
        });

        if (this.total_column_width > this.grid_width) {
            this.payments_column_defs.filter((item, index) => {
                grid_params.columnApi.setColumnWidth(item.field, item['width'], false);
            });
        }
    };
    get_grid_ref_adjustments = (grid_params) => {
        this.setState({
            grid_params_adjustments: grid_params,
            adjustments_rows_count: grid_params.api.getModel().getRowCount()
        });
        if (this.total_column_width_adjustment > this.grid_width) {
            this.adjustments_column_defs.filter((item, index) => {
                grid_params.columnApi.setColumnWidth(item.field, item['width'], false);
            });
        }
    };
    //Function calls after click of filter button and recalculate the footer sum.
    on_filter_button_click = (type) => {
        var row_api = this.state[`grid_params_${type}`].api;
        var footer_obj = this.initialize_footer_obj(this[`${type}_column_defs`]);
        var footer_cell_data;
        let row_count = 0; // Update row count
        if (row_api) {
            row_api.forEachNodeAfterFilter((node, index) => {
                const row = node.data;
                this[`${type}_column_defs`].map((value, index) => {
                    switch (value.type) {
                        case 'currency':
                            if (row[value.field].indexOf('(') != -1) {
                                footer_cell_data = row[value.field] ? '-' + row[value.field].replace(/[$,()]/g, '') : 0;
                            } else {
                                footer_cell_data = row[value.field] ? row[value.field].replace(/[$,()]/g, '') : 0;
                            }
                            footer_obj[value.field] += isNaN(parseFloat(footer_cell_data)) ? 0 : parseFloat(footer_cell_data);
                            break;
                        default:
                            break;
                    }
                });
            });

            this[`${type}_column_defs`].map((value, index) => {
                if (value.field !== 'group' && value.type == 'currency') {
                    footer_obj[value.field] = currencyFormatter(footer_obj[value.field]);
                }
            });

            this[`${type}_footer`] = footer_obj;
            if (row_api.getPinnedBottomRow(0)) {
                row_api.setPinnedBottomRowData([footer_obj]);
            }
        }

        this.setState({ [`${type}_rows_count`]: row_api.getModel().getRowCount() });
    };
    
    // Used to create the title for export and print.
    set_title = (separator) => {
        this.current_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
        let print_export_subtitle =  this.title && this.title.replace('<br>', separator)
        return (this.page_metadata.title +
            separator +
            print_export_subtitle +
            separator +
            this.current_date +
            separator +
            separator);
    };

    // Function calls on initialization of export report data
    on_export_button = () => {
        let maintitle = this.set_title('\n');
        let row_count = this.state.grid_params_payments.api.rowModel.rowsToDisplay.length;
        let count = row_count == 1 ? row_count + ' record' : row_count + ' records';
        let title_payments = get_sub_title('Payments', '\n', count);
        let title = `${maintitle}${title_payments}`;

        let row_count_adjustments = this.state.grid_params_adjustments.api.rowModel.rowsToDisplay.length;
        let count_adjustments =
            row_count_adjustments == 1 ? row_count_adjustments + ' record' : row_count_adjustments + ' records';
        let title_adjustments = get_sub_title('\n\nAdjustments', '\n', count_adjustments);

        let titles = [title, title_adjustments];
        let grid_params = [this.state.grid_params_payments, this.state.grid_params_adjustments];
        multi_grid_export(grid_params, titles, this.page_metadata.title, []);
        grid_params = [];
    };

    // Function calls on initialization of Print report data
    on_print_button = () => {
        let maintitle = this.set_title('<br/>');
        let row_count = this.state.grid_params_payments.api.rowModel.rowsToDisplay.length;
        let count = row_count == 1 ? row_count + ' record' : row_count + ' records';
        let title_payments = get_sub_title('Payments', '<br/>', count);
        let title = `${maintitle}${title_payments}`;

        let row_count_adjustments = this.state.grid_params_adjustments.api.rowModel.rowsToDisplay.length;
        let count_adjustments =
            row_count_adjustments == 1 ? row_count_adjustments + ' record' : row_count_adjustments + ' records';
        let title_adjustments = get_sub_title('<br/><br/>Adjustments', '<br/>', count_adjustments);

        let titles = [title, title_adjustments];
        let grid_params = [this.state.grid_params_payments, this.state.grid_params_adjustments];
        multi_grid_print(grid_params, titles, this.page_metadata.title, []);
        grid_params = [];
    };

   
    controls_toggler = (type) => {
        if (this.is_mounted) {
            this.setState({
                controls_is_expended: {
                    ...this.state.controls_is_expended,
                    [type]: !this.state.controls_is_expended[type]
                }
            });
        }
    };

    show_drill_down_title = () => { 
        this.setState({
            drill_down_title_shown : !this.state.drill_down_title_shown
        })
    }

    //Used to create the title for export and print.
    set_drill_down_title = () => {
        this.title = this.title && this.title
        .replace('%20', ' ')
        .replace('%2c', ',')
        .replace('%23', '#')
        .replace(/<Font Color=red>/g, '')
        .replace(/<font color=red>/g, '')
        .replace(/<\/Font>/g, '')
        .replace(/<\/font>/g, '')
        .replace('-1', 'All');
        return decodeURIComponent(this.title);
    }

    render() {
        const payments_calculatedHeight = this.state.payments_rows_count * 28 + 75;
        const payments_grid_height = payments_calculatedHeight <= 480 ? payments_calculatedHeight : 480;
        const adjustments_calculatedHeight = this.state.adjustments_rows_count * 28 + 75;
        const adjustments_grid_height = adjustments_calculatedHeight <= 480 ? adjustments_calculatedHeight : 480;

        return (
            <LoaderComponent loading={this.state.loading}>
                <div className={'common-forms-add'}>
                    <div
                        className={'common-forms-search report-framework subReport'}
                        style={{ display: 'flex' }}
                    >
                        <SubReportHeader
                            title={this.page_metadata.title+'<br>'}
                            length={null}
                            show_drilldown_title={this.show_drill_down_title} 
                            drilldown_title_shown={this.state.drill_down_title_shown}
                            drilldown_title={this.set_drill_down_title()}
                        />
                        <div
                            style={
                                this.state.grid_visible
                                    ? { overflow: 'auto', paddingRight: 20, flex: '1 1 0' }
                                    : { paddingRight: 20, flex: '1 1 0' }
                            }
                        >
                            <div
                                id='report-scrollable-area'
                                className='report-wrapper'
                                style={
                                    this.state.grid_visible
                                        ? { flex: 1, background: 'transparent', border: 0 }
                                        : { background: 'transparent', border: 0 }
                                }
                            >
                                {this.state.grid_visible && (
                                    <Grid style={{ marginTop: 10, marginBottom: 0 }}>
                                        <Grid.Column computer={16} tablet={16} textAlign='left' className='accordionColumn'>
                                            <Accordion fluid styled style={{ marginBottom: 15 }}>
                                                <Accordion.Title
                                                    active={this.state.controls_is_expended.payments}
                                                    index={0}
                                                    onClick={(e) => this.controls_toggler('payments')}
                                                >
                                                    <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name='angle right' />
                                                    Payments
                        </Accordion.Title>
                                                <Accordion.Content
                                                    active={this.state.controls_is_expended.payments}
                                                    style={{ paddingBottom: 0 }}
                                                >
                                                    <Grid.Row>
                                                        <Grid style={{ margin: 0 }}>
                                                            <Grid.Column tablet={5} computer={4} textAlign='left'>
                                                                <p style={{ fontSize: '16px' }}> </p>
                                                            </Grid.Column>
                                                            <Grid.Column tablet={5} computer={8} textAlign='center'>
                                                                <p style={{ fontSize: '16px', minHeight: 22 }}>
                                                                    {`${this.state.payments_rows_count} ${
                                                                        this.state.payments_rows_count == 1 ? ' record shown' : ' records shown'
                                                                        }`}
                                                                </p>
                                                            </Grid.Column>
                                                            <Grid.Column tablet={2} computer={4} textAlign='right' />
                                                        </Grid>
                                                        <GridView
                                                            id={this.page_metadata.payment_summery_grid}
                                                            column={this.payments_column_defs}
                                                            row={this.state.payments_rows}
                                                            headerHeight={this.grid_header_height}
                                                            get_grid_ref={this.get_grid_ref_payments}
                                                            enableColResize={true}
                                                            emptyMessage={messages.no_records_found}
                                                            headerIdForTabNavigation={this.page_metadata.payment_summery_header_id}
                                                            suppressSizeToFit={true}
                                                            style={{ height: payments_grid_height, minHeight : 110 }}
                                                            pinnedBottomRowData={[this.payments_footer]}
                                                            on_filter_button_click={() => this.on_filter_button_click('payments')}
                                                        />
                                                    </Grid.Row>
                                                </Accordion.Content>
                                            </Accordion>
                                            <Accordion fluid styled>
                                                <Accordion.Title
                                                    active={this.state.controls_is_expended.adjustments}
                                                    index={0}
                                                    onClick={(e) => this.controls_toggler('adjustments')}
                                                >
                                                    <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name='angle right' />
                                                    Adjustments
                        </Accordion.Title>
                                                <Accordion.Content
                                                    active={this.state.controls_is_expended.adjustments}
                                                    style={{ paddingBottom: 0 }}
                                                >
                                                    <Grid.Row>
                                                        <Grid style={{ margin: 0 }}>
                                                            <Grid.Column tablet={5} computer={4} textAlign='left'>
                                                                <p style={{ fontSize: '16px' }}> </p>
                                                            </Grid.Column>
                                                            <Grid.Column tablet={5} computer={8} textAlign='center'>
                                                                <p style={{ fontSize: '16px', minHeight: 22 }}>
                                                                    {`${this.state.adjustments_rows_count} ${
                                                                        this.state.adjustments_rows_count == 1 ? ' record shown' : ' records shown'
                                                                        }`}
                                                                </p>
                                                            </Grid.Column>
                                                            <Grid.Column tablet={2} computer={4} textAlign='right' />
                                                        </Grid>
                                                        <GridView
                                                            id={this.page_metadata.adjustment_summery_grid}
                                                            column={this.adjustments_column_defs}
                                                            row={this.state.adjustments_rows}
                                                            headerHeight={this.grid_header_height_adjustment}
                                                            get_grid_ref={this.get_grid_ref_adjustments}
                                                            enableColResize={true}
                                                            emptyMessage={messages.no_records_found}
                                                            headerIdForTabNavigation={this.page_metadata.adjustment_summery_header_id}
                                                            suppressSizeToFit={true}
                                                            style={{ height: adjustments_grid_height, minHeight : 110 }}
                                                            pinnedBottomRowData={[this.adjustments_footer]}
                                                            on_filter_button_click={() => this.on_filter_button_click('adjustments')}
                                                        />
                                                    </Grid.Row>
                                                </Accordion.Content>
                                            </Accordion>
                                        </Grid.Column>
                                    </Grid>
                                )}
                            </div>
                        </div>
                        <div
                            className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                            id='applicationFooterSticky'
                            style={{ paddingRight: 25 }}
                        >
                            {this.state.grid_visible && (
                                <ReportFooter
                                    on_print_button={this.on_print_button}
                                    on_export_button={this.on_export_button}
                                    //export_disabled={this.state.is_search_disabled}
                                    //print_disabled={this.state.is_search_disabled}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </LoaderComponent>
        );
    }
}

// Get user and login details from store.
const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details
    };
};

export default connect(mapStateToProps)(PaymentAdjustmentSummaryByPosComponent);
