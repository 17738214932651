// Patient letter preview component
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Checkbox, Dimmer, Form, Grid, Header, Loader } from 'semantic-ui-react';
import * as session_storage from '../../shared/session_storage_utility';
import { set_active_patient } from '../../login/action/login_action';
import {
    handle_click_on_enter,
    set_focus_to_app_header,
    set_focus_on_element_with_id
} from './../../shared/tab_navigation_utility';
import { get_patient_letter_preview } from './../action/patient_letter_action';
import { AlertConfirm } from '../../shared/component/alert_confirm_component';
import { patient_messages } from '../patient_constants';
import { add_patient_account_notes, get_patient_account_notes_list } from '../action/patient_action';
import { printPDF } from '../../reports/util/export_print_utility';

// Patient letter preview component
export class PatientLetterPreviewComponent extends React.Component<any, any> {

    _is_mounted = false;
    scroll_height = 0;

    constructor(props) {

        super(props);
        // Initialize state property here
        this.state = {
            loading: true,
            letter_preview: null,
            open_confirm_alert: false,
            confirm_message: '',
            is_print_ready: false
        };
    }

    // Error Handling in Component. 
    componentDidCatch(error, info) {
        if (this._is_mounted) {
            this.setState({
                loading: false,
            });
        }
    }

    UNSAFE_componentWillMount() {
        this._is_mounted = true;
    }

    componentDidMount() {
        this._is_mounted = true;
        // setting the focus on edit button for the first time, when component mounts.
        set_focus_on_element_with_id("view_patient_edit_button");
        document.body.classList.add('patient-view');
        if (this.props.location && this.props.location.state) {
            const { patient_id, letter_id, case_id } = this.props.location.state;
            this.get_letter_preview(patient_id, letter_id, case_id);
        }
    }

    componentWillUnmount() {
        this._is_mounted = false;
        document.body.classList.remove('patient-view');
    }

    get_letter_preview = (patient_id, letter_id, case_id) => {
        let token = this.props.user_login_details.user_data.data.token_details.access_token;
        let query_string = `patient_id=${patient_id}&letter_id=${letter_id}&case_id=${case_id}`;
        get_patient_letter_preview(token, query_string).then(
            response => {
                if (response && response.data && response.data.data) {
                    this.setState({
                        letter_preview: response.data.data,
                        loading: false,
                        is_print_ready: true
                    })
                }
            },
            error => {
                this.setState({
                    letter_preview: null,
                    loading: false
                })
            }
        );
    }

    handle_yes = (e) => {
        e.preventDefault();
        this.setState(
            { open_confirm_alert: false },
            () => { this.add_note() }
        );
    }

    handle_no = (e) => {
        e.preventDefault();
        this.setState(
            { open_confirm_alert: false },
            () => { this.print_letter() }
        );
    }

    on_print_click = () => {
        this.setState({
            open_confirm_alert: true,
            confirm_message: patient_messages.patient_letter_preview_alert
        });
    }

    add_note = async () => {
        if (this.props.location && this.props.location.state) {
            const { patient_id, letter_id, case_id } = this.props.location.state;
            const token = this.props.user_login_details.user_data.data.token_details.access_token;
            const note_payload = {
                patient_id: this.props.location.state.patient_id,
                company_id: this.props.user_login_details.user_data.data.company_id,
                note_code_id: 0,
                note_text: `${this.props.location.state.letter_name || ''} - Letter Generated`,
                show_on_patient: false,
                show_on_charge: false,
                show_on_payment: false,
                show_on_case: false,
                is_approved: true,
                stick_on: null
            }
            await this.props.add_patient_account_notes(patient_id, token, note_payload);
            this.props.get_patient_account_notes_list(patient_id, token, this.props.data_model_key);
            this.print_letter();
        }
    }

    print_letter = () => {
        let printElement: any;
        let print_report_button = document.getElementById("btn-patient-letter-preview");
        if (document.getElementById("formLetters")) {
            document.getElementById("formLetters").remove();
            document.body.style.overflow = null;
        }
        let is_chrome = navigator.userAgent.indexOf("Chrome") > -1; 
        let margin = is_chrome ? '20px' : '10px';
        let print_data = `<style>@media print {
                @page { size: auto; margin: ${margin} !important; }
                body { padding:10px; }
                p { margin: 20px; }
            }</style>`;
        printElement = document.createElement("div");
        printElement.setAttribute("id", "formLetters");
        print_data += `<p>${this.state.letter_preview}</p>`;
        printElement.innerHTML = print_data;
        document.body.setAttribute("style", "overflow:visible!important");
        document.body.appendChild(printElement);
        printPDF(printElement, print_report_button, 'Form Letter');
    }

    render() {
        return (<React.Fragment>
            <Dimmer active={this.state.loading}>
                <Loader size='massive'>Loading</Loader>
            </Dimmer>
            <div onKeyDown={handle_click_on_enter} className="sixteen wide computer sixteen wide mobile sixteen wide tablet column patient-menu" id="applicationPageTitleSticky">
                <h3 className="ui header">Form Letters</h3>
            </div>
            <div className="auto-height-patient-scroll" id="scrollable-content-area">
                <div className="patient-letter-preview" dangerouslySetInnerHTML={{
                    __html: this.state.letter_preview || ''
                }} />
            </div>
            <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area" id="applicationFooterSticky">
                <Button
                    id='cancel_button_id'
                    type='button'
                    onClick={() => this.props.history.goBack()}
                    basic>Cancel</Button>
                <Button
                    id="btn-patient-letter-preview"
                    type="submit"
                    onKeyDown={set_focus_to_app_header}
                    className="ui primary button"
                    role="button"
                    onClick={this.on_print_click}>Print</Button>
            </div>
            <AlertConfirm
                title={'Form Letter'}
                labelYes={'Yes'}
                labelNo={'No'}
                open={this.state.open_confirm_alert}
                message={this.state.confirm_message}
                confirm={this.handle_yes}
                cancel={this.handle_no}
                close={() => this.setState({ open_confirm_alert: false })}
            />
        </React.Fragment>);
    }
}

// Function used for getting data from actions
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        add_patient_account_notes: add_patient_account_notes,
        get_patient_account_notes_list: get_patient_account_notes_list
    }, dispatch)
}

const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        patient_details: state.patient_details

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientLetterPreviewComponent);