import { messages } from "../../reports/report_constants";
import { downloadCSV } from "../../reports/util/export_print_utility";
import { removeClass, addClass, checkboxElementById } from '../../shared/utility';
import * as _ from 'lodash';

export const location_name = (val) => {
    let pos = '';
    if (val) {
        const { code, name } = val;
        if (code) {
            pos = `${code}`
        }
        if (name) {
            pos = `${pos} - ${name}`
        }
    }
    return pos;
}

//Location sortable value in the billing settings columns
export const location_label = (val) => {
    let pos = '';
    if (val) {
        if (val.location_code) {
            pos = `${val.location_code}`
        }
        if (val.location_name) {
            pos = `${val.location_code} - ${val.location_name}`
        }
    }
    return pos;
}

export const insurance_name = (val) => {
    let name = '';
    if (val) {
        const { insurance_class_code, insurance_class_description } = val;
        if (insurance_class_code) {
            name = `${insurance_class_code}`
        }
        if (insurance_class_description) {
            name = `${name} - ${insurance_class_description}`
        }
    }
    return name;
}

export const get_insurance_code = (insurance) => {
    let pos = '';
    if (insurance) {
        const { insurance_code, insurance_name } = insurance;
        if (insurance_code) {
            pos = `${insurance_code}`
        }
        if (insurance_name) {
            pos = `${pos} - ${insurance_name}`
        }
    }
    return pos;
}

export const company_name = (data) => {
    let value = '';
    if (data) {
        if (data.gpms_code) {
            value = `${data.gpms_code}`;
        }
        if (data.name) {
            value = `${value} - ${data.name}`;
        }
    }
    return value;
};

export const provider_name = (val) => {
    let provider = '';
    if (val) {
        const { provider_code, description } = val;
        if (provider_code) {
            provider = `${provider_code}`
        }
        if (description) {
            provider = `${provider} - ${description}`
        }
    }
    return provider;
}

export const is_alpha_numeric = (value) => {
    var letters = /^[0-9a-zA-Z]+$/;
    if (value.trim().length > 0 && !value.match(letters)) {
        return false;
    }

    return true;
}

export const get_selected_insurance_code_value = (selected_row) => {
    var format_data = { "label": '', "title": '', "insurance_code": '' };
    if (selected_row) {
        var id = '';
        var name = '';
        var code = '';
        var adddress = '';
        var label = '';
        if (selected_row) {
            id = selected_row.id;
            if (selected_row.insurance_code != null && selected_row.insurance_code != undefined) {
                if (selected_row.insurance_code != null && selected_row.insurance_code != undefined) {
                    label = selected_row.insurance_code;
                    code = selected_row.insurance_code;
                }
                if (selected_row.insurance_name != null && selected_row.insurance_name != undefined) {
                    label = `${label} - ${selected_row.insurance_name}`;
                }
                if (selected_row.address1 != null && selected_row.address1 != undefined) {
                    label = `${label} - ${selected_row.address1}`;
                }
            }
        }

        // Set Auto search control for insurance
        format_data = {
            ...selected_row,
            "insurance_code": code,
            "title": `${id}`,
            "label": label,
        };
    }
    return format_data;
}

export const groupArr = (data, n) => {
    var group = [];
    for (var i = 0, j = 0; i < data.length; i++) {
        if (i >= n && i % n === 0) j++;
        group[j] = group[j] || [];
        group[j].push(data[i]);
    }
    return group;
}


export const manage_print_grid = (columns, row_data, all_selected, all_disabled) => {
    let printContent = '';
    printContent += '<table id="mainGrid" class="ui fixed table">';
    printContent += '<thead>';
    // Render Grid Header
    printContent += '<tr>';
    columns.map((colData) => {
        if (colData.field === 'checkbox') {
            printContent += `
            <th class="center" style="width:${colData.width}px">
                <div class="ui checkbox">
                    <input type="checkbox" ${all_disabled ? 'disabled' : ''}
                    ${all_selected ? 'checked' : ''}
                    value=${all_selected} style="opacity:1 !important"/>
                </div>
            </th>`;
        } else {
            printContent += `<th>${colData.headerName}</th>`;
        }
    });
    printContent += '</tr>';
    printContent += '</thead>';
    printContent += '<tbody>';
    if (row_data.length > 0) {
        row_data.forEach((data) => {
            printContent += `<tr>`;
            columns.map((colData) => {
                if (colData.field === 'checkbox') {
                    printContent += `
            <td class="center">
                <div class="ui checkbox">
                    <input type="checkbox" ${data.is_disabled ? 'disabled' : ''} ${data.is_current ? 'checked' : ''} value=${
                        data.selected
                        } style="opacity:1 !important"/>
                </div>
            </td>`;
                } else {
                    if (data[colData.field]) {
                        printContent += `<td>${data[colData.field]}</td>`;
                    } else {
                        printContent += `<td></td>`;
                    }
                }
            });
            printContent += `</tr>`;
        });
    } else {
        printContent += `<tr><td colspan="100%" class="no_records">${messages.no_records_found}</td></tr>`;
    }
    printContent += '</tbody>';
    printContent += '</table>';
    return printContent;
};

export const export_835_exclusion_report = (data, company) => {
    const main_header = ['Error Description', 'Exclusions', 'Last Updated By', 'Last Updated Date'];
    const child_header = ['','Payer Code', 'Description', 'Outbound ID'];
    const title = ['Exclusion Report'];
	const company_name = [company];
	var tmprptdate = new Date().toLocaleString(); 
    const records = [data.length + ' Errors']

    let csv = [];
    csv.push(title);
    csv.push(company_name);
	csv.push([tmprptdate]);
    csv.push(records);
    csv.push(['']);
    csv.push(['']);

    csv.push(main_header);

    data.map(mainRow => {
        // main row
        let main_row = [];
        main_row.push(mainRow.error_description);
        main_row.push(mainRow.exclusions);
        main_row.push(mainRow.last_updated_by);
		var tmpdate = mainRow.last_updated_date;
		if (tmpdate != null) {
			var tmpdateISO = tmpdate.getFullYear() + '-' + ("00" + (tmpdate.getMonth() + 1)).slice(-2) + '-' + ("00" + tmpdate.getDate()).slice(-2) + 'T' + ("00" + tmpdate.getHours()).slice(-2) + ':' + ("00" + tmpdate.getMinutes()).slice(-2) + ':' + ("00" + tmpdate.getSeconds()).slice(-2);
			main_row.push(tmpdateISO);
		}
		else {
			main_row.push(' ');
		}
        csv.push(main_row);

        //child row
        csv.push(child_header);

        mainRow.payers.map(payers => {
            let child_row = [];
            child_row.push('');
            child_row.push(payers.payer_code + '\t');
            child_row.push(payers.description);
            child_row.push(payers.outbound_id);
            csv.push(child_row);
        });

    });
    downloadCSV(csv, "835 Error Exclusions");

}

export const handleCellCheckboxDeleteAccountNote = (params: any, id: string, colname: string, component: any) => {
    let eDiv: any = document.createElement('div');
    eDiv.innerHTML = `${cellCheckboxDeleteAccountNoteRender(params, id)}`;
    let eCheckbox: any = eDiv.querySelector('input[type=checkbox]');
    let span: any = eDiv.querySelector(`#rowSpanUnchecked_${params.node.data.noteId}`);

    if (eCheckbox) {
        if (params.node.data.isActive === 'Active') {
            eCheckbox.addEventListener('change', (event: any) => {
                params.node.setDataValue(colname, event.target.checked);
                handleDeleteAccountNoteCheckbox(params, event.target.checked, params.node, component)
            });
        } else {
            if (!params.node.data.is_checked) {
                eCheckbox.setAttribute('disabled', 'true');
                if (span) {
                    addClass(span, 'disabledCursor');
                }
            }
        }
    }
    return eDiv;
};

export const cellCheckboxDeleteAccountNoteRender = (params: any, type: string) => {
    let id: string = `checkb_${type}_${params.data.noteId}`;
    let spn: string = `span_${type}_${params.data.noteId}`;
    return `
      <span ref="eCellWrapper" class="ag-cell-wrapper grid-checkbox" id='${spn}'>
        <input type='checkbox' ${params.value ? 'checked' : ''} id="${id}" />
        <span class="ag-selection-checkbox">
          <span id="rowSpanChecked_${params.data.noteId}" class="ag-icon ag-icon-checkbox-checked ${params.value ? '' : 'ag-hidden'}"></span>
          <span id="rowSpanUnchecked_${params.data.noteId}" class="ag-icon ag-icon-checkbox-unchecked ${!params.value ? '' : 'ag-hidden'}"></span>
        </span>
      </span>`;
};

export const evaluateSelectAllDeleteAccountNote = (params: any, component: any) => {
    let renderedNodeschecked: any = params.api.getRenderedNodes().map((node: any) => {
        let checkboxDeleteAccountNoteRow: any = document.querySelector(`#checkb_checkboxAccountNote_${node.data.noteId}`);
        if (checkboxDeleteAccountNoteRow && node.data.isActive === 'Active') {
            return checkboxDeleteAccountNoteRow.checked
        }
    });
    let allChecked: string = 'unchecked';
    if (_.includes(renderedNodeschecked, true) || _.includes(renderedNodeschecked, false)) {
        if (!_.includes(renderedNodeschecked, false)) {
            allChecked = 'checked';
        } else if (_.includes(renderedNodeschecked, true) && _.includes(renderedNodeschecked, false)) {
            allChecked = 'indeterminate';
        }
    }

    let elements: any = checkboxElementById('checkAllId');
    if (elements['checked_checkAllId']) {
        switch (allChecked) {
            case 'checked':
                removeClass(elements['checked_checkAllId'], 'ag-hidden');
                addClass(elements['unchecked_checkAllId'], 'ag-hidden');
                addClass(elements['indeterminate_checkAllId'], 'ag-hidden');
                elements['checkbox_checkAllId'].setAttribute("deleteAccountNoteChecked", true);
                elements['checkbox_checkAllId'].setAttribute("deleteAccountNoteIndeterminate", false);
                break;
            case 'unchecked':
                removeClass(elements['unchecked_checkAllId'], 'ag-hidden');
                addClass(elements['checked_checkAllId'], 'ag-hidden');
                addClass(elements['indeterminate_checkAllId'], 'ag-hidden');
                elements['checkbox_checkAllId'].setAttribute("deleteAccountNoteChecked", false);
                elements['checkbox_checkAllId'].setAttribute("deleteAccountNoteIndeterminate", false);
                break;
            case 'indeterminate':
                removeClass(elements['indeterminate_checkAllId'], 'ag-hidden');
                addClass(elements['checked_checkAllId'], 'ag-hidden');
                addClass(elements['unchecked_checkAllId'], 'ag-hidden');
                elements['checkbox_checkAllId'].setAttribute("deleteAccountNoteChecked", false);
                elements['checkbox_checkAllId'].setAttribute("deleteAccountNoteIndeterminate", true);
                break;
            default:
                break;
        }

        removeClass(elements['unchecked_checkAllId'], 'disabled');
        removeClass(elements['checkbox_checkAllId'], 'disabledCursor');
        removeClass(elements['indeterminate_checkAllId'], 'disabled');
        removeClass(elements['indeterminate_checkAllId'], 'disabledCursor');
    }
};

export const handleDeleteAccountNoteCheckbox = (params: any, checked: any, node: any, component: any) => {
    let renderedNodes: Array<number> = params.api.getRenderedNodes().map((node: any) => node.data.noteId)
    if (checked && node.data.isActive === 'Active' && renderedNodes.indexOf(node.data.noteId) >= 0) {
        component.setState({
            selectedRows: [...component.state.selectedRows, node.data]
        });
    }
    if (!checked && renderedNodes.indexOf(node.data.noteId) >= 0) {
        component.setState({
            selectedRows: _.remove(component.state.selectedRows, (row: any) => row.noteId != node.data.noteId)
        });
    }

    if (!node.data.is_checked) {
        params.api.forEachNode((rowNode: any) => {
            let checkboxAccountNoteRow: any = document.querySelector(`#checkb_checkboxAccountNote_${rowNode.data.noteId}`);
            let spanDeleteAccountNoteRow: any = document.querySelector(`#rowSpanUnchecked_${rowNode.data.noteId}`);
            if (checkboxAccountNoteRow && rowNode.data.isActive === 'Active') {
                checkboxAccountNoteRow.removeAttribute('disabled');
                removeClass(checkboxAccountNoteRow, 'disabledCursor');
                removeClass(spanDeleteAccountNoteRow, 'disabledCursor');
            }
        })
    }
    evaluateSelectAllDeleteAccountNote(params, component);
};

export const handleHeaderDeleteAccountNoteSelectAll = (params: any, id: string, colname: string, component: any) => {
    let elements: any = checkboxElementById(id);
    elements[`checkbox_${id}`].addEventListener('click', () => {
        let checkValue: boolean = elements[`checkbox_checkAllId`].getAttribute("deleteAccountNoteChecked") === 'true';
        let indeterminateValue: boolean = elements[`checkbox_checkAllId`].getAttribute("deleteAccountNoteIndeterminate") === 'true';
        let newValue: boolean = !checkValue;
        if (indeterminateValue) {
            newValue = true;
        }
        let renderedNodes: Array<number> = params.api.getRenderedNodes().map((node: any) => node.data.noteId);
        params.api.forEachNodeAfterFilterAndSort((node: any) => {
            if (newValue) {
                if (node.data.isActive == 'Active' && !node.data.is_checked && renderedNodes.indexOf(node.data.noteId) >= 0) {
                    node.setDataValue(colname, newValue);
                    handleDeleteAccountNoteCheckbox(params, newValue, node, component)
                }
            } else {
                if (node.data.is_checked && renderedNodes.indexOf(node.data.noteId) >= 0) {
                    node.setDataValue(colname, newValue);
                    handleDeleteAccountNoteCheckbox(params, newValue, node, component)
                }
            }
        });
    });
};
