enum ReportId {
    CrossCompanyPatientSearchReport = 326,
}

// Add new reportId with exclude sensitive data for each report
export const getSensitiveConditions = (): Map<number, string[]> => {
    const conditions: Map<number, string[]> = new Map<number, string[]>();
    conditions.set(ReportId.CrossCompanyPatientSearchReport, ["@ssn"]);
    
    return conditions;
};
