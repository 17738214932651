import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr as toaster } from "react-redux-toastr";
import * as local_storage from '../../shared/local_storage_utility';
import { Button, Dimmer, Form, Grid, Input, Checkbox, Modal, Popup, Loader, Header } from 'semantic-ui-react';
import DatePicker from '../../shared/component/date_picker_component';
import DateRangePickerComponent from '../../shared/component/date_range_component';
import GridView from '../../shared/component/grid';
import { show_html_content_toaster } from '../../admin/batch_tasks/utility';
import { search_intergration_payment, release_integration_payment, close_integration_payment } from "../action/integration_payment_action";
import { addClass, currency_formatter, custom_date_format, get_all_error, removeClass } from '../../shared/utility';
import { currency_comparator } from '../../shared/component/grid/comparators';
import * as global_constants from '../../global_constants';
import { INTEGRATION_PAYMENT_MESSAGE } from '../payment_constants';
import { messages } from '../../reports/report_constants';

export class ReleasePatientPayment extends React.Component<any, any>{

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            openModal: false,
            showSearchAction: false,
            depositDate: null,
            batchNumber: '',
            token: '',
            companyId: '',
            errorListMessage: [],
            initialDate: null,
            finishDate: null,
            includeInactive: false,
            rowData: [],
            countCheck: 0,
            isValidationError: false,
            messageValidation: '',
            selectedIdItem: {
                status: '',
                columnAction: '',
                integrationPaymentParseId: [],
                errorIds: [],
            }
        }
    }

    page_name = 'gridIntegrationPayment';

    column_defs = [
        {
            headerName: "Release",
            field: "is_release",
            minWidth: 85,
            maxWidth: 85,
            width: 85,
            filter: "textFilter",
            type: "string",
            unSortIcon: true,
            suppressFilter: true,
            suppressSorting: true,
            suppressSizeToFit: true,
            resizable: false,
            suppressMovable: true,
            cellRenderer: (params: any) => {
                return this.handle_cell_checkbox_payment(params, "is_release", "is_close", "status", "Ready");
            }
        },
        {
            headerName: "Close",
            field: "is_close",
            minWidth: 70,
            maxWidth: 70,
            width: 70,
            filter: "textFilter",
            type: "string",
            unSortIcon: true,
            suppressFilter: true,
            suppressSorting: true,
            suppressSizeToFit: true,
            resizable: false,
            suppressMovable: true,
            cellRenderer: (params: any) => {
                return this.handle_cell_checkbox_payment(params, "is_close", "is_release", "status", "Ready");
            }
        },
        {
            headerName: "Filename",
            field: "integrationFileName",
            filter: "textFilter",
            unSortIcon: true,
            type: 'string',
        },
        {
            headerName: "Status",
            field: "status",
            filter: "textFilter",
            unSortIcon: true,
            type: 'string',
            cellRenderer: params => this.charge_review_cell_renderer(params, 'status'),
        },
        {
            headerName: "Load Date",
            field: "loadDate",
            filter: "textFilter",
            unSortIcon: true,
            type: 'date',
            cellRenderer: params => {
                return custom_date_format(params.value, global_constants.date_format["mm/dd/yyyy"])
            }
        },
        {
            headerName: "Amount",
            field: "fileAmountTotal",
            unSortIcon: true,
            type: "currency",
            filter: "currencyFilter",
            comparator: currency_comparator,
            cellStyle: { textAlign: "right" },
            cellRenderer: params => {
                return currency_formatter(params.value);
            }
        },
        {
            headerName: "Company",
            field: "companyName",
            filter: "textFilter",
            unSortIcon: true,
            type: 'string',
        },
        {
            headerName: "Vendor",
            field: "vendorName",
            filter: "textFilter",
            unSortIcon: true,
            type: 'string',
        }
    ];

    cell_checkbox_render_payment = (params, is_disabled: boolean, type: string, altr: string) => {
        let id = is_disabled ? `disabled_${type}_${params.rowIndex}` : `checkb_${type}_${params.rowIndex}`;
        let spn = is_disabled ? `disabled_span${type}_${params.rowIndex}` : `span_${type}_${params.rowIndex}`;
        return `
      <span ref="eCellWrapper" class="ag-cell-wrapper grid-checkbox" id='${spn}'>
        <input type='checkbox' ${params.value ? 'checked' : ''} ${is_disabled || params.data[altr] === true ? 'disabled' : ''} id="${id}" />
        <span class="ag-selection-checkbox">
          <span class="ag-icon ag-icon-checkbox-checked ${params.value ? '' : 'ag-hidden'}"></span>
          <span class="ag-icon ag-icon-checkbox-unchecked ${!params.value ? '' : 'ag-hidden'} ${is_disabled || params.data[altr] === true ? 'disabled' : ''}"></span>
        </span>
      </span>`;
    };

    handle_cell_checkbox_payment = (
        params,
        currentType: string,
        compareType: string,
        compareField: string,
        compareValue: string
    ) => {
        let is_disabled = false;

        if (params.data[compareField] === 'Closed' || params.data[compareField] === 'Released' ||
            (params.data[compareField] === 'Error' && currentType === 'is_release')) {
            is_disabled = true;
        }

        let divObject = document.createElement('div');
        divObject.innerHTML = `${this.cell_checkbox_render_payment(params, is_disabled, currentType, compareType)}`;
        let eCheckbox = divObject.querySelector('input[type=checkbox]');
        if (eCheckbox) {
            if (!is_disabled) {
                let spanCompareType = document.querySelector(`#span_${compareType}_${params.rowIndex}`);
                let spanCurrentType = document.querySelector(`#span_${currentType}_${params.rowIndex}`);
                let checkBoxCompareType = document.querySelector(`#checkb_${compareType}_${params.rowIndex}`);
                let checkBoxCurrentType = document.querySelector(`#checkb_${currentType}_${params.rowIndex}`);

                if (params.data[currentType]) {
                    if (checkBoxCompareType) {
                        checkBoxCompareType.setAttribute('disabled', 'true');
                        spanCompareType ? addClass(spanCompareType.querySelector('.ag-icon-checkbox-unchecked'), 'disabled') : '';
                    }
                    if (checkBoxCurrentType) {
                        checkBoxCurrentType.removeAttribute('disabled');
                        spanCurrentType ? removeClass(spanCurrentType.querySelector('.ag-icon-checkbox-unchecked'), 'disabled') : '';
                    }
                } else {
                    if (checkBoxCurrentType) {
                        checkBoxCurrentType.removeAttribute('disabled');
                        spanCurrentType ? removeClass(spanCompareType.querySelector('.ag-icon-checkbox-unchecked'), 'disabled') : '';
                    }
                    if (checkBoxCompareType) {
                        checkBoxCompareType.removeAttribute('disabled');
                        spanCompareType ? removeClass(spanCompareType.querySelector('.ag-icon-checkbox-unchecked'), 'disabled') : '';
                    }
                }
            }

            eCheckbox.addEventListener('change', (event: any) => {
                if (event.target.checked) {
                    if (this.state.countCheck === 0) {
                        this.setState({
                            countCheck: 1
                        });

                        let integrationPaymentParseId = [];
                        let integrationPaymentErrorsId = [];
                        let status = params.data['status'];
                        integrationPaymentParseId.push(params.data['integrationPaymentParsedId'])
                        integrationPaymentErrorsId.push(params.data['integrationPaymentErrorId'])

                        this.setState({
                            selectedIdItem: {
                                status: status,
                                columnAction: currentType,
                                integrationPaymentParseId: integrationPaymentParseId,
                                errorIds: integrationPaymentErrorsId,
                            }
                        });

                        params.node.setDataValue(currentType, event.target.checked);
                        params.node.setDataValue(compareType, false);
                    }
                    else {
                        event.target.checked = false;
                        const toastr_options = show_html_content_toaster(INTEGRATION_PAYMENT_MESSAGE.FILE_ALREADY_EXIST);
                        toaster.error("", toastr_options);
                    }
                }
                else {
                    this.setState({
                        countCheck: 0
                    });

                    this.initialize_grid_data();
                    params.node.setDataValue(currentType, event.target.checked);
                    params.node.setDataValue(compareType, false);
                }
            });
        }
        return divObject;
    };

    search_intergration_payment = async (param) => {
        await search_intergration_payment(this.state.token, param)
            .then(
                (response) => {
                    this.setState({
                        rowData: response.data.data,
                    });
                    this.setState({
                        loading: false
                    });
                    var response = response;
                },
                (error) => {
                    this.setState({
                        loading: false
                    });
                    if (error.response.data) {
                        const toastr_options = show_html_content_toaster(get_all_error(error.response.data));
                        toaster.error("", toastr_options);
                    }
                    var response = response;
                }
            );
    }

    initialize_grid_data() {
        this.setState({
            countCheck: 0,
            selectedIdItem: {
                status: '',
                columnAction: '',
                integrationPaymentParseId: [],
                errorIds: [],
            }
        });
    }

    release_payment = async (param) => {
        await release_integration_payment(this.state.token, param).then(
            (response) => {
                this.setState({
                    loading: false
                });
                if (response.data.status === 1) {
                    this.handle_search();
                    const toastr_options_end_process = show_html_content_toaster(INTEGRATION_PAYMENT_MESSAGE.PAYMENT_RELEASED);
                    toaster.success("", toastr_options_end_process);
                    this.initialize_grid_data();
                } else {
                    const toastr_options_end_process = show_html_content_toaster(response.data.messages[0].message.toString());
                    toaster.error("", toastr_options_end_process);
                }
            },
            (error) => {
                this.setState({
                    loading: false
                });
                if (error.response.data) {
                    const toastr_options = show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error("", toastr_options);
                }
            }
        );
    }

    close_payment = async (param) => {
        var response = await close_integration_payment(this.state.token, param).then(
            (response) => {
                this.setState({
                    loading: false
                });
                if (response.data.status === 1) {
                    this.handle_search();
                    const toastr_options_end_process = show_html_content_toaster(INTEGRATION_PAYMENT_MESSAGE.PAYMENT_CLOSED);
                    toaster.success("", toastr_options_end_process);
                    this.initialize_grid_data();
                } else {
                    const toastr_options_end_process = show_html_content_toaster(response.data.messages[0].message.toString());
                    toaster.error("", toastr_options_end_process);
                }
            },
            (error) => {
                this.setState({
                    loading: false
                });
                if (error.response.data) {
                    const toastr_options = show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error("", toastr_options);
                }
            }
        );
    }

    UNSAFE_componentWillMount = () => {
        this.setState({
            loading: false
        })
    }

    componentDidMount() {
        document.body.classList.add('admin-framework');
        this.setState({
            companyId: local_storage.get("auth_data").company_id,
            token: local_storage.get("auth_data").token_details.access_token,

        });
    };

    componentWillUnmount() {
        document.body.classList.remove('admin-framework');
        this.setState({
            companyId: local_storage.get("auth_data").company_id,
            token: local_storage.get("auth_data").token_details.access_token,
        });
    };

    required_range_date_validator = () => {
        let is_error = false;
        let messageValidation = '';
        if (moment.isMoment(this.state.initialDate) && moment.isMoment(this.state.finishDate)) {
            if (moment(this.state.initialDate).isAfter(this.state.finishDate) || moment(this.state.finishDate).isBefore(this.state.initialDate)) {
                this.setState({
                    isValidationError: true
                });
                is_error = true;
                messageValidation = 'Please enter a valid range date.';
            }
        }
        else {
            this.setState({
                isValidationError: true
            });
            is_error = true;
            messageValidation = 'Date Range is required.';
        }

        if (is_error) {
            const toastr_options_process = show_html_content_toaster(messageValidation);
            toaster.warning("", toastr_options_process);
        }
        return is_error;
    };

    required_release_validator = () => {
        let is_error = false;
        let messageValidation = '';
        if (this.state.depositDate === null) {
            this.setState({
                isValidationError: true
            });
            is_error = true;
            messageValidation = 'Deposit date is required.';
        }
        else {
            this.setState({
                isValidationError: false
            });
            is_error = false;
        }

        if (is_error) {
            const toastr_options_process = show_html_content_toaster(messageValidation);
            toaster.warning("", toastr_options_process);
        }
        return is_error;
    };

    handle_close_popup = () => {
        this.setState({
            openModal: false
        });
    }

    handle_show_popup = () => {
        this.setState({
            openModal: true
        });
    }

    handle_search = async () => {
        if (this.required_range_date_validator()) {
            return;
        }
        this.setState({
            showSearchAction: true,
            loading: true,
            depositDate: null,
            batchNumber: ''
        });

        let param = {
            "InitialDate": moment(this.state.initialDate).format('YYYY-MM-DD'),
            "FinishedDate": moment(this.state.finishDate).format('YYYY-MM-DD'),
            "IncludeInactive": this.state.includeInactive ? true : false,
            "CompanyId": this.state.companyId
        }
        var response = await this.search_intergration_payment(param);
    }

    handle_clean = () => {
        this.setState({
            rowData: [],
            depositDate: null,
            batchNumber: '',
            initialDate: null,
            finishDate: null,
            includeInactive: false,
            countCheck: 0,
            selectedIdItem: {
                status: '',
                columnAction: '',
                integrationPaymentParseId: [],
                errorIds: [],
            }
        });
    }

    handle_statement_date_change = (dt) => {
        this.setState({
            depositDate: dt,
        });
    };

    handle_input_change = e => {
        const { name, value } = e.target;
        this.setState({
            batchNumber: value
        });
    };

    handle_process = async () => {
        if (this.state.countCheck === 0) {
            const toastr_options_process = show_html_content_toaster(INTEGRATION_PAYMENT_MESSAGE.SELECT_ONE_ROW);
            toaster.warning("Select Row", toastr_options_process);
            return;
        }

        if (this.state.selectedIdItem.columnAction === 'is_release') {
            if (this.required_release_validator()) {
                return;
            }
            this.show_loading(true);

            let paramRelease = {
                "IntegrationPaymentParsedIds": this.state.selectedIdItem.integrationPaymentParseId,
                "DepositDate": moment(this.state.depositDate).format('YYYY-MM-DD'),
                "BatchNumber": this.state.batchNumber,
                "CompanyId": this.state.companyId
            };

            await this.release_payment(paramRelease);
        }
        else {
            this.show_loading(true);

            let paramClose = {
                "IntegrationPaymentParsedIds": this.state.selectedIdItem.integrationPaymentParseId,
                "IntegrationPaymentErrorIds": this.state.selectedIdItem.errorIds,
            };

            await this.close_payment(paramClose);
        }
    }

    show_loading = (isShow) => {
        this.setState({
            loading: isShow
        });

        const toastr_options_process = show_html_content_toaster(INTEGRATION_PAYMENT_MESSAGE.PROCESSING_WILL_ALERT);
        toaster.warning("", toastr_options_process);
    }


    handle_date_change = (startDate, endDate) => {
        this.setState({
            initialDate: startDate,
            finishDate: endDate
        });
    }

    charge_review_cell_renderer = (params, col_name) => {
        var eDiv = document.createElement('div');
        var icon = params.value;
        if (col_name == 'status') {
            if (params.value == "Error") {
                eDiv.addEventListener('click', (param) => {
                    this.setState({
                        errorListMessage: params.data.errorListMessage
                    });
                    this.handle_show_popup();
                });
            }
        }
        eDiv.innerHTML = `<span>${icon}</span>`;
        return eDiv;
    }

    render() {
        return (
            <React.Fragment>
                <Dimmer active={this.state.loading}>
                    <Loader size="massive">Loading</Loader>
                </Dimmer>
                <Modal
                    open={this.state.openModal}
                    onClose={this.handle_close_popup}
                    centered={false}
                    closeIcon
                    closeOnDimmerClick={false}
                >
                    <Modal.Header>{'Integration Payment Error'}</Modal.Header>
                    <Modal.Content image scrolling>
                        <Modal.Description>
                            <h4><b>Contact support with your error info.</b></h4>
                            {this.state.errorListMessage.map((item, position) => {
                                if (position < 5)
                                    return (<div key={position}>
                                        <b>Error#</b>{position + 1}<br/>
                                        <b>Message:</b> {item['errorMessage']}<br/>
                                        <b>Segment Name:</b> {item['segmentName']}<br/>
                                        <b>Transaction Index:</b> {item['transactionIndex']}<br/>
                                        <b>Element Position:</b> {item['elementPosition']}<br/>
                                        <b>Segment Position:</b> {item['segmentPosition']}<br/>
                                    </div>);
                            })}
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Grid>
                            <Grid.Column tablet={8} computer={8} style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                Total: {this.state.errorListMessage.length}
                            </Grid.Column>
                            <Grid.Column tablet={8} computer={8} style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }} >
                                <Button id='process_button_id' type='button' onClick={this.handle_close_popup} basic >Close</Button>
                            </Grid.Column>
                        </Grid>
                    </Modal.Actions>
                </Modal>
                <div className="common-forms-search patient-search" >
                    <h3>Release Patient Payments</h3>
                    <div className='wrapper' style={{ padding: 15 }} >
                        <div className='admin-wrapper' >
                            <Grid className='headerGrid' >
                                <Grid.Column computer={16}>
                                    <h3 className='ui header left aligned' dangerouslySetInnerHTML={{ __html: '' }} />
                                    <Form autoComplete='off' >
                                        <Grid>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label><b> Load Date</b></label>
                                                    <DateRangePickerComponent
                                                        id={'dt_load_date'}
                                                        updateDatesChange={this.handle_date_change}
                                                        startDate={this.state.initialDate}
                                                        endDate={this.state.finishDate}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column tablet={8} computer={4} verticalAlign="middle">
                                                <Form.Field >
                                                    <label><p className="ui-grid-label-empty">show_inactive</p></label>
                                                    <Checkbox label='Include Inactive' checked={this.state.includeInactive} onChange={() =>
                                                        this.setState((prev) => ({
                                                            includeInactive: !this.state.includeInactive
                                                        }))
                                                    } />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid>
                                        <Grid style={{ margin: '0 0px' }}>
                                            <Grid.Column computer={16} textAlign='right'>
                                                <Button id='clear_button_id' type='submit' onClick={this.handle_clean} basic>Clear</Button>
                                                <Button id='search_button_id' type='submit' onClick={this.handle_search} primary>Search</Button>
                                            </Grid.Column>
                                        </Grid>
                                    </Form>
                                </Grid.Column>
                            </Grid>
                            <React.Fragment>
                                {(this.state.showSearchAction && !this.state.loading) ? (
                                    <div style={{ padding: 15 }} >
                                        <GridView
                                            id={this.page_name}
                                            row={this.state.rowData}
                                            column={this.column_defs}
                                            style={{ height: '175px' }}
                                            wrapperStyle={{ width: '100%', height: '100%', display: 'flex' }}
                                            suppressMovableColumns={false}
                                            enableColResize={true}
                                            suppressSizeToFit={true}
                                            emptyMessage={messages.no_records_found}
                                        />
                                    </div>
                                ) : <div></div>}
                            </React.Fragment>
                            <React.Fragment>
                                {(this.state.showSearchAction && !this.state.loading) ?
                                    (<Grid className='headerGrid' style={{ padding: 15 }} >
                                        <Grid.Column computer={16}>
                                            <h3 className='ui header left aligned' dangerouslySetInnerHTML={{ __html: '' }} />
                                            <Form autoComplete='off' >
                                                <Grid>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <label><b> Deposit Date</b></label>
                                                            <DatePicker
                                                                id={'dt_deposit_date'}
                                                                date_update={this.handle_statement_date_change}
                                                                date={this.state.depositDate}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4} >
                                                        <Form.Field>
                                                            <label><b> Batch Number</b></label>
                                                            <Input
                                                                name='bachtNumber'
                                                                id='batch_number'
                                                                maxLength={50}
                                                                onChange={e => this.handle_input_change(e)}
                                                                type='text'
                                                                value={this.state.batchNumber}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={8} style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }} >
                                                        <Button id='process_button_id' type='button' primary onClick={this.handle_process}>Process</Button>
                                                    </Grid.Column>
                                                </Grid>
                                            </Form>
                                        </Grid.Column>
                                    </Grid>)
                                    : <div></div>}
                            </React.Fragment>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch)
}

const mapStateToProps = (state) => {
    return {
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(ReleasePatientPayment));