import * as global_constants from '../../../global_constants';
import { RequestConfig } from '../../../shared/network/config';
import { Request } from '../../../shared/network/request';

export const get_eob_keys = (token: string, param: string) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.remove_move_835s.get_eob_keys}?${param}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'silversurfer': param
    }

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config)
        .then(response => {
            if (response) {
                return response;
            }
        });
};

export const submit_remove_move = (token: string, params) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.remove_move_835s.submit_remove_move }`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': params
    })

    return request.make(request_config);
}