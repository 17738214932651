import * as moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { saveAs } from "../../../shared/file-saver.min";
import {
  Accordion,
  Button,
  Form,
  Grid,
  Icon,
  Input,
  Modal,
} from "semantic-ui-react";
import { get_search_data } from "../../../admin/constants/action/constants_action";
import {
  users,
} from "../../../admin/constants/constants";
import {
  constants,
  end_points,
  toastr_options,
  charge_exception,
} from "../../../global_constants";
import { get_patient_search } from "../../../patient/action/patient_search_action";
import ReportHeader from "../../../reports/component/report_header";
import { messages } from "../../../reports/report_constants";
import { date_format_with_no_time, print_html_data_to_pdf } from "./../../../shared/utility";
import { log_error } from "../../../shared/action/shared_action";
import AdvancedControl from "../../../shared/component/advanced_control";
import AutoSearchComponent from "../../../shared/component/auto_search_component";
import DateRangePickerComponent from "../../../shared/component/date_range_component";
import GridView from "../../../shared/component/grid/index";
import { LoaderComponent } from "../../../shared/component/loading_component";
import MultiSelectDropDownComponent from "../../../shared/component/multi_select_dropdown_component";
import Selection from "../../../shared/component/selection_component";
import { decodeURIComponentSafe } from "../../utility";
import * as session_storage from "../../../shared/session_storage_utility";
import * as sharedConstants from "../../../shared/shared_constants";
import * as bulk_payment_update_constants from "./../bulk_payment_update_constants";
import {
  handle_click_on_enter,
  set_focus_on_element_with_id,
  set_focus_to_app_header,
} from "../../../shared/tab_navigation_utility";
import {
  custom_date_format,
  format_dashes_number,
  format_date,
  get_age,
  get_all_error,
  get_columns,
} from "../../../shared/utility";
import {
  get_bulk_payment_updates_master_data_by_company,
  search_bulk_payment_updates,
  update_bulk,
} from "../../action/payment_action";
import { bulk_payment_updates_report_grid_id } from "../../payment_constants";
import {
  date_remove_timezone,
  row_data_formatter,
} from "../../../billing/utility";
import { get_searchList } from "../../../shared/action/autosearch_action";
import * as ReportConstants from "../../../reports/report_constants";
import { save_billing_audit } from "../../../reports/action/report_action";
import { isNullOrUndefined } from "util";
import DatePicker from "../../../shared/component/date_picker_component";
import * as local_storage from "../../../shared/local_storage_utility";
import debounce from 'lodash.debounce';

export class BulkPaymentUpdateComponent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: {
        company_id: false,
      },
      search_criteria: {},
      patient_grid_data: {},
      companies_grid_data: {},
      grid_params: [],
      is_submitted: false,
      re_render: false,
      loading: false,
      show_grid: false,
      controls_is_expended: true,
      is_search_disabled: false,
      report_rows_count: 0,
      total_rows_per_view: 0,
      display_extra_records_modal: false,
      is_searched: false,
      payment_code: "",
      is_checked_on_closed_month: false,
      dropdown_data: {
        payment_code: [],
        payment_code_single: [],
      },
    };
  }
  minDate = null;
  is_mounted = false;
  is_initial_result: boolean = false;
  update_disabled: boolean = true;
  patient_id: any = null;
  token: string = "";
  total_column_width: number = 0;
  grid_header_height: number = 0;
  report_data: any = {
    rows: [],
    column_def: [],
  };
  max_inner_width = 0;
  page_metadata: any;
  title_search_criteria: any = {};
  print_export_search_criteria: any = {};
  current_date: string;
  PATIENT_ENUM = "PATIENT";
  dropdown_data: any = {
    // Multi Select
    place_of_service: [],
    payment_code: [],
    // Single Select
    payment_code_single: [],
  };
  selected_row: any[] = [];
  total_rows_per_view = 5000;

  UNSAFE_componentWillMount = () => {
    this.token =
      this.props.user_login_details.user_data.data.token_details.access_token;
    this.page_metadata = this.get_initial_data();
    // getting up search criteria from the session
    if (session_storage.get("bulk_update_criteria")) {
      this.is_initial_result = true;
      const search_criteria = session_storage.get("bulk_update_criteria");
      this.title_search_criteria = {
        ...search_criteria,
      };
      this.setState(
        {
          search_criteria: {
            ...search_criteria,
          },
        },
        this.check_required_fields
      );
    } else {
      this.title_search_criteria = {
        ...this.page_metadata.title_search_criteria,
      };
      this.setState({
        search_criteria: {
          ...this.page_metadata.initial_search_criteria,
        },
        show_asterisk: true,
        update_has_values: false
      });
    }
  };

  check_required_fields = (checkCloseMonth?: boolean) => {
    const show_asterisk = !(
      this.state.search_criteria.check_card_auth_number ||
      (this.state.search_criteria.date_of_service_from &&
        this.state.search_criteria.date_of_service_end) ||
      this.state.search_criteria.batch ||
      this.state.search_criteria.patient_id
    );
    let update_has_values = false;
    
    if (!isNullOrUndefined(this.state.update_criteria)) {
      update_has_values = this.state.update_criteria.batch_update ||  
                          this.state.update_criteria.check_card_auth_number_update || 
                          ((checkCloseMonth === undefined || !checkCloseMonth) ? this.state.update_criteria.deposit_date : undefined) || 
                          this.state.update_criteria.payment_code;
    }

    this.setState({
      show_asterisk: show_asterisk,
      update_has_values: update_has_values
    });
  };

  get_initial_data = () => {
    return bulk_payment_update_constants.bulk_payment_updates(this.props);
  };
  
  componentDidMount = () => {
    this.is_mounted = true;
    if (!document.body.classList.contains("reports")) {
      document.body.classList.add("reports");
    }

    this.get_master_data();

    let company_control = document.getElementById(
      "advance-control-reset_available_companies_search"
    );
    if (company_control) company_control.focus();

    document.addEventListener("keydown", this._handle_enter_key.bind(this), false);
    this.page_load();
  };

  componentDidUpdate(prevProps: { patient_id: any }, prevState: any) {
    this.report_data.column_def = [...this.page_metadata.column_defs];

    this.page_load();
  }

  page_load = () => {
    const auth_data = local_storage.get("auth_data");

    let accrual_flag = false,
      open_month = null;

    if (auth_data) {
      accrual_flag = auth_data.accrual_accounting || false;
      open_month = auth_data.open_month || null;
    }

    this.minDate = null;
    if (accrual_flag) {
      this.minDate = new Date(open_month);
    }

    // Check permission to update payments
    this.props.shared_details.left_menu_data.data.map((menu) => {
      if (menu.name == "Bulk Payment Updates" && menu.view) {
        this.update_disabled = !this.selected_row || this.selected_row.length == 0
      }
    });
  };

  componentWillUnmount = () => {
    document.body.classList.remove("reports");
    this.is_mounted = false;
    session_storage.remove(`bulk_update_criteria`);
    document.removeEventListener("keydown", this._handle_enter_key.bind(this), false);
  };

  get_master_data = async () => {
    if (this.is_mounted) {
      this.setState({
        loading: true,
      });
    }

    const arrData = [];
    const company_id = this.state.search_criteria.company_id;
    arrData.push(
      get_bulk_payment_updates_master_data_by_company(this.token, {
        company_id,
        request: 1,
      })
    );
    arrData.push(
      get_bulk_payment_updates_master_data_by_company(this.token, {
        company_id,
        request: 2,
      })
    );

    if (this.is_initial_result) {
      let params: any = {
        ...this.state.search_criteria,
      };
      delete params.selected_company;
      delete params.selected_patient;
      arrData.push(search_bulk_payment_updates(this.token, params));
    }
    await Promise.all(arrData)
      .then(
        (response) => {
          let state: any = {};

          let element: any = {};
          for (let index = 0; index < response.length; index++) {
            if (response[index] && response[index].data.status) {
              element = { ...element, ...response[index].data.data };
            }
          }
          if (element) {
            this.format_primary_dropdown_data(element);
          }
          if (this.is_mounted) {
            this.setState({
              loading: false,
              ...state,
              update_criteria: {
                ...this.state.update_criteria,
              },
              dropdown_data: {
                ...this.state.dropdown_data,
                ...this.dropdown_data,
              },
              is_export_disabled: false,
              is_print_disabled: false,
              is_checked_on_closed_month: false,
              is_updated_disabled: this.update_disabled || false,
            });
          }
        },
        (error) => {
          if (this.is_mounted) {
            this.setState({
              loading: false,
            });
          }
          log_error(error);
          if (error.response.data) {
            const toastr_options = this.show_html_content_toaster(
              get_all_error(error.response.data)
            );
            toastr.error("", toastr_options);
          }
        }
      )
      .catch((error) => {
        if (this.is_mounted) {
          this.setState({
            loading: false,
          });
        }
        log_error(error);
      });
  };

  // Get Report Data.
  get_report_data = async () => {
    let params: any = {
      ...this.state.search_criteria,
    };

    delete params.selected_patient;
    if (this.is_mounted) {
      this.setState({
        loading: true,
        show_grid: false,
        is_search_disabled: true,
        is_export_disabled: true,
        is_print_disabled: true,
        is_updated_disabled: true,
      });
    }
    params.totalRows = this.total_rows_per_view;

    params.date_of_service_from = date_remove_timezone(
      params.date_of_service_from
    );
    params.date_of_service_end = date_remove_timezone(
      params.date_of_service_end
    );

    await search_bulk_payment_updates(this.token, params).then(
      (response) => {
        if (response.data.status && response.data.data) {
          let updated_records = row_data_formatter(
            response.data.data.bulk_payment_updates_detail_list,
            this.report_data.column_def
          );
          this.report_data["rows"] = updated_records || [];
          this.total_column_width = 0;
          const grid_height = get_columns(
            this.report_data["rows"],
            this.report_data.column_def
          );
          this.grid_header_height = grid_height.header_height;
          this.total_column_width = grid_height.total_column_width;
          const display_max_alert =
            response.data.data.total_record_data &&
            response.data.data.total_record_data > this.total_rows_per_view;
          if (this.is_mounted) {
            this.setState({
              loading: false,
              show_grid: true,
              report_rows_count:
                this.report_data["rows"] && this.report_data["rows"].length,
              is_search_disabled: false,
              is_export_disabled: false,
              is_print_disabled: false,
              is_updated_disabled: this.update_disabled || false,
              controls_is_expended: false, // closing the accordion on search
              display_extra_records_modal: display_max_alert,
              extra_record_count: display_max_alert
                ? response.data.data.total_record_data -
                  this.total_rows_per_view
                : 0,
              total_rows_per_view: response.data.data.total_record_data,
            });
          }
        } else {
          if (response.data.messages) {
            log_error(response.data.messages);
            const toastr_options = this.show_html_content_toaster(
              get_all_error(response.data.messages)
            );
            toastr.error("", toastr_options);
          }
          if (this.is_mounted) {
            this.setState({
              loading: false,
              is_search_disabled: false,
              controls_is_expended: true,
            });
          }
        }
      },
      (error: { response: { data: any } }) => {
        if (this.is_mounted) {
          this.setState({
            loading: false,
            is_search_disabled: false,
            controls_is_expended: true,
          });
        }
        if (error.response && error.response.data) {
          log_error(error);
          const toastr_options = this.show_html_content_toaster(
            get_all_error(error.response.data)
          );
          toastr.error("", toastr_options);
        }
      }
    );
  };

  format_primary_dropdown_data = (data: {
    location: any;
    payment_code: any;
  }) => {
    const { location, payment_code } = data;

    // Single select uses key "text" while multi select uses "name"
    const paymentCodeSingleSelect = [];

    payment_code.forEach(function (obj) {
      let payment = { text: obj["name"], value: obj.value };

      paymentCodeSingleSelect.push(payment);
    });

    paymentCodeSingleSelect.unshift({
      text: "",
      value: "",
    });

    this.dropdown_data = {
      ...this.dropdown_data,
      place_of_service: location || [],
      payment_code: payment_code || [],
      payment_code_single: paymentCodeSingleSelect || [],
    };
    this.setState({
      search_criteria: {
        ...this.state.search_criteria,
        place_of_service: this.dropdown_data.place_of_service.map(
          (pos) => pos.value
        ),
        is_updated_disabled: this.update_disabled || false,
      },
    });
  };

  handle_date = (search_criteria, name: string, title = false) => {
    if (title) {
      return search_criteria[name]
        ? moment(search_criteria[name]).format("MM/DD/YYYY")
        : "";
    } else {
      return search_criteria[name]
        ? moment(moment(search_criteria[name]).format("MM/DD/YYYY"))
        : null;
    }
  };

  // Search Criteria Toggler
  controls_toggle = () => {
    if (this.is_mounted) {
      this.setState({
        controls_is_expended: !this.state.controls_is_expended,
      });
    }
  };

  // Render multiSelect Control
  render_multiSelect_element = (
    name: string,
    options: any[],
    selected_items: any[]
  ) => {
    return (
      <MultiSelectDropDownComponent
        class_name="manage-content-multi-select"
        id={name}
        key={name}
        key_id={name}
        data={options}
        selected_list={selected_items}
        onChangeSelected={this.multi_select_handler}
        forceOnChangeSelected={true}
        has_validation={false}
        show_filter={true}
        reset_disable={true}
        disabled={this.state[`disable_${name}`]}
      />
    );
  };

  // Render Dropdown Control
  render_dropdown_element = (
    name: string,
    value: any,
    options: any[],
    is_disabled = false
  ) => {
    return (
      <Selection
        id={name}
        name={name}
        defaultValue={value || ""}
        options={options}
        onChange={this.on_dropdown_change}
        disabled={is_disabled}
      />
    );
  };

  // Dropdown change Handler
  on_dropdown_change = (value: any, e) => {
    const { name, selectedOptions, options, selectedIndex } = e.target;
    if (this.is_mounted) {
      if (this.state.search_criteria[name] !== undefined) {
        this.title_search_criteria[name] = selectedOptions
          ? selectedOptions[0].outerText
          : options[selectedIndex].text;
        this.setState((prevState: { search_criteria: any }) => ({
          search_criteria: {
            ...prevState.search_criteria,
            [name]: value,
          },
        }));
      }

      this.setState(
        {
          [name]: value,
          update_criteria: {
            ...this.state.update_criteria,
            [name]: value,
          },
        },
        this.check_required_fields
      );
    }
  };

  // multi select change handler
  multi_select_handler = (id, values) => {
    this.dropdown_data[id] = this.dropdown_data[id].map((item) => {
      if (values.indexOf(item.value) > -1) {
        item = {
          ...item,
          checked: true,
          is_checked: true,
        };
      } else {
        item = {
          ...item,
          checked: false,
          is_checked: false,
        };
      }
      return item;
    });
    if (this.is_mounted) {
      this.title_search_criteria[id] = `${values.length} Selected`;
      this.setState((prevState: { search_criteria: any }) => ({
        search_criteria: {
          ...prevState.search_criteria,
          [id]: [...values],
        },
      }));
    }
  };

  // Calender change handler.
  range_date_change_handler = (from_date: any, end_date: any) => {
    if (this.is_mounted) {
      let isErrorDate = false;
      if ((!from_date && end_date) || (from_date && !end_date)) {
        isErrorDate = true;
      } else {
        isErrorDate = from_date
          ? moment.isMoment(from_date)
            ? false
            : true
          : false || end_date
          ? moment.isMoment(end_date)
            ? false
            : true
          : false;
      }

      this.setState(
        (prevState: { search_criteria: any }) => ({
          search_criteria: {
            ...prevState.search_criteria,
            date_of_service_from: from_date || null,
            date_of_service_end: end_date || null,
          },
          is_error_date: isErrorDate,
        }),
        this.check_required_fields
      );
    }
  };

  // Input change handler.
  input_change_handler = (e) => {
    const { name, value } = e.target;
    let error = this.state.error;
    error[e.target.name] = false;

    if (this.is_mounted) {
      this.setState(
        {
          error: error,
          [name]: value,
          search_criteria: {
            ...this.state.search_criteria,
            [name]: value,
          },
          update_criteria: {
            ...this.state.update_criteria,
            [name]: value.trim(),
          },
        },
        this.check_required_fields
      );
    }
  };

  // Input on blur handler.
  input_onblur_handler = (e) => {
    const { name, value } = e.target;

    if (this.is_mounted) {
      this.setState(
        {
          [name]: value.trim(),
          search_criteria: {
            ...this.state.search_criteria,
            [name]: value.trim(),
          },
          update_criteria: {
            ...this.state.update_criteria,
            [name]: value.trim(),
          },
        },
        this.check_required_fields
      );
    }
  };

  calendar_change_handler = (name, date_object) => {
    let error = this.state.error;

    if (this.state.is_submitted || this.state.is_searched) {
      if (date_object == null) {
        error["formatted_" + name] = true;
      } else {
        error["formatted_" + name] = false;
      }
    } else {
      error["formatted_" + name] = false;
      }
      //console.log("date", custom_date_format(date_object._d, "YYYY-MM-DDTHH:mm:ss.sssZ"))
    if (this.is_mounted) {
      this.setState({
        error: error,
          [name]: date_object
              ? date_format_with_no_time(date_object._i)
          : "",
        [`is_${name}`]: moment.isMoment(date_object) ? false : true,
        [`formatted_${name}`]: date_object,
        update_criteria: {
          ...this.state.update_criteria,
          [name]: date_object
              ? date_format_with_no_time(date_object._i)
            : "",
        },
      }, this.check_required_fields);
    }
  };

  handle_navigation_after_last_element = (event: {
    shiftKey: any;
    keyCode: string;
  }) => {
    // apply a check where grid is empty
    if (!event.shiftKey && event.keyCode == "9" && !this.state.show_grid) {
      set_focus_to_app_header(event);
    }
  };

  show_html_content_toaster = (msg: any) => {
    return {
      component: () => (
        <div>
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        </div>
      ),
      timeOut: toastr_options.toastr_time_out,
      preventDuplicates: true,
    };
  };

  //############## Advance Search Controls Start ####################
  /**Functions for Quick Search */
  on_item_selection(selected_row: any, name: any) {
    if (this.is_mounted) {
      let patient_search_value = {
        label: selected_row.label,
        title: selected_row.title,
      };
      this.title_search_criteria["selected_patient"] = patient_search_value;
      this.setState(
        (prev) => ({
          search_criteria: {
            ...prev.search_criteria,
            patient_id: patient_search_value.title,
            selected_patient: patient_search_value,
          },
        }),
        this.check_required_fields
      );
    }
  }

  clear_quick_search = (name: string) => {
    if (this.is_mounted) {
      this.title_search_criteria["selected_patient"] = {};
      this.setState(
        (prev) => ({
          search_criteria: {
            ...prev.search_criteria,
            patient_id: "",
            selected_patient: {},
          },
        }),
        this.check_required_fields
      );
    }
  };

  // ON Grid Row Selection
  on_grid_row_selection = (selected_row: any, name: string) => {
    if (this.is_mounted) {
      const patient_name = this.format_name(selected_row.name);
      let patient_search_value = {
        label: patient_name,
        title: selected_row.id,
      };
      this.title_search_criteria["selected_patient"] = patient_search_value;
      this.setState(
        (prev) => ({
          search_criteria: {
            ...prev.search_criteria,
            patient_id: patient_search_value.title,
            selected_patient: patient_search_value,
          },
        }),
        this.check_required_fields
      );
    }
  };

  format_name = (name: {
    last_name: any;
    middle_initial: string;
    first_name: string;
  }) => {
    return (
      (name.last_name == null ? "" : name.last_name) +
      (name.middle_initial == null ? "" : " " + name.middle_initial) +
      (name.first_name == null ? "" : " " + name.first_name)
    );
  };

  //It renders report grid and its search controls.
  get_data_list = async (inputValue: string, companyid: any, name: string) => {
    return await get_searchList(
      this.token,
      this.state.search_criteria.company_id,
      inputValue.trim(),
      constants.Quick_Search_Suggestion_List_Size,
      end_points.patient_urls.quick_search,
      false
    );
  };

  render_result = (props: { title: string; label: React.ReactNode }, type) => {
    return (
      <div key={props.title} tabIndex={0} className="item_auto_search">
        <div className="fs_13">
          <span className="bold">{props.label}</span>
          {type == this.PATIENT_ENUM && (
            <span>{props.title ? "(" + props.title + ")" : ""}</span>
          )}
        </div>
      </div>
    );
  };

  //Prepare suggestion list to show in Quick Search result
  prepare_suggestion = (data: { data: any[] }, type: string) => {
    let formattedList = [];
    if (data && data.data.length > 0) {
      data.data.map(
        (item: {
          last_name: string;
          middle_initial: string;
          first_name: any;
          id: { toString: () => any };
          email: any;
          date_of_birth: string | number | Date;
          phone: { home: string; work: string; cell: string };
        }) => {
          formattedList.push({
            label:
              (item.last_name == null ? "" : item.last_name + " ") +
              (item.middle_initial == null ? "" : item.middle_initial + " ") +
              (item.first_name == null ? "" : item.first_name),
            title: item.id.toString(),
            email: item.email,
            dob: (item.date_of_birth == null
              ? ""
              : format_date(new Date(item.date_of_birth), false)
            ).toString(),
            age:
              (item.date_of_birth == null ? "" : get_age(item.date_of_birth)) +
              " Yrs",
            home:
              item.phone.home == null || item.phone.home == ""
                ? " "
                : " H: " + format_dashes_number(item.phone.home),
            work:
              item.phone.work == null || item.phone.work == ""
                ? " "
                : ", W: " + format_dashes_number(item.phone.work),
            cell:
              item.phone.cell == null || item.phone.cell == ""
                ? " "
                : ", M: " + format_dashes_number(item.phone.cell),
          });
          return;
        }
      );
    } else {
      if (this.is_mounted) {
        this.setState({ quick_search_error_message: "No Patient Found !" });
      }
    }
    return formattedList;
  };

  on_advance_search = async (
    params: { [x: string]: string; code?: any; name?: any },
    type: string
  ) => {
    let new_params = {};
    new_params = {
      patient_name: {
        first_name: params.first_name,
        last_name: params.last_name,
      },
      show_in_active: true,
      id: params.Id,
      page_size: params.page_size,
      company_id: this.state.search_criteria.company_id,
    };

    // Search function.
    if (this.is_mounted) {
      this.props.get_patient_search(new_params, this.token).then((res: any) => {
        this.setState({
          patient_grid_data: {
            ...this.state.grid_conf,
            rows: this.props.patient_details.patient_search,
            column: sharedConstants.patient_search_configuration(
              sharedConstants.patient_search_configuration_id
            ).column_defs,
            messages: "No Patient Found",
          },
        });
      });
    }
  };

  // get the searched value which is typed for searching location or providers
  get_value = (input: string, type: string) => {
    const { error, is_submitted } = this.state;
    if (input.trim().length >= 3) {
      this.on_company_quick_search(input);
    }
  };

  render_advance_quick_search = (
    type: any,
    search_data: any,
    control_id: string,
    value: { label: string; title: string },
    get_quick_search_data_list: Function,
    grid_header_id,
    has_error = false,
    is_disabled = false
  ) => {
    return (
      <React.Fragment>
        <AdvancedControl
          onGridRowSelection={(row: any) =>
            this.on_grid_row_selection(row, type)
          }
          gridConfig={search_data}
          disabled_element={is_disabled}
          controlId={control_id}
          onSearch={(param: any) => this.on_advance_search(param, type)}
          search_type={control_id}
          headerIdForGridTabNavigation={grid_header_id}
        />
        <AutoSearchComponent
          getInputValue={(e: any) => this.get_value(e, type)}
          default_value={value}
          errorMessage={"No Record Found!"}
          prepareRenderList={(props) => this.render_result(props, type)}
          getList={get_quick_search_data_list}
          prepareDataList={(data: any) => this.prepare_suggestion(data, type)}
          selectresult={(item: any) => this.on_item_selection(item, type)}
          control_id={control_id}
          is_disabled={is_disabled}
          show_clear_search={true}
          clear_search={() => this.clear_quick_search(type)}
          errorClass={has_error ? "req-background-inp" : ""}
        />
      </React.Fragment>
    );
  };

  on_company_quick_search = async (params: string) => {
    let url = "";
    let result: any;
    if (params.trim().length > 0) {
      url =
        users.api.default_company_quick_search +
        "?keyword=" +
        params +
        "&page_size=" +
        constants.Quick_Search_Suggestion_List_Size;
      result = await get_search_data(this.token, url);
      return result;
    } else {
      let result: any = {};
      result.data = [];
      return result;
    }
  };

  validateForm = () => {
    const { date_of_service_from, date_of_service_end } =
      this.state.search_criteria;
    const { show_asterisk } = this.state;
    let has_error = false;

    let page_metadata = this.get_initial_data();
    if (show_asterisk) {
      has_error = true;
      this.setState({
        error: {
          required_field_missing: true,
        },
      });
      return false;
    } else {
      this.setState({
        error: {
          required_field_missing: false,
        },
      });
    }

    if (date_of_service_from && isNullOrUndefined(date_of_service_end)) {
      toastr.error("", page_metadata.messages.date_range_mandatory_fields);
      return false;
    }

    if (isNullOrUndefined(date_of_service_from) && date_of_service_end) {
      toastr.error("", page_metadata.messages.date_range_mandatory_fields);
      return false;
    }

    if (date_of_service_from && date_of_service_end) {
      if (date_of_service_from <= date_of_service_end) {
        if (Math.trunc((date_of_service_end - date_of_service_from) / 86400000) > 92) {
          toastr.error(
            "",
            page_metadata.messages.date_range_exceed + " 92 days."
          );
          return false;
        }
      } else {
        toastr.error("", page_metadata.messages.date_range_invalid);
        return false;
      }
    }

    return true;
  };

  //############## Advance Search Controls End ####################

  // Search Handler
  handle_search = (e) => {
    this.print_export_search_criteria = { ...this.title_search_criteria };
    if (!this.validateForm()) return;
    this.get_report_data();
    session_storage.set("bulk_update_criteria", this.state.search_criteria);
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  _handle_enter_key(event) {
    if (event.keyCode === 13) { 
      this.handle_search(null);
    }
  }

  // Function calls on click of Clear button and clear all the searched result as well as criteria.
  clear_handler = (on_clear_company: boolean = false) => {
    this.report_data = {
      column_def: [...this.page_metadata.column_defs],
      rows: [],
    };

    let page_metadata = this.get_initial_data();
    this.title_search_criteria = {
      ...this.page_metadata.title_search_criteria,
    };
    this.print_export_search_criteria = {
      ...this.title_search_criteria,
    };
    this.is_initial_result = false;
    session_storage.remove(`bulk_update_criteria`);

    let search_criteria = on_clear_company
      ? {
          ...page_metadata.initial_search_criteria,
          company_id: "",
          selected_company: {},
        }
      : page_metadata.initial_search_criteria;

    let update_criteria = page_metadata.initial_update_criteria;
    if (this.is_mounted) {
      this.selected_row = [];
      this.setState(
        {
          is_submitted: false,
          error: { company_id: on_clear_company },
          search_criteria: search_criteria,
          show_grid: false,
          extra_record_count: 0,
          controls_is_expended: true,
          is_searched: false,
          is_error_date: false,
          update_criteria: update_criteria,
        },
        () => {
          if (!on_clear_company) {
            this.get_master_data();
          }

          this.check_required_fields();
        }
      );
    }
  };

  get_grid_ref = (grid_params: {
    columnApi: {
      setColumnWidth: (arg0: any, arg1: any, arg2: boolean) => void;
    };
  }) => {
    if (this.is_mounted) {
      this.setState({
        grid_params,
      });
    }
    let scrollable_area = document.getElementById("report-scrollable-area");
    if (scrollable_area) {
      const grid_width = scrollable_area.clientWidth;
      if (this.total_column_width > grid_width) {
        this.report_data.column_def.filter(
          (item: { field: any; width: any }) => {
            grid_params.columnApi.setColumnWidth(item.field, item.width, false);
          }
        );
      }
    }
  };

  on_grid_out = () => {
    set_focus_on_element_with_id("app_header_dropdown");
  };

  // Function calls save billing audit on Export or Print
  private saveReportEventAction = (eventActionId: number) => {
    let reqBody = { reportId: "0", reportEventActionId: eventActionId };

    const accessToken =
      this.props.user_login_details.user_data.data.token_details.access_token;
    save_billing_audit(reqBody, accessToken);
  };

  debounced_check_box_selection() {
    debounce(this.on_check_box_selection.bind(this), 300)
  }
  
  // Select Row's on checkbox
  on_check_box_selection = (items: any[]) => {
    let itemOnClosedMonthChecked = false;
    let noItemsChecked = !items || items.length == 0;
    this.update_disabled = noItemsChecked;

    if (this.is_mounted && items.length != this.selected_row.length) {
      this.selected_row = items;
    }

    // Check if any checked items is in a closed month to disable deposit date
    for (let item of items) {
      if (item.is_Closed_Month && item.is_Closed_Month == 1) {
        itemOnClosedMonthChecked = true;
        this.setState({
          update_criteria: {
            ...this.state.update_criteria,
            "deposit_date": "",
          },
        });
        break;
      }
    }

    this.check_required_fields(itemOnClosedMonthChecked);
    this.setState({
      is_checked_on_closed_month: itemOnClosedMonthChecked,
      is_updated_disabled: this.update_disabled || false,
    });
  };

  update_bulk = async () => {
    let payments = [];
    if (this.selected_row && this.selected_row.length <= 0) {
      toastr.error("", this.page_metadata.messages.valid_update_msg);
      return;
    }

    this.selected_row.forEach((item) => {
      payments.push({ patient_credit_id: item.patient_Credit_Id });
    });

    // In case of mis-match of selection
    if (
      payments &&
      this.selected_row &&
      payments.length != this.selected_row.length &&
      payments.length > 0
    ) {
      toastr.warning("", this.page_metadata.messages.mismatch_update_error);
    }

    if (payments && payments.length <= 0) {
      toastr.error("", this.page_metadata.messages.r2_valid_update_msg);
      return;
    }

    const params = {
      payments: payments,
      ...this.state.update_criteria,
      company_id: this.state.search_criteria.company_id,
      };

      params.deposit_date = params.deposit_date == "" ? null : params.deposit_date;

    if (this.is_mounted) {
      this.setState({
        loading: true,
        is_search_disabled: true,
      });
    }

    await update_bulk(this.token, params).then(
      (response: {
        data: { status: number; data: any; messages: { message: any }[] };
      }) => {
        if (response.data && response.data.status == 1) {
          if (
            payments &&
            payments.length > 0 &&
            response.data.data.payment_count > 0 &&
            payments.length != response.data.data.payment_count
          ) {
            toastr.warning(
              "",
              this.page_metadata.messages.mismatch_update_error
            );
          }

          const msg = `${response.data.data.payment_count} Payment(s) were updated.`;

          if (response.data.data.payment_count > 0) {
            toastr.success("", msg);
          } else {
            toastr.error("", msg);
          }
          let page_metadata = this.get_initial_data();
          let update_criteria = page_metadata.initial_update_criteria;

          if (this.is_mounted) {
            this.selected_row = [];
            this.setState(
              {
                loading: false,
                is_search_disabled: false,
                update_criteria: update_criteria,
                is_checked_on_closed_month: false,
                is_submitted: false,
                show_grid: false,
                extra_record_count: 0,
                controls_is_expended: true,
                is_searched: false,
                is_error_date: false,
              });
          }
        } else {
          if (response.data.messages) {
            const toastr_options = this.show_html_content_toaster(
              get_all_error(response.data.messages)
            );
            toastr.error("", toastr_options);
          }
          if (this.is_mounted) {
            this.selected_row = [];
            this.setState({
              loading: false,
              is_search_disabled: false,
            });
          }
        }
      },
      (error: { response: { data: any } }) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.messages &&
          error.response.data.messages.length > 0
        ) {
          let exception = error.response.data.messages.filter((item) => {
            return item.code === charge_exception.code;
          });
          if (exception && exception.length > 0) {
            if (this.is_mounted) {
              this.setState({
                loading: false,
                is_search_disabled: false,
              });
            }
          } else {
            if (this.is_mounted) {
              this.setState({
                loading: false,
                is_search_disabled: false,
              });
            }
            const toastr_options = this.show_html_content_toaster(
              get_all_error(error.response.data)
            );
            toastr.error("", toastr_options);
          }
        } else {
          if (this.is_mounted) {
            this.setState({
              loading: false,
              is_search_disabled: false,
            });
          }
          if (error.response.data) {
            log_error(error);
            const toastr_options = this.show_html_content_toaster(
              get_all_error(error.response.data)
            );
            toastr.error("", toastr_options);
          }
        }
      }
    );
  };

  on_filter_changed = () => {
    this.setState((state) => ({
      report_rows_count: state.grid_params.api.getModel().getRowCount(),
    }));
  };

  on_export_button = () => {
    const title = this.get_title("\n");
    let csv = [];
    csv.push(title);
    let header = [];

    this.report_data.column_def.forEach((colData, index) => {
      if (index === 0) {
        return;
      }

      if (index == this.report_data.column_def.length - 1) {
        header.push(colData.headerName + "\n");
      } else {
        header.push(colData.headerName);
      }
    });

    csv.push(header.join(","));

    if (this.report_data["rows"] && this.report_data["rows"].length > 0) {
      this.report_data["rows"].forEach((row) => {
        let data = [];
        this.report_data.column_def.forEach((colData, index) => {
          if (index === 0) {
            return;
          }
          data.push(`"${row[colData.field]}"`);
        });
        let stringRow = data.join(",");
        csv.push(stringRow + "\n");
      });
    }

    let blob = new Blob(csv, {
      type: "text/csv;charset=utf-8",
    });

    saveAs(blob, this.page_metadata.title + ".csv");
    this.saveReportEventAction(ReportConstants.ReportEventAction.Export);
  };

  on_print_button = () => {
    const title = this.get_title("<br>");
    let content = [];
    content.push('<div class="reportPrint">');
    content.push(title);
    content.push('<table id="mainGrid">');
    content.push("<thead>");
    content.push("<tr>");
    this.report_data.column_def.forEach((colData, index) => {
      if (index === 0) {
        return;
      }
      content.push(
        `<th style="text-align:${colData.align ? colData.align : "left"}">${
          colData.headerName
        }</th>`
      );
    });
    content.push("</tr>");
    content.push("</thead>");
    content.push("<tbody>");
    if (this.report_data["rows"] && this.report_data["rows"].length > 0) {
      this.report_data["rows"].forEach((row) => {
        let data = [];
        data.push("<tr>");
        this.report_data.column_def.forEach((colData, index) => {
          if (index === 0) {
            return;
          }
          data.push(
            `<td style="text-align:${colData.align ? colData.align : "left"}">${
              row[colData.field]
            }</td>`
          );
        });

        data.push("</tr>");
        content.push(data.join(""));
      });
    } else {
      content.push(
        '<tr><td colspan="100%" class="no_records"> No records found </td></tr>'
      );
    }

    content.push("</tbody>");
    content.push("</table>");
    content.push("</div>");

    print_html_data_to_pdf(
      content.join(""),
      "print_report_button",
      this.page_metadata.title
    );
    this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
  };

  get_title = (separater) => {
    let title = [];
    title.push(`${this.page_metadata.title} ${separater}${separater}`);
    title.push(
      `Company: ${
        this.page_metadata.initial_search_criteria.selected_company.label || ""
      }${separater}`
    );
    title.push(
      `Check/Credit Card Auth Number: ${
        this.state.search_criteria.check_card_auth_number || ""
      }${separater}`
    );
    title.push(
      `Deposit Start Date: ${
        moment.isMoment(this.state.search_criteria.date_of_service_from)
          ? this.state.search_criteria.date_of_service_from.format("MM/DD/YYYY")
          : ""
      }${separater}`
    );
    title.push(
      `Deposit End Date: ${
        moment.isMoment(this.state.search_criteria.date_of_service_end)
          ? this.state.search_criteria.date_of_service_end.format("MM/DD/YYYY")
          : ""
      }${separater}`
    );
    title.push(
      `Batch Number: ${this.state.search_criteria.batch || ""}${separater}`
    );
    title.push(
      `Patient ID: ${this.state.search_criteria.patient_id || ""}${separater}`
    );
    title.push(
      `Location: ${
        (this.state.search_criteria.place_of_service || []).length
      } Selected ${separater}`
    );
    title.push(
      `Payment Code: ${
        (this.state.search_criteria.payment_code || []).length
      } Selected ${separater}`
    );
    title.push(
      `${
        moment(new Date()).format("MM/DD/YYYY") +
        " " +
        moment(new Date()).format("hh:mm:ss A")
      }${separater}`
    );
    title.push(
      `${this.state.report_rows_count || 0} records${separater}${separater}`
    );

    return decodeURIComponentSafe(title.join(""), 1);
  };

  // close popup
  confirm_close_handler = () => {
    if (this.is_mounted) {
      this.setState({
        display_extra_records_modal: false,
      });
    }
  };

  confirm_handler = (e) => {
    if (this.is_mounted) {
      this.setState({
        display_extra_records_modal: false,
      });
    }
  };

  render() {
    const {
      loading,
      controls_is_expended,
      is_clear_disabled,
      is_error_date,
      search_criteria,
      is_search_disabled,
      error,
    } = this.state;

    return (
      <React.Fragment>
        <LoaderComponent loading={loading}>
          <div className={"common-forms-add "}>
            <div
              className={
                "common-forms-search report-framework batch_billing_run"
              }
            >
              <ReportHeader title={this.page_metadata.title} />
              <div className="outer-wrap">
                <div
                  className="patient-search-form patient_search_bottom_padding"
                  id="report-criteria-container"
                  style={{ padding: 0, border: 0, background: "transparent" }}
                >
                  <Form autoComplete="off">
                    <Grid>
                      <Grid.Column
                        computer={16}
                        tablet={16}
                        textAlign="left"
                        className="accordionColumn"
                        id="accordion-column"
                      >
                        <Accordion fluid styled>
                          <Accordion.Title
                            active={controls_is_expended}
                            index={0}
                            onClick={this.controls_toggle}
                          >
                            <Icon
                              onKeyDown={handle_click_on_enter}
                              tabIndex={0}
                              name="angle right"
                            />
                            Search Criteria
                          </Accordion.Title>
                          <Accordion.Content active={controls_is_expended}>
                            <Grid>
                              <Grid.Row>
                                <Grid.Column tablet={16} computer={16}>
                                  {error.required_field_missing && (
                                    <p className="req-alert">
                                      At least 1 of the following fields are
                                      required to search: Check/Credit Card Auth
                                      Number, Deposit Date Range, Batch Number,
                                      or Patient ID
                                    </p>
                                  )}
                                </Grid.Column>
                              </Grid.Row>
                              <Grid.Column tablet={8} computer={4}>
                                <Form.Field>
                                  <label>
                                    Check/Credit Card Auth Number{" "}
                                    {this.state.show_asterisk && (
                                      <span className="req-alert required-field-marker">
                                        *
                                      </span>
                                    )}
                                  </label>
                                  <Input
                                    maxLength={50}
                                    name={"check_card_auth_number"}
                                    type={"text"}
                                    id={"check_card_auth_number"}
                                    value={
                                      search_criteria.check_card_auth_number ||
                                      ""
                                    }
                                    onChange={(e) =>
                                      this.input_change_handler(e)
                                    }
                                    onBlur={(e) => this.input_onblur_handler(e)}
                                    autoComplete="new-password"
                                    className={
                                      error.required_field_missing
                                        ? "red-error-thin"
                                        : ""
                                    }
                                  />
                                </Form.Field>
                              </Grid.Column>
                              <Grid.Column tablet={8} computer={4}>
                                <Form.Field>
                                  <label>
                                    Deposit Date Range{" "}
                                    {this.state.show_asterisk && (
                                      <span className="req-alert required-field-marker">
                                        *
                                      </span>
                                    )}
                                  </label>
                                  <DateRangePickerComponent
                                    updateDatesChange={(
                                      from_date: any,
                                      end_date: any
                                    ) =>
                                      this.range_date_change_handler(
                                        from_date,
                                        end_date
                                      )
                                    }
                                    startDate={
                                      search_criteria.date_of_service_from
                                    }
                                    endDate={
                                      search_criteria.date_of_service_end
                                    }
                                    error={
                                      is_error_date ||
                                      error.required_field_missing
                                    }
                                    id={"date_of_service"}
                                    maxDate={moment()}
                                    is_disabled={!search_criteria.company_id}
                                    anchorDirection={"right"}
                                  />
                                </Form.Field>
                              </Grid.Column>
                              <Grid.Column tablet={8} computer={4}>
                                <Form.Field>
                                  <label>
                                    Batch Number{" "}
                                    {this.state.show_asterisk && (
                                      <span className="req-alert required-field-marker">
                                        *
                                      </span>
                                    )}
                                  </label>
                                  <Input
                                    maxLength={12}
                                    name={"batch"}
                                    type={"text"}
                                    id={"batch"}
                                    value={search_criteria.batch || ""}
                                    onChange={(e) =>
                                      this.input_change_handler(e)
                                    }
                                    onBlur={(e) => this.input_onblur_handler(e)}
                                    autoComplete="new-password"
                                    className={
                                      error.required_field_missing
                                        ? "red-error-thin"
                                        : ""
                                    }
                                  />
                                </Form.Field>
                              </Grid.Column>
                              <Grid.Column tablet={8} computer={4}>
                                <Form.Field className={"advance-quick-search"}>
                                  <label>
                                    {"Patient ID"}{" "}
                                    {this.state.show_asterisk && (
                                      <span className="req-alert required-field-marker">
                                        *
                                      </span>
                                    )}
                                  </label>
                                  {this.render_advance_quick_search(
                                    this.PATIENT_ENUM,
                                    this.state.patient_grid_data,
                                    "PATIENT",
                                    search_criteria.selected_patient,
                                    this.get_data_list,
                                    sharedConstants.patient_search_configuration_id,
                                    error.required_field_missing,
                                    !search_criteria.company_id
                                  )}
                                </Form.Field>
                              </Grid.Column>
                              <Grid.Column tablet={8} computer={4}>
                                <Form.Field>
                                  <label>Location</label>
                                  {this.render_multiSelect_element(
                                    "place_of_service",
                                    this.dropdown_data.place_of_service,
                                    search_criteria.place_of_service || []
                                  )}
                                </Form.Field>
                              </Grid.Column>
                              <Grid.Column tablet={8} computer={4}>
                                <Form.Field>
                                  <label>Payment Code</label>
                                  {this.render_multiSelect_element(
                                    "payment_code",
                                    this.dropdown_data.payment_code,
                                    search_criteria.payment_code || []
                                  )}
                                </Form.Field>
                              </Grid.Column>
                            </Grid>
                          </Accordion.Content>
                        </Accordion>
                      </Grid.Column>
                    </Grid>
                  </Form>
                </div>
                <Grid style={{ marginTop: 0, marginBottom: 0 }}>
                  <Grid.Column tablet={16} computer={16} textAlign="right">
                    <Button
                      type="reset"
                      id="clear_button"
                      basic
                      onClick={() => this.clear_handler()}
                      disabled={is_clear_disabled}
                      content={"Clear"}
                    />
                    <Button
                      id="search_error"
                      className="primary"
                      type="submit"
                      onKeyDown={this.handle_navigation_after_last_element}
                      style={{ marginRight: "0" }}
                      disabled={is_search_disabled}
                      content={"Search"}
                      onClick={this.handle_search}
                    />
                  </Grid.Column>
                </Grid>
                {this.state.show_grid && (
                  <div
                    id="report-scrollable-area"
                    className="report-wrapper mar-t-10"
                  >
                    <Grid className="dateTime" style={{ marginTop: 10 }}>
                      <Grid.Column tablet={5} computer={4} textAlign="left">
                        <p style={{ fontSize: "16px", minHeight: 22 }}>
                          Search Results
                        </p>
                      </Grid.Column>
                      <Grid.Column tablet={5} computer={8} textAlign="center">
                        {
                          <p style={{ fontSize: "16px", minHeight: 22 }}>
                            {`${this.state.report_rows_count} ${
                              this.state.report_rows_count == 1
                                ? " record shown"
                                : " records shown"
                            }`}
                          </p>
                        }
                      </Grid.Column>
                      <Grid.Column tablet={2} computer={4} textAlign="right" />
                    </Grid>
                    <Modal
                      closeIcon
                      centered={false}
                      closeOnDimmerClick={false}
                      open={this.state.display_extra_records_modal}
                      onClose={this.confirm_close_handler}
                      className="confirm_box default-modal"
                      id={"confirm_box"}
                    >
                      <Modal.Header>Max Record Alert</Modal.Header>
                      <Modal.Content>
                        <Modal.Description>
                          <Grid>
                            <Grid.Column>
                              <p>
                                The limit of {this.total_rows_per_view} records
                                will be returned.
                              </p>
                              <p>
                                {(this.state.total_rows_per_view - this.total_rows_per_view)} records
                                matching these search criteria will <b>not</b>{" "}
                                be displayed.
                              </p>
                              <br />
                              <p>Updates will only affect displayed records.</p>
                            </Grid.Column>
                          </Grid>
                          <Grid>
                            <Grid.Column computer={16} textAlign="right">
                              <Button
                                type="button"
                                id="confirm_button_confirm_box_window"
                                onKeyDown={(e) => {}}
                                onClick={this.confirm_close_handler}
                                primary
                              >
                                Continue
                              </Button>
                            </Grid.Column>
                          </Grid>
                        </Modal.Description>
                      </Modal.Content>
                    </Modal>
                    <GridView
                      id={bulk_payment_updates_report_grid_id}
                      row={this.report_data.rows}
                      column={this.report_data.column_def}
                      headerHeight={this.grid_header_height}
                      style={{
                        height: "100%",
                        display: "flex",
                        flexFlow: "column",
                      }}
                      wrapperClass={"grid_wrapper_hierarchy"}
                      enableColResize={true}
                      gridFullHeight={true}
                      get_grid_ref={this.get_grid_ref}
                      onRowSelection={this.on_check_box_selection}
                      suppressSizeToFit={true}
                      headerIdForTabNavigation="error_corrections_configuration_header_id"
                      emptyMessage={messages.no_records_found}
                      checkboxSelection={true}
                      handleCheckboxNavigation={true}
                      on_filter_button_click={this.on_filter_changed}
                    />
                  </div>
                )}
              </div>
              {this.state.show_grid && (
                <Form autoComplete="off">
                  <Grid.Column
                    tablet={16}
                    computer={16}
                    className="footer-area"
                    id="applicationFooterSticky"
                    style={{ paddingRight: 22, paddingLeft: 0 }}
                  >
                    <Grid style={{ marginTop: 0, marginBottom: 0 }}>
                      <Grid.Column tablet={4} computer={4} textAlign="left">
                        <Form.Field>
                          <label>Check/Credit Card Auth Number</label>
                          <Input
                            maxLength={50}
                            name={"check_card_auth_number_update"}
                            type={"text"}
                            id={"check_card_auth_number_update"}
                            style={{ width: "100%" }}
                            onBlur={(e) => this.input_onblur_handler(e)}
                            onChange={(e) =>
                              this.input_change_handler(e)
                            }
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column tablet={3} computer={3} textAlign="left">
                        <Form.Field>
                          <label>Deposit Date</label>
                          <DatePicker
                            maxDate={moment().format("MM/DD/YYYY")}
                            date_update={(date_object) =>
                              this.calendar_change_handler(
                                "deposit_date",
                                date_object
                              )
                            }
                            minDate={this.minDate}
                            name="deposit_date"
                            id_popper="root"
                            id="id-deposit-date"
                            style={{ width: "100%" }}
                            is_disabled = {this.state.is_checked_on_closed_month}
                          />
                           {this.state.is_checked_on_closed_month && (
                                    <p className="req-alert">
                                      Deposit Date cannot be edited on closed-month records.
                                    </p>
                                  )}
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column tablet={2} computer={2} textAlign="left">
                        <Form.Field>
                          <label>Batch Number</label>
                          <Input
                            maxLength={12}
                            name={"batch_update"}
                            type={"text"}
                            id={"batch_update"}
                            style={{ width: "100%" }}
                            onBlur={(e) => this.input_onblur_handler(e)}
                            onChange={(e) =>
                              this.input_change_handler(e)
                            }
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column tablet={2} computer={2} textAlign="left">
                        <Form.Field>
                          <label>Payment Code</label>
                          {this.render_dropdown_element(
                            "payment_code",
                            null,
                            this.dropdown_data.payment_code_single
                          )}
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column
                        tablet={3}
                        computer={3}
                        textAlign="right"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <Button
                          id="export_report_button"
                          type="submit"
                          onClick={this.on_export_button}
                          basic
                          disabled={this.state.is_export_disabled}
                          content={"Export"}
                        />
                        <Button
                          id="print_report_button"
                          type="submit"
                          onClick={this.on_print_button}
                          disabled={this.state.is_print_disabled}
                          basic
                          content={"Print"}
                        />
                        <Button
                          id="is_updated_disabled"
                          className="primary"
                          onClick={this.update_bulk}
                          type="button"
                          onKeyDown={set_focus_to_app_header}
                          style={{ marginRight: "0" }}
                          disabled={this.state.is_updated_disabled || !this.state.update_has_values}
                          content={"Apply"}
                        />
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Form>
              )}
            </div>
          </div>
        </LoaderComponent>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      get_patient_search: get_patient_search,
    },
    dispatch
  );
};

const mapStateToProps = (state: {
  patient_details: { patient_header: { data: { id: any } } };
  user_login_details: any;
  shared_details: any;
  launch_darkly: any;
}) => {
  return {
    patient_details: state.patient_details,
    user_login_details: state.user_login_details,
    shared_details: state.shared_details,
    patient_id:
      (state.patient_details &&
        state.patient_details.patient_header &&
        state.patient_details.patient_header.data &&
        state.patient_details.patient_header.data.id) ||
      "",
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BulkPaymentUpdateComponent)
);
