import * as React from 'react';
import { task_icon_config } from '../../global_constants';

interface IPatientTaskIconComponentState {
    patientId: any
}

interface IPatientTaskIconComponentProps {
    patientId: any
}

export class PatientTaskIconComponent extends React.Component<IPatientTaskIconComponentProps, IPatientTaskIconComponentState> {

    constructor(props) {
        super(props)
        this.state = {
            patientId: this.props.patientId && this.props.patientId.toString().trim() != "" ? this.props.patientId : '0'
        }
    }

    openWindow = () => {
        const [url, param] = task_icon_config.webpt_tasks_client_url.split('?r=')
        const redirect = atob(param)
        const newRedirect = btoa(`${redirect}?patientId=${this.state.patientId}&perspective=patients`)
        const modifiedURL = `${url}?r=${newRedirect}`
        window.open(modifiedURL, `patient-tasks-${this.state.patientId}`, 'toolbar=1,location=1,menubar=1,height=587,width=1279,left=320,top=147,')
    }

    render() {
        return (
            <svg viewBox="0 0 80 80" className="sc-gqjmRU jQXFWP" onClick={this.openWindow} style={{ minWidth: '22px', minHeight: '22px', cursor: 'pointer' }}>
                <g id="icon-tasking-gray-dark" strokeWidth="0">
                    <rect id="Rectangle" x="0" y="0" width="80" height="80" fill="none"></rect>
                    <path d="M36,48.0000012 C34.9388148,48.0008345 33.9207884,47.5799543 33.17,46.83 L25.17,38.83 C23.6070342,37.2670342 23.6070342,34.7329659 25.17,33.17 C26.7329659,31.6070342 29.2670342,31.6070342 30.83,33.17 L36,38.34 L49.17,25.17 C50.7329658,23.6070342 53.2670342,23.6070342 54.83,25.17 C56.3929658,26.7329658 56.3929658,29.2670342 54.83,30.83 L38.83,46.83 C38.0792116,47.5799543 37.0611852,48.0008345 36,48.0000012 Z" id="Path" fillRule="nonzero"></path>
                    <path d="M72,8 C72,5.790861 70.209139,4 68,4 L12,4 C9.790861,4 8,5.790861 8,8 L8,72 C8,74.209139 9.790861,76 12,76 L48,76 C48.2619487,75.9981304 48.5231255,75.971343 48.78,75.92 C48.89,75.92 48.99,75.86 49.1,75.83 L49.52,75.7 C49.65,75.7 49.76,75.57 49.88,75.51 C49.9931714,75.4560065 50.103333,75.3959184 50.21,75.33 C50.4318978,75.1831569 50.6394802,75.0157518 50.83,74.83 L70.83,54.83 C70.9965506,54.6608558 71.1471855,54.4767465 71.28,54.28 L71.39,54.1 C71.48,53.95 71.56,53.8 71.64,53.64 C71.72,53.48 71.64,53.52 71.72,53.45 C71.8030873,53.2353143 71.8699418,53.0146945 71.92,52.79 L71.92,52.79 C71.9685148,52.5598747 71.9919847,52.3251752 71.99,52.09 C71.99,52.09 71.99,52.09 71.99,52.01 L72,8 Z M16,12 L64,12 L64,48 L48,48 C45.790861,48 44,49.790861 44,52 L44,68 L16,68 L16,12 Z M52,62.34 L52,56 L58.34,56 L55.17,59.17 L52,62.34 Z" id="Shape" fillRule="nonzero"></path>
                </g>
            </svg>
        )
    }
}

export default PatientTaskIconComponent