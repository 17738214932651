import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as session_storage from '../../shared/session_storage_utility';
import { format_patient_name } from '../../shared/utility';
import { set_url_history } from "../util/report_data_formatter";
import { financial_reports } from '../report_constants';
export class ReportInterceptorComponent extends React.Component<any, any> {
    constructor(props) {
        super(props);
    }
    //Define class level varibales.
    report_id = 0;

    //It is used to reset the default values and redirect to financial and reports component.
    UNSAFE_componentWillMount() {
        session_storage.remove('report_search_criteria');
        session_storage.remove('report_advance_search');
        this.report_id = (this.props.location.state && this.props.location.state.report_id) ? this.props.location.state.report_id : session_storage.get("report_id");
        session_storage.set("report_id", this.report_id);
        if (session_storage.get("url_history").length > 1) {
            this.props.history.goBack();
            session_storage.set("url_history", []);
            return;
        }
        if(!this.props.set_url_history){
        set_url_history();
        }
        var company_id = (this.props.user_login_details
            && this.props.user_login_details.user_data
            && this.props.user_login_details.user_data.data
            && this.props.user_login_details.user_data.data.company_id) || 0;

        if (financial_reports.indexOf(this.report_id) != -1) {
            var patient_id = session_storage.get("active_patient") || 0;
            if (!patient_id) {
                this.props.history.push(`/no_patient_selected`);
                return;
            } 
            var patient_name = (this.props.selected_patient
                && this.props.selected_patient.data
                && format_patient_name(this.props.selected_patient.data.name) || '');
        }
        // Patient Payment Log
        if (this.report_id == 132) {
            var route = '/patient/report/sub_report_data?'
            var report_params = "rid=" + this.report_id + "&companyid=" + company_id + "&patientid=" + patient_id + "&PName=" + patient_name;
            this.props.history.push(`${route}${encodeURIComponent(report_params)}`, { report_id: this.report_id, is_sub_report_controls: true, is_financial: true });
        }
        // Patient Statement Log
        if (this.report_id == 30290) {
            var route = '/patient/report/sub_report_data?'
            var report_params = "rid=" + this.report_id + "&companyid=" + company_id + "&patientid=" + patient_id + "&rpt=patstmtlog";
            this.props.history.push(`${route}${encodeURIComponent(report_params)}`, { report_id: this.report_id,  is_financial: true });
        }
        // Patient Visit Log
        else if (this.report_id == 131) {
            var route = '/patient/report/sub_report_data?'
            var report_params = "rid=" + this.report_id + "&companyid=" + company_id + "&patientid=" + patient_id + "&rpt=visitlog";
            this.props.history.push(`${route}${encodeURIComponent(report_params)}`, { report_id: this.report_id, is_sub_report_controls: true, is_financial: true });
        }
        // Patient Billing History
        else if (this.report_id == 407) {
            var route = '/patient/report/sub_report_data?'
            var report_params = "rid=" + this.report_id + "&companyid=" + company_id + "&patientid=" + patient_id + "&PName=" + patient_name;
            this.props.history.push(`${route}${encodeURIComponent(report_params)}`, {
                report_id: this.report_id,
                is_financial: true
            });
        }
        // Patient Ledger Visit
        else if (this.report_id == 30100) {
            var route = '/patient/report/sub_report_data?'
            var report_params = "rid=" + this.report_id + "&companyid=" + company_id + "&patientid=" + patient_id + "&PName=" + patient_name;
            this.props.history.push(`${route}${encodeURIComponent(report_params)}`, {
                report_id: this.report_id,
                is_financial: true
            });
        }
        // Patient Cross Company
        else if (this.report_id == 326) {
            this.props.history.push(`/report/report_data`, { report_id: this.report_id });
        }
        // Insurance Overpayment Summary
        else if (this.report_id == 296) {
            var route = '/report/sub_report_data?'
            var report_params = "rid=" + this.report_id + "&companyid=" + company_id;
            this.props.history.push(`${route}${encodeURIComponent(report_params)}`, {
                report_id: this.report_id,
                is_sub_report_controls: false,
                is_financial: false
            });
        }
        else {
            this.props.history.push(`/report/report_data`, { report_id: this.report_id });
        }
        return; // Added in case of safari mac book, it reloads the browser.
    }

    render() {
        return <React.Fragment></React.Fragment>;
    }
}

//Get user and login details from store.
const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        selected_patient: state.patient_details.patient_header,
    }
}

export default withRouter(connect(mapStateToProps)(ReportInterceptorComponent));

