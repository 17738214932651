import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import { sso_config_data } from '../../global_constants';
import { get_sso_token_value_from_url } from '../../shared/utility';
import { sso_login_reset } from '../action/sso_action';


export class SSOLoginResetComponent extends React.Component<any, any> {
    [x: string]: any;

    constructor(props) {
        super(props);
        // Initialize state property here
        this.state = {
            loading: false
        };
    }

    UNSAFE_componentWillMount() {
        this.sso_login_reset();
    }

    sso_login_reset = () => {
        let token = get_sso_token_value_from_url(window.location.href, sso_config_data.sso_setup_token_key);

        if (token) {
            this.setState({
                loading: true
            })
            sso_login_reset(token).then((response) => {
                if (response && response.data && response.data.status == 0) {
                    if (response.data.messages && response.data.messages.length > 0) {
                        toastr.error('', response.data.messages[0].message);
                    }
                }
                console.log('good', response);
                window.close();
                this.setState({
                    loading: false
                })
            })
                .catch(function (error) {
                    toastr.error('', 'Error while reset SSO.');
                    console.log('Error in SSO login reset', error);
                    window.close();
                    this.setState({
                        loading: false
                    })
                });
        } else {
            setTimeout(() => {
                //if token not found
                toastr.error('', 'Error while reset SSO. SSO reset token not found.');
                console.log('SSO login reset token not found');
                window.close();
                this.setState({
                    loading: false
                });
            }, 10);

        }
    }

    render() {

        return (
            <div>
                <Dimmer active={this.state.loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
            </div>
        );
    }
}

export default SSOLoginResetComponent;
//export default withRouter(connect()(SSOLoginResetComponent))
