import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import { Checkbox, Grid, Header, Table, TableBody } from 'semantic-ui-react';
import { handle_click_on_enter } from './../../shared/tab_navigation_utility';

export class AdditionalPermission extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.additional_permission_list,
      all_permission: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.update_additional_permission) {
      let all_permission = true;

      this.props.additional_permission_list.some((permission, index, permissions) => {
        if (!permission.is_access) {
          all_permission = false;
          return true;
        }
      });

      this.setState({
        data: this.props.additional_permission_list,
        all_permission: all_permission
      });
    }
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  toggle_permissions = (e, toggle, index) => {
    let data = this.state.data;
    let all_permission = this.state.all_permission;
    data[index].is_access = toggle.checked;

    const enabledPermission = data.filter((value, index, array) => {
      return value.is_access == true;
    });

    if (enabledPermission.length == data.length) {
      all_permission = true;
    } else {
      all_permission = false;
    }

    this.setState({
      data: data,
      all_permission: all_permission
    });
  };

  toggle_all_permissions = (e, toggle) => {
    let data = this.state.data;

    data.map((permission, index, permissions) => {
      permission.is_access = toggle.checked;
    });
    this.setState({
      data: data,
      all_permission: toggle.checked
    });
  };

  get_update_additional_permission_list = () => {
    let update_list = this.state.data;
    return update_list;
  };

  render() {
    
    let additional_permission = this.state.data;
    let all_permission = this.state.all_permission;
    let is_parent_disabled =  false;
    if(additional_permission.length == 0) {
      all_permission =  false;
      is_parent_disabled = true;
    }
    return (
      <Grid
        className='form patient-search-form comapny_details_sub_section mar-t-5'
        style={user_css.user_additional_grid}
      >
        <Grid.Column id='ip_access_section' computer={16} className=''>
          <Header as='h2' textAlign='left' className='section-heading-mini_gray'>
            Additional Permission
          </Header>
          <Grid>
            <Grid.Column tablet={16} computer={16}>
              <Grid.Row>
                <div className='customtable' style={{ border: 0 }}>
                  <div className='table-responsive'>
                    <Table
                      id={'ip_access_section_table_' + this.props.row_count}
                      className='charge_review_patient_info'
                    >
                      <Table.Header>
                        <Table.Row key={'ip_access_table_row_' + this.props.row_count}>
                          <Table.HeaderCell style={{ width: '60%', minWidth: '60%', minHeight: '42px'  }}>
                            Available Additional Permissions
                          </Table.HeaderCell>
                          <Table.HeaderCell className='text-center'>
                            <div style={user_css.postionRe}>
                              <Checkbox toggle checked={all_permission} onChange={this.toggle_all_permissions} 
                              disabled={is_parent_disabled}
                              onKeyDown={handle_click_on_enter}/>
                              <span style={user_css.allText}> All</span>
                            </div>
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      {additional_permission.length > 0 && <TableBody>
                        {additional_permission ? (
                          additional_permission.map((item, index) => {
                            return (
                              <Table.Row key={'patient_info_' + index}>
                                <Table.Cell>
                                  <p>{item.ps_name}</p>
                                </Table.Cell>
                                <Table.Cell className='text-center'>
                                  <Checkbox
                                    toggle
                                    checked={item.is_access}
                                    onChange={(e, toggle) => {
                                      this.toggle_permissions(e, toggle, index);
                                    }}
                                    onKeyDown={handle_click_on_enter}
                                  />
                                </Table.Cell>
                              </Table.Row>
                            );
                          })
                        ) : (
                          <Table.Row key={'patient_info_empty'}>
                            <Table.Cell>No Permission Found</Table.Cell>
                          </Table.Row>
                        )}
                      </TableBody>}
                    </Table>
                  </div>
                </div>
              </Grid.Row>
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid>
    );
  }
}

// Css Style
const user_css = {
  user_additional_grid: {
    borderColor: '#cccccc',
    borderStyle: 'solid',
    borderWidth: '2px 1px 1px',
    background: '#fff',
    padding: 0,
    height: '100%'
  },
  postionRe: {
    margin: 0,
    padding: 0,
    display: 'inline-block',
    width: '120px',
    position: 'relative'
  } as React.CSSProperties,
  allText: {
    position: 'absolute',
    top: '1px',
    left: '90px'
  } as React.CSSProperties
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

const mapStateToProps = state => {
  return {};
};

export default reduxForm({
  form: 'reset'
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AdditionalPermission)
);
