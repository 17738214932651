import * as React from "react";
import { Input, Button, Icon } from "semantic-ui-react";
import { handle_focus_on_tab } from "../tab_navigation_utility";

export interface IMultiSelectPanelProps {
    hide_reset?: boolean;// To hide reset button set it to true
    hide_select_all?: boolean;//To hide select all button set it to true
    data: any; //IMultiSelectDropDownDataDto defined in global constants
    selected_list?: Array<string | number>;//List of selected string/numbers items 
    include_future?: boolean; //To display/hide checkbox set include_future true/false
    include_future_selected?: boolean; //To show checkbox checked/unchecked set include_future_selected true/false
    displayExtended?: boolean; //To display toggle eye icon in search box
    drawer_type?: string;//If displayExtended true and drawer_type='permission_sets' then filter will be applied on 'menu_description' key of data list
                        //If displayExtended true and drawer_type !='permission_sets' then filter will be applied on 'description' key of data list
    onChangeSelected?: any; //Callback method to parent component and returns selected item list
    toggleExtendedDrawer?: any;// Callback method on click on toggle eye icon
    className?: string; //Css class name of multi select component
    id?: string;//Id of  multi select component
    searchBoxId?: string; //Id of search textbox
    is_save_in_progress?: boolean;//If is_save_in_progress is true then checkbox label class will be 'error tooltip' else 'tooltip'
    is_add_edit_allowed?: boolean;//If displayExtended and is_add_edit_allowed is true then select all button will be disabled
    btn_id?: string //Control id where focus has to be set after focus on reset button
}

export class MultiSelectPanelComponent extends React.Component<IMultiSelectPanelProps, any> {
    _is_mounted = false;
    constructor(props) {

        super(props);
        this.state = {
            data: this.props.data,
            selected_values: this.props.selected_list,
            filter_text: '',
            include_future_selected: this.props.include_future_selected || false,
            displayExtended: this.props.displayExtended || false,
            toggle_selected: true
        };
    }
    componentDidMount = () => {
        this._is_mounted = true;
        if (this.props.include_future && this.state.include_future_selected) {
            this.setState({
                selected_values: [...this.props.data]
            })
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this._is_mounted &&
            (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) || JSON.stringify(prevProps.selected_list) !== JSON.stringify(this.props.selected_list))) {
            let selected_list = this.props.selected_list;

            // handling edge case in case of include future 
            if (this.props.include_future_selected) {
                selected_list = this.state.selected_values;
            }

            this.setState({
                data: this.props.data,
                selected_values: selected_list,
                filter_text: ''
            });
        }
    };

    on_select = (event, item_id) => {
        const { checked } = event.target;
        var selected_values = this.state.selected_values;
        if (checked) {
            selected_values.push(item_id)
            this.setState({
                selected_values
            })
        }
        else {
            var index = selected_values.indexOf(item_id)
            if (index !== -1) {
                selected_values.splice(index, 1);
            }
            this.setState({
                selected_values
            })
        }
        this.notify_selected_to_parent(selected_values);
    }

    on_search = (event) => {
        
        if (event.target.value) {
            let data = [];
            if(this.props.displayExtended){
                let desc_key = 'description'
                if(this.props.drawer_type=='permission_sets'){
                    desc_key = 'menu_description'
                }
                data = this.props.data.filter((item) => {
                    if (
                      item['name'].toString().toLowerCase().includes(event.target.value.toString().toLowerCase()) ||
                        (item[desc_key] != undefined && item[desc_key].toString().toLowerCase().includes(event.target.value.toString().toLowerCase()))
                    ) {
                      return item;
                    }
                });
            }else{
                data = this.props.data.filter(item => item.name.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()));
            }
            this.setState({
                data,
                filter_text: event.target.value
            })
        }
        else {
            this.setState({
                data: this.props.data,
                filter_text: event.target.value
            })
        }
    }

    on_select_all = () => {
        var selected_values = this.state.selected_values;
        this.state.data.filter(item => item.is_disabled != true).map((item) => {
            if (!selected_values.includes(item.value)) {
                selected_values.push(item.value);
            }
        });
        this.setState({
            selected_values
        });
        this.notify_selected_to_parent(selected_values);
    }

    on_reset = () => {
        this.setState({
            filter_text: '',
            selected_values:[],
            data: this.props.data,
            include_future_selected: false
        });
        this.notify_selected_to_parent([]);
    }

    /**
     * This method is called by a parent component by instantiation reference
     * and allows updating the list of selected ones and not need to notify
     * that action to the parent component.
     */
    update_to_specific_selections_without_notification = (selectedValuesIncoming) => {
        let selected_values = selectedValuesIncoming;
        this.setState({
            selected_values
        });
    }

    notify_selected_to_parent = (selected_list) => {
        this.props.onChangeSelected && this.props.onChangeSelected(selected_list);
    }

    toggle_include_future = () => {
        let include_future_selected = this.state.include_future_selected;
        let selected_values = this.state.selected_values;
        let value_to_notify = null;

        if (include_future_selected) {
            selected_values = [];
            value_to_notify = [];
        } else {
            this.props.data.filter(item => item.is_disabled != true).map((item) => {
                if (!selected_values.includes(item.value)) {
                    selected_values.push(item.value);
                }
            });
            value_to_notify = [-1];
        }

        this.setState({
            selected_values,
            include_future_selected: !include_future_selected
        });
        this.notify_selected_to_parent(value_to_notify);
    }

    toggle_display_selected = () => {
        this.setState((prevState) => ({
            toggle_selected: !prevState.toggle_selected,
            data: prevState.toggle_selected ? this.props.data.filter(item => prevState.selected_values.includes(item.value)) : this.props.data
        }));
    }

    handle_icon_keydown = (e)=>{
        const list: any = document.getElementById(`multi-select-panel-list`);
        const list_items = list.children;
        for (let i = 0; i < list_items.length; i++) {
          list_items[i].lastElementChild.style.display = '';
          list_items[i].className = '';
        }
        if (e.target.parentElement && e.target.parentElement.nextSibling) {
          e.target.parentElement.parentElement.className = 'focused';
          e.target.parentElement.nextSibling.style.display = 'inline';
        }
    }

    key_press = (event, value) => {
        if (event.which == 13) {
            if (this.props.toggleExtendedDrawer) {
                this.props.toggleExtendedDrawer(value)
            }
        }
    };

    render() {
        return (
            <div className={this.props.className} id={this.props.id ? this.props.id : "multi-select-panel"}>
                <div className="multi-select-panel">
                    <div className="multi-select-panel-header">
                        <div className="input-tag">
                            <span
                                {...(this.state.displayExtended && this.state.selected_values.length > 0 && { onClick: () => this.toggle_display_selected() })}
                                className={this.state.selected_values.length == 0 ? "hide-total" : this.state.displayExtended ? 'cursor_point' : ""}
                                style={{ backgroundColor: !this.state.toggle_selected || (this.state.selected_values.length == this.state.data.length)?  '#00A7E1' : '' }}
                                itemID="span_total">
                                {this.state.displayExtended && <Icon aria-hidden='true' name='eye' />}{this.state.selected_values.length} Selected
                            </span>
                            <Input
                                id={this.props.searchBoxId ? this.props.searchBoxId : "multi-select-panel-search"}
                                autoComplete='off'
                                name='name'
                                type='text'
                                value={this.state.filter_text}
                                onChange={value => this.on_search(value)}
                                {...(this.state.displayExtended && {
                                    placeholder: 'Search...'
                                })}
                            />
                        </div>
                    </div>
                    <div className="multi-select-panel-body">
                        <div className="multi-select-panel-body-inner">
                            <ul id={'multi-select-panel-list'}>
                                {
                                    this.state.data.map((item, index) => {
                                        var is_checked = this.state.selected_values.indexOf(item.value) > -1;
                                        var label_class = "";
                                        if (item.on_hover_msg && this.props.is_save_in_progress == true && is_checked == true && !item.is_disabled)
                                            label_class = "error tooltip";
                                        else if (item.on_hover_msg)
                                            label_class = "tooltip";
                                        return (
                                            <li key={item.value} className={item.is_disabled ? "disabled" : ""}>
                                                {item.use_ind_checkbox ?
                                                    <div className={label_class}>
                                                        <i className="icon-icemoon icon-disabled_indeterminate"/>
                                                    {item.name}
                                                    {
                                                        (item.on_hover_msg) &&
                                                        (<span className="tooltiptext">{item.on_hover_msg}</span>)
                                                    }
                                                    </div>
                                                    :
                                                    <label className={label_class}>
                                                        <input type="checkbox"
                                                        id={this.props.id ? this.props.id + '-chk-' + item.value : 'chk-' + item.value}
                                                        onChange={value => this.on_select(value, item.value)}
                                                        checked={this.state.include_future_selected || is_checked}
                                                        disabled={this.state.include_future_selected || item.is_disabled}
                                                        {...(this.state.displayExtended && {
                                                            onFocus: (e) => {
                                                                this.handle_icon_keydown(e);
                                                            }
                                                        })}
                                                    />
                                                    {item.name}
                                                    {
                                                        (item.on_hover_msg) &&
                                                        (<span className="tooltiptext">{item.on_hover_msg}</span>)
                                                    }
                                                </label>
                                                }
                                                {this.state.displayExtended && 
                                                    <Icon 
                                                        aria-hidden='true' className='multi-select-eye-icon' name='eye' 
                                                        onClick={()=>this.props.toggleExtendedDrawer(item.value)} 
                                                        tabIndex={0} 
                                                        onKeyPress={(e)=>this.key_press(e, item.value)}
                                                    />
                                                }
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>

                    <div className="multi-select-panel-footer">
                        {!this.props.hide_select_all &&
                            <Button type='button' id='multi_select_panel_select_all' disabled={this.state.include_future_selected || (this.props.displayExtended && this.props.is_add_edit_allowed)} onClick={this.on_select_all} className="select-all">Select All</Button>
                        }
                        {this.props.include_future &&
                            <React.Fragment>
                                <input type="checkbox"
                                    id={this.props.id ? this.props.id + '-chk-include-future' : 'chk-include-future'}
                                    onChange={this.toggle_include_future}
                                    checked={this.state.include_future_selected}
                                    disabled={false}
                                    className={'multi_select_checkbox_include_future'}
                                />
                                <Button type='button' id='multi_select_panel_include_future' onClick={this.toggle_include_future} className="select-all multi_select_panel_include_future" content="Include future" />
                            </React.Fragment>
                        }
                        {!this.props.hide_reset &&
                            <Button type='button'
                                id='multi_select_panel_reset'
                                onClick={this.on_reset}
                                className="reset"
                                {...(this.state.displayExtended && { onKeyDown: (e) => handle_focus_on_tab(e, this.props.btn_id), disabled: this.props.is_add_edit_allowed })}
                            >Reset
                        </Button>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default MultiSelectPanelComponent;