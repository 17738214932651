import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as session_storage from '../../shared/session_storage_utility';
import { LoaderComponent } from '../../shared/component/loading_component';
import { Button, Form, Grid, Input, Accordion, Icon } from "semantic-ui-react";
import ReportHeader from '../../reports/component/report_header';
import { handle_click_on_enter, set_focus_to_app_header, applyFocusOnDateRangePicker } from '../../shared/tab_navigation_utility';
import DateRangePickerComponent from '../../shared/component/date_range_component';
import CheckboxComponent from '../../shared/component/checkbox_component';
import * as moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { toastr_options, charge_exception } from '../../global_constants';
import DatePicker from '../../shared/component/date_picker_component';
import AdvancedControl from "../../shared/component/advanced_control";
import AutoSearchComponent from '../../shared/component/auto_search_component';
import { procedure_code_quick_search, procedure_code_advance_search, adjustment_code_quick_search, payment_code_quick_search } from './../../shared/action/autosearch_action';
import SelectionSearch from '../../shared/component/selection_search_component';
import { get_charge_case_list } from '../../charge/action/charge_action';
import { prepare_case_options } from '../../charge/utility';
import ErrorCorrectionEditableComponent from './error_corrections_editable_component';
import * as local_storage from '../../shared/local_storage_utility';
import { get_popper_id } from '../utility';
import * as shared_constants from '../../shared/shared_constants';
import { content_length, content_width } from '../../shared/component/grid/utils';
import { get_editable_error_correction, save_edittable_error_correction as save_editable_error_correction } from '../action/payment_action';
import { date_format_with_no_time, get_all_error, print_html_data_to_pdf } from '../../shared/utility';
import { get_procedure_code_modifiers, log_error } from '../../shared/action/shared_action';
import { search_diagnosis_codes_by_page } from '../../diagnosis/action/diagnosis_action';
import ConfirmationComponent from '../../shared/component/confirmation_component';
import { DX_API_PAGE_SIZE } from '../edit_charge_util';
import { money_type } from '../../global_constants';
import { adjustment_code, payment_code } from '../../admin/constants/constants';
import { get_search_data } from '../../admin/constants/action/constants_action';

const Advanced_control = (props) => {
    return (
      <Grid.Column mobile={16} tablet={8} computer={4}>
        <Form.Field className='advance-quick-search'>
          <label>{props.label}</label>
          <AdvancedControl
            onGridRowSelection={props.onGridRowSelection}
            gridConfig={props.gridConfig}
            controlId={props.controlId}
            onSearch={props.onSearch}
            search_type={props.search_type}
            headerIdForGridTabNavigation={props.headerIdForGridTabNavigation}
          />
          <AutoSearchComponent
            getInputValue={props.getInputValue}
            default_value={props.default_value}
            errorMessage={'No Record Found !'}
            prepareRenderList={props.prepareRenderList}
            getList={props.getList}
            prepareDataList={props.prepareDataList}
            selectresult={props.selectResult}
            show_clear_search={true}
            clear_search={props.clear_search}
          />
        </Form.Field>
      </Grid.Column>
    );
};

export class ErrorCorrectionsFFComponent extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            show_grid: false,
            is_submitted: false,
            controls_is_expended: true,
            is_search_disabled: false,
            show_bummepd_charge_message_popup: false,
            procedure_code_search_data: {},
            charge_transaction_data: [],
            edit_all: false,
            button_text: 'Edit All',
            grid_conf: {
                isPagination: true,
                gridAutoHeight: true,
                selectionType: 'single',
                paginationPageSize: 20,
            },
            case_list: [],
            dropdown_data: {
                modifiers: [],
                diagnosis_codes: []
            },
            search_criteria: {
                date_of_service_from: null,
                date_of_service_to: null,
                deposit_date: null,
                charge_id: null,
                procedure_code: null,
                payment_code: null,
                adjustment_code: null,
                case_id: -1,
                check_number: "",
                batch_number: "",
                show_inactive: false,
                selected_adjustment_code: {
                    "title": '',
                    "label": ''
                },
                selected_payment_code: {
                    "title": '',
                    "label": ''
                },
                selected_procedure_code: {
                    "title": '',
                    "label": ''
                }
            }
        };
        this.scroll_ref = React.createRef()
    }
    
    maxIntegerValue = 2147483647; //this is the max integer value that can be represented in datatype int in SQL server which is the charge_id field type
    is_mounted = false;
    ref_node: any;
    scroll_ref: any;
    page_index = 1;
    page_size = DX_API_PAGE_SIZE;
    patient_id: any = null;
    token: string = '';
    case_list: any = [];
    minDate = null;
    search_enum = {
        procedure: 'procedure_code',
        payment: 'payment_code',
        adjustment: 'adjustment_code'
    };

    get_search_data = () => {
        this.patient_id = session_storage.get('active_patient');
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        let error_corrections_search = session_storage.get(`error_corrections_criteria`);
        if (error_corrections_search && error_corrections_search.search_criteria) {
          let updatedCriteria = {
            ...error_corrections_search.search_criteria,
            date_of_service_from: this.handle_date('date_of_service_from', error_corrections_search.search_criteria),
            date_of_service_to: this.handle_date('date_of_service_to', error_corrections_search.search_criteria),
            deposit_date: this.handle_date('deposit_date', error_corrections_search.search_criteria)
          };

          this.setState({ search_criteria: updatedCriteria }, () => {
            this.handle_search();
          });
        }
    };

    get_case_list = async () => {
        return await get_charge_case_list(this.token, this.patient_id).then(response => {
            if (response.data.data) {
                if (response.data.data.length > 0) {
                    if (this.is_mounted) {
                        this.case_list = prepare_case_options(response.data.data.reverse(), 'error_corrections');
                        this.setState({
                            case_list: this.case_list,
                        })
                    }
                } else {
                    if (this.is_mounted) {
                        this.case_list = []
                        this.setState({
                            search_criteria: { ...this.state.search_criteria, case_id: -1 },
                            case_list: this.case_list
                        });
                    }
                }
                return this.case_list;
            }
            else {
                if (this.is_mounted) {
                    this.case_list = []
                    this.setState({
                        search_criteria: { ...this.state.search_criteria, case_id: -1 },
                        case_list: this.case_list
                    });
                }
                return this.case_list;
            }
        });
    }

    handle_date = (name: React.ReactText, criteria, title = false) => {
        if (title) {
            return criteria[name] ? moment(criteria[name]).format('MM/DD/YYYY') : '';
        } else {
            return criteria[name] ? moment(moment(criteria[name]).format('MM/DD/YYYY')) : null;
        }
    };

    UNSAFE_componentWillMount = () => {
        this.patient_id = session_storage.get('active_patient');
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
    };

    componentDidMount = async () => {
        if (this.patient_id == '' || this.patient_id == undefined) {
            session_storage.set('no_patient_selected_for', this.props.location.pathname);
            this.props.history.push(`/no_patient_selected`);
        } else {
            this.is_mounted = true;
            if (!document.body.classList.contains('reports')) {
                document.body.classList.add('reports');
            }
            applyFocusOnDateRangePicker()

            const auth_data = local_storage.get("auth_data");
            let accrual_flag = false;
            let open_month = null;
            if (auth_data) {
                accrual_flag = auth_data.accrual_accounting || false;
                open_month = auth_data.open_month || null;
            }
            this.minDate = null;
            if (accrual_flag) {
                this.minDate = new Date(open_month);
            }

            //Load API data
            await this.get_all_data();
            this.get_search_data();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        //change page data if patient changes from header, instead of navigate to other page
        if (prevProps.patient_id && prevProps.patient_id != this.props.patient_id) {
            this.patient_id = session_storage.get("active_patient");
            this.clear_handler();
            this.get_case_list();
            applyFocusOnDateRangePicker();
        }
    }

    componentWillUnmount = () => {
        document.body.classList.remove('reports');
        this.is_mounted = false;
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            if (route_array && (route_array[2] !== 'error_corrections_details')) {
                session_storage.remove('error_corrections_criteria');
            }
        }
    };

    //Get data
    get_all_data = async () => {
        this.patient_id = session_storage.get('active_patient');
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;

        let arrData = [];

        arrData.push(get_charge_case_list(this.token, this.patient_id));
        arrData.push(get_procedure_code_modifiers(this.token));
        this.page_index = 1;
        const keyword = '';
        let query_string = `pageIndex=${this.page_index}&pageSize=${this.page_size}&keyword=${keyword}`;
        arrData.push(search_diagnosis_codes_by_page(this.token, query_string));
        if (this.is_mounted) {
            this.setState({
                loading: true,
            });
        }
        await Promise.all(arrData)
            .then((res) => {
                let state = {
                    ...this.state,
                    loading: false
                };
                this.case_list = [];
                //Case list data response
                if (res[0].data && res[0].data.data && res[0].data.data.length > 0) {
                    this.case_list = prepare_case_options(res[0].data.data.reverse(), 'error_corrections');
                    state['case_list'] = this.case_list;
                } else {
                    this.case_list = [];
                    state['case_list'] = this.case_list;
                    state['search_criteria'] = { ...this.state.search_criteria, case_id: -1 };
                }

                // Modifier list data response
                if (res[1].data && res[1].data.data && res[1].data.data.length > 0) {
                    state['dropdown_data'].modifiers = this.update_data(res[1].data.data, 'modifiers');
                } else {
                    state['dropdown_data'].modifiers = this.update_data([], 'modifiers');
                }

                //Diagnosis code list data response
                if (res[2].data && res[2].data.data && res[2].data.data.result && res[2].data.data.result.length > 0) {
                    this.page_index = res[2].data.data.pageIndex;
                    this.page_size = res[2].data.data.pageSize;
                    state['dropdown_data'].diagnosis_codes = this.update_data(res[2].data.data.result, 'diagnosis_codes');
                } else {
                    state['dropdown_data'].diagnosis_codes = this.update_data([], 'diagnosis_codes')
                }

                //Update state data
                if (this.is_mounted) {
                    this.setState(state);
                }
            },
                (error) => {
                    if (this.is_mounted) {
                        this.setState(prevState => ({
                            loading: false,
                            case_list: [],
                            dropdown_data: {
                                modifiers: [],
                                diagnosis_codes: []
                            },
                            search_criteria: { ...prevState.search_criteria, case_id: -1 }
                        }));
                    }
                    log_error(error);
                    if (error.response.data) {
                        const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                        toastr.error("", toastr_options);
                    }
                }
            )
            .catch((error) => {
                if (this.is_mounted) {
                    this.setState(prevState => ({
                        loading: false,
                        case_list: [],
                        dropdown_data: {
                            modifiers: [],
                            diagnosis_codes: []
                        },
                        search_criteria: { ...prevState.search_criteria, case_id: -1 }
                    }));
                }
                log_error(error);
                if (error.data.messages[0].message.indexOf("<br") > -1) {
                    this.show_html_content_toaster(error.response.data.messages[0].message);
                } else {
                    toastr.error("", error.response.data.messages[0].message);
                }
            });
    };

    // Search Criteria Toggler
    controls_toggle = () => {
        if (this.is_mounted) {
            this.setState({
                controls_is_expended: !this.state.controls_is_expended
            });
        }
    };

    // Edit All Toggler
    edit_all_toggle = () => {
        let load_time = 0;
        if (this.state.charge_transaction_data && this.state.charge_transaction_data.length > 0) {
            load_time = this.state.charge_transaction_data.length * 45;
        }
        if (this.is_mounted) {
            this.setState(
                {
                    loading: true,
                    button_text: this.state.edit_all ? 'Edit All' : 'Read All'
                },
                async () => {
                    await this.stop_loader(load_time, true)
                        .then((res) => {
                            if (res) {
                                this.setState({
                                    edit_all: !this.state.edit_all,
                                    loading: false
                                });
                            }
                        })
                        .catch(() => {
                            console.log('l1 cancelled');
                        });
                }
            );
        }
    };

    stop_loader = async (delay, value) => {
        return new Promise((resolve) =>
            setTimeout(() => {
                resolve(value);
            }, delay)
        );
    }

    update_data = (source, type) => {
        let target = [];
        let screenWidth = window.screen.width;
        let subVal = 8;
        let subValModifiers = 16;
        if (screenWidth > 1364) {
            subVal = 12;
        }
        if (source && source.length > 0) {
            for (let y = 0; y < source.length; y++) {
                let item = source[y];
                let text_val = (type == 'modifiers') ? (item.procedure_modifier_code ? item.procedure_modifier_code : '') + ' - ' + (item.procedure_modifier_desc ? item.procedure_modifier_desc : '') : (item.code ? item.code : '') + ' - ' + (item.description ? item.description : '');

                target.push({
                    disabled: false,
                    is_checked: false,
                    name: text_val,
                    ...item,
                    value: (type == 'modifiers') ? item.id : item.code,
                    label_text: (type == 'modifiers') ? text_val.substring(0, subValModifiers) : text_val.substring(0, subVal)
                })
            }
        }
        return target;
    }

    load_more_diagnosis = async (page_index, page_size, keyword = '') => {
        let query_string = `pageIndex=${page_index}&pageSize=${page_size}&keyword=${keyword}`;
        return await search_diagnosis_codes_by_page(this.token, query_string);
    }

    // Calender change handler.
    range_date_change_handler = (date_of_service_from: any, date_of_service_to: any) => {
        if (this.is_mounted) {
            this.setState(prevState => ({
                search_criteria: {
                    ...prevState.search_criteria,
                    date_of_service_from: date_of_service_from || null,
                    date_of_service_to: date_of_service_to || null
                },
                is_error_date: date_of_service_from ? (moment.isMoment(date_of_service_from) ? false : true) : false || date_of_service_to ? (moment.isMoment(date_of_service_to) ? false : true) : false
            }));
        }
    };

    max_value_validation = (input) => {
        if (Number(input) > 2147483647) {
            return this.max_value_validation(input.substring(0, input.length - 1));
        }
        else {
            return Number(input);
        }
    }

    handle_change = (e, type) => {
        let newValue, name, value = '';
        if (type != 'case_id') {
            name = e.target.name;
            value = e.target.value
        }
        switch (type) {
            case "case_id":
                name = type;
                newValue = e ? parseInt(e.value) : e;
                break;
            case "charge_id":
                newValue = (e.target.validity.valid) ?
                    Number(value) <= 2147483647 ?
                        value :
                        this.max_value_validation(value) :
                    this.state.search_criteria.charge_id;
                break;
            case "show_inactive":
                newValue = e.target.checked;
                break;
            default:
                newValue = value;
        }
        if (this.is_mounted) {
            this.setState(prevState => ({
                search_criteria: { ...prevState.search_criteria, [name]: newValue },
            }));
        }
    };

    // Function calls on click of Clear button and clear all the searched result as well as criteria.
    clear_handler = () => {
        if (this.is_mounted) {
            this.setState({
                search_criteria: {
                    date_of_service_from: null,
                    date_of_service_to: null,
                    deposit_date: null,
                    charge_id: null,
                    procedure_code: null,
                    adjustment_code: null,
                    payment_code: null,
                    case_id: -1,
                    check_number: "",
                    batch_number: "",
                    show_inactive: false,
                    selected_adjustment_code: {
                        "title": '',
                        "label": ''
                    },
                    selected_payment_code: {
                        "title": '',
                        "label": ''
                    },
                    selected_procedure_code: {
                        "title": '',
                        "label": ''
                    }
                },
                show_grid: false,
                is_submitted: false,
                controls_is_expended: true,
                is_search_disabled: false,
                procedure_code_search_data: {},
                charge_transaction_data: []
            })
        }

        session_storage.remove(`error_corrections_criteria`);
    };

    handle_navigation_after_last_element = (event) => {
        // apply a check where grid is empty
        if (
            !event.shiftKey &&
            event.keyCode == "9" &&
            !this.state.show_grid
        ) {
            set_focus_to_app_header(event);
        }
    };

    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }

    handle_search = async () => {
        if (!this.patient_id) {
          toastr.error('', 'The Patient ID is invalid.');
          return;
        }

        if (this.is_mounted) {
          this.setState({
            loading: true,
            show_grid: false,
            is_search_disabled: true
          });
        }

        let criteria = this.state.search_criteria;
        let date_of_service_from = criteria.date_of_service_from
          ? moment(criteria.date_of_service_from).format('MM/DD/YYYY')
          : '01/01/1975';
        let date_of_service_to = criteria.date_of_service_to
          ? moment(criteria.date_of_service_to).format('MM/DD/YYYY')
          : moment().format('MM/DD/YYYY');
        let deposit_date = criteria.deposit_date ? moment(criteria.deposit_date).format('MM/DD/YYYY') : null;

        let parameters_to_set = {
            ...this.state.search_criteria,
            deposit_date: deposit_date,
            patient_id: parseInt(this.patient_id)
        }

        let params = {
            ...parameters_to_set,
            date_of_service_from: date_of_service_from,
            date_of_service_to: date_of_service_to
        }
        delete params.selected_procedure_code;
        delete params.selected_payment_code;
        delete params.selected_adjustment_code;

        await get_editable_error_correction(this.token, params).then(
          (response) => {
            if (response.data && response.data.status === 1) {
              session_storage.set(`error_corrections_criteria`, {
                search_criteria: parameters_to_set
              });
              if (this.is_mounted) {
                this.setState({
                  charge_transaction_data: response.data.data || [],
                  controls_is_expended: response.data.data.length > 0 ? false : true,
                  edit_all: response.data.data.length == 1 ? true : false,
                  button_text: response.data.data.length == 1 ? 'Read All' : 'Edit All'
                });
              }
            } else {
              const toastr_options = this.show_html_content_toaster(get_all_error(response.data));
              toastr.error('', toastr_options);
            }
            if (this.is_mounted) {
              this.setState({
                loading: false,
                show_grid: true,
                is_search_disabled: false
              });
            }
          },
          (error: { response: { data: any } }) => {
            if (this.is_mounted) {
              this.setState({
                loading: false,
                is_search_disabled: false
              });
            }

            if (error.response.data) {
              const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
              toastr.error('', toastr_options);
            }
          }
        );
    };

  // ################## Advanced Control ##################
  get_quick_search_list = async (search_keyword: any, type: string) => {
    let result: any;
    if (type == this.search_enum.procedure) {
      result = await procedure_code_quick_search(search_keyword, this.token);
    } else if (type == this.search_enum.adjustment) {
      result = await adjustment_code_quick_search(search_keyword, this.token);
    } else {
      result = await payment_code_quick_search(search_keyword, this.token);
    }
    return result;
  };

  // on provider search item selection
  on_advanced_search_grid_row_selection = (selectedRow, type: any) => {
    if (this.is_mounted) {
      this.setState((prev) => ({
        search_criteria: {
          ...prev.search_criteria,
          [type]: selectedRow.id,
          [`selected_${type}`]: selectedRow
        }
      }));
    }
  };

  // clear data on clear icon
  clear_quick_search = (type: any) => {
    if (this.is_mounted) {
      this.setState((prev) => ({
        search_criteria: {
            ...prev.search_criteria,
            [type]: null,
            [`selected_${type}`]: {
              title: '',
              label: ''
            }
        }
      }));
    }
  };

  // get the searched value which is typed for searching location or providers
  get_input_value = (input: { trim: () => { (): any; new (): any; length: number } }, type: string) => {
    if (input.trim().length >= 3) {
      this.get_quick_search_list(input, type);
    }
    if (this.is_mounted) {
      this.setState({
        form: { ...this.state.form, insurance_payer: null }
      });
    }
  };

  // Auto search for class
  renderResult = (props: { title: string | number; label: React.ReactNode }) => {
    return (
      <div key={props.title + '_main'} tabIndex={0} className='item_auto_search'>
        <div key={props.title} className='fs_13'>
          <span>{props.label}</span>
        </div>
      </div>
    );
  };

  row_selection_format = (item, type: string) => {
    let formatted = null;
    if (type == this.search_enum.procedure) {
      formatted = {
        title: `${item.proc_code}`,
        code: item.proc_code,
        description: item.proc_desc1,
        id: item.proc_code,
        label: item.proc_code + (item.proc_desc1 ? `- ${item.proc_desc1}` : '')
      };
    } else if (type == this.search_enum.adjustment) {
      formatted = {
        title: `${item.adjustment_code}`,
        code: item.adjustment_code,
        description: item.adjustment_desc,
        id: item.adjustment_code_id,
        label: item.adjustment_code + (item.adjustment_desc ? `- ${item.adjustment_desc}` : '')
      };
    } else {
      formatted = {
        title: `${item.payment_code}`,
        code: item.payment_code,
        description: item.payment_desc,
        id: item.payment_code_id,
        label: item.payment_code + (item.payment_desc ? `- ${item.payment_desc}` : '')
      };
    }
    return formatted;
  };

  // prepare the suggestion list with search result
  prepare_suggestion = (data: { data: string | any[] }, type: string) => {
    let formattedList = [];
    let data_length = data.data.length;
    if (data && data_length) {
      for (let i = 0; i < data_length; i++) {
        let item = data.data[i];
        if (type == this.search_enum.procedure) {
            formattedList.push({
                title: `${item.proc_code}`,
                code: item.proc_code,
                description: item.proc_desc1,
                id: item.proc_code,
                label: item.proc_code + (item.proc_desc1 ? `- ${item.proc_desc1}` : '')
            });
        } else if (type == this.search_enum.adjustment) {
          formattedList.push({
            title: `${item.adjustment_code}`,
            code: item.adjustment_code,
            description: item.adjustment_desc,
            id: item.adjustment_code_id,
            label: item.adjustment_code + (item.adjustment_desc ? `- ${item.adjustment_desc}` : '')
          });
        } else {
          formattedList.push({
            title: `${item.payment_code}`,
            code: item.payment_code,
            description: item.payment_desc,
            id: item.payment_code_id,
            label: item.payment_code + (item.payment_desc ? `- ${item.payment_desc}` : '')
          });
        }
      }
    }
    return formattedList;
  };

  // search by typing name or code
  on_advance_search = async (params, type: string) => {
    let search_data = null;
    let config_column = null;
    const code_desc = type === 'adjustment_code' ? 'adjustment_desc' : 'payment_desc';
    const api = `/constant/${type}/search?${type}=${params[type] || ''}&${code_desc}=${
      params[code_desc] || ''
    }&show_inactive=false`;
    if (type == this.search_enum.procedure) {
      search_data = await procedure_code_advance_search(params, this.token).then((res) => res.data);
      config_column = this.column_def_format(
        shared_constants.search_procedure_code_configuration('procedure_code_search_type').column_defs,
        search_data.data.result
      );
      search_data = {
        ...search_data,
        data: search_data.data.result
      };
    } else if (type == this.search_enum.adjustment) {
      search_data = await get_search_data(this.token, api).then((res) => res.data);
      config_column = adjustment_code.column_def.slice(0, -2);
    } else {
      search_data = await get_search_data(this.token, api).then((res) => res.data);
      config_column = payment_code.column_def.slice(0, -2);
    }
    const search_result = search_data && search_data.data !== null ? search_data.data : [];
    const grid_data = {
      ...this.state.grid_conf,
      rows: search_result,
      column: config_column,
      messages: search_data.messages,
      paginationPageSize: 20
    };
    if (this.is_mounted) {
      this.setState({ [`${type}_grid_data`]: grid_data });
    }
  };

  column_def_format = (column_def, rows) => {
    if (rows && rows.length > 0) {
      let address1_length = 0;
      const result = rows.map((item) => {
        const text_address1_length = content_length(item.proc_desc1);
        if (text_address1_length > address1_length) {
          address1_length = text_address1_length;
        }
        return item;
      });
      column_def = column_def.map((item) => {
        if (item.field == 'proc_desc1') {
          return { ...item, minWidth: content_width(address1_length) };
        }
        return item;
      });
    }
    return column_def;
  };
  // ################## Advanced Control ##################

    refresh_bummepd_charge_modal = () => {
        if (this.is_mounted) {
            this.setState({
                show_bummepd_charge_message_popup: false
            }, () => {
                this.handle_search();
            });
        }
    }

    on_close_bummepd_charge_popup = () => {
        if (this.is_mounted) {
            this.setState({
                show_bummepd_charge_message_popup: false
            });
        }
    }

    on_print_button = () => {
        let printData = document.getElementById('error-correction-table');
        if (printData && printData.outerHTML) {
            print_html_data_to_pdf(printData.outerHTML, 'print_error_correction_button', 'Error Corrections');
        }
    }

    handle_save_click = (e) => {
        e.preventDefault();
        this.save_charge();
    }

    //Save charge and payment edited rows
    save_charge = (bypass_dx_check: boolean = false) => {
        let charges = this.ref_node.get_edited_data();
        this.setState({
            is_submitted: true
        });
        let chargeIdErrors = [];
        if (charges && charges.length > 0) {
            this.setState({
                loading: true,
                is_submitted: true
            });

            charges.map(charge => {
                charge.payments.forEach(payment => {
                    if(payment.to_charge_id && parseInt(payment.to_charge_id) > this.maxIntegerValue) {
                        chargeIdErrors.push(payment.to_charge_id);
                    }

                    if(payment.money_type == money_type.CREDIT_CARD_NUM || payment.money_type == money_type.DEBIT_CARD_NUM ){
                        payment.check_num = null;
                    }else{
                        payment.credit_card_num = null;
                    }

                    payment.deposit_date = date_format_with_no_time(payment.deposit_date);

                    if (payment.adjustment_amount == "") { payment.adjustment_amount = 0; }
                    if (payment.transfer_detail_amount == "") { payment.transfer_detail_amount = 0; }
                    if (payment.payment_amount == "") { payment.payment_amount = 0; }
                    if (payment.xfer_ins_code == "") { payment.xfer_ins_code = 0; }
                });

                charge.date_of_service = date_format_with_no_time(charge.date_of_service);
                charge.plan_of_care_date = date_format_with_no_time(charge.plan_of_care_date);
                charge.start_of_care_date = date_format_with_no_time(charge.start_of_care_date);
                charge.updated_date = date_format_with_no_time(charge.updated_date);
                if (charge.units == "") { charge.units = 0; }
            });
            
            if(chargeIdErrors.length > 0)
            {
                toastr.error(`${chargeIdErrors.length} value(s) for the To Charge field exceeds the maximum field length.`);
                this.setState({
                    loading: false,
                });
                return;
            }

            // return prepared_data;
            save_editable_error_correction(charges, this.token).then(res => {
                let state_object = { is_submitted: false };
                if (res.data.data && this.is_mounted) {
                    if (res.data.data.saved_charge_records && res.data.data.saved_charge_records > 0) {
                        toastr.success('', `${res.data.data.saved_charge_records} Charge saved.`);
                    }
                    if (res.data.data.fail_charge_records && res.data.data.fail_charge_records > 0) {
                        toastr.error('', `${res.data.data.fail_charge_records} Charge failed.`);
                    }

                    if (res.data.data.saved_Payments_records && res.data.data.saved_Payments_records > 0) {
                        toastr.success('', `${res.data.data.saved_Payments_records} Payments saved.`);
                    }
                    if (res.data.data.fail_Payments_records && res.data.data.fail_Payments_records > 0) {
                        toastr.error('', `${res.data.data.fail_Payments_records} Payments failed.`);
                    }

                    this.handle_search();
                }

                if (res.data.messages && res.data.messages[0].message) {
                    let exception = res.data.messages.filter((item) => {
                        return item.code === charge_exception.code
                    })
                    if (exception && exception.length > 0) {
                        state_object['show_bummepd_charge_message_popup'] = true;
                    }
                    else {
                        let toastr_options = this.show_html_content_toaster(get_all_error(res.data));
                        toastr.error("", toastr_options);
                    }

                }

                //Update state
                this.setState({
                    loading: false,
                    ...state_object
                });
            },
                error => {
                    let state_object = {};
                    if (error && error.response && error.response.data && error.response.data.messages && error.response.data.messages.length > 0) {
                        let exception = error.response.data.messages.filter((item) => {
                            return item.code === charge_exception.code
                        })
                        if (exception && exception.length > 0) {
                            state_object['show_bummepd_charge_message_popup'] = true;
                        } else {
                            const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                            toastr.error('', toastr_options);
                        }
                    }
                    else {
                        if (error.response.data) {
                            const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                            toastr.error("", toastr_options);
                        }
                    }

                    //Update state
                    this.setState({
                        loading: false,
                        ...state_object
                    });
                });

        }
    }

    onScroll = (e) => {
        const scrollTop = this.scroll_ref.current.scrollTop;
        const table_Warp = this.scroll_ref.current;
        if (this.ref_node) {
          const searchFrom = table_Warp.querySelector('form').clientHeight;
          const buttonArea = table_Warp.querySelector('#button_area').clientHeight;
          const tablePosition = searchFrom + buttonArea;
          const thead = table_Warp.querySelector('thead');
          thead.style =
            scrollTop > tablePosition
              ? `transform: translate(0, ${scrollTop - tablePosition - 5}px);
                 box-shadow: #00a7e1 0px 1px 0px, #bec7d2 0px -1px 0px;
                 z-index: 3;
                 position: relative;`
                : `transform: translate(0, 0); box-shadow:none`;
        }
    };

    render() {
        const { loading, controls_is_expended, is_clear_disabled, is_error_date, search_criteria, is_search_disabled, charge_transaction_data } = this.state

        return (
            <LoaderComponent loading={loading}>
                <div className={'common-forms-add '}>
                    <div className={'common-forms-search report-framework batch_billing_run'}>
                        <ReportHeader title={"Error Corrections"} />
                        <div id="id_div_popper" />
                        <div className='outer-wrap' ref={this.scroll_ref} onScroll={this.onScroll}>
                            <div className='patient-search-form  patient_search_bottom_padding' id='report-criteria-container'>
                                <Form autoComplete='off'>
                                    <Grid>
                                        <Grid.Column
                                            computer={16}
                                            tablet={16}
                                            textAlign='left'
                                            className='accordionColumn'
                                            id='accordion-column'
                                        >
                                            <Accordion fluid styled>
                                                <Accordion.Title
                                                    active={controls_is_expended}
                                                    index={0}
                                                    onClick={this.controls_toggle}
                                                >
                                                    <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name='angle right' />
                                                    Search Criteria
                                                </Accordion.Title>
                                                <Accordion.Content active={controls_is_expended}>
                                                    <Grid>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field style={{zIndex:3, position:'relative'}}>
                                                                <label>
                                                                    Date of Service
                                                                </label>
                                                                <DateRangePickerComponent
                                                                    updateDatesChange={(date_of_service_from, date_of_service_to) =>
                                                                        this.range_date_change_handler(date_of_service_from, date_of_service_to)
                                                                    }
                                                                    startDate={search_criteria.date_of_service_from}
                                                                    endDate={search_criteria.date_of_service_to}
                                                                    error={is_error_date}
                                                                    id={'dos'}
                                                                    maxDate={moment()}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field >
                                                                <label>Charge ID</label>
                                                                <Input
                                                                    id='charge_id'
                                                                    name='charge_id'
                                                                    pattern='[0-9]*'
                                                                    value={search_criteria.charge_id || ""}
                                                                    onChange={(e) => this.handle_change(e, 'charge_id')}
                                                                    type='text'
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field >
                                                                <label>Deposit Date</label>
                                                                <DatePicker
                                                                    maxDate={moment().format('MM/DD/YYYY')}
                                                                    id_popper={get_popper_id()}
                                                                    date_update={date => this.setState(prevState => ({
                                                                        search_criteria: { ...prevState.search_criteria, deposit_date: date }
                                                                    }))}
                                                                    date={search_criteria.deposit_date}
                                                                    minDate={this.state.minDate} id="deposit_date" />
                                                            </Form.Field>
                                                        </Grid.Column>                                                        
                                                        <Advanced_control
                                                            // quick search
                                                            label={'Procedure Code'}
                                                            onGridRowSelection={(row) =>
                                                                this.on_advanced_search_grid_row_selection(this.row_selection_format(row, this.search_enum.procedure), this.search_enum.procedure)
                                                            }
                                                            gridConfig={this.state.procedure_code_grid_data}
                                                            controlId={'PROCEDURE_CODE'}
                                                            onSearch={(param) => this.on_advance_search(param, this.search_enum.procedure)}
                                                            search_type={'PROCEDURE_CODE'}
                                                            headerIdForGridTabNavigation={'procedure_code_search'}
                                                            // advance search
                                                            getInputValue={(e) => this.get_input_value(e, this.search_enum.procedure)}
                                                            default_value={this.state.search_criteria.selected_procedure_code}
                                                            prepareRenderList={this.renderResult}
                                                            getList={(e) => this.get_quick_search_list(e, this.search_enum.procedure)}
                                                            prepareDataList={(data) => this.prepare_suggestion(data, this.search_enum.procedure)}
                                                            selectResult={(item) => this.on_advanced_search_grid_row_selection(item, this.search_enum.procedure)}
                                                            clear_search={() => this.clear_quick_search(this.search_enum.procedure)}
                                                        />
                                                        <Grid.Column tablet={8} computer={4} >
                                                            <Form.Field >
                                                                <label>Case</label>
                                                                <SelectionSearch
                                                                    id="error_correction_case"
                                                                    name="case"
                                                                    noOptionsMessage={'No Case Found !'}
                                                                    defaultValue={search_criteria.case_id}
                                                                    options={this.state.case_list || []}
                                                                    onChange={(value: any) => this.handle_change(value, 'case_id')}
                                                                    placeHolder="Case"
                                                                    autoFocus={false}
                                                                    openMenuOnFocus={true}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field >
                                                                <label>Check Number</label>
                                                                <Input
                                                                    autoComplete="off"
                                                                    name="check_number"
                                                                    id="check_number"
                                                                    onChange={(e) => this.handle_change(e, 'check_number')}
                                                                    value={search_criteria.check_number || ""}
                                                                    type="text"
                                                                    maxLength="50"
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field >
                                                                <label>Batch Number</label>
                                                                <Input
                                                                    autoComplete="off"
                                                                    name="batch_number"
                                                                    id="batch_number"
                                                                    onChange={(e) => this.handle_change(e, 'batch_number')}
                                                                    value={search_criteria.batch_number || ""}
                                                                    type="text"
                                                                    maxLength="40"
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Advanced_control
                                                            // quick search
                                                            label={'Payment Code'}
                                                            onGridRowSelection={(row) =>
                                                                this.on_advanced_search_grid_row_selection(this.row_selection_format(row, this.search_enum.payment), this.search_enum.payment)
                                                            }
                                                            gridConfig={this.state.payment_code_grid_data}
                                                            controlId={'PAYMENT_CODE'}
                                                            onSearch={(param) => this.on_advance_search(param, this.search_enum.payment)}
                                                            search_type={'PAYMENT_CODE'}
                                                            headerIdForGridTabNavigation={payment_code.id}
                                                            // advance search
                                                            getInputValue={(e) => this.get_input_value(e, this.search_enum.payment)}
                                                            default_value={this.state.search_criteria.selected_payment_code}
                                                            prepareRenderList={this.renderResult}
                                                            getList={(e) => this.get_quick_search_list(e, this.search_enum.payment)}
                                                            prepareDataList={(data) => this.prepare_suggestion(data, this.search_enum.payment)}
                                                            selectResult={(item) => this.on_advanced_search_grid_row_selection(item, this.search_enum.payment)}
                                                            clear_search={() => this.clear_quick_search(this.search_enum.payment)}
                                                        />
                                                        <Advanced_control
                                                            // quick search
                                                            label={'Adjustment Code'}
                                                            onGridRowSelection={(row) =>
                                                                this.on_advanced_search_grid_row_selection(this.row_selection_format(row, this.search_enum.adjustment), this.search_enum.adjustment)
                                                            }
                                                            gridConfig={this.state.adjustment_code_grid_data}
                                                            controlId={'ADJUSTMENT_CODE'}
                                                            onSearch={(param) => this.on_advance_search(param, this.search_enum.adjustment)}
                                                            search_type={'ADJUSTMENT_CODE'}
                                                            headerIdForGridTabNavigation={adjustment_code.id}
                                                            // advance search
                                                            getInputValue={(e) => this.get_input_value(e, this.search_enum.adjustment)}
                                                            default_value={this.state.search_criteria.selected_adjustment_code}
                                                            prepareRenderList={this.renderResult}
                                                            getList={(e) => this.get_quick_search_list(e, this.search_enum.adjustment)}
                                                            prepareDataList={(data) => this.prepare_suggestion(data, this.search_enum.adjustment)}
                                                            selectResult={(item) => this.on_advanced_search_grid_row_selection(item, this.search_enum.adjustment)}
                                                            clear_search={() => this.clear_quick_search(this.search_enum.adjustment)}
                                                        />
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field style={{ marginTop: 32 }}>
                                                                <CheckboxComponent
                                                                    name='show_inactive'
                                                                    id='show_inactive'
                                                                    onChange={e => this.handle_change(e, 'show_inactive')}
                                                                    checked={search_criteria.show_inactive}
                                                                    label={'Show Inactive'}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid>
                                                    <Grid>
                                                        <Grid.Column tablet={16} computer={16} textAlign='right'>
                                                            <Button
                                                                type='reset'
                                                                id='clear_button'
                                                                basic
                                                                onClick={this.clear_handler}
                                                                disabled={is_clear_disabled}
                                                                content={'Clear'}
                                                            />
                                                            <Button
                                                                id='search_error'
                                                                className='primary'
                                                                type='submit'
                                                                onKeyDown={this.handle_navigation_after_last_element}
                                                                style={{ marginRight: '0' }}
                                                                disabled={is_search_disabled}
                                                                content={'Search'}
                                                                onClick={this.handle_search}
                                                            />
                                                        </Grid.Column>
                                                    </Grid>
                                                </Accordion.Content>
                                            </Accordion>
                                        </Grid.Column>
                                    </Grid>
                                </Form>
                                {this.state.show_grid &&
                                <React.Fragment>
                                    {charge_transaction_data && charge_transaction_data.length > 0 && (
                                        <Grid id="button_area">
                                            <Grid.Column tablet={16} computer={16} textAlign='right'>
                                                <Button
                                                    type='button'
                                                    id='edit_all_button'
                                                    basic
                                                    onClick={this.edit_all_toggle}
                                                    content={this.state.button_text}
                                                />
                                            </Grid.Column>
                                        </Grid>
                                    )}
                                    <ErrorCorrectionEditableComponent
                                        onRef={ref => (this.ref_node = ref)}
                                        isSubmitted={this.state.is_submitted}
                                        charge_transaction_data={charge_transaction_data}
                                        dropdown_data={this.state.dropdown_data}
                                        edit_all={this.state.edit_all}
                                        edit_all_toggle={this.edit_all_toggle}
                                        getDxData={this.load_more_diagnosis}
                                    />

                                    {this.state.show_bummepd_charge_message_popup ?
                                        <ConfirmationComponent message={charge_exception.message}
                                            title='Confirm' show_modal={this.state.show_bummepd_charge_message_popup}
                                            onCloseModal={this.on_close_bummepd_charge_popup}
                                            save_button_text='Refresh'
                                            on_force_save_click={(e) => { this.refresh_bummepd_charge_modal() }} />
                                        : ''}
                                </React.Fragment>}
                            </div>
                        </div>
                        {this.state.show_grid && charge_transaction_data && charge_transaction_data.length > 0 && (
                            <div
                                className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                                id='applicationFooterSticky'
                                style={{ paddingRight: 15 }}
                            >
                                <Grid style={{ marginTop: 0, marginBottom: 0 }}>
                                    <Grid.Column computer={16} tablet={16} textAlign='right'>
                                        <Button
                                            id='print_error_correction_button'
                                            type='button'
                                            onClick={this.on_print_button}
                                            basic
                                            content='Print'
                                        />
                                        <Button
                                            id='save_error_correction_button'
                                            type='submit'
                                            primary
                                            content='Save'
                                            onKeyDown={set_focus_to_app_header}
                                            onClick={this.handle_save_click}
                                        />
                                    </Grid.Column>
                                </Grid>
                            </div>
                        )}
                    </div>
                </div>
            </LoaderComponent>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({

    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        patient_id: state.patient_details && state.patient_details.patient_header && state.patient_details.patient_header.data && state.patient_details.patient_header.data.id || ''
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorCorrectionsFFComponent));