import * as React from "react";
import { connect } from "react-redux";
import { Dimmer, Grid, Header, Loader } from "semantic-ui-react";
import { get_patient_account_notes_action, get_patient_account_notes_status_action } from "../../patient/action/patient_action";
import PatientCreditsComponents from '../../payment/component/patient_credits_component';
import * as session_storage from '../../shared/session_storage_utility';
import { get_patient_account_receipt, get_patient_credits, get_insurance } from '../action/payment_action';
import AllocatedTotalPaymentInfo from "./allocated_and_payment_total_info";
import PaymentNotes from "./payments_notes";
import AddUnAssignedPayment from "./unassigned-payment-add-component";
import AddAdditionalPayment from "./add_additional_payments_component";
import { patientCreditType } from '../../payment/payment_constants';
import * as local_storage from '../../shared/local_storage_utility';
import { get_lauch_darkley_key_value } from "../../shared/utility";
import { check_copay_transaction_type } from "../utility";
import { UnassignedPaymentFeatures } from "../../admin/constants/constants";

//Unassigned payment components for Unassigned Payments
export class UnassignedPayments extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            paymentsData: {
                accountNotes: [],
                receipt: [],
                credits: {}
            },
            payment_method_list: [],
            account_notes: this.props.get_patient_account_notes,
            patient_credits: this.props.patient_credits
        };
    }
    payment_notes = {
        token: "",
        sticky_code: 0,
        patient_id: session_storage.get("active_patient"),
    }
    UNSAFE_componentWillMount = () => {
        this.payment_notes = {
            token: this.props.user_login_details.user_data.data.token_details.access_token,
            sticky_code: 3,
            patient_id: session_storage.get("active_patient")
        }
    };

    //Get Data from api's
    componentDidMount = () => {
        document.body.classList.add('unassigned-payments');
        if ((this.payment_notes.patient_id == '' || this.payment_notes.patient_id == undefined)) {
            session_storage.set('no_patient_selected_for', this.props.location.pathname);
            this.props.history.push(`/no_patient_selected`);
        } else {
            this.setState({
                loading: true
            });
            this.get_data();
        }

    };

    get_data = async () => {
        this.get_patient_account_notes(this.payment_notes.patient_id, this.payment_notes.token, this.payment_notes.sticky_code);

        const token = local_storage.get("auth_data").token_details.access_token;
        const insurance_result = await get_insurance(this.payment_notes.patient_id, token).then((res) => res.data);
        const insurance_data = insurance_result.data !== null ? insurance_result.data.map((val, index) => { return { key: index, value: val.id, text: val.name, data: val } }) : [];
        const transaction_type = this.props.user_login_details.master_data_by_type.data.credit_types !== null ? this.props.user_login_details.master_data_by_type.data.credit_types : [];
        const payment_methods = this.props.user_login_details.payment_methods !== null ? this.props.user_login_details.payment_methods.map((val, index) => { return { key: index, value: val.value, text: val.text } }) : [];
        const insurance = {};

        //Story B-32481 and discussion
        // To make 0-Patient option for all transaction type
        //transaction_type.map(value => {
        //    insurance[value.credit_type_id] = this.filter_insurance(insurance_data, value.credit_type_id);
        //});

        transaction_type.map(value => {
            insurance[value.credit_type_id] = insurance_data;
        });
        this.setState({
            insurance_obj: insurance,
            payment_method_list: payment_methods
        });
        this.props.get_patient_credits(this.payment_notes.patient_id, this.payment_notes.token);
    }

    //Get Payments NotesData from API function
    componentDidUpdate(prevProps, prevState) {
        if (this.props.patient_account_notes_status && this.props.patient_account_notes_status.updated && this.props.patient_account_notes_status.addedNoteType === "Payment") {
            this.get_patient_account_notes(this.payment_notes.patient_id, this.payment_notes.token, this.payment_notes.sticky_code);
            this.props.get_patient_account_notes_status({
                updated: false,
                addedNoteType: "Payment"
            });
        }
        //change page data if patient changes from header, instead of navigate to other page
        if (prevProps.patient_id != this.props.patient_id) {
            this.payment_notes.patient_id = session_storage.get("active_patient");
            this.get_data();
        }
        if (this.props.credit_fatch_time !== prevProps.credit_fatch_time) {
            this.setState({
                loading: false
            })
            if (this.props.patient_credits && this.props.patient_credits.length < 2) {
                //Get insurance default asigned data if cash payment credits save and insurance empty.
                this.get_insurance();
            }
        }

    }

    componentWillUnmount() {
        document.body.classList.remove('unassigned-payments');
    }

    filter_insurance = (data, credit_type_id) => {
        const credit_type = credit_type_id || 0;
        return data.filter((item, index) => {
            //if (item.value == 0 && credit_type != patientCreditType.copay && credit_type != patientCreditType.patientrefund) {
            //Check for all copy type
            if (item.value == 0 && !check_copay_transaction_type(credit_type_id) && credit_type != patientCreditType.patientrefund) {
                return false;
            } else {
                return true;
            }
        });
    }

    //Get Payments Notes Data function
    get_patient_account_notes = async (patient_id, token, sticky_code) => {
        await get_patient_account_notes_action(patient_id, token, sticky_code).then(
            response => {
                this.setState({
                    paymentsData: {
                        ...this.state.paymentsData,
                        accountNotes: response.data
                    }
                });
            }
        );
    };


    //Get Patient account receipt function
    get_patient_account_receipt = async (patient_id, token) => {
        get_patient_account_receipt(patient_id, token).then(response => {
            this.setState({
                paymentsData: {
                    ...this.state.paymentsData,
                    receipt: response.data
                },
                loading: false
            });
        });
    };

    get_insurance = () => {
        const token = local_storage.get("auth_data").token_details.access_token;
        const transaction_type = this.props.user_login_details.master_data_by_type.data.credit_types !== null ? this.props.user_login_details.master_data_by_type.data.credit_types : [];
        get_insurance(this.payment_notes.patient_id, token).then((res) => {
            if (res.data && res.data.data) {
                const data = res.data.data.map((val, index) => { return { key: index, value: val.id, text: val.name, data: val } });
                const insurance = {};
                //Story B-32481 and discussion
                // To make 0-Patient option for all transaction type
                //transaction_type.map(value => {
                //    insurance[value.credit_type_id] = this.filter_insurance(data, value.credit_type_id);
                //});
                transaction_type.map(value => {
                    insurance[value.credit_type_id] = data;
                });
                this.setState({
                    insurance_obj: insurance,
                });
            }
        });
    }

    render() {
        const { paymentsData, loading } = this.state

        let patient = session_storage.get("active_patient"); // rendering the payment data only when patient is selected
        if (patient) {
            return (<div className="common-forms unassigned-payment" id="id_div_unassigned_payment">
                {paymentsData.accountNotes.length > 0 &&
                    (<PaymentNotes accountNotes={paymentsData.accountNotes} />)
                }
                <div id="id_div_popper" />
                <div className="auto-height-patient-full-details-scroll" id="scrollable-content-area">
                    <Dimmer active={loading}>
                        <Loader size="massive">Loading</Loader>
                    </Dimmer>

                    <Grid>
                        <Grid.Column computer={16}>
                            <Header as="h3" dividing textAlign="left">
                                Unassigned Payments
                        </Header>
                        </Grid.Column>
                    </Grid>
                    {this.props.rsiB23760UpdatesToUnassignedPaymentsPage ?
                        <AddAdditionalPayment
                            credits={this.props.credits_data}
                            patientId={session_storage.get('active_patient')}
                            insurance={this.state.insurance_obj || {}}
                            payment_method_list={this.state.payment_method_list}
                        /> :
                        <AddUnAssignedPayment
                            credits={this.props.credits_data}
                            patientId={session_storage.get('active_patient')}
                            payment_method_list={this.state.payment_method_list}
                        />
                    }
                    {Object.keys(this.props.credits_data).length > 0 &&
                        (<AllocatedTotalPaymentInfo paymentsCredits={this.props.credits_data} />)
                    }

                    <PatientCreditsComponents
                        patientId={session_storage.get('active_patient')}
                        rows={this.props.patient_credits}
                        creditFatchTime={this.props.credit_fatch_time}
                        paymentCode={this.props.payment_code || {}}
                        insurance={this.state.insurance_obj || {}}
                        payment_method_list={this.state.payment_method_list}
                        addBatchNum={this.props.magnusPlat713AddBatchNumToUnassignedPaymentsPage}
                    />
                </div>
            </div>);
        }

        //Display loader while patient Id fetching 
        return (<div className="common-forms unassigned-payment" id="id_div_unassigned_payment">
            <div className="auto-height-patient-full-details-scroll" id="scrollable-content-area">
                <Dimmer active={loading}>
                    <Loader size="massive">Loading</Loader>
                </Dimmer>
            </div>
        </div>);
    }
}


const filter_payment_code = (credits, payment_code_list, user_type_id) => {
    const payment_code_obj = {};
    credits.map(item => {
        if (item.is_active && item.is_active == true) {
            const credit_type_id = item.patient_credit_type.credit_type_id;
            const code_list = payment_code_list.filter(data => {
                if (user_type_id != 4 || data.clinic_credit_flag == true) {
                    if ((credit_type_id === patientCreditType.copay && data.copay_flag == true) || (credit_type_id != patientCreditType.copay)) {
                        return true;
                    }
                }
            }).map((val, index) => { return { key: index, value: val.payment_id, text: val.payment_desc, data: val } });
            payment_code_obj[credit_type_id] = code_list;
        }
    });
    return payment_code_obj;
}

//Get user and login details from store.
const mapStateToProps = state => {
    const { user_login_details, patient_details, payment_details } = state;
    const { credits_data, credit_fatch_time } = payment_details;
    const credits = credits_data && credits_data.credits || [];
    const payment_code_list = user_login_details.master_data.data.payment_code || [];
    const user_type_id = user_login_details.user_data.data.user_type_r3_id;
    const payment_code_obj = filter_payment_code(credits, payment_code_list, user_type_id)

    return {
        user_login_details: user_login_details,
        patient_account_notes_status: patient_details.patient_account_notes_status,
        credits_data: credits_data || {},
        patient_credits: credits_data && credits_data.credits || [],
        payment_code: payment_code_obj,
        credit_fatch_time: credit_fatch_time,
        patient_id: patient_details && patient_details.patient_header && patient_details.patient_header.data && patient_details.patient_header.data.id || '',
        rsiB23760UpdatesToUnassignedPaymentsPage: get_lauch_darkley_key_value(state.launch_darkly, 'rsiB23760UpdatesToUnassignedPaymentsPage'),
        magnusPlat713AddBatchNumToUnassignedPaymentsPage: get_lauch_darkley_key_value(state.launch_darkly, UnassignedPaymentFeatures.magnusPlat713AddBatchNumToUnassignedPaymentsPage),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        get_patient_account_notes_status: (data) => dispatch(get_patient_account_notes_status_action(data)),
        get_patient_credits: (patient_id, token) => dispatch(get_patient_credits(patient_id, token))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnassignedPayments);
