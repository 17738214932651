import moment = require("moment");

function autoDatePicker(string, prevSelectedDate, maxDate = undefined) {
    var selectedDate = null;

    var dateFormat = 'MM/dd/yyyy';
    var todayDate = new Date();
    var date = string;
   // var maxDate = todayDate.toLocaleString();
    var speicalCharRegex = /[^\w\s]/gi;
    var date_regex = /((^(10|12|0?[13578])([/])(3[01]|[12][0-9]|0?[1-9])([/])((1[8-9]\d{2})|([2-9]\d{3}))$)|(^(11|0?[469])([/])(30|[12][0-9]|0?[1-9])([/])((1[8-9]\d{2})|([2-9]\d{3}))$)|(^(0?2)([/])(2[0-8]|1[0-9]|0?[1-9])([/])((1[8-9]\d{2})|([2-9]\d{3}))$)|(^(0?2)([/])(29)([/])([2468][048]00)$)|(^(0?2)([/])(29)([/])([3579][26]00)$)|(^(0?2)([/])(29)([/])(1[89][0][48])$)|(^(0?2)([/])(29)([/])([2-9][0-9][0][48])$)|(^(0?2)([/])(29)([/])(1[89][2468][048])$)|(^(0?2)([/])(29)([/])([2-9][0-9][2468][048])$)|(^(0?2)([/])(29)([/])(1[89][13579][26])$)|(^(0?2)([/])(29)([/])([2-9][0-9][13579][26])$))/;
    var isValidDate = date_regex.test(date);
    if (prevSelectedDate != null) {
        todayDate = new Date(prevSelectedDate);
    }
    var dateArray = date.split('/');

    if (dateArray.length > 1) {

        if (dateArray.length == 2) {
            var currentMonth = parseInt(dateArray[0]);
            var currentDate = parseInt(dateArray[1]);
            var currentYear = todayDate.getFullYear();
        } else if (dateArray.length == 3) {
            var currentMonth = parseInt(dateArray[0]);
            var currentDate = parseInt(dateArray[1]);
            if (!isNaN(parseInt(dateArray[2]))) {
                var year = dateArray[2];
                if (year.length == 2) {
                    if (parseInt(year) < 30) {
                        var currentYear = 2000 + parseInt(year);
                    } else {
                        var currentYear = 1900 + parseInt(year);

                    }
                } else {
                    var currentYear = parseInt(year);
                }
            } else {
                var currentYear = todayDate.getFullYear();
            }
        }

        if (currentDate <= 31 && currentMonth <= 12 && currentYear >= 1800) {
            var noOfDays = daysInMonth(currentMonth, currentYear)
            if (currentDate <= noOfDays) {
                selectedDate = new Date(currentMonth + '/' + currentDate + '/' + currentYear); // chnaged here

                if (maxDate) {
                    if (new Date(selectedDate) > new Date()) {
                        selectedDate = '';
                    }
                }
                //  setDate();
                return selectedDate;
            } else {
                selectedDate = '';
                // setDate();
                return selectedDate;
            }
        } else {
            selectedDate = '';
            // setDate();
            return selectedDate;
        }
    }
   
    if (date.length == 6 && speicalCharRegex.test(date)) {

        var currentMonth = parseInt(date.substring(0, 1));
        var currentDate = parseInt(date.substring(2, 3));
        var year = date.substring(4, 6);
        if (parseInt(year) < 30) {
            var currentYear = 2000 + parseInt(year);
        } else {
            var currentYear = 1900 + parseInt(year);
        }
        if (currentDate <= 31 && currentMonth <= 12 && currentYear >= 1800) {
            var noOfDays = daysInMonth(currentMonth, currentYear)
            if (currentDate <= noOfDays) {
                selectedDate = new Date(currentMonth + '/' + currentDate + '/' + currentYear);
                // setDate();
                return;
            } else {
                selectedDate = '';

                return null;
            }
        } else {
            selectedDate = '';
            //  setDate();
            return null;
        }
    } 

    if (speicalCharRegex.test(date) && !isValidDate) {
        date = date.replace(speicalCharRegex, '');
    }

    if (isValidDate) {
        var dateValue = new Date(date);
        if (dateValue instanceof Date && !isNaN(dateValue.valueOf())) {
            selectedDate = new Date(date);
        } else {
            selectedDate = '';
        }
    } else if (date == "00") {
        todayDate = new Date();
        selectedDate = todayDate; // changed here
    } else if (date > 0 && date.length <= 2) {

        selectedDate = new Date(todayDate.setDate(date));
    } else if (date == 0 && date.length >= 3) {
        let fullYear = 2000;
        selectedDate = new Date(todayDate.setFullYear(fullYear)); // changed here
    } else if (date < 1800 && date.length == 4) {

        var currentMonth = parseInt(date.substring(0, 2));
        var currentDate = parseInt(date.substring(2, 4));
        var currentYear = todayDate.getFullYear();
        if (currentMonth <= 12 && currentMonth > 0 && currentDate > 0 && currentDate <= 31) {
            todayDate = new Date(todayDate.setMonth(currentMonth - 1));
            var noOfDays = daysInMonth(currentMonth, currentYear)
            if (currentDate <= noOfDays) {
                selectedDate = new Date(todayDate.setDate(currentDate)); // changed here
            } else {
                selectedDate = '';
            }
        } else if (currentMonth == 0 && currentDate > 0 && currentDate <= 31) {
            var noOfDays = daysInMonth(currentMonth, currentYear)
            if (currentDate <= noOfDays) {
                selectedDate = new Date(todayDate.setDate(currentDate)); // chnaged here
            } else {
                selectedDate = '';
            }
        } else {
            selectedDate = '';
        }
    } else if (date.length == 4 && date > 0) {
        //getDateFromString
        selectedDate = new Date(todayDate.setFullYear(date)); // changed here


    } else if (date.length == 6 && date > 0) {
        var currentMonth = parseInt(date.substring(0, 2));
        var currentDate = parseInt(date.substring(2, 4));
        var year = date.substring(4, 6);
        if (parseInt(year) < 30) {
            var currentYear = 2000 + parseInt(year);
        } else {
            var currentYear = 1900 + parseInt(year);
        }
        if (currentDate <= 31 && currentMonth <= 12 && currentYear >= 1800) {
            var noOfDays = daysInMonth(currentMonth, currentYear)
            if (currentDate <= noOfDays) {
                selectedDate = new Date(currentMonth + '/' + currentDate + '/' + currentYear);
            } else {
                selectedDate = '';
            }
        }
    } else if (date.length == 8 && date > 0) {
     
        var currentMonth = parseInt(date.substring(0, 2));
        var currentDate = parseInt(date.substring(2, 4));
        var currentYear = parseInt(date.substring(4, 8));
        if (currentDate <= 31 && currentMonth <= 12 && currentYear >= 1800) {
            var noOfDays = daysInMonth(currentMonth, currentYear)
            if (currentDate <= noOfDays) {
                selectedDate = new Date(currentMonth + "/" + currentDate + "/" + currentYear); //changed here
                
            } else {
                selectedDate = '';
            }
        } else {
            selectedDate = '';
        }
    } else {
        selectedDate = '';
    }
  
    return selectedDate;
}

function daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
}

export const is_selected_date_in_range = (providedMinRange, providedMaxRange, selectedDate) => {
    if (providedMinRange) { 
        providedMinRange = moment(providedMinRange); 

        if(selectedDate < providedMinRange) return false;
    }
    if (providedMaxRange) { 
        providedMaxRange = moment(providedMaxRange); 

        if(selectedDate > providedMaxRange) return false;
    }
    
    return true;
}



export default autoDatePicker;