import * as React from 'react';
import CheckboxComponent from './checkbox_component';
import { text_comparator } from './comparators';
import { GridFilterFrom } from './utils';

class CheckListFilter extends React.Component<any, any> {
    [x: string]: any;
    _is_mounted = false;
    //Get innitalise data when component created
    constructor(props) {
        super(props);
        this.state = {
            filterData: [],
            selectData: [],
            data: this.getRowData(),
            selectAll: false,
            shouldUpdateChild: true,
            clearAll: false
        };

        this.valueGetter = this.props.valueGetter;
    }

    //Filter status to active or inactive
    isFilterActive() {
        const { filterData } = this.state;

        return filterData !== null && filterData !== undefined && filterData.length > 0;
    }

    //Filter records
    doesFilterPass(params) {
        const nValue = this.valueGetter(params.node);
        if (nValue != null && nValue != undefined) {
            const nodeValue = nValue.toString().toLowerCase().trim();
            const { filterData } = this.state;
            return this.state.filterData.findIndex(value => value.toString().toLowerCase().trim() == nodeValue) >= 0;
        }
        return false;
    }

    // Get filter model
    getModel() {
        return { value: this.state.filterData };
    }

    // Set filter model
    setModel(model) {
        this.setState({ filterData: model ? model.value : [] })
    }

    // Do work after UI load
    afterGuiAttached(params) {
        this.focus();
    }

    focus() {
    }

    // close filter popup
    handleClose = (event) => {
        this.props.api.menuFactory.hidePopup();
    }

    // Checkbox filter row check/uncheck in the filter popup
    onCheckChange = (checked, item) => {
        let list = [];
        if (checked) {
            list = [...this.state.selectData, item];
        } else {
            list = this.state.selectData.filter(value => (value !== item));
        }
        if (this._is_mounted) {
            if (this.state.clearAll === true) {
                this.setState({ selectData: [...list], clearAll: false });
            } else {
                this.setState({ selectData: [...list] });
            }
        }
    }

    // Check all rilter checkbox in the popup
    onChangeSelectAll = (event) => {
        if (event.target.checked) {
            if (this.state.clearAll === true) {
                if (this._is_mounted) {
                    this.setState({
                        selectAll: true,
                        shouldUpdateChild: true,
                        clearAll: false,
                        selectData: [...this.state.data]
                    });
                }
            } else {
                if (this._is_mounted) {
                    this.setState({
                        selectAll: true,
                        shouldUpdateChild: true,
                        selectData: [...this.state.data]
                    });
                }
            }
        } else {
            if (this._is_mounted) {
                this.setState({
                    selectAll: false,
                    shouldUpdateChild: true,
                    selectData: []
                });
            }
        }
    }

    // Load column data into filter popup
    getRowData = () => {
        const { field } = this.props.colDef;
        let listItems = [];
        this.props.rowModel.rowsToDisplay.forEach((node) => {
            const value = this.props.valueGetter(node)
            if (value != null && value != undefined) {
                if (listItems.indexOf(value) === -1) {
                    listItems = [...listItems, value];
                }
            }
        });
        return listItems.sort(text_comparator);
    }

    //Filter button function call
    handleFilter = () => {
        const { selectData, data, selectAll } = this.state;
        //if (selectData.length !== data.length) {
        if (this._is_mounted) {
            this.setState({
                filterData: [...this.state.selectData]
            }, () => {
                this.props.filterChangedCallback();
                this.props.api.menuFactory.hidePopup();
            });
        }
    }

    // Clear button function call
    handleClear = () => {
        if (this._is_mounted) {
            this.setState({
                filterData: [],
                selectData: [],
                clearAll: true,
                shouldUpdateChild: true
            }, () => {
                this.props.filterChangedCallback();
                this.props.api.menuFactory.hidePopup();
            });
        }
    }

    //Component update function
    componentDidUpdate(previousProps, previousState) {
        if (previousState.selectData.length !== this.state.selectData.length) {
            const { selectData, data, selectAll } = this.state;
            const isAllChecked = selectData.length === data.length;
            if (isAllChecked !== selectAll) {
                if (this._is_mounted) {
                    this.setState({
                        selectAll: isAllChecked,
                        shouldUpdateChild: false
                    });
                }
            }
        }
    }
    componentDidMount() {
        this._is_mounted = true;
    }
    componentWillUnmount() {
        this._is_mounted = false;
    }

    valueOptionHandler = (result: { value: any; }, option: any) => {
        if (this._is_mounted) {
            this.setState({
                [`value${option}`]: result.value
            });
        }
    }

    inputHandler = (el: any, input2: string | number) => {
        return this[input2] = el
    }
   
    //Render function
    render() {
        return (
            <React.Fragment>
                <GridFilterFrom
                    options={this.state.data}
                    onChangeSelectAll={this.onChangeSelectAll}
                    selectAll={this.state.selectAll}
                    shouldUpdateChild={this.state.shouldUpdateChild}
                    clearAll={this.state.clearAll}
                    handleFilter={this.handleFilter}
                    handleClear={this.handleClear}
                    handleClose={this.handleClose}
                    valueOptionHandler={this.valueOptionHandler}
                    onCheckChange={this.onCheckChange}
                    inputHandler={this.inputHandler}
                    filterType ={'checklist'}
                />
            </React.Fragment>            
        );
    }
};

export default CheckListFilter;