import * as global_constants from './../../global_constants';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request'

export const get_procedure_code_replacements_payer = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.procedure_code_replacement_url.payer}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(requestConfig);
}

export const get_procedure_code_replacements_insurance_class = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.procedure_code_replacement_url.insurance_class}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(requestConfig);
}

export const get_procedure_code_replacements_criteria = (token, param) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.procedure_code_replacement_url.criteria}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'silversurfer': JSON.stringify(param)
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    });

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
}

export const get_procedure_code_replacement = (token) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.procedure_code_replacement_url.get_procedure_code_replacement}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(requestConfig);
}

export const validate_procedure_code_replacement = (token, procedure_code_replacement_data) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.procedure_code_replacement_url.validate}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': procedure_code_replacement_data
    })

    return request.make(requestConfig);
}

export const save_procedure_code_replacement = (token, procedure_code_replacement_data) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.procedure_code_replacement_url.save}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': procedure_code_replacement_data
    })

    return request.make(requestConfig);
} 