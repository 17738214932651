import axios, { AxiosRequestConfig } from 'axios';
import { toastr } from 'react-redux-toastr';
import * as local_storage from '../../shared/local_storage_utility';
import * as session_storage from '../../shared/session_storage_utility';
import { request_queue, build_version, constants } from './../../global_constants';
import { is_testing } from './../utility';
import { check_sso_user_mapping } from '../../sso/action/sso_action';
import { log_error } from '../action/shared_action';
import { _store_ } from '../../reducer_store';

class Deferred<T> {

    promise: Promise<T>;
    resolve: (value?: T | PromiseLike<T>) => void;
    reject: (reason?: any) => void;
    request_config: AxiosRequestConfig;

    constructor(request_config) {
        this.promise = new Promise<T>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
        this.request_config = request_config
    }
}

export class RequestManager {

    constructor() {}

    /**
     * purpose is to execute request now or maintain a queue for all those
     * request which we want to be resolved later. 
     * For all those request which is to be resolved later we are using Deferred Object.
     * If we want to execute request now then axios request is called.
     * 
     * @param config
     * @param make_queuable
     */
    process(config, make_queuable) {

        const request_config: AxiosRequestConfig = config;
        // create a deferred promise and return the deferred promise
        let deferred_promise = new Deferred(request_config)
        let promise = deferred_promise.promise

        if (local_storage.get("should_queue_request") == "true" &&  ! make_queuable) {
            request_queue.push(deferred_promise)
        } else {
            this.execute_request(deferred_promise);
        }

        return promise
    }

    // creating an axios request
    execute_request(promise, retry = 0) {
        let config = promise.request_config.get();

        //console.log('config', config);
        axios.get(window.location.origin + '/config/build_version.json?ver' + Math.random()).then((res) => {
            //console.log('normal', res.data);
            //console.log('JSON.parse',JSON.parse(res.data));
            var new_version = '';
            if (res.data) {
                new_version = `${res.data.environment.environment} ${res.data.environment.version}`;
            }
            if (build_version != new_version) {
                const toastrConfirmOptions = {
                    id: 'new-build-updated-pop',
                    okText: 'continue',
                    disableCancel: true,
                    onOk: () => {
                        //nevigate to login 
                        if (window.location.pathname && window.location.pathname.indexOf('login') >= 0) {
                            window.location.reload(true);
                        } else {
                            session_storage.set('hard_reload_application', true);
                            //history.go('/login');
                            window.location.pathname = '/logout';
                        }
                    }
                };
                toastr.confirm('An updated version of WebPT Billing is now available providing you with the latest security, features, improvements, and fixes, Please sign in again to continue.', toastrConfirmOptions);
                return;
            }

            let reqObj = axios.create();
            
            // here we are updating the Authorization token to make sure we use the latest token
            // token is updated for those which only has Authorization header
            var auth_data = local_storage.get("auth_data");
            if (auth_data != null && auth_data != undefined && auth_data.token_details != null
            ) {

                let token = auth_data.token_details.access_token;

                //updating the header token
                if (config.headers) {
                    config.headers.Authorization = 'Bearer ' + token;
                } else {
                    config['headers'] = { 'Authorization': 'Bearer ' + token}
                }
            } else {
                console.log('auth data not found in request execution', new Date(), config,'auth data==>', auth_data);
            }
            config.withCredentials= true;
            config.timeout = constants.request_timeout;
            

            reqObj(config).then(resp => {
                if (resp && resp.config.url.indexOf('/sso/check_user_mapping') > -1) {
                    if (resp && resp.data && resp.data.data) {
                        if (resp.data.data.result == false) {
                            //when user reset SSO from EMR,check SSO mapping, if no mapping exist
                            //logout user from billing if login by SSO
                            sessionStorage.removeItem('sso_user_login');
                            window.location.pathname = '/logout';
                        }
                    }
                }
                promise.resolve(resp)
            }, error => {                
                if (error.response && (error.response.status == 403|| error.response.status == 400 || error.response.status == 502)) {
                    if (!config.disable_retry && retry != 1) {
                        this.execute_request(promise, 1);
                        console.log("trying again for 403,400 and 502 at time => ", new Date(), error.config.url, auth_data);
                        return;
                    } else if (!config.disable_retry && retry == 1 && error.response.status == 403) {
                        //when reset SSO from EMR, check if SSO mapping if login by SSO
                        if (sessionStorage['sso_user_login']) {
                            if (auth_data && auth_data.user_id) {
                                check_sso_user_mapping(auth_data.user_id);
                            }
                        }
                    }
                    //else if (retry == 1 && error.response.status == 403) {
                    //    toastr.error('', 'Your Session is expired. You are not Authorized to access this functionality. Please re-login.');
                    //    window.location.pathname = '/logout';
                    //    return;
                    //}
                }
                else if(error.code == 'ECONNABORTED'){
                    toastr.error('', "Sorry, there was a system error while performing this operation. Please notify support if you continue to receive this error.");
                }
                else if (error.response && error.response.status == 404) {

                    _store_.dispatch({
                            type: constants.DISPLAY_404_PAGE,
                            payload: true
                        })                
                }
                else if (error.response && error.response.status == 503) {
                    if (!config.disable_retry && retry != 1) {
                        if (!is_testing()) {
                            console.log("trying again for 503 at time => ", new Date(), auth_data);
                        }
                        this.execute_request(promise, 1);
                        return;
                    } else {
                        toastr.error('', "Server is down");
                    }
                }
                else if (error.config.url.indexOf("v1/log_error") < 0 && error.message.indexOf("Network Error") >= 0) {

                    if (!config.disable_retry && retry != 1) {
                        if (!is_testing()) {
                            console.log("trying again for Network Error at time => ", new Date(), auth_data);
                        }
                        this.execute_request(promise, 1);
                        return;
                    } else {
                        toastr.error('', "Server is down");
                    }
                }

                if (!is_testing()) {

                    console.log("Request Error details at time => ", new Date(), error, 'Current Url', error.config.url);
                }
                if(error.config.url.indexOf("v1/log_error") < 0 ){
                    log_error(error);
                }
                promise.reject(error)
            })
        });
    }
}