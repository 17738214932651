﻿import * as React from 'react';
import PatientSearchModalComponent from '../../shared/component/patient_search_modal_component';

export class PatientSearchStripeComponent extends React.Component<any, any> {
    [x: string]: any;
    constructor(props) {
        super(props);       
        this.patient_quick_search_strip = React.createRef();
    }

    update_header = ()=> {
        if (this.patient_quick_search_strip && this.patient_quick_search_strip.update_quick_search) {
            this.patient_quick_search_strip.update_quick_search();
        }
    }

    render() {       
        return (
            <div className="fixedhead">
                <div className="patientstrip" id="applicationPatientHeaderSticky">
                    <div className=" margin-zero fixed-shadow">
                        <div className="width_75">
                            <p className="name patient_header_strip_parent_div">
                                <PatientSearchModalComponent onRef={instance => { this.patient_quick_search_strip = instance; }}  />
                            </p><div className="patient_header_strip" onClick={this.update_header} >Search for patient...</div></div>
                      
                    </div>
                </div>
            </div>
        );
    }
}
export default PatientSearchStripeComponent