import * as moment from 'moment';
import { isMoment } from 'moment';
import * as React from "react";
import { connect } from "react-redux";
import { toastr } from 'react-redux-toastr';
import { Button, Dimmer, Form, Grid, Header, Loader, Modal, Table } from "semantic-ui-react";
import { get_demand_statement_report, print_demand_statement } from '../../reports/action/report_action';
import DemandStatementReportGrid from '../../reports/component/demand_statement_grid_component';
import * as local_storage from '../../shared/local_storage_utility';
import * as session_storage from '../../shared/session_storage_utility';
import * as report_constants from "../report_constants";
import { handle_click_on_enter, set_focus_to_app_header, handle_navigation_on_modal, handle_focus_on_tab, set_focus_on_element_with_id, applyFocusOnDateRangePicker } from './../../shared/tab_navigation_utility';
import { setTimeout } from 'timers';
import DateRangePickerComponent from './../../shared/component/date_range_component';
import { get_lauch_darkley_key_value as get_launch_darkly_key_value, is_null_or_white_space } from '../../shared/utility';
import { OnlinePaymentSupportFlag } from '../../company/company_constants';




class DemandStatementReport extends React.Component<any, any> {
    _is_mounted = false;
    printRef: DemandStatementReportGrid;
    constructor(props) {
        super(props);
        this.state = {
            patient_id: session_storage.get("active_patient"),
            loading: false,
            show: true,
            start_date: moment("01/01/1975", "MM/DD/YYYY"),
            end_date: moment(new Date(), "MM/DD/YYYY"),
            show_zero_balance: false,
            error: {
                date: false,
                range: false,
            },
            start_print: false,
            grid_data: null,
            confirm_box_open: false,
            show_record: false,
            print_confirm: true,
            chevron_icon: 'chevron down',
            icon_down: true,
            visa_checked: false,
            master_checked: false,
            is_submitted : false,
            is_search_button_disabled: false,
            auth_data: local_storage.get("auth_data")         
        };
    }

    componentWillUnmount = () => {
        this._is_mounted = false;
        document.body.classList.remove('demand_statement', 'patient-header', 'patient-subHeader');
    }
    componentDidMount() {
        this._is_mounted = true;
        document.body.classList.add('demand_statement');
        // setting the focus on DateRange
        applyFocusOnDateRangePicker()
        //apply_focus_on_first_datepicker_of_current_page();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.report_data != this.props.report_data) {
            var state = { ...this.state };
            state.loading = false;
            state.is_search_button_disabled = false;
            state.show_record = !this.state.show_record;
            if (this.props.report_data.data.status) {
                state.grid_data = this.props.report_data.data;
                state.show = false;
            } else {
                toastr.error('', this.props.report_data.data.message);
            }
            if (this._is_mounted) {
                this.setState(state);
            }
        }
    }
    // toggle for showing filter section
    toggleHandler = (e) => {
        e.preventDefault();
        if (this._is_mounted) {
            this.setState({
                show: !this.state.show,
                icon_down: !this.state.icon_down
            });
        }
    }

    // validate the form with values
    validateForm = () => {
        const error = { ...this.state.error };
        error.date = (this.state.start_date === null ? true : false) || this.state.end_date === null ? true : false;
        error.range = (error.date) ? false : !moment(this.state.end_date).isSameOrAfter(this.state.start_date);
        if (JSON.stringify(error) !== JSON.stringify(this.state.error)) {
            if (this._is_mounted) {
                this.setState({ error: error });
            }
        }

        if (error.date || error.range) {
            const msg = error.date ? report_constants.messages.mandatory_fields : error.range && report_constants.messages.date_validation;
            toastr.error('', msg);
            return false;
        }

        return true;
    }


    /// handle the submit form 
    handleSubmit(e) {
        e.preventDefault();
        var is_loading = false;
        var is_search_button_disabled = false;
        if (this.validateForm()) {
            is_loading = true;
            is_search_button_disabled = true;
            if (this.state.print_confirm) {
                const param = {
                    patient_id: this.state.patient_id,
                    start_date: moment(this.state.start_date).format('MM-DD-YYYY'),
                    end_date: moment(this.state.end_date).format('MM-DD-YYYY'),
                    is_active: this.state.show_zero_balance
                };
                const token = local_storage.get("auth_data").token_details.access_token;             
                this.props.get_report_data(param, token); // action dispatched for getting filter data
            }
        }
        if (this._is_mounted) {
            this.setState({ loading: is_loading, is_submitted: true, is_search_button_disabled: is_search_button_disabled });
        }
    }
    clearForm = () => {
        if (this._is_mounted) {
            this.setState(prevState => ({
                start_date: null,
                end_date: null,
                show_zero_balance: false,
                error: { ...prevState.error, date: false },
                grid_data: null,
                is_submitted: false
            }));
        }
    }


    formatPhone = (obj) => {
        obj = obj ? obj : '';
        let numbers = obj.replace(/\D/g, '')
        let char = { 0: '(', 3: ') ', 6: ' - ' };
        obj = '';
        for (var i = 0; i < numbers.length; i++) {
            obj += (char[i] || '') + numbers[i];
        }
        return obj;
    }
    handleClear(e) {
        e.preventDefault();
        this.clearForm();
    }


    // print handler call on click print button
    printHandler = () => {
        if (this._is_mounted) {
            this.setState({ start_print: !this.state.start_print, confirm_box_open: false });
        }
        var param = {
            patientId: this.state.patient_id,
            fromDate: moment(this.state.start_date).format('MM-DD-YYYY'),
            thruDate: moment(this.state.end_date).format('MM-DD-YYYY'),
            isZeroBalance: this.state.show_zero_balance
        }
        const token = local_storage.get("auth_data").token_details.access_token;
        const response = print_demand_statement(param, token);
        this.printRef.printHandler();
    }

    // confirm popup handler
    handleCancel = () => {
        if (this._is_mounted) {
            this.setState({ confirm_box_open: false, start_print: !this.state.start_print, print_confirm: false });
        }
        this.printRef.printHandler();
    }

    master_visa_handler = (type) => {
        let card_type =  type == 'visa' ? 'visa_checked' : 'master_checked';
        let value = type == 'visa' ? !this.state.visa_checked : !this.state.master_checked;
        if (this._is_mounted) {
            this.setState({ [card_type]: value });
        }
    }
    // close popup 
    handleClose = () => {
        if (this._is_mounted) {
            this.setState({ confirm_box_open: false });
            set_focus_on_element_with_id("printButton");
        }
    }

    handleDateChange = (startDate, endDate) => {
        this.setState(prevState => ({
            start_date: startDate, end_date: endDate, error: {
                ...prevState.error, date: (isMoment(startDate) ? false : true) || (isMoment(endDate) ? false : true)
                }
        }))
    }

    render_pay_your_bill_url_link = () => {          
        if (this.props.goliath_bill1938_online_payment_support && !is_null_or_white_space(this.state.auth_data.online_Patient_payment_Url)) {
            return (<React.Fragment>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell colSpan="3">
                            <h4><b>Pay Your Bill Online</b></h4>
                            <p>{this.state.auth_data.online_Patient_payment_Url}</p>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
            </React.Fragment>)
        }
        return null;
    }

    render() {
        const form = this.state;
        const report = this.state.grid_data && this.state.grid_data.data;
        const company_detail = report && report.Company && report.Company[0]
        const style = {
            'display': this.state.show ? 'block' : 'none',
        }

        return (
            <div className="common-forms-search">
                <Dimmer active={this.state.loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                {this.state.confirm_box_open && (
                <div className="item">
                    <Modal
                        onClose={this.handleClose}
                        centered={false}
                        className="demand_statement_confirm default-modal"
                        open={this.state.confirm_box_open}
                        closeIcon
                        id={"printPopup"}
                        closeOnDimmerClick={false}
                    >
                        <Modal.Header>Demand Statement</Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                <Grid>
                                    <Grid.Column>
                                        <p>Will this statement be provided to the patient?</p>
                                    </Grid.Column>
                                </Grid>
                                <Grid>
                                    <Grid.Column computer={16} textAlign="right">
                                        <Button type="reset" id="no_button_print_modal" basic onClick={this.handleCancel}>No</Button>
                                        <Button type='submit' onKeyDown={(event) => {
                                                handle_focus_on_tab(event, "print_modal_close_icon_id");
                                            }
                                        } id="yes_button_print_modal" onClick={this.printHandler} primary>Yes</Button>
                                    </Grid.Column>
                                </Grid>
                            </Modal.Description>
                        </Modal.Content>
                    </Modal>
                </div>
                )}

                <Grid>
                    <Grid.Column computer={16}>
                        <Header as='h3' textAlign="left">Demand Statement</Header>
                    </Grid.Column>
                </Grid>
                <div className="auto-height-scroll demand_statement_wraper">
                    <Form className="patient-search-form" autoComplete="off" style={{ border: '0' }}>
                        <Grid>
                            <Grid.Column computer={16}>
                                <div className="accordion ui fluid styled common-accordion" >
                                    <div className="active title" onClick={e => { this.toggleHandler(e) }}>Search Criteria<i aria-hidden="true" tabIndex={0} onKeyDown={handle_click_on_enter} className={this.state.icon_down ? 'chevron down icon' : 'chevron up icon'}></i></div>
                                    <div className="content active" style={style}>
                                        <div className="ui grid">

                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label>Date Range {<span className={form.error.date ? "req-alert" : "req-alert_normal"}> (required)</span>}
                                                    </label>
                                                    <DateRangePickerComponent
                                                        updateDatesChange={this.handleDateChange}
                                                        startDate={form.start_date}
                                                        endDate={form.end_date}
                                                        error={form.error.date || form.error.range}
                                                        startYear={1975}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column tablet={10} computer={5} className="ledgerfull-show-inactive-checkbox">
                                                <label> </label>
                                                <div className="ui checkbox"><input type="checkbox" checked={this.state.show_zero_balance} onChange={val => this.setState({ show_zero_balance: !this.state.show_zero_balance })} /><label>Show Zero Balance Charges</label></div>
                                            </Grid.Column>
                                        </div>
                                        <Grid style={{ margin: '0 -17px' }}>
                                            <Grid.Column computer={16} textAlign="right">
                                                <Button type="reset" basic onClick={e => this.handleClear(e)}>Clear</Button>
                                                <Button type='submit' onClick={e => this.handleSubmit(e)} primary disabled={this.state.is_search_button_disabled}>Search</Button>
                                            </Grid.Column>
                                        </Grid>

                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid>
                        {report &&
                            <Grid className="check-pay-container" style={{ paddingBottom: 20 }}>                               
                                <Grid.Column id="company_info" tablet={16} computer={8}>
                                    <div className="payable-section" style={{height: '100%',marginBottom: 0}}>
                                        <h4><b>Make Check Payable to</b></h4>
                                        {company_detail && (
                                            <React.Fragment>
                                                <p>{company_detail.company_name}</p>
                                                <p>{company_detail.address1} {company_detail.address2}</p>
                                                <p>{company_detail.city ? company_detail.city + ', ':''}{company_detail.state} {company_detail.zip}</p>
                                                <br />
                                                <p><b>Billing Questions-</b> {this.formatPhone(company_detail.statement_phone)}</p>
                                                <p><b>Account</b> {company_detail.companyCode} {this.state.patient_id}</p>
                                                <br />
                                                <p>{company_detail.billing_first_name} {company_detail.billing_last_name}</p>
                                                <p style={{fontStyle: 'italic'}}>({company_detail.patient_first_name} {company_detail.patient_last_name})</p>
                                                <p>{company_detail.billing_address1}</p>
                                                {company_detail.billing_address2 && <p>{company_detail.billing_address2}</p>}
                                                <p>
                                                    {company_detail.billing_city ? company_detail.billing_city + ', ' : ''}{company_detail.billing_state}{' '}
                                                    {company_detail.billing_zip && company_detail.billing_zip.length > 5
                                                        ? company_detail.billing_zip.slice(0, 5)
                                                        : company_detail.billing_zip}
                                                </p>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </Grid.Column>
                                <Grid.Column id="check_info" tablet={16} computer={8}>
                                    {report_constants.display_credit_card_card_type.indexOf(company_detail.patient_statement_type) > -1 && <React.Fragment><div className="check-pay-print">                                
                                </div>
                                    <div>
                                        <Table celled className="default-table">                                          
                                                {this.render_pay_your_bill_url_link()}
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell colSpan="3">
                                                        <h4><b>If Paying by Credit Card, Fill out Below</b></h4>
                                                        <span>
                                                            <div className="ui checkbox"><input id="visa_checkbox_button" type="checkbox" onChange={e => { this.master_visa_handler('visa') }} defaultChecked={this.state.visa_checked} /><label>Visa <img src="/src/assets/images/VisaIcon.png" /></label>
                                                            </div>
                                                        </span>
                                                        &nbsp;&nbsp;&nbsp;
                                                        <span>
                                                            <div className="ui checkbox"><input type="checkbox" onChange={e => { this.master_visa_handler('master') }} defaultChecked={this.state.master_checked} /><label>Master Card <img src="/src/assets/images/MasterIcon.png" /></label></div>
                                                        </span>
                                                    </Table.HeaderCell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.HeaderCell>Card Number<br></br>&nbsp;</Table.HeaderCell>
                                                    <Table.HeaderCell>Amount<br></br>&nbsp;</Table.HeaderCell>
                                                    <Table.HeaderCell>Expiration Date<br></br>&nbsp;</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell colSpan="3">Signature</Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                    </div> 

                                    <br></br></React.Fragment>}
                                    <div>
                                        <Table celled className="default-table">
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell><b>Date</b></Table.HeaderCell>
                                                    <Table.HeaderCell><b>Pay This Amount</b></Table.HeaderCell>
                                                    <Table.HeaderCell><b>Account#</b></Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell>{moment(new Date()).format('MM/DD/YYYY')}</Table.Cell>
                                                    <Table.Cell textAlign="right">{report.PatientBalanceAmount < 0 ? '(' + report_constants.currencyFormatter(report.PatientBalanceAmount * (-1)) + ')' : report_constants.currencyFormatter(report.PatientBalanceAmount)}</Table.Cell>
                                                    <Table.Cell textAlign="right">{company_detail.companyCode}{this.state.patient_id}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell colSpan="2">Show Amount Paid Here</Table.Cell>
                                                    <Table.Cell>$</Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                    </div>                                     
                                    {(!this.props.goliath_bill1938_online_payment_support || is_null_or_white_space(this.state.auth_data.online_Patient_payment_Url)) && <React.Fragment>
                                        <br></br>
                                        <p>{company_detail.companyName} </p>
                                        <p>{company_detail.address1} {company_detail.address2}</p>
                                        <p>{company_detail.city ? company_detail.city + ', ' : ''}{company_detail.state} {company_detail.zip}</p>
                                        <br></br></React.Fragment>}
                                </Grid.Column>
                            </Grid>
                        }
                    </Form>
                    {/* auto adjusting width of columns on search */}
                    {report && (
                        <React.Fragment>
                            {!this.state.loading &&
                                <DemandStatementReportGrid ref={instance => { this.printRef = instance; }} rows={report} print={this.state.start_print} />
                            }
                            <br></br>
                            <Grid id="footer_patient_info" className="check-pay-container">
                                <Grid.Column tablet={16} computer={8}>
                                    <p>Patient Name : {company_detail.patientFirstName}{' '}{company_detail.patientLastName}</p>
                                    <p>Billing Questions: {report.Company && this.formatPhone(company_detail.statement_phone)}</p>
                                    <p>Please Remit: {company_detail.statementName}</p>
                                    <br></br>
                                </Grid.Column>
                                <Grid.Column tablet={16} computer={8}>
                                    <Table celled className="default-table">
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell><b>Total Balance</b></Table.HeaderCell>
                                                <Table.HeaderCell><b>Claim in Process</b></Table.HeaderCell>
                                                <Table.HeaderCell><b>Pay This Amount</b></Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell textAlign="right">{report.TotalAmount < 0 ? '(' + report_constants.currencyFormatter(report.TotalAmount * (-1)) + ')' : report_constants.currencyFormatter(report.TotalAmount)}</Table.Cell>
                                                <Table.Cell textAlign="right">{report.CliamAmount < 0 ? '(' + report_constants.currencyFormatter(report.CliamAmount * (-1)) + ')' : report_constants.currencyFormatter(report.CliamAmount)}</Table.Cell>
                                                <Table.Cell textAlign="right">{report.PatientBalanceAmount < 0 ? '(' + report_constants.currencyFormatter(report.PatientBalanceAmount * (-1)) + ')' : report_constants.currencyFormatter(report.PatientBalanceAmount)}</Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                    <br></br>
                                </Grid.Column>

                                <p className="onprint-divider">(This statement may only contain payments received through {moment(new Date()).format('MM/DD/YYYY').toString()}.)<br></br></p>
                            </Grid>
                        </React.Fragment>
                    )}

                </div>
                <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area" id="applicationFooterSticky">
                    {report && <Button
                    onKeyDown={set_focus_to_app_header}
                    type="submit"
                    id="printButton"
                    className="ui primary button"
                    role="button"
                    onClick={() => {
                        this.setState({ confirm_box_open: true })
                        handle_navigation_on_modal("print_modal_close_icon_id", "yes_button_print_modal");
                        setTimeout(() => {
                            let no_button_print_modal = document.getElementById("no_button_print_modal");
                            no_button_print_modal.focus();
                            
                        }, 500)
                    }}
                    >Print</Button>}
                </div>
            </div>

        );
    }

}

const mapDispatchToProps = dispatch => {
    return {
        get_report_data: (data, token) => dispatch(get_demand_statement_report(data, token))
    };
};

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
        report_data: state.report_details,
        goliath_bill1938_online_payment_support: get_launch_darkly_key_value(state.launch_darkly, OnlinePaymentSupportFlag.goliath_bill1938_online_payment_support),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DemandStatementReport);