import * as React from 'react';
import { Button, Form, Grid, Modal } from 'semantic-ui-react';

class DisplayExtraRecordsWarning extends React.Component<any, any> {
    [x: string]: any;

    constructor(props) {
        super(props);
        this.state = {
            show_modal: this.props.show_modal,
            button_text: this.props.button_text || 'Continue',
            title: this.props.title || 'Max Record Alert'
        };
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.show_modal !== this.props.show_modal) {
            this.setState({ show_modal: this.props.show_modal })
        }
    }
    
    on_button_click = () => {
        this.setState({ show_modal: false });
    }

    render() {
        const { show_modal, button_text, title } = this.state;
        let msg = this.props.message || 'The limit of 5000 records will be returned.<br/>' + this.props.remaining_records + ' records matching these search criteria will <b>not</b> be displayed.<br/> <br/> Updates will only affect displayed records.' 

        return (
            <div className="item">
                <Modal
                    centered={false}
                    className="searchCompany"
                    open={show_modal}
                    id={'extraRecordsWarningPopup'}
                    closeIcon={false}
                    closeOnDimmerClick={false}
                >
                    <Modal.Header>{title}</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Grid>
                                <Grid.Column>
                                    <Grid>
                                        <Grid.Column tablet={16} computer={16}>
                                            <Form.Field>
                                                <label dangerouslySetInnerHTML={{ __html: msg }} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={16} computer={16} align="right">
                                            <Button type='cancel' id='btn_continue' autoFocus={true} primary onClick={this.on_button_click}>{button_text}</Button>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                            </Grid>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

export default DisplayExtraRecordsWarning