import * as moment from 'moment';
import * as React from 'react';
import * as NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { YearInput } from 'semantic-ui-calendar-react';
import { Button, Checkbox, Dimmer, Grid, Loader, Table } from 'semantic-ui-react';
import * as global_constants from '../../global_constants';
import Selection from '../../shared/component/selection_component';
import yearAutoSelection from "../../shared/component/year_picker_auto";
import { custom_date_format, format_patient_medicare_cap_speciality, detect_ie, is_mobile } from '../../shared/utility';
import { add_update_patient_medicare_cap, get_patient_medicare_cap } from '../action/patient_action';
import { patient_messages } from '../patient_constants';
import { handle_click_on_enter } from '../../shared/tab_navigation_utility';
import CurrencyFormatter from '../../shared/component/currency_formatter';

export class MedicareCapComponent extends React.Component<any, any> {
    [x: string]: any;
    _isMounted = false;
    constructor(props) {
        super(props);
       
        //TODO: remove when year picker implemented
        var year_list: any = [];
        var current_year = moment().year();
        for (var i = (current_year - 20); i < (current_year + 50); i++) {
            year_list.push({
                "text": i,
                "value": i,
                "key": i.toString()
            })
        };

        this.state = {
            master_data: this.props.user_login_details.master_data.data,
            patient_master_data: this.props.user_login_details.formated_master_data,
            shown: true,
            medicare_cap_data: [],
            check_all: false,
            medicare_cap_current_year: "",
            loading: true
        }
        //this.handle_year_change = this.handle_year_change.bind(this);
        this.handleChange = this.handleChange.bind(this)
        this.yearPickerReferences = [];        
    }
    
    allInstances : any = {};

    componentDidMount() {
        this._isMounted = true;
        this.get_medicare_cap();
        if (this.props.onRef) {
            this.props.onRef(this);
        }

        let scrollableElement = document.getElementById("scrollable-content-area");
         scrollableElement.addEventListener('scroll', this.trackScrolling);

         // attaching window listener on scroll event for mobile devices
         if(is_mobile()) {
            window.addEventListener('scroll', this.listenWindowScrollingEvent);
         }
    }
    componentWillUnmount() {
        this._isMounted = false;
        if (this.props.onRef) {
            this.props.onRef(undefined)
        }
        let scrollableElement = document.getElementById("scrollable-content-area");
        scrollableElement.removeEventListener('scroll', this.trackScrolling);

        // removing window listener on scroll event for mobile devices
        if(is_mobile()) {
            window.removeEventListener('scroll', this.listenWindowScrollingEvent);
        }
    }
    componentDidUpdate(previousProps, previousState) {
        // If the patient changes, get new medicare cap data
        if (previousProps.patient_id != this.props.patient_id) {
            this.get_medicare_cap();
            if (this._isMounted) {
                this.setState({ shown: true });
            }
        }
        if (this.props.location.pathname != previousProps.location.pathname && this.props.location.pathname == "/patient_registration") {
            if (this._isMounted) {
                this.setState({
                    shown: true,
                    medicare_cap_data: [],
                    check_all: false
                });
            }
        }
        if (previousProps.shown != this.props.shown) {
            if (this._isMounted) {
                this.setState({ shown: false }); // show on change props
            }
        }
    }
    trackScrolling = (e) => {        
        this.yearPickerReferences.map(function(instance, index, instances) {
            if (instance && instance.current && instance.current.closePopup) {
                instance.current.closePopup();
            }
        })

    };
    listenWindowScrollingEvent = (e) => {
        /**
         * Preventing Event bubbing for window scroll events, 
         * as we have handled scroll event by ourself, which is handled
         * by trackScrolling listener.
         * 
         * This is done to prevent medicare cap to close for mobile devices.
         */
        if(e.preventDefault) {
            e.preventDefault();   
        }

        if(e.stopImmediatePropagation) {
            e.stopImmediatePropagation();
        }
    }
    // Get Medicare cap for the selected patient
    get_medicare_cap = async () => {
        if (this.props.patient_id) {
            if (!(isNaN(Number(this.props.patient_id)) || Number(this.props.patient_id) >= global_constants.maximum_value.integer)) {
                await this.props.get_patient_medicare_cap(this.props.user_login_details.user_data.data.token_details.access_token, this.props.patient_id)

                // If medicare cap data exists, set state object of medicare cap
                if (this.props.patient_details.selected_patient_medicare_cap && this.props.patient_details.selected_patient_medicare_cap.data && this.props.patient_details.selected_patient_medicare_cap.data.length > 0) {
                    if (this._isMounted) {
                        this.setState({
                            medicare_cap_data: this.props.patient_details.selected_patient_medicare_cap.data,
                            loading: false
                        });
                    }
                }
                else {
                    if (this._isMounted) {
                        this.setState({
                            medicare_cap_data: [],
                            loading: false
                        });
                    }
                }
            }
        } else {
            if (this._isMounted) {
                this.setState({
                    // medicare_cap_data: [],
                    loading: false
                });
            }
        }
    }

    check_invalid_data = () => {
        if (this.state.medicare_cap_data.length > 0) {
            var new_in_active_row = this.state.medicare_cap_data.filter(function (row, index) {
                return ((row.id == 0 && row.is_active == true) || (row.id > 0));
            });

            if (new_in_active_row && new_in_active_row.length > 0) {
                var check_invalid_old_data = new_in_active_row.filter(function (row, index) {
                    return row.id > 0 && row.is_active == true && (row.amount == '' || Number(row.amount) == 0 || row.year == '' || row.specialty_id == '');
                })[0];

                if (check_invalid_old_data) {
                    return true;
                }
                var check_invalid_new_data = new_in_active_row.filter(function (row, index) {
                    return (row.id == 0 && (row.amount == '' || Number(row.amount) == 0) && (row.year != '' || row.specialty_id != ''))
                        || (row.id == 0 && row.year == '' && (row.amount != '' && Number(row.amount) != 0  || row.specialty_id != ''))
                        || (row.id == 0 && row.specialty_id == '' && (row.amount != '' && Number(row.amount) != 0 || row.year != ''));
                })[0];
                if (check_invalid_new_data) {
                    return true;
                }
            }
        }
        return false;
    }

    check_duplicate_data = () => {
        var medicare_duplicate_count = 0;
        if (this.state.medicare_cap_data != null && this.state.medicare_cap_data.length > 0) {
            for (var i = 0; i < this.state.medicare_cap_data.length; i++) {
                if (this.state.medicare_cap_data.length > 1 && this.state.medicare_cap_data[i].is_active) {
                    for (var x = i + 1; x < this.state.medicare_cap_data.length; x++) {
                        if (this.state.medicare_cap_data[x].is_active &&
                            moment(this.state.medicare_cap_data[i].year).year().toString() == moment(this.state.medicare_cap_data[x].year).year().toString()
                            && this.state.medicare_cap_data[i].specialty_id == this.state.medicare_cap_data[x].specialty_id) {
                            medicare_duplicate_count++;
                        }
                    }
                }
            }
        }
        return medicare_duplicate_count;
    }


    add_update_medicare_cap = async (patient_id) => {
        var data = this.state.medicare_cap_data.length;
        if (data > 0 && patient_id) {
            
            var new_in_active_row = this.state.medicare_cap_data.filter(function (row, index) {
                return ((row.id == 0 && row.is_active == true) || (row.id > 0));
            });
            if (new_in_active_row && new_in_active_row.length > 0) {
                var updated_data = new_in_active_row.filter(function (row, index) {
                    return ((row.amount != '' && row.year != '' && row.specialty_id != '' && row.id == 0 && row.is_active == true)
                        || (row.id > 0 && row.amount != '' && row.year != '' && row.specialty_id != ''));
                });

                var updated_data_length = updated_data.length;

                if (updated_data && updated_data_length > 0) {

                    for (let f = 0; f < updated_data_length; f++) {
                        let row = updated_data[f];
                        if (row.id > 0 && !row.amount && !row.year && !row.specialty_id) { row.is_active = false }
                    }

                    for (let f = 0; f < updated_data_length; f++) {
                        let row = updated_data[f];
                        //if (row.year) { row.year = custom_date_format(moment(row.year).format("YYYY/MM/DD"), global_constants.date_format["mm/dd/yyyy h:MM:ss TT"]) } 
                        if (row.year) {
                            if (row.year.length == 4) {
                                row.year = moment([Number(row.year)]).format("MM/DD/YYYY");
                            }
                            row.year = new Date(custom_date_format(row.year, global_constants.date_format["mm/dd/yyyy h:MM:ss"])).toISOString();
                        }

                    }

                    await this.props.add_update_patient_medicare_cap(this.props.user_login_details.user_data.data.token_details.access_token, patient_id, updated_data);
                    return true;
                }
            }
        }
        return false;
    }


    // toggle to show and hide Advance Search
    private toggle = () => {
        if (this._isMounted) {
            this.setState({
                shown: !this.state.shown
            });
        }
    }

    on_add_click(): any {

        var old_data = this.state.medicare_cap_data;
        var seq = old_data.length;
        var new_row = {
            amount: '',
            company_id: 438,
            id: 0,
            is_active: true,
            patient_id: this.props.patient_id,
            sequence: (seq == null || seq == undefined ? 1 : seq + 1),
            specialty_id: '',
            year: "",
            is_selected: false
        }
        old_data.push(new_row);
        if (this._isMounted) {
            this.setState({
                medicare_cap_data: old_data,
                check_all: false
            });
        }
    }

    /*amount_change = (value, current_row, index) => {
        var data = this.state.medicare_cap_data;
        var amount_data = Number(value.value.replace("$", '').replace(/,/g, ''));
        data[index].amount = amount_data > 0 ? amount_data : "";
        if (this._isMounted) {
            this.setState({
                medicare_cap_data: data
            });
        }
    }*/
    amount_change = (value, name, index) => {
        let { medicare_cap_data } = this.state
        medicare_cap_data[index].amount = value;
        if (this._isMounted) {
            this.setState({
                medicare_cap_data
            });
        }
    }

    speciality_change = (value, row, index) => {
        var data = this.state.medicare_cap_data;
        data[index].specialty_id = Number(value);
        if (this._isMounted) {
            this.setState({
                medicare_cap_data: data
            });
        }
    }

    isYearInRange = (value) => {
        return Number(value) >= global_constants.DATE_RANGE_MEDICARE_CAP.YEAR_MIN 
        && Number(value) <= global_constants.DATE_RANGE_MEDICARE_CAP.YEAR_MAX;
    }

    // on Handler blur  fired get auto suggested value
    handleChange_blur = (e, value, index) => {
       
        var data = [...this.state.medicare_cap_data];
        
        value = yearAutoSelection(value, null);

        if (value !="" && moment([Number(value)]).isValid() && this.isYearInRange(value) ) {
            data[index].year = moment([Number(value)]).year().toString();
        } else {
            data[index].year = null;
        }

        if (this._isMounted) {
            this.setState({ medicare_cap_data: data });
        }
    }

    // on handle change on select date from year picker
    handleChange = (event, value, index, row) => {
        event.persist()
        event.stopPropagation()
        var data = [...this.state.medicare_cap_data];
        if (typeof index != 'undefined' && typeof row != 'undefined') {

            if (value && Number(value) != 0) {
                if (moment([Number(value)]).isValid()) {
                    data[index].year = moment([Number(value)]).year().toString();
                }
                //this.setState({ medicare_cap_data: data });
            } else {
                data[index].year = value;
            }
            if (this._isMounted) {
                this.setState({ medicare_cap_data: data });
            }
        }
    }

    // on handle change on select date from year picker
    handleChangeOnEnter = (value, index, row) => {
       
        var data = [...this.state.medicare_cap_data];
        if (typeof index != 'undefined' && typeof row != 'undefined') {

            if (value && Number(value) != 0) {
                if (moment([Number(value)]).isValid()) {
                    data[index].year = moment([Number(value)]).year().toString();
                }
                //this.setState({ medicare_cap_data: data });
            } else {
                data[index].year = value;
            }
            if (this._isMounted) {
                this.setState({ medicare_cap_data: data });
            }
        }
    }

    check_medicare_cap_row = (e, value, rowData, index) => {
        var data = this.state.medicare_cap_data;
        data[index].is_selected = value.checked;
        var allrowChecked = data.filter(function (row, index) {
            return (row.is_selected == false || !row.is_selected);
        });
        var allChecked = false;
        if (allrowChecked.length == 0) {
            allChecked = true;
        } else {
            allChecked = false;
        }
        if (this._isMounted) {
            this.setState({
                medicare_cap_data: data,
                check_all: allChecked
            });
        }
    }

    check_all_medicare_data = (e, value) => {
        var data = this.state.medicare_cap_data;
        var data_length = data.length;
        if (data_length > 0) {

            for (let i = 0; i < data_length; i++) {
                var row = data[i];
                row.is_selected = value.checked
            }
            if (this._isMounted) {
                this.setState({
                    medicare_cap_data: data,
                    check_all: value.checked
                });
            }
        }
    }

    // Returns new GUID
    get_new_guid = function () {
        return (this.create_guid() + this.create_guid() + "-" + this.create_guid() + "-4" + this.create_guid().substr(0, 3) + "-" + this.create_guid() + "-" + this.create_guid() + this.create_guid() + this.create_guid()).toLowerCase();
    };

    // Function used to create GUID. 
    create_guid = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };

    remove_medicare_cap = () => {
        var data = this.state.medicare_cap_data;
        //data.map(function (row, index) {
        //    if (row.is_selected == false) {
        //        row.is_active = false;
        //    }
        //});

        var selected_date_for_remove = data.filter(function (row, index) {
            return row.is_selected == true
        })[0];
        if (selected_date_for_remove) {
            for (let row of data) {
                if (row.is_selected == true) { row.is_active = false }
            }
            if (this._isMounted) {
                this.setState({
                    medicare_cap_data: data,
                    check_all: false
                });
            }
        }
        else {
            toastr.warning('', patient_messages.medicare_checkbox_select);
        }
    }

    check_invalid_row = (row, field_name) => {
        if (!row.year && !row.amount && !row.specialty_id) {
            return false;
        }
        if (field_name == 'year') {
            if (row.year) {
                return false;
            }
        }
        else if (field_name == 'amount') {
            if (row.amount && Number(row.amount) !=0) {
                return false;
            }
        }
        else if (field_name == 'specialty_id') {
            if (row.specialty_id) {
                return false;
            }
        }
        return true;
    }

    on_blur_for_ie = (reference_child) => {        
        reference_child.current.closePopup();
    }

    form_medicare_row = () => {
        var master_data = this.state.master_data.med_cap_specialty;
        var med_cap_specialty_master_data = this.state.patient_master_data.med_cap_specialty;
        var is_view = this.props.is_view;
        var current_state = this;
        var show_edit = !this.state.shown;
        if (is_view) {
          return this.state.medicare_cap_data.map(function(row, index) {
            return (
              <Table.Row key={row.id}>
                <Table.Cell>
                  <Checkbox disabled={is_view} />
                </Table.Cell>
                <Table.Cell>{moment(row.year).year()}</Table.Cell>
                <Table.Cell>
                  {/*<NumberFormat value={row.amount} fixedDecimalScale={true} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>} />*/}
                  <CurrencyFormatter
                    onChange={(values) => {
                      current_state.amount_change(values, 'amount', index);
                    }}
                    prefix='$'
                    id={'medicare_amount_' + index}
                    maxLength={13}
                    defaultValue={row.amount != null ? row.amount : ''}
                    allowNegative={true}
                    disabled={is_view}
                  />
                </Table.Cell>
                <Table.Cell>{format_patient_medicare_cap_speciality(row.specialty_id, master_data)}</Table.Cell>
              </Table.Row>
            );
          });
        } else {
          let cal_date = this.state.medicare_cap_current_year;
          //const handleCalender = this.handleChange;
          current_state.yearPickerReferences = [];
          return this.state.medicare_cap_data.map(function(row, index) {
            if (row.is_active) {
              let year_picker_reference: any = React.createRef();
              current_state.yearPickerReferences[index] = year_picker_reference;
              return (
                <Table.Row key={index}>
                  <Table.Cell>
                    <Checkbox
                      checked={row.is_selected}
                      onKeyDown={handle_click_on_enter}
                      onChange={(e, value) => current_state.check_medicare_cap_row(e, value, row, index)}
                    />
                  </Table.Cell>
                  <Table.Cell
                    className='custom-datepicker'
                    onBlur={(e) => {
                      if (e) {
                        if (detect_ie() && document.activeElement.tagName != 'TABLE') {
                          // got blur close the calendar
                          current_state.on_blur_for_ie(year_picker_reference);
                          current_state.handleChange_blur(e, e.target.value, index);
                        }
                      }
                    }}
                  >
                    {
                      <YearInput
                        popupPosition={'top left'}
                        type={'Year'}
                        closable
                        name={'medicare_year' + index}
                        id={'medicare_year_' + index}
                        index={index}
                        ref={year_picker_reference}
                        maxLength='4'
                        minDate={global_constants.DATE_RANGE_MEDICARE_CAP.YEAR_MIN.toString()}
                        maxDate={global_constants.DATE_RANGE_MEDICARE_CAP.YEAR_MAX.toString()}
                        animation={'fade'}
                        closeOnMouseLeave={false}
                        clearable={false}
                        onBlur={(e) => {
                          if (e) {
                            let { value } = e.target;
                            if(value && value.length) {
                                value = (value.length == 4) 
                                ? value
                                : value == '00'
                                ? moment()
                                    .year()
                                    .toString()
                                : '';
                                current_state.handleChange_blur(e, value, index);
                            }
                          }
                        }}
                        value={row.year ? (row.year.length > 4 ? moment(row.year).format('YYYY') : row.year.toString()) : ''}
                        onChange={(e, { value }) => {
                          if (e) {
                            current_state.handleChange(e, value, index, row);
                          } else {
                            if (value) {
                              current_state.handleChangeOnEnter(value, index, row);
                            }
                          }
                        }}
                        onKeyDown={(evt) => {
                          if (evt.key != 'Tab' && current_state.yearPickerReferences[index].current.state.popupIsClosed) {
                            current_state.yearPickerReferences[index].current.openPopup();
                          }
                          if (evt.keyCode == 13 && evt.key === 'Enter') {
                            evt.preventDefault();
                            current_state.yearPickerReferences[index].current.closePopup();
                            let { value } = evt.target;
                            value =
                              value.length == 4
                                ? value
                                : value == '00'
                                ? moment()
                                    .year()
                                    .toString()
                                : '';
                            current_state.handleChange(evt, value, index, row);
                          }
                        }}
                        className={current_state.check_invalid_row(row, 'year') && current_state.props.form_submitted ? 'error req-border-inp' : 'example-calendar-input'}
                      />
                    }
                  </Table.Cell>
                      <Table.Cell className={current_state.check_invalid_row(row, 'amount') && current_state.props.form_submitted ? 'error req-border-inp' : ''}>
                    <CurrencyFormatter
                      onChange={(values) => {
                        current_state.amount_change(values, 'amount', index);
                      }}
                      prefix='$'
                      id={'medicare_amount_' + index}
                      maxLength={13}
                      defaultValue={row.amount != null ? row.amount : ''}
                      allowNegative={true}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Selection
                      placeHolder={row.specialty_id.toString() ? null : 'Select'}
                      defaultValue={row.specialty_id.toString()}
                      onChange={(value) => {
                        current_state.speciality_change(value, row, index);
                      }}
                      options={med_cap_specialty_master_data}
                              style={current_state.check_invalid_row(row, 'specialty_id') && current_state.props.form_submitted ? 'req-background-inp' : ''}
                      hidden={true}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            }
          });
        }
    }

    render() {
        var hidden = {
            display: this.state.shown ? "none" : "block"
        }
        return (
          <div className='common-forms-view' id='medicare'>
            <div className='ui grid'>
              <div className='sixteen wide computer column'>
                <div className='accordion ui fluid styled common-accordion'>
                  <div className='active title' onClick={this.toggle.bind(this)}>
                    Medicare Cap
                    <i
                      tabIndex={0}
                      onKeyDown={handle_click_on_enter}
                      aria-hidden='true'
                      className={!this.state.shown ? 'ellipsis angle up icon' : 'ellipsis angle down icon'}
                    ></i>
                  </div>
                  <div className='content active' style={hidden}>
                    <Grid.Column>
                      <small>
                        Enter the dollar amount applied toward the Medicare cap to date for the specified year, for therapy services not billed through RevFlow.
                      </small>
                    </Grid.Column>
                    <Grid.Column>
                      <div className='customtable'>
                        <div className={'table-responsive ' + (this.props.is_view ? ' disable' : '')}>
                          <Dimmer active={this.state.loading}>
                            <Loader size='massive'>Loading</Loader>
                          </Dimmer>
                          <Table id='patient_medicare_cap_table'>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell style={{ minWidth: '50px' }}>
                                  <Checkbox checked={this.state.check_all} onChange={this.check_all_medicare_data} disabled={this.props.is_view} />
                                </Table.HeaderCell>
                                <Table.HeaderCell>Year</Table.HeaderCell>
                                <Table.HeaderCell>Amount</Table.HeaderCell>
                                <Table.HeaderCell>Specialty</Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            {this.state.medicare_cap_data.length > 0 && <Table.Body>{this.form_medicare_row()}</Table.Body>}
                          </Table>
                        </div>
                      </div>
                    </Grid.Column>
                    <Grid.Column computer={16} style={{ textAlign: 'right' }}>
                      <Button type='button' basic disabled={this.props.is_view} onClick={() => this.remove_medicare_cap()}>
                        Delete
                      </Button>
                      <Button type='button' onClick={() => this.on_add_click()} primary disabled={this.props.is_view}>
                        Add Row
                      </Button>
                    </Grid.Column>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        get_patient_medicare_cap: get_patient_medicare_cap,
        add_update_patient_medicare_cap: add_update_patient_medicare_cap
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        patient_details: state.patient_details
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MedicareCapComponent))