import * as React from 'react';
import { connect } from 'react-redux';
import { Prompt, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Grid, Modal } from 'semantic-ui-react';
import * as global_constant from './../../global_constants';
import { prompt_for_logout, set_notes_modal_status } from './../action/shared_action';

import { handle_focus_on_tab } from './../tab_navigation_utility';
import { custom_prompt, unsaved_form_data_for_prompt } from '../../login/action/login_action';
import * as session_storage from './../session_storage_utility';
interface IProps {
    is_data_changed: boolean,
    save: Function,
    when?: boolean, 
    history?: any[],
    go_next_location?: boolean,
    is_modal?: boolean,
    auto_logout?: any,
    prompt_for_logout?: (arg0: string) => void,
    allow_for_selective_routes?: any,
    check_prompt_for_logout?: string,
    set_custom_prompt?: (arg0: { value?: any; stage?: string; params?: any; }) => void,
    set_focus?:any,
    set_unsaved_form_data_for_prompt?: (arg0: boolean) => void,
    check_unsaved_form_data_for_prompt?: any,
    check_custom_prompt_change?: { stage: string; value: any; params: any; },    
    set_notes_modal_status?: (arg0: boolean) => void,
    prompt_message?: any,
    hide_yes_button?: any
    no_button_text?: any,
    yes_button_text?: any,
    no_button_class_name?: any,
}

export class PromptNavigation extends React.Component<IProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            show_prompt_modal: false,
            last_location: null,
            confirm_navigation: false
        };

        // stop user to perform logout action
        // before asking him to save the unsaved data
        this.props.prompt_for_logout(global_constant.prompt_for_logout.PROMPT_REQUIRED);
        this.update_prompt_open_status(true);
    }
   
    componentDidUpdate(prevProps) {

        if (prevProps.go_next_location != this.props.go_next_location) {
            this.go_next_location();
        }

        if (prevProps.is_data_changed && this.state.show_prompt_modal) {

            this.handle_popup_open();
        }  

        // changeing the set_unsaved_form_data_for_prompt to true 
        if (this.props.is_data_changed && !prevProps.is_data_changed) {
            this.props.set_unsaved_form_data_for_prompt(true);
        }

        // changeing the set_unsaved_form_data_for_prompt to false 
        if (!this.props.is_data_changed && prevProps.is_data_changed) {
            this.props.set_unsaved_form_data_for_prompt(false);
        }
        

         // if custom_prompt state is invoke then set stage to ongoing 
         if(this.props.check_custom_prompt_change != null && this.props.check_custom_prompt_change.stage &&
            this.props.check_custom_prompt_change.stage == global_constant.custom_prompt_stages.INVOKE) {
                
            this.props.set_custom_prompt({
                value: this.props.check_custom_prompt_change.value,
                stage: global_constant.custom_prompt_stages.ONGOING,
                params: this.props.check_custom_prompt_change.params,
            }); 

            this.setState({
                show_prompt_modal: true
            });
        }
    }
    handle_popup_open = () => {

        let closeIcons: NodeListOf<HTMLElement> = document.querySelectorAll('i.close.icon:not(.auto-search-icon)') as NodeListOf<HTMLElement>;
        let closeIcon = closeIcons[closeIcons.length - 1]
        if (closeIcon) {
            closeIcon.tabIndex = 0;
            closeIcon.id = "prompt_navigation_close_icon";
            closeIcon.addEventListener("keydown", function (event) {
                if (event.shiftKey && event.keyCode == 9) {
                    event.preventDefault();
                    let search_button = document.getElementById("id_btn_yes");
                    search_button.focus();
                }

                if (event.keyCode == 13) {
                    event.preventDefault();
                    var el = event.target as HTMLElement
                    el.click();
                }
            });

            document.getElementById("id_btn_cancel").focus();
        }
    }
    // stop navigation from forwarding to next location
    handleBlockedNavigation = (nextLocation, source = null) => {

        
        this.processCustomPromptFunctionalities(nextLocation);        

        const { confirm_navigation } = this.state
        var block_navigation = false;
        var show_prompt_modal = false;
        let is_data_changed = this.props.is_data_changed;
        // By passing the un save prompt by sending "by_pass_prompt" flag in url state.
        if (nextLocation.state && nextLocation.state.by_pass_prompt) {
            is_data_changed = false;
        }

        // this will show prompt modal asking user to save unsaved data
        if (!confirm_navigation && nextLocation != '' && is_data_changed && !this.props.auto_logout) {

            // will block the navigation
            block_navigation = false;

            // show prompt modal
            show_prompt_modal = true;
            
        } else {

            // wont block the navigation
            block_navigation = true;

            // wont open the prompt modal
            show_prompt_modal = false;

            // below condition will work only if it is triggered
            // apart from "cancel_button"
            if (source != global_constant.SOURCE_CANCEL_BUTTON) {

                // allow user to perform logout action
                if (this.props.check_prompt_for_logout == global_constant.prompt_for_logout.REQUIRED) {
                    this.props.prompt_for_logout(global_constant.prompt_for_logout.HANDLED);
                }
               
            }
        }

        // Special condition when we only want to allow prompt navigation for selective routes
        // check nextLocation must contains be a part of allow_for_selective_routes
        if(this.props.allow_for_selective_routes && !this.props.auto_logout) {
            if(this.props.allow_for_selective_routes.indexOf(nextLocation.pathname) === -1) { // routes dont exist for selective check
                show_prompt_modal = false;
                block_navigation = true; // wont block the navigation
            }
        }

        // I dont want to do the navigation when i am logging out from the application
        if (this.props.check_prompt_for_logout == global_constant.prompt_for_logout.REQUIRED || this.props.check_prompt_for_logout == global_constant.prompt_for_logout.HANDLED) {
            nextLocation = '';
            block_navigation = false;
        }

        this.setState({
            show_prompt_modal: show_prompt_modal,
            last_location: nextLocation == '' ? null : nextLocation,
        });


        // if cancel button is clicked then we will resetting the value for prompt_for_logout
        if (source == global_constant.SOURCE_CANCEL_BUTTON) {
            this.props.prompt_for_logout(global_constant.prompt_for_logout.PROMPT_REQUIRED);
        }

        if (typeof this.props.set_focus === 'function' && !show_prompt_modal) {
            this.props.set_focus();
        }

        return block_navigation;
    }

    // close prompt modal
    close_prompt_modal = (callback) =>
        this.setState({
            show_prompt_modal: false,
        }, callback);

    // do changes on clicking on Yes button with firing props method 
    save_unchanges = (e) => {

        // should loggout after action completion, we should send this in the callback of save
        if (this.props.check_prompt_for_logout == global_constant.prompt_for_logout.REQUIRED) {
            this.setState({
                last_location : "/login"
            })
        }
        
        this.setState({ show_prompt_modal: false });
        if (typeof this.props.save != 'undefined') {
            this.props.save(e);
            
        }

        // resetting the value for prompt_for_logout
        this.props.prompt_for_logout(global_constant.prompt_for_logout.PROMPT_REQUIRED);
    }

    // handle action on confirming the action
    handleConfirmNavigationClick = () => this.close_prompt_modal(() => {
        const { last_location } = this.state
        
        this.process_custom_prompt_for_continue_stage();

        // I am here because user has clicked No Button, 
        // this means user wants to get out of the application
        // If prompt for logout is required then  
        // i must handle logout functionality in componentDidUpdate 
        // by comparing prompt_for_logout to be "handled"
        if (this.props.check_prompt_for_logout == global_constant.prompt_for_logout.REQUIRED) {
            this.props.prompt_for_logout(global_constant.prompt_for_logout.HANDLED);
        }
        else if (last_location) {
            this.setState({
                confirm_navigation: true
            }, () => {
                // Navigate to the previous blocked location      
                this.props.history.push(last_location);
            })
        }

    })
    go_next_location = () => {
        const { last_location } = this.state;
        if (last_location != null) {
            this.props.history.push(last_location);
        }

        this.process_custom_prompt_for_continue_stage();
    }
    onClose = (e) => {
        this.setState({ show_prompt_modal: false });
        if (typeof this.props.set_focus === 'function') {
            this.props.set_focus();
        }
    }

    processCustomPromptFunctionalities = (nextLocation) => {
         // if custom_prompt state is invoke then set stage to ongoing 
         if(nextLocation == "" // which means on close / cancel button is invoked
            && this.props.check_custom_prompt_change != null && this.props.check_custom_prompt_change.stage &&
            this.props.check_custom_prompt_change.stage == global_constant.custom_prompt_stages.ONGOING) {
                
            this.props.set_custom_prompt({ });
        }
    }

    process_custom_prompt_for_continue_stage = () => {
        // if custom_prompt state is invoke then set stage to ongoing 
        if(this.props.check_custom_prompt_change != null && this.props.check_custom_prompt_change.stage &&
            this.props.check_custom_prompt_change.stage == global_constant.custom_prompt_stages.ONGOING) {
            
            this.props.set_custom_prompt({
                value: this.props.check_custom_prompt_change.value,
                stage: global_constant.custom_prompt_stages.CONTINUE,
                params: this.props.check_custom_prompt_change.params
            }); 
            this.props.set_notes_modal_status(false)
        }
    }

    componentWillUnmount = () => {
        if(this.props.set_unsaved_form_data_for_prompt) {
            this.props.set_unsaved_form_data_for_prompt(false);
            // this.props.set_custom_prompt({ });
        }
        this.update_prompt_open_status(false);
    }

    update_prompt_open_status = (is_open) => {
        let oldStatus = session_storage.get("_prompt_status");
        let status = {};

        if(this.props.is_modal) {
            status = {
                modal: is_open,
                page: oldStatus && oldStatus.page ? oldStatus.page : false
            }
        } else {
            status = {
                modal: oldStatus && oldStatus.modal ? oldStatus.modal : false,
                page: is_open
            }
        }
        session_storage.set("_prompt_status", status);
    }

    render() {
        var hide_yes_button = { display: this.props.hide_yes_button ? "none" : "" }
        const { when } = this.props
        return (
            <div className="item" id="id_div_unsave_prompt">
                <Prompt
                    when={when}
                    message={this.handleBlockedNavigation} />
                <Modal
                    onClose={e => this.handleBlockedNavigation("", global_constant.SOURCE_CANCEL_BUTTON)}
                    centered={false}
                    className="default-modal"
                    open={this.state.show_prompt_modal}
                    closeIcon
                    closeOnDimmerClick={false}
                >
                    <Modal.Header>Unsaved changes</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Grid>
                                <Grid.Column>
                                    <p>{this.props.prompt_message || "Your changes have not been saved. Do you wish to save now?"}</p>
                                </Grid.Column>
                            </Grid>
                            <Grid>
                                <Grid.Column computer={16} textAlign="right">
                                    <Button id="id_btn_cancel" type="reset" basic onClick={e => this.handleBlockedNavigation("", global_constant.SOURCE_CANCEL_BUTTON)}>Cancel</Button>
                                    <Button id="id_btn_no" type="reset" className={this.props.no_button_class_name || 'basic'}  onClick={this.handleConfirmNavigationClick}>{this.props.no_button_text || 'No'}</Button>
                                    <Button id="id_btn_yes" style={hide_yes_button} type='submit' onKeyDown={(event) => handle_focus_on_tab(event, "prompt_navigation_close_icon")} onClick={this.save_unchanges} primary>{this.props.yes_button_text || 'Yes'}</Button>
                                </Grid.Column>
                            </Grid>

                        </Modal.Description>

                    </Modal.Content>
                </Modal>
                </div>
            );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        prompt_for_logout: prompt_for_logout,
        set_notes_modal_status: set_notes_modal_status,
        set_custom_prompt: custom_prompt,
        set_unsaved_form_data_for_prompt: unsaved_form_data_for_prompt,
    }, dispatch)
}

const mapStateToProps = (state) => {
    return {
        auto_logout: state.user_login_details.auto_logout,
        check_unsaved_form_data_for_prompt: state.user_login_details.unsaved_form_data_for_prompt,
        check_custom_prompt_change: state.user_login_details.custom_prompt
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (PromptNavigation));