import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { toastr as toaster } from 'react-redux-toastr';
import { Accordion, Button, Form, Grid, Icon, Input } from 'semantic-ui-react';
import { BatchClaimsFeatures, option_formater as option_formatter } from '../../../admin/constants/constants';
import { toastr_options } from '../../../global_constants';
import ReportHeader from '../../../reports/component/report_header';
import { messages, report_ledger_full_configuration_header_id, dateTimeFormatter } from '../../../reports/report_constants';
import { log_error } from '../../../shared/action/shared_action';
import DateRangePickerComponent from '../../../shared/component/date_range_component';
import GridView from '../../../shared/component/grid/index';
import SelectionComponent from '../../../shared/component/selection_component';
import { LoaderComponent } from '../../../shared/component/loading_component';
import * as local_storage from '../../../shared/local_storage_utility';
import * as session_storage from '../../../shared/session_storage_utility';
import { handle_click_on_enter, set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import { get_all_error, get_columns, get_lauch_darkley_key_value } from '../../../shared/utility';
import {
  search_batch_billing,
  batch_billing_lookup,
  batch_billing_change_status,
  batch_billing_schedule_cancel,
  clear_batch_claims_format_cache,
  get_last_format_flush
} from '../../action/billing_actions';
import { batch_billing_configuration, batch_billing_page_type, column_def_ff_columns } from '../../billing_constants';
import { payer } from '../../../admin/constants/constants';
import { get_search_data, get_payer_group_by_company } from '../../../admin/constants/action/constants_action';
import { get_group_column } from '../../../shared/component/grid/utils';
import { headerDragTemplate } from '../../../reports/report_constants';
import { check_internal_staff, row_data_formatter } from '../../utility';
import * as global_constants from '../../../global_constants';
import FeatureFlipUtils from '../../../shared/feature_flip_helper';
import * as FFConstant from '../../../feature_flip_constant';


const DropdownInput = ({ label, id, options, change_handler, defaultValue }) => {
  return (
    <Grid.Column tablet={8} computer={4}>
      <Form.Field>
        <label dangerouslySetInnerHTML={{ __html: label }} />
        <SelectionComponent
          id={id}
          name={id}
          options={options}
          onChange={change_handler}
          defaultValue={defaultValue}
          style={'dropdown-options-wrap'}
        />
      </Form.Field>
    </Grid.Column>
  );
};

export class BatchBillingComponent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      controls_is_expended: true,
      is_submitted: false,
      is_grid_visible: false,
      loading: false,
      is_loading_dropdown: true,
      is_date_required: true,
      is_search_disabled: false,
      is_clear_button_disabled: false,
      is_cancel_disabled: false,
      is_delete_disabled: false,
      is_clear_disabled: false,
      is_clear_cache_disabled: false,
      is_cache_flush_success: false,
      is_new_disabled: false,
      is_release_disabled: false,
      is_hold_disabled: false,
      is_rerun_disabled: false,
      grid_rows_count: 0,
      is_error_date: false,
      grid_params: null
    };
  }

  batch_billing: any = {};
  options_data: any = {
    ...this.batch_billing.options_data
  };
  report_data: any = {
    rows: [],
    cols: []
  };
  search_criteria: any = {
    ...this.batch_billing.search_criteria
  };
  payer_item = [];
  report_container: any;
  title: string;
  total_column_width: number = 0;
  grid_header_height: number = 0;
  is_initial_result = false;
  grid_width = 0;
  token: any = '';
  is_mounted = false;
  grid_release_data_length: number = 0;
  software_only_company = false;
  updated_grid_column = [];
  is_scheduled_run = false;
  service_type = '0';
  selected_items = [];
  current_user_type: global_constants.enum_user_type_r3_id = 1;
  page_title: string = '';
  is_button_update: boolean = false;
  cache_last_updated_date: Date;
  // Fetching the report id and params.
  UNSAFE_componentWillMount = () => {
    this.batch_billing = batch_billing_configuration(this.props);
    this.options_data = {
      ...this.batch_billing.options_data
    };
    this.search_criteria = {
      ...this.batch_billing.search_criteria
    };
    this.token = this.props.user_login_details.user_data.data.token_details.access_token;
    let auth_data = local_storage.get('auth_data');
    if (auth_data && auth_data.user_type_r3_id) {
      this.current_user_type = auth_data.user_type_r3_id;
      this.software_only_company = auth_data.company_service_type == '3';
    }

    if (session_storage.get('batch_billing_search_criteria')) {
      this.is_initial_result = true;
      this.search_criteria = {
        ...session_storage.get('batch_billing_search_criteria')
      };
      this.search_criteria = {
        ...this.search_criteria,
        start: this.handle_date('start'),
        end: this.handle_date('end')
      };
    }
    if (this.is_initial_result) {
      if (this.search_criteria.batch_type === '3') {
        this.report_data.cols = [...this.batch_billing.column_defs_scheduled_runs];
        this.updated_grid_column = [...this.batch_billing.column_defs_scheduled_runs];
      } else {
        this.report_data.cols = [...this.batch_billing.column_defs_current_past_runs];
        this.updated_grid_column = [...this.batch_billing.column_defs_current_past_runs];
      }
    } else {
      this.report_data.cols = [...this.batch_billing.column_defs_current_past_runs];
      this.updated_grid_column = [...this.batch_billing.column_defs_current_past_runs];
    }
    this.page_title = this.set_page_title();
    this.service_type =
      this.current_user_type == global_constants.enum_user_type_r3_id.Partner ||
      this.current_user_type == global_constants.enum_user_type_r3_id.Customer
        ? '3'
        : this.service_type;
  };

  componentDidMount = () => {
    this.is_mounted = true;
    this.get_dropdowns_data();
    if (!document.body.classList.contains('reports')) {
      document.body.classList.add('reports');
    }

    let elem = document.getElementById('accordion-column');
    let all_form_elem = elem.getElementsByTagName('select');
    all_form_elem[0].focus();
  };

  componentWillUnmount = () => {
    document.body.classList.remove('reports');
    this.is_mounted = false;
    this.search_criteria = {
      ...this.batch_billing.search_criteria
    };
    this.report_data = [];
    if (this.props.history.location) {
      const route_array = this.props.history.location.pathname.split('/');
      route_array && route_array[2] !== 'batch_billing' && session_storage.remove('batch_billing_search_criteria');
    }
  };

  get_dropdowns_data = async () => {
    let arrData = [];
    if (this.is_mounted) {
      this.setState({
        re_render: true,
        is_loading_dropdown: true,
        is_date_required: this.search_criteria.batch_type === '3' ? false : true
      });
    }

    if (this.props.rsiB31486AddCompanyMultiSelectDropDownToPayersPage) {
        let params = {
          company_id : -1,
          showInactive: false
        }
        arrData.push(get_payer_group_by_company(this.token, params));
     }
    else {
      arrData.push(get_search_data(this.token, payer.api.payer_groups.api));
    }
    arrData.push(batch_billing_lookup(this.token, 0, batch_billing_page_type.search));
    arrData.push(get_last_format_flush(this.token));
    await Promise.all(arrData)
      .then((response) => {
        // Format,Batch Type, Run Status and Date Type Dropdown Data
        if (response[1].data && response[1].data.data && response[1].data.data.data) {
          let option_data = response[1].data.data.data;
          let format_data = (option_data.format_title && option_data.format_title.result) || [];
          let run_status_type = (option_data.run_status_type && option_data.run_status_type) || [];
          let company_list = (option_data.company_list && option_data.company_list.result) || [];
          format_data.unshift({ name: '--ALL--', id: 0 });
          run_status_type[0].text = '--ALL--';

          this.options_data = {
            ...this.options_data,
            format_title_id: option_formatter(format_data, 'name', 'id', false, false),
            batch_type: (option_data.batch_type && option_data.batch_type) || [],
            date_type: (option_data.date_type && option_data.date_type) || [],
            run_status_type: run_status_type,
            company_id:
              option_formatter(
                company_list,
                'company_name',
                'company_id',
                false, // to add blank object
                false // to sorted object,
              ) || []
          };
        }
        // Payer Groups Dropdown Data
        if (response[0].data && response[0].data.data) {
          let payer_group_data = [];
          if (
            this.current_user_type == global_constants.enum_user_type_r3_id.Partner ||
            this.current_user_type == global_constants.enum_user_type_r3_id.Customer
          ) {
            payer_group_data = response[0].data.data.filter((item) => {
              if (item.is_software_only) {
                return item;
              }
            });
          } else {
            payer_group_data = response[0].data.data;
          }
          let payer_groups = option_formatter(
            payer_group_data,
            'payer_group_name',
            'payer_group_id',
            false, // to add blank object
            true // to shorted object
          );
          payer_groups.unshift({ text: '', value: 0 });
          this.options_data.payer_groups = payer_groups;
        }
        // Set Default company
        let company_id = '-1';
        const current_company = this.props.user_login_details.user_data.data.company_id;
        this.options_data.company_id.some((item) => item.value === current_company);
        if (this.service_type === '3') {
          company_id = this.options_data.company_id.some((item) => item.value === current_company)
            ? current_company
            : this.options_data.company_id.length > 0
            ? this.options_data.company_id[0].value
            : '-1';
        } else {
          company_id = this.options_data.company_id.length > 0 ? this.options_data.company_id[0].value : '-1';
        }
        // Set default value in dropdown
        if (!this.is_initial_result) {
          this.search_criteria = {
            ...this.search_criteria,
            company_id: company_id,
            format_title_id:
              this.options_data.format_title_id.length > 0 ? this.options_data.format_title_id[0].value : '0',
            batch_type: this.options_data.batch_type.length > 0 ? this.options_data.batch_type[0].value : '0',
            run_status: this.options_data.run_status_type.length > 0 ? this.options_data.batch_type[0].value : '0',
            payer_group_id: this.options_data.payer_groups.length > 0 ? this.options_data.payer_groups[0].value : '0'
          };
        } else {
          this.search_criteria = {
            ...this.search_criteria,
            company_id: company_id
          };
        }
        if (response[2].data && response[2].data.data) {
          this.cache_last_updated_date = dateTimeFormatter(response[2].data.data);
        } 

        this.setState(
          {
            re_render: false,
            is_loading_dropdown: false
          },
          () => {
            this.is_initial_result && this.get_report_data();
          }
        );
      })
      .catch((error) => {
        if (this.is_mounted) {
          this.setState({
            is_show_pop_up: false,
            is_loading_dropdown: false
          });
        }
        if (error.response) {
          error.response.map((item) => {
            if (item.error.response && item.error.response.data && item.error.response.data.messages) {
              log_error(item.error);
              const toastr_options = this.show_html_content_toaster(get_all_error(item.error.response.data));
              toaster.error('', toastr_options);
            }
            return;
          });
        }
      });
  };

  get_payer_group_on_company_change = async () => {

    if (this.props.rsiB31486AddCompanyMultiSelectDropDownToPayersPage) {
      let arrData = [];
      if (this.is_mounted) {
        this.setState({
          re_render: true,
          is_loading_dropdown: true,
          is_date_required: this.search_criteria.batch_type === '3' ? false : true
        });
      }

        let params = {
          company_id : this.search_criteria.company_id,
          showInactive: false
        }
        arrData.push(get_payer_group_by_company(this.token, params));

    await Promise.all(arrData)
      .then((response) => {
        // Payer Groups Dropdown Data
        if (response[0].data && response[0].data.data) {
          let payer_group_data = [];
          if (
            this.current_user_type == global_constants.enum_user_type_r3_id.Partner ||
            this.current_user_type == global_constants.enum_user_type_r3_id.Customer
          ) {
            payer_group_data = response[0].data.data.filter((item) => {
              if (item.is_software_only) {
                return item;
              }
            });
          } else {
            payer_group_data = response[0].data.data;
          }
          let payer_groups = option_formatter(
            payer_group_data,
            'payer_group_name',
            'payer_group_id',
            false, // to add blank object
            true // to shorted object
          );
          payer_groups.unshift({ text: '', value: 0 });
          this.options_data.payer_groups = payer_groups;
        }

        // Set default value in dropdown
        this.search_criteria = {
          ...this.search_criteria,            
          payer_group_id: this.options_data.payer_groups.length > 0 ? this.options_data.payer_groups[0].value : '0'
        };

        this.setState(
          {
            re_render: false,
            is_loading_dropdown: false
          });
      })
      .catch((error) => {
        if (this.is_mounted) {
          this.setState({
            is_show_pop_up: false,
            is_loading_dropdown: false
          });
        }
        if (error.response) {
          error.response.map((item) => {
            if (item.error.response && item.error.response.data && item.error.response.data.messages) {
              log_error(item.error);
              const toastr_options = this.show_html_content_toaster(get_all_error(item.error.response.data));
              toaster.error('', toastr_options);
            }
            return;
          });
        }
      });
    }
  }

  // Get Report Data.
  get_report_data = async () => {
    let params: any = {};
    let button_status = {
      is_cancel_disabled: false,
      is_release_disabled: false,
      is_hold_disabled: false,
      is_rerun_disabled: false
    };
    params = {
      ...this.search_criteria,
      start: this.handle_date('start', true),
      end: this.handle_date('end', true)
    };
    this.selected_items = [];
    if (this.search_criteria.batch_type === '3') {
      delete params.set_id;
      delete params.run_number;
    }
    this.is_button_update = true;
    this.page_title = this.set_page_title(this.search_criteria.batch_type);
    this.grid_width = document.getElementsByClassName('patient-search-form')[0].clientWidth;
    if (this.is_mounted) {
      this.setState({
        loading: true,
        is_grid_visible: false,
        is_search_disabled: true,
        is_delete_disabled: true,
        ...this.disabled_buttons(this.search_criteria.run_status, button_status)
      });
    }
    await search_batch_billing(this.token, params).then(
      (response) => {
        if (response.data && response.data.data) {
          response.data.data.allow_grouping = true;
          this.report_data.allow_grouping = response.data.data.allow_grouping;
		  response.data.data = row_data_formatter(response.data.data, this.report_data.cols);
		  this.report_data['rows'] = response.data.data || [];
		  this.format_colum_data();
		  this.total_column_width = 0;
          const grid_height = get_columns(this.report_data['rows'], this.report_data.cols);
          this.grid_header_height = grid_height.header_height;
          this.total_column_width = grid_height.total_column_width;
          // Set Column Definitions
          if (this.search_criteria.batch_type === '3') {
            this.is_scheduled_run = true;
          } else {
            this.is_scheduled_run = false;
            this.report_data.cols.map((item) => {
              if (item.type == 'hyper_link') {
                return (item.headerComponentParams = headerDragTemplate(`link_${item.field}`, item.headerName));
              }
              return (item.headerComponentParams = headerDragTemplate(item.field, item.headerName));
            });
            this.report_data.cols.unshift(get_group_column());
			}
          if (this.is_mounted) {
            this.setState({
              loading: false,
              is_grid_visible: true,
              grid_rows_count: this.report_data['rows'] && this.report_data['rows'].length,
              is_search_disabled: false,
              is_delete_disabled: false,
              is_clear_disabled: false,
              is_new_disabled: false
            });
          }
        } else {
          if (response.data.messages) {
            log_error(response.data.messages);
            const toastr_options = this.show_html_content_toaster(get_all_error(response.data.messages));
            toaster.error('', toastr_options);
          }
          if (this.is_mounted) {
            this.setState({
              loading: false,
              is_search_disabled: false,
              is_delete_disabled: false,
              is_clear_disabled: false,
              is_new_disabled: false,
              controls_is_expended: true
            });
          }
        }
      },
      (error: { response: { data: any } }) => {
        if (this.is_mounted) {
          this.setState({
            loading: false,
            is_search_disabled: false,
            is_delete_disabled: false,
            is_clear_disabled: false,
            is_new_disabled: false,
            controls_is_expended: true
          });
        }
        if (error.response && error.response.data) {
          log_error(error);
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error('', toastr_options);
        }
      }
    );
	};

	private format_colum_data() {
		if (this.props.testOutputSearchGridFFVal) {
			this.evaluate_and_update_row_data(GridConstants.IS_TEST, GridConstants.TRUE, GridConstants.TEST, GridConstants.LIVE);
			this.evaluate_and_update_row_data(GridConstants.IS_PENDING, GridConstants.TRUE, GridConstants.YES, GridConstants.BLANK);
		} 
    else {
			this.remove_ff_cols(this.report_data.cols);
		}

    if (!(this.props.rsiBill393UiBillingFailedCharges && check_internal_staff())) {
        this.remove_cols_new_ff(this.report_data.cols);
    }

		this.evaluate_and_update_row_data(GridConstants.STATUS_UPDATE, GridConstants.TRUE, GridConstants.YES, GridConstants.NO);
		this.evaluate_and_update_row_data(GridConstants.RUN_STATUS, GridConstants.IN_PROGRESS_INCORRECT, GridConstants.IN_PROGRESS_CORRECT);
		this.evaluate_and_update_row_data(GridConstants.PRINT_STATUS, GridConstants.IN_PROGRESS_INCORRECT, GridConstants.IN_PROGRESS_CORRECT);

	}

	private evaluate_and_update_row_data(prop_name: string, eval_value: string, t_value: string, f_value?: string): any {
		if (f_value || f_value === GridConstants.BLANK) {
			this.report_data.rows.map((row) => {
				return row[prop_name] = row[prop_name] === eval_value ? t_value : f_value;
			});
		}
		else {
			this.report_data.rows.map((row) => {
				if (row[prop_name] === eval_value) {
					return row[prop_name] = t_value;
				}
			});
		}
	}

	//Apply Ff 
	private remove_ff_cols (column_def: any): any {
		if (!column_def || column_def.length == 0)
			return column_def;
		return FeatureFlipUtils.apply_column_ff(column_def,
			column_def_ff_columns, false);
	};

	// Apply Ff 
	private remove_cols_new_ff (column_def: any): any {
		if (!column_def || column_def.length == 0) {
      return column_def;
    }
    return FeatureFlipUtils.apply_column_ff(column_def, ['num_chg_pass', 'num_chg_fail'], false);
	};

  // Calender change handler.
  range_date_change_handler = (from_date: any, end_date: any, type: any) => {
    this.search_criteria = {
      ...this.search_criteria,
      start: from_date || null,
      end: end_date || null
    };
    if (this.is_mounted) {
      this.setState({
        is_error_date:
          from_date && moment.isMoment(from_date) ? (end_date && moment.isMoment(end_date) ? false : true) : true
      });
    }
  };

  // Dropdown change handler.
  dropdown_change_handler = (event: {
    target: { selectedOptions?: any; options?: any; selectedIndex?: any; id?: any; value?: any };
  }) => {
    const { id, value } = event.target;
    let is_required = {};
    this.search_criteria[id] = value;
    if (id === 'run_status') {
      this.is_button_update = false;
    }
    if (id === 'batch_type') {
      if (value === '3') {
        is_required['is_date_required'] = false;
        this.updated_grid_column = [...this.batch_billing.column_defs_scheduled_runs];
      } else {
        is_required['is_date_required'] = true;
        this.updated_grid_column = [...this.batch_billing.column_defs_current_past_runs];
      }
    }
    if (this.is_mounted) {
      this.setState({
        form_object: this.search_criteria,
        ...is_required
      }, () => {
        if(id === 'company_id') {
          this.get_payer_group_on_company_change();
        }
      });
    }
  };

  // Input change handler.
  input_change_handler = (e) => {
    const { value, name } = e.target;
    if (value.length > 0 && !/^\d+$/.test(value)) {
      toaster.error('', this.batch_billing.update_msg.numeric_allow);
    }
    var txt_pid = e.target.value.trim();
    this.search_criteria[name] = e.target.validity.valid
      ? Number(txt_pid) <= 2147483647
        ? txt_pid
        : this.max_value_validation(txt_pid)
      : this.search_criteria[name];
    if (this.is_mounted) {
      this.setState({
        form_object: this.search_criteria
      });
    }
  };

  max_value_validation = (input) => {
    if (Number(input) > 2147483647) {
      return this.max_value_validation(input.substring(0, input.length - 1));
    } else {
      return Number(input);
    }
  };

  //Required Field Validator.
  required_field_validator = () => {
    let is_error = false;
    let from_date: any = this.search_criteria.start;
    let to_date: any = this.search_criteria.end;
    if (this.state.is_date_required) {
      if (from_date && to_date) {
        is_error = false;
      } else {
        is_error = true;
        toaster.error('', messages.mandatory_fields);
      }
    }
    this.setState({
      is_error_date: is_error
    });
    return is_error;
  };

  // Function used to Run Report based on controllers values.
  run_report_handler = (e: { preventDefault: () => void; stopPropagation: () => void }) => {
    let is_error = false;
    if (this.is_mounted) {
      this.setState({ is_submitted: true });
    }
    //Validate Required Field
    is_error = this.required_field_validator();
    if (is_error) {
      return;
    }
    this.report_data.cols = [...this.updated_grid_column];
    this.get_report_data();
    session_storage.set('batch_billing_search_criteria', this.search_criteria);
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  // Function used to put a row in the fmt_cache_command that will initiate a refetch of the data in memory in the r4 service
  clear_cache_handler = async () => {
    this.setState({
      is_clear_cache_disabled: true
    });
    let arrData = [];
    arrData.push(clear_batch_claims_format_cache(this.token));
    await Promise.all(arrData)
      .then((response) => {
        this.setState(
          {
            is_cache_flush_success: true
          });
      })
      .catch((error) => {
        if (this.is_mounted) {
          this.setState({
            is_clear_cache_disabled: false
          });
        }
        if (error.response) {
          error.response.map((item) => {
            if (item.error.response && item.error.response.data && item.error.response.data.messages) {
              log_error(item.error);
              const toastr_options = this.show_html_content_toaster(get_all_error(item.error.response.data));
              toaster.error('', toastr_options);
            }
            return;
          });
        }
      });
  };

  // Function calls on click of Clear button and clear all the searched result as well as criteria.
  clear_handler = () => {
    if (this.is_mounted) {
      this.is_initial_result = false;
      this.is_scheduled_run = false;
      this.is_button_update = false;
      this.selected_items = [];
      this.search_criteria = { ...this.batch_billing.search_criteria };
      this.report_data = {
        rows: [],
        cols: [...this.batch_billing.column_defs_current_past_runs]
      };
      this.updated_grid_column = [...this.batch_billing.column_defs_current_past_runs];
      this.page_title = this.set_page_title();
      session_storage.remove('batch_billing_search_criteria');
      this.setState({
        is_grid_visible: false,
        is_error: false,
        is_submitted: false,
        controls_is_expended: true,
        is_search_disabled: false,
        is_clear_disabled: false,
        is_new_disabled: false,
        is_cancel_disabled: false,
        is_delete_disabled: false,
        is_release_disabled: false,
        is_hold_disabled: false,
        is_rerun_disabled: false
      });
    }
  };

  // Show multiple messages
  show_html_content_toaster = (msg: any) => {
    return {
      component: () => (
        <div>
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        </div>
      ),
      timeOut: toastr_options.toastr_time_out,
      preventDuplicates: true
    };
  };

  // Search Criteria Toggler
  controls_toggle = () => {
    if (this.is_mounted) {
      this.setState({
        controls_is_expended: !this.state.controls_is_expended
      });
    }
  };

  // Function calls on initialization of Ag-Grid and catch its reference.
  get_grid_ref = (grid_params: {
    api: { getModel: () => { (): any; new (): any; getRowCount: { (): any; new (): any } } };
    columnApi: { setColumnWidth: (arg0: any, arg1: any, arg2: boolean) => void };
  }) => {
    if (this.is_mounted) {
      this.setState({
        grid_params,
        grid_rows_count: grid_params.api.getModel().getRowCount()
      });
    }
    if (this.total_column_width > this.grid_width) {
      this.report_data.cols.filter((item: { [x: string]: any; field: any }) => {
        grid_params.columnApi.setColumnWidth(item.field, item['width'], false);
      });
    }
  };

  //Function calls after click of filter button and recalculate the footer sum.
  on_filter_button_click = () => {
    // Update row count
    this.setState({
      grid_rows_count: this.state.grid_params.api.getModel().getRowCount()
    });
  };

  is_row_selectable = (params) => {
    if (params.group) return false;
    if (params.data && params.data.run_status_type == 5) return false;
    return true;
  };

  on_group_by_Change = () => {
    if (this.is_mounted) {
      this.selected_items = [];
      this.setState({
        is_cancel_disabled: false,
        is_new_disabled: false,
        is_release_disabled: false,
        is_hold_disabled: false,
        is_rerun_disabled: false
      });
    }
  };

  handle_navigation_after_last_element = (event) => {
    // apply a check where grid is empty
    if (
      !event.shiftKey &&
      event.keyCode == '9' &&
      (this.state.report_data == null || this.state.report_data.length == 0)
    ) {
      set_focus_to_app_header(event);
    }
  };

  handle_date = (name: React.ReactText, title = false) => {
    if (title) {
      return this.search_criteria[name] ? moment(this.search_criteria[name]).format('MM/DD/YYYY') : '';
    } else {
      return this.search_criteria[name] ? moment(moment(this.search_criteria[name]).format('MM/DD/YYYY')) : null;
    }
  };

  create_batch_billing = () => {
    //this.props.history.push(`/coming_soon`);
    this.props.history.push(`/billing/batch_billing`);
  };

  // Select case_id on checkbox
  on_check_box_selection = (items) => {
    if (this.selected_items.length !== items.length) {
      this.selected_items = items;
      const run_status = parseInt(this.search_criteria.run_status);
      let button_status = {
        is_cancel_disabled: false,
        is_release_disabled: false,
        is_hold_disabled: false,
        is_rerun_disabled: false
      };
      // Disabled button basis of record type If status set is 'All'
      if (run_status === 0 && this.is_button_update) {
        this.selected_items.map((item) => {
          button_status = { ...this.disabled_buttons(item.run_status_type, button_status) };
        });
        this.setState({
          ...button_status
        });
      }
    }
  };

  disabled_buttons = (value, button_status) => {
    let { is_cancel_disabled, is_release_disabled, is_hold_disabled, is_rerun_disabled } = button_status;
    value = parseInt(value);
    switch (value) {
      // Requested
      case 1:
        is_release_disabled = true;
        is_rerun_disabled = true;
        break;
      // InProgress
      case 2:
        is_release_disabled = true;
        is_rerun_disabled = true;
        break;
      // Completed
      case 3:
        // is_cancel_disabled = true;
        is_release_disabled = true;
        is_hold_disabled = true;
        break;
      // OnHold
      case 4:
        is_hold_disabled = true;
        is_rerun_disabled = true;
        break;
      // Cancelled
      case 5:
        // is_cancel_disabled = true;
        // is_hold_disabled = true;
        // is_rerun_disabled = true;
        // is_release_disabled = true;
        break;
      default:
        break;
    }
    return {
      is_cancel_disabled,
      is_release_disabled,
      is_hold_disabled,
      is_rerun_disabled
    };
  };

  batch_billing_change_status = (format_run_type) => {
    let params = [];
    let format_run_text = {
      1: 'rerun',
      2: 'hold',
      3: 'release',
      4: 'cancel'
    };
    this.selected_items.map((item) => {
      switch (format_run_type) {
        case 1: // Rerun
          if ([3].indexOf(item.run_status_type) > -1) {
            params.push(item['run_number']);
          }
          break;
        case 2: // Hold
          if ([1, 2].indexOf(item.run_status_type) > -1) {
            params.push(item['run_number']);
          }
          break;
        case 3: // Release
          if ([4].indexOf(item.run_status_type) > -1) {
            params.push(item['run_number']);
          }
          break;
        case 4: // Cancel
        default:
          if ([1, 2, 4].indexOf(item.run_status_type) > -1) {
            params.push(item['run_number']);
          }
          break;
      }
      return;
    });
    if (params && params.length <= 0) {
      toaster.error('', `${this.batch_billing.update_msg.empty_error} ${format_run_text[format_run_type]}.`);
      return;
    }
    this.update_batch_billing_status(format_run_type, params);
  };

  update_batch_billing_status = async (format_run_type, params) => {
    if (this.is_mounted) {
      this.setState({
        loading: true,
        is_clear_disabled: true,
        is_new_disabled: true,
        is_release_disabled: true,
        is_hold_disabled: true,
        is_rerun_disabled: true
      });
    }
    await batch_billing_change_status(this.token, format_run_type, params).then(
      (response) => {
        if (response.data && response.data.status === 1) {
          toaster.success('', response.data.messages[0].message);
          if (this.is_mounted) {
            this.selected_items = [];
            this.setState(
              {
                loading: false
              },
              () => this.get_report_data()
            );
          }
        } else {
          if (response.data.messages) {
            const toastr_options = this.show_html_content_toaster(get_all_error(response.data.messages));
            toaster.error('', toastr_options);
          }
          if (this.is_mounted) {
            this.selected_items = [];
            this.setState({
              loading: false,
              is_clear_disabled: false,
              is_new_disabled: false,
              is_release_disabled: false,
              is_hold_disabled: false,
              is_rerun_disabled: false
            });
          }
        }
      },
      (error) => {
        if (this.is_mounted) {
          this.setState({
            loading: false,
            is_clear_disabled: false,
            is_new_disabled: false,
            is_release_disabled: false,
            is_hold_disabled: false,
            is_rerun_disabled: false
          });
        }
        if (error.response.data) {
          log_error(error);
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error('', toastr_options);
        }
      }
    );
  };

  delete_schedule = () => {
    let params = [];
    this.selected_items.map((item) => {
      params.push(item.id);
    });
    if (params && params.length <= 0) {
      toaster.error('', this.batch_billing.update_msg.deleted_msg);
      return;
    }
    this.batch_billing_schedule_cancel(params);
  };

  batch_billing_schedule_cancel = async (params) => {
    if (this.is_mounted) {
      this.setState({
        loading: true,
        is_search_disabled: true
      });
    }
    await batch_billing_schedule_cancel(this.token, params).then(
      (response) => {
        if (response.data && response.data.status === 1) {
          toaster.success('', response.data.messages[0].message);
          if (this.is_mounted) {
            this.selected_items = [];
            this.setState(
              {
                loading: false,
                is_search_disabled: false
              },
              () => this.get_report_data()
            );
          }
        } else {
          if (response.data.messages) {
            const toastr_options = this.show_html_content_toaster(get_all_error(response.data.messages));
            toaster.error('', toastr_options);
          }
          if (this.is_mounted) {
            this.selected_items = [];
            this.setState({
              loading: false,
              is_search_disabled: false
            });
          }
        }
      },
      (error) => {
        if (this.is_mounted) {
          this.setState({
            loading: false,
            is_search_disabled: false
          });
        }
        if (error.response.data) {
          log_error(error);
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error('', toastr_options);
        }
      }
    );
  };

  set_page_title = (id = undefined) => {
    if (id == undefined) {
      return 'Batch Claims';
    }
    let batch_type = [
      'Batch Claims - Current Runs',
      'Batch Claims - Past Runs',
      'Batch Claims - Current and Past Runs',
      'Batch Claims - Scheduled Runs'
    ];
    return batch_type[id];
  };

  render() {
    return (
      <LoaderComponent loading={this.state.loading || this.state.is_loading_dropdown}>
        <div className={'common-forms-add '}>
          <div className={'common-forms-search report-framework batch_billing_run'}>
            <ReportHeader title={this.page_title} />
            <div className='outer-wrap'>
              <div className='patient-search-form  patient_search_bottom_padding' id='report-criteria-container'>
                <Form autoComplete='off' onSubmit={this.run_report_handler}>
                  <Grid>
                    <Grid.Column
                      computer={16}
                      tablet={16}
                      textAlign='left'
                      className='accordionColumn'
                      id='accordion-column'
                    >
                      <Accordion fluid styled>
                        <Accordion.Title
                          active={this.state.controls_is_expended}
                          index={0}
                          onClick={this.controls_toggle}
                        >
                          <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name='angle right' />
                          Search Criteria
                        </Accordion.Title>
                        <Accordion.Content active={this.state.controls_is_expended}>
                          <Grid>
                            <DropdownInput
                              label='Format'
                              id='format_title_id'
                              change_handler={(value, event) => {
                                this.dropdown_change_handler(event);
                              }}
                              defaultValue={this.search_criteria.format_title_id}
                              options={this.options_data.format_title_id}
                            />
                            <DropdownInput
                              label='Company'
                              id='company_id'
                              change_handler={(value, event) => {
                                this.dropdown_change_handler(event);
                              }}
                              defaultValue={this.search_criteria.company_id}
                              options={this.options_data.company_id}
                            />
                            <DropdownInput
                                label='Payer Group'
                                id='payer_group_id'
                                change_handler={(value, event) => {
                                    this.dropdown_change_handler(event);
                                }}
                                defaultValue={this.search_criteria.payer_group_id}
                                options={this.options_data.payer_groups}
                            />
                            <DropdownInput
                              label='Run Type'
                              id='batch_type'
                              change_handler={(value, event) => {
                                this.dropdown_change_handler(event);
                              }}
                              defaultValue={this.search_criteria.batch_type}
                              options={this.options_data.batch_type}
                            />
                          </Grid>
                          <Grid>
                            <DropdownInput
                              label='Date Type'
                              id='date_type'
                              change_handler={(value, event) => {
                                this.dropdown_change_handler(event);
                              }}
                              defaultValue={this.search_criteria.date_type}
                              options={this.options_data.date_type}
                            />
                            <Grid.Column tablet={8} computer={4}>
                              <Form.Field>
                                <label>
                                  Date Range{' '}
                                  {this.state.is_date_required && (
                                    <span
                                      className={
                                        this.state.is_submitted && this.state.is_error_date
                                          ? 'req-alert'
                                          : 'req-alert_normal'
                                      }
                                    >
                                      (required)
                                    </span>
                                  )}
                                </label>
                                <DateRangePickerComponent
                                  updateDatesChange={(from_date, end_date) =>
                                    this.range_date_change_handler(from_date, end_date, 'dos')
                                  }
                                  startDate={this.search_criteria.start}
                                  endDate={this.search_criteria.end}
                                  error={
                                    this.state.is_submitted && this.state.is_date_required && this.state.is_error_date
                                  }
                                  id={'dos'}
                                  maxDate={moment()}
                                />
                              </Form.Field>
                            </Grid.Column>
                            <DropdownInput
                              label='Status'
                              id='run_status'
                              change_handler={(value, event) => {
                                this.dropdown_change_handler(event);
                              }}
                              defaultValue={this.search_criteria.run_status}
                              options={this.options_data.run_status_type}
                            />
                            {this.search_criteria.batch_type !== '3' && (
                              <React.Fragment>
                                <Grid.Column tablet={8} computer={4}>
                                  <Form.Field>
                                    <label>Set ID</label>
                                    <Input
                                      autoComplete='off'
                                      id='set_id'
                                      name='set_id'
                                      pattern='[0-9]*'
                                      onChange={(e) => this.input_change_handler(e)}
                                      value={this.search_criteria['set_id'] ? this.search_criteria['set_id'] : ''}
                                      type='text'
                                    />
                                  </Form.Field>
                                </Grid.Column>
                                <Grid.Column tablet={8} computer={4}>
                                  <Form.Field>
                                    <label>Run Number</label>
                                    <Input
                                      autoComplete='off'
                                      id='run_number'
                                      name='run_number'
                                      pattern='[0-9]*'
                                      onChange={(e) => this.input_change_handler(e)}
                                      value={
                                        this.search_criteria['run_number'] ? this.search_criteria['run_number'] : ''
                                      }
                                      type='text'
                                    />
                                  </Form.Field>
                                </Grid.Column>
                              </React.Fragment>
                            )}
                          </Grid>
                        </Accordion.Content>
                      </Accordion>
                    </Grid.Column>
                  </Grid>
                  <Grid>
                    {(this.props.BatchClaimsProjectFFVal && check_internal_staff()) && (
                    <Grid.Column tablet={8} computer={8} textAlign='left'>
                      <Button
                        type='button'
                        id='clear_cache_button'
                        basic
                        onClick={this.clear_cache_handler}
                        disabled={this.state.is_clear_cache_disabled}
                        content={'Clear Cache'}
                      />
                      {this.state.is_cache_flush_success && (
                        <span>Queued</span>
                      )}
                      {!this.state.is_cache_flush_success && (
                        <span>Last Updated: {dateTimeFormatter(this.cache_last_updated_date)}</span>
                      )}
                    </Grid.Column>
                    )}
                    <Grid.Column 
                      tablet={(!this.props.BatchClaimsProjectFFVal || !check_internal_staff()) ? 16 : 8} 
                      computer={(!this.props.BatchClaimsProjectFFVal || !check_internal_staff()) ? 16 : 8} 
                      textAlign='right'>
                      <Button
                        type='button'
                        id='clear_report_button'
                        basic
                        onClick={this.clear_handler}
                        disabled={this.state.is_clear_disabled}
                        content={'Clear'}
                      />
                      <Button
                        type='button'
                        id='new_scheduled_button'
                        basic
                        onClick={this.create_batch_billing}
                        disabled={this.state.is_new_disabled}
                        content={'New'}
                      />
                      <Button
                        id='create_report_button'
                        className='primary'
                        type='submit'
                        onKeyDown={this.handle_navigation_after_last_element}
                        style={{ marginRight: '0' }}
                        disabled={this.state.is_search_disabled}
                        content={'Search'}
                      />
                    </Grid.Column>
                  </Grid>
                </Form>
              </div>
              {this.state.is_grid_visible && (
                <div id='report-scrollable-area' className='report-wrapper'>
                  <GridView
                    id={this.batch_billing.report_grid_id}
                    row={this.report_data.rows}
                    column={this.report_data.cols}
                    wrapperClass={'grid_wrapper paginated_grid'}
                    style={{ height: '100%', display: 'flex', flexFlow: 'column' }}
                    wrapperStyle={{ width: '100%', height: '100%', display: 'flex' }}
                    headerHeight={this.grid_header_height}
                    suppressMovableColumns={true}
                    suppressColumnVirtualisation={true}
                    enableColResize={true}
                    emptyMessage={messages.no_records_found}
                    get_grid_ref={this.get_grid_ref}
                    headerIdForTabNavigation={report_ledger_full_configuration_header_id}
                    suppressSizeToFit={true}
                    rowGroupPanelShow={!this.is_scheduled_run}
                    on_filter_button_click={() => {
                      this.on_filter_button_click();
                    }}
                    isPagination={true}
                    gridAutoHeight={true}
                    paginationPageSize={20}
                    checkboxSelection={true}
                    onRowSelection={this.on_check_box_selection}
                    isRowSelectable={this.is_row_selectable}
                    onGroupByChange={this.on_group_by_Change}
                  />
                </div>
              )}
            </div>
            {this.state.is_grid_visible && (
              <div
                className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                id='applicationFooterSticky'
                style={{ paddingRight: 15 }}
              >
                <Grid style={{ marginTop: 0, marginBottom: 0 }}>
                  <Grid.Column computer={16} tablet={16} textAlign='right'>
                    {this.is_scheduled_run ? (
                      <Button
                        id='rerun'
                        className='primary'
                        onClick={this.delete_schedule}
                        type='button'
                        onKeyDown={set_focus_to_app_header}
                        style={{ marginRight: '0' }}
                        disabled={this.state.is_delete_disabled}
                        content={'Delete'}
                      />
                    ) : (
                      <React.Fragment>
                        <Button
                          id='cancel'
                          type='button'
                          onClick={() => this.batch_billing_change_status(this.batch_billing.format_run_status.cancel)}
                          basic
                          disabled={this.state.is_cancel_disabled}
                          content={'Cancel'}
                        />
                        <Button
                          id='release'
                          type='button'
                          onClick={() => this.batch_billing_change_status(this.batch_billing.format_run_status.onhold)}
                          basic
                          disabled={this.state.is_release_disabled}
                          content={'Release'}
                        />
                        <Button
                          id='hold'
                          type='button'
                          onClick={() => this.batch_billing_change_status(this.batch_billing.format_run_status.release)}
                          disabled={this.state.is_hold_disabled}
                          basic
                          content={'Hold'}
                        />
                        <Button
                          id='rerun'
                          className='primary'
                          onClick={() => this.batch_billing_change_status(this.batch_billing.format_run_status.rerun)}
                          type='button'
                          onKeyDown={set_focus_to_app_header}
                          style={{ marginRight: '0' }}
                          disabled={this.state.is_rerun_disabled}
                          content={'Rerun'}
                        />
                      </React.Fragment>
                    )}
                  </Grid.Column>
                </Grid>
              </div>
            )}
          </div>
        </div>
      </LoaderComponent>
    );
  }
}

// Get user and login details from store.
const mapStateToProps = (state) => {
  return {
    user_login_details: state.user_login_details,
    selected_patient: state.patient_details.patient_header,
    is_expand: state.shared_details.is_expand,
    rsiB31486AddCompanyMultiSelectDropDownToPayersPage: get_lauch_darkley_key_value(state.launch_darkly, "rsiB31486AddCompanyMultiSelectDropDownToPayersPage"),
    BatchClaimsProjectFFVal: get_lauch_darkley_key_value(state.launch_darkly, BatchClaimsFeatures.projectFlag),
	  testOutputSearchGridFFVal: get_lauch_darkley_key_value(state.launch_darkly, BatchClaimsFeatures.projectFlag),
	  rsiBill393UiBillingFailedCharges: get_lauch_darkley_key_value(state.launch_darkly, FFConstant.rsi_bill_393_ui_billing_failed_charges)
  };
};

export default connect(mapStateToProps)(BatchBillingComponent);

export enum GridConstants {
	BLANK = '',
	IN_PROGRESS_CORRECT = 'In Progress',
	IN_PROGRESS_INCORRECT = 'InProgress',
	IS_PENDING = 'isPending',
	IS_TEST = 'isTest',
	LIVE = 'Live',
	NO = 'No',
	PRINT_STATUS = 'print_status',
	RUN_STATUS = 'run_status',
	STATUS_UPDATE = 'status_update',
	TEST = 'Test',
	TRUE = 'True',
	YES = 'Yes'
}
