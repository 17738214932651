import * as React from "react";
import { connect } from 'react-redux';
import { Checkbox } from 'semantic-ui-react';
import { IDropDownDataDto } from './../../global_constants';
import { custom_date_format, currency_formatter, get_lauch_darkley_key_value} from '../../shared/utility';
import { date_format, eob_claim_status_code } from '../../global_constants';
import Selection from '../../shared/component/selection_component';
import { get_release_835_eob_patient_claim_details } from '../action/payment_action';
import { toastr } from 'react-redux-toastr';
import { IEOB835ReleaseDetailCardClaimDto, EobReleaseLineItemType, icn_data_session_key_name_list, epr_patient_balance } from '../payment_constants';
import CurrencyFormatter from '../../shared/component/currency_formatter';
import * as session_storage from "../../shared/session_storage_utility";
import { e835_partial_patient_adjustment } from "../../feature_flip_constant";

interface IReleaseEobPatientCardDetailProps {
    eob_key: number,
    patient_id: number,
    company_id: number,
    clinic_code: string,
    case_id: number,
    update_error_info: any, //call back
    save_edit: any, //call back method
    save_group_edit: any, // call back with charge group update.
    user_login_details: any, // from redux store
    payment_adjustment: any, // from redux store
    patient_has_sticky_notes: boolean,
    excludedNote: boolean,
    features:any // LD key value pair
}

interface IReleaseEobPatientCardDetailState {
    is_loading: {
        claim: boolean,
        adj_qual: boolean,
        reason_code: boolean
    },
    claim_detail: IReleaseEobPatientCardDetail,
    new_row_id: number;
}

interface IReleaseEobPatientCardDetail {

    patient_id: number,
    eob_key: number
    claim_detail_list: Array<IReleaseEobPatientCardClaimDetail>
}

interface IReleaseEobPatientCardClaimDetail {

    claim_icn: string,
    claim_dos_list: Array<IReleaseEobPatientCardClaimDetailDos>
    is_expanded: boolean

}

interface IReleaseEobPatientCardClaimDetailDos {
    claim_icn: string,
    date_of_service: Date,
    charge_list: Array<IReleaseEobPatientCardClaimCharge>
}

interface IReleaseEobPatientCardClaimCharge {
    charge_id: number,
    cpt_code: string
    units: number,
    modifier: string,
    billed_amount: number,
    allowed_amount: number,
    expected_amount: number,
    paid_amount: number,
    adjustment_amount: number,
    adjustment_qualifier_code: string,
    reason_code: string,
    balance_amount: number,
    claim_status_code: number,
    insurance_balance_id: number,
    patient_balance_id: number,
    patient_balance_adjustment_id: number,
    id: number,
    service_id: number,
    group_id: number,
    sequence: number,
    is_primary: number,
    validation_message: string,
    exclude_error: boolean,
    is_added: boolean,
    is_removed: boolean,
    clinic_code: string,
    case_id: number,
    check_number: string,
    //transactional properties
    worked: boolean,
    display_row_id: number,
    max_patient_balance: number
}



export class Release835sPatientCardDetail extends React.Component<IReleaseEobPatientCardDetailProps, IReleaseEobPatientCardDetailState>
{
    // #region Class Variable declaration
    _is_mounted: boolean
    _claim_data_from_server: Array<IEOB835ReleaseDetailCardClaimDto>
    // #endregion


    constructor(props) {
        super(props);
        this._is_mounted = false;

        this.state = {
            is_loading: {
                claim: false,
                adj_qual: false,
                reason_code: false
            },
            claim_detail: null,
            new_row_id: 0
        }
    }

    // #region Life Cycle methods
    componentDidMount() {
        this._is_mounted = true;
        document.body.classList.add('admin-framework');
        this.get_patient_claim_details();
    }

    componentWillUnmount = () => {
        this._is_mounted = false;
        document.body.classList.remove('admin-framework');
    }
    // #endregion Life Cycle methods


    // #region Data Fetch Methods
    get_patient_claim_details = async () => {
        
        // #region check for session data before server fetch
        let session_data = session_storage.get(this.get_session_key());
        if (session_data && session_data.server_data && session_data.display_data) {
            this._claim_data_from_server = session_data.server_data;
            this.setState({ claim_detail: session_data.display_data, new_row_id: this.get_new_row_id(this._claim_data_from_server) });
            //BILL-1647
            this.update_server_claim_objects(session_data.display_data.claim_detail_list);
            return;
        }
        // #endregion check for session data before server fetch

        // #region Server Fetch
        let token = this.props.user_login_details.user_data.data.token_details.access_token;
        let is_loading = this.state.is_loading;
        let claim_data: IReleaseEobPatientCardDetail
        is_loading.claim = true;
        this.setState({ is_loading });
        await get_release_835_eob_patient_claim_details(token, this.props.eob_key, this.props.patient_id, this.props.company_id, this.props.case_id).then(
            (response) => {
                if (response && response.data && response.data.data) {
                    this._claim_data_from_server = this.set_epr_on_charge_line_items_from_primary_line_server_data(response.data.data);
                    claim_data = this.transform_claim_card_data_for_display(this._claim_data_from_server);
                    is_loading.claim = false;
                    this.setState({ is_loading: is_loading, claim_detail: claim_data, new_row_id: this.get_new_row_id(this._claim_data_from_server) });
                    //BILL-1647
                    this.update_server_claim_objects(claim_data.claim_detail_list);
                }
                else {
                    is_loading.claim = false;
                    this.setState({ is_loading: is_loading, claim_detail: null });
                }

            },
            (error) => {
                is_loading.claim = false;
                this.setState({ is_loading: is_loading, claim_detail: null });
                toastr.error('', 'Error fetching data.');
            }

        );
        // #endregion Server Fetch
    }

    update_server_claim_objects = (claim_detail_list) => {
        //BILL-1647
        claim_detail_list.map(claim => {
            claim.claim_dos_list.map(dos => {
                dos.charge_list.map(charge => {
                    let server_claim_object: IEOB835ReleaseDetailCardClaimDto = null;
                    server_claim_object = this.get_claim_object_from_server_data(charge.display_row_id);
                    if (server_claim_object.is_primary && server_claim_object.patient_balance_id == 0 && server_claim_object.patient_balance_adjustment_id == 0) {
                        this.props.save_edit(server_claim_object);
                    }
                });
            });
        });
    }

    get_adjustment_qualifier_options(adj_code): Array<IDropDownDataDto> {
        let options = this.props.payment_adjustment.adjustment_qualifier.adjustment_qualifier.data.map(a => {
            return { key: a, text: a, value: a }
        })
        let na = options.filter(option => {
            if (option.key === 'NA') return option;
        });
        options = options.filter(option => {
            if (option.key !== 'NA') return option;
        });
        if (na.length > 0) {
            options.unshift(na[0]);
        }
        if (adj_code != 'PR') {
            options = options.filter(option => option.key !== 'PR')
        }
        return options;
    }

    get_adjustment_reason_options(): Array<IDropDownDataDto> {
        let options = this.props.payment_adjustment.reason_code.adjustment_reason.map(a => {
            return { key: a.id, text: a.adjustment_reason, value: a.id }
        })
        options.sort((a, b) => (a.value > b.value) ? 1 : -1);
        return options;
    }

    get_insurance_balance_options(): Array<IDropDownDataDto> {

        let option1: IDropDownDataDto = { key: '0', text: 'Keep Current', value: '1' };
        let option2: IDropDownDataDto = { key: '1', text: 'Transfer to Next', value: '0' };
        let ins_dd_options: Array<IDropDownDataDto> = [option1, option2];
        return ins_dd_options;
    }

    get_patient_balance_options(): Array<IDropDownDataDto> {
        let option1: IDropDownDataDto = { key: '0', text: 'Keep', value: '1' };
        let option2: IDropDownDataDto = { key: '1', text: 'Adjust', value: '0' };
        let ins_dd_options: Array<IDropDownDataDto> = [option1, option2];
        return ins_dd_options;
    }

    get_patient_balance_adjustment_code_options(): Array<IDropDownDataDto> {
        let defaultValue = { key: 0, text: " - ", value: 0 };
        const activeAdjustmentCodes = this.props.payment_adjustment.adjustment_code.adjustment_code.filter(code => code.is_active);

        let codes = this.sort_codes(activeAdjustmentCodes);
        let dropdownData = codes.map(a => {
            return { key: a.adjustment_code_id, text: a.adjustment_code + " - " + a.adjustment_desc, value: a.adjustment_code_id }
        });
        dropdownData.unshift(defaultValue);

        return dropdownData;
    }

    sort_codes = (data) => {
        data = data.sort((obj1, obj2) => {
            var nameA = obj1.adjustment_code.toLowerCase(),
                nameB = obj2.adjustment_code.toLowerCase();
            if (nameA < nameB)
                //sort string ascending
                return -1;
            if (nameA > nameB) return 1;
            return 0;
        });
        return data;
    }

    get_claim_status_code_description = (claim_status_code_id: number) => {
        if (claim_status_code_id == null) return '';
        let description = eob_claim_status_code[claim_status_code_id.toString()];
        return description == null || undefined ? 'Invalid Code' : description;
    }
    // #endregion Data Fetch Methods


    // #region action method
    get_new_row_id = (claims: Array<IEOB835ReleaseDetailCardClaimDto>) => {
        
        let current_max_row_id: number = 0
        
        if (claims == null || claims == undefined) {
            current_max_row_id = 0;
        } 
        else {
            var sortedClaims = claims.sort((a, b) => {
               return a.display_row_id > b.display_row_id ? 1 : -1;
            });

            if (sortedClaims && sortedClaims.length >= 1) current_max_row_id = sortedClaims[sortedClaims.length - 1].display_row_id;
        }
        
        return current_max_row_id + 1;
    }

    get_next_sequence = (charge_list: IReleaseEobPatientCardClaimCharge[], cpt: string) => {
        let nextSequence = 0;

        charge_list.map(charge => {
            if (charge.cpt_code == cpt) {
                if (nextSequence <= charge.sequence) {
                    nextSequence = charge.sequence;
                }
            }
        });

        return nextSequence + 1;
    }

    get_primary_line_payer_rules = (charge_list: IReleaseEobPatientCardClaimCharge[], cpt: string) => {
        let primary_charge: IReleaseEobPatientCardClaimCharge = null;
        charge_list.map(charge => {
            if (charge.cpt_code == cpt) {
                if (charge.is_primary == 1) {
                    primary_charge = charge;
                }
            }
        });

        return primary_charge;
    }

    resort_claim_details = (a: IReleaseEobPatientCardClaimCharge, b: IReleaseEobPatientCardClaimCharge) => {
        if (a.cpt_code < b.cpt_code) return -1;
        if (a.cpt_code > b.cpt_code) return 1;

        if (a.group_id < b.group_id) return -1;
        if (a.group_id > b.group_id) return 1;

        if (a.service_id < b.service_id) return -1;
        if (a.service_id > b.service_id) return 1;

        if (a.sequence < b.sequence) return -1;
        if (a.sequence > b.sequence) return 1;

        if (a.display_row_id < b.display_row_id) return -1;
        if (a.display_row_id > b.display_row_id) return 1;

        return 0;
    }


    toggle_claim_detail = (claim_icn: string) => {

        let claim = this.state.claim_detail;

        if (claim == null || claim.claim_detail_list == null || claim.claim_detail_list.length == 0) return;

        claim.claim_detail_list.map(detail => {

            if (detail.claim_icn === claim_icn) detail.is_expanded = !detail.is_expanded;
        });
        this.set_data_in_session();
        this.setState({ claim_detail: claim });
    }


    //String type Value
    on_change_string_value_in_dd = (field_name: string, claim_icn: string, display_row_id: number, dos: Date, value: string) => {
        let claim = this.state.claim_detail;
        let server_claim_object: IEOB835ReleaseDetailCardClaimDto = null;
        let is_update_state = false;

        if (claim == null || claim.claim_detail_list == null || claim.claim_detail_list.length == 0) return;

        claim.claim_detail_list.map(detail => {

            if (detail.claim_icn === claim_icn) {
                detail.claim_dos_list.map(dos_detail => {
                    if (dos_detail.date_of_service === dos) {
                        dos_detail.charge_list.map(charge => {
                            if (charge.display_row_id === display_row_id) {
                                if (value != charge[field_name]) {
                                    charge[field_name] = value;
                                    server_claim_object = this.get_claim_object_from_server_data(display_row_id);
                                    is_update_state = true;
                                }
                            }
                        });

                    }

                });


            }
        });
        
        if (is_update_state) {
            this.setState({ claim_detail: claim });
            if (server_claim_object != null) {
                server_claim_object[field_name] = value;
                this.props.save_edit(server_claim_object);
            }
        }
        this.set_data_in_session();
    }

    //Int type Value
    on_change_int_value_in_dd = (field_name: string, claim_icn: string, display_row_id: number, dos: Date, value: string) => {
        let claim = this.state.claim_detail;
        let server_claim_object: IEOB835ReleaseDetailCardClaimDto = null;
        let is_update_state = false;

        if (claim == null || claim.claim_detail_list == null || claim.claim_detail_list.length == 0) return;

        claim.claim_detail_list.map(detail => {

            if (detail.claim_icn === claim_icn) {
                detail.claim_dos_list.map(dos_detail => {
                    if (dos_detail.date_of_service === dos) {
                        dos_detail.charge_list.map(charge => {
                            if (charge.display_row_id === display_row_id) {
                                if (parseInt(value) != charge[field_name]) {
                                    charge[field_name] = parseInt(value);
                                    // check if adj code needs to be cleared
                                    if (charge.patient_balance_id == 1) {
                                        charge.patient_balance_adjustment_id = 0;
                                    }
                                    server_claim_object = this.get_claim_object_from_server_data(display_row_id);
                                    is_update_state = true;
                                }
                            }
                        });

                    }

                });


            }
        });

        if (is_update_state) {
            this.setState({ claim_detail: claim });
            if (server_claim_object != null) {
                server_claim_object[field_name] = parseInt(value);
                // check if adj code needs to be cleared
                if (server_claim_object.patient_balance_id == 1) {
                    server_claim_object.patient_balance_adjustment_id = 0;
                }
                this.props.save_edit(server_claim_object);
            }
        }
        this.set_data_in_session()
    }

    update_charge_group_on_change_int_value_in_dd = (field_name: string, claim_icn: string, dos: Date, cpt: string, group_id:number, service_id:number, value: string) => {
        let claim = this.state.claim_detail;
        let server_claim_object_array: Array<IEOB835ReleaseDetailCardClaimDto> = [];
        let server_claim_object: IEOB835ReleaseDetailCardClaimDto = null;
        let is_update_state = false;
        cpt = cpt != undefined && cpt != null ? cpt.trim() : '';

        if (claim == null || claim.claim_detail_list == null || claim.claim_detail_list.length == 0) return;

        claim.claim_detail_list.map(detail => {

            if (detail.claim_icn === claim_icn) {
                detail.claim_dos_list.map(dos_detail => {
                    if (dos_detail.date_of_service === dos) {
                            dos_detail.charge_list.map(charge => {
                            if (charge.group_id == group_id && charge.service_id == service_id && charge.cpt_code === cpt) {
                                if (parseInt(value) != charge[field_name]) {
                                    charge[field_name] = parseInt(value);
                                    // check if adj code needs to be cleared
                                    if (charge.patient_balance_id == epr_patient_balance.keep) {
                                        charge.patient_balance_adjustment_id = 0;
                                        charge.max_patient_balance = null;
                                    }
                                    else if (this.props.features.e835_partial_patient_adjustment &&
                                             charge.patient_balance_id == epr_patient_balance.adjust &&
                                             charge.max_patient_balance === null)
                                    {//Default Maximum Patient Balance = 0
                                        charge.max_patient_balance = 0.00;
                                    }
                                    
                                    server_claim_object = this.get_claim_object_from_server_data(charge.display_row_id);
                                    if (server_claim_object != null) {
                                        server_claim_object.patient_balance_id = charge.patient_balance_id;
                                        server_claim_object.max_patient_balance = charge.max_patient_balance
                                        server_claim_object.patient_balance_adjustment_id = charge.patient_balance_adjustment_id;
                                        server_claim_object[field_name] = parseInt(value);
                                        server_claim_object_array.push(server_claim_object);

                                    }
                                    is_update_state = true;
                                }
                            }
                        });

                    }

                });
            }
        });

        if (is_update_state) {
            this.setState({ claim_detail: claim });
            if (server_claim_object_array.length > 0 ) {
                this.props.save_group_edit(server_claim_object_array);
            }
        }
        this.set_data_in_session()
    }

    on_change_text_box = (field_name: string, claim_icn: string, display_row_id: number, dos: Date, e) => {
        let claim = this.state.claim_detail;
        let server_claim_object: IEOB835ReleaseDetailCardClaimDto = null;

        let is_update_state = false;
        if (claim == null || claim.claim_detail_list == null || claim.claim_detail_list.length == 0) return;

        let incoming_value = e;
        incoming_value = ('' + incoming_value).replace(/[$]/g, '');
        incoming_value = isNaN(parseFloat(incoming_value)) ? 0 : parseFloat(incoming_value);

        claim.claim_detail_list.map(detail => {

            if (detail.claim_icn === claim_icn) {

                detail.claim_dos_list.map(dos_detail => {
                    if (dos_detail.date_of_service === dos) {
                        dos_detail.charge_list.map(charge => {
                            if (charge.display_row_id === display_row_id) {
                                if (charge[field_name] != incoming_value) {
                                    charge[field_name] = incoming_value;
                                    is_update_state = true;
                                    server_claim_object = this.get_claim_object_from_server_data(display_row_id);
                                }
                            }
                        });

                    }

                });
            }
        });

        if (is_update_state) {
            this.setState({ claim_detail: claim });
            if (server_claim_object != null) {
                server_claim_object[field_name] = incoming_value;
                //reset required edited fields of server_claim_object if adjustment_amount set to 0
                if (field_name == 'adjustment_amount' && incoming_value == 0 && (server_claim_object['paid_amount'] == 0 && server_claim_object['is_added'] == true)) {
                    server_claim_object['adjustment_qualifier_code'] = 'NA';
                    server_claim_object['reason_code'] = '0';
                }
                this.props.save_edit(server_claim_object);

            }
        }
        this.set_data_in_session();
    }

    update_charge_group_on_change_max_patient_balance = (field_name: string, claim_icn: string, dos: Date, cpt: string, group_id: number, service_id: number, value: string) => {
        if (!this.props.features.e835_partial_patient_adjustment) return;
        let claim = this.state.claim_detail;
        let server_claim_object_array: Array<IEOB835ReleaseDetailCardClaimDto> = [];
        let server_claim_object: IEOB835ReleaseDetailCardClaimDto = null;
        let is_update_state = false;
        cpt = cpt != undefined && cpt != null ? cpt.trim() : '';

        if (claim == null || claim.claim_detail_list == null || claim.claim_detail_list.length == 0) return;

        claim.claim_detail_list.map(detail => {

            if (detail.claim_icn === claim_icn) {
                
                detail.claim_dos_list.map(dos_detail => {
                    if (dos_detail.date_of_service === dos) {
                        
                        dos_detail.charge_list.map(charge => {
                            if (charge.group_id == group_id && charge.service_id == service_id && charge.cpt_code === cpt) {
                                
                                let parsedValue: number = null;
                                if (charge.patient_balance_id == epr_patient_balance.adjust) parsedValue = 0;
                                if (!isNaN(parseInt(value))) parsedValue = parseInt(value);
                                
                                if (parsedValue != charge[field_name]) {
                                    charge[field_name] = parsedValue;
                                    server_claim_object = this.get_claim_object_from_server_data(charge.display_row_id);
                                    if (server_claim_object != null) {
                                        server_claim_object[field_name] = parsedValue;
                                        server_claim_object_array.push(server_claim_object);
                                    }
                                    is_update_state = true;
                                }
                            }
                        });

                    }

                });


            }
        });

        if (is_update_state) {
            this.setState({ claim_detail: claim });
            if (server_claim_object_array.length > 0) {
                this.props.save_group_edit(server_claim_object_array);
            }
        }
        this.set_data_in_session()
    }

    on_change_checkbox = (field_name: string, claim_icn: string, dos: Date, cpt: string, group_id: number, charge_id: number, service_id: number, event: any) => {
        let claim = this.state.claim_detail;
        if (claim == null || claim.claim_detail_list == null || claim.claim_detail_list.length == 0) return;

        let { checked } = event.target;
        let server_claim_object_array: Array<IEOB835ReleaseDetailCardClaimDto> = [];
        let server_claim_object: IEOB835ReleaseDetailCardClaimDto = null;
               

        claim.claim_detail_list.map((detail) => {

            if (detail.claim_icn === claim_icn) {

                detail.claim_dos_list.map(dos_detail => {

                    if (dos_detail.date_of_service === dos) {

                        dos_detail.charge_list.map((charge) => {

                            if (charge.cpt_code === cpt && charge.group_id === group_id && charge.service_id === service_id) {
                                switch (field_name) {
                                    case 'worked':
                                        charge.worked = checked;
                                        charge.exclude_error = false;
                                        break;
                                    case 'exclude_error':
                                        charge.worked = false;
                                        charge.exclude_error = checked;
                                        break;
                                    default:
                                        charge.worked = false;
                                        charge.exclude_error = false;
                                }
                                server_claim_object = this.get_claim_object_from_server_data(charge.display_row_id);
                                if (server_claim_object != null) {
                                    server_claim_object.exclude_error = charge.exclude_error
                                    server_claim_object_array.push(server_claim_object);
                                }; 
                                //update error info
                                if (charge_id == charge.charge_id) {
                                    this.props.update_error_info(claim.patient_id, this.props.case_id, dos_detail.date_of_service, charge.cpt_code, charge.validation_message, charge.worked || charge.exclude_error);
                                }
                            }
                        });
                    }

                });
            }
        });

        this.setState({ claim_detail: claim });

        if (server_claim_object_array != null && server_claim_object_array.length > 0) {
            this.props.save_group_edit(server_claim_object_array);
        }
        
        this.set_data_in_session();
    }

    on_click_remove_row = (claim_icn: string, dos: Date, display_row_id: number) => {
        let claim:IReleaseEobPatientCardDetail = this.state.claim_detail;
        let server_claim_object: IEOB835ReleaseDetailCardClaimDto = null;
        if (claim == null || claim.claim_detail_list == null || claim.claim_detail_list.length == 0) return;
        
        claim.claim_detail_list.map(detail => {
            if (detail.claim_icn === claim_icn) {
                detail.claim_dos_list.map(dos_detail => {
                    if (dos_detail.date_of_service === dos) {
                        dos_detail.charge_list.map(charge => {
                            if (charge.display_row_id === display_row_id) {
                                charge.is_removed = true;
                                charge.is_added = false;
                                server_claim_object = this.get_claim_object_from_server_data(display_row_id);
                                if (server_claim_object != null) {
                                    server_claim_object.is_added = false;
                                    server_claim_object.is_removed = true;
                                }
                            }
                            
                        });
                        
                    }

                });
            }
        });

        this.discard_from_ui_added_line_item_on_remove(claim_icn, dos, display_row_id, claim);
        this.discard_from_server_data_added_line_item_on_remove(display_row_id);

        this.setState({ claim_detail: claim });
        if (server_claim_object != null && this.props.save_edit) {
            this.props.save_edit(server_claim_object);
        }
        this.set_data_in_session();
    }
    

    on_click_add_row = (claim_icn: string, cpt: string, dos: Date, group_id: number, service_id: number, charge_list: IReleaseEobPatientCardClaimCharge[]) => {
        let claim = this.state.claim_detail;
        let server_claim_object: IEOB835ReleaseDetailCardClaimDto = null;
        if (claim == null || claim.claim_detail_list == null || claim.claim_detail_list.length == 0) return;
        let newSequence = this.get_next_sequence(charge_list, cpt);
        let primary_charge = this.get_primary_line_payer_rules(charge_list, cpt);

        let newCharge: IReleaseEobPatientCardClaimCharge = {
            id: 0,
            cpt_code: cpt,
            units: 0,
            modifier: "",
            billed_amount: 0,
            allowed_amount: 0,
            expected_amount: 0,
            paid_amount: 0,
            adjustment_amount: 0,
            adjustment_qualifier_code: "NA",
            reason_code: "0",
            balance_amount: 0,
            claim_status_code: primary_charge.claim_status_code,
            insurance_balance_id: primary_charge.insurance_balance_id,
            patient_balance_id: primary_charge.patient_balance_id,
            patient_balance_adjustment_id: primary_charge.patient_balance_adjustment_id,
            service_id: service_id,
            group_id: group_id,
            sequence: newSequence,
            charge_id: 0,
            is_primary: 0,
            exclude_error: primary_charge.exclude_error,
            validation_message: '',
            worked: primary_charge.worked,
            is_added: true,
            is_removed: false,
            display_row_id: this.state.new_row_id,
            clinic_code: this.props.clinic_code,
            case_id: this.props.case_id,
            check_number: primary_charge.check_number,
            max_patient_balance:primary_charge.max_patient_balance
        };


        claim.claim_detail_list.map(detail => {
            if (detail.claim_icn === claim_icn) {
                detail.claim_dos_list.map(dos_detail => {
                    if (dos_detail.date_of_service === dos) {
                        dos_detail.charge_list.push(newCharge);
                        this.add_or_remove_claim_object_to_server_data({
                            id: 0,
                            eob_key: this.props.eob_key,
                            check_number: primary_charge.check_number,
                            icn: claim_icn,
                            patient_id: this.props.patient_id,
                            date_of_service: new Date(dos),
                            cpt_code: cpt,
                            units: 0,
                            mod: '',
                            billed_amount: 0,
                            allowed_amount: 0,
                            expected_amount: 0,
                            paid_amount: 0,
                            adjustment_amount: 0,
                            adjustment_qualifier_code: 'NA',
                            reason_code: '0',
                            balance_amount: 0,
                            claim_status_code: primary_charge.claim_status_code,
                            insurance_balance_id: primary_charge.insurance_balance_id,
                            patient_balance_id: primary_charge.patient_balance_id,
                            patient_balance_adjustment_id: primary_charge.patient_balance_adjustment_id,
                            service_id: service_id,
                            group_id: group_id,
                            sequence: newSequence,
                            charge_id: 0,
                            is_primary: 0,
                            validation_message: '',
                            exclude_error: primary_charge.exclude_error,
                            is_added: true,
                            is_removed: false,
                            display_row_id: this.state.new_row_id,
                            clinic_code: this.props.clinic_code,
                            case_id: this.props.case_id,
                            max_patient_balance: primary_charge.max_patient_balance
                        });
                        server_claim_object = this.get_claim_object_from_server_data(this.state.new_row_id);
                    }
                    dos_detail.charge_list.sort(this.resort_claim_details);
                });
            }
        });

        this.setState({ claim_detail: claim, new_row_id: this.state.new_row_id + 1 });
        if (server_claim_object != null && this.props.save_edit) {
            this.props.save_edit(server_claim_object);
        }
        this.set_data_in_session();
    }
    // #endregion action methods

    // #region util methods
    transform_claim_card_data_for_display = (calim_card_data_list: Array<IEOB835ReleaseDetailCardClaimDto>): IReleaseEobPatientCardDetail => {

        //Dispay parent object Initialization
        let card_detail: IReleaseEobPatientCardDetail = {
            eob_key: this.props.eob_key,
            patient_id: this.props.patient_id,
            claim_detail_list: []
        };

        //Get the Distinct Claim ICN #
        let unique_icn_array: Array<string> = [];
        calim_card_data_list.map(item => {
            if (unique_icn_array.indexOf(item.icn) == -1)
                unique_icn_array.push(item.icn);
        });

        //Load the ICN in claim_detail_list
        card_detail.claim_detail_list = unique_icn_array.map((icn, index) => {
            return ({
                claim_icn: icn,
                claim_dos_list: [],
                is_expanded: index == 0 ? true : false
            });
        });

        //Get the DOS for a given claim_icn
        unique_icn_array.map(icn => {

            let all_rows_for_icn = calim_card_data_list.filter(row => {
                if (row.icn === icn) return row;
            });

            //Get unique DOS
            let unique_dos_icn_array = [];
            all_rows_for_icn.map(row => {
                if (unique_dos_icn_array.indexOf(new Date(row.date_of_service).setHours(0, 0, 0, 0)) == -1)
                    unique_dos_icn_array.push(new Date(row.date_of_service).setHours(0, 0, 0, 0));
            });

            //Initialize the ICN with list of dos.
            let current_icn = card_detail.claim_detail_list.filter(claim_list => {
                if (claim_list.claim_icn === icn) return claim_list;
            });

            current_icn[0].claim_dos_list = unique_dos_icn_array.map(dos => {
                return ({
                    charge_list: [],
                    claim_icn: icn,
                    date_of_service: dos
                });
            });

        });

        //Initialize the charges for the dos
        card_detail.claim_detail_list.map(claim => {

            claim.claim_dos_list.map(claim_dos => {

                let icn_dos_rows_filtered_from_server: Array<IEOB835ReleaseDetailCardClaimDto> =
                    calim_card_data_list.filter(row => {

                        if (row.icn === claim.claim_icn &&
                            new Date(row.date_of_service).setHours(0, 0, 0, 0)
                            === new Date(claim_dos.date_of_service).setHours(0, 0, 0, 0)) {
                            return row;

                        }
                    });

                let transformed_charges: Array<IReleaseEobPatientCardClaimCharge> =
                    icn_dos_rows_filtered_from_server.map((row) => {
                        let display_row: IReleaseEobPatientCardClaimCharge = {
                            id: row.id,
                            cpt_code: row.cpt_code,
                            units: row.units,
                            modifier: row.mod,
                            billed_amount: row.billed_amount,
                            allowed_amount: row.allowed_amount,
                            expected_amount: row.expected_amount,
                            paid_amount: row.paid_amount,
                            adjustment_amount: row.adjustment_amount,
                            adjustment_qualifier_code: row.adjustment_qualifier_code,
                            reason_code: row.reason_code,
                            balance_amount: row.balance_amount,
                            claim_status_code: row.claim_status_code,
                            insurance_balance_id: row.insurance_balance_id,
                            patient_balance_id: row.patient_balance_id,
                            patient_balance_adjustment_id: row.patient_balance_adjustment_id,
                            service_id: row.service_id,
                            group_id: row.group_id,
                            sequence: row.sequence,
                            charge_id: row.charge_id,
                            is_primary: row.is_primary,
                            validation_message: row.validation_message,
                            exclude_error: row.exclude_error,
                            worked: false,
                            is_added: row.is_added,
                            is_removed: row.is_removed,
                            display_row_id: row.display_row_id,
                            clinic_code: row.clinic_code,
                            case_id: row.case_id,
                            check_number: row.check_number,
                            max_patient_balance: row.max_patient_balance
                        };
                        return display_row;
                    });
                claim_dos.charge_list = transformed_charges;
            });
        });

        return card_detail;
    }

    get_claim_object_from_server_data = (display_row_id: number): IEOB835ReleaseDetailCardClaimDto => {

        let matched_rows: Array<IEOB835ReleaseDetailCardClaimDto> = [];
        let is_match: boolean = false
        matched_rows = this._claim_data_from_server.filter(row => {
            if (row.display_row_id === display_row_id) {
                is_match = true;
                return row;
            }
        });
        return is_match ? matched_rows[0] : null;
    }

    add_or_remove_claim_object_to_server_data = (charge: IEOB835ReleaseDetailCardClaimDto) => {
        this._claim_data_from_server.push(charge);
    }

    get_primary_charge_line_count = (charge_list: Array<IReleaseEobPatientCardClaimCharge>): number => {
        let charge_primary_line_count: number = 0;
        charge_list.map(charge => {
            if (charge.is_primary == EobReleaseLineItemType.primary)
                charge_primary_line_count = charge_primary_line_count + 1;
        });
        return charge_primary_line_count;
    }

    get_session_key = (): string => {
        return this.props.eob_key + '_' + this.props.patient_id + "_" + this.props.case_id + "_" + this.props.company_id;
    }

    set_data_in_session = () => {
        // #region session data
        let keys = session_storage.get(icn_data_session_key_name_list);
        if (keys == null || keys == undefined) keys = [];
        let current_session_key = this.get_session_key();
        if (!keys.includes(current_session_key))keys.push(current_session_key);
        session_storage.set(icn_data_session_key_name_list, keys);
        session_storage.set(current_session_key, { server_data: this._claim_data_from_server, display_data: this.state.claim_detail });
        // #endregion
    }

    set_epr_on_charge_line_items_from_primary_line_server_data = (claim_card_data_list: Array<IEOB835ReleaseDetailCardClaimDto>) : Array<IEOB835ReleaseDetailCardClaimDto> => {

        if (claim_card_data_list == undefined || claim_card_data_list == null || claim_card_data_list.length == 0) return claim_card_data_list;

        claim_card_data_list.map(line_item => {

            if (line_item.is_primary == EobReleaseLineItemType.primary) {
                let non_primary_charge_lines = this.get_server_charge_group_non_primary_lines(claim_card_data_list, line_item.date_of_service, line_item.cpt_code, line_item.group_id, line_item.service_id);
                non_primary_charge_lines.map(non_primary_line => {
                    non_primary_line.patient_balance_adjustment_id = line_item.patient_balance_adjustment_id;
                    non_primary_line.insurance_balance_id = line_item.insurance_balance_id;
                    non_primary_line.patient_balance_id = line_item.patient_balance_id;
                });
            }
        });
        
        return claim_card_data_list;
    }

    get_server_charge_group_non_primary_lines = (claim_card_data_list: Array<IEOB835ReleaseDetailCardClaimDto>, dos: Date, cpt: string, group_id: number, service_id: number) => {

        let non_primary_line_item = claim_card_data_list.filter(line_item => {
            if (
                line_item.is_primary != EobReleaseLineItemType.primary &&
                new Date(line_item.date_of_service).setHours(0, 0, 0, 0) === new Date(dos).setHours(0, 0, 0, 0) &&
                line_item.cpt_code === cpt &&
                line_item.group_id === group_id &&
                line_item.service_id === service_id)
            {
                return line_item;
            }
        });
        
        return non_primary_line_item;
    }

    is_dispaly_add_row = (claim_icn: string, dos: Date, cpt: string, group_id: number, service_id: number) : boolean => {

        let claim = this.state.claim_detail;
        let charge_group_line_item_count = 0;
        
        if (claim == null || claim.claim_detail_list == null || claim.claim_detail_list.length == 0) return false;
        
        claim.claim_detail_list.map(detail => {

            if (detail.claim_icn === claim_icn) {

                detail.claim_dos_list.map(dos_detail => {

                    if (dos_detail.date_of_service === dos) {

                        dos_detail.charge_list.map(charge => {

                            if (charge.cpt_code === cpt && charge.group_id === group_id && charge.service_id === service_id && !charge.is_removed) {
                                charge_group_line_item_count = charge_group_line_item_count + 1;
                            }
                        });

                    }

                });
            }
        });
        
        return (charge_group_line_item_count < 5);
    }

    discard_from_ui_added_line_item_on_remove = (claim_icn: string, dos: Date, display_row_id: number, claim: IReleaseEobPatientCardDetail) =>
    {
        claim.claim_detail_list.map(detail => {
            if (detail.claim_icn === claim_icn) {
                detail.claim_dos_list.map(dos_detail => {
                    if (dos_detail.date_of_service === dos) {
                        let filtered_charges = dos_detail.charge_list.filter(charge => {
                            if (!(charge.display_row_id === display_row_id && charge.id == 0 && charge.is_removed))
                                return charge;
                        });

                        dos_detail.charge_list = filtered_charges;
                    }
                })
            }
        });

    }

    discard_from_server_data_added_line_item_on_remove = (display_row_id:number) =>
    {
        this._claim_data_from_server = this._claim_data_from_server.filter(row => {
            if (!(row.display_row_id === display_row_id && row.id == 0 && row.is_removed)) {
                return row;
            }
        });
    }
    // #endregion util methods


    // #region render help methods
    get_claim_component_collapsible_for_render = () => {

        if (this.state.claim_detail == null || this.state.claim_detail.claim_detail_list == null || this.state.claim_detail.claim_detail_list.length == 0) return (<></>);
        return this.state.claim_detail.claim_detail_list.map((claim_detail, index) => {
                        return (
                            <div className="accordion ui fluid styled common-accordion" key={index}>
                                <div className='title active icn'>
                                    <i onClick={() => this.toggle_claim_detail(claim_detail.claim_icn)} tabIndex={0} aria-hidden={!(claim_detail.is_expanded)}
                                        className={claim_detail.is_expanded ? "angle up icon" : "angle down icon"}>
                                    </i>
                                    <span id={'e835s_ICN_' + index}>{'Claim ICN: ' + claim_detail.claim_icn}</span>
                                </div>
                                {
                                    claim_detail.is_expanded &&
                                    this.get_dos_display_for_icn(claim_detail.claim_dos_list)
                                }
                                {
                                    claim_detail.is_expanded &&
                                    <div className='title active icn aggregate'>
                                        {this.get_claim_box_footer(claim_detail)}
                                    </div>
                                }

                            </div>
                        );
                    })
    }

    get_dos_display_for_icn = (claim_dos_list: Array<IReleaseEobPatientCardClaimDetailDos>) => {

        return claim_dos_list.map((dos_details, index) => {
            return (
                <div className='content active' key={index} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                    <div className='dos-subrow'>
                        <span style={{ fontWeight: 'bold' }}>Date of Service:</span>
                        <span> {' ' + custom_date_format(dos_details.date_of_service, date_format["mm/dd/yyyy"])}</span>
                    </div>
                    <div style={{ overflowX: 'auto' }}>
                        <table className="ui table charges-table" style={{ borderCollapse: 'collapse' }}>
                            <thead>
                                {this.get_claim_detail_table_header()}
                            </thead>
                            <tbody>
                                {this.get_claim_detail_table_rows(dos_details)}
                            </tbody>
                            <tfoot>
                                {this.get_claim_table_footer(dos_details)}
                            </tfoot>
                        </table>
                    </div>
                </div>
            );
        })
    }

    get_claim_detail_table_header = () => {
        return (
            <tr>
                <th style={{ textAlign: 'left', wordWrap: 'break-word', whiteSpace: 'normal' }}>CPT Code</th>
                <th style={{ textAlign: 'left', wordWrap: 'break-word', whiteSpace: 'normal' }}>Charge ID</th>
                <th style={{ textAlign: 'left', wordWrap: 'break-word', whiteSpace: 'normal' }}>Units</th>
                <th style={{ textAlign: 'left', wordWrap: 'break-word', whiteSpace: 'normal' }}>Modifier</th>
                <th style={{ textAlign: 'right', wordWrap: 'break-word', whiteSpace: 'normal' }}>Billed</th>
                <th style={{ textAlign: 'right', wordWrap: 'break-word', whiteSpace: 'normal' }}>Current Balance</th>
                <th style={{ textAlign: 'right', wordWrap: 'break-word', whiteSpace: 'normal' }}>Allowed</th>
                <th style={{ textAlign: 'right', wordWrap: 'break-word', whiteSpace: 'normal' }}>Expected</th>
                <th></th>
                <th></th>
                <th style={{ textAlign: 'right', wordWrap: 'break-word', whiteSpace: 'normal' }}>Paid</th>
                <th style={{ textAlign: 'right', wordWrap: 'break-word', whiteSpace: 'normal' }}>Adjustment</th>
                <th style={{ textAlign: 'left', wordWrap: 'break-word', whiteSpace: 'normal' }}>Adjustment Qualifier</th>
                <th style={{ textAlign: 'left', wordWrap: 'break-word', whiteSpace: 'normal' }}>Reason</th>
                <th style={{ textAlign: 'left', wordWrap: 'break-word', whiteSpace: 'normal' }}>Claim Status</th>
                <th style={{ textAlign: 'left', wordWrap: 'break-word', whiteSpace: 'normal' }}>Insurance Balance</th>
                <th style={{ textAlign: 'left', wordWrap: 'break-word', whiteSpace: 'normal' }}>Patient Balance</th>
                <th style={{ textAlign: 'left', wordWrap: 'break-word', whiteSpace: 'normal' }}>Patient Balance Adjustment Code</th>
                {
                    this.props.features.e835_partial_patient_adjustment &&
                    <th style={{ textAlign: 'right', wordWrap: 'break-word', whiteSpace: 'normal' }}>Max Patient Balance</th>
                }
            </tr>
        );
    }



    get_claim_detail_table_rows = (claim_detail_dos: IReleaseEobPatientCardClaimDetailDos) => {
        let current_cpt_code = '';
        let current_group_id = -1;
        let current_service_id = -1;
        let background_colors: Array<string> = ['#e9f1f4', '#ffffff'];
        let borderTopStyle = '';
        let current_row_background = '';
        let hasError = false;
        
        let rows = claim_detail_dos.charge_list.map((charge, index) => {

            if (charge.is_removed == true) return;

            if ((current_cpt_code === '' && current_group_id == -1 && current_service_id == -1) ||
                ((current_cpt_code.trim() != charge.cpt_code.trim() || (current_group_id != charge.group_id || current_service_id != charge.service_id)))
            ) {

                current_row_background = (current_row_background == '' || current_row_background === background_colors[1]) ? background_colors[0] : background_colors[1];
                current_cpt_code = charge.cpt_code;
                current_service_id = charge.service_id;
                current_group_id = charge.group_id;
            }

            let primary = charge.is_primary == EobReleaseLineItemType.primary;
            borderTopStyle = primary ? '' : 'hidden';

            let isExpectedRateGreater = charge.expected_amount > charge.allowed_amount;

            // since the error validation message is not guarenteed to be in the primary object, get the error from the cpt batch
            // if it exists and assigne the error back to the validation message field to ensure when it renders that it always 
            // displays the error on the primary line
            let checkErrors = claim_detail_dos.charge_list.filter(f => f.cpt_code == current_cpt_code && f.group_id == current_group_id && f.service_id == current_service_id && f.validation_message !== '');
            if (checkErrors.length > 0) {
                hasError = true;
                if (primary) {
                    charge.validation_message = checkErrors[0].validation_message;
                }
            } else {
                hasError = false;
            }

            let hide_PR_EditableFields = (charge.adjustment_qualifier_code.trim() === 'PR');

            let isDisabledField = (this.props.patient_has_sticky_notes && !this.props.excludedNote) ? true : false;

            if (charge.adjustment_amount === 0 && !hide_PR_EditableFields && (charge.paid_amount === 0 && charge.is_added)) {
                charge.adjustment_qualifier_code = 'NA';
                charge.reason_code = '0';
            }

            if (charge.adjustment_amount === 0 && !hide_PR_EditableFields && (charge.adjustment_amount === 0 && !charge.is_added)) {
                charge.adjustment_qualifier_code = 'NA';
                charge.reason_code = '0';
            }

            if (charge.adjustment_qualifier_code == '') {
                charge.adjustment_qualifier_code = 'NA';
            }
            if (charge.reason_code == '') {
                charge.reason_code = '0';
            }

            let adjustment_qualifier_and_reason_required_style = (isDisabledField || hide_PR_EditableFields || (charge.adjustment_amount === 0 && charge.is_added)) && 'disable-field';
            if (((charge.paid_amount === 0 && charge.is_added) && charge.adjustment_amount === 0 && !hide_PR_EditableFields)) adjustment_qualifier_and_reason_required_style = 'disable-required-field';

            return (
                <React.Fragment key={index}>
                    {primary && hasError &&
                        <tr className={hasError ? (charge.exclude_error || charge.worked) ? 'table_error_exculded_row_notice_background' : 'table_error_row_notice_background' : ''}>
                            <td colSpan={this.props.features.e835_partial_patient_adjustment? 19: 18}>
                                <div>
                                    <div>
                                        <div style={{ display: 'flex' }}>
                                            <div className={(charge.exclude_error || charge.worked) ? 'sticky-note sticky-note-exclude table_row_sticky_note_error' : "sticky-note sticky-note-error table_row_sticky_note_error"} style={{ width: 'auto' }}>{charge.validation_message}</div>
                                            <Checkbox
                                                id={'worked_' + charge.display_row_id + '_' + this.props.case_id}
                                                checked={charge.worked}
                                                onChange={(e) => this.on_change_checkbox('worked', claim_detail_dos.claim_icn, claim_detail_dos.date_of_service, charge.cpt_code, charge.group_id, charge.charge_id, charge.service_id, e)}
                                                label='Mark as worked'
                                                className='table-detail-checkbox'
                                                style={{ marginTop: '5px' }}
                                                disabled={isDisabledField}
                                            />
                                            <Checkbox
                                                id={'posted_' + charge.display_row_id + '_' + this.props.case_id}
                                                checked={charge.exclude_error}
                                                onChange={e => this.on_change_checkbox('exclude_error', claim_detail_dos.claim_icn, claim_detail_dos.date_of_service, charge.cpt_code, charge.group_id, charge.charge_id, charge.service_id, e)}
                                                label='Post and exclude error'
                                                className='table-detail-checkbox'
                                                style={{ marginTop: '5px' }}
                                                disabled={isDisabledField}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </td>
                        </tr>
                    }
                    <tr style={index == 0 && !hasError ? { backgroundColor: `${current_row_background}` } : { backgroundColor: `${current_row_background}`, borderTop: 'hidden' }}
                        className={hasError ? (charge.exclude_error || charge.worked) ? 'table_error_exculded_row_notice_background' : 'table_error_row_notice_background' : ''}>
                        <td style={{ textAlign: 'left' }}>
                            {primary ? charge.cpt_code : ''}
                        </td>
                        <td style={{ textAlign: 'left' }}>{primary ? charge.charge_id : ''}</td>
                        <td style={{ textAlign: 'left' }}>{primary ? charge.units : ''}</td>
                        <td style={{ textAlign: 'left' }}>{primary ? charge.modifier : ''}</td>
                        <td style={{ textAlign: 'right' }}>{primary ? currency_formatter(charge.billed_amount) : ''}</td>
                        <td style={{ textAlign: 'right' }}>{primary ? currency_formatter(charge.balance_amount) : ''}</td>
                        <td style={{ textAlign: 'right' }}>{primary ? currency_formatter(charge.allowed_amount) : ''}</td>
                        <td style={{ textAlign: 'right' }}><span style={ isExpectedRateGreater ? { color: '#98262B' }:{}}>{ primary ? (charge.expected_amount ? currency_formatter(charge.expected_amount): '–' ): ''}</span></td>
                        <td>
                            {
                                !primary &&
                                <i
                                    className={!isDisabledField ? "icon-icemoon icon-minus-sign-circle":"icon-icemoon icon-minus-sign-circle disable-cursor"}
                                    onClick={() => { !isDisabledField ? this.on_click_remove_row(claim_detail_dos.claim_icn, claim_detail_dos.date_of_service, charge.display_row_id) : null }}
                                />
                            }
                        </td>
                        <td>
                            {
                                primary && this.is_dispaly_add_row(claim_detail_dos.claim_icn, claim_detail_dos.date_of_service, charge.cpt_code, charge.group_id, charge.service_id) &&
                                <i
                                    className={!isDisabledField ? "icon-icemoon icon-plus-sign-circle":"icon-icemoon icon-plus-sign-circle disable-cursor"}
                                    onClick={() => { !isDisabledField ? this.on_click_add_row(claim_detail_dos.claim_icn, charge.cpt_code, claim_detail_dos.date_of_service, charge.group_id, charge.service_id, claim_detail_dos.charge_list) : null }}
                                />
                            }
                        </td>
                        <td>
                            {!charge.is_added &&
                                <CurrencyFormatter
                                    name='id_paid_amount_edit_835'
                                    id='id_paid_amount_edit_835'
                                    className={isDisabledField && 'disable-field'}
                                    onChange={e => this.on_change_text_box('paid_amount', claim_detail_dos.claim_icn, charge.display_row_id, claim_detail_dos.date_of_service, e)}
                                    prefix='$'
                                    maxLength={15}
                                    allowNegative={true}
                                    zeroAsDefault={true}
                                    defaultValue={charge.paid_amount || 0}
                                    disabled={isDisabledField}
                                />
                            }
                        </td>
                        <td>
                            {
                                <CurrencyFormatter
                                    name='id_adjustment_amount_edit_835'
                                    id='id_adjustment_amount_edit_835'
                                    className={(isDisabledField || hide_PR_EditableFields) && 'disable-field'}
                                    onChange={e => this.on_change_text_box('adjustment_amount', claim_detail_dos.claim_icn, charge.display_row_id, claim_detail_dos.date_of_service, e)}
                                    prefix='$'
                                    maxLength={15}
                                    allowNegative={true}
                                    zeroAsDefault={true}
                                    isRequired={charge.adjustment_amount === 0 && charge.is_added}
                                    defaultValue={charge.adjustment_amount || 0}
                                    disabled={(isDisabledField || hide_PR_EditableFields)}
                                />
                            }
                        </td>
                        <td>
                            {
                                <Selection
                                    hidden={true}
                                    id='id_adjustment_qualifier_edit_835'
                                    style={adjustment_qualifier_and_reason_required_style}
                                    defaultValue={charge.adjustment_qualifier_code.trim() || 'NA'}
                                    options={this.get_adjustment_qualifier_options(charge.adjustment_qualifier_code)}
                                    isRequired={(charge.adjustment_qualifier_code === 'NA' && (charge.paid_amount === 0 && charge.is_added)) || (charge.adjustment_qualifier_code === 'NA' && (charge.adjustment_amount > 0 && !charge.is_added))}
                                    onChange={value => this.on_change_string_value_in_dd('adjustment_qualifier_code', claim_detail_dos.claim_icn, charge.display_row_id, claim_detail_dos.date_of_service, value)}
                                    disabled={isDisabledField || hide_PR_EditableFields
                                        || (charge.adjustment_amount === 0 && charge.paid_amount === 0 && charge.is_added)
                                        || (charge.adjustment_amount === 0 && charge.adjustment_qualifier_code === 'NA' && !charge.is_added)}
                                />
                            }
                        </td>
                        <td>
                            {  
                                <Selection
                                    hidden={true}
                                    id='id_reason_code_835'
                                    style={adjustment_qualifier_and_reason_required_style}
                                    defaultValue={parseInt(charge.reason_code) + ''}
                                    options={this.get_adjustment_reason_options()}
                                    isRequired={(parseInt(charge.reason_code) === 0 && (charge.paid_amount === 0 && charge.is_added)) || (parseInt(charge.reason_code) === 0 && (charge.adjustment_amount > 0 && !charge.is_added))}
                                    onChange={value => this.on_change_int_value_in_dd('reason_code', claim_detail_dos.claim_icn, charge.display_row_id, claim_detail_dos.date_of_service, value)}
                                    disabled={isDisabledField || hide_PR_EditableFields
                                        || (charge.adjustment_amount === 0 && charge.paid_amount === 0 && charge.is_added)
                                        || (charge.adjustment_amount === 0 && parseInt(charge.reason_code) === 0 && !charge.is_added)}
                                />
                            }
                        </td>
                        <td style={{ textAlign: 'left' }} className={'no-wrap'}>
                            {primary ? this.get_claim_status_code_description(charge.claim_status_code) : ''}
                        </td>
                        <td>
                            {primary &&
                                <Selection
                                    placeHolder={'Select'}
                                    hidden={true}
                                    id='id_insurance_balance_edit_835'
                                    style={isDisabledField && 'disable-field'}
                                    defaultValue={charge.insurance_balance_id + '' || ''}
                                    options={this.get_insurance_balance_options()}
                                    onChange={value =>
                                        this.update_charge_group_on_change_int_value_in_dd('insurance_balance_id', claim_detail_dos.claim_icn, claim_detail_dos.date_of_service, charge.cpt_code, charge.group_id, charge.service_id, value)
                                    }
                                    disabled={isDisabledField}
                                />
                            }
                        </td>
                        <td>
                            {primary &&
                                <Selection
                                    placeHolder={'Select'}
                                    hidden={true}
                                    id={`id_patient_balance_edit_835`}
                                    style={isDisabledField && 'disable-field'}
                                    defaultValue={charge.patient_balance_id + '' || ''}
                                    options={this.get_patient_balance_options()}
                                    onChange={value => this.update_charge_group_on_change_int_value_in_dd('patient_balance_id', claim_detail_dos.claim_icn, claim_detail_dos.date_of_service, charge.cpt_code, charge.group_id, charge.service_id, value)}
                                    disabled={isDisabledField}
                                />
                            }
                        </td>
                        <td>
                            {primary &&
                                <Selection
                                    placeHolder={'Select'}
                                    hidden={true}
                                    id={`id_patient_balance_adjustment_edit_835`}
                                    style={isDisabledField && 'disable-field'}
                                    defaultValue={charge.patient_balance_adjustment_id + '' || 0}
                                    isRequired={charge.patient_balance_id == 0 && charge.patient_balance_adjustment_id == 0}
                                    disabled={charge.patient_balance_id == 1 || isDisabledField}
                                    options={this.get_patient_balance_adjustment_code_options()}
                                    onChange={value => this.update_charge_group_on_change_int_value_in_dd('patient_balance_adjustment_id', claim_detail_dos.claim_icn, claim_detail_dos.date_of_service, charge.cpt_code, charge.group_id, charge.service_id, value)}
                                />
                            }
                        </td>
                        {
                            this.props.features.e835_partial_patient_adjustment &&
                            <td>
                                    {primary &&
                                        <CurrencyFormatter
                                            name='id_max_patient_balance'
                                            id='id_max_patient_balance'
                                            className={(charge.patient_balance_id == epr_patient_balance.keep || isDisabledField) && 'disable-field'}
                                            onChange=
                                            {
                                                (e) => this.update_charge_group_on_change_max_patient_balance(
                                                    'max_patient_balance',
                                                    claim_detail_dos.claim_icn,
                                                    claim_detail_dos.date_of_service,
                                                    charge.cpt_code,
                                                    charge.group_id,
                                                    charge.service_id,
                                                    e)
                                            }
                                            prefix='$'
                                            maxLength={15}
                                            allowNegative={false}
                                            zeroAsDefault={true}
                                            defaultValue={charge.max_patient_balance == null ? '' : charge.max_patient_balance}
                                            disabled={charge.patient_balance_id == epr_patient_balance.keep || isDisabledField}
                                        />
                                    }
                            </td>
                        }
                    </tr>
                </React.Fragment>
            );
        });
        return (rows);
    }

    get_claim_table_footer = (claim_detail: IReleaseEobPatientCardClaimDetailDos) => {
        let charge_primary_line_count = this.get_primary_charge_line_count(claim_detail.charge_list);

        let charges_text = charge_primary_line_count > 1 ? 'Charges' : 'Charge'
        let dos_charge_count_text: string
            = '(' + custom_date_format(claim_detail.date_of_service, date_format["mm/dd/yyyy"]) + ' - ' + charge_primary_line_count + ' ' + charges_text + ')';

        let total_billed: number = 0,
            total_allowed: number = 0,
            total_paid: number = 0,
            total_balance: number = 0;

        claim_detail.charge_list.map(charge => {
            total_billed = total_billed + charge.billed_amount;
            total_allowed = total_allowed + charge.allowed_amount;
            total_paid = total_paid + charge.paid_amount;
            total_balance = total_balance + charge.balance_amount;
        })


        return (
            <tr style={{ backgroundColor: 'white', borderStyle: 'solid', borderColor: '#dce1e7', borderWidth: '1px' }}>
                <td colSpan={4}><span style={{ fontWeight: 'bold', textAlign: 'left' }}>Date of Service </span><span>{dos_charge_count_text}</span></td>
                <td style={{ textAlign: 'right' }}>{currency_formatter(total_billed)}</td>
                <td style={{ textAlign: 'right' }}>{currency_formatter(total_balance)}</td>
                <td style={{ textAlign: 'right' }}>{currency_formatter(total_allowed)}</td>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ textAlign: 'right' }}>{currency_formatter(total_paid)}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                {this.props.features.e835_partial_patient_adjustment && <td></td>}
            </tr>
        );

    }

    get_claim_box_footer = (claim_detail: IReleaseEobPatientCardClaimDetail) => {
        let charges_text = '';
        let claimTotal = '';
        let total_billed: number = 0,
            total_paid: number = 0,
            number_of_charges: number = 0

        claim_detail.claim_dos_list.map(dos_detail => {
            number_of_charges = number_of_charges + this.get_primary_charge_line_count(dos_detail.charge_list);
            dos_detail.charge_list.map(charge => {
                total_billed = total_billed + charge.billed_amount;
                total_paid = total_paid + charge.paid_amount;
            });

        });
        charges_text = number_of_charges > 1 ? ' Charges' : ' Charge';
        claimTotal = '(' + number_of_charges + charges_text + ', ' + claim_detail.claim_dos_list.length + ' Date of Service)';

        return (
            <table style={{ borderCollapse: 'collapse' }}>
                <thead></thead>
                <tbody></tbody>
                <tfoot>
                    <tr style={{ background: 'none' }}>
                        <td style={{ width: '351px', paddingLeft: '21px' }}><span style={{ fontWeight: 'bold' }}>Claim Total </span><span>{claimTotal}</span></td>
                        <td className='text-left' style={{ width: '399px' }}>{currency_formatter(total_billed)}</td>
                        <td className='text-right' style={{ width: '130px' }}>{currency_formatter(total_paid)}</td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
        );
    }

    is_data_loading = () => {
        let data_load_in_progress = (this.state.is_loading.adj_qual || this.state.is_loading.reason_code || this.state.is_loading.claim);

        return (data_load_in_progress || !this._is_mounted);
    }
    // #endregion render help methods


    render() {
        return (
            <div className="ui form common-forms">
                <div className="sixteen wide computer column">
                    {this._is_mounted && this.get_claim_component_collapsible_for_render()}
                </div>
            </div>

        );
    }

}

//
const mapStateToProps = (state, component_props) => {
    return {
        user_login_details: state.user_login_details,
        payment_adjustment: state.payment_adjustment,
        ...component_props,
        features: {
            e835_partial_patient_adjustment: get_lauch_darkley_key_value(state.launch_darkly, e835_partial_patient_adjustment)
        }
    };
};
export default connect(mapStateToProps)(Release835sPatientCardDetail);