import * as React from 'react';
import { Grid } from 'semantic-ui-react';
import moment = require('moment');
import { handle_click_on_enter } from '../../shared/tab_navigation_utility';

const ReportHeader = props => {
  return (
    <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
      <Grid.Column computer={16}>
        <h3 className='ui header left aligned' dangerouslySetInnerHTML={{ __html: props.title }} />
      </Grid.Column>
    </Grid>
  );
};
export default ReportHeader;

export const SubReportHeader = props => {
  return (
    <React.Fragment>
      <Grid className='headerGrid'>
        <Grid.Column computer={16}>
          <h3 className='ui header left aligned' dangerouslySetInnerHTML={{ __html: props.title }} onClick={props.show_drilldown_title}/>
          {(props.drilldown_title.trim() === "" || props.drilldown_title.trim() !== "<br>") && (
          <React.Fragment>
          <i
            tabIndex={0}
            onClick={props.show_drilldown_title}
            onKeyDown={handle_click_on_enter}
            aria-hidden='true'
            className={props.drilldown_title_shown ? 'chevron up horizontal icon' : 'chevron down horizontal icon'}
          />
          {props.drilldown_title && props.drilldown_title_shown && (
            <p className={'drilldown_title'} dangerouslySetInnerHTML={{ __html: props.drilldown_title }} />
          )}
          </React.Fragment>
          )}
          <hr className={'header_separator'} />
        </Grid.Column>
      </Grid>
      {props.showDate ||
        (props.showDate === undefined && (
          <Grid className='dateTime'>
            <Grid.Row>
              <Grid.Column tablet={5} computer={4} textAlign='left'>
                <p style={{ fontSize: '16px', minHeight: 22 }}>
                  {moment(new Date()).format('MM/DD/YYYY')} &nbsp; {moment(new Date()).format('hh:mm:ss A')}
                </p>
              </Grid.Column>
              {props.length !=null && <Grid.Column tablet={5} computer={8} textAlign='center'>
                {
                  <p style={{ fontSize: '16px', minHeight: 22 }}>
                    {`${props.length} ${props.length == 1 ? ' record shown' : ' records shown'}`}
                  </p>
                }
              </Grid.Column>}
              <Grid.Column tablet={2} computer={4} textAlign='right' />
            </Grid.Row>
          </Grid>
        ))}
    </React.Fragment>
  );
};
