import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Button } from 'semantic-ui-react';
import { toastr as toaster } from 'react-redux-toastr';
import { LoaderComponent } from '../../shared/component/loading_component';
import ReportHeader from '../../reports/component/report_header';
import ErrorCorrectionChargeViewUpdateComponent from './error_correction_charge_view_update_component';
import ErrorCorrectionPaymentDetailsComponent from './error_correction_payment_details_component';
import { handle_click_on_enter, set_focus_to_app_header } from '../../shared/tab_navigation_utility';
import * as session_storage from '../../shared/session_storage_utility';
import { ERROR_CORRECTION_MESSAGE } from '../payment_constants';
import { save_charge_for_error_correction, save_errorcorrection_payment } from '../action/payment_action';
import { get_all_error } from '../../shared/utility';
import * as global_constants from '../../global_constants';
import { charge_messages } from '../../charge/charge_constant';
import ConfirmationComponent from '../../shared/component/confirmation_component';
import { money_type } from '../../global_constants';

class ErrorCorrectionDetailsComponent extends React.Component<any, any> {

    payments_node: any;
    charge_update_ref: any;

    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            is_submitted: false,
            save_confirmation_dx_box: false,
            show_bummepd_charge_message_popup:false
        };
        this.charge_update_ref = React.createRef();
    }

    token: any = '';
    _is_mounted = false;
    patient_id :any = null;
    charge_id = 0;

    // Fetching the report id and params.
    UNSAFE_componentWillMount = () => {
        this.patient_id = session_storage.get('active_patient');

    };

    componentDidMount = async () => {
        this._is_mounted = true;
        if (!document.body.classList.contains('reports')) {
            document.body.classList.add('reports');
        }
        this.charge_id = this.props.location.state.charge_id

        if (this.patient_id == '' || this.patient_id == undefined ) {
            session_storage.set('no_patient_selected_for', this.props.location.pathname);
            session_storage.remove('error_corrections_criteria');
            this.props.history.goBack();
        }
    };

    //Get Payments NotesData from API function
    componentDidUpdate(prevProps, prevState) {
        //change page data if patient changes from header, instead of navigate to other page
        if (prevProps.patient_id && prevProps.patient_id != this.props.patient_id) {
            session_storage.remove('error_corrections_criteria');
            this.props.history.goBack();
        }
    }


    componentWillUnmount = () => {
        this._is_mounted = false;
        document.body.classList.remove('reports');
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            if (route_array && (route_array[2] !== 'error_corrections')) {
                session_storage.remove('error_corrections_criteria');
            }
        }
    };

    cancel_handler = () => {
        this.props.history.goBack();
    };

    handle_save_click = (e) => {
        e.preventDefault();
        this.charge_update_ref.mark_form_submitted();

        this.save_charge();
    }

    save_charge = (bypass_dx_check: boolean = false) => {

        let prepared_data = this.charge_update_ref.prepare_charge_data();
        if (!this.charge_update_ref.validateForm(prepared_data)) return;

        // if data is not changed
        if (!this.charge_update_ref.is_data_unsaved(prepared_data)) {
            let data_list = this.payments_node && this.payments_node.get_data_list();
            if (!data_list.is_invalid) {
                this.save_payment(prepared_data, data_list.row_items || [], true);
            }
        } else {

            if (!bypass_dx_check) {
                if (!this.charge_update_ref.validate_dx_data()) {
                    this.setState({
                        save_confirmation_dx_box: true
                    });
                    return;
                }
            }

            let data_list = this.payments_node && this.payments_node.get_data_list();
            if (data_list.is_invalid) return;

            this.setState({
                loading: true
            });

            // return prepared_data;
            save_charge_for_error_correction(prepared_data, this.token).then(res => {
                if (res.data.data && this._is_mounted) {
                    toaster.success('', '1 Charge saved.');
                    this.save_payment(prepared_data, data_list.row_items || []);
                } else {
                    this.setState({
                        loading: false
                    });
                    if (res.data.messages && res.data.messages[0].message) {
                        let charge_exception = res.data.messages.filter((item) => {
                            return item.code === global_constants.charge_exception.code
                        })
                        if (charge_exception && charge_exception.length > 0) {
                            if (this._is_mounted) {
                                this.setState({
                                    loading: false,
                                    show_bummepd_charge_message_popup: true
                                })
                            }
                        }
                        else {
                            let toastr_options = this.show_html_content_toaster(get_all_error(res.data));
                            toaster.error("", toastr_options);
                        }
                       
                    }
                }
            },
                error => {
                    if (error && error.response && error.response.data && error.response.data.messages && error.response.data.messages.length > 0) {
                        let charge_exception = error.response.data.messages.filter((item) => {
                            return item.code === global_constants.charge_exception.code
                        })
                        if (charge_exception && charge_exception.length > 0) {
                            if (this._is_mounted) {
                                this.setState({
                                    loading: false,
                                    show_bummepd_charge_message_popup: true
                                })
                            }
                        } else {
                            if (this._is_mounted) {
                                this.setState({
                                    loading: false
                                })
                            }
                            const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                            toaster.error('', toastr_options);
                        }
                    }
                    else {
                        if (this._is_mounted) {
                            this.setState({
                                loading: false
                            })
                        }
                        if (error.response.data) {
                            const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                            toaster.error("", toastr_options);
                        }
                    }
                });
        }
    }

    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }


    save_payment = (charge_details, data_list, is_charge_unsaved = false) => {
        if (data_list.length > 0) {
            this.setState({
                loading: true
            });
            
            data_list.map(data_list => {
                if(data_list.money_type == money_type.CREDIT_CARD_NUM || data_list.money_type == money_type.DEBIT_CARD_NUM ){
                    data_list.check_num = null;
                }else{
                    data_list.credit_card_num = null;
                }
            });
            
            let params = {
                payment: data_list,
                charge: { ...charge_details, patient_id: this.patient_id }
            };
            save_errorcorrection_payment(params, this.token).then(res => {
                if (res.data && res.data.status == 1 && this._is_mounted) {
                    this.setState({
                        loading: false,
                        is_submitted: false
                    }, () => {
                        if(res.data.data && 
                            (res.data.data.fail_records && res.data.data.fail_records > 0) ||
                            (res.data.data.saved_records && res.data.data.saved_records > 0)
                        ) {
                            if (res.data.data.saved_records > 0) {
                                toaster.success('', `${res.data.data.saved_records} Payments saved.`);
                            }
                            if (res.data.data.fail_records > 0) {
                                toaster.error('', `${res.data.data.fail_records} Payments failed.`);
                            }
                        }

                        this.props.history.goBack();
                    });
                }
            },
            error => {
                this.setState({
                    loading: false
                });
                if (error.response.data) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error("", toastr_options);
                }
            });
        } else {
            this.setState({
                loading: false,
                is_submitted: true
            }, () => {
                if (is_charge_unsaved) {
                    toaster.warning('', ERROR_CORRECTION_MESSAGE.NO_CHANGE);
                } else {
                    this.props.history.goBack();
                }
            });
        }
    }

    on_close_dx_confirmation = () => {
        if (this._is_mounted) {
            this.setState({
                save_confirmation_dx_box: false
            });
        }
    }

    on_save_dx_confirmation = () => {
        if (this._is_mounted) {
            this.setState({
                save_confirmation_dx_box: false
            });
        }

        this.save_charge(true);
    }

    onScroll = (e) => {
        e.stopPropagation();
        let ele = document.querySelector(".results.transition.visible");
        if(ele ) {
            let activeElement: any = document.activeElement;
            activeElement && activeElement.blur();
        }
    }
    refresh_bummepd_charge_modal = () => {
        if (this._is_mounted) {
            this.setState({
                show_bummepd_charge_message_popup: false
            }, () => {
                this.props.history.goBack();
            });
        }
    }
    on_close_bummepd_charge_popup = () => {
        if (this._is_mounted) {
            this.setState({
                show_bummepd_charge_message_popup: false
            });
        }
    }
    render() {
        const { save_confirmation_dx_box, loading } = this.state;
        return (<LoaderComponent loading={loading}>
            {save_confirmation_dx_box ?
                <ConfirmationComponent message={charge_messages.diagnosis_inappropriate}
                    title='Confirm' show_modal={save_confirmation_dx_box} onCloseModal={this.on_close_dx_confirmation} on_force_save_click={(e) => { this.on_save_dx_confirmation() }} />
                : ''}
            {this.state.show_bummepd_charge_message_popup &&
                <ConfirmationComponent message={global_constants.charge_exception.message}
                    title='Confirm' show_modal={this.state.show_bummepd_charge_message_popup}
                    onCloseModal={this.on_close_bummepd_charge_popup}
                    save_button_text='Refresh'
                    on_force_save_click={(e) => { this.refresh_bummepd_charge_modal() }} />
                }
            <div className={'common-forms-add '}>
                <div className={'common-forms-search report-framework error-correction'}>
                    <ReportHeader title={'Error Corrections - Transaction Edits'} />
                    <div id="id_div_popper" />
                    <div className='outer-wrap' onScroll={this.onScroll} style={{ overflowX: 'hidden' }}>
                        <ErrorCorrectionChargeViewUpdateComponent
                            patient_id={this.props.patient_id}
                            charge_id={this.charge_id}
                            onRef={instance => { this.charge_update_ref = instance; }}
                        />
                        <ErrorCorrectionPaymentDetailsComponent
                            onRef={ref => (this.payments_node = ref)}
                            isSubmitted={this.state.is_submitted}
                            patient_id={this.props.patient_id}
                        />
                    </div>

                    <div
                        className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                        id='applicationFooterSticky'
                        style={{ paddingRight: 15 }}>
                        <Grid style={{ marginTop: 0, marginBottom: 0 }}>
                            <Grid.Column computer={16} tablet={16} textAlign='right'>
                                <Button
                                    id='cancel'
                                    type='button'
                                    onClick={this.cancel_handler}
                                    basic
                                    disabled={this.state.is_cancel_disabled}
                                    content={'Cancel'}
                                />
                                <Button
                                    id='save'
                                    className='primary'
                                    onClick={this.handle_save_click}
                                    type='button'
                                    onKeyDown={set_focus_to_app_header}
                                    style={{ marginRight: '0' }}
                                    disabled={this.state.is_rerun_disabled}
                                    content={'Save'}
                                />
                            </Grid.Column>
                        </Grid>
                    </div>
                </div>
            </div>
        </LoaderComponent>);
    }
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};

// Get user and login details from store.
const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        patient_id: state.patient_details && state.patient_details.patient_header && state.patient_details.patient_header.data && state.patient_details.patient_header.data.id || ''
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorCorrectionDetailsComponent);
