import { IValidator } from "./IValidator";
import { addPatientInitialConfiguration, patientInvalidFieldMessage } from '../patient_constants';

export class FirstCharacterValidator implements IValidator {
    private errorMessages = new Array<string>();

    isValid(textValue: string): boolean {
        const startsWithLetterRegexp = addPatientInitialConfiguration.startsWithLetterRegexp;
        const hasLetterAtTheStart = startsWithLetterRegexp.test(textValue)

        this.errorMessages = [];
        if (!hasLetterAtTheStart) {
            this.errorMessages.push(patientInvalidFieldMessage.invalidFirstCharacter);
        }
        return hasLetterAtTheStart;
    }

    getErrorMessages(): string[] {
        return this.errorMessages;
    }
}
