import * as React from 'react';
import * as moment from 'moment';
import { connect } from 'react-redux';
import { Icon } from '@blueprintjs/core';
import { bindActionCreators } from 'redux';
import { toastr as toaster } from 'react-redux-toastr';
import ReportRow835Component from './835_exclusion_report_row_component';
import { log_error } from '../../../shared/action/shared_action';
import { get_columns, get_all_error } from '../../../shared/utility';
import * as report_constants from '../../../reports/report_constants';
import * as report_service from '../../../reports/action/report_action';
import * as export_print_utility from '../../../reports/util/export_print_utility';
import { Icon as SemanticIcon, Table, GridColumn, Grid, TableBody } from 'semantic-ui-react';
import { mm_dd_yyyy_comparator, number_comparator, text_comparator } from '../../../shared/component/grid/comparators';

export class PrintReport835Component extends React.Component<any, any> {
    [x: string]: any;
    constructor(props: any) {
        super(props);
        this.state = {
            expand_all: true,
            collapse_all: false,
            allow_sort: true,
            report_data: [],
            is_grid_visible: false,
            is_show_pop_up: false,
            grid_params: null,
            loading: true,
            row_params: {},
            show_date: false,
            report_rows_count: 0,
            is_error: {},
            refresh_report: false,
            expand: true,
        };
        this.rowNodes = [];
    }

    // Define class level variables.
    title = "";
    rowNodes: any;
    current_date: any;
    company_name: any;
    _is_mounted = false;
    sort_key: string = '';
    sort_type: string = '';
    total_column_width: number = 0;
    grid_header_height: number = 0;
    page_name = "835 Error Exclusions"
    error_excusion_column_def = report_constants.error_excusion_835__configuration.column_defs;
    error_excusion_child_column_def = report_constants.error_excusion_835__configuration.child_column;

    // Fetching the report id and params.
    UNSAFE_componentWillMount = () => {
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
    };

    componentWillUnmount = () => {
        document.body.classList.remove('reports');
        this._is_mounted = false;

    };

    componentDidMount() {
        this._is_mounted = true;
        document.body.classList.add('reports');
    }

    toggle_state_values = () => {
        this.setState(prevState => ({
            expand_all: !prevState.expand_all,
            collapse_all: !prevState.collapse_all,
        }));
    }

    //Render Expand All/Collapse All icon
    render_angle_icon = () => {
        return (
            <span className="angle-group-expand-collapse" onClick={this.toggle_state_values}>
                {this.state.collapse_all && <Icon icon="expand-all" className="angle-color angle" />}
                {this.state.expand_all && <Icon icon="collapse-all" className="angle-color angle" />}
            </span>
        );
    }

    render_body = () => {
        var innerColumn = this.error_excusion_child_column_def.map(value => ({ ...value }))
        return (
            <TableBody>
                {this.props.report_data.map((error, index) => {
                    return (<ReportRow835Component
                        key={`main-${index}`}
                        error={error}
                        index={index}
                        payers={error.payers}
                        innerColumn={innerColumn}
                        show_payers={this.state.expand_all}
                    />)
                })}
            </TableBody>
        );
    }



    render_header = () => {
        return this.error_excusion_column_def.map((col, index) => {
            if (col.field === 'angle_icon') {
                return (<Table.HeaderCell key={`mhalevel${index}`} style={{ 'width': '40px' }}>
                    {this.render_angle_icon()}
                </Table.HeaderCell>);
            }
            return (<Table.HeaderCell key={`mhlevel${index}`}>
                <div className={`table-col-header-container sortable`}>
                    <span className="header-col-label" id={col.field}>{col.headerName}</span>
                </div>
            </Table.HeaderCell>);
        });
    }

    render_no_data_available = () => {
        return (
            <TableBody>
                <Table.Row >
                    <Table.Cell colSpan={12} textAlign='center'>
                        No Record Available
                    </Table.Cell>
                </Table.Row>
            </TableBody>
        );
    }

    render() {
        return (
            <div id={'no-exclusion-report'} className='table-responsive' style={{ display: 'none' }}>
                <Table id={"exclusion-report-835"}>
                    <Table.Header>
                        <Table.Row>
                            {this.render_header()}
                        </Table.Row>
                    </Table.Header>
                    {this.props.report_data && this.props.report_data.length > 0 && this.render_body()}
                    {this.props.report_data.length == 0 && this.render_no_data_available()}
                </Table>
            </div>);
    }
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};

// Get user and login details from store.
const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        patient_id: state.patient_details && state.patient_details.patient_header && state.patient_details.patient_header.data && state.patient_details.patient_header.data.id || ''
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintReport835Component);