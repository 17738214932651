import * as React from "react"
import { Label } from "semantic-ui-react";

export default class PaymentBadgeRendererComponent extends React.Component<any, any> {
	constructor(props) {
		super(props);

		this.state = {
			number_errors: props.value
		}

		this.onCaratClicked = this.onCaratClicked.bind(this);
	}

	onCaratClicked = () => {
		this.props.onClickFunction(this.props.node.data);
	}

	render() {
		if (this.state.number_errors == -1) {
			return (<label></label>);
		}
		else if (this.state.number_errors == 0) {
			return (<span><i aria-hidden="true" className={`angle-color angle down icon`} style={paymentBadgeCSS.marginLeft} onClick={this.onCaratClicked}></i></span>);
		}
		else { //( this.state.number_errors > 0 )
			return (<span><Label circular color={"red"} id={`errorLabel-${0}`} className="labelCircular card-info" style={paymentBadgeCSS.badgePlacement}>
				{this.state.number_errors}
			</Label><i aria-hidden="true" className={`angle-color angle down icon`} onClick={this.onCaratClicked}></i></span>);
		}
	}
};

export const paymentBadgeCSS = {
	marginLeft: {
		marginLeft: 35
	},
	badgePlacement: {
		marginRight: 11,
		marginBottom: 2,
		verticalAlign: 'middle'
	}
};
