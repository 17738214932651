
// #region Interface definitions
export interface IRichTextEditorProps {
    editorContent?: string,
    readonly?: boolean,
    onChange?: any, // callback function
    editorStyle?: IEditorStyle // css styling
    hideToolbar?: boolean
    toolbarClassName?: any // css styling for tool bar display
    toolbarConfig?: IRichTextEditorToolbarConfig // need to define the structure as part of B-36191, TK-102764	
    toolbarCustomMenuConfig?: Array<IRichTextEditorToolbarCustomMenuConfig> // custom buttons on tool bar config B-36191, TK-102765
}

export interface IEditorStyle {
    borderWidth: number;
    minheight: number;
    maxheight: number;
}

export interface IToolBarOption {
    optionName: string,
    inDropdown?: boolean,
    options?: Array<string>,
    hide?: boolean
}

export interface IRichTextEditorToolbarCustomMenuConfig  {
    controlType: RichTextEditorCustomMenuControl,
    options: Array<IRichTextEditorCustomMenuOptionItem>,
    cssClass: string,
    inDropdown?: boolean,
    dropDownTitle: string,
    sortData:boolean,
}

export interface IRichTextEditorToolbarConfig {
    tool_bar_options: Array<IToolBarOption>
}

export interface IRichTextEditorCustomMenuOptionItem {
    value: string,
    text: string,
    tooltip?: string
}
// #endregion Interface definitions

//#region constants

export enum RichTextEditorCustomMenuControl {
    addInlineText = "addInlineText"
}

export enum RichTextEditorToolbarOptions {
    inline = 'inline',
    blockType = 'blockType',
    fontSize = 'fontSize',
    fontFamily = 'fontFamily',
    list = 'list',
    textAlign = 'textAlign',
    link = 'link',
    image = 'image',
    history = 'history'
}

export enum RichTextEditorInlineOptions {
    bold = 'bold',
    italic = 'italic',
    underline = 'underline',
}

export enum RichTextEditorBlockTypeOptions {
    Normal = 'Normal',
    H1 = 'H1',
    H2 = 'H2',
    H3 = 'H3',
    H4 = 'H4',
    H5 = 'H5',
    H6 = 'H6',
    Blockquote = 'Blockquote'
}

export enum RichTextEditorFontFamilyOptions {
    Arial = 'Arial',
    Georgia = 'Georgia',
    Impact = 'Impact',
    Tahoma = 'Tahoma',
    TimesNewRoman = 'Times New Roman',
    Verdana = 'Verdana'
}

export enum RichTextEditorListOptions {
    unordered = 'unordered',
    ordered = 'ordered',
    indent = 'indent',
    outdent = 'outdent'
}

export enum RichTextEditorTextAlignOptions {
    left = 'left',
    center = 'center',
    right = 'right',
    justify = 'justify'
}

export enum RichTextEditorLinkOptions {
    link = 'link',
    unlink = 'unlink'
}

export enum RichTextEditorHistoryOptions {
    undo = 'undo',
    redo = 'redo'
}

export const rich_text_editory_default_font_size_options = [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96];

//#endregion


export default class RichTextEditorHelper {

    static get_default_tool_bar_config(): IRichTextEditorToolbarConfig {
        let options: Array<IToolBarOption> = [];

        options.push(this.configure_option(RichTextEditorToolbarOptions.inline, this.get_option_values(RichTextEditorInlineOptions), false, false));
        options.push(this.configure_option(RichTextEditorToolbarOptions.blockType, this.get_option_values(RichTextEditorBlockTypeOptions), false, true));
        options.push(this.configure_option(RichTextEditorToolbarOptions.fontSize, rich_text_editory_default_font_size_options, false, true));
        options.push(this.configure_option(RichTextEditorToolbarOptions.fontFamily, this.get_option_values(RichTextEditorFontFamilyOptions), false, true));
        options.push(this.configure_option(RichTextEditorToolbarOptions.list, this.get_option_values(RichTextEditorListOptions), false, false));
        options.push(this.configure_option(RichTextEditorToolbarOptions.textAlign, this.get_option_values(RichTextEditorTextAlignOptions), false, false));
        options.push(this.configure_option(RichTextEditorToolbarOptions.link, this.get_option_values(RichTextEditorLinkOptions), false, false));
        options.push(this.configure_option(RichTextEditorToolbarOptions.image, [], false, false));
        options.push(this.configure_option(RichTextEditorToolbarOptions.history, this.get_option_values(RichTextEditorHistoryOptions), false, false));

        return ({ tool_bar_options: options });
    }

    static configure_option(optionName: string, options: Array<any>, hide: boolean, inDropdown: boolean): IToolBarOption {
        let option: IToolBarOption;
        option = { optionName: optionName, options: options, hide: hide, inDropdown: inDropdown };
        return (option);
    }

    static is_valid(entry): boolean {
        return !(entry == null || entry == undefined);
    }

    static get_react_draft_toolbar_object(config: IRichTextEditorToolbarConfig) {

        let react_draft_toolbar_object = { options: [] };

        let default_toolbar_config:IRichTextEditorToolbarConfig = this.get_default_tool_bar_config();

        if (!this.is_valid(config) || !this.is_valid(config.tool_bar_options)) config = default_toolbar_config;

        config.tool_bar_options.forEach((option) => {

            let default_option_settings = default_toolbar_config.tool_bar_options.find(x => x.optionName == option.optionName);

            let option_config = this.get_option_config(option, option.optionName, default_option_settings);
           
            if (this.is_valid(option_config)) {
                react_draft_toolbar_object[option.optionName] = option_config;
                react_draft_toolbar_object.options.push(option.optionName)
            }
        });

        return react_draft_toolbar_object;
    }

    static get_option_config(option_config: IToolBarOption, option_name: string, default_option_settings:IToolBarOption) {
               
        if (!this.is_valid(option_config))
            option_config = default_option_settings;


        if (option_config.inDropdown == undefined) option_config.inDropdown = default_option_settings.inDropdown;

        if (option_config.options == undefined) option_config.options = default_option_settings.options;

        if (option_config.hide == undefined) option_config.hide = default_option_settings.hide;

        let react_draft_option_object;

        if (option_config.hide)
            react_draft_option_object = null
        else {
            react_draft_option_object = {
                inDropdown: option_config.inDropdown ? true : false,
                options: option_config.options
            }

        }

        return react_draft_option_object;
    }

    static get_option_values(values: any): Array<string> {
        if (!this.is_valid(values)) return [];
        return Object.keys(values).map(key => values[key]).filter(value => typeof value === 'string') as string[]
    }

    static configure_rich_text_edtior_style(border_width: number, min_heigth: number, max_height: number): IEditorStyle {

        let style: IEditorStyle;

        style = {
            borderWidth: border_width,
            minheight: min_heigth,
            maxheight: max_height
        };

        return style;
    }

    static get_editor_style_for_react_draft(style_config: IEditorStyle, is_editor_readonly: boolean) {
        
        let editor_font_color = "";
        let editor_height_in_px = "730px";

        if (is_editor_readonly) editor_font_color = "#DCDAD1"

        let default_style = { border: "1px solid", minHeight: editor_height_in_px, height: editor_height_in_px, color: editor_font_color, padding: "2px", margin: "0 0 0 0", backgroundImage: "none" }

        if (!this.is_valid(style_config)) return default_style;

        var border_width_in_px: string = this.is_valid(style_config.borderWidth) ? style_config.borderWidth + 'px solid' : '1px solid';
        var min_height_in_px: string = this.is_valid(style_config.minheight) ? style_config.minheight + 'px' : editor_height_in_px;
        var height_in_px: string = this.is_valid(style_config.maxheight) ? style_config.maxheight + 'px' : editor_height_in_px;

        return { border: border_width_in_px, minHeight: min_height_in_px, height: height_in_px, color: editor_font_color, padding: "2px", margin: "0 0 0 0", backgroundImage: "none" };


    }

    static get_toolbar_custom_menu_config(controlType: RichTextEditorCustomMenuControl, inDropdown: boolean,
        options: Array<IRichTextEditorCustomMenuOptionItem>, dropDownTitle: string="", cssClass: string="", sortData:boolean): IRichTextEditorToolbarCustomMenuConfig {
        return {
            controlType: controlType, inDropdown: inDropdown,
            options: options, dropDownTitle: dropDownTitle, cssClass: cssClass,
            sortData:sortData
        }
    }

    static sort_options (options: Array<IRichTextEditorCustomMenuOptionItem>): Array<IRichTextEditorCustomMenuOptionItem> {

        if (!this.is_valid(options) || options.length == 0 || options.length == 1) return options;

        for (let outerIndex = 0; outerIndex < options.length - 1; outerIndex++) {

            let option_current_config = null;
            let option_next_config = null;

            for (let innerIndex = 0; innerIndex < options.length - outerIndex - 1; innerIndex++) {

                option_current_config = options[innerIndex];
                option_next_config = options[innerIndex + 1];

                if (!this.is_valid(option_current_config)) option_current_config = { value: '', text: '', tooltip: '' };
                if (!this.is_valid(option_next_config)) option_next_config = { value: '', text: '', tooltip: '' };


                let option_current_config_text: string = this.is_valid(option_current_config.text) ? option_current_config.text.toLowerCase() : '';
                let option_next_config_text: string = this.is_valid(option_next_config.text) ? option_next_config.text.toLowerCase() : '';


                if (option_current_config_text > option_next_config_text) {
                    options[innerIndex] = option_next_config;
                    options[innerIndex + 1] = option_current_config;
                }

            }

        }
        return options;
    }
}

