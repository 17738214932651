
export const TOKEN_AUTH = 'USER:TOKEN_AUTH';
export const USER_LOGOUT = 'USER:LOGOUT';
export const USER_AUTHENTICATION = 'USER:AUTHENTICATION';
export const SAVE_TOKEN = 'USER:SAVE_TOKEN';
export const USER_ERROR = 'USER:ERROR';
export const FORGOT_PASSWORD = 'USER:FORGOT_PASSWORD';
export const RESET_PASSWORD = 'USER:RESET_PASSWORD';
export const FORGOT_PASSWORD_TOKEN_VALIDATION = 'USER:FORGOT_PASSWORD_TOKEN_VALIDATION';
export const RE_SEND_IP_REGISTRATION_LINK = 'USER:RE_SEND_IP_REGISTRATION_LINK';
export const MASTER_DATA = 'USER:MASTER_DATA';
export const MASTER_DATA_BY_TYPE = 'USER:MASTER_DATA_BY_TYPE';
export const validation = {
    required: 'Username and Password cannot be left blank.'
}

export const RESET_REQUIRED_VALIDATION = "The New Password and Confirm Password cannot be left blank."
export const MISMATCH_PASSWORD = "The New Password and Confirm Password do not match."
export const IMAGES_PATH = "/src/assets/images/";
export const WEB_PT_LOGO = "webpt-logo-white-LOGIN-SCREEN.png";
export const WEB_PT_COLOR_LOGO = "logoWebPTColor.svg";

export const AUTHENTICATION_ERROR_CODE = 9300;

/**
 * Master data API 
 * Used type to get master data
 */
export enum MasterData {
    all = 0
}
