import { IProviderBillingStrategy } from './provider_billing_validator_strategy';

export class ProviderBillingValidator {
    private strategy: IProviderBillingStrategy;

    public setStrategy(strategy: IProviderBillingStrategy) {
        this.strategy = strategy;
    }

    public isValid(data: any[]): boolean {
        return this.strategy.validate(data);
    }
}
