import { AgGridReact } from 'ag-grid-react';
import 'ag-grid/dist/styles/ag-grid.css';
import 'ag-grid/dist/styles/ag-theme-balham.css';
import * as React from 'react';
import { clearTimeout, setTimeout } from 'timers';
import { child_row_height, row_height, charge_child_row_height, inner_row_height, inner_grid_reset_eob_row_height } from './case_row_renderer';
import { DIMEN, GRID_AUTO_HEIGHT_STYLE, GRID_BASE_STYLE, GRID_FILTER_COMPONENTS, ICONS, TEMPLATE_LOADING, TEMPLATE_NO_RECORD } from './constants';
import { clear_filter, filterSelectedDisplay, isTouchDevice, copy_cell_text, clear_all_filter, filterRowDataForGrouped, group_row_by_column, groupRowByColumnWithRowsCount } from './utils';
import InnerGridRendererComponent from '../../../reports/util/inner_grid_renderer_component';
import InnerGridTableRendererComponent from '../../../reports/util/inner_grid_table_renderer_component';
import GroupPanelComponent from './group_panel_component';

interface IProps {
    id?: string,
    column: any[],
    row: any[],
    onRowSelection?: Function,
    onRowDoubleClicked?: Function,
    onGroupRowClicked?: Function,
    selectionType?: string,
    emptyMessage?: string,
    headerHeight?: number,
    style?: any,
    wrapperStyle?: object,
    wrapperClass?: string,
    gridAutoHeight?: boolean,
    gridAutoRowHeight?: boolean,
    checkboxSelection?: boolean,
    radioSelection?: boolean,
    get_grid_ref?: Function,
    on_filter_button_click?: Function,
    enableEnterOnNavigation?: boolean,
    onRef?: any,
    headerIdForTabNavigation?: string,
    pinnedBottomRowData?: any[],
    suppressMovableColumns?: boolean,
    suppressColumnVirtualisation?: boolean,
    enableColResize?: boolean,
    isTotal?: boolean,
    detailsName?: string,
    onCellClicked?: Function,
    getRowHeight?: Function,
    isRowSelectable?: Function,
    suppressSizeToFit?: boolean,
    rowClassRules?: object,
    disableHover?: boolean,
    rowGroupPanelShow?: boolean,
    displayGroupRowCount?: boolean,
    onGroupByChange?: Function,
    isGroupColumnAlwaysVisible?: boolean,
    onCellMouseDown?: Function,
    enableExpandAndCollapse?: boolean
}

/**
 * Grid view component use to display data
 */
class GridView extends React.Component<IProps, any> {
    is_mounted = false;
    gridApi: any;
    gridColumnApi: any;
    focused_cell_column: any;
    focused_cell_index: any;
    container_grid: HTMLElement;
    suppressSizeToFit = false;
    ref_group_panel: any;

    //Get innitalise data when component created
    constructor(props) {
        super(props);
        this.state = {
            frameworkComponents: { ...GRID_FILTER_COMPONENTS, InnerGridRendererComponent: InnerGridRendererComponent, InnerGridTableRendererComponent },
            defaultColDef: { suppressKeyboardEvent: this.keyboardEvent.bind(this) },
            getNodeChildDetails: function getNodeChildDetails(rowItem) {
                if (rowItem.participants) {
                    return {
                        group: true,
                        expanded: rowItem.expanded || rowItem.group === "text value",
                        children: rowItem.participants,
                        key: rowItem.group
                    };
                } else {
                    return null;
                }
            },
            getRowHeight: function (params) {
                var rowIsNestedRow = !params.node.group;
                if (this.props.id.indexOf('report_id_') > -1) {
                    if(params.node.rowPinned){
                        return 28;
                    }
                    return rowIsNestedRow ? inner_row_height(params.data, params) : 28;
                }
                else if (this.props.id && this.props.id.indexOf('accordian_grid_') != -1) {
                    let detailData = undefined;
                    if (params.data != undefined && params.data[props.detailsName] != undefined) {
                        detailData = params.data[props.detailsName];
                    }
                    return rowIsNestedRow ? inner_child_row_height(detailData ? detailData.length : 0) : 28;
                }
                else if(this.props.id === ('reset_835_eob')){
                    if(params.node.rowPinned){
                        return 28;
                    }
                    return rowIsNestedRow ? inner_grid_reset_eob_row_height(params.data, params) : 28;                    
                }
                else if(this.props.id === ('id_grid_enhanced_add_edit_payer_rules')){                     
                    let detailData = undefined;
                    if (params.data != undefined && params.data[props.detailsName] != undefined) {
                        detailData = params.data[props.detailsName];
                    }
                    return rowIsNestedRow ? inner_child_row_height(detailData ? detailData.length : 0) : 42;                   
                }
                else {
                    return rowIsNestedRow ? child_row_height(params.data) : row_height(params.data);
                }
            },
            grouped_rows: [],
            grouped_fields: [],
        };
        this.suppressSizeToFit = this.props.suppressSizeToFit ? this.props.suppressSizeToFit : false
    }

    // F=grid function callback on ready state
    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
        if (isTouchDevice()) {
            params.api.addEventListener('rowClicked', this.onRowClick.bind(this));
        } else {
            params.api.addEventListener('rowDoubleClicked', this.onRowDoubleClick.bind(this));
            if (this.props.onGroupRowClicked) params.api.addEventListener('rowClicked', this.props.onGroupRowClicked.bind(this));
        }
        if (this.props.selectionType || this.props.checkboxSelection || this.props.radioSelection) {
            params.api.addEventListener('rowSelected', this.onSelectionChanged.bind(this));
        }
        if (!this.props.suppressSizeToFit) {
            params.api.addEventListener('gridSizeChanged', this.onGridResize.bind(this));
        }
        if (this.props.get_grid_ref) {
            this.props.get_grid_ref(params);
        }
        if (this.props.gridAutoRowHeight && this.is_mounted) {
            this.setState({
                timeout: setTimeout(() => { params.api.resetRowHeights() },500)
            });
        }
        this.handle_first_tab();
        this.container_grid = document.querySelector(`#${this.props.id}`);
        if (this.container_grid) {
            this.container_grid.addEventListener('mouseleave', this.handle_mouse_leave);
            if (this.props.rowGroupPanelShow) {
                const grid_column_nodes: NodeListOf<HTMLElement> = document.getElementById(this.props.id).querySelectorAll('.ag-cell-label-container .ag-header-cell-label') as NodeListOf<HTMLElement>;
                if (grid_column_nodes && grid_column_nodes.length > 0) {
                    for (var i = 0; i < grid_column_nodes.length; i++) {
                        let node = grid_column_nodes[i];
                        if (node.draggable) {
                            node.ondragstart = this.onDragStart;
                            node.onclick = this.handle_header_click;
                        }
                    }
                }
            }
        }
        this.setGridOverflow();
        params.api.sizeColumnsToFit();
        if (this.props.enableExpandAndCollapse){                
            this.set_icon_expand_and_collepse();
        }        
    }

    set_icon_expand_and_collepse=() =>{
        let cellgrids = document.querySelectorAll('#'+ this.props.id +' .ag-header-row');
        if (cellgrids.length > 0) {
            let mycell = cellgrids[1].firstChild as HTMLElement;  
            mycell.innerHTML = '<i aria-hidden="true" class="angle-color angle up icon" style="color:black;height: 10px;"></i>'+
            '<i aria-hidden="true" class="angle-color angle down icon" style="color:black; height: 13px;"></i>';
            mycell.style.display = 'flex';
            mycell.style.flexDirection = 'column';
            mycell.style.justifyContent = 'center';
            mycell.style.alignItems = 'center'; 
            mycell.addEventListener('click', this.expand_and_collepse_handler);
        }
    }

    expand_and_collepse_handler =() =>{                      
        let icon_cell = document.querySelectorAll('#'+ this.props.id +' .ag-header-row')[1].firstChild as HTMLElement;        
        let expand_rows = (icon_cell.firstChild as HTMLElement).classList.contains('up')
        if (expand_rows)
        {           
            icon_cell.innerHTML = '<i aria-hidden="true" class="angle-color angle down icon" style="color:black;height: 10px;"></i>'+
                           '<i aria-hidden="true" class="angle-color angle up icon" style="color:black; height: 13px;"></i>';
            this.gridApi.expandAll();            
        }
        else
        {            
            icon_cell.innerHTML = '<i aria-hidden="true" class="angle-color angle up icon" style="color:black;height: 10px;"></i>'+
                           '<i aria-hidden="true" class="angle-color angle down icon" style="color:black; height: 13px;"></i>';
            this.gridApi.collapseAll();
        }
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.row !== this.props.row) {
            if (this.gridApi) {
                clear_all_filter(this.gridApi);
            }
        }
    }

    handle_mouse_leave = event => {
        this.gridApi.clearFocusedCell();
    }

    /**
   * Handle the tab event for the first time
   */
    handle_first_tab = () => {
        if (this.props.headerIdForTabNavigation) {
            let self = this;
            let header: HTMLElement = document.getElementById(this.props.headerIdForTabNavigation) as HTMLElement;
            if (header) {
                // remove if already exist
                header.removeEventListener("keydown", this.event_listener_to_handle_header_tab, true);
                //Add listener
                header.addEventListener("keydown", this.event_listener_to_handle_header_tab);
            }
        }
    }

    event_listener_to_handle_header_tab = (event) => {
        if (!event.shiftKey && event.keyCode == 9 && this.props.row.length > 0) {
            event.preventDefault();
            this.set_focus_on_first_cell_of_first_column();
        }
    }

    /**
     * Set focus to the first cell of first display row.
     * This is done to handle the tabbing when we traverse the grid again and again
     * */
    set_focus_on_first_cell_of_first_column = () => {

        if (this.gridApi && this.gridColumnApi) {
            // scrolls to the first row
            this.gridApi.ensureIndexVisible(0);

            // scrolls to the first column
            var firstCol = this.gridColumnApi.getAllDisplayedColumns()[0];
            this.gridApi.ensureColumnVisible(firstCol);

            let topRowIndex = 0;
            // sets focus into the first grid cell
            this.gridApi.setFocusedCell(0, firstCol);

            if ((!this.props.checkboxSelection) && (!this.props.radioSelection)) {
                this.gridApi.forEachNode((node) => {
                    if (node.rowIndex == topRowIndex) {
                        node.setSelected(true)
                    }
                });
            }
        }
    }

    ensureScrollIsLeft = () => {
        this.gridApi.ensureColumnVisible(this.gridColumnApi.getAllColumns()[0].colId);
    }

    keyboardEvent({ event }) {
        copy_cell_text(event, this.gridApi);// Mac safari copy

        if (this.props.selectionType || this.props.enableEnterOnNavigation) {
            if (event.which === 13) {

                if (this.props.enableEnterOnNavigation) {
                    let focussedCell = this.gridApi.getFocusedCell();

                    let firstColumn = this.gridColumnApi.getAllColumns()[0].colId;

                    // if the row is focussed
                    if (this.gridApi.getFocusedCell() && this.gridApi.getFocusedCell().column && this.gridApi.getFocusedCell().column.colId) {
                        let currentColumnField = this.gridApi.getFocusedCell().column.colId;

                        if (firstColumn == currentColumnField) {
                            event.target.click();
                        } else {
                            this.props.onRowDoubleClicked && this.props.onRowDoubleClicked(this.props.row[focussedCell.rowIndex]);
                        }
                    }
                } else {
                    var selectedRows = this.gridApi.getSelectedRows();
                    if (selectedRows && selectedRows.length > 0) {
                        this.props.onRowDoubleClicked && this.props.onRowDoubleClicked(selectedRows[0]);
                    }
                }
            }

            if (event.which == 32) {
                if (this.props.enableEnterOnNavigation && this.props.radioSelection) {
                    let firstColumn = this.gridColumnApi.getAllColumns()[0].colId;

                    // if the row is focussed
                    if (this.gridApi.getFocusedCell() && this.gridApi.getFocusedCell().column && this.gridApi.getFocusedCell().column.colId) {
                        let currentColumnField = this.gridApi.getFocusedCell().column.colId;

                        if (firstColumn == currentColumnField) {
                            event.target.querySelector('input').click();
                        }
                    }
                }
            }

            if (event.keyCode == '9') {
                this.gridApi.clearFocusedCell();
            }
        }
    }

    keyRowSelection = (index) => {
        if (this.props.selectionType) {
            this.gridApi.forEachNode((node) => {
                if (index === node.rowIndex) {
                    node.setSelected(true);
                }
            });
        }
    }

    navigateToNextCell(params) {

        var previousCell = params.previousCellDef;
        var suggestedNextCell = params.nextCellDef;

        var KEY_UP = 38;
        var KEY_DOWN = 40;
        var KEY_LEFT = 37;
        var KEY_RIGHT = 39;

        switch (params.key) {
            case KEY_DOWN:
                previousCell = params.previousCellDef;
                return suggestedNextCell;

            case KEY_UP:
                previousCell = params.previousCellDef;
                return suggestedNextCell;

            case KEY_LEFT:
            case KEY_RIGHT:
                return suggestedNextCell;
            default:
                throw "this will never happen, navigation is always on of the 4 keys above";
        }
    }
    // grid function callback on ready state
    onGridResize() {
        if (!this.suppressSizeToFit) {
            this.gridApi.sizeColumnsToFit();
        }
    }
    
    // Set Grid no record height 
    setGridOverflow =()=>{
        if (this.get_rows() && this.get_rows().length === 0) {
            let grid_id:any = document.getElementById(this.props.id || 'myGrid');
            if (grid_id) {
              let ag_header = grid_id.querySelector('.ag-header').offsetHeight;
              let ag_body = grid_id.querySelector('.ag-body').offsetHeight;
              let ag_overlay: any = grid_id.querySelector('.ag-overlay');
              ag_overlay.style.marginTop = `${ag_header}px`;
              ag_overlay.style.height = `${ag_body}px`;
              ag_overlay.style.minHeight = `30px`;
            }
          }
    }
    // Grid data wrap
    onColumnResized(event) {
        if (event.finished && this.is_mounted) {
            this.setState({
                timeout: setTimeout(() => { this.gridApi.resetRowHeights(); }, 200)
            });
        }
    }
    // grid row click function call
    onSelectionChanged() {
        if (this.props.radioSelection && this.props.radioSelection === true) {
            var selectedRows = this.gridApi.getSelectedRows();
            this.props.onRowSelection && this.props.onRowSelection(selectedRows);
        } else if (this.props.onRowSelection) {
            const selectedRows = filterSelectedDisplay(this.gridApi);
            this.props.onRowSelection(selectedRows);
        }
    }

    // grid row double click function call
    onRowDoubleClick({ data, node, rowIndex }) {
        if (node.group) {
            this.props.onRowDoubleClicked && this.props.onRowDoubleClicked(data);
        }
    }

    // grid row single click function call
    onRowClick({ data, node, rowIndex }) {
        if (node.group) {
            this.props.onRowDoubleClicked && this.props.onRowDoubleClicked(data);
        }
    }
    onGroupRowClicked({ data, node, rowIndex }): void {
        if (node.group) {
            this.props.onGroupRowClicked && this.props.onGroupRowClicked(data, node, rowIndex);
        }
    }
    // grid row cell click function call
    onCellClicked(params) {
        this.props.onCellClicked && this.props.onCellClicked({ data: params.data, column: params.column });
    }
    // grid row cell click function call
    onCellFocused(params) {
        this.focused_cell_column = params.column;
        this.focused_cell_index = params.rowIndex;
        if (this.props.disableHover && this.focused_cell_column !== null && this.focused_cell_index !== null) {
            this.gridApi.getFocusedCell();
            this.gridApi.clearFocusedCell();
        }
    }

    onCellMouseOver(params) {
        if (!this.props.disableHover && params.rowIndex !== this.focused_cell_index && !params.rowPinned && params.node.group) {
            this.gridApi.setFocusedCell(params.rowIndex, params.column.colId);
        }
    }

    onCellMouseOut(params) {
    }

    // grid Pagination Changed callback
    onFilterChanged({ api }) {
        const row_count = api.rowModel.rowsToDisplay.length;
        if (this.state.grouped_fields.length > 0) {
            this.on_group_change(this.state.grouped_fields);
        }
        if (this.is_mounted) {
            this.setState({
                re_render: false,
                total_record: row_count,
            });
        }
        if (this.props.on_filter_button_click) {
            this.props.on_filter_button_click();
        }
        if (this.props.onRowSelection) {
            const selectedRows = filterSelectedDisplay(api);
            this.props.onRowSelection(selectedRows);
        }
        clear_filter(api);
    }
    getOptions = () => {
        let attributes = {};
        if (this.props.checkboxSelection && this.props.checkboxSelection === true) {
            attributes = { ...attributes, ...{ rowSelection: 'multiple', suppressRowClickSelection: true } };
        } else if (this.props.radioSelection) {
            attributes = { ...attributes, ...{ rowSelection: 'single', suppressRowClickSelection: true } };
        } else if (this.props.selectionType) {
            attributes = { ...attributes, ...{ rowSelection: this.props.selectionType } };
        }
        if (this.props.gridAutoHeight) {
            attributes = { ...attributes, ...{ gridAutoHeight: this.props.gridAutoHeight } };
        }
        if (this.props.suppressColumnVirtualisation) {
            attributes['suppressColumnVirtualisation'] = true;
        }
        if (this.props.isTotal && this.props.isTotal === true) {
            attributes = { ...attributes, ...{ getRowStyle: this.getRowStyleTotal.bind(this) } };
        }
        if (this.props.onCellClicked) {
            attributes = { ...attributes, ...{ onCellClicked: this.props.onCellClicked } };
        }
        if (this.props.isRowSelectable) {
            attributes['isRowSelectable'] = this.props.isRowSelectable;
        }
        if (this.props.rowClassRules) {
            attributes = { ...attributes, ...{ rowClassRules: this.props.rowClassRules } };
        }
        if (this.props.rowGroupPanelShow) {
            attributes['getNodeChildDetails'] = this.getNodeChildDetails.bind(this);
        }
        return attributes;
    }

    getRowStyleTotal(params) {
        if (params && (params.node.rowIndex == params.api.rowModel.rowsToDisplay.length - 1)) {
            return { fontWeight: 600 }
        }
        return null;
    }
    
    gridStyle = () => {
        if (this.props.gridAutoHeight && this.props.gridAutoHeight === true) {
            return { ...GRID_AUTO_HEIGHT_STYLE };
        } else {
            return { ...GRID_BASE_STYLE, ...this.props.style };
        }
    }
    isRowSelectable(rowNode) {
        if (rowNode.data && rowNode.data.participants && rowNode.data.participants.length > 0) return false;

        return true;
    }
    componentDidMount() {
        this.is_mounted = true;

        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }
    componentWillUnmount() {
        this.is_mounted = false;
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
        }

        if (this.props.onRef) {
            this.props.onRef(this);
        }

        if (this.container_grid) {
            this.container_grid.removeEventListener('mouseleave', this.handle_mouse_leave);
        }
    }

    onDragStart = (dragEvent) => {
        var userAgent = window.navigator.userAgent;
        var isIE = userAgent.indexOf('Trident/') >= 0;
        let column_value = dragEvent.target.dataset;
        if (column_value) {
            dragEvent.dataTransfer.setData(isIE ? 'text' : 'text/plain', JSON.stringify(column_value));
        }
    };

    handle_header_click = (e) => {
        let items = this.gridApi.getSortModel();
        if (this.ref_group_panel) {
            this.ref_group_panel.set_sort_model(items);
        }
    }

    // Render group by panel
    renderGroupPanel = () => {
        return (this.props.rowGroupPanelShow) ? (<GroupPanelComponent
            onRef={ref => (this.ref_group_panel = ref)}
            onChange={this.on_group_change}
            onSort={this.on_sort_change}
            onClick={this.handle_group_tag_click}
        />) : null;
    }

    // Sort callback from group by tag click
    on_sort_change = (sortModel) => {
        this.gridApi.setSortModel(sortModel);
    }

    // Group tag click event handler
    handle_group_tag_click = (data, e) => {
        let items = this.gridApi.getSortModel();
        if (items && items.length > 0) {
            let is_exist = false;
            items = items.map((value) => {
                if (value.colId == data.field) {
                    is_exist = true;
                    value.sort = value.sort ? value.sort == 'asc' ? 'desc' : null : 'asc';
                }
                return value;
            })
            if (!is_exist) {
                items.push({ colId: data.field, sort: 'asc' });
            }
        } else {
            items = [{ colId: data.field, sort: 'asc' }];
        }
        this.gridApi.setSortModel(items);
        if (this.ref_group_panel) {
            this.ref_group_panel.set_sort_model(items);
        }
    }

    on_group_change = (fields) => {
        let row = this.props.row;
        if (this.gridApi.isAnyFilterPresent()) {
            const filteredField = this.gridApi.getFilterModel();
            row = filterRowDataForGrouped(row, filteredField);
        }
        let groupedRows = group_row_by_column(row, fields, this.props);
        //if (this.props.displayGroupRowCount) {
        //    groupedRows = groupRowByColumnWithRowsCount(row, fields, this.props.column);
        //}
        if (row && row.length > 0) {
            let columnVisible = (this.props.isGroupColumnAlwaysVisible !== undefined && this.props.isGroupColumnAlwaysVisible)? true: fields.length > 0;
            this.gridColumnApi.setColumnVisible('group', columnVisible);
            this.setState(
                {
                    grouped_fields: fields,
                    grouped_rows: fields.length > 0 ? groupedRows : []
                },
                () => {
                    this.props.onGroupByChange && this.props.onGroupByChange(fields);
                }
            );
        }
    }

    get_rows = () => {
        return (this.props.rowGroupPanelShow && this.state.grouped_rows.length > 0)? this.state.grouped_rows: this.props.row;
    }

    getNodeChildDetails(rowItem) {
        return (rowItem.participants) ? {
            group: true,
            expanded: rowItem.group === 'text value',
            children: rowItem.participants,
            key: rowItem.group
        } : null;
    }

    //Render function
    render() {
        const style = this.gridStyle();
        const options = this.getOptions();
        const wrapperStyle = this.props.wrapperStyle ? this.props.wrapperStyle : { width: "100%", height: "100%" };
        const wrapperClass = this.props.wrapperClass ? this.props.wrapperClass : 'grid_wrapper';        
        return (
            <div style={wrapperStyle} className={`main-grid-wrapper ${wrapperClass}`}>
                <div
                    id={this.props.id || "myGrid"}
                    style={style}
                    className="ag-theme-balham custom-multiselect grid-case-list">
                    {this.renderGroupPanel()}
                    <AgGridReact
                        defaultColDef={this.state.defaultColDef}
                        columnDefs={this.props.column}
                        rowData={this.get_rows()}
                        icons={ICONS}
                        gridOptions={options}
                        enableFilter={true}
                        enableSorting={true}
                        suppressMenuHide={true}
                        suppressPaginationPanel={true}
                        suppressMovableColumns={this.props.suppressMovableColumns ? true : false}
                        suppressDragLeaveHidesColumns={true}
                        headerHeight={this.props.headerHeight || DIMEN.HEIGHT_HEADER}
                        frameworkComponents={this.state.frameworkComponents}
                        overlayLoadingTemplate={TEMPLATE_LOADING}
                        overlayNoRowsTemplate={this.props.emptyMessage || TEMPLATE_NO_RECORD}
                        navigateToNextCell={this.navigateToNextCell.bind(this)}
                        onCellMouseOver={this.onCellMouseOver.bind(this)}
                        onCellMouseOut={this.onCellMouseOut.bind(this)}
                        onCellFocused={this.onCellFocused.bind(this)}
                        onFilterChanged={this.onFilterChanged.bind(this)}
                        getRowHeight={this.state.getRowHeight.bind(this)}
                        onGridSizeChanged={this.onGridResize.bind(this)}
                        onGridReady={this.onGridReady.bind(this)}
                        getNodeChildDetails={this.state.getNodeChildDetails}
                        pinnedBottomRowData={this.props.pinnedBottomRowData || null}
                        onCellMouseDown={(event)=>{ this.props.onCellMouseDown && this.props.onCellMouseDown(event) }}
                        enableColResize={this.props.enableColResize ? this.props.enableColResize : false}                        
                    />
                </div>
            </div>
        );
    }
}

function inner_child_row_height(detailsLength) {

    const footer_row_height = 28;
    const inner_row_height = 39;
    const inner_header_height = 52;
    const scrollbar_height = 19;
    let row_height = 0;
    if (detailsLength != undefined && detailsLength > 0) {
        row_height = detailsLength * inner_row_height + inner_header_height + scrollbar_height;
        return row_height;
    }
    return footer_row_height;
}
export default GridView;