import * as React from 'react';
import { connect } from 'react-redux';
import { Accordion, Input, Button, Dimmer, Form, Grid, Icon, Loader } from 'semantic-ui-react';
import { toastr as toaster } from 'react-redux-toastr';
import {
    formatNumber,
    currencyFormatter,
    messages,
    summary_forecast_clinic_stats_configuration
} from '../../reports/report_constants';
import ReportHeader from '../../reports/component/report_header';
import ReportFooter from '../../reports/component/report_footer';
import { format_amount } from '../../payment/utility';

export class SummaryForecastClinicStatsComponent extends React.Component<any, any> {
    is_mounted: boolean;

    constructor(props: any) {
        super(props);
        this.state = {
            clinic_summary_stats: this.props.clinic_summary_stats,
            error: {}
        };
        this.clinic_summary_stats = this.props.clinic_summary_stats;
    }

    clinic_summary_stats: any = {
        new_patients: 0,
        actual_collections_pct: 0,
        visits: 0,
        est_payments: 0,
        charges: 0,
        est_payments_per_visit: 0,
        charges_per_visit: 0
    };

    page_metadata = summary_forecast_clinic_stats_configuration;

    UNSAFE_componentWillMount = async () => {
    };

    componentDidMount = async () => {
        this.is_mounted = true;
    };

    componentWillUnmount = () => {
        this.is_mounted = false;
    };

    render() {
        return (
            <div>
                <ReportHeader title={this.page_metadata.title} />
                <div
                    className={'report-wrapper'}
                    style={{ paddingRight: 0, background: 0, border: 0, display: 'flex', flex: 1 }}
                >
                    {this.render_program()}
                </div>
            </div>
        );
    }

    render_program = () => {
        return (
            <div id='clinic-stats-container' className='report-wrapper'
                style={{ width: '100%' }}
            >
                {this.render_stat_result()}
            </div>
        );
    };

    render_stat_result = () => {
        if (this.clinic_summary_stats) {
            let input_json = JSON.stringify({ "clinic_summary_stats": this.clinic_summary_stats, "page_metadata": this.page_metadata });
            return (
                <div className="patient-search-form">
                    <Grid>
                        <Grid.Column computer={4}>
                            <span>{this.page_metadata.new_patients_label}</span>
                        </Grid.Column>
                        <Grid.Column computer={4}>
                            <span>{this.clinic_summary_stats.new_patients}</span>
                        </Grid.Column>
                        <Grid.Column computer={4}>
                            <span>{this.page_metadata.actual_collections_pct_label}</span>
                        </Grid.Column>
                        <Grid.Column computer={4}>
                            <span>{formatNumber(this.clinic_summary_stats.actual_collections_pct)}%</span>
                        </Grid.Column>
                    </Grid>
                    <Grid>
                        <Grid.Column computer={4}>
                            <span>{this.page_metadata.visits_label}</span>
                        </Grid.Column>
                        <Grid.Column computer={4}>
                            <span>{this.clinic_summary_stats.visits}</span>
                        </Grid.Column>
                        <Grid.Column computer={4}>
                            <span>{this.page_metadata.est_payments_label}</span>
                        </Grid.Column>
                        <Grid.Column computer={4}>
                            <span>{currencyFormatter(this.clinic_summary_stats.est_payments)}</span>
                        </Grid.Column>
                    </Grid>
                    <Grid>
                        <Grid.Column computer={4}>
                            <span>{this.page_metadata.charges_label}</span>
                        </Grid.Column>
                        <Grid.Column computer={4}>
                            <span>{currencyFormatter(this.clinic_summary_stats.charges)}</span>
                        </Grid.Column>
                        <Grid.Column computer={4}>
                            <span>{this.page_metadata.est_payments_per_visit_label}</span>
                        </Grid.Column>
                        <Grid.Column computer={4}>
                            <span>{currencyFormatter(this.clinic_summary_stats.est_payments_per_visit)}</span>
                        </Grid.Column>
                    </Grid>
                    <Grid>
                        <Grid.Column computer={4}>
                            <span>{this.page_metadata.charges_per_visit_label}</span>
                        </Grid.Column>
                        <Grid.Column computer={4}>
                            <span>{currencyFormatter(this.clinic_summary_stats.charges_per_visit)}</span>
                        </Grid.Column>
                        <Grid.Column computer={4}>
                        </Grid.Column>
                        <Grid.Column computer={4}>
                        </Grid.Column>
                    </Grid>
                    <Input
                        name={'clinic_summary_stats_data'}
                        type={'hidden'}
                        id={'clinic_summary_stats_data'}
                        value={input_json}
                    />
                </div>
            );
        }
    };

}

// Get user and login details from store.
const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details
    };
};

export const summary_forecast_clinic_stats_print = (data) => {
    var print_html = '';
    print_html += `<div style='font-weight: bold;font-size:16px'>${data.page_metadata.title}</div>`;
    print_html += `<table style='border-top: 1px solid black'><thead>`;
    print_html += `<tr style='font-weight: bold;font-size:16px;'><td>${data.page_metadata.new_patients_label}</td><td>${data.page_metadata.actual_collections_pct_label}</td><td>${data.page_metadata.visits_label}</td><td>${data.page_metadata.est_payments_label}</td><td>${data.page_metadata.charges_label}</td><td>${data.page_metadata.est_payments_per_visit_label}</td><td>${data.page_metadata.charges_per_visit_label}</td></tr>`;
    print_html += `</thead>`;
    print_html += `<tbody>`;
    print_html += `<tr><td>${data.clinic_summary_stats.new_patients}</td><td>${formatNumber(data.clinic_summary_stats.actual_collections_pct)}%</td><td>${data.clinic_summary_stats.visits}</td><td>${currencyFormatter(data.clinic_summary_stats.est_payments)}</td><td>${currencyFormatter(data.clinic_summary_stats.charges)}</td><td>${currencyFormatter(data.clinic_summary_stats.est_payments_per_visit)}</td><td>${currencyFormatter(data.clinic_summary_stats.charges_per_visit)}</td></tr>`;
    print_html += `</tbody></table>`;
    return print_html;
}

export const clinic_summary_stats = (stat) => {
    return { clinic_summary_stats: stat };
};

export default connect(mapStateToProps)(SummaryForecastClinicStatsComponent);
