import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Modal } from 'semantic-ui-react';
import * as global_constants from '../../global_constants';
// Sub Layouts
import { refresh_token, auto_logout } from '../../login/action/login_action';
import * as local_storage from './../../shared/local_storage_utility';
import { close_chat, get_lauch_darkley_key_value } from '../../shared/utility';
import { magnus_plat_5681_isolate_auth0 } from '../../feature_flip_constant';

/*
 1) Display the Idle warning popup
 2) Get new token if user becomes active
 3) Logout application if user is inactive for a certain time
 */
export class IdleTokenHandleComponent extends React.Component<any, any> {
    [x: string]: any;
    _is_mounted= false;
    constructor(props) {
        super(props);
        this.state = {
            show_token_popup: props.show_token_popup,
            idle_time_warn: global_constants.constants.idle_popup_warning_time
        }
        this.props.auto_logout(false);
    }

    UNSAFE_componentWillMount() {
        if (this.props.show_token_popup == true) {
            this.idle_time_count_down();
        }
    }
    componentDidMount() {
        this._is_mounted = true;
    }

    shouldComponentUpdate(nextProps, nextState) {
        // if the idle time is 0 , logout application
        if (nextState.idle_time_warn == 0) {
            clearInterval(this.idle_warn);
            close_chat();
            setTimeout(() => {
                if (this.props.magnusPlat5681IsolateAuth0) {
                    if (sessionStorage['sso_user_login']) {
                        this.props.history.push('/v2/logout');
                    } else {
                        this.props.history.push('/logout');
                    }
                } else {
                    this.props.history.push('/logout');
                }
            }, 0);
            return false;
        }

        if (this.props.show_token_popup != nextProps.show_token_popup) {
            if (this._is_mounted) {
                this.setState({
                    idle_time_warn: global_constants.constants.idle_popup_warning_time,
                    show_token_popup: nextProps.show_token_popup,
                })
            }

            if (nextProps.show_token_popup == true) {
                this.idle_time_count_down();
            }
            if (nextProps.show_token_popup == false) {
                this.reset_session();
            }

        }
        return true;
       
    }

    // Clear Interval time on Unmount
    componentWillUnmount() {
        clearInterval(this.idle_warn);
    }
   

    idle_time_count_down = () => {
        // Decrease the time count down
        this.idle_warn = setInterval(() => {

            var idle_time_warn = this.state.idle_time_warn

            idle_time_warn--;

            // we dont want Prompt navigation to trigger
            if (idle_time_warn == 0) {
                this.props.auto_logout(true);
            }

            if (this._is_mounted) {
                this.setState({
                    idle_time_warn: idle_time_warn
                })
            }
           

        }, 1000);

    }

     //call this method to refresh token
    reset_session = () => {

        this.props.update_show_token_popup(false);

        // showing idle popup false
        local_storage.set("prompt_for_idle_timeout", false);
        console.log("Start Idle Token, refreshing token, user data =>", this.props.user_login_details.user_data.data);
        // API call for refresh token
        this.props.refresh_token(this.props.user_login_details.user_data.data);
        console.log("End Idle Token, refreshing token, user data =>", this.props.user_login_details.user_data.data);

        clearInterval(this.idle_warn);
        if (this._is_mounted) {
            this.setState({
                show_token_popup: false
            })
        }
    }
    
    render() {
        return (
            <>
            <Modal
                open={this.state.show_token_popup} centered={false}
                header='Do you want to continue this session?'
                content={`You will be logged off in ${this.state.idle_time_warn} seconds due to inactivity.`}
                closeOnDimmerClick={false}
                closeOnDocumentClick={false}
                actions={[{ key: 'OK', content: 'Click Here to Continue', positive: true, onClick: this.reset_session }
               ]}
            />
        </>)
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        refresh_token: refresh_token,
        auto_logout: auto_logout
    }, dispatch)
}


const mapStateToProps = (state) => {

    return {
        user_login_details: state.user_login_details,
        user_details: state.user_login_details,
        shared_details: state.shared_details,
        magnusPlat5681IsolateAuth0: get_lauch_darkley_key_value(state.launch_darkly, magnus_plat_5681_isolate_auth0)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IdleTokenHandleComponent))