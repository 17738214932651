import * as React from 'react';

export default class ComingSoonComponent extends React.Component<any, any> {
    
    render() {
        return (
            <div className="coming-soon">
                    <img src={'./../src/assets/images/under_construction.png'} />
                    </div>
        );
    }
}

