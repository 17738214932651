import * as React from 'react';
import { mm_dd_yyyy_comparator } from './comparators';
import { AND, OR, TYPE_EQUAL } from './constants';
import { filterComparator, GridFilterFrom, strip, add_link_value, get_node_value } from './utils';

class LinkDateFilter extends React.Component<any, any> {
    valueGetter: any;
    input1: any;
    input2: any;
    _is_mounted = false;
    //Get innitalise data when component created
    constructor(props) {
        super(props);
        this.state = {
            filterData: [],
            selectData: [],
            data: this.getRowData(),
            selectAll: false,
            shouldUpdateChild: true,
            clearAll: false,
            valueOption1: TYPE_EQUAL,
            valueOption2: OR,
            valueOption3: TYPE_EQUAL,
            textValue1: '',
            textValue2: ''
        };
        this.valueGetter = this.props.valueGetter;
    }

    //Filter status to active or inactive
    isFilterActive() {
        const { textValue1, textValue2, filterData } = this.state;
        if (textValue1 !== null && textValue1 !== undefined && textValue1 !== '') return true;
        if (textValue2 !== null && textValue2 !== undefined && textValue2 !== '') return true;

        return filterData !== null && filterData !== undefined && filterData.length > 0;
    }

    //Filter records
    doesFilterPass(params) {
        const nValue = this.valueGetter(params.node);
        if (nValue != null && nValue != undefined) {
            const textValue = strip(nValue);
            const nodeValue = textValue.toString().trim();
            const { textValue1, textValue2, valueOption1, valueOption2, valueOption3, filterData } = this.state;
            if (textValue1.length > 0 && textValue2.length > 0 && valueOption2.length > 0) {
                const matchFirst = filterComparator(valueOption1, nodeValue, textValue1);
                const matchSecond = filterComparator(valueOption3, nodeValue, textValue2);
                if (valueOption2 === OR && (matchFirst || matchSecond)) {
                    return true;
                } else if (valueOption2 === AND && (matchFirst && matchSecond)) {
                    return true;
                }
            } else if (textValue1 && textValue1.length > 0 && filterComparator(valueOption1, nodeValue, textValue1)) {
                return true;
            } else if (textValue2 && textValue2.length > 0 && filterComparator(valueOption3, nodeValue, textValue2)) {
                return true;
            }

            return this.state.filterData.findIndex(value => value.toString().trim() == nodeValue) >= 0;
        }
        return false;
    }

    // Get filter model
    getModel() {
        return { value: this.state.filterData };
    }

    // Set filter model
    setModel(model) {
        if (this._is_mounted) {
            this.setState({ filterData: model ? model.value : [] });
        }
    }

    // Do work after UI load
    afterGuiAttached(params) {
        this.focus();
    }

    focus() {
    }

    // call function from outside the grid component
    componentMethod(message) {
        alert(`Alert from PartialMatchFilterComponent ${message}`);
    }

    // close filter popup
    handleClose = (event) => {
        this.props.api.menuFactory.hidePopup();
    }

    // Checkbox filter row check/uncheck in the filter popup
    onCheckChange = (checked, item) => {
        let list = [];
        if (checked) {
            list = [...this.state.selectData, item];
        } else {
            list = this.state.selectData.filter(value => (value !== item));
        }
        if (this._is_mounted) {
            if (this.state.clearAll === true) {
                this.setState({ selectData: [...list], clearAll: false });
            } else {
                this.setState({ selectData: [...list] });
            }
        }
    }

    // Check all rilter checkbox in the popup
    onChangeSelectAll = (event) => {
        if (this._is_mounted) {
            if (event.target.checked) {
                if (this.state.clearAll === true) {
                    this.setState({
                        selectAll: true,
                        shouldUpdateChild: true,
                        clearAll: false,
                        selectData: [...this.state.data]
                    });
                } else {
                    this.setState({
                        selectAll: true,
                        shouldUpdateChild: true,
                        selectData: [...this.state.data]
                    });
                }
            } else {
                this.setState({
                    selectAll: false,
                    shouldUpdateChild: true,
                    selectData: []
                })
            }
        }
    }

    // Load column data into filter popup
    getRowData = () => {
        const { field } = this.props.colDef;
        let listItems = [];
        this.props.rowModel.rowsToDisplay.forEach((node) => {
            if (node.group) {
                get_node_value(node, (childNode) => {
                    add_link_value(this.props.valueGetter(childNode), listItems);
                });
            } else {
                add_link_value(this.props.valueGetter(node), listItems);
            }
        });
        return listItems.sort(mm_dd_yyyy_comparator);
    }

    //Filter button function call
    handleFilter = () => {
        const { selectData, data, selectAll } = this.state;
        //if (selectData.length !== data.length) {
        if (this._is_mounted) {
            this.setState({
                filterData: [...this.state.selectData],
                textValue1: this.input1.value,
                textValue2: this.input2.value
            }, () => {
                this.props.filterChangedCallback();
                this.props.api.menuFactory.hidePopup();
            });
        }
    }

    // Clear button function call
    handleClear = () => {
        this.input1.value = '';
        this.input2.value = '';
        if (this._is_mounted) {
            this.setState({
                filterData: [],
                selectData: [],
                clearAll: true,
                shouldUpdateChild: true,
                valueOption1: TYPE_EQUAL,
                valueOption2: OR,
                valueOption3: TYPE_EQUAL,
                textValue1: '',
                textValue2: ''
            }, () => {
                this.props.filterChangedCallback();
                this.props.api.menuFactory.hidePopup();
            });
        }
    }

    //Component update function
    componentDidUpdate(previousProps, previousState) {
        if (previousState.selectData.length !== this.state.selectData.length) {
            const { selectData, data, selectAll } = this.state;
            const isAllChecked = selectData.length === data.length;
            if (isAllChecked !== selectAll && this._is_mounted) {
                this.setState({
                    selectAll: isAllChecked,
                    shouldUpdateChild: false
                });
            }
        }
    }
    componentDidMount() {
        this._is_mounted = true;
    }
    componentWillUnmount() {
        this._is_mounted = false;
    }
    valueOptionHandler = (result: { value: any; }, option: any) => {
        if (this._is_mounted) {
            this.setState({
                [`value${option}`]: result.value
            });
        }
    }

    inputHandler = (el: any, input2: string | number) => {
        return this[input2] = el
    }     
    //Render function
    render() {
        return (
            <React.Fragment>
                <GridFilterFrom
                    options={this.state.data}
                    onChangeSelectAll={this.onChangeSelectAll}
                    selectAll={this.state.selectAll}
                    shouldUpdateChild={this.state.shouldUpdateChild}
                    clearAll={this.state.clearAll}
                    handleFilter={this.handleFilter}
                    handleClear={this.handleClear}
                    handleClose={this.handleClose}
                    valueOptionHandler={this.valueOptionHandler}
                    onCheckChange={this.onCheckChange}
                    inputHandler={this.inputHandler}
                />
            {/*            
            <div className="filter-container">
                <span>Show items with value that
                  <Icon name='close' onClick={this.handleClose} />
                </span>
                <input type="checkbox" style={{ margin: '0 4px' }}
                    checked={this.state.selectAll}
                    onChange={this.onChangeSelectAll} />Select All
                <div className="multiselect-scrollbox">{this.renderItems()}</div>
                <div>
                    <Form>
                        <Form.Select fluid options={OPTIONS}
                            defaultValue={TYPE_EQUAL}
                            onChange={(e, result) => this._is_mounted ? this.setState({ valueOption1: result.value }) : ''} />
                        <input type="text" ref={el => this.input1 = el} />
                        <Form.Select className="half-width"
                            options={OPERATOR}
                            defaultValue={OR}
                            onChange={(e, result) => this._is_mounted ? this.setState({ valueOption2: result.value }) : ''} />
                        <Form.Select
                            fluid options={OPTIONS}
                            defaultValue={TYPE_EQUAL}
                            onChange={(e, result) => this._is_mounted ? this.setState({ valueOption3: result.value }):''} />
                        <input type="text" ref={el => this.input2 = el} />
                    </Form>
                    <Button primary onClick={this.handleFilter}>Filter</Button>
                    <Button basic onClick={this.handleClear}>Clear</Button>
                </div>
            </div>
            */}
            </React.Fragment>            
        );
    }
};

export default LinkDateFilter;