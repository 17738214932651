import * as React from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as session_storage from '../../shared/session_storage_utility';
import { refresh_token, set_active_patient, unsaved_form_data_for_prompt, custom_prompt, get_master_data, get_master_data_by_type } from '../../login/action/login_action';
import * as local_storage from '../../shared/local_storage_utility';
import { get_company_detail_data, get_patient_company_info, set_notes_modal_status, set_prevent_company_model_status } from '../../shared/action/shared_action'
import LedgerVisitPopUpComponent from '../component/ledger_visit_pop_up';
import { LINK_ROUTE_EDIT_PAGE, AdminConstant } from '../report_constants';
import { admin_constant_company_detail } from '../../user/action/user_action';
import { custom_prompt_values, custom_prompt_stages } from "../../global_constants";
import { MasterData } from "../../login/login_constants";
import { detect_ie, update_company_details, clear_sotrage_after_company_change_on_main_page } from '../../shared/utility';

export class LinkRendererComponent extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            is_show_pop_up: false,
            prevent_change_model: false,
            charge_id: 0
        };
    }

    // Declare Class level variable
    linkParam = {
        link_value: '',
        row_value: '',
        patient_id: ''
    };
    show_prompt = false;
    is_clicked = false
    // Function used to get link value from hyperlink.
    get_link_value = row_value => {
        if (!row_value) {
            return '';
        }
        row_value = row_value.replace('<b>', '').replace('</b>', '');
        var start = row_value.indexOf('>') + 1;
        var end = row_value.indexOf('</');
        return row_value.substring(start, end).trim();
    };

    // Function used to get parameter value from hyperlink.
    get_params = query_param => {
        var query_param_array;
        if (!query_param) {
            return '';
        }
        query_param_array = query_param.split('>');
        query_param_array = query_param_array[0]
            .replace('<a ', '')
            .replace('</a>', '')
            .replace('>', '')
            .replace(/["]/g, '')
        query_param_array = query_param_array.split('?');
        query_param_array = query_param_array[1]
            .replace('<a ', '')
            .replace('</a>', '')
            .replace('>', '')
            .replace(/["]/g, '')
        return query_param_array;
    };

    // Function used to set hyperlink parameter.
    set_hyperlink_value = (row_value, r4_to_r6_routes) => {
        if (!row_value) {
            return '';
        }
        let link_value = '';
        let query_param = '';
        let query_param_array = [];
        let key = '';
        let value = '';
        row_value = row_value.toString()
            .replace(/%a0/g, "")
            .replace(/%d0/g, "")
            .replace(/%2c/g, ",")
            .replace(/%20/g, " ")
            .replace(/%23/g, "#");
        try {
            row_value = decodeURIComponent(row_value);
        } catch (e) {
            row_value = row_value;
        }

        row_value = row_value.replace('<b>', '').replace('</b>', '');

        this.linkParam = {
            link_value: row_value,
            row_value: '',
            patient_id: ''
        };

        link_value = this.get_link_value(row_value);
        query_param = link_value && this.get_params(row_value);
        query_param = link_value && query_param.trim();
        row_value = row_value.toLowerCase().trim();
        r4_to_r6_routes.filter((route) => {
            let r4_route = route.r4_route.toLowerCase().trim();
            let r6_route = route.r6_route.toLowerCase().trim();
            let route_parameters = route.parameters ? route.parameters.toLowerCase().trim() : '';
            
            if (row_value.indexOf(r4_route) > -1) {
                if (route.has_parameter && route_parameters) {
                    route_parameters = route_parameters.split(',');
                    query_param_array = query_param.split('&');
                    route_parameters.filter(param => {
                        param = query_param_array.find(x => x.toLowerCase().trim().indexOf(param) > -1)
                        param = param && param.split('=');
                        key = param && param[0];
                        value = param && param[1];
                       
                        if (r6_route.indexOf(key.toLowerCase()) > -1) {
                            var r6_route_chunk = r6_route.split('/');
                            r6_route_chunk = r6_route_chunk.map((item) => {
                                if (item == key.toLowerCase()) {
                                    item = value;
                                } else {
                                    if (item.indexOf(key) > -1) {
                                        var r6_route_inner_chunk = item.split('?');
                                        r6_route_inner_chunk = r6_route_inner_chunk.map((inner_item) => {
                                            if (inner_item == key) {
                                                inner_item = value;
                                            }
                                            return inner_item;
                                        })
                                        item = r6_route_inner_chunk.join('?');
                                    }
                                }
                                return item;
                            })
                            r6_route = r6_route_chunk.join('/');
                        }                     
                        if (key == 'id') {
                            this.linkParam.patient_id = value;
                        }
                    })
                    this.linkParam = {
                        ...this.linkParam,
                        link_value: link_value,
                        row_value: r6_route
                    }
                }
                else {
                    r6_route = r6_route.replace('param', encodeURIComponent(query_param));
                    // In case of financial or patient reports
                    if (this.props.location && this.props.location.pathname.toLowerCase().indexOf('patient') > -1) {
                        r6_route = `/patient${r6_route}`
                    }
                    this.linkParam = {
                        link_value: link_value,
                        row_value: r6_route,
                        patient_id: ''
                    }
                }

            }
        })
    };

    componentDidUpdate = (previousProps, previousState) => {
        // if custom_prompt state is continue then invoke  and change company and continue navigate
        if (
            this.props.check_custom_prompt_change != null &&
            this.props.check_custom_prompt_change.stage &&
            this.props.check_custom_prompt_change.value == custom_prompt_values.CHANGE_FROM_REPORT &&
            this.props.check_custom_prompt_change.stage == custom_prompt_stages.CONTINUE &&
            session_storage.get('prompt_contiue_listner') == true
        ) {
            session_storage.remove('prompt_contiue_listner')
            this.change_company_patient(this.props.check_custom_prompt_change.params);
            this.props.set_custom_prompt({});
            this.props.set_notes_modal_status(false);
        }
    };

    UNSAFE_componentWillMount = () => {
        this.set_hyperlink_value(this.props.value, this.props.r4_to_r6_routes);
    };

    // Change company in application
    change_company = async (company_data) => {
        var user_data = this.props.user_login_details.user_data.data;
        user_data.company_id = company_data.id;
        user_data.company_name = company_data.name;
        user_data.gpms_code = company_data.gpms_code;

        // Set auth data in local storage on company changed 
        update_company_details(company_data);

        await this.props.refresh_token(user_data, true);
        console.log("End Report, refreshing token, user data =>", this.props.user_login_details.user_data.data);  

        // get master data when user change company
        this.props.get_master_data(
            MasterData.all,
            this.props.user_login_details.user_data.data.user_id,
            this.props.user_login_details.user_data.data.token_details.access_token,
            true
        );
        this.props.get_master_data_by_type(
            MasterData.all,
            this.props.user_login_details.user_data.data.user_id,
            this.props.user_login_details.user_data.data.token_details.access_token,
            true
        );
    }

    // Function used to navigate from report links
    click_to_navigate = async (e) => {
        if(this.is_clicked) return; // handle to restrict multiple clicks on the same time
        this.is_clicked = true;

        session_storage.set('is_navigate', true);
        let navigate_to = e.currentTarget.attributes['data-attr'].value;
        let report_id = this.props.location.state ? this.props.location.state.report_id : null;
        let data_patient_id = '';
        
        // Open Modal From Ledger Visit Report
        if (navigate_to && navigate_to.indexOf('claim_adjustment_component') > -1) {
            let charge_id = e.target.innerText;
            this.setState({
                is_show_pop_up: true,
                charge_id
            });
            return;
        }
    
        // Check if next navigation need patient id
        if (e.currentTarget.attributes['data-patient-id'] && e.currentTarget.attributes['data-patient-id'].value) {
            data_patient_id = e.currentTarget.attributes['data-patient-id'].value;
        }
    
        // Payment Integration Summary return to first level of report after cancel click
        if (report_id == 157 && e.currentTarget && e.currentTarget.innerHTML && e.currentTarget.innerHTML == 'Cancel') {
            this.props.history.push('/report/report_data', { report_id: report_id, is_sub_report_controls: false });
        }
    
        let params = {
            navigate_to,
            data_patient_id,
            report_id
        };
        this.check_change_company_situation(params);
    };

    /**
     * Check navigation in relate to different company
     * Check navigation in relate to different patient
     */
    check_change_company_situation = async (params) => {
        let { data_patient_id, report_id, navigate_to } = params;
        let auth_data = local_storage.get('auth_data');
        let current_company_id = auth_data && auth_data.company_id;
        let token = auth_data && auth_data.token_details && auth_data.token_details.access_token;
        let company: any;
        let is_company_change = false;
        let is_patient_change = false;

        /**
         * Check Company ID in report search criteria
         */
        if (session_storage.get('report_search_criteria')  && !(navigate_to.indexOf('sub_report_data') > -1)) {
            let report_search_criteria = session_storage.get('report_search_criteria');
            let company_data = report_search_criteria.find((x) => x.default_value == current_company_id);
            /**
             * if navigation related to other then current company
             */
            if (!company_data) {
                if(detect_ie()){
                    let count = parseInt(local_storage.get("open_tab_count"));
                    if(count > 1) {
                        this.props.set_prevent_company_model_status(true);
                        this.is_clicked = false;
                        return false;
                    }
                }
                company_data = report_search_criteria
                    .filter((item) => {
                        return item.name.toLowerCase().indexOf('parent_company');
                    })
                    .find((x) => x.name.toLowerCase().indexOf('company') > -1);

                if (company_data === undefined) {
                    this.props.history.push(navigate_to, {
                        report_id: report_id,
                        is_sub_report_controls: false
                    });
                    return;
                }

                if (company_data && company_data.default_value == -1) {
                    let array_navigate_to = navigate_to.split('/');
                    if (array_navigate_to.length >= 3) {
                        let item_id = array_navigate_to[array_navigate_to.length - 1];
                        let item_type = array_navigate_to[array_navigate_to.length - 2];
                        let patient_id = '';

                        if (item_type == 'patient') {
                            patient_id = item_id;
                        } else if (data_patient_id) {
                            patient_id = data_patient_id;
                            item_type = 'patient';
                        }

                        switch (item_type) {
                            case 'patient':
                                await get_patient_company_info(patient_id, token).then(async (response) => {
                                    if (
                                        response &&
                                        response.data &&
                                        response.data.data &&
                                        response.data.data.id != current_company_id
                                    ) {
                                        is_company_change = true;
                                        is_patient_change = true;
                                        company = response.data.data;
                                        console.log('5', navigate_to);
                                    }
                                });
                                break;
                            case 'insurance':
                            case 'referring_physician':
                            case 'procedure_code':
                            case 'locations':
                            case 'provider':
                                switch (true) {
                                    case item_type === 'provider':
                                        item_type = `${item_type}_code`;
                                        break;
                                    case item_type === 'locations':
                                        item_type = `location_code`;
                                        break;
                                    default:
                                        item_type;
                                        break;
                                }
                                await admin_constant_company_detail(item_id, AdminConstant[item_type], token).then(
                                    async (response) => {
                                        if (
                                            response &&
                                            response.data &&
                                            response.data.data &&
                                            response.data.data.id != current_company_id
                                        ) {
                                            is_company_change = true;
                                            is_patient_change = true;
                                            company = response.data.data;
                                            console.log('6', navigate_to);
                                        }
                                    }
                                );
                                break;
                            default:
                                break;
                        }
                    }
                } else {
                    await get_company_detail_data(company_data.default_value, token).then(async (response) => {
                        if (response && response.data) {
                            is_company_change = true;
                            is_patient_change = true;
                            company = response.data;
                            console.log('7', navigate_to);
                        }
                    });
                }
            }
        }
        if (!is_patient_change && data_patient_id != '') {
            is_patient_change = data_patient_id != this.props.active_patient_id;
        }
        this.show_prompt = is_company_change || is_patient_change;
        let new_params = {
            is_company_change: is_company_change,
            is_patient_change,
            company,
            navigate_to,
            data_patient_id,
            report_id
        };

        if (this.show_prompt) {
            // company and patient change is going to happen
            if (this.props.check_unsaved_form_data_for_prompt) {
                this.show_prompt = false;
                session_storage.set('prompt_contiue_listner', true);
                this.props.set_custom_prompt({
                    value: custom_prompt_values.CHANGE_FROM_REPORT,
                    stage: custom_prompt_stages.INVOKE,
                    params: new_params
                });
                return;
            } else {
                this.change_company_patient(new_params);
            }
        } else {
            this.props.history.push(navigate_to, { report_id: report_id, is_sub_report_controls: false });
        }
    }

    change_company_patient = async (params) => {
        let { is_company_change: is_company_change, is_patient_change, company, navigate_to, data_patient_id, report_id } = params;
        let x = await (async () => {
            // Change company
            if (is_company_change) {
                local_storage.set('report_navigation', {
                    pathname: navigate_to,
                });
                local_storage.set('last_report_navigation', window.location)
                local_storage.set('company_change_on_same_tab', true);
                local_storage.set('report_navigation_active_patient', data_patient_id);
                const report_search_criteria=  session_storage.get('report_search_criteria');
                if(report_search_criteria){
                    local_storage.set('report_search_criteria', report_search_criteria);
                }
                await this.change_company(company);
            }
            // Change patient start
            if (is_patient_change) {
                await this.props.set_active_patient(data_patient_id);
                session_storage.set('active_patient', data_patient_id);
            }
            // Navigate to link
            this.props.history.push(navigate_to, { report_id: report_id, is_sub_report_controls: false });
            clear_sotrage_after_company_change_on_main_page();
            return;
        })();
    }

    /**
     * Check link drill-down page edit route
     * Call for- patient, procedure_code, referring_physician, insurance
    */
    is_link_edit_route = (navigate_to) => {
        if (navigate_to) {
            return LINK_ROUTE_EDIT_PAGE.indexOf(navigate_to.substring(0, navigate_to.lastIndexOf('/'))) > -1;
        }
        return false;
    }

    // Function calls Close button of popup to close modal
    on_pop_up_close = () => {
        this.is_clicked = false;
        this.setState({ is_show_pop_up: false })
    }

    render() {
        return (
            <React.Fragment>
                {this.linkParam.link_value === 'Total' || !this.linkParam.row_value ? (
                    this.linkParam.link_value
                ) : (
                    <span
                        className={'href_link'}
                        data-attr={this.linkParam.row_value}
                        data-patient-id={this.linkParam.patient_id}
                        onClick={this.click_to_navigate}
                        dangerouslySetInnerHTML={{ __html: this.linkParam.link_value }}
                    />
                )}
                {this.state.is_show_pop_up && (
                    <LedgerVisitPopUpComponent
                        show_modal={this.state.is_show_pop_up}
                        on_pop_up_close={this.on_pop_up_close}
                        row_params={this.state.row_params}
                        charge_id={this.state.charge_id}
                    />
                )}
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        check_unsaved_form_data_for_prompt: state.user_login_details.unsaved_form_data_for_prompt,
        check_custom_prompt_change: state.user_login_details.custom_prompt,
        active_patient_id: state.user_login_details.active_patient_id
    }
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        refresh_token: refresh_token,
        set_notes_modal_status: set_notes_modal_status,
        set_active_patient: set_active_patient,
        set_unsaved_form_data_for_prompt: unsaved_form_data_for_prompt,
        set_custom_prompt: custom_prompt,
        get_master_data: get_master_data,
        get_master_data_by_type: get_master_data_by_type,
        set_prevent_company_model_status
    }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LinkRendererComponent))