import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { Button, Dimmer, Grid, Input, Loader, Modal } from 'semantic-ui-react';
import * as global_constant from '../../global_constants';
import { left_menu_permission } from '../../shared/action/shared_action';
import * as local_storage from '../../shared/local_storage_utility';
import * as session_storage from '../../shared/session_storage_utility';
import {
    get_lauch_darkley_key_value, get_sso_token_value_from_url, setDefaultAuthData, setup_tabId_in_localStorage
} from '../../shared/utility';
import {
    handle_click_on_enter, handle_focus_on_shift_tab, handle_focus_on_tab
} from './../../shared/tab_navigation_utility';
// Sub Layouts
import {
    change_password_required,
    clear_cache,
    get_master_data,
    get_master_data_by_type,
    re_send_ip_registration_link, set_active_patient, user_authentication
} from '../action/login_action';
import * as login_constants from '../login_constants';

import { setTimeout } from 'timers';
import { sso_user_verification_post } from '../../sso/action/sso_action';
import CryptoService from './../../shared/crypto_service/crypto_service';
import LaunchDarkley from './../../shared/launch_darkley/launch_darkley';
import { magnus_plat_5681_isolate_auth0 } from '../../feature_flip_constant';

var pendoInit = require('../../../../config/pendo_init');
/*
  1) Login Page of the application
  2) Show popup if the password is going to expire
  */
export class LoginComponent extends React.Component<any, any> {
    _ff_dashboard_new = true;
    constructor(props) {
        super(props);
        // hard reload if new build notification
        if (session_storage.get('hard_reload_application')) {
            session_storage.remove('hard_reload_application');
            window.location.reload(true);
        }
        session_storage.clear_all();
        local_storage.clear_all();
        // Initialize state property here
        this.state = {
            loading: false,
            is_password_expired: false,
            expired_password_alert_popup: { show: false, content: '' },
            change_password_alert_popup: { show: false, content: '' },
            is_submitted: false,
            show_resend_email: false,
            is_sso_login: false
        };
        this.props.patient_details.patient_search = null;
    }

    get_cookie = (name) => {
        const nameLenPlus = name.length + 1;
        return (
            document.cookie
                .split(';')
                .map((c) => c.trim())
                .filter((cookie) => {
                    return cookie.substring(0, nameLenPlus) === `${name}=`;
                })
                .map((cookie) => {
                    return decodeURIComponent(cookie.substring(nameLenPlus));
                })[0] || null
        );
    };

    on_login_page_load = () => {
        const token = this.get_cookie('token');
        const isSsoLogin = this.get_cookie('IsSsoLogin');

        if (isSsoLogin) {
            this.props.clear_cache();
            this.setState({
                is_sso_login: true
            });

            window.location.href = global_constant.sso_url.webpt_emr_authorize;
        } else if (token) {
            // clear redux-store
            this.props.clear_cache();

            if (token) {
                this.setState({
                    is_sso_login: true
                });
                // TODO: store a flag to identify user login from SSO
                sessionStorage['sso_user_login'] = true;
                this.login_via_sso(token);
            } else {
                sessionStorage.removeItem('sso_user_login');
                setDefaultAuthData();
            }
        }
    };

    componentDidMount() {
        // getting encryption passphrase for the first time
        CryptoService.get_encryption_key();
        local_storage.set('open_tab_count', 0);
        this.on_login_page_load();
    }

    encrypt_password = async (value) => {
        let encryptedValue = CryptoService.encrypt_password(value);
        return encryptedValue;
    };

    // method will execute when user will click on Sign-In button on login window
    on_sign_in = async () => {
        // remove the sso user session if logging in by user name and password.
        sessionStorage.removeItem('sso_user_login');
        // sets tabId in localStorage
        setup_tabId_in_localStorage();

        this.setState({
            is_submitted: true
        });

        if (
            this.props.form_values.txt_Username == undefined ||
            this.props.form_values.txt_Username == '' ||
            this.props.form_values.txt_Password == undefined ||
            this.props.form_values.txt_Password == ''
        ) {
            toastr.error('', login_constants.validation.required);
        } else {
            this.setState({
                loading: true,
                is_submitted: true
            });

            let is_main_window = session_storage.get_raw('is_main_window');

            if (is_main_window == undefined || is_main_window == 'undefined') {
                session_storage.set_raw('is_main_window', true);
            }
            await CryptoService.get_encryption_key();

            // User's Password must be encrypted before sending it to server
            var data = await this.encrypt_password(this.props.form_values.txt_Password);
            // this.props.form_values.txt_Password = data;

            // Authenticate the user credentials...
            const authenticate = await this.props.authentication(this.props.form_values.txt_Username.trim(), data);
            if (
                this.props.user_login_details &&
                this.props.user_login_details.user_data &&
                this.props.user_login_details.user_data.status == 1
            ) {
                await this.get_menu_data();
                this.get_master_data();
                if (this.props.user_login_details.user_data.data.login_status == 7) {
                    // Show Expired Password Alert PopUp
                    this.setState({
                        expired_password_alert_popup: {
                            show: true,
                            content: this.props.user_login_details.user_data.data.message
                        }
                    });
                } else if (this.props.user_login_details.user_data.data.login_status == 10) {
                    // show Change Password Alert PopUp
                    this.setState({
                        change_password_alert_popup: {
                            show: true,
                            content: this.props.user_login_details.user_data.data.message
                        }
                    });
                } else if (this.props.user_login_details.user_data.data.login_status == 1) {
                    pendoInit();
                    LaunchDarkley.update(() => {
                        if (this.props.rsiB29536Dashboard) {
                            session_storage.set('active_patient', '');
                            this.props.set_active_patient('');
                            this.props.history.push('/dashboard');
                        } else {
                            this.props.history.push('/patient_search');
                        }
                    });
                }
            } else {
                // this.props.form_values.txt_Password = "";
                if (
                    this.props.user_login_details.user_data &&
                    this.props.user_login_details.user_data.messages.length > 0 &&
                    this.props.user_login_details.user_data.messages[0].message != undefined &&
                    this.props.user_login_details.user_data.messages[0].code === 9901
                ) {
                    this.setState({
                        loading: false,
                        is_submitted: true,
                        show_resend_email: true
                    });
                } else {
                    this.setState({
                        loading: false,
                        is_submitted: true
                    });
                }
                if (
                    this.props.user_login_details.user_data &&
                    this.props.user_login_details.user_data.messages.length > 0
                ) {
                    toastr.error('', this.props.user_login_details.user_data.messages[0].message);
                }
                // this.props.history.push('/');
            }
        }
    };

    get_master_data = async () => {
        try {
            // if user log in successfully get master data with cache and cache expire time
            this.props.get_master_data(
                login_constants.MasterData.all,
                this.props.user_login_details.user_data.data.user_id,
                this.props.user_login_details.user_data.data.token_details.access_token
            );
            this.props.get_master_data_by_type(
                login_constants.MasterData.all,
                this.props.user_login_details.user_data.data.user_id,
                this.props.user_login_details.user_data.data.token_details.access_token
            );
        } catch (e) {}
    };

    private on_forgot_password() {
        setTimeout(() => {
            this.props.history.push('/forgotPasswordRequest');
        }, 0);
    }

    // close password expired alert popup and move to Change Password page
    expired_password_close = () => {
        this.setState({
            expired_password_alert_popup: { show: false, content: '' }
        });
        this.props.change_password_required(true);
        setTimeout(() => {
            pendoInit();
            LaunchDarkley.update();
            this.props.history.push('/manage_account');
        }, 0);
    };

    // close Change Password alert popup
    change_password_close = () => {
        this.setState({
            change_password_alert_popup: { show: false, content: '' }
        });
        setTimeout(() => {
            pendoInit();
            LaunchDarkley.update(() => {
                if (this.props.rsiB29536Dashboard) {
                    this.props.history.push('/dashboard');
                } else {
                    this.props.history.push('/patient_search');
                }
            });
            // this.props.history.push('/patient_search')
        }, 0);
    };

    // close Change Password alert popup and move to Change Password page
    change_password_redirection = () => {
        this.setState({
            change_password_alert_popup: { show: false, content: '' }
        });
        this.props.change_password_required(true);
        setTimeout(() => {
            pendoInit();
            LaunchDarkley.update();
            this.props.history.push('/manage_account');
        }, 0);
    };

    // Generates HTML for field's in the form i.e. Login Form
    private renderField = (field) => {
        const className = `form-control ${!field.input.value && this.state.is_submitted ? 'search-error-thin' : ''}`;
        const type = field.input.name == 'txt_Password' ? 'password' : 'text';
        return (
            <div className='form-group'>
                <label>{field.label}</label>
                {type == 'password' ? (
                    <Input
                        type='text'
                        className={className}
                        {...field.input}
                        id='login_userpass_field'
                        autoComplete='off'
                    />
                ) : (
                    <Input
                        type={type}
                        id='login_username_field'
                        onKeyDown={(event) => handle_focus_on_shift_tab(event, 'success')}
                        className={className}
                        {...field.input}
                        maxLength={50}
                        autoFocus
                        autoComplete='off'
                    />
                )}
            </div>
        );
    };

    re_send_ip_registration_link = async () => {
        if (this.props.form_values.txt_Username == undefined || this.props.form_values.txt_Username == '') {
            toastr.error('', 'Please enter User Name');
        } else {
            this.setState({
                loading: true
            });
            const resend = await this.props.re_send_ip_registration_link(this.props.form_values.txt_Username);

            toastr.success('', this.props.user_login_details.re_send_ip_registration_data.messages[0].message);

            this.setState({
                loading: false,
                show_resend_email: false
            });
        }
    };

    // Left menu data
    get_menu_data = async () => {
        await this.props.menu_permission(
            this.props.user_login_details.user_data.data.user_id,
            this.props.user_login_details.user_data.data.company_id,
            this.props.user_login_details.user_data.data.token_details.access_token
        );
    };

    // Fire Sign in on Enter key press
    login_handle_key_press = (event) => {
        if (event.key == 'Enter') {
            this.on_sign_in();
        }
    };

    // method will execute when user will click on Sign-In button on login window
    login_via_sso = async (token) => {
        // sets tabId in localStorage
        setup_tabId_in_localStorage();
        this.setState({
            loading: true,
            is_submitted: true
        });

        let is_main_window = session_storage.get_raw('is_main_window');

        if (is_main_window == undefined || is_main_window == 'undefined') {
            session_storage.set_raw('is_main_window', true);
        }
        await CryptoService.get_encryption_key();

        await this.props.sso_user_verification_post(token);

        if (
            this.props.user_login_details &&
            this.props.user_login_details.user_data &&
            this.props.user_login_details.user_data &&
            this.props.user_login_details.user_data.status == 1
        ) {
            await this.get_menu_data();
            this.get_master_data();
            if (this.props.user_login_details.user_data.data.login_status == 7) {
                // Show Expired Password Alert PopUp
                this.setState({
                    expired_password_alert_popup: {
                        show: true,
                        content: this.props.user_login_details.user_data.data.message
                    }
                });
            } else if (this.props.user_login_details.user_data.data.login_status == 10) {
                // show Change Password Alert PopUp
                this.setState({
                    change_password_alert_popup: {
                        show: true,
                        content: this.props.user_login_details.user_data.data.message
                    }
                });
            } else if (this.props.user_login_details.user_data.data.login_status == 1) {
                pendoInit();
                LaunchDarkley.update(() => {
                    if (this.props.rsiB29536Dashboard) {
                        this.props.history.push('/dashboard');
                    } else {
                        this.props.history.push('/patient_search');
                    }
                });
            }
        } else {
            if (
                this.props.user_login_details.user_data &&
                this.props.user_login_details.user_data.messages &&
                this.props.user_login_details.user_data.messages.length > 0 &&
                this.props.user_login_details.user_data.messages[0].message != undefined &&
                this.props.user_login_details.user_data.messages[0].code === 9901
            ) {
                this.setState({
                    loading: false,
                    is_sso_login: false,
                    is_submitted: false,
                    show_resend_email: true
                });
            } else {
                this.setState({
                    loading: false,
                    is_sso_login: false,
                    is_submitted: false
                });
            }
            if (
                this.props.user_login_details.user_data &&
                this.props.user_login_details.user_data.messages.length > 0
            ) {
                toastr.error('', this.props.user_login_details.user_data.messages[0].message);
            } else if (
                this.props.user_login_details.user_data &&
                this.props.user_login_details.user_data.messages &&
                this.props.user_login_details.user_data.messages.length > 0 &&
                this.props.user_login_details.user_data.messages[0].message != undefined &&
                this.props.user_login_details.user_data.messages[0].code === 300003
            ) {
                // TODO :- Redirect sso setup
                toastr.error('', this.props.user_login_details.user_data.messages[0].message);
            }
            sessionStorage.removeItem('sso_user_login');
            this.props.history.push('/login');
        }
    };

    render() {
        let { is_sso_login } = this.state;
        return (
            <React.Fragment>
                <Dimmer active={this.state.loading || is_sso_login}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <div className='login-wrapper' onKeyPress={this.login_handle_key_press}>
                    <Grid.Row>
                        <Grid.Column computer={8} className='text-right centerContent login-logo'>
                            <div>
                                <img src={login_constants.IMAGES_PATH + login_constants.WEB_PT_LOGO} alt='logo' />
                                <div className='clr'></div>
                                <span className='version'>
                                    {global_constant.show_app_version ? global_constant.build_version : ''}
                                </span>
                                <span className='LoginAppName'>Billing</span>
                            </div>
                        </Grid.Column>
                        {!is_sso_login && (
                            <Grid.Column computer={8} className='centerContent'>
                                <div className='loginbox text-left'>
                                    <h2>Login</h2>
                                    <form autoComplete='off'>
                                        <Field
                                            name='txt_Username'
                                            label='Username'
                                            autoComplete='new_password'
                                            component={this.renderField}
                                        />
                                        <Field
                                            name='txt_Password'
                                            autoComplete='new_password'
                                            label='Password'
                                            component={this.renderField}
                                        />
                                        <div className='text-right form-group'>
                                            <a
                                                tabIndex={0}
                                                className='text-right cursor_point'
                                                onKeyDown={handle_click_on_enter}
                                                onClick={() => this.on_forgot_password()}
                                            >
                                                Forgot password ?
                                            </a>
                                            <br />
                                        </div>
                                        <div className='text-center'>
                                            <Button
                                                id='success'
                                                onKeyDown={(event) =>
                                                    handle_focus_on_tab(event, 'login_username_field')
                                                }
                                                primary
                                                className='success_btn'
                                                type='button'
                                                onClick={() => this.on_sign_in()}
                                            >
                                                SIGN IN
                                            </Button>
                                        </div>
                                        {this.state.show_resend_email ? (
                                            <div id='dvResendEmail' className='ip_resend_link'>
                                                Click on{' '}
                                                <a
                                                    className='cursor_point'
                                                    onClick={() => this.re_send_ip_registration_link()}
                                                >
                                                    resend
                                                </a>{' '}
                                                to send IP registration e-mail again.
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </form>
                                </div>
                                <div className='clear10'></div>
                                <div className='helpSection centerContent'>
                                    <i className='copyright outline icon' />
                                    <span>{global_constant.copyright_info}</span>
                                </div>
                            </Grid.Column>
                        )}
                    </Grid.Row>
                </div>
                <Modal
                    open={this.state.expired_password_alert_popup.show}
                    centered={false}
                    header='Alert'
                    className='font-size-16'
                    content={this.state.expired_password_alert_popup.content}
                    closeOnDimmerClick={false}
                    closeOnDocumentClick={false}
                    actions={[{ key: 'OK', content: 'OK', positive: true, onClick: this.expired_password_close }]}
                />
                <Modal
                    open={this.state.change_password_alert_popup.show}
                    centered={false}
                    header='Alert'
                    className='font-size-16'
                    content={this.state.change_password_alert_popup.content}
                    closeOnDimmerClick={false}
                    closeOnDocumentClick={false}
                    actions={[
                        { key: 'OK', content: 'OK', positive: true, onClick: this.change_password_close },
                        {
                            key: 'change_password',
                            content: 'Change Password',
                            positive: true,
                            onClick: this.change_password_redirection
                        }
                    ]}
                />
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            sso_user_verification_post: sso_user_verification_post,
            authentication: user_authentication,
            clear_cache: clear_cache,
            change_password_required: change_password_required,
            re_send_ip_registration_link: re_send_ip_registration_link,
            set_active_patient: set_active_patient,
            get_master_data: get_master_data,
            get_master_data_by_type: get_master_data_by_type,
            menu_permission: left_menu_permission
        },
        dispatch
    );
};

const mapStateToProps = (state) => {
    const formValues = getFormValues('log_in')(state) || {};
    return {
        user_login_details: state.user_login_details,
        form_values: formValues,
        patient_details: state.patient_details,
        rsiB29536Dashboard: get_lauch_darkley_key_value(state.launch_darkly, 'rsiB29536Dashboard'),
        magnusPlat5681IsolateAuth0: get_lauch_darkley_key_value(state.launch_darkly, magnus_plat_5681_isolate_auth0)
    };
};

/* - Connect component to redux
  - Used to map the redux store state and dispatch it to the props of a component
  - 2 input parameters:-
      1) mapStateToProps- The component will subscribe to Redux store updates. This means that any time the store is updated, mapStateToProps will be called [output]
      2) mapDispatchToProps- Map dispatched data from the action creater to props of a component [action creater]
*/
export default reduxForm({
    form: 'log_in'
})(connect(mapStateToProps, mapDispatchToProps)(LoginComponent));
