import 'core-js';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import 'semantic-ui-css/semantic.min.css';
import * as global_constants from './src/app/global_constants';
import ForgotComponent from './src/app/login/component/forgot_password';
import LoginComponent from './src/app/login/component/login_component';
import ResetComponent from './src/app/login/component/reset_password';
import ReducerStore from './src/app/reducer_store';
// Sub Layouts
import AuthorizedRouteComponent from './src/app/shared/component/authorised_route_component';
import ComingSoonComponent from './src/app/shared/component/coming_soon_component';
import PrimaryLayoutComponent from './src/app/shared/component/primary_layout_component';
import IPRegistration from './src/app/user/component/ip_registration_component';
import './src/assets/css/custom.css';
import PendoSetup from './config/pendo_setup'
import LaunchDarkley from './src/app/shared/launch_darkley/launch_darkley';
import SSOLoginComponent from './src/app/sso/component/sso_login_component';
import SSORedirectComponent from './src/app/sso/component/sso_redirect_component';
import LogoutComponent from './src/app/login/component/logout_component';
import SSOLoginResetComponent from './src/app/sso/component/sso_login_reset_component';
import { remove_expired_session_data } from './src/app/shared/utility';
import  CallbackComponent  from './src/app/auth0/component/callback_component';
import IamSetupLoginComponent from './src/app/iam/component/setup_login_component';
import AuthLogoutComponent from './src/app/auth0/component/auth_logout_component';

remove_expired_session_data();
setInterval(() => {
    remove_expired_session_data();
}, 300000);
// Launch Darkly need socket to flip their features.
// To make socket run for IE and Edge we need to require this package
require('event-source-polyfill');
PendoSetup();
const { store, persistor } = ReducerStore();
var current_app_path = document.location.pathname;
//excluding embedded_chat route from R6 bundle, because it conflict with sale-force chat library
if (current_app_path.indexOf('embedded_chat') < 0)
{
    if (current_app_path == "/sso/login" || current_app_path.indexOf("/sso/setup/redirect") >= 0) {
        ReactDOM.render(
            <BrowserRouter >
                <Switch>
                    <Route path="/sso/login" component={SSOLoginComponent} exact />
                    <Route path="/sso/setup/redirect" component={SSORedirectComponent} exact />
                    <Redirect to="/sso/login" />
                </Switch>
            </BrowserRouter >
            ,
            document.getElementById('root') as HTMLElement
        );
    } else {

        // initialising Launch Darkly
        LaunchDarkley.init(store);

        ReactDOM.render(
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter >
                        <div className="login-wrapperBlue">
                            <Switch>
                                <Route path="/sso/reset_login" component={SSOLoginResetComponent} exact />
                                <Route path="/login" component={LoginComponent} exact />
                                <Route path="/iam/setup" component={IamSetupLoginComponent} exact />
                                <Route path="/logout" component={LogoutComponent} exact />
                                <Route path="/v2/logout" component={AuthLogoutComponent} exact />
                                <Route path="/forgotPasswordRequest" component={ForgotComponent} exact />
                                <Route path="/resetPassword" component={ResetComponent} exact />
                                <Route path="/ipRegistration" component={IPRegistration} exact />
                                <Route path="/app/coming_soon" component={ComingSoonComponent} exact />                                
                                <Route path="/callback"  component={CallbackComponent} exact/> 
                                <AuthorizedRouteComponent path="" component={PrimaryLayoutComponent} />
                                <Redirect to="/login" />

                            </Switch>
                        </div>
                    </BrowserRouter >
                    <ReduxToastr
                        timeOut={global_constants.toastr_options.toastr_time_out}// by setting to 0 it will prevent the auto close
                        newestOnTop={global_constants.toastr_options.newestOnTop}
                        preventDuplicates={global_constants.toastr_options.prevent_duplicates}
                        position={global_constants.toastr_options.position}
                        transitionIn={global_constants.toastr_options.transition_in}
                        transitionOut={global_constants.toastr_options.transition_out}
                        progressBar={global_constants.toastr_options.progress_bar}
                        closeOnToastrClick
                    />
                </PersistGate>
            </Provider>
            , document.getElementById('root') as HTMLElement
        );
    }
}