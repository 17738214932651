import { currency_comparator, link_number_comparator, number_comparator, text_comparator, mm_dd_yyyy_comparator, mm_dd_yyyy_h_mm_ss_tt_comparator } from '../../shared/component/grid/comparators';
import { get_header_template_with_navigation, get_header_group_template_with_navigation } from '../../shared/component/grid/constants';
import { strip, get_group_column, format_number } from '../../shared/component/grid/utils';
import * as report_constants from '../report_constants';
import * as session_storage from '../../shared/session_storage_utility';
import { get_columns, is_null_or_white_space } from '../../shared/utility';

export const report_grid_first_header_id = "report_grid_first_header_id";

//Used to manage the URL history on click of back and initial result buttons.
export const set_url_history = () => {
    var url_history = session_storage.get('url_history');
    if (url_history && url_history.length == 0) {
        url_history.push(location.pathname + location.search);
    } else {
        if (url_history && url_history[url_history.length - 1] != location.pathname + location.search) {
            url_history.push(location.pathname + location.search);
        }
    }
    session_storage.set('url_history', url_history);
};

// Function used to set column definition for report grid.
export const set_column_def = (data, report_id, features) => {
    let report_cols = data.ReportColumn;
    let r4_to_r6_routes = data.R4ToR6Route;
    let default_sort_column = data.DefaultSortColumn;
    let is_row_grouping = data.allow_grouping;
    return (report_cols = report_cols.map((item, index) => {

        item.is_hide = (item.hide && item.hide == true);
        let header_template = get_header_template(is_row_grouping, item);
        if (report_id in report_constants.report_has_hidden_column) {
            item.is_hide = report_constants.report_has_hidden_column[report_id].columns.some((column) => item.header.toLowerCase() == column);
            if (index === 1) {
                header_template = get_first_header_template(is_row_grouping, item);
            }
        } else if (index === 0) {
            header_template = get_first_header_template(is_row_grouping, item);
        }
        /**
         * set column type 
         */
        const column_type = (() => {
            switch (true) {
                case item.header == "Payment ID":
                    return "string";
                case item.is_hyper_link:
                    return "hyper_link";
                case item.field_name.toLowerCase().indexOf("per") > -1:
                    return "percentage";
                case item.field_name.toLowerCase().indexOf("decimal") > -1:
                    return "currency";
                case item.field_name.toLowerCase().indexOf("int") > -1:
                    return "number";
                case item.field_name.toLowerCase().indexOf("datetimeoffset") > -1:
                    return "datetimeoffset";
                case item.field_name.toLowerCase().indexOf("datetime") > -1:
                    return "datetime";
                case item.field_name.toLowerCase().indexOf("date") > -1:
                    return "date";
                case item.field_name.toLowerCase().indexOf("string") > -1:
                default:
                    return "string";
            }
        })();

    const filter = get_column_filter(column_type, item, report_id);
        let column: Object;
        if (item.field_name === "group") {
            column = {
                maxWidth: 63,
                suppressSorting: true,
                suppressFilter: true,
                suppressMovable: false,
                colSpan: (params) => {
                    if (!params.node.group && !params.node.rowPinned) {
                        return params.columnApi.getAllColumns().length;
                    }
                    return 1;
                },
                cellRendererSelector: (params) => {
                    if (params && params.data && params.data.participants && params.data.participants.length == 0) {
                        return null;
                    }
                    if (params && params.node.rowPinned) {
                        return null;
                    }
                    if (params) {
                        if (params.node.group) {
                            return {
                                component: "agGroupCellRenderer"
                            };
                        }
                        if (params.data && params.data.child_column) {
                            return {
                                component: "InnerGridRendererComponent"
                            };
                        }
                    }
                }
            };
        } else if (item.field_name === "checkbox") {
            column = {
                headerCheckboxSelection: true,
                checkboxSelection: true,
                minWidth: 40,
                maxWidth: 40,
                suppressSorting: true,
                suppressFilter: true,
                suppressMovable: false,
            }
        } else if (report_id === 30292 && item.header === "Closed") {
            column = {
                cellRenderer: params => {
                    let input = document.createElement('input');
                    input.type = "checkbox";
                    input.checked = params.value && params.value === 'true' ? true : false;
                    input.disabled = true;
                    return input;
                }
            };
        } else {
            let cellRenderer = item.is_hyper_link
                ? check_report_open_in_pop(report_id, item)
                    ? 'PopUpLinkRenderer'
                    : 'LinkRendererComponent'
                : item.data_type == 'string'
                ? htmlTextRenderer
                : item.cellRenderer
                ? item.cellRenderer
                : null;
            column = {
                cellRenderer
            };
        }
    return {
      // Default Sort remove as per R4
      // sort: item.header.trim().toLowerCase() == default_sort_column.trim().toLowerCase() ? 'asc' : '',
      ...column,
      ...filter,
      headerComponentParams: header_template,
      field: item.field_name,
      headerName: item.header,
      unSortIcon: true,
      hide: item.is_hide ? true : false,
      type: column_type,
      format: item.format,
      cellStyle: (params)=>get_cell_style(params, report_id, item),
      cellRendererParams: {
          ...set_cellRendererParams(report_id, r4_to_r6_routes, features, item.header)
      }
    };
  }))
};

const htmlTextRenderer = params => {
    return params.value;
};

/**
 * Check is link report open as
 * @param report_id
 * @param r4_to_r6_routes
 * @param features
 */
export const set_cellRendererParams = (report_id, r4_to_r6_routes, features, header) => {
    //debugger;
    let cellRendererParams = {
      r4_to_r6_routes: r4_to_r6_routes,
      auto_print: false,
      downloadPdf: false,
      custom_print: false,
      is_print_disabled: false,
      is_export_disabled: false
    };
    if (report_constants.report_has_download_pdf_column[report_id] && report_constants.report_has_download_pdf_column[report_id][header]) {
        const report = report_constants.report_has_download_pdf_column[report_id][header];
      if (report['check_ff']) {
        cellRendererParams = {
          ...cellRendererParams,
          auto_print: features && !features[report['features']],
          downloadPdf: features && features[report['features']],
          custom_print: features && features[report['features']]
        };
      } else {
        cellRendererParams = {
          ...cellRendererParams,
          auto_print: report['auto_print'],
          downloadPdf: report['downloadPdf'],
          custom_print: report['custom_print'],
          is_print_disabled: report['is_print_disabled'] || false,
          is_export_disabled: report['is_export_disabled'] || false
        };
      }
    }
    return cellRendererParams;
};


/**
 * Check is link report open in popup
 * @param report_id
 * @param item
 */
const check_report_open_in_pop = (report_id, item) => {
    let is_report_open_popup = false;
    if (report_constants.report_has_download_pdf_column[report_id] && report_constants.report_has_download_pdf_column[report_id][item.header]) {
        is_report_open_popup = report_constants.report_has_download_pdf_column[report_id][item.header]['header'] === item.header;
    }
    return is_report_open_popup;
}

/**
 * Grid cell style
 * @param report_id
 * @param item
 */
const get_cell_style = (params, report_id, item) => {
    const field = item.field_name.toLowerCase();
    switch (true) {
        case field.indexOf('decimal') > -1 || field.indexOf('int') > -1 || is_column_right(report_id, item):
            return { textAlign: 'right' };
        case report_id === 30292 && item.header === 'Closed':
            return { textAlign: 'center' };
        case report_id === 30375:
            if (
                (params.data.int32Col1 == '1' && item.header == 'Expiration Date') ||
                (params.data.int32Col0 == '1' && item.header == 'Expected Rate')
            ) {
                return {
                    color: 'red'
                };
            } else {
                return {};
            }
        default:
            return {};
    }
}

/**
 * Column right aligned for grid column data type is either string or hyperlink 
 * and it's value is either number, count, or decimal
 * @param report_id
 * @param item
 */
const is_column_right = (report_id, item) => {
    if (report_id === 30200 && item.is_hyper_link && item.field_name === 'stringCol1') {
        // Incomplete constants summary grid count hyper link column right aligned 
        return true;
    }
    return false
}

const get_header_template = (is_row_grouping, item) => {
    if (is_row_grouping) {
        if (item.is_hyper_link) {
            return report_constants.headerDragTemplate(`link_${item.field_name}`, item.header);
        }

        return report_constants.headerDragTemplate(item.field_name, item.header);
    }
    return report_constants.headerTemplate;
}

// First Template with tab-index value
const get_first_header_template = (is_row_grouping, item) => {
    if (is_row_grouping) {
        if (item.is_hyper_link) {
            return get_header_group_template_with_navigation(report_grid_first_header_id, `link_${item.field_name}`, item.header);
        }

        return get_header_group_template_with_navigation(report_grid_first_header_id, item.field_name, item.header);
    }
    return get_header_template_with_navigation(report_grid_first_header_id);;
}

/**
 * is_hyperlink_type_exist- function returns true if any hyperlink type column exist false otherwise
 * @param columns
 * @returns boolean
 */
export const is_hyperlink_type_exist = (columns = []) => {
    return columns.some(item => item.type === 'hyper_link');
}

/**
 * is_hyperlink_column_exist- function returns true if any hyperlink exist false otherwise
 * @param columns
 * @returns boolean
 */
export const is_hyperlink_column_exist = (columns = []) => {
    return columns.some(item => item.is_hyper_link);
}

// Function used to get parameter value from hyperlink.
export const get_params = row_value => {
    if (!row_value) {
        return '';
    }
    var params, value1, value2, value3;
    row_value = row_value.split('>');
    value1 = row_value[0]
        .replace('<a ', '')
        .replace('</a>', '')
        .replace('>', '')
        .replace(/["]/g, '')
    value2 = value1.split('?');
    value3 = value2[1]
        .replace('<a ', '')
        .replace('</a>', '')
        .replace('>', '')
        .replace(/["]/g, '')
    params = encodeURIComponent(value3);
    return params;
};

// Function used to get link value from hyperlink.
export const get_link_value = row_value => {
    if (!row_value) {
        return '';
    }
    row_value = row_value.replace('<b>', '').replace('</b>', '')
    var start = row_value.indexOf('>') + 1;
    var end = row_value.indexOf('</');
    if (start > 0 && end > 0) {
        return row_value.substring(start, end).trim();
    }
    else return row_value;    
};

// Function calls after click of filter button and recalculate the footer sum.
export const on_filter_button_click = (grid_ref, report_data, report_id) => {
    var footer_obj = initialize_footer_obj(report_data.cols, report_id);
    var footer_cell_data;
    var row_api = grid_ref.api;
    if (row_api) {
        row_api.forEachNodeAfterFilter(row => {
            if (row.level === 0 || (report_data.allow_grouping && !row.group)) {
            row = row.data;
            report_data.cols.map(value => {
                if (value.field !== 'group' && !row['group'] && row[value.field]) {
                    if (value.format && value.format.trim() != '' && value.format.trim() != "N") {
                        switch (value.type) {
                            case 'currency':
                                if (row[value.field].indexOf('(') != -1) {
                                    footer_cell_data = row[value.field] ? '-' + row[value.field].replace(/[$,()]/g, '') : 0;
                                } else {
                                    footer_cell_data = row[value.field] ? row[value.field].replace(/[$,()]/g, '') : 0;
                                }
                                // As per R4, report 96, currency type but %
                                if (value.format && value.format.indexOf('%F') > -1) {
                                    footer_obj[value.field] += isNaN(parseFloat(footer_cell_data)) ? 0 : (parseFloat(footer_cell_data) * 0.01);
                                }
                                else {
                                    footer_obj[value.field] += isNaN(parseFloat(footer_cell_data)) ? 0 : parseFloat(footer_cell_data);
                                }
                                break;
                            case 'percentage':
                                footer_cell_data = row[value.field] ? row[value.field].replace(/[%,]/g, '') : 0;
                                footer_obj[value.field] += isNaN(parseFloat(footer_cell_data)) ? 0 : (parseFloat(footer_cell_data) * 0.01);
                                break;
                            case 'number':
                                footer_obj[value.field] += row[value.field] ? format_number(row[value.field]) : 0;
                                break;
                            case 'hyper_link':
                                if (report_id === 30200) {
                                    footer_obj[value.field] += row[`link_${value.field}`] ? parseFloat(row[`link_${value.field}`]) : 0;
                                }
                                else {
                                    footer_obj[value.field] = null
                                }                                 
                                break;
                            default:
                        }
                    }
                    else if (report_id === report_constants.patient_credit_report && value.format && value.format.trim() != '' && value.format.trim() == 'N' && value.headerName && value.headerName.toLowerCase() == 'balance remaining') {
                        footer_obj[value.field] += row[`link_${value.field}`] ? parseFloat(row[`link_${value.field}`]) : 0;
                    }
                }
            });
        }
        });

        // Footer Formatting
        footer_obj = footer_data_formatter(report_data.rows, report_data.cols, footer_obj, report_id);
        if (row_api.getPinnedBottomRow(0)) {
            row_api.setPinnedBottomRowData([footer_obj]);
        }
    }
};

// Function used to initialize the footer object.
export const initialize_footer_obj = (column_def, report_id) => {
    var footer_obj = {};
    var first_field = '';
    column_def.map((value) => {
            if (!value.hide && value.field === 'group' && !first_field) {
                first_field = value.field;
            }
            if ((value.field.indexOf('string') > -1 || value.field.indexOf('datetime') > -1) && !first_field) {
                first_field = value.field;
            }

            if (value.format && value.format.trim() != '' && value.format.trim() != 'N' && (value.type == 'currency' || value.type == 'number' || value.type == 'percentage')) {
                footer_obj[value.field] = 0;
                footer_obj[first_field] = 'Total';
            } else if (value.format && value.format.trim() != '' && value.format.trim() != 'N' && (value.type == 'hyper_link') && report_id === 30200) {
                footer_obj[value.field] = 0;
                footer_obj[first_field] = 'Total';
            } else {
                footer_obj[value.field] = null;
            }
    });
    return footer_obj;
};

// Function use to format the grid data on load.
export const row_data_formatter = (report_rows, report_cols, report_id) => {
    var footer = initialize_footer_obj(report_cols, report_id);
    // Column Formatting
    report_rows.map(row => {
        report_cols.map(col => {
            if (col.type == 'currency' || col.type == 'number' || col.type == 'percentage') {
                // Adding column to get footer.
                if(col.format.trim() != ''){
                    footer[col.field] += row[col.field] ? parseFloat(row[col.field]) : 0;
                }
                // Formatting of Currency,Percentage and Number type data
                if (col.format && col.format.trim() != 'T') {
                    row[col.field] = format_as_parameter(row[col.field], col.format);
                }
                else {
                    row[col.field] = format_as_datatype(row[col.field], col.type);
                }
            } else if (col.type === 'hyper_link') {
                row[`link_${col.field}`] = strip(row[col.field]);
                if (col.format && col.format.trim() != '' && col.format.trim() != 'N' && report_id === 30200) {
                    footer[col.field] += row[`link_${col.field}`] ? parseFloat(row[`link_${col.field}`]) : 0;
                }

                if (report_id === report_constants.patient_credit_report && col.format && col.format.trim() != '' && col.format.trim() == 'N' && col.headerName && col.headerName.toLowerCase() == 'balance remaining') {
                    footer[col.field] += row[`link_${col.field}`] ? parseFloat(row[`link_${col.field}`]) : 0;
                }
            } else {
                // Formatting of String and Date type data
                row[col.field] = format_as_datatype(row[col.field], col.type);
            }
        });
        return row;
    });

    // Footer Formatting
    let footer_obj = footer_data_formatter(report_rows, report_cols, footer, report_id);
    return { report_rows, footer_obj };
};

export const footer_data_formatter = (report_rows, report_cols, footer, report_id: number = null) => {
    report_cols.map((value, index) => {
        if (value.format && value.format.trim() != '' && (value.type == 'currency' || value.type == 'number' || value.type == 'percentage'
            || (report_id == 508 && value.type == 'hyper_link' && value.format.trim() != 'N'))) {
            if (value.format && value.format.trim() != 'T') {
                if ((value.format.indexOf('F') > -1 || value.format.indexOf('A') > -1)) {
                    let format = value.format.trim().substring(0, 2);
                    if (value.format.indexOf('A') > -1) {
                        footer[value.field] = (footer[value.field] && report_rows.length) && (footer[value.field] / report_rows.length);
                        footer[value.field] = format_as_parameter(footer[value.field], format, true);
                    }
                    else if (value.format.indexOf('F') > -1) {
                        let expression = value.format.substring(value.format.indexOf('[') + 1, value.format.indexOf(']'));
                        let express_array = expression && expression.split(',');
                        let key1 = express_array && express_array[0].substring(1);
                        let operator = express_array && express_array[1];
                        let key2 = express_array && express_array[2].substring(1);
                        let column_key = Object.keys(footer);
                        if (column_key.indexOf('group') > -1) {
                            column_key = column_key.splice(1)
                        }
                        let operand1 = column_key[parseInt(key1)]
                        let operand2 = column_key[parseInt(key2)]
                        operand1 = (operand1 && footer[operand1]) || '';
                        operand2 = (operand2 && footer[operand2]) || '';
                        footer[value.field] = operand1 && operand2 &&
                            report_constants.currency_value(operand1) && //Check for 0
                            report_constants.currency_value(operand2) && // Check for 0
                            eval(report_constants.currency_value(operand1) + operator + report_constants.currency_value(operand2));
                        footer[value.field] = (footer[value.field] && (format == '%' ? footer[value.field] : footer[value.field]) || null);
                        footer[value.field] = format_as_parameter(footer[value.field], format, true);
                    }
                }
                else {
                    footer[value.field] = format_as_parameter(footer[value.field], value.format, true);
                }
            } else {
                footer[value.field] = format_as_datatype(footer[value.field], value.type, true);
            }
        } else if (report_id === report_constants.patient_credit_report && value.format && value.format.trim() != '' && value.format.trim() == 'N' && value.headerName && value.headerName.toLowerCase() == 'balance remaining') {
            footer[value.field] = format_as_datatype(footer[value.field], 'currency', true);
        }
    });
    return footer
};

// Format data on basis of column parameter format coming from DB
export const format_as_parameter = (value, format, is_footer = false) => {
    let is_value_valid = value != null || value != undefined;
    return (value = (() => {
      switch (true) {
        case format.indexOf('$A') > -1 || format.indexOf('$F') > -1 || format.indexOf('$T') > -1:
          return is_value_valid ? report_constants.currencyFormatter(value) : '$0.00';

        case format.indexOf('$?T') > -1:
          return is_value_valid ? report_constants.currencyFormatter(value) : '';

        case format.indexOf('$?F') > -1:
            return is_value_valid && !is_null_or_white_space(value) ? report_constants.currencyFormatter(value) : '-';

        case format.indexOf('%T') > -1:
          return is_value_valid ? (parseFloat(value) * 100).toFixed(2) + '%' : '0.00%';

        case format.indexOf('%F') > -1:
          if (is_footer) {
            // As per R4, report 96, currency type but %
            return is_value_valid ? report_constants.formatNumber((parseFloat(value) * 100).toFixed(2)) + '%' : '0.00%';
          } else {
            return is_value_valid ? report_constants.formatNumber(parseFloat(value).toFixed(2)) + '%' : '0.00%';
          }

        case format.indexOf('%?F') > -1:
            if (is_footer) {
                return is_value_valid && !is_null_or_white_space(value) ? report_constants.formatNumber((parseFloat(value) * 100).toFixed(2)) + '%' : '-';
            } else {
                return is_value_valid && !is_null_or_white_space(value) ? report_constants.formatNumber(parseFloat(value).toFixed(2)) + '%' : '-';
            }

        case format.indexOf('#A') > -1 || format.indexOf('#F') > -1 || format.indexOf('#T') > -1:
          return is_value_valid ? report_constants.formatNumber(value) : '0';

        case format.indexOf('N') > -1:
        case format == '':
          // As per R4,when 'N' show footer as empty and column with no format.
          if (is_footer) {
            return '';
          } else {
            return value;
          }

        default:
          return value;
      }
    })());
}

// Format data on basis of data type
export const format_as_datatype = (value, data_type, is_footer = false) => {
    return (value = (() => {
        switch (data_type) {
            case 'date':
            case 'datetime':
                return value != null || value != undefined ? report_constants.dateFormatter(value) : '';
            case 'datetimeoffset':
                return value != null || value != undefined ? report_constants.dateTimeFormatter(value) : '';
            case 'currency':
                return !is_null_or_white_space(value) ? report_constants.currencyFormatter(value) : '$0.00';
            case 'percentage':
                return !is_null_or_white_space(value) ? (parseFloat(value) * 100).toFixed(2) + '%' : '0.00%';
            case 'number':
                return value != null || value != undefined ? report_constants.formatNumber(value) : 0;
            default:
                return value != null || value != undefined ? value.toString().trim() : '';
        }
    })());
};

// Function use to filter in to the grid data on load.
export const get_column_filter = (data_type, item, report_id) => {
    if (data_type != null && data_type != undefined) {
        switch (true) {
            case data_type == "currency":
            case data_type == "percentage":
                return {
                    filter: "currencyFilter",
                    comparator: currency_comparator
                };
            case data_type == "number":
                return {
                    filter: "numberFilter",
                    comparator: number_comparator
                };
            case data_type == "date":
                return {
                    filter: 'dateFilter',
                    comparator: mm_dd_yyyy_comparator
                };
            case data_type == "datetime":
            case data_type == "datetimeoffset":
                return {
                    filter: 'dateTimeFilter',
                    comparator: mm_dd_yyyy_h_mm_ss_tt_comparator
                };                
            case data_type == "hyper_link":
                switch (true) {
                    case report_id == 157 && item.field_name == "stringCol4":
                    case report_id == 296 && item.field_name == "stringCol0":                 
                    case report_id == 30200 && item.field_name == "stringCol1" && item.header == "Count":
                    case (report_id == 30050 || report_id == 30251) && item.field_name == "stringCol0":
                        return {
                            filter: "linkNumberFilter",
                            comparator: (link1, link2) => link_number_comparator(strip(link1), strip(link2))
                        };
                    case report_id == 30100 && item.field_name == "stringCol1":
                    case report_id == 407 && item.field_name == "stringCol0":
                        return {
                            filter: "linkDateFilter",
                            comparator: (link1, link2) => mm_dd_yyyy_comparator(strip(link1), strip(link2))
                        };
                    default:
                        return {
                            filter: "linkFilter",
                            comparator: (link1, link2) => text_comparator(strip(link1), strip(link2))
                        };
                }
            default:
                return {
                    filter: "textFilter",
                    comparator: text_comparator
                };
        }
    }   
}

/**
 * Grid column wrapping Start for Charge Audit Summary
 */

export const has_hierarchy = (cols: any, rows: any, child_column: any[], report_id: number) => {
  // Patient Payment Log
  if (report_id == 132) {
    cols = cols.filter((item: { [x: string]: string }) => {
      if (item["header"] == "Patient_Credit_Id") {
        item["header"] = "Patient Credit ID";
      }
      return item
    });
  }
  // Add Group Column
  cols.unshift({
    field_name: "group",
    data_type: "string",
    header: "",
    format: "",
    position: 0,
    is_hyper_link: false,
    link0: "link0",
    link1: "link1",
    link2: "link2",
    link3: "link3",
    link4: "link4",
    link5: "link5",
    link6: "link6",
    link7: "link7",
    column_data: ""
  });

  child_column = set_child_column_def(child_column, report_id);
  rows = rows.map((item: any) => {
    if (item.details.length > 0) {
      item.details = child_row_data_formatter(item.details,child_column);
      item = {
        ...item,
        participants: [
          {
            ...item,
            ...item.details,
            child_column
          }
        ],
        group: ""
      };
    } else {
      item = {
        ...item,
        participants: [],
        group: ""
      };
    }
    delete item["details"];
    return item;
  });

  return { cols, rows };
};

export const formate_report_cols_row_data = (data, report_id: number, features= null, detail_for_reason = false, additional_actions = []) => {
  data.ReportColumn = data.ReportColumn ? data.ReportColumn : [];
  data.ReportRows = data.ReportRows ? data.ReportRows : [];
  data.footer_obj = {};
  data.ChildReportColumn = data.ChildReportColumn ? data.ChildReportColumn : [];
  data.HasHierarchy = data.HasHierarchy ? data.HasHierarchy : false;
  let grid_height: { total_column_width: any; header_height: any };
  let total_column_width: any;
  let grid_header_height: any;

  // Patient Payment Log
  if (report_id == 132) {
    data.ReportColumn = data.ReportColumn.filter((item: { [x: string]: string }) => {
      if (item["header"] == "Patient_Credit_Id") {
        item["header"] = "Patient Credit ID";
      }
      return item;
    });
   }
    // Billing Exceptions- detail for reason will be exist in drill-down last page
    if (report_id === 30250 && detail_for_reason) {
        data.ReportColumn.unshift({
            field_name: "checkbox",
            data_type: "string",
            header: "",
            format: "",
            position: 0,
            is_hyper_link: false,
            link0: "link0",
            link1: "link1",
            link2: "link2",
            link3: "link3",
            link4: "link4",
            link5: "link5",
            link6: "link6",
            link7: "link7",
            column_data: ""
        });
        data["link_drill_down_last_page"] = true
    }

    let items_selected = additional_actions.filter(action=> action.validate_items_selected === true)
    if (items_selected.length>0) {
        data.ReportColumn.unshift({
            field_name: "checkbox",
            data_type: "string",
            header: "",
            format: "",
            position: 0,
            is_hyper_link: false,
            link0: "link0",
            link1: "link1",
            link2: "link2",
            link3: "link3",
            link4: "link4",
            link5: "link5",
            link6: "link6",
            link7: "link7",
            column_data: ""
        });
    }
    data.additional_actions = additional_actions.length>0?additional_actions: undefined

  if (data.HasHierarchy) {
    // Add Group Column
    data.ReportColumn.unshift({
      field_name: "group",
      data_type: "string",
      header: "",
      format: "",
      position: 0,
      is_hyper_link: false,
      link0: "link0",
      link1: "link1",
      link2: "link2",
      link3: "link3",
      link4: "link4",
      link5: "link5",
      link6: "link6",
      link7: "link7",
      column_data: ""
    });
  }
  //   Set Column Def
  data.ReportColumn = set_column_def(data, report_id, features);

  // Formate Row Data
  const formattedData = row_data_formatter(data.ReportRows, data.ReportColumn, report_id);
  data.ReportRows  = formattedData.report_rows
  data.footer_obj  = formattedData.footer_obj
  // Formate Row Data if  hierarchy report
    if (data.HasHierarchy) {
        data.ChildReportColumn = set_child_column_def(data.ChildReportColumn, report_id);
        data.ReportRows = data.ReportRows.map((item: any) => {
            item["group"] = "";
            if (item.details.length > 0) {
                item.details = JSON.parse(item.details);
                item["details"] = child_row_data_formatter(item.details, data.ChildReportColumn);
                item = {
                    ...item,
                    participants: [
                        {
                            ...item,
                            child_column: data.ChildReportColumn
                        }
                    ]
                };
            } else {
                item = {
                    ...item,
                    participants: []
                };
            }
            delete item["details"];
            return item;
        });
    }

  // Set Column Definition
    if (data.allow_grouping) {
        data.ReportColumn.unshift(get_group_column());
    }
  // Set Column Width
  grid_height = get_columns(data.ReportRows, data.ReportColumn);
  total_column_width = grid_height.total_column_width;
  grid_header_height = grid_height.header_height;

  return { data, total_column_width, grid_header_height };
};

// Function use to format Column Definition on load.
const set_child_column_def = (child_column: any[], report_id: number) => {
  return child_column.map((item) => {
    let header_template = get_header_template(false, item);
    const column_type =
      item.header == "Payment ID"
        ? "string"
        : item.is_hyper_link
        ? "hyper_link"
        : item.field_name.toLowerCase().indexOf("per") > -1
        ? "percentage"
        : item.field_name.toLowerCase().indexOf("decimal") > -1
        ? "currency"
        : item.field_name.toLowerCase().indexOf("int") > -1
        ? "number"
        : item.field_name.toLowerCase().indexOf("string") > -1
        ? "string"
        : item.field_name.toLowerCase().indexOf("datetimeoffset") > -1
        ? "datetimeoffset"
        : item.field_name.toLowerCase().indexOf("datetime") > -1
        ? "datetime"
        : item.field_name.toLowerCase().indexOf("date") > -1
        ? "date"
        : "string";
    const filter = get_column_filter(column_type, item, report_id);
    return {
      // Default Sort remove as per R4
      // sort: item.header.trim().toLowerCase() == default_sort_column.trim().toLowerCase() ? 'asc' : '',
      headerComponentParams: header_template,
      field: item.field_name,
      headerName: item.header,
      ...filter,
      unSortIcon: true,
      hide: item.is_hide ? true : false,
      type: column_type,
      format: item.format,
      suppressMenu: true,
      suppressSorting: true,
      cellStyle:
        item.field_name.toLowerCase().indexOf("decimal") > -1 || item.field_name.toLowerCase().indexOf("int") > -1
          ? { textAlign: "right" }
          : {}
    };
  });
};

// Function use to format the grid data on load.
const child_row_data_formatter = (report_rows: any[],column_def: { type: string; format: string; field: string | number; }[]) => {
  // Column Formatting
  report_rows.map((row) => {
    column_def.map((col: { type: string; format: string; field: React.ReactText }) => {
      if (col.type == "currency" || col.type == "number" || col.type == "percentage") {
        // Formatting of Currency,Percentage and Number type data
        if (col.format && col.format.trim() != "T") {
          row[col.field] = format_as_parameter(row[col.field], col.format);
        } else {
          row[col.field] = format_as_datatype(row[col.field], col.type);
        }
      } else if (col.type === "hyper_link") {
        row[`link_${col.field}`] = strip(row[col.field]);
      } else {
        // Formatting of String and Date type data
        row[col.field] = format_as_datatype(row[col.field], col.type);
      }
    });
    return row;
  });
  return report_rows;
};

export const apply_feature_flip = (columns, column_ff) => {
    if (!columns) return columns;
    columns.map((column) => {
        if (column_ff[`${column.field}`] == undefined) return;
        column.hide = !column_ff[`${column.field}`];
    });
    return columns;
};
