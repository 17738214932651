import * as React from 'react';
/**
 * Checkbox component to handle check/uncheck state from parent qand indivisual
 */

class CheckboxComponent extends React.Component<any, any> {
    [x: string]: any;
    _is_mounted = false;
    //Get innitalise data when component created
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
        };
        this.onChange = this.onChange.bind(this)
    }

    componentDidMount() {
        this._is_mounted = true;

        this.setState({
            checked: this.props.checked
        })
    }
    componentWillUnmount() {
        this._is_mounted = false;
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.checked !== prevProps.checked && this.props.checked !== this.state.checked && this._is_mounted) {
            this.setState({
                checked: this.props.checked
            })
        }
    }
    // Checkbox check change function
    onChange(evt) {
        evt.persist()        
        if (evt.target.checked) {
            this.setState({ checked: true });
            this.props.onChange(evt, true,this.props.value);
        } else {
            this.setState({ checked: false });
            this.props.onChange(evt, false, this.props.value);
        }
    }

    //Render function
    render() {
        return (
            <div className="checkbox">
                <input
                    type="checkbox"
                    id={this.props.id || "ui-chk-id"}
                    name={this.props.name || "ui-chk-name"}
                    checked={this.state.checked || false}
                    value={this.props.value || ""}
                    disabled={this.props.disabled || false}
                    onChange={this.onChange}
                    className={this.props.className||""}
                />
                {this.props.label && <label className={`CheckLabel ${this.props.labelClassName || ''}`} htmlFor={this.props.name || "ui-chk-name"}> {this.props.label}</label>}
            </div>
        );
    }
};

export default CheckboxComponent;