const cloneDeep = require('lodash/clonedeep');
import * as constants from "../835_error_exclusions";
import * as api_request from '../../../shared/network/request_extensions';
import * as global_constants from '../../../global_constants';
import { generate_guid } from '../../../shared/utility';


export const search_configuration = (currentPage: number, pageSize: number, token: string) => {
    //debugger;
    const url = `${global_constants.base_end_points}${global_constants.end_points.error_configuration_835_urls.search}`;

    return api_request.get_config_request(url, token, cloneDeep({ 'params': { currentpage: currentPage, pagesize: pageSize, rnd: generate_guid() } }));

}

export const save_configuration_data = (token: string, configuration_data: any) => {
    //debugger;
    const url = `${global_constants.base_end_points}${global_constants.end_points.error_configuration_835_urls.save}`;

    return api_request.post_config_request(url, token, cloneDeep({ data: { 'data': configuration_data, rnd: generate_guid() } } ) );
}




