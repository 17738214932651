import CheckListFilter from './checklist_filter';
import CurrencyFilter from './currency_filter';
import DateFilter from './date_filter';
import DateTimeFilter from './date_time_filter';
import GroupTextFilter from './group_text_filter';
import LinkFilter from './link_filter';
import NumberFilter from './number_filter';
import TextFilter from './text_filter';
import LinkNumberFilter from './link_number_filter';
import LinkDateFilter from './link_date_filter';
import MonthNameFilter from './month_name_filter';

export const DIMEN = {
    HEIGHT_HEADER: 35,
    HEIGHT_ROW: 30,
    HEIGHT_CONSTANT: 54,
    HEIGHT_NO_RECORD: 300,
    PAGINATION_SIZE: 20
}

//Grid icons
export const ICONS = {
    sortAscending: '<i aria-hidden="true" class="sort ascending icon"></i>',
    sortDescending: '<i aria-hidden="true" class="sort descending icon"></i>',
    groupExpanded: '<i aria-hidden="true" class="angle-color angle up icon"></i>',
    groupContracted: '<i aria-hidden="true" class="angle-color angle down icon"></i>',
    menu: '<i class="filter-active-icon filter icon icon-filter"></i>',
    sortUnSort: '<i aria-hidden="true" class="sort icon"></i>',
};

//Types
export const TYPE_EQUAL = 'is_equal';
export const TYPE_NOT_EQUAL = 'is_not_equal';
export const TYPE_STARTS_WITH = 'starts_with';
export const TYPE_ENDS_WITH = 'ends_with';
export const TYPE_CONTAINS = 'contains';
export const TYPE_NOT_CONTAINS = 'not_contains';
export const OR = 'or';
export const AND = 'and';

//Options dropdown
export const OPTIONS = [
    { key: 'isequal', text: 'Is equal to', value: TYPE_EQUAL },
    { key: 'start', text: 'Starts with', value: TYPE_STARTS_WITH },
    { key: 'contains', text: 'Contains', value: TYPE_CONTAINS },
    { key: 'notcontains', text: 'Not contains', value: TYPE_NOT_CONTAINS },
    { key: 'end', text: 'Ends with', value: TYPE_ENDS_WITH },
    { key: 'isnotequal', text: 'Is not equal to', value: TYPE_NOT_EQUAL }
];

// Operators
export const OPERATOR = [
    { key: 'or', text: 'OR', value: OR },
    { key: 'and', text: 'AND', value: AND },
];


//Grid tamplates to show status for progress and empty state.
export const TEMPLATE_LOADING = '<span>Loading...</span>';
export const TEMPLATE_NO_RECORD = "<span>No Record Available</span>";

export const GRID_BASE_STYLE = {
    boxSizing: "border-box",
    height: "480px",
    width: "100%"
};
export const GRID_PATIENT_STYLE = {
    height: "660px",
    marginBottom: "20px"
};
export const GRID_AUTO_HEIGHT_STYLE = {
    boxSizing: "border-box",
    width: "100%"
};

export const HEADER_TEMPLATE_AMOUNT = {
    template: '<div class="ag-cell-label-container amt-text-right" role="presentation">' +
        '<div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '<i class="small filter icon filter-ipad-amount filter-active-icon"></i>' +
        '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '</div>' +
        '</div>'
};
export const HEADER_TEMPLATE_FIRST_COLUMN = {
    template: '<div class="ag-cell-label-container" role="presentation">' +
        '<i class="small filter icon filter-ipad filter-active-icon"></i>' +
        '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '<div ref="eLabel" class="ag-header-cell-label header-first-col-container" role="presentation" tabindex="0">' +
        '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '</div>' +
        '</div>'
};

export const get_header_template_with_navigation = (id) => {
    return {
        template: '<div class="ag-cell-label-container" role="presentation">' +
            '<i class="small filter icon filter-ipad filter-active-icon"></i>' +
            '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '<div ref="eLabel" class="ag-header-cell-label header-first-col-container" role="presentation" id="'+ id +'" tabindex="0">' +
            '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
            '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
            '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
            '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
            '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
            '</div>' +
            '</div>'
    }
};
export const get_header_group_template_with_navigation = (id, field, name) => {
    return {
        template: '<div class="ag-cell-label-container" role="presentation">' +
            '<i class="small filter icon filter-ipad filter-active-icon"></i>' +
            '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '<div ref="eLabel" class="ag-header-cell-label header-first-col-container" role="presentation" id="' + id + '" tabindex="0" draggable="true" data-field="' + field + '" data-name="' + name + '">' +
            '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
            '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
            '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
            '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
            '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
            '</div>' +
            '</div>'
    }
};

export const HEADER_TEMPLATE_WITH_NAVIGATION = {
    template: '<div class="ag-cell-label-container" role="presentation">' +
        '<i tabindex="0" class="small filter icon filter-ipad filter-active-icon"></i>' +
        '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '<div ref="eLabel" class="ag-header-cell-label header-first-col-container" role="presentation" tabindex="0">' +
        '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '</div>' +
        '</div>'
};

export const HEADER_TEMPLATE_COLUMN = {
    template: '<div class="ag-cell-label-container" role="presentation">' +
        '<i class="small filter icon filter-ipad filter-active-icon"></i>' +
        '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '<div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '</div>' +
        '</div>'
};

//Month array of full name 
export const MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const GRID_FILTER_COMPONENTS = {
    textFilter: TextFilter,
    checklistFilter: CheckListFilter,
    dateTimeFilter: DateTimeFilter,
    dateFilter: DateFilter,
    numberFilter: NumberFilter,
    currencyFilter: CurrencyFilter,
    linkFilter: LinkFilter,
    groupTextFilter: GroupTextFilter,
    linkNumberFilter: LinkNumberFilter,
    linkDateFilter: LinkDateFilter,
    monthNameFilter: MonthNameFilter
};
export const GRID_NAME = {
    ACCOUNT_NOTES: 'ACCOUNT_NOTES',
    MEDICAL_RECORD: 'MEDICAL_RECORD',
    VIEW_CHARGE: 'VIEW_CHARGE',
    ACCOUNT_TASK_COMMENTS: 'ACCOUNT_TASK_COMMENTS'
};