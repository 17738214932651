import { date_format } from '../../global_constants';
import { mm_dd_yyyy_comparator } from '../../shared/component/grid/comparators';
import { custom_date_format, get_columns } from './../../shared/utility';
import { account_notes_configuration } from '../patient_constants';

// This is a comparison function that will result in dates being sorted in
// DESCENDING order.
const date_sort_desc = (obj1, obj2) => {
    if (new Date(obj1.created_date) > new Date(obj2.created_date)) return -1;
    if (new Date(obj1.created_date) < new Date(obj2.created_date)) return 1;
    return 0;
};

// Format data to be given to grid
export const format_account_notes = (task, account_note) => {
    let combined_account_notes = [];
    if (task != null && task != undefined && account_note != null && account_note != undefined) {
        combined_account_notes = task.concat(account_note);
        var combined_account_notes_length = combined_account_notes.length;

        if (combined_account_notes && combined_account_notes.length > 0) {
            for (let j = 0; j < combined_account_notes_length; j++) {
                let current_obj = combined_account_notes[j];
                if (current_obj.due_date) {
                    current_obj.id = current_obj.task_id;
                    current_obj.due_date = current_obj.due_date == null ? null : custom_date_format(current_obj.due_date, date_format["mm/dd/yyyy"]);
                    current_obj.note_text = current_obj.task_description;
                    current_obj.note_code = current_obj.task_type.task_type;
                    current_obj.stick_on = "";
                    current_obj.assignee = current_obj.taskGroup != null && current_obj.task_group.task_group_id > 0 ? current_obj.task_group.group_name : current_obj.assignee;
                    current_obj.created_by = current_obj.created_by_name;
                    current_obj.isTask = true;
                }
                else {
                    current_obj.created_by = current_obj.posted_by;
                    current_obj.due_date = '';
                    current_obj.assignee = "";
                    current_obj.task_status = "";
                    current_obj.isTask = false;
                }
                current_obj.created_date = current_obj.created_date == null ? null : custom_date_format(current_obj.created_date, date_format["mm/dd/yyyy"]);
                if (current_obj.updated_date)
                    current_obj.updated_date = current_obj.updated_date == null ? null : custom_date_format(current_obj.updated_date, date_format["mm/dd/yyyy"]);
            }
        }
    }
    return combined_account_notes.sort((item1, item2) => mm_dd_yyyy_comparator(item2["created_date"], item1["created_date"]));
    // combined_account_notes = combined_account_notes.sort((item1, item2) => mm_dd_yyyy_comparator(item2["created_date"], item1["created_date"]))
    // let grid_width = get_columns(combined_account_notes, account_notes_configuration.column_defs);
    // return {combined_account_notes,grid_width}
}