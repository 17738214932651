import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dimmer, Form, Grid, Loader } from 'semantic-ui-react';
import { date_format } from '../../global_constants';
import { log_error } from '../../shared/action/shared_action';
import * as session_storage from '../../shared/session_storage_utility';
import { custom_date_format, format_patient_name, get_columns, get_lauch_darkley_key_value } from '../../shared/utility';
import * as report_service from '../action/report_action';
import * as report_constants from '../report_constants';
import { apply_feature_flip } from '../util/report_data_formatter';
import * as export_print_utility from '../util/export_print_utility';
import { set_focus_on_element_with_id, set_focus_to_app_header } from './../../shared/tab_navigation_utility';
import LedgerFullPopUpComponent from './ledger_full_pop_up';
import ReportFooter from './report_footer';
import ReportGrid from './report_grid';
import ReportHeader from './report_header';
import ReportSearchCriteriaComponent from './report_search_criteria_component';

enum report {
  ledger_full = 'ledger_full',
  payment_adjustment = 'payment_adjustment',
  charges = 'charges',
  claims = 'claims'
}

class PatientLedgerFullReportComponent extends React.Component<any, any> {
  [x: string]: any;
  _is_mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      report_data: [],
      is_grid_visible: false,
      is_show_pop_up: false,
      grid_params: null,
      pop_up_data: {
        payment_adjustment: [],
        charges: [],
        claims: []
      },
      loading: false,
      is_print_disabled: true,
      is_export_disabled: true,
      current_date: new Date(),
      row_params: {},
      legder_full_footer_obj: {},
      show_date: false,
      report_rows_count: 0
    };
  }
  // Define class level variables.
  report_container: any;
  run_report_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
  ledger_full_column_def = report_constants.report_ledger_full_configuration.column_defs;
  charges_column_def = report_constants.report_charges_configuration.column_defs;
  payment_adjustment_column_def = report_constants.report_payment_adjustment_configuration.column_defs;
  claims_column_def = report_constants.report_claims_configuration.column_defs;
  patient_name: string;
  account_no: string;
  current_date: string;
  company_name: any;
  title: string;
  report_metadata: any[];
  report_data: any[];
  total_column_width: number = 0;
  grid_header_height: number = 0;

  UNSAFE_componentWillMount = () => {
    var patient_id = session_storage.get('active_patient') || 0;
    if (!patient_id) {
      this.props.history.push(`/no_patient_selected`);
      return;
    }
  };

  componentWillUnmount = () => {
    document.body.classList.remove('reports', 'patient-header', 'patient-subHeader');
    this._is_mounted = false;
  };

  componentDidMount() {
    this._is_mounted = true;
    document.body.classList.add('reports');
  }


  // Function calls on click of Run Report button
  on_run_report_handler = search_criteria => {
    var token = this.props.user_login_details.user_data.data.token_details.access_token;
    var search_criteria = { ...search_criteria };
    if (this._is_mounted) {
      this.setState({
        search_criteria: search_criteria,
        loading: true,
        is_search_button_disabled: true
      });
    }
    search_criteria.start_date = custom_date_format(search_criteria.start_date._d, date_format['mm-dd-yyyy']);
    search_criteria.end_date = custom_date_format(search_criteria.end_date._d, date_format['mm-dd-yyyy']);
    report_service.get_ledger_full_report_data(search_criteria, token).then(response => {
      if (response && response.data) {
        var report_rows = response.data.data ? response.data.data : [];
        var formatted_data = this.row_data_formatter(report_rows, report.ledger_full);
          const grid_height = get_columns(report_rows, this.ledger_full_column_def);
          this.total_column_width = grid_height.total_column_width;
          this.grid_header_height = grid_height.header_height;
        if (this._is_mounted) {
          this.setState({
            loading: false,
            report_data: formatted_data,
            is_grid_visible: true,
            is_export_disabled: false,
            is_print_disabled: false,
            show_date: true,
            report_rows_count: report_rows && report_rows.length,
            is_search_button_disabled: false
          });
        }
        this.run_report_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
      }
    }),
      error => {
        if (this._is_mounted) {
          this.setState({
            loading: false,
            is_search_button_disabled: false
          });
        }
        log_error(error);
      };
  };

  //Function used to initialize the footer object.
  initialize_footer_obj = column_def => {
    var footer_obj = {};
    column_def.map((value, index) => {
      if (index == 0) {
        footer_obj[value.field] = 'Total';
      } else if (value.type == 'currency' || value.type == 'number') {
        footer_obj[value.field] = 0;
      } else {
        footer_obj[value.field] = null;
      }
    });
    return footer_obj;
  };

  //FUnction use to format the grid data on load.
  row_data_formatter = (data, report_name) => {
    var column_def;
    switch (report_name) {
      case report.ledger_full:
        column_def = this.ledger_full_column_def;
        break;
      case report.charges:
        column_def = this.charges_column_def;
        break;
      case report.payment_adjustment:
        column_def = this.payment_adjustment_column_def;
        break;
      case report.claims:
        column_def = this.claims_column_def;
        break;
      default:
    }

    if (report_name == report.ledger_full) {
      var footer_obj = this.initialize_footer_obj(column_def);
    }

    data.map(row => {
      column_def.map((value, index) => {
        switch (value.type) {
          case 'date':
            row[value.field] =
              row[value.field] != null || row[value.field] != undefined
                ? report_constants.dateFormatter(row[value.field])
                : '';
            break;
            case 'currency':
            if (report_name == report.ledger_full) {
              footer_obj[value.field] += row[value.field] ? parseFloat(row[value.field]) : 0;
                }
                row[value.field] =
                    row[value.field] != null || row[value.field] != undefined
                        ? report_constants.currencyFormatter(row[value.field])
                        : (value.allowBlank != null && value.allowBlank != undefined && value.allowBlank == true)
                            ? ''
                            : report_constants.currencyFormatter(0);
            break;
          case 'number':
            if (report_name == report.ledger_full) {
              footer_obj[value.field] += row[value.field] ? parseInt(row[value.field]) : 0;
            }
            row[value.field] =
              row[value.field] != null || row[value.field] != undefined ? parseInt(row[value.field]) : 0;
            break;
          case 'boolean':
              if (report_name == report.ledger_full) {
                row[value.field] = row[value.field] ? 'Active' : 'Inactive';
              }            
            break;
          default:
            row[value.field] =
              row[value.field] != null || row[value.field] != undefined
                ? row[value.field] === row.is_active
                  ? this.capitalize_text(row[value.field].toString().trim())
                  : row[value.field].toString().trim()
                : '';
          //value.minWidth = value.minWidth < ((row[value.field].length * 7) + 50) ? ((row[value.field].length * 7) + 50)  : value.minWidth;
        }
      });
      return row;
    });

    if (report_name == report.ledger_full) {
      column_def.map((value, index) => {
        if (value.type == 'currency') {
          footer_obj[value.field] = report_constants.currencyFormatter(footer_obj[value.field]);
        }
      });
      if (this._is_mounted) {
        this.setState({ legder_full_footer_obj: footer_obj });
      }
    }

    return data;
  };

  capitalize_text = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Function calls on double click of grid and opens popup
  // To Do, need to replace nested calls with axios.all()
  onCellClicked = async ({ data, column }) => {
    if (!(column.colId == 'dos')) {
      return;
    }
    if (this._is_mounted) {
      this.setState({
        loading: true,
        row_params: data,
        report_name: data.dos ? data.dos : '&nbsp;'
      });
    }
    var token = this.props.user_login_details.user_data.data.token_details.access_token;
    var search_criteria = { ...this.state.search_criteria, charge_id: data.charge_id };
    let arrData = [];
    arrData.push(report_service.get_ledger_payment_adjustment_report_data(search_criteria, token));
    arrData.push(report_service.get_ledger_charges_report_data(search_criteria, token));
    arrData.push(report_service.get_ledger_claims_report_data(search_criteria, token));

    await Promise.all(arrData)
      .then(response => {
        if (this._is_mounted) {
          this.setState({
            pop_up_data: {
              ...this.state.pop_up_data,
              payment_adjustment: this.row_data_formatter(response[0].data.data, report.payment_adjustment),
              charges: this.row_data_formatter(response[1].data.data, report.charges),
              claims: this.row_data_formatter(response[2].data.data, report.claims)
            },
            loading: false,
            is_show_pop_up: true
          });
        }
      })
      .catch(error => {
        if (this._is_mounted) {
          this.setState({
            loading: false
          });
        }
        log_error(error);
      });
  };

  //Function calls on click of Close button of popup
  on_pop_up_close = () => {
    if (this._is_mounted) {
      this.setState({ is_show_pop_up: false });
    }
  };

  //Function calls on click of Clear button and clear all the searched result as well as criteria.
  clear_handler = () => {
    if (this._is_mounted) {
      this.setState({
        loading: true
      });
    }
    setTimeout(() => {
      if (this._is_mounted) {
        this.setState({
          report_data: [],
          is_grid_visible: false,
          is_export_disabled: true,
          is_print_disabled: true,
          show_date: false,
          loading: false
        });
      }
      this.report_data = [];
      this.report_metadata = [];
    }, 400);
  };

  //Function calls after click of filter button and recalculate the footer sum.
  on_filter_button_click = () => {
    var footer_obj = this.initialize_footer_obj(this.ledger_full_column_def);
    var cell_data;
    var row_api = this.state.grid_params.api;
    if (row_api) {
      row_api.forEachNodeAfterFilter((row, index) => {
        row = row.data;
        this.ledger_full_column_def.map((value, index) => {
          switch (value.type) {
            case 'currency':
              if (row[value.field].indexOf('(') != -1) {
                cell_data = row[value.field] ? '-' + row[value.field].substring(2, row[value.field].length - 1) : 0;
              } else {
                cell_data = row[value.field] ? row[value.field].substring(1, row[value.field].length) : 0;
              }
              footer_obj[value.field] += isNaN(parseFloat(cell_data)) ? 0 : parseFloat(cell_data);
              break;
            case 'number':
              footer_obj[value.field] += row[value.field] ? parseInt(row[value.field]) : 0;
              break;
            default:
          }
        });
      });

      this.ledger_full_column_def.map((value, index) => {
        if (value.type == 'currency') {
          footer_obj[value.field] = report_constants.currencyFormatter(footer_obj[value.field]);
        }
      });
      row_api.setPinnedBottomRowData([footer_obj]);
    }
  };

  //Function calls on intialization of Ag-Grid and catch its reference.
  get_grid_ref = grid_params => {
    if (this._is_mounted) {
      this.setState({
        grid_params,
        report_rows_count: grid_params.api.getModel().getRowCount()
      });
    }
    var grid_width = document.getElementsByClassName('ag-body-viewport')[0].clientWidth;
    if (this.total_column_width > grid_width) {
      this.ledger_full_column_def.filter(item => {
        grid_params.columnApi.setColumnWidth(item.field, item['width'], false);
      });
    }
  };

  //Function calls on initialization of export report data
  on_export_button = () => {
    this.title = this.set_title('\n');
    export_print_utility.export_grid_data(this.state.grid_params, this.title, 'Ledger Full');
  };

  //Function calls on initialization of Print report data
  on_print_button = () => {
    this.title = this.set_title('<br>');
    export_print_utility.print_grid_data(this.state.grid_params, this.title,'Ledger Full');
  };

  //Used to create the title for export and print.
  set_title = separter => {
    this.company_name = this.props.user_login_details.user_data.data.company_name;
    this.patient_name =
      this.props.location.pathname.indexOf('patient') != -1
        ? format_patient_name(this.props.selected_patient.data.name) + separter
        : '';
    this.account_no =
      this.props.location.pathname.indexOf('patient') != -1
        ? 'Account#:' + session_storage.get('active_patient') + separter
        : '';
    this.current_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
    return (this.title =
      'Ledger Full' +
      separter +
      this.company_name +
      separter +
      this.patient_name +
      this.account_no +
      this.current_date +
      separter +
      (this.state.report_rows_count == 1
        ? this.state.report_rows_count + ' record'
        : this.state.report_rows_count + ' records') +
      separter +
      separter);
  };

  handle_navigation_after_last_element = event => {
    // apply a check where grid is empty
    if (
      !event.shiftKey &&
      event.keyCode == '9' &&
      (this.state.report_data == null || this.state.report_data.length == 0)
    ) {
      set_focus_to_app_header(event);
    }
  };

  update_report_rows_count = () => {
    this.setState({
      report_rows_count: this.state.grid_params.api.getModel().getRowCount()
    });
  };
  render() {
    return (
      <div className={'common-forms-add financial'}>
        <Dimmer active={this.state.loading}>
          <Loader size='massive'>Loading</Loader>
        </Dimmer>
        <div className={'common-forms-search report-framework'}>
          <ReportHeader title={'Ledger Full'} />
          <div id='report-scrollable-area' className='report-wrapper' style={this.state.is_grid_visible ? { flex: 1 } : {}}>
            <div className='patient-search-form  patient_search_bottom_padding' id='report-criteria-container'>
              <Form autoComplete='off'>
                <ReportSearchCriteriaComponent
                  on_run_report={this.on_run_report_handler}
                  on_clear={this.clear_handler}
                  handle_navigation_after_last_element={this.handle_navigation_after_last_element}
                  is_search_button_disabled={this.state.is_search_button_disabled}
                />
              </Form>
            </div>
            {this.state.is_grid_visible && (
              <React.Fragment>
                <Grid className='dateTime'>
                  <Grid.Column tablet={5} computer={4} textAlign='left'>
                    <p style={{ fontSize: '16px', minHeight: 22 }}>{this.run_report_date}</p>
                  </Grid.Column>
                  <Grid.Column tablet={5} computer={8} textAlign='center'>
                    {
                      <p style={{ fontSize: '16px', minHeight: 22 }}>
                        {`${this.state.report_rows_count} ${
                          this.state.report_rows_count == 1 ? ' record shown' : ' records shown'
                        }`}
                      </p>
                    }
                  </Grid.Column>
                  <Grid.Column tablet={2} computer={4} textAlign='right' />
                </Grid>
                <ReportGrid
                  id={report_constants.report_ledger_full_configuration.patient_report_grid_id}
                  row={this.state.report_data}
                  column={this.ledger_full_column_def}
                  styles={{ height: '100%' }}
                  wrapperStyle={{ width: '100%', height: '100%', display: 'flex' }}
                  suppressColumnVirtualisation={true}
                  suppressMovableColumns={false}
                  enableColResize={true}
                  emptyMessage={report_constants.messages.no_records_found}
                  pinnedBottomRowData={[this.state.legder_full_footer_obj]}
                  onCellClicked={this.onCellClicked}
                  get_grid_ref={this.get_grid_ref}
                  on_filter_button_click={() => {
                    this.on_filter_button_click();
                    this.update_report_rows_count();
                  }}
                  headerHeight={this.grid_header_height}
                  onGridOut={() => {
                    set_focus_on_element_with_id('app_header_dropdown');
                  }}
                  headerIdForTabNavigation={report_constants.report_ledger_full_configuration_header_id}
                />
              </React.Fragment>
            )}
          </div>
          <div
            className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
            id='applicationFooterSticky'
          >
            <ReportFooter
              on_print_button={() => this.on_print_button()}
              on_export_button={() => this.on_export_button()}
              export_disabled={this.state.is_export_disabled}
              print_disabled={this.state.is_print_disabled}
            />
          </div>
          {this.state.is_show_pop_up && (
            <LedgerFullPopUpComponent
              show_modal={this.state.is_show_pop_up}
              pop_up_data={this.state.pop_up_data}
              on_pop_up_close={this.on_pop_up_close}
              row_params={this.state.row_params}
              report_name={this.state.report_name} 
            />
          )}
        </div>
      </div>
    );
  }
}

//Get user and login details from store.
const mapStateToProps = state => {
  return {
    user_login_details: state.user_login_details,
    selected_patient: state.patient_details.patient_header,
    is_expand: state.shared_details.is_expand
  };
};

export default connect(mapStateToProps)(PatientLedgerFullReportComponent);
