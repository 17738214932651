import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Dimmer, Form, Grid, Loader, Input } from "semantic-ui-react";
import { set_focus_on_element_with_id } from '../../../shared/tab_navigation_utility'
import GridView from '../../../shared/component/grid';
import { toastr } from 'react-redux-toastr';
import { get_all_error, get_columns } from '../../../shared/utility';
import { toastr_options } from '../../../global_constants';
import * as session_storage from '../../../shared/session_storage_utility';
import { get_referring_physician_status, update_referring_physician_status } from '../action/constants_action';
import { referring_physician_status_configuration, search_grid_id } from '../constants';
import { log_error } from '../../../shared/action/shared_action';

/**
 * Referring Physician Status
 */
export class ReferringPhysicianStatusComponent extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            show_grid: false,
            is_search_disabled: false,
            is_update_disabled: false,
            grid_params: [],
            company_name: props.user_login_details.user_data.data.gpms_code + " - " + props.user_login_details.user_data.data.company_name,
            grid_rows_count: 0,
            update_pending: true,
            search_key: ''
        };

        this.handle_clear = this.handle_clear.bind(this);
    }


    total_column_width: number = 0;
    grid_header_height: number = 0;
    width_padding: number = 32;
    grid_column_def: any = [];
    selected_rows: any = [];
    grid_data: any = { rows: [] };

    UNSAFE_componentWillMount = () => {
        this.grid_column_def = referring_physician_status_configuration.column_defs.map(col => ({ ...col }));
    };

    componentDidMount = () => {
        document.body.classList.add('admin-framework');
        let search_key = session_storage.get('referring_physician_status_key');
        if (search_key) {
            this.setState({ search_key: search_key ? search_key : '' }, () => {
                this.handle_search();
            })
        }
    }

    componentWillUnmount = () => {
        document.body.classList.remove('admin-framework');
        if (this.props.history.location) {
            let route_array = this.props.history.location.pathname.split('/');
            if (route_array && route_array[2] !== ('referring_physician' || 'referring_physician_status')) {
                session_storage.remove('referring_physician_status_key');
            }
        }
    }

    show_html_content_toaster = (msg) => {
        return {
            component: () => (<div><div dangerouslySetInnerHTML={{ __html: msg }} /></div>),
            timeOut: toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }

    get_grid_ref = grid_params => {
        this.setState({
            grid_params,
            grid_rows_count: grid_params.api.getModel().getRowCount()
        });
        var grid_width = document.getElementsByClassName('admin-wrapper')[0].clientWidth;
        if (this.total_column_width > (grid_width - this.width_padding)) {
            this.grid_column_def.filter(item => {
                grid_params.columnApi.setColumnWidth(item.field, item.width, false);
            });
        }
    };

    on_filter_changed = () => {
        this.setState(state => ({
            grid_rows_count: state.grid_params.api.getModel().getRowCount()
        }));
    };

    on_grid_out = () => {
        set_focus_on_element_with_id('app_header_dropdown');
    };

    is_row_selectable = (params) => {
        return params.data.is_complete ? false : true;
    }

    handle_change = (e) => {
        this.setState({ search_key: e.target.value });
    };

    handle_clear = () => {
        this.setState({
            show_grid: false,
            is_search_disabled: false,
            is_update_disabled: false,
            search_key: '',
            grid_rows_count: 0,
            loading: false,
            update_pending: true
        });
        this.selected_rows = [];
        this.grid_data = { rows: [] }
        session_storage.remove(`referring_physician_status_key`);
    }

    handle_cancel = () => {
        this.setState({
            show_grid: false,
            is_search_disabled: false,
            is_update_disabled: false,
            grid_rows_count: 0,
            loading: false,
            update_pending: true
        });

        this.selected_rows = [];
        this.grid_data = { rows: [] }
    }

    get_full_name = (row) => {
        let name = null;
        if (row.first_name) {
            name = row.first_name;
        }
        if (row.middle_name) {
            name += " " + row.middle_name;
        }
        if (row.last_name) {
            name += " " + row.last_name;
        }

        return name;
    }

    row_data_formatter = (rows) => {
        return rows.map((row) => {
            //If true, show a check-mark otherwise shows nothing.
            //Added new key is_complete to display in the filter
            // i.e filter true for check-mark
            row.is_complete = (row.is_incomplete_rf3 === false) ? true : false;
            return row
        });
    }

    handle_search = async (event = null) => {
        this.setState({
            is_search_disabled: true,
            is_update_disabled: true,
            loading: true,
            show_grid: false,
            update_pending: true
        });

        this.selected_rows = [];
        this.grid_data = { rows: [] }
        var token = this.props.user_login_details.user_data.data.token_details.access_token;
        await get_referring_physician_status(token, this.state.search_key || ' ').then(
            (response) => {
                if (response.data && response.data.data && response.data.data.length > 0) {
                    this.grid_data.rows = this.row_data_formatter(response.data.data);
                    session_storage.set('referring_physician_status_key', this.state.search_key || ' ');
                    const grid_height = get_columns(this.grid_data.rows, this.grid_column_def);
                    this.total_column_width = grid_height.total_column_width;
                    this.grid_header_height = grid_height.header_height;
                    this.setState({
                        is_search_disabled: false,
                        is_update_disabled: false,
                        loading: false,
                        show_grid: true,
                        grid_rows_count: this.grid_data.rows.length,
                        update_pending: true
                    });
                } else {
                    this.handle_cancel()
                    toastr.error("", referring_physician_status_configuration.invalid_search);
                }
            },
            (error) => {
                log_error(error);
                if (error.response && error.response.data) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.data));
                    toastr.error('', toastr_options);
                }

                this.setState({
                    is_search_disabled: false,
                    is_update_disabled: false,
                    loading: false,
                    show_grid: true,
                    update_pending: true,
                    grid_rows_count: this.grid_data.rows.length
                });
            }
        );
    }

    on_check_box_selection = (data) => {
        this.selected_rows = data;
    }

    handle_update = async () => {
        if (this.selected_rows.length > 0) {
            const physician_ids = this.selected_rows.map(row => (row.referring_physician_id));
            let param = `incompletePhysicianIds=${physician_ids.join()}`;
            this.setState({
                loading: true,
                is_search_disabled: true,
                is_update_disabled: true
            });
            var token = this.props.user_login_details.user_data.data.token_details.access_token;
            await update_referring_physician_status(token, param).then(
                (response) => {
                    if (response.data && response.data.data) {
                        toastr.success("", referring_physician_status_configuration.success_message);
                        this.selected_rows = [];
                        this.setState({
                            loading: false,
                            is_search_disabled: false,
                            is_update_disabled: false,
                            update_pending: false,
                        }, () => {
                            this.handle_search();
                        });
                    } else {
                        if (response.data && response.data.messages && response.data.messages[0].message.indexOf("<br") > -1) {
                            let toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                            toastr.error("", toastr_options);
                        }
                        this.setState({
                            loading: false,
                            is_search_disabled: false,
                            is_update_disabled: false,
                            update_pending: true
                        });
                    }
                },
                (error) => {
                    this.setState({
                        loading: false,
                        is_search_disabled: false,
                        is_update_disabled: false,
                        update_pending: true
                    });
                    log_error(error);
                    if (error.response && error.response.data) {
                        const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                        toastr.error("", toastr_options);
                    }
                }
            );
        } else {
            toastr.error('', referring_physician_status_configuration.selection_error);
        }
    };

    // On double click of grid, it redirects user to view page.
    on_row_double_clicked = (selected_row_data: { referring_physician_id: any; }) => {
        let code_id = selected_row_data.referring_physician_id;
        this.props.history.push(`/admin/referring_physician/${code_id}`);
    };

    render() {
        return (
            <React.Fragment>
                <Dimmer active={this.state.loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <div className='admin-wrapper' style={this.grid_data.rows.length != 0 ? { paddingBottom: 20 } : {}}>
                    <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                        <Grid.Column computer={16}>
                            <h3 className='ui header left aligned'>Referring Physician Status</h3>
                        </Grid.Column>
                    </Grid>
                    <div id='admin-scrollable-area' className='wrapper' style={this.state.show_grid ? { flex: 1 } : {}}>
                        <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container'>
                            <Form id="referring_physician_status_container" autoComplete='off' onSubmit={e => this.handle_search(e)}>
                                <Grid style={{ marginBottom: 0 }}>
                                    <Grid.Column computer={16} >
                                        <Grid>
                                            <Grid.Column tablet={8} computer={5}>
                                                <Form.Field >
                                                    <label>Company</label>
                                                    <span style={{ fontSize: 16 }}>{this.state.company_name}</span>
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column tablet={8} computer={5}>
                                                <Form.Field>
                                                    <label>Search Criteria</label>
                                                    <Input
                                                        autoComplete='off'
                                                        name='search_key'
                                                        id='id_search_key'
                                                        maxLength={50}
                                                        type='text'
                                                        value={this.state.search_key}
                                                        onChange={e => this.handle_change(e)}
                                                    />
                                                    <span style={{ color: '#757575', fontSize: 10, marginTop: 5 }}>
                                                        MD Code, First Name, or Last Name
                                                    </span>
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid>
                                    </Grid.Column>
                                </Grid>
                                <Grid style={{ margin: '0 -17px' }}>
                                    <Grid.Column computer={16} textAlign='right'>
                                        <Button id="clear_form" type="button" onClick={this.handle_clear} basic >Clear</Button>
                                        <Button id="id_search" type='submit' primary disabled={this.state.is_search_disabled} >Search</Button>
                                    </Grid.Column>
                                </Grid>
                                {this.state.show_grid && (
                                    <Grid style={{ marginTop: '-1rem', marginBottom: 0 }}>
                                        <Grid.Column tablet={5} computer={4} textAlign='left'>
                                            <p style={{ fontSize: '16px' }}>Search Results</p>
                                        </Grid.Column>
                                        <Grid.Column tablet={5} computer={8} textAlign='center'>
                                            {
                                                <p style={{ fontSize: '16px', minHeight: 22 }}>
                                                    {`${this.state.grid_rows_count} ${this.state.grid_rows_count == 1 ? ' record shown' : ' records shown'
                                                        }`}
                                                </p>
                                            }
                                        </Grid.Column>
                                        <Grid.Column tablet={2} computer={4} textAlign='right' />
                                    </Grid>
                                )}
                            </Form>
                        </div>
                        {this.state.show_grid && (
                            <GridView
                                id={referring_physician_status_configuration.grid_id}
                                row={this.grid_data.rows}
                                column={this.grid_column_def}
                                style={{ height: '100%' }}
                                wrapperStyle={{ width: '100%', height: '100%', display: 'flex' }}
                                suppressMovableColumns={false}
                                enableColResize={true}
                                selectionType={'single'}
                                checkboxSelection={true}
                                gridAutoRowHeight={true}
                                onRowSelection={(item) => this.on_check_box_selection(item)}
                                onRowDoubleClicked={this.on_row_double_clicked}
                                get_grid_ref={this.get_grid_ref}
                                suppressSizeToFit={true}
                                headerHeight={this.grid_header_height}
                                headerIdForTabNavigation={search_grid_id}
                                onForceGridOut={this.on_grid_out}
                                isRowSelectable={this.is_row_selectable}
                                on_filter_button_click={this.on_filter_changed}
                            />)}
                    </div>
                    {this.state.show_grid > 0 && this.state.update_pending && (
                        <div className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area' id='applicationFooterSticky' style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <Grid.Column computer={16} textAlign='right'>
                                <Button type="button" id="btn_id_cancel"
                                    onClick={this.handle_cancel} basic >Cancel</Button>
                                <Button primary id='btn_id_update'
                                    disabled={this.state.is_update_disabled} type='button'
                                    style={{ marginRight: 0 }}
                                    onClick={this.handle_update}>Update
                                </Button>
                            </Grid.Column>
                        </div>)}
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({

    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReferringPhysicianStatusComponent));