import ExecutionEnvironment from "exenv";
import { is_testing } from './../../../utility';

let can_use_dom;
const EE = ExecutionEnvironment;

if (is_testing()) {
    can_use_dom = true
} else {
    can_use_dom = EE.canUseDOM
}


const SafeHTMLElement = can_use_dom ? window.HTMLElement : {};

export const canUseDOM = can_use_dom;

export default SafeHTMLElement;
