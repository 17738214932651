import { Drawer, Position } from '@blueprintjs/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { Grid, Header, Icon } from 'semantic-ui-react';
import DrawerComponent from '../../../../shared/component/drawer_component';
import PermissionsEditComponent from './permissions_edit_component';
import { toastr_options } from '../../../../global_constants';
import { log_error } from '../../../../shared/action/shared_action';
import { get_all_error } from '../../../../shared/utility';
import {
  get_permission_list,
  get_permission_sets_list,
  get_roles_list,
  update_permissions,
  search_r2_role,
  get_role_with_permission,
  add_update_role,
  add_update_permission_set
} from '../../action/constants_action';
import SearchComponent from './search_component';
import PermissionSetRolesViewAddEditComponent from './add_edit_drawer_component';
import ExtendedPermissionSetRolesViewComponent from './extended_permission_set_roles_view_component';
import { toastr as toaster } from 'react-redux-toastr';
import {
  option_formater as option_formatter,
  roles_permission_set_data,
  roles_permissions_msg,
  roles_ps_keys_to_delete,
  roles_permission_set_controls
} from '../../constants';
import { LoaderComponent } from '../../../../shared/component/loading_component';
import { get_service_type, get_user_type_for_roles } from '../../../../user/action/user_action';
import { handle_click_on_enter, set_focus_on_element_with_id } from '../../../../shared/tab_navigation_utility';
import { ERROR_CORRECTION_MESSAGE } from '../../../../payment/payment_constants';
import ExtendedDrawerComponent from '../../../../shared/component/extended_drawer_component';

export class RolesAndPermissionsComponent extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      is_add_edit_allowed: true,
      isEditPermissionsOpen: false,
      isEditExtendedOpen: false,
      drawer_type: '',
      action_type: '',
      role_list: [],
      permission_sets_list: [],
      permission_list: [],
      drawer_selected_item: {},
      user_type: this.props.user_login_details.user_data.data.user_type_r3_id,
      extended_drawer_data: {},
      is_from_toaster: false,
      extended_drawer_id: 0
    };
    this.token = this.props.user_login_details.user_data.data.token_details.access_token;
  }

  // Class level variable
  is_mounted = false;
  menu_permission_object: Array<any> = [{ update: true }];
  roles_ref: any;
  permission_set_ref: any;
  token: any = '';
  options_data = {
    service_type: [],
    user_type: [],
    permission_id: [],
    role_id: [],
    r2_role_id: []
  };

  componentDidMount() {
    document.body.classList.add('admin-framework');
    this.is_mounted = true;
    this.checkUpdatePermission();
    this.get_data();
  }

  componentWillUnmount = () => {
    this.is_mounted = false;
    document.body.classList.remove('admin-framework');
  };

  checkUpdatePermission = () => {
    this.menu_permission_object = this.props.shared_details.left_menu_data.data.filter((menu) => {
      return menu.name === 'Roles & Permissions - Edit';
    });
    if (this.menu_permission_object.length === 0 || !this.menu_permission_object[0].view) {
      if (this.is_mounted) {
        this.setState({
          is_add_edit_allowed: false
        });
      }
    }
  };

  get_data = async () => {
    let arrData = [];
    let { role_list, permission_sets_list, permission_list } = this.state;
    if (this.is_mounted) {
      this.setState({
        loading: true
      });
    }
    arrData.push(get_roles_list(this.token, { user_type: this.state.user_type }));
    arrData.push(get_permission_sets_list(this.token, { user_type: this.state.user_type }));
    arrData.push(get_service_type(this.token));
    arrData.push(get_user_type_for_roles(this.token, { user_type_r3_id: this.state.user_type }));
    arrData.push(get_permission_list(this.token, { user_type: this.state.user_type }));
    arrData.push(search_r2_role(this.token, { text_to_search: '' }));
    await Promise.all(arrData)
      .then((response) => {
        if (response[0].data && response[0].data.data) {
          role_list = response[0].data.data;
          this.options_data.role_id = option_formatter(response[0].data.data, 'name', 'id', true, false, 'Select');
        }
        if (response[1].data && response[1].data.data) {
          permission_sets_list = response[1].data.data;
        }
        if (response[2].data && response[2].data.data) {
          response[2].data.data.find((res) => res.service_type === 'Software Only') && (response[2].data.data.find((res) => res.service_type === 'Software Only').service_type = 'Self Service');
          this.options_data.service_type = option_formatter(
            response[2].data.data,
            'service_type',
            'id',
            true,
            false,
            'Select'
          );
        }
        if (response[3].data && response[3].data.data) {
          response[3].data.data.find((res) => res.name === 'BMS') && (response[3].data.data.find((res) => res.name === 'BMS').name = 'WebPT');
          this.options_data.user_type = option_formatter(
            response[3].data.data,
            'name',
            'user_type_id',
            true,
            false,
            'Select'
          );
        }
        if (response[4].data && response[4].data.data) {
          permission_list = response[4].data.data.map((item) => {
            return {
              ...item,
              menu_description: item.menu_description || ''
            };
          });
          this.options_data.permission_id = option_formatter(
            response[4].data.data,
            'name',
            'id',
            true,
            false,
            'Select'
          );
        }
        if (response[5].data && response[5].data.data) {
          this.options_data.r2_role_id = option_formatter(
            response[5].data.data,
            'role_name',
            'role_id',
            true,
            false,
            'Select'
          );
        }
        if (this.is_mounted) {
          this.setState({
            loading: false,
            role_list,
            permission_sets_list,
            permission_list
          });
        }
      })
      .catch((error) => {
        if (this.is_mounted) {
          this.setState({
            role_list,
            permission_sets_list,
            permission_list,
            loading: false
          });
        }
        if (error.response) {
          error.response.map((item) => {
            if (item.error.response && item.error.response.data && item.error.response.data.messages) {
              log_error(item.error);
              const toastr_options = this.show_html_content_toaster(get_all_error(item.error.response.data));
              toaster.error('', toastr_options);
            }
            return;
          });
        }
      });
  };

  get_roles_list = async () => {
    let { role_list } = this.state;
    if (this.is_mounted) {
      this.setState({
        loading: true
      });
    }
    await get_roles_list(this.token, { user_type: this.state.user_type })
      .then((response) => {
        if (response.data && response.data.data) {
          role_list = response.data.data;
          this.options_data.role_id = option_formatter(response.data.data, 'name', 'id', true, false, 'Select');
        }
        if (this.is_mounted) {
          this.setState({
            loading: false,
            role_list
          });
        }
        this.roles_ref.clear_search();
      })
      .catch((error) => {
        if (this.is_mounted) {
          this.setState({
            role_list,
            loading: false
          });
        }
        if (error.response) {
          error.response.map((item) => {
            if (item.error.response && item.error.response.data && item.error.response.data.messages) {
              log_error(item.error);
              const toastr_options = this.show_html_content_toaster(get_all_error(item.error.response.data));
              toaster.error('', toastr_options);
            }
            return;
          });
        }
      });
  };

  get_permission_sets_list = async () => {
    let { permission_sets_list } = this.state;
    if (this.is_mounted) {
      this.setState({
        loading: true
      });
    }
    await get_permission_sets_list(this.token, { user_type: this.state.user_type })
      .then((response) => {
        if (response.data && response.data.data) {
          permission_sets_list = response.data.data;
        }
        if (this.is_mounted) {
          this.setState({
            loading: false,
            permission_sets_list
          });
        }
        this.permission_set_ref.clear_search()
      })
      .catch((error) => {
        if (this.is_mounted) {
          this.setState({
            permission_sets_list,
            loading: false
          });
        }
        if (error.response) {
          error.response.map((item) => {
            if (item.error.response && item.error.response.data && item.error.response.data.messages) {
              log_error(item.error);
              const toastr_options = this.show_html_content_toaster(get_all_error(item.error.response.data));
              toaster.error('', toastr_options);
            }
            return;
          });
        }
      });
  };

  get_permission_list = async () => {
    let { permission_list } = this.state;
    if (this.is_mounted) {
      this.setState({
        loading: true
      });
    }
    await get_permission_list(this.token, { user_type: this.state.user_type })
      .then((response) => {
        if (response.data && response.data.data) {
          permission_list = response.data.data.map((item) => {
            return {
              ...item,
              menu_description: item.menu_description || ''
            };
          });
          this.options_data.permission_id = option_formatter(response.data.data, 'name', 'id', true, false, 'Select');
          if (this.is_mounted) {
            this.setState({
              loading: false,
              permission_list
            });
          }
        }
      })
      .catch((error) => {
        if (this.is_mounted) {
          this.setState({
            permission_list,
            loading: false
          });
        }
        if (error.response) {
          error.response.map((item) => {
            if (item.error.response && item.error.response.data && item.error.response.data.messages) {
              log_error(item.error);
              const toastr_options = this.show_html_content_toaster(get_all_error(item.error.response.data));
              toaster.error('', toastr_options);
            }
            return;
          });
        }
      });
  };

  update_permission = async (params) => {
    if (params.length === 0) {
      toaster.warning('', ERROR_CORRECTION_MESSAGE.NO_EDIT_CHANGE);
      return;
    }

    if (this.is_mounted) {
      this.setState({
        loading: true,
        isEditPermissionsOpen: false
      });
    }
    await update_permissions(this.token, params)
      .then((response) => {
        if (response.data && response.data.status) {
          toaster.success('', 'Permission(s) updated successfully.');
          this.get_permission_list();
        }
      })
      .catch((error) => {
        if (this.is_mounted) {
          this.setState({
            loading: false
          });
        }
        if (error.response) {
          error.response.map((item) => {
            if (item.error.response && item.error.response.data && item.error.response.data.messages) {
              log_error(item.error);
              const toastr_options = this.show_html_content_toaster(get_all_error(item.error.response.data));
              toaster.error('', toastr_options);
            }
            return;
          });
        }
      });
  };

  handle_success_msg = (val) => {
    let { action_type } = this.state;
    let msg = '';
    switch (action_type) {
      case 'new':
        msg = roles_permissions_msg[val + '_add_msg'];
        break;
      case 'copy':
        msg = roles_permissions_msg[val + '_add_msg'];
        break;
      case 'edit':
        msg = roles_permissions_msg[val + '_update_msg'];
        break;
      default:
        msg = '';
        break;
    }
    return msg;
  };

  add_update_roles = async (params) => {
    let { action_type } = this.state;
    if (this.is_mounted) {
      this.setState({
        loading: true
      });
    }

    let formatted_params = {
      ...params,
      ...(action_type === 'copy' && { parent_id: parseInt(params.parent_id) }),
      permission_set_ids: params.selected_list || [],
      id: parseInt(params.id) || 0,
      is_active: true,
      required_user_level: parseInt(params.user_type)
    };

    roles_ps_keys_to_delete.forEach((e) => delete formatted_params[e]);

    await add_update_role(this.token, formatted_params)
      .then((response) => {
        if (response.data && response.data.status) {
          let msg = this.handle_success_msg('role');
          const toastr_options = this.show_html_content_toaster(msg);
          toaster.success('', toastr_options);
          this.get_roles_list();
          this.setState({
            isEditDrawerOpen: false,
            drawer_type: '',
            action_type: '',
            extended_drawer_id: 0,
            isEditExtendedOpen: false
          });
        } else {
          const toastr_options = this.show_html_content_toaster(get_all_error(response.data));
          toaster.error('', toastr_options);
          if(response.data.messages && response.data.messages[0].message ==='The role name entered is a duplicate.'){
            this.set_error_on_drawer();
          }
        }
        this.setState({
          loading: false
        });
      })
      .catch((error) => {
        if (this.is_mounted) {
          this.setState({
            loading: false,
            drawer_type: '',
            action_type: ''
          });
        }
        if (error.response) {
          error.response.map((item) => {
            if (item.error.response && item.error.response.data && item.error.response.data.messages) {
              log_error(item.error);
              const toastr_options = this.show_html_content_toaster(get_all_error(item.error.response.data));
              toaster.error('', toastr_options);
            }
            return;
          });
        }
      });
  };

  set_error_on_drawer = () => {
    let id = roles_permission_set_controls[this.state.drawer_type][0].id;
    const input: any = document.getElementById(id);
    set_focus_on_element_with_id(id);
    input.parentNode.classList.add("req-border-inp");
  }

  add_update_permission_set = async (params) => {
    let { action_type } = this.state;
    if (this.is_mounted) {
      this.setState({
        loading: true
      });
    }

    let formatted_params = {
      ...params,
      ...(action_type === 'copy' && { parent_id: parseInt(params.parent_id) }),
      permission_ids: params.selected_list || [],
      id: parseInt(params.id) || 0,
      is_active: true,
      required_user_level: parseInt(params.user_type)
    };

    roles_ps_keys_to_delete.forEach((e) => delete formatted_params[e]);

    await add_update_permission_set(this.token, formatted_params)
      .then((response) => {
        if (response.data && response.data.status) {
          let msg = this.handle_success_msg('permission_set');
          const toastr_options = this.show_html_content_toaster(msg);
          toaster.success('', toastr_options);
          this.get_permission_sets_list();
          this.setState({
            isEditDrawerOpen: false,
            drawer_type: '',
            action_type: '',
            extended_drawer_id: 0,
            isEditExtendedOpen: false
          });
        } else {
          const toastr_options = this.show_html_content_toaster(get_all_error(response.data));
          toaster.error('', toastr_options);
          if(response.data.messages && response.data.messages[0].message ==='The permission set name entered is a duplicate.'){
            this.set_error_on_drawer();
          }
        }
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        if (this.is_mounted) {
          this.setState({
            loading: false,
            drawer_type: '',
            action_type: ''
          });
        }
        if (error.response) {
          error.response.map((item) => {
            if (item.error.response && item.error.response.data && item.error.response.data.messages) {
              log_error(item.error);
              const toastr_options = this.show_html_content_toaster(get_all_error(item.error.response.data));
              toaster.error('', toastr_options);
            }
            return;
          });
        }
      });
  };

  update_status = async (drawer_type, id, is_from_toaster = false) => {
    if (this.is_mounted) {
      this.setState({
        loading: true,
        is_from_toaster: is_from_toaster
      });
    }
    const params = {
      [roles_permission_set_data[drawer_type].params]: id,
      mark_in_active: !is_from_toaster
    };
    await roles_permission_set_data[drawer_type]
      .remove_url(this.token, params)
      .then((response) => {
        let message = '';
        if (response.data && response.data.status) {
          message = response.data.messages && response.data.messages[0].message
              ? response.data.messages[0].message
              : '';
          if(message !=''){
            this.show_toaster(message, drawer_type, id, response.data.status);
          }
        } else {
          message = response.data.messages[0].message;
          this.show_toaster(message, drawer_type, 0, response.data.status);
          this.setState({
            loading: false
          });
        }
      })
      .catch((error) => {
        if (this.is_mounted) {
          this.setState({
            loading: false
          });
        }
        if (error.response) {
          error.response.map((item) => {
            if (item.error.response && item.error.response.data && item.error.response.data.messages) {
              log_error(item.error);
              const toastr_options = this.show_html_content_toaster(get_all_error(item.error.response.data));
              toaster.error('', toastr_options);
            }
            return;
          });
        }
      });
  };

  show_toaster = (messages, drawer_type, item, status) => {
    const options = this.show_html_content_toaster(messages, drawer_type, item);
    if (status) {
      toaster.success('', options);
    } else {
      let toaster_error_options = {
        position: 'bottom-center',
        className: 'roles-rrt-info-error',
        timeOut: toastr_options.toastr_time_out,
        component: () => (
          <div>
            <span>
              <span>{messages}</span>
            </span>
          </div>
        )
      };
      toaster.error('', toaster_error_options);
    }
  };

  on_hide_toaster = (drawer_type) => {
    if (this.is_mounted) {
      this.setState({
        loading: false
      });
    }
    if (!this.state.is_from_toaster) {
      if (drawer_type === 'roles') {
        this.get_roles_list();
      } else {
        this.get_permission_sets_list();
      }
    }
  };

  // Show multiple messages
  show_html_content_toaster = (messages: any, drawer_type = '', item = 0) => {
    const toastr_option = {
      preventDuplicates: true,
      ...(item != 0
        ? {
            className: 'rrt-info-light',
            position: 'bottom-center',
            timeOut: 10000,
            onHideComplete: () => this.on_hide_toaster(drawer_type),
            component: () => (
              <div>
                <span>
                  <span>{messages}</span>
                  <span
                    className='href_link'
                    id='undo_id'
                    onClick={() => this.update_status(drawer_type, item, true)}
                    dangerouslySetInnerHTML={{ __html: ' Undo' }}
                  />
                </span>
              </div>
            )
          }
        : {
            position: 'top-center',
            timeOut: toastr_options.toastr_time_out,
            component: () => <div dangerouslySetInnerHTML={{ __html: messages }} />,
            preventDuplicates: false
          })
    };
    return toastr_option;
  };

  edit_permissions = () => {
    if (this.is_mounted) {
      this.setState({
        isEditPermissionsOpen: true
      });
    }
  };

  handleEditPermissionDrawerClose = () => {
    if (this.is_mounted) {
      this.setState({
        isEditPermissionsOpen: false
      });
    }
  };

  getEditPermissionsComponent = () => {
    return (
      <PermissionsEditComponent
        saveHandlerCallback={this.update_permission}
        permission_list={this.state.permission_list}
        save_disable={!this.state.is_add_edit_allowed}
      />
    );
  };

  // Data formatter
  format_data = (rows) => {
    let columns = roles_permission_set_data.extended_table_controls.roles;
    if (rows.e_role_list) {
      for (const key in rows) {
        if (key === 'e_role_list') {
          rows.e_role_list = rows.e_role_list.map((row_data) => {
            columns.filter((col_data) => {
              switch (col_data.type) {
                case 'user_type':
                  let user_type_val = row_data[col_data.field]
                    ? this.options_data.user_type.find((element) => element.value == row_data[col_data.field])
                    : '';
                  row_data[col_data.field] = user_type_val ? user_type_val.text : '';
                  break;
                case 'service_type':
                  let service_type_val = row_data[col_data.field]
                    ? this.options_data.service_type.find((element) => element.value == row_data[col_data.field])
                    : '';
                  row_data[col_data.field] = service_type_val ? service_type_val.text : '';
                  break;
                default:
                  row_data[col_data.field] = row_data[col_data.field];
              }
            });
            return row_data;
          });
        }
      }
    }

    return rows;
  };

  get_extended_data = async (id) => {
    let { extended_drawer_data } = this.state;
    if (this.is_mounted) {
      this.setState({
        loading: true
      });
    }

    await get_role_with_permission(this.token, {
      [roles_permission_set_data[this.state.drawer_type].extended_params]: id
    })
      .then((response) => {
        if (response.data && response.data.data) {
          if (this.is_mounted) {
            this.setState((prevState) => ({
              loading: false,
              extended_drawer_data: this.format_data(response.data.data),
              isEditExtendedOpen: true
            }));
          }
        }
      })
      .catch((error) => {
        if (this.is_mounted) {
          this.setState({
            extended_drawer_data,
            loading: false
          });
        }
        if (error.response) {
          error.response.map((item) => {
            if (item.error.response && item.error.response.data && item.error.response.data.messages) {
              log_error(item.error);
              const toastr_options = this.show_html_content_toaster(get_all_error(item.error.response.data));
              toaster.error('', toastr_options);
            }
            return;
          });
        }
      });
  };

  handleExtendedDrawerClose = (extendedClose = false, id = 0) => {
    if (this.state.extended_drawer_id === id) {
      return;
    }
    this.setState(
      {
        isEditDrawerOpen: !extendedClose,
        isEditExtendedOpen: false,
        extended_drawer_id: id
      },
      () => {
        this.get_extended_data(id);
      }
    );
  };

  getPermissionSetDrawerList = () => {
    let data = [];
    let form_data = {};
    let drawer_selected_item = {
      ...this.state.drawer_selected_item
    };

    if (this.state.drawer_type === 'roles') {
      data = this.state.permission_sets_list.map((item) => {
        return {
          ...item,
          value: item.id,
          is_disabled: this.state.action_type === 'view'
        };
      });
    } else {
      data = this.state.permission_list.map((item) => {
        return {
          ...item,
          value: item.id,
          is_disabled: this.state.action_type === 'view'
        };
      });
    }

    // Formate data for drawer form
    for (const key in drawer_selected_item) {
      switch (key) {
        case 'name':
        case 'description':
          form_data[key] = drawer_selected_item[key]
            ? this.state.action_type === 'copy'
              ? `${drawer_selected_item[key]} - Clone`
              : drawer_selected_item[key]
            : '';
          break;
        case 'required_user_level':
          form_data['user_type'] = drawer_selected_item[key] ? drawer_selected_item[key] : '';
          break;
        case 'permission_set_ids':
          form_data['selected_list'] = drawer_selected_item[key] ? drawer_selected_item[key] : [];
          break;
        case 'permission_ids':
          form_data['selected_list'] = drawer_selected_item[key] ? drawer_selected_item[key] : [];
          break;
        case 'id':
          form_data[key] = this.state.action_type === 'copy' ? '' : drawer_selected_item[key];
          break;
        case 'parent_id':
          form_data[key] =
            this.state.action_type === 'copy'
              ? drawer_selected_item[key]
                ? drawer_selected_item[key]
                : drawer_selected_item['id']
              : 0;
          break;
        default:
          form_data[key] = drawer_selected_item[key] ? drawer_selected_item[key] : '';
          break;
      }
    }

    return (
      <PermissionSetRolesViewAddEditComponent
        handleExtendedDrawerClose={this.handleExtendedDrawerClose}
        options={this.options_data}
        drawer_type={this.state.drawer_type}
        action_type={this.state.action_type}
        data={data}
        drawer_data={JSON.stringify(form_data)}
        form_data={form_data}
        is_add_edit_allowed={this.state.is_add_edit_allowed}
        saveHandlerCallback={
          this.state.drawer_type === 'roles' ? this.add_update_roles : this.add_update_permission_set
        }
      />
    );
  };

  getExtendedPermissionSetData = () => {
    return (
      <ExtendedPermissionSetRolesViewComponent
        extendedTabId='extended_ps_permission_id'
        data={this.state.extended_drawer_data}
        drawer_type={this.state.drawer_type}
      />
    );
  };

  handleDrawerClose = () => {
    if (this.is_mounted) {
      this.setState({
        isEditDrawerOpen: false,
        extended_drawer_id: 0,
        isEditExtendedOpen: false,
        drawer_type: '',
        action_type: ''
      });
    }
  };

  get_data_by_id = async (action_type, drawer_type, id) => {
    let { drawer_selected_item } = this.state;
    if (this.is_mounted) {
      this.setState({
        loading: true,
        drawer_type,
        action_type
      });
    }
    await roles_permission_set_data[drawer_type]
      .url(this.token, {
        [roles_permission_set_data[drawer_type].params]: id
      })
      .then((response) => {
        if (response.data && response.data.data && this.is_mounted) {
          this.setState({
            loading: false,
            drawer_selected_item: response.data.data,
            isEditDrawerOpen: true
          });
        }
      })
      .catch((error) => {
        if (this.is_mounted) {
          this.setState({
            drawer_selected_item,
            loading: false
          });
        }
        if (error.response) {
          error.response.map((item) => {
            if (item.error.response && item.error.response.data && item.error.response.data.messages) {
              log_error(item.error);
              const toastr_options = this.show_html_content_toaster(get_all_error(item.error.response.data));
              toaster.error('', toastr_options);
            }
            return;
          });
        }
      });
  };

  handleDrawerAction = (action_type, drawer_type, item) => {
    switch (action_type) {
      case 'delete':
        this.update_status(drawer_type, item);
        break;
      case 'new':
        if (this.is_mounted) {
          this.setState({
            drawer_selected_item: {},
            isEditDrawerOpen: true,
            drawer_type,
            action_type
          });
        }
        break;
      default:
        this.get_data_by_id(action_type, drawer_type, item);
        break;
    }
  };

  render() {
    return (
      <React.Fragment>
        <LoaderComponent loading={this.state.loading}>
          <div className='admin-wrapper roles_permissions'>
            <Grid className='headerGrid'>
              <Grid.Column computer={16}>
                <h3 className='ui header left aligned'>Roles & Permissions</h3>
              </Grid.Column>
            </Grid>
            <div id='admin-scrollable-area' className='wrapper roles_permissions_wrapper'>
              <Grid>
                <Grid.Column computer={8} tablet={16}>
                  <div id='roles'>
                    <Header content={'Role'} style={{ fontFamily: 'ProximaNova-Regular' }} />
                    <SearchComponent
                      onRef={ref => (this.roles_ref = ref)}
                      data={this.state.role_list}
                      advanced_control_name='roles'
                      lookup_id='roles_lookup'
                      add_label='Add Role'
                      search_box_id='multi-select-dropdown-search-roles'
                      controlId={'ROLES_SEARCH'}
                      search_type={'ROLES_SEARCH'}
                      is_add_edit_allowed={this.state.is_add_edit_allowed}
                      action_callback={this.handleDrawerAction}
                      options={this.options_data}
                      search_api={{
                        global_base: 'roles_and_permissions',
                        end_points: ['base', 'roles']
                      }}
                    />
                  </div>
                </Grid.Column>
                <Grid.Column computer={8} tablet={16}>
                  <div id='permissions'>
                    <Header
                      style={{ fontFamily: 'ProximaNova-Regular', display: 'flex', justifyContent: 'space-between' }}
                    >
                      Permission Set
                      {this.state.is_add_edit_allowed && (
                        <span
                          style={{ fontSize: 16, color: '#00a7e1', cursor: 'pointer' }}
                          tabIndex={0}
                          onClick={this.edit_permissions}
                          onKeyDown={handle_click_on_enter}
                          id='edit_permission'
                        >
                          <Icon name='pencil' style={{ margin: 0 }} /> Edit Permissions
                        </span>
                      )}
                    </Header>
                    <SearchComponent
                      onRef={ref => (this.permission_set_ref = ref)}
                      data={this.state.permission_sets_list}
                      advanced_control_name='permission_sets'
                      lookup_id='permission_set_lookup'
                      add_label='Add Permission Set'
                      controlId={'PERMISSION_SEARCH'}
                      search_box_id='multi-select-dropdown-search-permission-set'
                      search_type={'PERMISSION_SEARCH'}
                      is_add_edit_allowed={this.state.is_add_edit_allowed}
                      action_callback={this.handleDrawerAction}
                      options={this.options_data}
                      search_api={{
                        global_base: 'roles_and_permissions',
                        end_points: ['base', 'permission_sets_list']
                      }}
                    />
                  </div>
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </LoaderComponent>
        {this.state.isEditPermissionsOpen && (
          <DrawerComponent
            key={this.props.key_id}
            canOutsideClickClose={false}
            canEscapeKeyClose={false}
            isOpen={this.state.isEditPermissionsOpen}
            className='ext-bp-drawer provider-drawer billing-provider-drawer permissions-edit'
            handleDrawerClose={this.handleEditPermissionDrawerClose}
            position={Position.RIGHT}
            title=''
            innerContent={this.getEditPermissionsComponent()}
            size={Drawer.SIZE_STANDARD}
          />
        )}

        {this.state.isEditDrawerOpen && (
          <ExtendedDrawerComponent
            isOpen={this.state.isEditDrawerOpen}
            isExtendedOpen={this.state.isEditExtendedOpen}
            isCloseButtonShown={this.state.isCloseButtonShown}
            innerContent={this.getPermissionSetDrawerList()}
            handleDrawerClose={this.handleDrawerClose}
            innerExtendedContent={this.getExtendedPermissionSetData()}
            portalClassName={'roles-permission-drawer'}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_login_details: state.user_login_details,
    shared_details: state.shared_details
  };
};

export default connect(mapStateToProps)(RolesAndPermissionsComponent);
