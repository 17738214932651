import { headerTemplate } from "../../reports/report_constants";
import { mm_dd_yyyy_h_mm_ss_tt_comparator, text_comparator, number_comparator, currency_comparator, mm_dd_yyyy_comparator } from "../../shared/component/grid/comparators";
import { readonly_checkbox_cell_renderer } from "../../shared/component/grid/gridview_cell_renderer";
import { areStringsEqualCaseInsensitive } from "../../shared/utility";
import { handleCellCheckboxDeleteAccountNote } from "../constants/utility";
import { headerStatusTemplate, statusStyle } from "../constants/constants";
import { get_header_checkbox_template } from "../../reports/report_constants";
import { get_header_template_with_navigation, header_template_with_navigation } from '../../shared/shared_constants';


export const manage_payers_configuration = (props: any) => {
    return {
        title: "FTP Manager",
        report_grid_id: "manage_payers_grid",
        default_page_size: 20,
        manage_payers_column_defs: [
            {
                headerName: "Connection Name",
                headerComponentParams: headerTemplate,
                field: "payerName",
                filter: "textFilter",
                type: "string",
                unSortIcon: true,
                minWidth: 175,
                cellRenderer: function (params) {
                    let eDiv = document.createElement('div');
                    eDiv.innerHTML = `<span class='href_link'>${params.value}</span>`;
                    let eSpan = eDiv.querySelector("span");
                    eSpan.addEventListener("click", (event: any) => {
                        props.history.push(`/admin/dashboard/ftp_manager/connection_detail/${params.data.autoFtpPayerId}`);
                    });
                    return eDiv;
                },
                comparator: text_comparator
            },
            {
                headerName: "FTP Host",
                headerComponentParams: headerTemplate,
                field: "hostName",
                filter: "textFilter",
                type: "string",
                unSortIcon: true,
                minWidth: 175,
                comparator: text_comparator
            },
            {
                headerName: "User Name",
                headerComponentParams: headerTemplate,
                field: "userName",
                filter: "textFilter",
                type: "string",
                unSortIcon: true,
                minWidth: 175,
                comparator: text_comparator
            },
            {
                headerName: "Status",
                headerComponentParams: headerStatusTemplate,
                field: "isActive",
                filter: "checklistFilter",
                type: "boolean",
                minWidth: 175,
                cellStyle: statusStyle,
                unSortIcon: true
            },
        ],
    };
};

export const delete_account_notes_constants = (component: any) => {
    return {
        title: 'Delete Account Notes',
        report_grid_id: "delete_account_note_grid",
        session_storage_key: 'delete_account_notes_criteria',
        default_page_size: 20,
        // DB column is int type, so max value is 2147483647
        max_number_value: 2147483647,
        delete_account_note_column_defs: [
            {
                headerName: "",
                headerComponentParams: (params: any) => get_header_checkbox_template("checkAllId"),
                field: "is_checked",
                minWidth: 100,
                width: 100,
                type: 'string',
                suppressFilter: true,
                suppressSorting: true,
                suppressSizeToFit: true,
                suppressMovable: true,
                cellRenderer: (params: any) => handleCellCheckboxDeleteAccountNote(params, 'checkboxAccountNote', 'is_checked', component)
            },
            {
                headerName: "Note ID",
                headerComponentParams: headerTemplate,
                field: 'noteId',
                filter: 'numberFilter',
                minWidth: 120,
                width: 120,
                maxWidth: 250,
                type: 'string',
                unSortIcon: true,
                comparator: number_comparator
            },
            {
                headerName: "Note",
                headerComponentParams: headerTemplate,
                field: "note",
                filter: "textFilter",
                type: "string",
                unSortIcon: true,
                minWidth: 200,
                width: 800,
                maxWidth: 1000,
                comparator: text_comparator,
                tooltipField: 'note'
            },
            {
                headerName: "Status",
                headerComponentParams: headerStatusTemplate,
                field: "isActive",
                filter: "checklistFilter",
                type: "boolean",
                minWidth: 180,
                width: 180,
                cellStyle: statusStyle,
                unSortIcon: true
            }
        ],
        confirmInactivateMessage: 'Are you sure you want to inactivate record(s) selected?',
        validationMessage: 'Please select at least one record to inactivate',
        inactivateSuccessMessage: 'Record(s) inactivated successfully',
    }
}

export const connectionDetailMessages = {
    connectionSaved:'Connection saved successfully',
    connectionUpdated:'Connection updated successfully',
    requiredFields:'Please fill all required fields',
}

export const ftp_scheduler_configuration = (props: any) => {
    return {
        title: "FTP Scheduler",
        report_grid_id: "ftp_scheduler_grid",
        default_page_size: 20,
        columns_definition: [
            {
                headerName: "Connection Name",
                headerComponentParams: headerTemplate,
                field: "payerName",
                filter: "textFilter",
                type: "string",
                unSortIcon: true,
                cellRenderer: function (params) {
                    let eDiv = document.createElement('div');
                    eDiv.innerHTML = `<span class='href_link'>${params.value}</span>`;
                    let eSpan = eDiv.querySelector("span");
                    eSpan.addEventListener("click", (event: any) => {
                        props.history.push(`/admin/dashboard/ftp_scheduler/scheduled_run_details/${params.data.autoFtpSchedulerId}`);
                    });
                    return eDiv;
                },
                comparator: text_comparator
            },
            {
                headerName: "Type",
                headerComponentParams: headerTemplate,
                field: "scheduleProcess",
                filter: "textFilter",
                type: "string",
                unSortIcon: true,
                comparator: text_comparator,
                cellRenderer: (params) => {
                    let eDiv = document.createElement("div"),
                        isUpload = areStringsEqualCaseInsensitive(params.value, "Upload"),
                        className = isUpload ? "upload_icon" : "download_icon";

                    eDiv.className = className;
                    eDiv.style.marginTop = "5px";

                    if (isUpload) {
                        eDiv.style.marginLeft = "30px";
                    }

                    return eDiv;
                }
            },
            {
                headerName: "Schedule Time",
                headerComponentParams: headerTemplate,
                field: "scheduleTime",
                filter: "textFilter",
                type: "string",
                unSortIcon: true,
                comparator: text_comparator
            },
            {
                headerName: "Override",
                headerComponentParams: headerTemplate,
                field: "overrideSchedule",
                filter: "checklistFilter",
                type: "boolean",
                unSortIcon: true,
                comparator: text_comparator,
                cellStyle: { textAlign: 'center' },
                cellRenderer: readonly_checkbox_cell_renderer
            },
            {
                headerName: "Last Executed",
                headerComponentParams: headerTemplate,
                field: "lastRun",
                type: "datetime",
                unSortIcon: true,
                filter: 'dateTimeFilter',
                comparator: mm_dd_yyyy_h_mm_ss_tt_comparator,
            },
            {
                headerName: "Last Run",
                headerComponentParams: headerTemplate,
                field: "lastRunStatus",
                filter: "checklistFilter",
                type: "boolean",
                unSortIcon: true,
                comparator: text_comparator,
                cellRenderer: (params) => {
                    let eDiv = document.createElement('div'),
                        foregroundColor = params.value == "Success" ? "black" : "red";

                    eDiv.innerHTML = `<span style="color: ${foregroundColor}">${params.value}</span>`;
                    return eDiv;
                }
            },
            {
                headerName: "Current Run",
                headerComponentParams: headerTemplate,
                field: "isRunning",
                filter: "checklistFilter",
                type: "boolean",
                unSortIcon: true,
                comparator: text_comparator
            },
            {
                headerName: "Status",
                headerComponentParams: headerStatusTemplate,
                field: "isDisabled",
                filter: "checklistFilter",
                type: "boolean",
                cellStyle: statusStyle,
                unSortIcon: true
            },
        ],
    };
};

export const scheduledRunDetailConfiguration = {
    title:'FTP Scheduler',
    cardTitle: 'Scheduled Run Details',
    runTypeList: [{ value: 'U', text: 'Upload' }, { value: 'D', text: 'Download' }],
    timemask:[/[0-2]/,/\d/,':',/[0-5]/,/\d/],
    timemask20h:[/[0-2]/,/[0-3]/,':',/[0-5]/,/\d/],
    scheduledRunSaved:'Scheduled Run saved successfully',
    scheduledRunUpdated: 'Scheduled Run updated successfully',
    scheduledRunNotFound: 'Scheduled Run not found',
    requiredFields: 'Please fill all required fields',
    timeFormatValidation: new RegExp(/^\d{2}:\d{2}$/)
}

export const remove_move_835s_constants = (component: any) => {
    return {
        id: 'remove_move_835s_id',
        title: 'Remove/Move 835s',
        session_storage_key: 'remove_move_835s_criteria',
        report_grid_id: "remove_move_835s_grid",
        eob_keys_column_defs: [
            {
                headerName: "",
                headerComponentParams: header_template_with_navigation("checkAllEobKeys"),
                field: "checkbox",
                minWidth: 80,
                width: 80,
                headerCheckboxSelection: true,
                checkboxSelection: true,
                suppressFilter: true,
                suppressSorting: true,
                suppressSizeToFit: true,
                resizable: false,
                suppressMovable: true
            },
            {
                headerName: "835 Key",
                headerComponentParams: headerTemplate,
                field: "eob_key",
                type: "string",
                filter: "textFilter",
                comparator: text_comparator,
                suppressSizeToFit: true,
                unSortIcon: true
            },
            {
                headerName: "Company",
                headerComponentParams: headerTemplate,
                field: "company_code",
                type: "string",
                filter: "textFilter",
                comparator: text_comparator,
                suppressSizeToFit: true,
                unSortIcon: true
            },
            {
                headerName: "Check/EFT Number",
                headerComponentParams: headerTemplate,
                field: "check_eft_num",
                type: "string",
                filter: "textFilter",
                comparator: text_comparator,
                suppressSizeToFit: true,
                unSortIcon: true
            },
            {
                headerName: "Amount",
                headerComponentParams: headerTemplate,
                field: "check_amt",
                type: "currency",
                filter: "currencyFilter",
                comparator: currency_comparator,
                unSortIcon: true,
                cellStyle: { textAlign: "right" }
            },
            {
                headerName: "Payer",
                headerComponentParams: headerTemplate,
                field: "payer",
                type: "string",
                filter: "textFilter",
                comparator: text_comparator,
                suppressSizeToFit: true,
                unSortIcon: true
            },
            {
                headerName: "Inbound ID",
                headerComponentParams: headerTemplate,
                field: "payer_id",
                type: "string",
                filter: "textFilter",
                comparator: text_comparator,
                suppressSizeToFit: true,
                unSortIcon: true
            },
            {
                headerName: "835 Date",
                headerComponentParams: headerTemplate,
                field: "eob_date",
                type: "date",
                filter: "dateFilter",
                comparator: mm_dd_yyyy_comparator,
                suppressSizeToFit: true,
                unSortIcon: true
            },
            {
                headerName: "Release Date",
                headerComponentParams: headerTemplate,
                field: "release_date",
                type: "date",
                filter: "dateFilter",
                comparator: mm_dd_yyyy_comparator,
                suppressSizeToFit: true,
                unSortIcon: true
            },
            {
                headerName: "Processed Date",
                headerComponentParams: headerTemplate,
                field: "processed_date",
                type: "date",
                filter: "dateFilter",
                comparator: mm_dd_yyyy_comparator,
                suppressSizeToFit: true,
                unSortIcon: true
            }
        ],
        action_options: [
            {
                text: "Move",
                value: "M"
            },
            {
                text: "Remove",
                value: "R"
            }
        ]
    }
}

export const remove_move_835s_messages = {
    required_field: 'Please fill all the mandatory fields or check highlighted field values.',
    enter_valid_numbers_separated_by_commas: `Please enter number(s) only. Use ',' for multiple. There should be no trailing/leading space.`,
    at_least_one_835_is_invalid: `At least one 835 entered is invalid.`,
    succesfully_moved_835s: "Successfully Moved 835s",
    succesfully_removed_835s: "Successfully Removed 835s"
}
