import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Classes, Drawer } from '@blueprintjs/core';

export interface IProps {
  canOutsideClickClose: boolean;
  isOpen: boolean;
  hasBackdrop: boolean;
  className: string;
  handleDrawerClose: Function;
  title: string;
  extendedTitle: string;
  isExtendedOpen: boolean;
  innerContent: string;
  innerExtendedContent: string;
  portalClassName: string;
  position: string;
  size: string;
  isCloseButtonShown: boolean;
}
export class ExtendedDrawerComponent extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      autoFocus: true,
      canEscapeKeyClose: this.props.canEscapeKeyClose,
      canOutsideClickClose: this.props.canOutsideClickClose || false,
      hasBackdrop: true,
      enforceFocus: true,
      position: this.props.position,
      size: this.props.size,
      isExtendedOpen: this.props.isExtendedOpen
    };
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.isExtendedOpen !== this.props.isExtendedOpen) {
      this.setState({
        isExtendedOpen: this.props.isExtendedOpen
      });
    }
  }

  handle_drawer_close = () => {
    this.props.handleDrawerClose && this.props.handleDrawerClose();
  };

  extendedDrawer = () => {
    const domNode = document.getElementById('add-edit-drawer-component');
    const portalClassName = document.getElementsByClassName('bp3-portal');
    if (this.state.isExtendedOpen) {
      portalClassName && portalClassName[0] && portalClassName[0].classList.add('extended');
    } else {
      portalClassName && portalClassName[0] && portalClassName[0].classList.remove('extended');
    }
    if (this.state.isExtendedOpen && domNode) {
      return ReactDOM.createPortal(this.props.innerExtendedContent, domNode);
    }
    return null;
  };

  render() {
    return (
      <React.Fragment>
        <Drawer
          key={'initial-drawer'}
          isOpen={this.props.isOpen || false}
          className={`ext-bp-drawer initial-drawer provider-drawer billing-provider-drawer ${this.props.className}`}
          onClose={this.handle_drawer_close}
          title={this.props.title || ''}
          portalClassName={this.props.portalClassName || ''}
          isCloseButtonShown={this.props.isCloseButtonShown}
          {...this.state}
        >
          <div className={Classes.DRAWER_BODY}>
            <div className={Classes.DIALOG_BODY}>{this.props.innerContent}</div>
          </div>
        </Drawer>
        {this.extendedDrawer()}
      </React.Fragment>
    );
  }
}

export default ExtendedDrawerComponent;
