import * as LDClient from 'launchdarkly-js-client-sdk';
const _ = require('lodash');
import { launch_darkley } from '../../global_constants';
import * as local_storage from '../local_storage_utility';
import { set_action } from './action/launch_darkly_action';


export default class LaunchDarkley {

    /**
     * Initialize Launch Darkley
     */
   static init = (store) => {

       if (!launch_darkley.use_ld_feature) {
           return;
       }

       try {
           let context = LaunchDarkley.prepare_user_context();
           let ld_options = { logger: LDClient.basicLogger({ level: 'error' }) };
           window['ldClient'] = LDClient.initialize(launch_darkley.key, context, ld_options);
           
           window['ldClient'].on('ready', () => {

               // #region Sample object - documentation
                  //all_flags = { "QA-test-Demo-02": false, "argos-b31470-exported-charges": true, "argos-b33306-remove-access-to-r4-with-overrides": true }
                  //camel_case_all_flags = { "qaTestDemo02":false,"argosB31470ExportedCharges":true,"argosB33306RemoveAccessToR4WithOverrides":true }
               // #endregion Sample object - documentation

               var all_flags = window['ldClient'].allFlags();
               if (all_flags == null) return; // Ideal situation this will never happen
               var camel_case_all_flags = LaunchDarkley.convert_to_camel_case(all_flags);
               store.dispatch(set_action(camel_case_all_flags));
               LaunchDarkley.subscribeToChanges(all_flags, store);
           });
       }
       catch (err) {
           console.log("Error Reported with LaunchDarkly Client Initialization.");
           console.log(err);
       }
    }

    /**
     * Update user in launch darkley
     * */
    static update = (callback: any = undefined) => {
        try {
            let context = LaunchDarkley.prepare_user_context();
            window['ldClient'].identify(context, null, userdata => {
                console.log('updated user in launch darkly.');
                if (callback) {
                    callback();
                }
               
            });
            window['ldClient'].flush();
        }
        catch (e) {
            if (callback) {
                callback();
            }
        }
    }

    /**
    * User is logged in and page is reloaded some how
    * */
    static isValidUser = (auth_data) => {
        return auth_data != undefined &&
            auth_data != null &&
            auth_data.user_id != null &&
            auth_data.company_id != null;
    }


    /**
     * Prepare LaunchDarkly context
     * https://launchdarkly.github.io/js-client-sdk/interfaces/LDUser.html
     */
    static prepare_user_context = () =>
    {
        if (!launch_darkley.use_ld_feature) {
            return;
        }

        let context = {};
        let auth_data = local_storage.get('auth_data');

        if (LaunchDarkley.isValidUser(auth_data)) {
            context = {
                kind: 'user',
                key: auth_data.user_id,
                firstName: auth_data.first_name,
                lastName: auth_data.last_name,
                anonymous: false,
                company_id: auth_data.company_id,
                user_name: auth_data.user_name,
                user_type: auth_data.user_type_r3_id,
                _meta: {
                    privateAttributes: ['company_id', 'user_name', 'user_type']
                }
            };
        } else { // anonomous user
            context = {
                key: '-1',
                anonymous: true,
                company_id: -1,
                user_type: -1,
                _meta: {
                    privateAttributes: ['company_id', 'user_type']
                }
            };
        }
        return context;
    }

    static convert_to_camel_case = (all_flags) =>
    {
        const transformed_all_flags = {};
        for (const flag in all_flags)
        {
            const camel_cased_key = _.camelCase(flag);
            transformed_all_flags[camel_cased_key] = window['ldClient'].variation(flag, all_flags[flag]);
        }
        return transformed_all_flags;
    }

    //https://docs.launchdarkly.com/sdk/features/flag-changes#javascript
    static subscribeToChanges = (all_flags, store) => {
        for (const flag in all_flags) {
            const camelCaseFlagKey = _.camelCase(flag);
            window['ldClient'].on(`change:${flag}`, current => {
                const newFlagValue = {};
                newFlagValue[camelCaseFlagKey] = current;
                store.dispatch(set_action(newFlagValue));
            });
        }
    };
} 

