import * as React from "react";
import { Grid } from "semantic-ui-react";
import * as session_storage from '../../shared/session_storage_utility';
export default class NoPatientSelectedPayment extends React.Component<any, any> {
    componentWillUnmount = () => {
        session_storage.set('no_patient_selected_for', null);
    }

    render() {
        return (<div >
            <Grid>
                <Grid.Column mobile={16} tablet={16} computer={16} className="no-patient-found">
                    <i aria-hidden="true" className="warning sign icon"></i>
                    <span> No Patient Selected </span>
                </Grid.Column>
            </Grid>
        </div>);
    }
}
