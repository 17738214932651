import * as global_constants from '../../global_constants';
import { RequestConfig } from '../../shared/network/config';
import { Request } from '../../shared/network/request';
import * as reactivate_constants from './../../charge/constant/reactivate_constants';

export const search = (token, params) => {
    const url = `${global_constants.base_end_points}${reactivate_constants.end_points.search}`;
    
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': params
    })

    return request.make(requestConfig)
};

export const initial_load = (token) => {
    const url = `${global_constants.base_end_points}${reactivate_constants.end_points.initial_load}`;
    
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(requestConfig)
};

export const reactivate = (token, params) => {
    const url = `${global_constants.base_end_points}${reactivate_constants.end_points.reactivate}`;
    
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': params
    })

    return request.make(requestConfig)
};
