import { compressToUTF16, decompressFromUTF16 } from './lz-string';
import { createTransform } from 'redux-persist';
const NODE_ENV = typeof process !== 'undefined' ? process.env.NODE_ENV : 'production';

const createTransformCompress = (config) => {
    return createTransform(
        (state) => compressToUTF16(JSON.stringify(state)),
        (state) => {
            if (typeof state !== 'string') {
                if (NODE_ENV !== 'production') {
                    console.error('redux-persist-transform-compressToUTF16: expected outbound state to be a string');
                }
                return state;
            }

            try {
                return JSON.parse(decompressFromUTF16(state));
            } catch (err) {
                if (NODE_ENV !== 'production') {
                    console.error('redux-persist-transform-compressToUTF16: error while decompressing state', err);
                }
                return null;
            }
        },
        config
    );
};
export default createTransformCompress;