import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastr as toaster } from 'react-redux-toastr';
import { Button, Dimmer, Form, Label, Loader, Popup, Segment } from "semantic-ui-react";
import { release835sDetail_configuration, payment_adjustments } from "../payment_constants";
import * as session_storage from "../../shared/session_storage_utility";
import { PopUpLinkRenderer } from '../../shared/component/popup_link_renderer_component';
import { DataTableBody, DataTableCell, DataTableHead, DataTableHeadCell, DataTableRow, DataTableFooter, DataTable, ToolTip, ToggleSwitch } from "@webpt/bonfire";
import { release835DetailCard, get_adjustment_reason, get_adjustment_qualifier, get_adjustment_codes, saveEditable835, updateEOBValidationErrors } from "../action/payment_action";
import * as moment from 'moment';
import * as _ from 'lodash';
import { ResizableDraggableModal } from "../../shared/resizable-draggable-modal";
import AccountNotesViewAddComponent from "../../shared/component/account_notes_view_add_component";
import { token_verify_post } from '../../sso/action/sso_action';
import DatePicker from "../../shared/component/date_picker_component";
import PaymentStickyNotes from "./payments_sticky_notes_component";
import { get_patient_account_notes_action } from "../../patient/action/patient_action";
import * as patient_constants from '../../patient/patient_constants';
import { IEOB835ReleaseDetailCardClaimDto, IEOB835ReleaseDetailCardErrorInfoDto, icn_data_session_key_name_list } from '../payment_constants';
import Release835sPatientCardDetail from './release_835s_patient_card_detail_component';
import { log_error } from "../../shared/action/shared_action";
import PromptNavigation from '../../shared/component/prompt_navigation_component';
import { PatientTaskIconComponent } from "../../patient/component/patient_task_icon_component";
import { user_tasks_sso_authentication } from "../../user/action/user_action";
import {get as getLocalStorage} from '../../shared/local_storage_utility';
import { get_lauch_darkley_key_value } from "../../shared/utility";
import { magnus_plat_5681_isolate_auth0 } from "../../feature_flip_constant";

export class Release835sDetailComponent extends React.Component<any, any> {

    _parameters: any = {};

    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            page: 1,
            isSsoUser: false,
            activeIndex: [],
            adjustment_qualifier: [],
            adjustment_reason: {},
            totalChargesPerPage: 0,
            totalChargesExpandedPerPage: 0,
            totalPatients835file: 0,
            totalPatientsAvailable: 0,
            isDisplayedErrorsOnly: true,
            _edited_eob_line_item: [],
            userId: (getLocalStorage("auth_data") && getLocalStorage("auth_data").user_id ? getLocalStorage("auth_data").user_id : null)
        };
        this.clearPatientCardSessionContent();
    }
    
    searchCriteria835Release = {};
    totalPatientsCards = [];
    isSubmitted = false;
    errorInfoItems: Array<IEOB835ReleaseDetailCardErrorInfoDto> = [{
        patientAccount: '',
        caseId: 0,
        dateOfService: new Date(),
        cptCode: '',
        errorMessage: ''
    }];

    loadData = async () => {
        let parameters = JSON.parse(session_storage.get("selectedCheckNumber"));
        let { check_eft_num: checkNumber, payer_id: inboundId, payer: payer, check_amt: amount, eob_date: date835, load_date: loadDate, clinic_name: location, eob_key: eob_key, link_check_eft_num: link_check_eft_num } = parameters.selectedRow;
        let companyId = parameters.companyId;
        let data: any = {
            checkNumber,
            inboundId,
            payer,
            amount,
            date835,
            loadDate,
            location,
        }
        try {
            let { data: { data: patients } } = await release835DetailCard(`eobKey=${eob_key}&checkNumber=${link_check_eft_num}&companyId=${companyId}`, this.props.token)           
            this.countTotalPatients835file(patients)
            data = this.getPatientCardsToDisplay(data);
            
        } catch (error) {
            toaster.error('', release835sDetail_configuration.errors.patientsNotFound);
        }
        
        this.setState({
            data,
            loading: false
        }, this.countTotalCharges);
    }

    countTotalPatients835file = (patients) =>{
        this.totalPatientsCards  = JSON.parse(JSON.stringify(patients))
        let totalPatients835file = this.totalPatientsCards.filter((item,index,array)=> array.findIndex(patient=>patient.patientId === item.patientId) == index).length;
        this.setState({totalPatients835file});
    }

    getPatientCardsToDisplay = (data) => {
        let patientsAvailable = this.totalPatientsCards.filter(patient => {
            if (
                (patient.errorInfo.length > 0 && patient.errorInfo.filter(error => error.errorMessage != release835sDetail_configuration.errors.hasStickyNotes).length > 0) ||
                (patient.errorInfo.length > 0 && patient.errorInfo.filter(error => error.errorMessage == release835sDetail_configuration.errors.hasStickyNotes).length == 1 && !patient.hasExcludedErrors)
            ) {
                return patient;
            }
            else if (patient.errorInfo.length == 0 && !patient.hasExcludedErrors) { return patient };
        });

        let patients = this.state.isDisplayedErrorsOnly ? patientsAvailable.filter(item => {
            if (item.errorInfo.length > 0) {
                return item;
            }
        }) : patientsAvailable;

        patients = patients.filter(item => item.totalCharges > 0);
        let totalPatientsAvailable = patients.filter((item,index,array)=> array.findIndex(patient=>patient.patientId === item.patientId) == index).length;
        this.setState({totalPatientsAvailable})
        
        patients = _.chunk(patients, release835sDetail_configuration.maxCards);
        if (this.state.page > patients.length && patients.length > 0){
            this.setState({page : patients.length});
        }
        data = { ...data, patients}
        if (this.state.totalPatients835file === 0) {
            toaster.error('', release835sDetail_configuration.errors.patientsNotFound);
        }
        return data
    }

    async componentDidMount() {
        try {
	        session_storage.get("searchCriteria835Release") ? this.searchCriteria835Release = JSON.parse(session_storage.get("searchCriteria835Release")) : this.searchCriteria835Release = null;
	        session_storage.get("selectedCheckNumber") ? this.reloadValidationErrors () : this.props.history.push(release835sDetail_configuration.parentUrl);
        } catch (error) {
            this.searchCriteria835Release = null;
            toaster.error('', release835sDetail_configuration.errors.searchError);
        }
        if (sessionStorage['sso_user_login']) {
            try {
                if (this.props.magnusPlat5681IsolateAuth0) {
                    let { data: { data: { ssoRevflowAuthorize: { accessCode = null } } } } = await user_tasks_sso_authentication(this.state.userId)
                    if (accessCode) {
                        this.setState({
                            isSsoUser: true
                        });
                    }
                }
                else {
                    let { data: { content = null } } = await token_verify_post()
                    let { data: { data: { ssoRevflowAuthorize: { accessCode = null } } } } = await user_tasks_sso_authentication(this.state.userId)
                    if (content && accessCode) {
                        this.setState({
                            isSsoUser: true
                        });
                    }
                }
            } catch (error) {
                console.error(error)
            }
        }
        session_storage.set("release_835_search_criteria", this.searchCriteria835Release);
        window.addEventListener('beforeunload', this.onReloadPage);

        if (this.props.payment_adjustment != undefined) {
            this.props.update_payment_adjustment_qualifier_info(this.props.token);
            this.props.update_payment_adjustment_reason_info(this.props.token);
            this.props.update_payment_adjustment_code_info(this.props.token);
        }
        try {
            let params = session_storage.get("selectedCheckNumber");
            if (params) {
                this._parameters = JSON.parse(params);
                let clinic = this._parameters.selectedRow.clinic_name.split(' ');
                this._parameters.clinic_code = clinic[0];
            }
        } catch (error) {
            toaster.error('', release835sDetail_configuration.errors.checkNumberError);
        }
    }

    componentWillUnmount(): void {
        // from here 
        // removing would retain data for browser back button
        session_storage.remove("selectedCheckNumber");
        session_storage.remove("searchCriteria835Release");
        release835sDetail_configuration.parentUrl !== this.props.history.location.pathname && session_storage.remove('release_835_search_criteria');
        // to here
        window.removeEventListener('beforeunload', this.onReloadPage);
        this.props.clear_payment_adjustment_qualifier_redux();
        this.props.clear_payment_adjustment_reason_redux();
        this.props.clear_payment_adjustment_code_redux();
        this.clearPatientCardSessionContent();
    }

    clearPatientCardSessionContent = () => {
        // #region clear patient  ICN card details stored
        let patient_card_icn_data_keys = session_storage.get(icn_data_session_key_name_list);
        if (patient_card_icn_data_keys != undefined && patient_card_icn_data_keys != null) {
            patient_card_icn_data_keys.map(key => {
                session_storage.remove(key);
            });
            session_storage.remove(icn_data_session_key_name_list);
        }
        // #endregion clear patient card details stored
    }

    handleClick = (index: any): void => {
        let { activeIndex } = this.state
        activeIndex[index] = !!!activeIndex[index]
        this.loadStickyNotes(index);
        this.setState({ activeIndex })
        this.countTotalExpandedChargesPerCard();
    }

    loadStickyNotes = async (index: any) => {
        let selectedPatient = this.state.data.patients[this.state.page - 1][index];
        if (selectedPatient.hasStickyNotes === undefined) {
            this.setState({
                loading: true
            });
            try {
                let response = await get_patient_account_notes_action(selectedPatient.patientId, this.props.token, patient_constants.sticky_code.Payment);

                let totalStickyNotes = response.data.length;
                this.state.data.patients.forEach(patientRow => {
                    patientRow.forEach((patient, index) => {
                        if (patient.patientId == selectedPatient.patientId) {
                            if (totalStickyNotes != 0) {
                                patientRow[index].hasStickyNotes = true;
                                patientRow[index].stickyNotes = { detail: response.data };
                            } else {
                                patientRow[index].hasStickyNotes = false;
                            }
                        }
                    });
                });
                let data = { ...this.state.data }
                this.setState({
                    data,
                    loading: false
                });
            } catch (error) {
                toaster.error('', release835sDetail_configuration.errors.patientsNotFound);
            }
        }
    }

    renderAccountNotes = () => {
        let { title, patientId, patient_name, gpms_code, company_name } = this.state.accountNotesModal;
        return (
            <ResizableDraggableModal
                showModal={this.state.accountNotesModal}
                dimmer={true}
                boundWith={"window"}
                dragHandleClassName={"daragble-header"}
                autoResizeOnload={false}
                popUpPosition={{
                    width: 699,
                    height: 542,
                    minHeight: 400,
                    minWidth: 515,
                    maxWidth: 1600,
                    maxHeight: 900
                }}
                enable={{
                    bottomRight: true
                }}
                className={'modal_container'}
                disableDragging={true}
            >
                <ResizableDraggableModal.Header
                    onRequestClose={() => this.setState({ accountNotesModal: undefined })}
                >{title}</ResizableDraggableModal.Header>
                <ResizableDraggableModal.Content className={"daragble-content scrolling"}>
                    <ResizableDraggableModal.Description className={"daragble-description"}>
                        <AccountNotesViewAddComponent
                            is_patient_header={false}
                            is_view={false}
                            close_icon={() => this.setState({ accountNotesModal: undefined })}
                            patient_id={patientId}
                            patient_name={patient_name}
                            gpms_code={gpms_code}
                            company_name={company_name}
                            data_model_key={"dialogAccountNotes"}
                            show_account_notes={!!this.state.accountNotesModal}
                            showModal={!!this.state.accountNotesModal}
                            onCancel={() => this.setState({ accountNotesModal: undefined })}
                        />
                    </ResizableDraggableModal.Description>
                </ResizableDraggableModal.Content>
            </ResizableDraggableModal>
        );
    }

    renderSaveAndReleaseModal = () => {
        let { title } = this.state.saveAndReleaseModal;
        return (
            <ResizableDraggableModal
                showModal={this.state.saveAndReleaseModal}
                dimmer={true}
                boundWith={"window"}
                dragHandleClassName={"daragble-header"}
                autoResizeOnload={false}
                popUpPosition={{
                    width: 500,
                    height: 405,
                    minHeight: 405,
                    minWidth: 500,
                    maxWidth: 500,
                    maxHeight: 405
                }}
                enable={{
                    bottomRight: true
                }}
                className={'modal_container'}
                disableDragging={true}
            >
                <ResizableDraggableModal.Header
                    onRequestClose={() => this.setState({ saveAndReleaseModal: undefined })}
                >{title}</ResizableDraggableModal.Header>
                <ResizableDraggableModal.Content className={"daragble-content scrolling"}>
                    <ResizableDraggableModal.Description className={"daragble-description"}>
                        <Form autoComplete="off" onSubmit={() => { this.saveAndRealease({ date: this.state.saveAndReleaseModal.date, batch: this.state.saveAndReleaseModal.batch, claimEobkey: this._parameters.selectedRow.eob_key }) }}>
                            <Form.Field className={this.state.saveAndReleaseModal.dateHasTouched && !this.state.saveAndReleaseModal.date ? "requiredWithBgColor" : ""} required>
                                <label><b>Deposit Date</b></label>
                                <DatePicker
                                    id={"depositDate"}
                                    name={"depositDate"}
                                    maxDate={moment().format('MM/DD/YYYY')}
                                    date_update={(dateObj: any) => {
                                        this.setState((prevState: any) => ({ saveAndReleaseModal: { ...prevState.saveAndReleaseModal, date: dateObj ? moment(dateObj) : null, dateHasTouched: true } }))
                                    }}
                                    is_required={this.state.saveAndReleaseModal.dateHasTouched && !this.state.saveAndReleaseModal.date ? true : false}
                                    date={this.state.saveAndReleaseModal.date}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label><b>Batch Number</b></label>
                                <Form.Input
                                    id="batchNumber"
                                    name="batchNumber"
                                    value={this.state.saveAndReleaseModal.batch || ''}
                                    onChange={(event: any) => {
                                        let batch: any = event.target.value
                                        this.setState((prevState: any) => ({ saveAndReleaseModal: { ...prevState.saveAndReleaseModal, batch } }))
                                    }}
                                />
                            </Form.Field>
                            <div style={{ height: '117px' }} />
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
                                <Button
                                    type="button"
                                    id="cancelButtonModal"
                                    basic
                                    onClick={() => this.setState({ saveAndReleaseModal: undefined })}
                                    content={"Cancel"}
                                />
                                <Button
                                    id="saveButtonModal"
                                    className="primary"
                                    type="submit"
                                    disabled={!!!this.state.saveAndReleaseModal.date}
                                    content={"Save & Release"}
                                />
                            </div>
                        </Form>
                    </ResizableDraggableModal.Description>
                </ResizableDraggableModal.Content>
            </ResizableDraggableModal>
        );
    }

    renderErrorModal = () => {
        let { title, checkNumber} = this.state.errorModal;
        return (
            <ResizableDraggableModal
                showModal={this.state.errorModal}
                dimmer={true}
                boundWith={"window"}
                dragHandleClassName={"daragble-header"}
                autoResizeOnload={false}
                popUpPosition={{
                    width: 500,
                    height: 205,
                    minHeight: 205,
                    minWidth: 500,
                    maxWidth: 250,
                    maxHeight: 205
                }}
                enable={{
                    bottomRight: true
                }}
                className={'modal_container'}
                disableDragging={true}
            >
                <ResizableDraggableModal.Header
                    onRequestClose={() => this.setState({ errorModal: undefined })}
                >{title}</ResizableDraggableModal.Header>
                <ResizableDraggableModal.Content className={"daragble-content scrolling"}>
                    <ResizableDraggableModal.Description className={"daragble-description"}>
                        <Form.Field>
                            <label>Oops! Check number {checkNumber} has already been released.</label>
                            <div style={{ height: '30px' }} />
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                <Button
                                    variant="primary"
                                    type="button"
                                    id="closeButtonModal"
                                    className="primary"
                                    onClick={() => this.closeErrorModal()}
                                    content={"OK"}
                                />
                            </div>
                        </Form.Field>
                    </ResizableDraggableModal.Description>
                </ResizableDraggableModal.Content>
            </ResizableDraggableModal>
        );
    }

    closeErrorModal = () => {
        this.setState({ errorModal: undefined });
        this.isSubmitted = true;
        this.onDetail835ReleaseCancelClick();
    }
    validateSave = () => {
        let isFormValid = true;
        this.state._edited_eob_line_item.map(charge => {
            if (charge.is_primary && charge.patient_balance_id == 0 && charge.patient_balance_adjustment_id == 0) {
                isFormValid = false;
            }
            if (((parseInt(charge.reason_code) === 0 || charge.adjustment_qualifier_code.trim() === 'NA') && (charge.paid_amount === 0 && charge.is_added))
                || ((parseInt(charge.reason_code) === 0 || charge.adjustment_qualifier_code.trim() === 'NA') && (charge.adjustment_amount > 0 && !charge.is_added))
                || ((charge.reason_code === '' || charge.adjustment_qualifier_code.trim() === '') && (charge.adjustment_amount > 0 && !charge.is_added))) {
                isFormValid = false;
            }
        })

        if (isFormValid) {
            this.setState({
                saveAndReleaseModal: {
                    title: 'Save Edits and Release Check'
                }
            });
        }
        else {
            toaster.error('', release835sDetail_configuration.errors.unableToSave);
        }
    }



    countVisibleCards = () => {
        if (this.state.data !== undefined && this.state.data.patients && this.state.data.patients.length > 0) {
            return this.state.data.patients[this.state.page - 1].length;
        }
        else {
            return 0;
        }
    }

    saveAndRealease = async (releaseData: any) => {
        this.setState({ saveAndReleaseModal: undefined, loading: true }) 
        let { date, batch, claimEobkey } = releaseData;

        let allPatients = [];

        if (this.state.data.patients !== undefined) {
            allPatients = this.state.data.patients.length > 0 ? this.state.data.patients.reduce((p: any, n: any) => p.concat(n)) : [];
        }

        let patientsWithExcluded = [];
        let excludedNotes = [];

        if (allPatients !== undefined && allPatients.length != 0) {
            patientsWithExcluded = allPatients.filter(patient => patient.stickyNotes !== undefined && patient.stickyNotes.excludedNote !== undefined);

            patientsWithExcluded.forEach(patientExcluded => {
                excludedNotes.push(patientExcluded.stickyNotes.excludedNote);
            });
        }

        let dto = {
            excludedNotes,
            editLineList: this.state._edited_eob_line_item,
            date,
            batch,
            claimEobkey
            //TO DO 
            //patients: this.state.data.patients.length > 0 ? this.state.data.patients.reduce((p: any, n: any) => p.concat(n)) : []
        }

        if (dto.editLineList && dto.editLineList.length > 0) {
            dto.editLineList.forEach(editLine => {
                if (editLine.patient_id) {
                    editLine.patient_id = editLine.patient_id.toString();
                }
                if (editLine.reason_code) {
                    editLine.reason_code = editLine.reason_code.toString();
                }
            });
        }

        try {
            let response = await saveEditable835(dto, this.props.token);

            if (response.data.data) {
                if (response.data.data == 1) {
                    this.isSubmitted = true;
                    toaster.success('', release835sDetail_configuration.process_msg.success);
                    this.props.history.push(release835sDetail_configuration.parentUrl);
                } else if (response.data.data == -1) {
                        let parameters = JSON.parse(session_storage.get("selectedCheckNumber"));
                        let checkNumberEft = parameters.selectedRow.link_check_eft_num;
                        this.setState({
                            errorModal: {
                                title: 'Error',
                                checkNumber: checkNumberEft
                            }
                        });
                   }
            } else {
                toaster.error('', release835sDetail_configuration.process_msg.error);
            }
            this.setState({
                loading: false
            });
        } catch (error) {
            toaster.error('', release835sDetail_configuration.process_msg.error);
            this.setState({
                loading: false
            });
        }
            this.isSubmitted=false;
    }

    onDetail835ReleaseCancelClick = () => {
        this.props.history.push(release835sDetail_configuration.parentUrl);
    };

    isDataUpdated = () => {
        let isStickyNoteModify = (this.state.data && this.state.data.patients && this.state.data.patients.length > 0 ? this.state.data.patients.reduce((p: any, n: any) => p.concat(n)) : []).filter((p: any) => {
            if (p.stickyNotes && p.stickyNotes.excludedNote) {
                return p.stickyNotes.excludedNote
            }
        }).length > 0

        if ( (isStickyNoteModify  || this.state._edited_eob_line_item.length > 0 ) && !this.isSubmitted ) {
            return true;
        }
        return false;
    }

    onReloadPage = (e: any) => {
        if (this.isDataUpdated()) {
            e.returnValue = null;
        }
    }

    //When Ad/Remove functionality comes in need to make sure Id=0 is not duplicated on edit.
    save_eob_release_line_item_edit = (object: IEOB835ReleaseDetailCardClaimDto) => {

        if (object == null || object == undefined) return;

        let in_memory_edits = this.state._edited_eob_line_item;

        let is_update: boolean = false;
        in_memory_edits = in_memory_edits.map(row => {
            //Update
            if (row.display_row_id === object.display_row_id && row.patient_id === object.patient_id && object.case_id === row.case_id) {
                is_update = true;
                row = object;
            }
            return row;
        });
        //Add
        if (!is_update) in_memory_edits.push(object)

        //remove newly added lines out of memory
        in_memory_edits = in_memory_edits.filter(row => {
            if (!(row.id == 0 && row.is_removed)) {
                return row;                
            }
        })
           
        this.setState({ _edited_eob_line_item: in_memory_edits });
    }

    save_eob_release_line_item_charge_group_edit = (object_array: Array<IEOB835ReleaseDetailCardClaimDto>) => {

        if (object_array == null || object_array == undefined || object_array.length == 0) return;

        let in_state_edited_line_items = this.state._edited_eob_line_item;

        if (in_state_edited_line_items.length == 0) {
            in_state_edited_line_items = object_array;
        }
        else {

            object_array.map(item => {

                let is_updated = false;

                for (let i = 0; i < in_state_edited_line_items.length; i++) {
                    let state_item = in_state_edited_line_items[i];
                    if (state_item.display_row_id === item.display_row_id && state_item.patient_id === item.patient_id && state_item.case_id === item.case_id) {
                        in_state_edited_line_items[i] = item;
                        is_updated = true;
                    }
                }

                if (!is_updated) in_state_edited_line_items.push(item);

            });
        }

        this.setState({ _edited_eob_line_item: in_state_edited_line_items});
    }

    onChecked = (index: any) => {
        let psn_case_id: number = null;
        let psn_patient_id: number;
        let psn_exclude_note: object = null;

        let patient = this.state.data.patients[this.state.page - 1][index];

        if (patient == null || patient == undefined) return;

        psn_patient_id = patient.patientId;
        psn_exclude_note = patient.stickyNotes.excludedNote;
        psn_case_id = patient.caseId;

        let stickyErrorInfoItems = patient.errorInfo.filter(error => error.patientAccount === patient.patientAccount && error.caseId === patient.caseId && error.dateOfService === null && error.cptCode === null);
        this.updateErrorInfo(psn_patient_id, psn_case_id, null, null, release835sDetail_configuration.errors.hasStickyNotes, stickyErrorInfoItems.length == 1);

        this.setState((state: any) => {
            let update_patient = this.state.data.patients[this.state.page - 1][index];
            update_patient.stickyNotes.excludedNote = psn_exclude_note ? undefined : { eob_key: this._parameters.selectedRow.eob_key, patient_id: psn_patient_id, case_id: psn_case_id};
            return state
        })
    }

    hasExclusion = (index: any): boolean => {
        return this.state.data.patients[this.state.page - 1][index].stickyNotes.excludedNote !== undefined;
    }

    displayErrorToggle = (e: any) => {
        let toggleState = this.state.isDisplayedErrorsOnly;
        this.setState({ isDisplayedErrorsOnly: !toggleState }, () => {
            let data = this.getPatientCardsToDisplay(this.state.data);
            this.setState({
                data,
                loading: false,
                activeIndex: []
            }, this.countTotalCharges);
        });
    }

    getPage = (newPage) => {
        this.setState({ page: newPage, activeIndex: [], totalChargesExpandedPerPage: 0 }, this.countTotalCharges);
    }

    countTotalCharges = () => {
        const totalChargesPerPage = this.state.data && this.state.data.patients && this.state.data.patients.length > 0 ?
            this.state.data.patients[this.state.page - 1].reduce((sum: any, item: any) => sum + item.totalCharges, 0) : 0;
        this.setState({ totalChargesPerPage });
        this.countTotalExpandedChargesPerCard();
    }

    countTotalExpandedChargesPerCard = () => {
        const totalChargesExpandedPerPage = this.state.activeIndex.reduce((total: number, item: any, index: any) => {
            if (item && this.state.data.patients[this.state.page - 1] && this.state.data.patients[this.state.page - 1][index]) {
                return total + this.state.data.patients[this.state.page - 1][index].totalCharges
            } else {
                return total
            }
        }, 0);
        this.setState({ totalChargesExpandedPerPage });
    }

    reloadValidationErrors  = async () => {
        try {
            let parameters = JSON.parse(session_storage.get("selectedCheckNumber"));
            let response =  await updateEOBValidationErrors(parameters.selectedRow.eob_key, this.props.token).then(res =>{
                this.loadData();
                return res;
            });
        } catch (error) {
            console.log(error);
            this.loadData();
        }        
    }

    //error info callback
    updateErrorInfo = (patient_id: number, case_id: number, date_of_service: Date, cpt_code: string, validation_message: string, checked: boolean) => {
        let validation_messages = validation_message.split(';');
        if (this.state.data && this.state.data.patients) {
            this.state.data.patients.map(patients => {
                patients.map(patient => {
                    if (patient.patientId === patient_id && patient.caseId === case_id) {
                        validation_messages.map(errMsg => {
                            errMsg = errMsg.trim();
                            let inErrorInfoItems = this.errorInfoItems.filter(error => error.patientAccount === patient.patientAccount && error.caseId === case_id && (moment(error.dateOfService).format('MM/DD/YYYY') === moment(date_of_service).format('MM/DD/YYYY') && error.cptCode === cpt_code && error.errorMessage === errMsg));
                            let errorIndex = -1;
                            for (let i = 0; i < patient.errorInfo.length; i++) {
                                let error = patient.errorInfo[i];
                                if (moment(error.dateOfService).format('MM/DD/YYYY') === moment(date_of_service).format('MM/DD/YYYY') && error.patientAccount === patient.patientAccount && error.caseId === case_id && error.cptCode === cpt_code && error.errorMessage === errMsg) {
                                    errorIndex = i;
                                }
                            }
                            if (checked) {
                                if (errorIndex >= 0) {
                                    if (inErrorInfoItems.length <= 0) {
                                        this.errorInfoItems.push(patient.errorInfo[errorIndex]);
                                    }
                                    patient.errorInfo.splice(errorIndex, 1);
                                }
                            } else {
                                if (inErrorInfoItems.length > 0) {
                                    let insertAt = errMsg === release835sDetail_configuration.errors.hasStickyNotes ? 0 : 1;
                                    patient.errorInfo.splice(insertAt, 0, inErrorInfoItems[0]);
                                }
                            }
                        });
                    }
                });
            });
        }
    }

    render() {
        const { activeIndex } = this.state;
        return (
            <>
                <Dimmer active={this.state.loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <PromptNavigation
                    is_data_changed={this.isDataUpdated()}
                    go_next_location={this.isDataUpdated()}
                    prompt_message={release835sDetail_configuration.prompt_message.add_new}
                    hide_yes_button={true}
                    no_button_text="Continue"
                    no_button_class_name="primary"
                />
                <div className="datatable-content-layout">
                    <div style={{ paddingLeft: '10px', paddingRight:'17px'  }}>
                        <div style={{ display: 'flex', justifyContent:'space-between', alignItems:'center' }}>
                            <h3 style={{ marginBottom: '0px' }}>{release835sDetail_configuration.title}</h3>
                            <ToggleSwitch
                                checked={this.state.isDisplayedErrorsOnly}
                                onChange={event => this.displayErrorToggle(event.target.checked)}>                            
                                <span style={{ fontWeight: 'bold' }}>
                                    Display Errors Only
                                </span>                                
                            </ToggleSwitch>
                        </div>
                    </div>
                    <div className="datatable-content">
                        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                            <DataTable style={{ height: '100%', border: '1px solid #A1AEBB', tableLayout:'fixed' }}>
                                <DataTableHead>
                                    <DataTableRow>
                                    {release835sDetail_configuration.columnDef.map((column: any, index: any) => (<th colSpan={index == 0 || index == 2 ? 2 : 1} key={`head_${index}`} className="text-nowrap">{column.headerName}</th>))}
                                    </DataTableRow>
                                </DataTableHead>
                                <DataTableBody>
                                    <DataTableRow style={{ height: '40px' }}>
                                        {this.state.data && release835sDetail_configuration.columnDef.map((column: any, index: any) => {
                                            return (<DataTableCell colSpan={index == 0 || index == 2 ? 2 : 1} key={`col_${index}`} className="text-nowrap">
                                                {column.type === 'hyper_link' ?
                                                    <div style={{ fontWeight: 'bold' }}><PopUpLinkRenderer key={`link_${index}`} r4_to_r6_routes={release835sDetail_configuration.r4ToR6Route} is_print_disabled={true} is_export_disabled={true} auto_print={false} show_hide_model_popup_report={() => { }} downloadPdf={true} value={this.state.data[column.field]} /></div>
                                                    : this.state.data[column.field]}
                                            </DataTableCell>)
                                        })}
                                    </DataTableRow>
                                    <DataTableRow>
                                        <DataTableCell colSpan={9} style={{ paddingTop: '0px', paddingBottom: '0px', background: 'rgb(233, 241, 244)' }}>
                                            <div style={{ height: '100%', overflow: 'auto' }}>
                                                {(this.state.data && this.state.data.patients && this.state.data.patients[(this.state.page - 1)]) && this.state.data.patients[(this.state.page - 1)].map((patient: any, index: any) => {
                                                    return (
                                                        <Segment key={`card-${index}`} className="card-segment patient-account-card">
                                                            <div className="card-header">
                                                                <div style={{ minWidth: '2em', minHeight: '2em', display: 'flex', alignItems: 'center' }}>
                                                                    {
                                                                        (patient.errorInfo && patient.errorInfo.length > 0) &&
                                                                        <div onMouseEnter={() => this.setState({ [`errorLabel-${index}`]: true })} onMouseLeave={() => this.setState({ [`errorLabel-${index}`]: false })}>
                                                                            <Label circular color={"red"} id={`errorLabel-${index}`} className="labelCircular card-info">
                                                                                {(patient.errorInfo || []).length}
                                                                            </Label>
                                                                            <ToolTip
                                                                                isOpen={this.state[`errorLabel-${index}`]}
                                                                                anchorEl={document.getElementById(`errorLabel-${index}`)}
                                                                                placement="right"
                                                                                variant="toolTipDark"
                                                                            > 
                                                                                {patient.errorInfo.map((error: any, errorIndex) => {
                                                                                    return (
                                                                                        <div key={`tooltip-${index}-${errorIndex}`}>
                                                                                            <b>{error.errorMessage}</b><br />
                                                                                            {error.dateOfService && (<>DOS: {moment(error.dateOfService).format('MM/DD/YYYY')}<br /></>)}
                                                                                            {error.cptCode && (<>CPT: {error.cptCode}<br /></>)}
                                                                                            {errorIndex + 1 !== patient.errorInfo.length && <br />}
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </ToolTip>
                                                                        </div>
                                                                    } 
                                                                    {
                                                                        (patient.readWithErrors && patient.errorInfo && patient.errorInfo.length == 0) &&
                                                                        <div>
                                                                            <Label circular color={"blue"} id={`errorLabel-${index}`} className="labelCircular card-info">
                                                                                0
                                                                            </Label>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <span className="card-info angle-group-expand-collapse" onClick={() => this.handleClick(index)}>
                                                                    <i aria-hidden="true" className={`angle-color angle ${activeIndex[index] ? 'up' : 'down'} icon`}></i>
                                                                </span>
                                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '22px' }}>
                                                                    <span className="card-info">{`${patient.patientName} (${patient.patientId})`}</span>
                                                                    {this.state.isSsoUser &&
                                                                        <Popup trigger={
                                                                            <div id="patient_task" className="popOver text-left flag-align pat-header-icon-icemoon card-patient-task-icon">
                                                                                <PatientTaskIconComponent
                                                                                    key={`release835PatientTask-${index}-${patient.patientId}-${patient.altRecordId2}-${patient.caseId}`}
                                                                                    patientId={patient.altRecordId2}
                                                                                />
                                                                            </div>
                                                                        }
                                                                            content='View Patient Tasks'
                                                                            on='hover'
                                                                        />
                                                                    }
                                                                    <Popup
                                                                        trigger={
                                                                            <div className='popOver text-left flag-align pat-header-icon-icemoon card-account-notes-icon'>
                                                                                <i
                                                                                    className={'icon-File_iconLarge'}
                                                                                    style={{fontSize:'22px'}}
                                                                                    id='patient_header_all_notes_icon'
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            accountNotesModal: {
                                                                                                title: `Account Notes for ${patient.patientName}`,
                                                                                                patientId: patient.patientId,
                                                                                                patient_name: patient.patientName,
                                                                                                gpms_code: patient.gpmsCode,
                                                                                                company_name: patient.companyName
                                                                                            }
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        }
                                                                        content='View All Account Notes'
                                                                        on='hover'
                                                                    />
                                                                    <span className="card-info" style={{ fontSize: '18px' }}><b>Case: </b>{patient.caseName}</span>
                                                                </div>
                                                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', fontSize: '18px' }}>
                                                                    <span className="card-info"><b>Primary Ins: </b>{patient.primaryInsuranceDesc}</span>
                                                                    {patient.secondaryInsuranceDesc && <span className="card-info"><b>Secondary Ins: </b>{patient.secondaryInsuranceDesc}</span>}
                                                                    {patient.tertiaryInsuranceDesc && <span className="card-info"><b>Tertiary Ins: </b>{patient.tertiaryInsuranceDesc}</span>}
                                                                </div>
                                                            </div>
                                                            {activeIndex[index] &&
                                                                <div>
                                                                    {patient.hasStickyNotes == true &&
                                                                        <div style={{ padding: '0 25px 15px' }}>
                                                                            <PaymentStickyNotes
                                                                                paymentStickyNotes={patient.stickyNotes.detail}
                                                                                excludedNote={patient.stickyNotes.excludedNote}
                                                                                index={index}
                                                                                onChecked={this.onChecked}
                                                                                hasExclusion={this.hasExclusion}
                                                                            />
                                                                        </div>
                                                                    }
                                                                    <div className='claim-table'>
                                                                        <Release835sPatientCardDetail
                                                                            eob_key={this._parameters.selectedRow.eob_key}
                                                                            patient_id={patient.patientId}
                                                                            company_id={this._parameters.companyId}
                                                                            clinic_code={this._parameters.clinic_code}
                                                                            case_id={patient.caseId}
                                                                            update_error_info={this.updateErrorInfo}
                                                                            save_edit={this.save_eob_release_line_item_edit}
                                                                            save_group_edit={this.save_eob_release_line_item_charge_group_edit}
                                                                            patient_has_sticky_notes={patient.hasStickyNotes}
                                                                            excludedNote={patient.hasStickyNotes ? patient.stickyNotes.excludedNote : false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </Segment>
                                                    )
                                                })}
                                            </div>
                                        </DataTableCell>
                                    </DataTableRow>
                                    <DataTableRow className="datatable-footer-total-row">
                                        <DataTableCell colSpan={5} style={{ textAlign: 'left', borderTop: '1px solid #A1AEBB' }}><a style={{ fontWeight: 'bold', marginRight: '15px', color: 'rgb(53,64,82)' }} >Check Total</a><a style={{ color: '#003A4C', fontWeight: 500, fontSize: '16px' }} >{this.state.totalChargesExpandedPerPage}/{this.state.totalChargesPerPage} Charges on Expanded Card(s)</a>
                                        </DataTableCell>
                                        <DataTableCell style={{ borderTop: '1px solid #A1AEBB', color: '#003A4C', fontWeight: 500, fontSize: '16px' }}>{this.state.data ? this.state.data.amount : ""}</DataTableCell>
                                        <DataTableCell colSpan={3} style={{ textAlign: 'right', borderTop: '1px solid #A1AEBB', color: '#003A4C', fontWeight: 500, fontSize: '16px' }}>{this.state.totalPatientsAvailable}/{this.state.totalPatients835file} <a style={{ marginRight: '15px', color: '#003A4C', fontSize: '16px' }}>Accounts Available</a></DataTableCell>
                                    </DataTableRow>
                                </DataTableBody>
                            </DataTable>
                            <div className="datatable-footer">
                                <div style={{ flex: '1 1 0px' }} />
                                <div style={{ display: 'flex', flexDirection: 'row', flex: '1 1 0px', justifyContent: 'center' }}>
                                    <DataTableFooter
                                        className={`datatable-active-page ${this.state.page === 1 ? " datatable-first-page" : " datatable-first-page-enabled"} ${this.state.page === ((this.state.data && this.state.data.patients) ? this.state.data.patients.length || 1 : 1) ? " datatable-last-page" : " datatable-last-page-enabled"}`}
                                        page={this.state.page}
                                        pages={(this.state.data && this.state.data.patients) ? this.state.data.patients.length || 1 : 1}
                                        showPageCount={false}
                                        getPage={newPage => this.getPage(newPage)}
                                        pageRangeDisplayed={release835sDetail_configuration.pageRangeDisplayed}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', padding: '10px 0px 10px 10px', flex: '1 1 0px', justifyContent: 'right' }}>
                                    <Button
                                        type="button"
                                        id="cancelButton"
                                        basic
                                        onClick={this.onDetail835ReleaseCancelClick}
                                        content={"Cancel"}
                                    />
                                    <Button
                                        id="saveButton"
                                        className="primary"
                                        type="button"
                                        onClick={() => this.validateSave()}
                                        style={{ marginRight: "0" }}
                                        content={"Save & Release"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.accountNotesModal && this.renderAccountNotes()}
                {this.state.saveAndReleaseModal && this.renderSaveAndReleaseModal()}
                {this.state.errorModal && this.renderErrorModal()}
            </>)
    }
}

export const update_payment_adjustment_qualifier_info = (token) => {
    return dispatch => get_adjustment_qualifier(token).then(res =>
        dispatch({
            type: payment_adjustments.qualifier,
            payload: {
                adjustment_qualifier: res.data,
            }
        }),
        error => {
            log_error(error);
        }
    )
}

export const update_payment_adjustment_reason_info = (token) => {
    return dispatch => get_adjustment_reason(token).then(res =>
        dispatch({
            type: payment_adjustments.reason,
            payload: {
                adjustment_reason: res.data.data,
            }
        }),
        error => {
            log_error(error);
        }
    )
};

export const update_payment_adjustment_code_info = (token) => {
    return dispatch => get_adjustment_codes(token).then(res =>
        dispatch({
            type: payment_adjustments.code,
            payload: {
                adjustment_code: res.data.data,
            }
        }),
        error => {
            log_error(error);
        }
    )
};

export const clear_payment_adjustment_reason_redux = () => {
    return dispatch => dispatch({
        type: payment_adjustments.reason,
        payload: {
            adjustment_reason: [],
        }
    });
};

export const clear_payment_adjustment_qualifier_redux = () => {
    return dispatch => dispatch({
        type: payment_adjustments.qualifier,
        payload: {
            adjustment_qualifier: [],
        }
    });
}

export const clear_payment_adjustment_code_redux = () => {
    return dispatch => dispatch({
        type: payment_adjustments.code,
        payload: {
            adjustment_code: [],
        }
    });
}
const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        update_payment_adjustment_qualifier_info,
        update_payment_adjustment_reason_info,
        update_payment_adjustment_code_info,
        clear_payment_adjustment_qualifier_redux,
        clear_payment_adjustment_reason_redux,
        clear_payment_adjustment_code_redux
    }, dispatch);
};

const mapStateToProps = (state: any) => {
    return {
        token: state.user_login_details.user_data.data.token_details.access_token,
        payment_adjustment: state.payment_adjustment,
        magnusPlat5681IsolateAuth0: get_lauch_darkley_key_value(state.launch_darkly, magnus_plat_5681_isolate_auth0)
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Release835sDetailComponent);
