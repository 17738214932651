import * as React from 'react';
import { Dimmer, Form, Grid, Loader } from 'semantic-ui-react';
import * as case_constant from '../../case/case_constants';

interface ConditionalBilling {
    shown: boolean;
    case_data: Array<any>;
    is_view_mode: boolean;
    conditional_billing_form_data: {
        conditional_billing_code1: string;
        conditional_billing_code2: string;
        conditional_billing_code3: string;
        conditional_billing_note: string;
    };
}

class ConditionalBillingComponent extends React.Component<any, ConditionalBilling> {
    _is_mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            shown: true,
            is_view_mode: (props.page_state == case_constant.enum_case_page_state.edit) ? true : false,
            conditional_billing_form_data: {
                conditional_billing_code1: "",
                conditional_billing_code2: "",
                conditional_billing_code3: "",
                conditional_billing_note: ""
            },
            case_data: props.case_data
        }
    }
    loading = false;

    componentDidMount() {
        this._is_mounted = true;
        if (this.props.onRef) {
            this.props.onRef(this);
        }

        let case_data = this.props.case_data;
        const conditional_billing_form_data = {...this.state.conditional_billing_form_data};
        conditional_billing_form_data.conditional_billing_code1 = case_data.conditional_billing_code1 ? case_data.conditional_billing_code1 : '';
        conditional_billing_form_data.conditional_billing_code2 = case_data.conditional_billing_code2 ? case_data.conditional_billing_code2 : '';
        conditional_billing_form_data.conditional_billing_code3 = case_data.conditional_billing_code3 ? case_data.conditional_billing_code3 : '';
        conditional_billing_form_data.conditional_billing_note = case_data.conditional_billing_note ? case_data.conditional_billing_note : '';
        this.setState({ conditional_billing_form_data });
    }
    componentWillUnmount() {
        this._is_mounted = false;
    }
    componentDidUpdate(previousProps, previousState) {

        if (previousProps.shown != this.props.shown && this._is_mounted) {
            this.setState({ shown: false }); // show on change props
        }

        if (previousProps.page_state !== this.props.page_state && this._is_mounted) {
            this.setState({
                is_view_mode: (this.props.page_state == case_constant.enum_case_page_state.edit) ? true : false
            });
        }

        if (this.update_required(previousProps) && previousProps.shown === this.props.shown && this._is_mounted) {
            this.setState({ conditional_billing_form_data: this.format_conditional_billing_data() });
        }

    }

    update_required = (previousProps) => {
        return !(JSON.stringify(previousProps) === JSON.stringify(this.props));
    }

    format_conditional_billing_data = () => {
        let case_data = this.props.case_data;
        const conditional_billing_form_data = { ...this.state.conditional_billing_form_data };
        conditional_billing_form_data.conditional_billing_code1 = case_data.conditional_billing_code1 ? case_data.conditional_billing_code1 : '';
        conditional_billing_form_data.conditional_billing_code2 = case_data.conditional_billing_code2 ? case_data.conditional_billing_code2 : '';
        conditional_billing_form_data.conditional_billing_code3 = case_data.conditional_billing_code3 ? case_data.conditional_billing_code3 : '';
        conditional_billing_form_data.conditional_billing_note = case_data.conditional_billing_note ? case_data.conditional_billing_note : '';
        return conditional_billing_form_data;
    }

    UNSAFE_componentWillMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    // toggle to show and hide Cases Component
    private toggle = () => {
        if (this._is_mounted) {
            this.setState({
                shown: !this.state.shown
            });
        }
    }

    handle_input_change = (e) => {
        const input_field = e.target.name;
        let input_value = e.target.value;
        if (this._is_mounted) {
            this.setState({
                conditional_billing_form_data: { ...this.state.conditional_billing_form_data, [input_field]: input_value },
            });
        }
    }

    toggle_by_parent_component = (flag) => {
        if (this._is_mounted) {
            this.setState({
                shown: flag
            });
        }
    }

    render() {
        var hidden = {
            display: this.state.shown ? "none" : "block"
        }
        return (
            <div className="ui grid">
                <div className="sixteen wide computer column">
                    <div className="accordion ui fluid styled common-accordion">
                        <div className="active title" onClick={this.toggle.bind(this)} >Conditional Billing<i aria-hidden="true"
                            className={this.state.shown ? "angle down styled-accordion-icon horizontal icon " : "angle up styled-accordion-icon horizontal icon "}></i></div>
                        <div className="content active" style={hidden} >
                            <Dimmer active={this.loading}>
                                <Loader size='massive'>Loading</Loader>
                            </Dimmer>
                            <Grid>
                                <Grid.Column mobile={16} tablet={8} computer={3}>
                                    <Form.Field >
                                        <label className={'conditional-billing-label'}>Conditional Billing Code 1</label>
                                        <input
                                            className={'conditional-billing-inputs'}
                                            disabled={this.state.is_view_mode}
                                            autoComplete="off"
                                            name='conditional_billing_code1'
                                            value={this.state.conditional_billing_form_data.conditional_billing_code1}
                                            onChange={this.handle_input_change}
                                            id="conditional_billing_code1"
                                            maxLength={3}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={8} computer={3}>
                                    <Form.Field >
                                        <label className={'conditional-billing-label'}>Conditional Billing Code 2</label>
                                        <input
                                            className={'conditional-billing-inputs'}
                                            disabled={this.state.is_view_mode}
                                            autoComplete="off"
                                            name='conditional_billing_code2'
                                            value={this.state.conditional_billing_form_data.conditional_billing_code2}
                                            onChange={this.handle_input_change}
                                            id="conditioal_billing_code2"
                                            maxLength={3}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={8} computer={3}>
                                    <Form.Field >
                                        <label className={'conditional-billing-label'}>Conditional Billing Code 3</label>
                                        <input
                                            className={'conditional-billing-inputs'}
                                            disabled={this.state.is_view_mode}
                                            autoComplete="off"
                                            name='conditional_billing_code3'
                                            value={this.state.conditional_billing_form_data.conditional_billing_code3}
                                            onChange={this.handle_input_change}
                                            id="conditional_billing_code3"
                                            maxLength={3}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={8} computer={7}>
                                    <Form.Field >
                                        <label>Conditional Billing Note</label>
                                        <textarea
                                            className={'conditional-billing-inputs'}
                                            rows={2}
                                            cols={50}
                                            disabled={this.state.is_view_mode}
                                            autoComplete="off"
                                            name='conditional_billing_note'
                                            value={this.state.conditional_billing_form_data.conditional_billing_note}
                                            onChange={this.handle_input_change}
                                            id="conditional_billing_note"
                                            maxLength={80}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ConditionalBillingComponent;