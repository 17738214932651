import * as global_constants from '../src/app/global_constants';
import * as local_storage from '../src/app/shared/local_storage_utility';
import { decompressFromUTF16 } from '../src/app/shared/lz-string';

module.exports = function (user_id = '', gpms_code = '') {
    const pendoKey = global_constants.pendo_key;
    if (pendoKey !== '') {

        const userData = {
            user_id: user_id,
            gpms_code: gpms_code
        };

        const postLoginData = local_storage.get('auth_data');
        const preLoginIdentifiedData = JSON.parse(localStorage.getItem(`persist:root` + sessionStorage.getItem('sessionId')));

        if (postLoginData) {
            userData.user_id = postLoginData.user_id;
            userData.gpms_code = postLoginData.gpms_code;
        } else if (preLoginIdentifiedData) {
            const user_login_details = decompressFromUTF16(JSON.parse(preLoginIdentifiedData.user_login_details));
            const data = user_login_details;
            if (
                data.forgot_password_token_validation &&
                data.forgot_password_token_validation != null &&
                data.forgot_password_token_validation.data.user_id !== 0
            ) {
                userData.user_id = data.forgot_password_token_validation.data.user_id;
                userData.gpms_code = data.forgot_password_token_validation.data.gpms_code;
            }
        }

        if (userData) {
            if (!pendo.accountId) {
                //console.log('pendo visitor,account:', userData.user_id, userData.gpms_code);
                pendo.initialize({
                    visitor: {
                        id: 'revflow-' + userData.user_id.toString() // 'VISITOR-UNIQUE-ID' // Required if user is logged in
                        // email: // Optional
                        // role: // Optional

                        // You can add any additional visitor level key-values here,
                        // as long as it's not one of the above reserved names.
                    },
                    account: {
                        id: 'revflow-' + userData.gpms_code //'ACCOUNT-UNIQUE-ID' // Highly recommended
                        // name: // Optional
                        // planLevel: // Optional
                        // planPrice: // Optional
                        // creationDate: // Optional

                        // You can add any additional account level key-values here,
                        // as long as it's not one of the above reserved names.
                    }
                });
            } else {
                //console.log('pendo visitor,account:', userData.user_id, userData.gpms_code);
                pendo.updateOptions({
                    visitor: {
                        id: 'revflow-' + userData.user_id.toString() // 'VISITOR-UNIQUE-ID' // Required if user is logged in
                        // email: // Optional
                        // role: // Optional

                        // You can add any additional visitor level key-values here,
                        // as long as it's not one of the above reserved names.
                    },
                    account: {
                        id: 'revflow-' + userData.gpms_code //'ACCOUNT-UNIQUE-ID' // Highly recommended
                        // name: // Optional
                        // planLevel: // Optional
                        // planPrice: // Optional
                        // creationDate: // Optional

                        // You can add any additional account level key-values here,
                        // as long as it's not one of the above reserved names.
                    }
                });
            }
        }
    }
};