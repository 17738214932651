import * as React from 'react';
import PayerRulesSearchComponent from './payer_rules_search_component';
import PayerRulesSearchFFComponent from './payer_rules_search_ff_component';

function PayerRulesReRouteComponent(props) {
  const enhancedPayerRulesFF = props.enhancedPayerRulesFF;
  if (enhancedPayerRulesFF) {
    return <PayerRulesSearchFFComponent />;
  }
  return <PayerRulesSearchComponent />;
}

export default PayerRulesReRouteComponent;
