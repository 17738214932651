import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { refresh_token } from '../../login/action/login_action';
import * as session_storage from '../../shared/session_storage_utility';


interface MyComponentProps {
    component: any,
    user_login_details:any
    refresh_token:any
}
/*
 1)Load application data only if the user has logged in. i.e token is generated
 2)Refresh token on regular intervals
 */
class AuthorizedRouteComponent extends React.Component<MyComponentProps, {}>{

    [x: string]: any;

    constructor(props: any) {
        super(props);
    }

    render() {
        const { component: Component, user_login_details, ...rest } = this.props
        return (
            <>
                <Route {...rest} render={props => {
                    return user_login_details.is_logged_in && user_login_details.user_data.data.token_details.access_token
                        ? (user_login_details.is_password_expired && props.location.pathname != "/manage_account" ? <Redirect to="/manage_account" />
                             : <Component {...props} />)
                        : <Redirect to="/login" />
                     }} />
            </>
        )
    }
}

const mapStateToProps= (state) =>{
    return {
        user_login_details: state.user_login_details
    }
}


const mapDispatchToProps = (dispatch) => {

    return bindActionCreators({
        refresh_token: refresh_token
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizedRouteComponent)
