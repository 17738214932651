const cloneDeep = require('lodash/clonedeep');
import { Request } from './request';
import { RequestConfig } from './config';


export class FullRequest {
    url: string
    request_config: any
    headers: any

    constructor(url: string, method: string, token: string, headers?: any, config?: any) {

        // use default headers if not passed in
        this.headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token
        }

        if (headers != null && typeof headers !== 'undefined') {

            for (var h in headers) {
                // override if specified in parameter
                if (IsProperty(headers, 'Accept')) {
                    this.headers['Accept'] = headers[h];
                }
                else if (IsProperty(headers, 'Content-Type')) {
                    this.headers['Content-Type'] = headers[h];
                }
                else if (IsProperty(headers, 'Authorization')) {
                    this.headers['Authorization'] = headers[h];
                }
                else if (IsProperty(headers, 'silversurfer')) {
                    this.headers['silversurfer'] = cloneDeep(headers[h]);
                }
                else {
                        this.headers[h] = cloneDeep(headers[h]);
                }
            }        
        }


        this.request_config = new RequestConfig({
            'url': url,
            'headers': this.headers,
            'method': method,             
        });

        if (config != null && typeof config !== 'undefined') {

            for (var c in config) {
                if (IsProperty(config, 'url') || IsProperty(config, 'headers') || IsProperty(config, 'method')) {
                    ;
                }
                if (IsProperty(config, 'data')) {
                        this.request_config.add_data(cloneDeep(config[c]));
                }
                else if (IsProperty(config, 'params')) {
                        this.request_config.add_params(cloneDeep(config[c]));
                }
                else {      // shouldn't happen but in case we need it
                    this.request_config[c] = cloneDeep(config[c]);
                }

            }
        }

    }

    make(should_queue: boolean = false): any {
        let request = new Request()
        return request.make(this.request_config, should_queue)
    }
}
export class GetRequest extends FullRequest {

    constructor(url: string, token?: string, headers?: any, config?: any) {
        super(url, 'get', token, headers, config)
    }

}

export class PostRequest extends FullRequest {

    constructor(url: string, token: string, headers?: any, config?: any) {
        super(url, 'post', token, headers, config)
    }
}

export class PutRequest extends FullRequest {

    constructor(url: string, token: string, headers?: any, config?: any) {
        super(url, 'put', token, headers, config)
    }
}

// Helper functions


// #region GET Methods
export const get_header_request = (url: string, token: string, headers: any, queue?: boolean) => {

    let request = new GetRequest(url, token, headers, null)

    return request.make((queue === undefined) ? false : queue)

}

export const get_config_request = (url: string, token: string, config: any, queue?: boolean) => {

    let request = new GetRequest(url, token, null, config)

    return request.make((queue === undefined) ? false : queue)
}

export const get_url_request = (url: string, queue?: boolean) => {

    let request = new GetRequest(url, null, null, null)

    return request.make((queue === undefined) ? false : queue)
}

export const get_header_config_request = (url: string, token: string, header: any, request_config: any, queue?: boolean) => {

    let request = new GetRequest(url, token, header, request_config)

    return request.make((queue === undefined) ? false : queue)
}

export const get_query_request = (url: string, token: string, params: any, queue?: boolean) => {

    let queryString = Object.keys(params)
        .map(key => key + '=' + params[key])
        .join('&')
    let urlstring = url + '?' + queryString

    return get_request(urlstring, token, queue)

}

export const get_request = (url: string, token: string, queue?: boolean) => {

    let request = new GetRequest(url, token, null, null)

    return request.make((queue === undefined) ? false : queue)
}
// #endregion

// #region POST Methods
export const post_config_request = (url: string, token: string, config: any, queue?: boolean) => {
    let request = new PostRequest(url, token, null, config)
    
    return request.make((queue === undefined) ? false : queue)
}

export const post_header_request = (url: string, token: string, header: any, queue?: boolean) => {
    let request = new PostRequest(url, token, header, null)

    return request.make((queue === undefined) ? false : queue)
}
export const post_request = (url: string, token: string, queue?: boolean) => {
    let request = new PostRequest(url, token, null, null)

    return request.make((queue === undefined) ? false : queue)
}
// #endregion

// #region PUT Methods
export const put_query_request = (url: string, token: string, params: any, queue?: boolean) => {

    let queryString = Object.keys(params)
        .map(key => key + '=' + params[key])
        .join('&')
    let urlstring = url + '?' + queryString

    return put_request(urlstring, token, queue)

}

export const put_request = (url: string, token: string, queue?: boolean) => {
    let request = new PutRequest(url, token, null, null)

    return request.make((queue === undefined) ? false : queue)
}

export const put_config_request = (url: string, token: string, request_config: any, queue?: boolean) => {
    let request = new PutRequest(url, token, null, request_config)

    return request.make((queue === undefined) ? false : queue)
}

export const put_config_header_request = (url: string, token: string, request_config: any, header?: any, queue?: boolean) => {
    let request = new PutRequest(url, token, header, request_config)

    return request.make((queue === undefined) ? false : queue)
}

// #endregion

// #region Helper Methods
function IsProperty(obj: object, property: any): boolean {
    if (obj == null || typeof obj === 'undefined') {
        return false
    }
    else {
        return Object.prototype.hasOwnProperty.call(obj, property)
    }
}


