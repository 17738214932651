import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Form, Grid } from "semantic-ui-react";
import Selection from '../../../shared/component/selection_component';
import { LoaderComponent } from '../../../shared/component/loading_component';
import ReportHeader from '../../../reports/component/report_header';
import GridView from '../../../shared/component/grid/index';
import { user_companies } from '../../../user/action/user_action';
import { toastr } from 'react-redux-toastr';
import { get_bulk_user_template, bulk_user_upload, user_bulk_upload_user_type } from '../action/constants_action';
import { toastr_options } from '../../../global_constants';
import { saveAs } from "../../../shared/file-saver.min";
import { messages, report_ledger_full_configuration_header_id } from '../../../reports/report_constants';
import { user_bulk_upload } from '../constants';
import { get_columns, isSupportedMimeType } from "./../../../shared/utility"


/**
 * Aug 01, 2021
 * User Buld upload
 */
class UserBulkUploadComponent extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            is_edit_mode: false,
            form_submited: false,
            is_save_button_disabled: false,
            selected_user_type: '',
            is_grid_visible: false,
            grid_rows_count: 0,
            grid_params: null,
            success: '',
            user_type: []
        }
    }

    is_mounted = false;

    title: string = 'User Bluk Upload';
    page_name: string = 'user_bulk_upload';
    total_column_width: number = 0;
    grid_header_height: number = 0;
    is_initial_result = false;
    grid_width = 0;
    page_metadata: any;
    width_padding: number = 50;

    componentDidMount() {
        this.is_mounted = true;
        document.body.classList.add('reports');
        let token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.page_metadata = user_bulk_upload;
        this.get_user_type();
    }

    componentWillUnmount = () => {
        document.body.classList.remove('reports');
        this.is_mounted = false;
    };

    show_html_content_toaster = (msg: any) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ),
            timeOut: toastr_options.toastr_time_out,
            preventDuplicates: true,
            position: 'bottom-center'
        };
    }

    get_user_type = () => {
        let token = this.props.user_login_details.user_data.data.token_details.access_token;
        user_bulk_upload_user_type(token).then(
            res => {
                let users = [{ key: -1, value: '-1', text: ' ' }];
                if (res.data && res.data.data) {
                    const list = res.data.data.map((val, index) => { return { key: index, value: val.bulkUserAllowedTypeId, text: val.userType } });
                    users = users.concat(list);
                }
                this.setState({ user_type: users });
            },
            error => {
                this.setState({ user_type: [] });
            }
        );
    }


    format_data = (rows, columns) => {
        let formatted_data = rows.map(row_data => {
            columns.filter(col_data => {
                switch (col_data.type) {
                    case 'boolean':
                        row_data[col_data.field] = row_data[col_data.field] ? true : false;
                        break;
                    case 'number':
                        row_data[col_data.field] = row_data[col_data.field];
                        break;
                    case 'string':
                        row_data[col_data.field] = row_data[col_data.field];
                        break;
                    default:
                        row_data[col_data.field] = row_data[col_data.field];
                }
            });
            return row_data;
        });
        return formatted_data;
    };

    download_template = () => {
        let token = this.props.user_login_details.user_data.data.token_details.access_token;
        //Start loading
        this.setState({ loading: true, success: '', is_grid_visible: false });
        get_bulk_user_template(token, this.state.selected_user_type)
            .then(res => {
                const fileName = this.file_name(this.state.selected_user_type);
                saveAs(res.data, fileName);
                //Stop loading
                this.setState({ loading: false });
            });
    }

    file_name = (type) => {

        switch (parseInt(type)) {
            case 0:
                return 'BulkUserTemplate_WebPT.xlsx';
            case 1:
                return 'BulkUserTemplate_Partner.xlsx';
            case 2:
                return 'BulkUserTemplate_Customer.xlsx';

            default:
                return 'BulkUserTemplate.xlsx';
        }
    }

    upload_logo = () => {
        let input: any = this.refs.input_reader;
        input.click();
    };

    on_change = (e) => {
        const { name, files } = e.target;
        if (files && files.length > 0 && this.checkMimeType(e)) {
            let token = this.props.user_login_details.user_data.data.token_details.access_token;
            const data = new FormData()
            for (var x = 0; x < files.length; x++) {
                data.append('file', files[x]);
            }

            //Start loading
            this.setState({ loading: true, success: '', is_grid_visible: false });

            bulk_user_upload(token, data)
                .then(res => { // then print response status
                    this.display_grid(res);
                }, error => {
                    const toastr_options = this.show_html_content_toaster(this.page_metadata.message.error);
                    toastr.error('', toastr_options);
                    this.setState({ loading: false });
                })
                .catch(err => { // then print response status
                    const toastr_options = this.show_html_content_toaster(this.page_metadata.message.error);
                    toastr.error('', toastr_options);
                    this.setState({ loading: false });
                });
        }
    }

    display_grid = (res) => {
        let state = { loading: false, is_grid_visible: false };
        if (res.data.data.errorMessage && res.data.data.errorMessage.length > 0) {
            let data = this.format_data(res.data.data.errorMessage, this.page_metadata.column_def);
            const grid_height = get_columns(data, this.page_metadata.column_def);
            this.total_column_width = grid_height.total_column_width;
            this.grid_header_height = grid_height.header_height;
            state['grid_rows_count'] = data && data.length || 0;
            state['rows'] = data || [];
            state['is_grid_visible'] = data && data.length > 0 ? true : false;
            state['success'] = data && data.length > 0 ? 'no' : 'yes';
            const toastr_options = this.show_html_content_toaster(this.page_metadata.message.error);
            toastr.error('', toastr_options);
        } else {
            state['success'] = 'yes';
            const toastr_options = this.show_html_content_toaster(this.page_metadata.message.success(res.data.data.newUserCount));
            toastr.success('', toastr_options);
        }
        this.setState(state);
    }

    checkMimeType = (event) => {
        let err = [];
        const types = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
        
        err = isSupportedMimeType(event, types);
        for (var z = 0; z < err.length; z++) {
            toastr.error(err[z]);
        }
        return true;
    }

    //Dropdown change handler.
    dropdown_change_handler = (data, e) => {
        this.setState({ selected_user_type: e.target.value != '-1' ? e.target.value : '' });
    };


    // Search Criteria Toggler
    controls_toggle = () => {
        if (this.is_mounted) {
            this.setState({
                controls_is_expended: !this.state.controls_is_expended
            });
        }
    };

    // Function calls on initialization of Ag-Grid and catch its reference.
    get_grid_ref = grid_params => {
        this.setState({
            grid_params,
            grid_rows_count: grid_params.api.getModel().getRowCount()
        });
        var grid_width = document.getElementsByClassName('report-wrapper')[0].clientWidth;
        if (this.total_column_width > (grid_width - this.width_padding)) {
            this.page_metadata.column_def.filter(item => {
                grid_params.columnApi.setColumnWidth(item.field, item.width, false);
            });
        }
    };

    //Function calls after click of filter button and recalculate the footer sum.
    on_filter_button_click = () => {
        // Update row count
        this.setState({
            grid_rows_count: this.state.grid_params.api.getModel().getRowCount()
        });
    };


    render() {
        return (<LoaderComponent loading={this.state.loading}>
            <div className={'common-forms-add'}>
                <div className={'common-forms-search report-framework'}>
                    <ReportHeader title={'User Bulk Upload'} />
                    <div
                        id="report-scrollable-area"
                        className="report-wrapper"
                        style={this.state.is_grid_visible ? { flex: 1 } : {}}>
                        <div className="patient-search-form  patient_search_bottom_padding"
                            id="report-criteria-container">
                            <Form autoComplete="off">
                                <Grid>
                                    <Grid.Column tablet={8} computer={4}>
                                        <Form.Field >
                                            <label>For User Type</label>
                                            <Selection
                                                name='user_type'
                                                id='id_user_type'
                                                options={this.state.user_type}
                                                defaultValue={this.state.selected_user_type}
                                                disabled={false}
                                                style={this.state.form_submited ? 'red-error-thin' : ''}
                                                onChange={this.dropdown_change_handler}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column tablet={8} computer={8}>
                                        <Form.Field >
                                            <label style={{ visibility: 'hidden' }}>TEXT</label>
                                            <div style={{ display: 'flex' }}>
                                                <Button type="button"
                                                    id="btn-download-template"
                                                    basic onClick={this.download_template}
                                                    disabled={!this.state.selected_user_type}>Download Template</Button>
                                                <Button type="button"
                                                    id="btn-download-template" primary
                                                    onClick={this.upload_logo}>Upload Spreadsheet</Button>
                                            </div>

                                            <input
                                                type='file'
                                                ref='input_reader'
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                style={{ display: 'none' }}
                                                value=''
                                                onChange={this.on_change}
                                                name='input_upload'
                                                id='id_input_upload'
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid>
                            </Form>
                        </div>
                        {this.state.is_grid_visible && (
                            <React.Fragment>
                                <Grid className="dateTime">
                                    <Grid.Column tablet={5} computer={4} textAlign="left">
                                        <p style={{ fontSize: '16px', minHeight: 22 }}>Search Results</p>
                                    </Grid.Column>
                                    <Grid.Column tablet={5} computer={8} textAlign="center">
                                        {
                                            <p style={{ fontSize: '16px', minHeight: 22 }}>
                                                {`${this.state.grid_rows_count} ${
                                                    this.state.grid_rows_count == 1
                                                        ? ' record shown'
                                                        : ' records shown'
                                                    }`}
                                            </p>
                                        }
                                    </Grid.Column>
                                    <Grid.Column tablet={2} computer={4} textAlign="right" />
                                </Grid>
                                <GridView
                                    id={`id_grid_${this.page_name}`}
                                    row={this.state.rows}
                                    column={this.page_metadata.column_def}
                                    headerHeight={this.grid_header_height}
                                    style={{ height: '100%' }}
                                    wrapperStyle={{ width: '100%', height: '100%', display: 'flex', flex: 1 }}
                                    wrapperClass={'grid_wrapper accordian_grid'}
                                    enableColResize={true}
                                    emptyMessage={messages.no_records_found}
                                    get_grid_ref={this.get_grid_ref}
                                    headerIdForTabNavigation={report_ledger_full_configuration_header_id}
                                    on_filter_button_click={() => {
                                        this.on_filter_button_click();
                                    }}
                                    suppressSizeToFit={true}
                                />
                            </React.Fragment>
                        )}
                    </div>
                    <div
                        className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area"
                        id="applicationFooterSticky">
                    </div>
                </div>
            </div>
        </LoaderComponent>);
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        user_companies: user_companies
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        user_companies_response: state.user_details.user_companies_response,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserBulkUploadComponent));