import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Dimmer, Form, Grid, Icon, Loader, Modal } from 'semantic-ui-react';
import { clearTimeout, setTimeout } from 'timers';
import { get_control_list } from '../../../../shared/action/advanced_control_action';
import { handle_focus_on_tab, set_focus_on_element_with_id } from '../../../../shared/tab_navigation_utility';
import Selection from '../../../../shared/component/selection_component';

interface IProps {
  controlId: string;
  onSearch: Function;
  onClear: Function;
  onCloseModal: Function;
  showModal?: boolean;
  get_control_list?: Function;
  advance_control_state?: object;
  user_login_details?: { user_data: { data: { token_details: { access_token: any } } } };
  addClicks: Function;
  keyPress: Function;
  disabled_element?: string;
  set_parent_show_modal_state?: Function;
  className?: string;
  search_type?: string;
  autoFocusForSearchIcon?: boolean;
  options?: any[];
  default_options?: object;
  form_data: object;
}

export class AdvancedControlModal extends React.PureComponent<IProps, any> {
  static defaultProps: Partial<IProps> = {
    default_options: {}
  };
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      control_data: null,
      loading: false,
      form_data: this.props.form_data,
      control_id: this.props.controlId,
      disabled_element: this.props.disabled_element ? this.props.disabled_element : false
    };

    this.grid_reference = React.createRef();
  }
  total_column_width: number = 0;
  grid_header_height: number = 0;
  grid_reference: any;
  is_mounted = false;

  componentDidMount() {
    this.is_mounted = true;
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousState.showModal != this.state.showModal) {
      if (this.is_mounted) {
        this.setState({
          form_data: this.props.form_data,
          showModal: this.state.showModal
        });
      }
      if (this.state.showModal) {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.props.get_control_list(this.state.control_id, token);
      }
    }

    if (previousProps.showModal !== this.props.showModal) {
      if (this.is_mounted) {
        this.setState({
          form_data: this.props.form_data,
          showModal: this.props.showModal
        });
      }
    }

    if (previousProps.advance_control_state !== this.props.advance_control_state) {
      const control_id = this.state.control_id.toLowerCase();
      if (this.is_mounted) {
        this.setState({
          form_data: this.props.form_data,
          control_data: this.props.advance_control_state[control_id]
        });
      }
    }

    if (previousProps.disabled_element != this.props.disabled_element) {
      if (this.is_mounted) {
        this.setState({
          disabled_element: this.props.disabled_element
        });
      }
    }
  }

  componentWillUnmount() {
    this.is_mounted = false;
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }
  }

  controlHandle = (e) => {
    if (this.state.disabled_element) {
      return;
    }
    e.preventDefault();
    if (this.is_mounted) {
      if (this.props.search_type) {
        this.handle_close_tab();
      }
      if (this.is_mounted) {
        this.setState({ showModal: true });
      }
    }
    if (typeof this.props.set_parent_show_modal_state != 'undefined') {
      this.props.set_parent_show_modal_state(true, this.props.controlId);
    }
    if (this.is_mounted) {
      this.setState({
        timeout: setTimeout(() => {
          // if no DOM is there no need to go further.
          if (!document) return;
          const modelList: NodeListOf<HTMLElement> = document.querySelectorAll(
            '#advance_search_model_pop'
          ) as NodeListOf<HTMLElement>;
          const model = modelList[modelList.length - 1];
          if (model) {
            const fist_input = model.getElementsByTagName('input');
            if (fist_input && fist_input.length > 0 && fist_input[0]) {
              fist_input[0].focus();
            }
          }
        }, 500)
      });
    }
  };

  /* getHandler method provide handles on bases of provided label button */
  getHandler = (action, e) => {
    switch (action) {
      case 'Search':
        this.searchFormHandler(e);
        break;
      case 'Clear':
        this.clearFormHandler(e);
        break;
      case 'Cancel':
        this.closeModal();
        break;
      case 'Add New':
        this.props.addClicks();
        break;
      case 'Add':
        this.props.addClicks();
        break;
      case 'New':
        this.props.addClicks();
        break;
      default:
        break;
    }
  };

  closeModal = () => {
    if (this.is_mounted) {
      this.setState(
        {
          showModal: false,
          form_data: {}
        },
        () => {
          if (this.props.onCloseModal) {
            this.props.onCloseModal();
          }
          if (this.props.set_parent_show_modal_state) {
            this.props.set_parent_show_modal_state(false, this.props.controlId);
          }
          // when modal is closing and focus should move to search magnifying glass
          if (this.props.search_type) {
            set_focus_on_element_with_id('advance-control-' + this.props.search_type);
          }
        }
      );
    }
  };

  searchFormHandler = (e) => {
    if (this.is_mounted) {
      this.setState(
        {
          showModal: false
        },
        () => {
          // when modal is closing and focus should move to search magnifying glass
          if (this.props.search_type) {
            set_focus_on_element_with_id('advance-control-' + this.props.search_type);
          }
          this.props.onSearch(this.state.form_data);
        }
      );
    }
  };

  clearFormHandler = (e) => {
    if (this.is_mounted) {
      const old_form_data = this.state.form_data;
      let form_data = {};
      if (this.state.control_data) {
        this.state.control_data.inputFields.filter((item) => {
          if (item.type === 'Select') {
            form_data = {
              ...form_data,
              [item.name]: this.props.default_options[item.name] || ''
            };
          } else if (item.type === 'TextBox') {
            form_data = {
              ...form_data,
              [item.name]: ''
            };
          }
        });
      }
      this.setState(
        {
          form_data,
          error_message: ''
        },
        () => {
          this.props.onClear(old_form_data);
        }
      );
    }
  };

  handleChange = (e) => {
    const name = e.target.name;
    const input_val = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const form_updated_data = Object.assign({}, this.state.form_data, { [name]: input_val });
    if (this.is_mounted) {
      this.setState({
        form_data: form_updated_data
      });
    }
  };

  input_key_press = (event) => {
    if (event.which == 13) {
      if (this.props.keyPress) {
        var data = this.state.form_data; // add page size property
        this.props.keyPress(data, event);
      }
    }
  };

  key_tab_press = (event, type) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      if (type == 'open') {
        this.controlHandle(event);
        if (typeof this.props.set_parent_show_modal_state != 'undefined') {
          this.props.set_parent_show_modal_state(true, this.props.controlId);
        }
      } else if (type == 'Search') {
        this.getHandler(type, event);
      }
    }
  };

  handle_close_tab = () => {
    setTimeout(() => {
      if (!document) return; // if no DOM is there no need to go further.
      let closeIcons: NodeListOf<HTMLElement> = document.querySelectorAll(
        'i.close.icon:not(.auto-search-icon)'
      ) as NodeListOf<HTMLElement>;
      let closeIcon = closeIcons[closeIcons.length - 1];
      if (closeIcon) {
        closeIcon.tabIndex = 0;
        closeIcon.id = 'advance_control_close_icon' + this.props.search_type;
        let search_type = this.props.search_type;
        closeIcon.addEventListener('keydown', (event) => {
          if (event.shiftKey && event.keyCode == 9) {
            event.preventDefault();
            let search_button = document.getElementById('advance_control_search_button' + search_type);
            search_button.focus();
          }

          if (event.keyCode == 13) {
            event.preventDefault();
            var el = event.target as HTMLElement;
            el.click();
          }
        });
      }
    }, 500);
  };

  handle_search_tab = (event) => {
    // if grid is not empty then focus should move on cross icon.
    handle_focus_on_tab(event, 'advance_control_close_icon' + this.props.search_type);
  };

  render_controls = (input_controls) => {
    return input_controls.map((field, index) => {
      switch (field.type) {
        case 'Checkbox':
          return (
            <Grid.Column tablet={8} computer={8} key={index}>
              <Form.Field>
                <label>&nbsp;</label>
                <div className='ui checkbox'>
                  <input
                    type={field.type}
                    name={field.name}
                    value={this.state.form_data.hasOwnProperty(field.name) ? this.state.form_data[field.name] : ''}
                    onChange={this.handleChange}
                    onKeyUp={(e) => {
                      this.key_tab_press(e, 'Search');
                    }}
                    onKeyPress={this.input_key_press}
                  />
                  <label>{field.labelName}</label>
                </div>
              </Form.Field>
            </Grid.Column>
          );
        case 'Select':
          return (
            <Grid.Column tablet={8} computer={8} key={index}>
              <Form.Field>
                <label>{field.labelName}</label>
                <Selection
                  id={field.name}
                  name={field.name}
                  {...(this.props.default_options ? {} : { placeHolder: 'Select' })}
                  defaultValue={this.state.form_data[field.name]}
                  options={(this.props.options && this.props.options[field.name]) || []}
                  onChange={(value, e) => this.handleChange(e)}
                />
              </Form.Field>
            </Grid.Column>
          );
        case 'TextBox':
          return (
            <Grid.Column tablet={8} computer={8} key={index}>
              <Form.Field>
                <label>{field.labelName}</label>
                <input
                  type={'text'}
                  name={field.name}
                  value={this.state.form_data[field.name] || ''}
                  onChange={this.handleChange}
                  onKeyUp={(e) => {
                    this.key_tab_press(e, 'Search');
                  }}
                  onKeyPress={this.input_key_press}
                  {...(field.maxLength != null ? { maxLength: field.maxLength } : {})}
                />
              </Form.Field>
            </Grid.Column>
          );
        default:
          return (
            <Grid.Column tablet={8} computer={8} key={index}>
              <Form.Field></Form.Field>
            </Grid.Column>
          );
      }
    });
  };

  render_action_button = (button_controls) => {
    return button_controls.map((field, index) => {
      if (field.action == 'Search') {
        return (
          <Button
            key={index}
            name={field.name}
            className={'ui primary button'}
            type='button'
            onKeyDown={this.handle_search_tab}
            onClick={(e) => this.getHandler(field.action, e)}
            id={
              this.props.search_type
                ? 'advance_control_search_button' + this.props.search_type
                : 'advance_control_search_button'
            }
          >
            {field.action}
          </Button>
        );
      }
      return (
        <Button
          key={index}
          name={field.name}
          className={'basic'}
          type='button'
          onClick={(e) => this.getHandler(field.action, e)}
          id='bg_fff2'
        >
          {field.action}
        </Button>
      );
    });
  };

  render() {
    let button_controls = [];
    let input_controls = [];
    const form_fields = this.state.control_data ? this.state.control_data.inputFields : null;
    if (form_fields != null) {
      button_controls = form_fields.filter((element) => element.type == 'Button' && element).reverse(); // filter button controls
      input_controls = form_fields.filter((element) => element.type != 'Button' && element);
    }

    return (
      <React.Fragment>
        <button
          type='button'
          className='searchIcon'
          disabled={this.state.disabled_element}
          onClick={this.controlHandle}
          autoFocus={this.props.autoFocusForSearchIcon ? true : false}
          id={this.props.search_type ? 'advance-control-' + this.props.search_type : ''}
        >
          <Icon aria-hidden='true' name='search' />
        </button>
        {form_fields && (
          <Modal
            onClose={this.closeModal}
            centered={false}
            className={`modal_container default-modal ${this.props.className}`}
            open={this.state.showModal}
            closeIcon={true}
            id='advance_search_model_pop'
            closeOnDimmerClick={false}
          >
            <Dimmer active={this.state.loading}>
              <Loader size='huge'>Loading</Loader>
            </Dimmer>
            <Modal.Header>
              <div>
                <h3>{this.state.control_data.title}</h3>
              </div>
            </Modal.Header>
            <Modal.Content id='advanced_control_content'>
              <Modal.Description>
                <div className='common-forms' style={{ padding: '0' }}>
                  <Form>
                    <Grid>{this.render_controls(input_controls)}</Grid>
                    <Grid>
                      <Grid.Column tablet={16} computer={16} textAlign='right' className='mar-t-10'>
                        {this.render_action_button(button_controls)}
                      </Grid.Column>
                    </Grid>
                  </Form>
                </div>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return { get_control_list: (control_id, token) => dispatch(get_control_list(control_id, token)) };
};

const mapSTateToProps = (state) => {
  return {
    advance_control_state: state.advanced_control_details,
    user_login_details: state.user_login_details
  };
};

export default connect(mapSTateToProps, mapDispatchToProps)(AdvancedControlModal);
