import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Dimmer, Form, Grid, Input, Loader, Checkbox, Modal } from 'semantic-ui-react';
import * as constants from '../../admin/constants/constants';
import Selection from '../../shared/component/selection_component';
import DateRangePickerComponent from './../../shared/component/date_range_component';
import { get_patient_search } from '../../patient/action/patient_search_action';
import {
    get_columns,
    get_all_error,
    get_lauch_darkley_key_value as get_launch_darkly_key_value
} from '../../shared/utility';
import * as constants_actions from '../../admin/constants/action/constants_action';
import { get_patient_insurance } from '../action/claim_indicator_action'
import {
    search_demand_claims,
    update_batch_claims,
    get_claims_report,
    validate_charges,
    add_batch_billing
} from '../action/billing_actions';
import { handle_focus_on_tab, set_focus_on_element_with_id, set_focus_to_app_header } from '../../shared/tab_navigation_utility';
import GridView from '../../shared/component/grid';
import * as moment from 'moment';
import * as session_storage from '../../shared/session_storage_utility';
import * as local_storage from '../../shared/local_storage_utility';
import { toastr_options } from '../../global_constants';
import { toastr } from 'react-redux-toastr';
import { LoaderComponent } from '../../shared/component/loading_component';
import * as global_constants from '../../global_constants';
import * as patient_constants from '../../patient/patient_constants';
import {
    AllowOverrideFormatForDemandClaims, featureFlags
} from '../../company/company_constants';
import { check_company_allow_override_format_for_demand_claims } from '../../company/action/company_action';

class DemandClaimsComponent extends React.Component<any, any> {
    [x: string]: any;
    patient_id: any = null;
    constructor(props) {
        super(props);
        // Define initial state value.
        this.state = {
            is_loading: false,
            is_modal_screen_loading: false,
            show_demand_payer_group_modal: false,
            show_template_overlay_checkbox: false,
            is_searched: false,
            search_criteria : {
                format_overlay_id: "",
                format: "",
                insurance: "",
                mode: "DEMAND",
                bill_type: constants.demand_claims.bill_types[0].value,
                date_of_service: {
                    from_date: null,
                    to_date: null
                }
            },
            payer_group: null, 
            show_render: true,
            show_pending: false,
            view_template_overlay: false,
            insurances: [],
            grid_conf: {
                isPagination: true,
                gridAutoHeight: true,
                selectionType: 'single',
                paginationPageSize: 20,
            },
            error: {}
        };
    }
    token: string = '';
    page_metadata: any;
    _is_mounted = false;
    modes = constants.demand_claims.mode_types;
    formats = [];
    default_formats;
    payer_groups = [];
    bill_types = constants.demand_claims.bill_types;
    selected_claims= [];
    total_column_width: number = 0;
    grid_header_height: number = 0;
    code_id: number = 0;
    width_padding: number = 32;
    row_data = [];
    footer_object:any = [];
    pdf_counter: number=0;    
    report_date = moment().format("MM/DD/YYYY hh:mm:ss A").toString();    

    // Fetching the page_name and state.
    UNSAFE_componentWillMount = () => {
        this.get_page_metadata();
    };

    //Use to fetch params and page metadata
    get_page_metadata = () => {
        this.patient_id = session_storage.get('active_patient');
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        //Verifying page_name is correct or not.
        this.page_metadata = constants.demand_claims;

        // getting the old session in case of reload
        this.demand_claims_search_criteria = session_storage.get('demand_claims_search_criteria');
        if (this.demand_claims_search_criteria) {
            if(this.demand_claims_search_criteria.date_of_service.from_date) {
                this.demand_claims_search_criteria.date_of_service.from_date = moment(this.demand_claims_search_criteria.date_of_service.from_date);
            }
            if(this.demand_claims_search_criteria.date_of_service.to_date) {
                this.demand_claims_search_criteria.date_of_service.to_date = moment(this.demand_claims_search_criteria.date_of_service.to_date);   
            }
            this.setState({ search_criteria: this.demand_claims_search_criteria }, () => {
                this.search_handler({});
            })
        } 

        this.setState({
            show_render: true
        });
        
    };

    componentDidMount = () => {
        this._is_mounted = true;
        document.body.classList.add('admin-framework');

        if (this.patient_id == '' || this.patient_id == undefined ) {
            session_storage.set('no_patient_selected_for', this.props.location.pathname);
            this.props.history.push(`/no_patient_selected`);
        } else {
            this.get_patient_data();
        }
    }

    //Get Payments NotesData from API function
    componentDidUpdate(prevProps, prevState) {
        
        //change page data if patient changes from header, instead of navigate to other page
        if (prevProps.patient_id && prevProps.patient_id != this.props.patient_id) {
            this.patient_id = session_storage.get("active_patient");
            this.get_patient_data();
            this.clear_handler();
        }
    }

    get_patient_data = () => {
        this.get_patient_insurance(this.patient_id);
        this.refresh_formats();
        this.setState({
            search_criteria : {
                ...this.state.search_criteria,
            }
        });
    }

    refresh_formats = async (show_pending:boolean = false) => {
        this.formats = await this.get_format_list(show_pending);
        if(this.formats.length > 0) {
            this.onChangeDropdownHandler({target: this.formats[0]});
            let search_criteria = this.state.search_criteria;
            search_criteria.format = this.formats[0].value;
            this.setState({
                search_criteria: search_criteria
            });
            let formatList = [];
             this.formats.forEach(element => {
                if (element.text === "Paper 1500 2/12" || element.text === "Paper UB04" ||element.text === "Invoice" ) {
                    formatList.push({ name: element.text, id: element.value });
                }
              });
            this.default_formats = {
                'Requested CMS 1500' : { 'name':'Paper 1500 2/12', id: formatList.find(({ name }) => name === 'Paper 1500 2/12' ).id },
                'Requested UB04' :  { 'name':'Paper UB04', id: formatList.find(({ name }) => name === 'Paper UB04' ).id },
                'Requested Invoice' :  { 'name':'Invoice',  id: formatList.find(({ name }) => name === 'Invoice' ).id },
            };
        }
    }

    
    refresh_payer_groups = async() => {
        this.payer_groups = await this.get_payer_group_list();
    }

    get_payer_group_list = async() => {
        return await constants_actions.get_default_payer_group_by_company(this.token, local_storage.get('auth_data').company_id).then(
            response => {
                var target = [];
                target.push({
                    "text": 'Default',
                    "value": 'Default',
                    "key": '-1',
                    "is_active": 'true'
                });
                if (response.data && response.data.data) {                    
                    for (let i = 0; i < response.data.data.length; i++) {
                        let item = response.data.data[i];
                        target.push({
                            "text": item.payer_group_name,
                            "value":item.payer_group_id,
                            "key": item.payer_group_code,
                            "is_active": item.is_active
                        });
                        
                    }
                    this.setState({
                        search_criteria : {
                            ...this.state.search_criteria,
                            payer_group: target[0].value
                        }
                    })
                } 
                return target;                
            },
            error => {
                return [];
            }
        );
    }

    get_format_list = (show_pending) => {
        const url = this.props.features.ff_sumaj_bill_2467_print_claim_with_line_overlay  
        ? `${this.page_metadata.api.format_form_list.url}?show_pending=${show_pending}`
        : `${this.page_metadata.api.format_list.url}?show_pending=${show_pending}`;

        return constants_actions.get_data(this.token, url).then(
            response => {
                if (response.data && response.data.data) {
                    var target = [];
                    
                    for (let y = 0; y < response.data.data.length; y++) {
                        let item = response.data.data[y];
                        if (this.props.features.ff_sumaj_bill_2467_print_claim_with_line_overlay) {
                            target.push({
                                "text": item.description,
                                "value":item.id,
                                "key": item.code,
                                "is_active": item.is_active,
                                "has_form": item.has_form,
                                "format_overlay_id": item.format_overlay_id
                            });
                            
                        } else {
                            target.push({
                                "text": item.description,
                                "value":item.id,
                                "key": item.code,
                                "is_active": item.is_active
                            })
                        }
                    }
                   
                    return target;                
                } else {
                    return [];
                }
            },
            error => {
                return [];
            }
        );
    }

     // Data formatter
     format_data = (rows, columns) => {
        var footer_obj = this.initialize_footer_obj(this.page_metadata.column_def);
         let patient_id = this.patient_id;
        let formatted_data = rows.map(row_data => {
            columns.filter(col_data => {
                switch (col_data.type) {
                    case 'currency':
                        footer_obj[col_data.field] += row_data[col_data.field] ? parseFloat(row_data[col_data.field]) : 0;
                        row_data[col_data.field] =
                            row_data[col_data.field] != null || row_data[col_data.field] != undefined ? constants.currencyFormatter(row_data[col_data.field]) : constants.currencyFormatter(0);
                        break;
                    case 'boolean':
                        row_data[col_data.field] = row_data[col_data.field] ? true : false;
                        break;
                    case 'number':
                        footer_obj[col_data.field] += row_data[col_data.field] ? parseInt(row_data[col_data.field]) : 0;
                        row_data[col_data.field] = row_data[col_data.field];
                        break;
                    case 'string':
                        row_data[col_data.field] = row_data[col_data.field];
                        break;
                    case 'date':
                        row_data[col_data.field] = row_data[col_data.field] ? moment(row_data[col_data.field]).format("MM/DD/YYYY") : null;
                        break;
                    case 'static':
                        if(col_data.field == "patient_id") {
                            row_data[col_data.field] = patient_id;
                        }
                        if(col_data.field == "payer_group") {
                            row_data[col_data.field] = row_data[col_data.field];
                        }

                        break;
                    default:
                        row_data[col_data.field] = row_data[col_data.field];
                }
            });
            return row_data;
        });

        this.page_metadata.column_def.map((value, index) => {
            if (value.type == 'currency') {
              footer_obj[value.field] = constants.currencyFormatter(footer_obj[value.field]);
            }
          });
    
        this.footer_object = [footer_obj];

        return formatted_data;
    };

    //Function calls after click of filter button and recalculate the footer sum.
    on_filter_button_click = () => {
        var footer_obj = this.initialize_footer_obj(this.page_metadata.column_def);
        var cell_data;
        var row_api = this.state.grid_params.api;
        if (row_api) {
            row_api.forEachNodeAfterFilter((row, index) => {
                row = row.data;
                this.page_metadata.column_def.map((value, index) => {
                    switch (value.type) {
                        case 'currency':
                            if (row[value.field].indexOf('(') != -1) {
                                cell_data = row[value.field] ? '-' + row[value.field].substring(2, row[value.field].length - 1) : 0;
                            } else {
                                cell_data = row[value.field] ? row[value.field].substring(1, row[value.field].length) : 0;
                            }
                            footer_obj[value.field] += isNaN(parseFloat(cell_data)) ? 0 : parseFloat(cell_data.split(',').join(''));
                            break;
                        default:
                    }
                });
            });

            this.page_metadata.column_def.map((value, index) => {
                if (value.type == 'currency') {
                    footer_obj[value.field] = constants.currencyFormatter(footer_obj[value.field]);
                }
            });
            row_api.setPinnedBottomRowData([footer_obj]);
        }
    };
    
    // validate the form with values
    validateForm = () => {
        const { date_of_service } = this.state.search_criteria;

        let has_error = false;
        let error = {
            from_date: false,
            to_date: false
        }

        if (! date_of_service.from_date) {
            has_error = true;
            error.from_date = true;
        }

        if (! date_of_service.to_date) {
            has_error = true;
            error.to_date = true;
        }

        if (has_error) {
            toastr.error('', global_constants.constants.mandatory_fields);
            if (this._is_mounted) {
                this.setState({
                    error: error
                });
            }

            return false;
        }

        return true
    }

    get_date = (date) => {
        if (date) {
            return moment(date).format("MM/DD/YYYY 00:00:00").toString();
        }
        return '';
    }

    search_handler = async (e) => {
        if (this._is_mounted) {
            this.setState({
                is_searched: true 
            });
        }

        if ( ! this.validateForm()) return;

        const { insurance, date_of_service, bill_type } = this.state.search_criteria;
        let ins = insurance == "" || insurance == "-999" ? 0 : insurance;
        let params = {
            Patient_id: this.patient_id,
            Ins_code: ins,
            Dos_from: date_of_service.from_date ? this.get_date(date_of_service.from_date): "1990-01-01",
            Dos_to: date_of_service.to_date ? this.get_date(date_of_service.to_date) : "1990-01-01",
            Bill_type: bill_type
        };
        this.report_date = moment().format("MM/DD/YYYY hh:mm:ss A").toString();
        this.setState({
            is_loading: true,
            show_grid: false
        });
        this.selected_claims = [];
        this.row_data = [];

        await search_demand_claims(this.token, params).then(
            (response) => {
                if (response.data) {
                    this.row_data = response.data.data ? response.data.data : [];
                    this.row_data = this.format_data(this.row_data, this.page_metadata.column_def);
                    const grid_height = get_columns(this.row_data, this.page_metadata.column_def, {});
                    this.total_column_width = grid_height.total_column_width;
                    this.grid_header_height = grid_height.header_height;

                    this.setState({
                        show_grid: true,
                        is_loading: false,
                        grid_rows_count: this.row_data && this.row_data.length
                    });
                    session_storage.set('demand_claims_search_criteria', {
                            patient_id: params.Patient_id,
                            insurance: params.Ins_code,
                            bill_type: params.Bill_type,
                            date_of_service: {
                                from_date: params.Dos_from,
                                to_date: params.Dos_to
                            }
                    });
                } else {
                    if (response.data.messages && response.data.messages[0].message.indexOf("<br") > -1) {
                        let toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                        toastr.error("", toastr_options);
                    }
                    this.setState({
                        is_loading: false
                    });
                  }
            },
            error => {
                this.setState({
                    is_loading: false
                });
                if (error.response.data) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toastr.error("", toastr_options);
                }
            })
    }


    //Function used to initialize the footer object.
  initialize_footer_obj = column_def => {
    var footer_obj = {};
    column_def.map((value, index) => {
        if (index == 0) {
          footer_obj[value.field] = 'Total';
        } else if (value.type == 'currency' || value.type == 'number') {
          footer_obj[value.field] = 0;
        } else {
          footer_obj[value.field] = null;
        }      
    });
    return footer_obj;
  };


    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }

    get_patient_insurance = (patient_id) => {
        get_patient_insurance(patient_id, this.token).then(res => {
            if (res.data.data && this._is_mounted) {
                this.setState({
                    insurances: this.format_patient_insurance(res.data.data)
                }) 
            }
        })
    }

    // Select open claims to close 
    on_check_box_selection = (items) => {
        this.selected_claims = items;
    };

    format_patient_insurance = (source) => {
        var target = [{ "text": 'Select', "value": "-999", "key": "", "ins_code_id":"" }];

        var source_length = source.length;

        if (source && source_length > 0) {
            for (let y = 0; y < source_length; y++) {
                let item = source[y];
                target.push({
                    "text": item.ins_desc ,
                    "value": item.pst_ins,
                    "key": item.ins_desc,
                    "ins_code_id": item.ins_code
                })
            }
        }

        return target;
    }

    on_batch_claim_modal_button_clicked = async () => {
        let selected_claims = this.selected_claims;
        if (selected_claims.length <= 0) {
            toastr.warning('', 'No charges selected.');
            return;
        }
        
        let claims = selected_claims.map(claim => {
            return claim.charge_id;
        });

        this.setState({
            is_loading: true
        });

        if (this.state.search_criteria.payer_group === 'Default') {
            let params = {
                bill_type: this.state.search_criteria.bill_type,
                company_id: local_storage.get('auth_data').company_id
            };
            await this.update_batch_claims_process(params, claims);
        } else {
            let selected_payer_group = this.payer_groups.find(o => o.value == this.state.search_criteria.payer_group);
            let selected_format = this.default_formats[selected_payer_group.text].id;

            let params = {
                list_charges_id: claims,
                format_id: selected_format,
                payer_group_id: selected_payer_group.value,
                is_status_update: 0
            };
            let run_type = 'manual_run_allow_override';
            await add_batch_billing(this.token, run_type, params).then(
                (response: { data: { status: number; messages: { message: any }[] } }) => {
                    if (response.data && response.data.status === 1) {
                        let toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                        toastr.success("", toastr_options);
                        this.setState({
                            show_grid: false,
                            is_loading: false,
                            grid_rows_count: 0,
                            show_demand_payer_group_modal: false,
                            show_pending: false,
                        });
                        this.selected_claims = [];
                    } else {
                        if (response.data.messages && response.data.messages[0].message) {
                            let toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                            toastr.error("", toastr_options);
                        }
                        this.setState({
                            is_loading: false,
                            show_demand_payer_group_modal: false,
                            show_pending: false,
                        });
                    }
                },
                error => {
                    this.setState({
                        is_loading: false
                    });
                    if (error.response.data) {
                        const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                        toastr.error("", toastr_options);
                    }
                });
        }

    }

    on_batch_claim_clicked = async () => {

        var selected_claims = this.selected_claims;

        if (selected_claims.length <= 0) {
            toastr.warning('', 'No charges selected.');
            return;
        }
        let claims = selected_claims.map(claim => {
            return claim.charge_id;
        })

        this.setState({
            is_loading: true
        });

        let params = {
            bill_type: this.state.search_criteria.bill_type,
            company_id: local_storage.get('auth_data').company_id
        };

        if (this.props.features.ff_sumaj_b32093_allow_override_format_for_demand_claims) {
            await this.refresh_payer_groups();
            await this.update_batch_claims_payer_group(params, claims);
        } else {
            await this.update_batch_claims_process(params, claims);
        }
    };

    update_batch_claims_payer_group = async (params, claims) => {
        await check_company_allow_override_format_for_demand_claims(this.token, params).then(
            (response) => {
                if (response.data.data && response.data.status === 1) {
                    this.setState({
                        show_demand_payer_group_modal: true,
                        is_loading: false
                    });
                } else {
                    this.update_batch_claims_process(params, claims);
                }
            });
    };

    update_batch_claims_process = async (params, claims) => {
        await update_batch_claims(this.token, params, claims).then(
            (response) => {
                if (response.data && response.data.status == 1) {
                    let toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                    toastr.success("", toastr_options);

                    this.setState({
                        show_grid: false,
                        is_loading: false,
                        grid_rows_count: 0,
                        show_demand_payer_group_modal: false,
                        show_pending: false,
                    });
                    this.selected_claims = [];

                } else {
                    if (response.data.messages && response.data.messages[0].message) {
                        let toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                        toastr.error("", toastr_options);
                    }
                    this.setState({
                        is_loading: false
                    });
                }
            },
            error => {
                this.setState({
                    is_loading: false
                });
                if (error.response.data) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toastr.error("", toastr_options);
                }
            })
    };

    invoke_get_claim(mode) {
        var selected_claims = this.selected_claims;
        
        if(selected_claims.length <=0) {  
            toastr.warning('', 'No charges selected.');
            return;
        }

        if (this._is_mounted) {
            this.setState({ is_modal_screen_loading: true });
        }

        let claims = selected_claims.map(claim => {
            return claim.charge_id;
        })

        const { insurance, format,bill_type, format_overlay_id } = this.state.search_criteria;
        let ins = insurance == "" || insurance == "-999" ? 0 : insurance;

        let params = {
            ...(this.props.features.ff_sumaj_bill_2467_print_claim_with_line_overlay) && { format_overlay_id: this.state.view_template_overlay ? format_overlay_id : 0 },
            format_id: format,
            mode: mode,
            ins_code_id : ins,
            bill_type: bill_type
        };

        get_claims_report(params, claims, this.token).then(
            (response) => {                
                this.generate_pdf(response.data.data, 'output_report');

                if (this._is_mounted) {
                    this.setState({
                        is_modal_screen_loading: false
                    });
                }
            },
            error => {
                this.setState({
                    is_modal_screen_loading: false
                });
                if (error.response.data) {
                    const toastr_options = error.response.status === 500 && error.response.data.messages == null ? global_constants.constants.general_support_error : this.show_html_content_toaster(get_all_error(error.response.data));
                    toastr.error("", toastr_options);
                }
            })
    }

    generate_pdf = (data, type) => {
        const pdf_file = new Blob([data], {
          type: 'application/pdf'
        });
        if (pdf_file.size <= 0) {
          if (this._is_mounted) {
            this.setState({ is_modal_screen_loading: false });
          }
          if (type == "scanned_documents") {
            toastr.error(
              "",
              patient_constants.patient_messages.pdf_generation_error
            );
          } else {
            toastr.error("", patient_constants.patient_messages.report_not_found);
          }
          return false;
        } else {
          // For IE
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            if (this._is_mounted) {
              this.setState({
                is_modal_screen_loading: false
              });
            }
            window.navigator.msSaveOrOpenBlob(pdf_file);
          } else {
              const pdf_content = window.URL.createObjectURL(pdf_file);
              var win = open(pdf_content, 'demand_claim'+ this.pdf_counter++, '');
              if (!win) {
                toastr.error('', global_constants.global_messages.popup_blocked);
              } else {
                win.focus();
                win.onload = () => {
                  win.print();
                  window.URL.revokeObjectURL(pdf_content);
                };
              }
              if (this._is_mounted) {
                this.setState({
                  is_modal_screen_loading: false
                });
              }   
          }
        }
      };

    // Conver array buffer to base64
    arrayBufferToBase64(arrayBuffer) {
        return btoa(new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    }

    on_demand_claim_clicked = () => {
        
        let selected_claims = this.selected_claims;
        
        if(selected_claims.length <=0) {  
            toastr.warning('', 'No charges selected.');
            return;
        }

        this.setState({
            is_loading: true
        });
        let claims = selected_claims.map(claim => {
            return claim.charge_id;
        })

        const { insurance, format,bill_type } = this.state.search_criteria;
        let ins = insurance == "" || insurance == "-999" ? 0 : insurance;

        let params = {
            format_id: format,
            mode: "P",
            ins_code_id : ins,
            bill_type: bill_type
        };

        validate_charges(params, claims, this.token).then(
            (response) => {                
                if (response.data && response.data.status == 1) {
 
                    this.setState({
                        show_demand_modal: true,
                        is_loading: false
                    });

                    this.handle_on_open_modal_window();

                } else {

                    if (this._is_mounted) {
                        this.setState({
                            is_loading: false
                        });
                    }

                    if (response.data.messages && response.data.messages[0].message) {
                        let toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                        toastr.error("", toastr_options);
                    }                                       
                }
            },
            error => {
                this.setState({
                    is_loading: false
                });
                if (error.response.data) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toastr.error("", toastr_options);
                }
            })
    }

    close_demand_claims_model_popup = () => {
        this.refresh_formats(); // refreshing format to the default state
        this.setState({
            show_demand_modal: false,
            show_pending: false,
        }); 
        set_focus_on_element_with_id("demand_claim_modal_button");  
    }

    onChangeDropdownHandler = e => {
        const { value, name } = e.target;
        let { search_criteria } = this.state;
        search_criteria[name] = value;

        if (this.props.features.ff_sumaj_bill_2467_print_claim_with_line_overlay) {
            let format = this.formats.find(f => f.value.toString() === value.toString());
            if (format && format.has_form !== null) {
                let show_overlay_checkbox = format && format.has_form;
                search_criteria.format_overlay_id = format.format_overlay_id;

                this.setState({
                    search_criteria: search_criteria,
                    show_template_overlay_checkbox: show_overlay_checkbox,
                    view_template_overlay: false
                });
            } else {
                this.setState({
                    search_criteria: search_criteria
                });
            }
        } else {
            this.setState({
                search_criteria: search_criteria
            });
        }
    }

    handleDateChange = (startDate, endDate) => {
        let error = this.state.error;
        if( startDate || endDate) {
            let { search_criteria } = this.state;
            let date_of_service = search_criteria.date_of_service;     
            date_of_service = {
                from_date: startDate, 
                to_date: endDate
            };
            search_criteria.date_of_service = date_of_service;

            if (this.state.is_searched) {
                if (startDate == null) {
                    error['from_date'] = true;
                } else {
                    error['from_date'] = false;
                }
                if (endDate == null) {
                    error['to_date'] = true;
                } else {
                    error['to_date'] = false;
                }    
            } else {
                error['to_date'] = false;
                error['from_date'] = false;
            }

            this.setState({
                error: error,
                search_criteria:search_criteria
            });
        }
    }

    update_report_rows_count = () => {
        this.setState({
            grid_rows_count: this.state.grid_params.api.getModel().getRowCount()
        });
    };

    on_grid_out = () => {
        set_focus_on_element_with_id('app_header_dropdown');
    };

    //Function calls on initialization of Ag-Grid and catch its reference.
    get_grid_ref = grid_params => {
        this.setState({
            grid_params,
            grid_rows_count: grid_params.api.getModel().getRowCount()
        });
        var grid_width = document.getElementsByClassName('admin-wrapper')[0].clientWidth;
        if (this.total_column_width > (grid_width - this.width_padding)) {
            this.page_metadata.column_def.filter(item => {
                grid_params.columnApi.setColumnWidth(item.field, item.width, false);
            });
        }
    };
    handle_on_open_modal_window = () => {
        setTimeout(() => {
            let closeIcons: NodeListOf<HTMLElement> = document.querySelectorAll('i.close.icon') as NodeListOf<HTMLElement>;
            let closeIcon = closeIcons[closeIcons.length - 1]
            closeIcon.tabIndex = 0;
            closeIcon.id = "demand_claim_model_close_button";
            closeIcon.addEventListener("keydown", function (event) {
                let code = event.keyCode || event.which;

                if (event.shiftKey && code == 9) {
                    event.preventDefault();
                    set_focus_on_element_with_id("demand_claim_button");
                }

                if (code == 13) {
                    event.preventDefault();
                    var el = event.target as HTMLElement
                    el.click();
                }
            });
            set_focus_on_element_with_id("Format_id_dropdown"); 
        }, 200)
    }


    //Reset button the search control.
    clear_handler = () => {
        this.setState({
            error:{},
            is_searched: false,
            show_grid: false,
            show_demand_modal: false,
            show_pending: false,
            payer_group: null,
            grid_rows_count: 0,
            search_criteria : {
                insurance: "",
                format: "",
                mode: "DEMAND",
                bill_type: constants.demand_claims.bill_types[0].value,
                date_of_service: {
                    from_date: null,
                    to_date: null
                }
            }
        });

        this.selected_claims = [];

        session_storage.remove('demand_claims_search_criteria');
    };

    close_demand_claims_payer_group_model_popup = () => {
        this.setState({
            show_demand_payer_group_modal: false,
            show_pending: false,
        });
    };

    componentWillUnmount = () => {
        document.body.classList.remove('admin-framework');
        this.is_mounted = false;
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[2] !== 'demand_claims' && session_storage.remove('demand_claims_search_criteria');
        }
    };
    //It renders report grid and its search controls.
    render() {
        const { insurances, show_pending, is_loading, error, show_demand_modal, grid_rows_count, show_render, show_grid, is_searched, view_template_overlay } = this.state;
        const { insurance, format, mode, bill_type, date_of_service } = this.state.search_criteria;

        return (
            <React.Fragment>               
                {this.state.show_demand_modal && 
                        <Modal
                        onClose={this.close_demand_claims_model_popup}
                        centered={false}
                        className="default-modal"
                        open={this.state.show_demand_modal}
                        closeIcon
                        closeOnDimmerClick={false}
                        id="demand_claim_modal_popup"
                    >
                        <Modal.Header>Demand Claim - Format</Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                <Dimmer active={this.state.is_modal_screen_loading}>
                                    <Loader size='massive'>Loading</Loader>
                                </Dimmer>
                                <div className='common-forms' style={{ padding: '0' }}>
                                        <Form>
                                            <Grid>                                            
                                                <Grid.Row columns={2} className="margin-zero padding-zero">
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <label>Format</label>
                                                            <Selection
                                                                id='Format_id_dropdown'
                                                                name='format'
                                                                defaultValue={format}
                                                                options={this.formats}
                                                                onChange={(value, e) => {
                                                                    this.onChangeDropdownHandler(e);
                                                                }}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column mobile={16} tablet={8} computer={10} verticalAlign={'middle'} textAlign={'left'}>
                                                        <Form.Group inline>
                                                        <Form.Field style={{ marginTop: '32px' }}>             
                                                            <Checkbox label='Show Pending' checked={show_pending} 
                                                            onChange={() => {
                                                                    this.setState({show_pending: ! show_pending})
                                                                    this.refresh_formats( ! show_pending);
                                                                } 
                                                            } />
                                                        </Form.Field>
                                                        {this.props.features.ff_sumaj_bill_2467_print_claim_with_line_overlay && this.state.show_template_overlay_checkbox && (
                                                        <Form.Field style={{ marginTop: '32px' }}>
                                                            <Checkbox label='View With Template Overlay' checked={view_template_overlay}
                                                            onChange={() =>{this.setState({view_template_overlay: ! view_template_overlay})}
                                                            } />
                                                        </Form.Field>)}
                                                        </Form.Group>
                                                    </Grid.Column>
                                                </Grid.Row>                                               
                                            </Grid>
                                        </Form>
                                    <Grid>
                                        <Grid.Column computer={16} style={{ textAlign: 'right' }} className="mar-t-15 mar-b-15">                                            
                                            <Button basic onClick={this.close_demand_claims_model_popup}>Cancel</Button>
                                            <Button
                                                id='preview_claim_button'
                                                type='submit'
                                                onClick={() => {
                                                    this.invoke_get_claim("P");
                                                }}
                                                basic>Preview Claim</Button>
                                            <Button
                                                id='demand_claim_button'
                                                type='submit'
                                                onClick={() => {
                                                    this.invoke_get_claim("D");
                                                }}
                                                onKeyDown={(event) => handle_focus_on_tab(event, "demand_claim_model_close_button")}
                                                primary>Demand Claim</Button>
                                        </Grid.Column>
                                    </Grid>
                                </div>
                            </Modal.Description>
                        </Modal.Content>
                    </Modal>
                        }
            {this.props.features.ff_sumaj_b32093_allow_override_format_for_demand_claims && this.state.show_demand_payer_group_modal && (
                    <Modal
                        onClose={this.close_demand_claims_payer_group_model_popup}
                        centered={false}
                        className="default-modal"
                        open={this.state.show_demand_payer_group_modal}
                        closeIcon
                        closeOnDimmerClick={false}
                        id="demand_claim_modal_popup"
                    >
                        <Modal.Header>Demand Claim - Payer Group</Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                <Dimmer active={this.state.is_modal_screen_loading}>
                                    <Loader size='massive'>Loading</Loader>
                                </Dimmer>
                                <div className='common-forms' style={{ padding: '0' }}>
                                    <Form>
                                        <Grid>
                                            <Grid.Row columns={1} className="margin-zero padding-zero">
                                                <Grid.Column tablet={8} computer={4}>
                                                    <Form.Field>
                                                        <label>Payer Group</label>
                                                        <Selection
                                                            id='payer_group'
                                                            name='payer_group'
                                                            defaultValue={this.state.search_criteria.payer_group}
                                                            options={this.payer_groups}
                                                            onChange={(value, e) => {
                                                                this.onChangeDropdownHandler(e);}
                                                            }
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Form>
                                    <Grid>
                                        <Grid.Column computer={16} style={{ textAlign: 'right' }} className="mar-t-15 mar-b-15">
                                            <Button basic onClick={this.close_demand_claims_payer_group_model_popup}>Cancel</Button>
                                            <Button
                                                id='allow_override_add_manual_run_batch_claim_button'
                                                type='submit'
                                                onClick={this.on_batch_claim_modal_button_clicked}
                                                onKeyDown={() => { }}
                                                primary>Batch Claim</Button>
                                        </Grid.Column>
                                    </Grid>
                                </div>
                            </Modal.Description>
                        </Modal.Content>
                    </Modal>
            )}
            {this.state.show_render && (
            <LoaderComponent loading={is_loading} >
                    <div className={'admin-wrapper '} style={!this.state.show_grid ? { paddingBottom: 20 } : {}}>
                        <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                            <Grid.Column computer={16}>
                                <h3 className='ui header left aligned' dangerouslySetInnerHTML={{ __html: this.page_metadata.search_header }} />
                            </Grid.Column>
                        </Grid>
                      
                        <div id='admin-scrollable-area' className='wrapper' style={this.state.show_grid ? { flex: 1 } : {}}>
                            <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container'>
                                <Form autoComplete='off' onSubmit={e => this.search_handler(e)}>
                                    <Grid>                                     
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Insurance</label>
                                                <Selection
                                                    id='insurance_id'
                                                    name='insurance'
                                                    defaultValue={insurance}
                                                    options={insurances}
                                                    onChange={(value, e) => {
                                                        this.onChangeDropdownHandler(e);                                                        
                                                    }}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Date of Service{' '}
                                                    {
                                                        <span className={is_searched && (error.from_date || error.to_date) ? 'req-alert' : 'req-alert_normal'}>
                                                            (required)
                                                        </span>
                                                    }
                                                </label>
                                                <DateRangePickerComponent
                                                    updateDatesChange={this.handleDateChange}
                                                    startDate={date_of_service.from_date}
                                                    endDate={date_of_service.to_date}
                                                    error={is_searched && (error.from_date || error.to_date)}
                                                    maxDate={moment()}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Bill Type</label>
                                                <Selection
                                                    id='bill_type_id'
                                                    name='bill_type'
                                                    defaultValue={bill_type}
                                                    options={this.bill_types}
                                                    onChange={(value, e) => {
                                                        this.onChangeDropdownHandler(e);
                                                    }}
                                                />
                                            </Form.Field>
                                        </Grid.Column>                                       
                                    </Grid>                                   
                                    <Grid style={{ margin: '0 -17px' }}>
                                        <Grid.Column computer={16} textAlign='right'>
                                            <Button id='clear_button_id' type='button' onClick={this.clear_handler} basic>Clear</Button>
                                            <Button id='search_button_id' type='submit' disabled={is_loading==true} primary>Search</Button>
                                        </Grid.Column>
                                    </Grid>
                                    {this.state.show_grid && !is_loading && (
                                    <Grid className='dateTime'>
                                        <Grid.Column tablet={5} computer={4} textAlign='left'>
                                            <p style={{ fontSize: '16px', minHeight: 22 }}>{this.report_date}</p>
                                        </Grid.Column>
                                        <Grid.Column tablet={5} computer={8} textAlign='center'>
                                            {
                                                <p style={{ fontSize: '16px', minHeight: 22 }}>
                                                    {`${grid_rows_count} ${grid_rows_count == 1 ? ' record shown' : ' records shown'
                                                        }`}
                                                </p>
                                            }
                                        </Grid.Column>
                                        <Grid.Column tablet={2} computer={4} textAlign='right' />
                                    </Grid>
                                )}
                                </Form>
                            </div>  
                            {this.state.show_grid && (
                                <GridView
                                    id={`id_grid_demand_claims`}
                                    row={this.row_data}
                                    column={this.page_metadata.column_def}
                                    style={{ height: '100%' }}
                                    wrapperStyle={{ width: '100%', height: '100%', display: 'flex' }}
                                    suppressMovableColumns={false}
                                    checkboxSelection={true}
                                    onRowSelection={(item) => this.on_check_box_selection(item)}
                                    enableColResize={true}
                                    onRowDoubleClicked={this.on_row_double_clicked}
                                    selectionType={'single'}
                                    get_grid_ref={this.get_grid_ref}
                                    suppressSizeToFit={true}
                                    headerHeight={this.grid_header_height}
                                    headerIdForTabNavigation={constants.search_grid_id}
                                    onForceGridOut={this.on_grid_out}
                                    on_filter_button_click={() => {
                                            this.update_report_rows_count()
                                            this.on_filter_button_click();
                                        }
                                    }
                                    handleCheckboxNavigation={true}
                                    isRowSelectable={
                                        (rowNode) => {
                                            return rowNode.data ? rowNode.data.status_desc == "Ready" : false;
                                        }
                                    }
                                    pinnedBottomRowData={this.footer_object}                                    
                                />
                            )}                          
                        </div>
                        {this.state.show_grid && (
                            <div
                                className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                                id='applicationFooterSticky'
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                                <Grid.Column computer={16} textAlign='right'>
                                    <Button
                                        id='batch_claim_button'
                                        type='submit'
                                        onClick={this.on_batch_claim_clicked}
                                        basic>Batch Claim</Button>
                                    <Button
                                        onKeyDown={set_focus_to_app_header}
                                        id='demand_claim_modal_button'
                                        type='submit'
                                        onClick={this.on_demand_claim_clicked}
                                        primary>Demand Claim</Button>
                                </Grid.Column>
                            </div>
                        )}

                    </div>            
                </LoaderComponent>)
                }
                </React.Fragment>

        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        get_patient_search: get_patient_search
    }, dispatch)
}

//Get user and login details from store.
const mapStateToProps = state => {
    let patient_details = state.patient_details;

    return {
        user_login_details: state.user_login_details,
        patient_id: patient_details && patient_details.patient_header && patient_details.patient_header.data && patient_details.patient_header.data.id || '',
        features: {
            ff_sumaj_b32093_allow_override_format_for_demand_claims: get_launch_darkly_key_value(state.launch_darkly, AllowOverrideFormatForDemandClaims.sumajB32093AllowOverrideFormatForDemandClaims),
            ff_sumaj_bill_2467_print_claim_with_line_overlay: get_launch_darkly_key_value(state.launch_darkly, featureFlags.sumajbill2467printclaimwithlineoverlay)
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DemandClaimsComponent);