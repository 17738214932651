import * as React from 'react';
import { Accordion, Button, Checkbox, Dimmer, Form, Grid, Icon, Loader, Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { Position, Drawer } from '@blueprintjs/core';
import { toastr as toaster } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom'

import { advanced_search_class } from '../../../shared/action/shared_action';
import * as billingPaneConstants from '../provider_billing_pane_constants';
import * as constants from '../constants';
import * as constants_actionss from '../action/constants_action';
import * as constants_actions from '../action/constants_action';
import * as global_constants from '../../../global_constants';
import * as local_storage from './../../../shared/local_storage_utility';
import * as session_storage from '../../../shared/session_storage_utility';
import { check_valid_npi, is_null_or_white_space, is_user_partner_customer_and_company_revEquip_revServe } from '../../../shared/utility';
import ClaimHoldsAddEditDrawerComponent from './claimholds_add_edit_drawer_component';
import { ConcreteStrategyClaimHoldNameUnique } from './providerBilling/provider_billing_concrete_setting_name_unique';
import { ConcreteStrategyOnlyLocationInGroups, ConcreteStrategyOnlyProviderInGroups, ConcreteStrategySingleDataInGroups, ConcreteStrategySingleDataInGroupsLocationClaimHold } from './providerBilling/provider_billing_concrete_data_in_groups';
import DrawerComponent from '../../../shared/component/drawer_component';
import { get_insurance_codes } from '../action/constants_action';
import ProviderBillingDisplayRowComponent from './provider_billing_display_row_component';
import ProviderBillingHeaderComponent from './provider_billing_header_component';
import { ProviderBillingValidator } from './providerBilling/provider_billing_validator';
import { option_formater as option_formatter } from '../constants';
import { get_all_error, zip_code_formation } from '../../../shared/utility';
import { handle_click_on_enter, set_focus_on_element_with_id, set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import Selection from '../../../shared/component/selection_component';
import { PromptNavigation } from '../../../shared/component/prompt_navigation_component';
import { ConcreteStrategyDataRestrictionAll } from './providerBilling/provider_billing_concrete_data_restriction_all';
import { ConcreteCombinationsLimit } from './providerBilling/provider_billing_concrete_combinations_limit';
import { is_alpha_numeric } from '../utility';

class ClaimHoldsAddEditComponent extends React.Component<any, any> {
    _is_mounted = false;
    apiPromisesCollector: Promise<string>[] = [];
    billing_selectedItems: any;
    claim_hold_selectedItems: any;
    code_id: Number = 0;
    company_service_type: any;
    current_user_type: any;
    disable_fields = true;
    getLocationDataPromise: Promise<string>;
    getLocationCodePromise: Promise<string>;
    getLocationClaimsListPromise: Promise<string>;
    getLocationsClaimsListPromiseResolve: any;
    getLocationClaimsListPromiseResolve: any;
    getProviderClaimsListPromiseReject: any;
    go_next: boolean = false;
    is_new_claim_button_disable: boolean = false;
    incomingPayload: any;
    initial_form_object: any = {};
    page_metadata: any;
    retrievePendingReasonPromise: Promise<string>;
    retrievePendingReasonPromiseReject: any;
    retrievePendingReasonPromiseResolve: any;
    retrieveProvidersAndInsurancesPromise: Promise<string>;
    retrieveProvidersAndInsurancesPromiseReject: any;
    retrieveProvidersAndInsurancesPromiseResolve: any;
    rowNodesForClaims: any;
    token: string = '';
    validator: any = new ProviderBillingValidator();

    //Billing data variable
    claim_model: any = {
        claim_hold_name: '',
        claim_delay_reason_id: '31',
        start_date: null,
        end_date: null,
        claim_hold_id: 0,
        location_id: 0,
        location_ids: [],
        provider_ids: [],
        insurance_class: '',
        insurance_class_ids: [],
        insurance_code: '',
        insurance_code_ids: [],
        not_used_for_billing: false,
        status: true,
        hasChanges: false
    };

    form_object: any = {
        address1: '',
        city: '',
        dont_show_in_lookups: false,
        fax: '',
        is_active: true,
        phone: '',
        pos_code: '',
        pos_name: '',
        practice_id: 0,
        state: '',
        zip: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            by_pass_prompt: false,
            claim_delay_reason: [],
            claim_hold_col_fields: this.retrieveProviderClaimHoldsColumns(),
            claim_hold_rows: [],
            claim_hold_rows_original: [],
            claimTabId: '',
            controls_is_expended: {
                location: true,
                claim: true,
                billing: true
            },
            error: {},
            insuranceClasses: [],
            insuranceCodes: [],
            isClaimDrawerOpen: false,
            is_loading: true,
            is_save_button_disabled: false,
            is_submitted: false,
            isSaveInProgress: false,
            locationClaimHold: {
                originalRowData: {},
                originalRowIndex: {}
            },
            openPanelMode: {
                isModeEditable: false,
                isModeCreate: false
            },
            patient_master_data: this.props.user_login_details.formated_master_data,
            providers: [],
            provider_search: [],
            required: {},
            required_error: {},
            required_text_fields: {},
            show_inactive_claim: false,
            show_render: true,
            system_setting_required: {},
            unsaved_claim_rows: [],
        };
        this.billing_selectedItems = [];
        this.claim_hold_selectedItems = [];
        this.rowNodesForClaims = [];
    }

    UNSAFE_componentWillMount = () => {
        this._is_mounted = true;
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.code_id = this.props.match.params && this.props.match.params.id ? this.props.match.params.id : 0;
        let auth_data = local_storage.get("auth_data");

        //Verifying page_name is correct or not.
        this.page_metadata = constants.locations;
        this.initial_form_object = { ...this.form_object };
        this.generate_first_calls_promises();

        if (auth_data && auth_data.user_type_r3_id) {
            this.current_user_type = auth_data.user_type_r3_id;
            this.company_service_type = auth_data.company_service_type;
        }
        if (
            is_user_partner_customer_and_company_revEquip_revServe(this.company_service_type, this.current_user_type)
        ) {
            this.is_new_claim_button_disable = true;
        }

        this.setState({
            is_loading: true
        });
    };

    componentDidMount = () => {
        this._is_mounted = true;
        document.body.classList.add('admin-framework');

        this.retrievePendingReasons();

        this.get_location_card_practice_select_data();

        if (this.code_id) {
            this.get_location_card_data(this.code_id);
        } else {
            if (this._is_mounted) {
                this.setState({
                    form_object: this.form_object   
                });
            }
        }

        if (this.code_id > 0) {
            this.get_location_claim_list(this.code_id);
        }
        this.retrieveProvidersAndInsurancesForMultiTab();

        Promise.all(this.apiPromisesCollector).then(() => {
            this.setState({
                is_loading: false
            });
        });
    };

    componentWillUnmount = () => {
        document.body.classList.remove('admin-framework');
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[1] !== this.page_metadata.route && session_storage.remove(this.page_metadata.storage_key);
        }
        session_storage.remove('location_claim_data_loaded');
    };

    accordion_toggle = (type: React.ReactText) => {
        if (this._is_mounted) {
            this.setState((prev_state) => ({
                controls_is_expended: {
                    ...prev_state.controls_is_expended,
                    [type]: !prev_state.controls_is_expended[type]
                }
            }));
        }
    };

    claim_hold_from_drawer_callback = (incomingPayload) => {
        this.setState({
            isClaimDrawerOpen: false
        });
        let old_data = this.get_all_updated_claim_data();

        if (this.state.openPanelMode.isModeEditable) {
            incomingPayload.hasChanges = this.state.openPanelMode.isModeEditable;
            old_data[this.state.locationClaimHold.originalRowIndex] = incomingPayload;
            this.claim_hold_selectedItems[this.state.locationClaimHold.originalRowIndex] = {
                providers: incomingPayload.provider_ids,
                insuranceClass: incomingPayload.insurance_class_ids,
                insuranceCode: incomingPayload.insurance_code_ids
            };
        }
        if (this.state.openPanelMode.isModeCreate) {
            old_data.push({ ...incomingPayload });
            this.claim_hold_selectedItems[old_data.length - 1] = {
                providers: incomingPayload.provider_ids,
                insuranceClass: incomingPayload.insurance_class_ids,
                insuranceCode: incomingPayload.insurance_code_ids
            };
            this.setState((state) => ({
                unsaved_claim_rows: [...old_data, old_data.length - 1]
            }));
        }

        this.setState((state) => ({
            claim_hold_rows: old_data,
            claim_hold_rows_original: [...old_data]
        }));
        session_storage.set('location_claim_data_loaded', old_data);
    }

    determine_required_fields = () => {

        let required_text_fields: any = {
            pos_name: true,
            pos_code: true,
            address1: true,
            city: true,
            state: true,
            zip: true,
            practice_id: true
        };

        if (this.form_object.pos_identifier && this.form_object.pos_identifier == '12') {
            required_text_fields.address1 = false;
            required_text_fields.city = false;
            required_text_fields.state = false;
            required_text_fields.zip = false;
        }

        if (this._is_mounted) {
            this.setState({
                required_text_fields: required_text_fields
            });
        }
    }

    generate_first_calls_promises = () => {
        this.getLocationClaimsListPromise = new Promise((resolve, reject) => {
            this.getLocationsClaimsListPromiseResolve = resolve;
            this.getProviderClaimsListPromiseReject = reject;
        });
        this.retrieveProvidersAndInsurancesPromise = new Promise((resolve, reject) => {
            this.retrieveProvidersAndInsurancesPromiseResolve = resolve;
            this.retrieveProvidersAndInsurancesPromiseReject = reject;
        });
        this.retrievePendingReasonPromise = new Promise((resolve, reject) => {
            this.retrievePendingReasonPromiseResolve = resolve;
            this.retrievePendingReasonPromiseReject = reject;
        });
    };

    get_all_updated_data = () => {
        return this.state.billing_rows;
    }

    get_all_updated_claim_data = () => {
        return this.state.claim_hold_rows;
    }

    get_location_card_data = code_id => {
        let url = this.page_metadata.api.get.url;
        url = url.replace('id_value', code_id);
        if (this._is_mounted) {
            this.setState({
                is_loading: true
            });
        }
        constants_actions.get_data(this.token, url).then(
            async (response) => {
                if (response.data && response.data.data) {
                    this.form_object = { ...response.data.data };
                    this.initial_form_object = { ...this.form_object };
                    if (this._is_mounted) {
                        this.setState({
                            form_object: this.form_object
                        });
                    }

                    this.determine_required_fields();
                }
            },
            (error) => {
                if (this._is_mounted) {
                    this.setState({
                        is_loading: false
                    });
                }
                if (error.response && error.response.data && error.response.data.messages) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error('', toastr_options);
                }
            }
        );
    };

    get_location_card_practice_select_data = () => {
        if (this._is_mounted) {
            this.setState({
                is_loading: true
            });
        }
        constants_actions.get_search_data(this.token, `${this.page_metadata.api.practice.url}`).then(
            res => {
                if (res.data) {
                    if (this._is_mounted) {
                        this.setState({
                            practices: option_formatter(
                                res.data.data,
                                'practice_name',
                                'practice_id',
                                false, // to add blank object
                                false // to shorted object
                            )
                        });
                    }
                }
            },
            error => {
                if (this._is_mounted) {
                    this.setState({
                        is_loading: false
                    });
                }
                if (error.response && error.response.data && error.response.data.messages) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error('', toastr_options);
                }
            }
        );
    };

    get_claimhold_reason = () => {
        let claim_delay_reason = [{
            "key": 0,
            "value": 31,
            "text": "Pending Location Credentialing"
        }];

        this.setState({
            claim_delay_reason: claim_delay_reason
        });
    }

    get_location_claim_list = (code_id: Number) => {
        this.apiPromisesCollector.push(this.getLocationClaimsListPromise);

        const location_claim_data_in_storage = session_storage.get('location_claim_data_loaded');
        if (location_claim_data_in_storage) {
            this.setState({
                claim_hold_rows: location_claim_data_in_storage,
                claim_hold_rows_original: [...location_claim_data_in_storage]
            });
            this.getLocationsClaimsListPromiseResolve('ok');
        }
        else {
            constants_actions.get_location_claim_hold(this.token, this.code_id).then(
                (response) => {
                    this.setState({
                        claim_hold_rows: Array.isArray(response.data.data) ? response.data.data : [],
                        claim_hold_rows_original: Array.isArray(response.data.data) ? [...response.data.data] : []
                    });
                    this.getLocationsClaimsListPromiseResolve('ok');
                    session_storage.set('location_claim_data_loaded', response.data.data);
                },
                (error) => {
                    this.getLocationsClaimsListPromiseResolve('error');
                });
        }
    };

    on_column_moved = (id, cols) => {
        this.setState({
            [`${id}_col_fields`]: cols
        });
    }

    on_sort_changed = (id, rows) => {
        this.setState({
            [`${id}_rows`]: rows
        });
        this[`${id}_selectedItems`] = [];
    }

    on_unsaved_claims = (row_num, is_unsave) => {
        if (is_unsave && this.state.unsaved_claim_rows.indexOf(row_num) == -1) {
            this.setState(state => ({
                unsaved_claim_rows: [...state.unsaved_claim_rows, row_num]
            }))
        } else if (!is_unsave && this.state.unsaved_claim_rows.indexOf(row_num) != -1) {
            this.setState(state => ({
                unsaved_claim_rows: state.unsaved_claim_rows.filter(val => val !== row_num)
            }))
        }
    }

    confirm_close_handler = () => {
        if (this._is_mounted) {
            set_focus_on_element_with_id("save_location_claim_btn");
            this.setState({
                confirm_box_open: false
            });
        }
    };

    cancel_handler = () => {
        let last_report_navigation = local_storage.get('last_report_navigation');
        local_storage.remove('last_report_navigation');
        if (!last_report_navigation) {
            let last_report_navigation = local_storage.get('last_report_navigation');
            local_storage.remove('last_report_navigation');
            if (!last_report_navigation) {
                this.props.history.goBack()
            } else {
                this.props.history.push(last_report_navigation);
            }
        } else {
            this.props.history.push(last_report_navigation);
        }

    };

    confirm_handler = e => {
        this.claim_hold_from_drawer_callback(this.incomingPayload)
        if (this._is_mounted) {
            this.setState({
                confirm_box_open: false
            });
        }
    };

    new_claim_handler = () => {
        this.setState({
            openPanelMode: {
                isModeEditable: false,
                isModeCreate: true
            }
        });
        this.unbindClaimRowData();
        this.handleDrawerClaimOpen();
    };

    render_claim_hold_rows = () => {
        if (this.state.claim_hold_rows && this.state.claim_hold_rows.length > 0) {
            return this.state.claim_hold_rows.map((item, index) => {
                if (this.claim_hold_selectedItems[index] == undefined) {
                    this.claim_hold_selectedItems[index] = {
                        providers: item.provider_ids,
                        insuranceClass: item.insurance_class_ids,
                        insuranceCode: item.insurance_code_ids
                    };
                }
                return this.state.show_inactive_claim ? (
                    <ProviderBillingDisplayRowComponent
                        key={index}
                        data={item}
                        onClickCallbackHandler={this.handleLocationClaimRowClick}
                        onClickBadgeItemHandler={this.onClickClaimBadgeItemHandler}
                        column_fields={this.state.claim_hold_col_fields}
                        row_index={index}
                        providers={this.claim_hold_selectedItems[index].providers}
                        insuranceClass={this.claim_hold_selectedItems[index].insuranceClass}
                        insuranceCode={this.claim_hold_selectedItems[index].insuranceCode}
                        onRef={(ref) => (this.rowNodesForClaims[index] = ref)}
                        on_unsave_billing={this.on_unsaved_claims}
                        disable_elements={this.disable_fields}
                        claim_delay_reason={this.state.claim_delay_reason}
                        row_type={"claim"}
                    />
                ) : (
                    item.status && (
                        <ProviderBillingDisplayRowComponent
                            key={index}
                            data={item}
                            onClickCallbackHandler={this.handleLocationClaimRowClick}
                            onClickBadgeItemHandler={this.onClickClaimBadgeItemHandler}
                            column_fields={this.state.claim_hold_col_fields}
                            row_index={index}
                            providers={this.claim_hold_selectedItems[index].providers}
                            insuranceClass={this.claim_hold_selectedItems[index].insuranceClass}
                            insuranceCode={this.claim_hold_selectedItems[index].insuranceCode}
                            onRef={(ref) => (this.rowNodesForClaims[index] = ref)}
                            on_unsave_billing={this.on_unsaved_claims}
                            disable_elements={this.disable_fields}
                            claim_delay_reason={this.state.claim_delay_reason}
                            row_type={"claim"}
                        />
                    )
                );
            });
        }
        return this.retrieveDefaultProviderClaimHoldRow();
    }

    retrieveDefaultProviderClaimHoldRow = () => {
        const columnsCount = this.retrieveProviderClaimHoldsColumns().length;
        return (
            <tr>
                <td colSpan={columnsCount}>
                    <p className='ui-grid-label-empty'>TEXT</p>
                </td>
            </tr>
        );
    };

    retrieveProviderClaimHoldsColumns = () => {
        return billingPaneConstants.location_claim_holds_columns;
    }

    show_inactive = (e) => {
        const { name, checked } = e.target;
        this.setState({
            [name]: checked
        });
    };

    show_html_content_toaster = msg => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ),
            timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        };
    };

    save_handler = (e, from_pop_up) => {

        this.setState({
            is_loading: true,
            is_save_button_disabled: true,
            is_submitted: true,
            isSaveInProgress: true
        });

        let location_claim_data = this.get_all_updated_claim_data();
        if (this.code_id) {
            let url = this.page_metadata.api.update.url;
            url = url.replace('id_value', this.code_id);
            constants_actions.add_location_claim_hold(this.token, this.code_id, location_claim_data).then(
                response => {
                    if (response.data && response.data.data) {
                        this.go_next = true;
                        toaster.success('', this.page_metadata.validation_message.update);
                        this.initial_form_object = { ...this.form_object };
                        if (this._is_mounted) {
                            this.setState({
                                is_loading: false,
                                by_pass_prompt: !from_pop_up ? true : false,
                                is_save_button_disabled: false
                            });
                        }
                        if (!from_pop_up && this.go_next) {
                            this.go_next = false;
                            let last_report_navigation = local_storage.get('last_report_navigation');
                            local_storage.remove('last_report_navigation');
                            if (!last_report_navigation) {
                                this.props.history.goBack()
                            } else {
                                this.props.history.push(last_report_navigation);
                            }
                        }
                    } else {
                        if (response.data.messages[0].message.indexOf('<br') > -1) {
                            this.show_html_content_toaster(response.data.messages[0].message);
                        } else {
                            toaster.error('', response.data.messages[0].message);
                        }
                        this.go_next = false;
                        if (this._is_mounted) {
                            this.setState({
                                is_loading: false,
                                is_save_button_disabled: false
                            });
                        }
                    }
                },
                error => {
                    if (this._is_mounted) {
                        this.go_next = false;
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false
                        });
                    }
                    if (error.response && error.response.data && error.response.data.messages) {
                        const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                        toaster.error('', toastr_options);
                    }
                }
            );
        }
    }

    private bindClaimRowData = (selectedRowData, selectedRowIndex) => {
        this.setState({
            locationClaimHold: {
                originalRowData: selectedRowData,
                originalRowIndex: selectedRowIndex
            }
        });
    };

    private getClaimHoldDrawerComponent = () => {
        return (
            <ClaimHoldsAddEditDrawerComponent
                save_disable={this.is_new_claim_button_disable}
                saveHandlerCallback={this.locationClaimSaveCallback}
                {...this.state} />
        );
    };

    private handleDrawerClaimClose = () => this.setState({ isClaimDrawerOpen: false });
    private handleDrawerClaimOpen = () => this.setState({ isClaimDrawerOpen: true });

    private handleLocationClaimRowClick = (e, selectedRowData, selectedRowIndex) => {
        this.setState({
            openPanelMode: {
                isModeEditable: true,
                isModeCreate: false
            }
        });
        this.bindClaimRowData(selectedRowData, selectedRowIndex);
        this.handleDrawerClaimOpen();
    }

    private onClickClaimBadgeItemHandler = (itemId) => {
        this.setState({
            claimTabId: itemId
        });
    }

    private locationClaimSaveCallback = (incomingPayload) => {
        this.validator.setStrategy(new ConcreteStrategyClaimHoldNameUnique());
        if (
            !this.validator.isValid([
                incomingPayload,
                this.state.locationClaimHold.originalRowIndex,
                this.state.claim_hold_rows
            ])
        ) {
            toaster.error('', billingPaneConstants.settingNameUniqueValidationLocationClaimHoldError);
            return;
        }

        this.validator.setStrategy(new ConcreteStrategyOnlyProviderInGroups());
        if (this.validator.isValid(incomingPayload)) {
            this.incomingPayload = incomingPayload;
            this.setState({
                confirm_box_open: true
            });
            return;
        }

        this.validator.setStrategy(new ConcreteStrategySingleDataInGroupsLocationClaimHold());
        if (!this.validator.isValid(incomingPayload)) {
            toaster.error('', billingPaneConstants.dataInGroupsValidationErrorLocationClaimHold);
            return;
        }


        this.claim_hold_from_drawer_callback(incomingPayload);
    }

    private retrievePendingReasons = async () => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;

        let claim_delay_reason = [];

        claim_delay_reason = await constants_actions.get_provider_pending_reason(token).then(res => {
            if (res.data.data != null && res.data.data.length > 0) {
                return res.data.data.map((val, index) => {
                    return { key: index, value: val.pending_reason_id, text: val.pending_reason_desc }
                });
            }
        }, (error) => {
            this.retrievePendingReasonPromiseReject('error');
            return;
        });
        claim_delay_reason.reverse();
        this.setState({
            claim_delay_reason
        }, () => {
            this.retrievePendingReasonPromiseResolve('ok');
        })
    }

    private retrieveProvidersAndInsurancesForMultiTab = async () => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        const params = { page_size: global_constants.constants.PAGE_SIZE };

        this.apiPromisesCollector.push(this.retrieveProvidersAndInsurancesPromise);
        await get_insurance_codes(token)
            .then(response => {
                this.setState({
                    insuranceCodes: response.data.data
                });
            }, (error) => {
                this.retrieveProvidersAndInsurancesPromiseReject('error');
            })
            .then(async () => {
                var url = '/constant/providers/search?provider_code=&first_name=&last_name=&show_inactive=false&page_Size=5000';
                await constants_actionss.get_search_data(this.token, url).then(
                    response => {
                        if (response.data) {
                            let mapped_provider_code_data = response.data.data.map((item, index) => {
                            return {
                                provider_id: item.provider_id,
                                provider_code: Number(item.provider_code),
                                description: item.description
                            };
                        });
                        this.setState({
                            providers: mapped_provider_code_data
                        });
                        }
                    })
            }, (error) => {
                this.retrieveProvidersAndInsurancesPromiseReject('error');
            })
            .then(async () => {
                await advanced_search_class(params, token)
                    .then(response => {
                        this.setState({
                            insuranceClasses: response.data.data
                        });
                        this.retrieveProvidersAndInsurancesPromiseResolve('ok');
                    });
            }, (error) => {
                this.retrieveProvidersAndInsurancesPromiseReject('error');
            });
    }

    private unbindClaimRowData = () => {
        this.setState({
            locationClaimHold: {
                originalRowData: this.claim_model
            },
            claimTabId: ''
        });
        this.setState({
            providerClaimHold: {
                originalRowData: this.claim_model
            },
            claimTabId: ''
        });
    }

    render() {
        const { controls_is_expended, error, required_text_fields } = this.state;
        return (
            this.state.show_render && (
                <React.Fragment>
                    <Modal
                        closeIcon
                        centered={false}
                        closeOnDimmerClick={false}
                        open={this.state.confirm_box_open}
                        onClose={this.confirm_close_handler}
                        className='confirm_box default-modal'
                        id={'confirm_box'}
                    >
                        <Modal.Header>Confirm</Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                <Grid>
                                    <Grid.Column>
                                        <p>{billingPaneConstants.onlyProviderInGroupsValidationErrorClaimHold}</p>
                                    </Grid.Column>
                                </Grid>
                                <Grid>
                                    <Grid.Column computer={16} textAlign='right'>
                                        <Button type='cancel' basic onClick={this.confirm_close_handler}>
                                            Cancel
                                        </Button>
                                        <Button type='button' id="confirm_button_confirm_box_window" onKeyDown={(e) => {
                                        }} onClick={this.confirm_handler} primary>
                                            Continue
                                        </Button>
                                    </Grid.Column>
                                </Grid>
                            </Modal.Description>
                        </Modal.Content>
                    </Modal>
                    <Dimmer active={this.state.is_loading}>
                        <Loader size='massive'>Loading</Loader>
                    </Dimmer>
                    <div className={'admin-wrapper view-edit Locations provider_id'} style={location_css.locations_id}>
                        <Grid className='headerGrid' style={location_css.headerGrid}>
                            <Grid.Column computer={16}>
                                <h3 className='ui header left aligned'>Claim Holds</h3>
                            </Grid.Column>
                        </Grid>
                        <div className='wrapper' style={location_css.wrapper}>
                            <Form autoComplete='off' name='view_edit_form' className='common-forms' style={location_css.common_forms}>
                                <div id='admin-scrollable-area' style={location_css.scrollbar_area}>
                                    {/* Location Information */}
                                    <Grid className='common-accordion' style={location_css.provider_top}>
                                        <Grid.Column
                                            computer={16}
                                            tablet={16}
                                            textAlign='left'
                                            className='accordionColumn'
                                            id='accordion-column'
                                        >
                                            <Accordion fluid styled>
                                                <Accordion.Title active={controls_is_expended.location} index={0} onClick={() => this.accordion_toggle('location')}>
                                                    <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name='angle right' />
                                                    Location Information
                                                </Accordion.Title>
                                                <Accordion.Content active={controls_is_expended.location} style={location_css.accordion_content}>
                                                    {/* Location Information */}
                                                    <div id='search-criteria-container'>
                                                        <Grid>
                                                            <Grid.Column tablet={8} computer={4}>
                                                                <Form.Field>
                                                                    <label>
                                                                        Location Code{' '}
                                                                        {required_text_fields.pos_code && (
                                                                            <span>(required)</span>
                                                                        )}
                                                                    </label>
                                                                    <input
                                                                        autoComplete='new-password'
                                                                        name='pos_code'
                                                                        id='pos_code'
                                                                        value={this.form_object.pos_code ? this.form_object.pos_code : ''}
                                                                        type='text'
                                                                        maxLength={5}
                                                                        pattern='[0-9a-zA-Z]*'
                                                                        disabled={this.disable_fields}
                                                                    />
                                                                    { error.pos_code && <div className='requiredText'>Alphanumeric, 3 to 5.</div>}
                                                                </Form.Field>
                                                            </Grid.Column>
                                                            <Grid.Column tablet={8} computer={4}>
                                                                <Form.Field>
                                                                    <label>
                                                                        Location Name{' '}
                                                                        {required_text_fields.pos_name && (
                                                                            <span> (required) </span>
                                                                        )}
                                                                    </label>
                                                                    <input
                                                                        autoComplete='new-password'
                                                                        name='pos_name'
                                                                        id='pos_name'
                                                                        value={this.form_object.pos_name ? this.form_object.pos_name : ''}
                                                                        type='text'
                                                                        maxLength={50}
                                                                        disabled={this.disable_fields}
                                                                    />
                                                                </Form.Field>
                                                            </Grid.Column>
                                                            <Grid.Column tablet={8} computer={4}>
                                                                <Form.Field>
                                                                    <label>
                                                                        Address{' '}
                                                                        {required_text_fields.address1 && (
                                                                            <span> (required) </span>
                                                                        )}
                                                                    </label>
                                                                    <input
                                                                        autoComplete='new-password'
                                                                        name='address1'
                                                                        id='address1'
                                                                        value={this.form_object.address1 ? this.form_object.address1 : ''}
                                                                        type='text'
                                                                        maxLength={50}
                                                                        disabled={this.disable_fields}
                                                                    />
                                                                </Form.Field>
                                                            </Grid.Column>
                                                            <Grid.Column tablet={8} computer={4}>
                                                                <Form.Field>
                                                                    <label>
                                                                        City{' '}
                                                                        {required_text_fields.city && (
                                                                            <span> (required) </span>
                                                                        )}
                                                                    </label>
                                                                    <input
                                                                        autoComplete='new-password'
                                                                        name='city'
                                                                        id='city'
                                                                        value={this.form_object.city ? this.form_object.city : ''}
                                                                        type='text'
                                                                        maxLength={50}
                                                                        disabled={this.disable_fields}
                                                                    />
                                                                </Form.Field>
                                                            </Grid.Column>
                                                        </Grid>
                                                        <Grid>
                                                            <Grid.Column tablet={8} computer={4}>
                                                                <Form.Field>
                                                                    <label>
                                                                        State{' '}
                                                                        {required_text_fields.state && (
                                                                            <span> (required) </span>
                                                                        )}
                                                                    </label>
                                                                    <Selection
                                                                        id='state'
                                                                        name='state'
                                                                        onChange={undefined}
                                                                        options={option_formatter(
                                                                            this.state.patient_master_data.states,
                                                                            'text',
                                                                            'value',
                                                                            true, // to add blank object
                                                                            false, // to sorted object,
                                                                            'Select'
                                                                        )}
                                                                        defaultValue={this.form_object.state ? this.form_object.state : ''}
                                                                        disabled={this.disable_fields}
                                                                    />
                                                                </Form.Field>
                                                            </Grid.Column>
                                                            <Grid.Column tablet={8} computer={4}>
                                                                <Form.Field>
                                                                    <label>
                                                                        Zip Code{' '}
                                                                        {required_text_fields.zip && (
                                                                            <span> (required) </span>
                                                                        )}
                                                                    </label>
                                                                    <input
                                                                        autoComplete='new-password'
                                                                        name='zip'
                                                                        value={this.form_object.zip ? zip_code_formation(this.form_object.zip) : ''}
                                                                        id='zip'
                                                                        maxLength={10}
                                                                        pattern='[0-9]*'
                                                                        disabled={this.disable_fields}
                                                                    />
                                                                    { error.zip && <div className='requiredText'>Zip can be 5 or 9 digits.</div>}
                                                                </Form.Field>
                                                            </Grid.Column>
                                                            <Grid.Column tablet={8} computer={4}>
                                                                <Form.Field className={ error.phone ? 'error' : ''}>
                                                                    <label>Phone</label>
                                                                    <MaskedInput
                                                                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                                        className={'form-control'}
                                                                        placeholder='(___) ___-____'
                                                                        guide={true}
                                                                        id='phone'
                                                                        name='phone'
                                                                        autoComplete='new-password'
                                                                        showMask={true}
                                                                        value={this.form_object.phone ? this.form_object.phone : ''}
                                                                        disabled={this.disable_fields}
                                                                    />
                                                                    { error.phone && <div className='requiredText'>Please enter 10 digits</div>}
                                                                </Form.Field>
                                                            </Grid.Column>
                                                            <Grid.Column tablet={8} computer={4}>
                                                                <Form.Field className={ error.fax ? 'error' : ''}>
                                                                    <label>Fax</label>
                                                                    <MaskedInput
                                                                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                                        className={'form-control'}
                                                                        placeholder='(___) ___-____'
                                                                        guide={true}
                                                                        id='fax'
                                                                        name='fax'
                                                                        autoComplete='new-password'
                                                                        showMask={true}
                                                                        value={this.form_object.fax}
                                                                        disabled={this.disable_fields}
                                                                    />
                                                                    { error.fax && <div className='requiredText'>Please enter 10 digits</div>}
                                                                </Form.Field>
                                                            </Grid.Column>
                                                        </Grid>
                                                        <Grid>
                                                            <Grid.Column tablet={8} computer={4}>
                                                                <Form.Field>
                                                                    <label>
                                                                        Practice{' '}
                                                                        {required_text_fields.practice_id && (
                                                                            <span> (required) </span>
                                                                        )}
                                                                    </label>
                                                                    <Selection
                                                                        id='practice_id'
                                                                        name='practice_id'
                                                                        placeHolder={'Select'}
                                                                        hidden={true}
                                                                        options={this.state.practices}
                                                                        defaultValue={this.form_object.practice_id ? this.form_object.practice_id : ''}
                                                                        disabled={this.disable_fields}
                                                                        onChange={undefined} />
                                                                </Form.Field>
                                                            </Grid.Column>
                                                            <Grid.Column tablet={8} computer={4}>
                                                                <Form.Field style={{ marginTop: 32 }}>
                                                                    <Checkbox
                                                                        name='dont_show_in_lookups'
                                                                        id='dont_show_in_lookups'
                                                                        checked={this.form_object.dont_show_in_lookups ? true : false}
                                                                        label={'Exclude From Lookups'}
                                                                        disabled={this.disable_fields}
                                                                    />
                                                                </Form.Field>
                                                            </Grid.Column>
                                                            <Grid.Column tablet={8} computer={4}>
                                                                <Form.Field style={{ marginTop: 32 }}>
                                                                    <Checkbox
                                                                        name='is_active'
                                                                        id='is_active'
                                                                        checked={this.form_object.is_active ? true : false}
                                                                        label={'Is Active'}
                                                                        disabled={this.disable_fields}
                                                                    />
                                                                </Form.Field>
                                                            </Grid.Column>
                                                        </Grid>
                                                    </div>
                                                </Accordion.Content>
                                            </Accordion>
                                        </Grid.Column>
                                    </Grid>
                                    {/* Location Claim Holds */}
                                    <Grid className='common-accordion'>
                                        <Grid.Column
                                            computer={16}
                                            tablet={16}
                                            textAlign='left'
                                            className='accordionColumn'
                                            id='accordion-column'
                                        >
                                            <Accordion fluid styled>
                                                <Accordion.Title active={controls_is_expended.claim} index={0} onClick={() => this.accordion_toggle('claim')}>
                                                    <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name='angle right' />
                                                    Location Claim Holds
                                                </Accordion.Title>
                                                <Accordion.Content active={controls_is_expended.claim} style={location_css.accordion_content}>
                                                    <Grid style={{ marginTop: '-0.5rem', marginBottom: '-2rem' }}>
                                                        <Grid.Column computer={16} textAlign='left' style={location_css.inactive_column}>
                                                            <Checkbox
                                                                name='show_inactive_claim'
                                                                id='show_inactive_claim'
                                                                onChange={(e) => this.show_inactive(e)}
                                                                checked={this.state.show_inactive_claim}
                                                                label={'Show Inactive'}
                                                                disabled={this.state.claim_hold_rows_original.length <= 0}
                                                            />
                                                        </Grid.Column>
                                                    </Grid>
                                                    <Grid>
                                                        <Grid.Column computer={16}>
                                                            <div className="customtable" id="id_billing_container">
                                                                <div className="table-responsive" id="id_billing_settings_table">
                                                                    <table className="ui table" id="id-table-billing-settings-form">
                                                                        <thead>
                                                                            <ProviderBillingHeaderComponent
                                                                                columns={this.state.claim_hold_col_fields}
                                                                                rows={this.state.claim_hold_rows_original}
                                                                                onSortChanged={this.on_sort_changed}
                                                                                onColumnMoved={this.on_column_moved}
                                                                                id={"claim_hold"}
                                                                            />
                                                                        </thead>
                                                                        <tbody>
                                                                            {this.render_claim_hold_rows()}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </Grid.Column>
                                                    </Grid>
                                                    <Grid>
                                                        <Grid.Column computer={16} textAlign="right" style={location_css.new_button_section}>
                                                            <Button
                                                                id='id_btn_new_row'
                                                                type='button'
                                                                onClick={this.new_claim_handler}
                                                                primary
                                                                style={location_css.new_button}
                                                                content='New'
                                                            />
                                                        </Grid.Column>
                                                    </Grid>
                                                </Accordion.Content>
                                            </Accordion>
                                        </Grid.Column>
                                    </Grid>
                                </div>
                                <div
                                    className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                                    id='applicationFooterSticky'
                                    style={location_css.footerArea}
                                >
                                    <Button
                                        id='cancel_button_id'
                                        type='button'
                                        onClick={this.cancel_handler}
                                        basic
                                        content="Cancel"
                                    />
                                    <Button
                                        id='save_button_id'
                                        type='button'
                                        disabled={this.state.is_save_button_disabled}
                                        onKeyDown={set_focus_to_app_header}
                                        onClick={e => this.save_handler(e, false)}
                                        primary style={location_css.save_button}
                                        content="Save"
                                    />
                                </div>
                            </Form>
                        </div>
                    </div>
                    {this.state.isClaimDrawerOpen && <DrawerComponent
                        key={this.props.key_id}
                        canOutsideClickClose={false}
                        canEscapeKeyClose={false}
                        isOpen={this.state.isClaimDrawerOpen}
                        className="ext-bp-drawer provider-drawer billing-provider-drawer claim-provider-drawer"
                        handleDrawerClose={this.handleDrawerClaimClose}
                        position={Position.RIGHT}
                        title=""
                        isCloseButtonShown={true}
                        innerContent={this.getClaimHoldDrawerComponent()}
                        size={Drawer.SIZE_STANDARD}
                    />}
                </React.Fragment>
            )
        );
    }

}

const location_css = {
    accordion_content: {
        padding: 0
    },
    common_forms: {
        paddingTop: 0,
        height: '100%',
        paddingBottom: 0,
        display: 'flex',
        flexDirection: 'column'
    } as React.CSSProperties,
    footerArea: {
        paddingLeft: 0,
        paddingBottom: 0,
        marginBottom: 12,
        paddingRight: 25,
        paddingTop: 5
    },
    headerGrid: {
        marginTop: 0,
        marginBottom: 0,
        paddingRight: 15
    },
    inactive_column: {
        marginBottom: '-1rem'
    },
    locations_id: {
        paddingBottom: 0
    },
    new_button: {
        marginRight: 0
    },
    new_button_section: {
        marginTop: -10
    },
    provider_top: {
        marginTop: 5
    },
    save_button: {
        marginRight: 0
    },
    scrollbar_area: {
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingRight: 15
    } as React.CSSProperties,
    wrapper: {
        borderTop: 0,
        marginBottom: 0
    }
}

const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
        shared_details: state.shared_details,
        features: {
        }
    };
};

export default connect(mapStateToProps)(ClaimHoldsAddEditComponent);
