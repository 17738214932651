import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Dimmer, Form, Grid, Header, Input, Loader } from 'semantic-ui-react';
import * as global_constants from '../../global_constants';
import AdvancedControl from "../../shared/component/advanced_control";
import AutoSearchComponent from '../../shared/component/auto_search_component';
import Selection from '../../shared/component/selection_component';
import * as session_storage from '../../shared/session_storage_utility';
import * as sharedConstants from "../../shared/shared_constants";
import * as billing_constant from '../billing_constants'
import { set_focus_to_app_header } from '../../shared/tab_navigation_utility';
import { format_dashes_number, format_date, get_age, format_patient_name, custom_date_format, currency_formatter, amount_unformatter, get_all_error, date_format_with_no_time } from '../../shared/utility';
import ClaimIndicatorRowComponent from './claim_indicator_row_component';
import ClaimIndicatorHeaderComponent from './claim_indicator_header_component'
import * as Action from './../../shared/action/autosearch_action';
import DatePicker from '../../shared/component/date_picker_component';
import * as moment from 'moment';
import { get_patient_search } from '../../patient/action/patient_search_action';
import { bindActionCreators } from 'redux';
import { get_patient_insurance, get_patient_charges, save_charge, get_payer_group, get_condition_code } from '../action/claim_indicator_action'
import { toastr } from 'react-redux-toastr';
import ConfirmationComponent from '../../shared/component/confirmation_component';


class ClaimIndicatorComponent extends React.Component<any, any> {
    _is_mounted = false;
    menu_permission_object: Array<any> = [{create:true,update:true}];
    rowNodes: any;
    // Define controller data.
    control_data = [];
    constructor(props) {
        super(props);
        // Define initial state value.
        this.state = {
            show_bummepd_charge_message_popup:false,
            patient_id: null,
            insurance_id: '',
            show_patient_modal: false,

            is_loading: false,
            is_submitted: false,

            grid_conf: {
                isPagination: true,
                gridAutoHeight: true,
                selectionType: 'single',
                paginationPageSize: 20,
            },
            grid_data: {},
            payer_group:'',
            is_search_button_disabled: false,
            show_grid: false,
            billing_rows_original: [],
            billing_rows : [],
            //billing_rows: [],
            data_searched: false,
            show_render: true,
            insurance_data:[],
            col_fields: billing_constant.claim_indicator_setting_columns,
            condition_code:[]
        };
        this.rowNodes = []
    }
    update_data = (source,type) => {
        var target = [{ "text": 'Select', "value": "-999", "key": "", "ins_code_id":"" }];

        var source_length = source.length;

        if (source && source_length > 0) {
            for (let y = 0; y < source_length; y++) {
                let item = source[y];
                target.push({
                    "text": type == 'ins' ? item.ins_desc : item.condition_code,
                    "value": type == 'ins' ? item.pst_ins : item.condition_code_id,
                    "key": type == 'ins' ? item.ins_desc : item.condition_code,
                    "ins_code_id": type == 'ins' ? item.ins_code : item.condition_code_description
                })
            }
        }

        return target;

    }
    get_patient_insurance = (patient_id) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        get_patient_insurance(patient_id, token).then(res => {
            if (res.data.data && this._is_mounted) {
                this.setState({
                    insurance_data: this.update_data(res.data.data,'ins')
                }) 
            }
        })
    }
    
    // Fetching the page_name and state.
    UNSAFE_componentWillMount = () => {
        this.menu_permission_object = this.props.shared_details.left_menu_data.data
            .filter((menu, index) => {
                return menu.name == 'Claim Indicator';
            });       
    };

    componentDidMount = () => {
        this._is_mounted = true;
        var token = this.props.user_login_details.user_data.data.token_details.access_token;
        get_condition_code(token).then(res => {
            if (res.data.data && this._is_mounted) {
                this.setState({
                    condition_code: this.update_data(res.data.data, 'condition')
                })
            }
        })
    };

    componentWillUnmount = () => {

    };

    get_insurance_id = (ins_code) => {
        return this.state.insurance_data.filter(i => i.value == ins_code)[0].ins_code_id;
    }
   

    //Dropdown change handler
    dropdown_change_handler = (e, value) => {
        var token = this.props.user_login_details.user_data.data.token_details.access_token;
        if (this._is_mounted) {
            this.setState({
                insurance_code:value
            }, () => {
                get_payer_group(this.state.insurance_code, token).then(res => {
                    if (this._is_mounted && res.data.data) {
                        this.setState({
                            payer_group:res.data.data
                        })
                    }
                })

                })
        }
    };


    get_all_updated_data = () => {
        let row_items = [];
        this.rowNodes.map((node) => {
            if (node) {
                row_items = [...row_items, node.get_updated_data()];
            }
        })
        return row_items;
    }
    //filter the data which is actually changed
    filter_data_changed = (old_data, new_data) => {
        let new_data_to_save = [];
        if (new_data.length > 0) {
            for (var indx = 0; indx < new_data.length; indx++) {
                let item = new_data[indx];
                var old_charge_data = old_data.filter(old_item => {
                    return (old_item.charge_id == item.charge_id)
                });
                if (old_charge_data.length > 0) {
                    item.late_reason = item.late_reason == -999 ? null : item.late_reason;
                    item.claim_action = item.claim_action == -999 ? null : item.claim_action;
                    item.condition_code_id = item.condition_code_id == -999 ? null : item.condition_code_id;
                    if (item.rebill_status == true || old_charge_data[0].late_reason != item.late_reason || old_charge_data[0].claim_action != item.claim_action || old_charge_data[0].claim_code != item.claim_code || old_charge_data[0].note != item.note || old_charge_data[0].condition_code_id != item.condition_code_id) {
                        new_data_to_save.push(item);
                    }
                }
            }
        }
        return new_data_to_save;
    }
    //Save the form object and redirect it to search

    show_html_content_toaster = (msg: any) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ),
            timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        };
    };


    save_handler = () => {
        var data = this.get_all_updated_data();
        if (data.length > 0) {
            for (let indx = 0; indx < data.length; indx++) {
                var elem = document.getElementById('id_late_reason_' + data[indx].charge_id);
                var elem_action = document.getElementById('id_action_' + data[indx].charge_id);
                if (elem) {
                    elem.className = '';
                    //elem.focus();
                }
                if (elem_action) {
                    elem_action.className = '';
                    //elem.focus();
                }
            }
        }

        var new_data = this.filter_data_changed(this.state.billing_rows_original, data);
        if (new_data.length > 0) {
            new_data.map((item) => {
                if (item.rebill_status == true) { item.status = 'C' } else { item.status = item.status }
                item.owed_amt = amount_unformatter(item.owed_amt);
                item.total_chg_amt = amount_unformatter(item.total_chg_amt);
                item.claim_action = (item.claim_action == '-999' || item.claim_action == null) ? null : item.claim_action;
                item.late_reason = (item.late_reason == '-999' || item.late_reason == null)  ? null : item.late_reason.toString();
                item.condition_code_id = (item.condition_code_id == '-999' || item.condition_code_id == null) ? 0 : item.condition_code_id;
                item.note = item.note == null ? '' : item.note;
                item.date_of_service = date_format_with_no_time(item.date_of_service);
                item.last_billed_date = date_format_with_no_time(item.last_billed_date);
            });
            var valid = this.validate_charges(new_data);           
            if (valid) {
                if (this._is_mounted) {
                    this.setState({
                        is_loading: true,
                    })
                }
                var token = this.props.user_login_details.user_data.data.token_details.access_token;
                save_charge(token, new_data).then(res => {
                    if (res.data.data) {
                        toastr.success('', billing_constant.error_messages.added_successfully);
                        if (this._is_mounted) {
                            this.setState({
                                is_loading: false,
                                formatted_from_date: null,
                                formatted_to_date: null,
                                billing_rows_original: [],
                                billing_rows: [],
                                data_searched: false,
                                insurance_code: '-999',
                                from_date: null,
                                to_date: null,
                            })
                        }
                    }
                }, (error) => {
                    if (error && error.response && error.response.data && error.response.data.messages && error.response.data.messages.length > 0) {
                        let exception = error.response.data.messages.filter((item) => {
                            return item.code === global_constants.charge_exception.code
                        });
                        if (exception && exception.length > 0) {
                            if (this._is_mounted) {
                                this.setState({
                                    is_loading: false,
                                    show_bummepd_charge_message_popup: true
                                })
                            }
                        } else {
                            if (this._is_mounted) {
                                this.setState({
                                    is_loading: false
                                });
                            }
                            const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                            toastr.error('', toastr_options);
                        }
                    } else {
                        toastr.error('System error', error);
                        if (this._is_mounted) {
                            this.setState({
                                is_loading: false,
                            })
                        }
                    }                    
                });
            }
        }
        else {
            if (this._is_mounted) {
                this.setState({
                    is_loading: false,
                })
            }
            toastr.error('', billing_constant.error_messages.no_charges_to_save);
        }
    };
    validate_charges = (data) => {        
        var invalid_late_data = data.filter(item => {
            return (item.claim_action == 'Late' && (item.late_reason == null || item.late_reason == undefined));
        });
        if (invalid_late_data.length > 0) {     
            for (let indx = 0; indx < invalid_late_data.length; indx++) {
                var elem = document.getElementById('id_late_reason_' + invalid_late_data[indx].charge_id);
                if (elem) {
                    elem.className = 'req-background-inp';
                    //elem.focus();
                }
            }
            toastr.error('', billing_constant.error_messages.select_late_reason);
            return false;
        }
        var invalid_staus_data = data.filter(item => {
            return (item.rebill_status && (item.claim_action == null || item.claim_action == undefined));
        });
        if (invalid_staus_data.length > 0) {
            for (let indx = 0; indx < invalid_staus_data.length; indx++) {
                var elem = document.getElementById('id_action_' + invalid_staus_data[indx].charge_id);
                if (elem) {
                    elem.className = 'req-background-inp';
                    //elem.focus();
                }
            }
            
            toastr.error('', billing_constant.error_messages.select_action);
            return false;
        }
        return true;
    }
    //Function used to Run Report based on controllers values.
    cancel_handler = () => {

    };





    //Callback on column sorting click
    on_sort_changed = (rows) => {
        this.setState({ billing_rows: rows });
    }

    //callback on column reordering drag and drop
    on_column_moved = (cols) => {
        this.setState({ col_fields: cols });
    }

    //It renders report grid and its search controls.
    getDataList = async (inputValue, companyid) => {
        var token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await Action.get_searchList(token, companyid, inputValue.trim(), global_constants.constants.Quick_Search_Suggestion_List_Size, global_constants.end_points.patient_urls.quick_search);

    }
    renderResult = (props) => {
        return (
            <div key={props.title} tabIndex={0} className="item_auto_search">
                <div className='fs_13'>
                    <span className="bold">{props.label}</span><span>{props.title ? '(' + props.title + ')' : ''}</span>
                </div>
            </div>
        )
    }
    //Prepare suggestion list to show in Quick Search result
    prepareSuggestion = (data) => {
        let formattedList = [];
        if (data && data.data.length > 0) {
            data.data.map((item) => {
                formattedList.push({
                    "label": (item.last_name == null ? '' : item.last_name + ' ') + (item.middle_initial == null ? '' : item.middle_initial + ' ') + (item.first_name == null ? '' : item.first_name),
                    "title": item.id.toString(),
                    "email": item.email,
                    "dob": (item.date_of_birth == null ? '' : format_date(new Date(item.date_of_birth), false)).toString(),
                    "age": (item.date_of_birth == null ? '' : get_age(item.date_of_birth)) + ' Yrs',
                    "home": (item.phone.home == null || item.phone.home == '' ? ' ' : ' H: ' + format_dashes_number(item.phone.home)),
                    "work": (item.phone.work == null || item.phone.work == '' ? ' ' : ', W: ' + format_dashes_number(item.phone.work)),
                    "cell": (item.phone.cell == null || item.phone.cell == '' ? ' ' : ', M: ' + format_dashes_number(item.phone.cell)),
                });

                return;
            });
        }
        else {
            if (this._is_mounted) {
                this.setState({ quick_search_error_message: 'No Patient Found !' })
            }
        }
        return formattedList;

    }
    onselection = (item) => {

        //session_storage.set('active_patient', item.title);
        //this.props.set_active_patient(item.title)
        this.get_patient_insurance(item.title);
        if (this._is_mounted) {
            this.setState({ patient_id: item.title });
        }
    }
    on_patient_search = (pat_obj) => {
        var params = {
            patient_name: {
                first_name: pat_obj.first_name,
                last_name: pat_obj.last_name
            },
            id: pat_obj.Id,
            page_size: pat_obj.page_size,
            company_id: this.props.user_login_details.user_data.data.company_id
        }
        var token = this.props.user_login_details.user_data.data.token_details.access_token;
        // Search function.
        this.props.get_patient_search(params, token).then(res => {
            this.setState({
                grid_data: { ...this.state.grid_conf, rows: this.props.patient_details.patient_search, column: sharedConstants.patient_search_configuration(sharedConstants.patient_search_configuration_id).column_defs, messages: "No Patient Found" }

            });
        });
    }
    format_name = (name) => {
        return (name.last_name == null ? '' : name.last_name) + (name.middle_initial == null ? '' : ' ' + name.middle_initial) + (name.first_name == null ? '' : ' ' + name.first_name);
    }
    on_patient_grid_row_selection = (selected_row) => {
        var patient_name = this.format_name(selected_row.name)
        // this.control_data[equence] = selected_row.id;
        let patient_search_value = {
            'label': patient_name,
            'title': selected_row.id,
        }
        this.get_patient_insurance(selected_row.id);
        this.setState({
            patient_id: selected_row.id,
            patient_search_value: patient_search_value
        })
        // session_storage.set('report_patient_adv_search', patient_search_value);
        this.closeModal();
    }
    //close the modal on selection of patient through quick search
    closeModal = () => {
        this.setState({ show_patient_modal: false })
    }

    clear_quick_search = () => {
       // this.control_data[sequence] = '';
        this.setState({
            patient_search_value: {},
            insurance_data: [],
            insurance_code: '-999',
            payer_group: null,
            patient_id: null
        })
        //session_storage.set('report_patient_adv_search', {});
    }
    calendar_change_handler = (name, date_object) => {
        let error = this.state.error;

        if (this._is_mounted) {
            this.setState({
                error: error,
                [name]: date_object ? custom_date_format(date_object._d, 'mm/dd/yyyy') : '',
                [`is_${name}`]: moment.isMoment(date_object) ? false : true,
                [`formatted_${name}`]: date_object
            });
        }
    };
    clear_search_result = () => {
        if (this._is_mounted) {
            this.setState({
                patient_search_value: {},
                insurance_data: [],
                formatted_from_date: null,
                formatted_to_date: null,
                billing_rows_original: [],
                billing_rows: [],
                is_search_button_disabled: false,
                show_grid: false,
                data_searched: false,
                patient_id: null,
                insurance_code: '-999',
                from_date: null,
                to_date: null,
                payer_group: null
            });
        }
    }
    get_charge_details = () => {        
        let criteria = {
            patient_id: this.state.patient_id ? this.state.patient_id:0,
            ins_code: this.state.insurance_code == '-999' ? 0 : this.state.insurance_code,
            from_date: this.state.from_date ? this.state.from_date :  new Date('1900/01/01'),
            to_date: this.state.to_date ? this.state.to_date : new Date('1900/01/01')
        };
        if (this._is_mounted) {
            this.setState({               
                billing_rows_original: [],
                billing_rows: [],
                data_searched: false, 
                is_loading:true
            });
        }
        if (this.state.formatted_from_date && this.state.formatted_to_date && this.state.formatted_from_date > this.state.formatted_to_date) {
            toastr.error('', billing_constant.error_messages.date_validation_msg);
            return;
        }
        let token = this.props.user_login_details.user_data.data.token_details.access_token;
        get_patient_charges(criteria, token).then(res => {
            if (res.data.data && this._is_mounted) {
                var charge_amount_total = 0;
                var balance_total = 0;
                let data = res.data.data;
                if (res.data.data.length > 0) {                    
                    data.map((item, index) => {
                        if (item.date_of_service) {
                            item.date_of_service = format_date(new Date(item.date_of_service));
                        }
                        if (item.last_billed_date) {
                            item.last_billed_date = format_date(new Date(item.last_billed_date));
                        }
                        if (item.total_chg_amt != null) {                            
                            item.total_chg_amt = item.total_chg_amt ? item.total_chg_amt : 0;
                            item.unformatted_chg_amt = item.total_chg_amt;
                            charge_amount_total += item.total_chg_amt ? parseFloat(item.total_chg_amt) : 0;
                            item.total_chg_amt = currency_formatter(item.total_chg_amt);
                        }
                        if (item.owed_amt!=null) {
                            item.owed_amt = item.owed_amt ? item.owed_amt : 0;
                            item.unformatted_owed_amt = item.owed_amt;
                            balance_total += item.owed_amt ? parseFloat(item.owed_amt) : 0;
                            item.owed_amt = currency_formatter(item.owed_amt);
                        }
                        let reason = 'Select';
                        let condition_text = 'Select';
                        let action_text = 'Select';
                        if (!(item.late_reason == null || item.late_reason == undefined  || item.late_reason == '')) {
                           reason= billing_constant.claim_reason.filter(val => val.value == item.late_reason)[0].text;                            
                        }
                        item.late_reason_text = reason;
                        if (!(item.condition_code == null || item.condition_code == undefined || item.condition_code == '')) {                           
                            condition_text = item.condition_code;
                        }
                        item.condition_code_text = condition_text;    
                        if (!(item.claim_action == null || item.claim_action == undefined || item.claim_action == '')) {
                            action_text = item.claim_action;
                        }
                        item.claim_action_text = action_text; 
                        item.rebill_status = false;
                    });
                }               
                this.setState({
                    data_searched: true,
                    balance_total: currency_formatter(balance_total),
                    charge_amount_total: currency_formatter(charge_amount_total),
                    billing_rows: data.length > 0 ? data:[],
                    billing_rows_original: data.length > 0 ? data : [],
                    is_loading: false
                })
            }

        }, (error) => {
            toastr.error('System error', error);
            this.setState({
                is_loading:false
            })
            })
    }
    render_billing_rows = () => {
       
        if (this.state.billing_rows && this.state.billing_rows.length > 0) {
            return this.state.billing_rows.map((item, index) => {
                return (
                    <ClaimIndicatorRowComponent
                        key={index}
                        data={item}
                        column_fields={this.state.col_fields}
                        row_count={index}
                        onRef={ref => (this.rowNodes[index] = ref)}
                        condition_code={this.state.condition_code}
                    />
                )
            });
        }

        return (<tr>
            <td colSpan={12} style={{ textAlign:'center' }}> No Record Available</td>
            
        </tr>);
    }
    render_footer = ()=> {
        return(<tr>
            <td ><b>Total</b></td><td ></td><td ></td><td ><b>{this.state.charge_amount_total}</b></td>
            <td ><b>{this.state.balance_total}</b></td><td ></td><td ></td><td ></td><td ></td><td ></td><td ></td><td ></td>
        </tr>);

    }
    refresh_bummepd_charge_modal = () => {
        if (this._is_mounted) {
            this.setState({
                show_bummepd_charge_message_popup: false
            }, () => {
                this.get_charge_details();
            });
        }
    }
    on_close_bummepd_charge_popup = () => {
        if (this._is_mounted) {
            this.setState({
                show_bummepd_charge_message_popup: false
            });
        }
    }

    render() {
        return (
            this.state.show_render && (
                <React.Fragment>
                    <Dimmer active={this.state.is_loading}>
                        <Loader size='massive'>Loading</Loader>
                    </Dimmer>
                    <div className='common-forms-add  common-forms-add-flex'>
                        <Header as='h3' dividing textAlign='left' id='applicationPageTitleSticky'>
                            Claim Indicator
                        </Header>
                        <div className={'auto-height-scroll'} id='scrollable-content-area'>
                            <Form id="form_claim_indicator" className='patient-add-form' onSubmit={this.get_charge_details} autoComplete='off'>
                                <Grid >
                                    <Grid.Column computer={16}>

                                        <Grid style={{marginTop:'10px'}} >
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field className="advance-quick-search case-reg-form">
                                                    <label>Patient</label>
                                                    <AdvancedControl
                                                        onGridRowSelection={this.on_patient_grid_row_selection}
                                                        gridConfig={this.state.grid_data}
                                                        controlId={"PATIENT"}
                                                        onSearch={this.on_patient_search}
                                                        search_type={"PATIENT_REPORT"}
                                                        headerIdForGridTabNavigation={sharedConstants.patient_search_configuration_id}
                                                    />

                                                    <AutoSearchComponent control_id="patient_header_quick_search_input"
                                                        default_value={this.state.patient_search_value}
                                                        errorMessage={"No Record Found!"}
                                                        getList={this.getDataList} prepareRenderList={this.renderResult}
                                                        prepareDataList={this.prepareSuggestion}
                                                        selectresult={this.onselection} is_disabled={false} show_clear_search={true}
                                                        clear_search={ this.clear_quick_search}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label>Insurance</label>
                                                    <Selection
                                                        id='insurance_code'
                                                        name='insurance_code'
                                                        options={this.state.insurance_data}
                                                        onChange={(value, e) => {
                                                            this.dropdown_change_handler(e,value);
                                                        }}
                                                        defaultValue={this.state.insurance_code || '-999'}
                                                        style={'dropdown-options-wrap'}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>

                                            <Grid.Column tablet={8} computer={4} >
                                                <label>
                                                    Date of Service From{' '}

                                                </label>
                                                <DatePicker
                                                    maxDate={moment().format('MM/DD/YYYY')}
                                                    date_update={date_object => this.calendar_change_handler('from_date', date_object)}
                                                    date={this.state.formatted_from_date}
                                                    //is_required={(is_searched || is_submitted) && error.formatted_from_date}
                                                    id_popper="root"
                                                    id="date-of-service-from"
                                                    styleClass={'claim_indicator_date'}
                                                />
                                            </Grid.Column>
                                            <Grid.Column tablet={8} computer={4} >
                                                <label>
                                                    Date of Service Through{' '}

                                                </label>
                                                <DatePicker
                                                    maxDate={moment().format('MM/DD/YYYY')}
                                                    date_update={date_object => this.calendar_change_handler('to_date', date_object)}
                                                    date={this.state.formatted_to_date}
                                                    //is_required={(is_searched || is_submitted) && error.formatted_from_date}
                                                    id_popper="root"
                                                    id="date-of-service-through"
                                                    styleClass={'claim_indicator_date'}
                                                />
                                            </Grid.Column>
                                           

                                        </Grid>
                                        <Grid>

                                            <Grid.Column tablet={8} computer={16} >
                                                <div>
                                                    <label>Payer Group{' '}</label>
                                                    <div className="values">{this.state.payer_group}</div>
                                                </div>
                                            </Grid.Column>
                                        </Grid>
                                        <Grid>
                                        <Grid.Column computer={16} tablet={8} textAlign="right">
                                            <Button id="clear_search_charge_btn" type='button' onClick={() => this.clear_search_result()} basic >Clear</Button>
                                                <Button id="search_charge_btn" type='submit' primary disabled={this.state.is_search_button_disabled} >Search</Button>
                                            </Grid.Column>
                                        </Grid>
                                    </Grid.Column>
                                </Grid>
                                
                               
                                {this.state.data_searched && this.state.billing_rows.length > 0 &&
                                    <div style={{ marginTop: '10px' }} >
                                        <Dimmer active={this.state.loading}>
                                            <Loader size="massive">Loading</Loader>
                                        </Dimmer>
                                        <Grid>
                                        <Grid.Column computer={16} className="padd-r-0">
                                                <p>Charges</p>
                                            </Grid.Column>
                                            <Grid.Column computer={16}>
                                                <div className="customtable" id="id_billing_container">
                                                    <div className="table-responsive" id="id_billing_settings_table">
                                                        <table className="ui table" id="id-table-billing-settings-form">
                                                            <thead>
                                                                <ClaimIndicatorHeaderComponent
                                                                    columns={this.state.col_fields}
                                                                    rows={this.state.billing_rows_original}
                                                                    onSortChanged={this.on_sort_changed}
                                                                //onColumnMoved={this.on_column_moved}
                                                                />
                                                            </thead>
                                                            <tbody>
                                                            {this.render_billing_rows()}
                                                            {this.render_footer()}
                                                        </tbody>
                                                        
                                                        </table>
                                                    </div>
                                                </div>
                                            </Grid.Column>

                                        </Grid>
                                    </div>
                                }
                                {this.state.data_searched && this.state.billing_rows.length <= 0 &&
                                    <Grid>
                                        <Grid.Column id="charge_review_details_section" computer={16} className="padd-r-0">
                                            <p style={{ textAlign: "center" }}>No Record Available</p>
                                        </Grid.Column>
                                    </Grid>
                                
                                }
                            </Form>
                        </div>
                        <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area sticky_footer" id="applicationFooterSticky" >
                            <Button id="claim_save_btn" type='button' disabled={!(this.state.billing_rows.length > 0)}
                                onKeyDown={set_focus_to_app_header} onClick={() => this.save_handler()} primary >Save</Button>
                        </div>

                    </div>
                    {this.state.show_bummepd_charge_message_popup ?
                        <ConfirmationComponent message={global_constants.charge_exception.message}
                            title='Confirm' show_modal={this.state.show_bummepd_charge_message_popup}
                            onCloseModal={this.on_close_bummepd_charge_popup}
                            save_button_text='Refresh'
                            on_force_save_click={(e) => { this.refresh_bummepd_charge_modal() }} />
                        : ''}
                </React.Fragment>
            )
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        get_patient_search: get_patient_search
    }, dispatch)
}
//Get user and login details from store.
const mapStateToProps = state => {
    return {
        selected_patient: state.patient_details.patient_header,
        user_login_details: state.user_login_details,
        shared_details: state.shared_details,
        patient_details: state.patient_details
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimIndicatorComponent);
