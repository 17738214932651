import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Grid, Header, Modal } from 'semantic-ui-react';
import { clearTimeout, setTimeout } from 'timers';
import { set_focus_on_element_with_id } from './../../shared/tab_navigation_utility';
import { format_date, modal_hide_on_print, modal_show_after_print, modal_show_after_print_ios, execute_print, print_html_data_to_pdf } from './../../shared/utility';
import GridView from '../../shared/component/grid';
import { GRID_NAME } from '../../shared/component/grid/constants';
import * as patient_constants from '../patient_constants';
import { custom_date_format } from '../../shared/utility';
import * as global_constants from '../../global_constants';
import * as _ from 'lodash';
export class AccountNotesPrintNotesComponent extends React.Component<any, any> {
    _is_mounted = false;
    constructor(props) {
        super(props);

        if (this.props.open) {
            this.handle_on_open_modal_window();
        }

        this.state = {
            shown: this.props.open,
            current_page: 0,
            total_page: 1,
            date: this.print_date(),
            time: this.print_time(),
            note_info: this.props.note_info
        }
    }

    // Print current date
    print_date = () => {
        var today = new Date();
        return format_date(new Date(today));
    }

    // Print current time
    print_time = () => {
        var today = new Date();
        var hours: any = today.getHours();

        var display_hours: any = hours % 12;
        display_hours = display_hours ? display_hours : 12; // the hour '0' should be '12'

        var display_hours: any = display_hours < 10 ? '0' + display_hours : display_hours;

        var minutes: any = today.getMinutes();
        minutes = minutes < 10 ? '0' + minutes : minutes;

        var seconds: any = today.getSeconds();
        seconds = seconds < 10 ? '0' + seconds : seconds;

        var ampm = hours >= 12 ? 'PM' : 'AM';

        var time = display_hours + ":" + minutes + ":" + seconds + " " + ampm;
        return time;
    }


    // Print Note/task
    print_note = () => {
        let rowNodes = '';
        this.state.note_info.map(value => {
            if (value.isTask)
                rowNodes = `${rowNodes}${this.getTaskNode(value)}`;
            else
                rowNodes = `${rowNodes}${this.getNode(value)}`;
            return null;
        });
        return rowNodes;
    }

    // Generate Note for Print
    getNode = (value) => (
        `<tr>
            <td colspan="2" style="vertical-align:top;">
                <div class="left"><strong>Note Code:</strong></div>
                <div class="right">${value.note_code === null ? '' : value.note_code}</div>
                <div class="clr"></div>
                <div class="left"><strong>Created Date:</strong></div>
                <div class="right">${value.created_date === null ? '' : value.created_date}</div>
                <div class="clr"></div>
                <div class="left"><strong>Created By:</strong></div>
                <div class="right">${value.created_by === null ? '' : value.created_by} </div>
                <div class="clr"></div>
            </td>
            <td colspan="2" style="vertical-align:top;">${value.note_text === null ? '' : value.note_text} </td>
        </tr>`)

    // Generate Task for Print
    getTaskNode = (value) => (
        `<tr>
            <td colspan="2" style="vertical-align:top;">
                <div class="left" style="vertical-align:top"><span><strong>Description:</strong></span></div>
                <div class="right">${value.task_description}</div>
                <div class="clr"></div>
                <div class="left"><strong>Assignee Type:</strong></div>
                <div class="right">${value.assignee_type}</div>
                <div class="clr"></div>
                <div class="left"><strong>Assignee Individual:</strong></div>
                <div class="right">${(value.task_group != null && value.task_group.task_group_id > 0) ? '' : value.assignee}</div>
                <div class="clr"></div>
                <div class="left"><strong>Assignee Group:</strong></div>
                <div class="right">${(value.task_group != null && value.task_group.task_group_id > 0) ? value.task_group.group_name : ''} </div>
                <div class="clr"></div>
                <div class="left"><strong>Note Type:</strong></div>
                <div class="right">R4 Task </div>
                <div class="clr"></div>
                <div class="left"><strong>Task Type:</strong></div>
                <div class="right">${value.note_code === null ? '' : value.note_code} </div>
                <div class="clr"></div>
                <div class="left"><strong>Due Date:</strong></div>
                <div class="right">${value.due_date} </div>
                <div class="clr"></div>
                <div class="left"><strong>Status:</strong></div>
                <div class="right">${value.task_status} </div>
                <div class="clr"></div>
                <div class="left"><strong>Created By:</strong></div>
                <div class="right">${value.created_by_name}</div>
                <div class="clr"></div>
                <div class="left"><strong>Created Date:</strong></div>
                <div class="right">${value.created_date === null ? '' : value.created_date} </div>
                <div class="clr"></div>
                <div class="left"><strong>Updated By:</strong></div>
                <div class="right">${value.updated_by_name}</div>
                <div class="clr"></div>
                <div class="left"><strong>Updated Date:</strong></div>
                <div class="right">${value.updated_date === null ? '' : value.updated_date} </div>
                <div class="clr"></div>
                <div class="left"><strong>Completed Date:</strong></div>
                <div class="right">${custom_date_format(value.completed_date, global_constants.date_format["mm/dd/yyyy"])}</div>
                <div class="clr"></div>
            </td>
            <td colspan="2" style="vertical-align:top;">
                ${this.getTaskCommentNode(value)}
            </td>
        </tr>`);

    // Generate Task comments for Print
    getTaskCommentNode = (value) => {
        if (value.task_comments && value.task_comments.length > 0) {
            var comment_table_html =
                `<table style='width:100%;border-collapse:collapse;border: 1px solid black'>
                <tr style='width:100%'>
                <th  style="vertical-align:top;  width:60%; border-right: 1px solid black; text-align:left;padding-left:5px"><b>Comment</b></th>
                <th  style="vertical-align:top;  width:40%; padding-left:10px; text-align:left"><b>Created By</b></th>
                </tr>`;

            value.task_comments.map(comment => {
                comment_table_html = comment_table_html +
                    `<tr style="border-top: 1px solid black;width:100%">
                    <td  style="vertical-align:top; border-right: 1px solid black; width:60%;border-bottom-width:0px">${comment.comment_text}</td>
                    <td  style="vertical-align:top; width:40%;padding-left:10px; border-bottom-width:0px">${comment.created_by}&nbsp; ${custom_date_format(comment.created_date, global_constants.date_format["mm/dd/yyyy"])}</td>
                     </tr>`
            });

            comment_table_html = comment_table_html + `</table>`;
            return comment_table_html;
        }
        else {
            return "";
        }
    };

    
    // Print Option
    print_page = () => {
        var print_html_data: any ="";
        print_html_data += `<div class="notes-table">`;
        print_html_data += `<table><thead>
            <tr>
            <td style="width:120px;"><strong>Note For:</strong></td>
            <td style="width:30%"> ${this.props.patient_name} </td>
            <td style="width:120px"><strong>Account Number:</strong></td>
            <td>  ${this.props.patient_id}  </td>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td style="padding-bottom:10px!important"><strong>Company:</strong></td>
            <td style="padding-bottom:10px!important"> ${this.props.gpms_code}-${this.props.company_name}</td>
            <td style="padding-bottom:10px!important"></td>
            <td style="padding-bottom:10px!important"></td>
            </tr>
            <tr>
            <td><strong>Printed Date:</strong></td>
            <td>${this.state.date}</td>
            <td><strong>Printed Time:</strong></td>
            <td> ${this.state.time}</td>
            </tr>
            <tr >
            <td colspan="4"><div class="border"></div></td>
            </tr>
            <tr>
            <td style="font-size:16px"><strong>Note Info:</strong></td>
            <td></td>
            <td style="font-size:16px"><strong> Note Text: </strong></td>
            <td></td>
            </tr>`;
        print_html_data += this.print_note();
        print_html_data += `</tbody></table></div>`;
        print_html_data_to_pdf(print_html_data,'printButton','Account Notes');
        /*
        modal_hide_on_print(print_html_data);
        if (window.print) {
            if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
                if (this._is_mounted) {
                    this.setState({
                        timeout_print: setTimeout(() => {
                            execute_print();
                        }, 100)
                    });
                    this.setState({
                        timeout: setTimeout(() => {
                            modal_show_after_print_ios();
                        }, 5000)
                    });
                }
            }
            else {                
                if (window.matchMedia) {
                    var mediaQueryList = window.matchMedia('print');
                    mediaQueryList.addListener(function(mql) {
                        if (mql.matches) {
                        } else {
                            afterPrint();
                        }
                    });
                }
                var afterPrint = () =>  modal_show_after_print();
                // These are for Firefox
                window.onafterprint = afterPrint;
                execute_print();
            }
        }
        */
        return;
    }

    // Close Modal
    close_modal = () => {
        if (this._is_mounted) {

            if (this.props.is_patient_header) {
                // set focus to parent element
                set_focus_on_element_with_id("print_selected_notes_button_patient_header");
            } else {
                // set focus to parent element
                set_focus_on_element_with_id("print_selected_notes_button");
            }

            this.setState({
                shown: false
            });
        }
        this.props.onCloseModal();
        return;
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.open !== this.props.open) {
            if (this._is_mounted) {

                this.setState({
                    shown: this.props.open
                });
            }

        }

        if (previousProps.note_info !== this.props.note_info) {
            if (this._is_mounted) {
                this.setState({
                    note_info: this.props.note_info,
                    current_page: 0,
                    total_page: 1,
                    date: this.print_date(),
                    time: this.print_time()
                });
            }
            this.pg_count();
        }

    }

    componentDidMount() {
        this._is_mounted = true;
        this.pg_count();
    }
    
    componentWillUnmount() {
        this._is_mounted = false;
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
        }
        if (this.state.timeout_print) {
            clearTimeout(this.state.timeout_print);
        }
    }
    // Page Count
    pg_count = () => {
        var tot_page = this.state.total_page;
        var quotient = 0;
        var remainder = 0;

        if (this.props.note_info.length > 10) {
            quotient = Math.floor(this.props.note_info.length / 10);
            remainder = (this.props.note_info.length % 10);
            if (remainder != 0) {
                tot_page = (quotient + 1);
            } else {
                tot_page = quotient;
            }

            this.setState({
                total_page: tot_page
            });
        }
    }

    // Pagination - Next
    on_next_click = () => {
        if ((this.state.current_page + 1) < this.state.total_page) {
            if (this._is_mounted) {
                this.setState({
                    current_page: (this.state.current_page + 1)
                });
            }
        }
    }

    // Pagination - Previous
    on_previous_click = () => {
        if (this.state.current_page > 0) {
            if (this._is_mounted) {
                this.setState({
                    current_page: (this.state.current_page - 1)
                });
            }
        }
    }

    // Create UI
    create_ui = () => {
        let show_data = [];
        for (var i = (this.state.current_page * 10); i < (this.state.current_page * 10) + 10; i++) {
            if (typeof this.state.note_info[i] === 'undefined') {

            } else {
                show_data.push(this.state.note_info[i]);
            }
        }

        return show_data.map(value => (

            <div key={value.id}>
                {(value.isTask === true) &&
                    <div>
                    <Grid columns="3">
                        <Grid.Column>
                            <label>Description:</label>
                            <div>
                                <span>{value.task_description}</span>
                            </div>
                        </Grid.Column>
                        <Grid.Column>
                        <label>Assignee Type:</label>
                            <div>
                                <span>{value.assignee_type}</span>
                            </div>
                        </Grid.Column>
                        <Grid.Column>
                        <label>Assignee Individual:</label>
                            <div>
                                <span>{(value.task_group != null && value.task_group.task_group_id > 0) ? '' : value.assignee}</span>
                            </div>
                        </Grid.Column>
                    </Grid>
                    <Grid columns="3">
                        <Grid.Column>
                            <label>Assignee Group:</label>
                            <div>
                                <span>{(value.task_group != null && value.task_group.task_group_id > 0) ? value.task_group.group_name : ''}</span>
                            </div>
                        </Grid.Column>
                        <Grid.Column>          
                        </Grid.Column>
                        <Grid.Column>
                        </Grid.Column>
                    </Grid>
                   </div>
                }
                <Grid>
                    <Grid.Column>
                        <Header as='h3' dividing>
                            Note Info:
                        </Header>
                    </Grid.Column>
                </Grid>
                 {(!value.isTask) &&
                     <Grid columns="3">
                         <Grid.Column>
                             <label>{(value.isTask === true) ? 'Task Type' : 'Note Type'}:</label>
                             <div>
                                 <span>{value.note_code === null ? '' : value.note_code}</span>
                             </div>
                         </Grid.Column>
                         <Grid.Column>
                             <label>Created Date:</label>
                             <div>
                                 <span>{value.created_date === null ? '' : value.created_date}</span>
                             </div>
                         </Grid.Column>
                         <Grid.Column>
                             <label>Created By:</label>
                             <div>
                                 <span>{value.created_by === null ? '' : value.created_by}</span>
                             </div>
                         </Grid.Column>
                     </Grid>
                 }
                   {(value.isTask === true) &&
                            <div>
                            <Grid columns="3">
                                <Grid.Column>
                                    <label>Note Type:</label>
                                    <div>
                                        <span>R4 Task</span>
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <label>Task Type:</label>
                                    <div>
                                        <span>{value.note_code === null ? '' : value.note_code}</span>
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <label>Due Date:</label>
                                    <div>
                                        <span>{value.due_date}</span>
                                    </div>
                                </Grid.Column>
                                
                            </Grid>
                            <Grid columns="3">
                               <Grid.Column>
                                   <label>Status:</label>
                                   <div>
                                       <span>{value.task_status}</span>
                                   </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <label>Created By:</label>
                                    <div>
                                <span>{value.created_by_name} {value.created_date === null ? '' : value.created_date}</span>
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <label>Updated By:</label>
                                    <div>
                                <span>{value.updated_by_name} {value.updated_date === null ? '' : value.updated_date}</span>
                                    </div>
                                </Grid.Column>
                            </Grid>
                            <Grid columns="3">
                                <Grid.Column>
                                    <label>Completed Date:</label>
                                    <div>
                                <span>{custom_date_format(value.completed_date, global_constants.date_format["mm/dd/yyyy"])}</span>
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                </Grid.Column>
                                <Grid.Column>
                                </Grid.Column>
                            </Grid>
                       </div>
                   }
                  
                <Grid>
                    <Grid.Column>
                        <Header as='h3' dividing>
                            Note Text:
                                    </Header>
                    </Grid.Column>
                </Grid>

                        {(!value.isTask) &&
                            <Grid>
                                <Grid.Column>
                                    <label>{value.note_text === null ? '' : value.note_text}</label>
                                </Grid.Column>
                            </Grid>
                        }
                        {(value.isTask) &&
                            <Grid>
                    <Grid.Column>
                        <GridView
                            id={"account_task_comments_grid_id_"+value.task_id}
                            row={value.task_comments || []}
                            column={patient_constants.account_task_comments_configuration.column_defs}
                            gridAutoHeight={true}
                            checkboxSelection={false}
                            name={GRID_NAME.ACCOUNT_TASK_COMMENTS}
                            enableEnterOnNavigation={false}
                            handleCheckboxNavigation={false}
                            wrapperClass={"account_task_comments_grid_wrapper"}
                            getRowHeight={(params) => this.onHeight(params, value.task_id) }
                        />
                    </Grid.Column>
                            </Grid>
                        }

            </div>
        ))
    }
  
    onHeight(params: any, task_id: any) {
        let singleRowHeight: number;
        try {
            const grid = document.getElementById(`account_task_comments_grid_id_${task_id}`);
            const row = grid.querySelectorAll(`[row-index="${params.node.id}"]`)[1];
            const cols = row.querySelectorAll('div');
            singleRowHeight = _.max(Array.from(cols).map(col => col.clientHeight));
        } catch (error) {
            singleRowHeight = patient_constants.defaultRowHeight;
        }
        return singleRowHeight;
    }

    handle_on_open_modal_window = () => {
        setTimeout(() => {
            if(! document) return;
            let closeIcons: NodeListOf<HTMLElement> = document.querySelectorAll('i.close.icon') as NodeListOf<HTMLElement>;
            let closeIcon = closeIcons[closeIcons.length - 1]
            closeIcon.tabIndex = 0;
            closeIcon.id = "account_notes_model_print_selected_notes_icon";
            closeIcon.addEventListener("keydown", function (event) {
                if (event.shiftKey && event.keyCode == 9) {
                    event.preventDefault();
                    let print_notes_button = document.getElementById("printButton");
                    print_notes_button.focus();
                }
                if (!event.shiftKey && event.keyCode == 9) {
                    event.preventDefault();
                    let print_notes_button = document.getElementById("account_notes_close_button");
                    print_notes_button.focus();
                }
                if (event.keyCode == 13) {
                    event.preventDefault();
                    var el = event.target as HTMLElement
                    el.click();
                }
            });

            set_focus_on_element_with_id("printButton");
        }, 10)
    }

    handle_focus_on_tab_for_close = (event) => {
        if (event.shiftKey && event.keyCode == '9') {
            event.preventDefault();

            set_focus_on_element_with_id("account_notes_model_print_selected_notes_icon");
            
        }

        if (!event.shiftKey && event.keyCode == '9') {
            event.preventDefault();

            set_focus_on_element_with_id("printButton");
        }
    }


    handle_focus_on_tab_for_print = (event) => {
        if (event.shiftKey && event.keyCode == '9') {
            event.preventDefault();

            set_focus_on_element_with_id("account_notes_close_button");            
        }

        if (!event.shiftKey && event.keyCode == '9') {
            event.preventDefault();

            set_focus_on_element_with_id("account_notes_model_print_selected_notes_icon");
        }
    }


    render() {

        // Check Empty Note Info
        if ((Object.keys(this.props.note_info).length === 0)) return null;

        return (

            <Modal
                onClose={this.close_modal}
                centered={false}
                className="searchCompany print-notes-modal"                
                open={this.state.shown}
                closeIcon
                closeOnDimmerClick={false}
                id="printPopup"
            >
                <Modal.Header>Account Notes</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Grid columns="3">
                            <Grid.Column>
                                <label>Note For:</label>
                                <div>
                                    <span>{this.props.patient_name}</span>
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <label><label>Account Number:</label></label>
                                <div>
                                    <span>{this.props.patient_id}</span>
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <label>Company:</label>
                                <div>
                                    <span>{this.props.gpms_code} - {this.props.company_name}</span>
                                </div>
                            </Grid.Column>
                        </Grid>
                        <Grid columns="3">
                            <Grid.Column>
                                <label>Printed Date:</label>
                                <div>
                                    <span>{this.state.date}</span>
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <label>Printed Time:</label>
                                <div>
                                    <span>{this.state.time}</span>
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <label> &nbsp;</label>
                                <div>
                                    <span> &nbsp;</span>
                                </div>
                            </Grid.Column>
                        </Grid>
                        {this.create_ui()}
                    </Modal.Description>

                    <Grid className="mar-t-15">
                        <Grid.Column align="center" mobile={16} tablet={16} computer={16}>

                            <div>
                                <b>Page {this.state.current_page + 1}/{this.state.total_page} </b>
                                {(this.props.note_info.length <= 10) ? '' : <div><Button basic onClick={() => this.on_previous_click()} disabled={this.state.current_page === 0 ? true : false}>Previous</Button> <Button basic onClick={() => this.on_next_click()} disabled={this.state.current_page === (this.state.total_page - 1) ? true : false}>Next</Button></div>}
                            </div>

                        </Grid.Column>
                    </Grid>
                    {
//                        tabIndex={0} id="print_report_button" 
  //                  tabIndex={0} id="account_notes_close_button" onKeyDown={(event) => handleFocusOnTab(event, "print_report_button")} 
                    }
                    <Grid>
                        <Grid.Column mobile={16} tablet={16} computer={16}>
                            <Button id="printButton" onKeyDown={this.handle_focus_on_tab_for_print}  floated="right" primary onClick={this.print_page.bind(this)}>Print</Button>
                            <Button id="account_notes_close_button" onKeyDown={this.handle_focus_on_tab_for_close} style={{ marginRight: '4px' }} floated="right" basic onClick={this.close_modal.bind(this)}>Close</Button>
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        patient_details: state.patient_details
    };
}


export default withRouter(connect(mapStateToProps)(AccountNotesPrintNotesComponent))
