//Utitlity functions
import * as moment from 'moment';
import { patientCreditType } from './payment_constants';
import * as report_constants from '../reports/report_constants';
import { enum_posting_type, batch_payments_columns, enum_page_type } from './batch_payment/batch_payment_constants';
import { saveAs } from '../shared/file-saver.min';
import { print_html_data_to_pdf } from './../shared/utility';
import { money_type } from '../global_constants';
export var batch_footer_object = {};

// get money type text from array of object
export const get_money_type_text = (value, payment_method_list) => {
  const payment_money_array = payment_method_list;
  var money_text = '';
  for (var i = 0; i < payment_money_array.length; i++) {
    if (payment_money_array[i].value == value) {
      money_text = payment_money_array[i].text;
    }
  }
  return money_text;
};


export const get_cc_number = data => {
  const { payment_money_type, check_num, cc_num } = data;
  if (payment_money_type == 1) {
    return '';
  } else if (payment_money_type == 2) {
    //return check_num || '';
    return cc_num || '';
  } else if (payment_money_type == 3) {
    return cc_num || '';
  }
};

export const get_place_of_service = place_of_service => {
  let pos = '';
  if (place_of_service) {
    const { code, name } = place_of_service;
    if (code) {
      pos = `${code}`;
    }
    if (name) {
      pos = `${pos} - ${name}`;
    }
  }
  return pos;
};

export const get_provider = billing_provider => {
  let pos = '';
  if (billing_provider) {
    const { code, description } = billing_provider;
    if (code) {
      pos = `${code}`;
    }
    if (description) {
      pos = `${pos} - ${description}`;
    }
  }
  return pos;
};

/**
 * Format date string
 * @param date
 * @param pattern
 */
export const format_date = (date, pattern) => {
    if (date) {
        return moment(date).format(pattern);
    }
    return '';
}

export const NO_INSURANCE_REQUIRED_TRANSACTIONS = [
    patientCreditType.copay,
    patientCreditType.coinsurance,
    patientCreditType.deductible,
    patientCreditType.other,
    patientCreditType.paymentonaccount,
    patientCreditType.wellness,
    patientCreditType.patientrefund
];
export const check_copay_transaction_type = (value) => {
    if (value == patientCreditType.copay || value == patientCreditType.coinsurance || value == patientCreditType.deductible
        || value == patientCreditType.other || value == patientCreditType.paymentonaccount || value == patientCreditType.wellness
    ) {
        return true
    } else {
        return false
    }
}

const yyyy_mm_dd_number = date => {
  if (date === undefined || date === null || date.length < 10) {
    return null;
  }

  let year = date.substring(0, 4);
  let month = date.substring(5, 7);
  let day = date.substring(8, 10);
  return year * 10000 + month * 100 + day;
};

export const yyyy_mm_dd_comparator = (date1, date2) => {
  var date1Number = yyyy_mm_dd_number(date1);
  var date2Number = yyyy_mm_dd_number(date2);
  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }
  return date1Number - date2Number;
};
export const get_popper_id = () => {
  if (window.screen.width == 1366 && window.screen.height == 768) {
    return 'id_div_popper';
  }
  return null;
};

//Function calls on click of print button
export const batch_payments_print_data = (rows, title, posting_type, page_type, denied_crossover_ff: boolean) => {

    let column_data = JSON.parse(JSON.stringify(batch_payments_columns));
    if (!denied_crossover_ff)
    {
        column_data = column_data.filter(column => {
            if (column.headerName != 'Crossover') return column;
        });
    }

    let header_data = column_data.filter(colData => {
    if (colData.display_in.indexOf(posting_type) > -1) {
        if (page_type == enum_page_type.batch_adjustment) {
            if (colData.headerName == 'Units' || colData.headerName == 'Payment') {
                // return colData;
            } else {
                return colData;
            }

        } else {
            return colData;
        }
    }
  });

  let report_row = batch_row_data_formatter(rows, header_data);
  let printContent = '';
  printContent += '<div class="reportPrint">';
  printContent += title;
  printContent += '<table id="mainGrid">';
  printContent += '<thead>';
  printContent += '<tr>';

  header_data.map(colData => {
    printContent += `<th style="text-align:${colData.align ? colData.align : 'left'}">${colData.headerName}</th>`;
  });

  printContent += `</tr>`;
  printContent += '</thead>';
  printContent += '<tbody>';
  if (report_row.length > 0) {
    report_row.forEach(data => {
      if (posting_type == enum_posting_type.multiple_entry) {
        printContent += `<tr style='vertical-align: top;'>`;
      } else {
        printContent += `<tr>`;
      }
      header_data.map((colData, _index) => {
        if (data[colData.field]) {
          printContent += `<td style="text-align:${colData.align ? colData.align : 'left'}">${
            Array.isArray(data[colData.field]) ? data[colData.field].join('<br><br>') : data[colData.field]
          }
          </td>`;
        } else {
          printContent += `<td style="text-align:${colData.align ? colData.align : 'left'}"></td>`;
        }
      });
      printContent += '</tr>';
    });
  } else {
    printContent +=
      '<tr><td colspan="100%" class="no_records">' + report_constants.messages.no_records_found + '</td></tr>';
  }
  if (report_row.length > 0) {
    let footer_obj = batch_footer_object;
    let footer_cell;
    if (footer_obj) {
      printContent += '<tr>';
      header_data.map(colData => {
        footer_cell = footer_obj[colData.field] ? footer_obj[colData.field] : '';
        printContent += `<td style="text-align:${colData.align ? colData.align : 'left'}">${footer_cell}</td>`;
      });
      printContent += '</tr>';
    }
  }
  printContent += '</tbody>';
  printContent += '</table>';
  printContent += '</div>';
  let page_title = '';    if (page_type == enum_page_type.batch_adjustment) {
      page_title = 'Batch Adjustments';
  } else {
      page_title = 'Batch Payments';
  }
  print_html_data_to_pdf(printContent, 'print_report_button', page_title);
};

export const batch_payments_export_data = (rows, title, report_name, posting_type, page_type, denied_crossover_ff: boolean) => {
  let csv = [];
  let table_header = [];
  let table_rows = [];
  let title_row = [];

    let column_data = JSON.parse(JSON.stringify(batch_payments_columns));
    if (!denied_crossover_ff) {
        column_data = column_data.filter(column => {
            if (column.headerName != 'Crossover') return column;
        });
    }
    console.log()
    //batch_payments_columns
    let header_data = column_data.filter(colData => {
      if (colData.display_in.indexOf(posting_type) > -1) {
          if (page_type == enum_page_type.batch_adjustment) {
              if (colData.headerName == 'Units' || colData.headerName == 'Payment') {
                 // return colData;
              } else {
                  return colData;
              }
         
          } else {
              return colData;
          }
   
    }
  });
  let report_row = batch_row_data_formatter(rows, header_data);
  title_row.push(title);
  csv.push(title_row);

  header_data.map((colData, index) => {
    if (index == header_data.length - 1) {
      table_header.push(colData.headerName + '\n');
    } else {
      table_header.push(colData.headerName);
    }
  });
  csv.push(table_header);

  if (report_row.length > 0) {
    report_row.forEach((data: { [x: string]: any }) => {
      header_data.map((colData, index) => {
        if (data[colData.field]) {
          if (index == header_data.length - 1) {
            let new_row =''
            // let new_row= Array.isArray(data[colData.field]) ? `"${data[colData.field].join(',')}"` : data[colData.field];
            if (Array.isArray(data[colData.field])) {
              new_row =`"${data[colData.field].join('\n\n')}"`
            } else {
              new_row = data[colData.field]
            }
            table_rows.push(new_row + '\n');
          } else {
            let new_row =''
            if (Array.isArray(data[colData.field])) {
              new_row =`"${data[colData.field].join('\n\n')}"`
            } else {
              new_row = data[colData.field]
            }
            table_rows.push(new_row);
          }
        } else {
          if (index == header_data.length - 1) {
            table_rows.push('\n');
          } else {
            table_rows.push('');
          }
        }
      });
      csv.push(table_rows);
      table_rows = [];
    });
  } else {
    table_rows.push(report_constants.messages.no_records_found);
  }

  let footer_obj = batch_footer_object;
  let footer_cell: any;
  let footer_row = [];
  if (footer_obj) {
    header_data.map((colData, index) => {
      footer_cell = footer_obj[colData.field] ? footer_obj[colData.field] : '';
      if (index == header_data.length - 1) {
        footer_row.push(footer_cell + '\n');
      } else {
        footer_row.push(footer_cell);
      }
    });
    csv.push(footer_row);
  }

  let csvContent: any = csv.map(e => {
    e = e.map((row: string) => {
      if (row && row != '' && row.toString().indexOf(',') > -1) {
        row = row.replace(/,/g, ' ');
      }
      return row;
    });
    return e.join(',');
  });

  let blob = new Blob(csvContent, {
    type: 'text/csv;charset=utf-8'
  });

  //Blob.js
  saveAs(blob, report_name + '.csv');
};

export const batch_row_data_formatter = (report_rows, report_cols) => {
  let footer = initialize_footer_obj(report_cols);
  let formatted_report_rows = [...report_rows];
  // Column Formatting
  formatted_report_rows = formatted_report_rows.map(item => {
    var row = { ...item };
    report_cols.map(col => {
      // Multiple Type
      if (Array.isArray(row[col.field])) {
        if (col.type == 'currency' || col.type == 'number') {
          row[col.field] = row[col.field].map(sub_item => {
            //Adding column to get footer.
            if(col.field != 'xfer_det_amounts'){
              footer[col.field] += sub_item.amount ? parseFloat(sub_item.amount) : 0;
            }
            //Formatting of Currency,Percentage and Number type data
            return format_as_datatype(sub_item.amount, col.type);
          });
        } else {
          row[col.field] = row[col.field].map(sub_item => {
            //Adding column to get footer.
            return format_as_datatype(sub_item.title, col.type)
            // return sub_item.id
            //   ? format_as_datatype(sub_item.id, col.type)
            //   : format_as_datatype(sub_item.text, col.type);
          });
        }
      } else {
        if (col.type == 'currency') {
          //Adding column to get footer.
          if(col.field != 'xfer_det_amounts'){
            footer[col.field] += row[col.field] ? parseFloat(row[col.field]) : 0;
          }
          //Formatting of Currency,Percentage and Number type data
          row[col.field] = format_as_datatype(row[col.field], col.type);
        } else if (col.type == 'number') {
            row[col.field] = format_as_datatype(row[col.field], col.type);
        } else {
          row[col.field] = format_as_datatype(row[col.field], col.type);
        }
      }
    });
    return row;
  });

  // Footer Formatting
  report_cols.map((col, index) => {
    if (col.field != 'xfer_det_amounts' && col.type == 'currency') {
      footer[col.field] = format_as_datatype(footer[col.field], col.type, true);
    }
  });

  batch_footer_object = footer;
  return formatted_report_rows;
};

export const initialize_footer_obj = column => {
  let footer_obj = {};
  let first_field = '';
  column.map((value, index) => {
    if (value.field.indexOf('charge_id') > -1 && !first_field) {
      first_field = value.field;
    }
    if (value.field != 'xfer_det_amounts' && value.type == 'currency') {
      footer_obj[value.field] = 0;
      footer_obj[first_field] = 'Total';
    } else {
      footer_obj[value.field] = null;
    }
  });
  return footer_obj;
};

// Format data on basis of data type
export const format_as_datatype = (value, data_type, is_footer = false) => {
  switch (data_type) {
    case 'date':
      value = value != null || value != undefined ? report_constants.dateFormatter(value) : '';
      break;
    case 'currency':
      if (is_footer) {
        value = value ? report_constants.currencyFormatter(value) : '$0.00';
      } else {
        value = value.toString() != '' ? report_constants.currencyFormatter(value) : '';
      }
      break;
    case 'number':
      if (is_footer) {
        value = value ? report_constants.formatNumber(value) : 0;
      } else {
        value = value.toString() != '' ? report_constants.formatNumber(value) : '';
      }      
      break;
    case 'yes_no':
      value = value ? value : 'N';      
      break;
    default:
      value = value != null || value != undefined ? value.toString().trim() : '';
  }
  return value;
};


export const decodeURIComponentSafe =(uri, mod)=> {
  let out = new String(),
    arr,
    i = 0,
    l,
    x;
  typeof mod === 'undefined' ? (mod = 0) : 0;
  arr = uri.split(/(%(?:d0|d1)%.{2})/);
  for (l = arr.length; i < l; i++) {
    try {
      x = decodeURIComponent(arr[i]);
    } catch (e) {
      x = mod ? arr[i].replace(/%(?!\d+)/g, '%') : arr[i];
    }
    out += x;
  }
  return out;
}
/**
 * Detect Mac Safari
 */
export const is_mac_safari = () => {
    const ua = navigator.userAgent.toLowerCase();
    var is_mac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    //var is_mac = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i) ? true : false;
    if (is_mac && ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1) {
        return true;
    }
    return false;
}

export const format_amount=(num)=> {
    return !isNaN(+num) ? (+num).toFixed(2) : 0;
}

export const edit_payments_conf = {
    column_def: [
        { headerName: 'Patient ID', field: 'patient_id', width: 105, minWidth: 105, sort: true },
        { headerName: 'Date of Service', field: 'date_of_service', width: 60, minWidth: 60, sort: true },
        { headerName: 'Deposit Date', field: 'deposit_date', width: 155, minWidth: 155, sort: true },
        { headerName: 'Payment', field: 'payment_amount', width: 60, minWidth: 60, sort: true },
        { headerName: 'Payment Code', field: 'payment_code', width: 155, minWidth: 155, sort: true },
        { headerName: 'Adjustment', field: 'adjustment_amount', width: 155, minWidth: 155, sort: true },
        { headerName: 'Adjustment Code', field: 'adjustment_code', width: 136, minWidth: 136, sort: true },
        { headerName: 'Transfer', field: 'xfer_amount', width: 136, minWidth: 136, sort: true },
        { headerName: 'Transfer Detail', field: 'pat_resp_amt', width: 136, minWidth: 136, sort: true },
        { headerName: 'From Insurance', field: 'from_insurance', width: 136, minWidth: 136, sort: true },
        { headerName: 'Transfer to Payer', field: 'transfer_to_payer', width: 136, minWidth: 136, sort: true },
        { headerName: 'Payment Type', field: 'money_type', width: 136, minWidth: 136, sort: true },
        { headerName: 'Batch', field: 'batch_num', width: 136, minWidth: 136, sort: true },
        { headerName: 'Adjustment Qualifier', field: 'adjustment_qualifier', width: 136, minWidth: 136, sort: false },
        { headerName: 'Adjustment Reason', field: 'adjustment_reason', width: 136, minWidth: 136, sort: false }
    ],
  update_column_def: [
    { headerName: 'Date of Service', field: 'date_of_service', width: 60, minWidth: 60, sort: true},
    { headerName: 'Procedure Code', field: 'proc_code', width: 60, minWidth: 60, sort: true},
    { headerName: 'Deposit Date', field: 'deposit_date', width: 155, minWidth: 155, sort: true},
    { headerName: 'Payment', field: 'payment_amount', width: 60, minWidth: 60, sort: true },
    { headerName: 'Payment Code', field: 'payment_code', width: 155, minWidth: 155, sort: true },
    { headerName: 'Adjustment', field: 'adjustment_amount', width: 155, minWidth: 155, sort: true },
    { headerName: 'Adjustment Code', field: 'adjustment_code', width: 136, minWidth: 136, sort: true },
    { headerName: 'Transfer', field: 'xfer_amount', width: 136, minWidth: 136, sort: true },
    { headerName: 'Transfer Detail', field: 'pat_resp_amt', width: 136, minWidth: 136, sort: true },
    { headerName: 'From Insurance', field: 'from_insurance', width: 136, minWidth: 136, sort: true },
    { headerName: 'Transfer to Payer', field: 'transfer_to_payer', width: 136, minWidth: 136, sort: true },
    { headerName: 'Payment Type', field: 'money_type', width: 136, minWidth: 136, sort: true },
    { headerName: 'Check Number', field: 'check_num', width: 136, minWidth: 136, sort: true },
    { headerName: 'Batch', field: 'batch_num', width: 136, minWidth: 136, sort: true },
    { headerName: 'Adjustment Qualifier', field: 'adjustment_qualifier', width: 136, minWidth: 136, sort: false },
    { headerName: 'Adjustment Reason', field: 'adjustment_reason', width: 136, minWidth: 136, sort: false }
    ]    
};

export const prepare_insurance_code = (item, type: any, printable = false) => {
  let label = null;
  let title = null;
  let INSURANCE = {
    FROM: 'FROM',
    TRANSFER: 'TRANSFER'
  };
  if (type == INSURANCE.FROM) {
    title = item.from_ins_code_id || '';
    label = item.from_ins_code || '';
    label += item.from_ins_name ? ` - ${item.from_ins_name}` : '';
    label += item.from_address ? `, ${item.from_address}` : '';
    label += item.from_city ? `, ${item.from_city}` : '';
    label += item.from_state ? `, ${item.from_state}` : '';
    label += item.from_zip ? `, ${item.from_zip}` : '';
  } else {
    title = item.xfer_ins_code_id || '';
    label = item.xfer_ins_code || '';
    label += item.xfer_ins_name ? ` - ${item.xfer_ins_name || ''}` : '';
    label += item.xfer_state ? `, ${item.xfer_state || ''}` : '';
    label += item.xfer_city ? `, ${item.xfer_city || ''}` : '';
    label += item.xfer_state ? `, ${item.xfer_state || ''}` : '';
    label += item.xfer_zip ? `, ${item.xfer_zip || ''}` : '';
  }
  return printable ? label : { label: label, title: title };
};

export const get_check_credit_num = (form_object)=> {
  return form_object.money_type != money_type.CREDIT_CARD_NUM &&
    form_object.money_type != money_type.DEBIT_CARD_NUM &&
    form_object.check_num != null
    ? form_object.check_num
    : (form_object.money_type == money_type.CREDIT_CARD_NUM || form_object.money_type == money_type.DEBIT_CARD_NUM) &&
      form_object.credit_card_num != null
    ? form_object.credit_card_num
    : '';
}