import * as React from 'react';
import * as moment from 'moment';
import { connect } from 'react-redux';
import { Button, Grid, Input, Segment } from 'semantic-ui-react';
import { Tab, Tabs, Tooltip } from '@blueprintjs/core';
import { toastr as toaster } from 'react-redux-toastr';
import { mandatory_fields } from '../constants';
import * as billingPaneConstants from '../provider_billing_pane_constants';
import * as local_storage from '../../../shared/local_storage_utility';
import { ConcreteCombinationsLimit } from './providerBilling/provider_billing_concrete_combinations_limit';
import DateRangePickerComponent from '../../../shared/component/date_range_component';
import { enum_user_type_r3_id } from '../../../global_constants';
import { insurance_name, provider_name } from '../utility';
import MultiSelectPanelComponent from '../../../shared/component/multi_select_panel_component';
import { ProviderBillingService } from './providerBilling/provider_billing_service';
import { ProviderBillingValidator } from './providerBilling/provider_billing_validator';
import Selection from '../../../shared/component/selection_component';
import { set_focus_on_element_with_id } from '../../../shared/tab_navigation_utility';
import { statusSelectOptions } from '../provider_billing_pane_constants';
interface IFormFields {
    location_id: number;
    claim_hold_id: number;
    claim_hold_name: string;
    claim_delay_reason_id: string;
    status: React.ReactText;
    provider_ids: number[];
    insurance_class_ids: number[];
    insurance_code_ids: number[];
    start_date: any;
    end_date: any;
}
class ClaimHoldsAddEditDrawerComponent extends React.Component<any, any> {
    _isMount = false;
    company_service_type: any;
    current_user_type: any;
    is_user_partner_customer = false;
    min_date = '1900-01-01T00:00:00';
    max_date = '2999-01-01T00:00:00';
    private readonly claimDelayReasonSelectOptions = [];
    private readonly providerCredentialedInsuranceClasses = [];
    private readonly statusSelectOptions = statusSelectOptions;
    private readonly tabInsuranceClassesData = [];
    private readonly tabInsuranceCodesData = [];
    private readonly tabProvidersData = [];
    private validator: any = new ProviderBillingValidator();
    private providerBillingService = new ProviderBillingService();
    required_filed: any = {
        claim_hold_name: true,
        claim_hold_dos: true
    };

    private currentFormFields: IFormFields = {
        location_id: null,
        claim_hold_id: null,
        claim_hold_name: '',
        status: 'true',
        claim_delay_reason_id: '31',
        start_date: null,
        end_date: null,
        provider_ids: [],
        insurance_class_ids: [],
        insurance_code_ids: []
    };

    constructor(props) {
        super(props);
        this.state = {
            formFields: this.props.locationClaimHold.originalRowData,
            isSubmitted: false,
            externalErrors: {},
            formErrors: {},
            ...this.handle_tabs_on_load()
        };

        this.currentFormFields = {
            ...this.props.locationClaimHold.originalRowData,
            start_date:
                this.props.locationClaimHold.originalRowData.start_date == this.min_date
                    ? null
                    : this.props.locationClaimHold.originalRowData.start_date
                        ? moment(moment(this.props.locationClaimHold.originalRowData.start_date).format('MM/DD/YYYY'))
                        : null,
            end_date:
                this.props.locationClaimHold.originalRowData.end_date == this.max_date
                    ? null
                    : this.props.locationClaimHold.originalRowData.end_date
                        ? moment(moment(this.props.locationClaimHold.originalRowData.end_date).format('MM/DD/YYYY'))
                        : null
        };

        this.tabProvidersData = this.props.providers.map((val, index) => {
            return { value: val.provider_id, name: provider_name(val) };
        });

        this.tabInsuranceClassesData = this.props.insuranceClasses.map((val, index) => {
            return { value: val.id, name: insurance_name(val) };
        });

        this.tabInsuranceCodesData = this.props.insuranceCodes.map((val, index) => {
            return { value: val.insurance_code_id, name: val.insurance_display_name };
        });

        if (this.props.claim_delay_reason) {
            this.claimDelayReasonSelectOptions = this.props.claim_delay_reason;
        }
        else {
            this.claimDelayReasonSelectOptions = [{
                "key": 0,
                "value": 31,
                "text": "Pending Location Credentialing"
            }];
        }


        const auth_data = local_storage.get('auth_data');
        if (auth_data && auth_data.user_type_r3_id) {
            this.current_user_type = auth_data.user_type_r3_id;
            this.company_service_type = auth_data.company_service_type;
        }
        if (
            this.current_user_type == enum_user_type_r3_id.Partner ||
            this.current_user_type == enum_user_type_r3_id.Customer
        ) {
            this.is_user_partner_customer = true;
        }
    }

    componentDidMount() {
        this._isMount = true;
        setTimeout(() => {
            set_focus_on_element_with_id('claim_hold_name_input');
        }, 0);

    }

    handle_insurance_class_on_change_selected = (selected_list: number[]): void => {
        this.currentFormFields.insurance_class_ids = selected_list;
        if (selected_list.length > 0) {
            this.setState({
                disable_insurance_code_tab: true
            });
        } else {
            this.setState({
                disable_insurance_code_tab: false
            });
        }
    };

    handle_insurance_code_on_change_selected = (selected_list: number[]): void => {
        this.currentFormFields.insurance_code_ids = selected_list;
        if (selected_list.length > 0) {
            this.setState({
                disable_insurance_class_tab: true
            });
        } else {
            this.setState({
                disable_insurance_class_tab: false
            });
        }
    };

    handle_providers_on_change_selected = (selected_list: number[]): void => {
        this.currentFormFields.provider_ids = selected_list;
    };

    handle_tabs_on_load() {
        const { insurance_class_ids, insurance_code_ids } = this.props.locationClaimHold.originalRowData;
        let disable_insurance_code_tab = false;
        let disable_insurance_class_tab = false;
        let defaultSelectedTabId = this.props.claimTabId || 'provider_id';

        if (!insurance_code_ids.length && insurance_class_ids.length > 0) {
            disable_insurance_code_tab = true;
            defaultSelectedTabId = this.props.claimTabId === 'insurance_code_id' ? 'provider_id' : defaultSelectedTabId;
        } else if (insurance_code_ids.length > 0 && !insurance_class_ids.length) {
            disable_insurance_class_tab = true;
            defaultSelectedTabId = this.props.claimTabId === 'insurance_class_id' ? 'provider_id' : defaultSelectedTabId;
        }

        return {
            disable_insurance_code_tab,
            disable_insurance_class_tab,
            defaultSelectedTabId
        };
    }

    is_permutations_limit_acceptable = (newSelectedValues: any): boolean => {
        this.validator.setStrategy(new ConcreteCombinationsLimit());
        const hasValueLessThanLimit = this.validator.isValid([{ ...this.currentFormFields }, newSelectedValues]);
        const instancesReference: any = this.refs;

        if (!hasValueLessThanLimit) {
            toaster.error('', billingPaneConstants.maximumCombinationsExceededClaimHold);
            this.providerBillingService.rollbackToPreviousSelectedItems([
                instancesReference,
                { ...this.currentFormFields },
                newSelectedValues
            ]);
        }

        return hasValueLessThanLimit;
    };

    on_dropdown_change = (value: any, e) => {
        const { name } = e.target;
        this.currentFormFields = {
            ...this.currentFormFields,
            [name]: value
        };
        this.setState({
            formFields: this.currentFormFields
        });
    };

    range_date_change_handler = (from_date: any, end_date: any) => {
        let errors = this.state.formErrors;
        errors['claim_hold_dos'] = from_date ? false : true;

        this.currentFormFields = {
            ...this.currentFormFields,
            start_date: from_date || null,
            end_date: end_date || null
        };
        this.setState({
            formFields: this.currentFormFields,
            formErrors: errors
        });
    };

    onChangeInputsHandler = (e) => {
        const input_field = e.target.name;
        let input_value = e.target.value;
        this.currentFormFields[input_field] = input_value;
        let errors = this.state.formErrors;
        errors['claim_hold_name'] = input_value && input_value.trim() ? false : true;
        this.setState({
            formFields: this.currentFormFields,
            formErrors: errors
        });
    };

    onChangeDropdownHandler = (e) => {
        const { value, name } = e.target;
        if (name == 'status') {
            this.currentFormFields[name] = value == 'true';
        } else {
            this.currentFormFields[name] = value;
        }
        this.setState({
            formFields: this.currentFormFields
        });
    };

    validationsPassed(validate_date: any = true) {
        let formIsValid = true;
        let errors = {};
        this.currentFormFields.claim_hold_name = this.currentFormFields.claim_hold_name.toString().trim();

        const mandatory_fields_empty =
            this.required_filed.claim_hold_name &&
            !this.currentFormFields.claim_hold_name &&
            validate_date &&
            this.required_filed.claim_hold_dos &&
            !this.currentFormFields.start_date;
        if (mandatory_fields_empty) {
            toaster.error('', mandatory_fields);
            errors['claim_hold_name'] = true;
            errors['claim_hold_dos'] = true;
            formIsValid = false;
        } else if (
            !mandatory_fields_empty &&
            this.required_filed.claim_hold_name &&
            !this.currentFormFields.claim_hold_name
        ) {
            errors['claim_hold_name'] = true;
            formIsValid = false;
            toaster.error('', billingPaneConstants.generalValidationErrorClaimHold);
        } else if (
            !mandatory_fields_empty &&
            validate_date &&
            this.required_filed.claim_hold_dos &&
            !this.currentFormFields.start_date
        ) {
            errors['claim_hold_dos'] = true;
            formIsValid = false;
            toaster.error('', billingPaneConstants.startDateRequired);
        }

        this.setState({
            formErrors: errors
        });
        return formIsValid;
    }

    saveHandler = (ev) => {
        ev.preventDefault();
        this.setState({
            isSubmitted: true
        });
        const formFieldsValidationPassed: boolean = this.validationsPassed();
        if (!formFieldsValidationPassed) {
            return;
        }
        if (formFieldsValidationPassed && this.is_permutations_limit_acceptable(null)) {
            let claimHolds = this.currentFormFields;
            this.props.saveHandlerCallback(claimHolds);
        }
    };

    private renderInsuranceClassesPanel = () => {
        return this.getInsuranceClassPanel();
    };
    private getInsuranceClassPanel = () => {
        //if (this.props.features.ProviderCredentialingAndHolds) {
        //    this.currentFormFields.insurance_class_ids = this.currentFormFields.insurance_class_ids.filter(f => this.providerCredentialedInsuranceClasses.indexOf(f) === -1);
        //}

        const selectedInsuranceClassItems = [...this.currentFormFields.insurance_class_ids];

        return (
            <div>
                <MultiSelectPanelComponent
                    id='insurance-class-multi-select'
                    data={this.tabInsuranceClassesData}
                    selected_list={selectedInsuranceClassItems}
                    onChangeSelected={(selectedItems) => {
                        if (this.is_permutations_limit_acceptable({ insurance_class_ids: [...selectedItems] })) {
                            this.handle_insurance_class_on_change_selected([...selectedItems]);
                        }
                    }}
                    ref='insurance_class_ids'
                    displayExtended={true}
                />
            </div>
        );
    };

    private renderInsuranceCodePanel = () => {
        return this.getInsuranceCodePanel();
    };
    private getInsuranceCodePanel = () => {
        const selectedInsuranceCodeItems = [...this.currentFormFields.insurance_code_ids];

        return (
            <div>
                <MultiSelectPanelComponent
                    id='insurance-code-multi-select'
                    data={this.tabInsuranceCodesData}
                    selected_list={selectedInsuranceCodeItems}
                    onChangeSelected={(selectedItems) => {
                        if (this.is_permutations_limit_acceptable({ insurance_code_ids: [...selectedItems] })) {
                            this.handle_insurance_code_on_change_selected([...selectedItems]);
                        }
                    }}
                    ref='insurance_code_ids'
                    displayExtended={true}
                />
            </div>
        );
    };

    private renderProvidersPanel = () => {
        return this.getProvidersPanel();
    };
    private getProvidersPanel = () => {
        const selectedProviderItems = [...this.currentFormFields.provider_ids];
        let is_include_future_selected = false;
        if (selectedProviderItems && selectedProviderItems.length === 1 && selectedProviderItems[0] === -1) {
            is_include_future_selected = true;
        }
        return (
            <div>
                <MultiSelectPanelComponent
                    id='provider-multi-select'
                    data={this.tabProvidersData}
                    selected_list={selectedProviderItems}
                    onChangeSelected={(selectedItems) => {
                        if (this.is_permutations_limit_acceptable({ provider_ids: [...selectedItems] })) {
                            this.handle_providers_on_change_selected([...selectedItems]);
                        }
                    }}
                    ref='provider_ids'
                    include_future_selected={is_include_future_selected}
                    include_future={true}
                    displayExtended={true}
                />
            </div>
        );
    };

    render() {
        const { externalErrors, formErrors, isSubmitted } = this.state;
        return (
            <React.Fragment>
                <div className='bp3-dialog-save-button'>
                    <Button
                        id='save_location_claim_btn'
                        className='primary'
                        type='button'
                        disabled={this.props.save_disable}
                        onClick={(e) => this.saveHandler(e)}                        content={'Apply'}
                    />
                </div>
                <Grid columns='equal'>
                    <Grid.Row>
                        <Grid.Column>
                            <label>
                                Hold Name{' '}
                                <span
                                    className={
                                        isSubmitted && (externalErrors.claim_hold_name || formErrors.claim_hold_name)
                                            ? 'req-alert'
                                            : 'req-alert_normal'
                                    }
                                >
                                    (required)
                                </span>
                            </label>
                            <Input
                                autoComplete='off'
                                name='claim_hold_name'
                                id='claim_hold_name'
                                type='text'
                                disabled={this.props.save_disable}
                                defaultValue={this.currentFormFields.claim_hold_name || ''}
                                onChange={(e) => this.onChangeInputsHandler(e)}                                maxLength={50}
                                className={
                                    isSubmitted && (externalErrors.claim_hold_name || formErrors.claim_hold_name) ? 'req-border-inp' : ''
                                }
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column tablet={16} computer={5}>
                            <label>
                                <span>Status </span>
                            </label>
                            <Selection
                                id='status'
                                name='status'
                                disabled={this.props.save_disable}
                                options={this.statusSelectOptions}
                                onChange={(value, e) => {
                                    this.onChangeDropdownHandler(e);
                                }}
                                defaultValue={this.currentFormFields.status}
                            />
                        </Grid.Column>
                        <Grid.Column tablet={16} computer={11}>
                            <label>
                                Date of Service Range{' '}
                                <span className={isSubmitted && formErrors.claim_hold_dos ? 'req-alert' : 'req-alert_normal'}>
                                    (required)
                                </span>
                            </label>
                            <DateRangePickerComponent
                                updateDatesChange={(from_date: any, end_date: any) =>
                                    this.range_date_change_handler(from_date, end_date)
                                }
                                startDate={this.currentFormFields.start_date}
                                endDate={this.currentFormFields.end_date}
                                error={isSubmitted && formErrors.claim_hold_dos ? true : false}
                                id={'date_of_service'}
                                maxDate={moment()}
                                anchorDirection={'right'}
                                is_disabled={this.props.save_disable}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <label>
                                Claim Delay Reason <span className={'req-alert_normal'}></span>
                            </label>
                            <Selection
                                name='claim_delay_reason_id'
                                id='claim_delay_reason_id'
                                defaultValue={this.currentFormFields.claim_delay_reason_id}
                                options={this.claimDelayReasonSelectOptions}
                                onChange={this.on_dropdown_change}
                                disabled={this.props.save_disable}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className='last-row'>
                        <Segment>
                            <Tabs animate={true} id='billing-tab-group' defaultSelectedTabId={this.state.defaultSelectedTabId}>
                                <Tab id='provider_id'
                                    title='Providers'
                                    panel={this.renderProvidersPanel()}
                                />
                                <Tab
                                    id='insurance_class_id'
                                    disabled={this.state.disable_insurance_class_tab}
                                    panel={this.renderInsuranceClassesPanel()}
                                >
                                    <Tooltip
                                        content={
                                            <div>
                                                Insurance Class tab is disabled while
                                                <br />
                                                Insurance Codes are selected.
                                            </div>
                                        }
                                        disabled={!this.state.disable_insurance_class_tab}
                                        position={'top'}
                                    >
                                        Insurance Class
                                    </Tooltip>
                                </Tab>
                                <Tab
                                    id='insurance_code_id'
                                    disabled={this.state.disable_insurance_code_tab}
                                    panel={this.renderInsuranceCodePanel()}
                                >
                                    <Tooltip
                                        content={
                                            <div>
                                                Insurance Code tab is disabled while
                                                <br />
                                                Insurance Classes are selected.
                                            </div>
                                        }
                                        disabled={!this.state.disable_insurance_code_tab}
                                        position={'top'}
                                    >
                                        Insurance Code
                                    </Tooltip>
                                </Tab>
                                <Tabs.Expander />
                            </Tabs>
                        </Segment>
                    </Grid.Row>
                </Grid>
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        features: {

        }
    };
};

export default connect(mapStateToProps)(ClaimHoldsAddEditDrawerComponent);