import * as global_constants from './../../global_constants';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request';
import { log_error } from '../../shared/action/shared_action';
import * as constants from '../billing_constants';

export const get_companies = (params, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_statement_urls.companies_search}`;
    
    let headers = {
        'Authorization': 'Bearer ' + token
    }

    let request = new Request();
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        params: params
    })

    return request.make(request_config);
}

export const lookup_companies = (params, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_statement_urls.companies_lookup}`;
    
    let headers = {
        'Authorization': 'Bearer ' + token
    }

    let request = new Request();
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        params: params
    })

    return request.make(request_config);
}

export const create_patient_statement = (data, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_statement_urls.create_patient_statement}`;
    let headers = {
        'Authorization': 'Bearer ' + token
    }

    let request = new Request();
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        data: data,
        'disable_retry': true
    })

    return request.make(request_config);
}

export const reset_patient_statement = (data, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_statement_urls.reset_patient_statement}`;
    let headers = {
        'Authorization': 'Bearer ' + token
    }

    let request = new Request();
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'put',
        data: data,
        'disable_retry': true
    })

    return request.make(request_config);
}

export const batch_options = (params, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_statement_urls.batch_options}`;
    
    let headers = {
        'Authorization': 'Bearer ' + token
    }

    let request = new Request();
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        params: params
    })

    return request.make(request_config);
}

 export const getRequestPatientStatementProcess = (data: any, token: string) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_statement_urls.requestPatientStatementProcess}`;

    let headers = {
        'Authorization': 'Bearer ' + token
    }

    let request = new Request();
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        data: data,
        'disable_retry': true
    })

    return request.make(request_config);
}

export const downloadRequestPatientStatementProcessFile = (params: any, token: string) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_statement_urls.requestPatientStatementProcessFile}`;
    let headers = {
        'Authorization': 'Bearer ' + token
    }

    let request = new Request();
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        params: params
    });

    return request.make(request_config);
}
export const resetPatientStatementsProcess = (data: any, token: string) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_statement_urls.resetPatientStatementRequestProcess}`;

    let headers = {
        'Authorization': 'Bearer ' + token
    }

    let request = new Request();
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': data,
        'disable_retry': true
    })

    return request.make(request_config);
}