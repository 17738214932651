﻿import * as React from "react";
import { Input, Button, Checkbox, Icon } from "semantic-ui-react";

interface IProps {
    data: any[],
    show_filter?: boolean,
    id?: string,
    class_name?: string,
    selected_list?: any[],
    onOpen?: Function,
    onChangeSelected: Function,
    key_id?: string,
    search_box_id?: string,
    is_save_in_progress?: boolean,
    has_validation?: boolean,
    forceOnChangeSelected ?: boolean, // will force onChangeSelected method to triggered on item change.
    reset_disable?: boolean,
    disabled?: boolean,
    include_future_selected?: boolean,
    include_future_disabled?: boolean,
    include_future?: boolean,
    include_future_values?: boolean,
	include_eye_feature?: boolean
}
   
export class MultiSelectDropDownComponent extends React.PureComponent<IProps, any> {
    node: any;
    _show_filter = true;
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.state = {
            data: this.props.data,
            selected_values: this.props.selected_list,
            filter_text: '',
            show:false,
            include_future_selected: this.props.include_future_selected || false,
            include_future_disabled: this.props.include_future_disabled || false,
			toggle_selected:true
        };
        if (this.props.show_filter != undefined && this.props.show_filter==false)
            this._show_filter = false;

    }

    componentDidMount = () => {
        if(this.props.include_future && this.state.include_future_selected){
            this.setState({
                selected_values: [...this.props.data],
                include_future_disabled: this.props.include_future_disabled
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.setState({
                data: this.props.data,
                selected_values: this.props.selected_list,
                filter_text: '',
            });
        }
        
        if (JSON.stringify(prevProps.selected_list) !== JSON.stringify(this.props.selected_list)) {

            this.setState({
                selected_values: this.props.selected_list
            });
        }

        if (this.props.include_future &&
                (prevProps.include_future_selected !== this.props.include_future_selected)) {

            this.setState({
                include_future_selected: this.props.include_future_selected, 
                selected_values: this.props.selected_list
            });
        }
    }
    
    on_select = (event, item_id) => {
        const { checked } = event.target;
        var selected_values = this.state.selected_values
        if (checked) {
            selected_values.push(item_id)
        }
        else {
            var index = selected_values.indexOf(item_id)
            if (index !== -1) {
                selected_values.splice(index, 1);
            }    
        }
        this.setState({
            selected_values
        })

        this.props.forceOnChangeSelected && this.props.onChangeSelected && 
        this.props.onChangeSelected(this.props.key_id, selected_values);
    }
    
    on_search = (event) => {
        if (event.target.value) {
            var data = this.props.data.filter(item => item.name.toString().toLowerCase().includes(event.target.value.toString().toLowerCase()));
            this.setState({
                data,
                filter_text: event.target.value
            })
        }
        else {
            this.setState({
                data: this.props.data,
                filter_text: event.target.value
            })
        }
    }

    on_select_all = () => {
        var selected_values = this.state.selected_values;
        this.state.data.filter(item => item.is_disabled != true).map((item) => {
            if (selected_values.indexOf(item.value) < 0) {
                selected_values.push(item.value);
            }
        });
        this.setState({
            selected_values
        });
        
        this.props.forceOnChangeSelected && this.props.onChangeSelected && 
        this.props.onChangeSelected(this.props.key_id, selected_values);
    }

    on_reset = () => {
        let selected_values = [];
        if(this.props.reset_disable){
            this.props.data.map(item=>{
                if(item.is_disabled){
                    selected_values.push(item.value)
                }
                return;
            })
        }
        this.setState({
            filter_text: '',
            selected_values,
            data: this.props.data,
            include_future_selected: false,
            toggle_selected: true
        });

        this.props.forceOnChangeSelected && this.props.onChangeSelected && 
        this.props.onChangeSelected(this.props.key_id, selected_values);
    }

    handleClick() {
        if (!this.state.show) {
            document.addEventListener('mousedown', this.handleOutsideClick, false);
            this.props.onOpen && this.props.onOpen(this.props.key_id);
        } else {
            document.removeEventListener('mousedown', this.handleOutsideClick, false);
        }
        this.setState(prevState => ({
			show: !prevState.show,
		}));
		this.setState((prevState) => ({
			toggle_selected: true,
			data: this.props.data
		}));
	}

    handleOutsideClick(e) {
        if (this.node && this.node.contains(e.target)) {
            return;
		}
        this.handleClick();
        this.props.onChangeSelected && this.props.onChangeSelected(this.props.key_id,this.state.selected_values);
	}

    key_tab_press = (event) => {
        if (event.which == 13) {
        // on enter event
        event.preventDefault();
        this.handleClick();
        }
    }

    toggle_include_future = () => {
        let include_future_selected = this.state.include_future_selected;
        let selected_values = this.state.selected_values;
        let value_to_notify = null;
       
        if(include_future_selected) {
            selected_values = [];
            value_to_notify = [];
        } else {
            this.state.data.filter(item => item.is_disabled != true).map((item) => {
                if (selected_values.includes(item.value) < 0) {
                    selected_values.push(item.value);
                }
            });
            value_to_notify = [-1];
        }

        include_future_selected = !include_future_selected;
        this.setState({
            selected_values,
            include_future_selected: include_future_selected
        });
        this.props.onChangeSelected(this.props.key_id, value_to_notify);
    }

    toggle_display_selected(e: MouseEvent) {
        if (!this.props.include_eye_feature) return;

		this.setState((prevState) => ({
			toggle_selected: !prevState.toggle_selected,
			data: prevState.toggle_selected ? this.props.data.filter(item => prevState.selected_values.includes(item.value)) : this.props.data
        }));
        e.stopPropagation();
    }

    render() {
        var hidden = { display: this.state.show ? "block" : "none" }
        var validation = (this.props.has_validation && !this.state.show) ? "req-background-inp" : "";
        let parent_class = this.props.class_name + ' multi-select-dropdown';
        parent_class += this.props.disabled ? ' disabled': '';
        let display_value = this.state.selected_values.length;
        let disabled_items = 0;
        let future_disabled = false;

        if (this.props.include_future_values) {
            // handling edge case in case of include future 
            if (this.state.selected_values.length === 1 && this.state.selected_values[0] == -1) {
                this.state.data.map((item, index) => {
                    var is_checked = this.state.selected_values.indexOf(item.value) > -1;
                    if ((this.state.include_future_selected || is_checked) && item.is_disabled) {
                        disabled_items++;
                    }
                });
                display_value = this.props.data.length - disabled_items;
            }

            future_disabled = this.state.include_future_disabled;
            future_disabled = (this.state.data.length <= 0);
            //console.log('future_disabled: ' + future_disabled);
            this.state.data.map((item, index) => {
                if (item.is_disabled) {
                    future_disabled = true;
                }
            });
        }


        return ( 
			<div className={parent_class} id={this.props.id ? this.props.id : "multi-select-dropdown"} ref={node => { this.node = node; }}>
				<div className={'multi-select-dropdown-header ' + validation} onClick={this.handleClick.bind(this)} tabIndex={0} onKeyPress={(e) => this.key_tab_press(e)}>
					<span className={display_value == 0 ? "hide-total" : ""}
						itemID="span_total"
						style={{ backgroundColor: (!this.state.toggle_selected && this.props.include_eye_feature) ? '#00A7E1' : '' }}
						onClick={this.state.show ? this.toggle_display_selected.bind(this) : () => { }}>
						  {this.props.include_eye_feature && <Icon aria-hidden='true' name='eye' />}{display_value} Selected</span>
					{/* <i onClick={this.handleClick.bind(this)} tabIndex={0} 
                                className="angle down icon"></i> */}
				</div>
                <div className="multi-select-dropdown-body" style={hidden}>
                    {this._show_filter  &&
                        <Input
                            id={this.props.search_box_id ? this.props.search_box_id : "multi-select-dropdown-search"}
                            autoComplete='off'
                            name='name'
                            type='text'
                            value={this.state.filter_text}
                            onChange={value => this.on_search(value)}
                        />
                    }
                        <div className="multi-select-dropdown-body-inner">
                            <ul>
                                {
                                    this.state.data.map((item, index) => {
                                        var is_checked = this.state.selected_values.indexOf(item.value) > -1;
                                        var label_class = "";
                                        if (item.on_hover_msg && this.props.is_save_in_progress == true && is_checked == true && item.is_disabled == false)
                                            label_class = "error tooltip";
                                        else if (item.on_hover_msg)
                                            label_class = "tooltip";
                                        return (
                                            <li key={item.value+"-"+index} className={item.is_disabled ? "disabled" : ""}>
                                                <label className={label_class}><input type="checkbox"
                                                    id={this.props.key_id ? 'provider-multi-select-chk_' + item.value + '_' + this.props.key_id: 'chk_' + item.value}
                                                    onChange={value => this.on_select(value, item.value)}
                                                    checked={(this.state.include_future_selected || is_checked) && !item.is_disabled}
                                                    disabled={ this.state.include_future_selected || item.is_disabled}
                                                />{item.name}
                                                    {
                                                        (item.on_hover_msg) &&
                                                        (<span className="tooltiptext">{item.on_hover_msg}
                                                            <i></i>
                                                        </span>)
                                                    }
                                                </label>

                                            </li>
                                        )
                                    })

                                }
                            </ul>
                        </div>
                        <div className="multi-select-dropdown-footer">
                            <Button type='button' onClick={this.on_select_all} disabled={this.state.include_future_selected}  className="select-all">Select All</Button>
                            {this.props.include_future && 
                            <React.Fragment>
                                <input type="checkbox"
                                    id={this.props.id ? this.props.id+'-chk-include-future':'chk-include-future'}
                                    onChange={this.toggle_include_future}
                                    checked={this.state.include_future_selected}
                                    disabled={future_disabled}
                                    className={'multi_select_checkbox_include_future'}
                                />
                            <Button type='button' id='multi_select_panel_include_future' onClick={this.toggle_include_future} className="select-all multi_select_panel_include_future" content="Include future" disabled={future_disabled}/>
                            </React.Fragment>
                        }
                            <Button type='button' onClick={this.on_reset} className="reset">Reset</Button>
                        </div>
                    </div>
               </div>
        )
    }
}

export default MultiSelectDropDownComponent;
