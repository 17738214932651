import CryptoService from './crypto_service/crypto_service';
import { sjcl_password } from './crypto_service/crypto_service';
import { get_private_key_for_encryption } from './action/shared_action';

// To remove a session storage
export const remove = (key) => {
    sessionStorage.removeItem(key);
};

// Removes all session storage
export const clear_all = () => {
    const isMainWindow = sessionStorage.getItem('is_main_window');
    const sessionId = sessionStorage.getItem('sessionId');
    const tabId = sessionStorage.getItem('tabId');
    let sso_user = sessionStorage.getItem('sso_user_login');
    sessionStorage.clear();
    if (sso_user) {
        sessionStorage.setItem('sso_user_login', 'true');
    }
    sessionStorage.setItem('sessionId', sessionId);
    sessionStorage.setItem('is_main_window', isMainWindow);
    sessionStorage.setItem('tabId', tabId);
};

// To add to session storage
export const set = (key, value) => {
    if (value === undefined) return false;
    if (!sjcl_password) {
        get_private_key_for_encryption().then((res) => {
            if (res.status == 200) {
                CryptoService.set_value(res.data.value);
            }

            // encrypting the value before storing it in the session
            let encryptedValue = CryptoService.encrypt(JSON.stringify(value));

            // let encryptedValue = value;
            sessionStorage.setItem(key, encryptedValue);
        });
    } else {
        // encrypting the value before storing it in the session
        let encryptedValue = CryptoService.encrypt(JSON.stringify(value));
        // let encryptedValue = value;
        sessionStorage[key] = encryptedValue;
    }
};

// Read that value back from session storage
export const get = (key) => {
    if (sessionStorage.getItem(key)) {
        if (!sjcl_password) {
            get_private_key_for_encryption().then((res) => {
                if (res.status == 200) {
                    CryptoService.set_value(res.data.value);
                }
                let encryptedValue = sessionStorage.getItem(key);
                if (encryptedValue != undefined) {
                    // decrypting the value before sending it for usage
                    let decryptedValue = CryptoService.decrypt(encryptedValue);
                    return JSON.parse(decryptedValue);
                } else {
                    return undefined;
                }
            });
        } else {
            let encryptedValue = sessionStorage.getItem(key);
            if (encryptedValue != undefined) {
                // decrypting the value before sending it for usage
                let decryptedValue = CryptoService.decrypt(encryptedValue);
                return JSON.parse(decryptedValue);
            } else {
                return undefined;
            }
        }
    }
    return undefined;
};

// Read raw
export const get_raw = (key) => {
    if (sessionStorage.getItem(key)) {
        return sessionStorage.getItem(key);
    }
    return undefined;
};

// Write raw
// keys to be store raw (tabId, sessionId, is_main_window)
export const set_raw = (key, value) => {
    if (value == undefined) return false;
    sessionStorage.setItem(key, JSON.stringify(value));
};
