import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Dimmer, Form, Grid, Input, Loader, Modal, Header } from 'semantic-ui-react';
import { set_focus_to_app_header, set_focus_on_element_with_id, handle_focus_on_tab } from '../../../shared/tab_navigation_utility';
import * as local_storage from '../../../shared/local_storage_utility';
import * as constants from '../constants';
import * as session_storage from '../../../shared/session_storage_utility';
import PromptNavigation from '../../../shared/component/prompt_navigation_component';
import TextareaAutosize from 'react-textarea-autosize';
import SelectionComponent from '../../../shared/component/selection_component';
import { override_field_dd, override_field_dd_value, toastr_options } from '../../../global_constants';
import * as constant_action from '../action/constants_action';
import { toastr as toaster } from 'react-redux-toastr';
import { log_error } from '../../../shared/action/shared_action';
import { enum_user_type_r3_id } from '../../../global_constants'
import { Tooltip } from '@blueprintjs/core';

interface EobClaimAdjustmentCodeDto {
    carc:string,
    description: string,
    is_excluded: boolean,
    claim_adjustment_code_id: number,
    created_by:number,
    created_date: string
    updated_by: number,
    updated_date:string
    ov: EobClaimAdjustmentReasonCodeCompanyOverrideDto
}

interface EobClaimAdjustmentReasonCodeCompanyOverrideDto {
    claim_adj_code_company_ov_id: number,
    claim_adj_code_id: number,
    company_id: number,
    def_excl_id: number,
    is_active: boolean,
    created_by: number,
    created_by_desc: string,
    display_format_created_date: string,
    updated_by: number,
    updated_by_desc: string,
    display_format_updated_date: string
}

interface IState {
    is_loading: boolean,
    confirm_box_open: boolean,
    is_save_button_disabled: boolean,
    form_object: EobClaimAdjustmentCodeDto,
    is_save_submitted: boolean
}
class ClaimAdjustmentReasonCodeWithCompanyOvComponent extends React.Component<any, IState>
{
    // #region Class Variables
    _is_mounted: boolean
    _code_id: number
    _page_metadata: any
    _token: string
    _r3_user_type: number
    _page_name: string // retaining old name
    _nav_go_next: boolean = false;
    _initial_form_object: EobClaimAdjustmentCodeDto;
    _search_criteria_from_session: any
    // #endregion Class Variables

    constructor(props)
    {
        super(props);
        this._is_mounted = false;
        this.state = {
            is_loading: false,
            confirm_box_open: false,
            is_save_button_disabled: false,
            form_object: null,
            is_save_submitted: false
        }
    }

    // #region Life Cycle Methods
    UNSAFE_componentWillMount = () =>
    {
        this._token = this.props.user_login_details.user_data.data.token_details.access_token;
        this._code_id = this.props.match.params && this.props.match.params.id ? this.props.match.params.id : -1;
        this._page_name = 'claim_adjustment_code';
        this._page_metadata = constants[this._page_name];
        this._r3_user_type = this.props.user_login_details.user_data.data.user_type_r3_id * 1;
        this._search_criteria_from_session = session_storage.get(`constant_${this._page_name}_criteria`);
    }

    componentDidMount()
    {
        this._is_mounted = true;
        document.body.classList.add('admin-framework');
        if (this.is_update_carc() )
            this.get_data(this._code_id);
        else {
            let newObject = this.get_new_claim_adjustment_reason_code();
            this._initial_form_object = JSON.parse(JSON.stringify(newObject));
            this.setState({form_object: newObject});
        }
        session_storage.set(`constant_${this._page_name}_criteria`, this._search_criteria_from_session);    
        this.set_default_foucs();
        

    }

    componentWillUnmount = () => {
        this._is_mounted = false;
        document.body.classList.remove('admin-framework');
        if (this.props.history.location && this.props.history.location.pathname) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[2] !== this._page_name && session_storage.remove(`constant_${this._page_name}_criteria`);
        }
    };
    // #endregion Life Cycle Methods


    //#region API Integration
    get_data = code_id => {
        if (!this._is_mounted) return;
        let url = this._page_metadata.api.get.url;
        url = url.replace('id_value', code_id);
        this.setState({is_loading: true});
        constant_action.get_data(this._token, url).then(
            response =>
            {
                if (response.data && response.data.data) {
                    this._initial_form_object = JSON.parse(JSON.stringify(response.data.data)); 
                    this.setState({
                        form_object: response.data.data,
                        is_loading: false
                    });
                   
                } else {
                    if (response.data.messages[0].message.indexOf('<br') > -1) {
                        this.show_html_content_toaster(response.data.messages[0].message);
                    } else {
                        toaster.error('', response.data.messages[0].message);
                    }
                    
                    this.setState({is_loading: false});
                }
            },
            error =>
            {
                this.setState({is_loading: false});
                log_error(error);
                if (error.data.messages[0].message.indexOf('<br') > -1) {
                    this.show_html_content_toaster(error.response.data.messages[0].message);
                } else {
                    toaster.error('', error.response.data.messages[0].message);
                }
            }
        );
    };

    save_handler = (e) => {

        if (!this._is_mounted) return;

        // #region Access Denied Check
        if (this.is_acess_denied() && this.is_add_carc())
        {
            toaster.error('', 'Access Denied');
            return;
        }
        // #endregion Access Denied Check

        this.setState({ is_save_submitted: true });

        // #region Validation
        this._nav_go_next = false;
        if (!this.is_form_data_valid()) return;
        // #endregion Validation

        this.setState({
            is_loading: true,
            is_save_button_disabled: true
        });


        if (this.is_update_carc()) {
            // Update
            let url = this._page_metadata.api.update.url;
            url = url.replace('id_value', this._code_id);

            constant_action.update_data(this._token, url, this.state.form_object).then(
                response => {
                    if (response.data && response.data.data) {
                        this._nav_go_next = true;
                        toaster.success('', this._page_metadata.validation_message.update);
                        this._initial_form_object = JSON.parse(JSON.stringify(this.state.form_object));
                        this.setState({ is_loading: false })

                        if (this._nav_go_next) {
                            this.last_report_navigation();
                            this._nav_go_next = false;

                        }
                    }
                    else {
                        this._nav_go_next = false;
                        toaster.error('', response.data.messages[0].message);
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false
                        });
                    }
                },
                error => {
                    this._nav_go_next = false;
                    this.setState({
                        is_loading: false,
                        is_save_button_disabled: false
                    });

                    if (error.response.data) toaster.error('', error.response.data.messages[0].message);
                }
            );
        }
        else {
            // Add
            let url = this._page_metadata.api.add.url;

            constant_action.add_data(this._token, url, this.state.form_object).then(
                response => {
                    if (response.data && response.data.data) {
                        this._nav_go_next = true;
                        toaster.success('', this._page_metadata.validation_message.save);
                        this._initial_form_object = JSON.parse(JSON.stringify(this.state.form_object));
                        this.setState({ is_loading: false })
                        if (this._nav_go_next) {
                            this.last_report_navigation();
                            this._nav_go_next = false;
                        }
                    }
                    else {
                        this._nav_go_next = false;
                        toaster.error('', response.data.messages[0].message);
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false
                        });
                    }
                },
                error => {
                    this._nav_go_next = false;
                    this.setState({
                        is_loading: false,
                        is_save_button_disabled: false
                    });
                    if (error.response.data) toaster.error('', error.response.data.messages[0].message);
                }
            );
        }
    }
    //#endregion API Integration


    // #region Action Methods
    text_change_handler = (e, name) =>
    {
        const value  = e.target.value;
        let form_object = this.state.form_object;
        form_object[name] = value;
        if (form_object[name] != null || form_object[name] != undefined)
            form_object[name] = form_object[name];
        this.setState({ form_object });
    }

    dropdown_change_handler = (value, e) =>
    {
        let form_object:EobClaimAdjustmentCodeDto = this.state.form_object;
        if (form_object.ov == null) form_object.ov = this.get_new_overrides_object();
        form_object.ov.def_excl_id = value * 1;
    }

    check_box_change_handler = (e) =>
    {
        const checked  = e.target.checked;
        let form_object = this.state.form_object;
        form_object.is_excluded = checked;
        this.setState({form_object});
    }

    cancel_handler = () =>
    {
        this.last_report_navigation();
    }
    // #endregion


    // #region Valiation Helper Method
    set_default_foucs = () => {
        let elem = document.getElementById('search-criteria-container');
        let all_form_elem = elem.getElementsByTagName('input');
        all_form_elem[0].focus();
    };

    is_form_data_valid = (): boolean => {
        let form_data = this.state.form_object;
        if (form_data == null) return false;
        if (form_data.carc == null || form_data.carc == undefined || form_data.carc.trim() == '') return false;
        return true;

    }

    display_carc_validation = ():boolean =>
    {
        var isStateObjectValid = !(this.state.form_object == null || this.state.form_object == undefined);
        var isCarcEntryValid = isStateObjectValid &&
            !(this.state.form_object.carc == null || this.state.form_object.carc == undefined || this.state.form_object.carc.trim() == '')

        return (this.state.is_save_submitted && !isCarcEntryValid);
    }
    // #endregion Valiation Helper Method


    // #region Util methods
    is_update_carc = (): boolean =>
    {
        return (this._code_id != -1);
    }

    //-1 for Id is not used at API for persistance.
    //DB has an entry for Id=0, hence choose -1 for
    //distinguishing new entry.
    is_add_carc = (): boolean => {
        return (this._code_id == -1);
    }

    show_html_content_toaster = msg => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ),
            timeOut: toastr_options.toastr_time_out,
            preventDuplicates: true
        };
    };

    get_form_field_value = (name : string) =>
    {
        let returnValue = '';

        if (this.state.form_object && this.state.form_object[name])
            returnValue = this.state.form_object[name];

        return returnValue;
    }

    get_form_field_checkbox_value = (name): boolean => {
        let returnValue:boolean = false;

        if (this.state.form_object && this.state.form_object[name])
            returnValue = this.state.form_object[name] ? true :false;

        return returnValue;
    }

    get_form_field_ov_value = (name: string) => {
        let returnValue = '';

        if (this.state.form_object && this.state.form_object.ov && this.state.form_object.ov[name])
            returnValue = this.state.form_object.ov[name];

        return returnValue;
    }

    last_report_navigation = () => {

        let last_report_navigation_value = local_storage.get('last_report_navigation');
        local_storage.remove('last_report_navigation');
        if (!last_report_navigation_value) {
            this.props.history.goBack()
        } else {
            this.props.history.push(last_report_navigation_value);
        }
    }

    get_new_claim_adjustment_reason_code = (): EobClaimAdjustmentCodeDto => {
        let newObject: EobClaimAdjustmentCodeDto = {
            carc: null,
            claim_adjustment_code_id: 0,
            description: "",
            is_excluded: false,
            created_by: null,
            created_date: '',
            updated_by: null,
            updated_date: '',
            ov: null
        };
        return newObject;
    }

    get_new_overrides_object = ():EobClaimAdjustmentReasonCodeCompanyOverrideDto => {
        return ({
            claim_adj_code_company_ov_id:0,
            claim_adj_code_id: this.state.form_object ? this.state.form_object.claim_adjustment_code_id:0,
            company_id: this.props.user_login_details.user_data.data.company_id,
		    def_excl_id:0,
		    is_active:true,
		    created_by: this.props.user_login_details.user_data.data.user_id,
		    created_by_desc:null,
		    display_format_created_date:null,
		    updated_by: this.props.user_login_details.user_data.data.user_id,
		    updated_by_desc: null,
		    display_format_updated_date:null
        });
    }

    is_acess_denied = () => {
        let is_access_denied: boolean = true;
        switch (this._r3_user_type)
        {
            case enum_user_type_r3_id.BMS:
            case enum_user_type_r3_id.Sys_Admin:
                is_access_denied = false;
                break;
            default:
                is_access_denied = true;
        }
        enum_user_type_r3_id

        return is_access_denied
    }
    // #endregion Util methods


    // #region Render Helper Methods
    get_cross_company_row_1() {

        return (
            <Grid>
                <Grid.Column className='form_column' computer={4} tablet={8}>
                    <Form.Field>
                        <label>
                            Claim Adjustment Reason Code
                            {
                                this.is_add_carc() &&
                                <span className={this.display_carc_validation() ? 'req-alert' : 'req-alert_normal'}>
                                    {' '}(required)
                                </span>
                            }
                        </label>
                        {
                            <Input
                                className={this.display_carc_validation() ? 'req-border-inp' : ''}
                                name='carc'
                                id='carc'
                                value={this.get_form_field_value('carc')}
                                maxLength={10}
                                onChange={(e) => this.text_change_handler(e, 'carc')}
                                disabled={this.is_update_carc() || this.is_acess_denied()}
                            />
                        }

                    </Form.Field>
                </Grid.Column>
                <Grid.Column className='form_column' computer={4} tablet={8}>
                    <Form.Field>
                        <label>Description</label>
                        <TextareaAutosize
                            autoComplete='new-password'
                            name={'description'}
                            maxLength={255}
                            id={'description'}
                            onChange={e => this.text_change_handler(e, 'description')}
                            value={this.get_form_field_value('description')}
                            disabled={this.is_acess_denied()}
                        />
                    </Form.Field>
                </Grid.Column>
                <Grid.Column className='form_column' computer={4}></Grid.Column>
            </Grid>
        );
    }

    get_cross_company_row_2()
    {
        return (
            <Grid>
                <Grid.Column className='checkbox' computer={16}>
                    <Form.Field className='ui checkbox'>
                        <input
                            type='checkbox'
                            name={'is_excluded'}
                            id={'is_excluded'}
                            checked={this.get_form_field_checkbox_value('is_excluded')}
                            onChange={(e) => this.check_box_change_handler(e)}
                            disabled={this.is_acess_denied()}
                        />
                        <label className='chkbx_label' htmlFor='is_excluded'>
                            Is Excluded 
                        </label>
                        <Tooltip
                            content='Check if you do not want the adjustment to post for this reason code.'
                            position='right'
                        >
                            <i
                                className="icon-icemoon info-icon-gray"
                                style={{ paddingLeft: "10px" }}
                            />
                        </Tooltip>
                    </Form.Field>
                </Grid.Column>
                <Grid.Column></Grid.Column>
            </Grid>
        );

    }

    get_cross_company_row_3()
    {
        return (
            <Grid>
                <Grid.Column className='form_column' computer={4} tablet={8}>
                    <Form.Field>
                        <label>Updated By</label>
                        <label className={'values'}>{this.get_form_field_value('updated_by_desc')}</label>
                    </Form.Field>
                </Grid.Column>
                <Grid.Column className='form_column' computer={4} tablet={8}>
                    <Form.Field>
                        <label>Updated Date</label>
                        <label className={'values'}>{this.get_form_field_value('updated_date')}</label>
                    </Form.Field>
                </Grid.Column>
            </Grid>
        );

    }

    get_company_ov_row_1()
    {
        return (
            <Grid>
                <Grid.Column computer={4} tablet={4} mobile={4}>
                    <Form.Field>
                        <label>
                            <span>
                                Is Excluded
                                <Tooltip
                                    content={
                                        <>
                                            Default = Inherit Cross-Company Setting
                                            <br />
                                            Yes = Override Cross-Company Setting and do not post adjustments
                                            <br />
                                            No = Override Cross-Company Setting and post adjustments
                                        </>
                                    }
                                    position='right'
                                >
                                    <i
                                        className="icon-icemoon info-icon-gray"
                                        style={{ paddingLeft: "10px" }}
                                    />
                                </Tooltip>
                            </span>
                        </label>
                        <SelectionComponent
                            id={'ov_is_excluded'}
                            options={override_field_dd}
                            onChange={(value, e) => {
                                this.dropdown_change_handler(value, e);
                            }}
                            defaultValue={this.state.form_object && this.state.form_object.ov && this.state.form_object.ov['def_excl_id'] != undefined ? this.state.form_object.ov['def_excl_id'] : 0}
                            style={'dropdown-options-wrap'}
                            disabled={this.is_add_carc() && this.is_acess_denied()}
                        />
                    </Form.Field>
                </Grid.Column>
            </Grid>
        );

    }

    get_company_ov_row_2()
    {
        return (
            <Grid>
                <Grid.Column computer={4}>
                    <Form.Field>
                        <label>Updated By</label>
                        <label className={'values'}>{this.get_form_field_ov_value('updated_by_desc')}</label>
                    </Form.Field>
                </Grid.Column>
                <Grid.Column computer={4}>
                    <Form.Field>
                        <label>Updated Date</label>
                        <label className={'values'}>{this.get_form_field_ov_value('display_format_updated_date')}</label>
                    </Form.Field>
                </Grid.Column>
            </Grid>
        );

    }
    // #endregion Render Helper Methods

    render() {
        return (
            <React.Fragment>
                <PromptNavigation
                    is_data_changed={JSON.stringify(this._initial_form_object) !== JSON.stringify(this.state.form_object)}
                    save={e => this.save_handler(e)}
                    go_next_location={this._nav_go_next}
                    history={this.props.history}
                />
                <Dimmer active={this.state.is_loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <div className={'admin-wrapper view-edit ' + this._page_metadata.id}>
                    <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                        <Grid.Column computer={16}>
                            <h3 className='ui header left aligned'>
                                {this.is_update_carc() ? this._page_metadata.edit_header : this._page_metadata.add_header}
                            </h3>
                        </Grid.Column>
                    </Grid>
                    <div className='wrapper' style={payers_css.wrapper}>
                        <Form autoComplete='off' name='view_edit_form' className='common-forms' style={payers_css.common_forms}>
                            <div id='admin-scrollable-area' style={payers_css.scrollbar_area} key={'outer_row_' + 1}> 
                                <div
                                    className='patient-search-form patient_search_bottom_padding'
                                    id='search-criteria-container'
                                    style={payers_css.patient_search_form}
                                    key={'outer_row_' + 2}
                                > 
                                    <Grid>
                                        <Grid.Column computer={16}>
                                            <Header as='h4'>Cross-Company Settings</Header>
                                        </Grid.Column>
                                    </Grid>
                                    { this.get_cross_company_row_1() }
                                    { this.get_cross_company_row_2() }
                                    { this.is_update_carc() && this.get_cross_company_row_3() }
                                </div>
                                    
                                <div
                                    className='patient-search-form patient_search_bottom_padding'
                                    id='search-criteria-container'
                                    style={payers_css.patient_search_form}
                                    key={'outer_row_' + 3}
                                >
                                    <Grid>
                                        <Grid.Column computer={16}>
                                            <Header as='h4'>Company Specific Overrides</Header>
                                        </Grid.Column>
                                    </Grid>
                                    {this.get_company_ov_row_1()}
                                    {this.is_update_carc() && this.get_company_ov_row_2()}
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div
                        className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                        id='applicationFooterSticky'
                    >
                        <Button id='cancel_button_id' type='button' onClick={this.cancel_handler} basic content={'Cancel'} />
                        <Button
                            type='submit'
                            onKeyDown={set_focus_to_app_header}
                            onClick={e => this.save_handler(e)}
                            id='save_button_id'
                            disabled={this.state.is_save_button_disabled}
                            primary
                            content={'Save'}
                        />
                    </div>
                </div>
                
            </React.Fragment>
        );

    }

}

// Css Style
const payers_css = {
    locations_id: {
        paddingBottom: 0
    },
    headerGrid: {
        marginTop: 0,
        marginBottom: 0,
        paddingRight: 15
    },
    wrapper: {
        borderTop: 0,
        marginBottom: 0
    },
    common_forms: {
        paddingTop: 0,
        height: '100%',
        paddingBottom: 0,
        display: 'flex',
        flexDirection: 'column'
    } as React.CSSProperties,

    patient_search_form: {
        paddingBottom: 0,
        flexBasis: 'auto',
        borderColor: '#cccccc',
        borderStyle: 'solid',
        borderWidth: '2px 1px 1px',
        margin: '0 0 15px 0px',
        background: '#fff',
        padding: '25px 15px'
    },
    footerArea: {
        paddingLeft: 0,
        paddingBottom: 0,
        marginBottom: 12,
        paddingRight: 25,
        paddingTop: 20
    },
    help_text: {
        color: '#757575',
        fontSize: 16,
        fontStyle: 'italic',
        fontWeight: 400,
        margin: 0,
        padding: 0
    },
    scrollbar_area: {
        height: '100%',
        paddingRight: 15
    } as React.CSSProperties,
    save_button: {
        marginRight: 0
    }
};

//Get user and login details from store.
const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details
    };
};

export default connect(mapStateToProps)(ClaimAdjustmentReasonCodeWithCompanyOvComponent);