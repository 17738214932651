import * as React from 'react';
import { connect } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { Button, Checkbox, Dimmer, Form, Loader } from 'semantic-ui-react';
import { toastr } from 'react-redux-toastr';
import * as _ from 'lodash';
import { log_error } from '../../../shared/action/shared_action';
import { Tooltip } from '@blueprintjs/core';
import Selection from '../../../shared/component/selection_component';
import { get_active_payers } from '../action/manage_payers_actions';
import { scheduledRunDetailConfiguration } from '../dashboard_constants';
import { add_ftp_schedule, edit_ftp_schedule, get_ftp_schedule_by_id } from '../action/ftp_schedule_actions';

class ScheduledRunDetailComponent extends React.Component<any, any> {

    token: string = "";

    constructor(props) {
        super(props);

        let initialValue = {
            autoFtpPayerId: '',
            overrideSchedule: false,
            scheduleProcess: '',
            scheduleTime: '',
            isDisabled: true
        }
        this.state = {
            loading: false,
            connectionNameList: [],
            runTypeList: scheduledRunDetailConfiguration.runTypeList,
            maskTime: scheduledRunDetailConfiguration.timemask,
            scheduledRunDetail: initialValue,
            initialValue,
            formSubmited: false
        };
    }

    async componentDidMount() {
        this.token = this.props.userLoginDetails.user_data.data.token_details.access_token;
        await this.loadScheduledRunDetailsPage()
        if (this.props.match.params.id) {
            this.loadScheduledRunDetail(this.props.match.params.id)
        }
    }

    loadScheduledRunDetailsPage = async () => {
        this.setState({
            loading: true
        });
        let payersList = await get_active_payers(this.token)
        let connectionNameList = payersList.data.data.map((payer: any) => { return { value: payer.autoFtpPayerId, text: payer.payerName } })
        this.setState({
            loading: false,
            connectionNameList,
        });
        this.setState({
            loading: false
        });
    }

    loadScheduledRunDetail = async (id: number) => {
        this.setState({
            loading: true
        });
        let response = await get_ftp_schedule_by_id(this.token, id)
        if (response.data && response.data.data) {
            let scheduleTime = ''
            if (response.data.data.scheduleTime) {
                let [hours, minutes, seconds] = response.data.data.scheduleTime.split(':');
                scheduleTime = `${hours}:${minutes}`
            }

            const scheduledRunDetail = {
                autoFtpSchedulerId: response.data.data.autoFtpSchedulerId,
                autoFtpPayerId: response.data.data.autoFtpPayerId,
                overrideSchedule: response.data.data.overrideSchedule,
                scheduleProcess: `${response.data.data.scheduleProcess}`.trim(),
                scheduleTime,
                isDisabled: !response.data.data.isDisabled
            }

            this.setState({
                loading: false,
                scheduledRunDetail,
                initialValue: scheduledRunDetail
            });
        } else {
            toastr.error('', scheduledRunDetailConfiguration.scheduledRunNotFound);
            this.setState({
                loading: false
            });
        }
    }

    onInputChange = (e: any) => {
        let { value, checked, type, name } = e.target;
        if (name === 'scheduleTime') {
            this.setState({
                scheduledRunDetail: {
                    ...this.state.scheduledRunDetail,
                    [name]: type === 'checkbox' ? checked : value
                },
                maskTime: value.startsWith('2') ? scheduledRunDetailConfiguration.timemask20h : scheduledRunDetailConfiguration.timemask
            });
        } else {
            this.setState({
                scheduledRunDetail: {
                    ...this.state.scheduledRunDetail,
                    [name]: type === 'checkbox' ? checked : value
                }
            });
        }
    }

    validateFields = () => {
        const requiredFields = [
            'autoFtpPayerId',
            'scheduleTime',
            'scheduleProcess'
        ];

        return _.every(requiredFields, (field) => !!this.state.scheduledRunDetail[field]) && !this.timeValidation();
    }

    onCancel = () => {
        this.props.history.goBack()
    }

    onSubmit = async () => {
        this.setState({
            loading: true,
            formSubmited: true
        })
        if (this.validateFields()) {
            const dto = {
                autoFtpPayerId: Number(this.state.scheduledRunDetail.autoFtpPayerId),
                overrideSchedule: this.state.scheduledRunDetail.overrideSchedule,
                scheduleProcess: this.state.scheduledRunDetail.scheduleProcess,
                scheduleTime: `${this.state.scheduledRunDetail.scheduleTime}:00`,
                isDisabled: !this.state.scheduledRunDetail.isDisabled
            }
            if (!this.state.scheduledRunDetail.autoFtpSchedulerId) {
                try {
                    let response = await add_ftp_schedule(this.token, dto);
                    (response.data.data > 0 && toastr.success('', scheduledRunDetailConfiguration.scheduledRunSaved)) ? this.props.history.goBack() :
                        toastr.error('', response.data.messages[0].message);
                } catch (error) {
                    log_error(error)
                }
            } else {
                try {
                    let response = await edit_ftp_schedule(this.token, this.state.scheduledRunDetail.autoFtpSchedulerId, dto);
                    (response.data.data && toastr.success('', scheduledRunDetailConfiguration.scheduledRunUpdated)) ? this.props.history.goBack() :
                        toastr.error('', response.data.messages[0].message);
                } catch (error) {
                    log_error(error)
                }
            }
            this.setState({
                loading: false
            })
        } else {
            toastr.error('', scheduledRunDetailConfiguration.requiredFields);
            this.setState({
                loading: false
            })
        }
    }

    timeValidation = (): boolean => {
        return this.state.scheduledRunDetail.scheduleTime ?
            !(scheduledRunDetailConfiguration.timeFormatValidation.test(this.state.scheduledRunDetail.scheduleTime)) :
            true;
    }

    render() {
        return (
            <>
                <Dimmer active={this.state.loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <Form onSubmit={this.onSubmit} style={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '100%', height: '100%' }}>
                    <div style={{ position: 'relative', flexGrow: 1 }}>
                        <div style={{ position: 'absolute', display: 'flex', flexDirection: 'column', width: '100%', height: '100%', overflow: 'auto' }}>
                            <div style={{ position: 'relative' }}>
                                <h3 style={{ marginBottom: '0px', fontWeight: 'bold', fontSize: '22px', padding: '5px 5px 10px 5px' }}>{scheduledRunDetailConfiguration.title}</h3>
                                <div style={{ border: '1px solid rgb(204, 204, 204)', background: 'rgb(255, 255, 255)', padding: '10px', marginRight: '15px' }}>
                                    <h3 className='card-title' style={{ marginBottom: '0px', paddingLeft: '10px' }}>{scheduledRunDetailConfiguration.cardTitle}</h3>
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <div style={{ flex: 1, padding: '10px' }}>
                                            <Form.Field className='form-field-input'>
                                                <label>
                                                    Connection Name <span className={this.state.formSubmited && !this.state.scheduledRunDetail.autoFtpPayerId ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                </label>
                                                <Selection
                                                    id='autoFtpPayerId'
                                                    name='autoFtpPayerId'
                                                    placeHolder={'Select'}
                                                    isRequired={this.state.formSubmited && !this.state.scheduledRunDetail.autoFtpPayerId}
                                                    hidden={true}
                                                    options={this.state.connectionNameList}
                                                    onChange={(value, e) => {
                                                        this.onInputChange(e);
                                                    }}
                                                    defaultValue={this.state.scheduledRunDetail.autoFtpPayerId || ''}
                                                />
                                            </Form.Field>
                                        </div>
                                        <div style={{ flex: 1, display: 'flex', padding: '10px' }}>
                                            <div style={{ flex: 1, paddingRight: '10px' }}>
                                                <Form.Field className={`form-field-mask ${this.state.formSubmited && this.timeValidation() ? 'required-mask-bg' : ''}`}>
                                                    <label>
                                                        Schedule Time  <span className={this.state.formSubmited && this.timeValidation() ? "req-alert" : 'req-alert_normal'}>(required)</span><Tooltip
                                                            content={
                                                                <div>
                                                                    hh:mm
                                                                </div>
                                                            }
                                                            position={'right'}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 20 20" style={{ width: '30px' }}><path style={{ fill: '#7F8FA4' }} d="M9-15h2v2H9Zm0,4h2v6H9Zm1-9A10,10,0,0,0,0-10,10,10,0,0,0,10,0,10,10,0,0,0,20-10,10,10,0,0,0,10-20ZM10-2a8.011,8.011,0,0,1-8-8,8.011,8.011,0,0,1,8-8,8.011,8.011,0,0,1,8,8A8.011,8.011,0,0,1,10-2Z" transform="translate(20) rotate(180)" /></svg>
                                                        </Tooltip>
                                                    </label>
                                                    <MaskedInput
                                                        mask={this.state.maskTime}
                                                        placeholder="00:00"
                                                        guide={true}
                                                        id='scheduleTime'
                                                        name='scheduleTime'
                                                        autoComplete='off'
                                                        value={this.state.scheduledRunDetail.scheduleTime || ''}
                                                        onChange={e => this.onInputChange(e)}
                                                    />
                                                </Form.Field>
                                            </div>
                                            <div style={{ flex: 1, paddingLeft: '10px' }}>
                                                <Form.Field className='form-field-input'>
                                                    <label>
                                                        Run Type <span className={this.state.formSubmited && !this.state.scheduledRunDetail.scheduleProcess ? "req-alert" : 'req-alert_normal'}>(required)</span>
                                                    </label>
                                                    <Selection
                                                        id='scheduleProcess'
                                                        name='scheduleProcess'
                                                        placeHolder={'Select'}
                                                        hidden={true}
                                                        isRequired={this.state.formSubmited && !this.state.scheduledRunDetail.scheduleProcess}
                                                        options={this.state.runTypeList}
                                                        onChange={(value, e) => {
                                                            this.onInputChange(e);
                                                        }}
                                                        defaultValue={this.state.scheduledRunDetail.scheduleProcess || ''}
                                                    />
                                                </Form.Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <div style={{ flex: 1, display: 'flex', padding: '10px' }}>
                                            <div style={{ flex: 1, paddingRight: '10px' }}>
                                                <Form.Field>
                                                    <Checkbox
                                                        name='overrideSchedule'
                                                        id='overrideSchedule'
                                                        className='form-field-checkbox checkbox-label-center'
                                                        onChange={e => this.onInputChange(e)}
                                                        checked={this.state.scheduledRunDetail.overrideSchedule ? this.state.scheduledRunDetail.overrideSchedule : false}
                                                        label={'Run Inmediately'}
                                                    />
                                                </Form.Field>
                                            </div>
                                            <div style={{ flex: 1, paddingLeft: '10px' }}>
                                                <Form.Field>
                                                    <Checkbox
                                                        name='isDisabled'
                                                        id='isDisabled'
                                                        className='form-field-checkbox checkbox-label-center'
                                                        onChange={e => this.onInputChange(e)}
                                                        checked={this.state.scheduledRunDetail.isDisabled ? this.state.scheduledRunDetail.isDisabled : false}
                                                        label='Is Active'
                                                    />
                                                </Form.Field>
                                            </div>
                                        </div>
                                        <div style={{ flex: 1, display: 'flex', padding: '10px' }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', padding: '10px' }}>
                        <Button name='cancelButton' type='button' basic onClick={this.onCancel}>
                            Cancel
                        </Button>
                        <Button name='saveButton' type='submit' primary>
                            Save
                        </Button>
                    </div>
                </Form>
            </>
        )
    }
}

const mapStateToProps = ({ user_login_details }) => {
    return {
        userLoginDetails: user_login_details
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(ScheduledRunDetailComponent);