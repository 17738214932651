import * as global_constants from '../../global_constants';
import * as shared_constants from '../shared_constants';

export default function (state = [], { type, payload, error }) {
    switch (type) {
        case global_constants.constants.LEFT_MENU_PERMISSION:
            if (payload) {
                return { ...state, ["left_menu_data"]: payload.data};
            } else if (error) {
                // TODO do something here
                //console.log(error)
            } else {
                return state
            }
            break;
        case shared_constants.USER_R2_URL:
            return { ...state, ["user_r2_url"]: payload.data };
        case global_constants.constants.HEADER_UI_CHANGE:
            return { ...state, ["is_expand"]: payload.is_expand };
        case global_constants.constants.SHOW_NOTES_MODAL:
            return {...state,["show_notes_modal"]: payload }; 
        case global_constants.constants.SHOW_MODEL_POPUP_REPORT:
            return {...state,["show_model_popup_report"]: payload }; 
        case global_constants.constants.SHOW_PREVENT_COMPANY_CHANGE_MODEL:
            return {...state,["show_prevent_change_model"]: payload }; 
        case global_constants.constants.PROMPT_FOR_LOGOUT:
            /**
             * We are storage this state into our redux-store to solve a problem:- i.e show prompt for unsaved page before logging out
             * States maintained here are :-
             * 1. not_required : this means no need to check for prompt for unsaved page before logout.
             * 2. prompt_required: need to check for the prompt for unsaved pages before logout
             * 2. required : need to check for the navigation before logout
             * 3. handled : we have already handled the prompt, thus make user force_logout in app header in componentDidUpdate
             * */

            return { ...state, ["prompt_for_logout"]: payload.prompt };
        default:
            return state
    }
}