import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Input, Form } from 'semantic-ui-react';
import * as ProcCodeReplacementConstants from '../constant/procedure_code_replacement_constants';
import {
    validate_procedure_code_replacement, save_procedure_code_replacement
} from '../action/procedure_code_replacement_action';
import Selection from '../../shared/component/selection_component';
import DateRangePickerComponent from '../../shared/component/date_range_component';
import { date_format } from '../../global_constants';
import moment = require('moment');
import { custom_date_format } from '../../shared/utility';
import AutoSearchComponent from '../../shared/component/auto_search_component';
import AdvancedControl from "../../shared/component/advanced_control";
import * as  shared_constants from '../../shared/shared_constants';
import * as global_constants from '../../global_constants';
import { procedure_code_advance_search, procedure_code_quick_search } from '../../shared/action/autosearch_action';
import MultiSelectPanelComponent from '../../shared/component/multi_select_panel_component';
import { toastr } from 'react-redux-toastr';
import { AlertConfirm } from '../../shared/component/alert_confirm_component';

class ClaimsScrubbingProcedureReplacementAddEditComponent extends React.Component<any, any> {
    _is_mounted = false;
    item_selection_call = false;
    search_keyword = '';
    criteria_title = '';
    blur_call = false;
    original_proc_code = {};
    replacement_proc_code = {};
    selected_criteria_id = 0;
    source_type_id = 0;
    _payers_list = [];
    _insurance_class_list = [];
    has_multiselect_validation = false;
    has_service_validation = false;
    has_effective_date_error = false;
    original_selected_procedure_code = {
        "title": '',
        "label": ''
    };
    replacement_selected_procedure_code = {
        "title": '',
        "label": ''
    };

    constructor(props) {
        super(props);
        this.state = {
            is_save_in_progress: false,
            is_submitted: false,
            proc_replacement_data: { ...this.props.data },
            validation_msg: {},
            selected_criteria_items: [],
            grid_conf: {
                isPagination: true,
                gridAutoHeight: true,
                selectionType: 'single',
                paginationPageSize: 20,
            },
            open_save_alert: false

        };
    }

    componentDidMount() {
        this._is_mounted = true;
        this.load_initial_data();
    }

    load_initial_data = async () => {
        this._payers_list = this.props.payers_list;
        this._insurance_class_list = this.props.insurance_class_list;
        if (this.props.data.claim_processing_rule_id == 0) {
            this.criteria_title = this.props.criteria_list[0].text;
            this.selected_criteria_id = this.props.criteria_list[0].value;
            this.set_source_type_id();
            var proc_replacement_data = this.state.proc_replacement_data;
            proc_replacement_data.claim_processing_criteria.claim_processing_criteria_id = this.selected_criteria_id;
            proc_replacement_data.claim_processing_criteria.claim_processing_criteria_name = this.criteria_title;
            this.setProcReplacementDataState(proc_replacement_data);
        }
        else {
            this.criteria_title = this.props.data.claim_processing_criteria.claim_processing_criteria_name;
            this.selected_criteria_id = this.props.data.claim_processing_criteria.claim_processing_criteria_id;
            this.set_source_type_id();
            if (this.props.data.original_procedure_codes != null) {
                this.original_selected_procedure_code = {
                    "title": (this.props.data.original_procedure_codes.procedure_code_id).toString(),
                    "label": this.props.data.original_procedure_codes.proc_desc1 ? this.props.data.original_procedure_codes.proc_code +
                        (" - " + (this.props.data.original_procedure_codes.proc_desc1)) :
                        this.props.data.original_procedure_codes.proc_code,
                }
            }
            if (this.props.data.replacement_procedure_codes != null) {
                this.replacement_selected_procedure_code = {
                    "title": (this.props.data.replacement_procedure_codes.claim_processing_result_id).toString(),
                    "label": this.props.data.replacement_procedure_codes.claim_processing_result_desc ?
                        this.props.data.replacement_procedure_codes.claim_processing_result_data + (" - " + (this.props.data.replacement_procedure_codes.claim_processing_result_desc)) :
                        this.props.data.replacement_procedure_codes.claim_processing_result_data
                }
            }
            let selected_criteria_items = [];
            this.props.data.claim_processing_criteria_source_details.map((item => {
                selected_criteria_items.push(item.claim_processing_criteria_source_data_id)
            }));
            this.set_criteria_items_state(selected_criteria_items);
        }
    }

    //#region save and validate region
    saveHandler = (ev, saveFromPopup) => {

        ev.preventDefault();
        this.setState({
            is_submitted: true
        });
        if (this.is_ui_validation_passed()) {
            let source_details = [];
            this.state.selected_criteria_items.map((item => {
                source_details.push({
                    "claim_processing_criteria_source_type_id": this.source_type_id,
                    "claim_processing_criteria_source_data_id": item,
                    "claim_processing_criteria_source_detail_id": this.get_criteria_source_detail_id(item)
                });
            }));
            let proc_replacement_data = this.state.proc_replacement_data;
            proc_replacement_data.claim_processing_criteria_source_details = source_details;
            this.service_validate(proc_replacement_data);
        }

    }

    get_criteria_source_detail_id = (criteria_item) => {
        let source_detail_id = 0;
        let filtered_source_details = [];
        source_detail_id = 0;
        filtered_source_details = this.props.data.claim_processing_criteria_source_details.filter(x => x.claim_processing_criteria_source_data_id == criteria_item
            && x.claim_processing_criteria_source_type_id == this.source_type_id);
        if (filtered_source_details.length > 0)
            source_detail_id = filtered_source_details[0].claim_processing_criteria_source_detail_id;
        return source_detail_id;
    }

    get_validation_message = (service_msg, validation_object_msg) => {
        if (service_msg != null && service_msg != undefined) return service_msg + " " + validation_object_msg;
        return validation_object_msg;
    }

    service_validate = async (proc_code_replacement_obj) => {
        this.setState({ is_save_in_progress: true });
        if (this._is_mounted) {
           
            proc_code_replacement_obj.is_active = proc_code_replacement_obj.is_active == null || proc_code_replacement_obj.is_active == "false" ? false : true;
            await validate_procedure_code_replacement(this.props.user_login_details.user_data.data.token_details.access_token, proc_code_replacement_obj)
                .then(response => {
                    this.has_service_validation = false;
                    this.has_multiselect_validation = false;
                    if (response.data.data) {
                        response.data.data.claim_processing_criteria_source_details.map(source_details => {
                            if (source_details.validation && source_details.validation.length > 0) {
                                if (this.source_type_id == ProcCodeReplacementConstants.criteria_type.payers) {
                                    this._payers_list = this.set_hover_msg_on_criteria_details_items(source_details, [...this._payers_list]);
                                }
                                else if (this.source_type_id == ProcCodeReplacementConstants.criteria_type.insurance_classes) {
                                    this._insurance_class_list = this.set_hover_msg_on_criteria_details_items(source_details, [...this._insurance_class_list]);
                                }
                            }
                        });
                        var validation_msg = {};
                        if (response.data.data.validation && response.data.data.validation.length > 0) {
                            this.has_service_validation = true;
                            response.data.data.validation.map(validation => {
                                validation_msg[`${validation.propertyName}`] = this.get_validation_message(validation_msg['${validation.propertyName}'], validation.message);
                            });
                            let toaster_validations = response.data.data.validation.filter(x => x.propertyName == "");
                            if (toaster_validations && toaster_validations.length > 0) {
                                let toaster_msg = "";
                                toaster_validations.map(validation => {
                                    if (toaster_msg == '')
                                        toaster_msg = validation.message;
                                    else
                                        toaster_msg = toaster_msg + '</br>' + validation.message;
                                });
                                const toastr_options = this.show_html_content_toaster(toaster_msg);
                                toastr.error('', toastr_options);
                            }
                        }
                        if (this.has_service_validation) {
                            this.setState({ validation_msg });
                            this.setState({ is_save_in_progress: false });
                        }
                        else {
                            this.setState({
                                validation_msg: {},
                                proc_replacement_data: proc_code_replacement_obj,
                                open_save_alert: true
                            });
                        }
                    }
                    else {
                        this.setState({ validation_msg: {} });
                    }
                })
        }
    }

    set_hover_msg_on_criteria_details_items = (source_details, criteria_details_items) => {
        return criteria_details_items.map(item => {
            if (item.value == source_details.claim_processing_criteria_source_data_id) {
                item.on_hover_msg = this.get_validation_message_by_prop_name(source_details.validation, ProcCodeReplacementConstants.field_identifier.claim_processing_criteria_source_data_id);
                this.has_service_validation = true;
                this.has_multiselect_validation = true;
            }
            return item;
        });
    }

    get_validation_message_by_prop_name = (validations, property_name) => {
        if (validations && validations.length > 0) {
            let filtered_validation = validations.filter(x => x.propertyName == property_name);
            if (filtered_validation.length > 0)
                return filtered_validation[0].message;
            else return ''
        }
    }

    is_ui_validation_passed = () => {
        let is_validation_successful = true;
        if (!this.state.proc_replacement_data.claim_processing_rule_name)
            is_validation_successful = false;
        else if (!this.original_selected_procedure_code.label)
            is_validation_successful = false;
        else if (!this.replacement_selected_procedure_code.label)
            is_validation_successful = false;
        else if (!this.state.proc_replacement_data.start_date || this.has_effective_date_error)
            is_validation_successful = false;
        else if (this.state.selected_criteria_items.length == 0)
            is_validation_successful = false;
        return is_validation_successful;
    }

    save_data = async (proc_replacement_data) => {
        if (this._is_mounted) {
            await save_procedure_code_replacement(this.props.user_login_details.user_data.data.token_details.access_token, proc_replacement_data)
                .then(response => {
                    if (response.data.data && response.data.data == true) {
                        this.setState({ is_save_in_progress: false });
                        toastr.success('', ProcCodeReplacementConstants.messages.save_success_msg);
                        this.notify_save();
                    }
                    else {
                        toastr.error('', ProcCodeReplacementConstants.messages.save_error_msg);
                        this.setState({ is_save_in_progress: false });
                    }
                }, (error) => {
                    this.setState({ is_save_in_progress: false });
                    toastr.error('', ProcCodeReplacementConstants.messages.save_error_msg);
                });
        }
    }

    notify_save = () => {
        this.props.saveHandlerCallback && this.props.saveHandlerCallback(true);
    }

    notify_update = () => {
        this.props.updateHandlerCallback && this.props.updateHandlerCallback();
    }

    is_save_button_disabled = () => {
        return this.state.is_save_in_progress == true;
    }

    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }
    //#endregion

    //#region update data event
    onChangeRuleName = e => {
        this.remove_service_validation(ProcCodeReplacementConstants.field_identifier.claim_processing_rule_name);
        var proc_replacement_data = this.state.proc_replacement_data;
        proc_replacement_data.claim_processing_rule_name = e.target.value;
        this.setProcReplacementDataState(proc_replacement_data);
        this.notify_update();
    }

    onChangeStatus = e => {
        this.remove_service_validation(ProcCodeReplacementConstants.field_identifier.claim_processing_status);
        const { value } = e.target;
        var proc_replacement_data = this.state.proc_replacement_data;
        proc_replacement_data.is_active = value;
        this.setProcReplacementDataState(proc_replacement_data);
        this.notify_update();
    };

    onChangeCriteria = e => {
        this.remove_service_validation(ProcCodeReplacementConstants.field_identifier.claim_processing_criteria);
        const { value } = e.target;
        this.set_criteria_items_state([]);
        this.criteria_title = e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text;
        var proc_replacement_data = this.state.proc_replacement_data; 
        this.selected_criteria_id = value;
        this.set_source_type_id();
        proc_replacement_data.claim_processing_criteria.claim_processing_criteria_id = value;
        proc_replacement_data.claim_processing_criteria.claim_processing_criteria_name = this.criteria_title;
        this.setProcReplacementDataState(proc_replacement_data);
        this.notify_update();
    };

    setProcReplacementDataState = (proc_replacement_data_obj) => {
        this.setState({
            proc_replacement_data: proc_replacement_data_obj,
            is_save_in_progress: false
        });
    };

    on_criteria_item_selected = (selected_list) => {
        this.remove_service_validation(ProcCodeReplacementConstants.field_identifier.claim_processing_criteria_source_details);
        this.set_criteria_items_state(selected_list);
        this.notify_update();
    }

    set_source_type_id = () => {
        if (this.selected_criteria_id == ProcCodeReplacementConstants.criteria_type.payers)
            this.source_type_id = ProcCodeReplacementConstants.criteria_type.payers;
        else if (this.selected_criteria_id == ProcCodeReplacementConstants.criteria_type.insurance_classes || ProcCodeReplacementConstants.criteria_type.excluded_insurance_classes)
            this.source_type_id = ProcCodeReplacementConstants.criteria_type.insurance_classes;
    }
    //#endregion

    //#region code for AdvancedControl

    load_proc_code_data_for_advance_control = async (proc_code_type, params) => {

        var grid_data = [];
        const search_data = await procedure_code_advance_search(params,
            this.props.user_login_details.user_data.data.token_details.access_token).then(res => res.data);
        const search_result = search_data.data !== null ? search_data.data.result : [];

        let col_def = shared_constants.search_procedure_code_configuration('procedure_code_search').column_defs;
        grid_data = {
            ...this.state.grid_conf,
            rows: search_result,
            column: col_def,
            messages: search_data.messages
        };
        if (this._is_mounted) {
            this.setState({
                loading: false,
                procedure_code_search_data: grid_data
            });
        }
    }

    on_original_proc_code_search = async (params) => {
        this.load_proc_code_data_for_advance_control(ProcCodeReplacementConstants.proc_code_type.original, params)
    }
    on_replacement_proc_code_search = async (params) => {
        this.load_proc_code_data_for_advance_control(ProcCodeReplacementConstants.proc_code_type.replacement, params)
    }

    on_original_proc_code_grid_row_selection = (selected_row) => {
        this.set_proc_code_data(ProcCodeReplacementConstants.proc_code_type.original, selected_row)
        this.set_proc_code_state(ProcCodeReplacementConstants.proc_code_type.original);
    }

    on_replacement_proc_code_grid_row_selection = (selected_row) => {
        this.set_proc_code_data(ProcCodeReplacementConstants.proc_code_type.replacement, selected_row)
        this.set_proc_code_state(ProcCodeReplacementConstants.proc_code_type.replacement);
    }

    //#endregion

    //#region code for AutoSearchComponent
    load_proc_code_data_for_auto_search_control = async (proc_code_type, search_keyword) => {
        this.item_selection_call = false;
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await procedure_code_quick_search(search_keyword, token);
    }

    get_original_proc_code_quick_search_data_list = async (search_keyword) => {
        return this.load_proc_code_data_for_auto_search_control(ProcCodeReplacementConstants.proc_code_type.original, search_keyword);
    }

    get_replacement_proc_code_quick_search_data_list = async (search_keyword) => {
        this.search_keyword = search_keyword;
        var proc_code_resp = this.load_proc_code_data_for_auto_search_control(ProcCodeReplacementConstants.proc_code_type.replacement, search_keyword);
        return proc_code_resp;
    }

    prepare_suggestion = (_data, proc_code_type) => {
        this.blur_call = true;
        let formattedList = [];
        if (proc_code_type == ProcCodeReplacementConstants.proc_code_type.replacement) {
            if (this.search_keyword && (this.search_keyword.toLowerCase().indexOf("non") > -1 || this.search_keyword.toLowerCase().indexOf("one") > -1)) {
                _data.data.push({
                    "proc_code": "NONE",
                    "procedure_code_id": 0,
                    "proc_desc1": ""
                });
            }

        }
        var data_length = 0;
        if (_data.data) {
            data_length = _data.data.length;
            _data = _data.data;
        }
        else {
            data_length = _data.length;
        }
        if (_data && data_length) {
            for (var i = 0; i < data_length; i++) {
                var item = _data[i];
                item = {
                    "procedure_code_id": `${item.procedure_code_id}`,
                    "proc_code": `${item.proc_code}`,
                    "title": `${item.procedure_code_id}`,
                    "label": item.proc_desc1 ? (item.proc_code) + (" - " + (item.proc_desc1)) : (item.proc_code),
                    "proc_desc1": item.proc_desc1
                };
                formattedList.push(item);
            }
        }
        return formattedList;
    }

    original_proc_code_prepare_suggestion = (_data, type_of_search) => {
        return this.prepare_suggestion(_data, ProcCodeReplacementConstants.proc_code_type.original);
    }

    replacement_proc_code_prepare_suggestion = (_data, type_of_search) => {
        return this.prepare_suggestion(_data, ProcCodeReplacementConstants.proc_code_type.replacement);
    }

    proc_code_item_selection = (proc_code_type, selected_row) => {
        this.item_selection_call = true;
        this.blur_call = false;
        this.set_proc_code_data(proc_code_type, selected_row);

        setTimeout(() => {
            this.set_proc_code_state(proc_code_type);
        }, 1000)
    }

    on_original_proc_code_item_selection = (selected_row) => {
        this.proc_code_item_selection(ProcCodeReplacementConstants.proc_code_type.original, selected_row);
    }

    on_replacement_proc_code_item_selection = (selected_row) => {
        this.proc_code_item_selection(ProcCodeReplacementConstants.proc_code_type.replacement, selected_row);
    }

    clear_quick_search = (proc_code_type) => {
        var selected_row =
        {
            procedure_code_id: 0,
            proc_code: '',
            is_active: false
        };
        this.set_proc_code_data(proc_code_type, selected_row);
        this.set_proc_code_state(proc_code_type);
    }

    on_original_proc_code_clear_quick_search = () => {
        this.clear_quick_search(ProcCodeReplacementConstants.proc_code_type.original)
    }

    on_replacement_proc_code_clear_quick_search = () => {
        this.clear_quick_search(ProcCodeReplacementConstants.proc_code_type.replacement)
    }

    blur_auto_search = (proc_code_type, value) => {
        this.item_selection_call = false;
        if (value.results.length == 0 || value.results[0].label == '') {
            if (proc_code_type == ProcCodeReplacementConstants.proc_code_type.original && this.original_selected_procedure_code.label == '')
                this.clear_quick_search(proc_code_type);
            else if (proc_code_type == ProcCodeReplacementConstants.proc_code_type.replacement && this.replacement_selected_procedure_code.label == '')
                this.clear_quick_search(proc_code_type);
        } else {
            if (this.blur_call) {
                var selected_row = null;
                if (proc_code_type == ProcCodeReplacementConstants.proc_code_type.original &&
                    this.original_selected_procedure_code.label == undefined || this.original_selected_procedure_code.label == '') {
                    selected_row = value.results[0];
                }
                else if (proc_code_type == ProcCodeReplacementConstants.proc_code_type.replacement &&
                    this.replacement_selected_procedure_code.label == undefined || this.replacement_selected_procedure_code.label == '') {
                    selected_row = value.results[0];
                }
                if (selected_row != null && selected_row != undefined) {
                    this.set_proc_code_data(proc_code_type, selected_row);
                    setTimeout(() => {
                        if (!this.item_selection_call) {
                            this.set_proc_code_state(proc_code_type);
                        }
                    }, 500)
                }
            }
        }
    }

    on_original_proc_code_blur_auto_search = (e, value) => {
        this.blur_auto_search(ProcCodeReplacementConstants.proc_code_type.original, value)
    }

    on_replacement_proc_code_blur_auto_search = (e, value) => {
        this.blur_auto_search(ProcCodeReplacementConstants.proc_code_type.replacement, value)
    }

    render_suggestion_result = (props) => {
        return (
            <div key={props.title} tabIndex={0} className="item_auto_search">
                <div key={props.title} className="fs_13">
                    <span>{props.label}</span>
                </div>
            </div>

        )
    }

    //#endregion

    //#region set proc code data and state
    set_proc_code_data = (proc_code_type, selected_row) => {
        if (this._is_mounted) {
            if (proc_code_type == ProcCodeReplacementConstants.proc_code_type.original) {
                this.remove_service_validation(ProcCodeReplacementConstants.field_identifier.original_procedure_codes);
                this.original_proc_code =
                    {
                        procedure_code_id: selected_row.procedure_code_id,
                        proc_code: selected_row.proc_code,
                        is_active: true
                    };

                this.original_selected_procedure_code = {
                    "title": (selected_row.procedure_code_id).toString(),
                    "label": selected_row.proc_desc1 ? (selected_row.proc_code) + (" - " + (selected_row.proc_desc1)) : (selected_row.proc_code),
                }
            }
            else if (proc_code_type == ProcCodeReplacementConstants.proc_code_type.replacement) {
                this.remove_service_validation(ProcCodeReplacementConstants.field_identifier.replacement_procedure_codes);
                this.replacement_proc_code =
                    {
                        claim_processing_result_data: selected_row.proc_code,
                        is_active: true
                    };

                this.replacement_selected_procedure_code = {
                    "title": (selected_row.procedure_code_id).toString(),
                    "label": selected_row.proc_desc1 ? (selected_row.proc_code) + (" - " + (selected_row.proc_desc1)) : (selected_row.proc_code),
                }
            }
        }
    }

    set_proc_code_state = (proc_code_type) => {
        if (this._is_mounted) {
            var proc_replacement_data = this.state.proc_replacement_data;
            if (proc_code_type == ProcCodeReplacementConstants.proc_code_type.original) {
                proc_replacement_data.original_procedure_codes = this.original_proc_code;
            }
            else if (proc_code_type == ProcCodeReplacementConstants.proc_code_type.replacement) {
                proc_replacement_data.replacement_procedure_codes = this.replacement_proc_code;
            }
            this.setState({
                proc_replacement_data: proc_replacement_data,
                is_save_in_progress: false
            });
        }
        this.notify_update();
    }

    set_criteria_items_state = (items) => {
        if (this._is_mounted) {
            this.setState({
                selected_criteria_items: items,
                is_save_in_progress: false
            });
        }
    }

    remove_service_validation = (field_name) => {
        let validation_msg = this.state.validation_msg;
        if (validation_msg && validation_msg[`${field_name}`]) {
            validation_msg[`${field_name}`] = '';
            this.setState({ validation_msg });
        }
    }
    //#endregion

    //#region muliselect  panel region
    private getPayersMultiSelectPanel = () => {
        return (
            <div className="criteria_panel">
                <MultiSelectPanelComponent
                    id={ProcCodeReplacementConstants.field_identifier.claim_processing_criteria_source_details}
                    data={this._payers_list}
                    selected_list={this.state.selected_criteria_items}
                    onChangeSelected={this.on_criteria_item_selected}
                    is_save_in_progress={this.has_multiselect_validation}
                />
            </div>);
    }

    private geInsuranceClassMultiSelectPanel = () => {
        return (
            <div className="criteria_panel">
                <MultiSelectPanelComponent
                    id={ProcCodeReplacementConstants.field_identifier.claim_processing_criteria_source_details}
                    data={this._insurance_class_list}
                    selected_list={this.state.selected_criteria_items}
                    onChangeSelected={this.on_criteria_item_selected}
                />
            </div>);
    }

    private renderPayersPanel = () => { return this.getPayersMultiSelectPanel(); }

    private renderInsuranceClassPanel = () => { return this.geInsuranceClassMultiSelectPanel(); }

    private renderMultiSelectPanel = () => {
        if (this.selected_criteria_id == ProcCodeReplacementConstants.criteria_type.payers)
            return this.renderPayersPanel();
        else if (this.selected_criteria_id == ProcCodeReplacementConstants.criteria_type.insurance_classes
            || this.selected_criteria_id == ProcCodeReplacementConstants.criteria_type.excluded_insurance_classes)
            return this.renderInsuranceClassPanel();
    }

    //#endregion

    //#region validation methods
    mark_field_for_required_validation = (field_name) => {
        let has_required_filed_validation = false;
        switch (field_name) {
            case ProcCodeReplacementConstants.field_identifier.claim_processing_rule_name:
                has_required_filed_validation = !this.state.proc_replacement_data.claim_processing_rule_name;
                break;
            case ProcCodeReplacementConstants.field_identifier.original_procedure_codes:
                has_required_filed_validation = !this.original_selected_procedure_code.label;
                break;
            case ProcCodeReplacementConstants.field_identifier.replacement_procedure_codes:
                has_required_filed_validation = !this.replacement_selected_procedure_code.label;
                break;
            case ProcCodeReplacementConstants.field_identifier.effective_date_range:
                has_required_filed_validation = !this.state.proc_replacement_data.start_date || (this.state.proc_replacement_data.end_date && new Date(this.state.proc_replacement_data.end_date).getTime() == NaN);
                break;
            case ProcCodeReplacementConstants.field_identifier.claim_processing_criteria_source_details:
                has_required_filed_validation = this.state.selected_criteria_items.length == 0;
                break;
        }
        has_required_filed_validation = has_required_filed_validation && this.state.is_submitted;
        return has_required_filed_validation;
    }

    mark_field_for_validation = (field_name) => {
        let has_field_validation = false;
        switch (field_name) {
            case ProcCodeReplacementConstants.field_identifier.claim_processing_rule_name:
                has_field_validation = !this.state.proc_replacement_data.claim_processing_rule_name || this.state.validation_msg.claim_processing_rule_name;
                break;
            case ProcCodeReplacementConstants.field_identifier.original_procedure_codes:
                has_field_validation = !this.state.proc_replacement_data.original_procedure_codes.proc_code || this.state.validation_msg.original_procedure_codes;
                break;
            case ProcCodeReplacementConstants.field_identifier.replacement_procedure_codes:
                has_field_validation = !this.state.proc_replacement_data.replacement_procedure_codes.claim_processing_result_data || this.state.validation_msg.replacement_procedure_codes;
                break;
            case ProcCodeReplacementConstants.field_identifier.effective_date_range:
                has_field_validation = !this.state.proc_replacement_data.start_date || (this.state.proc_replacement_data.end_date && new Date(this.state.proc_replacement_data.end_date).getTime() == NaN) || this.state.validation_msg.effective_date_range;
                break;
            case ProcCodeReplacementConstants.field_identifier.claim_processing_criteria:
                has_field_validation = !this.state.proc_replacement_data.claim_processing_criteria.claim_processing_criteria_name || this.state.validation_msg.claim_processing_criteria;
                break;
            case ProcCodeReplacementConstants.field_identifier.claim_processing_criteria_source_details:
                has_field_validation = this.state.selected_criteria_items.length == 0 || this.state.validation_msg.claim_processing_criteria_source_details;
                break;

        }
        has_field_validation = has_field_validation && this.state.is_submitted;
        return has_field_validation;
    }
    display_required_text = (field_name) => {
        let has_validation_msg = false;
        switch (field_name) {
            case ProcCodeReplacementConstants.field_identifier.claim_processing_rule_name:
                has_validation_msg = this.state.proc_replacement_data.claim_processing_rule_name && this.state.validation_msg.claim_processing_rule_name;
                break;
            case ProcCodeReplacementConstants.field_identifier.original_procedure_codes:
                has_validation_msg = this.state.proc_replacement_data.original_procedure_codes.proc_code && this.state.validation_msg.original_procedure_codes;
                break;
            case ProcCodeReplacementConstants.field_identifier.replacement_procedure_codes:
                has_validation_msg = this.state.proc_replacement_data.replacement_procedure_codes.claim_processing_result_data && this.state.validation_msg.replacement_procedure_codes;
                break;
            case ProcCodeReplacementConstants.field_identifier.effective_date_range:
                has_validation_msg = this.state.proc_replacement_data.start_date && this.state.validation_msg.effective_date_range;
                break;
            case ProcCodeReplacementConstants.field_identifier.claim_processing_criteria:
                has_validation_msg = this.state.proc_replacement_data.claim_processing_criteria.claim_processing_criteria_id && this.state.validation_msg.claim_processing_criteria;
                break;
            case ProcCodeReplacementConstants.field_identifier.claim_processing_criteria_source_details:
                has_validation_msg = this.state.selected_criteria_items.length > 0 && this.state.validation_msg.claim_processing_criteria_source_details;
                break;
        }
        has_validation_msg = has_validation_msg && this.state.is_submitted;
        return has_validation_msg;
    }

    get_required_text_div = (field_name) => {
        return (
            <div className='requiredText'>
                {
                    this.display_required_text(field_name)
                        ? this.state.validation_msg[field_name]
                        : ''
                }
            </div>
        );
    }
    //#endregion

    // #region helper methods
    getDisplayFormatDate = (date) => {
        if (!date)
            return null;
        else
            return moment(custom_date_format(date, date_format["mm/dd/yyyy"]));
    }

    convertInDateFormat = (date) => {
        if (date)
            return date.format('YYYY-MM-DDT00:00:00');
        else
            return null;
    }

    handleEffectiveDateChange = (startDate, endDate) => {
        this.remove_service_validation(ProcCodeReplacementConstants.field_identifier.effective_date_range);
        var proc_replacement_data = this.state.proc_replacement_data;
        proc_replacement_data.start_date = this.convertInDateFormat(startDate);
        proc_replacement_data.end_date = this.convertInDateFormat(endDate);
        this.setProcReplacementDataState(proc_replacement_data);
        this.notify_update();
    }

    handleEffectiveDateHasError = (hasError) => {
        this.has_effective_date_error = hasError;
    }

    // #endregion
    //#region alert box region

    alert_box_helper = () => {
        this.setState({
            open_save_alert: false,
            is_save_in_progress: false
        });
    }

    handle_save_confirm = () => {
        this.setState({
            open_save_alert: false
        });
        this.save_data(this.state.proc_replacement_data);
    }

    //Render save alert message
    render_save_alert_message = () => {
        return (
            <React.Fragment>

                <label>{ProcCodeReplacementConstants.messages.save_prompt_message}</label><br />
                <label>&nbsp;&nbsp;&nbsp;{ProcCodeReplacementConstants.messages.save_prompt_rule_name}{this.state.proc_replacement_data.claim_processing_rule_name}</label><br />


            </React.Fragment>
        )
    }
    //#endregion
    render() {
        return (
            <React.Fragment>
                <div className='bp3-dialog-save-button'>
                    <Button
                        id={ProcCodeReplacementConstants.save_button.id}
                        className="primary"
                        type='button'
                        disabled={this.is_save_button_disabled()}
                        onClick={e => this.saveHandler(e, false)}>{ProcCodeReplacementConstants.save_button.name}</Button>
                </div>
                <AlertConfirm
                    open={this.state.open_save_alert}
                    close={() => this.alert_box_helper()}
                    cancel={() => this.alert_box_helper()}
                    confirm={() => this.handle_save_confirm()}
                    message={this.render_save_alert_message()}
                    labelYes={ProcCodeReplacementConstants.save_button.name}
                />
                <Grid columns='equal'>
                    <Grid.Row>
                        <Grid.Column>
                            <label>
                                Setting Name<span className={this.mark_field_for_required_validation(ProcCodeReplacementConstants.field_identifier.claim_processing_rule_name) ? "req-alert" : 'req-alert_normal'}> (required)</span>
                            </label>
                            <Input
                                autoComplete='off'
                                name={ProcCodeReplacementConstants.field_identifier.claim_processing_rule_name}
                                id={ProcCodeReplacementConstants.field_identifier.claim_processing_rule_name}
                                type='text'
                                defaultValue={this.state.proc_replacement_data.claim_processing_rule_name || ''}
                                onChange={e => this.onChangeRuleName(e)}
                                maxLength={50}
                                className={this.mark_field_for_validation(ProcCodeReplacementConstants.field_identifier.claim_processing_rule_name) ? 'req-border-inp' : ''}
                            />
                            {this.get_required_text_div(ProcCodeReplacementConstants.field_identifier.claim_processing_rule_name)}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <label>
                                <span>
                                    Original Procedure Code
                                    <span className={this.mark_field_for_required_validation(ProcCodeReplacementConstants.field_identifier.original_procedure_codes) ? "req-alert" : 'req-alert_normal'}> (required)</span>
                                </span>
                            </label>
                            <Form.Field className="advance-quick-search" >
                                <AdvancedControl
                                    id={ProcCodeReplacementConstants.field_identifier.original_procedure_code_advance_search}
                                    gridConfig={this.state.procedure_code_search_data}
                                    onGridRowSelection={this.on_original_proc_code_grid_row_selection}
                                    controlId={global_constants.constants.advanced_control_type.procedure_code}
                                    onSearch={this.on_original_proc_code_search}
                                    search_type={'procedure_code_search'}
                                    headerIdForGridTabNavigation={'procedure_code_search'}
                                    disabled_element={false}
                                />
                                <AutoSearchComponent
                                    default_value={this.original_selected_procedure_code}
                                    prepareRenderList={this.render_suggestion_result}
                                    getList={this.get_original_proc_code_quick_search_data_list}
                                    prepareDataList={this.original_proc_code_prepare_suggestion}
                                    selectresult={this.on_original_proc_code_item_selection}
                                    on_blur={(e, value) => { this.on_original_proc_code_blur_auto_search(e, value) }}
                                    errorMessage={'No Record Found !'}
                                    control_id={ProcCodeReplacementConstants.field_identifier.original_procedure_codes}
                                    is_focus={false}
                                    show_clear_search={true}
                                    clear_search={() => this.on_original_proc_code_clear_quick_search()}
                                    on_blur_parent_handle={true}
                                    errorClass={this.mark_field_for_validation(ProcCodeReplacementConstants.field_identifier.original_procedure_codes) ? 'req-border-inp' : ''}
                                />

                            </Form.Field>
                            {this.get_required_text_div(ProcCodeReplacementConstants.field_identifier.original_procedure_codes)}


                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <label>
                                <span>
                                    Replacement Code
                                    <span className={this.mark_field_for_required_validation(ProcCodeReplacementConstants.field_identifier.replacement_procedure_codes) ? "req-alert" : 'req-alert_normal'}> (required)</span>
                                </span>
                            </label>
                            <Form.Field className="advance-quick-search" >
                                <AdvancedControl
                                    id={ProcCodeReplacementConstants.field_identifier.replacement_procedure_code_advance_search}
                                    gridConfig={this.state.procedure_code_search_data}
                                    onGridRowSelection={this.on_replacement_proc_code_grid_row_selection}
                                    controlId={global_constants.constants.advanced_control_type.procedure_code}
                                    onSearch={this.on_replacement_proc_code_search}
                                    search_type={'procedure_code_search'}
                                    headerIdForGridTabNavigation={'procedure_code_search'}
                                />
                                <AutoSearchComponent
                                    default_value={this.replacement_selected_procedure_code}
                                    prepareRenderList={this.render_suggestion_result}
                                    getList={this.get_replacement_proc_code_quick_search_data_list}
                                    prepareDataList={this.replacement_proc_code_prepare_suggestion}
                                    selectresult={this.on_replacement_proc_code_item_selection}
                                    on_blur={(e, value) => { this.on_replacement_proc_code_blur_auto_search(e, value) }}
                                    errorMessage={'No Record Found !'}
                                    control_id={ProcCodeReplacementConstants.field_identifier.replacement_procedure_codes}
                                    is_focus={false}
                                    show_clear_search={true}
                                    clear_search={() => this.on_replacement_proc_code_clear_quick_search()}
                                    on_blur_parent_handle={true}
                                    errorClass={this.mark_field_for_validation(ProcCodeReplacementConstants.field_identifier.replacement_procedure_codes) ? 'req-border-inp' : ''}
                                />

                            </Form.Field>
                            {this.get_required_text_div(ProcCodeReplacementConstants.field_identifier.replacement_procedure_codes)}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <label>
                                Effective Date Range<span className={this.mark_field_for_required_validation(ProcCodeReplacementConstants.field_identifier.effective_date_range) ? "req-alert" : 'req-alert_normal'}> (required)</span>
                            </label>
                            <Form.Field className="advance-quick-search" >
                                <DateRangePickerComponent
                                    updateDatesChange={this.handleEffectiveDateChange}
                                    startDate={this.getDisplayFormatDate(this.state.proc_replacement_data.start_date)}
                                    endDate={this.getDisplayFormatDate(this.state.proc_replacement_data.end_date)}
                                    id={ProcCodeReplacementConstants.field_identifier.effective_date_range}
                                    error={this.mark_field_for_validation(ProcCodeReplacementConstants.field_identifier.effective_date_range)}
                                    errorDisplayType={shared_constants.enum_date_range_error_display_type.UNDERCOMPONENT}
                                    updateHasError={this.handleEffectiveDateHasError}
                                    disableClearOnValidation={true}

                                />
                                {this.get_required_text_div(ProcCodeReplacementConstants.field_identifier.effective_date_range)}
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <label>
                                <span>
                                    Qualifying Criteria
                                </span>
                            </label>
                            <Selection
                                id={ProcCodeReplacementConstants.field_identifier.claim_processing_criteria}
                                name={ProcCodeReplacementConstants.field_identifier.claim_processing_criteria}
                                options={this.props.criteria_list}
                                onChange={(value, e) => {
                                    this.onChangeCriteria(e);
                                }}
                                defaultValue={this.state.proc_replacement_data.claim_processing_criteria.claim_processing_criteria_id}
                                isRequired={this.mark_field_for_validation(ProcCodeReplacementConstants.field_identifier.claim_processing_criteria)}
                            />
                            {this.get_required_text_div(ProcCodeReplacementConstants.field_identifier.claim_processing_criteria)}
                        </Grid.Column>
                        <Grid.Column>
                            <label>
                                <span>
                                    Status
                                </span>
                            </label>
                            <Selection
                                id={ProcCodeReplacementConstants.field_identifier.claim_processing_status}
                                name={ProcCodeReplacementConstants.field_identifier.claim_processing_status}
                                options={ProcCodeReplacementConstants.statusSelectOptions}
                                onChange={(value, e) => {
                                    this.onChangeStatus(e);
                                }}
                                defaultValue={this.state.proc_replacement_data.is_active}
                            />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row className="criteria_title_wrapper">
                        <Grid.Column>
                            <div>
                                <span id={ProcCodeReplacementConstants.field_identifier.claim_processing_criteria} className="criteria_title">{this.criteria_title}</span>
                                <span className={this.mark_field_for_required_validation(ProcCodeReplacementConstants.field_identifier.claim_processing_criteria_source_details) ? "req-alert" : 'req-alert_normal'}> (required)</span>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className='criteria_wrapper'>
                        <Grid.Column>
                            {this.get_required_text_div(ProcCodeReplacementConstants.field_identifier.claim_processing_criteria_source_details)}
                            {this.renderMultiSelectPanel()}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            </React.Fragment>
        );
    }
}

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details
    };
};

export default connect(mapStateToProps)(ClaimsScrubbingProcedureReplacementAddEditComponent);