import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Grid, Table, GridColumn, Form } from 'semantic-ui-react';
import * as moment from 'moment';
import { toastr as toaster } from 'react-redux-toastr';
import ErrorCorrectionEditableRowComponent from './error_correction_editable_row_component';
import * as local_storage from '../../shared/local_storage_utility';
import * as payment_constants from '../payment_constants';
import * as session_storage from '../../shared/session_storage_utility';
import { get_adjustment_reason } from '../action/payment_action';
import { get_references } from '../batch_payment/action/batch_payment_action';
import { get_adjustment_code } from '../batch_payment/action/batch_payment_action';
import { option_formater_for_multiple_column, set_order_data } from '../../shared/utility';
import * as constants from '../../admin/constants/constants';
import { text_comparator, currency_comparator, mm_dd_yyyy_comparator, number_comparator } from '../../shared/component/grid/comparators';
import { Icon } from '@blueprintjs/core';
import { get_medical_list, get_primary_specility_list } from '../../shared/action/shared_action';
import { get_lauch_darkley_key_value as isFFEnabled } from '../../shared/utility';
import { ErrorCorrectionsEnhancementsProject } from '../payment_constants';

export class ErrorCorrectionEditableComponent extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            is_loading: false,
            expand_all: this.props.IsErrorCorrectionsEnhancementsEnabled ? true : false,
            collapse_all: this.props.IsErrorCorrectionsEnhancementsEnabled ? false : true,
            allow_sort: true,
            charges: [...this.props.charge_transaction_data],
            payment_type: [],
            payment_code: [],
            adjustment_code: [],
            adjustment_qualifier: [],
            adjustment_reason: [],
            payment_code_list: [],
            medical_group: [],
            specility: [],
            is_delete_payments_allowed: false,
        };

        this.rowNodes = [];
        this.list_data = [];
        this.edit_mode_rows = [];
        this.closed_editable_rows = [];
        this.page_metadata = payment_constants.error_corrections_editable;
    }
    no_adjustment_code = 'No Adjustment Code';
    token: any = '';
    patient_id: any = null;
    _is_mounted = false;
    page_metadata: any = { child_column: [], child_column_enhanced: [] };
    list_data: any = [];
    rowNodes: any;
    sort_type: string = '';
    sort_key: string = '';
    medical_group_unfiltered: any = [];
    primary_spaciality_unfiltered: any = [];
    edit_mode_rows: any = [];
    closed_editable_rows: any = [];

    componentDidMount = async () => {
        this._is_mounted = true;
        this.props.onRef(this);
        document.body.classList.add('admin-framework');
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.patient_id = session_storage.get('active_patient');
        this.checkDeletePaymentPermission();
      
        if (this.props.edit_all) {
            this.setState({ collapse_all: false, expand_all: true, allow_sort: false })
        }
        if(this.props.charge_transaction_data && this.props.charge_transaction_data.length > 0){
            this.get_api_data();
            this.get_references_data();
            this.get_referring_physician_data();
        } 
        this.validate_data();
    };

    componentWillUnmount = () => {
        this._is_mounted = false;
        document.body.classList.remove('admin-framework');
        this.props.onRef(undefined)
    };

    componentDidUpdate(previousProps, previousState) {
        if (this.props.edit_all !== previousProps.edit_all) {
            if (this.props.edit_all) {
                this.edit_mode_rows = this.state.charges.map(item => item.charge_id);
            } else {
                this.edit_mode_rows = [];
            }
            const sort_status = this.update_row_sort_status(!this.props.edit_all)
            this.setState({
                collapse_all: !this.props.edit_all,
                expand_all: this.props.edit_all,
                allow_sort: sort_status
            })
        }
    }

    validate_data = () => {
        let row_items = [];
        let is_any_invalid = false;
        let payment_invalid = false;
        this.rowNodes.map((node) => {
            if (node && !node.is_valid_payment()) {
                payment_invalid = true; // To show toaster one time only
                node.force_error_edit();
            }
        });
    }

    checkDeletePaymentPermission = () => {
        let delete_permission = this.props.shared_details.left_menu_data.data.filter((menu) => {
          return menu.name === 'Error Corrections - Delete Payment';
        });
        
        if (delete_permission.length > 0) {
          if (this._is_mounted) {
            this.setState({
                is_delete_payments_allowed: delete_permission[0].view
            });
          }
        }
      };

    update_row_sort_status = (sort_status) => {
        let sort = sort_status;
        if (this.props.charge_transaction_data && this.props.charge_transaction_data.length > 0) {
            if (sort) {
                const is_unsaved = this.rowNodes.some((node) => {
                    if (node && (node.is_charge_unsaved() || node.is_payment_unsaved())) {
                        return true;
                    }
                    return false;
                })
                if (is_unsaved) {
                    sort = false;
                }
            }
        }

        return sort;
    }

    get_edited_data = () => {
        let row_items = [];
        let is_any_invalid = false;
        let payment_invalid = false;
        this.rowNodes.map((node) => {
            if (node && (node.is_charge_unsaved() || node.is_payment_unsaved() || !node.is_valid_payment())) {
                const p_valid = node.is_valid_payment();
                if (!p_valid) {
                    payment_invalid = true; // To show toaster one time only
                    node.force_error_edit();
                }

                if (node.is_valid_charge() && p_valid) {
                    const data = node.get_updated_data();
                    if (data.has_charge_change || data.has_payment_change) {
                        row_items.push(data);
                    }
                } else {
                    is_any_invalid = true;
                }
            }
        });

        //Show toaster when no change
        if (!is_any_invalid && row_items && row_items.length == 0) {
            toaster.success('', payment_constants.ERROR_CORRECTION_MESSAGE.NO_EDIT_CHANGE);
        }

        return is_any_invalid ? [] : row_items;
    }


    get_references_data = async () => {
        let res = await this.props.get_references(this.patient_id, this.token, 1);
        let dropdown_data = {
            transfer_insurance_code: constants.option_formater(
                this.props.batch_payment_reference.batch_reference.data[5].refrences,
                'title',
                'value',
                true, // to add blank object
                false, // to shorted object
            ),
            adjustment_qualifier: constants.option_formater(
                this.props.batch_payment_reference.batch_reference.data[4].refrences,
                'value',
                'value',
                true, // to add blank object
                true, // to shorted object
                'NA'
            )
        };

        if (this._is_mounted) {
            this.setState({ ...dropdown_data });
        }
    };

    get_adjustment_reason = () => {
        return get_adjustment_reason(this.token).then(
            response => {
                if (response.data && response.data.data) {
                    let options = response.data.data.map((val, index) => {
                        return {
                            original_text: val.adjustment_reason,
                            key: index,
                            value: val.id,
                            text: val.adjustment_reason.length > 130 ? val.adjustment_reason.substr(0, 130) + '...' : val.adjustment_reason,
                            data: val
                        }
                    });
                    options.unshift({
                        text: this.no_adjustment_code,
                        value: ' '
                    });
                    return options;
                } else {
                    return [];
                }
            },
            error => {
                return [];
            }
        );
    }

    get_active_data = (data) => {
        return data.filter((item) => {
            if (item.is_active) {
                return item;
            }
        })
    }

    /*Dropdown API call*/
    get_api_data = async () => {
        const dropdown_data = {};
        dropdown_data['is_loading_dropdown'] = false;

        dropdown_data['adjustment_reason'] = await this.get_adjustment_reason();

        let adjustment_code = await get_adjustment_code(this.token);
        adjustment_code.data.data = set_order_data(this.get_active_data(adjustment_code.data.data), 'adjustment_desc');
        dropdown_data['adjustment_code'] = option_formater_for_multiple_column(
            adjustment_code.data.data,
            'adjustment_code',
            'adjustment_desc',
            'adjustment_code_id',
            true, // to add blank object
            false // to shorted object
        );

        let payment_code_list = [];
        let payment_code_data = [...this.props.user_login_details.master_data.data.payment_code];
        payment_code_data.map((val) => {
            val['description'] = val['payment_code'] + ' - ' + val['payment_desc']
        });
        payment_code_list = set_order_data(this.get_active_data(this.props.user_login_details.master_data.data.payment_code), 'payment_desc');
        dropdown_data['payment_code'] = constants.option_formater(
            payment_code_list,
            'description',
            'payment_id',
            true, // to add blank object
            true // to shorted object
        );

        dropdown_data['payment_type'] = constants.option_formater(
            this.props.user_login_details.payment_methods,
            'text',
            'value',
            true, // to add blank object
            false // to shorted object
        )
        this.setState(dropdown_data);
    }

    get_date = (date, pattern) => {
        if (date) {
            return moment(date).format(pattern);
        }
        return '';
    }

    on_data_change = (data, index) => {
        this.list_data[index] = data;
    }

    //Change sorting type
    get_sort_type = (key, type) => {
        let stype = 'asc';
        if (key === this.sort_key) {
            if (type === '') {
                stype = 'asc';
            } else if (type === 'asc') {
                stype = 'desc';
            } else if (type === 'desc') {
                stype = '';
            }
        }
        return stype;
    }

    sortBy = (col) => {
        const key = col.field;
        if (!this.state.allow_sort) return;
        const is_unsaved = this.rowNodes.some((node) => {
            if (node && (node.is_charge_unsaved() || node.is_payment_unsaved())) {
                return true;
            }
            return false;
        })
        if (is_unsaved) {
            this.setState({ allow_sort: false })
            return;
        }

        let rows = [];
        this.rowNodes.map((node) => {
            if (node) {
                rows.push(node.get_data());
            }
        })

        this.sort_type = this.get_sort_type(key, this.sort_type);
        this.sort_key = key;
        if (this.sort_type !== '') {
            rows.sort((a, b) => {
                let value1 = a[key];
                let value2 = b[key];
                if (this.sort_type == 'desc') {
                    value1 = b[key];
                    value2 = a[key];
                }

                switch (col.type) {
                    case 'currency':
                        return currency_comparator(value1, value2);
                    case 'date':
                        return mm_dd_yyyy_comparator(value1, value2);
                    case 'number':
                        return number_comparator(value1, value2);

                    default:
                        return text_comparator(value1, value2);
                }
            });
        }

        this.setState({ charges: rows });
    }

    //Change sorting icon
    render_sort_icon = (key) => {
        if (!this.state.allow_sort) return null;

        let sort = '';
        if (key === this.sort_key) {
            if (this.sort_type === 'asc') {
                sort = 'ascending'
            } else if (this.sort_type === 'desc') {
                sort = 'descending';
            }
        }
        return (<i aria-hidden="true" className={`sort ${sort} small icon`}></i>);
    }

    toggle_state_values = () => {
        this.setState(prevState => ({
            expand_all: !prevState.expand_all,
            collapse_all: !prevState.collapse_all,
        }), () => {
            if (this.props.edit_all) {
                this.props.edit_all_toggle();
            }
        });
    }

    on_edit_toggle = (edit_expand, item, index) => {
        if (edit_expand) {
            this.edit_mode_rows.push(item.charge_id);
            if (this.state.allow_sort) {
                this.setState({ allow_sort: false });
            }
        } else {
            this.edit_mode_rows = this.edit_mode_rows.filter(value => (value !== item.charge_id));
            if (!this.state.allow_sort && this.edit_mode_rows && this.edit_mode_rows.length == 0) {
                const is_unsaved = this.rowNodes.some((node) => {
                    if (node && (node.is_charge_unsaved() || node.is_payment_unsaved())) {
                        return true;
                    }
                    return false;
                })
                if (!is_unsaved) {
                    this.setState({ allow_sort: true });
                }
            }
        }
    }

    //Render Expand All/Collapse All icon
    render_angle_icon = () => {
        return (this.props.charge_transaction_data && this.props.charge_transaction_data.length > 0 && <span className="angle-group-expand-collapse" onClick={this.toggle_state_values}>
            {this.state.collapse_all && <Icon icon="expand-all" className="angle-color angle" />}
            {this.state.expand_all && <Icon icon="collapse-all" className="angle-color angle" />}
        </span>);
    }

    render_header = () => {
        return this.page_metadata.column.map((col, index) => {
            if (col.field === 'angle_icon') {
                return (<Table.HeaderCell key={`level0-h${index}`} style={{ 'width': '40px' }}>
                    {this.render_angle_icon()}
                </Table.HeaderCell>);
            }
            return (<Table.HeaderCell key={`level0-h${index}`}>
                <div className={`table-col-header-container ${this.state.allow_sort ? 'sortable' : 'no-sortable'}`}
                    onClick={() => this.sortBy(col)}>
                    <span className="header-col-label" id={col.field}>{col.headerName}</span>
                    <span className="col-header-sort-icon">
                        {this.render_sort_icon(col.field)}
                    </span>
                </div>
            </Table.HeaderCell>);
        });
    }

    get_referring_physician_data = async () => {
        const medical_group = await get_medical_list(this.props.token).then(res => {
            if (res.data.data != null && res.data.data.length > 0) {
                this.medical_group_unfiltered = res.data.data;
                return res.data.data.map((val, index) => {
                    return { key: index, value: val.id, text: val.medical_group }
                });
            }
        });

        const specility = await get_primary_specility_list(this.token).then(res => {
            if (res.data.data != null && res.data.data.length > 0) {
                this.primary_spaciality_unfiltered = res.data.data;
                return res.data.data.map((val, index) => {
                    return { key: index, value: val.id, text: val.specialty_desc }
                });
            }
        });
        if (this._is_mounted) {
            this.setState({
                medical_group,
                specility
            })
        }
    }

    render_body = () => {
        const auth_data = local_storage.get("auth_data");
        let accrual_flag = false;
        let open_month = null;
        if (auth_data) {
            accrual_flag = auth_data.accrual_accounting || false;
            open_month = auth_data.open_month || null;
        }
        this.closed_editable_rows = [];
        
        const columns = this.props.IsErrorCorrectionsEnhancementsEnabled
        ? this.page_metadata.child_column_enhanced.map(value => ({ ...value }))
        : this.page_metadata.child_column.map(value => ({ ...value }));

        return this.state.charges.map((row, index) => {
            if (row.is_active) {
                this.closed_editable_rows.push(index);
            }
            return (<ErrorCorrectionEditableRowComponent
                key={index}
                data={row}
                rowNum={index}
                token={this.token}
                patientId={this.props.patient_id}
                onRef={ref => (this.rowNodes[index] = ref)}
                onDataChange={this.on_data_change}
                isSubmitted={this.props.isSubmitted}
                totalRows={this.state.charges.length}
                expand={this.state.expand_all}
                edit_all={this.props.edit_all}
                accrualFlag={accrual_flag}
                openMonth={open_month}
                innerColumn={columns || []}
                dropdown_data={this.props.dropdown_data}
                paymentCode={this.state.payment_code || []}
                adjustmentCode={this.state.adjustment_code || []}
                paymentType={this.state.payment_type || []}
                adjustmentQualifier={this.state.adjustment_qualifier || []}
                adjustmentReason={this.state.adjustment_reason || []}
                medical_group={this.state.medical_group || []}
                specility={this.state.specility || []}
                medical_group_unfiltered={this.medical_group_unfiltered || []}
                primary_spaciality_unfiltered={this.primary_spaciality_unfiltered || []}
                edit_all_toggle={this.props.edit_all_toggle}
                total_length={this.state.charges.length}
                onEditToggle={this.on_edit_toggle}
                getDxData={this.props.getDxData}
                isDeletePaymentAllowed={this.state.is_delete_payments_allowed}
            />);
        });
    }

    render_no_data_available = () => {
        return (<Table.Row >
            <Table.Cell colSpan={12} textAlign='center'>
                No Record Available
            </Table.Cell>
        </Table.Row>)
    }

    render() {
        return (<Form autoComplete="off" className="normal-form padd-r-0 error-correction-payments padd-b-0">
            <Grid><GridColumn computer={16}>
                <div className='customtable'>
                    <div className='table-responsive' >
                        <Table id={"error-correction-table"} style={{tableLayout: 'fixed'}}>
                            <Table.Header>
                                <Table.Row>
                                    {this.render_header()}
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.props.charge_transaction_data && this.props.charge_transaction_data.length > 0 && this.render_body()}
                                {this.props.charge_transaction_data.length == 0 && this.render_no_data_available()}
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </GridColumn></Grid>
        </Form>);
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        get_references: get_references
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        patient_id: state.patient_details && state.patient_details.patient_header && state.patient_details.patient_header.data && state.patient_details.patient_header.data.id || '',
        batch_payment_reference: state.batch_payment_reference,
        shared_details: state.shared_details,
        IsErrorCorrectionsEnhancementsEnabled: isFFEnabled(state.launch_darkly, ErrorCorrectionsEnhancementsProject.projectFlag)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorCorrectionEditableComponent));
