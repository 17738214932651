import * as React from 'react';
import { Form, Grid, Input } from "semantic-ui-react";
import * as moment from 'moment';
import Calendar from '../../shared/component/calendar_component';
import Selection from '../../shared/component/selection_component';
import { toastr as toaster } from 'react-redux-toastr';
import * as local_storage from '../../shared/local_storage_utility';
import { get_popper_id, format_amount } from '../utility';
import CurrencyFormatter from '../../shared/component/currency_formatter';
import { money_type } from '../../global_constants';
import { date_format_with_no_time } from '../../shared/utility';

export class ErrorCorrectionEditPaymentFormComponent extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            form_object: this.init_data(this.props.data),
            err_adjustment_qualifier: this.props.err_adjustment_qualifier || false,
            move_to_patient: false,
            move_to_insurance: false,
            is_delete: false,
            is_macro_transaction: false,
        };
    }

    is_mounted = false;
    minDate = null;
    initial_data: any = {};

    componentDidMount() {
        this.is_mounted = true;
        this.props.onRef(this);

        const auth_data = local_storage.get("auth_data");
        let accrual_flag = false;
        let open_month = null;
        if (auth_data) {
            accrual_flag = auth_data.accrual_accounting || false;
            open_month = auth_data.open_month || null;
        }
        this.minDate = null;
        if (accrual_flag) {
            this.minDate = new Date(open_month);
        }
    }
    //Component update function
    componentDidUpdate(previousProps, previousState) {
        const state_value = {};
        let is_update = false;

        if (is_update) {
            this.setState(state_value);
        }
    }
    componentWillUnmount() {
        this.is_mounted = false;
        this.props.onRef(undefined)
    }

    init_data = (data) => {
        const form_data = { ...data };
        form_data['deposit_date'] = date_format_with_no_time(form_data.deposit_date);
        form_data['adjustment_amount'] = format_amount(data.adjustment_amount || 0);
        form_data['payment_amount'] = format_amount(data.payment_amount || 0);
        form_data['transfer_detail_amount'] = format_amount(data.transfer_detail_amount || 0);
        form_data['from_ins_code'] = data.from_ins_code == 0 ? '0' : `${data.from_ins_code}`;
        form_data['xfer_ins_code'] = data.xfer_ins_code == 0 ? '0' : `${data.xfer_ins_code}`;
        form_data['batch_num'] = data.batch_num || '';
        form_data['adjustment_qualifier'] = data.adjustment_qualifier || '';
        form_data['adjustment_reason'] = data.adjustment_reason || '';
        form_data['delete'] = data.delete || false;
        form_data['to_insurance'] = data.to_insurance || false;
        form_data['to_patient'] = data.to_patient || false;
        form_data['to_charge_id'] = 0;
        this.initial_data = { ...form_data };
        return form_data;
    }

    //Get row number
    get_row_num = () => this.props.row_id;

    //Get data retruns updated data item.
    get_updated_data = () => {
        const form_data = { ...this.state.form_object };
        return {
            ...form_data,
            adjustment_amount: form_data.adjustment_amount || 0,
            payment_amount: form_data.payment_amount || 0,
            transfer_detail_amount: form_data.transfer_detail_amount || 0
        };
    };

    //Get data retruns data item.
    get_data = () => ({ ...this.props.form_object });

    //Check data change
    is_data_unsaved = () => {
        if (JSON.stringify(this.initial_data) !== JSON.stringify(this.state.form_object)) {
            return true;
        }
        return false;
    }

    //Check form validation
    is_valid = () => {
        let flag_value = true;
        let update_value = {};
        if (this.required_adjustment_qualifier(this.state.form_object.adjustment_qualifier, this.state.form_object)) {
            update_value['err_adjustment_qualifier'] = true;
            flag_value = false;
        }

        if (flag_value === false) {
            this.setState({ ...update_value })
        }
        return flag_value;
    }

    default_date = (date, pattern) => {
        if (date) {
            const dt = moment(date).format(pattern);
            return moment(dt);
        }
        return null;
    }

    required_adjustment_qualifier = (dropdown_value, form_data) => {
        const {
            payment_amount,
            adjustment_amount,
            transfer_detail_amount
        } = form_data;
        let adj_qual = dropdown_value || '';
        if (adj_qual == 'PR' || adj_qual == 'CO') {
            if (transfer_detail_amount != 0 && adjustment_amount != 0) {
                toaster.error('', 'Can not enter value in both Adjustment Amount and Transfer Detail Amount for qualifier PR and CO.');
                return true;
            } else if (adj_qual == "PR" && transfer_detail_amount == 0) {
                toaster.error('', 'For PR qualifier, enter value in Transfer Detail Amount.');
                return true;
            } else if (adj_qual == "CO" && (adjustment_amount == 0 && payment_amount == 0)) {
                toaster.error('', 'For CO qualifier, enter value in Adjustment Amount and/or Payment Amount.');
                return true;
            }
        }
        return false;
    }

    get_date = (date, pattern) => {
        if (date) {
            return moment(date).format(pattern);
        }
        return '';
    }

    handle_input_change = (e, input_name) => {
        const { value, name } = e.target;
        let value_updated = value;
        if (name == 'to_charge_id') {
            let isnum = /^\d+$/.test(value);
            if (isnum) {
                value_updated = value
            } else {
                value_updated = 0;
            }
        }
        if (this.is_mounted) {
            this.setState(prevState => ({
                form_object: {
                    ...prevState.form_object,
                    [input_name]: value_updated
                },
            }), () => {
                this.props.onDataChange(input_name, value_updated, this.props.row_id);
            });
        }
    }

    // Will handle date selection from calendar  
    handle_calendar_change = (date) => {
        const deposit_date = date_format_with_no_time(date);
        if (this.is_mounted) {
            this.setState(prevState => ({
                form_object: { ...prevState.form_object, deposit_date },
            }), () => {
                this.props.onDataChange('deposit_date', deposit_date, this.props.row_id);
            });
        }
    }

    //Dropdown change handler.
    dropdown_change_handler = (data, e) => {
        const { value, name } = e.target;
        const object = {};
        if (name == 'adjustment_qualifier') {
            object['err_adjustment_qualifier'] = this.required_adjustment_qualifier(value, this.state.form_object);
        }
        if (this.is_mounted) {
            this.setState(prev_state => ({
                form_object: {
                    ...prev_state.form_object,
                    [name]: value == ' ' ? '' : value
                },
                ...object
            }), () => {
                this.props.onDataChange(name, value == ' ' ? '' : value, this.props.row_id);
            });
        }
    };

    handle_amount_change = (value, input_name) => {
        this.setState(prevState => ({
            form_object: {
                ...prevState.form_object,
                [input_name]: value
            },
            err_adjustment_qualifier: this.required_adjustment_qualifier(prevState.form_object.adjustment_qualifier, { ...prevState.form_object, [input_name]: value })
        }), () => {
            this.props.onDataChange(input_name, value, this.props.row_id);
        });
    }

    on_check_change = (e, name) => {
        let check_value = { delete: false, to_insurance: false, to_patient: false };
        if (name == 'is_macro_transaction') {
            check_value['delete'] = !this.state.form_object.delete;
        } else {
            check_value[name] = !this.state.form_object[name];
        }

        if (this.is_mounted) {
            this.setState(prev_state => ({
                form_object: {
                    ...prev_state.form_object,
                    ...check_value
                },
                ...check_value
            }), () => {
                this.props.onObjectChange(check_value, this.props.row_id);
            });
        }
    }

    render_radio_element = (name, value, label, is_disabled) => {
        if (is_disabled) return null;
        
        const actionLabel = {
            textIndent: -17
        } as React.CSSProperties;

        return (<Form.Field style={{ paddingRight: '20px', paddingLeft: '10px' }}><div className="checkbox">
            <label><p className="ui-grid-label-empty-error-corrections">TEXT</p></label>
            <label className="radio-inline" style={actionLabel}>
                <input
                    id={`${name}-${this.props.rowNum}`}
                    name={`${this.props.rowNum}-${name}`}
                    type="radio"
                    onClick={e => this.on_check_change(e, name)}
                    checked={value}
                    readOnly
                    disabled={is_disabled}
                /> {label}
            </label>
        </div></Form.Field>);
    }

    render_macro_or_delete = () => {
        let del_comp = this.render_radio_element("delete", this.state.form_object.delete, 'Delete', (!this.state.form_object.is_active || !this.state.form_object.allow_delete || !this.props.isDeletePaymentAllowed))

        if (this.state.form_object.is_macro_transaction) {
            let macro_val = !this.state.form_object.is_active && !this.state.form_object.is_macro_transaction;
            del_comp = this.render_radio_element("is_macro_transaction", this.state.form_object.delete, 'Move to Unassigned', macro_val)
        }
        return del_comp
    }

    render() {
        return this.props.IsErrorCorrectionsEnhancementsEnabled ? this.render_form_enhanced() : this.render_form();
    }

    render_form_enhanced = () => {
        const { form_object, move_to_patient, move_to_insurance } = this.state
        let isSubmittedWithError = this.props.isSubmitted && this.state.err_amount ? 'red-error-thin numberFormat' : 'numberFormat';
        let columnClass = 'error-correction-adjustment-column';
        let labelClass = 'error-corrections-label';
        let contentAlignment = 'text-left';
        let currencyClass = `${isSubmittedWithError} ${contentAlignment}`;
        const ddlMargin = '-4px';
        const ddlWidth = '100%';
        const inputMargin = '-3px';
        const inputBottomMargin = '-2px';
        let ddlAligner = {
            marginTop: ddlMargin,
            width: ddlWidth
        };
        let labelReadOnlyAligner = {
            marginBottom: inputBottomMargin
        };
        let inputReadOnlyAligner = {
            marginTop: inputMargin,
            marginBottom: inputBottomMargin
        };

        let gridClass = 'error-corrections-grid';
        let rowClass = 'error-corrections-row';

        return (<Grid columns='equal' style={{ background: '#dee2e6', borderTop: '2px solid #747474' }} className='error-corrections-non-print-component'>
        {/* First Row */}
        <Grid.Row className={rowClass}>
            <Grid.Column className={columnClass}>
                <Grid className={gridClass}>
                    <label className={labelClass}>Deposit Date</label>
                </Grid>
            </Grid.Column>

            <Grid.Column className={columnClass}>
                <Grid className={gridClass}>
                    <label className={labelClass}>From Ins Code</label>
                </Grid>
            </Grid.Column>

            <Grid.Column className={columnClass}>
                <Grid className={gridClass}>
                    <label className={labelClass}>Payment Code</label>
                </Grid>
                </Grid.Column>

            <Grid.Column className={columnClass}>
                <Grid className={gridClass}>
                    <label className={labelClass}>Payment</label>
                </Grid>
            </Grid.Column>

            <Grid.Column className={columnClass}>
                <Grid className={gridClass}>
                    <label className={labelClass}>Adjustment Code</label>
                </Grid>
                </Grid.Column>

            <Grid.Column className={columnClass} tablet={3} computer={3}>
                    <Grid className={gridClass}>
                        <Grid.Column className={columnClass} tablet={8} computer={8} style={labelReadOnlyAligner}>
                            <label className={labelClass}>Adjustment Amt</label>
                        </Grid.Column>
                        <Grid.Column className={columnClass} tablet={8} computer={8} style={labelReadOnlyAligner}>
                            <label className={labelClass}>Transfer Detail Amt</label>
                    </Grid.Column>
                </Grid>
            </Grid.Column>

            <Grid.Column className={columnClass} tablet={3} computer={3}>
                <Grid className={gridClass}>
                    <Grid.Column className={columnClass} tablet={8} computer={8} style={labelReadOnlyAligner}>
                            <label className={labelClass}>Trans Ins Code</label>
                    </Grid.Column>
                    <Grid.Column className={columnClass} tablet={8} computer={8} style={labelReadOnlyAligner}>
                            <label className={labelClass}>To Charge</label>
                    </Grid.Column>
                </Grid>
            </Grid.Column>

            <Grid.Column className={columnClass}>
                <Grid className={gridClass}>
                    <label className={labelClass}></label>
                </Grid>
            </Grid.Column>
        </Grid.Row>
        {/* END First Row */}
        
        {/* Second Row */}
        <Grid.Row className={rowClass}>            
            <Grid.Column className={`custom-datepicker-for-table datepicker-keybowrd-payment${this.props.row_id} calendar-error-correction ${columnClass}`}>
                <Grid className={gridClass}>
                    <Form.Field >
                        <Calendar
                            id={`deposit_date_${this.props.rowNum}`}
                            id_popper={get_popper_id()}
                            maxDate={moment().format('MM/DD/YYYY')}
                            date_update={this.handle_calendar_change}
                            date={this.default_date(form_object.deposit_date, 'MM/DD/YYYY')}
                            minDate={this.state.minDate}
                            is_disabled={!form_object.is_active || form_object.deposit_date_disabled}
                            class_name={`datepicker-keybowrd-payment${this.props.row_id}`}
                        />
                    </Form.Field>
                </Grid>
            </Grid.Column>

            <Grid.Column className={columnClass}>
                <Grid className={gridClass}>
                    <Form.Field style={ddlAligner}>
                        <Selection
                            placeHolder={'Select'}
                            hidden={true}
                            id={`from_ins_code_${this.props.rowNum}`}
                            name='from_ins_code'
                            defaultValue={form_object.from_ins_code == 0 ? '0' : `${form_object.from_ins_code}` || ''}
                            options={this.props.insuranceCode || []}
                            disabled={!form_object.is_active || form_object.from_ins_code_disabled}
                            onChange={(value, e) => {
                                this.dropdown_change_handler(value, e);
                            }}
                        />
                    </Form.Field>
                </Grid>
            </Grid.Column>

            <Grid.Column className={columnClass}>
                <Grid className={gridClass}>
                    <Form.Field style={ddlAligner}>
                        <Selection
                            id={`payment_code_${this.props.rowNum}`}
                            defaultValue={form_object.payment_code || ''}
                            options={this.props.paymentCode || []}
                            name='payment_code'
                            disabled={!form_object.is_active || form_object.payment_code_disabled}
                            onChange={(value, e) => {
                                this.dropdown_change_handler(value, e);
                            }}
                        />
                    </Form.Field>
                </Grid>
            </Grid.Column>

            <Grid.Column className={columnClass}>
                <Grid className={gridClass}>
                        <Form.Field className={this.props.isSubmitted && this.state.err_amount ? "requiredWithBgColor" : ""}>
                            <CurrencyFormatter
                                id={`payment_amount${this.props.rowNum}`}
                                name={`payment_amount${this.props.row_id}`}
                                onChange={(value) => this.handle_amount_change(value, 'payment_amount')}
                                prefix='$'
                                maxLength={15}
                                allowNegative={true}
                                defaultValue={form_object.payment_amount || ''}
                                disabled={!form_object.is_active || form_object.payment_amount_disabled}
                                className={currencyClass}
                            />
                        </Form.Field>
                </Grid>
            </Grid.Column>

            <Grid.Column className={columnClass}>
                <Grid className={gridClass}>
                    <Form.Field style={ddlAligner}>
                        <Selection
                            id={`adjustment_code_${this.props.rowNum}`}
                            defaultValue={form_object.adjustment_code || ''}
                            options={this.props.adjustmentCode || []}
                            disabled={!form_object.is_active || form_object.adjustment_code_disabled}
                            name='adjustment_code'
                            onChange={(value, e) => {
                                this.dropdown_change_handler(value, e);
                            }}
                        />
                    </Form.Field>
                </Grid>
            </Grid.Column>

            <Grid.Column className={columnClass} tablet={3} computer={3}>
                <Grid className={gridClass}>
                        <Grid.Column className={columnClass} tablet={8} computer={8}>
                        <Form.Field className={this.state.err_amount ? "requiredWithBgColor" : ""} style={inputReadOnlyAligner}>
                            <CurrencyFormatter
                                id={`adjustment_amount${this.props.rowNum}`}
                                name={`adjustment_amount${this.props.row_id}`}
                                onChange={(value) => this.handle_amount_change(value, 'adjustment_amount')}
                                prefix='$'
                                maxLength={15}
                                allowNegative={true}
                                defaultValue={form_object.adjustment_amount || ''}
                                disabled={!form_object.is_active || form_object.adjustment_amount_disabled}
                                className={currencyClass}
                            />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column className={columnClass} tablet={8} computer={8}>
                        <Form.Field className={this.state.err_amount ? "requiredWithBgColor" : ""} style={inputReadOnlyAligner}>
                            <CurrencyFormatter
                                id={`transfer_detail_amount${this.props.rowNum}`}
                                name={`transfer_detail_amount${this.props.row_id}`}
                                onChange={(value) => this.handle_amount_change(value, 'transfer_detail_amount')}
                                prefix='$'
                                maxLength={15}
                                allowNegative={true}
                                defaultValue={form_object.transfer_detail_amount || ''}
                                disabled={!form_object.is_active || form_object.patient_resp_amount_disabled}
                                className={currencyClass}
                            />
                        </Form.Field>
                    </Grid.Column>
                </Grid>
            </Grid.Column>

            <Grid.Column className={columnClass} tablet={3} computer={3}>
                <Grid className={gridClass}>
                    <Grid.Column className={columnClass} tablet={8} computer={8}>
                        <Form.Field style={inputReadOnlyAligner}>
                            <Selection
                                id={`xfer_ins_code${this.props.rowNum}`}
                                defaultValue={form_object.xfer_ins_code == 0 ? '0' : `${form_object.xfer_ins_code}` || ''}
                                options={this.props.tranferInsuranceCode || []}
                                disabled={!form_object.is_active || form_object.xfer_ins_code_disabled}
                                name='xfer_ins_code'
                                onChange={(value, e) => {
                                    this.dropdown_change_handler(value, e);
                                }}
                            />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column className={columnClass} tablet={8} computer={8}>
                            <Form.Field style={inputReadOnlyAligner}>
                                <Input
                                    id={`to_charge_id${this.props.rowNum}`}
                                    name='to_charge_id'
                                    maxLength={10}
                                    error={false}
                                    disabled={!form_object.is_active || form_object.to_Charge_disabled}
                                    value={form_object.to_charge_id || ''}
                                    onChange={e => this.handle_input_change(e, 'to_charge_id')}
                                />
                            </Form.Field>
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column className={columnClass}>
                <Grid className={gridClass}>
                    {<div style={{ display: 'flex' }}>
                        {this.state.form_object.is_active && !form_object.to_patient_disabled && this.render_radio_element("to_patient", form_object.to_patient, 'Move to Patient', false)}
                        {this.state.form_object.is_active && !form_object.to_insurance_disabled && this.render_radio_element("to_insurance", form_object.to_insurance, 'Move to Insurance', false)}
                    </div>}
                </Grid>
            </Grid.Column>
        </Grid.Row>
        {/* END Second Row */}

        {/* Third Row */}
        <Grid.Row className={rowClass}>
            <Grid.Column className={columnClass}>
                <Grid className={gridClass}>
                    <label className={labelClass}>Payment Type</label>
                </Grid>
            </Grid.Column>

            <Grid.Column className={columnClass}>
                <Grid className={gridClass}>
                    <label className={labelClass}>Check Number</label>
                </Grid>
            </Grid.Column>

            <Grid.Column className={columnClass}>
                <Grid className={gridClass}>
                    <label className={labelClass}>Batch Number</label>
                </Grid>
            </Grid.Column>

            <Grid.Column className={columnClass}>
                <Grid className={gridClass}>
                    <label className={labelClass}>Adjustment Qual</label>
                </Grid>
            </Grid.Column>

            <Grid.Column className={columnClass}>
                <Grid className={gridClass}>
                    <label className={labelClass}>Adjustment Reason</label>
                </Grid>
            </Grid.Column>

            <Grid.Column className={columnClass} tablet={3} computer={3}>
                <Grid className={gridClass}>
                    <Grid.Column className={columnClass} tablet={16} computer={16} style={labelReadOnlyAligner}>
                        <label className={labelClass}>Modified By</label>
                    </Grid.Column>
                </Grid>
            </Grid.Column>

            <Grid.Column className={columnClass} tablet={3} computer={3}>
                <Grid className={gridClass}>
                    <Grid.Column className={columnClass} tablet={16} computer={16} style={labelReadOnlyAligner}>
                        <label className={labelClass}>Created By</label>
                    </Grid.Column>
                </Grid>
            </Grid.Column>

            <Grid.Column className={columnClass}>
                <Grid className={gridClass}>
                    <label className={labelClass}></label>
                </Grid>
            </Grid.Column>
        </Grid.Row>
        {/* END Third Row */}

        {/* Fourth Row */}
        <Grid.Row className={rowClass}>
            <Grid.Column className={`custom-datepicker-for-table datepicker-keybowrd-payment${this.props.row_id} calendar-error-correction ${columnClass}`}>
                <Grid className={gridClass}>
                    <Form.Field style={ddlAligner}>
                        <Selection
                            id={`money_type${this.props.rowNum}`}
                            defaultValue={form_object.money_type || ''}
                            options={this.props.paymentType || []}
                            disabled={!form_object.is_active || form_object.money_type_disabled}
                            name='money_type'
                            onChange={(value, e) => {
                                this.dropdown_change_handler(value, e);
                            }}
                        />
                    </Form.Field>
                </Grid>
            </Grid.Column>

            <Grid.Column className={columnClass}>
                    <Grid className={gridClass}>
                        <Form.Field >
                            <Input
                                id={`check_num${this.props.rowNum}`}
                                name='check_num'
                                maxLength={20}
                                error={false}
                                disabled={!form_object.is_active || form_object.check_num_disabled}
                                value={(form_object.money_type != money_type.CREDIT_CARD_NUM && form_object.money_type != money_type.DEBIT_CARD_NUM) && form_object.check_num != null ? form_object.check_num
                                    : (form_object.money_type == money_type.CREDIT_CARD_NUM || form_object.money_type == money_type.DEBIT_CARD_NUM) && form_object.credit_card_num != null ? form_object.credit_card_num : ''
                                }
                                onChange={e => {
                                    if (form_object.money_type === money_type.CREDIT_CARD_NUM || form_object.money_type === money_type.DEBIT_CARD_NUM) {
                                        this.handle_input_change(e, 'credit_card_num');
                                    } else {
                                        this.handle_input_change(e, 'check_num');
                                    }
                                }}
                            />
                        </Form.Field>
                </Grid>
            </Grid.Column>
            <Grid.Column className={columnClass}>
                    <Grid className={gridClass}>
                    <Form.Field >
                        <Input
                            id={`batch_num${this.props.rowNum}`}
                            name='batch_num'
                            maxLength={50}
                            error={false}
                            disabled={!form_object.is_active || form_object.batch_num_disabled}
                            value={form_object.batch_num || ''}
                            onChange={e => this.handle_input_change(e, 'batch_num')}
                        />
                    </Form.Field>
                </Grid>
            </Grid.Column>
            <Grid.Column className={columnClass}>
                <Grid className={gridClass}>
                    <Form.Field style={ddlAligner}>
                        <Selection
                            name='adjustment_qualifier'
                            id={`adjustment_qualifier${this.props.rowNum}`}
                            defaultValue={form_object.adjustment_qualifier || ''}
                            options={this.props.adjustmentQualifier || []}
                            disabled={!form_object.is_active || form_object.adjustment_qualifier_disabled}
                            isRequired={this.state.err_adjustment_qualifier}
                            onChange={(value, e) => {
                                this.dropdown_change_handler(value, e);
                            }}
                        />
                    </Form.Field>
                </Grid>
            </Grid.Column>

            <Grid.Column className={columnClass}>
                <Grid className={gridClass}>
                    <Form.Field style={ddlAligner}>
                        <Selection
                            id={`adjustment_reason${this.props.rowNum}`}
                            defaultValue={form_object.adjustment_reason || ''}
                            options={this.props.adjustmentReason || []}
                            disabled={!form_object.is_active || form_object.adjustment_reason_disabled}
                            name='adjustment_reason'
                            onChange={(value, e) => {
                                this.dropdown_change_handler(value, e);
                            }}
                        />
                    </Form.Field>
                </Grid>
            </Grid.Column>

            <Grid.Column className={columnClass} tablet={3} computer={3}>
                <Grid className={gridClass}>
                    <Grid.Column className={columnClass} tablet={16} computer={16}>
                            <Form.Field style={inputReadOnlyAligner}>
                                <Input
                                    id={`modified_by${this.props.rowNum}`}
                                    name='modified_by'
                                    value={form_object.modified_by || ""}
                                    disabled={true}
                                    type='text'
                                />
                            </Form.Field>
                    </Grid.Column>
                </Grid>
            </Grid.Column>

            <Grid.Column className={columnClass} tablet={3} computer={3}>
                <Grid className={gridClass}>
                    <Grid.Column className={columnClass} tablet={16} computer={16}>
                            <Form.Field style={inputReadOnlyAligner}>
                                <Input
                                    id={`created_by${this.props.rowNum}`}
                                    name='created_by'
                                    value={form_object.created_by || ""}
                                    disabled={true}
                                    type='text'
                                />
                            </Form.Field>
                    </Grid.Column>
                </Grid>
            </Grid.Column>

            <Grid.Column className={columnClass}>
                <Grid className={gridClass}>
                    {<div style={{ display: 'flex' }}>
                        {this.render_macro_or_delete()}
                    </div>}
                </Grid>
            </Grid.Column>
        </Grid.Row>
        {/* END Fourth Row */}
        </Grid>);
    };

    render_form = () => {
        const { form_object, move_to_patient, move_to_insurance } = this.state
        let table_width = document.getElementById('error-correction-table').clientWidth;

        return (<Grid style={{ background: '#dee2e6', borderTop: '2px solid #747474', 'width': table_width }} className='error-corrections-non-print-component'>
            <Grid.Column tablet={4} computer={4}
                className={`custom-datepicker-for-table datepicker-keybowrd-payment${this.props.row_id} calendar-error-correction`}>
                <Form.Field >
                    <label>Deposit Date</label>
                    <Calendar
                        id={`deposit_date_${this.props.rowNum}`}
                        id_popper={get_popper_id()}
                        maxDate={moment().format('MM/DD/YYYY')}
                        date_update={this.handle_calendar_change}
                        date={this.default_date(form_object.deposit_date, 'MM/DD/YYYY')}
                        minDate={this.state.minDate}
                        is_disabled={!form_object.is_active || form_object.deposit_date_disabled}
                        class_name={`datepicker-keybowrd-payment${this.props.row_id}`}
                    />
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}>
                <Form.Field className={this.props.isSubmitted && this.state.err_amount ? "requiredWithBgColor" : ""}>
                    <label>Payment</label>
                    <CurrencyFormatter
                        id={`payment_amount${this.props.rowNum}`}
                        name={`payment_amount${this.props.row_id}`}
                        onChange={(value) => this.handle_amount_change(value, 'payment_amount')}
                        prefix='$'
                        maxLength={15}
                        allowNegative={true}
                        defaultValue={form_object.payment_amount || ''}
                        disabled={!form_object.is_active || form_object.payment_amount_disabled}
                        className={this.props.isSubmitted && this.state.err_amount ? 'red-error-thin numberFormat' : 'numberFormat'}
                    />
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}>
                <Form.Field className={this.state.err_amount ? "requiredWithBgColor" : ""}>
                    <label>Adjustment</label>
                    <CurrencyFormatter
                        id={`adjustment_amount${this.props.rowNum}`}
                        name={`adjustment_amount${this.props.row_id}`}
                        onChange={(value) => this.handle_amount_change(value, 'adjustment_amount')}
                        prefix='$'
                        maxLength={15}
                        allowNegative={true}
                        defaultValue={form_object.adjustment_amount || ''}
                        disabled={!form_object.is_active || form_object.adjustment_amount_disabled}
                        className={this.props.isSubmitted && this.state.err_amount ? 'red-error-thin numberFormat' : 'numberFormat'}
                    />
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}></Grid.Column>
            <Grid.Column tablet={4} computer={4} >
                <Form.Field >
                    <label>From Ins Code</label>
                    <Selection
                        placeHolder={'Select'}
                        hidden={true}
                        id={`from_ins_code_${this.props.rowNum}`}
                        name='from_ins_code'
                        defaultValue={form_object.from_ins_code == 0 ? '0' : `${form_object.from_ins_code}` || ''}
                        options={this.props.insuranceCode || []}
                        disabled={!form_object.is_active || form_object.from_ins_code_disabled}
                        onChange={(value, e) => {
                            this.dropdown_change_handler(value, e);
                        }}
                    />
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4} >
                <Form.Field >
                    <label>Payment Code</label>
                    <Selection
                        id={`payment_code_${this.props.rowNum}`}
                        defaultValue={form_object.payment_code || ''}
                        options={this.props.paymentCode || []}
                        name='payment_code'
                        disabled={!form_object.is_active || form_object.payment_code_disabled}
                        onChange={(value, e) => {
                            this.dropdown_change_handler(value, e);
                        }}
                    />
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4} >
                <Form.Field >
                    <label>Adjustment Code</label>
                    <Selection
                        id={`adjustment_code_${this.props.rowNum}`}
                        defaultValue={form_object.adjustment_code || ''}
                        options={this.props.adjustmentCode || []}
                        disabled={!form_object.is_active || form_object.adjustment_code_disabled}
                        name='adjustment_code'
                        onChange={(value, e) => {
                            this.dropdown_change_handler(value, e);
                        }}
                    />
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4} >
                <Form.Field className={this.state.err_amount ? "requiredWithBgColor" : ""}>
                    <label>Transfer Detail Amount</label>
                    <CurrencyFormatter
                        id={`transfer_detail_amount${this.props.rowNum}`}
                        name={`transfer_detail_amount${this.props.row_id}`}
                        onChange={(value) => this.handle_amount_change(value, 'transfer_detail_amount')}
                        prefix='$'
                        maxLength={15}
                        allowNegative={true}
                        defaultValue={form_object.transfer_detail_amount || ''}
                        disabled={!form_object.is_active || form_object.patient_resp_amount_disabled}
                        className={this.props.isSubmitted && this.state.err_amount ? 'red-error-thin numberFormat' : 'numberFormat'}
                    />
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}>
                <Form.Field >
                    <label>Transfer Insurance Code</label>
                    <Selection
                        id={`xfer_ins_code${this.props.rowNum}`}
                        defaultValue={form_object.xfer_ins_code == 0 ? '0' : `${form_object.xfer_ins_code}` || ''}
                        options={this.props.tranferInsuranceCode || []}
                        disabled={!form_object.is_active || form_object.xfer_ins_code_disabled}
                        name='xfer_ins_code'
                        onChange={(value, e) => {
                            this.dropdown_change_handler(value, e);
                        }}
                    />
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4} >
                <Form.Field >
                    <label>Payment Type</label>
                    <Selection
                        id={`money_type${this.props.rowNum}`}
                        defaultValue={form_object.money_type || ''}
                        options={this.props.paymentType || []}
                        disabled={!form_object.is_active || form_object.money_type_disabled}
                        name='money_type'
                        onChange={(value, e) => {
                            this.dropdown_change_handler(value, e);
                        }}
                    />
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4} >
                <Form.Field >
                    <label>Adjustment Qualifier</label>
                    <Selection
                        name='adjustment_qualifier'
                        id={`adjustment_qualifier${this.props.rowNum}`}
                        defaultValue={form_object.adjustment_qualifier || ''}
                        options={this.props.adjustmentQualifier || []}
                        disabled={!form_object.is_active || form_object.adjustment_qualifier_disabled}
                        isRequired={this.state.err_adjustment_qualifier}
                        onChange={(value, e) => {
                            this.dropdown_change_handler(value, e);
                        }}
                    />
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}>
                <Form.Field >
                    <label>To Charge ID</label>
                    <Input
                        id={`to_charge_id${this.props.rowNum}`}
                        name='to_charge_id'
                        maxLength={9}
                        error={false}
                        disabled={!form_object.is_active || form_object.to_Charge_disabled}
                        value={form_object.to_charge_id || ''}
                        onChange={e => this.handle_input_change(e, 'to_charge_id')}
                    />
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}>
                <Form.Field >
                    <label>Check Number</label>
                    <Input
                        id={`check_num${this.props.rowNum}`}
                        name='check_num'
                        maxLength={20}
                        error={false}
                        disabled={!form_object.is_active || form_object.check_num_disabled}
                        value={(form_object.money_type != money_type.CREDIT_CARD_NUM && form_object.money_type != money_type.DEBIT_CARD_NUM) && form_object.check_num != null  ? form_object.check_num 
                             : (form_object.money_type == money_type.CREDIT_CARD_NUM || form_object.money_type == money_type.DEBIT_CARD_NUM) && form_object.credit_card_num != null ? form_object.credit_card_num : ''
                        }
                        onChange={e => {
                            if(form_object.money_type === money_type.CREDIT_CARD_NUM || form_object.money_type === money_type.DEBIT_CARD_NUM){
                                this.handle_input_change(e, 'credit_card_num');
                            }else{
                                this.handle_input_change(e, 'check_num');
                            }
                        }}
                    />
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}>
                <Form.Field >
                    <label>Batch Number</label>
                    <Input
                        id={`batch_num${this.props.rowNum}`}
                        name='batch_num'
                        maxLength={50}
                        error={false}
                        disabled={!form_object.is_active || form_object.batch_num_disabled}
                        value={form_object.batch_num || ''}
                        onChange={e => this.handle_input_change(e, 'batch_num')}
                    />
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4} >
                <Form.Field >
                    <label>Adjustment Reason</label>
                    <Selection
                        id={`adjustment_reason${this.props.rowNum}`}
                        defaultValue={form_object.adjustment_reason || ''}
                        options={this.props.adjustmentReason || []}
                        disabled={!form_object.is_active || form_object.adjustment_reason_disabled}
                        name='adjustment_reason'
                        onChange={(value, e) => {
                            this.dropdown_change_handler(value, e);
                        }}
                    />
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}>
                <Form.Field >
                    <label>Created By</label>
                    <Input
                        id={`created_by${this.props.rowNum}`}
                        name='created_by'
                        value={form_object.created_by || ""}
                        disabled={true}
                        type='text'
                    />
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={4} computer={4}>
                <Form.Field >
                    <label>Modified By</label>
                    <Input
                        id={`modified_by${this.props.rowNum}`}
                        name='modified_by'
                        value={form_object.modified_by || ""}
                        disabled={true}
                        type='text'
                    />
                </Form.Field>
            </Grid.Column>
            <Grid.Column tablet={6} computer={6}>
                {<div style={{ display: 'flex' }}>
                    {this.state.form_object.is_active && !form_object.to_patient_disabled && this.render_radio_element("to_patient", form_object.to_patient, 'Move to Patient', false)}
                    {this.state.form_object.is_active && !form_object.to_insurance_disabled && this.render_radio_element("to_insurance", form_object.to_insurance, 'Move to Insurance', false)}
                    {this.render_macro_or_delete()}
                </div>}
            </Grid.Column>
        </Grid>);
    };
}
