export const gridColumns = [
    {
        headerName: 'Setting Name',
        field: 'setting_name',
        renderer: 'setting_name_renderer',
        style: { minWidth: '150px', width: '150px' }
    },
    {
        headerName: 'Location',
        field: 'location',
        renderer: 'location_renderer',
        style: { minWidth: '150px', width: '150px' }
    },
    {
        headerName: 'Insurance Class',
        field: 'insurance_class_id',
        renderer: 'insurance_class_renderer',
        style: { minWidth: '160px', width: '160px' }
    },
    {
        headerName: 'Insurance Code',
        field: 'insurance_code',
        renderer: 'insurance_code_renderer',
        style: { minWidth: '200px', width: '200px' }
    },
    {
        headerName: 'Individual Number',
        field: 'individual_number',
        renderer: 'individual_number_renderer',
        style: { minWidth: '130px', width: '130px' }
    },
    {
        headerName: 'Individual Qualifier',
        field: 'individual_qualifier',
        renderer: 'individual_qualifier_renderer',
        style: { minWidth: '120px', width: '120px' }
    },
    {
        headerName: 'Group Number',
        field: 'group_number',
        renderer: 'group_number_renderer',
        style: { minWidth: '130px', width: '130px' }
    },
    {
        headerName: 'Group Qualifier',
        field: 'group_qualifier',
        renderer: 'group_qualifier_renderer',
        style: { minWidth: '120px', width: '120px' }
    },
    {
        headerName: 'Tax ID Number',
        field: 'tax_identification_number',
        renderer: 'tax_identification_number_renderer',
        style: { minWidth: '180px', width: '180px' }
    },
    {
        headerName: 'SSN/EIN',
        field: 'ssn_tin_flag',
        renderer: 'ssn_tin_flag_renderer',
        style: { minWidth: '130px', width: '130px' }
    },
    {
        headerName: 'Status',
        field: 'status',
        renderer: 'status_renderer',
        style: { minWidth: '100px', width: '100px', textAlign: 'center' }
    }
];

export const location_claim_holds_columns = [
    {
        headerName: 'Hold Name',
        field: 'claim_hold_name',
        renderer: 'hold_name_renderer',
        style: { minWidth: '150px', width: '150px' }
    },
    {
        headerName: 'Provider',
        field: 'provider',
        renderer: 'provider_renderer',
        style: { minWidth: '150px', width: '150px' }
    },
    {
        headerName: 'Insurance Class',
        field: 'insurance_class_id',
        renderer: 'insurance_class_renderer',
        style: { minWidth: '160px', width: '160px' }
    },
    {
        headerName: 'Insurance Code',
        field: 'insurance_code',
        renderer: 'insurance_code_renderer',
        style: { minWidth: '200px', width: '200px' }
    },
    {
        headerName: 'Claim Delay Reason',
        field: 'claim_delay_reason_id',
        renderer: 'claim_delay_reason_renderer',
        style: { minWidth: '130px', width: '130px' }
    },
    {
        headerName: 'Date of Service Range',
        field: 'dos_range',
        renderer: 'dos_renderer',
        style: { minWidth: '130px', width: '130px' }
    },
    {
        headerName: 'Status',
        field: 'status',
        renderer: 'status_renderer',
        style: { minWidth: '100px', width: '100px', textAlign: 'center' }
    }
];


export const provider_claim_holds_columns = [
    {
        headerName: 'Hold Name',
        field: 'claim_hold_name',
        renderer: 'hold_name_renderer',
        style: { minWidth: '150px', width: '150px' }
    },
    {
        headerName: 'Location',
        field: 'location',
        renderer: 'location_renderer',
        style: { minWidth: '150px', width: '150px' }
    },
    {
        headerName: 'Insurance Class',
        field: 'insurance_class_id',
        renderer: 'insurance_class_renderer',
        style: { minWidth: '160px', width: '160px' }
    },
    {
        headerName: 'Insurance Code',
        field: 'insurance_code',
        renderer: 'insurance_code_renderer',
        style: { minWidth: '200px', width: '200px' }
    },
    {
        headerName: 'Claim Delay Reason',
        field: 'claim_delay_reason_id',
        renderer: 'claim_delay_reason_renderer',
        style: { minWidth: '130px', width: '130px' }
    },
    {
        headerName: 'Date of Service Range',
        field: 'dos_range',
        renderer: 'dos_renderer',
        style: { minWidth: '130px', width: '130px' }
    },
    {
        headerName: 'Status',
        field: 'status',
        renderer: 'status_renderer',
        style: { minWidth: '100px', width: '100px', textAlign: 'center' }
    }
];

export const statusSelectOptions = [
    {
        value: true,
        text: 'Active'
    },
    {
        value: false,
        text: 'Inactive'
    }
];

export const initialSettings = {
    permutationsLimit: 2000
};

// general validation errors
export const generalValidationError = 'The setting was unable to be saved.';
export const startDateRequired = 'Start Date is required.';
export const maximumCombinationsExceeded = `Selecting this option exceeds the max combinations of ${initialSettings.permutationsLimit}. Please create an additional Billing Setting to accommodate.`;
export const generalValidationErrorClaimHold = 'The Claim Hold was unable to be saved.';
export const maximumCombinationsExceededClaimHold = `Selecting this option exceeds the max combinations of ${initialSettings.permutationsLimit}. Please add another Claim Hold to accommodate.`;

// provider validation errors
export const settingNameUniqueValidationError = 'The Setting Name entered already exists for the provider. Enter a new Setting Name.';
export const dataInGroupsValidationError = 'At least 1 Location AND 1 Insurance Class/Code must be selected.';
export const dataRestrictionValidationError = 'One or more Location/Insurance Class/Insurance Code combinations already exists for this provider.';
export const dataInGroupsValidationErrorClaimHold = 'You must select at least one Location.';
export const onlyLocationInGroupsValidationErrorClaimHold = 'All charges in the DOS Range for the selected Location(s) will be on hold unless you add an Insurance Class or Code to your selection.';


// location validation errors
export const settingNameUniqueValidationLocationClaimHoldError = 'The Setting Name entered already exists for the location. Enter a new Setting Name.';
export const dataInGroupsValidationLocationClaimHoldError = 'At least 1 Provider AND 1 Insurance Class/Code must be selected.';
export const dataRestrictionValidationLocationClaimHoldError = 'One or more Provider/Insurance Class/Insurance Code combinations already exists for this location.';
export const dataInGroupsValidationErrorLocationClaimHold = 'You must select at least one Provider.';
export const onlyProviderInGroupsValidationErrorClaimHold = 'All charges in the DOS Range for the selected Provider(s) will be on hold unless you add an Insurance Class or Code to your selection.';