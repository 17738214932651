import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Table } from 'semantic-ui-react';

export class ErrorCorrectionPrintableData extends React.Component<any, any> {

    render_charge_details = () => {
        return (
        <Table id={"error-correction-table-printable"} className="error-correction-print">
            <Table.Body>
                <Table.Row >
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Charge ID</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {this.props.data.charge_id}</Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Date of Service</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {this.props.data.date_of_service }</Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Procedure Code</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {this.props.data.proc_code}</Table.Cell>
                </Table.Row >
                <Table.Row>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Units</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {this.props.data.units}</Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Charge Amount</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {this.props.data.amount}</Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Total Charge Amount</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {this.props.data.total_charge_amount}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Payment</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {this.props.data.payment}</Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Adjustment</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {this.props.data.adjustment}</Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Balance</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {this.props.data.balance}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Insurance Balance</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {this.props.data.insurance_balance}</Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Patient Balance</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {this.props.data.patient_balance}</Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Last Modified</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {this.props.data.updated_date}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Provider</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {this.props.data.provider}</Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Location</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {this.props.data.place_of_service}</Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Referring Physician</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {this.props.data.referring_physician}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Plan of Care Date</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {this.props.data.plan_of_care_date}</Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Preauthorization</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {this.props.data.preauth}</Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Modifiers</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {this.props.data.modifiers}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Diagnosis Codes</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {this.props.data.diagnosis_codes}</Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}></Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
        )
    }

    render_payment_details = (data, index) => {
        if (!data) return;
        return (
        <Table id={"error-correction-table-printable"} key = {index} className="error-correction-print" style={{'background': 'rgb(222, 226, 230)',
            'borderTop': '2px solid rgb(116, 116, 116)'}}>
            <Table.Body>
                <Table.Row >
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Deposit Date</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {data.deposit_date}</Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Payment</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {data.payment}</Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Adjustment</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {data.adjustment}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>From Ins Code</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {data.from_ins_code}</Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Payment Code</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {data.payment_code}</Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Adjustment Code</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {data.adjustment_code}</Table.Cell>
                </Table.Row >
                <Table.Row>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Transfer Detail Amount</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {data.transfer_detail_amount}</Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Transfer Insurance Code</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {data.insurance_code}</Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Payment Type</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {data.payment_type}</Table.Cell>
                </Table.Row >
                <Table.Row >
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Adjustment Qualifier</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {data.adj_qualifier}</Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>To Charge ID</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {data.to_charge_id}</Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Check Number</strong></Table.Cell>
                    <Table.Cell style={{ "paddingBottom": "10px!important" }}>{data.check_cred_num}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Batch Number</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {data.batch_num}</Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Adjustment Reason</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {data.adj_reason}</Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Created By</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {data.created_by}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}><strong>Modified By</strong></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> {data.modified_by}</Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}></Table.Cell>
                    <Table.Cell style={{"paddingBottom":"10px!important"}}> </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
        )
    }

    render() {
        return (
            <React.Fragment>
                {this.render_charge_details()}
                {this.props.payment_data && this.props.payment_data.length > 0 && this.props.payment_data.map((data, index) => this.render_payment_details(data, index) ) }
            </React.Fragment>   
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch)
}

const mapStateToProps = (state) => {
    return {
        
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorCorrectionPrintableData));
