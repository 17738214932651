import * as React from "react";
import * as moment from "moment";
import { toastr_options } from "../../global_constants";
import { dateTimeFormatter } from "../../reports/report_constants";

// Show multiple messages
export const show_html_content_toaster = (msg) => {
    return {
        component: () => (<div><div dangerouslySetInnerHTML={{ __html: msg }} /></div>),
        timeOut: toastr_options.toastr_time_out,
        preventDuplicates: true
    };
};

export const get_title = (name, separter, content) => {
    let date = moment(new Date()).format("MM/DD/YYYY") + " " + moment(new Date()).format("hh:mm:ss A");
    return (`${name}${separter}${date}${separter}${separter}${content}${separter}${separter}`);
}

export const get_sub_title = (name, separter, content) => {
    return (`${name}${separter}${content}${separter}${separter}`);
}

export const print_card_template = (columns = [], data = {}) => {
    let template = '<form class="ui form"style="border: 1px solid; padding: 10px;"><div class="ui grid" >';
    columns.map((col) => {
        template = `${template}<div class="${col.computer || 'four'} wide computer ${col.computer || 'eight'} wide tablet column">`;
        let value = col.type === 'datetime' ? dateTimeFormatter(data[col.field]) : data[col.field];
        template = `${template}<div class="field"><label>${col.name}</label><div class="values">${value || ''}</div></div></div>`;
    });
    template = `${template}</div></form>`;
    return template;
}

export const export_card_content = (columns = [], data = {}) => {
    let header = [];
    let rows = [];
    columns.map((col, index) => {
        header.push(col.name);
        let value = col.type === 'datetime' ? dateTimeFormatter(data[col.field]) : data[col.field];
        value=index == 0 ? '\n' + value : value;
        rows.push(value);
    });
    return [...header,...rows];
}