import * as global_constants from '../../global_constants';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request';

//Get all active patient letters
export const get_patient_letters = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_letter.patient_letters}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let requestConfig = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(requestConfig);
};

//Get patient letter all cases
export const get_patient_letter_cases = (token, query_string) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_letter.patient_letters_cases}?${query_string}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let requestConfig = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(requestConfig);
};

//Get patient letter HTML preview
export const get_patient_letter_preview = (token, query_string) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_letter.patient_letters_preview}?${query_string}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let requestConfig = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(requestConfig);
};

//Edit Patient letter
export const get_patient_letter_edit = (token, query_string) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_letter.get_patient_letter_edit}?${query_string}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let requestConfig = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(requestConfig);
};
