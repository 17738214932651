import * as moment from 'moment';
import * as React from 'react';
import autoDatePicker from "./date_picker_utility";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import { toastr } from 'react-redux-toastr';
import { enum_date_range_error_display_type } from './../../shared/shared_constants';

interface IProps {
    startDate?: object,
    endDate?: object,
    minDate?: object,
    maxDate?: object,
    updateDatesChange?: Function,
    error?: boolean,
    minimumNights?: number,
    id?: string,
    startYear?: number,
    errorDisplayType?: enum_date_range_error_display_type,
    updateHasError?: Function,
    disableClearOnValidation?: boolean,
    is_disabled?: boolean,
    anchorDirection?: string,
}

class DateRangePickerComponent extends React.Component<IProps, any> {
    [x: string]: any;
    start_date_id = this.props.id ? "date_range_start_date_id" +this.props.id : "date_range_start_date_id";
    end_date_id = this.props.id ? "date_range_end_date_id" +this.props.id : "date_range_end_date_id";

    static defaultProps: Partial<IProps> = {
        anchorDirection: 'left'
    };

    constructor(props) {
        super(props)
        this.state = {
            focusedInput: null,
            startDate: this.props.startDate ? this.props.startDate : null,
            endDate: this.props.endDate ? this.props.endDate : null,
            minDate: this.props.minDate ? this.props.minDate : null,
            maxDate: this.props.maxDate ? this.props.maxDate : null,
            is_disabled: this.props.is_disabled ? this.props.is_disabled : false,
            minimumNights: this.props.minimumNights ? this.props.minimumNights : 0,
            error: this.props.error ? this.props.error : false,
            startYear: this.props.startYear ? this.props.startYear : 1900,
            direction: 'left',
            dateFormat: 'MM/DD/YYYY',
            numMonths: 2,
            clearStartDate: false,
            clearEndDate: false,
            errorMessage: ''
        };
    }
    timeout_border:any = null;
    ERROR_MSG_INVALID_THROUGH_DATE_RANGE = '"Through Date" must be on or after the "From Date".';
    ERROR_MSG_INVALID_FROM_DATE_RANGE = '"From Date" must be on or before the "Through Date".';
    ENTITY_FROM_DATE = "From Date";
    ENTITY_THROUGH_DATE = "Through Date";
    OPERATOR_AFTER = "after";
    OPERATOR_BEFORE = "before";
   
    ERROR_MSG_INVALID_INPUT = 'You must enter a valid date in the format of mm/dd/yyyy.';
    UPPER_LIMIT = "12/31/2100";
    LOWER_LIMIT = "01/01/1900";

    componentDidMount() {

        /**Registering custom event*/
        let startInput = document.getElementById(this.start_date_id);
        if (startInput) {
            startInput.setAttribute("maxLength", "10")
            startInput.addEventListener('keydown', this.startInputKeyDown);
            startInput.addEventListener('blur', this.startInputBlur);
        }

        let endInput = document.getElementById(this.end_date_id);
        if (endInput) {
            endInput.setAttribute("maxLength", "10")
            endInput.addEventListener('keydown', this.endInputKeyDown);
            endInput.addEventListener('blur', this.endInputBlur);
        }

        let dateInput = document.getElementById(this.start_date_id);
        if (dateInput && dateInput.parentElement && dateInput.parentElement.parentElement) {
            let calendarButton: any = dateInput.parentElement.parentElement.querySelector(".DateRangePickerInput_calendarIcon");
            if (calendarButton) {
                calendarButton.tabIndex = -1;
            }
        }
    }

    startInputKeyDown = (e) => {

        if (this.props.errorDisplayType && this.props.errorDisplayType == enum_date_range_error_display_type.UNDERCOMPONENT) {
            this.setState({
                errorMessage: ''
            });
            if (this.props.updateHasError)
                this.props.updateHasError(false);
        }

        if (!this.is_valid_char_entered(e)) {
            e.preventDefault();
            return;
        }

        var key = e.key;
        if (!e.shiftKey && key === 'Tab') {
            e.stopPropagation();
            e.preventDefault();
            e.stopImmediatePropagation();
            let ele: any = document.getElementById(this.end_date_id);
            ele.focus();
            ele.select();
        }

        if (key === 'Enter') {
            this.startInputBlur(e, false);
        }
    }

    is_valid_char_entered = (event) => {
        //Allow [0-9], '-', '/' and '\' chars only
        let key = event.key;
        let regex = /^[0-9-/\\]/;
        if (!regex.test(key) && key != 'Tab' && key != "Backspace" && key != 'Enter' && key != 'Delete' && key != 'ArrowLeft' && key != 'ArrowRight' && !event.ctrlKey) {
            return false
        }
        else return true;
    }

    display_error(entity, operator, value) {
        this.display_error_by_type('',`"${entity}" must be on or ${operator} "${value}".`);          
    }

    display_error_by_type = (title: string, message: string) => {
        if (this.props.updateHasError)
            this.props.updateHasError(true);

        switch (this.props.errorDisplayType) {
            case enum_date_range_error_display_type.UNDERCOMPONENT: {
                this.setState({
                    errorMessage: message
                });
                break;
            }
            case enum_date_range_error_display_type.TOASTER:
            default: {
                toastr.error(title, message);
                break;
            }
        }
    }

    is_start_date_valid_in_date_range = (startDate, endDate) => {

        if (!startDate) {
            this.display_error_by_type('',this.ERROR_MSG_INVALID_FROM_DATE_RANGE);
            return false; // if startDate is null then it is not valid
        }

        if(this.props.minDate) {
            // return false if startDate is lessthen minDate
            if (startDate.startOf('day').isBefore(moment(this.props.minDate).startOf('day'))) {
                this.display_error(this.ENTITY_FROM_DATE, this.OPERATOR_AFTER, moment(this.props.minDate).format("MM/DD/YYYY"));
                return false;
            }
        }    

        if(this.props.maxDate) {
            // return false if startDate is more than maxDate
            if (startDate.startOf('day').isAfter(moment(this.props.maxDate).startOf('day'))) {
                this.display_error(this.ENTITY_FROM_DATE, this.OPERATOR_BEFORE, moment(this.props.maxDate).format("MM/DD/YYYY")); 
                return false;
            }
        }    
                
        // if startDate is exceeded lower limit then it is not valid
        if(startDate.startOf('day').isBefore(moment(this.LOWER_LIMIT).startOf('day'))) {
            this.display_error(this.ENTITY_FROM_DATE, this.OPERATOR_AFTER, moment(this.LOWER_LIMIT).format("MM/DD/YYYY"));
            return false;
        }

        // if startDate is exceeded upper limit then it is not valid
        if(startDate.startOf('day').isAfter(moment(this.UPPER_LIMIT))) {
            this.display_error(this.ENTITY_FROM_DATE, this.OPERATOR_BEFORE, moment(this.UPPER_LIMIT).format("MM/DD/YYYY")); 
            return false;
        }

        if(!endDate && ! moment.isMoment(endDate)) return true; // if end date is null then it is valid

        let valid_range = startDate.startOf('day').isSameOrBefore(endDate.endOf('day')); // return true only if startDate is less than endDate

        if (!valid_range) {
            this.setState({
                endDate: null,
                clearEndDate: true
            }, () => {
                this.setState({
                    clearEndDate: false
                });
            });
        }

        return true;
    }

    is_end_date_valid_in_date_range = (startDate, endDate) => {
        if (!endDate) {
            this.display_error_by_type('',this.ERROR_MSG_INVALID_THROUGH_DATE_RANGE);
            return false; // if startDate is null then it is not valid
        }  
        if(this.props.maxDate) {
            // return false if endDate is more than maxDate
            if (endDate.startOf('day').isAfter(moment(this.props.maxDate).startOf('day'))) {
                this.display_error(this.ENTITY_THROUGH_DATE, this.OPERATOR_BEFORE, moment(this.props.maxDate).format("MM/DD/YYYY"));
                return false;
            }
        }    
        
        if(this.props.minDate) {
            // return false if endDate is less than minDate
            if (endDate.startOf('day').isBefore(moment(this.props.minDate).startOf('day'))) {
                this.display_error(this.ENTITY_THROUGH_DATE, this.OPERATOR_AFTER, moment(this.props.minDate).format("MM/DD/YYYY"));
                return false;
            }
        }    
        // if endDate is exceeded upper limit then it is not valid
        if(endDate.startOf('day').isAfter(moment(this.UPPER_LIMIT))) {
            this.display_error(this.ENTITY_THROUGH_DATE, this.OPERATOR_BEFORE, moment(this.UPPER_LIMIT).format("MM/DD/YYYY"));
            return false;
        }

         // if endDate is exceeded endYear then it is not valid
         if(endDate.startOf('day').isBefore(moment(this.LOWER_LIMIT).startOf('day'))) {
            this.display_error(this.ENTITY_THROUGH_DATE, this.OPERATOR_AFTER, moment(this.LOWER_LIMIT).format("MM/DD/YYYY"));
            return false;
        }

        if(!startDate && ! moment.isMoment(startDate)) return true; // if end date is null then it is valid

        let valid_range = endDate.startOf('day').isSameOrAfter(startDate.startOf('day'));

        if (!valid_range) {
            this.setState({
                startDate: null,
                clearStartDate: true
            }, () => {
                this.setState({
                    clearStartDate: false
                });
            });
        }

        return true; // return true only if endDate is greater than startDate
    }

    startInputBlur = (e, apply_prevent_default = true) => {

        let is_valid = true;
        if (apply_prevent_default) {
            e.stopPropagation();
            e.preventDefault();
        }

        let d = e.target.value;
        let suggested_date = autoDatePicker(d, this.state.startDate, null);  // pickup correct date
        let m = moment(suggested_date);
        is_valid = m.isValid();

        if (d && !is_valid) {
            this.display_error_by_type('', this.ERROR_MSG_INVALID_INPUT);
        }

        if (is_valid && // checking if the formated date is valid
            (this.is_start_date_valid_in_date_range(moment(m.format("MM/DD/YYYY")), this.state.endDate)) // checking if start and end dates are in range
        ) {
            this.updateStartDateChange(m);
        }
        else {
            if (!this.props.disableClearOnValidation) {
                this.setState({
                    startDate: null,
                    clearStartDate: true
                }, () => {
                    this.setState({
                        clearStartDate: false
                    });
                });
                this.props.updateDatesChange(null, this.state.endDate);
            }
            else {
                this.updateStartDateChange(m);
            }
        }

    }

    updateStartDateChange = (momentDate) => {
        if (!momentDate.isValid()) return;

        let newDate = null;
        var s = momentDate.format("MM/DD/YYYY");
        newDate = moment(s).startOf('day');

        this.setState({
            startDate: newDate
        });

        this.props.updateDatesChange(newDate, this.state.endDate);
    }

    componentDidUpdate(previousProps, previousState) {
        if (this.props.startDate != previousProps.startDate
            || this.props.endDate != previousProps.endDate) {
                this.setState({
                    endDate: this.props.endDate,
                    startDate: this.props.startDate,
                });
        }
      
        if (this.props.is_disabled != previousProps.is_disabled) {
                this.setState({
                    is_disabled: this.props.is_disabled
                });
        }
    }

    endInputKeyDown = (e) => {

        if (this.props.errorDisplayType && this.props.errorDisplayType == enum_date_range_error_display_type.UNDERCOMPONENT) {
            this.setState({
                errorMessage: ''
            });
            if (this.props.updateHasError)
                this.props.updateHasError(false);
        }

        if (e.shiftKey && e.keyCode == '9') {
            e.stopPropagation();
            e.preventDefault();
            e.stopImmediatePropagation();
            let ele: any = document.getElementById(this.start_date_id);
            ele.select();
        }

        if (!this.is_valid_char_entered(e)) {
            e.preventDefault();
            return;
        }

        var key = e.key;
        if (!e.shiftKey && key === 'Tab') {           
            this.setState({
                focusedInput: null
            });
            this.set_active_border(null);
        }

        if (key === 'Enter') {
            this.endInputBlur(e, false);
        }
    }

    endInputBlur = (e, apply_prevent_default = true) => {
        let is_valid = true;

        if (apply_prevent_default) {
            e.stopPropagation();
            e.preventDefault();
        }

        let d = e.target.value;
        let suggested_date = autoDatePicker(d, this.state.endDate, null);  // pickup correct date
        let m = moment(suggested_date);
        is_valid = m.isValid()

        if (d && !is_valid) {
            this.display_error_by_type('', this.ERROR_MSG_INVALID_INPUT);
        }

        if (is_valid = m.isValid() &&  // checking if the formated date is valid
            (this.is_end_date_valid_in_date_range(this.state.startDate, moment(m.format("MM/DD/YYYY"))))) // checking if start and end dates are in range
        {
            this.updateEndDateChange(m);
        }
        else {
            if (!this.props.disableClearOnValidation) {
                this.setState({
                    endDate: null,
                    clearEndDate: true
                }, () => {
                    this.setState({
                        clearEndDate: false
                    });
                });
                this.props.updateDatesChange(this.state.startDate, null);
            }
            else {
                this.updateEndDateChange(m)
            }
        }
    }

    updateEndDateChange = (momentDate) => {
        if (!momentDate.isValid()) return;

        let newDate = null;
        var s = momentDate.format("MM/DD/YYYY");
        newDate = moment(s).endOf('day');
        this.setState({
            endDate: newDate
        });
        this.props.updateDatesChange(this.state.startDate, newDate);
    }

    is_valid_date = (date) => {
        var date_regex = /((^(10|12|0?[13578])([/])(3[01]|[12][0-9]|0?[1-9])([/])((1[8-9]\d{2})|([2-9]\d{3}))$)|(^(11|0?[469])([/])(30|[12][0-9]|0?[1-9])([/])((1[8-9]\d{2})|([2-9]\d{3}))$)|(^(0?2)([/])(2[0-8]|1[0-9]|0?[1-9])([/])((1[8-9]\d{2})|([2-9]\d{3}))$)|(^(0?2)([/])(29)([/])([2468][048]00)$)|(^(0?2)([/])(29)([/])([3579][26]00)$)|(^(0?2)([/])(29)([/])(1[89][0][48])$)|(^(0?2)([/])(29)([/])([2-9][0-9][0][48])$)|(^(0?2)([/])(29)([/])(1[89][2468][048])$)|(^(0?2)([/])(29)([/])([2-9][0-9][2468][048])$)|(^(0?2)([/])(29)([/])(1[89][13579][26])$)|(^(0?2)([/])(29)([/])([2-9][0-9][13579][26])$))/;
        return date_regex.test(date);
    }

    handleFocusChange = (focusedInput) => {
        this.apply_divider();
        this.setState({
            focusedInput: focusedInput
        });
        this.set_active_border(focusedInput);
    }

    set_active_border = (focusedInput) => {
        let dateInput = document.getElementById(this.start_date_id)
        if (dateInput && dateInput.parentElement && dateInput.parentElement.parentElement) {
            if (focusedInput && !dateInput.parentElement.parentElement.classList.contains("DateRangePickerfocus")) {
                dateInput.parentElement.parentElement.classList.toggle('DateRangePickerfocus');
            } else if (!focusedInput && dateInput.parentElement.parentElement.classList.contains("DateRangePickerfocus")) {
                dateInput.parentElement.parentElement.classList.toggle('DateRangePickerfocus');
            }
        }
    }

    componentWillUnmount = () => {
        clearTimeout(this.timeout_border);
    }

    apply_divider = () => {
        clearTimeout(this.timeout_border);

        var timoutPeroid = 0;
        if(! document) return; // no need to perform furthur operations if document is not present.
        
        let parentContainer = document.querySelector(".DayPicker_transitionContainer__horizontal")
        if (!parentContainer) {
            timoutPeroid = 100;
        }

        this.timeout_border = setTimeout(()=> {

            
            let tables = document.querySelectorAll(".CalendarMonth_table");
            let parent = document.querySelector(".DayPicker_transitionContainer__horizontal");

            if(!parent) {
                this.apply_divider();
            }
            let left_table : any = tables[1];
            let right_table : any = tables[2];
            
            if(right_table && left_table && right_table.offsetHeight && left_table.offsetHeight ) {
                if(right_table.offsetHeight > left_table.offsetHeight) {
                    if(!right_table.classList.contains("left_border")) {
                        right_table.classList.toggle("left_border");
                    }
                    if(left_table.classList.contains("right_border")) {
                        left_table.classList.toggle("right_border");
                    }
                } else {
                    if(!left_table.classList.contains("right_border")) {
                        left_table.classList.toggle("right_border");
                    }
                    if(right_table.classList.contains("left_border")) {
                        right_table.classList.toggle("left_border");
                    }
                }
            }

            if(parent) {
                if(right_table.offsetHeight == left_table.offsetHeight
                    && (right_table.offsetHeight >= 158 || right_table.offsetHeight <= 160)) {                   
                        if(parent.classList.contains("calendar_container_big")) {
                            parent.classList.toggle("calendar_container_big");
                        }
                        if(!parent.classList.contains("calendar_container_small")) {
                            parent.classList.toggle("calendar_container_small");
                        }
                } else {
                    if(parent.classList.contains("calendar_container_small")) {
                        parent.classList.toggle("calendar_container_small");
                    }
                    if(!parent.classList.contains("calendar_container_big")) {
                        parent.classList.toggle("calendar_container_big");
                    }               
                }    
                }

        }, timoutPeroid);
   
    }
    renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
        return (
            <>
                <p>{month.format('MMMM')} {month.year()}</p>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: -10 }}>
                    <div className="" style={{ marginRight: 8,  minHeight: "31.2px" }}>
                        <select className="dropdown-options-wrap" value={month.month()} onChange={(e) => {
                                onMonthSelect(month, e.target.value)
                                this.apply_divider();
                        }
                        }>
                            {moment.months().map((label, index) => (
                                <option value={index} key={index}>
                                    {label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <select className="dropdown-options-wrap" value={month.year()} onChange={(e) => {
                            onYearSelect(month, e.target.value);
                            this.apply_divider();
                        }}>
                            {Array.from(Array(200)).map((_, index) => (
                                <option value={this.state.startYear + index} key={this.state.startYear + index}>
                                    {this.state.startYear + index}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </>
        );
    }

    handleDatesChange = ({ startDate, endDate }) => {
        if(moment.isMoment(startDate)) {
            startDate = startDate.startOf('day');
        }
        if(moment.isMoment(endDate)) {
            endDate = endDate.endOf('day');
        }        
        this.setState({
            startDate: startDate,
            endDate: endDate
        });

        this.props.updateDatesChange(startDate, endDate);
    }

    get_error_message_div = () => {
        return (
            <div className='requiredText'>
                {
                    this.state.errorMessage
                }
            </div>
        );
    }

    render() {
        const { startDate, endDate, focusedInput, minDate, maxDate, clearStartDate, clearEndDate, minimumNights, is_disabled} = this.state;

        return (
            <div className={`custom_calender ${this.props.error || this.state.errorMessage? "req-alert" : ""}`}>
                <DateRangePicker
                    anchorDirection={this.props.anchorDirection}
                    disabled={is_disabled}
                    minimumNights={minimumNights}
                    clearStartDate={clearStartDate}
                    clearEndDate={clearEndDate}
                    transitionDuration={0}
                    startDate={startDate} // momentPropTypes.momentObj or null,
                    startDateId={this.start_date_id} //string.isRequired,
                    endDate={endDate} // momentPropTypes.momentObj or null,
                    endDateId={this.end_date_id} //string.isRequired,
                    onDatesChange={this.handleDatesChange} //func.isRequired,
                    focusedInput={focusedInput} //oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={this.handleFocusChange} //func.isRequired,
                    monthFormat="MMMM YYYY"
                    numberOfMonths={2}
                    enableOutsideDays={true}
                    renderMonthElement={this.renderMonthElement}
                    small={true}
                    customInputIcon={<i itemType="i" className="custom-datepicker icon icon calendar" tabIndex={-1}></i>}
                    inputIconPosition="after"
                    hideKeyboardShortcutsPanel={true}
                    daySize={25}
                    minDate={minDate}
                    maxDate={maxDate}
                    keepFocusOnInput={true}
                    onPrevMonthClick={(a, b) => {
                        this.apply_divider();
                    }}
                    onNextMonthClick={(a, b) => {
                        this.apply_divider();
                    }}
                    horizontalMonthPadding={10}
                    navPrev={<button type="button" className="react-datepicker__navigation calendar-icon-prev"><i  tabIndex={-1} aria-hidden="true" className="chevron left icon sidebar-caret"></i></button>}
                    navNext={<button type="button" className="react-datepicker__navigation calendar-icon-next"><i  tabIndex={-1} aria-hidden="true" className="chevron right icon sidebar-caret"></i></button>}
                    verticalHeight={440}
                    isOutsideRange={date => date.isBefore(minDate, 'day') || date.isAfter(maxDate, 'day')}
                />
                {this.state.errorMessage && this.get_error_message_div()}
            </div>
        );
    }
}

export default DateRangePickerComponent;