import * as React from 'react';
import { connect } from 'react-redux';
import { toastr as toaster } from 'react-redux-toastr';
import { Button, Checkbox, Dimmer, Form, Grid, Input, Loader, Modal } from 'semantic-ui-react';
import * as global_constants from '../../../global_constants';
import PromptNavigation from '../../../shared/component/prompt_navigation_component';
import Selection from '../../../shared/component/selection_component';
import * as session_storage from '../../../shared/session_storage_utility';
import { set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import * as constants_actions from '../action/constants_action';
import * as insurance_classes_actions from '../action/insurance_classes_action';
import * as constants from '../constants';
import * as local_storage from '../../../shared/local_storage_utility';
import { get_lauch_darkley_key_value as get_launch_darkly_key_value } from '../../../shared/utility';


class InsuranceClassesAddEditComponent extends React.Component<any, any> {
    _is_mounted = false;

    constructor(props) {
        super(props);
        // Define initial state value.
        this.state = {
            show_render: true,
            form_object: {},
            is_loading: false,
            is_submitted: false,
            required_error: {},
            required_filed: {},
            payment_codes: [],
            adjustment_codes: [],
            pend_reasons: [],
            insurance_type_codes: [],
            by_pass_prompt: false,
            is_save_button_disabled: false,
            pend_reason_disable: true,
            // company and user info for provider credentials permissions
            service_type: 0,
            user_type: 0,
            show_provider_unlink_warning: false
        };
    }

    // Define initial properties and its value.
    form_object: any = {
        id: 0,
        insurance_class_id: 0,
        insurance_class_code: '',
        insurance_class_description: '',
        payment_code: 0,
        adjustment_code: 0,
        insurance_type: 0,
        audit_required: false,
        auth_required: false,
        apply_specialty_modifiers: false,
        apply_tax: false,
        apply_excise_tax: false,
        enable_functional_reporting: false,
        enable_mips: false,
        adjust_secondary_balance: false,
        pend_reason: '', // signifies the alt record id in a dropdown
        provider_credentials_required: false,
        is_active: true
    };

    required_filed: any = {
        insurance_class_code: true,
        insurance_class_description: true,
        payment_code: true,
        adjustment_code: true,
        insurance_type: true
    };

    page_name: string = 'insurance_class';
    menu_permission_object: Array<any> = [{ update: true }];
    disable_fields = false;
    page_metadata: any;
    token: string = '';
    id: number = 0;
    insurance_class_id: number = 0;
    is_grid_visible = false;
    go_next: boolean = false;
    is_cancel: boolean = false;
    initial_form_object: any = {};
    search_criteria: any = {
        ins_class_code: '',
        ins_class_desc: '',
        include_inactive: false
    };

    // Fetching the page_name and state.
    UNSAFE_componentWillMount = () => {
        const { user_data } = this.props.user_login_details;
        this.token = user_data.data.token_details.access_token;
        this.insurance_class_id = this.props.match.params && this.props.match.params.id ? this.props.match.params.id : 0;
        this.id = this.insurance_class_id;
        //Verifying page_name is correct or not.
        this.initial_form_object = this.init_fields(this.form_object);
        this.page_metadata = constants.insurance_class;
    };

    componentDidMount = () => {
        this._is_mounted = true;
        document.body.classList.add('admin-framework');
        let elem = document.getElementById('search-criteria-container');
        let all_form_elem = elem.getElementsByTagName('input');
        all_form_elem[0].focus();
        this.get_api_data();
    };

    componentWillUnmount = () => {
        document.body.classList.remove('admin-framework');
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[2] !== this.page_name && session_storage.remove(`constant_${this.page_name}_criteria`);
        }
    };

    checkUpdatePermission = () => {
        this.menu_permission_object = this.props.shared_details.left_menu_data.data
            .filter((menu) => {
                return menu.name == 'Edit Insurance Classes';
            });

        if (this.menu_permission_object.length == 0 || !this.menu_permission_object[0].view) {
            this.disable_fields = true;
            this.setState({ is_save_button_disabled: true })
        }
    }

    init_fields = (value) => {
        const object = { ...value };
        object.insurance_class_code = value.insurance_class_code || '';
        object.insurance_class_description = value.insurance_class_description || '';
        object.payment_code = value.payment_code || 0;
        object.adjustment_code = value.adjustment_code || 0;
        object.insurance_type = value.insurance_type || 0;
        object.audit_required = value.audit_required || false;
        object.auth_required = value.auth_required || false;
        object.apply_specialty_modifiers = value.apply_specialty_modifiers || false;
        object.apply_tax = value.apply_tax || false;
        object.apply_excise_tax = value.apply_excise_tax || false;
        object.enable_functional_reporting = value.enable_functional_reporting || false;
        object.enable_mips = value.enable_mips || false;
        object.adjust_secondary_balance = value.adjust_secondary_balance || false;
        object.is_active = value.is_active || false;
        object.pend_reason = value.pend_reason || '';
        object.provider_credentials_required = value.provider_credentials_required || false;
        return object;
    }

    /*Dropdown API call*/
    get_api_data = async () => {
        if (this.id) {
            this.checkUpdatePermission();
            this.get_data(this.id);
        }
        const dropdown_data = {};
        dropdown_data['payment_codes'] = await this.get_payment_codes();
        dropdown_data['adjustment_codes'] = await this.get_adjustment_codes();
        dropdown_data['insurance_type_codes'] = await this.get_insurance_type_codes();
        dropdown_data['pend_reasons'] = await this.get_pend_reasons();
        this.setState(dropdown_data);
        if (this.props.IsProviderCredentials) {
            this.get_member_info();
        }
    }

    // Get the data on the basis of ID.
    get_data = id => {
        let url = this.page_metadata.api.get.url;
        url = url.replace('id_value', id);
        this.setState({
            is_loading: true
        });
        constants_actions.get_data(this.token, url).then(
            response => {
                if (response.data && response.data.data) {
                    this.form_object = this.init_fields(response.data.data);
                    this.initial_form_object = { ...this.form_object };
                    this.setState({
                        form_object: this.form_object,
                        is_loading: false
                    });
                }

                if (this.props.IsProviderCredentials) {
                    if (this.form_object.pend_reason != '0' && this.form_object.pend_reason != '') {
                        this.setState({ pend_reason_disable: false });
                    }
                    else {
                        this.form_object.pend_reason = '';
                    }
                }
                
            },
            error => {
                this.setState({
                    is_loading: false
                });
            }
        );
    };

    // get member information
    get_member_info = () => {
        let url = this.page_metadata.api.memberget.url;
        const auth_data = local_storage.get("auth_data");
        const company_id = auth_data.company_id;
        url = url.replace('id_value', company_id);
        constants_actions.get_data(this.token, url).then(
            response => {
                if (response.data && response.data.data) {
                    const { user_data } = this.props.user_login_details;
                    this.setState({ user_type: user_data.data.user_type_id });
                    this.setState({ service_type: response.data.data.service_type_id });
                    this.check_Provider_credentials_permissions();
                }
                    
            },
            error => {
                // if there is a problem getting memebr information disable ability to edit/add form
                const toastr_options = this.show_html_content_toaster(error.message);
                toaster.error("", toastr_options);
                this.setState({ is_save_button_disabled: true });
            }
        );
    }

    get_payment_codes = () => {
        const url = `${global_constants.end_points.payment_code_url.get_payment_codes}`;
        return constants_actions.get_data(this.token, url).then(
            response => {
                if (response.data && response.data.data) {
                    return response.data.data.map((val, index) => { return { key: index, value: val.payment_code_id, text: val.payment_desc, data: val } });
                } else {
                    return [];
                }
            },
            error => {
                return [];
            }
        );
    }

    get_adjustment_codes = () => {
        const url = `${global_constants.end_points.adjustment_code_url.get_adjustment_codes}`;
        return constants_actions.get_data(this.token, url).then(
            response => {
                if (response.data && response.data.data) {
                    return response.data.data.map((val, index) => { return { key: index, value: val.adjustment_code_id, text: val.adjustment_desc, data: val } })
                } else {
                    return [];
                }
            },
            error => {
                return [];
            }
        );
    }

    get_pend_reasons = () => {
        const url = `${global_constants.end_points.insurance.insurance_class_prov_cred}`;
        return constants_actions.get_data(this.token, url).then(
            response => {
                if (response.data && response.data.data) {
                    return response.data.data.map((val, index) => { return { key: index, value: val.delayFlag, text: val.delayDescription, data: val } })
                } else {
                    return [];
                }
            },
            error => {
                return [];
            }
        );
    }

    get_insurance_type_codes = () => {
        const url = `${global_constants.end_points.insurance.insurance_type_codes}`;
        return constants_actions.get_data(this.token, url).then(
            response => {
                if (response.data && response.data.data) {
                    return response.data.data.map((val, index) => { return { key: index, value: val.ins_type_code_id, text: val.description, data: val } })
                } else {
                    return [];
                }
            },
            error => {
                return [];
            }
        );
    }

    get_insurance_class_has_active_providers = async () => {
        await insurance_classes_actions.get_insurance_class_has_active_providers(this.token, this.insurance_class_id)
            .then(
                response => {
                    this.setState({ show_provider_unlink_warning: response.data.data });
                },
                error => {
                    //  fail safely by showing the warning.
                    this.setState({ show_provider_unlink_warning: true });
                }
            );
    }

    //Input change handler.
    input_change_handler = e => {
        const { value, name } = e.target;
        this.form_object[name] = value;
        this.setState(prev_state => ({
            required_error: {
                ...prev_state.required_error,
                [name]: this.is_error(value, name)
            },
            form_object: this.form_object
        }));
    };

    //Input checkbox handler.
    checkbox_handler = e => {
        const { checked, name } = e.target;
        this.form_object[name] = checked;
        this.setState(prev_state => ({
            required_error: {
                ...prev_state.required_error,
                [name]: false
            },
            form_object: this.form_object
        }));
    };

    delay_reason_onchange = async e => {
        const { checked } = e.target;
        if (checked) {
            await this.get_insurance_class_has_active_providers().then(value => {
                this.setState({ pend_reason_disable: false });
                this.check_Provider_credentials_permissions();
            });
        }
        else {
            this.setState({ pend_reason_disable: true });
            this.form_object.pend_reason = '';
        }
    }

    //checks for permissions on provider credentialing fields
    check_Provider_credentials_permissions = () => {
        // user and company permissions check
        if (this.state.service_type === global_constants.CompanyServiceType.SoftwareOnly) {
            this.setState({ provider_credential_disable: false });
            this.setState({ is_save_button_disabled: false });
        }
        else {
            if (this.state.user_type === global_constants.enum_user_type_r3_id.Sys_Admin || this.state.user_type === global_constants.enum_user_type_r3_id.BMS) {
                this.setState({ provider_credential_disable: false });
                this.setState({ is_save_button_disabled: false });
            }
            else {
                this.setState({ pend_reason_disable: true });
                this.setState({ provider_credential_disable: true });
            }
        }
    }

    //Dropdown change handler
    on_change_dropdown_handler = e => {
        const { value, name } = e.target;
        if (name === 'pend_reason') {
            this.form_object[name] = value == ' ' ? null : value;
        }
        else {
            this.form_object[name] = value == ' ' ? null : Number(value);
        }
       

        this.setState(prev_state => ({
            required_filed: {
                ...this.required_filed
            },
            required_error: {
                ...prev_state.required_error,
                [name]: false
            },
            form_object: this.form_object
        }));
    };


    // validate each input box on change event
    is_error = (value, name) => {
        if (this.required_filed[name] && !value) {
            return true;
        }
        return false;
    };

    is_valid = () => {
        let is_error = false;
        let required_error = { ...this.state.required_error };
        for (let name in this.form_object) {
            if (name === 'insurance_class_code' || name === 'insurance_class_description') {
                this.form_object[name] = this.form_object[name].toString().trim();
            }
            if (this.required_filed[name] && !this.form_object[name]) {
                required_error[name] = true;
                is_error = true;
            }
            if (this.props.IsProviderCredentials) {
                if (name === 'provider_credentials_required' && this.form_object[name]) {
                    if (this.form_object.pend_reason === '') { // 0 is the default value of the dropdown signifiying that an option has not been chosen
                        required_error[name] = true;
                        is_error = true;
                    }
                }
            }
            
            
        }

        if (is_error) {
            this.setState({
                required_error,
            });
            toaster.error('', constants.mandatory_fields);
        }
        return is_error;
    }

    //Save the form object and redirect it to search
    save_handler = (e, from_pop_up) => {
        this.setState({ is_submitted: true });
        this.go_next = false;
        //Check validation
        if (this.is_valid()) {
            return;
        }
        //Start loader
        this.setState({
            is_loading: true,
            is_save_button_disabled: true
        });
        session_storage.set(`constant_${this.page_name}_criteria`, {
            search_criteria: this.search_criteria,
        });
        if (this.insurance_class_id) {
            //Update
            let url = this.page_metadata.api.update.url;
            url = url.replace('id_value', this.insurance_class_id);
            constants_actions.update_data(this.token, url, this.form_object).then(
                response => {
                    if (response.data && response.data.data) {
                        this.initial_form_object = { ...this.form_object };
                        this.go_next = true;
                        toaster.success('', this.page_metadata.validation_message.update);
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false
                        }, () => {
                            if (!from_pop_up && this.go_next) {
                                this.go_next = false;
                                this.props.history.goBack();
                            }
                        });
                    } else {
                        if (response.data.messages[0].message.indexOf('<br') > -1) {
                            this.show_html_content_toaster(response.data.messages[0].message);
                        } else {
                            toaster.error('', response.data.messages[0].message);
                        }
                        this.go_next = false;
                        if (this._is_mounted) {
                            this.setState({
                                is_loading: false,
                                is_save_button_disabled: false
                            });
                        }
                    }
                },
                error => {
                    if (this._is_mounted) {
                        this.go_next = false;
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false
                        });
                    }
                    if (error.response.data) toaster.error('', error.response.data.messages[0].message);
                }
            );
        } else {
            //Add
            let url = this.page_metadata.api.add.url;
            constants_actions.add_data(this.token, url, this.form_object).then(
                response => {
                    if (response.data && response.data.data) {
                        this.initial_form_object = { ...this.form_object };
                        this.go_next = true;
                        toaster.success('', this.page_metadata.validation_message.save);
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false
                        }, () => {
                            if (!from_pop_up && this.go_next) {
                                this.go_next = false;
                                this.props.history.goBack();
                            }
                        });
                    } else {
                        if (response.data.messages[0].message.indexOf('<br') > -1) {
                            this.show_html_content_toaster(response.data.messages[0].message);
                        } else {
                            toaster.error('', response.data.messages[0].message);
                        }
                        this.go_next = false;
                        if (this._is_mounted) {
                            this.setState({
                                is_loading: false,
                                is_save_button_disabled: false
                            });
                        }
                    }
                },
                error => {
                    if (this._is_mounted) {
                        this.go_next = false;
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false
                        });
                    }
                    if (error.response.data) toaster.error('', error.response.data.messages[0].message);
                }
            );
        }
    };

    //Function used to Run Report based on controllers values.
    cancel_handler = () => {
        this.initial_form_object = { ...this.form_object };
        this.setState({ by_pass_prompt: true }, () => this.props.history.goBack());
    };

    // Show multiple messages
    show_html_content_toaster = msg => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ),
            timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        };
    };

    is_data_unsaved = () => {
        if (JSON.stringify(this.initial_form_object) !== JSON.stringify(this.form_object)) {
            return true;
        }
        return false;
    }

    render_insurace_class_code = () => {
        if (this.insurance_class_id) {
            return (<label>{this.form_object.insurance_class_code ? this.form_object.insurance_class_code : ''}</label>)
        }

        const { form_object, required_error, is_submitted } = this.state;
        return (<Input
            autoComplete='off'
            name='insurance_class_code'
            id='id_insurance_class_code'
            onChange={e => this.input_change_handler(e)}
            value={this.form_object.insurance_class_code ? this.form_object.insurance_class_code : ''}
            type='text'
            maxLength={2}
            className={!form_object.insurance_class_code && this.required_filed.insurance_class_code && is_submitted && required_error.insurance_class_code ? 'req-border-inp' : ''}
        />)
    }
    //It renders report grid and its search controls.
    render() {
        const { form_object, required_error, is_submitted } = this.state;

        return (
            this.state.show_render && (
                <React.Fragment>
                    {!this.state.by_pass_prompt &&
                        (<PromptNavigation
                            is_data_changed={this.is_data_unsaved()}
                            save={e => this.save_handler(e, true)}
                            go_next_location={this.go_next}
                            history={this.props.history}
                        />)}
                    <Dimmer active={this.state.is_loading}>
                        <Loader size='massive'>Loading</Loader>
                    </Dimmer>
                    <div className={'admin-wrapper view-edit Locations ' + this.page_metadata.id} style={loaction_css.locations_id}>
                        <Grid className='headerGrid' style={loaction_css.headerGrid}>
                            <Grid.Column computer={16}>
                                <h3 className='ui header left aligned'>{this.page_metadata.add_header}</h3>
                            </Grid.Column>
                        </Grid>
                        <div className='wrapper' style={loaction_css.wrapper}>
                            <Form autoComplete='off' name='view_edit_form' className='common-forms' style={loaction_css.common_forms}>
                                <div id='admin-scrollable-area' style={loaction_css.scrollbar_area}>
                                    <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container' style={loaction_css.patient_search_form}>
                                        <Grid>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label>
                                                        Insurance Class Code{' '}
                                                        <span className={!form_object.insurance_class_code && this.required_filed.insurance_class_code && is_submitted && required_error.insurance_class_code ? 'req-alert' : 'req-alert_normal'}>
                                                            (required)
                                                         </span>
                                                    </label>
                                                    {this.render_insurace_class_code()}
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label>
                                                        Insurance Class Description{' '}
                                                        <span className={!form_object.insurance_class_description && this.required_filed.insurance_class_description && is_submitted && required_error.insurance_class_description ? 'req-alert' : 'req-alert_normal'}>
                                                            (required)
                                                         </span>
                                                    </label>
                                                    <Input
                                                        autoComplete='off'
                                                        name='insurance_class_description'
                                                        id='id_insurance_class_description'
                                                        onChange={e => this.input_change_handler(e)}
                                                        value={this.form_object.insurance_class_description ? this.form_object.insurance_class_description : ''}
                                                        type='text'
                                                        maxLength={50}
                                                        className={!form_object.insurance_class_description && this.required_filed.insurance_class_description && is_submitted && required_error.insurance_class_description ? 'req-border-inp' : ''}
                                                        disabled={this.disable_fields}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label>
                                                        Payment Code{' '}
                                                        {this.required_filed.payment_code && (
                                                            <span className={!form_object.payment_code && this.required_filed.payment_code && is_submitted && required_error.payment_code ? 'req-alert' : 'req-alert_normal'}>
                                                                (required)
                                                             </span>
                                                        )}
                                                    </label>
                                                    <Selection
                                                        id='id_payment_code'
                                                        name='payment_code'
                                                        placeHolder={'Select'}
                                                        hidden={true}
                                                        options={this.state.payment_codes}
                                                        onChange={(value, e) => {
                                                            this.on_change_dropdown_handler(e);
                                                        }}
                                                        defaultValue={this.form_object.payment_code || ''}
                                                        style={
                                                            !form_object.payment_code && this.required_filed.payment_code && is_submitted && required_error.payment_code
                                                                ? 'dropdown-options-wrap req-background-inp'
                                                                : 'dropdown-options-wrap'
                                                        }
                                                        disabled={this.disable_fields}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label>
                                                        Adjustment Code{' '}
                                                        {this.required_filed.adjustment_code && (
                                                            <span className={!form_object.adjustment_code && this.required_filed.adjustment_code && is_submitted && required_error.adjustment_code ? 'req-alert' : 'req-alert_normal'}>
                                                                (required)
                                                             </span>
                                                        )}
                                                    </label>
                                                    <Selection
                                                        id='provider_type_id'
                                                        name='adjustment_code'
                                                        placeHolder={'Select'}
                                                        hidden={true}
                                                        options={this.state.adjustment_codes}
                                                        onChange={(value, e) => {
                                                            this.on_change_dropdown_handler(e);
                                                        }}
                                                        defaultValue={this.form_object.adjustment_code || ''}
                                                        style={
                                                            !form_object.adjustment_code && this.required_filed.adjustment_code && is_submitted && required_error.adjustment_code
                                                                ? 'dropdown-options-wrap req-background-inp'
                                                                : 'dropdown-options-wrap'
                                                        }
                                                        disabled={this.disable_fields}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid>
                                        <Grid>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label>
                                                        Insurance Type{' '}
                                                        {this.required_filed.insurance_type && (
                                                            <span className={!form_object.insurance_type && this.required_filed.insurance_type && is_submitted && required_error.insurance_type ? 'req-alert' : 'req-alert_normal'}>
                                                                (required)
                                                             </span>
                                                        )}
                                                    </label>
                                                    <Selection
                                                        id='id_insurance_type'
                                                        name='insurance_type'
                                                        placeHolder={'Select'}
                                                        hidden={true}
                                                        options={this.state.insurance_type_codes}
                                                        onChange={(value, e) => {
                                                            this.on_change_dropdown_handler(e);
                                                        }}
                                                        defaultValue={this.form_object.insurance_type || ''}
                                                        style={
                                                            !form_object.insurance_type && this.required_filed.insurance_type && is_submitted && required_error.insurance_type
                                                                ? 'dropdown-options-wrap req-background-inp'
                                                                : 'dropdown-options-wrap'
                                                        }
                                                        disabled={this.disable_fields}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field >
                                                    <label><p className="ui-grid-label-empty">TEXT</p></label>
                                                    <Checkbox
                                                        name='audit_required'
                                                        id='id_audit_required'
                                                        onChange={e => this.checkbox_handler(e)}
                                                        checked={this.form_object.audit_required ? this.form_object.audit_required : false}
                                                        label={'Audit Required'}
                                                        disabled={this.disable_fields}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field >
                                                    <label><p className="ui-grid-label-empty">TEXT</p></label>
                                                    <Checkbox
                                                        name='auth_required'
                                                        id='id_auth_required'
                                                        onChange={e => this.checkbox_handler(e)}
                                                        checked={this.form_object.auth_required ? this.form_object.auth_required : false}
                                                        label={'Authorization Required'}
                                                        disabled={this.disable_fields}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field >
                                                    <label><p className="ui-grid-label-empty">TEXT</p></label>
                                                    <Checkbox
                                                        name='apply_specialty_modifiers'
                                                        id='id_apply_specialty_modifiers'
                                                        onChange={e => this.checkbox_handler(e)}
                                                        checked={this.form_object.apply_specialty_modifiers ? this.form_object.apply_specialty_modifiers : false}
                                                        label={'Apply Specialty Modifiers'}
                                                        disabled={this.disable_fields}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid>
                                        <Grid>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field >
                                                    <label><p className="ui-grid-label-empty">TEXT</p></label>
                                                    <Checkbox
                                                        name='apply_tax'
                                                        id='id_apply_tax'
                                                        onChange={e => this.checkbox_handler(e)}
                                                        checked={this.form_object.apply_tax ? this.form_object.apply_tax : false}
                                                        label={'Apply Tax'}
                                                        disabled={this.disable_fields}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field >
                                                    <label><p className="ui-grid-label-empty">TEXT</p></label>
                                                    <Checkbox
                                                        name='apply_excise_tax'
                                                        id='id_apply_excise_tax'
                                                        onChange={e => this.checkbox_handler(e)}
                                                        checked={this.form_object.apply_excise_tax ? this.form_object.apply_excise_tax : false}
                                                        label={'Apply Excise Tax'}
                                                        disabled={this.disable_fields}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field >
                                                    <label><p className="ui-grid-label-empty">TEXT</p></label>
                                                    <Checkbox
                                                        name='enable_functional_reporting'
                                                        id='id_enable_functional_reporting'
                                                        onChange={e => this.checkbox_handler(e)}
                                                        checked={this.form_object.enable_functional_reporting ? this.form_object.enable_functional_reporting : false}
                                                        label={'Enable Functional Reporting'}
                                                        disabled={this.disable_fields}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field >
                                                    <label><p className="ui-grid-label-empty">TEXT</p></label>
                                                    <Checkbox
                                                        name='enable_mips'
                                                        id='id_enable_mips'
                                                        onChange={e => this.checkbox_handler(e)}
                                                        checked={this.form_object.enable_mips ? this.form_object.enable_mips : false}
                                                        label={'Enable MIPS'}
                                                        disabled={this.disable_fields}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field >
                                                    <label><p className="ui-grid-label-empty">TEXT</p></label>
                                                    <Checkbox
                                                        name='adjust_secondary_balance'
                                                        id='id_adjust_secondary_balance'
                                                        onChange={e => this.checkbox_handler(e)}
                                                        checked={this.form_object.adjust_secondary_balance ? this.form_object.adjust_secondary_balance : false}
                                                        label={'Adjust Secondary Balance'}
                                                        disabled={this.disable_fields}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column tablet={8} computer={4} className={this.props.IsProviderCredentials ? 'ff-b35950-show' : 'ff-b35950-hide'}>
                                                <Form.Field >
                                                    <label><p className="ui-grid-label-empty">TEXT</p></label>
                                                    <Checkbox
                                                        name='provider_credentials_required'
                                                        id='id_provider_credentials_required'
                                                        onChange={e => { this.checkbox_handler(e); this.delay_reason_onchange(e); }}
                                                        checked={this.form_object.provider_credentials_required ? this.form_object.provider_credentials_required : false}
                                                        label={'Provider Credentials Required'}
                                                        disabled={this.state.provider_credential_disable}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column tablet={8} computer={4} className={this.props.IsProviderCredentials ? 'ff-b35950-show' : 'ff-b35950-hide'}>
                                                <Form.Field>
                                                    <label>
                                                        Delay Description {this.state.pend_reason_disable ? '' : '(required)'}
                                                    </label>
                                                    <Selection
                                                        id='id_pend_reason'
                                                        name='pend_reason'
                                                        placeHolder={'Select'}
                                                        hidden={true}
                                                        options={this.state.pend_reasons}
                                                        onChange={(value, e) => {
                                                            this.on_change_dropdown_handler(e);
                                                        }}
                                                        style={
                                                            form_object.provider_credentials_required && is_submitted && required_error.provider_credentials_required
                                                                ? 'dropdown-options-wrap req-background-inp'
                                                                : 'dropdown-options-wrap'
                                                        }
                                                        defaultValue={this.form_object.pend_reason || ''}
                                                        disabled={this.state.pend_reason_disable}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>

                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field >
                                                    <label><p className="ui-grid-label-empty">TEXT</p></label>
                                                    <Checkbox
                                                        name='is_active'
                                                        id='id_is_active'
                                                        onChange={e => this.checkbox_handler(e)}
                                                        checked={this.form_object.is_active ? this.form_object.is_active : false}
                                                        label={'Is Active'}
                                                        disabled={this.disable_fields}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid>
                                    </div>
                                </div>
                                <div className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area' id='applicationFooterSticky' style={loaction_css.footerArea}>
                                    <Button
                                        id='cancel_button_id'
                                        type='button' onClick={this.cancel_handler}
                                        basic>Cancel</Button>
                                    <Button
                                        id='save_button_id'
                                        type='button'
                                        disabled={this.state.is_save_button_disabled}
                                        onKeyDown={set_focus_to_app_header}
                                        onClick={e => this.save_handler(e, false)}
                                        primary style={loaction_css.save_button}>Save</Button>
                                </div>
                            </Form>
                        </div>
                    </div>

                    <Modal

                        onClose={() => this.setState({ show_provider_unlink_warning: false })}
                        centered={false}
                        className=" default-modal"
                        open={this.state.show_provider_unlink_warning}
                        closeIcon={true}
                        id="provider_unlinking_warning_modal_id"
                        closeOnDimmerClick={false}>
                        <Modal.Header>
                            <h3>Warning</h3>
                        </Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                <div className="common-forms response-message-poup" style={{ padding: "0" }}>
                                    <p>
                                        This Insurance Class is already defined for an individual provider(s). Pressing the Save button will cause that provider link to be removed, since this setting will override it.
                                    </p>
                                </div>
                                <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area padd-r-0">

                                    <Button id="btnUnlinkWarning" type="button" primary onClick={() => this.setState({ show_provider_unlink_warning: false})}>
                                        OK
                                    </Button>
                                </div>
                            </Modal.Description>
                        </Modal.Content>
                    </Modal>
                </React.Fragment>
            )
        );
    }
}
// Css Style
const loaction_css = {
    locations_id: {
        paddingBottom: 0
    },
    headerGrid: {
        marginTop: 0,
        marginBottom: 0,
        paddingRight: 15
    },
    wrapper: {
        borderTop: 0,
        marginBottom: 0
    },
    common_forms: {
        paddingTop: 0,
        height: '100%',
        paddingBottom: 0,
        display: 'flex',
        flexDirection: 'column'
    } as React.CSSProperties,

    patient_search_form: {
        paddingBottom: 0,
        flexBasis: 'auto',
        borderColor: '#cccccc',
        borderStyle: 'solid',
        borderWidth: '1px 0px 0px',
        margin: '0 0 15px 0px',
        padding: '25px 15px 0 0'
    },
    footerArea: {
        paddingLeft: 0,
        paddingBottom: 0,
        marginBottom: 12,
        paddingRight: 25,
        paddingTop: 20
    },
    scrollbar_area: {
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column'
    } as React.CSSProperties,
    save_button: {
        marginRight: 0
    }
};
//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
        shared_details: state.shared_details,
        IsProviderCredentials: get_launch_darkly_key_value(state.launch_darkly, constants.ProviderCredentials.projectFlag)
    };
};

export default connect(mapStateToProps)(InsuranceClassesAddEditComponent);