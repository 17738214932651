import * as React from 'react';
import { connect } from 'react-redux';
import { toastr as toaster } from 'react-redux-toastr';
import { Button, Dimmer, Form, Grid, Header, Icon, Loader, Radio, Table, TableBody } from 'semantic-ui-react';
import * as global_constants from '../../../global_constants';
import { messages, ReportEventAction, IPayloadForBillingAudit, ReportEntityTypeId, saveAuditPrintExport } from '../../../reports/report_constants';
import { log_error } from '../../../shared/action/shared_action';
import CheckboxComponent from '../../../shared/component/checkbox_component';
import MultiSelectDropDownComponent from '../../../shared/component/multi_select_dropdown_component';
import SelectionComponent from '../../../shared/component/selection_component';
import { set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import { get_all_error, print_html_data_to_pdf } from '../../../shared/utility';
import { get_company_list } from '../../../user/action/change_company_action';

import {
  get_constants,
  get_sub_constants,
  copy_selected_constants,
  copy_all_constants,
  copy_selected_sub_constants
} from '../action/constants_action';
import { manage_constant } from '../constants';
import { groupArr, manage_print_grid } from '../utility';
import { AlertConfirm } from '../../../shared/component/alert_confirm_component';
import { generateAuditDataEntryPayload } from '../../../reports/util/export_print_utility';

const ConstantTable = (props) => {
  const { type, name, column_def, row_data, select_all_row, select_row, all_selected, all_disabled } = props;
  return (
    <Grid.Column className={'grid_row_wrap'} computer={8}>
      <strong
        dangerouslySetInnerHTML={{ __html: `Constants [${name}]` }}
        className={'sub_constant_text'}
        id={`sub_constant_${type}`}
      />
      <div className={'grid_row_wrap_border'}>
        <div className='customtable'>
          <Table id={`sub_constant_${type}_table`} fixed>
            <Table.Header>
              <Table.Row key={'ip_access_table_header_row'}>
                {column_def.map((item, index) => {
                  if (item.field === 'checkbox') {
                    return (
                      <Table.HeaderCell
                        textAlign={'center'}
                        key={`header_row_${index}`}
                        style={{ width: `${item.width}px` }}
                      >
                        <CheckboxComponent
                          id={`check_all_${type}`}
                          name={`check_all_${type}`}
                          onChange={(e) => select_all_row(e)}
                          checked={all_selected}
                          disabled={all_disabled}
                        />
                      </Table.HeaderCell>
                    );
                  } else {
                    return (
                      <Table.HeaderCell
                        key={`header_row_${index}`}
                        style={{ minwidth: `${item.width}px` }}
                        content={item.headerName}
                      />
                    );
                  }
                })}
              </Table.Row>
            </Table.Header>
            <TableBody>
              {row_data.length > 0 ? (
                row_data.map((row, rowindex) => {
                  return (
                    <Table.Row key={`${type}_row}_${rowindex}`}>
                      {column_def.map((item, index) => {
                        if (item.field === 'checkbox') {
                          return (
                            <Table.Cell textAlign={'center'} key={`${type}_cell_${rowindex}_${index}`}>
                              <CheckboxComponent
                                id={`chb_${rowindex}_${row.key}_${row.value}`}
                                name={row.key}
                                value={row.value}
                                onChange={(e) => select_row(e)}
                                checked={row.is_current}
                                disabled={row.is_disabled}
                              />
                            </Table.Cell>
                          );
                        } else {
                          return <Table.Cell content={row[item.field]} key={`${type}_cell_${rowindex}_${index}`} />;
                        }
                      })}
                    </Table.Row>
                  );
                })
              ) : (
                <Table.Row key={`${type}_row}_no_record`}>
                                      <Table.Cell colSpan={column_def.length} content={messages.no_records_found} textAlign={'center'} />
                </Table.Row>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </Grid.Column>
  );
};

class ManageConstants extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    // Define initial state value.
    this.state = {
      is_submitted: false,
      save_disabled: false,
      print_disabled: true,
      show_grid: false,
      show_render: true,
      is_loading: false,
      shown: false,
      all_selected: false,
      all_disabled: false,
      save_button_text: '',
      info_text: '',
      destination_company_name: '',
      source_company_name: '',
      constant_options: [],
      form_data: {},
      error: {
        source_company_id: true,
        destination_company_id: true
      },
      source_table_data: [],
        destination_table_data: [],
        copy_prompt: {
            messages: [],
            alert: false
        }
    };
  }

  // Define initial properties and its value.
  is_mounted = false;
  page_metadata: any;
  token: string = '';
  options_data = {
    company: [],
    copy_types: [
      {
        text: 'All Constants',
        value: 'all'
      },
      {
        text: 'Selected Constants',
        value: 'selected'
      },
      {
        text: 'Selected Sub-Constants',
        value: 'sub'
      }
    ]
  };
  source_row_data: any = [];
  source_column_def: any = [];
  destination_row_data: any = [];
  destination_column_def: any = [];
  constant_options = [];
  selected_constant: any[] = [];
  selected_sub_constants: any[] = [];
  reportId: string = "0";
  form_data = {
    source_company_id: '',
    destination_company_id: '',
    copy_type: 'all',
    constant_id: 0
  };
  initial_form_data = {
    source_company_id: '',
    destination_company_id: '',
    copy_type: 'all',
    constant_id: 0
  };
  UNSAFE_componentWillMount() {
    this.is_mounted = true;
    this.token = this.props.user_login_details.user_data.data.token_details.access_token;
    this.page_metadata = manage_constant;
    this.destination_column_def = manage_constant.destination_column_def;
    this.source_column_def = manage_constant.source_column_def;
    this.get_dropdown_data();
  }

  componentDidMount() {
    document.body.classList.add('admin-framework');
    let elem = document.getElementById('search-criteria-container');
    let all_form_elem = elem.getElementsByTagName('select');
    all_form_elem[0].focus();
    this.handle_search_button_tab();
  }

  // get dropdown data
  get_dropdown_data = async () => {
    if (this.is_mounted) {
      this.setState({
        save_button_text: this.page_metadata['all'].save_button_text,
        info_text: this.page_metadata['all'].info_text,
        is_loading: true
      });
    }
    await get_company_list(this.token, '', '').then(
      (res) => {
        if (res.data.status) {
          if (res.data.data) {
            let bms_template = {};
            res.data.data = res.data.data.sort((obj1: { name: string }, obj2: { name: string }) => {
              let nameA = obj1.name ? obj1.name.toLowerCase() : '';
              let nameB = obj2.name ? obj2.name.toLowerCase() : '';
              if (nameA < nameB)
                //sort string ascending
                return -1;
              if (nameA > nameB) return 1;
              return 0;
            });
            res.data.data.map((item: { gpms_code: string; name: any; id: any }) => {
              if (item.gpms_code == 'BMS') {
                bms_template = {
                  text: item.gpms_code ? `${item.gpms_code} - ${item.name}` : `${item.name}`,
                  value: item.id
                };
              } else {
                this.options_data.company.push({
                  text: item.gpms_code ? `${item.gpms_code} - ${item.name}` : `${item.name}`,
                  value: item.id
                });
              }
            });
            if (JSON.stringify(bms_template) !== JSON.stringify({})) {
              this.options_data.company.unshift(bms_template);
            }
            if (this.is_mounted) {
              this.setState({
                is_loading: false
              });
            }
          }
        } else {
          if (res.data.messages) {
            log_error(res.data.messages);
            const toastr_options = this.show_html_content_toaster(get_all_error(res.data.messages));
            toaster.error('', toastr_options);
          }
          if (this.is_mounted) {
            this.setState({
              is_loading: false
            });
          }
        }
      },
      (error: { response: { data: { messages: { message: any }[] } } }) => {
        if (this.is_mounted) {
          this.setState({
            is_loading: false
          });
        }
        if (error.response && error.response.data && error.response.data.messages) {
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error('', toastr_options);
        }
      }
    );
  };

  // get constants
  get_constants = async (hide_warning = false) => {
    let params = {
      company_id: this.form_data.destination_company_id
    };
    const constant_id = this.form_data.constant_id;
    if (this.is_mounted) {
      this.setState({
        is_loading: true,
        all_disabled: false,
        all_selected: false
      });
    }
    await get_constants(this.token, params).then(
        (res: { data: { status: any; data: any[] } }) => {
        if (res.data.status) {
            let selected_constant = [];
            /* BILL-658 Hotfix - remove filter
               this.constant_options = (this.apply_ff_to_constants(res.data.data)).map((item) => {
            */
            this.constant_options = (res.data.data).map((item) => {
            item = {
              ...item,
              name: item.id === 1 ? 'Revenue Center Code' : item.name,
              disabled: item.checked,
              is_disabled: item.checked,
              is_checked: item.checked,
              value: item.id,
              on_hover_msg: null
            };
            if (item.checked) {
              selected_constant.push(item.id);
            }
            return item;
          });
          this.destination_column_def = this.destination_column_def.map((item) => {
            if (item.field === 'key') {
              item = {
                ...item,
                headerName:
                  constant_id !== 0 ? this.constant_options[constant_id - 1].name : this.constant_options[0].name
              };
            }
            return item;
          });
          this.source_column_def = this.source_column_def.map((item) => {
            if (item.field === 'key') {
              item = {
                ...item,
                headerName:
                  constant_id !== 0 ? this.constant_options[constant_id - 1].name : this.constant_options[0].name
              };
            }
            return item;
          });
          if (this.constant_options.some((item) => item.checked) && !hide_warning) {
            this.show_warning();
          }
          if (this.is_mounted) {
            this.selected_constant = [...selected_constant];
            this.form_data.constant_id =
              this.form_data.constant_id !== 0 ? this.form_data.constant_id : this.constant_options[0].id;
            this.setState({
              is_loading: false,
              constant_options: this.constant_options
            });
          }
        }
      },
      (error: { response: { data: { messages: { message: any }[] } } }) => {
        if (this.is_mounted) {
          this.setState({
            is_loading: false
          });
        }
        if (error.response && error.response.data && error.response.data.messages) {
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error('', toastr_options);
        }
      }
    );
    };

  //Apply feature flip to constants
    apply_ff_to_constants = (data) => {
        return data.filter((item) => item.id !== manage_constant.manage_constant_type.procedureCodeReplacement)
    };

  // copy all constants
  copy_all_constants = async () => {
    let params = {
      source_company_id: this.form_data.source_company_id,
      destination_company_id: this.form_data.destination_company_id
    };
    if (this.is_mounted) {
      this.setState({
        is_loading: true,
        is_submitted: true,
        save_disabled: true
      });
    }
    await copy_all_constants(this.token, params).then(
      (res) => {
          if (res.data.status) {
              if (res.data.messages == null || res.data.messages.length == 0) {
                  toaster.success('', this.page_metadata.all.sucess_msg);
              }
              else {
                  this.setState({
                      copy_prompt: {
                          messages: res.data.messages,
                          alert: true
                      }
                  });
              }
             
          this.setState(
            {
              is_loading: false,
              is_submitted: false,
              save_disabled: false
            },
            () => {
              this.get_constants(true);
            }
          );
        } else {
          if (this.is_mounted) {
            this.setState({
              is_loading: false,
              is_submitted: false,
              save_disabled: false
            });
          }
          toaster.error('', this.page_metadata.validation_message.error_msg);
        }
      },
      (error: { response: { data: { messages: { message: any }[] } } }) => {
        if (this.is_mounted) {
          this.setState({
            is_loading: false,
            is_submitted: false,
            save_disabled: false
          });
        }
        log_error(error);
        toaster.error('', this.page_metadata.validation_message.error_msg);
      }
    );
  };

  // get sub constants
  get_sub_constants = async () => {
    let param = {
      source_company_id: this.form_data.source_company_id,
      destination_company_id: this.form_data.destination_company_id,
      constant_id: this.form_data.constant_id
    };
    this.selected_sub_constants = [];
    if (this.is_mounted) {
      this.setState({
        is_loading: true,
        show_grid: false,
        all_disabled: false,
        all_selected: false,
        destination_table_data: [],
        source_table_data: []
      });
    }
      await get_sub_constants(this.token, param).then(
          (res) => {
        if (res.data.status) {
          this.source_row_data = [...res.data.data.view];
          this.destination_row_data = [...res.data.data.edit];
          this.destination_row_data = this.destination_row_data.map((item) => {
            item = {
              ...item,
                is_disabled: item.is_current || item.is_disable_copy,
                is_checked: item.is_current
              };
              this.add_item_in_selected_subconstants(item);
              return item;
          });
          if (this.is_mounted) {
            this.setState(
              {
                is_loading: false,
                show_grid: true,
                source_table_data: this.source_row_data.slice(0, 100),
                destination_table_data: this.destination_row_data.slice(0, 100),
                all_selected: this.selected_sub_constants.length === this.destination_row_data.length,
                all_disabled: this.selected_sub_constants.length === this.destination_row_data.length
              },
              () => {
                this.register_scroll();
              }
            );
          }
        } else {
          if (res.data.messages) {
            log_error(res.data.messages);
            const toastr_options = this.show_html_content_toaster(get_all_error(res.data.messages));
            toaster.error('', toastr_options);
          }
          if (this.is_mounted) {
            this.setState({
              is_loading: false
            });
          }
        }
      },
      (error: { response: { data: { messages: { message: any }[] } } }) => {
        if (this.is_mounted) {
          this.setState({
            is_loading: false
          });
        }
        log_error(error);
        if (error.response && error.response.data && error.response.data.messages) {
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error('', toastr_options);
        }
      }
    );
  };

  // add selected constants
  copy_selected_constants = async () => {
    let constant_ids = [];
    this.state.constant_options.map((item) => {
      if (!item.disabled && item.checked) {
        constant_ids.push(item.id);
      }
    });
    if (constant_ids.length <= 0) {
      toaster.error('', this.page_metadata.validation_message.selection_error);
      return;
    }
    let param = {
      source_company_id: this.form_data.source_company_id,
      destination_company_id: this.form_data.destination_company_id,
      constant_ids
    };
    if (this.is_mounted) {
      this.setState({
        is_loading: true,
        is_submitted: true,
        save_disabled: false
      });
    }
    await copy_selected_constants(this.token, param).then(
      (res) => {
        if (res.data.status) {
            if (res.data.messages == null || res.data.messages.length == 0) {
                toaster.success('', this.page_metadata.selected.sucess_msg);
            }
            else {
                this.setState({
                    copy_prompt: {
                        messages: res.data.messages,
                        alert: true
                    }
                });
            }
          this.setState(
            {
              is_loading: false,
              is_submitted: false,
              save_disabled: false
            },
            () => {
              this.get_constants(true);
            }
          );
        } else {
          if (this.is_mounted) {
            this.setState({
              is_loading: false,
              is_submitted: false,
              save_disabled: false
            });
          }
          toaster.error('', this.page_metadata.validation_message.error_msg);
        }
      },
      (error: { response: { data: { messages: { message: any }[] } } }) => {
        if (this.is_mounted) {
          this.setState({
            is_loading: false,
            is_submitted: false,
            save_disabled: false
          });
        }
        log_error(error);
        toaster.error('', this.page_metadata.validation_message.error_msg);
      }
    );
  };

  // get constants
  copy_selected_sub_constants = async () => {
      let keys = this.destination_row_data.filter((item) => item.is_disabled);

      if (!this.is_sub_constant_selected(keys)) {
          toaster.error('', this.page_metadata.validation_message.selection_error);
          return;
      }
      let param = {
          source_company_id: this.form_data.source_company_id,
          destination_company_id: this.form_data.destination_company_id,
          constant_id: this.form_data.constant_id,
          keys: this.selected_sub_constants
      };
      if (this.is_mounted) {
          this.setState({
              is_loading: true,
              is_submitted: true,
              save_disabled: false,
              all_disabled: false,
              all_selected: false
          });
      }
      await copy_selected_sub_constants(this.token, param).then(
          (res) => {
                if (res.data.status) {
                    toaster.success('', this.page_metadata.sub.sucess_msg);
                    this.setState(
                        {
                            is_loading: false,
                            is_submitted: false,
                            save_disabled: false,
                            show_grid: false
                        },
                        () => {
                            this.get_constants(true);
                            this.get_sub_constants();
                        }
                    );
                } else {
                    if (this.is_mounted) {
                        this.setState({
                            is_loading: false,
                            is_submitted: false,
                            save_disabled: false
                        });
                    }
                    toaster.error('', this.page_metadata.validation_message.error_msg);
                }
            },
          (error: { response: { data: { messages: { message: any }[] } } }) => {
                if (this.is_mounted) {
                    this.setState({
                        is_loading: false,
                        is_submitted: false,
                        save_disabled: false
                    });
                }
                log_error(error);
                toaster.error('', this.page_metadata.validation_message.error_msg);
            }
      );
  };

  //Check is there any sub constants selected
    is_sub_constant_selected = (disabled_sub_constants) => {
        if (this.selected_sub_constants.length === disabled_sub_constants.length
            || (this.form_data.constant_id == manage_constant.manage_constant_type.procedureCodeReplacement && this.selected_sub_constants.length == 0)) {
            return false;
        }
        else
            return true;
    }

  // dropdown change handler.
  dropdown_change_handler = (e) => {
    const { id, value } = e.target;
    let {
      save_button_text,
      info_text,
      destination_company_name,
      source_company_name,
      constant_options,
      is_submitted
    } = this.state;
    if (id === 'copy_type') {
      if (this.form_data.destination_company_id && this.form_data.source_company_id) {
        this.form_data[id] = value;
        save_button_text = this.page_metadata[value].save_button_text;
        info_text = this.page_metadata[value].info_text;
        constant_options = this.form_data[id] !== value ? this.constant_options : constant_options;
        this.selected_constant = this.formate_slected_list(constant_options);
        if (value === 'sub') {
          info_text = '';
          this.get_sub_constants();
        }
      } else {
        e.preventDefault();
        this.form_data[id] = 'all';
        e.target.value = this.form_data[id];
        save_button_text = this.page_metadata['all'].save_button_text;
        info_text = this.page_metadata['all'].info_text;
        is_submitted = true;
          toaster.error('', messages.mandatory_fields);
      }
    } else {
      this.form_data[id] = value;
      if (id === 'destination_company_id') {
        this.get_constants();
        this.form_data.source_company_id && this.form_data.copy_type === 'sub' && this.get_sub_constants();
        destination_company_name = this.get_dropdwon_text('company', value);
      } else {
        source_company_name = this.get_dropdwon_text('company', value);
        this.form_data.destination_company_id && this.form_data.copy_type === 'sub' && this.get_sub_constants();
      }
    }
    if (this.is_mounted) {
      this.setState((prev_state) => ({
        save_button_text,
        info_text,
        destination_company_name,
        source_company_name,
        constant_options,
        is_submitted,
        form_data: { ...this.form_data },
        show_grid: false,
        all_selected: false,
        all_disabled: false,
        print_disabled: !(this.form_data.source_company_id && this.form_data.destination_company_id),
        error: {
          ...prev_state.error,
          [id]: false
        }
      }));
    }
    };

  //add item in selected sub contant list
  add_item_in_selected_subconstants = (item) => {
        if (this.form_data.constant_id == manage_constant.manage_constant_type.procedureCodeReplacement) {
            if (!item.is_disabled && item.is_current)
                this.selected_sub_constants.push(item.key);
        }
        if (this.form_data.constant_id == manage_constant.manage_constant_type.enhancedPayerRule) {
            if (item.is_current)
                this.selected_sub_constants.push(item.key + '_' + item.value);
        }
        else if (item.is_current) {
            this.selected_sub_constants.push(item.key);
        }
    }

  // Show warning on selecte destination company
  show_warning() {
    const toastrConfirmOptions = {
      id: 'continue-copy-constants',
      okText: 'continue',
      disableCancel: true,
      closeOnShadowClick: true,
      onOk: () => {
        let elem = document.getElementById('search-criteria-container');
        let all_form_elem = elem.getElementsByTagName('select');
        all_form_elem[1].focus();
      }
    };
    toaster.confirm(this.page_metadata.validation_message.warning_msg, toastrConfirmOptions);
    let elem = document.querySelectorAll('.redux-toastr')[0];
    let all_form_elem = elem.getElementsByTagName('button');
    all_form_elem[0].focus();
    all_form_elem[0].addEventListener('keydown', (event) => {
      if (event.keyCode == 9) {
        event.preventDefault();
      }
    });
    }

  //Render copy alert message
  render_copy_alert_message = () => {
      let message_index = 0;
      return (
          <React.Fragment>
              <label>{manage_constant.copy_alert_settings.message}:</label><br />
              <label className="manage_constant_alert_title">&nbsp;&nbsp;{manage_constant.copy_alert_settings.constantName}:</label>
              {
                  this.state.copy_prompt.messages.map((item) => {
                      message_index++;
                      return (<span key={message_index} className="message_constant_alert_name">{item.message}</span>)
                  })
              }
          </React.Fragment>
      )
    }

  // Render constant option
  render_sub_constant_options = () => {
    return groupArr(this.constant_options, 4).map((data_object, index) => {
      return (
        <Grid.Column computer={16} key={'outer_row_' + index}>
          <Grid>
            {data_object.map(
              (
                data_row: { control_type: string; id: string | number; checked: boolean; name: {}; disabled: boolean },
                index: string
              ) => {
                return (
                  <Grid.Column
                    className={data_row.control_type === 'checkbox' ? 'form_column' : 'radio_button'}
                    key={'inner_row_' + index}
                    tablet={8}
                    verticalAlign='middle'
                    computer={4}
                  >
                    <Icon name='checkmark' color={data_row.checked ? 'green' : 'grey'} />
                    <Radio
                      key={index}
                      label={data_row.name}
                      name='radioGroup'
                      value={data_row.id}
                      checked={this.form_data.constant_id === data_row.id}
                      onChange={(e) => this.radio_handler(e, data_row.id)}
                    />
                  </Grid.Column>
                );
              }
            )}
          </Grid>
        </Grid.Column>
      );
    });
  };

  // Radio selection handler
  radio_handler = (e: any, id: any) => {
    let headerName: any;
    this.constant_options.filter((item) => {
      if (item.id == id) return (headerName = item.name);
    });
    this.destination_column_def = this.destination_column_def.map((item) => {
      if (item.field === 'key') {
        item = {
          ...item,
          headerName
        };
      }
      return item;
    });
    this.source_column_def = this.source_column_def.map((item) => {
      if (item.field === 'key') {
        item = {
          ...item,
          headerName
        };
      }
      return item;
    });
    this.form_data.constant_id = id;
    this.get_sub_constants();
    if (this.is_mounted) {
      this.setState({
        constant_id: id
      });
    }
  };

  // Save constant
  save_constant = (type: any) => {
    const { destination_company_id, source_company_id } = this.form_data;
    if (destination_company_id && source_company_id) {
      if (this.is_mounted) {
        this.setState({
          is_submitted: true
        });
      }
      if (destination_company_id === source_company_id) {
        toaster.error('', this.page_metadata.validation_message.same_company);
        return;
      }
    } else {
      if (this.is_mounted) {
        this.setState({
          is_submitted: true
        });
      }
        toaster.error('', messages.mandatory_fields);
      return;
    }
    switch (type) {
      case 'sub':
        this.copy_selected_sub_constants();
        break;
      case 'selected':
        this.copy_selected_constants();
        break;
      default:
        this.copy_all_constants();
        break;
    }
  };

  // Handle tab on search
  handle_search_button_tab() {
    setTimeout(() => {
      let searchButton = document.getElementById('search_button_id') as HTMLButtonElement;
      let printButton = document.getElementById('print_report_button') as HTMLButtonElement;
      let self = this;
      searchButton &&
        searchButton.addEventListener('keydown', (event) => {
          let keyCode = event.keyCode || event.which;
          // apply a check where grid is empty
          if (!event.shiftKey && keyCode == 9 && printButton && printButton.disabled) {
            set_focus_to_app_header(event);
          }
        });

      printButton &&
        !printButton.disabled &&
        printButton.addEventListener('keydown', (event) => {
          let keyCode = event.keyCode || event.which;
          // apply a check where grid is empty
          if (!event.shiftKey && keyCode == 9) {
            set_focus_to_app_header(event);
          }
        });
    }, 200);
  }

  // Show multiple messages
  show_html_content_toaster = (msg: any) => {
    return {
      component: () => (
        <div>
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        </div>
      ),
      timeOut: global_constants.toastr_options.toastr_time_out,
      preventDuplicates: true
    };
  };

  // reset page
  clear_constants() {
    this.constant_options = [];
    this.form_data = { ...this.initial_form_data };
    this.selected_constant = [];
    let elem = document.getElementById('search-criteria-container');
    let all_form_elem = elem.getElementsByTagName('select');
    all_form_elem[0].focus();
    if (this.is_mounted) {
      this.setState({
        is_submitted: false,
        all_selected: false,
        all_disabled: false,
        show_grid: false,
        is_loading: false,
        save_button_text: this.page_metadata['all'].save_button_text,
        info_text: this.page_metadata['all'].info_text,
        destination_company_name: '',
        source_company_name: '',
        form_data: { ...this.initial_form_data },
        error: {
          destination_company_id: true,
          source_company_id: true
        },
        constant_options: []
      });
    }
  }

  // Function calls on initialization of Print report data
  on_print_button = () => {
    if (this.is_mounted) {
      this.setState(
        {
          print_disabled: true
        },
        () => {
          let printContent = '';
          let searchCriteriaContainer = this.prepare_html_data();
          printContent += '<div class="manage-constants">';
          printContent += `<strong>Manage Constants</strong><br/><br/>`;
          printContent += searchCriteriaContainer;
          if (this.state.show_grid) {
            printContent += '<div style="display: flex;">';
            printContent += '<div style="width:49.5%; margin-right:0.5%;">';
            printContent += `<strong class='sub_constant_text'>Constants [${this.state.source_company_name}]</strong>`;
            printContent += manage_print_grid(this.source_column_def, this.source_row_data, false, false);
            printContent += '</div>';
            printContent += '<div style="width:49.5%; margin-left:0.5%;">';
            printContent += `<strong class='sub_constant_text'>Constants [${this.state.destination_company_name}]</strong>`;
            printContent += manage_print_grid(
              this.destination_column_def,
              this.destination_row_data,
              this.state.all_selected,
              this.state.all_disabled
            );
            printContent += '</div>';
            printContent += '</div>';
          }
          printContent += '</div>';
          print_html_data_to_pdf(printContent, 'print_report_button', 'manage constants');
          if (this.is_mounted) {
            this.setState({
              print_disabled: false
            });
          }
        }
      );
      }
    this.saveReportEventAction(ReportEventAction.Print);
  };

  // Function calls save billing audit on Export or Print
  private saveReportEventAction = (eventActionId: number) => {
    const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
    const { user_id, company_id } = this.props.user_login_details.user_data.data;

    const payload: IPayloadForBillingAudit = {
      reportId: this.reportId,
      contextTitle: `Manage Constants - ${ReportEventAction[eventActionId]}`,
      eventActionId: eventActionId,
      userId: Number(user_id),
      companyId: Number(company_id),
      entityTypeId: ReportEntityTypeId.A_COMPANY,
      data: { SourceCompanyName: this.state.source_company_name, DestinationCompanyName: this.state.destination_company_name }
    };

    const reqBody = generateAuditDataEntryPayload(payload);
    saveAuditPrintExport(reqBody, accessToken);
  };

  // Check Valid Html
  prepare_html_data = () => {
    let vaild_ele: any = '';
    vaild_ele = document.querySelector('#search-criteria-container');
    let searchContainerTemp: any = document.createElement('div');
    if (document.getElementById('searchContainerTemp')) {
      document.getElementById('searchContainerTemp').remove();
    }
    searchContainerTemp.setAttribute('id', 'searchContainerTemp');
    if (
      vaild_ele.querySelectorAll('.radio_button') &&
      vaild_ele.querySelectorAll('.radio_button').length > 0 &&
      vaild_ele.querySelectorAll('.radio_button .checked input') &&
      vaild_ele.querySelectorAll('.radio_button .checked input').length > 0
    ) {
      vaild_ele.querySelectorAll('.radio_button .checkbox input').forEach((element) => {
        element.removeAttribute('checked');
      });
      vaild_ele.querySelectorAll('.radio_button .checkbox.checked input')[0].setAttribute('checked', 'checked');
    }
    searchContainerTemp.innerHTML = vaild_ele.outerHTML;
    document.body.appendChild(searchContainerTemp);
    let source_company_id = searchContainerTemp.querySelector('#source_company_id');
    let destination_company_id = searchContainerTemp.querySelector('#destination_company_id');
    let copy_type = searchContainerTemp.querySelector('#copy_type');
    if (source_company_id && source_company_id.innerHTML) {
      source_company_id.innerHTML = `<option value='${this.form_data.source_company_id}' selected>${this.state.source_company_name}</option>`;
    }
    if (destination_company_id && destination_company_id.innerHTML) {
      destination_company_id.innerHTML = `<option value='${this.form_data.destination_company_id}' selected>${this.state.destination_company_name}</option>`;
    }
    if (copy_type && copy_type.innerHTML) {
      let copy_type_name = '';
      this.options_data.copy_types.map((item) => {
        if (item.value == this.form_data.copy_type) {
          copy_type_name = item.text;
        }
        return;
      });
      copy_type.innerHTML = `<option value='${this.form_data.copy_type}' selected>${copy_type_name}</option>`;
    }
    let searchContainer = document.querySelector('#searchContainerTemp');
    vaild_ele = searchContainer.innerHTML;
    searchContainerTemp.remove();
    return vaild_ele;
  };

  // get dropdown text
  get_dropdwon_text = (field: string | number, value: any) => {
    let option = this.options_data[field].filter((item: { value: any; text: any }) => {
      if (item.value == value) {
        return item.text;
      }
    });
    return option && option[0] ? option[0].text : '';
  };

  // select constant change
  select_constant_change = (id, values) => {
    let constant_options = this.state.constant_options.map((item) => {
      if (values.indexOf(item.id) > -1) {
        item = {
          ...item,
          checked: true,
          is_checked: true
        };
      } else {
        item = {
          ...item,
          checked: false,
          is_checked: false
        };
      }
      return item;
    });
    if (this.is_mounted) {
      this.selected_constant = [...values];
      this.setState({
        constant_options
      });
    }
  };

  // fomate selcte list
  formate_slected_list = (constant_options) => {
    let selected_constant = [];
    constant_options.map((item) => {
      if (item.checked) {
        selected_constant.push(item.id);
      }
      return item;
    });
    return selected_constant;
  };

  register_scroll = () => {
    let scrollArea = document.getElementById(`scrollable-content-area`);
    scrollArea.addEventListener('scroll', (event) => {
      this.load_more_data(event);
    });
    scrollArea.addEventListener('keydown', (event) => {
      this.load_more_data(event);
    });
    scrollArea.addEventListener('mousewheel', (event) => {
      this.load_more_data(event);
    });
  };

  load_more_data = (event) => {
    const container = event.currentTarget;
    if (container.scrollTop + container.offsetHeight >= container.scrollHeight) {
      let destination_data = [...this.state.destination_table_data];
      let source_data = [...this.state.source_table_data];
      let update_state = false;
      if (this.destination_row_data && this.destination_row_data.length !== this.state.destination_table_data.length) {
        let updated_data = this.destination_row_data.slice(
          this.state.destination_table_data.length,
          this.state.destination_table_data.length + 100
        );
        destination_data = destination_data.concat(updated_data);
        update_state = true;
      }
      if (this.source_row_data && this.source_row_data.length !== this.state.source_table_data.length) {
        let updated_data = this.source_row_data.slice(
          this.state.source_table_data.length,
          this.state.source_table_data.length + 100
        );
        source_data = source_data.concat(updated_data);
        update_state = true;
      }
      if (this.is_mounted && update_state) {
        this.setState({
          destination_table_data: destination_data,
          source_table_data: source_data
        });
      }
    }
  };

  // Select All row in Table
  select_all_row = (e) => {
    let table_data = this.state.destination_table_data.map((item) => {
      if (!item.is_disabled) {
        item = {
          ...item,
          is_current: e.target.checked
        };
      }
      return item;
    });
    this.selected_sub_constants = [];
    this.destination_row_data = this.destination_row_data.map((item) => {
      if (!item.is_disabled) {
        item = {
          ...item,
          is_current: e.target.checked
        };
        }
        this.add_item_in_selected_subconstants(item);
      return item;
    });

    this.setState({
      destination_table_data: table_data,
      all_selected: e.target.checked
    });
  };

  // Select Row in table
  select_row = (e) => {
    this.selected_sub_constants = [];
    let table_data = this.state.destination_table_data.map((item) => {
        let id = e.target.id.split('_')[2];
        let value = e.target.value;
        if (!item.is_disabled && String(item.key) == id) {
            if (item.table_Constant == manage_constant.manage_constant_table_name.enhanced_payer_rule) {
                if (String(item.value) == value) {
                    item = {
                        ...item,
                        is_current: e.target.checked
                    }
                }
            } else {
                item = {
                    ...item,
                    is_current: e.target.checked
            }
        };
      }
      return item;
    });
      this.destination_row_data = this.destination_row_data.map((item) => {
          let id = e.target.id.split('_')[2];
          let value = e.target.value;
          if (!item.is_disabled && String(item.key) == id) {
              if (item.table_Constant == manage_constant.manage_constant_table_name.enhanced_payer_rule) {
                  if (String(item.value) == value) {
                      item = {
                          ...item,
                          is_current: e.target.checked
                      }
                  }
              } else {
                  item = {
                      ...item,
                      is_current: e.target.checked
                  }
              };
          }
          this.add_item_in_selected_subconstants(item);
          return item;
      });
    let all_selected = this.selected_sub_constants.length === this.destination_row_data.length;
    this.setState({
      destination_table_data: table_data,
      all_selected
    });
  };

    //#region alert box region
    alert_box_helper = () => {
        this.setState({
            copy_prompt: {
                messages: [],
                alert: false
            }
        });
    }

    handle_confirm = (e) => {
        e.preventDefault();
        this.setState({
            copy_prompt: {
                messages: [],
                alert: false
            }
        });
    }
    //#endregion
  render() {
    return (
      <React.Fragment>
        <div className={'admin-wrapper manage-component'}>
          <Dimmer active={this.state.is_loading}>
            <Loader size='massive'>Loading</Loader>
                </Dimmer>

                <AlertConfirm
                    open={this.state.copy_prompt.alert}
                    close={() => this.alert_box_helper()}
                    cancel={() => this.alert_box_helper()}
                    confirm={this.handle_confirm}
                    message={this.render_copy_alert_message()}
                    title={manage_constant.copy_alert_settings.title}
                    labelYes={manage_constant.copy_alert_settings.yesButtonLabel}
                    hide_cancel_button={true}
                />

          <Grid className='headerGrid'>
            <Grid.Column computer={16}>
              <h3 className='ui header left aligned' dangerouslySetInnerHTML={{ __html: 'Manage Constants' }} />
            </Grid.Column>
          </Grid>
          <div
            className={'main_wrap'}
            id={'scrollable-content-area'}
            style={this.form_data.copy_type !== 'sub' ? { flex: 1 } : {}}
          >
            <div id='admin-scrollable-area' className='wrapper'>
              <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container'>
                <Form autoComplete='off'>
                  <Grid columns={3}>
                    <Grid.Row id={'dropdown_row'}>
                      <Grid.Column>
                        <Form.Field>
                          <label>
                            Copy Constants From
                            <span
                              className={
                                this.state.is_submitted && this.state.error.source_company_id
                                  ? 'req-alert'
                                  : 'req-alert_normal'
                              }
                              dangerouslySetInnerHTML={{ __html: ' (required)' }}
                            />
                          </label>
                          <SelectionComponent
                            id={'source_company_id'}
                            placeHolder={'Select'}
                            hidden={true}
                            options={this.options_data.company}
                            onChange={(value: any, e: any) => {
                              this.dropdown_change_handler(e);
                            }}
                            defaultValue={this.form_data.source_company_id}
                            style={
                              this.state.is_submitted && this.state.error.source_company_id
                                ? 'dropdown-options-wrap req-background-inp'
                                : 'dropdown-options-wrap '
                            }
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column>
                        <Form.Field>
                          <label>
                            Copy Constants To
                            <span
                              className={
                                this.state.is_submitted && this.state.error.destination_company_id
                                  ? 'req-alert'
                                  : 'req-alert_normal'
                              }
                              dangerouslySetInnerHTML={{ __html: ' (required)' }}
                            />
                          </label>
                          <SelectionComponent
                            id={'destination_company_id'}
                            placeHolder={'Select'}
                            hidden={true}
                            options={this.options_data.company}
                            onChange={(value: any, e: any) => {
                              this.dropdown_change_handler(e);
                            }}
                            defaultValue={this.form_data.destination_company_id}
                            style={
                              this.state.is_submitted && this.state.error.destination_company_id
                                ? 'dropdown-options-wrap req-background-inp'
                                : 'dropdown-options-wrap '
                            }
                            wrapperStyle={''}
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column>
                        <Form.Field>
                          <label>Copy</label>
                          <SelectionComponent
                            id={'copy_type'}
                            placeHolder={'Select'}
                            hidden={true}
                            options={this.options_data.copy_types}
                            onChange={(value: any, e: any) => {
                              this.dropdown_change_handler(e);
                            }}
                            defaultValue={this.form_data.copy_type}
                            style={'dropdown-options-wrap'}
                            wrapperStyle={''}
                          />
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  {this.state.info_text && (
                    <Grid id={'info_text'}>
                      <Header as='h6'>{this.state.info_text}</Header>
                    </Grid>
                  )}
                  {this.form_data.copy_type === 'selected' && (
                    <Grid columns={3} id={'sub_constant_checkbox'}>
                      <Grid.Row>
                        <Grid.Column>
                          <Form.Field>
                            <label>Copy Selected Constants</label>
                            <MultiSelectDropDownComponent
                              class_name='manage-contant-multi-select'
                              id={'contant-multi-select'}
                              key={'contant-multi-select'}
                              key_id={'contant-multi-select'}
                              data={this.state.constant_options}
                              selected_list={this.selected_constant}
                              onChangeSelected={this.select_constant_change}
                              forceOnChangeSelected={true}
                              has_validation={false}
                              show_filter={true}
                              reset_disable={true}
                            />
                          </Form.Field>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  )}
                  {this.form_data.copy_type === 'sub' && (
                    <Grid id={'sub_constant_radio'}>{this.render_sub_constant_options()}</Grid>
                  )}
                </Form>
              </div>
              {this.form_data.copy_type === 'sub' && this.state.show_grid && (
                <Grid id={'manage_constant_grid'}>
                  <ConstantTable
                    type={'source'}
                    name={this.state.source_company_name}
                    column_def={this.source_column_def}
                    row_data={this.state.source_table_data}
                  />
                  <ConstantTable
                    type={'destination'}
                    name={this.state.destination_company_name}
                    column_def={this.destination_column_def}
                    row_data={this.state.destination_table_data}
                    select_row={this.select_row}
                    select_all_row={this.select_all_row}
                    all_selected={this.state.all_selected}
                    all_disabled={this.state.all_disabled}
                  />
                </Grid>
              )}
              <Grid id={'action_section'}>
                <Grid.Column computer={16} textAlign='right'>
                  <Button
                    id='clear_button_id'
                    type='button'
                    onClick={(e) => this.clear_constants()}
                    basic
                    content='Clear'
                  />
                  <Button
                    id='search_button_id'
                    type='submit'
                    primary
                    disabled={this.state.save_disabled}
                    content={this.state.save_button_text}
                    onClick={(e) => this.save_constant(this.form_data.copy_type)}
                    style={{ marginRight: 0 }}
                  />
                </Grid.Column>
              </Grid>
            </div>
          </div>
          <div
            className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
            id='applicationFooterSticky'
          >
            <Grid.Column computer={16} textAlign='right'>
              <Button
                onKeyDown={set_focus_to_app_header}
                id='print_report_button'
                type='submit'
                onClick={this.on_print_button}
                disabled={this.state.print_disabled}
                primary
                content={'Print'}
                style={this.form_data.copy_type !== 'sub' ? { marginRight: 0 } : { marginRight: 10 }}
              />
            </Grid.Column>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// Get user and login details from store.
const mapStateToProps = (state) => {
  return {
      user_login_details: state.user_login_details
  };
};

export default connect(mapStateToProps)(ManageConstants);
