import * as global_constants from '../../global_constants';
import * as shared_constants from '../shared_constants';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request';

export const log_error = (data) => {
    // API call to log error in database
    const url = `${global_constants.base_end_points}${global_constants.end_points.error_logging_url}`;
    let error_url;
    let error_stack;

    if (data.config) {
        error_url = data.config.url;
    } else {
        error_url = window.location.href;
    }

    if (data.stack) {
        error_stack = data.stack;
    } else {
        error_stack = data
    }

    var error_object = {
        url: error_url,
        error_message: JSON.stringify(error_stack || {}),
        error_code:4258
    }

    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': error_object
    })
    
    request.make(request_config).then(
        response => { },
        error => { }
    );
}

export const left_menu_permission = (user_id, company_id, token) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.user_urls.permissions}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            user_id: user_id,
            company_id: company_id
        }
    })
    return dispatch => request.make(request_config).then(
        response => dispatch({
            type: global_constants.constants.LEFT_MENU_PERMISSION,
            payload: response
        }),
        error => {
            log_error(error)
        }
    );

}

export const get_r2_url = (redirect_url, company_id, token, guid) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.user_urls.r2_url}`;
    var data = { "redirect_url": redirect_url, token: guid, company_id: company_id }

    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': data
    })

    return dispatch => request.make(request_config).then(
        response => dispatch({
            type: shared_constants.USER_R2_URL,
            payload: response
        }),
        error => {
            log_error(error)
        }
    );
}

//Remove  R2 url from store on change company
export const remove_r2_url_from_store = () => {
    return dispatch =>
        dispatch({
            type: shared_constants.USER_R2_URL,
            payload: {data:null}
        })
}

// store r2 base url and page name in the store
export const store_r2_data = ( url, page_name) => {
    return dispatch =>
      dispatch({
            type: shared_constants.R2_PAGE_INFORMATION,
                    payload:
                    {
                        page_name: page_name,
                        r2_base_url:url
                    }
                })

}

// quick search locations by keywords
export const get_fax_queue_data = (id, document_type, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.fax.fax}/${id}${global_constants.end_points.fax.get_fax_queue_data}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            document_type: document_type,
        }
    })
    return request.make(request_config);
}

// quick search locations by keywords
export const send_fax_queue_data = (data, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.fax.fax}${global_constants.end_points.fax.send_fax_queue}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': data
    })
    return request.make(request_config);
}

// function used for notifying patient header's expand/collapse property
export const header_ui_change = (is_expand) => {
    return dispatch =>
        dispatch({
            type: global_constants.constants.HEADER_UI_CHANGE,
            payload: { is_expand }
        })

}

// get medical group listing for add referring physician
export const get_medical_list = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.referring_physician.get_medical_group_list}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(request_config);
}

// get primary specility listing for add referring physician
export const get_primary_specility_list = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.referring_physician.get_primary_specility_list}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(request_config);
}
// add referring physician
export const add_referring_physician = (token ,params) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.referring_physician.add_referring_physician}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': params
    })
    return request.make(request_config);
}


export const add_referring_source = (token,params)=>{
    const url = `${global_constants.base_end_points}${global_constants.end_points.referring_source.referring_source}${global_constants.end_points.referring_source.add_reffering_source}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': params
    })
    return request.make(request_config)
}

export const get_contracts = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.insurance.contract}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(request_config);
}

export const get_insurance_types = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.insurance.type}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(request_config);
}

// add Primary Insurance
export const add_primary_insurance = (token, params) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.insurance.add_primary_insurance}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': params
    })
    return request.make(request_config);
}


// add new insurance apis=================Start========================

// quick search class by keywords
export const quick_search_class = (params, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.insurance.quick_search_class}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            keyword: params.keyword,
            page_size: global_constants.constants.Quick_Search_Suggestion_List_Size,
            enableRestriction: false
        }
    })
    return request.make(request_config)
}

// search class by advacned control
export const advanced_search_class = (params, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.insurance.search_class}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(params)
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
             ...params 
        }
    })
    return request.make(request_config)
}

// quick search payer by keywords
export const quick_search_payer = (params, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.insurance.quick_search_payer}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            keyword: params.keyword,
            page_size: global_constants.constants.Quick_Search_Suggestion_List_Size,
            enableRestriction: false
        }
    })
    return request.make(request_config)
}

export const get_claim_override_payer = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.procedure_code_replacement_url.payer}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(requestConfig);
}

// search class by advacned control
export const search_class_by_company = (company_id,params, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.insurance.search_class_by_company}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(params)
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {            
            company_id
        }
    })
    return request.make(request_config)
}

// quick search payer by keywords
export const quick_search_class_by_company = (company_id,params, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.insurance.quick_search_class_by_company}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            company_id:company_id,
            keyword: params.keyword,
            page_size: global_constants.constants.Quick_Search_Suggestion_List_Size,           
        }
    })
    return request.make(request_config)
}
// search payer by advacned control
export const advanced_search_payer = (params, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.insurance.search_payer}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(params)
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            ...params
        }
    })
    return request.make(request_config)
}

export const get_procedure_code_modifiers = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.procedure_code.procedure_code}${global_constants.end_points.procedure_code.procedure_code_modifiers}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }
    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(requestConfig)
}
export const get_company_modifiers = (token,company_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.procedure_code.procedure_code}${global_constants.end_points.procedure_code.company_modifiers}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }
    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            company_id: company_id,
        }
    })
    return request.make(requestConfig)
}

export const get_multiple_fee_schedules = (token,procedure_code_id, params) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.procedure_code.procedure_codes}/${procedure_code_id}${global_constants.end_points.procedure_code.multiple_fee_schedules}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(params)
    }
    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(requestConfig)
}

/**
 * prompt are (not_required, prompt_requred, required, handled)
 * meaning of these states is explained in state reducer under condition PROMPT_FOR_LOGOUT
 * @param prompt
 */
export const prompt_for_logout = (prompt) => {
    return dispatch =>
        dispatch({
            type: global_constants.constants.PROMPT_FOR_LOGOUT,
            payload: { prompt }
        });
} 
export const get_private_key_for_encryption = () => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.user_urls.master_sys_setting_by_key}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    let requestConfig = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            key: "OfflineEncryptionKey"
        }
    })
    return request.make(requestConfig)
}


//Get company details on the basis of company id.
export const get_company_detail_data = (company_id, token) => {
    const url = `${global_constants.base_end_points}/company/${company_id}/company_details`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(request_config);
}

//check if new version is deployed
export const check_app_version = () => {
    const url = `${global_constants.base_end_points}/check_app_version`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'req_version': global_constants.build_version
    }
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config);
}

export const get_patient_company_info = (patient_id,token ) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.patient_urls.patients}/${patient_id}/get_patient_company_info`;

    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config);

}

// search user types by advanced control
export const advanced_search_user_type = (params, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.user_urls.user_type_advance_search}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(params)
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
             ...params 
        }
    })
    return request.make(request_config)
}

// search user role by advanced control
export const advanced_search_user_role = (params, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.user_urls.user_role_advance_search}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(params)
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
             ...params 
        }
    })
    return request.make(request_config)
}

// function used for notifying Menu expand/collapse property
export const set_notes_modal_status = (is_expand: any) => {
    return dispatch =>
    dispatch({
        type: global_constants.constants.SHOW_NOTES_MODAL,
        payload: is_expand
    })
}

// function used for notifying model popup report
export const show_hide_model_popup_report = (is_expand: any) => {
    return dispatch =>
    dispatch({
        type: global_constants.constants.SHOW_MODEL_POPUP_REPORT,
        payload: is_expand
    })
}

// quick search payer by keywords
export const quick_search_rejection_group = (params, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.rejection_group.quick_search}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            keyword: params.keyword,
            page_size: global_constants.constants.Quick_Search_Suggestion_List_Size,
            enableRestriction: false,
            include_inactive: params.include_inactive
        }
    })
    return request.make(request_config)
}

// search payer by advacned control
export const advanced_search_rejection_group = (params, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.rejection_group.advanced_search}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(params)
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(request_config)
}

// function used for notifying Menu expand/collapse property
export const set_prevent_company_model_status = (is_expand: any) => {
    return dispatch =>
    dispatch({
        type: global_constants.constants.SHOW_PREVENT_COMPANY_CHANGE_MODEL,
        payload: is_expand
    })
}