import { MenuItem } from "@blueprintjs/core";
import { ItemPredicate, ItemRenderer } from "@blueprintjs/select";
import * as React from "react";

export interface IData {
    /** Title of item. */
    title: string;
}

/** Data source*/
export const ITEMS: IData[] = [
    { title: "Non-editable" },
    { title: "Inactive" }
];

export const renderItem: ItemRenderer<IData> = (item, { handleClick, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }
    const text = `${item.title}`;
    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            key={item.title}
            onClick={handleClick}
            text={highlightText(text, query)}
        />
    );
};

export const renderCreateItemOption = (
    query: string,
    active: boolean,
    handleClick: React.MouseEventHandler<HTMLElement>,
) => (
        <MenuItem
            icon="add"
            text={`Create "${query}"`}
            active={active}
            onClick={handleClick}
            shouldDismissPopover={false}
        />
    );

export const filterItem: ItemPredicate<IData> = (query, item, _index, exactMatch) => {
    const normalizedTitle = item.title.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
        return normalizedTitle === normalizedQuery;
    } else {
        return `${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
    }
};

function highlightText(text: string, query: string) {
    let lastIndex = 0;
    const words = query
        .split(/\s+/)
        .filter(word => word.length > 0)
        .map(escapeRegExpChars);
    if (words.length === 0) {
        return [text];
    }
    const regexp = new RegExp(words.join("|"), "gi");
    const tokens: React.ReactNode[] = [];
    while (true) {
        const match = regexp.exec(text);
        if (!match) {
            break;
        }
        const length = match[0].length;
        const before = text.slice(lastIndex, regexp.lastIndex - length);
        if (before.length > 0) {
            tokens.push(before);
        }
        lastIndex = regexp.lastIndex;
        tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
        tokens.push(rest);
    }
    return tokens;
}

function escapeRegExpChars(text: string) {
    return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
}

export const itemSelectProps = {
    itemPredicate: filterItem,
    itemRenderer: renderItem,
    items: ITEMS,
};

export function createItem(title: string): IData {
    return {
        title
    };
}

export function areItemsEqual(itemA: IData, itemB: IData) {
    // Compare only the titles (ignoring case) just for simplicity.
    return itemA.title.toLowerCase() === itemB.title.toLowerCase();
}

export function doesItemEqualQuery(item: IData, query: string) {
    return item.title.toLowerCase() === query.toLowerCase();
}

export function arrayContainsItem(items: IData[], itemToFind: IData): boolean {
    return items.some((item: IData) => item.title === itemToFind.title);
}

export function addItemToArray(items: IData[], itemToAdd: IData) {
    return [...items, itemToAdd];
}

export function deleteItemFromArray(items: IData[], itemToDelete: IData) {
    return items.filter(item => item !== itemToDelete);
}

export function maybeAddCreatedItemToArrays(
    items: IData[],
    createdItems: IData[],
    item: IData,
): { createdItems: IData[]; items: IData[] } {
    const isNewlyCreatedItem = !arrayContainsItem(items, item);
    return {
        createdItems: isNewlyCreatedItem ? addItemToArray(createdItems, item) : createdItems,
        // Add a created item to `items` so that the item can be deselected.
        items: isNewlyCreatedItem ? addItemToArray(items, item) : items,
    };
}

export function maybeDeleteCreatedItemFromArrays(
    items: IData[],
    createdItems: IData[],
    item: IData,
): { createdItems: IData[]; items: IData[] } {
    const wasItemCreatedByUser = arrayContainsItem(createdItems, item);

    // Delete the item if the user manually created it.
    return {
        createdItems: wasItemCreatedByUser ? deleteItemFromArray(createdItems, item) : createdItems,
        items: wasItemCreatedByUser ? deleteItemFromArray(items, item) : items,
    };
}