import * as global_constants from '../../global_constants';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request';

export const get_advance_search = (params, token) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.diagnosis.diagnosis}${global_constants.end_points.diagnosis.diagnosis_search}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'silversurfer': JSON.stringify(params)
    }
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config);    
}

export const diagnosis_code_quick_search_data = (param, token) => {

    const url = `${global_constants.base_end_points}${global_constants.end_points.diagnosis.diagnosis}${global_constants.end_points.diagnosis.diagnosis_quick_search}`;

    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            keyword: param,
            page_size: global_constants.constants.Quick_Search_Suggestion_List_Size
        }
    })

    return request.make(request_config);
}

export const get_case_diagnosis_code = (case_id, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.diagnosis.diagnosis}/${case_id}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })

    return request.make(request_config);
}

export const add_update_case_diagnosis_code = (token, case_diagnosis_code, case_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.diagnosis.diagnosis}/${case_id}${global_constants.end_points.diagnosis.add_update_case_diagnosis_code}`;


    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': case_diagnosis_code
    })

    return request.make(request_config);
}

export const search_diagnosis_codes_by_page = (token, query_string) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.diagnosis.diagnosis}${global_constants.end_points.diagnosis.search_by_page}?${query_string}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};