import * as moment from 'moment';
import * as React from "react";
import { connect } from "react-redux";
import { toastr } from 'react-redux-toastr';
import TextareaAutosize from 'react-textarea-autosize';
import { Button, Dimmer, Form, Grid, Loader, Modal } from "semantic-ui-react";
import { get_insurance, quick_search_location, quick_search_provider, savePaymentDetails, search_place, search_billing_and_treating_provider, addPaymentDetails } from "../../payment/action/payment_action";
import { patientCreditType, PAYMENT_ACTION, payment_const, payment_message } from '../../payment/payment_constants';
import AdvancedControl from "../../shared/component/advanced_control";
import CurrencyFormatter from '../../shared/component/currency_formatter';
import DatePicker from '../../shared/component/date_picker_component';
import Selection from '../../shared/component/selection_component';
import * as local_storage from '../../shared/local_storage_utility';
import * as session_storage from '../../shared/session_storage_utility';
import AutoSearchComponent from './../../shared/component/auto_search_component';
import AdditionalPaymentsComponents from './additional_payments_component';
import { search_location_configuration, search_provider_configuration } from '../../shared/shared_constants';
import { set_focus_on_element_with_id, handle_focus_on_tab } from './../../shared/tab_navigation_utility';
import { get_popper_id } from '../utility';
import { adminBillingAuditLogFeatures } from '../../admin/constants/constants';
import { get_lauch_darkley_key_value, date_format_with_no_time } from '../../shared/utility';

class AddAdditionalPayment extends React.Component<any, any> {

    element_amount: HTMLElement;
    element_credit_card: HTMLElement;
    additionalPaymentsNode: any;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            is_submitted: false,
            is_submit_diabled: false,
            closeControl: false,
            show_modal: false,
            payment_details: {
                deposit_date: null,
                patient_credit_id: 0,
                patient_credit_type: {},
                patient_id: session_storage.get("active_patient"),
                payment_code: {},
                amount: '',
                original_amount: 0,
                payment_money_type: 2,
                check_num: '',
                cc_num: '',
                insurance_code: '',
                insurance_name: '',
                comment: '',
                place_of_service: {},
                credit_type_id: 1,
                billing_provider: {},
                batch: '',
                is_active: true,
                charge_id: null,
                credit_card_auth_num: '',
                check_credit_card: '',
            },
            payment_code: this.filter_payment_code(),
            err_deposit_date: false,
            err_credit_check_num: false,
            check_num_required: true,
            grid_conf: {
                isPagination: true,
                gridAutoHeight: true,
                selectionType: 'single',
                paginationPageSize: 20,
            },
            amount_negative: false,
            location_id: -1,
            location_search_data: {},
            provider_search_data: {},
            selected_location: {},
            selected_provider: {},
            payment_code_list: [],
            insurance_list: [],
            search_loader: false,
            insurance_loading: true,
            transaction_list: [],
            default_location_selected_row: null,
            default_provider_selected_row: null,
            master_data: this.props.user_login_details.master_data,
            credits: null,
            is_force_save: false,
            error_message: '',
            amount_focused: false,
            minDate: null,
        };
    }

    initial_state = null;
    is_amount_negative_error = false;
    is_negative_valid = false;
    empty_field_error = true;
    is_amount_changed = '';
    is_minus = false;
    location_search_type = "location";
    provider_search_type = "provider";

    async componentDidMount() {
        this.initial_state = this.state.payment_details;
        const token = local_storage.get("auth_data").token_details.access_token;
        const transaction_type = this.props.user_login_details.master_data_by_type.data.credit_types !== null ? this.props.user_login_details.master_data_by_type.data.credit_types.map((val, index) => { return { key: index, value: val.credit_type_id, text: val.credit_type } }) : [];
        const new_payment_code = this.filterPaymentCodeList(patientCreditType.copay); // filter the payment code list on basis of credit type value
        const auth_data = local_storage.get("auth_data");
        let accrual_flag = false;
        let open_month = null;
        if (auth_data) {
            accrual_flag = auth_data.accrual_accounting || false;
            open_month = auth_data.open_month || null;
        }
        var minDate = null;
        if (accrual_flag) {
            minDate = new Date(open_month);
        }
        this.setState({
            payment_code_list: new_payment_code,
            transaction_list: transaction_type,
            payment_details: { ...this.state.payment_details, credit_type_id: 1 },
            minDate: minDate
        });

        this.element_amount = document.querySelector('#add_id_input_amount');
        this.element_credit_card = document.querySelector('#credit_number');
    }

    componentDidUpdate = (previousProps, previousState) => {

        if (previousProps.payment_details != this.props.payment_details) {
            const result = this.props.payment_details;
            this.setState({ loading: false, is_submit_diabled: false });
            let flag = this.props.payment_details.is_saved ? 'true' : 'false';
            session_storage.set('is_saved', flag);
            if (result.action_type === PAYMENT_ACTION.ADD_CREDIT) {
                if (result.response.status === 1 && result.is_saved && result.is_saved == true) {
                    this.emptyForm(true);
                    toastr.success('', payment_message.SAVE_SUCCESS);
                    if (document.getElementById('app_header_search_patient_icon')) {
                        document.getElementById('app_header_search_patient_icon').click();
                    }
                } else if ((result.response.status === 0) && result.is_saved && result.is_saved == true) {
                    const msg = typeof result.response.messages != 'undefined' && result.response.messages !== null ? result.response.messages.map(data => data.message).join('.') : 'Unexpected Error';
                    const code = typeof result.response.messages != 'undefined' && result.response.messages !== null ? result.response.messages.reduce(data => { return data.code }) : 0;
                    if (code.code == 50002) {
                        this.handle_navigation_on_modal();
                        this.setState({ show_modal: true, error_message: msg });
                    } else {
                        let display_message = msg.replace(payment_message.ERROR_SERVER_SIDE_PATIENT_REFUND, payment_message.ERROR_PATIENT_REFUND_ADD_MUTIPLE);
                        toastr.error('', display_message);
                    }

                } else if (result.response.status === 400) {
                    const msg = typeof result.response.data.messages != 'undefined' && result.response.data.messages !== null ? result.response.data.messages.map(data => data.message).join('.') : 'Unexpected Error';
                    toastr.error('', msg);
                }
            }
        }

        if (previousProps.credits != this.props.credits && this.props.payment_details.is_saved != true) {
            var location_id = -1;
            var formatted_billing_provider = this.format_row_suggestions(this.provider_search_type);
            var location_formatted_data = this.format_row_suggestions(this.location_search_type);
            if (typeof this.props.credits.billing_provider != "undefined" && this.props.credits.billing_provider.code) {
                let item = this.props.credits.billing_provider;
                formatted_billing_provider = {
                    "title": `${item.id}`,
                    "code": item.code,
                    "description": item.description,
                    "id": item.id,
                    "label": (item.code) + " - " + (item.description),

                };
            }
            if (typeof this.props.credits.place_of_service != "undefined" && this.props.credits.place_of_service.code) {
                let item = this.props.credits.place_of_service;
                location_id = item.id;
                location_formatted_data = {
                    "title": `${item.id}`,
                    "code": item.code,
                    "name": item.name,
                    "id": item.id,
                    "label": (item.code) + " - " + (item.name),

                };
            }

            this.setState((prevState) => ({
                default_location_selected_row: location_formatted_data,
                default_provider_selected_row: formatted_billing_provider,
                payment_details: {
                    ...prevState.payment_details,
                    billing_provider: this.check_empty_location_provider(this.provider_search_type, formatted_billing_provider),
                    place_of_service: this.check_empty_location_provider(this.location_search_type, location_formatted_data),
                },
                selected_location: location_formatted_data,
                selected_provider: formatted_billing_provider,
                location_id: location_id
            }));
        }

        //clear form data when patient change from header
        if (this.props.patientId !== previousProps.patientId) {
            this.emptyForm(true);
        }
    }


    filter_payment_code = () => {
        const payment_code_list = this.props.user_login_details.master_data.data.payment_code;
        const user_type_id = this.props.user_login_details.user_data.data.user_type_r3_id;
        const transaction_types = this.props.user_login_details.master_data_by_type.data.credit_types || [];
        const payment_code_obj = {};
        transaction_types.map(item => {
            const credit_type_id = parseInt(item.credit_type_id);
            const code_list = payment_code_list.filter(data => {
                if (user_type_id != 4 || data.clinic_credit_flag == true) {
                    if ((credit_type_id === patientCreditType.copay && data.copay_flag == true) || (credit_type_id != patientCreditType.copay)) {
                        return true;
                    }
                }
            }).map((val, index) => { return { key: index, value: val.payment_id, text: val.payment_desc, data: val } });
            payment_code_obj[credit_type_id] = code_list;
        });
        return payment_code_obj;
    }

    set_focus = (error_data) => {
        if (error_data.amount) {
            this.element_focus(this.element_amount);
        } else if (this.state.check_num_required && error_data.check_num) {
            this.element_focus(this.element_credit_card);
        }
    }

    element_focus = (element) => {
        setTimeout(() => {
            if (element) {
                element.focus();
            }
        }, 100);
    }

    check_negative = () => {
        if (this.check_copay_transaction_type(this.state.payment_details.credit_type_id) && this.state.payment_details.amount.indexOf('-') != -1) {
            this.is_negative_valid = false;
        } else {
            this.is_negative_valid = true;
        }

    }

    check_empty_location_provider = (type, row) => {
        var return_row = {};
        if (type == this.location_search_type) {
            return_row = (row != null && (row.code == '' || row.name == '')) ? {} : row;
        } else if (type == this.provider_search_type) {
            return_row = (row != null && (row.code == '' || row.description == '')) ? {} : row;
        }
        return return_row;
    }

    format_row_suggestions = (type) => {
        if (type == this.location_search_type) {
            return { title: '', code: '', name: '', id: '', label: '' };
        } else if (type == this.provider_search_type) {
            return { title: '', code: '', description: '', id: '', label: '' };
        }
    }

    // save the text value in state onchange
    onChange = (e, input_name) => {
        const input_value = e.target.value;
        var payment_state_data = { ...this.state.payment_details, [input_name]: input_value };
        let err_credit_check_num = false;
        if (input_name === 'check_credit_card') {
            if (this.state.payment_details.payment_money_type === 2) {
                payment_state_data.check_num = input_value;
                payment_state_data.cc_num = '';
                //Validation commented credit card not required
                //err_credit_check_num = (this.state.is_submitted && input_value == '') ? true : false;
            } else if (this.state.payment_details.payment_money_type === 3 || this.state.payment_details.payment_money_type === 4 || this.state.payment_details.payment_money_type === 5) {
                payment_state_data.cc_num = input_value;
                payment_state_data.check_num = '';
                //Validation commented credit card not required
                //err_credit_check_num = (this.state.is_submitted && input_value == '') ? true : false;
            }
            else if (this.state.payment_details.payment_money_type === 1) {
                payment_state_data.check_num = '';
                payment_state_data.cc_num = '';
            }
        }

        this.setState({ payment_details: payment_state_data, err_credit_check_num });
    }

    // on dropdown select add validations
    onDropDownSelect = (value, type) => {
        var check_num = true;
        if (type === 'payment_money_type') {
            if (value === 1) {
                check_num = false;
            }
        }
        this.setState(prevState => ({ payment_details: { ...prevState.payment_details, [type]: value }, check_num_required: check_num }))
    }

    // filter the payment code list on basis of select patient credit types like copy , interest
    filterPaymentCodeList = (credit_type_id) => {
        credit_type_id = parseInt(credit_type_id);
        const payment_code_list = this.state.master_data.data.payment_code;
        const user_type_id = this.props.user_login_details.user_data.data.user_type_r3_id;
        const new_payment_code = payment_code_list.filter((data, index) => {
            if (user_type_id != 4 || data.clinic_credit_flag == true) {
                if ((this.check_copay_transaction_type(credit_type_id) && data.copay_flag == true) || (!this.check_copay_transaction_type(credit_type_id))) {

                    return true;
                }
            }
        }).map((val, index) => { return { key: index, value: val.payment_id, text: val.payment_desc } });
        return new_payment_code;
    }


    //Check form validation
    is_valid = () => {
        let flag_value = true;
        let update_value = {};
        const form = this.state.payment_details;
        let error_data = { ...this.state.error };
        if (form.deposit_date === null) {
            update_value['err_deposit_date'] = true;
            flag_value = false;
        }
        //Credit card validation not required
        //if (form.payment_money_type !== 1 && this.state.check_num_required && form.check_credit_card === '') {
        //    update_value['err_credit_check_num'] = true;
        //    flag_value = false;
        //}

        if (flag_value === false) {
            this.setState({ ...update_value })
        }

        this.is_amount_negative_error = (form.credit_type_id == 7 && form.amount > 0) ? true : false;
        this.is_negative_valid = (this.check_copay_transaction_type(form.credit_type_id) && form.amount < 0) ? true : false;
        return flag_value;
    }

    get_credit_type = (credit_type_id) => {
        let type_id = parseInt(credit_type_id)
        let master_data_by_type = this.props.user_login_details.master_data_by_type;
        let credit_types = master_data_by_type && master_data_by_type.data.credit_types.filter(item => item.credit_type_id === type_id);
        if (credit_types && credit_types.length > 0) {
            return credit_types[0];
        }
        return null;
    }

    // save payment details on save  button click
    onSaveHandler = (e) => {
        e.preventDefault();
        let data_list = this.additionalPaymentsNode && this.additionalPaymentsNode.get_data_list() || [];
        let negative_status = this.additionalPaymentsNode && this.additionalPaymentsNode.get_negative_amount_status();
        if (this.is_valid() && data_list.length > 0) {
            if (negative_status.amount_negative_error) {
                toastr.error('', payment_message.amount_negative);
            }
            else {
                const token = local_storage.get("auth_data").token_details.access_token;
                const payment_detail = { ...this.state.payment_details };

                payment_detail.payment_money_type = payment_detail.payment_money_type;
                payment_detail.deposit_date = date_format_with_no_time(payment_detail.deposit_date);
                payment_detail.payment_deposit_date = '';
                payment_detail.credit_card_auth_num = payment_detail.check_credit_card;
                payment_detail.check_num = payment_detail.check_credit_card;
                payment_detail.patient_id = session_storage.get("active_patient");

                const credit_list = [];
                for (let i = 0; i < data_list.length; i++) {
                    credit_list.push({
                        ...payment_detail,
                        ...data_list[i],
                        patient_credit_type: this.get_credit_type(data_list[i].credit_type_id)
                    })
                }

                credit_list.map(item => {
                    item.deposit_date = date_format_with_no_time(item.deposit_date);
                    item.date_of_service = date_format_with_no_time(item.date_of_service);
                });

                var payment_credit = {
                    is_from_charge: false,
                    is_force_save: this.state.is_force_save,
                    credits: credit_list,
                    current_date: moment().format("YYYY-MM-DDT00:00:00"),
                }
                this.setState({
                    loading: true,
                    is_submit_diabled: true,
                    is_force_save: false,
                    error_message: ''
                });
                if (this.props.magnusPlat604AuditLogCreateTheAddUpdateDeleteControllersForPaymentActions) {
                    this.props.addPaymentDetails(payment_credit, PAYMENT_ACTION.ADD_CREDIT, this.props.patientId, token);
                }
                else {
                    payment_credit.credits.map(item => {
                        item.deposit_date = date_format_with_no_time(item.deposit_date);
                        item.date_of_service = date_format_with_no_time(item.date_of_service);
                    });

                    this.props.savePaymentDetails(payment_credit, PAYMENT_ACTION.ADD_CREDIT, this.props.patientId, token);
                }
            }

        } else {
            toastr.error('', payment_message.required_field);
            this.setState({
                is_submitted: true
            });
        }
    }

    handle_navigation_on_modal = () => {
        setTimeout(() => {
            let closeIcons: NodeListOf<HTMLElement> = document.querySelectorAll('i.close.icon:not(.auto-search-icon)') as NodeListOf<HTMLElement>;
            let closeIcon = closeIcons[closeIcons.length - 1]
            if (closeIcon) {
                closeIcon.tabIndex = 0;
                closeIcon.id = "confirm_modal_window_close_icon";
                closeIcon.addEventListener("keydown", function (event) {
                    if (event.shiftKey && event.keyCode == 9) {
                        event.preventDefault();
                        let prevButton = document.getElementById("id_add_modal_btn_ok");
                        prevButton.focus();
                    }

                    if (event.keyCode == 13) {
                        event.preventDefault();
                        var el = event.target as HTMLElement
                        el.click();
                    }
                });
            }

            set_focus_on_element_with_id("id_add_modal_btn_cancel");

        }, 0)
    }


    // make empty the control values of form on specific condition
    emptyForm = (is_retain_data = false) => {
        this.setState(prevState => ({
            payment_details: {
                ...prevState.payment_details,
                place_of_service: this.check_empty_location_provider(this.location_search_type, this.state.default_location_selected_row),
                billing_provider: this.check_empty_location_provider(this.provider_search_type, this.state.default_provider_selected_row),
                deposit_date: is_retain_data ? prevState.payment_details.deposit_date : null,
                payment_code: {},
                amount: '',
                payment_money_type: 2,
                check_num: '',
                cc_num: '',
                insurance_code: '',
                insurance_name: '',
                comment: '',
                credit_type_id: 1,
                check_credit_card: '',
                batch: ''
            },
            selected_location: this.check_empty_location_provider(this.location_search_type, this.state.default_location_selected_row),
            selected_provider: this.check_empty_location_provider(this.provider_search_type, this.state.default_provider_selected_row),
            check_num_required: true,
            is_submitted: false,
            clear_additional_payment: { clear: true }
        }));
    }

    // clear the fields on clear button click
    onClearHandler = (e) => {
        e.preventDefault();
        this.emptyForm();
    }

    // search location by typing name or code
    onPlaceSearch = async (params) => {
        const token = local_storage.get("auth_data").token_details.access_token;
        const search_data = await search_place(params, token).then(res => res.data);
        const search_result = search_data.data !== null ? search_data.data.result : [];
        const grid_data = {
            ...this.state.grid_conf,
            rows: search_result,
            column: search_location_configuration('MODAL_ADD_SEARCH_LOCATION').column_defs,
            messages: search_data.messages
        };
        this.setState({ location_search_data: grid_data });
    }

    // search provider using code and description
    onProviderSearch = async (params) => {
        const token = local_storage.get("auth_data").token_details.access_token;
        params.id = this.state.location_id;
        const search_data = await search_billing_and_treating_provider(params, token).then(res => res.data);
        const search_result = search_data.data !== null ? search_data.data.result : [];
        const grid_data = {
            ...this.state.grid_conf,
            rows: search_result,
            column: search_provider_configuration('MODAL_ADD_SEARCH_PROVIDER').column_defs,
            messages: search_data.messages
        };
        this.setState({ provider_search_data: grid_data });
    }

    // select a location row in grid on modal
    onLocationGridRowSelection = (selectedRow) => {
        let id = (typeof selectedRow.title != undefined) ? selectedRow.title : 0;
        this.setState(prevState => ({
            error: { ...prevState.error, location: false },
            selected_location: selectedRow, location_id: id,
            payment_details: { ...prevState.payment_details, place_of_service: selectedRow }
        }));
    }

    // on provider search item selection
    onProviderGridRowSelection = (selectedRow) => {

        this.setState(prevState => ({
            selected_provider: selectedRow,
            payment_details: { ...prevState.payment_details, billing_provider: selectedRow }
        }));
    }

    //Auto search for Location
    renderResult = (props) => {
        return (<div key={props.title} tabIndex={0} className="item_auto_search">
            <div key={props.title} className="fs_13">
                <span>{props.label}</span>
            </div>
        </div>);
    }

    // get the quick search location apis data
    getLocationDataList = async (search_keyword) => {
        const token = local_storage.get("auth_data").token_details.access_token;
        return await quick_search_location(search_keyword, token);
    }
    // get the quick search provider apis data
    getProviderDataList = async (search_keyword) => {
        //var search_key_obj = { key: search_keyword, locationId: this.state.location_id };
        var search_key_obj = { key: search_keyword, locationId: -1 };//Search provider irrespective of location value as -1
        const token = local_storage.get("auth_data").token_details.access_token;
        return await quick_search_provider(search_key_obj, token);
    }

    // preapare the suggestion list with search result
    prepareSuggestion = (data, type) => {
        let formattedList = [];
        var data_length = data.data.length;
        if (data && data_length) {
            for (let i = 0; i < data_length; i++) {
                let item = data.data[i];
                if (type == this.location_search_type) {

                    formattedList.push({
                        "title": `${item.id}`,
                        "code": item.code,
                        "name": item.name,
                        "id": item.id,
                        "label": (item.code) + " - " + (item.name),

                    });
                } else if (type == this.provider_search_type) {
                    formattedList.push({
                        "title": `${item.id}`,
                        "code": item.code,
                        "description": item.description,
                        "id": item.id,
                        "label": (item.code) + " - " + (item.description),

                    });
                }
            }
        }
        return formattedList;
    }

    // select the item on click suggested items
    onselection = (item, type) => {
        if (type === 'provider') {
            this.onProviderGridRowSelection(item);
        } else {
            this.onLocationGridRowSelection(item)
        }

    }

    clear_quick_search = (type) => {
        if (type == this.provider_search_type) {
            this.setState({ selected_provider: {}, payment_details: { ...this.state.payment_details, billing_provider: {} } });
        } else {
            this.setState(prevState => ({
                selected_location: {}, location_id: -1,
                payment_details: { ...this.state.payment_details, place_of_service: {} },
                error: { ...prevState.error, location: prevState.is_submitted ? true : false }
            }));
        }
    }

    // select the item on click suggested items
    onModalRowSelection = (item, type) => {
        if (type === 'provider') {
            let formatted_provider = {
                "title": `${item.id}`,
                "code": item.code,
                "description": item.description,
                "id": item.id,
                "label": (item.code) + " - " + (item.description),

            };
            this.onProviderGridRowSelection(formatted_provider);
        } else {
            let formatted_row = {
                "title": `${item.id}`,
                "code": item.code,
                "name": item.name,
                "id": item.id,
                "label": (item.code) + " - " + (item.name),

            };
            this.onLocationGridRowSelection(formatted_row)
        }

    }

    check_copay_transaction_type = (value) => {
        if (value == patientCreditType.copay || value == patientCreditType.coinsurance || value == patientCreditType.deductible
            || value == patientCreditType.other || value == patientCreditType.paymentonaccount || value == patientCreditType.wellness
        ) {
            return true
        } else {
            return false
        }
    }

    // get the searched value which is typed for searching location or providers
    get_input_value = (input, type) => {
        if (input.length >= 3) {
            if (type === 'provider') {
                this.getProviderDataList(input);
            } else {
                this.getLocationDataList(input);
            }
        }

    }


    filter_dropdown_value = (options, selected_val) => {
        if (options == null || options == '') {
            return null;
        }
        const return_text = options.filter((val) => {
            if (val.value == selected_val) {
                return val;
            }
        });
        return return_text.length > 0 ? return_text[0] : null;
    }

    force_save_handle = (e) => {
        this.setState({ is_force_save: true, show_modal: false }, () => {
            this.onSaveHandler(e);
        });
    }

    // modal handler
    modal_handler = (status) => {
        if (status == true) {
            this.handle_navigation_on_modal();
        }
        this.setState({ show_modal: status });
    }

    render_card_required = () => {
        if (this.state.check_num_required) {
            return (<span className={this.state.is_submitted && this.state.err_credit_check_num ? "req-alert" : "req-alert_normal"}>
                (required)
            </span>)
        }
        return null;
    }

    render() {
        const payment_data = this.state.payment_details;
        const error = this.state.error;
        const { when } = this.props
        const { show_prompt_modal, last_location } = this.state
        return (<div>
            <Dimmer active={this.state.loading}>
                <Loader size='massive'>Loading</Loader>
            </Dimmer>
            <div className="item" id="id_div_modal_add">
                <Modal
                    onClose={e => this.modal_handler(false)}
                    centered={false}
                    className="default-modal"
                    open={this.state.show_modal}
                    closeIcon
                    closeOnDimmerClick={false}
                >
                    <Modal.Header>Confirm</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Grid>
                                <Grid.Column>
                                    <p>{this.state.error_message}</p>
                                </Grid.Column>
                            </Grid>
                            <Grid>
                                <Grid.Column computer={16} textAlign="right">
                                    <Button id="id_add_modal_btn_cancel" type="reset" basic onClick={e => this.modal_handler(false)}>Cancel</Button>
                                    <Button id="id_add_modal_btn_ok" type='submit' onClick={this.force_save_handle} onKeyDown={(event) => { handle_focus_on_tab(event, "confirm_modal_window_close_icon") }} primary>OK</Button>
                                </Grid.Column>
                            </Grid>

                        </Modal.Description>

                    </Modal.Content>
                </Modal>
            </div>
            <Form autoComplete="off" className="normal-form padd-r-0 add-additonal-payment-view">
                <Grid className="mar-t-5 padd-r-0">
                    <Grid.Column className="padd-r-0">
                        <Grid>
                            <Grid.Column tablet={8} computer={4}>
                                <Form.Field className={this.state.is_submitted && this.state.err_deposit_date ? "requiredWithBgColor" : ""}>
                                    <label>Deposit Date <span className={this.state.is_submitted && this.state.err_deposit_date ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                    <DatePicker
                                        is_required={this.state.is_submitted && this.state.err_deposit_date}
                                        maxDate={moment().format('MM/DD/YYYY')}
                                        id_popper={get_popper_id()}
                                        date_update={date => this.setState(prevState => ({
                                            payment_details: { ...prevState.payment_details, deposit_date: date },
                                            err_deposit_date: date ? false : true
                                        }))}
                                        date={payment_data.deposit_date}
                                        minDate={this.state.minDate}
                                        id="payment-datepicker"
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column tablet={8} computer={4}>
                                <Form.Field>
                                    <label>Payment Method</label>
                                    <Selection
                                        defaultValue={payment_data.payment_money_type || 2}
                                        id="add_id_payment_method"
                                        placeHolder='Select'
                                        options={this.props.payment_method_list}
                                        onChange={value => this.onDropDownSelect(parseInt(value), 'payment_money_type')}
                                        hidden={true}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column tablet={8} computer={4}>
                                <Form.Field className={this.state.check_num_required && this.state.is_submitted && this.state.err_credit_check_num ? 'requiredWithBgColor' : ''}>
                                    <label>Check/Credit card Number</label>
                                    <Form.Input fluid
                                        maxLength={50}
                                        name='txt_creditcard'
                                        onChange={e => this.onChange(e, 'check_credit_card')}
                                        value={payment_data.check_credit_card}
                                        error={this.state.is_submitted && this.state.check_num_required && this.state.err_credit_check_num}
                                        id='credit_number'
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column tablet={8} computer={4}>
                                <Form.Field>
                                    <label>Batch</label>
                                    <input id="add_id_input_batch"
                                        name="txt_batch"
                                        type="text"
                                        maxLength={38}
                                        onChange={e => this.onChange(e, 'batch')}
                                        value={payment_data.batch} />
                                </Form.Field>
                            </Grid.Column>

                            <Grid.Column tablet={16} computer={16}>
                                <Form.Field>
                                    <label>Comments</label>
                                    <TextareaAutosize
                                        maxLength={500}
                                        rows={1}
                                        className="textarea-single-row"
                                        value={payment_data.comment}
                                        onChange={e => this.onChange(e, 'comment')}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <AdditionalPaymentsComponents
                                onRef={ref => (this.additionalPaymentsNode = ref)}
                                is_submitted={this.state.is_submitted}
                                clear_data={this.state.clear_additional_payment}
                                paymentCode={this.state.payment_code}
                                insurance={this.props.insurance}
                                transaction_types={this.state.transaction_list || []}
                                payment_method_list={this.props.payment_method_list}
                                place_of_service={this.state.payment_details.place_of_service}
                                billing_provider={this.state.payment_details.billing_provider}
                            />
                        </Grid>
                        <Grid>
                            <Grid.Column computer={16} textAlign="right">
                                <Button id="add_id_btn_clear" type="reset" basic onClick={this.onClearHandler} >Clear</Button>
                                <Button id="add_id_btn_save" type='submit' onClick={this.onSaveHandler} primary disabled={this.state.is_submit_diabled}>Add</Button>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid>
            </Form>
        </div>);
    }
}

const mapDispatchToProps = dispatch => {
    return {
        savePaymentDetails: (data, action_type, patientId, token) => dispatch(savePaymentDetails(data, action_type, patientId, token)),
        addPaymentDetails: (data, action_type, patientId, token) => dispatch(addPaymentDetails(data, action_type, patientId, token))
    };
};

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
        payment_details: state.payment_details,
        magnusPlat604AuditLogCreateTheAddUpdateDeleteControllersForPaymentActions: get_lauch_darkley_key_value(state.launch_darkly, adminBillingAuditLogFeatures.magnusPlat604AuditLogCreateTheAddUpdateDeleteControllersForPaymentActions)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAdditionalPayment);