import * as React from "react";
import { Button, Grid, Accordion, Icon } from "semantic-ui-react";
import { set_focus_to_app_header, handle_click_on_enter } from "./../../shared/tab_navigation_utility";

/**
 * Report optional buttons
 * It will render for Billing Exceptions
 * @param props
 * @returns JSX.Element
 */
const optional_buttons = (props) => {
  if (props.link_drill_down_last_page) {
    return (
      <React.Fragment>
        <Button
          id="remove_report_button"
          type="submit"
          onClick={props.on_remove_button}
          basic
          disabled={props.remove_disabled}
          content={'Remove'}
        />
        <Button
          id="reprocess_report_button"
          type="submit"
          onClick={props.on_reprocess_button}
          basic
          disabled={props.reprocess_disabled}
          content={'Reprocess'}
        />
      </React.Fragment>
    );
  }
  return null;
};

/**
 * Additional actions for report's grid
 * It will return buttons' render for report's grid
 * @param additional_actions
 * @returns JSX.Element
 */
const get_additional_actions = (props) => {
  if(props.additional_actions && props.additional_actions.length > 0){
    return (
      <>
      {
        props.additional_actions.map((action:any)=>{
          return <Button
          id={action.id}
          key={action.id}
          type={action.type} 
          onClick={()=>props.validate_items_selected(action)}
          basic
          disabled={action.disabled}
          content={action.content}
          />
        })
      }
      </>
    );
  }
  return null;
};

const ReportFooter = (props) => {
  let is_close_primary = props.is_print_notvisible ? props.is_export_notvisible ? true : false : false;
  let is_export_primary = props.is_print_notvisible ? true : false;
  return (
    <React.Fragment>
      <Grid.Column computer={16} tablet={16} textAlign="right">
        {props.additional_actions && get_additional_actions(props)}
        {props.link_drill_down_last_page && optional_buttons(props)}
        {props.is_close_button && (
          <Button
            id='print_report_button'
            type='button'
            onKeyDown={set_focus_to_app_header}
            onClick={props.on_close}
            basic={!is_close_primary}
            primary={is_close_primary}
            content={'Close'}
          />
        )}
        {!props.is_export_notvisible && (
          <Button
            id="export_report_button"
            type="submit"
            onClick={props.on_export_button}
            disabled={props.export_disabled}
            basic ={!is_export_primary}
            primary={is_export_primary}
            content={'Export'}
          />
        )}
        {!props.is_print_notvisible && (
          <Button
            id="print_report_button"
            type="submit"
            onKeyDown={set_focus_to_app_header}
            onClick={props.on_print_button}
            disabled={props.print_disabled}
            primary
            content={'Print'}
          />
        )}
      </Grid.Column>
      {props.disclaimer && (
        <Grid.Column computer={16} tablet={16} textAlign="left" className="accordionColumn">
          <Accordion fluid styled>
            <Accordion.Title active={props.disclaimer_is_expended} index={0} onClick={props.disclaimer_toggle}>
              <Icon
                name="angle right"
                onKeyDown={(event) => {
                  set_focus_to_app_header(event);
                  handle_click_on_enter(event);
                }}
                tabIndex={0}
                id="footer_disclaimer"
              />
              Disclaimer
            </Accordion.Title>
            <Accordion.Content active={props.disclaimer_is_expended}>
              <p dangerouslySetInnerHTML={{ __html: props.disclaimer }} />
            </Accordion.Content>
          </Accordion>
        </Grid.Column>
      )}
    </React.Fragment>
  );
};
export default ReportFooter;
