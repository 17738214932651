import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Grid } from 'semantic-ui-react';
import { set_url_history } from '../../reports/util/report_data_formatter';
import { store_r2_data } from '../../shared/action/shared_action';
import { r2_decode_url, r2_form_path, get_lauch_darkley_key_value } from '../../shared/utility';
import { handle_click_on_enter } from '../../shared/tab_navigation_utility';
import { constants } from '../../global_constants';
import * as session_storage from '../../shared/session_storage_utility';

export class StatementAndPaymentsComponent extends React.Component<any, any> {
    _is_mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            shown: true,
        }
    }

    // toggle to show and hide Statement and Payments Component
    private toggle = () => {
        if (this._is_mounted) {
            this.setState({
                shown: !this.state.shown
            });
        }
    }
    componentWillUnmount() {
        this._is_mounted = false;       
    }
    componentDidMount() {
        this._is_mounted = true;       
    }
    componentDidUpdate(previousProps, previousState) {
        if (previousProps.shown != this.props.shown) {
            if (this._is_mounted) {
                this.setState({ shown: false });
            }
        }
        if (previousProps.patient_id != this.props.patient_id) {
            if (this._is_mounted) {
                this.setState({ shown: true });
            }
        }
    }

    on_patient_statement_log(): any {
        const menu_data_object = this.props.shared_details.left_menu_data.data
        const menu_data = menu_data_object.filter((item) => {
            return item.id == constants.PATIENT_STATEMENT_LOG_MENU_ID
        })[0];// menu_data_object[109]
        // for decoding R2 EXACT url after converting const values
        const r2_base_url = r2_decode_url(menu_data.r2_url, this.props.patient_id, this.props.company_id);

        // store r2 base url and page name in the store
        this.props.store_r2_data(r2_base_url, menu_data.name);

        // Form url pathname as an input for R6
        const pathname = r2_form_path(menu_data.name)

        this.props.history.push(`/R2/${pathname}`);
    }

    on_add_click(): any {
        this.props.parent_props
    }

    // Redirect to selected Financial report
    create_report_button_handler = (report_name,event) => {
        switch (report_name) {
            case "payment_log":
                 var report_id = 132;
                this.props.history.push(`/patient/report/report_interceptor`, {
                    report_id: report_id
                });
                session_storage.set("url_history", []);
                set_url_history();
                break;
            case "statement_log":
                var report_id = 30290;
                this.props.history.push(`/patient/report/report_interceptor`, {
                    report_id: report_id
                });
                session_storage.set("url_history", []);
                set_url_history();
                break;
            default:
        }
        session_storage.set("report_id", report_id);
        event.preventDefault();// Added in case of safari mac book, it reloads the browser.
    }
    on_patient_stmt_log_click(e): any {
        if (this.props.rsiB30132ConvertPatientStatementLog) {
            this.create_report_button_handler('statement_log', e);
        }
        else {
            this.on_patient_statement_log();
        }

    }
    render() {
        var hidden = {
            display: this.state.shown ? "none" : "block"
        }

        return (

            <div className="common-forms-view" id="statement_payment_tab">
                <div className="ui grid">
                    <div className="sixteen wide computer column">
                        <div className="accordion ui fluid styled common-accordion common-accordion-updated">
                            <div className="active title" onClick={this.toggle.bind(this)} >Statement and Payments<i tabIndex={0} onKeyDown={handle_click_on_enter} aria-hidden="true"
                                className={!this.state.shown ? "ellipsis angle up icon" : "ellipsis angle down icon"}></i></div>
                            <div className="content active" style={hidden}>
                                <Grid>
                                    <Grid.Column tablet={8} computer={8} textAlign="left">
                                        <label>Patient Statement Log</label>
                                    </Grid.Column>
                                    <Grid.Column tablet={8} computer={8} textAlign="left">
                                        <Button type='button' id="patient_statement_log" primary onClick={(e) => this.on_patient_stmt_log_click(e)} >Create Report</Button>
                                    </Grid.Column>
                                </Grid>
                                <Grid>
                                    <Grid.Column tablet={8} computer={8} textAlign="left">
                                        <label>Patient Payment Log</label>
                                    </Grid.Column>
                                    <Grid.Column tablet={8} computer={8} textAlign="left">
                                        <Button type='button' id="patient_payment_log" primary onClick={(e) => this.create_report_button_handler('payment_log', e)} >Create Report</Button>
                                    </Grid.Column>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        store_r2_data: store_r2_data
    }, dispatch)
}

//Get user and login details from store.
const mapStateToProps = (state) => {
    return {
        shared_details: state.shared_details,
        rsiB30132ConvertPatientStatementLog: get_lauch_darkley_key_value(state.launch_darkly, 'rsiB30132ConvertPatientStatementLog'),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StatementAndPaymentsComponent));
