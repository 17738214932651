import * as React from "react";
import { connect } from "react-redux";
import { Button, Dimmer, Form, Grid, Input, Loader, Header } from "semantic-ui-react";
import { toastr as toaster } from "react-redux-toastr";
import * as moment from "moment";
import GridView from "../../../shared/component/grid";
import { get_batch_task_details, get_batch_task_runs } from "../action/batch_tasks_action";
import { batch_tasks_configuration, batch_tasks_header_id, batch_task_card_columns } from "../batch_tasks_constants";
import {
    currencyFormatter,
    dateFormatter,
    messages,
    dateTimeFormatter,
    ReportEventAction,
    ReportEntityTypeId,
    IPayloadForBillingAudit,
    saveAuditPrintExport
} from "../../../reports/report_constants";
import { strip, get_columns, get_all_error } from "../../../shared/utility";
import ReportHeader from "../../../reports/component/report_header";
import ReportFooter from "../../../reports/component/report_footer";
import { error } from "util";
import { constants } from "os";
import { log_error } from "../../../shared/action/shared_action";
import { multi_grid_export, multi_grid_print, generateAuditDataEntryPayload } from "../../../reports/util/export_print_utility";
import { show_html_content_toaster, get_title, get_sub_title, print_card_template, export_card_content } from '../utility';

export class BatchTaskDetailComponent extends React.Component<any, any> {
    handle_navigation_after_last_element: any;
    is_mounted: boolean;

    constructor(props: any) {
        super(props);
        this.state = {
            loading_detail: true,
            loading_runs: true,
            task_runs_grid_visible: false,
            batch_details_grid_visible: false,
            batch_task_details: {}
        };
    }

    current_date: string;
    title: string;
    grid_width = 0;
    grid_header_height: number = 0;
    total_column_width: number = 0;
    task_runs_column_defs: any = [];
    batch_tasks = batch_tasks_configuration(false, this.props);
    report_data: any = { rows: [] };

    UNSAFE_componentWillMount = async () => {
        this.task_runs_column_defs = this.batch_tasks.task_runs_column_defs;       
    }

    componentDidMount = async () => {
        this.is_mounted = true;
        document.body.classList.add("reports");

        let task_id = this.props.match.params.batch_task_id
        let token = this.props.user_login_details.user_data.data.token_details.access_token;
        await get_batch_task_details(token, task_id).then((response) => {
            this.setState({
                loading_detail: false,
                batch_task_details: response.data.data,
                batch_details_grid_visible: true
            })
        });
        this.get_batch_task_runs_data(token, task_id);
    };

    componentWillUnmount = () => {
        document.body.classList.remove("reports");
        this.is_mounted = false;
        this.report_data = {};
    };

    // Get task runs data
    get_batch_task_runs_data = async (token, task_id) => {
        this.grid_width = document.getElementsByClassName("report-wrapper")[0].clientWidth;
        
        if (this.is_mounted) {
            this.setState({
                loading_runs: true,
                task_runs_grid_visible: false,
            });
        }

        await get_batch_task_runs(token, task_id).then(
            (response) => {
                if (response.data && response.data.data) {
                    response.data.data = this.row_data_formatter(response.data.data, this.task_runs_column_defs);
                    this.report_data["rows"] = response.data.data || [];
                    this.total_column_width = 0;
                    const grid_height = get_columns(this.report_data["rows"], this.task_runs_column_defs);
                    this.grid_header_height = grid_height.header_height;
                    this.total_column_width = grid_height.total_column_width;
                    if (this.is_mounted) {
                        this.setState({
                            loading_runs: false,
                            task_runs_grid_visible: true,
                            report_data: this.report_data,
                            is_process_disabled: false,
                            is_update_disabled: false,
                        });
                    }
                } else {
                    log_error(response.data.messages[0].message.indexOf("<br") > -1);
                    if (response.data.messages[0].message.indexOf("<br") > -1) {
                        const toastr_options = show_html_content_toaster(get_all_error(response.data.messages[0].message));
                        toaster.error("", toastr_options);
                    }
                    if (this.is_mounted) {
                        this.setState({
                            loading_runs: false,
                            is_process_disabled: true,
                            is_update_disabled: true
                        });
                    }
                }
            },
            (error) => {
                if (this.is_mounted) {
                    this.setState({
                        loading_runs: false,
                        is_process_disabled: true,
                        is_update_disabled: true
                    });
                }
                log_error(error);
                if (error.response.data) {
                    const toastr_options = show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error("", toastr_options);
                }
            }
        );
    };

    // FUnction use to format the grid data on load.
    row_data_formatter = (row_data, column_def) => {
        row_data.map((row) => {
            column_def.map((value, index) => {
                switch (value.type) {
                    case "date":
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined ? dateFormatter(row[value.field]) : "";
                        break;
                    case "datetime":
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined ? dateTimeFormatter(row[value.field]) : "";
                        break;
                    case "currency":
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined
                                ? currencyFormatter(row[value.field])
                                : currencyFormatter(0);
                        break;
                    case "number":
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined ? parseInt(row[value.field]) : 0;
                        break;
                    case "hyper_link":
                        row[`link_${value.field}`] = strip(row[value.field]);
                        break;
                    case "custom_enum":
                        if (value.field == "batch_type" || value.field == "program_type") {
                            row[value.field] = row[value.field];
                        }
                        if (value.field == "status") {
                            row[value.field] = row[value.field];
                        }
                        break;
                    default:
                        row[value.field] = row[value.field] != null || row[value.field] != undefined ? row[value.field] : "";
                }
                if (value.field == "status" && row[value.field] === "Payer ID Not Found") {
                }
            });

            return row;
        });
        return row_data;
    };
    get_grid_ref = (grid_params) => {
        this.setState({
            grid_params,
            report_rows_count: grid_params.api.getModel().getRowCount()
        });
        if (this.total_column_width > this.grid_width) {
            this.task_runs_column_defs.filter((item, index) => {
                grid_params.columnApi.setColumnWidth(item.field, item["width"], false);
            });
        }
    };

    // Used to create the title for export and print.
    set_title = (separter) => {
        this.current_date = moment(new Date()).format("MM/DD/YYYY") + " " + moment(new Date()).format("hh:mm:ss A");
        return (this.title =
            "Batch Tasks" +
            separter +
            this.current_date +
            separter +
            (this.state.report_rows_count == 1
                ? this.state.report_rows_count + " record"
                : this.state.report_rows_count + " records") +
            separter +
            separter);
    };

    // Function calls on initialization of export report data
    on_export_button = () => {
        this.title = get_title('Batch Tasks', '\n', export_card_content(batch_task_card_columns, this.state.batch_task_details));
        let row_count = this.state.grid_params.api.rowModel.rowsToDisplay.length;
        let count = row_count == 1 ? row_count + ' record' : row_count + ' records';
        let sub_title = get_sub_title('Task Runs', '\n', count);

        let titles = [`${this.title}${sub_title}`];
        let grid_params = [this.state.grid_params];
        multi_grid_export(grid_params, titles, "Batch Tasks", ["batch_run_id"]);
        grid_params = [];
        this.saveReportEventAction(ReportEventAction.Export);
    };

    // Function calls on initialization of Print report data
    on_print_button = () => {
        this.title = get_title('Batch Tasks', '<br/>', print_card_template(batch_task_card_columns, this.state.batch_task_details));
        let row_count = this.state.grid_params.api.rowModel.rowsToDisplay.length;
        let count = row_count == 1 ? row_count + ' record' : row_count + ' records';
        let sub_title = get_sub_title('Task Runs', '<br/>', count);

        let title = [`${this.title}${sub_title}`];
        let grid_params = [this.state.grid_params];
        multi_grid_print(grid_params, title, "Batch Tasks", ["batch_run_id"]);
        grid_params = [];
        this.saveReportEventAction(ReportEventAction.Print);
    };

    // Function calls save billing audit on Export or Print
    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;

        const payload: IPayloadForBillingAudit = {
            reportId: String(this.props.user_login_details.user_data.data.user_id),
            contextTitle: `${this.batch_tasks.title} Details - ${ReportEventAction[eventActionId]}`,
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: ReportEntityTypeId.bat_task,
            data: { Records: this.state.grid_params.api.rowModel.rowsToDisplay.length }
        };

        const reqBody = generateAuditDataEntryPayload(payload);
        saveAuditPrintExport(reqBody, accessToken);
    }

    render_batch_task = () => {
        if (this.state.batch_details_grid_visible) {
            return (<Form><Grid style={{ padding: 15 }}>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                        <label>Task</label>
                        <div className="values">
                            {this.state.batch_task_details.name}
                        </div>
                    </Form.Field>
                </Grid.Column>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                        <label>Code</label>
                        <div className="values">
                            {this.state.batch_task_details.task_code}
                        </div>
                    </Form.Field>
                </Grid.Column>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                        <label>Class</label>
                        <div className="values">
                            {this.state.batch_task_details.class_name}
                        </div>
                    </Form.Field>
                </Grid.Column>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                        <label>Status</label>
                        <div className="values">
                            {this.state.batch_task_details.status}
                        </div>
                    </Form.Field>
                </Grid.Column>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                        <label>Last Run Date</label>
                        <div className="values">
                            {dateTimeFormatter(this.state.batch_task_details.last_run_dt)}
                        </div>
                    </Form.Field>
                </Grid.Column>
            </Grid></Form>);
        }
        return null;
    }

    render() {
        return (
            <div className={"common-forms-add"}>
                <Dimmer active={this.state.loading_detail || this.state.loading_runs}>
                    <Loader size="massive">Loading</Loader>
                </Dimmer>
                <div className={"common-forms-search report-framework batch-tasks-program"} style={{ paddingRight: 0 }}>
                    <ReportHeader title={this.batch_tasks.title} />
                    <div style={{ overflow: "auto", paddingRight: 20, flex: "1 1 0" }}>
                        <div
                            id="report-scrollable-area"
                            className="report-wrapper"
                            style={this.state.batch_details_grid_visible ? { flex: 1 } : {}}
                        >
                            {this.render_batch_task()}
                        </div>
                        {this.state.task_runs_grid_visible && (
                            <React.Fragment>
                                <div style={{ border: " 1px solid #cccccc", background: " #fff", marginTop: 20 }}>
                                    <div className="ui grid headerGrid" style={{ margin: 0, paddingTop: 5 }}>
                                        <div className="sixteen wide computer column">
                                            <h3 className="ui header left aligned">Task Runs</h3>
                                        </div>
                                    </div>
                                    <GridView
                                        id={this.batch_tasks.batch_task_details_grid_id}
                                        row={this.report_data.rows}
                                        headerHeight={this.grid_header_height}
                                        get_grid_ref={this.get_grid_ref}
                                        column={this.task_runs_column_defs}
                                        enableColResize={true}
                                        emptyMessage={messages.no_records_found}
                                        headerIdForTabNavigation={batch_tasks_header_id}
                                        suppressSizeToFit={true}
                                        gridAutoHeight={true}
                                        paginationPageSize={10}
                                        isPagination={true}
                                        paginationMessage={' '}
                                    />
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                    <div
                        className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area"
                        id="applicationFooterSticky"
                        style={{ paddingRight: 25 }}
                    >
                        <ReportFooter
                            on_print_button={this.on_print_button}
                            on_export_button={this.on_export_button}
                            export_disabled={this.state.is_export_disabled}
                            print_disabled={this.state.is_print_disabled}
                        />
                    </div>
                </div>
            </div>);
    }
}


// Get user and login details from store.
const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details
    };
};

export default connect(mapStateToProps)(BatchTaskDetailComponent);
