import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Grid } from "semantic-ui-react";
import * as global_constants from '../../global_constants';
import { export_grid_data, generateAuditDataEntryPayload, print_grid_data } from '../../reports/util/export_print_utility';
import GridView from '../../shared/component/grid';
import { LoaderComponent } from '../../shared/component/loading_component';
import { set_focus_to_app_header } from './../../shared/tab_navigation_utility';
import { custom_date_format, get_all_error, get_columns,  } from './../../shared/utility';
import { get_charge_edit_rules_list } from './../action/charge_action';
import { charge_edit_rule_configuration } from './../charge_constant';
import * as ReportConstants from '../../reports/report_constants';
import { providerBillingSettingsFeatures,  } from '../../admin/constants/constants';

export class ChargeEditRuleComponent extends React.Component<any, any> {
    [x: string]: any;
    _is_mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            charge_rule_list: [],
            show_delete_warning_charge_popup: false,
            show_grid: false,
            rows_count: 0,
            grid_params: null
        }

        this.grid_reference = React.createRef();
    }
    total_column_width: number = 0;
    grid_header_height: number = 0;
    current_date_time: string = '';
    reportId: string = '0';

    componentDidMount = () => {
        this._is_mounted = true;
        document.body.classList.add('admin-framework');
        this.current_date_time = custom_date_format(new Date(), global_constants.date_format["mm/dd/yyyy h:MM:ss TT"]);
        this.get_charge_edit_rules_list();
    };

    componentWillUnmount = () => {
        this._is_mounted = false;
        document.body.classList.remove('admin-framework');
    }

    //Get Data from api's
    get_charge_edit_rules_list = async () => {
        if (this._is_mounted) {
            this.setState({
                loading: true,
                selected_charge_list: []
            });
        }
        this.grid_width = document.getElementsByClassName("admin-wrapper")[0].clientWidth;
        await get_charge_edit_rules_list().then(response => {
            if (response.data.data && response.data.data.length > 0) {
                const grid_height = get_columns(response.data.data, charge_edit_rule_configuration.column_defs);
                this.grid_header_height = grid_height.header_height + 10;
                this.total_column_width = grid_height.total_column_width;
                if (this._is_mounted) {
                    this.setState({
                        charge_rule_list: response.data.data,
                        loading: false,
                        rows_count: response.data.data.length,
                        show_grid: true
                    });
                }
            }
            else {
                if (this._is_mounted) {
                    this.setState({
                        charge_rule_list: [],
                        loading: false,
                        show_grid: true
                    });
                }
            }

        }, error => {
            const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
            toastr.error('', toastr_options);
            if (this._is_mounted) {
                this.setState({
                    charge_rule_list: [],
                    loading: false,
                    show_grid: true
                });
            }
        });
    }

    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }
    get_grid_ref = (grid_params) => {
        if (this._is_mounted) {
            this.setState({
                grid_params,
                rows_count: grid_params.api.getModel().getRowCount()
            });
        }
        if (this.total_column_width > this.grid_width) {
            charge_edit_rule_configuration.column_defs.filter((item, index) => {
                grid_params.columnApi.setColumnWidth(item.field, item["width"], false);
            });
        }
    };

    on_filter_button_click = () => {
        // Update row count
        this.setState({
            rows_count: this.state.grid_params.api.getModel().getRowCount() //this.state.grid_params.api.getModel().getRowCount()
        });
    };

    export_charge_rules = () => {
        export_grid_data(this.state.grid_params, "Correct Coding Initiative \n" + this.current_date_time + " \n", "Correct Coding Initiative")
        this.saveReportEventAction(ReportConstants.ReportEventAction.Export);
    }
    print_charge_edit_rules = () => {
        print_grid_data(this.state.grid_params, "Correct Coding Initiative<br>" + this.current_date_time + "<br><br>", "Correct Coding Initiative", "charge_edit_rule_print_btn", false)
        this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
    }
    
    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;
        const payload: ReportConstants.IPayloadForBillingAudit = {
            reportId: this.reportId,
            contextTitle: "Correct Coding Initiative" + " - " + ReportConstants.ReportEventAction[eventActionId],
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: ReportConstants.ReportEntityTypeId.ChargeAudit,
            data: { Records: this.state.rows_count }
        }
        const reqBody = generateAuditDataEntryPayload(payload);
        ReportConstants.saveAuditPrintExport(reqBody, accessToken);
    };

    render() {
        return (
            <LoaderComponent loading={this.state.loading}>
                <div className={'admin-wrapper'} style={!this.state.show_grid ? { paddingBottom: 20 } : {}}>
                    <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                        <Grid.Column computer={16}>
                            <h3 className='ui header left aligned' dangerouslySetInnerHTML={{ __html: "Correct Coding Initiative" }} />
                        </Grid.Column>
                    </Grid>
                    <div id='admin-scrollable-area' className='wrapper' style={this.state.show_grid ? { flex: 1 } : {}}>
                        <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container'>
                            <Grid style={{ marginTop: '-1rem', marginBottom: 0 }}>
                                <Grid.Column tablet={5} computer={4} textAlign='left'>
                                    <p style={{ fontSize: '16px' }}>{this.current_date_time}</p>
                                </Grid.Column>
                                <Grid.Column tablet={5} computer={8} textAlign='center'>
                                    {
                                        <p style={{ fontSize: '16px', minHeight: 22 }}>
                                            {`${this.state.rows_count} ${
                                                this.state.rows_count == 1 ? ' record shown' : ' records shown'
                                                }`}
                                        </p>
                                    }
                                </Grid.Column>
                                <Grid.Column tablet={2} computer={4} textAlign='right' />
                            </Grid>
                        </div>
                        {this.state.show_grid &&
                            <GridView
                                id={'charge_edit_rules_grid'}
                                onRef={instance => { this.grid_reference = instance; }}
                                style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
                                wrapperStyle={{ width: '100%', display: 'flex', flex: 1 }}
                                //wrapperStyle={{ width: '100%', height: '100%', display: 'flex' }}
                                // gridAutoHeight={true}
                                suppressSizeToFit={true}
                                emptyMessage={global_constants.constants.no_records_found_message}
                                enableColResize={true}
                                row={this.state.charge_rule_list}
                                column={charge_edit_rule_configuration.column_defs}
                                headerHeight={this.grid_header_height}
                                get_grid_ref={this.get_grid_ref}
                                on_filter_button_click={() => {
                                    this.on_filter_button_click();
                                }}
                            />
                        }
                    </div>
                    {this.state.show_grid &&
                        (this.state.charge_rule_list.length > 0 &&
                            <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area" id="applicationFooterSticky">
                                <Button id="charge_edit_rule_export_btn" type='button' basic onClick={this.export_charge_rules} >Export</Button>
                                <Button id="charge_edit_rule_print_btn" onKeyDown={set_focus_to_app_header} type='button'
                                    primary onClick={() => this.print_charge_edit_rules()}>Print</Button>
                            </div>
                        )
                    }
                </div>

            </LoaderComponent>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details
        //patient_id: state.patient_details && state.patient_details.patient_header && state.patient_details.patient_header.data && state.patient_details.patient_header.data.id || ''
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch)
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChargeEditRuleComponent));