import { payment_adjustments } from '../payment_constants'

const initial_state = {
    adjustment_qualifier: [],
    reason_code: [],
    adjustment_code: []
};

export default function (state = initial_state, { type, payload }) {
    switch (type) {
        case payment_adjustments.qualifier:
            return {
                ...state, 
                adjustment_qualifier: payload
            };
            break;
        case payment_adjustments.reason:
            return {
                ...state,
                reason_code: payload
            };
            break;
        case payment_adjustments.code:
            return {
                ...state,
                adjustment_code: payload
            };
            break;
        default:
            return state;
    }
}