/*
 *This reducer is persisted i.e - Any data in this reducer will be stored in the local storage  
 */


import * as shared_constants from '../shared_constants';
import * as patient_constants from './../../patient/patient_constants';

export default function (state = [], { type, payload, error }) {
    switch (type) {

        case shared_constants.R2_PAGE_INFORMATION:
            return { ...state, ["r2_page_data"]: payload };
        // Single patient details
        //case patient_constants.GET_PATIENT_DETAILS:
        //    return { ...state, ["selected_patient"]: payload.data };
        default:
            return state
    }
}