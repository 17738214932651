import * as global_constants from '../../global_constants';
import { log_error } from '../../shared/action/shared_action';
import * as login_constants from '../../login/login_constants';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request';

export const user_logout = () => {
    const url = `${global_constants.base_end_points_v2}${global_constants.end_points.oauth_urls.logout}`;

    let request = new Request();
    
    let request_config = new RequestConfig({
        'url': url,
        'method': 'post'
    })

    return dispatch => request.make(request_config).then(
        response => dispatch({
            type: login_constants.USER_LOGOUT,
            payload: response
        }),
        error => {
            dispatch({
                type: login_constants.USER_LOGOUT,
                payload: error
            });
            log_error(error)
        }
    );
}
