import * as React from 'react';
import { parse_JSON } from './utils';

interface IProps {
    id?: string,
    name?: string,
    onChange?: Function,
    onSort?: Function,
    onClick?: Function,
    onRef?: any,
}

class GroupPanelComponent extends React.Component<IProps, any>{

    constructor(props) {
        super(props);
        this.state = {
            fields: [],
            groupItems: [],
            sortModel:[]
        }
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    onDragOver(event) {
        event.preventDefault();
    }

    onDrop(event) {
        event.preventDefault();
        var userAgent = window.navigator.userAgent;
        var isIE = userAgent.indexOf('Trident/') >= 0;
        var textData = event.dataTransfer.getData(isIE ? 'text' : 'text/plain');
        let data = parse_JSON(textData);
        if (data && Object.keys(data).length !== 0) {
            let data = parse_JSON(textData);
            const sort_field = `${data.field}`.replace(/link_/g, '');
            if (this.state.fields.indexOf(data.field) === -1) {
                this.setState(prevState => ({
                    fields: [...prevState.fields, data.field],
                    groupItems: [...prevState.groupItems, data],
                    sortModel: [...prevState.sortModel, { colId: sort_field, sort: 'asc' }]
                }), () => {
                    this.props.onChange(this.state.fields);
                    this.props.onSort([...this.state.sortModel]);
                });
            }
        }
    }

    onDragStart(dragEvent) {
        var userAgent = window.navigator.userAgent;
        var isIE = userAgent.indexOf('Trident/') >= 0;
        let data = dragEvent.target.dataset;
        dragEvent.dataTransfer.setData(isIE ? 'text' : 'text/plain', JSON.stringify(data));
    }

    handleDrop(event) {
        event.preventDefault();
        var userAgent = window.navigator.userAgent;
        var isIE = userAgent.indexOf('Trident/') >= 0;
        var textData = event.dataTransfer.getData(isIE ? 'text' : 'text/plain');
        let data_source = parse_JSON(textData);
        if (data_source && Object.keys(data_source).length !== 0) {
            if (data_source && data_source.id) {
                let data_destination = event.currentTarget.dataset;
                if (data_source.field != data_destination.field) {
                    let groups = this.state.groupItems.filter(value => value.field != data_source.field);
                    let index = groups.findIndex(value => value.field == data_destination.field);
                    if (index != -1) {
                        let position = (data_source.id < data_destination.id) ? index + 1 : index;
                        groups.splice(position, 0, data_source);
                        this.setState(prevState => ({
                            groupItems: groups,
                            fields: groups.map(value => (value.field))
                        }), () => {
                            this.props.onChange(this.state.fields);
                        });
                    }
                }
            }
        }
    }

    handle_tag_click = (data, e) => {
        if (this.props.onClick && e.target.className != 'ag-column-drop-cell-button') {
            const sort_field = `${data.field}`.replace(/link_/g, '');
            this.props.onClick({ ...data, field: sort_field }, e);
        }
    }

    set_sort_model = (sortModel = []) => {
        this.setState({ sortModel });
    }

    remove = (field) => {
        const sort_field = `${field}`.replace(/link_/g, '');
        this.setState(prevState => ({
            fields: prevState.fields.filter(value => (value !== field)),
            groupItems: prevState.groupItems.filter(data => (data.field !== field)),
            sortModel: prevState.sortModel.filter(data => (data.colId !== sort_field))
        }), () => {
            this.props.onChange(this.state.fields);
            this.props.onSort([...this.state.sortModel]);
        });
    }

    render_sort_icon = (field) => {
        let sort = null;
        const sort_field = `${field}`.replace(/link_/g, '');
        this.state.sortModel.map((model) => {
            if (model.colId == sort_field) {
                sort = model.sort ? model.sort == 'asc' ? 'ascending' : 'descending' : null;
            }
        });
        return (<i aria-hidden="true" className={`sort small icon ${sort}`}></i>)
    }

    render_items = () => {
        return (
            <span
                onDragEnter={this.onDragOver.bind(this)}
                style={{ float: 'none', display: 'block' }}
                className='itemsHolder'
            >
                {this.state.groupItems.map((item, index) => (
                    <React.Fragment key={item.field}>
                        {index > 0 && <span className='ag-icon ag-icon-small-right'></span>}
                        <span
                            className='ag-column-drop-cell'
                            key={item.field}
                            draggable={true}
                            onDragStart={this.onDragStart.bind(this)}
                            onDrop={this.handleDrop.bind(this)}
                            onClick={(e) => this.handle_tag_click(item, e)}
                            data-field={item.field}
                            data-name={item.name}
                            data-id={index}
                            id={index}
                        >
                            <span ref='eDragHandle' className='ag-column-drag'>
                                <span className='ag-icon ag-icon-grip'></span>
                            </span>
                            <span ref='eText' className='ag-column-drop-cell-text'>
                                {item.name}
                                {this.render_sort_icon(item.field)}
                            </span>
                            <span ref='btRemove' className='ag-column-drop-cell-button' onClick={e => this.remove(item.field)}>
                                <span className='ag-icon ag-icon-cancel'></span>
                            </span>
                        </span>
                    </React.Fragment>
                ))}
            </span>
        );
    };

    render_label = () => {
        if (this.state.groupItems.length > 0) {
            return (<span className='grouped-by-label'>Grouped by:</span>)
        }

        return (<span className='ag-column-drop-empty-message'>Drag a column header and drop it here to group by that column</span>);
    }

    render() {
        return (
            <div className='ag-column-drop ag-unselectable ag-column-drop-horizontal ag-column-drop-row-group'
                onDragOver={this.onDragOver.bind(this)}
                onDrop={this.onDrop.bind(this)}
            >
                {this.render_label()}
                <div className='ag-column-drop-list' id='eGroupContainer'>
                    {this.render_items()}
                </div>
            </div>
        );
    }
}

export default GroupPanelComponent;
