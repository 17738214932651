import { compressToUTF16, decompressFromUTF16 } from './lz-string';
import { build_version } from '../global_constants';
import { generate_guid } from './utility';

// To add to local storage
export const set = (key, value) => {
    if (value == undefined) {
        return false;
    }
    if (!sessionStorage.getItem('sessionId')) {
        sessionStorage.setItem('sessionId', generate_guid());
    }
    const compressValue = compressToUTF16(JSON.stringify(value));
    localStorage.setItem(key + sessionStorage.getItem('sessionId'), compressValue);
};

// Read that value back from local storage
export const get = (key) => {
    if (sessionStorage.getItem('sessionId') && localStorage.getItem(key + sessionStorage.getItem('sessionId'))) {
        const string = localStorage.getItem(key + sessionStorage.getItem('sessionId'));
        const decompressedValue = decompressFromUTF16(string);
        return JSON.parse(decompressedValue);
    } else {
        return undefined;
    }
};

// To remove a local storage
export const remove = (key) => {
    localStorage.removeItem(key + sessionStorage.getItem('sessionId'));
};

// Removes all local storage
export const clear_all = () => {
    //  localStorage.clear();
    // const persist_root = get('persist:root');
    Object.keys(localStorage).forEach(function (key) {
        if (key.indexOf(sessionStorage.getItem('sessionId')) > -1) {
            localStorage.removeItem(key);
        }
    });
    set('version', build_version);
    // set('persist:root', persist_root);
};

/**
 * Method count the key present in the local storage by matching first 5 text of the key, and returns the count.
 * @param keyName
 */
export const keyCountUsingKeyNameMatch = (keyName) => {
    let keyCount = 0;
    Object.keys(localStorage).forEach(function (key) {
        if (key.substring(0, 5) == keyName) {
            if (
                localStorage.getItem(key) != undefined &&
                localStorage.getItem(key) == sessionStorage.getItem('sessionId')
            )
                keyCount++;
        }
    });
    return keyCount;
};

export const deleteAllCookies = () => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
};

export const deleteAllCookiesWithPath = () => {
    const cookies = document.cookie.split('; ');
    for (let c = 0; c < cookies.length; c++) {
        const d = window.location.hostname.split('.');
        while (d.length > 0) {
            const cookieBase =
                encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
                '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
                d.join('.') +
                ' ;path=';
            const p = location.pathname.split('/');
            document.cookie = cookieBase + '/';
            while (p.length > 0) {
                document.cookie = cookieBase + p.join('/');
                p.pop();
            }
            d.shift();
        }
    }
};
