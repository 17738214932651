import * as React from "react";
import { connect } from "react-redux";
import { Button, Dimmer, Form, Grid, Loader } from "semantic-ui-react";
import GridView from "../../../shared/component/grid";
import { toastr as toaster } from "react-redux-toastr";
import * as moment from "moment";
import { get_batch_tasks_program_details, get_batch_tasks_program_runs, get_batch_tasks_program_logs } from "../action/batch_tasks_action";
import { batch_tasks_header_id, programs_configuration, batch_program_card_columns } from "../batch_tasks_constants";
import { currencyFormatter, dateFormatter, messages, dateTimeFormatter } from "../../../reports/report_constants";
import { strip, get_columns, get_all_error } from "../../../shared/utility";
import ReportHeader from "../../../reports/component/report_header";
import ReportFooter from "../../../reports/component/report_footer";
import { log_error } from "../../../shared/action/shared_action";
import { multi_grid_export, multi_grid_print } from "../../../reports/util/export_print_utility";
import { show_html_content_toaster, get_title, get_sub_title, print_card_template, export_card_content } from '../utility';

export class ProgramDetailComponent extends React.Component<any, any> {
    handle_navigation_after_last_element: any;
    is_mounted: boolean;

    constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            loading_program_run: true,
            loading_program_log: true,
            program_run_grid_visible: false,
            program_log_grid_visible: false,
            program_details_grid_visible: false,
            program_details: {}
        };
    }

    current_date: string;
    title: string;
    grid_width = 0;
    grid_width_log = 0;
    grid_header_height: number = 0;
    grid_header_height_log: number = 0;
    total_column_width: number = 0;
    total_column_width_log: number = 0;
    batch_tasks = programs_configuration(false, this.props);
    program_runs_column_defs: any = [];
    program_logs_column_defs: any = [];
    report_data: any = {
        program_run_rows: [],
        program_logs_rows: []
    };

    UNSAFE_componentWillMount = async () => {
        this.program_runs_column_defs = this.batch_tasks.program_runs_column_defs;
        this.program_logs_column_defs = this.batch_tasks.program_logs_column_defs;
        this.total_column_width = 0;
        this.total_column_width = 0;
    }

    componentDidMount = async () => {
        this.is_mounted = true;
        document.body.classList.add("reports");

        let program_id = this.props.match.params.program_id
        let token = this.props.user_login_details.user_data.data.token_details.access_token;
        await get_batch_tasks_program_details(token, program_id).then((response) => {
            this.setState({
                loading: false,
                program_details_grid_visible: true,
                program_details: response.data.data,
            })
        });
        this.get_program_runs_data(token, program_id);
        this.get_program_logs_data(token, program_id);
    };

    componentWillUnmount = () => {
        document.body.classList.remove("reports");
        this.is_mounted = false;
        this.report_data = {};
    };

    // Get program runs data
    get_program_runs_data = async (token, program_id) => {
        this.grid_width = document.getElementsByClassName("report-wrapper")[0].clientWidth;
        if (this.is_mounted) {
            this.setState({
                loading_program_run: true,
                program_run_grid_visible: false,
            });
        }

        await get_batch_tasks_program_runs(token, program_id).then(
            (response) => {
                if (response.data && response.data.data) {
                    response.data.data = this.row_data_formatter(response.data.data, this.program_runs_column_defs);
                    this.report_data["program_run_rows"] = response.data.data || [];
                    const grid_height = get_columns(this.report_data["program_run_rows"], this.program_runs_column_defs);
                    this.grid_header_height = grid_height.header_height;
                    this.total_column_width = grid_height.total_column_width;
                    if (this.is_mounted) {
                        this.setState({
                            loading_program_run: false,
                            program_run_grid_visible: true,
                            report_data: this.report_data,
                        });
                    }
                } else {
                    log_error(response.data.messages[0].message.indexOf("<br") > -1);
                    if (response.data.messages[0].message.indexOf("<br") > -1) {
                        const toastr_options = show_html_content_toaster(get_all_error(response.data.messages[0].message));
                        toaster.error("", toastr_options);
                    }
                    if (this.is_mounted) {
                        this.setState({
                            loading_program_run: false,
                            is_process_disabled: true,
                            is_update_disabled: true
                        });
                    }
                }
            },
            (error) => {
                if (this.is_mounted) {
                    this.setState({
                        loading_program_run: false,
                        is_process_disabled: true,
                        is_update_disabled: true
                    });
                }
                log_error(error);
                if (error.response.data) {
                    const toastr_options = show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error("", toastr_options);
                }
            }
        );
    };
    // Get program runs data
    get_program_logs_data = async (token, program_id) => {
        this.grid_width_log = document.getElementsByClassName("report-wrapper")[0].clientWidth;
        if (this.is_mounted) {
            this.setState({
                loading_program_log: true,
                program_log_grid_visible: false,
            });
        }

        await get_batch_tasks_program_logs(token, program_id).then(
            (response) => {
                if (response.data && response.data.data) {
                    response.data.data = this.row_data_formatter(response.data.data, this.program_logs_column_defs);
                    this.report_data["program_log_rows"] = response.data.data || [];
                    const grid_height = get_columns(this.report_data["program_log_rows"], this.program_logs_column_defs);
                    this.grid_header_height_log = grid_height.header_height;
                    this.total_column_width_log = grid_height.total_column_width;
                    if (this.is_mounted) {
                        this.setState({
                            loading_program_log: false,
                            program_log_grid_visible: true,
                            report_data: this.report_data,
                        });
                    }
                } else {
                    log_error(response.data.messages[0].message.indexOf("<br") > -1);
                    if (response.data.messages[0].message.indexOf("<br") > -1) {
                        const toastr_options = show_html_content_toaster(get_all_error(response.data.messages[0].message));
                        toaster.error("", toastr_options);
                    }
                    if (this.is_mounted) {
                        this.setState({
                            loading_program_log: false,
                            is_process_disabled: true,
                            is_update_disabled: true
                        });
                    }
                }
            },
            (error) => {
                if (this.is_mounted) {
                    this.setState({
                        loading_program_log: false,
                        is_process_disabled: true,
                        is_update_disabled: true
                    });
                }
                log_error(error);
                if (error.response.data) {
                    const toastr_options = show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error("", toastr_options);
                }
            }
        );
    };

    // FUnction use to format the grid data on load.
    row_data_formatter = (row_data, column_def) => {
        row_data.map((row) => {
            column_def.map((value, index) => {
                switch (value.type) {
                    case "date":
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined ? dateFormatter(row[value.field]) : "";
                        break;
                    case "datetime":
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined ? dateTimeFormatter(row[value.field]) : "";
                        break;
                    case "currency":
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined
                                ? currencyFormatter(row[value.field])
                                : currencyFormatter(0);
                        break;
                    case "number":
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined ? parseInt(row[value.field]) : 0;
                        break;
                    case "hyper_link":
                        row[`link_${value.field}`] = strip(row[value.field]);
                        break;
                    case "custom_enum":
                        if (value.field == "batch_type" || value.field == "program_type") {
                            row[value.field] = row[value.field];
                        }
                        if (value.field == "status") {
                            row[value.field] = row[value.field];
                        }
                        break;
                    default:
                        row[value.field] = row[value.field] != null || row[value.field] != undefined ? row[value.field] : "";
                }
                if (value.field == "status" && row[value.field] === "Payer ID Not Found") {
                }
            });

            return row;
        });
        return row_data;
    };
    get_grid_ref_program_runs = (grid_params) => {
        this.setState({
            grid_params,
            report_rows_count: grid_params.api.getModel().getRowCount()
        });

        if (this.total_column_width > this.grid_width) {
            this.program_runs_column_defs.filter((item, index) => {
                grid_params.columnApi.setColumnWidth(item.field, item["width"], false);
            });
        }
    };
    get_grid_ref_program_logs = (grid_params) => {
        this.setState({
            grid_params_logs: grid_params
        });
        if (this.total_column_width_log > this.grid_width_log) {
            this.program_logs_column_defs.filter((item, index) => {
                grid_params.columnApi.setColumnWidth(item.field, item["width"], false);
            });
        }
    };

    // Used to create the title for export and print.
    set_title = (separter) => {
        this.current_date = moment(new Date()).format("MM/DD/YYYY") + " " + moment(new Date()).format("hh:mm:ss A");
        return (this.title =
            "Batch Program" +
            separter +
            this.current_date +
            separter +
            (this.state.report_rows_count == 1
                ? this.state.report_rows_count + " record"
                : this.state.report_rows_count + " records") +
            separter +
            separter);
    };

    // Function calls on initialization of export report data
    on_export_button = () => {
        this.title = get_title('Batch Program', '\n', export_card_content(batch_program_card_columns, this.state.program_details));
        let row_count = this.state.grid_params.api.rowModel.rowsToDisplay.length;
        let count = row_count == 1 ? row_count + ' record' : row_count + ' records';
        let title_program_runs = get_sub_title('Task Runs', '\n', count);
        let title = `${this.title}${title_program_runs}`;

        let row_count_logs = this.state.grid_params_logs.api.rowModel.rowsToDisplay.length;
        let count_logs = row_count_logs == 1 ? row_count_logs + ' record' : row_count_logs + ' records';
        let title_program_logs = get_sub_title('\n\nProgram Logs', '\n', count_logs);

        let titles = [title, title_program_logs];
        let grid_params = [this.state.grid_params, this.state.grid_params_logs];
        multi_grid_export(grid_params, titles, "Batch Program", ["batch_run_id","batch_log_id"]);
        grid_params = [];
    };

    // Function calls on initialization of Print report data
    on_print_button = () => {
        this.title = get_title('Batch Program', '<br/>', print_card_template(batch_program_card_columns, this.state.program_details));
        let row_count = this.state.grid_params.api.rowModel.rowsToDisplay.length;
        let count = row_count == 1 ? row_count + ' record' : row_count + ' records';
        let title_program_runs = get_sub_title('Task Runs', '<br/>', count);
        let title = `${this.title}${title_program_runs}`;

        let row_count_logs = this.state.grid_params_logs.api.rowModel.rowsToDisplay.length;
        let count_logs = row_count_logs == 1 ? row_count_logs + ' record' : row_count_logs + ' records';
        let title_program_logs = get_sub_title('<br/><br/>Program Logs', '<br/>', count_logs);
     
        let titles = [title, title_program_logs];
        let grid_params = [this.state.grid_params, this.state.grid_params_logs];
        multi_grid_print(grid_params, titles, "Batch Program", ["batch_run_id", "batch_log_id"]);
        grid_params = [];
    };

    render_program = () => {
        if (this.state.program_details_grid_visible) {
            return (<Form><Grid style={{ padding: 15 }}>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                        <label>Start</label>
                        <div className="values">
                            {dateTimeFormatter(this.state.program_details.start_dt)}
                        </div>
                    </Form.Field>
                </Grid.Column>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                        <label>End</label>
                        <div className="values">
                            {dateTimeFormatter(this.state.program_details.end_dt)}
                        </div>
                    </Form.Field>
                </Grid.Column>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                        <label>Update</label>
                        <div className="values">
                            {dateTimeFormatter(this.state.program_details.update_dt)}
                        </div>
                    </Form.Field>
                </Grid.Column>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                        <label>Status</label>
                        <div className="values">
                            {this.state.program_details.status}
                        </div>
                    </Form.Field>
                </Grid.Column>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                        <label>Type</label>
                        <div className="values">
                            {this.state.program_details.program_type}
                        </div>
                    </Form.Field>
                </Grid.Column>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                        <label>Machine</label>
                        <div className="values">
                            {this.state.program_details.machine_name}
                        </div>
                    </Form.Field>
                </Grid.Column>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                        <label>Thread</label>
                        <div className="values">
                            {this.state.program_details.managed_thread_id}
                        </div>
                    </Form.Field>
                </Grid.Column>
                <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                        <label>User</label>
                        <div className="values">
                            {this.state.program_details.user_name}
                        </div>
                    </Form.Field>
                </Grid.Column>
                <Grid.Column tablet={16} computer={16}>
                    <Form.Field>
                        <label>Path</label>
                        <div className="values">
                            {this.state.program_details.program_path}
                        </div>
                    </Form.Field>
                </Grid.Column>

            </Grid></Form>);
        }
        return null;
    }

    render() {
        return (
            <div className={"common-forms-add"}>
                <Dimmer active={this.state.loading || this.state.loading_program_run || this.state.loading_program_log}>
                    <Loader size="massive">Loading</Loader>
                </Dimmer>
                <div className={"common-forms-search report-framework batch-tasks-program"} style={{ paddingRight: 0 }}>
                    <ReportHeader title={this.batch_tasks.title} />
                    <div style={{ overflow: "auto", paddingRight: 20, flex: "1 1 0" }}>
                        <div
                            id="report-scrollable-area"
                            className="report-wrapper"
                            style={this.state.program_details_grid_visible ? { flex: 1 } : {}}
                        >
                            {this.render_program()}
                        </div>

                        {this.state.program_run_grid_visible && (
                            <div style={{ border: " 1px solid #cccccc", background: " #fff", marginTop: 20 }}>
                                <div className="ui grid headerGrid" style={{ margin: 0, paddingTop: 5 }}>
                                    <div className="sixteen wide computer column">
                                        <h3 className="ui header left aligned">Program Runs</h3>
                                    </div>
                                </div>
                                <GridView
                                    id={this.batch_tasks.program_run_grid}
                                    row={this.report_data.program_run_rows}
                                    headerHeight={this.grid_header_height}
                                    get_grid_ref={this.get_grid_ref_program_runs}
                                    column={this.program_runs_column_defs}
                                    enableColResize={true}
                                    emptyMessage={messages.no_records_found}
                                    headerIdForTabNavigation={batch_tasks_header_id}
                                    suppressSizeToFit={true}
                                    gridAutoHeight={true}
                                    paginationPageSize={10}
                                    isPagination={true}
                                    paginationMessage={' '}
                                />
                            </div>
                        )}
                        {this.state.program_log_grid_visible && (
                            <div style={{ border: " 1px solid #cccccc", background: " #fff", marginTop: 20 }}>
                                <div className="ui grid headerGrid" style={{ margin: 0, paddingTop: 5 }}>
                                    <div className="sixteen wide computer column">
                                        <h3 className="ui header left aligned">Program Logs</h3>
                                    </div>
                                </div>
                                <GridView
                                    id={this.batch_tasks.program_log_grid}
                                    row={this.report_data.program_log_rows}
                                    headerHeight={this.grid_header_height_log}
                                    get_grid_ref={this.get_grid_ref_program_logs}
                                    column={this.program_logs_column_defs}
                                    enableColResize={true}
                                    emptyMessage={messages.no_records_found}
                                    headerIdForTabNavigation={batch_tasks_header_id}
                                    suppressSizeToFit={true}
                                    gridAutoHeight={true}
                                    paginationPageSize={10}
                                    isPagination={true}
                                    paginationMessage={' '}
                                />
                            </div>
                        )}
                    </div>
                    <div
                        className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area"
                        id="applicationFooterSticky"
                        style={{ paddingRight: 25 }}
                    >
                        <ReportFooter
                            on_print_button={this.on_print_button}
                            on_export_button={this.on_export_button}
                            export_disabled={this.state.is_export_disabled}
                            print_disabled={this.state.is_print_disabled}
                        />
                    </div>
                </div>
            </div>);
    }
}


// Get user and login details from store.
const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details
    };
};

export default connect(mapStateToProps)(ProgramDetailComponent);
