import { text_comparator, mm_dd_yyyy_comparator } from './component/grid/comparators';
import * as FFConstant from '../feature_flip_constant';
//Please make sure for new flag entries in Launch Darkly: In Launch Darkly select the option
//"Make this flag available to client-side SDKs" on "Settings" tab. else the flag will not 
//be available to R6 Presentation Layer.
export const ignore_menu_items = [
{
    key: "tfB28776BillingFailedChargeRules",
    value: "Failed Charge Rules"
},
{
    key: "argosB31470ExportedCharges",
    value: "Exported Charges"
},
{
    key: "tfB29284BillingFailedChargesReport",
    value: "Failed Charges"
},
{
    key: "rsiB29536Dashboard",
    value: "Dashboard"
},
{
    key: "rsiB32935RolesPermissionsMenuAndPermissions",
    value: "Roles & Permissions"
},    
{
    key: "rsiB32929ConvertUserBulkUpload",
    value: "User Bulk Upload"
},
    {
        key: FFConstant.kraken_dynamic_form_letters_ff_key,
        value: "Dynamic Form Letters"
    },
    {
        key: "primeB32881BulkPaymentUpdates",
        value: "Bulk Payment Updates"
    },
    {
        key: FFConstant.prime_additional_configurability_for_claims,
        value: "Overrides: Claim"
    },
	{
		key: "goliathBill353835ValidationConfiguration",
		value: "835 Error Exclusions"
	},
	{
		key: FFConstant.leviathan_streamline_claim_holds,
		value: "Claim Hold Summary"
    },
    {
        key: FFConstant.kraken_provider_claim_holds_card_to_stand_alone_page,
        value: "Provider Claim Holds"
    },
    {
        key: FFConstant.prime_reactivate_charges,
        value: "Reactivate Charge"
    },
    {
        key: FFConstant.argos_bill_2473_upload_835s,
        value: "Upload 835"
    },
];
export const get_header_template_with_navigation = (id) => {
    return {
        template: '<div class="ag-cell-label-container" role="presentation">' +
            '<i class="small filter icon filter-ipad filter-active-icon"></i>' +
            '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '<div ref="eLabel" class="ag-header-cell-label header-first-col-container" role="presentation" id="' + id + '" tabindex="0">' +
            '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
            '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
            '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
            '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
            '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
            '</div>' +
            '</div>'
    }
};

export const header_template_with_navigation = id => {
    return {
        template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '<i class="small filter icon filter-ipad filter-active-icon"></i>' +
            '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '<div ref="eLabel" class="ag-header-cell-label" role="presentation" id="' +
            id +
            '" tabindex="0">' +
            '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
            '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
            '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
            '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
            '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
            '</div>' +
            '</div>'
    };
};

//'th large','table','th small','qrcode',microsoft,windows,block layout
export const app_left_parent_menu = [
    {
        name: "Dashboard",
        icon: "block layout",
        id: -999
    },
    {
        name: "Patient",
        icon: "address card",
        id: 1
    },
    {
        name: "Charges",
        icon: "credit card",
        id: 8
    },
    {
        name: "Billing",
        icon: "file alternate",
        id: 26
    },
    {
        name: "Payments",
        icon: "dollar",
        id: 43
    },
    {
        name: "Denial Management",
        icon: "exclamation circle",
        id: 77
    },
    {
        name: "AR Follow-up",
        icon: "check square",
        id: 79
    },
    {
        name: "Reports",
        icon: "chart bar",
        id: 86
    },
    {
        name: "Month End Reports",
        icon: "chart bar",
        id: 259
    },
    {
        name: "Admin",
        icon: "user",
        id: 89
    }
]

export const app_header_menu = {
    change_password: 105,
    change_company: 104
};

//Grid tamplates to show status for progress and empty state.
export const overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading...</span>';
export const overlayNoRowsTemplate = '<span>No Patient Found</span>';

export const search_result_default_paze_size = 100;

export const USER_R2_URL = 'USER:USER_R2_URL';
export const R2_PAGE_INFORMATION = 'R2:PAGE_INFORMATION';

export const sso_help_url = 'https://discover.webpt.com/docs/webpt-billing-single-sign-on';

// FF Menu Item Names
export const provider_claim_holds_name = 'Provider Claim Holds';
export const claim_holds_name = 'Claim Holds';


// value 'true' for is_open_r2_page i.e. which pages need to be opened in R6.
export const is_open_r2_page = {
    'Payment Codes': false,
    'Patient Payments Out Of Balance': true,
    'Released 835s': true,
    'Add/Edit Payer Rules': true,
    'Patient Statements': true,
    'Search by Check Number': true,
    'Duplicate Payment Summary': true,
    'Batch Payments': true,
    'Unrecognized 835s': false,
    'Update Payer ID': true,
    '835 Monthly Comparison': false,
    'Client Listing': false,
    'Overdue Patient Accounts': false,
    'Integration Rejection Summary': false,
    'New Rejection Summary': false,
    '835 Company Summary': true,
    '835 Search': true,
    'Invoicing': true,
    'No Patient Statement Listing': false,
    'Add Group Adjustments': true,
    'Release 835s': false,
    'Patient Info Change Report': false,
    'Integration Connection Home': false,
    'Manually Posted EOBs': false,
    'Open Zero Payments': true,
    'UnApproved Status Notes': false,
    'Month End Patient Statements Run': false,
    'Open Accounts': false,
    'Account Balance': false,
    'UnBilled Aging Secondary': false,
    'Payment Balancing': false,
    'Global Rejection Summary 394v': false,
    'Month End': false,
    'Add Adjustments': true,
    'Secondary Billing': true,
    'WebPT Updated Charge Report': false,
    'EOB Sys Payment Search': true,
    'Last Patient Statement': true,
    'Error Corrections': false,
    'Edit Payments': true,
    'Charge-Case Transfer': true,
    'Auto Adjust Rebill': true,
    'Batch Updates': true,
    'Correct Coding Initiative': true,
    'Status Group Code': true,
    'Status Code': true,
    'Status Category': true,
    'Batch Tasks': false,
    'Claim Indicator': false,
    'Demand Claims': true,
    'Batch Claims': true,
    '277 Report': true,
    'Open Claims': false,
    'Electronic Attachments': true,
    'Provider Credentialing List': true,
    'Manage Constants': true,
    'Merge Accounts': true,
    'Revenue Centers': true,
    '277 status code group':true,
};

export const r2_page_to_r6_page = [
    {
        key: "rsiB28566ConvertMergeAccountsPage",
        value: "Merge Accounts"
    },
    
    {
        key: "rsiB28091ConvertStatusGroupCodePageSearch",
        value: "Status Group Code"
    },
    {
        key: "rsiB28096ConvertStatusCategoryPageSearch",
        value: "Status Category"
    },
    {
        key: "rsiB26251ConvertManageConstants",
        value: "Manage Constants"
    },
    {
        key: "rsiB28983ConvertDemandClaimsPage",
        value: "Demand Claims"
    },
    {
        key: "rsiB29037ConvertOpenZeroPayment",
        value: "Open Zero Payments"
    },
    {
        key: "rsiB28087ConvertStatusCodePageSearch",
        value: "Status Code"
    },
    {
        key: "rsiB28381ConvertAddEditPayerRulesPageSearch",
        value: "Add/Edit Payer Rules"
    },
    {
        key: "rsiB29084FormatterHomePageUpdates",
        value: "Batch Claims"
    }, 
    {
        key: "rsiB30130ConvertChargeEditRules",
        value: "Correct Coding Initiative"
    },
    {
        key: "rsiB30151ConvertEditPaymentsSearchView",
        value: "Edit Payments"
    },
    {
        key: "rsiB30144ConvertChargeCaseTransfer",
        value: "Charge-Case Transfer"
    },
    {
        key: "rsiB30359BatchUpdatesSearchCriteria",
        value: "Batch Updates"
    }
]
const headerTemplate = {
    template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '<i class="small filter icon filter-ipad filter-active-icon"></i>' +
        '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '<div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '</div>' +
        '</div>'
};

export const search_class_configuration = id_for_navigation => {
    return {
        column_defs: [
            {
                headerName: 'Ins Class Code',
                field: 'insurance_class_code',
                filter: 'textFilter',
                comparator: text_comparator,
                minWidth: 175,
                unSortIcon: true,
                headerComponentParams: get_header_template_with_navigation(id_for_navigation),
            },
            {
                headerName: 'Ins Class Desc',
                field: 'insurance_class_description',
                filter: 'textFilter',
                comparator: text_comparator,
                minWidth: 175,
                unSortIcon: true,
                headerComponentParams: headerTemplate,
            }
        ]
    }
};
export const search_class_by_company_configuration = id_for_navigation => {
    return {
        column_defs: [
            {
                headerName: 'Ins Class Code',
                field: 'code',
                filter: 'textFilter',
                comparator: text_comparator,
                minWidth: 175,
                unSortIcon: true,
                headerComponentParams: get_header_template_with_navigation(id_for_navigation),
            },
            {
                headerName: 'Ins Class Desc',
                field: 'description',
                filter: 'textFilter',
                comparator: text_comparator,
                minWidth: 175,
                unSortIcon: true,
                headerComponentParams: headerTemplate,
            }
        ]
    }
};
export const search_payer_configuration = {
    column_defs: [
        {
            headerName: 'Name',
            field: 'name',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 175,
            unSortIcon: true,
            headerComponentParams: headerTemplate
        },
        {
            headerName: 'Description',
            field: 'description',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 175,
            unSortIcon: true,
            headerComponentParams: headerTemplate
        },
        {
            headerName: 'Outbound ID',
            field: 'outbound_id',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 175,
            unSortIcon: true,
            headerComponentParams: headerTemplate
        },
        {
            headerName: 'Inbound ID',
            field: 'inbound_id',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 175,
            unSortIcon: true,
            headerComponentParams: headerTemplate
        }
    ]
};

export const search_referring_physician_configuration = id_for_navigation => {
    return {
        pagination_size: 20,
        column_defs: [
            {
                headerName: 'MD Code',
                field: 'md_code',
                filter: 'textFilter',
                comparator: text_comparator,
                headerComponentParams: get_header_template_with_navigation(id_for_navigation),
                unSortIcon: true,
                minWidth: 175
            },
            {
                headerName: 'First Name',
                unSortIcon: true,
                headerComponentParams: headerTemplate,
                field: 'first_name',
                filter: 'textFilter',
                comparator: text_comparator,
                minWidth: 175
            },
            {
                headerName: 'Last Name',
                unSortIcon: true,
                headerComponentParams: headerTemplate,
                field: 'last_name',
                filter: 'textFilter',
                comparator: text_comparator,
                minWidth: 175
            },
            {
                headerName: 'Address 1',
                unSortIcon: true,
                headerComponentParams: headerTemplate,
                field: 'address1',
                filter: 'textFilter',
                comparator: text_comparator,
                minWidth: 200
                //cellClass: "cell-wrap-text",
                //autoHeight: true,
            },
            {
                headerName: 'Address 2',
                unSortIcon: true,
                field: 'address2',
                headerComponentParams: headerTemplate,
                filter: 'textFilter',
                comparator: text_comparator,
                minWidth: 200
                //cellClass: "cell-wrap-text",
                //autoHeight: true,
            },
            {
                headerName: 'City',
                unSortIcon: true,
                field: 'city',
                filter: 'textFilter',
                comparator: text_comparator,
                headerComponentParams: headerTemplate,
                minWidth: 175
            },
            {
                headerName: 'Credentials',
                unSortIcon: true,
                field: 'credentials',
                filter: 'textFilter',
                comparator: text_comparator,
                headerComponentParams: headerTemplate,
                minWidth: 175
            },
            {
                headerName: 'Medical Group',
                unSortIcon: true,
                field: 'medical_group',
                headerComponentParams: headerTemplate,
                filter: 'textFilter',
                comparator: text_comparator,
                minWidth: 185
            },
            {
                headerName: 'NPI',
                unSortIcon: true,
                field: 'npi',
                headerComponentParams: headerTemplate,
                filter: 'textFilter',
                comparator: text_comparator,
                minWidth: 185
            }
        ]
    };
};

export const search_referring_source_configuration = id_for_navigation => {
    return {
        pagination_size: 20,
        column_defs: [
            {
                headerName: 'Source Description',
                field: 'description',
                filter: 'textFilter',
                comparator: text_comparator,
                headerComponentParams: get_header_template_with_navigation(id_for_navigation),
                unSortIcon: true,
                minWidth: 175
            },
            {
                headerName: 'Source Type',
                unSortIcon: true,
                headerComponentParams: headerTemplate,
                field: 'type',
                filter: 'textFilter',
                comparator: text_comparator,
                minWidth: 175
            }
        ]
    };
};

export const search_referring_source_type_grid_header_id = 'search_referring_source_type_grid_header_id';

export const search_referring_source_type_configuration = {
    pagination_size: 20,
    column_defs: [
        {
            headerName: 'Source Type Code',
            field: 'code',
            filter: 'textFilter',
            comparator: text_comparator,
            headerComponentParams: get_header_template_with_navigation('search_referring_source_type_grid_header_id'),
            unSortIcon: true,
            minWidth: 175
        },
        {
            headerName: 'Source Type Description',
            unSortIcon: true,
            headerComponentParams: headerTemplate,
            field: 'description',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 175
        }
    ]
};

export const search_location_configuration = id_for_navigation => {
    return {
        column_defs: [
            {
                headerName: 'Location Name',
                field: 'name',
                filter: 'textFilter',
                comparator: text_comparator,
                minWidth: 175,
                headerComponentParams: get_header_template_with_navigation(id_for_navigation),
                unSortIcon: true
            },
            {
                headerName: 'Location Code',
                field: 'code',
                filter: 'textFilter',
                comparator: text_comparator,
                headerComponentParams: headerTemplate,
                minWidth: 175,
                unSortIcon: true
            }
        ]
    };
};

export const search_location_by_company_configuration = id_for_navigation => {
    return {
        column_defs: [
            {
                headerName: 'Location Name',
                field: 'description',
                filter: 'textFilter',
                comparator: text_comparator,
                minWidth: 175,
                headerComponentParams: get_header_template_with_navigation(id_for_navigation),
                unSortIcon: true
            },
            {
                headerName: 'Location Code',
                field: 'code',
                filter: 'textFilter',
                comparator: text_comparator,
                headerComponentParams: headerTemplate,
                minWidth: 175,
                unSortIcon: true
            }
        ]
    };
};

export const search_procedure_code_configuration = id_for_navigation => {
    return {
        column_defs: [
            {
                headerName: 'Procedure Code',
                field: 'proc_code',
                filter: 'textFilter',
                comparator: text_comparator,
                minWidth: 180,
                headerComponentParams: get_header_template_with_navigation(id_for_navigation),
                unSortIcon: true
            },
            {
                headerName: 'Procedure Description',
                field: 'proc_desc1',
                filter: 'textFilter',
                comparator: text_comparator,
                headerComponentParams: headerTemplate,
                minWidth: 175,
                unSortIcon: true
            },
            {
                headerName: 'Alternate Procedure Code',
                field: 'alt_proc_code',
                filter: 'textFilter',
                comparator: text_comparator,
                headerComponentParams: headerTemplate,
                minWidth: 210,
                unSortIcon: true
            }
        ]
    };
};

export const search_provider_configuration = id_for_navigation => {
    return {
        column_defs: [
            {
                headerName: 'Provider Name',
                field: 'description',
                filter: 'textFilter',
                comparator: text_comparator,
                minWidth: 175,
                headerComponentParams: get_header_template_with_navigation(id_for_navigation),
                unSortIcon: true
            },
            {
                headerName: 'Provider Code',
                field: 'code',
                filter: 'textFilter',
                comparator: text_comparator,
                minWidth: 175,
                headerComponentParams: headerTemplate,
                unSortIcon: true
            }
        ]
    };
};
export const search_provider_by_company_configuration = id_for_navigation => {
    return {
        column_defs: [
            {
                headerName: 'Provider Name',
                field: 'provider_desc',
                filter: 'textFilter',
                comparator: text_comparator,
                minWidth: 175,
                headerComponentParams: get_header_template_with_navigation(id_for_navigation),
                unSortIcon: true
            },
            {
                headerName: 'Provider Code',
                field: 'provider_code',
                filter: 'textFilter',
                comparator: text_comparator,
                minWidth: 175,
                headerComponentParams: headerTemplate,
                unSortIcon: true
            }
        ]
    };
};
export const search_special_program_configuration = id_for_navigation => {
    return {
        column_defs: [
            {
                headerName: 'Special Program Code',
                field: 'special_program_code',
                filter: 'textFilter',
                comparator: text_comparator,
                minWidth: 175,
                headerComponentParams: get_header_template_with_navigation(id_for_navigation),
                unSortIcon: true
            },
            {
                headerName: 'Special Program Name',
                field: 'special_program_name',
                filter: 'textFilter',
                comparator: text_comparator,
                minWidth: 175,
                headerComponentParams: headerTemplate,
                unSortIcon: true
            }
        ]
    };
};

export const patient_search_configuration_id = 'patient_search_configuration_id';

export const patient_search_configuration = patient_search_configuration_id => {
    return {
        column_defs: [
            {
                headerName: 'First Name',
                field: 'first_name',
                filter: 'textFilter',
                minWidth: 175,
                headerComponentParams: get_header_template_with_navigation(patient_search_configuration_id),
                unSortIcon: true
            },
            {
                headerName: 'Last Name',
                field: 'last_name',
                filter: 'textFilter',
                headerComponentParams: headerTemplate,
                minWidth: 175,
                unSortIcon: true
            },
            {
                headerName: 'Gender',
                field: 'gender',
                filter: 'textFilter',
                headerComponentParams: headerTemplate,
                minWidth: 150,
                unSortIcon: true
            },
            {
                headerName: 'Date of Birth',
                headerComponentParams: headerTemplate,
                field: 'date_of_birth',
                filter: 'dateFilter',
                minWidth: 200,
                unSortIcon: true
                // comparator: mm_dd_yyyy_comparator
            }
        ]
    };
};

export const insurance_search_grid_header_id = 'insurance_search_grid_header_id';

export const search_insurance_configuration = {
    column_defs: [
        {
            headerName: 'Ins Code',
            field: 'insurance_code',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 130,
            width: 130,
            headerComponentParams: get_header_template_with_navigation(insurance_search_grid_header_id),
            unSortIcon: true
        },
        {
            headerName: 'Insurance Name',
            field: 'insurance_name',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 195,
            width: 195,
            headerComponentParams: headerTemplate,
            unSortIcon: true
        },
        {
            headerName: 'Address 1',
            field: 'address1',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 155,
            width: 155,
            headerComponentParams: headerTemplate,
            unSortIcon: true
        },
        {
            headerName: 'City',
            field: 'city',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 100,
            width: 100,
            headerComponentParams: headerTemplate,
            unSortIcon: true
        },
        {
            headerName: 'Phone',
            field: 'phone_customer',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 120,
            width: 120,
            headerComponentParams: headerTemplate,
            unSortIcon: true
        },
        {
            headerName: 'Insurance Class',
            field: 'insurance_class',
            filter: 'textFilter',
            comparator: text_comparator,
            minWidth: 190,
            width: 190,
            headerComponentParams: headerTemplate,
            unSortIcon: true
        }
    ]
};

export const account_note_sticky_code = {
    case: 0,
    charge: 1,
    patient: 2,
    payment: 3
};

export const account_notes_modal_configuration = {
    column_defs: [
        {
            headerName: "",
            headerComponentParams: headerTemplate,
            field: "id",
            headerCheckboxSelection: true,
            checkboxSelection: true,
            width: 32,
            maxWidth: 32,
            suppressSorting: true,
            suppressFilter: true,
            suppressSizeToFit: true,
            valueGetter: function chainValueGetter(params) {
                return "";
            }
        },
        {
            headerName: "Note",
            headerComponentParams: headerTemplate,
            field: "note_text",
            filter: "textFilter",
            minWidth: 350,
            unSortIcon: true,
            cellClass: "cell-wrap-text",
            comparator: text_comparator,
            cellRenderer: "GridCellTooltip"
        },
        {
            headerName: "Type",
            headerComponentParams: headerTemplate,
            field: "note_code",
            filter: "textFilter",
            unSortIcon: true,
            minWidth: 140,
            comparator: text_comparator,

        },
        {
            headerName: "Date",
            headerComponentParams: headerTemplate,
            field: "created_date",
            filter: "dateFilter",
            unSortIcon: true,
            minWidth: 120,
            comparator: mm_dd_yyyy_comparator,

        },
        //Task
        {
            headerName: "Due Date",
            headerComponentParams: headerTemplate,
            field: "due_date",
            filter: "dateFilter",
            unSortIcon: true,
            minWidth: 150,
            comparator: mm_dd_yyyy_comparator
        },
        {
            headerName: "Created by",
            headerComponentParams: headerTemplate,
            field: "created_by",
            filter: "textFilter",
            unSortIcon: true,
            minWidth: 250,
            comparator: text_comparator
        },
        //Task
        {
            headerName: "Assignee",
            headerComponentParams: headerTemplate,
            field: "assignee",
            filter: "textFilter",
            unSortIcon: true,
            minWidth: 250,
            comparator: text_comparator
        },
        //Task
        {
            headerName: "Status",
            headerComponentParams: headerTemplate,
            field: "task_status",
            filter: "textFilter",
            unSortIcon: true,
            minWidth: 150,
            comparator: text_comparator
        },
        {
            headerName: "Stuck on",
            headerComponentParams: headerTemplate,
            field: "stick_on",
            filter: "textFilter",
            unSortIcon: true,
            minWidth: 150,
            comparator: text_comparator
        }
    ]
};

export const search_note_code_configuration = id_for_navigation => {
    return {
        column_defs: [
            {
                headerName: 'Note Code Description',
                field: 'description',
                filter: 'textFilter',
                comparator: text_comparator,
                minWidth: 175,
                headerComponentParams: get_header_template_with_navigation(id_for_navigation),
                unSortIcon: true
            },
            {
                headerName: 'Note Code',
                field: 'code',
                filter: 'textFilter',
                comparator: text_comparator,
                headerComponentParams: headerTemplate,
                minWidth: 175,
                unSortIcon: true
            }

        ]
    };
};

export enum enum_date_range_error_display_type {
    TOASTER = "TOASTER",
    UNDERCOMPONENT = "UNDERCOMPONENT"

}

export const analytics_permission_description = "Analytics Access";