import * as React from 'react';
import { connect } from 'react-redux';
import { toastr as toaster } from 'react-redux-toastr';
import { Button, Checkbox, Dimmer, Form, Grid, Input, Loader } from 'semantic-ui-react';
import * as global_constants from '../../../global_constants';
import PromptNavigation from '../../../shared/component/prompt_navigation_component';
import * as session_storage from '../../../shared/session_storage_utility';
import { set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import { get_all_error } from '../../../shared/utility';
import * as constants_actions from '../action/constants_action';
import * as constants from '../constants';
import { log_error } from '../../../shared/action/shared_action';

class PracticesViewEditComponent extends React.Component<any, any> {
  _is_mounted = false;
  constructor(props) {
    super(props);
    // Define initial state value.
    this.state = {
      show_render: true,
      form_object: {},
      is_loading: false,
      is_submitted: false,
      logo_loading: false,
      required_error: {},
      grid_conf: {
        isPagination: true,
        gridAutoHeight: true,
        selectionType: 'single',
        paginationPageSize: 20
      },
      by_pass_prompt: false,
      is_save_button_disabled: false
    };
  }

  // Define initial properties and its value.
  form_object: any = {
    practice_id: 0,
    practice_code: '',
    practice_name: '',
    logo_image_index_id: null,
    image_name: null,
    image_base64: null,
    is_active: true
  };
  required_filed: any = {
    practice_code: true,
    practice_name: true
  };

  page_name: string = 'practice';
  page_metadata: any;
  token: string = '';
  code_id: 0;
  search_criteria: {};
  is_grid_visible = false;
  initial_form_object: any = {};
  go_next: boolean = false;
  by_pass_prompt: boolean = false;
  image_formate = 'data:image/bmp;base64';
  is_image_updated: boolean = false;
  image_preview: any = '';
  practice_search_criteria: any = {
    practice_code: '',
    practice_name: '',
    practice_id: '',
    show_inactive: false
  };
  // Fetching the page_name and state.
  UNSAFE_componentWillMount = () => {
    this._is_mounted = true;
    this.token = this.props.user_login_details.user_data.data.token_details.access_token;
    this.code_id = this.props.match.params && this.props.match.params.id ? this.props.match.params.id : 0;
    //Verifying page_name is correct or not.
    this.page_metadata = constants.practice;
    this.initial_form_object = { ...this.form_object };
    if (this.code_id) {
      this.get_data(this.code_id);
    } else {
      this.setState({
        form_object: this.form_object
      });
    }
  };

  componentDidMount = () => {
    this._is_mounted = true;
    document.body.classList.add('admin-framework');
    let elem = document.getElementById('search-criteria-container');
    let all_form_elem = elem.getElementsByTagName('input');
    all_form_elem[0].focus();
  };

  componentWillUnmount = () => {
    this._is_mounted = false;
    document.body.classList.remove('admin-framework');
    if (this.props.history.location) {
      var route_array = this.props.history.location.pathname.split('/');
      route_array && route_array[2] !== 'practice' && session_storage.remove('practice_search_criteria');
    }
  };

  // Get the data on the basis of ID.
  get_data = code_id => {
    let url = this.page_metadata.api.get.url;
    url = url.replace('id_value', code_id);
    if (this._is_mounted) {
      this.setState({
        is_loading: true
      });
    }
    constants_actions.get_data(this.token, url).then(
      response => {
        if (response.data && response.data.data) {
          this.form_object = response.data.data;
          if (this.form_object.logo_image_index_id > 0) {
            this.get_logo_image(this.form_object.logo_image_index_id);
          }
          this.initial_form_object = { ...this.form_object };
          if (this._is_mounted) {
            this.setState({
              form_object: this.form_object,
              is_loading: false
            });
          }
        } else {
          if (response.data && response.data.messages) {
            const toastr_options = this.show_html_content_toaster(get_all_error(response.data));
            toaster.error('', toastr_options);
          }
          if (this._is_mounted) {
            this.setState({
              is_loading: false
            });
          }
        }
      },
      error => {
        if (this._is_mounted) {
          this.setState({
            is_loading: false,
            logo_loading: true
          });
        }
        if (error.response.data) {
          log_error(error);
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error('', toastr_options);
        }
      }
    );
  };

  //Input change handler.
  input_change_handler = e => {
    const { value, checked, type, name, files } = e.target;
    if (type == 'checkbox') {
      this.form_object[name] = checked;
    } else {
      this.form_object[name] = value;
    }
    if (this._is_mounted) {
      this.setState(prev_state => ({
        required_error: {
          ...prev_state.required_error,
          [name]: value ? false : true
        },
        form_object: this.form_object
      }));
    }
  };

  //Input File handler.
  file_handler = e => {
    const { name, files } = e.target;
    var is_valid_mime;
    if (this._is_mounted) {
      this.setState({
        logo_loading: true
      });
    }
    constants.loadMime(files[0], (mime, is_valid, mime_type, file) => {
      //print the output to the screen
      is_valid_mime = is_valid;
      if (is_valid_mime) {
        this.form_object.logo_image_index_id = this.initial_form_object.logo_image_index_id;
        let reader = new FileReader();
        let self = this;
        this.form_object.image_name = file.name;
        this.form_object.logo_image_index_id = null;
        reader.onload = (r: any) => {
          this.image_preview = this.form_object[name] = r.target.result.split(',')[1];
          this.image_formate = mime_type;
          self.setState(prev_state => ({
            form_object: this.form_object,
            logo_loading: false,
            required_error: {
              ...prev_state.required_error,
              [name]: false
            }
          }));
        };
        reader.readAsDataURL(file);
        document.getElementById('upload_logo_id').blur();
        this.is_image_updated = true;
      } else {
        toaster.error('', 'Only gif, jpg and png files can be uploaded');
      }
    });
    if (this._is_mounted) {
      this.setState(prev_state => ({
        required_error: {
          ...prev_state.required_error,
          [name]: false
        },
        form_object: this.form_object
      }));
    }
  };

  get_logo_image = logo_image_index_id => {
    if (this._is_mounted) {
      this.setState({
        logo_loading: true
      });
    }
    constants_actions.open_image(this.token, `${this.page_metadata.api.logo.url}/${logo_image_index_id}`).then(
      res => {
        if (res.data && res.data) {
          this.image_formate = 'data:image/bmp;base64';
          this.image_preview = this.arrayBufferToBase64(res.data.data);
          this.initial_form_object = { ...this.form_object };
          if (this._is_mounted) {
            this.setState({
              form_object: this.form_object,
              logo_loading: false
            });
          }
        }
      },
      error => {
        if (this._is_mounted) {
          this.setState({
            logo_loading: false
          });
        }
        if (error.response && error.response.data && error.response.data.messages) {
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error('', toastr_options);
        }
      }
    );
  };

  // Image upload handler.
  upload_logo = () => {
    let input: any = this.refs.input_reader;
    input.click();
  };

  // Image remove handler.
  remove_logo = () => {
    this.form_object.image_base64 = null;
    this.form_object.logo_image_index_id = null;
    this.image_preview = null;
    if (this._is_mounted) {
      this.setState({
        form_object: this.form_object
      });
    }
    document.getElementById('remove_logo_id').blur();
  };

  // Conver array buffer to base64
  arrayBufferToBase64(arrayBuffer) {
    return btoa(new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
  }

  //Save the form object and redirect it to search
  save_handler = (e, from_pop_up) => {
    this.setState({
      is_submitted: true
    });
    var is_error = false;
    this.go_next = false;

    for (let name in this.form_object) {
      this.form_object[name] =
        this.form_object[name] && typeof this.form_object[name] !== 'boolean' ? this.form_object[name].toString().trim() : this.form_object[name];
      if (this.required_filed[name] && !this.form_object[name]) {
        if (this._is_mounted) {
          this.setState(prev_state => ({
            required_error: {
              ...prev_state.required_error,
              [name]: true
            }
          }));
          is_error = true;
        }
      }
    }

    if (is_error) {
      toaster.error('', constants.mandatory_fields);
      return (is_error = true);
    }

    // Check Field Validation
    for (var key in this.state.error) {
      if (this.state.error[key]) {
        toaster.error('', constants.mandatory_fields);
        is_error = true;
      }
    }
    if (is_error) {
      return;
    }
    this.setState({
      is_loading: true,
      is_save_button_disabled: true
    });
    session_storage.set('practice_search_criteria', this.practice_search_criteria);
    if (this.code_id) {
      //Update
      let url = this.page_metadata.api.update.url;
      url = url.replace('id_value', this.code_id);
      if (!this.is_image_updated) {
        this.form_object.image_base64 = null;
      } else {
        this.form_object.logo_image_index_id = null;
      }
      constants_actions.update_data(this.token, url, this.form_object).then(
        response => {
          if (!!response.data.status) {
            this.go_next = true;
            toaster.success('', this.page_metadata.validation_message.update);
            this.initial_form_object = { ...this.form_object };
            if (this._is_mounted) {
                this.setState({
                    is_loading: false,
                    by_pass_prompt: !from_pop_up ? true : false,
                    is_save_button_disabled: false
                });
            }
            if (!from_pop_up && this.go_next) {
              this.go_next = false;
              this.props.history.goBack();
              }
          } else {
            if (response.data && response.data.messages) {
              log_error(response.data.messages);
              const toastr_options = this.show_html_content_toaster(get_all_error(response.data));
              toaster.error('', toastr_options);
            }
            this.go_next = false;
            if (this._is_mounted) {
              this.setState({
                is_loading: false,
                is_save_button_disabled: false
              });
            }
          }
        },
        error => {
          if (this._is_mounted) {
            this.go_next = false;
            this.setState({
              is_loading: false,
              is_save_button_disabled: false
            });
          }
          if (error.response && error.response.data) {
            log_error(error);
            const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
            toaster.error('', toastr_options);
          }
        }
      );
    } else {
      //Add
      let url = this.page_metadata.api.add.url;
      constants_actions.add_data(this.token, url, this.form_object).then(
        response => {
          if (!!response.data.status) {
            this.go_next = true;
            toaster.success('', this.page_metadata.validation_message.save);
            this.initial_form_object = { ...this.form_object };
            if (this._is_mounted) {
                this.setState({
                    is_loading: false,
                    by_pass_prompt: !from_pop_up ? true : false,
                    is_save_button_disabled: false
                });
            }              
            if (!from_pop_up && this.go_next) {
              this.go_next = false;
              this.props.history.goBack();
            }
          } else {
            if (response.data && response.data.messages) {
              log_error(response.data.messages);
              const toastr_options = this.show_html_content_toaster(get_all_error(response.data));
              toaster.error('', toastr_options);
            }
            this.go_next = false;
            if (this._is_mounted) {
              this.setState({
                is_loading: false,
                is_save_button_disabled: false
              });
            }
          }
        },
        error => {
          if (this._is_mounted) {
            this.go_next = false;
            this.setState({
              is_loading: false,
              is_save_button_disabled: false
            });
          }
          if (error.response && error.response.data) {
            log_error(error);
            const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
            toaster.error('', toastr_options);
          }
        }
      );
    }
  };

  //Function used to Run Report based on controllers values.
  cancel_handler = () => {
    this.by_pass_prompt = true;
    this.setState(
      {
        by_pass_prompt: true
      },
      () => this.props.history.goBack()
    );
  };

  show_html_content_toaster = msg => {
    return {
      component: () => (
        <div>
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        </div>
      ),
      timeOut: global_constants.toastr_options.toastr_time_out,
      preventDuplicates: true
    };
  };
  //It renders report grid and its search controls.
  render() {
    const { form_object, required_error, is_submitted } = this.state;
    return (
      this.state.show_render && (
        <React.Fragment>
          {!this.state.by_pass_prompt && (
            <PromptNavigation
              is_data_changed={JSON.stringify(this.initial_form_object) !== JSON.stringify(this.form_object)}
              save={e => this.save_handler(e, true)}
              go_next_location={this.go_next}
              history={this.props.history}
            />
          )}
          <Dimmer active={this.state.is_loading}>
            <Loader size='massive'>Loading</Loader>
          </Dimmer>
          <div className={'admin-wrapper view-edit practices ' + this.page_metadata.id} style={{ paddingBottom: 0 }}>
            <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
              <Grid.Column computer={16}>
                <h3 className='ui header left aligned'>{this.page_metadata.add_header}</h3>
              </Grid.Column>
            </Grid>
            <div
              id='admin-scrollable-area'
              className='wrapper'
              style={{ overflowX: 'auto', overflowY: 'auto', display: 'block' }}
            >
              <div
                className='patient-search-form patient_search_bottom_padding'
                id='search-criteria-container'
                style={{ paddingBottom: 0 }}
              >
                <Form autoComplete='off' name='view_edit_form' className='common-forms' style={{ width: '100%' }}>
                  <Grid>
                    <Grid.Column tablet={8} computer={4}>
                      <Form.Field>
                        <label>
                          Practice Code{' '}
                          <span
                            className={
                              !form_object.practice_code &&
                              this.required_filed.practice_code &&
                              is_submitted &&
                              required_error.practice_code
                                ? 'req-alert'
                                : 'req-alert_normal'
                            }
                          >
                            (required)
                          </span>
                        </label>
                        <Input
                          autoComplete='off'
                          name='practice_code'
                          id='practice_code'
                          onChange={e => this.input_change_handler(e)}
                          value={this.form_object.practice_code ? this.form_object.practice_code : ''}
                          type='text'
                          maxLength='50'
                          className={
                            !form_object.practice_code &&
                            this.required_filed.practice_code &&
                            is_submitted &&
                            required_error.practice_code
                              ? 'req-border-inp'
                              : ''
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column tablet={8} computer={4}>
                      <Form.Field>
                        <label>
                          Practice Description{' '}
                          <span
                            className={
                              !form_object.practice_name &&
                              this.required_filed.practice_name &&
                              is_submitted &&
                              required_error.practice_name
                                ? 'req-alert'
                                : 'req-alert_normal'
                            }
                          >
                            (required)
                          </span>
                        </label>
                        <Input
                          autoComplete='off'
                          name='practice_name'
                          id='practice_name'
                          onChange={e => this.input_change_handler(e)}
                          value={this.form_object.practice_name ? this.form_object.practice_name : ''}
                          type='text'
                          maxLength='50'
                          className={
                            !form_object.practice_name &&
                            this.required_filed.practice_name &&
                            is_submitted &&
                            required_error.practice_name
                              ? 'req-border-inp'
                              : ''
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column tablet={8} computer={4}>
                      <Form.Field style={{ marginTop: 32 }}>
                        <Checkbox
                          name='is_active'
                          id='is_active'
                          onChange={e => this.input_change_handler(e)}
                          checked={this.form_object.is_active ? true : false}
                          label={'Is Active'}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid>
                  <Grid>
                    <Grid.Column tablet={16} computer={16}>
                      <div style={{ display: 'inline-block', textAlign: 'center' }}>
                        <Button
                          type='button'
                          id='remove_logo_id'
                          onClick={() => this.remove_logo()}
                          disabled={this.image_preview ? false : true}
                          basic
                        >
                          Remove Logo
                        </Button>
                        <Button
                          type='button'
                          id='upload_logo_id'
                          onClick={this.upload_logo}
                          basic
                          // disabled={this.state.logo_loading}
                        >
                          Upload Logo
                        </Button>
                        <input
                          type='file'
                          ref='input_reader'
                          accept='image/png,image/gif,image/jpeg,image/jpg'
                          style={{ display: 'none' }}
                          value=''
                          onChange={e => this.file_handler(e)}
                          name='image_base64'
                          id='image_base64'
                        />
                        {/* {this.state.logo_loading && (
                          <React.Fragment>
                            <br />
                            <br />
                            <Loader active={this.state.logo_loading} inline size='small' />
                          </React.Fragment>
                        )} */}
                      </div>
                    </Grid.Column>
                  </Grid>
                  {this.image_preview && (
                    <Grid>
                      <Grid.Column
                        tablet={16}
                        computer={16}
                        style={{ overflowX: 'auto', overflowY: 'hidden', marginLeft: 0 }}
                      >
                        <img
                          src={`${this.image_formate},${this.image_preview}`}
                          alt='practice image'
                          style={{ maxWidth: '100%', maxHeight: '100%' }}
                        />
                      </Grid.Column>
                    </Grid>
                  )}
                </Form>
              </div>
            </div>
            <div
              className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
              id='applicationFooterSticky'
            >
              <Button id='cancel_button_id' type='button' onClick={() => this.cancel_handler()} basic>
                Cancel
              </Button>
              <Button
                type='submit'
                onKeyDown={set_focus_to_app_header}
                onClick={e => this.save_handler(e, false)}
                id='save_button_id'
                primary
                disabled={this.state.is_save_button_disabled}
              >
                Save
              </Button>
            </div>
          </div>
        </React.Fragment>
      )
    );
  }
}

//Get user and login details from store.
const mapStateToProps = state => {
  return {
    user_login_details: state.user_login_details
  };
};

export default connect(mapStateToProps)(PracticesViewEditComponent);
