import * as React from 'react';
import { Icon, Search } from 'semantic-ui-react';
import { clearTimeout, setTimeout } from 'timers';
import * as local_storage from '../../shared/local_storage_utility';
import { detect_ie, is_mac_safari, get_text_max_width } from '../utility';

interface IProps {
    selectresult: Function,
    errorMessage: string,
    getList: Function,
    prepareDataList: Function,
    prepareRenderList?: Function,
    errorClass?: string,
    getInputValue?: Function,
    loginDetail?: { token_details: { access_token: string }, company_id: string},
    default_value?: { label: string, title: string},
    is_focus?:  boolean,
    is_disabled?: boolean,
    isLoading?: boolean,
    show_clear_search?: boolean,
    clear_search?: Function,
    on_blur?: Function,
    on_focus?: (e: any) => void,
    onKeyDown?: Function,
    control_id?: string,
    on_blur_parent_handle?: boolean,
    minimum_search_characters?:number
}
class AutoSearchComponent extends React.PureComponent<IProps, any> {
    [x: string]: any;
    _is_mounted = false;
    UNSAFE_componentWillMount() {
        //this.resetComponent()
    }
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false, results: [],
            value: this.props.default_value ? this.props.default_value.label : '',
            min_characters: this.props.minimum_search_characters ? this.props.minimum_search_characters:3,
            selectedlabel: this.props.default_value ? this.props.default_value.label : '',
            selectedValue: '',
            name: '',
            typing: false, input: '', typingTimeout: 0,
            show_clear_search: this.props.show_clear_search == undefined ? true : this.props.show_clear_search,
            is_focus: this.props.is_focus ? this.props.is_focus : false,
        };
    }
    show_message = false;
    resetComponent = () => {
        if (this._is_mounted) {
            this.setState({
                isLoading: false,
                results: [], value: '',
                selectedValue: '',
                selectedlabel: ''
            });
        }
    };
    //function called when any selection is made in suggestion list
    handleResultSelect = (e, { result }) => {
        //localStorage.setItem('active_patient', result.title);
        this.props.selectresult(result);
        if (this._is_mounted) {
            this.setState({
                selectedValue: result.title,
                value: result.label,
                selectedlabel: result.label,
                results: [{ ...result }]
            });
        }
        //set minimum width after result section 
        var max_length = get_text_max_width([result.label], 'label');// Math.max(..._this.state.results.map(e => e.label.length))
            var element1: HTMLElement = document.querySelector('.results.transition');
            var element: HTMLElement = document.querySelector('.results.transition.visible');
            if (element) {
                
                if (is_mac_safari()) {
                    element.tabIndex = 0; // use to get relatedTarget for mac Safari
                }
                element.scrollLeft = 0; // Set scroll at left side if right end.
                var child_nodes = element.childNodes || [];
                for (let x = 0; x < child_nodes.length; x++) {
                    var item = child_nodes[x];
                    if (item && item["style"]) {
                        item["style"]["min-width"] = Math.round(max_length) + "px";
                    }
                }
            }
    }

    on_blur_handle = (e, value) => {
        if (this.props.on_blur) {
            this.props.on_blur(e, value);
        }

        let relatedTarget = e.relatedTarget;
        if (detect_ie()) {
            relatedTarget = document.activeElement;
            if (relatedTarget && ((relatedTarget.className === 'item_auto_search') ||
                (relatedTarget.className === 'results transition visible') ||
                (relatedTarget.className === 'prompt'))) {
                return
            }
        }

        if (relatedTarget && ((relatedTarget.className === 'item_auto_search') || (relatedTarget.className === 'results transition visible'))) {
            return
        }

        this.clear_unselection();
    }

    on_key_down = (e, value) => {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(e, value);
        }

        if (!e.shiftKey && e.keyCode == '9') {
            this.clear_unselection();
        } else if (e.keyCode === 13 && this.clear_input_on_enter_pressed(e.target.value)) {
            this.clear_unselection();
        }
    }

    //Clear input text and initialized data state.
    clear_unselection = () => {
        if (!this.props.on_blur_parent_handle && (this.state.value != this.state.selectedlabel)) {
            // If want clear input typed and no item selected from suggestion or no record in suggestion
            this.clear_quick_search();
        }
    }

    clear_input_on_enter_pressed = (value) => {
        if (this.state.results && this.state.results.length === 0) {
            return true;
        } else if (value.length < 3 && value != this.state.selectedlabel) {
            return true;
        }
        return false;
    }

    prepare_renderList = props => {
        let tab_index = this.state.value != this.state.selectedlabel ? 0 : -1;
        return (<div key={props.title} tabIndex={tab_index} className="item_auto_search">
            <div key={props.title} className="fs_13">
                <span>{props.label}</span>
            </div>
        </div>);
    };

    //function to get the list of patient based on typed character
    getList = async (value) => {
        // TODO Verify this code for token 
        var token = '';
        var companyid = '';
        if (this.props.loginDetail) {
            token = this.props.loginDetail.token_details.access_token;
            companyid = this.props.loginDetail.company_id;
        }
        else {
            if (local_storage.get("auth_data")) {
                token = local_storage.get("auth_data").token_details.access_token;
                companyid = local_storage.get("auth_data").company_id;
            }
        }

       await this.props.getList(value, companyid).then(
            response => {               
                this.show_message = (response.data.data.length) ? false : true;
                //set render content with based on max length value
                var _this = this;
                if (this._is_mounted) {
                    const state_object = {
                        list: response.data,
                        inputValue: value.trim(),
                        isLoading: false,
                        results: this.state.value == value ? this.props.prepareDataList(response.data) : this.state.results,
                        timeout: setTimeout(() => {
                            var max_length = get_text_max_width(_this.state.results,'label');// Math.max(..._this.state.results.map(e => e.label.length))
                            var element: HTMLElement = document.querySelector('.results.transition.visible');
                            if (element) {
                                if (is_mac_safari()) {
                                    element.tabIndex = 0; // use to get relatedTarget for mac Safari
                                }
                                element.scrollLeft = 0; // Set scroll at left side if right end.
                                var child_nodes = element.childNodes||[];
                                for (let x = 0; x < child_nodes.length; x++) {
                                    var item = child_nodes[x];
                                    if (item && item["style"]) {
                                        item["style"]["min-width"] = Math.round(max_length ) + "px";
                                    }
                                }
                            }

                        }, 0)
                    };

                    if (this._is_mounted) {
                        this.setState(state_object);
                    }
                }
            }
        );
    }
    //ON change  event of search box
    handleChange = (e, { value }) => {
        const self = this;
        if (self.props.getInputValue) {
            self.props.getInputValue(value);
        }
        if (this._is_mounted) {
            self.setState({ isLoading: true, value });
        }
        //if (self.state.value.length < 1) return self.resetComponent()
        if (value.length > (parseInt(this.state.min_characters)-1)) {
            this.show_message = false;
            if (self.state.typingTimeout) {
                clearTimeout(self.state.typingTimeout);
            }
            if (self._is_mounted) {
                self.setState({
                    name: value,
                    typing: false,
                    typingTimeout: setTimeout(function () {
                        self.getList(value);
                    }, 350)
                });
            }
        }
    };
    renderResult = (props) => {
        if (this.props.prepareRenderList) {
            return this.props.prepareRenderList(props);
        }
        return this.prepare_renderList(props);
    }
    componentDidUpdate(previousProps, previousState) {

        if (previousProps.default_value !== this.props.default_value) {
            if (this._is_mounted) {
                this.setState({
                    value: this.props.default_value.label ? this.props.default_value.label : '',
                    selectedlabel: this.props.default_value.label ? this.props.default_value.label : '',
                    results: this.props.default_value.label ? [{ ...this.props.default_value, label: this.props.default_value.label ? this.props.default_value.label : '', title: this.props.default_value.title ? this.props.default_value.title : '' }] : []
                });
            }
        }
        if (previousProps.isLoading !== this.props.isLoading) {
            if (this._is_mounted) {
                this.setState({ isLoading: this.props.isLoading });
            }
        }
        if (previousProps.show_clear_search !== this.props.show_clear_search) {
            if (this._is_mounted) {
                this.setState({ show_clear_search: this.props.show_clear_search })
            }
        }
        if (previousProps.is_focus !== this.props.is_focus) {
            if (this._is_mounted) {
                this.setState({ is_focus: this.props.is_focus })
            }
        }

    }
    clear_quick_search = () => {
        if (this.props.clear_search) {
            if (this.props.is_disabled) {
                return;
            }
            this.props.clear_search();
        }
    }

    componentDidMount() {
        this._is_mounted = true;
    }
    componentWillUnmount() {
        this._is_mounted = false;
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
        }

    }
    render() {
        const { isLoading, value, selectedValue, results, show_clear_search } = this.state
        return (
            <React.Fragment><Search
                category={false}
                onBlur={this.on_blur_handle}
                className={this.props.errorClass}
                loading={isLoading}
                onResultSelect={this.handleResultSelect}
                onSearchChange={this.handleChange}
                onFocus={this.props.on_focus}
                results={results}
                value={value}
                disabled={this.props.is_disabled}
                resultRenderer={this.renderResult}
                input={{
                    ref: r => {
                        if (r && r.inputRef && this.state.is_focus) {
                            r.inputRef.focus()
                        }
                    }
                }}
                onKeyDown={this.on_key_down}
                placeholder='Search'
                id={this.props.control_id ? this.props.control_id : 'ctrl'}
                autoComplete="new-password"
                minCharacters={this.state.min_characters}
                selectFirstResult
                noResultsMessage={<div className='no-data-found'>{this.show_message ? this.props.errorMessage : ''}</div>}


            ></Search>
                {show_clear_search ?
                    <button type="button" className="closeIcon" disabled={this.props.is_disabled} onClick={() => this.clear_quick_search()}>
                        <Icon aria-hidden="true" className="close icon auto-search-icon" name='close' />
                    </button>
                    : ''}
            </React.Fragment>)

    }
}


export default AutoSearchComponent
