import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dimmer, Grid, Loader, Button } from 'semantic-ui-react';
import { toastr as toaster } from 'react-redux-toastr';
import { log_error } from '../../shared/action/shared_action';
import * as session_storage from '../../shared/session_storage_utility';
import { get_all_error, get_lauch_darkley_key_value as get_launch_darkly_key_value, get_lauch_darkley_key_value } from '../../shared/utility';
import * as report_service from '../action/report_action';
import * as report_constants from '../report_constants';
import * as export_print_utility from '../util/export_print_utility';
import * as report_data_formatter from '../util/report_data_formatter';
import ReportHeader from './report_header';
import GridView from '../../shared/component/grid';
import * as global_constants from '../../global_constants';
import { set_focus_to_app_header } from '../../shared/tab_navigation_utility';

export class UnrecognizedEobComponent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      report_data: [],
      is_grid_visible: false,
      is_show_pop_up: false,
      grid_params: null,
      loading: false,
      is_print_disabled: true,
      is_export_disabled: true,
      current_date: new Date(),
      row_params: {},
      manual_eob_footer_obj: {},
      show_date: false,
      is_submitted: false,
      report_rows_count: 0,
      is_error: {},
      is_update_disabled: true,
      selected_835s: []
    };
  }
  // Define the class level variables.
  _is_mounted = false;
  _eob_key = 0;
  report_container: any;
  run_report_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
  patient_name: string;
  account_no: string;
  current_date: string;
  title: string;
  report_metadata: any[];
  report_data: any = {
    rows: [],
    footer_obj: {}
  };
  total_column_width: number = 0;
  grid_header_height: number = 0;
  grid_row_height: number = 28;
  search_criteria: any = {
    month: '1'
  };
  initial_search_criteria: any = {
    month: '1'
  };
  is_initial_result = false;
  footer_object = {};
  last_col_width = 0;
  grid_width = 0;
  max_inner_width = 0;
  token: any;
  title_search_criteria: any;
  has_hierarchy: any;
  company_name: any;
  eButton: { addEventListener: (arg0: string, arg1: (para: any) => void) => void };
  report_id: number;

  // Fetching the report id and params.
  UNSAFE_componentWillMount = () => {
    this.token = this.props.user_login_details.user_data.data.token_details.access_token;
    this.report_id = this.props.features.B32780unrecognized835sUpdates
      ? report_constants.unrecognized_eob.updates_ff_on
      : report_constants.unrecognized_eob.updates_ff_off;
    this.search_criteria = {
      ...this.search_criteria
    };
    if (session_storage.get('unrecognized_eob_criteria')) {
      this.is_initial_result = true;
      this.search_criteria = {
        ...session_storage.get('unrecognized_eob_criteria')
      };
    }
    if (!this.is_initial_result)
      this.title_search_criteria = {
        ...this.search_criteria
      };
  };

  componentDidMount() {
    this._is_mounted = true;
    document.body.classList.add('reports');
    this.getReport('');
  }

  componentWillUnmount = () => {
    document.body.classList.remove('reports');
    this._is_mounted = false;
    this.search_criteria = {
      ...this.initial_search_criteria
    };
    this.report_data = [];
    this.report_metadata = [];
    session_storage.remove('unrecognized_eob_criteria');
  };

  //Get Report Data.
  get_report_data = async () => {
    let param = [
      {
        sequence: 1,
        label_name: 'User',
        name: 'userid',
        control_type: 'TextBox',
        required: true,
        disabled: false,
        invisible: true,
        default_value: '',
        param_name: '@userid',
        auto_postback: false,
        master_data: null,
        data_type: 'int',
        depends_on: 0,
        report_param_id: 8514
      }
    ];
    if (this._is_mounted) {
      this.setState({
        loading: true,
        is_grid_visible: false,
        is_update_disabled: true,
        selected_835s: []
      });
    }
    await report_service.get_report_data(this.report_id, param, this.token).then(
      (response: {
        data: {
          data: {
            HasHierarchy: any;
            ReportRows: any;
            ReportColumn: any;
            footer_obj: any;
            ReportHeader: any;
            ShowFooter: any;
            R4ToR6Route: any;
            Title: any;
            Company: any;
            DefaultSortColumn: any;
          };
          messages: { message: any }[];
        };
        config: { headers: { Authorization: any } };
      }) => {
        if (response.data && response.data.data) {
          const formatted_data = report_data_formatter.formate_report_cols_row_data(response.data.data, this.report_id);
          response.data.data = formatted_data.data;
          this.has_hierarchy = response.data.data.HasHierarchy;
          this.report_data.rows = response.data.data.ReportRows ? response.data.data.ReportRows : [];
          this.report_data.cols = response.data.data.ReportColumn ? response.data.data.ReportColumn : [];
          this.report_data.footer_obj = response.data.data.footer_obj ? response.data.data.footer_obj : {};
          // Set cell data based on cell type
          this.report_data.cols[3].cellRenderer = (params: any) => this.operations_cell_renderer(params);
          this.report_data.cols[9].cellRenderer = (params: any) => this.operations_cell_renderer(params);
          this.report_data.cols[10].cellRenderer = (params: any) => this.operations_cell_renderer(params);
          if (!this.props.features.B32780unrecognized835sUpdates) {
            this.report_data.cols[9].cellRenderer = (params: any) => this.operations_cell_renderer(params);
          }

          this.report_data.header = response.data.data.ReportHeader;
          this.report_data.ShowFooter = response.data.data.ShowFooter;
          this.report_data.r4_to_r6_routes = response.data.data.R4ToR6Route;
          this.report_data.title = response.data.data.Title;
          this.company_name = response.data.data.Company;
          this.report_data.DefaultSortColumn = response.data.data.DefaultSortColumn;
          this.total_column_width = formatted_data.total_column_width; // ACAAAA
          this.grid_header_height = formatted_data.grid_header_height;
          let footer_obj = this.initialize_footer_obj(this.report_data.cols);
          this.report_data.cols.map((value: { type: string; field: string | number }, index: any) => {
            if (value.type == 'currency') {
              footer_obj[value.field] = report_constants.currencyFormatter(footer_obj[value.field]);
            }
          });

          this.footer_object = footer_obj;
          if (this._is_mounted) {
            this.setState({
              loading: false,
              report_data: this.report_data,
              is_grid_visible: true,
              is_export_disabled: false,
              is_print_disabled: false,
              show_date: true,
              is_submitted: true,
              report_rows_count: this.report_data.rows && this.report_data.rows.length,
              is_update_disabled: this.report_data.rows && this.report_data.rows.length > 0 ? false : true
            });
          }
        } else {
          log_error(response.data.messages[0].message.indexOf('<br') > -1);
          if (response.data.messages[0].message.indexOf('<br') > -1) {
            const toastr_options = this.show_html_content_toaster(get_all_error(response.data.messages[0].message));
            toaster.error('', toastr_options);
          }
          if (this._is_mounted) {
            this.setState({
              loading: false,
              is_update_disabled: true
            });
          }
        }
      },
      (error: { response: { data: any } }) => {
        if (this._is_mounted) {
          this.setState({
            loading: false,
            is_update_disabled: true
          });
        }
        log_error(error);
        if (error.response.data) {
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error('', toastr_options);
        }
      }
    );
  };

  operations_cell_renderer = (params: {
    data: { int32Col0: any; stringCol2: string; link_stringCol2: any };
    colDef: { headerName: any };
    node: { data: { [x: string]: string }; setDataValue: (arg0: string, arg1: any) => void };
  }) => {
    let eDiv = document.createElement('div');
    let eob_key = params.data.int32Col0;
    if (params && params.data && params.data.stringCol2) {
      let p1 = params.data.stringCol2.split('?', 2);
      let p2 = p1[1].replace('</a>', '').replace('"', '');
      let p3 = p2.split('><b', 1);

      switch (params.colDef.headerName) {
        case 'Check Number':
          eDiv.className = 'ag-react-container';
          eDiv.innerHTML = '<span class="href_link" data-attr=/report/sub_report_data?' + p2 + '</span>';
          eDiv.addEventListener('click', (para) => {
            this.props.history.push({
              pathname: '/report/sub_report_data', ///+ params.data.link_stringCol1,
              search: p3[0],
              state: { eob_key: params.data.link_stringCol2 }
            });
          });
          break;
        case 'Remove':
          eDiv.innerHTML = '<button class="btn-close">Remove</button>';
          this.eButton = eDiv.querySelector('.btn-close');
          this.eButton.addEventListener('click', (para: any) => {
            this.remove_eob(eob_key);
          });
          break;
        case 'Update':
          eDiv.innerHTML = '<button class="btn-simple">Update</button>';
          this.eButton = eDiv.querySelector('.btn-simple');
          // add event listener to button
          this.eButton.addEventListener('click', (para) => {
            this.update_eob([params.data]);
          });
          break;
        default:
          eDiv.innerHTML = `
          <input
            type= "text"
            id= "val_${eob_key}"
            maxlength= "3"
            size= "5"
            style= "width: 100%;height: 25px !important;border: 1px solid;"
          />`;
          let eInput = eDiv.querySelector('input');
          if (eInput) {
            eInput.value = params.node.data['int32Col1'] || '';
            eInput.addEventListener('blur', (event: any) => {
              params.node.setDataValue('int32Col1', event.target.value);
            });
          }
          break;
      }
    }
    return eDiv;
  };

  // Function used to initialize the footer object.
  initialize_footer_obj = (column_def: any[]) => {
    let footer_obj = {};
    column_def.map((value: { field: string; type: string }, index: number) => {
      if (value.field !== 'group') {
        if (index == 1) {
          footer_obj[value.field] = 'Total';
        } else if (value.type == 'currency' || value.type == 'number') {
          footer_obj[value.field] = 0;
        } else {
          footer_obj[value.field] = null;
        }
      }
    });
    return footer_obj;
  };

  // Show multiple messages
  show_html_content_toaster = (msg: string) => {
    return {
      component: () => (
        <div>
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        </div>
      ),
      timeOut: global_constants.toastr_options.toastr_time_out,
      preventDuplicates: true
    };
  };

  // Function calls after click of filter button and recalculate the footer sum.
  on_filter_button_click = () => {
    let row_api = this.state.grid_params.api;
    let row_count = 0; // Update row count
    if (row_api) {
      row_api.forEachNodeAfterFilter((row: any, index: any) => {
        row_count++;
      });
    }
    // Update row count
    if (this._is_mounted) {
      this.setState({
        report_rows_count: row_count //this.state.grid_params.api.getModel().getRowCount()
      });
    }
  };

  //Function calls on initializations of Ag-Grid and catch its reference.
  get_grid_ref = (grid_params: {
    api: { getModel: () => { (): any; new (): any; getRowCount: { (): any; new (): any } } };
    columnApi: { setColumnWidth: (arg0: any, arg1: any, arg2: boolean) => void };
  }) => {
    if (this._is_mounted) {
      this.setState({
        grid_params,
        report_rows_count: grid_params.api.getModel().getRowCount()
      });
    }
    let grid_width = document.getElementsByClassName('ag-body')[0].clientWidth;
    if (this.total_column_width > grid_width - report_constants.const_data_width) {
      this.report_data.cols.filter((item: { [x: string]: any; field: any }, index: any) => {
        grid_params.columnApi.setColumnWidth(item.field, item['width'], false);
      });
    }
  };

  // Used to create the title for export and print.
  set_title = (separator: string) => {
    this.current_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
    return (this.title =
      'Unrecognized 835s ' +
      separator +
      this.current_date +
      separator +
      (this.state.report_rows_count == 1
        ? this.state.report_rows_count + ' record'
        : this.state.report_rows_count + ' records') +
      separator +
      separator);
  };

  // Function calls on initialization of export report data
  on_export_button = () => {
    this.title = this.set_title('\n');
    export_print_utility.export_grid_data(this.state.grid_params, this.title, 'Unrecognized EOB');
    this.saveReportEventAction(report_constants.ReportEventAction.Export);
  };

  // Function calls on initialization of Print report data
  on_print_button = () => {
    this.title = this.set_title('<br/>');
    export_print_utility.print_grid_data(this.state.grid_params, this.title, 'Unrecognized EOB');
    this.saveReportEventAction(report_constants.ReportEventAction.Print);
  };

  // Function calls save billing audit on Export or Print
  private saveReportEventAction = (eventActionId: number) => {      
      const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
      const { user_id, company_id } = this.props.user_login_details.user_data.data;

      const payload: report_constants.IPayloadForBillingAudit = {
          reportId: String(this.report_id),
          contextTitle: `Unrecognized 835s - ${report_constants.ReportEventAction[eventActionId]}`,
          eventActionId: eventActionId,
          userId: Number(user_id),
          companyId: Number(company_id),
          entityTypeId: report_constants.ReportEntityTypeId.a_rpt_ver,
          data: { Records: this.report_data.rows.length }
      }

      const reqBody = export_print_utility.generateAuditDataEntryPayload(payload);

      report_constants.saveAuditPrintExport(reqBody, accessToken);
  };

  public getReport(e: any) {
    this.get_report_data();
    session_storage.set('unrecognized_eob_criteria', this.search_criteria);
    this.run_report_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  // Select 835 Key on checkbox
  on_check_box_selection = (items: string | any[]) => {
    if (this.state.selected_835s.length !== items.length) {
      if (this._is_mounted) {
        this.setState({
          selected_835s: items
        });
      }
    }
  };

  // Update 835 key
  update_eob = (params: any[]) => {
    if (this.props.features.B32780unrecognized835sUpdates) {
      const row_api = this.state.grid_params.api;
      if (row_api) {
        row_api.forEachNodeAfterFilter((row: any) => {
          if (row.data['int32Col1']) {
            params.push({
              eobkey: row.data.int32Col0,
              clinicCode: row.data.int32Col1
            });
          }
        });
      }
    } else {
      params = params.map((item: { int32Col0: any; int32Col1: any }) => {
        return {
          eobkey: item.int32Col0,
          clinicCode: item.int32Col1
        };
      });
    }
    if (params && params.length <= 0) {
      toaster.error("", report_constants.unrecognized_eob.empty_error);
      return;
    }
    if (params.length > 0) {
      if (this._is_mounted) {
        this.setState(
          {
            is_update_disabled: true,
            loading: true
          },
          () => {
            report_service
              .update_unrecognized_eob(this.token, params)
              .then(
                (response: { data: { data: any } }) => {
                  if (response.data && response.data.data) {
                    this.getReport('');
                  } else {
                    if (this._is_mounted) {
                      this.setState({
                        is_update_disabled: false,
                        loading: false
                      });
                    }
                    const toastr_options = this.show_html_content_toaster(
                      'EOB was NOT Updated, Check if Clinic Code is Valid.'
                    );
                    toaster.error('', toastr_options);
                  }
                },
                (error) => {
                  log_error(error);
                  if (error.response.data) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error('', toastr_options);
                  }
                  if (this._is_mounted) {
                    this.setState({
                      is_update_disabled: false,
                      loading: false
                    });
                  }
                }
              )
              .catch((error) => {
                log_error(error);
                if (error.response.data) {
                  const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                  toaster.error('', toastr_options);
                }
                if (this._is_mounted) {
                  this.setState({
                    is_update_disabled: false,
                    loading: false
                  });
                }
              });
          }
        );
      }
    }
  };

  // Remove 835 key
  remove_eob = (params: any[]) => {
    if (this._is_mounted) {
      this.setState(
        {
          is_update_disabled: true,
          loading: true
        },
        () => {
          report_service
            .close_unrecognized_eob(this.token, params)
            .then(
              (response: { data: { data: any } }) => {
                if (response.data && response.data.data) {
                  this.getReport('');
                } else {
                  if (this._is_mounted) {
                    this.setState({
                      is_update_disabled: false,
                      loading: false
                    });
                  }
                  const toastr_options = this.show_html_content_toaster('EOB was NOT Deleted, Try again later.');
                  toaster.error('', toastr_options);
                }
              },
              (error) => {
                log_error(error);
                if (error.response.data) {
                  const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                  toaster.error('', toastr_options);
                }
                if (this._is_mounted) {
                  this.setState({
                    is_update_disabled: false,
                    loading: false
                  });
                }
              }
            )
            .catch((error) => {
              log_error(error);
              if (error.response.data) {
                const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                toaster.error('', toastr_options);
              }
              if (this._is_mounted) {
                this.setState({
                  is_update_disabled: false,
                  loading: false
                });
              }
            });
        }
      );
    }
  };

  render() {
    return (
      <div className={'common-forms-add'}>
        <Dimmer active={this.state.loading}>
          <Loader size='massive'>Loading</Loader>
        </Dimmer>
        <div className={'common-forms-search report-framework'}>
          <ReportHeader title={'Unrecognized 835s'} />
          <div
            id='report-scrollable-area'
            className='report-wrapper'
            style={this.state.is_grid_visible ? { flex: 1 } : {}}
          >
            {this.state.is_grid_visible && !this.state.loading && (
              <React.Fragment>
                <Grid className='dateTime'>
                  <Grid.Column tablet={5} computer={4} textAlign='left'>
                    <p style={{ fontSize: '16px', minHeight: 22 }}>{this.run_report_date}</p>
                  </Grid.Column>
                  <Grid.Column tablet={5} computer={8} textAlign='center'>
                    {
                      <p style={{ fontSize: '16px', minHeight: 22 }}>
                        {`${this.state.report_rows_count} ${
                          this.state.report_rows_count == 1 ? ' record shown' : ' records shown'
                        }`}
                      </p>
                    }
                  </Grid.Column>
                  <Grid.Column tablet={2} computer={4} textAlign='right' />
                </Grid>
                <GridView
                  id={`grid_report_id_${this.report_id}`}
                  row={this.report_data.rows}
                  column={this.report_data.cols}
                  headerHeight={this.grid_header_height}
                  style={{ height: '100%' }}
                  wrapperStyle={{ width: '100%', height: 0, display: 'flex', flex: 1 }}
                  getRowHeight={() => this.grid_row_height}
                  suppressMovableColumns={true}
                  suppressColumnVirtualisation={true}
                  enableColResize={true}
                  emptyMessage={report_constants.messages.no_records_found}
                  get_grid_ref={this.get_grid_ref}
                  headerIdForTabNavigation={'Manually_posted_eob_grid'}
                  pinnedBottomRowData={this.report_data.ShowFooter && [this.report_data.footer_obj]}
                  on_filter_button_click={() => {
                    this.on_filter_button_click();
                  }}
                />
              </React.Fragment>
            )}
          </div>
          <div
            className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
            id='applicationFooterSticky'
          >
            <Grid.Column computer={16} tablet={16} textAlign='right'>
              <Button
                id='export_report_button'
                type='submit'
                onClick={this.on_export_button}
                disabled={this.state.is_export_disabled}
                content={'Export'}
                basic
              />
              <Button
                id='print_report_button'
                type='submit'
                onKeyDown={set_focus_to_app_header}
                onClick={this.on_print_button}
                disabled={this.state.is_print_disabled}
                content={'Print'}
                basic={this.props.features.B32780unrecognized835sUpdates}
                primary={!this.props.features.B32780unrecognized835sUpdates}
              />
              {this.props.features.B32780unrecognized835sUpdates && (
                <Button
                  id='update_report_button'
                  type='button'
                  onClick={() => this.update_eob(this.state.selected_835s)}
                  disabled={this.state.is_update_disabled}
                  content={'Update'}
                  primary
                />
              )}
            </Grid.Column>
          </div>
        </div>
      </div>
    );
  }
}

//Get user and login details from store.
const mapStateToProps = (state: {
  user_login_details: any;
  patient_details: { patient_header: any };
  shared_details: { is_expand: any };
  launch_darkly: any;
}) => {
  return {
    user_login_details: state.user_login_details,
    selected_patient: state.patient_details.patient_header,
    is_expand: state.shared_details.is_expand,
    features: {
      B32780unrecognized835sUpdates: get_launch_darkly_key_value(
        state.launch_darkly,
        'rsiB32780Unrecognized835SUpdates'
      ),
    }
  };
};

export default connect(mapStateToProps)(UnrecognizedEobComponent);
