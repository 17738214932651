import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dimmer, Form, Grid, Loader, Button } from 'semantic-ui-react';
import { toastr as toaster } from 'react-redux-toastr';
import { log_error } from '../../shared/action/shared_action';
import * as session_storage from '../../shared/session_storage_utility';
import { custom_date_format, get_columns, charge_get_columns, get_all_error,  } from '../../shared/utility';
import * as report_service from '../action/report_action';
import * as report_constants from '../report_constants';
import * as export_print_utility from '../util/export_print_utility';
import * as report_data_formatter from '../util/report_data_formatter';
import { set_focus_to_app_header } from './../../shared/tab_navigation_utility';
import ReportFooter from './report_footer';
import ReportHeader from './report_header';
import GridView from '../../shared/component/grid';
import * as global_constants from '../../global_constants';
import {
    get_report_data,
    get_report_metadata,
    get_sub_report_data,
    get_updated_dropdown_data,
    get_updated_dependent_dropdown_data,
    remove_billing_exceptions,
    reprocess_billing_exceptions,
} from '../action/report_action';
import { set_url_history } from '../util/report_data_formatter';
import * as Action from './../../shared/action/autosearch_action';
import AdvancedControl from './../../shared/component/advanced_control';
import ReportController from './report_controller';
import ReportGrid from './report_grid';
import { AlertConfirm } from '../../shared/component/alert_confirm_component';
import { messages } from '../report_constants';

export class ManuallyPostedEobComponent extends React.Component<any, any> {
  [x: string]: any;
    _is_mounted = false;
    _eob_list = [];
  constructor(props) {
    super(props);
    this.state = {
      report_data: [],
      is_grid_visible: false,
      is_show_pop_up: false,
      grid_params: null,
      loading: false,
      is_print_disabled: true,
      is_export_disabled: true,
      current_date: new Date(),
      row_params: {},
      manual_eob_footer_obj: {},
        show_date: false,
      is_submitted: false,
      report_rows_count: 0,
      is_error: {},
      is_save_button_disabled: true
    };
  }
  // Define the class level variables.
  report_container: any;
    run_report_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
    manual_posted_eob_column_def = [];   //report_constants.report_manually_posted_eob.
  patient_name: string;
  account_no: string;
  current_date: string;
  title: string;
  report_metadata: any[];
  report_data: any = {
    rows: [],
    footer_object: {}
  };
  total_column_width: number = 0;
  grid_header_height: number = 0;
  grid_row_height: number = 28;  
    

  search_criteria: any = {
    month: '1'
  };

  initial_search_criteria: any = {
    month: '1'
  };

  is_initial_result = false;
  footer_object = {};
  last_col_width = 0;
  grid_width = 0;
  max_inner_width = 0;
  reportId: string = "0";
  // Fetching the report id and params.
    UNSAFE_componentWillMount = () => {
    this.token = this.props.user_login_details.user_data.data.token_details.access_token;
    this.search_criteria = {
      ...this.search_criteria,
    };

    if (session_storage.get('manual_posted_eob_criteria')) {
      this.is_initial_result = true;
      this.search_criteria = {
        ...session_storage.get('manual_posted_eob_criteria')
      };
    }
    if (!this.is_initial_result)
      this.title_search_criteria = {
        ...this.search_criteria,
        //company_id: this.props.user_login_details.user_data.data.company_name
         /// month: '1'
      };
  };

  componentWillUnmount = () => {
    document.body.classList.remove('reports');
    this._is_mounted = false;
    this.search_criteria = {
      ...this.initial_search_criteria
    };
    this.report_data = [];
    this.report_metadata = [];
    session_storage.remove('manual_posted_eob_criteria');
  };

  componentDidMount() {
    this._is_mounted = true;
    document.body.classList.add('reports');
      //if (this.is_initial_result) {   //  this is an Autorun report
      this.getReport('');
      //this.get_report_data();
      //session_storage.set('manual_posted_eob_criteria', this.search_criteria);
      //this.run_report_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
      //if (e) {
      //    e.preventDefault();
      //    e.stopPropagation();
      //}
  }

  //Get updated dropdown data on dropdown change.
  get_dropdown_master_data = async () => {
    if (this._is_mounted) {
      this.setState({
        re_render: false,
        loading: true
      });
    }
  
  };

   //Get Report Data.
  get_report_data = async () => {

      //let param = [{ "sequence": 1, "label_name": "Month", "name": "month", "control_type": "DropDownList", "required": true, "disabled": false, "invisible": false, "default_value": "1", "param_name": "@month", "auto_postback": false, "master_data": null, "data_type": "int", "depends_on": 0, "report_param_id": 8318 }]
      let param = [{ "sequence": 2, "label_name": "User", "name": "userid", "control_type": "TextBox", "required": true, "disabled": false, "invisible": true, "default_value": "", "param_name": "@userid", "auto_postback": false, "master_data": null, "data_type": "int", "depends_on": 0, "report_param_id": 8503 }]
    if (this._is_mounted) {
      this.setState({
        loading: true,
        is_grid_visible: false,
        is_save_button_disabled: true
      });
      }


      await report_service.get_report_data(report_constants.manually_posted_eob, param, this.token).then(
      response => {
          if (response.data && response.data.data) {
              
              //  new block
              const formatted_data = report_data_formatter.formate_report_cols_row_data(response.data.data, report_constants.manually_posted_eob);
              response.data.data = formatted_data.data;

              this.has_hierarchy = response.data.data.HasHierarchy
              this.report_data.rows = response.data.data.ReportRows ? response.data.data.ReportRows : [];
              this.report_data.cols = response.data.data.ReportColumn ? response.data.data.ReportColumn : [];
              this.report_data.cols[2].cellRenderer = params => this.eob_key_cell_renderer(params, 'eob_key'),
              this.report_data.footer_obj = response.data.data.footer_obj ? response.data.data.footer_obj : {};

              this.manual_posted_eob_column_def = this.report_data.cols;


              this.report_data.header = response.data.data.ReportHeader;
              this.report_data.ShowFooter = response.data.data.ShowFooter;
              this.report_data.r4_to_r6_routes = response.data.data.R4ToR6Route;
              this.report_data.title = response.data.data.Title;
              this.company_name = response.data.data.Company;
              this.report_data.DefaultSortColumn = response.data.data.DefaultSortColumn;

              
              this.total_column_width = formatted_data.total_column_width*2;  // ACAAAA
              this.grid_header_height = formatted_data.grid_header_height;

              var footer_obj = this.initialize_footer_obj(this.manual_posted_eob_column_def);
              this.manual_posted_eob_column_def.map((value, index) => {
                  if (value.type == 'currency') {
                    footer_obj[value.field] = report_constants.currencyFormatter(footer_obj[value.field]);
                  }
                });
                if (this._is_mounted) {
                    this.setState({ charge_audit_footer_obj: { ...footer_obj } });
                }
                this.footer_object = footer_obj;


              if (this._is_mounted) {
            this.setState({
              loading: false,
              report_data: this.report_data,
              is_grid_visible: true,
              is_export_disabled: false,
              is_print_disabled: false,
                show_date: true,
              is_submitted: true,
              report_rows_count: this.report_data.rows && this.report_data.rows.length,
                is_save_button_disabled: (this.report_data.rows && this.report_data.rows.length > 0) ? false : true,
            });
          }
        } else {
          log_error(response.data.messages[0].message.indexOf('<br') > -1);
          if (response.data.messages[0].message.indexOf('<br') > -1) {
            const toastr_options = this.show_html_content_toaster(get_all_error(response.data.messages[0].message));
            toaster.error("", toastr_options);
          }
          if (this._is_mounted) {
            this.setState({
              loading: false,
              //is_save_button_disabled: false
            });
          }
        }
      },
      error => {
        if (this._is_mounted) {
          this.setState({
            loading: false,
            //is_save_button_disabled: false
          });
        }
        log_error(error);
        if (error.response.data) {
          const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
          toaster.error("", toastr_options);
        }
      }
    );
  };

  
    eob_key_cell_renderer = (params, col_name) => {
        var eDiv = document.createElement('div');
        eDiv.className = 'ag-react-container';
        if (params && params.data && params.data.stringCol1) {
            var p1 = params.data.stringCol1.split('?', 2);
            var p2 = p1[1].replace('</a>', '').replace('"', '');
            var p3 = p2.split('><b', 1);
            //this.sub_report_id = parseInt(this.sub_report_id[0].split('rid=')[1]);
            //console.log('p2', p2)
            //console.log('p3', p3[0])
            eDiv.innerHTML = '<span class="href_link" data-attr=/report/sub_report_data?' + p2 + '</span>';
            //eDiv.innerHTML = '<span class="href_link" data-attr=/report/sub_report_data?rid%3D30124&cr_id%3D30124&Company_Id%3D420&FDate%3D03%2F15%2F2019&TDate%3D03%2F15%2F2019&CheckEftNbr%3D3296731&eobkey%3D9945325>' + 'aca va link' + '</span>'
            eDiv.addEventListener('click', (para) => {
                this.props.history.push({
                    pathname: '/report/sub_report_data', ///+ params.data.link_stringCol1,
                    search: p3[0],
                    //search: '?rid%3D30124&cr_id%3D30124&Company_Id%3D420&FDate%3D03%2F15%2F2019&TDate%3D03%2F15%2F2019&CheckEftNbr%3D3296731&eobkey%3D9945325',
                    state: { eob_key: params.data.link_stringCol1 }
                })
            });
        }
        return eDiv;
    };


    get_eob_keys = () =>{
        var row_api = this.state.grid_params.api;
        let row_count = 0;

        if (row_api) {
            row_api.forEachNodeAfterFilter((row, index) => {
                //eobs.push(row.data.int32Col0);
                this._eob_list = [...this._eob_list, row.data.int32Col0];  
            });
        };
        return;   //this.eob_list;
    }


  //Function used to Close All EOBs.
    run_report_handler = async e => {
        var is_error = false;
        if (this._is_mounted) {
            this.setState({ is_submitted: true });
        }
        //debugger;

        this.get_eob_keys();
       
        await report_service.close_eob(this.token, this._eob_list).then(
            response => {
                if (response.data && response.data.data) {

                    this.getReport(e);
                };

            });
    }

          
  //Function used to initialize the footer object.
    initialize_footer_obj = column_def => {
       // debugger;
    var footer_obj = {};
    column_def.map((value, index) => {
      if (value.field !== 'group') {
        if (index == 1) {
          footer_obj[value.field] = 'Total';
        } else if (value.type == 'currency' || value.type == 'number') {
          footer_obj[value.field] = 0;
        } else {
          footer_obj[value.field] = null;
        }
      }
    });
    return footer_obj;
  };

  capitalize_text = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  //Function calls on click of Clear button and clear all the searched result as well as criteria.
  clear_handler = () => {
    if (this._is_mounted) {
      this.setState({
        loading: true,
          is_grid_visible: false,
          is_save_button_disabled: true
      });
    }
    this.get_dropdown_master_data();
    this.search_criteria = {
      ...this.initial_search_criteria,
        month: '1',
        date_type: 'By Created Date',
      from_date: custom_date_format(moment(), 'mm/dd/yyyy'),
      to_date: custom_date_format(moment(), 'mm/dd/yyyy')
    };
    this.title_search_criteria = {
      company_id: this.props.user_login_details.user_data.data.company_name,
      practice_id: '',
      location_id: '',
      date_type: 'Posting/Created Date',
      from_date: custom_date_format(moment(), 'mm/dd/yyyy'),
      to_date: custom_date_format(moment(), 'mm/dd/yyyy')
    };

    this.report_data = [];
    this.report_metadata = [];
    this.is_submitted = false;
    session_storage.remove('manual_posted_eob_criteria');
    setTimeout(() => {
      if (this._is_mounted) {
        this.setState({
          report_data: [],
          is_grid_visible: false,
          is_export_disabled: true,
          is_print_disabled: true,
          show_date: false,
          loading: false,
          is_start_date: false,
          is_end_date: false,
          is_error: {
            start_date: false,
            end_date: false
          }
        });
      }
    }, 400);
  };

  // Show multiple messages
  show_html_content_toaster = msg => {
    return {
      component: () => (
        <div>
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        </div>
      ),
      timeOut: global_constants.toastr_options.toastr_time_out,
      preventDuplicates: true
    };
  };

  //Function calls after click of filter button and recalculate the footer sum.
  on_filter_button_click = () => {
    var footer_obj = this.initialize_footer_obj(this.manual_posted_eob_column_def);
    var row_api = this.state.grid_params.api;
    var footer_cell_data;
    let row_count = 0; // Update row count
    if (row_api) {
      row_api.forEachNodeAfterFilter((row, index) => {
          row_count++;
      });

      
    }
    // Update row count
    this.setState({
      report_rows_count: row_count //this.state.grid_params.api.getModel().getRowCount()
    });
  };

  //Function calls on intialization of Ag-Grid and catch its reference.
  get_grid_ref = grid_params => {
    if (this._is_mounted) {
      this.setState({
        grid_params,
        report_rows_count: grid_params.api.getModel().getRowCount()
      });
    }
      //if (this.total_column_width > this.grid_width) {
      var grid_width = document.getElementsByClassName('ag-body')[0].clientWidth;

      if (this.total_column_width > (grid_width - report_constants.const_data_width)) {
      this.manual_posted_eob_column_def.filter((item, index) => {
        grid_params.columnApi.setColumnWidth(item.field, item['width'], false);
      });
    }
  };

    //getRowHeight = (params) => {
    //    console.log('params', params);
    //    if (params.node.rowPinned) {
    //        return 38;
    //    }
    //    else {
    //        return 28;
    //    }
    //}


  //Used to create the title for export and print.
  set_title = separter => {
    this.current_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
    return (this.title =
      'Manually Posted EOBs ' +
      separter +
      this.current_date +
      separter +
      (this.state.report_rows_count == 1
        ? this.state.report_rows_count + ' record'
        : this.state.report_rows_count + ' records') +
      separter +
      separter);
  };

    get_grid_option = () => {
        if (this.report_data.link_drill_down_last_page) {
            return {
                onRowSelection: this.on_check_box_selection,
                checkboxSelection: true
            }
        }

        return null;
    }


    //Function calls on initialization of export report data
    on_export_button = () => {
        this.title = this.set_title('\n');
        export_print_utility.export_grid_data(this.state.grid_params, this.title, 'Manually Posted EOB');
        this.saveReportEventAction(report_constants.ReportEventAction.Export);
    };

    //Function calls on initialization of Print report data
    on_print_button = () => {
        this.title = this.set_title('<br/>');
        export_print_utility.print_grid_data(this.state.grid_params, this.title, 'Manually Posted EOB');
        this.saveReportEventAction(report_constants.ReportEventAction.Print);
    };

    // Function calls save billing audit on Export or Print
    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;

        const payload: report_constants.IPayloadForBillingAudit = {
            reportId: this.reportId,
            contextTitle: `Manually Posted EOB - ${report_constants.ReportEventAction[eventActionId]}`,
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: report_constants.ReportEntityTypeId.a_rpt_ver,
            data: { Records: this.state.grid_params.api.rowModel.rowsToDisplay.length }
        }

        const reqBody = export_print_utility.generateAuditDataEntryPayload(payload);

        report_constants.saveAuditPrintExport(reqBody, accessToken);
    };

  handle_navigation_after_last_element = event => {
    
    // apply a check where grid is empty
    if (
      !event.shiftKey &&
      event.keyCode == '9' &&
      (this.state.report_data == null || this.state.report_data.length == 0)
    ) {
      set_focus_to_app_header(event);
    }
  };

    private getReport(e: any) {
        this.get_report_data();
        session_storage.set('manual_posted_eob_criteria', this.search_criteria);
        this.run_report_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        

    }

  render() {
    return (
      <div className={'common-forms-add'}>
        <Dimmer active={this.state.loading}>
          <Loader size='massive'>Loading</Loader>
        </Dimmer>
        <div className={'common-forms-search report-framework'}>
          <ReportHeader title={'Manually Posted EOBs'} />
          <div
            id='report-scrollable-area'
            className='report-wrapper'
                    style={this.state.is_grid_visible ? { flex : 1 } : {}}
          >            
            <div className='patient-search-form  patient_search_bottom_padding' id='report-criteria-container'>
              <Form autoComplete='off'>
                <Grid>
                  <Grid.Column tablet={16} computer={16} textAlign='right'>
                    <Button
                      id='close_all_eobs'
                      className='primary'
                      onClick={this.run_report_handler}
                      type='submit'
                      onKeyDown={this.handle_focus_for_grid}
                      style={{ marginRight: '0' }}
                      disabled={this.state.is_save_button_disabled}
                    >
                      Remove All
                    </Button>
                  </Grid.Column>
                </Grid>
              </Form>
                    </div>

                    {this.state.is_grid_visible && !this.state.loading &&  (
              <React.Fragment>
                <Grid className='dateTime'>
                  <Grid.Column tablet={5} computer={4} textAlign='left'>
                    <p style={{ fontSize: '16px', minHeight: 22 }}>{this.run_report_date}</p>
                  </Grid.Column>
                  <Grid.Column tablet={5} computer={8} textAlign='center'>
                    {
                      <p style={{ fontSize: '16px', minHeight: 22 }}>
                        {`${this.state.report_rows_count} ${
                          this.state.report_rows_count == 1 ? ' record shown' : ' records shown'
                        }`}
                      </p>
                    }
                  </Grid.Column>
                  <Grid.Column tablet={2} computer={4} textAlign='right' />
                </Grid>
                <GridView
                                id='grid_report_id_30105-1'
                                row={this.report_data.rows}
                                column={this.report_data.cols}
                                headerHeight={this.grid_header_height}
                                style={{ height: '100%' }}  //, display: 'flex', flex: 1, flexDirection: 'column', alignSelf: 'stretch' }}
                                wrapperStyle={{ width: '100%', height: 0 , display: 'flex', flex: 1 }}
                                //wrapperClass={'grid_wrapper'}  
                                getRowHeight={()=>this.grid_row_height}  
                            
                  //gridAutoHeight={false}
                  selectionType={"single"}
                                //gridAutoRowHeight={true}
                  suppressMovableColumns={true}
                  suppressColumnVirtualisation={true}
                                enableColResize={true}
                                //suppressSizeToFit={true}
                  emptyMessage={report_constants.messages.no_records_found}
                  get_grid_ref={this.get_grid_ref}
                  headerIdForTabNavigation={'Manually_posted_eob_grid'}
                   pinnedBottomRowData={this.report_data.ShowFooter && [this.report_data.footer_obj]}
                  on_filter_button_click={() => { this.on_filter_button_click(); }}
                  //detailsName='details'
                />
              </React.Fragment>
            )}
          </div>
          <div
            className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
            id='applicationFooterSticky'
          >
            <ReportFooter
              on_print_button={() => this.on_print_button()}
              on_export_button={() => this.on_export_button()}
              export_disabled={this.state.is_export_disabled}
              print_disabled={this.state.is_print_disabled}
            />
          </div>
        </div>
      </div>
    );
  }
}

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
        selected_patient: state.patient_details.patient_header,
        is_expand: state.shared_details.is_expand,
    };
};

export default connect(mapStateToProps)(ManuallyPostedEobComponent);
