import * as React from 'react';
import { Header } from "semantic-ui-react";
import GridView from '../../shared/component/grid';
import * as report_constant from '../report_constants';
import { get_eob_remarks_data } from '../action/report_action';
import { get_all_error, get_columns } from '../../shared/utility';
import { toastr } from 'react-redux-toastr';
import { set_focus_on_element_with_id } from '../../shared/tab_navigation_utility';
import { LoaderComponent } from '../../shared/component/loading_component';
import { toastr_options } from '../../global_constants';

class OpenZeroRemarksAndAdjustmentCodeComponent extends React.PureComponent<any, any> {
    [x: string]: any;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            is_grid_visible: false
        }
    }

    _is_mounted = false;
    grid_data: any = {
        rows: [],
        grid_conf: report_constant.open_zero_payments__remards_setting_columns
    };
    total_column_width: number = 0;
    grid_header_height: number = 0;

    componentDidMount() {
        this._is_mounted = true
        this.get_eob_remarks_data();
    }

    componentWillUnmount = () => {
        this._is_mounted = false;
    };

    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }

    // Get Remarks Data.
    get_eob_remarks_data = async () => {
        if (this._is_mounted) {
            this.setState({
                loading: true,
                is_grid_visible: false
            });
        }
        this.grid_data.row = []
        await get_eob_remarks_data(this.props.eob_key, this.token).then(
            (response) => {
                if (response.data && response.data.data) {
                    this.grid_data.rows = response.data.data;
                    const grid_height = get_columns(this.grid_data.rows, this.grid_data.grid_conf);
                    this.total_column_width = grid_height.total_column_width;
                    this.grid_header_height = grid_height.header_height;
                } else {
                    const toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                    toastr.error('', toastr_options);
                }
                if (this._is_mounted) {
                    this.setState({
                        loading: false,
                        is_grid_visible: true
                    });
                }
            },
            (error: { response: { data: any } }) => {
                if (this._is_mounted) {
                    this.setState({
                        loading: false
                    });
                }

                if (error.response.data) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toastr.error("", toastr_options);
                }
            }
        );
    };

    on_grid_out = () => {
        set_focus_on_element_with_id('app_header_dropdown');
    };

    get_grid_ref = grid_params => {
        if (this._is_mounted) {
            this.setState({
                grid_params
            });
        }
        var grid_width = document.getElementById("open_zero_remarks_payment").clientWidth;
        this.props.setRemarks(this.state.grid_params);

        if (this.total_column_width > grid_width) {
            this.grid_data.grid_conf.filter(item => {
                grid_params.columnApi.setColumnWidth(item.field, item.width, false);
            });
        }
    };

    update_report_rows_count = () => {
        var grid_width = document.getElementById("open_zero_remarks_payment").clientWidth;

        if (this.total_column_width > grid_width) {
            this.grid_data.grid_conf.filter(item => {
                this.state.grid_params.columnApi.setColumnWidth(item.field, item.width, false);
            });
        }

        this.props.setRemarks(this.state.grid_params);
    };

    //Render function
    render() {
        return (
            <LoaderComponent loading={this.state.loading}>
                <div style={{ border: "1px solid #cccccc", background: " #fff", marginTop: 10 }} className="open-zero-framework" id="open_zero_remarks_payment">
                    <div className="ui grid headerGrid" style={{ margin: 0, paddingTop: 5 }}>
                        <div className="sixteen wide computer column">
                            <Header as='h2' textAlign="left" className="section-heading-miniHead">Remarks and Adjustment Code</Header>
                        </div>
                    </div>
                    {this.state.is_grid_visible && (
                        <GridView
                            id="open_zero_remarks"
                            row={this.grid_data.rows}
                            column={this.grid_data.grid_conf}
                            style={{ height: '100%' }}
                            wrapperStyle={{ width: '100%', height: '100%', display: 'flex' }}
                            suppressMovableColumns={false}
                            enableColResize={true}
                            selectionType={'single'}
                            get_grid_ref={this.get_grid_ref}
                            suppressSizeToFit={true}
                            headerHeight={this.grid_header_height}
                            gridAutoHeight={true}
                            headerIdForTabNavigation="open_zero_remarks_id"
                            onForceGridOut={this.on_grid_out}
                            on_filter_button_click={() => {
                                this.update_report_rows_count();
                            }}
                        />)}
                </div>
            </LoaderComponent>
        );
    }
};

export default OpenZeroRemarksAndAdjustmentCodeComponent;