import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Dimmer, Grid, Header, Loader, Modal } from 'semantic-ui-react';
import { clearTimeout, setTimeout } from 'timers';
import * as session_storage from '../../shared/session_storage_utility';
import {
  format_patient_name,
  modal_show_after_print_ios,
  modal_hide_on_print,
  modal_show_after_print,
  set_column_data_width,
  get_columns,
  execute_print,
  print_html_data_to_pdf
} from '../../shared/utility';
import * as report_service from '../action/report_action';
import * as report_constants from '../report_constants';
import * as report_data_formatter from '../util/report_data_formatter';
import GridView from './../../shared/component/grid';
import { saveAs } from './../../shared/file-saver.min';
import { set_focus_on_element_with_id, handle_focus_on_tab } from './../../shared/tab_navigation_utility';
import { log_error } from '../../shared/action/shared_action';
import * as global_constants from '../../global_constants';
import { toastr as toaster } from 'react-redux-toastr';

enum report {
  payment_adjustment = 'payment_adjustment',
  charges = 'charges',
  claims = 'claims'
}

class LedgerVisitPopUpComponent extends React.Component<any, any> {
  _is_mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      is_print_disabled: false,
      patient_adjustments_grid_ref: {},
      claims_grid_ref: {},
      is_show_pop_up: false,
      report_data: {}
    };
  }
  company_name = '';
  patient_name = '';
  account_no = null;
  current_date = '';
  title = '';
  charges_total_column_width: any;
  payment_adjustment_total_column_width: any;
  claims_total_column_width: any;
  charges_max_column_height: any;
  payment_adjustment_max_column_height: any;
  claims_max_column_height: any;
  charges_column_def = report_constants.report_charges_configuration.column_defs;
  payment_adjustment_column_def = report_constants.report_payment_adjustment_configuration.column_defs;
  claims_column_def = report_constants.report_claims_configuration.column_defs;

  UNSAFE_componentWillMount() {
    var charge_id = parseInt(this.props.charge_id);
    var token = this.props.user_login_details.user_data.data.token_details.access_token;
    if (charge_id) this.get_data(charge_id, token);
  }

  componentWillUnmount = () => {
    this._is_mounted = false;
  };

  componentDidMount() {
    this._is_mounted = true;
  }
  //Function calls on click of close button.
  close_modal = () => {
    this.props.on_pop_up_close();
  };

  get_data = async (charge_id, token) => {
    let arrData = [];
    if (this._is_mounted) {
      this.setState({
        loading: true
      });
    }
    arrData.push(report_service.get_patient_payment_adjustments(token, charge_id));
    arrData.push(report_service.get_patient_claim_data(token, charge_id));
    // arrData.push(report_service.get_charge_report_title(token, charge_id));
    await Promise.all(arrData)
      .then(response => {
        if (response[0].data && response[0].data.data && response[1].data && response[1].data.data) {
          const payment_adjustment = get_columns(response[0].data.data, this.payment_adjustment_column_def);
          this.payment_adjustment_max_column_height = payment_adjustment.header_height;
          this.payment_adjustment_total_column_width = payment_adjustment.total_column_width;
          const claims = get_columns(response[1].data.data, this.claims_column_def);
          this.claims_max_column_height = claims.header_height;
          this.claims_total_column_width = claims.total_column_width;
          //this.charges_total_column_width = get_columns(response[2].data.data, this.charges_column_def);

          this.setState({
            report_data: {
              ...this.state.report_data,
              payment_adjustment: this.row_data_formatter(response[0].data.data, report.payment_adjustment),
              claims: this.row_data_formatter(response[1].data.data, report.claims)
              // charges: this.row_data_formatter(response[1].data.data, report.charges)
            },
            loading: false,
            is_show_pop_up: true
          });
          //this.payment_adjustment_total_column_width = set_column_data_width(
          //  response[0].data.data,
          //  report_constants.report_payment_adjustment_configuration.column_defs
          //);
          //this.claims_total_column_width = set_column_data_width(
          //  response[1].data.data,
          //  report_constants.report_claims_configuration.column_defs
          //);
          /*this.charges_total_column_width = set_column_data_width(
            response[2].data.data,
            report_constants.report_charges_configuration.column_defs
          ); */
        }
      })
      .catch(error => {
        if (this._is_mounted) {
          this.setState({
            loading: false,
            is_show_pop_up: false
          });
        }
        log_error(error);
        if (error.data.messages[0].message.indexOf('<br') > -1) {
          this.show_html_content_toaster(error.response.data.messages[0].message);
        } else {
          toaster.error('', error.response.data.messages[0].message);
        }
      });
  };

  // Show multiple messages
  show_html_content_toaster = msg => {
    return {
      component: () => (
        <div>
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        </div>
      ),
      timeOut: global_constants.toastr_options.toastr_time_out,
      preventDuplicates: true
    };
  };

  //Function use to format the grid data on load.
  row_data_formatter = (data, report_name) => {
    var column_def;
    switch (report_name) {
      case report.charges:
        column_def = this.charges_column_def;
        break;
      case report.payment_adjustment:
        column_def = this.payment_adjustment_column_def;
        break;
      case report.claims:
        column_def = this.claims_column_def;
        break;
      default:
    }

    data.map(row => {
      column_def.map(value => {
        switch (value.type) {
          case 'date':
            row[value.field] =
              row[value.field] != null || row[value.field] != undefined
                ? report_constants.dateFormatter(row[value.field])
                : '';
            break;
          case 'currency':
            row[value.field] =
              row[value.field] != null || row[value.field] != undefined
                ? report_constants.currencyFormatter(row[value.field])
                : report_constants.currencyFormatter(0);
            break;
          case 'number':
            row[value.field] =
              row[value.field] != null || row[value.field] != undefined ? parseInt(row[value.field]) : 0;
            break;
          default:
            row[value.field] =
              row[value.field] != null || row[value.field] != undefined ? row[value.field].toString().trim() : '';
          // value.minWidth = value.minWidth < row[value.field].length * 7 + 50 ? row[value.field].length * 7 + 50 : value.minWidth;
        }
      });
      return row;
    });

    return data;
  };

  //Function calls on intialization of Ag-Grid and catch its reference.
  get_patient_adjustments_grid_ref = grid_params => {
    if (this._is_mounted) {
      this.setState({
        patient_adjustments_grid_ref: grid_params
      });
    }
    var grid_width = document.getElementsByClassName('description')[0].clientWidth;
    if (this.payment_adjustment_total_column_width > grid_width) {
      this.payment_adjustment_column_def.filter(item => {
        grid_params.columnApi.setColumnWidth(item.field, item.width, false);
      });
    }
  };

  //Function calls on intialization of Ag-Grid and catch its reference.
  get_claims_grid_ref = grid_params => {
    if (this._is_mounted) {
      this.setState({
        claims_grid_ref: grid_params
      });
    }
    var grid_width = document.getElementsByClassName('description')[0].clientWidth;
    if (this.claims_total_column_width > grid_width) {
      this.claims_column_def.filter(item => {
        grid_params.columnApi.setColumnWidth(item.field, item.width, false);
      });
    }
  };

  //Function calls on intialization of Ag-Grid and catch its reference.
  /*   get_charges_grid_ref = grid_params => {
    if (this._is_mounted) {
      this.setState({
        charges_grid_ref: grid_params
      });

      set_focus_on_element_with_id(report_constants.report_charges_grid_header_id);
    }
    var grid_width = document.getElementsByClassName('description')[0].clientWidth;
    if (this.charges_total_column_width > grid_width) {
      this.charges_column_def.filter(item => {
        grid_params.columnApi.setColumnWidth(item.field, item.minWidth, false);
      });
    }
  }; */

  //Function calls on initialization of export report data
  on_export_button = () => {
    this.title = this.set_title('\n');
    var field_type;
    var report_name = 'Payment Adjustments and Billing Log Detail';
    var csv_data: any;
    var params = {
      processCellCallback: param => {
        field_type = param.column.colDef.type;
        if (field_type) {
          if (field_type == 'hyper_link' && param.value != 'Total') {
            param.value = report_data_formatter.get_link_value(param.value);
          }
        }
        return param.value;
      },
      fileName: report_name
    };
    csv_data =
      this.title +
      'Payment and Adjustments' +
      '\n' +
      this.state.patient_adjustments_grid_ref.api.getDataAsCsv(params) +
      '\n' +
      '\n' +
      '\n' +
      '\n' +
      'Claims' +
      '\n' +
      this.state.claims_grid_ref.api.getDataAsCsv(params);
    var blob = new Blob([csv_data], {
      type: 'text/csv;charset=utf-8'
    });
    //Blob.js
    saveAs(blob, report_name + '.csv');
  };

  set_title = separter => {
    this.company_name =
      this.props.user_login_details.user_data.data.gpms_code +
      '-' +
      this.props.user_login_details.user_data.data.company_name;
    this.patient_name = format_patient_name(this.props.selected_patient.data.name);
    this.account_no = 'Account#:' + session_storage.get('active_patient');
    this.current_date = report_constants.dateFormatter(this.state.current_date);
    return (this.title =
      'Payment Adjustments and Billing Log Detail' +
      separter +
      this.company_name +
      separter +
      this.patient_name +
      separter +
      this.account_no +
      separter +
      this.current_date +
      separter +
      separter);
  };

  //Function calls on click of print button.
  on_print_button = () => {
    var printContent = '';
    printContent += '<div class="reportPrint">';
    this.title = this.set_title('<br>');
    printContent += this.title;
    printContent += "<div style='font-weight: bold;font-size:16px'>Payment and Adjustments</div><br>";
    printContent += '<div class="reportPrint"><table><thead><tr>';
    var row_api = this.state.patient_adjustments_grid_ref.api;
    var col_api = this.state.patient_adjustments_grid_ref.columnApi;
    var header_data = col_api.getAllColumns();

    header_data.forEach(function(value, index) {
      printContent += '<th>' + value.colDef.headerName + '</th>';
    });
    printContent += '</tr></thead>';

    if (row_api.getModel().getRowCount() > 0) {
      row_api.forEachNodeAfterFilterAndSort(function(data, index) {
        printContent += '<tr>';
        header_data.forEach(function(colData, index) {
          printContent += '<td>' + data.data[colData.colDef.field] + '</td>';
        });
        printContent += '</tr>';
      });
    } else {
      printContent +=
        '<tr><td colspan="100%" class="norecords">' + report_constants.messages.no_records_found + '</td></tr>';
    }
    printContent += '</table></div><br>';

    printContent += "<div style='font-weight: bold;font-size:16px'>Claim Log</div><br/>";
    printContent += '<div class="reportPrint"><table><thead><tr>';
    row_api = this.state.claims_grid_ref.api;
    col_api = this.state.claims_grid_ref.columnApi;
    header_data = col_api.getAllColumns();

    header_data.forEach(function(value, index) {
      printContent += '<th>' + value.colDef.headerName + '</th>';
    });
    printContent += '</tr></thead>';
    if (row_api.getModel().getRowCount() > 0) {
      row_api.forEachNodeAfterFilterAndSort(function(data, index) {
        printContent += '<tr>';
        header_data.forEach(function(colData, index) {
          printContent += '<td>' + data.data[colData.colDef.field] + '</td>';
        });
        printContent += '</tr>';
      });
    } else {
      printContent +=
        '<tr><td colspan="100%" class="norecords">' + report_constants.messages.no_records_found + '</td></tr>';
    }
    printContent += '</table></div>';
    printContent += '</div>';

    print_html_data_to_pdf(printContent,'printButton','Payment Adjustments and Billing Log Detail');
    /*
    modal_hide_on_print(printContent);
    if (window.print) {
      if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
        setTimeout(function() {
          execute_print();
        }, 100);

        setTimeout(function() {
          modal_show_after_print_ios();
        }, 5000);
      } else {
        if (window.matchMedia) {
          var mediaQueryList = window.matchMedia('print');
          mediaQueryList.addListener(function(mql) {
            if (mql.matches) {
            } else {
              afterPrint();
            }
          });
        }
        var afterPrint = () => modal_show_after_print();
        // These are for Firefox
        window.onafterprint = afterPrint;
        execute_print();
      }
    }
    */
  };

  render() {
    return (
      <div className='item'>
        <Modal
          onClose={this.close_modal}
          centered={false}
          className='ledger-report-popup'
          open={this.props.show_modal}
          closeIcon
          closeOnDimmerClick={false}
          id='printPopup'
        >
          <Modal.Header>Payment Adjustments and Billing Log Detail</Modal.Header>
          <Modal.Content>
            <Dimmer active={this.state.loading}>
              <Loader size='massive'>Loading</Loader>
            </Dimmer>
            <Modal.Description>
              {this.state.is_show_pop_up && (
                <React.Fragment>
                  {/* <Header as='h3'>Charges</Header>
                  <GridView
                    id={report_constants.report_ledger_full_configuration.patient_report_grid_id}
                    row={this.state.report_data.charges}
                    style={{ height: '150px' }}
                    column={this.charges_column_def}
                    suppressMovableColumns={false}
                    enableColResize={true}
                    emptyMessage={report_constants.messages.no_records_found}
                    get_grid_ref={this.get_charges_grid_ref}
                    headerHeight={41}
                    onGridOut={() => {
                      set_focus_on_element_with_id(report_constants.report_payment_adjustment_header_id);
                    }}
                    headerIdForTabNavigation={report_constants.report_charges_grid_header_id}
                  /> */}
                  <Header as='h3'>Payment and Adjustments</Header>
                  <GridView
                    id={report_constants.report_ledger_full_configuration.patient_report_grid_id}
                    style={{ height: '250px' }}
                    row={this.state.report_data.payment_adjustment}
                    column={this.payment_adjustment_column_def}
                    suppressColumnVirtualisation={true}
                    suppressMovableColumns={false}
                    enableColResize={true}
                    emptyMessage={report_constants.messages.no_records_found}
                    get_grid_ref={this.get_patient_adjustments_grid_ref}
                    headerHeight={this.payment_adjustment_max_column_height}
                    headerIdForTabNavigation={report_constants.report_payment_adjustment_header_id}
                  />
                  <Header as='h3'>Claim Log</Header>
                  <GridView
                    id={report_constants.report_ledger_full_configuration.patient_report_grid_id}
                    style={{ height: '250px' }}
                    row={this.state.report_data.claims}
                    column={this.claims_column_def}
                    suppressMovableColumns={false}
                    enableColResize={true}
                    emptyMessage={report_constants.messages.no_records_found}
                    get_grid_ref={this.get_claims_grid_ref}
                    headerHeight={this.claims_max_column_height}
                    headerIdForTabNavigation={report_constants.report_claims_header_id}
                  />
                </React.Fragment>
              )}
            </Modal.Description>
            <Grid className='mar-t-0'>
              <Grid.Column computer={16} textAlign='right'>
                <Button type='reset' onClick={this.close_modal} basic>
                  Close
                </Button>
                <Button
                  type='submit'
                  id='printButton'
                  onKeyDown={event => {
                    handle_focus_on_tab(event, 'ledger_full_popup_close_icon');
                  }}
                  onClick={this.on_print_button}
                  disabled={this.state.is_print_disabled}
                  primary
                >
                  Print
                </Button>
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

//Get user and login details from store.
const mapStateToProps = state => {
  return {
    user_login_details: state.user_login_details,
    selected_patient: state.patient_details.patient_header
  };
};

export default connect(mapStateToProps)(LedgerVisitPopUpComponent);
