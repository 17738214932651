import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Grid, List } from 'semantic-ui-react';
import { handle_focus_on_tab, handle_focus_on_shift_tab, set_focus_on_element_with_id } from '../../shared/tab_navigation_utility';

interface IProps {
    available_contracts: any[],
    selected_contracts: any[],
    add_all_contracts: (event: any, data: import("semantic-ui-react").ButtonProps) => void,
    add_selected_contracts: (event: any, data: import("semantic-ui-react").ButtonProps) => void,
    remove_all_contracts: (event: any, data: import("semantic-ui-react").ButtonProps) => void,
    remove_selected_contracts: (event: any, data: import("semantic-ui-react").ButtonProps) => void,
    select_contracts_to_add: Function,
    select_contracts_to_remove: Function,
    select_contract_class: string,
    disable_elements: boolean
}

class ContractsSelectionBoxComponent extends React.PureComponent<IProps, any> {
    constructor(props) {
        super(props);
    }

    handle_add_selected_key_down = (event) => {

        //if (event.shiftKey && event.keyCode == '9') {
        //    event.preventDefault();

        //    if()
        //    set_focus_on_element_with_id(idToFocusOn);
        //}

        if (!event.shiftKey && event.keyCode == '9') {
            event.preventDefault();

            set_focus_on_element_with_id("add_selected_contracts");
        }
    }

    render() {

        return (
            <>
                <Grid.Column mobile={16} tablet={16} computer={8} className="padd-r-5 text-right">
                    <label className="valign-middle">Available Contracts</label>
                    <Button id="add_selected_contracts" primary onClick={this.props.add_selected_contracts} disabled={this.props.disable_elements}>Add Selected</Button>
                    <Button id="add_all_contracts" type='submit' primary onClick={this.props.add_all_contracts} disabled={this.props.disable_elements}>Add All</Button>
                    <div>
                        <List className={this.props.select_contract_class ? `selection-box ${this.props.select_contract_class}` : 'selection-box'}>
                            {
                                this.props.available_contracts.map((item, idx) => {
                                    
                                    return (<List.Item key={idx}><Checkbox id={item.id} label={item.name} name={item.name} checked={item.is_active} key={idx}
                                        onChange={(e, item) => this.props.select_contracts_to_add(e, item, idx)} disabled={this.props.disable_elements}/></List.Item>);
                                })
                            }
                        </List>
                    </div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8} className="padd-l-5 text-right">
                    <label className="valign-middle">Selected Contracts</label>
                    <Button id="remove_selected_contracts" primary onClick={this.props.remove_selected_contracts} disabled={this.props.disable_elements}>Remove Selected</Button>
                    <Button id="remove_all_contracts" type='submit' primary onClick={this.props.remove_all_contracts} disabled={this.props.disable_elements}>Remove All</Button>
                   
                    <div>
                        <List className={this.props.select_contract_class ? `selection-box ${this.props.select_contract_class}` :'selection-box'}>
                            {
                                this.props.selected_contracts.map((item, idx) => {
                                    
                                    return (<List.Item key={idx}><Checkbox id={item.id} label={item.name} name={item.name} checked={item.is_active} key={idx}
                                        onChange={(e, item) => this.props.select_contracts_to_remove(e, item, idx)} disabled={this.props.disable_elements}/></List.Item>);
                                })
                            }
                        </List>
                    </div>
                </Grid.Column>
            </>
        )
    }

}

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

const mapSTateToProps = (state) => {
    return {
        
    };
}

export default connect(mapSTateToProps, mapDispatchToProps)(ContractsSelectionBoxComponent);