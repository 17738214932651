import * as React from "react"
import { overridesCss } from "./overrides_constants";

export default class BadgeRendererComponent extends React.Component<any, any> {
    constructor(props) {
        super(props);
 
        this.state = {
            cellValue: BadgeRendererComponent.getValueToDisplay(props)
        }
    }
 
    render() {
        let entityIds: any = {};

        if (this.state.cellValue) {
            entityIds = JSON.parse(this.state.cellValue);
        }

        return this.retrieveBadge(entityIds.Ids);
    }
 
    static getValueToDisplay(params) {
        return params.valueFormatted ? params.valueFormatted : params.value;
    }

    private retrieveBadge = (items) => {
        if (items) {
            // Sanitize array
            items = items.toString().split(",");
        }

        if (items && items.length === 1 && items[0] == -1) {
            return (<label style={overridesCss.badgeStyle}><span className="badge-default"> ALL </span></label>);
        } else if (items && items.length > 0) {
            return (<label style={overridesCss.badgeStyle}><span className="badge-default"> {items.length} Selected </span></label>);
        }

        return (<label style={overridesCss.dashStyle}>-</label>);
    };
 };
