import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Form, Grid, Header } from 'semantic-ui-react';
import { toastr } from 'react-redux-toastr';
import { merge_accounts } from '../../patient/action/patient_action';
import AdvancedControl from '../../shared/component/advanced_control';
import AutoSearchComponent from '../../shared/component/auto_search_component';
import * as sharedConstants from "../../shared/shared_constants";
import { get_patient_search } from '../../patient/action/patient_search_action';
import * as global_constants from '../../global_constants';
import * as export_print_utility from '../../reports/util/export_print_utility';
import * as Action from './../../shared/action/autosearch_action';
import { format_dashes_number, format_date, get_age, get_all_error } from '../../shared/utility';
import { patient_messages } from '../../patient/patient_constants';
import { LoaderComponent } from '../../shared/component/loading_component';
import * as ReportConstants from '../../reports/report_constants';
import { generateAuditDataEntryPayload } from '../../reports/util/export_print_utility';

export class PatientMergeAccountsComponent extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            grid_data: {},
            patient_search_value: {
                patient_id_to_remove: {},
                patient_id_to_keep: {}
            },
            error: {
                patient_id_to_remove: false,
                patient_id_to_keep: false
            },
            errorMsg: "",
            show_patient_modal: false,
            grid_conf: {
                isPagination: true,
                gridAutoHeight: true,
                selectionType: 'single',
                paginationPageSize: 20,
            },
        };
        this.clear_quick_search = this.clear_quick_search.bind(this);
        this.onselection = this.onselection.bind(this);
        this.show_html_content_toaster = this.show_html_content_toaster.bind(this);
        this.on_patient_search = this.on_patient_search.bind(this);
        this.on_patient_grid_row_selection = this.on_patient_grid_row_selection.bind(this);
        this.get_data_list = this.get_data_list.bind(this);
        this.prepare_suggestion = this.prepare_suggestion.bind(this);
    }
    _is_mounted = false;
    reportId = '0';

    componentDidMount = () => {
        this._is_mounted = true;
    };

    componentWillUnmount = () => {
        this._is_mounted = false;
    };

    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }

    on_patient_search = (pat_obj, name) => {
        var params = {
            patient_name: {
                first_name: pat_obj.first_name,
                last_name: pat_obj.last_name   
            },
            show_in_active: name == "patient_id_to_remove" ? true : false,
            id: pat_obj.Id,
            page_size: pat_obj.page_size,
            company_id: this.props.user_login_details.user_data.data.company_id
        }
        var token = this.props.user_login_details.user_data.data.token_details.access_token;
        // Search function.
        if (this._is_mounted) {
            this.props.get_patient_search(params, token).then(res => {
                this.setState({
                    grid_data: { ...this.state.grid_conf, rows: this.props.patient_details.patient_search, column: sharedConstants.patient_search_configuration(sharedConstants.patient_search_configuration_id).column_defs, messages: "No Patient Found" }

                });
            });
        }
    }

    format_name = (name) => {
        return (name.last_name == null ? '' : name.last_name) + (name.middle_initial == null ? '' : ' ' + name.middle_initial) + (name.first_name == null ? '' : ' ' + name.first_name);
    }

    on_patient_grid_row_selection = (selected_row, name) => {
        var patient_name = this.format_name(selected_row.name)
        let patient_search_value = {
            'label': patient_name,
            'title': selected_row.id,
        }
        if (this._is_mounted) {
            this.setState(prevState => ({
                patient_search_value: {
                    ...prevState.patient_search_value, [name]: patient_search_value
                },
                error: { [name]: selected_row.id ? false : true },
                errorMsg: ""
            }));
        }
        
        this.closeModal();
    }

    closeModal = () => {
        if (this._is_mounted) {
            this.setState({ show_patient_modal: false, grid_data: {} })
        }
    }

    //It renders report grid and its search controls.
    get_data_list = async (inputValue, companyid, name) => {
        var token = this.props.user_login_details.user_data.data.token_details.access_token;
        let inactive_flag = name == "patient_id_to_remove" ? true : false;
        return await Action.get_searchList(token, companyid, inputValue.trim(), global_constants.constants.Quick_Search_Suggestion_List_Size, global_constants.end_points.patient_urls.quick_search, inactive_flag);
    }
   
    render_result = (props) => {
        return (
            <div key={props.title} tabIndex={0} className="item_auto_search">
                <div className='fs_13'>
                    <span className="bold">{props.label}</span><span>{props.title ? '(' + props.title + ')' : ''}</span>
                </div>
            </div>
        )
    }

    //Prepare suggestion list to show in Quick Search result
    prepare_suggestion = (data) => {
        let formattedList = [];
        if (data && data.data.length > 0) {
            data.data.map((item) => {
                formattedList.push({
                    "label": (item.last_name == null ? '' : item.last_name + ' ') + (item.middle_initial == null ? '' : item.middle_initial + ' ') + (item.first_name == null ? '' : item.first_name),
                    "title": item.id.toString(),
                    "email": item.email,
                    "dob": (item.date_of_birth == null ? '' : format_date(new Date(item.date_of_birth), false)).toString(),
                    "age": (item.date_of_birth == null ? '' : get_age(item.date_of_birth)) + ' Yrs',
                    "home": (item.phone.home == null || item.phone.home == '' ? ' ' : ' H: ' + format_dashes_number(item.phone.home)),
                    "work": (item.phone.work == null || item.phone.work == '' ? ' ' : ', W: ' + format_dashes_number(item.phone.work)),
                    "cell": (item.phone.cell == null || item.phone.cell == '' ? ' ' : ', M: ' + format_dashes_number(item.phone.cell)),
                });

                return;
            });
        }
        else {
            if (this._is_mounted) {
                this.setState({ quick_search_error_message: 'No Patient Found !' })
            }
        }
        return formattedList;

    }

    onselection = (item, name) => {
        if (this._is_mounted) {
            this.setState(prevState => ({
                patient_search_value: {
                    ...prevState.patient_search_value, [name]: {
                        'label': item.label,
                        'title': item.title,
                    }
                },
                error: { [name]: item.label ? false : true },
                errorMsg: ""
            }));
        }
    }

    clear_quick_search = (name) => {
        if (this._is_mounted) {
            this.setState(prevState => ({
                patient_search_value: {
                    ...prevState.patient_search_value, [name]: {}
                }
            }));
        }
    }

    handle_merge = async (event = null) => {
        if (!this.state.patient_search_value.patient_id_to_remove.title && this._is_mounted) {
            this.setState({ error: { patient_id_to_remove: true }, errorMsg: patient_messages.invalid_remove_id });
            toastr.error('', patient_messages.invalid_remove_id);
            return;
        }

        if (!this.state.patient_search_value.patient_id_to_keep.title && this._is_mounted)
        {
            this.setState({ error: { patient_id_to_keep: true }, errorMsg: patient_messages.invalid_keep_id});
            toastr.error('', patient_messages.invalid_keep_id);
            return;
        }

        if (this.state.patient_search_value.patient_id_to_remove.title == this.state.patient_search_value.patient_id_to_keep.title && this._is_mounted) {
            this.setState({ error: { patient_id_to_remove: true, patient_id_to_keep: true }, errorMsg: patient_messages.duplicate_remove_keep_id });
            toastr.error('', patient_messages.duplicate_remove_keep_id);
            return;
        }
        if (this._is_mounted) {
            this.setState({
                loading: true
            });
        }

        if ((this.state.error.patient_id_to_remove || this.state.error.patient_id_to_keep)  && event) {
            event.preventDefault();
        } else {
            var token = this.props.user_login_details.user_data.data.token_details.access_token;
            let criteria = this.state.patient_search_value

            let response = await merge_accounts(token, criteria.patient_id_to_remove.title, criteria.patient_id_to_keep.title);
            if (response && response.data.status == 1) {
                if (response.data && response.data.data) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                    toastr.success('', toastr_options);
                }
            } else {
                const toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                toastr.error('', toastr_options);
            }
            if (this._is_mounted) {
                this.setState({ errorMsg: get_all_error(response.data) })
            }

        }
        if (this._is_mounted) {
            this.setState({
                loading: false,
            });
        }
    }

    handle_print = () => {
        export_print_utility.print_merge_accounts("Merge Accounts", this.state.patient_search_value, this.state.errorMsg, 'print_patient_details');
        this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
    }

    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;

        const payload: ReportConstants.IPayloadForBillingAudit = {
            reportId: String(this.state.patient_search_value.patient_id_to_keep.title),
            contextTitle: `Merge Accounts - ${ReportConstants.ReportEventAction[eventActionId]}`,
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: ReportConstants.ReportEntityTypeId.D_Patient,
            data: {
                PatientToRemove: this.state.patient_search_value.patient_id_to_remove.title,
                PatientToKeep: this.state.patient_search_value.patient_id_to_keep.title }
        }

        const reqBody = generateAuditDataEntryPayload(payload);

        ReportConstants.saveAuditPrintExport(reqBody, accessToken);
    }

    render() {
        const { loading, patient_search_value, error, grid_data } = this.state
        return (
            <LoaderComponent loading={loading}>
                <div className='common-forms-add-flex common-forms-search patient-search'>
                    <Grid>
                        <Grid.Column computer={16}>
                            <Header as='h3' textAlign="left">Merge Accounts</Header>
                        </Grid.Column>
                    </Grid>
                    <div className='auto-height-patient-scroll' id='scrollable-content-area'>
                        <div className='border-wrap' >
                            <Form id="patient-search-criteria-container" className="patient-search-form" autoComplete="off" >
                                <Grid>
                                    <Grid.Column>
                                        <Grid>
                                            <Grid.Column mobile={8} tablet={6} computer={4}>
                                                <Form.Field className={`advance-quick-search ${error.patient_id_to_remove ? "requiredWithBgColor" : ""}`}>
                                                    <label>Patient ID to Remove<span className={error.patient_id_to_remove ? "req-alert" : 'req-alert_normal'}> (required)</span></label>
                                                    <AdvancedControl
                                                        onGridRowSelection={item => this.on_patient_grid_row_selection(item, "patient_id_to_remove")}
                                                        gridConfig={grid_data}
                                                        controlId={"PATIENT"}
                                                        onSearch={data => this.on_patient_search(data, "patient_id_to_remove")}
                                                        search_type={"PATIENT_REPORT"}
                                                        headerIdForGridTabNavigation={sharedConstants.patient_search_configuration_id}
                                                    />
                                                    <AutoSearchComponent
                                                        control_id="patient_header_quick_search_input"
                                                        default_value={patient_search_value.patient_id_to_remove}
                                                        errorMessage={"No Patient Found!"}
                                                        getList={(inputValue, companyId) => this.get_data_list(inputValue, companyId, "patient_id_to_remove")}
                                                        prepareRenderList={this.render_result}
                                                        prepareDataList={this.prepare_suggestion}
                                                        selectresult={item => this.onselection(item, "patient_id_to_remove")}
                                                        is_disabled={false}
                                                        show_clear_search={true}
                                                        clear_search={() => this.clear_quick_search("patient_id_to_remove")}
                                                        errorClass={error.patient_id_to_remove ? 'req-background-inp' : ''}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column mobile={8} tablet={6} computer={4}>
                                                <Form.Field className={`advance-quick-search ${error.patient_id_to_keep ? "requiredWithBgColor " : ""}`}>
                                                    <label>Patient ID to Keep<span className={error.patient_id_to_keep ? "req-alert" : 'req-alert_normal'}> (required)</span></label>
                                                    <AdvancedControl
                                                        onGridRowSelection={item => this.on_patient_grid_row_selection(item, "patient_id_to_keep")}
                                                        gridConfig={grid_data}
                                                        controlId={"PATIENT"}
                                                        onSearch={data => this.on_patient_search(data, "patient_id_to_keep")}
                                                        search_type={"PATIENT_REPORT"}
                                                        headerIdForGridTabNavigation={sharedConstants.patient_search_configuration_id}
                                                    />
                                                    <AutoSearchComponent
                                                        control_id="patient_header_quick_search_input"
                                                        default_value={patient_search_value.patient_id_to_keep}
                                                        errorMessage={"No Patient Found!"}
                                                        getList={(inputValue, companyId) => this.get_data_list(inputValue, companyId, "patient_id_to_keep")}
                                                        prepareRenderList={this.render_result}
                                                        prepareDataList={this.prepare_suggestion}
                                                        selectresult={item => this.onselection(item, "patient_id_to_keep")}
                                                        is_disabled={false}
                                                        show_clear_search={true}
                                                        clear_search={() => this.clear_quick_search("patient_id_to_keep")}
                                                        errorClass={error.patient_id_to_keep ? 'req-background-inp' : ''}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid>
                                    </Grid.Column>
                                </Grid>
                                <Grid style={{ margin: '0 -17px' }}>
                                    <Grid.Column computer={16} textAlign='right'></Grid.Column>
                                </Grid>
                            </Form>
                        </div>
                    </div>
                    <div className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area' id='applicationFooterSticky'>
                        <Button type="button" id="print_patient_details" basic onClick={this.handle_print}>Print</Button>
                        <Button id="patient_merge_btn" type='button' onClick={this.handle_merge} primary>Merge</Button>
                    </div>
                </div>
            </LoaderComponent>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        get_patient_search: get_patient_search
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        patient_details: state.patient_details,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientMergeAccountsComponent));