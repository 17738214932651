import * as React from 'react';
import { connect } from 'react-redux';
import { toastr as toaster } from 'react-redux-toastr';
import { Accordion, Button, Dimmer, Form, Grid, Icon, Loader } from 'semantic-ui-react';
import * as global_constants from '../../../../global_constants';
import { search_place} from '../../../../shared/action/autosearch_action';
import { advanced_search_class, get_claim_override_payer } from '../../../../shared/action/shared_action';
import { handle_click_on_enter, set_focus_to_app_header } from '../../../../shared/tab_navigation_utility';
import { check_valid_npi, is_null_or_white_space, nullifyString } from '../../../../shared/utility';
import * as constants from '../../constants';
import { is_alpha_numeric } from '../../utility';
import DrawerComponent from '../../../../shared/component/drawer_component';
import { Position, Drawer } from '@blueprintjs/core';
import { get_insurance_codes } from '../../action/constants_action';
import GridView from "../../../../shared/component/grid/index";
import { overrides, overridesCss } from "./overrides_constants";
import * as action from '../../action/overrides_action';
import LocationOverrideComponent from './LocationOverrideComponent';
import PromptNavigation from '../../../../shared/component/prompt_navigation_component';
import { AlertConfirm } from '../../../../shared/component/alert_confirm_component';



class OverridesComponent extends React.Component<any, any> {
    _is_mounted = false;
    // API Promises
    retrieveComponentDataPromise: Promise<string>;
    // Resolved Promises
    retrieveComponentDataPromiseResolve: any;
    // Rejected Promises
    retrieveComponentDataPromiseReject: any;
    // API Collector to Track
    apiPromisesCollector: Promise<string>[] = [];
    incomingPayload: any;
    page_metadata: any;
    token: string = '';
    company_id: Number = 0;
    claimOverrideId: 0;    

    required_field: any = {
        setting_Name: true,
    };

    overrideModel: any = {
        claim_Override_ID: null,
        company_ID: null,
        setting_Name: '',
        tax_Identification_Number: '',
        national_Provider_Identifier: '',
        taxonomy: '',
        pay_To_Name: '',
        pay_To_Address: '',
        pay_To_City: '',
        pay_To_State: '',
        pay_To_Zip_Code: '',
        location_ids: [],
        payer_ids: [],
        insurance_class_ids: [],
        insurance_code_ids: [],
        locations: null,
        payers: null,
        insurance_Classes: null,
        insurance_Codes: null,
        locations_Json_Version: null,
        payers_Json_Version: null,
        insurance_Classes_Json_Version: null,
        insurance_Codes_Json_Version: null,
        needsPersistence: false,
        modified_Date: null,
        read_Date_Time: null,
        is_Active: true,
        is_Active_Text: 'Active',
        row_Id:0
    };

    constructor(props) {
        super(props);
        // Define initial state value.
        this.state = {
            show_render: true,
            is_loading: true,
            is_submitted: false,
            is_save_button_disabled: false,
            isNewButtonDisabled: true,
            isSaveInProgress: false,
            required_error: {},
            error: {},
            locations: [],
            payers: [],
            insuranceClasses: [],
            insuranceCodes: [],
            overrides: [],
            unsavedOverrides: [],
            isOverrideDrawerOpened: false,
            controlsExpanded: {
                locationOverrides: true
            },
            overrideInMemoryStorage: {
                originalRowData: {}
            },
            openPanelMode: {
                isOnEditMode: false,
                isOnCreateMode: false
            },
            overrideTabId: '',
            alert: {
                showBox: false,
                message: ''
            },
            show_inactive:false
        };
        this.page_metadata = overrides(this.props);

        const { user_data } = this.props.user_login_details;
        this.token = user_data.data.token_details.access_token;
        this.company_id = user_data.data.company_id;
        this.claimOverrideId = this.props.match.params && this.props.match.params.id ? this.props.match.params.id : 0;
    }

    componentDidMount = () => {
        this._is_mounted = true;
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;

        this.loadComponentData();

        this.retrieveComponentDataPromise = new Promise((resolve, reject) => {
            this.retrieveComponentDataPromiseResolve = resolve;
            this.retrieveComponentDataPromiseReject = reject;
        });
    };

    get_overrides_data = async () => {
        await action.get_overrides_by_company(this.token).then(
            (response) => {
                if (response && response.data) {
                    let overrides = response.data.data;

                    // Add data that is used on client side
                    let override_counter: number = 0;
                    overrides = overrides.map((override,index) => ({
                        ...override,
                        row_Id:index,
                        is_Active: override.is_Active == null ? false : override.is_Active,
                        is_Active_Text: override.is_Active == true ? 'Active' : 'Inactive',
                        location_ids: is_null_or_white_space(override.locations) ? [] : JSON.parse(override.locations).Ids,
                        payer_ids: is_null_or_white_space(override.payers) ? [] : JSON.parse(override.payers).Ids,
                        insurance_class_ids: is_null_or_white_space(override.insurance_Classes) ? [] : JSON.parse(override.insurance_Classes).Ids,
                        insurance_code_ids: is_null_or_white_space(override.insurance_Codes) ? [] : JSON.parse(override.insurance_Codes).Ids,
                        needsPersistence: false
                    }));

                    this.setState({
                        overrides: [...overrides]
                    });
                }
            },
            (error) => {
                this.retrieveComponentDataPromiseReject('error')
            }
        );
    }

    refresh_overrides_data_post_apply = async (inMemoryOverridesList) => {
        this.setState({
            isOnEditMode: false,
            isOnCreateMode: false,
            isOverrideDrawerOpened: false
        });
        await action.refresh_overrides_data(this.token, inMemoryOverridesList).then(
            (response) => {
                if (response && response.data) {
                    let overrides = response.data.data;

                    // Add data that is used on client side
                    overrides = overrides.map((override,index) => ({
                        ...override,
                        row_Id:index,
                        is_Active: override.is_Active == null ? false : override.is_Active,
                        is_Active_Text: override.is_Active == true ? 'Active' : 'Inactive',
                        location_ids: is_null_or_white_space(override.locations) ? [] : JSON.parse(override.locations).Ids,
                        payer_ids: is_null_or_white_space(override.payers) ? [] : JSON.parse(override.payers).Ids,
                        insurance_class_ids: is_null_or_white_space(override.insurance_Classes) ? [] : JSON.parse(override.insurance_Classes).Ids,
                        insurance_code_ids: is_null_or_white_space(override.insurance_Codes) ? [] : JSON.parse(override.insurance_Codes).Ids
                    }));

                    this.setState({
                        overrides: [...overrides]
                    });

                    if (response.data.messages && response.data.messages.length > 0) {
                        toaster.success('', response.data.messages[0].message);
                    }
                }
            },
            (error) => {
                toaster.error('', this.page_metadata.messaging.errorGettingAnswerFromServer);
            }
        );
    }

    loadComponentData = async () => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        const params = { page_size: global_constants.constants.PAGE_SIZE };
        let insuranceCodes, locations, insuranceClasses, payers;

        this.apiPromisesCollector.push(this.retrieveComponentDataPromise);
        await this.get_overrides_data()
            .then(async () => {
            await get_insurance_codes(token).then(response => {
                insuranceCodes = response.data.data;
            }, (error) => {
                this.retrieveComponentDataPromiseReject('error');
            }).then(async () => {
                await search_place(params, token).then(response => {
                    locations = response.data.data.result;
                });
            }, (error) => {
                this.retrieveComponentDataPromiseReject('error');
            }).then(async () => {
                await advanced_search_class(params, token).then(response => {
                    insuranceClasses = response.data.data;
                });
            }, (error) => {
                this.retrieveComponentDataPromiseReject('error');
            }).then(async () => {
                await get_claim_override_payer(token).then(response => {
                    payers = [];

                    for (let payer in response.data.data) {
                        payers.push({
                            payer_key_id: response.data.data[payer].payer_id,
                            description: response.data.data[payer].display_name,
                        });
                    }
                });
            }, (error) => {
                this.retrieveComponentDataPromiseReject('error');
            }).then(() => {
                this.setState({
                    insuranceCodes: insuranceCodes,
                    locations: locations,
                    insuranceClasses: insuranceClasses,
                    payers: payers,
                    isNewButtonDisabled: false,
                    is_loading: false,
                });

                this.retrieveComponentDataPromiseResolve('ok');
            });
        });
    };

    isValidValueByFieldName = (value, fieldName) => {
        let error = false;

        switch (fieldName) {
            case 'national_Provider_Identifier':
                if (!isNaN(value)) {
                    error = (value && value.trim().length > 0) ? !check_valid_npi(value) : false;
                }
                break;

            case 'taxonomy':
                if (is_alpha_numeric(value)) {
                    error = (value && value.trim().length > 0 && value.trim().length < 10) ? true : false;
                }
                break;

            case 'setting_Name':
                let trimmedSettingName = value ? value.trim() : '',
                    thereIsSettingName = !is_null_or_white_space(trimmedSettingName);

                if (thereIsSettingName) {
                    error = trimmedSettingName.length > 80 ? true : false;
                } else {
                    error = true;
                }
                break;

            case 'tax_Identification_Number':
                if (is_alpha_numeric(value)) {
                    error = (value && value.trim().length > 9) ? true : false;
                }
                break;

            default:
                error = false;
                break;
        }

        return error;
    };

    validateAllOverrides = () => {
        let isError = false,
            error = this.state.error,
            overrides = this.state.overrides;

        // If no overrides, return false
        if (!overrides || overrides.length == 0) {
            return isError;
        }

        overrides.forEach(override => {
            for (let overrideField in override) {
                // Sanitize field contents
                override[overrideField] = override[overrideField] == null || override[overrideField] == undefined ? '' : override[overrideField];

                // Is field required and is null or empty, then add an error
                if (this.required_field[overrideField] && is_null_or_white_space(override[overrideField])) {
                    this.setState(prev_state => ({
                        required_error: {
                            ...prev_state.required_error,
                            [override]: true
                        }
                    }));
                    isError = true;
                }

                // Specific logic for each field validation
                error[override] = this.isValidValueByFieldName(override[overrideField], overrideField);

                if (!isError) {
                    isError = error[override];
                }
            }
        });

        this.setState(prev_state => ({
            error: {
                ...prev_state.error
            }
        }));

        if (isError) {
            toaster.error('', constants.mandatory_fields);
            this.setState({
                isSaveInProgress: false
            });

            isError = true;
        }

        return isError;
    };

    save_handler = (e, supress_warning:boolean) => {
        e.preventDefault();
        this.setState({
            is_submitted: true,
            isSaveInProgress: true,
            is_save_button_disabled: true,
        });

        let overridesThatNeedPersistence = this.getOverridesThatNeedPersistence();

        if (overridesThatNeedPersistence.length == 0) {
            toaster.success('', this.page_metadata.messaging.saveNoChanges);

            if (this._is_mounted) {
                this.setState({
                    is_submitted: false,
                    isSaveInProgress: false,
                    is_save_button_disabled: false,
                });
            }

            return;
        }
        
        let is_error = this.validateAllOverrides();
        
        if (is_error) {
            this.setState({
                is_submitted: false,
                isSaveInProgress: false,
                is_save_button_disabled: false,
            });
            return;
        }

        this.setState({
            is_loading: true
        });

        overridesThatNeedPersistence.forEach(override => {
            if (override) {
                override.modified_date = nullifyString(override.modified_date);
                override.modified_Date = nullifyString(override.modified_Date);
                override.read_Date_Time = nullifyString(override.read_Date_Time);
            }
        });

        action.saveOverrides(this.token, overridesThatNeedPersistence, supress_warning).then(
            async response => {
                // There is a response and does not have messages (errors)
                if (response.data && !response.data.messages) {
                    await this.get_overrides_data();
                } else if (response.data.messages && response.data.messages.length > 0) { // We have messages, show them
                    let warning_message_array = response.data.messages.filter(message => message.code == this.page_metadata.message_code.warning);
                    let validation_message_array = response.data.messages.filter(message => message.code == this.page_metadata.message_code.validation);

                    if (validation_message_array && validation_message_array.length > 0) {
                        this.show_toaster_error(validation_message_array)
                    }
                    else if (warning_message_array && warning_message_array.length > 0) {
                        this.show_warning_message(warning_message_array);
                    }
                } else { // Communication issues or server down
                    this.show_toaster_error(this.page_metadata.messaging.errorGettingAnswerFromServer);
                }
            },
            error => {
                if (error.response.data) {
                    this.show_toaster_error(error.response.data.messages);
                } else {                    
                    this.show_toaster_error(this.page_metadata.messaging.errorOnSave);
                }
            }
        ).then(() => {
            
            if (this._is_mounted && this.state.alert.showBox == false) {
                toaster.success('', this.page_metadata.messaging.saveSuccessful);
                this.setState({
                    is_loading: false,
                    is_save_button_disabled: false,
                    is_submitted: false,
                    isSaveInProgress: false
                });
            }
        });
    };

    show_toaster_error = (messages) => {
        if (messages && messages.length > 0) {
            let toaster_message = "";
            messages.forEach((element) => {
                if (toaster_message != "")
                    toaster_message = toaster_message + "<br>" + element.message;
                else
                    toaster_message = element.message;
            });
            if (toaster_message.indexOf('<br') > -1) {
                let toastr_options = this.show_html_content_toaster(toaster_message);
                toaster.error('', toastr_options);
            } else {
                toaster.error('', toaster_message);
            }

        }
    };

    cancel_handler = () => {
        this.setState({
            unsave_billing_rows: []
        }, () => {
            this.props.history.goBack();
        });
    };

    show_html_content_toaster = msg => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ),
            timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        };
    };

    getLocationOverrideComponent = () => {
        return (
            <LocationOverrideComponent
                saveHandlerCallback={this.overrideDrawerCallback}
                token={this.token}
                company_ID={this.company_id}
                {...this.state} />
        );
    };

    onNewOverrideHandler = () => {
        //Set the row Id.
        this.overrideModel.row_Id = this.state.overrides.length;

        this.setState({
            openPanelMode: {
                isOnEditMode: false,
                isOnCreateMode: true
            },
            overrideInMemoryStorage: {
                originalRowData: this.overrideModel
            },
            overrideTabId: '',
            isOverrideDrawerOpened: true
        });
    };

    onEditOverrideHandler = (item) => {

        //Ignore the repeated row clicks to open the drawer else logs 429 error.
        if (this.state.isOverrideDrawerOpened && (this.state.openPanelMode.isOnEditMode || this.state.openPanelMode.isOnCreateMode)) return;

        let fieldName = item.colDef.field,
            tabId = '',
            override = item.data;

        switch(fieldName) {
            case 'payers':
                tabId = 'payer_id';
                break;
            case 'insurance_Classes':
                tabId = 'insurance_class_id';
                break;
            case 'insurance_Codes':
                tabId = 'insurance_code_id';
                break;
            case 'locations':
            default:
                tabId = 'location_id';
                break;
        }

        this.setState({
            openPanelMode: {
                isOnEditMode: true,
                isOnCreateMode: false
            },
            overrideInMemoryStorage: {
                originalRowData: override
            },
            isOverrideDrawerOpened: true,
            overrideTabId: tabId,
        });
    };

    onOverrideDrawerCloseHandler = async () => {
        this.setState({ isOverrideDrawerOpened: false });
        await this.refresh_overrides_data_post_apply(this.state.overrides);
    }

    overrideDrawerCallback =  (incomingPayload) => {
        let currentOverrides = [...this.state.overrides];

        if (this.state.openPanelMode.isOnEditMode) {
            currentOverrides[incomingPayload.row_Id] = incomingPayload;
        }

        if (this.state.openPanelMode.isOnCreateMode) {
            incomingPayload.company_ID = this.company_id;
            currentOverrides.push({ ...incomingPayload });

            this.setState({
                unsavedOverrides: [...currentOverrides, currentOverrides.length - 1]
            });
        }

        this.refresh_overrides_data_post_apply(currentOverrides);

        
    };

    getGridRowForDisplay = () => {
        let return_overrides;
        if (this.state.show_inactive)
            return_overrides = this.state.overrides
        else {
            return_overrides = this.state.overrides.filter(override => {
                if (override.is_Active) return override;
            });
        }
           
        return return_overrides;
    }

    showInactiveHandler = (e) => {
        let chk_box_selection = e.target.checked;
        this.setState({ show_inactive: chk_box_selection});
    }

    getOverridesThatNeedPersistence = () => {
        let overridesThatNeedPersistence = this.state.overrides.filter(function (override) {
                return typeof override.needsPersistence != undefined && override.needsPersistence;
            }
        );

        return overridesThatNeedPersistence;
    };

    accordionToggle = (type: React.ReactText) => {
        if (this._is_mounted) {
            this.setState((prev_state) => ({
                controlsExpanded: {
                    ...prev_state.controlsExpanded,
                    [type]: !prev_state.controlsExpanded[type]
                }
            }));
        }
    };

    getRowHeight = () => 40;

    is_data_updated = (): boolean => {
        
        let data_to_be_persisted = this.getOverridesThatNeedPersistence();
        let is_data_updated: boolean = false;
        if (data_to_be_persisted && data_to_be_persisted.length > 0) is_data_updated = true; 

        return is_data_updated
    }

    // #region Alert Box
    show_warning_message = (warning_message_array) => {

        if (!warning_message_array && warning_message_array.length == 0) return;

        let msg_only_array = [];
        warning_message_array.map((error_msg) => {
            msg_only_array.push(error_msg.message);
        })

        let alert_config = this.state.alert;
        alert_config.showBox = true;
        alert_config.message = msg_only_array.join('\n');
        this.setState({ alert: alert_config });
    }

    on_cancel_alert_box = () => {
        let alert_config = this.state.alert;
        alert_config.showBox = false;
        alert_config.message = '';
        this.setState({ alert: alert_config, is_loading: false, is_save_button_disabled:false });
    }

    on_alert_continue = (e) => {
        let alert_config = this.state.alert;
        alert_config.showBox = false;
        alert_config.message = '';
        this.setState({ alert: alert_config })
        this.save_handler(e, true);
    }
    // #endregion Alert Box

    render() {
        const { controlsExpanded } = this.state;
        let is_data_updated = this.is_data_updated();
        return (
            this.state.show_render && (
                <React.Fragment>
                    <Dimmer active={this.state.is_loading}>
                        <Loader size='massive'>Loading</Loader>
                    </Dimmer>
                    <PromptNavigation
                        is_data_changed={is_data_updated}
                        go_next_location={is_data_updated}
                        prompt_message={this.page_metadata.unSavedChangesMsg}
                        save={e => this.save_handler(e, false)}
                        history={this.props.history}
                        hide_yes_button={true}
                        no_button_text="Continue"
                        no_button_class_name="primary"
                    />
                    <AlertConfirm
                        open={this.state.alert.showBox}
                        close={() => this.on_cancel_alert_box()}
                        cancel={() => this.on_cancel_alert_box()}
                        confirm={(e) => this.on_alert_continue(e)}
                        message={this.state.alert.message}
                        title={'Warning'}
                        labelYes={'Continue'}
                    />
                    <div className={'admin-wrapper view-edit Locations ' + this.page_metadata.id} style={overridesCss.locations_id}>
                        <Grid className='headerGrid' style={overridesCss.headerGrid}>
                            <Grid.Column computer={16}>
                                <h3 className='ui header left aligned'>{this.page_metadata.title}</h3>
                            </Grid.Column>
                        </Grid>
                        <div className='wrapper' style={overridesCss.wrapper}>
                            <Form autoComplete='off' name='view_edit_form' className='common-forms' style={overridesCss.common_forms}>
                                <div id='admin-scrollable-area' style={overridesCss.scrollbar_area}>
                                    {/* Location Override */}
                                    <Grid className='common-accordion' style={overridesCss.accordionColumn}>
                                        <Grid.Column
                                            computer={16}
                                            tablet={16}
                                            textAlign='left'
                                            className='accordionColumn'
                                            id='accordion-column'
                                        >
                                            <Accordion fluid styled>
                                                <Accordion.Title active={controlsExpanded.locationOverrides} index={0} onClick={() => this.accordionToggle('locationOverrides')}>
                                                    <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name='angle right' />
                                                    Location Override
                                                </Accordion.Title>
                                                <Accordion.Content active={controlsExpanded.locationOverrides} style={overridesCss.accordion_content}>
                                                    <Grid>
                                                        <Grid.Column tablet={8} computer={16}>
                                                            <Form.Field className='ui checkbox'>
                                                                <div >
                                                                    <input
                                                                        type='checkbox'
                                                                        name='show_inactive_chkbox'
                                                                        id='show_inactive_chkbox'
                                                                        checked={this.state.show_inactive}
                                                                        onChange={e => this.showInactiveHandler(e)}
                                                                    />
                                                                    <label className='chkbx_label' htmlFor='show_inactive'>
                                                                        Show Inactive
                                                                    </label>
                                                                </div>
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column computer={16}>
                                                        <React.StrictMode>
                                                            <GridView
                                                                id="overridesComponentGrid"
                                                                row={this.getGridRowForDisplay()}
                                                                column={this.page_metadata.column_defs}
                                                                enableColResize={true}
                                                                gridAutoHeight={true}
                                                                headerIdForTabNavigation="error_corrections_configuration_header_id"
                                                                emptyMessage={'<span></span>'}
                                                                checkboxSelection={true}
                                                                handleCheckboxNavigation={true}
                                                                getRowHeight = {this.getRowHeight}
                                                                onCellClicked={this.onEditOverrideHandler}
                                                                suppressHorizontalScroll={true}
                                                                wrapperClass={'overridesGridWrapperClass'}
                                                            />
                                                        </React.StrictMode>
                                                        </Grid.Column>
                                                    </Grid>
                                                    <Grid>
                                                        <Grid.Column computer={16} textAlign="right" style={overridesCss.new_button_section}>
                                                            <Button
                                                                id='id_btn_new_row'
                                                                type='button'
                                                                onClick={this.onNewOverrideHandler}
                                                                primary
                                                                disabled={this.state.isNewButtonDisabled}
                                                                style={overridesCss.new_button}
                                                                content="New"
                                                            />
                                                        </Grid.Column>
                                                    </Grid>
                                                </Accordion.Content>
                                            </Accordion>
                                        </Grid.Column>
                                    </Grid>
                                </div>
                                <div
                                    className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                                    id='applicationFooterSticky'
                                    style={overridesCss.footerArea}
                                >
                                    <Button
                                        id='cancel_button_id'
                                        type='button'
                                        onClick={this.cancel_handler}
                                        basic
                                        content="Cancel"
                                    />
                                    <Button
                                        id='save_button_id'
                                        type='button'
                                        disabled={this.state.is_save_button_disabled}
                                        onKeyDown={set_focus_to_app_header}
                                        onClick={e => this.save_handler(e, false)}
                                        primary style={overridesCss.save_button}
                                        content="Save"
                                    />
                                </div>
                            </Form>
                        </div>
                    </div>
                    {this.state.isOverrideDrawerOpened && <DrawerComponent
                        key={this.props.key_id}
                        canOutsideClickClose={false}
                        canEscapeKeyClose={false}
                        isOpen={this.state.isOverrideDrawerOpened}
                        className="ext-bp-drawer provider-drawer billing-provider-drawer claim-provider-drawer"
                        handleDrawerClose={this.onOverrideDrawerCloseHandler}
                        position={Position.RIGHT}
                        title=""
                        isCloseButtonShown={true}
                        innerContent={this.getLocationOverrideComponent()}
                        size={Drawer.SIZE_STANDARD}
                    />}
                </React.Fragment>
            )
        );
    }
};

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
        shared_details: state.shared_details,
    };
};

export default connect(mapStateToProps)(OverridesComponent);
