import * as React from 'react';
import { is_firefox } from '../utility';

interface IProps {
    onChange: Function;
    onBlur?: Function;
    onFocus?: Function;
    defaultValue?: string;
    placeHolder?: string;
    displayType?: string;
    className?: string;
    allowNegative?: boolean;
    suffix?: string;
    prefix?: string;
    focused?: boolean;
    fixedDecimalScale?: boolean;
    maxLength?: number;
    disabled?: boolean;
    id?: string;
    name?: string;
    onKeyDown?: Function;
    is_decimal_allowed?: boolean;
    decimalScale?: number;
}

export default class PercentageControl extends React.Component<IProps, any> {
    static defaultProps: Partial<IProps> = {
        defaultValue: '',
        placeHolder: '',
        displayType: 'text',
        className: 'numberFormat',
        allowNegative: true,
        suffix: '',
        prefix: '$',
        focused: false,
        fixedDecimalScale: true,
        maxLength: 13,
        disabled: false,
        id: 'numberFormat',
        name: 'numberFormat',
        is_decimal_allowed: true,
        decimalScale: 2
    };
    constructor(props) {
        super(props);
        this.state = {
            amount: '',
            value: this.props.defaultValue,
            formatted_value: '',
            max_length: typeof this.props.maxLength != 'undefined' ? this.props.maxLength : 13,
            disabled: typeof this.props.disabled != 'undefined' ? this.props.disabled : false
        };
    }

    amount_ref: any;
    is_separator = 0;
    is_amount_changed = '';
    is_minus = false;
    selection_start = 0;
    element = null;
    back_space = false;
    delete_btn = false;
    max_length = typeof this.props.maxLength != 'undefined' ? this.props.maxLength : 13;
    selectionBeforeInput: {
        selectionStart: number;
        selectionEnd: number;
    };
    need_update = true;
    is_first_time_char = true;

    componentDidUpdate(prevProps, prevState) {
        if (String(prevState.value) != String(this.state.value)) {
            // on change id is sent with onChange function
            // to improve slowness of multiple controls
            let on_change_id = null;
            // transforming on_change_id
            if (this.props.id && this.props.id.replace) {
                on_change_id = this.props.id.replace('_txt', '');
            }
            // if on change id is null, code wont break,
            // one must write code and props id to improve slowness of the control
            this.props.onChange(this.state.value, on_change_id);
        }
        if (this.props.defaultValue != prevProps.defaultValue) {
            let filtered = this.formatNumString(this.props.defaultValue);
            this.setState({ value: this.props.defaultValue || '', formatted_value: filtered });
        }
        if (this.props.focused != prevProps.focused) {
            this.focus_text();
        }
        if (this.props.maxLength != prevProps.maxLength) {
            this.setState({ max_length: this.props.maxLength });
            this.max_length = this.props.maxLength;
        }
    }

    componentDidMount = () => {
        if (typeof this.props.defaultValue != 'undefined' && this.props.defaultValue != '') {
            let formatted_amount = this.format_amount(this.props.defaultValue, 2, ',', '.');
            this.setState({ formatted_value: formatted_amount });
        }
    };

    focus_text = () => {
        this.amount_ref.focus();
    };

    // update values on removal
    update_value = (value, new_value, index) => {
        let str_ar = value.split('');
        str_ar[index] = new_value;
        let new_str = str_ar.join('');
        if (new_str.indexOf('-') == -1) {
            new_str = this.is_minus ? '-' + new_str : new_str;
        }
        return new_str;
    };

    // remove values on backspace
    remove_value = (value, pos, key_code = 'Backspace') => {
        const str_ar = value.split('');
        key_code == 'Backspace' ? str_ar.splice(pos, 1) : str_ar.splice(pos, 1, '0');
        let new_str = str_ar.join('').replace(/[^\w\s\.]/gi, '');
        let formatter = this.format_amount(new_str, 2, ',', '.');
        if (formatter.indexOf('-') == -1) {
            formatter = this.is_minus ? '-' + formatter : formatter;
        }
        return formatter;
    };

    set_cursor = (ele, selection_start) => {
        let timeout_time = 1;
        if (is_firefox()) {
            timeout_time = 5;
        }
        setTimeout(() => {
            ele.focus();
            this.setCaretPosition(ele, selection_start, selection_start);
        }, timeout_time);
    };

    set_cursor1 = (ele, selection_start) => {
        setTimeout(() => {
            ele.focus();
            this.setCaretPosition(ele, selection_start, selection_start);
        }, 10);
    };

    format_amount = (number, places, thousand, decimal) => {
        const prefix = this.props.prefix || '';
        const suffix = this.props.suffix || '';
        number = Number(number);
        places = !isNaN((places = Math.abs(places))) ? places : 2;
        thousand = thousand || ',';
        decimal = decimal || '.';
        const negative = number < 0 ? '-' : '';
        const i = parseInt((number = Math.abs(+number || 0).toFixed(places)), 10) + '';
        var j = (j = i.length) > 3 ? j % 3 : 0;
        return (
            negative +
            prefix +
            (j ? i.substr(0, j) + thousand : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousand) +
            (places
                ? decimal +
                  Math.abs(number - Number(i))
                      .toFixed(places)
                      .slice(2)
                : '') +
            suffix
        );
    };

    // get separators for amount string
    getSeparators() {
        const decimalSeparator = '.';
        let thousandSeparator = ',';
        return {
            decimalSeparator,
            thousandSeparator
        };
    }

    // spilt a float number into different parts beforeDecimal, afterDecimal, and negation
    splitDecimal = (numStr: string, allowNegative: boolean = true) => {
        const hasNegation = numStr[0] === '-';
        const addNegation = hasNegation && allowNegative;
        numStr = numStr.replace('-', '');
        const parts = numStr.split('.');
        const beforeDecimal = parts[0];
        const afterDecimal = parts[1] || '';
        return {
            beforeDecimal,
            afterDecimal,
            hasNegation,
            addNegation
        };
    };

    // remove all suffix and prefix in amount string
    removePrefixAndSuffix(val: string) {
        // remove prefix and suffix
        if (val) {
            const isNegative = val[0] === '-';
            // remove negation sign
            if (isNegative) val = val.substring(1, val.length);

            // remove prefix
            val =
                this.props.prefix && val.indexOf(this.props.prefix) === 0
                    ? val.substring(this.props.prefix.length, val.length)
                    : val;

            // remove suffix
            const suffixLastIndex = val.lastIndexOf(this.props.suffix);

            val =
                this.props.suffix && suffixLastIndex !== -1 && suffixLastIndex === val.length - this.props.suffix.length
                    ? val.substring(0, suffixLastIndex)
                    : val;
            // add negation sign back
            if (isNegative) val = '-' + val;
        }
        return val;
    }

    // remove formatting from amount string
    removeFormatting(val: string) {
        if (!val) return val;
        val = this.removePrefixAndSuffix(val);
        val = this.getFloatString(val);
        return val;
    }
    // complete formate the number
    formatInput(value: string = '') {
        value = this.removeFormatting(value);
        return this.formatNumString(value) || '';
    }

    // add thousand separter to number
    getThousandsGroupRegex = (thousandsGroupStyle: string) => {
        switch (thousandsGroupStyle) {
            case 'lakh':
                return /(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g;
            case 'wan':
                return /(\d)(?=(\d{4})+(?!\d))/g;
            case 'thousand':
            default:
                return /(\d)(?=(\d{3})+(?!\d))/g;
        }
    };

    limitToScale = (numStr: string, scale: number) => {
        let str = '';
        const filler = this.props.fixedDecimalScale ? '0' : '';
        for (let i = 0; i <= scale - 1; i++) {
            str += numStr[i] || filler;
        }
        return str;
    };

    // add negation if if there
    formatNumString(numStr: string = '') {
        let formattedValue = numStr;
        if (numStr === '' || numStr === null) {
            formattedValue = '';
        } else if (numStr === '-') {
            formattedValue = '-';
        } else {
            formattedValue = this.formatAsNumber(formattedValue.toString());
        }

        return formattedValue;
    }

    // formate normal amount string with prefix and dot, separators
    formatAsNumber = (numStr: string) => {
        const thousandsGroupStyle = 'thousand';
        const { thousandSeparator, decimalSeparator } = this.getSeparators();

        const hasDecimalSeparator =
            numStr.indexOf('.') !== -1 || (this.props.decimalScale && this.props.fixedDecimalScale);
        let { beforeDecimal, afterDecimal, addNegation } = this.splitDecimal(numStr, this.props.allowNegative); // eslint-disable-line prefer-const

        // apply decimal precision if its defined
        if (this.props.decimalScale !== undefined)
            afterDecimal = this.limitToScale(afterDecimal, this.props.decimalScale);

        if (thousandSeparator) {
            const thousandsGroupRegex = this.getThousandsGroupRegex(thousandsGroupStyle);
            beforeDecimal = beforeDecimal.replace(thousandsGroupRegex, '$1' + thousandSeparator);
        }

        // add prefix and suffix
        if (this.props.prefix) beforeDecimal = this.props.prefix + beforeDecimal;
        if (this.props.suffix) afterDecimal = afterDecimal + this.props.suffix;

        // restore negation sign
        if (addNegation) beforeDecimal = '-' + beforeDecimal;

        numStr = beforeDecimal + ((hasDecimalSeparator && decimalSeparator) || '') + afterDecimal;

        return numStr;
    };

    // get current curser position
    getCurrentCaretPosition = (el) => {
        let selectionStart = el.selectionStart;
        let selectionEnd = el.selectionEnd;
        return Math.max(selectionStart, selectionEnd);
    };

    // fetch changed cursor index
    findChangedIndex(prevValue: string, newValue: string) {
        let i = 0,
            j = 0;
        const prevLength = prevValue.length;
        const newLength = newValue.length;
        while (prevValue[i] === newValue[i] && i < prevLength) i++;

        // check what has been changed from last
        while (
            prevValue[prevLength - 1 - j] === newValue[newLength - 1 - j] &&
            newLength - j > i &&
            prevLength - j > i
        ) {
            j++;
        }
        return { start: i, end: prevLength - j };
    }

    // fetch correct value with prefix and formatting
    correctInputValue(caretPos: number, lastValue: string, value: string) {
        const { decimalSeparator } = this.getSeparators();
        const lastNumStr = this.state.numAsString || '';
        const { selectionStart, selectionEnd } = this.selectionBeforeInput;
        const { start, end } = this.findChangedIndex(lastValue, value);

        /** Check if only . is added in the numeric format and replace it with decimal separator */
        if (decimalSeparator == '.' && selectionStart === selectionEnd && value[selectionStart] === '.') {
            return [
                value.substr(0, selectionStart) + decimalSeparator + value.substr(selectionStart + 1, value.length),
                false
            ];
        }

        /* don't do anything if something got added,
         or if value is empty string (when whole input is cleared)
         or whole input is replace with a number
        */
        const leftBound = this.props.prefix.length;
        const rightBound = lastValue.length - this.props.suffix.length;
        let formatted = this.removeFormatting(value);
        if (
            formatted.length > lastValue.length ||
            !value.length ||
            start === end ||
            (selectionStart === 0 && selectionEnd === lastValue.length) ||
            (selectionStart === leftBound && selectionEnd === rightBound)
        ) {
            // this is when someone is trying to type before $ sign and the input has some previous value other than 0.00
            if (lastValue == '0.00' && selectionStart != 1 && selectionEnd != 1) {
                // making cursor point to 2 positions
                return [value, 2];
            }

            // this is when someone is trying to type before $ sign and the input has some previous value other than 0.00
            if (value.indexOf(this.props.prefix) > 0) {
                // making cursor point to 2 positions
                if (value.indexOf('-') !== -1 && value.indexOf(this.props.prefix) !== 2) {
                    return [value, false];
                }
                return [value, value.indexOf(this.props.prefix) + 1];
            }

            return [value, false];
        }

        // for numbers check if beforeDecimal got deleted and there is nothing after decimal,
        // clear all numbers in such case while keeping the - sign
        const numericString = this.removeFormatting(value);
        let { beforeDecimal, afterDecimal, addNegation } = this.splitDecimal(numericString, this.props.allowNegative); // eslint-disable-line prefer-const

        // clear only if something got deleted
        const isBeforeDecimalPoint = caretPos < value.indexOf(decimalSeparator) + 1;
        if (
            numericString.length < lastNumStr.length &&
            isBeforeDecimalPoint &&
            beforeDecimal === '' &&
            !parseFloat(afterDecimal)
        ) {
            return [addNegation ? '-' : '', false];
        }

        if (value.indexOf('-') === 1) {
            return [value, 3];
        }

        return [value, false];
    }

    setCaretPosition = (el, caretPos: number, select_end, use_range: any = false) => {
        el.value = el.value;
        // ^ this is used to not only get "focus", but
        // to make sure we don't have it everything -selected-
        // (it causes an issue in chrome, and having it doesn't hurt any other browser)
        if (el !== null) {
            if (el.createTextRange) {
                const range = el.createTextRange();
                range.move('character', caretPos);

                range.select();
                return true;
            }
            if (el.selectionStart || el.selectionStart === 0) {
                el.focus();

                // adding a patch to make code run
                if (use_range) {
                    el.setSelectionRange(select_end, select_end);
                    return true;
                }
                el.setSelectionRange(caretPos, caretPos);
                return true;
            }

            // fail city, fortunately this never happens (as far as I've tested) :)
            el.focus();
            return false;
        }
    };

    // set cursor with a moment later using set timeout
    setPatchedCaretPosition(el, caretPos: number, double_range: any = false) {
        let endPos = caretPos;
        if (double_range) {
            endPos = double_range;
        }

        this.setCaretPosition(el, caretPos, endPos, double_range);
    }

    // on change on text
    onChange = (e) => {
        e.persist();
        const el = e.target;
        let inputValue = el.value;
        let move_caret_to_double: any = false;
        const { state } = this;
        const lastValue = state.value || '';
        let currentCaretPosition = this.getCurrentCaretPosition(el);
        if (this.is_amount_changed) {
            inputValue = this.is_amount_changed == 'empty' ? '' : this.is_amount_changed;
        } else {
            let res = this.correctInputValue(currentCaretPosition, lastValue, inputValue);
            inputValue = res[0];
            move_caret_to_double = res[1];
        }
        let formattedValue = this.formatInput(inputValue) || '';
        currentCaretPosition = this.getExpectedCaretPosition(currentCaretPosition, formattedValue);
        let numAsString = this.removeFormatting(formattedValue);
        if (this.back_space) {
            currentCaretPosition = this.find_pos_on_removed(el.defaultValue, formattedValue, currentCaretPosition);
            if (this.props.prefix) {
                if (formattedValue == `-${this.props.prefix}0.00${this.props.suffix}`) {
                    formattedValue = `${this.props.prefix}0.00${this.props.suffix}`;
                    numAsString = '0.00';
                    currentCaretPosition = currentCaretPosition - 1;
                }
            } else {
                if (formattedValue == `-0.00${this.props.suffix}`) {
                    formattedValue = `0.00${this.props.suffix}`;
                    numAsString = '0.00';
                    currentCaretPosition = currentCaretPosition;
                }
            }
            this.back_space = false;
        }
        if (this.delete_btn) {
            currentCaretPosition = this.find_pos_on_deleted(el.defaultValue, formattedValue, el.selectionStart);
            this.delete_btn = false;
        }
        this.is_amount_changed = '';
        if (
            formattedValue == `${this.props.prefix}0.00${this.props.suffix}` &&
            state.formatted_value.indexOf('-') == 0
        ) {
            currentCaretPosition = 1;
        }
        this.update_amount(numAsString, formattedValue, currentCaretPosition, el, move_caret_to_double);
    };

    // get upcoming caret position after changed the amount sting
    getExpectedCaretPosition = (caretPos, nextValue) => {
        const special_chr_count = (nextValue.match(/,/g) || []).length;
        if (special_chr_count != this.is_separator) {
            caretPos = caretPos + 1;
            this.is_separator = special_chr_count;
        }
        return caretPos;
    };

    escapeRegExp(str: string) {
        return str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
    }

    getNumberRegex(g: boolean, ignoreDecimalSeparator?: boolean) {
        let decimalScale = this.props.decimalScale;
        const { decimalSeparator } = this.getSeparators();
        return new RegExp(
            '\\d' +
                (decimalSeparator && decimalScale != 0 && !ignoreDecimalSeparator
                    ? '|' + this.escapeRegExp(decimalSeparator)
                    : ''),
            g ? 'g' : undefined
        );
    }

    // decide float value in amount string
    getFloatString(num: string = '') {
        let decimalScale = 2;
        const { decimalSeparator } = this.getSeparators();
        const numRegex = this.getNumberRegex(true);

        // remove negation for regex check
        let hasNegation = num[0] === '-';
        if (num.indexOf('-') !== -1) {
            hasNegation = true;
        }
        if (hasNegation) num = num.replace('-', '');

        // if decimal scale is zero remove decimal and number after decimal Separator
        if (decimalSeparator && decimalScale === 0) {
            num = num.split(decimalSeparator)[0];
        }
        num = (num.match(numRegex) || []).join('').replace(decimalSeparator, '.');

        // remove extra decimals
        const firstDecimalIndex = num.indexOf('.');
        if (firstDecimalIndex !== -1) {
            num = `${num.substring(0, firstDecimalIndex)}.${num
                .substring(firstDecimalIndex + 1, num.length)
                .replace(new RegExp(this.escapeRegExp(decimalSeparator), 'g'), '')}`;
        }

        // add negation back
        if (hasNegation) num = '-' + num;

        return num;
    }

    // finally update amount string to state
    update_amount = (value, formatted_value, caretPos, input, move_caret_to_double: boolean = false) => {
        this.setState({ value: value, formatted_value: formatted_value }, () => {
            this.setPatchedCaretPosition(input, caretPos, move_caret_to_double);
        });
    };

    // get changed curser position in amount string
    find_pos_on_removed = (lastValue, value, selection_start) => {
        const sp_chr_count = (value.match(/,/g) || []).length;
        const last_spe_char_count = (lastValue.match(/,/g) || []).length;
        const reduced_pos = last_spe_char_count - sp_chr_count;
        let is_reduce = last_spe_char_count != sp_chr_count ? 1 : 0;
        let new_pos = selection_start - is_reduce - reduced_pos;
        return new_pos;
    };

    find_pos_on_deleted = (lastValue, value, selection_start) => {
        const sp_chr_count = (value.match(/,/g) || []).length;
        const last_spe_char_count = (lastValue.match(/,/g) || []).length;
        let reduced_pos = last_spe_char_count - sp_chr_count;
        if (lastValue[selection_start + 1] == ',' && lastValue[selection_start - 1] != this.props.prefix) {
            reduced_pos = reduced_pos - 1;
        }
        if (lastValue[selection_start + 1] == ',' && lastValue[selection_start - 1] == this.props.prefix) {
            reduced_pos = 0;
        }
        if (lastValue[selection_start] == ',') {
            selection_start = value[selection_start] == '.' ? selection_start : selection_start + 1;
            reduced_pos = 0;
        }
        let new_pos = selection_start - reduced_pos;
        return new_pos;
    };

    // update max length when all digit filled upto max length and you want to add negative sign (it will reset on removal on negative)
    update_max_length = (is_minus) => {
        let is_add = is_minus ? this.props.maxLength + 1 : this.props.maxLength;
        this.setState({ max_length: is_add });
        this.is_minus = is_minus;
        this.max_length = is_add;
    };

    // onkeydown event it get fired
    on_key_press = (e) => {
        e.persist();
        e.stopPropagation();
        let ele = e.target;
        this.element = ele;
        const value = e.currentTarget.defaultValue;
        let is_minus = value.indexOf('-') == -1 ? false : true;
        if (is_minus != this.is_minus) {
            this.update_max_length(is_minus);
        }
        const value_length = e.currentTarget.defaultValue.length;
        let selection_start = e.currentTarget.selectionStart;
        const selection_end = e.currentTarget.selectionEnd;
        selection_start = selection_start < selection_end ? selection_start : selection_end;
        let final = '';
        let selection = this.getCurrentCaretPosition(ele);
        const last_value = !isNaN(e.key) ? Number(e.key) : String.fromCharCode(e.which);
        const dot_index = value.indexOf('.');
        const special_chr_count = (value.match(/[,-]/g) || []).length;
        this.selectionBeforeInput = {
            selectionStart: selection_start,
            selectionEnd: selection_end
        };
        if (e.key == '.' || (e.key == 'Decimal' && this.props.is_decimal_allowed)) {
            if (value != '') {
                this.is_amount_changed = value;
                this.set_cursor(ele, dot_index + 1);
            } else {
                this.is_amount_changed = 'empty';
            }
        } else if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
            // on press digit
            if (this.props.allowNegative && value == '-') {
                let amount_val = this.update_value(value, last_value, 3);
                let formattedValue = this.formatInput(amount_val);
                this.is_amount_changed = formattedValue;
                this.update_amount(amount_val, formattedValue, 2, ele);
                this.is_minus = true;
            } else if (
                selection_end - selection_start == 1 &&
                (value[selection_start] == ',' || value[selection_start] == '.')
            ) {
                this.is_amount_changed = value;
                this.set_cursor(ele, selection_end);
            } else if (
                (selection_start == 0 && selection_end == value.length) ||
                (selection == 1 && selection_start !== selection_end)
            ) {
                let formattedValue = this.formatInput(last_value.toString()) || '';
                const numAsString = this.removeFormatting(formattedValue);
                this.is_amount_changed = formattedValue;
                this.update_amount(numAsString, formattedValue, 0, ele);
            } 
            else if (
                selection_start == selection_end &&
                value[selection_start - 1] == '0' &&
                value[selection_start - 2] == this.props.prefix
            ) {
                let decimal_val = value.split('.')[1];
                let formattedValue = this.is_minus
                    ? `-${this.props.prefix}${last_value}.${decimal_val}${this.props.suffix}`
                    : `${this.props.prefix}${last_value}.${decimal_val}${this.props.suffix}`;
                let currentCaretPosition = this.getExpectedCaretPosition(selection, formattedValue);
                const numAsString = this.removeFormatting(formattedValue);
                this.is_amount_changed = formattedValue;
                this.update_amount(numAsString, formattedValue, currentCaretPosition - 1, ele);
            } else if (selection_start == selection_end && selection_start == dot_index + 3) {
                let selection = this.getCurrentCaretPosition(ele);
                this.is_amount_changed = value;
                let amount_value = this.removeFormatting(value);
                this.update_amount(amount_value, value, selection + 1, ele);
            } else if (selection_start > dot_index && selection_end <= dot_index + 3) {
                final = this.update_value(value, last_value, selection_start);
                final = selection_end - selection_start == 2 ? `${final.substring(0, final.length - 1)}0` : final;
                this.is_amount_changed = final;
                let amount_value = this.removeFormatting(final);
                let pos = value_length != this.state.max_length ? selection_start : selection_start + 1;
                this.update_amount(amount_value, final, pos, ele);
            } else if (selection_start > dot_index && value_length == selection_start) {
                let selection = this.getCurrentCaretPosition(ele);
                final = this.update_value(value, last_value, selection - 1);
                let amount_value = this.removeFormatting(final);
                this.update_amount(amount_value, final, selection, ele);
            } else if (selection_start > dot_index && value_length > selection_start) {
                let selection = this.getCurrentCaretPosition(ele);
                final = this.update_value(value.trim(), last_value, selection);
                let formattedValue = this.formatInput(final);
                let amount_value = this.removeFormatting(final);
                selection = selection == this.max_length ? selection + 1 : selection;
                this.update_amount(amount_value, formattedValue, selection, ele);
            } else if (selection_start < dot_index && selection_end > dot_index && selection_end < dot_index + 2) {
                this.is_amount_changed = value;
                this.set_cursor(ele, selection_end);
            } else if (value == `${this.props.prefix}0.00${this.props.suffix}`) {
                final = this.update_value(value, last_value, 0);
                this.is_amount_changed = final;
                let amount_value = this.removeFormatting(final);
                this.update_amount(amount_value, final, 0, ele);
            }
        } else if (e.key == 'Backspace') {
            if (
                (dot_index == 1 && selection_start == 1 && selection_end == 1) ||
                (value.indexOf('-') == 0 && dot_index == 2 && selection_start == 2 && selection_end == 2)
            ) {
                let decimal_val = value.split('.')[1];
                this.is_amount_changed = `${this.props.suffix}0.${decimal_val}`;
            } else if (
                selection_start == 0 &&
                selection_end == value.length &&
                value == `${this.props.prefix}0.00${this.props.suffix}`
            ) {
                this.is_amount_changed = 'empty';
            } else if (dot_index == selection_start - 1) {
                let pos: any;
                pos = selection_start - 2;
                this.element = ele;
                this.is_amount_changed = this.remove_value(value, pos);
                let index = this.find_pos_on_removed(value, this.is_amount_changed, selection_start);
                this.setCaretPosition(ele, index - 1, index - 1);
            } else if (selection_start == value_length - 1) {
                final = this.update_value(value, 0, dot_index + 2);
                this.is_amount_changed =
                    final == `-${this.props.prefix}0.00${this.props.suffix}`
                        ? `${this.props.prefix}0.00${this.props.suffix}`
                        : final;
            } else if (selection_start == value_length - 2) {
                final = this.update_value(value, 0, dot_index + 1);
                this.is_amount_changed =
                    final == `-${this.props.prefix}0.00${this.props.suffix}`
                        ? `${this.props.prefix}0.00${this.props.suffix}`
                        : final;
            } else if (selection_start == 0 && selection_end == 0 && value.indexOf('-') == 0) {
                this.is_minus = false;
                final = this.remove_value(value, 0);
                this.is_amount_changed = final;
                this.set_cursor(ele, selection_start + 1);
            } else if (selection_start < dot_index && selection_end > dot_index && selection_end < dot_index + 2) {
                this.is_amount_changed = value;
                this.set_cursor(ele, selection_end);
            } else if (value[selection_start - 1] == this.props.prefix && selection_end == dot_index) {
                let decimal_val = value.split('.')[1];
                this.is_amount_changed =
                    decimal_val == '00'
                        ? `${this.props.prefix}0.00${this.props.suffix}`
                        : value.indexOf('-') == 0
                        ? `-${this.props.prefix}0.${decimal_val}${this.props.suffix}`
                        : `${this.props.prefix}0.${decimal_val}${this.props.suffix}`;
                this.set_cursor(ele, selection_start + 1);
            } else if (selection_end - selection_start > 1) {
                let substr = value.substr(selection_start, selection_end);
                let pos = value.includes(',')
                    ? substr.includes(',')
                        ? selection_start
                        : selection_start - 1
                    : selection_start;
                this.set_cursor(ele, pos);
            } else {
                this.back_space = true;
                this.is_amount_changed = '';
            }
        } else if (this.props.allowNegative && e.key == '-') {
            this.is_minus = true;
            if (selection_start == 0 && selection_end == value_length) {
                this.update_max_length(true);
                this.is_amount_changed = '';
            } else {
                this.update_max_length(true);
                final = this.update_value(value.trim(), '-', 0);
                final = this.removeFormatting(final);
                let selection = this.getCurrentCaretPosition(ele);
                let actual_sel = selection + (special_chr_count > 0 ? special_chr_count - 1 : 0);
                this.is_amount_changed = final;
                let formattedValue = this.formatInput(final);
                this.update_amount(final, formattedValue, actual_sel, ele);
            }
        } else if (e.key == 'ArrowLeft' && selection_start == 2 && selection_end == 2) {
            this.set_cursor(ele, selection_start - 1);
        } else if (e.key == 'Delete') {
            if (this.props.allowNegative && value.indexOf('-') == 0) {
                if ((selection_start == 0 && selection_end == 0) || (selection_start == 1 && selection_end == 1)) {
                    this.is_minus = true;
                    this.is_amount_changed = value;
                    this.set_cursor(ele, selection_start + 1);
                } else if (
                    (dot_index <= 4 && selection_start == 2 && selection_end == 2) ||
                    (selection_start <= 2 && selection_end == dot_index)
                ) {
                    let pos: any;
                    pos = selection_start;
                    this.element = ele;
                    this.is_amount_changed = this.remove_value(value, pos, 'Delete');
                    this.is_amount_changed =
                        this.is_amount_changed == `-${this.props.prefix}0.00${this.props.suffix}`
                            ? `${this.props.prefix}0.00${this.props.suffix}`
                            : this.is_amount_changed;
                    this.set_cursor(ele, selection_start);
                } else if (selection_start > dot_index && selection_start <= dot_index + 2) {
                    let pos: any;
                    pos = selection_start;
                    this.element = ele;
                    this.is_amount_changed = this.remove_value(value, pos, 'Delete');
                    this.is_amount_changed =
                        this.is_amount_changed == `-${this.props.prefix}0.00${this.props.suffix}`
                            ? `${this.props.prefix}0.00${this.props.suffix}`
                            : this.is_amount_changed;
                    this.set_cursor(ele, pos + 1);
                } else if (dot_index == selection && dot_index == selection_start) {
                    this.is_amount_changed = value;
                    this.set_cursor(ele, selection_start + 1);
                } else if (selection_start < dot_index && selection_end > dot_index && selection_end <= dot_index + 2) {
                    this.is_amount_changed = value;
                    this.set_cursor(ele, selection_start);
                } else {
                    this.delete_btn = true;
                    this.is_amount_changed = '';
                }
            } else {
                if (selection_start == 0 && selection_end == 0) {
                    let pos: any;
                    pos = selection_start;
                    this.is_amount_changed = this.remove_value(value, pos, 'Delete');
                    let decimal_val = value.split('.')[0];
                    if (decimal_val.length === 1) {
                        this.set_cursor(ele, pos + 1);
                    }
                } else if (selection_start > dot_index && selection_start <= dot_index + 2) {
                    let pos: any;
                    pos = selection_start;
                    this.element = ele;
                    this.is_amount_changed = this.remove_value(value, pos, 'Delete');
                    this.set_cursor(ele, pos + 1);
                } else if (
                    selection_start == 0 &&
                    selection_end == value.length &&
                    value == `${this.props.prefix}0.00${this.props.suffix}`
                ) {
                    this.is_amount_changed = 'empty';
                } else if (dot_index == selection && dot_index == selection_start) {
                    this.is_amount_changed = value;
                    this.set_cursor(ele, selection_start + 1);
                } else if (selection_start < dot_index && selection_end > dot_index && selection_end <= dot_index + 2) {
                    this.is_amount_changed = value;
                    this.set_cursor(ele, selection_start);
                } else {
                    this.delete_btn = true;
                    value == `${this.props.prefix}0.00${this.props.suffix}`;
                    this.is_amount_changed = '';
                }
            }
        }
        if (this.props.onKeyDown) {
            this.props.onKeyDown(e);
        }
    };

    onPaste = async (e) => {
        e.persist();
        e.preventDefault();
        const el = e.target;
        const { max_length } = this.state;
        e.clipboardData.items[0].getAsString((text) => {
            let ac_length = parseInt(text) > 0 ? max_length - 4 : 13 - 4;
            let inputValue =
                parseInt(text) > 0
                    ? text.substring(0, ac_length - ac_length / 3 + 1)
                    : text.substring(0, ac_length - ac_length / 3 + 2);
            let currentCaretPosition = this.getCurrentCaretPosition(el);
            let formattedValue = this.formatInput(inputValue) || '';
            currentCaretPosition = this.getExpectedCaretPosition(currentCaretPosition, formattedValue);
            const numAsString = this.removeFormatting(formattedValue);
            if (this.back_space) {
                currentCaretPosition = this.find_pos_on_removed(el.defaultValue, formattedValue, currentCaretPosition);
                this.back_space = false;
            }
            this.update_amount(numAsString, formattedValue, currentCaretPosition, el);
        });
    };

    render() {
        return this.props.displayType && this.props.displayType == 'text' && this.props.disabled ? (
            <div>{this.state.formatted_value}</div>
        ) : (
            <input
                id={this.props.id}
                name={this.props.name}
                className={this.props.className}
                placeholder={this.props.placeHolder}
                value={this.state.formatted_value || ''}
                onChange={(values) => this.onChange(values)}
                onKeyDown={this.on_key_press}
                maxLength={this.state.max_length}
                ref={(instance) => (this.amount_ref = instance)}
                disabled={this.props.disabled}
                onBlur={(evt) => {
                    if (this.state.value == '-') {
                        this.is_amount_changed = 'empty';
                        this.setState({
                            value: ''
                        });
                        this.is_minus = false;
                    }
                    if (this.props.onBlur) {
                        this.props.onBlur(evt);
                    }
                }}
                onClick={(e: any) => {
                    if (e) {
                        e.target.select();
                    }
                }}
                onFocus={(e: any) => {
                    if (this.props.onFocus) {
                        this.props.onFocus(e);
                    }
                    if (e) {
                        e.target.select();
                    }
                }}
                onCut={(event) => {
                    this.props.onChange(this.state.value);
                }}
            />
        );
    }
}
