import * as React from 'react';
import { connect } from "react-redux";
import { dateFormatter } from '../../../reports/report_constants';

/**
 * Responsible to display Provider Billing rows
 **/
class ProviderBillingDisplayRowComponent extends React.Component<any, any> {
    _is_mount = false;
    initial_form_object: any = {};
    div_table_container: HTMLElement;
    private readonly noneLabel = 'NONE';
    private readonly noAuthorization = 'No Authorization';
    private readonly badgeLabel = 'badge-default';
    private readonly emptyLabel = '';

    // Get initialize data when component created
    constructor(props) {
        super(props);
        this.state = {
            row_index: this.props.row_index,
            form_object: this.props.data,
            status_disabled: false
        };
    }

    componentWillMount() {
        this.initial_form_object = { ...this.state.form_object };
    }

    componentDidMount() {
        this._is_mount = true;
        if (this.props.onRef) {
            this.props.onRef(this);
        }
        this.div_table_container = document.querySelector('#id_billing_settings_table');
    }

    //Component update function
    componentDidUpdate(previousProps, previousState) {
        if ((previousState.form_object.provider_billing_id === this.state.form_object.provider_billing_id) &&
            JSON.stringify(previousState.form_object) !== JSON.stringify(this.state.form_object)) {
            this.props.on_unsave_billing(this.props.row_index, JSON.stringify(this.initial_form_object) !== JSON.stringify(this.state.form_object))
        } else if (this.props.data != previousProps.data) {
            let data = { ...this.props.data };
            this.setState({
                form_object: data
            });
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(undefined);
        }
        this._is_mount = false;
    }

    private retrieveSelectedBadge = (items, badgeType = '') => {
        if (badgeType === 'location' && this.props.row_type === 'claim') {
          if (items && items.length === 1 && items[0] === -1) {
            return 'ALL';
          } else if (items && items.length > 0) {
            return items.length + ' Selected';
          }
        } else {
          if (items && items.length > 0) {
            return items.length + ' Selected';
          }
        }
        return '-';
    };

    private onRowClick = (ev) => {
        let currentRowData = this.state.form_object;
        let currentRowIndex = this.state.row_index;
        this.props.onClickCallbackHandler(ev, currentRowData, currentRowIndex);
    }

    private hasValue = (item) => {
        return item && item.length > 0;
    }

    private onClickBadgeHandler = (itemId) => {
        this.props.onClickBadgeItemHandler(itemId);
        if (this.props.row_type === 'claim') {
            this.onRowClick({})         
        }
    }

    get_updated_data = () => ({ ...this.state.form_object });

    render() {
        return this.props.row_type === 'claim' ? (
          <tr onDoubleClick={this.onRowClick} id={`provider-billing-row_${this.state.row_index}`}>
            {this.props.column_fields.map((cal, index) => this[cal.renderer](`${cal.field}_renderer`))}
          </tr>
        ) : (
          <tr onClick={this.onRowClick} id={`provider-billing-row_${this.state.row_index}`}>
            {this.props.column_fields.map((cal, index) => this[cal.renderer](`${cal.field}_renderer`))}
          </tr>
        );
    }

    // Setting Name display renderer
    setting_name_renderer = (key_id) => (
        <td data-label="setting_name" key={key_id}>
            <label>
                {this.state.form_object.setting_name || '-'}
            </label>
        </td>
    );

    // Claim Hold Name display renderer
    hold_name_renderer = (key_id) => (
        <td data-label='hold_name' key={key_id}>
        <label>{this.state.form_object.claim_hold_name || '-'}</label>
        </td>
    );

    // claim delay reason Name display renderer
    claim_delay_reason_renderer = (key_id) => (
        <td data-label='claim_delay_reason' key={key_id}>
            <label>{this.set_claim_reason(this.state.form_object.claim_delay_reason_id)}</label>
        </td>
    );

    set_claim_reason = (claim_delay_reason_id) => {
        const reason = this.props.claim_delay_reason.filter((item) => item.value == claim_delay_reason_id);
        return (reason && reason.length > 0 && reason[0].text) || this.noAuthorization;
    };

    // Setting Name display renderer
    dos_renderer = (key_id) => (
        <td data-label='dos' key={key_id}>
            <label>
                {`${
                (this.state.form_object.start_date && dateFormatter(this.state.form_object.start_date)) ||
                ''
                } - 
                ${
                (this.state.form_object.end_date && dateFormatter(this.state.form_object.end_date)) ||
                ''
                }`}
            </label>
        </td>
    );

    //Location class column cell renderer
    location_renderer = (key_id) => (
        <td onClick={() => this.onClickBadgeHandler('location_id')} data-label="location_id" key={key_id}>
            <label>
                <span className={this.hasValue(this.props.locations) ? this.badgeLabel : this.emptyLabel}>
                    {this.retrieveSelectedBadge(this.props.locations, 'location')}
                </span>
            </label>
        </td>
    );

    //Provider class column cell renderer
    provider_renderer = (key_id) => (
        <td onClick={() => this.onClickBadgeHandler('provider_id')} data-label="provider_id" key={key_id}>
            <label>
                <span className={this.hasValue(this.props.providers) ? this.badgeLabel : this.emptyLabel}>
                    {this.retrieveSelectedBadge(this.props.providers, 'provider')}
                </span>
            </label>
        </td>
    );

    //Insurance class column cell renderer
    insurance_class_renderer = (key_id) => (
        <td onClick={() => this.onClickBadgeHandler('insurance_class_id')} data-label="insurance class" key={key_id}>
            <label>
                <span className={this.hasValue(this.props.insuranceClass) ? this.badgeLabel : this.emptyLabel}>
                    {this.retrieveSelectedBadge(this.props.insuranceClass)}
                </span>
            </label>
        </td>
    );

    //Insurance Code column cell renderer
    insurance_code_renderer = (key_id) => (
        <td onClick={() => this.onClickBadgeHandler('insurance_code_id')} data-label="insurance code" key={key_id}>
            <label>
                <span className={this.hasValue(this.props.insuranceCode) ? this.badgeLabel : this.emptyLabel}>
                    {this.retrieveSelectedBadge(this.props.insuranceCode)}
                </span>
            </label>
        </td>
    );

    //Individual Number column cell renderer
    individual_number_renderer = (key_id) => (
        <td data-label="individual_number" key={key_id}>
            <label>
                {this.state.form_object.individual_number || '-'}
            </label>
        </td>
    );

    //Individual Qualifier column cell renderer
    individual_qualifier_renderer = (key_id) => (
        <td data-label="individual qualifier" key={key_id}>
            <label>
                {this.state.form_object.individual_qualifier || this.noneLabel}
            </label>
        </td>
    );

    //Group Number column cell renderer
    group_number_renderer = (key_id) => (
        <td data-label="group_number" key={key_id}>
            <label>
                {this.state.form_object.group_number || '-'}
            </label>
        </td>
    );

    //Group Qualifier column cell renderer
    group_qualifier_renderer = (key_id) => (
        <td data-label="group qualifier" key={key_id}>
            <label>
                {this.state.form_object.group_qualifier || this.noneLabel}
            </label>
        </td>
    );

    //Tax ID number column cell renderer
    tax_identification_number_renderer = (key_id) => (
        <td data-label="tax identification number" key={key_id}>
            <label>
                {this.state.form_object.tax_identification_number || '-'}
            </label>
        </td>
    );

    //SSN EIN column cell renderer
    ssn_tin_flag_renderer = (key_id) => (
        <td data-label="SSN TIN" key={key_id}>
            <label>
                {!this.state.form_object['ssn_tin_flag'] ? 'SSN' : 'EIN'}
            </label>
        </td>
    );

    //Is Active column cell renderer
    status_renderer = (key_id) => (
        <td data-label="Is Active" key={key_id}>
            <label>
                {this.state.form_object['status'] ? 'Active' : 'Inactive'}
            </label>
        </td>
    );
};
const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
    };
}
const mapDispatchToProps = dispatch => {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderBillingDisplayRowComponent);
