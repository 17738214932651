import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { Button, Dimmer, Input, Loader, Grid } from 'semantic-ui-react';
import * as global_constant from '../../global_constants';
import { user_forgot_password } from '../action/login_action';
import * as login_constants from '../login_constants';
import { handle_focus_on_tab, handle_focus_on_shift_tab, handle_click_on_enter } from './../../shared/tab_navigation_utility';

export class ForgotPasswordComponent extends React.Component<any, any> {
    [x: string]: any;

    constructor(props) {
        super(props);
        // Initialize state property here
        this.state = {
            loading: false,
            is_submitted:false
        };
    }
    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: global_constant.toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }
    // method will execute when user will clicks on Submit button on forgot password page
    on_submit = async () => {
        this.setState({
            is_submitted: true
        });        

        if (this.props.form_values.txt_Username != undefined && this.props.form_values.txt_Username != '' )
        {

            this.setState({
                loading:true,
                is_submitted: true
            });
           
            const forgot_password = await this.props.forgot_password(this.props.form_values.txt_Username);

            if (this.props.user_login_details.forgot_password && this.props.user_login_details.forgot_password.length > 0) {
                this.setState({
                    loading: false,
                    is_submitted: true
                });

                const toastr_options = this.show_html_content_toaster(this.props.user_login_details.forgot_password[0].message); 
                if (this.props.user_login_details.forgot_password[0].message == "Invalid user."
                    || this.props.user_login_details.forgot_password[0].code == login_constants.AUTHENTICATION_ERROR_CODE) {
                    toastr.error('', '', toastr_options);
                }
                else {
                    toastr.success('', '',toastr_options)
                }
            }
        }
    }

    private redirect_to_login_click() {
        this.props.history.push("/login");
    }

    // Generates HTML for field's in the form i.e. Forgot Password form
    private renderField = (field) => {
        const className = `form-control ${!field.input.value && this.state.is_submitted ? 'search-error-thin' : ''}`
        const type = field.input.name == 'Password' ? 'password' : 'text'
        return (
            <div className="form-group">
                <label>{field.label}</label>
                <Input placeholder="Username" id="forgot_password_username_field" onKeyDown={(event) => handle_focus_on_shift_tab(event, "redirect_to_login_page")} autoComplete="user_name" onKeyPress={this.validate_user_name} type={type} maxLength={50} className={className}{...field.input} autoFocus />
            </div>
        )
    }
    

    // not allowing spaces on user name field
    validate_user_name = (event) => {
        if (event.which == 32) {
            event.preventDefault();
            return;
        }   
    };

    // Fire Submit on Enter key press
    forgot_password_handle_key_press = (event) => {
        if (event.key == 'Enter' || event.which == 13 || event.keyCode == 13) {
            event.preventDefault();
            this.on_submit()
        }
    }
    render() {
        
        return (
            <>
                <Dimmer active={this.state.loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <div className="login-wrapper" onKeyPress={this.forgot_password_handle_key_press}>
                    <Grid.Row>
                        <Grid.Column computer={8} className="text-right centerContent login-logo">
                            <div>
                                <img src={login_constants.IMAGES_PATH + login_constants.WEB_PT_LOGO} alt="logo" />
                                <div className="clr"></div><span className="version">{global_constant.show_app_version ? global_constant.build_version:''}</span><span className="LoginAppName">Billing</span>
                            </div>
                        </Grid.Column>
                        <Grid.Column computer={8} className="centerContent">
                            <div className="loginbox text-left">
                                <h2>Forgot Password</h2>
                                <form>
                                    <Field
                                        name="txt_Username"
                                        label="Please enter your username to retrieve your password"
                                        component={this.renderField} 
                                    />
                                    <div className="text-center">
                                        <Button id="success" primary className="success_btn" type="button" onClick={() => this.on_submit()}>SUBMIT</Button>
                                    </div>
                                    <div className="text-center">
                                        <br />
                                        Click <a tabIndex={0} id="redirect_to_login_page" onKeyDown={(event) => {
                                                handle_focus_on_tab(event, "forgot_password_username_field")
                                                handle_click_on_enter(event)
                                            }
                                        } className="text-right cursor_point" onClick={() => this.redirect_to_login_click()}>here</a> to go to Login Page.
                                    </div>
                                </form>
                            </div>
                            <div className="clear10"></div>
                            <div className="helpSection centerContent">
                                <span>{global_constant.copyright_info}</span>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </div>
            </>
        );
    }
}



const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        forgot_password: user_forgot_password,
    }, dispatch)
}

const mapStateToProps = (state) => {
    const formValues = getFormValues("forgot")(state) || {};
    return {
        user_login_details: state.user_login_details,
        form_values: formValues
    };
}


/* - Connect component to redux
  - Used to map the redux store state and dispatch it to the props of a component
  - 2 input parameters:-
      1) mapStateToProps- The component will subscribe to Redux store updates. This means that any time the store is updated, mapStateToProps will be called [output]
      2) mapDispatchToProps- Map dispatched data from the action creater to props of a component [action creater]
*/
export default reduxForm({
    form: 'forgot'
})(connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordComponent))


