import { HEADER_TEMPLATE_FIRST_COLUMN, get_header_template_with_navigation } from '../shared/component/grid/constants';
// Password related error messages
export const is_weak_password = "<ul>Password must have at least eight characters, and must contain at least three of the following four character groups:<li>Uppercase characters (A through Z)</li><li>Lowercase characters (a through z)</li><li>Numbers (0 through 9)</li><li>Special characters (such as ! @ \ # $ % & = ? _ . , : ; ^ \\ - )</li></ul><p>Your password cannot be the same as your username.</p>";
export const old_password_required = "Old password cannot be left blank.";
export const confirm_password_required = "Confirm password cannot be left blank.";
export const new_password_required = "New password cannot be left blank.";
export const mismatch_password = "The New Password and Confirm Password do not match.";

export enum user_type_r3_id {
    sys_admin = 1,
    bms = 2,
    partner = 3,
    customer = 3
}
export const BMS_ADMIN_FULL_RIGHT_ROLE_ID = 83;
export const BMS_TEMPLATE_COMPANY_GMPS_CODE = 'BMS';

export const GET_USER_DETAILS = 'USER:GET_DETAILS';
export const UPDATE_USER_DETAILS = 'USER:UPDATE_DETAILS';
export const UPDATE_USER_PASSWORD = 'USER:UPDATE_PASSWORD';
export const USER_DETAILS = 'USER:DETAILS';
export const USER_IP_REGISTER = 'USER:USER_IP_REGISTER';
export const ADMIN_CONSTANT_COMPANY_DETAIL = 'USER:ADMIN_CONSTANT_COMPANY_DETAIL';
export const USER_COMPANIES = 'USER:USER_COMPANIES';
export const USER_PARENT_COMPANIES = 'USER:USER_PARENT_COMPANIES';

const headerTemplate = {
    template: '<div class="ag-cell-label-container" role="presentation">' +
        '<i class="small filter icon filter-ipad"></i>' +
        '<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '<div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '<span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '<span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '</div>' +
        '</div>'
};

export const change_company_grid_header_id = "change_company_grid_header_id";
export const change_company_configuration = {
    title: "Change Company",
    pagination_size: 20,
    control_defs: [{
        input: [{ label: "Company Name" }, { label: "Company Code" }],
        button: [{ name: "Search" }, { name: "Clear" }, { name: "Close" }]
    }],
    column_defs: [
        {
            headerName: "Company Name",
            headerComponentParams: get_header_template_with_navigation("change_company_grid_header_id"),
            field: "name",
            filter: "textFilter",
            unSortIcon: true,
        },
        {
            headerName: "Company Code",
            headerComponentParams: headerTemplate,
            field: "gpms_code",
            filter: "textFilter",
            unSortIcon: true,
        }
    ]
};

export const search_company_grid_header_id = "search_company_grid_header_id";
export const search_company_configuration = {
    title: "Company Search",
    pagination_size: 20,
    control_defs: [{
        input: [{ label: "Company Name" }, { label: "Company Code" }],
        button: [{ name: "Search" }, { name: "Clear" }, { name: "Close" }]
    }],
    column_defs: [
        {
            headerName: "Company Name",
            headerComponentParams: get_header_template_with_navigation(search_company_grid_header_id),
            field: "name",
            filter: "textFilter",
            unSortIcon: true,
            minWidth:183
        },
        {
            headerName: "Company Code",
            headerComponentParams: headerTemplate,
            field: "gpms_code",
            filter: "textFilter",
            unSortIcon: true,
            minWidth: 183
        }
    ]
};

export const remove_access_to_r4_with_overrides = {
    argosB33306RemoveAccessToR4WithOverridesFF: "argosB33306RemoveAccessToR4WithOverrides"
};

export const federated_user = {
    disablePasswordResetForFederatedUser: "magnusPlat1702DisablePasswordResetForFederatedUser"
};