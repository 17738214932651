import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Dimmer, Loader, Dropdown, Grid } from 'semantic-ui-react';
import { deletePaymentDetails, savePaymentDetails, updatePaymentDetails } from "../../payment/action/payment_action";
import { patientCreditType, PAYMENT_ACTION, payment_message, payment_const } from '../../payment/payment_constants';
import { AlertConfirm } from '../../shared/component/alert_confirm_component';
import PromptNavigation from '../../shared/component/prompt_navigation_component';
import * as local_storage from '../../shared/local_storage_utility';
import ItemActiveComponent from './item_active_component';
import ItemInactiveComponent from './item_inactive_component';
import { set_focus_on_element_with_id } from '../../shared/tab_navigation_utility';
import { yyyy_mm_dd_comparator } from '../utility';
import PaymentPagination from './payment_pagination_component';
import { MultiSelectComponent } from '../../shared/component/multi_select';
import { date_format_with_no_time, get_lauch_darkley_key_value, is_null_or_white_space } from '../../shared/utility';
import { adminBillingAuditLogFeatures } from '../../admin/constants/constants';

class PatientCreditsComponents extends React.Component<any, any> {

    _is_mounted = false;
    rowNodes: any;
    credit_list: any = [];
    credit_list_active: any = [];
    credits_editable: any = [];
    unsaved_rows: any = [];
    editable_row_count: any = 0;
    button_action: string = '';
    delete_action: string = 'delete';
    update_action: string = 'update';
    multi_select: any;
    multi_items: any = [];

    constructor(props) {
        super(props);
        this.state = {
            open_confirm_alert: false,
            confirm_message: '',
            select_data: [],
            select_all: false,
            should_update_child: true,
            payment_code_list: [],
            insurance_list: [],
            go_forward: false,
            is_update_unsaved: false,
            update_loading: false,
            //is_show_inactive: false,
            pagination_data: { row_count: 0 },
            ...this.init_active_data(),
        }
        this.rowNodes = []
    }

    componentDidMount() {
        this._is_mounted = true;
    }

    //Component update function
    componentDidUpdate(previousProps, previousState) {

        if (previousState.select_data.length !== this.state.select_data.length) {
            const { select_data, select_all } = this.state;
            const is_all_checked = this.editable_row_count > 0 && select_data.length === this.editable_row_count;
            if (is_all_checked !== select_all) {
                this.setState({
                    select_all: is_all_checked,
                    should_update_child: false
                });
            }
        }

        //change page data if patient changes from header, instead of navigate to other page
        if (previousProps.patient_id != this.props.patient_id) {
            if (this.multi_select) {
                this.multi_items = [];
                this.multi_select.handleClear();
            }
        }

        if (this.props.rows && this.props.creditFatchTime !== previousProps.creditFatchTime) {
            this.unsaved_rows = [];
            this.setState({
                select_data: [],
                update_loading: false,
                //is_show_inactive: false,
                is_update_unsaved: false,
                ...this.init_active_data()
            });
        }

        if (previousProps.payment_details.save_data_time != this.props.payment_details.save_data_time) {
            this.update_props_changes();
        }
    }

    componentWillUnmount() {
        this._is_mounted = false;
    }

    update_props_changes = () => {
        let state_value = {};
        const result = this.props.payment_details;
        let status = result.response && result.response.status || 0;
        if (status === 1) {
            if (this.state.is_update_unsaved) {
                this.unsaved_rows = []; // empty unsaved rows
                state_value['go_forward'] = !this.state.go_forward;
                state_value['is_update_unsaved'] = false;
            }
            if (result.action_type === PAYMENT_ACTION.UPDATE_CREDIT) {
                toastr.success('', payment_message.UPDATE_SUCCESS);
            } else if (result.action_type === PAYMENT_ACTION.DELETE_CREDIT) {
                toastr.success('', payment_message.DELETE_SUCCESS);
            }
        } else if (status === 0) {
            state_value['update_loading'] = false;
            if (result.action_type === PAYMENT_ACTION.UPDATE_CREDIT) {
                const msg = typeof result.response.messages != 'undefined' && result.response.messages !== null ? result.response.messages.map(data => data.message).join('.') : 'Unexpected Error';
                const code = typeof result.response.messages != 'undefined' && result.response.messages !== null ? result.response.messages.reduce(data => { return data.code }) : 0;
                if (code.code == 50002) {
                    this.button_action = this.update_action;
                    state_value['open_confirm_alert'] = true;
                    state_value['confirm_message'] = msg;
                } else {
                    toastr.error('', msg);
                }
            }
        } else if (status === 400) {
            state_value['update_loading'] = false;
            const msg = typeof result.response.data.messages != 'undefined' && result.response.data.messages !== null ? result.response.data.messages.map(data => data.message).join('.') : 'Unexpected Error';
            toastr.error('', msg);
        }
        if (Object.keys(state_value).length > 0) {
            this.setState(state_value);
        }
    }

    // Checkbox row check/uncheck
    on_check_change(checked, item, credit_data) {
        if (checked) {
            this.setState({
                select_data: [...this.state.select_data, ...[item]]
            });
        } else {
            const list_filter = this.state.select_data.filter(value => (value.patient_credit_id !== item.patient_credit_id));
            this.setState({
                select_data: list_filter
            });
        }
    }

    // Check all
    on_check_change_all = (event) => {
        if (event.target.checked) {
            this.setState(state => ({
                select_all: true,
                should_update_child: true,
                select_data: this.get_editable_rows(state.display_data),
            }));
        } else {
            this.setState({
                select_all: false,
                should_update_child: true,
                select_data: []
            })
        }
    }

    //// Check all
    //on_check_change_inactive = (event) => {
    //    if (this.props.rows) {
    //        if (event.target.checked) {
    //            this.credit_list = [...this.props.rows];
    //        } else {
    //            this.credit_list = [...this.credit_list_active];
    //        }
    //        const data = this.credit_list.slice(0, payment_const.PAGINATION_SIZE);
    //        this.editable_row_count = this.get_editable_rows(data).length;
    //        this.setState({
    //            display_data: data,
    //            pagination_data: { row_count: this.credit_list.length },
    //            is_show_inactive: event.target.checked,
    //            select_data: [],
    //            select_all: false,
    //            should_update_child: true,
    //        });
    //    }
    //}

    // Pagination change callback function
    on_pagination_change = (start_index, end_index) => {
        const data = this.credit_list.slice(start_index, end_index);
        this.editable_row_count = this.get_editable_rows(data).length;
        this.unsaved_rows = []; //discord unsaved changes rows
        this.setState({
            display_data: data,
            select_data: [],
            select_all: false,
            should_update_child: true,
            go_forward: false,  //discord unsaved changes
            is_update_unsaved: false //discord unsaved changes
        });
    }

    //Find editable rows
    get_editable_rows = (rows) => {
        if (rows) {
            const auth_data = local_storage.get("auth_data");
            let accrual_flag = false;
            let open_month = null;
            if (auth_data) {
                accrual_flag = auth_data.accrual_accounting || false;
                open_month = auth_data.open_month || null;
            }
            // All active rows when accrual flag true
            if (accrual_flag) {
                return rows.filter((item) => {
                    if (item.is_active && !this.is_disabled(item.deposit_date, open_month)) {
                        return true;
                    }
                    return false;
                });
            }
            // All active rows when accrual flag false
            return rows.filter((item) => item.is_active);
        }
        return []; //empty data
    }

    // Get all editable and inactive rows
    get_editable_and_inactive_rows = (rows) => {
        if (rows) {
            const auth_data = local_storage.get("auth_data");
            let accrual_flag = false;
            let open_month = null;
            if (auth_data) {
                accrual_flag = auth_data.accrual_accounting || false;
                open_month = auth_data.open_month || null;
            }
            // All active rows when accrual flag true
            if (accrual_flag) {
                return rows.filter((item) => {
                    if (item.is_active && !this.is_disabled(item.deposit_date, open_month)) { // editable rows
                        return true;
                    } else if (!item.is_active) { // enclude inactive rows also
                        return true;
                    }
                    return false;
                });
            }

            // All rows when accrual flag false
            // All active rows will be editable and enclude inactive rows also.
            return [...rows];
        }
        return []; //empty data
    }

    //Get credits when multi select applied
    get_rows_by_multi_selection = (items) => {
        if (items.indexOf('Inactive') !== -1 && items.indexOf('Non-editable') !== -1) {
            return this.credit_list = [...this.props.rows];
        } else if (items.indexOf('Non-editable') !== -1) {
            return this.credit_list = [...this.credit_list_active];
        } else if (items.indexOf('Inactive') !== -1) {
            return this.credit_list = this.get_editable_and_inactive_rows(this.props.rows);
        }
        return [];
    }

    init_active_data = () => {
        this.credit_list = []// clear pagination list
        this.credit_list_active = [];// clear previous active list data
        this.credits_editable = [];// clear previous editable list data
        if (this.props.rows) {
            const auth_data = local_storage.get("auth_data");
            let accrual_flag = false;
            let open_month = null;
            if (auth_data) {
                accrual_flag = auth_data.accrual_accounting || false;
                open_month = auth_data.open_month || null;
            }
            this.credit_list_active = this.props.rows.filter((item) => {
                if (item.is_active && accrual_flag && !this.is_disabled(item.deposit_date, open_month)) {
                    this.credits_editable.push(item);
                }
                return item.is_active === true
            });

            //Keep multi selection add, update and delete a payment
            if (this.multi_items && this.multi_items.length > 0) {
                this.credit_list = this.get_rows_by_multi_selection(this.multi_items);
            } else {
                if (accrual_flag) {
                    this.credit_list = [...this.credits_editable];
                } else {
                    this.credit_list = [...this.credit_list_active];
                }
            }
        }

        const data = this.credit_list.slice(0, payment_const.PAGINATION_SIZE);
        this.editable_row_count = this.get_editable_rows(data).length;
        return {
            display_data: data,
            select_data: [],
            select_all: false,
            should_update_child: true,
            pagination_data: { row_count: this.credit_list.length },
        }
    }

    // save payment details on save  button click
    on_save_handler = (is_force_save = false) => {

        let row_items = [];
        let is_any_invalid = false;
        if (this.unsaved_rows && this.unsaved_rows.length > 0) {
            this.rowNodes.map((node) => {
                if (node && this.unsaved_rows.indexOf(node.get_row_num()) != -1) {
                    if (node.is_valid() == true) {
                        row_items = [...row_items, node.get_updated_data()];
                    } else {
                        is_any_invalid = true;
                    }
                }
            })
        }

        if (!is_any_invalid) {

            if (row_items && row_items.length > 0) {
                const token = local_storage.get("auth_data").token_details.access_token;
                row_items = row_items.map(function(credit) {
                    credit.deposit_date = !is_null_or_white_space(credit.deposit_date) ? date_format_with_no_time(credit.deposit_date) : null;
                    credit.date_of_service = !is_null_or_white_space(credit.date_of_service) ? date_format_with_no_time(credit.date_of_service) : null;
                    return credit
                });
                var payment_credit = {
                    is_from_charge: false,
                    is_force_save,
                    credits: row_items,
                    current_date: date_format_with_no_time(moment().format("MM/DD/YYYY")),
                }
                this.setState({
                    update_loading: true,
                });
                if (this.props.magnusPlat604AuditLogCreateTheAddUpdateDeleteControllersForPaymentActions) {
                    this.props.updatePaymentDetails(payment_credit, PAYMENT_ACTION.UPDATE_CREDIT, this.props.patientId, token);
                }
                else {
                    this.props.savePaymentDetails(payment_credit, PAYMENT_ACTION.UPDATE_CREDIT, this.props.patientId, token);
                }
            } else {
                toastr.error('', payment_message.UPDATE_ERROR);
            }
        } else {
            toastr.error('', payment_message.required_field);
        }
    }

    //Show all button click
    //show_all = () => {
    //    if (this.props.rows) {
    //        if (this.state.is_show_inactive) {
    //            this.credit_list = [...this.props.rows];
    //        } else {
    //            this.credit_list = [...this.credit_list_active];
    //        }
    //        const data = this.credit_list.slice(0, payment_const.PAGINATION_SIZE);
    //        this.editable_row_count = this.get_editable_rows(data).length;
    //        this.setState({
    //            display_data: data,
    //            pagination_data: { row_count: this.credit_list.length },
    //            select_data: [],
    //            select_all: false,
    //            should_update_child: true,
    //        });
    //    }
    //}

    // Show delete alert
    show_delete_modal = () => {
        if (this.state.select_data.length > 0) {
            this.button_action = this.delete_action;
            this.handle_navigation_on_modal();
            this.setState({
                open_confirm_alert: true,
                confirm_message: payment_message.DELETE_CONFIRM
            });
        } else {
            toastr.error('', payment_message.DELETE_ERROR);
        }
    }

    //Update click event handler
    on_update_handle = (e) => {
        e.preventDefault();
        this.on_save_handler();
    }

    handle_navigation_on_modal = () => {
        setTimeout(() => {
            let closeIcons: NodeListOf<HTMLElement> = document.querySelectorAll('i.close.icon:not(.auto-search-icon)') as NodeListOf<HTMLElement>;
            let closeIcon = closeIcons[closeIcons.length - 1]
            if (closeIcon) {
                closeIcon.tabIndex = 0;
                closeIcon.id = "confirm_modal_window_close_icon";
                closeIcon.addEventListener("keydown", function (event) {
                    if (event.shiftKey && event.keyCode == 9) {
                        event.preventDefault();
                        let prevButton = document.getElementById("id_alert_btn_ok");
                        prevButton.focus();
                    }

                    if (event.keyCode == 13) {
                        event.preventDefault();
                        var el = event.target as HTMLElement
                        el.click();
                    }
                });
            }

            set_focus_on_element_with_id("id_alert_btn_cancel");

        }, 0)
    }
    handle_confirm = (e) => {
        e.preventDefault();
        this.setState({ open_confirm_alert: false },
            () => {
                if (this.button_action === this.delete_action) {
                    this.on_delete_handler();
                } else if (this.button_action === this.update_action) {
                    this.on_save_handler(true);
                }
            });
    }

    // In active patient credits
    on_delete_handler = () => {
        if (this.state.select_data && this.state.select_data.length > 0) {
            const token = local_storage.get("auth_data").token_details.access_token;
            let deleteItems = this.state.select_data.map(item => {
                let value_update = {};
                value_update['credit_card_auth_num'] = item.cc_num == '' ? null : item.cc_num;
                value_update['check_credit_card'] = item.cc_num == '' ? null : item.cc_num;
                value_update['check_num'] = item.cc_num == '' ? null : item.cc_num;
                return { ...item, ...value_update, is_active: false }
            });
            deleteItems = deleteItems.map(function(credit) {
                credit.deposit_date = date_format_with_no_time(credit.deposit_date);
                credit.date_of_service = date_format_with_no_time(credit.date_of_service);
                return credit
            });
            var payment_credit = {
                is_from_charge: false,
                is_force_save: true,
                credits: deleteItems,
                current_date: date_format_with_no_time(moment().format("MM/DD/YYYY")),
            }
            this.setState({
                update_loading: true,
            });

            if (this.props.magnusPlat604AuditLogCreateTheAddUpdateDeleteControllersForPaymentActions) {
                this.props.deletePaymentDetails(payment_credit, PAYMENT_ACTION.DELETE_CREDIT, this.props.patientId, token);
            }
            else {
                this.props.savePaymentDetails(payment_credit, PAYMENT_ACTION.DELETE_CREDIT, this.props.patientId, token);
            }
        } else {
            toastr.error('', payment_message.DELETE_ERROR);
        }
    }

    is_disabled = (deposit_date, open_month_date) => {
        if (!open_month_date) {
            return true;
        }

        if (yyyy_mm_dd_comparator(deposit_date, open_month_date) < 0) {
            return true;
        }

        return false;
    }

    render_rows = () => {
        const auth_data = local_storage.get("auth_data");
        let accrual_flag = false;
        let open_month = null;
        if (auth_data) {
            accrual_flag = auth_data.accrual_accounting || false;
            open_month = auth_data.open_month || null;
        }
        return this.state.display_data.map((item, index) => {
            if (item.is_active && item.is_active == true) {
                const new_payment_code = this.props.paymentCode[item.patient_credit_type.credit_type_id] || [];
                const disabled = (accrual_flag === true) ? this.is_disabled(item.deposit_date, open_month) : false;
                const filtered_insurance = this.props.insurance[item.patient_credit_type.credit_type_id] || [];
                const flag_checked = (disabled === true) ? false : this.state.select_all;

                if (disabled) {
                    return (<ItemInactiveComponent
                        data={item}
                        key={index}
                        checked={this.state.select_all}
                        patientId={this.props.patientId}
                        payment_method_list={this.props.payment_method_list}
                        addBatchNum={this.props.addBatchNum}
                    />);
                }

                return (<ItemActiveComponent
                    onRef={ref => (this.rowNodes[index] = ref)}
                    data={item}
                    key={index}
                    row_num={index}
                    disabled={disabled}
                    is_fetched={true}
                    check_unsave_callback={this.check_data_changes}
                    checked={flag_checked}
                    patientId={this.props.patientId}
                    shouldUpdate={this.state.should_update_child}
                    paymentCode={new_payment_code}
                    insurance={filtered_insurance}
                    onChange={this.on_check_change.bind(this)}
                    payment_method_list={this.props.payment_method_list}
                    addBatchNum={this.props.addBatchNum}
                />);
            }
            return (<ItemInactiveComponent
                data={item}
                key={index}
                checked={this.state.select_all}
                patientId={this.props.patientId}
                payment_method_list={this.props.payment_method_list}
                addBatchNum={this.props.addBatchNum}
            />);

        });
    }

    check_data_changes = (previous_row, row, key) => {
        const index = this.unsaved_rows.indexOf(key);
        if (index >= 0 && (JSON.stringify(row) === JSON.stringify(previous_row))) {
            this.unsaved_rows.splice(index, 1);
        } else if (index === -1) {
            this.unsaved_rows.push(key);
        }
        let is_update_unsaved = this.unsaved_rows.length > 0 ? true : false;
        if (this.state.is_update_unsaved !== is_update_unsaved) {
            this.setState({ is_update_unsaved: is_update_unsaved });
        }
    }

    //Tab key navigation and return to top header from bottom.
    set_focus_on_header = (event) => {
        if (!event.shiftKey && event.keyCode == '9') {
            event.preventDefault();
            set_focus_on_element_with_id("app_header_dropdown");
        }
    }

    on_change_multi_selection = (items) => {
        if (this.props.rows) {
            this.multi_items = items.map(value => value.title);
            if (this.multi_items && this.multi_items.length > 0) {
                this.credit_list = this.get_rows_by_multi_selection(this.multi_items);
            } else {
                const auth_data = local_storage.get("auth_data");
                let accrual_flag = false;
                let open_month = null;
                if (auth_data) {
                    accrual_flag = auth_data.accrual_accounting || false;
                    open_month = auth_data.open_month || null;
                }
                if (accrual_flag) {
                    this.credit_list = [...this.credits_editable];
                } else {
                    this.credit_list = [...this.credit_list_active];
                }
            }
            const data = this.credit_list.slice(0, payment_const.PAGINATION_SIZE);
            this.editable_row_count = this.get_editable_rows(data).length;
            this.setState({
                display_data: data.map(value => ({ ...value })),
                pagination_data: { row_count: this.credit_list.length },
                select_data: [],
                select_all: false,
                should_update_child: true,
            });
        }
    }

    //Render function
    render() {

        return (<div className="patient_credit_container" id="id_credit_container">
            <Dimmer active={this.state.update_loading}>
                <Loader size='massive'>Loading</Loader>
            </Dimmer>
            <br></br>
            <PromptNavigation
                is_data_changed={this.state.is_update_unsaved}
                save={this.on_update_handle}
                go_next_location={this.state.go_forward} />

            <Grid><Grid.Column computer={16} textAlign="right">
                <MultiSelectComponent
                    id="id_multi_select"
                    onRef={ref => (this.multi_select = ref)}
                    onChange={this.on_change_multi_selection}
                />
            </Grid.Column></Grid>
            <PaymentPagination
                data={this.state.pagination_data}
                onChange={this.on_pagination_change}
                changeTimestamp={this.state.data_change_timestamp}
            />
            <form className="ui form" id="id_form_credits">
                <div className="customtable mar-r-15" id="id_div_level0">
                    <div className="table-responsive" id="id_div_level1">
                        <table className="ui table">
                            <thead>
                                <tr>
                                    <th className="text-center">
                                        <input type="checkbox"
                                            id="id_check_all_items"
                                            onChange={this.on_check_change_all}
                                            checked={this.state.select_all}
                                            value="" />
                                    </th>
                                    <th style={{ minWidth: '150px' }}>Date of<br/>Service</th>
                                    <th style={{ minWidth: '150px' }}>Deposit<br />Date</th>
                                    <th style={{ minWidth: '110px' }}>Transaction<br />Type</th>
                                    <th style={{ minWidth: '110px' }}>Payment<br />Code</th>
                                    <th style={{ minWidth: '110px' }} className='text-right'>Unallocated<br />Amount</th>
                                    <th style={{ minWidth: '105px' }} className='text-right'>Total<br />Payment</th>
                                    <th style={{ minWidth: '105px' }}>Payment<br />Method</th>
                                    <th style={{ minWidth: '217px' }}>Check/Credit Card Number</th>
                                    <th style={{ minWidth: '150px' }}>Location</th>
                                    <th style={{ minWidth: '150px' }}>Provider</th>
                                    <th style={{ minWidth: '150px' }}>Insurance</th>
                                    <th style={{ minWidth: '150px' }}>Comment</th>
                                    { this.props.addBatchNum &&
                                        <th style={{ minWidth: '110px' }}>Batch Number</th> 
                                    }
                                    <th style={{ minWidth: '105px' }}>Date<br />Created</th>
                                    <th style={{ minWidth: '106px' }}>Created By</th>
                                    <th style={{ minWidth: '105px' }}>Date<br />Updated</th>
                                    <th style={{ minWidth: '106px' }}>Updated By</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.render_rows()}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="patient_credit_footer_div padd-r-15" id="id_credit_footer_div">
                    {
                        //    <div style={{ display: 'flex', alignItems: 'center' }}>
                        //    <input id="id_check_inactive" type="checkbox" checked={this.state.is_show_inactive} onChange={this.on_check_change_inactive} />
                        //    <span style={{ paddingLeft: '5px' }}>Show Inactive</span>
                        //</div>
                    }

                    {//<div>
                        //</div>
                        // <button type="button" id="id_btn_show_all" className="ui basic button" role="button" onClick={this.show_all}>Show All</button>
                    }
                    <button type="button" id="bg_fff2" className="ui basic button" role="button" onClick={this.show_delete_modal}>Delete</button>
                    <button type="submit" id="id_btn_update_credits" className="ui primary button" role="button" onClick={this.on_update_handle} onKeyDown={this.set_focus_on_header}>Update</button>

                </div>
            </form>
            <AlertConfirm
                open={this.state.open_confirm_alert}
                close={() => this.setState({ open_confirm_alert: false })}
                cancel={() => this.setState({ open_confirm_alert: false })}
                confirm={this.handle_confirm}
                message={this.state.confirm_message}
            />
        </div>);
    }
}

const mapDispatchToProps = dispatch => {
    return {
        savePaymentDetails: (data, action_type, patientId, token) => dispatch(savePaymentDetails(data, action_type, patientId, token)),
        updatePaymentDetails: (data, actionType, patientId, token) => dispatch(updatePaymentDetails(data, actionType, patientId, token)),
        deletePaymentDetails: (data, actionType, patientId, token) => dispatch(deletePaymentDetails(data, actionType, patientId, token))
    };
};

const mapStateToProps = (state:{
    patient_details: { patient_header: { data: { id: any } } };
    user_login_details: any;
    payment_details:any ;
    launch_darkly: any;
}) => {
    return {
        user_login_details: state.user_login_details,
        patient_details: state.patient_details,
        payment_details: state.payment_details,
        patient_id: state.patient_details && state.patient_details.patient_header && state.patient_details.patient_header.data && state.patient_details.patient_header.data.id || '',
        magnusPlat604AuditLogCreateTheAddUpdateDeleteControllersForPaymentActions: get_lauch_darkley_key_value(state.launch_darkly, adminBillingAuditLogFeatures.magnusPlat604AuditLogCreateTheAddUpdateDeleteControllersForPaymentActions)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientCreditsComponents);