import * as React from 'react';
import { Button, Grid, Input, Segment } from 'semantic-ui-react';
import MultiSelectPanelComponent from '../../../../shared/component/multi_select_panel_component';
import { roles_permission_set_controls, service_type_fields } from '../../constants';
import Selection from '../../../../shared/component/selection_component';
import { toastr as toaster } from 'react-redux-toastr';
import * as constants from '../../constants';
import { set_focus_on_close_drawer, set_focus_on_element_with_id } from '../../../../shared/tab_navigation_utility';

/**
 * Responsible to retrieve A Permission Set Edit Component
 **/
interface IProps {
  data: any[];
  drawer_type: string;
  action_type: string;
  form_data: {};
  is_add_edit_allowed: boolean;
  options: {};
  handleExtendedDrawerClose: Function;
  saveHandlerCallback: Function;
  drawer_data: {};
}

export default class PermissionSetRolesViewAddEditComponent extends React.PureComponent<IProps, any> {
  is_mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      isSubmitted: false,
      externalErrors: {},
      is_error_control: {},
      form_data: this.props.form_data || {},
      controls: roles_permission_set_controls[this.props.drawer_type]
    };
  }

  componentDidMount() {
    this.is_mounted = true;
    setTimeout(() => {
      set_focus_on_element_with_id(roles_permission_set_controls[this.props.drawer_type][0].id);
    }, 0);
  }

  componentWillUnmount = () => {
    this.is_mounted = false;
  };

  toggleExtendedDrawer = (id) => {
    this.props.handleExtendedDrawerClose(false, id);
  };

  handle_on_change_selected = (selected_list) => {
    if (this.is_mounted) {
      this.setState((prevState) => ({
        form_data: {
          ...prevState.form_data,
          selected_list
        }
      }));
    }
  };

  render_selection_control = (item) => {
    return ( 
      <React.Fragment>
        <label>
          {item.label}
          {item.is_required && (
            <span
              className={
                !this.state.form_data[item.name] &&
                item.is_required &&
                this.state.isSubmitted &&
                this.state.is_error_control[item.name]
                  ? 'req req-alert'
                  : 'req req-alert_normal'
              }
              dangerouslySetInnerHTML={{ __html: '*' }}
            />
          )}
        </label>
        <Selection
          id={item.id}
          name={item.name}
          disabled={!this.props.is_add_edit_allowed}
          defaultValue={this.state.form_data[item.id]}
          options={(this.props.options && this.props.options[item.id]) || []}
          onChange={(value, e) => this.onChangeInputsHandler(e)}
          wrapperStyle={`${this.props.action_type !=='new' && this.props.is_add_edit_allowed ? "edit-allowed" : ""} `}
          isRequired={
            !this.state.form_data[item.name] &&
            item.is_required &&
            this.state.isSubmitted &&
            this.state.is_error_control[item.name]
          }
        />
      </React.Fragment>
    );
  }

  render_controls = () => {
    return this.state.controls.map((item) => {
      if(item.id !=='service_type'){
        return ( 
          <Grid.Row key={item.id} {...(item.type !=='text' && this.props.drawer_type ==='roles' && item.id ==='user_type' && { columns: 2})}>
            <Grid.Column {...(item.type !=='text' && this.props.drawer_type ==='roles' && item.id ==='user_type' && { tablet: 8, computer: 8})}>
              {item.type === 'text' ? (
                <React.Fragment>
                  <label>
                    {item.label}
                    {item.is_required && (
                      <span
                        className={
                          !this.state.form_data[item.name] &&
                          item.is_required &&
                          this.state.isSubmitted &&
                          this.state.is_error_control[item.name]
                            ? 'req req-alert'
                            : 'req req-alert_normal'
                        }
                        dangerouslySetInnerHTML={{ __html: '*' }}
                      />
                    )}
                  </label>
                  <Input
                    autoComplete='off'
                    name={item.name}
                    id={item.id}
                    type='text'
                    onChange={(e) => this.onChangeInputsHandler(e)}
                    maxLength={item.maxLength}
                    value={this.state.form_data[item.id] || ''}
                    className={`${this.props.action_type !=='new' && this.props.is_add_edit_allowed ? "edit-allowed" : ""} ${
                      this.state.isSubmitted &&
                      (this.state.externalErrors[item.id] || this.state.is_error_control[item.id]) ? "req-border-inp" : ""
                    }`}
                    disabled={!this.props.is_add_edit_allowed}
                    placeholder={item.is_required ? 'Required' : ''}
                  />
                </React.Fragment>
              ) : (this.render_selection_control(item))}
            </Grid.Column>
            {item.type !=='text' && this.props.drawer_type ==='roles' && item.id ==='user_type' && <Grid.Column tablet= {8} computer= {8}>
              {this.render_selection_control(service_type_fields)}
            </Grid.Column>}
          </Grid.Row>
        );
      }
    });
  };

  remove_error_on_drawer = (val) => {
    const input: any = document.getElementById(val);
    if(input.parentNode.classList.contains("req-border-inp")){
      input.parentNode.classList.remove("req-border-inp");
    }
  }

  onChangeInputsHandler = (e) => {
    const { name, value, type, id } = e.target;
    if(name === 'name'){
      this.remove_error_on_drawer(id);
    }
    this.setState((prev_state) => ({
      is_error_control: {
        ...prev_state.is_error_control,
        [name]: !value
      },
      form_data: {
        ...this.state.form_data,
        [name]: (type === "select-one" && value != " " ) ? parseInt(value) : value
      }
    }));
  };

  save_drawer_data = () => {
    this.setState({
      isSubmitted: true
    });
    let is_error = false;
    let val_msg = '';

    if (
      this.props.drawer_type === 'permission_sets' &&
      this.state.form_data &&
      (!this.state.form_data.selected_list || this.state.form_data.selected_list.length === 0)
    ) {
      val_msg = constants.roles_permissions_msg.permissions_validation_msg;
      is_error = true;
    }

    this.state.controls.filter((data) => {
      this.state.form_data[data.name] =
        this.state.form_data[data.name] && typeof this.state.form_data[data.name] !== 'boolean'
          ? this.state.form_data[data.name].toString().trim()
          : this.state.form_data[data.name];
      if (data.is_required && !this.state.form_data[data.name]) {
        this.setState((prev_state) => ({
          is_error_control: {
            ...prev_state.is_error_control,
            [data.name]: true
          }
        }));
        val_msg = constants.mandatory_fields;
        is_error = true;
      }
      if (this.state.form_data[data.name] && data.maxLength && this.state.form_data[data.name].length > data.maxLength ) {
        this.setState((prev_state) => ({
          is_error_control: {
            ...prev_state.is_error_control,
            [data.name]: true
          }
        }));
        val_msg = "Must not exceed 100 characters.";
        is_error = true;
      }

    });

    if (is_error) {
      toaster.error('', val_msg);
      return;
    }

    this.props.saveHandlerCallback(this.state.form_data);
  };

  check_keys_for_new_action = (obj) => {
    for (var key in obj) {
      if (obj[key] !== null && obj[key] != "" && obj[key] != " " && obj[key] != [])
          return false;
    }
    return true;
  }

  render() {
    return (
      <React.Fragment>
        <div id={'add-edit-drawer-component'}>
          <Grid columns='equal'>
            {this.render_controls()}
            <Grid.Row>
              <Grid.Column>
                <label>
                  {this.props.drawer_type === 'roles' ? 'Permission Sets' : 'Permissions'}
                  {this.props.drawer_type === 'permission_sets' ? <span className='req'>*</span> : ''}
                </label>
                <Segment>
                  <MultiSelectPanelComponent
                    id={'permission-multi-select'}
                    data={this.props.data}
                    selected_list={this.state.form_data.selected_list || []}
                    onChangeSelected={this.handle_on_change_selected}
                    ref={this.props.drawer_type === 'roles' ? 'roles_ids' : 'permission_ids'}
                    displayExtended={true}
                    toggleExtendedDrawer={this.toggleExtendedDrawer}
                    btn_id={'save_roles_permission_btn'}
                    is_add_edit_allowed={!this.props.is_add_edit_allowed}
                    drawer_type={this.props.drawer_type}
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        {this.props.is_add_edit_allowed && (
            <div className='bp3-dialog-save-button'>
              <Button
                id='save_roles_permission_btn'
                className='primary'
                type='button'
                content={'Save'}
                disabled={
                  this.check_keys_for_new_action(this.state.form_data) || (this.props.action_type !='copy' && this.props.drawer_data === JSON.stringify(this.state.form_data))
                }
                onClick={this.save_drawer_data}
                onKeyDown={(event) => set_focus_on_close_drawer(event, 'bp3-dialog-close-button')}
              />
            </div>
          )}
      </React.Fragment>
    );
  }
}
