import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { Button, Dimmer, Form, Grid, Input, Loader, Accordion, Icon } from 'semantic-ui-react';
import * as constants from '../../../admin/constants/constants';
import * as global_constants from '../../../global_constants';
import { get_patient_account_notes_action, get_patient_account_notes_status_action } from '../../../patient/action/patient_action';
import { update_patient_header_info } from '../../../patient/action/patient_header_action';
import CurrencyFormatter from '../../../shared/component/currency_formatter';
import DatePicker from '../../../shared/component/date_picker_component';
import Selection from '../../../shared/component/selection_component';
import * as local_storage from '../../../shared/local_storage_utility';
import * as session_storage from '../../../shared/session_storage_utility';
import { account_note_sticky_code } from '../../../shared/shared_constants';
import { custom_date_format, date_format_with_no_time, get_all_error, is_null_or_white_space, set_order_data, try_parse_float, get_lauch_darkley_key_value } from '../../../shared/utility';
import ChargeAccountNotes from '../../component/payments_notes';
import { batch_payments_export_data, batch_payments_print_data, decodeURIComponentSafe } from '../../utility';
import { get_adjustment_code, get_references, save_batch_payments, search_batch_payments } from './../action/batch_payment_action';
import * as batch_payment_constants from './../batch_payment_constants';
import BatchPaymentDetailComponent from './batch_payment_detail_component';
import DateRangePickerComponent from '../../../shared/component/date_range_component';
import { handle_click_on_enter, set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import ConfirmationComponent from '../../../shared/component/confirmation_component';
import * as ReportConstants from '../../../reports/report_constants';
import { generateAuditDataEntryPayload } from '../../../reports/util/export_print_utility';
import { enum_selection_type } from './../batch_payment_constants';
import { kraken_bill_333_remove_and_replace_medicare_crossover_on_payers_page } from '../../../feature_flip_constant';

export class BatchPayment extends React.Component<any, any> {
    [x: string]: any;
    patient_id = 0;
    minDate = null;
    is_save_enabled = true;
    page_type = this.props.location.pathname == '/payments/batch_payments' ? batch_payment_constants.enum_page_type.batch_payments : batch_payment_constants.enum_page_type.batch_adjustment
    constructor(props) {
        super(props);

        // Define initial state value.
        this.state = {
            is_submitted: false,
            is_searched: false,
            account_notes: [],
            loading: false,
            posting_type: '1',
            show_all: false,
            search_invoked: false,
            patient_id: 0,
            from_date: null,
            to_date: null,
            insurance_code: 0,
            deposit_date: null,
            batch: '',
            total_payment_amount: '',
            payment_code: '',
            adjustment_code: '',
            payment_amount: '',
            transaction_type: '',
            payment_type: '',
            check_card_number: '',
            dropdown_data: {
                posting_type: [],
                transaction_type: [],
                transaction_code: [],
                payment_type: [],
                insurance_code: [],
                payment_code: [],
                adjustment_code: []
            },
            error: {},
            controls_is_expended: true,
            show_bummepd_charge_message_popup: false
        };
        this.batch_detail_child = React.createRef();
        //this.handle_date_range_change = this.handle_date_range_change.bind(this);
    }

    // Define initial properties and its value.
    _is_mounted = false;
    reportId:string = "0";
    // page_name: string = 'batch_payments';
    dropdown_data = {
        posting_type: [],
        transaction_type: [],
        transaction_code: [],
        payment_type: [],
        insurance_code: [],
        payment_code: [],
        adjustment_code: []
    };
    title: any = '';
    report_name = this.page_type == batch_payment_constants.enum_page_type.batch_payments ? 'Batch Payments' : 'Batch Adjustment';
    // report_name = 'Batch Payments';
    company_name = '';
    required_field: any = {
        deposit_date: true,
        payment_code: true,
        adjustment_code: true,
        payment_amount: true
    };
    insurance_code_all = "All";
    insurance_code_all_value = " ";
    is_initial_result = false;
    search_criteria = {
        patient_id: 0,
        from_date: custom_date_format(moment(), 'mm/dd/yyyy'),
        to_date: custom_date_format(moment(), 'mm/dd/yyyy'),
        insurance_code: 0,
        show_all: false,
    }
    payment_notes = {
        token: "",
        sticky_code: 0,
        patient_id: session_storage.get("active_patient"),
    }

    UNSAFE_componentWillMount() {
        this.payment_notes = {
            token: this.props.user_login_details.user_data.data.token_details.access_token,
            sticky_code: account_note_sticky_code.payment,
            patient_id: session_storage.get('active_patient')
        };
        // if (session_storage.get('batch_search_criteria')) {
        //   this.is_initial_result = true;
        //   this.search_criteria = {
        //     ...session_storage.get('batch_search_criteria')
        //   };
        // }  
    }

    componentDidMount = () => {
        this._is_mounted = true;
        document.body.classList.add('batch-payment');
        this.page_load();
    };

    reset_page_default_value = () => {
        this.title = '';
        this.dropdown_data = {
            posting_type: [],
            transaction_type: [],
            transaction_code: [],
            payment_type: [],
            insurance_code: [],
            payment_code: [],
            adjustment_code: []
        };
        this.report_name = this.page_type == batch_payment_constants.enum_page_type.batch_payments ? 'Batch Payments' : 'Batch Adjustment';
        this.company_name = '';
        this.required_field = {
            deposit_date: true,
            payment_code: true,
            adjustment_code: true,
            payment_amount: true
        };
        this.insurance_code_all = "All";
        this.insurance_code_all_value = " ";
        this.is_initial_result = false;
        this.search_criteria = {
            patient_id: 0,
            from_date: custom_date_format(moment(), 'mm/dd/yyyy'),
            to_date: custom_date_format(moment(), 'mm/dd/yyyy'),
            insurance_code: 0,
            show_all: false,
        }
    }

    page_load = (update_page = false) => {
       
        //rest page value for payment and adjustment toggle
        if (update_page) {
            this.reset_page_default_value();
        }

        this.report_name = this.page_type == batch_payment_constants.enum_page_type.batch_payments ? 'Batch Payments' : 'Batch Adjustment';
        window.setTimeout(() => {
            let posting_type_ele = document.getElementById('posting_type');

            if (posting_type_ele) {
                posting_type_ele.focus();
            }
        }, 200);
        const auth_data = local_storage.get("auth_data");
        let accrual_flag = false;
        let open_month = null;
        if (auth_data) {
            accrual_flag = auth_data.accrual_accounting || false;
            open_month = auth_data.open_month || null;
        }
        this.minDate = null;
        if (accrual_flag) {
            this.minDate = new Date(open_month);
        }

        if (this.payment_notes.patient_id == '' || this.payment_notes.patient_id == undefined) {
            session_storage.set('no_patient_selected_for', this.props.location.pathname);
            this.props.history.push(`/no_patient_selected`);
        } else {
            //Set Patient ID
            this.patient_id = this.payment_notes.patient_id;
            //
            this.get_data();
            // if (this.is_initial_result) {
            //   this.get_report_data();
            // }
        }
    }

    //Get Payments NotesData from API function
    componentDidUpdate(prevProps, prevState) {
        let currentPageType = this.props.location.pathname == '/payments/batch_payments' ? batch_payment_constants.enum_page_type.batch_payments : batch_payment_constants.enum_page_type.batch_adjustment;
        if (this.page_type != currentPageType) {
            if (this._is_mounted) {
                this.setState({
                    controls_is_expended: true
                });
            }
            this.page_type = currentPageType;
            this.page_load(true);
        }
        else {
            if (this.props.patient_account_notes_status && this.props.patient_account_notes_status.updated && this.props.patient_account_notes_status.addedNoteType === "Payment") {
                this.get_patient_account_notes(this.payment_notes.patient_id, this.payment_notes.sticky_code);
                this.props.get_patient_account_notes_status({
                    updated: false,
                    addedNoteType: "Payment"
                });
            }
            //change page data if patent changes from header, instead of navigate to other page
            if (prevProps.patient_id != this.props.patient_id) {
                this.patient_id = session_storage.get("active_patient");
                this.payment_notes = {
                    token: this.props.user_login_details.user_data.data.token_details.access_token,
                    sticky_code: account_note_sticky_code.payment,
                    patient_id: session_storage.get('active_patient')
                };
                this.get_data(true);
                if (this._is_mounted) {
                    this.setState({
                        controls_is_expended: true
                    }, () => {
                        if (this.page_type == batch_payment_constants.enum_page_type.batch_payments) {
                            //console.log('this.state.total_payment_amount', this.state.total_payment_amount);
                            if (try_parse_float(this.state.total_payment_amount)==0) {
                                document.getElementById('total_payment_amount').focus();
                            } else {
                                document.getElementById('payment_amount').focus();
                            }
                         } else {
                                document.getElementById('payment_amount').focus();
                         }
                    })
                }
            }
        }
    }

    componentWillUnmount = () => {
        this._is_mounted = false;
        this.is_initial_result = false;
        document.body.classList.remove('batch-payment');
    };

    get_active_data = (data) => {
        return data.filter((item) => {
            if (item.is_active) {
                return item;
            }
        })
    }

    get_data = async (is_retain_data = false) => {
        this.get_patient_account_notes(this.payment_notes.patient_id, this.payment_notes.sticky_code);
        let res = await this.props.get_references(this.payment_notes.patient_id, this.payment_notes.token, this.page_type);
        // let ins_res = await get_insurance(this.patient_id, this.token);
        let adjustment_code = await get_adjustment_code(this.payment_notes.token);
        let payment_code_list = [];
        if (this.page_type == batch_payment_constants.enum_page_type.batch_payments) {
            let payment_code_data = [...this.props.user_login_details.master_data.data.payment_code];
            payment_code_data.map((val) => {
                val['description'] = val['payment_code'] + ' - ' + val['payment_desc']
            });

            payment_code_list = set_order_data(this.get_active_data(this.props.user_login_details.master_data.data.payment_code), 'payment_desc');
        }

        //console.log(posting_type, transation_type);

        adjustment_code.data.data = set_order_data(this.get_active_data(adjustment_code.data.data), 'adjustment_desc');

        let updated_insurance = [];

        if (this.props.batch_payment_reference.batch_reference.data[5].refrences.length > 0){
            let zero_pat_obj =  this.props.batch_payment_reference.batch_reference.data[5].refrences.find(obj => obj.value == "0");
            let zero_pat_charge_val = zero_pat_obj && zero_pat_obj.title && zero_pat_obj.title.split('-')[2] ? zero_pat_obj.title.split('-')[2] : ' 0 Charges';

            updated_insurance = this.props.batch_payment_reference.batch_reference.data[5].refrences.map(obj =>
                obj.value === "0" ? { ...obj, title: '0 - PATIENT -' + `${zero_pat_charge_val}` } : obj
            );
        }

        this.dropdown_data = {
            adjustment_code: this.option_formater_for_multiple_column(
                adjustment_code.data.data,
                'adjustment_code',
                'adjustment_desc',
                'adjustment_code_id',
                false, // to add blank object
                false // to shorted object
            ),
            insurance_code: constants.option_formater(
                updated_insurance,
                'title',
                'value',
                true, // to add blank object
                false, // to shorted object
                this.insurance_code_all
            ),
            payment_code: constants.option_formater(
                payment_code_list,
                'description',
                'payment_id',
                false, // to add blank object
                true // to shorted object
            ),
            payment_type: constants.option_formater(
                this.props.user_login_details.payment_methods,
                'text',
                'value',
                false, // to add blank object
                false // to shorted object
            ),
            //payment_type: this.props.user_login_details.payment_methods !== null ? this.props.user_login_details.payment_methods.map((val, index) => { return { key: index, value: val.value, text: val.text } }) : [],
            posting_type: constants.option_formater(
                this.props.batch_payment_reference.batch_reference.data[0].refrences,
                'title',
                'value',
                false, // to add blank object
                false // to shorted object
            ),
            transaction_code: constants.option_formater(
                this.props.batch_payment_reference.batch_reference.data[2].refrences,
                'title',
                'value',
                false, // to add blank object
                false // to shorted object
            ),
            transaction_type: constants.option_formater(
                this.props.batch_payment_reference.batch_reference.data[1].refrences,
                'title',
                'value',
                false, // to add blank object
                false // to shorted object
            )
        };
        //console.log(this.dropdown_data);
        this.set_initial_value(is_retain_data);
    };

    set_values_on_search_success = (data) => {
        data = this.update_data(data);
        let selected_insurance_code = this.state.insurance_code;
        let selected_insurance = this.props.batch_payment_reference.batch_reference.data[5].refrences.find((insurance) => {
            return selected_insurance_code == insurance.value
        });
        let adjustment_code = {
            value: this.state.adjustment_code,
            text: this.state.adjustment_code_text,
        }
        let payment_code = {
            value: this.state.payment_code,
            text: this.state.payment_code_text,
        }

        if (this.page_type == batch_payment_constants.enum_page_type.batch_payments) {
            if (!payment_code) {
                payment_code = {
                    value: this.state.payment_code,
                    text: this.state.payment_code_text,
                }
            }
        }
        if (!adjustment_code) {
            adjustment_code = {
                value: this.state.adjustment_code,
                text: this.state.adjustment_code_text,
            }
        }

        if (this._is_mounted) {
            this.setState({
                search_invoked: true,
                data: data,
                adjustment_code: adjustment_code.value,
                adjustment_code_text: adjustment_code.text,
                payment_code: payment_code.value,
                payment_code_text: payment_code.text,
                loading: false
            });
        }
    }

    set_initial_value = (is_retain_data = false) => {
        if (this._is_mounted) {
            let selected_insurance = this.props.batch_payment_reference.batch_reference.data[6].refrences.find((insurance) => {
                return insurance.pst_coverage == 'P'
            });
            let intObject = {
                dropdown_data: this.dropdown_data,
                adjustment_code: selected_insurance ? selected_insurance.adj_code : '',
                adjustment_code_text: '',
                payment_type: is_retain_data ? this.state.payment_type : this.dropdown_data.payment_type[1].value,
                payment_type_text: this.dropdown_data.payment_type[1].text,

                posting_type: is_retain_data ? this.state.posting_type : this.dropdown_data.posting_type[0].value,
                posting_type_text: this.dropdown_data.posting_type[0].text,
                insurance_code: selected_insurance ? selected_insurance.value : this.dropdown_data.insurance_code[0].value,
                insurance_code_text: this.dropdown_data.insurance_code[0].text,

                transaction_code: this.dropdown_data.transaction_code[0].value,
                transaction_code_text: this.dropdown_data.transaction_code[0].text,
                transaction_type: this.dropdown_data.transaction_type[0].value,
                transaction_type_text: this.dropdown_data.transaction_type[0].text,
                search_invoked: false,
                show_all: is_retain_data? this.state.show_all : false,
                formatted_from_date: null,
                formatted_deposit_date: is_retain_data ? this.state.formatted_deposit_date : null,
                formatted_to_date: null,
                from_date: null,
                deposit_date: is_retain_data ? this.state.deposit_date : null,
                to_date: null,
                batch: is_retain_data ? this.state.batch : '',
                payment_amount: '',
                total_payment_amount: is_retain_data ? this.state.total_payment_amount : '',
                check_card_number: (this.page_type == batch_payment_constants.enum_page_type.batch_payments) && this.state.total_payment_amount != null && this.state.total_payment_amount != '' && this.state.total_payment_amount != undefined  && (try_parse_float(this.state.total_payment_amount) == 0) ? '' : is_retain_data ? this.state.check_card_number : '',
                error: {},
                is_submitted: false,
                is_searched: false
            }
            if (intObject.adjustment_code && this.dropdown_data.adjustment_code.length > 0) {
                var adjutment = this.state.dropdown_data.adjustment_code.find((adj) => {
                    return adj.value == intObject.adjustment_code
                });

                intObject.adjustment_code_text = adjutment && adjutment.text ? adjutment.text : '';
            }

            if (this.page_type == batch_payment_constants.enum_page_type.batch_payments) {
                intObject['payment_code'] = selected_insurance ? selected_insurance.pay_code : '';
                intObject['payment_code_text'] = '';

                if (intObject['payment_code'] && this.dropdown_data.payment_code.length>0) {
                    var payment = this.state.dropdown_data.payment_code.find((pay) => {
                        return pay.value == intObject['payment_code']
                    });

                    intObject['payment_code_text'] = payment && payment.text ? payment.text:'';
                }
            }
            this.setState(intObject);

        }
    }

    //Get account Notes Data for charge stick
    get_patient_account_notes = async (patient_id, sticky_code) => {
        await get_patient_account_notes_action(patient_id, this.payment_notes.token, sticky_code).then(response => {
            if (this._is_mounted) {
                this.setState({
                    account_notes: response.data,
                    loading: false
                });
            }
        }, (error) => {
            if (this._is_mounted) {
                this.setState({
                    loading: false
                });
            }
            if (error.response.data) {
                const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                toastr.error('', toastr_options);
            }
        });
    };

    // Dropdown change handler.
    dropdown_change_handler = (e, is_required = false) => {
        let selected_text = `${e.target.name}_text`;
        let error = this.state.error,
            isBatchPayments = this.page_type == batch_payment_constants.enum_page_type.batch_payments,
            isPostingType = e.target.name == 'posting_type';
        if (is_required && (this.state.is_submitted || this.state.is_searched)) {
            if (e.target.value && e.target.value.trim() == '') {
                error[e.target.name] = true;
            } else {
                error[e.target.name] = false;
            }
        } else {
            error[e.target.name] = false;
        }
        var pay_code = this.state.payment_code;
        var adjustment_code = this.state.adjustment_code;

        var pay_code_text = this.state.payment_code_text;
        var adjustment_code_text = this.state.adjustment_code_text;

        let show_loader = false;
        var form_submitted = this.state.is_submitted;
        if (e.target.name == 'insurance_code') {
            var insurance = this.props.batch_payment_reference.batch_reference.data[5].refrences.find((insurance) => {
                return insurance.value == e.target.value;
            });
            if (insurance) {
                adjustment_code = insurance.adj_code;
                if (adjustment_code != '') {
                    error['adjustment_code'] = false;
                    if (adjustment_code && this.state.dropdown_data.adjustment_code.length > 0) {
                        var adjutment = this.state.dropdown_data.adjustment_code.find((adj) => {
                            return adj.value == adjustment_code
                        });

                        adjustment_code_text = adjutment && adjutment.text ? adjutment.text : '';
                    }
                }

                if (isBatchPayments) {
                    pay_code = insurance.pay_code;
                    if (pay_code != '') {
                        error['payment_code'] = false;

                        if (pay_code && this.state.dropdown_data.payment_code.length > 0) {
                            var payment = this.state.dropdown_data.payment_code.find((pay) => {
                                return pay.value == pay_code
                            });
                            pay_code_text = payment && payment.text ? payment.text : '';
                        }
                    }
                }
            }
            else {
                adjustment_code = 0;
                adjustment_code_text = '';
                if (isBatchPayments){
                    pay_code = 0;
                    pay_code_text = '';
                }
            }
        }
        if (this.state.data && isPostingType) {
            show_loader = true;
            form_submitted = false;
            error = {}
        }
        
        // Remove Batch Payments - Group - Group Adjustment option
        if (isBatchPayments && isPostingType) {
            let groupOption = '2',
                isGroupOptionSelected = e.target.value == groupOption;
            if (isGroupOptionSelected) {
                let groupAdjustmentValue = '2',
                    groupAdjustmentIndex = this.state.dropdown_data.transaction_type
                        // Look for the appropriate value in the array to remove.
                        ? this.state.dropdown_data.transaction_type.map(tt => tt.value).indexOf(groupAdjustmentValue)
                        : null;
                // Remove Group adjustment option from transaction type
                if (groupAdjustmentIndex != null && groupAdjustmentIndex != -1) {
                    this.state.dropdown_data.transaction_type.splice(groupAdjustmentIndex, 1);
                }
            }
        }

        if (this._is_mounted) {
            this.setState({
                //loading: show_loader,
                adjustment_code: adjustment_code,
                payment_code: pay_code,
                is_submitted: form_submitted,
                payment_code_text: pay_code_text,
                adjustment_code_text: adjustment_code_text,
                error: error,
                [e.target.name]: e.target.value,
                [selected_text]: e.target.selectedOptions
                    ? e.target.selectedOptions[0].outerText
                    : e.target.options[e.target.selectedIndex].text
            }, () => {
                if (show_loader && this._is_mounted) {
                    this.setState({
                        data: this.update_data(this.state.data)
                    })
                }
            });
        }
    };

    // Input change handler.
    input_change_handler = e => {
        let error = this.state.error;
        error[e.target.name] = false;
        if (this._is_mounted) {
            this.setState({
                error: error,
                [e.target.name]: e.target.value
            });
        }
    };

    // Checkbox checkbox handler.
    checkbox_handler = e => {
        if (this._is_mounted) {
            this.setState({
                [e.target.id]: JSON.parse(e.target.checked)
            });
        }
    };

    calendar_change_handler = (name, date_object) => {
        let error = this.state.error;

        //if (error['formatted_' + name] && date_object != null) {
        //    error['formatted_' + name] = false;
        //}

        if (this.state.is_submitted || this.state.is_searched) {
            if (date_object == null) {
                error['formatted_' + name] = true;
            } else {
                error['formatted_' + name] = false;
            }
        } else {
            error['formatted_' + name] = false;
        }

        if (this._is_mounted) {
            this.setState({
                error: error,
                [name]: date_object ? custom_date_format(date_object._d, 'mm/dd/yyyy') : '',
                [`is_${name}`]: moment.isMoment(date_object) ? false : true,
                [`formatted_${name}`]: date_object
            });
        }
    };

    handle_date_range_change = (startDate, endDate) => {
        let error = this.state.error;

        if (this.state.is_submitted || this.state.is_searched) {
            if (startDate == null) {
                error['formatted_from_date'] = true;
            } else {
                error['formatted_from_date'] = false;
            }
            if (endDate == null) {
                error['formatted_to_date'] = true;
            } else {
                error['formatted_to_date'] = false;
            }

        } else {
            error['formatted_to_date'] = false;
            error['formatted_from_date'] = false;
        }

        if (this._is_mounted) {
            this.setState({
                error: error,
                from_date: startDate ? custom_date_format(startDate._d, 'mm/dd/yyyy') : '',
                to_date: endDate ? custom_date_format(endDate._d, 'mm/dd/yyyy') : '',
                [`is_to_date`]: moment.isMoment(endDate) ? false : true,
                [`is_from_date`]: moment.isMoment(startDate) ? false : true,
                [`formatted_from_date`]: startDate ? moment(startDate) : null,
                [`formatted_to_date`]: endDate ? moment(endDate) : null
            });
        }
    }

    validate_search_data = () => {
        let has_error = false;
        let error = {
            formatted_from_date: false,
            formatted_to_date: false,
            formatted_deposit_date: false,
            payment_amount: false,
            payment_code: false,
            adjustment_code: false,
        }
        if (!this.state.deposit_date) {
            has_error = true;
            error.formatted_deposit_date = true;
        }
        if (!this.state.from_date) {
            has_error = true;
            error.formatted_from_date = true;
        }

        if (!this.state.to_date) {
            has_error = true;
            error.formatted_to_date = true;
        }
        if (this.state.payment_amount == '' || this.state.payment_amount == ' ') {
            has_error = true;
            error.payment_amount = true;
        }

        if (this.page_type == batch_payment_constants.enum_page_type.batch_payments) {
            if (this.state.payment_code == '' || this.state.payment_code == ' ') {
                has_error = true;
                error.payment_code = true;
            }
        }

        if (!this.state.adjustment_code || this.state.adjustment_code == ' ') {
            has_error = true;
            error.adjustment_code = true;
        }


        if (has_error) {
            toastr.error('', global_constants.constants.mandatory_fields);
            if (this._is_mounted) {
                this.setState({
                    error: error
                });
            }


            return false;
        }


        if (this.state.formatted_from_date && this.state.formatted_to_date && (this.state.formatted_to_date < this.state.formatted_from_date)) {
            toastr.error('', batch_payment_constants.messages.date_validation);
            return false;
        }

        return true
    }

    search_handler = async (e = undefined) => {
        if (this._is_mounted) {
            this.setState({
                is_submitted: false,
                is_searched: true
            });
        }

        if (!this.validate_search_data()) return false;
        let error = {
            formatted_deposit_date: false,
            payment_amount: false,
            payment_code: false,
            adjustment_code: false,
        }
        if (this._is_mounted) {
            this.setState({
                loading: true,
                error: error
            });
        }
        this.search_criteria = {
            ...this.search_criteria,
            patient_id: this.payment_notes.patient_id,
            from_date: this.state.from_date,
            to_date: this.state.to_date,
            insurance_code: this.state.insurance_code == this.insurance_code_all_value ? null : this.state.insurance_code,
            show_all: this.state.show_all,
        }
        await this.get_report_data()
    };

    get_report_data = async () => {
        await search_batch_payments(this.search_criteria, this.payment_notes.token).then((response) => {
            // session_storage.set('batch_search_criteria', this.search_criteria);
            this.set_values_on_search_success(response.data);
        }).catch((error) => {
            if (this._is_mounted) {
                this.setState({
                    loading: false,
                    search_invoked: false
                });
            }
            if (error.response.data) {
                const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                toastr.error('', toastr_options);
            }
        });
    }

    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }
    //To Do, need to move this in utility
    option_formater_for_multiple_column = (
        array,
        text1,
        text2,
        value,
        add_blank = false,
        sorted = true,
        blank_text = ' '
    ) => {
        // let options = array
        let options = array.map(item => {
            return {
                text: item[text1] + ' - ' + item[text2],
                value: item[value]
            };
        });

        if (sorted) {
            options = options.sort(function (obj1, obj2) {
                var nameA = obj1.text.toLowerCase(),
                    nameB = obj2.text.toLowerCase();
                if (nameA < nameB)
                    //sort string ascending
                    return -1;
                if (nameA > nameB) return 1;
                return 0;
            });
        }

        if (add_blank) {
            options.unshift({
                text: blank_text,
                value: ' '
            });
        }
        return options;
    };

    //Function calls on initialization of export report data
    on_export_button = () => {
        this.title = this.set_title('\n');
        let data = []
        if (this.batch_detail_child && this.batch_detail_child.get_all_charge_data) {
            data = [...this.batch_detail_child.get_all_charge_data().data];
        }
        batch_payments_export_data(data, this.title, this.report_name, this.state.posting_type, this.page_type, this.props.features.krakenBill333RemoveAndReplaceMedicareCrossoverOnPayersPage);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Export);
    };

    //Function calls on initialization of Print report data
    on_print_button = () => {
        this.title = this.set_title('<br>');
        let data = []
        if (this.batch_detail_child && this.batch_detail_child.get_all_charge_data) {
            data = this.batch_detail_child.get_all_charge_data().data;
        }
        batch_payments_print_data(data, this.title, this.state.posting_type, this.page_type, this.props.features.krakenBill333RemoveAndReplaceMedicareCrossoverOnPayersPage);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
    };

    // Function calls save billing audit on Export or Print
    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;

        const payload: ReportConstants.IPayloadForBillingAudit = {
            reportId: this.reportId,
            contextTitle: `${this.report_name} - ${ReportConstants.ReportEventAction[eventActionId]}`,
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: ReportConstants.ReportEntityTypeId.D_Charge,
            data: { Records: Number(this.state.data.data.length) }
        }

        const reqBody = generateAuditDataEntryPayload(payload);

        ReportConstants.saveAuditPrintExport(reqBody, accessToken);
    };

    on_click_clear = () => {
        this.set_initial_value();
    }

    validate_data_on_save = () => {
        let has_error = false;
        let error = {
            formatted_deposit_date: false,
            payment_amount: false,
            payment_code: false,
            adjustment_code: false,
        }
        if (!this.state.deposit_date) {
            has_error = true;
            error.formatted_deposit_date = true;
        }
        if (this.state.payment_amount == '' || this.state.payment_amount == ' ') {
            has_error = true;
            error.payment_amount = true;
        }

        if (this.page_type == batch_payment_constants.enum_page_type.batch_payments) {
            if (this.state.payment_code == '' || this.state.payment_code == ' ') {
                has_error = true;
                error.payment_code = true;
            }
        }

        if (!this.state.adjustment_code || this.state.adjustment_code == ' ') {
            has_error = true;
            error.adjustment_code = true;
        }

        if (has_error) {
            toastr.error('', global_constants.constants.mandatory_fields);

            if (this._is_mounted) {
                this.setState({
                    error: error,
                });
            }

            return false;
        }

        return true
    }

    prepare_data_for_save_request = () => {
        let payments = [];
        var total = {};
        if (this.batch_detail_child && this.batch_detail_child.get_all_charge_data) {
            var json_data = { ... this.batch_detail_child.get_all_charge_data() };
            payments = [...json_data.data];
            total = { ...json_data.total };
        }
        let state = this.state;
        if (state.posting_type == batch_payment_constants.enum_posting_type.group) {
            payments = payments.filter(val => { return val.yes_no == "Y" })
        }
        payments = payments.filter(val => {
            var is_xfer_amount_empty = (val.xfer_amount.toString() == '' || val.xfer_amount.toString() == '0.00') ? true : false;
            var is_pay_amount_empty = (val.pay_amount.toString() == '' || val.pay_amount.toString() == '0') ? true : false;
            var is_adj_amounts_empty = (val.adj_amounts[0].amount.toString() == '' || val.adj_amounts[0].amount.toString() == val.owed_amount.toFixed(2)) ? true : false;
            let is_allow_amount_empty = !!(is_null_or_white_space(val.allow_amount));

            return (!(is_xfer_amount_empty && is_allow_amount_empty && is_pay_amount_empty && is_adj_amounts_empty))

        });
        let data = {
            "patient_id": this.patient_id,
            "posting_type": isNaN(state.posting_type) ? 1 : parseInt(state.posting_type),
            "deposit_date": state.deposit_date,
            "insuranc_type_id": state.insurance_type,
            "payment_code": state.payment_code.toString(),
            "adjustment_code": state.adjustment_code.toString(),
            "total_eob_amount": state.payment_amount,
            "batch_Num": state.batch,
            "transaction_type": isNaN(state.transaction_type) ? 1 : parseInt(state.transaction_type),
            "money_type": isNaN(state.payment_type) ? 1: parseInt(state.payment_type),
            "credit_check_number": state.check_card_number,
            "payments": payments,
            "total": total,
            "page_type": this.page_type,
            "charge_open_date": state.data && state.data.data && state.data.data.length > 0 ? state.data.data[0].charge_open_date:new Date()
        }

        return data;
    }
    table_level_validation = (data) => {
        let payments = [...data.payments];
        var invalid_payment = payments.filter(val => {
            return (try_parse_float(val.xfer_amount) != 0 && (val.xfer_ins_code_id == undefined || val.xfer_ins_code_id == null || val.xfer_ins_code_id.trim().toString() == ''));
        });
        if (invalid_payment.length > 0) {
            invalid_payment.map((item) => {
                if (document.getElementById('transfer_insurance' + item.row_index)) {
                    //invalid_payment[0].xfer_ins_code_id_selected = true;
                    document.getElementById('transfer_insurance' + item.row_index).className = 'mar-t-0 dropdown-options-wrap req-background-inp ';
                    document.getElementById('transfer_insurance' + item.row_index).focus();
                }
            });
            if (document.getElementById('transfer_insurance' + invalid_payment[0].row_index)) {
                //document.getElementById('transfer_insurance' + item.row_index).className = 'mar-t-0 dropdown-options-wrap req-background-inp ';
                document.getElementById('transfer_insurance' + invalid_payment[0].row_index).focus();
            }
            toastr.error('', batch_payment_constants.messages.required_field);

            //if (this._is_mounted) {
            //    this.setState({
            //        data: { data: payments }
            //    })
            //}
            return false;
        }

        if (this.state.posting_type == batch_payment_constants.enum_posting_type.group) {
            if (this.batch_detail_child && this.batch_detail_child.get_all_charge_data_group) {
                if (this.batch_detail_child.get_all_charge_data_group()) {
                    return false;
                }
            }
        }

        if (this.state.posting_type != batch_payment_constants.enum_posting_type.group && try_parse_float(data.total.pay_amount) != try_parse_float(data.total_eob_amount)) {
            toastr.error('', batch_payment_constants.messages.less_payment_amount);
            return false;
        }
        return true;
    }
    set_loader = (flag) => {
        if (this._is_mounted) {
            this.setState({
                loading: flag
            });
        }
    }
    on_save_click = async (skip_validation = false) => {       
        this.is_save_enabled = false;       
        if (this._is_mounted) {
            this.setState({
                is_submitted: true,
                is_searched: false
            });
        }
        // get all the filled data from current page and retrieve data from grid
        let json_data = this.prepare_data_for_save_request();

        json_data.deposit_date = date_format_with_no_time(json_data.deposit_date);
        json_data.payments.map((item) => {
            item.date_of_service = date_format_with_no_time(item.date_of_service);
        });
        json_data.payments.map(p => {
            p.xfer_det_amounts.map((item) => {
                if (item.amount == '') {
                    item.amount = 0;
                }
            });
        });

        //console.log(json_data);
        // apply Required validations
        if (!skip_validation) {
            if (!this.validate_data_on_save()) { this.is_save_enabled = true; return false; }
            if (this.batch_detail_child && this.batch_detail_child.table_row_validation) {
                if (!this.batch_detail_child.table_row_validation(true)) {
                    this.is_save_enabled = true;
                    return false;
                }
            }
        }
        // return false;
        //if (!this.table_level_validation(json_data)) return false;

        if (this.state.deposit_date) {
            let accrual_flag = false;
            let open_month = null;
            const auth_data = local_storage.get("auth_data");
            if (auth_data) {
                accrual_flag = auth_data.accrual_accounting || false;
                open_month = auth_data.open_month || null;
            }
            if (accrual_flag) {
                if (new Date(open_month) > new Date(this.state.deposit_date)) {
                    toastr.error('', batch_payment_constants.messages.close_open_month);
                    this.is_save_enabled = true;
                    return false;
                }
            }
        }

        // this.setState({
        //    is_submitted: true
        // });

        if (this._is_mounted) {
            this.setState({
                loading: true
            })
        }

        json_data.payments.forEach(payment => {
            payment.xfer_ins_code_id = is_null_or_white_space(payment.xfer_ins_code_id) ? null : parseInt(payment.xfer_ins_code_id);
            payment.allow_amount = is_null_or_white_space(payment.allow_amount) ? null : payment.allow_amount;
        });

        // call save api
        await save_batch_payments(json_data, this.token).then((res) => {
            this.is_save_enabled = true;
            if (res.data.status == 1) {
                if (res.data.messages && res.data.messages.length > 0) {
                    if (res.data.messages[0].message != '') {
                        toastr.success('', res.data.messages[0].message);
                        
                    }
                }
                //let update_total_eob = 0;
                if (this.state.total_payment_amount != '') {
                    let update_total_eob = try_parse_float(this.state.total_payment_amount) - try_parse_float(this.state.payment_amount);
                    if (this._is_mounted) {
                        this.setState({
                            payment_amount: "",
                            data: { data: [] },
                            total_payment_amount: parseFloat(update_total_eob.toString()).toFixed(2),
                            loading: false,
                            is_submitted: false
                        })
                    }
                }
                else {
                    if (this._is_mounted) {
                        this.setState({
                            payment_amount: "",
                            data: { data: [] },
                            loading: false,
                            is_submitted: false
                        })
                    }
                }


                if (document.getElementById('app_header_search_patient_icon')) {
                    document.getElementById('app_header_search_patient_icon').click();
                }
            } else {
                if (res.data && res.data.messages && res.data.messages.length > 0) {
                    let charge_exception = res.data.messages.filter((item) => {
                        return item.message === global_constants.charge_exception.text
                    })
                    if (charge_exception && charge_exception.length>0) {
                        if (this._is_mounted) {
                            this.setState({
                                loading: false,
                                is_submitted: false,
                                show_bummepd_charge_message_popup: true
                            })
                        }
                    } else {
                        const toastr_options = this.show_html_content_toaster(get_all_error(res.data));
                        toastr.error('', toastr_options);
                    }
                }
                if (this._is_mounted) {
                    this.setState({
                        loading: false,
                        is_submitted: false

                    })
                }
                //if (res.data.messages) {
                //    const toastr_options = this.show_html_content_toaster(get_all_error(res.data));
                //    toastr.error('', toastr_options);
                //}
            }
            this.is_save_enabled = true;
            //console.log("on response success");

        }).catch((error) => {
            if (this._is_mounted) {
                this.setState({
                    loading: false,
                    is_submitted: false
                });
            }
            if (error.response.data) {
                const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                toastr.error('', toastr_options);               
            }
            this.is_save_enabled = true;
        });


    }

    //Used to create the title for export and print.
    set_title = separter => {
        let title = ``;
        title += `Posting Type: ${this.state.posting_type_text || ''}<br>`
        title += `Deposit Date: ${this.state.deposit_date || ''}<br>`
        title += `Batch: ${this.state.batch || ''}<br>`
        if (this.page_type == batch_payment_constants.enum_page_type.batch_payments) {
            title += `Total Payment Amount: ${this.state.total_payment_amount || ''} <br>`
            //title += `Payment Code: ${this.state.payment_code_text || ''} <br>`
        } else {
            title += `Total Adjustment Amount: ${this.state.payment_amount || ''} <br>`
        }
        title += `Payment Type: ${this.state.payment_type_text || ''} <br>`
        title += `Check / Credit Card Auth Number: ${this.state.check_card_number || ''}<br>`;
        if (this.page_type == batch_payment_constants.enum_page_type.batch_payments) { 
            title += `Insurance Code: ${this.state.insurance_code_text || ''}<br>`
            title += `Payment Amount: ${this.state.payment_amount || ''} <br>`
            title += `Payment Code: ${this.state.payment_code_text || ''} <br>`
        }
        title += `Adjustment Code: ${this.state.adjustment_code_text || ''} <br>`
        if (this.page_type == batch_payment_constants.enum_page_type.batch_adjustment) {
            title += `Insurance Code: ${this.state.insurance_code_text || ''}<br>`
        }
        title += `Date of Service From: ${this.state.from_date || ''}<br>`
        title += `Date of Service Through: ${this.state.to_date || ''}<br>`
        
        if (this.state.posting_type == 2) {
            title += `Transaction Type: ${this.state.transaction_type_text || ''} <br>`
        }
        title += `Show All Charges: ${this.state.show_all ? 'Yes' : 'No'}`
        
       
        this.company_name = `${this.props.user_login_details.user_data.data.company_name}`;
        this.patient_name = `Patient Name: ${this.props.patient_details.patient_header.data.name.first_name} ${this.props.patient_details.patient_header.data.name.last_name}`;
        this.account_no = `Patient ID: ${session_storage.get('active_patient')}`;
        this.current_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
        return this.title = decodeURIComponentSafe(
            this.report_name +
            separter +
            separter +
            this.company_name +
            separter +
            this.patient_name +
            separter +
            this.account_no +
            separter +
            title.replace(/<br>/g, separter) +  
            separter +
            separter +
            this.current_date +
            separter +
            separter, 1
        );
    };
    update_data = (data) => {
        if (data) {
            //let state_data = data ? data : this.state.charge_detail;
            let total_adjustments = batch_payment_constants.total_adjustment(this.state.posting_type);
            let default_adjustment_reason = this.state.posting_type != batch_payment_constants.enum_posting_type.multiple_entry ? '45' : '0';
            let default_adjustment_reason_text = this.state.posting_type != batch_payment_constants.enum_posting_type.multiple_entry ? '45 - Charges exceed your contracted/ legislated fee arrangement.' : 'No Adjustment Code';

            let default_adjustment_qual = this.state.posting_type == batch_payment_constants.enum_posting_type.standard_payment ? 'CO' : ' ';
            let default_adjustment_qual_text = this.state.posting_type == batch_payment_constants.enum_posting_type.standard_payment ? 'CO' : 'NA';

            // Batch Payments - Group: Save in db as NA 45
            if (this.page_type == batch_payment_constants.enum_page_type.batch_payments &&
                this.state.posting_type == batch_payment_constants.enum_posting_type.group
            ) {
                default_adjustment_qual = ' ';
                default_adjustment_qual_text = 'NA';
                default_adjustment_reason = '45';
                default_adjustment_reason_text = '45 - Charges exceed your contracted/ legislated fee arrangement.';
            }

            for (var i = 0; i < data.data.length; i++) {
                data.data[i]['allow_amount'] = '';
                data.data[i]['pay_amount'] = '';
                data.data[i]['owed_amount'] = data.data[i].owed_amount;
                if (total_adjustments == 1) {
                    data.data[i]['adj_amounts'] = [{ "sequence": 1, "amount": '' }];
                    data.data[i]['xfer_det_amounts'] = [{ "sequence": 1, "amount": '' }];
                    data.data[i].adj_code_reason_ids = [{ "sequence": 1, "value": default_adjustment_reason, title: default_adjustment_reason_text }];
                    data.data[i].adj_qualifier_ids = [{ "sequence": 1, "value": default_adjustment_qual, title: default_adjustment_qual_text }];
                }
                else {
                    data.data[i]['adj_amounts'] = [{ "sequence": 1, "amount": '' }, { "sequence": 2, "amount": '' }, { "sequence": 3, "amount": '' }, { "sequence": 4, "amount": '' }, { "sequence": 5, "amount": '' }];
                    data.data[i]['xfer_det_amounts'] = [{ "sequence": 1, "amount": '' }, { "sequence": 2, "amount": '' }, { "sequence": 3, "amount": '' }, { "sequence": 4, "amount": '' }, { "sequence": 5, "amount": '' }];
                    data.data[i].adj_code_reason_ids = [{ "sequence": 1, "value": default_adjustment_reason, title: default_adjustment_reason_text }, { "sequence": 2, "value": default_adjustment_reason, title: default_adjustment_reason_text }, { "sequence": 3, "value": default_adjustment_reason, title: default_adjustment_reason_text }, { "sequence": 4, "value": default_adjustment_reason, title: default_adjustment_reason_text }, { "sequence": 5, "value": default_adjustment_reason, title: default_adjustment_reason_text }];
                    data.data[i].adj_qualifier_ids = [{ "sequence": 1, "value": default_adjustment_qual, title: default_adjustment_qual_text }, { "sequence": 2, "value": default_adjustment_qual, title: default_adjustment_qual_text }, { "sequence": 3, "value": default_adjustment_qual, title: default_adjustment_qual_text }, { "sequence": 4, "value": default_adjustment_qual, title: default_adjustment_qual_text }, { "sequence": 5, "value": default_adjustment_qual, title: default_adjustment_qual_text }];
                }
                if (total_adjustments == 2) {
                    data.data[i]["yes_no"] = 'N';
                }
                data.data[i]['xfer_amount'] = '';
                data.data[i]['crossover'] = 'N';

                data.data[i].error = { transfer_code_id: false };
                data.data[i].transfer_code_id = '3';
                data.data[i].transfer_code_text = '3 - Copay';
                data.data[i].xfer_ins_code_id = '';
                //data.data[i].xfer_ins_code_id_selected = false;
                data.data[i].xfer_ins_code_text = '';
                data.data[i].from_ins_code_id = data.data[i].cur_ins_code;
                data.data[i].from_ins_code_text = data.data[i].from_ins_code_id == 0 ?
                    "0 - Patient" : (data.data[i].ins_code + ' ' + data.data[i].ins_name);
                data.data[i].yes_no = '';
                data.data[i].row_index = i;
                data.data[i]['total_adjustment'] = total_adjustments;

                if (document.getElementById('transfer_insurance' + i)) {
                    document.getElementById('transfer_insurance' + i).className = 'mar-t-0';
                }

                // Reset Adjustment qualifier validation highlights
                for (let j = 0; j < data.data[i].adj_qualifier_ids.length; j++) {
                    let rowAdjustmentQualifierControl = document.getElementById(`${enum_selection_type.adjustment_qual}${j}${i}`);
                    if (rowAdjustmentQualifierControl) {
                        rowAdjustmentQualifierControl.className = 'mar-t-0 ';
                    }
                }

                var ins_data = [...data.data[i].charge_insurance_list];
                data.data[i].charge_insurance_list_from = constants.option_formater(
                    ins_data,
                    'description',
                    'insurance_code_id',
                    false, // to add blank object
                    false, // to shorted object
                );
                data.data[i]['charge_insurance_list_to_transfer'] = constants.option_formater(
                    ins_data,
                    'description',
                    'insurance_code_id',
                    true, // to add blank object
                    false, // to shorted object
                    'Select'
                )

            }
            //if (data) {

            //}
        }
        //console.log("updated data",data);
        return data;
        //if (this._is_mounted) {
        //    this.setState({
        //        charge_detail: state_data,
        //    })

        //}
    }
    amount_change = (value, name) => {
        let error = this.state.error;
        error[name] = false;

        if (this.state.is_submitted || this.state.is_searched) {
            if (value.trim() == '') {
                error[name] = true;
            } else {
                error[name] = false;
            }
        } else {
            error[name] = false;
        }
        if (this._is_mounted) {
            this.setState({
                error: error,
                [name]: value
            });
        }
    }

    // Search Criteria Toggler
    controls_toggle = () => {
        if (this._is_mounted) {
            this.setState({
                controls_is_expended: !this.state.controls_is_expended
            });
        }
    };

    handle_focus_on_tab = (event) => {
        if (!event.shiftKey && event.keyCode == '9') {
            if (!this.state.search_invoked || (this.state.data && this.state.data.data.length == 0)) {
                set_focus_to_app_header(event);
            }
        }
    }

    focus_adjustment = (e) => {
        if (e.which == 9 && e.shiftKey) {
            let total_charge_counts = this.state.data ? this.state.data.data ? this.state.data.data.length : 0 : 0
            let required_elem_id = total_charge_counts == 1 ? 'trans_code' + (total_charge_counts - 1).toString(): 'adjustment' + 0 + '_' + (total_charge_counts - 1).toString();
            if (required_elem_id && document.getElementById(required_elem_id)) {
                document.getElementById(required_elem_id).focus();
                e.preventDefault();
            }
        }
    }
    refresh_bummepd_charge_modal = () => {
        if (this._is_mounted) {
            this.setState({
                show_bummepd_charge_message_popup: false
            }, () => {
                 this.get_report_data()
                //if (this.props.charge_review_or_audit) {
                //    if (this.props.save_response) {
                //        this.props.save_response();
                //    }
                //}
                //else if (this.state.charge.charge_id > 0) {
                //    this.props.history.goBack();
                //}
            });
        }
    }
    on_close_bummepd_charge_popup = () => {
        if (this._is_mounted) {
            this.setState({
                show_bummepd_charge_message_popup: false
            });
        }
    }
    //It renders report grid and its search controls.
    render() {
        const { is_submitted, error, required, is_searched } = this.state;       
        return (
            <div className='common-forms unassigned-payment batch-payment'>
                <Dimmer active={this.state.loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                {this.state.account_notes.length > 0 && (
                    <ChargeAccountNotes show_stick_on={true} accountNotes={this.state.account_notes} />
                )}
                {this.state.show_bummepd_charge_message_popup ?
                    <ConfirmationComponent message={global_constants.charge_exception.message}
                        title='Confirm' show_modal={this.state.show_bummepd_charge_message_popup}
                        onCloseModal={this.on_close_bummepd_charge_popup}
                        save_button_text='Refresh'
                        on_force_save_click={(e) => { this.refresh_bummepd_charge_modal() }} />
                    : ''}
                <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                    <Grid.Column computer={16}>
                        <h3
                            className='ui header left aligned batch_header'
                            dangerouslySetInnerHTML={{ __html: this.report_name }}
                        />
                    </Grid.Column>
                </Grid>
                <div
                    className='auto-height-patient-full-details-scroll'
                    id='scrollable-content-area'

                >
                    <Form autoComplete='off' >
                        <Grid>
                            <Grid.Column computer={16} tablet={16} textAlign='left' className='accordionColumn'>
                                <Accordion fluid styled>
                                    <Accordion.Title
                                        active={this.state.controls_is_expended}
                                        index={0}
                                        onClick={this.controls_toggle}>
                                        <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name={this.state.controls_is_expended ? 'angle down':'angle right'} />
                                        Search Criteria
                                    </Accordion.Title>
                                    <Accordion.Content active={this.state.controls_is_expended}>
                                        {this.page_type == batch_payment_constants.enum_page_type.batch_payments?                                        
                                            <React.Fragment>
                                                <Grid style={{ paddingTop: '10px' }}>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <label>Posting Type</label>
                                                            <Selection
                                                                id='posting_type'
                                                                name='posting_type'
                                                                options={this.state.dropdown_data.posting_type}
                                                                onChange={(value, e) => {
                                                                    this.dropdown_change_handler(e);
                                                                }}

                                                                defaultValue={this.state.posting_type}
                                                                style={'dropdown-options-wrap'}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field className={error.formatted_deposit_date ? "requiredWithBgColor" : ''}>
                                                            <label>Deposit Date{' '}
                                                                {
                                                                    <span
                                                                        className={
                                                                            (is_searched || is_submitted) && error.formatted_deposit_date
                                                                                ? 'req-alert'
                                                                                : 'req-alert_normal'
                                                                        }
                                                                    >(required)
                                                        </span>
                                                                }
                                                            </label>
                                                            <DatePicker
                                                                maxDate={moment().format('MM/DD/YYYY')}
                                                                date_update={date_object => this.calendar_change_handler('deposit_date', date_object)}
                                                                date={this.state.formatted_deposit_date}
                                                                is_required={(is_searched || is_submitted) && error.formatted_deposit_date}
                                                                minDate={this.minDate}
                                                                name='deposit_date'
                                                                id_popper="root"
                                                                id="id-deposit-date"
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <label>Batch</label>
                                                            <Input
                                                                maxLength={12}
                                                                name={'batch'}
                                                                type={'text'}
                                                                id={'batch'}
                                                                value={this.state.batch}
                                                                onChange={e => this.input_change_handler(e)}
                                                                autoComplete='new-password'
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <label>Total Payment Amount</label>
                                                            <CurrencyFormatter
                                                                onChange={(values) => { this.amount_change(values, "total_payment_amount") }}
                                                                prefix='$'
                                                                maxLength={15}
                                                                id={'total_payment_amount'}
                                                                defaultValue={this.state.total_payment_amount}
                                                                allowNegative={true}
                                                                disabled={false}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>                                                
                                                    </Grid>
                                                <Grid>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <label>Payment Type</label>
                                                            <Selection
                                                                id='payment_type'
                                                                name='payment_type'
                                                                options={this.state.dropdown_data.payment_type}
                                                                onChange={(value, e) => {
                                                                    this.dropdown_change_handler(e);
                                                                }}
                                                                defaultValue={this.state.payment_type}
                                                                style={'dropdown-options-wrap'}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field>
                                                                <label>Check / Credit Card Auth Number</label>
                                                                <Input
                                                                    maxLength={50}
                                                                    name={'check_card_number'}
                                                                    type={'text'}
                                                                    id={'check_card_number'}
                                                                    value={this.state.check_card_number}
                                                                    onChange={e => this.input_change_handler(e)}
                                                                    autoComplete='new-password'
                                                                />
                                                            </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <label>Insurance</label>
                                                            <Selection
                                                                id='insurance_code'
                                                                name='insurance_code'
                                                                options={this.state.dropdown_data.insurance_code}
                                                                onChange={(value, e) => {
                                                                    this.dropdown_change_handler(e);
                                                                }}
                                                                defaultValue={this.state.insurance_code}
                                                                style={'dropdown-options-wrap'}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field className={(is_searched || is_submitted) && error.payment_amount ? "requiredWithBgColor" : ''}>
                                                            <label>{'Payment Amount'} {' '}
                                                                {
                                                                    <span
                                                                        className={
                                                                            (is_searched || is_submitted) && error.payment_amount
                                                                                ? 'req-alert'
                                                                                : 'req-alert_normal'
                                                                        }
                                                                    >(required)
                                                    </span>
                                                                }
                                                            </label>
                                                            <CurrencyFormatter
                                                                onChange={(values) => { this.amount_change(values, "payment_amount") }}
                                                                prefix='$'
                                                                maxLength={15}
                                                                id={'payment_amount'}
                                                                defaultValue={this.state.payment_amount}
                                                                allowNegative={true}
                                                                disabled={false}
                                                                className={(is_searched || is_submitted) && error.payment_amount ? 'red-error-thin' : ''}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                </Grid>
                                                <Grid>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <label>Payment Code{' '}
                                                                {
                                                                    <span
                                                                        className={
                                                                            (is_searched || is_submitted) && error.payment_code
                                                                                ? 'req-alert'
                                                                                : 'req-alert_normal'
                                                                        }
                                                                    >(required)
                                                                    </span>
                                                                }
                                                            </label>
                                                            <Selection
                                                                id='payment_code'
                                                                name='payment_code'
                                                                options={constants.option_formater(
                                                                    this.state.dropdown_data.payment_code,
                                                                    'text',
                                                                    'value',
                                                                    true, // to add blank object
                                                                    false, // to shorted object
                                                                    'Select'
                                                                )}

                                                                onChange={(value, e) => {
                                                                    this.dropdown_change_handler(e, true);
                                                                }}
                                                                defaultValue={this.state.payment_code}
                                                                style={(is_searched || is_submitted) && error.payment_code
                                                                    ? 'dropdown-options-wrap req-background-inp'
                                                                    : 'dropdown-options-wrap'}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <label>Adjustment Code{' '}
                                                                {
                                                                    <span
                                                                        className={
                                                                            (is_searched || is_submitted) && error.adjustment_code
                                                                                ? 'req-alert'
                                                                                : 'req-alert_normal'
                                                                        }
                                                                    >
                                                                        (required)
                                                                    </span>
                                                                }
                                                            </label>
                                                            <Selection
                                                                id='adjustment_code'
                                                                name='adjustment_code'
                                                                options={constants.option_formater(
                                                                    this.state.dropdown_data.adjustment_code,
                                                                    'text',
                                                                    'value',
                                                                    true, // to add blank object
                                                                    false, // to shorted object
                                                                    'Select'
                                                                )}

                                                                onChange={(value, e) => {
                                                                    this.dropdown_change_handler(e, true);
                                                                }}
                                                                defaultValue={this.state.adjustment_code}
                                                                style={(is_searched || is_submitted) && error.adjustment_code
                                                                    ? 'dropdown-options-wrap req-background-inp'
                                                                    : 'dropdown-options-wrap'}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <label>Date of Service{' '}
                                                                {
                                                                    <span className={(is_searched || is_submitted) && (error.formatted_from_date || error.formatted_to_date) ? 'req-alert' : 'req-alert_normal'}>
                                                                        (required)
                                                            </span>
                                                                }
                                                            </label>
                                                            <DateRangePickerComponent
                                                                id="date-of-service-range"
                                                                updateDatesChange={this.handle_date_range_change}
                                                                startDate={this.state.formatted_from_date}
                                                                endDate={this.state.formatted_to_date}
                                                                error={(is_searched || is_submitted) && (error.formatted_from_date || error.formatted_to_date)}
                                                                maxDate={moment()}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    
                                                    { this.state.posting_type === '2' && (
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field>
                                                                <label>Transaction Type</label>
                                                                <Selection
                                                                    id='transaction_type'
                                                                    name='transaction_type'
                                                                    options={this.state.dropdown_data.transaction_type}
                                                                    onChange={(value, e) => {
                                                                        this.dropdown_change_handler(e);
                                                                    }}
                                                                    defaultValue={this.state.transaction_type}
                                                                    style={'dropdown-options-wrap'}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    )}
                                                </Grid>
                                            </React.Fragment>
                                            :                                            
                                            <React.Fragment>
                                                <Grid style={{ paddingTop: '10px' }}>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <label>Posting Type</label>
                                                            <Selection
                                                                id='posting_type'
                                                                name='posting_type'
                                                                options={this.state.dropdown_data.posting_type}
                                                                onChange={(value, e) => {
                                                                    this.dropdown_change_handler(e);
                                                                }}

                                                                defaultValue={this.state.posting_type}
                                                                style={'dropdown-options-wrap'}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field className={error.formatted_deposit_date ? "requiredWithBgColor" : ''}>
                                                            <label>Deposit Date{' '}
                                                                {
                                                                    <span
                                                                        className={
                                                                            (is_searched || is_submitted) && error.formatted_deposit_date
                                                                                ? 'req-alert'
                                                                                : 'req-alert_normal'
                                                                        }
                                                                    >(required)
                                                    </span>
                                                                }
                                                            </label>
                                                            <DatePicker
                                                                maxDate={moment().format('MM/DD/YYYY')}
                                                                date_update={date_object => this.calendar_change_handler('deposit_date', date_object)}
                                                                date={this.state.formatted_deposit_date}
                                                                is_required={(is_searched || is_submitted) && error.formatted_deposit_date}
                                                                minDate={this.minDate}
                                                                name='deposit_date'
                                                                id_popper="root"
                                                                id="id-deposit-date"
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <label>Batch</label>
                                                            <Input
                                                                maxLength={12}
                                                                name={'batch'}
                                                                type={'text'}
                                                                id={'batch'}
                                                                value={this.state.batch}
                                                                onChange={e => this.input_change_handler(e)}
                                                                autoComplete='new-password'
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field className={(is_searched || is_submitted) && error.payment_amount ? "requiredWithBgColor" : ''}>
                                                            <label>{'Total Adjustment Amount'} {' '}
                                                                {
                                                                    <span
                                                                        className={
                                                                            (is_searched || is_submitted) && error.payment_amount
                                                                                ? 'req-alert'
                                                                                : 'req-alert_normal'
                                                                        }
                                                                    >(required)
                                                                    </span>
                                                                }
                                                            </label>
                                                            <CurrencyFormatter
                                                                onChange={(values) => { this.amount_change(values, "payment_amount") }}
                                                                prefix='$'
                                                                maxLength={15}
                                                                id={'payment_amount'}
                                                                defaultValue={this.state.payment_amount}
                                                                allowNegative={true}
                                                                disabled={false}
                                                                className={(is_searched || is_submitted) && error.payment_amount ? 'red-error-thin' : ''}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                                                                        
                                                </Grid>
                                                <Grid>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <label>Payment Type</label>
                                                            <Selection
                                                                id='payment_type'
                                                                name='payment_type'
                                                                options={this.state.dropdown_data.payment_type}
                                                                onChange={(value, e) => {
                                                                    this.dropdown_change_handler(e);
                                                                }}
                                                                defaultValue={this.state.payment_type}
                                                                style={'dropdown-options-wrap'}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <label>Check / Credit Card Auth Number</label>
                                                            <Input
                                                                maxLength={50}
                                                                name={'check_card_number'}
                                                                type={'text'}
                                                                id={'check_card_number'}
                                                                value={this.state.check_card_number}
                                                                onChange={e => this.input_change_handler(e)}
                                                                autoComplete='new-password'
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <label>Adjustment Code{' '}
                                                                {
                                                                    <span
                                                                        className={
                                                                            (is_searched || is_submitted) && error.adjustment_code
                                                                                ? 'req-alert'
                                                                                : 'req-alert_normal'
                                                                        }
                                                                    >
                                                                        (required)
                                                                    </span>
                                                                }
                                                            </label>
                                                            <Selection
                                                                id='adjustment_code'
                                                                name='adjustment_code'
                                                                options={constants.option_formater(
                                                                    this.state.dropdown_data.adjustment_code,
                                                                    'text',
                                                                    'value',
                                                                    true, // to add blank object
                                                                    false, // to shorted object
                                                                    'Select'
                                                                )}

                                                                onChange={(value, e) => {
                                                                    this.dropdown_change_handler(e, true);
                                                                }}
                                                                defaultValue={this.state.adjustment_code}
                                                                style={(is_searched || is_submitted) && error.adjustment_code
                                                                    ? 'dropdown-options-wrap req-background-inp'
                                                                    : 'dropdown-options-wrap'}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <label>Insurance</label>
                                                            <Selection
                                                                id='insurance_code'
                                                                name='insurance_code'
                                                                options={this.state.dropdown_data.insurance_code}
                                                                onChange={(value, e) => {
                                                                    this.dropdown_change_handler(e);
                                                                }}
                                                                defaultValue={this.state.insurance_code}
                                                                style={'dropdown-options-wrap'}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                  </Grid>
                                                <Grid>
                                                    <Grid.Column tablet={8} computer={4}>
                                                        <Form.Field>
                                                            <label>Date of Service{' '}
                                                                {
                                                                    <span className={(is_searched || is_submitted) && (error.formatted_from_date || error.formatted_to_date) ? 'req-alert' : 'req-alert_normal'}>
                                                                        (required)
                                                            </span>
                                                                }
                                                            </label>
                                                            <DateRangePickerComponent
                                                                id="date-of-service-range"
                                                                updateDatesChange={this.handle_date_range_change}
                                                                startDate={this.state.formatted_from_date}
                                                                endDate={this.state.formatted_to_date}
                                                                error={(is_searched || is_submitted) && (error.formatted_from_date || error.formatted_to_date)}
                                                                maxDate={moment()}
                                                            />
                                                        </Form.Field>
                                                    </Grid.Column>
                                                    {this.state.posting_type === '2' && (
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field>
                                                                <label>Transaction Type</label>
                                                                <Selection
                                                                    id='transaction_type'
                                                                    name='transaction_type'
                                                                    options={this.state.dropdown_data.transaction_type}
                                                                    onChange={(value, e) => {
                                                                        this.dropdown_change_handler(e);
                                                                    }}
                                                                    defaultValue={this.state.transaction_type}
                                                                    style={'dropdown-options-wrap'}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    )}
                                                </Grid>
                                            </React.Fragment>
                                        }
                                    </Accordion.Content>
                                </Accordion>
                            </Grid.Column>
                        </Grid>
                        <Grid>

                            <Grid.Column tablet={16} computer={16} textAlign='right'>
                                <Form.Field className='ui checkbox'>
                                    <div style={{ paddingRight: '15px' }}>
                                        <input
                                            type='checkbox'
                                            name={'show_all'}
                                            id={'show_all'}
                                            checked={this.state.show_all}
                                            onChange={e => this.checkbox_handler(e)}
                                        />
                                        <label className='chkbx_label' htmlFor={'show_all'}>
                                            {' Show All Charges'}
                                        </label>
                                    </div>
                                </Form.Field>
                                <Button id='cancel_button_id' type='button' onClick={() => this.on_click_clear()} basic
                                    content="Clear"/>
                                <Button
                                    type='submit'
                                    onClick={e => this.search_handler(e)}
                                    id='save_button_id'
                                    disabled={this.state.is_save_button_disabled}
                                    primary
                                    onKeyDown={this.handle_focus_on_tab}
                                    content="Search"/>
                            </Grid.Column>
                        </Grid>
                    </Form>
                    <Form autoComplete='off' onSubmit={() => this.on_save_click(false)} >
                        {
                            //this is hidden button, this button used to handle save on enter in grid or required save controls
                            <Button id='batch_payment_save_hidden_btn' type='submit' hidden onClick={() => this.on_save_click(false)} className="display-none"
                                disabled={!this.is_save_enabled || (!this.state.search_invoked || (this.state.data && this.state.data.data.length == 0))   } content="Save Hidden"/>
                                
                        }

                        {this.state.search_invoked ? this.state.data ? (
                            <BatchPaymentDetailComponent data={this.state.data} parent_state={this.state}
                                posting_type={this.state.posting_type}
                                on_save={this.on_save_click}
                                loading={this.set_loader}
                                page_type={this.page_type}
                                onRef={instance => { this.batch_detail_child = instance; }}
                            />
                        ) : (
                                <div style={{ marginTop: '20px' }}>
                                    <label>No Record Found!</label>
                                </div>
                            ) : ''}
                    </Form>
                </div>
                <Grid id='applicationFooterSticky' className={'sticky_footer'}>
                    <Grid.Column computer={16} textAlign='right'>
                        <Button id='export_report_button' type='submit' onClick={this.on_export_button}
                            {...(this.state.posting_type == batch_payment_constants.enum_posting_type.standard_payment ? { onKeyDown: (e) => { this.focus_adjustment(e) } } : {})}
                            disabled={!this.state.search_invoked || (this.state.data && this.state.data.data.length == 0)}
                            basic content="Export"/>
                        <Button id='print_report_button' type='submit' onClick={this.on_print_button}
                            disabled={!this.state.search_invoked || (this.state.data && this.state.data.data.length == 0)}
                            basic content="Print" />
                        <Button id='save_report_button' type='submit' onKeyDown={set_focus_to_app_header} onClick={() => this.on_save_click(false)} disabled={!this.is_save_enabled || (!this.state.search_invoked  || (this.state.data && this.state.data.data.length == 0))}
                            primary content="Save" />
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details,
        features: {
            krakenBill333RemoveAndReplaceMedicareCrossoverOnPayersPage: get_lauch_darkley_key_value(state.launch_darkly, kraken_bill_333_remove_and_replace_medicare_crossover_on_payers_page)
        },
        patient_account_notes_status: state.patient_details.patient_account_notes_status,
        patient_details: state.patient_details,
        batch_payment_reference: state.batch_payment_reference,
        patient_id:
            (state.patient_details &&
                state.patient_details.patient_header &&
                state.patient_details.patient_header.data &&
                state.patient_details.patient_header.data.id) ||
            '',
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            update_patient_header_info: update_patient_header_info,
            get_references: get_references,
            get_patient_account_notes_status: data => dispatch(get_patient_account_notes_status_action(data))
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BatchPayment);