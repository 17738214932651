import { CONSTANTS } from '../../reports/report_constants';
const initial_state = {
    
};

export default function (state = initial_state, { type, payload }) {

    switch (type) {

        case CONSTANTS.REQUEST_SUCCESS:
            return { ...state,  data : payload };
            break;
        case CONSTANTS.REQUEST_FAILURE:
            
            const messages = typeof payload.messages != 'undefined' && payload.messages !== null && payload.messages.constructor == Array ? payload.messages.map(data => data).join('.') : payload.message;

            return {
                ...state, data: { status: payload.status, message: messages }
            };
            break;
        case CONSTANTS.COMPANY_CHANGE:
            return { ...state, ["company_name"]: payload };            
        default:
            return state
    }

}
