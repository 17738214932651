import * as printExportConstants from "./print_export_constants"
import * as SensitiveInformationCollectionToExclude from './sensitive_information_collection_to_exclude'

export const getValueByElementId = (key: string): string => {
    let value = null;
    const tag = document.getElementById(key);

    const tagName = (tag as HTMLElement | null).tagName;
    switch (tagName) {
        case "INPUT":
            value = (tag as HTMLInputElement | null).value;
            break;
        case "SELECT":
            const select = tag as HTMLSelectElement | null;
            value = select.options[select.selectedIndex].text;
            break;
    }
    return value;
}

export const getValueFromReportComponentType = (item): string => {    
    let value = '';
    try {
        switch (item.control_type) {
            case "DropDownList":
                const select = document.getElementById(item.sequence) as HTMLSelectElement | null;
                if (select != null) {
                    value = select.options[select.selectedIndex].text;
                }
                break;
            case "LookupObjectPatient":
                const input = document.getElementById(item.sequence) as HTMLSelectElement | null;
                if (input != null) {
                    value = input.value;
                }
                break;
            case "DateRange":
                const startDate = (document.getElementById(`date_range_start_date_id${item.sequence}`) as HTMLInputElement).value;
                const endDate = (document.getElementById(`date_range_end_date_id${item.sequence}`) as HTMLInputElement).value;
                value = `${startDate} - ${endDate}`;
                break;
            case "CheckBox":
                value = item.default_value === true ? "Yes" : "No";
                break;
            default:
                value = item.default_value;
                break;
        }
    }
    catch (e) {
        return null;
    }
    return value;
}

export const AssignReportValueFromElementById = (search_criteria): void => {    
    search_criteria.filter(item => item.invisible === false).map(item => {
        item['viewable_text'] = getValueFromReportComponentType(item);        
    });
}

export const getReportDynamic = (reportId: any): any => {
    let reportValue= {
        entityReportId: 0,
        featureFlipName: ''
    }
    printExportConstants.DynamicInformationReportAudit.map((dataObject, index) => {
        dataObject.reports.map(item => {
            if (reportId == item.reportId) {
                reportValue = {
                    entityReportId: item.entityReportId,
                    featureFlipName: dataObject.featureFlipName
                };
            }
        });
    });
    return reportValue;
}

export const findSensitiveData = (reportId: number, item): boolean => {
    const sensitiveConditions: Map<number, string[]> = SensitiveInformationCollectionToExclude.getSensitiveConditions();

    const paramNames: string[] | undefined = sensitiveConditions.get(reportId);
    if (paramNames) {
        return paramNames.indexOf(item.param_name) !== -1 ? true : false;
    }

    return false;
};
