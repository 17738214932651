import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import TextareaAutosize from 'react-textarea-autosize';
import { Button, Checkbox, Dimmer, Form, Grid, Loader } from 'semantic-ui-react';
import * as global_constants from '../../../global_constants';
import { insurance_advance_search, insurance_quick_search } from '../../../shared/action/autosearch_action';
import { advanced_search_class, get_contracts, get_insurance_types, quick_search_class } from '../../../shared/action/shared_action';
import AddPrimaryInsurance from '../../../shared/component/add_primary_insurance_component';
import PromptNavigation from '../../../shared/component/prompt_navigation_component';
import * as session_storage from '../../../shared/session_storage_utility';
import { get_multiple_errors } from '../../../shared/utility';
import ClaimAdjCodeSearch from '../../constants/component/claim_adjustment_code_advanced_search';
import { add_data, get_data, get_search_data, update_data } from '../action/constants_action';
import * as admin_payment_const from '../constants';

class PayerAlertAddEdit extends React.Component<any, any> {
  _is_mount = false;
  constructor(props) {
    super(props);
    this.state = {
      patient_id: session_storage.get('active_patient'),
      loading: false,
      control: null,
      search_form: {
        insurance_class_id: null,
        insurance_code_id: null,
        message: '',
        show_inactive: true
      },
      initial_search_form: {
        insurance_class_id: null,
        insurance_code_id: null,
        message: '',
        show_inactive: true
      },
      go_forward: false,
      is_active: false,
      search_data: { rows: null, auto_search: null },
      ins_code_selected_row: null,
      ins_class_selected_row: null,
      ins_code_search_data: null,
      ins_class_search_data: null,
      mode: '',
      row: null,
      ins_error: false,
      alert_error: false,
      is_submitted: false,
      add_primary_insurance_modal: false,
      insurance_types: null,
      insurance_contract: null,
      ins_code_modal: false,
      by_pass_prompt: false
    };
  }
  default_search_form = null;
  ins_code_enum = 'ins_code';
  ins_class_enum = 'ins_class';
  edit_mode = 'edit';
  add_mode = 'add';
  token = '';
  is_fetched_row = false;
  is_cancel = false;
  code_id = 0;
  search_criteria = {
    insurance_class_id: '',
    insurance_code_id: '',
    message: '',
    show_inactive: false
  };
  ins_class_row = null;
  ins_code_row = null;
  initial_action = '';
  error_msg = null;
  new_search_form = null;
  initial_search_form = null;
  is_submitted = false;
  go_next: boolean = false;
  is_grid_visible = false;
  is_initial_result = false;
  ins_code_selected_row = {};
  ins_class_selected_row = {};
  by_pass_prompt: boolean = false;

  componentDidMount = () => {
    this._is_mount = true;
    document.body.classList.add('admin-framework');
    this.get_insurance_master_info();
    this.token = this.props.user_login_details.user_data.data.token_details.access_token;
    this.code_id = this.props.match.params && this.props.match.params.id ? this.props.match.params.id : 0;
    let controls = this.get_control();
    if (this.code_id) {
      if (this._is_mount) {
        this.setState({
          loading: true,
          control: controls
        });
      }
      this.is_fetched_row = true;
      this.get_view_detail(this.code_id);
    } else {
      if (this._is_mount) {
        this.setState({
          mode: this.add_mode,
          control: controls
        });
      }
      this.default_search_form = {
        search_form: { ...this.state.search_form },
        payer_selected_row: null,
        adj_code_selected_row: null
      };
    }
  };

  //componentDidUpdate = (prevProps, prevState) => {
  //  if (prevProps.user_login_details != this.props.user_login_details) {
  //    this.token = this.props.user_login_details.user_data.data.token_details.access_token;
  //  }
  //  if (this.is_cancel && prevState.mode != this.state.mode) {
  //    let search_form =
  //      this.default_search_form && prevState.mode == this.edit_mode
  //        ? {
  //            search_form: { ...this.default_search_form.search_form },
  //            ins_code_error: false,
  //            ins_class_error: false,
  //            ins_code_selected_row: { ...this.default_search_form.ins_code_selected_row },
  //            ins_class_selected_row: { ...this.default_search_form.ins_class_selected_row }
  //          }
  //        : { search_form: { ...this.state.search_form } };
  //    if (this._is_mount) {
  //      this.setState({ ...search_form });
  //    }
  //    this.is_cancel = false;
  //  }

  //  if (prevState.search_form !== this.state.search_form && this.default_search_form) {
  //    let is_data_changed =
  //      JSON.stringify(this.default_search_form.search_form) !== JSON.stringify(this.state.search_form);
  //  }
  //};
  componentDidUpdate(previousProps, previousState) {
      this.token = this.props.user_login_details.user_data.data.token_details.access_token; // update token at component update
  }
  componentWillUnmount = () => {
    this._is_mount = false;
    document.body.classList.remove('admin-framework');
    if (this.props.history.location) {
      var route_array = this.props.history.location.pathname.split('/');
      route_array && route_array[2] !== 'payer_alert' && session_storage.remove('payer_alert_search');
    }
  };

  // quick search handler in payer and adjustment code
  on_quick_search = async (params, type) => {
    if (type == this.ins_code_enum) {
      return await insurance_quick_search(params, this.token);
    } else if (type == this.ins_class_enum) {
      let param = { keyword: params };
      return await quick_search_class(param, this.token);
    }
  };

  on_advanced_search = async (params, type) => {
    if (this.ins_class_enum == type) {
      await advanced_search_class(params, this.token).then(
        res => {
          let result = res.data ? res.data.data : [];
          if (this._is_mount) {
            this.setState({
              ins_class_search_data: {
                rows: result,
                column: admin_payment_const.search_class_configuration.column_defs
              }
            });
          }
        },
        error => {
          if (error.response && error.response.data && error.response.data.messages) {
            toastr.error('', error.response.data.messages[0].message);
          }
        }
      );
    } else if (this.ins_code_enum == type) {
      params.records = 100;
      await insurance_advance_search(params, this.token).then(
        res => {
          let result = res.data ? res.data.data : [];
          if (this._is_mount) {
            this.setState({
              ins_code_search_data: {
                rows: result,
                column: admin_payment_const.search_insurance_configuration.column_defs
              }
            });
          }
        },
        error => {
          if (error.response && error.response.data && error.response.data.messages) {
            toastr.error('', error.response.data.messages[0].message);
          }
        }
      );
    }
  };

  // filter and modify result
  get_result = url => {
    get_search_data(this.token, url).then(
      res => {
        let result = res.data;
        if (result) {
          result = result.data.map((val) => {
            val.is_active = val.is_active ? 'Active' : 'Inactive';
            return val;
          });
        }
        if (this._is_mount) {
          this.setState({
            claim_adj_search_data: {
              rows: result,
              column: admin_payment_const.claim_adjust_code.column_def
            }
          });
        }
      },
      error => {
        if (error.response && error.response.data && error.response.data.messages) {
          toastr.error('', error.response.data.messages[0].message);
        }
      }
    );
  };

  // make http request on bases of request type get api result
  make_request = (url, type, data) => {
    let result = null;
    if (type == 'GET') {
      return get_data(this.token, url);
    } else if (type == 'POST') {
      return add_data(this.token, url, data);
    } else if (type == 'PUT') {
      return update_data(this.token, url, data);
    }

    return result;
  };

  // update state selected in searhc form
  update_data = (data, type) => {
    if (type == this.ins_class_enum) {
      if (this._is_mount) {
        this.setState({
          search_form: {
            ...this.state.search_form,
            insurance_class_id: data ? data.id : null
          },
          ins_class_selected_row: data,
          is_focused: false,
          ins_error: data == null ? false : data ? false : true
        });
      }
    } else {
      if (this._is_mount) {
        this.setState({
          search_form: {
            ...this.state.search_form,
            insurance_code_id: data ? data.id : null
          },
          ins_code_selected_row: data,
          is_focused: false,
          ins_error: data && data.id ? false : this.state.ins_error
        });
      }
    }
  };

  // validate form
  validate_form = () => {
    let ins_error = false;
    let alert_error = false;
    if (this.state.search_form.insurance_class_id == null && this.state.search_form.insurance_code_id == null) {
      ins_error = true;
    }

    if (this.state.search_form.message.trim() == '') {
      alert_error = true;
    }
    if (!alert_error && ins_error) {
      this.error_msg = [admin_payment_const.insurance_mandatory_fields];
    } else if (alert_error && !ins_error) {
      this.error_msg = [admin_payment_const.mandatory_fields];
    } else {
      this.error_msg = [admin_payment_const.mandatory_fields, admin_payment_const.insurance_mandatory_fields];
    }
    if (this._is_mount) {
      this.setState({
        ins_error: ins_error,
        alert_error: alert_error
      });
    }
    if (ins_error || alert_error) {
      return false;
    }
    return true;
  };

  // button handler like save
  save_handler = async (e, from_pop_up) => {
    e.preventDefault();
    this.go_next = false
    this.is_submitted = true;
    this.new_search_form = { ...this.state };
    if (this.validate_form()) {
      let controls = this.get_control();
      if (this._is_mount) {
        this.setState({
          loading: true,
          control: controls,
          is_save_button_disabled: true
        });
      }
      let url = admin_payment_const.payer_alert_search.api.payer_alert;
      url = this.code_id ? url + '/' + this.code_id : url;
      let method = this.code_id ? 'PUT' : 'POST';
      let params = {
        status: this.state.search_form.show_inactive,
        ...this.state.search_form
      };
      session_storage.set('payer_alert_search', {
        search_criteria: this.search_criteria,
        ins_class_selected_row: {},
        ins_code_selected_row: {}
      });
      await this.make_request(url, method, params).then(
        res => {
          if (res.data.status == 1) {
            this.go_next = true;
            if (this.code_id) {
              toastr.success('', admin_payment_const.messages.payer_alert_update);
            } else {
              toastr.success('', admin_payment_const.messages.payer_alert_add);
              this.initial_action = this.add_mode;
            }
          } else {
            if ((res.data.messages && res.data.messages[0].code == '50002') || res.data.messages[0].code == '50000') {
              this.go_next = false;
              toastr.error('', res.data.messages[0].message);
            }
          }
            if (this._is_mount) {
                this.setState({                    
                    search_form: { ...this.new_search_form.search_form },
                    initial_search_form: { ...this.new_search_form.search_form },
                    ins_code_selected_row: { ...this.new_search_form.ins_code_selected_row },
                    ins_class_selected_row: { ...this.new_search_form.ins_class_selected_row },
                    by_pass_prompt: !from_pop_up ? true : false,
                    loading: false,
                    is_save_button_disabled: false
                }, () => {
                    setTimeout(() => {
                        if (!from_pop_up && this.go_next) {
                            this.go_next = false;
                            this.props.history.goBack();
                        } 
                    },30)
                });
            }         
        },
        error => {
          this.setState({ 
            loading: false, 
            search_form: { ...this.default_search_form.search_form },
            is_save_button_disabled: false
           });
          if (error.response && error.response.data && error.response.data.messages) {
            toastr.error('', error.response.data.messages[0].message);
          }
        }
      );
    } else {
      let messages = this.error_msg;
      const toastr_options = this.show_html_content_toaster(get_multiple_errors(messages));
      toastr.error('', toastr_options);
    }
  };

  // cancel the edit mode
  clear_handle = () => {
    if (this._is_mount) {
      this.by_pass_prompt = true;
      this.setState(
        {
          by_pass_prompt: true
        },
        () => this.props.history.goBack()
      );
    }
  };

  formatted_row = data => {
    let row = { ins_code: null, ins_class: null };
    let ins_code = data.insurance_code_desc.split(',');
    row.ins_code = {
      insurance_name: ins_code[0],
      address1: ins_code.indexOf(1) ? ins_code[1] : '',
      city: ins_code.indexOf(2) ? ins_code[2] : '',
      state: ins_code.indexOf(3) ? ins_code[3] : '',
      zip: ins_code.indexOf(4) ? ins_code[4] : ''
    };
    row.ins_class = {
      insurance_class_code: data.insurance_class,
      insurance_class_description: data.insurance_class_desc
    };
    return row;
  };

  // get view detail by id by api calling
  get_view_detail = async code_id => {
    let formatted_row = null;
    let result = null;
    let url = admin_payment_const.payer_alert_search.api.payer_alert;
    url = admin_payment_const.payer_alert_search.api.payer_alert + '/' + code_id;
    if (this._is_mount) {
      this.setState({
        is_loading: true,
        logo_loading: true
      });
    }
    // await this.make_request(admin_payment_const.payer_alert_search.api.payer_alert + '/' + view_id, 'GET', null).then(
      get_data(this.token, url).then(
      res => {
        if (res.data && res.data.data) {
        result = res.data.data;
        formatted_row = this.formatted_row(res.data.data);
        if (this._is_mount) {
          this.setState({
            is_fetched: this.is_fetched_row,
            ins_code_selected_row: formatted_row.ins_code,
            ins_class_selected_row: formatted_row.ins_class,
            search_form: {
              ...this.state.search_form,
              insurance_class_id: result.insurance_class_id,
              insurance_code_id: result.insurance_code_id,
              message: result.message,
              show_inactive: result.status
            },
            initial_search_form: {
              ...this.state.search_form,
              insurance_class_id: result.insurance_class_id,
              insurance_code_id: result.insurance_code_id,
              message: result.message,
              show_inactive: result.status
            },
            loading: false,
            row: result,
            is_active: result.status
          });
        }
        this.default_search_form = {
          ins_code_selected_row: formatted_row.ins_code,
          ins_class_selected_row: formatted_row.ins_class,
          search_form: {
            ...this.state.search_form,
            insurance_class_id: result.insurance_class_id,
            insurance_code_id: result.insurance_code_id,
            message: result.message,
            show_inactive: result.status
          }
        };
        this.ins_code_row = formatted_row.ins_code;
        this.ins_class_row = formatted_row.ins_class;
      }
    },
    error => {
      if (error.response && error.response.data && error.response.data.messages) {
        toastr.error('', error.response.data.messages[0].message);
      }
    });
  };

  // get control data
  get_control = () => {
    let controls = {
      insurance_class: {
        type: 'ins_class',
        grid_config: {
          rows: null,
          column: admin_payment_const.search_class_configuration.column_defs
        },
        control_id: 'INSURANCE_CLASS',
        selected_row: null,
        error_message: 'No Record Found !',
        label: ['insurance_class_code', 'insurance_class_description']
      },
      insurance_code: {
        type: 'ins_code',
        grid_config: {
          rows: null,
          column: admin_payment_const.search_insurance_configuration.column_defs
        },
        control_id: 'TERTIARY_INSURANCE',
        error_message: 'No Record Found !',
        label: ['insurance_code', 'insurance_name', 'address1', 'city', 'state', 'zip'],
        selected_row: null
      }
    };
    return controls;
  };

  set_primary_insurance_details = data => {
    if (this._is_mount && data) {
      let row = {};
      row['insurance_code'] = data.insurance_code;
      row['insurance_name'] = data.name;
      row['address1'] = data.address ? data.address.address1 : '';
      row['city'] = data.address ? data.address.city : '';
      row['state'] = data.address ? data.address.state : '';
      row['zip'] = data.address ? data.address.zip : '';
      this.setState({
        ins_code_selected_row: row,
        ins_code_modal: !this.state.ins_code_modal,
        search_form: {
          ...this.state.search_form,
          insurance_code_id: data.id
        }
      });
    }
  };

  get_insurance_master_info = async () => {
    var ins_type = await get_insurance_types(this.token).then(res => {
      if (res.data.data != null && res.data.data.length > 0) {
        return res.data.data.map((val, index) => {
          return { key: index, value: val.id, text: val.name };
        });
      }
    }); // Get All insurance types from api

    const available_contracts = await get_contracts(this.token).then(res => {
      if (res.data.data != null && res.data.data.length > 0) {
        return res.data.data;
      }
    });
    if (this._is_mount) {
      this.setState({
        insurance_types: ins_type,
        insurance_contract: available_contracts
      });
    }
  };

  show_html_content_toaster = msg => {
    return {
      component: () => (
        <div>
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        </div>
      ),
      timeOut: global_constants.toastr_options.toastr_time_out,
      preventDuplicates: true
    };
  };

  // add primary insurance
  add_primary_insurance = () => {
    if (this._is_mount) {
      this.setState({ add_primary_insurance_modal: !this.state.add_primary_insurance_modal });
    }
  };

  // change handler
  on_change_handle = e => {
    let value = e.target.value;
    this.setState(prevState => ({
      search_form: {
        ...prevState.search_form,
        message: value
      },
      alert_error: value.trim() ? false : true
    }));
  };
  
  on_close_primary_insurance_modal = () => {
    // setting the focus on Add New Button
    let modalButtons: any = document.querySelectorAll('#bg_fff2');
    if (modalButtons.length > 0) {
      modalButtons[modalButtons.length - 1].focus();
    }
  };

  render() {
    return (
      <React.Fragment>
        <Dimmer active={this.state.loading}>
          <Loader size='massive'>Loading</Loader>
        </Dimmer>
        {!this.state.by_pass_prompt && (
          <PromptNavigation
            is_data_changed={JSON.stringify(this.state.initial_search_form) !== JSON.stringify(this.state.search_form)}
            save={e => this.save_handler(e, true)}
            go_next_location={this.go_next}
            history={this.props.history}
          />
        )}
        <AddPrimaryInsurance
          insurance_types={this.state.insurance_types}
          insurance_contract={this.state.insurance_contract}
          set_primary_insurance_details={this.set_primary_insurance_details}
          add_primary_insurance={this.state.add_primary_insurance_modal}
          on_close_modal={this.on_close_primary_insurance_modal}
        />
        <div className={'admin-wrapper view-edit'} style={!this.state.show_grid ? { paddingBottom: 0 } : {}}>
          <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
            <Grid.Column computer={16}>
              <h3 className='ui header left aligned'>Payer Alerts</h3>
            </Grid.Column>
          </Grid>
          <div id='admin-scrollable-area' className='wrapper' style={this.state.show_grid ? { flexGrow: 1 } : {}}>
            <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container'>
              <Form id='patient-search-criteria-container' className=' ' autoComplete='off'>
                <Grid>
                  <Grid.Column mobile={8} tablet={8} computer={4}>
                    <Form.Field
                      className={
                        this.state.ins_error ? 'requiredWithBgColor advance-quick-search' : 'advance-quick-search'
                      }
                    >
                      <label>Insurance Class</label>
                      <ClaimAdjCodeSearch
                        control={this.state.control ? this.state.control.insurance_class : null}
                        update_data={this.update_data}
                        search_result={this.state.ins_class_search_data}
                        on_quick_search={this.on_quick_search}
                        on_advanced_search={this.on_advanced_search}
                        selected_row={this.state.ins_class_selected_row}
                        className={this.state.ins_error ? 'error' : ''}
                        is_focused={true}
                        headerIdForGridTabNavigation={admin_payment_const.payer_alert_search_insurance_class_id}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column mobile={8} tablet={8} computer={4}>
                    <Form.Field
                      className={
                        this.state.payer_error ? 'requiredWithBgColor advance-quick-search' : 'advance-quick-search'
                      }
                    >
                      <label>Insurance Code</label>
                      <ClaimAdjCodeSearch
                        on_quick_search={this.on_quick_search}
                        on_advanced_search={this.on_advanced_search}
                        addClicks={this.add_primary_insurance}
                        search_result={this.state.ins_code_search_data}
                        control={this.state.control ? this.state.control.insurance_code : null}
                        headerIdForGridTabNavigation={admin_payment_const.payer_alert_search_insurance_code_id}
                        update_data={this.update_data}
                        selected_row={this.state.ins_code_selected_row}
                        hide_modal={this.state.ins_code_modal}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column tablet={8} computer={4}>
                    <Form.Field>
                      <label>
                        Alert Message{' '}
                        <span
                          className={this.state.alert_error && this.is_submitted ? 'req-alert' : 'req-alert_normal'}
                        >
                          (required)
                        </span>
                      </label>
                      <TextareaAutosize
                        maxLength={200}
                        rows={1}
                        className={
                          this.state.alert_error && this.is_submitted
                            ? 'textarea-single-row req-background-textarea'
                            : 'textarea-single-row'
                        }
                        onChange={this.on_change_handle}
                        value={this.state.search_form.message}
                      />
                    </Form.Field>
                  </Grid.Column>

                  <Grid.Column mobile={8} tablet={8} computer={4} verticalAlign={'bottom'}>
                    <Form.Field className=''>
                      <label />
                      <Checkbox
                        label='Is Active'
                        checked={this.state.search_form.show_inactive}
                        onChange={() =>
                          this.setState(prev => ({
                            search_form: {
                              ...prev.search_form,
                              show_inactive: !this.state.search_form.show_inactive
                            }
                          }))
                        }
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid>
              </Form>
            </div>
          </div>
          <div
            className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
            id='applicationFooterSticky'
          >
            <Button type='reset' id='bg_fff1' onClick={this.clear_handle} basic>
              Cancel
            </Button>
            <Button id='btn_save' type='submit' onClick={e => this.save_handler(e, false)} primary disabled={this.state.is_save_button_disabled}>
              Save
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

//Get user and login details from store.
const mapStateToProps = state => {
  return {
    user_login_details: state.user_login_details
  };
};
export default connect(
  mapStateToProps,
  null
)(PayerAlertAddEdit);
