import * as api_request from '../../../shared/network/request_extensions';
import * as global_constants from '../../../global_constants';
import { generate_guid } from '../../../shared/utility';
const cloneDeep = require('lodash/clonedeep');

// Get the list of all the claim overrides of the current user company
export const get_overrides_by_company = (token: string) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.claim_overrides.claim_overrides_by_company}`;
    return api_request.get_request(url, token);
}

export const refresh_overrides_data = (token: string, inMemoryOverrides) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.claim_overrides.refresh_override_data}`;
    return api_request.post_config_request(url, token, cloneDeep({ data: { 'data': inMemoryOverrides } }));
}


// Save overrides
export const saveOverrides = (token: string, overrides: any, supress_warning:boolean) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.claim_overrides.saveClaimOverrides}`;
    let request_data = {
        overrides: overrides,
        supressWarning: supress_warning,
        rnum: generate_guid()
    };
    return api_request.post_config_request(url, token, cloneDeep({ data: { 'data': request_data } } ) );
}

// Get a claim override using associated settings
export const get_override_by_settings = (token: string, params: any) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.claim_overrides.claim_override_by_settings}`;

    return api_request.post_config_request(url, token, cloneDeep({ data: { 'data': params } } ));
}

// Get insurance codes details
export const insurance_class_ids = (token: string, params: any) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.claim_overrides.insurance_class_ids}`;

    return api_request.post_config_request(url, token, cloneDeep({ data: { 'data': params } } ));
}

export const validate_override_settingname_and_collision = (token: string, currentOverride, inMemoryOverrides) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.claim_overrides.validate_override_settingname_and_collision}`;

    let request_data = {
        currentOverride: currentOverride,
        inMemoryClaimOverrideDtos: inMemoryOverrides,
        rnum: generate_guid()
   };

    return api_request.post_config_request(url, token, cloneDeep({ data: { 'data': request_data } }));
}
