import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Dimmer, Form, Header, Loader, Button, Grid } from "semantic-ui-react";
import Selection from '../../../shared/component/selection_component';
import * as constants from '../constants';
import { user_companies } from '../../../user/action/user_action';
import { toastr } from 'react-redux-toastr';
import * as moment from 'moment';
import { get_all_error } from '../../../shared/utility';
import { add_scheduled_month_end_job } from '../action/constants_action';
import { toastr_options } from '../../../global_constants';
import { ResizableDraggableModal } from '../../../shared/resizable-draggable-modal';

export class ScheduleNewJob extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            is_edit_mode: false,
            form_submited: false,
            month_list: moment.months().map(function (val, index) {
                return { key: index+1, value: index+1, text: val };
            }),
            year_list: Array.from(Array(constants.no_of_year_for_schedule_job)).map(function (_, index) {
                return { key: index + 1, value: constants.start_year_for_schedule_job + index, text: constants.start_year_for_schedule_job+index };
            }),
            company_list: [],
            schedule_job: { month: constants.current_month_for_schedule_job, year: constants.current_year_for_schedule_job, company_id: this.props.user_login_details.user_data.data ? this.props.user_login_details.user_data.data.company_id : "" },
            is_save_button_disabled: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.createNewJob = this.createNewJob.bind(this);
        this.createCompanyListings = this.createCompanyListings.bind(this);
    }

    componentDidMount = async () => {
        let token = this.props.user_login_details.user_data.data.token_details.access_token;
        await this.props.user_companies(false, token);
        let response = this.props.user_companies_response;
        if (response && response.status == 1) {
            this.createCompanyListings(response.data);
        } else {
            const toastr_options = this.show_html_content_toaster(get_all_error(response.data));
            toastr.error('', toastr_options);
        }
    }

    createCompanyListings = (data) => {
        this.setState({
            company_list: data.map(function (val) {
                return { key: val.company_id, value: val.company_id, text: val.gpms_code + ' - ' + val.company_name };
            })
        })
    }

    handleChange = (val, value) => {
        this.setState(prevState => ({
            schedule_job: { ...prevState.schedule_job, [val]: value }
        }));
    };

    componentWillUnmount = () => {
        this.setState({
            schedule_job: { month: constants.current_month_for_schedule_job, year: constants.current_year_for_schedule_job, company_id: this.props.user_login_details.user_data.data ? this.props.user_login_details.user_data.data.company_id : "" }
        })
    }

    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }

    cancelJob = () => {
        this.props.history.push(`/admin/month_end`, { prevPath: location.pathname });
    }

    redirectToList = () => {
        this.props.history.push(`/admin/month_end`, { company_id: this.state.schedule_job.company_id, company_name: this.state.company_list.find(item => item.value.toString() === this.state.schedule_job.company_id.toString()).text });
    }

    submitForm = () => {
        let monthToClose = moment().year(this.state.schedule_job.year).month(this.state.schedule_job.month - 1).date(1);
        if (monthToClose.isSameOrAfter(moment(), 'month')) {
            this.setState({
                renderWarningModal: {
                    title: constants.warningModalForScheduleJob.title,
                    message: constants.warningModalForScheduleJob.message
                }
            });
        } else {
            this.createNewJob();
        }
    }

    createNewJob = () => {
        this.setState({
            loading: true,
            is_save_button_disabled: true,
            renderWarningModal: undefined
        });
        let token = this.props.user_login_details.user_data.data.token_details.access_token;

        var data = {
            company_id: this.state.schedule_job.company_id,
            start_date: this.state.schedule_job.year + "-" + (this.state.schedule_job.month.toString().length === 1 ? "0" : "") + this.state.schedule_job.month + "-" + "01"
        };

        add_scheduled_month_end_job(token, data).then(resp => {
            if (resp.data.status == 1) {
                toastr.success('', "Job Added Successfully");
                this.redirectToList();
            } else {
                const toastr_options = this.show_html_content_toaster(get_all_error(resp.data));
                toastr.error('', toastr_options);
            }
        }, (error) => {
            const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
            toastr.error('', toastr_options);
        })
        this.setState({
            loading: false,
            is_save_button_disabled: false
        });
    }

    renderWarningModal = () => {
        let { title, message } = this.state.renderWarningModal;
        return (
            <ResizableDraggableModal
                showModal={this.state.renderWarningModal}
                dimmer={true}
                boundWith={"window"}
                dragHandleClassName={"daragble-header"}
                autoResizeOnload={false}
                popUpPosition={{
                    width: 450,
                    height: 217,
                    minWidth: 450,
                    minHeight: 217,
                    maxWidth: 450,
                    maxHeight: 217
                }}
                enable={{
                    bottomRight: true
                }}
                className={'modal_container'}
                disableDragging={true}
            >
                <ResizableDraggableModal.Header
                    onRequestClose={() => this.setState({ renderWarningModal: undefined })}
                >{title}</ResizableDraggableModal.Header>
                <ResizableDraggableModal.Content className={"daragble-content scrolling"}>
                    <ResizableDraggableModal.Description className={"daragble-description descriptionWarningModal"}>
                        {message}
                    </ResizableDraggableModal.Description>
                </ResizableDraggableModal.Content>
                <ResizableDraggableModal.Footer className='footerWarningModal'>
                    <Button
                        type="button"
                        id="cancelButtonModal"
                        basic
                        onClick={() => this.setState({ renderWarningModal: undefined })}
                        content={"Cancel"}
                    />
                    <Button
                        id="saveButtonModal"
                        className="primary"
                        type="button"
                        content={"Continue"}
                        onClick={this.createNewJob}
                    />
                </ResizableDraggableModal.Footer>
            </ResizableDraggableModal>
        );
    }

    render() {
        const { month_list, schedule_job, year_list, company_list } = this.state

        return (
            <React.Fragment>
                <Dimmer active={this.state.loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <div className='common-forms-add  common-forms-add-flex'>
                    <Header as='h3' dividing textAlign='left' id='applicationPageTitleSticky'>
                        Schedule Month End
                    </Header>
                    <div className={this.state.is_edit_mode ? 'auto-height-patient-scroll' : 'auto-height-scroll'} id='scrollable-content-area'>
                        <Form className='patient-add-form' autoComplete='off'>
                            <Grid>
                                <Grid.Column className='mar-b-20' mobile={16} tablet={16} computer={16}>
                                    <Grid className='mar-t-15'>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Company</label>
                                                <Selection
                                                    placeHolder='Select'
                                                    name='schedule_job_company'
                                                    id='schedule_job_company'
                                                    options={company_list}
                                                    onChange={(value) => this.handleChange('company_id', value)}
                                                    defaultValue={schedule_job.company_id}
                                                    disabled={false}
                                                    hidden={true}
                                                    style={this.state.form_submited ? 'red-error-thin' : ''}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Month</label>
                                                <Selection
                                                    placeHolder='Select'
                                                    name='schedule_job_month'
                                                    id='schedule_job_month'
                                                    options={month_list}
                                                    onChange={(value) => this.handleChange('month', value)}
                                                    defaultValue={schedule_job.month}
                                                    disabled={false}
                                                    hidden={true}
                                                    style={this.state.form_submited ? 'red-error-thin' : ''}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Year</label>
                                                <Selection
                                                    placeHolder='Select'
                                                    name='schedule_job_year'
                                                    id='schedule_job_year'
                                                    options={year_list}
                                                    onChange={(value) => this.handleChange('year', value)}
                                                    defaultValue={schedule_job.year}
                                                    disabled={false}
                                                    hidden={true}
                                                    style={this.state.form_submited ? 'red-error-thin' : ''}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                            </Grid>
                            <br />
                        </Form>
                    </div>
                    <div className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area' id='applicationFooterSticky'>
                        <Button id='create_new_schedule_job_cancel_btn' basic onClick={this.cancelJob}>
                            Cancel
                        </Button>
                        <Button id='create_new_schedule_job_btn' type='submit' disabled={this.state.is_save_button_disabled} primary onClick={this.submitForm}>
                            Save
                        </Button>
                    </div>
                    {this.state.renderWarningModal && this.renderWarningModal()}
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        user_companies: user_companies
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        user_companies_response: state.user_details.user_companies_response,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScheduleNewJob));