import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-scroll';
import { bindActionCreators } from 'redux';
import { Button, Checkbox, Dimmer, Form, Grid, Header, Loader } from 'semantic-ui-react';
import { date_format, url_rewrite, maximum_value } from '../../global_constants';
import * as session_storage from '../../shared/session_storage_utility';
import { get_patient_details } from '../action/patient_action';
import { update_collection_flag } from '../action/patient_header_action';
import { address_format, currency_formatter, custom_date_format, fixSSN, format_gender, format_marital_status, format_patient_name, format_spoken_language, phone_format, phone_primary, reminder_type } from './../../shared/utility';
import AccountNotesComponent from './account_notes_view_component';
import CasesComponent from './cases_component';
import FinancialComponent from './financial_component';
import MedicalRecordComponent from './medical_record_component';
import MedicareCapComponent from './medicare_cap_component';
import PrintPatientFormsComponent from './print_patient_forms_component';
import StatementAndPaymentsComponent from './statement_and_payments_component';
import { enum_primary_phone } from './../patient_constants';
import { handle_click_on_enter, set_focus_to_app_header, set_focus_on_element_with_id } from './../../shared/tab_navigation_utility';
import { set_active_patient } from '../../login/action/login_action';

// View Patient Details
export class ViewPatientComponent extends React.Component<any, any> {
    [x: string]: any;
    _is_mounted = false;
    constructor(props) {

        super(props);
        // Initialize state property here
        this.state = {
            loading: true,
            master_data: this.props.user_login_details.master_data.data,
            patient_id: props.match.params.patient_id,
            patient_data: {

                full_name: '',
                preferred_name: '',
                address: '',
                phone_home: '',
                phone_mobile: '',
                phone_work: '',
                phone_primary: '',
                email: '',
                reminder_type: '',
                date_of_birth: '',
                gender: '',
                ssn: '',
                marital_status: '',
                drivers_license: '',
                spoken_language: '',
                comment: '',
                alternate_patient_ID: '',
                requested_provider:'',
                emergency_full_name: '',
                emergency_phone_home: '',
                emergency_phone_mobile: '',
                emergency_phone_work: '',

                responsibile_party_same_as_patient: false,
                responsibile_party_full_name: '',
                responsibile_party_address: '',
                responsibile_party_email: '',
                responsibile_party_phone: '',
                responsibile_party_date_of_birth: '',
                responsibile_party_gender: '',
                responsibile_party_ssn: '',
                responsibile_party_marital_status: '',
                responsibile_party_drivers_license: '',
                responsibile_party_health_saving_account: '',

                billing_information_is_patient_statement: false,
                //financial information
                total_ansurance_adj_amount: '',
                total_insurance_balance: '',
                total_insurance_pay_amount: '',
                total_patient_adj_amount: '',
                total_patient_balance: '',
                total_patient_pay_amount: '',
                total_insurance_charge_amount: '',
                total_patient_charge_amount: '',
                total_charge_amount: '',
                total_payment_amount: '',
                total_adj_amount: '',
                total_balance: '',
                account_summary_notes_data: []
            },
            show_account_notes: false,
            show_print_patient_form: true,
            show_medical_record: false,
            show_medicare: true,
            statement_shown: true,
            show_medical: true,
            show_print: true,
            show_cases: true,
            financial_shown: true
        };
        this.account_note_child = React.createRef();
    }
    scroll_height = 0;
    // Error Handling in Component. 
    componentDidCatch(error, info) {
        if (this._is_mounted) {
            this.setState({
                loading: false
            });
        }
    }

    UNSAFE_componentWillMount() {
        this._is_mounted = true;
        this.get_patient_details(this.state.patient_id)
    }

    componentDidMount() {
        this._is_mounted = true;
        // setting the focus on edit button for the first time, when component mounts.
        set_focus_on_element_with_id("view_patient_edit_button");
        document.body.classList.add('patient-view');
    }
    componentWillUnmount() {
        this._is_mounted = false;
        document.body.classList.remove('patient-view');
    }
    componentDidUpdate(previousProps, previousState) {
        // To check if the View Patient is refreshed
        if (this.props.match.params.patient_id && 
            session_storage.get('active_patient') != this.props.match.params.patient_id
            ) {
            session_storage.set('active_patient', this.props.match.params.patient_id);
            this.set_active_patient_to_store(this.props.match.params.patient_id);
        }
        if (previousProps.match.params.patient_id != this.props.match.params.patient_id) {
            if (this._is_mounted) {
                this.setState({
                    show_account_notes: false,
                    show_print_patient_form: true,
                    show_medical_record: false,
                    patient_id: this.props.match.params.patient_id,
                    patient_data: {
                        id: 0,
                        full_name: '',
                        preferred_name: '',
                        address: '',
                        phone_home: '',
                        phone_mobile: '',
                        phone_work: '',
                        phone_primary: '',
                        email: '',
                        reminder_type: '',
                        date_of_birth: '',
                        gender: '',
                        ssn: '',
                        marital_status: '',
                        drivers_license: '',
                        spoken_language: '',
                        comment: '',
                        alternate_patient_ID: '',
                        requested_provider: '',
                        emergency_full_name: '',
                        emergency_phone_home: '',
                        emergency_phone_mobile: '',
                        emergency_phone_work: '',

                        responsibile_party_same_as_patient: false,
                        responsibile_party_full_name: '',
                        responsibile_party_address: '',
                        responsibile_party_email: '',
                        responsibile_party_phone: '',
                        responsibile_party_date_of_birth: '',
                        responsibile_party_gender: '',
                        responsibile_party_ssn: '',
                        responsibile_party_marital_status: '',
                        responsibile_party_drivers_license: '',
                        responsibile_party_health_saving_account: '',

                        billing_information_is_patient_statement: false,
                        is_patient_active: false,
                        //financial information
                        total_ansurance_adj_amount: '',
                        total_insurance_balance: '',
                        total_insurance_pay_amount: '',
                        total_patient_adj_amount: '',
                        total_patient_balance: '',
                        total_patient_pay_amount: '',
                        total_insurance_charge_amount: '',
                        total_patient_charge_amount: '',
                        total_charge_amount: '',
                        total_payment_amount: '',
                        total_adj_amount: '',
                        total_balance: '',
                        account_summary_notes_data: [],

                    }
                });
            }
            this.get_patient_details(this.props.match.params.patient_id)
        }
    }

    // To check patient's and responsible party is same
    is_responsibile_party_same_as_patient(patient_address, responsible_party_address, patient_full_name, responsible_party_full_name): any {

        if ((patient_full_name == responsible_party_full_name) &&
            patient_address == responsible_party_address &&
            this.props.patient_details.selected_patient.data.email == this.props.patient_details.selected_patient.data.responsible_party.email &&
            this.props.patient_details.selected_patient.data.date_of_birth == this.props.patient_details.selected_patient.data.responsible_party.date_of_birth &&
            this.props.patient_details.selected_patient.data.gender == this.props.patient_details.selected_patient.data.responsible_party.gender &&
            this.props.patient_details.selected_patient.data.ssn == this.props.patient_details.selected_patient.data.responsible_party.ssn &&
            this.props.patient_details.selected_patient.data.marital_status == this.props.patient_details.selected_patient.data.responsible_party.marital_status &&
            this.props.patient_details.selected_patient.data.driver_license == this.props.patient_details.selected_patient.data.responsible_party.driver_license &&
            (this.props.patient_details.selected_patient.data.phone.primary == enum_primary_phone.home ?
                this.props.patient_details.selected_patient.data.responsible_party.phone.cell == this.props.patient_details.selected_patient.data.phone.home
                : this.props.patient_details.selected_patient.data.phone.primary == enum_primary_phone.work ?
                    this.props.patient_details.selected_patient.data.responsible_party.phone.cell == this.props.patient_details.selected_patient.data.phone.work
                    : this.props.patient_details.selected_patient.data.responsible_party.phone.cell == this.props.patient_details.selected_patient.data.phone.cell)) {
            return true
        }

        return false
    }

    set_active_patient_to_store = (patient_id) => {
        this.props.set_active_patient(patient_id)

    }

    // Get Patient details
    get_patient_details = async (patient_id) => {
        if (isNaN(Number(patient_id)) || Number(patient_id) >= maximum_value.integer) {
            toastr.error('', url_rewrite.error_message);
            if (this._is_mounted) {
                this.setState({
                    loading: false
                });
            }
            session_storage.set('active_patient', '');
            this.set_active_patient_to_store('');
            this.props.history.push(`${url_rewrite.navigate_to}`);
        }
        else {
            if (session_storage.get('active_patient') == '') {
                session_storage.set('active_patient', patient_id);
                this.set_active_patient_to_store(patient_id);

            }
            if (this._is_mounted) {
                this.setState({
                    finantial_loading: null,
                    loading: true
                });
            }
            await this.props.get_patient_details(this.props.user_login_details.user_data.data.token_details.access_token, patient_id)

            if (this.props.patient_details.selected_patient.data && this.props.patient_details.selected_patient.status != 0) {

                await this.props.update_collection_flag(this.props.patient_details.selected_patient.data.collection_flag);

                var patient_address = address_format(this.props.patient_details.selected_patient.data.address, true, this.state.master_data);
                var patient_full_name = `${this.props.patient_details.selected_patient.data.suffix ? this.props.patient_details.selected_patient.data.suffix : ''} ${format_patient_name(this.props.patient_details.selected_patient.data.name)}`;
                var responsible_party_full_name = `${this.props.patient_details.selected_patient.data.responsible_party.suffix ? this.props.patient_details.selected_patient.data.responsible_party.suffix : ''} ${format_patient_name(this.props.patient_details.selected_patient.data.responsible_party.name)}`;
                var responsible_party_address = address_format(this.props.patient_details.selected_patient.data.responsible_party.address, true, this.state.master_data);

                //Set financial Data
                var total_insurance_pay_amount = this.props.patient_details.selected_patient.data.total_insurance_pay_amount == null || this.props.patient_details.selected_patient.data.total_insurance_pay_amount == '' ? 0 : this.props.patient_details.selected_patient.data.total_insurance_pay_amount;
                var total_insurance_adjAmount = this.props.patient_details.selected_patient.data.total_ansurance_adj_amount == null || this.props.patient_details.selected_patient.data.total_ansurance_adj_amount == '' ? 0 : this.props.patient_details.selected_patient.data.total_ansurance_adj_amount;
                var total_insurance_balance = this.props.patient_details.selected_patient.data.total_insurance_balance == null || this.props.patient_details.selected_patient.data.total_insurance_balance == '' ? 0 : this.props.patient_details.selected_patient.data.total_insurance_balance;
                var total_patient_pay_amount = this.props.patient_details.selected_patient.data.total_patient_pay_amount == null || this.props.patient_details.selected_patient.data.total_patient_pay_amount == '' ? 0 : this.props.patient_details.selected_patient.data.total_patient_pay_amount;
                var total_patient_adj_amount = this.props.patient_details.selected_patient.data.total_patient_adj_amount == null || this.props.patient_details.selected_patient.data.total_patient_adj_amount == '' ? 0 : this.props.patient_details.selected_patient.data.total_patient_adj_amount;
                var total_patient_balance = this.props.patient_details.selected_patient.data.total_patient_balance == null || this.props.patient_details.selected_patient.data.total_patient_balance == '' ? 0 : this.props.patient_details.selected_patient.data.total_patient_balance;
                var total_insurance_charge_amount = this.props.patient_details.selected_patient.data.total_insurance_pay_amount + this.props.patient_details.selected_patient.data.total_ansurance_adj_amount + this.props.patient_details.selected_patient.data.total_insurance_balance;
                var total_patient_charge_amount = this.props.patient_details.selected_patient.data.total_patient_pay_amount + this.props.patient_details.selected_patient.data.total_patient_adj_amount + this.props.patient_details.selected_patient.data.total_patient_balance;
                var total_charge_amount = (total_insurance_charge_amount) + (total_patient_charge_amount);
                var total_payment_amount = this.props.patient_details.selected_patient.data.total_insurance_pay_amount + this.props.patient_details.selected_patient.data.total_patient_pay_amount;
                var total_adj_amount = this.props.patient_details.selected_patient.data.total_ansurance_adj_amount + this.props.patient_details.selected_patient.data.total_patient_adj_amount;
                var total_balance = this.props.patient_details.selected_patient.data.total_insurance_balance + this.props.patient_details.selected_patient.data.total_patient_balance;
                if (this._is_mounted) {
                this.setState({
                    loading: false,
                    finantial_loading: 'loaded',
                    patient_data: {
                        id: this.props.patient_details.selected_patient.data.id,
                        full_name: patient_full_name,
                        preferred_name: this.props.patient_details.selected_patient.data.name.preferred_name,
                        address: patient_address,
                        phone_home: phone_format(this.props.patient_details.selected_patient.data.phone.home),
                        phone_mobile: phone_format(this.props.patient_details.selected_patient.data.phone.cell),
                        phone_work: phone_format(this.props.patient_details.selected_patient.data.phone.work),
                        phone_primary: phone_primary(this.props.patient_details.selected_patient.data.phone.primary, this.state.master_data.primary_phone),
                        email: this.props.patient_details.selected_patient.data.email,
                        reminder_type: reminder_type(this.props.patient_details.selected_patient.data.reminder_type, this.state.master_data.reminder_type),
                        date_of_birth: this.props.patient_details.selected_patient.data.date_of_birth ? custom_date_format(this.props.patient_details.selected_patient.data.date_of_birth, date_format['mm/dd/yyyy']) : '',
                        gender: format_gender(this.props.patient_details.selected_patient.data.gender, this.state.master_data.gender),
                        ssn: fixSSN(this.props.patient_details.selected_patient.data.ssn),
                        marital_status: format_marital_status(this.props.patient_details.selected_patient.data.marital_status, this.state.master_data.marital_status),
                        drivers_license: this.props.patient_details.selected_patient.data.driver_license,
                        spoken_language: format_spoken_language(this.props.patient_details.selected_patient.data.spoken_language, this.state.master_data.languages),
                        comment: this.props.patient_details.selected_patient.data.patient_notes,
                        alternate_patient_ID: this.props.patient_details.selected_patient.data.alt_record_id,
                        requested_provider: this.props.patient_details.selected_patient.data.provider_desc,

                        emergency_full_name: format_patient_name(this.props.patient_details.selected_patient.data.emergency_contact.name),
                        emergency_phone_home: phone_format(this.props.patient_details.selected_patient.data.emergency_contact.phone.home),
                        emergency_phone_mobile: phone_format(this.props.patient_details.selected_patient.data.emergency_contact.phone.cell),
                        emergency_phone_work: phone_format(this.props.patient_details.selected_patient.data.emergency_contact.phone.work),

                        responsibile_party_same_as_patient: this.is_responsibile_party_same_as_patient(patient_address, responsible_party_address, patient_full_name, responsible_party_full_name),
                        responsibile_party_full_name: responsible_party_full_name,
                        responsibile_party_address: responsible_party_address,
                        responsibile_party_email: this.props.patient_details.selected_patient.data.responsible_party.email,
                        responsibile_party_phone: phone_format(this.props.patient_details.selected_patient.data.responsible_party.phone.cell),
                        responsibile_party_date_of_birth: this.props.patient_details.selected_patient.data.responsible_party.date_of_birth ? custom_date_format(this.props.patient_details.selected_patient.data.responsible_party.date_of_birth, date_format['mm/dd/yyyy']) : '',
                        responsibile_party_gender: format_gender(this.props.patient_details.selected_patient.data.responsible_party.gender, this.state.master_data.gender),
                        responsibile_party_ssn: fixSSN(this.props.patient_details.selected_patient.data.responsible_party.ssn),
                        responsibile_party_marital_status: format_marital_status(this.props.patient_details.selected_patient.data.responsible_party.marital_status, this.state.master_data.marital_status),
                        responsibile_party_drivers_license: this.props.patient_details.selected_patient.data.responsible_party.driver_license,
                        responsibile_party_health_saving_account: currency_formatter(this.props.patient_details.selected_patient.data.responsible_party.hsa),

                        billing_information_is_patient_statement: this.props.patient_details.selected_patient.data.is_patient_statement ? false : true,
                        is_patient_active: this.props.patient_details.selected_patient.data.is_active,

                        //Set financial Data
                        total_insurance_pay_amount: total_insurance_pay_amount,
                        total_insurance_adjAmount: total_insurance_adjAmount,
                        total_insurance_balance: total_insurance_balance,
                        total_patient_pay_amount: total_patient_pay_amount,
                        total_patient_adj_amount: total_patient_adj_amount,
                        total_patient_balance: total_patient_balance,
                        total_insurance_charge_amount: total_insurance_charge_amount,
                        total_patient_charge_amount: total_patient_charge_amount,
                        total_charge_amount: total_charge_amount,
                        total_payment_amount: total_payment_amount,
                        total_adj_amount: total_adj_amount,
                        total_balance: total_balance,

                        row_total: { name: "Total", charge_amount: currency_formatter(total_charge_amount), payment_amount: currency_formatter(total_payment_amount), adjustment_amount: currency_formatter(total_adj_amount), balance: currency_formatter(total_balance) },
                        account_summary_notes_data: [
                            {
                                name: "Insurance", charge_amount: currency_formatter(total_insurance_charge_amount), payment_amount: currency_formatter(total_insurance_pay_amount), adjustment_amount: currency_formatter(total_insurance_adjAmount), balance: currency_formatter(total_insurance_balance)
                            },
                            {
                                name: "Patient", charge_amount: currency_formatter(total_patient_charge_amount), payment_amount: currency_formatter(total_patient_pay_amount), adjustment_amount: currency_formatter(total_patient_adj_amount), balance: currency_formatter(total_patient_balance)
                            }
                        ]
                    }
                });
                 }
            }
            else {
                toastr.error('', this.props.patient_details.selected_patient.messages[0].message);
                if (this._is_mounted) {
                    this.setState({
                        loading: false
                    });
                }
                session_storage.set('active_patient', ''); 
                this.set_active_patient_to_store('');

                this.props.history.push(`${url_rewrite.navigate_to}`);
            }
        }
    }


    on_edit_click(): any {
        this.props.history.push(`/edit_patient/${this.state.patient_id}`);
        //this.props.history.push(`/coming_soon`);
    }

    // toggle to show and hide Account Notes Component
    private toggle_account_notes = () => {
        if (this._is_mounted) {
            this.setState({
                show_account_notes: !this.state.show_account_notes
            });
        }
    }

    // toggle to show and hide Print Patient Form Component
    private toggle_print_patient_form = () => {
        if (this._is_mounted) {
            this.setState({
                show_print_patient_form: !this.state.show_print_patient_form
            });
        }
    }

    // toggle to show and hide Medical Record
    private toggle_medical_record = () => {
        if (this._is_mounted) {
            this.setState({
                show_medical_record: !this.state.show_medical_record
            });
        }
    }

    update_patient_flag = async (collection_flag, update_collection_flag) => {
        this.get_patient_details(this.state.patient_id);
        if (this.account_note_child && this.account_note_child.get_account_details) {
            this.account_note_child.get_account_details(this.state.patient_id);
        }
        if (update_collection_flag) {
            await this.props.update_collection_flag(collection_flag);
        }
    }

    // set current scrollTop value
    manageScrollHeight(container, element, type) {
        this.scroll_height = element.offsetTop;
        // var maxScrollPosition = container.scrollHeight - container.clientHeight;
    }

    // toggle to show and hide Print financial Component
    private toggle_financial_shown = (flag) => {
        if (this._is_mounted) {
            this.setState({
                financial_shown: flag
            });
        }
    }

    // scroll handle to open relative tab and set scrollTop
    scrollHandle = (event, element, show) => {
        let scrollContainer = document.getElementById('scrollable-content-area');
        var myElement = document.getElementById(element);
        this.manageScrollHeight(scrollContainer, myElement, element)
        if (this._is_mounted) {
            if (show == 'show_medicare') {
                this.setState({ show_medicare: !this.state.show_medicare }, this.set_scroll_height);
            } else if (show == 'show_account_notes') {
                this.setState({ show_account_notes: true }, this.set_scroll_height);
            } else if (show == 'statement_shown') {
                this.setState({ statement_shown: !this.state.statement_shown }, this.set_scroll_height);
            } else if (show == 'show_print') {
                this.setState({ show_print_patient_form: false }, this.set_scroll_height);
            } else if (show == 'show_medical') {
                this.setState({ show_medical_record: true }, this.set_scroll_height);
            } else if (show == 'show_cases') {
                this.setState({ show_cases: !this.state.show_cases }, this.set_scroll_height);
            } else if (show == 'financial') {
                this.setState({ financial_shown: false }, this.set_scroll_height);
            }
        }
    }

    // set scroll top value of each tab
    set_scroll_height = () => {
        document.getElementById('scrollable-content-area').scrollTop = this.scroll_height;
    }

    render() {
        return (
            <React.Fragment>
                <Dimmer active={this.state.loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <div onKeyDown={handle_click_on_enter} className="sixteen wide computer sixteen wide mobile sixteen wide tablet column patient-menu" id="applicationPageTitleSticky">
                    <Link tabIndex={0} onClick={(e) => this.scrollHandle(e, 'view_info', 'view_info')} activeClass="active" to="#view_info" > <h3 className="ui header">View Patient</h3></Link>
                    <ul className="subheader-menu">
                        <li><Link to="#medicare" tabIndex={0} onClick={(e) => this.scrollHandle(e, 'medicare', 'show_medicare')} activeClass="active" smooth={true} containerId="scrollContainer">
                            Medicare Cap
                           </Link>
                        </li>
                        <li>
                            <Link to="#financial" tabIndex={0} onClick={(e) => this.scrollHandle(e, 'financial', 'financial')} containerId="scrollContainer">Financial</Link>
                        </li>
                        <li>
                            <Link to="#statement_payment" tabIndex={0} onClick={(e) => this.scrollHandle(e, 'statement_payment_tab', 'statement_shown')} containerId="scrollContainer">Statement and Payments</Link>
                        </li>
                        <li>
                            <Link to="#cases" smooth={true} tabIndex={0} onClick={(e) => this.scrollHandle(e, 'cases', 'show_cases')} containerId="scrollContainer">Cases</Link>
                        </li>
                        <li>
                            <Link to="#account_notes" smooth={true} tabIndex={0} onClick={(e) => this.scrollHandle(e, 'account_notes', 'show_account_notes')} containerId="scrollContainer">Account Notes</Link>
                        </li>
                        <li>
                            <Link to="#medical_record_tab" smooth={true} tabIndex={0} onClick={(e) => this.scrollHandle(e, 'medical_record_tab', 'show_medical')} containerId="scrollContainer">Medical Record</Link>
                        </li>
                        <li>
                            <Link to="#print_tab" smooth={true} tabIndex={0} onClick={(e) => this.scrollHandle(e, 'print_tab', 'show_print')} containerId="scrollContainer">Print Patient Forms</Link>
                        </li>
                    </ul>
                </div>
                <div className="auto-height-patient-scroll" id="scrollable-content-area">
                    <Form className="patient-view-form ">
                        <Grid id="view_info">
                            <Grid.Column className="mar-b-20" mobile={16} tablet={16} computer={8}>
                                <Header as='h3' dividing>
                                    Patient Information
                                </Header>
                                <Grid>
                                    <Grid.Column mobile={8} tablet={8} computer={10}>
                                        <div>
                                            <label>Name</label>
                                            <div className="values">{this.state.patient_data.full_name}</div>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={8} computer={6}>
                                        <div>
                                            <label>Preferred Name</label>
                                            <div className="values">{this.state.patient_data.preferred_name}</div>
                                        </div>
                                    </Grid.Column>
                                </Grid>
                                <Grid>
                                    <Grid.Column>
                                        <div>
                                            <label>Address</label>
                                            <div className="values">{this.state.patient_data.address}</div>
                                        </div>
                                    </Grid.Column>
                                </Grid>
                                <Header as='h4' className="small">
                                    Phone
                                </Header>
                                <Grid>
                                    <Grid.Column mobile={8} tablet={8} computer={4}>
                                        <div>
                                            <label>Home</label>
                                            <div className="values">{this.state.patient_data.phone_home}</div>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={8} computer={4}>
                                        <div>
                                            <label>Cell</label>
                                            <div className="values">{this.state.patient_data.phone_mobile}</div>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={8} computer={4}>
                                        <div>
                                            <label>Work</label>
                                            <div className="values">{this.state.patient_data.phone_work}</div>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={8} computer={4}>
                                        <div>
                                            <label>Primary Phone</label>
                                            <div className="values">{this.state.patient_data.phone_primary}</div>
                                        </div>
                                    </Grid.Column>
                                </Grid>
                                <Header as='h4' className="small">
                                    Other Contact Information
                                </Header>
                                <Grid>
                                    <Grid.Column mobile={8} tablet={8} computer={12}>
                                        <div>
                                            <label>Email</label>
                                            <div className="values breakContent">{this.state.patient_data.email}</div>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={8} computer={4}>
                                        <div>
                                            <label>Reminder Type</label>
                                            <div className="values">{this.state.patient_data.reminder_type}</div>
                                        </div>
                                    </Grid.Column>
                                </Grid>
                                <Header as='h4' className="small">
                                    Other Patient Information
                                </Header>
                                <Grid>
                                    <Grid.Column mobile={8} tablet={8} computer={4}>
                                        <div>
                                            <label>Date of Birth</label>
                                            <div className="values">{this.state.patient_data.date_of_birth}</div>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={8} computer={4}>
                                        <div>
                                            <label>Gender</label>
                                            <div className="values">{this.state.patient_data.gender}</div>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={8} computer={4}>
                                        <div>
                                            <label>SSN</label>
                                            <div className="values">{this.state.patient_data.ssn}</div>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={8} computer={4}>
                                        <div>
                                            <label>Marital Status</label>
                                            <div className="values">{this.state.patient_data.marital_status}</div>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={8} computer={4}>
                                        <div>
                                            <label>Driver's License</label>
                                            <div className="values breakContent">{this.state.patient_data.drivers_license}</div>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={8} computer={4}>
                                        <div>
                                            <label>Spoken Language</label>
                                            <div className="values">{this.state.patient_data.spoken_language}</div>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={8} computer={8}>
                                        <div>
                                            <label>Requested Provider</label>
                                            <div className="values ">{this.state.patient_data.requested_provider}</div>
                                        </div>
                                    </Grid.Column>
                                    
                                </Grid>
                                <Header as='h4' className="small">
                                    Internal Information
                                </Header>
                                <Grid>
                                    <Grid.Column mobile={8} tablet={8} computer={8}>
                                        <div>
                                            <label>Comment</label>
                                            <div className="values breakContent">{this.state.patient_data.comment}</div>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={8} computer={8}>
                                        <div>
                                            <label>Alternate Patient ID</label>
                                            <div className="values breakContent">{this.state.patient_data.alternate_patient_ID}</div>
                                        </div>
                                    </Grid.Column>
                                </Grid>
                            </Grid.Column>
                            <Grid.Column className="mar-b-20" mobile={16} tablet={16} computer={8}>
                                <Header as='h3' dividing>
                                    Emergency Contact
                                </Header>
                                <Grid>
                                    <Grid.Column mobile={8} tablet={8} computer={16}>
                                        <div>
                                            <label>Name</label>
                                            <div className="values">{this.state.patient_data.emergency_full_name}</div>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={8} computer={4}>
                                        <div>
                                            <label>Home</label>
                                            <div className="values">{this.state.patient_data.emergency_phone_home}</div>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={8} computer={4}>
                                        <div>
                                            <label>Cell</label>
                                            <div className="values">{this.state.patient_data.emergency_phone_mobile}</div>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={8} computer={4}>
                                        <div>
                                            <label>Work</label>
                                            <div className="values">{this.state.patient_data.emergency_phone_work}</div>
                                        </div>
                                    </Grid.Column>
                                </Grid>
                                <Header as='h3' dividing>
                                    Responsible Party
                                </Header>
                                <Form.Field>
                                    <Checkbox id='responsibile_party_same_as_patient' label='Same as Patient' className="dark_checkbox_label"
                                        checked={this.state.patient_data.responsibile_party_same_as_patient} readOnly disabled />
                                </Form.Field>
                                <Grid>
                                    <Grid.Column>
                                        <div>
                                            <label>Name</label>
                                            <div className="values">{this.state.patient_data.responsibile_party_full_name}</div>
                                        </div>
                                    </Grid.Column>
                                </Grid>
                                <Grid>
                                    <Grid.Column>
                                        <div>
                                            <label>Address</label>
                                            <div className="values">{this.state.patient_data.responsibile_party_address}</div>
                                        </div>
                                    </Grid.Column>
                                </Grid>
                                <Grid>
                                    <Grid.Column mobile={8} tablet={8} computer={12}>
                                        <div>
                                            <label>Email</label>
                                            <div className="values breakContent">{this.state.patient_data.responsibile_party_email}</div>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={8} computer={4}>
                                        <div>
                                            <label>Phone</label>
                                            <div className="values">{this.state.patient_data.responsibile_party_phone}</div>
                                        </div>
                                    </Grid.Column>
                                </Grid>
                                <Grid>
                                    <Grid.Column mobile={8} tablet={8} computer={4}>
                                        <div>
                                            <label>Date of Birth</label>
                                            <div className="values">{this.state.patient_data.responsibile_party_date_of_birth}</div>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={8} computer={4}>
                                        <div>
                                            <label>Gender</label>
                                            <div className="values">{this.state.patient_data.responsibile_party_gender}</div>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={8} computer={4}>
                                        <div>
                                            <label>SSN</label>
                                            <div className="values">{this.state.patient_data.responsibile_party_ssn}</div>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={8} computer={4}>
                                        <div>
                                            <label>Marital Status</label>
                                            <div className="values">{this.state.patient_data.responsibile_party_marital_status}</div>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={8} computer={12}>
                                        <div>
                                            <label>Driver's License</label>
                                            <div className="values breakContent">{this.state.patient_data.responsibile_party_drivers_license}</div>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={8} computer={4}>
                                        <div>
                                            <label>Health Savings Account</label>
                                            <div className="values">{this.state.patient_data.responsibile_party_health_saving_account}</div>
                                        </div>
                                    </Grid.Column>
                                </Grid>
                                <Header as='h3' dividing>
                                    Billing Information
                                </Header>
                                <Form.Field>
                                    <Checkbox id='billing_information_is_patient_statement' label='Do not send batch statement to patient' className="dark_checkbox_label"
                                        checked={this.state.patient_data.billing_information_is_patient_statement} readOnly disabled />
                                </Form.Field>
                            </Grid.Column>
                        </Grid>

                        <div className="common-forms-view">
                            <Grid>
                                <Grid.Column>
                                    <p className="instructinfo">Fields marked with <strong className={'font_bold'}>"required"</strong> must be completed before you can save. Fields marked with <strong className={'font_bold'}>"required for billing"</strong> do not need to be completed in order to save. However, you cannot bill charges for this patient until these fields are completed.</p>
                                </Grid.Column>
                            </Grid>
                        </div>

                        <div style={{ padding: '20px 0' }} className="common-forms-view">
                            <Grid>
                                <Grid.Column>
                                    <Form.Field>
                                        <Checkbox id='patient_active' label='Patient Active' className='dark_checkbox_label'
                                            checked={this.state.patient_data.is_patient_active} readOnly disabled />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid>
                        </div>

                        <MedicareCapComponent shown={this.state.show_medicare} is_view={true} patient_id={this.state.patient_id} />

                        {(this.props.patient_details.selected_patient && this.props.patient_details.selected_patient.status != 0
                            && this.props.patient_details.selected_patient) &&
                            <FinancialComponent parent_props={this.props}
                                update_flag={this.update_patient_flag}
                                loading={this.state.finantial_loading}
                                shown={this.state.financial_shown}
                                toggle={this.toggle_financial_shown}
                                financial_data={this.state.patient_data.account_summary_notes_data}
                                rowTotal={this.state.patient_data.row_total}
                            />
                        }

                        <StatementAndPaymentsComponent shown={this.state.statement_shown} patient_id={this.state.patient_id} company_id={this.props.user_login_details.user_data.data.company_id} parent_props={this.props} />

                        <CasesComponent shown={this.state.show_cases} />

                        <div className="common-forms-view" id="account_notes">
                            <div className="ui grid">
                                <div className="sixteen wide computer column">
                                    <div className="accordion ui fluid styled common-accordion">
                                        <div className="active title" onClick={this.toggle_account_notes.bind(this)} >Account Notes<i tabIndex={0} onKeyDown={handle_click_on_enter} aria-hidden="true"
                                            className={this.state.show_account_notes ? "ellipsis angle up icon" : "ellipsis angle down icon"}></i></div>
                                        {this.state.show_account_notes &&
                                            <AccountNotesComponent
                                                patient_data={this.state.patient_data}
                                                is_patient_header={false}
                                                show_account_notes={this.state.show_account_notes}
                                                get_account_details={this.update_patient_flag}
                                                onRef={instance => { this.account_note_child = instance; }}
                                                data_model_key={'patient_view_account_notes'}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="common-forms-view" id="medical_record_tab">
                            <div className="ui grid">
                                <div className="sixteen wide computer column">
                                    <div className="accordion ui fluid styled common-accordion">
                                        <div className="active title" onClick={this.toggle_medical_record.bind(this)} >Medical Record<i tabIndex={0} onKeyDown={handle_click_on_enter} aria-hidden="true"
                                            className={this.state.show_medical_record ? "ellipsis angle up icon" : "ellipsis angle down icon"}></i></div>
                                        {this.state.show_medical_record ? <MedicalRecordComponent shown={this.state.show_medical_record} /> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="common-forms-view" id="print_tab">
                            <div className="ui grid">
                                <div className="sixteen wide computer column">
                                    <div className="accordion ui fluid styled common-accordion">
                                        <div className="active title" onClick={this.toggle_print_patient_form.bind(this)} >Print Patient Forms<i tabIndex={0} onKeyDown={handle_click_on_enter} aria-hidden="true"
                                            className={!this.state.show_print_patient_form ? "ellipsis angle up icon" : "ellipsis angle down icon"}></i></div>
                                        {!this.state.show_print_patient_form ? <PrintPatientFormsComponent show_print_patient_form={this.state.show_print_patient_form} patient_id={this.state.patient_id} patient_full_name={this.state.patient_data.full_name} /> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
                <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area" id="applicationFooterSticky">
                    <Button id="view_patient_edit_button" tabIndex={0} type="submit" onKeyDown={set_focus_to_app_header} className="ui primary button" role="button" onClick={() => this.on_edit_click()}>Edit Patient</Button>
                </div>
            </React.Fragment>
        );

    }

}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        get_patient_details: get_patient_details,
        update_collection_flag: update_collection_flag,
        set_active_patient: set_active_patient,
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        patient_details: state.patient_details

    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewPatientComponent))