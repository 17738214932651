import { header_template } from '../admin/constants/constants';
import { HEADER_TEMPLATE_FIRST_COLUMN, HEADER_TEMPLATE_COLUMN } from '../shared/component/grid/constants';
import { headerTemplate, get_header_checkbox_template, report_ledger_full_configuration_header_id } from '../reports/report_constants';
import { currency_comparator, text_comparator, mm_dd_yyyy_comparator, number_comparator } from '../shared/component/grid/comparators';
import { get_header_template_with_navigation } from '../shared/shared_constants';
import { strip, removeClass, addClass } from '../shared/utility';
import { handle_cell_checkbox } from '../billing/utility';
import PaymentBadgeRendererComponent from './component/payment_badge_renderer';

export const payment_message = {
    required_field: 'Please fill all the mandatory fields or check highlighted field values.',
    SAVE_SUCCESS: 'Credit Added.',
    UPDATE_ERROR: 'No Changes',
    UPDATE_SUCCESS: 'Credit Updated.',
    DELETE_ERROR: 'Please select a record to delete.',
    DELETE_CONFIRM: 'Do you want to delete selected record?',
    DELETE_SUCCESS: 'Credit Deleted.',
    amount_negative: 'Amount must be negative for refund.',
    negative_invalid: 'Negative amount is not valid.',
    ERROR_SERVER_SIDE_PATIENT_REFUND: 'The refund cannot be larger than the copay total',
    ERROR_PATIENT_REFUND: 'The refund cannot be larger than the patient credit',
    ERROR_PATIENT_REFUND_ADD_MUTIPLE: 'When Transaction Type is Patient Refund, the value cannot be larger than the patient credit amount',
    RESET_835s_ERROR_SELECTION: 'Please select a record to Reset 835s',
    RESET_835s_SUCCESS: 'The selected 835s have been submitted for reset.',
    RESET_835s_ERROR: 'At least one 835 failed to reset. Please try again. If you continue to experience an issue, contact support.',
}

export const ERROR_CORRECTION_MESSAGE = {
    SAVE_SUCCESS: 'Saved successfully.',
    NO_CHANGE: 'No changes have been made, press cancel to exit page.',
    NO_EDIT_CHANGE: 'No changes have been made.',
    DELETE_CHARGE_CONFIRM: 'Are you sure you want to delete this charge?',
    DELETE_PAYMENT_CONFIRM: 'Are you sure you want to delete this payment?',
}

export const INTEGRATION_PAYMENT_MESSAGE = {
    FILE_ALREADY_EXIST: "There is already a file selected.",
    SELECT_ONE_ROW: "Please select one row.",
    PROCESSING_WILL_ALERT: "Processing. We will alert you when finished",
    PAYMENT_RELEASED: "Patient payments released",
    PAYMENT_CLOSED: "Patient payments closed"
}

export const PAYMENT_ACTION = {
    ADD_CREDIT: 'AADD_CREDIT',
    UPDATE_CREDIT: 'UPDATE_CREDIT',
    DELETE_CREDIT: 'DELETE_CREDIT'
}

export const payment_const = {
    save: 'SAVE_PAYMENT',
    save_request: 'SAVE_REQUEST',
    save_success: 'SUCCESS',
    save_failure: 'FAILURE',
    save_credits: 'SAVE_CREDIT',
    all_payment_method: [{ value: 1, text: 'Cash' }, { value: 2, text: 'Check' }, { value: 3, text: 'Credit Card' }, { value: 4, text: 'Debit Card' }, { value: 5, text: 'HSA' }],    //these all the methods for R6 payment
    credit_type: { creditTypeId: 1, copay: 2 },
    change_flag: 'CHANGE_FLAG',
    check_flag: false,
    PAGINATION_SIZE: 6
}

export const payment_adjustments = {
    qualifier: "Adjustment Qualifier",
    reason: "Adjustment Code",
    code: "Patient Balance Adjustment Code"
}

export const patientCreditType = {
    copay: 1,
    insoverpayment: 2,
    interest: 3,
    patientcredit: 4,
    balancefwd: 5,
    medicalrecord: 6,
    patientrefund: 7,
    supplyrecord: 8,

    coinsurance: 9,
    deductible: 10,
    other: 11,
    paymentonaccount: 12,
    wellness: 13
}
export const search_location_configuration = {
    column_defs: [
        {
            headerName: "Location Name",
            headerComponentParams: HEADER_TEMPLATE_FIRST_COLUMN,
            field: "name",
            filter: "textFilter",
            minWidth: 175
        },
        {
            headerName: "Location Code",
            headerComponentParams: HEADER_TEMPLATE_COLUMN,
            field: "code",
            filter: "textFilter",
            minWidth: 175
        },

    ]
};

export const search_provider_configuration = {
    column_defs: [
        {
            headerName: "Provider Name",
            headerComponentParams: HEADER_TEMPLATE_FIRST_COLUMN,
            field: "description",
            filter: "textFilter",
            minWidth: 175
        },
        {
            headerName: "Provider Code",
            headerComponentParams: HEADER_TEMPLATE_COLUMN,
            field: "code",
            filter: "textFilter",
            minWidth: 175
        },

    ]
};

export const ACTION_CONSTANT = {
    GET_PATIENT_CREDITS: 'GET_PATIENT_CREDITS'
}
export const BATCH_REFERENCE_DATA = 'BATCH:REFERENCE_DATA';

export const reset835s = {
    title: 'Reset 835s',
    report_grid_id: 'reset_835_eob',
    msg: `Please enter number(s) only. Use ',' for multiple. There should be no trailing/leading space.`,
    invalid_835_msg: `At least one 835 entered is invalid. Only valid results are displayed.`,
    invalid_835: `At least one 835 entered is invalid.`,
    column_defs: [
        {
            headerName: '',
            field: 'group',
            type: 'string',
            unSortIcon: true,
            maxWidth: 40,
            width: 40,
            suppressSorting: true,
            suppressFilter: true,
            resizable: false,
            suppressMovable: false,
            colSpan: (params) => {
                if (!params.node.group && !params.node.rowPinned) {
                    return params.columnApi.getAllColumns().length;
                }
                return 1;
            },
            cellRendererSelector: (params) => {
                if (params && params.data && params.data.participants && params.data.participants.length == 0) {
                    return null;
                }
                if (params) {
                    if (params.node.group) {
                        return {
                            component: 'agGroupCellRenderer',
                        };
                    }
                    if (params.data && params.data.child_column) {
                        return {
                            component: 'InnerGridRendererComponent',
                        };
                    }
                }
            },
            valueGetter: function chainValueGetter(params) {
                return '';
            }
        },
        {
            headerName: "",
            headerComponentParams: get_header_template_with_navigation(report_ledger_full_configuration_header_id),
            // headerComponentParams: headerTemplate,
            field: "eob_id",
            headerCheckboxSelection: true,
            checkboxSelection: true,
            maxWidth: 40,
            minWidth: 40,
            width: 40,
            suppressSorting: true,
            suppressFilter: true,
            suppressSizeToFit: true,
            resizable: false,
            suppressMovable: false,
            cellStyle: { 'padding-left': '6px' },
        },
        {
            headerName: '835 Key',
            headerComponentParams: headerTemplate,
            field: 'eob_key',
            type: 'number',
            suppressSorting: true,
            suppressFilter: true,
        },
        {
            headerName: 'Company Code',
            headerComponentParams: headerTemplate,
            field: 'clinic_code',
            type: 'string',
            suppressSorting: true,
            suppressFilter: true,
        },
        {
            headerName: 'Released By',
            headerComponentParams: headerTemplate,
            field: 'release_by',
            type: 'string',
            suppressSorting: true,
            suppressFilter: true,
        },
        {
            headerName: 'Released Date',
            headerComponentParams: headerTemplate,
            field: 'release_date',
            type: 'dateTime',
            suppressSorting: true,
            suppressFilter: true,
        },
        {
            headerName: 'Status',
            headerComponentParams: headerTemplate,
            field: 'status',
            type: 'string',
            suppressSorting: true,
            suppressFilter: true,
        },
        {
            headerName: 'Closed By',
            headerComponentParams: headerTemplate,
            field: 'closed_by',
            type: 'string',
            suppressSorting: true,
            suppressFilter: true,
        },
        {
            headerName: 'Closed Date',
            headerComponentParams: headerTemplate,
            field: 'closed_date',
            type: 'dateTime',
            suppressSorting: true,
            suppressFilter: true,
        },
        {
            headerName: 'Deposit Date',
            headerComponentParams: headerTemplate,
            field: 'deposit_date',
            type: 'dateTime',
            suppressSorting: true,
            suppressFilter: true,
        },
    ],
    child_column: [
        {
            headerName: 'Check Numbers',
            headerComponentParams: headerTemplate,
            field: 'check_number',
            type: 'string',
            suppressSorting: true,
            suppressFilter: true,
        },
        {
            headerName: 'Charge ID',
            headerComponentParams: headerTemplate,
            field: 'charge_id',
            type: 'string',
            suppressSorting: true,
            suppressFilter: true,
        },
        {
            headerName: 'Patient ID',
            headerComponentParams: headerTemplate,
            field: 'patient_id',
            type: 'string',
            suppressSorting: true,
            suppressFilter: true,
        },
        {
            headerName: 'Total Charge Amount',
            headerComponentParams: headerTemplate,
            field: 'total_charge_amount',
            type: 'currency',
            cellStyle: { textAlign: 'right' },
            suppressSorting: true,
            suppressFilter: true,
        },
        {
            headerName: 'Payment Amount',
            headerComponentParams: headerTemplate,
            field: 'payment_amount',
            type: 'currency',
            cellStyle: { textAlign: 'right' },
            suppressSorting: true,
            suppressFilter: true,
        },
        {
            headerName: 'Adjustment Amount',
            headerComponentParams: headerTemplate,
            field: 'adjustment_amount',
            type: 'currency',
            cellStyle: { textAlign: 'right' },
            suppressSorting: true,
            suppressFilter: true
        },
    ],
};

export const release835sDetail_configuration = {
    title: "Release 835 - Check Details",
    url: "/payments/release_835s_detail",
    parentUrl: "/payments/release_835s",
    pageRangeDisplayed: 5,
    prompt_message: {
        add_new: 'This action will cause any unsaved data to be lost. Continue?',
        title: 'Unsaved changes'
    },
    prompt_title: {
        unsaved_changes: 'Unsaved Changes',
        delete: 'Delete',
        confirm: 'Confirm',
    },
    prompt_button_label: {
        continue: 'Continue',
        delete: 'Delete',
        ok: 'OK'
    },
    errors: {
        patientsNotFound: 'Patients not found',
        unableToSave: 'Unable to save. Please review the required fields.',
        dataLoadFail: 'Data could not be loaded',
        searchError: 'Search Error',
        checkNumberError: 'Check Number Error',
        hasStickyNotes: 'Account has a payment sticky note',
    },
    maxCards: 7,
    columnDef: [
        {
            headerName: "Check Number",
            field: "checkNumber",
            type: "hyper_link"
        },
        {
            headerName: 'Inbound ID',
            field: 'inboundId',
            type: 'string'
        },
        {
            headerName: 'Payer',
            field: 'payer',
            type: 'string'
        },
        {
            headerName: 'Amount',
            field: 'amount',
            type: 'currency',
        },
        {
            headerName: '835 Date',
            field: 'date835',
            type: 'string'
        },
        {
            headerName: 'Load Date',
            field: 'loadDate',
            type: 'string'
        },
        {
            headerName: 'Location',
            field: 'location',
            type: 'string'
        }
    ],
    r4ToR6Route: [
        {
            has_parameter: false,
            parameters: null,
            r4_route: "ReportSelection.aspx",
            r6_route: "/report/sub_report_data?param"
        }
    ],
    process_msg: {
        success: "835 key processed successfully.",
        error: "An error ocurred while processing 835 key."
    }
};

export const epr_patient_balance =
{
    adjust: 0,
    keep: 1
};


export const error_corrections_report_grid_id = 'report_id_error_corrections';
export const error_correction_search_page_size = null;

export const error_corrections = (props) => {
    return {
        title: 'Error Corrections',
        child_column: [
            {
                headerName: '',
                maxWidth: 40,
                width: 40,
                suppressMenu: true,
                suppressSorting: true,
            },
            {
                headerName: 'Posting Date',
                field: 'deposit_date',
                align: 'left',
                suppressMenu: true,
                suppressSorting: true,
            },
            {
                headerName: 'Posted By',
                field: 'post_by',
                align: 'left',
                suppressMenu: true,
                suppressSorting: true,
            },
            {

                headerName: 'Transaction',
                field: 'transaction',
                align: 'left',
                suppressMenu: true,
                suppressSorting: true,
            },
            {

                headerName: 'Payment',
                field: 'payment',
                cellStyle: { textAlign: 'right' },
                suppressMenu: true,
                suppressSorting: true
            },
            {

                headerName: 'Adjustment',
                field: 'adjustment_sec',
                cellStyle: { textAlign: 'right' },
                suppressMenu: true,
                suppressSorting: true
            },
            {
                headerName: 'Transfer',
                field: 'transfer',
                cellStyle: { textAlign: 'right' },
                suppressMenu: true,
                suppressSorting: true
            },
            {

                headerName: 'From',
                field: 'xfer_ins',
                align: 'left',
                suppressMenu: true,
                suppressSorting: true,
            },
            {

                headerName: 'To',
                field: 'xfer_to_ins',
                align: 'left',
                suppressMenu: true,
                suppressSorting: true,
            },
            {

                headerName: 'Check Number',
                field: 'check_number',
                align: 'left',
                suppressMenu: true,
                suppressSorting: true,
            },
            {

                headerName: 'Batch Number',
                field: 'batch_number',
                align: 'left',
                suppressMenu: true,
                suppressSorting: true,
            },
            {

                headerName: 'Adjustment Qualifier',
                field: 'adjustment_qualifier',
                align: 'left',
                suppressMenu: true,
                suppressSorting: true,
            },
            {

                headerName: 'Adjustment Code',
                field: 'adjustment_code',
                align: 'left',
                suppressMenu: true,
                suppressSorting: true,
            }
        ],
        innerRowClassRules: {
            // row style function
            'inactive-pay': function (params) {
                return !params.data.pay_active
            }
        },
        rowClassRules: {
            // row style function
            'inactive-charge': function (params) {
                return !params.data.chg_Active
            }
        },
        column_defs: [
            {
                headerName: '',
                field: 'group',
                type: 'string',
                unSortIcon: true,
                maxWidth: 40,
                width: 40,
                suppressSorting: true,
                suppressFilter: true,
                resizable: false,
                colSpan: function (params) {
                    if (!params.node.group && !params.node.rowPinned) {
                        return params.columnApi.getAllColumns().length;
                    }
                    return 1;
                },
                cellRendererSelector: (params) => {
                    if (params && params.data && params.data.participants) {
                        if (!params.data.participants[0].details) {
                            return null;
                        }
                    }
                    if (params && params.node.rowPinned) {
                        return null;
                    }
                    if (params) {
                        if (params.node.group) {
                            return {
                                component: "agGroupCellRenderer"
                            };
                        }
                        if (params.data && params.data.child_column) {
                            return {
                                component: "InnerGridRendererComponent"
                            };
                        }
                    }
                },
                valueGetter: function chainValueGetter(params) {
                    return '';
                }
            },
            {
                headerName: 'Charge ID',
                headerComponentParams: get_header_template_with_navigation("error_corrections_configuration_header_id"),
                field: 'charge_id',
                filter: 'textFilter',
                type: 'link',
                unSortIcon: true,
                comparator: text_comparator,
                cellRenderer: (params) => error_corrections_link_renderer(params, props)
            },
            {
                headerName: 'Date of Service',
                headerComponentParams: headerTemplate,
                field: 'dos',
                filter: 'dateFilter',
                type: 'date',
                unSortIcon: true,
                comparator: mm_dd_yyyy_comparator
            },
            {
                headerName: 'Provider',
                headerComponentParams: headerTemplate,
                field: 'provider_name',
                filter: 'textFilter',
                type: 'string',
                unSortIcon: true,
                comparator: text_comparator,
            },
            {
                headerName: 'Location',
                headerComponentParams: headerTemplate,
                field: 'pos_name',
                filter: 'textFilter',
                type: 'string',
                unSortIcon: true,
                comparator: text_comparator
            },
            {
                headerName: 'MD',
                headerComponentParams: headerTemplate,
                field: 'mD_name',
                filter: 'textFilter',
                type: 'string',
                unSortIcon: true,
                comparator: text_comparator,
            },
            {
                headerName: 'CPT',
                headerComponentParams: headerTemplate,
                field: 'cpt_code',
                filter: 'textFilter',
                comparator: text_comparator,
                type: 'string',
                unSortIcon: true,
            },
            {
                headerComponentParams: headerTemplate,
                field: 'units',
                headerName: 'Units',
                filter: 'numberFilter',
                type: 'number',
                unSortIcon: true,
                comparator: number_comparator,
            },
            {
                headerName: 'Modifiers',
                headerComponentParams: headerTemplate,
                field: 'proc_mods',
                filter: 'textFilter',
                comparator: text_comparator,
                type: 'string',
                unSortIcon: true,
            },
            {
                headerComponentParams: headerTemplate,
                field: 'dxs',
                headerName: 'Diagnosis Codes',
                filter: 'textFilter',
                type: 'string',
                unSortIcon: true
            },
            {
                headerName: 'Charge Amount',
                headerComponentParams: headerTemplate,
                field: 'charge_amount',
                filter: 'currencyFilter',
                comparator: currency_comparator,
                type: 'currency',
                cellStyle: { textAlign: 'right' },
                unSortIcon: true,
            },
            {
                headerName: 'Payment',
                headerComponentParams: headerTemplate,
                field: 'total_pay_amt',
                filter: 'currencyFilter',
                comparator: currency_comparator,
                type: 'currency',
                cellStyle: { textAlign: 'right' },
                unSortIcon: true,
                
            },
            {
                headerName: 'Adjustment',
                headerComponentParams: headerTemplate,
                comparator: currency_comparator,
                field: 'adjustment',
                filter: 'currencyFilter',
                cellStyle: { textAlign: 'right' },
                type: 'currency',
                unSortIcon: true,
            },
            {
                headerName: 'Balance',
                headerComponentParams: headerTemplate,
                comparator: currency_comparator,
                field: 'balance',
                filter: 'currencyFilter',
                type: 'currency',
                unSortIcon: true,
                cellStyle: { textAlign: 'right' },
            },
            {
                headerName: 'Patient Balance',
                headerComponentParams: headerTemplate,
                comparator: currency_comparator,
                field: 'patient_balance',
                filter: 'currencyFilter',
                type: 'currency',
                cellStyle: { textAlign: 'right' },
                unSortIcon: true,
            },
            {
                headerName: 'Insurance Balance',
                headerComponentParams: headerTemplate,
                comparator: currency_comparator,
                field: 'insurance_balance',
                filter: 'currencyFilter',
                cellStyle: { textAlign: 'right' },
                type: 'currency',
                unSortIcon: true,
            },
            {
                headerName: 'Modified By',
                headerComponentParams: headerTemplate,
                field: 'modified_by',
                filter: 'textFilter',
                type: 'string',
                unSortIcon: true,
            }
        ]
    }
};

export const modifiers_list = (data) => {
    let screenWidth = window.screen.width;
    let subVal = 8;
    if (screenWidth > 1364){
        subVal = 12;
    }
    let list = [];

    for (let i = 0; i < 3; i++) {
        if (data[`proc_mod${i + 1}`]) {
            let val = {}
            val['disabled'] = false,
            val['is_checked'] = false,
            val['name'] = ((data[`proc_mod${i + 1}`] ? data[`proc_mod${i + 1}`] : '') + ' - ' + (data[`proc_mod${i + 1}_desc`] ? data[`proc_mod${i + 1}_desc`] : '')),
            val['value'] = data[`proc_mod${i + 1}_id`],
            val['label_text'] = ((data[`proc_mod${i + 1}`] ? data[`proc_mod${i + 1}`] : '') + ' - ' + (data[`proc_mod${i + 1}_desc`] ? data[`proc_mod${i + 1}_desc`]: '')).substring(0, subVal),
            val['procedure_modifier_desc'] = data[`proc_mod${i + 1}_desc`],
            val['procedure_modifier_code']= data[`proc_mod${i + 1}`]
            list.push(val)
        }
    }
    return list.filter(item => item.value);
}

export const diagnosis_code_list = (data) => {
    let screenWidth = window.screen.width;
    let subVal = 8;
    if (screenWidth > 1364){
        subVal = 12;
    }
    let list = [];

    for (let i = 0; i < 8; i++) {
        if (data[`dx${i + 1}`]) {
            let val = {}
            val['disabled'] = false,
            val['is_checked'] = false,
            val['name'] = ((data[`dx${i + 1}`] ? data[`dx${i + 1}`] : '') + ' - ' + (data[`dx${i + 1}_desc`] ? data[`dx${i + 1}_desc`] : '')),
            val['value'] = data[`dx${i + 1}`],
            val['label_text'] = ((data[`dx${i + 1}`] ? data[`dx${i + 1}`] : '') + ' - ' + (data[`dx${i + 1}_desc`] ? data[`dx${i + 1}_desc`]: '')).substring(0, subVal),
            val['description'] = data[`dx${i + 1}_desc`],
            val['icd_type'] = data[`dx${i + 1}_icd_type`]
            val['diagnosis_code_id']= data[`dx${i + 1}_id`]
            list.push(val)
        }
    }
    return list.filter(item => item.value);
}

export const error_corrections_editable = {
    title: 'Error Corrections',
    column: [
        {
            headerName: '',
            field: 'angle_icon',
        },
        {
            headerName: 'Charge ID',
            field: 'charge_id',
            textAlign: 'left',
            type: 'string'
        },
        {
            headerName: 'Date of Service',
            field: 'date_of_service',
            textAlign: 'left',
            type: 'date'
        },
        {
            headerName: 'Procedure Code',
            field: 'proc_code',
            textAlign: 'left',
            type: 'string'
        },
        {
            headerName: 'Units',
            field: 'units',
            textAlign: 'left',
            type: 'number'
        },
        {
            headerName: 'Charge',
            field: 'amount',
            textAlign: 'right',
            type: 'currency'
        },
        {
            headerName: 'Total Charge',
            field: 'total_charge_amount',
            textAlign: 'right',
            type: 'currency'
        },
        {
            headerName: 'Payment',
            field: 'payment',
            textAlign: 'right',
            type: 'currency'
        },
        {
            headerName: 'Adjustment',
            field: 'adjustment',
            textAlign: 'right',
            type: 'currency'
        },
        {
            headerName: 'Balance',
            field: 'balance',
            textAlign: 'right',
            type: 'currency'
        },
        {
            headerName: 'Ins Balance',
            field: 'insurance_balance',
            textAlign: 'right',
            type: 'currency'
        },
        {
            headerName: 'Patient Balance',
            field: 'patient_balance',
            textAlign: 'right',
            type: 'currency'
        },
    ],
    child_column: [
        {
            headerName: '',
            width: 40,
            minWidth: 40
        },
        {
            headerName: 'Deposit Date',
            field: 'deposit_date',
            type: 'date',
            minWidth: 150
        },
        {
            headerName: 'Transaction Code',
            field: 'payment_code_description',
            type: 'string',
            minWidth: 150
        },
        {
            headerName: 'Payment',
            field: 'payment_amount',
            cellStyle: { textAlign: 'right' },
            type: 'currency'
        },
        {
            headerName: 'Adjustment',
            field: 'adjustment_amount_display',
            cellStyle: { textAlign: 'right' },
            type: 'currency'
        },
        {
            headerName: 'Transfer Detail Amt',
            field: 'transfer_detail_amount',
            cellStyle: { textAlign: 'right' },
            type: 'currency',
            minWidth: 160
        },
        {
            headerName: 'From Ins Code',
            field: 'xfer_ins_name',
            type: 'string'
        },
        {
            headerName: 'Check Number',
            field: 'check_credit_num',
            type: 'string',
            minWidth: 130
        },
        {
            headerName: 'Transfer Ins Code',
            field: 'xfer_to_ins_name',
            type: 'string',
            minWidth: 150
        }
    ],
    child_column_enhanced: [
        {
            headerName: '',
            width: 40,
            minWidth: 40
        },
        {
            headerName: 'Deposit Date',
            field: 'deposit_date',
            type: 'date',
            minWidth: 150
        },
        {
            headerName: 'Posted By',
            field: 'created_by',
            type: 'string',
            minWidth: 150
        },
        {
            headerName: 'Transaction Code',
            field: 'payment_code_description',
            type: 'string',
            minWidth: 150
        },
        {
            headerName: 'Payment',
            field: 'payment_amount',
            cellStyle: { textAlign: 'right' },
            type: 'currency'
        },
        {
            headerName: 'Adjustment',
            field: 'adjustment_amount_display',
            cellStyle: { textAlign: 'right' },
            type: 'currency'
        },
        {
            headerName: 'Transfer Detail Amt',
            field: 'transfer_detail_amount',
            cellStyle: { textAlign: 'right' },
            type: 'currency',
            minWidth: 100,
            width: 100,
        },
        {
            headerName: 'From Ins Code',
            field: 'xfer_ins_name',
            type: 'string'
        },
        {
            headerName: 'Check Number',
            field: 'check_credit_num',
            type: 'string',
            minWidth: 130
        },
        {
            headerName: 'Batch Number',
            field: 'batch_num',
            type: 'string',
            minWidth: 130
        },
        {
            headerName: 'Transfer To',
            field: 'xfer_to_ins_name',
            type: 'string',
            minWidth: 150
        }
    ],
};

export const release835s_configuration = (r4_to_r6_routes: any) => {
  return {
    title: "Release 835s",
    report_grid_id: "release835s",
    release_grid_id: "releaseGrid",
    msg: `Either a Check Number or an 835 Date Range must be entered. Both can be entered, but only one is required.`,
    invalid_835_msg: `At least one 835 entered is invalid. Only valid results are displayed.`,
    process_msg: {
      success: "835 key(s) processed successfully.",
      empty_error: "Please select at least one record to process.",
      deposit_error: "Deposit Date required for releasing.",
      close_month_error: "The Deposit Date cannot be in a closed month.",
    },
    closed_msg: {
      success: "835 key(s) closed successfully.",
      error: "Please select at least one record to close."
    },
	  column_defs: [
      {
        headerName: "Release",
        headerComponentParams: get_header_checkbox_template("is_release"),
        field: "is_release",
        minWidth: 85,
        maxWidth: 85,
        width: 85,
        filter: "textFilter",
        type: "string",
        unSortIcon: true,
        suppressFilter: true,
        suppressSorting: true,
        suppressSizeToFit: true,
        resizable: false,
        suppressMovable: true,
        cellRenderer: (params: any) => {
          return handle_cell_checkbox(params,"is_release", "is_close", "status", "Payer ID Not Found",false,false);
        }
      },
      {
        headerName: "Close",
        headerComponentParams: get_header_checkbox_template("is_close"),
        field: "is_close",
        minWidth: 70,
        maxWidth: 70,
        width: 70,
        filter: "textFilter",
        type: "string",
        unSortIcon: true,
        suppressFilter: true,
        suppressSorting: true,
        suppressSizeToFit: true,
        resizable: false,
        suppressMovable: true,
        cellRenderer: (params: any) => {
            return handle_cell_checkbox(params, "is_close", "is_release", "status", "Payer ID Not Found",false,true);
        }
      },
      {
        headerName: "Status",
        headerComponentParams: headerTemplate,
        field: "status",
        type: "string",
        filter: "textFilter",
        comparator: text_comparator,
        unSortIcon: true
      },
      {
        headerName: "Payment Method",
        headerComponentParams: headerTemplate,
        field: "payment_method_code",
        type: "string",
        filter: "textFilter",
        comparator: text_comparator,
        unSortIcon: true
      },
      {
        headerName: "Check/EFT Number",
        headerComponentParams: headerTemplate,
        field: "check_eft_num",
        unSortIcon: true,
        type: "hyper_link",
        filter: "linkFilter",
        comparator: (link1: any, link2: any) => text_comparator(strip(link1), strip(link2)),
        cellRenderer: "PopUpLinkRenderer",
        cellRendererParams: { r4_to_r6_routes: r4_to_r6_routes, is_print_disabled: true, is_export_disabled: true, auto_print: false, downloadPdf: true }
      },
      {
        headerName: "Amount",
        headerComponentParams: headerTemplate,
        field: "check_amt",
        type: "currency",
        filter: "currencyFilter",
        comparator: currency_comparator,
        unSortIcon: true,
        cellStyle: { textAlign: "right" }
      },
      {
        headerName: "Payer",
        headerComponentParams: headerTemplate,
        field: "payer",
        type: "string",
        filter: "textFilter",
        comparator: text_comparator,
        unSortIcon: true
      },
      {
        headerName: "Payer ID",
        headerComponentParams: headerTemplate,
        field: "payer_id",
        type: "string",
        filter: "textFilter",
        comparator: text_comparator,
        unSortIcon: true
      },
      {
        headerName: "835 Date",
        headerComponentParams: headerTemplate,
        field: "eob_date",
        type: "date",
        filter: "dateFilter",
        comparator: mm_dd_yyyy_comparator,
        unSortIcon: true
      },
      {
        headerName: "Load Date",
        headerComponentParams: headerTemplate,
        field: "load_date",
        type: "date",
        filter: "dateFilter",
        comparator: mm_dd_yyyy_comparator,
        unSortIcon: true
      },
      {
        headerName: "835 Key",
        headerComponentParams: headerTemplate,
        field: "eob_key",
        type: "string",
        filter: "textFilter",
        comparator: text_comparator,
        unSortIcon: true
      },
      {
        headerName: "Location",
        headerComponentParams: headerTemplate,
        field: "clinic_name",
        type: "string",
        filter: "textFilter",
        comparator: text_comparator,
        unSortIcon: true
          }
	  ],
	  ff_column_defs: [
		{
			headerName: "",
			field: "number_errors",
			cellRendererFramework: PaymentBadgeRendererComponent,
			type: "string",
			unSortIcon: false,
			width: 15,
			headerClass: 'non-filter-column'
		},
		{
			headerName: "Release",
			headerComponentParams: get_header_checkbox_template("is_release"),
			field: "is_release",
			minWidth: 85,
			maxWidth: 85,
			width: 85,
			filter: "textFilter",
			type: "string",
			unSortIcon: true,
			suppressFilter: true,
			suppressSorting: true,
			suppressSizeToFit: true,
			resizable: false,
			suppressMovable: true,
			cellRenderer: (params: any) => {
				return handle_cell_checkbox(params, "is_release", "is_close", "status", "Payer ID Not Found", false, false);
			}
		},
		{
			headerName: "Close",
			headerComponentParams: get_header_checkbox_template("is_close"),
			field: "is_close",
			minWidth: 70,
			maxWidth: 70,
			width: 70,
			filter: "textFilter",
			type: "string",
			unSortIcon: true,
			suppressFilter: true,
			suppressSorting: true,
			suppressSizeToFit: true,
			resizable: false,
			suppressMovable: true,
			cellRenderer: (params: any) => {
				return handle_cell_checkbox(params, "is_close", "is_release", "status", "Payer ID Not Found", false, true);
			}
		},
		{
			headerName: "Status",
			headerComponentParams: headerTemplate,
			field: "status",
			type: "string",
			filter: "textFilter",
			comparator: text_comparator,
			unSortIcon: true
		},
		{
			headerName: "Payment Method",
			headerComponentParams: headerTemplate,
			field: "payment_method_code",
			type: "string",
			filter: "textFilter",
			comparator: text_comparator,
			unSortIcon: true
		},
		{
			headerName: "Check/EFT Number",
			headerComponentParams: headerTemplate,
			field: "check_eft_num",
			unSortIcon: true,
			type: "hyper_link",
			filter: "linkFilter",
			comparator: (link1: any, link2: any) => text_comparator(strip(link1), strip(link2)),
			cellRenderer: "PopUpLinkRenderer",
			cellRendererParams: { r4_to_r6_routes: r4_to_r6_routes, is_print_disabled: true, is_export_disabled: true, auto_print: false, downloadPdf: true }
		},
		{
			headerName: "Amount",
			headerComponentParams: headerTemplate,
			field: "check_amt",
			type: "currency",
			filter: "currencyFilter",
			comparator: currency_comparator,
			unSortIcon: true,
			cellStyle: { textAlign: "right" }
		},
		{
			headerName: "Payer",
			headerComponentParams: headerTemplate,
			field: "payer",
			type: "string",
			filter: "textFilter",
			comparator: text_comparator,
			unSortIcon: true
		},
		{
			headerName: "Inbound ID",
			headerComponentParams: headerTemplate,
			field: "payer_id",
			type: "string",
			filter: "textFilter",
			comparator: text_comparator,
			unSortIcon: true
		},
		{
			headerName: "835 Date",
			headerComponentParams: headerTemplate,
			field: "eob_date",
			type: "date",
			filter: "dateFilter",
			comparator: mm_dd_yyyy_comparator,
			unSortIcon: true
		},
		{
			headerName: "Load Date",
			headerComponentParams: headerTemplate,
			field: "load_date",
			type: "date",
			filter: "dateFilter",
			comparator: mm_dd_yyyy_comparator,
			unSortIcon: true
		},
		{
			headerName: "835 Key",
			headerComponentParams: headerTemplate,
			field: "eob_key",
			type: "string",
			filter: "textFilter",
			comparator: text_comparator,
			unSortIcon: true
		},
		{
			headerName: "Location",
			headerComponentParams: headerTemplate,
			field: "clinic_name",
			type: "string",
			filter: "textFilter",
			comparator: text_comparator,
			unSortIcon: true
		  }
		],
    release_column_def: [
      {
        headerName: "Close",
        headerComponentParams: get_header_template_with_navigation(report_ledger_full_configuration_header_id),
        field: "checkbox",
        headerCheckboxSelection: true,
        checkboxSelection: true,
        suppressFilter: true,
        suppressSorting: true,
        suppressSizeToFit: true,
        resizable: false,
        suppressMovable: true
      },
      {
        headerName: "835 Key",
        headerComponentParams: headerTemplate,
        field: "eob_key",
        type: "string",
        filter: "textFilter",
        comparator: text_comparator,
        unSortIcon: true
      },
      {
        headerName: "Company",
        headerComponentParams: headerTemplate,
        field: "company",
        type: "string",
        filter: "textFilter",
        comparator: text_comparator,
        unSortIcon: true
      },
      {
        headerName: "Check/EFT Number",
        headerComponentParams: headerTemplate,
        field: "check_eft_num",
        unSortIcon: true,
        type: "hyper_link",
        filter: "linkFilter",
        comparator: (link1: any, link2: any) => text_comparator(strip(link1), strip(link2)),
        cellRenderer: "PopUpLinkRenderer",
        cellRendererParams: { r4_to_r6_routes: r4_to_r6_routes, is_print_disabled: true, is_export_disabled: true, auto_print: false, downloadPdf: true }
      },
      {
        headerName: "Amount",
        headerComponentParams: headerTemplate,
        field: "check_amt",
        type: "currency",
        filter: "currencyFilter",
        comparator: currency_comparator,
        unSortIcon: true,
        cellStyle: { textAlign: "right" }
      },
      {
        headerName: "Payer",
        headerComponentParams: headerTemplate,
        field: "payer",
        type: "string",
        filter: "textFilter",
        comparator: text_comparator,
        unSortIcon: true
      },
      {
        headerName: "Payer ID",
        headerComponentParams: headerTemplate,
        field: "payer_id",
        type: "string",
        filter: "textFilter",
        comparator: text_comparator,
        unSortIcon: true
      },
      {
        headerName: "835 Date",
        headerComponentParams: headerTemplate,
        field: "eob_date",
        type: "date",
        filter: "dateFilter",
        comparator: mm_dd_yyyy_comparator,
        unSortIcon: true
      },      
      {
        headerName: "Release Date",
        headerComponentParams: headerTemplate,
        field: "release_date",
        type: "date",
        filter: "dateFilter",
        comparator: mm_dd_yyyy_comparator,
        unSortIcon: true
      },
      {
        headerName: "Processed Date",
        headerComponentParams: headerTemplate,
        field: "processed_date",
        type: "date",
        filter: "dateFilter",
        comparator: mm_dd_yyyy_comparator,
        unSortIcon: true
      },
      {
        headerName: "Errors",
        headerComponentParams: headerTemplate,
        field: "comments",
        unSortIcon: true,
        type: "hyper_link",
        filter: "linkFilter",
        comparator: (link1: any, link2: any) => text_comparator(strip(link1), strip(link2)),
        cellRenderer: "PopUpLinkRenderer",
        cellRendererParams: { r4_to_r6_routes: r4_to_r6_routes, is_print_disabled: true, is_export_disabled: true, auto_print: false, downloadPdf: true }
      }
	  ],
	  ff_release_column_def: [
		{
			headerName: "Close",
			headerComponentParams: get_header_template_with_navigation(report_ledger_full_configuration_header_id),
			field: "checkbox",
			headerCheckboxSelection: true,
			checkboxSelection: true,
			suppressFilter: true,
			suppressSorting: true,
			suppressSizeToFit: true,
			resizable: false,
			suppressMovable: true
		},
		{
			headerName: "835 Key",
			headerComponentParams: headerTemplate,
			field: "eob_key",
			type: "string",
			filter: "textFilter",
			comparator: text_comparator,
			unSortIcon: true
		},
		{
			headerName: "Company",
			headerComponentParams: headerTemplate,
			field: "company",
			type: "string",
			filter: "textFilter",
			comparator: text_comparator,
			unSortIcon: true
		},
		{
			headerName: "Check/EFT Number",
			headerComponentParams: headerTemplate,
			field: "check_eft_num",
			unSortIcon: true,
			type: "hyper_link",
			filter: "linkFilter",
			comparator: (link1: any, link2: any) => text_comparator(strip(link1), strip(link2)),
			cellRenderer: "PopUpLinkRenderer",
			cellRendererParams: { r4_to_r6_routes: r4_to_r6_routes, is_print_disabled: true, is_export_disabled: true, auto_print: false, downloadPdf: true }
		},
		{
			headerName: "Amount",
			headerComponentParams: headerTemplate,
			field: "check_amt",
			type: "currency",
			filter: "currencyFilter",
			comparator: currency_comparator,
			unSortIcon: true,
			cellStyle: { textAlign: "right" }
		},
		{
			headerName: "Payer",
			headerComponentParams: headerTemplate,
			field: "payer",
			type: "string",
			filter: "textFilter",
			comparator: text_comparator,
			unSortIcon: true
		},
		{
			headerName: "Inbound ID",
			headerComponentParams: headerTemplate,
			field: "payer_id",
			type: "string",
			filter: "textFilter",
			comparator: text_comparator,
			unSortIcon: true
		},
		{
			headerName: "835 Date",
			headerComponentParams: headerTemplate,
			field: "eob_date",
			type: "date",
			filter: "dateFilter",
			comparator: mm_dd_yyyy_comparator,
			unSortIcon: true
		},
		{
			headerName: "Release Date",
			headerComponentParams: headerTemplate,
			field: "release_date",
			type: "date",
			filter: "dateFilter",
			comparator: mm_dd_yyyy_comparator,
			unSortIcon: true
		},
		{
			headerName: "Processed Date",
			headerComponentParams: headerTemplate,
			field: "processed_date",
			type: "date",
			filter: "dateFilter",
			comparator: mm_dd_yyyy_comparator,
			unSortIcon: true
		},
		{
			headerName: "Errors",
			headerComponentParams: headerTemplate,
			field: "comments",
			unSortIcon: true,
			type: "hyper_link",
			filter: "linkFilter",
			comparator: (link1: any, link2: any) => text_comparator(strip(link1), strip(link2)),
			cellRenderer: "PopUpLinkRenderer",
			cellRendererParams: { r4_to_r6_routes: r4_to_r6_routes, is_print_disabled: true, is_export_disabled: true, auto_print: false, downloadPdf: true }
		}
	  ],
    eob_payer_column_defs: [
      {
        headerName: "Payer Id",
        field: "payer_id",
        filter: "textFilter",
        comparator: text_comparator,
        minWidth: 175,
        headerComponentParams: get_header_template_with_navigation("payer"),
        unSortIcon: true
      },
      {
        headerName: "Name",
        field: "name",
        filter: "textFilter",
        comparator: text_comparator,
        minWidth: 175,
        headerComponentParams: headerTemplate,
        unSortIcon: true
      }
    ]
  };
};

export enum release835Status {
    noPayerRuleEstablished = 'No Payer Rule Established',
    payerIdNotFound = 'Payer ID Not Found',
    inboundIdNotFound = 'Inbound ID Not Found'
}

export const payerRulesPageName = 'Add/Edit Payer Rules';

export const sticky_note_help_url = 'https://discover.webpt.com/v1/docs/en/webpt-billing-account-notes-and-sticky-notes';

const error_corrections_link_renderer = (params, props) => {
    if (!params.node.rowPinned) {
        let eDiv = document.createElement("span");
        eDiv.setAttribute('id', `${params.colDef.field}_${params.value}`);
        eDiv.innerHTML = params.value;
        if (params.data.chg_Active) {
            eDiv.setAttribute('class', 'href_link');
            eDiv.addEventListener("click", (para) => {
                props.history.push({
                    pathname: '/payments/error_corrections_details',
                    search: '',
                    state: { charge_id: params.value }
                });
            });
        }
        return eDiv;
    } else {
        return params.value
    }
};

export const bulk_payment_updates_report_grid_id = 'report_id_bulk_payment_updates';

export const ErrorCorrectionsEnhancementsProject = {
    projectFlag: "primeBill541ErrorCorrectionsEnhancements"
}

//Data posted by Server.
export interface IEOB835ReleaseDetailCardClaimDto {
    id: number,
    eob_key: number,
    check_number: string,
    icn: string,
    patient_id: number,
    date_of_service: Date,
    cpt_code: string,
    units: number,
    mod: string,
    billed_amount: number,
    allowed_amount: number,
    expected_amount: number,
    paid_amount: number,
    adjustment_amount: number,
    adjustment_qualifier_code: string
    reason_code: string,
    balance_amount: number,
    claim_status_code: number | null,
    insurance_balance_id: number,
    patient_balance_id: number,
    patient_balance_adjustment_id: number,
    service_id: number,
    group_id: number,
    sequence: number,
    charge_id: number,
    is_primary: number,
    validation_message: string,
    exclude_error: boolean,
    is_added:boolean,
    is_removed: boolean,
    display_row_id: number,
    clinic_code: string,
    case_id: number,
    max_patient_balance:number
}

export const EobReleaseLineItemType = {
    primary: 1 //row with balance 
}

//For check details page
export interface IEOB835ReleaseEditExcludeNoteDto {
    id: number,
    eob_key: number,
    patient_id: number,
    updated_by: number,
    updated_date: Date
}

// For error info
export interface IEOB835ReleaseDetailCardErrorInfoDto {

    patientAccount: string,
    caseId: number,
    dateOfService: Date,
    cptCode: string,
    errorMessage: string
}


export const icn_data_session_key_name_list = 'release_835s_patient_card_icn_detail_keys';

export const e835_inbound_id_set_up = 
{
    no_insurances_with_code: 50030,
    drawer_p1_text: 'One or more patients listed on this check do not have an insurance associated with the Inbound ID.',
    drawer_p2_with_error_text: 'There were no insurances found which are available to associate to',
    drawer_p3_with_error_text: 'Please continue posting this check manually.',
    drawer_p2_text: 'If none of the the listed insurances should be associated with this Inbound ID, ignore this status and post the check normally.',
    drawer_p3_text: 'Are any of the listed insurances associated with this Inbound ID?',
    drawer_p1_no_insurance_no_error_text: 'The payer previously missing the Inbound ID has already been updated. You may close this message and continue.',
    drawer_title_inbound_id_attached: 'Inbound ID Already Attached',
    drawer_title_inbound_id_not_found: 'Inbound ID Not Found',
    drawer_title_no_insurances_found: 'No Insurances Found',
    error_msg_unable_to_update_payer: 'There was a problem updating the payers(s). Please contact support@webpt.com if the issue persists.',
    success_msg_on_save: 'Excellent! Payer(s) had Inbound IDs added.',
    no_insurances_selected: 'No insurances selected to associate with the Inbound ID',
}

export const upload_835_constants = {
    title: 'Upload 835',
    button_label: 'Upload',
    message: {
        error: 'Oof! An error occurred and your file was not uploaded.',
        success: 'Success! Your file was uploaded.',
        no_files_selected: 'You did not select a file.',
        too_many_files_selected: 'Only 1 file can be uploaded.',
        wrong_filesize: 'That file doesn\'t fit. Uploads must be less than %d MB.',
        file_type_blacklisted: 'That file type is not accepted. Try again.'
    }
};
