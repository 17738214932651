import * as billing_constants from '../billing_constants';

export default function (state = [], { type, payload }) {
    switch (type) {
        case billing_constants.OPEN_CLAIMS_SEARCH:
            return { ...state, ["open_claims_search_data"]: payload.data };
        case billing_constants.EMPTY_CLAIMS_DATA:
            return { ...state, ["open_claims_search_data"]: [] };
        default:
            return state
    }
}