import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Icon, Popup } from 'semantic-ui-react';
import * as global_constant from '../../global_constants';
import { get_doctors_order_info } from '../action/patient_header_action';
import * as service_utility from './../../shared/utility';
import { handle_click_on_enter } from './../../shared/tab_navigation_utility';

export class PatientInformationIconComponent extends React.Component<any, any> {
    [x: string]: any;
    _is_mounted = false;
    constructor(props) {
        super(props);

        this.state = {

            renderApiResponse: false,
            case_name: "",
            eligibility: {},
            pre_auth: {},
            referring: {},
            doctors: {},
            icon_color: "green_icon",
            icon_new_header:"icon-Info_iconGreen"
        }
    }



    componentDidMount() {
        this._is_mounted = true;
        this.format_case_data();
    }

    componentDidUpdate(previousProps, previousState) {
        if (this.props.selected_case.id != previousProps.selected_case.id) {
            this.format_case_data();
        }
        else {
            if (this.props.is_render_again !== previousProps.is_render_again && this.props.is_render_again) {

                this.format_case_data();
                this.props.set_state_is_force_update(false);
            }
        }
    }

    format_case_data = async () => {
        // Format Case Name
        var case_name = service_utility.caseFormat(this.props.selected_case);

        var eligibility = this.set_eligibilty_info(this.props.selected_case);
        var pre_auth = this.set_pre_authorization_info(this.props.selected_case);
        var referring = this.set_referring_physician_details();
        var doctors = await this.setup_doctor_order_info(this.props.selected_case);
        // Get Icon Color
        var icon_new_header = 'icon-Info_iconGreen';        
        var icon_color = "green_icon";
        if (pre_auth.is_any_invalid || eligibility.is_any_invalid || doctors.is_any_invalid) {
            icon_color = "red_icon";
            icon_new_header = 'icon-Info_icon';
        } else {
            icon_color = "green_icon";
            icon_new_header = 'icon-Info_iconGreen';
        }
        if (this._is_mounted) {
            this.setState({
                renderApiResponse: true,
                case_name: case_name,
                eligibility: eligibility,
                pre_auth: pre_auth,
                referring: referring,
                doctors: doctors,
                icon_color: icon_color,
                icon_new_header: icon_new_header
            });
        }
    }
    /*
     EXPIRED - Doctor's Orders Expires : 08/20/2018
     Total Visits : 07 Visit Used : 04 Visits Left : 03
     */
    setup_doctor_order_info = (caseItem) => {

        var doctor_order_info: {
            doctor_order_expire: any,
            visit_used: number,
            visit_left: number,
            total_visit: number,
            show_no_visit_left: any,
            show_NA_visit: boolean,
            show_NA_expire: boolean,
            multiple_entry: boolean,
            is_any_invalid: boolean
        } = {
            doctor_order_expire: "",
            visit_used: 0,
            visit_left: 0,
            total_visit: 0,
            show_no_visit_left: "",
            show_NA_visit: false,
            show_NA_expire: false,
            multiple_entry: false,
            is_any_invalid: false
        };
        if (caseItem.subscriber != null && caseItem.subscriber.length > 0) {
            var doctors = caseItem.subscriber.length > 0 ? (caseItem.subscriber[0] != null ? (caseItem.subscriber[0].subscriber_doctors_orders != null ? caseItem.subscriber[0].subscriber_doctors_orders : '') : '') : '';

            if (doctors != '' && doctors.length > 0) {

                doctor_order_info.multiple_entry = caseItem.subscriber[0].subscriber_doctors_orders_counts > 1 ? true : false;

                doctor_order_info = this.doctors_order_exist(caseItem, doctors, doctor_order_info);
                return doctor_order_info;
            }
            else {
                doctor_order_info.doctor_order_expire = <>Doctor's Orders Expires : <span className="normalBlack">{global_constant.constants.not_specified}</span></>;
                doctor_order_info.show_NA_visit = true;
            }
        }

        return doctor_order_info;
    }

    doctors_order_exist = function (caseItem, doctors, doctor_order_info) {
        var allHaveEndDate = true;
        var allHaveStartDate = true;
        var minDate = new Date(0);
        var subscriberDoctorsOrders = [];

        var isEmptyEndDate = doctors.map(function (doctor) {
            return new Date(doctor.thru_date).getTime() == minDate.getTime();
        })[0];
        if (isEmptyEndDate) {
            allHaveEndDate = false;
        }

        var isEmptyStartDate = doctors.map(function (doctor) {
            return new Date(doctor.from_date).getTime() == minDate.getTime();
        })[0];
        if (isEmptyStartDate) {
            allHaveStartDate = false;
        }

        if (allHaveEndDate) {
            doctors = doctors.sort(function (obj1, obj2) { return new Date(obj2.thru_date).getTime() - new Date(obj1.thru_date).getTime() });
        }
        else if (allHaveStartDate) {
            doctors = doctors.sort(function (obj1, obj2) { return new Date(obj2.from_date).getTime() - new Date(obj1.from_date).getTime() });
        }
        else {
            doctors = doctors.sort(function (obj1, obj2) { return obj2.id - obj1.id });
        }
        if (allHaveStartDate || allHaveEndDate) {
            doctor_order_info = this.get_doctors_orders_visit_counts(caseItem.id, caseItem, doctors, doctor_order_info);
        }
        else {
            doctor_order_info = this.setup_doctor_order_visit_info(caseItem, doctors, doctor_order_info);
        }

        return doctor_order_info;
    }

    get_doctors_orders_visit_counts = async function (caseId, caseItem, doList: any, doctor_order_info) {
        if (doList == null || doList.Count == 0) return doList;
        var minDate = new Date(0);
        var from_date = minDate;
        var thru_date = minDate;
        var noThruDate = false;
        var noFromDate = false;

        var doList_length = doList.length;

        if (doList && doList_length > 0) {
            for (let k = 0; k < doList_length; k++) {
                let row = doList[k];
                if (from_date == minDate && row.from_date != null) from_date = row.from_date;
                else if (row.from_date < from_date) from_date = row.from_date;
                else if (row.from_date == null) noFromDate = true;

                if (thru_date == minDate && row.thru_date != null) thru_date = row.thru_date;
                else if (row.thru_date > thru_date) thru_date = row.thru_date;
                else if (row.thru_date == null) noThruDate = true;

                row.visits_used = 0;
            }
        }


        if (from_date == minDate) from_date = new Date('1975, 01, 01')
        if (noThruDate) {
            thru_date = null;
        }
        else if (thru_date == minDate) { thru_date = new Date('1975, 01, 01'); }
        if (noFromDate) {
            from_date = null;
        }

        await this.props.get_doctors_order_info(from_date, thru_date, caseId, this.props.user_login_details.user_data.data.token_details.access_token)

        var visitList = this.props.doctors_order.data;
        var visitList_length = visitList.length
        if (visitList && visitList_length > 0) {
            for (let i = 0; i < visitList_length; i++) {
                let rowV = visitList[i]

                var addedToDO = false;

                doList = doList.sort(function (obj1, obj2) { return new Date(obj1.from_date).getTime() - new Date(obj2.from_date).getTime() });
                var doList_length = doList.length;
                if (doList && doList_length > 0) {

                    for (let i = 0; i < doList_length; i++) {
                        let rowDO = doList[i];

                        if ((rowDO.from_date != null && rowDO.from_date <= rowV.date_of_service) && (rowDO.thru_date == null || rowV.date_of_service <= rowDO.thru_date)) {
                            if (!addedToDO && rowDO.prescribed_visits != null && rowDO.prescribed_visits > rowDO.visits_used) {
                                if (rowDO.visits_used != null) rowDO.visits_used++;
                                else rowDO.visits_used = 1;
                                addedToDO = true;
                            }
                        }
                    }

                    doList = doList.sort(function (obj1, obj2) { return new Date(obj2.from_date).getTime() - new Date(obj1.from_date).getTime() });

                    for (let i = 0; i < doList_length; i++) {
                        let rowDO = doList[i];
                        if ((rowDO.from_date != null && rowDO.from_date <= rowV.date_of_service) && (rowDO.thru_date == null || rowV.date_of_service <= rowDO.thru_date) && !addedToDO) {
                            if (rowDO.visits_used != null) rowDO.visits_used++;
                            else rowDO.visits_used = 1;
                            addedToDO = true;
                        }
                    }
                }
            }
        }
        else {
            var doList_length = doList.length;
            if (doList && doList_length > 0) {
                for (let i = 0; i < doList_length; i++) {
                    let rowDO = doList[i];
                    rowDO.visits_used = 0;
                }
            }

        }

        doctor_order_info = this.setup_doctor_order_visit_info(caseItem, doList, doctor_order_info)

        return doctor_order_info;

    }

    setup_doctor_order_visit_info = (case_item, doctors, doctor_order_info) => {

        var multipleEntryStrik = case_item.subscriber[0].subscriber_doctors_orders_counts > 1 ? '* ' : '';
        var thru_date = doctors[0].thru_date != null ? (doctors[0].thru_date) : global_constant.constants.not_specified;
        var doctorsExpire = new Date(thru_date);
        var dateOfExpire = '';
        if (thru_date != global_constant.constants.not_specified && (doctorsExpire > new Date(0))) {
            dateOfExpire = service_utility.custom_date_format(thru_date, global_constant.date_format["mm/dd/yyyy"]);
        }
        else {
            dateOfExpire = global_constant.constants.not_specified;
        }
        if (thru_date != global_constant.constants.not_specified && this.is_date_expired(doctorsExpire)) {
            doctor_order_info.doctor_order_expire = <> {multipleEntryStrik}  EXPIRED - Doctor's Orders Expires : <span className="normalBlack">{dateOfExpire}</span></>;
            doctor_order_info.is_any_invalid = true;
        }
        else if (thru_date == global_constant.constants.not_specified) {
            doctor_order_info.doctor_order_expire = <> {multipleEntryStrik}  Doctor's Orders Expires : <span className="normalBlack">{global_constant.constants.not_specified}</span></>;
        }
        else {
            doctor_order_info.doctor_order_expire = <> {multipleEntryStrik}  Doctor's Orders Expires : <span className="normalBlack">{dateOfExpire}</span></>;
        }
        var prescribed_visits = doctors[0].prescribed_visits != null ? (doctors[0].prescribed_visits) : 0;
        var visits_used = doctors[0].visits_used != null ? (doctors[0].visits_used) : 0;
        if (doctors[0].prescribed_visits != null) {
            var visit_left = prescribed_visits - visits_used;
            if (prescribed_visits > 0 && (visit_left) <= 0) {

                doctor_order_info.visit_used = visits_used;
                doctor_order_info.visit_left = (visit_left < 0 ? 0 : visit_left);
                doctor_order_info.total_visit = prescribed_visits;
                doctor_order_info.show_no_visit_left = true;
                doctor_order_info.is_any_invalid = true;
            }
            else {
                doctor_order_info.visit_used = visits_used;
                doctor_order_info.visit_left = (visit_left < 0 ? 0 : visit_left);
                doctor_order_info.total_visit = prescribed_visits;
            }
        }
        else {
            doctor_order_info.visit_used = 0;
            doctor_order_info.visit_left = 0;
            doctor_order_info.total_visit = 0;
            doctor_order_info.show_NA_visit = true;
        }

        return doctor_order_info;
    }

    //check if the date is expire or not
    is_date_expired = (date) => {
        var today_date = service_utility.custom_date_format(new Date(), global_constant.date_format["mm/dd/yyyy"]);
        var compair_date = service_utility.custom_date_format(date, global_constant.date_format["mm/dd/yyyy"]);
        var expired = false;

        if (new Date(compair_date).getTime() != new Date(0).getTime() && new Date(compair_date).getTime() < new Date(today_date).getTime()) {
            expired = true;
        }
        return expired;
    }

    /*Formatting of Referring physician data. 
      Referring Physician: 1111 1
      Phone: (353) 353-5355
      Fax: (535) 353 - 5353
     */
    set_referring_physician_details = () => {

        var referring_physician = {
            ref_physician_name: this.props.selected_case.referring_physician != null ? (this.props.selected_case.referring_physician.name != null ? ((this.props.selected_case.referring_physician.name.first_name != null ? this.props.selected_case.referring_physician.name.first_name : '') + " " + (this.props.selected_case.referring_physician.name.last_name != null ? this.props.selected_case.referring_physician.name.last_name : '')) : '') : '',
            phone: (this.props.selected_case.referring_physician != null && this.props.selected_case.referring_physician.phone != null) ? service_utility.phone_format(this.props.selected_case.referring_physician.phone) : '',
            fax: (this.props.selected_case.referring_physician != null && this.props.selected_case.referring_physician.fax != null) ? service_utility.phone_format(this.props.selected_case.referring_physician.fax) : '',
        }

        return referring_physician;
    }

    /* Formatting of Authorization data.
        EXPIRED - Authorization Expires : 08/20/2018
        Total Visits : 07 Visit Used : 04 Visits Left : 03
        Auth# : 067996786
     */
    set_pre_authorization_info = (case_item) => {
        var authorization_info: {
            authorization_expire: any,
            visit_used: number,
            visit_left: number,
            total_visit: number,
            pre_auth_number: any,
            show_no_visit_left: any,
            show_NA_visit: boolean,
            show_NA_expire: boolean,
            multiple_entry: boolean,
            is_any_invalid: boolean,
            is_units: boolean
        } = {
            authorization_expire: "",
            visit_used: 0,
            visit_left: 0,
            total_visit: 0,
            pre_auth_number: "",
            show_no_visit_left: "",
            show_NA_visit: false,
            show_NA_expire: false,
            multiple_entry: false,
            is_any_invalid: false,
            is_units: false
        };
        var authorization = case_item.subscriber.length > 0 ? (case_item.subscriber[0] != null ? (case_item.subscriber[0].subscriber_pre_auth != null ? case_item.subscriber[0].subscriber_pre_auth : '') : '') : '';

        if (authorization != '' && authorization.length > 0) {
            // Sort authorization data by descending order
            authorization = authorization.sort(function (obj1, obj2) { return obj2.id - obj1.id });

            var auth_thru_date = authorization[0].thru_date != null ? (authorization[0].thru_date) : global_constant.constants.not_specified;

            authorization_info.multiple_entry = case_item.subscriber[0].subscriber_pre_auth_counts > 1 ? true : false;

            var multiple_entry_strik = case_item.subscriber[0].subscriber_pre_auth_counts > 1 ? '* ' : '';
            var date_of_expire = '';
            var auth_expire = new Date(auth_thru_date);

            if (auth_thru_date != global_constant.constants.not_specified && auth_expire > new Date(0)) {
                date_of_expire = service_utility.custom_date_format(auth_thru_date, global_constant.date_format["mm/dd/yyyy"]);
            }
            else {
                date_of_expire = global_constant.constants.not_specified;
            }

            if (auth_thru_date != global_constant.constants.not_specified && this.is_date_expired(auth_expire)) {
                authorization_info.authorization_expire = (<> {multiple_entry_strik} EXPIRED- Authorization Expires : <span className='normalBlack'>{date_of_expire}</span></>);
                authorization_info.is_any_invalid = true;
            }
            else if (auth_thru_date == global_constant.constants.not_specified) {
                authorization_info.authorization_expire = (<> {multiple_entry_strik} Authorization Expires : <span className='normalBlack'>{global_constant.constants.not_specified} </span></>);
            }
            else {
                authorization_info.authorization_expire = (<> {multiple_entry_strik}  Authorization Expires : <span className='normalBlack'> {date_of_expire}</span></>);
            }

            var pre_auth_visits = authorization[0].pre_auth_visits != null ? (authorization[0].pre_auth_visits) : 0;
            var visitsUsed = authorization[0].visits_used != null ? (authorization[0].visits_used) : 0;

            authorization_info.pre_auth_number = authorization[0].pre_auth_number != null ? (authorization[0].pre_auth_number) : "";

            if (authorization[0].pre_auth_visits != null) {
                var visit_left = pre_auth_visits - visitsUsed;
                if (authorization[0].is_units) {
                    authorization_info.is_units = true;
                    if (pre_auth_visits > 0 && (visit_left) <= 0) {
                        authorization_info.show_no_visit_left = "NO UNITS LEFT - ";
                        authorization_info.total_visit = pre_auth_visits;
                        authorization_info.visit_used = visitsUsed;
                        authorization_info.visit_left = (visit_left < 0 ? 0 : visit_left);
                        authorization_info.is_any_invalid = true;
                    }
                    else {
                        authorization_info.show_no_visit_left = "";
                        authorization_info.total_visit = pre_auth_visits;
                        authorization_info.visit_used = visitsUsed;
                        authorization_info.visit_left = (visit_left < 0 ? 0 : visit_left);
                    }
                }
                else {
                    if (pre_auth_visits > 0 && (visit_left) <= 0) {
                        authorization_info.show_no_visit_left = "NO VISITS LEFT - ";
                        authorization_info.total_visit = pre_auth_visits;
                        authorization_info.visit_used = visitsUsed;
                        authorization_info.visit_left = (visit_left < 0 ? 0 : visit_left);
                        authorization_info.is_any_invalid = true;
                    }
                    else {
                        authorization_info.show_no_visit_left = "";
                        authorization_info.total_visit = pre_auth_visits;
                        authorization_info.visit_used = visitsUsed;
                        authorization_info.visit_left = (visit_left < 0 ? 0 : visit_left);
                    }
                }

            }
            else {
                authorization_info.show_no_visit_left = "";
                authorization_info.total_visit = 0;
                authorization_info.visit_used = 0;
                authorization_info.visit_left = 0;
                authorization_info.show_NA_visit = true;
            }
        }
        else {
            authorization_info.show_no_visit_left = "";
            authorization_info.total_visit = 0;
            authorization_info.visit_used = 0;
            authorization_info.visit_left = 0;
            authorization_info.authorization_expire = (<> Authorization Expires : <span className='normalBlack'> {global_constant.constants.not_specified} </span></>);
            authorization_info.show_NA_visit = true;
        }

        return authorization_info;
    }

    /*Formatting of Eligibility data.
      Ins Eligible Through : Not Specified
      Eligibility Comment : 5
     */
    set_eligibilty_info = (case_item) => {

        // Form object to be returned
        var eligibility_info: {
            eligibility_expire: any,
            comment: any,
            multiple_entry: boolean,
            is_any_invalid: boolean
        } = {
            eligibility_expire: "",
            comment: "",
            multiple_entry: false,
            is_any_invalid: false
        };
        var eligibility = case_item.subscriber.length > 0 ? (case_item.subscriber[0] != null ? (case_item.subscriber[0].subscriber_eligibility != null ? case_item.subscriber[0].subscriber_eligibility : '') : '') : '';
        if (eligibility != '' && eligibility.length > 0) {
            //if (!multipleEntry) {
            //    multipleEntry = case_item.subscriber[0].subscriberEligibilityCounts > 1 ? true : false;
            //}
            eligibility_info.multiple_entry = case_item.subscriber[0].subscriber_eligibility_counts > 1 ? true : false;

            var multiple_entry_strik = case_item.subscriber[0].subscriber_eligibility_counts > 1 ? '* ' : '';
            var terminationDate = eligibility[0].termination_date != null ? (eligibility[0].termination_date) : global_constant.constants.not_specified;
            var date_of_expire = '';
            var eligExpire = new Date(terminationDate);

            if (terminationDate != global_constant.constants.not_specified && eligExpire > new Date(0)) {
                date_of_expire = service_utility.custom_date_format(eligExpire, global_constant.date_format["mm/dd/yyyy"]);
            }
            else {
                date_of_expire = global_constant.constants.not_specified;
            }

            if (terminationDate != global_constant.constants.not_specified && this.is_date_expired(eligExpire)) {
                eligibility_info.eligibility_expire = <> {multiple_entry_strik} EXPIRED- Insurance Eligible Through : <span className='normalBlack'> {date_of_expire}</span></>;
                eligibility_info.is_any_invalid = true;
            }
            else if (terminationDate == global_constant.constants.not_specified) {
                eligibility_info.eligibility_expire = <> {multiple_entry_strik} Insurance Eligible Through : <span className='normalBlack'>  {global_constant.constants.not_specified} </span> </>;
            }
            else {
                eligibility_info.eligibility_expire = <> {multiple_entry_strik} Insurance Eligible Through : <span className='normalBlack'> {date_of_expire} </span></>;
            }

            eligibility_info.comment = <> Eligibility Comment : <span className='normalBlack'> {(eligibility[0].comments != null ? (eligibility[0].comments) : '')}</span> </>;
        }
        else {
            eligibility_info.eligibility_expire = <> Insurance Eligible Through : <span className='normalBlack'> {global_constant.constants.not_specified} </span></>;
            eligibility_info.comment = <> Eligibility Comment : </>;
        }

        return eligibility_info;
    }
    componentWillUnmount() {
        this._is_mounted = false;
    }
    render() {


        // Dynamic Formatting of HTML Data
        var get_pre_auth_visit = (pre_auth) => {
            if (pre_auth.show_NA_visit) {
                var html = (
                    <Grid>
                        <Grid.Row columns={1} className="margin-zero">
                            <Grid.Column>
                                Total Visits : <span className="normalBlack">N/A</span>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )
                return html;
            }
            if (pre_auth.is_units) {
                var html = (
                    <Grid>
                        <Grid.Row columns={3} className="margin-zero">
                            <Grid.Column>
                                {pre_auth.show_no_visit_left ? "NO UNITS LEFT - " : ""}Total Units : <span className="normalBlack">{pre_auth.total_visit}</span>
                            </Grid.Column>
                            <Grid.Column>
                                Units Used : <span className="normalBlack">{pre_auth.visit_used}</span>
                            </Grid.Column>
                            <Grid.Column>
                                Units Left : <span className="normalBlack">{pre_auth.visit_left}</span>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )
                return html;
            }
            var html = (
                <Grid>
                    <Grid.Row columns={3} className="margin-zero">
                        <Grid.Column>
                            {pre_auth.show_no_visit_left ? "NO VISITS LEFT - " : ""}Total Visits : <span className="normalBlack">{pre_auth.total_visit}</span>
                        </Grid.Column>
                        <Grid.Column>
                            Visit Used : <span className="normalBlack">{pre_auth.visit_used}</span>
                        </Grid.Column>
                        <Grid.Column>
                            Visits Left : <span className="normalBlack">{pre_auth.visit_left}</span>
                        </Grid.Column>
                    </Grid.Row>

                </Grid>
            )
            return html;
        }

        var get_doctors_order_visit = (doctor) => {
            if (doctor.show_NA_visit) {
                var html = (
                    <Grid>
                        <Grid.Row columns={1} className="margin-zero">
                            <Grid.Column>
                                Total Visits : <span className="normalBlack">N/A</span>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )
                return html;
            }
            var html = (
                <Grid><Grid.Row columns={3} className="margin-zero">
                    <Grid.Column>
                        {doctor.show_no_visit_left ? "NO VISITS LEFT - " : ""}Total Visits : <span className="normalBlack">{doctor.total_visit}</span>
                    </Grid.Column>
                    <Grid.Column>
                        Visit Used : <span className="normalBlack">{doctor.visit_used}</span>
                    </Grid.Column>
                    <Grid.Column>
                        Visits Left : <span className="normalBlack">{doctor.visit_left}</span>
                    </Grid.Column>
                </Grid.Row>
                </Grid>
            )
            return html;
        }
        
        return (
            <Popup id='patient_info-strip' on={service_utility.is_mobile() ? 'click' : 'hover'}
                trigger={
                     <span className="popOver"> <i tabIndex={0} id='collection_flag' className={this.state.icon_new_header} /> </span >                     
                }                
            >
                <Popup.Content>
                    <ul>
                        <li>
                            <Grid.Row columns={1} className="margin-zero">
                                <Grid.Column>
                                    <div>
                                        Case Name : <span className="normalBlack">{this.state.case_name}</span>
                                    </div>
                                    <div>
                                        Total Visits : <span className="normalBlack">{this.props.selected_case.total_note_visit}</span>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </li>
                        <li>
                            <Grid.Row columns={1} className="margin-zero">
                                <Grid.Column>
                                    <div>
                                        Referring Physician : <span className="normalBlack">{this.state.referring.ref_physician_name}</span>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid>
                                <Grid.Row columns={2} className="margin-zero">
                                    <Grid.Column>
                                        Phone : <span className="normalBlack">{this.state.referring.phone}</span>
                                    </Grid.Column>
                                    <Grid.Column>
                                        Fax : <span className="normalBlack">{this.state.referring.fax}</span>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Grid.Row columns={1} className="margin-zero">
                                <Grid.Column>
                                    <div>
                                        {this.state.doctors.doctor_order_expire}
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                            {get_doctors_order_visit(this.state.doctors)}
                        </li>
                        <li>
                            <Grid.Row columns={1} className="margin-zero">
                                <Grid.Column>
                                    <div>
                                        {this.state.pre_auth.authorization_expire}

                                    </div>
                                </Grid.Column>
                            </Grid.Row>

                            {get_pre_auth_visit(this.state.pre_auth)}
                            <Grid.Row columns={1} className="margin-zero">
                                <Grid.Column>
                                    Auth# : <span className="normalBlack" style={{ wordWrap: 'break-word', whiteSpace: "normal" }}>{(this.state.pre_auth.pre_auth_number && this.state.pre_auth.pre_auth_number != null) ? this.state.pre_auth.pre_auth_number : global_constant.constants.not_specified}</span>
                                </Grid.Column>
                            </Grid.Row>

                        </li>
                        <li>
                            <Grid.Row columns={1} className="margin-zero">
                                <Grid.Column>
                                    <div>
                                        {this.state.eligibility.eligibility_expire}
                                    </div>
                                    <div>
                                        {this.state.eligibility.comment}
                                    </div>
                                    <div>
                                        {(this.state.doctors.multiple_entry || this.state.pre_auth.multiple_entry || this.state.eligibility.multiple_entry) ? global_constant.constants.multiple_entries : " "}
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </li>
                    </ul>
                </Popup.Content>
            </Popup>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        get_doctors_order_info: get_doctors_order_info
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        shared_details: state.shared_details,
        doctors_order: state.patient_details.doctors_order
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientInformationIconComponent)