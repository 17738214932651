import * as global_constants from '../../../global_constants';
import { BATCH_REFERENCE_DATA } from '../../../payment/payment_constants';
import { constant} from './../batch_payment_constants'
import { log_error } from '../../../shared/action/shared_action';
import { RequestConfig } from './../../../shared/network/config';
import { Request } from './../../../shared/network/request';

// get references for batch payments
export const get_references = (patient_id, token, pageType) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.batch_payements.references}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            patient_id: patient_id,
            transaction_type: pageType
        }
    })
    return dispatch =>
        request.make(request_config).then(
            response => dispatch({
                type: BATCH_REFERENCE_DATA,
                payload: response
            }),
            error => {
                log_error(error)
            }
        );
    //return request.make(request_config);

}

// search batch payments
export const search_batch_payments = (params, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.batch_payements.search}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': params
    })

    return request.make(request_config);

}

// search batch payments
export const get_adjustment_code = (token) => {
    const url = `${global_constants.base_end_points}`+'/constant/adjustment_code/search?adjustment_code=&adjustment_desc=&show_inactive=false';
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': {
            show_inactive: false
        }
    })

    return request.make(request_config);

}
// quick search referring_source by keywords
export const adjustment_code_search = (param, token) => {
    const url = `${global_constants.base_end_points}${constant.adjustment_search}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': param
    })

    return request.make(request_config);
}

// save batch payments
export const save_batch_payments = (params, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.payment.batch_payements.save}`;
    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': params
    })

    return request.make(request_config);

}

