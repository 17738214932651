import * as global_constants from '../../global_constants';
import { log_error } from '../../shared/action/shared_action';
import * as user_constants from '../user_constants';
import { RequestConfig } from './../../shared/network/config';
import { Request } from './../../shared/network/request';
import axios, { AxiosRequestConfig } from 'axios';
import { task_icon_config } from '../../global_constants';
import { create_endpoint } from '../../shared/utility';

// Get user Details : Manage My Account
export const get_user_data = (id,token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.user_urls.user_details}${id}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return dispatch => request.make(request_config).then(
            response => dispatch({
                type: user_constants.GET_USER_DETAILS,
                    payload: response
                }),
                error => {
                    log_error(error)
                }
        )

}

// Update User Details : Manage My Account
export const update_user_data = (id, token, user) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.user_urls.user_details}${id}`;

    let user_data = user;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': user
    })

    return dispatch => request.make(request_config).then(
                response => {
                    if (response.data && response.data.data) {
                        response.data['user_data'] = user_data
                    }
                    dispatch({
                        type: user_constants.UPDATE_USER_DETAILS,
                        payload: response
                    })
                },
                error => {
                    log_error(error)
                }
            )
  
}

// Update Password : Manage My Account
export const update_user_password = (token, data) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.user_urls.change_password}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': data
    })
    return dispatch => request.make(request_config).then(
                response => dispatch({
                    type: user_constants.UPDATE_USER_PASSWORD,
                    payload: response
                }),
                error => {
                    log_error(error)
                }
        )
}


export const ip_registartion_request = (token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.user_urls.ip_registration}`;

    var data = "unique_registration_id=" + token;

    let request = new Request();
    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post',
        'data': data
    })

    return dispatch => request.make(request_config).then(
                response => dispatch({
                    type: user_constants.USER_IP_REGISTER,
                    payload: response
                }),
                error => {
                    log_error(error)
                }
        )
}

export const have_default_access = (userid,token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.user_urls.user_details}${userid}${global_constants.end_points.user_urls.has_default_company_access}`;

    let request = new Request();
    let headers = {
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(request_config)
}

// Get get admin constant company detail
export const admin_constant_company_detail = (id, admin_constant, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.user_urls.user_details}${global_constants.end_points.user_urls.admin_constant_company_detail}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': { id, admin_constant }
    })

    return request.make(request_config);
}

export const user_tasks_sso_authentication = (userid) => {
    const url = `${global_constants.base_end_points}/user_tasks_sso_authentication/${userid}`;

    let request = new Request();
    let headers = {
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'post'
    })
    return  request.make(request_config);
}

// Get the list of all the companies
export const user_companies = (include_all, token) => {
  const url = `${global_constants.base_end_points}${global_constants.end_points.user_urls.user_details}${global_constants.end_points.user_urls.user_companies}`;
  let request = new Request();
  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + token,
  }

  let request_config = new RequestConfig({
    'url': url,
    'headers': headers,
    'method': 'get',
    'params': { include_all }
  })

  return dispatch => request.make(request_config).then(
    response => dispatch({
      type: user_constants.USER_COMPANIES,
      payload: response
    }),
    error => {
      log_error(error)
    }
  )
}

// Get the list of all the parent companies
export const user_parent_companies = (software_only, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.company_urls.company}${global_constants.end_points.company_urls.parent_companies}`;
    let request = new Request();
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    }

    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get',
        'params': { software_only }
    })

    return dispatch => request.make(request_config).then(
        response => dispatch({
            type: user_constants.USER_PARENT_COMPANIES,
            payload: response
        }),
        error => {
            log_error(error)
        }
    )
}

export const get_user_type_for_roles = (token, params) => {
  const url = create_endpoint('user_urls', ['base', 'get_user_type_for_roles']);
  let queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
  let request = new Request();
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token
  };

  let request_config = new RequestConfig({
    url: url + '?' + queryString,
    headers: headers,
    method: 'get'
  });
  return request.make(request_config);
};

export const get_service_type = (token) => {
  const url = create_endpoint('user_urls', ['base', 'service_type']);
  let request = new Request();
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token
  };

  let request_config = new RequestConfig({
    url,
    headers: headers,
    method: 'get'
  });
  return request.make(request_config);
};


//Verify if the user has EMR permission set for Analytics app access.
export const get_sso_user_emr_permissionset = (billing_user_id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.user_urls.emr_permissionset}/${billing_user_id}`;
    let request = new Request();
    let headers = {}
    let request_config = new RequestConfig({
        'url': url,
        'headers': headers,
        'method': 'get'
    })
    return request.make(request_config);
}