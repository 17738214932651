import * as moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { toastr as toaster } from "react-redux-toastr";
import { bindActionCreators } from "redux";
import { Button, Dimmer, Form, Grid, Input, Loader } from "semantic-ui-react";
import GridView from "../../../shared/component/grid/index";
import SelectionComponent from "../../../shared/component/selection_component";
import ReportFooter from "../../../reports/component/report_footer";
import ReportHeader from "../../../reports/component/report_header";
import { multi_grid_export, multi_grid_print, generateAuditDataEntryPayload } from "../../../reports/util/export_print_utility";
import { log_error } from "../../../shared/action/shared_action";
import * as session_storage from "../../../shared/session_storage_utility";
import { get_all_error, get_columns, strip, get_lauch_darkley_key_value } from "../../../shared/utility";
import { set_focus_to_app_header } from "../../../shared/tab_navigation_utility";
import { get_batch_tasks, get_batch_tasks_programs, update_batch_tasks_status } from "../action/batch_tasks_action";
import {
    batch_tasks_configuration,
    batch_tasks_status_on_success_display
} from "../batch_tasks_constants";
import {
     currencyFormatter, 
     dateFormatter, 
     messages, 
     report_ledger_full_configuration_header_id, 
     dateTimeFormatter, 
     ReportEventAction,
     ReportEntityTypeId, 
     IPayloadForBillingAudit,
     saveAuditPrintExport
} from "../../../reports/report_constants";
import { show_html_content_toaster, get_title, get_sub_title } from '../utility';

export class BatchTasksComponent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            is_grid_visible: false,
            is_program_grid_visible: false,
            loading: true,
            loading_programs: true,
            is_print_disabled: true,
            is_export_disabled: true,
            is_process_disabled: true,
            is_update_disabled: true,
            is_save_button_disabled: false,
            report_data: [],
            current_date: new Date(),
            row_params: {},
            charge_audit_footer_obj: {},
            report_rows_count: 0,
            grid_params: null
        };
    }

    R4ToR6Route: any = [
        {
            has_parameter: false,
            parameters: null,
            r4_route: "ReportSelection.aspx",
            r6_route: "/report/sub_report_data?param"
        }
    ];
    report_container: any;
    patient_name: string;
    account_no: string;
    current_date: string;
    title: string;
    total_column_width: number = 0;
    grid_header_height: number = 0;
    total_column_width_program: number = 0;
    grid_header_height_program: number = 0;
    is_initial_result = false;
    last_col_width = 0;
    grid_width = 0;
    grid_width_program = 0;
    token: any = "";
    is_mounted = false;
    is_submitted: boolean;
    selected_batch_tasks: any = [];
    grid_header_program_height: number = 0;
    batch_tasks = batch_tasks_configuration(this.R4ToR6Route, this.props);
    batch_tasks_column_def: any = [];
    program_execution_column_def: any = [];
    report_data: any = {
        rows: [],
        program_execution_rows: []
    };

    // Fetching the report id and params.
    UNSAFE_componentWillMount = () => {
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.batch_tasks_column_def = this.batch_tasks.batch_tasks_column_defs;
        this.program_execution_column_def = this.batch_tasks.program_execution_column_defs;
    };

    componentDidMount = async () => {
        this.is_mounted = true;
        document.body.classList.add("reports");
        this.get_report_data();
        this.get_program_execution();
    };

    componentWillUnmount = () => {
        document.body.classList.remove("reports");
        this.is_mounted = false;
        this.report_data = [];
    };


    // Get program execution data
    get_program_execution = async () => {
        this.grid_width_program = document.getElementsByClassName("report-wrapper")[0].clientWidth;
        if (this.is_mounted) {
            this.setState({
                loading_programs: true,
                is_program_grid_visible: false,
                is_process_disabled: true,
                is_update_disabled: true
            });
        }
        await get_batch_tasks_programs(this.token).then(
            (response) => {
                if (response.data && response.data.data) {
                    response.data.data = this.row_data_formatter(response.data.data, this.program_execution_column_def);
                    this.report_data["program_execution_rows"] = response.data.data || [];
                    this.total_column_width_program = 0;
                    const grid_height = get_columns(this.report_data["program_execution_rows"], this.program_execution_column_def);
                    this.grid_header_height_program = grid_height.header_height;
                    this.total_column_width_program = grid_height.total_column_width;
                    if (this.is_mounted) {
                        this.setState({
                            loading_programs: false,
                            report_data: this.report_data,
                            is_process_disabled: false,
                            is_update_disabled: false,
                            is_program_grid_visible: true
                        });
                    }
                } else {
                    log_error(response.data.messages[0].message.indexOf("<br") > -1);
                    if (response.data.messages[0].message.indexOf("<br") > -1) {
                        const toastr_options = show_html_content_toaster(get_all_error(response.data.messages[0].message));
                        toaster.error("", toastr_options);
                    }
                    if (this.is_mounted) {
                        this.setState({
                            loading_programs: false,
                            is_process_disabled: true,
                            is_update_disabled: true
                        });
                    }
                }
            },
            (error) => {
                if (this.is_mounted) {
                    this.setState({
                        loading_programs: false,
                        is_process_disabled: true,
                        is_update_disabled: true
                    });
                }
                log_error(error);
                if (error.response.data) {
                    const toastr_options = show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error("", toastr_options);
                }
            }
        );
    };

    // Get Report Data.
    get_report_data = async () => {
        this.grid_width = document.getElementsByClassName("report-wrapper")[0].clientWidth;
        this.selected_batch_tasks = [];//clear data selection
        if (this.is_mounted) {
            this.setState({
                loading: true,
                is_grid_visible: false,
                is_save_button_disabled: true
            });
        }
        await get_batch_tasks(this.token).then(
            (response) => {
                if (response.data && response.data.data) {
                    response.data.data = this.row_data_formatter(response.data.data, this.batch_tasks_column_def);
                    this.report_data["rows"] = response.data.data || [];
                    this.total_column_width = 0;
                    const grid_height = get_columns(this.report_data["rows"], this.batch_tasks_column_def);
                    this.grid_header_height = grid_height.header_height;
                    this.total_column_width = grid_height.total_column_width;
                    if (this.is_mounted) {
                        this.setState({
                            loading: false,
                            report_data: this.report_data,
                            is_grid_visible: true,
                            is_export_disabled: false,
                            is_print_disabled: false,
                            report_rows_count: this.report_data["rows"] && this.report_data["rows"].length,
                            is_save_button_disabled: false
                        });
                    }
                } else {
                    log_error(response.data.messages[0].message.indexOf("<br") > -1);
                    if (response.data.messages[0].message.indexOf("<br") > -1) {
                        const toastr_options = show_html_content_toaster(get_all_error(response.data.messages[0].message));
                        toaster.error("", toastr_options);
                    }
                    if (this.is_mounted) {
                        this.setState({
                            loading: false,
                            is_save_button_disabled: false
                        });
                    }
                }
            },
            (error: { response: { data: any } }) => {
                if (this.is_mounted) {
                    this.setState({
                        loading: false,
                        is_save_button_disabled: false
                    });
                }
                log_error(error);
                if (error.response.data) {
                    const toastr_options = show_html_content_toaster(get_all_error(error.response.data));
                    toaster.error("", toastr_options);
                }
            }
        );
    };

    // FUnction use to format the grid data on load.
    row_data_formatter = (row_data, column_def) => {
        row_data.map((row) => {
            column_def.map((value, index) => {
                switch (value.type) {
                    case "date":
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined ? dateFormatter(row[value.field]) : "";
                        break;
                    case "datetime":
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined ? dateTimeFormatter(row[value.field]) : "";
                        break;
                    case "currency":
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined
                                ? currencyFormatter(row[value.field])
                                : currencyFormatter(0);
                        break;
                    case "number":
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined ? parseInt(row[value.field]) : 0;
                        break;
                    case "hyper_link":
                        row[`link_${value.field}`] = strip(row[value.field]);
                        break;
                    case "custom_enum":
                        if (value.field == "batch_type" || value.field == "program_type") {
                            row[value.field] = row[value.field];
                        }
                        if (value.field == "status") {
                            row[value.field] = row[value.field];
                        }
                        break;
                    default:
                        row[value.field] = row[value.field] != null || row[value.field] != undefined ? row[value.field] : "";
                }
                if (value.field == "status" && row[value.field] === "Payer ID Not Found") {
                }
            });

            return row;
        });
        return row_data;
    };

    // Function calls on intialization of Ag-Grid and catch its reference.
    get_grid_ref = (grid_params) => {
        if (this.is_mounted) {
            this.setState({
                grid_params,
                report_rows_count: grid_params.api.getModel().getRowCount()
            });
        }
        if (this.total_column_width > this.grid_width) {
            this.batch_tasks_column_def.filter((item, index) => {
                grid_params.columnApi.setColumnWidth(item.field, item["width"], false);
            });
        }
    };

    get_grid_ref_programs = (grid_params) => {
        if (this.is_mounted) {
            this.setState({
                grid_program_params: grid_params
            });
        }
        if (this.total_column_width_program > this.grid_width_program) {
            this.program_execution_column_def.filter((item, index) => {
                grid_params.columnApi.setColumnWidth(item.field, item["width"], false);
            });
        }
    };

    // Used to create the title for export and print.
    set_title = (separter) => {
        this.current_date = moment(new Date()).format("MM/DD/YYYY") + " " + moment(new Date()).format("hh:mm:ss A");
        return (this.title =
            "Batch Tasks" + separter + this.current_date + separter +
            (this.state.report_rows_count == 1
                ? this.state.report_rows_count + " record"
                : this.state.report_rows_count + " records")
            + separter + separter);
    };

    // Function calls on initialization of export report data
    on_export_button = () => {
        let row_count = this.state.grid_params.api.rowModel.rowsToDisplay.length;
        let count = row_count == 1 ? row_count + ' record' : row_count + ' records';
        this.title = get_title('Batch Tasks', '\n', count);
        let row_count_program = this.state.grid_program_params.api.rowModel.rowsToDisplay.length;
        let count_program = row_count_program == 1 ? row_count_program + ' record' : row_count_program + ' records';
        let sub_title = get_sub_title('\n\nProgram Execution', '\n', count_program);

        let title = [this.title, sub_title];
        let grid_params = [this.state.grid_params, this.state.grid_program_params];
        multi_grid_export(grid_params, title, "Batch Tasks", ["checkbox", "batch_task_id", "batch_program_id"]);
        grid_params = [];
        this.saveReportEventAction(ReportEventAction.Export);
    };

    // Function calls on initialization of Print report data
    on_print_button = () => {
        let row_count = this.state.grid_params.api.rowModel.rowsToDisplay.length;
        let count = row_count == 1 ? row_count + ' record' : row_count + ' records';
        this.title = get_title('Batch Tasks', '<br/>', count);
        let row_count_program = this.state.grid_program_params.api.rowModel.rowsToDisplay.length;
        let count_program = row_count_program == 1 ? row_count_program + ' record' : row_count_program + ' records';
        let sub_title = get_sub_title('<br/><br/>Program Execution', '<br/>', count_program);

        let title = [this.title, sub_title];
        let grid_params = [this.state.grid_params, this.state.grid_program_params];
        multi_grid_print(grid_params, title, "Batch Tasks", ["checkbox", "batch_task_id", "batch_program_id"]);
        grid_params = [];
        this.saveReportEventAction(ReportEventAction.Print);
    };

    // Function calls save billing audit on Export or Print
    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;

        const payload: IPayloadForBillingAudit = {
            reportId: String(this.props.user_login_details.user_data.data.user_id),
            contextTitle: `${this.batch_tasks.title} - ${ReportEventAction[eventActionId]}`,
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: ReportEntityTypeId.bat_task,
            data: { Records: this.state.grid_params.api.rowModel.rowsToDisplay.length }
        };

        const reqBody = generateAuditDataEntryPayload(payload);

        saveAuditPrintExport(reqBody, accessToken);
    }

    handle_navigation_after_last_element = (event) => {
        // apply a check where grid is empty
        if (
            !event.shiftKey &&
            event.keyCode == "9" &&
            (this.state.report_data == null || this.state.report_data.length == 0)
        ) {
            set_focus_to_app_header(event);
        }
    };

    get_status = (status) => {
        switch (status) {
            case "resume":
                return 1;
            case "suspend":
                return 2;
            case "cancel":
                return 3;
        }
    };

    update_status = (status) => {
        if (this.selected_batch_tasks && this.selected_batch_tasks.length > 0) {
            let status_id = this.get_status(status);
            let task_ids = this.selected_batch_tasks.map((task, index) => {
                return task["batch_task_id"];
            });

            let data = { "task_status": status_id, "task_ids": task_ids };

            update_batch_tasks_status(this.token, data).then(
                (response) => {
                    if (response.data && response.data.data) {
                        this.get_report_data();
                        toaster.success("", "The selected tasks have been " + batch_tasks_status_on_success_display[status_id] + ".");
                    } else {

                        log_error(response.data.messages[0].message.indexOf("<br") > -1);
                        if (response.data.messages[0].message.indexOf("<br") > -1) {
                            const toastr_options = show_html_content_toaster(get_all_error(response.data.messages[0].message));
                            toaster.error("", toastr_options);
                        }
                    }
                },
                (error) => {
                    log_error(error);
                    if (error.response.data) {
                        const toastr_options = show_html_content_toaster(get_all_error(error.response.data));
                        toaster.error("", toastr_options);
                    }
                }
            );
        } else {
            toaster.error('', this.batch_tasks.selection_error);
        }
    }

    // Batch task grid row selection callback function
    on_check_box_selection = (items) => {
        this.selected_batch_tasks = items;
    };

    render() {
        return (
            <div className={"common-forms-add"}>
                <Dimmer active={this.state.loading || this.state.loading_programs}>
                    <Loader size="massive">Loading</Loader>
                </Dimmer>
                <div className={"common-forms-search report-framework batch-tasks-program"} style={{ paddingRight: 0 }}>
                    <ReportHeader title={this.batch_tasks.title} />
                    <div style={{ overflow: "auto", paddingRight: 20, flex: "1 1 0" }}>
                        <div
                            id="report-scrollable-area"
                            className="report-wrapper"
                            style={this.state.is_grid_visible ? { flex: 1 } : {}}
                        >
                            {this.state.is_grid_visible && (
                                <React.Fragment>
                                    <GridView
                                        id={this.batch_tasks.report_grid_id}
                                        row={this.report_data.rows}
                                        column={this.batch_tasks_column_def}
                                        checkboxSelection={true}
                                        headerHeight={this.grid_header_height}
                                        enableColResize={true}
                                        emptyMessage={messages.no_records_found}
                                        get_grid_ref={this.get_grid_ref}
                                        headerIdForTabNavigation={report_ledger_full_configuration_header_id}
                                        suppressSizeToFit={true}
                                        gridAutoHeight={true}
                                        onRowSelection={this.on_check_box_selection}
                                    />

                                </React.Fragment>
                            )}
                        </div>
                        {this.state.is_grid_visible &&
                            (<Grid style={{ margin: '0 -17px' }}>
                                <Grid.Column computer={16} textAlign='right'>
                                    <Button id='cancel_button_id' type='button'
                                        onClick={() => this.update_status("cancel")} basic>Cancel</Button>
                                    <Button id='resume_button_id' type='button'
                                        onClick={e => this.update_status("resume")} basic>Resume</Button>
                                    <Button id='suspend_button_id' type='submit'
                                        onClick={e => this.update_status("suspend")} primary>Suspend</Button>
                                </Grid.Column>
                            </Grid>)
                        }
                        {this.state.is_program_grid_visible && (
                            <React.Fragment>
                                <div style={{ border: " 1px solid #cccccc", background: " #fff", marginTop: 20 }}>
                                    <div className="ui grid headerGrid" style={{ margin: 0, paddingTop: 5 }}>
                                        <div className="sixteen wide computer column">
                                            <h3 className="ui header left aligned">Program Execution</h3>
                                        </div>
                                    </div>
                                    <GridView
                                        id={this.batch_tasks.program_execution_id}
                                        row={this.report_data.program_execution_rows}
                                        column={this.program_execution_column_def}
                                        headerHeight={this.grid_header_height_program}
                                        enableColResize={true}
                                        emptyMessage={messages.no_records_found}
                                        get_grid_ref={this.get_grid_ref_programs}
                                        headerIdForTabNavigation={report_ledger_full_configuration_header_id}
                                        suppressSizeToFit={true}
                                        gridAutoHeight={true}
                                        paginationPageSize={10}
                                        isPagination={true}
                                        paginationMessage={' '}
                                    />
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                    <div
                        className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area"
                        id="applicationFooterSticky"
                        style={{ paddingRight: 25 }}
                    >
                        <ReportFooter
                            on_print_button={this.on_print_button}
                            on_export_button={this.on_export_button}
                            export_disabled={this.state.is_export_disabled}
                            print_disabled={this.state.is_print_disabled}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
        },
        dispatch
    );
};
// Get user and login details from store.
const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        selected_patient: state.patient_details.patient_header,
        is_expand: state.shared_details.is_expand
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchTasksComponent);