import * as moment from 'moment';
import * as React from 'react';
import * as NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { bindActionCreators } from 'redux';
import { Button, Form, Grid } from 'semantic-ui-react';
import * as global_constants from '../../global_constants';
import CurrencyFormatter from '../../shared/component/currency_formatter';
import DatePicker from '../../shared/component/date_picker_component';
import { add_eligibility } from './../../case/action/subscriber_action';
import { custom_date_format, date_format_with_no_time, date_format_with_no_time_return_string_or_null, is_firefox, is_null_or_white_space } from './../../shared/utility';
import { enum_case_page_state, enum_type_of_insurance } from './../case_constants';
import CheckboxComponent from "./../../shared/component/checkbox_component"

// Add Edit and view Insurance Eligibility details
//Define the state with their data types
interface IProps {
    insurance_type: string
    form_submitted: boolean,
    case_id: number,
    user_login_details: any,
    subscriber_data: any,
    page_state: string,
    onRef?: any,
    subscriber_fetched_time: any
}

interface IState {
    show_inactive_eligibility: boolean,
    subscriber: any,
    eligibility_list: any,
    page_state: enum_case_page_state,
    eligibility: {
        amount_to_collect: number,
        authorization_required: boolean,
        co_insurance_percent: number,
        comments: string,
        contact_name: string,
        dme_benefits: string,
        effective_date?: Date,
        formated_effective_date?: Date,
        excluded_conditions: string,
        id: number,
        in_network_copay?: number,
        in_network_deduct?: number,
        in_or_out_of_network?: string,
        is_active?: boolean,
        max_calender_benefits: string,
        max_out_patient_benefits: string,
        out_network_copay?: number,
        out_network_deduct?: number,
        payer_comment: string,
        per_visit_limitation?: number,
        referral_required?: boolean,
        separate_copay?: boolean,
        separate_copay_amount?: string,
        subscriber_id?: number,
        termination_date?: Date,
        formated_termination_date?: Date,
        ytd_deductible_met?: number,
        is_show_active: boolean
    },
    insurance_type: string
    form_submitted?: boolean,
    case_id: number,
    initial_eligibility: any
}

export class InsuranceEligibilityComponent extends React.Component<any, any> {
    _is_mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            show_inactive_eligibility: false,
            subscriber: this.props.subscriber_data,
            eligibility_list: [],
            insurance_type: this.props.insurance_type,
            form_submitted: this.props.form_submitted,
            case_id: this.props.case_id,
            page_state: this.props.page_state,
            eligibility: {
                amount_to_collect: 0,
                authorization_required: false,
                co_insurance_percent: null,
                comments: '',
                contact_name: '',
                dme_benefits: '',
                effective_date: null,
                excluded_conditions: '',
                id: null,
                in_network_copay: 0,
                in_network_deduct: 0,
                in_or_out_of_network: null,
                is_active: false,
                max_calender_benefits: null,
                max_out_patient_benefits: '',
                out_network_copay: 0,
                out_network_deduct: 0,
                payer_comment: '',
                per_visit_limitation: null,
                referral_required: false,
                separate_copay: null,
                separate_copay_amount: 0,
                subscriber_id: null,
                termination_date: null,
                ytd_deductible_met: 0,
                is_show_active: true
            },
            initial_eligibility: null
        }
    }
    ins_type_id = ''
    componentDidMount() {
        this._is_mounted = true;
        if (this.props.onRef) {
            this.props.onRef(this);
        }        
    }
    UNSAFE_componentWillMount() {

        if (this.props.onRef) {
            this.props.onRef(this);
        }
        const { eligibility } = this.state
        let { initial_eligibility } = this.state
        initial_eligibility = Object.assign({}, eligibility)
        this.setState({ initial_eligibility })
    }
    componentWillUnmount() {
        this._is_mounted = false;
        if (this.props.onRef) {
            this.props.onRef(undefined)
        }
    }
    componentDidUpdate(previousProps, previousState) {

        if (previousProps.subscriber_data.id !== this.props.subscriber_data.id) {
            this.get_eligibility_data_from_props();
        } else if (previousProps.page_state != this.props.page_state) {
            this.get_eligibility_data_from_props();
        }
        else if (previousProps.subscriber_fetched_time != this.props.subscriber_fetched_time) {
            this.get_eligibility_data_from_props();
        }
    }

    check_active_eligibility = (eligibility_data) => {
        return eligibility_data.filter(function (row, index) {
            return (row.is_active == true);
        });
    }

    get_eligibility_data_from_props = () => {
        var subscriber_data = Object.assign({}, this.props.subscriber_data);
        var eligibility_data = Object.assign([], subscriber_data.subscriber_eligibility);
        for (let item of eligibility_data) {
            if (!(item.effective_date == '' || item.effective_date == null || item.effective_date == undefined)) {
                item.formated_effective_date = moment(item.effective_date);
            }
            if (!(item.termination_date == '' || item.termination_date == null || item.termination_date == undefined)) {
                item.formated_termination_date = moment(item.termination_date);
            }
            item.co_insurance_percent = Number.parseFloat(item.co_insurance_percent).toFixed(1)
            item.is_show_active = item.is_active
        }
        if (this._is_mounted) {
            this.setState({
                subscriber: subscriber_data,
                eligibility_list: [...eligibility_data],
                form_submitted: this.props.form_submitted,
                page_state: this.props.page_state,
                show_inactive_eligibility: false,
            });
        }
    }

    show_inactive_eligibility_change = (e, data) => {
        if (this._is_mounted) {
            this.setState({
                show_inactive_eligibility: !this.state.show_inactive_eligibility
            });
        }
    }

    add_eligibility = () => {               
        const new_eligibility = Object.assign({}, this.state.eligibility);
        const eligibility_data = Object.assign([], this.state.eligibility_list);
        new_eligibility.is_active = true;
        new_eligibility.is_show_active = true;
        new_eligibility.id = 0;
        new_eligibility.subscriber_id = this.props.subscriber_data && this.props.subscriber_data.id ? this.props.subscriber_data.id : 0;
        eligibility_data.push(new_eligibility);
        if (this._is_mounted) {
            this.setState({
                eligibility_list: eligibility_data
            }, () => {                
                let elem = document.getElementById(`${this.ins_type_id}_txt_contact_name_${eligibility_data.length - 1}`);
                elem.focus();
            });
        }
    }

    on_change_eligibility = (evt, obj) => {
        const { eligibility_list } = this.state
        const { name } = evt.target
        let { value } = evt.target
        if (name == "per_visit_limitation" && (value.indexOf('.') > -1 || value.length > 9)) {
            return true
        }
        //bank space validation: Not allow blank spaces while input
        else if (name == "per_visit_limitation" && value.length > 0 && value.match(/[^0-9]/g) && value.match(/[^0-9]/g).join("") != null) {
            return;
        }
        if (name == "per_visit_limitation") {
            value = evt.target.validity.valid
                ? Number(evt.target.value) <= 2147483647
                    ? evt.target.value
                    : eligibility_list[obj.idx][name]
                : eligibility_list[obj.idx][name];
        }
        eligibility_list[obj.idx][name] = value
        if (this._is_mounted) {
            this.setState({
                eligibility_list
            });
        }
    }
    on_change_eligibility_checkbox = (evt, idx, checked) => {
        const { eligibility_list } = this.state
        const { name } = evt.target
        eligibility_list[idx][name] = checked
        if (this._is_mounted) {
            this.setState({
                eligibility_list
            });
        }
    }

    on_change_eligibility_radio = (evt,value, name, idx) => {
        evt.persist()
        evt.stopPropagation()
        const { eligibility_list } = this.state
        let ins_type_id = this.state.insurance_type === enum_type_of_insurance.primary_insurance ? "primary_insurance" : (this.state.insurance_type === enum_type_of_insurance.secondary_insurance ? "secondary_insurance" : "tertiary_insurance");
        let checked;
        const id = `${ins_type_id}_eligibility_${name}_${idx}`
        // const { value } = evt.target
        if (name == 'in_network' && value == 1 || name == 'out_network' && value == 1) {
            checked = null
        }
        if (name == 'in_network' && value == 0) {
            checked = true
        }
        if (name == 'out_network' && value == 0) {
            checked = false
        }

        eligibility_list[idx].in_or_out_of_network = checked
        if (this._is_mounted) {
            this.setState({
                eligibility_list
            });
        }

    }

    handle_calendar_change = (idx, name, dt) => {
        const { eligibility_list } = this.state;
        eligibility_list[idx][name] = date_format_with_no_time(dt);
        if (this._is_mounted) {
            this.setState({
                eligibility_list
            });
        }
    }

    amount_change = (value, name, index) => {
        let amount_fields = ["amount_to_collect", "in_network_copay", "out_network_copay", "separate_copay_amount"]
        const { eligibility_list } = this.state
        if (name == "co_insurance_percent") {
            eligibility_list[index][name] = value.value;
        } else {
            let num_value = Number(value)
            let int_value = parseInt(value)
            let float_value = num_value - int_value
            if (amount_fields.indexOf(name) >= 0 && num_value > 0 && value.length > 6) {
                value = value.substring(0, 3)
                value = parseInt(value) + float_value;
            }
            eligibility_list[index][name] = value;
        }
        if (this._is_mounted) {
            this.setState({
                eligibility_list
            });
        }
    }

    on_delete_eligibility = (index) => {
        let data = Object.assign([], this.state.eligibility_list);
        if (data[index].id == 0) {
            data.splice(index, 1)
        }
        if (this._is_mounted) {
            this.setState({
                eligibility_list: [...data]
            }, () => {
                if (data.length > 0) {
                    let elem = document.getElementById(`${this.ins_type_id}_txt_contact_name_${data.length - 1}`);
                    elem.focus();
                }                
            });
        }

    }

    get_updated_eligibility_list = () => {
        let { eligibility_list, initial_eligibility } = this.state
        const arrayData = []
        for (let row of eligibility_list) {
            if (row.id > 0
                || (row.id == 0
                    && ((row.amount_to_collect != "" && row.amount_to_collect != null)
                        || (row.co_insurance_percent != 0 && row.co_insurance_percent != null)
                        || row.comments.trim().length > 0
                        || row.contact_name.trim().length > 0
                        || row.dme_benefits.trim().length > 0
                        || row.formated_effective_date != null
                        || row.excluded_conditions.trim().length > 0
                        || (row.in_network_copay != "" && row.in_network_copay != null)
                        || (row.in_network_deduct != "" && row.in_network_deduct != null)
                        || row.in_or_out_of_network != null
                        || (row.max_calender_benefits != null && row.max_calender_benefits.trim().length > 0)
                        || row.max_out_patient_benefits.trim().length > 0
                        || (row.out_network_copay != "" && row.out_network_copay != null)
                        || (row.out_network_deduct != "" && row.out_network_deduct != null)
                        || (row.payer_comment.trim().length > 0 && row.payer_comment != null)
                        || (row.per_visit_limitation != 0 && row.per_visit_limitation != null)
                        || row.referral_required
                        || row.authorization_required
                        || (row.separate_copay != false && row.separate_copay != null)
                        || (row.separate_copay_amount != "" && row.separate_copay_amount != null)
                        || row.formated_termination_date != null
                        || (row.ytd_deductible_met != "" && row.ytd_deductible_met != null)
                    ))) {
                row.pst_coverage = this.state.insurance_type === enum_type_of_insurance.primary_insurance ? "P" : (this.state.insurance_type === enum_type_of_insurance.secondary_insurance ? "S" : "T");
                row.co_insurance_percent = row.co_insurance_percent !== null ? Math.round(row.co_insurance_percent) : row.co_insurance_percent;
                row.effective_date = row.formated_effective_date == null ? null : date_format_with_no_time_return_string_or_null(row.formated_effective_date);
                row.termination_date = row.formated_termination_date == null ? null : date_format_with_no_time_return_string_or_null(row.formated_termination_date);
                row.separate_copay_amount = row.separate_copay_amount == "" ? null : row.separate_copay_amount;
                row.in_network_copay = row.in_network_copay == "" ? null : row.in_network_copay;
                row.in_network_deduct = row.in_network_deduct == "" ? null : row.in_network_deduct;
                row.amount_to_collect = row.amount_to_collect == "" ? null : row.amount_to_collect;
                row.out_network_copay = row.out_network_copay == "" ? null : row.out_network_copay
                row.out_network_deduct = row.out_network_deduct == "" ? null : row.out_network_deduct
                row.ytd_deductible_met = row.ytd_deductible_met == "" ? null : row.ytd_deductible_met
                arrayData.push(row)
            }
        }
        if (this._is_mounted) {
            this.setState({
                eligibility_list: [...arrayData],
                show_inactive_eligibility: false
            });
        }
        return arrayData;
    }

    is_list_empty = () => {
        return this.get_updated_eligibility_list().length > 0 ? false : true
    }

    on_save_eligibility = (case_id, subscriber_id, eligibility_list) => {
        if (eligibility_list && eligibility_list.length > 0) {
            eligibility_list.forEach(eligibility => {
                if (eligibility && !is_null_or_white_space(eligibility.effective_date)) {
                    eligibility.effective_date = date_format_with_no_time(eligibility.effective_date);
                }

                if (eligibility && !is_null_or_white_space(eligibility.termination_date)) {
                    eligibility.termination_date = date_format_with_no_time(eligibility.termination_date);
                }
            });
        }
        return add_eligibility(this.props.user_login_details.user_data.data.token_details.access_token, case_id, subscriber_id, eligibility_list);
    }
    render() {
        let active_index = 0;
        let ins_type_id = this.state.insurance_type === enum_type_of_insurance.primary_insurance ? "primary_insurance" : (this.state.insurance_type === enum_type_of_insurance.secondary_insurance ? "secondary_insurance" : "tertiary_insurance");
        this.ins_type_id = ins_type_id
        const { eligibility_list } = this.state;
        let eligibility_list_data = []
        var active_eligibility_list = true;
        if (eligibility_list.length > 0 && this.state.show_inactive_eligibility) {
            active_eligibility_list = false
        }

        if (eligibility_list.length > 0 && !this.state.show_inactive_eligibility) {
            eligibility_list_data = eligibility_list.filter(row => row.is_show_active)
            active_eligibility_list = eligibility_list_data.length > 0 ? false : true

        }
        return (
            <Grid>
                <Grid.Column>
                    <Grid className="padd-15">
                        <Grid.Column mobile={16} tablet={16} computer={16}>
                            <p className="sub-heading-blue mar-r-15 float-left mar-b-0">{this.state.insurance_type} Eligibility</p>
                            <CheckboxComponent
                                label='Show Inactive'
                                checked={this.state.show_inactive_eligibility}
                                id={ins_type_id + "_show_inactive_eligibility"}
                                onChange={(evt, checked) => { this.show_inactive_eligibility_change(evt, checked) }}
                                disabled={this.state.is_view_mode}
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={16} className="padding-zero" id={`${this.state.insurance_type}_insurance_eligibility`}>
                            {
                                this.state.eligibility_list.map((row, index) => {
                                    if ((row.id == 0 && (row.is_active || row.is_show_active)) || (row.id > 0 && row.is_show_active) || (this.state.show_inactive_eligibility && (row.id > 0 && !row.is_show_active))) {
                                        if (row.is_active && row == 0) {
                                            row.is_show_active = row.is_active
                                        }
                                        active_index++;
                                        return (
                                            <div className="ui grid" key={index}>
                                                <Grid.Column mobile={16} tablet={16} computer={8} className="padding-zero">
                                                    <Grid>
                                                        <Grid.Column mobile={16} tablet={16} computer={16}>
                                                            <Form.Field >
                                                                <label>Contact Name</label>
                                                                <Form.Input
                                                                    fluid
                                                                    disabled={this.state.page_state === enum_case_page_state.edit}
                                                                    autoComplete="new-password" maxLength={50}
                                                                    id={ins_type_id + '_txt_contact_name_' + index}
                                                                    name="contact_name"
                                                                    value={row.contact_name || ''}
                                                                    idx={index}
                                                                    onChange={this.on_change_eligibility} />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid>
                                                    <Grid>
                                                        <Grid.Column mobile={16} tablet={8} computer={6}>
                                                            <Form.Field >
                                                                <label>Amount to Collect</label>
                                                                <CurrencyFormatter
                                                                    onChange={(values) => { this.amount_change(values, "amount_to_collect", index) }}
                                                                    prefix='$'
                                                                    id={ins_type_id + "_amount_to_collect_" + index}
                                                                    maxLength={row.amount_to_collect !== null && row.amount_to_collect.toString().includes("-") ? 13 : 7}
                                                                    defaultValue={row.amount_to_collect != null ? row.amount_to_collect : ""}
                                                                    allowNegative={true}
                                                                    disabled={this.state.page_state === enum_case_page_state.edit}
                                                                //className={this.state.error.amount ? 'red-error-thin numberFormat' : 'numberFormat'}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column mobile={16} tablet={8} computer={5}>
                                                            <Form.Field >
                                                                <label>Effective Date</label>
                                                                <DatePicker
                                                                    is_disabled={this.state.page_state === enum_case_page_state.edit}
                                                                    id="txt_effective_date"
                                                                    disabledKeyboardNavigation
                                                                    selected={row.formated_effective_date}
                                                                    maxDate={moment().format('MM/DD/YYYY')}
                                                                    showMonthDropdown dropdownMode="select"
                                                                    name="effective_date"
                                                                    onBlur={(d) => this.handle_calendar_change(index, 'formated_effective_date', d)}
                                                                    date_update={(d) => this.handle_calendar_change(index, 'formated_effective_date', d)}
                                                                    date={row.formated_effective_date}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column mobile={16} tablet={8} computer={5}>
                                                            <Form.Field >
                                                                <label>Termination Date</label>
                                                                <DatePicker
                                                                    is_disabled={this.state.page_state === enum_case_page_state.edit}
                                                                    id="txt_termination_date"
                                                                    disabledKeyboardNavigation
                                                                    selected={row.formated_termination_date}
                                                                    showMonthDropdown dropdownMode="select"
                                                                    name="termination_date"
                                                                    onBlur={(d) => this.handle_calendar_change(index, 'formated_termination_date', d)}
                                                                    date_update={(d) => this.handle_calendar_change(index, 'formated_termination_date', d)}
                                                                    date={row.formated_termination_date}
                                                                    maxLength={10}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid>
                                                    <Grid>
                                                        <Grid.Column mobile={16} tablet={8} computer={8}>
                                                            <Form.Field >
                                                                <label>In Network Copay</label>
                                                                <CurrencyFormatter
                                                                    onChange={(values) => { this.amount_change(values, "in_network_copay", index) }}
                                                                    prefix='$'
                                                                    id={ins_type_id + "_in_network_copay_" + index}
                                                                    maxLength={row.in_network_copay !== null && row.in_network_copay.toString().includes("-") ? 13 : 7}
                                                                    defaultValue={row.in_network_copay != null ? row.in_network_copay : ""}
                                                                    allowNegative={true}
                                                                    disabled={this.state.page_state === enum_case_page_state.edit}
                                                                //className={this.state.error.amount ? 'red-error-thin numberFormat' : 'numberFormat'}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column mobile={16} tablet={8} computer={8}>
                                                            <Form.Field >
                                                                <label>In Network Deductible</label>
                                                                <CurrencyFormatter
                                                                    onChange={(values) => { this.amount_change(values, "in_network_deduct", index) }}
                                                                    prefix='$'
                                                                    id={ins_type_id + "_in_network_deduct_" + index}
                                                                    maxLength={13}
                                                                    defaultValue={row.in_network_deduct != null ? row.in_network_deduct : ""}
                                                                    allowNegative={true}
                                                                    disabled={this.state.page_state === enum_case_page_state.edit}
                                                                //className={this.state.error.amount ? 'red-error-thin numberFormat' : 'numberFormat'}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid>
                                                    <Grid>
                                                        <Grid.Column mobile={16} tablet={8} computer={8}>
                                                            <Form.Field >
                                                                <label>Out of Network Copay</label>
                                                                <CurrencyFormatter
                                                                    onChange={(values) => { this.amount_change(values, "out_network_copay", index) }}
                                                                    prefix='$'
                                                                    id={ins_type_id + "_out_network_copay_" + index}
                                                                    maxLength={row.out_network_copay !== null && row.out_network_copay.toString().includes("-") ? 13 : 7}
                                                                    defaultValue={row.out_network_copay != null ? row.out_network_copay : ""}
                                                                    allowNegative={true}
                                                                    disabled={this.state.page_state === enum_case_page_state.edit}
                                                                //className={this.state.error.amount ? 'red-error-thin numberFormat' : 'numberFormat'}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column mobile={16} tablet={8} computer={8}>
                                                            <Form.Field >
                                                                <label>Out of Network Deductible</label>
                                                                <CurrencyFormatter
                                                                    onChange={(values) => { this.amount_change(values, "out_network_deduct", index) }}
                                                                    prefix='$'
                                                                    id={ins_type_id + "_out_network_deduct_" + index}
                                                                    maxLength={13}
                                                                    defaultValue={row.out_network_deduct != null ? row.out_network_deduct : ""}
                                                                    allowNegative={true}
                                                                    disabled={this.state.page_state === enum_case_page_state.edit}
                                                                //className={this.state.error.amount ? 'red-error-thin numberFormat' : 'numberFormat'}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid>
                                                    <Grid>

                                                        <Grid.Column mobile={16} tablet={8} computer={8}>
                                                            <Form.Field >
                                                                <label>Separate Copay Amount</label>
                                                                <CurrencyFormatter
                                                                    onChange={(values) => { this.amount_change(values, "separate_copay_amount", index) }}
                                                                    prefix='$'
                                                                    id={ins_type_id + "_separate_copay_amount_" + index}
                                                                    maxLength={row.separate_copay_amount !== null && row.separate_copay_amount.toString().includes("-") ? 13 : 7}
                                                                    defaultValue={row.separate_copay_amount != null ? row.separate_copay_amount : ""}
                                                                    allowNegative={true}
                                                                    disabled={this.state.page_state === enum_case_page_state.edit}
                                                                //className={this.state.error.amount ? 'red-error-thin numberFormat' : 'numberFormat'}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column mobile={16} tablet={8} computer={8}>
                                                            <Form.Field >
                                                                <label>Maximum Outpatient Benefits</label>
                                                                <Form.Input
                                                                    fluid
                                                                    id={ins_type_id + "_eligibility_max_out_patient_benefits_" + index}
                                                                    disabled={this.state.page_state === enum_case_page_state.edit}
                                                                    autoComplete="new-password"
                                                                    maxLength={50}
                                                                    value={row.max_out_patient_benefits || ''}
                                                                    name="max_out_patient_benefits"
                                                                    idx={index}
                                                                    onChange={this.on_change_eligibility}

                                                                />

                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid>
                                                    <Grid>
                                                        <Grid.Column mobile={16} tablet={8} computer={8}>
                                                            <Form.Field >
                                                                <label>Maximum Calendar Benefits</label>
                                                                <Form.Input
                                                                    fluid
                                                                    id={ins_type_id + "_eligibility_max_calender_benefits_" + index}
                                                                    disabled={this.state.page_state === enum_case_page_state.edit}
                                                                    autoComplete="new-password"
                                                                    maxLength={50}
                                                                    value={row.max_calender_benefits || ''}
                                                                    name="max_calender_benefits"
                                                                    idx={index}
                                                                    onChange={this.on_change_eligibility}
                                                                />
                                                                <br></br>
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid>
                                                </Grid.Column>

                                                <Grid.Column mobile={16} tablet={16} computer={8} className="padding-zero">
                                                    <Grid>
                                                        <Grid.Column mobile={16} tablet={8} computer={8}>
                                                            <Form.Field >
                                                                <label>Per Visit Limitation</label>

                                                                <Form.Input
                                                                    fluid
                                                                    autoComplete="off"
                                                                    name="per_visit_limitation"
                                                                    value={row.per_visit_limitation || ''}
                                                                    onChange={this.on_change_eligibility}
                                                                    idx={index}
                                                                    id={ins_type_id + "_per_visit_limitation_" + index}
                                                                    maxLength={9}
                                                                    disabled={this.state.page_state === enum_case_page_state.edit}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column mobile={16} tablet={8} computer={8}>
                                                            <Form.Field >
                                                                <label>DME Benefit</label>
                                                                <Form.Input
                                                                    fluid
                                                                    disabled={this.state.page_state === enum_case_page_state.edit}
                                                                    autoComplete="new-password"
                                                                    maxLength={50}
                                                                    id={ins_type_id + "_eligibility_dme_benefits_" + index}
                                                                    value={row.dme_benefits || ''}
                                                                    name="dme_benefits"
                                                                    idx={index}
                                                                    onChange={this.on_change_eligibility}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid>
                                                    <Grid>
                                                        <Grid.Column mobile={16} tablet={8} computer={8}>
                                                            <Form.Field >
                                                                <label>YTD Deductible Met</label>
                                                                <CurrencyFormatter
                                                                    onChange={(values) => { this.amount_change(values, "ytd_deductible_met", index) }}
                                                                    prefix='$'
                                                                    maxLength={13}
                                                                    id={ins_type_id + "_ytd_deductible_met_" + index}
                                                                    defaultValue={row.ytd_deductible_met != null ? row.ytd_deductible_met : ""}
                                                                    allowNegative={true}
                                                                    disabled={this.state.page_state === enum_case_page_state.edit}
                                                                //className={this.state.error.amount ? 'red-error-thin numberFormat' : 'numberFormat'}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column mobile={16} tablet={8} computer={8}>
                                                            <Form.Field >
                                                                <label>Co-insurance Percentage</label>
                                                                <NumberFormat
                                                                    value={row.co_insurance_percent || ''}
                                                                    maxLength="12"
                                                                    displayType="input"
                                                                    allowNegative={true}
                                                                    decimalScale={2}
                                                                    id={ins_type_id + "_eligibility_co_insurance_percent_" + index}
                                                                    disabled={this.state.page_state === enum_case_page_state.edit}
                                                                    onValueChange={(values) => { this.amount_change(values, "co_insurance_percent", index) }}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid>
                                                    <Grid>
                                                        <Grid.Column mobile={16} tablet={16} computer={16}>
                                                            <Form.Field >
                                                                <label>Excluded Conditions</label>
                                                                <Form.Input
                                                                    fluid
                                                                    maxLength={50}
                                                                    id={ins_type_id + "_eligibility_excluded_conditions_" + index}
                                                                    autoComplete="new-password"
                                                                    value={row.excluded_conditions || ''}
                                                                    disabled={this.state.page_state === enum_case_page_state.edit}
                                                                    name="excluded_conditions"
                                                                    idx={index}
                                                                    onChange={this.on_change_eligibility}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid>
                                                    <Grid>
                                                        <Grid.Column mobile={16} tablet={16} computer={16}>
                                                            <Form.Field >
                                                                <label>Comments</label>
                                                                <TextareaAutosize
                                                                    autoComplete="new-password"
                                                                    maxLength={250}
                                                                    id={ins_type_id + "_eligibility_comment_" + index}
                                                                    value={row.comments || ''}
                                                                    disabled={this.state.page_state === enum_case_page_state.edit}
                                                                    idx={index}
                                                                    name='comments'
                                                                    onChange={(evt, { props }) => { this.on_change_eligibility(evt, props) }}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid>

                                                    <Grid>
                                                        <Grid.Row>
                                                            <Grid.Column mobile={16} tablet={8} computer={9}>
                                                                <Grid>
                                                                    <Grid.Column mobile={16} tablet={16} computer={16}>
                                                                        <Form.Field >
                                                                            <CheckboxComponent
                                                                                label='Referral Required'
                                                                                checked={row.referral_required || false}
                                                                                id={ins_type_id + "_eligibility_referral_required_" + index}
                                                                                name="referral_required"
                                                                                onChange={(evt, checked) => { this.on_change_eligibility_checkbox(evt, index, checked) }}
                                                                                disabled={this.state.page_state === enum_case_page_state.edit}
                                                                            />
                                                                        </Form.Field>
                                                                    </Grid.Column>
                                                                    <Grid.Column mobile={16} tablet={16} computer={16}>
                                                                        <Form.Field>
                                                                            <CheckboxComponent
                                                                                label='Preauthorization Required'
                                                                                checked={row.authorization_required || false}
                                                                                name="authorization_required"
                                                                                id={ins_type_id + "_eligibility_authorization_required_" + index}
                                                                                onChange={(evt, checked) => { this.on_change_eligibility_checkbox(evt, index, checked) }}
                                                                                disabled={this.state.page_state === enum_case_page_state.edit}
                                                                            />
                                                                        </Form.Field>
                                                                    </Grid.Column>
                                                                    <Grid.Column mobile={16} tablet={16} computer={16}>
                                                                        <Form.Field>
                                                                            <CheckboxComponent
                                                                                label='Eligibility is Active'
                                                                                name="is_active"
                                                                                checked={row.is_active || false}
                                                                                id={ins_type_id + "_eligibility_is_active_" + index}
                                                                                onChange={(evt, checked) => { this.on_change_eligibility_checkbox(evt, index, checked) }}
                                                                                disabled={this.state.page_state === enum_case_page_state.edit}
                                                                            />
                                                                        </Form.Field>
                                                                    </Grid.Column>
                                                                </Grid>
                                                            </Grid.Column>

                                                            <Grid.Column mobile={16} tablet={8} computer={7}>
                                                                <Grid>
                                                                    <Grid.Column mobile={16} tablet={16} computer={16}>
                                                                        <Form.Field>
                                                                            <div className="ui radio">
                                                                                <input
                                                                                    type="radio"
                                                                                    value={row.in_or_out_of_network !== null && row.in_or_out_of_network ? 1 : 0}
                                                                                    checked={row.in_or_out_of_network !== null && row.in_or_out_of_network ? true : false}
                                                                                    id={ins_type_id + "_eligibility_in_network_" + index}
                                                                                    disabled={this.state.page_state === enum_case_page_state.edit}                                                                                    
                                                                                    onKeyDown={(event) => {
                                                                                        event.preventDefault();  
                                                                                        if (event.shiftKey && event.key === "Tab") {
                                                                                            let elem = document.getElementById(`${ins_type_id}_eligibility_is_active_${index}`);
                                                                                            elem.focus();
                                                                                        }
                                                                                        if ((!event.shiftKey && event.key === "Tab") || event.key == "ArrowDown" || event.key == "ArrowLeft") {                                                                                            
                                                                                            let elem = document.getElementById(`${ins_type_id}_eligibility_out_network_${index}`);
                                                                                            elem.focus();
                                                                                        }
                                                                                        if (!is_firefox() && event.keyCode == 32) {
                                                                                            let value = row.in_or_out_of_network !== null && row.in_or_out_of_network ? 1 : 0
                                                                                            this.on_change_eligibility_radio(event, value, "in_network", index)
                                                                                        }
                                                                                    }}
                                                                                    onChange={(event) => {
                                                                                        let value = row.in_or_out_of_network !== null && row.in_or_out_of_network ? 1 : 0
                                                                                        this.on_change_eligibility_radio(event, value, "in_network", index)
                                                                                    }}
                                                                                /> <label> In Network</label>
                                                                            </div>
                                                                        </Form.Field>
                                                                    </Grid.Column>
                                                                    <Grid.Column mobile={16} tablet={16} computer={16}>
                                                                        <Form.Field>
                                                                            <div className="ui radio">
                                                                                <input
                                                                                    type="radio"
                                                                                    value={row.in_or_out_of_network != null && !row.in_or_out_of_network ? 1 : 0}
                                                                                    id={ins_type_id + "_eligibility_out_network_" + index}
                                                                                    checked={row.in_or_out_of_network != null && !row.in_or_out_of_network ? true : false}
                                                                                    disabled={this.state.page_state === enum_case_page_state.edit}                                                                                    
                                                                                    onKeyDown={(event) => { 
                                                                                        event.preventDefault();
                                                                                        if (!event.shiftKey && event.key === "Tab") {
                                                                                            let id = row.id > 0 ? `${ins_type_id}_add_eligibility` : `${ins_type_id}_delete_eligibility_${index}`
                                                                                            let elem = document.getElementById(id);
                                                                                            elem.focus();                                                                                            
                                                                                        }                                                                                       
                                                                                        if ((event.shiftKey && event.key === "Tab") || event.key == "ArrowUp" || event.key == "ArrowRight") {                                                                                           
                                                                                            let elem = document.getElementById(`${this.ins_type_id}_eligibility_in_network_${index}`);
                                                                                            elem.focus();                                                                                               
                                                                                        } 
                                                                                        if (!is_firefox() && event.keyCode == 32) {
                                                                                            let value = row.in_or_out_of_network != null && !row.in_or_out_of_network ? 1 : 0
                                                                                            this.on_change_eligibility_radio(event, value, "out_network", index)
                                                                                        }
                                                                                    }}
                                                                                    onChange={(event) => {
                                                                                        let value = row.in_or_out_of_network != null && !row.in_or_out_of_network ? 1 : 0
                                                                                        this.on_change_eligibility_radio(event, value, "out_network", index)
                                                                                    }}
                                                                                /><label> Out of Network</label>
                                                                            </div>
                                                                        </Form.Field>
                                                                    </Grid.Column>
                                                                </Grid>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                </Grid.Column>
                                                <Grid.Column mobile={16} tablet={16} computer={16} textAlign="right">
                                                    <Button type="button" id={ins_type_id + "_delete_eligibility_" + index} basic disabled={this.state.page_state === enum_case_page_state.edit || row.id > 0 ? true : false} onClick={() => { this.on_delete_eligibility(index) }}>Delete</Button>
                                                    {
                                                        (index == eligibility_list.length - 1 || active_index == eligibility_list_data.length) && <Button type="button" primary id={`${ins_type_id }_add_eligibility`} disabled={this.state.page_state === enum_case_page_state.edit}
                                                            onClick={this.add_eligibility}>Add Eligibility</Button>}
                                                </Grid.Column>
                                            </div>
                                        )
                                    }
                                })
                            }

                        </Grid.Column>

                        {(active_eligibility_list) && <Grid.Column mobile={16} tablet={16} computer={16} textAlign="right" className="mar-t-15">
                            <Button type="button" primary id={`${ins_type_id}_add_eligibility`} disabled={this.state.page_state === enum_case_page_state.edit}
                                onClick={this.add_eligibility}>Add Eligibility</Button>
                        </Grid.Column>}

                    </Grid>
                </Grid.Column >

            </Grid >
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        patient_details: state.patient_details
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InsuranceEligibilityComponent))