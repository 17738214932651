import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Button, Dimmer, Form, Grid, Header, Loader } from 'semantic-ui-react';
import CheckboxComponent from '../../../shared/component/checkbox_component';
import * as session_storage from '../../../shared/session_storage_utility';
import ClaimAdjCodeSearch from "../../constants/component/claim_adjustment_code_advanced_search";
import { add_data, adj_payer_advanced_search, get_data, get_search_data, update_data } from '../action/constants_action';
import * as payment_constants from '../constants';
import * as global_constants from './../../../global_constants';
import { set_focus_to_app_header } from './../../../shared/tab_navigation_utility';
import { custom_date_format } from './../../../shared/utility';
import PromptNavigation from '../../../shared/component/prompt_navigation_component';

class ClaimAdjCodePayerOverrideComponent extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            patient_id: session_storage.get("active_patient"),
            control: null,
            form_object: {
                adj_code: "",
                payer_id: "",
                is_def_excl: false,
                created_by: "",
                created_date: "",
                updated_by: "",
                updated_date: "",
                eob_claim_adj_code_is_def_excl: false,
                is_active: true
            },
            error: {
                adj_code: false,
                payer_id: false
            },
            is_view_mode: false,
            is_loading: false,
            claim_adj_search_data: null,
            payer_search_data: null,
            adj_code_selected_row: null,
            payer_selected_row: null,
            by_pass_prompt: false,
            is_save_button_disabled: false
        }
    }
    _is_mounted = false;
    initial_form_data = null;
    initial_error_data = null;
    initial_adj_code_selected_row = null;
    initial_payer_selected_row = null
    is_submitted = false;
    token = "";
    code_id: 0;
    adjustment_code_enum = "adjustment_code";
    payer_enum = "payer";
    empty_search_form = null;
    search_criteria: {};
    by_pass_prompt: boolean = false;
    go_next: boolean = false;
    UNSAFE_componentWillMount = () => {
        const { form_object, error } = this.state
        this.initial_form_data = { ...form_object };
        this.initial_error_data = { ...error };
        this.search_criteria = this.props.history.location.state && this.props.history.location.state.search_criteria ? this.props.history.location.state.search_criteria : {};
        this.get_paryer_override_by_id();

    }
    get_paryer_override_by_id = () => {
        const { form_object } = this.state
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.code_id = this.props.match.params && this.props.match.params.id ? this.props.match.params.id : 0;
        if (this.code_id > 0) {
            this.setState({
                is_loading: true
            })
            let url = `${payment_constants.eob_claim_adjcode_search.api.get.url}?pageSize=10&searchById=${this.code_id}`
            get_data(this.token, url).then(response => {
                if (response.data && response.data.data.length > 0) {
                    const res_data = response.data.data[0]
                    const adj_code: any = {};
                    const payer: any = {};
                    form_object.adj_code = res_data.adj_code,
                        form_object.payer_id = res_data.payer_id,
                        form_object.created_by = res_data.lk10099_Desc,
                        form_object.is_def_excl = res_data.is_def_excl,
                        form_object.created_date = res_data.created_date,
                        form_object.updated_by = res_data.lk10101_Desc,
                        form_object.updated_date = res_data.updated_date,
                        form_object.is_active = res_data.is_active,
                        form_object.eob_claim_adj_code_is_def_excl = res_data.eob_claim_adj_code_is_def_excl
                        adj_code.carc = res_data.lk10096_Code
                        adj_code.description = res_data.lk10096_Desc
                        payer.description = res_data.lk10097_Desc
                        payer.payer_key_id = res_data.payer_id
                        payer.name = res_data.lk10097_Code
                        this.initial_form_data = { ...form_object }
                        this.initial_adj_code_selected_row = { ...adj_code }
                        this.initial_payer_selected_row = { ...payer }
                        this.setState({
                            form_object,
                            adj_code_selected_row: { ...adj_code },
                            payer_selected_row: { ...payer },
                           // is_view_mode: this.code_id > 0 ? true : false,
                            is_loading: false,
                            error: { ...this.initial_error_data}
                        })
                } else {
                    toastr.warning('', "No record found");
                    this.props.history.push("/payments/claim_adj_code_pay_ov/search");
                }
            }, error => {
                this.is_loading(false)
                if (error.response.data)
                    toastr.error('', error.response.data.messages[0].message);
            })
        }
    }
    componentDidMount = () => {
        this._is_mounted = true;
        this.empty_search_form = this.state.form_object;
        let controls = {
            adjustment_code: {
                type: "adjustment_code",
                grid_config: {
                    rows: null,
                    column: payment_constants.eob_claim_adjcode_search.column_defs
                },
                control_id: 'CLAIM_ADJUSTMENT_CODE',
                selected_row: null,
                error_message: 'No Record Found !',
                label: ["carc", "description"]
            },
            payer: {
                type: "payer",
                grid_config: {
                    rows: null,
                    column: payment_constants.claim_payer_search.column_defs
                },
                control_id: 'PAYER_DESCRIPTION',
                error_message: 'No Record Found !',
                label: ["name", "description"],
                selected_row: null,
            },
        }
        this.setState({
            control: controls
        });
        this.set_focus();

    }
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.user_login_details != this.props.user_login_details) {
            this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        }
    }

    componentWillUnmount = () => {
        this._is_mounted = false;
        document.body.classList.remove('admin-framework');
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[2] !== 'claim_adj_code_pay_ov' && session_storage.remove('claim_adj_reason_code_search_criteria');
        }
    };

    on_quick_search = async (params, type) => {
        let url = '';
        if (type == this.adjustment_code_enum) {
            url = payment_constants.eob_claim_adjcode_search.api.claim_adj_quick_search + "?keyword=" + params + "&pageSize=" + global_constants.constants.Quick_Search_Suggestion_List_Size;
            return await get_search_data(this.token, url);
        } else {
            url = payment_constants.claim_payer_search.api.payer_quick_search + "?keyword=" + params + "&page_size=" + global_constants.constants.Quick_Search_Suggestion_List_Size;
            let result = await get_search_data(this.token, url);
            result.data.data = result.data && result.data.data ? result.data.data.sort((a, b) => a.name.localeCompare(b.name)) : result
            return result
        }
    }

    on_advanced_search = async (params, type) => {

        if (this.adjustment_code_enum == type) {
            let query_params = "?pageSize=5000"
            if (params["adjustment_code"])
                query_params = `${query_params}&code=${params.adjustment_code}`
            if (params["adjustment_desc"])
                query_params = `${query_params}&description=${params.adjustment_desc}`
            let url = `${payment_constants.eob_claim_adjcode_search.api.eob_claim_adjcode_search}${query_params}`
            this.get_result(url);
        } else {
            params.Description = params.Description || ""
            params.Outbound_id = params.Outbound_id || ""
            params.name = params.name || ""
            params.Inbound_id = params.Inbound_id || ""
            adj_payer_advanced_search(params, this.token).then((res) => {
                this.setState({
                    payer_search_data: {
                        rows: res.data.data,
                        column: payment_constants.claim_payer_search.column_defs
                    }
                })
            }, error => {
                if (error.response && error.response.data && error.response.data.messages) {
                    toastr.error('', error.response.data.messages[0].message);
                }
            });
        }

    }

    get_result = (url) => {
        get_search_data(this.token, url).then((res) => {
            let result = res.data;
            if (result) {
                result = result.data.map((val, index) => {
                    val.is_text_excluded = val.is_excluded ? "True" : "False";
                    return val;
                });
            }
            this.setState({
                claim_adj_search_data: {
                    rows: result,
                    column: payment_constants.eob_claim_adjcode_search.column_defs
                }
            });
        }, error => {
            if (error.response && error.response.data && error.response.data.messages) {
                toastr.error('', error.response.data.messages[0].message);
            }
        });
    }


    update_data = (data, type) => {
        if (type == this.adjustment_code_enum) {
            let eob_claim_adj_code_is_def_excl = data && data.is_excluded != undefined ? data.is_excluded : this.initial_form_data.eob_claim_adj_code_is_def_excl
            data && (data.is_excluded = data.is_excluded.toString())
            this.setState({
                form_object: {
                    ...this.state.form_object,
                    adj_code: data ? data.carc : '',
                    eob_claim_adj_code_is_def_excl
                },
                error: {
                    ...this.state.error,
                    adj_code: this.is_submitted ? (data && data.carc ? false : true) : false
                },
                adj_code_selected_row: data
            });
        } else {
            this.setState({
                form_object: {
                    ...this.state.form_object,
                    payer_id: data ? data.payer_key_id : ''
                },
                error: {
                    ...this.state.error,
                    payer_id: this.is_submitted ? (data && data.payer_key_id ? false : true) : false
                },
                payer_selected_row: data
            });
        }
    }

    set_parent_show_modal_state = (show_status, control_type) => {
        if (control_type == global_constants.constants.advanced_control_type.referring_physician) {
            this.setState({ show_primary_advanced_control: show_status });
        }
        else if (control_type == global_constants.constants.advanced_control_type.second_referring_physician) {
            this.setState({ show_secondary_advanced_control: show_status });
        }
    }
    //Cancel handler
    cancel_handler = () => {
        this.by_pass_prompt = true;
        this.setState({ by_pass_prompt: true }, () => this.props.history.goBack());
    };

    validate_form = () => {
        const { form_object, error } = this.state
        error.adj_code = form_object.adj_code !== "" && form_object.adj_code !== null ? false : true
        error.payer_id = form_object.payer_id !== "" && form_object.payer_id !== null ? false : true
        if (this._is_mounted) {
            this.setState({
                error: error
            });
        }
        if (Object.keys(error).some((data) => { return error[data] == true })) {            
            toastr.error('', payment_constants.mandatory_fields)
            return false;
        }
        return true;
    }
    save_handler = (e, from_pop_up) => {
        e.preventDefault();
        this.go_next = false; 
        this.is_submitted = true;
        const { form_object} = this.state
        let form_obj: any = {};
        form_obj = {
            adj_code: form_object.adj_code,
            payer_id: form_object.payer_id,
            is_def_excl: form_object.is_def_excl,
            is_active: form_object.is_active
        }
        if (this.validate_form()) {
            this.setState({
                is_loading: true,
                is_save_button_disabled: true
            })
            session_storage.set('claim_adj_reason_code_search_criteria', {});
            if (this.code_id) {
                form_obj.eob_claimadjcode_payer_ov_id = parseInt(this.code_id)
                let url = payment_constants.eob_claim_adjcode_search.api.update.url

                update_data(this.token, url, form_obj).then(response => {

                    if (response.data.data) {
                        this.go_next = true;                        
                        toastr.success('', payment_constants.messages.payer_update_adj_code);
                        this.initial_form_data = { ...form_object }
                        this.setState({
                            is_loading: false,
                            by_pass_prompt: !from_pop_up ? true : false,
                            is_save_button_disabled: false
                        })
                        if (!from_pop_up && this.go_next) {
                            this.go_next = false;
                            this.props.history.goBack();
                        } 
                    } else {
                        toastr.error('', response.data.messages[0].message)
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false
                        })
                    }
                }, error => {
                    this.setState({
                        is_loading: false,
                        is_save_button_disabled: false
                    })
                    if (error.response.data)
                        toastr.error('', error.response.data.messages[0].message)
                }
                )
            } else {
                //Add
                let url = payment_constants.eob_claim_adjcode_search.api.add.url
                add_data(this.token, url, form_obj).then(response => {
                    if (response.data.data) {
                        this.go_next = true;
                        toastr.success('', payment_constants.messages.payer_add_adj_code);
                        this.initial_form_data = { ...form_object }  
                        this.setState({
                            is_loading: false,
                            by_pass_prompt: !from_pop_up ? true : false,
                            is_save_button_disabled: false
                        })
                        if (!from_pop_up && this.go_next) {
                            this.go_next = false;
                            this.props.history.goBack();
                        } 
                    } else {
                        toastr.error('', response.data.messages[0].message)
                        this.setState({
                            is_loading: false,
                            is_save_button_disabled: false
                        })
                    }
                }, error => {
                    this.setState({
                        is_loading: false,
                        is_save_button_disabled: false
                    })
                    if (error.response.data)
                        toastr.error('', error.response.data.messages[0].message)
                }
                )
            }
        }
    };
    is_loading = (is_loading) => {
        this.setState({
            is_loading
        })
    }

    datetimeformat = (datetime) => {
        return moment(datetime).format('MM/DD/YYYY h:mm:ss A');
    }
    set_focus = () => {
        document.body.classList.add('admin-framework');
        let elem = document.getElementById('admin-scrollable-area');
        let all_form_elem = elem.getElementsByTagName('input');
        all_form_elem[0].focus();
    }
    render() {
        const { form_object, by_pass_prompt, error } = this.state
        return (<>
            {!by_pass_prompt && (
                <PromptNavigation
                    is_data_changed={((JSON.stringify(this.initial_form_data) !== JSON.stringify(form_object)))}
                    save={e => this.save_handler(e, true)}
                    go_next_location={this.go_next}
                    history={this.props.history}
                    set_focus={this.set_focus}
                />
            )}
            <Dimmer active={this.state.is_loading}>
                <Loader size='massive'>Loading</Loader>
            </Dimmer>
            <div className={'admin-wrapper view-edit'} style={{ paddingBottom: 0 }}>
                <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                    <Grid.Column computer={16}>
                        <h3 className='ui header left aligned'>Claim Adjustment Reason Codes - Payer Override</h3>
                    </Grid.Column>
                </Grid>
                <div id='admin-scrollable-area' className='wrapper' style={{ overflowX: 'auto', overflowY: 'auto', display: 'block' }}>
                    <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container' style={{ paddingBottom: 0 }}>
                        <Form autoComplete='off' name='view_edit_form' className='common-forms' style={{ width: '100%' }}>
                            <Grid>
                                <Grid.Column mobile={8} tablet={8} computer={5}>
                                    <Form.Field className={`advance-quick-search ${error.adj_code ? "requiredWithBgColor error" : ""}`}>
                                        <label className="label-normal">
                                            Claim Adjustment Reason Code{
                                                !this.state.is_view_mode
                                                    ? <span className={error.adj_code ? "req-alert" : "req-alert_normal"}> (required)</span>
                                                    : ''}
                                        </label>
                                        <ClaimAdjCodeSearch
                                            control={this.state.control ? this.state.control.adjustment_code : null}
                                            update_data={this.update_data}
                                            search_result={this.state.claim_adj_search_data}
                                            on_quick_search={this.on_quick_search}
                                            on_advanced_search={this.on_advanced_search}
                                            selected_row={this.state.adj_code_selected_row}
                                            is_disabled={this.state.is_view_mode}
                                            is_focused={true}
                                            headerIdForGridTabNavigation={payment_constants.eob_claim_adjcode_search_grid_header_id}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column mobile={8} tablet={8} computer={5}>
                                    <Form.Field className={`advance-quick-search ${error.payer_id ? "requiredWithBgColor error" : ""}`}>
                                        <label className="label-normal">
                                            Payer{
                                                !this.state.is_view_mode
                                                    ? <span className={error.payer_id ? "req-alert" : "req-alert_normal"} > (required)</span>
                                                    : ''}
                                        </label>
                                        <ClaimAdjCodeSearch
                                            on_quick_search={this.on_quick_search}
                                            on_advanced_search={this.on_advanced_search}
                                            search_result={this.state.payer_search_data}
                                            control={this.state.control ? this.state.control.payer : null}
                                            update_data={this.update_data} selected_row={this.state.payer_selected_row}
                                            is_disabled={this.state.is_view_mode}
                                            headerIdForGridTabNavigation={payment_constants.claim_payer_search_header_id}
                                        />
                                    </Form.Field>
                                </Grid.Column>                                
                            </Grid>
                            <Grid>
                                <Grid.Column mobile={8} tablet={8} computer={5}>
                                    <Form.Field className="checkbox checkbox-margin-adj">
                                        <CheckboxComponent
                                            label='Is Excluded'
                                            checked={form_object.is_def_excl}
                                            id="pay_ov_is_exclude"
                                            onChange={(evt, checked) => {
                                                this.setState((prev) => ({
                                                    form_object: {
                                                        ...prev.form_object, is_def_excl: checked
                                                    }
                                                }))
                                            }}
                                            disabled={this.state.is_view_mode}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column mobile={8} tablet={8} computer={6} verticalAlign={'bottom'}>
                                    <Form.Field className="checkbox checkbox-margin-adj">
                                        <CheckboxComponent
                                            label='Is Active'
                                            checked={form_object.is_active}
                                            id="pay_ov_is_active"
                                            onChange={(evt, checked) => {
                                                this.setState((prev) => ({
                                                    form_object: {
                                                        ...prev.form_object, is_active: checked
                                                    }
                                                }))
                                            }}
                                            disabled={this.state.is_view_mode}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid>
                            {
                                this.code_id > 0 && (<Grid>
                                    <Grid.Column mobile={8} tablet={4} computer={4}>
                                        <Form.Field>
                                            <label>
                                                Created By
                                        </label>
                                            <label className="values">{form_object.created_by} </label>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={4} computer={4}>
                                        <Form.Field>
                                            <label>
                                                Created Date
                                        </label>
                                            <label className="values">{moment(form_object.created_date).format('MM/DD/YYYY hh:mm:ss A')} </label>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={4} computer={4}>
                                        <Form.Field>
                                            <label>
                                                Updated By
                                        </label>
                                            <label className="values">{form_object.updated_by} </label>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={4} computer={4}>
                                        <Form.Field>
                                            <label>
                                                Updated Date
                                            </label>
                                            <label className="values">{moment(form_object.updated_date).format('MM/DD/YYYY hh:mm:ss A')}</label>
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid>)
                            }
                            <Grid>
                                <Grid.Column mobile={16} tablet={16} computer={16}>
                                    <Form.Field>
                                        <label>
                                            Default Exclude Value
                                    </label>
                                        <label className="values">{form_object.eob_claim_adj_code_is_def_excl ? "True" : "False"}</label>
                                    </Form.Field>
                                </Grid.Column>
                            </Grid>                            
                        </Form>
                    </div>
                </div>
                <div className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area' id='applicationFooterSticky'>
                    <Button id='cancel_button_id' type='button' onClick={() => this.cancel_handler()} basic>Cancel</Button>
                    <Button type='submit' onKeyDown={set_focus_to_app_header} onClick={e => this.save_handler(e, false)} id='save_button_id' primary disabled={this.state.is_save_button_disabled}>Save</Button>
                </div>
            </div>
            </>
        );
    }
}

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        selected_patient: state.patient_details.patient_header,
        user_login_details: state.user_login_details
    };
};

export default connect(
    mapStateToProps
)(ClaimAdjCodePayerOverrideComponent);