import * as React from 'react';
import { toastr } from 'react-redux-toastr';
import { Form, Grid, Header, Progress } from 'semantic-ui-react';
import * as global_constant from '../../global_constants';
import * as user_constants from '../user_constants';

class PasswordStrengthComponent extends React.Component<any, any> {
    [x: string]: any;

    constructor(props) {
        super(props);
        this.state = {
            strength: 0,
            input: '',
            password_form: {
                old_password: '',
                new_password: '',
                confirm_password: ''
            },
            password_form_invalid: {
                old_password: false,
                confirm_password: false,
                new_password: false
            },

        };
    }

    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: global_constant.toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }
    componentDidUpdate(previousProps, previousState) {
        if (previousProps.is_edit_enabled && !this.props.is_edit_enabled) {
            this.setState({
                strength: 0,
                input: '',
                password_form: {
                    old_password: '',
                    new_password: '',
                    confirm_password: ''
                },
                password_form_invalid: {
                    old_password: false,
                    confirm_password: false,
                    new_password: false
                }
            })
        }
    }

    //Rule to check the password strength 
    strength_rule = (password) => {
        var passwordRating = 0;
        var strength = 0;
        if (/^(?:.*[!@\#$%^&=?_.,:;\\-]+.*)$/.test(password))//Match any special character
        {
            passwordRating += 5;
        }
        if (/^(?=.*[a-z]).+$/.test(password))//Match any lower case character
        {
            passwordRating += 5;
        }
        if (/^(?=.*[A-Z]).+$/.test(password))//Match any upper case character
        {
            passwordRating += 5;
        }
        if (/^(?=.*[0-9]).+$/.test(password))//Match any number
        {
            passwordRating += 5;
        }
        passwordRating += (password.length * 10) <= 80 ? (password.length * 10) : 80;

        strength = passwordRating;
        return strength;

    }


    toaster_for_password_strength = () => {
        if (this.state.password_form.new_password) {
            var strength = this.strength_rule(this.state.password_form.new_password)

            if (strength < 95) {

                const toastr_options = this.show_html_content_toaster(user_constants.is_weak_password);
                toastr.error('', '', toastr_options);

            }
        }
    }

    //Change event for input :- old password, new password ...
    private on_password_form_change = (event) => {
        var field_name = event.currentTarget.name ? event.currentTarget.name.replace("txt_", "") : event.currentTarget.name;
        var field_value = event.currentTarget.value;

        var state = { ...this.state }

        if (field_name == 'new_password') {
            state.input = field_value;
            state.strength = this.strength_rule(event.currentTarget.value)
        }
        else {
            this.toaster_for_password_strength();
        }

        state.password_form[field_name] = field_value;

        var is_valid = this.validate_password_value(field_name, field_value, state.strength);
        state.password_form_invalid[field_name] = is_valid;

        this.props.password_data(state)
        this.setState({
            strength: state.strength,
            input: state.input,
            password_form: state.password_form,
            password_form_invalid: state.password_form_invalid
        });
    }


    // validate each input box on change event
    validate_password_value(field_name, field_value, strength) {

        switch (field_name) {

            /* Error Class if:-
            1) The form is submitted
            2) New Pasword and Confirm Password has value and Old Password is empty
            */
            case 'old_password':
                return (this.props.is_submitted && this.state.password_form.new_password && !field_value);
                break;

            /* Error Class if:-
            1) The form is submitted
            2) New Pasword has value and Confirm Password is empty
            3) New Pasword does not match Confirm Password
            */
            case 'confirm_password':
                return (this.props.is_submitted && this.state.password_form.new_password && (!field_value || this.state.password_form.new_password != field_value) ? true : false);
                break;

            /* Error Class if:-
             1) The form is submitted
             2) Old Password or Confirm Password has value and New Password is empty
             */
            case 'new_password':
                return (this.props.is_submitted && (!field_value && (this.state.password_form.old_password || this.state.password_form.confirm_password)) ? true : false);
                break;
        }

    }

    // validate all values in the form 
    private validate_password_form(data) {


        var password_form_invalid = {
            old_password: (data.confirm_password || data.new_password) && !data.old_password ? true : false,
            confirm_password: ((data.old_password || data.new_password) && !data.confirm_password) || (data.new_password != data.confirm_password) ? true : false,
            new_password: ((data.confirm_password || data.old_password) && !data.new_password) || (data.new_password && this.strength_rule(data.new_password) < 95 ? true : false)
        }
        var is_invalid = password_form_invalid.old_password || password_form_invalid.confirm_password || password_form_invalid.new_password;

        if (password_form_invalid.new_password) {
            this.toaster_for_password_strength()
        }
        this.setState({
            password_form_invalid: password_form_invalid
        })

        return is_invalid;
    }

    render() {
        const { strength, input } = this.state;
        return (
            <div className="passwrd-strength">
                <br />
                <Header as='h3' dividing textAlign="left">
                    Change My Password
                </Header>
                <Grid.Row className="margin-rt-0">
                    <Grid>
                        <Grid.Column>
                            <Grid doubling columns={3} style={{ Padding: '0' }}>
                                <Grid.Column>
                                    <Form.Field>
                                        <label>Old Password</label>
                                        <Form.Input autoComplete='new_password' className="concealInput" type="text" disabled={!this.props.is_edit_enabled} name='txt_old_password' value={this.state.password_form.old_password} onChange={this.on_password_form_change} error={this.state.password_form_invalid.old_password} />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <label>New Password</label>
                                        <Form.Input autoComplete='new_password' className="concealInput" type="text" disabled={!this.props.is_edit_enabled} name='txt_new_password' value={this.state.password_form.new_password} onFocus={this.toaster_for_password_strength} error={this.state.password_form_invalid.new_password} onBlur={this.toaster_for_password_strength}
                                            onChange={this.on_password_form_change} />

                                        {input != '' ? strength < 80 ? <Progress error percent={100} active>Weak Password </Progress> : this.state.strength < 95 ? <Progress warning percent={100} active> Moderate Password</Progress> : <Progress success percent={100} active> Strong Password</Progress> : ''}
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <label>Confirm Password</label>
                                        <Form.Input autoComplete='new_password' className="concealInput" type="text" disabled={!this.props.is_edit_enabled} name='txt_confirm_password' value={this.state.password_form.confirm_password} onChange={this.on_password_form_change} error={this.state.password_form_invalid.confirm_password} />
                                        <div className="requiredText">{this.props.is_submitted && (this.state.password_form.confirm_password != this.state.password_form.new_password ? user_constants.mismatch_password : '')}</div>
                                    </Form.Field>
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>
                    </Grid>
                </Grid.Row>

            </div>



        )
    }
}
export default PasswordStrengthComponent