import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Dimmer, Grid, Loader, Form, Input, Table, Checkbox } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';
import MultiSelectPanelComponent from '../../../shared/component/multi_select_panel_component';
import { R6ResponseData, R6ResponseStatus, IDropDownDataDto, IMultiSelectDropDownDataDto, enum_user_type_r3_id } from '../../../../app/global_constants';
import * as constants from '../patient_letter_constants';
import * as action from '../action/patient_letter_action';
import { Icon } from 'semantic-ui-react';
import { show_html_content_toaster, get_all_error } from '../../../shared/utility'
import { toastr as toaster } from 'react-redux-toastr';
import { printPDF } from '../../../reports/util/export_print_utility';
import RichTextEditor from '../../../shared/component/rich_text_editor/rich_text_editor_component';
import RichTextEditorHelper, {
    IRichTextEditorToolbarConfig, RichTextEditorToolbarOptions, RichTextEditorListOptions, IEditorStyle,
    IRichTextEditorToolbarCustomMenuConfig, RichTextEditorCustomMenuControl, IRichTextEditorCustomMenuOptionItem
} from '../../../shared/component/rich_text_editor/rich_text_editor_helper';


export interface IFormLetterDetailProps {
    companyList: Array<IMultiSelectDropDownDataDto>;
    letterObject: constants.IPatientLetterSaveRequestDto;
    letterVariableList: Array<constants.IPatientLetterVariableDto>;
    loggedInUserDetails: any; //user_login_details.data
    onLetterUpdate: any; //callback function
}

class FormLetterDetailComponent extends React.Component<IFormLetterDetailProps, any> {

    _is_mounted: boolean;
    state: constants.IPatientLetterDetailStateTemplate;
    token: string;
    text_area_ref;
    rich_text_editor_heigth_default: number = 770;
    rich_text_editor_heigth_readOnly: number = 818
    // #region Constructor
    constructor(props) {
        super(props);
        
        // #region load the selected companies by user
        let selected_companies: Array<number> = this.get_selected_company_id(this.props.letterObject.is_global, this.props.letterObject.company_list);
        // #endregion load the selected companies by user

        this.state = {
            letter: this.props.letterObject,
            selected_companies_id_list: selected_companies,
            is_save_in_progress: false,
            is_validation_displayed:false,
            show_pop_up_menu: false
        }

        this.token = this.props.loggedInUserDetails.token_details.access_token;

        this.text_area_ref = React.createRef();
    }
    // #endregion

    // #region life cycle methods
    componentDidMount() {
        this._is_mounted = true;
    }


    componentWillUnmount() {
        this._is_mounted = false;
    }
    // #endregion life cycle methods

    // #region helper methods
    is_user_sys_admin(user_type_id: number): boolean {

        let evaluation: boolean;

        evaluation = user_type_id == enum_user_type_r3_id.Sys_Admin;

        return evaluation;
    }

    is_letter_editable(): boolean {
        let evaluation: boolean = true;
        if (this.state.letter.is_global) {
            evaluation = this.is_user_sys_admin(this.props.loggedInUserDetails.user_type_r3_id);
        }

        return evaluation;
    }

    is_letter_editable_class(): string {
        let evaluation: boolean = true;
        if (this.state.letter.is_global) {
            evaluation = this.is_user_sys_admin(this.props.loggedInUserDetails.user_type_r3_id);
        }

        return evaluation ? '' : 'disabled';
    }

    is_global_check_box_editable(): boolean {
        return (this.is_user_sys_admin(this.props.loggedInUserDetails.user_type_r3_id) && this.state.letter.patient_letter_id == 0);
    }

    has_ui_validation(): boolean {

        if (this.state.letter.patient_letter_name.trim() == '') return true;

        if (this.state.selected_companies_id_list.length == 0) return true;

        return false;
    }


    get_company_mapping(): Array<constants.IPatientLetterCompanyDto> {

        let letter_company_id_list: Array<number> = this.state.letter.company_list.map((mapping) => {
            return mapping.company_id;
        });

        let company_list: Array<constants.IPatientLetterCompanyDto> = [];
        company_list = [...this.state.letter.company_list];

        if (!this.state.letter.is_global) {
            // #region New Mapping
            this.state.selected_companies_id_list.map((company_id) => {
                if (letter_company_id_list.indexOf(company_id) == -1) {
                    let new_company_mapping: constants.IPatientLetterCompanyDto;
                    new_company_mapping = {
                        company_id: company_id,
                        is_active: true,
                        is_exclude: false,
                        gpms_code: ''
                    }
                    company_list.push(new_company_mapping);
                }
            });
            // #endregion New Mapping


            // #region Delete Mapping 
            company_list.map((mapping) => {
                if (this.state.selected_companies_id_list.indexOf(mapping.company_id) == -1) {
                    mapping.is_active = false;
                }
            });
            // #endregion Delete Mapping 

        }
        else {
            
            let all_company_id_list: Array<number> = this.props.companyList.map((displayCompany) => {
                return parseInt(displayCompany.value);
            });

            // #region New exclude mappings
            all_company_id_list.forEach((company_id) => {
                if (this.state.selected_companies_id_list.indexOf(company_id) == -1 && letter_company_id_list.indexOf(company_id) == -1) {
                    let new_company_mapping: constants.IPatientLetterCompanyDto;
                    new_company_mapping = {
                        company_id: company_id,
                        is_active: true,
                        is_exclude: true,
                        gpms_code: ''
                    }
                    company_list.push(new_company_mapping);
                }
                else if (letter_company_id_list.indexOf(company_id) == 0 && this.state.selected_companies_id_list.indexOf(company_id) == 0) {
                    company_list.filter((mapping) => {
                        if (mapping.company_id == company_id) {
                            mapping.is_exclude = false;
                            mapping.is_active = false;
                        }
                    })
                }
                
            });
            // #endregion Delete Mapping
        }

        return company_list;
    }

    get_selected_company_id(is_letter_global:boolean, letter_company_list : Array<constants.IPatientLetterCompanyDto>): Array<number> {

        let selected_companies_id_list: Array<number> = [];

        if (is_letter_global) {

            let letter_company_id_list: Array<number> = letter_company_list.map((mapping) => {
                return mapping.company_id;
            });

            this.props.companyList.forEach((company_obj) => {
                if (company_obj.value == undefined || parseInt(company_obj.value) == NaN) return;

                if (letter_company_id_list.length == 0 || letter_company_id_list.indexOf(parseInt(company_obj.value)) == -1)
                    selected_companies_id_list.push(parseInt(company_obj.value))
            })

        } else {
            letter_company_list.forEach((mapping) => {
                selected_companies_id_list.push(mapping.company_id);
            });
        }

        return selected_companies_id_list;
    }
    // #endregion helper methods

    // #region handlers
    on_change_letter_name(e) {
        let state_read = this.state;
        state_read.letter.patient_letter_name = e.target.value;
        this.props.onLetterUpdate(true);
        this.setState({ letter: state_read.letter });
    }

    on_change_global_handler(e) {
        let state_read = this.state;
        state_read.letter.is_global = e.target.checked;
        if (state_read.letter.is_global) {
            state_read.letter.company_list = [];
            state_read.selected_companies_id_list = this.get_selected_company_id(state_read.letter.is_global, []);
        }
        else if (!state_read.letter.is_global && state_read.letter.patient_letter_id == 0) {
            state_read.selected_companies_id_list = [];
        }
        this.props.onLetterUpdate(true);
        this.setState({ letter: state_read.letter, selected_companies_id_list: state_read.selected_companies_id_list });
    }

    on_change_active_handler(e) {
        let state_read = this.state;
        state_read.letter.is_active = e.target.checked;
        this.props.onLetterUpdate(true);
        this.setState({ letter: state_read.letter });
    }

    on_change_company_selection = (selected_list) => {
        let state_read = this.state;
        state_read.selected_companies_id_list = selected_list;
        this.props.onLetterUpdate(true);
        this.setState({ selected_companies_id_list: state_read.selected_companies_id_list });

    }

    on_change_letter_content(letter_content:string) {
        let state_read = this.state;
        state_read.letter.patient_letter_content = letter_content;
        this.props.onLetterUpdate(true);
        this.setState({ letter: state_read.letter });
    }

    on_add_patient_letter_variable(variable_value: string) {
        if (!this.is_letter_editable()) return;
        let text_area_value: string = this.text_area_ref.current.props.value;
        let cursor_start: number = this.text_area_ref.current._ref.selectionStart;
        text_area_value = `${text_area_value.substr(0, cursor_start)}${variable_value}${text_area_value.substr(cursor_start)}`;
        
        let state_read = this.state;
        state_read.letter.patient_letter_content = text_area_value;
        this.setState({ letter: state_read.letter});

    }
    // #endregion Change handlers

    // #region Save Handlers
    is_save_button_disabled = (): boolean => {
        return this.state.is_save_in_progress && !this.has_ui_validation();
    }

    async save_handler(is_copy: boolean = false) {
        
        let state_read = this.state;
        state_read.is_validation_displayed = true;
        this.setState({ is_validation_displayed: state_read.is_validation_displayed });

        if (this.has_ui_validation())  return;
        
        state_read.is_save_in_progress = true;
        state_read.is_validation_displayed = false;

        this.setState({ is_save_in_progress: state_read.is_save_in_progress, is_validation_displayed: state_read.is_validation_displayed});

        this.state.letter.company_list = this.get_company_mapping();

        await action.save_patient_letter(this.token, this.state.letter).then(
            (response: R6ResponseData) => {
                
                if (response && response.data && response.data.data) {
                    
                    if (response.data.status == R6ResponseStatus.success) {
                        if (state_read.letter.patient_letter_id == 0) { state_read.letter.patient_letter_id = response.data.data.patientLetterId; }

                        toaster.success("", `Patient Letter ${this.state.letter.patient_letter_name} is saved.`);
                        this.props.onLetterUpdate(false);

                        if (is_copy) {
                            state_read.letter.patient_letter_name = 'Copy of ' + this.state.letter.patient_letter_name;
                            state_read.letter.cloned_from = state_read.letter.patient_letter_id;
                            state_read.letter.patient_letter_id = 0;
                            state_read.letter.is_global = false;
                            state_read.letter.company_list = [];
                            state_read.selected_companies_id_list = this.get_selected_company_id(state_read.letter.is_global, state_read.letter.company_list);
                            this.props.onLetterUpdate(true);
                        }
                    }
                    else {
                        const toastr_options = show_html_content_toaster(get_all_error(response.data.messages));
                        toaster.error("", toastr_options);
                    }

                    state_read.show_pop_up_menu = false;
                    state_read.is_save_in_progress = false;
                    
                    this.setState({
                        letter: state_read.letter,
                        show_pop_up_menu: state_read.show_pop_up_menu,
                        is_save_in_progress: state_read.is_save_in_progress,
                        selected_companies_id_list: state_read.selected_companies_id_list
                    });
                }

                document.getElementById("pl_name_id").focus();
            },
            (error) => {
                const toastr_options = show_html_content_toaster(error.message);
                toaster.error("", toastr_options);
                state_read.is_save_in_progress = false;
                this.setState({ is_save_in_progress: state_read.is_save_in_progress });
            }
        );
    }

    async on_click_save_and_copy() {
        await this.save_handler(true);        
    }

    // #endregion Save Handlers

    // #region Print Preview
    on_print_preview_button() {
        var print_content = this.get_print_content(this.state.letter.patient_letter_content, this.props.letterVariableList);
        let printElement: any;
        let print_report_button = document.getElementById(constants.preview_button.id);
        let is_chrome = navigator.userAgent.indexOf("Chrome") > -1;
        let margin = is_chrome ? '20px' : '10px';
        let print_data = `<style>@media print {
                @page { size: auto; margin: ${margin} !important; }
                body { padding:10px; }
                p { margin: 20px; }
            }</style>`;
        printElement = document.createElement("div");
        printElement.setAttribute("id", "printFormLetters");
        print_data += `<p>${print_content}</p>`;
        printElement.innerHTML = print_data;
        document.body.setAttribute("style", "overflow:visible!important");
        document.body.appendChild(printElement);
        printPDF(printElement, print_report_button, 'Form Letter');
    }

    get_print_content(letter_content: any, letter_variable_list: Array<constants.IPatientLetterVariableDto>) {
        if (letter_content && letter_content.length > 0) {
            letter_variable_list.map((item) => {
                letter_content = letter_content.split(item.patient_letter_variable_value).join(item.patient_letter_variable_name);
            })
        }
        return letter_content;
    }

    // #endregion Print Preview

    // #region UI Validation
    mark_field_for_required_validation(field_name: string): boolean {

        if (!this.state.is_validation_displayed) return false;

        let has_validations: boolean = false;

        switch (field_name) {
            case constants.name_textbox.name:
                has_validations = this.state.letter.patient_letter_name.trim() == '';
                break;
            case constants.company_multiselect.name:
                has_validations = this.state.selected_companies_id_list.length == 0;
                break;
            default:
                has_validations = false;
        }


        return has_validations;
    }

    mark_field_for_validation(field_name: string): boolean {

        if (!this.state.is_validation_displayed) return false;

        let has_validations: boolean = false;

        switch (field_name) {
            case constants.name_textbox.name:
                has_validations = (this.state.letter.patient_letter_name.trim() == '')
                break;
            default:
                has_validations = false;
        }

        return has_validations;
    }

    get_required_text_div(field_name: string): string {
        return '';
    }

    // #endregion UI Validation

    // #region Save Ellipsis
    pop_the_ellipsis_menu() {
        let state_read = this.state;
        state_read.show_pop_up_menu
        this.setState({ show_pop_up_menu: !state_read.show_pop_up_menu });
    }
    // #endregion

    // #region Rich Text Editor 
    get_rich_text_editor_component = () => {

        let editor_style_config = this.get_rich_text_editor_style_config();
        let is_letter_ready_only = !this.is_letter_editable();
        let tool_bar_config = this.get_toolbar_config();
        let tool_bar_custom_config = this.get_toolbar_custom_menu_config();
        
        return (
            <RichTextEditor
                editorContent={this.state.letter.patient_letter_content}
                editorStyle={editor_style_config}
                hideToolbar={is_letter_ready_only}
                readonly={is_letter_ready_only}
                onChange={e => this.on_change_letter_content(e)}
                toolbarConfig={tool_bar_config}
                toolbarCustomMenuConfig={tool_bar_custom_config}
            />
        );
    }


    get_rich_text_editor_style_config(): IEditorStyle {

        let is_letter_ready_only = !this.is_letter_editable();
        let editor_height: number;

        if (is_letter_ready_only)
            editor_height = this.rich_text_editor_heigth_readOnly;
        else
            editor_height = this.rich_text_editor_heigth_default;

        return RichTextEditorHelper.configure_rich_text_edtior_style(1, editor_height, editor_height);
    }


    get_toolbar_config(): IRichTextEditorToolbarConfig {

        let default_config = RichTextEditorHelper.get_default_tool_bar_config();
        default_config.tool_bar_options = default_config.tool_bar_options.map((config) => {
            if (config.optionName == RichTextEditorToolbarOptions.image) {
                config.hide = true;
            }
            if (config.optionName == RichTextEditorToolbarOptions.link) {
                config.hide = true;
            }
            if (config.optionName == RichTextEditorToolbarOptions.list) {
                config.options = [RichTextEditorListOptions.unordered];
            }
            return config;
        })
        return default_config;
    }

    get_custom_menu_options(): Array<IRichTextEditorCustomMenuOptionItem> {
        let options: Array<IRichTextEditorCustomMenuOptionItem>;
        options = this.props.letterVariableList.map((variable) => {
            return { value: variable.patient_letter_variable_value, text: variable.patient_letter_variable_name + ' - ' + variable.patient_letter_variable_value}
        });
        return options;
    }

    get_toolbar_custom_menu_config(): Array<IRichTextEditorToolbarCustomMenuConfig> {
        let custom_buttons: Array<IRichTextEditorToolbarCustomMenuConfig> = [];

        custom_buttons.push(RichTextEditorHelper.get_toolbar_custom_menu_config(RichTextEditorCustomMenuControl.addInlineText, true,
            this.get_custom_menu_options(), "Letter Variables", "rte-template-variables", true
        ))
        
        return custom_buttons
    }

    // #endregion Rich Text Editor

    // #region Render
    render() {

        return (
            <React.Fragment>
                <Table className="fl-detail-table">
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell className="fl-left-panel">
                                {this.get_rich_text_editor_component()}
                            </Table.Cell>

                            <Table.Cell className="fl-right-panel">
                                <Grid columns='equal' className="fl-right-panel-wrapper">
                                    <Grid.Row>
                                        <Grid.Column>
                                            <label>
                                                {constants.name_textbox.name}<span className={this.mark_field_for_required_validation(constants.name_textbox.name) ? "req-alert" : 'req-alert_normal'}> (required)</span>
                                            </label>
                                            <Input
                                                autoComplete='off'
                                                name={constants.name_textbox.id}
                                                id={constants.name_textbox.id}
                                                type='text'
                                                value={this.state.letter.patient_letter_name}
                                                onChange={e => this.on_change_letter_name(e)}
                                                maxLength={50}
                                                className={this.mark_field_for_validation(constants.name_textbox.name) ? 'req-border-inp' : ''}
                                                disabled={!this.is_letter_editable()}
                                                autoFocus
                                            />
                                            {this.get_required_text_div(constants.name_textbox.name)}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Checkbox id={constants.global_checkbox.id}
                                                label={constants.global_checkbox.name}
                                                checked={this.state.letter.is_global}
                                                onChange={e => this.on_change_global_handler(e)}
                                                disabled={!this.is_global_check_box_editable()}
                                            /> &nbsp; &nbsp; &nbsp;
                                            <Checkbox id={constants.active_checkbox.id}
                                                label={constants.active_checkbox.name}
                                                checked={this.state.letter.is_active}
                                                onChange={e => this.on_change_active_handler(e)}
                                                disabled={!this.is_letter_editable()}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <label>
                                                {constants.company_multiselect.name}<span className={this.mark_field_for_required_validation(constants.company_multiselect.name) ? "req-alert" : 'req-alert_normal'}> (required)</span>
                                            </label>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row className="custom-width-drawer-ms-wrapper">
                                        <Grid.Column>
                                            <div className="ms-panel">
                                                <MultiSelectPanelComponent
                                                    id={constants.company_multiselect.id}
                                                    data={this.props.companyList}
                                                    selected_list={this.state.selected_companies_id_list}
                                                    onChangeSelected={this.on_change_company_selection}
                                                    is_save_in_progress={this.state.is_save_in_progress}
                                                    displayExtended={true}
                                                    hide_reset={this.state.letter.is_global}
                                                    hide_select_all={this.state.letter.is_global}
                                                />
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>

                <div className='bp3-dialog-save-button'>
                    <span>
                        <Button
                            id={constants.save_button.id}
                            className="primary"
                            type='button'
                            disabled={this.is_save_button_disabled()}
                            onClick={() => this.save_handler()}>{constants.save_button.name}
                        </Button>
                        <div className="save-more">
                            <button className="save-more-btn">
                                <i className="icon-icemoon icon-show-more" onClick={() => { this.is_save_button_disabled() ? '#': this.pop_the_ellipsis_menu() }} />
                            </button>

                            {this.state.show_pop_up_menu &&
                                <div className="save-more-cotainer">
                                    <ul>
                                        <div>
                                            <li>
                                                <a onClick={() => this.on_click_save_and_copy()}>Save and Copy</a>
                                            </li>
                                        </div>
                                    </ul>
                                </div>
                            }
                        </div>

                    </span>


                </div>

                <div className='fl-preview-button'>
                    <Button
                        id={constants.preview_button.id}
                        type='submit'
                        onClick={() => this.on_print_preview_button()}>
                        {constants.preview_button.name}
                    </Button>
                </div>
            </React.Fragment>
        );

    }
    // #endregion

}


//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details
    };
};
export default connect(mapStateToProps)(FormLetterDetailComponent);