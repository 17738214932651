import * as report_constants from '../report_constants';
import * as dateFormat from 'dateformat';
import { print_html_data_to_pdf } from '../../shared/utility';
import * as _ from 'lodash';

export const get_value_from_currency = (value: any) => {
  if (typeof value === 'string') {
    if (value.indexOf('(') != -1) {
      value = value ? '-' + value.replace(/[$,()]/g, '') : 0;
    } else {
      value = value ? value.replace(/[$,()]/g, '') : 0;
    }
  }
  return parseFloat(value);
};

//Function calls on click of print button when is a Crystal Report  type
export const on_print_button = (
  grid_ref: { api: any },
  title: string,
  eob_error: any[],
  eob_change: any[],
  auto_print = false,
  ld_ff: boolean
) => {
    let row_api = grid_ref.api;

    let argos_bill_1162_editable_835s = ld_ff;

    let g_billed_amount: number,
        g_allowed_amount: number,
        g_adjust_amount: number,
        g_pay_amount: number,
        g_balance: number,
        g_patient_ids: any,
        patient: any,
        check_number = '',
        print_content = '',
        title_line0 = '',
        title_line1 = '',
        title_line2 = '',
        title_line3 = '';

    print_content += '<div class="reportPrint">';

    //Error Report

    let titleLines = title.split('<br>');
    titleLines.filter(notEmpty => notEmpty === '');

    if (titleLines && titleLines.length > 0) {
        title_line0 = titleLines[0];
    }

    if (titleLines && titleLines.length > 1) {
        title_line1 = titleLines[1];
    }

    if (titleLines && titleLines.length > 2) {
        title_line2 = titleLines[2];
    }

    if (titleLines && titleLines.length > 3) {
        title_line3 = titleLines[3];
    }

    if (eob_error && eob_error.length > 0) {
        check_number = eob_error[0].check_eft_num;
    }

    print_content += [title_line0, title_line1, title_line2, 'Check # ' + check_number, title_line3].join('<br>');
    print_content += '<hr>';

    g_patient_ids = new Array();

    if (eob_error && eob_error.length > 0) {
        print_content += '<table id="mainGrid">';
        print_content += `
      <thead>
        <tr>
          <th style="width:118px;">Check Number</th>
          <th style="">Patient ID</th>
          <th style="">First Name</th>
          <th style="">Last Name</th>
          <th style="">Date of Service</th>
          <th style="">CPT</th>
          <th style="">Billed</th>
          <th style="">Allowed</th>
          <th style="">Payment</th>
          <th style="">Adjustment</th>
          <th style="">Balance</th>
          <th style="">Error Description</th>
        </tr>
      </thead>`;

    print_content += `<tbody>`;

        g_billed_amount = 0;
        g_allowed_amount = 0;
        g_pay_amount = 0;
        g_adjust_amount = 0;
        g_balance = 0;

        eob_error.map((row) => {
            print_content += `<tr>
            <td>${row.check_eft_num}</td>
            <td>${row.acctno}</td>
            <td>${row.patFname}</td>
            <td>${row.patLname}</td>
            <td>${ Date.parse(row.dos) > 0 ? dateFormat(row.dos, 'mm/dd/yyyy') : '' }</td>
            <td>${row.cpt}</td>
            <td>${row.billed_amt}</td>
            <td>${row.allow_amt}</td>
            <td>${row.pay_amt}</td>
            <td>${row.adj_amt}</td>
            <td>${row.balance}</td>
            <td>${row.error_desc}</td>
            </tr>`;

            g_billed_amount += get_value_from_currency(row.billed_amt);
            g_allowed_amount += get_value_from_currency(row.allow_amt);
            g_pay_amount += get_value_from_currency(row.pay_amt);
            g_adjust_amount += get_value_from_currency(row.adj_amt);
            g_balance += get_value_from_currency(row.balance);

            if (g_patient_ids.indexOf(row.acctno) == -1) {
                g_patient_ids.push(row.acctno);
            }
        });

      print_content += `</tbody>`;

      print_content += `<tr class="footer">
            <td>Total</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>${report_constants.currencyFormatter(g_billed_amount)}</td>
            <td>${report_constants.currencyFormatter(g_allowed_amount)}</td>
            <td>${report_constants.currencyFormatter(g_pay_amount)}</td>
            <td>${report_constants.currencyFormatter(g_adjust_amount)}</td>
            <td>${report_constants.currencyFormatter(g_balance)}</td>
            <td></td>
            </tr>`;

      print_content += `</table><br>`;

  }

 //Change Report

    if (argos_bill_1162_editable_835s == true) {

        if (eob_change) {
            print_content += [title_line0.replace('Errors', 'Edits'), title_line1, title_line2, 'Check # ' + check_number, eob_change.length + ' records'].join('<br>');
            print_content += '<hr>';
        }

        if (eob_change && eob_change.length > 0) {

            //add an empty patient to end of list for changes without a patient in error report (this should not happen?)
            g_patient_ids.push('');

            g_patient_ids.map((acctno) => {
                patient = eob_error.filter(patient => patient.acctno === acctno)

                if ((patient && patient.length > 0) || (acctno == '' && eob_change.length > 0)) {
                    if (acctno == '') {
                        let patient_id = eob_change[0].patient_id || "unknown";
                        let first_name = eob_change[0].first_name || "unknown";
                        let last_name = eob_change[0].last_name || "unknown";
                        print_content += `<div style="padding-bottom:5px;"><span><b>Patient ID:</b> ${patient_id}</span>&nbsp;&nbsp;<span><b>First Name:</b> ${first_name}</span>&nbsp;&nbsp;<span><b>Last Name:</b> ${last_name}</span></div>`;
                    }
                    else
                        print_content += `<div style="padding-bottom:5px;"><span><b>Patient ID:</b> ${patient[0].acctno}</span>&nbsp;&nbsp;<span><b>First Name:</b> ${patient[0].patFname}</span>&nbsp;&nbsp;<span><b>Last Name:</b> ${patient[0].patLname}</span></div>`;

                    print_content += '<table id="mainGrid">';
                    print_content += `
                    <thead>
                    <tr>
                        <th style="width:118px;">Date of Service</th>
                        <th style="">CPT</th>
                        <th style="">Worked By</th>
                        <th style="">Edited Date</th>
                        <th style="">Changed Value</th>
                        <th style="">From</th>
                        <th style="">To</th>
                    </tr>
                    </thead>`;

                    print_content += `<tbody>`;

                    if (acctno != '') {
                        //select changes based on error report patients
                        let changes_for_patient = eob_change.filter(changes => patient[0].acctno.endsWith(changes.patient_id));
                        changes_for_patient.map((row) => {
                            print_content += `<tr>
                    <td>${dateFormat(row.dos, 'mm/dd/yyyy')}</td>
                    <td>${row.cpt}</td>
                    <td>${row.worked_by}</td>
                    <td>${dateFormat(row.edited_date, 'mm/dd/yyyy')}</td>
                    <td>${row.changed_value}</td>
                    <td>${row.from_value}</td>
                    <td>${row.to_value}</td>
                    </tr>`;
                        });
                        changes_for_patient.forEach(remove => eob_change.splice(eob_change.findIndex(change => change.patient_id === remove.patient_id), 1));
                    } else {
                        //last of the changes with no matching patients in error report
                        eob_change.map(row => {
                            print_content += `<tr>
                    <td>${dateFormat(row.dos, 'mm/dd/yyyy')}</td>
                    <td>${row.cpt}</td>
                    <td>${row.worked_by}</td>
                    <td>${dateFormat(row.edited_date, 'mm/dd/yyyy')}</td>
                    <td>${row.changed_value}</td>
                    <td>${row.from_value}</td>
                    <td>${row.to_value}</td>
                    </tr>`;
                        });
                        eob_change = [];
                    }

                    print_content += `</tbody>`;

                    print_content += `</table><br>`;

                }
            });
        }
    }
  print_content += `
  </div>`;
  if (auto_print) {
    return print_content;
  } else {
    print_html_data_to_pdf(print_content, 'print_report_button', '835 Company Summary Detail');
  }
};


