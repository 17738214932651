import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as session_storage from '../../shared/session_storage_utility';
import { LoaderComponent } from '../../shared/component/loading_component';
import { Button, Form, Grid, Input, Accordion, Icon } from "semantic-ui-react";
import ReportHeader from '../../reports/component/report_header';
import { handle_click_on_enter, set_focus_to_app_header, set_focus_on_element_with_id, applyFocusOnDateRangePicker } from '../../shared/tab_navigation_utility';
import DateRangePickerComponent from '../../shared/component/date_range_component';
import CheckboxComponent from '../../shared/component/checkbox_component';
import * as moment from 'moment';
import { toastr } from 'react-redux-toastr';
import * as payment_constants from '../payment_constants';
import * as report_constants from '../../reports/report_constants'
import { toastr_options } from '../../global_constants';
import { get_all_error, get_columns } from '../../shared/utility';
import GridView from '../../shared/component/grid/grid_child_ui_component';
import { get_error_correction_data } from '../action/payment_action';
import { validate_chg_for_correction } from '../../charge/action/charge_action';

export class ErrorCorrectionsComponent extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            show_grid: false,
            controls_is_expended: true,
            is_search_disabled: false,
            grid_params: [],
            is_correct_charge_disabled: true,
            search_criteria: {
                from_date: null,
                to_date: null,
                charge_id: "",
                show_inactive: false
            }
        };
    }
    is_mounted = false;
    patient_id : any = null;
    token: string = '';
    total_column_width: number = 0;
    grid_header_height: number = 0;
    grid_data: any = {
        rows: [],
        grid_conf: [],
    };
    max_inner_width = 0;
    page_metadata: any;

    get_page_metadata = () => {
        this.patient_id = session_storage.get('active_patient');
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;

        this.page_metadata = payment_constants.error_corrections(this.props);

        let error_corrections_search = session_storage.get(`error_corrections_criteria`);

        if (error_corrections_search && error_corrections_search.search_criteria) {
            let updatedCriteria = {
                ...error_corrections_search.search_criteria, from_date: this.handle_date('from_date', error_corrections_search.search_criteria), to_date: this.handle_date('to_date', error_corrections_search.search_criteria)
            }
            this.setState({ search_criteria: updatedCriteria }, () => {
                this.handle_search();
            })
        }
    };

    handle_date = (name: React.ReactText, criteria, title = false) => {
        if (title) {
            return criteria[name] ? moment(criteria[name]).format('MM/DD/YYYY') : '';
        }else {
            return criteria[name] ? moment(moment(criteria[name]).format('MM/DD/YYYY')) : null;
        }
    };

    UNSAFE_componentWillMount = () => {
        this.get_page_metadata();
    };

    componentDidMount = () => {
        this.is_mounted = true;
        if (!document.body.classList.contains('reports')) {
            document.body.classList.add('reports');
        }
        applyFocusOnDateRangePicker()

        if (this.patient_id == '' || this.patient_id == undefined ) {
            session_storage.set('no_patient_selected_for', this.props.location.pathname);
            this.props.history.push(`/no_patient_selected`);
        } 
    }

    //Get Payments NotesData from API function
    componentDidUpdate(prevProps, prevState) {
        this.grid_data.grid_conf = this.page_metadata.column_defs;
        //change page data if patient changes from header, instead of navigate to other page
        if (prevProps.patient_id && prevProps.patient_id != this.props.patient_id) {
            this.patient_id = session_storage.get("active_patient");
            this.clear_handler();
            applyFocusOnDateRangePicker();
        }
    }

    componentWillUnmount = () => {
        document.body.classList.remove('reports');
        this.is_mounted = false;
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            // route_array[2] !== 'error_corrections' || 
            if(route_array && (route_array[2] !== 'error_corrections_details')) {
                session_storage.remove('error_corrections_criteria');   
            }
        }
    };

    // Search Criteria Toggler
    controls_toggle = () => {
        if (this.is_mounted) {
            this.setState({
                controls_is_expended: !this.state.controls_is_expended
            });
        }
    };

    // Calender change handler.
    range_date_change_handler = (from_date: any, end_date: any) => {
        if (this.is_mounted) {
            this.setState(prevState => ({
                search_criteria: {
                    ...prevState.search_criteria,
                    from_date: from_date || null,
                    to_date: end_date || null
                },
                is_error_date: from_date ? (moment.isMoment(from_date) ? false : true) : false || end_date ? (moment.isMoment(end_date) ? false : true) : false
            }));
        }
    };

    max_value_validation = (input) => {
        if (Number(input) > 2147483647) {
            return this.max_value_validation(input.substring(0, input.length - 1));
        }
        else {
            return Number(input);
        }
    }

    handle_change = (e) => {
        const { name, value } = e.target;
        let newValue = '';
        if (name != "show_inactive") {
            newValue = (e.target.validity.valid) ?
                Number(value) <= 2147483647 ?
                    value :
                    this.max_value_validation(value) :
                this.state.search_criteria.charge_id;
            if (this.is_mounted) {
                this.setState({
                    is_correct_charge_disabled: name == 'charge_id' ? newValue ? false : true : true
                })
            }
        }
        if (this.is_mounted) {
            this.setState(prevState => ({
                search_criteria: { ...prevState.search_criteria, [name]: (name == "show_inactive" ? e.target.checked : newValue) },
            }));
        }
    };
    
    // Function calls on click of Clear button and clear all the searched result as well as criteria.
    clear_handler = () => {
        if (this.is_mounted) {
            this.setState({
                search_criteria : {
                    from_date: null,
                    to_date: null,
                    charge_id: "",
                    show_inactive: false
                },
                show_grid: false,
                is_correct_charge_disabled: true
            })
            this.grid_data = {
                rows: []
            }
        }
        session_storage.remove(`error_corrections_criteria`);
    };

    handle_navigation_after_last_element = (event) => {
        // apply a check where grid is empty
        if (
            !event.shiftKey &&
            event.keyCode == "9" &&
            !this.state.show_grid
        ) {
            set_focus_to_app_header(event);
        }
    };
   
    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }

    correct_charge = async () => {
        if (this.is_mounted) {
            this.setState({
                loading: true,
            });
        }
        var token = this.props.user_login_details.user_data.data.token_details.access_token;
        let criteria = this.state.search_criteria;

        await validate_chg_for_correction(criteria.charge_id, token).then(
            (response) => {
                if (response.data && response.data.data) {
                    this.props.history.push({
                        pathname: '/payments/error_corrections_details',
                        state: { charge_id: criteria.charge_id }
                    });
                } else {
                    const toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                    toastr.error('', toastr_options);
                }
                if (this.is_mounted) {
                    this.setState({
                        loading: false,
                    });
                }
            },
            (error: { response: { data: any } }) => {
                if (this.is_mounted) {
                    this.setState({
                        loading: false,
                    });
                }

                if (error.response.data) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toastr.error("", toastr_options);
                }
            }
        );

    }

    row_data_formatter = data => {
        data.map(row => {
            // Formate Inner grid Row
            row['details'] = row.payment_adj_detail && row.payment_adj_detail.map((item) => {
                return (item = {
                    deposit_date:
                        item.deposit_date != null || item.deposit_date != undefined
                            ? report_constants.dateFormatter(item.deposit_date)
                            : '',
                    post_by: item.post_by || "",
                    transaction: item.transaction || "",
                    payment:
                        item.payment != null || item.payment != undefined
                            ? report_constants.currencyFormatter(item.payment)
                            : report_constants.currencyFormatter(0),
                    adjustment_sec:
                        item.adjustment_sec != null || item.adjustment_sec != undefined
                            ? report_constants.currencyFormatter(item.adjustment_sec)
                            : report_constants.currencyFormatter(0),
                    transfer:
                        item.transfer != null || item.transfer != undefined
                        ? report_constants.currencyFormatter(item.transfer)
                        : report_constants.currencyFormatter(0),
                    xfer_ins: item.xfer_ins || "",
                    xfer_to_ins: item.xfer_to_ins || "",
                    check_number: item.check_number != null ? item.check_number 
                                    : item.credit_card_num != null ? item.credit_card_num : '',
                    batch_number: item.batch_number || "",
                    adjustment_qualifier: item.adjustment_qualifier || "",
                    adjustment_code: item.adjustment_code || "",
                    pay_active: item.pay_active
                });
            });
            this.grid_data.grid_conf.map((value, index) => {
                switch (value.type) {
                    case 'date':
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined
                                ? report_constants.dateFormatter(row[value.field])
                                : '';
                        break;
                    case 'currency':
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined
                                ? report_constants.currencyFormatter(row[value.field])
                                : report_constants.currencyFormatter(0);
                        break;
                    case 'number':
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined ? parseInt(row[value.field]) : 0;
                        break;
                    case "link":
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined ? row[value.field].toString() : " ";
                        break;
                    default:
                        row[value.field] = row[value.field];
                }
            });
            return row;
        });
        return data;
    };

    handle_search = async () => {
        if (!this.patient_id) {
            toastr.error('', 'The Patient ID is invalid.');
            return;
        }

        if (this.is_mounted) {
            this.setState({
                loading: true,
                show_grid: false,
                is_search_disabled: true,
            });
        }
        this.grid_data.rows = []

        var token = this.props.user_login_details.user_data.data.token_details.access_token;
        let criteria = this.state.search_criteria;
        let from_date = criteria.from_date ? moment(criteria.from_date).format('MM/DD/YYYY') : "01/01/1975";
        let to_date = criteria.to_date ? moment(criteria.to_date).format('MM/DD/YYYY') : moment(new Date()).format('MM/DD/YYYY');

        await get_error_correction_data(this.patient_id, from_date, to_date, criteria.show_inactive, token).then(
            (response) => {
                if (response.data && response.data.status === 1) {
                    let updatedCriteria = {
                        ...criteria, charge_id:""
                    }
                    session_storage.set(`error_corrections_criteria`, {
                        search_criteria: updatedCriteria
                    });
                    response.data.data = this.row_data_formatter(response.data.data);
                    this.max_inner_width = 0;
                    this.grid_data['rows'] = response.data.data
                        ? response.data.data.map((item: any) => {
                            item['group'] = '';
                            const child_column = this.page_metadata.child_column.map(value => ({ ...value }));
                            const inner_table_width = get_columns(item.payment_adj_detail || [], child_column);
                            this.max_inner_width =
                                inner_table_width.total_column_width > this.max_inner_width
                                    ? inner_table_width.total_column_width
                                    : this.max_inner_width;
                            if (item.payment_adj_detail && item.payment_adj_detail.length > 0) {
                                item = {
                                    ...item,
                                    expanded: true,
                                    participants: [
                                        {
                                            ...item,
                                            child_column,
                                            row_class: this.page_metadata.innerRowClassRules
                                        }
                                    ]
                                };
                            } else {
                                item = {
                                        ...item,
                                    participants: [{...item}]
                                };
                            }
                            
                            delete item['details'];
                            return item;
                        })
                        : [];
                    this.total_column_width = 0;
                    const grid_height = get_columns(
                        this.grid_data['rows'],
                        this.grid_data.grid_conf
                    );

                    this.grid_header_height = grid_height.header_height;
                    this.total_column_width = grid_height.total_column_width > this.max_inner_width ? grid_height.total_column_width : this.max_inner_width;
                } else {
                    const toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                    toastr.error('', toastr_options);
                }
                if (this.is_mounted) {
                    this.setState({
                        loading: false,
                        show_grid: true,
                        is_search_disabled: false
                    });
                }
            },
            (error: { response: { data: any } }) => {
                if (this.is_mounted) {
                    this.setState({
                        loading: false,
                        is_search_disabled: false
                    });
                }

                if (error.response.data) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toastr.error("", toastr_options);
                }
            }
        );
    };

   get_grid_ref = grid_params => {
        if (this.is_mounted) {
            this.setState({
                grid_params
            });
        } 
        let scrollbale_area = document.getElementById('report-scrollable-area');
        if(scrollbale_area) {
            var grid_width = scrollbale_area.clientWidth;
            if (this.total_column_width > grid_width) {
                let totalWidth = 0;
                this.grid_data.grid_conf.filter(item => {
                    grid_params.columnApi.setColumnWidth(item.field, item.width, false);
                    totalWidth += item.width;
                });
                if (this.total_column_width > totalWidth) {
                    this.grid_data.grid_conf.filter(item => {
                        let equaldiff = Math.ceil((this.total_column_width - totalWidth) / this.grid_data.grid_conf.length);
                        grid_params.columnApi.setColumnWidth(item.field, item.width + equaldiff, false);
                    });
                }
            }
        }
    };
    on_grid_out = () => {
        set_focus_on_element_with_id('app_header_dropdown');
    };

    render() {
        const { loading, controls_is_expended, is_clear_disabled, is_error_date, search_criteria, is_search_disabled, is_correct_charge_disabled } = this.state

        return (
            <LoaderComponent loading={loading}>
                <div className={'common-forms-add '}>
                    <div className={'common-forms-search report-framework batch_billing_run'}>
                        <ReportHeader title={"Error Corrections"} />
                        <div className='outer-wrap'>
                            <div className='patient-search-form  patient_search_bottom_padding' id='report-criteria-container'>
                                <Form autoComplete='off'>
                                    <Grid>
                                        <Grid.Column
                                            computer={16}
                                            tablet={16}
                                            textAlign='left'
                                            className='accordionColumn'
                                            id='accordion-column'
                                        >
                                            <Accordion fluid styled>
                                                <Accordion.Title
                                                    active={controls_is_expended}
                                                    index={0}
                                                    onClick={this.controls_toggle}
                                                >
                                                    <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name='angle right' />
                                                    Search Criteria
                                                </Accordion.Title>
                                                <Accordion.Content active={controls_is_expended}>
                                                    <Grid>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field>
                                                                <label>
                                                                    Date of Service
                                                                </label>
                                                                <DateRangePickerComponent
                                                                    updateDatesChange={(from_date, end_date) =>
                                                                        this.range_date_change_handler(from_date, end_date)
                                                                    }
                                                                    startDate={search_criteria.from_date}
                                                                    endDate={search_criteria.to_date}
                                                                    error={is_error_date}
                                                                    id={'dos'}
                                                                    maxDate={moment()}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field >
                                                                <label>Charge ID</label>
                                                                <Input
                                                                    id='charge_id'
                                                                    name='charge_id'
                                                                    pattern='[0-9]*'
                                                                    value={search_criteria.charge_id || ""}
                                                                    onChange={(e) => this.handle_change(e)}
                                                                    type='text'
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field style={{ marginTop: 32 }}>
                                                                <CheckboxComponent
                                                                    name='show_inactive'
                                                                    id='show_inactive'
                                                                    onChange={e => this.handle_change(e)}
                                                                    checked={search_criteria.show_inactive}
                                                                    label={'Show Inactive'}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid>
                                                    <Grid>
                                                        <Grid.Column tablet={16} computer={16} textAlign='right'>
                                                            <Button
                                                                type='reset'
                                                                id='clear_button'
                                                                basic
                                                                onClick={this.clear_handler}
                                                                disabled={is_clear_disabled}
                                                                content={'Clear'}
                                                            />
                                                            <Button
                                                                id='correct_charge_button'
                                                                basic
                                                                onClick={this.correct_charge}
                                                                content={'Correct this Charge'}
                                                                type="button"
                                                                disabled={is_correct_charge_disabled}
                                                            />
                                                            <Button
                                                                id='search_error'
                                                                className='primary'
                                                                type='submit'
                                                                onKeyDown={this.handle_navigation_after_last_element}
                                                                style={{ marginRight: '0' }}
                                                                disabled={is_search_disabled}
                                                                content={'Search'}
                                                                onClick={this.handle_search}
                                                            />
                                                        </Grid.Column>
                                                    </Grid>
                                                </Accordion.Content>
                                            </Accordion>
                                        </Grid.Column>
                                    </Grid>
                                </Form>
                            </div>
                            {this.state.show_grid && (
                                <div id='report-scrollable-area' className='report-wrapper mar-t-10'>
                                    <GridView
                                        id={payment_constants.error_corrections_report_grid_id}
                                        row={this.grid_data.rows}
                                        column={this.grid_data.grid_conf}
                                        headerHeight={this.grid_header_height}
                                        style={{ height: '100%', display: 'flex', flexFlow: 'column' }}
                                        wrapperClass={'grid_wrapper_hierarchy'}
                                        wrapperStyle={{ width: '100%', height: '100%', display: 'flex', flex: 1 }}
                                        enableColResize={true}
                                        gridAutoHeight={true}
                                        get_grid_ref={this.get_grid_ref}
                                        detailsName='details'
                                        rowClassRules={this.page_metadata.rowClassRules}
                                        suppressSizeToFit={true}
                                        headerIdForTabNavigation="error_corrections_configuration_header_id"
                                    />
                                   
                                </div>
                            )}
                        </div>
                        {this.state.show_grid && (
                            <div
                                className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                                id='applicationFooterSticky'
                                style={{ paddingRight: 15 }}
                            >
                            </div>
                        )}
                    </div>
                </div>
            </LoaderComponent>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({

    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        patient_id: state.patient_details && state.patient_details.patient_header && state.patient_details.patient_header.data && state.patient_details.patient_header.data.id || ''
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorCorrectionsComponent));