import * as report_constants from '../report_constants';
import * as dateFormat from 'dateformat';
import { print_html_data_to_pdf } from '../../shared/utility';
import * as _ from 'lodash';

export const get_value_from_currency = (value: any) => {
  if (typeof value === 'string') {
    if (value.indexOf('(') != -1) {
      value = value ? '-' + value.replace(/[$,()]/g, '') : 0;
    } else {
      value = value ? value.replace(/[$,()]/g, '') : 0;
    }
  }
  return parseFloat(value);
};

//Function calls on click of print button when is a Crystal Report  type
export const on_print_button = (
  grid_ref: { api: any },
  title: string,
  eob_glossary: any[],
  eob_recoup: any[],
  auto_print = false
) => {
  let row_api = grid_ref.api;
  // Code for Mapping the Fields
  let perf_prov: any,
    group_id: any,
    patient: any,
    hic: any,
    account: any,
    icn: any,
    dos: string,
    pos: any,
    control_number: any,
    cpt: any,
    mods: any,
    units: any,
    billed_amount: any,
    allowed_amount: any,
    deducted_amount: any,
    co_insurance_name: any,
    adjust_code: any,
    adjust_amount: any,
    pay_amount: any,
    balance: any,
    moa_code: any,
    interest_amount: any,
    late_charge_amt: any,
    provider_adj_amt: any,
    previous_group_id: any,
    next_group_id: any,
    pat_resp: number,
    bil_amt: number,
    allow_amt: number,
    deduct_amt: number,
    bal: number,
    adj_amt: number,
    pay_amt: number,
    int_amt: number,
    late_amt: number,
    g_bil_amt: number,
    g_allow_amt: number,
    g_deduct_amt: number,
    g_bal: number,
    g_adj_amt: number,
    g_pay_amt: number,
    g_total_claims: number,
    g_prov_adj_amt: number,
    provider_number: any,
    g_int_amt: number,
    g_late_amt: number,
    prov_adj_amt: number,
    g_pat_resp: number,
    print_content = '';

  print_content += `
  <style>
  @media only screen {
    .reportPrintEOB table {
        margin: 0 !important;
        padding: 3px 3px !important;
        width: 100% !important;
        border: none !important;
    }
    table.reportPrintEOB{
      border-collapse: collapse;
    }
    .reportPrintEOB td {
        margin: 0 !important;
        padding: 3px 3px !important;
        border: none !important;
    }
    .reportPrintEOB2 td {
        margin: 0 !important;
        padding: 3px 3px !important;
        border-bottom: 2px solid #000 !important;
    }
    .page-break-style{
      margin-bottom: 30px
    }
    .w-100{
      width: 100%;
    }
    .reportPrintEOB table.page-break-style {
      padding: 0 !important;
      margin-bottom: 20px !important;
    }
  }
  </style>`;
  print_content += '<div class="w-100">';
  if (row_api.getModel().getRowCount() > 0) {
    let fRow = row_api.getRowNode(0);
    let eob_eft: any,
      eob_date: any,
      payer_name: string,
      payer_address: string,
      payer_city: string,
      payee_name: string,
      payee_address: string,
      payee_city: string,
      providerNumber: string;
      providerNumber = fRow.data['stringCol0'];
    eob_eft = fRow.data['stringCol14'];
    eob_date = dateFormat(fRow.data['datetimeCol0'], 'mm/dd/yyyy');
    payer_name = fRow.data['stringCol15'];
    payer_address = fRow.data['stringCol16'];
    payer_city = fRow.data['stringCol17'];
    payee_name = fRow.data['stringCol18'];
    payee_address = fRow.data['stringCol19'];
    payee_city = fRow.data['stringCol20'];
    title = payer_name + '<br>' + payer_address + '<br>' + payer_city; // Payer
    title +=
      '<br><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
      payee_name +
      '<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
      payee_address +
      '<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' +
      payee_city +
      '<br><br>'; // Payee
    print_content += title;
    print_content += '<table id="printTable" class="reportPrintEOB">';
    print_content += `
      <thead>
        <tr class="reportPrintEOB">
          <td style="width:15% !important;">PROVIDER #:</td>
          <td style="width:5% !important">${providerNumber}</td>
          <td style="width:5% !important;"></td>
          <td style="width:5% !important;"></td>
          <td style="width:5% !important;"></td>
          <td style="width:5% !important;"></td>
          <td style="width:10% !important;"></td>
          <td style="width:10% !important;"></td>
          <td style="width:10% !important;"></td>
          <td style="width:5% !important;"></td>
          <td style="width:5% !important;"></td>
          <td style="width:8% !important;"></td>
          <td style="width:12% !important;"></td>
        </tr>
        <tr class="reportPrintEOB">
          <td>CHECK/EFT#:</td>
          <td>${eob_eft}</td>
          <td></td>
          <td></td>
          <td></td>
          <td>DATE:</td>
          <td>${eob_date}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr class="reportPrintEOB">
          <td class="reportPrintEOB">PERF-PROV</td>
          <td>SERV-DATE</td>
          <td></td>
          <td>POS</td>
          <td>NOS</td>
          <td>PROC</td>
          <td>MODS</td>
          <td>BILLED</td>
          <td>ALLOWED</td>
          <td>DEDUCT</td>
          <td>COINS</td>
          <td class="reportPrintEOB">RC-AMT</td>
          <td class="reportPrintEOB">PROV PD</td>
        </tr>
        <tr class="reportPrintEOB2">
          <td class="reportPrintEOB">CNTL #</td>
          <td colspan="5"></td>
          <td colspan="7" class="reportPrintEOB">GRP</td>
        </tr>
      </thead>`;
    fRow = null;
    pat_resp = bil_amt = allow_amt = deduct_amt = bal = adj_amt = pay_amt = int_amt = late_amt = prov_adj_amt = 0;
    g_pat_resp = g_bil_amt = g_allow_amt = g_deduct_amt = g_bal = g_adj_amt = g_pay_amt = g_int_amt = g_late_amt = g_prov_adj_amt = 0;
    print_content += `
      <tbody>
        <tr>
          <td colspan="13" style="padding: 0 !important;">
            <table id="printTable" class="reportPrintEOB page-break-style">`;
    row_api.forEachNodeAfterFilterAndSort((data: { data: { [x: string]: any } }, index: number) => {
      (provider_number = data.data['stringCol0']),
        (perf_prov = data.data['stringCol1']),
        (eob_date = data.data['datetimeCol0']),
        (group_id = data.data['decimalCol0']),
        (patient = data.data['stringCol2']),
        (hic = data.data['stringCol3']),
        (account = data.data['stringCol4']),
        (icn = data.data['stringCol5']),
        (dos = data.data['datetimeCol1']),
        (pos = data.data['stringCol6']),
        (control_number = data.data['stringCol7']),
        (cpt = data.data['stringCol8']),
        (mods = data.data['stringCol9']),
        (units = data.data['stringCol10']),
        (billed_amount = data.data['decimalCol1']),
        (allowed_amount = data.data['decimalCol2']),
        (deducted_amount = data.data['decimalCol3']),
        (co_insurance_name = data.data['stringCol11']),
        (adjust_code = data.data['stringCol12']),
        (adjust_amount = data.data['decimalCol5']),
        (pay_amount = data.data['decimalCol6']),
        (balance = data.data['decimalCol7']),
        (moa_code = data.data['stringCol13']),
        (interest_amount = data.data['decimalCol8']),
        (late_charge_amt = data.data['decimalCol9']),
        (provider_adj_amt = data.data['decimalCol10']);
      //  group header
      if (index == 0) {
        g_total_claims = 1;
        print_content += `
                      <tr class="reportPrintEOB">
                        <td style="width:15% !important;"></td>
                        <td style="width:5% !important"></td>
                        <td style="width:5% !important;"></td>
                        <td style="width:5% !important;"></td>
                        <td style="width:5% !important;"></td>
                        <td style="width:5% !important;"></td>
                        <td style="width:10% !important;"></td>
                        <td style="width:10% !important;"></td>
                        <td style="width:10% !important;"></td>
                        <td style="width:5% !important;"></td>
                        <td style="width:5% !important;"></td>
                        <td style="width:8% !important;"></td>
                        <td style="width:12% !important;"></td>
                      </tr>
                      <tr class="reportPrintEOB">
                        <td class="reportPrintEOB">NAME</td>
                        <td >${patient}</td>
                        <td></td>
                        <td style="text-align:right;">HIC: </td>
                        <td>${hic}</td>
                        <td style="text-align:right;">ACNT</td>
                        <td >${account}</td>
                        <td style="text-align:right;">ICN </td>
                        <td>${icn}</td>
                        <td colspan=2>ASG Y MOA</td>
                        <td colspan=2 class="reportPrintEOB">${moa_code}</td>
                      </tr>`;
      } else {
        let pRow = row_api.getRowNode(index - 1);
        previous_group_id = pRow.data['decimalCol0'];
        // decimalCol0  Group_id
        if (group_id != previous_group_id) {
          pat_resp = bil_amt = allow_amt = deduct_amt = bal = adj_amt = pay_amt = int_amt = late_amt = prov_adj_amt = 0;
          g_total_claims += 1;
          print_content += `
                      <tr>
                        <td class="reportPrintEOB">NAME</td>
                        <td colspan=2>${patient}</td>
                        <td style="text-align:right;">HIC: </td>
                        <td>${hic}</td>
                        <td style="text-align:right;">ACNT</td>
                        <td >${account}</td>
                        <td style="text-align:right;">ICN </td>
                        <td>${icn}</td>
                        <td colspan=2>ASG Y MOA</td>
                        <td colspan=2 class="reportPrintEOB">${moa_code}</td>
                      </tr>`;
        }
      }
      // group details
      print_content += `
                  <tr class="reportPrintEOB">
                    <td class="reportPrintEOB">${perf_prov}</td>
                    <td>${dos === '' ? '' : dateFormat(dos, 'mmdd')}</td>
                    <td>${dos === '' ? '' : dateFormat(dos, 'mmddyy')}</td>
                    <td>${pos} </td>
                    <td> ${units}</td>
                    <td>${cpt}</td>
                    <td>${mods}</td>
                    <td>${billed_amount}</td>
                    <td>${allowed_amount}</td>
                    <td>${deducted_amount}</td>
                    <td>${balance}</td>
                    <td >${adjust_amount}</td>
                    <td  class="reportPrintEOB">${pay_amount}</td>
                  </tr>`;
      print_content += `
                  <tr class="reportPrintEOB">
                    <td class="reportPrintEOB">${control_number}</td>
                    <td colspan=5></td>
                    <td class="reportPrintEOB">${adjust_code}</td>
                    <td colspan=6></td>
                  </tr>`;
      pat_resp += get_value_from_currency(balance) + get_value_from_currency(deducted_amount);
      bil_amt += get_value_from_currency(billed_amount);
      allow_amt += get_value_from_currency(allowed_amount);
      deduct_amt += get_value_from_currency(deducted_amount);
      bal += get_value_from_currency(balance);
      adj_amt += get_value_from_currency(adjust_amount);
      pay_amt += get_value_from_currency(pay_amount);
      int_amt += get_value_from_currency(interest_amount);
      late_amt += get_value_from_currency(late_charge_amt);
      prov_adj_amt += get_value_from_currency(provider_adj_amt);

      // Report Totals
      g_pat_resp += get_value_from_currency(balance) + get_value_from_currency(deducted_amount);
      g_bil_amt += get_value_from_currency(billed_amount);
      g_allow_amt += get_value_from_currency(allowed_amount);
      g_deduct_amt += get_value_from_currency(deducted_amount);
      g_bal += get_value_from_currency(balance);
      g_adj_amt += get_value_from_currency(adjust_amount);
      g_pay_amt += get_value_from_currency(pay_amount);
      g_int_amt += get_value_from_currency(interest_amount);
      g_late_amt += get_value_from_currency(late_charge_amt);
      g_prov_adj_amt += get_value_from_currency(provider_adj_amt);

      let nRow = row_api.getRowNode(index + 1);
      next_group_id = nRow != undefined ? nRow.data['decimalCol0'] : 0;

      //  group footer
      if (nRow == undefined || group_id != next_group_id) {
        print_content += `
                    <tr class="reportPrintEOB">
                      <td class="reportPrintEOB">PAT RESP</td>
                      <td >${report_constants.currencyFormatter(pat_resp)}</td>
                      <td colspan="3">CLAIM TOTALS</td>
                      <td></td>
                      <td></td>
                      <td>${report_constants.currencyFormatter(bil_amt)}</td>
                      <td >${report_constants.currencyFormatter(allow_amt)}</td>
                      <td>${report_constants.currencyFormatter(deduct_amt)}</td>
                      <td>${report_constants.currencyFormatter(bal)}</td>
                      <td >${report_constants.currencyFormatter(adj_amt)}</td>
                      <td class="reportPrintEOB">${report_constants.currencyFormatter(pay_amt)}</td>
                    </tr>
                    <tr>
                      <td colspan="3" class="reportPrintEOB">ADJ TO TOTAL: PREV P</td>
                      <td colspan="4">INTEREST&nbsp;&nbsp;&nbsp;<b>${report_constants.currencyFormatter(int_amt)}</b></td>
                      <td colspan="5">LATE FILING&nbsp;&nbsp;&nbsp;<b>${report_constants.currencyFormatter(late_amt)}</b></td>
                      <td class="reportPrintEOB"><b>${report_constants.currencyFormatter(pay_amt)}&nbsp;&nbsp;&nbsp;NET</b></td>
                    </tr>`;
        if (row_api.getModel().getRowCount() - 1 !== index || row_api.getModel().getRowCount() === index + 1) {
          if ((next_group_id && group_id != next_group_id) || next_group_id == 0) {
             print_content += `
                    <tr class="reportPrintEOB2 forwarded">
                      <td class="reportPrintEOB2" colspan="5">CLAIM INFORMATION FORWARDED</td>
                      <td colspan="8" class="reportPrintEOB2">${co_insurance_name}</td>
                    </tr>`;
          }
          pat_resp = bil_amt = allow_amt = deduct_amt = bal = adj_amt = pay_amt = int_amt = late_amt = 0;
        }
      }
    });
    print_content += `
            </table>
          </td>
        </tr>
      </tbody>
    </table>`;

    // Grand Totals
    print_content += `
      <table id="TtblTotal" class="reportPrintEOB" ${
        row_api.getModel().getRowCount() > 0 ? 'style="margin-top: 0 "' : ''
      }>
        <tr>
          <td class="reportPrintEOB">TOTALS</td>
          <td># OF CLAIMS</td>
          <td>BILLED AMT</td>
          <td>ALLOWED AMT</td>
          <td>DEDUCT AMT</td>
          <td>COINS AMT</td>
          <td>TOTAL RC-AMT</td>
          <td>PROV PD AMT</td>
          <td>PROV ADJ-AMT</td>
          <td class="reportPrintEOB">CHECK AMT</td>
        </tr>
        <tr>
          <td class="reportPrintEOB"></td>
          <td>${g_total_claims}</td>
          <td>${report_constants.currencyFormatter(g_bil_amt)}</td>
          <td >${report_constants.currencyFormatter(g_allow_amt)}</td>
          <td>${report_constants.currencyFormatter(g_deduct_amt)}</td>
          <td>${report_constants.currencyFormatter(g_bal)}</td>
          <td>${report_constants.currencyFormatter(g_adj_amt)}</td>
          <td >${report_constants.currencyFormatter(g_pay_amt)}</td>
          <td >${report_constants.currencyFormatter(g_prov_adj_amt)}</td>
          <td class="reportPrintEOB">${report_constants.currencyFormatter(g_pay_amt - g_prov_adj_amt)}</td>
        </tr>
      </table>
      `;
  }

  // Adjustment footer
  print_content += `
    <br><br>PROVIDER ADJ DETAILS:<br>
    <table id="printProvTbl" class="reportPrintEOB">
      <tr>
        <td class="reportPrintEOB">PLB REASON CODE</td>
        <td>FCN</td>
        <td>HIC</td>
        <td class="reportPrintEOB">AMOUNT</td>
      </tr>
    `;
  eob_recoup.map((item) => {
    print_content += `
      <tr>
        <td class="reportPrintEOB" >${item.reason}</td>
        <td>${item.fcn}</td>
        <td>${item.hic_id}</td>
        <td class="reportPrintEOB">${report_constants.currencyFormatter(item.amt)}</td>
      </tr>`;
  });
  print_content += `</table>`;

  // GLOSSARY footer
  print_content += `
    <br><br>GLOSSARY: Group, Reason, MOA, Remark and Adjustment Code<br>
    <table id = "printGlosTbl" class="reportPrintEOB">`;
  eob_glossary.map((item) => {
    print_content += `
      <tr>
        <td class="reportPrintEOB" >${item.adj_code}</td>
        <td class="reportPrintEOB">${item.description}</td>
      </tr>`;
  });
  print_content += `
  </table>
  <br>
  </div>`;
  if (auto_print) {
    return print_content;
  } else {
    print_html_data_to_pdf(print_content, 'print_report_button', '835 Company Summary Detail');
  }
};

export const get_grid_data_for_eob = (grid_params:any) => {
  let grid_data: any = {
      groups: [],
      g_totals: {}
  };
  grid_params.api.forEachNodeAfterFilterAndSort(({ data: {
      stringCol0: provider_number,
      stringCol1: perf_prov,
      datetimeCol0: eob_date,
      decimalCol0: group_id,
      stringCol2: patient,
      stringCol3: hic,
      stringCol4: account,
      stringCol5: icn,
      datetimeCol1: dos,
      stringCol6: pos,
      stringCol7: control_number,
      stringCol8: cpt,
      stringCol9: mods,
      stringCol10: units,
      decimalCol1: billed_amount,
      decimalCol2: allowed_amount,
      decimalCol3: deducted_amount,
      stringCol11: co_insurance_name,
      stringCol12: adjust_code,
      decimalCol5: adjust_amount,
      decimalCol6: pay_amount,
      decimalCol7: balance,
      stringCol13: moa_code,
      decimalCol8: interest_amount,
      decimalCol9: late_charge_amt,
      decimalCol10: provider_adj_amt,
      stringCol0: providerNumber,
      stringCol14: eob_eft,
      stringCol15: payer_name,
      stringCol16: payer_address,
      stringCol17: payer_city,
      stringCol18: payee_name,
      stringCol19: payee_address,
      stringCol20: payee_city
  } }) => {
      grid_data.groups.push({
          provider_number,
          perf_prov,
          eob_date,
          group_id,
          patient,
          hic,
          account,
          icn,
          dos,
          pos,
          control_number,
          cpt,
          mods,
          units,
          billed_amount,
          allowed_amount,
          deducted_amount,
          co_insurance_name,
          adjust_code,
          adjust_amount,
          pay_amount,
          balance,
          moa_code,
          interest_amount,
          late_charge_amt,
          provider_adj_amt,
          pat_resp: get_value_from_currency(balance) + get_value_from_currency(deducted_amount),
          bil_amt: get_value_from_currency(billed_amount),
          allow_amt: get_value_from_currency(allowed_amount),
          deduct_amt: get_value_from_currency(deducted_amount),
          bal: get_value_from_currency(balance),
          adj_amt: get_value_from_currency(adjust_amount),
          pay_amt: get_value_from_currency(pay_amount),
          int_amt: get_value_from_currency(interest_amount),
          late_amt: get_value_from_currency(late_charge_amt),
          prov_adj_amt: get_value_from_currency(provider_adj_amt),
          providerNumber,
          eob_eft,
          payer_name,
          payer_address,
          payer_city,
          payee_name,
          payee_address,
          payee_city
      });
  })
  grid_data.g_totals = {
      g_total_claims: grid_data.groups.length,
      g_pat_resp: _.sumBy(grid_data.groups, 'pat_resp'),
      g_bil_amt: _.sumBy(grid_data.groups, 'bil_amt'),
      g_allow_amt: _.sumBy(grid_data.groups, 'allow_amt'),
      g_deduct_amt: _.sumBy(grid_data.groups, 'deduct_amt'),
      g_bal: _.sumBy(grid_data.groups, 'bal'),
      g_adj_amt: _.sumBy(grid_data.groups, 'adj_amt'),
      g_pay_amt: _.sumBy(grid_data.groups, 'pay_amt'),
      g_int_amt: _.sumBy(grid_data.groups, 'int_amt'),
      g_late_amt: _.sumBy(grid_data.groups, 'late_amt'),
      g_prov_adj_amt: _.sumBy(grid_data.groups, 'prov_adj_amt')
  };
  grid_data.groups = _.groupBy(grid_data.groups, (data:any) => data.group_id);
  Object.keys(grid_data.groups).map((key) => {
      grid_data.groups[key] = {
          data: grid_data.groups[key],
          totals: {
              pat_resp: _.sumBy(grid_data.groups[key], 'pat_resp'),
              bil_amt: _.sumBy(grid_data.groups[key], 'bil_amt'),
              allow_amt: _.sumBy(grid_data.groups[key], 'allow_amt'),
              deduct_amt: _.sumBy(grid_data.groups[key], 'deduct_amt'),
              bal: _.sumBy(grid_data.groups[key], 'bal'),
              adj_amt: _.sumBy(grid_data.groups[key], 'adj_amt'),
              pay_amt: _.sumBy(grid_data.groups[key], 'pay_amt'),
              int_amt: _.sumBy(grid_data.groups[key], 'int_amt'),
              late_amt: _.sumBy(grid_data.groups[key], 'late_amt'),
              prov_adj_amt: _.sumBy(grid_data.groups[key], 'prov_adj_amt'),
          }
      }
  });
  grid_data.g_totals['g_total_claims'] = Object.keys(grid_data.groups).length;
  return grid_data;
};

export const on_print_button_for_eob_report = (
  grid_data: any,
  eob_glossary: any[],
  eob_recoup: any[],
  auto_print = false
) => {
  let print_content = `<style>@media only screen{.reportPrintEOB table{margin:0!important;padding:3px 3px!important;width:100%!important;border:none!important}table.reportPrintEOB{border-collapse:collapse}.reportPrintEOB td{margin:0!important;padding:3px 3px!important;border:none!important}.reportPrintEOB2 td{margin:0!important;padding:3px 3px!important;border-bottom:2px solid #000!important}.page-break-style{margin-bottom:30px}.w-100{width:100%}.reportPrintEOB table.page-break-style{padding:0!important;margin-bottom:20px!important}}</style><div class=w-100>`;
  let firstData = grid_data.groups[Object.keys(grid_data.groups)[0]].data[0];
  if (grid_data.groups[Object.keys(grid_data.groups)[0]].data.length > 0) {
    print_content += `${firstData.payer_name}<br>${firstData.payer_address}<br>${firstData.payer_city}<br><br>       ${firstData.payee_name}<br>       ${firstData.payee_address}<br>       ${firstData.payee_city}<br><br><table class=reportPrintEOB id=printTable><thead><tr class=reportPrintEOB><td style=width:15%!important>PROVIDER #:<td style=width:5%!important>${firstData.providerNumber}<td style=width:5%!important><td style=width:5%!important><td style=width:5%!important><td style=width:5%!important><td style=width:10%!important><td style=width:10%!important><td style=width:10%!important><td style=width:5%!important><td style=width:5%!important><td style=width:8%!important><td style=width:12%!important><tr class=reportPrintEOB><td>CHECK/EFT#:<td>${firstData.eob_eft}<td><td><td><td>DATE:<td>${firstData.eob_date}<td><td><td><td><td><td><tr class=reportPrintEOB><td class=reportPrintEOB>PERF-PROV<td>SERV-DATE<td><td>POS<td>NOS<td>PROC<td>MODS<td>BILLED<td>ALLOWED<td>DEDUCT<td>COINS<td class=reportPrintEOB>RC-AMT<td class=reportPrintEOB>PROV PD<tr class=reportPrintEOB2><td class=reportPrintEOB>CNTL #<td colspan=5><td class=reportPrintEOB colspan=7>GRP</thead><tbody><tr><td style=padding:0!important colspan=13>`;
    Object.keys(grid_data.groups).map((key, index) => {
      print_content += `<table class="reportPrintEOB page-break-style"id=printTable><tr class=reportPrintEOB><td style=width:15%!important><td style=width:5%!important><td style=width:5%!important><td style=width:5%!important><td style=width:5%!important><td style=width:5%!important><td style=width:10%!important><td style=width:10%!important><td style=width:10%!important><td style=width:5%!important><td style=width:5%!important><td style=width:8%!important><td style=width:12%!important><tr><td class=reportPrintEOB>NAME<td colspan=2>${grid_data.groups[key].data[0].patient}<td style=text-align:right>HIC:<td>${grid_data.groups[key].data[0].hic}<td style=text-align:right>ACNT<td>${grid_data.groups[key].data[0].account}<td style=text-align:right>ICN<td>${grid_data.groups[key].data[0].icn}<td colspan=2>ASG Y MOA<td colspan=2 class=reportPrintEOB>${grid_data.groups[key].data[0].moa_code}</td></tr>`;
      grid_data.groups[key].data.map((data) => {
        print_content += `<tr class=reportPrintEOB><td class=reportPrintEOB>${data.perf_prov}<td>${data.dos === '' ? '' : dateFormat(data.dos, 'mmdd')}<td>${data.dos === '' ? '' : dateFormat(data.dos, 'mmddyy')}<td>${data.pos}<td>${data.units}<td>${data.cpt}<td>${data.mods}<td>${data.billed_amount}<td>${data.allowed_amount}<td>${data.deducted_amount}<td>${data.balance}<td>${data.adjust_amount}<td class=reportPrintEOB>${data.pay_amount}<tr class=reportPrintEOB><td class=reportPrintEOB>${data.control_number}<td colspan=5><td class=reportPrintEOB>${data.adjust_code}<td colspan=6>`;
      })
      print_content += `<tr class=reportPrintEOB><td class=reportPrintEOB>PAT RESP<td>${report_constants.currencyFormatter(grid_data.groups[key].totals.pat_resp)}<td colspan=3>CLAIM TOTALS<td><td><td>${report_constants.currencyFormatter(grid_data.groups[key].totals.bil_amt)}<td>${report_constants.currencyFormatter(grid_data.groups[key].totals.allow_amt)}<td>${report_constants.currencyFormatter(grid_data.groups[key].totals.deduct_amt)}<td>${report_constants.currencyFormatter(grid_data.groups[key].totals.bal)}<td>${report_constants.currencyFormatter(grid_data.groups[key].totals.adj_amt)}<td class=reportPrintEOB>${report_constants.currencyFormatter(grid_data.groups[key].totals.pay_amt)}<tr><td class=reportPrintEOB colspan=3>ADJ TO TOTAL: PREV P<td colspan=4>INTEREST   <b>${report_constants.currencyFormatter(grid_data.groups[key].totals.int_amt)}</b><td colspan=5>LATE FILING   <b>${report_constants.currencyFormatter(grid_data.groups[key].totals.late_amt)}</b><td class=reportPrintEOB><b>${report_constants.currencyFormatter(grid_data.groups[key].totals.pay_amt)}   NET</b><tr class="reportPrintEOB2 forwarded"><td class=reportPrintEOB2 colspan=5>CLAIM INFORMATION FORWARDED<td colspan="8" class="reportPrintEOB2">${grid_data.groups[key].data[0].co_insurance_name}</tr></table>`;
    })
    // Grand Totals
    print_content += `</td></tr></tbody></table><table id="TtblTotal" class="reportPrintEOB" ${grid_data.groups[Object.keys(grid_data.groups)[0]].data.length > 0 ? 'style="margin-top: 0 "' : ''}><tr><td class=reportPrintEOB>TOTALS<td># OF CLAIMS<td>BILLED AMT<td>ALLOWED AMT<td>DEDUCT AMT<td>COINS AMT<td>TOTAL RC-AMT<td>PROV PD AMT<td>PROV ADJ-AMT<td class=reportPrintEOB>CHECK AMT<tr><td class=reportPrintEOB><td>${grid_data.g_totals.g_total_claims}<td>${report_constants.currencyFormatter(grid_data.g_totals.g_bil_amt)}<td>${report_constants.currencyFormatter(grid_data.g_totals.g_allow_amt)}<td>${report_constants.currencyFormatter(grid_data.g_totals.g_deduct_amt)}<td>${report_constants.currencyFormatter(grid_data.g_totals.g_bal)}<td>${report_constants.currencyFormatter(grid_data.g_totals.g_adj_amt)}<td>${report_constants.currencyFormatter(grid_data.g_totals.g_pay_amt)}<td>${report_constants.currencyFormatter(grid_data.g_totals.g_prov_adj_amt)}<td class=reportPrintEOB>${report_constants.currencyFormatter(grid_data.g_totals.g_pay_amt - grid_data.g_totals.g_prov_adj_amt)}</table>`;
  }
  // Adjustment footer
  print_content += `<br><br>PROVIDER ADJ DETAILS:<br><table class=reportPrintEOB id=printProvTbl><tr><td class=reportPrintEOB>PLB REASON CODE<td>FCN<td>HIC<td class=reportPrintEOB>AMOUNT`;
  eob_recoup.map((item) => {
    print_content += `<tr><td class=reportPrintEOB>${item.reason}<td>${item.fcn}<td>${item.hic_id}<td class=reportPrintEOB>${report_constants.currencyFormatter(item.amt)}`;
  });
  print_content += `</table>`;
  // GLOSSARY footer
  print_content += `<br><br>GLOSSARY: Group, Reason, MOA, Remark and Adjustment Code<br><table class=reportPrintEOB id=printGlosTbl>`;
  eob_glossary.map((item) => {
    print_content += `<tr><td class=reportPrintEOB>${item.adj_code}<td class=reportPrintEOB>${item.description}`;
  });
  print_content += `</table><br></div>`;
  if (auto_print) {
    return print_content;
  } else {
    print_html_data_to_pdf(print_content, 'print_report_button', '835 Company Summary Detail');
  }
};
