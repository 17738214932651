import * as React from 'react';
import { connect } from 'react-redux';

export class NotFoundPageComponent extends React.Component<any, any> {

    prevent_go_back = false;

    go_to_previous_page = () => {

        // flag to prevent user to go back to more than one levels
        // User can only go back to previous page
        if(this.prevent_go_back) return;

        this.prevent_go_back = true;

        // Moving back to previous page
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="not_found_image">
                <a onClick={this.go_to_previous_page}>
                    <img src={'./../src/assets/images/404-Lost-In-Space.png'} />
                </a>
            </div>
        );
    }
}
  
export default connect()(NotFoundPageComponent);

