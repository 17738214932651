import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Header, Modal } from 'semantic-ui-react';
import { clearTimeout, setTimeout } from 'timers';
import * as session_storage from '../../shared/session_storage_utility';
import {
  format_patient_name,
  modal_hide_on_print,
  modal_show_after_print,
  modal_show_after_print_ios,
  set_column_data_width,
  get_columns,
  execute_print,
  print_html_data_to_pdf,
  get_lauch_darkley_key_value
} from '../../shared/utility';
import * as report_constants from '../report_constants';
import { apply_feature_flip } from '../util/report_data_formatter';
import GridView from './../../shared/component/grid';
import {
  handle_focus_on_tab,
  set_focus_on_element_with_id,
  handle_navigation_on_modal
} from './../../shared/tab_navigation_utility';

enum report {
  ledger_full = 'ledger_full',
  payment_adjustment = 'payment_adjustment',
  charges = 'charges',
  claims = 'claims'
}

class LedgerFullPopUpComponent extends React.Component<any, any> {
  _is_mounted = false;
    charges_total_column_width: any;
    charges_grid_header_height: number = 35;
    payment_adjustment_total_column_width: any;
    payment_adjustment_grid_header_height: number = 35;
    claims_total_column_width: any;
    claim_grid_header_height: number = 35;
  charges_column_def: any;
  payment_adjustment_column_def: any;
  claims_column_def: any;
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      is_print_disabled: false,
      charges_grid_ref: {},
      patient_adjustments_grid_ref: {},
      claims_grid_ref: {}
    };
  }
  grid_width:any=''
  //Function calls on click of close button.
  close_modal = () => {
    this.props.on_pop_up_close();
  };

  componentDidMount() {
    this._is_mounted = true;
    handle_navigation_on_modal('ledger_full_popup_close_icon', 'printButton');
    this.charges_column_def = report_constants.report_charges_configuration.column_defs;
    this.payment_adjustment_column_def = report_constants.report_payment_adjustment_configuration.column_defs;
    this.claims_column_def = report_constants.report_claims_configuration.column_defs;

    //this.charges_total_column_width = set_column_data_width(
    //  this.props.pop_up_data.charges,
    //  report_constants.report_charges_configuration.column_defs
    //);
    //this.payment_adjustment_total_column_width = set_column_data_width(
    //  this.props.pop_up_data.payment_adjustment,
    //  report_constants.report_payment_adjustment_configuration.column_defs
    //);
    //this.claims_total_column_width = set_column_data_width(
    //  this.props.pop_up_data.claims,
    //  report_constants.report_claims_configuration.column_defs
    //);
    
    let charges_grid_dimension = get_columns(this.props.pop_up_data.charges, this.charges_column_def);
    this.charges_total_column_width = charges_grid_dimension.total_column_width;
    this.charges_grid_header_height = charges_grid_dimension.header_height;
  
    let payment_adjustment_grid_dimension = get_columns(this.props.pop_up_data.payment_adjustment, this.payment_adjustment_column_def);
    this.payment_adjustment_total_column_width = payment_adjustment_grid_dimension.total_column_width;
    this.payment_adjustment_grid_header_height = payment_adjustment_grid_dimension.header_height;

    let claims_grid_dimension = get_columns(this.props.pop_up_data.claims, this.claims_column_def);
    this.claims_total_column_width = claims_grid_dimension.total_column_width;
    this.claim_grid_header_height = claims_grid_dimension.header_height;

    this.grid_width = document.getElementsByClassName('description')[0].clientWidth;
  }

  componentWillUnmount() {
    this._is_mounted = false;
    if (this.state.timeout_charges) {
      clearTimeout(this.state.timeout_charges);
    }
    if (this.state.timeout_claims) {
      clearTimeout(this.state.timeout_claims);
    }
    if (this.state.timeout_adjustment) {
      clearTimeout(this.state.timeout_adjustment);
    }
  }

  render() {
    return (
      <div className='item'>
        <Modal
          onClose={this.close_modal}
          centered={false}
          className='ledger-report-popup'
          open={this.props.show_modal}
          closeIcon
          closeOnDimmerClick={false}
          id='printPopup'
        >
          <Modal.Header dangerouslySetInnerHTML={{ __html: this.props.report_name }}/>
          <Modal.Content>
            <Modal.Description>
              <Header as='h3'>Charges</Header>
              <GridView
                id={report_constants.report_charges_configuration.id}
                row={this.props.pop_up_data.charges}
                style={{ height: '150px' }}
                column={this.charges_column_def}
                suppressMovableColumns={false}
                enableColResize={true}
                emptyMessage={report_constants.messages.no_records_found}
                get_grid_ref={this.get_charges_grid_ref}
                headerHeight={this.charges_grid_header_height}
                onGridOut={() => {
                  set_focus_on_element_with_id(report_constants.report_payment_adjustment_header_id);
                }}
                headerIdForTabNavigation={report_constants.report_charges_grid_header_id}
              />
              <Header as='h3'>Payments and Adjustments</Header>
              <GridView
                id={report_constants.report_payment_adjustment_configuration.id}
                style={{ height: '150px' }}
                row={this.props.pop_up_data.payment_adjustment}
                column={this.payment_adjustment_column_def}
                suppressMovableColumns={false}
                suppressColumnVirtualisation={true}
                enableColResize={true}
                emptyMessage={report_constants.messages.no_records_found}
                get_grid_ref={this.get_patient_adjustments_grid_ref}
                headerHeight={this.payment_adjustment_grid_header_height}
                onGridOut={() => {
                  set_focus_on_element_with_id(report_constants.report_claims_header_id);
                }}
                headerIdForTabNavigation={report_constants.report_payment_adjustment_header_id}
              />
              <Header as='h3'>Claims</Header>
              <GridView
                id={report_constants.report_claims_configuration.id}
                style={{ height: '150px' }}
                row={this.props.pop_up_data.claims}
                column={this.claims_column_def}
                suppressMovableColumns={false}
                enableColResize={true}
                emptyMessage={report_constants.messages.no_records_found}
                get_grid_ref={this.get_claims_grid_ref}
                headerHeight={this.claim_grid_header_height}
                headerIdForTabNavigation={report_constants.report_claims_header_id}
              />
            </Modal.Description>
            <Grid className='mar-t-0'>
              <Grid.Column computer={16} textAlign='right'>
                <Button type='reset' onClick={this.close_modal} basic>
                  Close
                </Button>
                <Button
                  type='submit'
                  id='printButton'
                  onKeyDown={event => {
                    handle_focus_on_tab(event, 'ledger_full_popup_close_icon');
                  }}
                  onClick={this.on_print_button}
                  disabled={this.state.is_print_disabled}
                  primary
                >
                  Print
                </Button>
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>
      </div>
    );
  }

  //Function calls on click of print button.
  on_print_button = () => {
    var printContent = '';
    var company_name =
      this.props.user_login_details.user_data.data.gpms_code +
      '-' +
      this.props.user_login_details.user_data.data.company_name;
    var patient_name = format_patient_name(this.props.selected_patient.data.name);
    var account_no = session_storage.get('active_patient');
    var title =
      '<div id="companyName">' +
      company_name +
      '</div>' +
      '<div id=ledgerFullTitle> <div id="title">DOS Ledger Details</div>' +
      '<div>Charge Id :' +
      this.props.row_params.charge_id +
      '</div>' +
      '<div>DOS :' +
      this.props.row_params.dos +
      '</div>' +
      '<div>Location :' +
      this.props.row_params.pos +
      '</div>' +
      '<div>Provider :' +
      this.props.row_params.provider_name +
      '</div>' +
      '</div >' +
      '<div id="patientName">' +
      patient_name +
      '<div>' +
      '<div id="patientId">Account :' +
      account_no +
      '</div>';

    printContent += title;
    printContent += '<div style="font-weight: bold;font-size:16px">Charges</div><br/>';
    printContent += '<div class="reportPrint"><table><thead><tr>';
    var row_api = this.state.charges_grid_ref.api;
    var col_api = this.state.charges_grid_ref.columnApi;
    var header_data = col_api.getAllColumns();
    //Filter out hidden columns
      header_data = header_data.filter((value) => { if (!value.colDef.hide || value.colDef.hide == false) return value; });

    header_data.forEach(function(value, index) {
      printContent += '<th>' + value.colDef.headerName + '</th>';
    });

    printContent += '</tr></thead>';
    if (row_api.getModel().getRowCount() > 0) {
      row_api.forEachNodeAfterFilterAndSort(function(data, index) {
        printContent += '<tr>';
        header_data.forEach(function(colData, index) {
          printContent += '<td>' + data.data[colData.colDef.field] + '</td>';
        });
        printContent += '</tr>';
      });
    } else {
      printContent +=
        '<tr><td colspan="100%" class="norecords">' + report_constants.messages.no_records_found + '</td></tr>';
    }

    printContent += '</table></div><br/>';

    printContent += '<div style="font-weight: bold;font-size:16px">Payments and Adjustments</div><br/>';
    printContent += '<div class="reportPrint"><table><thead><tr>';
    row_api = this.state.patient_adjustments_grid_ref.api;
    col_api = this.state.patient_adjustments_grid_ref.columnApi;
    header_data = col_api.getAllColumns();

    header_data.forEach(function(value, index) {
      printContent += '<th>' + value.colDef.headerName + '</th>';
    });
    printContent += '</tr></thead>';

    if (row_api.getModel().getRowCount() > 0) {
      row_api.forEachNodeAfterFilterAndSort(function(data, index) {
        printContent += '<tr>';
        header_data.forEach(function(colData, index) {
          printContent += '<td>' + data.data[colData.colDef.field] + '</td>';
        });
        printContent += '</tr>';
      });
    } else {
      printContent +=
        '<tr><td colspan="100%" class="norecords">' + report_constants.messages.no_records_found + '</td></tr>';
    }
    printContent += '</table></div><br>';

    printContent += '<div style="font-weight: bold;font-size:16px">Claims</div><br/>';
    printContent += '<div class="reportPrint"><table><thead><tr>';
    row_api = this.state.claims_grid_ref.api;
    col_api = this.state.claims_grid_ref.columnApi;
    header_data = col_api.getAllColumns();

    header_data.forEach(function(value, index) {
      printContent += '<th>' + value.colDef.headerName + '</th>';
    });
    printContent += '</tr></thead>';
    if (row_api.getModel().getRowCount() > 0) {
      row_api.forEachNodeAfterFilterAndSort(function(data, index) {
        printContent += '<tr>';
        header_data.forEach(function(colData, index) {
          printContent += '<td>' + data.data[colData.colDef.field] + '</td>';
        });
        printContent += '</tr>';
      });
    } else {
      printContent +=
        '<tr><td colspan="100%" class="norecords">' + report_constants.messages.no_records_found + '</td></tr>';
    }
    printContent += '</table></div>';
    print_html_data_to_pdf(printContent,'printButton', 'DOS Ledger Details');
    /*
    modal_hide_on_print(printContent);
    if (window.print) {
      if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
        setTimeout(function() {
          execute_print();
        }, 100);

        setTimeout(function() {
          modal_show_after_print_ios();
        }, 5000);
      } else {
        if (window.matchMedia) {
          var mediaQueryList = window.matchMedia('print');
          mediaQueryList.addListener(function(mql) {
            if (mql.matches) {
            } else {
              afterPrint();
            }
          });
        }
        var afterPrint = () => modal_show_after_print();
        // These are for Firefox
        window.onafterprint = afterPrint;
        execute_print();
      }
    }
    */
  };

  //Function calls on intialization of Ag-Grid and catch its reference.
  get_charges_grid_ref = grid_params => {
    if (this._is_mounted) {
      this.setState({
        charges_grid_ref: grid_params
      });

      set_focus_on_element_with_id(report_constants.report_charges_grid_header_id);
    }

    if (this.charges_total_column_width > this.grid_width) {
      this.charges_column_def.filter(item => {
        grid_params.columnApi.setColumnWidth(item.field, item.width, false);
      });
    }
  };

  //Function calls on intialization of Ag-Grid and catch its reference.
  get_claims_grid_ref = grid_params => {
    if (this._is_mounted) {
      this.setState({
        claims_grid_ref: grid_params
      });
    }

    if (this.claims_total_column_width > this.grid_width) {
      this.charges_column_def.filter(item => {
        grid_params.columnApi.setColumnWidth(item.field, item.width, false);
      });
    }
  };

  //Function calls on intialization of Ag-Grid and catch its reference.
  get_patient_adjustments_grid_ref = grid_params => {
    if (this._is_mounted) {
      this.setState({
        patient_adjustments_grid_ref: grid_params
      });
    }

    if (this.payment_adjustment_total_column_width > this.grid_width) {
      this.payment_adjustment_column_def.filter(item => {
        grid_params.columnApi.setColumnWidth(item.field, item.width, false);
      });
    }
  };
}

//Get user and login details from store.
const mapStateToProps = state => {
  return {
    user_login_details: state.user_login_details,
    user_details: state.user_login_details,
    selected_patient: state.patient_details.patient_header
  };
};

export default connect(mapStateToProps)(LedgerFullPopUpComponent);
