import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import { user_logout } from '../action/auth_logout_action';
import * as local_storage from '../../shared/local_storage_utility';
import * as session_storage from '../../shared/session_storage_utility';
import { detect_ie_11 } from '../../shared/utility';
import * as global_constant from '../../global_constants';

const WAITING_TIMEOUT = 100;
export class AuthLogoutComponent extends React.Component<any, any> {    
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    UNSAFE_componentWillMount() {
        this.force_logout();
    }

    navigate_to_login = () => {
        sessionStorage.removeItem('sso_user_login');
        const queryParams = new URLSearchParams(window.location.search)
        const disable_redirect = JSON.parse(queryParams.get('disabled_redirect'));
        if (!disable_redirect) {
            window.location.href = global_constant.sso_config_data.sso_config.webpt_identity_logout;
        } else {
            this.setState({
                loading: false
            });
        }
    }

    force_logout = async () => {
        
        const logout = await this.props.logout();

        Object.keys(localStorage)
            .filter(key => key.startsWith("auth_data"))
            .forEach(key => localStorage.removeItem(key));
       
        local_storage.clear_all();
        session_storage.clear_all();

        // let other process to get completed
        setTimeout(() => {
            local_storage.deleteAllCookiesWithPath();

            if (detect_ie_11()) {
                local_storage.deleteAllCookies();
            }

            this.navigate_to_login();

        }, WAITING_TIMEOUT);

        // close other tabs as well
        if (detect_ie_11()) {
            localStorage.setItem('closeOtherWindow' + sessionStorage['sessionId'], sessionStorage['tabId']);
            // in the case of IE we are deleting  the key when listening in the on storage channel
            // localStorage.removeItem('closeOtherWindow' + sessionStorage['sessionId']);
        }
    }

    render() {
        return (
            <>
                <Dimmer active={this.state.loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        logout: user_logout
    }, dispatch)
}

const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthLogoutComponent))
