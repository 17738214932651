import * as moment from 'moment';
import * as React from "react";
import { connect } from "react-redux";
import { toastr } from 'react-redux-toastr';
import { Button, Checkbox, Dimmer, Form, Grid, Loader } from "semantic-ui-react";
import GridView from '../../../shared/component/grid';
import * as local_storage from '../../../shared/local_storage_utility';
import * as session_storage from '../../../shared/session_storage_utility';
import ClaimAdjCodeSearch from "../../constants/component/claim_adjustment_code_advanced_search";
import { advanced_search_payer, claim_adj_code_search, get_search_data } from '../action/constants_action';
import * as admin_payment_const from '../constants';
import { set_focus_on_element_with_id, set_focus_to_app_header } from './../../../shared/tab_navigation_utility';
import { get_columns } from './../../../shared/utility';
import * as global_constants from './../../../global_constants';
import { export_grid_data, print_grid_data, generateAuditDataEntryPayload } from '../../../reports/util/export_print_utility';
import * as ReportConstants from '../../../reports/report_constants';
class ClaimAdjCodeCompany extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            patient_id: session_storage.get("active_patient"),
            loading: false,
            control: null,
            search_form: {
                adj_code_id: '',
                payer_id: '',
                is_excluded: false,
                show_inactive :  false
            },
            claim_adj_search_data: null,
            payer_search_data: null,
            adj_code_selected_row: null,
            payer_selected_row: null,
            is_focused : true,
            grid_rows_count: 0,
            is_search_button_disabled: false
        };
        this.claimadj_codesearch = React.createRef();
        this.claimpayer_codesearch = React.createRef();
    }
    empty_search_form = null;
    adjustment_code_enum = "adjustment_code";
    payer_enum = "payer";
    page_name: string = "claim_adj_code_company";
    page_metadata = {
        search_header: "Claim Adjustment Reason Codes - Company Payer Override Search"
    }
    id = 0;
    search_timeout = null;
    search = "search";
    token = "";
    grid_ref = { api: null };
    total_column_width: number = 0;
    grid_header_height: number = 0;
    search_criteria: any = {
        adj_code_id: '',
        payer_id: '',
        is_excluded: false,
        show_inactive: false
    };
    adj_code_selected_row: null;
    payer_selected_row: null;
    _is_mount = false;
    claimadj_codesearch: any;
    claimpayer_codesearch: any;
    company_name: any;
    current_date: string;
    title: any;
    reportId: string = "0";

    UNSAFE_componentWillMount() {
        this.empty_search_form = { ...this.state.search_form };        
     }

    componentDidUpdate =(prevProps ,prevState)=>{
        if (prevProps.user_login_details != this.props.user_login_details) {
            this.set_default_focus();
            this.handle_tab_on_search();            
            this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        }
    }
    on_quick_search = async (params, type) => {
        let url = '';
        if (type == this.adjustment_code_enum) {
            url = admin_payment_const.claim_adjust_code.api.claim_adj_quick_search + "?keyword=" + params + "&pageSize=" + global_constants.constants.Quick_Search_Suggestion_List_Size;
        } else if (type == this.payer_enum) {
            url = admin_payment_const.claim_payer_search.api.payer_quick_search + "?keyword=" + params + "&page_size=" + global_constants.constants.Quick_Search_Suggestion_List_Size;
        }
        return await get_search_data(this.token, url);
    }

    on_advanced_search = (params ,type) => {

        if (this.adjustment_code_enum == type) {
            let url = admin_payment_const.claim_adjust_code.api.claim_adjcode_advanced_search;
            var q_str = Object.keys(params).map(key => {
                let key1 = key;
                if (key == 'page_size') {
                    key1 = "pageSize";
                } else if (key == "adjustment_code") {
                    key1 = "code"
                } else if (key == "adjustment_desc") {
                    key1 = "description"
                }
               return key1 + '=' + params[key]
            }).join('&');
            this.get_result(url+"?"+q_str);
        } else if (this.payer_enum == type) {
            params.Description = typeof params.Description == 'undefined' ? '' : params.Description;
            params.Outbound_id = typeof params.Outbound_id == 'undefined' ? '' : params.Outbound_id;
            params.Inbound_id = typeof params.Inbound_id == 'undefined' ? '' : params.Inbound_id;
            params.name = typeof params.name == 'undefined' ? '' : params.name;
            let url = admin_payment_const.claim_payer_search.api.payer_advanced_search;
            advanced_search_payer(this.token, url, params).then((res) => {
                let result = res.data;
                if (this._is_mount) {
                    this.setState({
                        payer_search_data: {
                            rows: result.data,
                            column: admin_payment_const.claim_payer_search.column_defs
                        }
                    })
                }
            }, error => {
                if (error.response && error.response.data && error.response.data.messages) {
                    toastr.error('', error.response.data.messages[0].message);
                }
            });
        };
         
    }

    get_result = (url) => {
        get_search_data(this.token, url).then((res) => {
            let result = res.data;
            if (result) {
                result = result.data.map((val, index) => {
                    val.is_active = val.is_active ? "Active" : "Inactive";
                    if (val.is_excluded) {
                        val.text_is_excluded = val.is_excluded ? "Is Excluded" : "";
                    }
                    return val;
                });
            }
            if (this._is_mount) {
                this.setState({
                    claim_adj_search_data: {
                        rows: result,
                        column: admin_payment_const.claim_adjust_code.column_def
                    }
                });
            }
        }, error => {
            if (error.response && error.response.data && error.response.data.messages) {
                toastr.error('', error.response.data.messages[0].message);
            }
        });
    }
    

    update_data = (data, type) => {
        if (type == this.adjustment_code_enum) {
            data && (data.is_excluded = data.is_excluded.toString())
            if (this._is_mount) {
                this.setState({
                    search_form: {
                        ...this.state.search_form, adj_code_id: data ? data.carc : ''
                    },
                    adj_code_selected_row: data,
                    is_focused: false
                }, () => {
                    document.getElementById('btn_search').focus();
                });
            }
        } else {
            if (this._is_mount) {
                this.setState({
                    search_form: {
                        ...this.state.search_form, payer_id: data ? data.payer_key_id : ''
                    }
                    ,
                    payer_selected_row: data,
                    is_focused: false
                }, () => {
                    document.getElementById('btn_search').focus();
                });
            }
        }
    }

    select_grid_checkbox = (flag) => {
        if (this.grid_ref.api !=null) {
            this.grid_ref.api.forEachNode((node) => {
                node.setSelected(true);
            });
        }
    }
    set_criteria_in_state = (search_criteria) => {
        let { search_form, payer_selected_row, adj_code_selected_row } = this.state
        for (let key in search_criteria) {
            search_form[key] = search_criteria[key]
            if (key == "adj_code_id" || key == "adj_code_selected_row")
                adj_code_selected_row = search_criteria.adj_code_selected_row
            if (key == "payer_id" || key == "payer_selected_row")
                payer_selected_row = search_criteria.payer_selected_row
        }
        this.setState({
            search_form,
            payer_selected_row,
            adj_code_selected_row
        }, () => {
            this.on_search();
        });
    }

    set_search_criteria_onsearch = () => {
        const { search_form, adj_code_selected_row, payer_selected_row } = this.state
        this.search_criteria = {
            show_inactive: search_form.show_inactive,
            is_excluded: search_form.is_excluded
        }
        if (search_form.adj_code_id != "") {
            this.search_criteria.adj_code_id = search_form.adj_code_id,
                this.search_criteria.adj_code_selected_row = adj_code_selected_row
        }
        if (search_form.payer_id != "") {
            this.search_criteria.payer_id = search_form.payer_id,
                this.search_criteria.payer_selected_row = payer_selected_row
        }

        session_storage.set('claim_adj_comp_reason_code_search_criteria', {
            search_criteria: this.search_criteria,
        });
    }
    on_search = () => {
      
        this.setState({ 
            loading: true, 
            grid_data :null,
            is_search_button_disabled: true 
        });
        const token = local_storage.get("auth_data").token_details.access_token;
        claim_adj_code_search(token, this.state.search_form).then(res => {
            var result =  res.data.data ? res.data.data : []
            result = result.map((val, index) => {
                val.adj_code_desc = val.adj_code_id ? val.adj_code_id.replace(val.adj_code_id, val.adj_code_id + " ") + " - " + val.adj_code_desc : val.adj_code_desc;
                val.is_active = val.is_active ? "Active" : "Inactive";
                val.text_is_excluded = val.is_excluded ? "Is Excluded" : "Not Excluded";
                 return val;
            });
            const grid_height = get_columns(result, admin_payment_const.claim_adjust_code_company.column_def);
            this.total_column_width = grid_height.total_column_width;
            this.grid_header_height = grid_height.header_height;
            var grid_data = {
                rows: result,
                grid_conf: admin_payment_const.claim_adjust_code_company.column_def,
                page_size: admin_payment_const.claim_adjust_code_company.page_size
            };
            //if (res.data.data != null && res.data.status) {
            //     grid_data = {
            //        rows: res.data.data,
            //    }
                
            //}
            if (this._is_mount) {
                this.setState({
                    grid_data: grid_data,
                    loading: false,
                    is_focused: false,
                    grid_rows_count: res.data.data && res.data.data.length,
                    is_search_button_disabled: false
                }, () => {
                    this.select_grid_checkbox(true);
                    //  setTimeout(() => {
                    document.getElementById('btn_search').focus();
                    // }, 400);
                });
                this.set_search_criteria_onsearch()
            }
        }, error => {
            if (this._is_mount) {
                this.setState({
                    loading: false ,
                    is_search_button_disabled: false
                });
            }
            if (error.response && error.response.data && error.response.data.messages) {
                toastr.error('', error.response.data.messages[0].message);
            }
        });
        this.props.history.push(`/payments/claim_adj_code_company/search`, { cancel: false });
    }
    componentWillUnmount = () => {
        document.body.classList.remove('admin-framework');
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[2] !== 'claim_adj_code_company' && session_storage.remove('claim_adj_comp_reason_code_search_criteria');
        }
        if(this.search_timeout) {
            clearTimeout(this.search_timeout)
        }
    };
    componentDidMount = () => {
        this._is_mount = true;
        document.body.classList.add('admin-framework');
        // this.empty_search_form = this.state.search_form;
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        let controls = {
            adjustment_code: {
                type: "adjustment_code",
                grid_config: {
                    rows: null,
                    column: admin_payment_const.claim_adjust_code.column_def
                },
                control_id: 'CLAIM_ADJUSTMENT_CODE',
                selected_row: null,
                error_message: 'No Record Found !',
                label: ["carc", "description"]
            },
            payer: {
                type: "payer",
                grid_config: {
                    rows: null,
                    column: admin_payment_const.claim_payer_search.column_defs
                },
                control_id: 'PAYER_DESCRIPTION',
                error_message: 'No Record Found !',
                label: ["name", "description"],
                selected_row: null,
            },
        }
        if (this._is_mount) {
            this.setState({
                control: controls
            });
        }   
        let claim_adj_comp_search_criteria = session_storage.get('claim_adj_comp_reason_code_search_criteria');
        this.search_criteria = claim_adj_comp_search_criteria && claim_adj_comp_search_criteria.search_criteria || this.search_criteria
        if (claim_adj_comp_search_criteria) {
            this.set_criteria_in_state(this.search_criteria)
        }
        this.set_default_focus();
        this.handle_tab_on_search();
    }

    //On double click of grid, it redirects user to view page.
    on_row_double_clicked = (selected_row_data) => {
        this.id = selected_row_data["id"];        
        this.props.history.push(`/payments/claim_adj_code_company/${this.id}`, { by_pass_prompt: false });
    }
    add_handler = (e) => {
        e.preventDefault()
        this.props.history.push(`/payments/claim_adj_code_company`);
    }

    get_grid_ref = (grid_params) => {
        this.setState({
            grid_params,
            grid_rows_count: grid_params.api.getModel().getRowCount()
        });  
        var grid_width = document.getElementsByClassName('admin-wrapper')[0].clientWidth;
        if (this.total_column_width > grid_width) {
            admin_payment_const.claim_adjust_code_company.column_def.filter(item => {
                grid_params.columnApi.setColumnWidth(item.field, item.width, false)
            })
        }
        this.grid_ref = grid_params;
    }

    clear_handle = () => {
        let initial_form = { ...this.empty_search_form }
        if (this._is_mount) {
            this.setState({
                search_form: initial_form,
                grid_data: null,
                adj_code_selected_row: null,
                payer_selected_row: null,
                grid_rows_count: 0
            });
        }
        this.search_criteria = {}
        this.claimadj_codesearch.clear_quick_search(this.claimadj_codesearch.props.control.type)
        this.claimpayer_codesearch.clear_quick_search(this.claimpayer_codesearch.props.control.type) 
        session_storage.remove('claim_adj_comp_reason_code_search_criteria');
    }

    set_default_focus = () => {
        let elem = document.getElementById('search-criteria-container');
        let all_form_elem = elem.getElementsByTagName('input');
        all_form_elem[0].focus();
    };
    
    handle_tab_on_search = () => {
        this.search_timeout = setTimeout(() => {
          let searchButton = document.getElementById('btn_search');
          let self:any = this;

          if(searchButton) {
            searchButton.addEventListener('keydown', function (event) {
                // apply a check where grid is empty
                if (!event.shiftKey && (self.row_data == null || (self.row_data.length == 0 && !self.state.show_grid))) {
                  set_focus_to_app_header(event);
                }
              });
          }
        }, 200);
    };

    on_grid_out = () => {
        set_focus_on_element_with_id("app_header_dropdown");
    }   
     
    update_report_rows_count = () => {
        this.setState({
          grid_rows_count: this.state.grid_params.api.getModel().getRowCount()
        });
    };   

  //Used to create the title for export and print.
  set_title = (separator) => {
    this.company_name = this.props.user_login_details.user_data.data.company_name;
    this.current_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
    this.title = this.page_metadata.search_header;
    return (this.title =
      this.title.replace(/<br>/g, separator) +
      separator +
      this.company_name +
      separator +
      this.current_date +
      separator +
      (this.state.grid_rows_count == 1
        ? this.state.grid_rows_count + ' record'
        : this.state.grid_rows_count + ' records') +
      separator +
      separator);
  };

  //Function calls on initialization of export report data
  on_export_button = () => {
    this.title = this.set_title('\n');
    this.page_name = this.page_name.replace(/<br>/g, '');
      export_grid_data(this.state.grid_params, this.title, this.page_name);
      this.saveReportEventAction(ReportConstants.ReportEventAction.Export);
  };

  //Function calls on initialization of Print report data
  on_print_button = () => {
    this.title = this.set_title('<br>');
      print_grid_data(this.state.grid_params, this.title, this.page_name);
      this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
  };

    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;

        const payload: ReportConstants.IPayloadForBillingAudit = {
            reportId: this.reportId,
            contextTitle: `${this.page_metadata.search_header} - ${ReportConstants.ReportEventAction[eventActionId]}`,
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: ReportConstants.ReportEntityTypeId.D_CLAIM,
            data: { Records: Number(this.state.grid_params.api.rowModel.rowsToDisplay.length) }
        }

        const reqBody = generateAuditDataEntryPayload(payload);
        ReportConstants.saveAuditPrintExport(reqBody, accessToken);
    };

    render() {
        return (<React.Fragment>
            <Dimmer active={this.state.loading}>
                <Loader size='massive'>Loading</Loader>
            </Dimmer>
            <div className={'admin-wrapper'}>
                <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                    <Grid.Column computer={16}>
                        <h3 className='ui header left aligned' dangerouslySetInnerHTML={{ __html: this.page_metadata.search_header }} />
                    </Grid.Column>
                </Grid>
                <div id='admin-scrollable-area' className='wrapper' style={this.state.grid_data ? { flex: 1 } : {}}>
                    <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container'>
                        <Form autoComplete='off' onSubmit={this.on_search}>
                            <Grid>
                                <Grid.Column mobile={8} tablet={8} computer={4} >
                                    <Form.Field className="advance-quick-search">
                                        <label>Claim Adjustment Reason Code</label>
                                        <ClaimAdjCodeSearch
                                            control={this.state.control ? this.state.control.adjustment_code : null}
                                            update_data={this.update_data}
                                            search_result={this.state.claim_adj_search_data}
                                            on_quick_search={this.on_quick_search}
                                            on_advanced_search={this.on_advanced_search}
                                            selected_row={this.state.adj_code_selected_row}
                                            onRef={instance => { this.claimadj_codesearch = instance; }}
                                            is_focused={this.state.is_focused}
                                            headerIdForGridTabNavigation={admin_payment_const.claim_adjust_code_header_id}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column mobile={8} tablet={8} computer={4} >
                                    <Form.Field className="advance-quick-search">
                                        <label>Payer</label>
                                        <ClaimAdjCodeSearch
                                            on_quick_search={this.on_quick_search}
                                            on_advanced_search={this.on_advanced_search}
                                            search_result={this.state.payer_search_data}
                                            control={this.state.control ? this.state.control.payer : null}
                                            onRef={instance => { this.claimpayer_codesearch = instance; }}
                                            update_data={this.update_data} selected_row={this.state.payer_selected_row}
                                            headerIdForGridTabNavigation={admin_payment_const.claim_payer_search_header_id} />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={8} computer={3} verticalAlign={'bottom'} textAlign={'left'}>
                                    <Form.Field className="">
                                        <label></label>
                                        <Checkbox label='Is Excluded' checked={this.state.search_form.is_excluded} onChange={() =>
                                            this.setState((prev) => ({
                                                search_form: { ...prev.search_form, is_excluded: !this.state.search_form.is_excluded }
                                            }))
                                        } />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={8} computer={4} verticalAlign={'bottom'} textAlign={'left'}>
                                    <Form.Field className="">
                                        <label></label>
                                        <Checkbox label='Show Inactive' checked={this.state.search_form.show_inactive} onChange={() =>
                                            this.setState((prev) => ({
                                                search_form: {
                                                    ...prev.search_form, show_inactive: !this.state.search_form.show_inactive
                                                }
                                            }))
                                        } />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid>
                            <Grid style={{ margin: '0 -17px' }}>
                                <Grid.Column computer={16} textAlign='right'>
                                    <Button id='clear_button_id' type='button' onClick={this.clear_handle} basic>Clear</Button>
                                    <Button id='new_button_id' type='button' onClick={e => this.add_handler(e)} basic>New</Button>
                                    <Button id='btn_search' type='submit' primary disabled={this.state.is_search_button_disabled}>Search</Button>
                                </Grid.Column>
                            </Grid>
                            {this.state.grid_data && (
                                <Grid style={{ marginTop: '-1rem', marginBottom: 0 }}>
                                    <Grid.Column tablet={5} computer={4} textAlign='left'>
                                    <p style={{ fontSize: '16px' }}>Search Results</p>
                                    </Grid.Column>
                                    <Grid.Column tablet={5} computer={8} textAlign='center'>
                                    {
                                        <p style={{ fontSize: '16px', minHeight: 22 }}>
                                        {`${this.state.grid_rows_count} ${
                                            this.state.grid_rows_count == 1 ? ' record shown' : ' records shown'
                                        }`}
                                        </p>
                                    }
                                    </Grid.Column>
                                    <Grid.Column tablet={2} computer={4} textAlign='right' />
                                </Grid>
                            )}
                        </Form>
                    </div>
                    {this.state.grid_data && !this.state.loading && (
                        <GridView
                            id="claim_adj_comp_pay_ov"
                            row={this.state.grid_data.rows}
                            column={this.state.grid_data.grid_conf}
                            selectionType={'single'}
                            style={{ height: '100%' }}
                            wrapperStyle={{ width: '100%', height: 0, display: 'flex', flex: '1 1 auto' }}
                            onRowDoubleClicked={this.on_row_double_clicked}
                            get_grid_ref={this.get_grid_ref}
                            suppressSizeToFit={true}
                            enableColResize={true}
                            headerHeight={this.grid_header_height}
                            onForceGridOut={this.on_grid_out}
                            headerIdForTabNavigation={admin_payment_const.claim_adjust_code_company_grid_header_id}
                            on_filter_button_click={() => this.update_report_rows_count()}
                        />
                    )}
                </div>
                {this.state.grid_data && !this.state.loading && (
                    <div
                    className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                    id='applicationFooterSticky'
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    >
                    <Grid.Column computer={16} textAlign='right'>
                        <Button
                        id='export_report_button'
                        type='submit'
                        onClick={this.on_export_button}
                        basic
                        content={'Export'}
                        />
                        <Button
                        onKeyDown={set_focus_to_app_header}
                        id='print_report_button'
                        type='submit'
                        onClick={this.on_print_button}
                        primary
                        style={{ marginRight: 0 }}
                        content={'Print'}
                        />
                    </Grid.Column>
                    </div>
                )}
            </div>
        </React.Fragment>
        );
    }
}
//Get user and login details from store.
const mapStateToProps = state => {
    return {
        user_login_details: state.user_login_details
    };
};
export default connect(mapStateToProps, null)(ClaimAdjCodeCompany);