import * as global_constants from "../../global_constants";
import { RequestConfig } from "./../../shared/network/config";
import { Request } from "./../../shared/network/request";

// Get the list of all the parent companies
export const get_parent_companies = (token, software_only) => {
  const url = `${global_constants.base_end_points}${global_constants.end_points.company_urls.company}${global_constants.end_points.company_urls.parent_companies}`;
  let request = new Request();
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  let request_config = new RequestConfig({
    url: url,
    headers: headers,
    method: "get",
    params: { software_only }
  });

  return request.make(request_config);
};

// Get company list by parent company
export const get_companies_list_by_parent = (token, param) => {
  const url = `${global_constants.base_end_points}/company/child_companies_by_parent`;
  let request = new Request();
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };

  let request_config = new RequestConfig({
    url: url,
    headers: headers,
    method: "get",
    params: param
  });
  return request.make(request_config);
};

export const get_e277_claims_search = (token, params) => {
  const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.e277_search_claims}`;
  let request = new Request();
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
    silversurfer: JSON.stringify(params)
  };

  let request_config = new RequestConfig({
    url: url,
    headers: headers,
    method: "get"
  });
  return request.make(request_config);
};

export const close_e277_claims = (token, data) => {
  const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.e277_close_claims}`;
  let request = new Request();
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  let request_config = new RequestConfig({
    url: url,
    method: "put",
    data: data
  });

  return request.make(request_config);
};

// Get the list of all the parent companies
export const get_parent_companies_by_inv_serv_type = (token, param) => {
  const url = `${global_constants.base_end_points}${global_constants.end_points.company_urls.company}/parent_companies_by_inv_serv_type`;
  let request = new Request();
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token
  };

  let request_config = new RequestConfig({
    url: url,
    headers: headers,
    method: 'get',
    params: param
  });

  return request.make(request_config);
};

export const search_demand_claims = (token, params) => {
  const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.demand_claims.search}`;
  let request = new Request();
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
    silversurfer: JSON.stringify(params)
  };

  let request_config = new RequestConfig({
    url: url,
    headers: headers,
    method: "get"
  });
  return request.make(request_config);
};
export const get_search_data = (token, api) => {
    const url = `${global_constants.base_end_points}${api}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });

    return request.make(request_config).then(response => {
        if (response) {
            return response;
        }
    });
};

export const update_batch_claims = (token, params, data) => {
  const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.demand_claims.batch_claims}`;
  let request = new Request();
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  let request_config = new RequestConfig({
      url: url,
      headers: headers,
      method: "put",
      data: data,
      params: params
  });
  return request.make(request_config);
};

export const validate_charges = (params, claim_ids, token) => {
  const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.demand_claims.validate_charge}`;
  let request = new Request();
  let headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": "Bearer " + token,
    "silversurfer": JSON.stringify(claim_ids)
  };

  let request_config = new RequestConfig({
      url: url,
      headers: headers,
      method: "get",
      params: params
  });
  return request.make(request_config);
};

export const get_claims_report = (params, claim_ids, token) => {

  const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.demand_claims.claims_report}`;
  let request = new Request();
  let headers = {
      'Authorization': 'Bearer ' + token,
      'silversurfer': JSON.stringify(claim_ids),
      'Content-type': 'application/pdf',
  }

  let request_config = new RequestConfig({
      'url': url,
      'headers': headers,
      'method': 'get',
      'responseType': 'arraybuffer',
      'transformResponse': function (data, headersGetter) {
          return { data: data, headers: headersGetter };
      },
      params: params
  })


  return request.make(request_config)
}


export const search_batch_billing = (token, params) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.batch_billing.search}`;
    let request = new Request();
    let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
        silversurfer: JSON.stringify(params)
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
    method: 'get'
    });
    return request.make(request_config);
};

export const batch_billing_lookup = (token, type,page_type) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.batch_billing.lookup}?type=${type}&page_type=${page_type}`;
    let request = new Request();
    let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });
    return request.make(request_config);
};

export const batch_billing_change_status = (token, params, data) => {
  const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.batch_billing.changestatus}/${params}`;
  let request = new Request();
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  let request_config = new RequestConfig({
      url: url,
      headers: headers,
      method: "put",
      data: data
  });
  return request.make(request_config);
};

export const batch_billing_schedule_cancel = (token, data) => {
  const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.batch_billing.schedulecancel}`;
  let request = new Request();
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token
  };

  let request_config = new RequestConfig({
    url: url,
    headers: headers,
    method: 'put',
    data: data
  });
  return request.make(request_config);
};
export const add_batch_billing = (token, type, params) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.batch_billing[type]}`;
    let request = new Request();
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
      silversurfer: JSON.stringify(params)
    };

    let request_config = new RequestConfig({
      url: url,
      headers: headers,
      method: 'post'
    });
    return request.make(request_config);
};

export const get_claims_detail = (token, id) => {
  const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.batch_billing.claims_detail}?id=${id}`;
  let request = new Request();
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token
  };

  let request_config = new RequestConfig({
    url: url,
    headers: headers,
    method: 'get'
  });
  return request.make(request_config);
};

export const get_edi_file = (token,run_number) =>{
  const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.batch_billing.get_edi_file}?run_id=${run_number}`;
  let request = new Request();
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token
  };
  let request_config = new RequestConfig({
    url: url,
    headers: headers,
    method: 'get'
  });

  return request.make(request_config);
}

export const get_batch_billing_claims = (token, id) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.batch_billing.run_set_details}/${id}`;
    let request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'get'
    });
    return request.make(request_config);
};

export const get_batch_claims_export_action = (token, params) => {
  const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.batch_billing.get_batch_claims_export}?set_id=${params.set_id}`;
  let request = new Request();
  let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
      'silversurfer': JSON.stringify(params.claim_header_id)
  };

  let request_config = new RequestConfig({
      url: url,
      headers: headers,
      method: 'get',
      // data: params.claim_header_id
  });
  return request.make(request_config);
};

export const obtain_batch_claims_export_action = (token, params) => {
  const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.batch_billing.obtain_batch_claims_export}/${params.set_id}`;
  let request = new Request();
  let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
  };

  let request_config = new RequestConfig({
      url: url,
      headers: headers,
      method: 'post',
      data: JSON.stringify(params.claim_header_id)
  });
  return request.make(request_config);
};

export const claims_change_status_action = (token, id, format_print_status_type, run_for, params) => {
    const end_point = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.batch_billing.update_run_status}`;
    const url = `${end_point}/${id}?print_status=${format_print_status_type}&run_for=${run_for}`;
    let request = new Request();
    let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: "put",
        data: params
    });
    return request.make(request_config);
};

export const get_batch_updates_master_data = (token) => {
  const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.batch_updates.master_data}`;
  let request = new Request();
  let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
  };

  let request_config = new RequestConfig({
      url: url,
      headers: headers,
      method: 'get'
  });
  return request.make(request_config);
};

export const get_batch_updates_master_data_by_company = (token, params) => {
  const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.batch_updates.master_data_by_company}`;
  let request = new Request();
  let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
  };

  let request_config = new RequestConfig({
      url: url,
      headers: headers,
      method: 'get',
      params
  });
  return request.make(request_config);
};

export const search_batch_updates = (token, params) => {
  const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.batch_updates.search}`;
  let request = new Request();
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
    silversurfer: JSON.stringify(params)
  };

  let request_config = new RequestConfig({
    url: url,
    headers: headers,
    method: 'get'
  });
  return request.make(request_config);
};

export const update_batch_status = (token, params) => {
  const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.batch_updates.update}`;
  let request = new Request();
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token
  };

  let request_config = new RequestConfig({
    url: url,
    headers: headers,
    method: 'put',
    data: params
  });
  
  return request.make(request_config);
};

export const claims_print_status_action = (params, token) => {
    const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.batch_billing.print_claim_status}`;
    const request = new Request();
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
    };

    let request_config = new RequestConfig({
        url: url,
        headers: headers,
        method: 'post',
        data: JSON.stringify(params),
        responseType: 'arraybuffer',
        transformResponse: function (data, headersGetter) {
          return { data: data, headers: headersGetter };
        }
    });
    return request.make(request_config);
};

export const claims_print_status = (params, token) => {
  const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.batch_billing.obtain_print_claim_status}`;
  const request = new Request();
  const headers = {
    Accept: 'application/json',
    Authorization: 'Bearer ' + token,
    'Content-type': 'application/json'
  };

  const request_config = new RequestConfig({
    url: url,
    headers: headers,
    method: 'post',
    data: JSON.stringify(params),
    responseType: 'arraybuffer',
    transformResponse: function (data, headersGetter) {
      return { data: data, headers: headersGetter };
    }
  });
  return request.make(request_config);
};

export const clear_batch_claims_format_cache = (token) => {
  const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.batch_billing.clear_format_cache}`;
  let request = new Request();
  let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
  };

  let request_config = new RequestConfig({
      url: url,
      headers: headers,
      method: 'post'
  });
  return request.make(request_config);
};

export const get_last_format_flush = (token) => {
  const url = `${global_constants.base_end_points}${global_constants.end_points.billing_urls.batch_billing.get_last_format_flush}`;
  let request = new Request();
  let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
  };

  let request_config = new RequestConfig({
      url: url,
      headers: headers,
      method: 'get'
  });
  return request.make(request_config);
}; 