import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, combineReducers } from 'redux';
import { update_patient_header_info } from '../../patient/action/patient_header_action';
import * as session_storage from '../../shared/session_storage_utility';
import { Modal, Dimmer, Loader, Form, Grid, Button } from 'semantic-ui-react';
import DatePicker from '../../shared/component/date_picker_component';
import moment = require('moment');
import CurrencyFormatter from '../../shared/component/currency_formatter';
import { payment_const } from '../../payment/payment_constants';
import Selection from '../../shared/component/selection_component';
import { date_format_with_no_time, get_lauch_darkley_key_value } from '../../shared/utility';
interface IState {
    patient_id: string,
    show_payment_full_modal: boolean,
    payment_code_list: Array<any>,
    payment: {
        min_date: any,
        deposit_date: any,
        formatted_deposit_date: any,
        credit_type_id: any,
        payment_code: any,
        amount: any,
        payment_money_type: any,
        check_credit_card: string,
        batch: string,
        comment: string,
        check_num: any,
        cc_num: any,
        billing_provider: any
        place_of_service: any,
        patient_id: any,
        original_amount: any,
        charge_id: any,
        credit_card_auth_num: any,
        patient_credit_type: any

    },
    payment_error: {
        deposit_date: boolean,
        location: boolean,
        payment_code: boolean,
        amount: boolean,
        check_num: boolean,
        insurance: boolean,

    },
    payment_full_submitted: boolean,
    payment_loading: boolean,
}

export class ChargePaymentFullModalComponent extends React.Component<any, IState> {
    [x: string]: any;
    _is_mounted = false;
    _is_visit = (this.props.location && this.props.location.state ? this.props.location.state.charge_visit : false);
    constructor(props) {
        super(props);
        this.state = {
            patient_id: session_storage.get('active_patient'),
            show_payment_full_modal: this.props.show_payment_full_modal,
            payment_code_list: this.props.payment_code_list,
            payment: this.props.payment_state,
            payment_loading: this.props.payment_loading,
            payment_full_submitted: this.props.payment_full_submitted,
            payment_error: {
                deposit_date: false,
                location: false,
                payment_code: false,
                amount: false,
                check_num: false,
                insurance: false,

            },
        }
    }
    componentDidMount = () => {
        this._is_mounted = true;
    }

    componentDidUpdate(previousProps, previousState) {
        if (this._is_mounted) {
            if (previousProps.show_payment_full_modal !== this.props.show_payment_full_modal) {
               
                this.setState({ 
                    show_payment_full_modal: this.props.show_payment_full_modal,
                    payment_code_list: this.props.payment_code_list,
                    payment: this.props.payment_state
                }, () => {
                    if (this.state.show_payment_full_modal) {
                        let search_button = document.getElementById("payment_code");
                       search_button.focus();
                        let el: HTMLElement = document.querySelector('#PaymentinFull .close');
                        if (el) {
                            el.setAttribute('tabIndex', '0');
                            el.addEventListener("keydown", function (event) {
                                if (!event.shiftKey && (event.keyCode == 9 || event.which == 9)) {
                                    event.preventDefault();
                                    let search_button = document.getElementById("payment_deposite_date");
                                    search_button.focus();
                                }
                                else if (event.shiftKey && (event.keyCode == 9 || event.which == 9)) {
                                    event.preventDefault();
                                    let search_button = document.getElementById("btnSavePayment");
                                    search_button.focus();
                                }
                                else if (event.keyCode == 13 || event.which == 9) {
                                    event.preventDefault();
                                    var el = event.target as HTMLElement
                                    el.click();
                                }
                            })
                        }
                    }
                }
);

            }

            if (previousProps.payment_full_submitted !== this.props.payment_full_submitted) {
               
                this.setState({ payment_full_submitted: this.props.payment_full_submitted });

            }
         
            if (previousProps.payment_loading !== this.props.payment_loading) {
               
                this.setState({ payment_loading: this.props.payment_loading });

            }
        }
    }

    componentWillUnmount = () => {
        this._is_mounted = false;
    }   

    on_payment_value_change = (value, input_name) => {
        var check_num = true;
        var error_data = { ...this.state.payment_error };
        var payment_state_data = { ...this.state.payment, [input_name]: value };
        if (input_name === 'payment_money_type') {
            if (value === 1) {
                check_num = false;
            }
        } else if (input_name === 'amount' && value != '') {
            error_data.amount = false;
        }
        if (this._is_mounted) {
            this.setState(prevState => ({
                payment: payment_state_data,
                payment_error: error_data,
            }));
        }
    }

    
    on_dropdown_payment_code = (value) => {
        const selected = this.state.payment_code_list.filter(data => {
            return data.value == value
        });
        if (this._is_mounted) {
            if (selected && selected.length > 0 && selected[0].data != undefined) {
                const credit_data_updated = { ...this.state.payment, payment_code: selected[0].data };
                this.setState(prevState => ({
                    payment: credit_data_updated
                }))
            } else {
                const payment_code = {
                    payment_id: ''
                }
                const credit_data_updated = {
                    ...this.state.payment, payment_code: payment_code
                };
                this.setState(prevState => ({
                    payment: credit_data_updated
                }))
            }
        }
    }

    save_payment_full_payment_focus_event = (e, id = '') => {
        if (!e.shiftKey && (e.keyCode == 9 || e.which == 9)) {
            let el: HTMLElement = document.querySelector('#PaymentinFull .close');
            if (el) {
                el.focus();
                e.preventDefault();
            }
        }
    }
    payment_deposit_date_change = (dt) => {
        let m = moment(dt);
        var payment_data = this.state.payment;
        if (m.isValid()) {
            payment_data.deposit_date = dt;
            payment_data.formatted_deposit_date = dt;
        } else {
            payment_data.deposit_date = null;
            payment_data.formatted_deposit_date = null;
        }
        if (this._is_mounted) {
            this.setState({
                payment: payment_data
            });
        }
    }

    
    render() {
        var style_margin_diagnosis = { marginTop: '8px', marginBottom: '20px' };
        let depositDate = this.state.payment.deposit_date ? date_format_with_no_time(this.state.payment.deposit_date) : null;
        return (
            <React.Fragment>
                <Modal
                    onClose={this.props.close_payment_full_moda}
                    centered={false}
                    className=" default-modal"
                    open={this.state.show_payment_full_modal}
                    closeIcon={true}
                    id="PaymentinFull"
                    closeOnDimmerClick={false}
                >
                    <Modal.Header>
                        <h3>Payment in Full</h3>
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Dimmer active={this.state.payment_loading}>
                                <Loader size="massive">Loading</Loader>
                            </Dimmer>
                            <Form autoComplete="off" className="normal-form padd-r-0 padd-b-0">
                                <div className="common-forms" style={{ padding: "0" }}>
                                    <Grid>
                                        <Grid.Column tablet={8} computer={8} >
                                            <Form.Field className={!this.state.payment.deposit_date && this.state.payment_full_submitted ? "requiredWithBgColor" : ""}>
                                                <label>Deposit Date</label>
                                                <DatePicker id="payment_deposite_date" minDate={this.state.payment.min_date} maxDate={moment().format('MM/DD/YYYY')}
                                                    date_update={(e) => { this.payment_deposit_date_change(e) }}
                                                    is_required={!this.state.payment.deposit_date && this.state.payment_full_submitted}
                                                    date={depositDate}

                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column tablet={8} computer={8} >
                                            <Form.Field>
                                                <label>Payment Code
                                                    <span className={!this.state.payment.payment_code.payment_id && this.state.payment_full_submitted ? "req-alert" : 'req-alert_normal'}> (required)</span>
                                                </label>
                                                <Selection
                                                    id="payment_code"
                                                    defaultValue={this.state.payment.payment_code.payment_id}
                                                    options={this.state.payment_code_list}
                                                    onChange={(value) => this.on_dropdown_payment_code(value)}
                                                    isRequired={!this.state.payment.payment_code.payment_id && this.state.payment_full_submitted}

                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid >

                                    <Grid>
                                        <Grid.Column tablet={8} computer={4} >
                                            <Form.Field>
                                                <label>Amount</label>
                                                <CurrencyFormatter
                                                    onChange={(value) => this.on_payment_value_change(value, 'amount')}
                                                    prefix='$'
                                                    id={'payment_amount'}
                                                    maxLength={15}
                                                    defaultValue={this.state.payment.amount}
                                                    allowNegative={false}
                                                    disabled={true}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>
                                                    Payment Method
                                                </label>
                                                <Selection
                                                    id="payment_method"
                                                    defaultValue={this.state.payment.payment_money_type || 3}
                                                    options={this.props.user_login_details.payment_methods}
                                                    onChange={(value) => this.on_payment_value_change(parseInt(value), 'payment_money_type')}
                                                    hidden={true}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field className={this.state.payment_full_submitted && this.state.payment.payment_money_type != 1 && (this.state.payment.cc_num == '' || this.state.payment.cc_num == undefined || this.state.payment.cc_num == null) ? "requiredWithBgColor" : ""}>
                                                <label>
                                                    Check/CC Number
                                                   {
                                                        (this.state.payment.payment_money_type == this.props.user_login_details.payment_methods[1].value ||
                                                            this.state.payment.payment_money_type == this.props.user_login_details.payment_methods[2].value ||

                                                            ((this.state.payment.payment_money_type == this.props.user_login_details.payment_methods[3].value ||
                                                                this.state.payment.payment_money_type == this.props.user_login_details.payment_methods[4].value))) ?
                                                            <span className={this.state.payment_full_submitted && this.state.payment.payment_money_type != 1
                                                                && (this.state.payment.cc_num == '' || this.state.payment.cc_num == undefined || this.state.payment.cc_num == null)
                                                                ? "req-alert" : 'req-alert_normal'}> (required)</span>
                                                            : ''
                                                    }
                                                </label>
                                                <Form.Input type="text" id="payment_check_cc_number" value={this.state.payment.cc_num}
                                                    onChange={(e) => this.on_payment_value_change(e.currentTarget.value, 'cc_num')} maxLength={50}
                                                    required={this.state.payment_full_submitted}
                                                    error={this.state.payment_full_submitted && this.state.payment.payment_money_type != 1 && (this.state.payment.cc_num == '' || this.state.payment.cc_num == undefined || this.state.payment.cc_num == null)} />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>
                                                    Batch
                                                </label>
                                                <Form.Input type="text" id="payment_batch" value={this.state.payment.batch}
                                                    maxLength={38} onChange={(e) => this.on_payment_value_change(e.currentTarget.value, 'batch')}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid >
                                    <Grid>
                                        <Grid.Column tablet={16} computer={16} >
                                            <Form.Field>
                                                <label>Comment</label>
                                                <Form.Input type="text" id="payment_batch"
                                                    value={this.state.payment.comment}
                                                    maxLength={50} onChange={(e) => { 
                                                            this.on_payment_value_change(e.currentTarget.value, 'comment')
                                                        }   
                                                    }
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid >
                                </div>
                                <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column text-right padd-r-0" style={{ marginTop: '30px' }}>
                                    <Button id="btnCanclePayment" type="button" basic onClick={() => this.props.close_payment_full_moda()}>
                                        Cancel
                                     </Button>
                                    <Button className="margin-zero" id="btnSavePayment"
                                        onKeyDown={this.save_payment_full_payment_focus_event} type="button" primary onClick={() => this.props.save_payment_full_moda(this.state.payment)}>
                                        Save
                                    </Button>
                                </div>
                            </Form>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        //get_patient_search: get_patient_search
        update_patient_header_info: update_patient_header_info,
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        user_details: state.user_login_details,
        patient_details: state.patient_details,
     }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChargePaymentFullModalComponent));