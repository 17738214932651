import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Dimmer, Form, Grid, Loader, Accordion, Icon } from 'semantic-ui-react';
import { option_formater } from '../../admin/constants/constants';
import { constants, end_points, toastr_options, charge_exception } from '../../global_constants';
import { get_patient_search } from '../../patient/action/patient_search_action';
import { get_release_claims_references } from '../../reports/action/report_action';
import { enum_drop_down_type } from '../../reports/report_constants';
import { get_searchList } from '../../shared/action/autosearch_action';
import { log_error, get_company_detail_data, get_patient_company_info, set_prevent_company_model_status} from '../../shared/action/shared_action';
import AutoSearchComponent from '../../shared/component/auto_search_component';
import DatePicker from '../../shared/component/date_picker_component';
import Selection from '../../shared/component/selection_component';
import * as session_storage from '../../shared/session_storage_utility';
import * as local_storage from '../../shared/local_storage_utility';
import { patient_search_configuration, patient_search_configuration_id } from '../../shared/shared_constants';
import AdvancedControl from './../../shared/component/advanced_control';
import GridView from './../../shared/component/grid';
import ConfirmationComponent from '../../shared/component/confirmation_component';
import { set_focus_on_element_with_id, set_focus_to_app_header, handle_click_on_enter } from './../../shared/tab_navigation_utility';
import { custom_date_format, format_patient_name, get_columns, update_company_details, get_all_error, detect_ie, clear_sotrage_after_company_change_on_main_page} from './../../shared/utility';
import {
    add_release_claim_delay,
    get_patient_details_by_id,
    get_release_claim_search_data,
    get_filtered_case_list
} from './../action/charge_action';
import * as charge_constants from './../charge_constant';
import { quick_search_class_by_company, search_class_by_company } from './../../shared/action/shared_action';
import * as shared_constants from './../../shared/shared_constants';
import { enum_type_of_search } from './../../case/case_constants';
import { MasterData } from './../../login/login_constants';
import LaunchDarkley from './../../shared/launch_darkley/launch_darkley';
import { get_master_data, get_master_data_by_type, refresh_token, set_active_patient } from './../../login/action/login_action';

export class ReleaseClaimDelayComponent extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            show_bummepd_charge_message_popup: false,
            controls_is_expended: true,
            loading: true,
            show_grid: false,
            grid_data: null,
            selected_release_claim: [],
            grid_rows_count: 0,
            grid_columns: [],
            grid_rows: [],
            patient_search_value: {},
            grid_params: null,
            insurance_class_search_data: null,
            insurance_class_quick_search_format:
            {
                "title": '',
                "label": '',
            }
        };
    }
    is_mounted = false;
    is_submitted = false;
    token = '';
    total_column_width: number = 0;
    grid_header_height: number = 0;
    is_initial_result: Boolean = false;
    is_case_selected = false;
    release_data: any = {
        rows: [],
        column: [],
        page_size: ''
    };
    options_data: any = {
        case_id: [],
        company_id: [],
        practice_id: [],
        location_id: [],
        provider_id: [],
        delayed_reason: []
    };
    search_criteria: any = {
        change_control: '',
        company_id: '-1',
        practice_id: '-1',
        location_id: '-1',
        provider_id: '-1',
        patient_id: session_storage.get('active_patient') || -1,
        case_id: '-1',
        delayed_reason: '-1',
        insurance_class_id:'-1',
        from_date: null,
        through_date: null,
        is_searched: false
    };
    result_search_criteria:any= {};

    initial_search_criteria: any = {
        company_id: '-1',
        practice_id: '-1',
        location_id: '-1',
        provider_id: '-1',
        patient_id: session_storage.get('active_patient') || -1,
        case_id: '-1',
        delayed_reason: '-1',
        insurance_class_id: '-1',
        from_date: null,
        through_date: null,
        is_searched: false
    };

    // Fetching the report id and params.
    UNSAFE_componentWillMount = () => {
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.get_dropdown_master_data();
        this.release_data = {
            rows: [],
            column: charge_constants.release_claim_delay(this.navigate_to_patient).claim_delay_column_def,
            page_size: charge_constants.release_claim_delay(this.navigate_to_patient).page_size
        };
    };

    componentDidMount() {
        this.is_mounted = true;
        document.body.classList.add('admin-framework'); 
        if (session_storage.get('release_cliam_dela_search_criteria')) {
            this.search_criteria = session_storage.get('release_cliam_dela_search_criteria');

            console.log("stored", session_storage.get('release_cliam_dela_search_criteria'))
            //this.search_release_claims();
        }
        if (session_storage.get('active_patient')) {
            this.get_patient_details();
        }
      
    }

    componentWillUnmount = () => {
        this.is_mounted = false;
        document.body.classList.remove('admin-framework');
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[2] !== "patient" && session_storage.remove('report_patient_adv_search');
        }
        ;
    };

    //Get updated dropdown data on dropdown change.
    get_dropdown_master_data = async () => {
        if (this.is_mounted) {
            this.setState({
                re_render: false,
                loading: true
            });
        }
        let params = {
            change_control: this.search_criteria.change_control,
            company_id: this.props.user_login_details.user_data.data.company_id,
            practice_id: this.search_criteria.practice_id,
            location_id: this.search_criteria.location_id,
            provider_id: this.search_criteria.provider_id
        };
        // Get Master Data
        await get_release_claims_references(params, this.token).then(
            (response) => {
                let company_id = response.data.data[enum_drop_down_type.company];
                let practice_id = response.data.data[enum_drop_down_type.practice];
                let location_id = response.data.data[enum_drop_down_type.location];
                let provider_id = response.data.data[enum_drop_down_type.provider];
                let delayed_reason = response.data.data[enum_drop_down_type.delayed_reason];
                delayed_reason = option_formater(
                    delayed_reason,
                    'value',
                    'key',
                    false, // to add blank object
                    true // to shorted object
                );
                delayed_reason.unshift({ text: '- ALL DELAY REASONS -', value: '-1' });
                if (response.data && response.data.data) {
                    this.options_data = {
                        ...this.options_data,
                        company_id: company_id.map((item) => {
                            return {
                                text: `${item['gpms_code']} - ${item['company_name']}`,
                                value: item['company_id']
                            };
                        }),
                        practice_id,
                        location_id,
                        provider_id,
                        delayed_reason
                    };
                    const update_search_criteria = {
                        company_id: this.props.user_login_details.user_data.data.company_id,
                        practice_id: practice_id && practice_id.length > 1 ? '-1' : practice_id[0].value,
                        location_id: location_id && location_id.length > 1 ? '-1' : location_id[0].value,
                        provider_id: provider_id && provider_id.length > 1 ? '-1' : provider_id[0].value
                    };
                    this.search_criteria = {
                        ...this.search_criteria,
                        ...update_search_criteria
                    };

                    this.initial_search_criteria = {
                        ...this.initial_search_criteria,
                        ...update_search_criteria
                    };

                    if (this.is_mounted) {
                        this.setState({
                            re_render: false,
                            loading: false
                        });
                    }
                }
            },
            (error) => {
                if (this.is_mounted) {
                    this.setState({
                        loading: false
                    });
                }
                log_error(error);
                if (error.data.messages[0].message.indexOf('<br') > -1) {
                    this.show_html_content_toaster(error.response.data.messages[0].message);
                } else {
                    toastr.error('', error.response.data.messages[0].message);
                }
            }
        );
    };

    //Get updated dropdown data on dropdown change.
    get_updated_dropdown_data = async (name, value) => {
        let params = {};
        if (name === 'company_id') {
            params = {
                change_control: 'company_id',
                company_id: value === '-1' ? -1 : value,
                practice_id: -1,
                location_id: -1,
                provider_id: -1
            };
            this.options_data = {
                ...this.options_data,
                case_id: []
            };
        } else {
            params = {
                change_control: this.search_criteria.change_control,
                company_id: this.search_criteria.company_id,
                practice_id: this.search_criteria.practice_id,
                location_id: this.search_criteria.location_id,
                provider_id: this.search_criteria.provider_id
            };
        }

        if (this.is_mounted) {
            this.setState({
                re_render: true
            });
        }
        await get_release_claims_references(params, this.token).then(
            async (response) => {
                let practice_id = [],
                    location_id = [],
                    provider_id = [];
                switch (name) {
                    case 'company_id':
                        practice_id = response.data.data[enum_drop_down_type.practice];
                        location_id = response.data.data[enum_drop_down_type.location];
                        provider_id = response.data.data[enum_drop_down_type.provider];
                        this.options_data = {
                            ...this.options_data,
                            practice_id,
                            location_id,
                            provider_id
                        };
                        this.search_criteria = {
                            ...this.search_criteria,
                            patient_id: '-1',
                            case_id: '-1',
                            insurance_class_id: '-1',
                            practice_id: practice_id && practice_id.length > 1 ? '-1' : practice_id[0].value,
                            location_id: location_id && location_id.length > 1 ? '-1' : location_id[0].value
                        };
                        break;
                    case 'practice_id':
                        location_id = response.data.data[enum_drop_down_type.location];
                        this.options_data = {
                            ...this.options_data,
                            location_id
                        };
                        this.search_criteria = {
                            ...this.search_criteria,
                            location_id: location_id && location_id.length > 1 ? '-1' : location_id[0].value
                        };
                        break;
                    default:
                        break;
                }

                if (this.is_mounted) {
                    this.setState({
                        re_render: false
                    });
                }
            },
            (error) => {
                if (this.is_mounted) {
                    this.setState({
                        loading: false
                    });
                }
                log_error(error);
                if (error.data.messages[0].message.indexOf('<br') > -1) {
                    this.show_html_content_toaster(error.response.data.messages[0].message);
                } else {
                    toastr.error('', error.response.data.messages[0].message);
                }
            }
        );
    };

    // Get Case List
    get_case_list = async () => {
        let case_endpoint = '/cases/filter_Cases?apply_incomplete_filter=true&get_case_with_charge_dos=true';
        let url = `/patients/${this.search_criteria.patient_id}${case_endpoint}`;
        this.setState({
            loading: true,
            patient_search_value: session_storage.get('report_patient_adv_search')
                ? session_storage.get('report_patient_adv_search')
                : {}
        });
        await get_filtered_case_list(this.token, url).then((response) => {
            if (response.data.data) {
                if (response.data.data.length == 1) {
                    this.search_criteria.from_date =
                        response.data.data[0].date_of_service != null
                            ? custom_date_format(response.data.data[0].date_of_service, 'mm/dd/yyyy')
                            : '';
                }

                let case_id = response.data.data.map((item) => {
                    return {
                        text: `${item['id']} - ${item['description']}`,
                        value: item['id'],
                        ...item
                    };
                });
                case_id.unshift({
                    text: '- ALL CASES -',
                    value: '-1',
                    id: -1,
                    status: null,
                    description: '',
                    start_of_care_date: null,
                    provider_id: null,
                    md_code: null,
                    plan_of_care_date: null,
                    effective_date: null,
                    case_effective_date: null,
                    is_case_active: null,
                    patient_id: null,
                    injury_des: null,
                    date_of_service: null,
                    is_case_update: false,
                    plan_of_care_expiration_date: null,
                    created_date: null,
                    discharge_date: null
                });
                this.options_data = {
                    ...this.options_data,
                    case_id
                };
                if (this.is_mounted) {
                    this.setState({ loading: false }, () => {
                        this.search_criteria.is_searched = false;
                        if (response.data.data.length == 1) {
                            this.search_criteria.case_id = response.data.data[0].id;
                            this.search_release_claims();
                        } 
                    });
                }
            } else {
                if (this.is_mounted) {
                    this.setState({
                        loading: false
                    });
                }
            }
        });
    };

    // Get From date from case
    get_date_from_select = (id) => {
        let item = this.options_data.case_id.find((item) => item.id == id);
        this.search_criteria.from_date =
            item.date_of_service !== null ? custom_date_format(item.date_of_service, 'mm/dd/yyyy') : '';
    };

    // Dropdown change handler.
    dropdown_change_handler = (event) => {
        if (!event) return;
        const { name, value } = event.target;
        this.search_criteria[name] = value;
        if (['company_id', 'practice_id'].indexOf(name) > -1) {
            this.search_criteria.change_control = name;
            if (this.is_case_selected) {
                this.search_criteria.from_date = '';
                this.search_criteria.through_date = '';
                this.is_case_selected = false;
            }
            this.get_updated_dropdown_data(name, value);
        } else if (name === 'case_id') {
            if (value == '-1') {
                this.search_criteria.from_date = '';
                this.search_criteria.through_date = '';
            } else {
                this.is_case_selected = true;
                this.get_date_from_select(value);
            }
            this.release_data = {
                ...this.release_data,
                rows: []
            };
        }
       
        if (this.is_mounted) {
            this.setState(
                {
                    search_criteria: {
                        ...this.search_criteria
                    },
                    patient_search_value: name === 'company_id' ? {} : this.state.patient_search_value,
                    insurance_class_quick_search_format: name === 'company_id' ? {} : this.state.insurance_class_quick_search_format,
                    selected_release_claim: name == 'case_id' ? [] : this.state.selected_release_claim
                },
                () => {
                    this.search_criteria.is_searched = false;
                    name == 'case_id' && this.search_release_claims();
                }
            );
        }
    };

    // Calender change handler.
    calendar_change_handler = (name, date_object) => {
        this.search_criteria[name] = date_object ? custom_date_format(date_object._d, 'mm/dd/yyyy') : '';
        if (this.is_mounted) {
            this.setState({
                search_criteria: {
                    ...this.search_criteria
                }
            });
        }
    };
    // ################### Patient search advance control start ###################
    get_patient_quick_search_data_list = async (inputValue, companyid) => {
        let token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await get_searchList(
            token,
            this.search_criteria.company_id,
            inputValue.trim(),
            constants.Quick_Search_Suggestion_List_Size,
            end_points.patient_urls.quick_search
        );
    };

    // Prepare suggestion list to show in Quick Search result
    prepare_suggestion = (search_data) => {
        let formattedList = [];
        let data = search_data.data;
        let data_length = data.length;
        if (data_length > 0) {
            for (let i = 0; i < data_length; i++) {
                let item = data[i];
                formattedList.push({
                    label:
                        (item.last_name == null ? '' : item.last_name + ' ') +
                        (item.middle_initial == null ? '' : item.middle_initial + ' ') +
                        (item.first_name == null ? '' : item.first_name),
                    title: item.id.toString()
                });
            }
        }
        return formattedList;
    };

    // Render Patient list after Quick Search
    render_result = (props, type = undefined) => {
        return (
            <div key={props.title} tabIndex={0} className="item_auto_search">
                <div className="fs_13">
                    <span className="bold">{props.label}</span>
                    {!type && <span>{props.title ? '(' + props.title + ')' : ''}</span>}
                </div>
            </div>
        );
    };

    // Patient seleted Quick Search list
    on_item_selection = (selected_row, name) => {
        this.search_criteria[name] = selected_row.title;
        let patient_search_value = {
            label: selected_row.label,
            title: selected_row.title
        };
        session_storage.set('report_patient_adv_search', patient_search_value);
        this.closeModal();
        this.get_case_list();
    };

    // close the modal on selection of patient through quick search
    closeModal = () => {
        this.setState({ show_patient_modal: false });
    };

    // Clear Quick Search
    clear_quick_search = (name) => {
        this.search_criteria[name] = '-1';
        this.search_criteria['case_id'] = '-1';
        this.search_criteria['from_date'] = '';
        this.options_data = {
            ...this.options_data,
            case_id: []
        };
        this.setState({
            patient_search_value: {}
        });
        session_storage.set('report_patient_adv_search', {});
    };

    // Patient Selction from grid on double click
    on_patient_grid_row_selection = (selected_row, sequence) => {
        let patient_name = format_patient_name(selected_row.name);
        this.search_criteria[sequence] = selected_row.id;
        let patient_search_value = {
            label: patient_name,
            title: selected_row.id
        };
        this.setState({
            patient_search_value
        });
        session_storage.set('report_patient_adv_search', patient_search_value);
        this.get_case_list();
        this.closeModal();
    };

    // Patient Search in modal
    on_patient_search = (pat_obj) => {
        let params = {
            patient_name: {
                first_name: pat_obj.first_name,
                last_name: pat_obj.last_name
            },
            id: pat_obj.Id,
            page_size: pat_obj.page_size,
            company_id: this.search_criteria.company_id // Pass current company in advance search.
        };
        let token = this.props.user_login_details.user_data.data.token_details.access_token;
        // Search function.
        this.props.get_patient_search(params, token, false).then((res) => {
            this.setState({
                grid_data: {
                    ...this.state.grid_conf,
                    rows: this.props.patient_details.patient_search,
                    column: patient_search_configuration(patient_search_configuration_id).column_defs,
                    messages: 'No Patient Found'
                }
            });
        });
    };
    // ################### Patient search advance control End ###################

    // Get Patient details on reload or has active patient id
    get_patient_details = async () => {
        this.search_criteria.patient_id = session_storage.get('active_patient');
        let patient_search_value;
        if (
            this.props.patient_details &&
            this.props.patient_details.patient_header &&
            this.props.patient_details.patient_header.data
        ) {
            let { name, id } =
                this.props.patient_details &&
                this.props.patient_details.patient_header &&
                this.props.patient_details.patient_header.data;
            patient_search_value = {
                label: `${name.last_name || ''} ${name.middle_initial || ''} ${name.first_name || ''}`,
                title: id.toString()
            };
            session_storage.set('report_patient_adv_search', patient_search_value);
        } else {
            let params = {
                id: session_storage.get('active_patient'),
                company_id: this.props.user_details.user_data.data.company_id,
                date_of_birth: null,
                patient_name: { first_name: '', middle_initial: '', last_name: '' },
                responsible_name: { first_name: '', middle_initial: '', last_name: '' },
                ssn: '',
                phone: '',
                show_in_active: false,
                policy_number: '',
                alt_record_id: '',
                page_index: 0,
                page_size: 5000,
                responsible_ssn: '',
                responsible_phone: '',
                responsible_date_of_birth: '',
                is_responsible_search: 'false'
            };
            await get_patient_details_by_id(params, this.token).then((res) => {
                if (res.data && res.data.data && res.data.data.result) {
                    let { name, id } = res.data.data.result[0];
                    patient_search_value = {
                        label: `${name.last_name || ''} ${name.middle_initial || ''} ${name.first_name || ''}`,
                        title: id.toString()
                    };
                    session_storage.set('report_patient_adv_search', patient_search_value);
                }
            });
        }
        await this.get_case_list();
    };

    // Search Release Claims Data basis of search criteria
    search_release_claims = async () => {
        let search_criteria = {
            ...this.search_criteria
        };
        this.is_submitted = this.is_submitted ? true : search_criteria.is_searched ? true : false;
        search_criteria.from_date = search_criteria.from_date || '1975-01-01';
        search_criteria.through_date = search_criteria.through_date || moment().format('YYYY-MM-DD');
        if (this.is_mounted) {
            this.setState({
                loading: true,
                show_grid: false
            });
        }
        this.result_search_criteria = search_criteria;
        session_storage.set('release_cliam_dela_search_criteria', this.result_search_criteria);
        await get_release_claim_search_data(this.token, search_criteria).then(
            (res) => {
                if (res.data) {
                    let result = res.data && res.data.data ? res.data.data : [];
                    result = result.map((row, index) => {
                        row.date_of_service = moment(row.date_of_service).format('MM/DD/YYYY');
                        row.claim_delay_reason = `${row.claim_delay_reason} (${moment(row.claim_delay_flag).format(
                            'MM/DD/YYYY'
                        )})`;
                        return row;
                    });
                    const grid_height = get_columns(
                        result,
                        this.release_data.column
                    );
                    this.total_column_width = grid_height.total_column_width;
                    this.grid_header_height = grid_height.header_height;
                    this.release_data = {
                        ...this.release_data,
                        rows: [...result]
                    };
                    if (this.is_mounted) {
                        this.setState({
                            loading: false,
                            show_grid: true,
                            grid_rows_count: result && result.length,
                            controls_is_expended: false // closing the accordion on search
                        });
                    }
                }
            },
            (error) => {
                if (this.is_mounted) {
                    this.setState({
                        loading: false,
                        show_grid: true
                    });
                }
                if (error.response.data) toastr.error('', error.response.data.messages[0].message);
            }
        );
    };

    // Clear Search criteria
    clear_handler = async () => {
        let patient_search_value = {};
        this.options_data = {
            ...this.options_data,
            case_id: []
        };
        this.release_data = {
            ...this.release_data,
            rows: []
        };
        this.search_criteria = {
            ...this.initial_search_criteria
        };
        this.result_search_criteria = {
            ...this.initial_search_criteria
        }
        await this.get_dropdown_master_data();
        if (session_storage.get('active_patient')) {
            await this.get_patient_details();
        } else {
            session_storage.remove('report_patient_adv_search');
        }
        if (this.is_mounted) {
            this.setState({
                selected_release_claim: [],
                grid_rows_count: 0,
                show_grid: false,
                insurance_class_quick_search_format: {},
                patient_search_value: session_storage.get('report_patient_adv_search')
                    ? session_storage.get('report_patient_adv_search')
                    : {}
            });
        }
        this.is_submitted = false;
    };

    // Select case_id on checkbox
    on_check_box_selection = (items) => {
        if (this.is_mounted) {
            this.setState({
                selected_release_claim: items
            });
        }
    };

    // Release claims
    release_claim = async () => {
        const { selected_release_claim } = this.state;
        if (selected_release_claim.length > 0) {
            let listChargeId = selected_release_claim.map((item, index) => {
                return {
                    charge_id: item.charge_id, charge_open_time: item.charge_open_time
                }
            });
            let api = `${charge_constants.release_claim_delay(this.navigate_to_patient).api.release_claim_delay}`;
            let release_claim_obj = {
                data: listChargeId,
                api
            };
            await add_release_claim_delay(this.token, release_claim_obj).then(
                (res) => {
                    if (res.data && res.data.data) {
                        toastr.success('', charge_constants.charge_messages.release_claim_added);
                        this.setState(
                            {
                                selected_release_claim: []
                            },
                            () => {
                                this.search_release_claims();
                            }
                        );
                    }
                    else {

                        if (res && res.data && res.data.messages && res.data.messages.length > 0) {
                            let exception = res.data.messages.filter((item) => {
                                return item.code === charge_exception.code
                            })
                            if (exception && exception.length > 0) {
                                if (this.is_mounted) {
                                    this.setState({
                                        loading: false,
                                        show_bummepd_charge_message_popup: true
                                    })
                                }
                            } 
                        } else {
                            if (this.is_mounted) {
                                this.setState({
                                    loading: false,
                                    show_grid: false
                                });
                            }
                            if (res.data) toastr.error('', res.data.messages[0].message);
                        } 
                    }
                },
                (error) => {
                    if (error && error.response && error.response.data && error.response.data.messages && error.response.data.messages.length > 0) {
                        let exception = error.response.data.messages.filter((item) => {
                            return item.code === charge_exception.code
                        })
                        if (exception && exception.length > 0) {
                            if (this.is_mounted) {
                                this.setState({
                                    loading: false,
                                    show_bummepd_charge_message_popup: true
                                })
                            }
                        } else {
                            if (this.is_mounted) {
                                this.setState({
                                    loading: false,
                                    show_grid: false
                                });
                            }
                            const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                            toastr.error('', toastr_options);
                        }
                    } else {
                        if (this.is_mounted) {
                            this.setState({
                                loading: false,
                                show_grid: false
                            });
                        }
                        if (error.response.data) toastr.error('', error.response.data.messages[0].message);
                    }                    
                }
            );
        } else {
            toastr.error('', charge_constants.charge_messages.no_charges_to_save);
        }
    };

    // Set cloumn width
    get_grid_ref = (grid_params) => {
        let grid_width = document.getElementsByClassName('admin-wrapper')[0].clientWidth;
     
        if (this.total_column_width > grid_width) {
            this.release_data.column.filter((item) => {
                grid_params.columnApi.setColumnWidth(item.field, item.width, false);
            });
        }
        this.setState({
            grid_params,
            grid_rows_count: grid_params.api.getModel().getRowCount()
        });
    };

    // Tab focues on save button after grid out
    on_grid_out = () => {
        set_focus_on_element_with_id('release_claim_delay_save_button');
    };

    handle_on_key_down_select = (event, case_id) => {
        if (!event.shiftKey && event.keyCode == '9' && case_id == 0) {
            event.preventDefault();
            set_focus_on_element_with_id('app_header_dropdown');
        }
    };

    setFocusToAppHeader = (event) => {
        // apply a check where grid is empty
        if (!event.shiftKey && event.keyCode == '9' && !this.state.show_grid) {
            set_focus_to_app_header(event);
        }
    };

    update_report_rows_count = () => {
        this.setState({
            grid_rows_count: this.state.grid_params.api.getModel().getRowCount()
        });
    };

    // Show multiple messages
    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ),
            timeOut: toastr_options.toastr_time_out,
            preventDuplicates: true
        };
    };

    // To handle focus on grid.
    handle_focus_for_grid = (event) => {
        // apply a check where grid is empty
        // if it is not empty send the focus to app header
        if (
            !event.shiftKey &&
            event.keyCode == '9' &&
            (!this.state.is_grid_visible || this.release_data.rows == null || this.release_data.rows.length == 0)
        ) {
            set_focus_to_app_header(event);
        }
    };

    on_insurance_class_grid_row_selection = ( selected_row) => {
        var id = '';
        var name = '';
        var code = '';
        var selected_data = '';

        //var charge_audit_search = this.state.search_creteria;

        if (selected_row) {
            id = selected_row.id;
            if ((selected_row.code != null && selected_row.code != undefined) || (selected_row.description != null && selected_row.description != undefined)) {
                code = (selected_row.code == null && selected_row.code == undefined) ? '' : selected_row.code;
                name = (selected_row.description == null && selected_row.description == undefined) ? '' : selected_row.description;
            }
        }
        selected_data = code + " - " + name + " " + (selected_row.is_active ? "" : "(I)");
        var format_bp = { "label": '', "title": '', "bp_code": '' };
        if (parseInt(id) > 0) {
            format_bp = {
                "title": id.toString(),
                "bp_code": code,
                "label": selected_data
            };
        }

        this.search_criteria['insurance_class_id'] = selected_row.id;

        if (this.is_mounted) {
            this.setState({
                insurance_class_quick_search_format: format_bp
            });
        }
    }

    on_insurance_class_item_selection = (item,) => {
        this.search_criteria['insurance_class_id'] = item.title;

        if (this.is_mounted) {
            this.setState({
                insurance_class_quick_search_format: item,
            });
        }
    }

    on_insurance_class_search = async (params) => {
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        params = params ? params : {};
        var grid_data = [];

        const search_data_ins_class = await search_class_by_company(this.search_criteria.company_id, params, token)
            .then(res =>
                res.data).catch(error => {
                    if (this.is_mounted) {
                        this.setState({
                            loading: false
                        });
                    }
                });
        const search_class_result = (search_data_ins_class && search_data_ins_class.data !== null) ? search_data_ins_class.data.result : [];
        grid_data = {
            ...this.state.grid_conf, rows: search_class_result,
            column: shared_constants.search_class_by_company_configuration(enum_type_of_search.insurance_class + '_headerid').column_defs,
            messages: search_data_ins_class ? search_data_ins_class.messages : ''
        }
        if (this.is_mounted) {
            this.setState({ insurance_class_search_data: grid_data });
        }
    }
    
    get_insurance_class_quick_search_data_list = async (search_keyword) => {
        var search_key_obj = { keyword: search_keyword };
        const token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await quick_search_class_by_company(this.search_criteria.company_id, search_key_obj, token);
    }

    prepare_ins_class_suggestion = (_data) => {
        let formattedList = [];
        var data_length = 0;
        if (_data.data) {
            data_length = _data.data.length;
            _data = _data.data;
        }
        else {
            data_length = _data.length;
        }
        if (_data && data_length) {
            for (var i = 0; i < data_length; i++) {
                var item = _data[i];
                formattedList.push({
                    "title": `${item.id}`,
                    "code": item.code,
                    "name": item.description,
                    "label": (item.code) + " - " + (item.description) + " " + (item.is_active ? "" : "(I)"),


                })
            }

        }
        return formattedList;
    }

    clear_ins_class_quick_search = () => {       
        this.search_criteria['insurance_class_id']='-1'
        if (this.is_mounted) {
            this.setState({
                insurance_class_quick_search_format: {
                    "title": '',
                    "label": '',
                }
            });
        }
    }

    navigate_to_patient = async (patient_id) => {
        console.log("navigate_to_patient", patient_id);
        if (this.is_mounted) {
            this.setState({
                loading: true
            });
        }
        let auth_data = local_storage.get('auth_data');
        let current_company_id = auth_data && auth_data.company_id;

        if (current_company_id != this.result_search_criteria.company_id) {
            if(detect_ie()){
                let count = parseInt(local_storage.get("open_tab_count"));
                if(count > 1) {
                    this.props.set_prevent_company_model_status(true);
                    if (this.is_mounted) {
                        this.setState({
                            loading: false
                        });
                    }
                    return false;
                }
            }
            if (this.result_search_criteria.company_id ==-1) {
                await get_patient_company_info(patient_id, this.props.user_login_details.user_data.data.token_details.access_token).then(async (response) => {
                    if (response && response.data && response.data.data &&
                        response.data.data.id != current_company_id
                    ) {
                        await get_company_detail_data(response.data.data.id, this.props.user_login_details.user_data.data.token_details.access_token).then(async response => {
                            if (response && response.data) {

                                await this.change_company(response.data);
                                session_storage.set('active_patient', patient_id);
                                this.props.set_active_patient(patient_id);
                                this.props.history.push(`/patient/${patient_id}`)

                            }
                        }).catch(error => {
                            if (this.is_mounted) {
                                this.setState({
                                    loading: false
                                });
                            }
                            })
                    }
                }).catch(error => {
                    if (this.is_mounted) {
                        this.setState({
                            loading: false
                        });
                    }
                });

            } else {
                await get_company_detail_data(this.result_search_criteria.company_id, this.props.user_login_details.user_data.data.token_details.access_token).then(async response => {
                    if (response && response.data) {
                        local_storage.set('report_navigation', {
                            pathname: `/patient/${patient_id}`,
                        });
                        local_storage.set('last_report_navigation', window.location)
                        local_storage.set('company_change_on_same_tab', true);
                        await this.change_company(response.data);
                        session_storage.set('active_patient', patient_id);
                        this.props.set_active_patient(patient_id);
                        this.props.history.push(`/patient/${patient_id}`)
                        clear_sotrage_after_company_change_on_main_page();
                    }
                }).catch(error => {
                    if (this.is_mounted) {
                        this.setState({
                            loading: false
                        });
                    } })
            }            
        } else {
            session_storage.set('active_patient', patient_id);
            this.props.set_active_patient(patient_id);
            this.props.history.push(`/patient/${patient_id}`)
        }

    }

    change_company = async (company_data) => {
        var user_data = this.props.user_login_details.user_data.data;
        user_data.company_id = company_data.id;
        user_data.company_name = company_data.name;
        user_data.gpms_code = company_data.gpms_code;

        // Set auth data in local storage on company changed 
        update_company_details(company_data);

        await this.props.refresh_token(user_data, true);
        console.log("End Charge Audit, refreshing token, user data =>", this.props.user_login_details.user_data.data);

        // get master data when user change company
        this.props.get_master_data(MasterData.all, this.props.user_login_details.user_data.data.user_id, this.props.user_login_details.user_data.data.token_details.access_token, true);
        this.props.get_master_data_by_type(MasterData.all, this.props.user_login_details.user_data.data.user_id, this.props.user_login_details.user_data.data.token_details.access_token, true);
        LaunchDarkley.update();
    }

    refresh_bummepd_charge_modal = () => {
        if (this.is_mounted) {
            this.setState({
                show_bummepd_charge_message_popup: false,
                selected_release_claim: []
            }, () => {
                this.search_release_claims();
            });
        }
    }

    on_close_bummepd_charge_popup = () => {
        if (this.is_mounted) {
            this.setState({
                show_bummepd_charge_message_popup: false
            });
        }
    }

    // Search Criteria Toggler
    controls_toggle = () => {
        if (this.is_mounted) {
            this.setState({
                controls_is_expended: !this.state.controls_is_expended
            });
        }
    };

    render() {
        return (<div className={'admin-wrapper '}>
            <Dimmer active={this.state.loading}>
                <Loader size="massive">Loading</Loader>
            </Dimmer>
            <Grid className="headerGrid" style={{ marginTop: 0, marginBottom: 0 }}>
                <Grid.Column computer={16}>
                    <h3
                        className="ui header left aligned"
                        dangerouslySetInnerHTML={{ __html: 'Release Claim Delay' }}
                    />
                </Grid.Column>
            </Grid>
            <div
                id="admin-scrollable-area"
                className="wrapper"
                style={this.state.show_grid ? { flex: 1, marginBottom: 15 } : {}}
            >
                <div
                    className="common-forms patient-search-form patient_search_bottom_padding"
                    id="search-criteria-container"
                    style={this.state.show_grid ? {} : { paddingBottom: 20 }}
                >
                    <Form autoComplete="off">
                        <Grid className='common-accordion'>
                            <Grid.Column
                                computer={16}
                                tablet={16}
                                textAlign='left'
                                className='accordionColumn'
                                id='accordion-column'>
                                <Accordion fluid styled>
                                    <Accordion.Title active={this.state.controls_is_expended} index={0} onClick={this.controls_toggle}>
                                        <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name='angle right' />
                                        Search Criteria
                                    </Accordion.Title>
                                    <Accordion.Content active={this.state.controls_is_expended}>
                                        <Grid style={{ marginTop: 0 }}>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label>Company</label>
                                                    <Selection
                                                        id="company_id"
                                                        name="company_id"
                                                        options={this.options_data.company_id}
                                                        onChange={(value, event) => this.dropdown_change_handler(event)}
                                                        defaultValue={this.search_criteria.company_id}
                                                        style={'dropdown-options-wrap'}
                                                        autoFocus
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label>Practice</label>
                                                    <Selection
                                                        id="practice_id"
                                                        name="practice_id"
                                                        options={this.options_data.practice_id}
                                                        onChange={(value, event) => this.dropdown_change_handler(event)}
                                                        defaultValue={this.search_criteria.practice_id}
                                                        style={'dropdown-options-wrap'}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label>Location</label>
                                                    <Selection
                                                        id="location_id"
                                                        name="location_id"
                                                        options={this.options_data.location_id}
                                                        onChange={(value, event) => this.dropdown_change_handler(event)}
                                                        defaultValue={this.search_criteria.location_id}
                                                        style={'dropdown-options-wrap'}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label>Provider</label>
                                                    <Selection
                                                        id="provider_id"
                                                        name="provider_id"
                                                        options={this.options_data.provider_id}
                                                        onChange={(value, event) => this.dropdown_change_handler(event)}
                                                        defaultValue={this.search_criteria.provider_id}
                                                        style={'dropdown-options-wrap'}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid>
                                        <Grid style={{ marginTop: 0 }}>
                                            <Grid.Column mobile={8} tablet={8} computer={4}>
                                                <Form.Field className="advance-quick-search">
                                                    <label>Patient</label>
                                                    <AdvancedControl
                                                        id={'patient_id'}
                                                        name={'patient_id'}
                                                        showModal={this.state.show_patient_modal}
                                                        disabled_element={false}
                                                        onGridRowSelection={(row) => {
                                                            this.on_patient_grid_row_selection(row, 'patient_id');
                                                        }}
                                                        gridConfig={this.state.grid_data}
                                                        controlId={'PATIENT'}
                                                        autoFocusForSearchIcon={false}
                                                        onSearch={this.on_patient_search}
                                                        search_type={'PATIENT_REPORT'}
                                                        headerIdForGridTabNavigation={patient_search_configuration_id}
                                                    />
                                                    <AutoSearchComponent
                                                        control_id={'patient_id'}
                                                        getInputValue={null}
                                                        default_value={this.state.patient_search_value}
                                                        errorMessage={'No Patient Found !'}
                                                        prepareRenderList={this.render_result}
                                                        getList={this.get_patient_quick_search_data_list}
                                                        prepareDataList={(data) => this.prepare_suggestion(data)}
                                                        selectresult={(row) => this.on_item_selection(row, 'patient_id')}
                                                        show_clear_search={true}
                                                        clear_search={() => this.clear_quick_search('patient_id')}
                                                        errorClass={false ? 'search-error-thin' : ''}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column mobile={8} tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label>Case</label>
                                                    <Selection
                                                        id="case_id"
                                                        name="case_id"
                                                        options={this.options_data.case_id}
                                                        onChange={(value, event) => this.dropdown_change_handler(event)}
                                                        defaultValue={this.search_criteria.case_id}
                                                        onKeyDown={(value, event) => this.dropdown_change_handler(event)}
                                                        placeHolder={'Select'}
                                                        hidden={this.options_data.case_id.length > 0 ? true : false}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column mobile={8} tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label className="label-normal">From Date</label>
                                                    <DatePicker
                                                        id="from_date"
                                                        name="from_date"
                                                        maxDate={moment().format('MM/DD/YYYY')}
                                                        date={
                                                            this.search_criteria.from_date && moment(this.search_criteria.from_date)
                                                                ? moment(this.search_criteria.from_date)
                                                                : null
                                                        }
                                                        date_update={(date_object) =>
                                                            this.calendar_change_handler(
                                                                charge_constants.enum_date_type.from_date,
                                                                date_object
                                                            )
                                                        }
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column mobile={8} tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label>Through Date</label>
                                                    <DatePicker
                                                        id="through_date"
                                                        name="through_date"
                                                        maxDate={moment().format('MM/DD/YYYY')}
                                                        date={
                                                            this.search_criteria.through_date &&
                                                                moment(this.search_criteria.through_date)
                                                                ? moment(this.search_criteria.through_date)
                                                                : null
                                                        }
                                                        date_update={(date_object) =>
                                                            this.calendar_change_handler(
                                                                charge_constants.enum_date_type.through_date,
                                                                date_object
                                                            )
                                                        }
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid>
                                        <Grid style={{ marginTop: 0 }}>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field className="advance-quick-search">
                                                    <label>Insurance Class</label>
                                                    <AdvancedControl
                                                        onGridRowSelection={this.on_insurance_class_grid_row_selection}
                                                        gridConfig={this.state.insurance_class_search_data}
                                                        controlId={constants.advanced_control_type.insurance_class}
                                                        onSearch={this.on_insurance_class_search}
                                                        search_type={enum_type_of_search.insurance_class}
                                                        headerIdForGridTabNavigation={enum_type_of_search.insurance_class + '_headerid'}
                                                    />
                                                    <AutoSearchComponent
                                                        //on_blur={(e) => { this.on_blur_auto_search(e, enum_type_of_search.insurance_class) }}
                                                        control_id={"insurance_class"}
                                                        getInputValue={null}
                                                        default_value={this.state.insurance_class_quick_search_format}
                                                        errorMessage={'No Record Found !'}
                                                        prepareRenderList={(props) => this.render_result(props, 'insurance_class')}
                                                        getList={this.get_insurance_class_quick_search_data_list}
                                                        prepareDataList={(data) => this.prepare_ins_class_suggestion(data)}
                                                        selectresult={(item) => this.on_insurance_class_item_selection(item)}
                                                        //is_focus={false}
                                                        minimum_search_characters={2}
                                                        show_clear_search={true}
                                                        clear_search={() => this.clear_ins_class_quick_search()}
                                                    //errorClass={this.state.charge_form_invalid.charge.provider && this.state.form_submited ? 'search-error-thin' : ''}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label>Delay Reason</label>
                                                    <Selection
                                                        id="delayed_reason"
                                                        name="delayed_reason"
                                                        options={this.options_data.delayed_reason}
                                                        onChange={(value, event) => this.dropdown_change_handler(event)}
                                                        defaultValue={this.search_criteria.delayed_reason}
                                                        style={'dropdown-options-wrap'}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid>
                                    </Accordion.Content>
                                </Accordion>
                            </Grid.Column>
                        </Grid>
                        <Grid>
                            <Grid.Column tablet={16} computer={16} textAlign="right">
                                <Button type="reset" id="clear_report_button" basic onClick={this.clear_handler}>
                                    Clear
                                    </Button>
                                <Button
                                    id="create_report_button"
                                    className="primary"
                                    onClick={(e) => {
                                        this.search_criteria.is_searched = true;
                                        this.search_release_claims();
                                    }}
                                    type="submit"
                                    onKeyDown={this.handle_focus_for_grid}
                                    style={{ marginRight: '0' }}
                                    disabled={this.state.is_save_button_disabled}
                                >
                                    Search
                                    </Button>
                            </Grid.Column>
                        </Grid>
                        {this.state.show_grid && (
                            <Grid style={{ marginTop: '-1rem', marginBottom: 0 }}>
                                <Grid.Column tablet={5} computer={4} textAlign="left">
                                    <p style={{ fontSize: '16px' }}>Search Results</p>
                                </Grid.Column>
                                <Grid.Column tablet={5} computer={8} textAlign="center">
                                    {
                                        <p style={{ fontSize: '16px', minHeight: 22 }}>
                                            {`${this.state.grid_rows_count} ${
                                                this.state.grid_rows_count == 1 ? ' record shown' : ' records shown'
                                                }`}
                                        </p>
                                    }
                                </Grid.Column>
                                <Grid.Column tablet={2} computer={4} textAlign="right" />
                            </Grid>
                        )}
                    </Form>
                </div>
                {this.state.show_grid && (
                    <GridView
                        row={this.release_data.rows}
                        column={this.release_data.column}
                        style={{ height: '100%' }}
                        wrapperStyle={{ width: '100%', height: '100%', display: 'flex' }}
                        checkboxSelection={true}
                        get_grid_ref={this.get_grid_ref}
                        onRowSelection={this.on_check_box_selection}
                        suppressMovableColumns={false}
                        suppressSizeToFit={true}
                        enableColResize={true}
                        headerHeight={this.grid_header_height}
                        onGridOut={this.on_grid_out}
                        id={charge_constants.release_claim_delay_id}
                        handleCheckboxNavigation={true}
                        enableEnterOnNavigation={true}
                        on_filter_button_click={() => this.update_report_rows_count()}
                    />
                )}
            </div>
            <div
                className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area"
                id="applicationFooterSticky"
                style={{ paddingLeft: 0, paddingRight: 0, marginTop: 'auto' }}
            >
                <Button
                    id="release_claim_delay_save_button"
                    type="Button"
                    disabled={this.release_data.rows.length > 0 ? false : true}
                    onKeyDown={set_focus_to_app_header}
                    onClick={this.release_claim}
                    primary
                >
                    Save
                    </Button>
            </div>

            {this.state.show_bummepd_charge_message_popup ?
                <ConfirmationComponent message={charge_exception.message}
                    title='Confirm' show_modal={this.state.show_bummepd_charge_message_popup}
                    onCloseModal={this.on_close_bummepd_charge_popup}
                    save_button_text='Refresh'
                    on_force_save_click={(e) => { this.refresh_bummepd_charge_modal() }} />
                : ''}
        </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            set_active_patient: set_active_patient,
            refresh_token: refresh_token,
            get_master_data: get_master_data,
            get_master_data_by_type: get_master_data_by_type,
            get_patient_search: get_patient_search,
            set_prevent_company_model_status
        },
        dispatch
    );
};

const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        user_details: state.user_login_details,
        patient_details: state.patient_details
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReleaseClaimDelayComponent));
