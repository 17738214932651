// Providers search component
import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Accordion, Icon, Button, Checkbox, Dimmer, Form, Grid, Input, Loader } from 'semantic-ui-react';
import * as export_print_utility from '../../../reports/util/export_print_utility';
import GridView from '../../../shared/component/grid';
import * as session_storage from '../../../shared/session_storage_utility';
import { handle_click_on_enter, set_focus_on_element_with_id, set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import * as constants_actionss from '../action/constants_action';
import { phone_format, format_zip_code, get_columns, is_user_partner_customer_and_company_revEquip_revServe, get_lauch_darkley_key_value } from "./../../../shared/utility"
import * as constants from '../constants';
import * as local_storage from '../../../shared/local_storage_utility';
import * as global_constants from '../../../global_constants';
import * as ReportConstants from '../../../reports/report_constants';
import { BillingAuditLogPagesFeature, providerBillingSettingsFeatures } from '../constants';
import { withRouter } from 'react-router-dom'


class ClaimholdsSearchComponent extends React.Component<any, any> {

    constructor(props) {
        super(props);
        // Define initial state value.
        this.state = {
            provider_controls_is_expanded: true,
            location_controls_is_expanded: true,
            show_location_grid: false,
            show_provider_grid: false,
            show_render: true,
            provider_search_criteria: [],
            locations_search_criteria: [],
            is_loading: false,
            is_location_grid_loading: false,
            is_provider_grid_loading: false,
            is_provider_search_button_disabled: false,
            shown: false,
            location_grid_rows_count: 0,
            provider_grid_rows_count: 0
        };
    }

    // Define initial properties and its value.
    _is_mounted = false;
    search_type: "provider" | "location" | '' = '';
    page_name = 'claim_holds';
    provider_page_name: string = 'provider';
    location_page_name: string = 'location_claim_holds';
    page_route: string = '';
    unlisten: any;
    page_metadata: any;
    width_padding: number = 100;
    initial_provider_search_criteria: any = {
        provider_code: '',
        first_name: '',
        last_name: '',
        show_inactive: false
    };
    initial_location_search_criteria: any = {
        location_code: '',
        location_name: '',
        show_inactive: false
    };
    provider_search_criteria: any = {
        provider_code: '',
        first_name: '',
        last_name: '',
        show_inactive: false
    };
    location_search_criteria: any = {
        location_code: '',
        location_name: '',
        show_inactive: false
    };
    token: string = '';
    location_row_data = [];
    provider_row_data = [];
    code_id: number = 0;
    title = '';
    current_date: any;
    company_name: any;
    location_total_column_width = 0;
    provider_total_column_width = 0;
    grid_header_height: number = 0;
    current_user_type: global_constants.enum_user_type_r3_id = 1;
    company_service_type = 0;
    reportId: string = "0";

    // Fetching the page_name and state.
    UNSAFE_componentWillMount = () => {
        this.get_page_metadata();
        var auth_data = local_storage.get("auth_data");
        if (auth_data) {
            this.current_user_type = auth_data.user_type_r3_id;
            this.company_service_type = auth_data.company_service_type;
        }
        this.unlisten = this.props.history.listen((location, action) => {
            this.get_page_metadata();
        });
    };

    componentDidMount = () => {
        document.body.classList.add('admin-framework');
        let elem = document.getElementById('report-criteria-container');
        let all_form_elem = elem.getElementsByTagName('input');
        all_form_elem[0].focus();
    };

    componentWillUnmount = () => {
        this.clear_handler(null);
        this.unlisten();
        document.body.classList.remove('admin-framework');
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[1] !== this.page_metadata.route && session_storage.remove(this.page_metadata.provider_storage_key);
        }
    };

    //Use to fetch params and page metadata
    get_page_metadata = () => {
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;

        this.page_metadata = constants.billing_claimholds;

        // filter out column for feature flip
        this.page_metadata.provider_column_def = this.props.features.isProviderClaimHoldEnabled ? this.page_metadata.provider_column_def : this.page_metadata.provider_column_def.filter(c => c.field !== "claim_holds");

        this.provider_search_criteria = session_storage.get(this.page_metadata.provider_storage_key) || this.provider_search_criteria;
        
        this.location_search_criteria = session_storage.get(this.page_metadata.location_storage_key) || this.location_search_criteria;

        this.setState({
            show_render: true
        });
    };

    format_data = (rows, columns) => (this.search_type == this.page_metadata.location_header) ? this.format_location_data(rows, columns) : this.format_provider_data(rows, columns);

    // Data formatter
    format_provider_data = (rows, columns) => {
        let formatted_data = rows.map(row_data => {
            columns.filter(col_data => {
                switch (col_data.type) {
                    case 'currency':
                        row_data[col_data.field] =
                            row_data[col_data.field] != null || row_data[col_data.field] != undefined ? constants.currencyFormatter(row_data[col_data.field]) : constants.currencyFormatter(0);
                        break;
                    case 'boolean':
                        row_data[col_data.field] = row_data[col_data.field] ? 'Active' : 'Inactive';
                        break;
                    case 'number':
                        row_data[col_data.field] = row_data[col_data.field];
                        break;
                    case 'string':
                        row_data[col_data.field] = row_data[col_data.field];
                        break;
                    default:
                        row_data[col_data.field] = row_data[col_data.field];
                }
            });
            return row_data;
        });
        return formatted_data;
    };

    format_location_data = (rows, columns) => {
        let formatted_data = rows.map(row_data => {
            columns.filter(col_data => {
                switch (col_data.type) {
                    case 'currency':
                        row_data[col_data.field] =
                            row_data[col_data.field] != null || row_data[col_data.field] != undefined
                                ? constants.currencyFormatter(row_data[col_data.field])
                                : constants.currencyFormatter(0);
                        break;
                    case 'boolean':
                        if (col_data.field === 'apply_tax' || col_data.field === 'supply_only_charge_entry') {
                            row_data[col_data.field] = row_data[col_data.field] ? true : false;
                        } else {
                            row_data[col_data.field] = row_data[col_data.field] ? 'Active' : 'Inactive';
                        }
                        break;
                    case 'number':
                        row_data[col_data.field] = row_data[col_data.field];
                        break;
                    case 'string':
                        row_data[col_data.field] = row_data[col_data.field];
                        break;
                    case 'phone':
                        row_data[col_data.field] = row_data[col_data.field] ? phone_format(row_data[col_data.field]) : row_data[col_data.field];
                        break;
                    case 'zip':
                        row_data[col_data.field] = row_data[col_data.field] ? format_zip_code(row_data[col_data.field]) : row_data[col_data.field];
                        break;
                    default:
                        row_data[col_data.field] = row_data[col_data.field];
                }
            });
            return row_data;
        });
        return formatted_data;
    };

    //On double click of grid, it redirects user to view page.
    on_row_double_clicked = selected_row_data => {
        if ((this.search_type == this.page_metadata.location_header))
            this.on_location_row_double_clicked(selected_row_data);
        else
            this.on_provider_row_double_clicked(selected_row_data);
    };

    on_provider_row_double_clicked = selected_row_data => {
        let code_id = (this.search_type == this.page_metadata.location_header) ? selected_row_data['place_of_service_id'] : selected_row_data['provider_id'];
        this.props.history.push(`${this.page_metadata.base_url}/${this.provider_page_name}/${code_id}`);
    }

    on_location_row_double_clicked = selected_row_data => {
        let code_id = selected_row_data['place_of_service_id'];
        this.props.history.push(`${this.page_metadata.base_url}/${this.location_page_name}/${code_id}`);
    }

    //Input change handler.
    provider_input_change_handler = e => {
        const { value, checked, type, name } = e.target;
        if (type == 'checkbox') {
            this.provider_search_criteria[name] = checked;
        } else {
            this.provider_search_criteria[name] = value;
        }
        this.setState({
            provider_search_criteria: this.provider_search_criteria
        });
    };

    location_input_change_handler = e => {
        const { value, checked, type, name } = e.target;
        if (type == 'checkbox') {
            this.location_search_criteria[name] = checked;
        } else {
            this.location_search_criteria[name] = value;
        }
        this.setState({
            location_search_criteria: this.location_search_criteria
        });
    };

    location_search_handler = (e) => {
        this.search_type = this.page_metadata.location_header;
        var url = this.page_metadata.api.search.location_url;

        for (let key in this.location_search_criteria) {
            url = url.replace(
                key + '_value',
                typeof this.location_search_criteria[key] !== 'undefined' ? encodeURIComponent(this.location_search_criteria[key].toString().trim()) : ''
            );
        }

        this.setState({
            provider_controls_is_expanded: false,
            is_location_grid_loading: true
        })

        this.search_handler(e, url);
    }

    provider_search_handler = (e) => {
        this.search_type = this.page_metadata.provider_header;
        var url = this.page_metadata.api.search.provider_url;

        for (let key in this.provider_search_criteria) {
            url = url.replace(key + '_value', encodeURIComponent(this.provider_search_criteria[key].toString().trim()));
        }

        this.setState({
            location_controls_is_expanded: false,
            is_provider_grid_loading: true
        })

        this.search_handler(e, url);
    }

    search_handler = (e,url) => {
        
        this.setState({
            is_loading: true,
            is_search_button_disabled: true
        });
        constants_actionss.get_search_data(this.token, url).then(
            response => {
                if (response.data) {
                    let row_data = response.data.data ? response.data.data : []; 

                    if ((this.search_type == this.page_metadata.location_header)) {
                        this.location_row_data = this.format_data(row_data, this.page_metadata.location_column_def);
                        const grid_height = get_columns(row_data, this.page_metadata.location_column_def);
                        this.location_total_column_width = grid_height.total_column_width;
                        this.grid_header_height = grid_height.header_height;
                        this.setState({
                            is_loading: false,
                            show_location_grid: true,
                            is_location_grid_loading: false,
                            location_search_criteria: this.location_search_criteria,
                            location_grid_rows_count: row_data && row_data.length,
                            is_search_button_disabled: false
                        });
                        session_storage.set(this.page_metadata.location_storage_key, this.location_search_criteria);
                    }
                    else {
                        this.provider_row_data = this.format_data(row_data, this.page_metadata.provider_column_def);
                        const grid_height = get_columns(row_data, this.page_metadata.provider_column_def);
                        this.provider_total_column_width = grid_height.total_column_width;
                        this.grid_header_height = grid_height.header_height;
                        this.setState({
                            is_loading: false,
                            show_provider_grid: true,
                            is_provider_grid_loading: false,
                            is_search_button_disabled: false,
                            provider_search_criteria: this.provider_search_criteria,
                            provider_grid_rows_count: row_data && row_data.length
                        });
                        session_storage.set(this.page_metadata.provider_storage_key, this.provider_search_criteria);
                    }
                }
            },
            error => {
                this.setState({
                    is_loading: false,
                    is_location_grid_loading: false,
                    is_provider_grid_loading: false,
                    is_search_button_disabled: false
                });
            }
        );
    };

    clear_handler = e => {
        this.provider_search_criteria = { ...this.initial_provider_search_criteria };
        this.location_search_criteria = { ...this.initial_location_search_criteria };
        this.setState({
            show_provider_grid: false,
            show_location_grid: false,
            shown: false,
            provider_search_criteria: this.initial_provider_search_criteria,
            locations_search_criteria: this.initial_location_search_criteria,
            provider_grid_rows_count: 0,
            location_grid_rows_count: 0
        });
        session_storage.remove(this.page_metadata.provider_storage_key);
        session_storage.remove(this.page_metadata.location_storage_key);
    };

    //Reset button the search control.
    clear_provider_search_handler = e => {
        this.provider_search_criteria = { ...this.initial_provider_search_criteria };
        this.setState({
            show_provider_grid: false,
            shown: false,
            provider_search_criteria: this.initial_provider_search_criteria,
            provider_grid_rows_count: 0
        });
        session_storage.remove(this.page_metadata.provider_storage_key);

    };

    clear_location_search_handler = e => {
        this.location_search_criteria = { ...this.initial_location_search_criteria };
        this.setState({
            show_location_grid: false,
            shown: false,
            locations_search_criteria: this.initial_location_search_criteria,
            location_grid_rows_count: 0
        });

        session_storage.remove(this.page_metadata.location_storage_key);
    };

    //New button handler.
    new_provider_handler = e => {
        this.props.history.push(`${this.page_metadata.base_url}/${this.provider_page_name}`);
    };

    new_location_handler = e => {
        this.props.history.push(`${this.page_metadata.base_url}/${this.location_page_name}`);
    };

    //Function calls on initialization of Ag-Grid and catch its reference.
    get_location_grid_ref = grid_params => {
        this.setState({
            location_grid_params: grid_params,
            location_grid_rows_count: grid_params.api.getModel().getRowCount()
        });
        var grid_width = document.getElementsByClassName('common-forms-search')[0].clientWidth;
        if (this.location_total_column_width > (grid_width - this.width_padding)) {
            this.page_metadata.location_column_def.filter(item => {
                grid_params.columnApi.setColumnWidth(item.field, item.width, false);
            });
        }
    };

    get_provider_grid_ref = grid_params => {
        this.setState({
            provider_grid_params: grid_params,
            provider_grid_rows_count: grid_params.api.getModel().getRowCount()
        });
        var grid_width = document.getElementsByClassName('common-forms-search')[0].clientWidth;
        if (this.provider_total_column_width > (grid_width - this.width_padding)) {
            this.page_metadata.provider_column_def.filter(item => {
                grid_params.columnApi.setColumnWidth(item.field, item.width, false);
            });
        }
    };

    //Used to create the title for export and print.
    set_title = (separator, header, grid_count) => {
        this.company_name = this.props.user_login_details.user_data.data.company_name;
        this.current_date = moment(new Date()).format('MM/DD/YYYY') + ' ' + moment(new Date()).format('hh:mm:ss A');
        this.title = header;
        return this.title =
            this.title.replace(/<br>/g, separator) +
            separator +
            this.company_name +
            separator +
            this.current_date +
            separator +
            (grid_count == 1
                ? grid_count + ' record'
                : grid_count + ' records') +
            separator +
            separator;
    };

    on_provider_export = () => {
        this.title = this.set_title('\n', this.page_metadata.provider_header, this.state.provider_grid_rows_count);
        this.provider_page_name = this.provider_page_name.replace(/<br>/g, '');
        export_print_utility.export_grid_data(this.state.provider_grid_params, this.title, this.provider_page_name);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Export);
    }

    on_location_export = () => {
        this.title = this.set_title('\n', this.page_metadata.location_header, this.state.location_grid_rows_count);
        this.provider_page_name = this.provider_page_name.replace(/<br>/g, '');
        export_print_utility.export_grid_data(this.state.location_grid_params, this.title, this.location_page_name);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Export);
    }

    on_provider_print = () => {
        this.title = this.set_title('<br>', this.page_metadata.provider_header, this.state.provider_grid_rows_count);
        export_print_utility.print_grid_data(this.state.provider_grid_params, this.title, this.provider_page_name);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
    }

    on_location_print = () => {
        this.title = this.set_title('<br>', this.page_metadata.location_header, this.state.location_grid_rows_count);
        export_print_utility.print_grid_data(this.state.location_grid_params, this.title, this.location_page_name);
        this.saveReportEventAction(ReportConstants.ReportEventAction.Print);
    }

    // Function calls save billing audit on Export or Print
    private saveReportEventAction = (eventActionId: number) => {
        const accessToken = this.props.user_login_details.user_data.data.token_details.access_token;
        const { user_id, company_id } = this.props.user_login_details.user_data.data;

        const payload: ReportConstants.IPayloadForBillingAudit = {
            reportId: this.reportId,
            contextTitle: `${this.page_metadata.search_header} - ${ReportConstants.ReportEventAction[eventActionId]}`,
            eventActionId: eventActionId,
            userId: Number(user_id),
            companyId: Number(company_id),
            entityTypeId: ReportConstants.ReportEntityTypeId.C_PROVIDER,
            data: { Records: this.state.grid_params.api.rowModel.rowsToDisplay.length }
        };

        const reqBody = export_print_utility.generateAuditDataEntryPayload(payload);

        ReportConstants.saveAuditPrintExport(reqBody, accessToken);
    };

    provider_controls_toggle = () => {
        this.setState({
            provider_controls_is_expanded: !this.state.provider_controls_is_expanded,
            location_controls_is_expanded: false
        });
    }

    location_controls_toggle = () => {
        this.setState({
            location_controls_is_expanded: !this.state.location_controls_is_expanded,
            provider_controls_is_expanded: false
        });
    }

    on_grid_out = () => {
        set_focus_on_element_with_id('app_header_dropdown');
    };

    update_location_report_rows_count = () => {
        this.setState({
            location_grid_rows_count: this.state.location_grid_params.api.getModel().getRowCount()
        });
    };

    update_provider_report_rows_count = () => {
        this.setState({
            provider_grid_rows_count: this.state.provider_grid_params.api.getModel().getRowCount()
        });
    };

    get_grid_assigned_height = () => {
        var grid_height = document.getElementsByClassName('common-forms-search')[0].clientHeight;
        return `${grid_height - 430}px`;
    }

    //It renders report grid and its search controls.
    render() {
        return (
            this.state.show_render && (
                <React.Fragment>
                    <Dimmer active={this.state.is_loading}>
                        <Loader size='massive'>Loading</Loader>
                    </Dimmer>
                    <div className={'common-forms-search claimholds_search_parent'}>
                        <Grid className='headerGrid' style={{ marginTop: 0, marginBottom: 0 }}>
                            <Grid.Column computer={16}>
                                <h3 className='ui header left aligned' dangerouslySetInnerHTML={{ __html: this.page_metadata.search_header }} />
                            </Grid.Column>
                        </Grid>
                        <div id='admin-scrollable-area' className='report-wrapper'
                            style={(this.state.show_location_grid || this.state.show_provider_grid) ? { flex: 1, paddingRight: '10px'} : {}}>
                            <div className='patient-search-form patient_search_bottom_padding' id='report-criteria-container'>
                                <Form autoComplete='off' onSubmit={e => this.location_search_handler(e)}>
                                    <Grid>
                                        <Grid.Column computer={16} tablet={16} textAlign='left' className='accordionColumn'>
                                            <Accordion fluid styled>
                                                <Accordion.Title active={this.state.location_controls_is_expanded} index={0} onClick={this.location_controls_toggle}>
                                                    <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name={this.state.location_controls_is_expanded ? 'angle down' : 'angle right'} />
                                                    Locations
                                                </Accordion.Title>
                                                <Accordion.Content active={this.state.location_controls_is_expanded}>
                                                    <Grid>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field>
                                                                <label>Location Code</label>
                                                                <Input
                                                                    autoComplete='off'
                                                                    name='location_code'
                                                                    id='location_code'
                                                                    maxLength={50}
                                                                    onChange={e => this.location_input_change_handler(e)}
                                                                    value={this.location_search_criteria['location_code'] ? this.location_search_criteria['location_code'] : ''}
                                                                    type='text'
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field>
                                                                <label>Location Name</label>
                                                                <Input
                                                                    autoComplete='off'
                                                                    name='location_name'
                                                                    id='location_name'
                                                                    maxLength={26}
                                                                    onChange={e => this.location_input_change_handler(e)}
                                                                    value={this.location_search_criteria['location_name'] ? this.location_search_criteria['location_name'] : ''}
                                                                    type='text'
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field>
                                                                <label><p className="ui-grid-label-empty">show_inactive</p></label>
                                                                <Checkbox
                                                                    name='show_inactive'
                                                                    id='show_inactive_location'
                                                                    onChange={e => this.location_input_change_handler(e)}
                                                                    checked={this.location_search_criteria['show_inactive'] ? this.location_search_criteria['show_inactive'] : false}
                                                                    label={'Include Inactive'}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>

                                                    </Grid>
                                                    <Grid style={{ margin: '0 -17px' }}>
                                                        <Grid.Column computer={16} textAlign='right'>
                                                            <Button id='clear_button_id' type='button' onClick={e => this.clear_location_search_handler(e)} basic>Clear</Button>
                                                            {is_user_partner_customer_and_company_revEquip_revServe(this.company_service_type, this.current_user_type) || !this.page_metadata.new_provider_button_enabled ? null :
                                                                <Button id='new_button_id' type='button' onClick={e => this.new_location_handler(e)} basic>New</Button>}
                                                            <Button id='search_button_id' type='submit' primary>Search</Button>
                                                        </Grid.Column>
                                                    </Grid>
                                                    {this.state.show_location_grid && (
                                                        <Grid style={{ marginTop: '-1rem', marginBottom: 0 }}>
                                                            <Grid.Column tablet={5} computer={4} textAlign='left'>
                                                                <p style={{ fontSize: '16px' }}>Search Results</p>
                                                            </Grid.Column>
                                                            <Grid.Column tablet={5} computer={8} textAlign='center'>
                                                                {
                                                                    <p style={{ fontSize: '16px', minHeight: 22 }}>
                                                                        {`${this.state.location_grid_rows_count} ${this.state.location_grid_rows_count == 1 ? ' record shown' : ' records shown'
                                                                            }`}
                                                                    </p>
                                                                }
                                                            </Grid.Column>
                                                            <Grid.Column tablet={2} computer={4} textAlign='right' />
                                                        </Grid>
                                                    )}
                                                    {this.state.show_location_grid && !this.state.is_location_grid_loading && (
                                                        <Grid style={this.state.show_location_grid ? { height: this.get_grid_assigned_height() } : {} }>
                                                        <GridView
                                                            id={`id_grid_${this.page_name}`}
                                                            row={this.location_row_data}
                                                            column={this.page_metadata.location_column_def}
                                                            style={{ height: '100%' }}
                                                            wrapperStyle={{ width: '100%', height: '100%', display: 'flex', flex: 1 }}
                                                            wrapperClass={'grid_wrapper accordian_grid'}
                                                            suppressMovableColumns={false}
                                                            enableColResize={true}
                                                            onRowDoubleClicked={this.on_row_double_clicked}
                                                            selectionType={'single'}
                                                            get_grid_ref={this.get_location_grid_ref}
                                                            suppressSizeToFit={true}
                                                            headerHeight={this.grid_header_height}
                                                            headerIdForTabNavigation={constants.search_grid_id}
                                                            onForceGridOut={this.on_grid_out}
                                                                on_filter_button_click={() => this.update_location_report_rows_count()}
                                                            />
                                                        </Grid>
                                                    )}
                                                    {this.state.show_location_grid && (
                                                        <div
                                                            className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                                                            id='applicationFooterSticky'
                                                            style={{marginTop: 10, marginRight: -15 }}
                                                        >
                                                            <Grid.Column computer={16} textAlign='right' style={{ marginRight: -15 }}>
                                                                <Button
                                                                    id='export_report_button'
                                                                    type='submit'
                                                                    onClick={this.on_location_export}
                                                                    basic>Export</Button>
                                                                <Button
                                                                    onKeyDown={set_focus_to_app_header}
                                                                    id='print_report_button'
                                                                    type='submit'
                                                                    onClick={this.on_location_print}
                                                                    disabled={this.state.is_search_button_disabled}
                                                                    primary>Print</Button>
                                                            </Grid.Column>
                                                        </div>
                                                        )}
                                                </Accordion.Content>
                                            </Accordion>
                                        </Grid.Column>
                                    </Grid> 
                                </Form>
                            </div>
                            
                            <div className='patient-search-form patient_search_bottom_padding' id='report-criteria-container'>
                                <Form autoComplete='off' onSubmit={e => this.provider_search_handler(e)}>
                                    <Grid>
                                        <Grid.Column computer={16} tablet={16} textAlign='left' className='accordionColumn'>
                                            <Accordion fluid styled>
                                                <Accordion.Title active={this.state.provider_controls_is_expanded} index={0} onClick={this.provider_controls_toggle}>
                                                    <Icon onKeyDown={handle_click_on_enter} tabIndex={0} name={this.state.provider_controls_is_expanded ? 'angle down' : 'angle right'} />
                                                    Providers
                                                </Accordion.Title>
                                                <Accordion.Content active={this.state.provider_controls_is_expanded}>
                                                    <Grid>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field>
                                                                <label>Provider Code</label>
                                                                <Input
                                                                    autoComplete='off'
                                                                    name='provider_code'
                                                                    id='provider_code'
                                                                    maxLength={50}
                                                                    onChange={e => this.provider_input_change_handler(e)}
                                                                    value={this.provider_search_criteria['provider_code'] ? this.provider_search_criteria['provider_code'] : ''}
                                                                    type='text'
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field>
                                                                <label>Last Name</label>
                                                                <Input
                                                                    autoComplete='off'
                                                                    name='last_name'
                                                                    id='last_name'
                                                                    maxLength={50}
                                                                    onChange={e => this.provider_input_change_handler(e)}
                                                                    value={this.provider_search_criteria['last_name'] ? this.provider_search_criteria['last_name'] : ''}
                                                                    type='text'
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column tablet={8} computer={4}>
                                                            <Form.Field>
                                                                <label>First Name</label>
                                                                <Input
                                                                    autoComplete='off'
                                                                    name='first_name'
                                                                    id='first_name'
                                                                    maxLength={50}
                                                                    onChange={e => this.provider_input_change_handler(e)}
                                                                    value={this.provider_search_criteria['first_name'] ? this.provider_search_criteria['first_name'] : ''}
                                                                    type='text'
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column tablet={8} computer={4} verticalAlign="middle">
                                                            <Form.Field >
                                                                <label><p className="ui-grid-label-empty">show_inactive</p></label>
                                                                <Checkbox
                                                                    name='show_inactive'
                                                                    id='show_inactive'
                                                                    onChange={e => this.provider_input_change_handler(e)}
                                                                    checked={this.provider_search_criteria['show_inactive'] ? this.provider_search_criteria['show_inactive'] : false}
                                                                    label={'Include Inactive'}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid>
                                                    <Grid style={{ margin: '0 -17px' }}>
                                                        <Grid.Column computer={16} textAlign='right'>
                                                            <Button id='clear_button_id' type='button' onClick={e => this.clear_provider_search_handler(e)} basic>Clear</Button>
                                                            {is_user_partner_customer_and_company_revEquip_revServe(this.company_service_type, this.current_user_type) || !this.page_metadata.new_provider_button_enabled ? null :
                                                                <Button id='new_button_id' type='button' onClick={e => this.new_provider_handler(e)} basic>New</Button>}
                                                            <Button id='search_button_id' type='submit' primary>Search</Button>
                                                        </Grid.Column>
                                                    </Grid>
                                                    {this.state.show_provider_grid && (
                                                        <Grid style={{ marginTop: '-1rem', marginBottom: 0 }}>
                                                            <Grid.Column tablet={5} computer={4} textAlign='left'>
                                                                <p style={{ fontSize: '16px' }}>Search Results</p>
                                                            </Grid.Column>
                                                            <Grid.Column tablet={5} computer={8} textAlign='center'>
                                                                {
                                                                    <p style={{ fontSize: '16px', minHeight: 22 }}>
                                                                        {`${this.state.provider_grid_rows_count} ${this.state.provider_grid_rows_count == 1 ? ' record shown' : ' records shown'
                                                                            }`}
                                                                    </p>
                                                                }
                                                            </Grid.Column>
                                                            <Grid.Column tablet={2} computer={4} textAlign='right' />
                                                        </Grid>
                                                    )}
                                                    {this.state.show_provider_grid && !this.state.is_provider_grid_loading && (
                                                        <Grid style={this.state.show_provider_grid ? { height: this.get_grid_assigned_height() } : {}}>
                                                        <GridView
                                                            id={`id_grid_${this.page_name}`}
                                                            row={this.provider_row_data}
                                                            column={this.page_metadata.provider_column_def}
                                                            style={{ height: '100%' }}
                                                            wrapperStyle={{ width: '100%', height: '100%', display: 'flex', flex: 1 }}
                                                            wrapperClass={'grid_wrapper accordian_grid'}
                                                            suppressMovableColumns={false}
                                                            enableColResize={true}
                                                            onRowDoubleClicked={this.on_row_double_clicked}
                                                            selectionType={'single'}
                                                            get_grid_ref={this.get_provider_grid_ref}
                                                            suppressSizeToFit={true}
                                                            headerHeight={this.grid_header_height}
                                                            headerIdForTabNavigation={constants.search_grid_id}
                                                            onForceGridOut={this.on_grid_out}
                                                                on_filter_button_click={() => this.update_provider_report_rows_count()}
                                                            />
                                                        </Grid>
                                                    )}
                                                    {this.state.show_provider_grid && (
                                                        <div
                                                            className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                                                            id='applicationFooterSticky'
                                                            style={{ marginTop: 10, marginRight: -15 }}
                                                        >
                                                            <Grid.Column computer={16} textAlign='right' style={{ marginRight: -15}}>
                                                                <Button
                                                                    id='export_report_button'
                                                                    type='submit'
                                                                    onClick={this.on_provider_export}
                                                                    basic>Export</Button>
                                                                <Button
                                                                    onKeyDown={set_focus_to_app_header}
                                                                    id='print_report_button'
                                                                    type='submit'
                                                                    onClick={this.on_provider_print}
                                                                    disabled={this.state.is_search_button_disabled}
                                                                    primary>Print</Button>
                                                            </Grid.Column>
                                                        </div>
                                                        )}
                                                </Accordion.Content>
                                            </Accordion>
                                        </Grid.Column>
                                    </Grid>
                                </Form>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        );
    }
}

//Get user and login details from store.
const mapStateToProps = state => {
    return {
        selected_patient: state.patient_details.patient_header,
        user_login_details: state.user_login_details,
        features: {            
            isProviderClaimHoldEnabled: get_lauch_darkley_key_value(state.launch_darkly, providerBillingSettingsFeatures.rsiB31057ProviderClaimsSettingsPane),
        }
    };
};

export default connect(mapStateToProps)(ClaimholdsSearchComponent);