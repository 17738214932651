import * as React from 'react';
import { Button, Form, Grid, Modal } from 'semantic-ui-react';
import { setTimeout } from 'timers';

class ConfirmationComponent extends React.Component<any, any> {
    [x: string]: any;

    constructor(props) {
        super(props);
        //TODO: remove when year picker implemented
        this.state = { show_modal: this.props.show_modal, show_cancel_button: true };
    }
    UNSAFE_componentWillMount() {
        this.setState({ 
            show_modal: this.props.show_modal, 
            show_cancel_button: this.props.showCancelButton == null ? true : this.props.showCancelButton 
        });
        //this.resetComponent()
    }

    componentDidMount() {
       

        this.initialize_events();
    }

    initialize_events = () => {
        let el: HTMLElement = document.querySelector('#confirmationBillingPopup .close');
        if (el) {
            el.setAttribute('tabIndex', '0');

            if (this.props.custom_id) {
                el.setAttribute('id', this.props.custom_id);

            }
            el.addEventListener("keydown", function (event) {
                if (!event.shiftKey && (event.keyCode == 9 || event.which == 9)) {
                    event.preventDefault();
                    let search_button = document.getElementById("btn_cancel");
                    search_button.focus();
                }
                else if (event.shiftKey && (event.keyCode == 9 || event.which == 9)) {
                    event.preventDefault();
                    let search_button = document.getElementById("btn_ok");
                    search_button.focus();
                }
                else if (event.keyCode == 13 || event.which == 9) {
                    event.preventDefault();
                    var el = event.target as HTMLElement
                    el.click();
                }
            })
        }
    }
    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(undefined)
        }
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.show_modal !== this.props.show_modal) {
            this.setState({ show_modal: this.props.show_modal })

            if (this.props.custom_id && this.props.show_modal) {
                setTimeout(() => {
                    this.initialize_events();
                }, 100);
               
            }
        }
    }
    // remove the display none class for all the options of payer
    removeDisplayNoneClass = () => {
        var selectedObject = document.getElementsByTagName('select');
        if (selectedObject && selectedObject.length) {
            for (var i = 0; i < selectedObject.length; i++) {
                for (var j = 0; j < selectedObject[i].length; j++) {
                    selectedObject[i].options[j].classList.remove("display-none");
                }
            }
        }
    }
    //close the modal on selection of patient through quick search
    closeModal = () => {
        //if open modal using application header

        this.setState({ show_modal: false });
        this.props.onCloseModal();
        this.removeDisplayNoneClass();
    };
    on_save_click = () => {
        this.props.on_force_save_click()
        this.removeDisplayNoneClass();
    }
    on_cancel_click = () => {
        if (this.props.on_cancel_click) {
            this.props.on_cancel_click();
        }
        this.closeModal();

    }
    ok_tab_focus_event = (e, id = '') => {
        if (!e.shiftKey && (e.keyCode == 9 || e.which == 9)) {
            e.preventDefault();
            let el: HTMLElement =  null;
            if (this.props.custom_id) {
                el = document.getElementById(this.props.custom_id);
            } else {
                el = document.querySelector('#confirmationBillingPopup .close');
            }
            
            if (el) {
                el.focus();
                
            }
        }
        else if (e.shiftKey && (e.keyCode == 9 || e.which == 9)) {
            e.preventDefault();
            let el: HTMLElement = document.querySelector('#btn_cancel');
            if (el) {
                el.focus();
               
            }
        }
    }

    cancel_tab_focus_event = (e, id = '') => {
        if (e.shiftKey && (e.keyCode == 9 || e.which == 9)) {
            let el: HTMLElement = null;
            if (this.props.custom_id) {
                el = document.getElementById(this.props.custom_id);
            } else {
                el = document.querySelector('#confirmationBillingPopup .close');
            }
            if (el) {
                el.focus();
                e.preventDefault();
            }
        }
    }

    render() {
        const { show_modal } = this.state;
        let cancel_button_text = "Cancel";
        let save_button_text = "Save";
        cancel_button_text = this.props.cancel_button_text ? this.props.cancel_button_text : cancel_button_text;
        save_button_text = this.props.save_button_text ? this.props.save_button_text : save_button_text;
        return (
            <div className="item">
                <Modal
                    onClose={this.closeModal}
                    centered={false}
                    className="searchCompany"
                    open={show_modal}
                    id={'confirmationBillingPopup'}
                    closeIcon
                    closeOnDimmerClick={false}
                >
                    <Modal.Header>{this.props.title}</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Grid>
                                <Grid.Column>
                                    <Grid>
                                        <Grid.Column tablet={16} computer={16}>
                                            <Form.Field>
                                                <label dangerouslySetInnerHTML={{ __html: this.props.message }}/>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column tablet={16} computer={16} align="right">
                                            {this.state.show_cancel_button && 
                                            (<Button type='cancel' id='btn_cancel' onKeyDown={this.cancel_tab_focus_event} basic onClick={this.on_cancel_click}>{cancel_button_text}</Button>)}
                                            <Button type='submit' id='btn_ok' autoFocus={true}
                                                onKeyDown={this.ok_tab_focus_event} primary onClick={this.on_save_click}>{save_button_text}</Button>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                            </Grid>

                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}



export default ConfirmationComponent