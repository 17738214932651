import * as moment from 'moment';
import * as React from 'react';
import { connect } from "react-redux";
import TextareaAutosize from 'react-textarea-autosize';
import { Form } from 'semantic-ui-react';
import { quick_search_location, quick_search_provider, save_credits, search_place, search_billing_and_treating_provider } from "../../payment/action/payment_action";
import { payment_const } from '../../payment/payment_constants';
import AdvancedControl from "../../shared/component/advanced_control";
import Calendar from '../../shared/component/calendar_component';
import Selection from '../../shared/component/selection_component';
import * as local_storage from '../../shared/local_storage_utility';
import { amount_formatter, date_format_with_no_time } from '../../shared/utility';
import { get_place_of_service, get_provider, get_popper_id } from '../utility';
import AutoSearchComponent from './../../shared/component/auto_search_component';
import { search_location_configuration, search_provider_configuration } from '../../shared/shared_constants';

const credit_data = {};

class ItemActiveComponent extends React.Component<any, any> {

    button_location_clear: HTMLElement;
    button_provider_clear: HTMLElement;
    button_location_search: HTMLElement;
    button_provider_search: HTMLElement;
    _is_mounted = false;

    // Get innitalise data when component created
    constructor(props) {
        super(props);
        this.state = {
            checked: this.props.checked,
            closeControl: false,
            credit_data: this.init_credit_data(),
            payment_code: {},
            patient_credit_type: {},
            location_id: this.props.data.place_of_service && this.props.data.place_of_service.id || 0,
            location_search_data: {},
            provider_search_data: {},
            selected_location: { label: get_place_of_service(this.props.data.place_of_service) },
            selected_provider: { label: get_provider(this.props.data.billing_provider) },
            payment_money_type: 0,
            check_num_required: false,
            ...this.init_money_types(this.props.data.payment_money_type),
            minDate: null,
            is_fetched: false,
            err_cc_num: false,
            err_deposit_date: false,
            err_place_of_service: false,
            change_payment_method: false
        };
    }
    initial_data = null;
    init_credit_data = () => {
        const { data } = this.props;
        const credit_data = {};
        credit_data['patient_credit_id'] = data.patient_credit_id;
        credit_data['patient_id'] = data.patient_id;
        credit_data['deposit_date'] = this.get_date(data.deposit_date, 'MM/DD/YYYY');;
        credit_data['date_of_service'] = this.get_date(data.date_of_service, 'MM/DD/YYYY');;
        credit_data['patient_credit_type'] = data.patient_credit_type;
        credit_data['insurance_name'] = data.insurance_name;
        credit_data['insurance_code'] = data.insurance_code;
        credit_data['is_active'] = data.is_active;
        credit_data['original_amount'] = data.original_amount;
        credit_data['payment_code'] = data.payment_code;
        credit_data['payment_money_type'] = data.payment_money_type;
        credit_data['place_of_service'] = data.place_of_service;
        credit_data['amount'] = data.amount;
        credit_data['batch'] = data.batch;
        credit_data['billing_provider'] = data.billing_provider;
        credit_data['cc_num'] = data.cc_num == '' ? null : data.cc_num;
        credit_data['charge_id'] = data.charge_id;
        credit_data['check_num'] = data.check_num == '' ? null : data.check_num;
        credit_data['comment'] = data.comment;
        if( this.props.addBatchNum)
            credit_data['batch_Num'] = data.batch_Num;
        credit_data['credit_card_auth_num'] = data.cc_num == '' ? null : data.cc_num;
        credit_data['check_credit_card'] = data.cc_num == '' ? null : data.cc_num;
        return credit_data;
    }

    componentDidMount() {
        const auth_data = local_storage.get("auth_data");
        let accrual_flag = false;
        let open_month = null;
        if (auth_data) {
            accrual_flag = auth_data.accrual_accounting || false;
            open_month = auth_data.open_month || null;
        }
        var minDate = null;
        if (accrual_flag) {
            minDate = new Date(open_month);
        }
        this.setState({
            minDate: minDate
        });
        this.initial_data = this.state.credit_data;
        this.props.onRef(this);
        this._is_mounted = true;

        this.props.save_credits(this.state.credit_data, payment_const.save_credits);

        this.button_location_clear = document.querySelector(`.search-location-${this.props.row_num} .closeIcon`);
        this.button_provider_clear = document.querySelector(`.search-provider-${this.props.row_num} .closeIcon`);
        this.button_location_search = document.querySelector(`.search-location-${this.props.row_num} .searchIcon`);
        this.button_provider_search = document.querySelector(`.search-provider-${this.props.row_num} .searchIcon`);
        if (this.props.disabled && this.props.disabled === true) {
            this.btn_set_disabled(this.button_location_search);
            this.btn_set_disabled(this.button_provider_search);
        }
    }
    componentWillUnmount() {
        this.props.onRef(undefined)
        this._is_mounted = false;
    }

    //Component update function
    componentDidUpdate(previousProps, previousState) {
        const { shouldUpdate, checked, data, selectionCount } = this.props;
        if (shouldUpdate === true && previousProps.checked !== checked) {
            this.setState({ checked });
        } else if (this.state.checked === true && this.props.isClear === true) {
            this.setState({ checked: false });
        } else if (data != previousProps.data) {
            this.initial_data = this.init_credit_data();
            const money_type = this.init_money_types(this.props.data.payment_money_type);
            const selected_location = { label: get_place_of_service(this.props.data.place_of_service) };
            const selected_provider = { label: get_provider(this.props.data.billing_provider) };
            this.setState({
                credit_data: this.initial_data,
                is_fetched: true,
                checked,
                ...money_type,
                selected_location,
                selected_provider,
                err_cc_num: false,
                err_deposit_date: false,
                err_place_of_service: false,
                change_payment_method: false
            });

        }
        if ((JSON.stringify(previousState.credit_data) !== JSON.stringify(this.state.credit_data)) && !this.state.is_fetched) {
            this.props.check_unsave_callback(this.initial_data, this.state.credit_data, this.props.row_num);
        }

    }

    btn_set_focus = (element) => {
        if (this._is_mounted) {
            setTimeout(() => {
                if (element) {
                    element.focus();
                }
            }, 100);
        }
    }

    btn_set_disabled = (element: HTMLElement) => {
        if (element) {
            element['disabled'] = true;
        }
    }

    // Will handle date selection from calendar  
    handle_calendar_change = (date) => {
        const deposit_date = this.get_date(date, 'MM/DD/YYYY');
        const credit_data_updated = { ...this.state.credit_data, deposit_date };

        this.setState(prevState => ({
            credit_data: credit_data_updated,
            is_fetched: false,
            err_deposit_date: false,
        }));
    }

    handle_date_of_service_change = (date) => {
        const date_of_service = this.get_date(date, 'MM/DD/YYYY');
        const credit_data_updated = { ...this.state.credit_data, date_of_service };

        this.setState(prevState => ({
            credit_data: credit_data_updated,
            is_fetched: false,
            err_deposit_date: false,
        }));
    }

    on_check_change(event) {
        this.setState({ checked: event.target.checked });
        this.props.onChange(event.target.checked, this.props.data, this.state.credit_data);
    }

    on_change_cc = e => {
        const input_value = e.target.value;
        const credit_data_updated = { ...this.state.credit_data };

        //credit_data_updated.check_num = input_value || null;
        credit_data_updated.cc_num = input_value || null;
        credit_data_updated.credit_card_auth_num = input_value || null;
        credit_data_updated.check_credit_card = input_value || null;

        this.setState(prevState => ({
            credit_data: credit_data_updated,
            err_cc_num: false,
            is_fetched: false
        }));
    }

    on_change_comment = e => {
        const input_value = e.target.value;
        const credit_data_updated = { ...this.state.credit_data };
        credit_data_updated.comment = input_value;
        this.setState(prevState => ({
            credit_data: credit_data_updated,
            is_fetched: false
        }));
    }

    init_money_types = (payment_money_type) => {
        if (payment_money_type == 1) {
            return {
                payment_money_type: 1,
                check_num_required: false
            }
        } else if (payment_money_type == 2) {
            return {
                payment_money_type: 2,
                check_num_required: true
            }
        } else if (payment_money_type == 3) {
            return {
                payment_money_type: 3,
                check_num_required: true
            }
        }
        else if (payment_money_type == 4) {
            return {
                payment_money_type: 4,
                check_num_required: true
            }
        }
        else if (payment_money_type == 5) {
            return {
                payment_money_type: 5,
                check_num_required: true
            }
        }
    }

    get_row_num = () => this.props.row_num;

    get_date = (date, pattern) => {
        if (date) {
            return date_format_with_no_time(date).format(pattern);
        }
        return '';
    }

    //Get data retruns updated data item.
    get_updated_data = () => ({ ...this.state.credit_data, check_num: this.state.credit_data.cc_num });

    //Get data retruns data item.
    get_data = () => ({ ...this.props.data });

    //isChecked retruns selection status.
    is_checked = () => this.state.checked;

    //Check form validation
    is_valid = () => {
        let flag_value = true;
        const cc_num = this.state.credit_data['check_credit_card'] || '';
        const card_number = cc_num.toString().trim();
        let update_value = {};
        //Credit card validation not required
        //if (this.state.check_num_required == true && card_number.length < 1) {
        //    update_value['err_cc_num'] = true;
        //    flag_value = false;
        //}
        const deposit_date = this.state.credit_data.deposit_date;
        if (deposit_date === null || deposit_date === undefined || deposit_date === '') {
            update_value['err_deposit_date'] = true;
            flag_value = false;
        }
        if (JSON.stringify(this.state.credit_data.place_of_service) == '{}') {
            update_value['err_place_of_service'] = true;
            flag_value = false;
        }
        if (flag_value === false) {
            this.setState({ ...update_value })
        }
        return flag_value;
    }

    required_date = () => {
        let value = '';
        if (this.state.err_deposit_date === true) {
            value = 'required-field';
        }
        return value;
    }

    required_location = () => {
        let value = '';
        if (this.state.err_place_of_service === true) {
            value = 'requiredWithBgColor';
        }
        return value;
    }

    // search location by typing name or code
    on_place_search = async (params) => {
        const token = local_storage.get("auth_data").token_details.access_token;
        const search_data = await search_place(params, token).then(res => res.data);
        const search_result = search_data.data !== null ? search_data.data.result : [];
        const grid_data = {
            ...this.state.grid_conf, rows: search_result,
            column: search_location_configuration(`modal-location-${this.props.row_num}`).column_defs,
            messages: search_data.messages
        };
        this.setState({ location_search_data: grid_data });
    }

    // search provider using code and description
    on_provider_search = async (params) => {
        const token = local_storage.get("auth_data").token_details.access_token;
        params.id = this.state.location_id;
        const search_data = await search_billing_and_treating_provider(params, token).then(res => res.data);
        const search_result = search_data.data !== null ? search_data.data.result : [];
        const grid_data = {
            ...this.state.grid_conf, rows: search_result,
            column: search_provider_configuration(`modal-provider-${this.props.row_num}`).column_defs,
            messages: search_data.messages
        };
        this.setState({ provider_search_data: grid_data });
    }

    // select a location row in grid on modal
    on_location_grid_row_selection = (selected_row) => {
        const credit_data_updated = { ...this.state.credit_data, place_of_service: selected_row };
        this.setState(prevState => ({
            error: { ...prevState.error, location: false },
            selected_location: { label: get_place_of_service(selected_row) },
            location_id: selected_row.id || 0,
            credit_data: credit_data_updated,
            is_fetched: false,
            err_place_of_service: false
        }));
    }

    // on provider search item selection
    on_provider_grid_row_selection = (selected_row) => {
        const credit_data_updated = { ...this.state.credit_data, billing_provider: selected_row };
        this.setState(prevState => ({
            selected_provider: { label: get_provider(selected_row) },
            credit_data: credit_data_updated,
            is_fetched: false
        }));
    }

    //Auto search for Location
    render_result = (props) => {
        return (<div key={props.title} tabIndex={0} className="item_auto_search">
            <div key={props.title} className="fs_13">
                <span>{props.label}</span>
            </div>
        </div>);
    }

    // get the quick search location apis data
    get_location_data_list = async (search_keyword) => {
        const token = local_storage.get("auth_data").token_details.access_token;
        return await quick_search_location(search_keyword, token);
    }

    // get the quick search provider apis data
    get_provider_data_list = async (search_keyword) => {
        //var search_key_obj = { key: search_keyword, locationId: this.state.location_id };
        var search_key_obj = { key: search_keyword, locationId: -1 };//Search provider irrespective of location value as -1
        const token = local_storage.get("auth_data").token_details.access_token;
        return await quick_search_provider(search_key_obj, token);
    }

    // preapare the suggestion list with search result
    prepare_suggestion = (data) => {
        let formattedList = [];
        var data_length = data.data.length;
        if (data && data_length) {
            for (let i = 0; i < data_length; i++) {
                let item = data.data[i];
                let name = typeof item.name === 'undefined' ? item.description : item.name;
                item = { ...item, title: name, label: item.code == '' ? name : item.code + ' - ' + name };
                formattedList.push(item);
            }
        }
        return formattedList;
    }

    // select the item on click suggested items
    onselection = (item, type) => {
        if (type === 'provider') {
            this.on_provider_grid_row_selection(item);
        } else {
            this.on_location_grid_row_selection(item);
        }
    }

    clear_quick_search = (type) => {
        if (type === 'provider') {
            this.on_provider_grid_row_selection({});
        } else {
            this.on_location_grid_row_selection({});
        }
    }

    // get the searched value which is typed for searching location or providers
    get_input_value = (input, type) => {
        if (input.length >= 3) {
            if (type === 'provider') {
                this.get_provider_data_list(input);
            } else {
                this.get_location_data_list(input);
            }
        }
    }
    on_dropdown_payment_method = (value) => {
        const money_types = this.init_money_types(value);
        this.setState(prevState => ({
            credit_data: { ...this.state.credit_data, payment_money_type: money_types.payment_money_type },
            ...money_types,
            err_cc_num: false,
            is_fetched: false,
            change_payment_method: true
        }))
    }

    on_dropdown_payment_code = (value) => {
        const selected = this.props.paymentCode.filter(data => {
            return data.value == value
        })
        if (selected && selected.length > 0) {
            const credit_data_updated = { ...this.state.credit_data, payment_code: selected[0].data };
            this.setState(prevState => ({
                credit_data: credit_data_updated,
                is_fetched: false
            }))
        }
    }

    on_dropdown_insurance = (value) => {
        const selected = this.props.insurance.filter(data => {
            return data.value == value
        })
        if (selected && selected.length > 0) {
            const { key, value, text } = selected[0];
            const credit_data_updated = { ...this.state.credit_data, insurance_name: text, insurance_code: value };
            this.setState(prevState => ({
                credit_data: credit_data_updated,
                is_fetched: false
            }))
        }

    }

    // on close modal method will be fired
    close_locaton_modal = () => {
        this.btn_set_focus(this.button_location_clear);
    }

    close_provider_modal = () => {
        this.btn_set_focus(this.button_provider_clear);
    }

    //Get default date selection
    default_date = (date, pattern) => {
        if (date) {
            const dt = moment(date).format(pattern);
            return moment(dt);
        }
        return null;
    }

    get_payment_code = (data) => {
        const { patient_credit_type, payment_code } = data;
        if (patient_credit_type && patient_credit_type.credit_type_id && patient_credit_type.credit_type_id != 1) {
            let code = '';
            if (payment_code) {
                code = `${payment_code.payment_desc || ''}`
            }
            return code;
        }
        return (<Selection
            placeHolder={'Select'}
            hidden={true}
            id={`id_payment_code_${this.props.row_num}`}
            defaultValue={data.payment_code.payment_id}
            options={this.props.paymentCode}
            onChange={value => this.on_dropdown_payment_code(value)}
            disabled={this.props.disabled}
        />);
    }

    render_checkbox = () => {
        const { data, disabled } = this.props;
        if (data.is_active && data.is_active == true) {
            return (<input type="checkbox"
                id={`id_check_credit_item_${this.props.row_num}`}
                disabled={disabled}
                onChange={this.on_check_change.bind(this)}
                checked={this.state.checked} />);
        }
        return null;
    }
    get_payment_method_list = () => {
        let current_payment_method_list = [...this.props.payment_method_list];

        //if feature flip is off and user select value when FF is on
        if (!this.state.change_payment_method && current_payment_method_list.length != payment_const.all_payment_method.length) {
            var curr_payment_method_exist = current_payment_method_list.filter((item) => {
                if (item.value == this.state.payment_money_type) {
                    return item
                }
            })
            if (curr_payment_method_exist.length == 0) {
                curr_payment_method_exist = payment_const.all_payment_method.filter((item) => {
                    if (item.value == this.state.payment_money_type) {
                        return item;
                    }
                })
                if (curr_payment_method_exist.length > 0) {
                    current_payment_method_list.push(curr_payment_method_exist[0]);
                }
            }
        }
        return current_payment_method_list;
    }

    //Render function
    render() {
        const { data } = this.props;
        let current_payment_method_list = this.get_payment_method_list();

        return (<tr>
            <td className="text-center" style={{ width: '50px' }}>
                {this.render_checkbox()}
            </td>
            <td data-label="date" className={`custom-datepicker-for-table datepicker-keybowrd-${this.props.row_num}}`}>
                <Calendar
                    id={`date_of_service_${this.props.row_num}`}
                    id_popper={get_popper_id()}
                    date_update={this.handle_date_of_service_change}
                    date={this.default_date(this.state.credit_data['date_of_service'], 'MM/DD/YYYY')}
                    is_disabled={data.is_payment_distributed || this.props.disabled}
                    class_name={`datepicker-keybowrd-${this.props.row_num}`}
                />
            </td>
            <td data-label="date" className={`custom-datepicker-for-table datepicker-keybowrd-${this.props.row_num} ${this.required_date()}`}>
                <Calendar
                    id={`credit_date_${this.props.row_num}`}
                    id_popper={get_popper_id()}
                    is_required={this.state.err_deposit_date}
                    maxDate={moment().format('MM/DD/YYYY')}
                    date_update={this.handle_calendar_change}
                    date={this.default_date(this.state.credit_data['deposit_date'], 'MM/DD/YYYY')}
                    minDate={this.state.minDate}
                    is_disabled={this.props.disabled}
                    class_name={`datepicker-keybowrd-${this.props.row_num}`}
                />
            </td>
            <td data-label="transaction-type" >
                {data.patient_credit_type && data.patient_credit_type.credit_type || ''}
            </td>
            <td data-label="payment-code">
                {this.get_payment_code(data)}
            </td>
            <td data-label="unallocated-amount" className='text-right'>{amount_formatter(data.amount)}</td>
            <td data-label="total-payment" className='text-right'>{amount_formatter(data.original_amount)}</td>
            <td data-label="payment-method">
                <Selection
                    id={`id_payment_method_${this.props.row_num}`}
                    defaultValue={this.state.payment_money_type || 2}
                    options={current_payment_method_list}
                    onChange={value => this.on_dropdown_payment_method(value)}
                    disabled={this.props.disabled}
                />
            </td>
            <td data-label="credit-card">
                <Form.Field className={this.state.err_cc_num ? 'requiredWithBgColor' : ''}>
                    <Form.Input fluid maxLength={50}
                        name='txt_card_num'
                        onChange={e => this.on_change_cc(e)}
                        value={this.state.credit_data['check_credit_card'] || ''}
                        error={this.state.err_cc_num}
                        disabled={this.props.disabled}
                    />
                </Form.Field>
            </td>
            <td data-label="place-service">
                <Form.Field className={`advance-quick-search search-location-${this.props.row_num} ${this.required_location()}`}>
                    <AdvancedControl
                        gridConfig={this.state.location_search_data}
                        onGridRowSelection={this.on_location_grid_row_selection}
                        controlId={'LOCATION'}
                        onSearch={this.on_place_search}
                        onCloseModal={this.close_locaton_modal}
                        className={`modal-location-${this.props.row_num}`}
                        search_type={`PLACE_SERVICE_LOCATION_${this.props.row_num}`}
                        headerIdForGridTabNavigation={`modal-location-${this.props.row_num}`}
                    />
                    <AutoSearchComponent
                        default_value={this.state.selected_location}
                        getInputValue={this.get_input_value}
                        getList={this.get_location_data_list}
                        prepareDataList={this.prepare_suggestion}
                        selectresult={this.onselection}
                        errorClass={this.state.err_place_of_service ? 'error' : ''}
                        errorMessage={'No Location Found !'}
                        is_focus={false}
                        is_disabled={this.props.disabled}
                        show_clear_search={true}
                        clear_search={() => this.clear_quick_search('location')}
                    />
                </Form.Field>
            </td>
            <td data-label="provider">
                <Form.Field className={`advance-quick-search search-provider-${this.props.row_num}`}>
                    <AdvancedControl
                        gridConfig={this.state.provider_search_data}
                        onGridRowSelection={this.on_provider_grid_row_selection}
                        controlId={'PROVIDER'}
                        onSearch={this.on_provider_search}
                        onCloseModal={this.close_provider_modal}
                        className={`modal-provider-${this.props.row_num}`}
                        search_type={`PROVIDER_${this.props.row_num}`}
                        headerIdForGridTabNavigation={`modal-provider-${this.props.row_num}`}
                    />
                    <AutoSearchComponent
                        default_value={this.state.selected_provider}
                        getInputValue={(input) => this.get_input_value(input, 'provider')}
                        getList={this.get_provider_data_list}
                        prepareDataList={this.prepare_suggestion}
                        selectresult={(item) => this.onselection(item, 'provider')}
                        errorMessage={'No Billing Provider Found !'}
                        is_focus={false}
                        is_disabled={this.props.disabled}
                        show_clear_search={true}
                        clear_search={() => this.clear_quick_search('provider')}
                    />
                </Form.Field>
            </td>
            <td data-label="insurance">
                <Selection
                    placeHolder={'Select'}
                    hidden={true}
                    id={`id_select_insurance_${this.props.row_num}`}
                    defaultValue={data.insurance_code}
                    options={this.props.insurance}
                    onChange={value => this.on_dropdown_insurance(value)}
                    disabled={this.props.disabled}
                />
            </td>
            <td data-label="comment">
                <TextareaAutosize
                    className="textarea-single-row"
                    maxLength={2000}
                    value={this.state.credit_data['comment'] || ''}
                    onChange={e => this.on_change_comment(e)}
                    disabled={this.props.disabled}
                />
            </td>
            {this.props.addBatchNum &&
                <td data-label="batch-num">{data.batch_Num || ''}</td>
            }
            <td data-label="ceated-date" >{this.get_date(data.created_on, 'MM/DD/YYYY')}</td>
            <td data-label="created-by">{data.created_by || ''}</td>
            <td data-label="date-updated" >{this.get_date(data.updated_on, 'MM/DD/YYYY')}</td>
            <td data-label="updated-by" >{data.updated_by || ''}</td>
        </tr>);
    }
};

const mapStateToProps = ({ payment_details }) => {
    return {
        is_fetched: payment_details.is_saved
    };
}
const mapDispatchToProps = dispatch => {
    return {
        save_credits: (data, action_type) => dispatch(save_credits(data, action_type))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ItemActiveComponent);