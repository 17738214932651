import * as React from 'react';
import { Button, Grid, Input } from 'semantic-ui-react';
import { url_rewrite } from '../../../../global_constants';
import {
  handle_focus_on_tab,
  set_focus_on_close_drawer,
  set_focus_on_element_with_id
} from '../../../../shared/tab_navigation_utility';

interface IFormFields {
  permission_list: any[];
  saveHandlerCallback: Function;
  save_disable: boolean;
}

class PermissionsEditComponent extends React.PureComponent<IFormFields, any> {
  static defaultProps: Partial<IFormFields> = {
    permission_list: [],
    save_disable: false
  };

  constructor(props) {
    super(props);
    this.state = {
      formFields: this.props.permission_list,
      filterList: this.props.permission_list,
      filter_text: '',
      isSubmitted: false
    };
  }
  is_mounted = false;

  componentDidMount() {
    this.is_mounted = true;
    setTimeout(() => {
      set_focus_on_element_with_id('search_permissions');
    }, 0);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.permission_list) !== JSON.stringify(this.props.permission_list)) {
      if (this.is_mounted) {
        this.setState({
          formFields: this.props.permission_list,
          filterList: this.props.permission_list,
          filter_text: ''
        });
      }
    }
  }

  componentWillUnmount() {
    this.is_mounted = false;
  }

  onChangeInputsHandler = (e) => {
    let { formFields, filter_text } = this.state;
    let input_value = e.target.value;
    const input_field = e.target.name;
    let filterList = [];
    formFields = formFields.map((item) => {
      item = {
        ...item,
        menu_description: input_field === `permission_${item.id}` ? input_value : item.menu_description
      };
      if (this.filter_text_object(item, filter_text)) {
        filterList.push(item);
      }
      return item;
    });
    if (this.is_mounted) {
      this.setState({
        formFields,
        filterList
      });
    }
  };

  on_search = (event) => {
    const value = event.target.value;
    let filterList = [...this.state.formFields];
    if (value) {
      filterList = this.state.formFields.filter((item) => this.filter_text_object(item, value));
    }
    if (this.is_mounted) {
      this.setState({
        filterList,
        filter_text: value
      });
    }
  };

  filter_text_object = (obj, value) => {
    value = value.toString().toLowerCase();
    return (
      obj.name.toString().toLowerCase().includes(value) || obj.menu_description.toString().toLowerCase().includes(value)
    );
  };

  saveHandler = (ev) => {
    let { formFields } = this.state;
    ev.preventDefault();
    if (this.is_mounted) {
      this.setState(
        {
          isSubmitted: true,
          filter_text: '',
          formFields: this.props.permission_list,
          filterList: this.props.permission_list
        },
        () => {
          formFields = formFields.filter((item, index) => {
            if (item.menu_description !== this.props.permission_list[index]['menu_description']) {
              return item;
            }
          });
          this.props.saveHandlerCallback(formFields);
        }
      );
    }
  };

  handle_tab = (e, id) => {
    if (this.state.filterList[this.state.filterList.length - 1].id === id) {
      handle_focus_on_tab(e, 'save_provider_claim_btn');
    }
  };

  render() {
    return (
      <React.Fragment>
        <Grid columns='equal' style={{ width: '100%' }}>
          <Grid.Row style={{ marginBottom: 10 }}>
            <Grid.Column>
              <h3 style={{ marginBottom: 4 }}>Permission</h3>
              <Input
                autoComplete='off'
                name='search_permissions'
                id='search_permissions'
                type='text'
                value={this.state.filter_text}
                onChange={this.on_search}
                maxLength={2000}
                placeholder={'Search'}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ flex: 1, overflow: 'auto' }}>
            <Grid.Column style={{ margin: 0 }}>
              <Grid columns='equal' style={{ marginTop: 0, flex: 0 }}>
                {this.state.filterList.length > 0
                  ? this.state.filterList.map((item) => {
                      return (
                        <Grid.Row key={`${item.id}_permission`}>
                          <Grid.Column>
                            <label>{item.name}</label>
                            <Input
                              autoComplete='off'
                              name={`permission_${item.id}`}
                              id={`permission_${item.id}`}
                              type='text'
                              value={item.menu_description || ''}
                              onChange={(e) => this.onChangeInputsHandler(e)}
                              maxLength={2000}
                              placeholder={'Description...'}
                              onKeyDown={(e) => this.handle_tab(e, item.id)}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      );
                    })
                  : this.state.filter_text && (
                      <Grid.Row key={`0_permission`}>
                        <Grid.Column textAlign='center'>
                          <label>{url_rewrite.error_message}</label>
                        </Grid.Column>
                      </Grid.Row>
                    )}
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div className='bp3-dialog-save-button'>
          <Button
            id='save_provider_claim_btn'
            className='primary'
            type='button'
            disabled={
              this.props.save_disable ||
              JSON.stringify(this.props.permission_list) === JSON.stringify(this.state.formFields)
            }
            onClick={(e) => this.saveHandler(e)}
            content={'Save'}
            onKeyDown={(event) => set_focus_on_close_drawer(event, 'bp3-dialog-close-button')}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default PermissionsEditComponent;
