import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Form, Grid, Modal } from "semantic-ui-react";
import { set_focus_on_element_with_id, set_focus_to_app_header } from '../../shared/tab_navigation_utility';
import * as charge_constant from '../charge_constant';
import GridView from '../../shared/component/grid';
import { custom_date_format, date_format_with_no_time, get_all_error, get_columns } from '../../shared/utility';
import { toastr } from 'react-redux-toastr';
import { date_format, toastr_options } from '../../global_constants';
import * as report_constants from '../../reports/report_constants';
import * as session_storage from '../../shared/session_storage_utility';
import { get_case_visit_list, charge_case_transfer, charge_case_transfer_audit } from '../../case/action/case_action';
import { LoaderComponent } from '../../shared/component/loading_component';
import { prepare_case_options } from './../utility';
import * as moment from 'moment';
import SelectionSearch from '../../shared/component/selection_search_component';
import { get_charge_case_list } from './../action/charge_action';
import ReportHeader from '../../reports/component/report_header';
import { get_lauch_darkley_key_value } from '../../shared/utility';
import { ChargesBillingAuditLogEpicsFeature } from '../../admin/constants/constants';

export class ChargeCaseTransferComponent extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            show_grid: false,
            is_search_disabled: false,
            is_save_disabled: true,
            search_criteria: {
                from_case_id: null,
                to_case_id: null
            },
            grid_rows_count: 0,
            noOptionsMessage: '',
            open_navigation_modal: false,
        };
    }
    is_mounted = false;
    patient_id: any = null;
    token: string = '';
    from_case_list: any = [];
    to_case_list: any = [];
    total_column_width: number = 0;
    grid_header_height: number = 0;
    selected_charges: any = [];
    selected_charges_audit: any = [];
    searched_from_case_id: any = null;
    grid_data: any = {
        rows: [],
        grid_conf: charge_constant.charge_case_transfer_setting_columns,
    };

    report_date = moment().format("MM/DD/YYYY hh:mm:ss A").toString();  

    UNSAFE_componentWillMount = () => {
        this.patient_id = session_storage.get('active_patient');
        this.token = this.props.user_login_details.user_data.data.token_details.access_token; 
    };

    get_case_list = async () => {
        await get_charge_case_list(this.token, this.patient_id).then(response => {
            if (response.data.data) {
                if (response.data.data.length > 0) {
                    if (this.is_mounted) {
                        let charge_case_transfer_search = session_storage.get(`charge_case_transfer_criteria`);
                        this.from_case_list = prepare_case_options(response.data.data)
                        this.setState({ search_criteria: { ...this.state.search_criteria, from_case_id: charge_case_transfer_search && charge_case_transfer_search.search_criteria ? charge_case_transfer_search.search_criteria.from_case_id : null  } }, () => {
                            if (charge_case_transfer_search && charge_case_transfer_search.search_criteria) {
                                this.handle_search();
                            }
                        })
                    }
                } else {
                    if (this.is_mounted) {
                        this.from_case_list = []
                        this.setState({
                            search_criteria: { ...this.state.search_criteria, from_case_id: null },
                            noOptionsMessage: "No Case Found!"
                        });
                    }
                }
            }
            else {
                if (this.is_mounted) {
                    this.from_case_list = []
                    this.setState({
                        search_criteria: { ...this.state.search_criteria, from_case_id: null },
                        noOptionsMessage: "No Case Found!"
                    });
                }
            }
        });
    }

    componentDidMount = async () => {
        this.is_mounted = true;
        if (!document.body.classList.contains('reports')) {
            document.body.classList.add('reports');
        }
        if (this.patient_id == '' || this.patient_id == undefined) {
            session_storage.set('no_patient_selected_for', this.props.location.pathname);
            this.props.history.push(`/no_patient_selected`);
        } else {
            this.get_case_list()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        //change page data if patient changes from header, instead of navigate to other page
        if (prevProps.patient_id && prevProps.patient_id != this.props.patient_id) {
            this.patient_id = session_storage.get("active_patient");
            this.handle_clear();
            this.get_case_list();
        }
    }

    componentWillUnmount = () => {
        document.body.classList.remove('reports');
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[2] !== 'charge_case_transfer' && session_storage.remove('charge_case_transfer_criteria');
        }
    }

    show_html_content_toaster = (msg) => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ), timeOut: toastr_options.toastr_time_out,
            preventDuplicates: true
        }
    }

    get_grid_ref = grid_params => {
        if (this.is_mounted) {
            this.setState({
                grid_params,
                grid_rows_count: grid_params.api.getModel().getRowCount()
            });
            var grid_width = document.getElementsByClassName('reports')[0].clientWidth;
            if (this.total_column_width > grid_width) {
                this.grid_data.grid_conf.filter(item => {
                    grid_params.columnApi.setColumnWidth(item.field, item.width, false);
                });
            }
        }
    };

    updateReportRowsCount = () => {
        if (this.is_mounted) {
            this.setState({
                grid_rows_count: this.state.grid_params.api.getDisplayedRowCount()
            });
            var grid_width = document.getElementsByClassName('reports')[0].clientWidth;

            if (this.total_column_width > grid_width) {
                this.grid_data.grid_conf.filter(item => {
                    this.state.grid_params.columnApi.setColumnWidth(item.field, item.width, false);
                });
            }
        }
    };

    on_grid_out = () => {
        set_focus_on_element_with_id('app_header_dropdown');
    };

    handle_change = (val, value) => {
        if (this.is_mounted) {
            this.setState(prevState => ({
                search_criteria: { ...prevState.search_criteria, [val]: value ? parseInt(value.value) : value }
            }));

            if (val == 'to_case_id') {
                this.setState({ is_save_disabled: (this.selected_charges.length > 0 && value && parseInt(value.value)) ? false : true })
            }
        }
    };
    
    // Select charges to transfer 
    on_check_box_selection = (items) => {
        if (this.selected_charges.length !== items.length) {
            this.selected_charges = items;
        }
        if (this.is_mounted) {
            this.setState({ is_save_disabled: (items.length > 0 && this.state.search_criteria.to_case_id) ? false : true })
        }
    };

    // close popup
    confirm_close_handler = () => {
        if (this.is_mounted) {
            this.setState({
                open_navigation_modal: false
            });
            set_focus_on_element_with_id("app_header_search_patient_icon");
        }
    };

    navigate_to_demand_claim = () => {
        this.props.history.push(`/billing/demand_claims`)
    }

    handle_navigation_after_last_element = (event) => {
        // apply a check where grid is empty
        if (
            !event.shiftKey &&
            event.keyCode == "9" &&
            !this.state.show_grid
        ) {
            set_focus_to_app_header(event);
        }
    };

    handle_transfer_charge = async () => {
        if (this.state.search_criteria.to_case_id == null) {
            toastr.warning('', charge_constant.charge_messages.select_at_least_one_charge);
            return;
        }
        if (this.selected_charges && this.selected_charges.length == 0) {
            toastr.warning('', charge_constant.charge_messages.select_single_visit);
            return;
        }
        if (this.selected_charges && this.selected_charges.length > 0) {
            this.selected_charges_audit = [];
            let visit_ids = this.selected_charges.map((charge, index) => {
                charge["date_of_service"] = date_format_with_no_time(charge["date_of_service"]);
                if (this.props.magnusPlat1731BillingCharges) {
                    this.selected_charges_audit.push({ "visit_id": charge["visit_id"], "date_of_service": charge["date_of_service"], "total_chg_amt": Number(charge["total_chg_amt"].replace(/,/g, '').replace('$', '') )});
                }
                return charge["visit_id"];
            });
            let params: any;
            if (this.props.magnusPlat1731BillingCharges) {
                params = { "targetCaseId": this.state.search_criteria.to_case_id, "selectedVisitIds": this.selected_charges_audit, "sourceCaseId": this.state.search_criteria.from_case_id };
            }
            else {
                params = { "case_id": this.state.search_criteria.to_case_id, "visit_ids": visit_ids };
            }
            if (this.is_mounted) {
                this.setState({
                    loading: true,
                    is_search_disabled: true,
                    is_save_disabled: true
                });
            }

            if (this.props.magnusPlat1731BillingCharges) {
                await charge_case_transfer_audit(this.token, this.patient_id, params).then(
                    (response) => this.charge_transfer_on_success(response),
                    (error) => {
                        this.charge_transfer_on_error(error);
                    }
                );
            }
            else {
                await charge_case_transfer(this.token, this.patient_id, params).then(
                    (response) => this.charge_transfer_on_success(response),
                    (error) => {
                        this.charge_transfer_on_error(error);
                    }
                );
            }
         }
    };

    charge_transfer_on_success = (response) => {
        if (response.data && response.data.status === 1) {
            let toastr_options = this.show_html_content_toaster(get_all_error(response.data));
            toastr.success("", toastr_options);
            this.handle_search();
            this.setState({ open_navigation_modal: true })
        } else {
            if (response.data.messages && response.data.messages[0].message.indexOf("<br") > -1) {
                let toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                toastr.error("", toastr_options);
            }
        }
        this.selected_charges = []
        if (this.is_mounted) {
            this.setState(prevState => ({
                search_criteria: { ...prevState.search_criteria, to_case_id: null },
                loading: false,
                is_search_disabled: false,
                is_save_disabled: true
            }));
        }
    }

    charge_transfer_on_error = (error) => {
        if (this.is_mounted) {
            this.setState({
                loading: false,
                is_search_disabled: false,
                is_save_disabled: true
            });
        }
        if (error.response.data) {
            const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
            toastr.error("", toastr_options);
        }
    }

    handle_clear = () => {
        if (this.is_mounted) {
            this.setState({
                show_grid: false,
                is_search_disabled: false,
                is_save_disabled: true,
                search_criteria: {
                    from_case_id: null,
                    to_case_id: null
                },
                grid_rows_count: 0,
                loading: false,
            });
        }
        this.grid_data = {
            rows: [],
            grid_conf: charge_constant.charge_case_transfer_setting_columns
        }
        this.selected_charges = []
        this.searched_from_case_id = null;

        session_storage.remove(`charge_case_transfer_criteria`);
    }

    cancel_transfer = () => {
        if (this.state.grid_params) {
            this.state.grid_params.api.deselectAll();
        }
        this.on_check_box_selection([]);
        if (this.is_mounted) {
            this.setState(prevState => ({
                search_criteria: { ...prevState.search_criteria, to_case_id: null }
            }));
        }
    }

    row_data_formatter = (data, grid_column) => {
        data.map(row => {
            grid_column.map((value) => {
                switch (value.type) {
                    case 'date':
                        row[value.field] = row[value.field] ? custom_date_format(row[value.field], date_format["mm/dd/yyyy"]) : "";
                        break;
                    case "currency":
                        row[value.field] =
                            row[value.field] != null || row[value.field] != undefined
                                ? report_constants.currencyFormatter(row[value.field])
                                : report_constants.currencyFormatter(0);
                        break;
                    default:
                        row[value.field] = row[value.field] ? row[value.field].toString().trim() : "";
                }
            });
            return row;
        });

        return data;
    }

    // Get search Data.
    handle_search = async () => {
        if (!this.state.search_criteria.from_case_id) {
            toastr.warning('', 'Please select a Case.');
            return;
        }

        if (this.is_mounted) {
            this.setState(prevState => ({
                search_criteria: { ...prevState.search_criteria, to_case_id: null },
                loading: true,
                show_grid: false,
                is_search_disabled: true,
                is_save_disabled: true
            }));
        }
        this.report_date = moment().format("MM/DD/YYYY hh:mm:ss A").toString();
        this.selected_charges = []

        this.grid_data = {
            rows: [],
            grid_conf: charge_constant.charge_case_transfer_setting_columns
        }

        var token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.searched_from_case_id = this.state.search_criteria.from_case_id;

        await get_case_visit_list(this.patient_id, this.state.search_criteria.from_case_id, token).then(
            (response) => {
                if (response.data && response.data.status === 1) {
                    this.grid_data['rows'] = this.row_data_formatter(response.data.data, this.grid_data.grid_conf);

                    session_storage.set(`charge_case_transfer_criteria`, {
                        search_criteria: this.state.search_criteria
                    });

                    const grid_height = get_columns(
                        this.grid_data['rows'],
                        this.grid_data.grid_conf
                    );

                    this.grid_header_height = grid_height.header_height;
                    this.total_column_width = grid_height.total_column_width;
                    this.to_case_list = this.from_case_list.filter((item) => item.value !== this.searched_from_case_id) || [];
                } else {
                    const toastr_options = this.show_html_content_toaster(get_all_error(response.data));
                    toastr.error('', toastr_options);
                }
                if (this.is_mounted) {
                    this.setState({
                        loading: false,
                        show_grid: true,
                        is_search_disabled: false,
                        grid_rows_count: this.grid_data.rows.length
                    });
                }
            },
            (error: { response: { data: any } }) => {
                if (this.is_mounted) {
                    this.setState({
                        loading: false,
                        is_search_disabled: false
                    });
                }

                if (error.response.data) {
                    const toastr_options = this.show_html_content_toaster(get_all_error(error.response.data));
                    toastr.error("", toastr_options);
                }
            }
        );
    };

    render() {
        const { loading, show_grid, search_criteria, is_search_disabled, is_save_disabled, grid_rows_count, noOptionsMessage } = this.state

        return (
            <LoaderComponent loading={loading}>
                <div className={'common-forms-add report_277'}>
                    <div className={'common-forms-search report-framework'} style={{ paddingRight: 0 }}>
                        <ReportHeader title={'Charge-Case Transfer'} />
                        <div id='report-scrollable-area' className='report-wrapper' style={{ flex: '1 1 0px', paddingRight: '10px' }}>
                            <div className='charge_case_report'>
                                <div className='patient-search-form  patient_search_bottom_padding' id='report-criteria-container'>
                                    <Form id="charge_case_transfer_container" autoComplete='off' onSubmit={this.handle_search}>
                                        <Grid>
                                             <Grid.Column computer={6} tablet={10} >
                                                <Form.Field >
                                                    <label>From Case</label>
                                                    <SelectionSearch
                                                        id="from_case"
                                                        name="from_case"
                                                        defaultValue={search_criteria.from_case_id}
                                                        options={this.from_case_list || []}
                                                        onChange={(value) => this.handle_change('from_case_id', value)}
                                                        placeHolder="From Case"
                                                        noOptionsMessage={noOptionsMessage}
                                                        autoFocus={true}
                                                        openMenuOnFocus={true}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid>
                                        <Grid>
                                            <Grid.Column tablet={16} computer={16} textAlign='right'>
                                                <Button type="reset" id="clear_form" onClick={this.handle_clear} basic >Clear</Button>
                                                <Button id="case_transfer_search" onKeyDown={this.handle_navigation_after_last_element} disabled={is_search_disabled} type='submit' primary style={{ marginRight: '0' }}>Search</Button>
                                            </Grid.Column>
                                        </Grid>
                                    </Form>
                                    {show_grid && (
                                        <Grid>
                                            <Grid.Column tablet={5} computer={4} textAlign='left'>
                                                <p style={{ fontSize: '16px', minHeight: 22 }}>{this.report_date}</p>
                                            </Grid.Column>
                                            <Grid.Column tablet={5} computer={8} textAlign='center'>
                                                {
                                                    <p style={{ fontSize: '16px', minHeight: 22 }}>
                                                        {`${grid_rows_count} ${grid_rows_count == 1 ? ' record shown' : ' records shown'
                                                            }`}
                                                    </p>
                                                }
                                            </Grid.Column>
                                            <Grid.Column tablet={2} computer={4} textAlign='right' />
                                        </Grid>
                                    )}
                                </div>
                                {show_grid && (
                                    <GridView
                                        id="charge_case_transfer"
                                        row={this.grid_data.rows}
                                        column={this.grid_data.grid_conf}
                                        suppressMovableColumns={false}
                                        enableColResize={true} 
                                        wrapperStyle={{ width: '100%', display: 'flex', flexDirection: 'column', flex: '1 1 0px' }}
                                        checkboxSelection={true}
                                        onRowSelection={(item) => this.on_check_box_selection(item)}
                                        get_grid_ref={this.get_grid_ref}
                                        suppressSizeToFit={true}
                                        headerHeight={this.grid_header_height}
                                        headerIdForTabNavigation={charge_constant.search_grid_id}
                                        gridAutoHeight={true}
                                        onForceGridOut={this.on_grid_out}
                                        on_filter_button_click={this.updateReportRowsCount}
                                        wrapperClass={"grid_wrapper " + (grid_rows_count < 6 ? 'charge_case_transfer_filter' : '')}
                                    />
                                )}
                            </div>
                        </div>
                        {show_grid && (
                            <div
                                className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area'
                                id='applicationFooterSticky'
                                style={{ paddingRight: 15 }}
                            >
                                <div className='move-to-case'>
                                    <SelectionSearch
                                        id="to_case"
                                        name="to_case"
                                        defaultValue={search_criteria.to_case_id}
                                        options={this.to_case_list || []}
                                        onChange={(value) => this.handle_change('to_case_id', value)}
                                        placeHolder="Move to Case"
                                        noOptionsMessage="No Case Found!"
                                        openMenuOnFocus={true}
                                    />
                                </div>
                                <Button
                                    onKeyDown={set_focus_to_app_header}
                                    id='cancel_charge_case_transfer_button'
                                    type='button'
                                    onClick={this.cancel_transfer}
                                    basic
                                >
                                    Cancel
                                </Button>
                                <Button
                                    primary
                                    id='transfer_charge_button'
                                    type='button'
                                    disabled={is_save_disabled}
                                    style={{ marginRight: 0 }}
                                    onClick={this.handle_transfer_charge}
                                >
                                    Save
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
                <Modal
                    onClose={this.confirm_close_handler}
                    centered={false}
                    className=" default-modal"
                    open={this.state.open_navigation_modal}
                    closeIcon={true}
                    id="navigate_to_demand_claim_modal"
                    closeOnDimmerClick={false}
                >
                    <Modal.Header>
                        <h3>Confirm</h3>
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <div className="common-forms" style={{ padding: "0" }}>
                                <p>Do you want to go to the Demand Claims page?</p>
                            </div>
                            <div className="sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area padd-r-0">
                                <Button id="cancel" type="cancel" basic onClick={() => this.confirm_close_handler()}>
                                    Cancel
                                 </Button>
                                <Button id="navigate" type="button" primary onClick={() => this.navigate_to_demand_claim()}>
                                    OK
                                </Button>
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </LoaderComponent>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch)
}

const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        patient_id: state.patient_details && state.patient_details.patient_header && state.patient_details.patient_header.data && state.patient_details.patient_header.data.id || '',
        magnusPlat1731BillingCharges: get_lauch_darkley_key_value(state.launch_darkly, ChargesBillingAuditLogEpicsFeature.magnusPlat1731BillingCharges)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChargeCaseTransferComponent));